let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    slo: {
        defaults: {
            endpoint: 'slo.php',
            rootProperty: 'slo'
        },
        ...standardCrud
    },
    sloForms: {
        defaults: {
            endpoint: 'slo.php?property=form_instances',
            rootProperty: 'slo_form_instances'
        },
        ...standardCrud
    },
    sloPlan: {
        defaults: {
            endpoint: 'sloPlanV.php',
            rootProperty: 'plans'
        },
        ...standardCrud
    },
    sloStrategy: {
        defaults: {
            endpoint: 'sloStrategy.php',
            rootProperty: 'slo_strategies'
        },
        ...standardCrud
    },
    sloLogisticType: {
        defaults: {
            endpoint: 'sloLogistic.php?property=types',
            rootProperty: 'types'
        },
        ...standardCrud
    },
    sloLogistic: {
        defaults: {
            endpoint: 'sloLogistic.php',
            rootProperty: 'logistics'
        },
        ...standardCrud
    }
}
