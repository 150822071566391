<template>
    <div class="flex-fill flex-column">
        <div class="flex-shrink-1">
            <fe-title-select
                v-if="groups.length > 0 && selectedGroup"
                placeholder="Select an Assessment Group"
                :items="groups"
                v-model="selectedGroup"
            ></fe-title-select>
        </div>
        <fe-crud-grid
            ref="grid"
            :config="$models.alphaMap"
            :columnDefs="columnDefs"
            :insertDefaults="{
                created_by: null,
                data_point_type_id: group.id
            }"
            style="height: 100%;"
            :readParams="{ data_point_type_id: group.id }"
            refresh-on-created
            refresh-on-config
            disable-inline-create
            @beginCreate="beginAdd"
            @created="refresh"
            @updated="refresh"
            @destroyed="refresh"
        />

        <fe-dialog
            v-if="adding"
            @close="adding = false"
            @dismiss="adding = false"
            @accept="add"
            title="Add Score Display"
            dismissButtonText="Cancel"
            acceptButtonText="Save"
            :acceptButtonDisabled="!valid"
            persistent
        >
            <v-form ref="form" @submit.prevent v-model="valid">
                <div class="d-flex flex-column">
                    <fe-label>Numeric Value</fe-label>
                    <v-text-field
                        v-model="obj.numeric_score"
                        flat solo dense validateOnBlur
                        :style="{ width: '100%' }"
                        :rules="rules.numeric_score"
                    />

                    <fe-label>Display As</fe-label>
                    <v-text-field
                        v-model="obj.alpha_score"
                        flat solo dense validateOnBlur
                        :style="{ width: '100%' }"
                        :rules="rules.alpha_score"
                    />

                    <fe-label>Exclude From Calculation?</fe-label>
                    <v-switch
                        v-model="obj.excl_calc"
                    />

                    <fe-label>Exclude Score?</fe-label>
                    <v-switch
                        v-model="obj.excl_score"
                    />
                </div>
            </v-form>
        </fe-dialog>

        <fe-dialog
            v-if="showDuplicateMessage"
            title="Duplicate Numeric Value"
            width=500
            :dismissable="false"
            @accept="accept"
        >
            <p class=mt-5>Duplicate numeric values are not allowed.</p>
        </fe-dialog>
    </div>
</template>

<script>
    export default {
        name: 'AssessmentGroupScoreDisplay',
        props: {
            group: { type: Object, default: () => {}, required: true },
            groups: { type: Array, default: () => [] }
        },
        data: () => {
            return {
                adding: false,
                valid: true,
                showDuplicateMessage: false,
                obj: {
                    numeric_score: null,
                    alpha_score: null,
                    excl_calc: false,
                    excl_score: false,
                },
                rules: {
                    numeric_score: [
                        v => (!!v && !isNaN(v)) || 'Please enter a numeric score'
                    ],
                    alpha_score: [
                        v => (!!v && v.trim().length > 0) || 'Please enter display text'
                    ]
                },
                columnDefs: [
                    { headerName: null, headerCheckboxSelection: true, checkboxSelection: true, width: 70, minWidth: 70, colId: 'checkbox_column' },
                    {
                        headerName: "Numeric Value",
                        field: "numeric_score",
                        sortable: true,
                        editable: true
                    },
                    {
                        headerName: "Display As",
                        field: "alpha_score",
                        sortable: true,
                        editable: true
                    },
                    {
                        headerName: "Exclude from Calculation",
                        field: "excl_calc",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: "FeGridToggle",
                        cellRendererParams: {
                            rowDataKey: "wi"
                        }
                    },
                    {
                        headerName: "Exclude Score",
                        field: "excl_score",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: "FeGridToggle",
                        cellRendererParams: {
                            rowDataKey: "wi"
                        }
                    },
                    {
                        headerName: "Created By",
                        field: "user_full_name",
                        sortable: true,
                        editable: false
                    }
                ]
            }
        },
        computed: {
            selectedGroup: {
                get () { return this.group },
                set (v) { this.$emit('switchGroup', v) }
            }
        },
        methods: {
            beginAdd() {
                this.obj = {
                    numeric_score: null,
                    alpha_score: null,
                    excl_calc: false,
                    excl_score: false,
                }
                this.adding = true
            },
            add() {
                let r = this.$refs.grid.records
                let o = this.obj
                let dup = r.some(x => x.numeric_score == o.numeric_score) // do not allow duplicate numeric values

                if (this.$refs.form.validate() && !dup) {
                    let itm = Object.assign({}, o, {
                        data_point_type_id: this.group.id
                    })
                    this.$refs.grid.$refs.crud.create(itm)
                } else if (dup) {
                    this.showDuplicateMessage = true
                }
            },
            refresh() {
                this.$store.dispatch('global/loadAlphaMaps')
            },
            accept() {
                this.adding = true
                setTimeout(() => {
                    this.showDuplicateMessage = false
                }, 200)
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>