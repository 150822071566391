import MessageBox from '@/components/common/MessageBox'


const helpers = {
    install(Vue, options) {
        Vue.prototype.$log = console.log

        Vue.prototype.$pluckJoin = function(arr,prop) {
            return arr ? arr.map(x => x[prop]).join(',') : undefined
        }

        Vue.prototype.$hasSecurity = Vue.prototype.$checkSecurity = function(security) {
            security = Array.isArray(security) ? security : [security]
            return this.$_.difference(security,this.$store.state.global.sessionUser.security_codes).length==0
        }

        Vue.prototype.$isSupport = function() {
            return !!(this.$store.state.global.sessionUser && this.$store.state.global.sessionUser.user.login == 'support')
        }

        Vue.prototype.$objectToParams = function(obj, periodOverride) {
            let str = [];
            for (let p in obj) {
                if (obj.hasOwnProperty(p) && obj[p] || obj[p] === 0) {
                    // Periods have special logic that need to be a + to search
                    if (p==='period' && periodOverride && Array.isArray(obj[p])) {
                        obj[p] = obj[p].join('+');
                    }
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]))
                }
            }
            return str.join("&") || '';
        }

        Vue.prototype.$checkAllKeys = function(obj, arr, debug) {
            if (obj) {
                let valid = true
                arr.forEach(key => {
                    if (obj[key]) {
                        let v = obj[key]
                        if (Array.isArray(v)) {
                            if (v.length === 0) {
                                if (debug) console.warn(key, 'is an empty array')
                                valid = false
                            }
                        } else {
                            if (!v && v !== 0) {
                                if (debug) console.warn(key, 'has a value of ', v)
                                valid = false
                            }
                        }
                    } else {
                        if (debug) console.warn(key, 'is  missing value')
                        valid = false
                    }
                })
                return valid
            }
            return false
        }

        Vue.prototype.$getTimeDiff = function(startTime, endTime) {
            let start = this.$dayjs(startTime, "HH:mm")
            let end = this.$dayjs(endTime, "HH:mm")
            return end.diff(start, 'minutes')
        }

        Vue.prototype.$UUIDv4 = function() {
            let firstPart = (Math.random() * 46656) | 0
            let secondPart = (Math.random() * 46656) | 0
            let thirdPart = (Math.random() * 46656) | 0
            firstPart = ("000" + firstPart.toString(36)).slice(-3)
            secondPart = ("000" + secondPart.toString(36)).slice(-3)
            thirdPart = ("000" + secondPart.toString(36)).slice(-3)
            return firstPart + secondPart + thirdPart
        }

        Vue.prototype.$objectsAreDirty = function(obj1, obj2) {
            let obj1Keys = Object.keys(obj1)
            let obj2Keys = Object.keys(obj2)
            let missingKeys = [...this.$_.difference(obj1Keys, obj2Keys), ...this.$_.difference(obj2Keys, obj1Keys)]

            let areDirty = false
            // Are any keys added to either array
            missingKeys.forEach(key => {
                let v1 = obj1[key] || obj1[key] === 0 ? obj1[key] : null
                let v2 = obj2[key] || obj2[key] === 0 ? obj2[key] : null
                if (v1 != v2) {
                    areDirty = true
                }
            })

            // Were no keys added, but values are different?  Treat undefined/null as equal.  0 is a valid value
            if (!this.$_.isEqual(obj1, obj2)) {
                obj1Keys.forEach(key => {
                    let v1 = obj1[key] || obj1[key] === 0 ? obj1[key] : null
                    let v2 = obj2[key] || obj2[key] === 0 ? obj2[key] : null
                    if (v1 != v2) {
                        areDirty = true
                    }
                })
            }

            return areDirty
        }

        Vue.prototype.$isDirty = function(orig, curr) {
            let dirty = []

            if (Array.isArray(orig) && Array.isArray(curr)) {
                orig.forEach((o, i) => {
                    let areDirty = this.$objectsAreDirty(o, curr[i])
                    if (areDirty) dirty.push(curr[i])
                })

                if (dirty.length === 0) {
                    return false
                } else {
                    return dirty
                }
            } else if (!Array.isArray(orig) && !Array.isArray(curr)) {
                let areDirty = this.$objectsAreDirty(orig, curr)

                if (areDirty) dirty.push(curr)

                if (dirty.length === 0) {
                    return false
                }
                return dirty[0]
            } else {
                console.warn("Data type mismatch")
            }
        }

        Vue.prototype.$getUiSavedSearch = function(key) {
            this.$store.dispatch('global/getUiSavedSearch', { uiKey: key })
        }

        Vue.prototype.$updateUiSavedSearch = function (key, args, json) {
            this.$store.dispatch('global/updateUiSavedSearch', { uiKey: key, json: json, args: args })
                .then(response => {
                    this.$getUiSavedSearch(key)
                })
        }

        // Given a list of saved searches, group them together if they share
        // the same non-null saved_search_group_id value.
        //
        // Example:  [{groupId: 'abc', x: 1}, {groupId: 'abc', y: 2}, {groupId: 'xyz', x: 5}, {groupId: 'xyz', y: 7}]
        //           returns
        //           [[{groupId: 'abc', x: 1}, {groupId: 'abc', y: 2}], [{groupId: 'xyz', x: 5}, {groupId: 'xyz', y: 7}]]
        //           [{groupId: 'abc', x: 1, y: 2}, {groupId: 'xyz', x: 5, y: 7}]
        Vue.prototype.$collateSavedSearchesBySavedSearchId = function(arr) {
            arr = this.$_.cloneDeep(arr)

            let allGroupIds = arr.filter(itm => itm.saved_search_group_id).map(itm => itm.saved_search_group_id)
            let uniqueGroupIds = this.$_.uniq(allGroupIds)

            // Any saved search without a saved_search_group_id is thrown into a single "miscellaneous" result
            let itemsWithoutGroupId = arr.filter(itm => !itm.saved_search_group_id)
            let results = itemsWithoutGroupId.length ? [itemsWithoutGroupId] : []

            for (let id of uniqueGroupIds) {
                results.push(arr.filter(itm => itm.saved_search_group_id === id))
            }

            return results
        }

        Vue.prototype.$flashGridRow = function(grid,rowIndex,duration) {
            let node = grid.$el.querySelector(`.ag-center-cols-container [row-index="${rowIndex}"]`)
            node.classList.add('fe-grid-row-changed')
            setTimeout(() => {
                node.classList.add('fe-grid-row-changed-transition')
                node.classList.remove('fe-grid-row-changed')
                setTimeout(() => node.classList.remove('fe-grid-row-changed-transition'),1000)
            },duration)
        }

        Vue.prototype.$stripHtml = function(str) {
            var tmp = document.createElement("DIV")
            tmp.innerHTML = str.replace(/\</g, " <")
            return tmp.textContent || tmp.innerText || ""
        },

        Vue.prototype.$addCrumb = function(key, crumbs, index) {
            this.$store.commit('manage/addCrumb', {
                key: key,
                val: crumbs,
                index: index
            })
        }

        Vue.prototype.$removeCrumb = function (key, count) {
            this.$store.commit('manage/removeCrumb', {
                key: key,
                count: count ? count : 1
            })
        }

        Vue.prototype.$getLastCrumb = function(key) {
            return this.$store.state.manage.breadcrumbs[key].slice(-1)[0]
        }

        Vue.prototype.$dockableWindow = function(cfg) {
            this.$store.commit('global/addDockableWindow', cfg)
            return cfg
        }

        Vue.prototype.$tagStudents = function(show, students, callback) {
            this.$store.commit('global/tagStudents', {
                show: show,
                students: students,
                callback: callback
            })
        }

        Vue.prototype.$setCurrentModule = function(module, routerPath) {
            // this.$store.commit('set', {
            //     module: 'global',
            //     state: 'currentModule',
            //     value: module
            // })

            // if (routerPath) this.$router.replace(routerPath)

            // this.$store.dispatch('global/addRecentNavigation', {
            //     route: routerPath,
            //     name: module
            // })
        }

        Vue.prototype.$messageBox = options => {
            let mb = new Vue(Object.assign(MessageBox, {
                vuetify: Vue.prototype.$vuetify,
                destroyed: (c) => {
                    document.body.removeChild(mb.$mount().$el)
                }
            }))
            Object.assign(mb, Vue.prototype.$messageBox.options || {}, options)
            document.body.appendChild(mb.$mount().$el)
        }

        Vue.prototype.$confirmDelete = (records, callback, cancelCallback, msg) => {
            if (!Array.isArray(records)) records = [records]
            msg = msg || 'You are about to delete <b>' + records.length + ' record' + (records.length > 1 ? 's' : '') + '</b>.  This change is permanent!'

            Vue.prototype.$messageBox({
                title: 'Confirm Delete',
                persistent: true,
                message: msg,
                maxWidth: '500',
                actions: [{
                    text: 'Cancel',
                    usage: "ghost",
                    onClick: () => {
                        if (cancelCallback) cancelCallback()
                    }
                }, {
                    text: 'Delete',
                    usage: 'danger',
                    onClick: () => {
                        if (callback) callback()
                    }
                }]
            })
        }

        Vue.prototype.$confirmCreate = (records, callback, func, cancelCallback) => {
            if (!Array.isArray(records)) records = [records]

            func = func || 'Create'

            Vue.prototype.$messageBox({
                title: 'Confirm ',
                persistent: true,
                message: 'You are about to '+ func + ' <b>' + records.length + ' record' + (records.length > 1 ? 's' : '') + '</b>.',
                maxWidth: '500',
                actions: [{
                    text: 'Cancel',
                    usage: "ghost",
                    onClick: () => {
                        if (cancelCallback) cancelCallback()
                    }
                }, {
                    text: func.charAt(0).toUpperCase()+func.substring(1),
                    primary: true,
                    onClick: () => {
                        if (callback) callback()
                    }
                }]
            })
        }

        Vue.prototype.$confirmCancel = (callback) => {
            Vue.prototype.$messageBox({
                title: 'Confirm ',
                persistent: true,
                message: 'You are about to cancel this action. All unsaved changes will be lost',
                maxWidth: '500',
                actions: [{
                    text: 'Cancel',
                    primary: false
                }, {
                    text: 'Ok',
                    primary: true,
                    onClick: () => {
                        if (callback) callback()
                    }
                }]
            })
        }

        Vue.prototype.$fieldValidators = (key, title, cfg) => {
            // cfg: { min, max, limit, required, match}
            let cfgArr = []
            title = title || 'Field'

            if (!key) return []
            let v = {
                'text': [],
                'select': [],
                'number': [],
                'integer': [],
                'mm:ss': [],
                'url': []
            }

            if (key==='mm:ss') {
                cfgArr.push((v) => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Invalid time format')
            } else if (key === 'url') {
                cfgArr.push(v => /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/.test(v) || 'Invalid url')
            }

            // Set up rules for required values
            if (cfg.required) {
                v = {
                    ...v,
                    'text': [
                        (v) => !!v || title + ' is required'
                    ],
                    'select': [
                        (v) => !!v && (Array.isArray(v) ? !!v.length : true) || title + ' is required',
                    ],
                    'number': cfg.allowZero ? [
                        (v) => !!v || v === 0 || title + ' is required',
                    ] : [
                        (v) => !!v && v !== 0 || title + ' is required',
                    ],
                    'integer': [
                        (v) => !!v || v === 0 || title + ' is required',
                        (v) => (!isNaN(parseInt(v)) && (Math.floor(v) === parseFloat(v))) || `${title} must be a whole number`,
                    ],
                    'mm:ss': [
                        (v) => !!v || title + ' is required'
                    ],
                    'url': [
                        (v) => !!v || title + ' is required'
                    ]
                }
            }

            // Set up any rules that may apply even to non-required values
            else {
                v = {
                    ...v,
                    'integer': [
                        (v) => (v !== 0 && !v) || !isNaN(parseInt(v)) || `${title} must be a whole number`,
                        (v) => (v !== 0 && !v) || ((!!v || v === 0) && (Math.floor(v) === parseFloat(v))) || `${title} must be a whole number`,
                    ],
                }
            }

            if (cfg) {
                if (cfg.min || cfg.min === 0) {
                    cfgArr.push((v) => (!v || v >= cfg.min) || title + ' must be at least a value of ' + cfg.min,)
                }

                if (cfg.max) {
                    cfgArr.push((v) => v <= cfg.max || title + ' must be ' + cfg.max + ' or less')
                }

                if (cfg.limit) {
                    cfgArr.push((v) => {
                        if (!cfg.required && !v) {
                            return true
                        }

                        if (cfg.required && v && v.length <= cfg.limit) {
                            return true
                        }

                        if (v && v.length <= cfg.limit) {
                            return true
                        }

                        return 'Must be fewer than ' + cfg.limit + ' characters'
                    })
                }

                if (cfg.maxEntries) {
                    cfgArr.push(v => {
                        if (!cfg.required && !v) {
                            return true
                        }

                        if (cfg.required && v && v.length <= cfg.maxEntries) {
                            return true
                        }

                        if (v && v.length <= cfg.maxEntries) {
                            return true
                        }

                        return 'Must be fewer than ' + (cfg.maxEntries + 1) + ' entries'
                    })
                }

                if (cfg.match) {
                    let validRegEx = true;
                    let regEx = /./
                    try {
                        regEx = new RegExp(cfg.match);
                    } catch (e) {
                        validRegEx = false;
                    }

                    if (validRegEx) {
                        cfgArr.push(v => {
                            if (!v || regEx.test(v)) {
                                return true
                            }

                            return 'Invalid entry'
                        })
                    }
                }

                if (cfg.email) {
                    let regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                    cfgArr.push(v => {
                        if (!v || regEx.test(v)) {
                            return true
                        }

                        return 'Invalid email'
                    })
                }
            }

            return v[key].concat(cfgArr)
        }

        Vue.prototype.$errorPrompt = (v) => {
            if (typeof v === 'string' || v instanceof String) v = { message: v }
            Vue.prototype.$messageBox({
                title: v.title || 'Error',
                persistent: true,
                message: v.message,
                maxWidth: '500',
                actions: [{
                    text: 'Ok',
                    primary: true,
                    onClick: () => {
                        if (v.callback) v.callback()
                    }
                }]
            })
        }

        Vue.prototype.$mapStoreFields = function(vuexStore, key) {
            return {
                get() {
                    return vuexStore[key]
                }
            }
        }

        Vue.prototype.$modelGet = function(modelName, paramsObj) {
            return new Promise((resolve, reject) => {
                let model = Vue.prototype.$models[modelName]
                if (!model) reject("Invalid model " + modelName + " provided")
                let str = Vue.prototype.$models.getUrl(modelName, 'read')

                if (paramsObj) {
                    str += (str.indexOf('?') == -1 ? '?' : '&') + Vue.prototype.$objectToParams(paramsObj)
                }
                Vue.prototype.$axios.get(str)
                .then(response => {
                    let rp = model.read.rootProperty ? model.read.rootProperty : model.defaults.rootProperty
                    if (rp) {
                        resolve(Vue.prototype.$ecResponse(response, rp))
                    } else {
                        resolve(response.data)
                    }
                })
                .catch(err => {
                    reject(err)
                })
            })
        }

        Vue.prototype.$modelFetch = function(model, urlParams) {
            return new Promise((resolve, reject) => {
                if (model.read) {
                    Vue.prototype.$axios.get(model.read + (urlParams ? urlParams : ''))
                    .then(response => {
                        resolve(Vue.prototype.$ecResponse(response, model.rootProperty))
                    })
                    .catch((error) => {
                        reject(error)
                    })
                } else {
                    reject('Missing model read configuration')
                }
            })
        }

        Vue.prototype.$differenceByKey = function(a, b, k1, k2) {
            if (!k2) k2 = k1
            let diff = []


            if (b.length > a.length) {
                b.forEach((y) => {
                    let found = false
                    a.forEach((x) => {
                        if (x[k1] == y[k2]) {
                            found = true
                        }
                    })

                    if (!found) diff.push(y)
                })
            } else {
                a.forEach((x) => {
                    let found = false
                    b.forEach((y) => {
                        if (x[k1] == y[k2]) {
                            found = true
                        }
                    })

                    if (!found) diff.push(x)
                })
            }

            return diff
        }

        Vue.prototype.$setLoading = function(isLoading) {
            this.$store.commit('set', {
                module: 'global',
                state: 'loading',
                value: isLoading
            })
        }

        Vue.prototype.$grid = {
            checkColumn: function(width, pinned) {
                return {
                    minWidth: 70,
                    maxWidth: 70,
                    // maxWidth: width ? width : 65,
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    pinned: pinned ? 'left' : false,
                    colId: 'checkbox_column'
                }
            },
            incidentColumn: function(col, me) {
                let title = col.text.replace('<br>', ' ')
                if (col.dataIndex === 'student_comments') {
                    title = 'Comments'
                } else if (col.dataIndex === 'behavior_interventions') {
                    title = 'Intv'
                }
                return {
                    headerName: title,
                    field: col.dataIndex,
                    editable: false,
                    width: col.dataIndex === 'student_full_name' ? 180 : col.width,
                    hide : col.hide ? col.hide : false,
                    cellStyle(v) {
                        //['#19ADAB', '#FFEEAA', '#FF675D']
                        if (col.dataIndex === 'student_comments') {
                            return { cursor: 'pointer', textAlign: 'right' }
                        } else if (col.xtype === 'incidentcolumn') {
                            let count = parseInt(v.value || 0)

                            if (count <= 1) return {
                                background: '#19ADAB', color: 'white', textAlign: 'right', cursor: 'pointer'
                            }
                            if (count >= 2 && count <= 5) return { background: '#FFEEAA', textAlign: 'right', cursor: 'pointer'}
                            if (count >= 6) return { background: '#FF675D', color: 'white', textAlign: 'right', cursor: 'pointer'}
                        }
                    },
                    footerAvg: (col.xtype === 'incidentcolumn'),
                    cellRenderer: col.dataIndex === 'behavior_interventions' ? 'interventionColumn' : function (v) {
                        if (col.dataIndex === 'behavior_interventions') {
                            return v.value
                        }
                        if (col.xtype === 'incidentcolumn') return v.value || 0
                        return v.value
                    },
                    onCellClicked(v) {
                        me.$store.commit('global/showStudentCard', null)
                        if (col.dataIndex === 'student_full_name') {
                            // Show most recent school year of all data years selected
                            let schoolYearId = ''
                            if (me.params?.school_year_id?.length) {
                                schoolYearId = Math.max.apply(null, me.params.school_year_id)
                            }

                            me.$router.replace(`/StudentProfile/${v.data.student_id}/${schoolYearId}`)
                        } else if (col.dataIndex === 'student_comments') {
                            me.messageDialog.show = true
                            me.messageDialog.node = v.node
                            me.messageDialog.params = {
                                student_id: v.data.student_id,
                                school_year_id: me.params.school_year_id
                            }
                        } else if (col.xtype === 'incidentcolumn') {
                            me.$store.commit('global/addDockableWindow', {
                                name: 'Student Incidents',
                                data: v.data,
                                component: 'big-five',
                                attrs: {
                                    breadcrumbs: true,
                                    fixedTools: false,
                                    params: {
                                        student_id: v.data.student_id,
                                        school_year_id: me.params.school_year_id
                                    }
                                }
                            })

                        } else if (col.dataIndex === 'behavior_interventions') {
                            me.$dockableWindow({
                                name: 'Intervention Groups',
                                component: 'intervention-workspace',
                                attrs: {
                                    interventionPlanId: v.data.intervention_plan_id
                                }
                            })
                        }
                    }
                }
            },
            attendanceColumn: function(col, me, targets) {
                return {
                    headerName: col.text.replace('<br>', ' '),
                    field: col.dataIndex,
                    pinned: col.locked ? 'left' : undefined,
                    minWidth: col.width,
                    width: col.width ? col.width * 1.5 : 90,
                    hide: !!col.hidden,
                    filter: col.align == 'right' ? 'agNumberColumnFilter' : 'agTextColumnFilter',
                    cellRenderer: col.align == 'right' ? (meta) => {
                        return meta.value ? meta.value : 0 // This should be handled by the backend
                    } : null,
                    comparator: col.align === 'right' ? (n1, n2) => {
                        return parseFloat(n1) - parseFloat(n2)
                    } : undefined,
                    cellStyle: function (meta) {
                        let style = {
                            textAlign: col.align
                        }
                        let val = meta.value

                        if (meta.colDef.field == 'days_equivalent_pct' && val >= 0) {
                            if (targets?.EQUIV.length) {
                                let target = me.$_.find(targets.EQUIV, x => { return val <= x.end && val >= x.start })
                                let bgColor = target && target.color ? target.color : '#FFFFFF' // just in case target.color is undefined
                                let textColor = me.$calculateForegroundColor(bgColor)
                                style = { 'background-color': bgColor, 'color': textColor, textAlign: 'right' }
                            } else {
                                if (val < 90) {
                                    style = { 'background-color': '#900000', 'color': 'white;', textAlign: 'right' }
                                } else if (val < 95) {
                                    style = { 'background-color': '#F4FA58', 'color': 'black;', textAlign: 'right' }
                                } else {
                                    style = { 'background-color': '#04B431', 'color': 'white;', textAlign: 'right' }
                                }
                            }
                        }

                        if (meta.colDef.field === 'affected_days_pct' && val >= 0) {
                            if (targets?.AFFECTED.length) {
                                let target = me.$_.find(targets.AFFECTED, x => { return val <= x.end && val >= x.start })
                                let bgColor = target && target.color ? target.color : '#FFFFFF' // just in case target.color is undefined
                                let textColor = me.$calculateForegroundColor(bgColor)
                                style = { 'background-color': bgColor, 'color': textColor, textAlign: 'right' }
                            } else {
                                if (val < 86) {
                                    style = {'background-color': '#900000', 'color': 'white;', textAlign: 'right'}
                                } else if (val < 90) {
                                    style = {'background-color': '#F4FA58', 'color': 'black;', textAlign: 'right'}
                                } else {
                                    style = {'background-color': '#04B431', 'color': 'white;', textAlign: 'right'}
                                }
                            }
                        }

                        return style
                    },
                    onCellClicked(meta) {
                        let params = {
                            student_id: meta.data.student_id,
                            school_year_id: meta.data.school_year_id
                        }
                        me.$dockableWindow({
                            name: meta.data.student_full_name + ' Attendance',
                            component: 'student-attendance',
                            attrs: {
                                params: params,
                                pinnable: false
                            }
                        })
                    }
                }
            },
            deleteColumn: function(clickEvent) {
                return {
                    headerName: 'Delete',
                    field: 'delete_option',
                    maxWidth: 100,
                    // pinned: 'right',
                    cellRenderer(v) {
                        return '<i class="fe-grid-icon fas fa-trash theme--light"></i>'
                    },
                    cellStyle() {
                        return {
                            'cursor': 'pointer',
                            'text-align': 'left'
                        }
                    },
                    onCellClicked: function (v) {
                        if (clickEvent) clickEvent(v)
                        // me.deleteField(v.data)
                        // me.cellClicked(v)
                    }
                }
            },
            iconColumn: function (headerName, icon, clickEvent) {
                return {
                    headerName: headerName,
                    // field: 'delete_option',
                    maxWidth: 100,
                    // pinned: 'right',
                    cellRenderer(v) {
                        return `<i class="fe-grid-icon ${icon} theme--light"></i>`
                    },
                    cellStyle() {
                        return {
                            'cursor': 'pointer',
                            'text-align': 'left'
                        }
                    },
                    onCellClicked: function (v) {
                        if (clickEvent) clickEvent(v)
                        // me.deleteField(v.data)
                        // me.cellClicked(v)
                    }
                }
            },
            toggleColumn: function(headerName, field, tooltip, callback, options) {
                return {...{
                    headerName: headerName,
                    field: field,
                    minWidth: 90,
                    maxWidth: 90,
                    tooltipValueGetter: (params) => tooltip,
                    onCellValueChanged: v => callback ? callback(v) : null,
                    cellRendererFramework: 'FeGridToggle',
                    cellRendererParams: {
                        rowDataKey: field
                    },
                },...options}
            },
            dateColumn: function(headerName, field, format, options) {
                return {...{
                    headerName: headerName,
                    field: field,
                    editable: false,
                    cellRenderer(v) {
                        return me.$dayjs(v.value).format('LLL')
                    }
                }, ...options }
            },
            sort: function (valueA, valueB) {
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase())
            }
        }

        Vue.prototype.$downloadFile = function(url, name, extension) {
            this.$axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                },
                responseType: 'blob'
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name + (extension ? extension : '.pdf')); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.warn(error));
        }

        Vue.prototype.$ecResponse = function(response, rootProperty) {
            if (response && response.data) {
                let data;
                if (rootProperty) {
                    data = response.data[rootProperty]
                } else {
                    data = response.data
                }

                if (!data) {
                    this.$errorPrompt({
                        title: 'Server Error',
                        message: 'Unable to process server response.  Please contact support'
                    })
                    return false
                }

                // our PHP backend sometimes returns malformed JSON, so parse it using JSON5
                if (typeof data == 'string') {
                    try {
                        data = this.$JSON5.parse(data)
                    } catch (e) {
                        this.$errorPrompt({
                            title: 'Server Error',
                            message: 'Unable to process server response.  Please contact support'
                        })
                        return false
                    }
                }

                if (data.hasOwnProperty('success')) {
                    if (!data.success) {
                        this.$errorPrompt({
                            title: 'Error',
                            message: data.msg
                        })

                        return false
                    } else {

                        this.$snackbars.$emit('new', { text: data.msg, usage: 'success' })
                    }

                }
                return data
            }
        }

        Vue.prototype.$addS = function(arr) {
            if (arr.length == 1) {
                return ''
            }
            return 's'
        }

        Vue.prototype.$ecIcons = function(iconMain, iconStacked = null, color = null) {
            if(!color) {
                color = '#353D56'
            }
            if(iconMain == 's3d') {
                return "<div v-else class='btn-div'><span class='ec-fa-icon-text-bold'>3D</span></div>"
            } else {
                if(iconStacked) {
                    if(iconStacked.includes('fa')) {
                        return "<span :style='{ color: color }' class='fa-stack ec-fa-icon-stacked'>\n" +
                            "  <i :style='{ color: color }' class='fa-stack-2x ec-fa-icon-stacked-2x "+iconMain+"'></i>\n" +
                            "  <i :style='{ color: color }' style='padding-top: 3px;' class='fa-stack-1x fa-inverse ec-fa-icon-stacked "+iconStacked+"'></i>\n" +
                            "</span>"
                    } else {
                        return "<span :style='{ color: color }' class='fa-stack ec-fa-icon-stacked'>\n" +
                            "  <i :style='{ color: color }' class='fa-stack-2x ec-fa-icon-stacked-2x "+iconMain+"'></i>\n" +
                            "  <span :style='{ color: color }' class='ec-fa-icon-text-stacked ec-fa-icon-text-bold'>AD</span>\n" +
                            "</span>"
                    }
                }
            }
        }

        // Helper to determine the ideal font color for a given background color (black or white)
        Vue.prototype.$calculateForegroundColor = function(backgroundColor) {
            backgroundColor = backgroundColor.substring(1)

            // right now we expect a 6-length string (no # in the front)
            // anything else will default to black
            if (backgroundColor.length !== 6) {
                return "#000"
            }

            // if anything stupid happens, default to black again
            try {
                let rgb = [
                    parseInt(backgroundColor.substring(0, 2), 16),
                    parseInt(backgroundColor.substring(2, 4), 16),
                    parseInt(backgroundColor.substring(4), 16)
                ]

                // http://www.w3.org/TR/AERT#color-contrast
                let brightness = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000)

                return (brightness > 125) ? "#000" : "#fff"
            } catch (ex) {
                console.error("Error calculating ideal foreground color for a given background color of:", backgroundColor, ex)
                return "#000"
            }
        }

        /**
         * Remove values that have a negative `id` property.  -1 is commonly used for e.g. "All Schools"
         *
         * @param obj
         * @return obj Filtered results
         */
        Vue.prototype.$filterNegativeIds = function(obj) {
            let o = {}
            Object.keys(obj).forEach(k => {
                if (obj[k] != -1) o[k] = obj[k]
            })
            return o
        }

        Vue.prototype.$fetchStudentImages = function(ids, size=64, spriteSize, includeNames, includeStudentsWithoutImages) {
            if(!spriteSize) {
                spriteSize = Math.pow(2,Math.ceil(Math.log(spriteSize || size)/Math.log(2)))
                if(spriteSize<size*1.5) spriteSize *= 2
            }

            const defaultImgUrl = `${this.$axios.defaults.baseURL}ss3.php?action=get_image&file_name=defaultimg.png`
            // Quick local function to build the appropriate image object
            const buildImageObject = (d, spritesheetUrl, isTopSecretMode) => ({
                id: d.id,
                student_full_name: d.student_full_name || d.id,
                size: size,
                spriteSize: spriteSize,
                styles: {
                    background: `transparent url('${spritesheetUrl}') no-repeat ${d.x * -1}px ${d.y * -1}px`,
                    backgroundSize: d.isDefault ? `cover !important` : ``,
                    height: `${spriteSize}px`,
                    width: `${spriteSize}px`,
                    transform: `scale(${size/spriteSize})`,
                    transformOrigin: `0px 0px`,
                },
                top_secret: !!isTopSecretMode,
            })

            return new Promise((resolve, reject) => {
                let obj = { ids: ids, images: [], img: null }
                if (!ids.length) {
                    resolve(obj)
                } else {
                    this.$axios.post(`ss3.php?action=generate&sprite_size=${spriteSize}&debug=0&include_name=${!!includeNames}&include_meta=${!!includeStudentsWithoutImages}`,
                        JSON.stringify({ id: ids })
                    ).then(response => {
                        if (response.data.index && response.data.index.length) {
                            response.data.index.forEach(v => {
                                obj.img = `${this.$axios.defaults.baseURL}ss3.php?action=get_image&file_name=${v.filename}`
                                v.data.forEach(d => {
                                    d.isDefault = v.filename === 'defaultimg.png';
                                    obj.images.push(
                                        buildImageObject(d, obj.img, response.data.top_secret_mode)
                                    )
                                })

                                if (includeStudentsWithoutImages) {
                                    let students = response.data.meta?.students || []

                                    // Filter out any of the students who had image data returned
                                    students = students.filter(stu => !response.data.index[0].data.find(d => d.id == stu.id))
                                    for (let stu of students) {
                                        obj.images.push(
                                            buildImageObject({
                                                ...stu,
                                                x: 0,
                                                y: 0,
                                            }, defaultImgUrl, response.data.top_secret_mode)
                                        )
                                    }
                                }
                                // resolve(obj)
                                setTimeout( () => resolve(obj), 1000)
                            })
                        } else {
                            reject()
                        }
                    }).catch((err) => {
                        console.error(err)
                        reject(err)
                    })
                }
            })
        }
        Vue.prototype.$textColors = {
            muted: '#777777',
            success: '#0EA449',
            warning: '#FBC02D',
            danger: '#FF6D00',
            error: '#F44336',
        }
        Vue.prototype.$charts = {
            colors: [
                '#0049FF',
                '#0EA449',
                '#FF8E07',
                '#F02D1F',
                '#3CCCCA',
                '#5A53C9',
                '#2B87FF',
                '#49C379',
                '#FFA200',
                '#FD4336',
                '#53DAD8',
                '#746DE0',
            ],
            barChartConfig: function() {
                return {
                    chart: {
                        type: 'column',
                        marginBottom: 70,
                        marginTop: 70,
                        marginLeft: 60,
                        marginRight: 50,
                        zoomType: 'xy'
                    },
                    title: {
                        text: 'Change Me',
                        align: 'left',
                        style: {
                            fontSize: 14
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    exporting: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            color: '#08A5DA',
                            point: {
                                events: {
                                    click(e) {
                                        // me.$emit('chartClicked', me.config.x_field, me.config.data.find(r => r[me.config.x_field] === e.point.category), e)
                                    }
                                }
                            },
                            stacking: 'normal',
                            dataLabels: {
                                enabled: true,
                                style: {
                                    textOutline: 0,
                                    color: '#000000'
                                }
                            },
                            label: {
                                style: {
                                    color: 'black'
                                }
                            },
                            size: '100%'
                        }
                    },
                    legend: {
                        // margin: 5
                    },
                    yAxis: {
                        minPadding: 0,
                        maxPadding: 0,
                        endOnTick: false,
                        minimum: undefined,
                        maximum: undefined,
                        labels: {
                            style: {
                                fontSize: '10px'
                            }
                        }
                    },
                    xAxis: {
                        categories: [],
                        minPadding: 0,
                        maxPadding: 0,
                        labels: {
                            formatter() {
                                return (this.value + '').substring(0, 10)
                            },
                            style: {
                                fontSize: '10px',
                                color: 'gray'
                            }
                        }
                    },
                    series: []
                }
            }
        }

        // Helper for when values are known to exist as strings, yet
        // are also known (or strongly expected) to be numeric values,
        // e.g. student scores in an fe-grid
        Vue.prototype.$sortStringsNumerically = function(a, b) {
            a = parseFloat(a)
            b = parseFloat(b)

            if (isNaN(a) || isNaN(b)) return 0
            else if (a < b) return -1
            else if (a > b) return 1
            else return 0
        }

        Vue.prototype.$twoDecimals = function(val) {
            if(!val || val === 0) {
                return 0
            } else {
                if(typeof val === 'string') {
                    return parseFloat(parseFloat(val).toFixed(2))
                } else {
                    return parseFloat(val.toFixed(2))
                }
            }
        }

        Vue.prototype.$getAlphaScore = function(data_point_type_id, val, sub_category_id = null) {
            if (isNaN(val)) return val
            let map = this.$store.state.global.alphaMaps.find(mp => mp.data_point_type_id == data_point_type_id)
            if (map) {
                if (sub_category_id && map.exclusions.indexOf(sub_category_id) > -1) return val
                let match = map.maps.find(itm => itm.numeric_score == Math.floor(val))
                if (match) return match.alpha_score.toUpperCase()
            }
            return val
        }

        Vue.prototype.$fetchGoogleSheet = function (config) {
            let {url, starting, ending, sheetName} = config
            let sheetId = url.match('/spreadsheets/d/([a-zA-Z0-9-_]+)')

            return new Promise((resolve, reject) => {
                if (!sheetId || !sheetId[1]) {
                    reject('Unable to load sheet')
                    return
                }

                let params = {
                    spreadsheetId: sheetId[1],
                    rangeStart: starting,
                    rangeEnd: ending
                }

                if (sheetName) {
                    params.sheetName = sheetName.hasOwnProperty('id') ? sheetName.id : sheetName
                }

                let api = this.$models.getUrl('googleSheets', 'read') + 'values?' + this.$objectToParams(params)

                this.$axios.get(api)
                    .then(res => {
                        resolve(this.$parseGoogleSheetData(res?.data))
                    }, res => {
                        console.error('Error: ' + res.result.error.message)
                        reject(res.result.error.message)
                    });
            })
        }

        Vue.prototype.$parseGoogleSheetData = function(data) {
            let output = {
                rowKeys: [],
                colKeys: [],
                sheetValues: {}
            }

            if (data?.length > 1) {
                if (data[1].length > 1 && Number.isNaN(parseInt(data[1][1]))) {
                    /** Data is non-numeric. Calculate values **/
                    data.forEach(function (item, index) {
                        if (!index) {
                            output.colKeys = item.slice(0)
                        } else if (item.length) {
                            item.forEach(function (element, elemIndex) {
                                element = (typeof element === 'string') ? element.trim() : element
                                let colKey = output.colKeys[elemIndex]

                                let elementCompare = (element + '').toLowerCase()
                                let rowIndex = output.rowKeys.findIndex(rowItem => elementCompare === (rowItem + '').toLowerCase())
                                if (rowIndex === -1) {
                                    output.rowKeys.push(element)
                                    rowIndex = output.rowKeys.length - 1
                                }

                                if (!output.sheetValues.hasOwnProperty(colKey)) {
                                    output.sheetValues[colKey] = {}
                                }
                                if (!output.sheetValues[colKey].hasOwnProperty(element)) {
                                    output.sheetValues[colKey][elementCompare] = 0
                                }
                                output.sheetValues[colKey][elementCompare]++

                                /** Make sure the value that shows is not lowercase (if available) **/
                                if (element !== elementCompare) {
                                    output.rowKeys[rowIndex] = element
                                }
                            })
                        }
                    })
                } else {
                    data.forEach(function (item, index) {
                        if (!index) {
                            output.colKeys = item.slice(1)
                        } else if (item.length) {
                            let rowKey
                            item.forEach(function (element, elemIndex) {
                                if (!elemIndex) {
                                    rowKey = element + ''
                                    output.rowKeys.push(element)
                                } else {
                                    let colKey = output.colKeys[elemIndex - 1]
                                    if (!output.sheetValues.hasOwnProperty(colKey)) {
                                        output.sheetValues[colKey] = {}
                                    }
                                    output.sheetValues[colKey][rowKey.toLowerCase()] = element
                                }
                            })
                        }
                    })
                }
            }

            return output
        }

        Vue.prototype.$buildChartFromSheet = function(cfg, flipGrouping) {
            let output = {
                categories: [],
                series: []
            }

            if (Object.keys(cfg.sheetValues).length) {
                /** Data **/
                let rowKeys = cfg.rowKeys
                let colKeys = cfg.colKeys
                let data = cfg.sheetValues
                let series = {}

                rowKeys.forEach((rowName, rowIndex) => {
                    colKeys.forEach((colName, colIndex) => {
                        let value = data[colName][(rowName+'').toLowerCase()]

                        let seriesName = flipGrouping ? rowName : colName
                        let dataIndex = flipGrouping ? colIndex : rowIndex

                        if (!series.hasOwnProperty(seriesName)) {
                            series[seriesName] = {
                                name: seriesName,
                                data: []
                            }
                        }

                        series[seriesName].data[dataIndex] = parseFloat(value)
                    })
                })

                output.categories = flipGrouping ? colKeys : rowKeys
                output.series = Object.keys(series).map(x => {
                    let item = series[x]
                    item.data = item.data.map(z => z ? {y: z} : {y: 0})
                    return item
                })
            }

            return output
        },

        // This will return the array of possible demographic operators. You can scope
        // the list to a specific type by passing it in.
        //
        // Example Usage:
        //  - $demographicsOperators()
        //  - $demographicsOperators('number')
        //
        Vue.prototype.$demographicsOperators = function(type=null) {
            let ops = [
                { code: 'gt', text: 'Greater Than', types: ['date','number'] },
                { code: 'gte', text: 'Greater Than or Equal To', types: ['date','number'] },
                { code: 'lt', text: 'Less Than', types: ['date','number'] },
                { code: 'lte', text: 'Less Than or Equal To', types: ['date','number'] },
                { code: 'bt', text: 'Between', types: ['date','number'] },
                { code: 'eq', text: 'Equal To', types: ['date','number','string','boolean'] },
                { code: 'has', text: 'Contains', types: ['string'] },
                { code: 'start', text: 'Starts With', types: ['string'] },
                { code: 'end', text: 'Ends With', types: ['string'] }
            ]

            return type ? ops.filter(itm => itm.types.includes(type)) : ops
        }

        // Convert a shorthand demographic operator from the backend into an expression
        // understood in data lookups
        //
        // Example Usage:
        //  - ("111:=1") returns [{ key: 'demo_111', value: 'eq:1' }]
        //
        // Give it a simple string and receive an array with one item
        // Give it an array of strings to receive an array of multiple items (one per value)
        Vue.prototype.$translateSavedSearchDemographicArgumentForDataLookup = function(value) {
            if (!(value instanceof Array)) {
                value = [value]
            }

            let ops = [
                { code: 'gt', saved_search_code: '>' },
                { code: 'gte', saved_search_code: '>=' },
                { code: 'lt', saved_search_code: '<' },
                { code: 'lte', saved_search_code: '<=' },
                //{ code: 'bt', saved_search_code: '=' }, // between will have an = but can be detected by checking for the separate '..' e.g. 'Monday..Friday'
                { code: 'eq', saved_search_code: '=' },   // thus, this case is covered by the 'eq' code
                { code: 'has', saved_search_code: '*=' },
                { code: 'start', saved_search_code: '^=' },
                { code: 'end', saved_search_code: '$=' },
            ]

            // Look for the longest codes first, so that we don't
            // false positive a '>' when the value is really '>='
            ops.sort((a, b) => b.saved_search_code.length - a.saved_search_code.length)

            return value.map(s => {
                let pieces = s.split(':')
                if (pieces.length === 1) {
                    console.warn(`Invalid demographic argument '${s}' is being ignored...`)
                    return null
                }

                let demographicId = pieces[0]

                let result = ops.find(op => op.saved_search_code == pieces[1].substring(0, op.saved_search_code.length))
                if (result) {
                    let rawValue = pieces[1].substring(result.saved_search_code.length)

                    if (pieces[1].indexOf('..') >= 0) {
                        let betweenPieces = rawValue.split('..')
                        return { key: `demo_${demographicId}`, value: `bt:${betweenPieces[0]}:${betweenPieces[1]}` }
                    } else {
                        return { key: `demo_${demographicId}`, value: `${result.code}:${rawValue}` }
                    }
                } else {
                    return { key: `demo_${demographicId}`, value: `${pieces[1]}` }
                }
            }).filter(itm => !!itm) // filter out any invalid values
        }

        // This will parse and fetch all details of a demographic filter from it's
        // encoded querystring format.
        //
        // Example Usage:
        //  - $parseDemographic('demo_1', 1)
        //  - $parseDemographic('demo_2', 'bt:12:123')
        //  - $parseDemographic('demo_3', 'gte:5')
        //
        // Example Response:
        //  - {
        //     demo: { id: 1, name: 'Favorite Dog Name', ... },
        //     operator: { code: 'has', text: 'Contains', types: ['string'] },
        //     text: 'Ruffles',
        //     textWithOp: 'Contains Ruffles',
        //     textWithAll: 'Favorite Dog Name Contains Ruffles',
        // }
        Vue.prototype.$parseDemographic = async function(key, value) {
            let obj = { id: parseInt(key.split('_')[1]) }
            let demographics = await this.$store.dispatch('global/loadDemographics', { ifEmpty: true })
            obj.demo = demographics.find(itm => itm.id === obj.id)
            obj.text = ''
            obj.op = 'eq'
            if (obj.demo) {
                if (obj.demo.value_type == 'option') {
                    obj.text = `${value}`.split(',').map(itm => {
                        let exclude = `${itm}`[0] == '!'
                        let id = parseInt(`${itm}`.replace('!',''))
                        let opt = obj.demo.options.find(itm => itm.id == id)
                        if (!opt.display_name_group) {
                            if (opt && !opt.display_name) return (exclude) ? `exclude ${opt.value}` : `${opt.value}`
                                else if (opt && opt.display_name) return (exclude) ? `exclude ${opt.display_name}` : `${opt.display_name}`
                                else return null
                        } else {
                            if (opt) return (exclude) ? `exclude ${opt.display_name}` : `${opt.display_name}`
                            else return null
                        }
                    }).filter(itm => !!itm).join(', ')
                    obj.text = [...new Set(obj.text.split(', '))].join(', '); // remove duplicates (for display_names)
                } else {
                    let matches = value.match(/^(.*?):(.*)$/)
                    if (matches) {
                        obj.op = matches[1]
                        if (obj.demo.value_type == 'boolean') {
                            if (matches[2] === 1 || matches[2] === '1' || matches[2] === true) obj.text = 'Yes'
                            else if (matches[2] === 0 || matches[2] === '0' || matches[2] === false) obj.text = 'No'
                        } else if (obj.op == 'bt') {
                            obj.text = matches[2].replace(':', ' and ')
                        } else {
                            obj.text = matches[2]
                        }
                    }
                }
            }
            obj.operator = this.$demographicsOperators().find(itm => itm.code == obj.op)
            obj.textWithOp = `${obj.operator && obj.operator.text} ${obj.text}`
            obj.textWithAll = `${obj.demo && obj.demo.name} ${obj.operator && obj.operator.text} ${obj.text}`
            return obj
        }

        Vue.prototype.$formatDollars = function(amount, decimalCount = 2, decimal = '.', thousands = ',') {
            if (!amount) amount = 0
            try {
                decimalCount = Math.abs(decimalCount)
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount
                const negativeSign = amount < 0 ? '-' : ''
                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
                let j = (i.length > 3) ? i.length % 3 : 0
                return '$' + negativeSign + (j ? i.substring(0, j) + thousands : '') + i.substring(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
            } catch (e) {
                console.warn({ formatDollarsError: e })
            }
        }

        Vue.prototype.$isTopSecretModeActive = function(sessionUser, userPreferences) {
            return sessionUser?.user?.force_top_secret || userPreferences?.TOP_SECRET_MODE?.user_value == '1'
        }
    }
}

export default helpers
