import Index from '@/components/modules/attendanceReporting'
import StudentAttendance from '@/components/modules/attendanceReporting/StudentAttendance'

export default [{
    path: '/Attendance',
    name: 'Attendance Reporting',
    component: Index
}, {
    path: '/Attendance/Student/:studentId',
    name: 'Student Attendance',
    component: StudentAttendance,
    props: true
}]