var render = function render(){var _vm=this,_c=_vm._self._c;return _c('record-dialog',_vm._b({attrs:{"title":_vm.title,"preTitle":!_vm.clone,"width":"460px","verifyDirty":!(_vm.clone && _vm.clone.match(/cloud/)),"defaultRec":{
        name: null,
        description: null,
        intervention_form: false,
    }},on:{"create":_vm.createRec,"update":function($event){return _vm.$emit('update',$event)},"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"form",fn:function({rec}){return [_c('fe-label',{staticStyle:{"width":"76px"}},[_vm._v("Name")]),_c('v-text-field',{attrs:{"flat":"","solo":"","dense":"","autofocus":"","rules":_vm.$fieldValidators('text', null, {required:true, limit:50}).concat(
                v => v && !_vm.templates.some(x => x.name==v ) || 'Name already used'
            )},model:{value:(rec.name),callback:function ($$v) {_vm.$set(rec, "name", $$v)},expression:"rec.name"}}),_c('fe-label',{staticStyle:{"width":"76px"}},[_vm._v("Description")]),_c('v-textarea',{attrs:{"flat":"","solo":"","dense":"","height":"64","counter":255,"rules":_vm.$fieldValidators('text', null, {required:true, limit:255})},model:{value:(rec.description),callback:function ($$v) {_vm.$set(rec, "description", $$v)},expression:"rec.description"}}),(!_vm.clone)?_c('div',{staticClass:"d-flex"},[_c('fe-label',{staticStyle:{"width":"100px"}},[_vm._v("Intervention Referral Form")]),_c('fe-switch',{staticStyle:{"margin-left":"auto"},model:{value:(rec.intervention_form),callback:function ($$v) {_vm.$set(rec, "intervention_form", $$v)},expression:"rec.intervention_form"}})],1):_vm._e(),(_vm.canCreateLiveTemplate && (!_vm.updating || _vm.duplicating))?_c('div',{staticClass:"d-flex"},[_c('fe-label',{staticStyle:{"width":"100px"}},[_vm._v("Live Template")]),_c('fe-switch',{staticStyle:{"margin-left":"auto"},model:{value:(rec.live),callback:function ($$v) {_vm.$set(rec, "live", $$v)},expression:"rec.live"}})],1):_vm._e(),_c('fe-crud',{ref:"crud",attrs:{"config":_vm.$models.form,"readParams":{ignore_permissions:1},"autoload":true},on:{"read":function($event){_vm.templates=$event}}})]}}])},'record-dialog',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }