<template>
    <v-form ref="basicInformationForm" v-model="isValid">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <h3>Basic Information</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <fe-label>Name/Label</fe-label>
                    <v-text-field
                        :value="model.slo_name"
                        @input="$emit('update', { slo_name: $event })"
                        flat solo dense validateOnBlur
                        :style="{ width: '100%' }"
                        :rules="[v => !!v || 'Please enter a name']"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <fe-label>Assignee</fe-label>
                    <fe-remote-combo
                        :value="model.slo_user_user_id"
                        @input="$emit('update', { slo_user_user_id: $event })"
                        :url="$models.getUrl('user', 'read') + '&active=1'"
                        byId
                        itemText="user_full_name"
                        itemValue="id"
                        multiple
                        validateOnBlur
                        :rules="[v => (!!v && v.length > 0) || 'Please select at least one assignee']"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <fe-label>Reviewer</fe-label>
                    <fe-remote-combo
                        :value="model.slo_approval_user_id"
                        @input="$emit('update', { slo_approval_user_id: $event })"
                        :url="$models.getUrl('user', 'read') + '&property=slo'"
                        byId
                        itemText="user_full_name"
                        itemValue="id"
                        :rules="[v => !!v || 'Please select a reviewer']"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <h3>Duration</h3>
                </v-col>
            </v-row>
            <v-row>
                <!--
                <v-col cols="12">
                    <fe-label>Date Range</fe-label>
                    <fe-date-range-picker
                        ref="dateRange"
                        @input="onChangeDateRange"
                        :rules="[v => !!v || 'Please enter a date range']"
                    />
                </v-col>
                -->
                <v-col cols="6">
                    <fe-label>Start Date</fe-label>
                    <fe-date-picker
                        :value="model.slo_start_date"
                        @input="$emit('update', { slo_start_date: $event} )"
                        :rules="startDateRules"
                    />
                </v-col>
                <v-col cols="6">
                    <fe-label>End Date</fe-label>
                    <fe-date-picker
                        :value="model.slo_end_date"
                        @input="$emit('update', { slo_end_date: $event} )"
                        :rules="endDateRules"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <fe-label>Ending Year</fe-label>
                    <fe-remote-combo
                        :value="model.plan && model.plan.school_year_id"
                        @input="$emit('update', { plan: { school_year_id: $event } })"
                        :url="$models.getUrl('schoolYear', 'read')"
                        byId
                        rootProperty="years"
                        :rules="[v => !!v || 'Please select an ending year']"
                    />
                </v-col>
                <v-col cols="6">
                    <fe-label>Ending Window</fe-label>
                    <fe-remote-combo
                        :value="model.plan && model.plan.end_data_point_name_id"
                        @input="$emit('update', { plan: { end_data_point_name_id: $event } })"
                        :items="windowItems"
                        byId
                        :rules="[v => !!v || 'Please select an ending window']"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <h3>Monitoring</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <fe-label>Monitor Date</fe-label>
                    <fe-date-picker
                        :value="model.slo_monitor_date"
                        @input="$emit('update', { slo_monitor_date: $event} )"
                        :rules="monitorDateRules"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <h3>Rationale</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-textarea
                        :value="model.plan.rationale_text"
                        @input="$emit('update', { plan: { rationale_text: $event } })"
                        flat solo dense
                        :style="{ width: '100%' }"
                        height="56"
                    />
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    export default {
        name: 'SloCreationDatawallBasicInformation',
        components: {
        },
        props: {
            model: { type: Object, required: true },
            windowItems: { type: Array, required: true },
        },
        data() {
            return {
                isValid: false,
                wasStartDateInvalid: false,
                wasEndDateInvalid: false,
            }
        },
        computed: {
            computedDateRange() {
                if (this.model.slo_start_date && this.model.slo_end_date) {
                    return `${this.model.slo_start_date} - ${this.model.slo_end_date}`
                } else {
                    return null
                }
            },
            startDateRules() {
                let me = this
                return [
                    v => !!v || 'Please enter a start date',
                    v => { return !me.model.slo_end_date || v < me.model.slo_end_date || 'Start date must be before end date' },
                ]
            },
            endDateRules() {
                let me = this
                return [
                    v => !!v || 'Please enter an end date',
                    v => { return !me.model.slo_start_date || v > me.model.slo_start_date || 'End date must be after start date' },
                ]
            },
            monitorDateRules() {
                let me = this
                return [
                    v => !!v || 'Please enter a monitor date',
                    v => !me.model.slo_start_date || !me.model.slo_end_date || (v >= me.model.slo_start_date && v <= me.model.slo_end_date) || 'Monitor date must be between start date and end date'
                ]
            },
        },
        methods: {
            onChangeDateRange(e) {
                let slo_start_date = e.dateRange?.start?.date
                let slo_end_date = e.dateRange?.end?.date

                // backend expects yyyy-mm-dd
                if (slo_start_date) {
                    slo_start_date = this.$dayjs(slo_start_date).format("YYYY-MM-DD")
                    this.$emit('update', { slo_start_date })
                }
                if (slo_end_date) {
                    slo_end_date = this.$dayjs(slo_end_date).format("YYYY-MM-DD")
                    this.$emit('update', { slo_end_date })
                }
            },
        },
        watch: {
            isValid(value) {
                this.$emit('validate', value)
            },
            // if start or end date changes and the other date had a validation
            // error, force a revalidation to see if one fixed the other (before/after rules)
            'model.slo_start_date'(value) {
                if (value) {
                    if (this.startDateRules.find(f => f(value) !== true)) {
                        this.wasStartDateInvalid = true
                    } else if (this.wasEndDateInvalid) {
                        this.$refs.basicInformationForm.validate()
                    }
                }
            },
            'model.slo_end_date'(value) {
                if (value) {
                    if (this.endDateRules.find(f => f(value) !== true)) {
                        this.wasEndDateInvalid = true
                    } else if (this.wasStartDateInvalid) {
                        this.$refs.basicInformationForm.validate()
                    }
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .col {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
</style>
