<template>
    <v-slide-x-transition>
        <div v-if="mini" key="is_mini" style="margin-top: 18px">
            <menu-btn :small="false" useIcon="fas fa-bars">
                <v-list-item
                    v-for="item in items"
                    :key="`nav-item-` + item.name"
                    @click="localValue = item"
                    class="nav-item"
                    :class="[{'nav-item-selected': isSelected(item)}]"
                >
                    {{ item.name }}
                </v-list-item>
            </menu-btn>

        </div>

        <div v-else class="nav-container" :style="[{width: width }]" key="not_mini">
            <div class="nav-item-title" v-if="title">{{title}}</div>

            <div v-for="item in items" :key="`nav-item-` + item.name" class="nav-item" @click="localValue = item" :class="[{'nav-item-selected': isSelected(item)}]">
                {{item.name}}
            </div>

        </div>
    </v-slide-x-transition>
</template>

<script>
    import MenuBtn from '@/components/common/button/MenuBtn'

    export default {
        name: 'VerticalMenu',
        components: {
            MenuBtn
        },
        props: {
            title: {},
            mini: {
                type: Boolean,
                default: false
            },
            items: {
                type: Array,
                default: () => []
            },
            value: {

            },
            width: {
                default: '300px'
            }
        },
        watch: {
            localValue(v) {
                this.$emit('input', v)
            }
        },
        data() {
            return {
                localValue: null
            }
        },
        methods: {
            isSelected(item) {
                if (!this.localValue) return false

                return item.name == this.localValue.name
            }
        }
    }
</script>

<style lang="scss" scoped>
.nav-container {
    background-color: white;
    padding: 24px;
    border-right: 1px solid #E0E1E6;

    .nav-item-title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        color: #000000;
        margin-bottom: 16px;
    }

    .nav-item {
        cursor: pointer;
        font-size: 16px;
        height: 43px;
        line-height: 44px;
        font-style: normal;
        padding-left: 20px;
        transition: background-color 0.5s ease;
    }

    .nav-item-selected {
        background-color: #366195;
        color: white;
        border-radius: 5px;
    }
}
</style>
