let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    upload: {
        defaults: {
            endpoint: 'uploads.php',
            rootProperty: 'uploads'
        },
        ...standardCrud
    },
    uploadException: {
        defaults: {
            endpoint: 'uploads.php?property=exception',
            rootProperty: 'exception'
        },
        ...standardCrud
    },
    uploadType: {
        defaults: {
            endpoint: 'uploadTypes.php',
            rootProperty: 'types'
        },
        ...standardCrud
    },
    uploadTypesColumns: {
        defaults: {
            endpoint: 'uploadTypes.php?display_columns=json',
            rootProperty: 'types'
        },
        ...standardCrud
    },
    ftpUpload: {
        defaults: {
            endpoint: 'ftpUploads.php',
            rootProperty: 'ftp'
        },
        ...standardCrud
    },
    importType: {
        defaults: {
            endpoint: 'importTypes.php',
            rootProperty: 'types'
        },
        ...standardCrud
    },
    importIdentifier: {
        defaults: {
            endpoint: 'importIdentifiers.php',
            rootProperty: 'identifiers'
        },
        ...standardCrud
    },
    importFileType: {
        defaults: {
            endpoint: 'crud.php?property=ImportFileType',
            rootProperty: 'import_file_type'
        },
        ...standardCrud
    },
    importIdentifierType: {
        defaults: {
            endpoint: 'importIdentifierTypes.php',
            rootProperty: 'types'
        },
        ...standardCrud
    },
    importParser: {
        defaults: {
            endpoint: 'importParser.php',
            rootProperty: 'parsers'
        },
        ...standardCrud
    },
    unknownSchool: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_schools',
            rootProperty: 'schools'
        },
        ...standardCrud

    },
    unknownStudent: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_students',
            rootProperty: 'students'
        },
        ...standardCrud

    },
    unknownDataPointType: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_data_point_types',
            rootProperty: 'data_point_types'
        },
        ...standardCrud

    },
    unknownSubCategory: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_sub_categories',
            rootProperty: 'aliases'
        },
        ...standardCrud
    },
    unknownCategory: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_category',
            rootProperty: 'category'
        },
        ...standardCrud
    },
    unknownGrade: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_grades',
            rootProperty: 'grades'
        },
        ...standardCrud
    },
    unknownHomeLanguage: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_home_languages',
            rootProperty: 'unknown_home_languages'
        },
        ...standardCrud
    },
    unknownIncidentBehavior: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_incident_behaviors',
            rootProperty: 'incident_behaviors'
        },
        ...standardCrud
    },
    unknownIncidentResponse: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_incident_responses',
            rootProperty: 'incident_responses'
        },
        ...standardCrud
    },
    unknownIncidentLocation: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_incident_locations',
            rootProperty: 'incident_locations'
        },
        ...standardCrud
    },
    unknownIncidentMotivation: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_incident_motivations',
            rootProperty: 'incident_motivations'
        },
        ...standardCrud
    },
    unknownStudentDisability: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_student_disabilities',
            rootProperty: 'disabilities'
        },
        ...standardCrud
    },
    unknownMealStatus: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_meal_statuses',
            rootProperty: 'meal_statuses'
        },
        ...standardCrud
    },
    unknownUser: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_users',
            rootProperty: 'users'
        },
        ...standardCrud
    },
    unknownUserGroup: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_user_groups',
            rootProperty: 'user_groups'
        },
        ...standardCrud
    },
    unknownAttendanceExcused: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_attendance_excused',
            rootProperty: 'attendance_excused'
        },
        ...standardCrud
    },
    unknownAttendanceReason: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_attendance_reasons',
            rootProperty: 'attendance_reasons'
        },
        ...standardCrud
    },
    unknownAttendanceStatus: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_attendance_statuses',
            rootProperty: 'attendance_statuses'
        },
        ...standardCrud
    },
    unknownInterventionType: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_intervention_types',
            rootProperty: 'types'
        },
        ...standardCrud
    },
    unknownDemographic: {
        defaults: {
            endpoint: 'verifyUpload.php?property=unknown_demographics',
            rootProperty: 'unknown_demographics'
        },
        ...standardCrud
    },
}
