let standardCrud = {
    read: {
        params: {action: 'get'}
    },
    create: {
        params: {action: 'create'}
    },
    update: {
        params: {action: 'update'}
    },
    destroy: {
        params: {action: 'delete'}
    }
}

export default {
    demographic: {
        defaults: {
            endpoint: 'crud.php?property=Demographic',
            rootProperty: 'demographic'
        },
        ...standardCrud
    },
    demographicOption: {
        defaults: {
            endpoint: 'crud.php?property=DemographicOption',
            rootProperty: 'demographic_option'
        },
        ...standardCrud
    },
    demographicUser: {
        defaults: {
            endpoint: 'crud.php?property=DemographicUser',
            rootProperty: 'demographic_user'
        },
        ...standardCrud
    },
    demographicUserGroup: {
        defaults: {
            endpoint: 'crud.php?property=DemographicUserGroup',
            rootProperty: 'demographic_user_group'
        },
        ...standardCrud
    },
    studentDemographic: {
        defaults: {
            endpoint: 'studentDemographics.php',
            rootProperty: 'student_demographic'
        },
        ...standardCrud
    }
}
