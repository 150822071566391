<template>
    <form-common-config
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #fieldSpecific="{rec}">
            <div class="d-flex">
                <fe-label :style="labelStyle">Include Tags</fe-label>
                <v-autocomplete
                    ref="tagCombo"
                    :items="tags"
                    v-model="savedSearch.tag_id"
                    item-text="name"
                    item-value="id"
                    placeholder="All Tags"
                    clearable
                    multiple
                    flat solo dense
                >
                    <template #selection="data">
                        <template v-if="data.index==0">
                            {{formatSelected(data)}}
                        </template>
                    </template>

                    <template #item="data">
                        <v-list-item v-bind="data.attrs" v-on="data.on">
                            <v-list-item-action class="mr-2">
                                <v-checkbox :input-value="data.attrs.inputValue" :ripple="false" />
                            </v-list-item-action>
                            <v-icon size="10" :color="data.item.color" class="mr-2">fa-tag</v-icon>
                            <v-icon v-if="data.item.public_flag" size="10" color="rgba(0,0,0,.54)" class="mr-2">fa-globe-americas</v-icon>
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"/>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </div>
            
            <div class="d-flex">
                <fe-label :style="labelStyle">Tag Search Range</fe-label>
                <fe-remote-combo
                    byId
                    style="margin-left:auto;"
                    v-model="savedSearch.tag_date"
                    itemValue="value"
                    :items="[
                        {value:'all', name:'All Years'},
                        {value:'current', name:'Current Year'},
                        {value:'created_before_instance', name:'As of Form Creation'},
                    ]"
                    :clearable="false"
                ></fe-remote-combo>
            </div>

            <div class="d-flex">
                <fe-label :style="labelStyle">Show Tag Name</fe-label>
                <fe-switch style="margin-left:auto;" :value="savedSearch.tag_show_name" @input="savedSearch.tag_show_name=$event"/>
            </div>

            <div class="d-flex">
                <fe-label :style="labelStyle">Show Description</fe-label>
                <fe-switch style="margin-left:auto;" :value="savedSearch.tag_show_description" @input="savedSearch.tag_show_description=$event"/>
            </div>
            <fe-crud
                ref="crud"
                :key="rec.id"
                :readParams="{form_field_id:rec.id}"
                :config="$models.formFieldSavedSearch"
                autoload
                @read="processRead($event,rec)"
            />
        </template>
    </form-common-config>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'
import FormCommonConfig from '../FormCommonConfig'
import configMixin from './configMixin'

export default {
    inject: ['localStore'],
    mixins: [ configMixin ],
    
    components: {
        FormCommonConfig,
    },
    computed: {
        ...mapLocalState([ 'reloadFn' ]),
        labelStyle: me => ({ minWidth:'120px' }),
    },
    mounted() {
        this.$axios.get('tags.php?action=get&property=composite').then(response => {
            this.tags = response.data.tags
        })
        this.$watch('$refs.tagCombo.isMenuActive', v => {
            v && this.sort()
            !v && this.save()
        })
    },
    watch: {
        savedSearch: {
            deep: true,
            handler: 'save',
        },
    },
    methods: {
        sort() {
            this.tags = this.tags.sort((a,b) => {
                a = this.savedSearch.tag_id.includes(a.id) && -1
                b = this.savedSearch.tag_id.includes(b.id) && 1
                return a+b
            })
        },
        formatSelected(data) {
            if( this.savedSearch.tag_id.length>1) return 'Tags - '+this.savedSearch.tag_id.length
            return data.item.name
        },
        processRead(v,rec) {
            this.savedSearch = this.processSavedSearch(v, {
                form_field_id: rec.id,
                tag_id: [],
                tag_date: 'current',
                tag_show_name: 0,
                tag_show_description: 0,
            })
            this.savedSearch.tag_id = [].concat(this.savedSearch.tag_id)
            if(!this.$_.isBlank(v)) {
                this.cachedValue = {...this.savedSearch}
            }
            
            // let defaultRec = {
            //     form_field_id: rec.id,
            //     tag_id: [],
            //     tag_date: 'current',
            //     tag_show_name: 0,
            //     tag_show_description: 0,
            // }
            // if(v.length) {
            //     this.savedSearch = {
            //         ...defaultRec,
            //         ...this.$_.omit(v[0],['args']),
            //         ...(this.$_.transform(v[0].args, (acc,val,key) => {
            //             return acc[key.toLowerCase()] = val.length
            //                 ? (val.length==1 ? this.castIfNumber(val[0]) : val.map(v => this.castIfNumber(v)))
            //                 : null
            //         }))
            //     }
            //     this.savedSearch.tag_id = [].concat(this.savedSearch.tag_id)
            //     this.cachedValue = {...this.savedSearch}
            //
            // } else {
            //     // the watch on this will force a save to endpoint
            //     this.savedSearch = defaultRec
            // }
        },
        save() {
            // if(this.$refs.tagCombo.isMenuActive) return // bunch up multiple tag selects for form field versioning
            let dirty = JSON.stringify(this.cachedValue) != JSON.stringify(this.savedSearch)
            if(dirty) {
                this.cachedValue = {...this.savedSearch}
                let fieldId
                this.$refs.crud.create({...this.savedSearch, form_saved_search_type_id: 2}).then( r => {
                    fieldId = r.data?.form_field_ids[0]
                }).finally(() => {
                    this.reloadFn({fieldId:fieldId})
                })
            }
        }
    },
    data() {
        return {
            tags: [],
            savedSearch: {},
            cachedValue: {},
        }
    },
}
</script>

<style lang="scss" scoped>
// FormFieldSavedSearch
// http://192.168.1.32/educlimber/form.php?action=create&property=saved_searches
// {
//     "saved_searches": [
//         {
//             "tag_id": [],
//             "tag_date": "current",
//             "tag_show_name": true,
//             "tag_show_description": false,
//             "form_field_id": 2875,
//             "form_saved_search_type_id": 2,
//             "saved_search_id": ""
//         }
//     ]
// }
</style>
