<template>
    <div>
        <v-form v-model="valid">
            <fe-date-picker
                label="Start Date"
                :rules="$fieldValidators('text', null, { required: true })"
                v-model="record.intervention_plan_intervention_start_date"
            ></fe-date-picker>

            <fe-date-picker
                label="End Date"
                :rules="$fieldValidators('text', null, { required: true })"
                v-model="record.intervention_plan_intervention_end_date"
            ></fe-date-picker>

            <fe-label>Plan Name</fe-label>
            <v-text-field
                flat solo dense
                v-model="record.intervention_plan_name"
                :rules="$fieldValidators('text', null, { required: true, limit: 50 })"
            />

            <fe-remote-combo
                label="Intervention Level"
                :items="intvLevels"
                itemText="name"
                itemValue="id"
                :rules="$fieldValidators('select', null, { required: true })"
                v-model="record.intervention_level_id"
                byId
            />

            <fe-remote-combo
                label="Intervention Strategy"
                :items="intvTypes"
                itemText="name"
                itemValue="id"
                :rules="$fieldValidators('select', null, { required: true })"
                v-model="record.intervention_type_id"
                byId
            />

            <weekday-picker v-model="record.intervention_plan_intervention_week_days"/>

            <fe-label>Decision Rationale</fe-label>
            <v-textarea flat solo dense
                :counter="1000"
                v-model="record.decision_rationale"
                :rules="$fieldValidators('text', null, { required: true, limit: 1000 })"
            />
        </v-form>
    </div>
</template>

<script>
    import WeekdayPicker from '@/components/common/button/WeekdayPicker'

    export default {
        name: 'EditPlan',
        components: { WeekdayPicker },
        props: {
            value: {}
        },
        watch: {
            record(v) {
                this.$emit('input', v)
            },
            valid(v) {
                this.$emit('valid', v)
            },
            value: {
                handler(v) {
                    this.record = v ? v : {}
                },
                immediate: true
            }
        },
        data() {
            return {
                record: {},
                valid: false,
                intvTypes: [],
                intvLevels: []
            }
        },
        created() {
            // this.record = this.value ? {...this.value} : {}
        },
        mounted() {
            this.$modelGet('interventionType', {active: 1, type: 'academic'}).then(r=>this.intvTypes = r)
            this.$modelGet('interventionLevel', {active: 1}).then(r=>this.intvLevels = r)
        }
    }
</script>

<style lang="scss" scoped>

</style>
