<template>
    <div class="data-exporter">
        <fe-dialog
            width="80%"
            :title="`${obj.title}: ${obj.subtitle}`"
            @dismiss="dismiss"
        >
            <div class="v-toolbar__title text-xs-center">
                <span class="text-small">{{ obj.group }}</span>
            </div>

            <fe-grid
                v-if="success && collection.length > 0"
                ref="grid"
                :showToolbar="false"
                :columnDefs="columnDefs"
                :rowData="rowData"
                domLayout="normal"
                :styles="{ height: '300px' }"
            />

            <Empty
                v-if="success && collection.length === 0"
                class="mt-4"
                :title="restrictedCount === 0 ? 'No Results' : 'You do not have permission to view any of the students who meet this criteria'"
                :body="restrictedCount === 0 ? 'No results were returned for the selected school year.' : ''"
            />

            <div v-if="pending" class="text-xs-center">
                <v-btn text disabled>
                    <v-progress-circular indeterminate color="#999" size="20"/>
                </v-btn>
            </div>

            <template v-slot:footer>
                <div v-if="restrictedCount">{{ restrictedCount }} additional students meet this criteria that you do not have permission to view.</div>

                <v-spacer/>

                <fe-btn usage="ghost" @click="dismiss">Close</fe-btn>

                <fe-btn
                    usage="primary"
                    @click="printData"
                    :loading="acceptButtonLoading"
                    :disabled="acceptButtonDisabled"
                >
                    {{ acceptButtonText }}
                </fe-btn>
            </template>
        </fe-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import BaseService from '../services/BaseService'
import Empty from '../templates/Empty'

export default {
    name: 'DataViewer',

    props: {
        obj: Object
    },

    components: {
        Empty
    },

    data() {
        return {
            status: null,
            collection: [],
            restrictedCount: 0,
            printing: false,
            gridApi: null
        }
    },

    computed: {
        ...mapGetters({
            schoolYearPending: 'thresholds/schoolYear/pending',
            schoolYearCollection: 'thresholds/schoolYear/collection',
            schoolYearSuccess: 'thresholds/schoolYear/success',
            schoolYearError: 'thresholds/schoolYear/error'
        }),

        validSchoolYearCollection() {
            return this.schoolYearCollection.filter(x => !!x.active)
        },

        schoolYearId() {
            if (this.selectedSchoolYearId) {
                return this.selectedSchoolYearId
            } else if (this.validSchoolYearCollection.length > 0) {
                return this.validSchoolYearCollection[0].id
            }
            return null
        },

        schoolYearName() {
            if (this.obj && this.obj?.urlParams?.school_year_id) {
                return this.schoolYearCollection.find(x => x.id === this.obj.urlParams.school_year_id).name
            } else if (!this.obj && this.schoolYearId) {
                return this.schoolYearCollection.find(x => x.id === this.schoolYearId).name
            }
            return null
        },

        pending() {
            return this.status === 'pending'
        },

        error() {
            return this.status === 'error'
        },

        success() {
            return this.status === 'success'
        },

        columnDefs() {
            return [
                {headerName: 'Student ID', field: 'student_id', sortable: true},
                {headerName: 'Student', field: 'student_full_name', sortable: true},
                {headerName: 'Grade', field: 'grade_desc', sortable: true},
                {headerName: 'School', field: 'school_name', sortable: true}
            ]
        },

        rowData() {
            return this.collection
        },

        thresholdGroup() {
            return this.$store.getters['thresholds/group/selected']
        },

        thresholdGroupId() {
            return this.thresholdGroup ? this.thresholdGroup.id : null
        },

        acceptButtonText() {
            if (this.pending) {
                return 'Loading...'
            } else if (this.printing) {
                return 'Preparing Document...'
            } else {
                return 'Print'
            }
        },

        acceptButtonLoading() {
            return (this.pending || this.printing)
        },

        acceptButtonDisabled() {
            return (this.success && this.collection.length === 0)
        }
    },

    mounted() {
        this.refresh()
    },

    methods: {
        ...mapActions({
            print: 'thresholds/group/printTable'
        }),

        dismiss() {
            this.$emit('close')
        },

        refresh() {
            this.status = 'pending'
            this.collection = []
            this.restrictedCount = 0
            let params = this.obj.urlParams
            BaseService.fetch(params, {type: 'results'}).then(rsp => {
                if (rsp.data) {
                    this.collection = (rsp.data[params.property]) ? rsp.data[params.property] : []
                    this.restrictedCount = rsp.data.restricted || 0
                }
                this.status = 'success'
            }).catch(() => {
                this.status = 'error'
            })
        },

        printData() {
            this.printing = true
            let dataset = []
            let columns = this.columnDefs
            this.$refs.grid.gridApi.forEachNodeAfterFilterAndSort((row) => {
                dataset.push(columns.map(def => row.data[def.field]))
            })

            let params = {
                columns,
                dataset,
                groupName: this.thresholdGroup.name,
                schoolYearName: this.schoolYearName
            }

            this.print(params).then(() => {
                this.printing = false
            })
        }
    }
}
</script>
