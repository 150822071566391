<template>
    <div class="flex-column" style="width: 100%; height: calc(100% - 50px)">
        <v-layout v-if="isMounted" row fill-height class="sub-category-stepper">
            <fe-stepper-select-panel
                byId
                :items="schoolYears"
                :width="stepperWidth"
                v-model="localValue.school_year_id"
                :surfaceFirstSelection="surfaceFirstSelections"
                :filterItemsCount="0"
                v-if="!multipleYears && showYears"
            />
            <fe-stepper-checkbox-panel v-else-if="showYears" byId :items="schoolYears" :surfaceFirstSelections="surfaceFirstSelections" v-model="localValue.school_year_id"/>
            <v-divider vertical style="background-color:#E0E1E6"/>

            <fe-stepper-select-panel
                class="assessment-groups-panel"
                byId
                :width="stepperWidth"
                :filterItemsCount="0"
                :items="dataPointTypes"
                v-model="localValue.data_point_type_id"
                :surfaceFirstSelection="surfaceFirstSelections"
                @input="loadSub(...arguments); emitItem('type', ...arguments)"
                searchPlaceholder="Assessment Groups"
            />

            <v-divider vertical style="background-color:#E0E1E6"/>
            <fe-stepper-select-panel
                class="assessments-panel"
                byId
                :filterItemsCount="0"
                :width="stepperWidth"
                :items="subCategories"
                v-model="localValue.sub_category_id"
                :surfaceFirstSelection="surfaceFirstSelections"
                iconIdentifier="iconCls"
                searchPlaceholder="Assessments"
                @input="$emit('selected', true)"
            />

            <v-divider vertical style="background-color:#E0E1E6"/>
            <fe-stepper-select-panel
                v-if="!multipleWindows"
                byId
                :filterItemsCount="0"
                :width="stepperWidth"
                :items="dataPointNames"
                v-model="localValue.data_point_name_id"
                :surfaceFirstSelection="surfaceFirstSelections"
                searchPlaceholder="Windows"
                @input="$emit('selected', true)"
            />
            <fe-stepper-checkbox-panel
                v-else
                byId
                :filterItemsCount="0"
                :width="stepperWidth"
                :items="dataPointNames"
                :surfaceFirstSelections="surfaceFirstSelections"
                v-model="localValue.data_point_name_id"
                searchPlaceholder="Windows"
                @input="$emit('selected', true)"
            />
            <v-divider v-if="localValue.data_point_type_id" vertical style="background-color:#E0E1E6"/>

            <fe-stepper-panel v-if="allowScoreDetails">
                <v-form style="padding-bottom: 50px;" class="pt-2">
                    <v-checkbox label="Use Score Detail" v-model="useDetail"/>
                    <fe-remote-combo
                        v-if="useDetail"
                        v-model="localValue.score_detail_type_id"
                        byId
                        label="Score Detail Type"
                        :items="scoreDetails"
                        itemText="code"
                        hide-details
                        class="mx-2"
                        :multiple="false"
                    />

                    <div class="mx-2" v-if="useDetail">
                        <fe-label>Draw Plot Line At</fe-label>
                        <v-text-field
                            v-model="localValue.plot_value"
                            flat solo dense
                            type="number"

                        />

                        <fe-label>Plot Line Name</fe-label>
                        <v-text-field
                            v-model="localValue.plot_label"
                            flat solo dense
                        />
                    </div>
                </v-form>
            </fe-stepper-panel>
            <fe-stepper-panel v-if="!subCategories.length && localValue.data_point_type_id" class="pt-2 flex" :class="{ 'ml-6':localValue.data_point_type_id }">
                {{ "There are no assessments in this assessment group" }}
            </fe-stepper-panel>
            <fe-stepper-panel v-else-if="!surfaceFirstSelections.length && localValue.sub_category_id" class="pt-2 flex" :class="{ 'ml-6':localValue.data_point_type_id }">
                {{ "There are no windows configured for this assessment" }}
            </fe-stepper-panel>
        </v-layout>
    </div>
</template>

<script>
    export default {
        name: 'SubCategoryStepperPanel',
        props: {
            value: {},
            surfaceFirstSelections: {
                type: Boolean,
                default: false
            },
            schoolYearId: {
                default: null
            },
            allowScoreDetails: {
                type: Boolean,
                default: true
            },
            multipleYears: {
                type: Boolean,
                default: false
            },
            multipleWindows: {
                type: Boolean,
                default: false
            },
            showYears: {
                type: Boolean,
                default: true
            },
        },
        watch: {
            localValue: {
                handler(v) {
                    this.$emit('input', v)
                    this.$emit('selected', true)
                },
                deep: true
            },
            // Assessments and windows will vary between assessment groups, so
            // they must be erased if an existing assessment group is changed to a new one
            'localValue.data_point_type_id'(v, prevVal) {
                if (prevVal) {
                    this.localValue = {
                        ...this.localValue,
                        sub_category_id: null,
                        data_point_name_id: this.multipleWindows ? [] : null,
                    }
                }
            },
            summary(v) {
                this.$emit('summaryChange', v)
            },
            searchDisplay(v) {
                this.$emit('searchDisplayChange', v)
            },
            useDetail(v) {
                if (!v) {
                    this.localValue.score_detail_type_id = null
                }
            },
        },
        computed: {
            stepperWidth() {
                return "200px"
            },
            summary() {
                let str = ''
                let itm

                if (this.localValue.school_year_id) {
                    itm = this.schoolYears.find(itm => itm.id === this.localValue.school_year_id)
                    if (itm) {
                        str += itm.name
                    } else {
                        return str
                    }
                }

                if (this.localValue.data_point_type_id) {
                    itm = this.dataPointTypes.find(itm => itm.id === this.localValue.data_point_type_id)
                    if (itm) {
                        str += ` - ${itm.name}`
                    } else {
                        return str
                    }
                }

                if (this.localValue.sub_category_id) {
                    itm = this.subCategories.find(itm => itm.id === this.localValue.sub_category_id)
                    if (itm) {
                        str += ` - ${itm.name}`
                    } else {
                        return str
                    }
                }

                if (this.localValue.data_point_name_id) {
                    itm = this.dataPointNames.find(itm => itm.id === this.localValue.data_point_name_id)
                    if (itm) {
                        str += ` - ${itm.name}`
                    } else {
                        return str
                    }
                }

                if (this.localValue.score_detail_type_id) {
                    itm = this.scoreDetails.find(itm => itm.id === this.localValue.score_detail_type_id)
                    if (itm) {
                        str += ` - ${itm.code}`
                    } else {
                        return str
                    }
                }

                return str
            },
            searchDisplay() {
                let pieces = []
                let suffix

                if (this.showYears) {
                    if (this.multipleYears) {
                        if (!this.localValue?.school_year_id?.length) {
                            pieces.push('<b>Select School Years</b>')
                            return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                        } else {
                            suffix = this.localValue.school_year_id.length > 1 ? `...+${this.localValue.school_year_id.length - 1}` : ''
                            pieces.push(this.schoolYears.find(itm => itm.id === this.localValue.school_year_id[0])?.name + suffix)
                        }
                    }

                    else {
                        if (!this.localValue?.school_year_id) {
                            pieces.push('<b>Select School Year</b>')
                            return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                        } else {
                            pieces.push(this.schoolYears.find(itm => itm.id === this.localValue.school_year_id)?.name)
                        }
                    }
                }

                if (!this.localValue.data_point_type_id) {
                    pieces.push('<b>Select Assessment Group</b>')
                    return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                } else {
                    pieces.push(this.dataPointTypes.find(itm => itm.id === this.localValue.data_point_type_id)?.name)
                }

                if (!this.localValue.sub_category_id) {
                    pieces.push('<b>Select Assessment</b>')
                    return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                } else {
                    pieces.push(this.subCategories.find(itm => itm.id === this.localValue.sub_category_id)?.name)
                }

                if (this.multipleWindows) {
                    if (!this.localValue?.data_point_name_id?.length) {
                        pieces.push('<b>Select Windows</b>')
                        return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                    }

                    // All options are selected; add windows and return all
                    if (this.localValue.data_point_name_id.includes(-1)) {
                        pieces.push('All Windows')
                    } else {
                        suffix = this.localValue.data_point_name_id.length > 1 ? `...+${this.localValue.data_point_name_id.length - 1}` : ''
                        pieces.push(this.dataPointNames.find(itm => itm.id === this.localValue.data_point_name_id[0])?.name + suffix)
                    }

                    return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                }

                else {
                    if (!this.localValue.data_point_name_id) {
                        pieces.push('<b>Select Window</b>')
                        return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                    }

                    // All options are selected; add windows and return all
                    pieces.push(this.dataPointNames.find(itm => itm.id === this.localValue.data_point_name_id)?.name)
                    return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                }
            },
        },
        data() {
            return {
                isMounted: false,
                useDetail: false,
                dataPointTypes: [],
                includedAll: false,
                subCategories: [],
                dataPointNames: [],
                scoreDetails: [],
                categories: [],
                schoolYears: [],
                scSelected: [],
                localValue: {
                    data_point_type: {},
                    sub_category: {},
                    data_point_name: null,
                    category: {}
                }
            }
        },
        mounted() {
            this.localValue = {...this.value}

            // Pre-check local checkbox if previous setting chose a score detail
            if (this.value.score_detail_type_id) {
                this.useDetail = true
            }

            this.loadBase()

            if (this.localValue.data_point_type_id) this.loadSub()

            this.isMounted = true
        },
        methods: {
            loadBase() {
                this.$modelGet('schoolYear').then(r => {
                    this.schoolYears = r
                })
                this.$modelGet('category').then(r => this.categories = r)
                this.$modelGet('assessment', { hidden: 0, school_year_id: this.schoolYearId }).then(r => {
                    this.dataPointTypes = r //[{id: -1, name: 'All Assessment Groups' }].concat(r)
                })
            },
            // setSelected(v, item) {
            //     this.$emit('subCategorySelected', item)
            //     this.scSelected = [...v]
            // },
            emitItem(key, v, item) {
                this.$emit(key+'-selected', item)
            },
            loadSub(v, item) {
                let me = this
                this.subCategories = []
                this.dataPointNames = []
                let dptId = this.localValue.data_point_type_id
                if (dptId) {
                    me.$modelGet('subcategory', { data_point_type_id: dptId })
                        .then(r => {
                            me.subCategories = r
                            me.subCategories.forEach(s=> { if (s.child_cnt > 0) s.iconCls = 'f fa-sitemap' })
                        })

                    me.$modelGet('dataPointName', { data_point_type_id: dptId, hidden: 0 })
                        .then(r => me.dataPointNames = r.map(w => {
                            return {
                                id: w.id,
                                name: w.alias?w.alias:w.name
                            }
                        }))

                    this.$modelGet('scoreDetailType', { data_point_type_id: dptId, active: 1})
                        .then(r => {
                            this.scoreDetails = r
                        })
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.selected-item {
    background: #f5f6f8;
    padding: 4px 8px 4px 8px;
    margin: 4px 0px 4px 0px;
    &-name {
        overflow: hidden;
        white-space: nowrap;
        margin-right: 8px;
    }

}
.sub-category-stepper {
    ::v-deep .fe-stepper-checkbox {
        height: calc(100% - 75px) !important;
    }
}

.assessments-panel {
    ::v-deep .fe-stepper-list {
        height: calc(100% - 58px) !important;
    }
}

.assessment-groups-panel {
    ::v-deep .fe-stepper-list {
        height: calc(100% - 50px) !important;
    }
}
</style>
