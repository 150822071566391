<template>
    <div class="d-flex flex-column flex-fill" style="height: 100%; padding: 16px; overflow: hidden;">
        <div class="d-flex attendance-toolbar">
            <fe-title-select
                :items="viewTypes"
                v-model="viewType"
            ></fe-title-select>

            <div class="ml-auto" v-if="viewType && viewType.id == 'count'">
                <div class="ml-auto mt-2 d-flex toggle-button">
                    <div v-ripple class="show-charts first" @click="setPeriodView('charts')" :class="{selected: countView=='charts'}">
                        <v-icon class="mr-2">fas fa-chart-bar</v-icon>
                        Charts
                    </div>

                    <div v-ripple class="show-datawall" @click="setPeriodView('table')" :class="{selected: countView=='table'}">
                        <v-icon class="mr-2">fas fa-bars</v-icon>
                        Data List
                    </div>
                </div>
            </div>
        </div>

        <div v-if="viewType && viewType.id == 'rate'" class="flex-fill flex-column">
            <v-tabs v-model="activeTab" class="ec-tabs">
                <v-tab class="ec-tabs-small" v-for="(t, i) in rateCharts" :key="`att-tab-`+i">{{t.name}}</v-tab>
            </v-tabs>

            <v-carousel 
                class="mt-2"
                v-model="activeTab"
                height="100%"
                hide-delimiters light
                :show-arrows="false"
                ref="carouselContainer"
            >
                <v-carousel-item v-for="(c,i) in rateCharts" :key="`rate-chart-`+i">
                    <attendance-chart 
                        :height="fullHeight" 
                        :config="c.config"
                        :chartParams="c.chartParams" 
                        :property="c.property"
                        :showInsight="false"
                        :pinnable="true"
                        @chartClicked="doClick"
                        style="border: 1px solid #dddddd; border-radius: 4px;"
                    />
                </v-carousel-item>
            </v-carousel>
        </div>

        <!-- Period count charts -->
        <div v-if="viewType && viewType.id == 'count'" style="overflow: hidden;" class="d-flex flex-column flex-fill">
            <period-counts :allCharts="false" :params="queryParams" :showToggleButton="false" ref="periodCounts"/>
        </div>


        <!-- <div class="attendance-footer" v-show="viewType && viewType.id == 'count' && countView !='table'">
            <breadcrumb v-model="navigation" @click="doBreadcrumb"/>
        </div> -->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Breadcrumb from '@/components/common/Breadcrumb'
    import AttendanceChart from '@/components/charts/AttendanceChart'
    import AttendanceList from './AttendanceList'
    import PeriodCounts from './PeriodCounts'
    export default {
        name: 'StudentAttendance',
        components: { AttendanceChart, Breadcrumb, AttendanceList, PeriodCounts },
        props: {
            params: {
                type: Object,
                default:() => {}
            },
            studentId: {
            }
        },
        computed: {
            ...mapState('global', ['currentYear']),
            fullHeight() {
                let c = this.$refs.carouselContainer.$el.getBoundingClientRect()
                return parseInt(c.height)-80
            },
            queryParams() {
                let p = {...this.$props.params}

                if (this.$props.studentId) p.student_id = this.$props.studentId
                if (!p.school_year_id) p.school_year_id = this.currentYear.id
                return p
            }
        },
        data() {
            return {
                rateCharts: [],
                periodCharts: [],
                height: 350,
                insights: {},
                activeTab: null,
                navigation: [],
                viewTypes: [{
                    id: 'rate',
                    name: 'Attendance Rate'
                }, {
                    id: 'count',
                    name: 'Absence Count'
                }],
                viewType: null,
                countView: 'charts',
                studentRec: {}
            }
        },
        mounted() {
            this.$modelGet('student', {id: this.queryParams.student_id}).then(r => {
                this.studentRec=r[0]
                this.loadData()
            })
            
            this.viewType = this.viewTypes[0]

        },
        methods: {
            loadData() {
                if (!this.queryParams.student_id) {
                    console.warn('missing student id')
                    return
                }

                // All the years for a student
                let p = {...this.queryParams}
                p.chart_type = ['district']
                delete p.school_year_id
                this.$modelGet('attendanceCharts', p).then(response => {
                    response.forEach((chart, index) => {
                        let chartParams = chart.data.params
                        chart.title = (chart.title+' - '+this.studentRec.fname+' '+this.studentRec.lname).replace(/District/, 'School Year')
                        this.rateCharts.push({
                            name: 'By School Year',
                            config: chart,
                            chartParams: chart.params,
                            chartType: chart.chartType,
                            index: index
                        })
                        // Vue.set(this.totalCharts, index, {
                        
                        // })
                    })

                    p = {...this.queryParams}
                    p.chart_type = ['month', 'weekday','school']
                    this.$modelGet('attendanceCharts', p).then(response => {
                        response.forEach((chart, index) => {
                            let displayTitle=chart.title
                            chart.title = chart.title+' - '+this.studentRec.fname+' '+this.studentRec.lname
                            
                            this.rateCharts.push({
                                name: displayTitle,
                                config: chart,
                                chartParams: chart.params,
                                chartType: chart.chartType,
                                index: index
                            })
                            // Vue.set(this.totalCharts, index, {
                            
                            // })

                            this.loadPeriodCounts()
                        })
                    })

                })
            },
            setPeriodView(view) {
                this.$refs.periodCounts.setView(view)
                this.countView = view
            },
            loadPeriodCounts(extraParams) {
                let me = this

                this.$setLoading(true)
                me.periodCharts = []
                let p = {...this.queryParams, ...extraParams}
                p.chart_type = [
                    'weekday',
                    'month',
                    'reason',
                    'excused',
                    'status',
                    'term',
                    'course',
                    'period'
                ]

                if (!extraParams) {
                    this.navigation = [{
                        id: 'home',
                        name: 'Attendance Reporting',
                        params: p
                    }]
                }

                this.$modelGet('attendancePeriodCharts', p).then(response => {
                    this.insights = {}

                    response.forEach((chart, index) => {
                        let max = chart.data.reduce((prev, current) => {
                            return (parseInt(prev.total_count) > parseInt(current.total_count)) ? prev : current
                        })

                        this.insights[chart.key] = max

                        let chartParams = chart.data.params
                        this.periodCharts.push({
                            name: chart.title,
                            config: chart,
                            chartParams: chart.params,
                            chartType: 'period_charts',
                            index: index
                        })
                        // Vue.set(this.totalCharts, index, {
                        
                        // })
                    })

                }).finally(() => { this.$setLoading(false) })
            },
            doClick(evt, rec) {

                if (this.viewType.id == 'rate') return
                // this.$emit('drilldown', evt, rec)
                this.navigation.push({
                    id: evt.point.name,
                    name: evt.point.name,
                    params: rec.params
                })

                this.loadPeriodCounts(rec.params)
            }
        }
    }
</script>

<style lang="scss" scoped>
.student-insight {
    border: 1px solid var(--fe-accent);
    border-radius: 4px;
    margin: 16px;
    height: 200px;
    padding: 16px;
    background: var(--fe-accent);
}

.attendance-toolbar {
    padding: 8px;
    background: white;
}

.attendance-footer {
    bottom: 0px;
    padding: 8px;
    z-index: 2;
    border-top: 1px solid #e6e6e6;
    background: white;
}

.toggle-button {
    background: #FFFFFF;
    border: 1px solid #E0E1E6;
    border-radius: 4px;
    height: 32px;

    .first {
        border-right: 1px solid #E0E1E6;
    }

    .selected {
        background: var(--fe-accent);
    }

    div {
        width: 100px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        color: #050F2D !important;
        ::v-deep .v-icon {
            color: #050F2D;
        }
    }
}
</style>