<template>
    <div class="wrapper">
        
        <div>
            <fe-label>Title</fe-label>
            <v-text-field
                flat solo dense
                v-model="msgRec.title"
                placeholder="Enter a title"
                :rules="$fieldValidators('text', null, {required:true, limit:1000})"
            />
        
            <fe-label>Description</fe-label>
            <fe-editor
                class="compose"
                ref="editor"
                :editorConfig="editorConfig"
                v-model="msgRec.message"
                classic
                basic
            />
            
            <fe-label>URL</fe-label>
            <v-text-field
                flat solo dense
                v-model="msgRec.url"
                placeholder="Optional"
                :rules="$fieldValidators('text', null, {required:true, limit:1000})"
            />
        </div>
        
        
        <div class="d-flex">
            <fe-btn
                class="ml-auto" usage="ghost"
                @click="$emit('cancel')"
            >Cancel</fe-btn>
            <fe-btn
                class="mp-0" usage="primary"
                :disabled="$_.isBlank(msgRec.title) || $_.isBlank(msgRec.message)"
                @click="send"
            >Create</fe-btn>
        </div>
        
        
        <fe-crud
            ref="crud"
            :config="$models.message"
        />
        
    </div>
</template>

<script>
export default {
    name: 'NewNotification',
    
    methods: {
        send(e) {
            this.$axios.post('users.php?action=create_notification',{
                notifications: [this.msgRec]
            }).then(response => {
                this.$ecResponse(response)
            }).catch(error => {
                console.error(error)
            })
            this.$emit('created',this.msgRec)
        },
    },
    
    
    data() {
        return {
            msgRec: {
                title: null,
                message: null,
                url: null,
            },
            editorConfig: {
                // autoGrow_minHeight: 300,
                // autoGrow_onStartup: true,
                // height: 300,
                // startupFocus: true,
                toolbar: {
                    shouldNotGroupWhenFull: true,
                    items: [
                        'bold',
                        'italic',
                        'underline',
                        'link',
                        '|',
                        'alignment',
                        'fontSize',
                        'fontColor',
                        'fontBackgroundColor',
                        '|',
                        'bulletedList',
                        'numberedList',
                    ]
                }
            },
        }
    },
}
</script>

<style lang="scss" scoped>
    .compose ::v-deep .ck-editor__editable_inline {
        min-height: 240px;
        max-height: calc(100vh - 460px);
    }
</style>
