var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value)?_c('fe-dialog',{attrs:{"title":"Subscribers","width":500,"footer":false},on:{"close":function($event){return _vm.$emit('input',false)}}},[_c('fe-grid',{ref:"grid",attrs:{"simpleInsert":"id","simpleInsertPlaceholderText":"Add User","displayDensity":"medium","rowData":_vm.rowData,"showToolbar":false,"columnDefs":_vm.columnDefs,"domLayout":"autoHeight","simpleInsertConfig":{
            type:'combo',
            url:'users.php?action=get&active=1',
            itemText:'user_full_name',
            filter: function(x) {
                if(!this.index) {
                    let index = {}
                    this.forEach(y => index[y.user_id]=true)
                    Object.defineProperty(this,'index',{value:index})
                }
                return !this.index[x.id]
            }
        }},on:{"simpleInsert":_vm.create}}),_c('fe-crud',{ref:"crud",attrs:{"defaultParams":{form_id:_vm.templateId},"config":_vm.$models.formSubscriber,"autoload":""},on:{"read":function($event){_vm.rowData = $event}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }