<template lang="html">
    <fe-remote-combo
        :value="value"
        @input="$emit('input',$event)"
        label="Target Descriptor"
        url="targetSetV.php?action=get"
        rootProperty='descriptors'
        :params="{
            property: 'target_descriptors',
            types: types,
            target_set_id: targetSetId
        }"
        v-bind="$attrs"
        :filter="filter"
        moreText
    >
        <template slot="item" slot-scope="data">
            <v-list-item-avatar :style="{ 'margin-top':'14px', opacity: data.item.disabled ? .35 : 1 }">
                <v-checkbox
                    @input="$emit('select',data.item)"
                    :input-value="value && value.find(x => x.id == data.item.id)"
                />
            </v-list-item-avatar>
            <v-list-item-content :style="{ opacity: data.item.disabled ? .35 : 1 }">
                <v-list-item-title :style="{ color: data.item.color }" v-html="data.item.name" />
                <v-list-item-subtitle
                    style="font-size:85%;padding-bottom:6px;"
                    v-html="data.item.target_type_id === 1 ? 'Detailed' : 'Benchmark'"
                />
            </v-list-item-content>
        </template>
        
        <template slot="selection" slot-scope="data">
            {{data.item.name}}
        </template>
        
    </fe-remote-combo>
</template>

<script>
export default {
    name: 'TargetDescriptorCombo',
    props: {
        value: { required:true },
        targetSetId: { type:Number },
        types: { require:true }
    },
    data() {
        return {
            filter: (x) => {
                // return this.value?.[0] ? x.target_type_id == this.value?.[0].target_type_id : true
                x.disabled = this.value?.[0] && x.target_type_id != this.value?.[0].target_type_id ? true : false
                return true
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
