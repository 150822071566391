<template>
    <div>
        <v-layout column>
            <v-flex v-if="!adding">
                <fe-btn usage="primary" @click="adding=true" useIcon="add">Add
                </fe-btn>
            </v-flex>

            <v-flex v-if="adding">
                <fe-btn usage="secondary" @click="adding=false" useIcon="chevron_left">Back
                </fe-btn>
            </v-flex>

            <v-flex v-if="adding">
                <v-layout column class="mt-3">
                    <v-flex>
                        <v-form>
                            <fe-remote-combo
                                :url="crudConfig.createConfig.read"
                                :itemText="crudConfig.createConfig.itemText"
                                :itemValue="crudConfig.createConfig.itemValue"
                                rootProperty="schools"
                                v-model="createRecord"
                                :rules="[v => !!v || 'Field is required']"
                            ></fe-remote-combo>
                        </v-form>
                    </v-flex>

                    <v-flex class="text-xs-right">
                        <fe-btn usage="primary" :disabled="!createRecord" @click="addRecord(crudConfig.createConfig.record)">Save</fe-btn>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex v-if="!adding">
                <v-list>
                    <v-list-item
                        v-for="item in items"
                        :key="`edit-list-`+item[itemValue]"
                    >
                        <v-list-item-content>
                            <v-list-item-title>{{item[itemText]}}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action class="edit-list-item">
                            <v-icon @click="removeRecord(item)" color="red">delete</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    export default {
        name: 'EditList',
        data() {
            return {
                items: [],
                adding: false,
                createRecord: null
            }
        },
        props: {
            itemText: {
                type: String,
                default: 'name'
            },
            itemValue: {
                type: String,
                default: 'id'
            },
            crudConfig: {
                type: Object
            }
        },
        mounted() {
            this.loadData()
        },
        methods: {
            addRecord() {
                let c = this.crudConfig.createConfig
                // get the value from the combo
                c.record[c.setValue] = this.createRecord[this.itemValue]

                if (c.create) {
                    let r = {}

                    if (this.crudConfig.rootProperty) {
                        r[this.crudConfig.rootProperty] = [c.record]
                    } else {
                        r = [c.record]
                    }

                    this.$axios.post(c.create, r)
                    .then(response => {
                        this.$ecResponse(response)
                    })
                    .finally(() => {
                        this.adding = false
                        this.loadData()
                    })
                }

            },
            removeRecord(item) {
                let c = this.crudConfig

                if (c.destroy) {
                    let r = {}

                    if (this.crudConfig.rootProperty) {
                        r[this.crudConfig.rootProperty] = [item]
                    } else {
                        r = [item]
                    }

                    this.$axios.post(c.destroy, r)
                    .then(response => {
                        this.$ecResponse(response)
                    })
                    .finally(() => {
                        this.adding = false
                        this.loadData()
                    })
                }
            },
            loadData() {
                let cfg = this.crudConfig

                if (cfg.read) {
                    this.$axios.get(cfg.read)
                    .then(response => {
                        let data = []

                        if (response && response.data) {
                            if (cfg.rootProperty) {
                                this.$data.items = response.data[cfg.rootProperty]
                            } else {
                                this.$data.items = response.data
                            }
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.edit-list-item {
    cursor: pointer;
}
</style>
