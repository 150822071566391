let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    incidentBehaviorType: {
        rules: [
            r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        ],
        defaults: {
            endpoint: 'incidentBehaviorType.php',
            rootProperty: 'incident_behavior_type'
        },
        ...standardCrud
    },
    incidentBehaviorTypeSchool: {
        // rules: [
        //     r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        // ],
        defaults: {
            endpoint: 'incidentBehaviorTypeSchool.php',
            rootProperty: 'incident_behavior_type_schools'
        },
        ...standardCrud
    },
    incidentBehaviorTypeUserGroupRestriction: {
        defaults: {
            endpoint: 'crud.php?property=IncidentBehaviorTypeUserGroup',
            rootProperty: 'incident_behavior_type_user_group'
        },
        ...standardCrud
    },
    incidentResponseIncidentBehaviorType: {
        // rules: [
        //     r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        // ],
        defaults: {
            endpoint: 'incidentResponseIncidentBehaviorType.php?property=incident_behavior_type',
            rootProperty: 'incident_response_incident_behavior_types'
        },
        ...standardCrud
    },
    incidentResponse: {
        // rules: [
        //     r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        // ],
        defaults: {
            endpoint: 'incidentResponses.php',
            rootProperty: 'incident_responses'
        },
        ...standardCrud
    },
    incidentResponseField: {
        defaults: {
            endpoint: 'incidentResponses.php?property=field',
            rootProperty: 'incident_response_fields'
        },
        ...standardCrud
    },
    incidentResponseFieldOption: {
        defaults: {
            endpoint: 'incidentResponses.php?property=incident_response_field_options',
            rootProperty: 'incident_response_field_options'
        },
        ...standardCrud
    },
    incidentFieldType: {
        defaults: {
            endpoint: 'incidentField.php?property=incident_field_type',
            rootProperty: 'incident_field_types'
        },
        read: {
            params: { action: 'get' }
        }
    },
    incidentResponseSchool: {
        // rules: [
        //     r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        // ],
        defaults: {
            endpoint: 'incidentResponseSchool.php',
            rootProperty: 'incident_response_schools'
        },
        ...standardCrud
    },
    incidentBehavior: {
        // rules: [
        //     r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        // ],
        defaults: {
            endpoint: 'incidentBehavior.php',
            rootProperty: 'incident_behavior'
        },
        ...standardCrud
    },
    incidentLocation: {
        // rules: [
        //     r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        // ],
        defaults: {
            endpoint: 'incidentLocations.php',
            rootProperty: 'incident_locations'
        },
        ...standardCrud
    },
    incidentLocationAlias: {
        // rules: [
        //     r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        // ],
        defaults: {
            endpoint: 'incidentLocations.php?property=alias',
            rootProperty: 'incident_location_aliases'
        },
        ...standardCrud
    },
    schoolIncidentLocations: {
        // rules: [
        //     r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        // ],
        defaults: {
            endpoint: 'schoolIncidentLocations.php',
            rootProperty: 'school_incident_locations'
        },
        ...standardCrud
    },
    incidentMotivation: {
        // rules: [
        //     r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        // ],
        defaults: {
            endpoint: 'incidentMotivations.php',
            rootProperty: 'incident_motivations'
        },
        ...standardCrud
    },
    incidentField: {
        defaults: {
            endpoint: 'incidentField.php',
            rootProperty: 'incident_fields'
        },
        ...standardCrud
    },
    incidentFieldAlias: {
        defaults: {
            endpoint: 'incidentField.php?property=alias',
            rootProperty: 'incident_field_aliases'
        },
        ...standardCrud
    },
    incidentFieldDefinition: {
        defaults: {
            endpoint: 'crud.php?property=IncidentFieldDefinition',
            rootProperty: 'incident_field_definition'
        },
        ...standardCrud
    },
    incidentFieldOption: {
        defaults: {
            endpoint: 'incidentField.php',
            rootProperty: 'incident_field_options'
        },
        read: {
            params: { action: 'get', property: 'incident_field_options' }
        },
        create: {
            params: { action: 'create', property: 'incident_field_options' }
        },
        update: {
            params: { action: 'update', property: 'incident_field_options' }
        },
        destroy: {
            params: { action: 'delete', property: 'incident_field_options' }
        }
    },
    incidentBehaviorAlias: {
        // rules: [
        //     r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        // ],
        defaults: {
            endpoint: 'incidentBehavior.php?property=alias',
            rootProperty: 'incident_behavior_aliases'
        },
        ...standardCrud
    },
    incidentResponseAlias: {
        defaults: {
            endpoint: 'incidentResponses.php?property=alias',
            rootProperty: 'incident_response_aliases'
        },
        ...standardCrud
    },
    incidentBehaviorDescriptor: {
        // rules: [
        //     r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        // ],
        defaults: {
            endpoint: 'incidentBehaviorDescriptor.php',
            rootProperty: 'behavior_descriptors'
        },
        ...standardCrud
    }
}