<template>
    <fe-card
        class="alt-card ma-3"
        headerText="Recent Students"
        :menu="tools"
        :thumbnail="thumbnail"
    >
        <template v-slot:body>
            <div class="d-flex flex-wrap" v-if="photoURL">
                <fe-tooltip
                    v-for="item in studentImages"
                    :key="item.id"
                    :tooltip="item.student_full_name"
                >
                    <div class="ma-2 student-img"
                        @click="goToStudentProfile(item.id)"
                    >
                        <div :style="item.styles"></div>
                    </div>
                </fe-tooltip>
            </div>
        </template>
    </fe-card>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'StudentCard',
        data() {
            return {
                reloading: false,
                studentImages: [],
                photoURL: false,
                tools: [],
                thumbnail: {
                    icon: 'far fa-address-card'
                },
            }
        },
        computed: {
            ...mapState('global', ['uiSavedSearch', 'lastLaunchpadVisit']),
        },
        mounted() {
            this.loadData()
        },
        methods: {
            loadData() {
                this.$getUiSavedSearch('RECENT_STUDENTS')
                if(this.uiSavedSearch) {
                    this.generateSpriteSheet()
                } else {
                    this.reloading = false
                }
            },
            generateSpriteSheet() {
                let ss = this.uiSavedSearch.RECENT_STUDENTS || []
                let ids = ss?.length && ss[0].arguments.map(x => x.argument_value)

                if(ids) {
                    ids = this.$_.uniq(ids).slice(0,20)
                    // ids = [25163,26012,9230,9820,10108,33782] // appleton

                    // Students for whom an image cannot be generated for any reason can
                    // fall back to the generic silhouette
                    this.$fetchStudentImages(ids, 64, null, true, true).then((obj) => {
                        this.photoURL = obj.img

                        // Preserve the order of the saved search / recent students
                        this.studentImages = ids.map(studentId => obj.images.find(img => img.id == studentId)).filter(itm => !!itm).reverse()

                        this.reloading = false
                    })
                } else {
                    this.reloading = false
                }

            },
            goToStudentProfile(id) {
                if(id) {
                    this.$router.push({path: '/StudentProfile/' + id})
                }
            }
        },
        watch: {
            uiSavedSearch(v) {
                if(v) {
                    this.generateSpriteSheet()
                }
            },
            lastLaunchpadVisit(v) {
                if (!this.reloading) {
                    this.reloading = true
                    this.loadData()
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.student-img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
}
.simg {
    transform: translate(64);
}
</style>
