<template>
    <div class="flex-column pa-5" style="width: 100%; height: calc(100% - 100px)">
        <div class="d-flex fill-width">
            <fe-text-field placeholder="Search Saved Searches" v-model="filterText" prependIcon="fa-search" width="100%"/>
        </div>
        <div class="d-flex fill-width buttons">
            <v-spacer/>
            <fe-tooltip tooltip="Filter" class="d-inline-block">
                <v-menu
                    transition="slide-y-transition"
                    v-model="filters.show"
                    :close-on-content-click="false"
                    bottom
                    offset-y
                >
                    <template v-slot:activator="{ on }">
                        <fe-icon-btn v-on="on" :color="$vuetify.theme.themes.light.primary.base" class="ec-collection-icon material-icons" useIcon="filter_list"/>
                    </template>
                    <v-list class="ec-collection-filter-list">
                        <v-list-item v-for="opt in filterOptions" :key="opt">
                            <v-checkbox :input-value="filters.selections.find(itm => itm === opt)" @change="onToggleFilterOption(opt, $event)" :label="opt" hide-details/>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </fe-tooltip>
            <fe-tooltip tooltip="Sort" class="d-inline-block">
                <fe-icon-btn @click="onSortClick" :color="$vuetify.theme.themes.light.primary.base" :useIcon="sortIcon"></fe-icon-btn>
            </fe-tooltip>
        </div>
        <div style="flex: 1; overflow: auto; max-height: 100%;">
            <v-row>
                <v-col cols="4" v-for="q in sortedQueries" :key="q.id">
                    <v-card
                        class="elevation-0"
                        height="250"
                        style="border: 1px solid #E0E1E6;"
                        @click="loadSavedSearch(q)"
                    >
                        <v-card-title>
                            <v-spacer/>
                            <v-menu transition="slide-y-transition" :close-on-content-click="false" bottom offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on">more_vert</v-icon>
                                </template>
                                <v-list>
                                    <v-list-item @click="onRename(q)">Rename</v-list-item>
                                    <v-list-item @click="onDelete(q)">Delete</v-list-item>
                                </v-list>
                            </v-menu>
                        </v-card-title>
                        <v-card-text>
                            <div class="text-center pa-10">
                                <v-icon v-if="getSavedSearchIcons(q).length > 1" color="#9297A6" :style="{'font-size': ic===0 ? '28px' : '24px'} " v-for="(icon, ic) in getSavedSearchIcons(q)" :key="`icon`+ic" class="mr-1">{{icon}}</v-icon>
                                <v-icon v-else color="#9297A6" style="font-size: 40px;" v-for="(icon, ic) in getSavedSearchIcons(q)" :key="`icon`+ic" class="mr-1">{{icon}}</v-icon>

                                <div class="fs18 mt-10  ">{{q.name}}</div>
                                <div class="fs14 mt-2">{{ getSavedSearchType(q) }}</div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <fe-dialog
            v-if="renameDialog.show"
            title="Rename Query"
            width=600
            acceptButtonText="Save"
            @accept="doRename"
            @dismiss="renameDialog.show=false"
            :acceptButtonDisabled="!renameDialog.name"
        >
            <fe-label>Name for Saved Search</fe-label>
            <v-text-field flat solo dense v-model="renameDialog.name"/>
        </fe-dialog>

        <v-dialog v-if="editDialog.show" v-model="editDialog.show" width="90%" persistent>
            <district-stepper
                v-if="editDialog.component == 'charting' && editDialog.scope == 'district'"
                @close="editDialog.show = false"
                @apply="doBenchmarkChart(...arguments, 'District')"
                :state="editDialog.state"
                :value="editDialog.savedSearchParamsByDomain"
                surfaceFirstSelections
                :includeAttendance="editDialog.includeAttendance"
            />
            <grade-stepper
                v-if="editDialog.component == 'charting' && editDialog.scope == 'grade'"
                @close="editDialog.show = false"
                @apply="doBenchmarkChart(...arguments, 'Grade')"
                :state="editDialog.state"
                :value="editDialog.savedSearchParamsByDomain"
                surfaceFirstSelections
                :includeAttendance="editDialog.includeAttendance"
            />
            <course-stepper
                v-if="editDialog.component == 'charting' && editDialog.scope == 'class'"
                @close="editDialog.show = false"
                @apply="doBenchmarkChart(...arguments, 'Class')"
                :state="editDialog.state"
                :value="editDialog.savedSearchParamsByDomain"
                surfaceFirstSelections
                :includeAttendance="editDialog.includeAttendance"
            />
            <program-evaluation-stepper
                v-if="editDialog.component == 'program-evaluation'"
                @close="editDialog.show = false"
                :state="editDialog.state"
                :value="editDialog.savedSearchParamsByDomain"
                surfaceFirstSelections
            />
            <grade-stepper
                v-if="editDialog.component == 'data-wall' && editDialog.scope == 'grade'"
                @close="editDialog.show = false"
                @apply="doDataWall(...arguments, 'Grade Data Wall', 'Grade')"
                :state="editDialog.state"
                :value="editDialog.savedSearchParamsByDomain"
                surfaceFirstSelections
                :includeAttendance="editDialog.includeAttendance"
                incidentTypeOnly
                requireSubcategory
                multipleGrades
                cohort
            />
            <course-stepper
                v-if="editDialog.component == 'data-wall' && editDialog.scope == 'class'"
                @close="editDialog.show = false"
                @apply="doDataWall(...arguments, 'Class Data Wall', 'Class')"
                :state="editDialog.state"
                :value="editDialog.savedSearchParamsByDomain"
                surfaceFirstSelections
                :includeAttendance="editDialog.includeAttendance"
                incidentTypeOnly
                requireSubcategory
            />
            <scatter-landing
                v-if="editDialog.component == 'scatter-plot'"
                @close="editDialog.show = false"
                :state="editDialog.state"
                :value="editDialog.savedSearchParamsByDomain"
                surfaceFirstSelections
                :configJSON="editDialog.configJSON"
                :includeAttendance="editDialog.includeAttendance"
            />
            <longitudinal-landing
                v-if="editDialog.component == 'longitudinal'"
                @close="editDialog.show = false"
                :state="editDialog.state"
                :value="editDialog.savedSearchParamsByDomain"
                surfaceFirstSelections
                :configJSON="editDialog.configJSON"
            />
        </v-dialog>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    import * as Util from './Util'
    import scatterMixin from './mixins/scatter'
    import longitudinalMixin from './mixins/longitudinal'

    import DistrictStepper from './DistrictStepper'
    import GradeStepper from './GradeStepper'
    import CourseStepper from './CourseStepper'
    import ScatterLanding from './ScatterLanding'
    import ProgramEvaluationStepper from './ProgramEvaluationStepper'
    import LongitudinalLanding from './LongitudinalLanding'

    export default {
        name: 'AnalyticSavedSearches',
        mixins: [scatterMixin, longitudinalMixin],
        components: {
            DistrictStepper,
            GradeStepper,
            CourseStepper,
            ScatterLanding,
            LongitudinalLanding,
            ProgramEvaluationStepper,
        },
        data() {
            return {
                sortClick: 0,
                queries: [],
                filterText: '',
                filters: {
                    show: false,
                    options: [
                        'District Search',
                        'Grade Search',
                        'Class Search',
                        'Custom Grade Data Wall',
                        'Custom Class Data Wall',
                        'Scatter Plot',
                        'Longitudinal Chart',
                        'Program Evaluation',
                    ],
                    selections: [],
                },
                activeId: null,
                renameDialog: {
                    show: false,
                    name: '',
                },
                editDialog: {
                    show: false,
                },
            }
        },
        mounted() {
            this.filters.selections = [...this.filters.options]
            this.loadQueries()
        },
        computed: {
            ...mapState('global', ['pings']),
            sortedQueries() {
                let queries = this.filterText
                    ? this.queries.filter(itm => itm.name?.toLowerCase().indexOf(this.filterText.toLowerCase()) >= 0)
                    : this.queries

                queries = queries.filter(itm => this.filters.selections.includes(this.getSavedSearchType(itm)))

                return queries.sort((a, b) => {
                    let val = this.$dayjs(a.created) - this.$dayjs(b.created)
                    if (this.sortClick === 1) {
                        val *= -1
                    }
                    return -val
                })
            },
            sortIcon() {
                return this.sortClick === 0 ? 'fas fa-arrow-up' : 'fas fa-arrow-down'
            },
            filterOptions() {
                let options = this.filters.options

                if (!this.$hasSecurity('VIEW_ALL_SCHOOLS')) {
                    options = options.filter(s => s != 'District Search')
                }

                if (!this.$hasSecurity('PROGRAM_EVALUATION_DASHBOARD')) {
                    options = options.filter(s => s != 'Program Evaluation')
                }

                return options
            },
        },
        watch: {
            // If user modified a saved search, re-fetch data in case they renamed one
            'pings.analyticSavedSearches'(v) {
                this.loadQueries()
            },
        },
        methods: {
            loadQueries() {
                this.$axios.get('analyticSavedSearch.php?action=get&property=query&include_saved_search=false')
                    .then(r => {
                        this.queries = this.$ecResponse(r, 'queries').map(itm => ({
                            ...itm,
                            config_json: JSON.parse(itm.config_json || '{}')
                        }))
                    })
            },
            async fetchAnalyticSavedSearch(id) {
                let result = await this.$axios.get(`analyticSavedSearch.php?action=get&property=query&id=${id}`)
                    .then(r => {
                        return this.$ecResponse(r, 'queries').map(itm => ({
                            ...itm,
                            config_json: JSON.parse(itm.config_json || '{}')
                        }))
                    })

                return result.length ? result[0] : null
            },
            async fetchAnalyticSavedSearchParams(id) {
                let result = await this.$axios.get(`analyticSavedSearch.php?action=get&property=saved_params&analytic_saved_query_id=${id}`)
                    .then(r => this.$ecResponse(r, 'saved_params'))

                return result
            },
            onSortClick() {
                this.sortClick += 1
                if (this.sortClick > 1) {
                    this.sortClick = 0
                }
            },
            onToggleFilterOption(name, isChecked) {
                this.filters.selections = this.filters.selections.filter(itm => itm !== name)
                if (isChecked) {
                    this.filters.selections = [...this.filters.selections, name]
                }
            },
            getSavedSearchType(q) {
                let scope = q.config_json?.scope?.toLowerCase() || 'district'
                switch (q.config_json?.component) {
                    case 'charting':
                    case 'benchmark-chart':
                        return scope == 'district' ? 'District Search' : scope == 'grade' ? 'Grade Search' : 'Class Search'
                    case 'data-wall':
                        return scope == 'class' ? 'Custom Class Data Wall' : 'Custom Grade Data Wall'
                    case 'program-evaluation':
                        return 'Program Evaluation'
                    case 'scatter-plot':
                        return 'Scatter Plot'
                    case 'longitudinal':
                        return 'Longitudinal Chart'
                }

                // If this is a classic ui saved search, it doesn't have config json
                switch (q.analytic_saved_search_type_id) {
                    case 1:
                        return 'District Search'
                    case 2:
                        return 'Scatter Plot'
                    case 3:
                        return 'Longitudinal Chart'
                    default:
                        return ''
                }
            },
            getSavedSearchIcons(q) {
                let type = this.getSavedSearchType(q)

                switch (type) {
                    case 'District Search':
                        return ['fal fa-chart-bar']
                    case 'Grade Search':
                    case 'Class Search':
                        return ['fal fa-chart-bar', 'fal fa-table']
                    case 'Custom Class Data Wall':
                    case 'Custom Grade Data Wall':
                        return ['fal fa-table', 'fa-magnify']
                    case 'Program Evaluation':
                        return ['fal fa-chart-bar']
                    case 'Scatter Plot':
                        return ['fal fa-chart-scatter']
                    case 'Longitudinal Chart':
                        return ['fal fa-chart-line']
                    default:
                        return ['fal fa-table']
                }
            },
            getBaseIcon(q) {
                switch (q.analytic_saved_search_type_id) {
                    case 1:
                        return 'fal fa-chart-bar'
                        break
                    case 2:
                        return 'fal fa-chart-scatter'
                        break
                    case 3:
                        return 'fal fa-chart-line'
                        break
                }
            },
            getSecondaryIcon(scope) {
                switch (scope) {
                    case 'district':
                        return ''
                    case 'grade':
                        return 'fal fa-table'
                }
            },
            onRename(savedSearch) {
                this.activeId = savedSearch.id
                this.renameDialog = {
                    show: true,
                    name: savedSearch.name
                }
            },
            doRename() {
                this.renameDialog.show = false

                this.$axios.post('analyticSavedSearch.php?action=update&property=rename', {
                    queries: [{ id: this.activeId, name: this.renameDialog.name }]
                }).then(r => {
                    this.$store.commit('global/ping', 'analyticSavedSearches')
                })
            },
            onDelete(savedSearch) {
                this.$confirmDelete([1], () => {
                    this.$axios.post('analyticSavedSearch.php?action=destroy&property=query', {
                        queries: [{ id: savedSearch.id }]
                    }).then(r => {
                        this.$store.commit('global/ping', 'analyticSavedSearches')
                    })
                }, () => {
                })
            },
            /*
            analytic_saved_search_type_id
                1 = benchmark
                2 = scatter
                3 = line
            */
            async loadSavedSearch(q) {
                // Re-fetch saved search to get any changes to config json since index loaded
                q = await this.fetchAnalyticSavedSearch(q.id)

                let existingParams = await this.fetchAnalyticSavedSearchParams(q.id)

                let configJSON = q.config_json
                let scope = configJSON.scope?.toLowerCase()

                let compatibilityError = null

                if (!scope) {
                    switch (q.analytic_saved_search_type_id) {
                        case 1:
                            scope = 'district'
                            break
                        case 2:
                            scope = 'scatter'
                            break
                        case 3:
                            break
                    }
                }

                //  uppercase params can break API calls
                let p = []
                existingParams.forEach(ss => {
                    let tmp = {}
                    Object.keys(ss).forEach(k=>{
                        tmp[k.toLowerCase()] = ss[k]
                    })
                    p.push(tmp)
                })

                // Analytic saved searches created on the classic ui will not have
                // config json data.  They must be forward-ported to be compatible with new ui
                if (JSON.stringify(configJSON) == '{}') {
                    if (q.analytic_saved_search_type_id === 1) {
                        let translatedPopulationParams = { school_year_id: [], school_id: [], grade_id: [] }
                        let translatedAssessmentParams = { data_point_type_id: [], sub_category_id: [], data_point_name_id: [] }

                        for (let classicCriterion of p) {
                            for (let key in translatedPopulationParams) {
                                if (classicCriterion[key] && classicCriterion[key] instanceof Array) {
                                    translatedPopulationParams[key] = this.$_.uniq([...translatedPopulationParams[key], ...classicCriterion[key].map(itm => parseInt(itm))])
                                }
                            }

                            for (let key in translatedAssessmentParams) {
                                if (classicCriterion[key] && classicCriterion[key] instanceof Array) {
                                    translatedAssessmentParams[key] = this.$_.uniq([...translatedAssessmentParams[key], ...classicCriterion[key].map(itm => parseInt(itm))])
                                }
                            }
                        }

                        // Classic ui allows mixing school years, but this is not supported on new UI
                        if (translatedPopulationParams.school_year_id.length !== 1) {
                            compatibilityError = 'This analytic saved search is no longer supported because it contained multiple school years.'
                        }

                        // If exactly one grade was selected, we can convert this into a
                        // "Grade Search" and support viewing datawall data
                        else if (translatedPopulationParams.grade_id.length === 1) {
                            q.config_json = { scope: 'Grade', component: 'charting' }
                            configJSON = q.config_json
                            scope = 'grade'

                            // For Grade Searches, the population params must be cohort-based in new UI
                            translatedPopulationParams.cohort_school_year_id = translatedPopulationParams.school_year_id[0]
                            translatedPopulationParams.cohort_school_id = translatedPopulationParams.school_id
                            translatedPopulationParams.cohort_grade_id = translatedPopulationParams.grade_id[0]

                            delete translatedPopulationParams.school_year_id
                            delete translatedPopulationParams.school_id
                            delete translatedPopulationParams.grade_id

                            p = [
                                { ...translatedPopulationParams, saved_search_group_name: 'population' },
                                { ...translatedAssessmentParams, saved_search_group_name: 'assessment' },
                            ]
                        }

                        // If 0 grades or more than 1 grade was selected, then this must be
                        // a "District Search"
                        else {
                            q.config_json = { scope: 'District', component: 'charting' }
                            configJSON = q.config_json
                            scope = 'district'

                            translatedPopulationParams.school_year_id = translatedPopulationParams.school_year_id[0]

                            p = [
                                { ...translatedPopulationParams, saved_search_group_name: 'population' },
                                { ...translatedAssessmentParams, saved_search_group_name: 'assessment' },
                            ]
                        }
                    }

                    else if (q.analytic_saved_search_type_id === 2) {
                        let translatedPopulationParams = { school_year_id: [], school_id: [], grade_id: [] }
                        let advancedFilterParams = { }
                        let translatedAssessment1Params = { data_point_type_id: [], sub_category_id: [], data_point_name_id: [] }
                        let translatedAssessment2Params = { data_point_type_id: [], sub_category_id: [], data_point_name_id: [] }

                        // Any saved search key that is not population/assessment will be
                        // dumped into advanced filters (e.g. ethnicity)
                        let populationAndAssessmentKeys = [...Object.keys(translatedPopulationParams), ...Object.keys(translatedAssessment1Params)]

                        let assessmentNumber = 1 // 1 or 2 - always 2 data sets for a scatter plot
                        for (let classicCriterion of p) {
                            for (let key in translatedPopulationParams) {
                                if (classicCriterion[key] && classicCriterion[key] instanceof Array) {
                                    translatedPopulationParams[key] = this.$_.uniq([...translatedPopulationParams[key], ...classicCriterion[key].map(itm => parseInt(itm))])
                                }
                            }

                            // loop through keys in assessment 1 - the keys are identical in both assessment1 and assessment2
                            for (let key in translatedAssessment1Params) {
                                if (classicCriterion[key] && classicCriterion[key] instanceof Array) {
                                    if (assessmentNumber === 1) {
                                        translatedAssessment1Params[key] = this.$_.uniq([...translatedAssessment1Params[key], ...classicCriterion[key].map(itm => parseInt(itm))])
                                    } else {
                                        translatedAssessment2Params[key] = this.$_.uniq([...translatedAssessment2Params[key], ...classicCriterion[key].map(itm => parseInt(itm))])
                                    }
                                }
                            }

                            for (let key of Object.keys(classicCriterion).filter(itm => !populationAndAssessmentKeys.includes(itm))) {
                                if (classicCriterion[key] instanceof Array) {
                                    advancedFilterParams[key] = classicCriterion[key].join(',')
                                } else {
                                    //advancedFilterParams[key] = classicCriterion[key]
                                }
                            }

                            assessmentNumber += 1
                        }

                        p = [
                            { ...translatedPopulationParams, saved_search_group_name: 'population' },
                            { ...advancedFilterParams, saved_search_group_name: 'advanced-filters' },
                            { ...translatedAssessment1Params, saved_search_group_name: 'assessment1' },
                            { ...translatedAssessment2Params, saved_search_group_name: 'assessment2' },
                        ]

                        q.config_json = { scope: 'District', component: 'scatter-plot' }
                        configJSON = q.config_json
                        scope = 'district'
                    }

                    else if (q.analytic_saved_search_type_id === 3) {
                        let uncollatedTranslations = []
                        let dataSetSummaries = []

                        for (let classicCriterion of p) {
                            let groupId = `${Math.random()}`

                            let translatedPopulationParams = { school_year_id: [], school_id: [], grade_id: [] }
                            let advancedFilterParams = { }
                            let translatedAssessmentParams = { data_point_type_id: [], sub_category_id: [], data_point_name_id: [] }

                            // Any saved search key that is not population/assessment will be
                            // dumped into advanced filters (e.g. ethnicity)
                            let populationAndAssessmentKeys = [...Object.keys(translatedPopulationParams), ...Object.keys(translatedAssessmentParams)]

                            for (let key in translatedPopulationParams) {
                                if (classicCriterion[key] && classicCriterion[key] instanceof Array) {
                                    translatedPopulationParams[key] = this.$_.uniq([...translatedPopulationParams[key], ...classicCriterion[key].map(itm => parseInt(itm))])
                                }
                            }

                            // loop through keys in assessment 1 - the keys are identical in both assessment1 and assessment2
                            for (let key in translatedAssessmentParams) {
                                if (classicCriterion[key] && classicCriterion[key] instanceof Array) {
                                    translatedAssessmentParams[key] = this.$_.uniq([...translatedAssessmentParams[key], ...classicCriterion[key].map(itm => parseInt(itm))])
                                }
                            }

                            for (let key of Object.keys(classicCriterion).filter(itm => !populationAndAssessmentKeys.includes(itm))) {
                                if (classicCriterion[key] instanceof Array) {
                                    advancedFilterParams[key] = classicCriterion[key].join(',')
                                } else {
                                    //advancedFilterParams[key] = classicCriterion[key]
                                }
                            }

                            uncollatedTranslations.push(
                                { ...translatedPopulationParams, data_set_name: classicCriterion.series_name, saved_search_group_id: groupId, saved_search_group_name: 'population' },
                                { ...advancedFilterParams, saved_search_group_id: groupId, saved_search_group_name: 'advanced-filters' },
                                { ...translatedAssessmentParams, saved_search_group_id: groupId, saved_search_group_name: 'assessment' },
                            )

                            dataSetSummaries.push({ groupId: groupId, summaries: { isImportedFromClassic: true } })
                        }

                        p = uncollatedTranslations

                        q.config_json = { scope: 'District', component: 'longitudinal', dataSetSummaries: dataSetSummaries }
                        configJSON = q.config_json
                        scope = 'district'
                    }
                }

                // Backend, for each dataset, will return adv. demo values
                // in a syntax that is not directly compatible with score lookup
                // calls - the GET request keys must be in a precise format
                p = p.map(itm => {
                    let result = { ...itm }

                    if (result.demographic) {
                        let translations = this.$translateSavedSearchDemographicArgumentForDataLookup(result.demographic)
                        let demographics = {}

                        for (let obj of translations) {
                            if (!demographics[obj.key]) {
                                demographics[obj.key] = [obj.value]
                            } else {
                                demographics[obj.key].push(obj.value)
                            }
                            //result[obj.key] = obj.value
                        }

                        for (let key of Object.keys(demographics)) {
                            result[key] = demographics[key].join(',')
                        }

                        // After translation the array of demographic values and
                        // creating { demo_11=5, demo_12=gte:3 } type GET params, we can
                        // and should safely delete the `demographic` prop
                        delete result.demographic
                    }

                    return result
                })

                let savedSearchParams = p
                let populationParams, incidentParams, assessmentParams
                let advancedFilterParams = p.find(itm => itm.saved_search_group_name == 'advanced-filters') || {}

                advancedFilterParams = {
                    ...advancedFilterParams,
                    // Must use a workaround for inconsistency between FE/BE naming for intervention fields
                    intervention_level: advancedFilterParams.intervention_level_id || undefined,
                    intervention_exit_status: advancedFilterParams.intervention_exit_status_id || undefined,
                }

                if (compatibilityError) {
                    this.$messageBox({
                        title: 'Analytic Saved Search not Compatible with Early Access',
                        persistent: true,
                        maxWidth: 600,
                        message: compatibilityError,
                        actions: [{
                            text: 'Okay', primary: true,
                            onClick: () => {
                            }
                        }]
                    })
                } else if (scope == 'district' || scope == 'grade' || scope == 'class') {
                    let savedSearchParamsByDomain = Util.unpackSearchParams(p)

                    // because we don't save a value when they select "both," we need
                    // to set null or the default 'active' will override the saved search
                    if (savedSearchParamsByDomain.advancedFilterParams && !savedSearchParamsByDomain.advancedFilterParams.hasOwnProperty('student_active_flag')) {
                        savedSearchParamsByDomain.advancedFilterParams.student_active_flag = null
                    }

                    let collatedSavedSearches = this.$collateSavedSearchesBySavedSearchId(p)

                    this.editDialog = {
                        component: configJSON?.component,
                        scope: scope,
                        state: {
                            savedSearchId: q.id,
                            savedSearchName: q.name,
                        },
                        // Because longitudinals are a multitude of distinct data searches, the data
                        // for them must be uniquely fed in as an array of saved searches rather than one single unpacked version
                        savedSearchParamsByDomain: configJSON?.component == 'longitudinal' ? collatedSavedSearches : savedSearchParamsByDomain,
                        includeAttendance: !!configJSON?.include_attendance,
                        configJSON: configJSON || {},
                        show: true,
                    }
                }
            },
            doBenchmarkChart(opts, state, scope) {
                Util.doBenchmarkChart(this, opts, scope, null, { ...state, isDirty: true })
                this.editDialog.show = false
            },
            doDataWall(opts, state, title, scope) {
                Util.doDataWall(this, opts, title, scope, null, { ...state, isDirty: true })
                this.editDialog.show = false
            },
        }
    }
</script>

<style lang="scss" scoped>
.buttons {
    ::v-deep .v-btn {
        margin: 0 !important;
    }
    .material-icons {
        ::v-deep i {
            font-size: 24px !important;
        }
    }
}

.query-container {
    cursor: pointer;
    width: 200px;
    padding: 8px;
    border: 1px solid #DDDDDD;
    border-left: 8px solid #DDDDDD;
    border-radius: 5px;
    height: 60px;
    margin: 8px 8px 8px 8px;
    background: white;

    .query-title {
        font-weight: bold;
        width: 170px;
        overflow: hidden;
    }
}

.ec-show-more-less {
    border-bottom: 1px dotted;
}
</style>
