export default {
    watch: {
        windowSnap () { this.reflowAnyChartRefs() }
    },
    inject: {
        dockedWindowId: { from: 'dockedWindowId', default: null }
    },
    computed: {
        windowOptions () { return this.$store.state.global.dockableWindows.find(win => win.dockedWindowId === this.dockedWindowId) },
        windowSnap () { return this.windowOptions?.snap || 'full' }
    },
    methods: {
        setWindowTitle (title) {
            this.$store.commit('global/updateDockableWindow', {
                id: this.dockedWindowId,
                obj: { name: title }
            })
        },
        reflowAnyChartRefs () {
            Object.keys(this.$refs).filter(k => {
                let component = this.$refs[k]
                if (component?.$vnode?.componentOptions?.tag === 'highcharts') {
                    component.chart?.reflow()
                }
            })
        },
    }
}