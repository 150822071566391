<template>
    <div :style="style">
        <div class="flex-fill flex-column">
            <div class="d-flex justify-space-between align-center mb-2">
                <div class="flex-grow-1" :style="titleStyle">{{ title }}</div>
                <div class="flex-shrink-1 text-xs-right">
                    <v-btn icon small usage="ghost" @click="$emit('close')"><v-icon>close</v-icon></v-btn>
                </div>
            </div>
            <div class="flex-shrink-1"><slot name="aboveGrid" /></div>
            <div class="flex-fill">
                <fe-spinner v-if="!models" />
                <fe-grid
                    v-else-if="simpleInsertEnabled"
                    ref="grid"
                    :simpleInsert="field"
                    :simpleInsertConfig="fieldConfig"
                    :simpleInsertCounter="fieldCounter"
                    :simpleInsertDisabled="insertIsInvalid"
                    :simpleInsertPlaceholderText="fieldPlaceholder"
                    @simpleInsertChanged="insertValue = $event"
                    @simpleInsert="createItem"
                    @cellValueChanged="$emit('cellValueChanged',{...$event,crud:$refs.crud})"
                    displayDensity="medium"
                    :showToolbar="showToolbar"
                    :showAddRowBtn="false"
                    :showDownload="false"
                    :rowData="rowData"
                    :columnDefs="mergedDefs"
                    :insertDefaults="insertDefaults"
                    @deleted="deleted('deleted')"
                    :altSelectionTools="deletable"
                    style="height: calc(100% - 70px); width: 100%;"
                >
                    <template slot="selection-tools">
                        <fe-btn useIcon="fal fa-trash" usage="ghost" whiteText @click="massDestroy" class="fe-grid-action-btn">Delete</fe-btn>
                        <fe-btn usage="ghost" whiteText @click="$refs.grid.gridApi.deselectAll()" class="fe-grid-action-btn-last">Cancel</fe-btn>
                    </template>
                </fe-grid>
                <fe-grid
                    v-else
                    ref="grid"
                    @cellValueChanged="$emit('cellValueChanged',{...$event,crud:$refs.crud})"
                    displayDensity="medium"
                    :showToolbar="false"
                    :rowData="rowData"
                    :columnDefs="mergedDefs"
                    :insertDefaults="insertDefaults"
                    @deleted="deleted('deleted')"
                    :altSelectionTools="deletable"
                    style="height: 100%; width: 100%;"
                >
                    <template slot="selection-tools">
                        <fe-btn
                            useIcon="fal fa-trash"
                            usage="ghost"
                            whiteText
                            @click="massDestroy"
                            class="fe-grid-action-btn"
                        >
                            Delete
                        </fe-btn>
                    </template>
                </fe-grid>
            </div>
            <div class="flex-shrink-1"><slot name="belowGrid" /></div>
        </div>
        <fe-crud
            ref="crud"
            :autoload="true"
            :config="crudConfig"
            :defaultParams="defaultParams"
            :readParams="readParams"
            :createParams="createParams"
            :updateParams="updateParams"
            :destroyParams="destroyParams"
            @read="itemsLoaded"
        />
    </div>
</template>

<script>
    export default {
        name: 'SimpleGridDialogColumn',
        props: {
            cell: { type: Object, required: true },
            title: { type: String, required: true },
            field: { type: String },
            showField: { type: Boolean, default: true },
            subField: { type: String, default: null },
            fieldConfig: { type: Object, default: null },
            fieldHeader: { type: String, default: "Item", required: false },
            fieldCounter: { type: Number, default: null },
            fieldPlaceholder: { type: String, default: "Add an Item" },
            columnDefs: { type: Array, default: null },
            insertDefaults: { type: Object, default: null },
            crudConfig: { type: Object, default: null, required: true },
            defaultParams: { type: Object, default: null },
            readParams: { type: Object, default: null },
            createParams: { type: Object, default: null },
            updateParams: { type: Object, default: null },
            destroyParams: { type: Object, default: null },
            width: { type: String, default: '400px' },
            height: { type: String, default: '350px' },
            deleteTool: { type: Number, default: 0 },
            buildInsertRec: { type: Function, default: undefined },
            onRead: { type: Function, default: undefined },
            beforeDestroy: { type: Function, default: undefined },
            filter: { type: Function, default: undefined },
            suppressCreation: { type: Boolean, default: false },
            deletable: { type: Boolean, default: false },
            readOnly: { default: false },
            showToolbar: {default: false}
        },
        data() {
            return {
                models: null,
                cellData: null,
                insertValue: "",
                tools: [
                    { text: 'Delete', icon: 'fas fa-trash' },
                    { text: 'Remove', icon: 'fas fa-minus-circle' },
                ]
            }
        },
        computed: {
            simpleInsertEnabled() {
                return this.field && !this.readOnly && !this.fieldConfig?.insertDisabled
            },
            style() {
                return {
                    padding: '15px',
                    width: this.width,
                    height: this.height,
                }
            },
            titleStyle() {
                return {
                    'font-size': '20px',
                    'max-width': 'calc(100% - 40px)',
                    'white-space': 'nowrap',
                    'overflow': 'hidden',
                    'text-overflow': 'ellipsis',
                }
            },
            mergedDefs () {
                let icon = this.tools[this.deleteTool].icon
                let cols = this.field && this.showField
                    ? [{
                        headerName: this.fieldHeader,
                        field: this.field,
                        sortable: true,
                        editable: true,
                        autoHeight: true,
                        cellRenderer: this.subField ? this.renderWithSubField : undefined,
                    }]
                    : []
                this.columnDefs && cols.push(...this.columnDefs)
                !this.readOnly && cols.push({
                    headerName: this.tools[this.deleteTool].text,
                    field: 'id',
                    width: 95,
                    maxWidth: 95,
                    cellRenderer(v) {
                        return `<i class="${icon}" style="margin-left: 15px; font-size: 12px"></i>`
                    },
                    cellStyle() {
                        return {
                            cursor: 'pointer',
                            textAlign: 'left'
                        }
                    },
                    onCellClicked: (v) => this.destroyItem(v.data)
                })
                this.deletable && cols.unshift({
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        maxWidth: 70,
                        colId: 'checkbox_column'
                    })

                return cols
            },
            rowData() {
                return this.filter ? this.models.filter(this.filter) : this.models
            },
            insertIsInvalid() {
                return this.$_.isBlank(this.insertValue) || (!this.$_.isBlank(this.insertValue) && this.insertValue.length > this.fieldCounter)
            }
        },
        methods: {
            deleted() {
                /**
                 * Intentionally left blank to give the fe-grid the 'deletable' property
                **/
            },

            renderWithSubField(v) {
                return `${v.value}<div class="fe-grid-cell-subtext">${v.data[this.subField]}</div>`
            },
            createItem() {
                let val = this.insertValue
                let item = Object.assign({}, this.insertDefaults)
                if(this.buildInsertRec) {
                    item = this.buildInsertRec(val, this.insertDefaults)
                } else {
                    item[this.field] = val
                }
                this.$emit('creating', item)
                if (!this.suppressCreation) {
                    this.$refs.crud.create(item).then((rsp) => {
                        this.$emit('created', item)
                        this.loadItems()
                    })
                }
            },
            massDestroy() {
                let selected = this.$refs.grid.selectedRows()
                this.destroyItem(selected)
            },
            destroyItem(item) {
                this.$emit('persistCellDialog', true)
                let message = 'Are you sure you want to ' + this.tools[this.deleteTool].text.toLowerCase()
                    message += item.length > 1 ? ' these records' : ' this record'
                this.$messageBox({
                    title: 'Confirm ' + this.tools[this.deleteTool].text,
                    persistent: true,
                    maxWidth: 400,
                    message: message,
                    actions: [{
                        text: 'Cancel',
                        onClick: () => { this.$emit('persistCellDialog', false) }
                    }, {
                        text: this.tools[this.deleteTool].text, usage: this.deleteTool==0 ? 'danger' : 'primary',
                        onClick: () => {
                            this.$emit('persistCellDialog', false)
                            this.$emit('destroying', item)
                            item = this.beforeDestroy ? this.beforeDestroy(item) : item
                            if(item) {
                                this.$refs.crud.destroy(item).then((rsp) => {
                                    this.$emit('destroyed', item)
                                    this.loadItems()
                                })
                            }
                        }
                    }]
                })
            },
            loadItems() {
                this.insertValue = ""
                this.models = null
                this.$emit('reading')
                this.$refs.crud.read()
            },
            itemsLoaded(items) {
                this.models = this.onRead ? this.onRead(items) : items
                this.$emit('read', items)
            },
            getIdentifier(cell) {
                return ``
            },
            reload () {
                this.$refs.crud.read()
            }
        },
        watch: {
            cell (x) {
                this.$nextTick(() => this.loadItems())
            },
            rowData(v) {
                if(v) {
                    this.$emit('count',v.length)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
::v-deep .ag-center-cols-container {
    width: 100% !important;
}
</style>
