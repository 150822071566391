<template>
    <div class="sf-tooltip-root flex-column flex-fill" v-intersect="onShow">

        <fe-overlay
            :value="loading"
            loader
            text=""
        />

        <fe-grid 
            style="height: calc(100vh - 300px)"
            ref="grid"
            :key="gridChanged"
            :columnDefs="columnDefs"
            :rowData="templates"
            :bordered="false"
            :showAddRowBtn="false"
            :gridOptionOverrides="gridOptions"
            displayDensity="medium"
            disableInlineCreate
            @beginCreate="editRec"
            @rowDoubleClicked="selectTemplate($event.data)"
            @cellValueChanged="updateRec($event.data)"
        >
            <template #after-search-toolbar-items>
                <v-checkbox
                    v-model="showInactive"
                    label="Show Inactive"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    :style="{ marginTop: '6px' }"
                />
            </template>

            <template #toolbar-items v-if="$hasSecurity('CREATE_FORMS')">
                <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <fe-icon-btn
                            v-on="on"
                            useIcon="far fa-ellipsis-v"
                            class="smartforms-template-ellipsis-menu"
                        />
                    </template>
                    <v-list dense>
                        <v-list-item @click="openDefault = true">
                            <v-list-item-content>
                                {{ hasDefault ? 'Update Default Permissions' : 'Create Default Permissions'}}
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>

            <template #cellDialog="{cell}">
                <v-list-item @click="editRec({rec:cell.data})" :disabled="!canEdit(cell.data)">Rename</v-list-item>
                <v-list-item @click="editRec({rec:cell.data, clone:'local'})">Duplicate</v-list-item>
                <v-list-item @click="editRec({rec:cell.data, clone:'to_the_cloud'})" :disabled="!canEdit(cell.data)">Upload to Climber Cloud</v-list-item>
                <v-list-item @click="openFormReporting(cell.data)">Created Forms</v-list-item>
                <v-list-item @click="carryOverRec(cell.data)" v-if="$hasSecurity('MANAGE_ALL_FORM_TEMPLATES') && cell.data.form_restrictions">Carry Over to Next Year</v-list-item>
                <v-list-item @click="openSearch(cell.data)" v-if="exportFormDataEnabled && $hasSecurity('EXPORT_FORMS_DATA')">Export Data</v-list-item>
                <v-list-item @click="deleteRec(cell.data)" :disabled="!canEdit(cell.data)">Delete</v-list-item>
            </template>

        </fe-grid>


        <add-template v-if="addDialog.show"
            :value="addDialog.rec"
            :clone="addDialog.clone"
            @create="createRec" @update="updateRec"
            @close="addDialog.show=false"
        />

        <fe-crud ref="crud" :config="$models.form" @read="onRead" :read-params="{all:showInactive||undefined}" refreshOnConfig/>

        <fe-dialog
            v-if="manageModal || openDefault"
            :title="openDefault ? 'smartFORM Template Default Permissions' : 'Manage Form Restrictions for ' + getTitle"
            persistent
            @close="onCloseRestrictions"
            @accept="onCloseRestrictions"
            @dismiss="onCloseRestrictions"
            width="800"
            height="600"
            :acceptButtonText="openDefault ? 'Save' : 'Close'"
            noBodyPadding
            disableAutoclose
            :forceClose="false"
        >
            <div style="height: 100%; overflow: hidden; padding: 0 20px 20px 20px">
                <div class="d-flex mt-3" v-if="!openDefault">
                    <fe-label>
                        Restrict who can create, edit, or delete forms from this smartFORM template.<br/>
                        Please note that turning this on will automatically turn on restrictions for all form instances of this template.
                    </fe-label>
                    <v-spacer/>
                    <fe-switch :value="showRestrictions[templateId]" useIcon="check" style="margin-left: 50px" @input="onTurnOnRestrictions"/>
                </div>
                <div class="d-flex mb-2" v-else>
                    Configure default permissions for smartFORM Templates below.
                    When form restrictions are enabled for a template, these default permissions will be automatically applied, but can be manually changed on the template instance.
                </div>
                <div style="height: 400px">
                    <v-divider />
                    <fe-tabs
                        :tabs="tabs"
                        @tabClicked="tabClicked"
                        no-padding
                        style="height: 60px;"
                    />
                    <fe-grid
                        v-if="selectedTab.type == 'user'"
                        ref="manageGrid"
                        style="height: calc(100% - 45px)"
                        class="smartforms-manage-form-restrictions-grid"
                        domLayout="normal"
                        :columnDefs="manageColumnDefs"
                        :rowData="openDefault ? defaultUsers : manageRowData[templateId]"
                        :showDownload="false"
                        :showAddRowBtn="false"
                        :gridOptionOverrides="manageGridOptions"
                    >
                        <template v-slot:toolbar-items>
                            <div class="d-flex align-center">
                                <fe-btn usage="tertiary" @click="addUsers = true">Add User</fe-btn>
                                <fe-info-tip
                                    tooltip="If a user from a user group is also added as an individual user, we will give them the highest level of permissions from each setting."
                                    direction="top" color="#7E8494" icon="far fa-info-circle" size="16px"
                                />
                            </div>
                        </template>
                    </fe-grid>
                    <div v-else style="height: 400px">
                        <fe-grid
                            ref="manageGroupsGrid"
                            style="height: calc(100% - 95px)"
                            class="smartforms-manage-form-restrictions-grid"
                            domLayout="normal"
                            :columnDefs="manageGroupColumnDefs"
                            :rowData="openDefault ? defaultGroups : manageRowGroupData[templateId]"
                            :showDownload="false"
                            :showAddRowBtn="false"
                            :gridOptionOverrides="manageGridOptions"
                        ></fe-grid>
                        <div class="d-flex">
                            <fe-remote-combo
                                :items="openDefault ? userGroupsSelectableDefault : userGroupsSelectable[templateId]"
                                v-model="newGroups"
                                multiple
                                placeholder="Add User Group"
                                style="width: 100%; margin-top: 14px"
                                hide-details
                                moreText
                            />
                            <div class="d-flex align-center mt-2">
                                <fe-btn usage="tertiary" @click="onAddSecurity('group')">Add</fe-btn>
                                <fe-info-tip
                                    tooltip="If a user from a user group is also added as an individual user, we will give them the highest level of permissions from each setting."
                                    direction="top" color="#7E8494" icon="far fa-info-circle" size="16px"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fe-dialog>

        <fe-dialog
            v-if="addUsers"
            title="Find Users"
            width="700"
            height="70vh"
            acceptButtonText="Select"
            dismissButtonText="Cancel"
            dismissable
            persistent
            @close="onAddSecurity"
        >
            <user-search v-model="newUsers" :users="openDefault ? previouslySelectedUsersDefault : previouslySelectedUsers[templateId]" />
        </fe-dialog>

        <fe-dialog
            v-if="confirmChange"
            persistent
            title="Confirm Form Restrictions Change"
            width="800"
            acceptButtonText="Confirm"
            dismissButtonText="Cancel"
            @close="confirmChange=false;showRestrictions[templateId]=true"
            @accept="onTurnOffRestrictions"
        >
            <div>
                You are attempting to unrestrict access to this smartFORM template. Upon doing so, all users and user
                groups will have unrestricted access to view, edit, create, and delete instances of this smartFORM template.
            </div>
            <div>
                Are you sure you want to make this change?
            </div>
        </fe-dialog>

        <fe-dialog
            v-if="confirmNoChange"
            persistent
            title="Confirm to Not Turn on Restrictions"
            width="800"
            acceptButtonText="Return"
            dismissButtonText="Close"
            @close="onCloseNoChange"
            @dismiss="onCloseNoChange"
            @accept="confirmNoChange=false"
        >
            <div>
                You are attempting to close this modal without turning on restrictions. If you would like to publish the restrictions set from
                the table, please toggle on restrictions.
            </div>
        </fe-dialog>

        <fe-dialog
            v-if="openModify"
            persistent
            title="Modify smartFORM Template Default Permissions?"
            width="800"
            acceptButtonText="Save & Continue"
            dismissButtonText="Close"
            @close="openModify=false"
            @dismiss="openModify=false"
            @accept="onCloseDefault"
        >
            <div>
                You are attempting to modify the default permissions that were previously configured and saved.<br/>
                These changes will only apply, moving forward, to new forms after restrictions are enabled.
            </div>
        </fe-dialog>

        <search v-if="search.show"
            :value="search.rec"
            @close="search.show = false"
            @accept="openExportData"
        />

        <export-data v-if="exportData.show"
            :value="exportData.rec"
            @close="exportData.show=false"
        />

        <fe-crud ref="userGroupCrud" :config="userGroupCrudConfig" />

        <fe-dialog
            v-if="carryOver.show"
            persistent
            :title="carryOver.rec.name + ' - Carry Over?'"
            width="640"
            acceptButtonText="Yes, Carry Over"
            dismissButtonText="Cancel"
            @close="onCloseCarryOver"
            @dismiss="onCloseCarryOver"
            @accept="onCarryOver"
        >
            <div >
                Carry over {{ carryOver.rec.name }} along with its <span style="font-weight: bold">{{ carryOver.instancesToCarryOver }} instances</span>?
            </div>
            <div class="mt-3 mb-3">
                <span style="color: #7E8494">({{ carryOver.instancesNotToCarryOver }} instances of this form will not be carried over due to their status)</span>
            </div>
            <v-divider />
            <div class="mt-3">
                <span style="font-weight: bold">Please note: smartFORMS should only be carried over after the new school year roster data has been imported.</span>
                When a smartFORM is carried over, a form for the new school year will be created and all form instances associated with the prior year will be locked,
                archived, and available to view on the first day of the new school year.
            </div>
        </fe-dialog>

    </div>
</template>

<script>
import Vuex from 'vuex'
import { mapLocalState } from '@/util/vuexHelper'
import SmartFormTemplate from './SmartFormTemplate'
import AddTemplate from './AddTemplate'
import FormReport from '../reporting/FormReport'
import TitleColumn from './TitleColumn'
import SimpleGridDialogColumn from "../../../common/SimpleGridDialogColumn"
import UserSearch from '@/components/common/UserSearch'
import Toggle from './Toggle'
import ExportData from '../export/ExportData'
import Search from '../export/Search'
import Vue from 'vue'

export default {
    name: 'TemplateList',

    components: {
        AddTemplate,
        FormReport,
        SimpleGridDialogColumn,
        UserSearch,
        ExportData,
        Search,
    },

    data() {
        const cellStyleFn = v => this.canEdit(v.data)
            ? {
                opacity: '1',
                pointerEvents: 'all',
            } : {
                opacity: '.4',
                pointerEvents: 'none',
            };
        let me = this

        return {
            gridChanged: 0,
            showInactive: 0,
            addDialog: {
                show: false,
                rec: null,
                clone: false,
            },
            templates: [],
            gridOptions: {
                context: { componentParent: this },
                immutableData: true,
                getRowNodeId: data => data.id,
                suppressHorizontalScroll: true,
                scrollbarWidth: 0
            },
            columnDefs: [{
                headerName: 'Title and Description',
                field: 'name',
                autoHeight: true,
                cellRendererFramework: TitleColumn,
            }, {
                headerName: 'Live Template',
                field: 'live',
                cellStyle: {textAlign: 'center'},
                cellRenderer: v => v.data.live ? '<i class="fe-grid-icon fas fa-check theme--light"></i>' : '',
                maxWidth:120,
                hide: true,
            }, {
                headerName: 'Intervention Form', field: 'intervention_form', cellRendererFramework: 'FeGridToggle', maxWidth:150,
                cellStyle: cellStyleFn,
            }, {
                headerName: 'Active', field: 'disabled', cellRendererFramework: 'FeGridToggle', maxWidth:80,
                cellStyle: cellStyleFn,
                valueGetter: v => !v.data.disabled,
                valueSetter: v => {
                    v.data.disabled = !v.newValue
                    return true
                },
            }, {
                headerName: 'Form Restrictions',
                field: 'form_restrictions',
                cellRendererFramework: 'FeDialogColumn',
                maxWidth:150,
                cellStyle: cellStyleFn,
                onCellClicked: v => {
                    this.onCellClicked(v)
                },
                hide: !this.$hasSecurity('CREATE_FORMS'),
            }, {
                headerName: 'Created By',
                field: 'created_by_full_name',
                maxWidth: 200,
                cellStyle: { color: '#9297A6', fontSize: '12px' }
            }, {
                headerName: 'Created',
                field: 'created',
                maxWidth: 200,
                cellStyle: { color: '#9297A6', fontSize: '12px' }
            }, {
                colId: 'toolmenu',
                maxWidth: 50,
                cellRenderer: v => {
                    return '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>'
                },
                onCellClicked: v => {
                    this.$refs.grid.setDialogCell(v)
                },
                cellStyle : { display: 'flex' }
            }],
            cell: undefined,
            manageModal: false,
            tabs: [
                {
                    name: 'Users (0)',
                    path: '#',
                    type: 'user'
                }, {
                    name: 'User Groups (0)',
                    path: '#',
                    type: 'group'
                }
            ],
            selectedTab: {
                name: 'Users (0)',
                path: '#',
                type: 'user'
            },
            manageColumnDefs: [
                {
                    headerName: 'User',
                    field: 'user_full_name',
                    minWidth: 100,
                },
                {
                    headerName: 'Can View',
                    maxWidth: 80,
                    minWidth: 80,
                    field: 'can_view',
                    cellRendererFramework: Toggle,
                    editable: false,
                    onCellClicked: function(v) {
                        me.onEditSecurity(v.data, 'user')
                    },
                    cellStyle() {
                        return {
                            'padding-left': '5px'
                        }
                    },
                },
                {
                    headerName: 'Can Edit',
                    maxWidth: 80,
                    minWidth: 80,
                    field: 'can_edit',
                    cellRendererFramework: Toggle,
                    editable: false,
                    onCellClicked: function(v) {
                        me.onEditSecurity(v.data, 'user')
                    },
                    cellStyle() {
                        return {
                            'padding-left': '5px'
                        }
                    },
                },
                {
                    headerName: 'Can Create',
                    maxWidth: 80,
                    minWidth: 80,
                    field: 'can_create',
                    cellRendererFramework: Toggle,
                    editable: false,
                    onCellClicked: function(v) {
                        me.onEditSecurity(v.data, 'user')
                    },
                    cellStyle() {
                        return {
                            'padding-left': '5px'
                        }
                    },
                },
                {
                    headerName: 'Can Delete',
                    maxWidth: 80,
                    minWidth: 80,
                    field: 'can_delete',
                    cellRendererFramework: Toggle,
                    editable: false,
                    onCellClicked: function(v) {
                        me.onEditSecurity(v.data, 'user')
                    },
                    cellStyle() {
                        return {
                            'padding-left': '5px'
                        }
                    },
                },
                {
                    headerName: 'Can Share',
                    maxWidth: 80,
                    minWidth: 80,
                    field: 'can_share',
                    cellRendererFramework: Toggle,
                    editable: false,
                    onCellClicked: function(v) {
                        me.onEditSecurity(v.data, 'user')
                    },
                    cellStyle() {
                        return {
                            'padding-left': '5px'
                        }
                    },
                },
                {
                    headerName: 'Delete',
                    maxWidth: 80,
                    cellRenderer(v) {
                        return !v.data.user_full_name.includes('owner') ? '<i class="fas fa-trash" style="font-size: 18px; color: #7E8494"></i>' : ''
                    },
                    cellStyle(v) {
                        if(!v.data.user_full_name.includes('owner')) {
                            return {
                                'cursor': 'pointer',
                                'text-align': 'center'
                            }
                        }
                    },
                    onCellClicked: function(v) {
                        me.onRemoveUser(v.data, 'user')
                    }
                }
            ],
            manageGroupColumnDefs: [
                {
                    headerName: 'User Groups',
                    field: 'user_group_name',
                    minWidth: 100,
                },
                {
                    headerName: 'Can View',
                    maxWidth: 80,
                    minWidth: 80,
                    field: 'can_view',
                    cellRendererFramework: Toggle,
                    editable: v => !v.data.user_group_name.includes('Site Administrators'),
                    onCellClicked: function(v) {
                        me.onEditSecurity(v.data, 'user_group')
                    },
                    cellStyle() {
                        return {
                            'padding-left': '5px'
                        }
                    },
                },
                {
                    headerName: 'Can Edit',
                    maxWidth: 80,
                    minWidth: 80,
                    field: 'can_edit',
                    cellRendererFramework: Toggle,
                    editable: v => !v.data.user_group_name.includes('Site Administrators'),
                    onCellClicked: function(v) {
                        me.onEditSecurity(v.data, 'user_group')
                    },
                    cellStyle() {
                        return {
                            'padding-left': '5px'
                        }
                    },
                },
                {
                    headerName: 'Can Create',
                    maxWidth: 80,
                    minWidth: 80,
                    field: 'can_create',
                    cellRendererFramework: Toggle,
                    editable: v => !v.data.user_group_name.includes('Site Administrators'),
                    onCellClicked: function(v) {
                        me.onEditSecurity(v.data, 'user_group')
                    },
                    cellStyle() {
                        return {
                            'padding-left': '5px'
                        }
                    },
                },
                {
                    headerName: 'Can Delete',
                    maxWidth: 80,
                    minWidth: 80,
                    field: 'can_delete',
                    cellRendererFramework: Toggle,
                    editable: v => !v.data.user_group_name.includes('Site Administrators'),
                    onCellClicked: function(v) {
                        me.onEditSecurity(v.data, 'user_group')
                    },
                    cellStyle() {
                        return {
                            'padding-left': '5px'
                        }
                    },
                },
                {
                    headerName: 'Can Share',
                    maxWidth: 80,
                    minWidth: 80,
                    field: 'can_share',
                    cellRendererFramework: Toggle,
                    editable: v => !v.data.user_group_name.includes('Site Administrators'),
                    onCellClicked: function(v) {
                        me.onEditSecurity(v.data, 'user_group')
                    },
                    cellStyle() {
                        return {
                            'padding-left': '5px'
                        }
                    },
                },
                {
                    headerName: 'Delete',
                    maxWidth: 80,
                    cellRenderer(v) {
                        return !v.data.user_group_name.includes('Site Administrators') ? '<i class="fas fa-trash" style="font-size: 18px; color: #7E8494"></i>' : ''
                    },
                    cellStyle(v) {
                        if(!v.data.user_group_name.includes('Site Administrators')) {
                            return {
                                'cursor': 'pointer',
                                'text-align': 'center'
                            }
                        }
                    },
                    onCellClicked: function(v) {
                        me.onRemoveUser(v.data, 'user_group')
                    }
                }
            ],
            manageRowData: {},
            manageRowGroupData: {},
            addUsers: false,
            manageGridOptions: {
                context: { componentParent: this }
            },
            newUsers: [],
            newGroups: [],
            confirmChange: false,
            showRestrictions: {},
            exportData: {
                show: false,
                rec: null,
            },
            canExportData: {},
            dataIds: {},
            search: {
                show: false,
                rec: null,
            },
            templateId: null,
            loading: false,
            previouslySelectedUsers: {},
            previouslySelectedGroups: {},
            userGroupsSelectable: {},
            confirmNoChange: false,
            openDefault: false,
            defaultUsers: [],
            defaultGroups: [],
            hasDefault: false,
            userGroupsSelectableDefault: [],
            origDefault: [],
            openModify: false,
            previouslySelectedUsersDefault: [],
            carryOver: {
                show: false,
                rec: null,
                instancesToCarryOver: 0,
                instancesNotToCarryOver: 0,
            },
        }
    },

    computed: {
        sessionUser: me => me.$store.state.global.sessionUser.user,
        liveTemplatesEnabled: me => me.$store.getters['flags/flags']['ec-live-template'] === true,
        exportFormDataEnabled () { return this.$store.getters['flags/flags']['ec-export-forms-data'] === true },
        userGroupCrudConfig() { return this.$_.cloneDeep(this.$models.userGroup) },
        getTitle() {
            let val = this.cell.data.name
            if(val.toString().length > 40) {
                val = val.toString().slice(0, 40) + '...'
            }
            return val
        },
    },

    watch: {
        'selectedTab.type'(v) {
            if(v == 'group') {
                this.$refs.userGroupCrud.read()
                    .then(response => {
                        response.data.user_group.forEach(group => {
                            if(!this.openDefault) {
                                if(!this.previouslySelectedGroups[this.cell.data.id].find(x => x.id == group.id)) this.userGroupsSelectable[this.cell.data.id].push(group)
                            }
                        })
                    })
            }
        },
        openDefault(v) {
            if(v) {
                if(!this.hasDefault) {
                    Vue.set(this.tabs[0], 'name', 'Users (0)')
                    this.defaultUsers = []
                    this.defaultGroups = [
                        {
                            user_group_id: 2,
                            user_group_name: 'Site Administrators',
                            can_create: 1,
                            can_view: 1,
                            can_edit: 1,
                            can_delete: 1,
                            can_share: 1,
                        }
                    ]
                    Vue.set(this.tabs[1], 'name', 'User Groups (1)')
                }
                this.$refs.userGroupCrud.read()
                    .then(response => {
                        response.data.user_group.forEach(group => {
                            if(!this.defaultGroups.find(x => x.user_group_id == group.id)) this.userGroupsSelectableDefault.push(group)
                        })
                    })
            }
        }
    },

    methods: {
        onShow(e) {
            e.some(x => x.isIntersecting) && setTimeout(this.$refs.crud.read,400)
        },
        async onRead(e) {
            this.loading = true
            var hasLive = e.some(x => x.live)
            var liveCol = this.columnDefs.find(x => x.field == 'live')
            if(liveCol.hide != !hasLive) {
                liveCol.hide = !hasLive
                this.gridChanged += 1
            }
            this.templates = e
            // switch to promise so that user can't interact with grid until all done loading
            const promises = e.map(t => this.loadSecurity(t))
            await Promise.all(promises)
            // check if default template permissions exist
            this.$axios.get('form.php?property=permissions_template&action=get')
                .then((resp) => {
                    if (resp.data.template.length) {
                        this.defaultUsers = []
                        this.defaultGroups = []
                        this.origDefault = []
                        this.previouslySelectedUsersDefault = []
                        this.hasDefault = true
                        resp.data.template.forEach(permission => {
                            if(permission.user_id) {
                                this.defaultUsers.push(permission)
                                this.previouslySelectedUsersDefault.push(permission)
                            } else {
                                this.defaultGroups.push(permission)
                            }
                            this.origDefault.push(permission)
                        })
                        Vue.set(this.tabs[0], 'name', 'Users (' + this.defaultUsers.length + ')')
                        Vue.set(this.tabs[1], 'name', 'User Groups (' + this.defaultGroups.length + ')')
                    }
                })
            this.loading = false
        },
        canEdit(rec) {
            // ECWS-547 made it so that only the current user is returned in users_with_access 
            // only compares whether the single user returned matches session user
            return rec.users_with_access.find(x => this.sessionUser.id == x.id)?.can_edit
        },
        selectTemplate(rec) {
            if(this.canEdit(rec)) {
                this.$store.commit('global/addDockableWindow', {
                    name: rec.name,
                    component: 'SmartFormTemplate',
                    attrs: {
                        template: rec,
                    },
                    events: {
                        reload: this.$refs.crud.read,
                    },
                    titleSlotComponent: {
                        data() {
                            return {
                                name: 'EditPreviewBtn',
                                editing: true,
                                callback: () => {},
                            }
                        },
                        methods: {
                            edit(v) {
                                if(v != this.editing) this.editing = v
                                this.callback(this.editing)
                            }
                        },
                        template: `
                        <div class="d-inline-flex" style="width:calc(50% + 120px);">
                            <div class="fe-window-title"> ${rec.name} </div>
                            <div ref="group" class="ec-group-nav ml-auto">
                                <fe-btn usage="secondary" @click="edit(true)" class="ec-group-nav-option first title-bar" :class="{selected:editing}">Edit Mode</fe-btn>
                                <fe-btn usage="secondary" @click="edit(false)" class="ec-group-nav-option last title-bar" :class="{selected:!editing}">Preview Mode</fe-btn>
                            </div>
                        </div>
                        `
                    },
                })
            } else {
                this.$messageBox({
                    title: 'Insufficient Permission',
                    persistent: true,
                    maxWidth: '500px',
                    message: 'You do not have editing rights to this smartFORM template.',
                    actions: [{
                        text: 'Close',
                    }]
                })
            }
        },
        openFormReporting(e) {
            this.$refs.grid.openMenu = false
            this.$dockableWindow({
                name: e.name,
                component: 'form-report',
                attrs: {
                    templateId: e.id,
                    class: 'pa-4',
                },
            })
        },
        cloneRec(v) {
            this.$refs.crud.create({
                name: v.name,
                description: v.description,
                source_form_id: v.id,
                live: v.live ? 1 : 0,
            },{
                property: 'clone_form',
                to_the_cloud: this.addDialog.clone == 'to_the_cloud' ? 1 : undefined,
            }).then( r => {
                this.$refs.crud.read().then( r2 => {
                    if(this.addDialog.clone == 'local') {
                        let newId = r.data.ids[0]
                        let index = r2.data.forms.findIndex(x => x.id == newId)
                        this.$nextTick(() => {
                            this.$refs.grid.gridApi.ensureIndexVisible(index)
                            this.$flashGridRow(this.$refs.grid,index,3000)
                        })
                    }
                })
            }).finally( () => this.addDialog.show = false )
        },
        editRec({rec=null,clone=null}={}) {
            this.addDialog = {
                show: true,
                rec: rec,
                clone: clone,
            }
        },
        createRec(v) {
            this.$refs.crud.create(v).then( r => {
                this.addDialog.show = false
                this.$refs.crud.read().then( r => {
                    // find last id added
                    let t = r.data.forms.reduce((max,x) => x.id > max.id ? x : max, {id:0})
                    if(t.id) this.selectTemplate(t)
                })
            })
        },
        updateRec(v) {
            if(this.addDialog.clone) this.cloneRec(v)
            else {
                this.$refs.crud.update(v).finally( () => {
                    this.$refs.crud.read().finally( () => this.addDialog.show = false )
                })
            }
        },
        deleteRec(v) {
            this.$confirmDelete(v,() => {
                this.$refs.crud.destroy(v).then( r => {
                    this.templates = this.templates.filter( x => x.id !== v.id)
                })
            })
        },
        async onCellClicked(cell) {
            this.cell = cell
            this.templateId = cell.data.id
            this.manageModal = true
            // if no existing permissions and if default template, apply template
            if (this.hasDefault && !cell.data.form_restrictions > 0) {
                this.$axios.post('form.php?action=update&property=apply_template&form_id=' + cell.data.id)
                    .then(() => {
                        this.setInitial()
                    })
            } else {
                // re-grab form data so you don't have to reload every template after restriction changes
                this.$axios.get('form.php?action=get&form_id=' + cell.data.id)
                    .then((resp) => {
                        Vue.set(this.showRestrictions, cell.data.id, resp.data.forms[0].instance_restricted)
                        this.$axios.get('crud.php?property=FormManagementUserSecurity&action=get&form_id=' + cell.data.id)
                            .then((response) => {
                                // if no users yet exist, then set the initial;
                                // otherwise update in case the new can_share hasn't been pushed
                                if (!response.data.form_management_user_security.length) {
                                    this.setInitial()
                                } else {
                                    this.setInitial(true)
                                }
                            })
                    })
            }
        },
        async loadSecurity(template = null, off = false, rowNode = null, reset = false, userGroupId = null) {
            let id = null
            if (!template) {
                id = this.cell?.data.id
            }
            else {
                id = template.id
                template.form_restrictions = 0
            }
            this.manageRowData[id] = []
            this.manageRowGroupData[id] = []
            this.previouslySelectedUsers[id] = []
            this.previouslySelectedGroups[id] = []
            this.userGroupsSelectable[id] = []
            let getGroups = null
            let getUsers = null
            // only make calls if the instance is restricted (otherwise it will make calls to return null count)
            if (!template || template && template.instance_restricted) {
                getUsers = await this.$axios.get('crud.php?property=FormManagementUserSecurity&action=get&form_id=' + id)
                    .then((response) => {
                        if(!off) {
                            return response.data.form_management_user_security
                        }
                    })
                getGroups = await this.$axios.get('crud.php?property=FormManagementUserGroupSecurity&action=get&form_id=' + id)
                    .then((response) => {
                        if(!off) {
                            return response.data.form_management_user_group_security
                        }
                    })
                getUsers.forEach(user => {
                    if(!this.manageRowData[id].find(x => x.user_id == user.user_id)) {
                        this.manageRowData[id].push(user)
                        this.previouslySelectedUsers[id].push(user)
                    }
                })
                getGroups.forEach(group => {
                    if(!this.manageRowGroupData[id].find(x => x.user_group_id == group.user_group_id)) {
                        this.manageRowGroupData[id].push(group)
                        this.previouslySelectedGroups[id].push({
                            name: group.user_group_name,
                            id: group.user_group_id
                        })
                    }
                })
            }
            if (!template) {
                // always have owner be first user
                let owner = this.manageRowData[id].find(row => row.user_id === this.cell.data.created_by)
                if (owner) {
                    owner.user_full_name = owner.user_full_name.includes('owner') ? owner.user_full_name : owner.user_full_name + ' (owner)'
                    this.manageRowData[id] = this.manageRowData[id].filter(item => item.user_id !== owner.user_id)
                    this.manageRowData[id].unshift(owner)
                }
                // always have site administrator be first user group
                let group = this.manageRowGroupData[id].find(row => row.user_group_name === 'Site Administrators')
                if (group) {
                    this.manageRowGroupData[id] = this.manageRowGroupData[id].filter(item => !item.user_group_name.includes('Site Administrators'))
                    this.manageRowGroupData[id].unshift(group)
                }

                if (reset) {
                    // if resetting owner/site administrator to make sure that can_share is added
                    // then want to pass can_share and then reload (to grab 1) by recalling same function
                    let action = 'update'
                    let transactions = []
                    if (owner && !owner.can_share) {
                        let userSec = [{
                            form_id: this.cell.data.id,
                            user_id: this.cell.data.created_by,
                            can_share: 1,
                            id: owner.id
                        }]
                        transactions.push([this.$axios.post('crud.php?property=FormManagementUserSecurity&action=' + action, {form_management_user_security: userSec})])
                    }
                    if (group && !group.can_share) {
                        let groupSec = [{
                            form_id: this.cell.data.id,
                            user_group_id: userGroupId,
                            can_share: 1,
                            id: group.id
                        }]
                        transactions.push([this.$axios.post('crud.php?property=FormManagementUserGroupSecurity&action=' + action, {form_management_user_group_security: groupSec})])
                    }
                    Promise.all(transactions).then(() => {
                        // force change of values without reloading entire grid
                        owner.can_share = true
                        group.can_share = true
                    })
                }
            } else {
                // grab number to display in column
                template.form_restrictions = template.instance_restricted ? this.manageRowData[id].length + this.manageRowGroupData[id].length : null
                if(rowNode) {
                    rowNode.setDataValue('form_restrictions', template.form_restrictions)
                }
                this.$refs.grid.gridApi.refreshCells()
            }
            if (this.manageModal) {
                if (this.selectedTab.type == 'user') {
                    this.$refs.manageGrid.gridOptions.api.setRowData(this.manageRowData[id])
                }
                else {
                    this.$refs.manageGroupsGrid.gridOptions.api.setRowData(this.manageRowGroupData[this.cell.data.id])
                    this.$refs.userGroupCrud.read()
                        .then(response => {
                            response.data.user_group.forEach(group => {
                                if(!this.previouslySelectedGroups[id].find(x => x.id == group.id)) this.userGroupsSelectable[id].push(group)
                            })
                        })
                }
                setTimeout(function() {
                    Vue.set(this.tabs[0], 'name', 'Users (' + this.manageRowData[id].length + ')')
                    Vue.set(this.tabs[1], 'name', 'User Groups (' + this.manageRowGroupData[id].length + ')')
                }.bind(this), 500)
            }
        },
        onAddSecurity(type = 'user') {
            if(!this.openDefault) {
                if(type == 'user') {
                    this.addUsers = false
                    let p = []
                    this.newUsers.forEach(user => {
                        // if not a previously selected user (since those remained selected in the user search modal)
                        // then create their record
                        if(!this.previouslySelectedUsers[this.cell.data.id].find(x => x.user_id == user.id)) {
                            p.push({
                                form_id: this.cell.data.id,
                                user_id: user.id,
                                can_view: 0,
                                can_edit: 0,
                                can_create: 0,
                                can_delete: 0,
                                can_share: 0,
                            })
                        }
                    })
                    this.$axios.post('crud.php?property=FormManagementUserSecurity&action=create', {form_management_user_security: p})
                        .then((response) => {
                            if(response.data.success) {
                                this.loadSecurity()
                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                            } else {
                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                            }
                            this.newUsers = []
                        })
                } else {
                    let p = []
                    this.newGroups.forEach(group => {
                        if (!this.manageRowGroupData[this.cell.data.id].find(x => x.user_group_id == group.id)) {
                            p.push({
                                form_id: this.cell.data.id,
                                user_group_id: group.id,
                                can_view: 0,
                                can_edit: 0,
                                can_create: 0,
                                can_delete: 0,
                                can_share: 0,
                            })
                            this.$axios.post('crud.php?property=FormManagementUserGroupSecurity&action=create', {form_management_user_group_security: p})
                                .then((response) => {
                                    if (response.data.success) {
                                        this.loadSecurity()
                                        this.newGroups = []
                                        this.$snackbars.$emit('new', {text: response.data.msg, usage: 'success'})
                                    } else {
                                        this.$snackbars.$emit('new', {text: response.data.msg, usage: 'warning'})
                                    }
                                })
                        }
                    })
                }
            } else {
                if(type == 'user') {
                    this.addUsers = false
                    this.newUsers.forEach(user => {
                        if(!this.previouslySelectedUsersDefault.find(x => x.user_id == user.id)) {
                            this.defaultUsers.push({
                                user_id: user.id,
                                user_full_name: user.user_full_name,
                                can_view: 0,
                                can_edit: 0,
                                can_create: 0,
                                can_delete: 0,
                                can_share: 0,
                            })
                        }
                    })
                    this.newUsers = []
                    Vue.set(this.tabs[0], 'name', 'Users (' + this.defaultUsers.length + ')')
                } else {
                    this.newGroups.forEach(group => {
                        if (!this.defaultGroups.find(x => x.user_group_id == group.id)) {
                            this.defaultGroups.push({
                                user_group_name: group.name,
                                user_group_id: group.id,
                                can_view: 0,
                                can_edit: 0,
                                can_create: 0,
                                can_delete: 0,
                                can_share: 0,
                            })
                        }
                    })
                    this.newGroups = []
                    Vue.set(this.tabs[1], 'name', 'User Groups (' + this.defaultGroups.length + ')')
                }
            }
        },
        onEditSecurity(data, type) {
            if(data && !this.openDefault) {
                if(type == 'user') {
                    if(data.user_full_name.includes('owner')) return
                    let id = this.manageRowData[this.templateId].find(x => x.user_id == data.user_id).id
                    let p = [{
                        form_id: data?.form_id,
                        user_id: data?.user_id,
                        can_view: data?.can_view ? 1 : 0,
                        can_edit: data?.can_edit ? 1 : 0,
                        can_create: data?.can_create ? 1 : 0,
                        can_delete: data?.can_delete ? 1 : 0,
                        can_share: data?.can_share ? 1 : 0,
                        id: id,
                    }]
                    this.$axios.post('crud.php?property=FormManagementUserSecurity&action=update', {form_management_user_security: p})
                        .then((response) => {
                            if(response.data.success) {
                                this.loadSecurity()
                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                            } else {
                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                            }
                        })
                } else {
                    if(data.user_group_name.includes('Site Administrators')) return
                    let id = this.manageRowGroupData[this.templateId].find(x => x.user_group_id == data.user_group_id).id
                    let p = [{
                        form_id: data?.form_id,
                        user_group_id: data?.user_group_id,
                        can_view: data?.can_view ? 1 : 0,
                        can_edit: data?.can_edit ? 1 : 0,
                        can_create: data?.can_create ? 1 : 0,
                        can_delete: data?.can_delete ? 1 : 0,
                        can_share: data?.can_share ? 1 : 0,
                        id: id,
                    }]
                    this.$axios.post('crud.php?property=FormManagementUserGroupSecurity&action=update', {form_management_user_group_security: p})
                        .then((response) => {
                            if(response.data.success) {
                                this.loadSecurity()
                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                            } else {
                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                            }
                        })
                }
            }
        },
        onRemoveUser(data, type) {
            if(!this.openDefault) {
                if(type == 'user') {
                    let owner = this.manageRowData[this.templateId].find(row => row.user_id === this.cell.data.created_by)
                    if(owner !== data) {
                        let id = this.manageRowData[this.templateId].find(x => x.user_id == data.user_id).id
                        let p = [{
                            form_id: data.form_id,
                            user_id: data.user_id,
                            id: id,
                        }]
                        this.$axios.post('crud.php?property=FormManagementUserSecurity&action=delete', {form_management_user_security: p})
                            .then((response) => {
                                if(response.data.success) {
                                    this.loadSecurity()
                                    this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                                } else {
                                    this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                                }
                            })
                    }
                } else {
                    let groupOwner = this.manageRowGroupData[this.templateId].find(item => item.user_group_name.includes('Site Administrators'))
                    if(groupOwner !== data) {
                        let id = this.manageRowGroupData[this.templateId].find(x => x.user_group_id == data.user_group_id).id
                        let p = [{
                            form_id: data.form_id,
                            user_group_id: data.user_group_id,
                            id: id,
                        }]
                        this.$axios.post('crud.php?property=FormManagementUserGroupSecurity&action=delete', {form_management_user_group_security: p})
                            .then((response) => {
                                if(response.data.success) {
                                    this.loadSecurity()
                                    this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                                } else {
                                    this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                                }
                            })
                    }
                }
            } else {
                if(type == 'user') {
                    let index = this.defaultUsers.findIndex(x => x.user_id == data.user_id)
                    let prevIndex = this.previouslySelectedUsersDefault.findIndex(x => x.user_id == data.user_id)
                    this.defaultUsers.splice(index, 1)
                    this.previouslySelectedUsersDefault.splice(prevIndex, 1)
                } else {
                    let index = this.defaultGroups.findIndex(x => x.user_group_id == data.user_group_id)
                    this.defaultGroups.splice(index, 1)
                    this.$refs.userGroupCrud.read()
                        .then(response => {
                            response.data.user_group.forEach(group => {
                                if(!this.defaultGroups.find(x => x.user_group_id == group.id)) this.userGroupsSelectableDefault.push(group)
                            })
                        })
                }
                Vue.set(this.tabs[0], 'name', 'Users (' + this.defaultUsers.length + ')')
                Vue.set(this.tabs[1], 'name', 'User Groups (' + this.defaultGroups.length + ')')
            }
        },
        onTurnOnRestrictions(data) {
            if(!data) {
                // user is turning off restrictions, give warning
                this.confirmChange = true
            } else {
                // push true
                this.$axios.post('form.php?action=update&property=instance_restriction&restrict_instances=1&form_id=' +this.cell.data.id)
                    .then((response) => {
                        if(response.data.success) {
                            Vue.set(this.showRestrictions, this.cell.data.id, true)
                            this.setInitial()
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
            }
        },
        onTurnOffRestrictions() {
            this.confirmChange = false
            this.$axios.post('form.php?action=update&property=instance_restriction&restrict_instances=0&form_id=' +this.cell.data.id)
                .then((response) => {
                    if(response.data.success) {
                        Vue.set(this.showRestrictions, this.cell.data.id, false)
                        this.manageModal = false
                        let template = this.templates.find(t => t.id == this.cell.data.id)
                        this.loadSecurity(template, true)
                        this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                    } else {
                        this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                    }
                })
                .finally(() => {
                    this.$refs.crud.read()
                })
        },
        onCloseRestrictions() {
            this.selectedTab.type = 'user'
            if(this.openDefault) {
                if(this.hasDefault) {
                    if(Object.entries(this.$_.cloneDeep(this.origDefault)).sort().toString() !==
                        Object.entries(this.$_.cloneDeep(this.defaultUsers.concat(this.defaultGroups))).sort().toString()) {
                        this.openModify = true
                    } else {
                        this.openDefault = false
                    }
                } else {
                    this.onCloseDefault()
                }
            } else {
                if(this.showRestrictions[this.cell.data.id]) {
                    let template = this.templates.find(t => t.id == this.cell.data.id)
                    let rowNode = this.$refs.grid.gridOptions.api.getRowNode(this.cell.data.id)
                    this.loadSecurity(template, false, rowNode)
                    this.manageModal = false
                } else {
                    this.confirmNoChange = true
                    this.manageModal = true
                }
            }
            this.selectedTab = {
                name: 'Users (0)',
                path: '#',
                type: 'user'
            }
        },
        openSearch(v) {
            this.search.show = true
            this.search.rec = v
        },
        openExportData(v) {
            this.search.show = false
            this.exportData.show = true
            this.exportData.rec = this.search.rec
            this.exportData.rec.school_year_id = v.school_year_id
        },
        setInitial(reset = false) {
            // push owner on load
            let action = 'create'
            if((!this.manageRowData[this.cell.data.id].length || this.hasDefault) && !reset) {
                let p = [{
                    form_id: this.cell.data.id,
                    user_id: this.cell.data.created_by,
                    can_view: 1,
                    can_edit: 1,
                    can_create: 1,
                    can_delete: 1,
                    can_share: 1,
                }]
                this.$axios.post('crud.php?property=FormManagementUserSecurity&action=' + action, {form_management_user_security: p})
                    .then(() => {
                        if(this.selectedTab.type == 'user') this.loadSecurity()
                    })
            } else {
                this.loadSecurity(null, false, null, reset, null)
            }
            // push site administrators on load
            if((!this.manageRowGroupData[this.cell.data.id].length && !this.hasDefault) && !reset) {
                let userGroupId = null
                this.$refs.userGroupCrud.read()
                    .then(response => {
                        userGroupId = response.data.user_group.find(row => row.name === 'Site Administrators').id
                        let p = [{
                            form_id: this.cell.data.id,
                            user_group_id: userGroupId,
                            can_view: 1,
                            can_edit: 1,
                            can_create: 1,
                            can_delete: 1,
                            can_share: 1,
                        }]
                        this.$axios.post('crud.php?property=FormManagementUserGroupSecurity&action=' + action, {form_management_user_group_security: p})
                            .then(() => {
                                if(this.selectedTab.type != 'user') this.loadSecurity()
                            })
                    })
            } else {
                let userGroupId = null
                this.$refs.userGroupCrud.read()
                    .then(response => {
                        userGroupId = response.data.user_group.find(row => row.name === 'Site Administrators').id
                        this.loadSecurity(null, false, null, reset, userGroupId)
                    })
            }
        },
        onCloseNoChange() {
            this.confirmNoChange = false
            this.manageModal = false
        },
        tabClicked(event) {
            this.selectedTab.type = event.type
        },
        onCloseDefault() {
            this.openModify = false
            // let users = this.defaultUsers.filter(x => !this.origDefault.includes(x))
            // let groups = this.defaultGroups.filter(x => !this.origDefault.includes(x))
            let p = this.defaultUsers.concat(this.defaultGroups)
            let action = this.hasDefault ? 'update' : 'create'
            this.$axios.post('form.php?property=permissions_template&action=' + action, p)
                .then((resp) => {
                    if(resp.data.success) {
                        this.$snackbars.$emit('new', { text: resp.data.msg, usage: 'success' })
                        this.hasDefault = true
                    } else {
                        this.$snackbars.$emit('new', { text: resp.data.msg, usage: 'warning' })
                    }
                })
                .finally(() => {
                    this.openDefault = false
                })
        },
        carryOverRec(v) {
            this.$axios.get('form.php?action=get&property=carryover_instances&form_id=' + v.id)
                .then((resp) => {
                    Vue.set(this.carryOver, 'instancesToCarryOver', resp.data.counts[0]['included'] ? resp.data.counts[0]['included'] : 0)
                    Vue.set(this.carryOver, 'instancesNotToCarryOver', resp.data.counts[0]['not_included'] ? resp.data.counts[0]['not_included'] : 0)
                })
            this.carryOver.show = true
            this.carryOver.rec = v
        },
        onCarryOver() {
            this.$axios.post('form.php?action=create&property=rollover', {form_id : [this.carryOver.rec.id]})
                .then((resp) => {
                    // close only on success
                    // reload if not ba
                    if(resp.data.batch_job_id) {
                        let me = this
                        this.$root.$emit('waiterDialog', {
                            action: 'create',
                            text: 'Carrying Over Forms',
                            successText: 'Your smartFORMS have been carried over',
                            successSubText: ' ',
                            batch_job_id: resp.data.batch_job_id,
                            content_count: this.carryOver.instancesToCarryOver.length,
                            callback: function() {
                                me.$emit('reload',{deferred:true})
                            },
                        })
                        this.$emit('reload')
                        this.$emit('close')
                    } else {
                        if(resp.data.success) {
                            this.$snackbars.$emit('new', { text: resp.data.msg, usage: 'success' })
                            this.$emit('reload')
                            this.$emit('close')
                        } else {
                            this.$snackbars.$emit('new', { text: resp.data.msg, usage: 'error' })
                            this.$emit('close')
                        }
                    }
                })
                .finally(() => {
                    this.onCloseCarryOver()
                })
        },
        onCloseCarryOver() {
            this.carryOver = {
                show: false,
                rec: null,
                instancesToCarryOver: 0,
                instancesNotToCarryOver: 0
            }
        },
    },
}
</script>

<style lang="scss" >
.fe-window-title {
    font-size: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100vw - 400px);
}
.lowerz {
    .sticky {
        background-color: pink;
        z-index: 5 !important;
    }
}
.smartforms-manage-form-restrictions {
    &-fe-switch {
        ::v-deep .v-input--switch__thumb {
            box-shadow: none !important;
        }
        &-disable {
            pointer-events: none;
        }
    }
    &-fe-switch-icon {
        font-size: 12px !important;
        padding-left: 1px;
    }
    &-grid {
        .medium-density.fe-grid-grid.ag-cell {
            padding: 0 !important;
        }
    }
}
.smartforms-template-ellipsis-menu {
    .v-btn {
        margin: 2px 0 !important;
    }
}
</style>
