<template>
    <div style="height: calc(100% - 20px)">
        <div class="mb-2">
            Select the columns you would like included in the export. Next, place them in the order in which they will appear (optional).
            The export will include all columns placed in the second table.
        </div>
        <div class="d-flex" style="height: calc(100% - 20px)">
            <div style="width: 100%;height: calc(100% - 20px)">
                <fe-text-field
                    placeholder="Search"
                    prependIcon="far fa-search"
                    v-model="origSearchText"
                    class="mb-2" style="width: 100%"
                />
                <fe-grid
                    ref="oldGrid"
                    :rowData="filterRowData"
                    :columnDefs="columns"
                    style="height: calc(100% - 20px); width: 100%"
                    domLayout="normal"
                    :showAddRowBtn="false"
                    suppressFieldDotNotation
                    :gridOptionOverrides="gridOptions"
                    :showToolbar="false"
                    class="smartforms-no-configure-grid"
                    @rowSelected="doSelect"
                    @gridReady="onGridReady"
                />
            </div>
            <div style="margin: auto 10px;">
                <fe-btn usage="tertiary" small class="smartforms-configure-export-btn" @click="moveToRightGrid" :disabled="!selectedRows.length">
                    <v-icon color="#050F2D">fa fa-arrow-right</v-icon>
                </fe-btn>
                <fe-btn usage="tertiary" small class="smartforms-configure-export-btn" @click="moveToLeftGrid" :disabled="!selectedRowsConfigured.length">
                    <v-icon color="#050F2D">fa fa-arrow-left</v-icon>
                </fe-btn>
            </div>
            <div style="width: 100%; height: calc(100% - 20px)">
                <fe-text-field
                    placeholder="Search"
                    prependIcon="far fa-search"
                    v-model="configureSearchText"
                    class="mb-2" style="width: 100%"
                    ref="configureSearchText"
                />
                <fe-grid
                    ref="newGrid"
                    :rowData="filterConfigureData"
                    :columnDefs="columnsConfigured"
                    style="height: calc(100% - 20px); width: 100%"
                    domLayout="normal"
                    :showAddRowBtn="false"
                    suppressFieldDotNotation
                    :gridOptionOverrides="gridOptionsConfigure"
                    :showToolbar="false"
                    class="smartforms-configure-export-drag"
                    @rowSelected="doSelectConfigure"
                    stopEditingWhenCellsLoseFocus
                    @gridReady="onGridReady2"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import ColumnName from './ColumnName'
    import ColumnNameDraggable from './ColumnNameDraggable'
    import PositionRenderer from './PositionRenderer'
    export default {
        name: 'ConfigureExport',

        props: {
            backupRowData: { type: Array },
            backupExportRowData: { type: Array },
            reset: { type: Boolean, default: false },
            grabConfigureData: { type: Boolean, default: false },
        },

        data() {
            let me = this
            return {
                columns: [
                    {
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        width: 70,
                        maxWidth: 70,
                        colId: 'checkbox_column',
                    },
                    {
                        headerName: 'Column Name',
                        field: 'field',
                        sortable: true,
                        editable: false,
                        cellRendererFramework: ColumnName,
                    },
                ],
                columnsConfigured: [
                    {
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        width: 70,
                        maxWidth: 70,
                        colId: 'checkbox_column',

                    },{
                        headerName: 'Position',
                        field: 'position',
                        editable: true,
                        maxWidth: 100,
                        cellEditorFramework: 'FeGridTextField',
                        cellRendererFramework: PositionRenderer,
                        cellEditorParams: v => {
                            return {
                                type: 'number',
                                rules: this.$fieldValidators('number', '', {min: 1, max: this.exportRowData.length}),
                            }
                        },
                        cellClass: params => {
                            if (params.data.column == 'ID') {
                                return 'disabled-position-text-field'
                            }
                        },
                        onCellValueChanged: v => { me.editCellValue(v) },
                    },
                    {
                        headerName: 'Column Name',
                        field: 'field',
                        sortable: false,
                        editable: false,
                        cellRendererFramework: ColumnNameDraggable,
                    },
                ],
                gridOptions: {
                    context: { componentParent: this }
                },
                gridOptionsConfigure: {
                    context: { componentParent: this },
                    rowDragManaged: true,
                    animateRows: true,
                    onRowDragEnd: (v) => {
                        // ag-grid auto puts the data into another data object
                        // only want to push the actual data
                        let rows = []
                        this.$refs.newGrid.gridOptions.api.forEachNode((node, index) => {
                            if(node.data.data) {
                                node.data.data.rowIndex = index + 1
                                node.data.data.position = index + 1
                                rows.push(node.data.data)
                            } else {
                                node.data.rowIndex = index + 1
                                node.data.position = index + 1
                                rows.push(node.data)
                            }
                        })
                        this.$refs.newGrid.gridOptions.api.setRowData(rows)
                        this.exportRowData = this.$refs.newGrid.gridOptions.api.getModel().rowsToDisplay.map(row => row.data)
                        this.$emit('change', false)
                    },
                },
                selectedRows: [],
                selectedRowsConfigured: [],
                origSearchText: '',
                configureSearchText: '',
                showPencilEditIcon: false,
                rowData: [],
                exportRowData: [],
            }
        },

        computed: {
            filterRowData() {
                return !this.origSearchText ? this.rowData
                    : this.rowData.filter(row => row.column && row.column.toLowerCase().includes(this.origSearchText.toLowerCase())
                        || row.value && row.value.toLowerCase().includes(this.origSearchText.toLowerCase())
                        || row.data.column && row.data.column.toLowerCase().includes(this.origSearchText.toLowerCase())
                        || row.data.value && row.data.value.toLowerCase().includes(this.origSearchText.toLowerCase()))
            },
            filterConfigureData() {
                return !this.configureSearchText ? this.exportRowData
                    : this.exportRowData.filter(row => row.column && row.column.toLowerCase().includes(this.configureSearchText.toLowerCase())
                        || row.value && row.value.toLowerCase().includes(this.configureSearchText.toLowerCase()))
            },
        },

        watch: {
            reset(v) {
                if(v) {
                    this.rowData = this.$_.cloneDeep(this.backupRowData)
                    // if not new configuration and row data includes more than just pinned
                    if(this.backupExportRowData.length > 1) this.exportRowData = this.$_.cloneDeep(this.backupExportRowData).filter(row => row.column != 'ID')
                    else {
                        let rows = []
                        // have to grab new data from right grid since changes
                        // not stored in variable
                        this.$refs.newGrid.gridOptions.api.forEachNode(node => {
                            rows.push(node.data)
                        })
                        this.$refs.newGrid.gridOptions.api.applyTransaction({ remove: rows })
                        this.exportRowData = []
                    }
                    this.$emit('change', true)
                    // set timeout to disable buttons on reset
                    setTimeout(function() {
                        this.$emit('resetFinished')
                    }.bind(this), 50)
                }
            },
            grabConfigureData(v) {
                if(v) {
                    this.exportRowData.forEach(row => {
                        row.colId = row.rowIndex
                    })
                    this.$emit('pushConfigureData', this.exportRowData)
                }
            },
            rowData: {
                deep: true,
                handler(oldVal, newVal) {
                    if(newVal.length && !this.reset) {
                        this.$emit('change', false)
                    } else {
                        this.$emit('change', true)
                    }
                }
            }
        },

        methods: {
            doSelect(v) {
                // left grid select
                if(v.node.selected) {
                    this.selectedRows.push(v.data)
                } else {
                    if(this.selectedRows.length) {
                        let remove = this.selectedRows.findIndex(x => x.id == v.data.id)
                        this.selectedRows.splice(remove,1)
                    }
                }
            },
            doSelectConfigure(v) {
                // right grid select
                if(v.node.selected) {
                    this.selectedRowsConfigured.push(v.data)
                } else {
                    if(this.selectedRowsConfigured.length) {
                        let remove = this.selectedRowsConfigured.findIndex(x => x.id == v.data.id)
                        this.selectedRowsConfigured.splice(remove,1)
                    }
                }
            },
            moveToRightGrid() {
                this.$refs.oldGrid.gridOptions.api.applyTransaction({ remove: this.$refs.oldGrid.gridOptions.api.getSelectedRows() })
                let addRows = []
                this.selectedRows.forEach(row => {
                    let remove = null
                    if(row.data) {
                        addRows.push(row.data)
                        this.exportRowData.push(row.data)
                        remove = this.rowData.findIndex(x => x.column == row.data.column || x.data.column == row.data.column)
                    } else {
                        addRows.push(row)
                        this.exportRowData.push(row)
                        remove = this.rowData.findIndex(x => x.column == row.column || x.data.column == row.column)
                    }
                    // set new data inside variables to keep track easier
                    this.rowData.splice(remove,1)
                })
                this.$refs.newGrid.gridOptions.api.applyTransaction({ add: addRows })
                this.rowData.forEach((row, index) => {
                    // reset indexes after move
                    row.rowIndex = index + 1
                    row.position = index + 1
                })
                this.exportRowData.forEach((row, index) => {
                    // reset indexes after move
                    row.rowIndex = index + 1
                    row.position = index + 1
                })
            },
            moveToLeftGrid() {
                let rows = []
                this.$refs.newGrid.gridOptions.api.getSelectedRows().forEach(row => {
                    rows.push(row)
                })
                this.$refs.newGrid.gridOptions.api.applyTransaction({ remove: rows })
                this.selectedRowsConfigured.forEach(row => {
                    // want to push row back to where it was originally in left grid
                    // which is what id value tells us
                    this.$refs.oldGrid.gridOptions.api.applyTransaction({ add: [row], addIndex: row.id - 1 })
                    // set new data inside variables to keep track easier
                    this.rowData.push(row)
                    let remove = this.exportRowData.findIndex(x => x.column == row.column)
                    this.exportRowData.splice(remove,1)
                })
                this.$refs.newGrid.gridApi.sizeColumnsToFit()
                this.rowData.forEach((row, index) => {
                    // reset indexes after move
                    row.rowIndex = index + 1
                    row.position = index + 1
                })
                this.exportRowData.forEach((row, index) => {
                    // reset indexes after move
                    row.rowIndex = index + 1
                    row.position = index + 1
                })
            },
            editCellValue(event) {
                // method for editing position cell by typing in new value
                let newData = []
                let immutableStore = this.exportRowData
                let movingNode = event.node
                let overNode = immutableStore[event.newValue - 1]
                if (movingNode.data.id !== overNode.id) {
                    let fromIndex = immutableStore.findIndex(data => data.id == movingNode.data.id)
                    let toIndex = immutableStore.findIndex(data => data.id == overNode.id)
                    let newStore = immutableStore.slice()
                    this.moveInArray(newStore, fromIndex, toIndex)
                    // only want to set data so grab data, don't push row nodes
                    newStore.forEach((node, index) => {
                        if(node.data) {
                            node.data.rowIndex = index + 1
                            node.data.position = index + 1
                            newData.push(node.data)
                        } else {
                            node.rowIndex = index + 1
                            node.position = index + 1
                            newData.push(node)
                        }
                    })
                    if(this.configureSearchText) {
                        this.configureSearchText = null
                        this.$refs.configureSearchText.text = null
                        setTimeout(function() {
                            this.$refs.newGrid.gridOptions.api.setRowData(newData)
                            this.exportRowData = this.$refs.newGrid.gridOptions.api.getModel().rowsToDisplay.map(row => row.data)
                        }.bind(this), 50)
                    } else {
                        this.$refs.newGrid.gridOptions.api.setRowData(newData)
                        this.exportRowData = this.$refs.newGrid.gridOptions.api.getModel().rowsToDisplay.map(row => row.data)
                    }
                    this.$emit('change', false)
                }
            },
            onGridReady() {
                // set ids for left grid
                this.$refs.oldGrid.gridOptions.deltaRowDataMode = false
                this.$refs.oldGrid.gridOptions.getRowNodeId = v => {
                    return v.id || v.data.id
                }
            },
            onGridReady2() {
                // set ids for right grid
                this.$refs.newGrid.gridOptions.deltaRowDataMode = false
                this.$refs.newGrid.gridOptions.getRowNodeId = v => {
                    return v.position + this.exportRowData.length || v.data.position + this.exportRowData.length
                }
            },
            moveInArray(arr, fromIndex, toIndex) {
                let element = arr[fromIndex]
                arr.splice(fromIndex, 1)
                arr.splice(toIndex, 0, element)
            },
        },

        mounted() {
            // set data without editing data that was passed up
            this.rowData = this.$_.cloneDeep(this.backupRowData)
            this.exportRowData = this.$_.cloneDeep(this.backupExportRowData).filter(row => row.column != 'ID')
        }
    }
</script>

<style lang="scss">
    .smartforms-configure-export-btn {
        .fe-button {
            height: 40px !important;
        }
    }
    .smartform-configure-export-disabled-cell {
        /*background: rgba(5, 15, 45, 0.05);*/
        color: #C0C3CF;
    }
    .ag-row-hover {
        .smartform-configure-export-cell-visible-on-hover {
            display: block;
        }
    }
    .disabled-position-text-field {
        background: rgba(5, 15, 45, 0.05);
    }
    .smartforms-configure-export-drag {
        .ag-row-pinned {
            border: none;
        }
        .ag-floating-top {
            box-shadow: none !important;
        }
        .ag-overlay-no-rows-center {
            display: none;
        }
        .empty-state {
            display: none;
        }
    }
    .smartforms-no-configure-grid {
        .empty-state {
            display: none;
        }
    }
</style>
