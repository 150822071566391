<template>
    <fe-card
        class="alt-card ma-3"
        headerText="Visualizations"
        :menu="tools"
    >
        <template v-if="showIncidentOptions === 0" v-slot:title-toolbar>
            <v-menu
                ref="date-picker"
                offset-y
                left
            >
                <template v-slot:activator="{on}">
                    <v-btn icon x-small v-on="on">
                        <v-icon>far fa-calendar-day</v-icon>
                    </v-btn>
                </template>

                <v-date-picker v-model="endDate"/>
            </v-menu>
        </template>

        <template v-slot:body>
            <v-tabs v-model="activeTab" class="tabs">
                <v-tab>
                    Incidents
                    <fe-info-tip
                        v-if="hasRestrictedIncidentTypes"
                        tooltip="There are some incident types that have been restricted from your visibility by your district"
                        direction="top"
                        color="#fff"
                        style="margin-left: 4px"
                        icon="far fa-exclamation-circle"
                    />
                </v-tab>

                <v-tab>Attendance</v-tab>

                <v-tab v-if="enrollmentDisplay">Enrollment</v-tab>

                <v-tab-item>
                    <div style="height: 300px;">
                        <chart
                            v-if="incidentChart"
                            :config="incidentChart"
                            :menu="false"
                            @chartClicked="incidentClicked"
                        />

                        <div
                            v-else
                            style="cursor: pointer; vertical-align: middle; height: 280px;"
                            @click="$router.replace('/Incidents')"
                        >
                            <fe-empty-state icon="fa-search" text="No data found.  Click to search"/>
                        </div>
                    </div>
                </v-tab-item>

                <v-tab-item>
                    <div style="height: 300px;">
                        <attendance-chart
                            v-if="att.ready"
                            :height="280"
                            :config="att.config"
                            :chartParams="att.params"
                            :showInsight="false"
                            :pinnable="false"
                            :collectionChart="false"
                            minimal
                            @chartClicked="attendanceClicked"
                        />

                        <div
                            v-else
                            style="cursor: pointer; vertical-align: middle; height: 280px;"
                            @click="attendanceClicked"
                        >
                            <fe-empty-state icon="fa-search" text="No data found.  Click to search"/>
                        </div>
                    </div>
                </v-tab-item>

                <v-tab-item v-if="enrollmentDisplay">
                    <div>
                        <enrollment-card
                            v-if="enr.ready"
                            :params="enr.params"
                            @click="enrollmentClicked"
                        />

                        <div
                            v-else
                            style="cursor: pointer; vertical-align: middle; height: 280px;"
                            @click="enrollmentClicked"
                        >
                            <fe-empty-state icon="fa-search" text="No data found. Click to search"/>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs>

            <div v-if="endDate && showIncidentOptions === 0" class="text-center">{{ startDate }} to {{ endDate }}</div>
        </template>
    </fe-card>
</template>

<script>
import {mapState} from 'vuex'
import Chart from "@/components/charts/Chart"
import AttendanceChart from '@/components/charts/AttendanceChart'
import windowOptionsMixin from '@/mixins/windowOptions'
import EnrollmentCard from "./EnrollmentCard.vue";

export default {
    name: 'VisualizationsCard',

    components: {
        AttendanceChart,
        Chart,
        EnrollmentCard
    },

    mixins: [windowOptionsMixin],

    data() {
        return {
            reloading: false,
            tools: [],
            items: [],
            incidentChart: false,
            att: {
                ready: false,
                params: null,
                config: null
            },
            enr: {
                ready: false,
                params: null,
                config: null
            },
            endDate: undefined,
            activeTab: 0,
            showIncidentOptions: 0,
        }
    },

    computed: {
        ...mapState('global', ['currentYear', 'sessionUser', 'defaultSchool', 'lastLaunchpadVisit']),

        startDate() {
            if (!this.endDate) return undefined
            return this.$dayjs(this.endDate).subtract(14, 'd').format('YYYY-MM-DD')
        },

        hasRestrictedIncidentTypes() {
            return (!!this.sessionUser.user?.has_restricted_incident_types)
        },

        enrollmentDisplay() {
            return this.$store.getters['flags/flags']['prebuilt-enrollment-vis']
        }
    },

    watch: {
        endDate(n, o) {
            if (o && n && n !== o) {
                this.loadChartData()
            }
        },

        lastLaunchpadVisit() {
            if (!this.reloading) {
                this.reloading = true
                this.loadChartData()
            }
        },

        activeTab(v) {
            this.showIncidentOptions = v
        },
    },

    mounted() {
        this.endDate = this.$dayjs().format('YYYY-MM-DD')
        this.loadChartData()
    },

    methods: {
        loadChartData() {
            let loadingCharts = [
                this.loadIncidents(),
                this.loadAttendance()
            ]

            if (this.enrollmentDisplay) {
                loadingCharts.push(this.loadEnrollment())
            }

            Promise.all(loadingCharts)
                .then(() => {
                    this.reloading = false
                    this.$setLoading(false)
                })
        },

        loadIncidents() {
            let url = this.$models.getUrl('studentIncidentChart', 'read');
            let p = {
                chart_types: ['incident_behavior'],
                start_date: this.startDate,
                end_date: this.endDate,
                school_id: this.defaultSchool?.id,
            }

            return this.$axios.get(url + '&' + this.$objectToParams(p))
                .then(response => {
                    let data = this.$ecResponse(response, 'incidents')

                    data.charts.forEach((chart) => {
                        let categories = chart.fields ? chart.fields : []

                        if (categories.length > 0) {
                            let series = this.buildSeries(categories, chart)

                            this.incidentChart = {
                                chart: {
                                    type: 'bar',
                                    height: '300px'
                                },
                                title: false,
                                subtitle: false,
                                xAxis: {
                                    categories: categories,
                                    labels: {
                                        enabled: false
                                    },
                                    title: {
                                        text: null
                                    }
                                },
                                yAxis: {
                                    endOnTick: false,
                                    title: {
                                        text: 'Total Count',
                                        align: 'high'
                                    },
                                    labels: {
                                        overflow: 'justify'
                                    }
                                },
                                plotOptions: {
                                    bar: {
                                        dataLabels: {
                                            enabled: true
                                        }
                                    }
                                },
                                legend: false,
                                credits: {
                                    enabled: false
                                },
                                series: series
                            }
                        }
                    })
                })
        },

        loadAttendance() {
            let url = this.$models.getUrl('attendanceCharts', 'read');
            let p = {
                chart_type: ['month'],
                school_year_id: this.currentYear.id,
                school_id: this.defaultSchool?.id,
            }

            return this.$axios.get(url + '&' + this.$objectToParams(p))
                .then(response => {
                    let data = this.$ecResponse(response, 'charts')

                    data.forEach(chart => {
                        this.att.params = chart.params
                        this.att.config = chart
                        if (chart.data.length > 0) this.att.ready = true
                    })
                })
        },

        loadEnrollment() {
            let url = this.$models.getUrl('enrollmentSummary', 'read');

            return this.$axios.get(url)
                .then(response => {
                    if (response?.data.summary) {
                        this.enr.params = response.data.summary
                        this.enr.ready = true
                    } else {
                        this.enr.ready = false
                    }
                })
        },

        incidentClicked(event, point) {
            let incidentId = point.incident_behavior_id
            let incidentName = point.incident_behavior_name.match(/.+-\s(.+)/)[1]

            let chartParams = {
                start_date: this.startDate,
                end_date: this.endDate,
                school_year_id: [{id: point.school_year_id, name: point.school_year_name}],
                incident_behavior_id: [{id: incidentId, name: incidentName}],
            }
            if (this.defaultSchool?.id) chartParams.school_id = this.defaultSchool.id

            let chartFilters = [{
                key: 'incident_behavior_id',
                name: incidentName,
                id: incidentId
            }]

            this.$store.commit('global/addDockableWindow', {
                name: 'Incident Charting',
                component: 'incident-charting',
                attrs: {
                    chartParams,
                    chartFilters,
                    windowed: true
                }
            })
        },

        attendanceClicked() {
            this.$dockableWindow({
                name: 'Attendance Reporting',
                component: 'attendance-reporting',
                attrs: {
                    params: {
                        school_year_id: this.currentYear.id,
                        school_id: this.defaultSchool?.id,
                    }
                }
            })
        },

        enrollmentClicked() {
            this.$dockableWindow({
                name: 'Enrollment',
                component: 'enrollment-reporting',
                attrs: {
                    params: {}
                }
            })
        },

        buildSeries(categories, cfg) {
            let series = []

            cfg.data.forEach(rec => {
                let exists = series.find(serie => {
                    return serie.name === rec.school_year_name
                })

                if (!exists) {
                    series.push({
                        name: rec.school_year_name,
                        schoolYearId: rec.school_year_id,
                        data: []
                    })
                }
            })

            series.forEach(serie => {
                categories.forEach(cat => {
                    let rec = cfg.data.find(data => data[cfg.x_field] === cat)
                    let data = rec ? rec : {}
                    data.y = rec ? rec.total_count : 0
                    serie.data.push(data)
                })
            })

            return series
        },
    }
}
</script>

<style lang="scss" scoped>
.tabs {
    .v-tab {
        color: #495B8F !important;
        font-size: 14px;
    }
    .v-tab:hover {
        background-color: #ECEDF1;
    }
    .v-tab--active {
        color: #006C90 !important;
    }
    .v-tab--active:hover {
        background-color: #E5F0F4;
    }
}
</style>
