<template>
    <div class="flex-fill flex-column slo-plan-panel">
        <div class="pa-2 d-flex flex-grow-1">
            <fe-spinner v-if="!forms" text="Loading forms..." />
            <fe-crud-grid
                ref="grid"
                :bordered="false"
                :columnDefs="columnDefs"
                :config="$models.sloForms"
                :defaultParams="{
                    slo_id: slo.id
                }"
                :emptyState="{
                    text: 'No SLO Forms found with the current selections.',
                    imageSrc: '/images/empty-state-horn.png',
                    imageStyle: 'max-width:50%; max-height:50%;',
                }"
                :rowData="forms"
                :showBorderToggle="false"
                :showDensityToolbar="false"
                :showDownload="false"
                :showFullscreen="false"
                :showSaveAsDefault="false"
                :style="{
                    height: '100%',
                    width: '100%'
                }"
                addRowBtnText="Add"
                autoload
                disableFilterUpdate
                disableInlineCreate
                refreshOnDestroyed
                @beginCreate="beginEntry"
                @firstDataRendered="adjustGrid"
                @rowDoubleClicked="loadSmartForm"
                @created="filterResults"
                @destroyed="filterResults"
                @read="forms = $event; filterResults()"
            />

            <fe-dialog
                v-if="createDialog.show"
                @accept="addForm"
                @close="createDialog.show=false"
                @dismiss="createDialog.show=false"
                acceptButtonText="Save"
                dismissButtonText="Cancel"
                title="Add Form"
                disableAutoclose
                :acceptButtonDisabled="!valid"
                persistent
            >
                <v-form ref="form" @submit.prevent v-model="valid">
                    <fe-remote-combo
                        v-model="createDialog.record.form_id"
                        class="pr-2"
                        label="Form Type"
                        :items="formTypes"
                        itemText="name"
                        itemValue="id"
                        hide-details
                        :multiple="false"
                        byId
                    />
                    <div class="flex-fill flex-column pr-2">
                        <fe-label>Form Name</fe-label>
                        <v-text-field
                            v-model="createDialog.record.name"
                            flat solo dense
                            :style="{ width: '100%' }"
                            :rules="$fieldValidators('text', 'Form Name', {required: true, limit: 50})"
                        />
                    </div>
                </v-form>
            </fe-dialog>
        </div>

        <fe-crud ref="formCrud" :config="$models.form" autoload @read="formTypes = $event"/>
    </div>
</template>

<script>
    export default {
        name: 'SloPanelForms',

        props: {
            slo: { type: Object, required: true }
        },

        components: { },

        data() {
            return {
                forms: [],
                formTypes: [],
                valid: true,
                createDialog: { show: false, record: {} },
                columnDefs: [
                    {
                        headerName: '',
                        pinned: 'left',
                        width: 70,
                        checkboxSelection: true,
                        headerCheckboxSelection: true
                    }, {
                        headerName: "Form Name",
                        field: "form_instance_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Form Type",
                        field: "form_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Created By",
                        field: "form_instance_created_by_full_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Created On",
                        field: "form_instance_created",
                        sortable: true,
                        editable: false
                    }
                ]
            }
        },

        computed: { },

        methods: {
            adjustGrid () {
                this.$nextTick(() => {
                    this.$refs.grid?.$refs.grid.resize()
                })
            },

            beginEntry() {
                this.resetEntry()
                this.createDialog.show = true
            },

            resetEntry() {
                this.createDialog.record = {
                    name: '',
                    form_id: null,
                    slo_id: this.slo.id
                }
            },

            addForm() {
                if (this.$refs.form.validate()) {
                    let form = this.createDialog.record
                    this.$axios.post('slo.php?action=create&property=form_instances&' + this.$objectToParams(form))
                    .then(r => {
                        if(r.data.success) {
                            this.$snackbars.$emit('new', { text: r.data.msg, usage: 'success' })
                            this.$nextTick(() => {
                                this.$refs.grid?.$refs.crud.read()
                                .then(() => this.filterResults())
                            })
                            this.$emit('refreshSlo')
                        } else {
                            this.$snackbars.$emit('new', { text: r.data.msg, usage: 'warning' })
                        }
                    })
                    .finally(() => {
                        this.createDialog.show = false
                    })
                }
            },

            filterResults() {
                if (this.$refs.grid?.$refs.grid.filterText?.length) {
                    this.$nextTick(() => {
                        this.$refs.grid.$refs.grid.filterResults()
                    })
                }
            },

            loadSmartForm(meta) {
                this.$dockableWindow({
                    name: meta.data.form_instance_name,
                    data: meta.data,
                    component: 'smart-form',
                    attrs: {
                        id: meta.data.form_instance_id,
                        template_id: meta.data.form_id
                    },
                })
            }
        },

        watch: { }
    }
</script>

<style lang="scss" scoped>
</style>
