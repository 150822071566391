<template>
    <fe-dialog
        ref="dialog"
        :title="title"
        persistent
        width=650
        acceptButtonText="Save"
        :acceptButtonDisabled="!valid"
        @accept="apply"
        @dismiss="$emit('close')"
    >
        <div class='d-flex flex-fill flex-column flex-grow-1 flex-shrink-1'>
            <v-form ref="logistic" v-model="valid">

                <fe-remote-combo
                    label="Type"
                    v-model="obj.slo_logistic_type_id"
                    :url="$models.getUrl('sloLogisticType', 'read')"
                    rootProperty="types"
                    byId
                    :rules="rules.type"
                    validateOnBlur
                />

                <fe-label>Logistics</fe-label>
                <v-textarea flat solo dense
                    :counter="1000"
                    v-model="obj.description"
                    :rules="$fieldValidators('text', null, { required: true, limit: 1000 })"
                />

                <fe-label>Cost</fe-label>
                <v-text-field flat solo dense
                    v-model="obj.cost"
                    type="number"
                    :rules="$fieldValidators('number', null, { min: 0, max: 100000000, required: false })"
                />

                <fe-remote-combo
                    label="Assignees"
                    v-model="obj.user_id"
                    :url="`${$models.getUrl('user', 'read')}&active=1`"
                    multiple
                    itemText="user_full_name"
                    itemValue="id"
                    byId
                    more-text
                    :rules="rules.assignee"
                    validateOnBlur
                />

                <fe-crud ref="crud" :config="$models.sloLogistic" />

            </v-form>
        </div>
    </fe-dialog>

</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'SloPanelLogisticsModal',
        props: {
            slo: { type: Object, required: true },
            item: { type: Object },
            dryRun: { type: Boolean, default: false },
        },
        components: { },
        data() {
            return {
                isRecurring: false,
                obj: {
                    cost: null,
                    description: null,
                    slo_logistic_type_id: null,
                    user_id: [],
                    completed: null
                },
                valid: true,
                rules: {
                    type: [
                        v => (!!v) || 'Please select a type'
                    ],
                    assignee: [
                        v => (!!v && Array.isArray(v) && v.length > 0) || 'Please select an assignee'
                    ]
                }
            }
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            title () { return this.item ? 'Edit Logistic' : 'Add Logistic' },
            saveObj () {
                return Object.assign({}, this.obj, {
                    id: this.item?.id || null,
                    dryRunId: this.item?.dryRunId || null,
                    cost: this.obj.cost ? this.obj.cost : 0,
                    slo_plan_id: this.item ? this.item.slo_plan_id : this.slo?.plan.slo_plan_id,
                    slo_logistic_users: this.obj.user_id,
                })
            }
        },
        methods: {
            async apply () {
                this.obj.completed = this.obj.completed ? true : false
                this.obj.cost = this.obj.cost ? parseFloat(this.obj.cost) : null

                if (this.item) {
                    if (this.dryRun) {
                        this.$emit('updated', this.saveObj)
                    } else {
                        let response = await this.$refs.crud.update(this.saveObj)
                        let sync = await this.syncUsers()
                        if (response.data.success) this.$emit('updated', this.saveObj)
                    }
                } else if (this.$refs.logistic.validate() && this.valid) {
                    if (this.dryRun) {
                        this.$emit('created', this.saveObj)
                    } else {
                        let response = await this.$refs.crud.create(this.saveObj)
                        if (response.data.success) this.$emit('created', {
                            ...this.saveObj,
                            id: response.data.logistics[0].id
                        })
                    }
                } else {
                    this.$emit('close')
                }
            },
            async syncUsers () {
                let endpoint = this.$models.getUrl('sloLogistic', 'create')
                let originalUserIds = this.item.slo_logistic_users.map(itm => itm.user_id)
                let currentUserIds = this.obj.user_id.concat([])
                let addedRequests = currentUserIds.filter(itm => !originalUserIds.includes(itm)).map(added => {
                    return this.$axios.post(`${endpoint}&action=add_user`, { id: this.item.id, user_id: added })
                })
                let removedRequests = originalUserIds.filter(itm => !currentUserIds.includes(itm)).map(removed => {
                    return this.$axios.post(`${endpoint}&action=remove_user`, { id: this.item.id, user_id: removed })
                })
                return Promise.all(addedRequests.concat(removedRequests))
            }
        },
        watch: {
            item: {
                handler (v) {
                    if (v && v !== true) {
                        this.obj.cost = v.cost,
                        this.obj.description = v.description
                        this.obj.slo_logistic_type_id = v.slo_logistic_type_id
                        this.obj.user_id = v.slo_logistic_users.map(itm => itm.user_id)
                        this.obj.completed = v.completed
                    } else {
                        this.obj.user_id = [this.sessionUser.user.id]
                    }
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>