<template>
    <div class="d-flex flex-column flex-fill" style="padding: 16px;" ref="chartContainer">
        <fe-crud
            v-if="messageParams"
            :config="$models.message"
            :readParams="messageParams"
            ref="messagesCrud"
            @read="v => messages = v"
            autoload
        />

        <fe-dialog
            v-if="viewMessages"
            title="Comments"
            width="800"
            dismissButtonText="Cancel"
            @close="viewMessages = false"
            @dismiss="viewMessages = false"
            @accept="viewMessages = false"
            :retain-focus="false"
        >
            <message
                dense
                :params="messageParams"
                @created="updateMessageCount"
                @deleted="updateMessageCount"
            />
        </fe-dialog>

        <!-- <div v-if="chartHeight && chartParams" class="mb-2 d-flex" :style="{height: chartHeight+'px' }"> -->
        <assessment-trendline :height="chartHeight" @chartClicked="chartClicked" v-if="chartHeight" :params="chartParams" class="mb-2" stopClick>
            <template v-slot:extra-tools>
                <fe-icon-btn color="#7E8494" usage="ghost" useIcon="fas fa-comment" @click="viewMessages = true" />
                <v-badge
                    v-if="messages.length"
                    color="pink"
                    :content="messages.length"
                    bordered
                    class="message-count"
                    @click="viewMessages = true"
                >
                </v-badge>
            </template>
        </assessment-trendline>
        <!-- </div> -->

        <div class="d-flex mb-1" :style="{height: gridHeight+'px'}">
            <fe-crud ref="crud"
                :config="$models.studentScoreDetail"
                :autoload="false"
                @read="details = $event"
            />

            <fe-grid
                :columnDefs="columns"
                :rowData="details"
                domLayout="normal"
                ref="gridGrid"
                displayDensity="small"
                :showToolbar="false"
                @rowClicked="loadDetail"
                class="pr-2"
                style="width: 50%;"
            >
            </fe-grid>

            <div style="width: 50%; border: 1px solid #E0E1E6; border-radius: 4px;" v-if="chartHeight">
                <highcharts class="fe-chart" ref='chartRef' :options="chartOptions"></highcharts>

            </div>
        </div>
    </div>
</template>

<script>
    import AssessmentTrendline from '@/components/charts/AssessmentTrendline'
    import Message from '@/components/common/Message'
    import windowOptionsMixin from '@/mixins/windowOptions'

    export default {
        name: 'AssessmentHistory',
        mixins: [ windowOptionsMixin ],
        components: {
            AssessmentTrendline,
            Message
        },
        props: {
            studentId: {
                required: true
            },
            subCategoryId: {
                required: true
            },
            dataPointScoreId: {
                required: true
            },
            dataPointTypeId: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                viewMessages: false,
                details: [],
                chartHeight: null,
                gridHeight: null,
                windows: [],
                series: [],
                detailTitle: '',
                messages: [],
                selectedDataPointScoreId: null
            }
        },
        mounted() {
            this.selectedDataPointScoreId = this.$props.dataPointScoreId
            let h = this.$refs.chartContainer.clientHeight

            this.chartHeight = parseInt(h * .65)
            this.gridHeight  = h - this.chartHeight

            this.$refs.crud.read({
                data_point_score_id: this.selectedDataPointScoreId
            })
        },
        computed: {
            messageParams() {
                return (this.studentId && this.selectedDataPointScoreId)
                    ? {
                        student_id: this.$props.studentId,
                        data_point_score_id: this.selectedDataPointScoreId
                    }
                    : null
            },
            chartOptions() {
                return {
                    chart: {
                        type: 'line',
                        height: this.gridHeight-20
                    },
                    title: {
                        text: this.detailTitle
                    },
                    plotOptions: {
                        series: {
                            fillOpacity: 0.1
                        }
                    },
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    xAxis: {
                        title: {
                            text: ''
                        },
                        categories: this.windows,
                        min: undefined
                    },
                    yAxis: {
                        endOnTick: false,
                        title: {
                            text: ''
                        },
                        min: undefined
                    },
                    series: this.series
                }
            },
            columns() {
                return this.detailParams ? [{
                    headerName: 'Detail',
                    field: 'score_detail_type_code',
                    flex: 1
                }, {
                    headerName: 'Value',
                    field: 'score_detail_detail_value',
                    maxWidth: 200,
                    cellStyle: { textAlign: 'right' }
                }] : []
            },
            detailParams() {
                if (!this.selectedDataPointScoreId) return false

                return {
                    data_point_score_id: this.selectedDataPointScoreId
                }
            },
            chartParams() {
                if (!this.$props.studentId || !this.$props.subCategoryId) return false

                return {
                    student_id: this.$props.studentId,
                    sub_category_id: this.$props.subCategoryId,
                    target_type_id: 1,
                    data_point_type_id: this.dataPointTypeId
                }
            }
        },
        methods: {
            updateMessageCount(rec, records) {
                this.messages = records
            },
            chartClicked(rec, evtt) {
                this.selectedDataPointScoreId = rec.data_point_score_id
                this.$refs.crud.read({
                    data_point_score_id: this.selectedDataPointScoreId
                })

                this.$refs.messagesCrud.read({
                    student_id: this.$props.studentId,
                    data_point_score_id: this.selectedDataPointScoreId
                })
            },
            loadDetail(meta) {
                let d = meta.data
                this.windows = []
                this.series  = []
                this.detailTitle = ''
                this.$modelGet('studentScores', {
                    student_id: this.$props.studentId,
                    sub_category_id: this.$props.subCategoryId,
                    score_detail_type_id: d.score_detail_type_id,
                    property: 'score_detail'
                }).then(details => {
                    if (details.length > 0) {
                        let detail = details[0]
                        this.detailTitle = detail.name
                        let serie = {
                            name: detail.name,
                            data: []
                        }

                        detail.chart_data.forEach(det => {
                            serie.data.push(parseFloat(det[detail.name]))
                            this.windows.push(det.data_point)
                        })
                        this.series = [serie]
                    }

                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.fe-chart ::v-deep * {
    font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
}

.message-count {
    left: -15px;
}
</style>
