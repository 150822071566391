<template>
    <div class="flex-fill flex-column no-scroll pa-4">
        <file-upload v-model="showUpload"/>

        <div class="d-flex">
            <!-- {{filters}} -->
            <fe-btn usage="ghost" useIcon="fas fa-chevron-left" @click="$router.push('/Uploads')">Back</fe-btn>
            <fe-filter-btn
                :items="statuses"
                title="Filter by Status"
                itemText="name"
                itemValue="id"
                v-model="filters.upload_status_id"
                :canExclude="false"
                :closeOnSelect="false"
            />

            <fe-filter-btn
                :items="uploadTypes"
                title="Filter by Type"
                itemText="name"
                itemValue="id"
                v-model="filters.upload_type_id"
                :canExclude="false"
                :closeOnSelect="false"
            />
        </div>

        <fe-dialog
            v-if="statusDialog.show"
            title="Modify Upload"
            @accept="setStatus"
            @dismiss="statusDialog.show=false"
            @cancel="statusDialog.show=false"
            acceptButtonText="Save"
            :acceptButtonDisabled="!statusDialog.rec && !statusDialog.uploadType"
        >
            <fe-remote-combo
                label="Upload Status"
                :items="statuses"
                v-model="statusDialog.rec"
                byId
            />

            <fe-remote-combo
                label="Upload Type"
                :items="uploadTypes"
                v-model="statusDialog.uploadType"
                byId
            />
        </fe-dialog>

        <fe-grid
            ref="grid"
            :columnDefs="columns"
            :rowData="gridItems"
            domLayout="normal"
            class="flex-grow-1"
            displayDensity="small"
            disableInlineCreate
            :gridOptionOverrides="gridOptionOverrides"
            @search="searchText = $event"
            @beginCreate="showUpload=true"
            @rowDoubleClicked="loadUpload"
            @rowSelected="selections = $refs.grid.gridApi.getSelectedRows()"
            @sortChanged="onSortChanged"
        >
            <template #toolbar-items>
                <fe-btn usage="ghost" useIcon="fas fa-sync-alt" @click="loadData"/>
                <v-menu offset-y v-if="selections.length > 0 && ($isSupport() || $hasSecurity('MANAGE_UPLOAD_TOOLS'))">
                    <template v-slot:activator="{ on }">
                        <fe-btn usage="secondary" v-on="on">Tools</fe-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="statusDialog.show = true; statusDialog.rec = null; statusDialog.uploadType=null">
                            <v-list-item-content>
                                <v-list-item-title>Modify Upload</v-list-item-title>
                                <v-list-item-subtitle>Change the properties of an upload.  Example: A COMPLETED status can be changed to READY to re-run upload</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="runSelections('validate')">
                            <v-list-item-content>
                                <v-list-item-title>Run Validation Now</v-list-item-title>
                                <v-list-item-subtitle>Validate without importing the selected uploads immediately</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="runSelections('import')">
                            <v-list-item-content>
                                <v-list-item-title>Run Import Now</v-list-item-title>
                                <v-list-item-subtitle>Import the selected uploads immediately.  Please be cautious of user load and importing rosters during the day  </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list>
                </v-menu>
            </template>

        </fe-grid>

        <div class="d-flex pt-2 pr-10">
            <v-spacer/>
            <v-btn text icon small @click="currPage=1">
                <v-icon>fas fa-chevron-left</v-icon>
            </v-btn>
            <fe-label class="mt-3 mr-5"> Page</fe-label>
            <div style="width: 70px;">
                <v-select flat solo dense :items="pages" v-model="currPage"/>
            </div>
            <fe-label class="mt-3 ml-5"> of {{ pages.length}}</fe-label>
            <v-btn text icon small @click="currPage=pages.length">
                <v-icon>fas fa-chevron-right</v-icon>
            </v-btn>
        </div>

        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.upload"
            @read="gridItems = $event"
            :readParams="{sort: 'created'}"
        />
    </div>
</template>

<script>
    import FileUpload from './FileUpload'
    import lodash from 'lodash'
    import DownloadColumn from './renderers/DownloadColumn'

    export default {
        name: 'Uploads',
        props: ['uploadStatusId'],
        components: {
            FileUpload,
        },
        data() {
            let me = this
            return {
                pages: [],
                currPage: 1,
                totalCount: 0,
                searchText: null,
                currSort: null,
                currSortDirection: null,
                showUpload: false,
                gridItems: [],
                gridOptionOverrides: {
                    suppressScrollOnNewData: true,
                    immutableData: true,
                    getRowNodeId: data => data.id,
                },
                statuses: [],
                uploadTypes: [],
                selections: [],
                statusDialog: {
                    show: false,
                    rec: null
                },
                filters: {
                    upload_status_id: {
                        included: []
                    },
                    upload_type_id: {
                        included: []
                    }
                }
            }
        },
        watch: {
            searchText: lodash.debounce(function(v) {
                this.loadData()
                this.currPage = 1
            }, 500),
            currPage(v) {
                this.loadData()
            },
            showUpload(v) {
                if (!v) {
                    this.loadData()
                }
            },
            uploadStatusId:{
                handler(v) {
                    if (v) {
                        this.filters.upload_status_id.included = [{id: v}]
                    } else {
                        this.filters.upload_status_id.included = []
                    }
                },
                immediate: true
            },
            filters: {
                deep: true,
                handler(v) {
                    this.currPage = 1
                    if (v && this.$refs.crud) {
                        this.loadData()
                    }
                }
            },
            'statusDialog.show'(v) {
                this.statusDialog.rec = null
                // When bringing up the modify modal, refresh upload types in case user
                // has created new ones within their current session
                if (v) {
                    this.$modelGet('uploadType', {active: 1}).then(response => {
                        this.uploadTypes = response
                    })
                } else {
                    this.selections = []
                    this.$refs.grid.editCancel()
                }
            }
        },
        computed: {
            columns() {
                let me = this
                let arr = [this.$grid.checkColumn(), {
                    headerName: 'Upload #',
                    field: 'id',
                    sortable: true,
                    width: 100
                }, {
                    headerName: "Status",
                    field: "status_name",
                    sortable: true,
                    width: 150,
                    cellStyle(meta) {
                        let color = null

                        switch (meta.value) {
                            case 'READY':
                                color = '#b3ffba'
                                break
                            case 'ERRORED':
                                color = '#ff9c9c'
                                break
                            case 'VALIDATION':
                                color = 'yellow'
                                break
                        }

                        return {
                            'background-color': color
                        }
                    },
                    cellRenderer(v) {
                        return v.value + (v.data.queued ? ' <i class="far fa-clock" title="Queued on ' + me.$dayjs(v.data.queued).format('LLL') + '"/>' : '')
                    }
                }, {
                    headerName: 'File Type',
                    field: 'upload_file_type_name',
                    sortable: false,
                    flex: 1,
                    hide: true
                }, {
                    headerName: "Upload Name",
                    field: "upload_description",
                    sortable: true,
                    flex: 1
                }, {
                    headerName: "Upload Type",
                    field: "upload_type_name",
                    sortable: true,
                    flex: 1
                }, {
                    headerName: 'File Location',
                    field: 'location',
                    sortable: false,
                    flex: 1,
                    hide: true
                }, {
                    headerName: "Uploaded By",
                    field: "full_name",
                    sortable: true,
                    flex: 1
                }, {
                    headerName: "Created",
                    field: "created",
                    sortable: true,
                    flex: 1,
                    cellRenderer(v) {
                        return me.$dayjs(v.value).format('LLL')
                    }
                }, {
                    headerName: "Rows Processed",
                    field: "rows_processed",
                    sortable: true,
                    width: 100,
                    cellStyle: {
                        textAlign: 'right',
                        color: 'gray'
                    }
                }, {
                    headerName: "Errors",
                    field: "error_count",
                    sortable: true,
                    width: 100,
                    cellStyle(v) {
                        return {
                            cursor: 'pointer',
                            textAlign: 'right',
                            color: v.data.error_count > 0 ? 'red' : 'gray'
                        }
                    }
                }, {
                    headerName: "Warnings",
                    field: "warn_count",
                    sortable: true,
                    width: 100,
                    cellStyle(v) {
                        return {
                            cursor: 'pointer',
                            textAlign: 'right',
                            color: v.data.warn_count > 0 ? 'orange' : 'gray'
                        }
                    }
                }, this.$grid.iconColumn('Detail', 'fas fa-search-plus', (v) => {
                    me.loadDetail(v.data)
                }), {
                    headerName: 'Download',
                    maxWidth: 100,
                    cellRendererFramework: DownloadColumn,
                    cellRendererParams: {
                        icon: 'fas fa-download',
                        enabledField: 'is_downloadable',
                        disabledTooltip: 'You do not have permission to download this file.',
                    },
                    onCellClicked: (v) => {
                        // me.loadDetail(v.data)
                        if (v.data.is_downloadable) {
                            me.downloadFile(v.data)
                        }
                    },
                },
                ]

                return arr
            }
        },
        mounted() {
            this.loadStatus()
            this.loadData()
        },
        methods: {
            setStatus() {
                this.selections.forEach(rec => {
                    if (this.statusDialog.uploadType) rec.upload_type_id = this.statusDialog.uploadType
                    rec.upload_status_id = this.statusDialog.rec
                })

                this.$refs.crud.update(this.selections)
                    .then(() => { this.loadData() })
                    .finally(() => { this.statusDialog.show = false })
            },
            runSelections(property) {
                this.$confirmCreate(this.selections, () => {
                    this.$axios.post('uploads.php?action=run_upload&property=' + encodeURIComponent(property), { uploads: this.selections })
                        .then(resp => {
                            this.$ecResponse(resp)
                        }).finally(() => {
                            this.loadData()
                        })
                }, 'run')
            },
            downloadFile(data) {
                this.$axios.get('uploads.php?action=get&property=download&id='+data.id, {
                    responseType: 'blob'
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let filename = data.location.replace(/^.*[\\\/]/, '')
                    link.setAttribute('download', filename); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => console.warn(error));
                //'' + rec.get('id')
            },
            loadDetail(data) {
                let me = this
                // only refresh table if the user actually changes something
                let dirty = false
                me.$store.commit('global/addDockableWindow', {
                    name: 'Upload Detail - ' + data.id,
                    // data: row,
                    component: 'upload-detail',
                    attrs: {
                        id: data.id,
                    },
                    events: {
                        uploadChanged() {
                            dirty = true
                        },
                        close() {
                            if (dirty) {
                                me.loadData()
                            }
                        }
                    }
                })
            },
            loadStatus() {
                this.$axios.get('uploads.php?action=get&property=status')
                    .then(response => {
                        this.statuses = response.data.status
                    })

                this.$modelGet('uploadType', {active: 1})
                    .then(response => {
                        this.uploadTypes = response
                    })
            },
            onSortChanged(grid) {
                let sortModel = grid.api.getSortModel()
                if (sortModel.length > 0) {
                    // Only single sort is permitted - no multisort at time of writing
                    this.currSort = sortModel[0].colId
                    this.currSortDirection = sortModel[0].sort
                }

                this.loadData() // Pagination is server-side, so data must be reloaded
            },
            loadData() {
                let v = this.filters
                let params = {
                    search: this.searchText,
                    page: this.currPage,
                    sort: this.currSort,
                    dir: this.currSortDirection?.toUpperCase(),
                    limit: 100,
                }
                Object.keys(v).forEach(key => {
                    if (v[key] && v[key].included) {
                        v[key].included.forEach(sel => {
                            if (!params[key]) params[key] = ''
                            params[key] += sel.id + ','})
                    } else {
                        // params[key] = ''
                    }
                })

                this.$setLoading(true)
                this.$refs.crud.read(params).then(r => {
                    this.totalCount = r.data.totalCount
                    let pageCount = Math.ceil(this.totalCount / 100)
                    this.pages = []
                    for (let i=0; i<pageCount; i++) {
                        this.pages.push(i+1)
                    }
                }).finally(() => { this.$setLoading(false)})
            },
            loadUpload(row) {
                let me = this

                this.$store.commit('global/addDockableWindow', {
                    name: 'Upload - ' + row.data.upload_description,
                    // data: row,
                    component: 'validate-upload',
                    attrs: {
                        id: row.data.id
                    },
                    events: {
                        close() {
                            me.loadData()
                        }
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
