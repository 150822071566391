<template>
    <div class="fc-wrapper">
        <div class="d-flex fc-header" @click="toggleExpand">
            <div class="fc-title" v-html="title"></div>
            <v-spacer/>
            <div v-if="!global" class="fc-tools">
                <fe-icon-btn
                    :useIcon="icon"
                    usage="ghost"
                    class="fc-btn"
                    x-small
                />
            </div>
        </div>

        <div v-if="isExpanded" class="fc-body">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: "FieldContainer",

    props: {
        title: {
            type: String,
            default: 'Filters'
        },
        expanded: {
            type: Boolean,
            default: false
        },
        global: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            isExpanded: true,
            expandedText: '-'
        }
    },

    computed: {
        icon() {
            let icon = 'fas fa-chevron-'
            icon += this.isExpanded ? 'up' : 'down'
            return icon
        }
    },

    watch: {
        expanded: {
            immediate: true,
            handler(v) {
                this.isExpanded = v
                this.setExpandedText()
            }
        }
    },

    methods: {
        doResize() {
            this.isExpanded = !this.isExpanded;
            this.setExpandedText();
        },

        toggleExpand() {
            if (!this.global) {
                this.isExpanded = !this.isExpanded
            }
        },

        setExpandedText() {
            if (this.isExpanded) {
                this.expandedText = '-';
            } else {
                this.expandedText = '+';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.fc-wrapper {
    margin-bottom: 16px;

    .fc-header {
        cursor: pointer;

        .fc-title {
            font-size: 14px;
            padding: 4px 8px;
        }
    }

    .fc-body {
        background: #F5F6F7;
        border-radius: 5px;
        padding: 8px 16px 0 16px;
        margin-bottom: 16px;
    }
}
</style>
