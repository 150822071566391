<template>
<div>
    <button @click="dialog=true" >{{displayName}}</button>
    <v-dialog v-model="dialog" width="300">
        <fe-stepper-filter
            :title="title" style="height:400px;"
            @apply="emit" @close="dialog=false"
            :applyDisabled="!enableFinalPanel"
        >

        <fe-stepper-checkbox-panel
            v-model="genders" :items="genderItems" width="100%"
            itemValue="value" itemText="display_name"
        />
        <v-divider vertical style="background-color:#E0E1E6"/>

        <template #footer>
            <div class="text-truncate" style="padding:14px">{{breadcrumb}}</div>
        </template>

        </fe-stepper-filter>
    </v-dialog>
</div>
</template>

<script>
import * as Util from '../../Util'

export default {
    name: 'GenderPanel',
    rank: 1,

    watch: {
        dialog(v) {
            if(!v) this.oldFilter = null
            v ? this.loadGenders() : this.clear()
        },
    },

    computed: {
        title() {
            return this.oldFilter ? `Edit ${this.displayName} Filter` : `Create ${this.displayName} Filter`
        },
        enableFinalPanel() {
            return !this.$_.isEmpty(this.genders)
        },
        breadcrumb() {
            return this.genders.map(x=>x.gender)
                .filter(Boolean)
                .join(', ')
        },
    },

    methods: {
        clear(panels) {
            this.restrict = true
            this.genders = []
            this.school_year = null
        },
        load(oldFilter) {
            this.oldFilter = oldFilter
            this.dialog = true
            let ep = oldFilter.editorParams

            this.restrict = ep.restrict
            this.genders = ep.genders
            this.school_year = ep.school_year
        },
        emit() {
            this.dialog = false
            let uniqueId = Util.UUIDv4()
            let name = this.genders.map(x => x.display_name).join(', ')

            // combine ids from singles and groups
            let ids = []
            this.genders.forEach(m => {
                if (!m.display_name_group) {
                    ids.push(m.value)
                } else {
                    ids = ids.concat(m.display_name_group)
                }
            })

            let filter = {
                type: this.type,
                id: uniqueId,
                restrict: this.restrict,
                report: true,
                params: this.$_.pickBy({
                    gender: ids,
                    school_year_id: this.school_year?.id,
                }, x => !this.$_.isBlank(x)),
            }
            Object.defineProperties(filter, {
                editor: {value: this},
                editorParams: {value: {
                    restrict: this.restrict,
                    genders: this.genders ? [...this.genders] : null,
                    school_year: this.school_year ? {...this.school_year} : null,
                }},
                chipText: {value: name},
                tooltipData: {value: this.$_.pickBy({
                    'Genders': name,
                    'Restrict': this.restrict,
                    'School Year': this.school_year?.name,
                }, x => !this.$_.isBlank(x))}
            })
            this.$emit('input', filter, this.oldFilter)
        },
        loadGenders() {
            this.$axios.get(this.$models.getUrl('gender', 'read'))
                .then((response) => {
                    if (response && response.data) {
                        let data = response.data.gender
                        let filtered = []
                        // if available, use display_name
                        data.forEach(itm => {
                            if (!itm.display_name_group) {
                                itm.display_name = itm.display_name || itm.value
                                filtered.push(itm)
                            } else {
                                let o = data.find(x => x.value == itm.display_name_group[0]) // find and return first option within display_name_group; id can be string
                                if (!filtered.find(x => x.display_name === o.display_name )) {
                                    o = {
                                        ...o,
                                        display_name: o.display_name || o.value // use display_name instead of value
                                    }
                                    filtered.push(o)
                                }
                            }
                        })
                        this.genderItems = filtered
                    }
                })
        }
    },

    data() {
        return {
            oldFilter: null,
            displayName: 'Gender',
            type: 'gender',
            dialog: false,
            genderItems: [],

            restrict: true,
            genders: [],
            school_year: null,
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
