export default {
    data() {
        return {
            studentCard: {
                show: false,
                studentRecord: {}
            },
            lastStudentOver: null
        }
    },
    methods: {
        doSelect(item) {
            this.$data.gridApi = item.api
            if (this.slo.create) {
                if (item.node.selected) {
                    this.slo.studentIds.push(item.data.student_id)
                } else {
                    this.slo.studentIds = this.slo.studentIds.filter(x => x !== item.data.student_id)
                }
                this.slo.studentIds = [...new Set(this.slo.studentIds)]
            } else {
                if (item.node.selected) {
                    this.$data.selectedItems.push(item)
                } else {
                    this.removeFromArr(this.$data.selectedItems, item)
                }
            }
        },
        doCellOver(meta) {
            if (meta.colDef.field === "student_full_name" && meta.data.student_id) {
                if (this.lastStudentOver === meta.data.student_id) {
                    this.studentCard.show = true
                } else {
                    let studentData = null

                    this.$axios.get(
                        'students.php?action=get&property=grade_history&id=' + meta.data.student_id + '&school_year_id=' + meta.data.school_year_id
                    ).then(response => {
                        if (response && response.data) {
                            studentData = response.data[0]
                            studentData.grade_desc = meta.data.grade || meta.data.grade_desc // make sure we show current grade level in student card
                        }
                    }).finally(() => {
                        this.studentCard = {
                            ...this.studentCard,
                            show: true,
                            imageUrl: this.$axios.defaults.baseURL + 'students.php?action=get&property=student_image&id=' + meta.data.student_id,
                            studentRecord: studentData,
                            top: meta.event.clientY,
                            left: meta.event.clientX
                        }

                        this.lastStudentOver = meta.data.student_id
                    })
                }
            } else {
                this.doCellOut(meta)
            }
        },
        doCellOut(meta) {
            if (meta.colDef.field !== "student_full_name") {
                this.studentCard.show = false
            }
        },
        goToStudentProfile(student_id, newTab=false) {
            if (!newTab) {
                this.$router.push({path: `/StudentProfile/${student_id}`})
            } else {
                let route = this.$router.resolve({ path: `/StudentProfile/${student_id}` });
                window.open(route.href);
            }
        },
    }
}
