<template lang="html">
<v-layout row align-end class="search-panel" wrap>
    <v-flex style="max-width:192px;" align-self-center>
        <h2>Students 3D</h2>
    </v-flex>
    <v-flex style="max-width:200px;">
        <fe-remote-combo
            class="pr-3"
            label="School Year"
            hide-details
            v-model="schoolYear"
            rootProperty="years"
            ref="schoolYearCombo"
            url="qry.php?action=get&property=school_years"
            :rules="[v => !!v || 'Field is required']"
        ></fe-remote-combo>
    </v-flex>

    <v-flex class="combo">
        <fe-remote-combo
            class="pr-3"
            label="Schools"
            multiple moreText hide-details
            v-model="schools"
            rootProperty="schools"
            url="schools.php?action=get&incl_groups=1"
            :rules="[v => !!v || 'Field is required']"
            :disabled="!schoolYear"
            :params="{ school_year_id: schoolYear ? schoolYear.id : null }"
        ></fe-remote-combo>
    </v-flex>

    <v-flex class="combo">
        <fe-remote-combo
            class="pr-3"
            label="Grades"
            multiple moreText hide-details
            v-model="grades"
            rootProperty="grades"
            url="grades.php?"
            :rules="[v => !!v || 'Field is required']"
            :disabled="!schoolYear"
            :params="{
                school_year_id: schoolYear ? schoolYear.id : null,
                school_id: schools ? schools.map(s => s.id) : null,
            }"
        ></fe-remote-combo>
    </v-flex>

    <v-flex >
        <FeSplitMenuBtn
            :btnAttrs="{ disabled: !schoolYear }"
            :close-on-content-click="false"
            offset-y
            style="position:relative; top:4px;"
            @click="$emit('input', params)"
        >
            Search
            <template slot="menu">
                <v-list style="padding: 20px 5px;">
                    <v-list-item
                        style="width: 200px; height: 75px; margin-top: 20px;"
                    >
                        <v-list-item-content style="height: 88px;">
                            <div class="theme--light v-label">
                                Active Status
                            </div>
                            <v-select
                                :items="activeStatusItems"
                                v-model="student_active_flag"
                                flat solo dense
                            ></v-select>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content style="height: 88px;">
                            <FeDatePicker
                                v-model="student_active_date"
                                label="Active as Of"
                            />
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </FeSplitMenuBtn>

    </v-flex>

</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import Students3D from '@/components/modules/students3d/View'

export default {
    name: 'DemographicSearch',
    components: {
        Students3D,
    },

    computed: {
        ...mapState('global',['sessionUser', 'defaultSchool']),
        params() {
            return {
                school_year_id: this.schoolYear.id,
                ...(this.schools) && { school_id: this.schools.map(x => x.id) },
                ...(this.grades) && { grade_id: this.grades.map(x => x.id) },
                ...(this.student_active_flag !== null) && { student_active_flag: this.student_active_flag },
                ...(this.student_active_date !== null) && { student_active_date: this.student_active_date },
            }
        },
        currentYear() {
            return this.sessionUser.currentYear
        }
    },
    
    mounted() {
        this.schoolYear = this.sessionUser.currentYear
        if (this.defaultSchool) {
            this.schools = [this.defaultSchool]
        }
    },
    
    data() {
        return {
            feDialog: false,
            cmp: Students3D,

            schoolYear: null,
            schools: null,
            grades: null,
            student_active_flag: 1,
            student_active_date: null,
            activeStatusItems: [
                { text: 'Inactive', value: 0 },
                { text: 'Active', value: 1 },
                { text: 'Both', value: null },
            ],
        }
    }
}
</script>

<style lang="css" scoped>
h2 {
    padding-left: 8px;
    padding-top: 8px;
    font-size: 24px;
    font-weight: 600;
}
.combo {
    width: 200px;
    min-width: 200px;
    max-width: 360px;
}
.search-panel {
    padding: 8px;
    background-color: white;
    /* border-bottom-color: rgb(224,225,230);
    border-bottom-style: solid;
    border-bottom-width: 1px; */
}

</style>
