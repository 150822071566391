<template>
    <div>
        <div class="d-flex flex-fill flex-column mt-5" style="overflow: auto; height: calc(100vh - 250px);">
            <draggable
                v-model="actionPlan"
                @change="handleObjectiveOrder"
                @start="handleStartOrder"
                @end="handleEndOrder"
                :move="handleCheckMove"
                :removeCloneOnHide="false"
                :scroll-sensitivity="300"
                :force-fallback="true"
                ghostClass="ap-draggable-ghost-class"
            >
                <template v-for="(objective, index) in actionPlan">
                    <objectives
                        :objective="objective"
                        @edit-objective="create.objective = true"
                        @duplicate-objective="create.objective = true; duplicate.objective = true"
                        @delete-objective="handleDeleteObj"
                        @edit-goal="create.goal = true"
                        :goals="goals"
                        @unlink-goal="handleUnlinkGoal"
                        @delete-goal="handleDeleteGoal"
                        @dismiss-task="handleCloseActionItem"
                        @reorder-goals="handleGoalOrder"
                    />
                </template>
            </draggable>
        </div>

        <create-objective
            v-if="create.objective"
            :activeDashboard="activeDashboard"
            :activeObjective="listObjectives.length == 1 ? listObjectives[0] : {}"
            :duplicateObj="duplicate.objective"
            :goals="goals"
            @close="create.objective = false; $emit('close')"
            @save="handleSave"
        />

        <create-goal
            v-if="create.goal"
            :activeDashboard="activeDashboard"
            :objectives="listObjectives"
            @close="create.goal = false; $emit('close')"
            @save="handleSaveGoal"
            :getSavedSearchValues="getSavedSearchValues"
            :unPackConfig="unPackConfig"
        />

        <edit-action-item
            v-if="create.task"
            :goals="goals"
            @save="handleCloseActionItem"
            @dismiss="handleCloseActionItem"
        />

        <action-plan-print
            v-if="openPrintView"
            :activeDashboard="activeDashboard"
            :actionPlan="actionPlan"
            @close="$emit('close-print')"
        />

        <fe-crud ref="objCrud" :config="objCrudConfig"/>
        <fe-crud ref="goalCrud" :config="goalCrudConfig"/>
        <fe-crud ref="taskCrud" :config="taskCrudConfig"/>
        <fe-crud ref="objGoalCrud" :config="objGoalCrudConfig"/>
        <fe-crud ref="fetchGoalCrud" :config="goalFetchCrudConfig"/>
    </div>
</template>

<script>
import Objectives from './Objectives'
import CreateObjective from './create/Objective'
import CreateGoal from './create/Goal'
import {mapState} from "vuex"
import EditActionItem from '../actionItems/EditActionItem'
import Vue from 'vue'
import draggable from 'vuedraggable'
import ActionPlanPrint from './Print'

export default {
    name: 'ActionPlanIndex',

    components: {Objectives, CreateObjective, CreateGoal, EditActionItem, draggable, ActionPlanPrint},

    props: {
        canEdit: {type: Number, default: 0},
        activeDashboard: {type: Object, default: () => {}},
        createObjective: {type: Boolean, default: false},
        createGoal: {type: Boolean, default: false},
        createTask: {type: Boolean, default: false},
        getSavedSearchValues: {type: Function},
        unPackConfig: {type: Function},
        openPrintView: {type: Boolean, default: false},
    },

    data() {
        return {
            objectives: [],
            goals: [],
            tasks: [],
            actionPlan: [],
            create: {
                objective: false,
                goal: false,
                task: false
            },
            listObjectives: [],
            duplicate: {
                objective: false,
                task: false
            },
            unlinkGoal: false,
            print: false,
        }
    },

    computed: {
        ...mapState('actionPlan', ['objective', 'goal', 'objDragging', 'goalDragging']),
        objCrudConfig() {
            let cfg = this.$_.cloneDeep(this.$models.dashObjective)
            cfg.read.params.kpi_dashboard_id = this.activeDashboard.id
            return cfg
        },
        goalFetchCrudConfig() {
            let cfg = this.$_.cloneDeep(this.$models.objectiveGoals)
            cfg.read.params.kpi_dashboard_id = this.activeDashboard.id
            return cfg
        },
        taskCrudConfig() {
            let cfg = this.$_.cloneDeep(this.$models.task)
            cfg.read.params.kpi_dashboard_id = this.activeDashboard.id
            return cfg
        },
        objGoalCrudConfig() {
            let cfg = this.$_.cloneDeep(this.$models.apObjectiveGoal)
            cfg.read.params.kpi_dashboard_id = this.activeDashboard.id
            return cfg
        },
        goalCrudConfig() {
            let cfg = this.$_.cloneDeep(this.$models.dashGoals)
            cfg.read.params.kpi_dashboard_id = this.activeDashboard.id
            return cfg
        },
        dummyActionPlan() {
            return [
                {
                    "id": 0,
                    "name": "Sample Objective",
                    "goals": [
                        {
                            "id": 0,
                            "default_statement": "Sample Goal",
                            "tasks": [
                                {
                                    "name": "Sample Task",
                                    "assignees": [
                                        {
                                            "user_fname": "Sample",
                                            "user_full_name": "User, Sample"
                                        }
                                    ],
                                    "due_dt": "2020-12-25",
                                    "task_status_id": 1,
                                    "description": "Sample Task Description",
                                    "created_by_full_name": "Creator, Sample",
                                    "notes": [
                                        {
                                            "comment": "Sample Action Item Comment",
                                            "canDelete": false,
                                            "created": "2020-12-25",
                                            "user_fname": "Sample",
                                            "user_full_name": "User, Sample"
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
    },

    watch: {
        createObjective(v) {
            this.create.objective = v
        },
        createGoal(v) {
            this.create.goal = v
        },
        createTask(v) {
            this.create.task = v
        }
    },

    mounted() {
        this.loadData()
    },

    methods: {
        loadData() {
            let transactions = [
                this.fetchObjectives(),
                this.fetchGoals(),
                this.fetchTasks()
            ]

            Promise.all(transactions).then(() => {
                this.loadActionPlan()
            })
        },

        fetchObjectives() {
            return this.$refs.objCrud.read()
                .then(resp => {
                    this.objectives = resp.data.action_plan_objective
                })
        },

        fetchGoals() {
            return this.$refs.fetchGoalCrud.read()
                .then(resp => {
                    this.goals = resp.data.goal_v
                })
        },

        fetchTasks() {
            return this.$refs.taskCrud.read()
                .then(resp => {
                    this.tasks = resp.data.tasks
                })
        },

        loadActionPlan() {
            this.$store.commit('actionPlan/set', {
                key: 'activeDashboard',
                value: this.activeDashboard
            })
            if (this.objectives.length || this.goals.length || this.tasks.length) {
                let objectives = this.objectives
                let goals = this.goals
                let tasks = this.tasks
                let dashName = this.activeDashboard.name
                /** Step 1: Sort Action Items (tasks) */
                let tasksByGoalId = {
                    none: []
                }
                this.$_.orderBy(tasks, ['rank']).forEach(task => {
                    if (task.goal_id) {
                        let goalId = task.goal_id
                        if (!tasksByGoalId.hasOwnProperty(goalId)) {
                            tasksByGoalId[goalId] = []
                        }
                        tasksByGoalId[goalId].push(task)
                    } else {
                        tasksByGoalId.none.push(task)
                    }
                })

                /** Step 2: Sort Goals and attach Items */
                let goalsByObjectiveId = {
                    none: []
                }
                goals.forEach(goal => {
                    goal.active = true
                    if (tasksByGoalId.hasOwnProperty(goal.id)) {
                        goal.tasks = tasksByGoalId[goal.id]
                    } else {
                        goal.tasks = []
                    }
                    if (goal.action_plan_objective_id) {
                        let objectiveId = goal.action_plan_objective_id
                        if (!goalsByObjectiveId.hasOwnProperty(objectiveId)) {
                            goalsByObjectiveId[objectiveId] = []
                        }
                        goalsByObjectiveId[objectiveId].push(goal)
                    } else {
                        goalsByObjectiveId.none.push(goal)
                    }
                })

                /** Step 3: Finalize Objectives, Goals, and Tasks */
                let actionPlan = objectives.map(objective => {
                    objective.active = true
                    if (goalsByObjectiveId.hasOwnProperty(objective.id)) {
                        objective.goals = this.$_.sortBy(goalsByObjectiveId[objective.id], ['rank'])
                    } else {
                        objective.goals = []
                    }
                    return objective
                })
                actionPlan = this.$_.sortBy(actionPlan, ['rank'])
                if (goalsByObjectiveId.none.length) {
                    actionPlan.push({
                        name: dashName + ' Goals',
                        active: true,
                        goals: goalsByObjectiveId.none
                    })
                }
                if (tasksByGoalId.none.length) {
                    actionPlan.push({
                        name: dashName + ' Action Items',
                        active: true,
                        goals: [{
                            active: true,
                            tasks: tasksByGoalId.none
                        }]
                    })
                }
                this.listObjectives = this.objectives.length ? this.objectives : actionPlan
                this.actionPlan = !actionPlan.length ? this.dummyActionPlan : actionPlan
            } else {
                this.actionPlan = this.dummyActionPlan
            }
        },

        handleSave(config) {
            let rank = this.objectives.length
            let data = {
                name: config.name,
                kpi_dashboard_id: this.activeDashboard.id,
                rank: rank
            }
            let dispatch = 'create'
            if (config.id && !this.duplicate.objective) {
                data.id = config.id
                data.rank = config.rank
                dispatch = 'update'
            }
            let payload = {
                action_plan_objective: [data]
            }
            this.$refs.objCrud[dispatch](payload)
                .then((response) => {
                    let linkTransactions = []
                    if (config.goal) {
                        linkTransactions.push(this.linkGoals({
                            action_plan_objective_id: response.data.action_plan_objective[0].id,
                            goal_id: config.goal.id,
                            rank: config.goals ? config.goals.length : 0
                        }))
                    } else {
                        linkTransactions.push(Promise.resolve(null))
                    }
                    Promise.all(linkTransactions).then(() => {
                        setTimeout(function () {
                            this.loadData()
                            this.duplicate.objective = false
                        }.bind(this), 100)
                    })
                })
        },

        handleSaveGoal(data, savedSearch) {
            let transactions = []
            if (data.assessment) {
                let ss = {
                    target_descriptor_id: savedSearch.targetDescriptorId.map(item => item.id)
                }
                transactions.push(this.savedSearchCreate(ss))
            } else {
                transactions.push(Promise.resolve(null))
            }

            Promise.all(transactions).then(response => {
                let goalConfig = this.$_.cloneDeep(data)
                if (!goalConfig.type) goalConfig.type = 'count'
                let savedSearchId = response && response[0]?.data?.saved_searches[0] ? response[0].data.saved_searches[0].id : null
                let config = Object.assign(goalConfig, {
                    saved_search_id: savedSearchId,
                    kpi_dashboard_id: this.activeDashboard.id,
                    kpi_config_id: null,
                    default_statement: null
                })
                if (goalConfig.visualization?.id) {
                    config.kpi_config_id = data.visualization.id
                    config.default_statement = this.setGoalStatement(goalConfig, goalConfig.visualization)
                } else {
                    config.type = null
                    config.operator = 'equal'
                    config.value = 1
                }
                if (!config.custom_statement) config.custom_statement = null
                if (!config.description) config.description = null
                let dispatch = 'create'
                if (goalConfig.id && !this.unlinkGoal) {
                    dispatch = 'update'
                }
                delete config.action_plan_objective_id
                delete config.action_plan_objective_link_id
                delete config.rank
                delete config.tasks
                delete config.active
                delete config.visualization
                delete config.assessment
                this.$refs.goalCrud[dispatch](config)
                    .then(response => {
                        let obj = goalConfig.objective ? this.objectives.find(o => o.id == goalConfig.objective) : null
                        let rank = 0
                        if (obj) rank = obj.goals.length
                        let existingObjectiveId = this.goal ? this.goal.action_plan_objective_id : null
                        let existingObjectiveLinkId = existingObjectiveId ? this.goal.action_plan_objective_link_id : null
                        let newObjectiveId = goalConfig.objective ? goalConfig.objective : null
                        let linkTransactions = []
                        if (existingObjectiveId !== newObjectiveId) {
                            if (existingObjectiveId) {
                                linkTransactions.push(
                                    this.unlinkGoals(existingObjectiveLinkId)
                                )
                            }
                            if (newObjectiveId) {
                                linkTransactions.push(
                                    this.linkGoals({
                                        goal_id: response.data.goal[0].id,
                                        action_plan_objective_id: newObjectiveId,
                                        rank: rank
                                    })
                                )
                            }
                        } else {
                            linkTransactions.push(Promise.resolve(null))
                        }
                        Promise.all(linkTransactions).then(() => {
                            setTimeout(function () {
                                this.loadData()
                                this.$emit('reload')
                            }.bind(this), 100)
                        })
                    })
            })
        },

        handleDeleteObj(obj) {
            let payload = {action_plan_objective: [obj]}
            this.$confirmDelete(1, () => {
                this.$refs.objCrud.destroy(payload)
                    .then(() => {
                        this.loadData()
                    })
            })
        },

        handleDeleteGoal(goal) {
            this.$confirmDelete(1, () => {
                this.$refs.goalCrud.destroy(goal)
                    .then(() => {
                        this.loadData()
                    })
            })
        },

        handleUnlinkGoal() {
            Vue.prototype.$messageBox({
                title: 'Confirm Unlink',
                persistent: true,
                message: 'Are you sure you want to unlink this Goal from this Objective?',
                maxWidth: '500',
                actions: [{
                    text: 'Cancel',
                    usage: "ghost"
                }, {
                    text: 'Unlink',
                    usage: 'danger',
                    onClick: () => {
                        let existingObjectiveId = this.goal ? this.goal.action_plan_objective_id : null
                        let existingObjectiveLinkId = existingObjectiveId ? this.goal.action_plan_objective_link_id : null
                        if (existingObjectiveLinkId) {
                            this.unlinkGoals(existingObjectiveLinkId)
                        }
                    }
                }]
            })
            this.unlinkGoal = false
        },

        setGoalStatement(goalConfig, visConfig) {
            let name = visConfig.name
            let operator = visConfig.goal_operator
            let goal_config = this.unpackConfigIds(goalConfig)
            if (goal_config.operator) {
                operator = goal_config.operator
            }
            switch (operator) {
                case 'equal':
                    operator = ' equal to '
                    break
                case 'less':
                    operator = ' less than '
                    break
                case 'greater':
                    operator = ' greater than '
                    break;
            }
            let value = goal_config.value || parseFloat(visConfig.goal_value)
            let type = goal_config.type === 'pct' ? ' based on the percentage of students.' : '.'
            return "For the data in " + name + " we hope to attain a value" + operator + value + type
        },

        unpackConfigIds(config) {
            let unpacked = {}
            this.$_.forEach(config, (value, key) => {
                let newValue
                if (!value) {
                    newValue = value
                } else if (this.$_.isArray(value)) {
                    newValue = value.map(x => x.hasOwnProperty('id') ? x.id : x)
                } else {
                    newValue = value.hasOwnProperty('id') ? value.id : value
                }
                unpacked[key] = newValue
            })
            return unpacked
        },

        unlinkGoals(id) {
            let payload = {
                action_plan_objective_goal: [{id: id}]
            }
            this.$refs.objGoalCrud.destroy(payload)
                .then(() => {
                    this.loadData()
                })
        },

        linkGoals(item) {
            let dispatch = 'create'
            if (item.hasOwnProperty('id')) {
                dispatch = 'update'
            }
            let payload = {
                action_plan_objective_goal: [item]
            }
            this.$refs.objGoalCrud[dispatch](payload)
        },

        savedSearchCreate(payload) {
            payload.kpi_force = 1
            return this.$axios.post('savedSearch.php?action=create', {saved_searches: ([payload])})
        },

        handleCloseActionItem() {
            this.create.task = false
            this.loadData()
            this.$emit('close')
        },

        handleObjectiveOrder() {
            let rankUpdates = this.actionPlan
                .map((item, index) => {
                    let updateItem = null
                    if (item.id) {
                        updateItem = {
                            name: item.name,
                            kpi_dashboard_id: item.kpi_dashboard_id,
                            rank: index,
                            id: item.id
                        }
                    }
                    return updateItem
                })
            let transactions = []
            if (rankUpdates.length) {
                rankUpdates.forEach(update => {
                    if (update) transactions.push(this.$refs.objCrud['update']({action_plan_objective: [update]}))
                })
            }
            Promise.all(transactions).then(() => {
                this.loadData()
            })
        },

        handleStartOrder() {
            this.$store.commit('actionPlan/set', {
                key: 'objDragging',
                value: true
            })
        },

        handleEndOrder() {
            this.$store.commit('actionPlan/set', {
                key: 'objDragging',
                value: false
            })
        },

        handleGoalOrder(obj, v) {
            let rankUpdates = obj.goals
                .map((item, index) => {
                    let updateItem = {
                        action_plan_objective_id: v.moved.element.action_plan_objective_id,
                        goal_id: item.id,
                        rank: index,
                        id: item.action_plan_objective_link_id
                    }
                    return updateItem
                })
            let transactions = []
            if (rankUpdates.length) {
                rankUpdates.forEach(update => {
                    transactions.push(this.linkGoals(update))
                })
            }
            Promise.all(transactions).then(() => {
                this.loadData()
            })
        },

        handleCheckMove(v) {
            if (!v.draggedContext.element.id) return false
        }
    }
}
</script>

<style lang="scss" scoped>
.ap-draggable-ghost-class {
    opacity: .5;
    background: #C8EBFB;
}
</style>
