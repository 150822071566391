<template>
    <fe-left-menu-layout
        title="Sooper Dooper "
        :menuItems="menu"
        v-model="selected"
    >   
        <!-- <template v-slot:menu>
            Menu
        </template> -->

        <template v-slot:toolbar>
            
        </template>

        <div style="width: 100%; height: 100%; backgound-color: gray;">
            
        </div>
    </fe-left-menu-layout>
</template>

<script>
    export default {
        name: 'LeftMenuLayout',
        data() {
            return {
                selected: null,
                menu: [{
                    name: 'Option 1',
                    rec: { id: 1, name: 'Option 1'}
                }, {
                    name: 'Option 2',
                    rec: { id: 2, name: 'Option 2'} 
                }, {
                    name: 'Option 3',
                    rec: { id: 3, name: 'Option 3'} 
                }, {
                    name: 'Option 4',
                    rec: { id: 4, name: 'Option 4'} 
                }, {
                    name: 'Option 5',
                    rec: { id: 5, name: 'Option 5'} 
                }]
            }    
        },
        mounted() {
            this.selected = this.menu[0]
        }
    }
</script>

<style lang="scss" scoped>

</style>