export default {
    dataPointName: {
        defaults: {
            endpoint: 'dataPointName.php', // scope: data_point_type_id=9999
            rootProperty: 'names'
        },
        read: {
            params: {
                action: 'get'
            }
        },
        create: {
            params: { action: 'create' },
        },
        update: {
            params: { action: 'update' },
        },
        destroy: {
            params: { action: 'delete' },
        }
    }
}