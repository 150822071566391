<template>
    <div class="d-flex justify-start">
        <fe-filter-btn
            v-if="templates"
            title="Form Type"
            :items="templates"
            itemText="form_name"
            itemValue="form_id"
            v-model="localValue.form_types"
            multiple :closeOnSelect="false"
        />

        <fe-filter-btn
            :title="dateTitle"
            :menuOptions="{maxWidth:'500px'}"
        >
            <div>
                <fe-remote-combo
                    byId
                    v-model="dateType"
                    itemValue="value"
                    :items="[
                        {value:'my', name:'Modified in School Year'},
                        {value:'cy', name:'Created in School Year'},
                        {value:'mr', name:'Modified in Date Range'},
                        {value:'cr', name:'Created in Date Range'},
                    ]"
                    :clearable="false"
                />
            
                <fe-remote-combo
                    v-if="dateType[1]=='y'"
                    url="qry.php?property=school_years"
                    style="width:270px;"
                    rootProperty="years"
                    itemText="name"
                    itemValue="id"
                    placeholder="All Years"
                    byId
                    multiple moreText
                    v-model="localValue.school_year_id"
                    @input="$event && (localValue.start_date = localValue.end_date = undefined)"
                />

                <div v-if="dateType[1]=='r'" class="d-flex" style="width:400px;">
                    <fe-date-picker
                        v-model="localValue.start_date"
                        placeholder="Start Date"
                        emitWhenValid
                        class="mr-2"
                        :max="localValue.end_date"
                        @input="$event && (localValue.school_year_id = undefined)"
                    />

                    <fe-date-picker
                        v-model="localValue.end_date"
                        placeholder="End Date"
                        emitWhenValid
                        class="ml-2"
                        :min="localValue.start_date"
                        @input="$event && (localValue.school_year_id = undefined)"
                    />
                    </div>
            </div>
        </fe-filter-btn>

        
        <fe-filter-btn
            v-if="canViewAllForms"
            title="User"
            url="users.php?action=get"
            itemText="user_full_name"
            itemValue="id"
            v-model="localValue.user_id"
            multiple :closeOnSelect="false"
            buffered
        />

        <fe-filter-btn
            v-else
            disabled
            :title="sessionUser.user.user_full_name"
        />
        
        <!-- <fe-filter-btn
            title="Data Id"
            url="form.php?action=get&property=data_id"
            rootProperty="data_id"
            itemText="data_id"
            itemValue="data_id"
            v-model="localValue.data_id"
            multiple :closeOnSelect="false"
        /> -->

        <fe-btn v-if="searchButton" @click="$emit('input',{...localValue})">Search</fe-btn>
        <fe-btn usage="ghost" @click="clearParams">Clear</fe-btn>

    </div>
</template>

<script>
import RecordDialog from '@/components/common/form/RecordDialog'
import { mapState } from 'vuex'

export default {
    name: 'FormPicker',

    props: {
        value: {type: Object, required: true},
        templates: {type: Array, default: null},
        searchButton: {type: Boolean, default: false},
    },
    
    computed: {
        ...mapState('global', ['sessionUser']),
        canViewAllForms() {
            return this.sessionUser.security_codes.indexOf('VIEW_ALL_FORMS') !== -1
        },
        dateTitle() {
            if(!this.$_.isBlank(this.localValue.school_year_id)) {
                let ids = String(this.localValue.school_year_id).split(',')
                return ids.length>1
                    ? 'School Years - '+ids.length
                    : this.$store.state.global.shareableStores.school_years.find(x => x.id == ids[0]).name
            }
            if(this.localValue.start_date || this.localValue.end_date) return 'Date Range'
            return 'All Years'
        },
    },
    
    watch: {
        value: {
            deep: true,
            immediate: true,
            handler(v) {
                if(JSON.stringify(v) != JSON.stringify(this.localValue)) {
                    let code = v.modified ? 'm' : 'c'
                    this.dateType = v.start_date || v.end_date ? code+'r' : code+'y'
                    this.localValue = {...v}
                }
            }
        },
        localValue: {
            deep: true,
            handler(v) {
                if(!this.searchButton) this.$emit('input',{...v})
            }
        },
        dateType(v) {
            this.localValue.modified = v[0] == 'm' ? 1 : 0
        },
    },

    methods: {
        clearParams() {
            this.localValue = {}
        },
    },

    data() {
        return {
            localValue: {},
            dateType: 'my',
        }
    },

}
</script>

<style lang="scss" scoped>

</style>
