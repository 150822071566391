<template>
    <v-layout column class="">
        <div v-if="ready" class="headline mb-3 d-flex">
            Performance Band: {{targetSet.name}}
            <v-spacer></v-spacer>
            <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <fe-icon-btn v-on="on" useIcon="fas fa-ellipsis-v"/>
                </template>

                <v-list dense>
                    <v-list-item @click="beginEditSet">
                        <v-list-item-content>
                            Edit
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="deletingSet = true">
                        <v-list-item-content>
                            Delete
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <v-row v-if="Object.keys(prefs).length && descriptors.length" style="flex: unset;">
            <v-col v-if="prefs.ATTENDANCE_EQUIV_RATE">
                <div class="sub-heading d-flex">SIS Reported Rate Band Values</div>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">Range</th>
                            <th class="text-left" style="width: 150px;">Starting Value</th>
                            <th class="text-left" style="width: 150px">Ending Value</th>
                            <th style="width: 50px"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(item, i) in halfFullTargets"
                            :key="'half-full-rate-' + item.id"
                        >
                            <td>
                                <div>
                                    <v-select
                                        :items="availableHalfFullDescriptors"
                                        item-value="id"
                                        v-model="item.attendance_target_descriptor_id"
                                        hide-details
                                        flat solo dense
                                        @input="editBand(item,'halfFullTargets')"
                                    >
                                        <template v-slot:selection="data">
                                            <v-icon :color="data.item.color" class="mr-1" small>fas fa-square</v-icon>
                                            {{ data.item.name }}
                                        </template>
                                        <template v-slot:item="data">
                                            <v-icon :color="data.item.color" class="mr-1" small>fas fa-square</v-icon>
                                            {{ data.item.name }}
                                        </template>
                                    </v-select>
                                </div>
                            </td>
                            <td>
                                <div style="width: 80px;">
                                    <v-text-field
                                        type="number"
                                        class="number-input mr-2"
                                        v-model="item.start_value"
                                        flat solo dense
                                        hide-details
                                        :rules="maxRule"
                                        @change="editBand(item,'halfFullTargets')"
                                    />
                                </div>
                            </td>
                            <td>
                                <span v-if="i">to <b>{{item.end_value}}</b></span>
                                <span v-else>and above</span>
                            </td>
                            <td>
                                <fe-icon-btn
                                    useIcon="fa-trash"
                                    @click="deleteBand(item, 'halfFullTargets')"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <v-simple-table v-if="adding === 1">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th style="height: 0; border: none;"></th>
                            <th style="height: 0; border: none; width: 164px;"></th>
                            <th style="height: 0; border: none; width: 150px;"></th>
                            <th style="height: 0; border: none; width: 50px;"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <div>
                                    <v-select
                                        :items="availableHalfFullDescriptors"
                                        item-value="id"
                                        v-model="newItem.attendance_target_descriptor_id"
                                        hide-details
                                        flat solo dense
                                    >
                                        <template v-slot:selection="data">
                                            <v-icon :color="data.item.color" class="mr-1" small>fas fa-square</v-icon>
                                            {{ data.item.name }}
                                        </template>
                                        <template v-slot:item="data">
                                            <v-icon :color="data.item.color" class="mr-1" small>fas fa-square</v-icon>
                                            {{ data.item.name }}
                                        </template>
                                    </v-select>
                                </div>
                            </td>
                            <td>
                                <div style="width: 80px;">
                                    <v-text-field
                                        type="number"
                                        class="number-input mr-2"
                                        v-model="newItem.start_value"
                                        :rules="maxRule"
                                        flat solo dense
                                        hide-details
                                    />
                                </div>
                            </td>
                            <td colspan="2">
                                <div class="d-flex justify-end">
                                    <fe-btn usage="ghost" @click="beginAddBand()">Cancel</fe-btn>
                                    <fe-btn usage="primary" @click="addBand('halfFullTargets')">Submit</fe-btn>
                                </div>
                            </td>
                        </tr>

                        </tbody>
                    </template>
                </v-simple-table>

                <fe-btn
                    :disabled="halfFullDescriptors.length === descriptors.length || adding !== undefined"
                    usage="tertiary"
                    @click="beginAddBand(1)"
                >
                    Add Range
                </fe-btn>
            </v-col>

            <v-col v-if="prefs.ATTENDANCE_AFFECTED_RATE">
                <div class="sub-heading d-flex">Full Day Rate Band Values</div>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">Range</th>
                            <th class="text-left" style="width: 150px;">Starting Value</th>
                            <th class="text-left" style="width: 150px;">Ending Value</th>
                            <th style="width: 50px"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(item, i) in fullTargets"
                            :key="'full-rate-' + item.id"
                        >
                            <td>
                                <div>
                                    <v-select
                                        :items="availableFullDescriptors"
                                        item-value="id"
                                        v-model="item.attendance_target_descriptor_id"
                                        hide-details
                                        flat solo dense
                                        @input="editBand(item,'fullTargets')"
                                    >
                                        <template v-slot:selection="data">
                                            <v-icon :color="data.item.color" class="mr-1" small>fas fa-square</v-icon>
                                            {{ data.item.name }}
                                        </template>
                                        <template v-slot:item="data">
                                            <v-icon :color="data.item.color" class="mr-1" small>fas fa-square</v-icon>
                                            {{ data.item.name }}
                                        </template>
                                    </v-select>
                                </div>
                            </td>
                            <td>
                                <div style="width: 80px;">
                                    <v-text-field
                                        type="number"
                                        class="number-input mr-2"
                                        v-model="item.start_value"
                                        flat solo dense
                                        hide-details
                                        @change="editBand(item, 'fullTargets')"
                                    />
                                </div>
                            </td>
                            <td>
                                <span v-if="i">to <b>{{item.end_value}}</b></span>
                                <span v-else>and above</span>
                            </td>
                            <td>
                                <fe-icon-btn
                                    useIcon="fa-trash"
                                    @click="deleteBand(item, 'fullTargets')"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>


                <v-simple-table v-if="adding === 2">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th style="height: 0; border: none;"></th>
                            <th style="height: 0; border: none; width: 164px;"></th>
                            <th style="height: 0; border: none; width: 150px;"></th>
                            <th style="height: 0; border: none; width: 50px;"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <div>
                                    <v-select
                                        :items="availableFullDescriptors"
                                        item-value="id"
                                        v-model="newItem.attendance_target_descriptor_id"
                                        hide-details
                                        flat solo dense
                                    >
                                        <template v-slot:selection="data">
                                            <v-icon :color="data.item.color" class="mr-1" small>fas fa-square</v-icon>
                                            {{ data.item.name }}
                                        </template>
                                        <template v-slot:item="data">
                                            <v-icon :color="data.item.color" class="mr-1" small>fas fa-square</v-icon>
                                            {{ data.item.name }}
                                        </template>
                                    </v-select>
                                </div>
                            </td>
                            <td>
                                <div style="width: 80px;">
                                    <v-text-field
                                        type="number"
                                        class="number-input mr-2"
                                        v-model="newItem.start_value"
                                        flat solo dense
                                        hide-details
                                    />
                                </div>
                            </td>
                            <td colspan="2">
                                <div class="d-flex justify-end">
                                    <fe-btn usage="ghost" @click="beginAddBand()">Cancel</fe-btn>
                                    <fe-btn usage="primary" @click="addBand('fullTargets')">Submit</fe-btn>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <fe-btn
                    :disabled="fullDescriptors.length === descriptors.length || adding !== undefined"
                    usage="tertiary"
                    @click="beginAddBand(2)"
                >
                    Add Range
                </fe-btn>

            </v-col>

            <v-col v-if="!prefs.ATTENDANCE_EQUIV_RATE || !prefs.ATTENDANCE_AFFECTED_RATE"></v-col>
        </v-row>

        <v-row style="flex: unset;">
            <v-col>
                <div class="sub-heading d-flex mb-2">Applies to Schools</div>
                <v-autocomplete
                    ref="schoolSelector"
                    :items="schools"
                    v-model="selectedSchools"
                    placeholder="Search schools by name"
                    prepend-inner-icon="fa-search"
                    item-text="name"
                    item-value="id"
                    return-object
                    clearable
                    hide-details
                    hide-no-data
                    multiple
                    flat solo dense
                    @blur="addSchools"
                />
            </v-col>

            <v-col class="d-none d-lg-block"></v-col>
            <v-col class="d-none d-xl-block"></v-col>
            <v-col class="d-none d-xl-block"></v-col>
        </v-row>

        <v-row>
            <v-col class="py-0">
                <div class="schools-chip-wrapper mt-2">
                    <v-chip
                        v-for="(school, s) in appliesToSchools"
                        class="school-chip"
                        close
                        @click:close="removeSchool(school)"
                        :key="`applies-to-`+s"
                    >
                        {{school.school_name}}
                    </v-chip>
                </div>
            </v-col>
        </v-row>

        <fe-dialog
            v-if="editDialog.show"
            title="Edit Performance Band"
            :footer="false"
            persistent
            disableAutoclose
            @close="dismissEditDialog"
        >
            <model-form
                idProperty="id"
                :records="editDialog.records"
                :fieldConfig="fieldConfigs"
                model="attendanceTargetSets"
                @cancel="dismissEditDialog"
                @done="targetSetEdited"
            />
        </fe-dialog>

        <fe-dialog
            v-if="deletingSet"
            title="Confirm Delete"
            acceptButtonText="Delete"
            @accept="deleteTargetSet"
            @dismiss="deletingSet = false"
            body="Are you sure you want to delete this Performance Band?"
        />

        <fe-crud ref="descriptorCrud" :config="descriptorCrudConfig" @read="descriptors = $event" autoload/>
        <fe-crud ref="schoolsCrud" :config="schoolsCrudConfig" @read="schools = $event" autoload/>
        <fe-crud ref="targetCrud" :config="targetCrudConfig" @read="targetsFetched"/>
        <fe-crud ref="targetSetSchoolsCrud" :config="targetSetSchoolsCrudConfig" @read="appliesToSchools = $event"/>
        <fe-crud ref="targetSetCrud" :config="targetSetCrudConfig"/>
    </v-layout>
</template>

<script>
    import ModelForm from '@/components/common/form/ModelForm'

    export default {
        name: "AttendancePerformanceBandDetail",

        components: {ModelForm},

        props: {
            detail: {
                default: () => {
                }
            },
            prefs: {
                default: () => {
                }
            },
            updateDescriptors: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                ready: false,
                fullTargets: [],
                halfFullTargets: [],
                adding: undefined,
                descriptors: [],
                fullDescriptors: [],
                halfFullDescriptors: [],
                schools: [],
                appliesToSchools: [],
                selectedSchools: [],
                newItem: {
                    attendance_target_type_id: undefined,
                    attendance_target_descriptor_id: undefined,
                    start_value: undefined,
                    attendance_target_set_id: undefined
                },
                maxRule: [v => v <= 100 || "Max value 100"],
                targetSet: {},
                editDialog: {show: false, records: []},
                editingSet: {},
                deletingSet: false
            }
        },

        computed: {
            descriptorCrudConfig() {
                return this.$_.cloneDeep(this.$models.attendanceTargetDescriptor)
            },

            targetCrudConfig() {
                return this.$_.cloneDeep(this.$models.attendanceTargets)
            },

            schoolsCrudConfig() {
                return this.$_.cloneDeep(this.$models.attendanceTargetSetSchoolV)
            },

            targetSetSchoolsCrudConfig() {
                return this.$_.cloneDeep(this.$models.attendanceTargetSetSchool)
            },

            targetSetCrudConfig() {
                return this.$_.cloneDeep(this.$models.attendanceTargetSets)
            },

            availableFullDescriptors() {
                return this.descriptors.map(item => {
                    let data = this.$_.clone(item)
                    data.disabled = this.fullDescriptors.includes(data.id)
                    return data
                })
            },

            availableHalfFullDescriptors() {
                return this.descriptors.map(item => {
                    let data = this.$_.clone(item)
                    data.disabled = this.halfFullDescriptors.includes(data.id)
                    return data
                })
            },

            fieldConfigs() {
                let me = this
                return [
                    {
                        field: 'name',
                        label: 'Performance Band Name',
                        component: 'v-text-field',
                        validatorKey: 'text',
                        validatorConfig: {required: true, limit: 50},
                        width: '100%',
                        attrs: {
                            required: true,
                            validateOnBlur: true,
                            flat: true,
                            solo: true,
                            dense: true
                        },
                        events: {
                            blur(field, item) {
                                me.editingSet = me.$_.cloneDeep(item)
                            }
                        }
                    }
                ]
            }
        },

        watch: {
            detail(v) {
                if (v) {
                    this.readTargets()
                    this.readSchools()
                    this.newItem.attendance_target_set_id = v.id
                    this.targetSet = this.$_.clone(v)
                    this.ready = true
                }
            },

            updateDescriptors() {
                /** Not really a true Boolean. Just a trigger that the descriptors have changed. **/
                this.$refs.descriptorCrud.read()
            },
            schools: {
                deep: true,
                handler(v) {
                    if(v.length) return v.filter(s => { return s.attendance_target_set_id === 0})
                }
            }
        },

        methods: {
            readTargets() {
                this.$refs.targetCrud.read({'attendance_target_set_id': this.detail.id})
            },

            readSchools() {
                this.$refs.targetSetSchoolsCrud.read({'attendance_target_set_id': this.detail.id})
            },

            beginEditSet() {
                this.editingSet = this.$_.cloneDeep(this.targetSet)
                this.editDialog.records = [this.targetSet]
                this.editDialog.show = true
            },

            targetSetEdited() {
                this.targetSet = this.$_.cloneDeep(this.editingSet)
                this.dismissEditDialog()
            },

            dismissEditDialog() {
                this.editDialog.show = false
            },

            deleteTargetSet() {
                this.$refs.targetSetCrud.destroy(this.targetSet)
                    .then(() => {
                        this.deletingSet = false
                        this.$store.commit('manage/removeCrumb', {
                            key: "Attendance",
                            count: 1
                        })
                        this.$emit('count-update')
                    })
            },

            targetsFetched(v) {
                let full = []
                let halfFull = []
                let fullDescriptors = []
                let halfFullDescriptors = []

                v.forEach(item => {
                    item.start_value = parseFloat(item.start_value)
                    item.end_value = parseFloat(item.end_value)
                    if (item.attendance_target_type_id === 2) {
                        full.push(item)
                        fullDescriptors.push(item.attendance_target_descriptor_id)
                    } else {
                        halfFull.push(item)
                        halfFullDescriptors.push(item.attendance_target_descriptor_id)
                    }
                })

                this.fullTargets = this.processBands(full)
                this.halfFullTargets = this.processBands(halfFull)
                this.fullDescriptors = fullDescriptors
                this.halfFullDescriptors = halfFullDescriptors
            },

            addSchools() {
                if (this.selectedSchools.length) {
                    let data = this.selectedSchools.map(item => {
                        return {
                            attendance_target_set_id: this.detail.id,
                            school_id: item.id
                        }
                    })

                    this.$refs.targetSetSchoolsCrud.create(data)
                        .then(() => {
                            this.$nextTick(() => {
                                this.$refs.schoolSelector.$refs.input.value = ''
                                this.selectedSchools = []
                            })
                            this.$refs.schoolsCrud.read()
                            this.readSchools()
                        })
                }
            },

            removeSchool(v) {
                this.$refs.targetSetSchoolsCrud.destroy(v)
                    .then(() => {
                        this.$refs.schoolsCrud.read()
                        this.readSchools()
                    })
            },

            beginAddBand(type) {
                this.newItem.attendance_target_type_id = type
                this.newItem.attendance_target_descriptor_id = undefined
                this.newItem.start_value = undefined
                this.adding = type
            },

            addBand(bandType) {
                if (undefined !== this.newItem.start_value && undefined !== this.newItem.attendance_target_descriptor_id) {
                    let bands = this.$_.clone(this[bandType])
                    this.newItem.start_value = parseFloat(this.newItem.start_value)
                    bands.push(this.newItem)
                    let processedBands = this.processBands(bands)

                    let create = []
                    let modified = []

                    processedBands.forEach(item => {
                        if (!item.hasOwnProperty('id')) {
                            create.push(item)
                        } else if (item.modified) {
                            modified.push(item)
                        }
                    })

                    let crud = this.$refs.targetCrud
                    if (create.length) {
                        crud.create(create)
                            .then(() => {
                                if (modified.length) {
                                    crud.update(modified)
                                        .then(() => {
                                            this.readTargets()
                                            this.beginAddBand()
                                        })
                                } else {
                                    this.readTargets()
                                    this.beginAddBand()
                                }
                            })
                    }
                }
            },

            editBand(v, bandType) {
                v.modified = true
                let bands = this.processBands(this[bandType])
                let modified = bands.filter(x => x.modified)

                this.$refs.targetCrud.update(modified)
                    .then(() => {
                        this.readTargets()
                    })
            },

            deleteBand(v, bandType) {
                this.$confirmDelete(v, () => {
                    let bands = this.$_.clone(this[bandType])
                    bands = bands.filter(item => item.id !== v.id)
                    let processedBands = this.processBands(bands)
                    let modified = processedBands.filter(x => x.modified)

                    let crud = this.$refs.targetCrud
                    crud.destroy(v)
                        .then(() => {
                            if (modified.length) {
                                crud.update(modified)
                                    .then(() => {
                                        this.readTargets()
                                    })
                            } else {
                                this.readTargets()
                            }
                        })
                })
            },

            processBands(data) {
                let ordered = this.$_.orderBy(data, ['start_value'], ['desc'])

                let max

                return ordered.map(item => {
                    let clone = this.$_.clone(item)
                    if (max) {
                        clone.end_value = max - .01
                    } else {
                        clone.end_value = 101
                    }
                    clone.modified = clone.modified || clone.end_value !== item.end_value
                    max = clone.start_value

                    return clone
                })
            }
        }
    }
</script>


<style lang="scss" scoped>
    .sub-heading {
        font-size: 18px;
        font-weight: bold;
    }

    .theme--light.v-data-table tbody tr td {
        border-bottom: none !important;
    }

    .disabled {
        color: #9a9a9a;
    }

    .v-data-table table {
        overflow: hidden;
    }

    .school-chip::v-deep {
        .v-chip__content {
            max-width: unset;
        }
    }

    .number-input::v-deep input {
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }
    }
</style>
