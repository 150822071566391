<template>
    <div class="slo-completion-status-toggle-button" @click="trigger" :class="classes">{{ text }}</div>
</template>

<script>
    export default {
        name: 'SloPanelStrategiesStatusButton',
        props: {
            item: { type: Object, required: true },
            allowCompletionReverse: { type: Boolean, default: false }
        },
        components: { },
        data() {
            return { }
        },
        computed: {
            complete () { return this.item.complete },
            overdue () { return !this.complete && this.$dayjs(`${this.item.schedule_date_occurrence} ${this.item.schedule_date_occurrence_end_time}`) < this.$dayjs() },
            incomplete () { return !this.complete && !this.overdue },
            classes () {
                return {
                    'is-complete': this.complete,
                    'is-overdue': this.overdue,
                    'is-incomplete': this.incomplete,
                    'allow-completion-reverse': this.allowCompletionReverse
                }
            },
            text () {
                if (this.complete) return "Done"
                else if (this.overdue) return "Overdue"
                else return "To Do"
            }
        },
        methods: {
            trigger () {
                if (!this.item.complete || this.allowCompletionReverse) {
                    this.$emit('setStatus', !this.item.complete)
                }
            }
        },
        watch: { }
    }
</script>

<style lang="scss">
    .slo-completion-status-toggle-button {
        $height: 22px;
        display: inline-block;
        height: $height;
        line-height: $height;
        font-size: $height / 2;
        border-radius: $height / 2;
        color: #eee;
        padding: 0 10px;
        &:hover { color: #fff; }
        &.is-complete {
            background-color: #746DE0;
            color: #fff;
            &.allow-completion-reverse {
                color: #eee;
                cursor: pointer;
                &:hover { background-color: darken(#746DE0, 10%); }
            }
        }
        &.is-overdue {
            cursor: pointer;
            background-color: #BF0D00;
            &:hover { background-color: darken(#BF0D00, 10%); }
        }
        &.is-incomplete {
            cursor: pointer;
            background-color: #2B87FF;
            &:hover { background-color: darken(#2B87FF, 10%); }
        }
    }
</style>
