<template>
    <v-layout column>
        <div class="headline mb-3 d-flex">
            Attendance Performance Bands
            <fe-info-tip
                class="ml-2"
                tooltip="Performance Bands"
            />
        </div>

        <div class="d-flex" style="width: 100%">
            <div style="width: 550px">
                <div class="sub-heading d-flex">
                    Range Descriptors
                    <fe-info-tip class="ml-2" tooltip="Range Descriptors"/>
                </div>

                <div class="table">
                    <div class="d-flex table-header">
                        <div class="table-column-1">Color</div>
                        <div class="table-column-2">Name</div>
                        <div class="table-column-3"></div>
                    </div>

                    <div class="table-body">
                        <draggable
                            v-model="descriptors"
                            handle=".drag-handle"
                            ghost-class="ghost-class"
                            @change="handleDragChange"
                        >
                            <div
                                v-for="(item, i) in descriptors"
                                :key="'descriptor' + i"
                                class="table-row d-flex"
                            >
                                <div class="table-column-1">
                                    <fe-color-selector
                                        v-model="item.color"
                                        @change="updateDescriptorColor($event, item)"
                                    />
                                    <div :class="addingDescriptor ? 'mask' : ''"></div>
                                </div>

                                <div class="table-column-2">
                                    <v-text-field
                                        v-model="item.name"
                                        hide-details
                                        flat solo dense
                                        :disabled="addingDescriptor"
                                        @change="updateDescriptor(item)"
                                    />
                                </div>

                                <div class="table-column-3 d-flex">
                                    <fe-icon-btn
                                        useIcon="fa-trash"
                                        @click="deleteDescriptor(item)"
                                        :disabled="addingDescriptor"
                                    />

                                    <div class="drag-handle" :class="addingDescriptor ? '' : 'active'">
                                        <fe-tooltip tooltip="Drag and Drop to reorder">
                                        <span class="fa-stack">
                                            <i class="far fa-ellipsis-v"/>
                                            <i class="far fa-ellipsis-v"/>
                                        </span>
                                        </fe-tooltip>
                                    </div>
                                </div>
                            </div>
                        </draggable>

                        <div v-if="addingDescriptor" class="table-row d-flex">
                            <div class="table-column-1">
                                <fe-color-selector
                                    v-model="newDescriptor.color"
                                    @change="newDescriptor.color = $event"
                                />
                            </div>

                            <div class="table-column-2">
                                <v-text-field
                                    v-model="newDescriptor.name"
                                    hide-details
                                    flat solo dense
                                />
                            </div>

                            <div class="table-column-4">
                                <fe-btn usage="ghost" @click="resetNewDescriptor">Cancel</fe-btn>
                                <fe-btn usage="primary" @click="addDescriptor()">Submit</fe-btn>
                            </div>
                        </div>
                    </div>
                </div>

                <fe-btn
                    :disabled="addingDescriptor"
                    usage="tertiary"
                    @click="addingDescriptor = true"
                >
                    Add Range Descriptor
                </fe-btn>
            </div>
        </div>

        <fe-action-list
            title="Performance Bands"
            :items="targetSetsWithCounts"
            addButtonText="Add Performance Band"
            :showSearch="false"
            :editButton="false"
            :showFiltering="false"
            @rowClick="detailView"
            @addButtonClick="beginEntry"
        />

        <fe-dialog
            v-if="createDialog.show"
            title="Create Performance Band"
            :footer="false"
            persistent
            disableAutoclose
            @close="dismissDialog"
        >
            <model-form
                idProperty="id"
                :records="createDialog.records"
                :fieldConfig="fieldConfigs"
                model="attendanceTargetSets"
                @cancel="dismissDialog"
                @done="created"
            />
        </fe-dialog>

        <fe-crud ref="targetSetCrud" :config="targetSetCrudConfig" @read="targetSets = $event" autoload/>
        <fe-crud ref="targetCrud" :config="targetCrudConfig" @read="targets = $event"/>
        <fe-crud ref="descriptorCrud" :config="descriptorCrudConfig" @read="descriptors = $event" autoload/>
    </v-layout>
</template>

<script>
    import ModelForm from '@/components/common/form/ModelForm'
    import draggable from 'vuedraggable'

    export default {
        name: "AttendancePerformanceBands",

        components: {ModelForm, draggable},

        props: {
            prefs: {
                default: () => {
                }
            }
        },

        data() {
            return {
                firstTime: true,
                targetSets: [],
                targets: [],
                descriptors: [],
                editingRanges: false,
                addingDescriptor: false,
                newDescriptor: {
                    color: '#0049FF',
                    name: undefined
                },
                createDialog: {show: false, records: []},
                fieldConfigs: [
                    {
                        field: 'name',
                        label: 'Performance Band Name',
                        component: 'v-text-field',
                        validatorKey: 'text',
                        validatorConfig: {required: true, limit: 50},
                        width: '100%',
                        attrs: {
                            required: true,
                            validateOnBlur: true,
                            flat: true,
                            solo: true,
                            dense: true
                        }
                    }
                ]
            }
        },

        computed: {
            targetSetCrudConfig() {
                return this.$_.cloneDeep(this.$models.attendanceTargetSets)
            },

            targetCrudConfig() {
                return this.$_.cloneDeep(this.$models.attendanceTargets)
            },

            descriptorCrudConfig() {
                return this.$_.cloneDeep(this.$models.attendanceTargetDescriptor)
            },

            targetSetsWithCounts() {
                let counts = {}
                let include = {
                    1: this.prefs.ATTENDANCE_EQUIV_RATE,
                    2: this.prefs.ATTENDANCE_AFFECTED_RATE
                }
                this.targets.forEach(item => {
                    if (!counts.hasOwnProperty(item.attendance_target_set_id)) {
                        counts[item.attendance_target_set_id] = 0
                    }
                    if (include[item.attendance_target_type_id]) {
                        counts[item.attendance_target_set_id]++
                    }
                })

                return this.targetSets.map(item => {
                    let count = counts.hasOwnProperty(item.id) ? counts[item.id] : 0
                    item.chips = [{
                        key: item.id,
                        icon: count ? null : 'fa-info-circle',
                        name: count + ' Band' + (count !== 1 ? 's' : '')
                    }]
                    return item
                })
            }
        },

        created() {
            this.$parent.$on('show performanceBands', () => {
                if (this.firstTime) {
                    this.firstTime = false
                } else {
                    this.$refs.targetSetCrud.read()
                }
                this.$refs.targetCrud.read()
            })
        },

        methods: {
            detailView(v) {
                this.$emit('detail-view', v)
            },

            resetNewDescriptor() {
                this.addingDescriptor = false
                this.newDescriptor.color = '#0049FF'
                this.newDescriptor.name = undefined
            },

            handleDragChange() {
                let dirty = []

                this.descriptors.forEach((item, i) => {
                    let newRank = i + 1
                    if (item.rank !== newRank) {
                        let clone = this.$_.cloneDeep(item)
                        clone.rank = newRank
                        dirty.push(clone)
                    }
                })

                if (dirty.length) {
                    this.updateDescriptor(dirty)
                }
            },

            addDescriptor() {
                let data = this.newDescriptor
                if (undefined !== data.color && undefined !== data.name) {
                    let crud = this.$refs.descriptorCrud
                    crud.create(data)
                        .then(() => {
                            crud.read()
                            this.$emit('descriptor-update')
                            this.resetNewDescriptor()
                        })
                }
            },

            updateDescriptorColor(color, data) {
                data.color = color
                this.updateDescriptor(data)
            },

            updateDescriptor(data) {
                let crud = this.$refs.descriptorCrud
                crud.update(data)
                    .then(() => {
                        crud.read()
                        this.$emit('descriptor-update')
                    })
            },

            deleteDescriptor(data) {
                this.$confirmDelete(data, () => {
                    let crud = this.$refs.descriptorCrud
                    crud.destroy(data)
                        .then(() => {
                            crud.read()
                            this.$emit('descriptor-update')
                        })
                })
            },

            beginEntry() {
                this.resetEntry()
                this.createDialog.show = true
            },

            resetEntry() {
                this.createDialog.records = [{
                    name: ''
                }]
            },

            dismissDialog() {
                this.$refs.targetSetCrud.read()
                this.createDialog.show = false
            },

            created() {
                this.dismissDialog()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .sub-heading {
        font-size: 18px;
        font-weight: bold;
    }

    .theme--light.v-data-table tbody tr td {
        border-bottom: none !important;
    }

    .table {
        width: 100%;
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        font-weight: 700;

        .table-header {
            width: 100%;
            height: 48px;
            border-bottom: thin solid rgba(0, 0, 0, 0.12);

            .table-column {
                padding: 16px;

                &-1 {
                    @extend .table-column;
                    width: 100px;
                }

                &-2 {
                    @extend .table-column;
                    flex-grow: 1;
                }

                &-3 {
                    @extend .table-column;
                    width: 67px;
                }
            }
        }

        .table-body {
            width: 100%;

            .ghost-class {
                opacity: .5;
                background: #C8EBFB;
            }

            .table-row {
                @extend .table-body;
                position: relative;
                height: 48px;

                .drag-handle {
                    width: 15px;
                    height: 48px;
                    padding: 12px 5px;
                    background: #ecedf1;
                    border-radius: 0 4px 4px 0;
                    display: none;
                    cursor: grab;
                }

                &:hover {
                    background: #eee;

                    .drag-handle.active{
                        display: block;

                        &:active {
                            cursor: grabbing;
                        }
                    }
                }

                .table-column {
                    &-1 {
                        width: 100px;
                        padding: 5px 16px;
                        position: relative;

                        .mask {
                            position: absolute;
                            top: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    &-2 {
                        flex-grow: 1;
                        padding: 5px 16px;
                    }

                    &-3 {
                        width: 67px;
                    }

                    &-4 {
                        flex-grow: 1;
                    }
                }
            }
        }
    }
</style>
