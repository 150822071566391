<template>
    <div>
        <v-layout row justify-end>
            <fe-btn @click="$emit('saveClick')" usage="secondary" :hidden="saveHidden" :disabled="saveDisabled">Save</fe-btn>
            <fe-btn @click="$emit('addClick')" usage="primary" :disabled="addDisabled">Add</fe-btn>
            <fe-btn @click="$emit('deleteClick')" usage="warning" :disabled="deleteDisabled">Delete</fe-btn>
            <fe-btn @click="$emit('refreshClick')" usage="ghost"  :disabled="refreshDisabled" fab useIcon="refresh"></fe-btn>
        </v-layout>
    </div>
</template>

<script>
    export default {
        name: 'CrudTools',
        props: {
            deleteDisabled: {
                type: Boolean,
                default: false
            },
            saveDisabled: {
                type: Boolean,
                default: false
            },
            saveHidden: {
                type: Boolean,
                default: false
            },
            addDisabled: {
                type: Boolean,
                default: false
            },
            refreshDisabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss" scoped>

</style>