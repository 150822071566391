<template>
    <v-layout v-if="isMounted" row fill-height style="height: 100%">
        <fe-stepper-select-panel
            v-model="params.cohort_school_year_id"
            class="stepper"
            :items="schoolYears"
            :surfaceFirstSelection="surfaceFirstSelections"
            byId
            searchPlaceholder="School Year"
            @input="$emit('selected', true)"
        />
        <v-divider vertical style="background-color:#E0E1E6"/>

        <template v-if="hasYear">
            <fe-stepper-select-panel
                v-model="params.user_id"
                class="stepper"
                :items="users"
                :surfaceFirstSelection="surfaceFirstSelections"
                itemText="user_full_name"
                itemValue="user_id"
                :byId=byId
                allOption
                allOptionText="All Users"
                search
                searchPlaceholder="User"
                @input="$emit('selected', true)"
            />
            <v-divider vertical style="background-color:#E0E1E6"/>
        </template>

        <template v-if="hasYear && hasUser && !includeFilters">
            <v-form>
                <fe-remote-combo
                    v-model="params.course_id"
                    label="Courses"
                    :byId=byId
                    :items="courses"
                    itemText="course_name"
                    itemValue="course_id"
                    style="padding:10px; width: 350px;"
                    :selectAllOption="false"
                    multiple
                    dense
                    lazyLoad
                    hide-details
                />
                <fe-remote-combo
                    v-model="params.period"
                    label="Periods"
                    :byId=byId
                    :items="periods"
                    :disabled="!hasCourse"
                    itemText="period"
                    itemValue="period"
                    style="padding:10px; width: 350px;"
                    :selectAllOption="periods.length > 0"
                    multiple
                    dense
                    lazyLoad
                    hide-details
                />
            </v-form>
        </template>

        <template v-if="hasYear && hasUser && includeFilters">
            <div v-if="isLoading" style="width: 20%">
                <fe-spinner
                    class="spinner"
                    text="Loading courses..."
                />
            </div>
            <fe-stepper-checkbox-panel
                v-else
                v-model="params.course_id"
                class="stepper"
                :items="courses"
                :surfaceFirstSelections="surfaceFirstSelections"
                :byId=byId
                itemText="course_name"
                itemValue="course_id"
                searchPlaceholder="Courses"
                search
                @input="$emit('selected', true);"
            />
            <v-divider vertical style="background-color:#E0E1E6"/>
        </template>

        <template v-if="hasYear && hasUser && hasCourse && includeFilters">
            <fe-stepper-checkbox-panel
                v-model="params.period"
                class="stepper"
                :items="periods"
                :surfaceFirstSelections="surfaceFirstSelections"
                :byId=byId
                itemText="period"
                itemValue="period"
                searchPlaceholder="Periods"
                allOptionText="All Periods"
                search
                allOption
                @input="$emit('selected', true)"
            />
            <v-divider vertical style="background-color:#E0E1E6"/>
        </template>

        <template v-if="includeFilters && hasYear && hasUser && hasCourse && hasPeriod">
            <fe-stepper-panel style="width: 20%; margin-bottom:-28px;">
                <div class="fs14 pa-2">Filters</div>
                <v-divider style="background-color:#E0E1E6"/>
                <v-form lazy-validation v-if="params.cohort_school_year_id" style="overflow-x: hidden;">
                    <advanced-filters
                        ref="advancedFilters"
                        byId
                        :showGroupBy="false"
                        :resetEntry="false"
                        v-model="params.advancedFilters"
                        listView
                    />
                </v-form>
            </fe-stepper-panel>
        </template>
    </v-layout>
</template>

<script>
import AdvancedFilters from '@/components/common/AdvancedFilters'
import advancedFiltersMixin from '@/mixins/advancedFilters'
import { mapState } from "vuex"

export default {
    name: 'CourseStepperPanel',

    components: {
        AdvancedFilters
    },

    mixins: [advancedFiltersMixin],

    props: {
        value: {},
        surfaceFirstSelections: {
            type: Boolean,
            default: false
        },
        includeFilters: {
            type: Boolean,
            default: true
        },
        byId: {
            type: Boolean,
            default: true
        },
        editing: {
            type: Boolean
        }
    },

    data() {
        return {
            isMounted: false,
            schoolYears: [],
            users: [],
            courses: [],
            periods: [],
            params: {},
            localValue: {},
            isLoading: false,
            allDisabilityIds: null,
            allLevelIds: null
        }
    },

    computed: {
        ...mapState('global', ['sessionUser']),

        hasYear() {
            return !!this.params?.cohort_school_year_id
        },

        hasUser() {
            return !!this.params?.user_id
        },

        hasCourse() {
            return !!this.params?.course_id?.length
        },

        hasPeriod() {
            return !!this.params?.period?.length
        },

        ellLevelEnabled() {
            return this.$store.state.global.shareableStores.ell_level_enabled
        },

        searchDisplay() {
            let pieces = []
            let itm

            let schoolYearId = this.params.school_year_id || this.params.cohort_school_year_id
            if (schoolYearId) {
                itm = this.schoolYears.find(itm => itm.id === schoolYearId)
                if (itm) {
                    pieces.push(itm?.name || '')
                } else {
                    return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                }
            } else {
                pieces.push('<b>Select School Year</b>')
                return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
            }

            let userId = this.params.user_id
            if (userId) {
                itm = this.users.find(itm => itm.user_id === userId)
                if (itm) {
                    pieces.push(itm?.user_full_name || '')
                } else {
                    return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                }
            } else {
                pieces.push('<b>Select User</b>')
                return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
            }

            let courseId = this.params.course_id
            if (courseId && courseId.length) {
                itm = this.courses.find(itm => itm.course_id === courseId[0])
                if (itm) {
                    pieces.push(itm.course_name + ' ' + (courseId.length > 1 ? '...+' + (courseId.length - 1) + '' : ''))
                } else {
                    return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                }
            } else {
                pieces.push('<b>Select Courses</b>')
                return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
            }

            let periodId = this.params.period
            if (periodId && periodId.length) {
                itm = this.periods.find(itm => itm.period === periodId[0])
                if (itm) {
                    pieces.push(itm.period + ' ' + (periodId.length > 1 ? '...+' + (periodId.length - 1) + '' : ''))
                } else {
                    pieces.push('All Periods')
                }
            } else {
                pieces.push('<b>Select Periods</b>')
                return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
            }

            pieces.push('Select Filters (Optional)')
            return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
        },
    },

    watch: {
        params: {
            handler(v) {
                // handle All Disabilities
                if (v.advancedFilters?.disability_type_id && v.advancedFilters?.disability_type_id[0].code === 'ALLDIS') {
                    v.advancedFilters.disability_type_id[0].allIds = this.allDisabilityIds // include all ids in the object
                } else if (v.advancedFilters?.disability_code && v.advancedFilters?.disability_code === 'ALLDIS') {
                    // If "All Disabilities" was previously selected and the user is editing the search,
                    // make sure user sees "All Disabilities" chip in the filters instead of individual disability chips
                    v.advancedFilters.disability_type_id = []
                    v.advancedFilters.disability_type_id.push(
                        {
                            description: 'All Disabilities',
                            code: 'ALLDIS',
                            id: -1,
                            allIds: this.allDisabilityIds
                        }
                    )
                    delete v.advancedFilters.disability_code
                }

                // handle Any Meal Status
                if (v.advancedFilters?.meal_status_id && v.advancedFilters?.meal_status_id[0].code === 'ALLSTATUS') {
                    v.advancedFilters.meal_status_id[0].allIds = this.mealStatusItems // include all ids in the object
                } else if (v.advancedFilters?.status_code && v.advancedFilters?.status_code === 'ALLSTATUS') {
                    // If "Any Status" was previously selected and the user is editing the search,
                    // make sure user sees "Any Status" chip in the filters instead of individual status chips
                    v.advancedFilters.meal_status_id = []
                    v.advancedFilters.meal_status_id.push(
                        {
                            name: 'Any Status',
                            display_name: 'All Status',
                            code: 'ALLSTATUS',
                            id: -1,
                            allIds: this.mealStatusItems
                        }
                    )
                    delete v.advancedFilters.status_code
                }

                // handle All Interventions
                if (v.advancedFilters?.intervention_level && v.advancedFilters?.intervention_level[0].code === 'ALLINT') {
                    v.advancedFilters.intervention_level[0].allIds = this.allLevelIds
                } else if (v.advancedFilters?.intervention_code && v.advancedFilters?.intervention_code === 'ALLINT') {
                    // If "All Interventions" was previously selected and the user is editing the search,
                    // make sure user sees "All Interventions" chip in the filters instead of individual disability chips
                    v.advancedFilters.intervention_level = []
                    v.advancedFilters.intervention_level.push(
                        {
                            description: 'All Interventions',
                            code: 'ALLINT',
                            id: -1,
                            allIds: this.allLevelIds,
                            rank: 0,
                            icon: null,
                        }
                    )
                    delete v.advancedFilters.intervention_code
                }

                this.$emit('input', this.$_.cloneDeep(v))//{...v, ...this.params.advancedFilters})
                this.$emit('selected', true)
            },
            deep: true
        },
        'params.cohort_school_year_id'(v) {
            if (v) {
                this.$axios.get('courseHistory.php?action=get&property=user_id&' + this.$objectToParams({
                    school_year_id: v,
                    active_only: 1
                }))
                    .then(r => {
                        this.users = r.data.courses
                        if (!this.includeFilters) {
                            this.users.unshift({
                                user_id: -1,
                                user_full_name: 'All Users'
                            })
                            if (this.params.user_id === undefined) {
                                this.params = {
                                    ...this.params,
                                    user_id: {user_id: -1, user_full_name: 'All Users'},
                                    course_id: [],
                                    period: []
                                }
                            }
                        }
                    })
            } else {
                this.users = []
            }
        },
        'params.user_id'(v, prevVal) {
            this.courses = []
            this.periods = []

            // Don't wipe out course id, period selections on page load
            // Only do so if there was a previous selection
            if (prevVal) {
                this.params.course_id = []
                this.params.period = []
            }

            if (!v) return

            this.isLoading = true
            const userId = this.byId ? v : v.user_id
            this.$axios.get('courseHistory.php?action=get&property=course_id&school_year_id=' + this.params.cohort_school_year_id + '&user_id=' + userId)
                .then(r => {
                    this.courses = r.data.courses
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        'params.course_id'(v) {
            this.loadPeriod()
        },
        searchDisplay(v) {
            this.$emit('searchDisplayChange', v)
        },
    },

    mounted() {
        this.params = {...this.value}
        this.loadBase()
        this.isMounted = true
    },

    methods: {
        getSubmitParams(advancedFiltersOnly = false) {
            let opts = this.getSearchObject()
            let p = {}
            let keys = {
                course_id: 'course_id',
                period: 'period',
                user_id: 'user_id',
                cohort_school_year_id: 'id'
            }

            if (!advancedFiltersOnly) {
                Object.keys(this.params).forEach(key => {
                    if (Array.isArray(this.params[key])) {
                        if (key === 'period') {
                            p[key] = this.params[key]?.map(r => r.period).join('+')
                        } else {
                            p[key] = this.params[key].map(r => r[keys[key]]).filter(r => r != -1)
                        }
                    } else if (this.params[key]) {
                        p[key] = this.params[key][keys[key]]
                    }
                })
            }

            Object.assign(p, opts)
            return p
        },

        loadBase() {
            this.$modelGet('schoolYear').then(r => {
                this.schoolYears = r
                if (this.params.cohort_school_year_id === undefined) {
                    this.params = {
                        ...this.params,
                        cohort_school_year_id: this.schoolYears[0].id
                    }
                }
            })
            this.$modelGet('interventionLevel')
                .then(levels => {
                    this.allLevelIds = levels.map(level => `${level.id}`).join(',')
                })
            this.$modelGet('studentDisabilityType')
                .then(disabilities => {
                    this.allDisabilityIds = disabilities.map(disability => `${disability.id}`).join(',')
                })

            if (this.sessionUser.security_codes.includes('SEARCH_MEAL_STATUS') || this.sessionUser.security_codes.includes('DRILLDOWN_MEAL_STATUS')) {
                this.$modelGet('mealStatusType')
                    .then(status => {
                        this.mealStatusItems = status.map(s => `${s.id}`).join(',')
                    })
            }
        },

        loadPeriod() {
            const courseId = this.byId ? this.params.course_id : this.params.course_id.map(x => x.course_id)
            const userId = this.byId ? this.params.user_id : this.params.user_id.user_id
            this.$axios.get('courseHistory.php?action=get&property=period&school_year_id=' + this.params.cohort_school_year_id + '&user_id=' + userId + '&course_id=' + courseId)
                .then(r => {
                    this.periods = r.data.courses
                })
                .finally(() => {
                    if (!this.editing && this.params.course_id?.length > 0) {
                        this.params.period = this.periods
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .filter-component {
    margin-left: auto;
    max-width: 100%;
}
.stepper {
    width: 30% !important;
    margin-bottom:-28px;
}
.spinner {
    position: relative;
    top: 80px;
}
</style>
