<template>
    <fe-dialog
        v-if="active"
        width="450"
        :title="title"
        :acceptButtonText="creating ? 'Create' : 'Update'"
        :acceptButtonDisabled="!valid || fetching"
        @dismiss="dismiss"
        @accept="apply"
    >
        <div v-if="fetching" class='text-smaller text-muted ma-3 pb-3'>
            <v-progress-circular indeterminate color="#999" size="14"/>
            Loading...
        </div>

        <v-form
            v-else
            v-model="valid"
            ref="form"
        >
            <v-layout row wrap>
                <v-flex xs12 px-1>
                    <fe-label content="Threshold Grouping Name"/>
                    <v-text-field
                        v-model="obj.name"
                        :rules="nameRules"
                        flat solo dense
                        required
                    />
                </v-flex>

                <v-flex xs12>
                    <v-layout row>
                        <v-flex xs6 px-1 align-center>
                            <fe-label class="mt-0">Start Date
                                <fe-info-tip
                                    class="mx-1" style="display:inline-block;"
                                    tooltip="Past dates can be used"
                            />
                            </fe-label>
                            <fe-date-picker
                                :value="obj.start_date"
                                :emitWhenValid="true"
                                :rules="startDateRules"
                                flat solo dense
                                @input="v => obj.start_date = v ? v : null"
                            />
                        </v-flex>

                        <v-flex xs6 px-1>
                            <fe-label content="End Date (optional)"/>
                            <fe-date-picker
                                :value="obj.end_date"
                                :emitWhenValid="true"
                                :rules="endDateRules"
                                flat solo dense
                                @input="v => obj.end_date = v ? v : null"
                            />
                        </v-flex>
                    </v-layout>
                </v-flex>

                <v-flex xs6 px-1>
                    <fe-label content="Include Weighting?"/>
                    <v-switch
                        v-model="weighted"
                        :label="weighted ? 'Yes' : 'No'"
                    />
                </v-flex>

                <v-flex xs6 px-1>
                    <fe-label content="Display on Student Page?"/>
                    <v-switch
                        v-model="obj.is_visible_student_page"
                        :label="obj.is_visible_student_page ? 'Yes' : 'No'"
                    />
                </v-flex>

                <v-flex xs12 px-1>
                    <fe-label content="Clear Previous Result on Each Scheduled Run?"/>
                    <v-switch
                        v-model="obj.clear_previous_results"
                        :label="obj.clear_previous_results ? 'Yes' : 'No'"
                    />
                </v-flex>

                <v-flex xs6 px-1>
                    <fe-label content="Scheduled Run Time"/>
                    <fe-chip-select
                        v-model="obj.scheduled_run_time"
                        :items="runTimes"
                        :multiple="false"
                        :clearable="false"
                    />
                </v-flex>

                <v-flex xs6 px-1>
                    <fe-label content="Scheduled Run Days"/>
                    <fe-chip-select
                        v-model="obj.scheduled_days"
                        :items="runDays"
                        :rules="scheduledDaysRules"
                        multiple
                    />
                </v-flex>
            </v-layout>
        </v-form>
    </fe-dialog>
</template>

<script>
import Map from '../services/Map'
import Api from '../services/Api'
import {mapGetters, mapActions} from 'vuex'

export default {
    name: 'GroupSettings',

    props: {
        active: Boolean,
        mode: String
    },

    data() {
        return {
            fetching: false,
            fetched: {},
            valid: false,
            startDatePickerMenu: false,
            endDatePickerMenu: false,
            nameRules: [
                v => !!v || 'Name is required',
                v => !!v && this.nameIsUnique(v) || 'Name must be unique',
                v => !!v && v.length <= 50 || 'Name must be 50 characters or less'
            ],
            startDateRules: [
                v => !!v || 'Please select a Start Date'
            ],
            endDateRules: [
                v => this.endDateIsAfterStartDate(v) || 'Must Occur After Start Date'
            ],
            scheduledDaysRules: [
                v => (!!v && v.length > 0) || 'Please select a Scheduled Run Day'
            ],
            obj: {},
            runDays: [
                {text: 'Mon', value: 'M'},
                {text: 'Tue', value: 'T'},
                {text: 'Wed', value: 'W'},
                {text: 'Thur', value: 'H'},
                {text: 'Fri', value: 'F'},
                {text: 'Sat', value: 'S'},
                {text: 'Sun', value: 'U'}
            ]
        }
    },

    computed: {
        ...mapGetters('thresholds/group', [
            'collection',
            'clear',
            'pending',
            'success',
            'error'
        ]),

        thresholdGroup() {
            return this.$store.getters['thresholds/group/selectedWithMeta']
        },

        otherThresholdGroupNames() {
            let collection = this.$store.getters['thresholds/group/collection'];
            return this.creating
                ? collection
                    .map(x => x.name.toLowerCase().trim())
                : collection
                    .filter(x => x.__idx !== this.thresholdGroup.__idx)
                    .map(x => x.name.toLowerCase().trim())
        },

        settings() {
            return this.mode === 'settings'
        },

        creating() {
            return this.mode === 'creating'
        },

        title() {
            return (this.creating)
                ? "Create a Threshold Grouping"
                : "Threshold Grouping Settings"
        },

        selected() {
            return this.fetched
        },

        weighted: {
            get() {
                return !this.obj.is_conditional
            },
            set(v) {
                this.obj.is_conditional = v ? 0 : 1
            }
        },

        runTimes() {
            return [...Array(24)].map((_, i) => {
                let hr = i
                if (hr === 0) hr = 12
                if (hr > 12) hr = hr - 12
                return {text: `${hr}:00 ${(i >= 12) ? 'pm' : 'am'}`, value: i}
            })
        }
    },

    watch: {
        clear: function (val) {
            if (val) this.reset()
        },

        active: function (val) {
            if (val) {
                if (this.settings) {
                    this.fetchSettings()
                } else {
                    this.obj = {
                        name: null,
                        is_visible_student_page: false,
                        start_date: null,
                        end_date: null,
                        is_conditional: 0,
                        operator: null,
                        scheduled_run_time: 5,
                        scheduled_days: this.runDays.map(x => x.value),
                        clear_previous_results: false
                    }
                }
                if (this.$refs.form) this.$refs.form.resetValidation()
            }
        }
    },

    mounted() {
        this.fetchSettings()
    },

    methods: {
        ...mapActions('thresholds/group', [
            'refresh',
            'refreshSelected'
        ]),

        endDateIsAfterStartDate(v) {
            return (!v || !this.obj.start_date) || (new Date(this.obj.start_date) < new Date(v))
        },

        nameIsUnique(name) {
            return !this.otherThresholdGroupNames.includes(name.toLowerCase().trim())
        },

        apply() {
            if (this.$refs.form.validate()) {
                this.$emit('apply', Object.assign({}, this.defaults, this.obj))
            }
        },

        dismiss() {
            this.$emit('cancel')
        },

        validate() {
            this.$refs.form.validate()
        },

        fetchSettings() {
            if (this.settings && this.thresholdGroup) {
                this.fetching = true
                Api().get(Map.thresholdGroups.path, {
                    params: {
                        action: 'get',
                        property: 'threshold_group',
                        id: this.thresholdGroup.id
                    }
                }).then(rsp => {
                    this.fetched = rsp.data.threshold_group[0]
                    this.obj = {
                        name: this.selected.name,
                        is_visible_student_page: this.selected.is_visible_student_page ? true : false,
                        is_conditional: this.selected.is_conditional ? true : false,
                        start_date: this.selected.start_date
                            ? new Date(this.selected.start_date).toISOString().substring(0, 10)
                            : null,
                        end_date: this.selected.end_date
                            ? new Date(this.selected.end_date).toISOString().substring(0, 10)
                            : null,
                        scheduled_run_time: this.selected.scheduled_run_time || 5,
                        scheduled_days: this.selected.scheduled_days || this.runDays.map(x => x.value),
                        clear_previous_results: this.selected.clear_previous_results ? true : false,
                    }
                }).finally(() => {
                    this.fetching = false
                })
            }
        }
    }
}
</script>

<style lang="scss">
.v-input--selection-controls.v-input--switch {
    margin: 0;
}
</style>
