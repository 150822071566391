<template>
    <div class="d-flex flex-wrap flex-fill" style="overflow: scroll;" v-observe-visibility="visibilityChanged">

        <meeting-creation v-if="editing" :editing="editing" @updated="editing = $event" @closed="editing = false" @close="editing = false" />

        <template v-if="items.length">
            <fe-card v-for="(item, i) in items"
                :headerText="item.title"
                :menu="['Edit', 'Delete']"
                :key="`meeting-`+i"
                width="400"
                class="ma-4 meeting-card d-flex flex-column"
                :style="{height: item.expanded ? undefined : '330px'}"
            >
                <template v-slot:title-toolbar>
                    <menu-btn>
                        <v-list-item @click="editing = item">
                            Edit
                        </v-list-item>

                        <v-list-item v-if="item.created_by == sessionUser.user.id" @click="doDelete(item)">
                            Delete
                        </v-list-item>
                    </menu-btn>
                </template>
                <template v-slot:body>
                    <div class="meeting-card-body fill-height" @click="$router.replace('/Meeting/'+item.id)">

                        <div style="width: 200px; color: #7E8494; font-size: 12px;">
                            {{getSchedule(item)}}
                        </div>

                        <div>
                            {{getTime(item)}}
                        </div>

                        <div style="color: #7E8494; font-size: 12px; margin-top: 16px;">Attendees</div>
                        <div class="flex-grow-0 meeting-user" style="width: 200px;">
                            <v-tooltip bottom v-for="(u,ui) in item.dashboard_meeting_users.slice(0,5)" :key="`dash-mtg-tooltip-${ui}`">
                                <template v-slot:activator="{ on }">
                                    <v-avatar size=26 v-on="on">
                                        <img v-if="u.photo_url" :src="u.photo_url" :alt="u.user_full_name">
                                        <v-icon v-else style="font-size: 26px;">fas fa-user-circle</v-icon>
                                    </v-avatar>
                                </template>
                                {{u.user_full_name}}
                            </v-tooltip>
                            {{ item.dashboard_meeting_users.length > 5 ? '+'+(item.dashboard_meeting_users.length-5) : ''}}

                        </div>

                        <div style="color: #7E8494; font-size: 12px; margin-top: 16px;">Description</div>
                        <div class="pr-4">{{ item.description }}</div>
                        <!-- <div :class="{'meeting-description' : !item.expanded}" :style="{height: item.expanded ? undefined : '50px', overflow: item.expanded ? undefined : 'hidden'}">{{item.description}}</div> -->

                        <!-- <div class="text-right">
                            <fe-btn usage="ghost"
                                :useIcon="item.expanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
                                @click="item.expanded=!item.expanded"
                            >Expand</fe-btn> -->
                        <!-- </div> -->
                    </div>
                </template>
            </fe-card>
        </template>
        <div v-else class="empty-state">
            <fe-empty-state
                image="/images/rocket_transparent.png"
                :text="emptyText"
            />
        </div>
    </div>
</template>

<script>
    import MeetingCreation from './creation'
    import MenuBtn from '@/components/common/button/MenuBtn'
    import { mapState } from 'vuex'
    export default {
        name: 'CardView',
        components: {MenuBtn, MeetingCreation},
        computed: {
            ...mapState('global', ['sessionUser', 'currentYear']),
            emptyText () {
                return (this.params && this.params.dashboard_id)
                    ? 'You have not linked any Meetings to this collection yet.'
                    : 'There are no Meetings to display here.'
            }
        },
        props: {
            params: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                editing: null,
                tools: [],
                items: []
            }
        },
        mounted() {
            this.loadData()
        },
        methods: {
            visibilityChanged (val) {
                if (val) this.loadData()
            },
            doDelete(rec) {
                this.$confirmDelete(rec, () => {
                    this.$axios.post('googleCalendarIntegrate.php?action=delete&property=meeting', { sIds: [rec.schedule_id+'__'+rec.created_by]})
                        .then(r => { this.$ecResponse(r)})

                    this.$axios.post('dashboardMeeting.php?action=destroy', { dashboard_meetings: [rec] })
                        .then(r => { this.$ecResponse(r)})
                        .finally(() => { this.loadData() })
                }, null, 'Deleting this meeting will also delete all comments created and added to students, meeting notes and action items assigned.  Are you sure you want to continue?')
            },
            loadData() {
                if (this.params.dashboard_id) {
                    this.$modelGet('meetingDate', { dashboard_id: this.params.dashboard_id }).then(dates => {
                        for (let date in dates) {
                            if (this.items.findIndex(x => x.id === dates[date].id) === -1) {
                                dates[date].expanded = false
                                this.items.push(dates[date])
                            }
                        }
                    }).finally(() => this.$emit('loading', false))
                } else {
                    this.$modelGet('userMeeting', this.params)
                        .then(response => {
                            if (response.length) {
                                this.$modelGet('meetingDate', { id: response.map( r=> r.id) }).then(dates => {
                                    dates.forEach(r=>r.expanded = false)
                                    this.items = dates
                                })
                            }
                        }).finally(() => this.$emit('loading', false))
                }
            },
            getTime(item) {
                let startTime = null
                let endTime   = null
                if (item.schedule.length > 0) {
                    let sched = item.schedule[0]
                    startTime = sched.default_start_time ? this.$dayjs('2022-01-01 ' + sched.default_start_time).format('h:mm A') : null
                    endTime   = sched.default_end_time ? this.$dayjs('2022-01-01 ' + sched.default_end_time).format('h:mm A') : null
                }

                if (startTime) {
                    return startTime + (endTime ? ' - ' + endTime : '')
                } else if (item.occurrences.length === 1) {
                    let occur = item.occurrences[0]

                    if (!occur.occurrence_start_time && !occur.occurrence_end_time) return 'Meeting times not set'
                    startTime = occur.occurrence_start_time ? this.$dayjs('2022-01-01 ' + occur.occurrence_start_time).format('h:mm A') : null
                    endTime   = occur.occurrence_end_time ? this.$dayjs('2022-01-01 ' + occur.occurrence_end_time).format('h:mm A') : null

                    return startTime + (endTime ? ' - ' + endTime : '')
                }

                return 'Meeting times not set'
            },
            getSchedule(item) {
                if (item.schedule.length > 0) {
                    let sched = item.schedule[0]
                    if (sched.frequency_cd == 'NOSCHED') return 'Not recurring'

                    let r = 'Weekly on '

                    if(sched.week_days) {
                        if (sched.week_days.length === 5) return r += ' Weekdays'
                        sched.week_days.split().forEach(v => {
                            switch (v) {
                                case 'M':
                                    r += 'Mon '
                                    break
                                case 'T':
                                    r += 'Tue '
                                    break
                                case 'W':
                                    r += 'Wed '
                                    break
                                case 'T':
                                    r += 'Thu '
                                    break
                                case 'F':
                                    r += 'Fri '
                                    break
                                case 'S':
                                    r += 'Sat '
                                    break
                                case 'U':
                                    r += 'Sun '
                                    break
                            }
                        })
                    }
                    return r
                } else {
                    return 'No Schedule'
                }
            },
        },
        watch: {
            params(v) {
                this.loadData()
            },
            editing(v) {
                if (!v) this.loadData()
            }
        }
    }
</script>

<style lang="scss" scoped>
.meeting-user {
    padding-top: 8px;
    padding-left: 12px;

    ::v-deep .v-avatar {
        position: relative;
        left: -5px;
        margin-left: -8px;
        border: 2px solid #e6e6e6;
    }
}

.meeting-description {
    position:relative;
}

.meeting-description:after {
    content:"";
    position:absolute;
    bottom:0px;
    left:0;
    height:15px;
    width:100%;
    background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,.9));
}

::v-deep .meeting-card .fe-card-title {
    .flex.grow {
        height: 100%;
        align-self: baseline;
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important;
    }

    .flex.grow div:first-child {
        margin-left: auto;
    }

    .fe-card-title-text {
        max-width: 320px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
            white-space: pre-wrap;
            word-break: break-word;
        }
    }
}

::v-deep .meeting-card .fe-card-text-full {
    height: auto;
    flex: 1;
    overflow: auto;
}

.meeting-card-body {
    height: 100%;
    cursor: pointer;
    overflow: auto;
}

.ec-meetings {
    &-empty-state {
        justify-content: center;
        display: flex;
        text-align: center;
    }
}

.empty-state {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    ::v-deep img {
        height: 340px;
    }
}
</style>
