<template>
    <div class="flex-column flex-fill">
        <form-filter v-model="filterParams" :templates="chartData" />
        <div style="overflow-y:scroll;">
            <template v-for="chart in charts">
                <highcharts
                    ref="chart"
                    :options="chart"
                ></highcharts>
            </template>
        </div>
    </div>
</template>

<script>
import FormFilter from './FormFilter'
import FormReport from './FormReport'
import windowOptionsMixin from '@/mixins/windowOptions'

export default {
    name: 'Reporting',
    mixins: [ windowOptionsMixin ],
    components: {
        FormFilter,
        FormReport,
    },

    watch: {
        filterParams: {
            deep: true,
            handler(v) {
                this.loadData({
                    ...v,
                    form_types: this.$pluckJoin(v.form_types?.included,'form_id'),
                    user_id: this.$pluckJoin(v.user_id?.included,'id'),
                    school_year_id: Array.isArray(v.school_year_id) ? v.school_year_id.join(',') : v.school_year_id,

                })
            }
        }
    },

    created() {
        this.filterParams = {
            school_year_id: this.$store.state.global.shareableStores.current_year.id,
            modified: 1,
        }
    },

    methods: {
        loadData(params) {
            let selectForm = (i) => {
                let item = this.chartData[i]
                this.$dockableWindow({
                    name: item.form_name,
                    component: 'form-report',
                    attrs: {
                        templateId: item.form_id,
                        showChartsFirst: true,
                        filters: this.filterParams,
                        class: 'pa-4',
                    },
                })
            }

            this.$axios({
                url: 'form.php?action=get&property=default_report_data',
                params: params,
            }).then(r => {
                this.chartData = r.data.report_data.instance_count.data
                let height = this.chartData.length * 26 + 80
                this.charts[0] = {
                    credits: { enabled: false },
                    exporting: {
                        buttons: {contextButton: {enabled:false}}
                    },
                    chart: {
                        type: 'bar',
                        height: height+'px',
                        events: {
                            render: function() {
                                let ticks = this.xAxis[0].ticks
                                this.series[0].points.forEach((point,i) => {
                                    if(ticks[i]) {
                                        let label = ticks[i].label.element
                                        label.classList.add('smartform-reporting-boldhover')
                                        label.onclick = () => selectForm(i)
                                    }
                                })
                            }
                        }
                    },
                    title: {text: null},
                    xAxis: {
                        categories: this.chartData.map(x => x.form_name),
                        labels: { style: { fontSize: '12px' } },
                    },
                    series: [{
                        showInLegend: false,
                        data: this.chartData.map(x => x.form_count)
                    }],
                    yAxis: { title: {text: null} },
                    tooltip: {
                        headerFormat: '',
                        pointFormat: '<b>{point.y}</b>',
                        // shared: true
                    },
                    drilldown: [],
                    plotOptions: {
                        series: {
                            point: {
                                events: {
                                    click: e => selectForm(e.point.index)
                                }
                            }
                        }
                    }
                }
            })

        },
    },

    data() {
        return {
            filterParams: {},
            chartData: [],
            charts: [],
        }
    }
}

</script>

<style lang="scss">
.smartform-reporting-boldhover:hover {
    cursor: pointer !important;
    fill: black !important;
    font-weight: bold;
}
</style>
