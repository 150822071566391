<template>
<div>
    <button @click="dialog=true" >{{displayName}}</button>
    <v-dialog v-model="dialog" width="600">
        <fe-stepper-filter
            :title="title" style="height:600px;"
            @apply="emit" @close="dialog=false"
            :applyDisabled="!enableFinalPanel"
        >
            <fe-stepper-select-panel :items="demographicItems" v-model="demographic" width="50%" />
            <v-divider vertical style="background-color:#E0E1E6"/>

            <fe-stepper-panel width="50%" style="padding:10px;">
                <!-- demo:{{demographic}}<br/> -->
                <!-- demoparams:{{demographicParams}}<br/> -->
                <template v-if="enableFinalPanel">
                    <fe-remote-combo
                        label="School Year"
                        infoText="If a student was exited at any time during the year, they will be considered exited for the entire year."
                        v-model="school_year"
                        rootProperty="years"
                        url="qry.php?action=get&property=school_years"
                    ></fe-remote-combo>
                    <v-layout row>
                        <v-flex class="pr-2">
                            <v-switch v-model="restrict" label="Restrict Results" hide-details />
                        </v-flex>
                        <v-flex>
                            <v-switch v-model="require_all" label="Require All" hide-details />
                        </v-flex>
                    </v-layout>

                    <advanced-demographic-filter
                        v-model="demographicParams"
                        :demographic="demographic"
                        @result="parseDemographicResult"
                    />

                </template>

                <!-- {{demographicResult}} -->

            </fe-stepper-panel>

            <template #footer>
                <div class="text-truncate" style="padding:14px">{{breadcrumb}}</div>
            </template>
        </fe-stepper-filter>
    </v-dialog>
</div>
</template>

<script>
import * as Util from '../../Util'
import AdvancedDemographicFilter from "@/components/common/AdvancedDemographicFilter"

export default {
    name: 'DemographicsPanel',
    rank: 1,
    components: {
        AdvancedDemographicFilter,
    },
    watch: {
        dialog(v) {
            if(!v) this.oldFilter = null
            v || this.clear()
        },
    },

    computed: {
        demographicItems: me => me.$store.getters['global/demographicsViewable'],
        title: me => me.oldFilter ? `Edit ${me.displayName} Filter` : `Create ${me.displayName} Filter`,
        enableFinalPanel: me => !me.$_.isEmpty(me.demographic),
        breadcrumb: me => me.demographic?.name,
    },

    created() {
        this.$store.dispatch('global/loadDemographics')
    },

    methods: {
        clear() {
            this.restrict = true
            this.demographic = null
            this.demographicParams = null
            this.demographicResult = null
            this.require_all = false
            this.school_year = null
        },

        load(oldFilter) {
            this.oldFilter = oldFilter
            this.dialog = true
            let ep = oldFilter.editorParams

            this.restrict = ep.restrict,
            this.demographic = ep.demographic,
            this.demographicParams = ep.demographicParams,
            this.demographicResult = ep.demographicResult,
            this.require_all = ep.require_all,
            this.school_year = ep.school_year
        },

        emit() {
            this.dialog = false
            let uniqueId = Util.UUIDv4()
            let name = this.demographic.name
            let key = `demo_${this.demographic.id}`
            let filter = {
                type: key,
                id: uniqueId,
                restrict: this.restrict,
                report: true,
                params: this.$_.pickBy({
                    [key]: this.demographicParams,
                    [uniqueId]: this.demographic.value_type === 'option' ? this.displayNamesWithIds() : null,
                    require_all: this.require_all,
                    school_year_id: this.school_year?.id,
                }, x => !this.$_.isBlank(x)),
            }
            Object.defineProperties(filter, {
                editor: {value: this},
                editorParams: {value: {
                    restrict: this.restrict,
                    demographic: this.demographic ? {...this.demographic} : null,
                    demographicParams: this.demographicParams,
                    demographicResult: this.demographicResult ? {...this.demographicResult} : null,
                    require_all: this.require_all,
                    school_year: this.school_year ? {...this.school_year} : null,
                }},
                grouper: {value: {
                    id: uniqueId,
                    name: name,
                    type: 'propertyArray',
                    args: {path: `filters.${uniqueId}`, property: 'value'},
                }},
                chipText: {value: name},
                tooltipData: {value: this.$_.pickBy({
                    'Demographic': name,
                    'Params': this.demographicResult?.textWithOp,
                    'Restrict': this.restrict,
                    'Require All': this.require_all,
                    'School Year': this.school_year?.name,
                }, x => !this.$_.isBlank(x))}
            })
            this.$emit('input', filter, this.oldFilter)
        },

        parseDemographicResult(e) {
            this.demographicResult = ''
            if(e) {
                this.$parseDemographic(e.key,e.encoded).then(r => {
                    this.demographicResult = r
                })
            }
        },

        displayNamesWithIds() {
            let options = this.demographic.options
            let names = this.demographicResult?.text.split(', ')
            let demo = {}
            names.forEach((name, i) => {
                let option = options.find(o => (o.display_name || o.value) === name)
                demo[i] = {
                    ids: option.display_name_group || option.id,
                    value: option.display_name || option.value
                }
            })
            return demo
        }
    },

    data() {
        return {
            oldFilter: null,
            displayName: 'Demographic',
            type: 'demographic',
            dialog: false,
            restrict: true,
            demographic: null,
            demographicParams: null,
            demographicResult: null,
            tagged_as_of_date: null,
            require_all: false,
            school_year: null,
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
