let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    collectionSavedSearchType: {
        defaults: {
            endpoint: 'dashboard.php?action=get&property=saved_search_type',
            rootProperty: 'saved_search_types'
        },
        ...standardCrud
    },
    collectionHome: {
        defaults: {
            endpoint: 'dashboard.php?action=get&property=home',
            rootProperty: 'dashboard'
        },
        ...standardCrud
    },
    collectionGroups: {
        defaults: {
            endpoint: 'dashboard.php?action=get&property=dashboard_group',
            rootProperty: 'dashboard_groups'
        },
        ...standardCrud
    },
    collectionUpdate: {
        defaults: {
            endpoint: 'dashboard.php?property=saved_search',
            rootProperty: 'saved_search'
        },
        ...standardCrud
    },
    collectionUsers: {
        defaults: {
            endpoint: 'dashboard.php?action=get&property=users',
            rootProperty: 'users'
        },
        ...standardCrud
    },
    collection: {
        defaults: {
            endpoint: 'dashboard.php'
        },
        read: {
            params: { action: 'get' }, rootProperty: 'dashboards'
        },
        create: {
            params: { action: 'create' }, rootProperty: 'dashboard'
        },
        update: {
            params: { action: 'update' }, rootProperty: 'dashboard'
        },
        destroy: {
            params: { action: 'delete' }, rootProperty: 'dashboards'
        }
    }
}

