<template>
    <v-layout row wrap>

        <v-flex xs6>
            <fe-label class="mr-2">
                Field Name
            </fe-label>
            <v-text-field flat solo dense class="mr-2"/>
        </v-flex>

        <v-flex xs6>
            <fe-label>
                Field Type
            </fe-label>
            <fe-remote-combo
                :url="optionsURL"
                itemText="name"
                itemValue="id"
                rootProperty="incident_field_types"
            />
        </v-flex>

        <v-flex xs4>
            <v-switch label="Required"/>
        </v-flex>

        <v-flex xs4>
            <v-switch label="Chart Results"/>
        </v-flex>

        <v-flex xs4>
            <v-switch label="Include in Export"/>
        </v-flex>

        <v-flex xs4>
            <v-switch label="Active"/>
        </v-flex>

        <v-flex xs12>
            <div class="headline mt-4">
                Field Options
            </div>

            <fe-crud-grid
                :config="optionGridCrud"
                :columnDefs="optionColumnDefs"
                displayDensity="medium"
            />
        </v-flex>
    </v-layout>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'IncidentField',
        props: {
            id: {

            }
        },
        computed: {
            crudConfig() {
                return this.$models.incidentField
            },
            optionsURL() {
                return 'incidentField.php?action=get&property=incident_field_options&incident_behavior_type_id=' + this.incidentBehaviorTypeId
            },
            gridCrud() {
                return {
                    rules: [
                        r => (r.name && r.name.length > 0) || "A name is required"
                    ],
                    defaults: {
                        // endpoint: this.crudConfig.read,
                        rootProperty: this.crudConfig.rootProperty,
                        params: {
                            id: this.id
                        }
                        // method: "get"
                    },
                    create: {
                        endpoint: this.crudConfig.create
                    },
                    read: {
                        endpoint: this.crudConfig.read
                    },
                    update: {
                        endpoint: this.crudConfig.update
                    }
                }
            },
            optionGridCrud() {
                return {
                    rules: [
                        r => (r.name && r.name.length > 0) || "A name is required"
                    ],
                    defaults: {
                        // endpoint: this.crudConfig.read,
                        rootProperty: this.crudConfig.rootProperty,
                        params: {
                            incident_behavior_type_id: this.incidentBehaviorTypeId
                        }
                        // method: "get"
                    },
                    create: {
                        endpoint: this.crudConfig.create
                    },
                    read: {
                        endpoint: this.crudConfig.read
                    },
                    update: {
                        endpoint: this.crudConfig.update
                    }
                }
            }
        },
        data() {
            return {
                optionColumnDefs: [
                    { headerName: null, headerCheckboxSelection: true, checkboxSelection: true, width: 70, minWidth: 70, colId: 'checkbox_column' },
                    {
                        headerName: "Label",
                        field: "label",
                        sortable: true,
                        editable: true
                    }, {
                        headerName: 'Active',
                        field: 'active',
                        sortable: true,
                        editable: true,
                        cellRendererFramework: 'FeGridToggle',
                        cellRendererParams: {
                            rowDataKey: 'active'
                        },

                        cellStyle: { "padding-top": 15 }
                    }
                ],
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>