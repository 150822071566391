<template lang="html">
    <div ref="panel" class="panel" v-bind:class="{ hide:!show }">
        <v-expansion-panels  multiple :value="[0,1]">
            <v-expansion-panel :key="0">
                <v-expansion-panel-header>Group</v-expansion-panel-header>

                <v-expansion-panel-content style="background-color:white;">
                    <div v-for="x in groupers">
                        <v-menu
                            v-if="x.type === 'multiple' && checkShow(x)"
                            offset-x
                            close-on-content-click
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <button
                                    v-on="on"
                                    :class="{ active: isActive(x) }"
                                    class="list-item"
                                >
                                    {{ multiName(x) }}
                                    <v-icon class="mr-1" style="float: right" x-small>fas fa-caret-right</v-icon>
                                </button>
                            </template>

                            <v-list dense>
                                <v-list-item v-for="y in x.subGroupers" style="cursor: pointer">
                                    <v-list-item-title @click="setGrouping(y)">
                                        {{ y.name }}
                                        <v-icon
                                            v-if="isActive(y)"
                                            class="mr-1 active-check"
                                            x-small
                                        >
                                            fas fa-check
                                        </v-icon>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <button
                            v-else-if="checkShow(x)"
                            @click="setGrouping(x)"
                            :class="{ active: isActive(x) }"
                            class="list-item"
                        >
                            {{ truncate(x.name) }}
                            <fe-info-tip v-if="x.infoText" style="display:inline-block" :tooltip="x.infoText"/>
                        </button>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :key="1">
                <v-expansion-panel-header>Filter</v-expansion-panel-header>

                <v-expansion-panel-content style="background-color:white;">
                    <component v-for="x in filterPanels"
                        v-show="checkShow(x)"
                        :is="x"
                        @input="setFilter(...arguments)"
                        :params="params"
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import Group from '../Group'
import {mapState} from 'vuex'
import {mapLocalState} from '@/util/vuexHelper'

export default {
    name: 'LeftSidePanel',

    inject: ['localStore'],

    props: {
        show: {type:Boolean, default:true},
    },

    data() {
        return {
            filterPanels: []
        }
    },

    computed: {
        ...mapLocalState(['params','groupers','activeGrouping','charts']),

        ...mapState('global',['sessionUser']),

        ellLevelEnabled () {
            return this.$store.state.global.shareableStores.ell_level_enabled
        },
    },

    created() {
        const filterPanelFiles = require.context('./filterPanels/',true,/\.vue$/);
        filterPanelFiles.keys().forEach(fileName => {
            const componentConfig = filterPanelFiles(fileName)
            const config = componentConfig.default || componentConfig
            this.filterPanels.push(config)
        })
    },

    methods: {
        checkShow(c) {
            //** For EL **//
            if (!this.ellLevelEnabled && c.el) {
                return false
            }
            //** For Security **//
            return this.$_.difference(c.security,this.sessionUser.security_codes).length === 0
        },

        truncate(text) {
            return text.length > 50 ? text.substring(0,50-3)+'...' : text
        },

        multiName(grouper) {
            if (this.isActive(grouper)) {
                return this.truncate(this.activeGrouping.grouper.name)
            }
            return this.truncate(grouper.name)
        },

        isActive(grouper) {
            if (!grouper) return // prevents console error if grouper is undefined

            let active = false
            let id = this.activeGrouping.id
            if (grouper.subGroupers) {
                grouper.subGroupers.forEach(x => {
                    if (id === x.id) active = true
                })
            } else {
                active = grouper && id === grouper.id
            }
            return active
        },

        setGrouping(grouper) {
            if(this.isActive(grouper)) {
                this.activeGrouping = Group.none(this.charts)
            } else {
                this.activeGrouping = Group.setGrouper(this.charts, grouper)
            }
        },

        setFilter(newFilter,oldFilter) {
            if(oldFilter) {
                // replace oldFilter and grouper
                this.params.filters = this.params.filters.map( x => x == oldFilter ? newFilter : x)
                if(this.isActive(oldFilter.grouper)) this.activeGrouping.grouper = newFilter.grouper
                this.groupers = this.groupers.map( x => x == oldFilter.grouper ? newFilter.grouper : x)
            } else {
                this.params.filters = [
                    ...this.params.filters,
                    newFilter,
                ]

                if(newFilter.grouper) {
                    this.groupers = [...this.groupers, newFilter.grouper]
                }
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.panel {
    min-width: 200px;
    max-width: 200px;
    background-color: white;
    overflow: auto;
    overflow-x: hidden;
    transition: margin-left .15s ease-in-out;

    ::v-deep button {
        display: block;
        margin: 0 auto;
        margin-bottom: 8px;
        padding: 5px;
        padding-left: 10px;
        width: calc(100% - 16px);
        font-size: 12px;
        text-align: left;
        color: #050f2d;
        border: 1px solid #CED0D9;
        border-radius: 5px;
        background-color: #fff;
        transition: background-color .3s ease;
        outline: none;

        &:hover {
            background-color: #dcdcdc;
        }

        &.active {
            color: white;
            background-color: var(--v-primary-base);
            border-color: var(--v-primary-base);
        }
    }
}

.active-check {
    float: right;
    color: var(--v-primary-base);
}

.list-item {
    position: relative;
}

.list-item div[feid="FeTooltip"] {
    position: absolute;
    right: 8px;
}

.hide {
    margin-left: -200px;
}

.v-expansion-panel {
    margin: 0 !important;
    &::before {
        box-shadow: none !important;
    }
    ::v-deep .v-expansion-panel-content__wrap {
        padding: 8px !important;
    }
    ::v-deep .v-expansion-panel-header {
        border: none !important;
        padding: 8px;
        margin: 0 8px;
        min-height: 32px;
        font-size: 14px;
        &__icon {
            position: absolute;
            right: 4px;
        }
    }
}

</style>
