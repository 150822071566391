<template>
    <div class="d-flex flex-wrap wrapper" >
        <div v-if="!editor" v-for="x in this.data" :key="x.unique_id" class="card">
            <div class="header">{{x.desc}}</div>
            <div class="id">{{x.unique_id}}</div>
        </div>
        <div v-if="editor" class="card">
            <div class="dim header">ID Type</div>
            <div class="dim id">12345</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SmartStudentId',
        props: ['data','fieldRec','editor'],
        data() {
            return {}
        }
    }
</script>

<style lang="scss" scoped>
.wrapper {
    border: 1px solid #ced0d9;
    border-radius: 3px;
    min-height: 43px;
    padding: 2px;
    margin-bottom: 19px;
    background-color: white;
}
.card {
    padding: 0 8px;
}
.header {
    color: rgb(126,132,148);
    font-size: 12px;
}
.id {
    font-size: 13px;
}
.dim {
    opacity: .65;
}
</style>
