<template>
    <fe-dialog
        :title="title"
        width="700"
        height="600"
        dismissable
        dismissButtonText="Cancel"
        :acceptButtonText="acceptButtonText"
        :acceptButtonDisabled="acceptButtonDisabled"
        @accept="submit"
        @dismiss="$emit('dismiss')"
    >
        <div style="height: 412px">
            <div v-if="!advancedSearch" class="flex-fill flex-column no-scroll">
                <div class="d-flex search-wrapper">
                    <fe-remote-combo
                        ref="usersSearchField"
                        v-model="userToViewAs"
                        placeholder="Search users"
                        url="users.php?action=get&active=1"
                        itemText="user_full_name"
                        prepend-inner-icon="fas fa-search"
                        style="width: 100%"
                        @change="select"
                    />
                    <span class="advanced-btn" @click="advancedSearch = true">
                    <u>Advanced</u>
                </span>
                </div>

                <div class="flex-fill flex-column no-scroll">
                    <fe-grid
                        ref="grid"
                        :rowData="filteredUsers"
                        :columnDefs="columnDefs"
                        :autoload="true"
                        :searchBar="false"
                        :showAddRowBtn="false"
                        :showDownload="false"
                        :showToolbar="false"
                        disableInlineCreate
                        disableFilterUpdate
                        suppressContextMenu
                        style="height: 100%;"
                        @cellClicked="select"
                    />
                </div>
            </div>

            <user-search
                v-else
                v-model="userToViewAs"
                :gridHeight="365"
                :multiSelect="false"
                @input="select"
            />
        </div>

        <fe-crud ref="crud" :config="crudConfig" autoload @read="sharedUsers = $event"/>
    </fe-dialog>
</template>

<script>
import UserSearch from "@/components/common/UserSearch"
import {mapState} from "vuex"

export default {
    name: "ViewAs",

    components: {UserSearch},

    props: {
        dashboard: {
            type: Object,
            required: true
        },
        viewAs: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            columnDefs: [{
                headerName: "Shared With Users",
                field: 'user_full_name',
                valueGetter: v => v.data.user_full_name ? v.data.user_full_name : v.data.user_group_name,
                sortable: true
            }],
            sharedUsers: [],
            advancedSearch: false,
            userToViewAs: null
        }
    },

    computed: {
        ...mapState('global', ['sessionUser']),

        title() {
            let title = 'View As'
            if (this.viewAs) {
                title = 'Viewing As: ' + this.viewAs.user_full_name
            }
            return title
        },

        acceptButtonText() {
            if (this.viewAs && this.$_.isEmpty(this.userToViewAs)) {
                return 'Clear'
            }
            return 'Select'
        },

        acceptButtonDisabled() {
            return this.$_.isEmpty(this.userToViewAs) && !this.viewAs
        },

        crudConfig() {
            let cfg = this.$_.cloneDeep(this.$models.dashboardUsers)
            cfg.read.params.kpi_dashboard_id = this.dashboard.id
            return cfg
        },

        filteredUsers() {
            return this.sharedUsers.filter(x => {
                let createdBy = x.user_id === this.dashboard.created_by
                let currentUser = x.user_id === this.sessionUser.user.id
                return !createdBy && !currentUser
            })
        },
    },

    methods: {
        submit() {
            this.$emit('viewAs', this.userToViewAs)
        },

        select(selection) {
            if (!this.$_.isEmpty(selection)) {
                let user = selection
                if (user.data) {
                    user = user.data
                } else if (this.$_.isArray(user)) {
                    user = user[user.length - 1]
                }
                this.userToViewAs = {
                    user_full_name: user.user_full_name,
                    id: user.user_id ?? user.id
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.search-wrapper {
    &:focus-within {
        .advanced-btn {
            display: none;
        }
    }
}

.advanced-btn {
    position: absolute;
    right: 65px;
    top: 69px;
    font-size: 12px;
    color: var(--v-primary-base);
    cursor: pointer;
}
</style>
