<template>
    <fe-dialog
        :title="title"
        width="900"
        height="600"
        dismissable
        dismissButtonText="Cancel"
        acceptButtonText="Save"
        :acceptButtonDisabled="!valid"
        @accept="save"
        @dismiss="$emit('close')"
    >
        <v-form v-if="ready" ref="form" @submit.prevent v-model="valid">
            <fe-remote-combo
                label="Our goal will be to have..."
                :items="operatorOptions"
                v-model="config.operator"
                :rules="this.$fieldValidators('select', null, {required: true})"
                byId
            />

            <fe-label>A Value of</fe-label>
            <v-text-field
                v-model="config.value"
                :rules="this.$fieldValidators('number', null, {required: true, min: 0})"
                type="number"
                flat solo dense clearable
            />

            <!-- Assessment -->
            <fe-remote-combo
                v-if="isAssessment"
                label="Based On..."
                :items=typeOptions
                v-model="config.type"
                :rules="this.$fieldValidators('select', null, {required: true})"
                byId
            />

            <fe-remote-combo
                v-if="isAssessment"
                label='In the following combined performance bands...'
                v-model="savedSearch.target_descriptor_id"
                :url='bandUrl'
                rootProperty='descriptors'
                :rules="this.$fieldValidators('select', null, {required: true})"
                multiple
            />
            <!-- End Assessment -->

            <fe-label>Custom Statement</fe-label>
            <v-text-field
                v-model="config.custom_statement"
                counter="50"
                :placeholder="statementPlaceholder"
                :rules="this.$fieldValidators('text', null, {required: false, limit: 50})"
                flat solo dense clearable
            />

            <fe-label>Description</fe-label>
            <v-textarea
                v-model="config.description"
                counter=255
                rows=4
                :rules="this.$fieldValidators('text', null, {required: false, limit: 255})"
                flat solo dense no-resize
            />
        </v-form>
    </fe-dialog>
</template>

<script>
export default {
    name: "GoalConfig",

    props: {
        visConfig: {
            type: Object,
            required: true
        },
        getSSValues: {
            type: Function,
            required: true
        }
    },

    data() {
        return {
            ready: false,
            valid: false,
            config: {
                operator: undefined,
                value: undefined,
                custom_statement: undefined,
                description: undefined
            },
            savedSearch: {
                target_type: undefined,
                target_set_id: undefined,
                target_descriptor_id: undefined
            },
            typeOptions: [{
                id: 'count',
                name: 'Total Count/Value'
            }, {
                id: 'pct',
                name: 'Total Percent'
            }],
            operatorOptions: [{
                id: 'less',
                name: 'Less Than'
            }, {
                id: 'greater',
                name: 'Greater Than'
            }, {
                id: 'equal',
                name: 'Equal To'
            }]
        }
    },

    computed: {
        title() {
            let output = this.visConfig.goal ? 'Edit' : 'Set'
            output += ' Goal'
            return output
        },

        statementPlaceholder() {
            let output = ''

            if (this.config.operator && this.config.value) {
                output = 'Default: ' + this.setGoalStatement(this.config, this.visConfig)
            }

            return output
        },

        isAssessment() {
            return this.visConfig.type === 'assessment'
        },

        bandUrl() {
            let url = this.$models.getUrl('targetSetDescriptor', 'read')
            if (this.savedSearch.target_type?.id) {
                url += '&types=' + this.savedSearch.target_type.id
            }
            if (this.savedSearch.target_set_id?.id) {
                url += '&target_set_id=' + this.savedSearch.target_set_id.id
            }
            return url
        }
    },

    mounted() {
        if (this.visConfig.goal) {
            let base = this.isAssessment ? {type: undefined} : {}
            this.config = Object.assign(base, this.config, this.visConfig.goal)
        }

        if (this.isAssessment) {
            this.setAssessmentValues()
        } else {
            this.ready = true
        }
    },

    methods: {
        save() {
            let config = this.$_.cloneDeep(this.config)
            config.default_statement = this.setGoalStatement(config, this.visConfig)
            this.$emit('save', this.visConfig, config, this.savedSearch)
        },

        setGoalStatement(goalConfig, visConfig) {
            let name = visConfig.name
            let operator = visConfig.goal_operator
            if (goalConfig.operator) {
                operator = goalConfig.operator
            }
            switch (operator) {
                case 'equal':
                    operator = ' equal to '
                    break
                case 'less':
                    operator = ' less than '
                    break
                case 'greater':
                    operator = ' greater than '
                    break;
            }
            let value = goalConfig.value || parseFloat(visConfig.goal_value)
            let type = goalConfig.type === 'pct' ? ' based on the percentage of students.' : '.'
            return "For the data in " + name + " we hope to attain a value" + operator + value + type
        },

        setAssessmentValues() {
            this.getSSValues(this.visConfig.saved_search_id, this.savedSearch)
                .then(() => {
                    if (this.visConfig.goal?.saved_search_id) {
                        this.getSSValues(this.visConfig.goal.saved_search_id)
                            .then(res => {
                                this.savedSearch.target_descriptor_id = res.savedSearch.target_descriptor_id
                                this.ready = true
                            })
                    } else {
                        this.savedSearch.target_descriptor_id = undefined
                        this.ready = true
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>
