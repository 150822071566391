import BaseService from '@/components/modules/thresholds/services/BaseService'
import BaseStore from '../Base'

export default Object.assign({}, BaseStore.default(), {
    namespaced: true,

    getters: Object.assign({}, BaseStore.default().getters, {
        __config(state, getters, rootState, rootGetters) {
            return {
                service: BaseService,
                opts: {type: 'results'},
                params: {property: 'chart', threshold_group_id: rootGetters['thresholds/group/selectedId']},
                prop: 'chart'
            }
        }
    })
})
