<template>
    <div class="d-flex flex-fill flex-column" style="overflow: hidden; padding-bottom: 60px;">
        <create-code @save="$refs.crud.read()" v-model="addCodeDialog.show" :incidentBehaviorTypeId="addCodeDialog.incident_behavior_type_id"/>

        <fe-dialog
            v-if="addDialog.show"
            title="Add Incident Type"
            :acceptButtonDisabled="!addDialog.name || addDialog.name.length > 25"
            @dismiss="addDialog.show = false"
            @accept="saveRecord({ name: addDialog.name })"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            persistent
            @close="addDialog.show=false"
        >
            <v-form @submit.prevent>
                <fe-label>Incident Type Name</fe-label>
                <v-text-field
                    v-model="addDialog.name"
                    placeholder="Enter name"
                    solo flat dense
                    :counter="25"
                    :rules="$fieldValidators('text', null, { required: true, limit: 25})"
                />

            </v-form>
        </fe-dialog>

        <v-dialog width="500" v-model="renameDialog.show" v-if="renameDialog.show">
            <v-card>
                <v-card-title>
                    Rename Incident Type
                </v-card-title>
                <v-card-text>
                    <model-form
                        idProperty="id"
                        @cancel="finishRename"
                        @done="finishRename"
                        :readParams="{active: 1}"
                        :records="renameDialog.records"
                        model="incidentBehaviorType"
                        :fieldConfig="fieldConfig"
                        submitButtonText="Save"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <fe-dialog
            v-if="detailDialog.show"
            acceptButtonText="Manage Codes"
            @accept="doManageCodes()"
            @dismiss="detailDialog.show=false"
            title="Incident Codes"
        >
            <v-list style="height: 300px;">
                <v-list-item v-for="(detail, index) in details" :key="`detail-list-`+index">
                    <v-list-item-content>
                        {{detail.name}}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </fe-dialog>

        <fe-dialog
            v-if="deleteDialog.show"
            :body="deleteConfirmText"
            @dismiss="deleteDialog.show = false"
            @accept="deleteRecord"
        />

        <fe-crud
            ref="crud"
            autoload
            :config="crudConfig"
            :readParams="{ manage: 1 }"
            @read="v => items = v"
        />

        <div class="headline mb-2">Types</div>

        <fe-action-list
            @click="$emit('click', $event)"
            @chipClick="showDetails($event, 'name')"
            show-search
            :showFiltering="false"
            :items="actionListItems"
            chipKey="chips"
            iconKey="icons"
            @rowClick="doSelect"
            addButtonText="Add"
            deleteButtonText="Delete Type"
            @addButtonClick="showAddDialog"
            @beginDestroy="deleteRecord"
            ref="list"
            @editButtonClick="showEditMenu"
            @selectionChanged="updateSelections"
        >
            <template v-slot:beforeButtons>
                <div class="d-flex">
                    <fe-btn @click="doRename" usage="tertiary" :disabled="selections.length === 0" class="ml-auto" v-if="showExtraTools">Rename</fe-btn>
                </div>
            </template>
        </fe-action-list>
    </div>
</template>

<script>
    import Vuex from 'vuex'
    import { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'
    import IncidentBehavior from './IncidentBehavior'
    import IncidentTypeCard from './IncidentTypeCard'
    import CrudTools from '@/components/common/CrudTools'
    import ActionList from '@/components/common/ActionList'
    import ModelForm from '@/components/common/form/ModelForm'
    import CreateCode from './dialogs/CreateCode'

    export default {
        name: 'IncidentTypeList',
        components: {
            CrudTools,
            IncidentBehavior,
            IncidentTypeCard,
            ActionList,
            ModelForm,
            CreateCode
        },
        inject: ['localStore'],
        computed: {
            ...mapState('manage', ['incidentTypes', 'breadcrumbs']),
            ...mapLocalState(['statusFields', 'toggleFields', 'statusOptions', 'selectedIncidentType']),
            crudConfig() {
                return this.$models.incidentBehaviorType
            },
            actionListItems() {
                let arr = [...this.items]

                arr.forEach((rec) => {
                    rec.icons = []
                    rec.chips = [{
                        name: rec.incident_behaviors.length + ' Code' + this.$addS(rec.incident_behaviors),
                        icon: rec.incident_behaviors.length === 0 ? 'fa-exclamation-circle' : null,
                        data: rec.incident_behaviors,
                        menuButtonText: rec.incident_behaviors.length === 0 ? 'Add Codes' : 'Manage Codes'
                    }]

                    let hidden = {
                        count: 0,
                        data: []
                    }

                    if (rec.show_description === 0) { hidden.count++; hidden.data.push({ name: 'Description '}) }
                    if (rec.show_motivation === 0) { hidden.count++; hidden.data.push({ name: 'Motivation '}) }
                    if (rec.show_response === 0) { hidden.count++; hidden.data.push({ name: 'Response '}) }
                    if (rec.show_parent_contact === 0) { hidden.count++; hidden.data.push({ name: 'Parent Contact '}) }

                    if (hidden.count > 0) {
                        rec.chips.push({
                            name: hidden.count + ' Hidden Section' + (hidden.count > 1 ? 's' : ''),
                            // data: hidden.data,
                            excludeClick: true
                        })
                    }
                    if (rec.kpi) {
                        rec.chips.push({
                            name: 'KPI',
                            // data: rec,
                            excludeClick: true
                        })
                    }

                    if (!rec.default_search) {
                        rec.icons.push({
                            icon: 'fas fa-signal-slash',
                            tooltip: 'Hidden from default search',
                        })
                    }

                    if (rec.notify_flag)
                        rec.icons.push({
                            icon: 'fas fa-bell',
                            tooltip: 'Notification Enabled',
                        })

                    if (rec.schools.length > 0)
                        rec.icons.push({
                            icon: 'fas fa-university',
                            tooltip: 'Restricted to ' + rec.schools.length + ' School' + (rec.schools.length > 1 ? 's' : ''),
                        })
                })

                return arr
            },
            headers() {
                let h = [{
                    text: 'Sel',
                    value: 'selected_data_record'
                }, {
                    text: 'Incident Type',
                    // align: 'left',
                    sortable: false,
                    value: 'name'
                }]

                this.toggleFields.forEach((f) => {
                    h.push({
                        text: f.label,
                        value: f.model
                    })
                })

                h.push(
                    { text: 'Codes', value: 'incident_behaviors' },
                    { text: 'Edit', value: 'edit_btn' })

                return h
            },
            deleteConfirmText() {
                return 'Are you sure you want to delete these ' + this.selectedRecords.length + ' record(s).  <br><br>' +
                    '<b>This change is permanent!</b>'
            }
        },
        data() {
            return {
                addCodeDialog: {
                    show: false,
                    incident_behavior_type_id: null
                },
                fieldConfig: [{
                    field: 'name',
                    label: 'Incident Type',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { required: true, limit: 25 },
                    width: '100%',
                    attrs: {
                        counter: 25,
                        required: true,
                        flat: true,
                        solo: true,
                        dense: true
                    }
                }],
                showExtraTools: false,
                selectedItem: null,
                selections: [],
                renameDialog: {
                    show: false,
                    records: []
                },
                items: [],
                editing: false,
                addDialog: {
                    show: false,
                    name: ''
                },
                detailDialog: {
                    show: false
                },
                details: [],
                deleteDialog: {
                    show: false
                },
                max25chars: v => v.length <= 25 || 'Name too long!',
                selectedRecords: []
            }
        },
        mounted() {
            // this.loadData()
        },
        methods: {
            showEditMenu() {
                if (!this.showExtraTools) this.selections = []

                this.showExtraTools=!this.showExtraTools
            },
            updateSelections(records) {
                this.$data.selections = records
            },
            doRename() {
                this.renameDialog.records = this.$data.selections.map(rec => { return {id: rec.id, name: rec.name} } )
                this.renameDialog.show = true
            },
            finishRename() {
                this.renameDialog.show = false
                this.renameDialog.records = []
                this.$refs.crud.read()
                this.$refs.list.edit()
            },
            doManageCodes() {
                this.$emit('manageCodesClick', this.detailDialog.record)
                this.detailDialog.show=false
            },
            showDetails(obj, key) {
                if (obj.chip.excludeClick) return

                if (obj.data.length === 0) {
                    this.addCodeDialog = {
                        show: true,
                        incident_behavior_type_id: obj.parent.id
                    }
                    return
                }

                this.selectedIncidentType = obj.parent
                this.$store.commit('manage/addCrumb', {
                    key: "Incidents",
                    val: [{
                        name: obj.parent.name,
                        data: obj.parent,
                        show: 'type'
                    }, {
                        name: 'Codes',
                        show: 'codes'
                    }]
                })

                // this.breadcrumbs.push()
            },
            getCountOf(item, status) {
                let count = 0

                this.statusFields.forEach((f) => {
                    if (item[f.model] === status) count++
                })

                return count
            },
            doSelect(item, evt) {
                this.selectedIncidentType = item
                this.$store.commit('manage/addCrumb', {
                    key: "Incidents",
                    val: {
                        name: item.name,
                        show: 'type'
                    }
                })
            },
            showAddDialog() {
                if (this.addDialog.show) this.addDialog.show = false
                this.$nextTick(() => {
                    this.addDialog.show = true
                    this.addDialog.name = ''
                })

            },
            saveRecord(item) {
                let cfg = this.crudConfig

                Object.assign(item, {
                    create_security_flag: false,
                    rank: null,
                    notify_flag: false,
                    complete_security_flag: false,
                    default_search: true,
                    restrict_description: false,
                    show_description: true,
                    show_response: true,
                    show_parent_contact: true,
                    show_motivation: true
                })

                this.$refs.crud.create([item])
                    .then((response) => {
                        this.addDialog.show = false

                        if (response.data.incident_behavior_types) {
                            let d = response.data.incident_behavior_types[0]
                            this.selectedIncidentType = d
                            this.$addCrumb('Incidents', {
                                name: d.name,
                                show: 'type'
                            })
                        }
                    })
            },
            deleteRecord(records) {
                let cfg = this.crudConfig
                let arr = []

                records.forEach(rec => {
                    arr.push({
                        id: rec.id
                    })
                })

                this.$confirmDelete(records, () => {
                    this.$refs.crud.destroy(arr)
                        .then(response => {
                            this.deleteDialog.show = false
                            this.$refs.crud.read()
                            this.$refs.list.edit(false)
                        })
                })


            },
            doClick(item) {
                // this.$router.push('/Manage/IncidentType/' + item.id)
                this.selectedItem = item
            },
            // loadData() {
            //     let cfg = this.crudConfig

            //     this.$axios.get(cfg.read)
            //     .then(response => {
            //         let data = []

            //         if (response && response.data) {
            //             if (cfg.rootProperty) {
            //                 this.$data.items = response.data[cfg.rootProperty]
            //             } else {
            //                 this.$data.items = response.data
            //             }

            //             this.$store.commit('set', {
            //                 module: 'manage',
            //                 state: 'incidentTypes',
            //                 value: this.$data.items
            //             })
            //         }
            //     })
            // }
        }
    }
</script>

<style lang="scss" scoped>
table thead th.word-wrap-example {
  word-wrap: break-word;
  white-space: normal;
}

// .incident-type-container {
//     border: 2px solid #E0E1E6;

//     .incident-type-row {
//         height: 64px;
//         width: 100%;
//         background: #FFFFFF;
//         border-bottom: 2px solid #E0E1E6;

//         .row-title {
//             font-style: normal;
//             font-weight: 500;
//             font-size: 16px;
//             color: #000000;
//             min-width: 220px;
//             padding-left: 20px;
//         }

//         &.last {
//             border-bottom: none;
//         }

//         .row-codes {
//             cursor: pointer;
//             display: inline-block;
//             background: #E0E1E6;
//             border-radius: 2px;
//             font-style: normal;
//             font-weight: normal;
//             font-size: 14px;
//             line-height: 18px;
//             padding: 6px 10px 6px 10px;
//             min-width: 95px;
//             text-align: center;
//             margin-left: 10px;
//             /* identical to box height */


//             color: #000000;
//         }
//     }
// }

</style>
