<template>
    <div>
        <div v-if="!valid" class="body-2 text-xs-center pa-4">
            <v-icon>info</v-icon>
            Intervention Decision Rationale requires a student and intervention to be attached to the smartFORM
        </div>

        <message v-else
            dense
            :style="{ height: height ? height+'px' : undefined, }"
            v-bind="$attrs"
            :allowPrivate="false"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Message from '@/components/common/Message'

    export default {
        name: 'InterventionDecisionRationale',
        
        components: { Message },
        
        props: ['fieldRec', 'height', 'editor'],
        computed: {
            valid() {
                return this.$attrs.params.intervention_id && this.$attrs.params.student_id
            }
        },
        data() {
            return {}
        }
    }
</script>

<style lang="scss" scoped>
</style>
