<template>
    <div class="flex-fill flex-column">
        <div class="d-flex">
            <div class="d-flex flex-grow-1">
                <fe-title-select
                    ref="titleSelect"
                    v-if="bands && bands.length > 0 && selectedBand"
                    placeholder="Select a Performance Band"
                    :items="bands"
                    v-model="selectedBand"
                ></fe-title-select>
                <fe-switch class="mt-2 ml-2" label="Default" v-model="band.site_default" @input="save"/>
                <fe-switch class="mt-2 ml-2" label="KPI" v-model="kpi" @input="save" />
                <fe-switch class="mt-2 ml-2" label="Active" v-model="band.active" @input="save" />
            </div>


            <div class="d-flex flex-shrink-1" style="text-align: right;">
                <fe-spinner
                    style=" display: inline-block; "
                    v-if="synchronizing"
                    text="synchronizing..."
                />
                <fe-btn
                    v-else-if="outOfSync"
                    color="success"
                    useIcon="refresh"
                    @click="confirmSync = true"
                >
                    Sync Updates
                </fe-btn>
                <v-menu
                    offset-y
                    close-on-content-click
                >
                    <template v-slot:activator="{ on }">
                        <fe-icon-btn v-on="on" use-icon="more_vert" />
                    </template>
                    <v-list>
                        <v-list-item @click="$refs.bandEditor.open()">
                            <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="copying = true">
                            <v-list-item-title>Copy</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="destroy">
                            <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="$isSupport()" @click="pushing = true">
                            <v-list-item-title>Copy to climberCLOUD</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <div class="d-flex">
            <div class="d-flex flex-grow-1">
                <div v-if="expanded" class="d-flex flex-shrink-1 flex-grow-1">
                    <template v-for="range in ranges">
                        <div class="flex-grow-1 range-area" v-if="range.descriptors.length > 0" :key="`range-${range.id}`">
                            <div class='range-type'>{{ range.display }}</div>
                            <div class='range-items'>
                                <div class='range-item' v-for="descriptor in range.descriptors" :key="`range-${range.id}-descriptor-${descriptor.id}`">
                                    <div class='swatch' :style="{ backgroundColor: descriptor.color }" />
                                    {{ descriptor.name }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div v-else class="d-flex flex-shrink-1 flex-grow-1">
                    <div style="cursor: pointer; line-height: 45px;" @click="() => expanded = true">
                        Range Descriptors &amp; Colors
                    </div>
                </div>
            </div>
            <div class="flex-shrink-1">
                <fe-icon-btn
                    :useIcon="expanded ? 'fas fa-angle-down' : 'fas fa-angle-left'"
                    @click="() => expanded = !expanded"
                />
            </div>
        </div>

        <fe-overlay
            :value="loading"
            loader
            text=""
        />

        <fe-crud-grid
            ref="grid"
            identifier="data_point_target_id"
            :config="$models.targetSetTarget"
            :defaultParams="{ target_set_id: band.id }"
            :readParams="filterParams"
            :gridOptionOverrides="{
                suppressRowClickSelection: true,
                groupSelectsChildren: true,
                groupSelectsFiltered: true,
                isRowSelectable: v => v.data.selectable
            }"
            :columnDefs="columnDefs"
            :insertDefaults="{ target_set_id: band.id }"
            showToolbarMenu
            :showDownload="false"
            style="height: 100%;"
            refresh-on-config
            refresh-on-updated
            @cellValueChanged="cellUpdated"
            disable-inline-create
            addRowBtnText="Configuration"
            addRowBtnIcon="edit"
            :showAddRowBtn="!updatesRunning"
            :searchBar="false"
            @beginCreate="create"
            @readProcessor="addGroupNames"
            @updateProcessor="adjustAdjacentValues"
            @selectionChanged="selectionChanged"
            @updated="cancelCopy"
            @read="loadTargetSuccess"
            @error="loadTargetError"
            @rowSelected="attemptSelect"
            @rowDataUpdated="expandFirstGroup"
            :overlayNoRowsTemplate="noRowsTemplate"
            grouped
            revert-invalid-cells
            :cellParamsFormatters="cellParamsFormatters"
            @bodyScroll="mouseWheelEvent"
        >
            <template v-slot:after-search-toolbar-items>
                <fe-filter-btn
                    v-if="available.assessments"
                    title="Assessments"
                    :items="selected.assessments"
                    itemValue="id"
                    itemText="name"
                    v-model="filtered.assessments"
                    :multiple="true"
                    :closeOnSelect="false"
                    @input="applyFilters"
                    :disabled="!bandProps.assessments || bandProps.assessments.length == 0"
                ></fe-filter-btn>
                <fe-filter-btn
                    v-if="available.windows"
                    title="Windows"
                    :items="selected.windows"
                    itemValue="id"
                    itemText="name"
                    v-model="filtered.windows"
                    :multiple="true"
                    :closeOnSelect="false"
                    @input="applyFilters"
                    :disabled="!bandProps.windows || bandProps.windows.length == 0"
                ></fe-filter-btn>
                <fe-filter-btn
                    v-if="available.grades"
                    title="Grades"
                    :items="selected.grades"
                    itemValue="id"
                    itemText="name"
                    v-model="filtered.grades"
                    :multiple="true"
                    :closeOnSelect="false"
                    @input="applyFilters"
                    :disabled="!bandProps.grades || bandProps.grades.length == 0"
                ></fe-filter-btn>
            </template>
            <template v-slot:toolbar-items v-if="updatesRunning">
                <fe-spinner class="spin-offset" text="updating..." />
            </template>
            <template v-slot:selection-tools>
                <fe-btn
                    useIcon="fal fa-copy"
                    usage="ghost"
                    whiteText
                    @click="confirmCopy"
                    class="fe-grid-action-btn"
                >
                    Copy Values
                </fe-btn>
                <fe-btn
                    usage="ghost"
                    whiteText
                    @click="cancelCopy"
                    class="fe-grid-action-btn-last"
                >
                    Cancel
                </fe-btn>
            </template>
        </fe-crud-grid>

        <assessment-group-band-editor
            ref="bandEditor"
            :group="group"
            :band="band"
            :descriptors="descriptors"
            @update="update"
        />

        <assessment-group-band-properties
            ref="bandProperties"
            :group="group"
            :band="band"
            :cfg="cfg"
            :availableAssessments="available.assessments"
            :availableGrades="available.grades"
            :availableWindows="available.windows"
            :availableSchoolYears="available.schoolYears"
            :availableScoreDetailTypes="available.scoreDetailTypes"
            :bandAssessments="bandProps.assessments"
            :bandGrades="bandProps.grades"
            :bandWindows="bandProps.windows"
            :bandSchoolYears="bandProps.schoolYears"
            :bandTypes="bandTypes"
            @scoreDetailTypesLoaded="available.scoreDetailTypes = $event"
            @update="cachedUpdates = $event"
        />

        <fe-crud
            ref="crudBands"
            :config="$models.targetSet"
        />

        <fe-crud
            ref="crudBandDescriptors"
            :config="$models.targetSetDescriptor"
            @read="bandDescriptors = $event"
        />

        <fe-crud
            autoload
            ref="crudBandAssessments"
            :config="$models.targetSetAssessment"
            :defaultParams="{
                target_set_id: band.id
            }"
            @read="bandProps.assessments = $event"
            refresh-on-config
        />

        <fe-crud
            autoload
            ref="crudBandGrades"
            :config="$models.targetSetGrade"
            :defaultParams="{
                target_set_id: band.id
            }"
            @read="bandProps.grades = $event"
            refresh-on-config
        />

        <fe-crud
            autoload
            ref="crudBandWindows"
            :config="$models.targetSetWindow"
            :defaultParams="{
                target_set_id: band.id
            }"
            @read="bandProps.windows = $event"
            refresh-on-config
        />

        <fe-crud
            autoload
            ref="crudBandSchoolYears"
            :config="$models.targetSetSchoolYear"
            :defaultParams="{
                target_set_id: band.id
            }"
            @read="bandProps.schoolYears = $event"
            refresh-on-config
        />

        <fe-crud
            ref="crudSync"
            :config="$models.targetSet"
            :defaultParams="{
                target_set_id: band.id,
                action: 'refresh_mv'
            }"
            @read="afterSync"
        />

        <fe-crud
            ref="crudCopy"
            :config="{
                defaults: {
                    endpoint: 'targetSet.php',
                    params: { action: 'copy' },
                    rootProperty: null
                }
            }"
            @created="afterCopyBand"
        />

        <fe-crud
            autoload
            :config="$models.subcategory"
            :readParams="{ data_point_type_id: group.id }"
            @read="available.assessments = $event"
        />

        <fe-crud
            autoload
            :config="$models.grade"
            @read="available.grades = $event"
        />

        <fe-crud
            autoload
            :config="$models.schoolYear"
            @read="available.schoolYears = $event"
        />

        <fe-crud
            autoload
            :config="$models.dataPointName"
            :readParams="{ data_point_type_id: group.id, show_all: 1 }"
            @read="available.windows = $event"
        />

        <fe-crud
            ref="crudCloud"
            :config="$models.targetSet"
            :createParams="{
                action: 'push_to_cloud'
            }"
            @created="pushing = false"
        />

        <fe-dialog
            v-if="cachedUpdates"
            title="Save Changes?"
            body="Are you sure you want to update changes to selected Assessments, Windows and Grades?"
            acceptButtonText="Yes"
            @accept="performUpdates"
            @dismiss="cachedUpdates = null"
        />

        <fe-dialog
            v-if="copyUpdates.length > 0"
            title="Copy Values?"
            acceptButtonText="Yes"
            @accept="doCopy"
            @dismiss="copyUpdates = []"
        >
            <p>
                Are you sure you want to copy the values from the {{ selections.length }} selected record{{ selections.length > 1 ? 's' : '' }}
                into {{ copyUpdates.length - selections.length }} other record{{ copyUpdates.length - selections.length > 1 ? 's' : '' }}?
            </p>

            <div class="d-flex">
                <fe-switch v-model="isCopyingAliases" label="Copy aliases"/>
                <fe-info-tip class="ml-2" :tooltip="`Select this option if you want to copy the alias into each of the other ${copyUpdates.length - selections.length} selected record${copyUpdates.length - selections.length > 1 ? 's' : ''}.`"/>
            </div>
        </fe-dialog>

        <fe-dialog
            v-if="confirmSync"
            title="Synchronize Values?"
            acceptButtonText="Yes"
            @accept="doSync"
            @read="afterSync"
            @dismiss="confirmSync = false"
        >
            Are you sure you want to synchronize all updated data within this performance band?
        </fe-dialog>

        <fe-dialog
            v-if="pushing"
            @close="pushing = false"
            @dismiss="pushing = false"
            @accept="push"
            title="Copy to climberCLOUD"
            acceptButtonText="Send"
            :acceptButtonDisabled="!pusherValid"
        >
            <v-form @submit.prevent v-model="pusherValid">
                <div class="d-flex flex-column">

                    <fe-label>Name</fe-label>
                    <v-text-field
                        v-model="pusher.target_set_name"
                        flat solo dense
                        :style="{ width: '100%' }"
                        :rules="pusherRules.name"
                    />

                    <fe-label>Description</fe-label>
                    <v-text-field
                        v-model="pusher.target_set_description"
                        flat solo dense
                        :style="{ width: '100%' }"
                    />

                </div>
            </v-form>
        </fe-dialog>

        <fe-dialog
            v-if="copying"
            @close="copying = false"
            @dismiss="copying = false"
            @accept="copyBand"
            title="Copy Performance Band"
            acceptButtonText="Copy"
            :acceptButtonDisabled="!copyValid"
        >
            <v-form @submit.prevent v-model="copyValid">
                <div class="d-flex flex-column">
                    <fe-label>Enter Name of Copy</fe-label>
                    <v-text-field
                        v-model="copyObj.target_set_name"
                        flat solo dense
                        :style="{ width: '100%' }"
                        :rules="copyRules.name"
                    />
                </div>
            </v-form>
        </fe-dialog>
    </div>
</template>

<script>
    import AssessmentGroupBandEditor from './AssessmentGroupBandEditor'
    import AssessmentGroupBandProperties from './AssessmentGroupBandProperties'

    const commonCellStyleFunction = (v) => {
        return {
            color: v.data.data_point_target_hidden ? '#999' : 'inherit',
            // Disable pointer events on disabled descriptors, primarily to disable the misleading "Double click to edit" tooltip
            pointerEvents: v.data.data_point_target_hidden ? 'none' : 'auto',
        }
    }

    export default {
        name: 'AssessmentGroupBandDetail',
        props: {
            group: { type: Object, default: () => {}, required: true },
            band: { type: Object, default: () => {}, required: true },
            bands: { type: Array, default: () => [], required: true },
            bandDescriptors: { type: Array, default: () => [], required: true },
            bandTypes: { type: Array, default: () => [], required: true },
            bandRangeTypes: { type: Array, default: () => [], required: true },
            // This is passed in as a prop (rather than being checked in
            // computed props in any way) to avoid re-triggering columnDefs,
            // which in turn collapses all rows (re-render occurs), and
            // expandFirstGroup only executes one time, thus failing to
            // re-expand after data refresh
            isScoreDetailBand: { type: Boolean, default: false },
        },
        components: {
            AssessmentGroupBandEditor,
            AssessmentGroupBandProperties
        },
        mounted() {
            this.$refs.grid.$refs.grid.gridOptions.deltaRowDataMode = true
            this.$refs.grid.$refs.grid.gridOptions.getRowNodeId = v => {
                return v.data_point_target_id + '-' + v.start_score_display_value + '-' + v.end_score_display_value
            }
        },
        data () {
            return {
                isShiftKeyPressed: false,
                cellFocusTimer: {
                    timerId: null,
                    editedCell: null,
                    authorizedWatcher: false,
                },
                cellMovement: 0,
                cellParamsFormatters: [
                    (p) => {
                        // attempt alpha match only if it's non-numeric
                        if (!/^[+-]?(?=[^.])[0-9]*[.]?[0-9]*$/.test(p.newValue)) {
                            let match = this.alphaMap?.maps.find(m => `${m.alpha_score}`.toLowerCase() == `${p.newValue}`.toLowerCase())
                            if (match) {
                                p.newValue = match.numeric_score
                                p.data[p.colDef.field] = match.numeric_score
                            }
                        }
                        return p
                    }
                ],
                cellRules: [
                    (value) => {
                        return !isNaN(value) || 'Invalid entry.'
                    },
                ],
                firstRender: true,
                cfg: {
                    assessments: {
                        comparison: { available: 'id', band: 'sub_category_id' }
                    },
                    grades: {
                        comparison: { available: 'id', band: 'grade_id' }
                    },
                    windows: {
                        comparison: { available: 'id', band: 'data_point_name_id' }
                    },
                    schoolYears: {
                        comparison: { available: 'id', band: 'school_year_id' }
                    }
                },
                copying: false,
                isCopyingAliases: false,
                copyValid: true,
                copyObj: {
                    target_set_name: null,
                },
                copyRules: {
                    name: [
                        v => (!!v && v.trim().length > 0) || 'Name is required'
                    ]
                },
                pushing: false,
                pusherValid: true,
                pusher: {
                    target_set_name: null,
                    target_set_description: null
                },
                pusherRules: {
                    name: [
                        v => (!!v && v.trim().length > 0) || 'Name is required'
                    ]
                },
                expanded: false,
                copyUpdates: [],
                confirmSync: false,
                synchronizing: false,
                updatesRunning: false,
                rangeTypes: [
                    { name: "BENCHMARK", display: "Benchmark Range" },
                    { name: "DETAILED+DATA WALL", display: "Performance Range" },
                    { name: "LINEGRAPH", display: "Line Range" },
                    { name: "DETAILED+LINEGRAPH", display: "Detailed Line Range" }
                ],
                bandProps: {
                    assessments: null,
                    grades: null,
                    windows: null,
                    schoolYears: null
                },
                available: {
                    assessments: null,
                    grades: null,
                    windows: null,
                    schoolYears: null,
                    scoreDetailTypes: null
                },
                cachedUpdates: null,
                selections: [],
                unselectable: [],
                filtersNeeded: false,
                filterParams: {},
                filtered: {
                    assessments: {
                        included: [],
                        excluded: []
                    },
                    grades: {
                        included: [],
                        excluded: []
                    },
                    windows: {
                        included: [],
                        excluded: []
                    },
                    schoolYears: {
                        included: [],
                        excluded: []
                    }
                },
                loading: false,
            }
        },
        computed: {
            formattedMaps () { return this.alphaMap?.maps || [] },
            outOfSync () { return !this.band.in_sync },
            selectedBand: {
                get () { return this.band },
                set (v) { this.$emit('switchBand', v) }
            },
            ranges () {
                return this.rangeTypes.map(type => {
                    let obj = Object.assign({}, this.rangeTypeByName(type.name))
                    obj.display = type.display
                    obj.descriptors = this.descriptorsByRangeTypeId(obj.id)
                    return obj
                })
            },
            descriptors () {
                return this.bandDescriptors.filter(desc => desc.target_set_id == this.band.id)
            },
            columnDefs () {
                return [
                    {
                        colId: 'checkbox_column',
                        headerName: null,
                        headerCheckboxSelection: false,
                        checkboxSelection: true,
                        width: 70,
                        minWidth: 70
                    },
                    {
                        colId: 'name',
                        headerName: "Type",
                        field: "name",
                        sortable: true,
                        editable: false,
                        minWidth: 200,
                        cellRenderer: (v) => { return this.findLabel(this.ranges, v.data.target_type_id, 'id', 'display') },
                        cellStyle: commonCellStyleFunction,
                    },
                    {
                        colId: 'color',
                        headerName: "Color",
                        field: "color",
                        sortable: true,
                        editable: false,
                        cellRenderer: (v) => { return this.swatchCell(v.data.target_descriptor_color) },
                    },
                    {
                        colId: 'target_descriptor_name',
                        headerName: "Label",
                        field: "target_descriptor_name",
                        sortable: true,
                        editable: false,
                        cellStyle: commonCellStyleFunction,
                    },
                    {
                        colId: 'data_point_target_alias',
                        headerName: "Alias",
                        field: "data_point_target_alias",
                        sortable: true,
                        editable: v => !v.data.data_point_target_hidden,
                        // Although cell is editable, we prefer not to enable tab cycling on it
                        suppressNavigable: true,
                        cellRenderer: (v) => {
                            if (!v.data.data_point_target_alias) {
                                return ''
                            } else {
                                return v.data.data_point_target_alias
                            }
                        },
                        cellStyle: commonCellStyleFunction,
                        hide: true, // advanced/power user option - hide by default
                    },
                    {
                        colId: 'from',
                        headerName: "From",
                        headerClass: 'header-hide-text',
                        field: "from",
                        sortable: false,
                        editable: false,
                        cellRenderer: (v) => { return 'From' },
                        cellStyle: commonCellStyleFunction,
                    },
                    {
                        colId: 'data_point_target_start_score',
                        headerName: "Starting Value (>=)",
                        field: "data_point_target_start_score",
                        sortable: true,
                        editable: v => !v.data.data_point_target_hidden,
                        // Alpha maps dropdown cell editor should only be used for assessment primary scores;
                        // a generic numeric entry field should be shown for score details
                        cellEditorFramework: !this.isScoreDetailBand ? 'FeGridChipSelect' : undefined,
                        cellEditorParams: {
                            rowDataKey: 'data_point_target_start_score',
                            mode: 'edit',
                            items: this.formattedMaps,
                            keyProp: 'numeric_score',
                            labelProp: 'alpha_score',
                            disableChips: true,
                            insertable: true,
                            elProps: {
                                rules: this.cellRules,
                            },
                            elEvents: { 'keydown': this.keyEvent },
                        },
                        cellRenderer: (v) => {
                            if (v.data.data_point_target_hidden) {
                                return '-'
                            } else if (!v.data.score_detail_type_id) {
                                return v.data.start_score_display_value
                            } else {
                                return v.data.data_point_target_start_score
                            }
                        },
                        cellStyle: commonCellStyleFunction,
                    },
                    {
                        colId: 'data_point_target_end_score',
                        headerName: "End Value (<)",
                        field: "data_point_target_end_score",
                        sortable: true,
                        editable: v => !v.data.data_point_target_hidden,
                        // Alpha maps dropdown cell editor should only be used for assessment primary scores;
                        // a generic numeric entry field should be shown for score details
                        cellEditorFramework: !this.isScoreDetailBand ? 'FeGridChipSelect' : undefined,
                        cellEditorParams: {
                            rowDataKey: 'data_point_target_end_score',
                            mode: 'edit',
                            items: this.formattedMaps,
                            keyProp: 'numeric_score',
                            labelProp: 'alpha_score',
                            disableChips: true,
                            insertable: true,
                            elProps: {
                                rules: this.cellRules,
                            },
                            elEvents: { 'keydown': this.keyEvent },
                        },
                        cellRenderer: (v) => {
                            if (v.data.data_point_target_hidden) {
                                return '-'
                            } else if (!v.data.score_detail_type_id) {
                                return v.data.end_score_display_value
                            } else {
                                return v.data.data_point_target_end_score
                            }
                        },
                        cellStyle: commonCellStyleFunction,
                    },
                    {
                        colId: 'groupName',
                        headerName: 'Group',
                        field: 'groupName',
                        rowGroup: true,
                        sortable: false,
                        editable: false,
                        hide: true
                    },
                    {
                        colId: "data_point_target_hidden",
                        headerName: 'Hidden',
                        field: 'data_point_target_hidden',
                        sortable: true,
                        editable: false,
                        cellRendererFramework: 'FeGridToggle',
                        hide: true, // advanced/power user option - hide by default
                    },
                ]
            },
            crudParams () { return { target_set_id: this.band.id, confirm: true } },
            noRowsTemplate () {
                return (this.filtersNeeded)
                    ? 'Too many records to display.  Please filter the results above.'
                    : null
            },
            selected () {
                let obj = {}
                Object.keys(this.filtered).forEach(type => {
                    if (this.available[type] && this.bandProps[type]) {
                        obj[type] = this.available[type].filter(i => {
                            return this.bandProps[type].find(itm => {
                                return i[this.cfg[type].comparison.available] === itm[this.cfg[type].comparison.band]
                            })
                        })
                    } else {
                        obj[type] = []
                    }
                })
                return obj
            },
            kpi: {
                get () { return !!this.band.kpi },
                set (v) {
                    this.band.kpi = v ? 1 : 0
                    this.save()
                }
            },
            alphaMap () {
                return (this.$store.state.global.alphaMaps)
                    ? this.$store.state.global.alphaMaps.find(itm => itm.data_point_type_id == this.group.id)
                    : null
            }
        },
        methods: {
            save () {
                this.$refs.crudBands.update([this.band])
            },
            expandFirstGroup (params) {
                if (this.firstRender) {
                    let expanded = false
                    params.api.forEachNode((node) => {
                        if (!expanded) {
                            node.setExpanded(true)
                            expanded = true
                        }
                    })
                    this.firstRender = false
                }
            },
            doSync () {
                this.synchronizing = true
                this.confirmSync = false
                this.$refs.crudSync.read()
            },
            afterSync () {
                this.synchronizing = false
                this.$emit('fetchBand')
            },
            confirmCopy () {
                let updates = []
                this.$refs.grid.$refs.grid.gridApi.forEachNodeAfterFilter(node => {
                    if (!node.group) {
                        let sel = this.selections.find(itm => itm.target_descriptor_id == node.data.target_descriptor_id)
                        if (sel) {
                            let diffs = {
                                data_point_target_start_score: sel.data_point_target_start_score,
                                data_point_target_end_score: sel.data_point_target_end_score,
                                data_point_target_hidden: sel.data_point_target_hidden,
                                data_point_target_alias: sel.data_point_target_alias,
                            }

                            updates.push({ ...node.data, ...diffs })
                        }
                    }
                })
                this.copyUpdates = updates
            },
            doCopy () {
                // Because copyUpdates is populated before the confirmation dialog which
                // itself includes a toggle for "copy aliases?", we have to back-delete
                // the alias field if the fe-switch is off
                if (!this.isCopyingAliases) {
                    for (let diff of this.copyUpdates) {
                        delete diff.data_point_target_alias
                    }
                }

                this.$refs.grid.$refs.crud.update(this.copyUpdates).then(rsp => {
                    this.copyUpdates = []
                })
            },
            cancelCopy () {
                this.$refs.grid.$refs.grid.editCancel()
            },
            selectionChanged (params) {
                this.selections = params.api.getSelectedRows()
            },
            attemptSelect (params) {
                if (!this.isSelectable(params.data))  params.node.setSelected(false)
            },
            isSelectable (rec) {
                return !this.selections.find(sel => {
                    return (rec.data_point_target_id !== sel.data_point_target_id &&
                        rec.target_descriptor_id === sel.target_descriptor_id)
                })
            },
            addGroupNames (records, resolve, reject) {
                let processed = records.map((rec, idx) => Object.assign(rec, {
                    groupName: this.constructGroupName(rec),
                    selectable: this.isSelectable(rec)
                }))
                resolve(processed)
            },
            constructGroupName (rec) {
                let parts = []
                parts.push(rec.sub_category_id ? rec.sub_category_name : 'All Assessments')
                parts.push(rec.grade_id ? rec.grade_name : 'All Grades')
                parts.push(rec.data_point_name_id ? rec.data_point_name_name : 'All Windows')
                return `${parts.join(', ')} [${this.band.target_set_type_id === 2 && rec.score_detail_type_name ? rec.score_detail_type_name : 'Primary Score'}]`
            },
            adjustAdjacentValues (records, resolve, reject) {
                if (!Array.isArray(records)) records = [records]

                if (records.length == 1) {
                    let processed = []
                    records.forEach(rec => {
                        processed.push(rec)

                        // When we look for the previous value to adjust, we should choose to
                        // skip over any descriptor that is marked as hidden
                        let hiddenDataPointTargets = this.$refs.grid.records
                            .filter(itm => itm.target_type_id === rec.target_type_id)
                            .filter(itm => {
                                return (itm.sub_category_id == rec.sub_category_id && itm.grade_id == rec.grade_id && itm.data_point_name_id == rec.data_point_name_id)
                            })
                            .filter(itm => !!itm.data_point_target_hidden)

                        let previousDescriptor = this.descriptorsByRangeTypeId(rec.target_type_id)
                            .filter(itm => itm.rank < rec.target_descriptor_rank)
                            .filter(itm => !hiddenDataPointTargets.find(dpt => dpt.target_descriptor_id === itm.id))
                            .sort((a,b) => b.rank - a.rank)
                            .find(x => true)
                        if (previousDescriptor) {
                            let previousRecord = this.$refs.grid.records.find(itm => {
                                return (itm.target_descriptor_id == previousDescriptor.id &&
                                    itm.sub_category_id == rec.sub_category_id &&
                                    itm.grade_id == rec.grade_id &&
                                    itm.data_point_name_id == rec.data_point_name_id)
                            })
                            if (previousRecord) {
                                processed.push(Object.assign(previousRecord, {
                                    data_point_target_end_score: rec.data_point_target_start_score
                                }))
                            }
                        }

                        let nextDescriptor = this.descriptorsByRangeTypeId(rec.target_type_id)
                            .filter(itm => itm.rank > rec.target_descriptor_rank)
                            .filter(itm => !hiddenDataPointTargets.find(dpt => dpt.target_descriptor_id === itm.id))
                            .sort((a,b) => a.rank - b.rank)
                            .find(x => true)
                        if (nextDescriptor) {
                            let nextRecord = this.$refs.grid.records.find(itm => {
                                return (itm.target_descriptor_id == nextDescriptor.id &&
                                    itm.sub_category_id == rec.sub_category_id &&
                                    itm.grade_id == rec.grade_id &&
                                    itm.data_point_name_id == rec.data_point_name_id)
                            })
                            if (nextRecord) {
                                processed.push(Object.assign(nextRecord, {
                                    data_point_target_start_score: rec.data_point_target_end_score
                                }))
                            }
                        }
                    })
                    resolve(processed)
                } else {
                    resolve(records)
                }
            },
            rangeTypeByName (name) {
                return this.bandRangeTypes.find(type => type.name == name) || {}
            },
            descriptorsByRangeTypeId (id) {
                return this.descriptors.filter(desc => desc.target_type_id == id)
            },
            update (params) {
                let payload = Object.assign({}, this.band, params.obj)
                this.loading = true
                this.$refs.crudBands.update(payload).then(() => {
                    this.$emit('fetchBand')
                    this.$emit('reloadCurrent')
                    this.selectedBand = payload
                    let descs = {
                        destroy: this.descriptors.filter(desc => !params.descriptors.find(desc2 => desc2.id == desc.id)),
                        update: params.descriptors.filter(desc => desc.id).map(desc => Object.assign(desc, {created: undefined})),
                        create: params.descriptors.filter(desc => !desc.id).map(desc => Object.assign(desc, {target_set_id: this.band.id}))
                    }
                    new Promise(resolve => { resolve(true) })
                        .then(() => {
                            if (descs.destroy.length) {
                                return this.$refs.crudBandDescriptors.destroy(descs.destroy)
                            } else {
                                return true
                            }
                        })
                        .then(() => {
                            if (descs.update.length) {
                                return this.$refs.crudBandDescriptors.update(descs.update)
                            } else {
                                return true
                            }
                        })
                        .then(() => {
                            if (descs.create.length) {
                                return this.$refs.crudBandDescriptors.create(descs.create)
                            } else {
                                return true
                            }
                        })
                        .finally(() => {
                            this.$emit('fetchBandDescriptors')
                            this.$refs.grid.$refs.crud.read()

                            // Reload assessments and windows data in case focus has changed
                            // to/from a score detail
                            this.$refs.crudBandAssessments.read()
                            this.$refs.crudBandWindows.read()
                        })
                })
                .finally(() => {
                    this.loading = false
                })
            },
            create () {
                this.$refs.bandProperties.open()
            },
            findLabel (collection, value, valueProp, labelProp) {
                let lbl = ''
                if (collection && value) {
                    let sel = collection.find(x => x[valueProp] == value)
                    if (sel) lbl = sel[labelProp]
                }
                return lbl
            },
            swatchCell (color) {
                return `<span style=" display: inline-block; vertical-align: middle; height: 24px; width: 24px; margin: 0 auto; border-radius: 2px; background-color: ${color};">&nbsp;</span>`
            },
            async performUpdates () {
                let me = this
                this.updatesRunning = true
                let types = []
                let crudRefs = {
                    assessments: 'crudBandAssessments',
                    grades: 'crudBandGrades',
                    windows: 'crudBandWindows',
                    schoolYears: 'crudBandSchoolYears'
                }
                let updates = this.cachedUpdates

                for (let u = 0; u < updates.length; u++) {
                    await me.$refs[crudRefs[updates[u].type]][updates[u].operation](updates[u].payload, me.crudParams)
                    .then(() => {
                        types.push(updates[u].type)
                    })
                }

                types.forEach(type => {
                    this.$refs[crudRefs[type]].read()
                })

                this.$refs.grid.$refs.crud.read()
                this.cachedUpdates = null
                this.updatesRunning = false
                this.$emit('fetchBand')
            },
            reset (editNextCell) {
                if (this.$refs.titleSelect) this.$refs.titleSelect.$forceUpdate()
                this.pusher.target_set_name = this.selectedBand.name
                this.pusher.target_set_description = null
                if (this.cellFocusTimer.authorizedWatcher) {
                    // This flag must be explicitly re-set to true each time as needed
                    this.cellFocusTimer.authorizedWatcher = false

                    if (this.isShiftKeyPressed) {
                        this.editPreviousCell()
                    } else {
                        this.editNextCell()
                    }
                }

                this.$refs.grid.$refs.grid.gridOptions.api.refreshCells({ force: true, suppressFlash: true })
            },
            loadTargetSuccess (recs) {
                this.filtersNeeded = false
                this.$emit('fetchBand')
            },
            loadTargetError (err) {
                this.filtersNeeded = true
                console.warn({ err: err })
            },
            applyFilters () {
                this.$refs.grid.$refs.grid.gridOptions.api.clearFocusedCell()
                let cfg = {
                    assessments: 'sub_category_id',
                    grades: 'grade_id',
                    windows: 'data_point_name_id',
                    schoolYears: 'school_year_id'
                }
                let obj = { }
                Object.keys(cfg).forEach(type => {
                    if (this.filtered[type].included.length > 0) {
                        obj[cfg[type]] = this.filtered[type].included
                            .map(x => x.id)
                            .join(',')
                    }
                })
                this.filterParams = obj
            },
            push () {
                let obj = Object.assign({}, this.pusher, { target_set_id: this.selectedBand.id })
                this.$refs.crudCloud.create({}, obj)
            },
            copyBand () {
                let obj = Object.assign({}, this.copyObj, { target_set_id: this.selectedBand.id })
                this.$refs.crudCopy.create(obj)
            },
            afterCopyBand () {
                this.$emit('reloadCurrent')
                this.copying = false
                this.$emit('fetchBand', { goToBand: this.copyObj.target_set_name})
            },
            destroy () {
                this.$confirmDelete(this.band, () => {
                    this.$refs.crudBands.destroy(this.band).then(response => {
                        this.$emit('reloadCurrent')
                        this.$emit('closeBand')
                    }).catch(err => {
                        console.warn({ err: err })
                    })
                })
            },
            cellUpdated (params) {
                // ag grid throws a cell value change even if it doesn't
                // change (eye roll?); but if we catch this, then we should
                // null out any "don't perform editNextCell after data reset"
                // which resolves a bug where [shift+tab, (change value), tab (to
                // perform save)] was resulting in a loss of cursor focus
                if (params.newValue === params.oldValue || (params.newValue === '' && params.oldValue === null)) {
                    // do nothing!
                } else {
                    // If a cell emitted a change event because user hit tab key to
                    // go to another cell, authorize the watcher for `bands` to
                    // move to the "next" cell (forward/backward) when data is reloaded;
                    // cancel the tab key timer immediately (watcher will do this work now)
                    if (this.cellFocusTimer.timerId) {
                        clearTimeout(this.cellFocusTimer.timerId)
                        this.cellFocusTimer.timerId = null
                        this.cellFocusTimer.authorizedWatcher = true
                    }
                }
            },

            editPreviousCell () {
                let gridApi = this.$refs.grid.$refs.grid.gridOptions.api
                let cell = this.cellFocusTimer.editedCell

                if (!cell) {
                    return
                }

                let prevRowIndex = cell.rowIndex - (cell.column?.colId == 'data_point_target_start_score' ? 1 : 0) // wrap to previous row
                let prevColId = (prevRowIndex < cell.rowIndex ? 'data_point_target_end_score' : 'data_point_target_start_score') // only 2 columns to pick from

                if (prevRowIndex < 0) {
                    return
                }

                // Refuse to edit any row that is set to "hidden"; try to proceed to the prev not-hidden row
                let prevRow = gridApi.getDisplayedRowAtIndex(prevRowIndex)
                while (prevRow?.data?.data_point_target_hidden) {
                    prevRowIndex -= 1
                    prevRow = gridApi.getDisplayedRowAtIndex(prevRowIndex)
                }

                if (!prevRow) {
                    return
                }

                this.$nextTick(() => {
                    gridApi.startEditingCell({
                        rowIndex: prevRowIndex,
                        colKey: prevColId
                    })

                    gridApi.setFocusedCell(prevRowIndex, prevColId)
                })
            },

            editNextCell () {
                let gridApi = this.$refs.grid.$refs.grid.gridOptions.api
                let cell = this.cellFocusTimer.editedCell

                if (!cell) {
                    return
                }

                let nextRowIndex = cell.rowIndex + (cell.column?.colId == 'data_point_target_end_score' ? 1 : 0) // wrap to next row
                let nextColId = (nextRowIndex > cell.rowIndex ? 'data_point_target_start_score' : 'data_point_target_end_score') // only 2 columns to pick from

                // Refuse to edit any row that is set to "hidden"; try to proceed to the next not-hidden row
                let nextRow = gridApi.getDisplayedRowAtIndex(nextRowIndex)
                while (nextRow?.data?.data_point_target_hidden) {
                    nextRowIndex += 1
                    nextRow = gridApi.getDisplayedRowAtIndex(nextRowIndex)
                }

                if (!nextRow) {
                    return
                }

                this.$nextTick(() => {
                    gridApi.setFocusedCell(nextRowIndex, nextColId)

                    gridApi.startEditingCell({
                        rowIndex: nextRowIndex,
                        colKey: nextColId
                    })
                })
            },
            keyEvent (e) {
                if (e.key == 'Tab' && this.cellFocusTimer.timerId) {
                    // Refuse to react to rapid tab presses
                    return
                } else if (e.key == 'Enter' && this.cellFocusTimer.timerId) {
                    return
                }

                if (e.type == 'keydown' && e.key == 'Tab') {
                    this.isShiftKeyPressed = e.shiftKey

                    // The event may already have these actions applied, but let's make quite certain
                    // We want to have full control over tab behavior - don't let ag grid have any control
                    e.preventDefault()
                    e.stopPropagation()

                    let editedCells = this.$refs.grid.$refs.grid.gridOptions.api.getEditingCells()
                    let editedCell = editedCells?.length ? editedCells[0] : null

                    // Immediately stop editing the cell, at least for a moment
                    this.$refs.grid.$refs.grid.gridOptions.api.stopEditing()

                    // Set a brief (essentially unnoticeable) timeout - during this
                    // time, we wait to see if a cellvaluechanged event will emit.
                    // If it does, we don't want to tab to the prev/next cell yet - instead,
                    // we'll prefer to wait for new score data to come back and ultimately
                    // call the reset() method which will resume editing on the appropriate
                    // cell (which may have an entirely different value at that point)
                    this.cellFocusTimer = {
                        ...this.cellFocusTimer,
                        timerId: setTimeout(() => {
                            if (e.shiftKey) {
                                this.editPreviousCell()
                            } else {
                                this.editNextCell()
                            }

                            this.cellFocusTimer.timerId = null
                        }, 100),
                        editedCell: editedCell,
                        authorizedWatcher: false,
                    }
                } else if (e.type == 'keyup' && e.key == 'Shift') {
                    this.isShiftKeyPressed = false
                } else if (e.type == 'keydown' && e.key == 'Enter') {
                    // User pressing enter to submit a grade should always
                    // move forward to next cell
                    this.isShiftKeyPressed = false
                }
            },
            mouseWheelEvent () {
                // If user scrolls the (7/20) grid itself and not within the fegridchipselects
                // stop editing to resolve an issue where the popup remains
                // in a fixed screen position even while the grid is scrolled through
                this.$refs.grid.$refs.grid.gridOptions.api.stopEditing()
            },
        },
        watch: {
            band (nv, ov) {
                if (nv.id !== ov.id) this.firstRender = true
                this.reset()
            },
            bands () {
                this.reset()
            },
            copying () { this.copyObj.target_set_name = `${this.band.name} copy` }
        }
    }
</script>

<style lang="scss" scoped>
    .swatch {
        height: 24px;
        width: 24px;
        border-radius: 2px;
        background-color: #fff;
    }

    .spin-offset {
        position: relative;
        top: -10px;
    }

    ::v-deep .header-hide-text .ag-header-cell-text {
        opacity: 0 !important;
    }

    .range-area {
        max-width: 300px;
        padding: 8px 16px;
        margin: 8px 8px 8px 0;
        overflow-y: auto;
        height: 100%;
        &:last-child { margin-right: 0; }
        .range-type {
            font-family: 'CerebriSans-Regular','Cerebri Sans','Roboto', sans-serif;
            font-size: 18px;
            color: #000;
            margin: 0 0 8px 0;
        }
        .range-item {
            width: 100%;
            clear: both;
            padding: 4px 55px 4px 55px;
            position: relative;
            min-height: 32px;
            font-size: 14px;
            color: #050F2D;
            .swatch {
                position: absolute;
                top: 0px;
                left: 10px;
            }
        }
    }
</style>
