<template lang="html">
    <div class="flex-column flex-fill">
        <fe-btn @click="overlay = !overlay" class="ml-auto" >overlay</fe-btn>
        <div style="height:100%;">
            some stuff
            <fe-overlay
                :value="overlay"
                loader
                text="some stuff is loading"
            />
        </div>
    </div>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'

export default {
    name: 'BasicLayout',
    inject: ['localStore'],
    computed: {
        ...mapLocalState('someText'),
    },
    data() {
        return {
            overlay: false
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
