<template>
    <span>
        <fe-crud
            ref="crud"
            :autoload="false"
            :config="crudModel"
            @read="v => crudItems = v"
        />

        <fe-crud
            ref="listCrud"
            :autoload="false"
            :config="itemsModel"
            @read="v => items = v"
        />

        <v-autocomplete
            :placeholder="emptyText"
            :items="items"
            flat solo dense
            v-model="localValue"
            @input="updateItems"
            v-bind="elProps"
            :itemText="itemText"
            :itemValue="itemValue"
            return-object
            hide-details
            :style="selectStyle"
        >
            <template v-slot:selection="{ sel, index }">
                <span  class="caption mr-1" v-if="index === 0">{{ totalSelections }} Selection{{totalSelections>1?'s':''}}</span>
            </template>
        </v-autocomplete>
    </span>
</template>

<script>
    export default {
        name: 'CrudSelect',
        props: {
            itemsModel: {
            },
            itemsModelParams: {
                default: () => {}
            },
            itemValue: {
                type: String,
                default: 'id'
            },
            itemText: {
                type: String,
                default: 'name'
            },
            selectStyle: {
                type: String,
                default: ''
            },
            crudModel: {
                required: true
            },

            crudModelReadParams: {
                default: null
            },
            crudModelCreateParams: {
                default: () => {}
            },
            crudItemValue: {
                type: String,
                default: 'id'
            },
            lookupItemName: {
                type: String,
                default: 'name'
            },
            value: {
            },
            listItems: {
                default: () => []
            }
        },
        computed: {
            elProps() {
                return { ...this.$attrs }
            },
            emptyText() {
                 let cnt = this.crudItems.length

                return cnt === 0 ? 'No Selection' : cnt + ' Selection'+(cnt>1?'s':'')
            },
            totalSelections() {
                if (!this.localValue) {
                    return 0
                } else if (Array.isArray(this.localValue)) {
                    return this.localValue.length
                } else if (this.localValue.hasOwnProperty) {
                    return Object.keys(this.localValue).length
                }

                return '??'
            }
        },
        data() {
            return {
                localValue: null,
                crudItems: [],
                items: []
            }
        },
        mounted() {
            this.loadCrudModel()
        },
        methods: {
            loadCrudModel() {
                // Load the list model
                this.$refs.listCrud.read(this.itemsModelParams)
                    .then(() => {
                        // Load the link table items
                        this.$refs.crud.read(this.crudModelReadParams)
                            .then((response) => {
                                this.setSelected()
                            })
                    })

            },
            setSelected() {
                let ids = []

                this.items.forEach((sch) => {
                    this.crudItems.forEach((sel) => {
                        if (sel[this.crudItemValue] === sch[this.itemValue]) {
                            ids.push(sch)
                        }
                    })
                })

                this.$data.localValue = ids
            },
            updateItems() {
                let selections = this.$data.localValue
                let existing   = this.$data.crudItems

                let diff = this.$differenceByKey(existing, selections, this.crudItemValue, this.itemValue)

                if (diff.length === 0) {
                    return
                }

                if (existing.length < selections.length) {
                    // Adding
                    // adding records
                    let arr = []
                    diff.forEach(a => {
                        let o = Object.assign({}, this.crudModelCreateParams)
                        o[this.crudItemValue] = a.id
                        arr.push(o)
                    })


                    this.$setLoading(true)
                    this.$refs.crud.create(arr)
                        .finally(() => {
                            this.$setLoading(false)
                            this.loadCrudModel()
                            // this.loadData()
                        })
                } else {
                    // removing records
                    this.$setLoading(true)
                    this.$refs.crud.destroy(diff)
                        .finally(() => {
                            this.$setLoading(false)
                            this.loadCrudModel()
                            // this.loadData()
                        })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
