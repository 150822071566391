import BaseService from '@/components/modules/thresholds/services/BaseService'
import BaseStore from '../Base'

export default Object.assign({}, BaseStore.default(), {
    namespaced: true,

    getters: Object.assign({}, BaseStore.default().getters, {
        __config(state, getters, rootState, rootGetters) {
            let prop = 'performance_band'
            return {
                service: BaseService,
                opts: {type: 'thresholdGroupProps'},
                params: {property: prop, threshold_group_id: rootGetters['thresholds/group/selectedId']},
                prop: prop
            }
        },

        __autosave: () => false
    })
})
