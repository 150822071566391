export default {
    dataPointScoreEditor: {
        defaults: {
            endpoint: 'dataPointScores.php',
            rootProperty: 'score_editor',
            params: { property: 'score_editor' }
        },
        read: {
            params: { action: 'get' }
        },
        create: {
            params: { action: 'create' },
        },
        update: {
            params: { action: 'update' },
        },
        destroy: {
            params: { action: 'delete' },
        }
    }
}