<template>
    <record-dialog
        title="User"
        width="350px"
        v-bind="$attrs"
        :defaultRec="{
            fname: null,
            lname: null,
            title: 'Educator',
            email_addr: null,
            login: null,
            active: 1,
            approve_slo: 0,
            force_top_secret: 0,
        }"
        @create="$emit('create',$event)" @update="$emit('update',$event)"
        @close="$emit('close')"
    >
        <template #form="{rec}">
            <fe-label style="width:76px;">First Name</fe-label>
            <v-text-field flat solo dense autofocus
                v-model="rec.fname"
                :rules="$fieldValidators('text', null, {required:true, limit:50})"
            />
            <fe-label style="width:76px;">Last Name</fe-label>
            <v-text-field flat solo dense
                v-model="rec.lname"
                @blur="genLogin(rec)"
                :rules="$fieldValidators('text', null, {required:true, limit:50})"
            />
            <fe-label style="width:76px;">Title</fe-label>
            <v-text-field flat solo dense
                v-model="rec.title"
                :rules="$fieldValidators('text', null, {required:true, limit:50})"
            />
            <fe-label style="width:76px;">Email</fe-label>
            <v-text-field flat solo dense
                v-model="rec.email_addr"
                :rules="$fieldValidators('text', null, {required:true, limit:100})"
            />
            <fe-label style="width:76px;">Login</fe-label>
            <v-text-field flat solo dense
                v-model="rec.login"
                :rules="$fieldValidators('text', null, {required:true, limit:100})"
            />
            <!-- <div class="d-flex">
                <fe-label style="width:76px;">Groups</fe-label>
                <fe-remote-combo
                    style="width:242px; max-width:242px;"
                    v-model="rec.groups"
                    multiple moreText
                    url="userGroup.php?action=get"
                    rootProperty="user_group"
                    byId
                />
            </div> -->

            <div class="d-flex">
                <fe-label style="width:100px;">Active</fe-label>
                <fe-switch style="margin-left:auto;" v-model="rec.active" />
            </div>
            <div class="d-flex">
                <fe-label style="width:100px;">Approve SLO</fe-label>
                <fe-switch style="margin-left:auto;" v-model="rec.approve_slo" />
            </div>
            <div class="d-flex">
                <fe-label style="width:100px;">Force Top Secret</fe-label>
                <fe-switch style="margin-left:auto;" v-model="rec.force_top_secret" />
            </div>
        </template>
            
    </record-dialog>
</template>

<script>
import { mapState } from 'vuex'
import RecordDialog from '@/components/common/form/RecordDialog'

export default {
    name: 'AddUser',
    
    components: {
        RecordDialog
    },
    
    computed: {
        ...mapState('global',['sessionUser']),
    },
    methods: {
        genLogin(userRec) {
            if(!userRec.login) {
                this.$axios({
                    url: 'users.php?action=get&property=generate_login',
                    params: {
                        fname: userRec.fname,
                        lname: userRec.lname,
                    }
                }).then((response) => {
                    userRec.login = response && response.data
                    if(!userRec.email_addr) {
                        userRec.email_addr = userRec.login + this.sessionUser.district.email_domain
                    }
                })
            }
        },
    },

    data() {
        return {
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
