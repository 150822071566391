var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-fill flex-column",staticStyle:{"position":"relative"}},[_c('v-slide-y-transition',[(_vm.selectedStudents.length > 0)?_c('div',{staticClass:"selection-toolbar d-flex"},[_c('div',{staticClass:"mt-3 ml-5"},[_vm._v(" "+_vm._s(_vm.selectedStudents.length)+" Record"+_vm._s(_vm.selectedStudents.length > 1 ? 's' : '')+" Selected ")]),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{staticClass:"white--text",attrs:{"text":""},on:{"click":_vm.tagStudents}},[_vm._v(" Tag Students ")]),_c('v-btn',{staticClass:"white--text",attrs:{"text":""},on:{"click":function($event){return _vm.$refs.grid.gridApi.deselectAll()}}},[_vm._v(" Cancel ")])],1)]):_vm._e()]),(_vm.gridReady)?_c('fe-grid',{ref:"grid",staticStyle:{"width":"100%","height":"100%"},attrs:{"domLayout":"normal","showAddRowBtn":false,"columnDefs":_vm.columns,"defaultColDefOverrides":_vm.defaultColDef,"rowData":_vm.data,"pinnedBottomRowData":_vm.footerRows,"displayDensity":"small"},on:{"cellMouseOver":_vm.showStudentCard,"cellMouseOut":_vm.hideStudentCard,"cellClicked":_vm.doClick,"rowSelected":_vm.doSelect,"gridReady":_vm.onGridReady}}):_vm._e(),_c('fe-student-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.studentCard.show),expression:"studentCard.show"}],ref:"studentCard",style:({
            position: 'absolute',
            left: `${_vm.studentCard.left}px`,
            top: `${_vm.studentCard.top}px`,
            padding: '1px 6px 1px 6px',
            zIndex: '10'
        }),attrs:{"fullNameField":"student_full_name","schoolField":"school_name","gradeField":"grade_desc","genderField":"gender","imageUrl":_vm.studentCard.imageUrl,"studentRecord":_vm.studentCard.studentRecord},on:{"close":() => {}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }