<template>
    <div class="flex-column fill-height pa-5 ec-collections-main">
         <v-flex row>
             <span style="font-size: 24px">Collections</span>
             <v-spacer/>
             <fe-btn @click="showNewCollectionDialog = true">New</fe-btn>
         </v-flex>

         <v-tabs height="100%" v-model="activeTab" class="ec-collection-tabs">
             <v-tab class="small-tab small-tab-all">All</v-tab>
             <v-tab class="small-tab small-tab-user">My Collections</v-tab>
             <v-tab class="small-tab small-tab-shared">Shared with Me</v-tab>

             <v-tab-item class="pt-4 pb-4">
                 <fe-text-field placeholder="Search Collections" prependIcon="fa-search" width="100%" @change="filterCollectionsTextChange" ref="filterText"></fe-text-field>
                 <div class="ec-collection-tab-item-div">
                     <collection-tab v-if="showMyCollections" :collectionData="collectionDataFiltered.user_dashboards" :activeTab="activeTab"
                         id="ecCollectionTabAllMy" :reload="reload" @reloaded="reload = false" title="My Collections" :collectionTypes="collectionTypes"
                         :filterText="filterCollectionsText" :collectionGroups="collectionGroups" />
                     <collection-tab :collectionData="collectionDataFiltered.shared_dashboards" :activeTab="activeTab"
                         id="ecCollectionTabAllShared" :reload="reload" @reloaded="reload = false" title="Shared with Me" :collectionTypes="collectionTypes"
                         :filterText="filterCollectionsText" :collectionGroups="collectionGroups" />
                 </div>
             </v-tab-item>
             <v-tab-item class="pt-4 pb-4">
                 <fe-text-field placeholder="Search Collections" prependIcon="fa-search" width="100%" @change="filterCollectionsTextChange" ref="filterText"></fe-text-field>
                 <div class="ec-collection-tab-item-div">
                    <collection-tab :collectionData="collectionDataFiltered.user_dashboards" :activeTab="activeTab" title="My Collections" :reload="reload" @reloaded="reload = false"
                        :collectionTypes="collectionTypes" :filterText="filterCollectionsText" :collectionGroups="collectionGroups" />
                 </div>
             </v-tab-item>
             <v-tab-item class="pt-4 pb-4">
                 <fe-text-field placeholder="Search Collections" prependIcon="fa-search" width="100%" @change="filterCollectionsTextChange" ref="filterText"></fe-text-field>
                 <div class="ec-collection-tab-item-div">
                    <collection-tab :collectionData="collectionDataFiltered.shared_dashboards" :activeTab="activeTab" title="Shared with Me" :reload="reload" @reloaded="reload = false"
                        :collectionTypes="collectionTypes" :filterText="filterCollectionsText" :collectionGroups="collectionGroups" />
                 </div>
             </v-tab-item>
        </v-tabs>

        <fe-dialog v-if="showNewCollectionDialog"
            persistent
            title="Create Collection"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            @accept="showNewCollectionDialog = false; createDashboard()"
            @dismiss="showNewCollectionDialog = false">
            <fe-text-field label="Collection Name" class="mb-5" width="100%" v-model="newCollectionName"></fe-text-field>
            <fe-label>Collection Color</fe-label>
            <fe-color-selector @change="updateNewCollectionColor" :value="colorSelectorValue"></fe-color-selector>
        </fe-dialog>
    </div>
</template>

<script>
    import Vuex from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'
    import CollectionView from '@/components/modules/collections/landing/Landing'
    import CollectionTab from '@/components/modules/collections/landing/Tabs'
    export default {
        name: 'CollectionsLanding',
        components: {
            CollectionView,
            CollectionTab
        },
        data() {
            return {
                collectionTypes: [],
                collectionData: [],
                collectionGroups: [],
                collectionDataFiltered: [],
                tabs: [{ name: 'All' }, { name: 'My Collections' }, { name: 'Shared with Me' }],
                activeTab: 'All',
                showNewCollectionDialog: false,
                colorSelectorValue: '#0049FF',
                filterCollectionsText: null,
                newCollectionName: null,
                filterText: '',
                reload: false,
                showMyCollections: false,
            }
        },
        created() {
            this.onLoad()
        },
        methods: {
            loadStatus() {
                this.$modelGet('collectionSavedSearchType', {active: 1})
                    .then(response => {
                        this.collectionTypes = response
                    })
            },
            loadHome() {
                this.$modelGet('collectionHome', {active: 1})
                    .then(response => {
                        this.collectionData = response
                    })
            },
            loadGroups() {
                this.$modelGet('collectionGroups', {active: 1})
                    .then(response => {
                        this.collectionGroups = response
                    })
            },
            createDashboard() {
                let params = {
                    title: this.newCollectionName,
                    color: this.colorSelectorValue
                }
                this.$axios.post('dashboard.php?action=create&property=dashboard&standalone=true', { dashboard: [params] })
                    .then(response => {
                        if(response.data.success) {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                            this.loadHome()
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
            },
            updateNewCollectionColor(v) {
                this.colorSelectorValue = v
            },
            filterCollectionsTextChange(v) {
                this.filterCollectionsText = v
            },
            onLoad() {
                this.loadStatus()
                this.loadHome()
                this.loadGroups()
            }
        },
        watch: {
            collectionData(v) {
                this.collectionDataFiltered = v
                this.collectionDataFiltered.user_dashboards.sort((a,b) => (a.dashboard_created > b.dashboard_created) ? 1 : ((b.dashboard_created > a.dashboard_created) ? -1 : 0))
                this.collectionDataFiltered.shared_dashboards.sort((a,b) => (a.dashboard_created > b.dashboard_created) ? 1 : ((b.dashboard_created > a.dashboard_created) ? -1 : 0))
                // if you have created collections then show my collections
                // if you have no created and no shared collections, then show my collections empty state
                this.showMyCollections = this.collectionDataFiltered.user_dashboards.length
                    || !this.collectionDataFiltered.user_dashboards.length && !this.collectionDataFiltered.shared_dashboards.length
            },
            $route(to, from) {
                if(to.path === '/Collections') {
                    this.onLoad()
                    this.$refs.filterText.text = null
                    this.reload = true
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .ec-collections-main {
        background: #F5F6F8;
    }
    .small-tab {
        text-transform: capitalize;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        font-size: 14px;
        letter-spacing: normal;
        justify-content: flex-start;
        &-all {
            min-width: 30px;
            max-width: 30px;
            padding-left: 6px;
            margin: 5px 15px 5px 0;
        }
        &-user {
            min-width: 130px;
            max-width: 130px;
            margin: 5px 0;
        }
        &-shared {
            min-width: 135px;
            max-width: 135px;
            margin: 5px 0;
        }
    }
    .small-tab::before {
        border-radius: 4px;
    }
    .ec-collection {
        &-tabs {
            ::v-deep .v-item-group {
                background: #F5F6F8 !important;
                position: relative;
                flex-wrap: nowrap;
                white-space: nowrap;
                max-width: 100%;
                overflow: auto;
            }
            ::v-deep .v-tabs-slider {
                border-radius: 1.5px;
                &-wrapper {
                    height: 3px !important;
                }
            }
        }
        &-tab-item-div {
            height: calc(100vh - 200px);
            overflow: auto;
        }
    }
</style>