<template>
    <div class="flex-fill flex-column slo-plan-panel">
        <template v-if="slo">

            <fe-card
                class="alt-card mb-3"
                :headerText="summaryHeader"
            >
                <template v-slot:title-toolbar>
                    <v-menu offset-y left>
                        <template v-slot:activator="{on}">
                            <v-btn icon x-small v-on="on"><v-icon>more_vert</v-icon></v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="$emit('edit', slo)">Edit</v-list-item>
                            <v-list-item @click="exportPDF">Print PDF</v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:body>
                    {{ slo.plan.rationale_text }}
                </template>
            </fe-card>

            <fe-card class="alt-card mb-3" headerText="Status Charts">
                <template v-slot:body>
                    <div class="d-flex justify-center align-center" style="height: 150px;">
                        <highcharts ref="chart-goals" :options="chartConfigs.goals" class="status-chart" />
                        <highcharts ref="chart-strategies" :options="chartConfigs.strategies" class="status-chart" />
                        <highcharts ref="chart-logistics" :options="chartConfigs.logistics" class="status-chart" />
                    </div>
                </template>
            </fe-card>

            <div class="d-flex mb-3">
                <fe-card class="alt-card flex-grow-1 flex-shrink-1 mr-1" headerText="SMART Goal" style="width: 50%;">
                    <template v-slot:title-toolbar>
                        <v-menu offset-y left>
                            <template v-slot:activator="{on}">
                                <v-btn icon x-small v-on="on"><v-icon>more_vert</v-icon></v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="rewriteStatement = true">Rewrite</v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    <template v-slot:body>
                        {{ statementText }}
                    </template>
                </fe-card>

                <fe-card class="alt-card flex-grow-1 flex-shrink-1 ml-1" headerText="Results" style="width: 50%;">
                    <template v-slot:body>
                        <p v-for="(goal, i) in goals" :key="`slo-goal-${i}`">
                            {{ goal.result_text }}
                        </p>
                    </template>
                </fe-card>
            </div>

            <benchmark-chart
                v-if="!showGrid && projectionChartConfig"
                ref="slo-summary-benchmark"
                :config="projectionChartConfig"
                type="basic"
                :pinnable="false"
                :params="chartingConfig"
                projections
                suppressDefaultDrilldown
                @chartDrilldown="$emit('chartDrilldown', $event)"
            />

            <div
                v-else-if="showGrid && datawallConfig"
                class="d-flex"
                style="min-height: 300px; max-height: 500px"
            >
                <data-wall
                    ref="slo-summary-datawall"
                    :params="datawallConfig"
                    :categories="[]"
                    hide-pin
                    hide-menu
                    standalone-borders
                    suppressDefaultDrilldown
                    @drilldown="$emit('dataWallDrilldown', $event)"
                    @scoreBreakdown="$emit('scoreBreakdown', $event)"
                />
            </div>

            <fe-dialog
                v-if="rewriteStatement"
                ref="dlgRewrite"
                title="Rewrite Goal"
                :acceptButtonDisabled="!statementValid || !statementTextChanged"
                acceptButtonText="Save"
                dismissButtonText="Cancel"
                @accept="doRewriteStatement"
                @dismiss="cancelRewriteStatement"
                @cancel="cancelRewriteStatement"
            >
                <fe-label content="Statement Text" />
                <v-textarea
                    v-model="statementText"
                    flat solo dense
                    placeholder="SLO Goal Statement"
                    hide-details
                    :rules="statementRules"
                />
            </fe-dialog>

            <fe-crud ref="planCrud" :config="$models.sloPlan" />

        </template>
    </div>
</template>

<script>
    import BenchmarkChart from '@/components/charts/BenchmarkChart'
    import DataWall from '@/components/modules/datawall/Index'

    export default {
        name: 'SloPanelSummary',
        props: {
            slo: { type: Object, required: true }
        },
        components: {
            BenchmarkChart,
            DataWall
        },
        data() {
            return {
                projectionChartConfig: null,
                datawallConfig: null,
                chartingConfig: null,
                statementText: '',
                rewriteStatement: false,
                statementRules: [
                    (v) => (`${v}`.trim().length > 0) || 'Please enter a goal statement.'
                ],
                statementTextChanged: false,
            }
        },
        computed: {
            statementValid () { return !this.statementRules.find(mthd => mthd(this.statementText) !== true ) },
            showGrid () { return !!this.slo?.plan?.student_count },
            strategies () { return this.slo?.strategies || [] },
            logistics () { return this.slo?.logistics || [] },
            goals () { return this.slo?.goals || [] },
            goalsCompletion () {
                let complete = 0
                let incomplete = 0
                this.goals.forEach(itm => {
                    if (itm.goal_reached) complete++
                    else incomplete++
                })
                let total = complete + incomplete

                return { 'count': complete > 0 ? this.$twoDecimals((complete / total) * 100) : 0, 'complete' : complete, 'incomplete' : incomplete }
            },
            strategiesCompletion () {
                let complete = 0
                let incomplete = 0
                this.strategies.forEach(itm => {
                    complete += parseInt(itm.total_complete)
                    incomplete += parseInt(itm.total_incomplete)
                })
                let total = complete + incomplete

                return { 'count': complete > 0 ? this.$twoDecimals((complete / total) * 100) : 0, 'complete' : complete, 'incomplete' : incomplete }
            },
            logisticsCompletion () {
                let complete = 0
                let incomplete = 0
                this.logistics.forEach(itm => {
                    if (itm.completed) complete++
                    else incomplete++
                })
                let total = complete + incomplete

                return { 'count': complete > 0 ? this.$twoDecimals((complete / total) * 100) : 0, 'complete' : complete, 'incomplete' : incomplete }
            },
            chartConfigs () {
                return {
                    goals: this.gaugeConfig('GOALS<br />COMPLETE', this.goalsCompletion),
                    strategies: this.gaugeConfig('STRATEGIES<br />COMPLETE', this.strategiesCompletion),
                    logistics: this.gaugeConfig('LOGISTICS<br />COMPLETE', this.logisticsCompletion)
                }
            },
            summaryHeader () {
                let start = this.$dayjs(this.slo.plan.slo_start_date, 'YYYY-MM-DD').format('LL')
                let end = this.$dayjs(this.slo.plan.slo_end_date, 'YYYY-MM-DD').format('LL')
                return `${this.slo.plan.slo_approval_user_display_name} is assigned to ${this.slo.slo_name} from ${start} to ${end}`
            },
        },
        methods: {
            async getProjectionConfig() {
                let qs = this.$objectToParams({
                    target_set_id: this.slo.target_set_id,
                    saved_search_id: this.slo.plan.saved_search_id
                })
                let response = await this.$axios.get(`targetScores.php?action=get&${qs}`)
                let config = response.data.categories[0].stacked_charts[0]

                let chartType = config.BASIC.length ? config.BASIC : config.DETAILED
                let projection = this.$_.cloneDeep(chartType[0])
                projection.name = "Goal"
                projection.preventDrilldown = true
                projection.preventDrilldownMessage = "Cannot drill into Goal Chart."
                projection.target_descriptors.forEach(td => {
                    let tdId = td.target_descriptor_id
                    let tdName = td.target_descriptor_name
                    this.slo.goals.forEach(goal => {
                        if (goal.target_descriptor_id == tdId) projection[tdName] = parseFloat(goal.goal)
                    })
                })
                chartType.push(projection)
                config.slo = true

                this.projectionChartConfig = config
            },
            async getDatawallConfig() {
                this.datawallConfig = {
                    saved_search_id: this.slo.plan.saved_search_id ? parseInt(this.slo.plan.saved_search_id): null,
                    sub_category_id: this.slo.plan.sub_category_id ? parseInt(this.slo.plan.sub_category_id): null,
                    student_id: this.slo.students.map(itm => parseInt(itm.student_id)).join(','),
                    property: 'datawall',
                    school_year_id: this.slo.plan.school_year_id ? parseInt(this.slo.plan.school_year_id): null
                }
            },
            async getChartingConfig() {
                this.chartingConfig = {
                    saved_search_id: this.slo.plan.saved_search_id ? parseInt(this.slo.plan.saved_search_id): null,
                    sub_category_id: this.slo.plan.sub_category_id ? parseInt(this.slo.plan.sub_category_id): null,
                    property: 'charts',
                    school_year_id: this.slo.plan.school_year_id ? parseInt(this.slo.plan.school_year_id): null
                }
            },
            gaugeConfig(title, count) {
                return {
                    chart: {
                        type: 'pie',
                        height: '120px',
                        plotShadow: false,
                        margin: [7, 5, 7, 5]
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        verticalAlign: 'middle',
                        floating: true,
                        text: '<span style="font-size: 16px;">' + count.count + '%<br>' + title + '</span>',
                        style: {
                            fontSize: 12
                        }
                    },
                    exporting: {
                        enabled: false
                    },

                    tooltip: {
                        enabled: true,
                        backgroundColor: '#FFFFFF',
                        borderColor: '#e6e6e6',
                        valueSuffix: '%',
                        useHTML: true,
                        formatter() {
                            return this.point.id == 'Complete'
                                ? '<span><i style="font-family: \'Font Awesome 5 Pro\' !important; color: #408AAF" class="fas fa-check-circle"></i>'
                                    + ' ' + `${this.point.id}` + ': ' + count.complete + '</span>'
                                : '<span> ' + `${this.point.id}` + ': ' + count.incomplete + '</span>'
                        }
                    },

                    plotOptions: {
                        pie: {
                            innerSize: '100%',
                            borderWidth: 12,
                            borderColor: null,
                            slicedOffset: 0,
                            dataLabels: {
                                connectorWidth: 0,
                                enabled: false
                            }
                        }
                    },

                    series: [{
                        name: 'On Track',
                        data: [{
                            name: 'Not On Track',
                            color: '#e6e6e6',
                            sliced: true,
                            y: 100 - count.count,
                            id: 'Incomplete'
                        }, {
                            name: 'On Track',
                            color: '#408AAF',
                            sliced: true,
                            y: count.count,
                            id: 'Complete'
                        }]
                    }]
                }
            },
            doRewriteStatement () {
                this.rewriteStatement = false
                this.$refs.planCrud.update({
                    id: this.slo.plan.slo_plan_id,
                    statement: this.statementText,
                    statement_text: this.statementText
                }).then(() => {
                    this.$emit('refreshSlo')
                })
            },
            cancelRewriteStatement () {
                this.rewriteStatement = false
                this.statementText = this.slo.statement_text || this.slo.statement
            },
            exportPDF () {
                this.$store.commit('global/addDockableWindow', {
                    name: 'SLO PDF Export',
                    component: 'i-frame-renderer',
                    attrs: {
                        url: `${this.$axios.defaults.baseURL}reportCreate.php?type=slo&slo_id=${this.slo.id}`,
                        app: 'url'
                    }
                })
            },
        },
        mounted () {
            if (this.showGrid) this.getDatawallConfig()
            else {
                this.getChartingConfig()
                this.getProjectionConfig()
            }
        },
        watch: {
            slo: {
                handler(v) {
                    this.statementText = v.statement_text || v.statement
                    this.statementTextChanged = false
                },
                deep: true,
                immediate: true
            },
            statementText(v) {
                this.statementTextChanged = v != this.slo.statement_text && v != this.slo.statement
            },
        }
    }
</script>

<style lang="scss" scoped>
    .status-chart ::v-deep * {
        font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
    }
    .status-chart {
        width: 150px;
    }
</style>
