<template>
    <v-snackbar
        :value="active"
        :timeout="0"
        :color="color"
    >
        {{ text }}
    </v-snackbar>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    name: 'Toast',

    data() {
        return {
            active: false,
            timer: null,
            defaultTimeout: 5000,
        }
    },

    computed: {
        ...mapGetters('thresholds/toast', [
            'messages',
            'current',
            'count'
        ]),

        text() {
            if (typeof this.current === "string") {
                return this.current
            } else if (this.current && typeof this.current === "object" && this.current.message) {
                return this.current.message
            } else {
                return null
            }
        },

        color() {
            if (this.current && typeof this.current === "object" && this.current.color) {
                return this.current.color
            } else {
                return null
            }
        },

        timeout() {
            if (this.current && typeof this.current === "object" && this.current.timeout) {
                return this.current.timeout
            } else {
                return this.defaultTimeout
            }
        },
    },

    watch: {
        count: function () {
            this.init()
        }
    },

    mounted() {
        this.init()
    },

    methods: {
        ...mapActions('thresholds/toast', [
            'shift',
            'push',
            'clear'
        ]),

        init() {
            this.active = !!this.current
            if (this.active) {
                if (!this.timer) {
                    this.timer = setTimeout(() => {
                        this.timer = null
                        this.shift()
                    }, this.timeout)
                }
            }
        }
    }
}
</script>
