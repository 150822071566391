<template>
    <v-layout row fill-height>
        <div v-if="showSchoolYear" class="d-flex" style="width: 160px; min-width: 160px;">
            <fe-stepper-checkbox-panel v-if="params" width="100%" byId :items="schoolYears" :surfaceFirstSelections="surfaceFirstSelections" v-model="params.school_year_id" search searchPlaceholder="School Years"/>
            <v-divider v-if="showSchoolYear" vertical style="background-color:#E0E1E6"/>
        </div>

        <div v-if="!showSchoolYear || hasSchoolYear" class="d-flex" style="width: 130px; min-width: 130px;">
            <fe-stepper-select-panel :items="dataTypes" v-model="selection" width="100%"/>
            <v-divider vertical style="background-color:#E0E1E6"/>
        </div>

        <div class="flex-grow-1">
            <!--
            These flexboxes-within-a-flexbox are causing width calc issues and
            bumping the "selections" panel off the screen.  Works cleanly if placed
            within an absolute div of its own.
            -->
            <div style="position: relative; height: 100%;">
                <div style="position: absolute; width: 100%; height: 100%;">
                    <template v-if="(!showSchoolYear || hasSchoolYear) && params && params.assessment">
                        <assessment-stepper-panel
                            v-model="params.assessment"
                            :surfaceFirstSelections="surfaceFirstSelections"
                            v-show="selection.id==='assessment'"
                            byId
                            @subCategorySelected="updateSelected('assessment', 'sub_category', ...arguments)"
                            @category-selected="updateSelected('assessment', 'category', ...arguments)"
                            @type-selected="updateSelected('assessment', 'data_point_type', ...arguments)"
                            @windowSelected="$emit('selected')"
                            @searchDisplayChange="searchDisplayAssessment = $event"
                        />
                    </template>

                    <template v-if="(!showSchoolYear || hasSchoolYear) && params && params.incident">
                        <incident-stepper-panel
                            v-model="params.incident"
                            :surfaceFirstSelections="surfaceFirstSelections"
                            v-show="selection.id==='incident'"
                            :incidentTypeOnly="incidentTypeOnly"
                            byId
                            @incident-behavior-type-selected="updateSelected('incident', 'behavior_type', ...arguments)"
                            @incident-behavior-selected="updateSelected('incident', 'behavior', ...arguments)"
                            @searchDisplayChange="searchDisplayIncidents = $event"
                        />
                    </template>

                    <template v-if="(!showSchoolYear || hasSchoolYear) && params">
                        <div v-show="selection.id == 'attendance'" class="col-12 pa-0 fill-height">
                            <div class="pa-4">
                                <fe-stepper-panel width="100%">
                                    <v-checkbox label="Include Attendance" v-model="params.attendance"/>
                                </fe-stepper-panel>
                            </div>
                            <v-divider vertical style="background-color:#E0E1E6"/>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <v-divider vertical style="background-color:#E0E1E6; z-index: 2;"/>

        <div class="pb-4" style="width: 20%; min-width: 20%; overflow: auto;">
            <fe-stepper-panel class="pa-2" width="100%">
                <template v-if="!selectedAssessments.length && !selectedIncidents.length && !selectedAttendance.length">
                    <div class="fs18 d-flex">
                        <div>Selections</div>
                    </div>
                    <div class="fs12">No selections.</div>
                </template>

                <template v-if="selectedAssessments.length">
                    <div class="mb-2">
                        <div class="fs18 d-flex">
                            <div>Assessments</div>
                            <v-spacer/>
                        </div>
                        <div v-for="(itm, i) in selectedAssessments" :key="`sc-`+i" class="d-flex selected-item">
                            <div class="selected-item-name">
                                {{ itm.title }}
                                <div v-if="itm.subtitle" class="fs10" style="line-height: 14px;">{{ itm.subtitle }}</div>
                                <div v-else-if="itm.parent" class="fs10" style="line-height: 14px;">{{ itm.parent.name }}</div>
                            </div>

                            <v-spacer/>
                            <div class="text-right" :class="{ 'mt-2': !!itm.name }">
                                <v-icon style="width: 20px;" @click="removeSelection('assessment', itm, i)">fas fa-times</v-icon>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="selectedIncidents.length">
                    <div class="mb-2">
                        <div class="fs18 d-flex">
                            <div>Incidents</div>
                            <v-spacer/>
                        </div>
                        <div v-for="(itm, i) in selectedIncidents" :key="`inc-`+i" class="d-flex selected-item">
                            <div class="selected-item-name">
                                {{ itm.title }}
                                <div v-if="itm.subtitle" class="fs10" style="line-height: 14px;">{{ itm.subtitle }}</div>
                                <div v-else-if="itm.parent" class="fs10" style="line-height: 14px;">{{ itm.parent.name }}</div>
                            </div>
                            <v-spacer/>
                            <div class="text-right">
                                <v-icon style="width: 20px;" @click="removeSelection('incident', itm, i)">fas fa-times</v-icon>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="selectedAttendance.length">
                    <div class="fs18 d-flex">
                        <div class="mt-2">Attendance</div>
                    </div>
                    <div v-for="(sc, i) in selectedAttendance" :key="`att-`+i" class="d-flex selected-item">
                        <div class="selected-item-name">{{ sc.name }}</div>
                        <v-spacer/>
                        <div class="text-right">
                            <v-icon style="width: 20px;" @click="removeSelection('attendance', sc, i)">fas fa-times</v-icon>
                        </div>
                    </div>
                </template>
            </fe-stepper-panel>
        </div>
    </v-layout>
</template>

<script>
    import AssessmentStepperPanel from './AssessmentStepperPanel'
    import IncidentStepperPanel from './IncidentStepperPanel'
    import { mapState } from 'vuex'

    export default {
        name: 'MultiDataStepperPanel',
        components: {
            AssessmentStepperPanel,
            IncidentStepperPanel
        },
        props: {
            value: {},
            surfaceFirstSelections: {
                type: Boolean,
                default: false
            },
            showYears: {
                type: Boolean,
                default: false
            },
            defaultSchoolYearId: {
                type: Number,
                default: null
            },
            showSchoolYear: {
                type: Boolean,
                default: false
            },
            incidentTypeOnly: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            params: {
                handler(v) {
                    let value = this.$_.cloneDeep(v)

                    // If user selected some assessment vars (category, assessment group, etc.)
                    // but didn't finish the process, then we want to propagate up an empty object
                    // to represent that we did not select any assessment (all steps are required!)
                    if (value?.assessment?.data_point_name_id?.length === 0) {
                        value.assessment = {}
                    }

                    // The same "all steps are required" logic applies to incidents as well, although
                    // for "type only" there is only one step in the first place
                    if (!this.incidentTypeOnly) {
                        if (value?.incident?.incident_response_id?.length === 0) {
                            value.incident = {}
                        }
                    }

                    this.$emit('input', value)
                },
                deep: true
            },
            'params.attendance'(v) {
                if (v) {
                    this.selectedAttendance = [{
                        id: 1,
                        name: 'Include Attendance'
                    }]
                } else {
                    this.selectedAttendance = []
                }

                this.$emit('selected', true)
            },
            searchDisplay(v) {
                this.$emit('searchDisplayChange', v)
            },
        },
        data() {
            return {
                schoolYears: [],
                scSelected: [],
                selection: { id: 'assessment' },
                dataTypes: [{
                    id: 'assessment',
                    name: 'Assessment'
                }, {
                    id: 'incident',
                    name: 'Incident'
                }, {
                    id: 'attendance',
                    name: 'Attendance'
                }],
                params: null,
                selectedAssessmentCategories: [],
                selectedAssessmentSubCategories: [],
                selectedAssessmentTypes: [],
                selectedIncidentBehaviorTypes: [],
                selectedIncidentBehaviors: [],
                selectedAttendance: [],
                searchDisplayAssessment: '<b>Select Categories</b>',
                searchDisplayIncidents: '<b>Select Behavior Types</b>',
            }
        },
        mounted() {
            this.params = this.$_.cloneDeep(this.value)

            // Default data school year to cohort school year (or whatever's given as
            // the default) unless e.g. a previous saved search is pre-supplying the SY values
            if (this.showSchoolYear && !this.params?.school_year_id?.length) {
                this.params.school_year_id = [this.defaultSchoolYearId]
            }

            this.loadBase()
        },
        methods: {
            loadBase() {
                this.$modelGet('schoolYear').then(r => {
                    this.schoolYears = r
                })

                let categoryId = this.params.assessment?.category_id?.filter(id => id > 0)
                if (categoryId?.length) {
                    this.$modelGet('category', { id: categoryId })
                        .then(r => this.selectedAssessmentCategories = r)
                }

                let dataPointTypeId = this.params.assessment?.data_point_type_id?.filter(id => id > 0)
                if (dataPointTypeId?.length) {
                    this.$modelGet('assessment', { id: dataPointTypeId })
                        .then(r => this.selectedAssessmentTypes = r)
                }

                let subCategoryId = this.params.assessment?.sub_category_id?.filter(id => id > 0)
                if (subCategoryId?.length) {
                    this.$modelGet('subcategory', { id: subCategoryId })
                        .then(r => this.selectedAssessmentSubCategories = r)
                }

                let incidentBehaviorTypeId = this.params.incident?.incident_behavior_type_id?.filter(id => id > 0)
                if (incidentBehaviorTypeId?.length) {
                    this.$modelGet('incidentBehaviorType', { id: incidentBehaviorTypeId })
                        .then(r => this.selectedIncidentBehaviorTypes = r)
                }

                let incidentBehaviorId = this.params.incident?.incident_behavior_id?.filter(id => id > 0)
                if (incidentBehaviorId?.length) {
                    this.$modelGet('incidentBehavior', { id: incidentBehaviorId })
                        .then(r => this.selectedIncidentBehaviors = r)
                }
            },
            setSelected(v, item) {
                // this.scSelected = [...v]
            },
            removeItem(group, key, item, index) {
                let idx = this.params[group][key].findIndex(r=>r.id===item.id)
                this.params[group][key].splice(idx, 1)
            },
            removeSelection(key, item, index) {
                if (key === 'assessment') {
                    if (item.type == 'category') {
                        this.selectedAssessmentCategories = this.selectedAssessmentCategories.filter(itm => itm.id !== item.parent.id)
                        this.params.assessment.category_id = this.params.assessment.category_id.filter(id => id !== item.parent.id)
                    } else if (item.type == 'data_point_type') {
                        // No parent means "All Assessments" was selected for "All Categories"
                        if (!item.parent) {
                            this.selectedAssessmentTypes = []
                            this.params.assessment.data_point_type_id = []
                        } else {
                            this.selectedAssessmentTypes = this.selectedAssessmentTypes.filter(itm => itm.id !== item.parent.id)
                            this.params.assessment.data_point_type_id = this.params.assessment.data_point_type_id.filter(id => id !== item.parent.id)
                        }
                    } else if (item.type == 'sub_category') {
                        // These are removing specifically-selected assessments, so there's no parent.id - remove the actual assessments
                        this.selectedAssessmentSubCategories = this.selectedAssessmentSubCategories.filter(itm => itm.id !== item.id)
                        this.params.assessment.sub_category_id = this.params.assessment.sub_category_id.filter(id => id !== item.id)
                    }
                } else if (key === 'incident') {
                    if (item.type == 'incident_behavior_type') {
                        if (!item.parent) {
                            this.selectedIncidentBehaviorTypes = []
                            this.params.incident.incident_behavior_type_id = []
                        } else {
                            this.selectedIncidentBehaviorTypes = this.selectedIncidentBehaviorTypes.filter(itm => itm.id !== item.parent.id)
                            this.params.incident.incident_behavior_type_id = this.params.incident.incident_behavior_type_id.filter(id => id !== item.parent.id)
                        }
                    } else if (item.type == 'incident_behavior') {
                        // These are removing specifically-selected behaviors, so there's no parent.id - remove the actual behaviors
                        this.selectedIncidentBehaviors = this.selectedIncidentBehaviors.filter(itm => itm.id !== item.id)
                        this.params.incident.incident_behavior_id = this.params.incident.incident_behavior_id.filter(id => id !== item.id)
                    }
                } else if (key === 'attendance') {
                    this.selectedAttendance = []
                    this.params.attendance=false
                }

                this.$emit('removed', item)
            },
            updateSelected(domain, key, item, items) {
                switch (domain) {
                    case 'assessment':
                        if (key == 'category') {
                            this.selectedAssessmentCategories = items
                        } else if (key == 'sub_category') {
                            this.selectedAssessmentSubCategories = [...new Set([...items, ...this.selectedAssessmentSubCategories])]
                        } else if (key == 'data_point_type') {
                            this.selectedAssessmentTypes = [...new Set([...items, ...this.selectedAssessmentTypes])]
                        }

                        break
                    case 'incident':
                        if (key == 'behavior') {
                            this.selectedIncidentBehaviors = items
                        } else if (key == 'behavior_type') {
                            this.selectedIncidentBehaviorTypes = items
                        }

                        break
                }

                this.$emit('selected', item)
            }
        },
        computed: {
            ...mapState('global', ['currentYear']),
            hasSchoolYear() {
                return !!this.params?.school_year_id?.length
            },
            selectedAssessments() {
                if (!this.params?.assessment?.category_id?.length || !this.params?.assessment?.sub_category_id?.length || !this.params?.assessment?.data_point_name_id?.length) {
                    return []
                }

                // If user selected all assessments, try to show which
                // assessment groups they picked
                else if (this.params.assessment?.sub_category_id?.includes(-1)) {

                    // If user also selected all assessment groups, try to show which
                    // categories they selected
                    if (this.params.assessment?.data_point_type_id?.includes(-1)) {
                        // If user also @#$@3ing selected all categories, they deserve a cruel fate
                        if (this.params.assessment?.category_id?.includes(-1)) {
                            return [{ id: null, title: 'All Assessments', parent: null, type: 'data_point_type', subtitle: 'All Categories' }]
                        } else {
                            return this.params.assessment.category_id.map(id => ({
                                id: null,
                                title: 'All Assessments',
                                parent: this.selectedAssessmentCategories.find(c => c.id == id),
                                type: 'category',
                            }))
                        }
                    } else {
                        return this.params.assessment.data_point_type_id.map(id => ({
                            id: null,
                            title: 'All Assessments',
                            parent: this.selectedAssessmentTypes.find(t => t.id == id),
                            type: 'data_point_type',
                        }))
                    }
                }

                // If user selected specific assessments, each will be shown to the user
                else {
                    return this.params.assessment.sub_category_id.map(id => {
                        let assessment = this.selectedAssessmentSubCategories.find(sc => sc.id == id)

                        return {
                            id: id,
                            title: assessment?.name,
                            parent: this.selectedAssessmentTypes?.find(t => t.id == assessment?.data_point_type_id),
                            type: 'sub_category',
                        }
                    })
                }
            },
            selectedIncidents() {
                if (this.incidentTypeOnly) {
                    if (!this.params?.incident?.incident_behavior_type_id?.length) {
                        return []
                    }

                    // When only incident type is being selected, "All" is never an option for the user
                    else {
                        return this.params.incident.incident_behavior_type_id.map(id => ({
                            id: null,
                            title: 'All Behaviors',
                            parent: this.selectedIncidentBehaviorTypes.find(t => t.id === id),
                            type: 'incident_behavior_type',
                        }))
                    }
                }

                else {
                    if (!this.params?.incident?.incident_response_id?.length) {
                        return []
                    }

                    // If user selected all behaviors, try to show which
                    // behavior types they picked
                    else if (this.params.incident?.incident_behavior_id?.includes(-1)) {
                        if (this.params.incident.incident_behavior_type_id.includes(-1)) {
                            return [{ id: null, title: 'All Behaviors', parent: null, type: 'incident_behavior_type', subtitle: 'All Behavior Types' }]
                        } else {
                            return this.params.incident.incident_behavior_type_id.map(id => ({
                                id: null,
                                title: 'All Behaviors',
                                parent: this.selectedIncidentBehaviorTypes.find(t => t.id === id),
                                type: 'incident_behavior_type',
                            }))
                        }
                    }

                    // Use picked specific behaviors which will be shown one by one
                    else {
                        return this.params.incident.incident_behavior_id.map(id => {
                            let behavior = this.selectedIncidentBehaviors.find(itm => itm.id === id)

                            return {
                                id: id,
                                title: behavior?.name,
                                subtitle: behavior?.incident_behavior_type_name, // backend generously returns full name, which we need when "All Types" is selected
                                type: 'incident_behavior',
                            }
                        })
                    }
                }
            },
            searchDisplay() {
                let prefix = ''
                if (this.showSchoolYear) {
                    if (!this.params?.school_year_id?.length) {
                        return '<b>Select School Years</b>'
                    }

                    prefix = this.schoolYears.find(sy => sy.id == this.params.school_year_id[0])?.name
                    if (this.params.school_year_id.length > 1) {
                        prefix += `...+${this.params.school_year_id.length - 1}`
                    }
                }

                if (this.selection?.id == 'assessment') {
                    prefix += prefix ? '&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;' : ''
                    return prefix + this.searchDisplayAssessment
                } else if (this.selection?.id == 'incident') {
                    prefix += prefix ? '&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;' : ''
                    return prefix + this.searchDisplayIncidents
                } else {
                    return prefix
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.selected-item {
    background: #f5f6f8;
    padding: 4px 8px 4px 8px;
    margin: 4px 0px 4px 0px;
    &-name {
        overflow: hidden;
        /*white-space: nowrap;*/
        margin-right: 8px;
    }

}

.advanced-options {
    margin-top: 4px;
    font-size: 10px;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}
</style>
