export default {
    category: {
        defaults: {
            endpoint: 'crud.php',
            rootProperty: 'category'
        },
        read: {
            params: {
                action: 'get',
                property: 'Category'
            }
        },
        create: {
            params: {
                action: 'create',
                property: 'Category'
            }
        },
        update: {
            params: { action: 'update', property: 'Category' },
        },
        destroy: {
            params: { action: 'delete', property: 'Category' },
        }
    }
}