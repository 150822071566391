var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fe-dialog',{attrs:{"title":_vm.title,"acceptButtonText":"Save","dismissButtonText":"Cancel","width":"350px","forceClose":false,"acceptButtonDisabled":!_vm.valid,"refreshOnUpdated":"","refreshOnCreated":"","dismissable":"","disableAutoclose":"","persistent":""},on:{"accept":_vm.save,"dismiss":_vm.dismiss}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('fe-remote-combo',{attrs:{"label":"Demographic Name","items":_vm.activeDemographics,"placeholder":"Select Demographic","rules":_vm.$fieldValidators('select', null, { required: true })},model:{value:(_vm.newRec.demographic),callback:function ($$v) {_vm.$set(_vm.newRec, "demographic", $$v)},expression:"newRec.demographic"}}),_c('fe-label',[_vm._v("Value")]),(!_vm.newRec.demographic)?_c('v-text-field',{attrs:{"disabled":"","flat":"","solo":"","dense":""}}):_vm._e(),(_vm.newRec.demographic && _vm.newRec.demographic.value_type === 'option')?_c('fe-remote-combo',{attrs:{"items":_vm.newRec.demographic.options,"itemText":_vm.displayName,"placeholder":"Select an option","rules":_vm.$fieldValidators('select', null, { required: true })},model:{value:(_vm.newRec.demographic_option),callback:function ($$v) {_vm.$set(_vm.newRec, "demographic_option", $$v)},expression:"newRec.demographic_option"}}):_vm._e(),(_vm.newRec.demographic && _vm.newRec.demographic.value_type === 'date')?_c('fe-date-picker',{attrs:{"placeholder":"Select a date","rules":_vm.$fieldValidators('text', null, { required: true })},model:{value:(_vm.newRec.date_value),callback:function ($$v) {_vm.$set(_vm.newRec, "date_value", $$v)},expression:"newRec.date_value"}}):_vm._e(),(_vm.newRec.demographic && _vm.newRec.demographic.value_type === 'boolean')?_c('fe-remote-combo',{attrs:{"placeholder":"Select an option","items":[{
                name: 'Yes',
                id: '1'
            }, {
                name: 'No',
                id: '0'
            }],"rules":_vm.$fieldValidators('select', null, { required: true }),"byId":""},model:{value:(_vm.newRec.boolean_value),callback:function ($$v) {_vm.$set(_vm.newRec, "boolean_value", $$v)},expression:"newRec.boolean_value"}}):_vm._e(),(_vm.newRec.demographic && _vm.newRec.demographic.value_type === 'string')?_c('v-text-field',{attrs:{"placeholder":"Enter text","rules":_vm.$fieldValidators('text', null, { required: true }),"flat":"","solo":"","dense":""},model:{value:(_vm.newRec.string_value),callback:function ($$v) {_vm.$set(_vm.newRec, "string_value", $$v)},expression:"newRec.string_value"}}):_vm._e(),(_vm.newRec.demographic && _vm.newRec.demographic.value_type === 'number')?_c('v-text-field',{attrs:{"placeholder":"Enter a number","type":"number","rules":_vm.$fieldValidators('number', null, { required: true }),"flat":"","solo":"","dense":""},model:{value:(_vm.newRec.number_value),callback:function ($$v) {_vm.$set(_vm.newRec, "number_value", $$v)},expression:"newRec.number_value"}}):_vm._e(),_c('fe-remote-combo',{attrs:{"label":"School Year","items":_vm.schoolYears,"disabled":_vm.allYears,"placeholder":_vm.allYears ? '' :'Select School Year',"rules":_vm.$fieldValidators('select', null, { required: !_vm.allYears }),"byId":""},model:{value:(_vm.newRec.school_year_id),callback:function ($$v) {_vm.$set(_vm.newRec, "school_year_id", $$v)},expression:"newRec.school_year_id"}}),_c('fe-date-picker',{attrs:{"label":"Start Date (Optional)"},model:{value:(_vm.newRec.start_date),callback:function ($$v) {_vm.$set(_vm.newRec, "start_date", $$v)},expression:"newRec.start_date"}}),_c('fe-date-picker',{attrs:{"label":"End Date (Optional)"},model:{value:(_vm.newRec.end_date),callback:function ($$v) {_vm.$set(_vm.newRec, "end_date", $$v)},expression:"newRec.end_date"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }