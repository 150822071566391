import BaseService from '@/components/modules/thresholds/services/BaseService'
import BaseStore from '../Base'
import Notify from './Notify'
import NotifyGroup from './NotifyGroup'
import Tag from './Tag'
import Form from './Form'
import Email from './Email'
import Filter from './Filter'
import Subgroup from './Subgroup'
import PerformanceBand from './PerformanceBand'
import History from './History'
import ChartData from './ChartData'

export default {
    namespaced: true,

    modules: {
        'notify': Notify,
        'notifyGroup': NotifyGroup,
        'tag': Tag,
        'form': Form,
        'email': Email,
        'filter': Filter,
        'subgroup': Subgroup,
        'performanceBand': PerformanceBand,
        'history': History,
        'chartData': ChartData
    },

    state: {
        ...BaseStore.default().state,

        selected_id: null
    },

    mutations: {
        ...BaseStore.default().mutations,

        select(state, id) {
            state.selected_id = id
        },

        setStatus(state, params) {
            let result = state.collection.find(x => x.id === params.id)
            let statuses = ['has_valid_results', 'is_queued']
            statuses.forEach(stts => {
                if (result && stts in result) {
                    result[stts] = params[stts]
                }
            })
        }
    },

    actions: {
        ...BaseStore.default().actions,

        refreshStatus({commit, getters}, id) {
            let cfg = getters.__getMethodConfigs.fetch
            return new Promise((resolve, reject) => {
                let params = Object.assign({}, cfg.params, {id: id})
                cfg.service.fetch(params, cfg.opts).then(rsp => {
                    let data = (rsp.data && cfg.prop) ? rsp.data[cfg.prop] : rsp.data
                    if (typeof data === 'object') {
                        commit('setStatus', Array.isArray(data) ? data[0] : data)
                        resolve(rsp)
                    } else {
                        reject(data)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },

        select({commit, dispatch, state}, params) {
            if (state.selected_id !== params.id) {
                commit('select', params.id)
                dispatch('clearChildren')
                dispatch('refreshStatus', params.id)
            }
        },

        clearChildren({dispatch}) {
            dispatch('notify/clear')
            dispatch('notifyGroup/clear')
            dispatch('tag/clear')
            dispatch('form/clear')
            dispatch('email/clear')
            dispatch('filter/clear')
            dispatch('subgroup/clear')
            dispatch('performanceBand/clear')
            dispatch('history/clear')
            dispatch('chartData/clear')
        },

        duplicate({commit, getters, dispatch}, data) {
            commit('pending')
            let cfg = getters.__getMethodConfigs.added
            return new Promise((resolve, reject) => {
                let params = Object.assign({}, cfg.params, {action: 'copy'})
                cfg.service.add(params, data, cfg.opts).then(rsp => {
                    if (typeof rsp.data === 'object' && rsp.data.success) {
                        dispatch('refresh')
                        dispatch('select', {id: rsp.data.threshold_group[0].id})
                    } else {
                        commit('error')
                        reject(data)
                    }
                }).catch(err => {
                    commit('error')
                    reject(err)
                })
            })
        },

        set({commit}, data) {
            commit('success', data)
        },

        afterReconcile({dispatch}, responses) {
            responses.forEach((rsp) => {
                if (rsp.success && rsp.ids && Array.isArray(rsp.ids) && rsp.ids.length > 0) {
                    dispatch('select', {
                        id: rsp.ids[0]
                    })
                }
            })
            dispatch('refresh')
        }
    },

    getters: {
        ...BaseStore.default().getters,

        __config() {
            let prop = 'threshold_group'
            return {
                service: BaseService,
                params: {property: prop},
                opts: {type: 'thresholdGroups'},
                prop: prop
            }
        },

        selected: state => (state.selected_id == null)
            ? null
            : state.collection.find(itm => {
                return itm.id === state.selected_id
            }),

        selectedId: (state, getters) => (!getters.selected) ? null : getters.selected.id,

        selectedWithMeta: (state, getters) => (state.selected_id == null)
            ? null
            : getters.collection.find(itm => {
                return itm.id === state.selected_id
            }),
    }
}
