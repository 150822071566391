import { render, staticRenderFns } from "./AddDisability.vue?vue&type=template&id=2e8ad1af&scoped=true"
import script from "./AddDisability.vue?vue&type=script&lang=js"
export * from "./AddDisability.vue?vue&type=script&lang=js"
import style0 from "./AddDisability.vue?vue&type=style&index=0&id=2e8ad1af&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e8ad1af",
  null
  
)

export default component.exports