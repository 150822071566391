<template>
    <meetings-landing
        :dashboard_id="dashboard_id"
        @created="refreshMeetings"
        @attached="refreshMeetings"
    />
</template>

<script>
    import { mapGetters } from 'vuex'
    import MeetingsLanding from "../../meetings/index"
    export default {
        name: 'Meetings',

        components: {
            MeetingsLanding
        },

        props: {
            reload: { type: Boolean, default: false }
        },

        data() {
            return {
                attaching: false,
                meetings: null,
                collectionData: [],
                dashboard_id: null
            }
        },

        methods: {
            onLoad() {
                this.meetings = null
                this.dashboard_id = null
                this.collectionData = []
                if(this.$route.params.id) {
                    this.dashboard_id = parseInt(this.$route.params.id.split(':')[1])
                    this.$axios.get('dashboardMeeting.php?action=get&dashboard_id=' + this.dashboard_id)
                        .then(response => {
                            this.collectionData = response.data.dashboard_meetings
                        })
                }
            },
            refreshMeetings() {
                this.onLoad()
            }
        },

        watch: {
            collectionData(v) {
                if(v) {
                    this.meetings = v
                }
            },
            $route(to, from) {
                this.onLoad()
            }
        },

        mounted() {
            this.onLoad()
        }
    }
</script>