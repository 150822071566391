<template>
    <div>
        <div v-if="!valid" class="body-2 text-xs-center pa-4">
            <v-icon>info</v-icon>
            Intervention Fields require a student and intervention to be attached to the smartFORM
        </div>

        <div v-else class="ma-4">
            <div v-for="(plan, i) in formInstance.interventions" :key="`interventionblock-`+i">
                <div class="modal-title mb-2" style="font-size: 18px;">Intervention - {{plan.plan_name}}</div>
                <span style="font-size: 12px;" v-html="plan.intervention_plan_statement"/>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'InterventionBlock',
        props: ['formInstance'],
        computed: {
            valid() {
                return this.formInstance?.interventions
            },
            interventionId() {
                return this.valid && this.formInstance.linked_intervention
            },
            studentId() {
                return this.valid && this.formInstance.first_student_id
            }
        },
        mounted() {},
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
