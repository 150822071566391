import Api from '../services/Api'
import ServiceMap from './Map'

export default {
    config(opts = {}) {
        let cfg = ServiceMap.default
        if (opts && opts['type'] && ServiceMap[opts['type']]) {
            cfg = Object.assign({}, ServiceMap.default, ServiceMap[opts.type])
        }
        return Object.assign({}, cfg, opts)
    },

    path(opts = {}) {
        return this.config(opts).path
    },

    fetch(params = {}, opts = {}) {
        let merged = Object.assign({}, {action: this.config(opts).fetchVerb}, params)
        return Api().get(this.path(opts), {params: merged})
    },

    update(params = {}, data = {}, opts = {}) {
        let merged = Object.assign({}, {action: this.config(opts).updateVerb}, params)
        return Api().post(this.path(opts), data, {params: merged})
    },

    add(params = {}, data = {}, opts = {}) {
        let merged = Object.assign({}, {action: this.config(opts).createVerb}, params)
        return Api().post(this.path(opts), data, {params: merged})
    },

    remove(params = {}, data = {}, opts = {}) {
        let merged = Object.assign({}, {action: this.config(opts).deleteVerb}, params)
        return Api().post(this.path(opts), data, {params: merged})
    }
}
