<template>
    <div :style="{paddingBottom: isFullForm ? '100px' : '0px'}">
        <div class="d-flex" v-if="canAddAdditionalMonitors">
            <div class="section-title pt-3">Measures</div>
            <fe-btn class="ml-auto" usage="tertiary" @click="addMonitor(false)">New Measure</fe-btn>
        </div>

        <div class="d-flex mb-3 flex-wrap" v-if="showTabs">
            <div v-for="(tab, i) in monitors" @click="activeTab=i"
                class="flex-shrink-1 monitor-tab my-1"
                :class="{'monitor-tab-active': i == activeTab}"
                :key="`monitor-`+i"
            >
                Measure {{i+1}}
            </div>
        </div>

        <fe-dialog
            v-if="studentMonitorDialog.show"
            data-test="link-student-monitors"
            title="Student Monitors"
            v-model="studentMonitorDialog.show"
            @close="studentMonitorDialog.show=false"
            :acceptButtonDisabled="studentMonitorDialog.records.length===0"
            dismissButtonText="Cancel"
            @accept="setMerge"
            width="90%"
            persistent
        >
            <student-monitor-list v-model="studentMonitorDialog.records" :params="studentMonitorDialog.params"/>
        </fe-dialog>


        <v-form v-for="(monitor, i) in monitors" v-show="activeTab == i" v-model="validForm[i]" :key="`monitor-cfg-${i}`" ref="monitor-cfg">

            <div v-if="operation === 'edit-monitoring-user'" class="mb-5">The monitoring user measures progress with the selected monitor. Only the monitoring user and interventionists can enter scores.</div>

            <div v-if="isFullForm || operation === 'edit-monitoring-user'">
                <fe-label>Monitoring User</fe-label>
                <fe-remote-combo
                    data-test="form-monitoring-user"
                    :url="$models.getUrl('user', 'read') + '&active=1'"
                    itemText="user_full_name"
                    itemValue="id"
                    v-model="monitor.monitor_user_id"
                    byId
                    :rules="$fieldValidators('select', 'Monitor user', { required: true })"
                />
            </div>

            <div v-if="operation === 'edit-monitoring-type'" class="mb-5">If you change the measure type, any prior scores for the original measure will continue to appear on Rate of Improvement (ROI) reports. If necessary, scores will be translated based on the new probe. To ensure reporting validity, this change should only be made if the original type selected was done in error.</div>

            <div v-if="isFullForm || operation === 'edit-monitoring-type'">
                <fe-label>Monitoring Type</fe-label>
                <fe-remote-combo
                    data-test="form-monitoring-type"
                    :url="monitoringTypeURL[i]"
                    itemText="display_name"
                    itemValue="id"
                    :clearable="!forceMonitoringTypeId"
                    :readonly="operation === 'add' && !!forceMonitoringTypeId"
                    rootProperty="subcategories"
                    v-model="monitor.sub_category_id"
                    @change="getDeps(monitor.sub_category_id, i)"
                    :rules="$fieldValidators('select', 'Monitor', { required: true })"
                >
                    <template slot="item" slot-scope="data">
                        <v-list-item-content>
                            <v-list-item-title v-html="data.item.display_name" />
                            <v-list-item-subtitle>
                                    {{data.item.data_point_type_name}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </fe-remote-combo>
            </div>

            <div v-if="isFullForm">
                <v-checkbox
                    data-test="form-primary-monitor"
                    label="Primary measures will be used in all ROI reporting for this intervention"
                    v-model="monitor.primary_flag"
                    @change="setPrimary(monitor, i)"
                />
            </div>

            <div v-if="isFullForm" class="section-title mb-2">Date and Time</div>

            <div v-if="operation === 'edit-dates'" class="mb-5">Enter the anticipated start and end dates for progress monitoring. These dates affect the ROI reports and will be added to the Monitoring User and Primary Interventionist&rsquo;s eduCLIMBER calendars.</div>

            <div v-if="isFullForm || operation === 'edit-dates'" class="d-flex">
                <fe-date-picker
                    data-test="form-start-date"
                    label="Start Date"
                    :rules="$fieldValidators('text', 'Start date', { required: true })"
                    v-model="monitor.start_date"
                    class="mr-2 flex-grow-1"
                    dateFormat="MM/DD/YYYY"
                    @input="$refs['monitor-cfg'][i].validate()"
                />
                <fe-date-picker
                    :ref="`monitorEndDate${i}`"
                    data-test="form-end-date"
                    label="End Date"
                    :rules="[v => !$dayjs(v).isBefore(monitor.start_date) || 'Not a valid End Date'].concat($fieldValidators('text', 'End date', { required: true }))"
                    v-model="monitor.end_date"
                    class="flex-grow-1"
                    dateFormat="MM/DD/YYYY"
                    @input="$refs['monitor-cfg'][i].validate()"
                />
            </div>

            <div v-if="operation === 'edit-times'" class="mb-3">Set a schedule for when students are assessed using the measure.</div>

            <div v-if="isFullForm || operation === 'edit-times'" class="d-flex">
                <div class="pr-2 flex-grow-1">
                    <fe-label>Start Time</fe-label>
                    <fe-time-scroll-picker
                        data-test="form-start-time"
                        v-model="monitor.start_time"
                        @input="(v) => {$refs['monitor-cfg'][i].validate(); setEndTime(v, monitor)}"
                    />
                </div>

                <div class="flex-grow-1">
                    <fe-label>End Time</fe-label>
                    <fe-time-scroll-picker
                        data-test="form-end-time"
                        v-model="monitor.end_time"
                        :rules="[v => monitor.start_time && v && !$dayjs('2000-01-01 ' + v).isBefore('2000-01-01 ' + monitor.start_time) || 'Not a valid End Time']"
                        @input="$refs['monitor-cfg'][i].validate()"
                    />
                </div>
            </div>

            <div v-if="isFullForm || operation === 'edit-times'">
                <fe-label>Every number of weeks</fe-label>
                <v-text-field
                    data-test="form-number-of-weeks"
                    flat solo dense
                    type="number"
                    v-model="monitor.monitor_frequency_cnt"
                    :rules="$fieldValidators('number', 'Number of weeks', { min: 1, max: 52, required: true })"
                />
            </div>

            <div v-if="isFullForm || operation === 'edit-times'">
                <weekday-picker :required="true" data-test="form-weekday-picker" v-model="monitor.monitor_week_days"/>
            </div>

            <div v-if="isFullForm" class="section-title mt-5 mb-1">Students</div>
            <div v-if="isFullForm">
                <fe-grid
                    v-if="monitor.sub_category_id && columnHeaders[monitor.sub_category_id.id]"
                    data-test="form-student-goals"
                    :ref="`monitor-${i}-grid-`+monitor.sub_category_id.id"
                    :showToolbar="false"
                    :columnDefs="columnHeaders[monitor.sub_category_id.id]"
                    :rowData="monitor.students"
                    displayDensity="medium"
                    style="min-height: 100px;"
                    domLayout="autoHeight"
                    stopEditingWhenCellsLoseFocus
                    @cellValueChanged="updateStudentValue(i, $event)"
                />
                <p v-else>
                    Please select a Monitoring Type.
                </p>
            </div>

            <div class="mt-2" v-if="isFullForm && monitor.mergedMonitors && monitor.mergedMonitors.length">
                <v-checkbox v-for="m in monitor.mergedMonitors" v-model="m.merge" :label="`Merge score for `+m.student_full_name"/>
            </div>

            <div v-if="isFullForm && canAddAdditionalMonitors">
                <v-btn outlined
                    color="red"
                    style="border: 1px solid red; width: 100%; margin: 8px 0 0 0 !important;"
                    @click="monitors.splice(i, 1); validForm.splice(i, 1); activeTab=i-1"
                >Remove Measure</v-btn>
            </div>
        </v-form>

        <div class="text-right mt-3" v-if="canAddAdditionalMonitors">
            <fe-btn @click="$emit('add')" :disabled="!isValid">Add</fe-btn>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import WeekdayPicker from '@/components/common/button/WeekdayPicker'
import StudentMonitorList from '../StudentMonitorList'
import timeMixin from './mixins/time'

export default {
        name: 'AcademicMonitorSchedule',

        components: {
            WeekdayPicker,
            StudentMonitorList
        },

        mixins: [timeMixin],

        props: {
            value: {
                type: Array,
                default() { return [] }
            },
            students: {
                type: Array,
                default() { return [] }
            },
            standalone: {
                type: Boolean,
                default: false
            },
            forceMonitoringTypeId: {
                type: Number,
                required: false,
            },
            fullModel: {},
            operation: {
                default: 'edit',
                validator(value, props) {
                    return ['add', 'edit', 'edit-monitoring-type', 'edit-monitoring-user', 'edit-dates', 'edit-times'].includes(value)
                },
            }
        },

        data() {
            return {
                grades: [],
                alphaMaps: {},
                monitors: [],
                validForm: [],
                activeTab: 0,
                dates: [],
                loaded: false,
                columnHeaders: {},
                dateRangeMenu: false,
                showGrid: false,
                studentMonitorDialog: {
                    show: false,
                    params: null,
                    records: [],
                    monitor: null
                },
                testStudents: [],
            }
        },

        computed: {
            ...mapState('global', ['sessionUser', 'currentYear']),
            ...mapState('intervention', ['categoryId']),
            isValid() {
                let valid = true

                if (!this.monitors) return false

                this.monitors.forEach(mon => {
                    if (valid) {
                        // Only do this if we are still valid
                        valid = this.$checkAllKeys(mon, ['monitor_user_id', 'sub_category_id', 'monitor_week_days', 'students'], true)
                    }
                })

                // If any of the validForm elems is not valid, the entire form cannot be valid
                if (this.validForm.filter(itm => !itm).length > 0) {
                    valid = false
                }

                return valid
            },
            // Create a URL specific to the selected students, category, and monitor sub_category_id
            monitoringTypeURL() {
                const stuIDs = this.$props.students.map(stu => stu.student_id).join(',')
                return this.monitors.map(monitor => this.$models.getUrl('academicMonitor', 'read') + `&school_date=${(monitor.start_date+'').replace(/^(\d+)\/(\d+)\/(\d+)$/, '$3-$1-$2')}&school_student_id=${stuIDs}&category_id=${this.categoryId}&sub_category_id=${monitor.sub_category_id?.id || ''}`)
            },
            getSchoolId() {
                // school_id from add measure popup || school_id new intervention creation process
                return this.students[0]?.school_id || this.$store.state.intervention.school.id
            },
            canAddAdditionalMonitors() {
                // if not standalone mode where user is already adding a new monitor, then user is viewing all monitors and should be able to
                // add/delete them
                return !this.standalone
            },
            showTabs() {
                // show tabs only if user can add additional monitors or there are multiple monitors already
                return this.canAddAdditionalMonitors || this.monitors.length > 1
            },
            isFullForm() {
                return this.operation === 'add' || this.operation === 'edit'
            },
        },

        watch: {
            validForm: {
                deep: true,
                handler(forms) {
                    const isValid = forms.every(isValid => isValid === true)
                    this.$emit('validityChanged', isValid)
                }
            },
            monitors: {
                deep: true,
                handler(v) {
                    this.$emit('input', v)
                }
            },
            activeTab (v) {
                this.getDeps(this.monitors[v].sub_category_id, v)
            }
        },

        mounted() {
            this.monitors = this.$_.cloneDeep(this.value)

            if (this.monitors.length === 0 || this.operation === 'add') {
                this.loaded = true
                this.addMonitor(true)
            }

            // load grades
            if (this.isFullForm) {
                this.$modelGet('grade')
                    .then((response) => {
                        this.grades = response

                        // get deps if we are editing an existing measure
                        if (this.monitors.length) {
                            this.getDeps(this.monitors[0].sub_category_id, 0)

                            // add any previously selected grades the user might not have access to
                            this.monitors.forEach(monitor => {
                                monitor.students.forEach(student => {
                                    if (!student.assessment_grade_id) {
                                        return
                                    }
                                    const grade = this.grades.find(grade => grade.id == student.assessment_grade_id)
                                    if (!grade) {
                                        this.grades.push({
                                            id: student.assessment_grade_id,
                                            name: student.assessment_grade_desc,
                                            rank: student.assessment_grade_rank,
                                        })
                                    }
                                })
                            })
                        }
                    })
                }
        },

        methods: {
            setMerge() {
                let index = this.studentMonitorDialog.monitor
                this.monitors[index].mergedMonitors = []
                this.studentMonitorDialog.records.forEach(s => {
                    this.monitors[index].mergedMonitors.push({
                        student_id: s.student_id,
                        student_full_name: s.student_full_name,
                        student_monitor_plan_id: s.student_monitor_plan_id,
                        merge: true
                    })
                })

            },

            setEndTime(startTime, monitor) {
                if (!this.loaded) {
                    return
                }
                monitor.end_time = this.getEndTime(startTime, monitor.end_time)
            },

            setPrimary(monitor, index) {
                if (monitor.primary_flag) {
                    this.monitors.forEach((mon,i) => {
                        if (i !== index) mon.primary_flag = false
                    })
                }
            },

            getDeps(monitor, index) {
                let me = this

                if (!monitor) {
                    return
                }

                // allow to merge unassigned monitors only for new monitors
                const allowToMergeUnassignedMonitors = this.monitors[index] && !this.monitors[index].schedule_id

                if (monitor && monitor.category_id) this.monitors[index].monitor_category_id = monitor.category_id

                let lookup = {
                    student_id: this.students.map(r=>r.student_id),
                    unassigned: 1,
                    sub_category_id: monitor.id
                }

                // check if the student has any unassigned monitors
                if (lookup.student_id.length > 0 && allowToMergeUnassignedMonitors) {
                    this.$axios.get('studentMonitors.php?action=get&'+this.$objectToParams(lookup))
                        .then(response => {
                            let unassigned = this.$ecResponse(response, 'monitors')

                            if (unassigned.length>0) {
                                this.$messageBox({
                                    title: 'Existing Monitors',
                                    persistent: true,
                                    message:
                                        `Students in this intervention have probes assigned to them that are not attached to an
                                         intervention.  You have the option to have these scores merged into this intervention. <br/>
                                         View now?`,
                                    maxWidth: '500',
                                    actions: [{
                                        text: 'Cancel', usage: 'ghost'
                                    }, {
                                        text: 'View', primary: true,
                                        onClick() {
                                            me.studentMonitorDialog.show=true
                                            me.studentMonitorDialog.monitor=index
                                            me.studentMonitorDialog.records = []
                                            me.studentMonitorDialog.params = {
                                                student_id: me.students.map(r=>r.student_id),
                                                sub_category_id: monitor.id,
                                                unassigned: 1
                                            }
                                        }
                                    }]
                                })
                            }
                        }).finally(() => {
                            this.loaded = true
                        })
                } else {
                    this.loaded = true
                }

                this.$modelGet('alphaMap', {sub_category_id: monitor.id })
                    .then((response) => {
                        this.alphaMaps[monitor.id] = response
                        let goal = {
                            headerName: 'Goal',
                            field: !this.$parent.fbMonitorAvailable ? 'value' : 'end_goal',
                            editable: true
                        }

                        if (response.length > 0) {
                            goal.cellRendererFramework = 'FeGridChipSelect'
                            goal.cellRendererParams = {
                                rowDataKey: 'goal',
                                mode: 'render',
                                multiple: false,
                                items: this.alphaMaps[monitor.id],
                                keyProp: 'numeric_score',
                                labelProp: 'alpha_score'
                            }
                            goal.cellEditorFramework = 'FeGridChipSelect'
                            goal.cellEditorParams = {
                                rowDataKey: 'goal',
                                mode: 'edit',
                                multiple: false,
                                items: this.alphaMaps[monitor.id],
                                keyProp: 'numeric_score',
                                labelProp: 'alpha_score'
                            }
                        }

                        let headers = [{
                            headerName: 'Student',
                            field: 'student_full_name'
                        }, goal, {
                            headerName: 'Grade',
                            field: 'assessment_grade_id',
                            editable: true,
                            valueFormatter: v => this.findLabel(me.grades, v.data.assessment_grade_id, 'id', 'name'),
                            cellEditorFramework: "FeGridChipSelect",
                            cellEditorParams: v => {
                                return {
                                    value: v.data.grade_level,
                                    rowDataKey: 'assessment_grade_id',
                                    mode: "edit",
                                    multiple: false,
                                    items: me.grades,
                                    labelProp: 'name',
                                    keyProp: 'id',
                                    disableChips: true
                                }
                            },
                        }]

                        this.$set(me.columnHeaders, monitor.id, headers)
                    })
                    .finally(() => { })
            },

            updateStudentValue(index, params) {
                this.monitors = this.monitors.map((monitor, i) => {
                    if (i === index) monitor.students[params.rowIndex][params.colDef.field] = params.newValue
                    return monitor
                })
            },

            async addMonitor(primary) {
                if (this.monitors.length === 0) primary = true
                else this.activeTab = this.monitors.length

                let subcategory = null
                let students = this.students.map(itm => Object.assign({}, itm))

                if (this.forceMonitoringTypeId) {
                    let rec = await this.$axios.get(`subcategories.php?action=get&id=${this.forceMonitoringTypeId}`).then(res => res.data);
                    subcategory = rec.subcategories[0]
                }

                // If FastBridge PM...
                if (this.$parent.fbMonitorAvailable) {
                    // get subcategory (monitor type) using supplied id
                    if (this.$parent.fbMonitorType) {
                        let rec = await this.$axios.get(`subcategories.php?action=get&id=${this.$parent.fbMonitorType}`).then(res => res.data);
                        subcategory = rec.subcategories[0]
                    }
                    // get students
                    if (this.$parent.fbMonitorStudents) {
                        students = this.$parent.fbMonitorStudents.map(itm => Object.assign({}, itm))
                    }
                }

                this.monitors.push({
                    monitor_user_id: this.operation === 'edit-monitoring-user' ? null : this.sessionUser.user.id,
                    sub_category_id: subcategory,
                    monitor_week_days: "",
                    students: students,
                    primary_flag: this.standalone ? false : primary,
                    start_date: this.fullModel?.schedule?.start_date,
                    end_date: this.fullModel?.schedule?.end_date,
                    start_time: null,
                    end_time: null,
                    showGrid: false,
                    mergedMonitors: [],
                    schedule: {}
                })

                // If FastBridge PM, trigger function to show student grid
                if (subcategory) {
                    this.getDeps(subcategory, 0)
                }
            },

            findLabel(collection, value, valueProp, labelProp) {
                let lbl = ''
                if (collection && value) {
                    let sel = collection.find(x => x[valueProp] === value)
                    if (sel) lbl = sel[labelProp]
                }
                return lbl
            }
        }
    }
</script>

<style lang="scss" scoped>
.monitor-tab {
    font-size: 14px;
    line-height: 14px;
    margin-right: 8px;
    cursor: pointer;
    color: #B3B7C5;
    padding-bottom: 4px;

    &-active {
        color: #366195;
        border-bottom: 2px solid #366195;
    }
}
</style>
