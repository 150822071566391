<template>
    <fe-dialog
        :title="title"
        width=600
        v-if="value"
        @dismiss="$emit('input',false)"
        @close="$emit('input',false)"
        persist
    >
        <div class="flex-grow-1 scroll-container">
            <div v-if="unreadCount" class="new-h-line mb-4">
                <div class="mx-4">New</div>
            </div>
            <template v-for="(item,i) in items">
                <v-lazy>
                    <div :key="item.id" class="pa-3 item mb-10" :class="{'new-note':i<unreadCount}">
                        <div class="d-flex align-center bborder mb-3">
                            <div class="d-inline-flex section-title">
                                {{item.title}}
                            </div>
                            <div class="ml-auto secondary-text fs12 flex-shrink-0 pl-2">
                                {{item.formattedCreated}}
                            </div>
                        </div>
                        <div v-html="item.message" />
                    </div>
                </v-lazy>
            </template>
            <div class="d-flex align pr-4 bcolor" style="height:22px;">
                <div v-if="!allLoaded"
                    class="d-flex link mr-auto ml-auto align-self-center"
                    @click="getUpdates()"
                >
                    load older updates
                </div>
            </div>
        </div>

        <template #footer>
            <fe-btn class="mp-0 ml-auto" usage="ghost" @click="$emit('input',false)">Close</fe-btn>
        </template>

    </fe-dialog>
</template>

<script>
    export default {
        name: 'UpdateNotifications',
        props: ['value','lastDate','unreadCount'],
        computed: {
            title() {
                return "eduCLIMBER Updates"
            },
        },
        data() {
            return {
                index: 0,
                items: [],
                allLoaded: false,
            }
        },
        watch: {
            async value(v) {
                if(v) {
                    this.allLoaded = false
                    this.getUpdates(this.$dayjs().subtract(1,'year').format('YYYY-MM-DD'))
                }
                if(!v) this.updateTimestamp()
            },
        },
        methods: {
            getUpdates(fromDate=null) {
                if(!fromDate) {
                    fromDate = '2014-01-01'
                    this.allLoaded = true
                }
                this.$axios.post(`users.php?action=notifications&from_date=${fromDate}`).then(response => {
                    if (response.data && response.data.notifications) {
                        response.data.notifications.forEach( x => {
                            let date = this.$dayjs(x.created)
                            x.formattedCreated = date.format('MMM D, YYYY')
                            x.unixTime = date.format('x')
                        })
                        this.items = response.data.notifications.sort((a,b) => b.id - a.id)
                    }
                })
            },
            updateTimestamp() {
                this.$emit('read')
                this.$axios.get('users.php?action=notification_timestamp')
            }
        }
    }
</script>

<style lang="scss" scoped>
.scroll-container {
    overflow:auto;
    // border-bottom: 1px solid #ECEDF1;
    // border-top: 1px solid #ECEDF1;
}
.item {
    // &:hover {
    //     background-color: var(--v-primary-lighten2);
    // }
}
.bborder {
    border-bottom: 1px solid #ECEDF1;
}
.notification-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.action {
    min-height: 38px;
}
.link {
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
    color: var(--v-primary-base);
}
.new-note {
    background-color: #CCE2E91A;
}
.new-h-line {
    color: #006C90;
    display: flex;
    flex-direction: row;
    &::before, &::after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid;
        margin: auto;
    }

}
</style>
