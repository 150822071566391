<template>
    <div class="flex-fill flex-column sidebar mt-0" :style="{width: collapsed ? '41px' : '300px'}">
        <div v-show="collapsed" class="page-title rotated">Form Information</div>
        <div v-if="!collapsed" style="width:100%;">
            <div class="ec-section-title" style="margin-top: 0px;" :class="{'d-flex': !collapsed}">
                <div class="mt-2" :class="{rotated: collapsed}">Form Information</div>
            </div>
            <v-tabs
                v-model="selectedTab"
                class="form-tabs"
                height="48px"
            >
                <v-tab>Field Activity</v-tab>
                <v-tab>Comments</v-tab>
                <v-tab>Subscribers</v-tab>
            </v-tabs>
        </div>

        <div v-if="!collapsed" style="height:100%; overflow:auto;">
            <v-slide-x-transition mode="out-in">
                <div v-if="selectedTab==0" key="activity" >
                    <div v-for="(r,i) in responses" :key="`resp`+i" class="form-reply" @mouseenter="$emit('highlightField', r)">
                        <div>{{r.user}} edit {{ r.field }}</div>
                        <div style="color: #7e8494;">{{r.date}}</div>
                    </div>
                </div>
                <div v-if="selectedTab==1" key="comments" >
                    <message  dense :params="{ form_instance_id: $props.rec.form_instance_id }" :allowPrivate="false"/>
                </div>
                <div v-if="selectedTab==2" key="subscribers" >
                    <div style="font-size: 10px;" class="mb-4">
                        The following users are subscribed to updates on this form.  When you are done making changes
                        to this form, select the users and click <b>Notify</b> to get updated.
                    </div>
                    <v-list>
                        <div class="d-flex" v-for="(s,i) in subscribers" :key="`subscriber-`+i">
                            <div>
                                <v-checkbox
                                    v-model="s.notify"
                                    color="primary"
                                ></v-checkbox>
                            </div>

                            <div>
                                <div>{{s.user_full_name}}</div>
                                <div class="caption" style="color: #7e8494;">{{s.user_email_addr}}</div>
                            </div>
                        </div>
                    </v-list>
                    <div class="text-right">
                        <fe-btn usage="tertiary" :disabled="subscribers.filter(r => r.notify).length === 0" @click="sendNotification">Notify</fe-btn>
                    </div>
                </div>
            </v-slide-x-transition>

        </div>

        <v-btn
            color="primary"
            fab dark small depressed
            class="collapse-btn"
            :style="{right: collapsed ? '30px' : '320px'}"
            @click="collapsed = !collapsed"
        >
            <v-icon size=14>{{ !collapsed ? 'fas fa-chevron-right' : 'fas fa-chevron-left'}}</v-icon>
        </v-btn>

    </div>
</template>

<script>
    import Message from '@/components/common/Message'
    export default {
        name: 'SmartformSidebar',
        components: {
            Message,
        },
        props: {
            rec: {
                type: Object,
                required: true
            },
        },
        computed: {
            responses() {
                let arr = []
                this.$props.rec.form_instance_sections.forEach(sec => {
                    sec.form_instance_responses.forEach(resp => {
                        if (resp.value && resp.form_instance_response_id) {
                            let u = resp.form_instance_response_created_by_full_name.split(',')
                            arr.push({
                                user: u[1] + ' ' + u[0],
                                field: resp.form_field_name,
                                date: resp.form_instance_response_created,
                                form_field_id: resp.form_field_id
                            })
                        }
                    })
                })
                return arr
            }
        },
        mounted() {
            this.$modelGet('formSubscriber', { form_id: this.$props.rec.form_id })
                .then(r => this.subscribers = r)
        },
        data() {
            return {
                collapsed: false,
                selectedTab: 0,
                subscribers: [],
            }
        },
        methods: {
            sendNotification() {
                let ids = []
                this.subscribers.forEach(r => {
                    if (r.notify) ids.push(r.user_id)
                })

                this.$setLoading(true)
                this.$axios.post('formResponse.php?action=notify_subscribers&form_instance_id=' + this.$props.rec.form_instance_id, {
                    user_id: ids
                }).then(response => {
                    this.$ecResponse(response)
                })
                .finally(() => {
                    this.$setLoading(false)
                    this.subscribers.forEach(r => { r.notify = false })
                })

            },
            showNotify() {
                this.selectedTab = 2
            }
        }
    }
</script>

<style lang="scss" scoped>
.sidebar {
    transition: width 300ms;
    border-left: 1px solid #ECEDF1;
    padding-left: 16px;
    overflow: hidden;
}

.collapse-btn {
    position: fixed;
    bottom: 40px;
    border-radius: 50% !important;
    width: 32px;
    height: 32px;
}

.rotated {
    padding-top: 12px;
    white-space: nowrap;
    transform: rotate(90deg);
}

.form-reply {
    border: 1px solid #ECEDF1;
    border-radius: 4px;
    margin-right: 8px;
    padding: 6px 8px;
    font-size: 12px;
    font-weight: regular;
    margin-bottom: 8px;

    div {
        padding: 2px;
    }
}
.form-tabs ::v-deep {
    // .v-tabs-bar__content {
    //     width:100%;
    // }
    // height: 48px;
    .v-slide-group__prev { display: none }
    .v-slide-group__next { display: none }
    .v-slide-group__wrapper {
        height: 36px;
    }
    .v-tab {
        text-transform: none;
        min-width: 0;
        font-size: 12px;
        padding: 5px 8px 4px 8px;
    }
    div.v-slide-group__content.v-tabs-bar__content {
        transform: translateX(0) !important;
    }
    // .v-window__container {
    //     height: 100%;
    // }
    // .v-window-item {
    //     height: 100%;
    // }

}
</style>
