<template>
    <div class="d-flex" v-if="tabs.length">
        <p class="tab my-2" v-for="(c, i) in tabs" @click="setTab(c)" :class="{'tab-active': localValue.name == c.name }" :key="`tabs-`+i">{{c.name}}</p>
    </div>
</template>

<script>
    export default {
        name: 'Tabs',
        props: {
            tabs: {
                type: Array,
                default: () => []
            },
            value: {

            }
        },
        watch: {
            localValue(v) {
                this.$emit('input', v)
            }
        },
        data() {
            return {
                localValue: {}
            }
        },
        mounted() {
            this.localValue = this.$props.value

            if (!this.$props.value && this.$props.tabs.length > 0) this.localValue = this.$props.tabs[0]
        },
        methods: {
            setTab(t) {
                this.localValue = t
            }
        }
    }
</script>

<style lang="scss" scoped>
.tab {
    text-transform: none !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 5px 8px 4px 8px;
    color: #B3B7C5;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                            supported by Chrome, Opera and Firefox */

    &:hover {
        font-weight: bold;
        background-color: #F5F6F8;
    }

    &-active {
        color: var(--v-primary-base) !important;
        border-bottom: 2px solid var(--v-primary-base);
    }
}
</style>
