<template>
    <fe-dialog
        v-if="active"
        @dismiss="active = false"
        @close="active = false"
        @accept="create"
        title="Add Windows"
        acceptButtonText="Save"
        dismissButtonText="Cancel"
        :acceptButtonDisabled="!valid"
        persistent
    >
        <v-form @submit.prevent="valid ? create() : () => {}">
            <div class="d-flex flex-column">
                <fe-label>Number of Windows</fe-label>
                <v-text-field
                    autofocus
                    type="number"
                    v-model="numberOfWindows"
                    flat solo dense
                    :style="{ width: '135px' }"
                    :rules="windowRules"
                    ref="numberOfWindows"
                    min="1"
                />
                <div>
                    <draggable v-model="objs">
                        <div v-for="(window, index) in objs" :key="`window-`+index">
                            <v-text-field
                                v-model="window.name"
                                flat solo dense validateOnBlur
                                :style="{ width: '100%' }"
                                :rules="rules.name"
                                prepend-icon="drag_indicator"
                            />
                        </div>
                    </draggable>
                </div>
            </div>
        </v-form>
    </fe-dialog>
</template>

<script>
    import draggable from 'vuedraggable';
    import Vue from 'vue';

    export default {
        name: 'WindowCreator',
        components: {
            draggable,
        },
        props: {
            group: { type: Object, default: () => {} }
        },
        data () {
            return {
                assessments: null,
                active: false,
                objs: [{
                    name: null,
                    alias: null,
                    hidden: false,
                    start_day: null,
                    end_day: null,
                    store_as_data_point_name_id: null,
                    import_alias: "",
                    norms_data_point_name_id: null,
                    rank: null
                }],
                rules: {
                    name: [
                        v => (!!v && v.trim().length > 0) || 'Please enter a name'
                    ]
                },
                errors: [],
                numberOfWindows: 1,
                oldValue: 1,
                windowRules: [
                    v => (!!v && v > 0)
                ],
            }
        },
        computed: {
            valid () {
                let errors = []
                Object.keys(this.rules).forEach(field => {
                    this.rules[field].forEach(rule => {
                        this.objs.forEach(obj => {
                            let test = rule(obj[field])
                            if (test !== true) errors.push(test)
                        })
                    })
                })
                if(!this.$refs?.numberOfWindows?.valid) errors.push(false)
                return errors.length == 0
            }
        },
        methods: {
            reset () {
                this.numberOfWindows = 1
                this.oldValue = 1
                this.objs.splice(0, this.objs.length)
                this.objs.push({
                    name: null,
                    alias: null,
                    hidden: false,
                    start_day: null,
                    end_day: null,
                    store_as_data_point_name_id: null,
                    import_alias: "",
                    norms_data_point_name_id: null,
                    rank: null
                })
            },
            open () {
                this.active = true
                this.$emit('open')
                this.reset()
            },
            close () {
                this.active = false
                this.$emit('close')
            },
            doTest() {

            },
            create () {
                this.objs.forEach(obj => {
                   Object.assign(obj, {
                        data_point_type_id: this.group.id
                    })
                })
                this.$emit('create', this.objs)

                this.active = false
            },
        },
        watch: {
            numberOfWindows(v) {
                let val = parseInt(v)
                if(val < 1 && val.length) {
                    // force value of focused field to be 1
                    this.numberOfWindows = 1
                    this.$refs.numberOfWindows.lazyValue = '1'
                } else {
                    if(val && val !== this.oldValue) {
                        if(val > this.oldValue) {
                            let diff = val - this.oldValue
                            for(let i = 0; i < diff; i++) {
                                this.objs.push({
                                    name: null,
                                    alias: null,
                                    hidden: false,
                                    start_day: null,
                                    end_day: null,
                                    store_as_data_point_name_id: null,
                                    import_alias: "",
                                    norms_data_point_name_id: null,
                                    rank: null
                                })
                            }
                        } else if(this.oldValue !== 1) {
                            let diff = this.oldValue - val
                            this.objs.splice(this.objs.length - diff,diff)
                        }
                        this.oldValue = val
                    }
                }
            }
        }
    }
</script>