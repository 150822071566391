<template>
    <div class="flex-fill flex-column no-scroll">
        <div class="headline mb-5 d-flex">
            Demographics
        </div>

        <div class="mb-1 d-flex">
            <strong>SIS-Imported Demographic Groups</strong>
            <fe-info-tip
                    class="ml-2"
                    tooltip="These demographic fields and their properties are inherited from your SIS."
            />
        </div>
        <fe-grid
                ref="sisgrid"
                :rowData="sisData"
                :columnDefs="columnDefsSis"
                :showDownload="false"
                :showAddRowBtn="false"
                style="height: 100%;"
                class="mb-10"
                displayDensity="small"
                disableInlineCreate
                suppressContextMenu
        >
            <template #cellDialog="{cell}">
                <v-list v-if="cell.colDef.colId === 'toolmenu'">
                    <v-list-item @click="beginEdit(cell.data,'sis')">Edit</v-list-item>
                </v-list>
                <fe-dialog
                    v-else-if="showEditOptions"
                    title="Options"
                    width="600"
                    persistent
                    @close="checkForSameDisplayName(cell.data)"
                    @accept="checkForSameDisplayName(cell.data)"
                    acceptButtonText="Done"
                >
                    <fe-grid
                            style="height: 300px;"
                            ref="optionGrid"
                            :showToolbar="false"
                            :columnDefs="cell.data.id !== -1 ? optionsColumnDefsSis : optionsColumnDefsSisHybrid"
                            :rowData="options.list"
                            :applyColumnDefOrder="true"
                            displayDensity="medium"
                            @cellValueChanged="checkChange"
                    />
                </fe-dialog>
            </template>
        </fe-grid>
        <fe-dialog
            v-if="combineDisplayName.show"
            title="Combine values?"
            width=600
            :footer="false"
            :dismissable="false"
        >
            <p class=mt-5 v-html="combineDisplayName.message"/>
            <div class="d-flex float-right mt-10">
                <fe-btn usage="ghost" @click="goBack">Cancel</fe-btn>
                <fe-btn usage="primary" @click="combineDisplayNames">Combine</fe-btn>
            </div>
        </fe-dialog>

        <div class="mb-1 d-flex">
            <strong>Custom Demographic Groups</strong>
            <fe-info-tip
                    class="ml-2"
                    tooltip="These demographic fields and their properties are created in eduCLIMBER. To link custom demographic fields to students, upload demographic data files in the Upload Manager."
            />
        </div>
        <fe-grid
            ref="customgrid"
            :rowData="customData"
            :columnDefs="columnDefsCustom"
            :showDownload="false"
            style="height: 100%;"
            class="pb-10"
            addRowBtnText="Add Custom Demographic"
            displayDensity="small"
            disableInlineCreate
            suppressContextMenu
            @beginCreate="beginCreate"
        >
            <template #cellDialog="{cell}">
                <v-list v-if="cell.colDef.colId === 'toolmenu'">
                    <v-list-item @click="beginEdit(cell.data,'custom')">Edit</v-list-item>
                    <v-list-item @click="doDelete(cell.data)">Delete</v-list-item>
                </v-list>
                <div v-else-if="cell.colDef.field === 'options' && cell.data.value_type === 'option'">
                    <div class="d-flex justify-space-between align-center pt-3 pr-2">
                        <v-flex grow class="grid-panel-title">Options</v-flex>
                        <v-flex shrink text-xs-right>
                            <v-btn icon small usage="ghost" @click="$refs.customgrid.openMenu = false">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-flex>
                    </div>
                    <fe-grid
                        style="width: 600px; padding: 20px 20px 20px 20px; height: 300px;"
                        ref="optionGrid"
                        :showToolbar="false"
                        :columnDefs="optionsColumnDefs"
                        :rowData="options.list"
                        @updated="updateOption"
                        displayDensity="medium"
                    />
                    <v-layout row class="ml-5 mr-4 mb-3">
                        <v-text-field
                            v-model="options.new"
                            flat solo dense
                            :counter="50"
                            style="margin-top: 6px; margin-right: 18px;"
                            placeholder="Enter an option name"
                        />

                        <fe-btn
                            :disabled="!options.new || options.new.length > 50"
                            @click="saveOption"
                        >
                            Add
                        </fe-btn>
                    </v-layout>
                </div>
            </template>
        </fe-grid>

        <record-dialog
            v-if="dialog.show"
            title="Demographic"
            :value="dialog.value"
            :defaultRec="{
                name: undefined,
                value_type: undefined,
                all_years: true,
                restricted: true,
                display_datawall: true,
                display_smartform: true,
                display_student_profile: false,
                active: dialog.activeAvailable
            }"
            verifyValid
            @create="save"
            @update="save"
            @close="dialog.show = false"
        >
            <template #form="{rec}">
                <fe-label>Demographic Name</fe-label>
                <fe-tooltip
                    v-if="dialog.table === 'sis' && dialog.show === 'edit'"
                    direction="right"
                    caret
                    tooltip="This setting is imported from the SIS and is not editable in eduCLIMBER."
                >
                    <v-text-field
                        v-model="rec.name"
                        flat solo dense disabled
                        :rules="$fieldValidators('text', 'Demographic Name', { required: true, limit: 50 })"
                    />
                </fe-tooltip>
                <v-text-field
                    v-else
                    v-model="rec.name"
                    flat solo dense
                    :rules="$fieldValidators('text', 'Demographic Name', { required: true, limit: 50 })"
                />

                <fe-tooltip
                        v-if="dialog.table === 'sis' && dialog.show === 'edit'"
                        direction="right"
                        caret
                        tooltip="This setting is imported from the SIS and is not editable in eduCLIMBER."
                >
                    <fe-remote-combo
                        v-model="rec.value_type"
                        label="Field Type"
                        :items="valueTypeOptions"
                        :rules="$fieldValidators('select', 'Field Type', {required: true})"
                        required
                        byId
                        disabled
                    />
                </fe-tooltip>
                <fe-remote-combo
                    v-else
                    v-model="rec.value_type"
                    label="Field Type"
                    :items="valueTypeOptions"
                    :rules="$fieldValidators('select', 'Field Type', {required: true})"
                    required
                    byId
                />

                <fe-tooltip
                    v-if="dialog.table === 'sis' && dialog.show === 'edit'"
                    direction="right"
                    caret
                    style="width:45%; opacity: .4;"
                    tooltip="This setting is imported from the SIS and is not editable in eduCLIMBER."
                >
                    <div class="d-flex justify-space-between">
                        <v-checkbox disabled v-model="rec.all_years" label="All School Years"/>
                    </div>
                </fe-tooltip>
                <div v-else class="d-flex justify-space-between">
                    <v-checkbox v-model="rec.all_years" label="All School Years"/>
                </div>

                <fe-tooltip
                    v-if="dialog.table === 'sis' && dialog.show === 'edit' &&
                        (dialog.value.name === 'ELP Level' ||
                        dialog.value.name === 'Ethnicity' ||
                        dialog.value.name === 'Meal Status' ||
                        dialog.value.name === 'Disability' ||
                        dialog.value.name === 'Gender')"
                    direction="right"
                    caret
                    style="width:45%; opacity: .4;"
                    tooltip="This is a system-level setting and is not editable."
                >
                    <div class="d-flex justify-space-between">
                        <v-checkbox disabled v-model="rec.restricted" label="Restricted"/>
                    </div>
                </fe-tooltip>
                <div v-else class="d-flex justify-space-between">
                    <v-checkbox v-model="rec.restricted" label="Restricted"/>
                </div>

                <p v-if="!dialog.activeAvailable" style="font-size: 12px; color: #7e8494;" class="mb-0">
                    <v-icon>fal fa-info-circle</v-icon>
                    The maximum number of demographic fields set to use as chart filter (15) has been reached.
                </p>
            </template>
        </record-dialog>

        <demographic-permissions
            v-if="permissions.show"
            :data="permissions.data"
            :userCrud="$refs.usersCrud"
            :groupCrud="$refs.userGroupsCrud"
            @update="this.load"
            @close="permissions.show = false"
        />

        <fe-crud ref="crud" :config="mainCrudConfig" @read="postRead"/>
        <fe-crud ref="optionsCrud" :config="optionsCrudConfig"/>
        <fe-crud ref="usersCrud" :config="usersCrudConfig"/>
        <fe-crud ref="userGroupsCrud" :config="userGroupsCrudConfig"/>
        <!-- hybrid demo cruds -->
        <fe-crud ref="ellLevelCrud" :config="ellLevelCrudConfig"/>
        <fe-crud ref="mealStatusCrud" :config="mealStatusCrudConfig"/>
        <fe-crud ref="disabilityCrud" :config="disabilityCrudConfig"/>
        <fe-crud ref="ethnicityCrud" :config="ethnicityCrudConfig"/>
        <fe-crud ref="genderCrud" :config="genderCrudConfig"/>
    </div>
</template>

<script>
import RecordDialog from '@/components/common/form/RecordDialog'
import DemographicPermissions from "./dialogs/DemographicPermissions"

export default {
    name: 'CustomDemographics',

    components: {
        RecordDialog,
        DemographicPermissions
    },

    data() {
        return {
            customData: [],
            sisData: [],
            dialog: {
                show: false,
                valid: false,
                value: undefined,
                table: null,
                activeAvailable: false
            },
            options: {
                list: [],
                new: undefined,
                demographic_id: undefined,
                cell: undefined
            },
            optionValues: [],
            displayNames: [],
            duplicateDisplayNames: [],
            ellLevelDisplayNames: [],
            isChanged: false,
            permissions: {
                show: false,
                data: undefined
            },
            showEditOptions: false,
            validUpdate: true,
            columnDefs: [
                {
                    headerName: 'Demographic Name',
                    field: 'name',
                    editable: false
                }, {
                    headerName: 'Field Type',
                    field: 'value_type',
                    editable: false,
                    maxWidth: 100,
                    valueGetter: v => {
                        return this.valueTypeOptions.filter(x => x.id === v.data.value_type)[0]?.name
                    }
                }, {
                    headerName: 'Options',
                    field: 'options',
                    editable: false,
                    maxWidth: 90,
                    valueGetter: v => v.data.options?.length,
                    cellRendererFramework: "FeDialogColumn",
                    onCellClicked: this.showOptions
                }, {
                    headerName: 'All School Years',
                    field: 'all_years',
                    maxWidth: 100,
                    cellStyle: {textAlign: 'center'},
                    cellRenderer: this.checkCol
                }, {
                    headerName: 'Restricted',
                    field: 'restricted',
                    maxWidth: 100,
                    cellStyle: {textAlign: 'center'},
                    cellRenderer: this.checkCol
                }, {
                    headerName: 'Permitted Users',
                    field: 'permitted',
                    maxWidth: 100,
                    cellStyle: {textAlign: 'center'},
                    cellRendererFramework: "FeDialogColumn",
                    onCellClicked: this.managePermissions
                }, {
                    headerName: 'Show on Student Profile',
                    field: 'display_student_profile',
                    maxWidth: 130,
                    cellStyle: {textAlign: 'center'},
                    cellRenderer: this.checkCol
                }, {
                    headerName: 'Show on Data Wall',
                    field: 'display_datawall',
                    maxWidth: 120,
                    cellStyle: {textAlign: 'center'},
                    cellRenderer: this.checkCol
                }, {
                    headerName: 'Show on smartFORMS',
                    field: 'display_smartform',
                    maxWidth: 120,
                    cellStyle: {textAlign: 'center'},
                    cellRenderer: this.checkCol
                }, {
                    headerName: 'Use as Chart Filter',
                    field: 'active',
                    maxWidth: 90,
                    cellRendererFramework: 'FeGridToggle',
                    onCellValueChanged: this.update
                }, {
                    headerName: 'Created By',
                    maxWidth: 150,
                    field: 'created_by_name'
                }, {
                    headerName: 'Created',
                    maxWidth: 120,
                    field: 'created',
                    cellRenderer: v => this.$dayjs(v.value).format('M/D/YYYY')
                }, {
                    colId: 'toolmenu',
                    maxWidth: 50,
                    cellStyle: {textAlign: 'center', cursor: 'pointer'},
                    cellRenderer: () => '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>',
                    onCellClicked: this.onCellClicked
                }
            ],
            valueTypeOptions: [
                {
                    name: 'Text',
                    id: 'string'
                }, {
                    name: 'Number',
                    id: 'number'
                }, {
                    name: 'Date',
                    id: 'date'
                }, {
                    name: 'Yes/No',
                    id: 'boolean'
                }, {
                    name: 'Options',
                    id: 'option'
                }
            ],
            combineDisplayName: {
                show: false,
                message: ''
            },
            optionsColumnDefs: [
                {
                    headerName: 'Option',
                    field: 'value',
                    sortable: false,
                    editable: true,
                    onCellValueChanged: v => this.updateOption(v.data),
                }, {
                    headerName: 'Order',
                    field: 'rank',
                    sortable: false,
                    editable: true,
                    width: 60,
                    cellStyle() {
                        return {
                            'text-align': 'center'
                        }
                    },
                    onCellValueChanged: v => this.updateOption(v.data)
                }, {
                    headerName: 'Active',
                    field: 'active',
                    sortable: false,
                    editable: false,
                    width: 80,
                    cellRendererFramework: 'FeGridToggle',
                    onCellValueChanged: v => this.updateOption(v.data)
                }, {
                    headerName: 'Delete',
                    field: 'delete_option',
                    width: 80,
                    cellRenderer() {
                        return '<i class="fas fa-trash" style="font-size: 12px"></i>'
                    },
                    cellStyle() {
                        return {
                            'cursor': 'pointer',
                            'text-align': 'center'
                        }
                    },
                    onCellClicked: v => {
                        this.deleteOption(v.data)
                    }
                }
            ],
            optionsColumnDefsSis: [
                {
                    headerName: 'Field value',
                    field: 'value',
                    sortable: true,
                    editable: false,
                    flex: 1,
                    onCellValueChanged: v => this.updateOption(v.data),
                },
                {
                    headerName: 'Display name (optional)',
                    headerTooltip: "If given a display name, SIS-imported demographic field values will display as this name in eduCLIMBER; otherwise, the field value will be used. Field values with the same display name will be combined.",
                    headerComponentParams: { template: '<div>Display name <i class="far fa-info-circle"></i></div>' },
                    field: 'display_name',
                    sortable: true,
                    editable: true,
                    flex: 1,
                    onCellValueChanged: v => this.updateOption(v.data, true),
                },
                {
                    headerName: 'Order',
                    field: 'rank',
                    sortable: true,
                    editable: true,
                    width: 80,
                    cellStyle() {
                        return {
                            'text-align': 'center'
                        }
                    },
                    onCellValueChanged: v => this.updateOption(v.data)
                }, {
                    headerName: 'Active',
                    field: 'active',
                    sortable: true,
                    editable: true,
                    width: 80,
                    cellRendererFramework: 'FeGridToggle',
                    onCellValueChanged: v => this.updateOption(v.data)
                },
            ],
            optionsColumnDefsSisHybrid: [
                {
                    headerName: 'Field value',
                    field: 'name',
                    sortable: true,
                    editable: false,
                },
                {
                    headerName: 'Display name (optional)',
                    headerTooltip: "If given a display name, SIS-imported demographic field values will display as this name in eduCLIMBER; otherwise, the field value will be used. Field values with the same display name will be combined.",
                    headerComponentParams: {template: '<div>Display name <i class="far fa-info-circle"></i></div>'},
                    field: 'display_name',
                    sortable: true,
                    editable: true,
                    onCellValueChanged: v => this.updateOption(v.data, true),
                }
            ]
        }
    },

    computed: {
        mainCrudConfig() {
            return this.$_.cloneDeep(this.$models.demographic)
        },

        optionsCrudConfig() {
            return this.$_.cloneDeep(this.$models.demographicOption)
        },

        usersCrudConfig() {
            return this.$_.cloneDeep(this.$models.demographicUser)
        },

        userGroupsCrudConfig() {
            return this.$_.cloneDeep(this.$models.demographicUserGroup)
        },

        activeAvailableCustom() {
            return this.customData.filter(x => x.active).length < 15
        },

        // hybrid demo cruds
        ellLevelCrudConfig() {
            return this.$_.cloneDeep(this.$models.ellLevel)
        },
        mealStatusCrudConfig() {
            return this.$_.cloneDeep(this.$models.mealStatusType)
        },
        disabilityCrudConfig() {
            return this.$_.cloneDeep(this.$models.studentDisabilityType)
        },
        ethnicityCrudConfig() {
            return this.$_.cloneDeep(this.$models.ethnicity)
        },
        genderCrudConfig() {
            return this.$_.cloneDeep(this.$models.gender)
        },

        columnDefsSis() {
            return [{
                headerName: 'Demographic Name',
                field: 'name',
                editable: false
            }, {
                headerName: 'Field Type',
                field: 'value_type',
                editable: false,
                maxWidth: 100,
                valueGetter: v => {
                    return this.valueTypeOptions.filter(x => x.id === v.data.value_type)[0]?.name
                }
            }, {
                headerName: 'Options',
                field: 'options',
                maxWidth: 90,
                cellRendererFramework: "FeDialogColumn",
                cellStyle: v => {
                    return v.data.value_type !== 'option' ? { 'pointer-events': 'none' } : null
                },
                valueGetter: v => v.data.options?.length,
                onCellClicked: v => this.showOptions(v, 'sis')
            }, {
                headerName: 'All School Years',
                field: 'all_years',
                maxWidth: 100,
                cellStyle: {textAlign: 'center'},
                cellRenderer: this.checkCol,
                hide: true
            }, {
                headerName: 'Restricted',
                field: 'restricted',
                maxWidth: 100,
                cellStyle: {textAlign: 'center'},
                cellRenderer: this.checkCol,
                hide: true
            }, {
                headerName: 'Permitted Users',
                field: 'permitted',
                maxWidth: 100,
                cellStyle: v => {
                    return !v.data.restricted ? { 'pointer-events': 'none' } : null
                },
                cellRendererFramework: 'FeDialogColumn',
                onCellClicked: this.managePermissions,
                hide: true
            }, {
                headerName: 'Show on Student Profile',
                field: 'display_student_profile',
                maxWidth: 130,
                cellRendererFramework: 'FeGridToggle',
                onCellValueChanged: v => this.update(v, 'sis'),
                valueGetter: v => {
                    let value = v.data[v.colDef.field]
                    if ((v.data.name === 'Meal Status' || v.data.name === 'Disability') && v.data.upload_id === -1) {
                        value = 0
                    }
                    return value
                },
                cellStyle(v) {
                    if (v.data.upload_id === -1) {
                        return {
                            'opacity': '.4',
                            'pointerEvents': 'none',
                        }
                    }
                }
            }, {
                headerName: 'Show on Data Wall',
                field: 'display_datawall',
                maxWidth: 120,
                cellRendererFramework: 'FeGridToggle',
                onCellValueChanged: v => this.update(v, 'sis'),
                valueGetter: v => {
                    let value = v.data[v.colDef.field]
                    if (v.data.name === 'Meal Status' && v.data.upload_id === -1) {
                        value = 0
                    }
                    return value
                },
                cellStyle(v) {
                    if (v.data.upload_id === -1) {
                        return {
                            'opacity': '.4',
                            'pointerEvents': 'none',
                        }
                    }
                }
            }, {
                headerName: 'Show on smartFORMS',
                field: 'display_smartform',
                maxWidth: 120,
                cellRendererFramework: 'FeGridToggle',
                onCellValueChanged: v => this.update(v, 'sis'),
                valueGetter: v => {
                    let value = v.data[v.colDef.field]
                    if (v.data.name === 'Meal Status' && v.data.upload_id === -1) {
                        value = 0
                    }
                    return value
                },
                cellStyle(v) {
                    if (v.data.upload_id === -1) {
                        return {
                            'opacity': '.4',
                            'pointerEvents': 'none',
                        }
                    }
                }
            }, {
                headerName: 'Use as Chart Filter',
                field: 'active',
                maxWidth: 90,
                cellRendererFramework: 'FeGridToggle',
                onCellValueChanged: v => this.update(v, 'sis'),
                cellStyle(v) {
                    if (v.data.upload_id === -1) {
                        return {
                            'opacity': '.4',
                            'pointerEvents': 'none',
                        }
                    }
                }
            }, {
                headerName: 'Created',
                maxWidth: 120,
                field: 'created',
                cellRenderer: v => this.$dayjs(v.value).format('M/D/YYYY'),
                hide: true
            }, {
                colId: 'toolmenu',
                maxWidth: 50,
                cellStyle: {textAlign: 'center', cursor: 'pointer'},
                cellRenderer: () => '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>',
                onCellClicked: (v) => this.onCellClickedSis(v)
            }]
        },

        columnDefsCustom() {
            return [{
                headerName: 'Demographic Name',
                field: 'name',
                editable: false
            }, {
                headerName: 'Field Type',
                field: 'value_type',
                editable: false,
                maxWidth: 100,
                valueGetter: v => {
                    return this.valueTypeOptions.filter(x => x.id === v.data.value_type)[0]?.name
                }
            }, {
                headerName: 'Options',
                field: 'options',
                maxWidth: 90,
                cellRendererFramework: "FeDialogColumn",
                cellStyle: v => {
                    return v.data.value_type !== 'option' ? { 'pointer-events': 'none' } : null
                },
                valueGetter: v => v.data.options?.length,
                onCellClicked: v => this.showOptions(v, 'custom')
            }, {
                headerName: 'All School Years',
                field: 'all_years',
                maxWidth: 100,
                cellStyle: {textAlign: 'center'},
                cellRenderer: this.checkCol
            }, {
                headerName: 'Restricted',
                field: 'restricted',
                maxWidth: 100,
                cellStyle: {textAlign: 'center'},
                cellRenderer: this.checkCol
            }, {
                headerName: 'Permitted Users',
                field: 'permitted',
                maxWidth: 100,
                cellStyle: v => {
                    return !v.data.restricted ? { 'pointer-events': 'none' } : null
                },
                cellRendererFramework: 'FeDialogColumn',
                onCellClicked: this.managePermissions,
            }, {
                headerName: 'Show on Student Profile',
                field: 'display_student_profile',
                maxWidth: 130,
                cellRendererFramework: 'FeGridToggle',
                onCellValueChanged: v => this.update(v, 'custom')
            }, {
                headerName: 'Show on Data Wall',
                field: 'display_datawall',
                maxWidth: 120,
                cellRendererFramework: 'FeGridToggle',
                onCellValueChanged: v => this.update(v, 'custom')
            }, {
                headerName: 'Show on smartFORMS',
                field: 'display_smartform',
                maxWidth: 120,
                cellRendererFramework: 'FeGridToggle',
                onCellValueChanged: v => this.update(v, 'custom')
            }, {
                headerName: 'Use as Chart Filter',
                field: 'active',
                maxWidth: 90,
                cellRendererFramework: 'FeGridToggle',
                onCellValueChanged: v => this.update(v, 'custom')
            }, {
                headerName: 'Created',
                maxWidth: 120,
                field: 'created',
                cellRenderer: v => this.$dayjs(v.value).format('M/D/YYYY'),
            }, {
                headerName:'Created By',
                field: 'created_by_name',
                maxWidth: 150
            }, {
                colId: 'toolmenu',
                maxWidth: 50,
                cellStyle: {textAlign: 'center', cursor: 'pointer'},
                cellRenderer: () => '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>',
                onCellClicked: (v) => this.onCellClickedCustom(v)
            }]
        },
    },

    mounted() {
        this.load()
    },

    methods: {
        load() {
            this.$refs.crud.read()
            this.$refs.ellLevelCrud.read().then(r => { this.hybridRead(r.data.ell_level, 'ELP Level') })
            this.$refs.mealStatusCrud.read().then(r => { this.hybridRead(r.data.meal_status, 'Meal Status') })
            this.$refs.disabilityCrud.read().then(r => { this.hybridRead(r.data.student_disability_type, 'Disability') })
            this.$refs.ethnicityCrud.read().then(r => { this.hybridRead(r.data.ethnicity, 'Ethnicity') })
            this.$refs.genderCrud.read().then(r => { this.hybridRead(r.data.gender, 'Gender') })
        },

        postRead(data) {
            let sisTransactions = []
            let customTransactions = []
            let sisData = []
            let customData = []

            data.forEach(x => {
                let params = {demographic_id: x.id}

                if (x.value_type === 'option' && x.upload_id) {
                    sisTransactions.push(this.$refs.optionsCrud.read(params).then(res => {
                        x.options = res.data.demographic_option
                    }))
                } else if (x.value_type === 'option' && !x.upload_id) {
                    customTransactions.push(this.$refs.optionsCrud.read(params).then(res => {
                        x.options = res.data.demographic_option
                    }))
                }

                if (x.restricted && x.upload_id) {
                    x.permitted = 0
                    sisTransactions.push(this.$refs.usersCrud.read(params).then(res => {
                        x.permitted += res.data.demographic_user.length
                    }))
                    sisTransactions.push(this.$refs.userGroupsCrud.read(params).then(res => {
                        x.permitted += res.data.demographic_user_group.length
                    }))
                } else if (x.restricted && !x.upload_id) {
                    x.permitted = 0
                    customTransactions.push(this.$refs.usersCrud.read(params).then(res => {
                        x.permitted += res.data.demographic_user.length
                    }))
                    customTransactions.push(this.$refs.userGroupsCrud.read(params).then(res => {
                        x.permitted += res.data.demographic_user_group.length
                    }))
                }

                // if record has upload_id, add to SIS imported table
                // otherwise, add to custom table
                x.upload_id ? sisData.push(x) : customData.push(x)
            })

            Promise.all(sisTransactions).then(() => {
                this.sisData = this.sisData.concat(sisData)
            })
            Promise.all(customTransactions).then(() => {
                this.customData = customData
            })
        },

        hybridRead(data, hybridType) {
            // add demographic name for processing in other functions
            data.forEach(d => {
                d.name = d.name || d.value || d.description // some use "value" or "description" instead of "name"
                d.hybrid_demo = hybridType
            })

            // add hybrid demographic to sis table
            this.sisData.push({
                id: -1, // indicates hybrid demographic
                upload_id: -1, // indicates hybrid demographic
                name: hybridType,
                restricted: false,
                options: data,
                value_type: 'option',
                value_type_locked: 1,
                display_datawall: 1,
                display_smartform: 1,
                display_student_profile: 1,
                active: 1
            })
        },

        checkCol(v) {
            return v.value ? '<i class="fe-grid-icon fas fa-check theme--light"></i>' : ''
        },

        onCellClickedCustom(v) {
            this.$refs.customgrid.setDialogCell(v)
        },

        onCellClickedSis(v) {
            this.$refs.sisgrid.setDialogCell(v)
        },

        beginCreate() {
            this.dialog.value = undefined
            this.dialog.valid = false
            this.dialog.activeAvailable = this.activeAvailableCustom
            this.dialog.show = 'create'
        },

        beginEdit(data, table) {
            this.options.list = null
            this.dialog.value = data
            this.dialog.table = table
            this.dialog.activeAvailable = data.active || this.activeAvailableCustom
            this.dialog.show = 'edit'
        },

        dismissDialog() {
            this.dialog.show = false
        },

        save(v) {
            let payload = this.$_.cloneDeep(v)
            let action = payload.id ? 'update' : 'create'
            this.$refs.crud[action](payload).then(() => {
                this.sisData = []
                this.customData = []
                this.dialog.show = false
                this.load()
            })
        },

        showOptions(data, table) {
            if (data.data.options) {
                this.resetOptions()
                if (table === 'sis') this.showEditOptions = true
                this.options.list = data.data.options
                this.options.demographic_id = data.data.id
                this.options.cell = data

                if (table === 'sis' && !["ELP Level","Meal Status","Disability","Ethnicity","Gender"].includes(data.data.name))
                    this.$refs.optionsCrud.read({demographic_id: this.options.demographic_id})
                table === 'custom' ? this.onCellClickedCustom(data) : this.onCellClickedSis(data)
            }
        },

        resetOptions() {
            this.options = {
                list: [],
                new: undefined,
                demographic_id: undefined,
                cell: undefined
            }
        },

        fetchOptions(id) {
            if (id) {
                return new Promise(resolve => {
                    this.$refs.optionsCrud.read({demographic_id: id}).then(res => {
                        resolve(res.data.demographic_option)
                    })
                })
            } else {
                return new Promise(resolve => {
                    resolve([])
                })
            }
        },

        saveOption() {
            let rank = 1
            this.options.list.forEach(x => {
                if (x.rank >= rank) rank = x.rank + 1
            })
            let payload = {
                demographic_id: this.options.demographic_id,
                value: this.options.new,
                active: true,
                rank
            }
            this.$refs.optionsCrud.create(payload).then(() => {
                this.fetchOptions(this.options.demographic_id).then(res => {
                    this.options.new = undefined
                    this.updateOptionsList(this.options.demographic_id, res)
                    this.options.list = res
                })
            })
        },

        checkChange(v) {
            // if user double-clicks empty field, changes focus, and then closes the dialog,
            // don't run updateOption and don't show combine dialog since no change was made
            this.validUpdate = !(v.newValue === undefined && v.oldValue === null);
        },

        updateOption(data, isDisplayName=false) {
            if (!this.validUpdate) return

            let payload = this.$_.clone(data)
            let crud = 'optionsCrud'
            payload.display_name = payload.display_name?.trim() // remove leading/trailing spaces

            if (isDisplayName && payload.display_name === '') payload.display_name = null
            if (data.hybrid_demo) {
                if (data.hybrid_demo === 'ELP Level') {
                    crud = 'ellLevelCrud'
                } else if (data.hybrid_demo === 'Meal Status') {
                    crud = 'mealStatusCrud'
                } else if (data.hybrid_demo === 'Disability') {
                    crud = 'disabilityCrud'
                } else if (data.hybrid_demo === 'Ethnicity') {
                    crud = 'ethnicityCrud'
                } else if (data.hybrid_demo === 'Gender') {
                    crud = 'genderCrud'
                }
                this.$refs[crud].update(payload).then(() => {
                    this.isChanged = isDisplayName // display_name changed, trigger duplicate check on Done
                })
            } else {
                this.$refs[crud].update(payload).then(() => {
                    this.fetchOptions(this.options.demographic_id).then(res => {
                        this.updateOptionsList(this.options.demographic_id, res)
                        this.options.list = res
                        this.isChanged = isDisplayName // display_name changed, trigger duplicate check on Done
                    })
                })
            }
        },

        deleteOption(data) {
            let payload = this.$_.clone(data)

            this.$confirmDelete(payload, () => {
                this.$refs.optionsCrud.destroy(payload).then(() => {
                    let remainingOptions = this.options.list.filter(x => x.id !== payload.id)
                    this.$nextTick(() => {
                        this.updateOptionsList(this.options.demographic_id, remainingOptions)
                        this.showOptions(this.options.cell, 'custom') // options can only be deleted from Custom grid
                    })
                })
            })
        },

        updateOptionsList(id, data) {
            this.customData = this.customData.map(x => {
                if (x.id === id) {
                    x.options = data
                }
                return x
            })
        },

        managePermissions(data) {
            if (data.data.restricted) {
                this.permissions.data = data.data
                this.permissions.show = true
            }
        },

        update(data, table) {
            let valid = true
            let dataGrid = table === 'sis' ? this.sisData : this.customData
            if (data.data.active) {
                valid = dataGrid.filter(x => x.active).length < 16
            }
            if (valid) {
                let payload = this.$_.cloneDeep(data.data)
                this.$refs.crud.update(payload)
            } else {
                this.$snackbars.$emit('new', {
                    text: 'The maximum number of demographic fields set to use as chart filter (15) has been reached.',
                    usage: 'error'
                })
            }
        },

        doDelete(data) {
            let payload = this.$_.clone(data)
            let params = {
                count: 1,
                demographic_id: data.id
            }
            let url = this.$models.getUrl('studentDemographic', 'read') + '&' + this.$objectToParams(params)
            this.$axios.get(url).then(res => {
                let msg
                if (res.data.totalCount) {
                    msg = 'You are about to delete a demographic record which has <b>' + res.data.totalCount + '</b> student records attached to it. This change is permanent. Are you sure you want to delete this record?'
                }

                this.$confirmDelete(payload, () => {
                    this.$refs.crud.destroy(payload, {warn_confirmed: 1}).then(() => {
                        this.load()
                    })
                }, null, msg)
            })
        },

        async checkForSameDisplayName(data, firstCheck=true) {
            /**
             * Get all options with duplicate display_names
             *
             * Cycle through each set of options with the same display_name
             *
             * Show the combineDisplayName dialog for each set
             * allowing the user to decide which to combine
             * or which to rename
             */
            this.showEditOptions = false

            if (firstCheck) {
                // set crud
                let crud = ''
                let root = ''
                let id = null
                if (data.name === 'ELP Level') {
                    crud = 'ellLevelCrud'
                    root = 'ell_level'
                } else if (data.name === 'Meal Status') {
                    crud = 'mealStatusCrud'
                    root = 'meal_status'
                } else if (data.name === 'Disability') {
                    crud = 'disabilityCrud'
                    root = 'student_disability_type'
                } else if (data.name === 'Ethnicity') {
                    crud = 'ethnicityCrud'
                    root = 'ethnicity'
                } else if (data.name === 'Gender') {
                    crud = 'genderCrud'
                    root = 'gender'
                } else {
                    id = {demographic_id: data.id}
                    crud = 'optionsCrud'
                    root = 'demographic_option'
                }
                // get options for display_name comparison
                await this.$refs[crud].read(id)
                    .then(r => {
                        this.displayNames = r.data[root].filter(x => x.display_name !== null)
                        this.duplicateDisplayNames =
                            [... new Set(this.displayNames.filter((item, index) => this.displayNames.map(el => el.display_name).indexOf(item.display_name) !== index).map(el => el.display_name))]
                    })
            }

            if (this.duplicateDisplayNames.length > 0 && this.isChanged) {
                let names = Object.values(this.duplicateDisplayNames)
                let options = []
                for (const n of names) {
                    if (!this.combineDisplayName.show) {
                        options = this.displayNames.filter(a => a.display_name === n)
                        if (options.length > 0) {
                            let optionValues = []
                            options.forEach(o => {
                                optionValues.push(o)
                            })
                            this.renderCombineDialog(n, optionValues)
                            this.duplicateDisplayNames.splice(n, 1)
                        }
                    }
                }
            } else {
                this.isChanged = false
                this.combineDisplayName.show = false
            }
        },

        renderCombineDialog(name, options) {
            this.combineDisplayName.message = 'You have given the same display name ("' + name + '") to the following demographic field values:<br><br>'
            options.forEach(o => {
                this.combineDisplayName.message += '• ' + (o.value || o.name || o.description) + '<br>'
            })
            this.combineDisplayName.message += '<br>Would you like to combine these values to display as one single category under your chosen display name in eduCLIMBER? You can always uncombine them later.'
            this.combineDisplayName.show = true
        },

        combineDisplayNames() {
            /**
             * Allow display_names to be combined (non-destructive data merging)
             *
             * Determine if there are more field groups that need user input
             * If not, close the combineDisplayName dialog and we're done
             * If so, re-open the combineDisplayName dialog with new fields/message and repeat
             */
            this.combineDisplayName.show = false
            this.$nextTick(() => {
                if (this.duplicateDisplayNames.length > 0) {
                    this.checkForSameDisplayName(null,false)
                } else {
                    this.isChanged = false
                }
            })
        },

        goBack() {
            this.combineDisplayName.show = false
            setTimeout(() => {
                this.showOptions(this.options.cell, 'sis')
            }, 200)
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .option-edit-icon {
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(50%);
    transform: translateY(-50%);
}
</style>
