export default {
    methods: {
        castIfNumber(v) {
            const str = (''+ v).trim()
            if (str.length === 0) return v
            return !isNaN(+str) ? Number(v) : v
        },
        processSavedSearch(v, defaultRec) {
            if(v.length) {
                return {
                    ...defaultRec,
                    ...this.$_.omit(v[0],['args']),
                    ...(this.$_.transform(v[0].args, (acc,val,key) => {
                        return acc[key.toLowerCase()] = val.length
                            ? (val.length==1 ? this.castIfNumber(val[0]) : val.map(v => this.castIfNumber(v)))
                            : null
                    }))
                }
            } else {
                return defaultRec
            }
        },
    }
}
