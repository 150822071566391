import Vue from "vue"
export default Vue.extend({
    template: `
        <span class="position: relative;">
            {{ params.value }}
            <span v-if="show" :style="wrapperStyle">
                <i :style="iconStyle" :class="iconClass" />
            </span>
        </span>
    `,
    computed: {
        show () { return this.params.data.unmatched_student_count > 0 },
        iconClass () { return this.params.icon || 'fas fa-exclamation-triangle' },
        wrapperStyle () { return `
            position: relative;
            margin-left: 5px;
        ` },
        iconStyle () { return `
            position: relative;
            font-size: 80%;
            top: -1px;
        ` }
    }
});
