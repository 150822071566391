<template>
    <v-layout column>
        <div class="headline">
            Strategies
            <p class="my-4 caption" style="color: #C0C3CF; font-size: 14px;">
                Intervention Strategies are the specific interventions that are completed with students to address areas of need
            </p>
        </div>


        <fe-action-list
            itemText="displayName"
            class="mt-5"
            :titleWidth="300"
            ref="list"
            :items="items"
            @rowClick="doSelect"
            :showFiltering="false"
            :show-search="false"
            :editButton="false"
            :addButton="false"
        >
        </fe-action-list>
    </v-layout>
</template>

<script>
    export default {
        name: "InterventionTypes",
        data() {
            return {
                items: []
            }
        },
        mounted() {
            this.loadData()
        },
        methods: {
            doSelect(item) {
                let event = 'show ' + item.show
                this.$emit('go', item)
            },
            loadData() {
                this.$modelGet('interventionType', { active: 1})
                    .then(response => {
                        let academic = response.filter(rec => rec.category_id !== 4)
                        let behavior = response.filter(rec => rec.category_id === 4)

                        this.items = [{
                            displayName: `Monitored by Assessment <span class="ml-4">${academic.length}<span>`,
                            name: 'Monitored by Assessment',
                            records: academic,
                            show: 'academic-type'
                        }, {
                            displayName: `Monitored by Point Sheet <span class="ml-4">${behavior.length}<span>`,
                            name: 'Monitored by Point Sheet',
                            count: behavior.length,
                            records: behavior,
                            show: 'behavior-type'
                        }]
                    })
            }
        }
    }
</script>

<style scoped>

</style>
