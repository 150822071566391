<template>
    <fe-dialog
        @dismiss="$emit('showDataManagerClicked', false)"
        @accept="$emit('showDataManagerClicked', false)"
        width="65%"
        title="Data Manager"
        persistent
        disableAutoclose
        acceptButtonText=""
        acceptButtonDisabled
        dismissButtonText=""
        :footer="false"
        :header="false"
    >
        <fe-mask v-model="loading" showLoader persistent/>
        <fe-dialog
          v-if="deleteModal"
          @accept="deleteData"
          @dismiss="deleteModal = false"
          :disableAutoclose="true"
          acceptButtonText="Delete"
          dismissButtonText="Cancel"
          usage="danger"
        >
          <v-layout column>
              <v-flex class="mb-5">You are about to delete <strong>{{ deleteMessage }}</strong>. This change is permanent!</v-flex>
              <v-flex><fe-label>Type <strong>delete data</strong> to confirm:</fe-label></v-flex>
              <v-flex><v-text-field v-model="deleteUserConfirmation"></v-text-field></v-flex>
          </v-layout>
        </fe-dialog>
        <fe-dialog
          title="Export Data"
          v-if="exportModal"
          @accept="exportData"
          @dismiss="exportModal = false"
          :disableAutoclose="true"
          acceptButtonText="Queue Export"
          dismissButtonText="Cancel"
        >
          <v-layout column>
              <v-flex class="mb-5">You are about to queue an export containing <strong>{{ exportMessage }}</strong>. Once the selected data has finished exporting, you will receive a message in eduCLIMBER containing a link to your download.</v-flex>
          </v-layout>
        </fe-dialog>
        <template v-slot:footer>
            <slot name="footer"/>
        </template>
        <v-card-title class="fe-dialog-card-title dialog-card-custom py-4">
            <slot name="header">
                <div class="flex-fill d-block">
                    Data Manager
                    <v-btn class="float-right pa-0 ma-0" icon @click="$emit('showDataManagerClicked', false)">
                        <v-icon>close</v-icon>
                    </v-btn>
                </div>
                <div class="flex-fill d-block" style="width: 100%;">
                    <v-tabs height="28px" class="mb-0 data-tabs" noPadding :showArrow="true" v-model="activeTab">
                        <v-tab class="small-tab small-tab-all">Data Management</v-tab>
                        <v-tab class="small-tab small-tab-all" @click="loadLogs()">Logs</v-tab>
                    </v-tabs>
                    <v-spacer class="data-tab-spacer"></v-spacer>
                </div>
            </slot>
        </v-card-title>

        <v-card-text class="d-flex flex-column flex-fill pa-4 no-scroll" style="height:500px">
            <v-tabs-items v-model="activeTab">
                <v-tab-item
                key="0">
                   <div class="mt-3">
                        <fe-filter-btn v-model="schoolYear" :multiple="false" :showClearButton="false" title="School Year" class="mr-2" :items="schoolYears"></fe-filter-btn>
                        <fe-filter-btn v-model="dataView" :multiple="false" :showClearButton="false" title="Data Type" :items="dataTypes"></fe-filter-btn>
                    </div>

                    <!-- all these grids must have a unique key within the common parent or the filter menu and column orders are broken in Vue 2 -->
                    <fe-grid  v-if="selectedSchoolYear.id && selectedDataView.id == 'assessment'"
                        ref="assessmentGrid"
                        key="assessment"
                        :columnDefs="assessmentCols"
                        :rowData="assessmentData"
                        v-bind="gridOptions"
                    >
                        <template #cellDialog="{cell}">
                            <v-list>
                                <v-list-item @click="confirmExport(cell.data)">Export</v-list-item>
                                <v-list-item @click="confirmDelete(cell.data)">Delete</v-list-item>
                            </v-list>
                        </template>
                    </fe-grid>

                    <fe-grid  v-else-if="selectedSchoolYear.id && selectedDataView.id == 'attendance'"
                        ref="attendanceGrid"
                        key="attendance"
                        :columnDefs="attendanceCols"
                        :rowData="attendanceData"
                        v-bind="gridOptions"
                    >
                        <template #cellDialog="{cell}">
                            <v-list>
                                <v-list-item @click="confirmExport(cell.data)">Export</v-list-item>
                                <v-list-item @click="confirmDelete(cell.data)">Delete</v-list-item>
                            </v-list>
                        </template>
                    </fe-grid>

                    <fe-grid  v-else-if="selectedSchoolYear.id && selectedDataView.id == 'form'"
                        ref="formGrid"
                        key="form"
                        :columnDefs="formCols"
                        :rowData="formData"
                        v-bind="gridOptions"
                    >
                        <template #cellDialog="{cell}">
                            <v-list>
                                <v-list-item @click="confirmExport(cell.data)">Export</v-list-item>
                                <v-list-item @click="confirmDelete(cell.data)">Delete</v-list-item>
                            </v-list>
                        </template>
                    </fe-grid>

                    <fe-grid  v-else-if="selectedSchoolYear.id && selectedDataView.id == 'incident'"
                        ref="incidentGrid"
                        key="incident"
                        :columnDefs="incidentCols"
                        :rowData="incidentData"
                        v-bind="gridOptions"
                    >
                        <template #cellDialog="{cell}">
                            <v-list>
                                <v-list-item @click="confirmExport(cell.data)">Export</v-list-item>
                                <v-list-item @click="confirmDelete(cell.data)">Delete</v-list-item>
                            </v-list>
                        </template>
                    </fe-grid>

                    <fe-grid  v-else-if="selectedSchoolYear.id && selectedDataView.id == 'intervention'"
                        ref="interventionGrid"
                        key="intervention"
                        :columnDefs="interventionCols"
                        :rowData="interventionData"
                        v-bind="gridOptions"
                    >
                        <template #cellDialog="{cell}">
                            <v-list>
                                <v-list-item @click="confirmExport(cell.data)">Export</v-list-item>
                                <v-list-item @click="confirmDelete(cell.data)">Delete</v-list-item>
                            </v-list>
                        </template>
                    </fe-grid>

                    <fe-grid  v-else-if="selectedSchoolYear.id && selectedDataView.id == 'roster'"
                        ref="rosterGrid"
                        key="roster"
                        :columnDefs="rosterCols"
                        :rowData="rosterData"
                        v-bind="gridOptions"
                    >
                        <template #cellDialog="{cell}">
                            <v-list>
                                <v-list-item @click="confirmExport(cell.data)">Export</v-list-item>
                                <v-list-item @click="confirmDelete(cell.data)">Delete</v-list-item>
                            </v-list>
                        </template>
                    </fe-grid>

                    <div v-else class="mt-10 flex-fill">
                        <fe-empty-state icon="fa-empty-set" text="Select a school year and data type above"></fe-empty-state>
                    </div>
                </v-tab-item>

                <v-tab-item key="1">
                    <div class="subtitle mt-5">Audit Log</div>
                    <fe-spinner :text="auditLogMessage" :icon="auditLogIcon" v-if="auditLogMessage" />
                    <fe-grid v-else
                        ref="logGrid"
                        :columnDefs="auditLogColumns"
                        :rowData="auditLogData"
                        v-bind="gridOptions"
                    />
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>

    </fe-dialog>
</template>

<script>
    const dataDetailMap = {
        assessment: (year, v) => {
            let assessment = v.assessment_group + (v.sub_category_name ? ' - ' + v.sub_category_name : '') + (v.data_point_name ? ' - ' + v.data_point_name : '')
            let pl = v.records != 1 ? 's' : ''
            let label = `${v.records} ${assessment} assessment record${pl} for ${year}`
            let include = []
            if (v.threshold_group_results) include.push(`${v.threshold_group_results} threshold results`)
            if (v.threshold_form_instances) include.push(`${v.threshold_group_results} threshold forms`)
            if (v.threshold_tags) include.push(`${v.threshold_tags} threshold tags`)
            if (include.length) {
                let last = include.pop()
                label += ' (this will include ' + include.join(', ') + (include.length > 1 ? ',' : '') + ' and ' + last + ')'
            }
            return [
                label,
                {
                    data_point_type_id: v.data_point_type_id,
                    sub_category_id: v.sub_category_id,
                    data_point_name_id: v.data_point_name_id
                }
            ]
        },
        attendance: (year, v) => {
            let attendance = v.attendance_status_name + (v.attendance_reason_name ? ' - ' + v.attendance_reason_name : '')
            let pl = v.records != 1 ? 's' : ''
            return [
                `${v.records} ${attendance} attendance record${pl} for ${year}`,
                {
                    attendance_status_id: v.attendance_status_id,
                    attendance_reason_id: v.attendance_reason_id,
                }
            ]
        },
        roster: (year, v) => {
            let roster = v.school_name + (v.grade_desc ? ' - ' + v.grade_desc : '')
            let pl = v.records != 1 ? 's' : ''
            return [
                `${v.records} ${roster} roster record${pl} for ${year}`,
                {
                    school_id: v.school_id,
                    grade_id: v.grade_id,
                }
            ]
        },
        threshold: (year, v) => {
            let threshold = v.threshold_group_name
            let pl = v.records != 1 ? 's' : ''
            return [
                `${v.records} ${threshold} threshold result${pl} for ${year}`,
                {
                    threshold_group_id: v.threshold_group_id
                }
            ]
        },
        intervention: (year, v) => {
            let intervention = v.category_name + (v.intervention_type_name ? ' - ' + v.intervention_type_name : '')
            let pl = v.records != 1 ? 's' : ''
            return [
                `${v.records} ${intervention} intervention record${pl} for ${year}`,
                {
                    category_id: v.category_id,
                    intervention_type_id: v.intervention_type_id,
                }
            ]
        },
        incident: (year, v) => {
            let incident = v.incident_behavior_type_name + (v.incident_behavior_name ? ' - ' + v.incident_behavior_name : '')
            let pl = v.records != 1 ? 's' : ''
            return [
                `${v.records} ${incident} incident record${pl} for ${year}`,
                {
                    incident_behavior_type_id: v.incident_behavior_type_id,
                    incident_behavior_id: v.incident_behavior_id,
                }
            ]
        },
        form: (year, v) => {
            let form = v.form_name
            let pl = v.records != 1 ? 's' : ''
            return [
                `${v.records} ${form} form instance${pl} for ${year}`,
                {
                    form_id: v.form_id,
                }
            ]
        }
    }

    export default {
        name: 'DataManager',
        watch: {
            schoolYear() {
                this.loadData()
            },
            dataView() {
                this.$nextTick(() => {
                    this.loadData()
                    const key = this.selectedDataView.id + 'Grid'
                    this.$refs?.[key]?.gridApi?.sizeColumnsToFit()
                })
            }
        },
        data() {
            let me = this
            this.loadPrerequisiteData()
            const actionMenu = (grid) => { return {
                maxWidth: 70,
                cellRenderer: v => '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>',
                onCellClicked: v => this.$refs[grid].setDialogCell(v),
            }}
            return {
                cell: null,
                gridOptions: {class: 'flex-fill', displayDensity: 'medium', showAddRowBtn: false, style: 'height: 400px'},
                activeTab: 0,
                deleteMessage: '',
                deleteUserConfirmation: '',
                deleteModal: false,
                deleteQueryParams: {},
                exportMessage: '',
                exportModal: false,
                exportQueryParams: {},
                dataView: {},
                schoolYear: {},
                schoolYears: [],
                loading: false,
                dataTypes: [{
                        id: 'assessment',
                        name: "Assessment"
                    }, {
                        id: 'attendance',
                        name: 'Attendance'
                    }, {
                        id: 'incident',
                        name: 'Incident'
                    }, {
                        id: 'intervention',
                        name: 'Intervention'
                    }, {
                        id: 'roster',
                        name: 'Roster'
                    }, {
                        id: 'form',
                        name: 'smartFORMS'
                    }
                ],
                assessmentCols: [{
                        field: "assessment_group",
                        headerName: "Assessment Group",
                    }, {
                        field: "sub_category_name",
                        headerName: "Assessment",
                        cellStyle: (v) => (v.data.sub_category_id < 0 ? {fontStyle: "italic", color: "#999"} : {}),
                    }, {
                        field: "data_point_name",
                        headerName: "Window",
                        cellStyle: (v) => (v.data.data_point_name_id < 0 ? {fontStyle: "italic", color: "#999"} : {}),
                    }, {
                        field: "records",
                        headerName: "Records",
                        width: 70,
                        cellStyle: { textAlign: 'right' }
                    }, {
                        field: "threshold_group_results",
                        headerName: "Threshold Results",
                        width: 80,
                        cellStyle: { textAlign: 'right' }
                    }, actionMenu('assessmentGrid')
                ],
                attendanceCols: [{
                        field: "attendance_status_name",
                        headerName: "Attendance Status",
                        cellStyle: (v) => (v.data.attendance_status_id < 0 ? {fontStyle: "italic", color: "#999"} : {}),
                    }, {
                        field: "attendance_reason_name",
                        headerName: "Attendance Reason",
                        cellStyle: (v) => (v.data.attendance_reason_id < 0 ? {fontStyle: "italic", color: "#999"} : {}),
                    }, {
                        field: "records",
                        headerName: "Records",
                        width: 70,
                        cellStyle: { textAlign: 'right' }
                    }, actionMenu('attendanceGrid')
                ],
                formCols: [{
                        field: "form_name",
                        headerName: "Form Name",
                    }, {
                        field: "records",
                        headerName: "Records",
                        width: 70,
                        cellStyle: { textAlign: 'right' }
                    }, {
                        field: "private_records",
                        headerName: "Private Records",
                        width: 70,
                        cellStyle: { textAlign: 'right' }
                    }, actionMenu('formGrid')
                ],
                incidentCols: [{
                        field: "incident_behavior_type_name",
                        headerName: "Incident Behavior Type",
                    }, {
                        field: "incident_behavior_name",
                        headerName: "Incident Behavior",
                        cellStyle: (v) => (v.data.incident_behavior_id < 0 ? {fontStyle: "italic", color: "#999"} : {}),
                    }, {
                        field: "records",
                        headerName: "Records",
                        width: 70,
                        cellStyle: { textAlign: 'right' }
                    }, actionMenu('incidentGrid')
                ],
                interventionCols: [{
                        field: "category_name",
                        headerName: "Category Name",
                    }, {
                        field: "intervention_type_name",
                        headerName: "Intervention Type",
                        cellStyle: (v) => (v.data.intervention_type_id < 0 ? {fontStyle: "italic", color: "#999"} : {}),
                    }, {
                        field: "records",
                        headerName: "Records",
                        width: 70,
                        cellStyle: { textAlign: 'right' }
                    }, actionMenu('interventionGrid')
                ],
                rosterCols: [{
                        field: "school_name",
                        headerName: "School",
                        cellStyle: (v) => (v.data.school_id < 0 ? {fontStyle: "italic", color: "#999"} : {}),
                    }, {
                        field: "grade_desc",
                        headerName: "Grade",
                        cellStyle: (v) => (v.data.grade_id < 0 ? {fontStyle: "italic", color: "#999"} : {}),
                    }, {
                        field: "records",
                        headerName: "Student History Records",
                        width: 70,
                        cellStyle: { textAlign: 'right' }
                    }, {
                        field: "course_records",
                        headerName: "Course History Records",
                        width: 70,
                        cellStyle: { textAlign: 'right' }
                    }, actionMenu('rosterGrid')
                ],
                thresholdCols: [{
                        field: "threshold_group_name",
                        headerName: "Group Name",
                    }, {
                        field: "records",
                        headerName: "Records",
                        width: 70,
                        cellStyle: { textAlign: 'right' }
                    }, actionMenu('thresholdGrid')
                ],
                auditLogColumns: [{
                        field: "text",
                        headerName: "Entry",
                        flex: 2,
                    }, {
                        field: "user_full_name",
                        headerName: "Deleted By",
                        flex: 1,
                    }, {
                        field: "user_id",
                        headerName: "Deleted By User ID",
                        hide: true,
                        flex: 1,
                    }, {
                        field: "created",
                        headerName: "Deleted On",
                        cellRenderer: v => me.$dayjs(v.value).format('LLL'),
                        flex: 1,
                        cellStyle: {
                            color: '#9297A6',
                            fontSize: '12px'
                        },
                    }
                ],

                assessmentData: [],
                attendanceData: [],
                formData: [],
                incidentData: [],
                interventionData: [],
                rosterData: [],

                auditLogData: [],

                auditLogMessage: true,
                auditLogIcon: "",
            }
        },

        computed: {
            selectedDataView() {
                return this.dataView?.included?.[0] || {}
            },
            selectedSchoolYear() {
                return this.schoolYear?.included?.[0] || {}
            }
        },

        mounted() {

        },

        methods: {
            showDeleteModal(message, queryParams) {
                this.deleteMessage = message
                this.deleteQueryParams = queryParams
                this.deleteUserConfirmation = ''
                this.deleteModal = true
            },
            showExportModal(id, message, queryParams) {
                this.exportMessage = message
                this.exportQueryParams = queryParams
                this.exportModal = true
            },
            confirmExport(v) {
                let m = dataDetailMap[this.selectedDataView.id](this.selectedSchoolYear.name, v)
                this.showExportModal(this.selectedDataView.id, m[0], m[1])
            },
            exportData() {
                this.exportModal = false
                this.$axios.post('dataManager.php?action=export&property=' + encodeURIComponent(this.selectedDataView.id), {
                    config: {
                        school_year_id: this.selectedSchoolYear.id,
                        ...this.exportQueryParams,
                    }
                })
                .then(response => {
                    this.$ecResponse(response)
                })
            },
            confirmDelete(v) {
                let m = dataDetailMap[this.selectedDataView.id](this.selectedSchoolYear.name, v)
                this.showDeleteModal(m[0], m[1])
            },
            deleteData() {
                if (this.deleteUserConfirmation != 'delete data') {
                    return
                }
                this.deleteModal = false
                this.loading = true
                this.$axios.get('dataManager.php?action=delete&property=' + encodeURIComponent(this.selectedDataView.id) + '&school_year_id=' + encodeURIComponent(this.selectedSchoolYear.id), {
                    params: this.deleteQueryParams,
                })
                .then(response => {
                    this.$ecResponse(response)
                    this.loadData()
                })
                .finally(() => {
                    this.loading = false
                })
            },
            changeTab(t) {

            },
            loadPrerequisiteData() {
                this.$axios.get('qry.php?property=school_years')
                .then(response => {
                    this.schoolYears = response.data.years
                })
            },
            loadLogs() {
                this.auditLogMessage = "Loading audit log..."
                this.auditLogIcon = "fa-spinner fa-spin"
                this.$axios.get('dataManager.php?action=get&property=logs')
                .then(response => {

                    if (!response.data.logs) {
                        this.auditLogMessage = "Failed to load audit log"
                        this.auditLogIcon = "fa fa-times"
                        return
                    }

                    this.auditLogMessage = ""
                    this.auditLogData = response.data.logs
                })
            },
            loadData() {
                if (!this.selectedSchoolYear.id || !this.selectedDataView.id) return
                this.loading = true
                this.$axios.get('dataManager.php?action=get&property=' + encodeURIComponent(this.selectedDataView.id) + '&school_year_id=' + encodeURIComponent(this.selectedSchoolYear.id))
                .then(response => {
                    const dataKey = this.selectedDataView.id + 'Data'
                    if (!this[dataKey]) return
                    this[dataKey] = response.data.data
                })
                .finally(() => {
                    this.loading = false
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dialog-card-custom {
        padding: 0 16px;
    }

    ::v-deep div.data-tabs .v-tab {
        text-transform: none !important;
    }
    .data-tab-header {
        width: 100%
    }

    div.v-tabs-bar {
        bottom: -3px;
        background-color: transparent;
    }

    .fe-tabs-main {
        height: 48px !important;
        margin-bottom: 20px
    }

    div.data-tab-spacer {
        border-bottom: #E0E1E6 solid 3px !important;
    }
</style>
