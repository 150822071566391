<template>
    <fe-dialog v-if="value"
        title="Dependencies"
        width="90%"
        @close="$emit('input',false)"
        :footer="false"
    >
        <fe-grid
            ref="grid"
            displayDensity="small"
            :rowData="filteredDependencies"
            :showToolbar="false"
            :columnDefs="columnDefs"
            :gridOptionOverrides="gridOptions"
            :bordered="false"
            domLayout="autoHeight"
            @rowDoubleClicked="editorRec=$event.data; showEditor=true"
            @gridReady="resize"
            @gridColumnsChanged="resize"
        />
        <div class="d-flex" style="width:100%;">
            <fe-btn usage="tertiary" @click="editorRec=null; showEditor=true">Add Rule</fe-btn>
        </div>
    
        <!-- <v-data-table
            :headers="[
                { text: 'If/When', value: 'statement_form_logic_id'},
                { text: 'Controller', value: 'controlling_form_field_id'},
            ]"
            :items="dependencies"
            hide-default-header
            hide-default-footer
            @click:row="editorRec=$event; showEditor=true"
        >
            <template #item.statement_form_logic_id="{item}">{{formLogicObject[item.statement_form_logic_id].name}}</template>
            <template #item.controlling_form_field_id="{item}">{{allFields[item.controlling_form_field_id].name}}</template>
        </v-data-table> -->
    
        <!-- <div class="grid" style="grid-template-columns: 80px auto 400px 120px auto 60px">
            <div class="headerCell" >If/When</div>
            <div class="headerCell" >Controller</div>
            <div class="headerCell" >Action</div>
            <div class="headerCell" >Dependency</div>
            <div class="headerCell" ></div>
            <div class="headerCell" ></div>
            
            <template v-for="rule in dependencies">
                
                <div class="cell bold">
                    {{formLogicObject[rule.statement_form_logic_id].name}}
                </div>
                <div class="cell">
                    {{allFields[rule.controlling_form_field_id].name}}
                </div>

                <div class="d-flex statement-middle">
                    <div v-if="rule.statement_form_logic_id!=1" class="cell w66p">
                        has value
                    </div>
                    <div
                        v-if="rule.statement_form_logic_id!=1"
                        class="cell bold w33p"
                    >
                        {{whenActionText[rule.action_form_logic_id]}}
                    </div>

                    <div
                        v-if="rule.statement_form_logic_id==1"
                        class="cell bold w33p"
                    >
                        {{formLogicObject[rule.cond_form_logic_id].name}}
                    </div>

                    <div
                        v-if="rule.statement_form_logic_id==1"
                        class="cell w33p"
                    >
                        {{rule.form_logic_value}}
                    </div>

                    <div
                        v-if="rule.statement_form_logic_id==1"
                        class="cell bold w33p"
                    >
                        {{formLogicObject[rule.action_form_logic_id].name}}
                    </div>
                </div>
                    
                <div class="cell bold">
                    {{rule.form_section_id ? 'Section' : 'Field'}}
                </div>
                
                <div
                    v-if="rule.form_section_id"
                    class="cell"
                >
                    {{sections.find(x=>x.id==rule.form_section_id).name}}
                </div>
                
                <div
                    v-if="rule.form_field_id"
                    class="cell"
                >
                    {{allFields[rule.form_field_id].name}}
                </div>
                
                <div class="cell center">
                    <i class="fe-grid-icon fas fa-trash theme--light dg-icon" @click="deleteRec(rule)" ></i>
                </div>
                
            </template>
        </div> -->
        
        <fe-crud ref="crud" :config="$models.formItemDependency" :defaultParams="{form_id:templateId}"/>
        
        <dependency-editor v-if="showEditor"
            :value="editorRec"
            :sections="sections"
            :allFields="allFields"
            :templateId="templateId"
            @close="showEditor=false"
        />
        
    </fe-dialog>
    
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'
import DependencyEditor from './DependencyEditor'

export default {
    name: 'DependencyGrid',
    inject: ['localStore'],

    components: {
        DependencyEditor,
    },

    props: ['value', 'sections', 'allFields', 'templateId', 'filter'],

    computed: {
        ...mapLocalState([ 'fieldConfigs', 'formLogic', 'dependencies', 'dependentSections', 'dependentFields', 'whenActionText', 'reloadFn' ]),
        formLogicObject() {
            return this.formLogic.reduce((acc,x) => {
                acc[x.id]=x
                return acc
            },{})
        },
        filteredDependencies() {
            return this.filter ? this.dependencies.filter(this.filter) : this.dependencies
        }
    },
    
    data() {
        return {
            showEditor: false,
            editorRec: null,
            gridOptions: {
                deltaRowDataMode: true,
                tooltipShowDelay: 500,
                getRowNodeId: v => {
                    return v.id
                },
            },
            columnDefs:[{
                field: 'dupWhen',
                maxWidth: 20,
                tooltipValueGetter: v => v.data.dupWhen ? `
                    This rule is being overridden by another rule.  Only the last rule created will function correctly.
                ` : '',
                cellRenderer: v => {
                    return v.data.dupWhen ? `<div style="font-weight:bold; color:red;">?</div>` : ''
                }
            }, {
                headerName: 'If/When',
                field: 'statement_form_logic_id',
                maxWidth: 80,
                cellRenderer: v => {
                    return `
                        <div style="font-weight:bold;">
                            ${this.formLogicObject[v.data.statement_form_logic_id].name}
                        </div>
                    `
                },
            }, {
                headerName: 'Controller',
                field: 'controlling_form_field_id',
                cellRenderer: v => {
                    return (this.allFields[v.data.controlling_form_field_id]?.name) || `<div style="color:red;">${v.data.controlling_form_field_id}</div>`
                },
            }, {
                headerName: 'Action',
                maxWidth: 300,
                valueGetter: v => {
                    return v.data.statement_form_logic_id == 1
                    ? `${this.formLogicObject[v.data.cond_form_logic_id].name}${v.data.form_logic_value}`
                    : `has value`
                },
                cellRenderer: v => {
                    if(v.data.statement_form_logic_id == 1) {
                        let value
                        let cfg = this.fieldConfigs[v.data.controlling_form_field_id]
                        if(cfg && cfg.items && !isNaN(v.data.form_logic_value)) {
                            let itemText = cfg.itemText ? cfg.itemText : 'name'
                            let itemValue = cfg.itemValue ? cfg.itemValue : 'id'
                            value = cfg.items.find(x => x[itemValue] == v.data.form_logic_value)?.[itemText]
                        } else {
                            value = v.data.form_logic_value
                        }
                        return `
                            <div class="d-flex">
                                <div style="width:50%;font-weight:bold;">${this.formLogicObject[v.data.cond_form_logic_id].name}</div>
                                <div style="width:50%;">${value}</div>
                            </div>
                        `
                    } else {
                        return `<div style="width:66%;font-weight:bold;">has value</div>`
                    }
                    return
                },
            }, {
                headerName: 'Effect',
                maxWidth: 120,
                valueGetter: v => {
                    return v.data.statement_form_logic_id == 1
                    ? this.formLogicObject[v.data.action_form_logic_id].name
                    : this.whenActionText[v.data.action_form_logic_id].name
                },
                cellRenderer: v => {
                    return v.data.statement_form_logic_id == 1
                    ? `<div style="font-weight:bold;">${this.formLogicObject[v.data.action_form_logic_id].name}</div>`
                    : `<div style="font-weight:bold;">${this.whenActionText[v.data.action_form_logic_id]}</div>`
                },

            }, {
                headerName: 'Dependency',
                valueGetter: v => {
                    let type = v.data.form_section_id ? 'Section' : 'Field'
                    return v.data.form_section_id
                        ? type+(this.sections.find(x=>x.id==v.data.form_section_id)?.name || v.data.form_section_id)
                        : type+(this.allFields[v.data.form_field_id]?.name || v.data.form_field_id)
                },
                cellRenderer: v => {
                    return `
                        <div class="d-flex">
                            <div style="width:100px;font-weight:bold;">${v.data.form_section_id ? 'Section' : 'Field'}</div>
                            <div>${v.data.form_section_id
                                ? this.sections.find(x=>x.id==v.data.form_section_id)?.name || '<div style="color:red;">'+v.data.form_section_id+'</div>'
                                : this.allFields[v.data.form_field_id]?.name || '<div style="color:red;">'+v.data.form_field_id+'</div>'
                            }</div>
                        </div>
                    `
                },
            }, {
                headerName: '',
                maxWidth: 50,
                cellRenderer: v => `<i class="fe-grid-icon fas fa-trash theme--light"></i>`,
                onCellClicked: x => this.deleteRec(x.data),
            }],
        }
    },

    methods: {
        resize() {
            this.$refs.grid?.gridApi?.sizeColumnsToFit()
        },
        deleteRec(rec) {
            this.$confirmDelete(rec,x => {
                this.$refs.crud.destroy(rec).finally(()=>this.reloadFn())
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.grid {
    display: grid;
    border: 1px solid #efefef;
    border-radius: 4px;
}
.headerCell {
    display: flex;
    padding-left: 8px;
    align-items: center;
    height: 36px;
    font-weight: bold;
    border-bottom: 1px solid #efefef;
}
.statement-middle {
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
}
.bb {
    border-bottom: 1px solid #efefef;
}
.cell {
    display: flex;
    height: 32px;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    padding-left: 8px;
    border-bottom: 1px solid #efefef;
}
.dg-icon {
    width:20px;
    height:20px;
    padding: 16px;
    border-radius:50%;
    &:hover {
        background-color: rgba(0,0,0,.05);
    }
}
.center {
    justify-content: center;
}
.bold {
    font-weight: bold;
}
.w33p {
    width: 33%;
}
.w66p {
    width: 66%;
}


// crud.php?property=FormItemDependency&action=update
// {
//     "form_item_dependency": [
//         {
//             "id": 8,
//             "form_id": 1,
//             "controlling_form_field_id": 9,
//             "form_field_id": 8,
//             "form_section_id": null,
//             "statement_form_logic_id": 1,
//             "cond_form_logic_id": 3,
//             "form_logic_value": "34",
//             "action_form_logic_id": 11,
//             "rank": 0,
//             "disabled": 0,
//             "created_by": 1,
//             "created": "2020-06-02 09:00:29",
//             "skip_instance_count": 0,
//             "fidconfig": "{}"
//         }
//     ]
// }

// crud.php?property=FormItemDependency&action=create
// {
//     "form_item_dependency": [
//         {
//             "form_id": 15,
//             "form_field_id": null,
//             "form_section_id": 38,
//             "statement_form_logic_id": 2,
//             "cond_form_logic_id": null,
//             "form_logic_value": "",
//             "action_form_logic_id": 15,
//             "rank": 0,
//             "created": null,
//             "skip_instance_count": 0,
//             "id": "FormItemDependency-1",
//             "controlling_form_field_id": 105,
//             "fidconfig": "{}"
//         }
//     ]
// }

// crud.php?property=FormItemDependency&action=delete
// {
//     "form_item_dependency": [
//         {
//             "id": 22,
//             "form_id": 15,
//             "controlling_form_field_id": 105,
//             "form_field_id": null,
//             "form_section_id": 38,
//             "statement_form_logic_id": 2,
//             "cond_form_logic_id": null,
//             "form_logic_value": "",
//             "action_form_logic_id": 15,
//             "rank": 0,
//             "disabled": 0,
//             "created_by": 1,
//             "created": "2020-06-11 14:47:00",
//             "skip_instance_count": 0
//         }
//     ]
// }
</style>
