<template>
    <div>
        <v-list
            v-if="localValue"
            :class="isRoot ? 'py-0' : (parentIsSelectable ? 'pl-8 py-0' : 'py-0')"
            style="width: 100%; overflow: hidden;"
        >
            <component
                v-for="itm in processedItems"
                :key="itm.id"
                :is="itm.children ? 'v-list-group' : 'v-list-item'"
                v-model="itm._isExpanded"
            >
                <template v-if="itm.children" v-slot:activator>
                    <v-list-item-title class="d-flex align-center">
                        <v-checkbox
                            v-if="itm.id"
                            :input-value="localValue.includes(itm.id)"
                            hide-details
                            :label="`${itm.name} (${itm.children.length})`"
                            @click.stop
                            @change="setSelected(itm, $event)"
                        >
                            <template #label>
                                <span>{{ itm.name }} ({{ itm.children.length }})</span>

                                <span v-if="showIcon">
                                    <i class="pl-2 fas fa-sitemap"/>

                                    <span class="sup">{{ itm.children.length }}</span>
                                </span>
                            </template>
                        </v-checkbox>

                        <span v-else class="ml-2" style="font-size: 14px;">{{ itm.name }} ({{ itm.children.length }})</span>
                    </v-list-item-title>
                </template>

                <template v-else>
                    <v-list-item-title class="d-flex align-center">
                        <v-checkbox
                            :input-value="localValue.includes(itm.id)"
                            hide-details
                            :label="itm.name"
                            @click.stop
                            @change="setSelected(itm, $event)"
                        />
                    </v-list-item-title>
                </template>

                <v-list-item-content v-if="itm.children" class="py-0">
                    <hierarchical-checkbox-panel
                        :items="itm.children"
                        :itemFilterFunction="itemFilterFunction"
                        :searchText="searchText"
                        :parentIsSelectable="!!itm.id"
                        v-model="localValue"
                        :surfaceFirstSelections="surfaceFirstSelections"
                    />
                </v-list-item-content>
            </component>
        </v-list>
    </div>
</template>

<script>
export default {
    name: 'HierarchicalCheckboxPanel',

    props: {
        value: {},
        surfaceFirstSelections: {
            type: Boolean,
            default: false
        },
        isRoot: {
            type: Boolean,
            default: false
        },
        parentIsSelectable: {
            type: Boolean,
            default: true
        },
        items: {
            type: Array,
            required: true
        },
        itemValue: {
            type: String,
            default: 'id'
        },
        itemText: {
            type: String,
            default: 'name'
        },
        searchText: {
            type: String,
            default: ''
        },
        itemFilterFunction: {
            type: Function,
            required: false
        },
        showIcon: {
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            localValue: null,
            firstSelectionIds: null,
        }
    },

    computed: {
        filteredItems() {
            let items = this.items
                .filter(itm => !this.searchText || itm.children || itm.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0)
                .filter(itm => !this.itemFilterFunction || this.itemFilterFunction(itm))

            return items || []
        },
        // Processing includes filtering, sorting, surfacing, etc.
        processedItems() {
            let items = this.filteredItems
            if (this.surfaceFirstSelections && this.firstSelectionIds !== null) {
                items = items.sort((a, b) => {
                    let id1 = a[this.itemValue]
                    let id2 = b[this.itemValue]

                    // "All Schools" e.g. should always remain at the very top
                    //if (a[this.itemValue] === this.allOptionValue) return -1
                    //else if (b[this.itemValue] === this.allOptionValue) return 1

                    if (this.firstSelectionIds.includes(id1) && !this.firstSelectionIds.includes(id2)) return -1
                    else if (this.firstSelectionIds.includes(id2) && !this.firstSelectionIds.includes(id1)) return 1
                    else return 0
                })
            }

            return items
        },
    },

    watch: {
        value(v) {
            // Don't update localValue if the ids are equal, because otherwise
            // we'd be creating "new" arrays (of same values) and hit an infinite
            // loop of value/localValue watchers
            v = v.map(id => parseInt(id))
            if (!this.$_.isEqual(v, this.localValue)) {
                this.localValue = this.$_.cloneDeep(v)
            }
        },
        localValue(v) {
            this.$emit('input', v)
        },
        items(v) {
            // When new items come in (probably just once after mounted), check
            // and see if any of their CHILDREN was among the default (i.e. surfaced)
            // selections; if so, also surface this entire hierarchy/treebranch, because
            // even if it isn't a default selection, some of its children are
            for (let itm of v) {
                if (!this.firstSelectionIds?.includes(itm[this.itemValue]) && itm.children?.length > 0) {
                    if (itm.children.find(child => this.firstSelectionIds?.includes(child[this.itemValue]))) {
                        this.firstSelectionIds.push(itm[this.itemValue])
                        itm._isExpanded = true
                    }
                }
            }
        },
    },

    mounted() {
        if (this.value) {
            if (this.surfaceFirstSelections) {
                this.firstSelectionIds = this.$_.cloneDeep(this.value) // value array is already just ids, so clone it
            }
        }

        this.localValue = this.$_.cloneDeep(this.value)
    },

    methods: {
        setSelected(item, value) {
            if (value && !this.localValue.includes(item.id)) {
                if (item.id === -1) {
                    this.localValue = [-1] // Clicking an "All [items]" option should uncheck all other checkboxes
                } else {
                    let value = [...this.localValue.filter(id => id !== -1), item.id] // Make sure "All" is unchecked when picking specific items

                    // Selecting a parent should implicitly select all child items
                    // that are not already selected
                    for (let id of this.getChildIds(item)) {
                        if (!value.includes(id)) {
                            value.push(id)
                        }
                    }

                    this.localValue = value
                }
            } else if (!value && this.localValue.includes(item.id)) {
                let value = this.localValue.filter(itm => itm !== item.id)

                // De-selecting a parent should implicitly de-select all child items
                // that are already selected
                for (let id of this.getChildIds(item)) {
                    value = value.filter(v => v !== id)
                }

                this.localValue = value
            }
        },

        // Get all child item ids for a given item, extending recursively
        // all the way down the tree (e.g. useful for select all type operations)
        getChildIds(item) {
            if (!item.children) {
                return []
            }

            let ids = []
            for (let itm of item.children) {
                ids = [...ids, itm.id, ...this.getChildIds(itm)]
            }

            return ids
        },
    },
}
</script>

<style lang="scss" scoped>
.selected-item {
    background: #f5f6f8;
    padding: 4px 8px 4px 8px;
    margin: 4px 0px 4px 0px;

    &-name {
        overflow: hidden;
        /*white-space: nowrap;*/
        margin-right: 8px;
    }
}

.v-list-item-title,
::v-deep .v-label {
    font-size: 14px !important;
}

/*
By default vuetify giving 16px x padding, but it's
just wasting space and not necessary within this component
*/
::v-deep .v-list-item {
    padding-left: 0 !important;
}

.sup {
    position: relative;
    font-size: 60%;
    top: -9px;
    left: -4px;
}
</style>
