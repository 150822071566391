<template>
    <div class="d-flex flex-column fill-flex">
        <div class="headline mb-1 d-flex">
            Levels
            <fe-info-tip
                class="ml-2"
                tooltip="Intervention levels indicate level of student need and can be used as search criteria"
            />
        </div>

        <fe-dialog width="500" v-model="createDialog.show" v-if="createDialog.show"
            title="Add Intervention Level"
            :footer="false"
            @close="createDialog.show=false"
            persistent
        >
            <model-form 
                idProperty="id" 
                @cancel="finishCreate" 
                @done="finishCreate" 
                :readParams="{active: 1}" 
                :records="createDialog.records" 
                model="interventionLevel" 
                :fieldConfig="fieldConfig"
                submitButtonText="Save"
            >
                <fe-label>Icon</fe-label>
                <div class="d-flex">
                    <div class="color-picker" v-for="c in colors" :key="c.id"
                        :style="{background: c.hex}"
                        :class="{'color-picker-selected': c.id === createDialog.records[0].icon}"
                        @click="createDialog.records[0].icon = c.id"
                    >
                    </div>
                </div>
            </model-form>
        </fe-dialog>

        <fe-crud-grid
            autoload
            :config="$models.interventionLevel"
            @read="v => levels = v"
            :createParams="createParams"
            @confirmDestroy="crudConfirmDelete"
            @beginCreate="startCreate"
            @updated="onUpdated"
            ref="grid"
            disableInlineCreate
            :gridOptions="gridOptions"
            :frameworkComponents="frameworkComponents"
            domLayout="autoHeight"
            :columnDefs="columns"
            :rowData="levels"
            @cellValueChanged="updateCrud('expectationCrud', ...arguments)"
            displayDensity="medium"  
        />

    </div>
</template>

<script>
    import NumericEditor from '@/components/common/grid/NumericEditor.js'
    import ColorSelect from '@/components/common/grid/ColorSelect.js'
    import ModelForm from '@/components/common/form/ModelForm'

    export default {
        name: "InterventionLevels",
        components: {
            ModelForm,
            ColorSelect
        },
        data() {
            return {
                frameworkComponents: {
                    numericEditor: NumericEditor,
                    colorSelect: ColorSelect
                },
                gridOptions: {

                },
                colors: [{
                    id: 'int_level_green',
                    color: '<i class="fas fa-square " style="color: #4bc647;"></i>',
                    hex: '#4bc647'
                }, {
                    id: 'int_level_yellow',
                    color: '<i class="fas fa-square " style="color: yellow;"></i>',
                    hex: 'yellow'
                }, {
                    id: 'int_level_orange',
                    color: '<i class="fas fa-square " style="color: orange;"></i>',
                    hex: 'orange'
                }, {
                    id: 'int_level_maroon',
                    color: '<i class="fas fa-square " style="color: #9b3939;"></i>',
                    hex: '#9b3939'
                }, {
                    id: 'int_level_baby_blue',
                    color: '<i class="fas fa-square " style="color: #4af4f7;"></i>',
                    hex: '#4af4f7'
                }, {
                    id: 'int_level_purple',
                    color: '<i class="fas fa-square " style="color: #ce4cbd;"></i>',
                    hex: '#ce4cbd'
                }],
                levels: [],
                createDialog: {
                    show: false,
                    records: [{
                        icon: 'int_level_orange'
                    }]
                }
            }
        },
        computed: {
            createParams() {
                return {
                    rank: this.levels.length
                }
            },
            fieldConfig() {
                let me = this
                return [{
                    field: 'name',
                    label: 'Level Name',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { required: true, limit: 50 },
                    width: '100%',
                    attrs: {
                        required: true,
                        flat: true,
                        solo: true,
                        dense: true,
                        counter: 50
                    }
                }, {
                    field: 'description',
                    label: 'Description',
                    component: 'v-textarea',
                    validatorKey: 'text',
                    validatorConfig: { limit: 255, required: true},
                    width: '100%',
                    attrs: {
                        required: true,
                        flat: true,
                        solo: true,
                        dense: true,
                        counter: 255
                    }
                }]
            },
            columns() {
                let me = this
                return [this.$grid.checkColumn(), {
                    headerName: 'Level',
                    field: 'name',
                    flex: 1,
                    editable: true
                }, {
                    headerName: 'Description',
                    field: 'description',
                    flex: 1,
                    editable: true
                }, {
                    headerName: 'Icon',
                    field: 'icon',
                    maxWidth: 100,
                    editable: true,
                    cellRenderer(v) { return me.getColor(v.value) },
                    cellEditor: 'colorSelect',
                    cellEditorParams: v => {
                        return {
                            items: me.colors,
                            rowDataKey: 'id',
                            keyProp: 'id',
                            labelProp: 'color',
                        }
                    }
                }, {
                    headerName: 'Created',
                    field: 'created'
                }, {
                    headerName: 'Order',
                    field: 'rank',
                    editable: true,
                    cellEditor: 'numericEditor',
                    maxWidth: 100,
                    cellStyle: { textAlign: 'right' }
                }, this.$grid.toggleColumn('Active', 'active', 'Allows level to be used during the creation process', () => { })]
            }
        },
        methods: {
            finishCreate() {
                this.createDialog.show = false
                this.createDialog.records = [{
                    icon: 'int_level_orange',
                    active: true
                }]

                this.$refs.grid.$refs.crud.read()
                this.$store.dispatch('global/reloadShareableStores') // Must be reloaded to obtain new intv level
            },
            startCreate() {
                this.createDialog.show=true
                this.createDialog.records = [{
                    icon: 'int_level_orange',
                    active: true,
                    rank: this.levels.length+1
                }]
            },
            updateCrud(crud, meta) {

            },
            onUpdated() {
                this.$store.dispatch('global/reloadShareableStores') // Must be reloaded to obtain potentially edited intv color/etc.
            },
            crudConfirmDelete() {

            },
            getColor(v) {
                let c = ''
                switch (v) {
                    case 'int_level_orange':
                        c = '<i class="fa fa-square" style="color: orange;"></i>';
                        break;
                    case 'int_level_maroon':
                        c = '<i class="fa fa-square" style="color: #9b3939;"></i>';
                        break;
                    case 'int_level_baby_blue':
                        c = '<i class="fa fa-square" style="color: #4af4f7;"></i>';
                        break;
                    case 'int_level_purple':
                        c = '<i class="fa fa-square" style="color: #ce4cbd;"></i>';
                        break;
                    case 'int_level_green':
                        c = '<i class="fa fa-square" style="color: #4bc647;"></i>';
                        break;
                    case 'int_level_yellow':
                        c = '<i class="fa fa-square" style="color: yellow;"></i>';
                        break;
                }
                return c
            },
        }
    }
</script>

<style scoped lang="scss">
.color-picker {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;

    &-selected {
        border: 2px solid #303030;
    }
}
</style>
