<template>
    <v-layout fill-height :column="labelAlign == 'top'" :row="labelAlign == 'left'">
        <v-flex shrink>
            <div
                class="field-label"
                :style="[{'margin-top': labelAlign=='left' ? labelTopMargin : null, width: labelWidth}]"
            >{{labelText}}</div>
        </v-flex>

        <v-flex align-center>
            <slot/>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        name: 'LabelField',
        data() {
            return {

            }
        },
        props: {
            labelAlign: {
                default: 'top'
            },
            labelText: {
            },
            labelWidth: {
                default: '150px'
            },
            labelTopMargin: {
                default: '0px'
            }
        }
    }
</script>

<style lang="scss" scoped>
.field-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    
    color: #050F2D;
}

.left-label {
    color: green;
}
</style>