<template>
    <fe-left-menu-layout
        title="Validate"
        :menuItems="menuItems"
        v-model="activeItem"
        :collapsible="false"
        menuWidth="250px"
    >
        <template v-slot:toolbar>
            <fe-btn usage="secondary" @click="doSetReady">Set Ready</fe-btn>
        </template>

        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.upload"
            :readParams="{ id: id}"
            @read="v => uploadRecord = v"
        />

        <div class="flex-grow-1 flex-fill flex-column no-scroll" v-if="record">
            <validate-schools v-if="activeItem.key == 'unknown_schools'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-assessment-groups v-if="activeItem.key == 'unknown_data_point_types'" :rec="record" @count="updateCount(activeItem.key, ...arguments)" @reloadCounts="getCounts"/>
            <validate-assessments v-if="activeItem.key == 'unknown_sub_categories'" :rec="record" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-grades v-if="activeItem.key == 'unknown_grades'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-home-language v-if="activeItem.key == 'unknown_home_languages'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-incident-behavior v-if="activeItem.key == 'unknown_incident_behaviors'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-incident-response v-if="activeItem.key == 'unknown_incident_responses'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-incident-location v-if="activeItem.key == 'unknown_incident_locations'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-incident-motivation v-if="activeItem.key == 'unknown_incident_motivations'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-student-disability v-if="activeItem.key == 'unknown_student_disabilities'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-meal-status v-if="activeItem.key == 'unknown_meal_statuses'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-student v-if="activeItem.key == 'unknown_students'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-user v-if="activeItem.key == 'unknown_users'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-user-group v-if="activeItem.key == 'unknown_user_groups'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-attendance-excused v-if="activeItem.key == 'unknown_attendance_excused'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-attendance-reason v-if="activeItem.key == 'unknown_attendance_reasons'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-attendance-status v-if="activeItem.key == 'unknown_attendance_statuses'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-intervention-type v-if="activeItem.key == 'unknown_intervention_types'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-score-details v-if="activeItem.key == 'unknown_score_detail_types'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-demographic v-if="activeItem.key == 'unknown_demographics'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-score-alpha-maps v-if="activeItem.key == 'unknown_score_alpha_maps'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
            <validate-data-point-names v-if="activeItem.key == 'unknown_data_point_names'" :uploadId="id" @count="updateCount(activeItem.key, ...arguments)"/>
        </div>
    </fe-left-menu-layout>
</template>

<script>
    import ValidateStudent from './validate/Student'
    import ValidateSchools from './validate/Schools'
    import ValidateAssessmentGroups from './validate/AssessmentGroups'
    import ValidateAssessments from './validate/Assessments'
    import ValidateGrades from './validate/Grades'
    import ValidateHomeLanguage from './validate/HomeLanguage'
    import ValidateIncidentBehavior from './validate/IncidentBehavior'
    import ValidateIncidentResponse from './validate/IncidentResponse'
    import ValidateIncidentLocation from './validate/IncidentLocation'
    import ValidateIncidentMotivation from './validate/IncidentMotivation'
    import ValidateStudentDisability from './validate/StudentDisability'
    // import Validate from './validate/'
    import ValidateMealStatus from './validate/MealStatus'
    import ValidateUser from './validate/User'
    import ValidateAttendanceExcused from './validate/AttendanceExcused'
    import ValidateAttendanceReason from './validate/AttendanceReason'
    import ValidateAttendanceStatus from './validate/AttendanceStatus'
    import ValidateUserGroup from './validate/UserGroup'
    import ValidateInterventionType from './validate/InterventionType'
    import ValidateScoreDetails from './validate/ScoreDetails'
    import ValidateScoreAlphaMaps from './validate/ScoreAlphaMaps'
    import ValidateDataPointNames from './validate/DataPointNames'
    import ValidateDemographic from './validate/Demographic'

    export default {
        name: 'ValidateUpload',
        props: ['id'],
        components: {
            ValidateSchools,
            ValidateAssessmentGroups,
            ValidateAssessments,
            ValidateGrades,
            ValidateHomeLanguage,
            ValidateIncidentBehavior,
            ValidateIncidentResponse,
            ValidateIncidentLocation,
            ValidateIncidentMotivation,
            ValidateStudentDisability,
            ValidateMealStatus,
            ValidateUser,
            ValidateAttendanceExcused,
            ValidateAttendanceReason,
            ValidateAttendanceStatus,
            ValidateUserGroup,
            ValidateInterventionType,
            ValidateStudent,
            ValidateScoreDetails,
            ValidateDemographic,
            ValidateScoreAlphaMaps,
            ValidateDataPointNames,
        },
        data() {
            return {
                uploadRecord: [],
                show: null,
                yellow: '#fdff85',
                green: '#beffab',
                menuItems: [],
                activeItem: {},
                columns: [],
                gridItems: [],
                record: null,
                checkColumn: {
                    width: 40,
                    pinned: 'left',
                    headerCheckboxSelection: true,
                    checkboxSelection: true
                },
                lookup: {
                    unknown_schools: 'Schools',
                    unknown_data_point_types: 'Assessment Groups',
                    unknown_sub_categories: 'Assessments',
                    unknown_grades: 'Grades',
                    unknown_home_languages: 'Languages',
                    unknown_incident_behaviors: 'Codes',
                    unknown_incident_responses: 'Responses',
                    unknown_incident_locations: 'Locations',
                    unknown_incident_motivations: 'Motivations',
                    unknown_student_disabilities: 'Disabilities',
                    unknown_meal_statuses: 'Meal Status',
                    unknown_users: 'Users',
                    unknown_user_groups: 'Groups',
                    unknown_attendance_excused: 'Excused Codes',
                    unknown_attendance_reasons: 'Reason Codes',
                    unknown_attendance_statuses: 'Status Codes',
                    unknown_intervention_types: 'Intv Types',
                    unknown_students: 'Students',
                    unknown_score_detail_types: 'Score Details',
                    unknown_demographics: 'Demographics',
                    unknown_score_alpha_maps: 'Score Displays',
                    unknown_data_point_names: 'Windows',
                }
            }
        },
        mounted() {
            this.getCounts()
        },
        watch: {
        },
        methods: {
            selectRow(selection) {
                this.selections = this.$refs.grid.gridApi.getSelectedRows()
            },
            updateCount(key, count) {
                let r = this.menuItems.find(m => m.key == key)
                r.name = this.lookup[key] + ' - ' + count
            },
            doSetReady() {
                let me = this

                this.$messageBox({
                    title: 'Set Ready',
                    persistent: true,
                    maxWidth: 400,
                    message: 'Are you sure you want this upload set to ready?  <br><br>This will skip any records still in validation and only process' +
                        ' known data on the file.',
                    actions: [{
                        text: 'Cancel',
                    }, {
                        text: 'Ok',
                        onClick: () => {
                            me.record.upload_status_id = 4
                            me.$refs.crud.update(me.record)
                                .then(response => {

                                })
                        }
                    }]
                })
            },
            getCounts() {
                this.menuItems = []

                this.$modelGet('upload', { id: this.$props.id })
                    .then(responses => {
                        this.record = responses[0]

                        this.$axios.get('verifyUpload.php?action=unknown_counts&upload_id='+this.$props.id)
                            .then(response => {
                                if (response.data) {
                                    this.setMenuItems(response.data)
                                    // this.menuItems = response.data
                                }
                            })
                            .catch(err => {
                                console.error('error', err)
                            })
                    }).catch(error => {
                        console.error('Unable to load upload', error)
                    })

            },
            setMenuItems(records) {
                Object.keys(records).forEach(key => {
                    if (records[key] > 0) {
                        // let name = key.replace('unknown_', '')
                        // name = name.replace(/_/g, ' ')
                        if (this.lookup[key]) {
                            this.menuItems.push({
                                name: this.lookup[key] + ' - '+records[key],
                                key: key
                            })
                        } else {
                            console.warn('missing validation key', key)
                        }
                    }
                })

                this.activeItem = (this.activeItem.key && this.menuItems.find(x=>x.key==this.activeItem.key))
                    || (this.menuItems.length && this.menuItems[0])
                    || {}

            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
