import axios from 'axios'
import MessageBox from '@/components/common/MessageBox'


const ecGoogle = {
    install(Vue, options) {

        // Determine status of Google Sync
        Vue.prototype.$googleStatus = function (shareableStores, userPreferences) {
            // if district has it disabled
            if (!(shareableStores.district.google_calendar_enabled === 1)) {
                return {
                    showGoogleCalendarSync: false,
                    googleCalendarSync: false,
                };
            }

            // if user has never sync on
            if (this.$_.get(userPreferences, ['GOOGLE_CALENDAR_COMPONENTS', 'user_value']) === 'rememberNo') {
                return {
                    showGoogleCalendarSync: false,
                    googleCalendarSync: false,
                };
            }

            // if user has always sync on
            if (this.$_.get(userPreferences, ['GOOGLE_CALENDAR_COMPONENTS', 'user_value']) === 'rememberYes') {
                return {
                    showGoogleCalendarSync: false,
                    googleCalendarSync:     true,
                };
            }

            // ask user per event
            return {
                showGoogleCalendarSync: true,
                googleCalendarSync:     this.$_.get(userPreferences, ['GOOGLE_CALENDAR_COMPONENTS', 'user_value']) === 'yes',
            };
        },

        Vue.prototype.$verifyGoogleLogin = function() {
            return new Promise((resolve, reject) => {
                this.$axios.get('googleCalendarIntegrate.php?action=verifyGoogleLogin')
                    .then(response => {
                    if (response.data) {
                        resolve(response.data)
                    } else {
                        reject(err)
                    }
                })
                .catch(err => { reject(err) })
            })
        }

        // Callback is optional
        Vue.prototype.$promptGoogleLogin = function(SessionUser, cb) {
            let self = this

            return new Promise((resolve, reject) => {
                gapi.load('auth2', () => {
                    let auth2 = gapi.auth2.getAuthInstance()
                    auth2.grantOfflineAccess({
                        scope: 'profile email https://www.googleapis.com/auth/calendar.events'
                    }).then(offlineResp => {
                        if (offlineResp.code) {
                            self.$sendTokenToServer({
                                code: offlineResp.code
                            }, function (result) {
                                if (result.data.success === true) {
                                    SessionUser.is_google_login = true
                                    return true;
                                } else {
                                    self.$snackbars.$emit('new', {
                                        text: result.data.msg,
                                        usage: 'error'
                                    })
                                    return false;
                                }
                            })
                        }
                    })
                })
            })
        }

        Vue.prototype.$sendTokenToServer = function(payload, callback) {
            let self = this

            return new Promise((resolve, reject) => {
                this.$axios.post('googleCalendarIntegrate.php?action=setGoogleToken', payload)
                    .then(response => {
                        if (callback) {
                            callback(response)
                        }
                        resolve(true)
                    })
            })
        }

        // If user has already linked their account for Google Calendar,
        // this function will "do nothing"
        Vue.prototype.$linkGoogleAccount = function(sessionUser) {
            this.$verifyGoogleLogin().then((response) => {
                let isGoogleLogin = response.success
                if (!isGoogleLogin) {
                    this.$promptGoogleLogin({...sessionUser.user}).then(success => {
                        if (success) {
                            this.$store.commit('set', {
                                module: 'global',
                                state: 'googleCalendarSyncPreference',
                                value: v
                            })
                        }
                    }).catch(err => {
                        console.warn('err', err)
                    })
                } else {
                    // SessionUser.set('is_google_login', true);
                }
            }).catch(err => {

            })
        }

        // Setting will be 'yes' | 'no' | 'rememberYes' | 'rememberNo'
        Vue.prototype.$syncToGoogleCalendar = function(sessionUser, scheduleId, cb) {
            let me = this

            // Backend expects payload of an array as schedule id and each user id, separated by 2 underscores
            let data = { sIds: [`${scheduleId}__${sessionUser.user.id}`] }

            this.$axios.post(this.$models.getUrl('meetingSchedule', 'syncToGoogleCalendar') + `&setting=yes`, data).then((resp) => {
                if (this.$_.get(resp, ['data', 'msg']) == 'prompt') {
                    this.$promptGoogleLogin(sessionUser, (err) => {
                        return cb(err)
                    })
                }

                // Backend returns a success value of -1 if user said "never" sync
                else if (this.$_.get(resp, ['data', 'success']) == '-1') {
                    cb(null, 'skip')
                }

                else if (resp.data?.success === false || resp.data?.success === '0') {
                    cb(new Error(resp.data?.msg || 'Google Calendar Sync Failed'))
                }

                else {
                    cb(null)
                }
            })
        }
    }
}

export default ecGoogle
