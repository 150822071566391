let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}
export default {
    subCategorySchoolGet: {
        defaults: {
            endpoint: 'crud.php?property=SubCategorySchool',
            rootProperty: 'sub_category_school'
        },
        ...standardCrud
    },
    subCategorySchoolAdd: {
        defaults: {
            endpoint: 'subCategorySchool.php?property=add',
            rootProperty: 'sub_category_school'
        },
        ...standardCrud
    },
    subCategorySchoolReplace: {
        defaults: {
            endpoint: 'subCategorySchool.php?property=replace',
            rootProperty: 'sub_category_school'
        },
        ...standardCrud
    },
    subCategorySchoolRemove: {
        defaults: {
            endpoint: 'subCategorySchool.php?property=remove',
            rootProperty: 'sub_category_school'
        },
        ...standardCrud
    },
    subCategorySchoolCreate: {
        defaults: {
            endpoint: 'subCategorySchool.php?property=create',
            rootProperty: 'sub_category_school'
        },
        ...standardCrud
    }
}