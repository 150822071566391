import { render, staticRenderFns } from "./SmartTagField.vue?vue&type=template&id=0f94e166&scoped=true"
import script from "./SmartTagField.vue?vue&type=script&lang=js"
export * from "./SmartTagField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f94e166",
  null
  
)

export default component.exports