export default {
    scoreCalcType: {
        defaults: {
            endpoint: 'subcategories.php',
            rootProperty: 'score_calc_types'
        },
        read: {
            params: {
                action: 'get',
                property: 'score_calc_type'
            }
        }
    }
}