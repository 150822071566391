var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form-common-config',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"fieldSpecific",fn:function({rec}){return [_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Attendance Type")]),_c('fe-remote-combo',{staticStyle:{"margin-left":"auto"},attrs:{"byId":"","itemValue":"value","items":[
                    {value:'period_list', name:'Absence Records'},
                    {value:'affected_rate', name:'Full Day Rate'},
                    {value:'period_rate', name:'Period Rate'},
                    {value:'equiv_rate', name:'SIS Reported Rate'},
                    {value:'affected_count', name:'Total Missed Days'},
                    {value:'equiv_count', name:'SIS Missed Days Count'},
                ],"clearable":false},model:{value:(_vm.formFieldSavedSearch.config_json.attendance_type),callback:function ($$v) {_vm.$set(_vm.formFieldSavedSearch.config_json, "attendance_type", $$v)},expression:"formFieldSavedSearch.config_json.attendance_type"}})],1),_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Attendance Search Range")]),_c('fe-remote-combo',{staticStyle:{"margin-left":"auto"},attrs:{"byId":"","itemValue":"value","items":[
                    {value:'form_created', name:'Year smartFORM Created'},
                    {value:'current', name:'Current Year'},
                    {value:'school_year', name:'School Year'},
                    {value:'all', name:'All Years'},
                ],"clearable":false},model:{value:(_vm.formFieldSavedSearch.config_json.date_type),callback:function ($$v) {_vm.$set(_vm.formFieldSavedSearch.config_json, "date_type", $$v)},expression:"formFieldSavedSearch.config_json.date_type"}})],1),(_vm.formFieldSavedSearch.config_json.date_type == 'school_year')?_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("School Year")]),_c('fe-remote-combo',{staticStyle:{"margin-left":"auto"},attrs:{"byId":"","multiple":"","moreLabel":"Years","clearable":false,"rootProperty":"years","url":"qry.php?action=get&property=school_years"},model:{value:(_vm.formFieldSavedSearch.school_year_id),callback:function ($$v) {_vm.$set(_vm.formFieldSavedSearch, "school_year_id", $$v)},expression:"formFieldSavedSearch.school_year_id"}})],1):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v(" As of Creation Date "),_c('v-flex',{staticStyle:{"display":"inline-block","margin-right":"10px"}},[_c('fe-info-tip',{attrs:{"tooltip":"This will ONLY display attendance data up to the smartFORM creation date."}})],1)],1),_c('fe-switch',{staticStyle:{"margin-left":"auto"},attrs:{"true-value":1,"false-value":0},model:{value:(_vm.formFieldSavedSearch.config_json.to_creation),callback:function ($$v) {_vm.$set(_vm.formFieldSavedSearch.config_json, "to_creation", $$v)},expression:"formFieldSavedSearch.config_json.to_creation"}})],1),(_vm.formFieldSavedSearch.config_json.attendance_type == 'period_list')?_c('div',[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Table Settings")]),_c('fe-remote-combo',{staticStyle:{"margin-left":"auto"},attrs:{"byId":"","multiple":"","moreLabel":"Columns","itemValue":"value","items":[
                    {value:'occurrence_date', name:'Date'},
                    {value:'occurrence_weekday', name:'Weekday'},
                    {value:'attendance_term_name', name:'Term'},
                    {value:'attendance_period', name:'Period'},
                    {value:'attendance_course', name:'Course'},
                    {value:'attendance_status_name', name:'Status'},
                    {value:'attendance_reason_name', name:'Reason'},
                    {value:'attendance_excused_name', name:'Excused'},
                ],"clearable":false},model:{value:(_vm.formFieldSavedSearch.config_json.display_columns),callback:function ($$v) {_vm.$set(_vm.formFieldSavedSearch.config_json, "display_columns", $$v)},expression:"formFieldSavedSearch.config_json.display_columns"}})],1):_vm._e(),_c('fe-crud',{key:rec.id,ref:"crud",attrs:{"readParams":{form_field_id:rec.id},"config":_vm.$models.formFieldSavedSearch,"autoload":""},on:{"read":function($event){return _vm.processRead($event,rec)}}})]}}])},'form-common-config',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }