<template>
    <collapsible-panel title="Notes" smallTitle>

        <p v-if="notes.length == 0">No notes created on this day.</p>

        <topic-message
            v-for="msg in children"
            v-if="!msg.private_flag || msg.private_flag && msg.created_by == sessionUser.user.id"
            :key="`topic-message-${msg.id}`"
            :message="msg"
            :messages="notes"
            disabled
        />

        <fe-crud
            ref="crud"
            autoload
            v-if="meeting && occurrence"
            refresh-on-config
            :config="$models.meetingNote"
            :readParams="crudParams"
            @read="notes = $event"
        />

    </collapsible-panel>
</template>

<script>
    import Message from '@/components/common/Message'
    import TopicMessage from './TopicMessage'
    import CollapsiblePanel from '@/components/common/CollapsiblePanel'
    import {mapState} from "vuex"

    export default {
        name: 'Notes',
        components: { CollapsiblePanel, TopicMessage },
        props: {
            meeting: { type: Object, required: true },
            occurrence: { type: Object }
        },
        data () {
            return {
                notes: []
            }
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            stamp () { return this.$dayjs(this.occurrence.occurrence, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss") },
            crudParams () {
                return {
                    dashboard_meeting_id: this.meeting.id,
                    created: this.stamp
                }
            },
            noteIds () { return this.notes.map(itm => itm.id) },
            children () { return this.notes.filter(itm => !this.noteIds.includes(itm.parent_message_id)) }
        }
    }
</script>

<style lang="scss" scoped>
</style>
