import axios from 'axios'

export const intervention = {
    namespaced: true,
    state: {
        school: null,
        interventionTypeId: null,
        editBehaviorPlan: null,
        categoryId: null
    },

    // getters: {
    //     allCollections: state => {
    //         return state.allCollections.user_dashboards.concat(state.allCollections.shared_dashboards)
    //     }

    // },

    mutations: {
        set(state, payload) {
            state[payload.key] = payload.value
        }
    },

    actions: {

    }
}