<template>
    <div class="flex-fill flex-column no-scroll">
        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.unknownIncidentLocation"
            :readParams="{ upload_id: $props.uploadId }"
            @read="v => items = v"
        />

        <fe-mask v-show="loading" :showLoader="true" />

        <fe-dialog
            width="400"
            title="Create Incident Location Alias"
            v-if="dialog.show"
            @dismiss="dialog.show=false"
            @close="dialog.show=false"
            @accept="setAlias"
            acceptButtonText="Alias"
            :acceptButtonDisabled="!dialog.record"
            persistent
        >
            <v-form>
                <fe-label>Location</fe-label>
                <v-autocomplete
                    :items="incidentLocations"
                    v-model="dialog.record"
                    itemValue="id"
                    itemText="name"
                    required
                    flat solo dense
                />
            </v-form>
        </fe-dialog>

        <fe-grid
            :columnDefs="columns"
            :rowData="items"
            class="flex-grow-1"
            displayDensity="small"
            ref="grid"
            :showAddRowBtn="false"
            @selectionChanged="selectRow"
            @cellValueChanged="sanitize"
            showToolbar
        >
            <template v-slot:toolbar-items>
                <fe-btn usage="primary" v-if="canSave" @click="doSave">Save</fe-btn>

                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <fe-btn usage="primary" v-on="on" :disabled="!selections.length">
                            Actions
                        </fe-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="(item, index) in tools" :key="index" @click="item.handler()">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </fe-grid>
    </div>
</template>

<script>
    export default {
        name: 'UnknownIncidentLocations',
        props: {
            uploadId: {
                required: true
            }
        },
        data() {
            let me = this

            return {
                loading: false,
                canSave: false,
                selections: [],
                incidentLocations: [],
                items: [],
                dialog: {
                    show: false,
                    record: null
                },
                pendingText: '<br><p class="caption mt-2">This will also save any pending changes.</p>',
                originalItems: [],
                tools: [{
                    title: 'Create Incident Location',
                    description: 'Create as a new location in eduCLIMBER',
                    handler() {
                        me.$messageBox({
                            title: 'Confirm Create',
                            persistent: true,
                            maxWidth: 600,
                            message: 'Are you sure you want to create ' + me.selections.length + ' location' + (me.selections.length > 1 ? 's' : '') +
                                me.pendingText,
                            actions: [{
                                text: 'Cancel', primary: false
                            }, {
                                text: 'Create', primary: true,
                                onClick: () => {
                                    me.selections.forEach(rec => {
                                        rec.matched_incident_location_id = null
                                        rec.new_incident_location =  true
                                        rec.admin_only = false
                                    })
                                    me.doSave()
                                }
                            }]
                        })
                    }
                }, {
                    title: 'Alias to Incident Location',
                    description: 'Set this location name as an alternate known name for a selected location',
                    handler() {
                        me.dialog.record = null
                        me.dialog.show = true
                    }
                }],
                columns: []
            }
        },
        watch: {
            items(v) {
            }
        },
        mounted() {
            this.loadIncidentLocations()
        },
        methods: {
            doSave() {
                let dirty = this.$isDirty(this.originalItems, this.items)
                this.loading = true
                this.$refs.crud.update(dirty)
                    .then(response => {
                        this.loadIncidentLocations()
                    })
                    .finally(() => { 
                        this.loading = false 
                    })
            },
            setAlias() {
                let me = this
                this.dialog.show = false
                this.selections.forEach(rec => { 
                    rec.new_incident_location = false
                    rec.matched_incident_location_id = this.dialog.record 
                })
                
                this.sanitize()
                this.$refs.grid.gridApi.deselectAll()
                this.doSave()
            },
            selectRow(row) {
                this.selections = this.$refs.grid.gridApi.getSelectedRows()
            },
            sanitize() {
                // this will check to see if the original fetched data set has been manipulated
                let dirty = this.$isDirty(this.originalItems, this.items)
                if (dirty) {
                    this.canSave = true
                } else {
                    this.canSave = false
                }
                // Since we are changing cells behind the ag-grid api, call refresh
                this.$refs.grid.gridApi.refreshCells()
            },
            loadIncidentLocations() {
                let me = this
                let id = this.$props.uploadId
                this.originalItems = []
                this.items = []

                this.loading = true
                this.$modelGet('incidentLocation')
                    .then(response => {
                        this.incidentLocations = response

                        this.columns = [me.$grid.checkColumn(), {
                            headerName: 'Import Location Name',
                            field: 'name',
                            sortable: true,
                            flex: 1
                        }, {
                            headerName: 'Alias To',
                            field: 'matched_incident_location_id',
                            editable: true,
                            flex: 1,
                            tooltipValueGetter: (param) => 'This location will also be known as the selected location in eduCLIMBER',
                            cellRenderer: v => {
                                if (v.value) {
                                    let r = me.incidentLocations.find(rec => { return rec.id == v.value })
                                    if (r) return r.name
                                }
                                return ''
                            },
                            cellEditorFramework: "FeGridChipSelect",
                            cellEditorParams: {
                                rowDataKey: "matched_incident_location_id",
                                mode: "edit",
                                items: me.$data.incidentLocations,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true
                            }
                        }]
                        this.$refs.crud.read()
                            .then(() => {
                                this.items.forEach(rec => {
                                    rec.incident_location_id   = undefined
                                    rec.admin_only = false
                                })
                                this.originalItems = this.$_.cloneDeep(this.items)
                                this.$emit('count', this.originalItems.length)
                            })
                            .finally(() => { 
                                this.loading = false 
                                this.sanitize()
                            })
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>