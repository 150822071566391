<template>
    <div class="message-parent">
        <template v-if="editable">
            <div style="font-size: 14px;" class="mb-2" v-if="!dense">
                <b>New Comment</b>
            </div>
            <v-form class="editor" @submit.prevent>
                <fe-editor
                    :toolbarExtras="['|', 'redo', 'undo']"
                    ref="editor"
                    :submittable="!hideSubmit"
                    style="word-break: break-all"
                    dense
                    :actionItemsBelow="actionItemsBelow"
                    @submit="createMsg"
                />
                <v-checkbox
                    v-if="allowPrivate"
                    v-model="private"
                    label="Private"
                    color="primary"
                    class="mb-2"
                    hide-details
                />
            </v-form>
        </template>

        <div v-if="toolbar && !createOnly" class="d-flex message-tool-bar">
            <div style="font-size: 14px;" v-if="!dense"><b>Recent Comments</b></div>
            <fe-icon-btn
                class="ml-auto"
                useIcon="fas fa-print"
                @click="print()"
            />

            <fe-icon-btn
                :useIcon="sortIcon"
                @click="sort()"
            />
        </div>

        <template v-if="createOnly"/>

        <div v-else-if="items.length === 0" class="text-xs-center pa-4 ma-4">
            <p class="caption">
                {{ noMessagesText }}
            </p>
        </div>

        <div v-else class="message-list" ref="messageContainer">
            <div
                v-for="item in items"
                class="message-container"
                :key="`message-` + item.id"
            >
                <div class="d-flex"
                     v-if="!item.private_flag || item.private_flag && item.created_by == sessionUser.user.id">
                    <div :style="{width: dense ? '50px': '60px'}" class="align-self-top flex-shrink-0">
                        <v-avatar :size="dense ? 28 : 37">
                            <img v-if="item.photo_url" :src="item.photo_url" :alt="item.created_by_full_name">
                            <v-icon v-else>fas fa-user</v-icon>
                        </v-avatar>
                    </div>

                    <div class="flex-grow-1">
                        <div>
                            <b v-html="item.created_by_full_name + ' &bull; ' + new Date(item.created).toLocaleDateString() + (item.glyph ? ' &bull; ' : '')"></b>
                            <v-icon v-if="item.glyph">{{ item.glyph }}</v-icon>
                            <v-icon v-if="item.private_flag" class="ml-2" color="#050F2D" size="12">fas fa-lock</v-icon>
                        </div>

                        <div v-if="editing.id === item.id" class="message-body d-flex mt-1">
                            <fe-editor
                                :toolbarExtras="['|', 'redo', 'undo']"
                                v-model="item.value"
                                actionItemsBelow
                                submittable
                                cancelable
                                @input="updateEditing"
                                @submit="updateMsg"
                                @cancel="cancel"
                            />
                        </div>

                        <div v-else v-html="item.value" class="message-body"/>

                        <v-chip v-if="item.message_type_value">{{ item.message_type_value }}</v-chip>
                    </div>

                    <div v-if="editable && editing.id !== item.id" style="width: 60px;">
                        <v-menu offset-y left>
                            <template v-slot:activator="{ on }">
                                <fe-icon-btn
                                    v-on="on"
                                    useIcon="fas fa-ellipsis-v"
                                />
                            </template>

                            <v-list dense>
                                <v-list-item @click="beginReply(item)">
                                    <v-list-item-content>
                                        Reply
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item v-if="item.can_modify && (!item.replies.length)" @click="beginEdit(item)">
                                    <v-list-item-content>
                                        Edit
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item v-if="item.can_modify && (!item.replies.length)"
                                             @click="beginDelete(item)">
                                    <v-list-item-content>
                                        Delete
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>

                <div v-if="replying.id === item.id" class="ml-12 mt-6">
                    <div style="font-size: 14px;"><b>Reply to Comment</b></div>
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <fe-editor
                                :toolbarExtras="['|', 'redo', 'undo']"
                                actionItemsBelow
                                submittable
                                cancelable
                                @input="updateEditing"
                                @cancel="cancel"
                                @submit="replyMsg"
                            />
                        </div>
                    </div>
                </div>

                <template v-if="item.replies.length">
                    <div
                        v-for="reply in item.replies"
                        :key="`reply-` + reply.id"
                        class="reply-container"
                    >
                        <div class="d-flex">
                            <div :style="{width: dense ? '50px': '60px'}" class="align-self-top">
                                <v-avatar :size="dense ? 28 : 37">
                                    <img
                                        v-if="reply.photo_url"
                                        :src="reply.photo_url"
                                        :alt="reply.created_by_full_name"
                                    >
                                    <v-icon v-else>fas fa-user</v-icon>
                                </v-avatar>
                            </div>

                            <div class="flex-grow-1">
                                <div>
                                    <b v-html="reply.created_by_full_name + ' &bull; ' + new Date(reply.created).toLocaleDateString() + (reply.glyph ? ' &bull; ' : '')"></b>
                                    <v-icon v-if="reply.glyph">{{ reply.glyph }}</v-icon>
                                </div>

                                <div v-if="editing.id === reply.id" class="message-body d-flex mt-1">
                                    <fe-editor
                                        :toolbarExtras="['|', 'redo', 'undo']"
                                        v-model="reply.value"
                                        actionItemsBelow
                                        submittable
                                        cancelable
                                        @submit="updateMsg"
                                        @cancel="cancel"
                                    />
                                </div>
                                <div v-else v-html="reply.value" class="message-body"/>

                                <v-chip v-if="reply.message_type_value">{{ reply.message_type_value }}</v-chip>
                            </div>

                            <div v-if="editable && editing.id !== reply.id && reply.can_modify" style="width: 60px;"
                                 class="align-self-center">
                                <v-menu offset-y left>
                                    <template v-slot:activator="{ on }">
                                        <fe-icon-btn
                                            v-on="on"
                                            useIcon="fas fa-ellipsis-v"
                                        />
                                    </template>

                                    <v-list dense>
                                        <v-list-item @click="beginEdit(reply)">
                                            <v-list-item-content>
                                                Edit
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item @click="beginDelete(reply)">
                                            <v-list-item-content>
                                                Delete
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <fe-crud
            ref="crud"
            :autoload="autoload && !createOnly && !disableReadAccess"
            :config="crudConfig"
            :readParams="params"
            @read="postRead"
        />

        <fe-dialog
            v-if="deleting"
            title="Confirm Delete"
            :body="'Are you sure you want to delete this comment'"
            acceptButtonText="Delete"
            @accept="deleteMsg(deleting)"
            @dismiss="cancel"
        />
    </div>
</template>

<script>
import {mapState} from "vuex"

export default {
    name: 'Message',

    props: {
        params: {
            type: Object,
            return: () => {
            }
        },
        autoload: {
            type: Boolean,
            default: true
        },
        disableReadAccess: {
            type: Boolean,
            default: false
        },
        createOnly: {
            type: Boolean,
            default: false
        },
        hideSubmit: {
            type: Boolean,
            default: false
        },
        messages: {
            type: Array,
            default: () => []
        },
        noMessagesText: {
            type: String,
            default: 'No Messages'
        },
        allowPrivate: {
            type: Boolean,
            default: true
        },
        dense: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        },
        toolbar: {
            type: Boolean,
            default: true
        },
        actionItemsBelow: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            items: [],
            sortOrder: 'asc',
            editing: {},
            replying: {},
            deleting: undefined,
            private: false
        }
    },

    computed: {
        ...mapState('global', ['sessionUser']),
        crudConfig() {
            return this.$_.cloneDeep(this.$models.message)
        },

        sortIcon() {
            return this.sortOrder === 'asc' ? 'fas fa-sort-amount-down' : 'fas fa-sort-amount-up'
        }
    },

    watch: {
        private(v, o) {
            if (v !== o) this.$emit('unsavedChanges', true)
            else this.$emit('unsavedChanges', false)
        },
        messages: {
            handler() {
                if (this.messages) this.items = this.messages
            },
            immediate: true
        }
    },

    mounted() {
        if (this.messages) {
            this.items = this.messages
        }
    },

    methods: {
        print() {
            // Get HTML to print from element
            let html = this.$refs.messageContainer.innerHTML
            // Get all stylesheets HTML
            let stylesHtml = '';
            for (let node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            let pWin = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

            pWin.document.write(`<!DOCTYPE html>
                <html>
                <head>
                    ${stylesHtml}

                    <style>
                        .v-avatar img {
                            display: none !important;
                        }
                    </style>

                </head>
                <body>
                    ${html}
                </body>
                </html>`);

            pWin.document.close()
            pWin.focus()
            pWin.print()
            pWin.close()
        },
        doSubmit() {
            try {
                let edtr = this.$refs.editor
                let note = edtr.formatNote(edtr.editorInstance.getData())
                this.createMsg(note)
            } catch (e) {
                this.$snackbars.$emit('new', {text: 'There was an issue saving this comment.', usage: 'error'})
            }
        },
        postRead(items) {
            this.items = items
            this.items.forEach(i => {
                if (!i.replies) i.replies = []
            })
            this.sort()
        },

        sort() {
            if (this.sortOrder === 'asc') {
                this.items = this.items.sort((a, b) => new Date(b.created) - new Date(a.created))
                this.sortOrder = 'desc'
            } else {
                this.items = this.items.sort((a, b) => new Date(a.created) - new Date(b.created))
                this.sortOrder = 'asc'
            }
        },

        createMsg(v) {
            let crud = this.$refs.crud
            let item = Object.assign({}, this.params, {value: v, private_flag: !!this.private})

            crud.create([item])
                .finally(() => {
                    crud.read().then(() => {
                        this.sort('asc')
                        this.$refs.editor.clearNote()
                        this.$emit('created', item, this.items)
                        this.$emit('updateCount', this.params.student_id, true, 'student_comments', this.items)
                    })
                })
        },

        beginEdit(v) {
            this.cancel()
            this.editing = v
            this.$emit('unsavedChanges', true)
        },

        updateMsg(v) {
            let item = Object.assign({}, this.editing, this.params, {value: v})

            let crud = this.$refs.crud

            crud.update([item])
                .finally(() => {
                    crud.read().then(() => {
                        this.sort()
                        this.cancel()
                    })
                })
        },

        beginReply(v) {
            this.cancel()
            this.replying = v
        },

        replyMsg(v) {
            let crud = this.$refs.crud
            let newData = {
                parent_message_id: this.replying.id,
                value: v
            }
            let item = Object.assign({}, this.params, newData)

            crud.create([item])
                .finally(() => {
                    crud.read().then(() => {
                        this.sort()
                        this.cancel()
                        // TODO - should there be $emit of 'created' or 'replied' (or both?) here, to match createMsg?
                        this.$emit('updateCount', this.params.student_id, true, 'student_comments', this.items)
                    })
                })
        },

        beginDelete(v) {
            this.deleting = v
        },

        deleteMsg(v) {
            let crud = this.$refs.crud
            crud.destroy([v])
                .finally(() => {
                    crud.read().then(() => {
                        this.sort()
                        this.cancel()

                        this.$emit('deleted', v, this.items)
                        this.$emit('updateCount', this.params.student_id, false, 'student_comments', this.items)
                    })
                })
        },

        cancel() {
            this.editing = {}
            this.replying = {}
            this.deleting = undefined
        },

        updateEditing() {
            this.$emit('unsavedChanges', true)
        }
    }
}
</script>

<style lang="scss" scoped>
.message-parent {
    height: calc(100% - 1px);
    overflow: hidden;

    .message-list {
        height: calc(100% - 150px);
        overflow: scroll;
        font-size: 12px;

        .replies {
            margin-left: 60px;
        }
    }
}

.message-container {
    padding: 10px;
    margin-bottom: 24px;

    .message-body {
        padding-top: 8px;
        color: #757575;
    }
}

.reply-container {
    padding: 10px 0 10px 80px;
}
</style>
