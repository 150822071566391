<template>
    <v-layout row class="fill-height">
        <template v-if="localValue">
            <div class="col-4 pa-0 fill-height">
                <fe-stepper-checkbox-panel
                    width="100%"
                    style="height: 100%"
                    :items="behaviorTypes"
                    :surfaceFirstSelections="surfaceFirstSelections"
                    :byId="byId"
                    v-model="localValue.incident_behavior_type_id"
                    :allOption="!incidentTypeOnly"
                    allOptionText="All Incident Types"
                    search
                    searchPlaceholder="Behavior Types"
                    @input="emitItem('incident-behavior-type', ...arguments)"
                />
            </div>
            <v-divider vertical style="background-color:#E0E1E6"/>
        </template>

        <template v-if="!incidentTypeOnly && localValue && localValue.incident_behavior_type_id.length">
            <div class="col-4 pa-0 fill-height">
                <fe-stepper-checkbox-panel
                    v-if="!incidentTypeOnly && localValue && localValue.incident_behavior_type_id.length"
                    width="100%"
                    style="height: 100%"
                    :items="filteredBehaviors"
                    :surfaceFirstSelections="surfaceFirstSelections"
                    :byId="byId"
                    v-model="localValue.incident_behavior_id"
                    allOption
                    allOptionText="All Behaviors"
                    search
                    searchPlaceholder="Behaviors"
                    @input="emitItem('incident-behavior', ...arguments)"
                />
            </div>
            <v-divider vertical style="background-color:#E0E1E6"/>
        </template>

        <template v-if="!incidentTypeOnly && localValue && localValue.incident_behavior_type_id.length && localValue.incident_behavior_id.length">
            <div class="col-4 pa-0 fill-height">
                <fe-stepper-checkbox-panel
                    width="100%"
                    style="height: 100%"
                    :items="responses"
                    :surfaceFirstSelections="surfaceFirstSelections"
                    :byId="byId"
                    v-model="localValue.incident_response_id"
                    allOption
                    allOptionText="All Responses"
                    search
                    searchPlaceholder="Responses"
                    @input="emitItem('incident-response', ...arguments)"
                />
            </div>
            <v-divider vertical style="background-color:#E0E1E6"/>
        </template>
    </v-layout>
</template>

<script>
    export default {
        name: 'IncidentStepperPanel',
        props: {
            value: {},
            surfaceFirstSelections: {
                type: Boolean,
                default: false
            },
            byId: {
                type: Boolean,
                default: true
            },
            incidentTypeOnly: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            localValue(v) {
                this.$emit('input', v)
            },
            searchDisplay(v) {
                this.$emit('searchDisplayChange', v)
            },
            'localValue.incident_behavior_type_id'(v, prevVal) {
                // If behavior type(s) are removed, any behaviors belonging
                // to that behavior type should also be removed
                if (v.length <= (prevVal?.length || 0)) {
                    if (v.length === 0) {
                        this.localValue.incident_behavior_id = []
                        this.localValue.incident_response_id = []
                    } else if (v.includes(-1)) {
                        // Keep all selections - all behaviors are valid as part of "All Types"
                    } else if (this.localValue.incident_behavior_id.includes(-1)) {
                        // Keep all selections, because "All Behaviors" is selected and we have
                        // at least one incident type selected so it's all valid
                    } else {
                        this.localValue.incident_behavior_id = this.localValue.incident_behavior_id.filter(itm => {
                            // We need full behavior object to know which type it belongs to
                            let behavior = this.behaviors.find(b => b.id === itm)
                            return v.includes(behavior?.incident_behavior_type_id)
                        })
                    }
                }
            },
            'localValue.incident_behavior_id'(v, prevVal) {
                // If all behaviors are unchecked, then response selections should reset
                if (v.length === 0) {
                    this.localValue.incident_response_id = []
                }
            },
        },
        mounted() {
            this.localValue = this.$_.cloneDeep(this.value)
        },
        data() {
            return {
                behaviorTypes: [],
                behaviors: [],
                responses: [],
                include: false,
                localValue: {
                    incident_behavior_type_id: [],
                    incident_behavior_id: [],
                    incident_response_id: [],
                }
            }
        },
        computed: {
            filteredBehaviors() {
                if (!this.localValue?.incident_behavior_type_id?.length) {
                    return this.behaviors
                } else if (this.localValue?.incident_behavior_type_id?.includes(-1)) {
                    return this.behaviors
                } else {
                    return this.behaviors.filter(b => this.localValue.incident_behavior_type_id.includes(b.incident_behavior_type_id))
                }
            },
            searchDisplay() {
                let pieces = []
                let suffix

                if (!this.localValue?.incident_behavior_type_id?.length) {
                    pieces.push('<b>Select Behavior Types</b>')
                    return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                } else {
                    if (this.localValue.incident_behavior_type_id.includes(-1)) {
                        pieces.push('All Behavior Types')
                    } else {
                        suffix = this.localValue.incident_behavior_type_id.length > 1 ? `...+${this.localValue.incident_behavior_type_id.length - 1}` : ''
                        pieces.push(this.behaviorTypes.find(itm => itm.id === this.localValue.incident_behavior_type_id[0])?.name + suffix)
                    }
                }

                // If this stepper is configured to only select an incident type, then we
                // are completely done now.
                if (this.incidentTypeOnly) {
                    return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                }

                // Otherwise, check behavior and response as well...
                if (!this.localValue?.incident_behavior_id?.length) {
                    pieces.push('<b>Select Behaviors</b>')
                    return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                } else {
                    if (this.localValue.incident_behavior_id.includes(-1)) {
                        pieces.push('All Behaviors')
                    } else {
                        suffix = this.localValue.incident_behavior_id.length > 1 ? `...+${this.localValue.incident_behavior_id.length - 1}` : ''
                        pieces.push(this.behaviors.find(itm => itm.id === this.localValue.incident_behavior_id[0])?.name + suffix)
                    }
                }

                if (!this.localValue?.incident_response_id?.length) {
                    pieces.push('<b>Select Responses</b>')
                    return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                } else {
                    if (this.localValue.incident_response_id.includes(-1)) {
                        pieces.push('All Responses')
                    } else {
                        suffix = this.localValue.incident_response_id.length > 1 ? `...+${this.localValue.incident_response_id.length - 1}` : ''
                        pieces.push(this.responses.find(itm => itm.id === this.localValue.incident_response_id[0])?.name + suffix)
                    }
                }

                return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
            },
        },
        created() {
            this.localValue = this.$_.cloneDeep(this.value)
            this.loadBase()
        },
        methods: {
            loadBase() {
                this.$modelGet('incidentBehaviorType', { active: 1 }).then(r => { 
                    this.behaviorTypes = r
                })

                if (!this.incidentTypeOnly) {
                    this.$modelGet('incidentBehavior', { active: 1 }).then(r => {
                        this.behaviors = r
                    })

                    this.$modelGet('incidentResponse', { active: 1 }).then(r => {
                        this.responses = r
                    })
                }
            },
            emitItem(key, v, item, items) {
                this.$emit(key+'-selected', item, items)
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>