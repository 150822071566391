<template>
    <div style="overflow: auto; ">
        <fe-card
            class="alt-card ma-3"
            headerText="Intervention Schedule"
        >
            <template v-slot:title-toolbar>
                <v-btn icon x-small @click="doEditIntvSched">
                    <v-icon>far fa-pencil</v-icon>
                </v-btn>
            </template>
            <template v-slot:body>
                <v-list>
                    <!-- Date -->
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>far fa-calendar-alt</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            {{ $dayjs(schedule.start_date).format('M/D/YYYY') }} - {{ $dayjs(schedule.end_date).format('M/D/YYYY') }}
                        </v-list-item-content>
                    </v-list-item>

                    <!-- Time -->
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>far fa-clock</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content v-if="schedule.start_time && schedule.end_time">
                            {{ $dayjs('2020-01-01 ' + schedule.default_start_time).format("h:mm A") }} - {{ $dayjs('2020-01-01 ' + schedule.default_end_time).format("h:mm A") }}
                        </v-list-item-content>

                        <v-list-item-content v-else>
                            No times set
                        </v-list-item-content>
                    </v-list-item>

                    <!-- Frequency -->
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>far fa-calendar-day</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            {{ frequencyDisplay }}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </fe-card>

        <fe-card
            v-if="plan.category_id && plan.category_id != '4'"
            class="alt-card ma-3"
            headerText="Review Schedules"
        >
            <template v-slot:body>
                <fe-crud-grid
                    v-if="interventionIds.length"
                    ref="grid"
                    style="height: 300px;"
                    :config="$models.interventionAnalysis"
                    :readParams="{
                        intervention_id: interventionIdString
                    }"
                    @readProcessor="processAnalysisData"
                    :columnDefs="schedColDefs"
                    refresh-on-config
                    disable-inline-create
                    displayDensity="small"
                    :showAddRowBtn="false"
                    @rowDoubleClicked="editSchedRec = $event.data"
                />
            </template>
        </fe-card>

        <sched-edit
            v-if="editSchedRec"
            :model="editSchedRec"
            @update="updateSchedule"
        />

        <fe-dialog
            v-if="editIntvSched"
            title="Edit Schedule"
            acceptButtonText="Update"
            dismissButtonText="Cancel"
            disableAutoclose
            @dismiss="editIntvSched = false"
            @close="editIntvSched = false"
            @accept="confirmIntvSchedEdit = true"
            :acceptButtonDisabled="!intvSchedIsValid"
        >
            <intervention-schedule v-model="editingSchedule" :timeRequired="plan.category_id !== 4" standalone ref="schedule"/>
        </fe-dialog>

        <fe-dialog
            v-if="confirmIntvSchedEdit"
            title="Override Plan?"
            acceptButtonText="Override"
            dismissButtonText="Cancel"
            @accept="updateIntvSched"
            @dismiss="confirmIntvSchedEdit = false; editIntvSched = false;"
        >
            Saving this record will update the entire intervention plan record for all students. If you are trying to create a new phase go to
            <a href="#" @click="doNewPhase">create a new phase</a>.
        </fe-dialog>

        <fe-crud
            ref="crudSched"
            :config="$models.interventionAnalysisSchedule"
            @updated="$refs.grid.$refs.crud.read()"
        />
        <fe-crud ref="intvSchedCrud" :config="intvSchedCrudConfig"/>
    </div>
</template>

<script>
import SchedEdit from '../SchedEdit'
import InterventionSchedule from '@/components/modules/intervention/creation/Schedule'

export default {
        name: 'GroupSchedule',
        components: {
            SchedEdit,
            InterventionSchedule
        },
        props: {
            interventionPlanId: { type: Number, required: true },
            studentPlans: { type: Array, default: () => { return [] }}
        },
        watch: {
            interventionPlanId: {
                handler() { this.loadData() },
                immediate: true
            },
            editingSchedule: {
                deep: true,
                handler(v) {
                    if (v.id) {
                        setTimeout(() => {
                            this.intvSchedIsValid = this.$refs.schedule?.isValid
                        }, 100)
                    }
                }
            }
        },
        computed: {
            interventionIds() { return this.studentPlans.map(itm => itm.intervention_id) },
            interventionIdString() { return this.interventionIds.join(',') },
            schedule: {
                get: function () {
                    let sched = (this.plan.schedule?.length) ? this.plan.schedule[0] : {}
                    if (sched.start_time) {
                        sched.default_start_time = sched.start_time
                    } else if (sched.default_start_time) {
                        sched.start_time = sched.default_start_time
                    }
                    if (sched.end_time) {
                        sched.default_end_time = sched.end_time
                    } else if (sched.default_end_time) {
                        sched.end_time = sched.default_end_time
                    }
                    return sched
                },
                set: function (sched) {
                    if (sched.start_time) {
                        sched.default_start_time = sched.start_time
                    } else if (sched.default_start_time) {
                        sched.start_time = sched.default_start_time
                    }
                    if (sched.end_time) {
                        sched.default_end_time = sched.end_time
                    } else if (sched.default_end_time) {
                        sched.end_time = sched.default_end_time
                    }
                    this.plan.schedule[0] = sched
                }
            },
            frequencyDisplay() {
                if (this.schedule?.frequency_cd) {
                    let statement = ''
                    let count = this.schedule.frequency_cnt
                    switch (this.schedule.frequency_cd) {
                        case 'WEEK':
                            let days = []
                            this.schedule.week_days.split('').forEach(d => {
                                switch (d) {
                                    case 'M':
                                        days[0] = 'Mon'
                                        break;
                                    case 'T':
                                        days[1] = 'Tues'
                                        break;
                                    case 'W':
                                        days[2] = 'Wed'
                                        break;
                                    case 'H':
                                        days[3] = 'Thurs'
                                        break;
                                    case 'F':
                                        days[4] = 'Fri'
                                        break;
                                }
                            })
                            days = days.filter(Boolean).join(', ')
                            statement += 'Every ' + count
                            statement += (count === 1) ? ' week' : ' weeks'
                            statement += ' on ' + days
                            break;
                        case 'BLOCK':
                            statement += 'Block Schedule'
                            if (count && this.schedule.secondary_cnt) {
                                statement += ' (' + count + ' on - ' + this.schedule.secondary_cnt + ' off)'
                            }
                            break;
                        case 'NOSCHED':
                            statement += 'As Needed'
                            if (count) {
                                statement += ' (' + count + ' total)'
                            }
                    }

                    return statement
                }
            },
            intvSchedCrudConfig() {
                let cfg = this.$_.cloneDeep(this.$models.interventionSchedule)
                cfg.read.params.id = this.schedule.id
                return cfg
            }
        },
        data() {
            return {
                plans: [],
                plan: {},
                editSchedRec: null,
                editIntvSched: false,
                editingSchedule: {},
                intvSchedIsValid: false,
                confirmIntvSchedEdit: false,
                schedColDefs: [
                    {
                        headerName: "Student",
                        field: "student_name",
                        sortable: true,
                        editable: false,
                        pinned: "left",
                        minWidth: 150
                    },
                    {
                        headerName: "Start Date",
                        field: "start_date",
                        sortable: true,
                        editable: false
                    },
                    {
                        headerName: "End Date",
                        field: "end_date",
                        sortable: true,
                        editable: false
                    },
                    {
                        headerName: "Frequency",
                        field: "frequency",
                        sortable: true,
                        editable: false
                    },
                    {
                        headerName: "Days",
                        field: "week_days",
                        sortable: true,
                        editable: false
                    }
                ]
            }
        },
        methods: {
            loadData() {
                let planUrl = `interventionPlan.php?action=get&property=list&include_schedule=1&v=2&intervention_plan_id=${this.interventionPlanId}`
                this.$axios.get(planUrl).then(r => {
                    this.plans = this.$ecResponse(r, 'plans')
                    if (this.plans.length) {
                        this.plan = this.plans[0]
                    }
                })
            },
            processAnalysisData (records, resolve, reject) {
                let processed = records.map((rec, idx) => Object.assign(rec, {
                    student_name: this.studentNameRenderer(rec),
                    start_date: (rec.schedule[0] && rec.schedule[0].start_date),
                    end_date: (rec.schedule[0] && rec.schedule[0].end_date),
                    frequency: this.frequencyRenderer(rec),
                    week_days: this.weekdayRenderer(rec)
                }))
                resolve(processed)
            },
            studentNameRenderer(rec) {
                let plan = this.studentPlans.find(sp => sp.intervention_id == rec.intervention_id)
                return plan ? plan.student_full_name : '-'
            },
            weekdayRenderer(rec) {
                let dayMap = { M: 'Mon', T: 'Tue', W: 'Wed', H: 'Thur', F: 'Fri' }
                return (rec.schedule[0])
                    ? rec.schedule[0].week_days.split('').map(char => dayMap[char]).join(", ")
                    : ''
            },
            frequencyRenderer(rec) {
                if (rec.schedule[0]) {
                    let val = rec.schedule[0].frequency_cnt
                    return (val == 1) ? `Every week` : `Every ${val} weeks`
                } else {
                    return ''
                }
            },
            updateSchedule (obj) {
                this.$refs.crudSched.update(obj.schedule[0], { intervention_id: obj.intervention_id })
            },
            doEditIntvSched () {
                this.editingSchedule = this.$_.cloneDeep(this.schedule)
                this.editIntvSched = true
            },
            updateIntvSched (confirm) {
                let me = this
                let payload = this.editingSchedule
                payload.default_start_time = payload.start_time
                payload.default_end_time = payload.end_time
                let duration = this.$getTimeDiff(payload.start_time, payload.end_time)

                let obj = {
                    "id": this.plan.id,
                    "intervention_plan_intervention_week_days": payload.week_days,
                    "intervention_plan_id": this.plan.id,
                    "intervention_plan_user_id": this.plan.intervention_plan_user_id,
                    "intervention_plan_intervention_schedule_id": payload.id,
                    "intervention_plan_intervention_frequency_cnt": payload.frequency_cnt,
                    "intervention_plan_intervention_frequency_cd": payload.frequency_cd,
                    "intervention_plan_intervention_secondary_cnt": payload.secondary_cnt,
                    "intervention_plan_intervention_start_date": payload.start_date,
                    "intervention_plan_intervention_end_date": payload.end_date,
                    "intervention_plan_intervention_start_time": payload.default_start_time,
                    "intervention_plan_intervention_end_time": payload.default_end_time,
                    "intervention_plan_session_duration": duration,
                    "intervention_plan_start_date": payload.start_date,
                    "intervention_plan_end_date": payload.end_date,
                }

                this.$axios.post('interventionPlan.php?action=update&allow_new_plan=0&property=plan'+(confirm?'&confirm=1':''), obj)
                    .then(r => {
                        if (r.data && r.data.confirm) {
                            me.$messageBox({
                                title: 'Confirm',
                                persistent: true,
                                maxWidth: 600,
                                message: r.data.msg,
                                actions: [{
                                    text: 'Cancel', primary: false,
                                    onClick: () => {
                                        this.editIntvSched = false
                                        this.confirmIntvSchedEdit = false
                                    }
                                }, {
                                    text: 'Update', primary: true,
                                    onClick: () => {
                                       me.updateIntvSched(true)
                                    }
                                }]
                            })
                        } else {
                            this.$ecResponse(r)
                            this.loadData()
                            this.editIntvSched = false
                            this.confirmIntvSchedEdit = false
                        }
                    })
                // this.$refs.intvSchedCrud.update(payload).then(r => {
                //     this.plan.schedule = r.data.schedule
                //     this.editIntvSched = false
                //     this.confirmIntvSchedEdit = false
                // })
            },
            doNewPhase() {
                this.editIntvSched = false
                this.confirmIntvSchedEdit = false
                this.$emit('new-phase')
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
