<template>
    <fe-remote-combo
        byId
        v-bind="$attrs"
        v-on="$listeners"
        :items="statuses"
        style="width: 100%"
        @input="setStatus"
        :value="statusId"
    />
</template>

<script>
    export default {
        name: 'FormStatusField',
        props: ['fieldRec','params'],
        computed: {
            statusId() {
                return this.params?.formInstance?.form_instance_status_id ? this.params.formInstance.form_instance_status_id : null
            }
        },
        data() {
            return {
                statuses: []
            }
        },
        mounted() {
            this.$modelGet('formInstanceStatus')
                .then((resp) => {
                    this.statuses = resp
                })
        },
        methods: {
            setStatus(v) {
                let r = {...this.params.formInstance}
                r.form_instance_status_id = v ? v : null // Allow clearing
                this.$axios.post('formResponse.php?action=update&property=form_instance', { form_instances: [r] }).then(resp => {
                    this.$ecResponse(resp)
                    this.params.formInstance.form_instance_status_id = v ? v : null
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .field-label {
        max-width: 120px;
        padding: 10px 10px 0 0;
        margin: 0;
    }
</style>
