<template>
    <v-layout column>
        <div class="headline mb-1 d-flex">
            Manage Schools
            <fe-info-tip
                class="ml-2"
                tooltip="Schools are created either manually or imported from student data files. Add the primary email for incident notifications to notify specific personnel. Schools can be merged but not deleted or deactivated."
            />
        </div>

        <fe-crud-grid
            ref="grid"
            :config="mainCrudConfig"
            :columnDefs="mainColumnDefs"
            displayDensity="medium"
            identifier="id"
            addRowBtnText="Create School"
            altSelectionTools
            refreshOnCreated
            refreshOnUpdated
            disableInlineCreate
            @read="schools = $event"
            @beginCreate="beginEntry"
        >
            <template v-slot:cellDialog="{cell}">
                <simple-grid-dialog-column
                    v-if="aliasCell"
                    :cell="cell"
                    title="Aliases"
                    field="alias"
                    fieldHeader="Alias"
                    fieldPlaceholder="Add an Alias"
                    :fieldCounter="255"
                    :crudConfig="aliasCrudConfig"
                    :defaultParams="{
                        school_id: cell.data.id
                    }"
                    :insertDefaults="{
                        school_id: cell.data.id
                    }"
                    @destroyed="load"
                    @created="load"
                    @close="closeDialogColumn"
                />
                <simple-grid-dialog-column
                    v-if="feederCell"
                    ref="feederDialog"
                    :cell="cell"
                    title="Feeder Schools"
                    :crudConfig="feederCrudConfig"
                    :columnDefs="feederColumnDefs"
                    :readParams="{
                        to_school_id: cell.data.id
                    }"
                    :destroyParams="cell.data"
                    :insertDefaults="{
                        to_school_id: cell.data.id
                    }"
                    :buildInsertRec="buildFeederRec"
                    width="1000"
                    @destroyed="load"
                    @created="load"
                    @close="closeDialogColumn"
                >
                    <template slot="belowGrid">
                        <div class="d-flex mt-2">
                            <fe-remote-combo
                                itemText="name"
                                itemValue="id"
                                v-model="newFeeder.from_school_id"
                                :items="schools"
                                placeholder="Add a Feeder School"
                                class="mr-1 mt-2"
                                byId
                                hide-details
                                @input="feederSelection($event, 'school')"
                            />
                            <fe-remote-combo
                                itemText="name"
                                itemValue="id"
                                v-model="newFeeder.grade_id"
                                :disabled="!newFeeder.from_school_id"
                                :url="feederGradeUrl"
                                rootProperty="grades"
                                placeholder="Select Grade"
                                class="mx-1 mt-2"
                                byId
                                hide-details
                                @input="feederSelection($event, 'grade')"
                            />
                            <fe-btn
                                :disabled="disableFeederAdd"
                                @click="feederAdd"
                            >
                                Add
                            </fe-btn>
                        </div>
                    </template>
                </simple-grid-dialog-column>
            </template>

            <template slot="selection-tools">
                <fe-btn
                    useIcon="fal fa-compress-alt"
                    usage="ghost"
                    whiteText
                    @click="beginMergeSchool"
                    class="fe-grid-action-btn"
                >
                    Merge School
                </fe-btn>
                <fe-btn
                    useIcon="fal fa-trash"
                    usage="ghost"
                    whiteText
                    @click="deleteRows"
                    class="fe-grid-action-btn"
                >
                    Delete
                </fe-btn>
                <fe-btn
                    useIcon="fal fa-arrow-alt-to-bottom"
                    usage="ghost"
                    whiteText
                    @click="exportGrid('selectedRows')"
                    class="fe-grid-action-btn"
                >
                    Download
                </fe-btn>
                <fe-btn
                    usage="ghost"
                    whiteText
                    @click="editCancel"
                    class="fe-grid-action-btn-last"
                >
                    Cancel
                </fe-btn>
            </template>
        </fe-crud-grid>

        <fe-dialog
            v-if="createDialog.show"
            title="Create School"
            :footer="false"
            persistent
            disableAutoclose
            @close="dismissDialog"
        >
            <model-form
                idProperty="id"
                :records="createDialog.records"
                :fieldConfig="fieldConfigs"
                model="school"
                @cancel="dismissDialog"
                @done="created"
                :submitPrevent="true"
            />
        </fe-dialog>

        <fe-dialog
            v-if="merging"
            ref="merging"
            title="Merge School(s)"
            acceptButtonText="Merge"
            dismissButtonText="Cancel"
            persistent
            dismissable
            disableAutoclose
            @close="merging = false"
            @accept="mergeSchools"
        >
            <v-form ref="mergeToForm" @submit.prevent>
                <fe-remote-combo
                    label="Merge To"
                    ref="mergeTo"
                    v-model="mergeTo"
                    :items="mergeableSchools"
                    :rules="requiredRule"
                />
            </v-form>
        </fe-dialog>

        <fe-crud ref="gradesCrud" :config="$models.grade" autoload @read="grades = $event"/>
    </v-layout>
</template>

<script>
    import { mapState } from 'vuex'
    import SimpleGridDialogColumn from "../../../common/SimpleGridDialogColumn"
    import ModelForm from '@/components/common/form/ModelForm'

    export default {
        name: "Schools",

        components: {SimpleGridDialogColumn, ModelForm},

        data() {
            return {
                schools: [],
                grades: [],
                merging: false,
                mergeTo: undefined,
                createDialog: {show: false, records: []},
                cell: undefined,
                aliasCell: false,
                feederCell: false,
                newFeeder: {
                    from_school_id: null,
                    grade_id: null
                },
                disableFeederGrade: true,
                disableFeederAdd: true,
                requiredRule: [v => !_.isEmpty(v) || 'This field is required']
            }
        },

        computed: {
            ...mapState('global', ['shareableStores']),

            mainCrudConfig() {
                let cfg = _.cloneDeep(this.$models.school)
                cfg.read.params['incl_feeders'] = 1
                cfg.read.params['incl_aliases'] = 1
                return cfg
            },

            aliasCrudConfig() {
                return _.cloneDeep(this.$models.schoolAliases)
            },

            feederCrudConfig() {
                return _.cloneDeep(this.$models.schoolLink)
            },

            mainColumnDefs() {
                return [
                    {
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        maxWidth: 70,
                        colId: 'checkbox_column'
                    },
                    {
                        headerName: 'Name',
                        field: 'name',
                        editable: true,
                        cellEditorFramework: "FeGridTextField"
                    },
                    {
                        headerName: 'Office Email',
                        field: 'office_email',
                        editable: true,
                        cellEditorFramework: "FeGridTextField",
                        cellEditorParams: () => {
                            return {
                                maxLength: 500
                            }
                        }
                    },
                    {
                        headerName: 'Excluded',
                        field: 'exclude_flag',
                        maxWidth: 100,
                        editable: false,
                        cellRendererFramework: "FeGridToggle",
                        cellRendererParams: {
                            rowDataKey: 'exclude_flag'
                        }
                    },
                    {
                        headerName: 'Ignore on Import',
                        field: 'ignore_flag',
                        maxWidth: 100,
                        editable: false,
                        cellRendererFramework: "FeGridToggle",
                        cellRendererParams: {
                            rowDataKey: 'ignore_flag'
                        }
                    },
                    {
                        headerName: 'Ed-Fi Number',
                        field: 'state_id',
                        maxWidth: 100,
                        editable: true,
                        cellEditorFramework: "FeGridTextField",
                        cellEditorParams: {
                            type: 'number'
                        }
                    },
                    {
                        headerName: 'Aliases',
                        field: 'aliases',
                        maxWidth: 100,
                        editable: false,
                        cellRendererFramework: "FeDialogColumn",
                        onCellClicked: v => {
                            this.feederCell = false
                            this.aliasCell = true
                            this.cellClicked(v)
                        }
                    },
                    {
                        headerName: 'Feeder Schools',
                        field: 'feeders',
                        maxWidth: 100,
                        editable: false,
                        valueGetter: params => {
                            return params.data.feeders ? params.data.feeders.length : 0
                        },
                        cellRendererFramework: "FeDialogColumn",
                        onCellClicked: v => {
                            this.resetFeederEntry()
                            this.aliasCell = false
                            this.feederCell = true
                            this.cellClicked(v)
                        }
                    },
                ]
            },

            feederColumnDefs() {
                let me = this
                return [{
                    headerName: 'School',
                    field: 'from_school_id',
                    cellRenderer(v) {
                        return me.findLabel(me.schools, v.data.from_school_id, 'id', 'name')
                    },
                }, {
                    headerName: 'Grade',
                    field: 'grade_id',
                    cellRenderer(v) {
                        return me.findLabel(me.grades, v.data.grade_id, 'id', 'name')
                    },
                }]
            },

            feederGradeUrl() {
                return this.$models.getUrl('grade', 'read') + '&school_id=' + this.newFeeder.from_school_id
            },

            mergeableSchools() {
                let selected = this.$refs.grid.$refs.grid.selectedRows()
                return _.differenceBy(this.schools, selected, 'id')
            },

            fieldConfigs() {
                return [
                    {
                        field: 'name',
                        label: 'School Name',
                        component: 'v-text-field',
                        validatorKey: 'text',
                        validatorConfig: {required: true},
                        width: '100%',
                        attrs: {
                            flat: true,
                            solo: true,
                            dense: true
                        }
                    }, {
                        field: 'office_email',
                        label: 'Office Email (comma separated)',
                        component: 'v-text-field',
                        validatorKey: 'text',
                        validatorConfig: {required: false},
                        width: '100%',
                        attrs: {
                            flat: true,
                            solo: true,
                            dense: true
                        }
                    }
                ]
            },

            secondaryEnrollmentEnabled () {
                return this.$store.getters['flags/flags']['ec-secondaryenrollment'] === true
            },
        },

        created() {
            this.$parent.$once('show schools', () => {
                this.$refs.grid.$refs.grid.resize()
            })

            if(this.secondaryEnrollmentEnabled) {
                this.mainColumnDefs.push({
                    headerName: 'Secondary Enrollment',
                    field: 'secondary_enrollment_flag',
                    maxWidth: 120,
                    editable: false,
                    cellRendererFramework: "FeGridToggle",
                    cellRendererParams: {
                        rowDataKey: 'secondary_enrollment_flag'
                    }
                })
            }
        },

        methods: {
            load() {
                this.$refs.grid.$refs.crud.read()
            },

            deleteRows() {
                this.$refs.grid.$refs.grid.deleteRows(...arguments)
            },

            exportGrid() {
                this.$refs.grid.$refs.grid.exportGrid(...arguments)
            },

            editCancel() {
                this.$refs.grid.$refs.grid.editCancel(...arguments)
            },

            beginEntry() {
                this.resetEntry()
                this.createDialog.show = true
            },

            dismissDialog() {
                this.createDialog.show = false
            },

            resetEntry() {
                this.createDialog.records = [
                    {
                        name: undefined,
                        office_email: '',
                        excluded_flag: false,
                        ignore_flag: false,
                        state_id: null
                    }
                ]
            },

            created() {
                this.load()
                this.dismissDialog()
            },

            beginMergeSchool() {
                this.mergeTo = undefined
                this.merging = true
            },

            mergeSchools() {
                if (this.$refs.mergeToForm.validate()) {
                    let mergeToId = this.mergeTo.id
                    if (mergeToId) {
                        let mergeFrom = this.$refs.grid.$refs.grid.selectedRows()

                        let payload = mergeFrom.map(x => {
                            x.merge_to_school_id = mergeToId
                            return x
                        })

                        this.$refs.grid.$refs.crud
                            .update(payload)
                            .then(() => {
                                this.merging = false
                            })
                    }
                }
            },

            cellClicked(cell) {
                this.cell = cell
                this.$refs.grid.$refs.grid.setDialogCell(cell)
            },

            closeDialogColumn() {
                this.aliasCell = false
                this.feederCell = false
                this.$refs.grid.$refs.grid.openMenu = false
            },

            resetFeederEntry() {
                this.newFeeder.from_school_id = null
                this.newFeeder.grade_id = null
            },

            feederSelection(v, type) {
                if (!v && 'school' === type) {
                    this.newFeeder.grade_id = null
                }

                if (this.newFeeder.grade_id) {
                    this.$refs.feederDialog.insertValue = this.newFeeder
                    this.disableFeederAdd = false
                } else {
                    this.$refs.feederDialog.insertValue = undefined
                    this.disableFeederAdd = true
                }
            },

            buildFeederRec(v, data) {
                data = Object.assign(data, v)
                return data
            },

            feederAdd() {
                this.$refs.feederDialog.createItem()
                this.$nextTick(() => {
                    this.resetFeederEntry()
                    this.load()
                })
            },

            findLabel(collection, value, valueProp, labelProp, countLabel) {
                let lbl = ''
                if (collection && value) {
                    if (Array.isArray(value)) {
                        return `${value.length} ${countLabel}`
                    } else {
                        let sel = collection.find(x => x[valueProp] === value)
                        if (sel) lbl = sel[labelProp]
                    }
                }
                return lbl
            }
        }
    }
</script>

<style scoped>

</style>
