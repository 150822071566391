<template>
    <div class="plan-history d-flex flex-column flex-fill" style="background: #F5F6F8; padding: 16px; overflow: scroll;">
        <fe-dialog
            title="Participation Details"
            v-if="fidelityDialog.show"
            dismissButtonText="Cancel"
            acceptButtonText="Done"
            width="800"
            @accept="loadStudent"
            @close="fidelityDialog.show=false"
            persistent
        >
            <fidelity :params="fidelityDialog.params"/>
        </fe-dialog>

        <v-expansion-panels multiple v-model="openPanels">
            <v-expansion-panel
                v-for="(p,i) in plans"
                class="plan-container"
                :style="{background: p.active_phase ? 'white' : '#ECEDF1'}"
                @change="checkPlan(p, i)"
            >
                <v-expansion-panel-header style="border-bottom: 1px solid #dddddd;">
                    <div class="d-flex">
                        <div class="plan-title">
                            {{ p.intervention_plan_name }}

                            <div class="plan-detail d-flex">
                                <div style="padding-top: 2px;" class="mr-2">
                                    {{ p.intervention_type_name.substring(0, 20) }}
                                </div>

                                <v-icon style="font-size: 6px">fas fa-circle</v-icon>

                                <div style="padding-top: 2px;" class="mx-2">{{ interventionists(p) }}</div>

                                <v-icon style="font-size: 6px">fas fa-circle</v-icon>

                                <div style="padding-top: 2px;" class="mx-2">
                                    {{ p.active_phase ? 'Active Phase' : 'Past Phase' }}
                                </div>
                            </div>
                        </div>

                        <v-spacer/>
                        <div style="font-size: 14px; padding-top: 10px; margin-right: 8px;">
                            {{ $dayjs(p.student_intervention_plan_effective_start_date).format('MMM DD') }} -
                            {{ $dayjs(p.student_intervention_plan_effective_end_date).format('MMM DD, YYYY') }}
                        </div>
                    </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content style="min-width: 800px;">
                    <v-row style="min-height: 100px;">
                        <v-col
                            :cols="flexCols"
                            class="fidelity-container"
                            :class="{'bottom-border': smallScreen}"
                            @click="showFidelity(p)"
                        >
                            <div class="d-flex">
                                <div style="color: #2B3963; font-size: 14px;">FIDELITY</div>
                                <v-spacer/>
                                <div
                                    style="margin-top: 2px; font-size: 11px;"
                                    :style="{color: getFidelityColor(p.fidelity)}"
                                >
                                    {{ p.fidelity }}% Participation
                                </div>
                            </div>

                            <div class="d-flex">
                                <div class="fidelity-stats">
                                    <div class="stats-count">
                                        {{ p.procedures ? p.procedures.length : 'NA' }}
                                    </div>

                                    <div class="stats-detail">Procedures</div>
                                </div>

                                <div class="fidelity-stats">
                                    <div class="stats-count">{{ p.unrecorded_days }}</div>

                                    <div class="stats-detail">Unrecorded Days</div>
                                </div>
                            </div>
                        </v-col>

                        <v-col
                            :cols="flexCols"
                            class="monitor-container"
                            :class="{'bottom-border': smallScreen}"
                            @click="$emit('monitor-click')"
                        >
                            <div class="d-flex">
                                <div style="color: #2B3963; font-size: 14px;">Progress Monitoring</div>

                                <v-spacer/>

                                <template v-if="p.category_id == 4">
                                    <div
                                        v-if="p.primary_probe && !p.primary_probe.goal && p.primary_probe.four_week_possible_pct < 80"
                                        style="color: red; font-size: 12px !important;"
                                    >
                                        <v-icon color="red" class="mr-2">fas fa-exclamation</v-icon>
                                        Not On Track
                                    </div>

                                    <div
                                        v-else-if="p.primary_probe && p.primary_probe.goal > p.primary_probe.four_week_possible_pct"
                                        style="color: red; font-size: 12px !important;"
                                    >
                                        <v-icon color="red" class="mr-2">fas fa-exclamation</v-icon>
                                        Not On Track
                                    </div>

                                    <div v-else style="color: green; font-size: 12px !important;">
                                        <v-icon color="success" class="mr-2">fas fa-check</v-icon>
                                        On Track
                                    </div>
                                </template>
                                <template v-else>
                                    <div v-if="p.primary_probe && p.primary_probe.on_track"
                                         style="color: green; font-size: 12px !important;"
                                    >
                                        <v-icon color="success" class="mr-2">fas fa-check</v-icon>
                                        On Track
                                    </div>

                                    <div v-else-if="p.primary_probe && p.primary_probe.not_on_track"
                                         style="color: red; font-size: 12px !important;"
                                    >
                                        <v-icon color="warning" class="mr-2">fas fa-times</v-icon>
                                        Not On Track
                                    </div>

                                    <div
                                        v-else-if="p.primary_probe && (p.primary_probe.no_roi || p.primary_probe.no_goal)"
                                        style="color: gray; font-size: 12px !important;"
                                    >
                                        <v-icon color="gray" class="mr-2">fas fa-question-circle</v-icon>
                                        No Data
                                    </div>
                                </template>
                            </div>

                            <div class="d-flex">
                                <div class="fidelity-stats" style="width: 33%;">
                                    <div class="stats-count">
                                        {{ p.primary_probe ? p.primary_probe.roi : 'NA' }}
                                    </div>

                                    <div class="stats-detail">ROI</div>
                                </div>

                                <div class="fidelity-stats" style="width: 33%;">
                                    <div class="stats-count" style="width: 33%;">
                                        {{ p.primary_probe ? p.primary_probe.latest_score : 'NA' }}
                                    </div>

                                    <div class="stats-detail">Latest Score</div>
                                </div>

                                <div class="fidelity-stats" style="width: 33%;">
                                    <div class="stats-count" style="width: 33%;">
                                        {{ p.primary_probe ? p.primary_probe.anticipated_final_score : 'NA' }}
                                    </div>

                                    <div class="stats-detail">Projected Score</div>
                                </div>
                            </div>
                        </v-col>

                        <v-col
                            :cols="flexCols"
                            class="decision-container"
                            :class="{'bottom-border': smallScreen}"
                            @click="$emit('message-click')"
                        >
                            <div class="d-flex">
                                <div style="color: #2B3963; font-size: 14px;">DECISION LOG</div>
                                <v-spacer/>
                                <v-icon>fas fa-comments</v-icon>
                            </div>

                            <div style="overflow: scroll; max-height: 150px;" class="mt-2">
                                <div style="font-size: 12px; color: #7E8494;" v-for="m in p.decisions" class="d-flex">
                                    <div v-html="m.created" style="width: 200px;"></div>

                                    <div v-html="stripCommon(m.value)" class="decision-message"></div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import Fidelity from './Fidelity'

export default {
    name: 'PlanHistory',

    components: {Fidelity},

    props: {
        params: {
            type: Object,
            default: () => {
            }
        },
        reload: {
            type: Boolean,
            default: false
        },
        currentPlan: {
            type: Object,
            default: () => {
            }
        }
    },

    data() {
        return {
            plans: [],
            openPanels: [],
            fidelityDialog: {
                show: false
            },
            windowWidth: window.innerWidth
        }
    },

    computed: {
        flexCols() {
            if (this.smallScreen) {
                return 12
            }

            return 4
        },
        smallScreen() {
            return this.windowWidth < 1100
        }
    },

    watch: {
        reload(v) {
            if (v) {
                this.loadStudent()
            }
        },
    },

    mounted() {
        window.addEventListener('resize', () => this.setBreaksize())
        this.loadStudent()
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.setBreaksize())
    },

    methods: {
        interventionists(plan) {
            return plan.interventionists.map(interventionist => interventionist.name).join(', ')
        },

        setBreaksize() {
            this.windowWidth = window.innerWidth
        },

        showFidelity(rec) {
            this.fidelityDialog.show = true
            this.fidelityDialog.params = {
                student_id: rec.student_id,
                intervention_id: rec.intervention_id,
                intervention_plan_id: rec.intervention_plan_id
            }
        },

        checkPlan(plan, i) {
            if (plan.planLoaded) {
                return;
            }
            this.$modelGet('interventionPlanStudent', {
                student_id: plan.student_id,
                intervention_plan_id: plan.intervention_plan_id
            })
            .then(response => {
                let r = response[0]
                r.active_phase = (i === 0 ? true : false)
                r.planLoaded = true

                this.$emit('setInterventionId', r.intervention_id)

                this.$modelGet('message', {
                    student_id: plan.student_id,
                    intervention_plan_id: plan.intervention_plan_id
                })
                .then(messages => {
                    r.decisions = messages
                        .filter(r => r.message_type_id == 3)
                        .map(r => {
                            r.created = this.$dayjs(r.created).format('MMM D, YYYY')
                            return r
                        })
                    // trigger view refresh
                    let newPlans = [...this.plans]
                    newPlans[i] = r
                    this.plans = newPlans
                    // end trigger
                })
            })
            .finally(() => {

            })
        },

        stripCommon(v) {
            return v.replace(/At the meeting(.+?),/, '')
        },

        getFidelityColor(v) {
            if (v < 70) return 'red'
            if (v < 80) return 'orange'
            return 'green'
        },

        loadStudent() {
            this.plans = []
            this.$modelGet('interventionPlanStudent', this.$props.params)
                .then(records => {
                    records.forEach(p => {
                        p.previous_plans.reverse().forEach((prev, i) => {
                            prev.active_phase = i === 0 ? true : false
                            prev.past_phase = i > 0
                            prev.planLoaded = false
                            this.plans.push(prev)
                        })
                    })
                    // set panel open that's related to the actual intervention;
                    // used to always set first intervention to open (so % not always matching)
                    if (!this.openPanels.length) {
                        this.openPanels = [this.plans.findIndex(x => x.intervention_plan_id == this.currentPlan.id)]
                    }
                    this.openPanels.forEach(i => this.checkPlan(this.plans[i], i))
                })
                .finally(() => {
                    this.$emit('loaded-student')
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.plan-history {
    ::v-deep .v-expansion-panel-content__wrap {
        padding: 0 !important;
    }
}

.bottom-border {
    border: 1px solid #E0E1E6;
}

.plan-container {
    background: #FFFFFF;
    border: 1px solid #E0E1E6;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    margin: 8px;

    .plan-title {
        font-size: 14px;
        font-weight: 500;
        color: #2B3963;

        .plan-detail {
            font-size: 12px;
            line-height: 16px;
        }
    }

    .fidelity-container {
        cursor: pointer;
        border-right: 1px solid #dddddd;
        padding: 16px;

        .fidelity-stats {
            width: 50%;
        }
    }

    .monitor-container {
        cursor: pointer;
        border-right: 1px solid #dddddd;
        flex: 1 1 auto;
        padding: 16px;
    }

    .decision-container {
        cursor: pointer;
        padding: 16px;
    }
}

.stats-count {
    margin-top: 16px;
    font-size: 24px;
    line-height: 48px;
    height: 48px;
    color: #050F2D;
}

.stats-detail {
    font-size: 12px;
}
</style>
