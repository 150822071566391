var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-fill flex-column"},[_c('div',{staticClass:"d-flex flex-shrink-1"},[_vm._l((_vm.selectionTypes),function(type){return [_c('fe-filter-btn',{key:`filter-${type}`,ref:`filter_${type}`,refInFor:true,class:{
                    isOptional: _vm.selections[type].optional,
                    isEnabled: _vm.selections[type].available.length
                },attrs:{"title":_vm.selections[type].title,"items":_vm.selections[type].available,"itemValue":_vm.selections[type].field.id,"itemText":_vm.selections[type].field.name,"multiple":!!_vm.selections[type].multiple,"disabled":!_vm.selections[type].available.length,"actions":true,"closeOnSelect":false,"showFirstSelection":"","buffered":""},model:{value:(_vm.selections[type].filtered),callback:function ($$v) {_vm.$set(_vm.selections[type], "filtered", $$v)},expression:"selections[type].filtered"}})]})],2),_c('div',{staticClass:"pa-2 d-flex flex-grow-1"},[(!_vm.plans)?_c('fe-spinner',{attrs:{"text":"Loading plans..."}}):_c('fe-grid',{ref:"grid",style:({
                height: '100%',
                width: '100%'
            }),attrs:{"columnDefs":_vm.columnDefs,"rowData":_vm.plans,"bordered":false,"showDensityToolbar":false,"showSaveAsDefault":false,"showBorderToggle":false,"showFullscreen":false,"showToolbar":false,"disable-inline-create":"","emptyState":{
                text: 'No SLO Plans found with the current selections.',
                imageSrc: '/images/empty-state-horn.png',
                imageStyle: 'max-width:50%; max-height:50%;',
            }},on:{"firstDataRendered":_vm.adjustGrid,"rowClicked":function($event){return _vm.sloSelected($event)}}})],1),_c('fe-crud',{attrs:{"autoload":"","config":_vm.$models.schoolYear},on:{"read":function($event){return _vm.loadItems('schoolYears', $event)}}}),_c('fe-crud',{attrs:{"autoload":"","config":_vm.$models.user,"defaultParams":{ active: 1 }},on:{"read":function($event){return _vm.loadItems('assignees', $event)}}}),(_vm.params)?_c('fe-crud',{attrs:{"autoload":"","config":_vm.$models.sloPlan,"defaultParams":_vm.params,"refresh-on-config":""},on:{"read":function($event){_vm.plans = $event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }