<template>
    <div class="d-flex flex-fill flex-column pa-2">
        <fe-grid
            :columnDefs="colDefs"
            :rowData="items"
            :showAddRowBtn="false"
            displayDensity="small"
            ref="grid"
            domLayout="autoHeight"
            @rowDoubleClicked="editIncident"
        />
        <fe-crud ref="crud" :config="crudConfig" @read="postRead"/>
    </div>
</template>

<script>
    import {mapState} from "vuex"
    import IFrameRenderer from '@/components/common/IFrameRenderer'

    export default {
        name: "OpenIncidents",

        components: {
            IFrameRenderer
        },

        props: {
            rowData: {
                type: Array,
                default: []
            }
        },

        data() {
            return {
                items: [],
                colDefs:  [{
                    headerName: 'Open Since',
                    field: 'incident_created',
                    valueFormatter: v => this.$dayjs(v.data.incident_created).format('YYYY-MM-DD'),
                    sortable: true,
                    width: 150
                }, {
                    headerName: 'Date',
                    field: 'incident_datetime',
                    valueFormatter: v => this.$dayjs(v.data.incident_datetime).format('YYYY-MM-DD'),
                    sortable: true,
                    width: 150
                }, {
                    headerName: 'Incident Type & Code',
                    field: 'incident_behavior_display_name',
                    sortable: true,
                    flex: 1
                }, {
                    headerName: "Student",
                    field: "student_full_name",
                    sortable: true,
                    width: 200,
                    flex: 1
                }, {
                    headerName: "School",
                    field: "school_name",
                    sortable: true,
                    flex: 1
                }, {
                    headerName: "Grade",
                    field: "grade_desc",
                    sortable: true,
                    width: 100
                }, {
                    headerName: 'Responder',
                    field: 'responder_user_full_name',
                    sortable: true,
                    flex: 1
                }]
            }
        },

        computed: {
            ...mapState('global', ['sessionUser', 'currentYear']),

            crudConfig() {
                return _.cloneDeep(this.$models.studentIncidentList)
            }
        },

        mounted() {
            if (this.rowData.length) {
                this.items = this.rowData
            } else {
                this.$refs.crud.read({
                    open: 1
                })
            }
        },

        methods: {
            editIncident(incident) {
                this.$store.commit('global/addDockableWindow', {
                    name: 'Edit Incident',
                    component: 'i-frame-renderer',
                    attrs: {
                        app: 'incident_creation',
                        cfg: {
                            params: {
                                user_id: this.sessionUser.user.id,
                                incident_id: incident.data.incident_id
                            },
                            identifier: new Date().getMilliseconds()
                        }
                    },
                    events: {
                        close: () => {
                            if (this.rowData.length) {
                                this.items = this.rowData
                            } else {
                                this.$refs.crud.read({
                                    open: 1
                                })
                            }
                        },
                    },
                })
            },
            postRead(data) {
                this.items = this.$_.uniqWith(data, (a,b) => {
                    return a.incident_id + ':' + a.student_id === b.incident_id + ':' + b.student_id
                })
            }
        }
    }
</script>

<style scoped>

</style>
