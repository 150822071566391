<template>
    <div class="d-flex flex-grow-1 flex-shrink-1" style="overflow: hidden">
        <div v-if="!dashboards.length" style="width: 100%">
            <div class="empty-state">
                <fe-empty-state
                    image="/images/rocket_small.png"
                    :text="dashboardMessage"
                />
            </div>
        </div>

        <div v-else class="d-flex flex-grow-1 flex-column">
            <div class="mt-4">
                <fe-text-field
                    ref="filterText"
                    placeholder="Search Dashboards"
                    prependIcon="fa-search"
                    width="100%"
                    @change="filterText"
                />
            </div>

            <div class="d-flex justify-space-between my-2">
                <div class="d-flex">
                    <fe-filter-btn
                        title="Year"
                        ref="yearFilter"
                        :items="dashboardYears"
                        v-model="filter.year"
                        :multiple="false"
                        @clear="close('year')"
                    />

                    <fe-filter-btn
                        title="Vis Types"
                        ref="typeFilter"
                        :items="visTypes"
                        v-model="filter.type"
                        :multiple="false"
                        @clear="close('type')"
                    />
                </div>

                <div class="d-flex">
                    <toggle-button :items="toggleItems" :value="view" class="ma-2" @input="$emit('toggle', $event)"/>
                </div>
            </div>

            <div v-if="!filteredDashboards.length" style="width: 100%">
                <div class="empty-state">
                    <fe-empty-state
                        image="/images/magnifying_glass.png"
                        text="No matching dashboards."
                    />
                </div>
            </div>

            <div v-if="view.show === 'gallery'" class="d-flex flex-shrink-1" style="overflow: hidden;">
                <gallery-view :dashboards="filteredDashboards" @select="select"/>
            </div>

            <div v-if="view.show === 'gallery'" class="flex-grow-1"></div>

            <list-view v-if="view.show === 'list'" :dashboards="filteredDashboards" @select="select"/>
        </div>
    </div>
</template>

<script>
import ToggleButton from '@/components/common/button/ToggleButton'
import GalleryView from "./GalleryView"
import ListView from "./ListView"
import {mapState} from "vuex"

export default {
    name: "DashboardSelection",

    components: {ToggleButton, GalleryView, ListView},

    props: {
        dashboards: {
            type: Array,
            default: []
        },
        view: {
            type: Object,
            required: true,
            default: {}
        },
        toggleItems: {
            type: Array,
            default: []
        }
    },

    data() {
        return {
            filter: {
                text: '',
                year: undefined,
                type: undefined
            },
            possibleVisTypes: {
                assessment: {
                    name: 'Assessment',
                    id: 'assessment'
                },
                attendance: {
                    name: 'Attendance',
                    id: 'attendance'
                },
                demographic: {
                    name: 'Demographic',
                    id: 'demographic'
                },
                googlesheet: {
                    name: 'Google Sheet',
                    id: 'googlesheet'
                },
                incidentResponse: {
                    name: 'Incident Response',
                    id: 'incidentResponse'
                },
                incidentType: {
                    name: 'Incident Type',
                    id: 'incidentType'
                },
                tag: {
                    name: 'Tag',
                    id: 'tag'
                }
            }
        }
    },

    computed: {
        ...mapState('global', [ 'shareableStores', 'kpiDashboardCount', ]),

        filteredDashboards() {
            let dashboards = this.$_.cloneDeep(this.dashboards)
            let filter = this.filter
            if (filter.text && filter.text.length) {
                dashboards = dashboards.filter(x => x.name.toLowerCase().includes(filter.text))
            }
            if (filter.year && filter.year.included.length) {
                let yearId = filter.year.included[0].id
                dashboards = dashboards.filter(x => x.school_year_id === yearId)
            }
            if (filter.type && filter.type.included.length) {
                let typeId = filter.type.included[0].id
                dashboards = dashboards.filter(x => {
                    if (!x.configs) return false
                    let hasType = false
                    x.configs.forEach(y => {
                        if (y.config.type === typeId) {
                            hasType = true
                        }
                    })
                    return hasType
                })
            }

            return dashboards
        },

        dashboardYears() {
            let years = {}
            this.dashboards.forEach(item => {
                if (!years[item.school_year_id]) {
                    years[item.school_year_id] = {
                        name: item.school_year_name,
                        id: item.school_year_id
                    }
                }
            })

            return this.$_.reverse(this.$_.sortBy(Object.values(years), 'id'))
        },

        visTypes() {
            let types = {}
            this.dashboards.forEach(item => {
                if (item.configs?.length) {
                    item.configs.forEach(config => {
                        let type = config.config.type
                        if (type !== 'section' && !types[type]) {
                            types[type] = this.possibleVisTypes[type]
                        }
                    })
                }
            })

            return this.$_.sortBy(Object.values(types), 'id')
        },

        dashboardMessage() {
            let hasADAccess = this.$hasSecurity('MANAGE_ACHIEVEMENT_DASHBOARD') || this.$hasSecurity('ACCESS_ACHIEVEMENT_DASHBOARD')
            let dashCount = this.shareableStores.kpi_dashboard_count > 0 || this.kpiDashboardCount > 0

            return hasADAccess ? 'You do not have any dashboards.<br>Create one by selecting New Dashboard above.' : !dashCount ? 'You have no shared dashboards at this time.' : null
        }
    },

    methods: {
        filterText(value) {
            this.filter.text = value ? value.toLowerCase() : ''
        },

        close(x) {
            this.$refs[x + 'Filter'].openMenu = false
        },

        select(item) {
            this.$emit('select', item)
        }
    }
}
</script>

<style lang="scss" scoped>
.empty-state {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
}
</style>
