<template>
    <div>
        <record-dialog
            :value="tagRec"
            v-model="tagRec"
            :defaultRec="defaultRec"
            @create="createRec"
            @close="dismissDialog"
        >
            <template #form="{rec}">
                <fe-label >Name</fe-label>
                <v-text-field autofocus flat solo dense
                    v-model="rec.name"
                    :rules="$fieldValidators('text', 'Name', {required:true, limit:50})"
                />

                <fe-label>Description</fe-label>
                <v-textarea flat solo dense
                    v-model="rec.description"
                    :counter="350"
                    :rules="$fieldValidators('text', null, {limit:350})"
                />

                <div class="d-flex justify-space-between mb-2">
                    <fe-label>Color</fe-label>
                    <fe-color-selector v-model="rec.color"></fe-color-selector>
                </div>
                <div class="d-flex justify-space-between">
                    <fe-label>Public</fe-label>
                    <fe-switch v-model="rec.public_flag" />
                </div>
                <div class="d-flex justify-space-between">
                    <fe-label>KPI</fe-label>
                    <fe-switch :disabled="!rec.public_flag" v-model="rec.kpi" />
                </div>

            </template>
        </record-dialog>

        <fe-crud ref="crud" :config="$models.tag" @read="tagsAdded = $event" />
        <fe-crud ref="crudStudentTag" :config="$models.studentTag"/>
    </div>
</template>

<script>
    import RecordDialog from '@/components/common/form/RecordDialog'

    export default {
        name: 'AddTag',

        components: {
            RecordDialog
        },

        props: {
            selectedItems: null,
            selectedStudent: null,
            tagRef: null
        },

        data() {
            return {
                tagRec: null,
                defaultRec: {
                    name: null,
                    description: null,
                    color: '#000',
                    public_flag: 0,
                    kpi: 0
                },
                tagsAdded: []
            }
        },

        methods: {
            createRec(v) {
                this.$refs.crud.create(v).then(r => {
                    if(!this.$props.selectedItems.length && !this.$props.selectedStudent.length) return

                    let recs = null

                    if (this.$props.selectedStudent.length) {
                        recs = this.$props.selectedStudent.map(x => ({
                            student_id: x.data.student_id,
                            tag_id: r.data.ids[0],
                            school_year_id: x.data.school_year_id
                        }))
                    } else {
                        recs = this.$props.selectedItems.map(x => ({
                            student_id: x.data.student_id,
                            tag_id: r.data.ids[0],
                            school_year_id: x.data.school_year_id
                        }))
                    }

                    this.$refs.crudStudentTag.create(recs).then((st) =>{
                        if (this.$props.selectedItems.length) {
                            this.$emit('loadData')
                        }

                        if (this.$props.selectedStudent.length) {
                            if (this.$props.tagRef) {
                                this.$props.tagRef.$refs.crud.read()
                            }
                        }

                        this.$emit('close')
                    })
                })
            },

            dismissDialog(v) {
                if (!this.$isDirty([v], [this.defaultRec])) {
                    this.$emit('close')
                } else {
                    this.$confirmCancel(() => {
                        this.$emit('close')
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>