<template>
    <fe-dialog
        title="Share smartFORM"
        persistent
        :footer="false"
        width="500"
        @dismiss="$emit('close')"
    >
        <fe-grid
            style="height: 300px;"
            ref="optionGrid"
            :showToolbar="false"
            :columnDefs="columnDefs"
            :rowData="items"
        ></fe-grid>

        <div class="d-flex">
            <fe-remote-combo
                v-model="user"
                style="margin-top: 6px; width: 380px;"
                :url="$models.getUrl('user', 'read')"
                itemText="user_full_name"
                itemValue="id"
                itemSubtext="email_addr"
                placeholder="Add user"
                appendedTextBtn="Advanced"
                @click:appendedTextBtn="showAdvanced=true"
            />
            <fe-btn :disabled="!user" @click="shareForm">Add</fe-btn>
        </div>

        <fe-dialog
            v-if="showAdvanced"
            title="Find Users"
            width="700"
            acceptButtonText="Add"
            dismissButtonText="Cancel"
            dismissable
            persistent
            @close="showAdvanced = false"
            @accept="shareForm"
        >
            <user-search @input="selectedUsers=$event"/>
        </fe-dialog>
        
        <fe-crud
            ref="crud"
            autoload
            :config="$models.formInstanceUser"
            :readParams="{ form_instance_id: formInstance.form_instance_id }"
            @read="items = $event"
        />
    </fe-dialog>
</template>

<script>
import UserSearch from "@/components/common/UserSearch"

export default {
    name: 'ShareFormDialog',
    components: {
        UserSearch
    },
    props: {
        formInstance: {
            type: Object,
            required: true,
        },
    },
    computed: {
    },
    methods: {
        shareForm() {
            var users2Add = this.selectedUsers.length ? this.selectedUsers : [this.user]
            this.$refs.crud.create( users2Add.map(x => ({
                form_instance_id: this.formInstance.form_instance_id,
                user_id: x.user_id ? x.user_id : x.id,
            }))).finally(() => {
                this.selectedUsers = []
                this.user = null
                this.$refs.crud.read()
            })
        },
    },
    data() {
        return {
            items: [],
            user: null,
            selectedUsers: [],
            showAdvanced: false,
            columnDefs: [{
               headerName: "User",
               field: "user_full_name",
               editable: false,
               flex: 1
           }, this.$grid.iconColumn('Remove', 'fas fa-trash', (v) => {
                this.$refs.crud.destroy([v.data]).then(() => {
                    this.$refs.crud.read()
                })
            })],
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
