<template lang="html">
    <v-layout ref="pixiWrapper" column fill-height>
        <canvas ref="canvas" class="pixi-view" @wheel="pan"/>
        <v-fab-transition>
            <v-icon
                v-show="leftScroll && zoomed"
                class="scroll-icon scroll-icon-left"
                size="30"
                v-ripple
                @click="pan({wheelDelta:600})"
            >
                fa-chevron-left
            </v-icon>
        </v-fab-transition>
        <v-fab-transition>
            <v-icon
                v-show="rightScroll && zoomed"
                class="scroll-icon"
                size="30"
                v-ripple
                @click="pan({wheelDelta:-600})"
            >
                fa-chevron-right
            </v-icon>
        </v-fab-transition>
        <slot></slot>
    </v-layout>
</template>

<script>
import * as pixi from 'pixi.js'
import * as Util from '../Util'
import gsap from 'gsap'
import Selection from '../Selection'
import ResizeObserver from 'resize-observer-polyfill';
import { mapLocalState } from '@/util/vuexHelper'

export default {
    name: 'Renderer',

    inject: ['localStore','spriteSheets'],

    data() {
        return {
            maskCanvases: 0,
            leftScroll: false,
            rightScroll: false,
        }
    },

    computed: {
        ...mapLocalState([
            'params',
            'charts',
            'activeGrouping',
            'activeLayout',
            'activeStudent',
            'atlas',
            'ss3',
            'zoomed'
        ])
    },

    watch: {
        activeGrouping(v) {
            this.doLayout()
        },
        atlas(v) {
            this.loadSpriteSheets(v)
        },
        zoomed(v) {
            this.zoom(v)
        }
    },

    mounted() {
        // set up resizing
        let resize = this.$_.debounce(this.resize,500)
        new ResizeObserver((entries, observer) => {
            if(!this.app) return

            for (const entry of entries) {
                const {left, top, width, height} = entry.contentRect;
                if(entry.target == this.$refs.pixiWrapper) {
                    resize(width,height)
                }
            }
        }).observe(this.$refs.pixiWrapper)

        // pixi setup
        this.app = new pixi.Application({
            tranparent: true,
            view: this.$refs.canvas,
            resizeTo: this.$el,
            backgroundColor: 0xffffff,
            resolution: window.devicePixelRatio == 1 ? 1 : 2,
        })

        this.defaultImage = pixi.Texture.from('images/default-student-img.png')

        this.addTicker()
    },

    beforeDestroy() {
        pixi.Ticker.system.remove(this.renderTick)
        pixi.Loader.shared.reset()
        pixi.Loader.reset()
        this.mainContainer.destroy({children:true, texture:true, baseTexture:true})
        this.app.destroy(false,{children:true, texture:true, baseTexture:true})
    },

    methods: {
        async addTicker() {
            await this.activeGrouping
            this.renderTime = 0
            this.FPS = new pixi.Text('',{fontSize:12, fill:0})
            this.FPS.alpha = .2
            this.FPS.anchor.set(0,1)
            this.app.stage.addChild(this.FPS)
            this.app.ticker.add(() => {
                let extra = ''
                if(this.$store.state.global.sessionUser.user.login == 'support') {
                    this.FPS.text = extra + this.app.ticker.FPS.toFixed(4) + ' ' + this.renderTime
                        + ' Students:' + this.charts.records?.length
                        + ' Chips:' + this.activeGrouping.groups.reduce((acc,x) => acc + x.data.length, 0 )
                }
                this.FPS.y = this.height
            })
            // this ticker must be cleaned up
            this.renderTick = () => {
                if(this.app.renderer) {
                    if(this.renderTime<1 && this.app.ticker.started) this.app.ticker.stop()
                    if(this.renderTime>0 && !this.app.ticker.started) this.app.ticker.start()
                    if(this.renderTime>0) this.renderTime -= 1
                }
            }
            pixi.Ticker.system.add(this.renderTick)
        },

        addRenderTime(v) {
            this.renderTime = Util.clamp(this.renderTime + v, 0, 180)
        },

        toViewX(x) {
            return x * this.mainContainer.scale.x + this.mainContainer.x
        },

        toViewY(y) {
            return y * this.mainContainer.scale.y + this.mainContainer.y
        },

        pan(e) {
            if(this.mainContainer && this.width != this.cWidth && this.mainContainer.scale.x == 1) {
                let endx = this.mainContainer.x + e.wheelDelta*1
                if(endx > 0) endx = 0
                if(endx < -this.cWidth + this.width) endx = -this.cWidth + this.width
                gsap.to(this.mainContainer, .3, {
                    x: endx,
                    ease: Quad.easeOut,
                    onUpdate: () => {
                        this.leftScroll = (this.mainContainer.x |0) < 0
                        this.rightScroll = this.width != this.cWidth && this.width - this.cWidth < this.mainContainer.x-1|0
                    }
                })
                this.addRenderTime(80)
            }
        },

        zoom(v) {
            if(this.mainContainer) {
                let scale = v ? 1 : this.width / (this.activeLayout.width)
                let y = v ? 0 : (this.activeLayout.height - this.activeLayout.height * scale) * .5

                gsap.to(this.mainContainer, .8, {
                    x: v ? (this.oldx || 0) : 0,
                    y: y,
                    ease: Power2.easeInOut
                })

                gsap.to(this.mainContainer.scale, .8, {
                    x: scale,
                    y: scale,
                    ease: Power2.easeInOut
                })

                this.oldx = this.mainContainer.x
                this.addRenderTime(60)
            }
        },

        resize(w,h) {
            w = w|0
            h = h|0
            let animate = this.width ? false : true
            if(this.width != w || this.height != h) {
                this.width = w
                this.height = h
                this.app.renderer.resize(this.width, this.height)
                this.doLayout(animate)
            }
        },

        getValidLocalPosition(c, e) {
            let pos = e.data.getLocalPosition(c)
            if(c == this.mainContainer) {
                return pos  // probably don't need to be so exact here... it depends on if we want
                            // the selection box to clear when we move off the canvas?
                            // if we do then to support zoom
                // let canvasStartY =
                // let cHeight =
                // return Util.rectHit(0, canvasStartY, this.cWidth, cHeight, pos.x, pos.y, 1, 1) ? pos : null
                // return Util.rectHit(0, 0, this.cWidth, this.height, pos.x, pos.y, 1, 1) ? pos : null
            } else if(c.hitArea) {
                let i = Util.rectHit(
                    c.hitArea.x, c.hitArea.y, c.hitArea.width, c.hitArea.height,
                    pos.x, pos.y, 1, 1
                ) ? pos : null
                if(i) {
                    i.ex = i.x
                    i.ey = i.y
                    i.x = i.x - c.hitArea.x
                    i.y = i.y - c.hitArea.y
                }
                return i
            } else {
                let i = Util.rectHit(c.x, c.y, c.width, c.height, pos.x, pos.y, 1, 1) ? pos : null
                return i
            }
        },

        createMainContainer() {
            // there is weirdness with how events are fired especially when hitboxes overlap
            this.mainContainer = new pixi.Container()
            this.mainContainer.x = 0
            this.mainContainer.y = 0
            this.mainContainer.interactive = true
            this.mainContainer.interactiveChildren = true

            this.selection = new Selection({
                groups: this.activeGrouping.groups,
                container: this.mainContainer,
                color: 0xff4500,
                alpha: .3
            })

            this.app.renderer.plugins.interaction.on('mouseup', (e) => {
                let studentRecs = this.selection.onMouseUp()
                if(!this.$_.isBlank(studentRecs)) {
                    this.selection.clearRects()
                    this.addRenderTime(1)
                    this.$emit('select',studentRecs)
                }
            })
            this.app.renderer.plugins.interaction.on('mousedown', this.selection.onMouseDown)

            this.mainContainer.on('mousemove', e => {
                let pos = this.getValidLocalPosition(this.mainContainer,e)
                this.info = pos ? `mainContainer ${pos.x|0} x ${pos.y|0}` : ''

                this.selection.onMouseMove(pos, this.groups)
                if(pos && this.selection.active) this.addRenderTime(1)

            })

            this.app.stage.addChild(this.mainContainer)
        },

        clear() {
            if(this.mainContainer) {
                while(this.mainContainer && this.mainContainer.children[0]) {
                    let a = this.mainContainer.children[0]
                    while(a.children[0]) {
                        let b = a.children[0]
                        try {
                            if(b && b.isSprite && !isNaN(b.width) && b.studentRecord) {
                                b.studentRecord.lastLocation = {
                                    x: this.toViewX(b.x+a.x),//*this.mainContainer.scale.x,// - this.mainContainer.x,
                                    y: this.toViewY(b.y+a.y),//*this.mainContainer.scale.y,// - this.mainContainer.y,
                                    width: b.width * this.mainContainer.scale.x,
                                    height: b.height * this.mainContainer.scale.y,
                                }
                            }
                        } catch (e) {

                        }
                        a.removeChild(b)
                    }

                    this.mainContainer.removeChild(a)
                }
                this.app.stage.removeChild(this.mainContainer)
                this.zoomed = true
            }
            pixi.Loader.shared.reset()
        },

        doLayout(animate=true) {
            if(animate) {
                this.addRenderTime(180)
                Promise.all(this.flyOut()).then(x => {
                    this.addRenderTime(180)
                    this.createLayout()
                    if(animate) {
                        this.flyIn()
                    }
                })
            } else {
                this.addRenderTime(80)
                this.createLayout()
            }
        },

        createLayout() {
            if(!this.width) return

            this.clear()
            this.createMainContainer()

            let groups = this.activeGrouping.groups
            let groupCount = groups.length

            let layout = {
                groupCount: groupCount,
                maxGroupLength: groups.reduce((max,x) => {
                    return x.data.length > max ? x.data.length : max
                },0),
                leftRightMargin: Math.max(120-groupCount*2,70) | 0,
                inBetweenMargin: Math.max(100-groupCount*6,40) | 0,
                topMargin: (this.height * .10) | 0,
                bottomMargin: (this.height * .05) | 0,
                labelHeight: (this.height * .10) | 0,

            }

            layout.hWhitespace = (layout.leftRightMargin * 2 + layout.inBetweenMargin * (groupCount-1)) | 0

            this.leftScroll = this.rightScroll = false
            this.cWidth = this.width
            let maxScreenGroups = Math.ceil((this.width-layout.hWhitespace)/40)
            if(groups.length>maxScreenGroups) {
                this.cWidth += (groups.length-maxScreenGroups)*80
                this.rightScroll = true
            }

            layout.groupWidth = ((this.cWidth - layout.hWhitespace) / groupCount) | 0
            if(layout.groupWidth < 80) {
                layout.bottomMargin *= 2
                layout.labelHeight *= 2
            }
            layout.groupHeight = (this.height - layout.topMargin - layout.bottomMargin - layout.labelHeight)
            let { squareSize, columns, rows } = Util.fitSquares6(layout.groupWidth, layout.groupHeight, layout.maxGroupLength)
            layout.spriteSize = squareSize
            layout.columns = columns
            layout.rows = rows
            layout.viewWidth = this.width
            layout.width = layout.hWhitespace + layout.groupWidth * layout.groupCount
            layout.height = layout.groupHeight + layout.topMargin + layout.labelHeight

            let i = 0
            groups.forEach(group => {
                group.x = i*layout.groupWidth + layout.leftRightMargin + i*layout.inBetweenMargin
                group.y = layout.topMargin
                group.width = layout.groupWidth
                group.height = layout.groupHeight
                group.spriteSize = layout.spriteSize

                i++

                let md = this.getMetaData(group)

                const container = new pixi.Container()
                container.isStudentSpriteGroup = true
                container.group = group
                container.x = group.x
                container.y = group.y
                container.interactive = true
                container.interactiveChildren = false
                container.hitArea = new pixi.Rectangle(md.minx,md.miny,md.width,md.height)
                container.groupSwitch = !!this.activeLayout

                group.columns = md.columns // actual columns in group... group.columns != layout.columns
                group.rows = md.rows // actual rows in group... group.rows != layout.rows

                let setActiveStudent = (e,modal) => {
                    let pos = this.getValidLocalPosition(container,e)
                    if(!container.isOver) return
                    if(!pos) return
                    if(this.activeStudent.modal) return

                    let x = Util.clamp((pos.x / group.spriteSize)|0, 0, group.columns-1)
                    let y = Util.clamp(group.rows - (pos.y / group.spriteSize)|0, 0, group.rows-1)

                    let studentRec = container.group.data[x+group.columns*y]

                    this.info2 = `${container.group.name} ${x} x ${y}\n(top: ${md.topEmptyCount}) (count: ${group.data.length}) `

                    if(studentRec) {
                        this.info2 += `(i: ${x+group.columns*y}) ${studentRec.student_full_name}`
                        this.activeStudent = {
                            rec: studentRec,
                            x: this.toViewX(group.x + pos.x + md.minx),
                            y: this.toViewY(group.y + pos.y + md.miny),
                            show: true,
                            modal: modal,
                        }

                    } else {
                        if(!this.activeStudent.modal) this.activeStudent.show = false
                    }
                }

                container.on('click', e => {
                    if(this.activeStudent.show) this.activeStudent.modal = true
                })
                container.on('mousemove', e => {
                    if(this.selection.isActive()) {
                        this.activeStudent.show = false
                    } else {
                        setActiveStudent(e,false)
                    }
                })
                container.on('mouseover', e => {
                    container.isOver = true
                })
                container.on('mouseout', e => {
                    container.isOver = false
                    if(!this.activeStudent.modal) this.activeStudent.show = false
                })
                this.mainContainer.addChild(container)

                // group label
                const rotateLabel = group.width < 80
                const fontSize = Util.clamp(rotateLabel ? group.width*.18 : group.width*.10, 12, 20)|0
                const textStyle = new pixi.TextStyle({
                    fontSize: fontSize,
                    fontFamily: 'CerebriSans-Regular',
                    align: 'center',
                    stroke: 0,
                    fill: '#0b132c',
                    wordWrap: true,
                    wordWrapWidth: rotateLabel ? layout.labelHeight+layout.bottomMargin : group.width+8,
                    breakWords: true
                })
                const percent = Math.round((group.data.length/this.activeGrouping.total)*1000)/10
                const text = `${group.name} (${percent}%, ${group.data.length})`
                const label = new pixi.Text(text, textStyle)
                label.resolution = 2.1
                label.isLabel = true
                label.groupSwitch = container.groupSwitch
                label.x = group.x + group.width/2
                label.y = group.y + group.height+10

                if(rotateLabel) {
                    label.rotation = 45
                } else {
                    label.anchor.x = .5
                }
                label.interactive = true
                label.on('mouseout', () => this.$emit('labelHover',null))
                label.on('mouseover', () => {
                    this.$emit('labelHover',{
                        text: text,
                        x: this.toViewX(label.x),// * this.mainContainer.scale.x + this.mainContainer.x,
                        y: this.toViewY(label.y),// * this.mainContainer.scale.y + this.mainContainer.y,
                    })
                })
                this.mainContainer.addChild(label)  // children of the group containers are non interactive by
                                                    // this our design.  so put these in mainContainer for hover

                // group border
                const border = new pixi.Graphics()
                border.isBorder = true
                border.groupSwitch = container.groupSwitch
                border.lineStyle(2, 0xd5dae2, 1)
                border.moveTo(-6+group.x, group.height-6+group.y)
                border.lineTo(-6+group.x, group.height+6+group.y)
                border.lineTo(group.width+6+group.x, group.height+6+group.y)
                border.lineTo(group.width+6+group.x, group.height-6+group.y)
                this.mainContainer.addChild(border)

                // student location
                let x=0, y=group.height-group.spriteSize
                group.data.forEach( student => {
                    student.rect = {
                        x: x+group.spriteSize*.05,
                        y: y+group.spriteSize*.05,
                        width: group.spriteSize-group.spriteSize*.1,
                        height: group.spriteSize-group.spriteSize*.1,
                    }

                    x += group.spriteSize
                    if(x+group.spriteSize > group.width+1) {
                        y -= group.spriteSize
                        x = 0
                    }
                })

                if(this.charts.records.length < this.ss3.tiledSpritesOn) {
                    // sprites
                    group.data.forEach( student => {
                        let tex
                        if(student.spriteSheet && !student.spriteSheet.default_pic && this.spriteSheets[student.spriteSheet.filename]) {
                            tex = this.spriteSheets[student.spriteSheet.filename].textures[`${student.student_id}@${student.spriteSheet.filename}`]
                        } else {
                            tex = this.defaultImage
                        }

                        const sprite = new pixi.Sprite(tex)
                        sprite.studentRecord = student
                        sprite.interactive = false
                        sprite.width = student.rect.width
                        sprite.height = student.rect.width

                        container.addChild(sprite)
                        sprite.x = student.rect.x
                        sprite.y = student.rect.y

                    })

                } else {
                    // tiled sprites
                    let sprite = new pixi.TilingSprite( this.defaultImage, md.width, md.height )
                    sprite.isTiledSprite = true
                    sprite.tileScale.x = group.spriteSize / this.defaultImage.width
                    sprite.tileScale.y = group.spriteSize / this.defaultImage.height

                    sprite.x = md.minx
                    sprite.y = md.miny

                    container.addChild(sprite)

                    if (md.topEmptyCount > 0) {
                        // cover top right where no students actually are
                        const tsprite = new pixi.Sprite(pixi.Texture.WHITE)
                        tsprite.anchor.set(1,0)
                        tsprite.x = sprite.width//md.minx + md.width
                        tsprite.y = 0//md.miny
                        tsprite.width = group.spriteSize * md.topEmptyCount
                        tsprite.height = group.spriteSize
                        tsprite.tint = 0xffffff

                        sprite.addChild(tsprite)
                    }
                }
                container.excludeHitArea = {
                    x: md.minx + md.width,
                    y: md.miny,
                    width: group.spriteSize * md.topEmptyCount,
                    height: group.spriteSize,
                }
            })

            this.activeLayout = layout

            // garbage collect spritesheets
            this.oldSpriteSheets && Object.keys(this.oldSpriteSheets).forEach( ss => {
                this.spriteSheets[ss].destroy()
                delete this.spriteSheets[ss]
                delete this.oldSpriteSheets[ss]
            })
        },

        flyIn() {
            if(this.mainContainer) {
                this.mainContainer.children.forEach( g => {
                    if(g.isLabel || g.isBorder) {
                        if(g.groupSwitch) {
                            gsap.to(g, .3, {
                                y: g.y,
                                delay: .5,
                                ease: Power2.easeOut
                            })
                            g.y += this.height
                        } else {
                            gsap.to(g, .3, {
                                y: g.y,
                                ease: Power2.easeOut
                            })
                            g.y += this.height
                        }

                    } else if(g.isStudentSpriteGroup) {
                        g.children.forEach( child => {
                            if(child.studentRecord) {
                                if(g.groupSwitch) {
                                    gsap.to(child, .8, {
                                        x: child.x,
                                        y: child.y,
                                        width: child.width,
                                        height: child.height,
                                        delay: Math.random()*.3,
                                        ease: Power4.easeInOut
                                    })
                                    child.x = child.studentRecord.lastLocation.x - child.parent.x
                                    child.y = child.studentRecord.lastLocation.y - child.parent.y
                                    child.width = child.studentRecord.lastLocation.width
                                    child.height = child.studentRecord.lastLocation.height
                                } else {
                                    gsap.to(child, .6, {
                                        y: child.y,
                                        delay: Math.random()*.3,
                                        ease: Power4.easeOut
                                    })
                                    child.y -= this.height
                                }

                            } else if(child.isTiledSprite) {
                                gsap.to(child, .4, {
                                    y: child.y,
                                    delay: child.parent.x*.0003,
                                    ease: Power4.easeOut
                                })
                                child.y -= this.height
                            }
                        })
                    }
                })
            }
        },

        flyOut() {
            let spritePromises = []
            if(this.mainContainer) {
                if(!this.activeLayout) {
                    this.mainContainer.children.forEach( g => {
                        if(g.isLabel || g.isBorder) {
                            spritePromises.push( new Promise( resolve => {
                                gsap.to(g, .6, {
                                    y: g.y + this.height*2,
                                    delay: g.studentRecord ? Math.random()*.4 : 0,
                                    ease: gsap.easeIn,
                                    onComplete: () => {
                                        resolve()
                                    }
                                })
                            }))
                        } else if(g.isStudentSpriteGroup) {
                            g.children.forEach( child => {
                                spritePromises.push( new Promise( resolve => {
                                    gsap.to(child, .6, {
                                        y: child.y + this.height*2,
                                        delay: child.studentRecord ? Math.random()*.4 : 0,
                                        ease: gsap.easeIn,
                                        onComplete: () => {
                                            resolve()
                                        }
                                    })
                                }))
                            })
                        }
                    })
                }
            }
            return spritePromises
        },

        changeSpriteTextures() {
            console.time('- changeSpriteTextures')
            this.addRenderTime(180)
            if(this.mainContainer) {
                this.mainContainer.children.forEach( g => {
                    if(g.isStudentSpriteGroup) {
                        g.children.forEach( sprite => {
                            let student = sprite.studentRecord
                            this.$_.delay(x => {
                                if(student && student.student_id) {
                                    if(student.spriteSheet
                                        && !student.spriteSheet.default_pic
                                        && this.spriteSheets[student.spriteSheet.filename]
                                    ) {
                                        let tex = this.spriteSheets[student.spriteSheet.filename].textures[`${student.student_id}@${student.spriteSheet.filename}`]
                                        sprite.texture = tex
                                    }
                                }
                            },Math.random()*250)
                        })
                    }
                })
            }
            console.timeEnd('- changeSpriteTextures')
        },

        loadSpriteSheets(atlas) {
            const spriteSize = this.ss3.spriteSize

            // mark existing spritesheets to be GC'd
            this.oldSpriteSheets = {...this.spriteSheets}

            console.time('loadSpriteSheets: ')
            Promise.all( atlas.map(aa => {
                return new Promise((resolve,reject) => {
                    console.time('load canvas image: ')
                    const img = new Image()
                    img.src = aa.meta.image
                    img.crossOrigin = "use-credentials"
                    img.onload = () => {
                        console.timeEnd('load canvas image: ')
                        console.time('create canvas')
                        const canvas = document.createElement('canvas')
                        canvas.width = img.width
                        canvas.height = img.height
                        const context = canvas.getContext('2d')
                        console.timeEnd('create canvas')
                        if(!canvas || !context) reject()

                        console.time('make circle')
                        for (let y = 0; y < img.height; y += spriteSize) {
                            for (let x = 0; x < img.width; x += spriteSize) {
                                context.fillStyle = '#000000'
                                context.beginPath()
                                context.arc( x + spriteSize*.5, y + spriteSize*.5, spriteSize*.5-1, 0, 2 * Math.PI )
                                context.fill()
                            }
                        }
                        console.timeEnd('make circle')

                        console.time('make texture')
                        context.globalCompositeOperation = 'source-in'
                        context.drawImage(img, 0, 0)
                        console.timeEnd('make texture')

                        console.time('make pixi texture')
                        const maskedTexture = new pixi.BaseTexture( canvas )
                        maskedTexture.mipmap = true
                        const sheet = new pixi.Spritesheet( maskedTexture, aa )
                        console.timeEnd('make pixi texture')

                        console.time('parse spritesheet')
                        sheet.parse(s => {
                            this.spriteSheets[aa.meta.simpleName] = sheet
                            resolve()
                        })
                        console.timeEnd('parse spritesheet')

                    }
                })
            })).then(() => {
                console.timeEnd('loadSpriteSheets: ')
                this.maskCanvases = 0

                // I only want to run this after I created all sprites
                this.changeSpriteTextures()
            })
        },

        getMetaData(group) {
            let m = {
                minx: 0,
                miny: Infinity,
                maxx: -Infinity,
                maxy: -Infinity,
                width: 0,
                height: 0,
                columns: {},
                rows: 0,
                topEmptyCount: 0,
            }
            let x=0, y=group.height-group.spriteSize
            group.data.forEach(rec => {
                // x, y
                if(y < m.miny) m.miny = y
                if(x+group.spriteSize > m.maxx) m.maxx = x+group.spriteSize
                if(y+group.spriteSize > m.maxy) m.maxy = y+group.spriteSize
                if(x == 0) m.rows++
                m.columns[x] = true

                x += group.spriteSize
                if(x+group.spriteSize > group.width+1) {
                    y -= group.spriteSize
                    x = 0
                }
            })
            m.width = m.maxx - m.minx
            m.height = m.maxy - m.miny
            m.columns = Object.keys(m.columns).length
            m.topEmptyCount = m.rows * m.columns - group.data.length

            return m
        },
    },
}
</script>

<style lang="scss" scoped>
    .pixi-view {
        width: 100%;
        height: 100%;
    }
    .maskCanvas {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        z-index: -1;
    }
    .scroll-icon {
        position: absolute;
        padding-left: 6px;
        bottom: 40px;
        right: 40px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: rgba(200,200,200,.9);
        color: white;
        &:hover {
            background-color: rgba(176,176,176,1);
        }
        &-left {
            padding-right: 10px;
            right: auto;
            left: 40px;
        }
    }
</style>
