<template>
    <div class="vertical-layout workspace-area" style="width: 100%;">
        <div v-if="working" class="layout-static" style="overflow: visible; position: relative; z-index: 100;">
            <v-toolbar
                id="titlebar"
                :color="editing ? null : 'transparent'"
                :class="editing ? 'edit-toolbar' : 'view-toolbar'"
                flat
            >
                <template>
                    <v-icon
                        v-if="editing"
                        class="mr-2"
                        color="white"
                        @click="$emit('mode', 'viewing')"
                    >
                        close
                    </v-icon>
                </template>

                <v-toolbar-title>
                    {{ selected.name }}
                    <span class='text-smaller text-muted'>
                        {{ startDate }}

                        <span v-if="endDate">
                          &mdash; {{ endDate }}
                        </span>

                        <v-icon
                            v-if="canManage"
                            size="18"
                            :color="editing ? 'white' : 'primary'"
                            @click="$emit('settings', true)"
                        >
                            edit
                        </v-icon>
                    </span>
                </v-toolbar-title>

                <v-spacer/>

                <template>
                    <fe-btn
                        v-if="editing"
                        usage="tertiary"
                        @click="$emit('mode', 'viewing')"
                    >
                        Exit
                    </fe-btn>

                    <v-menu
                        v-else-if="canManage"
                        v-model="showingMenu"
                        min-width="150"
                        nudge-left="62"
                        nudge-bottom="2"
                        bottom
                        offset-y
                    >
                        <template v-slot:activator="{ on }">
                            <fe-btn
                                v-on="on"
                                id="edit-menu-btn"
                                color="primary"
                                @click="showingMenu = !showingMenu"
                            >
                                Edit
                            </fe-btn>
                        </template>

                        <v-list>
                            <v-list-item @click="edit">
                                <v-list-item-title>Edit</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="copy">
                                <v-list-item-title>Duplicate</v-list-item-title>
                            </v-list-item>

                            <v-list-item class="delete-list-item" @click="confirmingDelete = thresholdGroup">
                                <v-list-item-title>Delete</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-toolbar>

            <div v-if="editing" style="height: 50px">
                <fe-tabs
                    v-model="activeTab"
                    class="tabs"
                    color="#5FA2DD"
                    :tabs="tabs"
                    no-padding
                />
            </div>
        </div>

        <div class="layout-stretched">
            <template v-if="editing">
                <v-card v-for="(itm,idx) in tabs" :key="itm.type" class="app-bkgd" flat>
                    <component
                        v-if="idx === activeTab"
                        :is="itm.type"
                        :key="itm.type"
                    />
                </v-card>
            </template>

            <template v-else-if="viewing && selected && !alert">
                <Charts @exporter="$emit('exporter', $event)"/>
            </template>

            <v-container>
                <v-card v-if="alert" class="ma-5" flat>
                    <v-alert :value="pending" type="info">
                        {{ $t('groups.pending') }}
                    </v-alert>

                    <v-alert :value="error" type="error">
                        {{ $t('groups.error') }}
                    </v-alert>

                    <v-alert :value="!selected && !pending && !error" type="info">
                        {{ $t('groups.selectPrompt') }}
                    </v-alert>
                </v-card>
            </v-container>
        </div>

        <fe-dialog
            v-if="confirmingDelete"
            usage="error"
            title="Delete Threshold Group?"
            dismissButtonText="Cancel"
            acceptButtonText="Delete"
            @accept="() => deleteConfirmed(confirmingDelete)"
            @dismiss="confirmingDelete = null"
        >
            Are you sure you want to permanently delete the threshold group named
            <strong>{{ confirmingDelete.name }}</strong>?
        </fe-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Tabs from '../tabs'
import Charts from '../templates/Charts'

export default {
    name: 'Selection',

    components: {
        ...Tabs,
        Charts
    },

    props: {
        mode: String,
        settings: Boolean
    },

    data() {
        return {
            confirmingDelete: null,
            showingMenu: false,
            activeTab: null
        }
    },

    computed: {
        ...mapGetters('thresholds/group', [
            'pending',
            'success',
            'error',
            'selected'
        ]),

        canManage () { return this.$hasSecurity('MANAGE_THRESHOLDS') },

        tabs() {
            return [
                {type: 'groupings', path: '#', name: 'Groupings'},
                {type: 'filters', path: '#', name: 'Filters'},
                {type: 'notify', path: '#', name: 'Notify'},
                {type: 'notifyGroups', path: '#', name: 'Notify User Groups'},
                {type: 'email', path: '#', name: 'Email'},
                {type: 'tags', path: '#', name: 'Tags'},
                {type: 'smartform', path: '#', name: 'smartFORMs'}
            ]
        },

        alert() {
            return !this.selected || this.pending || this.error
        },

        working() {
            return !this.alert
        },

        startDate() {
            return this.selected ? this.formatDateString(this.selected.start_date) : null
        },

        endDate() {
            return this.selected ? this.formatDateString(this.selected.end_date) : null
        },

        editing() {
            return this.mode === 'editing'
        },

        creating() {
            return this.mode === 'creating'
        },

        viewing() {
            return this.mode === 'viewing'
        },

        thresholdGroup() {
            return this.$store.getters['thresholds/group/selected']
        },

        thresholdGroupWithMeta() {
            return this.$store.getters['thresholds/group/selectedWithMeta']
        },

        thresholdGroupId() {
            return this.thresholdGroup.id
        }
    },

    methods: {
        ...mapActions('thresholds/group', [
            'remove',
            'select'
        ]),

        edit() {
            this.showingMenu = false
            this.$emit('mode', 'editing')
        },

        copy() {
            this.showingMenu = false
            this.$emit('mode', 'copying')
        },

        deleteConfirmed() {
            this.showingMenu = false
            let deleteIdx = this.thresholdGroupWithMeta.__idx
            this.remove({indexes: [deleteIdx]})
            this.select({id: null})
            this.confirmingDelete = null
        },

        formatDateString(dateString) {
            if (dateString) {
                let parts = dateString.split("-").map(x => parseInt(x))
                return `${parts[1]}/${parts[2]}/${parts[0]}`
            }
            return null
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../theme.scss";

.v-toolbar {
    &.edit-toolbar {
        color: #fff;
        background-color: $info-blue;
    }

    &.view-toolbar {
        color: #000;
    }
}

.tabs {
    height: 50px;
    border-radius: 0;
}

.v-toolbar__content {
    overflow: visible;
}

.delete-list-item:hover {
    color: #FF5252;
}

.workspace-area {
    background-color: $base-bkgd;
    min-height: 100%;
}
</style>
