<template>
    <fe-dialog
        title="New ID"
        persistent
        acceptButtonText="Save"
        dismissButtonText="Cancel"
        width="350px"
        refreshOnUpdated
        refreshOnCreated
        :forceClose="false"
        dismissable
        disableAutoclose
        @accept="saveRec"
        @dismiss="dismissDialog"
    >
        <v-form ref="form">
            <div class="d-flex">
                <fe-label>Unique ID#</fe-label>
                <v-text-field
                    flat solo dense
                    v-model="newRec.unique_id"
                    :rules="$fieldValidators('text', 'Unique ID#', { required: true })"
                    validateOnBlur
                />
            </div>
        </v-form>

    </fe-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { mapLocalState } from '@/util/vuexHelper'

export default {
    name: 'AddId',

    props: {
        crud: null,
        studentInfo: null,
        dataPointTypesList: null
    },

    data() {
        return {
            newRec: {
                student_id: undefined,
                data_point_type_id: undefined,
                unique_id: undefined,
                state_id_flag: undefined,
                district_id_flag: undefined,
                primary_flag: undefined,
                upload_id: undefined
            }
        }
    },

    inject: ['localStore'],

    computed: {
        ...mapLocalState(['updateStudent']),
        ...mapState('global',['sessionUser'])
    },

    methods: {
        saveRec() {
            this.newRec.student_id = this.$props.studentInfo.student_id

            if(this.$refs.form.validate()) {
                this.crud.create(this.newRec).then( r => {
                    this.resetEntry()
                    this.dismissDialog()
                })
            }
        },

        resetEntry() {
            this.newRec = {
                student_id: undefined,
                data_point_type_id: undefined,
                unique_id: undefined,
                state_id_flag: undefined,
                district_id_flag: undefined,
                primary_flag: undefined,
                upload_id: undefined
            }
        },

        dismissDialog() {
            if (this.$_.isEmpty(Object.values(this.newRec).filter(x => !!x))) {
                this.$emit('close')
            } else {
                this.$confirmCancel(() => {
                    this.resetEntry()
                    this.$emit('close')
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep div.fe-label {
        width: 76px;
    }

    ::v-deep {
        div.student-controls {
            width: 242px;
        }
    }
</style>
