<template>
    <record-dialog
        title="Dependency"
        width="800px"
        v-bind="$attrs"
        :value="value"
        :defaultRec="{
            form_id: this.templateId,
            statement_form_logic_id: 2,
            controlling_form_field_id: null,
            form_field_id: null,
            form_section_id: null,
            cond_form_logic_id: null,
            form_logic_value: null,
            action_form_logic_id: null,
        }"
        @create="update" @update="update"
        @close="$emit('close')"
    >
        <template #form="{rec}">
            {{loadItemList(rec)}}

            <div class="d-flex item-bar align-center justify-center mb-8">
                <item-stepper
                    title="Select a Controller"
                    label="Controller"
                    :value="rec"
                    :items="sections"
                    :allFields="allFields"
                    width="240px"
                    controller
                />

                <fe-btn class="mx-4" :disabled="!!rec.form_section_id" @click="swap(rec)">
                    <i class="far fa-exchange-alt"></i>
                </fe-btn>

                <item-stepper
                    title="Select a Dependency"
                    :value="rec"
                    :items="sections"
                    :allFields="allFields"
                    width="240px"
                />

            </div>

            <div class="d-flex">
                <fe-remote-combo
                    class="combo bold bb"
                    byId hide-details
                    v-model="rec.statement_form_logic_id"
                    :items="formLogic.filter(x => x.type=='STATEMENT' && x.id!=18)"
                    :clearable="false"
                    :rules="$fieldValidators('select', null, { required: true })"
                />

                <div class="token mt-2 mx-2">
                    {{fieldType(rec.controlling_form_field_id).name}}
                </div>
            </div>

            <fe-remote-combo
                v-if="rec.statement_form_logic_id==1"
                class="my-3"
                style="width:65%;"
                byId hide-details
                v-model="rec.cond_form_logic_id"
                :items="formLogic.filter(x => x.type=='CONDITIONAL')"
                :clearable="false"
                :rules="$fieldValidators('select', null, { required: true })"
            />

            <fe-remote-combo
                v-if="rec.statement_form_logic_id==1"
                class="mb-3"
                style="width:65%;"
                hide-details
                no-data-text="custom value"
                :value="{value:rec.form_logic_value, name:rec.form_logic_value}"
                itemValue="value" itemText="name"
                :items="listItems"
                :clearable="false"
                :rules="$fieldValidators('select', null, { required: true })"
                useSearchText
                @input="rec.form_logic_value = $event.value"
                @set="if(typeof $event.value=== 'undefined') {rec.form_logic_value = $event} else {rec.form_logic_value = $event.value}"
            />

            <fe-remote-combo
                v-if="rec.statement_form_logic_id==2"
                class="my-3"
                style="width:65%;"
                disabled hide-details
                value="Has value"
                :clearable="false"
            />

            <div class="d-flex">
                <div class="token mt-2">Then</div>
                <fe-remote-combo
                    v-if="rec.statement_form_logic_id==1"
                    class="mx-2"
                    byId hide-details
                    :value="formLogic.filter(x => x.context=='FIELD').find(x => x.id == rec.action_form_logic_id) ? rec.action_form_logic_id : null "
                    @input="rec.action_form_logic_id=$event"
                    :items="formLogic.filter(x => x.context=='FIELD')"
                    :clearable="false"
                    :rules="$fieldValidators('select', null, { required: true })"
                />
                <fe-remote-combo
                    v-if="rec.statement_form_logic_id==2"
                    class="mx-2"
                    byId hide-details
                    :value="whenActions.find(x => x.id == rec.action_form_logic_id) ? rec.action_form_logic_id : null "
                    @input="rec.action_form_logic_id=$event"
                    :items="whenActions"
                    :clearable="false"
                    :rules="$fieldValidators('select', null, { required: true })"
                />
                <div class="token mt-2">{{rec.form_field_id ? fieldType(rec.form_field_id).name : 'Section'}}</div>
            </div>

            <fe-crud ref="crud" :config="$models.formItemDependency" :defaultParams="{form_id:templateId}" autoload />
            <!-- {{$log(allFields[rec.controlling_form_field_id])}} -->
        </template>


    </record-dialog>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'
import smartFormMixin from '../smartFormMixin'
import ItemStepper from './ItemStepper'
import RecordDialog from '@/components/common/form/RecordDialog'
import Tabs from '@/components/common/button/Tabs'

export default {
    name: 'DependencyEditor',
    inject: ['localStore'],
    mixins: [smartFormMixin],

    components: {
        ItemStepper,
        RecordDialog,
        Tabs,
    },

    props: ['value', 'sections', 'allFields', 'templateId'],

    computed: {
        ...mapLocalState([ 'fieldConfigs', 'formLogic', 'dependencies', 'dependentSections', 'dependentFields', 'whenActionText', 'reloadFn' ]),
        whenActions() {
            return Object.keys(this.whenActionText).map(key=>({id:key, name:this.whenActionText[key]}))
        }
    },

    mounted() {
        this.dependencyType = this.value?.form_field_id ? 'Field' : 'Section'
    },

    methods: {
        async getListItems(ctrl,cfg) {
            if(!ctrl || !cfg) return null
            if(ctrl.xtype == 'combobox') return await this.$modelGet('formFieldOption',{ form_field_id: ctrl.id })
            else if(ctrl.xtype == 'usercombo') return cfg.params.users
            return null
        },
        loadItemList(rec) {
            if(JSON.stringify(rec) == this.lastRecString) return
            this.lastRecString = JSON.stringify(rec)

            let ctrl = this.allFields[rec.controlling_form_field_id]
            let controllerConfig = this.fieldConfigs[rec.controlling_form_field_id]

            let items = [{value:null,name:null}]
            if(ctrl.xtype == 'checkboxfield') items = [ ...items, ...this.formLogic.filter(x => x.type=='CONSTANT') ]

            this.getListItems(ctrl,controllerConfig).then(r => {
                let listItems = r ? r : controllerConfig?.items
                if(!this.$_.isBlank(listItems)) {
                    items = [
                        ...items,
                        ...listItems.map(x => ({
                            name: controllerConfig.itemText ? x[controllerConfig.itemText] : x.name,
                            value: controllerConfig.itemValue ? x[controllerConfig.itemValue] : x.id,
                        })),
                    ]
                }
                this.listItems = items
            })
        },
        swap(rec) {
            let c = rec.controlling_form_field_id
            rec.controlling_form_field_id = rec.form_field_id
            rec.form_field_id = c
        },
        fieldType(fieldId) {
            return this.formFieldTypes[this.allFields[fieldId]?.form_field_type_id] || {}
        },
        update(rec) {
            this.$emit('close')
            rec.fidconfig = rec.fidconfig || {}
            if(!rec.id) {
                this.$refs.crud.create({...rec, skip_instance_count:0})
                .finally(()=>this.reloadFn())
            } else {
                this.$refs.crud.update({...rec, skip_instance_count:0})
                .finally(()=>this.reloadFn())
            }
        },
    },

    data() {
        return {
            dependencyType: null,
            listItems: null,
            lastRecString: null,
        }
    }
}
</script>

<style lang="scss" scoped>
.item-bar {
    background-color: var(--fe-tertiary);
    padding: 16px;
    width: 100%;
}
.token {
    font-weight: bold;
}

</style>
