import Vue from 'vue'

export default Vue.extend({
    template: `
        <div style="font-weight:400; line-height:30px;">
            <v-menu
                v-if="data.restricted"
                open-on-hover
                offset-x
                content-class="elevation-1"
                :nudge-right="16"
            >
                <template v-slot:activator="{ on }">
                    <i v-on="on" class="fs12 fas fa-user-lock mr-1" @mouseover="getUsersWithAccess" />
                </template>
                <v-card :style="style">
                    Edit Access Restricted to:<br />
                    <div v-for="u in users" class="">&bull; {{u.user_full_name}}</div>
                </v-card>
            </v-menu>
    
            <b>{{value}}</b>
            <div class="fe-grid-cell-subtext">
                {{data.description}}
            </div>
        </div>
    `,

    computed: {
        value: me => me.params.value,
        data: me => me.params.data,
    },
    data: function() {
        return {
            style: {
                color: 'white',
                backgroundColor: '#050F2D',
                opacity: '.9',
                borderRadius: '3px',
                padding: '5px 10px',
                fontSize: '12px',
            },
            users: [],
        }
    },
    methods: {
        getUsersWithAccess() {
            this.$axios.get('form.php?property=access_users&action=get&form_id=' + this.params.data.id)
                .then(resp => {
                    this.users = resp.data.users_with_access
                        .sort((a,b) => a.user_full_name.localeCompare(b.user_full_name))
                        .filter(x => x.can_edit)
                })
        }
    }
})
