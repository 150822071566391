<template>
    <TabPane ignoreCount lessPadding v-if="thresholdGroup">
        <template slot="icon">
            <h3 class="card__title card__title--group">Threshold Grouping Conditions</h3>
        </template>

        <template slot="content">
            <div class="text-xs-left">
                <fe-btn
                    v-if="thresholdGroup.operator === 'any'"
                    usage="tertiary"
                    useIcon="link_off"
                    @click="requireAll"
                >
                    Require Any Threshold Grouping To Be Met
                </fe-btn>

                <fe-btn
                    v-if="thresholdGroup.operator === 'all'"
                    usage="tertiary"
                    useIcon="link"
                    @click="requireAny"
                >
                    Require All Threshold Groupings To Be Met
                </fe-btn>
            </div>
        </template>
    </TabPane>
</template>

<script>
import TabPane from '../templates/TabPane'

export default {
    name: 'ConditionalOperator',

    components: {
        TabPane
    },

    computed: {
        thresholdGroup() {
            return this.$store.getters['thresholds/group/selectedWithMeta']
        },

        thresholdGroupIdx() {
            return this.thresholdGroup.__idx
        }
    },

    methods: {
        requireAny() {
            this.$store.dispatch('thresholds/group/update', {__idx: this.thresholdGroupIdx, operator: 'any'})
        },

        requireAll() {
            this.$store.dispatch('thresholds/group/update', {__idx: this.thresholdGroupIdx, operator: 'all'})
        }
    }
}
</script>
