<template>
    <div class="d-flex flex-wrap">
        <fe-filter-btn
            ref="filterYear"
            title="Cohort Year"
            :items="years"
            itemValue="id"
            itemText="name"
            :multiple="false"
            closeOnSelect
            v-model="cohortFilter"
        />

        <fe-filter-btn
            :key="`school-btn-` + yearKey"
            title="School"
            v-if="(scope === 'District' || scope == 'Grade') && selected.school_year_id.included"
            :url="schoolURL"
            v-model="schoolFilter"
            :disabled="!selected.school_year_id"
            rootProperty="schools"
        />

        <fe-filter-btn
            :key="`grade-btn-` + yearKey"
            title="Grade"
            v-if="scope === 'District' || scope == 'Grade'"
            :url="selected.school_year_id.included && selected.school_id.included ? gradeURL : null"
            closeOnSelect
            v-model="gradeFilter"
            :disabled="!selected.school_year_id.included || !selected.school_id.included"
            :multiple="false"
            rootProperty="grades"
        />

        <fe-filter-btn
            :key="`teacher-btn-` + yearKey"
            title="User"
            v-if="scope == 'Class' && selected.school_year_id.included"
            :url="teacherURL"
            v-model="userFilter"
            itemValue="user_id"
            itemText="user_full_name"
            rootProperty="courses"
        />

        <fe-filter-btn
            :key="`course-btn-` + yearKey"
            title="Course"
            v-if="scope == 'Class'"
            :disabled="!selected.school_year_id.included || !selected.user_id"
            :url="selected.school_year_id.included && selected.user_id ? courseURL : null"
            v-model="courseFilter"
            itemValue="course_id"
            itemText="course_name"
            rootProperty="courses"
        />

        <fe-filter-btn
            :key="`period-btn-` + yearKey"
            title="Period"
            v-if="scope == 'Class'"
            :disabled="!selected.school_year_id.included || !selected.user_id"
            :url="selected.school_year_id.included && selected.user_id && selected.course_id ? periodURL : null"
            v-model="periodFilter"
            itemValue="period"
            itemText="period"
            rootProperty="courses"
        />

        <v-divider
            class="mx-2"
            inset
            vertical
        />

        <fe-filter-btn
            ref="assessmentYear"
            title="Assessment Year"
            url="qry.php?property=school_years"
            v-if="scope != 'District'"
            rootProperty="years"
            itemValue="id"
            itemText="name"
            :multiple="true"
            :closeOnSelect="false"
            v-model="yearFilter"
            showFirstSelection
        />

        <fe-filter-btn
            ref="assessmentRef"
            title="All Assessments"
            :items="assessments"
            :disabled="!selected.school_year_id"
            itemValue="id"
            itemText="name"
            v-model="assessmentFilter"
            showFirstSelection
        />

        <fe-filter-btn
            title="All Categories"
            v-if="scope != 'Class'"
            url="crud.php?action=get&property=Category&academic=1"
            itemValue="id"
            itemText="name"
            rootProperty="category"
            v-model="categoryFilter"
            :disabled="!selected.school_year_id"
        />

        <v-divider
            class="mx-2"
            inset
            vertical
        />

        <fe-btn
            usage="secondary"
            @click="aFilterDialog=true"
        >More Filters</fe-btn>

        <advanced-filters
            v-if="aFilterDialog"
            :queryParams="filterParams"
            :dptURL="dptURL"
            :selected="selected"
            @updateAssessment="updateAssessment"
            @close="aFilterDialog=false"
            v-model="advancedFilters"
        />

        <fe-btn
            usage="primary"
            :disabled="!canSearch"
            @click="doSearch"
        >Search</fe-btn>

    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import AdvancedFilters from './AdvancedFilters'

    export default {
        name: 'SearchMenu',
        components: {
            AdvancedFilters
        },
        watch: {
            selectedPreviousQuery(v) {
                this.selected = v
                this.doSearch()
            },

            collapsed(v) {
                TweenLite.to(this.$data, .2, { toWidth: (v ? 50 : 300) })
            },

            selected: {
                deep: true,
                handler(v, o) {
                    if (this.selectedClone && this.$isDirty(this.selectedClone?.school_year_id.included, v?.school_year_id.included)) {
                        this.yearKey = v.school_year_id?.included[0].id
                        v.school_id.included = []

                        if (v.grade_id) {
                            v.grade_id = {}
                        }

                        this.selectedClone = _.cloneDeep(v)
                    }
                }
            }
        },
        props: {
            showSearchBy: {
                type: Boolean,
                default: true
            },
            scope: {
                type: String,
                default: 'District'
            },
            includeAssessmentDetail: {
                type: Boolean,
                default: false
            },
            searchSelect: {
                type: Object,
                default: undefined
            }
        },
        computed: {
            ...mapState([
                'filterSchoolYears',
                'filterSchools',
                'filterGrades',
                'filterDataPointTypes',
                'filterSubCategories',
                'filterWindows',
                'filterTargetSets',
                'filterQuickResults',
                'previousQueries'
            ]),

            yearFilter: {
                set(v) {
                    this.selected.school_year_id = v
                },
                get() {
                    return this.selected.school_year_id
                }
            },

            schoolFilter: {
                set(v) {
                    this.selected.school_id = v
                },
                get() {
                    return this.selected.school_id
                }
            },

            gradeFilter: {
                set(v) {
                    this.selected.grade_id = v
                },
                get() {
                    return this.selected.grade_id
                }
            },

            userFilter: {
                set(v) {
                    this.selected.user_id = v
                },
                get() {
                    return this.selected.user_id
                }
            },

            courseFilter: {
                set(v) {
                    this.selected.course_id = v
                },
                get() {
                    return this.selected.course_id
                }
            },

            periodFilter: {
                set(v) {
                    this.selected.period = v
                },
                get() {
                    return this.selected.period
                }
            },

            cohortFilter: {
                set(v) {
                    this.selected.cohort_school_year_id = v
                },
                get() {
                    return this.selected.cohort_school_year_id
                }
            },

            assessmentFilter: {
                set(v) {
                    this.selected.data_point_type_id = v
                },
                get() {
                    return this.selected.data_point_type_id
                }
            },

            categoryFilter: {
                set(v) {
                    this.selected.category_id = v
                },
                get() {
                    return this.selected.category_id
                }
            },

            showTeacher() {
                return this.searchBy && this.searchBy.id == 'teacher'
            },
            teacherURL() {
                if (this.schoolYearAsString) {
                    return 'courseHistory.php?action=get&property=user_id&school_year_id=' + this.schoolYearAsString
                }
                return null
            },
            courseURL() {
                if (this.selected.school_year_id?.included?.length > 0 && this.selected.user_id?.included?.length > 0) {
                    return 'courseHistory.php?action=get&property=course_id&school_year_id=' + this.selected.school_year_id.included[0].id + '&user_id=' + this.selected.user_id.included[0].user_id
                }
                return null
            },
            periodURL() {
                if (this.selected.school_year_id?.included?.length > 0 && this.selected.user_id?.included?.length > 0 && this.selected.course_id?.included?.length > 0) {
                    return 'courseHistory.php?action=get&property=period&school_year_id=' + this.selected.school_year_id.included[0].id + '&user_id=' + this.selected.user_id.included[0].user_id + '&course_id=' + this.selected.course_id.included[0].course_id
                }
                return null
            },
            dptURL() {
                return 'dataPointType.php?action=get&hidden=0&property=assessments'
            },
            scURL() {
                let p = this.formatParams()

                if (p.data_point_type_id) {
                    return 'subcategories.php?action=get&data_point_type_id=' + p.data_point_type_id
                }
                return null
            },
            windowURL() {
                if (this.dptId) {
                    return 'dataPointName.php?action=get&data_point_type_id=' + this.dptId
                }
                return null
            },
            schoolURL() {
                if (this.selected.school_year_id?.included?.length > 0 && this.schoolYearAsString) {
                    return 'schools.php?action=get&incl_groups=1&school_year_id=' + this.schoolYearAsString
                }
                return null
            },
            gradeURL() {
                if (this.selected.school_year_id?.included?.length > 0 && this.schoolYearAsString && this.selected.school_id?.included?.length > 0) {
                    if (this.selected.school_id?.included?.length > 0) {
                        return 'grades.php?action=get&school_year_id=' + this.schoolYearAsString + '&school_id=' + this.gradeYearAsString
                    } else {
                        return 'grades.php?action=get&school_year_id=' + this.schoolYearAsString + '&school_id=' + this.selected.school_id.included[0].id
                    }
                }
                return null
            },
            filterParams() {
                let p = this.formatParams()
                let f = {
                    school_year_id: p.school_year_id ? p.school_year_id : undefined,
                    school_id: p.school_id ? p.school_id : undefined
                }
                return f
            },
            schoolYearAsString() {
                let year = this.selected.school_year_id

                if (year?.included.length) {
                    year = year.included
                }

                if (Array.isArray(year)) {
                    let yr = ''
                    year.forEach((v, i) => {
                        if(v?.id) {
                            yr += (i === 0 ? '' : '%2C') + v.id
                        } else {
                            yr += (i === 0 ? '' : '%2C') + v
                        }
                    })
                    return yr
                } else {
                    if (year?.id) return year.id
                    return year
                }
            },

            gradeYearAsString() {
                let grade = this.selected.school_id.included

                if (Array.isArray(grade)) {
                    let gd = ''
                    grade.forEach((v, i) => {
                        gd += (i === 0 ? '' : '%2C') + v.id
                    })
                    return gd
                } else {
                    return grade
                }
            }
        },
        data() {
            return {
                selectedPreviousQuery: null,
                cohortSearch: false,
                toWidth: 300,
                searchBy: null,
                searchByOptions: [{
                    id: 'building',
                    name: 'Building/Grade'
                }, {
                    id: 'teacher',
                    name: 'Teacher/Classroom'
                }, {
                    id: 'assessment',
                    name: 'Assessment Detail'
                }],
                advancedFilters: null,
                selected: {
                    school_year_id: {
                        included: []
                    },
                    school_id: {},
                    grade_id: {},
                    course_id: {},
                    period: {},
                    cohort_school_year_id: {
                        included: []
                    },
                    data_point_type_id: {
                        included: []
                    },
                    category_id: {}
                },
                selectedClone: null,
                item: {
                    active: 0,
                },
                collapsed: false,
                years: [],
                yearKey: 0,
                assessments: null,
                aFilterDialog: false
            }
        },

        mounted() {
            if (this.defaultSearchBy) {
                this.searchBy = this.searchByOptions.find((rec) => { return (rec.id === this.defaultSearchBy) })
            }

            if (!this.includeAssessmentDetail) {
                this.searchByOptions = this.searchByOptions.filter( (rec) => { return rec.id != 'assessment' })
            }

            this.assessments = []


            this.$axios.get('dataPointType.php?action=get&hidden=0&property=assessments')
            .then(response => {
                response.data.forEach(item => {
                    this.assessments.push(item)
                })
            })

            if (this.searchSelect) {
                this.$modelGet('schoolYear').then((response) => {
                    this.years = response
                }).finally(() => {
                    this.selected = this.searchSelect
                    this.$refs.filterYear.selectItem(this.selected.school_year_id.included[0], true)
                    if (this.scope == 'Grade' || this.scope == 'Class') {
                        this.selected.cohort_school_year_id.included = this.selected.school_year_id.included[0]
                        this.$refs.assessmentYear.selectItem(this.selected.school_year_id.included[0], true)
                    }
                    this.$refs.assessmentRef.selectItem(this.selected.data_point_type_id.included[0], true)
                    this.selectedClone = _.cloneDeep(this.selected)
                    this.doSearch()
                })
            } else {
                this.$modelGet('schoolYear').then((response) => {
                    this.years = response
                    this.selected.school_year_id.included = [response[0]]
                    this.$refs.filterYear.selectItem(response[0], true)
                    this.selected.data_point_type_id.included = [this.assessments[0]]
                    if (this.scope == 'Grade' || this.scope == 'Class') {
                        this.selected.cohort_school_year_id.included = [response[0]]
                        this.$refs.assessmentYear.selectItem(response[0], true)
                    }

                }).finally(() => {
                    this.selectedClone = _.cloneDeep(this.selected)
                })
            }
        },

        methods: {
            canSearch() {
                return true
            },
            valueAsQueryString(key) {
                if (!this.selected[key]) return

                return 16
                let value = this.selected[key].id
                let rtn   = ''

                if (Array.isArray(value)) {
                    value.forEach((v, i) => {
                        rtn += (i === 0 ? '' : '%2C') + v
                    })
                    return rtn
                } else {
                    rtn = value
                }
                return rtn
            },
            updatePreviousQueries() {
                return

                let v = {...this.selected}
                let previousQueries = window.localStorage.getItem('ec-assessment-overview-parameters') || '[]'
                previousQueries = JSON.parse(previousQueries)

                if (!Array.isArray(previousQueries)) {
                    window.localStorage.setItem('ec-assessment-overview-parameters', JSON.stringify([previousQueries]))
                }

                let found = false
                previousQueries.forEach(function(query) {

                    if (query && !found && JSON.stringify(query.value) === JSON.stringify(v)) {
                        found = true
                    }
                })

                if (found) {
                    return
                }

                let name = ''

                // Build a name for the user
                if (v.school_year_id) {
                    let r = this.$_.find(this.$store.state.filterSchoolYears, function(r) {
                        return (r.id === v.school_year_id)
                    })
                    name += r.name + ' '
                }

                if (v.sub_category_id) {
                    let r = this.$_.find(this.$store.state.filterSubCategories, function(r) {
                        return (r.id === v.sub_category_id)
                    })

                    if (r) name += r.name + ' '
                }

                previousQueries.push({
                    name: name,
                    value: v
                })

                previousQueries = previousQueries.splice(0, 20)
                window.localStorage.setItem('ec-assessment-overview-parameters', JSON.stringify(previousQueries))
                this.$store.commit('set', {
                    key: 'previousQueries',
                    value: previousQueries
                })
            },

            formatParams(obj) {
                let p = {}
                let selected = obj ? obj : this.selected

                Object.keys(selected).forEach(sel => {
                    let val = selected[sel]

                    if (selected[sel].included) {
                        val = selected[sel].included
                    } else {
                        if (!Array.isArray(val)) val = [val]
                    }

                    val.forEach(rec => {
                        if (!p[sel]) p[sel] = []

                        if (rec.id) {
                            p[sel].push(rec.id)
                        } else {
                            p[sel].push(rec[sel])
                        }
                    })
                })
                return p
            },

            doSearch() {
                let me = this
                let selectedCopy = _.cloneDeep(this.selected)
                const removeEmpty = obj =>
                    Object.fromEntries(
                        Object.entries(obj)
                        .filter(([k, v]) => v.included)
                    )

                let newObj = removeEmpty(selectedCopy)
                let p = this.formatParams(newObj)
                let alternate = {

                }
                let valid = true

                switch (this.$props.scope) {
                    case 'District':
                        if (!p.school_year_id) {
                            valid = false
                        }
                        break;

                    case 'Grade':
                        if (!p.school_year_id || !p.grade_id) {
                            valid = false
                        }
                        break;
                }

                if (!valid) return
                this.$log('Advanced', this.advancedFilters)
                this.$emit('search', p, this.selected)
            },

            updateAssessment(type, data) {
                this.selected[type] = {}
                this.selected[type].included = []
                this.selected[type].included.push(data)
            }
        }
    }
</script>

<style lang="scss" scoped>

.filter-container {
    margin-left: 20px;
    height: 90vh;
}

.filter-parent {
    border-bottom: 1px solid #e6e6e6;
}

.filter-section {
    height: 250px;
    overflow: scroll;
    padding-left: 20px;
}

.filter-input ::v-deep label {
    font-size: 12px;
}

.filter-input ::v-deep  i {
    font-size: 16px;
}

.collapsed-filter-panel {
    transform: rotate(90deg);
    font-size: 20px;
    font-weight: bold;
    color: #404040;
    margin-top: 30px;
}

.expand-enter-active, .expand-leave-active {
  transition: all .3s ease;
}
/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.expand-enter, .expand-leave-to {
  width: 0;
  opacity: 0;
}

.small-font {
    font-size: 8px !important;
}
</style>
