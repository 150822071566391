<template>
    <div class="d-flex flex-column flex-fill" style="background-color: #eeeeee;">
        <div class="toolbar d-flex">
            <div class="page-title">Data Exports</div>
        </div>

        <v-container>
            <v-row cols=12>
                <v-col cols=6>
                    <v-card class="upload-card ma-4">
                        <v-card-title style="border-bottom: 1px solid #E0E1E6;">smartFORM Export</v-card-title>

                        <div class="d-flex justify-center">
                            <div class="flex-grow-1" style="text-align: center; height: 120px;" @click="$router.push('/ExportManager/SmartForms')">
                                <div class="page-section">Export smartFORM Data Based on Data ID</div>
                            </div>
                        </div>


                        <v-card-actions>
                            <!-- <div class="pl-2 view-all" @click="$router.push('/Upload/List')">View All Uploaded Files</div> -->
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols=6>

                </v-col>

                <v-col cols=6>

                </v-col>

                <v-col cols=6>

                </v-col>
            </v-row>

            <!-- <v-row>
                <v-col cols=6>
                    <v-card class="upload-card ma-4" v-if="hasImportManager" @click="$router.push('/ImportSummary')">
                        <v-card-title style="border-bottom: 1px solid #E0E1E6;">Import Summary</v-card-title>

                        <div class="page-title text-center" style="text-decoration: underline;">Customer Sign Off Summary</div>

                        <div class="body-3 pa-4">
                            A summary of all data pulled into eduCLIMBER to be signed off by the customer.  This step is necessary for the district to successfully exit onboarding.
                        </div>
                    </v-card>
                </v-col>
            </v-row> -->

        </v-container>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'UploadLanding',
        components: {
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            hasSecurity() {
                return this.sessionUser.security_codes.indexOf('UPLOAD_DATA') != -1
            },
            hasImportManager() {
                return this.sessionUser.security_codes.indexOf('MANAGE_FTP_UPLOAD') != -1
            }
        },
        data() {
            let me = this
            return {
                exportType: null
            }
        },
        mounted() {
        },
        methods: {
            go(card) {
                if (card.route) this.$router.push(card.route)
            },

        }
    }
</script>

<style lang="scss" scoped>
.toolbar {
    width: 100%;
    padding: 16px 16px 16px 16px;
}
.upload-card {
    width: 400px;
    cursor: pointer;
    background-color: white !important;
    background: white;
    background-color: #FFFFFF;
}

.view-all {
    color: var(--v-primary-base);

    &:hover {
        font-weight: bold;
    }
}
</style>
