<template>
    <div>
        <fe-card
            class="alt-card ma-3"
            headerText="Quick Links"
            :menu="tools"
        >
            <template v-slot:body>
                <div class="d-flex flex-wrap">
                    <div
                        v-for="item in items.filter(r=>!r.hidden)"
                        class="tile"
                        :style="[{backgroundColor: item.color }]"
                        @click="navigation(item)"
                    >
                        <div class="tile-text">{{ item.name }}</div>
                    </div>
                </div>
            </template>
        </fe-card>

        <v-dialog
            title="Resource Center"
            v-model="resourceDialog.show"
            persistent
            fullscreen
            @accept="resourceDialog.show=false"
            @close="resourceDialog.show=false"
        >
            <v-card>
                <v-card-title>
                    <div class="d-flex" style="width: 100%;">
                        <div>Resource Center</div>

                        <v-spacer/>

                        <fe-icon-btn useIcon="fas fa-times" @click="resourceDialog.show=false"/>
                    </div>
                </v-card-title>

                <v-card-text style="overflow: hidden;">
                    <iframe
                        src="https://schoolcity.com/learning-hub/index.php/ec/"
                        style="border: none; height: 85vh;"
                        class="pdf-iframe"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'QuickLinksCard',

    data() {
        return {
            tools: [],
            studentTag: false,
            userTag: false,
            resourceDialog: {show: false}
        }
    },

    computed: {
        items() {
            let me = this

            return [{
                name: 'User Tag Manager',
                color: '#746DE0',
                handler() {
                    me.$dockableWindow({
                        name: 'User Tag Manager',
                        component: 'user-tags',
                        attrs: {
                            class: 'pa-5',
                            hideTitle: true
                        }
                    })
                }
            }, {
                name: 'Student Tag Manager',
                color: '#2B87FF',
                handler() {
                    me.$dockableWindow({
                        name: 'Student Tag Manager',
                        component: 'student-tags',
                        attrs: {
                            class: 'pa-5',
                            hideTitle: true
                        }
                    })
                }
            }, {
                name: 'Collections',
                color: '#FFCA26',
                path: '/Collections'
            }, {
                name: 'Program Evaluation',
                color: '#746DE0',
                hidden: !me.$hasSecurity('PROGRAM_EVALUATION_DASHBOARD'),
                handler() {
                    me.$dockableWindow({
                        name: 'Program Evaluation',
                        component: 'program-evaluation',
                        attrs: {
                            class: 'pa-5'
                        }
                    })
                }
            }, {
                name: 'SLO',
                color: '#49C379',
                hidden: !this.sloEnabled,
                handler() {
                    me.$dockableWindow({
                        name: 'SLO Dashboard',
                        component: 'slo',
                        attrs: {
                            class: 'pa-5'
                        }
                    })
                }
            }]
        },

        sloEnabled() {
            return this.$store.getters['flags/flags']['ec-slo'] === true
        }
    },

    methods: {
        navigation(item) {
            if (item.handler) {
                item.handler()
                return
            }
            if (item.window) {
                this.$store.commit('global/addDockableWindow', {
                    name: item.name,
                    component: item.component
                })
            } else if (item.path) {
                this.$router.push(item.path)
            } else if (item.name === 'Student Tag Manager') {
                this.studentTag = true
            } else if (item.name === 'User Tag Manager') {
                this.userTag = true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tile {
    border-radius: 5px;
    width: 82px;
    height: 77px;
    margin: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &-text {
        color: white;
        font-size: 12px;
        text-align: center;
    }
}
</style>
