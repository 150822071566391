<template>
    <div class="d-flex flex-fill flex-column">
        <v-form v-model="validForm" @submit.prevent>

            <div class="d-flex">
                <fe-date-picker
                    label="Start Date"
                    v-model="localValue.start_date"
                    :rules="$fieldValidators('text', 'Start date', { required: true })"
                    class="mr-2 flex-grow-1"
                    dateFormat="MM/DD/YYYY"
                />
                <fe-date-picker
                    label="End Date"
                    v-model="localValue.end_date"
                    :rules="rules.endDate"
                    class="flex-grow-1"
                    dateFormat="MM/DD/YYYY"
                />
            </div>

            <fe-label>Frequency</fe-label>
            <v-radio-group row v-model="localValue.frequency_cd">
                <v-radio v-for="r in frequency" :key="r.id" :label="r.name" :value="r.id"/>
            </v-radio-group>

            <div v-if="localValue.frequency_cd === 'WEEK'" class="mb-5">
                <fe-label>Every number of weeks</fe-label>
                <v-text-field
                    :disabled="!localValue.frequency_cd"
                    flat solo dense
                    v-model="localValue.frequency_cnt"
                    :rules="$fieldValidators('number', null, frequencyValidator)"
                />

                <weekday-picker v-model="localValue.week_days"/>
            </div>

            <div v-if="localValue.frequency_cd === 'MONTH'">
                <fe-label>Day of Month</fe-label>
                <v-text-field
                    type="number"
                    :rules="$fieldValidators('number', null, {required: true, min: 1, max: 31 })"
                    flat solo dense
                    v-model="localValue.frequency_cnt"
                />
            </div>

            <div class="meetings">
                <div class="section-title mb-5">Meetings</div>
                <v-radio-group v-model="localValue.dashboard_meeting_id">
                    <v-radio label="Do not assign to meeting" :value="null"/>

                    <v-radio
                        :label="m.title"
                        :value="m.id"
                        v-for="(m,i) in meetings" :key="`meeting-`+i"
                    />
                </v-radio-group>
            </div>

            <div class="d-flex">
                <fe-btn class="ml-auto" usage="primary"  :disabled="!canSave" @click="$emit('add')">Add</fe-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import WeekdayPicker from '@/components/common/button/WeekdayPicker'

    export default {
        name: 'ReviewSchedule',
        props: {
            value: {
                default() { return { } }
            }
        },
        components: {
            WeekdayPicker
        },
        data() {
            return {
                meetings: [],
                validForm: false,
                datePicker: null,
                localValue: {},
                frequency: [{
                    id: 'WEEK',
                    name: 'Weekly'
                }, {
                    id: 'MONTH',
                    name: 'Monthly'
                }],
                rules: {
                    endDate: [
                        v => !this.$dayjs(v).isBefore(this.localValue.start_date) || 'Not a valid End Date'
                    ].concat(this.$fieldValidators('text', 'End date', {required: true}))
                }
            }
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            frequencyValidator() {
                return this.localValue.frequency_cd === 'MONTHLY' ? { required: true, min: 1, max: 31 } : { required: true, min: 1, max: 52 }
            },
            canSave() {
                let v = this.localValue
                if (!v.start_date || !v.end_date) {
                    return false
                }

                if (v.frequency_cd === 'WEEK') {
                    // Did we select the frequency
                    if (!v.frequency_cnt) {
                        return false
                    }
                    // Did we select a day of the week
                    if (!v.week_days || v.week_days.length === 0) {
                        return false
                    }
                }

                return this.validForm
            }
        },
        created() {
            this.localValue = this.$props.value
        },
        mounted() {
            this.$modelGet('userMeeting', {
                user_id: this.sessionUser.user.id,
                active: 1,
            }).then(resp => this.meetings = resp)
        },
        methods: {
            setDates(d) {

            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
