<template>
    <div style="width: 100%; height: 100%;" class="guardian-emails-grid">
        <fe-grid
            :showToolbar="false"
            style="height: 500px;"
            :columnDefs="columnDefs"
            :rowData="rowData"
            @cellClicked="onCellClicked"
        />
    </div>
</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue";

export default {
    components: {
        AgGridVue,
    },
    data() {
        const today = new Date()
        const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}/${today.getFullYear()}`

        return {
            columnDefs: [
                { headerName: 'Last Sent', field: 'lastSent', flex: 0.5, minWidth: 10, sortable: true, valueFormatter: v => this.$dayjs(v.data.lastSent).format('M/D/YY'), },
                { headerName: 'Sent By', field: 'sentBy', flex: 1, sortable: true },
                { headerName: 'Template', field: 'template', flex: 1, sortable: true },
                {
                    headerName: 'Download Response Report',
                    headerTooltip: "Download a CSV report to view a list of student guardians, the delivery status, and whether a response has been received.",
                    field: 'downloadResponseReport',
                    cellRenderer: v => {
                        return '<div style="color: #006C90"><i class="fas fa-arrow-to-bottom"></i> Download Report</div>'
                    },
                    flex: 1,
                    headerComponentParams: {
                        template: '<div>Download Response Report <i style="margin-left: 4px color: #495B8F" class="fas fa-info-circle"></i></div>',
                    },
                    sortable: true,
                },
            ],
            rowData: [],
        }
    },
    mounted() {
        this.$modelGet('guardianFormEmails')
            .then(response => this.rowData = response.sent_list)
            .catch(err => console.warn('Unable to load guardian form email setup', err))
    },
    methods: {
        onCellClicked(params) {
            if (params.colDef.field === 'downloadResponseReport') {
                const batchId = params.data.batchId
                this.$axios.get(`/api/core/guardianformemail/csv/${batchId}`)
                .then(response => {
                    // Download the CSV file
                    const csvData = new Blob([response.data], { type: 'text/csvcharset=utf-8' })
                    const link = document.createElement('a')
                    const url = URL.createObjectURL(csvData)
                    link.setAttribute('href', url)
                    link.setAttribute('download', 'student-dodument.csv')
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    URL.revokeObjectURL(url)
                })
                .catch(err => console.warn('Unable to download report', err))
            }
        }
    }
}
</script>

<style>
.guardian-emails-grid .ag-tooltip {
    max-width: 200px;
}
.guardian-emails-grid .bordered-rows .fe-grid-grid .ag-row.ag-row-last {
    border-bottom: 1px solid #eee !important;
}
</style>
