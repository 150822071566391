<template>
    <div v-if="xtype!='pdfpagebreak' || editor"
        :id="`form-field-${data.form_field_id || data.id}`"
        class="d-flex fill-height sf-field"
        :class="{
            ['xtype-'+xtype]: true,
            'editor-state': editor,
            'editor-state-selected': editor && editorState.selected,
            'editor-state-drageOver': editor && editorState.drageOver,
            'block-error': blockError,
        }"
        :draggable="editor && editorState.draggable"
        @click.stop="onClick"
        @dragstart.stop="onDragStart"
        @drop="onDrop"
        @dragover.prevent
    >
    <!-- @dragover.stop="editorState.drageOver = true"
    @dragleave.stop="editorState.drageOver = false" -->
        <div v-if="editor">
            <v-menu offset-y >
                <template #activator="{ on:gripMenu }">
                    <div class="dragbar"
                        @mouseover="editorState.draggable=true" @mouseleave="editorState.draggable=false"
                        v-on="{...gripMenu}"
                    >
                        <i class="fe-grid-icon far fa-ellipsis-v theme--light"></i>
                        <i class="fe-grid-icon far fa-ellipsis-v theme--light"></i>
                    </div>
                </template>

                <v-list dense>
                    <v-list-item @click="$emit('addDependency',data)">Add Dependency</v-list-item>
                    <v-list-item @click="$emit('addActionItem',data)">Add Action Item</v-list-item>
                    <v-list-item @click="$emit('duplicateField',data)">Duplicate</v-list-item>
                    <v-list-item @click="$emit('deleteField',data)">Delete</v-list-item>
                </v-list>
            </v-menu>
        </div>

        <div v-if="noLabels && smartFieldHasData"
            class="smartfield-container flex-grow-1"
            :class="{'no-events': isLocked}"
        >
            <div v-if="editor" class="d-flex ml-auto">
                <div class="mx-1" style="width:18px;" >
                    <v-menu v-if="hasTasks" offset-y>
                        <template #activator="{on}">
                            <i v-on="on" class="icon-btn fas fa-tasks theme--light" />
                        </template>
                        <v-list dense>
                            <template v-for="task in data.tasks">
                                <v-list-item @click="$emit('editActionItem',task)">
                                    {{task.name}}
                                    <fe-tooltip class="ml-auto" direction="right" caret style="display:inline-block" tooltip="Remove action item from field">
                                        <fe-icon-btn @click.stop="$emit('deleteActionItem',task)" size="12px" use-icon="fa-trash"></fe-icon-btn>
                                    </fe-tooltip>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </div>

                <div class="mx-1" style="width:18px;" >
                    <i v-if="hasRules"
                        class="icon-btn fas fa-project-diagram theme--light"
                        :class="{issue:hasRuleIssues}"
                        @click.stop="$emit('editDependency',data)"
                    />
                </div>

            </div>
            <component
                ref="localField"
                :is="componentType"
                :fieldRec="data"
                :editor="editor"
                v-bind="cfg"
                v-on="listeners"
                :disabled="disabled"
                :height="height"
                :isDuplicateDataId="isDuplicateDataId"
            ></component>
        </div>

        <template v-else>

            <div v-if="!noLabels && !data.hide_name && data.label_align == 'left'"
                class="sf-label d-flex"
                :style="[ {
                    width: '120px',
                    textAlign: data.label_align || 'left',
                    paddingTop: xtype == 'checkboxfield' ? '2px' : '0',
                    paddingRight: '10px',
                }]"
            >
                {{fieldLabel}}
                <fe-tooltip v-if="isDuplicateDataId"
                    tooltip="This form field has the same Data ID name as another form field. You may want to edit this Data ID name to make it unique.">
                    <v-icon @click="goToDataId" color="#BF0D00" class="ml-2" style="font-size: 14px; pointer-events: visible;">fal fa-info-circle</v-icon>
                </fe-tooltip>
            </div>

            <!-- @click="setActionItem" -->
            <div
                class="d-flex flex-column flex-grow-1"
                :class="{'no-events': isLocked}"
            >
                <div v-if="editor || !noLabels && !data.hide_name && data.label_align == 'top'"
                    class="d-flex sf-label"
                >
                    <div v-if="!noLabels && !data.hide_name && data.label_align == 'top'" class="d-flex">
                        {{fieldLabel}}
                        <fe-tooltip v-if="isDuplicateDataId"
                            tooltip="This form field has the same Data ID name as another form field. You may want to edit this Data ID name to make it unique.">
                            <v-icon @click="goToDataId" color="#BF0D00" class="ml-2" style="font-size: 14px; pointer-events: visible;">fal fa-info-circle</v-icon>
                        </fe-tooltip>
                    </div>

                    <div v-if="editor" class="d-flex ml-auto">
                        <div class="mx-1" style="width:18px;" >
                            <v-menu v-if="hasTasks" offset-y>
                                <template #activator="{on}">
                                    <i v-on="on" class="icon-btn fas fa-tasks theme--light" />
                                </template>
                                <v-list dense>
                                    <template v-for="task in data.tasks">
                                        <v-list-item @click="$emit('editActionItem',task)">
                                            {{task.name}}
                                            <fe-tooltip class="ml-auto" direction="right" caret style="display:inline-block" tooltip="Remove action item from field">
                                                <fe-icon-btn @click.stop="$emit('deleteActionItem',task)" size="12px" use-icon="fa-trash"></fe-icon-btn>
                                            </fe-tooltip>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-menu>
                        </div>

                        <div class="mx-1" style="width:18px;" >
                            <i v-if="hasRules"
                                class="icon-btn fas fa-project-diagram theme--light"
                                :class="{issue:hasRuleIssues}"
                                @click.stop="$emit('editDependency',data)"
                            />
                        </div>

                    </div>
                </div>

                <component
                    ref="localField"
                    :is="componentType"
                    :label="xtype == 'checkboxfield' ? data.box_label : ''"
                    :fieldRec="data"
                    :editor="editor"
                    v-bind="cfg"
                    v-on="listeners"
                    :style="[fontStyles, fieldTaskStatus, fieldMargins]"
                    class="sf-display field-task-status"
                    clearable
                    :loading="loading"
                    :disabled="disabled"
                    :height="height"
                    :isDuplicateDataId="isDuplicateDataId"
                ></component>

            </div>

            <div v-if="!noLabels && !data.hide_name && data.label_align == 'right'"
                class="sf-label d-flex"
                :style="[ {
                    width: '120px',
                    textAlign: data.label_align || 'left',
                    paddingTop: xtype == 'checkboxfield' ? '2px' : '0',
                    paddingLeft: '10px',
                }]"
            >
                {{fieldLabel}}
                <fe-tooltip v-if="isDuplicateDataId"
                    tooltip="This form field has the same Data ID name as another form field. You may want to edit this Data ID name to make it unique.">
                    <v-icon @click="goToDataId" color="#BF0D00" class="ml-2" style="font-size: 14px; pointer-events: visible;">fal fa-info-circle</v-icon>
                </fe-tooltip>
            </div>

        </template>
    </div>
</template>

<script>
import SmartTagField from './SmartTagField'
import ScoreField from './ScoreField'
import InterventionBlock from './InterventionBlock'
import ProgressMonitoringBlock from './ProgressMonitoringBlock'
import PageBreak from './PageBreak'
import FieldSpacer from './FieldSpacer'
import DisplayField from './DisplayField'
import InterventionDecisionRationale  from './InterventionDecisionRationale'
import SmartStudentId from './SmartStudentId'
import IncidentField from './IncidentField'
import AttendanceField from './AttendanceField'
import DemographicField from './DemographicField'
import GuardianField from './GuardianField'
import UserCombo from './UserCombo'
import Message from '@/components/common/Message'
import MenuBtn from '@/components/common/button/MenuBtn'
import { mapState } from 'vuex'
import { mapLocalState } from '@/util/vuexHelper'
import smartFormMixin from '../smartFormMixin'
import WeblinkField from './WebLinkField'
import SmartInstanceNameField from './SmartInstanceNameField'
import FormStatusField from './FormStatusField'

export default {
    name: 'FormField',
    mixins: [ smartFormMixin ],
    inject: ['localStore'],

    components: {
        SmartTagField,
        ScoreField,
        Message,
        MenuBtn,
        InterventionBlock,
        ProgressMonitoringBlock,
        PageBreak,
        FieldSpacer,
        DisplayField,
        InterventionDecisionRationale,
        SmartStudentId,
        IncidentField,
        AttendanceField,
        DemographicField,
        GuardianField,
        UserCombo,
        WeblinkField,
        SmartInstanceNameField,
        FormStatusField
    },

    props: {
        editor: {type:Boolean, default:false},
        liveForm: {type:Boolean, required:true},
        data: {},
        formInstance: {},
        disabled: {},
        duplicateFormDataIds: {type: Array, default: () => []},
    },

    computed: {
        ...mapState('global', ['currentYear']),
        ...mapLocalState([
            'fieldConfigs',
            'baseComponent',
            'schoolYears',
            'grades',
            'schools',
            'interventionLevels',
            'interventionTypes',
            'disabilityTypes',
            'ellLevels',
            'controls',
            'dependentSections',
            'dependentFields',
            'users',
            'students',
            'homeLanguages',
        ]),
        isLocked() {
            return this.editor || (this.formInstance ? !this.formInstance.can_edit : false)
        },
        isSelected: {
            set(v) {this.editorState.selected = v},
            get() {return this.editorState.selected}
        },
        fontStyles() {
            return {
                fontSize: this.data.font_size ? this.data.font_size+'px' : undefined,
                fontWeight: this.data.bold ? 'bold' : undefined,
                fontStyle: this.data.italic ? 'italic' : undefined,
                textDecoration: this.data.underline ? 'underline' : undefined,
            }
        },
        fieldTaskStatus() {
            switch (this.$props.data.field_task_status) {
                case 'today':
                    return {'--field-color': '#FAF2DF'}
                case 'overdue':
                    return {'--field-color': '#FAE1DF'}
                case 'future':
                    return {'--field-color': '#E0F3E7'}
                default:
                    if(this.xtype == 'checkboxfield') return {'--field-color': 'inherit'}
                    return {'--field-color': 'white'}
            }
        },
        xtype() {
            // return this.data.xtype || this.formFieldTypes[this.data.form_field_type_id].xtype
            if(!this.data.xtype) this.data.xtype = this.formFieldTypes[this.data.form_field_type_id].xtype
            return this.data.xtype
        },
        smartFieldHasData() {
            if (this.xtype === 'scorefield' && !(this.data.saved_searches && this.data.saved_searches.length > 0)) {
                return false
            }
            return true
        },
        componentType() {
            if (this.xtype === 'usercombo') return 'user-combo'
            if (this.xtype.indexOf('combo') > -1) return 'fe-remote-combo'
            if (this.xtype === 'smarttagfield') return 'smart-tag-field'
            if (this.xtype === 'scorefield') return 'score-field'
            if (this.xtype === 'datefield') return 'fe-date-picker'
            if (this.xtype === 'checkboxfield') return 'v-checkbox'
            if (this.xtype === 'textarea') return 'v-textarea'
            if (this.xtype === 'message') return 'message'
            if (this.xtype === 'interventionblock') return 'intervention-block'
            if (this.xtype === 'progressmonitoringblock') return 'progress-monitoring-block'
            if (this.xtype === 'pdfpagebreak') return 'page-break'
            if (this.xtype === 'fieldspacer') return 'field-spacer'
            if (this.xtype === 'displayfield') return 'display-field'
            if (this.xtype === 'decisionrationaleview') return 'intervention-decision-rationale'
            if (this.xtype === 'smartstudentid') return 'smart-student-id'
            if (this.xtype === 'smartincidentfield') return 'incident-field'
            if (this.xtype === 'smartattendancefield') return 'attendance-field'
            if (this.xtype === 'smartdemographicfield') return 'demographic-field'
            if (this.xtype === 'smartguardianfield') return 'guardian-field'
            if (this.xtype === 'weblink') return 'weblink-field'
            if (this.xtype === 'instancenamefield') return 'smart-instance-name-field'
            if (this.xtype === 'statuscodes') return 'form-status-field'

            return 'v-text-field'
        },
        fieldLabel() {
            if (this.data.hide_name) return ''
            return this.data.form_field_name || this.data.name
        },
        height: me => Number(me.data.height) || '',
        hasTasks: me => me.data.tasks && me.data.tasks.length,
        hasRules: me => me.dependentFields[me.data.id] || me.controls[me.data.id],
        hasRuleIssues: me => {
            return me.dependentFields[me.data.id] && me.dependentFields[me.data.id].find(x => x.dupWhen)
        },
        isDuplicateDataId() {
            return this.duplicateFormDataIds.includes(this.data.data_id)
        },
        fieldMargins() {
            return { 'margin-top': this.data.label_align != 'top' ? '18px' : '' }
        }
    },

    data() {
        return {
            editorState: {
                selected: false,
                draggable: false,
                drageOver: false,
            },
            blockError: false,
            cfg: {},
            listeners: {
                change: this.doResponseChange,
                input: this.checkRules,
                blockError: this.setBlockError,
            },
            loading: false,
            localItems: null
        }
    },

    created() {
        this.getComponentBinds(this.data)
    },

    methods: {
        setBlockError(v) {
            this.blockError = v
        },
        loadStudents() {
            if (!this.localItems) {
                this.loading = true
                this.$modelGet('studentGradeHistory', { school_year_id: this.currentYear.id })
                    .then(response => {
                        this.loading = false
                        this.cfg.items = response
                        this.localItems = true

                    })
                    .catch(err => {
                        console.warn('Unable to load core data [students]', err)
                    })
            }

        },
        onClick(e) {
            if(this.editor) {
                this.$emit('editor:click',this)
            }

            if (this.data.xtype === 'studentcombo') {
                this.loadStudents()
                this.$emit('student-focus')
            }

            this.$emit('field-focus', this.$props.data)
        },
        onDragStart(e) {
            e.dataTransfer.setData('sourceRec',JSON.stringify(this.data))
        },
        onDrop(e) {
            let source = JSON.parse(e.dataTransfer.getData('sourceRec'))
            if(source.form_field_type_id) {
                this.$emit('dropped',{target:this.data, source})
                e.stopPropagation()
            }
        },
        setComponentItems(cfg) {
            cfg.itemValue = cfg.itemValue || 'id'
            cfg.itemText = cfg.itemText || 'name'

            switch (this.xtype) {
                case 'studentcombo':
                    cfg.items = undefined
                    // cfg.url   = this.$models.getUrl('studentGradeHistory', 'read')+'&school_year_id='+this.currentYear.id
                    // cfg.items = this.students
                    cfg.itemValue = 'student_id'
                    cfg.itemText = 'student_full_name'
                    cfg.byId = true
                    break
                case 'usercombo':
                    cfg.itemText = 'user_full_name'
                    cfg.params = {
                        users: this.users
                    }
                    break
                case 'schoolyearcombo':
                    cfg.items = this.schoolYears
                    break
                case 'gradecombo':
                    cfg.items = this.grades
                    break
                case 'schoolcombo':
                    cfg.items = this.schools
                    break
                case 'interventioncombo':
                    cfg.items = this.interventionLevels
                    break
                case 'interventiontypecombo':
                    cfg.items = this.interventionTypes
                    break
                case 'disabilitycombo':
                    let types = this.$_.cloneDeep(this.disabilityTypes)
                    types.forEach(t => {
                        if (!t.display_name_group) {
                            t.display_name = t.display_name || t.description
                        } else {
                            t.display_name = t.description + (t.display_name ? " (" + t.display_name + ")" : "")
                        }
                    })

                    cfg.itemText = 'display_name'
                    cfg.items = types
                    cfg.multiple = true
                    cfg.moreLabel = 'Disability'
                    break

                case 'ellstatuscombo':
                    cfg.items = [
                        {id:1, name:'No'},
                        {id:2, name:'Yes'},
                    ]
                    break
                case 'ellcombo':
                    let levels = this.$_.cloneDeep(this.ellLevels)
                    levels.forEach(i => {
                        if (!i.display_name_group) {
                            i.display_name = i.display_name || i.name
                        } else {
                            i.display_name = i.name + (i.name ? " (" + i.display_name + ")" : "")
                        }
                    })

                    cfg.itemText = 'display_name'
                    cfg.items = levels
                    break

                case 'textarea':
                    cfg.rules = this.$fieldValidators('text', null, {required: false, limit: 20000})
                    break

                case 'progressmonitoringblock':
                    cfg.params = {
                        intervention_id: this.formInstance?.linked_intervention,
                        student_id: this.formInstance?.first_student_id,
                        category_id: this.formInstance?.intervention_category_id,
                        school_year_id: this.currentYear.id,
                    }
                    break
                case 'message':
                    // cfg.messages = this.data.records

                    // Don't allow reading of messages if viewing this form template to edit it,
                    // otherwise every single message will be fetched (performance, data visibility implications)
                    cfg.disableReadAccess = !this.data.form_instance_id
                    cfg.editable = !!this.data.form_instance_id

                    if (!this.data.form_instance_id) {
                        cfg.noMessagesText = 'Messages will be visible here when you create a form using this template.'
                    }

                    cfg.params = {
                        form_instance_id: this.data.form_instance_id,
                        form_field_id: this.data.form_field_id,
                    }
                    break
                case 'decisionrationaleview':
                    cfg.params = {
                        intervention_id: this.formInstance?.linked_intervention,
                        student_id: this.formInstance?.first_student_id,
                        message_type_id: 3,
                    }
                    break
                case 'basecombo':
                    if (this.data.form_field_type_id === 25) { // Home Language
                        cfg.items = this.homeLanguages
                        cfg.itemText = 'home_language_display'
                        cfg.itemValue = 'id'
                    }
                    break
                case 'datefield':
                    cfg.emitWhenValid = true
                    delete this.listeners.change
                    this.listeners.input = rec => {
                        if(this.cfg.value != rec) {
                            this.doResponseChange(rec)
                        }
                    }
                    break
                case 'combobox':
                    cfg.itemText = cfg.itemText || 'display_value'
                    break
                case 'statuscodes':
                    cfg.params = {
                        formInstance: this.formInstance
                    }
                    break
                default:
                    break
            }

        },
        checkRules(rec) {
            this.$emit('checkRules',[{...this.data, value:rec?.id ? rec.id : rec }])
        },
        doResponseChange(rec) {
            if(this.$refs?.localField.valid === false) return

            this.checkRules(rec)
            if (!rec) rec = "" // account for null

            let d = {
                form_field_id: this.data.form_field_id || this.data.id,
                form_instance_id: this.data.form_instance_id,
                value: rec?.id ? rec.id : rec
            }

            let submit
            if(this.data.form_field_id) {
                // form instance submit function
                submit = (vals, emit) => {
                    let o = {}
                    o[this.$models.formInstanceResponse.rootProperty] = vals.map(x => {
                        // only switch to strings here
                        if(Array.isArray(x.value) && x.value.length) {
                            x.value = typeof x.value[0] === 'object' ? x.value.map(y => y.id).join(',') : x.value.join(',')
                        }
                        x.value = this.$_.isBlank(x.value) ? "" : x.value+""
                        return x
                    })

                    this.$axios.post(this.$models.formInstanceResponse.update, o)
                    .then(response => {
                        // adding set timeout for occassional timing issue
                        setTimeout(function() {
                            let r = this.$ecResponse(response)
                            this.$emit('input', r.responses)
                            this.$emit('updated', r.responses)

                            if (emit) this.$emit('force-reload')
                            else this.cfg.value = rec
                        }.bind(this), 500)
                    })
                }
            } else {
                // template preview "submit" function
                submit = (vals) => {
                    vals.forEach(x => {
                        let comp = this.baseComponent.$refs['field_'+x.form_field_id][0]
                        comp.cfg.value = x.value
                        // comp.$emit('input',[{
                        //     id: x.form_field_id,
                        //     value: x.value
                        // }])
                    })
                }
            }

            if (this.liveForm && this.data.xtype === 'studentcombo' && !rec?.student_id) {
                // if clearing a student combo in a live form, reload to clear all related fields without responses
                submit([d],true)
            } else if (this.data.xtype === 'studentcombo' && rec?.student_id) {
                d.value = rec?.student_id ? rec.student_id : ""
                let me = this
                this.$messageBox({
                    title: 'Autofill Fields',
                    persistent: true,
                    maxWidth: 400,
                    message: 'You have changed a students on the form.  Would you like to autofill student related fields?' +
                    '<br>This will overwrite existing entries!',
                    actions: [{
                        text: 'No',
                        onClick: () => {
                            submit([d])
                        }
                    }, {
                        text: 'Autofill',
                        onClick: async () => {
                            if(this.liveForm && this.data.form_field_id) {
                                d.clear_student_values = 1
                                submit([d],true)
                            } else {
                                let dependents = []
                                let disabilities = []
                                await this.$modelGet('studentDisability', {
                                    student_id: d.value,
                                    school_year_id: this.currentYear.id,
                                    active: 1
                                }).then(r => {
                                    disabilities = r
                                })
                                Object.values(me.baseComponent.allFields).forEach(field => {
                                    let typeId = field.form_field_type_id
                                    if ([4, 5, 12, 16, 17, 25, 26, 28, 31, 32].indexOf(typeId) > -1) {
                                        let t = {
                                            form_field_id: field.form_field_id || field.id,
                                            form_instance_id: this.data.form_instance_id,
                                            value: null
                                        }
                                        if(typeId == 4) t.value = rec.school_id
                                        else if(typeId == 5) t.value = rec.grade_id
                                        else if(typeId == 12) t.value = null
                                        else if(typeId == 16) t.value = rec.date_of_birth
                                        else if(typeId == 17) t.value = me.ageAsOf(rec.date_of_birth, field.form_instance_created || new Date())
                                        else if(typeId == 25) t.value = rec.home_language_id
                                        else if(typeId == 26) t.value = null
                                        else if(typeId == 28) t.value = disabilities.map(x => x.student_disability_type_id)
                                        else if(typeId == 31) t.value = rec.ell_flag != null ? rec.ell_flag + 1 : null
                                        else if(typeId == 32) t.value = rec.ell_level_id

                                        dependents.push(t)
                                    }
                                })

                                dependents.push(d)
                                submit(dependents, true)
                            }
                        }
                    }]
                })
            } else {
                submit([d])
            }
        },

        ageAsOf(birthDate, dt) {
			if (!birthDate || !dt) return '';

			var from = new Date(birthDate);
			var to   = new Date(dt);
		    var age = to.getFullYear() - from.getFullYear();
		    var m = to.getMonth() - from.getMonth();
		    if (m < 0 || (m === 0 && to.getDate() < from.getDate())) {
		        age--;
            }

		    return age;
		},

        getComponentBinds(data) {
            let cfg = {
                value: data.record ? data.record : data.value,
                flat: true,
                solo: true,
                dense: true
            }

            if ([
                'smartincidentfield',
                'scorefield',
                'smartstudentid',
                'smartattendancefield',
                'smartdemographicfield',
                'smartguardianfield',
            ].includes(data.xtype)) {
                cfg.data = data.saved_searches

            } else if (data.xtype === 'smarttagfield') {
                cfg.tags = data.saved_searches

            } else if (this.xtype.indexOf('combo') > -1) {
                if (data.record) {
                    cfg.items = [data.record]
                } else if (data.records) {
                    if(this.xtype == 'combobox') {
                        cfg.items = data.records.filter(x => x.active==1).sort((a,b) => a.rank-b.rank)
                    } else {
                        cfg.items = data.records
                    }
                }
                cfg.itemText = data.recordItemText;
                cfg.itemValue = data.recordItemValue;

            } else if (this.xtype === 'datefield') {
                // cfg.emitWhenValid = true

            } else if (this.xtype === 'numberfield') {
                if (data.min_value || data.max_value) {
                    cfg.type = "number"
                    cfg.rules = this.$fieldValidators('number', null, { required: false, min: data.min_value, max: data.max_value })
                }

            } else if (this.xtype === 'checkboxfield') {
                delete cfg.value
                // some old database values are coming back as strings
                if (data.value === "false") {
                    cfg['input-value'] = false
                } else if (data.value) {
                    cfg['input-value'] = true
                }

            }

            this.setDependencies(cfg)
            this.setComponentItems(cfg)
            cfg.formInstance = this.$props.formInstance
            this.cfg = cfg
            this.fieldConfigs[this.data.id] = cfg
        },
        setDependencies(cfg) {
        },
        goToDataId() {
            // want to push user to Advanced tab so that they can edit data_id
            this.$emit('goToAdvanced')
        }
    }
}
</script>

<style lang="scss" scoped>
.icon-btn {
    width: 18px;
    height: 18px;
    display: inline-flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: rgba(0,0,0,.54);
    pointer-events: auto;
    cursor: pointer;
    &:hover {
        background-color: rgba(0,0,0,.1) ;
    }
}
.block-error {
    border: 1px solid red;
}
.field-toolbar {
    position: absolute;
    right: 0px;
    background-color: pink;
}
.highlight-field {
    background: var(--fe-accent);
    transition: background .5s linear;

    &-out {
        background: none;
    }
}

.highlight-field-out {
    background: none;
    transition: background .3s linear;
}

.issue {
    color: red;
}

// .displayfield {
//     padding-bottom: 8px;
//
//     ::v-deep b {
//         font-weight: 900;
//         color: black;
//     }
// }
.sf-field {
    margin: 0px;
    align-items: center;
    ::v-deep input[type='number'] {
        width: 100%;
    }
}
.no-events {
    pointer-events: none;
    cursor: pointer;
}
.editor {
    &-state {
        border-radius: 4px;
        padding-right: 8px;
        &:not(&-selected):hover {
            outline: 2px dashed var(--fe-selected);
            outline-offset: -3px;
        }
        &-selected {
            background-color: var(--fe-selected);
        }
        &-drageOver {
            outline: 4px dashed var(--fe-accent);
        }
    }
}
.dragbar {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 100%;
    &:hover {
        box-shadow:inset 0 0 0 99999px rgba(0,0,0,0.1);
    }
}

.smartfield-container {
    padding: 8px 8px 8px 8px ;
    border-radius: 5px;
    margin-bottom: 8px;
}

.sf-label {
    font-size: 12px;
}

// This fixes field alignments when two-line labels along side one-line labels
// This does not help with 3 line labels
.sf-label-top {
    position: absolute;
    bottom: 0px;
}

.sf-display {
    width: 100%;
}

// .sf-bold {
//     font-weight: bold;
// }
// .sf-italic {
//     font-style: italic;
// }
//
// .sf-underline {
//     text-decoration: underline;
// }

.field-task-status {
    ::v-deep .v-input__slot {
        background-color: var(--field-color) !important;
    }

}

</style>
