<template>
    <div class="flex-fill flex-column">
        <component
            :is="item.component"
            @go="go"
            v-bind="item.attrs"
        />
    </div>
</template>

<script>
    import Vuex, {mapState} from "vuex"
    import Breadcrumb from "../../../common/Breadcrumb"
    import LandingPage from "./LandingPage"
    import InterventionLevels from "./InterventionLevels"
    import InterventionTypes from "./InterventionTypes"
    import InterventionTypeList from "./InterventionTypeList"
    import InterventionExitStatus from "./InterventionExitStatus"
    import InterventionProgressMonitoring from "./InterventionProgressMonitoring"
    import InterventionParticipationTypes from "./InterventionParticipationTypes"
    import InterventionStrategy from "./InterventionStrategy"
    import FastBridgeIntegration from "./FastBridgeIntegration"
    import ScoreStatus from './ScoreStatus'

    export default {
        name: "ManageInterventions",

        components: {
            Breadcrumb,
            LandingPage,
            InterventionLevels,
            InterventionTypes,
            InterventionExitStatus,
            InterventionProgressMonitoring,
            InterventionParticipationTypes,
            InterventionTypeList,
            InterventionStrategy,
            FastBridgeIntegration,
            ScoreStatus
        },

        watch: {
            activePage: {
                deep: true,
                handler(n, o) {}
            }
        },

        data() {
            return {
                items: [{
                    name: 'Interventions',
                    show: 'index',
                    persist: false,
                    component: 'landing-page',
                    landingPage: true
                }, {
                    name: 'Levels',
                    show: 'levels',
                    persist: false,
                    component: 'intervention-levels',
                    landingPage: true,
                    count: 0
                }, {
                    name: 'Strategies',
                    show: 'types',
                    persist: true,
                    component: 'intervention-types',
                    landingPage: true,
                    count: 0
                }, {
                    name: 'Exit Status',
                    show: 'exitStatus',
                    persist: false,
                    component: 'intervention-exit-status',
                    landingPage: true,
                    count: 0
                // }, {
                //     name: 'Progress Monitoring',
                //     show: 'progressMonitoring',
                //     persist: true,
                //     component: 'intervention-progress-monitoring'
                }, {
                    name: 'Participation Types',
                    show: 'participationTypes',
                    persist: false,
                    component: 'intervention-participation-types',
                    landingPage: true,
                    count: 0
                }, {
                    name: 'Point Sheet Score Status',
                    show: 'score-status',
                    persist: false,
                    component: 'score-status',
                    landingPage: true
                }, {
                    name: 'Intervention Type List',
                    show: 'academic-type',
                    persist: false,
                    child: true,
                    component: 'intervention-type-list',
                    attrs: {
                        type: 'academic',
                        title: 'Monitored By Assessment'
                    }
                }, {
                    name: 'Intervention Type List',
                    show: 'behavior-type',
                    persist: false,
                    child: true,
                    component: 'intervention-type-list',
                    attrs: {
                        type: 'behavior',
                        title: 'Monitored by Point Sheet'
                    }
                }, {
                    name: 'Intervention Strategy',
                    show: 'intervention-strategy',
                    persist: false,
                    child: true,
                    component: 'intervention-strategy',
                    attrs: {
                    }
                // }, {
                //     name: 'Behavior Intervention Type',
                //     show: 'intervention-strategy',
                //     persist: false,
                //     child: true,
                //     show: 'intervention-strategy',
                //     attrs: {
                //         type: 'behavior'
                //     }
                }],
            }
        },

        computed: {
            ...mapState('manage', ['breadcrumbs']),

            activePage() {
                let b = this.breadcrumbs["Interventions"]

                return b[b.length - 1]
            },

            item(v) {
                if (v.activePage.component !== 'fast-bridge-integration') {
                    return this.items.find(r => r.show === this.activePage.show)
                } else {
                    return v.activePage
                }
            }
        },

        provide() {
            this.localStore = new Vuex.Store({
                mutations: {},
                actions: {}
            })

            return {
                localStore: this.localStore
            }
        },

        methods: {
            go(page, attrs) {
                if (!page) return

                if (attrs) {
                    let f = this.items.find(rec => rec.show == page.show)
                    f.attrs = attrs
                }

                this.$store.commit('manage/addCrumb', {
                    key: "Interventions",
                    val: page
                })
                let event = 'show ' + page.show
                this.$emit(event)
            }
        }
    }
</script>

<style scoped>

</style>
