<template>
    <v-layout v-if="isMounted" row fill-height>
        <fe-stepper-checkbox-panel v-if="multipleYears" byId :items="schoolYears" :surfaceFirstSelections="surfaceFirstSelections" v-model="params.school_year_id" search searchPlaceholder="School Years"/>
        <fe-stepper-select-panel v-else-if="!cohort" byId :items="schoolYears" :surfaceFirstSelection="surfaceFirstSelections" v-model="params.school_year_id" search searchPlaceholder="School Year"/>
        <fe-stepper-select-panel v-else-if="multipleGrades" byId :items="schoolYears" v-model="params.cohort_school_year_id" :surfaceFirstSelection="surfaceFirstSelections" search searchPlaceholder="School Year"/>
        <fe-stepper-select-panel v-else byId :items="schoolYears" v-model="params.cohort_school_year_id" :surfaceFirstSelection="surfaceFirstSelections" search searchPlaceholder="School Year"/>
        <v-divider vertical style="background-color:#E0E1E6"/>

        <template v-if="hasYear">
            <fe-stepper-checkbox-panel v-if="!cohort && hasYear" byId :items="schools" :surfaceFirstSelections="surfaceFirstSelections" v-model="params.school_id" allOption allOptionText="All Schools" search searchPlaceholder="Schools"/>
            <fe-stepper-checkbox-panel v-else-if="hasYear" byId :items="schools" :surfaceFirstSelections="surfaceFirstSelections" v-model="params.cohort_school_id" allOption allOptionText="All Schools" search searchPlaceholder="Schools"/>
            <v-divider v-if="hasYear" vertical style="background-color:#E0E1E6"/>
        </template>

        <template v-if="hasYear && hasSchool">
            <fe-stepper-checkbox-panel v-if="!cohort && hasYear" byId :items="grades" :surfaceFirstSelections="surfaceFirstSelections" v-model="params.grade_id" allOption allOptionText="All Grades" search searchPlaceholder="Grades"/>
            <fe-stepper-checkbox-panel v-else-if="cohort && multipleGrades && hasYear" byId :items="grades" :surfaceFirstSelections="surfaceFirstSelections" v-model="params.cohort_grade_id" allOption allOptionText="All Grades" search searchPlaceholder="Grades"/>
            <fe-stepper-select-panel v-else-if="hasYear" byId :items="grades" :surfaceFirstSelection="surfaceFirstSelections" v-model="params.cohort_grade_id" search searchPlaceholder="Grade"/>
            <v-divider v-if="hasYear" vertical style="background-color:#E0E1E6"/>
        </template>

        <template v-if="hasYear && hasSchool && hasGrade">
            <fe-stepper-panel>
                <div class="fs14 pa-2">Filters</div>
                <v-divider style="background-color:#E0E1E6"/>
                <v-form lazy-validation v-if="cohort ? params.cohort_school_year_id : params.school_year_id" style="overflow-x: hidden;">
                    <advanced-filters 
                        class="slim-filters" 
                        ref="advancedFilters" 
                        v-model="params.advancedFilters" 
                        listView 
                        byId 
                        :showGroupBy="false" 
                        :resetEntry="false"
                    />
                </v-form>
            </fe-stepper-panel>
        </template>
    </v-layout>
</template>

<script>
    import { mapState } from 'vuex'
    import AdvancedDemographicFilters from "@/components/common/AdvancedDemographicFilters"
    import AdvancedFilters from '@/components/common/AdvancedFilters'
    import advancedFiltersMixin from '@/mixins/advancedFilters'

    export default {
        name: 'StudentStepperPanel',

        components: {
            AdvancedDemographicFilters,
            AdvancedFilters
        },

        mixins: [advancedFiltersMixin],

        props: {
            value: {},
            byId: {
                type: Boolean,
                default: false
            },
            surfaceFirstSelections: {
                type: Boolean,
                default: false
            },
            cohort: {
                type: Boolean,
                default: false
            },
            multipleGrades: {
                type: Boolean,
                default: false
            },
            multipleYears: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isMounted: false,
                schoolYears: [],
                schools: [],
                grades: [],
                school_year_id: null,
                params: {},
                localValue: {}, // needed for advancedFilters.js
                mealStatusItems: null,
                allDisabilityIds: null,
                allLevelIds: null,
                advancedFilters: [{
                    label: 'Ethnicity',
                    model: 'ethnicity',
                    url: 'filters.php?action=get&filter=ethnicity',
                    rootProperty: 'ethnicities',
                    itemText: "ethnicity",
                    itemValue: "ethnicity"
                }, {
                    label: 'Disability Code',
                    model: 'disability',
                    url: 'filters.php?action=get&filter=disability',
                    itemText: "description",
                    itemValue: "code",
                    rootProperty: 'disabilities'
                }, {
                    label: 'Gender',
                    model: 'gender',
                    itemText: 'gender',
                    itemValue: 'gender',
                    url: 'filters.php?action=get&filter=gender',
                    rootProperty: 'genders'
                }, {
                    label: 'Meal Status',
                    itemText: 'name',
                    itemValue: 'id',
                    model: 'meal_status',
                    items: [],
                    rootProperty: 'meal_status'
                }, {
                    label: 'ELP Level',
                    itemText: 'name',
                    itemValue: 'id',
                    model: 'ell_level',
                    items: []
                }, {
                    label: 'ELL',
                    itemText: 'name',
                    itemValue: 'id',
                    model: 'ell',
                    items: [{
                        id: 0,
                        name: 'No'
                    }, {
                        id: 1,
                        name: 'Yes'
                    }]
                }]
            }
        },

        computed: {
            ...mapState('global', ['defaultSchool', 'sessionUser']),
            hasYear() {
                if (this.multipleYears) {
                    return this.params?.school_year_id?.length
                } else {
                    return this.params?.school_year_id || this.params?.cohort_school_year_id
                }
            },
            hasSchool() {
                return this.params?.school_id?.length || this.params?.cohort_school_id?.length
            },
            hasGrade() {
                if (!this.cohort) {
                    return this.params?.grade_id?.length
                } else {
                    return !!this.params?.cohort_grade_id
                }
            },
            isValid() {
                return this.hasYear && this.hasSchool && this.hasGrade
            },
            ellLevelEnabled () {
                return this.$store.state.global.shareableStores.ell_level_enabled
            },
            selectedYear() {
                if (this.params.school_year_id) {
                    if (Array.isArray(this.params.school_year_id)) {
                        return this.params.school_year_id.map(r => this.byId ? r : r.id)
                    } else {
                        return this.byId ? this.params.school_year_id : this.params.school_year_id.id
                    }
                } else if (this.params.cohort_school_year_id) {
                    if (Array.isArray(this.params.cohort_school_year_id)) {
                        return this.params.cohort_school_year_id.map(r => this.byId ? r : r.id)
                    } else {
                        return this.byId ? this.params.cohort_school_year_id : this.params.cohort_school_year_id.id
                    }
                }
            },
            searchDisplay() {
                let str = ''
                let pieces = []
                let itm

                if (this.multipleYears) {
                    if (!this.params?.school_year_id?.length) {
                        return '<b>Select School Years</b>'
                    } else {
                        let itm = this.schoolYears.find(itm => itm.id === this.params.school_year_id[0])
                        if (itm) {
                            pieces.push(itm.name + (this.params.school_year_id.length > 1 ? `...+${this.params.school_year_id.length - 1}` : ''))
                        } else {
                            return '<b>Select School Years</b>'
                        }
                    }
                }

                else {
                    let schoolYearId = this.params.school_year_id || this.params.cohort_school_year_id
                    if (schoolYearId) {
                        if (schoolYearId.length) {
                            itm = this.schoolYears.find(itm => itm.id === schoolYearId[0])
                            if (itm) {
                                pieces.push(itm.name + ' ' + (schoolYearId.length > 1 ? '...+' + (schoolYearId.length - 1) + '' : ''))
                            } else {
                                return str
                            }
                        } else {
                            itm = this.schoolYears.find(itm => itm.id === schoolYearId)
                            if (itm) {
                                pieces.push(itm?.name || '')
                            } else {
                                return str
                            }
                        }
                    } else {
                        return '<b>Select School Year</b>'
                    }
                }

                let schoolId = this.params.school_id || this.params.cohort_school_id
                if (schoolId && schoolId.length) {
                    itm = this.schools.find(itm => itm.id === schoolId[0])
                    if (itm) {
                        pieces.push(itm.name + ' ' + (schoolId.length > 1 ? '...+' + (schoolId.length - 1) + '' : ''))
                    } else {
                        pieces.push('All Schools')
                    }
                } else {
                    pieces.push('<b>Select Schools</b>')
                    return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                }

                let gradeId = this.params.grade_id || this.params.cohort_grade_id
                if (gradeId && (!(gradeId instanceof Array) || gradeId.length)) {
                    if (gradeId.length) {
                        itm = this.grades.find(itm => itm.id === gradeId[0])
                        if (itm) {
                            pieces.push(itm.name + ' ' + (gradeId.length > 1 ? '...+' + (gradeId.length - 1) + '' : ''))
                        } else {
                            pieces.push("All Grades")
                        }
                    } else {
                        itm = this.grades.find(itm => itm.id === gradeId)
                        if (itm) {
                            pieces.push(itm?.name || '')
                        } else {
                            pieces.push("All Grades")
                        }
                    }
                } else {
                    pieces.push('<b>Select Grade' + ((!this.cohort && this.hasYear) ? 's</b>' : '</b>'))
                    return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
                }

                pieces.push('Select Filters (Optional)')
                return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
            },
            summary() {
                let pieces = {}
                let itm

                let schoolYearId = this.params.school_year_id || this.params.cohort_school_year_id
                if (schoolYearId) {
                    if (schoolYearId.length > 1) {
                        pieces.schoolYear = `${schoolYearId.length} Years`
                    } else if (schoolYearId.length) {
                        itm = this.schoolYears.find(itm => itm.id === schoolYearId[0])
                        if (itm) {
                            pieces.schoolYear = itm.name
                        } else {
                            return pieces
                        }
                    } else {
                        itm = this.schoolYears.find(itm => itm.id === schoolYearId)
                        if (itm) {
                            pieces.schoolYear = itm.name
                        } else {
                            return pieces
                        }
                    }
                } else {
                    return pieces
                }

                let schoolId = this.params.school_id || this.params.cohort_school_id
                if (schoolId) {
                    if (schoolId.length > 1) {
                        pieces.school = `${schoolId.length} Schools `
                    } else if (schoolId.includes(-1)) {
                        pieces.school = 'All Schools '
                    } else if (schoolId.length) {
                        itm = this.schools.find(itm => itm.id === schoolId[0])
                        if (itm) {
                            pieces.school = `${itm.name}`
                        } else {
                            return pieces
                        }
                    } else {
                        itm = this.schools.find(itm => itm.id === schoolId)
                        if (itm) {
                            pieces.school = `${itm.name}`
                        } else {
                            return pieces
                        }
                    }
                } else {
                    return pieces
                }

                let gradeId = this.params.grade_id || this.params.cohort_grade_id
                if (gradeId) {
                    if (gradeId.length > 1) {
                        pieces.grade = `${gradeId.length} Grades `
                    } else if (gradeId.length && gradeId.includes(-1)) {
                        pieces.grade = 'All Grades '
                    } else if (gradeId.length) {
                        itm = this.grades.find(itm => itm.id === gradeId[0])
                        if (itm) {
                            pieces.grade = `${itm.name}`
                        } else {
                            return pieces
                        }
                    } else {
                        itm = this.grades.find(itm => itm.id === gradeId)
                        if (itm) {
                            pieces.grade = `${itm.name}`
                        } else {
                            return pieces
                        }
                    }
                } else {
                    return pieces
                }

                return pieces
            },
        },

        watch: {
            params: {
                handler(v) {
                    // handle All Disabilities
                    if (v.advancedFilters?.disability_type_id && v.advancedFilters?.disability_type_id[0].code === 'ALLDIS') {
                        v.advancedFilters.disability_type_id[0].allIds = this.allDisabilityIds // include all ids in the object
                    } else if (v.advancedFilters?.disability_code && v.advancedFilters?.disability_code === 'ALLDIS') {
                        // If "All Disabilities" was previously selected and the user is editing the search,
                        // make sure user sees "All Disabilities" chip in the filters instead of individual disability chips
                        v.advancedFilters.disability_type_id = []
                        v.advancedFilters.disability_type_id.push(
                            {
                                description: 'All Disabilities',
                                display_name: 'All Disabilities',
                                code: 'ALLDIS',
                                id: -1,
                                allIds: this.allDisabilityIds
                            }
                        )
                        delete v.advancedFilters.disability_code
                    }

                    // handle Any Meal Status
                    if (v.advancedFilters?.meal_status_id && v.advancedFilters?.meal_status_id[0].code === 'ALLSTATUS') {
                        v.advancedFilters.meal_status_id[0].allIds = this.mealStatusItems // include all ids in the object
                    } else if (v.advancedFilters?.status_code && v.advancedFilters?.status_code === 'ALLSTATUS') {
                        // If "Any Status" was previously selected and the user is editing the search,
                        // make sure user sees "Any Status" chip in the filters instead of individual status chips
                        v.advancedFilters.meal_status_id = []
                        v.advancedFilters.meal_status_id.push(
                            {
                                name: 'Any Status',
                                display_name: 'All Status',
                                code: 'ALLSTATUS',
                                id: -1,
                                allIds: this.mealStatusItems
                            }
                        )
                        delete v.advancedFilters.status_code
                    }

                    // handle All Interventions
                    if (v.advancedFilters?.intervention_level && v.advancedFilters?.intervention_level[0].code === 'ALLINT') {
                        v.advancedFilters.intervention_level[0].allIds = this.allLevelIds
                    } else if (v.advancedFilters?.intervention_code && v.advancedFilters?.intervention_code === 'ALLINT') {
                        // If "All Interventions" was previously selected and the user is editing the search,
                        // make sure user sees "All Interventions" chip in the filters instead of individual disability chips
                        v.advancedFilters.intervention_level = []
                        v.advancedFilters.intervention_level.push(
                            {
                                description: 'All Interventions',
                                code: 'ALLINT',
                                id: -1,
                                allIds: this.allLevelIds,
                                rank: 0,
                                icon: null,
                            }
                        )
                        delete v.advancedFilters.intervention_code
                    }

                    this.$emit('input', this.$_.cloneDeep(v))//{...v, ...this.params.advancedFilters})
                    this.$emit('selected', true)
                },
                deep: true
            },
            'params.school_year_id'(v) {
                if (this.cohort) return

                if (v) {
                    this.$modelGet('school', { school_year_id: this.selectedYear, incl_groups: 1, active: 1 }).then(r => {
                        this.schools = r.sort((a, b) => a.name < b.name ? -1 : 1)

                        // School groups cannot be selected, so ignore default school if it's a group
                        if (this.defaultSchool && !this.defaultSchool.school_group_id) {
                            if (this.params.school_id?.length === 0 || !this.params.school_id) {
                                // params currently arrives with undefined properties (e.g. cohort_school_id)
                                // so we have to redeclare the entire params object to allow watchers et al. to come online
                                this.params = {
                                    ...this.params,
                                    school_id: [this.defaultSchool.id],
                                }
                            }
                        }
                    })
                } else {
                    this.schools = []
                }
            },
            'params.cohort_school_year_id'(v) {
                if (v) {
                    this.$modelGet('school', { school_year_id: this.selectedYear, incl_groups: 1, active: 1 }).then(r => {
                        this.schools = r.sort((a, b) => a.name < b.name ? -1 : 1)

                        // School groups cannot be selected, so ignore default school if it's a group
                        if (this.defaultSchool && !this.defaultSchool.school_group_id) {
                            if (this.params.cohort_school_id?.length === 0 || !this.params.cohort_school_id) {
                                // params currently arrives with undefined properties (e.g. cohort_school_id)
                                // so we have to redeclare the entire params object to allow watchers et al. to come online
                                this.params = {
                                    ...this.params,
                                    cohort_school_id: [this.defaultSchool.id],
                                }
                            }
                        }
                    })
                } else {
                    this.schools = []
                }
            },
            'params.school_id'(v) {
                if (this.cohort) return
                if (v && this.params.school_year_id) {
                    this.$modelGet('grade', { school_year_id: this.selectedYear, school_id: v }).then(r => {
                        this.grades = r
                    })
                } else {
                    this.schools = []
                }
            },
            'params.cohort_school_id'(v) {
                if (v) {
                    this.$modelGet('grade', { school_year_id: this.selectedYear, school_id: v }).then(r => {
                        this.grades = r
                    })
                } else {
                    this.schools = []
                }
            },
            isValid(v) {
                this.$emit('validate', v)
            },
            searchDisplay(v) {
                this.$emit('searchDisplayChange', v)
            },
            summary(v) {
                this.$emit('summaryChange', v)
            },
        },

        mounted() {
            this.params = {...this.value}
            this.loadBase()
            this.isMounted = true
        },

        methods: {
            getSubmitParams(advancedFiltersOnly = false) {
                let opts = this.getSearchObject()
                let p = {}

                if (!advancedFiltersOnly) {
                    Object.keys(this.params).forEach(key => {
                        if (Array.isArray(this.params[key])) {
                            p[key] = this.params[key].map(r=>r.id).filter(r=>r!=-1)
                        } else {
                            p[key] = this.params[key].id
                        }
                    })
                }

                Object.assign(p, opts)
                return p
            },

            loadBase() {
                this.$modelGet('schoolYear').then(r => {
                    this.schoolYears = r
                })
                this.$modelGet('ellLevel')
                    .then(response => {
                        let f = this.advancedFilters.find(a=>a.model==='ell_level')
                        f.items = response
                    })
                    .catch(err => console.err('Unable to load core data [ellLevel]', err))
                this.$modelGet('interventionLevel')
                    .then(levels => {
                        this.allLevelIds = levels.map(level => `${level.id}`).join(',')
                    })
                this.$modelGet('studentDisabilityType')
                    .then(disabilities => {
                        this.allDisabilityIds = disabilities.map(disability => `${disability.id}`).join(',')
                    })

                if (this.sessionUser.security_codes.includes('SEARCH_MEAL_STATUS') || this.sessionUser.security_codes.includes('DRILLDOWN_MEAL_STATUS')) {
                    this.$modelGet('mealStatusType')
                        .then(status => {
                            this.mealStatusItems = status.map(s => `${s.id}`).join(',')
                        })
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
::v-deep .filter-component {
    margin-left: auto;
    max-width: 100%;
}
</style>
