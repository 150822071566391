<template>
    <fe-dialog
        v-if="record"
        title="Rename Form"
        :acceptButtonDisabled="!valid"
        @dismiss="$emit('close')"
        @accept="rename"
        dismissButtonText="Cancel"
        acceptButtonText="Save"
        persistent
    >
        <v-form @submit.prevent v-model="valid">
            <fe-label>Name</fe-label>
            <v-text-field
                :rules="$fieldValidators('text', null, { required: true, limit: 100})"
                counter=100
                flat solo dense
                v-model="record.display_name"
            />
        </v-form>
        
        <fe-crud ref="crud" :config="$models.formInstance" :autoload="false" />
    </fe-dialog>
</template>

<script>
export default {
    name: 'RenameDialog',
    props: {
        record: {
            type: Object,
        },
    },
    computed: {},
    methods: {
        rename() {
            this.$refs.crud.update(this.record)
            .finally(() => {
                this.$emit('close')
                this.$emit('changed',this.record)
            })
        },
    },
    data() {
        return {
            valid: false,
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
