<template>
    <div>
        <fe-mask v-show="loading" :showLoader="true"/>

        <v-layout v-if="!emptyCharts" class="ec-collection-layout-main" row wrap>
            <template v-for="(chart, index) in totalCharts">
                <v-flex
                    v-observe-visibility="{callback: (isVisible, entry) => visibilityChanged(isVisible, entry, index), once: true, throttle: 300}"
                    v-resize="resize"
                    :style="chart.type === 'big5' ? 'margin-bottom: -30px;' : null"
                    :key="index"
                    :ref="'chart' + index"
                    md6
                    xs12
                >
                    <template v-if="chart && chart.overallResults">
                        <template v-for="(cfg, i) in chart.overallResults">
                            <v-lazy>
                                <benchmark-chart
                                    ref="collectionChartRef"
                                    :border="false"
                                    class="ma-1"
                                    :uiConfig="chart.uiConfig"
                                    collectionChart
                                    :currentCategory="{ id:  1 }" :config='cfg'
                                    :type="cfg.BASIC.length > 0 ? 'basic' : 'detailed'"
                                    :key="`basic-chart-` + i"
                                    :pinTitle.sync="chart.title"
                                    :pinnable="false"
                                    :performanceBands="performanceBands"
                                    @delete="removePinnedChart(chart.params.saved_search_id)"
                                    @bandNotFound="fetchPerformanceBands(true)"
                                />
                            </v-lazy>
                        </template>
                    </template>

                    <template v-else-if="chart && chart.chartType === 'attendance'">
                        <v-lazy>
                            <attendance-chart
                                :pinTitle="chart.search_id.title || chart.name"
                                :height="400"
                                :config="chart.config"
                                :chartParams="chart.chartParams"
                                :property="chart.property"
                                :showInsight="false"
                                :params="{saved_search_id: chart.search_id.saved_search_id, dashboard_id: chart.search_id.dashboard_id, index:  chart.index}"
                                :pinnable="false"
                                collectionChart
                                @all-charts="getAllCharts"
                                @chartClicked="attendanceClick(chart, ...arguments)"
                            />
                        </v-lazy>
                    </template>

                    <template v-else-if="chart && chart.type === 'big5'">
                        <v-lazy>
                            <big-five-chart
                                :height="400"
                                :config="chart"
                                :chartParams="chart.chartParams"
                                :categories="chart.categories"
                                :pinTitle="chart.name"
                                collectionChart
                                @all-charts="getAllCharts"
                                @chartClicked="bigFiveClick(chart, ...arguments)"
                            />
                        </v-lazy>
                    </template>

                    <template v-else-if="chart && (chart.type === 'pyramid' || chart.type==='detailed_pyramid')">
                        <v-lazy>
                            <incident-range-charts
                                :params="{saved_search_id: chart.search_id.saved_search_id}"
                                :chartTypes="[chart.type]"
                                :pinnedChart="chart"
                                collectionChart
                                @all-charts="getAllCharts"
                            />
                        </v-lazy>
                    </template>

                    <template v-else-if="chart && chart.chartType === 'studentScore'">
                        <v-lazy>
                            <assessment-trendline
                                :border="false"
                                :params="{
                                    saved_search_id: chart.search_id.saved_search_id,
                                    dashboard_id: chart.search_id.dashboard_id,
                                    index:  chart.index,
                                    type: chart.chartType,
                                    title: chart.search_id.title
                                }"
                                :pinnable="false"
                                :height="350"
                                :isCollection="true"
                                @all-charts="getAllCharts" collectionChart
                            />
                        </v-lazy>
                    </template>

                    <template v-else-if="chart && chart.chartType === 'academicMonitor'">
                        <v-lazy>
                            <academic-progress-monitoring2
                                :pinTitle="chart.name"
                                :params="{saved_search_id: chart.search_id.saved_search_id, dashboard_id: chart.search_id.dashboard_id, index:  chart.index}"
                                :height="350"
                                :isCollection="true"
                                collectionChart
                                @all-charts="getAllCharts"
                            />
                        </v-lazy>
                    </template>

                    <template v-else-if="chart && chart.chartType === 'behaviorMonitor'">
                        <v-lazy>
                            <behavior-progress-monitoring
                                style="height: 400px;"
                                height="300"
                                :pinTitle="chart.name"
                                :params="{saved_search_id: chart.search_id.saved_search_id, dashboard_id: chart.search_id.dashboard_id, index:  chart.index}"
                                collectionChart
                                @all-charts="getAllCharts"
                                @delete="removePinnedChart(chart.search_id.saved_search_id)"
                            />
                        </v-lazy>
                    </template>

                    <template v-else-if="chart && chart.chartType === 'longitudinal'">
                        <v-lazy>
                            <div class="ml-2 mt-1" style="width: 98%;">
                                <chart-panel class="chart-container" style="height: 405px;" border>
                                    <div class="longitudinal-chart">
                                        <highcharts v-bind="chart.config" class="fe-chart"/>

                                        <div class="collection-tools d-flex">
                                            <fe-icon-btn useIcon="fas fa-expand-alt" @click="doExpand(chart)"/>

                                            <fe-icon-btn
                                                useIcon="far fa-trash"
                                                @click="removePinnedChart(chart.search_id)"
                                            />

                                            <menu-btn class="pa-3">
                                                <v-list-item @click="doRename(chart)">
                                                    <i class="far fa-edit fe-icon print-icon"/>
                                                    <span class="ml-6">Rename Pin</span>
                                                </v-list-item>
                                            </menu-btn>
                                        </div>
                                    </div>
                                </chart-panel>
                            </div>
                        </v-lazy>
                    </template>

                    <template v-else-if="chart && chart.chartType === 'enrollment'">
                        <v-lazy>
                            <enrollment-chart
                                :title="chart.title"
                                :type="chart.type"
                                :data="chart.data"
                                :demographic="chart.demographic"
                                :year="chart.school_year_id"
                                :date="chart.date"
                                collection
                                ready
                            />
                        </v-lazy>
                    </template>

                    <template v-else>
                        <chart
                            class="mr-2"
                            :config="chart"
                            :downloadable="false"
                            :printable="false"
                            collectionChart
                        />
                    </template>
                </v-flex>
            </template>
        </v-layout>

        <v-layout class="empty-state" v-else align-center justify-center column fill-height text-center>
            <fe-empty-state
                image="/images/rocket_transparent.png"
                text="You have not pinned any Charts yet. <br />
                To get started, navigate to any Chart and click the Pin Icon."
            />
        </v-layout>

        <fe-dialog
            v-if="renameDialog.show"
            title="Rename Chart"
            acceptButtonText="Apply"
            dismissButtonText="Cancel"
            persistent
            @accept="renamePin()"
            @dismiss="renameDialog.show = false"
        >
            <v-layout row>
                <div class="d-flex align-center">
                    <span>Rename:</span>
                    <fe-text-field v-model="renameDialog.name" class="ml-2" autofocus/>
                </div>
            </v-layout>
        </fe-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import Highcharts from 'highcharts'
import BigFive from "../../incidents/BigFive";
import StackedChart from "../../../charts/StackedChart";
import BigFiveChart from "../../../charts/BigFive";
import AttendanceChart from "../../../charts/AttendanceChart";
import AssessmentTrendline from "../../../charts/AssessmentTrendline";
import AcademicProgressMonitoring from "../../../charts/AcademicProgressMonitoring";
import AcademicProgressMonitoring2 from "../../../charts/AcademicProgressMonitoring2";
import BehaviorProgressMonitoring from "../../../charts/BehaviorProgressMonitoring";
import draggable from 'vuedraggable';
import exports from 'highcharts/modules/exporting';
import Chart from "../../../charts/Chart";
import IncidentRangeCharts from "../../incidents/IncidentRange";
import BenchmarkChart from '@/components/charts/BenchmarkChart'
import MenuBtn from '@/components/common/button/MenuBtn'
import ChartPanel from '@/components/charts/ChartPanel'
import EnrollmentChart from "@/components/charts/EnrollmentChart";

exports(Highcharts)

export default {
    name: 'CollectionCharts',

    components: {
        IncidentRangeCharts,
        Chart,
        AcademicProgressMonitoring2,
        AcademicProgressMonitoring,
        BehaviorProgressMonitoring,
        AssessmentTrendline,
        AttendanceChart,
        BigFiveChart,
        StackedChart,
        BigFive,
        BenchmarkChart,
        MenuBtn,
        ChartPanel,
        EnrollmentChart,
        draggable
    },

    props: {
        reload: {type: Boolean, default: false}
    },

    data() {
        return {
            loading: false,
            activeItem: {name: 'Charts'},
            collectionData: [],
            collectionManageSelection: null,
            overallResults: [],
            categories: [],
            currentCategory: {},
            totalCharts: [],
            emptyCharts: false,
            collectionUsers: [],
            charts: [],
            localCollectionSearches: [],
            dashboard_id: null,
            noDataChart: null,
            loadedCategories: [],
            collectionChartData: {},
            allCollections: [],
            performanceBands: [],
            isFetchingPerformanceBands: false,
            renameDialog: {
                show: false,
                existing: '',
                name: ''
            }
        }
    },

    computed: {
        enrollmentBaseConfig() {
            return {
                chart: {
                    type: 'bar'
                },
                credits: {
                    enabled: false
                },
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                title: {
                    enabled: false,
                    text: '',
                },
                exporting: {
                    filename: '',
                    csv: {
                        columnHeaderFormatter: function (item, key) {
                            if (item.isXAxis) {
                                return item.options.title
                            } else {
                                return item.name
                            }
                        }
                    },
                },
                xAxis: {
                    categories: [],
                    title: {
                        text: undefined
                    },
                },
                yAxis: {
                    title: {
                        text: '# of Students Enrolled'
                    },
                    labels: {
                        formatter: x => new Intl.NumberFormat().format(x.value)
                    }
                },
                legend: {
                    enabled: false,
                    align: 'center'
                },
                plotOptions: {
                    line: {
                        marker: {
                            enabled: false
                        }
                    },
                    bar: {
                        maxPointWidth: 50,
                        dataLabels: {
                            enabled: false
                        }
                    },
                    column: {
                        maxPointWidth: 125,
                        dataLabels: {
                            enabled: false,
                            inside: true,
                            style: {
                                textOutline: 0,
                                fontWeight: 600,
                                fontSize: 18
                            }
                        }
                    }
                },
                series: []
            }
        }
    },

    watch: {
        collectionData(v) {
            this.emptyCharts = false
            this.localCollectionSearches = []
            if (v && v.length > 0) {
                v.forEach(ss => {
                    if (ss.dashboard_saved_search_type_id !== 6) {
                        this.localCollectionSearches.push(ss)
                    }
                })
            }
            this.categories = []
            this.totalCharts = []
            if (v && this.localCollectionSearches.length > 0) {
                if (this.localCollectionSearches.length > 0) {
                    this.localCollectionSearches.forEach((lcs, index) => {
                        this.totalCharts.push({})
                        if (this.reload) {
                            this.visibilityChanged(true, null, index)
                        }
                    })
                }
            } else {
                this.emptyCharts = true
            }

            if (this.reload) {
                this.$emit('reloaded', false)
            }
        },

        $route() {
            this.onLoad()
        },

        reload(v) {
            if (v) {
                this.onLoad()
            }
        },

        reorderRowData(v) {
            this.$emit('reorder-row-data', v)
        },
    },

    mounted() {
        this.onLoad()
        this.fetchPerformanceBands()
    },

    methods: {
        async fetchPerformanceBands(isResync = false) {
            // If this is to resync missing PBs, then there might be a ton of
            // charts with the same problem (e.g. 29 charts on district search).
            // As long as one of their emits gets processed, we should hope/expect
            // all of them will be brought up-to-date.
            if (isResync && this.isFetchingPerformanceBands) {
                return
            } else if (isResync) {
                console.warn('Performance Band not found, possibly out of sync; requesting new bands data...')
            }

            this.isFetchingPerformanceBands = true
            this.performanceBands = await this.$modelGet('userTargetSet')
            this.isFetchingPerformanceBands = false
        },

        attendanceClick(chart, key, rec) {
            let p = {
                ...chart.config.params,
                ...rec.params
            }
            delete p.chart_type
            delete p.saved_search_id

            this.$store.commit('global/addDockableWindow', {
                name: chart.name,
                component: 'attendance-reporting',
                attrs: {
                    params: p
                }
            })
        },

        bigFiveClick(chart, key, rec, evt, modParams) {
            let p = {...chart.chartParams}
            p[chart.config.param_key] = rec[chart.config.param_key]
            p.student_id = rec.student_id || chart.chartParams.student_id
            p.school_year_id = rec.school_year_id || modParams.school_year_id
            if (modParams.range) p.range = modParams.range
            if (modParams.start_date) p.start_date = modParams.start_date
            if (modParams.end_date) p.end_date = modParams.end_date
            delete p.saved_search_id
            delete p.chart_types

            this.$store.commit('global/addDockableWindow', {
                name: 'Incident Drilldown',
                component: 'incident-overview',
                attrs: {
                    name: rec[key],
                    params: p,
                    allCharts: true,
                    fixedTools: false,
                    breadcrumbs: true
                }
            })
        },

        onLoad() {
            this.collectionData = []
            this.totalCharts = []
            this.loadedCategories = []
            if (this.$route.params.id) {
                this.dashboard_id = parseInt(this.$route.params.id.split(':')[1])
                this.$axios.get('dashboard.php?action=get&property=saved_search&dashboard_id=' + this.dashboard_id)
                    .then(response => {
                        this.collectionData = response.data.saved_searches
                    })
            }
        },

        collectionChart(ss, index) {
            this.collectionChartData = {}

            switch (ss.dashboard_saved_search_type_id) {
                case 1:
                    this.buildStackedChart(ss, index)
                    break;
                case 2:
                    Vue.set(this.totalCharts, index, {search_id: ss, index: index, chartType: 'studentScore'})
                    break;
                case 3:
                    this.buildBig5(ss, index)
                    break;
                case 4:
                    Vue.set(this.totalCharts, index, {
                        name: ss.title,
                        search_id: ss,
                        index: index,
                        chartType: 'academicMonitor'
                    })
                    break;
                case 5:
                    Vue.set(this.totalCharts, index, {
                        name: ss.title,
                        search_id: ss,
                        index: index,
                        chartType: 'behaviorMonitor'
                    })
                    break;
                case 7:
                case 8:
                    this.buildAttendance(ss, index)
                    break;
                case 9:
                case 10:
                    this.buildIncidentRange(ss, index)
                    break;
                case 11:
                    this.buildLongitudinal(ss, index)
                    break;
                case 12:
                case 13:
                    this.buildEnrollment(ss, index)
                    break;
            }
        },

        removePinnedChart(savedSearchId) {
            let me = this

            this.$confirmDelete([1], () => {
                let params = {
                    dashboard_id: me.dashboard_id,
                    saved_search_id: savedSearchId
                }
                me.$axios.post('dashboard.php?action=delete&property=saved_search', {dashboard_saved_search: params})
                    .then(response => {
                        if (response.data.success) {
                            me.$snackbars.$emit('new', {text: response.data.msg, usage: 'success'})
                            me.$store.commit('collection/set', {
                                key: 'reloadCollection',
                                value: true
                            })
                        } else {
                            me.$snackbars.$emit('new', {text: response.data.msg, usage: 'warning'})
                        }
                    })
            }, null, 'Are you sure you want to remove this pinned chart?')

        },

        doExpand(chart) {
            let cfg = this.$_.cloneDeep(chart.config.options)
            cfg.chart.height = window.innerHeight - 150

            this.$dockableWindow({
                name: cfg.title.text,
                component: 'highcharts',
                attrs: {
                    options: cfg,
                    class: 'ma-5',
                }
            })
        },

        doRename(existing) {
            this.renameDialog.name = existing.name
            this.renameDialog.existing = existing
            this.renameDialog.show = true
        },

        renamePin() {
            let newName = this.renameDialog.name
            let oldName = this.renameDialog.existing.config.options.title.text
            if (!this.$_.isEmpty(newName) && newName !== oldName) {
                let params = {
                    dashboard_id: this.dashboard_id,
                    saved_search_id: this.renameDialog.existing.search_id,
                    rank: this.renameDialog.existing.index,
                    title: newName
                }

                let url = 'dashboard.php?action=update&property=saved_search'

                this.$axios.post(url, {saved_searches: [params]}).then(response => {
                    if (response.data.success) {
                        this.$snackbars.$emit('new', {text: response.data.msg, usage: 'success'})
                        this.$store.commit('collection/set', {
                            key: 'reloadCollection',
                            value: true
                        })
                    } else {
                        this.$snackbars.$emit('new', {text: response.data.msg, usage: 'warning'})
                    }
                })
            }
            this.renameDialog.show = false
        },

        buildAttendance(ss, index) {
            let type = ss.dashboard_saved_search_type_id === 7 ? 'charts' : 'period_charts'

            this.overallResults = []
            this.categories = []

            this.$axios.get('attendance.php?action=get&property=' + type + '&saved_search_id=' + ss.saved_search_id)
                .then(response => {
                    response.data.charts.forEach((chart) => {
                        let chartParams = chart.data.params
                        Vue.set(this.totalCharts, index, {
                            name: chart.title,
                            config: chart,
                            chartParams: chartParams,
                            property: type,
                            chartType: 'attendance',
                            index: index,
                            search_id: ss
                        })
                    })
                })
        },

        buildStackedChart(ss, index) {
            this.overallResults = []

            this.$axios.get('targetScores.php?action=get&property=categories&saved_search_id=' + ss.saved_search_id)
                .then(response => {
                    this.categories = []
                    if (response.data.categories.length === 0) {
                        Vue.set(this.totalCharts, index, {
                            chartType: 'no data',
                            index: index,
                            search_id: ss,
                            saved_search_id: ss.saved_search_id,
                            dashboard_id: ss.dashboard_id,
                            chart: 'no-data',
                            savedSearchRecord: this.collectionData.find(r => r.saved_search_id === ss.saved_search_id)
                        })
                    } else {
                        response.data.categories.forEach(cat => {
                            this.categories.push({
                                id: cat.category_id,
                                name: cat.category_name,
                                title: ss.title,
                                isLoaded: false,
                                params: {saved_search_id: ss.saved_search_id},
                                overallResults: [],
                                assessments: [],
                                showDataWall: false,
                                index: index,
                                dash_id: ss.id,
                                savedSearchRecord: this.collectionData.find(r => r.saved_search_id === ss.saved_search_id)
                            })
                        })
                        if (this.categories.length > 0) {
                            this.loadCategory(this.categories[0], ss, index)
                        }
                    }
                })
        },

        buildIncidentRange(ss, index) {
            Vue.set(this.totalCharts, index, {
                name: ss.title,
                config: ss,
                type: ss.dashboard_saved_search_type_id === 10 ? 'detailed_pyramid' : 'pyramid',
                chartType: ss.dashboard_saved_search_type_id === 10 ? 'detailed_pyramid' : 'pyramid',
                index: index,
                search_id: ss
            })
        },

        buildBig5(ss, index) {
            this.$axios.get('behaviorIncident.php?action=get&property=big5&saved_search_id=' + ss.saved_search_id)
                .then(response => {
                    let chartParams = {
                        ...response.data.incidents.params,
                        chart_types: response.data.incidents.charts[0].chart_type
                    }
                    response.data.incidents.charts.forEach((chart) => {
                        let categories = chart.fields ? chart.fields : []
                        chart.title = ss.title
                        Vue.set(this.totalCharts, index, {
                            name: chart.title,
                            config: chart,
                            chartParams: chartParams,
                            categories: categories,
                            type: chart.chart_type.indexOf('pyramid') > -1 ? chart.chart_type : 'big5',
                            chartType: response.data.incidents.charts[0].chart_type,
                            index: index,
                            baseParams: chartParams,
                            search_id: ss,
                            restricted: response.data.incidents.restricted
                        })
                    })
                })
        },

        buildLongitudinal(ss, index) {
            let config = JSON.parse(ss.config_text)
            config.options.title = {
                text: ss.title,
                useHTML: true,
                align: "left",
                style: {
                    'color': '#7E8494',
                    'border-bottom': '1px solid #E0E1E6',
                    'display': 'block',
                    'width': '100%'
                }
            }
            Vue.set(this.totalCharts, index, {
                name: ss.title,
                config: config,
                chartType: 'longitudinal',
                index: index,
                search_id: ss.saved_search_id
            })
        },

        buildEnrollment(ss, index) {
            let type = ss.dashboard_saved_search_type_id === 12 ? 'ongoing' : 'trend'
            let model = type === 'ongoing' ? 'enrollmentCounts' : 'enrollmentTrends'
            let url = this.$models.getUrl(model, 'read')

            this.$axios.post(url, {saved_search_id: ss.saved_search_id})
                .then(response => {
                    let data = response.data[type === 'ongoing' ? 'enrollments' : 'enrollment_trend']
                    let categories

                    let group_by = data[0].group_by ? data[0].group_by : 'district'
                    let selected_date = data[0].selected_date

                    let chartData = {
                        show: group_by,
                        config: {}
                    }

                    let config = this.$_.cloneDeep(this.enrollmentBaseConfig)
                    config.exporting.filename = type === 'ongoing' ? 'Ongoing Enrollment' : 'Enrollment Trend'

                    let groupProp = 'name'
                    switch (group_by) {
                        case 'school':
                            chartData.name = "By School"
                            groupProp = 'school_name'
                            categories = this.$_.uniq(data.map(x => x.school_name))
                            break;
                        case 'grade':
                            chartData.name = "By Grade Level"
                            groupProp = 'grade_name'
                            categories = this.$_.uniq(data.map(x => x.grade_name))
                            break;
                        case 'demographic':
                            chartData.name = "By Demographic"
                            groupProp = 'demographic_value'
                            categories = this.$_.uniq(data.map(x => {
                                let name
                                if (x.ell_flag !== undefined) {
                                    name = x.ell_flag.toString()
                                } else {
                                    name = x.demographic_value
                                }
                                return name ? name : 'N/A'
                            }))
                            break;
                        case 'school_group':
                            chartData.name = "By School Group"
                            groupProp = 'school_group_name'
                            categories = this.$_.uniq(data.map(x => x.school_group_name))
                            break;
                        default:
                            chartData.name = "Overall"
                            categories = ['Overall']
                            if (type === 'ongoing') {
                                config.chart.type = 'column'
                                config.xAxis.title = ''
                            }
                    }

                    if (type === 'trend') {
                        config.chart.type = 'line'
                        config.xAxis.title = 'Dates'
                        config.legend.enabled = true
                        config.xAxis.categories = this.$_.uniq(data.map(x => x[groupProp] ? x[groupProp] : x.school_year_name))

                        if (group_by === 'district') {
                            config.series = [{
                                name: 'Overall',
                                data: data.map(x => x.enrolled_count)
                            }]
                        } else {
                            let series = {}
                            data.forEach(x => {
                                let name = x[groupProp];
                                let index = this.$_.indexOf(categories, x.name)
                                if (!series.hasOwnProperty(name)) series[name] = {data: [], name}

                                let seriesData = series[name].data;
                                if (seriesData.length < index) {
                                    for (let i = seriesData.length; i < index; i++) {
                                        seriesData.push(null)
                                    }
                                }
                                seriesData.push(x.enrolled_count)
                            })

                            config.series = Object.values(series)
                        }
                    } else {
                        let series = {
                            name: 'Enrolled Students',
                            data: data.map(x => x.student_count)
                        }

                        if (data.length > 15) {
                            config.xAxis.min = 0
                            config.xAxis.max = 15
                            config.xAxis.scrollbar = {
                                enabled: true
                            }
                        }

                        config.xAxis.categories = categories
                        config.series = [series]
                    }

                    chartData.config[type] = config

                    let value = {
                        chartType: 'enrollment',
                        title: ss.title,
                        type: type,
                        data: chartData,
                        index: index,
                        demographic: ss.demographic ?? '',
                        school_year_id: undefined,
                        date: selected_date
                    };

                    Vue.set(this.totalCharts, index, value)
                })
        },

        loadCategory(category, ss, index) {
            this.currentCategory = category
            category.params.category_id = category.id
            this.$axios.get('targetScores.php?action=get&' + this.$objectToParams(category.params))
                .then(response => {
                    category.overallResults = response.data.categories[0].stacked_charts
                    category.overallResults.forEach(assess => {
                        assess.title = category.title ? category.title : assess.sub_category_name
                        assess.search_id = ss
                    })
                    category.assessments = [...new Set(category.overallResults.map(item => item.data_point_type_name))]
                })
                .finally(() => {
                    this.$emit('updated-category', category)
                    let idx = this.loadedCategories.findIndex(c => c.index === index)
                    if (idx === -1) {
                        let ssRec = this.collectionData.find(r => r.saved_search_id === ss.saved_search_id)

                        Vue.set(this.totalCharts, index, {
                            id: category.category_id,
                            name: category.category_name,
                            title: ss.title,
                            isLoaded: false,
                            params: {saved_search_id: ss.saved_search_id},
                            overallResults: category.overallResults,
                            assessments: category.assessments,
                            showDataWall: false,
                            index: index,
                            dash_id: ss.id,
                            uiConfig: ssRec.config_text ? JSON.parse(ssRec.config_text) : {}
                        })
                        this.loadedCategories.push({index: index})
                    }
                })
        },

        getAllCharts(v) {
            if (v) {
                if (v.$refs.feChart) {
                    this.charts.push(v.$refs.feChart.chart)
                    this.$emit('all-charts', this.charts)
                }
            }
        },

        visibilityChanged(isVisible, entry, index) {
            if (isVisible) {
                this.collectionChart(this.localCollectionSearches[index], index)
            }
        },

        resize() {
            if (this.$refs.collectionChartRef) {
                this.$refs.collectionChartRef.forEach(chart => {
                    chart.disableTooltip()
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.ec-collection {
    &-left-layout {
        ::v-deep .menu-title-toolbar {
            display: none !important;
        }

        ::v-deep .nav-item-selected {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        ::v-deep {
            .flex-grow-1 {
                border-top: 1px solid #E0E1E6;
            }
        }
    }

    &-layout-main {
        height: calc(100vh - 200px);
        overflow: auto;
    }

    &-empty-state {
        justify-content: center;
        display: flex;
        text-align: center;
    }

    &-card {
        min-height: 100px;
        max-height: 100px;
        line-height: 0;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        min-width: 0;

        &-text {
            padding: 4px !important;
            text-align: center;

            &-text {
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 90px;
                width: 90px;
                white-space: nowrap;
                color: #fff !important;
                display: block;
                position: relative;
            }
        }
    }

    &-reorder-list {
        cursor: pointer;
        border: 1px solid #000;
        border-radius: 4px;
        margin-bottom: 2px;
        height: 35px;
        min-height: 35px !important;
        display: flex;
        align-items: center;
    }
}

.longitudinal-chart {
    .fe-chart {
        ::v-deep .highcharts-title {
            width: 100% !important;
            left: 0 !important;
            padding: 0 0 10px 16px !important;
            font-size: 14px !important;
        }

        ::v-deep * {
            font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
        }
    }

    .collection-tools {
        position: relative;
        top: -405px !important;
        float: right !important;
        z-index: 1 !important;
    }
}

::v-deep .empty-state img {
    height: 340px;
}
</style>
