<template>
    <div class="flex-column flex-fill pa-4" :class="!dashboard_id ? 'ec-collections-main' : ''">
        <fe-overlay
            :value="loading"
            loader
            text=""
        />

        <meeting-creation
            v-if="creating || created"
            @created="meetingCreated"
            @updated="meetingUpdated"
            :editing="created"
            @closed="afterCreate"
            @close="afterCreate"
            :dashboard_id="dashboard_id"
        />

        <meeting-attach
            v-if="attaching"
            @attach="attachMeeting"
            @close="attaching = false"
            :dashboard_id="dashboard_id"
        />

        <div class="d-inline-flex">
            <div style="font-size: 24px; padding-left: 12px;">Meetings</div>

            <v-spacer/>

            <div style="width: 300px">
                <v-autocomplete
                    v-model="searchMeeting"
                    :search-input.sync="searchText"
                    flat solo dense
                    placeholder="Search Meetings"
                    prepend-inner-icon="fas fa-search"
                    style="margin-top: 7px;"
                    :items="searchResults"
                    itemText="title"
                    itemValue="id"
                    return-object
                    clearable
                    :no-data-text="noSearchString ? '' : 'No data available'"
                    :hide-no-data="noSearchString"
                >
                    <template v-slot:item="data">
                        <v-list-item-content @click="$router.replace('/Meeting/'+data.item.id)">
                            <v-list-item-title>{{data.item.title}}</v-list-item-title>
                            <v-list-item-subtitle class="pl-4" style="font-size: 12px;">Owner: {{data.item.created_by_full_name}} Created: {{$dayjs(data.item.created).format('LLL')}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </div>
            <fe-btn v-if="dashboard_id" usage="secondary" @click="attaching = true">Attach</fe-btn>
            <fe-btn usage="primary" @click="created = null; creating = true">Create</fe-btn>
        </div>

        <v-tabs height="28px" v-model="activeTab" class="ec-collection-tabs" v-if="!dashboard_id">
            <v-tab class="small-tab small-tab-all">Schedule</v-tab>
            <v-tab class="small-tab small-tab-all">My Meetings</v-tab>
            <v-tab class="small-tab small-tab-all">All Meetings</v-tab>
        </v-tabs>

        <v-carousel
            v-if="!dashboard_id"
            class="mt-2"
            v-model="activeTab"
            height="100%"
            hide-delimiters light
            :show-arrows="false"
        >
            <v-carousel-item key="0" class="py-4">
                <div class="flex-fill flex-column px-4" style="overflow-y: scroll;">
                    <template v-for="(group, gi) in groups">

                        <div class="section-title" :key="`group-`+gi">{{group.title}}</div>

                        <div v-for="m in group.data" class="mt-5">
                            <div class="my-1" style="font-size: 12px;">{{m.time}}</div>
                            <div class="meeting-body">

                                <div class="d-flex" style="line-height: 48px;">
                                    <div class="flex-grow-1" style="cursor: pointer; font-size: 16px;" @click="loadMeeting(m)">{{m.title}}</div>
                                    <div class="flex-grow-0 meeting-user" style="width: 200px;">
                                        {{ m.rec.dashboard_meeting_users }}
                                        <v-tooltip bottom v-for="(u,ui) in m.meetingRec.dashboard_meeting_users.slice(0, 5)">
                                            <template v-slot:activator="{ on }">
                                                <v-avatar size=26 v-on="on">
                                                    <img v-if="u.photo_url" :src="u.photo_url" :alt="u.user_full_name">
                                                    <v-icon v-else style="font-size: 26px;">fas fa-user-circle</v-icon>
                                                </v-avatar>
                                            </template>
                                            {{u.user_full_name}}
                                        </v-tooltip>
                                        {{ m.meetingRec.dashboard_meeting_users.length > 5 ? '+'+(m.meetingRec.dashboard_meeting_users.length-5) : ''}}

                                    </div>
                                    <div class="flex-grow-0 text-right pr-5" style="width: 200px; color: #7E8494; font-size: 12px;">
                                        {{getSchedule(m)}}
                                        <fe-icon-btn :useIcon="m.expanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'" @click="m.expanded=!m.expanded"/>
                                    </div>
                                </div>

                                <div v-if="m.expanded" class="pa-4">{{m.meetingRec.description}}</div>
                            </div>
                        </div>

                        <div class="spacer" style="min-height: 50px;" />
                    </template>
                </div>
            </v-carousel-item>

            <v-carousel-item key="1">
                <card-view v-if="activeTab===1" ref="cardView1" :params="{user_id: sessionUser.user.id, active: 1}" @loading="showLoading"/>
            </v-carousel-item>

            <v-carousel-item key="2">
                <card-view v-if="activeTab===2" ref="cardView2" :params="{user_id: sessionUser.user.id, active: 0}" @loading="showLoading"/>
            </v-carousel-item>
        </v-carousel>

        <card-view ref="dashboardCard" v-if="dashboard_id" :params="{dashboard_id: dashboard_id}" />

        <fe-crud
            ref="crudAttach"
            :config="$models.meeting"
            @updated="$emit('attached')"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import lodash from 'lodash'
    import CardView from './CardView'
    import MeetingCreation from './creation'
    import MeetingAttach from './Attach'

    export default {
        name: 'MeetingsLanding',
        components: { CardView, MeetingCreation, MeetingAttach },
        computed: {
            ...mapState('global', ['currentYear', 'sessionUser'])
        },
        props:  {
            dashboard_id: { type: Number, default: null }
        },
        data() {
            return {
                attaching: false,
                creating: false,
                created: null,
                searchMeeting: false,
                searchText: null,
                searchResults: [],
                activeTab: 0,
                activeMeetings: [],
                inactiveMeetings: [],
                occurrences: [],
                todayMeetings: [],
                tomorrowMeetings: [],
                groups: {
                    today: {
                        title: 'Today',
                        data: []
                    },
                    tomorrow: {
                        title: 'Tomorrow',
                        data: []
                    }
                },
                noSearchString: true,
                loading: false,
            }
        },

        watch: {
            activeTab(v) {
                this.loading = true

                switch (v) {
                    case 0:
                        this.loadUpcoming()
                        break
                }
            },
            searchText: lodash.debounce(function(v) {
                if (v && v.length >= 3) {
                    this.noSearchString = false
                    this.doSearch(v)
                } else {
                    this.noSearchString = true
                }

            }, 500),
            $route(to, from) {
                this.loading = true
                if(!this.$props.dashboard_id) {
                    this.loadUpcoming()
                } else {
                    this.loading = false
                }
            }
        },

        mounted() {
            if(!this.$props.dashboard_id) {
                this.loadUpcoming()
            }
        },

        methods: {
            attachMeeting(rec) {
                rec.dashboard_id = this.dashboard_id
                this.$refs.crudAttach.update(rec).then(() => {
                    if (this.dashboard_id) this.$refs.dashboardCard.loadData()
                    else if (this.activeTab == 1) this.$refs.cardView1.loadData()
                    else if (this.activeTab == 2) this.$refs.cardView2.loadData()
                    else this.loadUpcoming()
                })
            },
            meetingCreated(rec) {
                this.created = rec
                this.creating = false
            },
            meetingUpdated(rec) {
                this.created = rec
            },
            afterCreate() {
                this.created = null
                this.creating = false
                this.loadUpcoming()
                if (this.dashboard_id) this.$refs.dashboardCard.loadData()
                else if (this.activeTab == 1) this.$refs.cardView1.loadData()
                else if (this.activeTab == 2) this.$refs.cardView2.loadData()
                this.$emit('created')
            },
            loadMeeting(rec) {
                this.$router.replace('/Meeting/'+rec.meetingRec.id)
            },
            doSearch(v) {
                this.$modelGet('userMeeting', { user_id: this.sessionUser.user.id, title: v })
                    .then(r => {
                        if(this.$props.dashboard_id) {
                            this.searchResults = r.filter(a => a.dashboard_id === this.$props.dashboard_id)
                        } else {
                            this.searchResults = r
                        }
                    })
            },
            getSchedule(occ) {
                if (occ.meetingRec.schedule.length > 0) {
                    let sched = occ.meetingRec.schedule[0]
                    if (sched.frequency_cd == 'NOSCHED') return 'Not recurring'

                    let r = 'Weekly on '

                    if (sched.week_days.length === 5) return r += ' Weekdays'
                    sched.week_days.split().forEach(v => {
                        switch (v) {
                            case 'M':
                                r += 'Mon '
                                break
                            case 'T':
                                r += 'Tue '
                                break
                            case 'W':
                                r += 'Wed '
                                break
                            case 'T':
                                r += 'Thu '
                                break
                            case 'F':
                                r += 'Fri '
                                break
                            case 'S':
                                r += 'Sat '
                                break
                            case 'U':
                                r += 'Sun '
                                break
                        }
                    })
                    return r
                } else {
                    return 'No Schedule'
                }
            },
            showLoading(e) {
                this.loading = e
            },
            loadUpcoming() {
                this.loading = true
                this.occurrences = []
                this.groups.today.data = []
                this.groups.tomorrow.data = []

                this.$modelGet('userMeeting', { user_id: this.sessionUser.user.id, active: 1})
                    .then(response => {
                        // Cannot pass empty value to meetingDate endpoint, or it will return meetings for all users.
                        let meetingIds = response.map(r => r.id)
                        if (meetingIds.length) {
                            this.$modelGet('meetingDate', { id: meetingIds }).then(dates => {
                                let todayDt = this.$dayjs().format('YYYY-MM-DD')
                                let tomorrowDt = this.$dayjs().add(1, 'd').format('YYYY-MM-DD')

                                dates.forEach(d => {
                                    d.occurrences.forEach(o => {
                                        let startTime = this.$dayjs('2022-01-01 ' + o.occurrence_start_time).format('h:mm A')
                                        let endTime   = o.occurrence_end_time ? this.$dayjs('2022-01-01 ' + o.occurrence_end_time).format('h:mm A') : 'Not provided'
                                        if (todayDt === o.occurrence) this.groups.today.data.push({
                                            title: d.title,
                                            time: startTime + ' - ' + endTime,
                                            meetingRec: d,
                                            rec: o,
                                            expanded: false
                                        })

                                        if (tomorrowDt === o.occurrence) this.groups.tomorrow.data.push({
                                            title: d.title,
                                            time: startTime + ' - ' + endTime,
                                            meetingRec: d,
                                            rec: o,
                                            expanded: false
                                        })
                                    })
                                })
                            })
                        }
                    }).finally(() => this.loading = false)
            }
        }
    }
</script>

<style lang="scss" scoped>
.ec-collections-main {
    background: #F5F6F8;;
}

.small-tab {
    text-transform: none;
    letter-spacing: normal;
}
.small-tab::before {
    border-radius: 4px;
}
.ec-collection {
    &-tabs {
        ::v-deep .v-item-group {
            background: #F5F6F8 !important;
            position: relative;
            flex-wrap: nowrap;
            white-space: nowrap;
            max-width: 100%;
            overflow: auto;
        }
        // ::v-deep .v-item-group {
        //     background: white !important;
        //     position: relative;
        //     flex-wrap: nowrap;
        //     white-space: nowrap;
        //     max-width: 100%;
        //     overflow: auto;
        // }
        ::v-deep .v-tabs-slider {
            border-radius: 1.5px;
            &-wrapper {
                height: 3px !important;
            }
        }
    }
}

.spacer {
    display: block;
    height: 48px;
}
.meeting-body {
    background: white;
    border-radius: 4px;
    padding-left: 16px;

    .meeting-user {
        ::v-deep .v-avatar {
            position: relative;
            left: -5px;
            margin-left: -8px;
            border: 2px solid #e6e6e6;
        }
        ::v-deep .v-icon {

        }
    }
}
</style>
