<template>
    <div class="flex-fill flex-column no-scroll" style="height: 400px;">
        <fe-crud-grid
            ref="grid"
            :autoload="false"
            :config="$models.studentGrades"
            :readParams="{ student_id: this.updateStudent.student_id }"
            :columnDefs="columnDefs"
            :extraTools="this.hasSecurity"
            :rowData="studentGrades"
            :showAddRowBtn="this.selections.length > 0 ? false : !this.hasSecurity ? false : true"
            :showEditbar="this.hasSecurity"
            @beginCreate="showAddDialog=true"
            @selectionChanged="setSelectedRows"
            @cellValueChanged="loadData"
            addRowBtnText="Add Grade"
            refreshOnCreated
            refreshOnUpdated
            disableInlineCreate
            displayDensity="medium"
            style="height:100%;"
        >
            <template v-slot:extra-tools>
                <fe-btn
                    useIcon="fa-exchange-alt"
                    usage="ghost"
                    whiteText
                    class="fe-grid-action-btn"
                    @click="$emit('reassignBtnClick', $refs.grid.$refs.crud)"
                >
                    Reassign
                </fe-btn>
            </template>
        </fe-crud-grid>

        <add-grade
            v-if="showAddDialog"
            @close="showAddDialog=false"
            :crud="$refs.grid.$refs.crud"
            :studentInfo="this.updateStudent"
            :schoolYearList="this.schoolYears"
        />
    </div>
</template>

<script>
    import Vuex, { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'
    import AddGrade from './AddGrade'

    export default {
        name: 'StudentGrades',

        components: {
            AddGrade
        },

        data() {
            return {
                showAddDialog: false,
                studentGrades: [],
                originalItems: null,
                gridApi: null,
            }
        },

        inject: ['localStore'],

        computed: {
            ...mapLocalState(['updateStudent','selections','schools','grades','schoolYears','hasSecurity']),

            valuesLoaded() {
                return !!this.schools && !!this.grades && !!this.updateStudent
            },

            columnDefs() {
                let me = this

                return !this.valuesLoaded ? [] : [
                    {
                        headerName: null,
                        headerCheckboxSelection: this.hasSecurity,
                        checkboxSelection: this.hasSecurity,
                        width: 70,
                        minWidth: 70,
                        hide: !this.hasSecurity,
                        colId: 'checkbox_column'
                    }, {
                        headerName: "Internal ID",
                        field: "student_id",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "School Year",
                        field: "school_year_id",
                        sortable: true,
                        editable: this.hasSecurity,
                        valueGetter: v => me.findLabel(me.schoolYears, v.data.school_year_id, 'id', 'name'),
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.school_year_id,
                                rowDataKey: "school_year_id",
                                mode: "edit",
                                items: me.schoolYears,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true
                            }
                        }
                    }, {
                        headerName: "Grade",
                        field: "grade_id",
                        colId: "editableGrade",
                        sortable: true,
                        editable: this.hasSecurity,
                        hide: this.$hasSecurity('VIEW_ALL_GRADES') ? false : true,
                        valueGetter: v => me.findLabel(me.grades, v.data.grade_id, 'id', 'name'),
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.grade_id,
                                rowDataKey: "grade_id",
                                mode: "edit",
                                items: me.grades,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true
                            }
                        }
                    }, {
                        headerName: "Grade",
                        field: "grade_desc",
                        colId: "uneditableGrade",
                        sortable: true,
                        editable: this.hasSecurity,
                        hide: this.$hasSecurity('VIEW_ALL_GRADES') ? true : false,
                    },{
                        headerName: "Upload ID",
                        field: "upload_id",
                        sortable: true,
                        editable: false
                    }
                ]
            }
        },

        mounted() {
            this.loadData()
        },

        methods: {
            loadData() {
                this.$refs.grid.$refs.crud.read()
                .then((response) => {
                    this.studentGrades = response.data.student_grades
                    this.originalItems = this.$_.cloneDeep(response.data.student_grades)
                })
            },

            findLabel(collection, value, valueProp, labelProp) {
                let lbl = ''
                if (collection && value) {
                    let sel = collection.find(x => x[valueProp] === value)
                    if (sel) lbl = sel[labelProp]
                }
                return lbl
            },

            setSelectedRows(meta) {
                this.$data.gridApi = meta.api
                this.selections = meta.api.getSelectedRows()
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
