
let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    message: {
        defaults: {
            endpoint: 'message.php',
            rootProperty: 'messages'
        },
        ...standardCrud
    }
}