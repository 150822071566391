import BaseService from '@/components/modules/thresholds/services/BaseService'
import BaseStore from '../Base'

export default Object.assign({}, BaseStore.default(), {
    namespaced: true,

    mutations: Object.assign({}, BaseStore.default().mutations, {
        set(state, params) {
            state.collection[params.idx] = params.obj
        }
    }),

    actions: Object.assign({}, BaseStore.default().actions, {
        afterRefresh({state, commit, rootGetters}) {
            if (!state.collection[0]) {
                commit('set', {
                    idx: 0,
                    obj: {
                        ethnicity: [],
                        gender: null,
                        grade_id: [],
                        intervention_level_id: [],
                        school_id: [],
                        ell_flag: null,
                        ell_level_id: null,
                        active_status: null,
                        active_date: null,
                        threshold_group_id: rootGetters['thresholds/group/selectedId']
                    }
                })
            }
        }
    }),

    getters: Object.assign({}, BaseStore.default().getters, {
        __config(state, getters, rootState, rootGetters) {
            let prop = 'saved_search'
            return {
                service: BaseService,
                opts: {type: 'thresholdGroupProps'},
                params: {property: prop, threshold_group_id: rootGetters['thresholds/group/selectedId']},
                prop: prop
            }
        }
    })
})
