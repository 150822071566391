import Api from '@/components/modules/thresholds/services/Api'

export default {
    namespaced: true,

    state: {
        ellLevels: null
    },

    mutations: {
        set(state, payload) {
            state[payload.state] = payload.value
        }
    },

    actions: {
        async load({commit, state}, payload) {
            if (!payload || !payload.ifEmpty || payload.ifEmpty && !state.ellLevels) {
                let response = await Api().get('crud.php?property=EllLevel&action=get')
                let levels = (response.data && response.data.ell_level) || []

                commit('set', {
                    state: 'ellLevels',
                    value: levels
                })

                return levels
            } else {
                return state.ellLevels
            }
        },

        async refresh({dispatch}, payload) {
            dispatch('load', payload)
        }
    },

    getters: {
        all: state => state.ellLevels,
        collection: state => state.ellLevels,
        success: state => !!state.ellLevels,
        clear: state => !state.ellLevels
    }
}
