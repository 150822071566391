<template>
    <div class="flex-fill flex-column">
        <div class="flex-shrink-1">
            <fe-title-select
                v-if="groups.length > 0 && selectedGroup"
                placeholder="Select an Assessment Group"
                :items="groups"
                v-model="selectedGroup"
            ></fe-title-select>

            <div class="ml-5 mt-2" v-if="!hasDefault">
                <v-icon color="error" class="mr-2">fas fa-exclamation-circle</v-icon>No default Performance Band
            </div>
        </div>
        <div class="flex-grow-1">
            <fe-action-list
                ref="lst"
                :showFiltering="false"
                show-search
                :items="displayItems"
                addButtonText="Add Band"
                @rowClick="$emit('selectGroupDetail', $event)"
                @addButtonClick="$emit('create')"
                chipKey="chips"
                hide-delete
                @beginEdit="showEditMenu"
                @cancelEdit="hideEditMenu"
                @selectionChanged="setSelections"
            >
                <template v-slot:beforeButtons>
                    <div class="d-flex">
                        <v-checkbox
                            :ripple="false"
                            v-model="showDisabled"
                            label="Show Disabled"
                            hide-details
                            :style="{ marginTop: '12px' }"
                        />

                        <v-spacer/>

                        <fe-btn @click="destroy" usage="tertiary" :disabled="selections.length == 0" v-if="showExtraTools">Delete</fe-btn>
                    </div>
                </template>
            </fe-action-list>

            <assessment-group-band-editor
                ref="bandEditor"
                :group="group"
                @create="insertItem('bands', $event)"
            />
        </div>
        <fe-crud ref="crudBands" :config="$models.targetSet" />
    </div>
</template>

<script>
    import AssessmentGroupBandEditor from './AssessmentGroupBandEditor'
    import { mapState } from 'vuex'

    export default {
        name: 'AssessmentGroupBands',
        props: {
            group: { type: Object, default: () => {}, required: true },
            groups: { type: Array, default: () => [] },
            bands: { type: Array, default: () => [], required: true },
            bandDescriptors: { type: Array, default: () => [], required: true }
        },
        components: {
            AssessmentGroupBandEditor
        },
        data: () => {
            return {
                selections: [],
                showExtraTools: false,
                // showDisabled: false,
            }
        },
        computed: {
            ...mapState('manage', ['manageBandsShowDisabled']),
            showDisabled: {
                get() {
                    return this.$store.getters["manage/getBandsDisabledState"];
                },
                set(newValue) {
                    return this.$store.dispatch("manage/setBandsDisabledState", newValue);
                },
            },
            selectedGroup: {
                get () { return this.group },
                set (v) { this.$emit('switchGroup', v) }
            },
            hasDefault() {
                let f = this.bands.filter(b => b.site_default)
                return f.length === 0 ? false : true
            },
            hasKpi() {
                let f = this.bands.filter(b => b.kpi)
                return f.length === 0 ? false : true
            },
            displayItems () {
                // Declare as a local var so that changes to data.showDisabled are reactive for this computed prop
                let showDisabled = this.showDisabled

                return this.bands.filter(band => {
                    return showDisabled || band.active
                }).map(band => {

                    band.chips = []

                    if (band.site_default) {
                        if (band.site_default_school_year_names?.length) {
                            if (band.site_default_school_year_names.length === 1) {
                                band.chips.push({ name: `Default (${band.site_default_school_year_names[0]})` })
                            } else {
                                band.chips.push({ name: `Default (${band.site_default_school_year_names[0]} ...+${band.site_default_school_year_names.length - 1})` })
                            }
                        } else {
                            // This is default for all school years
                            band.chips.push({ name: 'Default' })
                        }
                    }
                    if (band.kpi) band.chips.push({ name: 'KPI' })

                    if (!band.active) {
                        band.chips.push({ name: 'Disabled' })
                    }

                    return {
                        crumbName: band.name,
                        name: this.getFormattedName(band),
                        band: band,
                        show: "band-detail",
                        chips: band.chips ? band.chips : []
                    }
                })
            }
        },
        methods: {
            setSelections (items) {
                this.selections = items
            },
            showEditMenu() {
                this.selections = []
                this.showExtraTools = true
            },
            hideEditMenu() {
                this.selections = []
                this.showExtraTools = false
            },
            getFormattedName(band) {
                let descs = this.getBandDescriptors(band)
                return `${band.name} <span style="margin-left: 20px; font-size: 12px;">${descs.length}</span>`
            },
            getBandDescriptors(band) {
                return this.bandDescriptors.filter(desc => desc.target_set_id == band.id)
            },
            destroy () {
                let bands = this.selections.map(itm => itm.band)
                this.$confirmDelete(bands, () => {
                    this.$refs.crudBands.destroy(bands).then(response => {
                        this.$emit('fetchBand')
                        this.$emit('reloadCurrent')
                        this.$refs.lst.edit(false)
                    }).catch(err => {
                        console.warn({ err: err })
                    })
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
