<template>
    <fe-dialog
        :title="title"
        acceptButtonText="Save"
        dismissButtonText="Cancel"
        width="350px"
        :forceClose="false"
        :acceptButtonDisabled="!valid"
        refreshOnUpdated
        refreshOnCreated
        dismissable
        disableAutoclose
        persistent
        @accept="save"
        @dismiss="dismiss"
    >
        <v-form ref="form" v-model="valid">
            <fe-remote-combo
                label="Demographic Name"
                v-model="newRec.demographic"
                :items="activeDemographics"
                placeholder="Select Demographic"
                :rules="$fieldValidators('select', null, { required: true })"
            />

            <fe-label>Value</fe-label>
            <v-text-field v-if="!newRec.demographic" disabled flat solo dense/>

            <fe-remote-combo
                v-if="newRec.demographic && newRec.demographic.value_type === 'option'"
                v-model="newRec.demographic_option"
                :items="newRec.demographic.options"
                :itemText="displayName"
                placeholder="Select an option"
                :rules="$fieldValidators('select', null, { required: true })"
            />

            <fe-date-picker
                v-if="newRec.demographic && newRec.demographic.value_type === 'date'"
                v-model="newRec.date_value"
                placeholder="Select a date"
                :rules="$fieldValidators('text', null, { required: true })"
            />

            <fe-remote-combo
                v-if="newRec.demographic && newRec.demographic.value_type === 'boolean'"
                v-model="newRec.boolean_value"
                placeholder="Select an option"
                :items="[{
                    name: 'Yes',
                    id: '1'
                }, {
                    name: 'No',
                    id: '0'
                }]"
                :rules="$fieldValidators('select', null, { required: true })"
                byId
            />

            <v-text-field
                v-if="newRec.demographic && newRec.demographic.value_type === 'string'"
                v-model="newRec.string_value"
                placeholder="Enter text"
                :rules="$fieldValidators('text', null, { required: true })"
                flat solo dense
            />

            <v-text-field
                v-if="newRec.demographic && newRec.demographic.value_type === 'number'"
                v-model="newRec.number_value"
                placeholder="Enter a number"
                type="number"
                :rules="$fieldValidators('number', null, { required: true })"
                flat solo dense
            />

            <fe-remote-combo
                label="School Year"
                v-model="newRec.school_year_id"
                :items="schoolYears"
                :disabled="allYears"
                :placeholder="allYears ? '' :'Select School Year'"
                :rules="$fieldValidators('select', null, { required: !allYears })"
                byId
            />

            <fe-date-picker
                label="Start Date (Optional)"
                v-model="newRec.start_date"
            />

            <fe-date-picker
                label="End Date (Optional)"
                v-model="newRec.end_date"
            />
        </v-form>
    </fe-dialog>
</template>

<script>
export default {
    name: "AddDemographic",

    props: {
        studentInfo: null,
        schoolYears: null,
        demographics: null,
        editing: null
    },

    data() {
        return {
            valid: false,
            newRec: {
                demographic: undefined,
                demographic_option: undefined,
                date_value: undefined,
                boolean_value: undefined,
                string_value: undefined,
                number_value: undefined,
                school_year_id: undefined,
                start_date: undefined,
                end_date: undefined
            }
        }
    },

    computed: {
        title() {
            return this.editing ? 'Edit Demographic' : 'Add Demographic'
        },

        allYears() {
            return !this.newRec.demographic || !!parseInt(this.newRec.demographic.all_years)
        },

        activeDemographics() {
            return this.demographics
                ? this.demographics.filter(dmo => dmo.can_search)
                : []
        }
    },

    mounted() {
        if (this.editing) {
            let demographic = this.demographics.filter(y => y.id === this.editing.demographic_id)[0]

            Object.keys(this.newRec).forEach(x => {
                switch (x) {
                    case 'demographic':
                        this.newRec.demographic = demographic
                        break
                    case 'demographic_option':
                        if (this.editing.demographic_option_id) {
                            this.newRec.demographic_option = demographic.options.filter(y => y.id === this.editing.demographic_option_id)[0]
                        }
                        break
                    case 'number_value':
                        if (!isNaN(parseFloat(this.editing.number_value))) {
                            this.newRec.number_value = parseFloat(this.editing.number_value)
                        } else {
                            this.newRec.number_value = undefined
                        }
                        break
                    case 'boolean_value':
                        this.newRec.boolean_value = this.editing.boolean_value?.toString() || undefined
                        break
                    default:
                        if (this.editing[x]) {
                            this.newRec[x] = this.editing[x]
                        }
                }
            })

            this.newRec.id = this.editing.id
        }
    },

    methods: {
        save() {
            let data = this.$_.cloneDeep(this.newRec)
            data.demographic_id = data.demographic.id
            data.demographic_option_id = data.demographic_option?.id
            delete data.demographic
            delete data.demographic_option
            let emit = data.id ? 'update' : 'create'
            this.$emit(emit, data)
        },

        dismiss() {
            if (this.$_.isEmpty(Object.values(this.newRec).filter(x => !!x))) {
                this.$emit('close')
            } else {
                this.$confirmCancel(() => {
                    this.$emit('close')
                })
            }
        },

        displayName(item) {
            // if option has a display name, use it, otherwise use the value
            if (!item.display_name_group) {
                return item.display_name ? item.display_name : item.value
            } else {
                return item.value + (item.display_name ? " (" + item.display_name + ")" : "")
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.spacer {
    height: 62px;
    font-size: 13px;
    color: darkgrey;
}

.bold {
    font-weight: bold;
}
</style>
