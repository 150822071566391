<template>
    <div class="d-flex flex-column alt-card flex-fill panel" v-intersect="onShow">


        <div class="d-flex align-center pa-2 bborder flex-wrap">
            <span class="section-title ml-2">
                Inbox<span class="ml-2 secondary-text fs14">{{unreadCount}} unread</span>
            </span>


            <fe-btn v-if="!psportalEnabled" class="ml-auto" usage="tertiary" @click="newMessage()">New</fe-btn>

            <v-menu v-else offset-y max-height="60vh" content-class="new-menu-content">
                <template #activator="{ on }">
                    <fe-btn v-on="on" class="ml-auto" usage="tertiary">New</fe-btn>
                </template>
                <v-list dense>
                    <v-list-item @click="newMessage()">Users</v-list-item>
                    <v-list-item @click="newMessage(true)">Guardians</v-list-item>
                </v-list>
            </v-menu>


            <fe-text-field
                class="ma-2"
                ref="searchField"
                placeholder="Search messages"
                prependIcon="fa-search"
                width="100%"
                @change="filterText=$event"
                hide-details autofocus clearable
            />
        </div>

        <div v-if="!$_.isBlank(groups)" class="d-flex justify-space-around bborder mt-2" >
            <template v-for="(data,group) in groups">
                <group-button :group="group" :meta="data" v-model="groupBy" :style="buttonStyle" />
            </template>
        </div>

        <div v-if="$_.isBlank(items)" class="flex-grow-1 bborder">
            <fe-overlay v-if="loading" loader text="" color="white" />
            <fe-overlay
                v-else
                class="overlay"
                :absolute="false"
                :icon="groups[groupBy].icon"
                header="No Messages"
                text=""
                color="white"
            />
        </div>

        <div v-if="items && items.length" class="flex-grow-1 bborder" style="overflow:auto;" ref="scrollContainer">

            <div v-for="item in items" :key="item.id" class="pa-3 bborder item" @click="onClick(item)">
                <div class="d-flex align-center">
                    <div class="dot bcolor fs24"><span v-if="item.thread_unread">•</span></div>
                    <div class="">
                        <v-avatar size="22">
                            <!-- v-if="item.message_type_id==12" -->
                            <img
                                :src="counterpart(item).photo_url"
                            >
                            <!-- <img v-if="item.photo_url" :src="item.photo_url" :alt="item.created_by_full_name" /> -->
                            <!-- <v-icon v-else class="fs24">fas {{item.glyph}}</v-icon> -->
                        </v-avatar>
                    </div>
                    <div class="d-inline-flex counterpart pl-2">
                        {{counterpart(item).name}}
                    </div>
                    <div v-if="item.thread_unread" class="d-inline-flex flex-shrink-0 pl-2 bcolor">
                        ({{item.thread_unread}})
                    </div>
                    <div class="ml-auto secondary-text fs12 flex-shrink-0 pl-2">
                        {{item.formattedCreated}}
                    </div>
                </div>
                <div class="d-flex">
                    <!-- <div class="preview py-1" v-html="itemText(item)" /> -->
                    <div class="preview py-1" v-html="item.value" />
                    <v-btn class="ml-auto mt-auto ma-0 trash" icon x-small @click.stop="deleteThread(item)">
                        <v-icon small>far fa-trash</v-icon>
                    </v-btn>
                </div>
            </div>

        </div>

        <div class="d-flex align action pr-4 bcolor">
            <div class="d-flex link ml-auto align-self-center" @click="markAllRead">mark all as read</div>
        </div>

        <fe-crud
            ref="crud"
            :readParams="{inbox:1}"
            :config="$models.message"
            :autoload="false"
            @read="readCrud"
        />

        <direct-thread :rootMessage="rootMessage" @close="rootMessage=null" @reload="loadMessages" />

        <fe-dialog
            v-if="compose"
            title="Messages"
            width="600"
            @close="compose=null"
            :footer="false"
            :retain-focus="false"
        >
            <direct-compose :toGuardian="compose.toGuardian" @created="onMessageCreated" @cancel="compose=null"/>
        </fe-dialog>

    </div>

</template>

<script>
import DirectThread from './DirectThread'
import DirectCompose from './DirectCompose'
import GroupButton from './GroupButton'
import lodash from 'lodash'

export default {
    name: 'InboxDropdown',

    components: {
        DirectThread,
        DirectCompose,
        GroupButton,
    },

    props: {
        pollData: {},
    },
    computed: {
        buttonStyle: me => ({ width: 80 / Object.keys(me.groups).length+'%' }),
        psportalEnabled: me => me.$store.state.global.shareableStores.district.psportal_enabled == 1,
        unreadCount: me => me.groups.ALL.unread,
        groups: me => Object.keys(me.unreadCounts).reduce((acc,key) => {
            if(acc[key]) acc[key].unread = me.unreadCounts[key]
            acc.ALL.unread += me.unreadCounts[key]
            return acc
        },{
            ALL: {unread:0, icon:'far fa-inbox',name:'All', color:'#FFE072'},
            DIRECT: {unread:0, icon:'far fa-comments-alt',name:'DMs', color:'#19ADAB'},
            MEETING: {unread:0, icon:'far fa-calendar', stack:'fas fa-user', name:'Meetings', color:'#AFAAF9'},
            ACTION_ITEM: {unread:0, icon:'far fa-tasks',name:'Action Items', color:'#89B7F4'},
            // INTERVENTION: {unread:0, icon:'far fa-hands-heart',name:'Interventions', color:'#FFE072'},
            // SYSTEM_REPORT: {unread:0, icon:'far fa-cog',name:'Batch Jobs', color:'#FFAE93'},
        }),
        items() {
            let msgs = this.filterText
                ? this.messages.filter(x => {
                    return x.counterpart_name.toLowerCase().includes(this.filterText.toLowerCase())
                        || x.formattedCreated.toLowerCase().includes(this.filterText.toLowerCase())
                        || x.value.toLowerCase().includes(this.filterText.toLowerCase())
                })
                : this.messages

            if(this.groupBy != 'ALL') msgs = msgs.filter( x => x.message_group == this.groupBy)

            return msgs.sort((a,b) => b.id - a.id)
        }
    },
    watch: {
        pollData(v) {
            if(this.lastUnreadCount !== undefined && this.lastUnreadCount != v.unreadCount) {
                this.loadMessages()
            }
            this.lastUnreadCount = v.unreadCount
        },
    },
    methods: {
        // itemText(item) {
        //     let author = item.created_by_full_name != item.counterpart_name
        //         ? item.created_by_full_name
        //         : ''
        //     return author ? `<div style="opacity:.5;">${author}: </div>${item.value}` : item.value
        // },
        loadCounts() {
            this.$axios.get('message.php?action=unread_count').then(response => {
                this.unreadCounts = response.data.message_inbox_unread
                this.$emit('unreadCount',this.unreadCount)
            }).catch(error => {
                console.error(error)
            })
        },
        loadMessages() {
            this.loadCounts()
            this.loading = true
            this.$refs.crud.read()
        },
        counterpart(item) {
            const defaultImg = '/images/default-student-img.png'
            return item.counterpart_name
                ? {
                    // name: item.counterpart_name + (item.all_counterpart_names && '+' || ''),
                    name: item.counterpart_name,
                    photo_url: item.counterpart_photo_url || defaultImg,
                } : {
                    name: item.created_by_full_name,
                    photo_url: item.photo_url || defaultImg,
                }
        },
        onShow(e) {
            if(e[0].isIntersecting) {
                this.filterText = null
                this.$refs.searchField.text = null
                this.$refs.scrollContainer?.scrollTo(0,0)
                this.loadMessages()
            }
        },
        readCrud(e) {
            this.messages = e.map(x => ({...x, formattedCreated:this.$dayjs(x.created).format('MMM D, YYYY')}))
            this.lastUnreadCount = this.unreadCount
            this.loading = false
        },
        onClick(item) {
            item.thread_unread = 0
            this.rootMessage = item
            setTimeout(this.loadCounts,600)
        },
        showNotice(item) {
            this.$messageBox({
                title: 'Notice',
                persistent: false,
                maxWidth: 400,
                message: item.value,
                actions: [{
                    text: 'Ok',
                }]
            })
        },
        newMessage(toGuardian=false) {
            this.compose = {
                toGuardian
            }
        },
        onMessageCreated(e) {
            this.compose = false
        },
        markAllRead() {
            this.$messageBox({
                title: 'Confirm',
                persistent: true,
                maxWidth: 400,
                message: 'Mark all messages read?',
                actions: [{
                    text: 'Cancel'
                }, {
                    text: 'Mark All Read',
                    primart: true,
                    onClick: () => {
                        this.$axios.get('message.php?action=mark_all_read').then(response => {
                            this.$ecResponse(response)
                            this.$emit('unreadCount',0)
                            this.messages = this.messages.map(x => {
                                x.thread_unread = 0
                                return x
                            })
                        }).catch(error => {
                            console.error(error)
                        })
                    }
                }]
            })
        },
        deleteThread(item) {
            this.$messageBox({
                title: 'Confirm',
                persistent: true,
                maxWidth: 400,
                message: 'Delete the entire message thread?',
                actions: [{
                    text: 'Cancel'
                }, {
                    text: 'Delete',
                    primart: true,
                    onClick: () => {
                        this.$axios.get(`message.php?action=mark_thread_deleted&id=${item.root_message_id}`).then(response => {
                            this.$ecResponse(response)
                        }).catch(error => {
                            console.error(error)
                        })
                    }
                }]
            })
        },
    },
    data() {
        return {
            loading: true,
            rootMessage: null,
            filterText: null,
            groupBy: 'ALL',
            noBodyPadding: false,
            messages: [],
            compose: null,
            unreadCounts: {},
        }
    },
}
</script>

<style lang="scss" scoped>
.overlay ::v-deep {
    .v-icon {
        z-index: -1;
        font-size: 36px;
        color: var(--v-accent-darken2) !important;
    }
    .ec-overlay-header {
        padding-top: 16px;
        color: var(--v-accent-darken2);
    }
}
.panel {
    width: 350px;
    height: 600px;
    max-height: 90vh;
    background-color: white;
}
.item {
    &:hover {
        background-color: var(--v-primary-lighten2);
        & .trash {
            opacity: .3;
            &:hover {opacity: 1}
        }
    }
    & .trash {
        opacity: 0;
    }
}
.bborder {
    border-bottom: 1px solid #ECEDF1;
}
.dot {
    width: 26px;
}
.counterpart {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.preview {
    margin-left: 26px;
    height: 40px;
    color: black;
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.action {
    min-height: 38px;
}
.bcolor {
    color: var(--v-primary-base);
}
.link {
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
}
.new-menu-content {
    background-color: white;
    overflow: hidden;
}
</style>
