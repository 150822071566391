<template>
    <div class="flex-fill flex-column" style="overflow: hidden;">
        <div class="d-flex flex-wrap" v-if="showFilters">
            <fe-filter-btn
                title="Assessment Suite"
                :url="$models.getUrl('assessment', 'read')+'&hidden=0&property=assessments'"
                itemValue="id"
                itemText="name"
                v-model="localValue.data_point_type_id"
                :multiple="false"
                @input="loadDeps"
                ref="dptFilter"
            />

            <fe-filter-btn
                title="Assessment"
                :disabled="!subCategories.length"
                :items="subCategories"
                v-model="localValue.sub_category_id"
                :multiple="false"
                ref="assessmentFilter"
            />

            <fe-filter-btn
                title="Window"
                :disabled="!windows.length"
                :items="windows"
                v-model="localValue.data_point_name_id"
                multiple
                :closeOnSelect="false"
                ref="windowFilter"
            />

            <fe-filter-btn
                title="School Year"
                :items="schoolYears"
                v-model="localValue.school_year_id"
                showFirstSelection
                @input="loadSchools"
                multiple
                :closeOnSelect="false"
            />

            <fe-filter-btn
                title="Schools"
                :items="schools"
                :disabled="!schools.length"
                v-model="localValue.school_id"
                showFirstSelection
                @input="loadGrades"
                multiple
                :closeOnSelect="false"
                ref="schoolFilter"
            />

            <fe-filter-btn
                title="Grades"
                :items="grades"
                v-model="localValue.grade_id"
                showFirstSelection
                :disabled="!grades.length"
                multiple
                :closeOnSelect="false"
            />

            <fe-btn usage="secondary" @click="showAdvanced=true">Filters</fe-btn>

            <fe-dialog
                title="More Filters"
                v-if="showAdvanced"
                @accept="()=>{}"
                @close="showAdvanced=false"
                persistent
                dismissButtonText="Cancel"
                acceptButtonText="Apply"
                width="640"
            >
                <advanced-filters ref="advancedFilters" :showGroupBy="false" :isProgEval="true" :resetEntry="false" v-model="advanced"/>
            </fe-dialog>


            <fe-btn usage="primary" :disabled="!canSearch" @click="doSearch">Search</fe-btn>
        </div>

        <div class="flex-column flex-fill" style="overflow-y: scroll;" v-if="showCharts">
            <template v-for="(cfg, i) in chartConfigs">
                <div v-if="!cfg" class="loader">
                    <fe-spinner/>
                </div>

                <benchmark-chart
                    v-else
                    class="ma-4"
                    defaultStackType="percent"
                    :config='cfg[0]'
                    type='basic'
                    :key="`basic-chart-` + i"
                    :showSort="true"
                    :performanceBands="performanceBands"
                    @bandNotFound="fetchPerformanceBands(true)"
                />
            </template>
        </div>
    </div>
</template>

<script>
    import { Vuex, mapState } from 'vuex'
    import AdvancedFilters from '@/components/common/AdvancedFilters'
    import BenchmarkChart from '@/components/charts/BenchmarkChart'
    import { mapStoreState } from '@/util/vuexHelper'
    import advancedFiltersMixin from '@/mixins/advancedFilters'

    export default {
        name: 'ProgramEvaluation',
        components: {
            AdvancedFilters,
            BenchmarkChart
        },
        mixins: [advancedFiltersMixin],
        props: {
            showFilters: {
                default: true,
                type: Boolean
            },
            params: {
                default: () => {
                    return {
                        data_point_type_id: {
                            included: []
                        },
                        sub_category_id: {
                            included: []
                        },
                        data_point_name_id: {
                            included: []
                        }
                    }
                }
            },
            searchParams: {
                // used for parameters passed in directly without filters applied
                default: () => null
            }
        },
        watch: {
            'localValue.school_year_id'() {
                // If user has a default school, it will be preselected on pageload
                // so selecting a year(s) should also load in grades items immediately
                if (this.localValue.school_id?.included.length > 0) {
                    this.loadGrades()
                }
            },
        },
        computed: {
            ...mapState('global', ['defaultSchool']),
            chartConfigs() {
                return [this.overallResults, this.schoolResults, this.gradeResults, this.genderResults, this.ethnicityResults]
            },
            canSearch() {
                let valid = true
                this.chartConfigs.forEach(c => {
                    if (!c) valid = false
                })

                if (!valid) return false
                let lp = this.localValue
                if (lp.data_point_type_id?.included.length && lp.sub_category_id?.included.length && lp.school_year_id?.included.length) {
                    return true
                }
                return false
            }
        },
        data() {
            return {
                localStore: null,
                overallResults: [],
                schoolResults: [],
                gradeResults: [],
                genderResults: [],
                ethnicityResults: [],
                localValue: {},
                baseTitle: '',
                schoolYears: [],
                subCategories: [],
                windows: [],
                schools: [],
                grades: [],
                cohortSearch: false,
                showAdvanced: false,
                advanced: {
                    group_by: {
                        school:  true,
                        grade: false,
                        gender: true,
                        ethnicity: true
                    }
                },
                schoolGroups: true,
                showCharts: false,
                performanceBands: [],
                isFetchingPerformanceBands: false,
            }
        },
        created() {
            this.localValue = {...this.params}
            this.$modelGet('schoolYear').then(r=>this.schoolYears=r)

            if (this.searchParams) {
                this.doAltSearch(this.searchParams)
            }
        },
        mounted() {
            this.fetchPerformanceBands()

            if (this.defaultSchool) {
                this.$refs.schoolFilter?.selectItem(this.defaultSchool, true)
            }
        },
        methods: {
            async fetchPerformanceBands(isResync = false) {
                // If this is to resync missing PBs, then there might be a ton of
                // charts with the same problem (e.g. 29 charts on district search).
                // As long as one of their emits gets processed, we should hope/expect
                // all of them will be brought up-to-date.
                if (isResync && this.isFetchingPerformanceBands) {
                    return
                } else if (isResync) {
                    console.warn('Performance Band not found, possibly out of sync; requesting new bands data...')
                }

                this.isFetchingPerformanceBands = true
                this.performanceBands = await this.$modelGet('userTargetSet')
                this.isFetchingPerformanceBands = false
            },
            loadDeps() {
                this.$refs.assessmentFilter.clearSelections()
                this.$refs.windowFilter.clearSelections()

                let ids = this.localValue.data_point_type_id.included.map(r=>r.id).join(',')
                this.$modelGet('subcategory', { data_point_type_id: ids }).then(r=>this.subCategories=r)
                this.$modelGet('dataPointName', { data_point_type_id: ids }).then(r=>{
                    this.windows=r
                    this.windows.forEach(w=> {
                        w.name = w.alias?w.alias:w.name
                    })
                })
            },
            loadSchools() {
                let ids = this.localValue.school_year_id.included.map(r=>r.id).join(',')
                this.$modelGet('school', { school_year_id: ids }).then(r=>this.schools=r)
            },
            loadGrades() {
                if (this.localValue.school_year_id) {
                    let yearIds = this.localValue.school_year_id.included.map(r => r.id).join(',')
                    let ids = this.localValue.school_id.included.map(r => r.id).join(',')
                    this.$modelGet('grade', { school_year_id: yearIds, school_id: ids }).then(r => this.grades = r)
                }
            },
            applyAdvanced() {
                let p = this.$refs.advancedFilters.formatParams()
            },
            searchGrade(opts) {
                this.$axios.get('targetScores.php?action=get&group_by=grade&' + this.$objectToParams(opts))
                    .then((r) => {
                        let data = this.$ecResponse(r, 'categories')

                        this.gradeResults = data.length ? data[0].stacked_charts : [{
                            noData: true,
                            title: this.baseTitle + ' By Grade'
                        }]
                        this.searchGender(opts)
                    })
            },
            searchSchool(opts) {
                // By School
                this.$axios.get('targetScores.php?action=get&group_by=school&' + this.$objectToParams(opts))
                    .then((r) => {
                        let data = this.$ecResponse(r, 'categories')

                        this.schoolResults = data.length ? data[0].stacked_charts : [{
                            noData: true,
                            title: this.baseTitle + ' By School'
                        }]
                        this.searchGrade(opts)
                    })
            },
            searchEthnicity(opts) {
                this.$axios.get('targetScores.php?action=get&group_by=ethnicity&' + this.$objectToParams(opts))
                    .then((r) => {
                        let data = this.$ecResponse(r, 'categories')

                        this.ethnicityResults = data.length ? data[0].stacked_charts : [{
                            noData: true,
                            title: this.baseTitle + ' By Ethnicity'
                        }]
                    })
            },
            searchGender(opts) {
                // By Gender
                this.$axios.get('targetScores.php?action=get&group_by=gender&' + this.$objectToParams(opts))
                    .then((r) => {
                        let data = this.$ecResponse(r, 'categories')

                        this.genderResults = data.length ? data[0].stacked_charts : [{
                            noData: true,
                            title: this.baseTitle + ' By Gender'
                        }]
                        this.searchEthnicity(opts)
                    })
            },
            // This is passed in from the new analytics/charting area.
            doAltSearch(opts) {
                this.search(opts, 'Program Evaluation')
            },
            doSearch(selected) {
                let opts = this.getSearchObjectProgEval()
                this.search(opts,  this.localValue.sub_category_id.included[0].name)
            },
            search(opts, title) {
                this.overallResults = false
                this.schoolResults = false
                this.gradeResults = false
                this.genderResults = false
                this.ethnicityResults = false
                delete opts.group_by
                this.showCharts=true

                this.$axios.get('targetScores.php?action=get&' + this.$objectToParams(opts))
                    .then(r => {
                        let data = this.$ecResponse(r, 'categories')

                        this.overallResults = data.length ? data[0].stacked_charts : [{
                            noData: true,
                            title: this.title + ' Overall'
                        }]
                        this.searchSchool(opts)
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
.loader {
    height: 300px;
    text-align: center;
}
</style>
