<template>
    <form-common-config
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #fieldSpecific="{rec}">
            {{rec}}
        </template>
    </form-common-config>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'
import FormCommonConfig from '../FormCommonConfig'

export default {
    inject: ['localStore'],
    components: {
        FormCommonConfig,
    },
    computed: {
        ...mapLocalState([ 'reloadFn' ]),
        labelStyle: me => ({ minWidth:'120px' }),
    },
    methods: {
        // if saving config data that may version the field... like scorefield... make sure
        // to reload the form
    }
}
</script>

<style lang="scss" scoped>
</style>
