import MeetingsLanding from '@/components/modules/meetings'
import MeetingWorkspace from '@/components/modules/meetings/workspace'

export default [{
    path: '/Meetings',
    name: 'Meetings',
    component: MeetingsLanding
}, {
    path: '/Meeting/:id',
    props: true,
    name: 'MeetingWorkspace',
    component: MeetingWorkspace
}]