<template>
    <div class="flex-fill flex-column no-scroll">
        <div class="headline">Permission Groups</div>

        <fe-grid
            v-if="ready"
            style="height:100%"
            ref="grid"
            :columnDefs="columnDefs"
            :rowData="rowData"
            displayDensity="medium"
            disableInlineCreate
            addRowBtnText="Add Group"
            @beginCreate="editRec()"
            @cellValueChanged="updateRec($event.data,$event)"
        >
            <template #cellDialog="{cell}">
                <simple-grid-dialog-column v-if="cell.colDef.cellRendererFramework == 'FeDialogColumn' && cell.colDef.field != 'alias_cnt'"
                    :readOnly="dialogColumnDefs[cell.colDef.field].readOnly && cell.data.locked"
                    :cell="cell"
                    :title="cell.data.name"
                    :deleteTool="1"
                    :field="dialogColumnDefs[cell.colDef.field].column1.field"
                    :fieldHeader="dialogColumnDefs[cell.colDef.field].column1.headerName"
                    :columnDefs="dialogColumnDefs[cell.colDef.field].columnDefs && dialogColumnDefs[cell.colDef.field].columnDefs"
                    :crudConfig="dialogColumnDefs[cell.colDef.field].model"
                    :fieldConfig="dialogColumnDefs[cell.colDef.field].fieldConfig"
                    :buildInsertRec="dialogColumnDefs[cell.colDef.field].buildRec"
                    :width="dialogColumnDefs[cell.colDef.field].width"
                    :height="dialogColumnDefs[cell.colDef.field].height"
                    :insertDefaults="cell.data"
                    :readParams="{user_group_id:cell.data.id}"
                    @close="$refs.grid.openMenu = false"
                    @count="updateCellCount(cell,$event)"
                    :filter="v => guardianEmailDisplay || v.security_name !== 'Send smartFORMS to guardians'"
                />
                <simple-grid-dialog-column v-else-if="cell.colDef.field == 'alias_cnt'"
                    :cell="cell"
                    title="Aliases"
                    field="alias"
                    fieldHeader="Alias"
                    fieldPlaceholder="Add an Alias"
                    :crudConfig="$models.userGroupAlias"
                    :fieldCounter="255"
                    :insertDefaults="{user_group_id: cell.data.id}"
                    :readParams="{user_group_id: cell.data.id}"
                    :destroyParams="{user_group_id: cell.data.id}"
                    @close="$refs.grid.openMenu = false"
                    @count="updateCellCount(cell, $event)"
                />
                <v-list v-else>
                    <v-list-item @click="editRec(cell.data)">Edit Group</v-list-item>
                    <v-list-item @click="deleteRec(cell.data)">Delete Group</v-list-item>
                </v-list>
            </template>
        </fe-grid>

        <add-group v-if="showAddDialog"
            :value="groupRec"
            @create="createRec" @update="updateRec"
            @close="showAddDialog=false"
        />

        <fe-crud
            ref="crud"
            autoload
            :config="$models.userGroup"
            @read="postRead"
        />
    </div>
</template>

<script>
import SimpleGridDialogColumn from "@/components/common/SimpleGridDialogColumn"
import AddGroup from './dialogs/AddGroup'

export default {
    name: 'PermissionGroups',
    components: {
        SimpleGridDialogColumn,
        AddGroup,
    },
    computed: {
        guardianEmailDisplay() {
            return this.$store.getters['flags/flags']['ec-guardian-emails']
        },
    },
    methods: {
        init() {
            this.$refs.grid.gridOptions.deltaRowDataMode = true
            this.$refs.grid.gridOptions.getRowNodeId = v => {
                return v.id
            }
        },

        updateCellCount(cell,count) {
            cell.node.setDataValue(cell.colDef.field,count)
        },

        onCellClicked(v) {
            let cfg = v.column.colId == 'toolmenu' ? 'menu' : null
            this.$refs.grid.setDialogCell(v,cfg)
        },

        editRec(v) {
            this.groupRec = v
            this.showAddDialog = true
        },

        createRec(v) {
            this.$refs.crud.create(v).then( r => {
                this.$refs.crud.read().then( r => {
                    this.showAddDialog=false

                    // find last id added
                    let index=0, row=this.rowData[0]
                    this.rowData.forEach((v,i) => {
                        if(v.id > row.id) { row = v; index = i }
                    })

                    this.$nextTick(() => {
                        this.$refs.grid.gridApi.ensureIndexVisible(index)
                        this.$flashGridRow(this.$refs.grid,index,3000)
                    })
                })
                this.$emit('countChanged')
            })

        },

        updateRec(v,e) {
            if(e && e.colDef.field.endsWith('_cnt')) return
            this.showAddDialog = false
            this.$refs.crud.update(v).finally(() => {
                // this.rowData = this.rowData.map( x => x.id === v.id ? v : x)
                this.$refs.crud.read().finally(() => this.$refs.grid.gridApi.redrawRows())
                this.$emit('countChanged')
            })

        },
        deleteRec(v) {
            this.pendingDeleteId = v.id
            this.$refs.grid.gridApi.refreshCells({ force: true }) // refreshes cell style (to highlight as pending deletion)

            this.$messageBox({
                title: 'Delete Group',
                persistent: true,
                maxWidth: 400,
                message: `Are you sure you want to delete the Permission Group <strong>${v.name}</strong>?`,
                actions: [{
                    text: 'Cancel',
                    onClick: () => {
                        this.pendingDeleteId = null
                        this.$refs.grid.gridApi.refreshCells({ force: true }) // refreshes cell style (to remove highlight)
                    }
                }, {
                    text: 'Delete', usage: 'danger',
                    onClick: () => {
                        this.$refs.crud.destroy(v).then( r => {
                            this.rowData = this.rowData.filter( x => x.id !== v.id)
                            this.$emit('countChanged')
                        })
                    }
                }]
            })
        },

        renderWithSubField(v) {
            let locked = v.data.locked
                // ? '<i style="font-size:12px;" class="fe-grid-icon fas fa-lock ml-2 grey--text text--lighten-1"></i>'
                ? '<i style="font-size:12px;" data-tip="Permissions cannot be changed" class="ec-simple-tip fe-grid-icon fas fa-lock ml-2 grey--text text--lighten-1"></i>'
                : ''
            return v.data.description
                ? `<div style="line-height:30px;">${v.value}${locked}<div class="fe-grid-cell-subtext">${v.data.description}</div></div>`
                : v.value+locked
        },

        postRead(v) {
            // adjust counts by 1 if distrct doesn't have guardian email feature
            if (!this.guardianEmailDisplay) v.forEach(v => v.level >= 5 ? v.security_cnt-- : null)
            this.rowData = v
            this.ready = true
            this.$nextTick(() => {
                this.init()
            })
        },

        updateAliasCount(items) {
            this.$refs.grid.updateCachedRecords({
                id: this.cell.data.id,
                alias_cnt: items.length
            })
            this.refreshCrudGridCells()
            this.$nextTick(() => {
                if (this.$refs.grid.$refs.grid.filterText?.length) this.$refs.grid.$refs.grid.filterResults()
            })
        },
    },
    data() {
        let me = this
        const cellStyleFn = params => params.data.id == this.pendingDeleteId ? { backgroundColor: '#E0E1E6' } : { backgroundColor: 'transparent' };
        return {
            ready: false,
            groupRec: null,
            showAddDialog: false,
            pendingDeleteId: null,
            rowData: null,
            columnDefs: [
                {
                    headerName: 'Name',
                    field: 'name',
                    autoHeight: true,
                    cellRenderer: this.renderWithSubField,
                    cellStyle: cellStyleFn,
                }, {
                    headerName: 'Permissions',
                    field: 'security_cnt',
                    maxWidth: 100,
                    cellRendererFramework: 'FeDialogColumn',
                    onCellClicked: this.onCellClicked,
                    cellStyle: cellStyleFn,
                }, {
                    headerName: 'Users',
                    field: 'user_cnt',
                    maxWidth: 100,
                    cellRendererFramework: 'FeDialogColumn',
                    onCellClicked: this.onCellClicked,
                    cellStyle: cellStyleFn,
                }, {
                    headerName: "Aliases",
                    field: "alias_cnt",
                    maxWidth: 100,
                    sortable: true,
                    editable: false,
                    cellRendererFramework: 'FeDialogColumn',
                    onCellClicked: this.onCellClicked,
                    cellStyle: cellStyleFn,
                },
                { headerName: 'New User Default', field: 'new_user_default', cellRendererFramework: 'FeGridToggle', maxWidth:100, cellStyle: cellStyleFn, },
                { headerName: 'Created', field: 'created', maxWidth:200, cellStyle: cellStyleFn, },
                {
                    colId: 'toolmenu',
                    maxWidth: 50,
                    cellRenderer: v => {
                        return '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>'
                    },
                    onCellClicked: this.onCellClicked,
                    cellStyle: cellStyleFn,
                }
            ],
            dialogColumnDefs: {
                security_cnt: {
                    readOnly:true,
                    model: this.$models.userGroupSecurity,
                    column1: {headerName: 'Permissions', field: 'security_name'},
                    fieldConfig: {
                        type:'combo',
                        url:'security.php?action=get&property=codes',
                        rootProperty:'security',
                        itemSubtext:'description',
                        sort:(a,b) => a.security_name < b.security_name ? -1 : 1,
                        filter: function(x) {
                            if (!me.guardianEmailDisplay && x.security_name === 'Send smartFORMS to guardians') {
                                return false
                            }

                            let r = this.find(y => y.security_id == x.id)
                            return !r
                        }
                    },
                    buildRec: (v,d) => ({ security_id: v.id, user_group_id: d.id,}),
                    width: '600px',
                },
                user_cnt: {
                    model: this.$models.userGroupUser,
                    column1: {headerName: 'Users', field: 'user_full_name'},
                    fieldConfig: {
                        type:'combo',
                        url:'users.php?action=get&active=1',
                        itemText:'user_full_name',
                        filter: function(x) {
                            if(!this.index) {
                                let index = {}
                                this.forEach(y => index[y.user_id]=true)
                                Object.defineProperty(this,'index',{value:index})
                            }
                            return !this.index[x.id]
                        }
                    },
                    buildRec: (v,d) => ({ user_id: v.id, user_group_id: d.id,}),
                }
            }
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
