<template>
    <form-common-config
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #fieldSpecific="{rec}">
            <div class="d-flex">
                <fe-label :style="labelStyle">Min Value</fe-label>
                <v-text-field flat solo dense
                    :value="rec.min_value"
                    @change="rec.min_value = $event"
                    v-mask="'#####'"
                />
            </div>
            <div class="d-flex">
                <fe-label :style="labelStyle">Max Value</fe-label>
                <v-text-field flat solo dense
                    :value="rec.max_value"
                    @change="rec.max_value = $event"
                    v-mask="'#####'"
                />
            </div>
        </template>
    </form-common-config>
</template>

<script>
import FormCommonConfig from '../FormCommonConfig'

export default {
    components: {
        FormCommonConfig,
    },
    computed: {
        labelStyle: me => ({ minWidth:'120px' }),
    },
}
</script>

<style lang="scss" scoped>
</style>
