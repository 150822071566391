<template>
    <v-autocomplete
        v-bind="this.$attrs"
        :value="value" @input="$emit('input',$event)"
        :label="label"
        :loading="isLoading"
        :search-input.sync="userInput"
        :items="items"
        clearable
        item-text="student_full_name"
        item-value="student_id"
        placeholder="Enter student name..."
        no-data-text="No students found..."
    >
        <template slot="item" slot-scope="data">
            <v-list-item-content>
                <v-list-item-title v-html="data.item.student_full_name"/>
                <v-list-item-subtitle>{{data.item.school_name}} {{data.item.grade_desc}}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
        <template slot="selection" slot-scope="data">
            {{data.item.student_full_name}}
        </template>
    ></v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'StudentCombo',

    props: {
        value: {},
        label: {type: String, default: 'Student Name'},
        students: {type: Array, default: ()=>[]}
    },

    computed: {
        ...mapState('global', ['currentYear']),
        schoolYearRec: {
            set(rec) {
                this._schoolYearRec = rec
            },
            get() {
                return this._schoolYearRec || this.currentYear
            }
        }
    },

    data() {
        return {
            userInput: null,
            items: [],
            isLoading: false
        }
    },

    watch: {
        userInput(search) {
            if (search && search.length >= 3) {
                this.load(search)
            }
        }
    },

    beforeMount() {
        if(this.value) this.load(null,this.value)
    },

    methods: {
        load(search,id) {
            if (!this.isLoading) {
                let url = 'students.php?action=get&property=grade_history&school_year_id='+this.schoolYearRec.id+'&behavior_incident=1'
                url += id ? '&id='+id : '&name='+search
                this.isLoading = true
                this.$axios.get(url).then((response) => {
                    this.isLoading = false
                    if (response && response.data) {
                        this.items = response.data.map(x => {
                            if(this.students.some(y => x.student_id == y.student_id)) {
                                x.disabled = true
                            }
                            return x
                        })
                    }
                }).catch((err) => {
                    console.warn('err', err)
                })
            }
        }
    }
}
</script>

<style scoped="true">
    .student-meta {
        font-size: 65%;
        background-color: yellow;
    }
</style>
