<template>
    <div class="pa-4 pb-0">
        <fe-mask v-model="loading" :showLoader="true"/>

        <div class="d-flex mb-3">
            <div class="headline">{{title}}</div>
            <v-spacer/>
            <fe-btn usage="secondary" @click="calcRiskRatio = true">
                Edit Calculation
            </fe-btn>
        </div>

        <div class="d-flex row wrap" v-if="!loading">
            <fe-card
                v-for="(group, i) in sortedCalcData"
                :key="'group-' + i"
                :headerText="group.name"
                class="alt-card rr-card ma-2"
            >
                <template v-slot:body>
                    <div v-if="typeof group.value === 'string'" class="rr-card-count">
                        {{group.value}}
                    </div>
                    <div v-else class="rr-card-count">
                        {{group.value}} to 1
                    </div>
                    <div class="rr-card-comparison">
                        {{group.student_cnt}} / {{group.total_student_cnt}} Students
                    </div>
                </template>
            </fe-card>
        </div>

        <div class="d-flex" v-if="!loading">
            <fe-card class="alt-card chart-card ma-2 flex-grow-1" noTitle>
                <template v-slot:body>
                    <highcharts
                        :options="chartConfig"
                        ref="chart"
                    />
                </template>
            </fe-card>
            <fe-card class="alt-card legend-card ma-2">
                <template v-slot:card-title>
                    <div class="pa-3 pb-0">
                        <h3>Risk Ratio Value Level of Disproportionality</h3>
                        <p>Risk of one group compared to the risk of another group to help identify the degree of
                            disproportionality in incidents reported.</p>
                    </div>
                </template>
                <template v-slot:body>
                    <v-list flat dense>
                        <v-list-item-group>
                            <v-list-item v-for="(item, i) in riskLevels" :key="'risk-' + i">
                                <v-list-item-icon>
                                    <v-list-item-title>{{item.value}}</v-list-item-title>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{item.desc}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>
            </fe-card>
        </div>

        <risk-ratio-form
            v-if="calcRiskRatio"
            title="Edit Calculation"
            :params="localParams"
            @close="calcRiskRatio = false"
            @accept="update"
        />

        <fe-crud ref="crud" :config="crudConfig" @read="parseData"/>
    </div>
</template>

<script>
    import RiskRatioForm from "./RiskRatioForm"
    import {mapState} from "vuex"
    import windowOptionsMixin from '@/mixins/windowOptions'

    export default {
        name: "RiskRatio",
        mixins: [ windowOptionsMixin ],

        components: {RiskRatioForm},

        props: {
            params: {
                required: true
            }
        },

        data() {
            return {
                loading: true,
                localParams: {},
                chartConfig: {},
                calcData: undefined,
                calcRiskRatio: false,
                riskLevels: [{
                    value: "1.0",
                    desc: 'Equal Risk'
                }, {
                    value: "1.25",
                    desc: '25% Higher Risk'
                }, {
                    value: "1.5",
                    desc: '50% Higher Risk'
                }, {
                    value: "2.0",
                    desc: '2X Higher Risk'
                }, {
                    value: "2.5",
                    desc: '2.5X Higher Risk'
                }, {
                    value: "3.0",
                    desc: '3X Higher Risk'
                }],
                sortedCalcData: undefined,
            }
        },

        computed: {
            crudConfig() {
                let cfg = this.$_.cloneDeep(this.$models.studentIncident)
                cfg.read.params.property = 'risk_ratio'
                cfg.read.rootProperty = 'risk_ratio'
                return cfg
            },

            title() {
                if (!this.localParams.calculate_by) return ''
                return "By  " + this.localParams.calculate_by.text
            }
        },

        mounted() {
            this.localParams = this.$_.cloneDeep(this.params)
            this.load()
        },

        methods: {
            load() {
                this.loading = true
                let localParams = this.localParams
                let controlParam = localParams.calculate_by.id
                let readParams = {
                    school_year_id: localParams.school_year_id,
                    control_param: controlParam
                }

                switch (controlParam) {
                    case 'ethnicity':
                    case 'gender':
                        readParams.control_value = localParams.control[controlParam][controlParam]
                        break;
                    default:
                        readParams.control_value = localParams.control[controlParam].id
                        break;
                }

                readParams.start_date = localParams.start_date || null
                readParams.end_date = localParams.end_date || null

                Object.keys(localParams).forEach(key => {
                    if (key !== 'school_year_id' && key.includes('_id') && localParams[key]) {
                        readParams[key] = localParams[key]
                    }
                    if(Array.isArray(readParams[key])) readParams[key] = readParams[key].join(',')
                })

                this.$refs.crud.read(readParams)
            },

            parseData(rawData) {
                this.calcData = rawData
                this.chartData = this.buildChartData(rawData)
                this.loading = false
                // sort tiles by descending values
                // then set chart data in same order
                this.sortedCalcData = this.$_.cloneDeep(this.calcData.chartData).sort((a, b) => { return b.value - a.value })
                this.chartConfig = this.buildChartConfig(this.sortedCalcData)
            },

            buildChartConfig(rawData) {
                let me = this
                return {
                    chart: {
                        type: 'bar',
                        stacked: false,
                        height: '375px'
                    },
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    },
                    title: false,
                    subtitle: false,
                    xAxis: {
                        categories: rawData.map(x => x.name),
                        title: {
                            text: null
                        }
                    },
                    yAxis: {
                        endOnTick: false,
                        title: {
                            text: ''
                        },
                        dataLabels: {
                            enabled: true,
                            style: {
                                textOutline: 0
                            }
                        },
                        size: '100%'
                    },
                    plotOptions: {
                        series: {
                            cursor: 'pointer',
                            stacking: 'normal',
                            point: {
                                events: {
                                    click(e) {
                                        me.handleClick(e)
                                    }
                                }
                            }
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        data: rawData.map(x => typeof x.value === 'string' ? 0 : x.value)
                    }]
                }
            },

            handleClick(e) {
                let params = {...this.calcData.params}
                let key = this.localParams.calculate_by.id
                let value = e.point.category
                switch (key) {
                    case 'disability':
                        params.disability_type_id = value === 'Has Disability' ? -1 : 0
                        break;
                    case 'tag_id':
                        let negate = value === 'Not Specified' ? '!' : ''
                        params.tag_id = negate + this.localParams.control.tag_id.id
                        break;
                    default:
                        params[key] = value
                }

                this.$store.commit('global/addDockableWindow', {
                    name: 'Incident Drilldown',
                    component: 'incident-overview',
                    attrs: {
                        params,
                        allCharts: true,
                        fixedTools: false,
                        breadcrumbs: true
                    }
                })
            },

            buildChartData(rawData) {
                return {
                    categories: rawData.chartData.map(x => x.name),
                    series: [{
                        data: rawData.chartData.map(x => typeof x.value === 'string' ? 0 : x.value)
                    }]
                }
            },

            update(params) {
                this.calcRiskRatio = false
                this.localParams = this.$_.cloneDeep(params)
                this.load()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .rr-card {
        height: 125px;
        min-width: 140px;

        &-title {
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 16px;
        }

        &-count {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
        }

        &-comparison {
            color: #bbbbbb;
            text-align: center;
        }

        &.fe-card-text-full {
            height: unset;
        }
    }

    .chart-card {
        min-width: 700px;

        ::v-deep .fe-chart {
            top: -18px;
        }
    }

    ::v-deep .highcharts-title {
        display: none !important;
    }

    .legend-card {
        max-width: 350px;

        ::v-deep .fe-card-text-full {
            height: unset;
        }
    }
</style>
