<template>
    <div class="ml-2 mt-1" style="width: 98%;">
        <chart-panel :border="collectionChart" class="chart-container" :style="collectionChart ? {height: '400px'} : {height: '100%'}">
            <pin-dialog v-if="pinDialog.show" v-model="pinDialog.show" :pinParams="pinDialog.params" :title="pinDialog.title"/>

            <fe-dialog
                title="Students"
                v-if="dialog.show"
                @accept="dialog.show=false"
                @close="dialog.show=false"
                persistent
                dismissButtonText="Cancel"
                acceptButtonText="Done"
                width="800"
            >
                <div class="d-flex flex-fill flex-column" style="height: 500px;">
                    <fe-grid
                        :rowData="dialog.students"
                        :columnDefs="dialogColumns"
                        displayDensity="small"
                        style="height: 100%;"
                        :showAddRowBtn="false"
                        ref="grid"
                        @selectionChanged="selections=$refs.grid.gridApi.getSelectedRows()"
                    >
                        <template #toolbar-items>
                            <fe-btn usage="secondary" :disabled="selections.length===0" @click="tagStudents">Tag</fe-btn>
                        </template>
                    </fe-grid>
                </div>

            </fe-dialog>

            <v-col v-for="(chart,i) in charts" :cols="chartCols">
                <div :style="!collectionChart ? 'position: relative; border: 1px solid #dddddd; border-radius: 4px;' : ''">
                    <highcharts ref='chartRef' :options="chart.config" class="fe-chart" :class="collectionChart ? 'collection-chart' : ''"/>

                    <div class="tools" :class="collectionChart ? 'collection-tools' : ''">
                        <fe-icon-btn v-if="!collectionChart" useIcon="fas fa-thumbtack" @click="doPin(chart, i)"/>
                        <fe-icon-btn useIcon="fas fa-expand-alt" @click="doExpand(chart, i)"/>
                    </div>
                </div>
            </v-col>
        </chart-panel>
    </div>
</template>

<script>
    import PinDialog from '@/components/common/PinDialog'
    import windowOptionsMixin from '@/mixins/windowOptions'
    import ChartPanel from '@/components/charts/ChartPanel'

    export default {
        name: 'IncidentRangeCharts',
        mixins: [ windowOptionsMixin ],
        components: { PinDialog, ChartPanel },
        props: {
            params: {
                type: Object
            },
            pinnedChart: {
                type: Object
            },
            chartTypes: {
                type: Array,
                default: () => ['pyramid', 'detailed_pyramid']
            },
            height: {
                default: 350
            },
            collectionChart: {
                type: Boolean,
                default: false
            }
        },
        data() {
            let me = this
            return {
                raw: null,
                pinDialog: {
                    show: false,
                    title: ''
                },
                charts: [],
                dialog: {
                    show: false,
                    students: []
                },
                selections: [],
                dialogColumns: [me.$grid.checkColumn(), {
                    headerName: "Student",
                    field: "student_full_name",
                    editable: false,
                    flex: 1
                }, {
                    headerName: "School",
                    field: "school_name",
                    editable: false,
                    flex: 1
                }, {
                    headerName: "Grade",
                    field: "grade_desc",
                    editable: false,
                    flex: 1
                }],
                basicColors: ['#19ADAB', '#FFEEAA', '#FF675D'],
                detailedColors: ['#19ADAB', '#FFEEAA', '#FFBD00', '#FF675D']
            }
        },
        computed: {
            chartCols() {
                if(this.collectionChart && this.charts.length == 1) {
                    return 12
                }
                if (this.$vuetify.breakpoint.smAndDown) {
                    return 12
                } else if (this.$vuetify.breakpoint.mdAndDown || this.collectionChart) {
                    return 6
                } else if (this.$vuetify.breakpoint.lgAndDown) {
                    return 4
                }

                return 3
            }
        },
        watch: {
            params(v) {
                if (v) {
                    this.loadData()
                }
            }
        },
        mounted() {
            this.$emit('all-charts', this.$refs.chartRef)
            this.loadData()
        },
        methods: {
            doPin(chart, i) {
                let cfg = this.raw.charts[i]
                let p = {...this.params}
                p.dashboard_saved_search_type_id = cfg.saved_search_type_id
                this.pinDialog = {
                    show: true,
                    params: p,
                    title: cfg.title
                }
            },
            tagStudents() {
                let me=this
                this.$tagStudents(true, this.selections,()=> {
                    me.$refs.grid.gridApi.deselectAll()

                })
            },
            doExpand(chart, i) {
                let cfg = this.$_.cloneDeep(chart.config)
                cfg.chart.height = window.innerHeight-150

                this.$dockableWindow({
                    name: cfg.title.text,
                    component: 'highcharts',
                    attrs: {
                        options: cfg,
                        class: 'ma-5'
                    }
                })
            },
            chartConfig() {
                let me = this
                return {
                    chart: {
                        type: 'column',
                        stacked: true,
                        zoomType: 'xy',
                        height: me.$props.height + 'px'
                    },
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    },
                    title: {
                        align: 'left',
                        style: {
                            fontSize: 14
                        },
                        text: 'Change Me'
                    },
                    subtitle: false,
                    xAxis: {
                        categories: [],
                        title: {
                            text: null
                        }
                    },
                    yAxis: {
                        endOnTick: false,
                        title: {
                            text: 'Total Count'
                        },
                        dataLabels: {
                            enabled: true,
                            style: {
                                textOutline: 0
                            }
                        },
                        size: '100%'
                    },
                    plotOptions: {
                        series: {
                            cursor: 'pointer',
                            stacking: 'normal',
                            point: {
                                events: {
                                    click(e) {
                                        me.handleClick(e)
                                    }
                                }
                            }
                        }
                    },
                    legend: {

                    },
                    tooltip: {
                        formatter() {
                            return this.y + ' (' + parseFloat(this.percentage).toFixed(2) +'%)'
                        }

                    },
                    credits: {
                        enabled: false
                    },
                    series: []
                }
            },
            handleClick(e) {
                let to = undefined
                let from = 0

                let serie = e.point.series.name
                if (serie.indexOf('-') > -1) {
                    let arr = serie.split('-');
                    from    = arr[0];
                    to      = arr[1];
                } else if (serie.indexOf('+') > -1) {
                    let arr = serie.split('+');
                    from    = arr[0];
                } else {
                    from = serie;
                    to   = serie;
                }

                let p = {...this.params}
                p.incident_range_start = from
                p.incident_range_end = to
                delete p.range

                this.$setLoading(true)
                this.$axios.get('behaviorIncident.php?action=get&property=students&' + this.$objectToParams(p))
                    .then(response => {
                        this.$setLoading(false)
                        this.dialog.show = true
                        this.dialog.students = this.$ecResponse(response, 'students')
                    })
            },
            loadData() {
                let p = {...this.params}
                p.chart_types = this.chartTypes

                this.$modelGet('studentIncidentChart', p).then(response => {
                    this.raw = response
                    this.buildCharts()
                })
            },
            buildCharts() {
                if (!this.raw) return
                this.charts = []
                this.raw.charts.forEach(cfg => {
                    let chartConfig = this.chartConfig()
                    if(this.collectionChart) {
                        chartConfig.title.text = this.pinnedChart.name
                        chartConfig.title.useHTML = true
                        chartConfig.title.style = {
                            color: '#7E8494',
                            borderBottom: '1px solid #E0E1E6',
                            display: 'block'
                        }
                    } else {
                        chartConfig.title.text = cfg.title
                    }
                    //build the series
                    chartConfig.series = cfg.score_fields.map((r,i)=> {
                        return { name: r, data: [], color: (cfg.chart_type==='pyramid' ? this.basicColors[i] : this.detailedColors[i]) }
                    })

                    // build the categories
                    cfg.data.forEach((d,i)=>{
                        if (chartConfig.xAxis.categories.indexOf(d[cfg.key] == -1)) chartConfig.xAxis.categories.push(d[cfg.key])

                        chartConfig.series.forEach(s => { s.data[i] = parseInt(d[s.name]) ? parseInt(d[s.name]) : 0 })
                    })

                    this.charts.push({
                        config: chartConfig
                    })
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tools {
        position: absolute;
        right: 0px;
        top: 0px;
    }
    .fe-chart ::v-deep * {
        font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
    }
    .collection-tools {
        position: relative;
        top: -358px !important;
        float: right  !important;
        z-index: 1 !important;
    }
    .collection-chart {
        ::v-deep .highcharts-title {
            width: 100% !important;
            left: 0 !important;
            padding: 0 0 12px 5px !important;
            font-size: 14px !important;
        }
    }
    .chart-container {
        background: #fff;
    }
</style>
