<template>
    <div class="d-flex">
        <div v-if="fieldConfig.as_table" class="flex-fill no-scroll mb-5">
            <fe-grid
                ref="grid"
                :style="style"
                :defaultColDefOverrides="defaultColDef"
                :columnDefs="columnDefs"
                :rowData="rowData"
                :showToolbar="false"
                :showAddRowBtn="false"
                showScrollbars
                displayDensity="small"
                @gridReady="resize"
                @gridColumnsChanged="resize"
            />
        </div>
        <div v-else
            class="single-field"
        >
            <div class="pa-1" v-html="fieldValue"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'GuardianField',
        props: {
            data: {},
            fieldRec: {},
            editor: {},
        },
        methods: {
            resize() {
                this.$refs.grid?.gridApi?.sizeColumnsToFit()
            },
        },
        computed: {
            fieldConfig() {
                let config
                if(this.editor) {
                    // formFieldSavedSearch comes from either the crud or is update by the xtypeconfig
                    config = {
                        ...this.formFieldSavedSearch,
                        ...this.formFieldSavedSearch?.config_json,
                    }
                } else {
                    // this comes from the instance response
                    config = this?.data || {}
                }
                return config
            },
            fieldValue() {
                if(this.$_.isBlank(this.fieldConfig)) {
                    return null
                } else {
                    if(this.editor) {
                        return ''

                    } else {
                        let guardianIndex = this.fieldConfig.guardian
                            ? this.fieldConfig.guardian -1
                            : null
                        return this.fieldConfig.guardians.length > guardianIndex && this.fieldConfig.data_field
                            ? this.fieldConfig.guardians[guardianIndex][this.fieldConfig.data_field]
                            : ''
                    }
                }
            },
            rowData() {
                return this.data?.guardians ? this.data.guardians : []
            },
            style() {
                let h = 68+30*(Math.max(1,this.rowData.length))
                if(h>300) h = 300
                return {
                    width: '100%',
                    height: `${h}px`,
                }
            },
            columnDefs() {
                let cols = this.fieldConfig?.display_columns

                let a = cols ? [{
                    headerName: 'Guardian Name',
                    field: 'guardian_full_name',
                    hide: cols ? !cols.includes('guardian_full_name') : true
                }, {
                    headerName: 'First Name',
                    field: 'guardian_fname',
                    hide: cols ? !cols.includes('guardian_fname') : true
                }, {
                    headerName: 'Last Name',
                    field: 'guardian_lname',
                    hide: cols ? !cols.includes('guardian_lname') : true
                }, {
                    headerName: 'Address',
                    field: 'guardian_full_address',
                    hide: cols ? !cols.includes('guardian_full_address') : true
                }, {
                    headerName: 'Street',
                    field: 'guardian_addr_street',
                    hide: cols ? !cols.includes('guardian_addr_street') : true
                }, {
                    headerName: 'City',
                    field: 'guardian_addr_city',
                    hide: cols ? !cols.includes('guardian_addr_city') : true
                }, {
                    headerName: 'State',
                    field: 'guardian_addr_state',
                    hide: cols ? !cols.includes('guardian_addr_state') : true
                }, {
                    headerName: 'Zip',
                    field: 'guardian_addr_zip',
                    hide: cols ? !cols.includes('guardian_addr_zip') : true
                }, {
                    headerName: 'Phone',
                    field: 'guardian_phone_number',
                    hide: cols ? !cols.includes('guardian_phone_number') : true
                }, {
                    headerName: 'Work Phone',
                    field: 'guardian_work_phone_number',
                    hide: cols ? !cols.includes('guardian_work_phone_number') : true
                }, {
                    headerName: 'Email',
                    field: 'guardian_email_addr',
                    hide: cols ? !cols.includes('guardian_email_addr') : true
                }, {
                    headerName: 'Last Login Date',
                    field: 'guardian_last_login',
                    valueFormatter: v => v.data.guardian_last_login ? this.$dayjs(v.data.guardian_last_login).format('YYYY-MM-DD') : '',
                    maxWidth: 100,
                    hide: cols ? !cols.includes('guardian_last_login') : true
                }, {
                    headerName: 'Relationship',
                    field: 'relationship',
                    hide: cols ? !cols.includes('relationship') : true
                }, {
                    headerName: 'Created',
                    field: 'created',
                    valueFormatter: v => v.data.created ? this.$dayjs(v.data.created).format('YYYY-MM-DD') : '',
                    maxWidth: 100,
                    hide: cols ? !cols.includes('created') : true
                }] : []

                return a
            }
        },
        data() {
            if(!this.data) {
                // if no response data then get the columns
                this.$modelGet('formFieldSavedSearch', { form_field_id:this.fieldRec.id })
                .then( r => this.formFieldSavedSearch = r?.[0])
                // .then(r => {
                //     if(r.length) {
                //         this.formFieldSavedSearch = r.length && r[0]
                //     }
                // })
            }

            return {
                formFieldSavedSearch: null,
                defaultColDef: this.editor ? {
                    sortable: false,
                    resizable: false,
                    filter: false,
                    hide: false,
                    cellClass: 'x-grid-column'
                } : {
                    sortable: true,
                    resizable: true,
                    filter: true,
                    hide: false,
                    cellClass: 'x-grid-column'
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
.header {
    color: rgb(126,132,148);
    font-size: 12px;
}
.single-field {
    border: 1px solid #ced0d9;
    border-radius: 3px;
    min-height: 36px;
    padding: 2px;
    margin-bottom: 19px;
    background-color: white;
    width: 100%;
}
</style>
