<template>
    <div class="d-flex">
        <v-btn small icon>
            <v-icon small v-if="showExpand" @click="$emit('expand')">fas fa-expand-alt</v-icon>
        </v-btn>

        <v-menu offset-y v-if="showMore">
            <template v-slot:activator="{ on }">
                <v-btn fab small icon v-on="on">
                    <v-icon>more_vert</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-list-item @click="" v-if="showPin">
                    <v-list-item-action>
                        <i class="fas fa-thumbtack"/>
                    </v-list-item-action>

                    <v-list-item-title>Pin Chart</v-list-item-title>
                </v-list-item>

                <v-list-item @click="" v-if="showPercentBased">
                    <v-list-item-action>
                        <i class="fas fa-percentage"/>
                    </v-list-item-action>

                    <v-list-item-title>100% Height</v-list-item-title>
                </v-list-item>

                <v-list-item @click="" v-if="showDownload">
                    <v-list-item-action>
                        <i class="fas fa-image"/>
                    </v-list-item-action>

                    <v-list-item-title>Download Image</v-list-item-title>
                </v-list-item>
            </v-list>

            <slot></slot>
        </v-menu>
    </div>
</template>

<script>
    export default {
        name: 'MenuTools',
        data() {
            return {}
        },
        props: {
            showMore: {
                type: Boolean,
                default: true
            },
            showPin: {
                type: Boolean,
                default: true
            },
            showExpand: {
                type: Boolean,
                default: true
            },
            showPercentBased: {
                type: Boolean,
                default: false
            },
            showDownload: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>