<template>
    <div class="fill-height">
        <div class="flex-shrink-1 vis-type-text">Start by selecting your data to visualize</div>
        <div class="d-flex flex-wrap">
            <div v-for="tile in tiles" class="vis-type-tile" @click="tile.handler(tile)">
                <v-icon class="vis-type-tile-icon">{{tile.glyph}}</v-icon>
                <div class="vis-type-tile-text">{{tile.text}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TypeSelector",

    data() {
        return {
            restricted: false
        }
    },

    computed: {
        tiles() {
            let options = [{
                text: 'Section Header',
                glyph: 'fas fa-font',
                scope: this,
                handler(item) {
                    item.scope.go("section-header")
                }
            },{
                text: 'Assessment',
                glyph: 'fas fa-graduation-cap',
                scope: this,
                disabled: this.restricted,
                handler(item) {
                    if(!item.scope.restricted) item.scope.go("assessment");
                }
            }, {
                text: 'Attendance',
                glyph: 'fas fa-bell',
                scope: this,
                disabled: this.restricted,
                handler(item) {
                    if(!item.scope.restricted) item.scope.go("attendance");
                }
            }, {
                text: 'Incident Type',
                glyph: 'fas fa-exclamation',
                scope: this,
                disabled: this.restricted,
                handler(item) {
                    if(!item.scope.restricted) item.scope.go("incident-type");
                }
            }, {
                text: 'Incident Response',
                glyph: 'fas fa-reply',
                scope: this,
                disabled: this.restricted,
                handler(item) {
                    if(!item.scope.restricted) item.scope.go("incident-response");
                }
            }, {
                text: 'Tag',
                glyph: 'fas fa-tag',
                scope: this,
                disabled: this.restricted,
                handler(item) {
                    if(!item.scope.restricted) item.scope.go("tag");
                }
            }, {
                text: 'Demographics',
                glyph: 'fas fa-globe',
                scope: this,
                disabled: this.restricted,
                handler(item) {
                    if(!item.scope.restricted) item.scope.go("demographic");
                }
            }]

            if (!this.restricted) {
                options.push({
                    text: 'Google Sheet',
                    glyph: 'fas fa-file-spreadsheet',
                    scope: this,
                    handler(item) {
                        item.scope.go("google-sheet");
                    }
                })
            } else {
                options.splice(1,0, {
                    text: 'Google Sheet',
                    glyph: 'fas fa-file-spreadsheet',
                    scope: this,
                    handler(item) {
                        item.scope.go("google-sheet");
                    }
                })
            }

            return options
        }
    },

    methods: {
        go(to) {
            this.$emit('valid', false)
            this.$emit('go', to)
        }
    }
}
</script>

<style lang="scss" scoped>
.vis-type-text {
    color: #7E8494;
}

.vis-type-tile {
    height: 165px;
    width: 165px;
    border-radius: 5px;
    border: 1px solid #C0C3CF;
    margin: 23px;
    text-align: center;
    cursor: pointer;

    .vis-type-tile-icon {
        margin-top: 60px;
        margin-bottom: 16px;
        font-size: 28px;
        color: #050F2D
    }

    .vis-type-tile-text {
        font-size: 16px;
        color: #050F2D;
    }

    &:hover {
        border: 2px solid #015673;

        .vis-type-tile-icon, .vis-type-tile-text {
            color: #015673
        }
    }
}
</style>
