<template>
    <div class="flex-fill flex-column no-scroll pa-4">

        <fe-dialog @close="advancedDialog.show=false" width="500" persistent v-model="advancedDialog.show" v-if="advancedDialog.show" title="Advanced" :footer="false">
            <model-form
                idProperty="id"
                @cancel="finishAdvanced"
                @done="finishAdvanced"
                :records="advancedDialog.records"
                model="uploadType"
                :fieldConfig="advancedFields"
                submitButtonText="Save"
            />
        </fe-dialog>

        <fe-dialog @close="createDialog.show=false" width="500" persistent v-model="createDialog.show" v-if="createDialog.show" title="Add Upload Type" :footer="false">
            <model-form
                idProperty="id"
                @cancel="finishAdvanced"
                @done="finishAdvanced"
                :records="createDialog.record"
                model="uploadType"
                :fieldConfig="createFields"
                submitButtonText="Save"
            />
        </fe-dialog>

        <div class="d-flex">
            <fe-btn usage="ghost" useIcon="fas fa-chevron-left" @click="$router.push('/Uploads')">Back</fe-btn>
            <div class="page-title">Upload Types</div>
        </div>

        <fe-crud-grid
            ref="crud"
            :autoload="false"
            :config="$models.uploadType"
            @read="filterItems($event)"
            :readParams="{all: 1}"
            :columnDefs="columns"
            domLayout="normal"
            class="flex-grow-1"
            displayDensity="small"
            disableSnackbars
            disableSuccessfulSnackbars
            @cellClicked="handleClick"
            disableInlineCreate
            @beginCreate="doCreate"
        ></fe-crud-grid>
    </div>
</template>

<script>
    import ModelForm from '@/components/common/form/ModelForm'

    export default {
        name: 'Uploads',
        props: ['status'],
        components: {
            ModelForm
        },
        watch: {
            status: {
                handler(v) {
                    this.$modelGet('assessmentType')
                        .then(response => {
                            this.$refs.crud.$refs.crud.read()
                        })
                },
                immediate: true
            }
        },
        data() {
            let me = this
            let supportOnlyColumns = []
            if (!!this.$isSupport()) {
                supportOnlyColumns.push({
                    headerName: 'Preprocess',
                    field: 'preprocess_flag',
                    width: 80,
                    editable: false,
                    cellStyle: { textAlign: 'center' },
                    valueFormatter: (v) => v.data.preprocess_flag ? "Yes" : "",
                }, {
                    headerName: 'Norms ID',
                    field: 'norms_id',
                    width: 80,
                    hide: true,
                    editable: false,
                    cellStyle: { textAlign: 'center' }
                }, {
                    headerName: 'Created',
                    field: 'created',
                    width: 80,
                    hide: true,
                    editable: false,
                    cellStyle: { textAlign: 'center' }
                })
            }
            return {
                assessmentTypes: [],
                importType: [],
                advancedDialog: {
                    show: false,
                    records: []
                },
                createDialog: {
                    show: false,
                    record: []
                },
                createFields: [{
                    field: 'name',
                    label: 'Upload Type',
                    width: '100%',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    width: '100%',
                    required: true,
                    validatorConfig: { limit: 100, required: true },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true
                    }
                }, {
                    field: 'upload_path',
                    label: 'File Path',
                    width: '100%',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    width: '100%',
                    validatorConfig: { limit: 100, required: true },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true
                    }
                }, {
                    field: 'data_point_type_id',
                    label: 'Assessment Group',
                    component: 'fe-remote-combo',
                    width: '100%',
                    attrs: {
                        url: 'dataPointType.php?action=get&active=1',
                        itemValue: 'id',
                        itemText: 'name',
                        byId: true
                    }
                }, {
                    field: 'import_type_id',
                    label: 'Import Type',
                    component: 'fe-remote-combo',
                    width: '100%',
                    attrs: {
                        url: 'importTypes.php?action=get&active=1',
                        itemValue: 'id',
                        itemText: 'name',
                        rootProperty: 'types',
                        byId: true
                    }
                }, {
                    field: 'run_order',
                    label: 'Run Order',
                    component: 'v-text-field',
                    validatorKey: 'number',
                    width: '100%',
                    validatorConfig: { min: 0, max: 10, required: true },
                    attrs: {
                        type: 'number',
                        flat: true,
                        solo: true,
                        dense: true
                    }
                }, {
                    field: 'require_school_year',
                    label: 'Require School Year',
                    component: 'v-switch',
                    width: '100%',
                    attrs: {
                    }
                }, {
                    field: 'require_school',
                    label: 'Require School',
                    component: 'v-switch',
                    width: '100%',
                    attrs: {
                    }
                }, {
                    field: 'hidden',
                    label: 'Disabled',
                    component: 'v-switch',
                    width: '100%',
                    attrs: {
                    }
                }],
                advancedFields: [{
                    field: 'student_id_data_point_type_id',
                    label: 'Bind Student ID to Assessment',
                    component: 'fe-remote-combo',
                    width: '100%',
                    attrs: {
                        url: 'dataPointType.php?action=get&active=1',
                        itemValue: 'id',
                        itemText: 'name',
                        byId: true
                    }
                }, {
                    field: 'auto_create_assessment_flag',
                    label: 'Automatically Create Assessments',
                    component: 'v-switch',
                    width: '100%',
                    attrs: {
                    }
                }, {
                    field: 'purge_flag',
                    label: 'Purge',
                    component: 'v-switch',
                    width: '100%',
                    attrs: {
                    }
                }, {
                    field: 'purge_school_year',
                    label: 'Purge Full School Year',
                    component: 'v-switch',
                    toggle: (item) => {
                        // incidents allow purging the entire year
                        return !!item.purge_flag && (item.import_parser_code == 'BEHAVIORINCIDENTS' || item.import_parser_code == 'BEHAVIORINCIDENTSBULK')
                    },
                    width: '100%',
                    attr: {},
                }, {
                    field: 'purge_by_date',
                    label: 'Purge By Date',
                    component: 'fe-remote-combo',
                    width: '100%',
                    toggle: (item) => {
                        // incidents allow purging the entire year
                        return !!item.purge_flag && (item.import_parser_code == 'BEHAVIORINCIDENTS' || item.import_parser_code == 'BEHAVIORINCIDENTSBULK')
                    },
                    attrs: {
                        items: [
                            {id: "ENTRYDATE", name: "Purge by Entry Date"},
                            {id: "INCIDENTDATE", name: "Purge by Incident Date"},
                        ],
                        itemValue: 'id',
                        itemText: 'name',
                        byId: true
                    }
                }, {
                    field: 'locate_header_cnt',
                    label: 'Header Row #',
                    component: 'v-text-field',
                    validatorKey: 'number',
                    width: '100%',
                    required: false,
                    validatorConfig: { min: 0 },
                    attrs: {
                        type: 'number',
                        flat: true,
                        solo: true,
                        dense: true
                    }
                }, {
                    field: 'strip_newlines_flag',
                    label: 'Remove Newlines',
                    component: 'v-switch',
                    attrs: {
                        width: '100%'
                    }
                }],
                gridItems: [],
                statuses: [],
                uploadTypes: [],
                filters: {
                    upload_status_id: {
                        included: []
                    },
                    upload_type_id: {
                        included: []
                    }
                },
                columns: [{
                    headerName: 'ID',
                    field: 'id',
                    width: 80,
                    editable: false,
                    hide: true
                }, {
                    headerName: 'Upload Type',
                    field: 'name',
                    sortable: true,
                    flex: 2,
                    editable: true
                }, {
                    headerName: "Path",
                    field: "upload_path",
                    sortable: true,
                    flex: 1,
                    editable: true
                }, {
                    headerName: 'Assessment Group',
                    editable: true,
                    valueGetter: v => this.assessmentTypes.find(rec => rec.id === v.data.data_point_type_id)?.name ?? '',
                    valueSetter: v => {
                        v.data.data_point_type_id = v.newValue
                        return true
                    },
                    flex: 1,
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: v => ({
                        value: v.data.data_point_type_id,
                        mode: 'edit',
                        multiple: false,
                        items: me.assessmentTypes,
                        keyProp: 'id',
                        labelProp: 'name',
                        disableChips: true
                    }),
                }, {
                    headerName: 'Import Type',
                    editable: true,
                    flex: 1,
                    valueGetter: v => this.importTypes.find(rec => rec.id === v.data.import_type_id)?.name ?? '',
                    valueSetter: v => {
                        v.data.import_type_id = v.newValue
                        return true
                    },
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: v => ({
                        value: v.data.import_type_id,
                        mode: 'edit',
                        multiple: false,
                        items: me.importTypes,
                        keyProp: 'id',
                        labelProp: 'name',
                        disableChips: true
                    }),
                }, {
                    headerName: 'Run Order',
                    field: 'run_order',
                    width: 60,
                    editable: true,
                    cellStyle: { textAlign: 'right' }
                }, ...supportOnlyColumns,
                    me.$grid.toggleColumn('Require Year', 'require_school_year', '', () => {}),
                    me.$grid.toggleColumn('Require School', 'require_school', '', () => {}),
                    me.$grid.toggleColumn('Disabled', 'hidden', '', () => {}), {
                    headerName: 'Advanced',
                    width: 90,
                    cellStyle: {
                        textAlign: 'center',
                        cursor: 'pointer'
                    },
                    cellRenderer: () => {
                        return '<i class="fas fa-cog"></i>'
                    }
                }]
            }
        },
        computed: {
            columnsOld() {
                let me = this
                return
            }
        },
        created() {
            this.$modelGet('assessment')
                .then(response => {
                    response.sort((a, b) => a.name.localeCompare(b.name))
                    this.assessmentTypes = response
                })

            this.$modelGet('importType')
                .then(response => {
                    response.sort((a, b) => a.name.localeCompare(b.name))
                    this.importTypes = response
                })

        },
        methods: {
            doCreate() {
                this.createDialog.record = [{}]
                this.createDialog.show = true
            },
            handleClick(meta) {
                if (meta.colDef.headerName == 'Advanced') {
                    try {
                        // decode config_json
                        const config = JSON.parse(meta.data.config_json)
                        meta.data.purge_school_year = config.purge_school_year
                        meta.data.purge_by_date = config.purge_by_date
                    } catch (e) {}
                    this.advancedDialog.records = [meta.data]
                    this.advancedDialog.show = true
                }
            },
            finishAdvanced() {
                this.$refs.crud.$refs.crud.read()
                this.$data.advancedDialog = {
                    show: false,
                    records: []
                }

                this.createDialog.record = []
                this.createDialog.show = false
            },
            filterItems(data) {
                this.$data.gridItems = data
                let arr = []
                let s = this.$props.status ? this.$props.status : ''

                switch (s.toLowerCase()) {
                    case 'enabled':
                        arr = data.filter(rec => rec.hidden == 0)
                        break
                    case 'disabled':
                        arr = data.filter(rec => rec.hidden == 1 )
                        break
                    default:
                        arr = data
                        break
                }
                this.$refs.crud.setCachedRecords(arr)
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
