export default {
    dataPointEmbargo: {
        defaults: {
            endpoint: 'crud.php', // scope: score_detail_type_id=209
            rootProperty: 'data_point_embargo',
            params: {
                property: 'DataPointEmbargo',
            }
        },
        read: {
            params: { action: 'get' }
        },
        create: {
            params: { action: 'create' }
        },
        update: {
            params: { action: 'update' }
        },
        destroy: {
            params: { action: 'delete' }
        }
    },
    dataPointEmbargoUser: {
        defaults: {
            endpoint: 'crud.php', // scope: score_detail_type_id=209
            rootProperty: 'data_point_embargo_user',
            params: {
                property: 'DataPointEmbargoUser',
            }
        },
        read: {
            params: { action: 'get' }
        },
        create: {
            params: { action: 'create' }
        },
        update: {
            params: { action: 'update' }
        },
        destroy: {
            params: { action: 'delete' }
        }
    },
    acceptedDataEmbargoNotice: {
        defaults: {
            endpoint: 'crud.php', // scope: score_detail_type_id=209
            rootProperty: 'accepted_data_embargo_notice',
            params: {
                property: 'AcceptedDataEmbargoNotice',
            }
        },
        read: {
            params: { action: 'get' }
        },
        create: {
            params: { action: 'create' }
        },
        update: {
            params: { action: 'update' }
        },
        destroy: {
            params: { action: 'delete' }
        }
    }
}