<template>
    <div
        class="displayfield"
        v-html="fieldRec.display_value ? fieldRec.display_value : fieldRec.value"
        :style="{
            height: height ? height+'px' : undefined,
            textAlign: fieldRec.value_align || 'left',
            fontSize: fieldRec.font_size ? fieldRec.font_size+'px': null
        }
    "></div>
</template>

<script>
    export default {
        name: 'DisplayField',
        props: ['fieldRec','height'],
        data() {
            return {}
        }
    }
</script>

<style lang="scss" scoped>
div.displayfield {
    word-wrap: break-word;
    ::v-deep b {
        font-weight: 900;
        color: black;
    }
}
</style>
