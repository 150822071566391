import Vue from "vue";

export default Vue.extend({
    template: `<div>
                    <fe-tooltip :disabled="!owner && !params.disabled" :tooltip="getTooltip" direction="top" block>
                        <v-switch
                            feId="FeSwitch"
                            ref="switch"
                            v-model="value"
                            class="smartforms-manage-form-restrictions-fe-switch"
                            :class="owner ? 'smartforms-manage-form-restrictions-fe-switch-disable' : ''"
                            style="margin-top: 7px"
                            hide-details
                            @click="setValue"
                            :ripple="false"
                            :disabled="params.disabled"
                        />
                    </fe-tooltip>
              </div>`,

    data() {
        return {
            owner: false,
        }
    },

    computed: {
        getTooltip() {
            if (this.owner) {
                let type = this.params.colDef.field.split('_')[1]
                let owner = this.params.data.user_full_name ? 'user' : 'user_group'
                switch(type) {
                    case 'view':
                        if(owner == 'user') return 'The owner of this smartForm template will always have access to view the template.'
                        else return 'Site Administrators have special permissions that allow them to have view access to this template.'
                    case 'edit':
                        if(owner == 'user') return 'The owner of this smartForm template will always have access to edit the template.'
                        else return 'Site Administrators have special permissions that allow them to have edit access to this template.'
                    case 'create':
                        if(owner == 'user') return 'The owner of this smartForm template will always have access to create instances of the template.'
                        else return 'Site Administrators have special permissions that allow them to create instances of the this template.'
                    case 'delete':
                        if(owner == 'user') return 'The owner of this smartForm template will always have access to delete instances of the template.'
                        else return 'Site Administrators have special permissions that allow them to delete instances of the this template.'
                    default:
                        break
                }
            }
            if (this.params.disabled) {
                return this.params.disabledMessage || 'This cannot be disabled while Carry Over to Next Year is enabled for this Status Code.'
            }
        }
    },

    created() {
        this.value = this.params.value ? 1 : 0
    },

    mounted() {
        this.owner = this.params.data.user_full_name && this.params.data.user_full_name.includes('owner') || this.params.data.user_group_name == 'Site Administrators'
        let switchEl = this.$refs.switch.$el
        let toggleButtonEl = switchEl.querySelector('.v-input--switch__thumb')
        if (this.owner) {
            toggleButtonEl.innerHTML = `<i class="material-icons smartforms-manage-form-restrictions-fe-switch-icon">lock</i>`
        }
    },

    methods: {
        setValue () {
            let val = this.value
            if (!this.owner && !this.params.colDef.onCellValueChanged){
                this.params.node.data[this.params.column.colId] = val ? 1 : 0
            }
            // if wanting to make changes onCellValueChanged vs onCellClicked
            if (this.params.colDef.onCellValueChanged) {
                this.params.node.setDataValue(this.params.colDef.field, val ? 1 : 0)
            }
        },
    },
});
