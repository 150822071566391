export default {
    targetType: {
        defaults: {
            endpoint: 'targetType.php'
        },
        read: {
            params: { action: 'get', sort: 'name', dir: 'ASC' }
        },
        create: {
            params: { action: 'create' },
        },
        update: {
            params: { action: 'update' },
        },
        destroy: {
            params: { action: 'delete' },
        }
    }
}