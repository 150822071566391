<template>
    <div>
        <v-form v-model="valid" ref="form">

            <fe-label>Title</fe-label>
            <v-text-field
                v-model="title"
                flat solo dense validateOnBlur
                :style="{ width: '100%' }"
                :rules="rules.title"
            />

            <fe-label>Description</fe-label>
            <v-textarea
                v-model="description"
                flat solo dense
                :style="{ width: '100%' }"
                :rows="3"
                counter="2000"
                :rules="rules.description"
            />

            <br>

            <div class="d-flex">
                <div class="self-align-center" style="position: relative; top: -4px; width: 65px;"><fe-label>Recurring</fe-label></div>
                <div class="self-align-center" style="width: 100px;"><fe-switch v-model="isRecurring" useIcon="check" /></div>
            </div>

            <div v-if="isRecurring">

                <div class="mb-2">
                    <weekday-picker v-model="weekdays" />
                    <div v-if="!daysOfWeekValid" class="v-messages theme--light error--text days-of-week-errors" role="alert">
                        <div class="v-messages__wrapper">
                            <div class="v-messages__message">Day of week required</div>
                        </div>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="pr-2 flex-grow-1 flex-shrink-1">
                        <fe-label>Start Date</fe-label>
                        <fe-date-picker
                            :class="{ 'date-range-error': !dateRangeValid }"
                            :rules="$fieldValidators('text', 'Start date', { required: true })"
                            v-model="startDate"
                            prependIcon="fa-calendar-alt"
                            dateFormat="MM/DD/YYYY"
                        />
                    </div>

                    <div class="pr-2 flex-grow-1 flex-shrink-1">
                        <fe-label>End Date</fe-label>
                        <fe-date-picker
                            :class="{ 'date-range-error': !dateRangeValid }"
                            :rules="rules.endDate"
                            v-model="endDate"
                            prependIcon="fa-calendar-alt"
                            dateFormat="MM/DD/YYYY"
                        />
                    </div>

                    <div class="pr-2 flex-grow-1 flex-shrink-1">
                        <fe-label>Start Time</fe-label>
                        <fe-time-picker
                            prepend-icon="access_time"
                            :allowed-minutes="allowedMinutes"
                            v-model="startTime"
                            :rules="$fieldValidators('text', 'Start time', { required: true })"
                        />
                    </div>

                    <div class="flex-grow-1 flex-shrink-1">
                        <fe-label>End Time</fe-label>
                        <fe-time-picker
                            prepend-icon="access_time"
                            :allowed-minutes="allowedMinutes"
                            v-model="endTime"
                            :rules="rules.endTime"
                        />
                    </div>
                </div>

                <div>
                    <fe-label>Every # Week(s)</fe-label>
                    <v-text-field
                        flat solo dense
                        v-model="frequency"
                        validateOnBlur
                        :rules="rules.frequency"
                    />
                </div>

                <div class="text-muted text-smaller" style="position: relative; top: -8px;">
                    You can add dates outside the recurring schedule in the final step of meeting creation.
                </div>

            </div>

            <fe-label>Attendees</fe-label>

            <div id="attendee_chips">
                <v-chip
                    class="attendee-chip"
                    v-for="user in users"
                    :key="`mtg-attendee-user-${user.user_id}`"
                    @click:close="$emit('removeUsers', [user])"
                    close
                >
                    <v-avatar left>
                        <v-img v-if="user.photo_url" :src="user.photo_url" />
                        <v-icon v-else style="font-size: 26px;">fas fa-user-circle</v-icon>
                    </v-avatar>
                    {{ user.user_full_name }}
                </v-chip>
                <fe-icon-btn
                    v-if="users.length"
                    size="15px"
                    useIcon="fas fa-plus"
                    usage="ghost"
                    @click="onShowAddUsers"
                />
                <fe-btn
                    v-else
                    size="15px"
                    useIcon="fas fa-plus"
                    usage="tertiary"
                    @click="addUsers = true"
                    style="position: relative; left: -8px;"
                >Add Attendees</fe-btn>
            </div>
        </v-form>

        <fe-dialog
            v-if="addUsers"
            title="Meeting Attendees"
            @dismiss="addUsers = false"
            @accept="onAddUsers"
            width="800"
        >
            <user-search v-model="addingUsers" @filterUsers="onFilterUsers" :users="users" keepSelection />
        </fe-dialog>

    </div>
</template>

<script>
    import WeekdayPicker from '@/components/common/button/WeekdayPicker'
    import UserSearch from '@/components/common/UserSearch'

    export default {
        name: 'Details',
        components: { WeekdayPicker, UserSearch },
        props: {
            obj: { type: Object, required: true },
            editing: { type: Object, default: false },
            schedule: { type: Object, default: null },
            users: { type: Array, required: true }
        },
        data() {
            return {
                addUsers: false,
                selectedUsers: [],
                filteredUsers: [],
                allowedMinutes: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
                valid: true,
                rules: {
                    title: [
                        v => (!!v && v.trim().length > 0) || 'Please enter a title'
                    ],
                    description: [
                        v => (!!v && v.trim().length > 0) || 'Please enter a description',
                        v => !v || v.length <= 2000 || 'Value exceeds character limit',
                    ],
                    endDate: [
                        v => !this.$dayjs(v).isBefore(this.startDate) || 'Not a valid End Date'
                    ].concat(this.$fieldValidators('text', 'End date', { required: true })),
                    endTime: [
                        v => !this.$dayjs('2000-01-01 ' + v).isBefore('2000-01-01 ' + this.startTime) || 'Not a valid End Time'
                    ].concat(this.$fieldValidators('text', 'End time', { required: true })),
                    frequency: [
                        (v) => { return (v &&
                            `${v}`.match(/^\s*[0-9]*\s*$/) &&
                            !isNaN(parseInt(v)) &&
                            parseInt(v) >= 1) || 'Not a valid frequency'
                        }
                    ]
                },
                dateRangeValid: true,
                daysOfWeekValid: true
            }
        },
        computed: {
            creating () { return !this.editing },
            validations () {
                return {
                    description: this.rules.description.reduce((arr, fn) => {
                        let result = fn(this.description)
                        if (result !== true) {
                            arr = [...arr, result]
                        }

                        return arr
                    }, []).join(" ").trim()
                }
            },
            title: {
                get () { return this.obj.title },
                set (v) { this.setAttrs('obj', { title: v }) }
            },
            description: {
                get () { return this.obj.description },
                set (v) { this.setAttrs('obj', { description: v }) }
            },
            isRecurring: {
                get () { return this.schedule.frequency_cd != 'NOSCHED' },
                set (v) { this.setAttrs('schedule', { frequency_cd: v ? 'WEEK' : 'NOSCHED' }) }
            },
            weekdays: {
                get () { return this.schedule.week_days || '' },
                set (v) {
                    this.setAttrs('schedule', { week_days: v || '' })
                    this.$nextTick(() => this.validateDaysOfWeek())
                }
            },
            startTime: {
                get () { return this.schedule.default_start_time },
                set (v) {
                    this.setAttrs('schedule', { default_start_time: v })

                    if ((v && !this.endTime) || this.$dayjs('2000-01-01 ' + this.endTime).isBefore('2000-01-01 ' + v)) {
                        this.setAttrs('schedule', {default_end_time: this.$dayjs(v, 'HH:mm').add(15, 'm').format('HH:mm')})
                    }
                }
            },
            endTime: {
                get () { return this.schedule.default_end_time },
                set (v) { this.setAttrs('schedule', { default_end_time: v }) }
            },
            startDate: {
                get () { return this.schedule.start_date },
                set (v) { this.setAttrs('schedule', { start_date: v }) }
            },
            endDate: {
                get () { return this.schedule.end_date },
                set (v) { this.setAttrs('schedule', { end_date: v }) }
            },
            frequency: {
                get () { return this.schedule.frequency_cnt },
                set (v) { this.setAttrs('schedule', { frequency_cnt: v }) }
            },
            addingUsers: {
                get () { return [] },
                set (v) { this.selectedUsers = v }
            }
        },
        methods: {
            validateDaysOfWeek () { this.daysOfWeekValid = `${this.weekdays}` != '' },
            validateDateRange () { this.dateRangeValid = !!(this.schedule.start_date && this.schedule.end_date) },
            customRulesValid () {
                if (this.isRecurring) {
                    this.validateDaysOfWeek()
                    this.validateDateRange()
                    return (this.dateRangeValid && this.daysOfWeekValid)
                } else {
                    return true
                }
            },
            isValid () {
                return (this.$refs.form)
                    ? !!this.$refs.form.validate() & !!this.customRulesValid()
                    : false
            },
            setAttrs (itm, attrs) {
                this.$emit('setAttrs', { key: itm, attrs: attrs })
            },
            onShowAddUsers () {
                this.addUsers = true
                this.selectedUsers = []
            },
            // The user search received search results of a subset of users
            onFilterUsers (filteredUsers) {
                this.filteredUsers = filteredUsers
            },
            onAddUsers () {
                this.addUsers = false
                this.$emit('addUsers', this.selectedUsers)
                this.$emit('removeUsers', this.filteredUsers
                    .filter(u => !this.selectedUsers.find(su => su.id == u.id))
                    // filteredUsers from ag grid must be translated into a User{} with ~meeting_id data
                    .map(u => this.users.find(usr => usr.user_id == u.id))
                    // Any unselected ag grid rows that were not originally selected users
                    // will have mapped to undefined and must be filtered out
                    .filter(u => !!u)
                )
            },
        },
        watch: { }
    }
</script>

<style lang="scss">
    .date-range-error {
        .v-input.fe-date-range-text-field .v-input__slot {
            border-color: #ff5252;
        }
    }
    .days-of-week-errors {
        text-indent: 10px;
    }
    .date-range-errors {
        position: relative;
        height: 0px;
        overflow-y: visible;
        top: -20px;
        text-indent: 10px;
    }
    #attendee_chips {
        line-height: 45px;
        .attendee-chip.v-chip.v-chip--removable {
            &>span.v-chip__content {
                max-width: none;
                padding-left: 5px;
            }
        }
    }
</style>
