<template>
    <div class="flex-fill flex-column slo-plan-panel">
        <div class="d-flex">
            <fe-filter-btn
                :ref="`filter_users`"
                title="Assignee"
                :items="assignees"
                itemValue="id"
                itemText="name"
                v-model="assigneeSelection"
                multiple
                actions
                :closeOnSelect="false"
                show-first-selection
            />

            <fe-filter-btn
                :ref="`filter_start_date`"
                :title="dateStartText"
                v-model="dateStartFilter"
                show-first-selection
            >
                <functional-calendar
                    class="lean-calendar"
                    v-model="dateStartSelection"
                    dateFormat="mm/dd/yyyy"
                    is-date-picker
                />
                <div class="filter-clearing-area">
                    <v-btn
                        text depressed
                        color="default"
                        class="px-0"
                        @click="dateStartSelection = null"
                        :disabled="!dateStartSelection"
                    >Clear</v-btn>
                </div>
            </fe-filter-btn>

            <fe-filter-btn
                :ref="`filter_end_date`"
                :title="dateEndText"
                v-model="dateEndFilter"
                show-first-selection
            >
                <functional-calendar
                    class="lean-calendar"
                    v-model="dateEndSelection"
                    dateFormat="mm/dd/yyyy"
                    is-date-picker
                />
                <div class="filter-clearing-area">
                    <v-btn
                        text depressed
                        color="default"
                        class="px-0"
                        @click="dateEndSelection = null"
                        :disabled="!dateEndSelection"
                    >Clear</v-btn>
                </div>
            </fe-filter-btn>

            <fe-filter-btn
                :ref="`filter_statuses`"
                title="Status"
                :items="statuses"
                itemValue="id"
                itemText="name"
                v-model="statusSelection"
                multiple
                actions
                :closeOnSelect="false"
                show-first-selection
            />

            <v-spacer />

            <fe-btn
                usage="primary"
                @click="showModal = true"
            >Add</fe-btn>
        </div>

        <strategies-card
            v-for="strategy in strategies"
            :filters="filters"
            :slo="slo"
            :strategy="strategy"
            :statuses="statuses"
            :key="`slo-strategy-card-${strategy.id}-panel`"
            @visibilityChanged="adjustVisibleCount($event)"
            @refresh="$emit('refresh')"
            @edit="showModal=strategy"
            @inDevelopment="$emit('inDevelopment')"
        />

        <strategies-modal
            v-if="showModal"
            :slo="slo"
            :item="this.showModal === true ? null : this.showModal"
            @close="showModal = null"
            @created="strategyCreated"
            @updated="strategyUpdated"
        />

        <div class="pt-5 mt-5" v-if="!visibleCount">
            <fe-empty-state
                image="/images/rocket_small.png"
                text="There are no strategies to display."
            />
        </div>

    </div>
</template>

<script>
    import { FunctionalCalendar } from 'vue-functional-calendar'
    import StrategiesCard from './StrategiesCard'
    import StrategiesModal from './StrategiesModal'

    export default {
        name: 'SloPanelStrategies',
        props: {
            slo: { type: Object, required: true }
        },
        components: {
            FunctionalCalendar,
            StrategiesCard,
            StrategiesModal
        },
        data() {
            return {
                showModal: null,
                untruncated: [],
                assigneeSelection: { included: [], excluded: [] },
                statusSelection: { included: [], excluded: [] },
                statuses: [
                    { id: 0, name: 'To Do' },
                    { id: 1, name: 'Done' },
                    { id: 2, name: 'Overdue' }
                ],
                dateStartSelection: null,
                dateEndSelection: null,
                visibleCount: 0
            }
        },
        computed: {
            dateStartFilter () { return { included: this.dateStartSelection ? [{ id: 0, name: this.dateStartText }] : [], excluded: [] } },
            dateEndFilter () { return { included: this.dateEndSelection ? [{ id: 0, name: this.dateEndText }] : [], excluded: [] } },
            dateStartText () { return this.dateStartSelection?.selectedDate || 'Start Date' },
            dateEndText () { return this.dateEndSelection?.selectedDate || 'End Date' },
            strategies () { return this.slo.strategies },
            assignees () {
                let items = []
                this.strategies.forEach(itm => {
                    if (!items.find(b => b.id === itm.user_id)) {
                        items.push({
                            id: itm.user_id,
                            name: itm.user_display_name
                        })
                    }
                })
                return items
            },
            filters () {
                return {
                    start_date: this.dateStartSelection?.selectedDate || null,
                    end_date: this.dateEndSelection?.selectedDate || null,
                    assignee: this.assigneeSelection?.included || [],
                    status: this.statusSelection?.included || []
                }
            }
        },
        methods: {
            adjustVisibleCount (val) {
                if (val) this.visibleCount += 1
                else this.visibleCount -= 1
            },
            strategyCreated () {
                this.$emit('refresh')
                this.showModal = null
            },
            strategyUpdated () {
                this.$emit('refresh')
                this.showModal = null
            }
        },
        mounted () {
            this.visibleCount = this.strategies.length
        },
        watch: {
            'slo.strategies'(v) {
                if (v) this.visibleCount = v.length
            }
        }
    }
</script>

<style lang="scss" scoped>
    .filter-clearing-area {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: solid 1px #e5e5e5;
        cursor: pointer;
    }
    .lean-calendar {
        ::v-deep .vfc-main-container {
            box-shadow: none !important;
        }

        // ::v-deep .vfc-main-container {
        //     height: auto !important;
        // }
        ::v-deep .vfc-main-container {
            font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
            padding-bottom: 15px !important;
        }
        ::v-deep .vfc-top-date {
            font-size: 14px !important;
        }
        ::v-deep .vfc-arrow-right, ::v-deep .vfc-arrow-left {
            height: 10px !important;
            width: 10px !important;
        }
        ::v-deep .vfc-dayNames span {
            color: #7E8494 !important;
            margin-bottom: 0 !important;
            font-size: 13px;
        }
        ::v-deep .vfc-today:not(.vfc-marked) {
            background-color: transparent !important;
            color: #000 !important;
            border: 1px solid var(--fe-primary) !important;
        }
        ::v-deep .vfc-week {
            font-size: 13px;
        }
        ::v-deep .vfc-months-container {
            .vfc-content {
                width: 55% !important;
            }
        }

        ::v-deep .vfc-marked {
            background: #CCE2E9 !important;
        }

        ::v-deep .vfc-start-marked {
            background: #006C90 !important;
        }

        ::v-deep .vfc-end-marked {
            background: #006C90 !important;
        }

    }
</style>
