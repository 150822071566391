<template>
    <div class="flex-fill flex-column no-scroll">
        <!-- <div v-show="breadcrumbs.length > 1" class="manage-toolbar" color="white">
            <breadcrumb v-model="breadcrumbs" @click="go"/>
        </div> -->


        <div v-if="activePage.show == 'index'" style="overflow: scroll;" class="flex-fill flex-column">
            <general/>

            <fe-action-list
                :showSearch="false"
                :addButton="false"
                :editButton="false"
                @rowClick="go"
                :items="itemsWithCounts"
                :showFiltering="false"
                itemText="display"
            />
        </div>

        <incident-type-list v-else-if="activePage.show == 'types'" @click="setIncidentType" @manageCodesClick="manageCodes"/>

        <incident-type-card v-else-if="activePage.show == 'type' && selectedIncidentType" :incidentBehaviorTypeId="selectedIncidentType.id" @updated="loadData()"/>

        <incident-response-list v-else-if="activePage.show == 'responses'"/>

        <incident-response v-else-if="activePage.show == 'response'" :id="selectedResponse.id"/>

        <incident-location v-else-if="activePage.show == 'locations'"/>

        <incident-motivation v-else-if="activePage.show == 'motivations'"/>

        <incident-behavior v-else-if="activePage.show == 'codes'" :incidentBehaviorTypeId="selectedIncidentType.id"/>

        <incident-field v-else-if="activePage.show == 'fields'" :incidentBehaviorTypeId="selectedIncidentType.id"/>

        <incident-state-report v-else-if="activePage.show == 'state-reporting'"/>
    </div>
</template>

<script>
    import Vuex from 'vuex'
    import IncidentTypes from './IncidentType'
    import Breadcrumb from '@/components/common/Breadcrumb'
    import General from './General'
    import IncidentTypeList from './IncidentTypeList'
    import IncidentTypeCard from './IncidentTypeCard'
    import IncidentResponseList from './IncidentResponseList'
    import IncidentLocation from './IncidentLocation'
    import IncidentField from './IncidentField'
    import IncidentMotivation from './IncidentMotivation'
    import IncidentBehavior from './IncidentBehavior'
    import IncidentResponse from './IncidentResponse'
    import ActionList from '@/components/common/ActionList'
    import { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'

    export default {
        name: 'ManageIncident',
        components: {
            IncidentTypes,
            IncidentTypeCard,
            IncidentTypeList,
            IncidentResponseList,
            IncidentBehavior,
            IncidentResponse,
            IncidentLocation,
            IncidentField,
            IncidentMotivation,
            ActionList,
            General,
            Breadcrumb,
        },
        provide() {
            let me = this
            this.localStore = new Vuex.Store({
                state: {
                    selectedIncidentType: null,
                    selectedResponse: null,
                    incidentTypeCodes: []
                },
                mutations: {},
                actions: {}
            })

            return {
                localStore: this.localStore
            }
        },
        computed: {
            ...mapState('manage', ['incident', 'breadcrumbs']),
            ...mapState('global', [
                'sessionUser'
            ]),
            ...mapLocalState(['selectedIncidentType', 'selectedResponse']),
            activePage() {
                let me = this
                let b = this.breadcrumbs["Incidents"]

                let c = b[b.length-1]
                if (c.show === 'index') {
                    this.fetchCounts()
                }

                return c
            },
            incidentBreadcrumbs: {
                get() {
                    return this.breadcrumbs["Incidents"]
                },
                set(v) {}
            },
            itemsWithCounts() {
                let i = [...this.items]

                i.forEach((item) => {
                    switch (item.show) {
                        case 'types':
                            item.display = 'Types        <span style="margin-left: 20px; font-size: 12px;">' + this.counts.incident_behavior_types + '</span>'
                            break;
                        case 'locations':
                            item.display = 'Locations        <span style="margin-left: 20px; font-size: 12px;">' + this.counts.incident_locations + '</span>'
                            break;
                        case 'responses':
                            item.display = 'Responses        <span style="margin-left: 20px; font-size: 12px;">' + this.counts.incident_responses + '</span>'
                            break;
                        case 'motivations':
                            item.display = 'Motivations        <span style="margin-left: 20px; font-size: 12px;">' + this.counts.incident_motivations + '</span>'
                            break;
                    }
                })

                return i
            }
        },
        created() {
            // this.activePage = this.breadcrumbs[0]
            this.fetchCounts()
        },
        data() {
            return {
                show: 'types',
                counts: {},
                items: [{
                    name: 'Incident Types',
                    route: '/Manage/IncidentTypeList',
                    show: 'types'
                }, {
                    name: 'Locations',
                    route: '/Manage/IncidentLocation',
                    show: 'locations'
                }, {
                    name: 'Motivations',
                    route: '/Manage/IncidentLocation',
                    show: 'motivations'
                }, {
                    name: 'Responses',
                    route: '/Manage/IncidentResponses',
                    show: 'responses'
                }]
            }
        },
        methods: {
            fetchCounts() {
                this.$axios.get('qry.php?action=get&property=incident_configuration_counts')
                    .then(response => {
                        this.$data.counts = response.data.counts;


                    })
            },
            setIncidentType(item) {
                this.selectedIncidentType = item
                this.incidentBreadcrumbs.push({
                    name: item.name,
                    show: 'type'
                })
            },
            manageCodes(obj) {
                this.selectedIncidentType = obj.parent
                this.breadcrumbs.push({
                    name: obj.parent.name + ' Codes',
                    show: 'codes'
                })
            },
            go(to) {
                this.$store.commit('manage/addCrumb', {
                    key: "Incidents",
                    val: to
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

.manage-toolbar {
    padding-top: 26px;
    padding-bottom: 20px;

}

.active-page {
    text-decoration: underline;
    font-weight: bold !important;
}
</style>
