<template>
    <div class="d-flex flex-wrap wrapper" >
        <div class="card">
            {{ fieldRec.form_instance_name }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SmartInstanceNameField',
        props: ['data','fieldRec'],
        data() {
            return {}
        },
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        border: 1px solid #ced0d9;
        border-radius: 3px;
        min-height: 36px;
        line-height: 36px;
        padding: 2px;
        margin-bottom: 19px;
        background-color: white;
    }
    .card {
        padding: 0 8px;
    }
    .header {
        color: rgb(126,132,148);
        font-size: 12px;
    }
    .id {
        font-size: 13px;
    }
    .dim {
        opacity: .65;
    }
</style>
