import Api from '@/components/modules/thresholds/services/Api'

export default {
    namespaced: true,

    state: {
        demographics: []
    },

    mutations: {
        set(state, payload) {
            state[payload.state] = payload.value
        }
    },

    actions: {
        async load({commit, state}, payload) {
            if (!payload || !payload.ifEmpty || payload.ifEmpty && !state.demographics) {
                let demographicsResponse = await Api().get('crud.php?property=Demographic&action=get')
                let demographics = (demographicsResponse.data && demographicsResponse.data.demographic) || []
                let optionsResponses = await Promise.all(demographics
                    .filter(itm => itm.value_type === 'option')
                    .map(itm => Api().get(`crud.php?property=DemographicOption&action=get&demographic_id=${itm.id}`)))

                optionsResponses.forEach(rsp => {
                    if (rsp.data && rsp.data.demographic_option) {
                        rsp.data.demographic_option.forEach(option => {
                            demographics.forEach(demo => {
                                if (demo.id === option.demographic_id) {
                                    if (!demo.options) demo.options = []
                                    demo.options.push(option)
                                }
                            })
                        })
                    }
                })

                commit('set', {
                    state: 'demographics',
                    value: demographics
                })

                return demographics
            } else {
                return state.demographics
            }
        }
    },

    getters: {
        all: state => state.demographics
    }
}
