var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advanced-demographic-filters d-flex",class:{
        'flex-column': _vm.vertical,
        'flex-wrap': !_vm.vertical
    }},_vm._l((_vm.activeDemographics),function(demographic){return _c('advanced-demographic-filter',{key:`demographic-${demographic.id}`,class:{
            'pa-2': !_vm.vertical,
            'd-flex': _vm.vertical && !_vm.labelsOnTop,
            'justify-space-between': _vm.vertical,
            'flex-row': _vm.vertical && !_vm.labelsOnTop,
            'mx-2': _vm.labelsOnTop
        },style:({
            minWidth: _vm.vertical ? null : '250px',
            width: _vm.vertical ? '100%' : '20%'
        }),attrs:{"value":_vm.params[`demo_${demographic.id}`],"demographic":demographic,"labelOnTop":_vm.labelsOnTop,"vertical":_vm.vertical,"byId":_vm.byId,"resetEntry":_vm.resetEntry},on:{"result":function($event){return _vm.update(demographic, $event)}}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }