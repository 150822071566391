let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    meeting: {
        defaults: {
            endpoint: 'dashboardMeeting.php',
            rootProperty: 'dashboard_meetings'
        },
        ...standardCrud
    },
    userMeeting: {
        defaults: {
            endpoint: 'dashboardMeeting.php?property=user_meetings',
            rootProperty: 'dashboard_meetings'
        },
        ...standardCrud
    },
    meetingDate: {
        defaults: {
            endpoint: 'dashboardMeeting.php',
            rootProperty: 'dashboard_meetings'
        },
        ...standardCrud
    },
    meetingAttendance: {
        defaults: {
            endpoint: 'dashboardMeeting.php',
            rootProperty: 'dashboard_meeting_user_attendance',
            params: {
                property: 'attendance'
            }
        },
        ...standardCrud
    },
    meetingAgenda: {
        defaults: {
            endpoint: 'dashboardMeeting.php',
            rootProperty: 'dashboard_meeting_agendas',
            params: {
                property: 'agenda'
            }
        },
        ...standardCrud,
        destroy: {
            params: { action: 'destroy' }
        }
    },
    meetingStudent: {
        defaults: {
            endpoint: 'dashboardMeeting.php',
            rootProperty: 'dashboard_meeting_students',
            params: {
                property: 'students'
            }
        },
        ...standardCrud,
        destroy: {
            params: { action: 'destroy' }
        }
    },
    meetingUser: {
        defaults: {
            endpoint: 'dashboardMeeting.php',
            rootProperty: 'dashboard_meeting_users',
            params: {
                property: 'users'
            }
        },
        ...standardCrud,
        destroy: {
            params: { action: 'destroy' }
        }
    },
    meetingNote: {
        defaults: {
            endpoint: 'dashboardMeeting.php',
            rootProperty: 'dashboard_meeting_notes',
            params: {
                property: 'notes'
            }
        },
        ...standardCrud
    },
    meetingFormInstance: {
        defaults: {
            endpoint: 'dashboardMeeting.php',
            rootProperty: 'dashboard_meeting_form_instances',
            params: {
                property: 'dashboard_meeting_form_instance'
            }
        },
        ...standardCrud
    },
    meetingSchedule: {
        defaults: {
            endpoint: 'dashboardMeeting.php',
            rootProperty: 'dashboard_meeting_schedule',
            params: {
                property: 'schedule'
            }
        },
        syncToGoogleCalendar: {
            endpoint: 'googleCalendarIntegrate.php',
            params: {
                property: 'meeting',
                action: 'integrate',
            }
        },
        ...standardCrud
    },
}
