<template>
    <div>
        <v-form v-if="ready" ref="form" @submit.prevent v-model="isValid">
            <fe-label class="mt-0">Visualization Name</fe-label>
            <v-text-field
                v-model="config.name"
                counter=50
                :rules="this.$fieldValidators('text', null, {required: true, limit: 50})"
                flat solo dense clearable autofocus
            />

            <fe-label>Enter the Google Sheet URL</fe-label>
            <v-text-field
                v-model="config.url"
                placeholder="https://..."
                type="url"
                :rules="this.$fieldValidators('url', null, {required: true})"
                flat solo dense clearable
            />

            <fe-label>Starting Cell</fe-label>
            <v-text-field
                v-model="config.starting"
                placeholder="A1"
                :rules="this.$fieldValidators('text', null, {required: true, match: '^[A-Za-z]+[0-9]+$'})"
                flat solo dense clearable
            />

            <fe-label>Ending Cell or Column</fe-label>
            <v-text-field
                v-model="config.ending"
                placeholder="D or D9"
                :rules="this.$fieldValidators('text', null, {required: true, match: '^[A-Za-z]+[0-9]*$'})"
                flat solo dense clearable
            />

            <fe-remote-combo
                label="Sheet Name (Optional)"
                v-if="sheetNameOptions.length"
                v-model="config.sheetName"
                :items="sheetNameOptions"
            />

            <vis-type v-model="config.visualization" :numeric="false"/>

            <div class="d-flex">
                <fe-btn usage="primary" :disabled="!isValid" @click="loadSheet">
                    <v-icon class="mr-3">fas fa-table</v-icon>
                    Load Sheet
                </fe-btn>

                <v-checkbox
                    v-if="sheetLoaded && previewData"
                    v-model="config.flipGrouping"
                    label="Flip Grouping"
                    class="mt-3"
                    hide-details
                />
            </div>

            <div class="preview-wrapper">
                <div v-if="sheetLoaded && !previewData" class="no-data-message">
                    Specified Sheet and Range contains no data
                    <br>
                    Modify range or select a different sheet
                </div>
                <div v-else-if="sheetLoaded && previewData" style="height: 100%; width: 100%;">
                    <table-view
                        v-if="config.visualization === 'table'"
                        :data="previewData"
                        :config="config"
                    />
                    <highcharts
                        v-else
                        ref='highchart'
                        style="height: 100%; width: 100%;"
                        :options="previewData"
                    />
                </div>
            </div>

            <fe-label>Enter a Short Description</fe-label>
            <v-textarea
                v-model="config.description"
                counter=255
                rows=4
                :rules="this.$fieldValidators('text', null, {required: false, limit: 255})"
                flat solo dense no-resize
            />

            <display-settings :value="displaySettings" @change="displaySettings = $event"/>
        </v-form>
    </div>
</template>

<script>
import VisType from "./fields/VisType"
import DisplaySettings from "./fields/DisplaySettings"
import TableView from "../TableView"

export default {
    name: "GoogleSheet",

    components: {
        VisType,
        DisplaySettings,
        TableView
    },

    props: {
        dashboard: {
            type: Object,
            required: true
        },
        edit: {
            type: Object,
            required: false
        },
        setConfig: {
            type: Function,
            required: true
        }
    },

    data() {
        return {
            ready: false,
            isValid: false,
            config: {
                name: '',
                url: undefined,
                starting: 'A1',
                ending: undefined,
                sheetName: undefined,
                visualization: undefined,
                flipGrouping: false,
                yAxisMin: undefined,
                yAxisMax: undefined,
                description: '',
                config: {
                    type: 'googlesheet',
                    url: undefined,
                    starting: 'A1',
                    ending: undefined,
                    sheetName: undefined,
                    visualization: undefined,
                    flipGrouping: false,
                    yAxisMin: undefined,
                    yAxisMax: undefined,
                    description: ''
                }
            },
            sheetNameOptions: [],
            sheetLoaded: false,
            previewData: undefined,
            systemColors: [
                '#006699',
                '#F59B00',
                '#08916D',
                '#529EE0',
                '#52B7D8',
                '#D9A6C2',
                '#FFB03B',
                '#54A77B',
                '#E16032',
                '#4FD2D2',
                '#F0E442',
                '#E287B2'
            ]
        }
    },

    computed: {
        data() {
            if (!this.rawData) return {}
            return this.$buildChartFromSheet(this.rawData, this.config.flipGrouping)
        },

        displaySettings: {
            get() {
                return {
                    yAxisMin: this.config.yAxisMin,
                    yAxisMax: this.config.yAxisMax
                }
            },

            set(v) {
                Object.keys(v).forEach(x => {
                    if (this.config.hasOwnProperty(x)) {
                        this.config[x] = v[x]
                    }
                })
            }
        }
    },

    watch: {
        isValid(n, o) {
            if (n !== o) {
                this.updateValid()
            }
        },

        sheetLoaded(n, o) {
            if (n !== o) {
                this.updateValid()
            }
        },

        config: {
            deep: true,
            handler(v) {
                this.$emit('change', v)
            }
        },

        'config.sheetName': {
            handler() {
                if (this.ready && this.sheetLoaded) {
                    this.loadSheet()
                }
            }
        },

        'config.url': {
            handler(url) {
                let sheetId = url.match('/spreadsheets/d/([a-zA-Z0-9-_]+)')
                if (sheetId) {
                    let url = this.$models.getUrl('googleSheets', 'read') + 'sheets?spreadsheetId=' + sheetId[1]
                    this.$axios.get(url)
                        .then(res => {
                            if (res.data.length > 1) {
                                this.sheetNameOptions = res.data.map(item => {
                                    let name = item.properties.title
                                    return {
                                        id: name,
                                        name: name
                                    }
                                })
                            } else {
                                this.sheetNameOptions = []
                            }
                        })
                        .catch(err => {
                            this.sheetNameOptions = []
                            this.previewData = undefined
                            this.updateValid()
                            this.$snackbars.$emit('new', {
                                text: 'Invalid Google Sheet URL',
                                subtext: err.result.error.message,
                                usage: 'error'
                            })
                        })
                } else {
                    this.sheetNameOptions = []
                }
            }
        },

        'config.visualization': {
            handler() {
                if (this.sheetLoaded) this.doChart()
            }
        },

        'config.flipGrouping': {
            handler(n, o) {
                if (this.ready) {
                    this.doChart()
                }
            }
        }
    },

    mounted() {
        if (this.edit) {
            this.config = this.setConfig(this.config, this.edit)
            this.$nextTick(() => {
                this.loadSheet()
                this.ready = true
            })
        } else {
            this.ready = true
        }
    },

    methods: {
        updateValid() {
            this.$emit('valid', this.isValid && this.sheetLoaded && this.previewData)
        },

        loadSheet() {
            this.sheetLoaded = false
            this.previewData = undefined

            this.$fetchGoogleSheet(this.config)
                .then(res => {
                    this.sheetLoaded = true
                    if (Object.keys(res.sheetValues).length) {
                        this.rawData = res
                        this.doChart()
                    }
                })
        },

        doChart() {
            this.previewData = this.config.visualization === 'table' ? this.data : this.setChartOptions()
        },

        setChartOptions() {
            let data = this.data
            let type = 'column'
            let systemColors = this.systemColors
            let nextColor = 0
            let plotOptions = {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                },
                series: {
                    compare: 'percent'
                }
            }

            switch (this.config.visualization) {
                case 'trendline':
                    type = 'line'
                    plotOptions = {
                        series: {
                            label: {
                                connectorAllowed: false
                            }
                        }
                    }
                    break;
                case 'bar':
                    type = 'bar'
                    plotOptions = {
                        bar: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        },
                        series: {
                            compare: 'percent'
                        }
                    }
                    break;
            }

            let seriesArray = data.series.map(item => {
                if (!item.color) {
                    item.color = systemColors[nextColor]
                    nextColor++
                    if (nextColor >= systemColors.length) {
                        nextColor = 0
                    }
                }
                return item
            })

            return {
                chart: {
                    type: type,
                    zoomType: 'xy',
                    alignTicks: false
                },
                legend: {
                    align: 'center'
                },
                tooltip: {
                    useHTML: true,
                    formatter: function () {
                        let series = this.series
                        let point = this.point

                        let value = +point.y.toFixed(2)
                        let pct = ''

                        if (value === point.pct) {
                            value += '%'
                            pct = ' (' + +point.count.toFixed(2) + ')<br>'
                        } else if (point.pct) {
                            pct = ' (' + +point.pct.toFixed(2) + '%)<br>'
                        } else if (this.percentage) {
                            pct = ' (' + +this.percentage.toFixed(2) + '%)<br>'
                        }

                        return series.name + ': <b>' + value + '</b>' + pct
                    }
                },
                plotOptions: plotOptions,
                title: {
                    text: ''
                },
                xAxis: {
                    min: 0,
                    categories: data.categories
                },
                yAxis: {
                    reversedStacks: false,
                    startOnTick: false,
                    endOnTick: false,
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                series: seriesArray
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.preview-wrapper {
    position: relative;
    width: 100%;
    height: 300px;
    border: 1px solid #ced0d9;
    border-radius: 3px;
    margin-bottom: 24px;

    .no-data-message {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
    }
}
</style>
