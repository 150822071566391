<template>
    <div class="band-list-item text-muted">
        <v-layout row ma-0>
            <v-flex xs2>
                <ColorSelector
                    :value="band.color"
                    use-index
                    @change="v => updateBand({ color: v })"
                />
            </v-flex>

            <v-flex xs3>
                <v-text-field
                    id="label-input"
                    :class="`label-input ${ hasNameErrors ? 'invalid' : 'valid'}`"
                    :value="band.name"
                    flat solo filled
                    single-line
                    @input="v => updateBand({ name: v })"
                />
            </v-flex>

            <v-flex xs2 text-xs-right pr-1>
                From
            </v-flex>

            <v-flex xs2>
                <v-text-field
                    type="number"
                    id="from_value-input"
                    :class="`from_value-input ${ hasValueErrors ? 'invalid' : 'valid'}`"
                    :value="band.from_value"
                    flat solo filled
                    single-line
                    @input="v => updateBand({ from_value: v })"
                />
            </v-flex>
            <v-flex xs3 pl-1>
                <span
                    v-if="toValue"
                    class="text-no-wrap text-truncate to-value"
                >
                    to <strong>{{ adjustedToValue }}</strong>
                </span>

                <span v-else>
                    and up
                </span>
            </v-flex>
        </v-layout>

        <div class="metric-list-item-menu">
            <v-btn text icon @click="deleteBand" v-if="allowDelete">
                <v-icon>delete</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import ColorSelector from '../controls/ColorSelector'

export default {
    name: 'PerformanceBandRow',

    components: {
        ColorSelector
    },

    props: {
        band: Object,
        idx: Number,
        toValue: Number,
        errors: Object,
        allowDelete: Boolean
    },

    computed: {
        hasErrors() {
            return this.errors && (this.errors.value || this.errors.name)
        },

        hasNameErrors() {
            return this.errors && this.errors.name
        },

        hasValueErrors() {
            return this.errors && this.errors.value
        },

        adjustedToValue() {
            return (this.toValue) ? parseFloat(this.toValue - 0.1) : null
        }
    },

    methods: {
        ...mapActions('thresholds/group/performanceBand', [
            'remove',
            'update'
        ]),

        deleteBand() {
            this.remove({indexes: [this.idx]})
        },

        updateBand(params) {
            this.update(Object.assign({__idx: this.idx}, params))
        }
    }
}
</script>

<style lang="scss">
.band-list-item {
    .v-input.invalid {
        input {
            color: #f00;
        }
    }

    .to-value {
        display: inline-block;
        width: 100%;
        position: relative;
        top: 3px;
    }
}
</style>
