<template>
    <fe-stepper-filter
        :title="dataSetName" style="height:620px;"
        @apply="next" @close="$emit('close'); clearFilters()"
        :applyDisabled="!isNextValid"
        :applyBtnText="view.id == 'population' ? 'Next' : 'Save Data Set'"
    >
        <template #toolbar>
            <stepper-navigation :items="navs" v-model="view" :nextDisabled="!isNextValid"/>
        </template>

        <template #footer>
            <div class="d-flex" style="width: 100%;">
                <div class="mt-3 ml-4" v-html="searchDisplay"></div>
                <v-spacer/>
                <fe-btn v-if="view.id == 'population' && isSearchValid" usage="secondary" @click="emit">Save Data Set</fe-btn>
            </div>
        </template>

        <keep-alive>
            <template v-if="view.id=='population'">
                <student-stepper-panel
                    ref="studentStepper"
                    v-model="population"
                    :surfaceFirstSelections="surfaceFirstSelections"
                    byId
                    multipleYears
                    @selected="checkValid"
                    @searchDisplayChange="searchDisplayPopulation = $event"
                    @summaryChange="populationSummary = $event"
                />
            </template>

            <sub-category-stepper-panel
                v-model="assessment"
                :surfaceFirstSelections="surfaceFirstSelections"
                byId
                :allowScoreDetails="false"
                :showYears="false"
                multipleWindows
                @selected="checkValid"
                @searchDisplayChange="searchDisplayData = $event"
                @summaryChange="assessmentSummary = $event"
            />
        </keep-alive>
    </fe-stepper-filter>
</template>

<script>
    import * as Util from './Util'
    import StudentStepperPanel from '@/components/common/stepper/StudentStepperPanel'
    import SubCategoryStepperPanel from '@/components/common/stepper/SubCategoryStepperPanel'
    import IncidentStepperPanel from '@/components/common/stepper/IncidentStepperPanel'
    import MultiDataStepperPanel from '@/components/common/stepper/MultiDataStepperPanel'
    import StepperNavigation from './StepperNavigation'
    
    export default {
        name: 'LongitudinalStepper',
        components: {
            StudentStepperPanel,
            SubCategoryStepperPanel,
            IncidentStepperPanel,
            MultiDataStepperPanel,
            StepperNavigation
        },
        props: {
            savedSearchId: {
                type: Number,
                required: false
            },
            byId: {
                type: Boolean,
                default: true
            },
            requireSubcategory: {
                type: Boolean,
                default: false
            },
            value: {
                default: null
            },
            surfaceFirstSelections: {
                type: Boolean,
                default: false
            },
            dataSetName: {
                type: String,
                default: 'Data Set 1' // historically, Data Set 1 was a hard-coded title so let's preserve it if no prop explicitly given
            },
        },
        watch: {
            'view.id'(v, prevVal) {
                //if (prevValue == 'population') {
                //    this.formattedParams = this.$refs.studentStepper.getSubmitParams()
                //}
                if (prevVal == 'population') {
                    this.population = {
                        ...this.population,
                        ...this.$refs.studentStepper.getSubmitParams(true) // only want the filters - this.population holds school year, school et al. already
                    }
                }

                this.checkValid()
            },
            value: {
                handler(v) {
                    if (v) {
                        this.population = v.population
                        this.assessment = v.assessment
                    }
                },
                immediate: true
            }
        },
        data() {
            return {
                formattedParams: {},
                population: {
                    advancedFilters: {
                        student_active_flag: 1
                    },
                    school: [],
                    grade: []
                },
                assessment: {},
                view: {
                    id: ''
                },
                searchDisabled: true,
                allowNext: false,
                searchDisplayPopulation: '<b>Select School Years</b>',
                searchDisplayData: '<b>Select Assessment Group</b>',
                populationSummary: {},
                assessmentSummary: '',
            }
        },
        computed: {
            navs() {
                return [{
                    id: 'population',
                    name: 'Student Population'
                }, {
                    id: 'series1', // retain "series1" hard-coded as it looks like only an internal identifier/ref name (i.e. don't implement dataSetId as a prop)
                    name: 'Assessment'
                }]
            },
            dataSets() {
                return this.navs.slice(1)
            },
            formValid() {
                return false
            },
            schoolYearId() {
                return this.population.school_year_id
            },
            searchDisplay() {
                if (this.view.id == 'population') {
                    return this.searchDisplayPopulation
                } else {
                    return this.searchDisplayData
                }
            },
            isPopulationValid() {
                return (this.population?.school_year_id?.length && this.population?.school_id?.length && this.population?.grade_id?.length)
            },
            isDataValid() {
                return (this.assessment?.data_point_type_id && this.assessment?.sub_category_id && this.assessment?.data_point_name_id?.length)
            },
            isNextValid() {
                return (this.view.id == 'population' && this.isPopulationValid) || (this.view.id != 'population' && this.isSearchValid)
            },
            isSearchValid() {
                return this.isPopulationValid && this.isDataValid
            },
        },
        
        mounted() {
            this.view = this.navs[0]
            this.loadBase()
        },
        methods: {
            addDataSet() {
                let cnt = this.navs.length+1
                this.navs.push({
                    id: cnt,
                    name: 'Data Set ' + (cnt-1)
                })
            },
            checkValid(item) {
                this.$emit('input', {
                    population: this.population,
                    assessment: this.assessment
                })

                let sel = this.assessment
                let assmt = sel.assessment?.category_id?.length || sel.assessment?.data_point_type_id?.length

                if (this.view.id == 'population') {
                    this.searchDisabled = this.population?.school_year_id?.length > 0 ? false : true
                } else if (this.view.id == 'series1') {
                    this.searchDisabled = this.assessment?.sub_category_id ? false : true
                }
            },
            clearFilters() {
                this.population={}
                this.assessment={}
                this.incident={}
                this.view='population'
            },
            next() {
                switch (this.view.id) {
                    case 'population':
                        this.view = this.navs[1]
                        break
                    default:
                        this.emit()
                        break
                }
            },
            loadBase() {
                this.dialog=true
            },
            async emit() {
                    
                let p = { ...this.population }
                delete p.group_by

                let a = {
                    data_point_type_id: this.assessment?.data_point_type_id,
                    sub_category_id: this.assessment?.sub_category_id,
                    data_point_name_id: this.assessment?.data_point_name_id
                }

                let summaries = {
                    populationSummary: this.populationSummary,
                    assessmentSummary: this.assessmentSummary
                }

                this.$emit('done', {...p, ...a}, { ...p }, { ...a }, summaries)
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>