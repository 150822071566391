<template>
    <div class="d-flex flex-column flex-fill">
        <fe-grid
            ref="grid"
            :gridOptionOverrides="gridOptionOverrides"
            :columnDefs="columnDefs"
            displayDensity="small"
            :rowData="showFilters ? filteredScores : scores"
            style="height: 100%;"
            @cellValueChanged="updateScore"
            @selectionChanged="doSelection"
            :frameworkComponents="frameworkComponents"
            disableInlineCreate
            :showAddRowBtn="false"
            groupUseEntireRow
            :searchBar="false"
        >
            <template slot="left-toolbar-items">
                <fe-crud
                    :autoload="false"
                    ref="crud"
                    :config="$models.studentMonitorScore"
                    :readParams="allParams"
                    @read="v => scores = v"
                />

                <fe-btn
                    usage="secondary"
                    class="fe-grid-action-btn"
                    useIcon="fas fa-chevron-left"
                    @click="$emit('done')"
                    v-if="$listeners.done"
                >
                    Graph
                </fe-btn>
            </template>

            <template #after-search-toolbar-items>
                <div class="d-flex" v-if="showFilters">
                    <div>
                        <v-menu
                            ref="menu"
                            v-model="dateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn depressed class="date-filter" color="#E0E1E6" rounded v-on="on">
                                    {{ selectedDate ? selectedDate : 'Select Date'}}
                                    <v-icon class="pl-2">fa-caret-down</v-icon>
                                </v-btn>
                            </template>

                            <v-date-picker v-model="selectedDate" no-title scrollable @change="dateMenu=false">
                            </v-date-picker>
                        </v-menu>
                    </div>

                    <fe-filter-btn
                        :items="$_.uniqBy(scores, 'student_id')"
                        title="Students"
                        itemText="student_full_name"
                        itemValue="student_id"
                        :closeOnSelect="false"
                        v-model="selectedStudent"
                    />

                    <fe-filter-btn
                        :items="$_.uniqBy(scores, 'sub_category_id')"
                        title="Measures"
                        itemText="sub_category_name"
                        itemValue="sub_category_id"
                        :closeOnSelect="false"
                        v-model="selectedMeasure"
                    />
                </div>
            </template>

            <template v-slot:cellDialog="{cell}">
                <template v-if="cell.colDef.field === 'score_details'">
                    <div class="ma-5">
                        <div v-for="s in cell.data.score_details">
                            <fe-label>{{s.description}}</fe-label>
                            <v-text-field flat solo dense v-model="s.detail_value" @blur="saveDetail(cell.data)"/>
                        </div>
                    </div>
                </template>
            </template>

            <template #toolbar-items>
                <fe-dialog
                    :title="dateDialog.addDate ? 'Add Date' : 'Reschedule'"
                    v-if="dateDialog.show"
                    @accept="doReschedule"
                    @close="dateDialog.show=false"
                    persistent
                    dismissButtonText="Cancel"
                    acceptButtonText="Save"
                >
                    <fe-label>To Date</fe-label>
                    <fe-date-picker v-model="dateDialog.date"/>
                </fe-dialog>

                <v-slide-y-transition>
                    <div v-if="selections.length > 0" class="selection-toolbar d-flex">
                        <div class="mt-3 ml-5">{{selections.length}} Date{{ selections.length > 1 ? 's' : '' }} Selected</div>

                        <div class="ml-auto">
                            <v-btn text class="white--text" v-if="selections.length===1" @click="dateDialog={...addDate}">Add Date</v-btn>
                            <v-btn text class="white--text" v-if="selections.length" @click="dateDialog={...rescheduleDate}">Reschedule</v-btn>
                            <v-btn text class="white--text" v-if="selections.length" @click="deleteDate">Delete</v-btn>
                            <v-btn text class="white--text" @click="$refs.grid.gridApi.deselectAll()">Cancel</v-btn>
                        </div>
                    </div>
                </v-slide-y-transition>
            </template>
        </fe-grid>

        <div v-if="filteredScores.length === 0" class="empty-overlay">
            <fe-empty-state
                image="/images/empty-state-horn.png"
                header="No Assessments to Monitor"
                text="There are no assessments to monitor for this date range.  Try broadening your search criteria to find more data"
            ></fe-empty-state>
        </div>
    </div>
</template>

<script>
    import NumericEditor from '@/components/common/grid/NumericEditor.js'

    export default {
        name: 'StudentMonitorScores',
        components: {
            NumericEditor,
        },
        props: {
            studentMonitorPlanId: {
            },
            params: {
                default: () => { return {}}
            },
            showBackButton: {
                type: Boolean,
                default: false
            },
            showFilters: {
                type: Boolean,
                default: true
            },
        },
        watch: {
            selectedDate() {
                this.$refs.crud.read(this.allParams)
            }
        },
        computed: {
            allParams() {
                let p = {...this.params}
                if (this.selectedDate) p.schedule_date_occurrence = this.selectedDate

                if (this.studentMonitorPlanId) p.student_monitor_plan_id = this.studentMonitorPlanId
                return p
            },
            filteredScores() {
                if (this.selectedMeasure.included.length || this.selectedStudent.included.length) {
                    let arr = []
                    arr = this.scores.filter(r => {

                        if (this.selectedMeasure.included.length) {
                            let found = this.selectedMeasure.included.find(m=>m.sub_category_id === r.sub_category_id)

                            if (!found) return false
                        }

                        if (this.selectedStudent.included.length) {
                            let found = this.selectedStudent.included.find(m=>m.student_id === r.student_id)

                            if (!found) return false
                        }
                        return r
                    })

                    return arr
                }

                return this.scores
            },
        },
        data() {
            let me = this
            return {
                monitorRecord: {},
                detailRecord: {},
                dateMenu: false,
                selectedDate: null,
                selectedStudent: {
                    included: [],
                    excluded: []
                },
                selectedMeasure: {
                    included: [],
                    excluded: []
                },
                dateDialog: {
                    show: false,
                    date: me.$dayjs().format('MM/DD/YYYY'),
                    addDate: false
                },
                rescheduleDate: {
                    show: true,
                    date: me.$dayjs().format('MM/DD/YYYY'),
                    addDate: false
                },
                addDate: {
                    show: true,
                    date: me.$dayjs().format('MM/DD/YYYY'),
                    addDate: true
                },
                frameworkComponents: {
                    numericEditor: NumericEditor,
                },
                selections: [],
                scores: [],
                gridOptionOverrides: {
                    immutableData: true,
                    getRowNodeId: v => {
                        // Needs to be unique for each student, monitor plan, and separate days that could be moved between
                        return `${v.student_id}::${v.student_monitor_plan_id}::${v.schedule_date_id}`
                    },
                    groupDefaultExpanded: true,
                },
                columnDefs: [me.$grid.checkColumn(), {
                    headerName: 'Assessment',
                    field: 'sub_category_name',
                    rowGroup: true,
                    hide: true
                }, {
                    headerName: "Student",
                    field: "student_full_name",
                    editable: false,
                    flex: 1
                }, {
                    headerName: "Date",
                    field: "schedule_date_occurrence",
                    editable: false,
                    width: 150
                }, {
                    headerName: "Score",
                    field: "score",
                    editable: true,
                    width: 80,
                    // This field can either be a numeric input, or for assessments
                    // that use Score Display it can be a dropdown of Score Display values.
                    // ag-grid doesn't seem to offer a dynamic switching from row to row,
                    // so we have to use a custom framework that implements both possibilities.
                    cellEditorFramework: 'FeGridNumericInputChipSelectCombo',
                    cellEditorParams: v => {
                        let dataPointTypeId = v.data?.data_point_type_id
                        let subCategoryId = v.data?.sub_category_id
                        let isAlphaMap = me.isAlphaMap(dataPointTypeId, subCategoryId)

                        let params = {
                            type: isAlphaMap ? 'scoreDisplay' : 'numeric',
                            mode: 'edit',
                        }

                        if (isAlphaMap) {
                            params = {
                                ...params,
                                rowDataKey: v.rowIndex,
                                mode: 'edit',
                                items: me.formattedAlphaMapOfItm(v.data)?.maps || [],
                                keyProp: 'numeric_score',
                                labelProp: 'alpha_score',
                                disableChips: true,
                            }
                        }

                        return params
                    },
                    // Use custom cellRenderer that varies depending upon whether the row's assessment
                    // uses alpha maps / Score Display.  Ran into some troubles with valueGetter
                    // where the backend calls were sending the string value and getting forced (i.e. reset) into a 0
                    cellRenderer: v => {
                        let dataPointTypeId = v.data?.data_point_type_id
                        let subCategoryId = v.data?.sub_category_id
                        let isAlphaMap = me.isAlphaMap(dataPointTypeId, subCategoryId)

                        if (isAlphaMap) {
                            if (me.getAlphaMapOfItm(v.data)) {
                                let val = v.data.score
                                return (val !== null)
                                    ? me.getAlphaForNumeric(val, v.data)
                                    : val
                            }
                            return null
                        } else {
                            return v.data?.score
                        }
                    },
                    cellStyle: {
                        textAlign: 'right'
                    },
                    colId: 'score',
                }, {
                    headerName: "Errors",
                    field: "errors",
                    editable: true,
                    width: 80,
                    cellEditor: 'numericEditor',
                    cellStyle: {
                        textAlign: 'right'
                    }
                }, {
                    headerName: "Accuracy",
                    field: "accuracy",
                    editable: true,
                    width: 80,
                    cellEditor: 'numericEditor',
                    cellStyle: {
                        textAlign: 'right'
                    }
                }, {
                    headerName: "Upload ID",
                    field: "monitor_score_upload_id",
                    hide: true,
                    editable: false,
                    width: 80,
                    cellStyle: {
                        textAlign: 'right'
                    }
                }, {
                    headerName: 'Details',
                    field: 'score_details',
                    maxWidth: 110,
                    valueGetter: (v) => v.data && v.data.score_details ? v.data.score_details.length : '',
                    cellRendererFramework: "FeDialogColumn",
                    cellRendererParams: {
                        rowDataKey: "score_details",
                        parent: me
                    },
                    cellStyle(v) {
                        if (!v.value) {
                            return {
                                background: '#f7f7f7'
                            }
                        }
                    },
                    onCellClicked: function (v) {
                        if (v.data?.score_details) {
                            me.$refs.grid.setDialogCell(v)
                            me.detailRecord = v.data
                        } else {
                            me.$snackbars.$emit('new', {
                                text: 'Please configure Score Details',
                                usage: 'warning'
                            })
                        }
                    }
                }],
            }
        },
        mounted() {
            if (this.params.schedule_date_occurrence) {
                this.selectedDate = this.params.schedule_date_occurrence
            } else {
                this.$refs.crud.read()
            }
        },
        methods: {
            deleteDate() {
                this.$confirmDelete(this.selections, () => {
                    let recs=[]
                    this.selections.forEach(r => {
                        recs.push({
                            id: r.schedule_date_id,
                            occurrence: r.schedule_date_occurrence,
                            schedule_id: r.schedule_id
                        })
                    })
                    this.$axios.post('scheduleDate.php?action=delete', { schedule_date: recs })
                        .then(resp => {
                            this.$ecResponse(resp)
                        })
                        .finally(() => {
                            this.$refs.crud.read()
                            this.selections=[]
                        })
                })
            },
            doReschedule() {
                let recs = []

                if (this.dateDialog.addDate) {
                    recs = this.selections.map(r => { return { schedule_id: r.schedule_id, occurrence: this.dateDialog.date } })
                } else {
                    this.selections.forEach(r => {
                        recs.push({
                            id: r.schedule_date_id,
                            occurrence: this.dateDialog.date,
                            original_occurrence: r.schedule_date_occurrence,
                            schedule_id: r.schedule_id
                        })
                    })
                }

                this.$axios.post('scheduleDate.php?action='+(this.dateDialog.addDate?'create':'update'), { schedule_date: recs})
                    .then(r => {
                        this.$ecResponse(r)
                        this.$refs.crud.read()
                    })
                    .finally(() => { this.selections = [] })
            },
            doSelection() {
                this.selections = this.$refs.grid.gridApi.getSelectedRows()
            },
            updateScore(meta) {
                if (meta.newValue === meta.oldValue) return

                if(['score', 'errors'].includes(meta.colDef.field) && meta.data.stored_accuracy === null) {
                    meta.data.accuracy = null
                }

                // Briefly suspend editing on the grid; this is because if user tabs away
                // from "errors" and into "accuracy," then when the crud.update finishes and
                // receives auto-calculated accuracy data, our attempt to setDataValue on
                // that accuracy field will be ignored because it's an actively edited cell.
                let editedCells = this.$refs.grid.gridApi.getEditingCells()
                let editedCell = editedCells?.length ? editedCells[0] : null

                this.$refs.grid.gridApi.stopEditing()

                this.$refs.crud.update([meta.data]).then(r => {
                    let data = r.data.monitor_scores
                    if (data.length === 0) {
                        return
                    }

                    let index = this.scores.findIndex(itm => itm.composite_id === data[0].composite_id)
                    if (index >= 0) {
                        // This will be reactive.  Used to be a buggy cursor problem, but now we're
                        // using immutable/delta mode, so it's perfect
                        this.scores.splice(index, 1, {
                            ...this.scores[index],
                            accuracy: data[0].accuracy,
                            stored_accuracy: data[0].stored_accuracy,
                        })

                        // Now that we have updated the ag grid node with backend's auto calculations (if available),
                        // we should restore editing mode on the cell user had changed to (if any)
                        if (editedCell) {
                            // Wait till next tick so that accuracy is populated in ag grid
                            this.$nextTick(() => {
                                this.$refs.grid.gridApi.startEditingCell({
                                    rowIndex: editedCell.rowIndex,
                                    colKey: editedCell.column.colId,
                                })
                            })
                        }
                    }
                })
            },
            saveDetail(rec) {
                this.$refs.crud.update([rec]).then(r => {
                    this.$ecResponse(r, 'monitor_scores')
                })
            },
            getAlphaMapOfItm (item) {
                if (!item) return null
                let map = (this.$store.state.global.alphaMaps && item.data_point_type_id)
                    ? this.$store.state.global.alphaMaps.find(itm => itm.data_point_type_id == item.data_point_type_id)
                    : null
                return (map && map.exclusions.indexOf(item.sub_category_id) == -1) ? map : null
            },
            formattedAlphaMapOfItm (item) {
                return this.getAlphaMapOfItm(item)
                    ? Object.assign({}, this.getAlphaMapOfItm(item), { maps: this.getAlphaMapOfItm(item).maps.map(itm => {
                            return Object.assign({}, itm, { formatted_score: `${itm.numeric_score}` })
                        })})
                    : null
            },
            getAlphaForNumeric (numeric, item) {
                if (isNaN(numeric)) return numeric
                let val = Math.floor(numeric)
                let match = this.getAlphaMapOfItm(item) && this.getAlphaMapOfItm(item).maps.find(itm => itm.numeric_score == val)
                return  match ? match.alpha_score.toUpperCase() : numeric
            },
            isAlphaMap(dataPointTypeId, subCategoryId) {
                let globalAlpha = this.$_.cloneDeep(this.$store.state.global.alphaMaps)
                let map = (globalAlpha && dataPointTypeId)
                    ? globalAlpha.find(itm => itm.data_point_type_id == dataPointTypeId)
                    : null
                if (map?.exclusions.indexOf(subCategoryId) == -1) {
                    map.maps = map.maps.filter(itm => itm.id != dataPointTypeId)
                } else if (map?.exclusions.indexOf(subCategoryId) >= 0) {
                    // The given subcat/assessment is not configured to use Score Display, even though the assessment group may define some
                    return false
                }

                return (map?.maps.length > 0)
            }
        },
    }
</script>

<style lang="scss" scoped>
.selection-toolbar {
    width: 97%;
    position: absolute;
    left: 16px;
    height: 48px;
    background: #66A7BC;
    color: white;
    z-index: 10;
    border-radius: 4px;
}

.date-filter {
    background: #e5f0f4 !important;
    border: 1px solid #e5f0f4 !important;
    font-size: 14px;
    height: 36px;
    margin-top: 7px;

    &:hover {
        background: var(--fe-accent) !important;
    }
}

.empty-overlay {
    top: 40%;
    left: 20%;
    width: 60%;
    margin: 0px auto 0px auto;
    position: absolute;
    text-align: center;
    background: white;
}
</style>
