<template>
    <div class="pa-5" style="overflow: auto;">
        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.interventionType"
            @read="v => items = v"
        />

        <div class="d-flex">
            <fe-title-select
                fontColor="black"
                itemText="name"
                itemValue="id"
                placeholder="Select a Strategy"
                :items="strategies.filter(r => r.name != '').filter(r => isBehavior ? r.category_id == 4 : r.category_id != 4)"
                v-model="selectedRecord"
            ></fe-title-select>

            <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <fe-icon-btn class="ml-auto" v-on="on" useIcon="fas fa-ellipsis-v"/>
                </template>

                <v-list dense>
                    <v-list-item @click="renameDialog.show = true; renameDialog.name = selectedRecord.name; renameDialog.categoryId = selectedRecord.category_id">
                        <v-list-item-content>
                            Edit
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <fe-dialog
            title="Edit"
            v-if="renameDialog.show"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            @close="renameDialog.show=false; renameDialog.name = ''"
            @accept="selectedRecord.name = renameDialog.name; selectedRecord.category_id = renameDialog.categoryId; updateRec()"
            persistent
        >
            <fe-label>Skill Area</fe-label>
            <fe-remote-combo
                url="crud.php?action=get&property=Category"
                rootProperty="category"
                byId
                v-model="renameDialog.categoryId"
            />

            <fe-label>Name</fe-label>
            <v-text-field
                flat solo dense
                counter=50
                v-model="renameDialog.name"
                :rules="$fieldValidators('text', null, { required: true, limit: 50})"
            />
        </fe-dialog>

        <!-- CRUD -->
        <fe-crud
            v-if="isBehavior"
            ref="expectationCrud"
            :autoload="false"
            :config="$models.interventionTypeMonitor"
            @read="v => expectations = v"
            @confirmDestroy="crudConfirmDelete"
        />

        <fe-crud
            v-if="isBehavior"
            ref="periodCrud"
            :autoload="false"
            :config="$models.interventionTypePeriod"
            @read="v => periods = v"
            @confirmDestroy="crudConfirmDelete"
        />

        <fe-crud
            :autoload="false"
            ref="procedureCrud"
            :config="$models.interventionTypeProcedure"
            :readParams="crudReadParams"
            @read="v => procedures = v"
            @confirmDestroy="crudConfirmDelete"
        />

        <fe-crud
            :autoload="false"
            ref="attachmentCrud"
            :readParams="crudReadParams"
            :config="$models.interventionTypeAttachment"
            @read="v => attachments = v"
        />
        <!-- end CRUD -->


        <div v-if="selectedRecord" class="mt-5">
            <upload-attachment v-model="uploadDialog.show" :interventionTypeId="selectedRecord.id" @done="loadData"/>

            <v-dialog width="500" persistent v-model="procedureDialog.show" v-if="procedureDialog.show">
                <v-card>
                    <v-card-title>
                        Create Procedure
                    </v-card-title>
                    <v-card-text>
                        <model-form
                            idProperty="id"
                            @cancel="finishProcedure"
                            @done="finishProcedure"
                            :records="procedureDialog.records"
                            model="interventionTypeProcedure"
                            :fieldConfig="procedureFields"
                            submitButtonText="Save"
                        />
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog width="500" persistent v-model="expectationDialog.show" v-if="expectationDialog.show">
                <v-card>
                    <v-card-title>
                        Create Expectations
                    </v-card-title>
                    <v-card-text>
                        <model-form
                            idProperty="id"
                            @cancel="expectationDialog.show=false"
                            @done="expectationDialog.show=false; loadData()"
                            :records="expectationDialog.record"
                            model="interventionTypeMonitor"
                            :fieldConfig="expectationFields"
                            submitButtonText="Save"
                        />
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog width="500" persistent v-model="periodDialog.show" v-if="periodDialog.show">
                <v-card>
                    <v-card-title>
                        Create Period
                    </v-card-title>
                    <v-card-text>
                        <model-form
                            idProperty="id"
                            @cancel="periodDialog.show=false"
                            @done="periodDialog.show=false"
                            :records="periodDialog.record"
                            model="interventionTypePeriod"
                            :fieldConfig="periodFields"
                            submitButtonText="Save"
                        />
                    </v-card-text>
                </v-card>
            </v-dialog>

            <fe-dialog
                title="Manage Expectations"
                width="900"
                v-model="showExpectationBank"
                v-if="showExpectationBank"
                @close="showExpectationBank=false"
                @dismiss="showExpectationBank=false"
                :footer="false"
            >
                <expectation-bank/>
            </fe-dialog>

            <fe-dialog
                title="Manage Periods"
                width="900"
                v-model="showPeriodBank"
                v-if="showPeriodBank"
                @close="showPeriodBank=false"
                @dismiss="showPeriodBank=false"
                :footer="false"
            >
                <period-bank/>
            </fe-dialog>

            <div>
                <v-textarea
                    flat solo dense
                    :rules="$fieldValidators('text', 'Description', { required: false, limit: 5000 })"
                    counter="5000"
                    label="Description"
                    v-model="rec.description"
                    @change="updateRec"
                />
            </div>

            <div class="section-title">General</div>
            <v-row class="mt-10">
                <v-col class="d-flex" xs2>
                    <v-switch v-model="rec.active" @change="updateRec"/>
                    <fe-label>Active</fe-label>
                </v-col>

                <v-col class="d-flex" v-if="!isBehavior">
                    <fe-label class="mr-4">Restrict to Schools</fe-label>
                    <crud-select
                        :itemsModel="$models.school"
                        :crudModel="$models.interventionTypeSchool"
                        :crudModelReadParams="{ intervention_type_id: selectedRecord.id }"
                        :crudModelCreateParams="{ intervention_type_id: selectedRecord.id }"
                        crudItemValue="school_id"
                        itemText="name"
                        itemValue="id"
                        multiple
                    />
                </v-col>

                <v-col class="d-flex">
                    <fe-label class="mr-4">Default smartFORM</fe-label>
                    <crud-select
                        :itemsModel="$models.form"
                        :crudModel="$models.interventionTypeForm"
                        :crudModelReadParams="{ id: selectedRecord.id }"
                        :crudModelCreateParams="{ intervention_type_id: selectedRecord.id }"
                        crudItemValue="form_id"
                        itemText="name"
                        itemValue="id"
                        multiple
                    />
                </v-col>
            </v-row>

            <v-expansion-panels hover>
                <v-expansion-panel v-if="isBehavior">
                    <v-expansion-panel-header>Expectations ({{ expectations.length }})</v-expansion-panel-header>
                    <!-- <div class="mt-10 section-title">Expectations</div> -->
                    <v-expansion-panel-content>
                        <fe-grid
                            ref="expectationGrid"
                            class="ec-basic-grid"
                            domLayout="autoHeight"
                            :frameworkComponents="frameworkComponents"
                            :columnDefs="expectationColumnDefs"
                            :rowData="expectations"
                            :showToolbar="false"
                            @cellValueChanged="updateCrud('expectationCrud', ...arguments)"
                            minimal
                        />
                        <fe-btn class="mt-2" usage="tertiary" @click="addExpectation">Add Expectation</fe-btn>
                        <fe-btn class="mt-2" usage="secondary" @click="showExpectationBank=true">Manage Expectation Names</fe-btn>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="isBehavior">
                    <v-expansion-panel-header>Periods ({{ periods.length }})</v-expansion-panel-header>
                    <!-- <div class="mt-10 section-title">Expectations</div> -->
                    <v-expansion-panel-content>
                        <fe-grid
                            ref="periodGrid"
                            class="ec-basic-grid"
                            domLayout="autoHeight"
                            :frameworkComponents="frameworkComponents"
                            :columnDefs="periodColumnDefs"
                            :rowData="periods"
                            :showToolbar="false"
                            @cellValueChanged="updateCrud('periodCrud', ...arguments)"
                            minimal
                        />
                        <fe-btn class="mt-2" usage="tertiary" @click="addPeriod">Add Period</fe-btn>
                        <fe-btn class="mt-2" usage="secondary" @click="showPeriodBank=true">Manage Period Names</fe-btn>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>Procedures ({{ procedures.length }})</v-expansion-panel-header>
                    <v-expansion-panel-content>

                        <fe-grid
                            ref="procedureGrid"
                            class="ec-basic-grid"
                            :autoload="false"
                            domLayout="autoHeight"
                            :columnDefs="columnDefs"
                            :rowData="procedures"
                            :showToolbar="false"
                            @cellValueChanged="updateCrud('procedureCrud', ...arguments)"
                            minimal
                        />
                        <fe-btn class="mt-2" usage="tertiary" @click="addProcedure">Add Procedure</fe-btn>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>Attachments ({{ attachments.length }})</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <fe-grid
                            ref="attachmentGrid"
                            class="ec-basic-grid"
                            domLayout="autoHeight"
                            :columnDefs="attachmentColumnDefs"
                            :rowData="attachments"
                            :showToolbar="false"
                            minimal
                            @cellValueChanged="updateCrud('attachmentCrud', ...arguments)"
                        />
                        <fe-btn class="mt-2" usage="tertiary" @click="uploadDialog.show=true">Add Attachment</fe-btn>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script>
    import CrudSelect from '@/components/common/combo/CrudSelect'
    import UploadAttachment from './UploadAttachment'
    import ModelForm from '@/components/common/form/ModelForm'
    import NumericEditor from '@/components/common/grid/NumericEditor.js'
    import ExpectationBank from './ExpectationBank'
    import PeriodBank from './PeriodBank'

    export default {
        name: 'InterventionStrategy',
        components: {
            CrudSelect,
            UploadAttachment,
            ModelForm,
            ExpectationBank,
            PeriodBank
        },
        props: {
            id: {
                // required: true
            },
            type: {
                default: 'academic'
            }
        },
        watch: {
            id: {
                handler(v) {
                    // this.loadData()
                }
            },
            'expectationDialog.show'(v) {
                if (!v) this.$refs.expectationCrud.read(this.crudReadParams)
            },
            'periodDialog.show'(v) {
                if (!v) this.$refs.periodCrud.read(this.crudReadParams)
            },
            selectedRecord(v) {
                this.loadData()
            }
        },
        computed: {
            rec() {
                return this.selectedRecord ? this.selectedRecord : false
            },
            crudReadParams() {
                return this.selectedRecord ? { intervention_type_id: this.selectedRecord.id } : {}
            },
            isBehavior() {
                return this.$props.type == 'academic' ? false : true
            },
            columnDefs() {
                let me = this
                return [{
                    field: 'name',
                    headerName: 'Name',
                    flex: 1,
                    editable: true
                }, {
                    field: 'description',
                    headerName: 'Description',
                    flex: 1,
                    editable: true
                }, {
                    field: 'rank',
                    headerName: 'Order',
                    width: 70,
                    editable: true
                }, this.$grid.toggleColumn('Active', 'active', null, () => {

                }), this.$grid.deleteColumn((meta) => {
                    me.$confirmDelete(meta.data, () => {
                        me.$refs.procedureCrud.destroy(meta.data)
                            .then(() => {
                                me.loadData()
                            })
                    })
                })]
            },
            periodColumnDefs() {
                let me = this
                return [{
                    field: 'monitor_period_name',
                    headerName: 'Period',
                    minWidth: 300,
                    flex: 1
                }, {
                    field: 'rank',
                    headerName: 'Order',
                    minWidth: 70,
                    maxWidth: 90,
                    editable: true
                },
                    this.$grid.toggleColumn('Required', 'require_flag', null, () => {}, { minWidth: 110, maxWidth: 110 }),
                    this.$grid.toggleColumn('Active', 'active', null, () => {}, { minWidth: 110, maxWidth: 110 })
                ]
            },
            attachmentColumnDefs() {
                let me = this
                return [{
                    field: 'name',
                    headerName: 'Name',
                    flex: 1,
                    editable: false
                }, {
                    field: 'created_by_full_name',
                    headerName: 'Uploaded By',
                    flex: 1,
                    editable: false
                }, this.$grid.deleteColumn((meta) => {
                    me.$confirmDelete(meta.data, () => {
                        me.$refs.attachmentCrud.destroy(meta.data)
                            .then(() => {
                                me.loadData()
                            })
                    })
                }), this.$grid.iconColumn('View', 'fas fa-download', (v) => {
                    me.$downloadFile('interventionTypes.php?action=get&property=file&intervention_type_id='+me.selectedRecord.id+'&id='+v.data.id,
                        v.data.name
                    )
                })]
            },
            expectationColumnDefs() {
                let me = this
                return [{
                    field: 'expectation_name',
                    headerName: 'Name',
                    flex: 1
                }, {
                    headerName: 'Min Score',
                    field: 'lowest_score',
                    editable: true,
                    width: 60,
                    cellEditor: 'numericEditor'
                }, {
                    headerName: 'Max Score',
                    field: 'highest_score',
                    editable: true,
                    width: 60,
                    cellEditor: 'numericEditor',
                },  this.$grid.toggleColumn('Enforce Range', 'force_score_range', 'Score must be within the min/max range', () => {

                }),
                    this.$grid.toggleColumn('Required', 'require_flag', null, () => {}, { minWidth: 110, maxWidth: 110 }),
                    this.$grid.toggleColumn('Include in Total', 'total_incl_flag', null, () => {}, { minWidth: 110, maxWidth: 110 }),
                    this.$grid.toggleColumn('Active', 'active', null, () => {}, { minWidth: 110, maxWidth: 110 }),
                    this.$grid.deleteColumn((meta) => {
                        me.$confirmDelete(meta.data, () => {
                            me.$refs.expectationCrud.destroy(meta.data).then(() => me.loadData())
                        })
                    })
                ]
            },
            expectationFields() {
                let me = this
                return [{
                    field: 'sub_category_id',
                    label: 'Expectation',
                    width: '100%',
                    component: 'fe-remote-combo',
                    validatorKey: 'select',
                    validatorConfig: { required: true },
                    attrs: {
                        items: me.expectationOptions,
                        byId: true
                    }
                }, {
                    field: 'lowest_score',
                    label: 'Minimum Score',
                    width: '50%',
                    validatorKey: 'number',
                    component: 'v-text-field',
                    validatorConfig: { required: true, min: 0, max: 99 },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true,
                        type: 'number'
                    }
                }, {
                    field: 'highest_score',
                    label: 'Maximum Score',
                    width: '50%',
                    component: 'v-text-field',
                    validatorKey: 'number',
                    validatorConfig: { required: true, min: 0, max: 99 },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true,
                        type: 'number'
                    }
                }, {
                    field: 'force_score_range',
                    label: 'Enforce Range',
                    width: '50%',
                    component: 'fe-switch'
                }, {
                    field: 'require_flag',
                    label: 'Required',
                    width: '50%',
                    component: 'fe-switch'
                }, {
                    field: 'total_incl_flag',
                    label: 'Include in Total',
                    width: '50%',
                    component: 'fe-switch'
                }, {
                    field: 'active',
                    label: 'Active',
                    width: '50%',
                    component: 'fe-switch'
                }]
            },
            periodFields() {
                let me = this

                return [{
                    field: 'monitor_period_id',
                    label: 'Period',
                    width: '100%',
                    component: 'fe-remote-combo',
                    validatorKey: 'select',
                    validatorConfig: { required: true },
                    attrs: {
                        url: me.$models.getUrl('monitorPeriod', 'read'),
                        rootProperty: 'periods',
                        // items: me.periodOptions,
                        byId: true
                    }
                }, {
                    field: 'require_flag',
                    label: 'Required',
                    width: '50%',
                    component: 'fe-switch'
                }, {
                    field: 'active',
                    label: 'Active',
                    width: '50%',
                    component: 'fe-switch'
                }]
            }
        },
        data() {
            return {
                renameDialog: {
                    show: false,
                    name: '',
                    categoryId: null
                },
                showExpectationBank: false,
                showPeriodBank: false,
                selectedRecord: null,
                strategies: [],
                periods: [],
                items: [],
                procedures: [],
                attachments: [],
                expectations: [],
                expectationDialog:{
                    show: false,
                    record: null
                },
                periodDialog: {
                    show: false,
                    record: null
                },
                expectationOptions: [],
                uploadDialog: {
                    show: false
                },
                pdf: {
                    show: false,
                    data: null,
                    viewType: null
                },
                frameworkComponents: {
                    numericEditor: NumericEditor
                },
                procedureDialog: {
                    show: false,
                    records: []
                },
                procedureFields: [{
                    field: 'name',
                    label: 'Name',
                    width: '100%',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    width: '100%',
                    required: true,
                    validatorConfig: { limit: 100, required: true },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true
                    }
                }, {
                    field: 'description',
                    label: 'Description',
                    width: '100%',
                    component: 'v-textarea',
                    validatorKey: 'text',
                    width: '100%',
                    required: true,
                    validatorConfig: { limit: 500, required: false },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true
                    }
                }]
            }
        },
        mounted() {
            // Get all the types for academic/behavior
            this.$modelGet('interventionType', { academic: !this.isBehavior, behavior: this.isBehavior })
                .then(r => {
                    this.strategies = r

                    this.selectedRecord = this.strategies.find(s => s.id === this.$props.id)
                })
        },
        methods: {
            addExpectation() {
                let me = this
                this.$modelGet('behaviorMonitor').then(data => {
                    this.expectationOptions = data
                    this.expectationDialog.show = true
                    this.expectationDialog.record = [{
                        intervention_type_id: this.selectedRecord.id,
                        active: true,
                        total_incl_flag: true,
                        force_score_range: true,
                        require_flag: true,
                        active: true,
                        rank: this.expectations.length+1
                    }]
                })
            },
            addPeriod() {
                let me = this
                this.$modelGet('monitorPeriod').then(data => {
                    me.periodOptions = data
                    this.periodDialog.show = true
                    this.periodDialog.record = [{
                        intervention_type_id: this.selectedRecord.id,
                        monitor_period_id: null,
                        require_flag: true,
                        active: true,
                        rank: this.periods.length+1
                    }]
                })
            },
            saveExpectation() {
                this.$refs.expectationCrud.create(this.expectationDialog.record)
                    .then(() => {
                        // this.loadData()
                        this.$refs.expectationCrud.read()
                    })
            },
            updateCrud(ref, meta) {
                if (meta.oldValue != meta.newValue) {
                    this.$refs[ref].update([meta.data]).then(() => {
                        this.$refs[ref].read(this.crudReadParams)
                    })
                }
            },
            updateRec() {
                this.$refs.crud.update( this.selectedRecord )
                    .then(() => {   })
            },
            loadData() {
                if (!this.selectedRecord?.id) return

                this.$refs.procedureCrud.read(this.crudReadParams)
                this.$refs.attachmentCrud.read(this.crudReadParams)
                if (this.isBehavior) {
                    this.$refs.expectationCrud.read(this.crudReadParams)
                    this.$refs.periodCrud.read(this.crudReadParams)
                }

            },
            addProcedure() {
                this.procedureDialog.records = [{
                    intervention_type_id: this.selectedRecord.id,
                    rank: this.procedures.length+1
                }]
                this.procedureDialog.show=true
            },
            crudConfirmDelete(rsp, resolve, reject) {
                this.$confirmDelete([1], () => {
                    resolve()
                }, () => {
                    reject()
                }, rsp.data.msg)
            },
            finishProcedure() {
                this.procedureDialog.show = false
                this.$refs.procedureCrud.read(this.crudReadParams)
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
