<template>
    <TabPane
        emptyTitle="Create an Email Notification"
        emptyBody="To get started, add an email notification."
        emptyButton="Add an Email notification"
        :pending="pending"
        :error="error"
        :count="count"
        @emptyClick="addEmail"
    >

        <template slot="icon">
            <h3 class="card__title card__title--group">Manage Email Notifications</h3>
        </template>

        <template slot="buttons">
            <fe-btn usage="ghost" :disabled="!success && !error" text icon @click="reset">
                <v-icon>refresh</v-icon>
            </fe-btn>
        </template>

        <template slot="content">
            <fe-grid
                ref="grid"
                domLayout="autoHeight"
                :showToolbar="false"
                :columnDefs="columnDefs"
                :rowData="rowData"
                @rowDataChanged="onSelectionChanged"
                @selectionChanged="onSelectionChanged"
            />

            <fe-btn class="mt-5" usage="tertiary" @click="addEmail">Add an Email Notification</fe-btn>
        </template>

        <template slot="modals">
            <DeleteFooter
                modelName="email notification"
                :selectedIndexes="selectedIndexes"
                :items="rowData"
                @selectAll="() => $refs.grid.selectAll()"
                @deselectAll="() => $refs.grid.deselectAll()"
                @deleteSelection="removeEmails"
            />
        </template>
    </TabPane>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import TabPane from '../templates/TabPane'
import DeleteFooter from '../templates/DeleteFooter'

export default {
    name: 'Email',

    components: {
        TabPane,
        DeleteFooter
    },

    data() {
        return {
            selectedIndexes: [],
            emailTypes: [{
                text: "Include Students",
                value: "itemized"
            }, {
                text: "Summary Only",
                value: "summarized"
            }]
        }
    },

    computed: {
        ...mapGetters('thresholds/group/email', [
            'collection',
            'clear',
            'pending',
            'success',
            'error',
            'hasDirtied',
            'hasRemoved',
            'hasAdded',
            'hasChanges',
            'rowData',
            'count'
        ]),

        rows() {
            return this.rowData ? this.rowData : []
        },

        thresholdGroupId() {
            return this.$store.getters['thresholds/group/selected'].id
        },

        columnDefs() {
            return [
                {headerName: null, headerCheckboxSelection: true, checkboxSelection: true, width: 70, minWidth: 70},
                {
                    headerName: 'Email',
                    field: 'email_addr',
                    sortable: true,
                    editable: true,
                    cellEditorFramework: 'FeGridTextField',
                    cellEditorParams: {
                        placeholder: 'someone@somewhere.com',
                        type: 'email',
                        required: true
                    },
                    onCellValueChanged: this.updateCell
                },
                {
                    headerName: 'Email Type',
                    field: 'email_type',
                    sortable: true,
                    editable: true,
                    cellRendererFramework: 'FeGridChipSelect',
                    cellRendererParams: {
                        rowDataKey: 'email_type',
                        mode: 'render',
                        items: this.emailTypes,
                        keyProp: 'value',
                        labelProp: 'text'
                    },
                    cellEditorFramework: 'FeGridChipSelect',
                    cellEditorParams: {
                        rowDataKey: 'email_type',
                        mode: 'edit',
                        items: this.emailTypes,
                        keyProp: 'value',
                        labelProp: 'text'
                    },
                    onCellValueChanged: this.updateCell,
                    cellStyle: {padding: 0}
                },
                {headerName: 'Created', field: 'created', sortable: true}
            ]
        },

        hasSelection() {
            return (this.selectedIndexes.length > 0)
        }
    },

    watch: {
        clear: (v) => {
            if (v) this.reset()
        }
    },

    mounted() {
        this.reset()
    },

    updated() {
        if (this.clear) this.reset()
    },

    methods: {
        ...mapActions('thresholds/group/email', [
            'refresh',
            'update',
            'add',
            'remove',
            'reconcile'
        ]),

        onSelectionChanged(params) {
            this.selectedIndexes = (params.api)
                ? params.api.getSelectedRows().map(itm => itm.__idx)
                : []
        },

        updateCell(params) {
            this.update(params.data)
            this.$refs.grid.resize()
        },

        addEmail() {
            this.add([{
                email_addr: '',
                threshold_group_id: this.thresholdGroupId
            }])
        },

        removeEmails() {
            this.remove({indexes: this.selectedIndexes})
            this.selectedIndexes = []
        },

        reset() {
            this.refresh()
            this.selectedIndexes = []
        },

        save() {
            this.reconcile()
        }
    }
}
</script>
