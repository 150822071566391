<template>
    <div class="d-flex flex-fill flex-column pa-5">
        <div>
            <div v-if="!table" class="d-flex">
                <fe-btn  small icon usage="ghost" useIcon="fas fa-chevron-left" @click="$router.push('/ExportManager')"/>
                <div class="section-title" style="margin-top: 10px;">Export smartForms</div>
            </div>

            <div v-else class="d-flex">
                <fe-btn small icon usage="ghost" useIcon="fas fa-chevron-left" @click="params = { data_id: [] }; dataIds = []; table=false;"/>
                <div class="section-title" style="margin-top: 10px;">Export Parameters</div>
            </div>
        </div>

        <v-form v-if="!table">
            <fe-remote-combo
                label="School Year"
                :url="$models.getUrl('schoolYear', 'read')"
                rootProperty="years"
                v-model="params.school_year_id"
                byId
            />

            <fe-remote-combo
                url="form.php?property=form&action=get"
                label="Form Template"
                rootProperty="forms"
                v-model="params.form_id"
                byId
                @change="loadDataIds"
                :disabled="!params.school_year_id"
            />

            <selection-list :items="dataIds" style="height: 300px" v-model="params.data_id" multiple identifier="data_id">
                <template #row="{item}">
                    {{item.data_id}}
                </template>
            </selection-list>

            <div class="text-right">
                <fe-btn usage="primary" @click="submit" :disabled="params.data_id.length===0">View</fe-btn>
                <fe-btn usage="primary" @click="submitCSV" :disabled="params.data_id.length===0">Download</fe-btn>
            </div>
        </v-form>

        <div v-else class="d-flex flex-fill flex-column">
            <fe-grid
                :rowData="gridData"
                :columnDefs="columns"
                :gridOptionOverrides="gridOptions"
                displayDensity="small"
                style="height: 100%;"
                :showAddRowBtn="false"
                ref="grid"
                @selectionChanged="selections=$refs.grid.gridApi.getSelectedRows()"
                @rowDoubleClicked="loadSmartForm"
                suppressFieldDotNotation
            >
                <template #toolbar-items>
                    <fe-btn usage="secondary" :disabled="selections.length===0" @click="tagStudents">Tag</fe-btn>
                </template>
            </fe-grid>
        </div>
    </div>
</template>

<script>
    import SelectionList from '@/components/common/SelectionList'

    export default {
        name: 'ExportSmartForms',
        components: { SelectionList },
        data() {
            return {
                params: {
                    data_id: []
                },
                dataIds: [],
                gridData: [],
                columns: [],
                table: false,
                selections: [],
                gridOptions: {
                    suppressFieldDotNotation: true,
                }
            }
        },
        methods: {
            tagStudents() {
                let me = this
                let students = this.selections.map(x => ( {student_id: x['Student ID']} ))
                this.$store.commit('global/tagStudents', {
                    show: true,
                    students: students,
                    callback() {
                        me.$refs.grid.gridApi.deselectAll()
                    }
                })
            },
            loadDataIds() {
                this.$axios.get('form.php?property=data_id&action=get&form_id='+this.params.form_id)
                    .then(r => this.dataIds = r.data.data_id || [])
            },
            loadSmartForm(meta) {
                this.$dockableWindow({
                    name: meta.data['Form Name'],
                    data: meta.data,
                    component: 'smart-form',
                    attrs: {
                        id: meta.data.ID
                    },
                })
            },
            submitCSV() {
                let p = {...this.params}
                p.data_id = this.params.data_id.map(p=>p.data_id)
                this.$setLoading(true)
                this.$axios.post('formResponse.php?action=get&property=export&csv=1', p, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/csv'
                    },
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'smartFormsExport.csv'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }).catch((error) => console.error(error))
                .finally(() => { this.$setLoading(false) })
                //this.$axios.get(')

            },
            submit() {
                this.columns = [this.$grid.checkColumn(null, true)]
                this.gridData = []
                this.table=true
                let p = {...this.params}
                p.data_id = this.params.data_id.map(p=>p.data_id)
                this.$setLoading(true)
                this.$axios.post('formResponse.php?action=get&property=export', p)
                    .then((response) => {
                        this.gridData = response.data.form_instances

                        if (this.gridData.length>0) {
                            let first = this.gridData[0]

                            Object.keys(first).forEach(col => {
                                this.columns.push({
                                   headerName: col,
                                   field: col,
                                   editable: false,
                                   minWidth: 100
                                })
                            })
                        }
                    }).catch((error) => console.error(error))
                    .finally(() => { this.$setLoading(false) })
                //this.$axios.get(')

            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
