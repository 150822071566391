<template>
    <v-form v-model="isValid">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <h3>Goals</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <fe-remote-combo
                        :value="selectedGoalTypeId"
                        @input="$emit('changeGoalType', $event)"
                        :items="goalTypeItems"
                        byId
                        :rules="[v => !!v || 'Please select a type']"
                    />
                </v-col>
            </v-row>

            <template v-if="goals && goals.length && goals[0].slo_goal_type_id == 2">
                <v-container v-for="(goal, goalIndex) in goals" :key="goalIndex">
                <v-row>
                    <v-col cols="6">% of students</v-col>
                    <v-col cols="6">
                        <v-text-field
                            :value="goal.goal"
                            @input="$emit('updateAt', 'goals', goalIndex, { goal: parseFloat($event) })"
                            type="number"
                            flat solo dense validateOnBlur
                            :rules="[v => !!v || 'This field is required']"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">in</v-col>
                    <v-col cols="6">
                        <fe-remote-combo
                            :value="goal.target_descriptor_id"
                            readonly
                            :clearable="false"
                            :items="assessmentDescriptors"
                            byId
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">will achieve</v-col>
                    <v-col cols="6">
                        <fe-remote-combo
                            :value="goal.goal_target_descriptor_id"
                            @input="$emit('updateAt', 'goals', goalIndex, { goal_target_descriptor_id: parseInt($event) })"
                            :items="assessmentDescriptors"
                            byId
                            :rules="[v => !!v || 'This field is required']"
                        />
                    </v-col>
                </v-row>
                </v-container>
            </template>

            <template v-if="goals && goals.length && goals[0].slo_goal_type_id == 5">
                <v-row>
                    <v-col cols="6">Baseline</v-col>
                    <v-col cols="6">
                        <v-text-field
                            :value="goals && goals.length && goals[0].baseline"
                            type="number"
                            readonly
                            flat solo dense validateOnBlur
                            :rules="[v => !!v || 'This field is required']"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">Goal</v-col>
                    <v-col cols="6">
                        <v-text-field
                            :value="goals && goals.length && goals[0].goal"
                            @input="$emit('updateAt', 'goals', 0, { goal: parseFloat($event) })"
                            type="number"
                            flat solo dense validateOnBlur
                            :rules="[v => !!v || 'This field is required']"
                        />
                    </v-col>
                </v-row>
            </template>
        </v-container>
    </v-form>
</template>

<script>
    export default {
        name: 'SloCreationDatawallGoals',
        components: {
        },
        props: {
            dataPointTypeId: { type: Number, required: true },
            targetSetId: { type: Number, required: false },
            selectedGoalTypeId: { type: Number, required: true },
            goals: { type: Array, required: true },
            averageScore: { type: String, required: true }, // string, because it comes from toFixed(2)
            canCreateTargetAchievementGoals: { type: Boolean, required: true },
        },
        data() {
            return {
                isValid: false,
                assessmentDescriptors: [],
            }
        },
        computed: {
            goalTypeItems() {
                if (this.canCreateTargetAchievementGoals) {
                    return [{ id: 2, name: 'Target Achievement' }, { id: 5, name: 'Average Score' }]
                } else {
                    return [{ id: 5, name: 'Average Score' }]
                }
            },
        },
        async mounted() {
            if (this.targetSetId) {
                this.assessmentDescriptors = await this.$axios.get(
                    this.$models.getUrl('targetSetDescriptor', 'read') + `&target_set_id=${this.targetSetId}`
                ).then(res => res.data.descriptors.filter(itm => itm.target_type_id === 1))
            }
        },
        watch: {
            isValid(value) {
                this.$emit('validate', value)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .col {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
</style>