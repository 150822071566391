<template>
    <div class="d-flex flex-fill flex-column" v-if="iframe.app" :style="{padding: padding+'px'}">
        <iframe
            :src="iframe.src"
            frameborder="0"
            style="overflow:hidden;height:100%;width:100%;"
            height="100%"
            width="100%"
            ref="iframe"
            @load="registerEvents"
        ></iframe>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'IFrameRenderer',
        props: {
            url: '',
            app: {},
            urlParams: {},
            cfg: {
                default() { return { } }
            },
            padding: {
                default: 0
            }
        },
        watch: {
            app(v) {
                this.loadApp()
            },
            uris(v) {
                this.loadApp()
            }
        },
        data() {
            return {
                tstamp: null,
                src: null,
                iframe: {},
                activeApp: null,
                checkMessage: null
            }
        },
        computed: {
            ...mapState('global', ['sessionUser', 'uris'])
        },
        mounted() {
            this.src = null
            this.loadApp()
        },
        beforeDestroy() {
            this.unregisterEvents()
        },
        methods: {
            doTest() {
                let me = this
                let iframeCw = this.$refs.iframe.contentWindow
                let msgData = JSON.stringify({action:'parentclose'})
                iframeCw.postMessage(msgData, me.iframe.src)
            },
            registerEvents(evt) {
                let me = this
                let iframe = this.iframe
                let cfg = this.iframe.cfg
                let src = this.iframe.src

                let checkMessage = function(e) {
                    // checkMessage function being
                    // ec got event.  origin is:  https://qa01.educlimber.app
                    // Session URI AchievementDashboard/

                    // checkMessage function being
                    // ec got event.  origin is:  https://app.educlimber.com
                    // Session URI AchievementDashboard/
                    let appOrigin
                    try {
                        appOrigin = new URL(src).origin
                    } catch (e) {
                        // relative url strings
                        appOrigin = src
                    }

                    if (e.origin.replace(/\/$/, '') == appOrigin.replace(/\/$/, '') || e.origin.endsWith('.educlimber.app') || e.origin.endsWith('.educlimber.com')) {
                        let eventData

                        if(typeof e.data === 'string' || e.data instanceof String) {
                            if (e.data.charAt(0) === '!') {
                                console.warn('skipping invalid e.data in VueLoader',e.data)
                                return
                            }
                            try {
                                // parse the string to get object because our stuff should only be objects
                                eventData = JSON.parse(e.data)
                            } catch (err) {
                                // someone sent a non object...ignore it since we shouldn't be doing that with
                                // our vue apps but log what it was
                                console.warn('unrecognised event to VueLoader: ',e);
                                return
                            }
                        } else {
                            // here we have an object
                            eventData = e.data
                        }

                        if (eventData.identifier && eventData.identifier == cfg.identifier && eventData.action) {
                            for (let eventName in cfg.events) {
                                if (eventData.action === eventName) {
                                    try {
                                        cfg.events[eventName](JSON.parse(eventData.data))
                                    } catch (err) {
                                        cfg.events[eventName](false) // still throw the event, as the caller may not be expecting a response
                                    }

                                }
                            }
                        }
                    }
                }
                this.checkMessage = checkMessage
                window.addEventListener("message", this.checkMessage, true)
            },
            unregisterEvents() {
                let me = this
                window.removeEventListener('message', me.checkMessage, me.tstamp)
            },
            loadApp() {
                let cfg = this.$props.cfg
                let app = this.$props.app
                let f = this.iframe
                this.tstamp = this.$props.cfg.identifier || new Date().getMilliseconds()
                let appendUrl = (cfg.path ? cfg.path : '') + '?' + this.$objectToParams(this.$props.cfg.params)
                appendUrl += (appendUrl.indexOf('?') == -1 ? '?' : '&') + 'tstamp='+this.tstamp
                let host = this.uris[app]

                if (app === 'achievement_dashboard') {
                    let server = location.hostname
                    if (server.indexOf('.com') != -1) {
                        host = 'https://app.educlimber.com/AchievementDashboard/'
                    } else if (server.indexOf('.app') != -1) {
                        host = 'https://qa01.educlimber.app/AchievementDashboard/'
                    }
                }

                let src = this.$_.isBlank(this.url) ? host + appendUrl : this.url

                this.iframe = {
                    app: app,
                    src: src,
                    cfg: this.cfg
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
