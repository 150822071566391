export default {
    subcategoryAlias: {
        rules: [
            r => (r && r.alias_cd && r.alias_cd.trim().length > 0) || { alias_cd: 'Alias is required' }
        ],
        defaults: {
            endpoint: 'subcategories.php',
            rootProperty: 'subcategories',
            params: {
                property: 'alias'
            }
        },
        read: {
            endpoint: 'subcategories.php',
            rootProperty: 'subcategories',
            params: {
                action: 'get',
                show_all: 1
            }
        },
        create: {
            endpoint: 'crud.php',
            rootProperty: 'sub_category_alias',
            params: {
                property: 'SubCategoryAlias',
                action: 'create'
            }
        },
        update: {
            endpoint: 'crud.php',
            rootProperty: 'sub_category_alias',
            params: {
                property: 'SubCategoryAlias',
                action: 'update'
            }
        },
        destroy: {
            endpoint: 'subcategories.php',
            rootProperty: 'subcategories',
            params: {
                action: 'delete'
            }
        }
    }
}