<template>
    <div>
        <template v-if="savedSearches.length">
            <div style="margin-top: 10px;" v-for="(ss, i) in savedSearches" :key="`saved-search-`+i">
                <fe-chart
                    v-if="!hideChart[ss.savedSearchId]"
                    :name="ss.name"
                    :series="getSeries(ss)"
                    type="line"
                    legend="bottom"
                    :xAxisCategories="ss.categories"
                    :extraConfigs="chartOptions"
                    yAxisLabel="Score"
                ></fe-chart>
                <div v-else class="highcharts-title" style="color:rgb(126, 132, 148);">
                    {{ss.name}}
                </div>

                <v-data-table
                    :headers="ss.headers"
                    :items="ss.tableData"
                    class="ec-table"
                    hide-default-footer
                    dense
                    disable-pagination
                >
                    <template v-slot:items="props">
                        <tr>
                            <td v-for="(c, ci) in ss.headers" :key="`ss-table-`+ ci">
                                {{props.item[c.value]}}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </template>
        <div v-else class="empty-editor">
            {{fieldRec.name}}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ScoreField',
        inject: ['localStore'],
        data() {
            return {
                categories: [],
                series: [],
                hideChart: {},
                chartOptions: {
                    plotOptions: {
                        series: {
                            fillOpacity: 0.1
                        },
                        line: {
                            marker: {enabled: undefined}
                        }
                    },
                    xAxis: {
                        min: undefined,
                        labels: {
                            rotation: 0
                        }
                    },
                    yAxis: {
                        endOnTick: false,
                        min: undefined,
                        max: undefined
                    }
                }
            }
        },
        props: {
            data: {},
            fieldRec: {},
        },
        created() {
            this.savedSearches = []

            if (!this.data)
            return

            this.data.forEach((ss) => {
                this.hideChart[ss.saved_search_id] = ((ss.args.CHART_DISPLAY_OPTIONS ? ss.args.CHART_DISPLAY_OPTIONS[0] : null) == 'hidechart')

                if (ss.results.length > 0) {
                    let cfg = {
                        name: ss.sub_category_name,
                        savedSearchId: ss.saved_search_id,
                        series: [],
                        categories: [],
                        headers: [],
                        tableData: ss.results
                    }

                    // build the table
                    ss.columns.forEach((col) => {
                        if (!col.hidden)
                            cfg.headers.push({
                                text: col.text,
                                value: col.dataIndex == 'score' ? 'score_display' : col.dataIndex
                            })
                    })

                    let ignore = ['window']
                    ss.series.forEach((ser) => {
                        ignore.push(ser.name)
                    })

                    ss.chart_fields.forEach((field) => {
                        if (ignore.indexOf(field) == -1) {
                            cfg.categories.push(field)
                        }
                    })

                    // build the series
                    ss.series.forEach((ser) => {
                        if (ser.student) ser.type = 'area'
                        ser.data = []
                        cfg.categories.forEach((cat) => {
                            let score = null

                            if(ss.results.length) {
                                this.fieldRec.value = 1
                            }

                            ss.results.forEach((rec) => {
                                let tmpScore = parseFloat(rec[ser.name])

                                if (!this.chartOptions.yAxis.min || tmpScore < this.chartOptions.yAxis.min) {
                                    this.chartOptions.yAxis.min = tmpScore
                                }

                                if (!this.chartOptions.yAxis.max || tmpScore > this.chartOptions.yAxis.max) {
                                    this.chartOptions.yAxis.max = tmpScore
                                }
                                if (rec.window === cat) {
                                    score = tmpScore
                                }
                            })

                            if(ser.data.length < 10) this.chartOptions.plotOptions.line.marker.enabled = true
                            ser.data.push(parseInt(score))
                        })
                    })
                    cfg.series = ss.series
                    this.savedSearches.push(cfg)
                }

            })

        },
        methods: {
            getSeries(ss) {
                // debugger
                return ss.series
            }
        }
    }
</script>

<style lang="scss" scoped>
.ec-table {
    margin: 8px 0px 0px 0px;
}
</style>
