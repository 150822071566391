<template lang="html">
    <div class="flex-fill flex-column">
        <v-btn v-show="activeLayout" fab fixed @click="activeLayout=null" class="back" >
            <v-icon>arrow_back</v-icon>
        </v-btn>

        <v-card v-if="!activeLayout" v-for="x in layouts" @click="activeLayout=x" :keys="x" hover class="pa-4 ma-2" >
            {{x.name}}
        </v-card>

        <component v-if="activeLayout" :is="activeLayout" />
    </div>
</template>

<script>
import Vuex from 'vuex'
import { mapLocalState } from '@/util/vuexHelper'
import BasicLayout from './components/BasicLayout'
import ToolbarSidebarContent from './components/ToolbarSidebarContent'
import SidebarToolbarContent from './components/SidebarToolbarContent'
import LeftMenuLayout from './components/LeftMenuLayout'
import GridScroll from './components/GridScroll'
import GridWithPageScroll from './components/GridWithPageScroll'

export default {
    name: 'LayoutTemplate',

    computed: {
        ...mapLocalState(['activeLayout','layouts'])
    },

    provide() {
        this.localStore = new Vuex.Store({
            state: {
                activeLayout: null,
                layouts: [
                    BasicLayout,
                    ToolbarSidebarContent,
                    SidebarToolbarContent,
                    GridScroll,
                    GridWithPageScroll,
                    LeftMenuLayout
                ],
                someText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
            }
        })

        return { localStore: this.localStore }
    },

}
</script>

<style lang="scss" scoped>
.back {
    // transform: translate(10px,-10%);
}
.red {
    background-color: red;
}
.green {
    background-color: green;
}
.blue {
    background-color: blue;
}
.block {
    display: block;
    padding: 10px;
    margin: 8px;
    background-color: #557c83;
}
.content {
    width: 100%;
    height: 100%;
}
</style>
