<template>
    <div @click="onSortRequested(lastSort, $event)">
        <div class="score-header-wrapper d-flex" style="position: relative; z-index: 1;">
            <div v-html="params.displayName"></div>
            <div ref="menuButton" @click.stop="onMenuClicked($event)" class="ml-1">
                <i class="fas fa-bars" style="color: #979797;"></i>
            </div>
            <v-spacer/>
            <div v-if="ascSort == 'active' || descSort == 'active'">
                <i v-if="ascSort == 'active'" class="fas fa-arrow-up" style="color: #979797;"/>
                <i v-if="descSort == 'active'" class="fas fa-arrow-down" style="color: #979797;"/>
            </div>
            <div v-if="params.showScoreFilters" class="additional-options">
                <v-menu v-model="isScoreFilterMenuVisible" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <i v-on="on" :class="`fas fa-filter ml-1 ${ params.isFilterActive ? 'persistent ' : ''}`" style="color: #979797;"/>
                    </template>

                    <v-card>
                        <v-card-text v-if="params.filterType == 'numeric'" class="pa-4">
                            <fe-label>{{ params.filterValueName || 'Score' }} Greater Than</fe-label>
                            <div class="d-flex align-center">
                                <v-text-field
                                    class="flex-grow-1"
                                    v-model="filterValues.greaterThan"
                                    prepend-inner-icon="fas fa-greater-than"
                                    clearable
                                    hide-details
                                />
                                <v-menu v-if="params.alphaMaps" offset-y>
                                    <template v-slot:activator="{ on }">
                                        <fe-icon-btn v-on="on" useIcon="fas fa-ellipsis-v"/>
                                    </template>

                                    <v-list class="alpha-maps-list">
                                        <v-list-item v-for="itm in params.alphaMaps.maps" :key="itm.id" @click="filterValues.greaterThan = itm.alpha_score.toUpperCase()">
                                            {{ itm.alpha_score }}
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                            <fe-label :class="{ 'mb-2': !greaterThanSubtext, 'mb-4': !!greaterThanSubtext }" v-html="greaterThanSubtext"/>

                            <fe-label>{{ params.filterValueName || 'Score' }} Less Than</fe-label>
                            <div class="d-flex align-center">
                                <v-text-field
                                    class="flex-grow-1"
                                    v-model="filterValues.lessThan"
                                    prepend-inner-icon="fas fa-less-than"
                                    clearable
                                    hide-details
                                />
                                <v-menu v-if="params.alphaMaps" offset-y>
                                    <template v-slot:activator="{ on }">
                                        <fe-icon-btn v-on="on" useIcon="fas fa-ellipsis-v"/>
                                    </template>

                                    <v-list class="alpha-maps-list">
                                        <v-list-item v-for="itm in params.alphaMaps.maps" :key="itm.id" @click="filterValues.lessThan = itm.alpha_score.toUpperCase()">
                                            {{ itm.alpha_score }}
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                            <fe-label :class="{ 'mb-2': !lessThanSubtext, 'mb-4': !!lessThanSubtext }" v-html="lessThanSubtext"/>

                            <v-divider style="background-color:#E0E1E6"/>

                            <fe-label>{{ params.filterValueName || 'Score' }} Equals</fe-label>
                            <div class="d-flex align-center">
                                <v-text-field
                                    class="flex-grow-1"
                                    v-model="filterValues.equals"
                                    prepend-inner-icon="fas fa-equals"
                                    clearable
                                    hide-details
                                />
                                <v-menu v-if="params.alphaMaps" offset-y>
                                    <template v-slot:activator="{ on }">
                                        <fe-icon-btn v-on="on" useIcon="fas fa-ellipsis-v"/>
                                    </template>

                                    <v-list class="alpha-maps-list">
                                        <v-list-item v-for="itm in params.alphaMaps.maps" :key="itm.id" @click="filterValues.equals = itm.alpha_score.toUpperCase()">
                                            {{ itm.alpha_score }}
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                            <fe-label :class="{ 'mb-2': !equalsSubtext, 'mb-4': !!equalsSubtext }" v-html="equalsSubtext"/>

                            <div class="d-flex">
                                <v-spacer/>
                                <fe-btn usage="primary" @click="doApply">Apply</fe-btn>
                            </div>
                        </v-card-text>

                        <v-card-text v-else-if="params.filterType == 'text'" class="pa-4">
                            <fe-label>Text Contains</fe-label>
                            <div class="d-flex align-center">
                                <v-text-field
                                    class="flex-grow-1"
                                    v-model="filterValues.contains"
                                    prepend-inner-icon="fas fa-search"
                                    clearable
                                    hide-details
                                />
                            </div>

                            <div class="d-flex">
                                <v-spacer/>
                                <fe-btn usage="primary" @click="doApply">Apply</fe-btn>
                            </div>
                        </v-card-text>

                        <v-card-text v-else-if="params.filterType == 'interventionLevel'" class="pa-4">
                            <fe-grid
                                :frameworkComponents="frameworkComponents"
                                ref="interventionLevelsGrid"
                                style="width: 400px; height: 400px;"
                                :showToolbar="false"
                                :columnDefs="interventionLevelColumnDefs"
                                :rowData="interventionLevelItems"
                                :bordered="false"
                                @gridReady="onInterventionLevelGridReady"
                                @selectionChanged="onInterventionLevelSelectionChanged"
                            />

                            <div class="mt-5">
                                <fe-switch v-model="isOnlyIncludeFinishedSelected" label="Only include completed interventions"/>
                            </div>

                            <div class="d-flex">
                                <fe-btn usage="ghost" @click="onClearInterventionLevelSelection" :disabled="!selectedInterventionLevel.length">Clear</fe-btn>
                                <v-spacer/>
                                <fe-btn color="primary" @click="doApply">Apply</fe-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-menu>
            </div>
        </div>
        <div v-if="params.displaySubtitle" class="subtitle">
            {{ params.displaySubtitle }}
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import ColoredSquare from '../renderers/ColoredSquare'
import IconHeader from '../renderers/IconHeader'
import { mapState } from 'vuex'

export default Vue.extend({
    name: 'ScoreHeader',
    data: function () {
        return {
            isScoreFilterMenuVisible: false,
            ascSort: null,
            descSort: null,
            noSort: null,
            lastSort: 'inactive',
            filterValues: {
                greaterThan: null,
                lessThan: null,
                equals: null,
                contains: null,
            },
            selectedInterventionLevel: [],
            isOnlyIncludeFinishedSelected: false,
            frameworkComponents: {
                coloredSquare: ColoredSquare,
                iconHeader: IconHeader,
            },
        };
    },
    mounted() {
        switch (this.params.filterType) {
            case 'numeric':
                this.filterValues = {
                    greaterThan: null,
                    lessThan: null,
                    equals: null,
                }
                break
            // For intervention status, we are ultimately performing a text search
            // against the string status value (e.g. int_level_orange), but
            // multiple intervention levels may be selected
            case 'interventionLevel':
                this.filterValues = {
                    interventionLevels: null,
                }
                break
            case 'text':
                this.filterValues = {
                    contains: null,
                }
                break
            default:
                this.filterValues = {}
        }

        // Load in any existing values from previous filter executions
        this.filterValues = {
            ...this.filterValues,
            ...this.params.filterValues,
        }

        this.params.column.addEventListener('sortChanged', this.onSortChanged);
        this.onSortChanged();
    },
    computed: {
        ...mapState('global', ['shareableStores']),
        greaterThanSubtext() {
            return this.generateScoreFilterValueSubtext(this.filterValues.greaterThan)
        },
        lessThanSubtext() {
            return this.generateScoreFilterValueSubtext(this.filterValues.lessThan)
        },
        equalsSubtext() {
            return this.generateScoreFilterValueSubtext(this.filterValues.equals)
        },
        interventionLevelItems() {
            return this.shareableStores?.intervention_levels || []
        },
        interventionLevelColumnDefs() {
            let columnDefs = [
                {
                    ...this.$grid.checkColumn(),
                    width: 70,
                },
                {
                    headerName: "",
                    xheaderClass: 'intv-hands-header',
                    headerComponent: 'iconHeader',
                    headerComponentParams: {
                        iconClass: 'far fa-hands-heart',
                    },
                    width: 70,
                    minWidth: 70,
                    maxWidth: 70,
                    field: "color",
                    sortable: false,
                    cellRenderer: 'coloredSquare',
                    cellRendererParams: {
                    },
                    cellStyle: {
                        // Default ag grid is to ellipsis, and the colored button (i.e. no text anyway) is triggering ellipsis (!)
                        'text-overflow': 'clip',
                        // Kind of sucks, but better than fighting ag grid all day for a fixed-width column
                        'transform': 'translate(-8px, 0)',
                    },
                },
                {
                    headerName: "Intervention Level",
                    field: "name",
                    sortable: false,
                },
            ]

            return columnDefs
        },
    },
    methods: {
        convertAlphaMapToNumericValue(value) {
            // If the value is stringy, see if it's a known alpha map
            // and set subtext to the numeric equivalent
            let row = this.params.alphaMaps?.maps?.find(itm => itm.alpha_score.toUpperCase() == `${value}`.toUpperCase())
            if (row) {
                return row.numeric_score
            }

            return null
        },

        convertNumericValueToAlphaMap(value) {
            // If the value is numeric, see if we can convert it to an alpha map score
            if (!isNaN(parseFloat(value))) {
                let row = this.params.alphaMaps.maps.find(itm => itm.numeric_score == Math.floor(value))
                if (row) {
                    return row.alpha_score.toUpperCase()
                }
            }

            return null
        },

        // Helper function to generate an appropriate subtext for a field,
        // if the value entered correlates with an existing score display
        generateScoreFilterValueSubtext(value) {
            if (value === null || value === '') {
                return ''
            } else if (!this.params.alphaMaps) {
                return ''
            } else if (this.params.alphaMaps.exclusions.includes(this.params.subCategoryId)) {
                return ''
            } else {
                let v = this.convertAlphaMapToNumericValue(value)
                if (v) {
                    return `Numeric Value: ${v}`
                }

                v = this.convertNumericValueToAlphaMap(value)
                if (v) {
                    return `Displays As: ${v}`
                }
            }

            return ''
        },

        onInterventionLevelGridReady(params) {
            params.api.sizeColumnsToFit()
        },

        onInterventionLevelSelectionChanged(grid) {
            this.selectedInterventionLevel = grid.api.getSelectedRows()
        },

        onClearInterventionLevelSelection() {
            this.$refs.interventionLevelsGrid.gridApi.deselectAll()
            this.selectedInterventionLevel = []
        },

        onMenuClicked() {
            this.params.showColumnMenu(this.$refs.menuButton);
        },

        onSortChanged() {
            this.ascSort = this.descSort = this.noSort = 'inactive';
            if (this.params.column.isSortAscending()) {
                this.ascSort = 'active';
            } else if (this.params.column.isSortDescending()) {
                this.descSort = 'active';
            } else {
                this.noSort = 'active';
            }
        },

        onSortRequested(order, event) {
            switch(order) {
                case 'inactive':
                    order='asc'
                    break
                case 'asc':
                    order = 'desc'
                    break
                case 'desc':
                    order = 'inactive'
                    break
            }

            this.params.setSort(order, event.shiftKey);
            this.lastSort = order
        },

        doApply() {
            if (this.params.onApplyFilters) {
                let filterValues = { ...this.filterValues }

                // Intervention levels are selected from the fe-grid,
                // and we'll push them into the filterValues object here on submit
                if (this.params.filterType == 'interventionLevel') {
                    filterValues.interventionLevels = this.selectedInterventionLevel?.map(l => l.icon)

                    // icon name magically arrived with _ended at the end when it's a completed/closed/whatever intervention
                    if (this.isOnlyIncludeFinishedSelected) {
                        filterValues.interventionLevels = filterValues.interventionLevels.map(s => s + '_ended')
                    }
                }

                // Manual cleanup for html inputs that have data entered and then backspaced
                for (let key of Object.keys(filterValues)) {
                    switch (this.params.filterType) {
                        case 'numeric':
                            if (filterValues[key] === null || filterValues[key] === '') {
                                filterValues[key] = null
                            } else if (!isNaN(parseFloat(filterValues[key]))) {
                                // We leave the html inputs as stringy, so we must manually cast them into ints
                                filterValues[key] = parseFloat(filterValues[key])
                            } else {
                                // Stringy values are only allowed if they directly correspond to a score display/alpha map
                                let v = this.convertAlphaMapToNumericValue(filterValues[key])
                                if (v !== null) {
                                    filterValues[key] = v
                                } else {
                                    // Unable to translate, so we'll ignore it here
                                    filterValues[key] = null
                                }
                            }
                            break

                        case 'text':
                            if (filterValues[key] === null || filterValues[key] === '') {
                                filterValues[key] = null
                            }
                            break

                        case 'interventionLevel':
                            if (!filterValues[key].length) {
                                filterValues[key] = null // No intv level selected, no filtering to occur
                            }
                            break

                        default:
                            break
                    }
                }

                this.params.onApplyFilters(this.params.field, this.params.filterType, filterValues)

                // Programmatically dismiss the v-menu cause user has committed the filtering action
                this.isScoreFilterMenuVisible = false
            }
        },
    }
})
</script>

<style lang="scss" scoped>
.additional-options {
    visibility: hidden;
}
    .score-header-wrapper:hover .additional-options {
        visibility: visible;
    }

.alpha-maps-list {
    max-height: 350px;
    overflow: auto;
}

.persistent {
    visibility: visible !important;
}

.square {
    height: 24px;
    width: 24px;
    border-radius: 2px;
}

.selected-color {
    border-bottom: 2px #111 solid;
}
</style>