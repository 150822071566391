<template>
    <div style="position: relative;" :class="{bordered: border}">
        <slot></slot>

        <div class="fe-chart-toolbar d-flex">
            <slot name="tools">
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ChartPanel',
        props: {
            border: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                
            }
        },
    }
</script>

<style lang="scss" scoped>
.fe-chart-toolbar {
    position: absolute;
    top: 0px;
    right: 0px;
}

.bordered {
    border: 1px solid #dddddd;
    border-radius: 4px;
}

</style>