<template>
    <v-layout row fill-height>
        <fe-stepper-checkbox-panel
            v-if="showYears && localValue"
            :items="schoolYears"
            :surfaceFirstSelections="surfaceFirstSelections"
            byId
            v-model="localValue.school_year_id"
            :filterItemsCount="0"
        />
        <v-divider vertical v-if="showYears" style="background-color:#E0E1E6"/>

        <template v-if="localValue">
            <div class="col-12 pa-0 fill-height">
                <assessment-group-checkbox-panel
                    v-model="localValue"
                    :surfaceFirstSelections="surfaceFirstSelections"
                    @selectCategories="$emit('category-selected', null, $event)"
                    @selectAssessmentGroups="$emit('type-selected', null, $event)"
                    @selectAssessments="$emit('subCategorySelected', null, $event)"
                    @selectWindows="$emit('windowSelected')"
                    @searchDisplayChange="$emit('searchDisplayChange', $event)"
                />
            </div>
        </template>
    </v-layout>
</template>

<script>
    import AssessmentGroupCheckboxPanel from '@/components/common/stepper/AssessmentGroupCheckboxPanel'

    export default {
        name: 'AssessmentStepperPanel',
        components: {
            AssessmentGroupCheckboxPanel,
        },
        props: {
            value: {},
            surfaceFirstSelections: {
                type: Boolean,
                default: false
            },
            byId: {
                type: Boolean,
                default: true
            },
            showYears: {
                type: Boolean,
                default: false
            },
            schoolYearId: {
                type: Number,
                default: null
            },
        },
        watch: {
            localValue(v) {
                this.$emit('input', v)
            }
        },
        data() {
            return {
                schoolYears: [],
                localValue: null,
            }
        },
        mounted() {
            this.localValue = this.$_.cloneDeep(this.value)
            this.loadBase()
        },
        methods: {
            loadBase() {
                this.$modelGet('schoolYear').then(r => {
                    this.schoolYears = r
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
.selected-item {
    background: #f5f6f8;
    padding: 4px 8px 4px 8px;
    margin: 4px 0px 4px 0px;
    &-name {
        overflow: hidden;
        white-space: nowrap;
        margin-right: 8px;
    }
    
}
</style>