import BaseService from '@/components/modules/thresholds/services/BaseService'
import BaseStore from './Base'

export default Object.assign({}, BaseStore.default(), {
    namespaced: true,

    getters: Object.assign({}, BaseStore.default().getters, {
        __config() {
            return {
                service: BaseService,
                params: {active: 1},
                opts: {type: 'users'}
            }
        },

        collection: state => state.collection.map((row, idx) => Object.assign({}, row, {
            __fullName: `${row.lname}, ${row.fname}`,
            __idx: idx,
            __dirtied: state.dirtied.includes(idx),
            __removed: state.removed.includes(idx),
            __added: state.added.includes(idx)
        })),
    })
})
