<template>
    <div class="d-flex flex-fill flex-column" style="padding: 16px;">

        <div class="d-flex">
            <fe-btn chip usage="ghost" useIcon="fas fa-chevron-left" @click="$router.replace('/ImportManager')"/>
            <div class="page-title">{{ record.name }}</div>
        </div>

        <fe-dialog
            v-if="assignDialog.show"
            title="Assign Fields"
            width=600
            acceptButtonText="Assign"
            @accept="quickAssign"
            @dismiss="assignDialog.show=false"
        >
            <fe-label>Value Identifier Type</fe-label>
            <fe-remote-combo
                :items="identifierTypes"
                itemText="name"
                itemValue="id"
                byId
                v-model="assignDialog.record.value_import_identifier_type_id"
            />

            <fe-label>Name Identifier Type</fe-label>
            <fe-remote-combo
                :items="identifierTypes"
                itemText="name"
                itemValue="id"
                byId
                v-model="assignDialog.record.name_import_identifier_type_id"
            />

            <fe-label>Parent</fe-label>
            <fe-remote-combo
                :items="originalItems"
                itemText="name"
                itemValue="id"
                byId
                v-model="assignDialog.record.link_import_identifier_id"
            />

        </fe-dialog>

        <!-- Add Headers Dialog -->
        <fe-dialog
            v-if="headersDialog.show"
            title="Add Headers"
            width=600
            acceptButtonText="Create"
            @accept="setHeaders"
            @dismiss="headersDialog.show=false"
        >
            <fe-label class="d-flex align-center">
                <span>Comma or Tab Separated Text</span>
                <fe-info-tip class="ml-2" tooltip="Separator will default to tabs when the text includes tabs."/>
            </fe-label>
            <v-textarea
                flat solo dense
                v-model="headersDialog.record.name"
            />

            <fe-label>Value Identifier Type</fe-label>
            <fe-remote-combo
                :items="identifierTypes"
                itemText="name"
                itemValue="id"
                byId
                v-model="headersDialog.record.value_import_identifier_type_id"
            />

            <fe-label>Name Identifier Type</fe-label>
            <fe-remote-combo
                :items="identifierTypes"
                itemText="name"
                itemValue="id"
                byId
                v-model="headersDialog.record.name_import_identifier_type_id"
            />

        </fe-dialog>

        <fe-dialog
            v-if="dialog.show"
            title="New Record"
            width=600
            acceptButtonText="Create"
            @accept="finishCreate"
            @dismiss="dialog.show=false"
        >
            <fe-label>Column Header</fe-label>
            <v-text-field
                :rules="$fieldValidators('text', null, {required: true, limit: 255})"
                flat solo dense
                v-model="dialog.record.name"
            />

            <fe-label>Value Identifier Type</fe-label>
            <fe-remote-combo
                :items="identifierTypes"
                itemText="name"
                itemValue="id"
                byId
                v-model="dialog.record.value_import_identifier_type_id"
            />

            <fe-label>Name Identifier Type</fe-label>
            <fe-remote-combo
                :items="identifierTypes"
                itemText="name"
                itemValue="id"
                byId
                v-model="dialog.record.name_import_identifier_type_id"
            />

        </fe-dialog>

        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.importIdentifier"
            @read="onCrudRead"
            :readParams="{ import_type_id: $props.id }"
        />

        <fe-grid
            ref="grid"
            :rowData="items"
            :columnDefs="columns"
            domLayout="normal"
            class="flex-grow-1"
            displayDensity="small"
            disableInlineCreate
            @cellValueChanged="doUpdate"
            @beginCreate="doCreate"
            @selectionChanged="selectRow"
            @modelUpdated="resize"
        >
            <template v-slot:toolbar-items>
                <fe-btn usage="tertiary" v-if="selections.length > 0" @click="doCopy">Copy</fe-btn>
                <fe-btn usage="tertiary" v-if="selections.length > 0" @click="assignDialog.show=true">Quick Assign</fe-btn>
                <fe-btn usage="tertiary" @click="addHeaders">Add Headers</fe-btn>
                <fe-btn usage="error" v-if="selections.length > 0" @click="doDelete">Delete</fe-btn>
            </template>
        </fe-grid>
    </div>
</template>

<script>
    import ModelForm from '@/components/common/form/ModelForm'
    import { mapState } from 'vuex'

    export default {
        name: 'ImportLayout',
        components: {
            ModelForm
        },
        props: {
            id: {
                required: true
            }
        },
        watch: {
            id(v) {
                if (v) this.loadData()
            },
            'assignDialog.show'(v) {
                if (v) this.assignDialog.record = {}
            },
            // If the text entered into the csv-ish textarea has any tab characters
            // detected, then dynamically swap to a "tab delimited" mode
            'headersDialog.record.name'(v) {
                this.isTabDelimited = !!v.includes('\t')
            },
        },
        data() {
            return {
                identifierTypes: [],
                identifierTypeNames: {},
                items: [],
                originalItems: [],
                record: {},
                ready: false,
                headersDialog: {
                    show: false,
                    record: {}
                },
                isTabDelimited: false,
                assignDialog: {
                    show: false,
                    record: {}
                },
                selections: [],
                dialog: {
                    show: false,
                    record: {}
                },
            }
        },
        computed: {
            isFixedWidth() {
                return this.record.import_file_type_id === 2 ? true : false
            },
            formFields() {
                let me = this
                return [{
                    field: 'name',
                    label: 'Name',
                    width: '100%',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { required: true, limit: 255 },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true,
                        counter: 255
                    }
                },  {
                    field: 'import_parser_id',
                    label: 'Parser Type',
                    width: '100%',
                    component: 'fe-remote-combo',
                    validatorKey: 'select',
                    validatorConfig: { required: true },
                    attrs: {
                        items: me.importParsers,
                        byId: true
                    }
                }]
            },
            columns() {
                let me = this
                return this.ready ? self.columns = [this.$grid.checkColumn(), {
                    headerName: 'ID',
                    field: 'id',
                    width: 80,
                }, {
                    headerName: 'Norms ID',
                    field: 'norms_id',
                    width: 80,
                    hide: true,
                }, {
                    headerName: 'Name',
                    field: 'name',
                    flex: 1,
                    editable: true,
                    width: 150,
                    sort: 'asc',
                }, {
                    headerName: 'Value Identifier Type',
                    valueGetter: (v) => this.identifierTypeNames[v.data.value_import_identifier_type_id] ?? '',
                    valueSetter: (v) => {
                        v.data.value_import_identifier_type_id = v.newValue
                        return true
                    },
                    width: 150,
                    editable: true,
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: v => ({
                        value: v.data.value_import_identifier_type_id,
                        mode: "edit",
                        multiple: false,
                        items: me.identifierTypes,
                        keyProp: "id",
                        labelProp: "name",
                    })
                }, {
                    headerName: 'Name Identifier Type',
                    valueGetter: (v) => this.identifierTypeNames[v.data.name_import_identifier_type_id] ?? '',
                    valueSetter: (v) => {
                        v.data.name_import_identifier_type_id = v.newValue
                        return true
                    },
                    field: 'name_import_identifier_type',
                    width: 150,
                    editable: true,
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: v => ({
                        value: v.data.name_import_identifier_type_id,
                        mode: "edit",
                        multiple: false,
                        items: me.identifierTypes,
                        keyProp: "id",
                        labelProp: "name",
                    })
                }, {
                    headerName: 'Parent',
                    field: 'link_import_identifier_id',
                    width: 150,
                    valueGetter: (v) => {
                        return me.originalItems.find(iden => iden.id == v.data.link_import_identifier_id)?.name ?? ''
                    },
                    valueSetter: (v) => {
                        v.data.link_import_identifier_id = v.newValue
                        return true
                    },
                    editable: true,
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: v => ({
                        value: v.data.link_import_identifier_id,
                        mode: "edit",
                        multiple: false,
                        items: me.originalItems,
                        keyProp: "id",
                        labelProp: "name",
                    }),
                }, {
                    headerName: 'Format',
                    field: 'format',
                    width: 75,
                    editable: true
                }, {
                    headerName: 'Start Index',
                    field: 'start_index',
                    width: 100,
                    hide: me.isFixedWidth ? false : true,
                    editable: true
                }, {
                    headerName: 'End Index',
                    field: 'end_index',
                    width: 100,
                    hide: me.isFixedWidth ? false : true,
                    editable: true
                }, {
                    headerName: 'Rank',
                    field: 'rank',
                    width: 80,
                    hidden: true,
                    editable: true
                },
                    this.$grid.toggleColumn('Active', 'active', null, () => { }),
                    this.$grid.toggleColumn('Required', 'required', null, () => { })
                ] : []
            }
        },
        mounted() {
            this.loadData()
        },
        methods: {
            quickAssign() {
                this.selections.forEach(sel => {
                    if (this.assignDialog.record.name_import_identifier_type_id) {
                        sel.name_import_identifier_type_id = this.assignDialog.record.name_import_identifier_type_id
                    }

                    if (this.assignDialog.record.value_import_identifier_type_id) {
                        sel.value_import_identifier_type_id = this.assignDialog.record.value_import_identifier_type_id
                    }

                    if (this.assignDialog.record.link_import_identifier_id) {
                        sel.link_import_identifier_id = this.assignDialog.record.link_import_identifier_id
                    }
                })

                this.$refs.crud.update(this.selections).then(() => { this.loadData() })
            },
            doDelete() {
                this.$confirmDelete(this.selections, () => {
                    this.$refs.crud.destroy(this.selections).then(() => {
                        this.selections = []
                        this.$refs.crud.read()
                    })
                })
            },
            doCopy() {
                let arr = []
                this.selections.forEach((rec, i) => {
                    let r = {...rec}
                    r.id   = null
                    arr.push(r)
                })
                this.$refs.crud.create(arr).then(() => {
                    this.selections = []
                    this.$refs.crud.read()
                })
            },
            addHeaders() {
                this.headersDialog.record = {}
                this.headersDialog.show = true
            },
            setHeaders() {
                let arr = []
                let sep = this.isTabDelimited ? '\t' : ','
                this.headersDialog.record.name.split(sep).forEach(hdr => {
                    arr.push({
                        name: hdr,
                        import_type_id: this.$props.id,
                        name_import_identifier_type_id: this.headersDialog.record.name_import_identifier_type_id,
                        value_import_identifier_type_id: this.headersDialog.record.value_import_identifier_type_id,
                        rank: 0,
                        active: true,
                        required: false
                    })
                })

                if (arr.length > 0) {
                    this.$refs.crud.create(arr).then(() => {
                        this.loadData()
                    })
                }
            },
            selectRow(row) {
                this.selections = this.$refs.grid.gridApi.getSelectedRows()
            },
            doCreate() {
                this.dialog.record = {
                    import_type_id: this.$props.id
                }
                this.dialog.show   = true
            },
            finishCreate() {
                this.$refs.crud.create([this.dialog.record]).then(r=> {
                    this.selections = []
                    this.$refs.crud.read()
                    this.dialog.show = false
                })
            },
            doUpdate(meta) {
                // Soft == here because original values e.g. startIndex, endIndex
                // are integers from backend, but fe grid cell change starts returning
                // string values "1" "2" etc.
                if (meta.newValue == meta.oldValue) {
                    return
                } else if (meta.oldValue === null && meta.newValue === '') {
                    return
                }

                this.$refs.crud.update([meta.data]).then(() => {})
            },
            finish() {
                this.dialog.show = false
                this.loadData()
            },
            loadData() {
                Object.assign(this, {
                    identifierTypes: [],
                    items: [],
                    originalItems: [],
                    record: {},
                    ready: false,
                    headersDialog: {
                        show: false,
                        record: {}
                    },
                    assignDialog: {
                        show: false,
                        record: {}
                    },
                    selections: [],
                    dialog: {
                        show: false,
                        record: {}
                    }
                });
                this.$modelGet('importType',{ id: this.$props.id }).then(rec => {
                    this.record = rec[0]

                    this.$modelGet('importIdentifierType').then(data => {
                        this.identifierTypes = data
                        this.identifierTypes.sort((a, b) => a.name.localeCompare(b.name))
                        this.identifierTypeNames = this.identifierTypes.reduce((map, t) => {
                            map[t.id] = t.name
                            return map
                        }, {})
                        this.$refs.crud.read()
                    })
                })
            },
            onCrudRead(items) {
                this.items = items
                this.originalItems = this.$_.cloneDeep(items)
                this.originalItems.sort((a, b) => a.name.localeCompare(b.name))

                if (this.identifierTypes.length) {
                    this.ready = true
                } else {
                    this.ready = false
                    return console.warn('Import identifier types did not load.  Unable to render grid')
                }
            },
            resize() {
                this.$refs?.grid?.gridApi?.sizeColumnsToFit()
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
