var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"ec-section-title"},[_vm._v(_vm._s(_vm.formFieldTypes[_vm.localValue.form_field_type_id].name))]),_c('div',{staticClass:"ec-caption"},[_vm._v(" Configure the field with the options below. To reorder, change field type, add dependency, add an action item, or delete use the 6-dot menu. ")]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-tabs',{staticClass:"form-common-config",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',[_vm._v("Basic")]),_c('v-tab',[_vm._v("Advanced")]),(_vm.showStyling)?_c('v-tab',[_vm._v("Styling")]):_vm._e()],1),_c('v-carousel',{staticClass:"mt-2",attrs:{"light":"","height":"100%","hide-delimiters":"","show-arrows":false},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-carousel-item',[_c('div',{staticClass:"ec-section-subtitle"},[_vm._v("General")]),_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Field Name")]),_c('v-text-field',{attrs:{"flat":"","solo":"","dense":"","value":_vm.localValue.name,"rules":_vm.$fieldValidators('text', null, {required:true, limit:1000})},on:{"change":function($event){_vm.localValue.name = $event}}})],1),(_vm.showLabelFields)?_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Show Label")]),_c('fe-switch',{staticStyle:{"margin-left":"auto"},attrs:{"value":!_vm.localValue.hide_name},on:{"input":function($event){_vm.localValue.hide_name=!$event}}})],1):_vm._e(),(_vm.showLabelFields)?_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Align Label")]),_c('fe-remote-combo',{staticStyle:{"margin-left":"auto"},attrs:{"byId":"","itemValue":"value","items":[
                            {value:'left', name:'To the Left'},
                            {value:'right', name:'To the Right'},
                            {value:'top', name:'On Top'},
                        ],"clearable":false},model:{value:(_vm.localValue.label_align),callback:function ($$v) {_vm.$set(_vm.localValue, "label_align", $$v)},expression:"localValue.label_align"}})],1):_vm._e(),(_vm.showColumnWidth)?_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Column Width")]),_c('fe-remote-combo',{staticStyle:{"margin-left":"auto"},attrs:{"byId":"","itemValue":"value","items":[
                            {value:.25, name:'25%'},
                            {value:.33, name:'33%'},
                            {value:.50, name:'50%'},
                            {value:.66, name:'66%'},
                            {value:.75, name:'75%'},
                            {value:1, name:'100%'},
                            {value:0.00, name:'Fit'},
                        ],"clearable":false},model:{value:(_vm.localValue.column_width),callback:function ($$v) {_vm.$set(_vm.localValue, "column_width", $$v)},expression:"localValue.column_width"}})],1):_vm._e(),_c('div',{staticClass:"ec-section-subtitle"},[_vm._v("Field Specific")]),_vm._t("fieldSpecific",null,{"rec":_vm.localValue})],2),_c('v-carousel-item',[_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Element Height")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"value":parseInt(_vm.localValue.height),"flat":"","solo":"","dense":"","clearable":""},on:{"change":function($event){_vm.localValue.height = $event}}})],1),_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Hover Text")]),_c('v-text-field',{attrs:{"flat":"","solo":"","dense":"","value":_vm.localValue.tooltip,"rules":_vm.$fieldValidators('text', null, {limit:500})},on:{"change":function($event){_vm.localValue.tooltip = $event}}})],1),(_vm.showDataId)?_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v(" Data ID "),(new Date(_vm.template.created) < _vm.originalIdStartDate)?_c('v-flex',{staticStyle:{"display":"inline-block","margin-right":"10px"}},[_c('fe-info-tip',{attrs:{"tooltip":`Form templates created on or after ${this.$dayjs(this.originalIdStartDate).format('YYYY-MM-DD')}
                                will be prompted whether to update Data ID for all forms. If you need to update this Data ID for all forms
                                you will need to use 'Form Revision History from the 3 dot menu'`}})],1):_vm._e()],1),_c('v-text-field',{ref:"formDataId",attrs:{"flat":"","solo":"","dense":"","value":_vm.localValue.data_id,"rules":_vm.dataIdRules},on:{"change":_vm.onChangeDataId}})],1):_vm._e(),(!_vm.template.live)?_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Field Type")]),_c('fe-remote-combo',{staticStyle:{"margin-left":"auto"},attrs:{"byId":"","items":_vm.formFieldTypesSorted((a,b) => a.group > b.group ? -1 : 1),"clearable":false,"rules":_vm.$fieldValidators('select', null, { required: true })},model:{value:(_vm.localValue.form_field_type_id),callback:function ($$v) {_vm.$set(_vm.localValue, "form_field_type_id", $$v)},expression:"localValue.form_field_type_id"}})],1):_vm._e(),(_vm.showCarryOver)?_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Carry Over")]),_c('fe-switch',{staticStyle:{"margin-left":"auto"},attrs:{"value":_vm.localValue.carry_over},on:{"input":function($event){_vm.localValue.carry_over = $event === true ? 1 : 0}}})],1):_vm._e(),_c('div',{staticClass:"ec-section-subtitle"},[_vm._v("Rules")]),_c('div',{staticClass:"grid"},[_vm._l((_vm.myRules),function(rule){return [_c('div',{on:{"click":function($event){return _vm.$emit('scrollElement',{
                            fieldId: rule.controlling_form_field_id,
                        })}}},[_vm._v("controller")]),_c('div',{on:{"click":function($event){return _vm.$emit('scrollElement',{
                            fieldId: rule.form_field_id,
                            sectionId: rule.form_section_id,
                        })}}},[_vm._v("dependency")]),_c('div',{on:{"click":function($event){return _vm.$emit('editDependency',{rule})}}},[_vm._v("edit")])]})],2)]),_c('v-carousel-item',[_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Bold")]),_c('fe-switch',{staticStyle:{"margin-left":"auto"},model:{value:(_vm.localValue.bold),callback:function ($$v) {_vm.$set(_vm.localValue, "bold", $$v)},expression:"localValue.bold"}})],1),_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Italic")]),_c('fe-switch',{staticStyle:{"margin-left":"auto"},model:{value:(_vm.localValue.italic),callback:function ($$v) {_vm.$set(_vm.localValue, "italic", $$v)},expression:"localValue.italic"}})],1),_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Underline")]),_c('fe-switch',{staticStyle:{"margin-left":"auto"},model:{value:(_vm.localValue.underline),callback:function ($$v) {_vm.$set(_vm.localValue, "underline", $$v)},expression:"localValue.underline"}})],1),_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Font Size")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"placeholder":"default","value":_vm.localValue.font_size,"flat":"","solo":"","dense":"","clearable":""},on:{"change":function($event){_vm.localValue.font_size = $event}}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }