<template>
    <v-container pt-0 mt-0>
        <v-layout row wrap align-center>
            <v-flex xs12>
                <v-alert v-if="thresholdGroup.is_queued" class="mb-3 chart-alert" :value="true" type="info">
                    This threshold group has been queued and results will update shortly.
                </v-alert>

                <v-alert
                    v-else-if="!thresholdGroup.has_valid_results"
                    class="mb-3 chart-alert"
                    :value="true"
                    type="error"
                >
                    Recent changes to this threshold group will not be reflected in the results below
                    until the next time this threshold runs.
                </v-alert>

                <div class="column-area">
                    <div class="column-item">
                        <charts-card
                            title="School Year"
                            mIcon="calendar_today"
                        >
                            <ItemSelector
                                v-if="schoolYearSuccess"
                                labelProp="name"
                                keyProp="id"
                                :collection="validSchoolYearCollection"
                                :selectedKeys="schoolYearId"
                                @change="x => this.selectedSchoolYearId = x"
                            />
                        </charts-card>
                    </div>

                    <div class="column-item">
                        <charts-card
                            title="Students"
                            :pending="pending"
                            :empty="!returnData.students"
                            :icon="icons.students"
                        >
                            <Pie
                                v-if="returnData.students && returnData.students.hasPerformanceBand"
                                category="Students"
                                name="All Students"
                                :values="studentData.values"
                                :labels="studentData.labels"
                                :colors="studentData.colors"
                                :urlParams="studentData.urlParams"
                                @showDataTable="v => dataTableObj = v"
                            />
                            <Counts
                                v-else-if="returnData.students"
                                unit="Students"
                                :values="[returnData.students.series[0].data[0]]"
                                :labels="['Met the Threshold']"
                                :colors="[defaultColor]"
                                :urlParams="[getDataUrlParams('student', 'all', null, null)]"
                                @showDataTable="v => dataTableObj = v"
                            />
                        </charts-card>
                    </div>

                    <div
                        class="column-item"
                        v-for="(bd, i) in barData"
                        :key="`bd-${i}`"
                    >
                        <charts-card
                            :title="bd.title"
                            :pending="pending"
                            :empty="bd.series.length === 0"
                            :icon="icons[bd.icon]"
                        >
                            <div class="pt-3">
                                <Bar
                                    v-for="(itm,ii) in bd.series"
                                    :key="`bd-${i}-series-${ii}`"
                                    :name="itm.name"
                                    :values="itm.values"
                                    :labels="itm.labels"
                                    :colors="itm.colors"
                                    :max="itm.max"
                                    :urlParams="itm.urlParams"
                                    :category="bd.title"
                                    @showDataTable="v => dataTableObj = v"
                                />
                            </div>
                        </charts-card>
                    </div>

                </div>
            </v-flex>
            <v-flex xs12>
                <div class="column-item">
                    <charts-card
                        title="Run History"
                        :icon="icons.history"
                    >
                        <Runs @exporter="$emit('exporter', $event)"/>
                    </charts-card>
                </div>
            </v-flex>
        </v-layout>

        <DataViewer
            v-if="!!dataTableObj"
            :obj="dataTableObj"
            @close="dataTableObj = null"
        />
    </v-container>
</template>

<script>
import DataViewer from '../modals/DataViewer'
import ChartsCard from '../charts/Card'
import Counts from '../charts/Counts'
import Pie from '../charts/Pie'
import Bar from '../charts/Bar'
import Runs from '../menus/Runs'
import Colors from '../config/Colors'
import ItemSelector from '../controls/ItemSelector'
import {mapActions, mapGetters} from 'vuex'

import iconEthnicity from "../assets/images/header-icon-ethnicity.png"
import iconGender from "../assets/images/header-icon-gender.png"
import iconGrade from "../assets/images/header-icon-grade.png"
import iconHistory from "../assets/images/header-icon-history.png"
import iconSchool from "../assets/images/header-icon-school.png"
import iconStudents from "../assets/images/header-icon-students.png"

export default {
    name: 'Charts',

    components: {
        DataViewer,
        ChartsCard,
        Counts,
        Pie,
        Bar,
        Runs,
        ItemSelector
    },

    data() {
        return {
            dataTableObj: null,
            selectedSchoolYearId: null,
            defaultColor: '#0ea449',
            barDataConfigs: [
                {title: "By School", type: "school", icon: 'school'},
                {title: "By Gender", type: "gender", icon: 'gender'},
                {title: "By Ethnicity", type: "ethnicity", icon: 'ethnicity'},
                {title: "By Grade", type: "grade", icon: 'grade'}
            ],
            icons: {
                ethnicity: iconEthnicity,
                gender: iconGender,
                grade: iconGrade,
                history: iconHistory,
                school: iconSchool,
                students: iconStudents
            }
        }
    },

    computed: {
        ...mapGetters({
            schoolYearPending: 'thresholds/schoolYear/pending',
            schoolYearCollection: 'thresholds/schoolYear/collection',
            schoolYearSuccess: 'thresholds/schoolYear/success',
            schoolYearError: 'thresholds/schoolYear/error'
        }),

        ...mapGetters('thresholds/group/chartData', [
            'collection',
            'clear',
            'success',
            'error',
            'pending'
        ]),

        barData() {
            return this.barDataConfigs.map(cfg => {
                let obj = Object.assign({series: []}, cfg)
                let results = this.dataByType(cfg.type)
                if (results) {
                    if (results.hasPerformanceBand) {
                        obj.series = results.categories.map((cat, i) => {
                            let vals = results.series.map(x => x.data[i])
                            let lbls = results.series.map(x => x.name)
                            let clrs = results.series.map(x => Colors.getColor(x.color))
                            let urls = results.series.map(x => this.getDataUrlParams(cfg.type, results.categoryVid[i], lbls, x.bandId))
                            return {
                                name: cat,
                                values: vals,
                                labels: lbls,
                                colors: clrs,
                                urlParams: urls
                            }
                        })
                    } else {
                        obj.series = results.categories.map((cat, i) => {
                            let vals = results.series[0].data
                            return {
                                name: cat,
                                max: this.sumArray(vals),
                                values: [vals[i]],
                                labels: [cat],
                                colors: [this.defaultColor],
                                urlParams: results.series.map(() => this.getDataUrlParams(cfg.type, results.categoryVid[i], null, null))
                            }
                        })
                    }
                }
                return obj
            })
        },

        studentData() {
            let results = this.dataByType('student')
            if (results && results.hasPerformanceBand) {
                let lbls = results.series.map(x => x.name)
                return {
                    values: results.series.map(x => x.data[0]),
                    labels: lbls,
                    colors: results.series.map(x => Colors.getColor(x.color)),
                    urlParams: results.series.map(x => this.getDataUrlParams('student', results.categoryVid[0], lbls, x.bandId))
                }
            } else {
                return {
                    values: [],
                    labels: [],
                    colors: [],
                    urlParams: []
                }
            }
        },

        thresholdGroup() {
            return this.$store.getters['thresholds/group/selected']
        },

        thresholdGroupId() {
            return this.thresholdGroup.id
        },

        returnData() {
            return {
                students: this.dataByType("student"),
                school: this.dataByType("school"),
                gender: this.dataByType("gender"),
                ethnicity: this.dataByType("ethnicity"),
                grade: this.dataByType("grade")
            }
        },

        validSchoolYearCollection() {
            return this.schoolYearCollection.filter(x => !!x.active).filter(x => {
                if (!this.thresholdGroup.end_date) {
                    return x.year_end >= this.thresholdGroup.start_date
                } else {
                    return x.year_end >= this.thresholdGroup.start_date &&
                        x.year_start <= this.thresholdGroup.end_date
                }
            })
        },

        schoolYearId() {
            if (this.selectedSchoolYearId) {
                return this.selectedSchoolYearId
            } else if (this.validSchoolYearCollection.length > 0) {
                return this.validSchoolYearCollection[0].id
            }
            return null
        }
    },

    watch: {
        'thresholdGroup.id': function () {
            this.reset('thresholdGroup.id')
        },

        'thresholdGroup.is_queued': function (val) {
            if (!val) this.reset('thresholdGroup.is_queued')
        },

        'thresholdGroup.has_valid_results': function (val) {
            if (val) this.reset('thresholdGroup.has_valid_results')
        },

        'schoolYearId': function (val) {
            if (val) this.reset('thresholdGroup.schoolYearId')
        }
    },

    mounted() {
        this.reset('mounted')
    },

    methods: {
        ...mapActions('thresholds/group/chartData', [
            'refresh'
        ]),

        ...mapActions({
            schoolYearRefresh: 'thresholds/schoolYear/refresh'
        }),

        getDataUrlParams(type, category_vid, labels, performance_band_id) {
            return {
                property: 'chart_detail',
                threshold_group_id: this.thresholdGroupId,
                type: type,
                category_vid: category_vid,
                threshold_performance_band_id: performance_band_id,
                school_year_id: this.schoolYearId
            }
        },

        dataByType(type) {
            let itm = (this.success)
                ? this.collection.find(x => x.type === type)
                : null
            return (itm && itm.series.length > 0 && itm.series[0].data.length > 0)
                ? itm
                : null
        },

        sumArray(array) {
            return array.reduce((tot, cur) => {
                return tot + cur
            }, 0)
        },

        reset() {
            if (!this.schoolYearPending && !this.schoolYearSuccess) {
                this.schoolYearRefresh()
            } else if (this.schoolYearSuccess) {
                this.refresh({
                    params: {
                        school_year_id: this.schoolYearId
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss">
.column-area {
    columns: 3 200px;
    column-gap: 2em;

    .column-item {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;

        .v-text-field .v-input__slot {
            border: none;
        }
    }
}
</style>
