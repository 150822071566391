<template>
    <fe-dialog
        v-if="active"
        width="600"
        dismissButtonText="Cancel"
        :acceptButtonText="isAdding ? 'Create' : 'Save'"
        :acceptButtonDisabled="!isValid"
        :title="title"
        @accept="save"
        @dismiss="$emit('cancel')"
    >
        <v-form ref="form" v-model="valid">
            <v-layout row wrap my-2>
                <v-flex xs6 class="px-1">
                    <fe-label content="Metric Type"/>
                    <v-select
                        v-model="model.__type"
                        item-value="name"
                        item-text="display"
                        :items="thresholdTypes"
                        :rules="requiredRules"
                        :readonly="isEditing"
                        :disabled="isEditing"
                        flat solo dense
                    />
                </v-flex>

                <v-flex xs6 class="px-1">
                    <fe-label content="Metric Name"/>
                    <v-text-field v-model="model.name" :rules="nameRules" flat solo dense/>
                </v-flex>
            </v-layout>

            <component
                v-if="model.__type && isEditing"
                ref="metricFields"
                :is="currentMetricFields"
                :obj="obj"
            />
        </v-form>

        <fe-dialog
            v-if="windowsDialog"
            dismissButtonText="Go Back"
            acceptButtonText="Ignore &amp; Continue"
            title="Finish Adding Window?"
            body="It looks like you began adding a window to this metric, but did not click the '+' icon to save it.  If this was intentional, please choose to ignore and continue.  If not, please go back to finish adding the window."
            @dismiss="windowsDialog = false"
            @accept="forceSave"
        />
    </fe-dialog>
</template>

<script>
import MetricFields from '../groups/MetricFields'

export default {
    name: 'MetricEditor',

    components: {
        ...MetricFields
    },

    props: {
        obj: Object,
        metrics: Array
    },

    data() {
        return {
            windowsDialog: false,
            active: true,
            valid: false,
            model: {
                __type: null,
                name: null
            },
            selections: [],
            thresholdTypes: [{
                name: 'assessment',
                display: 'Academic',
                defaults: {
                    data_point_type_id: null,
                    operator_cd: "DECR",
                    score_detail_type_id: null,
                    secondary_value: "0",
                    value: 10,
                    value_type_cd: "PERCENT"
                }
            }, {
                name: 'behavior',
                display: 'Incident',
                defaults: {
                    start_day: null,
                    end_day: null,
                    day_qty: null,
                    operator_cd: "GREATER",
                    same_behavior_flag: 1,
                    secondary_value: "0",
                    type: "BEHAVIOR",
                    value: "1",
                    value_type_cd: "COUNT"
                }
            }, {
                name: 'attendance',
                display: 'Attendance',
                defaults: {
                    type: 'DAY',
                    day_qty: 30,
                    day_type: 'EQUIV',
                    operator_cd: 'GREATER',
                    value: 3,
                    secondary_value: null,
                    value_type_cd: 'COUNT'
                }
            }],
            nameRules: [
                v => !!v || 'Name is required',
                () => this.nameIsUnique || 'Name must be unique'
            ],
            requiredRules: [
                v => !!v || 'Selection is required'
            ]
        }
    },

    computed: {
        isValid() {
            return (this.isAdding || !this.$refs.metricFields)
                ? this.valid
                : (this.valid && this.$refs.metricFields.isValid)
        },

        saveData() {
            let primary = (this.isEditing)
                ? Object.assign({}, this.model, this.$refs.metricFields.saveData, {abbrev: this.abbrev})
                : Object.assign({}, this.model, this.defaults, {abbrev: this.abbrev})

            if (this.isAdding && this.model.__type === 'assessment' && this.dataPointTypes.length > 0) {
                primary.data_point_type_id = this.dataPointTypes[0].id
            }

            return primary
        },

        thresholdType() {
            return this.thresholdTypes.find(x => x.name === this.model.__type) || null
        },

        defaults() {
            return this.thresholdType ? this.thresholdType.defaults : {}
        },

        abbrev() {
            let name = `${this.model.name}`.trim()
            let words = name.split(" ")
            return (words.length > 1)
                ? `${words[0][0]}${words[1][0]}`.toUpperCase()
                : name.substring(0, 2).toUpperCase()
        },

        isEditing() {
            return !!(this.model.id)
        },

        isAdding() {
            return !this.isEditing
        },

        currentMetricFields() {
            return `${this.capitalize(this.thresholdType.name)}MetricFields`
        },

        title() {
            return (this.isEditing) ? "Edit a Metric" : "Add a Metric"
        },

        dataPointTypes() {
            return this.$store.getters['thresholds/dataPointType/collection']
        },

        siblings() {
            return this.metrics.filter(x => x.id !== this.model.id)
        },

        nameIsUnique() {
            return (this.model && this.model.name)
                ? !this.siblings.find(x => x.name.trim().toLowerCase() === this.model.name.trim().toLowerCase())
                : true
        }
    },

    watch: {
        obj: function () {
            this.refresh()
        },

        type: function () {
            this.refreshSelections()
        }
    },

    mounted() {
        this.refresh()
    },

    methods: {
        refresh() {
            this.model = Object.assign({}, this.obj)
            if (this.$store.getters['thresholds/dataPointType/clear']) {
                this.$store.dispatch('thresholds/dataPointType/refresh')
            }
        },

        save() {
            if (this.$refs.form.validate()) {
                if (this.$refs.metricFields && this.$refs.metricFields.hasWindowChanges) {
                    this.windowsDialog = true
                } else if (this.isAdding || !this.$refs.metricFields || this.validFields()) {
                    this.$emit('save', this.saveData)
                }
            }
        },

        forceSave() {
            if (this.$refs.form.validate()) {
                if (this.isAdding || !this.$refs.metricFields || this.validFields()) {
                    this.$emit('save', this.saveData)
                }
            }
        },

        validFields() {
            return this.$refs.metricFields.$refs.form.validate() &&
                (!this.$refs.metricFields.requiresWindows || this.$refs.metricFields.validWindows())
        },

        capitalize(str) {
            return str.toLowerCase().split(' ').map(function (word) {
                return (word.charAt(0).toUpperCase() + word.slice(1));
            }).join(' ');
        }
    }
}
</script>
