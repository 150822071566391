<template>
    <div style="background-color:#eee;">
        <!-- <fe-remote-combo
            byId
            v-model="dependencies[id]"
            itemValue="value"
            :items="[
                {value:'norm', name:'With Normative Data'},
                {value:'nonorm', name:'Without Normative Data'},
                {value:'hidechart', name:'Hide Chart'}
            ]"
            :clearable="false"
        /> -->
        {{value}}
    </div>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'

export default {
    name: 'DependencyPanel',
    inject: ['localStore'],

    props: ['value'],

    computed: {
        ...mapLocalState([ 'formLogic', 'dependencies', 'dependentSections', 'dependentFields' ]),
        
    },
    
    data() {
        return {
            
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
