<template>
    <div @click="$emit('select')" class="mb-4">
        <fe-card class="gallery-card mx-2 flex-fill flex-column">
            <template v-slot:card-title>
                <div class="thumbnail">
                    <div class="thumbnail-header">
                        <v-chip class="vis-count-chip" small>
                            <v-icon x-small class="mr-1">fas fa-layer-group</v-icon>
                            {{ dashboard.configs.length }} visualization{{ dashboard.configs.length !== 1 ? 's' : '' }}
                        </v-chip>
                    </div>

                    <v-lazy v-model="isActive" :options="{threshold: .5}" class="thumbnail-body">
                        <div v-if="!thumbnailConfig || empty" class="image-placeholder">
                            <div class="mt-5">
                                <img class="empty-img" :src="emptyMsg.img"/>
                            </div>

                            <div>{{ emptyMsg.msg }}</div>
                        </div>

                        <visualization
                            v-else
                            :config="thumbnailConfig"
                            :dashboard="dashboard"
                            :getSSValues="() => {}"
                            :allowDrilldown="false"
                            @empty="empty = $event"
                            thumbnail
                        />
                    </v-lazy>
                </div>

                <div class="hover-mask align-center flex-fill">
                    <div class="mask-text">View Dashboard</div>
                </div>
            </template>

            <template v-slot:body>
                <div class="vis-year">{{ dashboard.school_year_name }}</div>

                <div class="vis-name">{{ dashboard.name }}</div>

                <div class="vis-desc">{{ dashboard.description }}</div>

                <div class="vis-types my-2">
                    <v-chip small v-for="(type, i) in visTypes" :key="`type-${i}`" class="vis-type-chip">
                        {{ type.name }}
                    </v-chip>
                </div>
            </template>
        </fe-card>
    </div>
</template>

<script>
import Visualization from "./Visualization"

export default {
    name: "GalleryCard",

    components: {Visualization},

    props: {
        dashboard: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            isActive: false,
            empty: false
        }
    },

    computed: {
        visTypes() {
            let types = {}

            this.dashboard.configs.forEach(config => {
                let type = config.config.type
                if (type !== 'section' && !types[type]) {
                    types[type] = this.$parent.$parent.possibleVisTypes[type]
                }
            })

            return Object.values(types)
        },

        thumbnailConfig() {
            let configs = this.dashboard.configs.filter(x => x.config.type !== 'section')
            let sorted = this.$_.sortBy(configs, ['config.x', 'config.y'])
            if (!sorted.length) return false

            return this.unPackConfig(sorted[0], 0)
        },

        emptyMsg() {
            if (this.empty) {
                return {img: require('@/assets/charts.svg'), msg: 'Unable to load visualization preview.'}
            }
            return {img: require('@/assets/no-data.png'), msg: 'There are no visualizations in this dashboard.'}
        }
    },

    methods: {
        unPackConfig(data, i) {
            let cfg = this.$_.cloneDeep(data.config)
            if (this.dashboard.scope) {
                cfg.scope = this.dashboard.scope
            }

            return Object.assign({i}, data, cfg)
        }
    }
}
</script>

<style lang="scss" scoped>
.gallery-card {
    position: relative;
    width: 300px;
    cursor: pointer;

    .thumbnail {
        height: 188px;
        position: relative;

        .thumbnail-header {
            height: 28px;

            .vis-count-chip {
                color: #050F2D;

                ::v-deep .v-icon {
                    color: #050F2D;
                }
            }
        }

        .thumbnail-body {
            height: 160px;

            .image-placeholder {
                width: 100%;
                padding: 16px;
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                text-align: center;
                transform: translate(-50%, -50%);

                .empty-img {
                    height: 40px;
                    width: 40px;
                    opacity: .4;
                }
            }
        }
    }

    .hover-mask {
        position: absolute;
        top: -1px;
        left: -1px;
        width: 300px;
        height: 190px;
        display: none;
        background: rgba(0, 86, 115, 0.90);
        border: 1px solid #E0E1E6;
        box-sizing: border-box;
        border-radius: 5px 5px 0 0;

        .mask-text {
            position: absolute;
            left: 102px;
            top: 75px;
            color: #FFFFFF;
            font-size: 12px;
            line-height: 17px;
            text-align: center;
            text-decoration-line: underline;
        }
    }

    &:hover {
        .hover-mask {
            display: unset;
        }
    }

    .vis-year {
        color: #050F2D;
        font-size: 10px;
    }

    .vis-name {
        color: #000000;
        font-size: 16px;
        font-weight: bold;
    }

    .vis-desc {
        color: #000000;
        font-size: 12px;
    }

    .vis-type-chip {
        color: #050F2D;
    }

    ::v-deep .fe-card-text-full {
        height: unset;
        padding-bottom: 0;
    }
}
</style>
