<template>
    <fe-dialog
        ref="dialog"
        :title="title"
        persistent
        :footer="false"
        width=800
        @close="$emit('close')"
        @dismiss="$emit('close')"
    >
        <fe-mask v-model="loading" modalMask showLoader persistent/>

        <div class='d-flex flex-fill flex-column flex-grow-1 flex-shrink-1' style="height: 70vh; overflow-y: scroll; overflow-x: hidden;">
            <mtg-details
                ref="pnl0"
                v-if="panel == 0"
                :obj="obj"
                :editing="editing"
                :schedule="schedule"
                :users="users"
                @addUsers="addUsers"
                @removeUsers="removeUsers"
                @setAttrs="setAttrs"
            />
            <mtg-options
                ref="pnl1"
                v-if="panel == 1"
                :obj="obj"
                :editing="editing"
                :students="students"
                :showGoogleCalendarSync="showGoogleCalendarSync"
                @setAttrs="setAttrs"
                @addStudents="addStudents"
                @removeStudents="removeStudents"
                @syncStudents="syncStudents"
            />
            <mtg-occurrences
                ref="pnl2"
                v-if="panel == 2"
                :obj="obj"
                :editing="editing"
                @changeDates="changeDates"
            />
        </div>
        <div class='d-flex flex-fill justify-space-between' style="height: 50px;">
            <div class='d-flex justify-start'>
                <fe-btn usage="tertiary" v-if="showBack" @click="prevPanel">Back</fe-btn>
            </div>
            <div class='d-flex justify-end'>
                <fe-btn v-if="showCancel" @click="close" usage="ghost">Cancel</fe-btn>
                <fe-btn @click="saveAndClose" :usage="showNext ? 'secondary' : 'primary'">Save &amp; Close</fe-btn>
                <fe-btn v-if="showNext" @click="nextPanel">{{ panel == 1 ? 'Save & Continue' : 'Next' }}</fe-btn>
            </div>
        </div>

        <fe-crud ref="crud"
            :config="$models.meeting"
            disable-snackbars
        />

        <fe-crud
            ref="crudStudents"
            :autoload="!!this.editing"
            :config="$models.meetingStudent"
            :readParams="{
                dashboard_meeting_id: this.editing ? this.editing.id : null
            }"
            disable-snackbars
            @read="students = $event"
        />

        <fe-crud ref="crudUsers"
            :autoload="!!this.editing"
            :config="$models.meetingUser"
            :readParams="{
                dashboard_meeting_id: this.editing ? this.editing.id : null
            }"
            @read="users = $event"
            createSuccessSnackbarMessageProperty="msg"
        />

        <fe-crud ref="crudSchedule" :config="$models.scheduleDate" />

        <fe-dialog
            v-if="confirmScheduleChange"
            title="Schedule Change Method"
            @dismiss="confirmScheduleChange = false"
            @accept="saveWithScheduleChanges"
            :acceptButtonDisabled="!scheduleChangeMethod"
        >
            <p>
                You have made changes to this meeting's recurring schedule.  Please
                choose how you would like to apply changes to this meeting's dates.
            </p>
            <fe-remote-combo
                ref="addingMethod"
                placeholder="Please Choose a Method"
                :items="recurringMethods"
                itemText="name"
                itemValue="id"
                v-model="scheduleChangeMethod"
                :returnObject="false"
                style="width: 100%;"
            />
        </fe-dialog>
    </fe-dialog>
</template>

<script>
    import { mapState } from 'vuex'
    import MtgDetails from './Details'
    import MtgOptions from './Options'
    import MtgOccurrences from './Occurrences'

    export default {
        name: 'Creation',
        components: { MtgDetails, MtgOptions, MtgOccurrences },
        props: {
            editing: { type: Object, default: null },
            dashboard_id: { type: Number, default: null },
        },
        data() {
            return {
                panel: 0,
                users: [],
                students: [],
                schedule: null,
                confirmScheduleChange: null,
                scheduleChangeMethod: 1,
                showGoogleCalendarSync: true,
                recurringMethods: [
                    { id: 1, name: 'ADD new dates in addition to any existing dates' },
                    { id: 2, name: 'REPLACE any existing dates with new dates' }
                ],
                saveCallback: null,
                loading: false,
                obj: {
                    title: "",
                    description: "",
                    default_end_time: null,
                    default_start_time: null,
                    form_id: null,
                    id: null,
                    schedule_id: null,
                    google_calendar_sync: null,
                },
            }
        },
        computed: {
            ...mapState('global', ['shareableStores', 'sessionUser', 'userPreferences']),
            studentIds () { return this.students ? this.students.map(itm => itm.student_id) : [] },
            creating () { return !this.editing },
            title () { return this.editing ? 'Configure Meeting' : 'Create Meeting' },
            showCancel () { return !this.editing },
            showBack () { return this.panel !== 0 },
            showNext () { return this.panel !== 2 },
            hasChanges () { return this.creating || !!Object.keys(this.obj).find(k => this.obj[k] != this.editing[k]) },
            hasScheduleChanges () {
                if (this.creating) return true
                if (!(this.editing.schedule?.[0] && this.schedule)) return true
                if ((this.editing.schedule[0].frequency_cd == 'NOSCHED') == this.recurring) return true
                let comparisonKeys = ['start_date', 'end_date', 'default_end_time', 'default_start_time', 'frequency_cnt', 'week_days']
                return comparisonKeys.filter(k => {
                    if (k.match(/_date$/)) {
                        return this.$dayjs.utc(this.editing.schedule[0][k]).format('YYYY-MM-DD') != this.$dayjs.utc(this.schedule[k]).format('YYYY-MM-DD')
                    } else if (k.match(/_time$/)) {
                        return this.$dayjs.utc(this.editing.schedule[0][k], 'H:mm:s').format('H:mm') != this.$dayjs.utc(this.schedule[k], 'H:mm:s').format('H:mm')
                    } else {
                        return this.editing.schedule[0][k] != this.schedule[k]
                    }
                }).length > 0
            },
            recurring: {
                get () { return this.schedule.frequency_cd != 'NOSCHED' },
                set (v) { this.schedule.frequency_cd = v ? 'WEEK' : 'NOSCHED' }
            }
        },
        methods: {
            currentPanelIsValid () {
                return this.$refs[`pnl${this.panel}`].isValid()
            },
            saveAndClose () {
                if (this.currentPanelIsValid()) this.save(() => {
                    this.close()
                })
            },
            close () {
                this.$emit('closed')
            },
            nextPanel () {
                if (this.currentPanelIsValid()) {
                    if (this.editing || this.panel == 1) {
                        this.save(() => { this.panel += 1 })
                    } else {
                        this.panel += 1
                    }
                }
            },
            prevPanel () {
                if (this.currentPanelIsValid()) {
                    if (this.editing) {
                        this.save(() => { this.panel -= 1 })
                    } else {
                        this.panel -= 1
                    }
                }
            },
            loadObj () {
                this.users = this.editing
                    ? [].concat(this.editing.dashboard_meeting_users)
                    : []

                let googleStatus =  this.$googleStatus(this.shareableStores, this.userPreferences)
                this.showGoogleCalendarSync = googleStatus.showGoogleCalendarSync
                let googleCalendarSync = googleStatus.googleCalendarSync
                // restore from record
                if (this.showGoogleCalendarSync) {
                    let u = this.users.find(u => u.user_id == this.sessionUser.user.id)
                    if (u !== undefined) { // override default from above if present
                        googleCalendarSync = u.dashboard_meeting_user_google_calendar_sync
                    }
                }
                this.schedule = this.editing && this.editing.schedule.length
                    ? Object.assign({}, this.editing.schedule[0])
                    : {
                        start_date: null,
                        end_date: null,
                        default_end_time: null,
                        default_start_time: null,
                        frequency_cnt: 1,
                        frequency_cd: 'WEEK',
                        generate_dates_flag: 1,
                        week_days: '',
                        google_calendar_sync: null,
                    }

                this.recurring = (this.schedule.frequency_cd != 'NOSCHED')

                let vals = {}
                Object.keys(this.obj).forEach(k => vals[k] = this.editing ? this.editing[k] : null)
                vals.google_calendar_sync = googleCalendarSync
                this.obj = Object.assign({}, this.obj, vals)
            },
            save (cb) {
                if (this.editing && this.hasScheduleChanges) {
                    this.saveCallback = cb
                    this.confirmScheduleChange = true
                } else if (!this.hasChanges && !this.hasScheduleChanges && cb) {
                    cb()
                } else if (this.editing) {
                    this.loading = true

                    this.update(() => {
                        this.loading = false
                        cb()
                    })
                } else {
                    this.loading = true

                    this.create(() => {
                        this.loading = false
                        cb()
                    })
                }
            },
            saveWithScheduleChanges () {
                this.confirmScheduleChange = false
                let data = {
                    dashboard_meeting_schedule: {
                        dashboard_meeting_id: this.editing.id,
                        method: (this.scheduleChangeMethod == 2) ? 'replace' : 'add',
                        frequency_cd: this.schedule.frequency_cd,
                        start_date: this.schedule.start_date,
                        end_date: this.schedule.end_date,
                        default_end_time: this.schedule.default_end_time,
                        default_start_time: this.schedule.default_start_time,
                        frequency_cnt: this.schedule.frequency_cnt,
                        week_days: this.schedule.week_days,
                        generate_dates_flag: 1
                    }
                }
                this.$axios.post(this.$models.getUrl('meetingSchedule', 'create') + `&id=${this.editing.id}`, data).then(() => {
                    this.update(this.saveCallback, data.dashboard_meeting_schedule)
                })
            },
            // If user uses Occurrences screen to add single / recurring dates
            // then we should re-sync Google Calendar if user has that enabled
            changeDates(scheduleId, saveWithScheduleChanges=false) {
                // if google calendar sync and
                if (this.obj.google_calendar_sync && !saveWithScheduleChanges) {
                    this.$syncToGoogleCalendar(this.sessionUser, scheduleId, (err, responseText) => {
                        let snackText = 'Google Calendar sync complete'

                        if (err) {
                            console.error('Google Calendar Sync error:', err)
                            snackText = 'Google Calendar sync failed'
                        }

                        this.$snackbars.$emit('new', {
                            text: snackText,
                            subtext: 'You can exit at any time in this process and your changes will be saved.',
                            usage: err ? 'warning' : 'success'
                        })
                    })
                }
                if (saveWithScheduleChanges) this.saveWithScheduleChanges()
            },
            async create(cb) {
                let me = this
                if(this.dashboard_id) { this.obj.dashboard_id = this.dashboard_id }

                let meeting = await new Promise((resolve, reject) => {
                    this.$refs.crud.create(this.obj).then((sRsp) => {
                        let itm = sRsp.data.dashboard_meetings[0]
                        resolve(itm)
                    })
                })

                let schedule = await new Promise((resolve, reject) => {
                    if (this.recurring) {
                            let sched = {
                                dashboard_meeting_schedule: Object.assign({}, this.schedule, {
                                    frequency_cd: 'WEEK',
                                    start_date: this.schedule.start_date,
                                    end_date: this.schedule.end_date,
                                    method: 'replace',
                                    dashboard_meeting_id: meeting.id,
                                    id: meeting.schedule_id
                                })
                            }
                            this.$axios.post(this.$models.getUrl('meetingSchedule', 'create') + `&id=${meeting.id}`, sched).then(() => {
                                resolve(meeting)
                            })
                        } else {
                            resolve(meeting)
                        }
                })

                let users = await new Promise((resolve, reject) => {
                    if (this.users.length) {
                        this.addUsers(this.users, meeting.id, () => {
                            resolve(meeting)
                        })
                    } else {
                        resolve(meeting)
                    }
                })

                let students = await new Promise((resolve, reject) => {
                    if (this.students.length) {
                        this.addStudents(this.students, meeting.id, () => {
                            resolve(meeting)
                        })
                    } else {
                        resolve(meeting)
                    }
                })

                let snackText = 'Meeting Created'

                let calendar = await new Promise((resolve, reject) => {
                    if (!me.obj.google_calendar_sync) {
                        return resolve(meeting, false)
                    }
                    this.$syncToGoogleCalendar(this.sessionUser, meeting.schedule_id, (err, responseText) => {
                        if (err) {
                            console.error('Google Calendar Sync error:', err)
                            snackText = 'Meeting Created, but Google Calendar sync did not complete'
                        }
                        resolve(meeting, !!err)
                    })
                })

                let refresh = await this.$refs.crud.read({ id: meeting.id }).then((rRsp) => {
                    let final = rRsp.data.dashboard_meetings[0]
                    this.$emit('created', final)

                    this.$snackbars.$emit('new', {
                        text: snackText,
                        subtext: 'You can exit at any time in this process and your changes will be saved.',
                        usage: 'success'
                    })

                    if (cb) cb()

                    return meeting
                })
            },
            update (cb, data = null) {
                let me = this
                if(data) {
                    let vals = {}
                    Object.keys(this.obj).forEach(k => vals[k] = data[k] ? data[k] : this.obj[k])
                    this.obj = Object.assign({}, this.obj, vals)
                }

                if(this.dashboard_id) this.obj.dashboard_id = this.dashboard_id
                this.$refs.crud.update(this.obj).then((sRsp) => {
                    this.fetchAndEmitUpdate(this.editing.id, (itm) => {
                        // If this event doesn't require GC sync, we're done
                        if (!me.obj.google_calendar_sync) {
                            this.$snackbars.$emit('new', {
                                text: 'Meeting Updated',
                                subtext: 'You can exit at any time in this process and your changes will be saved.',
                                usage: 'success'
                            })

                            return cb ? cb(itm) : true
                        }

                        else {
                            this.$syncToGoogleCalendar(this.sessionUser, itm.schedule_id, (err, responseText) => {
                                let snackText = 'Meeting Updated'

                                if (err) {
                                    console.error('Google Calendar Sync error:', err)
                                    snackText = 'Meeting Updated, but Google Calendar sync failed'
                                }

                                this.$snackbars.$emit('new', {
                                    text: snackText,
                                    subtext: 'You can exit at any time in this process and your changes will be saved.',
                                    usage: err ? 'warning' : 'success'
                                })

                                if (cb) cb(itm)
                            })
                        }
                    })
                })
            },
            fetchAndEmitUpdate (id, cb) {
                this.$refs.crud.read({ id: id ? id : this.editing.id }).then((rRsp) => {
                    let itm = rRsp.data.dashboard_meetings[0]
                    this.$emit('updated', itm)
                    if (cb) cb(itm)
                })
            },
            saveObj () {
                let operation = (this.editing) ? 'update' : 'create'
                this.$refs.crud[operation](this.obj).then(() => this.$refs.crud.read({}))
            },
            setAttrs (params) {
                this.$data[params.key] = Object.assign({}, this[params.key], params.attrs)
            },
            addUsers (recs, meeting_id, cb) {
                if (this.creating && !meeting_id) {
                    recs.forEach((u) => {
                        if (!this.users.map(u => u.user_id).includes(u.id)) {
                            let itm = Object.assign({}, u, { id: null, user_id: u.user_id ? u.user_id : u.id })
                            this.users = this.users.concat(itm)
                        }
                    })
                } else {
                    let data = recs.filter(u => !this.users.map(itm => itm.user_id).includes(u.id)).map(u => {
                        return {
                            dashboard_meeting_id: meeting_id ? meeting_id : this.editing.id,
                            user_id: u.id ? u.id : u.user_id
                        }
                    })

                    // If no users were selected, exit now to avoid error response "no users" from backend
                    const handlerFunction = () => {
                        if (cb) {
                            cb()
                        } else {
                            this.fetchAndEmitUpdate(meeting_id)
                        }
                    }

                    if (data.length > 0) {
                        this.$refs.crudUsers.create(data).then(() => {
                            this.$refs.crudUsers.read().then(handlerFunction)
                        })
                    }

                    // If no users were added *or* removed, just call the done handler
                    if (data.length === 0) {
                        return handlerFunction()
                    }
                }
            },
            removeUsers (recs) {
                if (recs.length === 0) {
                    return
                }

                if (this.creating) {
                    let ids = recs.map(u => u.user_id)
                    this.users = this.users.filter(u => !ids.includes(u.user_id))
                } else {
                    this.$refs.crudUsers.destroy(recs).then(() => {
                        this.$refs.crudUsers.read().then(() => this.fetchAndEmitUpdate())
                    })
                }
            },
            studentFullName (stdnt) {
                return (stdnt.mdl_init)
                    ? `${stdnt.lname}, ${stdnt.fname} ${stdnt.mdl_init}.`
                    : `${stdnt.lname}, ${stdnt.fname}`
            },
            removeStudents (v) {
                if (this.creating) {
                    let ids = v.map(stdnt => stdnt.student_id)
                    this.students = this.students.filter(stdnt => !ids.includes(stdnt.student_id))
                } else {
                    this.$refs.crudStudents.destroy(v).then(() => {
                        this.$refs.crudStudents.read().then(() => this.fetchAndEmitUpdate())
                    })
                }
            },
            addStudents (v, meeting_id, cb) {
                if (this.creating && !meeting_id) {
                    this.students = this.students.concat(v.filter(itm => !this.studentIds.includes(itm.student_id)))
                } else {
                    let lst = meeting_id ? v : v.filter(itm => !this.studentIds.includes(itm.student_id))
                    let data = lst.map(itm => {
                        return {
                            student_id: itm.student_id,
                            dashboard_meeting_id: meeting_id ? meeting_id : this.editing.id
                        }
                    })
                    if (data.length) {
                        this.$refs.crudStudents.create(data).then(() => {
                            this.$refs.crudStudents.read({dashboard_meeting_id: meeting_id ? meeting_id : this.editing.id }).then(() => {
                                if (cb) {
                                    cb()
                                } else {
                                    this.fetchAndEmitUpdate(meeting_id)
                                }
                            })
                        })
                    } else {
                        if (cb) cb()
                    }
                }
            },
            syncStudents (v) {
                if (this.creating) {
                    if (v[0].length > 0) {
                        this.students = v.map(stdnt => {
                            return {
                                fname: stdnt.fname,
                                lname: stdnt.lname,
                                student_id: stdnt.student_id,
                                image: stdnt.image,
                                mdl_init: stdnt.mdl_init,
                                student_full_name: stdnt.student_full_name ? stdnt.student_full_name : this.studentFullName(stdnt)
                            }
                        })
                    }
                } else {
                    let operations = []

                    let createData = [{
                        student_id: v[0].student_id,
                        dashboard_meeting_id: this.editing.id
                    }]

                    if (createData.length) operations.push(this.$refs.crudStudents.create(createData))

                    if (operations.length) Promise.all(operations).then(() => {
                        this.$refs.crudStudents.read().then(() => this.fetchAndEmitUpdate())
                    })
                }
            }
        },
        watch: {
            editing: {
                handler () { this.loadObj() },
                immediate: true
            },

            'obj.google_calendar_sync'(v) {
                if (v) {
                    // If user toggles sync button on, do a check to ensure they've
                    // linked.  Will show a popup to grant calendar scope if not previously given
                    this.$linkGoogleAccount(this.sessionUser)
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
