<template>
    <div class="d-flex flex-fill flex-column">
        <fe-grid
            :columnDefs="columns"
            :rowData="items"
            class="flex-grow-1"
            displayDensity="small"
            ref="grid"
            :showAddRowBtn="false"
            @cellValueChanged="sanitize"
            @selectionChanged="selectRow"
            showToolbar
        >
            <template v-slot:toolbar-items>
                <fe-btn usage="primary" v-if="canSave" @click="doSave">Save</fe-btn>
            </template>
        </fe-grid>
    </div>
</template>

<script>
    export default {
        name: 'ValidateScoreDetails',
        props: {
            uploadId: { required: true }
        },
        data() {
            let me = this
            return {
                columns: [],
                items: [],
                originalItems: [],
                detailTypes: [],
                canSave: false
            }
        },
        mounted() {
            this.loadData()
        },
        methods: {
            sanitize() {
                // this will check to see if the original fetched data set has been manipulated
                let dirty = this.$isDirty(this.originalItems, this.items)
                if (dirty) {
                    this.canSave = true
                } else {
                    this.canSave = false
                }
                // Since we are changing cells behind the ag-grid api, call refresh
                this.$refs.grid.gridApi.refreshCells()
            },
            selectRow() {

            },
            doSave() {
                let dirty = this.$isDirty(this.originalItems, this.items)
                if (!dirty.length) return

                dirty.forEach(d => {
                    d.new_score_detail_type = d.new_score_detail_type === 1 ? true : false
                    d.primary_score_flag    = d.primary_score_flag === 1    ? true : false
                })
                this.$axios.post('verifyUpload.php?action=update&property=unknown_score_detail_types&upload_id='+this.uploadId, {
                    score_detail_types: dirty
                }).then(r => {
                    this.$ecResponse(r)
                    this.loadData()
                })
            },
            loadData() {
                let me = this

                this.$axios.get('verifyUpload.php?action=get&property=unknown_score_detail_types&upload_id='+this.uploadId)
                    .then(r => {
                        this.items = this.$ecResponse(r, 'score_detail_types')
                        this.items.forEach(i => {
                            i.primary_score_flag = 0
                            i.new_score_detail_type = 0
                            i.matched_score_detail_type_id = null
                        })

                        this.originalItems = this.$_.cloneDeep(this.items)
                        this.sanitize()

                        this.$emit('count', this.originalItems.length)

                        if (this.items.length) {
                            this.$modelGet('scoreDetailType', { data_point_type_id: this.items[0].data_point_type_id}).then(s => {
                                this.detailTypes = s

                                this.columns = [{
                                    headerName: "Assessment Group",
                                    field: "data_point_type_name",
                                    editable: false,
                                    flex: 1
                                }, {
                                    headerName: "Name",
                                    field: "name",
                                    editable: false,
                                    flex: 1
                                }, {
                                    headerName: "Assessment",
                                    field: "sub_category_name",
                                    editable: false,
                                    flex: 1
                                },
                                    me.$grid.toggleColumn('Create', 'new_score_detail_type', null, () => { }),
                                {
                                    headerName: "Create As",
                                    field: "display_name",
                                    editable: true,
                                    flex: 1
                                }, {
                                    headerName: "Alias To",
                                    field: "matched_score_detail_type_id",
                                    editable: true,
                                    flex: 1,
                                    cellRenderer: v => {
                                        if (v.value) {
                                            let r = me.detailTypes.find(rec => { return rec.id == v.value })
                                            if (r) return r.description
                                        }
                                        return ''
                                    },
                                    cellEditorFramework: "FeGridChipSelect",
                                    cellEditorParams: {
                                        rowDataKey: "matched_score_detail_type_id",
                                        mode: "edit",
                                        items: me.$data.detailTypes,
                                        keyProp: "id",
                                        labelProp: "description",
                                        disableChips: true
                                    }
                                },
                                    me.$grid.toggleColumn('Primary Score', 'primary_score_flag', null, () => { })
                                ]
                            })
                        }
                    })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
