import { render, staticRenderFns } from "./scorefield.vue?vue&type=template&id=5ba8c3b8&scoped=true"
import script from "./scorefield.vue?vue&type=script&lang=js"
export * from "./scorefield.vue?vue&type=script&lang=js"
import style0 from "./scorefield.vue?vue&type=style&index=0&id=5ba8c3b8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ba8c3b8",
  null
  
)

export default component.exports