<template>
    <TabPane
        emptyTitle="Create a User Group Notification"
        emptyBody="To get started, add user groups."
        emptyButton="Add User Groups"
        :pending="pending"
        :error="error"
        :count="count"
        @emptyClick="adding = true"
    >

        <template slot="icon">
            <h3 class="card__title card__title--group">Manage User Group Notifications</h3>
        </template>

        <template slot="buttons">
            <fe-btn usage="ghost" :disabled="!success && !error" text icon @click="reset">
                <v-icon>refresh</v-icon>
            </fe-btn>
        </template>

        <template slot="content">
            <fe-grid
                ref="grid"
                domLayout="autoHeight"
                :showToolbar="false"
                :columnDefs="columnDefs"
                :rowData="rowData"
                :styles="{ height: null }"
                @rowDataChanged="onSelectionChanged"
                @selectionChanged="onSelectionChanged">
            </fe-grid>

            <fe-btn class="mt-5" usage="tertiary" @click="adding = true">Add a User Group</fe-btn>
        </template>

        <template slot="modals">
            <UserGroupSelector
                :active="adding"
                :hidden-ids="existingUserGroupIds"
                @apply="addUserGroups"
                @cancel="adding = false"
            />

            <DeleteFooter
                modelName="user group"
                :selectedIndexes="selectedIndexes"
                :items="rowData"
                @selectAll="() => $refs.grid.selectAll()"
                @deselectAll="() => $refs.grid.deselectAll()"
                @deleteSelection="removeUserGroups"
            />
        </template>
    </TabPane>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import TabPane from '../templates/TabPane'
import DeleteFooter from '../templates/DeleteFooter'
import UserGroupSelector from '../modals/UserGroupSelector'

export default {
    name: 'NotifyGroups',

    components: {
        TabPane,
        UserGroupSelector,
        DeleteFooter
    },

    data() {
        return {
            selectedIndexes: [],
            adding: false,
            emailTypes: [{
                text: "Include Students",
                value: "itemized"
            }, {
                text: "Summary Only",
                value: "summarized"
            }]
        }
    },

    computed: {
        ...mapGetters('thresholds/group/notifyGroup', [
            'collection',
            'clear',
            'pending',
            'success',
            'error',
            'hasDirtied',
            'hasRemoved',
            'hasAdded',
            'hasChanges',
            'rowData',
            'count'
        ]),

        thresholdGroupId() {
            return this.$store.getters['thresholds/group/selected'].id
        },

        columnDefs() {
            return [
                {headerName: null, headerCheckboxSelection: true, checkboxSelection: true, width: 70, minWidth: 70},
                {headerName: 'Group', field: 'name', sortable: true},
                {
                    headerName: 'Email',
                    field: 'email_flag',
                    sortable: true,
                    editable: true,
                    cellRendererFramework: 'FeGridToggle',
                    onCellValueChanged: this.updateCell,
                    cellStyle: {padding: 0}
                },
                {
                    headerName: 'Email Type',
                    field: 'email_type',
                    sortable: true,
                    editable: true,
                    cellRendererFramework: 'FeGridChipSelect',
                    cellRendererParams: {
                        rowDataKey: 'email_type',
                        mode: 'render',
                        items: this.emailTypes,
                        keyProp: 'value',
                        labelProp: 'text'
                    },
                    cellEditorFramework: 'FeGridChipSelect',
                    cellEditorParams: {
                        rowDataKey: 'email_type',
                        mode: 'edit',
                        items: this.emailTypes,
                        keyProp: 'value',
                        labelProp: 'text'
                    },
                    onCellValueChanged: this.updateCell,
                    cellStyle: {padding: 0}
                },
                {headerName: 'Created', field: 'created', sortable: true}
            ]
        },

        hasSelection() {
            return (this.selectedIndexes.length > 0)
        },

        existingUserGroupIds() {
            return this.collection.map(itm => itm.user_group_id)
        }
    },

    watch: {
        clear: (v) => {
            if (v) this.reset()
        }
    },

    mounted() {
        this.reset()
    },

    updated() {
        if (this.clear) this.reset()
    },

    methods: {
        ...mapActions('thresholds/group/notifyGroup', [
            'refresh',
            'update',
            'add',
            'remove',
            'reconcile'
        ]),

        updateCell(params) {
            this.update(params.data)
            this.$refs.grid.resize()
        },

        onSelectionChanged(params) {
            this.selectedIndexes = params.api.getSelectedRows().map(itm => itm.__idx)
        },

        addUserGroups(userGroups) {
            this.add(userGroups.map(userGroup => {
                return {
                    email_flag: 1,
                    threshold_group_id: this.thresholdGroupId,
                    name: userGroup.name,
                    user_group_id: userGroup.id
                }
            }))
            this.adding = false
        },

        removeUserGroups() {
            this.remove({indexes: this.selectedIndexes})
            this.selectedIndexes = []
        },

        reset() {
            this.refresh()
            this.selectedIndexes = []
        },

        save() {
            this.reconcile()
        }
    }
}
</script>
