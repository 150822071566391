<template>
    <div class="flex-fill flex-column">
        <div v-if="!activePage">
            <div class="mb-4 headline">{{ title }}</div>
        </div>

        <fe-action-list v-if="!activePage"
            itemText="displayName"
            :items="displayItems"
            :showSearch="false"
            :addButton="false"
            :editButton="false"
            :showFiltering="false"
            @rowClick="go"
        />

        <component v-if="activePage" :is="activePage" @countChanged="getCounts" />
    </div>
</template>

<script>
import Vuex from 'vuex'
import { mapState } from 'vuex'
import StudentTags from './StudentTags'
import StudentList from './StudentList'
import GuardianList from './GuardianList'
import { mapLocalState } from '@/util/vuexHelper'
import Demographics from './CustomDemographics'

export default {
    name: 'ManageStudents',
    components: {
        StudentTags,
        StudentList,
        GuardianList,
        Demographics
    },
    provide() {
        this.localStore = new Vuex.Store({
            state: {
            }
        })
        return {
            localStore: this.localStore,
        }
    },
    mounted() {
        this.getCounts()
    },
    computed: {
        ...mapState('global', ['shareableStores']),
        crumbKey: me => 'Students & Guardians',
        title() {
            return this.shareableStores.district.psportal_enabled ? 'Students & Guardians' : 'Students'
        },
        activePage() {
            return this.$getLastCrumb(this.crumbKey)?.component
        },
        displayItems() {
            return this.items.reduce((filteredItems,x) => {
                if(x.securityCode && !this.$hasSecurity(x.securityCode)) return filteredItems
                if(x.securityCallback && !x.securityCallback()) return filteredItems

                filteredItems.push({
                    name: x.name,
                    displayName: `${x.name} <span class="ml-4">${x.count}<span>`,
                    component: x.component,
                })
                return filteredItems
            },[])
        }
    },
    methods: {
        go(to) {
            this.$addCrumb(this.crumbKey, to)
        },
        getCounts() {
            this.$axios.get('tags.php?action=get&property=composite').then(response => {
                this.items[0].count = response.data.tags.length;
            })
        }
    },
    data() {
        return {
            counts: {},
            items: [
                { name: 'Student Tags', count: 0, component: 'StudentTags', },
                { name: 'Students', count: '', component: 'StudentList', securityCode: 'MANAGE_STUDENT_DATA' },
                {
                    name: 'Demographics',
                    count: '',
                    component: 'Demographics',
                    securityCode: 'MANAGE_DEMOGRAPHICS'
                },
                // { name: 'Guardians', count: '', component: 'GuardianList', securityCallback: () => this.shareableStores.district.psportal_enabled, securityCode: 'MANAGE_GUARDIAN_DATA' }
                { name: 'Guardians', count: '', component: 'GuardianList', securityCode: 'MANAGE_GUARDIAN_DATA' }
            ]
        }
    },

}
</script>

<style lang="scss" scoped>
</style>
