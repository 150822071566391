import Vue from 'vue'
import Vuex from 'vuex'

import { global } from './global'
import { manage } from './manage'
import { navigation } from './navigation'
import { collection } from './collections'
import { intervention } from './interventions'
import { flags } from './flags'
import { actionPlan } from './actionPlan'
import { incidentEntry } from './incidentEntry'
import { thresholds } from './thresholds'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        "global": global,
        'manage': manage,
        'navigation': navigation,
        'collection': collection,
        'intervention': intervention,
        'flags': flags,
        'actionPlan': actionPlan,
        'incidentEntry': incidentEntry,
        'thresholds': thresholds
    },
    strict: debug,
    state: {
        schoolYears: []
    },
    mutations: {
        set(state, payload) {
            if (payload.state) {
                if (payload.module) {
                    state[payload.module][payload.state] = payload.value
                } else {
                    state[payload.state] = payload.value
                }

            }
        }
    },
    actions: {
        doQuery({ dispatch, commit }, payload) {
            return new Promise((resolve, reject) => {
                if (!payload.endpoint) reject('Missing endpoint')

                commit('set', {
                    module: 'global',
                    state: 'loading',
                    value: true
                })

                this._vm.$axios.get(payload.endpoint,{params:payload.params})
                    .then(response => {
                        let r = null

                        if (response.data) {
                            if(response.data) {
                                r = payload.rootProperty ? response.data[payload.rootProperty] : response.data
                            }

                            if (payload.state && payload.module) {
                                commit('set', {
                                    module: payload.module,
                                    state: payload.state,
                                    value: r
                                })
                            }

                            resolve(r)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('set', {
                            module: 'global',
                            state: 'loading',
                            value: false
                        })

                    })
            })

        }
    }
})
