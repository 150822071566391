<template>
    <div class="flex-fill flex-column no-scroll">
        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.unknownDemographic"
            :readParams="{ upload_id: $props.uploadId }"
            @read="v => items = v"
        />

        <fe-mask v-show="loading" :showLoader="true" />

        <fe-dialog
            width="400"
            title="New Demographic"
            v-if="createDialog.show"
            @dismiss="createDialog.show=false"
            @close="createDialog.show=false"
            @accept="doCreate"
            acceptButtonText="Save"
            :acceptButtonDisabled="!createDialog.valid"
            persistent
        >
            <v-form v-model="createDialog.valid">

                <fe-label v-if="onlyOne">Demographic Name</fe-label>
                <v-text-field
                    v-if="onlyOne"
                    v-model="createDialog.record.name"
                    itemValue="id"
                    itemText="name"
                    flat solo dense
                />

                <fe-label v-if="onlyOne">Demographic Type</fe-label>
                <v-autocomplete
                    :items="demographicTypes"
                    v-model="createDialog.record.value_type"
                    itemValue="code"
                    itemText="name"
                    required
                    flat solo dense
                />

                <div class="d-flex justify-space-between">
                    <fe-label>All School Years</fe-label>
                    <fe-switch disabled v-model="createDialog.record.all_years"/>
                </div>

            </v-form>
        </fe-dialog>


        <fe-dialog
            width="400"
            title="Create Demographic Alias"
            v-if="dialog.show"
            @dismiss="dialog.show=false"
            @close="dialog.show=false"
            @accept="setAlias"
            acceptButtonText="Alias"
            :acceptButtonDisabled="!dialog.record"
            persistent
        >
            <v-form>
                <fe-label>Demographic</fe-label>
                <v-autocomplete
                    :items="demographics"
                    v-model="dialog.record"
                    itemValue="id"
                    itemText="name"
                    required
                    flat solo dense
                />
            </v-form>
        </fe-dialog>

        <fe-grid
            :columnDefs="columns"
            :rowData="items"
            class="flex-grow-1"
            displayDensity="small"
            ref="grid"
            :showAddRowBtn="false"
            @selectionChanged="selectRow"
            @cellValueChanged="sanitize"
            showToolbar
        >
            <template v-slot:toolbar-items>
                <fe-btn usage="primary" v-if="canSave" @click="doSave">Save</fe-btn>

                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <fe-btn usage="primary" v-on="on" :disabled="!selections.length">
                            Actions
                        </fe-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="(item, index) in tools" :key="index" @click="item.handler()">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </fe-grid>
    </div>
</template>

<script>
    export default {
        name: 'UnknownDemographic',
        props: {
            uploadId: {
                required: true
            }
        },
        data() {
            let me = this

            return {
                loading: false,
                canSave: false,
                selections: [],
                demographics: [],
                items: [],
                createDialog: {
                    valid: false,
                    show: false,
                    record: {}
                },
                dialog: {
                    show: false,
                    record: null
                },
                pendingText: '<br><p class="caption mt-2">This will also save any pending changes.</p>',
                originalItems: [],
                tools: [{
                    title: 'Create New Demographic',
                    description: 'Create a new demographic in eduCLIMBER',
                    handler() {
                        if (me.onlyOne) {
                            me.createDialog.record = {...me.selections[0]}
                        } else {
                            me.createDialog.record = {}
                        }
                        me.createDialog.show = true
                    }
                }, {
                    title: 'Alias to Demographic',
                    description: 'Set this code name as an alternate known name for a selected demographic',
                    handler() {
                        me.dialog.show = true
                    }
                }],
                columns: [],
                demographicTypes: [
                    { code: 'date', name: 'Date' },
                    { code: 'number', name: 'Number' },
                    { code: 'option', name: 'Option' },
                    { code: 'string', name: 'Text' },
                    { code: 'boolean', name: 'Yes/No' }
                ]
            }
        },
        computed: {
            onlyOne() {
                return this.selections.length == 1 ? true : false
            }
        },
        mounted() {
            this.loadDemographic()
        },
        methods: {
            doSave() {
                let dirty = this.$isDirty(this.originalItems, this.items)
                this.loading = true
                this.$refs.crud.update(dirty)
                    .then(response => {
                        this.loadDemographic()
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            setAlias() {
                let me = this
                this.dialog.show = false
                this.selections.forEach(rec => { rec.demographic_id = this.dialog.record })

                this.sanitize()
                this.$refs.grid.gridApi.deselectAll()
                this.doSave()
            },
            doCreate() {
                if (this.onlyOne) {
                    this.selections.forEach(rec => {
                        rec.name = this.createDialog.record.name
                        rec.value_type = this.createDialog.record.value_type
                    })
                } else {
                    this.selections.forEach(rec => {
                        rec.value_type = this.createDialog.record.value_type
                    })
                }

                this.loading = true
                this.$refs.crud.update(this.selections)
                    .then(response => {
                        this.loadDemographic()
                    })
                    .finally(() => {
                        this.loading = false
                    })

            },
            selectRow(row) {
                this.selections = this.$refs.grid.gridApi.getSelectedRows()
            },
            sanitize() {
                // this will check to see if the original fetched data set has been manipulated
                let dirty = this.$isDirty(this.originalItems, this.items)
                if (dirty) {
                    this.canSave = true
                } else {
                    this.canSave = false
                }
                // Since we are changing cells behind the ag-grid api, call refresh
                this.$refs.grid.gridApi.refreshCells()
            },
            loadDemographic() {
                let me = this
                let id = this.$props.uploadId
                this.originalItems = []
                this.items = []

                this.loading = true
                this.$modelGet('demographic')
                    .then(response => {
                        this.demographics = response

                        this.columns = [me.$grid.checkColumn(), {
                            headerName: 'Import Demographic Code',
                            field: 'name',
                            sortable: true,
                            flex: 1
                        }, {
                            headerName: 'Alias To',
                            field: 'demographic_id',
                            editable: true,
                            flex: 1,
                            tooltipValueGetter: (param) => 'This code will also be known as the selected code in eduCLIMBER',
                            cellRenderer: v => {
                                if (v.value) {
                                    let r = me.demographics.find(rec => { return rec.id == v.value })
                                    if (r) return r.name
                                }
                                return ''
                            },
                            cellEditorFramework: "FeGridChipSelect",
                            cellEditorParams: {
                                rowDataKey: "demographic_id",
                                mode: "edit",
                                items: this.demographics,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true
                            }
                        }, {
                            headerName: 'All Years',
                            field: 'all_years',
                            sortable: true,
                            editable: false,
                            cellRenderer: v => { return (v.value) ? 'Yes' : 'No' },
                        }]
                        this.$refs.crud.read()
                            .then(() => {
                                this.items.forEach(rec => {
                                    rec.demographic_id = undefined
                                    rec.new_demographic = false
                                })
                                this.originalItems = this.$_.cloneDeep(this.items)
                                this.$emit('count', this.originalItems.length)
                            })
                            .finally(() => {
                                this.loading = false
                                this.sanitize()
                            })
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>