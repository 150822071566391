<template>
    <div>
        <v-menu class="menu" offset-y left close-on-content-click>
            <template v-slot:activator="{ on }">
                <fe-icon-btn
                    v-on="on"
                    v-bind="$attrs"
                    use-icon="fal fa-ellipsis-v"
                    class="menu-btn"
                    size="24px"
                    :class="{'dense': dense}"
                />
            </template>

            <v-list class="menu px-2" dense flat>
                <v-list-item
                    v-for="item in items"
                    :class="{divider: item.divider}"
                    @click="callback(item.callback, returnValue ? returnValue : item.name, $event)"
                >
                    <v-list-item-icon v-if="item.icon" class="menu-icon">
                        <v-icon :class="{remove: item.remove}">{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title :class="{remove: item.remove}">{{ item.name }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "Menu",

    props: {
        items: {
            type: Array,
            default: []
        },
        dense: {
            type: Boolean,
            default: false
        },
        returnValue: {
            required: false
        }
    },

    methods: {
        callback(cb, returnValue, e) {
            if (cb) {
                cb(returnValue, e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.menu-btn {
    ::v-deep .v-btn {
        margin-left: 0;
        margin-right: 0;

        .v-icon {
            color: #050F2D !important;
        }
    }
}

.menu {
    ::v-deep .theme--light.v-list-item {
        color: #050F2D !important;
    }

    .menu-icon {
        ::v-deep .v-icon {
            color: #050F2D;
        }

        .remove {
            color: red;
        }

        margin-right: 6px;
    }

    .remove {
        color: red;
    }

    .divider {
        border-bottom: 1px solid #E0E1E6;
    }
}
</style>
