<template>
    <v-layout column>
        <div class="headline mb-1 d-flex">
            Manage Grades
        </div>

        <fe-crud-grid
                ref="grid"
                :config="mainCrudConfig"
                :columnDefs="mainColumnDefs"
                :searchBar=false
                :readParams="{property: 'show_inactive'}"
                displayDensity="medium"
                identifier="id"
                addRowBtnText="Add Grade"
                refreshOnCreated
                refreshOnUpdated
                disableInlineCreate
                @read="grades = $event"
                @beginCreate="beginEntry"
        >
            <template v-slot:cellDialog="{cell}">
                <simple-grid-dialog-column
                        v-if="aliasCell"
                        :cell="cell"
                        title="Aliases"
                        field="map_id"
                        fieldHeader="Alias"
                        fieldPlaceholder="Add an Alias"
                        :fieldCounter="255"
                        :crudConfig="aliasCrudConfig"
                        :defaultParams="{ grade_id: cell.data.id }"
                        :insertDefaults="{ grade_id: cell.data.id }"
                        @destroyed="load"
                        @created="load"
                        @close="closeDialogColumn"
                />
            </template>
        </fe-crud-grid>

        <fe-dialog
                v-if="createDialog.show"
                title="Add Grade"
                :footer="false"
                persistent
                disableAutoclose
                @close="dismissDialog"
        >
            <model-form
                    idProperty="id"
                    :records="createDialog.records"
                    :fieldConfig="fieldConfigs"
                    model="grade"
                    @cancel="dismissDialog"
                    @done="created"
                    :submitPrevent="true"
            />
        </fe-dialog>

        <fe-crud
            ref="crudGrades"
            autoload
            :config="$models.grade"
            :readParams="{
                action: 'norms',
                property: 'show_inactive'
            }"
            refresh-on-config
            @read="norms = $event"
        />

    </v-layout>
</template>

<script>
    import SimpleGridDialogColumn from "../../../common/SimpleGridDialogColumn"
    import ModelForm from '@/components/common/form/ModelForm'

    export default {
        name: "Grades",

        components: {SimpleGridDialogColumn, ModelForm},

        data() {
            return {
                grades: [],
                norms: null,
                createDialog: {show: false, records: []},
                cell: undefined,
                aliasCell: false,
                requiredRule: [v => !_.isEmpty(v) || 'This field is required']
            }
        },

        computed: {
            mainCrudConfig() {
                let cfg = _.cloneDeep(this.$models.grade)
                return cfg
            },

            aliasCrudConfig() {
                return _.cloneDeep(this.$models.gradeMap)
            },

            mainColumnDefs() {
                let me = this
                return [
                    {
                        colId: 'checkbox_column',
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        maxWidth: 70
                    }, {
                        headerName: 'Grade',
                        field: 'name',
                        editable: true,
                        cellEditorFramework: "FeGridTextField"
                    }, {
                        headerName: 'Rank',
                        field: 'rank',
                        maxWith: 100,
                        editable: true,
                        cellEditorFramework: "FeGridTextField",
                        cellEditorParams: () => {
                            return {
                                type: 'number',
                                rules: this.$fieldValidators('number', 'Rank', {
                                    required: true,
                                    min: 0
                                })
                            }
                        },
                    }, {
                        headerName: 'Aliases',
                        field: 'aliases',
                        maxWidth: 100,
                        editable: false,
                        cellRendererFramework: "FeDialogColumn",
                        onCellClicked: v => {
                            this.aliasCell = true
                            this.cellClicked(v)
                        }
                    }, {
                        headerName: 'Active',
                        field: 'active',
                        maxWidth: 100,
                        cellRendererFramework: "FeGridToggle",
                        cellRendererParams: {
                            rowDataKey: 'active'
                        }
                    }, {
                        headerName: 'Excluded',
                        field: 'exclude_flag',
                        maxWidth: 100,
                        editable: false,
                        cellRendererFramework: "FeGridToggle",
                        cellRendererParams: {
                            rowDataKey: 'exclude_flag'
                        }
                    }, {
                        headerName: "Norms Name",
                        colId: "norms_id",
                        field: "norms_id",
                        sortable: true,
                        editable: true,
                        cellRenderer(v) { return me.findLabel(me.norms, v.data.norms_id, 'id', 'name') },
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: {
                            rowDataKey: "norms_id",
                            mode: "edit",
                            items: me.norms,
                            keyProp: "norms_id",
                            labelProp: "name",
                            disableChips: true
                        },
                        hide: true,
                        width: 80
                    }
                ]
            },

            fieldConfigs() {
                return [
                    {
                        field: 'name',
                        label: 'Grade Name',
                        component: 'v-text-field',
                        validatorKey: 'text',
                        validatorConfig: {required: true},
                        width: '100%',
                        attrs: {
                            flat: true,
                            solo: true,
                            dense: true
                        }
                    }
                ]
            }
        },

        created() {
            this.$parent.$once('show grades', () => {
                this.$refs.grid.$refs.grid.resize()
            })
        },

        methods: {
            load() {
                this.$refs.grid.$refs.crud.read()
            },

            beginEntry() {
                this.resetEntry()
                this.createDialog.show = true
            },

            dismissDialog() {
                this.createDialog.show = false
            },

            resetEntry() {
                this.createDialog.records = [
                    {
                        name: undefined,
                        excluded_flag: false,
                        ignore_flag: false
                    }
                ]
            },

            created() {
                this.load()
                this.dismissDialog()
            },

            cellClicked(cell) {
                this.cell = cell
                this.$refs.grid.$refs.grid.setDialogCell(cell)
            },

            closeDialogColumn() {
                this.aliasCell = false
                this.$refs.grid.$refs.grid.openMenu = false
            },

            findLabel(collection, value, valueProp, labelProp) {
                let lbl = ''
                if (collection && value) {
                    let sel = collection.find(x => x[valueProp] === value)
                    if (sel) lbl = sel[labelProp]
                }
                return lbl
            }
        }
    }
</script>

<style scoped>

</style>
