import Vue from "vue";

export default Vue.extend({
    template: `<div>
                    <div style="line-height: 20px;">{{ params.value }}</div>

                    <div v-if="params.data.description" style="line-height: 15px;">
                        <span
                            v-if="showDescription"
                            class="ai-name-column-description"
                            :id="'aiDescription' + params.data.id"
                        >
                            {{ params.data.description }}
                        </span>
                        <span
                            class="ai-show-description has-description"
                            @click="resize()"
                        >
                            {{ showDescription ? 'Hide description' : 'Show description' }}
                        </span>
                    </div>

                    <div v-if="hasBoth" style="margin: 5px;"></div>

                    <div
                        v-if="params.data.form_instance_response"
                        style="line-height: 15px;"
                        @click="loadSmartForm"
                    >
                        <span class="ai-show-description has-smartform">View SmartForm</span>
                    </div>
              </div>`,

    data() {
        return {
            showDescription: false
        }
    },

    computed: {
        hasBoth() {
            return this.params.data.form_instance_response && this.params.data.description
        }
    },

    mounted() {
        this.resize(true)
    },

    methods: {
        // after click, resize row so description shows fully
        resize(initialLoad = false) {
            if(!initialLoad) this.showDescription = !this.showDescription
            let gridOptions = this.params.context.componentParent.$refs.grid.gridOptions
            let me = this
            // small timeout to make sure description in DOM first
            setTimeout(function() {
                gridOptions.api.forEachNode(function (rowNode) {
                    if (rowNode.data && rowNode.data.id === me.params.data.id) {
                        let aiDescriptions = document.getElementsByClassName('ai-name-column-description')
                        let height = 55
                        if (me.hasBoth) height += 25
                        for(let i = 0; i < aiDescriptions.length; i++) {
                            if(aiDescriptions[i].id == 'aiDescription' + me.params.data.id) {
                                height += aiDescriptions[i].offsetHeight
                            }
                        }
                        rowNode.setRowHeight(height)
                    }
                })
                gridOptions.api.onRowHeightChanged()
            }, 10)
        },

        loadSmartForm() {
            let rec = this.params.data.form_instance_response
            this.$dockableWindow({
                name: rec.form_instance_name,
                data: rec,
                component: 'smart-form',
                attrs: {
                    id: rec.form_instance_id,
                    template_id: rec.form_id
                },
            })
        }
    }
});
