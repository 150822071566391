import Vue from "vue";

export default Vue.extend({
    template: `
        <div style="cursor: pointer; background-color: this.bgColor"  v-html="this.value">
        </div>
    `,
    data: function () {
        return {
            value: null
        };
    },
    beforeMount() {
    },
    mounted() {
        this.value = this.formatCell();
    },
    methods: {
        formatCell(params) {
            // always want bottom pinned row for calculation to return number
            let val = (this.params.dataPointTypeId && (this.params.value || this.params.value === 0) && this.params.node.rowPinned !== 'bottom')
                ? this.$getAlphaScore(this.params.dataPointTypeId, this.params.value, this.params.subCategoryId)
                : this.params.value

            if (this.params.htmlDisplay) return val || ''

            /*
            let key = this.params.colDef.field
            let details = this.params.data[key + ":Details"]
            let r = ''
            if (details) {
                let band = this.params.targetDescriptors[details.data_point_target_id]

                if (band) {
                    // let style = 'margin-right: 8px; background-color: ' + 
                    //     band.target_descriptor_color +
                    //     '; display: inline-block; border-radius: 5px; width: 100px; height: 16px;'
                    this.bgColor = band.target_descriptor_color
                    // r += '<div style="background-color: '+band.target_descriptor_color+';">'+
                    //     (this.params.value ? this.params.value : '') +
                    // '</div>'
                    return r
                } else {
                    return this.params.value
                }
            }
            */

            return val
        }
    }
});