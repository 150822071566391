<template>
    <div class="d-flex flex-fill flex-column" style="background-color: #eeeeee;">
        <div class="toolbar d-flex">
            <div class="page-title">Upload Management</div>

            <v-menu offset-y v-if="hasSecurity">
                <template v-slot:activator="{on}">
                    <fe-btn v-on="on" class="ml-auto">Upload</fe-btn>
                </template>

                <v-list>
                    <v-list-item @click="uploadStudentData()">
                        <v-list-item-title>Upload Student Data</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="uploadStudentImages()">
                        <v-list-item-title>Student Images</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <file-upload v-model="showUpload"/>

        <student-image-upload v-model="showStudentUpload"/>

        <v-container>
            <v-row cols=12>
                <v-col cols=6>
                    <v-card class="upload-card ma-4">
                        <v-card-title style="border-bottom: 1px solid #E0E1E6;">Uploaded Files</v-card-title>

                        <div class="d-flex justify-center">
                            <div class="flex-grow-1" style="text-align: center; height: 120px;" @click="$router.push('/Upload/List/'+uploads.VALIDATION.id)">
                                <div style="font-size: 36px; padding-top: 16px;">{{ uploads.VALIDATION ? uploads.VALIDATION.count : 0 }}</div>
                                <div class="page-section">Needing Validation</div>
                            </div>


                            <div v-if="uploads.READY" class="flex-grow-1" style="text-align: center; height: 120px;" @click="$router.push('/Upload/List/'+uploads.READY.id)">
                                <div style="font-size: 36px; padding-top: 16px;">{{ uploads.READY ? uploads.READY.count : 0 }}</div>
                                <div class="page-section">Ready to Run</div>
                            </div>
                        </div>


                        <v-card-actions>
                            <div class="pl-2 view-all" @click="$router.push('/Upload/List')">View All Uploaded Files</div>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols=6>
                    <v-card class="upload-card ma-4">
                        <v-card-title style="border-bottom: 1px solid #E0E1E6;">Upload Types</v-card-title>

                        <div class="d-flex" style="text-align: center; height: 120px;">

                            <div class='flex-grow-1' @click="$router.push('/Upload/Types/Enabled')">
                                <div style="font-size: 36px; padding-top: 16px;">{{ uploadTypes.enabled.count }}</div>
                                <div class="page-section">Enabled</div>
                            </div>

                            <div class='flex-grow-1' @click="$router.push('/Upload/Types/Disabled')">
                                <div style="font-size: 36px; padding-top: 16px;">{{ uploadTypes.disabled.count }}</div>
                                <div class="page-section">Disabled</div>
                            </div>
                        </div>

                        <v-card-actions>
                            <div class="pl-2 view-all" @click="$router.push('/Upload/Types/')">Manage</div>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols=6>
                    <v-card class="upload-card ma-4" v-if="hasImportManager" @click="$router.push('/ImportManager')">
                        <v-card-title style="border-bottom: 1px solid #E0E1E6;">Import Manager</v-card-title>

                        <div class="page-title text-center" style="text-decoration: underline;">Data Services Only</div>

                        <div class="body-3 pa-4 pb-8">
                            Only trained staff should modify imports.  This creates the mappings for files that allow
                            the data to come into eduCLIMBER.
                        </div>
                    </v-card>
                </v-col>

                <v-col cols=6>
                    <v-card class="upload-card ma-4" v-if="hasImportManager">
                        <v-card-title style="border-bottom: 1px solid #E0E1E6;" @click="$router.push('/SFTPManager')">sFTP Uploads</v-card-title>

                        <div class="page-title text-center" style="text-decoration: underline;" @click="$router.push('/SFTPManager')">Data Services Only</div>

                        <div class="body-3 pl-4 pr-4" @click="$router.push('/SFTPManager')">
                             Only trained staff should modify FTP uploads.<br/>
                             FTP files received over the last 2 weeks:
                        </div>

                        <div class="d-flex" style="text-align: center; height: 110px;">
                            <div class="flex-grow-1" @click="$router.push('/SFTPManager/1-3')">
                                <div :style="'font-size: 23px; padding-top: 16px;color:' + (ftpUploads.success > 0 ? $textColors.success : $textColors.muted)">{{ ftpUploads.success }}</div>
                                <div class="page-section" :style="'color:' + (ftpUploads.success > 0 ? $textColors.success : $textColors.muted)">1-3 Days<br>Ago</div>
                            </div>
                            <div class="flex-grow-1" @click="$router.push('/SFTPManager/4-7')">
                                <div :style="'font-size: 23px; padding-top: 16px;color:' + (ftpUploads.warning > 0 ? $textColors.warning : $textColors.muted)">{{ ftpUploads.warning }}</div>
                                <div class="page-section" :style="'color:' + (ftpUploads.warning > 0 ? $textColors.warning : $textColors.muted)">4-7 Days<br>Ago</div>
                            </div>
                            <div class="flex-grow-1" @click="$router.push('/SFTPManager/8-14')">
                                <div :style="'font-size: 23px; padding-top: 16px;color:' + (ftpUploads.danger > 0 ? $textColors.danger : $textColors.muted)">{{ ftpUploads.danger }}</div>
                                <div class="page-section" :style="'color:' + (ftpUploads.danger > 0 ? $textColors.danger : $textColors.muted)">8-14 Days<br>Ago</div>
                            </div>
                            <div class="flex-grow-1" @click="$router.push('/SFTPManager/15+')">
                                <div :style="'font-size: 23px; padding-top: 16px;color:' + (ftpUploads.error > 0 ? $textColors.error : $textColors.muted)">{{ ftpUploads.error }}</div>
                                <div class="page-section" :style="'color:' + (ftpUploads.error > 0 ? $textColors.error : $textColors.muted)">15+ Days<br>Ago</div>
                            </div>
                        </div>
                        <div v-if="ftpUploads.never > 0" class="body-3 pl-4 pr-4 pb-4" @click="$router.push('/SFTPManager/Never')" :style="'font-weight:bold;color:' + ($textColors.danger)">
                            <i class="fal fa-exclamation-circle"></i> Still Waiting to be Received: {{ ftpUploads.never }}
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import FileUpload from './FileUpload'
    import StudentImageUpload from './StudentImageUpload'
    import { mapState } from 'vuex'

    export default {
        name: 'UploadLanding',
        components: {
            FileUpload,
            StudentImageUpload
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            hasSecurity() {
                return this.sessionUser.security_codes.indexOf('UPLOAD_DATA') != -1
            },
            hasImportManager() {
                return this.sessionUser.security_codes.indexOf('MANAGE_FTP_UPLOAD') != -1
            }
        },
        data() {
            return {
                showUpload: false,
                showStudentUpload: false,
                ftpUploads: {
                    success: "-",
                    warning: "-",
                    danger: "-",
                    error: "-",
                    never: 0,
                },
                uploads: {
                    title: 'Uploads',
                    route: '/Upload/List',
                    VALIDATION: null,
                    READY: null,
                },
                uploadTypes: {
                    title: 'Uploads',
                    route: '/Upload/List',
                    enabled: {
                        count: 0
                    },
                    disabled: {
                        count: 0
                    },
                },
                cards: [{
                    title: 'Uploads',
                    route: '/Upload/List',
                }, {
                    title: 'Upload Types',
                    route: '/Upload/Types',
                }]
            }
        },
        mounted() {
            this.loadTotals()
        },
        methods: {
            go(card) {
                if (card.route) this.$router.push(card.route)
            },
            uploadStudentData() {
                this.showUpload = true
            },
            uploadStudentImages() {
                this.showStudentUpload = true
            },
            loadTotals() {
                this.$modelGet('uploadType', {property: 'count', all: 1})
                    .then(types => types.forEach(type => this.uploadTypes[type.status] = type))
                this.$modelGet('upload', { property: 'count_by_status', upload_status_id: '3,4'}) // upload status VALIDATION and READY
                    .then(records => records.forEach(upl => this.uploads[upl.name] = upl))
                if (this.hasImportManager) {
                    this.$axios('ftpUploads.php?action=get&property=count')
                        .then(records => this.ftpUploads = this.$ecResponse(records)?.ftp)
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.toolbar {
    width: 100%;
    padding: 16px 16px 16px 16px;
}
.upload-card {
    width: 400px;
    cursor: pointer;
    background-color: white !important;
    background: white;
    background-color: #FFFFFF;
}

.view-all {
    color: var(--v-primary-base);

    &:hover {
        font-weight: bold;
    }
}
</style>
