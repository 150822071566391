<template>
    <record-dialog
        title="Group"
        width="350px"
        v-bind="$attrs"
        :defaultRec="{
            name: null,
            description: null,
        }"
        @create="$emit('create',$event)" @update="$emit('update',$event)"
        @close="$emit('close')"
    >
        <template #form="{rec}">
            <fe-label style="width:76px;">Name</fe-label>
            <v-text-field flat solo dense autofocus
                v-model="rec.name"
                :rules="$fieldValidators('text', null, {required:true, limit:50})"
            />
            <fe-label style="width:76px;">Description</fe-label>
            <v-textarea flat solo dense
                v-model="rec.description"
                :counter="255"
                :rules="$fieldValidators('text', null, {limit:255})"
            />
        </template>

    </record-dialog>
</template>

<script>
import { mapState } from 'vuex'
import RecordDialog from '@/components/common/form/RecordDialog'

export default {
    name: 'AddGroup',
    
    components: {
        RecordDialog
    },
    
    computed: {
        ...mapState('global',['sessionUser']),
    },

    data() {
        return {
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
