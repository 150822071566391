<template>
    <div>
        <v-list v-if="success && count > 0" two-line>
            <v-list-item v-for="(itm) in collection.slice(0,5)" :key="itm.id">
                <v-list-item-content>
                    <div class='text-small text-muted'>
                        Started on <strong>{{ runDateFormatted(itm) }}</strong>
                        at <strong>{{ runTimeFormatted(itm) }}</strong>
                    </div>

                    <div class='text-smaller text-muted'>
                        <span>
                            {{ getSchoolYear(itm) }}
                        </span>

                        <span v-if="status(itm) === 'success'">
                            Duration: {{ durationFormatted(itm) }}
                        </span>

                        <span v-else-if="status(itm) === 'fail'">
                            <strong style='color: #f00;'>
                                <v-icon
                                v-if="itm.output && itm.output.trim() !== ''"
                                color="#f00"
                                size="14"
                                @click="output = itm"
                                >
                                    info
                                </v-icon>

                                Execution failed
                            </strong>
                        </span>

                        <span v-else>
                            <v-progress-circular indeterminate color="#999" size="10"/>
                        </span>
                    </div>
                </v-list-item-content>

                <v-list-item-action v-if="itm.count > 0">
                    <v-chip @click="showResults(itm)">{{ itm.count }} Records
                        <v-icon right>list</v-icon>
                    </v-chip>
                </v-list-item-action>
            </v-list-item>
        </v-list>

        <v-list v-else-if="success && count === 0">
            <v-list-item>
                <v-list-item-content>
                    <span class='text-smaller text-muted'>Run History is Empty</span>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-list v-else-if="error">
            <v-list-item>
                <v-list-item-content>
                    <span class='text-smaller text-muted'>Error Retrieving Run History</span>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-list v-else-if="pending">
            <v-list-item>
                <v-list-item-content>
                    <span class='text-smaller text-muted'>
                        <v-progress-circular indeterminate color="#999" size="14"/>
                        Loading...
                    </span>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <fe-dialog
            v-if="output"
            usage="error"
            title="Execution Failed"
            @dismiss="output = null"
            @accept="output = null"
        >
            <h5>Output Message:</h5>

            <p>{{ output.output }}</p>
        </fe-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    name: 'Runs',

    data() {
        return {
            output: null
        }
    },

    computed: {
        ...mapGetters({
            schoolYearCollection: 'thresholds/schoolYear/collection'
        }),

        ...mapGetters('thresholds/group/history', [
            'collection',
            'pending',
            'error',
            'success',
            'count'
        ]),

        thresholdGroup() {
            return this.$store.getters['thresholds/group/selected']
        },

        thresholdGroupId() {
            return this.$store.getters['thresholds/group/selected'].id
        },

        timeZone() {
            return this.$store.getters['thresholds/activeUser/timeZone']
        }
    },

    watch: {
        thresholdGroupId: function () {
            this.refresh()
        },

        'thresholdGroup.is_queued': function (val) {
            if (!val) this.refresh()
        },

        'thresholdGroup.has_valid_results': function (val) {
            if (val) this.refresh()
        }
    },

    mounted() {
        this.refresh()
    },

    methods: {
        ...mapActions('thresholds/group/history', [
            'refresh'
        ]),

        getSchoolYear(itm) {
            return (this.schoolYearCollection && this.schoolYearCollection.length > 0 && itm.school_year_id)
                ? `School Year: ${this.schoolYearCollection.find(x => x.id === itm.school_year_id).name}, `
                : null
        },

        runDateTime(itm) {
            return this.$dayjs(itm.ran)
        },

        runDateFormatted(itm) {
            return this.runDateTime(itm).format('MM/DD/YYYY')
        },

        runTimeFormatted(itm) {
            return this.runDateTime(itm).format('h:mm A')
        },

        durationFormatted(itm) {
            let d = parseInt(itm.duration)
            let mins = (d > 60) ? `${Math.round(d / 60)}m ` : ""
            return mins + `${d % 60}s`
        },

        status(itm) {
            if (itm.status === 'success') {
                return 'success'
            } else if (itm.status === 'fail') {
                return 'fail'
            } else {
                return 'pending'
            }
        },

        showResults(itm) {
            this.$emit('exporter', {type: 'run', obj: itm})
        }
    }
}
</script>
