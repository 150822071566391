<template>
    <div>
        <fe-card
            class="alt-card"
            width=250
        >
            <template v-slot:card-title>
                <div class="my-3 ml-4 d-flex">
                    <fe-title-select
                        v-model="viewBy"
                        fontColor="#2B3963"
                        fontSize="16px"
                        :items="views"
                    />

                    <v-spacer/>

                    <menu-btn v-if="viewBy.id==='mon'" class="mr-2">
                        <v-list-item @click="$emit('add-monitor-click')">
                            Add Monitoring Measure
                        </v-list-item>
                    </menu-btn>
                </div>
            </template>

            <template v-slot:body>
                <tabs :tabs="tabs" v-model="selectedTab"/>

                <div>
                    <v-text-field v-model="intvSearch" prepend-inner-icon="fas fa-search" flat solo dense/>
                </div>

                <div v-if="viewBy && viewBy.id === 'int'">
                    <div
                        v-if="interventions.length > 0"
                        v-for="item in interventions"
                        class="mb-3"
                        style="cursor: pointer;"
                        @click="loadIntervention(item)"
                    >
                        <div class="intv-title">
                            <span class="intv-sqr" :style="[getColor(item.intervention_level_icon)]"/>
                            {{ item.intervention_plan_name }}
                        </div>

                        <div class="intv-detail mb-2">{{ item.category_name }} - {{ item.school_year_name }}</div>

                        <div class="intv-status-btn mb-3" :style="[{'background-color': getStatusColor(item) }]">
                            {{ getOnTrack(item) }}
                        </div>

                        <div class="intv-detail" style="text-align: center;">Plan ROI: {{ item.roi }}</div>
                        <div class="intv-detail" style="text-align: center;">Goal ROI: {{ item.goal_roi }}</div>
                    </div>

                    <div v-if="interventions.length === 0">
                        <fe-empty-state
                            text="No interventions found"
                            style="font-size: 12px !important;"
                        />
                    </div>
                </div>

                <div v-if="viewBy.id === 'mon'">
                    <div
                        v-if="monitors.length > 0"
                        v-for="item in monitors"
                        class="mb-3 section-container"
                        @click="loadSelectedMonitor(item)"
                    >
                        <div class="intv-title d-flex">
                            <div style="cursor: pointer;">{{ item.sub_category_name }}</div>

                            <v-spacer/>

                            <menu-btn>
                                <v-list-item @click="printSummary(item)">Print Summary</v-list-item>

                                <v-list-item @click="selectedMonitor=item">Enter Scores</v-list-item>

                                <v-list-item @click="linkMonitor(item)">Link To Intervention</v-list-item>

                                <v-list-item @click="deleteMonitor(item)">Delete Monitor</v-list-item>
                            </menu-btn>
                        </div>

                        <div v-if="item.assessment_grade_desc" class="intv-detail">
                            {{ item.assessment_grade_desc }} Grade Level
                        </div>

                        <div class="intv-detail mb-2">{{ item.category_name }} - {{ item.school_year_name }}</div>

                        <div class="intv-detail" style="text-align: center;">ROI: {{ item.monitor_roi }}</div>

                        <div class="intv-detail" style="text-align: center;">Goal {{ item.goal }}</div>
                    </div>

                    <div v-if="monitors.length === 0">
                        <fe-empty-state
                            text="No monitors found"
                            style="font-size: 12px !important;"
                        />
                    </div>
                </div>
            </template>
        </fe-card>

        <fe-crud
            ref="crud"
            :config="$models.interventionROIStatus"
            :autoload="false"
            @read="v => items = v"
        />

        <fe-dialog
            v-if="selectedMonitor"
            title="Enter Score"
            dismissButtonText="Cancel"
            acceptButtonText="Done"
            width="800"
            persistent
            @accept="selectedMonitor=false"
            @close="selectedMonitor=false"
        >
            <div style="height: 60vh;">
                <student-monitor-scores
                    :params="{ student_monitor_plan_id: selectedMonitor.student_monitor_plan_id }"
                    :showFilters="false"
                />
            </div>
        </fe-dialog>

        <fe-dialog
            v-if="linkDialog.show"
            title="Link Interventions"
            dismissButtonText="Cancel"
            acceptButtonText="Link"
            width="800"
            :acceptButtonDisabled="linkDialog.selected.length === 0"
            persistent
            @accept="doLink"
            @close="linkDialog.show=false"
        >
            <selection-list
                v-model="linkDialog.selected"
                identifier="student_intervention_plan_id"
                style="height: 300px"
                :items="linkDialog.items"
                :multiple="false"
            >
                <template #row="{item}">
                    {{ item.intervention_phase_name ? item.intervention_phase_name : item.intervention_plan_name }} -
                    {{ item.intervention_level_name }}
                    <span
                        class="caption">({{ item.intervention_plan_start_date }} to {{ item.intervention_plan_end_date }})</span>
                </template>
            </selection-list>
        </fe-dialog>
    </div>
</template>

<script>
import Tabs from '@/components/common/button/Tabs'
import MenuBtn from '@/components/common/button/MenuBtn'
import StudentMonitorScores from '@/components/modules/intervention/monitor/StudentMonitorScores'
import SelectionList from '@/components/common/SelectionList'

export default {
    name: 'StudentIncidentCard',

    components: {
        Tabs,
        MenuBtn,
        StudentMonitorScores,
        SelectionList,
    },

    props: ['rec'],

    data() {
        return {
            items: [],
            intvSearch: null,
            viewBy: [{
                id: 'int',
                name: 'Interventions'
            }],
            views: [{
                id: 'int',
                name: 'Interventions'
            }, {
                id: 'mon',
                name: 'Monitors'
            }],
            tabs: [{
                name: 'Active'
            }, {
                name: 'Historical'
            }],
            selectedTab: null,
            monitorItems: [],
            selectedMonitor: false,
            linkDialog: {
                items: [],
                selected: [],
                show: false,
                rec: null
            }
        }
    },

    computed: {
        interventions() {
            let v = this.intvSearch

            if (!v) return this.filterByStatus(this.items, 'student_intervention_plan_intervention_end_date')

            let search = ['school_year_name', 'category_name', 'intervention_plan_name']

            let filtered = this.items.filter(rec => {
                let found = false
                search.forEach(n => {
                    if (rec[n].includes(v)) found = true
                })

                if (found) return rec
            })

            return this.filterByStatus(filtered, 'student_intervention_plan_intervention_end_date')
        },

        monitors() {
            let v = this.intvSearch

            if (!v) return this.filterByStatus(this.monitorItems, 'schedule_end_date')

            let search = ['school_year_name', 'category_name', 'sub_category_name']

            let filtered = this.monitorItems.filter(rec => {
                let found = false
                search.forEach(n => {
                    if (rec[n].includes(v)) found = true
                })

                if (found) return rec
            })
            return this.filterByStatus(filtered, 'schedule_end_date')
        }
    },

    watch: {
        'rec.student_id'(v) {
            if (v) {
                this.loadData()
            }
        },

        viewBy(v) {
            if (v.id === 'mon') {
                this.loadMonitors()
            }
        }
    },

    mounted() {
        if (this.rec && this.rec.student_id) {
            this.loadData()
        }
    },

    methods: {
        printSummary(rec) {
            window.open(`${process.env.VUE_APP_BASE_URI}studentMonitors.php?action=get&property=progress_charts&pdf=1&student_monitor_plan_id=${rec.student_monitor_plan_id}&student_id=${rec.student_id}`)
        },

        async linkMonitor(rec) {
            this.linkDialog.items = []
            let p = {
                student_id: rec.student_id,
                school_year_id: rec.school_year_id,
                property: 'interventions'
            }
            let resp = await this.$axios.get('studentInterventionPlans.php?action=get&' + this.$objectToParams(p))
            this.linkDialog.items = resp.data.students
            // add xSelect parameter so we can check the box of the intervention
            // to which this monitor has been assigned
            this.linkDialog.items.forEach((o, i) => {
                let xSelect = this.linkDialog.items[i].intervention_id === rec.intervention_id
                this.$set(this.linkDialog.items[i], "xSelect", xSelect)
            })
            this.linkDialog.show = true
            this.linkDialog.rec = rec
        },

        async doLink() {
            this.linkDialog.rec.intervention_id = this.linkDialog.selected[0].intervention_id

            let resp = await this.$axios.post('studentMonitors.php?action=update', {monitors: [this.linkDialog.rec]})
                .finally(() => {
                    this.loadMonitors()
                })
            // if calling this from progress monitoring page, refresh after linking
            if (typeof this.$parent.loadData !== 'undefined') {
                this.$parent.loadData(true)
            }
            let data = this.$ecResponse(resp)
        },

        deleteMonitor(rec, confirm) {
            let me = this
            this.$confirmDelete([rec], () => {
                this.$axios.post('studentMonitors.php?action=delete', {monitors: [rec]})
                    .then(resp => {
                        if (resp.data.confirm) {
                            // confirmed delete
                            me.$confirmDelete([1], () => {
                                me.$axios.post('studentMonitors.php?action=delete&confirm=1', {monitors: [rec]})
                                    .then(cr => {
                                        me.$ecResponse(cr)
                                        me.loadMonitors()
                                    })
                            }, () => {

                            }, resp.data.msg)

                            return
                        } else {
                            this.$ecResponse(resp)
                            this.loadMonitors()
                        }
                    })
            })
        },

        loadSelectedMonitor(item) {
            let me = this
            let windowId = this.$dockableWindow({
                name: item.student_full_name + ' ' + item.sub_category_name,
                component: 'academic-progress-monitoring',
                attrs: {
                    params: {
                        student_monitor_plan_id: item.student_monitor_plan_id,
                        monitor: item
                    },
                    height: window.innerHeight - 200,
                    monitorDecisions: true,
                    class: 'pa-5'
                },
                events: {
                    delete() {
                        me.$store.commit('global/removeDockedWindow', windowId)
                    }
                }
            })
        },

        loadIntervention(item) {
            this.$dockableWindow({
                name: item.intervention_plan_name,
                component: 'intervention-workspace',
                attrs: {
                    interventionPlanId: item.intervention_plan_id,
                    studentId: item.student_id,
                    interventionYear: { id: item.school_year_id, name: item.school_year_name }
                }
            })
        },

        filterByStatus(arr, dateField) {
            let v = this.selectedTab
            if (!v || !v.name) return arr

            let today = new Date()

            if (v.name === 'Active') {
                return arr.filter(rec => {
                    let endDate = new Date(rec[dateField])
                    return endDate > today

                })
            } else {
                return arr.filter(rec => {
                    let endDate = new Date(rec[dateField])
                    return endDate < today

                })
            }
        },

        loadData() {
            this.$refs.crud.read({
                student_id: this.rec.student_id,
                category_id: -1
            }).then(() => {
                this.viewBy = this.views[0]
            })
        },

        loadMonitors() {
            // set student_id based on request coming from inside or outside this component
            let student_id = null
            if (typeof this.rec === 'undefined') {
                student_id = this.linkDialog.selected[0].student_id
            } else {
                student_id = this.rec.student_id
            }
            this.monitorItems = []
            this.$axios.get('studentMonitors.php?action=get&academic=1&property=roi_target_list&probes_only=1&student_id=' + student_id)
                .then(response => {
                    this.monitorItems = response.data.monitors
                })
        },

        getColor(levelIcon) {
            let style = {'background-color': 'black'};

            switch (levelIcon) {
                case 'int_level_orange':
                    style = {'background-color': 'orange'}
                    break;
                case 'int_level_orange_ended':
                    style = {'background-color': 'orange', 'opacity': .5}
                    break;
                case 'int_level_maroon':
                    style = {'background-color': '#9b3939'}
                    break;
                case 'int_level_maroon_ended':
                    style = {'background-color': '#9b3939', 'opacity': .5}
                    break;
                case 'int_level_baby_blue':
                    style = {'background-color': '#4af4f7'}
                    break;
                case 'int_level_baby_blue_ended':
                    style = {'background-color': '#4af4f7', 'opacity': .5}
                    break;
                case 'int_level_purple':
                    style = {'background-color': '#ce4cbd'}
                    break;
                case 'int_level_purple_ended':
                    style = {'background-color': '#ce4cbd', 'opacity': .5}
                    break;
                case 'int_level_green':
                    style = {'background-color': '#4bc647'}
                    break;
                case 'int_level_green_ended':
                    style = {'background-color': '#4bc647', 'opacity': .5}
                    break;
                case 'int_level_yellow':
                    style = {'background-color': 'yellow'}
                    break;
                case 'int_level_yellow_ended':
                    style = {'background-color': 'yellow', 'opacity': .5}
                    break;
            }
            return style
        },

        getStatusColor(item) {
            if (item.category_id === 4) {
                if (item.four_week_possible_pct < 80 || item.goal > item.four_week_possible_pct) {
                    return 'red'
                } else {
                    return '#49C379'
                }
            } else {
                if (item.no_goal) {
                    return 'gray'
                } else if (item.on_track) {
                    return '#49C379'
                } else if (item.not_on_track) {
                    return 'red'
                } else if (!item.roi) {
                    return 'gray'
                }else {
                    return 'red'
                }
            }
        },

        getOnTrack(item) {
            if (item.category_id === 4) {
                return item.four_week_possible_pct < 80 || item.goal > item.four_week_possible_pct ? "Not On Track" : "On Track"
            } else {
                return item.on_track ? "On Track" 
                    : !item.roi ? 'No Data Entered' 
                    : item.no_goal ? 'No Goal' 
                    : "Not On Track"
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.list-text {
    font-size: 14px;
}

.section-container {
    border-bottom: 2px solid #ECEDF1;
}

.intv-title {
    font-size: 14px;
    color: #2B3963;
}

.intv-detail {
    font-size: 12px;
    font-weight: 300;
}

.intv-sqr {
    display: inline-block;
    height: 14px;
    width: 14px;
    margin-right: 8px;
}

.intv-status-btn {
    height: 32px;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    color: white;
    padding-top: 5px;
}
</style>
