<template>
    <v-layout column>
        <div class="headline mb-1 d-flex">
            Intervention Progress Monitoring
            <fe-info-tip
                class="ml-2"
                tooltip="Intervention Progress Monitoring"
            />
        </div>
        <div>Coming Soon&trade;</div>
    </v-layout>
</template>

<script>
    export default {
        name: "InterventionProgressMonitoring"
    }
</script>

<style scoped>

</style>
