<template>
    <div :style="{height: height ? height+'px' : undefined}">
        <div v-if="editor" class="d-flex justify-center empty-editor">
            {{fieldRec.name}}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FieldSpacer',
        props: ['fieldRec','editor', 'height'],
        data() {
            return {}
        }
    }
</script>

<style lang="scss" scoped>
div.empty-editor {
    display: block;
    height: calc(100% + 8px);
    border: 3px dashed rgba(0,0,0,.3);
}
</style>
