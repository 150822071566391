let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    aimswebAutomation: {
        defaults: {
            endpoint: 'automationManager.php',
            rootProperty: 'aimsweb',
            params: { property: 'aimsweb' }
        },
        ...standardCrud
    },
    instanceAutomation: {
        ...standardCrud,
        defaults: {
            endpoint: 'automationManager.php',
            rootProperty: 'instance',
        },
    },
    automationInstance: {
        ...standardCrud,
        read: {
            endpoint: 'api/core/automation/instance',
            rootProperty: 'instance',
        },
    },
}