<template>
    <div>
        <fe-card
            class="alt-card ma-3"
            headerText="Upcoming Reviews"
        >
            <template v-slot:body>
                <v-skeleton-loader
                    class="mx-auto"
                    type="text@5"
                    :loading="isLoading"
                    style="min-height: 120px;"
                >
                    <div class="text-center" style="height: 200px;">

                        <div v-for="item in items" class="d-flex my-2">
                            <div>{{item.name}}</div>
                            <div style="font-size: 16px; margin-left: 16px;">{{item.value}}</div>
                        </div>
                    </div>
                </v-skeleton-loader>
            </template>
        </fe-card>
    </div>
</template>

<script>
    export default {
        name: 'UpcomingReviews',
        props: {
            params: {

            }
        },
        watch: {
            params: {
                handler(v) {
                    if (v && Object.values(v).length) this.loadData()
                },
                immediate: true
            }
        },
        computed: {
            gridWidth() {
                return window.innerWidth-50
            }
        },
        data() {
            return {
                items: [],
                studentCount: 0,
                isLoading: false,
                summary: {
                    type: {},
                    group: {},
                    school: {},
                    grade: {},
                    user: {},
                    student: {}
                },
                dialog: {
                    show:false,
                    data: []
                }
            }
        },
        methods: {
            loadData() {
                this.items = []
                this.isLoading=true
                this.$axios.get('interventionView.php?action=get&property=upcoming_reviews&'+this.$objectToParams(this.params))
                    .then(r => {
                        let rec = this.$ecResponse(r, 'upcoming_reviews')

                        if (rec.length) {
                            for (let key in rec[0]) {
                                this.items.push({
                                    name: 'Upcoming in ' + key.replace('_day_cnt','') + ' Days',
                                    value: rec[0][key]
                                })
                            }
                        }

                        this.isLoading = false
                    })
            }
        },
    }
</script>

<style lang="scss" scoped>
.clickable {
    cursor: pointer;
}
</style>
