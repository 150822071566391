<template>
    <fe-dialog
        :title="title"
        width="1000"
        height="600"
        dismissable
        dismissButtonText="Cancel"
        :acceptButtonText="acceptButtonText"
        :acceptButtonDisabled="!dirty"
        :disableAutoclose="advancedSearch"
        :forceClose="!advancedSearch"
        @accept="save"
        @dismiss="dismiss"
    >
        <fe-tabs
            :tabs="tabs"
            no-padding
            @tabClicked="selectedTab = $event"
            style="height: 60px; margin-top: -20px;"
        />

        <div v-if="selectedTab.type === 'users'" style="height: 414px">
            <div v-show="!advancedSearch" class="flex-fill flex-column no-scroll">
                <div class="d-flex search-wrapper">
                    <fe-remote-combo
                        ref="usersSearchField"
                        placeholder="Search users"
                        url="users.php?action=get&active=1"
                        itemText="user_full_name"
                        v-model="userToAdd"
                        prepend-inner-icon="fas fa-search"
                        style="width: 100%"
                        @input="create($event, 'user')"
                    />
                    <span class="advanced-btn" @click="advancedSearch = true">
                    <u>Advanced</u>
                </span>
                </div>

                <div class="flex-fill flex-column no-scroll">
                    <fe-grid
                        ref="grid"
                        :rowData="mergedUsers.users"
                        :columnDefs="columnDefs"
                        :autoload="true"
                        :searchBar="false"
                        :showAddRowBtn="false"
                        :showDownload="false"
                        :showToolbar="false"
                        disableInlineCreate
                        disableFilterUpdate
                        suppressContextMenu
                        style="height: 100%;"
                        @cellValueChanged="update"
                    />
                </div>
            </div>

            <user-search v-if="advancedSearch" :gridHeight="365" @input="create($event, 'user')"/>
        </div>

        <div v-else style="height: 414px">
            <div class="flex-fill flex-column no-scroll">
                <div class="d-flex search-wrapper">
                    <fe-remote-combo
                        ref="usersSearchField"
                        placeholder="Search user groups"
                        :url="$models.getUrl('userGroup', 'read')"
                        rootProperty="user_group"
                        v-model="groupToAdd"
                        prepend-inner-icon="fas fa-search"
                        style="width: 100%"
                        @input="create($event, 'user_group')"
                    />
                </div>
                <div class="flex-fill flex-column no-scroll">
                    <fe-grid
                        ref="grid"
                        :rowData="mergedUsers.groups"
                        :columnDefs="columnDefs"
                        :autoload="true"
                        :searchBar="false"
                        :showAddRowBtn="false"
                        :showDownload="false"
                        :showToolbar="false"
                        disableInlineCreate
                        disableFilterUpdate
                        suppressContextMenu
                        style="height: 100%;"
                        @cellValueChanged="update"
                    />
                </div>
            </div>
        </div>

        <fe-crud ref="crud" :config="crudConfig" autoload @read="sharedUsers = $event"/>
    </fe-dialog>
</template>

<script>
import UserSearch from "@/components/common/UserSearch"

export default {
    name: "ShareDashboard",

    components: {UserSearch},

    props: {
        dashboard: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            advancedSearch: false,
            userToAdd: undefined,
            groupToAdd: undefined,
            changes: {
                create: [],
                update: [],
                destroy: []
            },
            sharedUsers: [],
            tabs: [
                {
                    name: 'Users',
                    path: '#',
                    type: 'users'
                }, {
                    name: 'User Groups',
                    path: '#',
                    type: 'groups'
                }
            ],
            selectedTab: {
                name: 'Users',
                path: '#',
                type: 'users'
            }
        }
    },

    computed: {
        currentUser() {
            return this.$store.state.global.sessionUser.user
        },

        title() {
            return this.advancedSearch ? 'Find User' : 'Share Dashboard'
        },

        acceptButtonText() {
            return this.advancedSearch ? 'Done' : 'Save'
        },

        crudConfig() {
            let cfg = this.$_.cloneDeep(this.$models.dashboardUsers)
            cfg.read.params.kpi_dashboard_id = this.dashboard.id
            return cfg
        },

        dirty() {
            let changes = this.changes
            return changes.create.length || changes.update.length || changes.destroy.length
        },

        filteredUsers() {
            return this.sharedUsers.filter(x => {
                let createdBy = x.user_id === this.dashboard.created_by
                let currentUser = x.user_id === this.currentUser.id
                return !createdBy && !currentUser
            })
        },

        mergedUsers() {
            let merged = this.$_.cloneDeep(this.filteredUsers)
            if (this.changes.destroy.length) {
                let ids = this.changes.destroy.map(x => x.id)
                merged = merged.filter(x => !ids.includes(x.id))
            }
            if (this.changes.update.length) {
                this.changes.update.forEach(x => {
                    let index = merged.findIndex(y => y.id === x.id)
                    merged[index] = x
                })
            }
            if (this.changes.create.length) {
                merged = merged.concat(this.changes.create)
            }

            return {
                users: this.$_.sortBy(merged.filter(x => x.type === 'user'), 'user_full_name'),
                groups: this.$_.sortBy(merged.filter(x => x.type === 'user_group'), 'user_group_name')
            }
        },

        columnDefs() {
            let columns = [{
                headerName: "Name",
                field: 'user_full_name',
                valueGetter: v => v.data.user_full_name ? v.data.user_full_name : v.data.user_group_name,
                sortable: true
            }, {
                headerName: "Created By",
                field: 'created_by_full_name',
                sortable: true
            }]

            if (this.dashboard.can_modify) {
                columns.push({
                    headerName: "Can Edit",
                    field: 'can_modify',
                    cellRendererFramework: "FeGridToggle",
                    cellRendererParams: {
                        rowDataKey: 'can_modify',
                        disabled: !this.dashboard.can_modify
                    },
                    maxWidth: 160,
                    sortable: true
                })
            }

            columns.push({
                headerName: "Can Share",
                field: 'can_share',
                cellRendererFramework: "FeGridToggle",
                cellRendererParams: {
                    rowDataKey: 'can_share'
                },
                maxWidth: 160,
                sortable: true
            })

            if (this.dashboard.can_duplicate) {
                columns.push({
                    headerName: "Can Duplicate",
                    field: 'can_duplicate',
                    cellRendererFramework: "FeGridToggle",
                    cellRendererParams: {
                        rowDataKey: 'can_duplicate',
                        disabled: !this.dashboard.can_duplicate
                    },
                    maxWidth: 160,
                    sortable: true
                })
            }

            if (this.dashboard.can_delete) {
                columns.push({
                    headerName: "Can Delete",
                    field: 'can_delete',
                    cellRendererFramework: "FeGridToggle",
                    cellRendererParams: {
                        rowDataKey: 'can_delete',
                        disabled: !this.dashboard.can_delete
                    },
                    maxWidth: 160,
                    sortable: true
                })
            }

            if (this.currentUser.id === this.dashboard.created_by) {
                columns.push({
                    headerName: "",
                    field: null,
                    maxWidth: 65,
                    cellRenderer: () => {
                        return '<i class="fas fa-minus-circle" style="cursor: pointer;"/>'
                    },
                    sortable: false,
                    onCellClicked: v => {
                        this.destroy(v, this.selectedTab.type)
                    }
                })
            }

            return columns
        }
    },

    methods: {
        create(user, type) {
            if (!user) return

            if (!this.$_.isArray(user)) {
                user = [user]
            }

            let dashboardId = this.dashboard.id
            let creator = this.currentUser

            user.forEach(x => {
                let notAlreadyAdding = !this.$_.find(this.changes.create, y => y.user_id === x.id)
                let notCreator = x.id !== this.dashboard.created_by

                if (notAlreadyAdding && notCreator) {
                    let newUser = {
                        can_duplicate: 0,
                        can_modify: 0,
                        can_share: 0,
                        can_delete: 0,
                        type: type,
                        created_by: creator.id,
                        created_by_full_name: creator.user_full_name,
                        kpi_dashboard_id: dashboardId
                    }

                    if (type === 'user') {
                        newUser.user_full_name = x.user_full_name
                        newUser.user_id = x.id
                    } else if (type === 'user_group') {
                        newUser.user_group_name = x.name
                        newUser.user_group_id = x.id
                    }

                    this.changes.create.push(newUser)
                }
            })

            this.$nextTick(() => {
                this.userToAdd = undefined
                document.activeElement.blur()
            })
        },

        update(cell) {
            let data = cell.data

            if (data.id) {
                let updateIndex = this.$_.findIndex(this.changes.update, x => x.id === data.id)
                if (updateIndex !== -1) {
                    let existing = this.filteredUsers.filter(x => x.id === data.id)[0]
                    if (this.$_.isEqual(existing, data)) {
                        this.changes.update.splice(updateIndex, 1)
                    } else {
                        this.changes.update.splice(updateIndex, 1, data)
                    }
                } else {
                    this.changes.update.push(data)
                }
            }
        },

        destroy(cell) {
            let data = cell.data

            if (data.id) {
                this.changes.destroy.push(data)

                let updateIndex = this.$_.findIndex(this.changes.update, x => x.id === data.id)
                if (updateIndex !== -1) {
                    this.changes.update.splice(updateIndex, 1)
                }
            } else {
                this.changes.create = this.changes.create.filter(x => x.user_id !== data.user_id)
            }
        },

        save() {
            if (this.advancedSearch) {
                this.advancedSearch = false
            } else {
                let transactions = []
                let changes = this.$_.cloneDeep(this.changes)

                let crud = this.$refs.crud

                if (changes.destroy.length) {
                    transactions.push(crud.destroy(changes.destroy))
                }
                if (changes.update.length) {
                    transactions.push(crud.update(changes.update))
                }
                if (changes.create.length) {
                    transactions.push(crud.create(changes.create))
                }

                Promise.all(transactions)
                    .then(() => {
                        this.$emit('close')
                    })
            }
        },

        dismiss() {
            if (this.advancedSearch) {
                this.advancedSearch = false
            } else {
                this.$emit('close')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.search-wrapper {
    &:focus-within {
        .advanced-btn {
            display: none;
        }
    }
}

.advanced-btn {
    position: absolute;
    right: 60px;
    top: 109px;
    font-size: 12px;
    color: var(--v-primary-base);
    cursor: pointer;
}
</style>
