<template>
    <v-layout column>
        <div class="headline mb-1 d-flex">
            School Groups
            <fe-info-tip
                class="ml-2"
                tooltip='School groups allow users to more easily and efficiently search for schools that your district has named and grouped together. A common example is creating a group type by level such as “Elementary Schools.”'
            />
        </div>

        <fe-crud-grid
            ref="grid"
            :config="crudConfig"
            :columnDefs="columnDefs"
            displayDensity="medium"
            refreshOnCreated
            refreshOnUpdated
            disableInlineCreate
            @beginCreate="beginEntry"
        >
            <template v-slot:cellDialog="{cell}">
                <simple-grid-dialog-column
                    ref="dialog"
                    :cell="cell"
                    title="Schools"
                    :crudConfig="schoolGroupSchoolCrudConfig"
                    :columnDefs="detailColumnDefs"
                    :onRead="detailRead"
                    :buildInsertRec="buildDetailRec"
                    :defaultParams="{
                        school_group_id: cell.data.school_group_id
                    }"
                    :insertDefaults="{
                        school_group_id: cell.data.school_group_id
                    }"
                    @close="detailClose"
                    @created="load(true)"
                    @destroyed="load(true)"
                >
                    <template slot="belowGrid">
                        <div class="d-flex" v-if="cell">
                            <fe-remote-combo
                                style="margin-top:16px; width:100%;"
                                ref="simpleInsertField"
                                itemText="name"
                                itemValue="id"
                                :items="getAvailableSchools(cell.data.schools)"
                                placeholder="Add a School"
                                @input="detailSelection"
                            />
                            <fe-btn
                                style="margin-top: 10px;"
                                :disabled="disableDetailAdd"
                                @click="detailAdd"
                            >
                                Add
                            </fe-btn>
                        </div>
                    </template>
                </simple-grid-dialog-column>
            </template>
        </fe-crud-grid>

        <fe-dialog
            v-if="createDialog.show"
            title="Create School Group"
            :footer="false"
            persistent
            disableAutoclose
            @close="dismissDialog"
        >
            <model-form
                idProperty="id"
                :records="createDialog.records"
                :fieldConfig="fieldConfigs"
                model="schoolGroups"
                @cancel="dismissDialog"
                @done="created"
            />
        </fe-dialog>

        <fe-crud ref="schools" :config="schoolsCrudConfig" autoload @read="schools = $event"/>
    </v-layout>
</template>

<script>
    import SimpleGridDialogColumn from "../../../common/SimpleGridDialogColumn"
    import ModelForm from '@/components/common/form/ModelForm'

    export default {
        name: "SchoolGroups",

        components: {SimpleGridDialogColumn, ModelForm},

        data() {
            let me = this
            return {
                createDialog: {show: false, records: []},
                schools: [],
                cell: undefined,
                columnDefs: [
                    {
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        width: 70,
                        minWidth: 70,
                        colId: 'checkbox_column',
                    },
                    {
                        headerName: 'Name',
                        field: 'name',
                        editable: true,
                    },
                    {
                        headerName: 'Schools',
                        field: 'schools',
                        editable: false,
                        valueGetter: params => {
                            return params.data.schools.length
                        },
                        cellRendererFramework: "FeDialogColumn",
                        onCellClicked: v => {
                            this.cellClicked(v)
                        }
                    },
                    {
                        headerName: 'Date Created',
                        field: 'created',
                        editable: false
                    },
                    {
                        headerName: 'Created By',
                        field: 'created_by_name',
                        editable: false
                    },
                    {
                        headerName: 'Order',
                        field: 'rank',
                        maxWidth: 90,
                        valueGetter: v => {
                            let r = me.schools.find(r=>r.id===v.data.id)
                            return r !== undefined ? r?.rank : 0
                        },
                        cellEditorParams: { type: 'number' },
                        cellStyle: v => {
                            return {
                                'color': v.data.schools.length > 0 ? '#333333' : '#CCCCCC',
                                'pointer-events': v.data.schools.length > 0 ? '' : 'none'
                            }
                        },
                        valueFormatter: v => { return v.value.toString() },
                        onCellValueChanged: v => me.updateRank(v.data),
                        editable: true
                    },
                ],
                detailColumnDefs: [
                    {
                        headerName: 'School Name',
                        field: 'school_name'
                    }
                ],
                disableDetailAdd: true
            }
        },

        computed: {
            crudConfig() {
                return _.cloneDeep(this.$models.schoolGroups)
            },

            schoolsCrudConfig() {
                let cfg = _.cloneDeep(this.$models.school)
                cfg.read.params['incl_groups'] = 1
                return cfg
            },

            schoolGroupSchoolCrudConfig() {
                return _.cloneDeep(this.$models.schoolGroupSchool)
            },

            fieldConfigs() {
                return [
                    {
                        field: 'name',
                        label: 'School Group Name',
                        component: 'v-text-field',
                        validatorKey: 'text',
                        validatorConfig: {required: true},
                        width: '100%',
                        attrs: {
                            flat: true,
                            solo: true,
                            dense: true
                        }
                    }
                ]
            }
        },

        created() {
            this.$parent.$once('show schoolGroups', () => {
                this.$refs.grid.$refs.grid.resize()
            })
        },

        methods: {
            load(refresh=false) {
                this.$refs.grid.$refs.crud.read()
                // refresh cells if we just update list of schools for group
                if (refresh) {
                    // take a beat to make sure cellStyle gets updated
                    setTimeout(() => {
                        this.$refs.grid.$refs.grid.gridApi.refreshCells({force: true})
                    }, 500)
                }
            },

            cellClicked(cell) {
                this.disableDetailAdd = true
                this.$refs.grid.$refs.grid.setDialogCell(undefined)
                this.$nextTick(() => {
                    this.cell = cell
                    this.$refs.grid.$refs.grid.setDialogCell(cell)
                })
            },

            beginEntry() {
                this.resetEntry()
                this.createDialog.show = true
            },

            dismissDialog() {
                this.createDialog.show = false
            },

            resetEntry() {
                this.createDialog.records = [
                    {
                        name: undefined
                    }
                ]
            },

            created() {
                this.load()
                this.dismissDialog()
            },

            detailRead(data) {
                return data.map(x => {
                    let school = this.schools.filter(y => y.id === x.school_id)
                    if (!school || school.length === 0) {
                        x.school_name = 'Restricted by Permission'
                    } else {
                        x.school_name = school[0].name
                    }


                    return x
                })
            },

            buildDetailRec(v, data) {
                data.school_id = v
                return data
            },

            detailAdd() {
                this.$refs.dialog.createItem()
            },

            detailSelection(v) {
                if (v) {
                    this.$refs.dialog.insertValue = v.id
                    this.disableDetailAdd = false
                } else {
                    this.$refs.dialog.insertValue = undefined
                    this.disableDetailAdd = true
                }
            },

            detailClose() {
                this.$refs.grid.$refs.grid.openMenu = false
            },

            getAvailableSchools(existing) {
                let ids = existing ? existing : []
                return this.schools.filter(x => !ids.includes(x.id))
            },

            updateRank(rec) {
                this.$axios.post('schools.php?action=update', { schools: [rec] })
                    .then(resp => {
                        setTimeout(() => {
                            this.$refs.schools.read()
                        }, 10)
                    })
            },
        }
    }
</script>

<style scoped>

</style>
