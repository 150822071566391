<template>
    <fe-dialog
        :title="title"
        width="400"
        acceptButtonText="Apply"
        dismissButtonText="Cancel"
        :acceptButtonDisabled="!riskRatioValid"
        dismissable
        persistent
        disableAutoclose
        @accept="submit"
        @close="$emit('close')"
    >
        <template v-slot:header>
            Calculate Risk Ratio
            <div class='title-tip-adj'>
                <fe-info-tip
                    tooltip="Use to determine how well your practices are meeting the needs of your students by
                        calculating and identifying disproportionality in discipline practices"
                />
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('close')">
                <v-icon>close</v-icon>
            </v-btn>
        </template>

        <v-form @submit.prevent>
            <v-layout row wrap>
                <v-flex xs12>
                    <fe-remote-combo
                        label="Select Comparison Type"
                        :items="calculateByOptions"
                        v-model="riskRatio.calculate_by"
                        itemText="text"
                        itemValue="id"
                        hide-details
                        @change="resetControl"
                    />

                    <fe-label>
                        Select Control Group
                        <div class='title-tip-adj'>
                            <fe-info-tip
                                tooltip="The control group is the largest segment of the student population.
                                    If 65% of your student population is white, the control is white"
                            />
                        </div>
                    </fe-label>

                    <fe-remote-combo
                        v-show="calculateBy === 'ethnicity'"
                        :url="$models.getUrl('ethnicityType', 'read')"
                        itemText="ethnicity"
                        itemValue="ethnicity"
                        rootProperty="ethnicities"
                        v-model="riskRatio.control.ethnicity"
                        hide-details
                    />

                    <fe-remote-combo
                        v-show="calculateBy === 'gender'"
                        :url="$models.getUrl('genderType', 'read')"
                        itemText="gender"
                        itemValue="gender"
                        rootProperty="genders"
                        v-model="riskRatio.control.gender"
                        hide-details
                    />

                    <fe-remote-combo
                        v-show="calculateBy === 'disability'"
                        :items="[{id: 0, name: 'No Disability'}, {id: 1, name:'Has Disability'}]"
                        v-model="riskRatio.control.disability"
                        hide-details
                    />

                    <fe-remote-combo
                        v-show="calculateBy === 'tag_id'"
                        :url="$models.getUrl('tag', 'read')"
                        rootProperty="tags"
                        v-model="riskRatio.control.tag_id"
                        hide-details
                    />

                    <fe-remote-combo
                        v-show="!calculateBy"
                        hide-details
                        disabled
                    />

                    <fe-remote-combo
                        label="Select School Year"
                        v-model="riskRatio.school_year_id"
                        :url="$models.getUrl('schoolYear', 'read')"
                        rootProperty="years"
                        multiple
                        hide-details
                        byId
                    />

                    <fe-label>Select Date Range (Optional)</fe-label>
                    <div class="d-flex">
                        <div class="pr-2">
                            <fe-label>Start Date</fe-label>
                            <fe-date-picker
                                v-model="riskRatio.start_date"
                                prependIcon="fa-calendar-alt"
                                dateFormat="MM/DD/YYYY"
                            />
                        </div>

                        <div>
                            <fe-label>End Date</fe-label>
                            <fe-date-picker
                                ref="endDate"
                                v-model="riskRatio.end_date"
                                :rules="rules.endDate"
                                prependIcon="fa-calendar-alt"
                                dateFormat="MM/DD/YYYY"
                            />
                        </div>
                    </div>

                    <v-expansion-panels multiple flat v-model="panelsOpen">
                        <v-expansion-panel class="mt-2">
                            <v-expansion-panel-header class="pa-0">Filter Incidents</v-expansion-panel-header>
                            <v-expansion-panel-content class="pa-0">
                                <fe-remote-combo
                                    label="Type"
                                    v-model="riskRatio.incident_behavior_type_id"
                                    :url="$models.getUrl('incidentBehaviorType', 'read')"
                                    rootProperty="incident_behavior_type"
                                    multiple
                                    hide-details
                                    byId
                                />
                                <fe-remote-combo
                                    label="Code"
                                    v-model="riskRatio.incident_behavior_id"
                                    :url="$models.getUrl('incidentBehavior', 'read')"
                                    rootProperty="incident_behavior"
                                    multiple
                                    hide-details
                                    byId
                                />
                                <fe-remote-combo
                                    label="Response"
                                    v-model="riskRatio.incident_response_id"
                                    :url="$models.getUrl('incidentResponse', 'read')"
                                    rootProperty="incident_responses"
                                    multiple
                                    hide-details
                                    byId
                                />
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel class="mt-2">
                            <v-expansion-panel-header class="pa-0">Filter Demographics</v-expansion-panel-header>
                            <v-expansion-panel-content class="pa-0">
                                <fe-remote-combo
                                    label="School"
                                    v-model="riskRatio.school_id"
                                    :url="$models.getUrl('school', 'read')"
                                    :params="{ 'school_year_id': riskRatio.school_year_id }"
                                    rootProperty="schools"
                                    hide-details
                                    byId
                                />
                                <fe-remote-combo
                                    label="Grade"
                                    v-model="riskRatio.grade_id"
                                    :url="$models.getUrl('grade', 'read')"
                                    rootProperty="grades"
                                    hide-details
                                    byId
                                />
                                <fe-remote-combo
                                    label="Tags"
                                    v-model="riskRatio.tag_id"
                                    :url="$models.getUrl('tag', 'read')"
                                    rootProperty="tags"
                                    hide-details
                                    byId
                                />
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-flex>
            </v-layout>
        </v-form>
    </fe-dialog>
</template>

<script>
    import {mapState} from "vuex"

    export default {
        name: "RiskRatioForm",

        props: {
            title: {
                type: String,
                default: 'Risk Ratio Search'
            },
            params: {
                default: undefined
            },
            currentYear: {
                default: () => {
                }
            }
        },

        data() {
            return {
                riskRatio: {
                    school_year_id: undefined,
                    school_id: undefined,
                    grade_id: undefined,
                    tag_id: undefined,
                    incident_behavior_type_id: undefined,
                    incident_behavior_id: undefined,
                    incident_response_id: undefined,
                    calculate_by: undefined,
                    start_date: undefined,
                    end_date: undefined,
                    control: {
                        ethnicity: undefined,
                        gender: undefined,
                        disability: undefined,
                        tag_id: undefined
                    }
                },
                calculateByOptions: [{
                    text: 'Ethnicity',
                    id: 'ethnicity'
                }, {
                    text: 'Gender',
                    id: 'gender'
                }, {
                    text: 'Disability',
                    id: 'disability'
                }, {
                    text: 'Tag',
                    id: 'tag_id'
                }],
                panelsOpen: [],
                rules: {
                    endDate: [v => !this.$dayjs(v).isBefore(this.riskRatio.start_date) || 'Not a valid End Date']
                },
                panels: {
                    incidents: [],
                    demographics: []
                }
            }
        },

        computed: {
            ...mapState('global', ['defaultSchool']),
            riskRatioValid() {
                let riskRatio = this.riskRatio
                if (!riskRatio.school_year_id) return false
                if (riskRatio.start_date
                    && riskRatio.end_date
                    && this.$dayjs(riskRatio.end_date).isBefore(riskRatio.start_date)) return false
                if (riskRatio.calculate_by) {
                    return !!riskRatio.control[riskRatio.calculate_by.id]
                } else {
                    return false
                }
            },

            calculateBy() {
                return this.riskRatio.calculate_by ? this.riskRatio.calculate_by.id : false
            }
        },

        watch: {
            'riskRatio.start_date': {
                handler(v) {
                    this.$nextTick(() => {
                        if (v) {
                            this.$refs.endDate.$refs.field.validate()
                        }
                    })
                }
            }
        },

        created() {
            if (this.params) {
                let params = this.$_.cloneDeep(this.params)
                if (
                    params.incident_behavior_id
                    || params.incident_behavior_type_id
                    || params.incident_response_id
                ) {
                    this.panelsOpen.push(0)
                }

                if (
                    params.school_id
                    || params.grade_id
                    || params.tag_id
                ) {
                    this.panelsOpen.push(1)
                }

                this.riskRatio = params
            } else {
                this.riskRatio.school_year_id = this.currentYear.id
            }
        },

        mounted() {
            this.$nextTick(() => {
                if (this.params?.school_id) {
                    this.riskRatio.school_id = this.params.school_id
                }

                // Don't apply default school if it's a custom school group
                // (e.g. "Elementary schools") because those schools do not appear in dropdown
                else if (this.defaultSchool && !this.defaultSchool.school_group_id) {
                    this.riskRatio.school_id = this.defaultSchool.id
                }
            })
        },

        methods: {
            resetControl() {
                this.riskRatio.control = {
                    ethnicity: undefined,
                    gender: undefined,
                    disability: undefined,
                    tag_id: undefined
                }
            },

            submit() {
                let args = this.$_.cloneDeep(this.riskRatio)
                if (args.start_date && !args.end_date) delete args.start_date
                if (args.end_date && !args.start_date) delete args.end_date
                this.$emit('accept', args)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .title-tip-adj {
        display: inline-block;
        position: relative;
        top: -3px;
        left: 5px;
    }
</style>
