<template>
    <collapsible-panel title="Attendees" smallTitle>
        <state-button
            v-for="atnd in attendeesWithImages"
            class="avatar-state"
            :key="`attendee-state-${atnd.id}`"
            :text="atnd.user_full_name"
            :value="atnd.in_attendance"
            @input="updateAttendance(atnd, $event)"
        >
            <v-avatar v-if="!atnd.in_attendance" size=22>
                <img v-if="atnd.photo_url" :src="atnd.photo_url" :alt="atnd.user_full_name">
                <v-icon v-else style="font-size: 26px;">fas fa-user-circle</v-icon>
            </v-avatar>
            <span class="avatar-state--name">{{ atnd.user_full_name }}</span>
        </state-button>
        <fe-crud
            ref="crud"
            autoload
            v-if="meeting && occurrence"
            :config="$models.meetingAttendance"
            :defaultParams="crudParams"
            @read="attendees = $event"
            refresh-on-config
        />
    </collapsible-panel>
</template>

<script>
    import CollapsiblePanel from '@/components/common/CollapsiblePanel'
    import StateButton from '@/components/common/button/StateButton'

    export default {
        name: 'Attendees',
        components: { CollapsiblePanel, StateButton },
        props: {
            meeting: { type: Object, required: true },
            occurrence: { type: Object },
        },
        data () {
            return {
                attendees: []
            }
        },
        watch: {
            meeting () { if (this.$refs.crud) this.$refs.crud.read() }
        },
        computed: {
            crudParams () {
                return {
                    dashboard_meeting_id: this.meeting.id,
                    occurrence: this.$dayjs(this.occurrence.occurrence, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss")
                }
            },
            attendeesWithImages () {
                return this.attendees.map(atnd => {
                    let match = this.meeting.dashboard_meeting_users.find(itm => itm.user_id == atnd.user_id)
                    return Object.assign({}, atnd, {
                        photo_url: (match && match.photo_url) || null
                    })
                })
            }
        },
        methods: {
            updateAttendance (atnd, v) {
                this.$refs.crud.update(Object.assign({}, atnd, {
                    in_attendance: v
                }))

                // Refresh crud data so that e.g. check mark appears
                this.$refs.crud.read()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .avatar-state {
        margin: 0 20px 10px 0;
        &--name {
            position: relative;
            top: 1px;
            margin-left: 10px;
        }
        .v-avatar {
            position: relative;
            top: -1px;
        }
    }
</style>
