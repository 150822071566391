<template>
    <v-dialog
        v-model="value"
        max-width="400"
    >
        <v-card>
            <v-card-title class="headline">Email Incident</v-card-title>
            <div style="padding: 0 0 0 7px;">
                <v-checkbox
                label="Include description"
                v-model="includeDescription"
                @click="confirmIncludeDescription"
                ></v-checkbox>
            </div>
            <div style="overflow:auto; max-height:70vh;">
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="items"
                    item-key="student_id"
                    show-select
                    hide-default-footer
                >
                    <template slot="items" slot-scope="props">
                        <td><v-checkbox v-model="props.selected" primary hide-details /></td>
                        <td>{{ props.item.student_full_name }}</td>
                        <td>{{ props.item.guardian_full_name }}</td>
                    </template>
                </v-data-table>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <fe-btn usage="tertiary" @click="$emit('input',false)">Cancel</fe-btn>
                <fe-btn color="primary" @click="emailIncident">Mail</fe-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
    name: 'EmailIncident',
    props: {
        value: { type: Boolean, default: false },
        students: { type: Array }
    },
    watch: {
        value(val) {
            if(!val || this.isLoading) return
            this.includeDescription = false
            this.isLoading = true
            let studentIds = this.students.map( x => x.student_id )
            let url = `students.php?action=get&property=guardians&student_id=`+studentIds.join()
            this.$axios.get(url).then((response) => {
                this.isLoading = false
                if (response && response.data) {
                    this.items = response.data.students
                    this.selected = this.items
                }
            }).catch((err) => {
                console.warn('err', err)
            })
        }
    },
    methods: {
        emailIncident() {
            if(this.isLoading) return
            this.isLoading = true
            let notify = []
            this.selected.forEach(x => {
                this.students.forEach( y => {
                    if(y.student_id == x.student_id) {
                        notify.push({
                            student_incident_id: y.student_incident_id,
                            guardian_id: x.guardian_id,
                            student_id: x.student_id,
                            include_description: this.includeDescription
                        })
                    }
                })
            })
            if(notify.length) {
                let url = `behaviorIncident.php?action=send_guardian_notification`
                let payload = {
                    guardians: notify
                }
                this.$axios.post(url,JSON.stringify(payload)).then((r) => {
                    this.isLoading = false
                    if(r.data) {
                        if(r.data.success) {
                            this.$snackbars.$emit('new', { text: r.data.msg, usage: 'success' })
                        } else {
                            this.$snackbars.$emit('new', { text: r.data.msg, usage: 'warning' })
                        }
                    }
                }).catch((err) => {
                    console.warn('err', err)
                })
            }
            this.$emit('input', false)
        },
        confirmIncludeDescription() {
            if(this.includeDescription) {
                this.$messageBox({
                    title: 'Include Description',
                    persistent: true,
                    message: 'Are you sure you wish include the description of this incident?<br>' +
                        '<br><b>Ensure the description contains appropriate information for the guardian.</b>',
                    maxWidth: '500',
                    actions: [{
                        text: 'No',
                        onClick: () => {
                            this.includeDescription = false
                        }
                    }, {
                        text: 'Yes',
                        primary: true
                    }]
                })
            }
        }
    },

    data() {
        return {
            headers: [{
                text: 'Student',
                align: 'left',
                value: 'student_full_name',
                width: '50%'
            }, {
                text: 'Guardian',
                align: 'left',
                value: 'guardian_full_name',
                width: '45%'
            }],
            items: [],
            selected: [],
            includeDescription: false,
            isLoading: false
        }
    }
}
</script>
