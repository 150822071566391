<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on }">
            <span class="option-chip">
                <fe-chip direction="top" v-on="on" v-if="activator=='chip'">
                    <span style="color: white">{{title}}</span>
                </fe-chip>

                <fe-chip direction="top" v-on="on" v-if="activator=='menu'">
                    <v-icon>vert_more</v-icon>
                </fe-chip>
            </span>
        </template>

        <div class="option-chip-panel">
            <div class="option-chip-title" v-if="title">
                {{ titleWithCount }}
            </div>

            <div class="option-chip-items" :style="[{ height: showButton ? '150px' : '185px' }]">
                <v-list dense>
                    <v-list-item v-for="(item, i) in localItems" :key="`option-chip-item-`+i">
                        <v-list-item-content class="option-chip-item">{{ item[itemText] }}</v-list-item-content>
                    </v-list-item>
                </v-list>
            </div>

            <div v-if="showButton" class="option-chip-button" @click="$emit('buttonClick')">{{buttonText}}</div>
        </div>
    </v-menu>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'DetailChip',
        props: {
            url: {
                type: String
            },
            model: {
                type: String
            },
            items: {
                // type: Array,
                default: () => []
            },
            title: {
                type: String
            },
            itemText: {
                type: String,
                default: 'name'
            },
            itemValue: {
                type: String,
                default: 'id'
            },
            buttonText: {
                type: String,
                default: 'Okay'
            },
            listTitle: {
                type: String,
                default: 'Items'
            },
            showButton: {
                type: Boolean,
                default: true
            },
            activator: {
                type: String,
                default: 'chip'
            }
        },
        computed: {
            titleWithCount() {
                return this.$props.listTitle + ' (' + this.localItems.length + ')'
            }
        },
        data() {
            return {
                localItems: []
            }
        },
        created() {
            this.load()
        },
        methods: {
            load() {
                if (this.$props.model) {
                    this.loadByModel()
                } else if (this.$props.items) {
                    if (Array.isArray(this.$props.items)) {
                        this.$data.localItems = this.$props.items
                    } else {
                        this.$data.localItems = [this.$props.items]
                    }
                }
            },
            loadByModel() {
                this.$modelFetch(this.$models[this.$props.model])
                    .then(data => {
                        this.$data.localItems = data
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }
        }
    }
</script>

<style lang="scss">
#app .option-chip .v-chip__content {
    cursor: pointer;
}

.option-chip {
    .v-chip {
        background-color: #747F9E !important;

        &:hover {
            background-color: #495B8F !important;
        }
    }
}

</style>

<style lang="scss" scoped>

.option-chip-panel {
    min-width: 231px;
    // height: 260px;
    left: 0px;
    top: 0px;
    padding: 15px 20px 5px 20px;
    background: #FFFFFF;
    border-radius: 5px;
    color: #2B3963;
    overflow: hidden;

    .option-chip-items {
        overflow: auto;
        margin-left: -10px;

        .option-chip-item {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 30px;
        }
    }

    .option-chip-button {
        margin-top: 18px;
        width: 100%;
        height: 32px;
        padding-top: 6px;
        text-align: center;
        color: white;
        background-color: #7AA2D3;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #7AA2D3;
        }
    }
}

.option-chip-title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 30px;
    color: #050F2D;
    padding-left: 5px;
}
</style>