<template>
    <fe-crud-grid
        ref="grid"
        :config="$models.studentTags"
        :columnDefs="columnDefs"
        :readParams="params"
        v-bind="$attrs"
        :bordered="false"
        :showDensityToolbar="false"
        :showSaveAsDefault="false"
        :showBorderToggle="false"
        :showFullscreen="false"
        :showAddRowBtn="true"
        addRowBtnText="Add Student Tag"
        @beginCreate="$emit('addTagClick', $props.student, $refs.grid)"
        @destroyed="$emit('updateGrid')"
        disableInlineCreate
        minimal
    />
</template>

<script>

    export default {
        name: 'StudentTagList',
        props: {
            params: {
                required: true
            },
            student: null
        },

        data() {
            return {
                columnDefs: [{
                    headerName: null,
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    width: 70,
                    minWidth: 70,
                    colId: 'checkbox_column'
                }, {
                    headerName: "Tag",
                    field: "tag_name",
                    sortable: true,
                    cellRenderer(v) {
                        let str = '<i class="fas fa-tag mr-2" style="color: ' + v.data.tag_color + ';"></i>' + v.value
                        return str
                    }
                }, {
                    headerName: "School Year",
                    field: "school_year_name",
                    sortable: true
                }, {
                    headerName: "Description",
                    field: "tag_description"
                }]
            }
        },
        methods: {
            reload () {
                if (this.$refs.grid) this.$refs.grid.$refs.crud.read()
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>