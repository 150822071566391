<template>
    <div>
        <!--<fe-chart-->
            <!--class="ma-2"-->
            <!--:name="title"-->
            <!--:series="series"-->
            <!--:height="350"-->
            <!--legend="bottom"-->
            <!--:ref="`chart-`+ title"-->
            <!--:xAxisCategories="categories"-->
            <!--:extraConfigs="extraConfigs"-->
            <!--type="null"-->
            <!--ref="academicProgressMonitoringChart"-->
        <!--&gt;</fe-chart>-->
        <chart v-if="monitor.student_monitor_plan_id" :config="localConfigs" class="mr-2" ref="academicProgressMonitoringChart" :collectionChart="collectionChart" :monitor="monitor" :isCollection="isCollection">
            <template #before-menu v-if="showTools && collectionChart">
                <fe-icon-btn useIcon="fas fa-expand-alt" @click="doExpand"/>
            </template>
        </chart>
    </div>
</template>

<script>
    import Chart from "./Chart";
    export default {
        name: 'AcademicProgressMonitoring2',
        components: {Chart},
        data() {
            let me = this

            return {
                categories: [],
                series: [],
                title: '',
                scoreDisplays: [],
                maps: [],
                scoreDisplayExclusions: [],
                subCategoryId: null,
                dataPointTypeId: null,
                raw: [],
                monitor: {},
                showChart: false,
                localConfigs: {
                    chart: {
                        inverted: false
                    },
                    title: {
                        text: '',
                        useHTML: true,
                        align: 'left',
                        style: {
                            'color': '#7E8494',
                            'border-bottom': '1px solid #E0E1E6',
                            'display': 'block',
                            'width': '100%'
                        }
                    },
                    subtitle: {
                        useHTML: true,
                        text: '',
                        align: 'left',
                        style: {
                            'margin': '10px 0 0 0'
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    },
                    xAxis: {
                        gridLineColor: 'transparent',
                        labels: {
                            step: 75,
                            rotation: 0
                        },
                        plotLines: [],
                        categories: []
                    },
                    tooltip: {
                        formatter() {
                            let serie = this.series
                            let v = '<b>'+serie.name+'</b><br/>'
                            if (['Accuracy', 'Errors'].indexOf(serie.name) > -1) {
                                return v + me.$twoDecimals(this.y)
                            } else {
                                if (me.$data.maps.length > 0) {
                                    let i = Math.floor(this.y)
                                    return v+me.$data.maps[i]
                                }

                                return v + me.$twoDecimals(this.y)
                            }

                        }
                    },
                    yAxis: [{
                        title: {
                            text: 'Score'
                        },
                        labels: {
                            formatter: function() {
                                if (me.$data.maps.length > 0) {
                                    let i = Math.floor(this.value)
                                    return "<div>" + me.$data.maps[Math.floor(this.value)+""] + "</div>"
                                }

                                return "<div>" + this.value + "</div>"
                            }
                        }
                    }, {
                        title: {
                            text: 'Accuracy'
                        },
                        opposite: true,
                        min: 0,
                        max: 100
                    }],
                    plotOptions: {
                        area: {
                            fillOpacity: .2,
                            marker: {
                                enabled: false,
                                symbol: 'circle',
                                radius: 2,
                                states: {
                                    hover: {
                                        enabled: true
                                    }
                                }
                            }
                        },
                        series: {
                            point: {
                                events: {
                                    click(event) {
                                        let series = event.point.series.chart.series,
                                            x = event.point.x,
                                            y = event.point.y
                                        event.preventDefault()
                                        me.$emit('chartClicked', series, x, y, event)
                                    }
                                }
                            }
                        }
                    },
                    legend: {
                        y: 10,
                        layout: 'horizontal',
                        align: 'left',
                        alignColumns: false
                    },
                    series: [],
                    dash_id: null,
                    saved_search_id: null,
                    index: null
                }
            }
        },
        props: {
            params: {
                required: true
            },
            collectionChart: {
                type: Boolean,
                default: false
            },
            showTools: {
                type: Boolean,
                default: true
            },
            isCollection: {
                type: Boolean,
                default: false
            },
        },
        mounted() {
            this.loadData()
            this.$emit('all-charts', this.$refs.academicProgressMonitoringChart)
        },
        methods: {
            loadData() {
                this.$modelGet('academicMonitorChart', this.$props.params)
                    .then(response => {
                        this.$data.raw = response
                        this.processData()
                    })
            },
            processData() {
                this.$data.raw.forEach(monitor => {
                    this.$data.monitor = monitor
                    this.$data.scoreDisplays = monitor.score_displays
                    this.$data.subCategoryId = monitor.sub_category_id
                    this.$data.dataPointTypeId = monitor.data_point_type_id

                    if (Object.keys(this.$data.scoreDisplays).length > 0 && this.$data.scoreDisplays[this.$data.dataPointTypeId]) {
                        this.$data.maps = this.$data.scoreDisplays[this.$data.dataPointTypeId]
                    }

                    let series = []

                    monitor.series.forEach(serie => {
                        if (serie.name != "ec_phaseline") {
                            let s = this.createSerie(serie)
                            series.push(s)
                        } else {
                            series.push({
                                name: '',
                                data: [],
                                color: ''
                            })
                        }
                    })

                    monitor.chartData.forEach((data, index) => {
                        this.$data.categories.push(data.occurrence)
                        series.forEach(serie => {
                            serie.data.push(data[serie.key] ? data[serie.key] : null)
                        })

                        if (data["ec_phaseline"] != undefined) {
                            this.localConfigs.xAxis.plotLines.push({
                                color: 'black',
                                width: 2,
                                value: index,
                                label: {
                                    text: data.phase_name,
                                    style: {
                                        fontSize: '10px'
                                    },
                                    y: -5
                                },
                                zIndex: 100,
                                dashStyle: 'longdash'
                            })
                        }
                    })

                    this.$data.series = series
                    this.$data.title = monitor.chart_title
                    this.$data.showChart = true

                    this.localConfigs.series = series
                    this.localConfigs.title.text = monitor.chart_title
                    this.localConfigs.xAxis.categories = this.categories
                    this.localConfigs.subtitle.text = monitor.intervention_name

                    if(this.params && this.params.dashboard_id) this.localConfigs.dash_id = this.params.dashboard_id
                    if(this.params && this.params.saved_search_id) this.localConfigs.saved_search_id = this.params.saved_search_id
                    if(this.params && this.params.index) this.localConfigs.index = this.params.index
                })
            },
            createSerie(serie) {
                let s = {
                    name: serie.display,
                    key: serie.name,
                    color: serie.color,
                    data: []
                }

                if (serie.name == "accuracy") serie.type = 'scatter'

                switch (serie.type) {
                    case 'scatter':
                        if (serie.name == "ec_phaseline") {
                            s.type = 'column'
                        } else {
                            s.type = 'scatter'
                            s.zIndex = 10
                            s.marker = {
                                lineWidth: 1,
                                zIndex: 100,
                                lineColor: serie.color
                            }
                            s.yAxis = 1
                        }

                        break
                    case 'line':
                        let isTrend = serie.name.includes('trend_')
                        s.type = isTrend ? 'area' : 'line'

                        if (!isTrend) {
                            s.marker = {
                                enabled: false
                            }
                        }

                        break
                    default:
                        s.type = 'line'
                }
                return s
            },

            doExpand() {
                let c = {
                    params: this.$props.params,
                    height: window.innerHeight-200,
                    showTools: false
                }
                this.$store.commit('global/addDockableWindow', {
                    name: this.title,
                    component: 'academic-progress-monitoring-2',
                    attrs: c
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .description {
        font-size: 14px;
        padding: 16px;
    }
</style>
