<template>
    <fe-card class="alt-card my-2" v-show="isVisible && recurringItemsVisible">
        <template #title-text>
            <fe-tooltip
                :tooltip="completionTooltip"
                class="title-chart-box"
            >
                <highcharts
                    ref="chart-goals"
                    :options="completionChart" class="slo-strategies-completion-chart"
                />
            </fe-tooltip>
            Strategy Details
        </template>
        <template #title-toolbar>
            <v-menu offset-y left>
                <template v-slot:activator="{on}">
                    <v-btn icon x-small v-on="on"><v-icon>more_vert</v-icon></v-btn>
                </template>
                <v-list>
                    <v-list-item @click="$emit('edit', strategy)">Edit</v-list-item>
                    <v-list-item @click="deleteStrategy">Delete</v-list-item>
                </v-list>
            </v-menu>
        </template>
        <template v-slot:body>

            <p class="pa-4">
                {{ strategyText }}
                <a href="#!" v-if="showMoreLess" @click="truncated=!truncated">
                    Show {{ truncated ? 'More' : 'Less' }}
                </a>
            </p>

            <fe-spinner v-if="loading" text="Loading..." />

            <div
                v-else-if="items.length"
                class="slo-strategy-grid"
                ref="slo-strategy-grid"
            >
                <strategies-card-row headers />
                <div class="row-container">
                    <strategies-card-row
                        v-for="item in items"
                        :item="item"
                        :key="`slo-strategy-card-${strategy.id}-row-${item.schedule_date_id}`"
                        @setStatus="setStatus(item, $event)"
                        @deleteItem="$emit('inDevelopment')"
                        @editItem="$emit('inDevelopment')"
                        @inDevelopment="$emit('inDevelopment')"
                        :visibility="getVisibility(item)"
                    />
                </div>
            </div>

            <fe-crud
                autoload
                ref="crudItems"
                :config="$models.sloStrategy"
                :readParams="{ id: strategy.id }"
                @read="items = $event"
            />
        </template>
    </fe-card>
</template>

<script>
    import StrategiesCardRow from './StrategiesCardRow'

    export default {
        name: 'SloPanelStrategiesCard',
        props: {
            slo: { type: Object, required: true },
            filters: { type: Object, required: true },
            strategy: { type: Object, required: true },
            statuses: { type: Array, required: true }
        },
        components: {
            StrategiesCardRow
        },
        data() {
            return {
                items: null,
                truncated: true,
                visibility: {},
                recurringItemsVisible: true,
            }
        },
        computed: {
            strategyText () {
                return this.truncated && this.strategy.slo_strategy_text.length > 250 ?
                    `${this.strategy.slo_strategy_text.substring(0, 250)}...`
                    : this.strategy.slo_strategy_text
            },
            matchesAssigneeFilter () {
                if (!this.filters.assignee?.length) return true
                if (this.filters.assignee.find(fltr => fltr.id === this.strategy.user_id)) return true
                let userIds = this.filters.assignee.map(fltr => fltr.id)
                return !!this.items.find(itm => userIds.includes(itm.user_id))
            },
            matchesDateRangeFilter() {
                if (!this.filters.start_date && !this.filters.end_date) return true
                let start = this.filters.start_date ? this.$dayjs.utc(this.filters.start_date).format('YYYY-MM-DD').valueOf() : this.$dayjs.utc().format('YYYY-MM-DD').valueOf()
                let end = this.filters.end_date ? this.$dayjs.utc(this.filters.end_date).format('YYYY-MM-DD').valueOf() : this.$dayjs.utc().format('YYYY-MM-DD').valueOf()
                return !!this.items.find(itm => this.$dayjs.utc(itm.schedule_date_occurrence).format('YYYY-MM-DD').valueOf() >= start
                    && this.$dayjs.utc(itm.schedule_date_occurrence).format('YYYY-MM-DD').valueOf() <= end)
            },
            matchesStatusFilter () {
                if (!this.filters.status?.length) return true
                let statusNames = this.filters.status.map(fltr => fltr.name)
                return !!this.items.find(itm => statusNames.includes(this.itemStatus(itm)))
            },
            loading () { return !this.items },
            isVisible () { return !this.items || (this.matchesAssigneeFilter && this.matchesDateRangeFilter && this.matchesStatusFilter) },
            showMoreLess () { return this.strategy.slo_strategy_text.length > 250 },
            completionTooltip () {
                let total = this.items?.length || 0
                let complete = this.items?.filter(itm => !!itm.complete).length || 0
                return `${complete}/${total} complete`
            },
            completionChart() {
                let total = this.items?.length || 0
                let complete = this.items?.filter(itm => !!itm.complete).length || 0
                let pctComplete = (total && complete)
                    ? this.$twoDecimals((complete * 100) / total)
                    : 0
                return {
                    chart: {
                        type: 'pie',
                        height: '24px',
                        plotShadow: false,
                        margin: [4,4,4,4]
                    },
                    credits: {
                        enabled: false
                    },
                    title: { text: null },
                    exporting: {
                        enabled: false
                    },
                    tooltip: { enabled: false },
                    plotOptions: {
                        pie: {
                            innerSize: '100%',
                            borderWidth: 4,
                            borderColor: null,
                            slicedOffset: 0,
                            dataLabels: {
                                connectorWidth: 0,
                                enabled: false
                            }
                        }
                    },
                    series: [{
                        name: 'Status',
                        data: [{
                            name: 'Incomplete',
                            color: '#e6e6e6',
                            sliced: true,
                            y: 100 - pctComplete
                        }, {
                            name: 'Complete',
                            color: '#408AAF',
                            sliced: true,
                            y: pctComplete
                        }]
                    }]
                }
            },
        },
        methods: {
            itemStatus (item) {
                if (item.complete) return 'Done'
                if (this.$dayjs(`${item.schedule_date_occurrence} ${item.schedule_date_occurrence_end_time}`) < this.$dayjs()) return 'Overdue'
                return 'To Do'
            },
            dateFormatter (str) { return this.$dayjs(str).format('MM/DD/YY') },
            async setStatus (item, complete) {
                let update = Object.assign({}, item, { complete: complete })
                await this.$refs.crudItems.update(update)
                await this.$refs.crudItems.read()
                this.$emit('refresh')
            },
            deleteStrategy () {
                let msg = 'Are you sure you want to delete this strategy? This change is permanent!'
                this.$confirmDelete(this.strategy, this.performDeletion, null, msg)
            },
            async performDeletion () {
                let rsp = await this.$refs.crudItems.destroy(this.strategy)
                if (rsp.data.success) {
                    await this.$axios.post('googleCalendarIntegrate.php?action=delete&property=slo', {
                        sIds: rsp.data.sIds
                    })
                }
                this.$emit('deleted', this.strategy)
                this.$emit('refresh')
            },
            getVisibility(item) {
                if (!this.filters.assignee?.length && !this.filters.status?.length && this.filters?.date == 'Invalid date' && !this.filters?.date) return true
                return this.visibility[item.schedule_date_id] != undefined ? this.visibility[item.schedule_date_id] : true
            },
        },
        watch: {
            'strategy.id'(v) {
                this.$refs.crudItems.read({ id: v })
            },
            isVisible (v) { this.$emit('visibilityChanged', v) },
            filters: {
                deep: true,
                handler() {
                    let start = this.filters.start_date ? this.$dayjs.utc(this.filters.start_date).format('YYYY-MM-DD').valueOf() : this.$dayjs.utc().format('YYYY-MM-DD').valueOf()
                    let end = this.filters.end_date ? this.$dayjs.utc(this.filters.end_date).format('YYYY-MM-DD').valueOf() : this.$dayjs.utc().format('YYYY-MM-DD').valueOf()
                    let visDate, visUser, visStat = false
                    let date = null
                    if (this.items.length > 1) {
                        // for recurring strategies, apply filters to each row
                        this.items.forEach(item => {
                            date = this.$dayjs.utc(item.schedule_date_occurrence).format('YYYY-MM-DD').valueOf()
                            visDate = this.filters.start_date || this.filters.end_date ? date >= start && date <= end : true
                            visUser = this.filters.assignee.length ? this.filters.assignee.some(fltr => fltr.id == item.user_id) : true
                            visStat = this.filters.status.length ? this.filters.status.some(fltr => fltr.name == this.itemStatus(item)) : true
                            this.visibility[item.schedule_date_id] = visDate && visUser && visStat
                        })
                        // if all items in this.items are not visible, we want to hide the whole card
                        this.recurringItemsVisible = !Object.keys(this.visibility).every((item) => !this.visibility[item])
                    }
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .v-card__text.fe-card-text {
        padding: 0;
    }
    .title-chart-box {
        display: inline-block;
        width: 26px;
        height: 26px;
        overflow: visible;
        position: relative;
        top: 4px;
        left: -5px;
        z-index: 10;
    }
    .slo-strategy-grid {
        overflow: hidden;
        .row-container {
            position: relative;
            max-height: 200px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
</style>
