<template>
    <div class="d-flex flex-column flex-fill" :class="pageBackground">
        <div class="d-flex pt-5 px-5 pb-1" v-if="!drilldown">
            <div style="font-size: 24px;">Interventions</div>
            <v-spacer/>
            <fe-btn usage="primary" @click="newIntervention">New</fe-btn>
        </div>

        <fe-dialog
                id="fb-dialog"
                v-if="interventionDialog.show"
                title="Dual Product Options"
                dismissButtonText="Cancel"
                acceptButtonText="Continue"
                :acceptButtonDisabled="interventionDialog.includefb && fbpm.id < 1"
                width="600px"
                persistent
                @accept="createIntervention(interventionDialog.includefb)"
                @dismiss="interventionDialog.show=false"
                @close="interventionDialog.show=false"
        >
            <div class="d-flex flex-column flex-fill">
                <div class="product-alert">Your district gives you access to FastBridge.</div>

                <div class="mt-3 mb-5 ml-3">Do you want to link to a FastBridge progress monitoring plan?</div>

                <v-radio-group v-model="interventionDialog.includefb" :mandatory="true">
                    <v-radio class="pendo-fb-no" label="No, I plan to use eduCLIMBER only" :value="false"/>
                    <v-radio class="pendo-fb-yes" label="Yes, I plan to use FastBridge for progress monitoring" :value="true" @change="loadFbPlans"/>
                </v-radio-group>

                <div v-if="interventionDialog.includefb" class="ml-10" style="margin-top: -7px">
                    <fe-remote-combo
                            ref="selectPlans"
                            :items="fbPlans"
                            itemText="intervention_name"
                            itemValue="id"
                            v-model="fbpm"
                            :rules="this.$fieldValidators('select', null, {required: true})"
                    />
                </div>

                <div v-if="fbpm.id === 0" class="fb-warning">
                    <i class="fas fa-exclamation-triangle"/>
                    <span class="fb-warning-text">It is highly recommended that you set up a plan in FastBridge first if you plan to use both eduCLIMBER and FastBridge. We are working on improving our integration and it will be more seamless in the future.</span>
                </div>
            </div>
        </fe-dialog>

        <div class="d-flex flex-wrap" v-if="!drilldown">

            <fe-filter-btn
                title="Year"
                url="qry.php?property=school_years"
                rootProperty="years"
                itemValue="id"
                itemText="name"
                :multiple="false"
                v-model="selected.school_year_id"
                :showClearButton="false"
            />

            <fe-filter-btn
                :title="dateDisplay||'Date Range'"
                v-model="ignoreDate"
                class="ec-interventions-filter-btn-calendar"
            >
                <div>
                    <functional-calendar class="lean-calendar" is-date-range v-model="dateRange" dateFormat="mm/dd/yyyy"/>
                    <v-divider/>
                    <div style="padding: 4px 0">
                        <fe-btn @click="resetDateRange()" usage="ghost" class="date-range-clear-btn">Clear</fe-btn>
                    </div>
                </div>
            </fe-filter-btn>

            <fe-filter-btn
                title="Interventionist"
                url="users.php?action=get&active=1&detail=0"
                itemValue="id"
                itemText="user_full_name"
                :multiple="false"
                v-model="selected.user"
                showClearButton
                :showApplyButton="false"
                :disabled="!$hasSecurity('SEARCH_BY_INTERVENTIONIST')"
                buffered
            />

            <fe-filter-btn
                title="Level"
                url="interventionLevels.php?action=get&active=1"
                itemValue="id"
                itemText="name"
                rootProperty="intervention_levels"
                :multiple="false"
                v-model="selected.intervention_level_id"
                showClearButton
                :showApplyButton="false"
            />

            <fe-filter-btn
                title="Strategy"
                url="interventionTypes.php?action=get&active=1"
                itemValue="id"
                itemText="name"
                :multiple="false"
                v-model="selected.intervention_type_id"
                showClearButton
                :showApplyButton="false"
            />

            <fe-filter-btn
                title="School"
                :url="schoolURL"
                v-model="selected.school_id"
                :disabled="!selected.school_year_id.included"
                rootProperty="schools"
                showClearButton
                :showApplyButton="false"
                :closeOnSelect="false"
                ref="schoolFilter"
            />

            <fe-filter-btn
                title="Grade"
                :url="gradeURL"
                v-model="selected.grade_id"
                :disabled="!selected.school_year_id.included"
                rootProperty="grades"
                showClearButton
                :showApplyButton="false"
                :closeOnSelect="false"
            />

            <!-- <fe-filter-btn
                title="Group"
                :url="interventionPlanURL"
                :multiple="false"
                v-model="selected.plan"
                :disabled="!selected.school_year_id.included"
                rootProperty="plans"
                actions
            /> -->

            <fe-filter-btn
                title="Student"
                :url="studentURL"
                :multiple="false"
                v-model="selected.student"
                itemText="student_full_name"
                itemValue="student_id"
                :disabled="selectedSchools"
                actions
            />

        </div>

        <div v-if="canSearch && !drilldown" :key="statsKey" class="d-flex flex-fill flex-column" style="overflow: scroll;">
            <v-row>
                <v-col :cols="cols">
                    <active-plans :params="searchParams" @drilldown="doPlansDrilldown"/>
                </v-col>

                <v-col :cols="cols">
                    <implementation-fidelity :params="searchParams"/>
                </v-col>

                <v-col :cols="cols">
                    <academic-goal-progress :params="academicParams" @drilldown="doAcademicDrilldown"/>
                </v-col>

                <v-col :cols="cols">
                    <behavior-goal-progress :params="searchParams" @drilldown="doPointSheetDrilldown"/>
                </v-col>

                <v-col :cols="cols">
                    <exit-status :params="searchParams" @drilldown="doExitStatusDrilldown"/>
                </v-col>

                <v-col :cols="cols">
                    <intervention-tools-card @intervention-workspace-updated="updateStats"/>
                    <!-- <upcoming-reviews :params="searchParams"/> -->
                </v-col>
            </v-row>
        </div>

        <div v-if="drilldown" class="d-flex flex-column flex-fill no-scroll mb-10">
            <div class="d-flex pt-4">
                <fe-btn small usage="ghost" class="my-0" useIcon="fas fa-chevron-left" @click="drilldown=false"></fe-btn>
                <div style="font-size: 24px; line-height: ">{{drilldownTitle}}</div>
            </div>

            <drilldown v-if="drilldownType!='academic'" :params="drilldownParams"/>
            <academic-drilldown v-if="drilldownType=='academic'" :params="drilldownParams"/>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import ActivePlans from './analysis/ActivePlans'
    import ImplementationFidelity from './analysis/ImplementationFidelity'
    import AcademicGoalProgress from './analysis/AcademicGoalProgress'
    import BehaviorGoalProgress from './analysis/BehaviorGoalProgress'
    import ExitStatus from './analysis/ExitStatus'
    import UpcomingReviews from './analysis/UpcomingReviews'
    import Drilldown from './analysis/Drilldown'
    import AcademicDrilldown from './analysis/AcademicDrilldown'
    import InterventionCreator from '@/components/modules/intervention/creation/Index'
    import InterventionToolsCard from '@/components/launchpad/InterventionToolsCard'
    import { FunctionalCalendar } from 'vue-functional-calendar'

    export default {
        name: 'InterventionAnalysis',
        components: {
            ActivePlans,
            ImplementationFidelity,
            AcademicGoalProgress,
            BehaviorGoalProgress,
            ExitStatus,
            UpcomingReviews,
            Drilldown,
            AcademicDrilldown,
            InterventionCreator,
            InterventionToolsCard,
            FunctionalCalendar
        },
        data() {
            return {
                selected: {
                    school_year_id: {},
                    baseDate: null,
                    endDate: null,
                    user: {},
                    school_id: {},
                    grade_id: {},
                    plan: {},
                    intervention_level_id: {},
                    intervention_type_id: {}
                },
                ignoreDate: {
                    included: []
                },
                dateRange: {},
                drilldown: false,
                drilldownParams: {},
                drilldownTitle: '',
                drilldownType: null,
                interventionDialog: {
                    show: null,
                    includefb: false,
                },
                fbpm: {},
                fbPlans: [],
                statsKey: 0,
            }
        },
        computed: {
            ...mapState('global', ['currentYear', 'sessionUser', 'defaultSchool']),
            fbMonitorAvailable () { return this.$store.state.global.fbMonitorAvailable },
            pageBackground() {
                //(!drilldown ? '#F5F6F8 !important;' : '#FFFFFF !important;')
                return this.drilldown ? 'drilldown' : 'main-page'
            },
            cols() {
                if (this.$vuetify.breakpoint.xs) {
                    return 12
                } else if (this.$vuetify.breakpoint.smAndDown) {
                    return 6
                }

                return 4
            },
            schoolURL() {
                if (this.schoolYearParams) {
                    return 'schools.php?action=get&' + this.schoolYearParams + '&incl_groups=1'
                }
                return null
            },
            gradeURL() {
                if (this.schoolYearParams) {
                    return 'grades.php?action=get&' + this.schoolYearParams
                }
                return null
            },
            studentURL() {
                if (this.schoolYearParams) {
                    let grade = this.selected.grade_id.included || []
                    let grade_id = grade.map(r => r.id).join(',')
                    return 'students.php?action=get&property=grade_history&' + this.schoolYearParams + '&grade_id=' + grade_id
                }
                return null
            },
            interventionPlanURL() {
                if (this.schoolYearParams) {
                    return 'interventionPlan.php?action=get&property=list&' + this.schoolYearParams + "&user_id=" + this.sessionUser.user.id
                }
            },
            schoolYearParams() {
                let year = this.selected.school_year_id.included || []
                let school = this.selected.school_id.included || []
                let p = {
                    school_year_id: year.length ? year.map(r => r.id) : '',
                    school_id: school.length ? school.map(r => r.id) : '',
                }
                return this.$objectToParams(p)
            },
            cssVars() {
                return {
                    '--fe-primary-darken': this.$vuetify.theme.themes.light.primary.darken1,
                    '--fe-primary-light2': this.$vuetify.theme.themes.light.primary.lighten2
                }
            },
            canSearch() {
                return this.selected.school_year_id
            },
            academicParams() {
                let p = {...this.searchParams}

                if (p.user_id) p.intervention_plan_user_id = p.user_id
                delete p.user_id
                return p
            },
            selectedSchools() {
                return !this.selected.school_year_id.included?.length || !this.selected.school_id.included?.length
            },
            searchParams() {
                let p = {}

                // school year
                if (this.selected.school_year_id) {
                    p.school_year_id = this.getIncluded(this.selected.school_year_id, 'id')
                }

                // intervention level
                if (this.selected.intervention_level_id) {
                    p.intervention_level_id = this.getIncluded(this.selected.intervention_level_id, 'id')
                }

                // user
                if (this.selected.user) {
                    p.user_id = this.getIncluded(this.selected.user, 'id')
                }

                // school
                if (this.selected.school_id) {
                    p.school_id = this.getIncluded(this.selected.school_id, 'id')
                }

                // grade
                if (this.selected.grade_id) {
                    p.grade_id = this.getIncluded(this.selected.grade_id, 'id')
                }

                // student
                if (this.selected.student) {
                    p.student_id = this.getIncluded(this.selected.student, 'student_id')
                }

                // plan
                if (this.selected.plan) {
                    p.intervention_plan_id = this.getIncluded(this.selected.plan, 'id')
                }

                if (this.selected.intervention_type_id) {
                    p.intervention_type_id = this.getIncluded(this.selected.intervention_type_id, 'id')
                }

                if (this.selected.baseDate && this.selected.endDate) {
                    p.base_date = this.$dayjs(this.selected.baseDate).format('YYYY-MM-DD')
                    p.end_date = this.$dayjs(this.selected.endDate).format('YYYY-MM-DD')
                }

                // Get rid of empty arrays
                for (let key in p) {
                    if (!p[key].length) delete p[key]
                }
                return p
            },
            dateDisplay() {
                if (this.dateRange?.dateRange?.start) {
                    return this.dateRange.dateRange.start + '-' + (this.dateRange.dateRange.end ? this.dateRange.dateRange.end : '')
                }
            }
        },
        watch: {
            dateRange: {
                handler(v) {
                    if (v.dateRange && v.dateRange.start) {
                        this.selected.baseDate=v.dateRange.start
                    }
                    if (v.dateRange && v.dateRange.end) {
                        this.selected.endDate=v.dateRange.end
                    }
                },
                deep: true
            }
        },
        mounted() {
            this.selected.school_year_id = {
                included: [{...this.currentYear}]
            }

            if (this.defaultSchool) {
                this.$refs.schoolFilter.clearSelections()
                this.$refs.schoolFilter.selectItem(this.defaultSchool, true)
            }

            if(!this.$hasSecurity('SEARCH_BY_INTERVENTIONIST')) {
                this.selected.user = {
                    included: [{...this.sessionUser.user}]
                }
            }

            this.loadData()
        },
        methods: {
            newIntervention() {
                // if there are FB monitors and they're accessible, show dialog.
                // If not, start create intervention process.
                if (this.fbMonitorAvailable) {
                    this.interventionDialog.show = true
                } else {
                    this.createIntervention()
                }
            },
            createIntervention(fb=false) {
                let p = this.$showPanel({
                    component: InterventionCreator,
                    width: 500,
                    openOn: 'right',
                    disableBgClick: true,
                    cssClass: 'main-left-slideout',
                    props: {
                        class: 'pa-5',
                        fbMonitorAvailable: fb, // false = use eC, true = use FB
                        fbMonitorId: this.fbpm.id, // FB monitor id
                        fbMonitorName: this.fbpm.intervention_name, // FB monitor name of selected
                        fbMonitorStudents: this.fbpm.students, // Students in selected FB monitor
                        fbMonitorStartDate: this.fbpm.start_date, // FB monitor start date
                        fbMonitorEndDate: this.fbpm.end_date, // FB monitor end date
                        fbMonitorType: this.fbpm.sub_category_id, // FB monitor sub category
                        fbMonitorSkill: this.fbpm.category_id, // Assessment category (skill)
                        fbMonitorUnmatched: this.fbpm.unmatched_student_count, // Unmatched student count
                    }
                })

                p.promise.then(res => {
                    // reset dialog
                    this.loadFbPlans()
                })
            },
            updateStats() {
                this.statsKey += 1
            },
            doAcademicDrilldown(rec) {
                let p = {...this.academicParams}
                if (rec) p[rec.key] = 1

                p.chart_types = [
                    'school',
                    'grade',
                    'tier',
                    'strategy',
                    'category',
                    'ethnicity',
                    'gender',
                    'interventionist',
                    'category',
                    'student'
                ]
                p.type='academic'
                this.drilldownParams = p
                this.drilldownTitle = 'Progress: ' + (rec ? rec.title : ' All')
                this.drilldownType = 'academic'
                this.drilldown = true
            },
            doPointSheetDrilldown(rec) {
                let p = {...this.searchParams}
                // p[rec.key.replace('total_', '')] = 1
                p.chart_types = [
                    'school',
                    'grade',
                    'tier',
                    'strategy',
                    'ethnicity',
                    'gender',
                    'interventionist',
                    'student'
                ]
                p.type='behavior'
                this.drilldownParams = p
                this.drilldownTitle = 'Progress: ' + rec.title
                this.drilldownType = null
                this.drilldown = true
            },
            doExitStatusDrilldown(rec) {
                let p = {...this.searchParams, ...{ exit_status_id: rec.id }}
                p.chart_types = [
                    'school',
                    'grade',
                    'tier',
                    'strategy',
                    'category',
                    'ethnicity',
                    'gender',
                    'interventionist',
                    'category'
                ]
                p.type='combined'
                this.drilldownParams = p
                this.drilldownTitle = rec.name
                this.drilldownType = null
                this.drilldown = true
            },
            doPlansDrilldown(params) {
                let p = {...params}
                p.chart_types = [
                    'school',
                    'grade',
                    'tier',
                    'strategy',
                    'category',
                    'ethnicity',
                    'gender',
                    'interventionist',
                    'category'
                ]
                p.type='combined'
                this.drilldownParams = p
                this.drilldownTitle = 'Intervention Overview'
                this.drilldownType = null
                this.drilldown = true

            },
            getIncluded(o, itemValue) {
                return o.included ? o.included.map(r=>r[itemValue]) : []
            },
            loadData() {
                // let properties = ['overall_fidelity', 'attendance_counts', 'exit_status_breakdown', 'upcoming_reviews']
                // let base = 'interventionView.php?action=get&property='

                // properties.forEach(p => {
                //     this.$axios.get(base+p+'&school_year_id=16')
                //         .then(r => {
                //         })
                // })
            },
            loadFbPlans() {
                // defaults
                this.fbPlans = []
                this.fbpm.id = -1
                this.fbPlans.push(
                    {id: -1, intervention_name: 'Select an option'},
                    {id: 0, intervention_name: 'A setup hasn’t been created yet.'}
                )

                // FastBridge Progress Monitors
                // If FB PMs are available, give users the option to create an Intervention using an FB PM
                this.$modelGet('fastBridgePmPlans', {property: 'available'})
                    .then(fb => {
                        if (fb.available.length > 0) {
                            fb.available.forEach(plan => {
                                this.fbPlans.push(plan)
                            })
                        }
                    })
            },
            resetDateRange() {
                this.dateRange.dateRange.end = ''
                this.dateRange.dateRange.start = ''
                this.selected.baseDate = null
                this.selected.endDate = null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .main-page {
        background: #F5F6F8;
    }

    .drilldown {
        background: white;
    }
    .interventions-date-picker {
        margin: 6px 8px;
        ::v-deep .v-input__icon {
            min-width: 15px !important;
            width: 15px !important;
            &--clear {
                display: none !important;
            }
        }
        ::v-deep .v-input__slot {
            border-radius: 28px !important;
            width: 85px !important;
            padding: 0 16px !important;
            border-color: rgb(224, 225, 230) !important;
        }
        &-active {
            ::v-deep .v-input__slot {
                width: 145px !important;
                background: var(--fe-primary-light2) !important;
                border-color: transparent !important;
            }
            ::v-deep .v-text-field__slot input {
                color: var(--fe-primary-darken) !important;
                font-size: 14px !important;
            }
        }
    }
    .lean-calendar {
        ::v-deep .vfc-main-container {
            box-shadow: none !important;
        }

        // ::v-deep .vfc-main-container {
        //     height: auto !important;
        // }
        ::v-deep .vfc-main-container {
            font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
            height: 290px;
        }
        ::v-deep .vfc-top-date {
            font-size: 14px !important;
        }
        ::v-deep .vfc-arrow-right, ::v-deep .vfc-arrow-left {
            height: 10px !important;
            width: 10px !important;
        }
        ::v-deep .vfc-dayNames span {
            color: #7E8494 !important;
            margin-bottom: 0 !important;
            font-size: 13px;
        }
        ::v-deep .vfc-today:not(.vfc-marked) {
            background-color: transparent !important;
            color: #000 !important;
            border: 1px solid var(--fe-primary) !important;
        }
        ::v-deep .vfc-week {
            font-size: 13px;
        }
        ::v-deep .vfc-months-container {
            .vfc-content {
                width: 55% !important;
            }
        }

        ::v-deep .vfc-marked {
            background: #CCE2E9 !important;
        }

        ::v-deep .vfc-start-marked {
            background: #006C90 !important;
        }

        ::v-deep .vfc-end-marked {
            background: #006C90 !important;
        }

    }

    .product-alert {
        margin: auto;
        height: 55px;
        width: 557px;
        padding: 17px 56px;
        border-left: 10px solid #80B6C7;
        background-color: #CCE2E9;
        background-position: 16px 10px;
        background-size: 28px;
        background-image: url(/images/FastBridge_logo.png);
    }

    .fb-warning {
        margin-top: -7px;
        margin-left: 45px;
    }

    .fb-warning-text {
        font-size: 12px;
        float: right;
        padding-left: 15px;
        width: 485px;
    }

    .date-range-clear-btn {
        ::v-deep .fe-button {
            margin: 0 !important;
            padding: 0 !important;
            min-width: 60px !important;
        }
    }
</style>
