<template>
    <div class="d-flex flex-fill flex-column no-scroll">
        <div class="text-right pr-3 mb-2">
            <toggle-button :items="toggles" v-model="activeToggle"/>
        </div>
        <v-row style="margin-bottom: 40px; overflow: scroll;" v-if="activeToggle.name=='Charts'">
            <v-col :cols="chartCols" v-for="chart in charts">
                <div class="bordered">
                    <highcharts class="fe-chart" :options="chart"/>
                    <menu-tools class="tools" :showPin="false" :showMore="false" @expand="doExpand(chart)"/>
                </div>
            </v-col>
        </v-row>

        <incident-grid v-if="activeToggle.name==='Data List'" ref="list" :params="tableParams" :showAddRowBtn="false" title=""/>

        <div class="incident-breadcrumb">
            <breadcrumb v-model="crumbs" @click="crumbClick"/>
        </div>
    </div>

</template>

<script>
    import MenuTools from '@/components/charts/MenuTools'
    import Breadcrumb from '@/components/common/Breadcrumb'
    import ToggleButton from '@/components/common/button/ToggleButton'
    import IncidentGrid from './IncidentGrid'
    import windowOptionsMixin from '@/mixins/windowOptions'

    export default {
        name: 'IncidentOverview',
        mixins: [ windowOptionsMixin ],
        components: { MenuTools, Breadcrumb, ToggleButton, IncidentGrid },
        data() {
            return {
                charts: [],
                crumbs: [],
                toggles: [{
                    name: 'Charts',
                    icon: 'fas fa-chart-bar'
                }, {
                    name: 'Data List',
                    icon: 'fas fa-bars'
                }],
                activeToggle: {}
            }
        },
        computed: {
            chartCols() {
                if (this.$vuetify.breakpoint.smAndDown) {
                    return 12
                } else if (this.$vuetify.breakpoint.mdAndDown) {
                    return 6
                } else if (this.$vuetify.breakpoint.lgAndDown) {
                    return 4
                }

                return 3
            },
            tableParams() {
                let p = {}
                this.crumbs.forEach(c => Object.assign(p, c.params))
                return p
            }
        },
        props: {
            params: {
                type: Object,
                required: true
            },
            name: {
                type: String,
                default: ''
            },
        },
        watch: {
            params: {
                handler() {
                    this.loadData()
                },
                immediate: true
            }
        },
        mounted() {
            this.activeToggle = this.toggles[0]
            this.crumbs.push({
                name: 'Drilldown: ' + this.name,
                params: {...this.params}
            })
        },
        methods: {
            doExpand(cfg) {
                let c = this.$_.cloneDeep({...cfg})
                c.chart.height = window.innerHeight-150
                delete c.plotOptions.series.point.events
                c.xAxis.labels.useHTML = true
                c.xAxis.labels.formatter = function(){
                    return this.value
                }

                this.$store.commit('global/addDockableWindow', {
                    name: 'Incident Drilldown',
                    component: 'highcharts',
                    attrs: {
                        options: c,
                    },
                })
            },
            crumbClick(crumb) {
                this.loadData(crumb.params)
            },
            loadData(params) {
                let p = params||this.params
                this.$setLoading(true)
                this.$axios.get('behaviorIncident.php?action=get&property=summary_charts&'+this.$objectToParams(p))
                    .then(response => {
                        this.$setLoading(false)
                        this.raw = this.$ecResponse(response, 'charts')
                        this.buildCharts()
                    })
            },
            addToCrumbs(name, key, rec) {
                let p = {}
                this.crumbs.forEach(c => Object.assign(p, c.params))
                p[key] = rec[key]
                this.crumbs.push({
                    name: name,
                    params: p
                })
                this.loadData(p)
            },
            buildCharts() {
                let me = this
                this.charts = []

                this.raw.forEach(cfg => {
                    let c = this.$charts.barChartConfig()
                    c.title.text = cfg.title
                    c.series = [{
                        name: 'Total',
                        data: []
                    }]

                    cfg.data.forEach(d => {
                        c.xAxis.categories.push(d[cfg.key])
                        c.series[0].data.push(d.total_count)
                    })

                    c.plotOptions.series.point.events.click = function(e) {
                        let cat = e.point.category
                        let rec = cfg.data.find(r => r[cfg.key] === cat)

                        if (rec) me.addToCrumbs(cat, cfg.param_key, rec)
                    }

                    this.charts.push(c)
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.bordered {
    border: 1px solid #dddddd;
    border-radius: 4px;
    position: relative;
}

.fe-chart ::v-deep * {
    font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
    color: #dddddd !important;
}

.tools {
    position: absolute;
    right: 5px;
    top: 0px;
}

.incident-breadcrumb {
    position: absolute;
    width: 98%;
    border-top: 1px solid #dddddd;
    bottom: 0px;
    background: white;
    padding: 4px 8px 4px 16px;
}
</style>
