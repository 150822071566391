<template>
    <div>
        <div>
            <v-layout row>
                <v-flex v-if="showSearch">
                    <v-text-field style="margin-top: 6px;" flat solo dense v-model="filterText" placeholder="Type to search..."/>
                </v-flex>

                <v-flex shrink v-if="addButton">
                    <fe-btn usage="primary" @click="$emit('addButtonClick')">{{addButtonText}}</fe-btn>
                </v-flex>
            </v-layout>
        </div>
        <div class="action-list-container">
            <div v-for="(type, i) in filteredItems" :key="`action-list-`+i" class="action-list-row" :class="[{last: i === filteredItems.length-1}]" @click="$emit('click', type)">
                <v-layout row>
                    <v-flex shrink v-if="select">
                        <v-checkbox class="mt-3 ml-3" @change="$emit('selected', type, $event)"></v-checkbox>
                    </v-flex>

                    <v-flex shrink> <div class="row-title">{{type[itemText]}}</div></v-flex>

                    <v-flex grow>
                        <detail-chip
                            :title="chip[chipItemText]"
                            v-for="(chip, index) in type[chipKey]"
                            :class="[{'exclude-click': chip.excludeClick}]"
                            :key="`action-list-chip-`+index"
                            :items="chip[chipData]"
                            @buttonClick="!chip.excludeClick ? $emit('chipClick', { parent: type, data: chip[chipData] }) : null"
                        />
                    </v-flex>

                    <v-flex class="text-xs-right pt-3 pr-3">
                        <v-icon @click="$emit('iconClick', icon[iconData], type)" class="action-list-icon" v-for="(icon, ii) in type[iconKey]" :key="`action-list-icon`+ii">
                            {{icon[iconName]}}
                        </v-icon>

                        <v-icon @click="$emit('click', type)">chevron_right</v-icon>
                    </v-flex>
                </v-layout>
            </div>
        </div>
    </div>
</template>

<script>
    import DetailChip from '@/components/common/DetailChip'

    export default {
        name: 'ActionList',
        components: {
            DetailChip
        },
        props: {
            items: {
                type: Array,
                default() { return [] }
            },
            showSearch: {
                type: Boolean,
                default: true
            },
            select: {
                type: Boolean,
                default: false
            },
            chipKey: {
                type: String,
                default: 'chips'
            },
            itemText: {
                type: String,
                default: 'name'
            },
            itemValue: {
                type: String,
                default: 'id'
            },
            chipItemText: {
                type: String,
                default: 'name'
            },
            chipData: {
                type: String,
                default: 'data'
            },
            iconKey: {
                type: String,
                default: 'icons'
            },
            iconName: {
                type: String,
                default: 'icon'
            },
            iconData: {
                type: String,
                default: 'data'
            },
            addButton: {
                type: Boolean,
                default: true
            },
            addButtonText: {
                type: String,
                default: 'Add'
            }
        },
        computed: {
            filteredItems() {
                let arr = []

                if (!this.filterText) {
                    return this.items
                }

                this.items.forEach((item) => {
                    if (item[this.itemText].toLowerCase().indexOf(this.filterText.toLowerCase()) > -1) {
                        arr.push(item)
                    }
                })

                return arr
                
            }
        },
        data() {
            return {
                editing: false,
                filterText: ''
            }
        }
    }
</script>

<style lang="scss" scoped>

.action-list-container {
    border: 2px solid #E0E1E6;
    overflow: hidden;

    .action-list-row {
        height: 64px;
        width: 100%;
        background: #FFFFFF;
        border-bottom: 2px solid #E0E1E6;
         
        &:hover {
            background-color: #EEF5FF;
        }

        .row-title {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
            min-width: 220px;
            padding-left: 20px;
            margin-top: 20px;
            cursor: pointer;
        }

        &.last {
            border-bottom: none;
        }

        .row-codes {
            cursor: pointer;
            display: inline-block;
            background: #E0E1E6;
            border-radius: 2px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            padding: 6px 10px 6px 10px;
            min-width: 95px;
            text-align: center;
            margin-left: 10px;
            /* identical to box height */


            color: #000000;
        }

        .action-list-icon {
            margin-right: 8px;
            cursor: pointer;
        }

        .exclude-click {
            cursor: default;
        }
    }
}

</style>