import BaseService from '@/components/modules/thresholds/services/BaseService'
import BaseStore from './Base'

export default Object.assign({}, BaseStore.default(), {
    namespaced: true,

    mutations: Object.assign({}, BaseStore.default().mutations, {
        success(state, data) {
            state.status = 'success'
            state.dirtied = []
            state.removed = []
            state.added = []
            state.collection = [data]
        }
    }),

    getters: Object.assign({}, BaseStore.default().getters, {
        __config() {
            return {
                service: BaseService,
                params: {activeUser: 1},
                opts: {type: 'users'}
            }
        },

        timeZone(state) {
            return (state.collection.length > 0 && state.collection[0].district)
                ? state.collection[0].district.time_zone
                : 'America/Chicago'
        }
    })
})
