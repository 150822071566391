<template>
    <div>
        <div v-if="Array.isArray(tags)" class="my-4">
            <div style="float: left; margin: 10px;" v-for="tag in tags" :key="`smart-tag-field-`+ tag.id">
                <span>
                    <v-icon :color="tag.tag_color" style="font-size: 12px; margin-right: 2px;">fas fa-tag</v-icon>
                    {{tag.tag_show_name ? tag.tag_name : ''}}
                    <span style="font-size: 10px;">{{tag.school_year_name}}</span>

                    <div>{{tag.tag_show_description ? tag.tag_description : ''}}</div>
                </span>
            </div>
        </div>
        <div v-else class="empty-editor">
            {{fieldRec.name}}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SmartTagField',
        props: {
            tags: {},
            fieldRec: {},
        },
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
