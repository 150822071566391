<template>
    <div class="d-flex flex-fill flex-column pa-2">
        <fe-grid
            v-if="ready"
            :columnDefs="colDefs"
            :rowData="items"
            :showAddRowBtn="false"
            displayDensity="small"
            ref="grid"
            style="height: 100%"
        />
        <fe-crud ref="crud" :config="crudConfig" @read="parseInterventions"/>
        <fe-crud ref="studentPlanCrud" :config="studentPlanCrudConfig"/>
        <fe-crud ref="statusCrud" :config="statusCrudConfig" autoload @read="postReadStatus"/>
    </div>
</template>

<script>
    import {mapState} from "vuex"
    import IFrameRenderer from '@/components/common/IFrameRenderer'

    export default {
        name: "EndingInterventions",

        components: {
            IFrameRenderer
        },

        props: {
            date: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        data() {
            return {
                ready: false,
                items: [],
                exitStatuses: [],
                colDefs:  [{
                    headerName: 'Intervention Name',
                    field: 'intervention_plan_name',
                    sortable: true
                }, {
                    headerName: 'Student',
                    field: 'student_full_name',
                    sortable: true
                }, {
                    headerName: 'Start Date',
                    field: 'start_date',
                    valueFormatter: v => this.$dayjs(v.data.start_date).format('YYYY-MM-DD'),
                    sortable: true
                }, {
                    headerName: 'End Date',
                    field: 'end_date',
                    valueFormatter: v => this.$dayjs(v.data.end_date).format('YYYY-MM-DD'),
                    sortable: true
                }, {
                    headerName: "Exit Status",
                    field: "intervention_exit_status_id",
                    valueGetter: v => this.findLabel(this.exitStatuses, v.data.intervention_exit_status_id, 'id', 'name'),
                    sortable: true,
                    editable: true,
                    cellEditorFramework: 'FeGridChipSelect',
                    cellEditorParams: v => {
                        return {
                            value: v.data.intervention_exit_status_id,
                            rowDataKey: "intervention_exit_status_id",
                            mode: "edit",
                            items: this.exitStatuses,
                            keyProp: "id",
                            labelProp: "name",
                            disableChips: true
                        }
                    },
                    onCellValueChanged: v => {
                        this.editIntervention(v.data)
                    }
                }]
            }
        },

        computed: {
            ...mapState('global', ['sessionUser', 'currentYear']),

            crudConfig() {
                return this.$_.cloneDeep(this.$models.interventionPlanList)
            },

            studentPlanCrudConfig() {
                return this.$_.cloneDeep(this.$models.studentInterventionPlan)
            },

            statusCrudConfig() {
                return this.$_.cloneDeep(this.$models.interventionExitStatus)
            }
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                let startDate = this.$dayjs(this.date).format('YYYY-MM-DD')
                let endDate = this.$dayjs(this.date).add(14,'d').format('YYYY-MM-DD')
                this.$refs.crud.read({
                    active: 1,
                    include_students: 1,
                    user_id: this.sessionUser.user.id,
                    school_year_id: this.currentYear.id,
                    student_date_range_start: startDate,
                    student_date_range_end: endDate
                })
            },

            parseInterventions(data) {
                let interventions = []
                data.forEach(plan => {
                    if (plan.students) {
                        let data = plan.students.map(s => {
                            s.intervention_plan_name = plan.name
                            return s
                        })
                        interventions.push(...data)
                    }
                })

                this.items = interventions
            },

            postReadStatus(v) {
                this.ready = true
                this.exitStatuses = v
            },

            findLabel(collection, value, valueProp, labelProp) {
                let lbl = ''
                if (collection && value) {
                    let sel = collection.find(x => x[valueProp] === value)
                    if (sel) lbl = sel[labelProp]
                }
                return lbl
            },

            editIntervention(intervention) {
                if (!intervention.intervention_exit_status_id) intervention.intervention_exit_status_id = null
                this.$refs.studentPlanCrud.update(intervention).then(() => {
                    this.load()
                })
            }
        }
    }
</script>

<style scoped>

</style>
