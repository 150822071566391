<template>
    <div ref="backgroundContainer">
        <div class="pa-4 fill-height d-flex flex-column" :style="{height: windowHeight + 'px'}">
            <div class="flex-grow-1" style="overflow-y: auto; overflow-x: hidden;">
                <fe-remote-combo
                    label="Course or Location"
                    v-model="localValue.course"
                    itemValue="course_id"
                    itemText="course_name"
                    :items="courses"
                    :rules="$fieldValidators('select', null, true)"
                    placeholder="Select course"
                    validateOnBlur
                    @change="$emit('modified')"
                />

                <fe-label>Observation Description</fe-label>
                <fe-editor
                    :key="wysiwygRerenderKey"
                    class="compose"
                    v-model="localValue.description"
                    classic
                    basic
                />

                <fe-crud ref="coursesCrud" :config="courseCrudConfig" autoload @read="courses = $event"/>
            </div>

            <div>
                <div class="d-flex footerBtns">
                    <v-spacer/>
                    <fe-btn
                        usage="ghost"
                        @click="$emit('close')"
                    >
                        {{!isPageValid || isNewUpdate ? 'Cancel' : 'Done'}}
                    </fe-btn>

                    <fe-btn @click="next" :disabled="!isPageValid">
                        {{ !isPageValid || isNewUpdate ? 'Save & Next' : 'Next' }}
                    </fe-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex"
import {bus} from "@/main.js"
import lodash from 'lodash'

export default {
    name: 'ObservationBackground',

    props: {
        value: {
            type: Object,
            required: true
        },
        studentId: {
            type: Number,
            required: true
        },
        schoolYearId: {
            type: Number,
            required: true
        },
        isNew: {
            type: Boolean
        }
    },

    data() {
        return {
            wysiwygRerenderKey: `key-${Math.random()}`,
            localValue: {},
            courses: [],
            windowHeight: null,
            isNewUpdate: false
        }
    },

    computed: {
        ...mapState('global', ['currentYear', 'sessionUser']),

        courseCrudConfig() {
            let cfg = this.$_.cloneDeep(this.$models.courseHistory)

            cfg.read.params.school_year_id = this.schoolYearId ? this.schoolYearId: this.currentYear.id
            cfg.read.params.student_id = this.studentId

            return cfg
        },

        isPageValid() {
            return (!!this.localValue?.course && !!this.localValue?.description)
        },
    },

    watch: {
        value(v) {
            let isDescriptionChanged = (v.description !== this.localValue?.description)

            // On page load the ckeditor receives default null-ish description,
            // and even though localModel gets updated in mounted(), there's no
            // listener/watcher within the ckeditor.  Thus, we need to manually force
            // a re-render of the ckeditor component so that it shows existing
            // description text when editing a previously-saved observation
            if (isDescriptionChanged) {
                this.$nextTick(() => {
                    this.wysiwygRerenderKey = `key-${Math.random()}`
                })
            }

            this.localValue = v
        },
        localValue(v) {
            this.$emit('input', v)
        },
        'localValue.description': lodash.debounce(function () {
            this.$emit('modified')
        }, 1000),
    },

    mounted() {
        this.$nextTick(() => {
            let height = this.$refs?.backgroundContainer?.clientHeight
            this.windowHeight = height > 864 ? height - 62 : height > 802 && height < 864 ? 802 : height < 802 ? 802 : height
        })

        this.isNewUpdate = this.isNew // check if user is creating new observation
    },

    methods: {
        refreshCount() {
            bus.$emit('refreshCount')
        },

        next() {
            this.isNewUpdate = false
            this.$emit('next');
            this.refreshCount()
        },
    },
}
</script>

<style lang="scss" scoped>
.compose ::v-deep .ck-editor__editable_inline {
    min-height: 240px;
    max-height: calc(100vh - 460px);
}

.footerBtns {
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: #FFFFFF;
    width: 100%;
    height: 80px;
    padding: 20px;
}
</style>
