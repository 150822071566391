<template>
    <div class="flex-fill flex-column d-flex" style="background: #fff;">
        <fe-grid ref="grid" style="height:100%;"
            :columnDefs="mergedDefs"
            :rowData="rowData"
            :showAddRowBtn="false"
            domLayout="normal"
            displayDensity="medium"
            @selection-changed="onSelect"
            @cellMouseOver="doCellOver"
            @cellMouseOut="doCellOut"
            exportFilename="Students 3D"
            :grouped="rowGroup"
        >
            <template slot="toolbar-items">
                <fe-btn :disabled="!selection.length" usage="secondary" @click="tagStudents">Apply Tag</fe-btn>
                <fe-btn :disabled="!selection.length" usage="secondary" @click="createIntervention">Intervention</fe-btn>
            </template>
        </fe-grid>

        <fe-student-card
            ref="studentCard"
            :style="{
                position: 'absolute',
                left: `${studentCard.left}px`,
                top: `${studentCard.top}px`,
                padding: '1px 6px 1px 6px',
                zIndex: '10'
            }"
            :imageUrl="studentCard.imageUrl"
            :studentRecord="studentCard.studentRecord"
            v-show="studentCard.show"
            fullNameField="student_full_name"
            schoolField="school_name"
            gradeField="grade_desc"
            genderField="gender"
            @close="() => {}"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapLocalState } from '@/util/vuexHelper'
import * as Util from '../Util'
import InterventionCreation from '@/components/modules/intervention/creation/Index'
import studentProfileMixin from '@/mixins/studentProfile'

export default {
    name: 'Grid',

    inject: ['localStore'],

    mixins: [ studentProfileMixin ],

    props: {
        selectedOnly: {type: Boolean, default: false},
        rowGroup: {type: Boolean, default: false},
    },

    data() {
        return {
            selection: [],
            filterDefs: [],
            rowData: [],
            columnDefs: [{
                checkboxSelection: true,
                minWidth: 70,
                width: 70,
                headerCheckboxSelection: true,
                colId: 'checkbox_column'
            }, {
                headerName: 'Student Name',
                field: 'student_full_name',
                width: 200,
                sortable: true,
                onCellClicked: v => {
                    this.goToStudentProfile(v.data.student_id)
                }
            }, {
                headerName: 'EduCLIMBER ID',
                field: 'student_id',
                width: 150,
                sortable: true,
                hide: true,
            }, {
                headerName: 'District ID',
                field: 'student_district_id',
                width: 150,
                sortable: true,
                hide: true,
            }, {
                headerName: 'School',
                field: 'school_name',
                sortable: true
            }, {
                headerName: 'Grade',
                field: 'grade_desc',
                sortable: true, width: 150
            }, {
                headerName: 'Gender',
                field: 'gender',
                sortable: true,
                width: 150
            }, {
                headerName: 'Ethnicity',
                field: 'ethnicity',
                sortable: true
            }, {
                headerName: 'Group',
                field: 'group_name',
                rowGroup: this.rowGroup,
                sortable: true,
                hide: true,
            }]
        }
    },

    computed: {
        ...mapState('global',['sessionUser']),

        ...mapLocalState(['activeGrouping','params', 'selectedStudents','vueLoaderId']),

        mergedDefs() {
            return [...this.columnDefs, ...this.filterDefs]
        }
    },

    mounted() {
        this.$refs.grid.gridOptions.groupUseEntireRow = true

        this.getData()
    },

    methods: {
        onSelect() {
            let api = this.$refs.grid.gridApi
            this.selection = api.getSelectedRows()
        },

        tagStudents() {
            this.$tagStudents(true, this.selection, ()=> {})
        },

        createIntervention() {
            if(this.$parent.$options.name !== 'MainPanel') this.selectedStudents = []

            let p = this.$showPanel({
                component: InterventionCreation,
                width: 500,
                openOn: 'right',
                disableBgClick: true,
                props: {
                    class: 'pa-5',
                    students: this.selection,
                }
            })
        },

        emitEcEvent(action) {
            let target = '*'
            let msgData = JSON.stringify({
                identifier: this.vueLoaderId,
                action: action,
                data: JSON.stringify(this.selection)
            })
            window.parent.postMessage(msgData,target)
         },

        async getScoreDetailType() {
            for (let f in this.params.filters) {
                let filter = this.params.filters[f]
                if (filter.params.score_detail_type_id) {
                    await this.$modelGet('scoreDetailType', { id: filter.params.score_detail_type_id })
                        .then(r => {
                            filter['score_detail_type'] = r[0].code
                        })
                }
            }
        },

        async getData() {
            let filterDefs = {}
            let recs = []
            let selectedIndex = {}
            let selectedStudents = this.selectedStudents.forEach ? this.selectedStudents : [this.selectedStudents]
            selectedStudents.forEach(x => selectedIndex[x.student_id] = true)

            await this.getScoreDetailType()

            this.activeGrouping.groups.forEach(group => {
                group.data.forEach(x => {
                    if(this.selectedOnly && !selectedIndex[x.student_id]) return

                    // filter to unique only if this a selection
                    if(this.selectedOnly) selectedIndex[x.student_id] = false

                    // assemble the data
                    let data = {
                        group_name: group.name,
                        student_id: x.student_id,
                        student_full_name: x.student_full_name,
                        student_district_id: x.student_district_id,
                        school_name: x.school_name,
                        grade_desc: x.grade_desc,
                        gender: x.gender,
                        ethnicity: x.ethnicity,
                        id: x.student_id,
                        school_year_id: x.school_year_id
                    }

                    // get the extra text that's contained in the filters
                    Util.studentFilterText(this, this.params.filters, x).forEach(f => {
                        if(f.name) {
                            let field = 'filter_' + f.name.replace(/\W/g,'') + (f.detail ? f.detail.replace(/\s+/g, '') : '')
                            let name = !f.detail ? f.name : f.name + ' (' + f.detail + ')'
                            data[field] = f.value
                            if(!filterDefs[field]) {
                                filterDefs[field] = {
                                    headerName: name,
                                    field: field,
                                    sortable: true,
                                    valueGetter(v) {
                                        if (v.data && v.data[field]) {
                                            // get numeric part of value, if exists, for sorting
                                            let number = v.data[field].replace(/[^0-9.]/g, '')
                                            if (isNaN(parseFloat(number))) {
                                                return v.data[field]
                                            } else {
                                                return parseFloat(number)
                                            }
                                        }
                                    },
                                    cellRenderer(v) {
                                        // display full alphanumeric score display (i.e. lexile score of 1340L))
                                        return v.data[field]
                                    },
                                }
                            }
                        }
                    })
                    recs.push(Object.freeze(data))
                })
            })
            this.filterDefs = Object.values(filterDefs)

            this.rowData = recs
        },
    },
}
</script>



<style lang="scss" scoped>
</style>
