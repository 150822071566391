import Index from '@/components/modules/manage/uploads/Index'
import UploadTypes from '@/components/modules/manage/uploads/UploadTypes'
import UploadList from '@/components/modules/manage/uploads/Uploads'
import ImportLanding from '@/components/modules/manage/uploads/import/Index'
import ImportLayout from '@/components/modules/manage/uploads/import/Layout'
import SFTPManager from '@/components/modules/manage/uploads/import/SFTPManager'
import ImportSummary from '@/components/modules/manage/uploads/ImportSummary'
import ExportManager from '@/components/modules/manage/exports/ExportManager'
import ExportSmartForms from '@/components/modules/manage/exports/SmartForms'


export default [{
    path: '/Uploads',
    name: 'Uploads',
    component: Index
}, {
    path: '/Upload/Types',
    name: 'UploadTypes',
    component: UploadTypes,
    meta: {
        requireSecurity: 'UPLOAD_DATA'
    },

}, {
    path: '/Upload/Types/:status',
    name: 'UploadTypes',
    props: true,
    component: UploadTypes,
    meta: {
        requireSecurity: 'UPLOAD_DATA'
    },
}, {
    path: '/Upload/List',
    name: 'UploadList',
    component: UploadList,
    meta: {
        requireSecurity: 'UPLOAD_DATA'
    },
}, {
    path: '/Upload/List/:uploadStatusId',
    name: 'UploadList',
    props: true,
    component: UploadList,
    meta: {
        requireSecurity: 'UPLOAD_DATA'
    },
}, {
    path: '/ImportManager',
    name: 'ImportLanding',
    component: ImportLanding,
    meta: {
        requireSecurity: 'MANAGE_FTP_UPLOAD'
    },
}, {
    path: '/ImportManager/:id',
    name: 'ImportLayout',
    component: ImportLayout,
    props: true,
    meta: {
        requireSecurity: 'MANAGE_FTP_UPLOAD'
    },
}, {
    path: '/SFTPManager',
    name: 'SFTPManager',
    component: SFTPManager,
    props: true,
    meta: {
        requireSecurity: 'MANAGE_FTP_UPLOAD'
    },
}, {
    path: '/SFTPManager/:age',
    name: 'SFTPManager',
    component: SFTPManager,
    props: true,
    meta: {
        requireSecurity: 'MANAGE_FTP_UPLOAD'
    },
}, {
    path: '/ImportSummary',
    name: 'ImportSummary',
    component: ImportSummary,
    meta: {
        requireSecurity: 'UPLOAD_DATA'
    }
}, {
    path: '/ExportManager',
    name: 'ExportManager',
    component: ExportManager,
    meta: {
        requireSecurity: 'EXPORT_FORMS_DATA'
    },
}, {
    path: '/ExportManager/SmartForms',
    name: 'SmartFormExports',
    component: ExportSmartForms,
    meta: {
        requireSecurity: 'EXPORT_FORMS_DATA'
    },
}]