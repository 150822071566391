export default {
    methods: {

        /**
         * getTime() returns a Dayjs object based on a raw
         * string containing a time
         *
         * @param string rawTime
         * @returns Dayjs
         */
        getTime(rawTime) {
            const time = this.$dayjs(rawTime, 'HH:mm:ss', true)
            return time.isValid() ? time : this.$dayjs(rawTime, "h:mm A")
        },

        /**
         * getEndTime() will return a new end time based off
         * the current start and end times.
         *
         * @param null|string startTime
         * @param null|string previousEndTime
         * @returns null|string
         */
        getEndTime(startTime, previousEndTime) {
            // clear end time if start time is empty
            if (!startTime) {
                return null
            }
            let start = this.getTime(startTime)
            // clear end time if start time is invalid
            if (!start.isValid()) {
                return null
            }
            let end = this.getTime(previousEndTime)
            // return end time if valid and comes after start time
            if (end.isValid() && end.isAfter(start)) {
                return previousEndTime
            }
            // return end time 15 minutes from now
            return start.add(15, 'm').format('h:mm A')
        }
    }
}