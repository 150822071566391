<template>
    <TabPane
        emptyTitle="Create a User Notification"
        emptyBody="To get started, add a user."
        emptyButton="Add a User"
        :pending="pending"
        :error="error"
        :count="count"
        @emptyClick="addUser"
    >
        <template slot="icon">
            <h3 class="card__title card__title--group">Manage User Notifications</h3>
        </template>

        <template slot="buttons">
            <fe-btn usage="ghost" :disabled="!success && !error" text icon @click="reset">
                <v-icon>refresh</v-icon>
            </fe-btn>
        </template>

        <template slot="content">
            <fe-grid
                ref="grid"
                domLayout="autoHeight"
                :showToolbar="false"
                :columnDefs="columnDefs"
                :rowData="rowData"
                :styles="{ height: null }"
                @rowDataChanged="onSelectionChanged"
                @selectionChanged="onSelectionChanged">
            </fe-grid>

            <fe-btn class="mt-5" usage="tertiary" @click="addUser">Add a User</fe-btn>
        </template>

        <template slot="modals">
            <NotifyEditor
                v-if="editor"
                :obj="editObj"
                @apply="saveUser"
                @cancel="cancelUser"
            />

            <DeleteFooter
                modelName="user"
                :selectedIndexes="selectedIndexes"
                :items="rowData"
                @selectAll="() => $refs.grid.selectAll()"
                @deselectAll="() => $refs.grid.deselectAll()"
                @deleteSelection="removeUsers"
            />
        </template>
    </TabPane>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import TabPane from '../templates/TabPane'
import DeleteFooter from '../templates/DeleteFooter'
import NotifyEditor from '../modals/NotifyEditor'

export default {
    name: 'Notify',

    components: {
        NotifyEditor,
        TabPane,
        DeleteFooter
    },

    data() {
        return {
            selectedIndexes: [],
            deleteConfirm: false,
            editor: false,
            editObj: null,
            emailTypes: [{
                text: "Include Students",
                value: "itemized"
            }, {
                text: "Summary Only",
                value: "summarized"
            }]
        }
    },

    computed: {
        ...mapGetters('thresholds/group/notify', [
            'collection',
            'clear',
            'pending',
            'success',
            'error',
            'hasDirtied',
            'hasRemoved',
            'hasAdded',
            'hasChanges',
            'rowData',
            'count'
        ]),

        allSelected() {
            return this.selectedIndexes.length === this.rowData.length
        },

        thresholdGroupId() {
            return this.$store.getters['thresholds/group/selected'].id
        },

        columnDefs() {
            return [
                {headerName: null, headerCheckboxSelection: true, checkboxSelection: true, width: 70, minWidth: 70},
                {headerName: 'User', field: 'user_full_name', sortable: true, editable: false},
                {
                    headerName: 'School',
                    field: 'school',
                    sortable: true,
                    editable: true,
                    cellRendererFramework: "FeGridChipSelect",
                    cellRendererParams: {
                        rowDataKey: 'school',
                        mode: 'render',
                        multiple: true,
                        items: this.$store.getters['thresholds/school/collection'],
                        keyProp: 'id',
                        labelProp: 'name'
                    },
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: {
                        rowDataKey: 'school',
                        mode: 'edit',
                        multiple: true,
                        items: this.$store.getters['thresholds/school/collection'],
                        keyProp: 'id',
                        labelProp: 'name'
                    },
                    onCellValueChanged: this.updateCell,
                    cellStyle: {padding: 0}
                },
                {
                    headerName: 'Grade',
                    field: 'grade',
                    sortable: true,
                    editable: true,
                    cellRendererFramework: "FeGridChipSelect",
                    cellRendererParams: {
                        rowDataKey: 'grade',
                        mode: 'render',
                        multiple: true,
                        items: this.$store.getters['thresholds/grade/collection'],
                        keyProp: 'id',
                        labelProp: 'name'
                    },
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: {
                        rowDataKey: 'grade',
                        mode: 'edit',
                        multiple: true,
                        items: this.$store.getters['thresholds/grade/collection'],
                        keyProp: 'id',
                        labelProp: 'name'
                    },
                    onCellValueChanged: this.updateCell,
                    cellStyle: {padding: 0}
                },
                {
                    headerName: 'Tag',
                    field: 'tag',
                    sortable: true,
                    editable: true,
                    cellRendererFramework: "FeGridChipSelect",
                    cellRendererParams: {
                        rowDataKey: 'tag',
                        mode: 'render',
                        multiple: true,
                        items: this.$store.getters['thresholds/tag/collection'],
                        keyProp: 'id',
                        labelProp: 'name'
                    },
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: {
                        rowDataKey: 'tag',
                        mode: 'edit',
                        multiple: true,
                        items: this.$store.getters['thresholds/tag/collection'],
                        keyProp: 'id',
                        labelProp: 'name'
                    },
                    onCellValueChanged: this.updateCell,
                    cellStyle: {padding: 0}
                },
                {
                    headerName: 'Email',
                    field: 'email_flag',
                    sortable: true,
                    editable: false,
                    cellRendererFramework: 'FeGridToggle',
                    onCellValueChanged: this.updateCell,
                    cellStyle: {padding: 0}
                },
                {
                    headerName: 'Email Type',
                    field: 'email_type',
                    sortable: true,
                    editable: true,
                    cellRendererFramework: "FeGridChipSelect",
                    cellRendererParams: {
                        rowDataKey: 'email_type',
                        mode: 'render',
                        items: this.emailTypes,
                        keyProp: 'value',
                        labelProp: 'text'
                    },
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: {
                        rowDataKey: 'email_type',
                        mode: 'edit',
                        items: this.emailTypes,
                        keyProp: 'value',
                        labelProp: 'text'
                    },
                    onCellValueChanged: this.updateCell,
                    cellStyle: {padding: 0}
                },
                {headerName: 'Created', field: 'created', sortable: true}
            ]
        },

        hasSelection() {
            return (this.selectedIndexes.length > 0)
        }
    },

    watch: {
        clear: (v) => {
            if (v) this.reset()
        }
    },

    mounted() {
        this.reset()
    },

    updated() {
        if (this.clear) this.reset()
    },

    methods: {
        ...mapActions('thresholds/group/notify', [
            'refresh',
            'add',
            'update',
            'remove',
            'reconcile'
        ]),

        updateCell(params) {
            this.update(params.data)
            this.$refs.grid.resize()
        },

        onSelectionChanged(params) {
            this.selectedIndexes = params.api.getSelectedRows().map(itm => itm.__idx)
        },

        addUser() {
            this.editObj = null
            this.editor = true
        },

        saveUser(data) {
            if (this.editObj) {
                this.update([data])
            } else {
                this.add([data])
            }
            this.cancelUser()
        },

        cancelUser() {
            this.editObj = null
            this.editor = false
        },

        removeUsers() {
            this.remove({indexes: this.selectedIndexes})
            this.selectedIndexes = []
            this.deleteConfirm = false
        },

        reset() {
            this.refresh()
            this.selectedIndexes = []
        }
    }
}
</script>
