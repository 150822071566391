<template>
    <v-dialog
        v-model="value"
        max-width="400"
        persistent
    >
        <v-form>
            <v-card>
                <v-card-title class="headline">Find Student</v-card-title>
                <v-card-text>
                    <div class="theme--light v-label">School Year</div>
                    <fe-remote-combo
                        v-model="selectedYear"
                        :items="schoolYears"
                        item-text="name"
                        item-value="id"
                        return-object
                        solo flat dense
                        :rules="[v => !!v || 'Field is required']"
                    ></fe-remote-combo>
                    <div class="theme--light v-label">Student Name</div>
                    <v-autocomplete
                        v-model="studentId"
                        placeholder="Enter student name..."
                        ref="focus"
                        autofocus
                        :loading="isLoading"
                        :search-input.sync="userInput"
                        :items="items"
                        item-text="student_full_name"
                        item-value="student_id"
                        no-data-text="No students found..."
                        return-object
                        clearable
                        solo flat dense
                        no-filter
                        :menu-props="{ auto: true, bottom: true }"
                    >
                        <template slot="item" slot-scope="data">
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.student_full_name"/>
                                <v-list-item-subtitle style="font-size:90%;padding-bottom:6px;">{{data.item.school_name}} {{data.item.grade_desc}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                        <template slot="selection" slot-scope="data">
                            {{data.item.student_full_name}}
                        </template>
                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <fe-btn usage="tertiary" text @click="$emit('input',false)">Cancel</fe-btn>
                    <fe-btn light depressed :disabled="studentId ? false : true" @click="emitSelection">Add</fe-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'FindStudent',
    props: {
        value: { type: Boolean, default: false },
        students: { type: Array }
    },
    computed: {
        ...mapState('global', ['currentYear']),
    },
    data() {
        return {
            selectedYear: null,
            studentId: null,
            userInput: null,
            items: [],
            isLoading: false,
            schoolYears: [],
        }
    },
    watch: {
        currentYear(val) {
            this.selectedYear = val
        },
        selectedYear(val) {
            this.items = []
            this.studentId = null
            if(!val) this.selectedYear = this.currentYear.id
        },
        value(val) {
            if(val) {
                this.clear(true)
            } else {
                return
            }
        },
        userInput(nv) {
            let me = this
            if (this.selectedYear && this.selectedYear.id && nv && nv.length >= 3) {
                let url = 'students.php?action=get&property=grade_history&school_year_id='+this.selectedYear.id+'&behavior_incident=1&name=' + nv
                this.isLoading = true
                this.$axios.get(url).then((response) => {
                    me.isLoading = false
                    me.loaded = true
                    if (response && response.data) {
                        me.items = response.data.map(x => {
                            if(me.$props.students.some(y => x.student_id == y.student_id)) {
                                x.disabled = true
                            }
                            return x
                        })
                    }
                }).catch((err) => {
                    console.warn('err', err)
                })
            }
        },
    },
    mounted() {
        this.$modelGet('schoolYear').then(r => {
            this.schoolYears = r
        })
    },
    methods: {
        clear(value = false) {
            this.items = []
            this.studentId = null
            if(value) this.selectedYear = this.currentYear
            this.userInput = ''
            requestAnimationFrame(() => {
                this.$refs.focus.focus()
            })
        },
        emitSelection() {
            if(this.value) {
                this.$emit('select', this.studentId)
                this.clear()
                this.$messageBox({
                    title: 'Add Student',
                    persistent: true,
                    message: 'Would you like to add another student?',
                    maxWidth: '500',
                    actions: [{
                        text: 'No',
                        onClick: () => {
                            this.$emit('input',false)
                            this.$emit('selectedSchoolYear', this.selectedYear)
                        }
                    }, {
                        text: 'Yes',
                        primary: true,
                        onClick: () => {
                            this.$refs.focus.focus()
                        }
                    }]
                })
            }
        }
    },
}
</script>

<style scoped="true">
    .student-meta {
        font-size: 65%;
        background-color: yellow;
    }
</style>
