<template>
    <div class="flex-fill flex-column">
        <div class="my-2">
            <v-icon class="mr-4">fas fa-exclamation-circle</v-icon>Modifying school year start and end dates on the current school year can lead to data inaccuracy and system stability issues.  This should be done
            during low usage times and take proper care to ensure that dates are accurate and do not overlap with other years.
        </div>
        <fe-crud
            :config="$models.schoolYearCRUD"
            @read="items = $event"
            ref="crud"
            autoload
        />
        <fe-grid
            :rowData="items"
            ref="grid"
            :config="$models.schoolYearCRUD"
            :columnDefs="columnDefs"
            displayDensity="small"
            style="height: 100%"
            disableInlineCreate
            :showAddRowBtn="false"
        >
        </fe-grid>
    </div>
</template>

<script>
    export default {
        name: 'SchoolYears',
        data() {
            let me = this
            return {
                items: [],
                columnDefs: [{
                    headerName: "School Year",
                    field: "name",
                    editable: false,
                    flex: 1
                }, {
                    headerName: "Start Date",
                    field: "year_start",
                    editable: true,
                    flex: 1,
                    onCellValueChanged: v => {
                        me.doUpdate(v)
                    }
                }, {
                    headerName: "End Date",
                    field: "year_end",
                    editable: true,
                    flex: 1,
                    onCellValueChanged: v => {
                        me.doUpdate(v)
                    }
                },me.$grid.toggleColumn('Active', 'active', null, (v) => {
                    me.$refs.crud.update([v.data]).then(r => me.load())
                })]
            }
        },
        mounted() {
            this.$refs.grid.gridOptions.deltaRowDataMode = true
            this.$refs.grid.gridOptions.getRowNodeId = v => {
                return v.id
            }
            this.load()
        },
        methods: {
            load() {
                this.$refs.crud.read()
            },
            doUpdate(meta) {
                let count = 0
                let dt = this.$dayjs(meta.newValue)

                if (!dt.isValid()) {
                    this.$snackbars.$emit('new', { text: 'Invalid date format', usage: 'error' })
                    this.load()
                    return
                }

                this.items.forEach(rec => {
                    if (rec[meta.colDef.field] == meta.newValue) count++
                })

                if (count > 1) {
                    this.$snackbars.$emit('new', { text: meta.colDef.headerName + ' is already defined.  Dates must be unique', usage: 'error' })
                    this.load()
                    return
                }

                this.$refs.crud.update([meta.data]).then(r => this.load())
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
