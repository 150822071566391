<template>
    <div class="flex-fill flex-column">
        <div class="headline mb-3 flex-shrink-1">
            Score Details
            <div class='title-tip-adj'>
                <fe-info-tip tooltip="Score Details provide additional information to the main score displayed and are considered metadata that can be used to further analyze assessment data.  These score details can be used to create thresholds, appear in data walls and color scores in performance bands." />
            </div>
        </div>
        <div class="flex-shrink-1">
            <fe-title-select
                v-if="groups.length > 0 && selectedGroup"
                placeholder="Select an Assessment Group"
                :items="groups"
                v-model="selectedGroup"
            ></fe-title-select>
        </div>
        <fe-crud-grid
            ref="grid"
            :config="$models.scoreDetailType"
            :columnDefs="columnDefs"
            :insertDefaults="{
                data_point_type_id: group.id
            }"
            style="height: 100%;"
            :readParams="{
                data_point_type_id: group.id,
                with_option_count: 1,
                with_sub_category_count: 1,
                with_alias_count: 1,
                order: 'rank'
            }"
            refresh-on-created
            refresh-on-config
            refresh-on-destroyed
            disable-inline-create
            @beginCreate="adding = true"
            @error="onGridError"
            :flexColumns="false"
            :failOnEmptyResponse="false"
        >
            <template v-slot:cellDialog="{ cell }">
                <simple-grid-dialog-column
                    v-if="cell.column.colId == 'sub_category_cnt'"
                    ref="scs"
                    height="300px"
                    :cell="cell"
                    title="Filter Score Detail"
                    :crudConfig="$models.scoreDetailTypeSubCategory"
                    :defaultParams="{
                        score_detail_type_id: cell.data.id
                    }"
                    :insertDefaults="{
                        score_detail_type_id: cell.data.id
                    }"
                    :columnDefs="subCategoryColumnDefs"
                    @close="$refs.grid.$refs.grid.openMenu = false"
                    :buildInsertRec="buildSubCategory"
                    @read="updateSubCategoryCount"
                >
                    <template v-slot:belowGrid>
                        <div class="d-flex mt-1">
                            <div class="flex-grow-1">
                                <v-select
                                    ref="subCategoryId"
                                    flat solo dense
                                    :items="assessments"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Add an Assessment"
                                    style=" margin-top: 6px; width: 260px;"
                                    v-model="addSubCategoryId"
                                />
                            </div>
                            <div class="flex-shrink-1 text-xs-right">
                                <fe-btn
                                    :disabled="!addSubCategoryId"
                                    @click="addSubCategory"
                                >Add</fe-btn>
                            </div>
                        </div>
                    </template>
                </simple-grid-dialog-column>


                <simple-grid-dialog-column
                    v-else-if="cell.column.colId == 'option_cnt'"
                    ref="optss"
                    height="300px"
                    :cell="cell"
                    title="Score Detail Options"
                    field="value"
                    fieldHeader="Detail Options"
                    fieldPlaceholder="Add a Detail Option"
                    :fieldCounter="255"
                    :crudConfig="$models.scoreDetailTypeOption"
                    :defaultParams="{
                        score_detail_type_id: cell.data.id
                    }"
                    :insertDefaults="{
                        score_detail_type_id: cell.data.id,
                        numeric_value: cell.data.option_cnt,
                        rank: cell.data.option_cnt,
                    }"
                    :columnDefs="optionColumnDefs"
                    @close="$refs.grid.$refs.grid.openMenu = false"
                    @read="updateOptionCount"
                    @cellValueChanged="optionUpdated"
                />


                <simple-grid-dialog-column
                    v-else-if="cell.column.colId == 'alias_cnt'"
                    ref="aliases"
                    height="300px"
                    :cell="cell"
                    title="Score Detail Aliases"
                    field="alias"
                    fieldHeader="Alias"
                    fieldPlaceholder="Add an Alias"
                    :fieldCounter="255"
                    :crudConfig="$models.scoreDetailTypeAlias"
                    :readParams="{
                        score_detail_type_id: cell.data.id
                    }"
                    :insertDefaults="{
                        data_point_type_id: group.id,
                        score_detail_type_id: cell.data.id
                    }"
                    @close="$refs.grid.$refs.grid.openMenu = false"
                    @read="updateAliasCount"
                />
            </template>
        </fe-crud-grid>

        <fe-crud
            ref="crudAssessments"
            autoload
            :config="$models.subcategory"
            :readParams="{ data_point_type_id: group.id }"
            refresh-on-config
            @read="assessments = $event"
        />

        <fe-crud
            ref="crudWindows"
            autoload
            :config="$models.dataPointName"
            :readParams="{ data_point_type_id: this.group.id }"
            refresh-on-config
            @read="windows = $event"
        />

        <fe-crud
            ref="crudProjectionTypes"
            autoload
            :config="$models.projectionType"
            @read="projectionTypes = $event"
        />

        <fe-dialog
            v-if="adding"
            @close="adding = false"
            @dismiss="adding = false"
            @accept="add"
            title="Add Score Detail"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            :acceptButtonDisabled="!valid"
            persistent
        >
            <v-form ref="form" @submit.prevent v-model="valid">
                <div class="d-flex flex-column">
                    <fe-label>Detail Name</fe-label>
                    <v-text-field
                        v-model="obj.code"
                        flat solo dense
                        :style="{ width: '100%' }"
                        :rules="rules.code"
                    />

                    <fe-label>Description</fe-label>
                    <v-text-field
                        v-model="obj.description"
                        flat solo dense
                        :style="{ width: '100%' }"
                        :rules="rules.description"
                    />
                </div>
            </v-form>
        </fe-dialog>
    </div>
</template>

<script>
    import SimpleGridDialogColumn from "@/components/common/SimpleGridDialogColumn"

    export default {
        name: 'AssessmentGroupScoreDetails',
        props: {
            group: { type: Object, default: () => {}, required: true },
            groups: { type: Array, default: () => [] }
        },
        data: () => {
            return {
                valid: true,
                adding: false,
                obj: {
                    code: null,
                    description: null,
                    projection_calculation_type_id: null,
                    from_data_point_name_id: null,
                    to_data_point_name_id: null,
                },
                rules: {
                    code: [
                        v => (!!v && v.trim().length > 0) || 'Please enter a code',
                        v => !v || v.trim().length <= 100 || 'Must be less than 100 characters',
                    ],
                    description: [
                        v => (!!v && v.trim().length > 0) || 'Please enter a description',
                        v => !v || v.trim().length <= 255 || 'Must be less than 255 characters',
                    ]
                },
                cell: null,
                assessments: null,
                windows: null,
                projectionTypes: null,
                addSubCategoryId: null,
                nextOptionRank: 0
            }
        },
        components: {
            SimpleGridDialogColumn
        },
        computed: {
            valuesLoaded () { return !!this.windows && !!this.projectionTypes && !!this.assessments },
            selectedGroup: {
                get () { return this.group },
                set (v) { this.$emit('switchGroup', v) }
            },
            columnDefs () {
                let me = this
                return !this.valuesLoaded ? [] : [
                    { headerName: null, headerCheckboxSelection: true, checkboxSelection: true, width: 50, minWidth: 50, pinned: "left" },
                    {
                        headerName: "Detail Name",
                        field: "code",
                        sortable: true,
                        editable: true,
                        pinned: "left",
                        minWidth: 200,
                        cellEditorFramework: 'FeGridTextField',
                        cellEditorParams: v => {
                            return {
                                type: 'text',
                                rules: this.$fieldValidators('text', v.code, {
                                    required: true,
                                }),
                                maxLength: 100,
                                block: true,
                            }
                        },
                    },
                    {
                        headerName: "Description",
                        field: "description",
                        sortable: true,
                        editable: true,
                        cellEditorFramework: 'FeGridTextField',
                        cellEditorParams: v => {
                            return {
                                type: 'text',
                                rules: this.$fieldValidators('text', v.code, {
                                    required: true,
                                }),
                                block: true,
                            }
                        },
                    },
                    {
                        headerName: "Used in Progress Monitoring",
                        field: "probe_flag",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: 'FeGridToggle'
                    },
                    {
                        headerName: "Restrict to Assessment",
                        field: "sub_category_cnt",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: 'FeDialogColumn',
                        onCellClicked: this.cellClicked
                    },
                    {
                        headerName: "Detail Options",
                        field: "option_cnt",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: 'FeDialogColumn',
                        onCellClicked: this.cellClicked
                    },
                    {
                        headerName: "Calculation Type",
                        field: "projection_calculation_type_id",
                        sortable: true,
                        editable: true,
                        cellRenderer(v) { return me.findLabel(me.projectionTypes, v.data.projection_calculation_type_id, 'id', 'description') },
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: {
                            rowDataKey: "projection_calculation_type_id",
                            mode: "edit",
                            items: me.projectionTypes,
                            keyProp: "id",
                            labelProp: "description",
                            disableChips: true
                        }
                    },
                    {
                        headerName: "From Window",
                        field: "from_data_point_name_id",
                        sortable: true,
                        editable: true,
                        cellRenderer(v) { return me.findLabel(me.windows, v.data.from_data_point_name_id, 'id', 'name') },
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: {
                            rowDataKey: "from_data_point_name_id",
                            mode: "edit",
                            items: me.windows,
                            keyProp: "id",
                            labelProp: "name",
                            disableChips: true
                        }
                    },
                    {
                        headerName: "To Window",
                        field: "to_data_point_name_id",
                        sortable: true,
                        editable: true,
                        cellRenderer(v) { return me.findLabel(me.windows, v.data.to_data_point_name_id, 'id', 'name') },
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: {
                            rowDataKey: "to_data_point_name_id",
                            mode: "edit",
                            items: me.windows,
                            keyProp: "id",
                            labelProp: "name",
                            disableChips: true
                        }
                    },
                    {
                        headerName: "Preferred Projection",
                        field: "primary_projection",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: 'FeGridToggle'
                    },
                    {
                        headerName: "Aliases",
                        field: "alias_cnt",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: 'FeDialogColumn',
                        onCellClicked: this.cellClicked,
                        hide: true
                    },
                    {
                        headerName: "Hide from Score Entry Screen",
                        field: "hide_entry",
                        sortable: false,
                        editable: false,
                        cellRendererFramework: 'FeGridToggle',
                        hide: true,
                    },
                    {
                        headerName: "Order",
                        field: "rank",
                        sortable: true,
                        editable: true,
                        cellRendererFramework: 'FeDialogColumn',
                        onCellClicked: this.cellClicked
                    }
                ]
            },
            subCategoryColumnDefs () {
                let me = this
                return !this.valuesLoaded ? [] : [
                    {
                        headerName: "Assessment",
                        field: "sub_category_id",
                        sortable: true,
                        editable: false,
                        cellRenderer(v) { return me.findLabel(me.assessments, v.data.sub_category_id, 'id', 'name') }
                    }
                ]
            },
            optionColumnDefs () {
                let me = this
                return !this.valuesLoaded ? [] : [
                    {
                        headerName: "Performance Band Value",
                        field: "numeric_value",
                        sortable: true,
                        editable: true
                    },
                    {
                        headerName: "Order",
                        field: "rank",
                        sortable: true,
                        editable: true
                    }
                ]
            }
        },
        methods: {
            add () {
                if (this.$refs.form.validate()) {
                    let itm = Object.assign({}, this.obj, {
                        data_point_type_id: this.group.id
                    })
                    this.$refs.grid.$refs.crud.create(itm)
                }
            },
            buildSubCategory() {
                return {
                    sub_category_id: this.addSubCategoryId,
                    score_detail_type_id: this.cell.data.id
                }
            },
            addSubCategory() {
                this.$refs.scs.createItem()
            },
            updateSubCategories() {
                this.$refs.scs.loadItems()
            },
            updateSubCategoryCount(items) {
                this.$refs.grid.updateCachedRecords({
                    id: this.cell.data.id,
                    sub_category_cnt: items.length
                })
                this.refreshCrudGridCells()
                this.$nextTick(() => {
                    if (this.$refs.grid.$refs.grid.filterText?.length) this.$refs.grid.$refs.grid.filterResults()
                })
            },
            updateOptionCount(items) {
                this.$refs.grid.updateCachedRecords({
                    id: this.cell.data.id,
                    option_cnt: items.length
                })
                this.refreshCrudGridCells()
                this.$nextTick(() => {
                    if (this.$refs.grid.$refs.grid.filterText?.length) this.$refs.grid.$refs.grid.filterResults()
                })
            },
            updateAliasCount(items) {
                this.$refs.grid.updateCachedRecords({
                    id: this.cell.data.id,
                    alias_cnt: items.length
                })
                this.refreshCrudGridCells()
                this.$nextTick(() => {
                    if (this.$refs.grid.$refs.grid.filterText?.length) this.$refs.grid.$refs.grid.filterResults()
                })
            },
            cellClicked(cell) {
                this.cell = cell
                this.addSubCategoryId = null
                this.$refs.grid.$refs.grid.setDialogCell(cell)
            },
            optionUpdated(cell) {
                cell.crud.update(cell.data)
            },
            findLabel(collection, value, valueProp, labelProp, countLabel) {
                let lbl = ''
                if (collection && value) {
                    if (Array.isArray(value)) {
                        return `${value.length} ${countLabel}`
                    } else {
                        let sel = collection.find(x => x[valueProp] == value)
                        if (sel) lbl = sel[labelProp]
                    }
                }
                return lbl
            },
            refreshCrudGridCells (ref='grid') {
                try {
                    this.$refs[ref].$refs.grid.gridApi.refreshCells()
                } catch (err) {
                    console.warn({ err: err })
                }
            },
            onGridError(err) {
                this.$refs.grid.$refs.crud.read()
            },
        },
        watch: {
            adding (v) {
                if (v) Object.keys(this.obj).forEach(k => this.obj[k] = null)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .title-tip-adj {
        display: inline-block;
        position: relative;
        top: -3px;
    }
</style>
