<template>
    <fe-grid
        ref="grid"
        :rowData="dashboards"
        :columnDefs="columnDefs"
        :showToolbar="false"
        :searchBar="false"
        :showAddRowBtn="false"
        :showDownload="false"
        style="height: 100%;"
        disableInlineCreate
        suppressContextMenu
        @gridReady="resize"
        @rowClicked="rowClicked"
    />
</template>

<script>
import Tooltip from "@/components/common/grid/Tooltip"

export default {
    name: "ListView",

    components: {Tooltip},

    props: {
        dashboards: {type: Array, default: []}
    },

    data() {
        let me = this
        return {
            columnDefs: [{
                headerName: 'Name',
                field: 'name',
                sortable: true,
                editable: false,
                cellRendererFramework: Tooltip,
                width: 250
            }, {
                headerName: 'Description',
                field: 'description',
                cellRendererFramework: Tooltip,
                sortable: true,
                editable: false
            }, {
                headerName: 'Year',
                field: 'school_year_name',
                sortable: true,
                editable: false,
                width: 130
            }, {
                headerName: 'Type',
                field: 'shared',
                valueGetter(v) {
                    let data = v.data
                    if (data.shared) return "Shared With Me"
                    if (data.district_default) return "District Dashboard"

                    return "My Dashboard"
                },
                sortable: true,
                editable: false,
                width: 170
            }, {
                headerName: 'Vis Count',
                field: 'configs',
                valueGetter(v) {
                    return v.data.configs?.length || 0
                },
                sortable: true,
                editable: false,
                width: 120
            }, {
                headerName: 'Vis Types',
                field: 'configs',
                valueGetter(v) {
                    let types = {}
                    v.data.configs.forEach(x => {
                        let type = x.config.type
                        if (type !== 'section' && !types[type]) {
                            types[type] = me.$parent.possibleVisTypes[type].name
                        }
                    })

                    return Object.values(types).join(', ')
                },
                sortable: true,
                editable: false,
                cellRendererFramework: Tooltip,
                width: 200
            }, {
                headerName: 'Creator',
                field: 'created_by_user_name',
                cellRendererFramework: Tooltip,
                sortable: true,
                editable: false
            }, {
                headerName: 'Created Date',
                field: 'created',
                valueFormatter: v => this.$dayjs(v.data.created).format('MM/DD/YYYY'),
                sortable: true,
                editable: false,
                width: 150
            }]
        }
    },

    methods: {
        rowClicked(v) {
            this.$emit('select', v.data)
        },

        resize() {
            this.$refs.grid?.gridApi?.sizeColumnsToFit()
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .ag-center-cols-container {
    cursor: pointer;
}
</style>
