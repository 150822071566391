<template>
    <div class="flex-fill flex-column">
        <div class="headline mb-3 flex-shrink-1">
            Embargo Dates
            <div class='title-tip-adj'>
                <fe-info-tip tooltip="Embargo Dates allow you to restrict visibility of Assessment scores to specified users until a specified date.  Only users specifically granted access to data for an Assessment and Window can view scoring data until that date is reached." />
            </div>
        </div>
        <div class="flex-shrink-1">
            <fe-title-select
                v-if="groups.length > 0 && selectedGroup"
                placeholder="Select an Assessment Group"
                :items="groups"
                v-model="selectedGroup"
            ></fe-title-select>
        </div>

        <fe-crud
            :config="$models.schoolYearCRUD"
            @read="schoolYears = $event.reverse()"
            autoload
        />
        <fe-crud
            :config="$models.user"
            @read="users = $event"
            autoload
        />

        <fe-grid
            ref="grid"
            :columnDefs="columnDefs"
            :rowData="rowData"
            style="height: 100%;"
            disable-inline-create
            @selectionChanged="selectionChanged"
            @beginCreate="beginAdd"
            @inserted="() => {}"
            @deleted="onDeleteEmbargoDates"
        >
            <template #toolbar-items>
                <fe-remote-combo
                    :items="schoolYears"
                    v-model="selectedSchoolYear"
                    :clearable="false"
                />
            </template>

            <template #extra-tools>
                <fe-btn
                    usage="ghost"
                    useIcon="far fa-pencil"
                    whiteText
                    @click="bulkEditDate.show = true"
                    class="fe-grid-action-btn"
                >
                    Change Date
                </fe-btn>
            </template>

            <template #cellDialog="{ cell }">
                <simple-grid-dialog-column v-if="cell.colDef.cellRendererFramework=='FeDialogColumn'"
                    title="Restriction to Users"
                    :crudConfig="$models.dataPointEmbargoUser"
                    :cell="cell"
                    :insertDefaults="cell.data"
                    :readParams="{ data_point_embargo_id: cell.data.id }"
                    :columnDefs="cellDialogs.users.columnDefs"
                    field="data_point_embargo_id"
                    :showField="false"
                    :fieldConfig="cellDialogs.users.fieldConfig"
                    :buildInsertRec="cellDialogs.users.buildRec"
                    @count="updateCellCount(cell, $event)"
                    @close="$refs.grid.openMenu = false"
                />
            </template>
        </fe-grid>

        <fe-crud
            ref="crud"
            :config="$models.dataPointEmbargo"
            :insertDefaults="{
                created_by: null,
                data_point_type_id: group.id
            }"
            :readParams="{
                school_year_id: selectedSchoolYear && selectedSchoolYear.id,
                data_point_type_id: group.id,
            }"
            refresh-on-created
            refresh-on-config
            @beginCreate="beginAdd"
            @read="onCrudRead"
            @created="refresh"
            @updated="refresh"
            @destroyed="refresh"
        />

        <fe-dialog
            v-if="bulkEditDate.show"
            @close="bulkEditDate.show = false"
            @dismiss="bulkEditDate.show = false"
            @accept="onBulkEditEmbargoDates"
            title="Modify Embargo Lift Date"
            dismissButtonText="Cancel"
            acceptButtonText="Save"
            :acceptButtonDisabled="!isBulkEditDateFormValid"
            persistent
        >
            <v-form ref="form" @submit.prevent v-model="isBulkEditDateFormValid">
                <div class="d-flex flex-column">
                    <div class="d-flex">
                        <div class="col col-6" style="padding-left: 0;">
                            <fe-label>Embargo Lift Date</fe-label>
                            <fe-date-picker
                                v-model="obj.embargoLiftDate"
                                :rules="[rules.required]"
                            />
                        </div>

                        <div class="col col-6" style="padding-right: 0;">
                            <fe-label>Embargo Lift Time</fe-label>
                            <fe-time-scroll-picker
                                v-model="obj.embargoLiftTime"
                                :rules="[rules.required]"
                            />
                        </div>
                    </div>
                </div>
            </v-form>
        </fe-dialog>

        <fe-dialog
            v-if="adding"
            class="dialog"
            @close="adding = false"
            @dismiss="adding = false"
            @accept="onSaveEmbargoDates"
            title="Add Embargo"
            dismissButtonText="Cancel"
            acceptButtonText="Save"
            :acceptButtonDisabled="!isCreateFormValid"
            persistent
        >
            <v-form ref="form" @submit.prevent v-model="isCreateFormValid">
                <div class="d-flex flex-column">
                    <fe-label>Assessment</fe-label>
                    <fe-remote-combo
                        v-model="obj.sub_category_id"
                        multiple moreText
                        byId
                        :style="{ width: '100%' }"
                        :items="subCategoriesItems"
                        :rules="[rules.required]"
                    />

                    <fe-label>Window</fe-label>
                    <fe-remote-combo
                        v-model="obj.data_point_name_id"
                        multiple moreText
                        byId
                        :style="{ width: '100%' }"
                        :items="dataPointNamesItems"
                        :rules="[rules.required]"
                    />

                    <div class="d-flex">
                        <div class="col col-6" style="padding-left: 0;">
                            <fe-label>Embargo Lift Date</fe-label>
                            <fe-date-picker
                                v-model="obj.embargoLiftDate"
                                :rules="[rules.required]"
                            />
                        </div>

                        <div class="col col-6" style="padding-right: 0;">
                            <fe-label>Embargo Lift Time</fe-label>
                            <fe-time-scroll-picker
                                v-model="obj.embargoLiftTime"
                                :rules="[rules.required]"
                            />
                        </div>
                    </div>

                    <fe-label>Users</fe-label>
                    <fe-remote-combo
                        v-model="obj.users"
                        url="users.php?action=get&active=1&property=data_point_embargoes"
                        itemText="user_full_name"
                        itemValue="id"
                        multiple moreText
                        byId
                        :rules="[rules.required]"
                    />
                </div>
            </v-form>
        </fe-dialog>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    import SimpleGridDialogColumn from '@/components/common/SimpleGridDialogColumn'

    export default {
        name: 'AssessmentGroupEmbargoDates',
        components: {
            SimpleGridDialogColumn,
        },
        props: {
            group: { type: Object, default: () => {}, required: true },
            groups: { type: Array, default: () => [] }
        },
        data: () => {
            return {
                users: [],
                selectedSchoolYear: null,
                schoolYears: [],
                subCategories: [],
                dataPointNames: [],
                embargoDates: [],
                adding: false,
                isCreateFormValid: true,
                obj: {
                    numeric_score: null,
                    alpha_score: null,
                    excl_calc: false,
                    excl_score: false,
                },
                selections: [],
                bulkEditDate: {
                    show: false,
                },
                isBulkEditDateFormValid: true,
                rules: {
                    required: v => !!v || 'This field is required',
                    numeric_score: [
                        v => (!!v && !isNaN(v)) || 'Please enter a numeric score'
                    ],
                    alpha_score: [
                        v => (!!v && v.trim().length > 0) || 'Please enter display text'
                    ]
                },
            }
        },
        computed: {
            ...mapState('global', ['currentYear', 'sessionUser']),
            selectedGroup: {
                get () { return this.group },
                set (v) { this.$emit('switchGroup', v) }
            },
            columnDefs() {
                return [
                    { headerName: null, headerCheckboxSelection: true, checkboxSelection: true, width: 70, minWidth: 70, maxWidth: 70, colId: 'checkbox_column' },
                    {
                        headerName: "Assessment Name",
                        field: "assessment_name",
                        sortable: true,
                        editable: false,
                        onCellClicked: this.onCellClicked,
                    },
                    {
                        headerName: "Assessment Window",
                        field: "assessment_window",
                        sortable: true,
                        editable: false,
                        onCellClicked: this.onCellClicked,
                    },
                    {
                        colId: 'embargoLiftDate',
                        headerName: "Embargo Lift Date",
                        field: "embargoLiftDate",
                        sortable: true,
                        editable: false,
                        onCellClicked: this.onCellClicked,
                        cellRenderer: this.formatDate,
                    },
                    {
                        colId: 'embargoLiftTime',
                        headerName: "Embargo Lift Time",
                        field: "embargoLiftTime",
                        sortable: true,
                        editable: false,
                        onCellClicked: this.onCellClicked,
                        cellRenderer: this.formatTime,
                    },
                    {
                        colId: 'userIdCount',
                        headerName: "Restriction to Users",
                        field: "userIdCount",
                        sortable: true,
                        editable: false,
                        minWidth: 100,
                        width: 100,
                        maxWidth: 100,
                        cellRendererFramework: 'FeDialogColumn',
                        onCellClicked: this.onCellClicked,
                    },
                    {
                        headerName: "Created By",
                        field: "createdBy",
                        sortable: true,
                        editable: false,
                        onCellClicked: this.onCellClicked,
                    }
                ]
            },
            cellDialogs() {
                return {
                    users: {
                        columnDefs: [
                            {
                                headerName: "User",
                                field: "user_full_name",
                                sortable: true,
                                editable: false,
                            },
                        ],
                        fieldConfig: {
                            type: 'combo',
                            url: 'users.php?action=get&active=1&property=data_point_embargoes',
                            filter: function(x) { return !this.find(y => y.user_id == x.id) },
                            itemText: 'user_full_name',
                            itemValue: 'id',
                        },
                        buildRec: (v, d) => {
                            return {
                                data_point_embargo_id: d.id,
                                user_id: v.id,
                            }
                        },
                    },
                }
            },
            rowData() {
                let results = this.embargoDates.map(itm => ({
                    ...itm,
                    assessment_name: this.subCategories.find(sc => sc.id === itm.sub_category_id)?.name || '',
                    assessment_name_rank: this.subCategories.find(sc => sc.id === itm.sub_category_id)?.rank || 1,
                    assessment_window: this.dataPointNames.find(dpn => dpn.id === itm.data_point_name_id)?.name || '',
                    assessment_window_rank: this.dataPointNames.find(sc => sc.id === itm.sub_category_id)?.rank || 1,
                    embargoLiftDate: itm.embargo_lift_datetime?.split(' ')[0],
                    embargoLiftTime: itm.embargo_lift_datetime?.split(' ')[1],
                    userIdCount: itm.user_id.length || 0,
                    createdBy: this.users.find(u => u.id === itm.created_by)?.user_full_name || '-',
                }))

                results.sort((a, b) => {
                    if (a.assessment_name_rank === b.assessment_name_rank) {
                        return a.assessment_window_rank - b.assessment_window_rank
                    } else {
                        return a.assessment_name_rank - b.assessment_name_rank
                    }
                })

                return results
            },
            subCategoriesItems() {
                if (!this.subCategories?.length) {
                    return []
                }

                return [
                    { id: -1, name: 'All Assessments' },
                    ...this.subCategories
                ]
            },
            dataPointNamesItems() {
                if (!this.dataPointNames?.length) {
                    return []
                }

                return [
                    { id: -1, name: 'All Windows' },
                    ...this.dataPointNames
                ]
            },
        },
        watch: {
            selectedSchoolYear(v) {
            },
            group() {
                this.loadBase()
            },
            'bulkEditDate.show'(v) {
                if (v) {
                    // Clear relevant form inputs when re-requesting bulk edit dialog
                    this.obj = {
                        embargoLiftDate: '',
                        embargoLiftTime: '',
                    }
                }
            },
            'obj.sub_category_id'(v, prevVal) {
                if (v?.includes(-1) && v?.length > 1) {
                    if (prevVal?.includes(-1)) {
                        this.obj.sub_category_id = this.obj.sub_category_id.filter(itm => itm !== -1)
                    } else {
                        this.obj.sub_category_id = this.obj.sub_category_id.filter(itm => itm === -1)
                    }
                }
            },
            'obj.data_point_name_id'(v, prevVal) {
                if (v?.includes(-1) && v?.length > 1) {
                    if (prevVal?.includes(-1)) {
                        this.obj.data_point_name_id = this.obj.data_point_name_id.filter(itm => itm !== -1)
                    } else {
                        this.obj.data_point_name_id = this.obj.data_point_name_id.filter(itm => itm === -1)
                    }
                }
            },
        },
        mounted() {
            this.selectedSchoolYear = this.currentYear
            this.loadBase()
        },
        methods: {
            loadBase() {
                this.subCategories = []
                this.dataPointNames = []

                this.$axios.get(`subcategories.php?action=get&data_point_type_id=${this.group.id}`).then(res => {
                    this.subCategories = res.data?.subcategories || []
                })

                this.$axios.get(`dataPointName.php?action=get&data_point_type_id=${this.group.id}`).then(res => {
                    this.dataPointNames = res.data?.names || []
                })
            },
            formatDate(v) {
                return this.$dayjs(v.data?.embargoLiftDate).format('MMMM Do, YYYY')
            },
            formatTime(v) {
                return this.$dayjs(`2021-01-01 ${v.data?.embargoLiftTime}`).format('h:mma')
            },
            onCellClicked(v) {
                if (v.column.colId != 'userIdCount') {
                    v.node.setSelected(!v.node.isSelected())
                } else {
                    let cfg = v.column.colId == 'toolmenu' ? 'menu' : null
                    this.$refs.grid.setDialogCell(v, cfg)
                }
            },
            updateCellCount(cell, count) {
                cell.node.setDataValue(cell.colDef.field, count)
            },
            selectionChanged (params) {
                this.selections = params.api.getSelectedRows()
            },
            beginAdd() {
                this.obj = {
                    sub_category_id: null,
                    data_point_name_id: null,
                    embargoLiftDate: '',
                    embargoLiftTime: '',
                    users: [],
                }
                this.adding = true
            },
            onCrudRead(records) {
                this.embargoDates = records
            },
            onSaveEmbargoDates() {
                let createPayload = []
                let updatePayload = []

                let subCategoryIds = this.obj.sub_category_id
                if (subCategoryIds.includes(-1)) {
                    subCategoryIds = this.subCategories.map(sc => sc.id)
                }

                let dataPointNameIds = this.obj.data_point_name_id
                if (dataPointNameIds.includes(-1)) {
                    dataPointNameIds = this.dataPointNames.map(dpn => dpn.id)
                }

                for (let scid of subCategoryIds) {
                    for (let wid of dataPointNameIds) {
                        let payload = {
                            school_year_id: this.selectedSchoolYear.id,
                            data_point_type_id: this.group.id,
                            sub_category_id: scid,
                            data_point_name_id: wid,
                            embargo_lift_datetime: `${this.obj.embargoLiftDate} ${this.obj.embargoLiftTime}`,
                            user_id: this.obj.users,
                        }

                        let rec = this.embargoDates.find(ed => ed.sub_category_id === scid && ed.data_point_name_id === wid)
                        if (rec) {
                            updatePayload.push({
                                ...payload,
                                id: rec.id,
                            })
                        } else {
                            createPayload.push({
                                ...payload,
                            })
                        }
                    }
                }

                if (createPayload.length) {
                    this.$refs.crud.create(createPayload).then(() => {
                        this.$emit('reloadCurrent')
                    })
                }
                if (updatePayload.length) {
                    this.$refs.crud.update(updatePayload)
                }
            },
            onBulkEditEmbargoDates() {
                let updatePayload = this.selections.map(itm => ({
                    id: itm.id,
                    embargo_lift_datetime: `${this.obj.embargoLiftDate} ${this.obj.embargoLiftTime}`,
                }))

                if (updatePayload.length) {
                    this.$refs.crud.update(updatePayload)
                }
            },
            onDeleteEmbargoDates(records) {
                this.$confirmDelete(
                    records.data,
                    () => {
                        let payload = records.data.map(itm => ({ id: itm.id }))
                        this.$refs.crud.destroy(payload).then(() => {
                            this.$refs.grid.deleteSuccess()
                            this.$emit('reloadCurrent')
                        }).catch(() => {
                            this.$refs.grid.deleteError()
                        })
                    },
                    () => {
                        this.$refs.grid.editCancel()
                    }
                )
            },
            refresh(a, b, c) {
                this.$refs.crud.read()
                //this.$store.dispatch('global/loadAlphaMaps')
            }
        }
    }
</script>

<style lang="scss" scoped>

::v-deep .col {
    /*padding: 0 !important;*/
}

.title-tip-adj {
    display: inline-block;
    position: relative;
    top: -3px;
}

</style>
