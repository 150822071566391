<template>
    <form-common-config v-bind="$attrs" v-on="$listeners">
        <template #fieldSpecific="{rec}">
            <div class="d-flex">
                <fe-label :style="labelStyle">Incident Search Range</fe-label>

                <fe-remote-combo
                    v-model="savedSearch.incident_date_type"
                    style="margin-left:auto;"
                    itemValue="value"
                    :items="[
                        {value:'current', name:'Current Year'},
                        {value:'all', name:'All Years'},
                        {value:'custom', name:'Date Range'},
                    ]"
                    :clearable="false"
                    byId
                />
            </div>

            <div v-if="savedSearch.incident_date_type == 'custom'" class="d-flex">
                <fe-date-picker
                    class="pr-1"
                    ref="start_date"
                    v-model="savedSearch.start_date"
                    label="Start Date"
                    :max="savedSearch.end_date"
                />

                <fe-date-picker
                    ref="end_date"
                    v-model="savedSearch.end_date"
                    label="End Date"
                    :min="savedSearch.start_date"
                />
            </div>

            <div class="d-flex">
                <fe-label :style="labelStyle">
                    As of Creation Date

                    <v-flex style="display: inline-block; margin-right: 10px">
                        <fe-info-tip
                            tooltip="This will ONLY display incidents that were created as of this smartFORM creation date."
                        />
                    </v-flex>
                </fe-label>

                <fe-switch
                    v-model="savedSearch.incident_to_creation"
                    style="margin-left:auto;"
                    :true-value="1"
                    :false-value="0"
                />
            </div>

            <fe-label :style="labelStyle">Incident Type</fe-label>

            <fe-remote-combo
                v-model="savedSearch.incident_behavior_type_id"
                moreLabel="Incident Type"
                placeholder="All Incident Types"
                style="margin-left:auto;"
                url="incidentBehaviorType.php?action=get"
                rootProperty="incident_behavior_type"
                byId multiple
            />

            <fe-label :style="labelStyle">Incident Code</fe-label>

            <fe-remote-combo
                v-model="savedSearch.incident_behavior_id"
                moreLabel="Incident Code"
                placeholder="All Incident Codes"
                style="margin-left:auto;"
                url="incidentBehavior.php?action=get"
                :params="savedSearch.incident_behavior_type_id ? {
                    incident_behavior_type_id: savedSearch.incident_behavior_type_id
                } : null "
                rootProperty="incident_behavior"
                byId multiple
            />

            <fe-label :style="labelStyle">Table Settings</fe-label>

            <fe-remote-combo
                v-model="savedSearch.incident_display_columns"
                moreLabel="Columns"
                style="margin-left:auto;"
                itemValue="value"
                :items="[
                    {value:'incident_datetime', name:'Date'},
                    {value:'column_time', name:'Time'},
                    {value:'incident_behavior_display_name', name:'Incident Type'},
                    {value:'incident_behavior_name', name:'Incident Code'},
                    {value:'description', name:'Description'},
                    {value:'incident_location_name', name:'Location'},
                    {value:'incident_response_name', name:'Response'},
                    {value:'observer_user_full_name', name:'Observer'},
                    {value:'incident_motivation_name', name:'Motivation'},
                    {value:'grade_desc', name:'Grade'},
                    {value:'school_name', name:'School'},
                    {value:'school_year_name', name:'School Year'},
                    {value:'entry_user_full_name', name:'Entry User'},
                    {value:'responder_user_full_name', name:'Responder'},
                    {value:'description', name:'Description'},
                ]"
                :clearable="false"
                byId multiple
            >
                <template v-slot:item="data">
                    <v-checkbox
                        :input-value="savedSearch.incident_display_columns.find(x => x == data.item.value)"
                        hide-details
                        :ripple="false"
                        class="ec-smartincidentfield-columns-checkbox"
                    />
                    {{ data.item.name }}
                    <fe-tooltip tooltip="This field may contain student names when imported from the Student Information System (SIS).">
                        <v-icon v-if="data.item.value == 'description'" class="ml-2" small>far fa-info-circle</v-icon>
                    </fe-tooltip>
                </template>
            </fe-remote-combo>

            <fe-crud
                ref="crud"
                :key="rec.id"
                :readParams="{form_field_id:rec.id}"
                :config="$models.formFieldSavedSearch"
                autoload
                @read="processRead($event,rec)"
            />
        </template>
    </form-common-config>
</template>

<script>
import {mapLocalState} from '@/util/vuexHelper'
import FormCommonConfig from '../FormCommonConfig'
import configMixin from './configMixin'

export default {
    inject: ['localStore'],

    mixins: [configMixin],

    components: {
        FormCommonConfig,
    },

    data() {
        return {
            savedSearch: {},
            cachedValue: {},
        }
    },

    computed: {
        ...mapLocalState(['reloadFn', 'selectedComponent']),

        labelStyle: () => ({minWidth: '120px'}),
    },

    watch: {
        savedSearch: {
            deep: true,
            handler: 'save',
        },
    },

    methods: {
        processRead(v, rec) {
            this.savedSearch = this.processSavedSearch(v, {
                form_field_id: rec.id,
                incident_date_type: 'current',
                incident_to_creation: 0,
                incident_display_columns: [
                    'incident_datetime',
                    'incident_behavior_display_name',
                    'incident_behavior_name',
                    'incident_location_name',
                    'incident_response_name',
                    'observer_user_full_name',
                ],
            })
            if (!this.$_.isBlank(v)) {
                this.cachedValue = {...this.savedSearch}
            }
        },

        save() {
            let dirty = JSON.stringify(this.cachedValue) != JSON.stringify(this.savedSearch)
            if (dirty) {
                this.cachedValue = {...this.savedSearch}
                let fieldId
                this.$refs.crud.create({...this.savedSearch, form_saved_search_type_id: 2}).then(r => {
                    fieldId = r.data?.form_field_ids[0]
                }).finally(() => {
                    this.selectedComponent.$refs.localField.savedSearch = {
                        ...this.selectedComponent.$refs.localField.savedSearch,
                        INCIDENT_DISPLAY_COLUMNS: this.savedSearch.incident_display_columns
                    }
                    this.reloadFn({fieldId: fieldId})
                })
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.ec-smartincidentfield-columns-checkbox {
    margin-right: 22px;
    ::v-deep .v-input__slot {
        padding: 0 !important;
    }
    ::v-deep i {
        color: rgba(0,0,0,.54) !important;
    }
}
</style>
