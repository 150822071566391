let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    savedSearch: {
        defaults: {
            endpoint: 'savedSearch.php'
        },
        ...standardCrud
    },
    savedSearchValues: {
        defaults: {
            endpoint: 'savedSearch.php?property=values',
            rootProperty: 'saved_search_values'
        },
        ...standardCrud
    }
}
