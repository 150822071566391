export default {
    scoreDetailTypeOption: {
        rules: [
            r => (r && r.score_detail_type_id && r.score_detail_type_id > 0) || { score_detail_type_id: 'Score Detail is required' },
            r => (r && r.value && r.value.trim().length > 0) || { value: 'Name is required' },
        ],
        defaults: {
            endpoint: 'scoreDetailTypes.php', // scope: score_detail_type_id=209
            rootProperty: 'options',
            params: { property: 'options' }
        },
        read: {
            params: { action: 'get' }
        },
        create: {
            params: { action: 'create' }
        },
        update: {
            params: { action: 'update' }
        },
        destroy: {
            params: { action: 'delete' }
        }
    }
}