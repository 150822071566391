<template>
    <div class="d-flex flex-fill flex-column" style="padding: 0px 16px;">
        <div class="headline mb-1 d-flex align-center">
            Assessment Categories
            <div class="title-tip-adj">
                <fe-info-tip
                    class="ml-2"
                    tooltip="Categories are used to group assessments by skill area."
                />
            </div>
        </div>

        <div class="mb-4 caption" style="color: #C0C3CF; font-size: 14px;">
            Assessment categories are used to group assessments by skill area.  The default list of categories provided by eduCLIMBER have restricted editing properties.  Custom categories created by your district are under your control.
            <br><b>Be cautious of changing category names after data has already been imported.</b>
        </div>

        <fe-dialog
            v-if="addDialog.show"
            title="Category"
            :acceptButtonDisabled="!addDialog.isValid"
            @dismiss="addDialog.show = false"
            @accept="addDialog.record.id ? doUpdate() : doCreate()"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            persistent
            @close="addDialog.show=false"
        >
            <v-form v-model="addDialog.isValid">
                <fe-label>Category Name</fe-label>
                <v-text-field
                    v-model="addDialog.record.name"
                    placeholder="Enter name"
                    solo flat dense
                    :counter="25"
                    v-if="isCustom"
                    :rules="$fieldValidators('text', null, { required: true, limit: 25})"
                />

                <v-select
                    flat solo dense v-else
                    :items="selectItems"
                    v-model="addDialog.record.name"
                    :return-object="false"
                />

                <fe-label>Category Order</fe-label>
                <v-text-field
                    v-model.number="addDialog.record.rank"
                    placeholder="Enter order value"
                    solo flat dense
                    :rules="$fieldValidators('number', null, { required: true, allowZero: true, min: 0, max: 999 })"
                />
            </v-form>
        </fe-dialog>

        <fe-crud
            ref="crud"
            :config="$models.category"
            autoload
            @read="v => items = v"
        />

        <fe-grid
            ref="grid"
            style="height: 100%;"
            :rowData="custom"
            :pinnedTopRowData="restricted"
            :columnDefs="cols"
            displayDensity="medium"
            @beginCreate="addDialog.show=true; addDialog.record={}"
            @cellValueChanged="gridCellUpdate"
            @rowDoubleClicked="edit"
            :disableInlineCreate="true"
        />
    </div>
</template>

<script>
    export default {
        name: 'ManageCategory',
        watch: {
            items: {
                handler(v) {
                    // this.restricted = v.filter(r=>r.id<=8)
                    this.custom = v.filter(r => r.id)
                },
                immediate: true
            }
        },
        computed: {
            isCustom() {
                return (!this.addDialog.record.id || this.addDialog.record.id > 8)
            },
            selectItems() {
                if (!this.addDialog.record.id) return []
                let items = []
                switch (this.addDialog.record.id) {
                    case 1:
                        items = ['Literacy', 'ELA', 'Reading & Writing', 'Reading', 'Lang Arts']
                        break
                    case 2:
                        items = ['Math', 'Mathematics']
                        break
                    case 4:
                        items = ['SEB', 'SEL', 'Social-Emotional/Behavior', 'Behavior']
                        break
                    case 5:
                        items = ['Science']
                        break
                    case 6:
                        items = ['Social Studies', 'SS']
                        break
                    case 7:
                        items = ['Specials', 'Elective', 'Encore', 'Others']
                        break
                    case 8:
                        items = ['Cumulative', 'Overall', 'Combined Performance']
                        break
                }
                return items
            }
        },
        data() {
            let me = this
            return {
                items: [],
                restricted: [],
                restrictedItem: false,
                custom: [],
                addDialog: {
                    show: false,
                    isValid: false,
                    record: {}
                },
                cols: [{
                   headerName: "Name",
                   field: "name",
                   flex: 1,
                   cellRenderer(v) {
                       return (v.data.id<=8?'<i class="fas fa-list mr-2" style="color: #ececec;"></i>':'')+v.value
                   }
                }, {
                    headerName: 'Created',
                    field: 'created',
                    width: 80,
                    valueFormatter: v => this.$dayjs(v.data.created).format('LLL'),
                    cellStyle: {
                        textAlign: 'right'
                    }
                }, {
                    headerName: 'Order',
                    field: 'rank',
                    width: 80,
                }, me.$grid.iconColumn('Delete', 'fas fa-trash', (v) => {
                    me.$confirmDelete([v.data], () => {
                        me.$refs.crud.destroy(v.data)
                            .finally(() => { me.load() })
                    })
                })]
            }
        },
        methods: {
            load() {
                this.$refs.crud.read()
            },
            gridCellUpdate(v) {
                this.$refs.crud.update(this.items).then(r=> {
                    this.$ecResponse(r)
                })
            },
            doCreate() {
                this.$refs.crud.create({
                    name: this.addDialog.record.name,
                    rank: this.addDialog.record.rank,
                })
                    .finally(() => { this.load()})
            },
            doUpdate() {
                this.$refs.crud.update(this.addDialog.record)
                    .finally(() => { this.load()})
            },
            edit(meta) {
                this.addDialog.record={...meta.data}
                this.addDialog.show=true
            }
        },
    }
</script>

<style lang="scss" scoped>
    ::v-deep .nav-item {
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: 43px;
    }
    ::v-deep .nav-item:hover {
        white-space: pre-wrap !important;
        word-break: break-word;
        line-height: 36px !important;
    }

    .title-tip-adj {
        display: inline-block;
        position: relative;
        top: -3px;
    }
</style>
