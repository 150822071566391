<template>
    <div>
        <i :class="params.iconClass"/>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    name: 'IconHeader',
})
</script>

<style lang="scss" scoped>
</style>