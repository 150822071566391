<template>
    <div class="flex-fill flex-column no-scroll" style="height: 400px;">
        <fe-crud-grid
            ref="grid"
            :autoload="true"
            :columnDefs="columnDefs"
            :config="$models.studentDisability"
            :readParams="{ student_id: this.updateStudent.student_id }"
            :rowData="studentDisabilities"
            :showAddRowBtn="this.selections.length > 0 ? false : !this.hasSecurity ? false : true"
            @beginCreate="showAddDialog=true"
            @read="v => studentDisabilities = v"
            @selectionChanged="setSelectedRows"
            addRowBtnText="Add Disability"
            refreshOnUpdated
            refreshOnCreated
            disableInlineCreate
            displayDensity="medium"
            style="height:100%;"
        >
        </fe-crud-grid>

        <add-disability v-if="showAddDialog" @close="showAddDialog=false" :crud="$refs.grid.$refs.crud" :studentInfo="this.updateStudent" :schoolYearList="this.schoolYears" :disabilitiesLength="this.disabilitiesLength" />
    </div>
</template>

<script>
    import Vuex, { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'
    import AddDisability from './AddDisability'

    export default {
        name: 'StudentDisabilities',

        components: {
            AddDisability
        },

        data() {
            return {
                showAddDialog: false,
                studentDisabilities: [],
                disabilitiesLength: null,
                gridApi: null
            }
        },

        inject: ['localStore'],

        computed: {
            ...mapLocalState(['updateStudent','selections','schoolYears','disabilityTypes','hasSecurity']),

            valuesLoaded() {
                return !!this.schoolYears && !!this.updateStudent
            },

            columnDefs() {
                let me = this

                return !this.valuesLoaded ? [] : [
                    {
                        headerName: null,
                        colId: "id",
                        headerCheckboxSelection: this.hasSecurity,
                        checkboxSelection: this.hasSecurity,
                        width: 70,
                        minWidth: 70,
                        hide: !this.hasSecurity
                    }, {
                        headerName: "School Year",
                        field: "school_year_id",
                        colId: "school_year_id",
                        sortable: true,
                        editable: this.hasSecurity,
                        valueGetter: v => me.findLabel(me.schoolYears, v.data.school_year_id, 'id', 'name'),
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.school_year_id,
                                rowDataKey: "school_year_id",
                                mode: "edit",
                                items: me.schoolYears,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true
                            }
                        }
                    }, {
                        headerName: "Disability",
                        field: "student_disability_type_id",
                        colId: "student_disability_type_id",
                        sortable: true,
                        editable: this.hasSecurity,
                        valueGetter: v => me.findLabel(me.disabilityTypes, v.data.student_disability_type_id, 'id', 'description'),
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.student_disability_type_id,
                                rowDataKey: "student_disability_type_id",
                                mode: "edit",
                                items: me.formatDisabilityTypes,
                                keyProp: "id",
                                labelProp: "display_name",
                                disableChips: true
                            }
                        }
                    }, {
                        headerName: "Inactive Dt",
                        field: "inactive_date",
                        colId: "inactive_date",
                        sortable: true,
                        editable: this.hasSecurity
                    }, {
                        headerName: 'Upload ID',
                        field: 'upload_id',
                        hide: true,
                        editable: false
                    }
                ]
            }
        },

        watch: {
            studentDisabilities: {
                handler(v) {
                    if (v.length > 0) {
                        this.disabilitiesLength = v.length + 1
                    }
                }
            },
            immediate: true
        },

        methods: {
            findLabel(collection, value, valueProp, labelProp) {
                let lbl = ''
                if (collection && value) {
                    let sel = collection.find(x => x[valueProp] === value)
                    if (sel) {
                        if (sel['display_name']) labelProp = 'display_name'
                        lbl = sel[labelProp]
                    }
                }
                return lbl
            },

            setSelectedRows(meta) {
                this.$data.gridApi = meta.api
                this.selections = meta.api.getSelectedRows()
            },

            formatDisabilityTypes() {
                let types = this.$_.cloneDeep(this.disabilityTypes)
                types.forEach(t => {
                    if (!t.display_name_group) {
                        t.display_name = t.display_name || t.description
                    } else {
                        t.display_name = t.description + (t.display_name ? " (" + t.display_name + ")" : "")
                    }
                })
                return types
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
