<template>
    <v-layout column>
        <div class="headline mb-1 d-flex">
            Terms
            <fe-info-tip
                class="ml-2"
                tooltip="Term is only used when a term name is not provided by your SIS and allows the district to define their terms by date for each school. Term dates cannot overlap."
            />
        </div>

        <fe-crud-grid
            ref="grid"
            :config="crudConfig"
            :columnDefs="columnDefs"
            displayDensity="medium"
            autoload
            refreshOnCreated
            disableInlineCreate
            @read="termRecords = $event"
            @beginCreate="beginEntry"
        >
            <template slot="toolbar-items">
                <fe-btn
                    usage="secondary"
                    @click="beginManageTerms"
                    class="fe-grid-action-btn"
                >
                    Manage Term Names
                </fe-btn>
            </template>
        </fe-crud-grid>

        <fe-dialog
            v-if="createDialog.show"
            ref="createDialog"
            title="Create Attendance Term"
            :footer="false"
            persistent
            disableAutoclose
            width="550px"
            @close="dismissDialog"
        >
            <model-form
                idProperty="id"
                ref="termForm"
                :records="createDialog.records"
                :fieldConfig="fieldConfigs"
                submitButtonText="Save and Complete"
                secondaryButtonText="Save and Create New Term"
                model="attendanceTerms"
                savePrevent
                @cancel="dismissDialog"
                @done="created"
                @save="doSave"
                @action="saveNew=true"
            />
        </fe-dialog>

        <fe-dialog
            v-if="manageTerms"
            title="Manage Term Names"
            :footer="false"
            height="350"
            persistent
            @dismiss="manageTerms = false"
        >
            <fe-grid
                ref="termsGrid"
                :rowData="terms"
                :columnDefs="termsColumnDefs"
                :showToolbar="false"
                :insertable="false"
                style="height: 78%"
                simpleInsert="name"
                simpleInsertPlaceholderText="Term Name"
                displayDensity="medium"
                @simpleInsertChanged="newTermName = $event"
                @simpleInsert="createTermName"
            />
        </fe-dialog>

        <fe-crud
            ref="termsCrud"
            :config="termCrudConfig"
            autoload
            @read="terms = $event"
        />
    </v-layout>
</template>

<script>
    import ModelForm from '@/components/common/form/ModelForm'

    export default {
        name: "AttendanceTerms",

        components: {ModelForm},

        data() {
            return {
                manageTerms: false,
                createDialog: {show: false, records: []},
                newTermName: undefined,
                years: null,
                schools: null,
                terms: null,
                termRecords: [],
                requiredRule: [v => !_.isEmpty(v) || 'This field is required'],
                saveNew: false,
            }
        },

        computed: {
            crudConfig() {
                return _.cloneDeep(this.$models.attendanceTerms)
            },

            termCrudConfig() {
                return _.cloneDeep(this.$models.courseTerms)
            },

            schoolParams() {
                let output = {}

                if (this.newEntry.school_year_id) {
                    output.school_year_id = this.newEntry.school_year_id
                }

                return output
            },

            valuesLoaded() {
                return !!this.$parent.years && !!this.$parent.schools && !!this.terms
            },

            columnDefs() {
                let me = this

                return !this.valuesLoaded ? [] : [
                    {
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        maxWidth: 70,
                        colId: 'checkbox_column'
                    },
                    {
                        headerName: 'Term Name',
                        field: 'course_term_id',
                        valueGetter: v => me.findLabel(me.terms, v.data.course_term_id, 'id', 'name'),
                        editable: true,
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.course_term_id,
                                rowDataKey: "course_term_id",
                                mode: "edit",
                                items: me.terms,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true,
                                elProps: {
                                    clearable: false
                                },
                            }
                        },
                        maxWidth: 150
                    },
                    {
                        headerName: 'School',
                        field: 'school_id',
                        valueGetter: v => me.findLabel(me.$parent.schools, v.data.school_id, 'id', 'name'),
                        editable: true,
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.school_id,
                                rowDataKey: "school_id",
                                mode: "edit",
                                items: me.$parent.schools,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true,
                                elProps: {
                                    clearable: false
                                },
                            }
                        }
                    },
                    {
                        headerName: 'School Year',
                        field: 'school_year_id',
                        valueGetter: v => me.findLabel(me.$parent.years, v.data.school_year_id, 'id', 'name'),
                        editable: true,
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.school_year_id,
                                rowDataKey: "school_year_id",
                                mode: "edit",
                                items: me.$parent.years,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true,
                                elProps: {
                                    clearable: false
                                },
                            }
                        },
                        maxWidth: 150
                    },
                    {
                        headerName: 'Start Date',
                        field: 'start_date',
                        cellEditorFramework: 'FeGridDatePicker',
                        editable: true,
                        maxWidth: 150
                    },
                    {
                        headerName: 'End Date',
                        field: 'end_date',
                        cellEditorFramework: 'FeGridDatePicker',
                        editable: true,
                        maxWidth: 150
                    }
                ]
            },

            termsColumnDefs() {
                return [{
                    headerName: 'Name',
                    field: 'name',
                    editable: true,
                    cellEditorFramework: "FeGridTextField",
                    onCellValueChanged: v => {
                        if (v.newValue) {
                            this.$refs.termsCrud.update(v.data).then(r => {
                                this.$refs.termsCrud.read()
                            })
                        }
                    }
                },
                    this.$grid.deleteColumn(meta => {
                        this.$confirmDelete(meta.data, () => {
                            if (this.termRecords.filter(x => x.course_term_id === meta.data.id).length) {
                                this.$snackbars.$emit('new', {
                                    text: 'Cannot delete term names that have associated records.',
                                    usage: 'error'
                                })
                            } else {
                                let termsCrud = this.$refs.termsCrud
                                termsCrud.destroy(meta.data)
                                    .then(() => {
                                        termsCrud.read()
                                    })
                            }
                        })
                    })
                ]
            },

            fieldConfigs() {
                let me = this
                return [
                    {
                        field: 'school_year_id',
                        label: 'School Year',
                        component: 'fe-remote-combo',
                        validatorKey: 'select',
                        validatorConfig: {required: true},
                        width: '100%',
                        attrs: {
                            validateOnBlur: true,
                            byId: true,
                            items: me.$parent.years,
                            itemText: 'name',
                            itemValue: 'id'
                        }
                    }, {
                        field: 'school_id',
                        label: 'School',
                        component: 'fe-remote-combo',
                        validatorKey: 'select',
                        validatorConfig: {required: true},
                        width: '100%',
                        attrs: {
                            validateOnBlur: true,
                            byId: true,
                            items: me.$parent.schools,
                            itemText: 'name',
                            itemValue: 'id',
                            multiple: true
                        }
                    }, {
                        field: 'course_term_id',
                        label: 'Term',
                        component: 'fe-remote-combo',
                        validatorKey: 'select',
                        validatorConfig: {required: true},
                        width: '100%',
                        attrs: {
                            validateOnBlur: true,
                            byId: true,
                            items: me.terms,
                            itemText: 'name',
                            itemValue: 'id'
                        }
                    }, {
                        field: 'start_date',
                        label: 'Start Date',
                        component: 'fe-date-picker',
                        width: '100%',
                        validatorKey: 'text',
                        validatorConfig: {required: true},
                        attrs: {
                            validateOnBlur: true,
                            flat: true,
                            solo: true,
                            dense: true
                        }
                    }, {
                        field: 'end_date',
                        label: 'End Date',
                        component: 'fe-date-picker',
                        width: '100%',
                        validatorKey: 'text',
                        validatorConfig: {required: true},
                        attrs: {
                            validateOnBlur: true,
                            flat: true,
                            solo: true,
                            dense: true
                        }
                    }
                ]
            }
        },

        created() {
            this.$parent.$once('show terms', () => {
                this.$refs.grid.$refs.grid.resize()
            })
        },

        methods: {
            load() {
                this.$refs.grid.$refs.crud.read()
            },

            reload(crud) {
                this.$nextTick(() => {
                    this.$refs[crud].read()
                })
            },

            beginEntry() {
                this.resetEntry()
                this.createDialog.show = true
            },

            beginManageTerms() {
                this.manageTerms = true
            },

            dismissDialog() {
                this.createDialog.show = false
            },

            resetEntry() {
                this.createDialog.records = [
                    {
                        school_year_id: undefined,
                        school_id: undefined,
                        course_term_id: undefined,
                        start_date: undefined,
                        end_date: undefined
                    }
                ]
            },

            created() {
                this.load()
                this.dismissDialog()
            },

            createTermName() {
                if (this.newTermName) {
                    let termsCrud = this.$refs.termsCrud
                    let payload = {
                        name: this.newTermName
                    }
                    termsCrud.create(payload)
                        .then(() => {
                            termsCrud.read()
                        })
                }
            },

            findLabel(collection, value, valueProp, labelProp) {
                let lbl = ''
                if (collection && value) {
                    let sel = collection.find(x => x[valueProp] === value)
                    if (sel) lbl = sel[labelProp]
                }
                return lbl
            },

            doSave(records) {
                let terms = []
                records.forEach(record => {
                    record.school_id.forEach(school => {
                        terms.push({
                            course_term_id: record.course_term_id,
                            start_date: record.start_date,
                            end_date: record.end_date,
                            school_year_id: record.school_year_id,
                            school_id: school
                        })
                    })
                })
                this.$refs.grid.$refs.crud.create(terms)
                    .then(() => {
                        this.$refs.grid.$refs.crud.read()
                    })
                    .finally(() => {
                        if (!this.saveNew) {
                            this.dismissDialog()
                        } else {
                            this.saveNew = false
                            this.dismissDialog()
                            this.resetEntry()
                            this.$nextTick(() => {
                                this.createDialog.show = true
                            })
                        }
                    })
            },
        }
    }
</script>

<style scoped></style>
