<template>
    <div class="d-flex row flex-nowrap" :class="{ headers: headers }" v-if="visibility">
        <div class="flex-grow-1 flex-shrink-1 cell cell-0">{{ assignee }}</div>
        <div class="flex-grow-0 flex-shrink-0 cell cell-1">{{ date }}</div>
        <div class="flex-grow-0 flex-shrink-0 cell cell-2">{{ time }}</div>
        <div class="flex-grow-0 flex-shrink-0 cell cell-3">
            <span v-if="headers">Status</span>
            <status-button
                v-else
                :item="item"
                @setStatus="$emit('setStatus', $event)"
            />
        </div>
        <div v-if="menuEnabled" class="flex-grow-0 flex-shrink-0 cell cell-4">
            <span v-if="headers">&nbsp;</span>
            <v-menu offset-y left v-else>
                <template v-slot:activator="{on}">
                    <v-btn icon x-small v-on="on"><v-icon>more_vert</v-icon></v-btn>
                </template>
                <v-list>
                    <v-list-item @click="$emit('deleteItem')">Edit</v-list-item>
                    <v-list-item @click="$emit('editItem')">Delete</v-list-item>
                </v-list>
            </v-menu>&nbsp;
        </div>
    </div>
</template>

<script>
    import StatusButton from './StrategiesStatusButton'

    export default {
        name: 'SloPanelStrategiesCardRow',
        props: {
            item: { type: Object, default: () => {
                return {}
            }},
            headers: { type: Boolean, default: false },
            visibility: { type: Boolean, default: true },
        },
        components: {
            StatusButton
        },
        data() {
            return {
                menuEnabled: false
            }
        },
        computed: {
            assignee () { return this.headers ? 'Assignee' : this.item.user_display_name },
            date () { return this.headers ? 'Date' : this.formattedDate },
            time () { return this.headers ? 'Time' : this.formattedTime },
            formattedDate () { return this.$dayjs(this.item.schedule_date_occurrence).format('MMM DD') },
            formattedTime () {
                return this.formattedEndTime
                    ? `${this.formattedStartTime} - ${this.formattedEndTime}`
                    : `${this.formattedStartTime}`
            },
            formattedStartTime () { return this.$dayjs(`${this.item.schedule_date_occurrence} ${this.item.schedule_date_occurrence_start_time}`).format('h:mma') },
            formattedEndTime () {
                return (this.item.schedule_date_occurrence_end_time)
                    ? this.$dayjs(`${this.item.schedule_date_occurrence} ${this.item.schedule_date_occurrence_end_time}`).format('h:mma')
                    : this.$dayjs(`${this.item.schedule_date_occurrence} ${this.item.schedule_date_occurrence_start_time}`).format('h:mma')
            }
        },
        methods: { },
        watch: { }
    }
</script>

<style lang="scss" scoped>
    .row {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: visible;
        line-height: 30px;
        border-top: solid 1px #e5e5e5;
        &.headers {
            font-weight: bold;
            .cell {
                padding-left: 0;
                &-0 { padding-left: 16px; }
            }
        }
        .cell {
            padding: 5px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            &-0 {
                padding-left: 16px;
                min-width: 50px;
            }
            &-1 { width: 100px;}
            &-2 { width: 150px;}
            &-3 { width: 120px;}
            &-4 {
                width: 70px;
                text-align: right;
                padding-right: 16px;
            }
        }
    }
</style>
