import Vue from 'vue'

export const incidentEntry = {
    namespaced: true,
    state: {
        preferences: {},
        appParams: {},
        psportalEnabled: false,
        users: [],
        activeUser: undefined,
        securityCodes: [],
        userPreferences: [],
        schoolYears: []
    },

    getters: {
        getUsers: state => state.users,
        activeUser: state => state.activeUser,
        securityCodes: state => state.securityCodes,
        hasSecurity: state => x => state.securityCodes.indexOf(x) > -1,
        getUserPreferences: state => state.userPreferences,
        preferences: state => state.preferences,
        appParams: state => state.appParams,
        psportalEnabled: state => state.psportalEnabled,
        schoolYears: state => state.schoolYears,
    },

    mutations: {
        SET_PREFERENCES: (state, {data}) => {
            data.forEach(p => state.preferences[p.code] = p.user_value)
        },
        SET_PARAMS: (state, {data}) => {
            state.appParams = data
        },
        SET_PSPORTAL_ENABLED: (state, value) => {
            state.psportalEnabled = !!value
        },
        SET_SCHOOL_YEARS: (state, { list }) => {
            state.schoolYears = list
        },
        ACTIVE_CONFIG: (state, { active }) => {
            state.activeConfig = active;
        }
    },

    actions: {
        FETCH: function({commit}, params) {
            let appParams = location.href.split('?')
            if(appParams[1]) {
                appParams = appParams[1].split('&')
                let data = {}
                for(let x in appParams) {
                    data[appParams[x].split('=')[0]] = appParams[x].split('=')[1]
                }
                commit('SET_PARAMS',{ data: data })
            }

            let url = 'districts.php?action=get'
            axios.get(url).then(response => {
                commit('SET_PSPORTAL_ENABLED', response.data.districts[0].psportal_enabled)
            }).catch(response => {
                console.warn('FETCH district properties failed: ', response.statusText)
            })

            url = 'preferences.php?action=get&property=district'
            return new Promise( (resolve, reject) => {
                axios.get(url).then( response => {
                    commit('SET_PREFERENCES',{ data: response.data.preferences })
                    resolve()
                }).catch( response => {
                    console.warn('FETCH failed: ',response.statusText);
                    reject()
                })
            })
        },
        FETCH_USERS: function ({ commit }, params) {
            let url = 'users.php?action=get'
            let p   = this._vm.$helpers.objectToParams(params)
            url     = url + (p ? '&' + p: '')

            return new Promise((resolve, reject) => {
                axios.get(url).then((response) => {
                    commit('SET_USERS', { list: response.data })
                    resolve(response.data)
                    // return response.kpi_dashboard_user
                }).catch((response) => {
                    reject()
                })
            })
        },
        FETCH_SCHOOL_YEARS: function ({ commit }, params) {
            let url = 'qry.php?property=school_years'

            return new Promise((resolve, reject) => {
                axios.get(url).then((response) => {
                    commit('SET_SCHOOL_YEARS', { list: response.data.years })
                    resolve()
                }).catch((response) => {
                    console.warn('FETCH FAILED',response.statusText);
                    reject()
                })
            })
        }
    }
}
