<template>
    <v-layout column>
        <fe-mask v-show="rebuildLoading" showLoader/>

        <div class="headline mb-1 d-flex">
            School Days Off
            <fe-info-tip
                class="ml-2"
                tooltip="School Days Off"
            />
        </div>

        <fe-crud-grid
            ref="grid"
            :config="mainCrudConfig"
            :columnDefs="mainColumnDefs"
            :autoload="false"
            :showDownload="false"
            :showToolbarMenu="false"
            :searchBar="false"
            displayDensity="medium"
            identifier="exclude_date"
            addRowBtnText="Add"
            disableInlineCreate
            persistCellDialog
            refreshOnCreated
            :readParams="filterIds"
            @beginCreate="beginEntry"
        >
            <template slot="left-toolbar-items">
                <fe-filter-btn
                    ref="filterYear"
                    title="Year"
                    v-model="yearFilter"
                    :multiple="false"
                    :items="years"
                    itemValue="id"
                    itemText="name"
                    closeOnSelect
                    @input="load"
                />
            </template>

            <template slot="toolbar-items">
                <fe-btn
                    useIcon="fal fa-calendar-alt"
                    usage="secondary"
                    @click="rebuildPrompt = true"
                    class="fe-grid-action-btn"
                >
                    Rebuild Schedules
                </fe-btn>
            </template>

            <template v-slot:cellDialog="{cell}">
                <simple-grid-dialog-column
                    ref="dialog"
                    :cell="cell"
                    title="Schools"
                    :crudConfig="secondaryCrudConfig"
                    :columnDefs="schoolsColumnDefs"
                    :onRead="v => v.filter(x => x.exclude_date === cell.data.exclude_date)"
                    :destroyParams="{
                        id: cell.data.id
                    }"
                    :insert-defaults="{
                        exclude_date: cell.data.exclude_date
                    }"
                    :buildInsertRec="buildSchoolRec"
                    @destroyed="load"
                    @created="postSchoolAdd"
                    @close="closeDialogColumn"
                >
                    <template slot="belowGrid">
                        <div class="d-flex" v-if="cell">
                            <fe-remote-combo
                                ref="addSchoolField"
                                style="margin-top:16px; width:100%;"
                                itemText="name"
                                itemValue="id"
                                :items="availableSchools"
                                placeholder="Add a School"
                                multiple
                                byId
                                hide-details
                                @input="schoolSelection"
                            />
                            <fe-btn
                                style="margin-top: 10px;"
                                :disabled="disableSchoolAdd"
                                @click="schoolAdd"
                            >
                                Add
                            </fe-btn>
                        </div>
                    </template>
                </simple-grid-dialog-column>
            </template>
        </fe-crud-grid>

        <fe-dialog
            v-if="inserting"
            title="Create Days Off"
            :footer="false"
            persistent
            disableAutoclose
            @close="dismissDialog"
            @accept="createNew"
        >
            <v-layout column>
                <v-flex xs12>
                    <v-form ref="form" @submit.prevent v-model="formValid">
                        <div class="d-flex">
                            <div class="pr-2">
                                <fe-label>Start Date</fe-label>
                                <fe-date-picker
                                    v-model="newEntry.start_date"
                                    :rules="$fieldValidators('text', 'Start date', { required: true })"
                                    prependIcon="fa-calendar-alt"
                                    dateFormat="MM/DD/YYYY"
                                />
                            </div>

                            <div>
                                <fe-label>End Date</fe-label>
                                <fe-date-picker
                                    ref="endDate"
                                    v-model="newEntry.end_date"
                                    :rules="rules.endDate"
                                    prependIcon="fa-calendar-alt"
                                    dateFormat="MM/DD/YYYY"
                                />
                            </div>
                        </div>

                        <fe-remote-combo
                            ref="schoolSearchField"
                            label="Select School(s)"
                            placeholder="Search Schools by Name"
                            class="chips-below flex-grow-1"
                            :class="{invalid: newEntry.invalid.schools}"
                            itemText="name"
                            itemValue="id"
                            :items="availableSchools"
                            validateOnBlur
                            @input="selectSchool"
                        />
                        <div v-if="newEntry.schools.length" class="chip-wrapper d-flex">
                            <v-flex xs11>
                                <v-chip
                                    v-for="school in newEntry.schools"
                                    class="chip"
                                    close
                                    @click:close="deselect(school)"
                                >
                                    {{ school.name }}
                                </v-chip>
                            </v-flex>

                            <v-flex xs1>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="clearAllSchools">
                                            <v-icon color="#0000008a" small>fal fa-times</v-icon>
                                        </v-btn>
                                    </template>

                                    <span>Clear All</span>
                                </v-tooltip>
                            </v-flex>
                        </div>
                        <div v-else-if="newEntry.invalid.schools" style="margin-top: -10px;">
                            <span class="error-msg">This field is required</span>
                        </div>
                    </v-form>

                    <div class="d-flex">
                        <div>
                            <v-progress-circular v-if="createLoading" color="primary" indeterminate/>
                        </div>
                        <fe-btn usage="ghost" class="ml-auto" @click="dismissDialog">Cancel</fe-btn>
                        <fe-btn usage="primary" @click="createNew" :disabled="!valid">Save</fe-btn>
                    </div>
                </v-flex>
            </v-layout>
        </fe-dialog>

        <fe-dialog
            v-if="rebuildPrompt"
            title="Rebuild All Schedules"
            acceptButtonText="Rebuild"
            dismissButtonText="Cancel"
            persistent
            dismissable
            disableAutoClose
            @close="rebuildPrompt = false"
            @accept="rebuild(rebuildDate)"
        >
            <p>This will attempt to sync all intervention and progress monitoring schedules with any school calendar
                changes made since the date specified. If a scheduled date has data entered for it, this will skip
                syncing the schedule and dates will have to be modified manually for the specific intervention or probe.<br>
                <i>This change is permanent.</i></p>
            <fe-date-picker v-model="rebuildDate"></fe-date-picker>
        </fe-dialog>

        <fe-crud ref="yearsCrud" :config="$models.schoolYear" autoload @read="years = $event"/>
        <fe-crud :config="schoolsCrudConfig" autoload @read="schools = $event"/>
    </v-layout>
</template>

<script>
    import {mapState} from "vuex"
    import SimpleGridDialogColumn from "../../../common/SimpleGridDialogColumn"

    export default {
        name: "SchoolDaysOff",

        components: {SimpleGridDialogColumn},

        data() {
            return {
                schools: [],
                years: [],
                inserting: false,
                rebuildPrompt: false,
                rebuildLoading: false,
                createLoading: false,
                rebuildDate: new Date().toISOString().split("T")[0],
                formValid: false,
                newEntry: {
                    start_date: undefined,
                    end_date: undefined,
                    schools: [],
                    invalid: {
                        schools: false
                    }
                },
                cell: undefined,
                disableSchoolAdd: true,
                filters: {
                    school_year: {}
                },
                rules: {
                    endDate: [
                        v => !this.newEntry.start_date || !this.$dayjs(v).isBefore(this.newEntry.start_date) || 'Not a valid End Date'
                    ].concat(this.$fieldValidators('text', 'End date', { required: true }))
                }
            }
        },

        computed: {
            ...mapState('global', ['currentYear']),

            mainCrudConfig() {
                let cfg = _.cloneDeep(this.$models.schoolDaysOff)
                cfg.read.params.property = 'dates'
                cfg.destroy.params.property = 'dates'
                return cfg
            },

            secondaryCrudConfig() {
                return _.cloneDeep(this.$models.schoolDaysOff)
            },

            schoolsCrudConfig() {
                return _.cloneDeep(this.$models.school)
            },

            mainColumnDefs() {
                return [
                    {
                        colId: 'checkbox_column',
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        maxWidth: 70
                    },
                    {
                        colId: 'exclude_date',
                        headerName: 'Date',
                        field: 'exclude_date',
                        editable: true
                    },
                    {
                        colId: 'schools',
                        headerName: 'Schools',
                        field: 'schools',
                        maxWidth: 100,
                        editable: false,
                        valueGetter: params => {
                            return params.data.schools ? params.data.schools.length : 0
                        },
                        cellRendererFramework: "FeDialogColumn",
                        onCellClicked: v => {
                            this.cellClicked(v)
                        }
                    }
                ]
            },

            schoolsColumnDefs() {
                let me = this
                return [{
                    headerName: 'School',
                    field: 'id',
                    cellRenderer(v) {
                        return me.findLabel(me.schools, v.data.school_id, 'id', 'name')
                    },
                }]
            },

            yearFilter: {
                set(v) {
                    this.filters.school_year = v
                    this.load()
                },
                get() {
                    return this.filters.school_year
                }
            },

            filterIds() {
                let filters = this.filters
                let output = {}

                if (filters.school_year.included && filters.school_year.included.length) {
                    output.school_year_id = filters.school_year.included[0].id
                }

                return output
            },

            availableSchools() {
                let existing = this.cell ? this.cell.data.schools : this.newEntry.schools
                this.schools.unshift({id: -1, name: 'All Schools'})
                return this.schools.filter(x => !existing.includes(x.id))
            },

            valid() {
                let newEntry = this.newEntry
                if (!newEntry.schools.length || !newEntry.start_date || !newEntry.end_date) return false
                return !this.$dayjs(newEntry.end_date).isBefore(newEntry.start_date)
            }
        },

        watch: {
            'newEntry.start_date': {
                handler(v) {
                    if (v) {
                        this.$refs.endDate.$refs.field.validate()
                    }
                }
            }
        },

        created() {
            this.filters.school_year.included = [this.currentYear]

            this.$parent.$once('show schoolDaysOff', () => {
                this.$refs.grid.$refs.grid.resize()
                this.load()
            })
        },

        methods: {
            load() {
                let filterIds = this.filterIds
                if (filterIds.school_year_id) {
                    return this.$refs.grid.$refs.crud.read(filterIds)
                }
            },

            beginEntry() {
                this.resetEntry()
                this.inserting = true
            },

            selectSchool(v) {
                if (v) {
                    if (v.id === -1) {
                        this.schools.forEach(school => {
                            if (school.id !== -1 && !_.find(this.newEntry.schools, school)) this.newEntry.schools.push(school)
                        })
                    }
                    else if (!_.find(this.newEntry.schools, v)) this.newEntry.schools.push(v)

                    if (this.newEntry.schools.length > 1) this.newEntry.schools = _.orderBy(this.newEntry.schools, ['name'],['asc'])

                    this.newEntry.invalid.schools = false
                    this.$nextTick(this.$refs.schoolSearchField.updateValue)
                }
            },

            deselect(item) {
                this.newEntry.schools = this.$_.differenceBy(this.newEntry.schools, [item], 'id')
            },

            clearAllSchools() {
                this.newEntry.schools = []
                this.newEntry.invalid.schools = true
            },

            dismissDialog() {
                this.inserting = false
            },

            resetEntry() {
                this.formValid = false
                this.newEntry = {
                    start_date: undefined,
                    end_date: undefined,
                    schools: [],
                    invalid: {
                        schools: false
                    }
                }
            },

            createNew() {
                let schools = this.newEntry.schools

                let start = this.$dayjs(this.newEntry.start_date).format('YYYY-MM-DD')
                let end = this.$dayjs(this.newEntry.end_date).format('YYYY-MM-DD')

                this.createLoading = true
                let date = start
                let payload = []

                do {
                    schools.forEach(x => {
                        payload.push({
                            exclude_date: date,
                            school_id: x.id
                        })
                    })

                    date = this.$dayjs(date).add(1, 'days').format('YYYY-MM-DD')
                }
                while (date <= end)

                if (payload.length) {
                    this.$refs.grid.$refs.crud
                        .create(payload)
                        .then(() => {
                            this.createLoading = false
                            this.dismissDialog()
                        })
                }
            },

            cellClicked(cell) {
                this.cell = cell
                this.$refs.grid.$refs.grid.setDialogCell(cell)
            },

            closeDialogColumn() {
                this.cell = undefined
                this.$refs.grid.$refs.grid.openMenu = false
            },

            schoolSelection(v) {
                if (v) {
                    this.$refs.dialog.insertValue = v
                    this.disableSchoolAdd = false
                } else {
                    this.$refs.dialog.insertValue = undefined
                    this.disableSchoolAdd = true
                }
            },

            buildSchoolRec(v, data) {
                return v.map(x => {
                    let output = _.clone(data)
                    output.school_id = x
                    return output
                })
            },

            schoolAdd() {
                this.$refs.dialog.createItem()
                this.$refs.addSchoolField.updateValue(false)
            },

            postSchoolAdd(rec) {
                let newSchools = rec.map(x => x.school_id)
                this.cell.data.schools.push(...newSchools)
                this.load()
            },

            rebuild(date) {
                if (date) {
                    this.rebuildLoading = true
                    this.$axios
                        .post(
                            'interventionPlan.php?action=rebuild_all_schedules',
                            JSON.stringify({from_date: date})
                        )
                        .then(res => {
                            this.$snackbars.$emit('new', { text: res.data.msg, usage: 'success', timeout: 8000})
                            this.rebuildLoading = false
                        })
                }
            },

            findLabel(collection, value, valueProp, labelProp, countLabel) {
                let lbl = ''
                if (collection && value) {
                    if (Array.isArray(value)) {
                        return `${value.length} ${countLabel}`
                    } else {
                        let sel = collection.find(x => x[valueProp] === value)
                        if (sel) lbl = sel[labelProp]
                    }
                }
                return lbl
            }
        }
    }
</script>

<style lang="scss" scoped>
    .chip-wrapper {
        margin-top: -20px;
    }

    .chips-below::v-deep {
        margin-bottom: 25px;

        .v-text-field__details {
            display: none;
        }

        &.invalid {
            margin-bottom: 5px;

            .v-input__slot {
                .v-icon {
                    color: var(--v-error-base) !important;
                    caret-color: var(--v-error-base) !important;
                }

                border-color: #ff5252;
            }
        }
    }

    .chip::v-deep {
        .v-chip__content {
            max-width: unset;
        }

        &.avatar {
            .v-chip__content {
                padding-left: 0;
            }
        }
    }

    .error-msg {
        font-size: 12px;
        font-weight: 300;
        color: #f02d1f;
        margin-left: 15px;
    }
</style>
