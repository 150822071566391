import BaseService from '@/components/modules/thresholds/services/BaseService'
import BaseStore from '../Base'

export default Object.assign({}, BaseStore.default(), {
    namespaced: true,

    mutations: Object.assign({}, BaseStore.default().mutations, {
        set(state, params) {
            state.collection[params.idx] = params.obj
        }
    }),

    actions: BaseStore.default().actions,

    getters: Object.assign({}, BaseStore.default().getters, {
        __config(state, getters, rootState, rootGetters) {
            let prop = 'threshold_subgroup'
            return {
                service: BaseService,
                opts: {type: 'thresholdGroupProps'},
                params: {property: prop, threshold_group_id: rootGetters['thresholds/group/selectedId']},
                prop: prop
            }
        }
    })
})
