<template>
    <div>
        <fe-grid
            ref="grid"
            :rowData="items"
            :columnDefs="columnDefs"
            @selectionChanged="setSelectedRows"
            flexColumns
            :showToolbarMenu="false"
            :showDownload="false"
            :showAddRowBtn="false"
            :searchBar="false"
            showScrollbars
            @deleted="$emit('show-selection-tools')"
            @gridReady="resize"
            disableInlineCreate
            suppressContextMenu
            :style="actionPlan ? { height: 'auto' } : { height: 'calc(60vh)' }"
            :domLayout="actionPlan ? 'autoHeight' : 'normal'"
            :gridOptionOverrides="gridOptions"
            :bordered="false"
            class="action-items-grid"
            displayDensity="large"
        >
            <template v-slot:selection-tools>
                <template v-if="selectedRows.length > 0">
                    <fe-btn
                        usage="ghost"
                        dark
                        useIcon="far fa-trash"
                        @click="deleteActionItems"
                    >
                        Delete
                    </fe-btn>
                    <fe-btn
                        usage="ghost"
                        dark
                        useIcon="far fa-check-double"
                        @click="showMassStatusModal = true"
                    >
                        Status
                    </fe-btn>
                    <fe-btn
                        v-if="selectedRows.length == 1"
                        usage="ghost"
                        dark
                        useIcon="far fa-pencil"
                        @click="editActionItem"
                    >
                        Edit
                    </fe-btn>
                    <fe-btn usage="ghost" dark @click="cancel">Cancel</fe-btn>
                </template>
            </template>
        </fe-grid>
        <v-menu
            v-model="showStatusMenu"
            :position-x="statusMenuPosition.x"
            :position-y="statusMenuPosition.y"
            absolute
        >
            <v-list v-if="selected && selected.colDef.headerName == 'Status'">
                <v-list-item
                    v-for="(status, index) in availableStatus"
                    :key="index"
                    @click="statusChanged(status)"
                >
                    <v-list-item-title v-html="renderStatus(status)"/>
                </v-list-item>
            </v-list>
            <v-date-picker v-else-if="selected && selected.colDef.headerName == 'Date Due'"
                no-title
                @change="dateChanged"
                :value="selected.data.due_dt"
            />
        </v-menu>
        <action-item-comments
            :comments="comments"
            @submit-note="submitNote"
            @delete-note="deleteNote"
        />
        <fe-dialog
            v-if="showMassStatusModal"
            title="Status"
            acceptButtonText="Done"
            dismissButtonText="Cancel"
            @dismiss="showMassStatusModal=false"
            @close="showMassStatusModal=false"
            @accept="massStatusChange"
        >
            <v-autocomplete
                :items="statusOptions"
                itemText="name"
                itemValue="id"
                flat solo
                v-model="massStatus"
            >
                <template v-slot:item="data">
                    <v-chip :color="data.item.color" class="action-item-mass-status-chip">{{data.item.name}}</v-chip>
                </template>
                <template v-slot:selection="data">
                    <v-chip :color="data.item.color" class="action-item-mass-status-chip">{{data.item.name}}</v-chip>
                </template>
            </v-autocomplete>
            This will change the status of {{ selectedRows.length }} Action Items.
        </fe-dialog>

        <fe-crud ref="userCrud" :config="userCrudConfig" autoload @read="users = $event"/>
    </div>
</template>

<script>
    import AssigneeColumn from './renderers/AssigneeColumn'
    import ActionItemComments from './Comments'
    import NameColumn from './renderers/NameColumn'
    import DueTimeColumn from './renderers/DueTimeColumn'
    import {mapState} from "vuex"

    export default {
        name: 'ActionItemsListView',

        components: {ActionItemComments},

        props: {
            items: {type: Array, default: () => ([])},
            actionPlan: {type: Boolean, default: false},
            unselectRows: {type: Boolean, default: false},
        },

        data() {
            let me = this
            return {
                columnDefs: [
                    {
                        field: 'selectionBox',
                        headerName: '',
                        pinned: 'left',
                        minWidth: 70,
                        width: 70,
                        checkboxSelection: true,
                        headerCheckboxSelection: true,
                        suppressSizeToFit: true
                    },
                    {
                        headerName: 'Action Item Name',
                        field: 'name',
                        sortable: true,
                        editable: false,
                        cellRendererFramework: NameColumn,
                        cellStyle: {
                            whiteSpace: 'normal',
                            padding: '10px'
                        },
                        minWidth: 300
                    },
                    {
                        headerName: 'Comments',
                        field: 'notes',
                        sortable: true,
                        editable: false,
                        valueGetter: params => {
                            return params.data.notes.length
                        },
                        cellRendererFramework: "FeDialogColumn",
                        onCellClicked: v => {
                            me.showComments(v)
                        },
                        cellStyle: {
                            display: 'flex',
                            alignItems: 'flex-start',
                            height: 'initial !important' // don't want comments to be the only one moving when description makes row larger
                        }
                    },
                    {
                        headerName: 'Assignees',
                        field: 'assignees',
                        width: 200,
                        sortable: true,
                        editable: false,
                        cellRendererFramework: AssigneeColumn,
                        cellRendererParams: (params) => {
                            return {
                                users: this.users,
                            }
                        },
                        cellStyle: {
                            padding: 0
                        },
                        onCellClicked: v => {
                            me.cellClicked(v)
                        },
                        colId: 'assignees'
                    },
                    {
                        headerName: 'Date Due',
                        field: 'due_dt',
                        sortable: true,
                        editable: false,
                        cellRenderer(v) {
                            if (!v.data || !v.data.due_dt) return
                            return me.$dayjs(v.data.due_dt).format('MM/DD/YYYY')
                        },
                        onCellClicked: v => {
                            me.cellClicked(v)
                        }
                    },
                    {
                        headerName: 'Time Due',
                        field: 'due_time',
                        sortable: true,
                        editable: false,
                        cellRendererFramework: DueTimeColumn,
                        cellStyle: {
                            padding: "3px 0 0 0",
                        },
                        width: 150
                    },
                    {
                        headerName: 'Created By',
                        field: 'created_by_full_name',
                        sortable: true,
                        editable: false
                    },
                    {
                        headerName: 'Status',
                        field: 'task_status_id',
                        sortable: true,
                        editable: false,
                        cellRenderer(v) {
                            if(!v.data || !v.data.task_status_id) return
                            return me.renderStatus(v.data.task_status_id)
                        },
                        onCellClicked: v => {
                            me.cellClicked(v)
                        },
                        minWidth: 100
                    }
                ],
                showStatusMenu: false,
                statusMenuPosition: {
                    x: 0,
                    y: 0
                },
                selected: null,
                selectedRows: [],
                gridOptions: {
                    context: {componentParent: this}
                },
                addAssignees: [],
                comments: {
                    show: false,
                    notes: null,
                    task_id: null
                },
                showMassStatusModal: false,
                statusOptions: [
                    { id: 1, name: 'To Do', color: '#2B87FF' },
                    { id: 4, name: 'In Progress', color: '#0EA449' },
                    { id: 2, name: 'On Hold', color: '#FFA200' },
                    { id: 3, name: 'Done', color: '#746DE0' }
                ],
                massStatus: null,
                users: [],
                selectedNodes: [],
            }
        },

        computed: {
            ...mapState('global', ['currentYear']),
            availableStatus() {
                let statuses = [1, 2, 4, 3]

                if (this.selected?.data?.task_status_id) {
                    return statuses.filter(x => x !== this.selected.data.task_status_id)
                }

                return statuses
            },
            userCrudConfig() {
                let cfg = this.$_.cloneDeep(this.$models.user)
                cfg.read.params.school_year_id = this.currentYear.id
                cfg.read.params.active = 1
                return cfg
            },
        },

        watch: {
            items: {
                deep: true,
                handler(v) {
                    if(v) {
                        this.cancel()
                        if(this.comments.show) {
                            let item = v.find(x => x.id == this.comments.task_id)
                            if(item) this.comments.notes = item.notes
                        }
                    }
                }
            },
            selected(v) {
                if (v && v.colDef.headerName == 'Time Due') {
                    this.$emit('update', 'due_time', v.data, v)
                }
            },
            addAssignees(v) {
                if(v && this.selected) {
                    this.$emit('add-assignee', this.selected.data, v)
                }
            },
            users(v) {
                this.$refs.grid.gridOptions.api.refreshCells({
                    force: true,
                    columns: ['assignees'], 
                })
            }, 
            unselectRows(v) {
                if(v) {
                    if(this.selectedNodes.length > 1) {
                        for (let i = 0; i < this.selectedNodes.length - 1; i++) {
                            this.selectedNodes[i].setSelected(false, false, true)
                        }
                        this.selectedNodes[this.selectedNodes.length - 1].setSelected(false)
                    } else {
                        if(this.selectedNodes.length) this.selectedNodes[0].setSelected(false)
                    }
                }
            }
        },

        methods: {
            editActionItem(e) {
                this.$emit('edit', this.selectedRows[0])
            },
            deleteActionItems(e) {
                this.$emit('delete', this.selectedRows)
            },
            setSelectedRows(item) {
                this.selectedNodes = item.api.getSelectedNodes()
                this.selectedRows = item.api.getSelectedRows()
                this.selectedNodes.forEach(node => {
                    node.oldStatusVal = node.data.task_status_id
                })
            },
            resize() {
                this.$refs.grid?.gridApi?.sizeColumnsToFit()
            },
            renderStatus(v) {
                let status
                let color
                switch (v) {
                    case 2:
                        status = 'On Hold'
                        color = '#FFA200'
                        break;
                    case 3:
                        status = 'Done'
                        color = '#746DE0'
                        break;
                    case 4:
                        status = 'In Progress'
                        color = '#0EA449'
                        break;
                    default:
                        status = 'To Do'
                        color = '#2B87FF'
                }
                return '<div class="action-items-status-column-ap-chip" style="background-color: ' + color + '; cursor: pointer;">' + status + '</div>'
            },
            cellClicked(v) {
                let e = v.event
                let target = e.target
                if (target.localName === 'td') target = target.firstChild
                let element = target.getBoundingClientRect()
                this.selected = v
                this.showStatusMenu = true
                this.statusMenuPosition.x = element.left
                this.statusMenuPosition.y = element.top + 30
            },
            cancel() {
                this.selectedRows = []
                this.$refs.grid?.gridApi?.deselectAll()
            },
            statusChanged(newStatus) {
                let payload = this.$_.cloneDeep(this.selected.data)
                payload.task_status_id = newStatus
                this.$emit('update', 'task_status_id', payload, this.selected)
            },
            dateChanged(date) {
                let payload = this.$_.cloneDeep(this.selected.data)
                payload.due_dt = date
                this.$emit('update', 'due_dt', payload, this.selected)
            },
            showComments(v) {
                this.comments.show = true
                this.comments.notes = v.data.notes
                this.comments.task_id = v.data.id
            },
            submitNote(rec) {
                this.$emit('submit-note', rec)
            },
            deleteNote(rec) {
                this.$emit('delete-note', rec, this.comments.task_id)
            },
            massStatusChange() {
                this.showMassStatusModal = false
                this.selectedRows.forEach(row => {
                    row.task_status_id = this.massStatus
                })
                this.$emit('update', 'task_status_id', this.selectedRows, this.selectedNodes)
                this.massStatus = null
            }
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .action-items-status-column-ap-chip {
        display: inline-block;
        top: 14px;
        height: 22px;
        border-radius: 11px;
        font-size: 11px;
        color: #FFFFFF;
        line-height: 22px;
        padding: 0 11px;
    }
    ::v-deep .action-items-status-menu-ap-chip {
        display: inline-block;
        height: 22px;
        border-radius: 11px;
        font-size: 11px;
        color: #FFFFFF !important;
        line-height: 22px;
        padding: 0 11px;
    }
    ::v-deep .action-item-mass-status-chip .v-chip__content {
        color: white !important;
    }
    ::v-deep .ai-show-description {
        cursor: pointer;
        color: #006C90;
        text-decoration-line: underline;
        font-size: 12px;
    }
    ::v-deep .ai-name-column-description {
        font-size: 12px;
    }
    ::v-deep .action-items-grid {
        .ag-row-hover {
            .action-items-fe-time-scroll-picker .v-select.v-text-field {
                .v-input__slot {
                    background: #EEF5FF !important;
                }
            }
        }
        .fe-grid-toolbar {
            position: relative;
            z-index: 100;
        }
        .ag-row-selected {
            .action-items-fe-time-scroll-picker .v-select.v-text-field {
                .v-input__slot {
                    background: #EEE;
                }
            }
        }
    }
</style>

<style lang="scss">
    .action-items-fe-time-scroll-picker .v-select.v-text-field  {
        input {
            padding-left: 20px !important;
        }
    }
    .action-items-assignee-column-ap-avatar {
        position: absolute;
        top: 12px;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color: #C4C4C4;
        text-align: center;
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
        &-letter {
            line-height: 1.25rem !important;
            padding: 2px;
        }
    }
    .action-items-assignee-column-assignee-cell {
        min-height: 50px;
        min-width: 200px;
        position: relative;
        cursor: pointer;
        margin-left: 20px;
    }
    .action-items-assignee-column-ap-more {
        position: absolute;
        top: 5px;
        left: 60px;
        font-size: 12px;
    }
    .action-items-assignee-column-custom {
        .v-input__slot {
            padding: 10px !important;
            border: none !important;
        }
    }
</style>
