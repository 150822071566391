import StudentProfile from '@/components/modules/studentprofile/Index'


export default [{
    path: '/StudentProfile/:id',
    props: (route) => {
        return {
            id: route.params.id
        }
    },
    name: 'StudentProfile',
    component: StudentProfile
}, {
    path: '/StudentProfile/:id/:year',
    props: (route) => {
        return {
            id: route.params.id,
            school_year_id: route.params.year
        }
    },
    name: 'StudentProfile',
    component: StudentProfile
}, {
    path: '/StudentProfile',
    name: 'StudentProfile',
    component: StudentProfile
}]