<template>
    <fe-crud-grid
        ref="grid"
        :config="$models.studentThresholdGroupResult"
        :columnDefs="columnDefs"
        :readParams="params"
        :bordered="false"
        :showDensityToolbar="false"
        :showSaveAsDefault="false"
        :showBorderToggle="false"
        :showFullscreen="false"
        :showAddRowBtn="false"
        :minimal="!meeting"
        autoload
    />
</template>

<script>
    export default {
        name: 'ThresholdsTable',
        props: {
            params: { type: Object, default: {} },
            meeting: { type: Boolean, default: false }
        },
        data() {
            return {
                columnDefs: [{
                    headerName: "Threshold",
                    field: "threshold_group_name",
                    sortable: true,
                    editable: false
                }, {
                    headerName: "School Year",
                    field: "school_year_name",
                    sortable: true,
                    editable: false,
                    hide: !this.meeting
                },{
                    headerName: "Band",
                    field: "band_name",
                    sortable: true,
                    editable: false,
                    cellRenderer(v) {
                        return `<span style="color: ${v.data.band_color || '#333'};">${v.data.band_name || ''}</span>`
                    }
                }]
            }
        }
    }
</script>