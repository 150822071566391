<template>
    <fe-dialog
        title="Link smartFORM"
        @dismiss="localValue=false; selectedType=null"
        @accept="linkFormTo"
        v-if="localValue"
        :width="width"
        :acceptButtonDisabled="!linkRecord"
        dismissButtonText="Cancel"
        acceptButtonText="Save"
        persistent
    >
        <!-- <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.incidentBehavior"
            @read="v => items = v"
        /> -->
        <div class="page-section mt-2">Link to</div>
        <div v-if="!selectedType">
            <v-list>
                <v-list-item class="row-item" v-for="(t, i) in types" :key="i" style="cursor: pointer;">
                    <v-list-item-content @click="t.handler">{{t.name}}</v-list-item-content>
                </v-list-item>
            </v-list>
        </div>

        <div v-else>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th v-for="c in columns">
                                {{ c.header }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="cursor: pointer;" v-for="item in lookupItems" :key="item.name" @click="linkRecord = item" :class="{selected: linkRecord == item}">
                            <td v-for="c in columns">
                                {{ item[c.field] }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>

        <div v-if="existing.length > 0">
            <div class="page-section mt-4">Currently Linked</div>
            <div class="current-links">
                <v-list>
                    <v-list-item v-for="(e,i) in existing" :key="`existing`+i">
                        <v-list-item-content>
                            {{ e.form_instance_name }} linked to Intervention ID: {{ e.intervention_id }}
                        </v-list-item-content>

                        <v-list-item-icon @click="doUnlink(e)" style="cursor: pointer;">
                            <v-icon>
                                fas fa-times
                            </v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list>
            </div>
        </div>

    </fe-dialog>


</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'LinkForm',
        props: {
            value: {

            },
            rec: {
                required: true
            }
        },
        computed: {
            ...mapState('global', ['sessionUser'])
        },
        watch: {
            value(v) {
                this.$data.localValue = v
                if (v) this.getExistingLinks()
            },
            localValue(v) {
                this.$emit('input', v)
            },
            selectedType(v) {
                switch (v) {
                    case 'intervention':
                        this.$data.width = 700
                        break
                    case 'slo':
                        this.$data.width = 700
                        break
                    case 'collection':
                        this.$data.width = 700
                        break
                    default:
                        this.$data.width = 700
                        break;
                }
            }
        },
        data() {
            let me = this
            return {
                selectedType: null,
                localValue: null,
                lookupItems: [],
                linkRecord: null,
                width: 600,
                columns: [],
                existing: [],
                types: [{
                    id: 'slo',
                    name: 'SLO',
                    handler() {
                        me.$data.selectedType = 'slo'
                        let url = 'sloPlanV.php?action=get&slo_user_user_id='+me.sessionUser.user.id
                        me.$axios.get(url)
                            .then(response => {
                                let data = response.data
                                me.$data.columns = [{
                                    header: 'SLO Name',
                                    field: 'slo_name'
                                }, {
                                    header: 'Reviewer',
                                    field: 'slo_approval_user_display_name'
                                }, {
                                    header: 'Created',
                                    field: 'slo_created'
                                }]

                                me.$data.lookupItems = response.data.plans
                            })
                    }
                }, {
                    id: 'intervention',
                    name: 'Intervention',
                    handler() {
                        me.$data.selectedType = 'intervention'
                        let url = 'studentInterventionPlans.php?action=get&student_id='+me.$props.rec.first_student_id+'&property=interventions'
                        me.$axios.get(url)
                            .then(response => {
                                let data = response.data
                                me.$data.columns = [{
                                    header: 'Intv ID',
                                    field: 'intervention_id'
                                }, {
                                    header: 'Title',
                                    field: 'intervention_plan_name'
                                }, {
                                    header: 'Level',
                                    field: 'intervention_level_name'
                                }, {
                                    header: 'Type',
                                    field: 'intervention_type_name'
                                }, {
                                    header: 'Interventionist',
                                    field: 'intervention_plan_user_full_name'
                                }, {
                                    header: 'Start Date',
                                    field: 'intervention_plan_start_date'
                                }, {
                                    header: 'End Date',
                                    field: 'intervention_plan_end_date'
                                }]

                                me.$data.lookupItems = response.data.students
                            })
                    }
                }]
            }
        },
        mounted() {
            this.$data.localValue = this.$props.value
            this.getExistingLinks()
        },
        methods: {
            doUnlink(rec) {
                let me = this

                this.$messageBox({
                    title: 'Confirm',
                    persistent: true,
                    maxWidth: 400,
                    message: 'Are you sure you want to unlink this smartFORM?',
                    actions: [{
                        text: 'Cancel'
                    }, {
                        text: 'Unlink',
                        usage: 'danger',
                        onClick: () => {
                            me.$axios.post('interventionView.php?action=delete&property=forms', { form_instances: [rec] })
                                .then(r=> {
                                    this.$ecResponse(r)
                                    me.getExistingLinks()
                                })
                        }
                    }]
                })
                // this.$confirmDelete([rec], () => {

                // }, null, 'Are you sure you want to unlink this smartFORM?')
            },
            processList(items, key, columns) {
                items.forEach(item => {})
            },
            getExistingLinks() {
                if (!this.$props.rec) return
                this.$axios.get('interventionView.php?action=get&property=forms&form_instance_id='+this.$props.rec.form_instance_id)
                    .then(r => this.existing = r.data ? r.data.form_instances : [])
            },
            linkFormTo() {
                let url = ''
                let p = {
                    link_type: this.$data.selectedType,
                    form_instance_id: this.$props.rec.form_instance_id,
                    property: 'form_link',
                    action: 'create'
                }
                switch (this.$data.selectedType) {
                    case 'slo':
                        p.link_id = this.$data.linkRecord.slo_id
                        this.linkOther('form.php?action=create&', p)
                        break
                    case 'intervention':
                        this.linkIntervention()
                        break
                    case 'collection':
                        p.link_id = this.$data.linkRecord.dashboard_id
                        this.linkOther('form.php?action=create&', p)
                        break
                }
            },
            linkOther(url, p) {
                this.$axios.post(url+'&'+this.$objectToParams(p))
                    .then(response => {
                        this.$ecResponse(response)
                    })
                    .finally(() => {
                        this.$data.localValue = false
                        this.$data.selectedType = null
                    })
            },
            linkIntervention() {
                let rec = this.$data.linkRecord
                let url = 'interventionView.php?action=create&property=forms'
                let p = {
                    student_id: this.$props.rec.first_student_id,
                    intervention_id: rec.intervention_id,
                    form_instance_id: this.$props.rec.form_instance_id
                }
                this.$axios.post(url, JSON.stringify({ form_instances: [p] }))
                    .then(response => {
                        this.$ecResponse(response)
                    })
                    .finally(() => {
                        this.$data.localValue = false
                        this.$data.selectedType = null
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
.selected {
    background-color: var(--fe-accent);
}

.row-item {
    &:hover {
        background-color: var(--fe-accent);
        font-weight: bold;
    }
}

.current-links {
    border: 1px solid #ECEDF1;
    border-radius: 4px;
    padding: 16px;
}
</style>
