<template>
    <div class="thresholds d-flex">
        <Main
            v-if="!editing"
            :mode="mode"
            :settings="settings"
            @mode="v => mode = v"
            @settings="v => settings = v"
            @exporter="v => exporter = v"
        />

        <Selection
            :mode="mode"
            :settings="settings"
            @mode="v => mode = v"
            @settings="v => settings = v"
            @exporter="v => exporter = v"
        />

        <GroupSettings
            :active="creating || settings"
            :mode="creating ? 'creating' : 'settings'"
            @cancel="cancelSettings"
            @apply="applySettings"
        />

        <GroupDuplicate
            :active="copying"
            @cancel="cancelSettings"
            @apply="applySettings"
        />

        <DataExporter
            v-if="!!exporter"
            :type="exporterType"
            :obj="exporterObj"
            @close="closeExporter"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Main from '../menus/Main'
import Selection from '../views/Selection'
import GroupSettings from '../modals/GroupSettings'
import GroupDuplicate from '../modals/GroupDuplicate'
import DataExporter from '../modals/DataExporter'
export default {
    name: 'Landing',

    components: {
        Main,
        Selection,
        GroupSettings,
        GroupDuplicate,
        DataExporter
    },

    data() {
        return {
            mode: 'viewing',
            settings: false,
            exporter: null,
            interval: null
        }
    },

    computed: {
        ...mapGetters('thresholds/group', [
            'collection',
            'clear',
            'selected',
            'selectedWithMeta',
            'selectedId'
        ]),

        editing() {
            return this.mode === 'editing'
        },

        creating() {
            return this.mode === 'creating'
        },

        copying() {
            return this.mode === 'copying'
        },

        viewing() {
            return this.mode === 'viewing'
        },

        exporterType() {
            return this.exporter && this.exporter.type
        },

        exporterObj() {
            return this.exporter && this.exporter.obj
        },

        selectedIndex() {
            return this.selectedWithMeta
                ? this.selectedWithMeta.__idx
                : null
        }
    },

    mounted() {
        this.$store.dispatch('thresholds/school/refresh')
        this.$store.dispatch('thresholds/grade/refresh')
        this.$store.dispatch('thresholds/tag/refresh')
        /** TODO maybe find another way to react to updates other than pinging every 5 seconds **/
        this.interval = setInterval(() => {
            this.checkStatus()
        }, 5000)
    },

    beforeDestroy() {
        clearInterval(this.interval)
    },

    methods: {
        ...mapActions('thresholds/group', [
            'add',
            'update',
            'duplicate',
            'refreshStatus'
        ]),

        closeExporter() {
            this.exporter = null
        },

        cancelSettings() {
            this.settings = false
            if (this.creating || this.copying) this.mode = 'viewing'
        },

        applySettings(data) {
            if (this.copying) {
                this.duplicate(data)
                this.mode = 'viewing'
            } else if (this.creating) {
                this.add([data])
                this.mode = 'viewing'
            } else {
                let params = Object.assign({}, data, {__idx: this.selectedIndex})
                this.update(params)
            }
            this.settings = false
        },

        checkStatus() {
            if (this.selected && (!this.selected.has_valid_results || this.selected.is_queued)) {
                this.refreshStatus(this.selectedId)
            }
        }
    }
}
</script>
