<template>
    <div class="flex-fill no-scroll mb-5" style="pointer-events: all">
        <fe-grid
            ref="grid"
            :style="style"
            :defaultColDefOverrides="defaultColDef"
            :columnDefs="columns"
            :rowData="rowData"
            :showToolbar="false"
            :showAddRowBtn="false"
            showScrollbars
            displayDensity="small"
            @gridReady="resize"
            @gridColumnsChanged="resize"
        />
    </div>
</template>

<script>
import Tooltip from "@/components/common/grid/Tooltip"

export default {
    name: 'IncidentField',

    components: {Tooltip},

    props: {
        data: {},
        fieldRec: {},
        editor: {},
    },

    data() {
        if (!this.data) {
            // if no response data then get the columns
            this.$modelGet('formFieldSavedSearch', {form_field_id: this.fieldRec.id})
                .then(r => {
                    if (r.length) {
                        this.savedSearch = r.length && r[0].args
                    }
                })
        }

        return {
            savedSearch: null,
            defaultColDef: this.editor ? {
                sortable: false,
                resizable: false,
                filter: false,
                hide: false,
                cellClass: 'x-grid-column'
            } : {
                sortable: true,
                resizable: true,
                filter: true,
                hide: false,
                cellClass: 'x-grid-column'
            },
        }
    },

    computed: {
        rowData() {
            return this.data?.incidents ? this.data.incidents : []
        },
        style() {
            let h = 68 + 30 * (Math.max(1, this.rowData.length))
            if (h > 300) h = 300
            return {
                width: '100%',
                height: `${h}px`,
            }
        },
        columns() {
            let cols = this?.data?.columns || this.savedSearch?.INCIDENT_DISPLAY_COLUMNS
            return cols ? [{
                headerName: 'Date',
                field: 'incident_datetime',
                valueFormatter: v => this.$dayjs(v.data.incident_datetime).format('YYYY-MM-DD'),
                maxWidth: 100,
                hide: cols ? !cols.includes('incident_datetime') : false
            }, {
                headerName: 'Time',
                field: 'incident_datetime',
                valueFormatter: v => this.$dayjs(v.data.incident_datetime).format('hh:mm A'),
                maxWidth: 100,
                hide: cols ? !cols.includes('column_time') : true
            }, {
                headerName: 'Incident Type',
                field: 'incident_behavior_display_name',
                hide: cols ? !cols.includes('incident_behavior_display_name') : false
            }, {
                headerName: 'Incident Code',
                field: 'incident_behavior_name',
                hide: cols ? !cols.includes('incident_behavior_name') : false
            }, {
                headerName: 'Location',
                field: 'incident_location_name',
                hide: cols ? !cols.includes('incident_location_name') : false
            }, {
                headerName: 'Response',
                field: 'incident_response_name',
                hide: cols ? !cols.includes('incident_response_name') : false
            }, {
                headerName: 'Observer',
                field: 'observer_user_full_name',
                hide: cols ? !cols.includes('observer_user_full_name') : false
            }, {
                headerName: 'Perceived Motivation',
                field: 'incident_motivation_name',
                hide: cols ? !cols.includes('incident_motivation_name') : true
            }, {
                headerName: 'Grade',
                field: 'grade_desc',
                hide: cols ? !cols.includes('grade_desc') : true
            }, {
                headerName: 'School',
                field: 'school_name',
                hide: cols ? !cols.includes('school_name') : true
            }, {
                headerName: 'School Year',
                field: 'school_year_name',
                hide: cols ? !cols.includes('school_year_name') : true
            }, {
                headerName: 'Entry User',
                field: 'entry_user_full_name',
                hide: cols ? !cols.includes('entry_user_full_name') : true
            }, {
                headerName: 'Responder',
                field: 'responder_user_full_name',
                hide: cols ? !cols.includes('responder_user_full_name') : true
            }, {
                headerName: 'Description',
                headerTooltip: "This field may contain student names when imported from the Student Information System (SIS).",
                headerComponentParams: { template: '<div">Description <i class="far fa-info-circle"></i></div>' },
                field: 'description',
                cellRendererFramework: Tooltip,
                hide: cols ? !cols.includes('description') : true
            }] : []
        }
    },

    methods: {
        resize() {
            this.$refs.grid?.gridApi?.sizeColumnsToFit()
        },
    }
}
</script>

<style lang="scss" scoped>
.header {
    color: rgb(126, 132, 148);
    font-size: 12px;
}
</style>
