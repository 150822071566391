<template>
  <span>
    <fe-label v-if="label" :content="label"/>

    <fe-chip-select
        ref="cs"
        v-model="selected"
        :items="collection"
        :item-text="labelProp"
        :item-value="keyProp"
        :multiple="multiple"
        :clearable="clearableDefault"
        :untruncated="untruncated"
        :readonly="readonly"
        :full-width="fullwidth"
        :rules="rules"
        :required="required"
        :autofocus="false"
        :chips="chips"
        open-on-clear
        deletable-chips
        @blur="onBlur"
    />
  </span>
</template>

<script>
export default {
    name: 'ItemSelector',

    props: {
        collection: Array,
        label: String,
        multiple: Boolean,
        selectedKeys: [Array, Number],
        keyProp: String,
        labelProp: String,
        readonly: Boolean,
        fullwidth: Boolean,
        rules: Array,
        required: Boolean,
        chips: {type: Boolean, default: true},
        untruncated: Boolean
    },

    computed: {
        clearableDefault() {
            return !!this.multiple
        },

        selected: {
            get: function () {
                if (this.multiple) {
                    return (Array.isArray(this.selectedKeys) && this.selectedKeys.length > 0)
                        ? this.collection.filter(itm => this.itemSelected(itm))
                        : []
                } else {
                    return this.selectedKeys
                }
            },
            set: function (val) {
                this.$emit('change', val)
            }
        }
    },

    methods: {
        itemSelected(itm) {
            return this.selectedKeys.map(x => `${x}`).includes(`${itm[this.keyProp]}`)
        },

        onBlur(e) {
            if (e instanceof FocusEvent) {
                this.$emit('updates', this.$refs.cs.getValue())
            }
        }
    }
}
</script>
