<template>
    <v-menu offset-x left :close-on-content-click="false">
        <template v-slot:activator="{ on }">
            <v-list-item v-on="on" class="d-flex">
                <i class="far fa-bullseye fe-icon" :style="{fontSize: '14px'}" />
                <span class="mx-3">Performance Bands</span>
                <v-spacer/>
                <i class="fas fa-caret-right"/>
            </v-list-item>
        </template>

        <v-list v-if="isLoaded" dense>
            <fe-search
                v-model="filters.assessmentGroup"
                placeholder="Assessment Group"
                clearable
            />

            <div class="popout">
                <v-menu offset-x left
                    v-for="ag in assessmentGroups"
                    :key="ag.id"
                    :close-on-content-click="false"
                >
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" class="d-flex">
                            {{ ag.name }}
                            <v-spacer/>
                            <i class="fas fa-caret-right"/>
                        </v-list-item>
                    </template>

                    <v-list dense>
                        <fe-search
                            v-model="filters.schoolYear"
                            placeholder="School Year"
                            clearable
                        />

                        <div class="popout">
                            <v-menu offset-x left
                                v-for="sy in filterSchoolYears(ag.schoolYears)"
                                :key="sy.id"
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-list-item v-on="on" class="d-flex">
                                        {{ sy.name }}
                                        <v-spacer/>
                                        <i class="fas fa-caret-right"/>
                                    </v-list-item>
                                </template>

                                <v-list dense>
                                    <fe-search
                                        v-model="filters.targetSet"
                                        placeholder="Performance Band"
                                        clearable
                                    />

                                    <div class="popout">
                                        <v-list-item
                                            v-for="ts in filterTargetSets(sy.targetSets)"
                                            :key="ts.id"
                                            @click="onSelectTargetSet(ts)"
                                        >
                                            <i v-if="ts.user_selected" class="far fa-dot-circle"/>
                                            <i v-else class="far fa-circle"/>

                                            <span class="ml-4">{{ ts.name }}</span>

                                            <i v-if="ts.site_default" class="ml-4 far fa-star" color="gold"/>
                                        </v-list-item>
                                    </div>
                                </v-list>
                            </v-menu>
                        </div>
                    </v-list>
                </v-menu>
            </div>
        </v-list>

        <fe-crud
            ref="crud"
            :config="$models.userTargetSet"
            autoload
            @read="onRead"
        />
    </v-menu>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'UserTargetSetsPopout',
        props: {
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            isLoaded() {
                return (this.userTargetSets?.length > 0)
            },
            assessmentGroups() {
                let results = this.userTargetSets.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1) // No 0 check, name will always be unique

                if (this.filters.assessmentGroup?.length >= 3) {
                    results = results.filter(itm => itm.name.toLowerCase().includes(this.filters.assessmentGroup.toLowerCase()))
                }

                return results
            },
        },
        data: () => ({
            userTargetSets: [],
            filters: {
                assessmentGroup: null,
                schoolYear: null,
                targetSet: null,
            },
        }),
        methods: {
            onRead(items) {
                this.userTargetSets = items
            },
            async onSelectTargetSet(itm) {
                // Backend checks the active session user (i.e. does not use our user_id
                // prop), but nonetheless user_id has traditionally been included here
                let payload = [{ user_id: this.sessionUser.id, target_set_id: itm.id }]

                await this.$refs.crud.create(payload)
                await this.$refs.crud.read()
            },

            filterSchoolYears(items) {
                // Less requirements to search by school year
                if (this.filters.schoolYear?.length >= 1) {
                    return items.filter(itm => itm.name.toLowerCase().includes(this.filters.schoolYear.toLowerCase()))
                } else {
                    return items
                }
            },

            filterTargetSets(items) {
                if (this.filters.targetSet?.length >= 3) {
                    return items.filter(itm => itm.name.toLowerCase().includes(this.filters.targetSet.toLowerCase()))
                } else {
                    return items
                }
            },
        },
    }
</script>

<style lang="scss" scoped>

.popout {
    max-height: 300px;
    overflow: auto;
}

</style>