<template>
    <div class="d-flex flex-fill flex-column" style="overflow: auto;">
        <div class="d-flex" :style="$vuetify.breakpoint.mdAndUp ? 'min-height: 75px;' : 'min-height: 75px; overflow: auto; min-width: 800px;'" style="background: white; padding: 16px;">
            <div>
                <v-menu
                    ref="menu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                >
                    <template v-slot:activator="{ on }">
                        <v-btn depressed class="date-filter" color="#E0E1E6" rounded v-on="on">
                            {{ selectedDate ? selectedDate : 'Select Date'}}
                            <v-icon class="pl-2">fa-caret-down</v-icon>
                        </v-btn>
                    </template>

                    <v-date-picker v-model="selectedDate" no-title scrollable>
                    </v-date-picker>
                </v-menu>
            </div>

            <fe-filter-btn
                ref="userFilter"
                borders
                :items="users"
                title="Interventionist"
                v-model="selectedUser"
                itemText="user_full_name"
                :multiple="false"
                buffered
                actions
                showClearButton
                :showApplyButton="false"
            />

            <fe-filter-btn
                :items="groups"
                title="Group"
                v-model="selectedGroup"
                itemText="intervention_plan_name"
                itemValue="intervention_plan_id"
                multiple
                actions
                :closeOnSelect="false"
                :showApplyButton="false"
            />

            <fe-filter-btn
                :items="students"
                title="Students"
                itemText="student_full_name"
                itemValue="student_id"
                :closeOnSelect="false"
                v-model="selectedStudent"
            />

            <div class="ml-auto d-flex">
                <v-btn color="tertiary" depressed v-if="selectedGroup.included.length===1" @click="attachmentDialog.show=true"><v-icon>far fa-paperclip</v-icon></v-btn>
                <v-btn color="tertiary" depressed v-if="selectedGroup.included.length===1" @click="showMessages"><v-icon>far fa-comment</v-icon></v-btn>
                <!-- <v-btn color="tertiary" depressed @click="startGroupReschedule('update')"><v-icon>far fa-calendar</v-icon></v-btn> -->
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn color="tertiary" depressed v-on="on"><v-icon>far fa-calendar</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="startGroupReschedule('update')">Reschedule Groups</v-list-item>
                        <v-list-item @click="startGroupReschedule('create')">Add Date for Group</v-list-item>
                    </v-list>
                </v-menu>
                <v-btn color="tertiary" depressed @click="quickDialog.show=true"><v-icon>far fa-bolt</v-icon></v-btn>
            </div>
        </div>

        <div style="overflow: auto; min-width: 850px;" v-if="loaded">
            <div style="background: #F5F6F8; padding: 16px; min-width: 800px;" v-for="(item, index) in filteredItems">
                <!-- toolbar for card -->
                <v-lazy>
                    <div class="participation-card">
                        <div class="d-flex" style="border-bottom: 1px solid #e6e6e6; padding: 8px 16px 8px 16px;">
                            <div class="d-flex">
                                <div class="text-center">
                                    <v-avatar size="36" style="margin-top: 2px; margin-right: 12px;">
                                        <v-img
                                            :src="getImageUrl(item.student_id)"
                                            alt="Student Image"
                                            :options="{'use-credentials': true }"
                                        />
                                    </v-avatar>
                                </div>

                                <div>
                                    <div>{{item.student_full_name}}</div>
                                    <div style="font-size: 12px;">{{item.intervention_plan_name}}</div>
                                </div>

                                <div class="ml-3"
                                    :style="{color: participationRate[index] < 80 ? 'red': 'green'}"
                                    style="font-size: 12px; margin-top: 1px; cursor: pointer;"
                                    v-if="!isNaN(participationRate[index])"
                                    @click="breakdownDialog.show=true; breakdownDialog.data=item"
                                >
                                    <v-icon class="mr-2" :color="participationRate[index] < 80 ? 'red': 'green'" style="font-size: 12px;">
                                        {{participationRate[index] < 80 ? 'fas fa-times' : 'fas fa-check'}}
                                    </v-icon>
                                    {{participationRate[index]}}%
                                </div>
                            </div>
                            <div class="d-flex ml-auto">
                                <div style="width: 150px;" class="mr-2">
                                    <fe-remote-combo
                                        :items="attendanceCodes"
                                        v-model="item.student_intervention_occurrence_attendance_type_id"
                                        hide-details
                                        itemValue="id"
                                        itemText="name"
                                        placeholder="Attendance"
                                        @input="setProcedure(item)"
                                        byId
                                    />
                                </div>

                                <div style="width: 90px;">
                                    <v-text-field
                                        v-if="item.category_id!=4"
                                        flat solo dense
                                        type="number"
                                        v-model="item.student_intervention_occurrence_duration_formatted"
                                        :placeholder="`Rec `+item.intervention_plan_session_duration+` min`"
                                        hide-details
                                        :rules="$fieldValidators('number', null, { required: false })"
                                        @blur="setProcedure(item)"
                                    />
                                </div>

                                <span class="mt-2 ml-2">Minutes</span>

                            </div>

                            <div>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on }">
                                        <fe-icon-btn small useIcon="fas fa-ellipsis-v" v-on="on"/>
                                    </template>
                                    <v-list>
                                        <v-list-item @click="showPlan(item)" >View plan summary</v-list-item>
                                        <v-list-item @click="showMonitor(item)">Progress monitoring</v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </div>

                        <div style="padding: 16px;">
                            <div v-if="item.procedures.length>0"  class="d-flex flex-wrap">
                                <div
                                    v-for="proc in item.procedures"
                                    class="procedure-toggle" @click="setProcedure(item, proc)"
                                    :class="{'procedure-toggle-selected': proc.student_intervention_occurrence_procedure_completed}"
                                >
                                    {{proc.intervention_type_procedure_name}}
                                </div>

                                <fe-switch
                                    :disabled="!item.student_intervention_occurrence_attendance_type_id"
                                    hide-details
                                    label="Select All"
                                    class="mt-2"
                                    @input="setProcedure(item, null, ...arguments)"
                                    :value="item.procedures.filter(r=>r.student_intervention_occurrence_procedure_completed).length===item.procedures.length"
                                />
                            </div>

                            <div v-else>
                                No procedures required
                            </div>

                            <!-- <div v-if="item.showProgressMonitoring" style="position: relative;">
                                <fe-icon-btn useIcon="fas fa-times" style="position: absolute; left: 16px; top 10px;" @click="item.showProgressMonitoring=false"/>
                                <academic-progress-monitoring  :params="{ student_monitor_plan_id: item.student_monitor_plan_id }"/>
                            </div> -->
                        </div>
                    </div>
                </v-lazy>
            </div>
        </div>

        <fe-dialog
            title="Record Participation for All"
            v-if="quickDialog.show"
            @accept="doQuick"
            @close="quickDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Save"
            :acceptButtonDisabled="!quickDialog.rec.attendanceId || (!quickDialog.rec.duration && !quickDialog.rec.recommendedDuration)"
        >
            <div>
                <fe-label>Participation</fe-label>
                <fe-remote-combo
                    v-model="quickDialog.rec.attendanceId"
                    :items="attendanceCodes"
                    hide-details
                    itemValue="id"
                    itemText="name"
                    placeholder="Attendance"
                />

                <fe-label>Duration</fe-label>
                <v-text-field
                    :disabled="quickDialog.rec.recommendedDuration"
                    v-model="quickDialog.rec.duration"
                    type="number"
                    flat solo dense
                    :placeholder="quickDialog.rec.recommendedDuration ? 'Recommended' : 'Enter duration'"
                    hide-details
                    :rules="$fieldValidators('number', null, { required: false, min: 0, max:999})"
                />

                <v-checkbox v-model="quickDialog.rec.recommendedDuration" class="mt-2" label="Set recommended duration"/>

                <fe-label style="font-weight: bold;">Would you like to complete procedures?</fe-label>
                <v-radio-group v-model="quickDialog.rec.complete">
                    <v-radio label="Yes, complete all procedures for all students" :value="true"/>
                    <v-radio label="No, do not complete procedures" :value="false"/>
                </v-radio-group>
            </div>
        </fe-dialog>

        <fe-dialog
            title="Attachments"
            v-if="attachmentDialog.show"
            @accept="attachmentDialog.show=false"
            @close="attachmentDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Done"
            width="600"
        >
            <fe-crud
                :autoload="true"
                ref="attachmentCrud"
                :readParams="{ intervention_type_id: selectedGroup.included[0].intervention_type_id }"
                :config="$models.interventionTypeAttachment"
                @read="v => attachments = v"
            />
            <fe-grid
                ref="attachmentGrid"
                class="ec-basic-grid"
                domLayout="autoHeight"
                :columnDefs="attachmentColumnDefs"
                :rowData="attachments"
                :showToolbar="false"
                minimal
                @cellValueChanged="updateCrud('attachmentCrud', ...arguments)"
            />
        </fe-dialog>

        <fe-dialog
            title="Participation Breakdown"
            v-if="breakdownDialog.show"
            @accept="breakdownDialog.show=false"
            @close="breakdownDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Done"
        >
            <chart-panel>
                <highcharts ref="participationChart" class="fe-chart" :options="getPieConfig(breakdownDialog.data)"/>

                <template #tools>
                    <menu-btn style="margin-top: 6px;">
                        <v-list-item @click="$refs.participationChart.chart.exportChart({ type: 'application/pdf', filename: breakdownDialog.data.student_full_name + '_Participation' })">
                            Save Image
                        </v-list-item>

                        <v-list-item @click="$refs.participationChart.chart.print()">
                            Print
                        </v-list-item>

                    </menu-btn>
                </template>
            </chart-panel>
        </fe-dialog>

        <fe-dialog
            title="Messages"
            width="800"
            v-if="messageDialog.show"
            @accept="messageDialog.show=false"
            @close="messageDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Done"
            :retain-focus="false"
        >
            <message :allowPrivate="false" dense :params="messageDialog.params"/>
        </fe-dialog>

        <fe-dialog
            :title="rescheduleGroupDialog.type ==='create' ? 'Add Date' : 'Reschedule'"
            v-if="rescheduleGroupDialog.show"
            @accept="doReschedule"
            @close="rescheduleGroupDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Save"
            :acceptButtonDisabled="!rescheduleGroupDialog.date || rescheduleGroupDialog.rescheduleGroups.length==0"
        >
            <div>
                <fe-label>New Date</fe-label>
                <fe-date-picker v-model="rescheduleGroupDialog.date"/>

                <fe-label>Intervention Groups</fe-label>
                <selection-list multiple identifier="intervention_plan_name" :items="rescheduleGroupDialog.groups" style="height: 200px" v-model="rescheduleGroupDialog.rescheduleGroups">
                    <template #row="{item}">
                        {{item.intervention_plan_name}}
                    </template>
                </selection-list>

                <div class="d-flex mt-4 justify-end" v-if="rescheduleGroupDialog.type==='create'">
                    <v-checkbox v-model="rescheduleGroupDialog.changeNoSched" label="Show as needed groups only"/>
                </div>
            </div>
        </fe-dialog>

        <fe-dialog
            title="Progress Monitoring"
            v-if="pmDialog.show"
            @accept="pmDialog.show=false"
            @close="pmDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Done"
            width="90vw"
        >
            <academic-progress-monitoring  showStatement :params="pmDialog.params"/>
        </fe-dialog>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import SelectionList from '@/components/common/SelectionList'
import Message from '@/components/common/Message'
import AcademicProgressMonitoring from '@/components/charts/AcademicProgressMonitoring'
import ChartPanel from '@/components/charts/ChartPanel'
import MenuBtn from '@/components/common/button/MenuBtn'
import windowOptionsMixin from '@/mixins/windowOptions'

export default {
        name: 'InterventionParticipation',

        components: { SelectionList, Message, AcademicProgressMonitoring, ChartPanel, MenuBtn },

        mixins: [ windowOptionsMixin ],

        props: {
            record: {
                type: Object,
                required: false
            }
        },

        data() {
            let me = this

            return {
                attachments: [],
                selectedDate: me.$dayjs().format('YYYY-MM-DD'),
                selectedUser: {
                    included: []
                },
                breakdownDialog: {
                    show: false,
                    data: null
                },
                selectedGroup: {
                    included: []
                },
                selectedStudent: {
                    included: []
                },
                users: [],
                groups: [],
                students: [],
                dateMenu: false,
                procedures: [{
                    name: 'Proc 1'
                }, {
                    name: 'Proc 2'
                }],
                items: [],
                itemsCopy: [],
                attendanceCodes: [],
                quickDialog: {
                    show: false,
                    rec: {
                        attendanceId: null,
                        duration: null,
                        recommendedDuration: true,
                        complete: true
                    }
                },
                rescheduleDialog: {
                    show:false,
                    date: null,
                    students: [],
                    rescheduleStudents: []
                },
                rescheduleGroupDialog: {
                    type: 'create',
                    show:false,
                    date: null,
                    groups: [],
                    rescheduleGroups: [],
                    changeNoSched: false
                },
                messageDialog: {
                    show: false,
                    params: {}
                },
                attachmentDialog: {
                    show: false,
                    params: {}
                },
                pmDialog: {
                    show: false,
                    params: {}
                },
                planDialog: {
                    show: false
                },
                participationRate: [],
                loaded: false
            }
        },

        computed: {
            ...mapState('global', ['sessionUser']),
            filteredItems() {
                let arr = [...this.items]
                let gIds = this.selectedGroup.included.map(r=>r.intervention_plan_id)
                if (gIds.length>0)
                    arr = arr.filter(a=>gIds.indexOf(a.intervention_plan_id) != -1)

                let sIds = this.selectedStudent.included.map(r=>r.student_id)

                if (sIds.length>0)
                    arr = arr.filter(a=>sIds.indexOf(a.student_id) != -1)

                arr.forEach(r => r.student_intervention_occurrence_duration_formatted = r.student_intervention_occurrence_duration/60)

                this.itemsCopy = this.$_.cloneDeep([...arr])

                return arr
            },
            attachmentColumnDefs() {
                let me = this
                let id = this.selectedGroup.included[0].intervention_type_id

                return [{
                    field: 'name',
                    headerName: 'Name',
                    flex: 1,
                    editable: false
                }, {
                    field: 'created_by_full_name',
                    headerName: 'Uploaded By',
                    flex: 1,
                    editable: false
                }, me.$grid.iconColumn('View', 'fas fa-download', (v) => {
                    me.$downloadFile('interventionTypes.php?action=get&property=file&intervention_type_id='+id+'&id='+v.data.id,
                        v.data.name
                    )
                })]
            }
        },

        watch: {
            selectedDate() {
                this.loadGroups()
            },
            'quickDialog.show'(v) {
                if (!v) return
                this.quickDialog.rec = {
                    attendanceId: null,
                    duration: null,
                    recommendedDuration: true,
                    complete: true
                }
            },
            'rescheduleDialog.show'(v) {
                if (!v) return
                this.rescheduleDialog.students = this.students.map(r=>{ return { student_id: r.student_id, student_full_name: r.student_full_name }})
            },
            'rescheduleGroupDialog.show'(v) {
                if (!v) return
                this.rescheduleGroupDialog.groups = [...this.groups]//.map(r=>{ return { intervention_plan_id: r.intervention_plan_id, intervention_plan_name: r.intervention_plan_name }})
                this.rescheduleGroupDialog.groups.forEach(g => {
                    // somehow, plans do not have a name defined
                    if (!g.intervention_plan_name) g.intervention_plan_name = g.intervention_type_name
                })
            },
            'rescheduleGroupDialog.changeNoSched'(v) {
                if (v) {
                    this.rescheduleGroupDialog.groups=this.rescheduleGroupDialog.nosched
                } else {
                    this.rescheduleGroupDialog.groups = this.groups
                }
            },
            'selectedUser.included'(v) {
                if (this.loaded) {
                    this.loadGroups()
                }
            },
            selectedStudent(v) {
                if(v.included.length) {
                    let ids = v.included.map(r=>r['student_id'])
                    let students = this.items.map(r=> {
                        return {student_id: r.student_id, student_full_name: r.student_full_name, intervention_plan_id: r.intervention_plan_id }
                    })
                    let studentsSelected = []
                    students.forEach(stu => {
                        if(ids.includes(stu.student_id)) studentsSelected.push(stu)
                    })
                    this.loadParticipationRate(studentsSelected)
                } else {
                    this.loadParticipationRate(this.items)
                }
            },
            selectedGroup(v) {
                this.loadParticipationRate(this.filteredItems)
            }
        },

        mounted() {
            if(this.record) {
                this.selectedDate = this.$dayjs(this.record.startDate).format('YYYY-MM-DD')
            }

            this.$axios.get('users.php?action=get&active=1&detail=0').then(r => {
                this.users = this.$ecResponse(r)
                this.selectedUser = {
                    included: [this.sessionUser.user]
                }
                if (!this.record) {
                    this.loaded = true
                }
            })

            this.$modelGet('interventionAttendanceType').then(r => {
                this.attendanceCodes = r
                this.loadGroups()
                    .then(() => {
                        if (this.record) {
                            let included = this.groups.filter(x => x.intervention_plan_id === this.record.record.intervention_plan_id)
                            this.selectedGroup = {
                                included
                            }
                            this.loaded = true
                        }
                    })
            })
        },

        methods: {
            getPieConfig(rec) {
                let types = {}
                // create the series
                rec.details.forEach(d => {
                    if (d.student_intervention_occurrence_attendance_type_name) {
                        types[d.student_intervention_occurrence_attendance_type_name] = {
                            name: d.student_intervention_occurrence_attendance_type_name,
                            y: 0
                        }
                    }
                })

                rec.details.forEach(d => {
                    if (d.student_intervention_occurrence_attendance_type_name)
                        types[d.student_intervention_occurrence_attendance_type_name].y++
                })

                let c = {
                    chart: {
                        type: 'pie',
                        height: 250
                    },
                    title: {
                        text: rec.student_full_name,
                        style: {
                            fontSize: 14,
                            textAlign: 'left'
                        }
                    },
                    tooltip: {
                        followPointer: false,
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            }
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    navigation: {
                        menuItemStyle: {
                            fontSize: '12px',
                            background: 'white'
                        },
                        menuItemHoverStyle: {
                            background: '#E5F0F4',
                            color: 'black'
                        }
                    },
                    exporting: {
                        enabled: false,
                        buttons: {
                            contextButton: {
                                symbol: 'menuball',
                                symbolStrokeWidth: 1,
                                symbolFill: '#330033',
                                symbolStroke: '#330033'
                            }
                        }
                    },
                    series: [{
                        name: 'Participation',
                        data: Object.values(types)
                    }]
                }

                return c
            },
            loadParticipationRate(arr) {
                if (!arr.length) {
                    return
                }
                this.participationRate = arr.map(a => NaN)
                let sp = arr.map(a => '(' + a.intervention_plan_id + ',' + a.student_id + ')')
                this.$modelGet('interventionPlanFidelity', {
                    intervention_plan_student_tuple: sp.join(','),
                }).then(resp => {
                    let m = new Map(resp.map(a => [
                            '(' + a.intervention_plan_id + ',' + a.student_id + ')',
                            a.fidelity != null ? parseFloat(a.fidelity).toFixed(0) : NaN,
                    ]))
                    let result = []
                    arr.forEach(a => {
                        let k = '(' + a.intervention_plan_id + ',' + a.student_id + ')'
                        if (m.has(k)) {
                            result.push(m.get(k))
                        } else {
                            result.push(NaN)
                        }
                    })
                    this.participationRate = result
                })
            },
            showPlan(rec) {
                this.$dockableWindow({
                    name: 'Intervention Groups',
                    component: 'intervention-workspace',
                    attrs: {
                        interventionPlanId: rec.intervention_plan_id
                    }
                })
            },
            showMonitor(item) {
                if (item.category_id===4) {
                    this.$store.commit('global/addDockableWindow', {
                        name: item.student_full_name + ' Progress Monitoring',
                        component: 'behavior-progress-monitoring',
                        attrs: {
                            params: {
                                student_monitor_plan_id: item.student_monitor_plan_id
                            },
                            includeSummary: true,
                            height: window.innerHeight-200,
                            class: 'pa-5'
                        }
                    })
                    return
                }
                this.$store.commit('global/addDockableWindow', {
                    name: item.student_full_name + ' Progress Monitoring',
                    component: 'academic-progress-monitoring',
                    attrs: {
                        params: {
                            student_monitor_plan_id: item.student_monitor_plan_id
                        },
                        height: window.innerHeight-200,
                        monitorDecisions: true,
                        class: 'pa-5'
                    }
                })
            },
            showMessages() {
                this.messageDialog = {
                    show: true,
                    params: { intervention_plan_id: this.selectedGroup.included[0].intervention_plan_id }
                }
            },
            startReschedule() {
                this.rescheduleDialog.rescheduleStudents = []
                this.rescheduleDialog.date = this.$dayjs().add(1, 'd').format('YYYY-MM-DD')
                this.rescheduleDialog.show=true
            },
            startGroupReschedule(type) {
                this.$axios.get('interventionPlan.php?action=get&property=list&frequency_cd=NOSCHED&user_id='+this.sessionUser.user.id)
                    .then(r => {
                        let d = this.$ecResponse(r, 'plans')
                        d.forEach(r=> {
                            r.intervention_plan_id   = r.id
                            r.intervention_plan_name = r.name
                        })
                        this.rescheduleGroupDialog.type = type
                        this.rescheduleGroupDialog.nosched = this.$_.uniqBy(d, 'id')
                        this.rescheduleGroupDialog.changeNoSched = false
                        this.rescheduleGroupDialog.rescheduleGroups = []
                        this.rescheduleGroupDialog.date = this.$dayjs().add(1, 'd').format('YYYY-MM-DD')
                        this.rescheduleGroupDialog.show=true
                    })
            },
            doReschedule() {
                let recs = this.rescheduleGroupDialog.rescheduleGroups
                let hasId = false
                let arr = recs.map(r=>{
                    if (r.schedule_date_id) hasId = true
                    return {
                        id: r.schedule_date_id,
                        occurrence: this.rescheduleGroupDialog.date,
                        occurrence_start_time: r.occurrence_date_occurrence_start_time,
                        occurrence_end_time: r.occurrence_date_occurrence_end_time,
                        schedule_id: r.intervention_plan_intervention_schedule_id
                    }
                })

                let type = this.rescheduleGroupDialog.type

                if (!hasId) type = 'create'

                this.$setLoading(true)
                this.$axios.post('scheduleDate.php?action='+type, { schedule_date: arr })
                    .then(r => this.$ecResponse(r))
                    .finally(() => { this.loadGroups() })
            },
            doQuick() {
                let arr = []
                this.filteredItems.forEach(item => {
                    // set the duration
                    item.student_intervention_occurrence_attendance_type_id = this.quickDialog.rec.attendanceId.id

                    if (this.quickDialog.rec.attendanceId.count_toward_fidelity) {
                        let dur = this.quickDialog.rec.recommendedDuration ? item.intervention_plan_session_duration : this.quickDialog.rec.duration
                        item.student_intervention_occurrence_duration = dur * 60
                    } else {
                        item.student_intervention_occurrence_duration = 0
                    }
                    // set the procedures
                    if (this.quickDialog.rec.complete)
                        item.procedures.forEach(proc => proc.student_intervention_occurrence_procedure_completed = true)

                    arr.push(this.getSubmitObject(item))
                })

                this.$setLoading(true)
                this.$axios.post('studentInterventionOccurrences.php?action=update', {
                    occurrences: arr
                }).then(response => {
                    this.$ecResponse(response)
                }).finally(() => {
                    this.$setLoading(false)
                    this.loadGroups()
                })
            },
            selectAll(item) {
                item.procedures.forEach(p=>p.student_intervention_occurrence_procedure_completed=true)
                this.setProcedure(item)
            },
            getImageUrl(studentId) {
                return this.$axios.defaults.baseURL + this.$models.getUrl('studentImage', 'read') + '&id=' + studentId
            },
            loadGroups() {
                let queryUser = this.selectedUser.included.length ? this.selectedUser.included[0] : this.sessionUser.user
                let p = {
                    schedule_date_occurrence: this.selectedDate,
                    user_id: queryUser.id,
                    include_fidelity: 1,
                    property: 'student_intervention_schedule'
                }

                this.$setLoading(true)
                return this.$axios.get('interventionView.php?action=get&'+this.$objectToParams(p))
                    .then(response => {
                        // showProgressMonitoring has to be set before the set on to $data.items or it won't react
                        let arr = this.$ecResponse(response, 'schedules')
                        this.items=arr
                        this.loadParticipationRate(this.items)
                        this.students = this.items.map(r=> { return {student_id: r.student_id, student_full_name: r.student_full_name }})
                        this.groups = this.$_.uniqBy(this.items, 'intervention_plan_id')
                    })
                    .finally(() => { this.$setLoading(false) })
            },
            getSubmitObject(item) {
                return {
                    student_intervention_plan_id: item.student_intervention_plan_id,
                    intervention_plan_id: item.intervention_plan_id,
                    schedule_date_id: item.schedule_date_id,
                    student_intervention_occurrence_id: item.student_intervention_occurrence_id,
                    student_intervention_occurrence_duration: item.student_intervention_occurrence_duration,
                    student_intervention_occurrence_attendance_type_id: item.student_intervention_occurrence_attendance_type_id,
                    student_intervention_occurrence_attendance_type_name: item.student_intervention_occurrence_attendance_type_name,
                    procedures: item.procedures.map(r => { return { intervention_type_procedure_id: r.intervention_type_procedure_id, completed: r.student_intervention_occurrence_procedure_completed }})
                }
            },
            setProcedure(item, proc, toggle) {
                if (item.student_intervention_occurrence_duration_formatted) {
                    item.student_intervention_occurrence_duration = item.student_intervention_occurrence_duration_formatted*60 // adjusted as db saves seconds
                }

                if (!item.student_intervention_occurrence_attendance_type_id) {
                    this.$snackbars.$emit('new', {text: 'Attendance status required', usage: 'warning'})
                    return
                }

                if (toggle === true || toggle === false) {
                    item.procedures.forEach(r => r.student_intervention_occurrence_procedure_completed = toggle)
                } else if (proc) {
                    proc.student_intervention_occurrence_procedure_completed = !proc.student_intervention_occurrence_procedure_completed
                }

                let o = this.getSubmitObject(item)

                this.$axios.post('studentInterventionOccurrences.php?action=update', {
                    occurrences: [o]
                }).then(response => {
                    this.$ecResponse(response)
                    this.loadParticipationRate(this.items)
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.procedure-toggle {
    background: white;
    border: 1px solid var(--fe-selected);
    padding: 8px;
    min-width: 100px;
    text-align: center;
    margin: 2px 8px 8px 2px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background: var(--fe-accent) !important;
    }

    &-selected {
        background: var(--fe-accent) !important;
    }
}

.participation-card {
    background: white;
    border-radius: 5px;
    border: 1px  solid #e6e6e6;
}

.date-filter {
    background: #fff !important;
    border: 1px solid #e6e6e6;
    font-size: 14px;
    height: 36px;
    margin-top: 7px;

    &:hover {
        background: var(--fe-accent) !important;
    }
}
</style>
