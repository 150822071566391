<template>
    <v-form ref="form" v-model="valid">
        <v-layout row wrap my-2 v-if="this.model">
            <v-flex xs12 class="px-1">
                <fe-label content="Based On"/>
                <v-select
                    v-model="model.type"
                    :items="types"
                    :rules="requiredRules"
                    flat solo dense
                    required
                />
            </v-flex>

            <v-flex xs12 class="px-1" v-if="model.type === 'DAY' || model.type ==='STUDENTPROFILE'">
                <fe-label content="Including"/>
                <v-select
                    v-model="model.day_type"
                    item-value="value"
                    item-text="text"
                    :items="dayTypes"
                    :rules="requiredRules"
                    flat solo dense
                    required
                />
            </v-flex>

            <v-flex xs6 class="px-1" v-if="model.type != 'STUDENTPROFILE'">
                <fe-label content="Starting Day"/>
                <fe-date-picker
                    dateFormat="MM/DD/YYYY"
                    :value="startDate"
                    :emitWhenValid="true"
                    :rules="startDateRules"
                    :showYear="false"
                    flat solo dense
                    @input="v => startDate = v"
                />
            </v-flex>

            <v-flex xs6 class="px-1" v-if="model.type != 'STUDENTPROFILE'">
                <fe-label content="Ending Day"/>
                <fe-date-picker
                    dateFormat="MM/DD/YYYY"
                    :value="endDate"
                    :emitWhenValid="true"
                    :rules="endDateRules"
                    :showYear="false"
                    flat solo dense
                    @input="v => endDate = v"
                />
            </v-flex>

            <v-flex xs12 class="pa-1">
                <FieldGroup groupTitle="Threshold Statement">
                    <fe-label content="Within a(n)" v-if="model.type != 'STUDENTPROFILE'"/>
                    <v-autocomplete
                        v-if="model.type != 'STUDENTPROFILE'"
                        v-model="model.day_qty"
                        placeholder="Any"
                        :items="dayQtyItems"
                        flat solo dense
                    />

                    <fe-label content="day period, that" v-if="model.type != 'STUDENTPROFILE'"/>
                    <v-select
                        v-if="model.type === 'DAY'"
                        v-model="model.value_type_cd"
                        item-value="value"
                        item-text="text"
                        :items="cdValueTypes"
                        :rules="requiredRules"
                        flat solo dense
                        required
                    />

                    <v-select
                        v-if="model.type === 'PERIOD'"
                        v-model="model.same_period_flag"
                        item-value="value"
                        item-text="text"
                        :items="periodFlags"
                        :rules="periodFlagsRules"
                        flat solo dense
                        required
                    />

                    <fe-label content="will be" v-if="model.type != 'STUDENTPROFILE'"/>
                    <fe-label content="Attendance will be" v-if="model.type === 'STUDENTPROFILE'"/>
                    <v-select
                        v-model="model.operator_cd"
                        item-value="value"
                        item-text="text"
                        :items="cdOperators"
                        :rules="requiredRules"
                        flat solo dense
                        required
                    />

                    <v-text-field
                        v-model="model.value"
                        type="number"
                        :rules="numberRules"
                        :input="() => $refs.form.validate()"
                        flat solo dense
                        required
                    />

                    <span v-if="model.operator_cd === 'BETWEEN'">
                        <fe-label content="and"/>
                        <v-text-field
                            v-model="model.secondary_value"
                            type="number"
                            :rules="numberRules"
                            flat solo dense
                            required
                        />
                    </span>
                </FieldGroup>
            </v-flex>

            <v-flex
                xs12
                class="px-1"
                v-if="model.type != 'STUDENTPROFILE'"
                v-for="list in selectionTypes"
                :key="list"
            >
                <FieldGroup :groupTitle="`${capitalize(list)} List`">
                    <StoreSelector
                        label="Selections"
                        keyProp="id"
                        labelProp="name"
                        :storeNamespace="`thresholds/attendance${capitalize(list)}`"
                        :selectedKeys="selectionValues[list]"
                        :autofocus="false"
                        multiple
                        untruncated
                        @updates="v => updateSelections(list, v)"
                    />
                </FieldGroup>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import axios from 'axios'
import Api from '../services/Api'
import StoreSelector from '../controls/StoreSelector'
import FieldGroup from '../templates/FieldGroup'

export default {
    name: 'AttendanceMetricFields',

    components: {
        StoreSelector,
        FieldGroup
    },

    props: {
        obj: Object
    },

    data() {
        return {
            valid: false,
            endpoint: 'attendanceThreshold.php',
            model: {
                type: 'DAY',
                day_qty: 30,
                day_type: "EQUIV",
                end_day: "0801",
                operator_cd: "GREATER",
                reset_day_flag: 0,
                same_period_flag: 0,
                secondary_value: null,
                start_day: "0701",
                value: '2.000',
                value_type_cd: 'COUNT'
            },
            startDateFull: null,
            endDateFull: null,
            types: [
                {value: 'DAY', text: 'Day'},
                {value: 'PERIOD', text: 'Period'},
                {value: 'STUDENTPROFILE', text: 'Student Profile Attendance'}
            ],
            dayTypes: [
                {value: 'EQUIV', text: 'SIS Reported Rate'},
                {value: 'AFFECTED', text: 'Full Days'}
            ],
            cdValueTypes: [
                {value: 'RATE', text: 'Attendance Rate'},
                {value: 'COUNT', text: 'Monitored Count'}
            ],
            cdOperators: [
                {value: 'GREATER', text: 'Greater Than'},
                {value: 'GREATER=', text: 'Greater Than or Equal to'},
                {value: 'LESS', text: 'Less Than'},
                {value: 'LESS=', text: 'Less Than or Equal to'},
                {value: 'BETWEEN', text: 'Between'},
                {value: 'EQUAL', text: 'Equal To'}
            ],
            periodFlags: [
                {value: 1, text: 'the same'},
                {value: 0, text: 'any'}
            ],
            selections: {
                reason: [],
                excused: [],
                status: []
            },
            requiredRules: [
                v => !!v || 'Selection is required'
            ],
            periodFlagsRules: [
                v => this.periodFlags.map(x => x.value).includes(v) || 'Selection is required'
            ],
            startDateRules: [
                v => {
                    let msg = !v
                        ? 'Required with End Date'
                        : 'Requires an End Date'
                    return (!!v === !!this.model.end_day) || msg
                }
            ],
            endDateRules: [
                v => {
                    let msg = !v
                        ? 'Required with Start Date'
                        : 'Requires a Start Date'
                    return (!!v === !!this.model.start_day) || msg
                }
            ]
        }
    },

    computed: {
        isValid() {
            return this.$refs.form.validate()
        },

        saveData() {
            return this.model
        },

        selectionTypes() {
            return Object.keys(this.selections)
        },

        selectionValues() {
            let obj = {}
            this.selectionTypes.forEach(x => {
                obj[x] = this.selections[x].map(sel => sel[`attendance_${x}_id`])
            })
            return obj
        },

        dayQtyItems() {
            let result = [{text: 'Any', value: null}]
            for (let i = 0; i < 365; i++) {
                result.push({text: `${i + 1}`, value: i + 1})
            }
            return result
        },

        numberRules() {
            let type = this.model.value_type_cd

            let rules = [v => (!!v && !isNaN(v)) || 'Value is required']

            if (type === 'COUNT') {
                rules.push(v => parseFloat(v) >= 1 || 'Value must be 1 or more')
            } else {
                rules.push(v => parseFloat(v) >= 0 || 'Value must be 0 or more')
            }

            rules.push(v => parseFloat(v) <= 1000 || 'Value must be 1000 or less')

            return rules
        },

        startDate: {
            get() {
                return this.startDateFull
            },
            set(val) {
                this.startDateFull = val
                this.model.start_day = val
                    ? this.getFormattedDate(val)
                    : null
                if (this.$refs.form) this.$refs.form.validate()
            }
        },

        endDate: {
            get() {
                return this.endDateFull
            },
            set(val) {
                this.endDateFull = val
                this.model.end_day = val
                    ? this.getFormattedDate(val)
                    : null
                if (this.$refs.form) this.$refs.form.validate()
            }
        }
    },

    watch: {
        obj: function () {
            this.setModel()
        },

        'model.type': function (v) {
            if (v === 'DAY') {
                this.model.same_period_flag = 0
            } else if (v === 'PERIOD') {
                this.model.value_type_cd = 'COUNT'
            }
        }
    },

    mounted() {
        this.setModel()
    },

    methods: {
        determineFullDate(formatted) {
            let changeMonth = 7 // This is when we change to new school year
            let now = new Date()
            let currentMonth = now.getMonth() + 1
            let currentYear = now.getFullYear()
            let m = formatted.substring(0, 2)
            let d = formatted.substring(2, 4)
            let y = currentYear
            // Determine if we need to add or subtract from the current year
            // depending on where we are in the school year.
            if (parseInt(m) >= changeMonth && currentMonth < changeMonth) y--
            if (parseInt(m) < changeMonth && currentMonth >= changeMonth) y++
            return new Date(`${m}/${d}/${y}`).toISOString().substring(0, 10)
        },

        getFormattedDate(full) {
            let date = new Date(full)
            let m = `0${date.getUTCMonth() + 1}`.slice(-2)
            let d = `0${date.getUTCDate()}`.slice(-2)
            return `${m}${d}`
        },

        setModel() {
            Object.keys(this.model).forEach(k => {
                if (this.obj[k] === 0) {
                    this.model[k] = this.obj[k]
                } else {
                    this.model[k] = this.obj[k] || null
                }
            })
            if (this.model.start_day) this.startDateFull = this.determineFullDate(this.model.start_day)
            if (this.model.end_day) this.endDateFull = this.determineFullDate(this.model.end_day)
            this.refreshAllSelections()
        },

        refreshAllSelections() {
            this.selectionTypes.forEach(itm => {
                this.refreshSelections(itm)
            })
        },

        refreshSelections(list) {
            Api().get(this.endpoint, {
                params: {
                    attendance_threshold_id: this.obj.id,
                    action: `get_${list}`
                }
            }).then((rsp) => {
                this.selections[list] = (rsp.data && rsp.data[list])
                    ? rsp.data[list]
                    : []
            })
        },

        updateSelections(list, updated) {
            let requests = []

            let changes = {
                deletions: this.selections[list].filter(v => !updated.includes(v[`attendance_${list}_id`])),
                additions: updated.filter(v => !this.selectionValues[list].includes(v)).map(v => {
                    return {
                        attendance_threshold_id: this.obj.id,
                        [`attendance_${list}_id`]: v
                    }
                })
            }

            if (changes.deletions.length > 0) {
                requests.push(Api().post(this.endpoint, {
                    [list]: changes.deletions
                }, {
                    params: {action: `remove_${list}`}
                }))
            }

            if (changes.additions.length > 0) {
                requests.push(Api().post(this.endpoint, {
                    [list]: changes.additions
                }, {
                    params: {action: `add_${list}`}
                }))
            }

            if (requests.length > 0) {
                axios.all(requests).then(() => {
                    this.refreshSelections(list)
                })
            }
        },

        capitalize(str) {
            return str.toLowerCase().split(' ').map(function (word) {
                return (word.charAt(0).toUpperCase() + word.slice(1));
            }).join(' ');
        }
    }
}
</script>
