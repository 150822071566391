<template>
    <div style="background: #f6f6f6; overflow: hidden;" class="pa-5 d-flex flex-fill flex-column" v-resize="resize">
        <div class="d-flex">
            <div v-ripple class="nav-option" :class="{active: view===0}" @click="view=0">Data Searches</div>
            <div v-ripple class="nav-option" :class="{active: view===1}" @click="view=1">Saved Searches</div>
            <!-- <div v-ripple class="nav-option" :class="{active: view===2}" @click="view=2">Shared Searches</div> -->
        </div>

        <v-slide-x-transition mode="out-in">
            <v-row v-if="view==0" style="overflow: scroll;" :key="`view`+view">
                <v-col :cols="cols" v-for="(c,i) in cards.filter(r=>!r.hidden)" :key="`card-`+i">
                    <v-card height="250" class="elevation-0" style="border: 1px solid #E0E1E6;" @click="c.handler">
                        <v-card-text>
                            <div class="text-center pa-10">
                                <v-icon v-if="c.icon.length > 1" color="#9297A6" :style="{'font-size': ic===0 ? '28px' : '24px'} " v-for="(icon, ic) in c.icon" :key="`icon`+ic" class="mr-1">{{icon}}</v-icon>
                                <v-icon v-else color="#9297A6" style="font-size: 40px;" v-for="(icon, ic) in c.icon" :key="`icon`+ic" class="mr-1">{{icon}}</v-icon>

                                <div class="fs18 mt-10  ">{{c.title}}</div>
                                <div class="fs14 mt-2">{{c.description}}</div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row v-if="view==1" class="fill-height" :key="`view`+view">
                <saved-searches/>
            </v-row>
        </v-slide-x-transition>

        <!-- steppers -->
        <v-dialog v-model="dialog" width="90%" persistent>
            <district-stepper v-if="dialog && stepper=='district'" @close="dialog=false" @apply="doBenchmarkChart(...arguments, 'District')"/>
            <grade-stepper v-if="dialog && stepper=='grade'" cohort @close="dialog=false" @apply="doBenchmarkChart(...arguments, 'Grade')"/>
            <grade-stepper v-if="dialog && stepper=='grade-datawall'" title="Custom Grade Data Wall" incidentTypeOnly requireSubcategory multipleGrades cohort @close="dialog=false" @apply="doDataWall(...arguments, 'Grade Data Wall', 'Grade')"/>
            <course-stepper
                v-if="dialog && stepper=='course'"
                @close="dialog=false" @apply="doBenchmarkChart(...arguments, 'Class')"
            />
            <course-stepper v-if="dialog && stepper=='course-datawall'" title="Custom Class Data Wall" incidentTypeOnly requireSubcategory @close="dialog=false" @apply="doDataWall(...arguments, 'Class Data Wall', 'Class')"/>
            <scatter-landing v-if="dialog && stepper=='scatter'" @close="dialog=false" @apply="() => {}"/>
            <longitudinal-landing v-if="dialog && stepper=='longitudinal'" @close="dialog=false" @apply="() => {}"/>
            <program-evaluation-stepper v-if="dialog && stepper=='program-eval'" @close="dialog=false" @apply="() => {}"/>
        </v-dialog>
    </div>
</template>

<script>
    import * as Util from './Util'
    import * as AdvancedFiltersUtil from '@/components/common/util/AdvancedFilters'
    import DistrictStepper from './DistrictStepper'
    import GradeStepper from './GradeStepper'
    import CourseStepper from './CourseStepper'
    import SavedSearches from './SavedSearches'
    import ScatterLanding from './ScatterLanding'
    import Charting from '@/components/modules/summary/Charting'
    import ProgramEvaluationStepper from './ProgramEvaluationStepper'
    import LongitudinalLanding from './LongitudinalLanding'

    export default {
        name: 'Analytics',

        components: {
            DistrictStepper,
            GradeStepper,
            CourseStepper,
            SavedSearches,
            ScatterLanding,
            LongitudinalLanding,
            ProgramEvaluationStepper
        },

        data() {
            let me = this
            return {
                cols: 3,
                view: 0,
                title: '',
                dialog: false,
                stepper: '',
                params: {},
                cards: [{
                    icon: ['fal fa-chart-bar'],
                    title: 'District Search',
                    description: 'What\'s my data across schools and grades?',
                    handler() {
                        me.dialog=true
                        me.stepper='district'
                    },
                    hidden: !this.$hasSecurity('VIEW_ALL_SCHOOLS')
                }, {
                    icon: ['fal fa-chart-bar', 'fal fa-table'],
                    title: 'Grade Search',
                    description: 'How are my students performing across multiple measures for a single grade?',
                    handler() {
                        me.dialog=true
                        me.stepper='grade'
                    }
                }, {
                    icon: ['fal fa-chart-bar', 'fal fa-table'],
                    title: 'Class Search',
                    description: 'How are my students performing across multiple measures for a single class?',
                    handler() {
                        me.dialog=true
                        me.stepper='course'
                    }
                }, {
                    icon: ['fal fa-table'],
                    title: 'Custom Grade Data Wall',
                    description: 'Create a customized Data Wall based on student grade levels',
                    handler() {
                        me.dialog=true
                        me.stepper='grade-datawall'
                    }
                }, {
                    icon: ['fal fa-table'],
                    title: 'Custom Class Data Wall',
                    description: 'Create a customized Data Wall based on student class assignments',
                    handler() {
                        me.dialog=true
                        me.stepper='course-datawall'
                    }
                }, {
                    icon: ['fal fa-chart-scatter'],
                    title: 'Scatter Plot',
                    description: 'How are my measures related?',
                    handler() {
                        me.dialog=true
                        me.stepper='scatter'
                    }
                }, {
                    icon: ['fal fa-chart-line'],
                    title: 'Longitudinal Chart',
                    description: 'How are students performing over time?',
                    handler() {
                        me.dialog=true
                        me.stepper='longitudinal'
                    }
                }, {
                    icon: ['fal fa-chart-bar'],
                    title: 'Program Evaluation',
                    description: 'How are students performing on a single measure over time?',
                    handler() {
                        me.dialog=true
                        me.stepper='program-eval'
                    },
                    hidden: !this.$hasSecurity('PROGRAM_EVALUATION_DASHBOARD'),
                }]
            }
        },

        computed: {
            formValid() {
                return false
            }
        },

        methods: {
            resize() {
                let w = window.innerWidth
                if (w <= 800) {
                    this.cols =  12
                } else if (w <= 1200) {
                    this.cols = 6
                } else {
                    this.cols = 4
                }
            },
            doBenchmarkChart(opts, state, scope) {
                Util.doBenchmarkChart(this, opts, scope, null, { ...state, isDirty: true })
                this.dialog=false
            },
            doDataWall(opts, state, title, scope) {
                Util.doDataWall(this, opts, title, scope, null, { ...state, isDirty: true })
                this.dialog=false
            },
            emit() {

            }
        },
    }
</script>

<style lang="scss" scoped>
.nav-option {
    color: #006C90;
    font-size: 14px;
    margin: 4px 16px 4px 16px;
    padding: 4px 4px 4px 4px;
    cursor: pointer;

    &.active {
        border-bottom: 3px solid #006C90;
        border-radius: 1px;
    }
}
</style>
