<template>
    <div
        class="pa-2 wrapper"
        :class="{'with-border': !collectionChart}"
        :style="collectionChart ? 'margin: 5px' : ''"
    >
        <div class="chart-header" :class="{'collection': collectionChart}">
            <div v-if="collectionChart" class="pa-1 d-flex collection-title">
                {{ pinTitle }}
            </div>

            <div v-else class="pa-1 d-flex title-select">
                <fe-title-select
                    v-model="selected"
                    fontSize="16px"
                    itemText="titleSelect"
                    itemValue="student_monitor_plan_id"
                    :items="plans"
                />
            </div>

            <div v-if="!collectionChart" class="tools">
                <fe-icon-btn
                    useIcon="fas fa-thumbtack"
                    small
                    @click="pinDialog.show=true"
                />
            </div>

            <div v-else class="tools d-flex">
                <fe-icon-btn
                    v-if="collectionChart"
                    useIcon="far fa-trash"
                    usage="ghost"
                    small
                    @click="$emit('delete')"
                />

                <menu-btn class="pa-2">
                    <v-list-item v-if="this.modifyFields.length" @click="showModifyDialog = true">
                        <i class="far fa-cog fe-icon print-icon"/>
                        <span class="ml-6">Update Pin</span>
                    </v-list-item>

                    <v-list-item @click="doRename">
                        <i class="far fa-edit fe-icon print-icon"/>
                        <span class="ml-6">Rename Pin</span>
                    </v-list-item>
                </menu-btn>
            </div>

            <div v-if="!collectionChart" class="d-flex">
                <fe-filter-btn
                    v-model="filterDates"
                    :title="dateDisplay||'Date Range'"
                    :disabled="quickRange.included.length > 0"
                >
                    <functional-calendar
                        v-model="dateRange"
                        class="lean-calendar"
                        dateFormat="mm/dd/yyyy"
                        is-date-range
                    />
                    <v-btn text color="secondary" @click="clearDates">Clear</v-btn>
                </fe-filter-btn>

                <fe-filter-btn
                    v-model="quickRange"
                    title="Quick Ranges"
                    :items="quickRanges"
                    :multiple="false"
                />

                <v-spacer/>

                <fe-tooltip tooltip="Print Progress Monitoring Charts">
                    <fe-icon-btn useIcon="far fa-print" @click="printing=true"/>
                </fe-tooltip>
            </div>
        </div>

        <pin-dialog v-model="pinDialog.show" :pinParams="pinParams" :title="pinTitle"/>

        <v-tabs v-if="ready" v-model="activeTab" height="24" class="ec-tabs">
            <v-tab v-if="includeSummary" class="small-tab">Summary</v-tab>
            <v-tab v-for="c in panels" class="small-tab">{{ c.title }}</v-tab>

            <v-tab-item v-if="includeSummary">
                <behavior-monitor-summary
                    v-if="selected.student_id"
                    :params="summaryParams"
                    :key="`mon-sum-`+selected.student_monitor_plan_id"
                    :printing="printing"
                    @monitorSummaryCharts="updateMonitorSummaryCharts"
                />
            </v-tab-item>

            <v-tab-item v-for="c in panels">
                <chart-panel>
                    <template #tools v-if="!collectionChart">
                        <fe-remote-combo
                            v-if="c.charts.length > 1"
                            v-model="c.showChart"
                            :items="c.options"
                        />

                        <menu-btn style="padding-top: 7px;">
                            <v-list-item @click="enterScores">
                                Enter Scores
                            </v-list-item>
                            <v-list-item @click="showGoalDialog = true">
                                Update Goal
                            </v-list-item>
                        </menu-btn>
                    </template>

                    <template v-if="!noData">
                        <template v-for="(chart, i) in c.charts">
                            <highcharts
                                v-if="c.charts.length===1 || c.showChart===chart.title.text"
                                class="fe-chart"
                                :ref="`bpm-chart-${i}`"
                                :key="`bpm-chart-${i}`"
                                :options="chart"
                            />
                        </template>
                    </template>

                    <div v-else class="fs24 text-center" style="padding-top: 100px;">
                        No data has been collected
                    </div>
                </chart-panel>
            </v-tab-item>
        </v-tabs>

        <fe-dialog
            v-if="addGoalDialog.show"
            title="Add Goal"
            dismissButtonText="Cancel"
            acceptButtonText="Save"
            :acceptButtonDisabled="!addGoalDialog.startDate || !addGoalDialog.endDate || !addGoalDialog.goal"
            persistent
            @accept="addGoal"
            @close="addGoalDialog.show=false"
        >
            <div>
                <fe-date-picker
                    v-model="addGoalDialog.startDate"
                    label="From Date"
                    class="flex-grow-1 mr-2"
                    prependIcon="fa-calendar-alt"
                    appendIconClosed="fa-caret-down"
                    appendIconOpen="fa-caret-up"
                    dateFormat="MM/DD/YYYY"
                    :rules="$fieldValidators('text', 'From Date', { required: true })"
                />

                <fe-date-picker
                    v-model="addGoalDialog.endDate"
                    label="To Date"
                    class="flex-grow-1 mr-2"
                    prependIcon="fa-calendar-alt"
                    appendIconClosed="fa-caret-down"
                    appendIconOpen="fa-caret-up"
                    dateFormat="MM/DD/YYYY"
                    :min="addGoalDialog.startDate"
                    :rules="$fieldValidators('text', 'To Date', { required: true })"
                />

                <fe-text-field
                    v-model="addGoalDialog.goal"
                    label="Goal"
                    type="number"
                    width="100%"
                    placeholder="80"
                    :rules="this.$fieldValidators('number', 'Score', {required: true, min: 1, max: 100})"
                />
            </div>
        </fe-dialog>

        <fe-dialog
            v-if="showGoalDialog"
            title="Update Goals"
            width="600px"
            height="400px"
            acceptButtonText="Done"
            @close="showGoalDialog=false"
            @dismiss="showGoalDialog=false"
            @accept="showGoalDialog=false"
        >
            <fe-grid
                ref="goal"
                displayDensity="small"
                style="height: 100%;"
                stopEditingWhenCellsLoseFocus
                :columnDefs="goalColDefs"
                :rowData="monitorGoals"
                :showAddRowBtn="false"
                :showToolbarMenu="false"
                :showDownload="false"
                :searchBar="false"
                @cellValueChanged="updateGoal"
            >
                <template v-slot:toolbar-items>
                    <div class="d-flex align-center">
                        <span class="text-smaller mr-4">Default goal: 80</span>
                        <fe-btn usage="primary" @click="openGoalDialog">Add Goal</fe-btn>
                    </div>
                </template>
            </fe-grid>
        </fe-dialog>

        <fe-dialog
            v-if="showModifyDialog"
            title="Modify Saved Search"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            width="500"
            persistent
            @accept="showModifyDialog = false; modifySavedSearch()"
            @dismiss="showModifyDialog = false"
        >
            <v-layout row wrap>
                <div v-if="modifyFields.includes('SCHOOL_YEAR_ID')" class="d-flex align-center mb-2">
                    <span style="width: 100px">School Year: </span>
                    <fe-remote-exclude-combo
                        v-model="modifyParams.SCHOOL_YEAR_ID"
                        style="width: 290px"
                        rootProperty="years"
                        itemText="name"
                        :url="$models.getUrl('schoolYear', 'read')"
                        hide-details
                        lazyLoad
                    />
                </div>
                <div class="d-flex align-center mb-2" v-if="modifyFields.includes('SCHOOL_ID')">
                    <span style="width: 100px">School: </span>
                    <fe-remote-exclude-combo
                        v-model="modifyParams.SCHOOL_ID"
                        style="width: 290px"
                        rootProperty="schools"
                        :url="$models.getUrl('school', 'read')"
                        hide-details
                        lazyLoad
                    />
                </div>
                <div class="d-flex align-center mb-2" v-if="modifyFields.includes('GRADE_ID')">
                    <span style="width: 100px">Grade: </span>
                    <fe-remote-exclude-combo
                        v-model="modifyParams.GRADE_ID"
                        style="width: 290px"
                        rootProperty="grades"
                        :url="$models.getUrl('grade', 'read')"
                        hide-details
                        lazyLoad
                    />
                </div>
                <div class="d-flex align-center mb-2" v-if="modifyFields.includes('COHORT_SCHOOL_YEAR_ID')">
                    <span style="width: 100px">Cohort School Year: </span>
                    <fe-remote-exclude-combo
                        v-model="modifyParams.COHORT_SCHOOL_YEAR_ID"
                        style="width: 290px"
                        rootProperty="years"
                        :url="$models.getUrl('schoolYear', 'read')"
                        hide-details
                        lazyLoad
                    />
                </div>
                <div class="d-flex align-center mb-2" v-if="modifyFields.includes('GENDER')">
                    <span style="width: 100px">Gender: </span>
                    <fe-remote-exclude-combo
                        v-model="modifyParams.GENDER"
                        style="width: 290px"
                        rootProperty="genders"
                        url="filters.php?action=get&filter=gender"
                        itemText="gender"
                        itemValue="gender"
                        hide-details
                        lazyLoad
                    />
                </div>
            </v-layout>
        </fe-dialog>

        <fe-dialog
            v-if="showRenameDialog"
            title="Rename Chart"
            acceptButtonText="Apply"
            dismissButtonText="Cancel"
            persistent
            @accept="showRenameDialog = false; renamePin()"
            @dismiss="showRenameDialog = false"
        >
            <v-layout row>
                <div class="d-flex align-center">
                    <span>Rename:</span>
                    <fe-text-field v-model="newPinName" class="ml-2"></fe-text-field>
                </div>
            </v-layout>
        </fe-dialog>

        <fe-crud
            v-if="selected.student_monitor_plan_id"
            ref="monitorGoals"
            :config="goalsCrudConfig"
            autoload
            @read="monitorGoals = $event"
        />

        <!-- hidden section for printing all charts (including ones from other tabs) -->
        <div v-show="false" id="apprintview">
            <template v-for="chart in allCharts">
                <highcharts
                    class="fe-chart"
                    :options="chart"
                />
            </template>
        </div>
    </div>
</template>

<script>
import ChartPanel from './ChartPanel'
import MenuBtn from '@/components/common/button/MenuBtn'
import PinDialog from '@/components/common/PinDialog'
import BehaviorMonitorSummary from './BehaviorMonitorSummary'
import PointSheetMonitoring from '@/components/modules/intervention/PointSheetMonitoring'
import {FunctionalCalendar} from 'vue-functional-calendar'

export default {
    name: 'BehaviorProgressMonitoring',

    components: {
        ChartPanel,
        MenuBtn,
        PinDialog,
        BehaviorMonitorSummary,
        PointSheetMonitoring,
        FunctionalCalendar,
    },

    props: {
        params: {
            required: true,
            type: Object
        },
        height: {
            default: 330
        },
        collectionChart: {
            type: Boolean,
            default: false
        },
        includeSummary: {
            type: Boolean,
            default: false
        },
        pinTitle: {
            default: ''
        }
    },

    data() {
        return {
            ready: false,
            panels: [],
            activeTab: 0,
            colors: {
                ODR: 'red',
                Goal: '#408AAF',
                Achieved: '#19ADAB'
            },
            pinDialog: {
                show: false
            },
            showGoalDialog: false,
            addGoalDialog: {
                show: false,
                startDate: null,
                endDate: null,
                goal: null,
            },
            monitorGoals: [],
            goalColDefs: [{
                headerName: 'From Date',
                field: 'start_date',
                sortable: true,
                editable: true,
                cellEditorFramework: 'FeGridDatePicker',
            }, {
                headerName: 'To Date',
                field: 'end_date',
                sortable: true,
                editable: true,
                cellEditorFramework: 'FeGridDatePicker',
            }, {
                headerName: 'Goal',
                field: 'goal',
                width: 90,
                sortable: true,
                editable: true,
                cellEditorFramework: "FeGridTextField",
                cellRenderer(v) {
                    return parseInt(v.value)
                },
            }, {
                headerName: '',
                field: 'delete',
                width: 70,
                cellRenderer() {
                    return '<i class="fas fa-trash" style="margin-left: 15px; font-size: 12px"></i>'
                },
                onCellClicked: v => this.deleteGoal(v.data),
            }

            ],
            localParams: {},
            noData: true,
            newPinName: undefined,
            showRenameDialog: false,
            modifyFields: [],
            searchArguments: undefined,
            modifyParams: {},
            showModifyDialog: false,
            plans: [],
            selected: {},
            dateRange: {},
            filterDates: {
                included: []
            },
            quickRange: {
                included: []
            },
            quickRanges: [{
                id: 2,
                name: '2 Weeks'
            }, {
                id: 4,
                name: '4 Weeks'
            }, {
                id: 6,
                name: '6 Weeks'
            }, {
                id: 8,
                name: '8 Weeks'
            }],
            printing: false,
            allCharts: [],
        }
    },

    computed: {
        summaryParams() {
            return {
                student_id: this.selected.student_id,
                student_monitor_plan_id: this.selected.student_monitor_plan_id,
                start_date: this.localParams.start_date,
                end_date: this.localParams.end_date
            }
        },

        pinParams() {
            let p = {...this.localParams}
            p.dashboard_saved_search_type_id = 5
            p.student_monitor_plan_id = this.selected.student_monitor_plan_id || null
            delete p.intervention_plan_id
            return p
        },

        dateDisplay() {
            if (this.dateRange.dateRange) {
                return this.dateRange.dateRange.start + '-' + (this.dateRange.dateRange.end ? this.dateRange.dateRange.end : '')
            }
        },

        goalsCrudConfig() {
            let cfg = _.cloneDeep(this.$models.monitorGoals)
            cfg.read.params.student_monitor_plan_id = this.selected.student_monitor_plan_id
            return cfg
        }
    },

    watch: {
        selected(v) {
            if (Object.keys(v).length) this.processData()
        },

        'quickRange.included'(v) {
            if (!v || v.length === 0) {
                this.localParams.start_date = null
                this.localParams.end_date = null
                this.loadData()
                return
            }
            let r = v[0]
            let today = this.$dayjs()
            let startDate = today.clone().subtract(r.id, 'w').format('YYYY-MM-DD')

            this.localParams.end_date = today.format('YYYY-MM-DD')
            this.localParams.start_date = startDate
            this.loadData()
        },

        dateRange: {
            handler(v) {
                let count = 0
                if (v.dateRange && v.dateRange.start) {
                    count++
                    this.localParams.start_date = this.$dayjs(v.dateRange.start).format('YYYY-MM-DD')
                } else {
                    this.localParams.start_date = null
                }

                if (v.dateRange && v.dateRange.end) {
                    count++
                    this.localParams.end_date = this.$dayjs(v.dateRange.end).format('YYYY-MM-DD')
                } else {
                    this.localParams.end_date = null
                }
                if (count === 2) {
                    this.loadData()
                }
            },
            deep: true
        },

        params: {
            deep: true,
            handler(v, oldV) {
                if (v && v.student_id !== oldV.student_id) {
                    // if progress monitoring still selected and student changed,
                    // clear selected and update localParams to match new student
                    this.selected = {}
                    this.localParams = {...this.params}
                    this.loadData()
                }
            }
        }
    },

    mounted() {
        this.localParams = {...this.params}
        this.loadData()
        this.setUpdateParams()
    },

    methods: {
        clearDates() {
            this.filterDates = {included: []}
            this.dateRange = {}
            delete this.localParams.start_date
            delete this.localParams.end_date
            this.loadData()
        },

        loadData() {
            let monitorId = this.selected.student_monitor_plan_id

            this.ready = false
            this.panels = []
            this.$axios.get('studentMonitors.php?action=get&property=progress_charts&type=behavior&' + this.$objectToParams(this.localParams))
                .then(r => {
                    this.plans = this.$ecResponse(r, 'monitors')

                    this.plans.forEach(p => p.titleSelect = p.intervention_name + ' (' + this.$dayjs(p.student_monitor_plan_start_date).format('MMM Do, YY') + ' to ' + this.$dayjs(p.student_monitor_plan_end_date).format('MMM Do, YY') + ')')

                    if (monitorId) {
                        let found = this.plans.find(r => r.student_monitor_plan_id === monitorId)
                        if (found) {
                            this.selected = found
                        } else {
                            this.selected = this.plans[0]
                        }
                    } else if (this.plans.length && this.plans[0].charts.length) {
                        this.selected = this.plans[0]
                    }

                }).finally(() => {
                this.ready = true
            })
        },

        enterScores() {
            this.$axios.get('studentMonitors.php?action=get&' + this.$objectToParams(this.params))
                .then(resp => {
                    let mon = this.$ecResponse(resp, 'monitors')?.find(itm => itm.student_monitor_plan_id === this.params.student_monitor_plan_id)
                    if (mon) {
                        this.$dockableWindow({
                            component: 'point-sheet-monitoring',
                            attrs: {
                                params: {
                                    intervention_id: mon.intervention_id,
                                    student_id: mon.student_id,
                                    student_monitor_plan_id: mon.student_monitor_plan_id
                                }
                            }
                        })
                    }
                })
        },

        updateGoal(v) {
            this.$axios.post('monitorGoals.php?action=update', {monitor_goals: [v.data]})
                .then(resp => {
                    this.loadData()
                    this.$nextTick(() => {
                        this.activeTab = 1 // stay on Goal tab panel
                    })
                    this.$snackbars.$emit('new', {
                        text: resp.data.msg,
                        usage: resp.data.success ? 'success' : 'warning'
                    })
                })
        },

        deleteGoal(v) {
            this.$axios.post('monitorGoals.php?action=delete', {monitor_goals: [v]})
                .then(resp => {
                    this.loadData()
                    this.$nextTick(() => {
                        this.$refs.monitorGoals.read()
                        this.$refs.goal.resize()
                        this.activeTab = 1 // stay on Goal tab panel
                    })
                    this.$snackbars.$emit('new', {
                        text: resp.data.msg,
                        usage: resp.data.success ? 'success' : 'warning'
                    })
                })
        },

        openGoalDialog() {
            this.addGoalDialog.show = true
            this.addGoalDialog.startDate = this.$dayjs().format('YYYY-MM-DD')
            this.addGoalDialog.endDate = this.plans[0].student_monitor_plan_end_date
        },

        addGoal() {
            let obj = {
                student_monitor_plan_id: this.params.student_monitor_plan_id,
                start_date: this.addGoalDialog.startDate,
                end_date: this.addGoalDialog.endDate,
                goal: this.addGoalDialog.goal
            }
            this.$axios.post('monitorGoals.php?action=create', {monitor_goals: [obj]})
                .then(resp => {
                    this.loadData()
                    this.$nextTick(() => {
                        this.$refs.monitorGoals.read()
                        this.$refs.goal.resize()
                        this.activeTab = 1 // stay on Goal tab panel
                    })
                    this.$snackbars.$emit('new', {
                        text: resp.data.msg,
                        usage: resp.data.success ? 'success' : 'warning'
                    })
                })
        },

        processData() {
            this.panels = []
            this.configureChart(this.selected.charts[0].charts)
            this.selected.charts.forEach(chart => {
                let c = chart.charts
                let arr = []
                if (Array.isArray(c)) {
                    c.forEach(r => arr.push(this.configureChart(r)))
                } else {
                    arr.push(this.configureChart(c))
                }

                if (arr.length) {
                    this.panels.push({
                        title: chart.name,
                        charts: arr,
                        options: arr.map(r => r.title.text),
                        showChart: arr[0].title.text
                    })
                }
            })
        },

        configureChart(cfg) {
            let options = {
                chart: {
                    height: this.height
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                legend: {
                    enabled: true
                },
                yAxis: [{
                    max: 100,
                    title: {
                        text: 'Score'
                    }
                }, {
                    title: {
                        text: 'Counts'
                    },
                    opposite: true
                }],
                xAxis: {
                    type: 'category',
                    title: {
                        text: 'Occurrence'
                    },
                    labels: {
                        tickPlacement: 'on'
                    }
                },
                title: {
                    text: cfg.title,
                    align: 'left'
                },
                series: []
            }

            if (cfg.chartData.length === 0) {
                this.noData = true
            } else {
                this.noData = false
            }

            cfg.series.forEach(serie => {
                let s = {
                    name: serie.name,
                    color: this.colors[serie.name],
                    data: cfg.chartData.map(r => {
                        return {
                            name: r.occurrence,
                            record: r,
                            y: r[serie.name],
                            marker: this.getMarker(serie, r)
                        }
                    }),
                    yAxis: serie.type == 'line' ? 0 : 1,
                    type: serie.type == 'bar' ? 'column' : serie.type,
                }
                options.series.push(s)
            })
            return options
        },

        doRename() {
            this.newPinName = undefined
            this.showRenameDialog = true
        },

        renamePin() {
            if (this.newPinName) {
                let params = {
                    dashboard_id: this.params.dashboard_id,
                    saved_search_id: this.params.saved_search_id,
                    rank: 0,
                    title: this.newPinName
                }

                let url = 'dashboard.php?action=update&property=saved_search'

                this.$axios.post(url, {saved_searches: [params]}).then(response => {
                    if (response.data.success) {
                        this.$snackbars.$emit('new', {text: response.data.msg, usage: 'success'})
                        this.$store.commit('collection/set', {
                            key: 'reloadCollection',
                            value: true
                        })
                    } else {
                        this.$snackbars.$emit('new', {text: response.data.msg, usage: 'warning'})
                    }
                })
            }
        },

        setUpdateParams() {
            this.modifyFields = []
            this.modifyParams = {}
            if (this.params?.saved_search_id) {
                let url = 'savedSearch.php?action=get&property=arguments&saved_search_id=' + this.params.saved_search_id
                this.$axios.get(url).then(res => {
                    if (res.data.saved_search_arguments.length > 0) {
                        this.searchArguments = res.data.saved_search_arguments
                        res.data.saved_search_arguments.forEach(data => {
                            if (!this.modifyParams[data.param_code]) {
                                this.modifyParams[data.param_code] = []
                            }
                            switch (data.param_code) {
                                case 'GENDER':
                                    this.modifyParams['GENDER'].push({
                                        gender: data.argument_value
                                    })
                                    this.modifyFields.push(data.param_code)
                                    break;
                                case 'SCHOOL_YEAR_ID':
                                case 'COHORT_SCHOOL_YEAR_ID':
                                case 'SCHOOL_ID':
                                case 'GRADE_ID':
                                    this.modifyParams[data.param_code].push({
                                        id: parseInt(data.argument_value)
                                    })
                                    this.modifyFields.push(data.param_code)
                                    break;
                            }
                        })
                    }
                })
            }
        },

        modifySavedSearch() {
            let params = []
            this.searchArguments.forEach(p => {
                let name = p.param_code
                if (this.modifyParams[name] && this.modifyParams[name].length > 0) {
                    params.push({
                        saved_search_id: p.saved_search_id,
                        saved_search_param_id: p.param_id,
                        value: this.modifyParams[name].map(value => name === 'GENDER' ? value.gender : value.id).toString()
                    })
                } else {
                    // add old params on top of new params
                    params.push({
                        saved_search_id: p.saved_search_id,
                        saved_search_param_id: p.param_id,
                        value: p.argument_value
                    })
                }
            })
            let url = 'savedSearch.php?action=update&property=arguments&saved_search_id=' + this.config.search_id.saved_search_id

            this.$axios.post(url, {saved_search_arguments: params}).then(res => {
                if (res.data.success) {
                    this.$snackbars.$emit('new', {text: res.data.msg, usage: 'success'})
                    this.$store.commit('collection/set', {
                        key: 'reloadCollection',
                        value: true
                    })
                } else {
                    this.$snackbars.$emit('new', {text: res.data.msg, usage: 'warning'})
                }
            })
        },

        getMarker(serie, rec) {
            let detail = rec[serie.name + '-Details']
            if (!detail) return {enabled: false}
            return serie.name === 'Goal' ? {enabled: false} : {
                fillColor: detail.nodeType ? (detail.nodeType == 'open' ? 'white' : (detail.nodeType == 'none' ? '' : 'black')) : 'black',
                lineColor: detail.nodeType == 'none' ? '' : 'black',
                radius: detail.nodeType == 'none' ? 0 : 4,
                lineWidth: detail.nodeType == 'none' ? 0 : 2,
                symbol: 'circle'
            }
        },

        updateMonitorSummaryCharts(v) {
            this.allCharts = []
            this.allCharts = v
            if (v.findIndex(x => x.title.text == 'Goal') == -1) {
                let goalChart = this.panels.find(x => x.title == 'Goal')
                this.allCharts.push(goalChart.charts[0])
            }
            this.exportCharts()
        },

        exportCharts() {
            setTimeout(function () {
                var mywindow = window.open('', 'PRINT', 'height=400,width=800')
                var style = document.createElement('style')
                var landscape = '@page {size:landscape !important; -webkit-print-color-adjust: exact !important; margin: 10px;}'
                var extra = '@media print {body{-webkit-print-color-adjust: exact !important;}}'
                mywindow.document.write('<html><head>')
                mywindow.document.write('</head><body >')
                mywindow.document.write(document.getElementById('apprintview').innerHTML)
                var head = mywindow.document.head
                style.type = 'text/css'
                style.media = 'print'
                style.appendChild(document.createTextNode(landscape))
                style.appendChild(document.createTextNode(extra))
                head.appendChild(style)
                mywindow.document.write('</body></html>')
                mywindow.document.close() // necessary for IE >= 10
                mywindow.focus() // necessary for IE >= 10*/
                mywindow.print()
                mywindow.close()
                this.printing = false
                this.allCharts = []
            }.bind(this), 1500)
        }
    }
}
</script>

<style lang="scss" scoped>
.fe-chart ::v-deep * {
    font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
    color: #dddddd !important;
}

.ec-tabs {
    height: 30px !important;
}

.small-tab {
    text-transform: none;
    letter-spacing: normal;
}

.wrapper {
    height: 450px;
    position: relative;

    &.with-border {
        border: 1px solid #dddddd;
        border-radius: 4px;
    }
}

.chart-header {
    &.collection {
        height: 35px;
        border-bottom: 1px solid #e0e1e6;
    }

    .title-select {
        width: calc(100% - 120px);
    }

    .collection-title {
        width: calc(100% - 75px);
        font-weight: 100 !important;
        font-size: 14px !important;
    }
}

.tools {
    position: absolute;
    top: 0;
    right: 0;
}

.lean-calendar {
    ::v-deep .vfc-main-container {
        box-shadow: none !important;
    }

    ::v-deep .vfc-main-container {
        font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
        padding-bottom: 15px !important;
    }

    ::v-deep .vfc-top-date {
        font-size: 14px !important;
    }

    ::v-deep .vfc-arrow-right, ::v-deep .vfc-arrow-left {
        height: 10px !important;
        width: 10px !important;
    }

    ::v-deep .vfc-dayNames span {
        color: #7E8494 !important;
        margin-bottom: 0 !important;
        font-size: 13px;
    }

    ::v-deep .vfc-today:not(.vfc-marked) {
        background-color: transparent !important;
        color: #000 !important;
        border: 1px solid var(--fe-primary) !important;
    }

    ::v-deep .vfc-week {
        font-size: 13px;
    }

    ::v-deep .vfc-months-container {
        .vfc-content {
            width: 55% !important;
        }
    }

    ::v-deep .vfc-marked {
        background: #CCE2E9 !important;
    }

    ::v-deep .vfc-start-marked {
        background: #006C90 !important;
    }

    ::v-deep .vfc-end-marked {
        background: #006C90 !important;
    }
}
</style>
