<template>
    <div class="flex-fill flex-column no-scroll dashboard py-4 px-6">
        <landing-page :items="items" :urlParams="urlParams"/>
    </div>
</template>

<script>
import LandingPage from './LandingPage'
import IFrameRenderer from '@/components/common/IFrameRenderer'
import {mapState} from 'vuex'

export default {
    name: 'ActionItems',

    components: {IFrameRenderer, LandingPage},

    props: {
        urlParams: {
            type: Object
        },
        items: {
            type: Array, default: () => []
        }
    },

    data() {
        return {
            tstamp: new Date().getMilliseconds()
        }
    },

    computed: {
        ...mapState('global', ['sessionUser', 'userPreferences']),

        path() {
            return '#/ActionItems'
        },

        cfg() {
            let me = this
            let p = {...this.$props.urlParams}
            p.user_id = this.sessionUser.user.id
            return {
                path: this.path,
                params: p,
                events: {
                    'ai-load-smartform': function (rec) {
                        me.$dockableWindow({
                            name: rec.form_instance_name,
                            data: rec,
                            component: 'smart-form',
                            attrs: {
                                id: rec.form_instance_id,
                                template_id: rec.form_id
                            },
                        })
                    },
                    'action-item-close': function () {
                        me.$emit('close')
                    },
                    delete() {},
                    save(response) {
                        me.$emit('save', response)
                    }
                },
                identifier: this.tstamp
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.dashboard {
    background: #F5F6F7;
}
</style>
