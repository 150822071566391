<template>
    <div class="flex-fill flex-column meeting-agenda" style="overflow: scroll;" v-if="meeting">
        <attendees-panel :meeting="meeting" :occurrence="occurrence" />
        <agenda-panel :meeting="meeting" :occurrence="occurrence" @students="$emit('students', $event)" />
        <notes-panel :meeting="meeting" :occurrence="occurrence" />
        <action-items-panel :meeting="meeting" :occurrence="occurrence" />
    </div>
</template>

<script>
    import AttendeesPanel from './Attendees'
    import AgendaPanel from './Agenda'
    import NotesPanel from './Notes'
    import ActionItemsPanel from './ActionItems'

    export default {
        name: 'Agenda',
        props: {
            meeting: { type: Object, required: true },
            occurrence: { type: Object }
        },
        components: { AttendeesPanel, AgendaPanel, NotesPanel, ActionItemsPanel },
        data () {
            return { }
        },
        computed: { },
        methods: { },
        watch: { }
    }
</script>

<style lang="scss" scoped>
.meeting-agenda {
    overflow: visible;
    padding: 14px 20px 20px 22px;
    position: relative;
    .is-collapsed & {
        margin-top: 50px;
    }
}
</style>