<template>
    <div>
        <Landing/>

        <Toast/>
    </div>
</template>

<script>
import IFrameRenderer from '@/components/common/IFrameRenderer'
import Landing from './templates/Landing.vue';
import Toast from './templates/Toast.vue';

export default {
    name: 'Thresholds',

    components: {
        IFrameRenderer,
        Landing,
        Toast
    }
}
</script>

<style lang="scss">
@import 'base';
</style>
