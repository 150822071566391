<template>
    <div>
        <fe-dialog
            title="Export smartFORM Data"
            width="600"
            acceptButtonText="Next"
            dismissButtonText="Cancel"
            @accept="pushSftpData(); $emit('accept', params)"
            @close="$emit('close')"
            :acceptButtonDisabled="!params.school_year_id || !form || (sftp && (!server || !port || !username || (!hasExistingSftp && !password)))"
        >
            <fe-remote-combo
                label="School Year"
                :url="$models.getUrl('schoolYear', 'read')"
                rootProperty="years"
                v-model="params.school_year_id"
                byId
            />

            <fe-remote-combo
                url="form.php?property=form&action=get"
                label="Form Template"
                rootProperty="forms"
                v-model="form"
                :disabled="!params.school_year_id"
            />

            <div v-if="sftpExportEnabled">
                <div class="d-flex">
                    <fe-label>Export to SFTP site:</fe-label>
                    <v-spacer />
                    <fe-switch v-model="sftp" :disabled="!form" @input="params.sftp=$event"/>
                </div>

                <div v-if="sftp">
                    <div class="d-flex mb-2">
                        <fe-label>Server: </fe-label>
                        <v-spacer />
                        <fe-text-field v-model="server"></fe-text-field>
                    </div>
                    <div class="d-flex mb-2">
                        <fe-label>Port: </fe-label>
                        <v-spacer />
                        <fe-text-field v-model="port"></fe-text-field>
                    </div>
                    <div class="d-flex mb-2">
                        <fe-label>Username: </fe-label>
                        <v-spacer />
                        <fe-text-field v-model="username"></fe-text-field>
                    </div>
                    <div class="d-flex mb-2">
                        <fe-label>Password: </fe-label>
                        <v-spacer />
                        <fe-text-field v-model="password" type="password" :placeholder="this.hasExistingSftp ? '****************' : ''"></fe-text-field>
                    </div>
                    <div class="d-flex">
                        <fe-label>Directory: </fe-label>
                        <v-spacer />
                        <fe-text-field v-model="directory" placeholder="Optional"></fe-text-field>
                    </div>
                </div>
            </div>
        </fe-dialog>

        <fe-crud ref="formExportSFTPConfigCrud" :config="$models.formExportSFTPConfig" />
    </div>
</template>

<script>
    import {mapState} from "vuex"
    export default {
        name: 'Search',

        props: {
            value: { type: Object },
        },

        computed: {
            ...mapState('global', ['currentYear']),
            sftpExportEnabled() {
                return this.$store.getters['flags/flags']['ec_sftp_formexport'] === true
            }
        },

        data() {
            return {
                params: {
                    school_year_id: null,
                    form_id: null,
                    name: null,
                    sftp: false,
                },
                form: null,
                sftp: false,
                server: null,
                port: null,
                username: null,
                password: null,
                directory: null,
                hasExistingSftp: false,
                updateId: null,
            }
        },

        watch: {
            form(v) {
                if(v) {
                    this.params.form_id = v.id
                    this.params.name = v.name
                    this.params.sftp = false
                    this.hasExistingSftp = false
                    this.sftp = false
                    this.updateId = null
                    this.server = null
                    this.port = null
                    this.username = null
                    this.password = null
                    this.directory = null
                    this.$refs.formExportSFTPConfigCrud.read({form_id: v.id})
                        .then(resp => {
                            if(resp.data?.form_export_sftp_config.length) {
                                this.hasExistingSftp = true
                                this.sftp = true
                                this.params.sftp = true
                                this.updateId = resp.data.form_export_sftp_config[0].id
                                this.server = resp.data.form_export_sftp_config[0].server
                                this.port = resp.data.form_export_sftp_config[0].port.toString()
                                this.username = resp.data.form_export_sftp_config[0].username
                                this.password = ""
                                this.directory = resp.data.form_export_sftp_config[0].directory
                            }
                        })
                }
            }
        },

        mounted() {
            this.params.school_year_id = this.currentYear.id
            this.form = this.value?.id ? this.value.id : null
        },

        methods: {
            pushSftpData() {
                if(this.sftp) {
                    let action = 'create'
                    let p = {
                        form_id: this.params.form_id,
                        server: this.server,
                        port: this.port,
                        username: this.username,
                        directory: this.directory,
                    }
                    if(this.hasExistingSftp) {
                        action = 'update'
                        p.id = this.updateId
                        // only want to pass password if changed or new, otherwise will cause encryption errors
                        if(this.password != "") p.encrypted_pswd = this.password
                    } else {
                        p.encrypted_pswd = this.password
                    }
                    this.$refs.formExportSFTPConfigCrud[action](p)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
