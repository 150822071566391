let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    observationBehaviorTypes: {
        defaults: {
            endpoint: 'observations.php?property=observation_behavior_type',
            rootProperty: 'observation_behavior_types',
        },
        ...standardCrud
    },
    observationBehaviors: {
        defaults: {
            endpoint: 'observations.php?property=observation_behavior',
            rootProperty: 'observation_behaviors',
        },
        ...standardCrud
    },
    observationBehaviorAntecedents: {
        defaults: {
            endpoint: 'observations.php?property=antecedents',
            rootProperty: 'observation_behavior_antecedents',
        },
        ...standardCrud
    },
    observationBehaviorConsequences: {
        defaults: {
            endpoint: 'observations.php?property=consequences',
            rootProperty: 'observation_behavior_consequences',
        },
        ...standardCrud
    },
    studentObservations: {
        defaults: {
            endpoint: 'observations.php?property=student_observation',
            rootProperty: 'student_observations',
        },
        ...standardCrud
    },
    studentObservationSamplings: {
        defaults: {
            endpoint: 'observations.php?property=student_observation_sampling',
            rootProperty: 'student_observation_samplings',
        },
        ...standardCrud
    },
    studentObservationBehaviorOccurrences: {
        defaults: {
            endpoint: 'observations.php?property=student_observation_behavior_occurrence',
            rootProperty: 'student_observation_behavior_occurrences',
        },
        ...standardCrud
    },
}