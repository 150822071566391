<template>
    <div class="d-flex flex-column alt-card flex-fill panel" v-intersect="onShow">

        <div class="d-flex align-center pa-2 bborder flex-wrap">
            <span class="section-title ml-2">
                Notifications<span class="ml-2 secondary-text fs14">{{unreadCount}} unread</span>
            </span>

            <fe-btn v-if="sessionUser.district.login_id == 9000" class="ml-auto" usage="tertiary" @click="compose=true">New</fe-btn>

            <fe-text-field
                class="ma-2"
                ref="searchField"
                placeholder="Search messages"
                prependIcon="fa-search"
                width="100%"
                @change="filterText=$event"
                hide-details autofocus clearable
            />
        </div>

        <div v-if="$_.isBlank(items)" class="flex-grow-1 bborder">
            <fe-overlay v-if="loading" loader text="" color="white" />
            <fe-overlay
                v-else
                class="overlay"
                :absolute="false"
                icon="far fa-bell"
                header="No Notifications"
                text=""
                color="white"
            />
        </div>

        <div v-if="items && items.length" class="flex-grow-1 bborder" style="overflow:auto;" ref="scrollContainer">

            <div v-for="item in items" :key="item.id" class="pa-3 bborder item" @click="onClick(item)">
                <div class="d-flex align-center">
                    <div class="dot bcolor fs24"><span v-if="item.unread">•</span></div>
                    <div v-if="!item.message_type_id" class="pr-2">
                        <v-avatar size="16" rounded="0">
                            <img
                                :src="item.photo_url"
                                style="filter:invert(1); padding-bottom:3px;"
                            >
                        </v-avatar>
                    </div>
                    <div class="d-inline-flex counterpart">
                        {{item.text || item.message_type_value}}
                    </div>
                    <div class="ml-auto secondary-text fs12 flex-shrink-0 pl-2">
                        {{item.formattedCreated}}
                    </div>
                </div>
                <div class="preview py-1" v-html="item.subtext || item.value" />
            </div>

        </div>

        <div class="d-flex align action pr-4 bcolor">
            <div class="d-flex link ml-auto align-self-center" @click="markAllRead">mark all as read</div>
        </div>

        <fe-crud
            ref="crud"
            :readParams="{message_type_id:'13,14', action:'get_bell_messages'}"
            :config="$models.message"
            autoload
            @read="readCrud"
        />

        <update-notifications
            v-model="showNotifications"
            :last-date="pollData.latestUnreadNotificationDate"
            :unread-count="pollData.unreadNotificationCount"
            @read="pollData.unreadCount -= pollData.unreadNotificationCount; pollData.unreadNotificationCount=0"
        />

        <fe-dialog
            v-if="compose"
            title="New Release Note"
            width="600"
            @close="compose=false"
            :footer="false"
            :retain-focus="false"
        >
            <new-notification @cancel="compose=null" @created="compose=null"/>
        </fe-dialog>

    </div>

</template>

<script>
import UpdateNotifications from './UpdateNotifications'
import NewNotification from './NewNotification'
//
export default {
    name: 'BellDropdown',

    components: {
        UpdateNotifications,
        NewNotification
    },

    props: {
        pollData: {},
    },
    computed: {
        sessionUser: me => me.$store.state.global.sessionUser,
        items() {
            let msgs = this.filterText
                ? this.messages.filter(x => {
                    return x.formattedCreated.toLowerCase().includes(this.filterText.toLowerCase())
                        || x.value.toLowerCase().includes(this.filterText.toLowerCase())
                })
                : [...this.messages]

            msgs = msgs.sort((a,b) => b.unixTime - a.unixTime)

            let unread = this.pollData.unreadNotificationCount
            let latestUnreadNotificationDate = this.$dayjs(this.pollData.latestUnreadNotificationDate)
            msgs.unshift({
                unread: unread,
                message_type_value: unread ? `${unread} New Release Notes` : 'View Release Notes',
                value: unread ? 'Click here to view the updates' : 'Click here to view older updates',
                photo_url: '/images/educlimber_white2.png',
                formattedCreated: latestUnreadNotificationDate.format('MMM D, YYYY'),
                unixTime: latestUnreadNotificationDate.format('x'),
            })

            this.unreadCount = msgs.reduce((acc,v) => acc += v.unread,0)

            return msgs
        }
    },
    watch: {
        pollData(v) {
            // if(this.lastUnreadCount != v.unreadCount) {
                // this.$refs.crud.read()
            // }
            // this.lastUnreadCount = v.unreadCount
        },
    },
    methods: {
        itemValue(value) {
            let json
            try { json = JSON.parse(value) }
            catch(e) { json = null }
            // json = JSON.parse("{\"href\":\"https:\\/\\/qa01.educlimber.app\\/batchJob.php?action=get&db=iberville_parish_la_test&id=41\",\"batch_job_id\":41}\n")
            // return `
            // <div style="width:100%;">
            //     Your files are ready to be downloaded.
            //     <a href="${json.href}" style="text-decoration:none; margin-left:auto;">
            //         <i style="font-size:20px; color:#7E8494;" class="fas fa-arrow-alt-to-bottom"></i>
            //     </a>
            // </div>
            // `
            return json
                ? {...json, value: 'Your files are ready to be downloaded.</br>'}
                : {value}
        },
        onShow(e) {
            this.filterText = null
            this.$refs.searchField.text = null
            this.$refs.scrollContainer?.scrollTo(0,0)
            if(e?.[0].isIntersecting) this.$refs.crud.read()
        },
        readCrud(e) {
            this.messages = e.map(x => {
                let date = this.$dayjs(x.created)
                return {
                    ...x,
                    unread: !x.recipient.read_flag,
                    formattedCreated: date.format('MMM D, YYYY'),
                    unixTime: date.format('x'),
                    ...this.itemValue(x.value)
                }
            })
            this.loading = false
            // this.lastUnreadCount = this.unreadCount
        },
        onClick(item) {
            if(item.message_type_id) {
                this.markRead(item)
                this.showNotice(item)
            } else {
                this.showNotifications = true
            }
        },
        showNotice(item) {
            let actions = [{
                text: 'Close',
                usage: 'ghost',
            }]
            if(item.href) actions.push({
                text: 'Download',
                href: item.href,
            })

            this.$messageBox({
                title: item.text || item.message_type_value,
                persistent: false,
                maxWidth: 400,
                message: item.subtext || item.value,
                actions: actions,
            })
        },
        markRead(item) {
            this.$axios.get('message.php?action=get', {
                params: {
                    id: item.id,
                    mark_as_read: 1,
                }
            })
            this.unreadCount -= item.unread
            item.unread = 0
            this.$emit('unreadCount',this.unreadCount)
            if(item.batch_job_id) {
                this.$root.$emit('waiterDialog', {
                    action: 'remove',
                    batch_job_id: item.batch_job_id,
                })
            }
        },
        markAllRead() {
            this.$messageBox({
                title: 'Confirm',
                persistent: true,
                maxWidth: 400,
                message: 'Mark all messages read?',
                actions: [{
                    text: 'Close'
                }, {
                    text: 'Mark All Read',
                    primary: true,
                    onClick: () => {
                        // update notifications as read
                        this.$axios.get('users.php?action=notification_timestamp')

                        // update certain message types as read
                        this.$axios.get('message.php?action=mark_all_read&message_type_id=13,14').then(response => {
                            this.$ecResponse(response)
                            this.$emit('unreadCount',0)
                            this.messages = this.messages.map(x => {
                                x.unread = 0
                                return x
                            })
                        }).catch(error => {
                            console.error(error)
                        })
                    }
                }]
            })
        },
    },
    data() {
        return {
            messages: [],
            loading: true,
            unreadCount: 0,
            filterText: null,
            noBodyPadding: false,
            showNotifications: false,
            compose: null,
        }
    },
}
</script>

<style lang="scss" scoped>
.overlay ::v-deep {
    .v-icon {
        z-index: -1;
        font-size: 36px;
        color: var(--v-accent-darken2) !important;
    }
    .ec-overlay-header {
        padding-top: 16px;
        color: var(--v-accent-darken2);
    }
}
.panel {
    width: 350px;
    height: 460px;
    max-height: 90vh;
    background-color: white;
}
.item {
    &:hover {
        background-color: var(--v-primary-lighten2);
    }
}
.bborder {
    border-bottom: 1px solid #ECEDF1;
}
.dot {
    width: 26px;
}
.preview {
    margin-left: 26px;
    height: 40px;
    color: black;
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.action {
    min-height: 38px;
}
.bcolor {
    color: var(--v-primary-base);
}
.link {
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
}
.new-menu-content {
    background-color: white;
    overflow: hidden;
}
</style>
