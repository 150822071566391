<template>
    <div>
        <fe-card
            class="alt-card ma-3"
            headerText="Point Sheet Goal Progress"
        >
            <template v-slot:body>
                <v-skeleton-loader
                    class="mx-auto"
                    type="text@5"
                    :loading="isLoading"
                    style="min-height: 120px;"
                >
                    <div class="d-flex" style="height: 130px;" >
                        <div class="ma-1 goal-container flex-grow-1" v-ripple v-for="s in sections" @click="$emit('drilldown', s)">
                            <div class="goal-number py-2" :style="{background: s.color}">
                                {{s.count}}

                                <div>{{totalCount ? parseFloat((s.count / totalCount)*100).toFixed(1) : '--'}}%</div>
                            </div>

                            <div class="goal-title">{{s.title}}</div>
                        </div>

                    </div>
                </v-skeleton-loader>
            </template>
        </fe-card>
    </div>
</template>

<script>
    export default {
        name: 'AcademicGoalProgress',
        props: {
            params: {

            }
        },
        watch: {
            params: {
                handler(v) {
                    if (v && Object.values(v).length) this.loadData()
                },
                immediate: true
            }
        },
        computed: {
            gridWidth() {
                return window.innerWidth-50
            },
            totalCount() {
                return this.items.data ? this.items.data.length : 0
            }
        },
        data() {
            return {
                items: [],
                isLoading: false,
                participationConfig: {},
                procedureConfig: {},

                dialog: {
                    show:false,
                    data: []
                },
                sections: {
                    on_track: {
                        title: 'On Track',
                        color: '#1BACAB',
                        count: 0,
                        data: []
                    },
                    not_on_track: {
                        title: 'Not On Track',
                        count: 0,
                        color: '#FF675D',
                        data: []
                    },
                    // data_entered: {
                    //     title: 'Data Entered',
                    //     count: 0,
                    //     color: '#4AC279',
                    //     data: []
                    // },
                    no_data_entered: {
                        title: 'No Data Entered',
                        count: 0,
                        color: '#BFC3CF',
                        data: []
                    }
                },
                totals: {
                }
            }
        },
        methods: {
            setDetail(key) {

            },
            loadData() {
                let p = {...{chart_types: ['student', ''] }, ...this.params}
                this.isLoading=true
                this.$axios.get('interventionView.php?action=get&property=student_intervention_summary_charts&type=behavior&'+this.$objectToParams(p))
                    .then(r => {
                        this.isLoading=false
                        this.items = this.$ecResponse(r, 'charts')[0]
                        this.processData()
                    })
            },

            processData() {
                let data = this.items.data || []
                for (let s in this.sections) {
                    this.sections[s].count = 0
                    this.sections[s].data  = []
                }

                data.forEach(rec => {
                    let pct = rec.four_week_possible_pct
                    if (!pct && pct !== 0) {
                        this.sections.no_data_entered.count++
                        this.sections.no_data_entered.data.push(rec)
                    } else if (pct) {
                        // this.sections.data_entered.count++
                        // this.sections.data_entered.data.push(rec)

                        if (parseFloat(pct) < 80) {
                            this.sections.not_on_track.count++
                            this.sections.not_on_track.data.push(rec)
                        } else {
                            this.sections.on_track.count++
                            this.sections.on_track.data.push(rec)
                        }
                    }
                })
                // this.totals = {}
                // this.totalCount = 0



                // this.items.forEach(item => {
                //     this.sections.forEach(s => {
                //         let n = item[s.key] || 0
                //         s.count += parseInt(n)
                //         this.totalCount += parseInt(n)
                //     })
                // })
            }
        },
    }
</script>

<style lang="scss" scoped>
.clickable {
    cursor: pointer;
}
.goal-container {
    text-align: center;
    width: 90px;
    cursor: pointer;

    .goal-number {
        font-size: 12px;
        border-radius: 4px;
    }

    .goal-title {
        font-size: 16px;
    }
}
</style>
