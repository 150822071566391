export default {
    namespaced: true,

    state: {
        activeDate: null,
    },

    mutations: {
        set() {
            // Does nothing - keeping it in here to be parallel with other non-text-field filters
        }
    },

    actions: {
        load() {
        },

        refresh() {
        },
    },

    getters: {
        all: state => state.activeDate,
        collection: state => state.activeDate,
        success: () => true,
        clear: () => false
    }
}
