<template>
    <fe-dialog
        :title="title"
        width="900"
        height="600"
        :footer="activeItem.component !== 'type-selector'"
        dismissable
        dismissButtonText="Cancel"
        :acceptButtonText="acceptButtonText"
        :acceptButtonDisabled="!valid"
        @accept="save"
        @dismiss="$emit('close')"
    >
        <component
            v-for="(item, i) in items"
            :key="`vis-config-item-${i}`"
            :ref="`config-${item.component}`"
            v-if="activeItem.component === item.component"
            :is="item.component"
            :dashboard="dashboard"
            :edit="edit"
            :globalFilters="!!dashboard.global_filters"
            :getSSValues="getSavedSearchValues"
            :getSchoolYears="getSchoolYears"
            :getObjId="getObjId"
            :setConfig="setConfig"
            @go="go"
            @valid="validUpdate"
            @change="configUpdate"
        />

        <fe-btn
            v-if="!edit && activeItem.component !== 'type-selector'"
            class="back-button"
            usage="tertiary"
            @click="go('type-selector')"
        >
            Back
        </fe-btn>
    </fe-dialog>
</template>

<script>
import {mapState} from "vuex"
import TypeSelector from "./TypeSelector"
import SectionHeader from "./SectionHeader"
import Assessment from "./Assessment"
import Attendance from "./Attendance"
import IncidentType from "./IncidentType"
import IncidentResponse from "./IncidentResponse"
import Demographic from "./Demographic"
import Tag from "./Tag"
import GoogleSheet from "./GoogleSheet"

export default {
    name: "VisConfig",

    components: {
        TypeSelector,
        SectionHeader,
        Assessment,
        Attendance,
        IncidentType,
        IncidentResponse,
        Demographic,
        Tag,
        GoogleSheet
    },

    props: {
        dashboard: {
            type: Object,
            required: true
        },
        edit: {
            type: Object,
            required: false
        },
        getObjId: {
            type: Function,
            required: true
        },
        getSavedSearchValues: {
            type: Function,
            required: true
        }
    },

    data() {
        return {
            valid: false,
            type: undefined,
            config: undefined,
            savedSearch: undefined,
            activeItem: {
                title: 'Visualization',
                component: 'type-selector'
            },
            items: [{
                title: 'Visualization',
                component: 'type-selector'
            }, {
                title: 'Section Header',
                component: 'section-header'
            }, {
                title: 'Assessment Visualization',
                component: 'assessment'
            }, {
                title: 'Attendance Visualization',
                component: 'attendance'
            }, {
                title: 'Incident Type Visualization',
                component: 'incident-type'
            }, {
                title: 'Incident Response Visualization',
                component: 'incident-response'
            }, {
                title: 'Demographic Visualization',
                component: 'demographic'
            }, {
                title: 'Tag Visualization',
                component: 'tag'
            }, {
                title: 'Google Sheet Visualization',
                component: 'google-sheet'
            }]
        }
    },

    computed: {
        ...mapState('global', ['shareableStores']),

        title() {
            let output = this.edit ? 'Edit ' : 'New '
            output += this.activeItem.title
            return output
        },

        acceptButtonText() {
            return this.edit ? 'Done' : 'Save'
        }
    },

    created() {
        if (this.edit) {
            switch (this.edit.type) {
                case 'googlesheet':
                    this.go('google-sheet', this.edit)
                    break;
                case 'incidentResponse':
                    this.go('incident-response', this.edit)
                    break;
                case 'incidentType':
                    this.go('incident-type', this.edit)
                    break;
                case 'section':
                    this.go('section-header', this.edit)
                    break;
                default:
                    this.go(this.edit.type, this.edit)
            }
        }
    },

    methods: {
        go(to, edit) {
            this.config = edit
            this.activeItem = this.items.filter(x => x.component === to)[0]
        },

        validUpdate(v) {
            this.valid = !!v
        },

        setConfig(base, edit) {
            let deepConfig = Object.assign({}, base.config, edit.config)
            let config = Object.assign({}, base, edit)
            config.config = deepConfig

            return config
        },

        configUpdate(v, key = 'config') {
            this[key] = v
        },

        getSchoolYears(startingYear, numPrevious = 1) {
            let schoolYears = this.shareableStores.school_years
            let collection = [startingYear]

            for (let i = 0; i < numPrevious; i++) {
                let inc = i + 1
                collection.push(startingYear.match(/([0-9]+)-([0-9]+)/).slice(1, 3).map(x => x - inc).join('-'))
            }

            return schoolYears.filter(x => collection.includes(x.name))
        },

        save() {
            this.$emit('save', this.config, this.savedSearch)
        }
    }
}
</script>

<style lang="scss" scoped>
.back-button {
    position: absolute;
    bottom: 8px;
    left: 8px;
}
</style>
