<template>
    <div v-if="!noDataMsg.show" class="d-flex flex-fill flex-column">
        <div class="ml-auto text-right pr-5" v-if="showToggleButton">
            <div class="ml-auto mt-2 d-flex toggle-button">
                <div v-ripple class="show-charts first" @click="view='charts'" :class="{selected: view=='charts'}">
                    <v-icon class="mr-2">fas fa-chart-bar</v-icon>
                    Charts
                </div>

                <div v-ripple class="show-datawall" @click="view='table'" :class="{selected: view=='table'}">
                    <v-icon class="mr-2">fas fa-bars</v-icon>
                    Data List
                </div>
            </div>
        </div>

        <div v-if="view=='charts'" class="d-flex flex-fill flex-column" style="overflow: scroll;">
            <v-row>
                <v-col  cols=6 v-for="(c,i) in periodCharts" :key="`period-chart-`+i">
                    <attendance-chart
                        :height="height"
                        :config="c.config"
                        :chartParams="c.chartParams"
                        property="period_charts"
                        :showInsight="false"
                        pinnable
                        style="border: 1px solid #dddddd; border-radius: 4px;"
                        @chartClicked="doClick"
                    />
                </v-col>
            </v-row>
        </div>

        <attendance-list :params="listParams" v-if="view == 'table'" class="pa-4"/>

        <div class="period-count-footer" v-if="view != 'table'">
            <breadcrumb v-model="navigation" @click="doBreadcrumb"/>
        </div>
    </div>

    <div v-else class="d-flex flex-fill flex-column">
        <v-layout align-center justify-center column fill-height text-center>
            <div class="headline">
                There are zero absences
            </div>
        </v-layout>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/common/Breadcrumb'
    import AttendanceChart from '@/components/charts/AttendanceChart'
    import AttendanceList from './AttendanceList'

    export default {
        name: 'PeriodCounts',
        components: { Breadcrumb, AttendanceChart, AttendanceList },
        data() {
            return {
                view: 'charts',
                navigation: [],
                periodCharts: [],
                insights: {},
                height: 350,
                viewItems: [{
                    name: 'Test 1',
                    icon: 'fas fa-edit'
                }, {
                    name: 'Test 2',
                    icon: 'fas fa-edit'
                }],
                noDataMsg: {
                    show: false
                }
            }
        },
        props: {
            params: {
                type: Object
            },
            showToggleButton: {
                type: Boolean,
                default: true
            },
            allCharts: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            this.loadPeriodCounts()
        },
        computed: {
            listParams() {
                let p = {...this.$props.params}
                this.navigation.forEach((n,ni) => { Object.assign(p, n.params)})
                return p
            }
        },
        methods: {
            setView(view) {
                this.view = view
            },
            doClick(evt, rec, point) {
                this.navigation.push({
                    id: point.category,
                    name: point.category,
                    params: rec.params
                })
                let p = {...rec.params}
                this.navigation.forEach((n,ni) => { Object.assign(p, n.params)})
                this.loadPeriodCounts(p)
            },
            doBreadcrumb(crumb, i) {
                let p = {...this.$props.params}
                this.navigation.forEach((n,ni) => { if (ni<=i) Object.assign(p, n.params)})
                this.loadPeriodCounts(p)
            },
            loadPeriodCounts(extraParams) {
                let me = this

                this.$setLoading(true)
                me.periodCharts = []
                let p = {...this.$props.params, ...extraParams}
                const chartTypes = [
                    'school',
                    'grade',
                    'weekday',
                    'month',
                    'ethnicity',
                    'gender',
                    'reason',
                    'excused',
                    'status',
                    'term',
                    'course',
                    'period',
                    'student'
                ]

                if (!extraParams) {
                    this.navigation = [{
                        id: 'home',
                        name: 'Absence Counts',
                        params: p
                    }]
                }

                let promises = chartTypes.map(chartType => {
                    p.chart_type = chartType

                    return this.$modelGet('attendancePeriodCharts', p).then(response => {
                        this.insights = {}
                        response.forEach((chart, index) => {
                            if (chart.data.length > 0) {
                                let max = chart.data.reduce((prev, current) => {
                                    return (parseInt(prev.total_count) > parseInt(current.total_count)) ? prev : current
                                })

                                this.insights[chart.key] = max

                                this.periodCharts.push({
                                    name: chart.title,
                                    config: chart,
                                    chartParams: chart.params,
                                    chartType: 'period_charts',
                                    index: index
                                })
                            } else {
                                this.noDataMsg.show = true
                            }
                        })
                    }).finally(() => { this.$setLoading(false) }).catch(error => {
                        console.error(`Error fetching data for ${chartType}:`, error)
                    })
                })

                Promise.all(promises)
            },
        }
    }
</script>

<style lang="scss" scoped>
.period-count-footer {
    bottom: 0px;
    padding: 8px;
    z-index: 2;
    border-top: 1px solid #e6e6e6;
    background: white;
}

.toggle-button {
    background: #FFFFFF;
    border: 1px solid #E0E1E6;
    border-radius: 4px;
    height: 32px;

    .first {
        border-right: 1px solid #E0E1E6;
    }

    .selected {
        background: var(--fe-accent);
    }

    div {
        width: 100px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        color: #050F2D !important;
        ::v-deep .v-icon {
            color: #050F2D;
        }
    }
}
</style>
