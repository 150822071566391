let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}
export default {
    task: {
        rules: [
            r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        ],
        defaults: {
            endpoint: 'task.php?property=task',
            rootProperty: 'tasks'
        },
        ...standardCrud
    },
    taskAssignee: {
        defaults: {
            endpoint: 'task.php?property=assignee',
            rootProperty: 'task_assignees'
        },
        ...standardCrud
    },
    taskNote: {
        defaults: {
            endpoint: 'task.php?property=note',
            rootProperty: 'task_notes'
        },
        ...standardCrud
    }
}
