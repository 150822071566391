<template>
    <div class="flex-fill flex-column">

        <div v-if="!activePage">
            <div class="mb-4 headline">Users & Security</div>
        </div>

        <fe-action-list v-if="!activePage"
            itemText="displayName"
            :items="displayItems"
            :showSearch="false"
            :addButton="false"
            :editButton="false"
            :showFiltering="false"
            @rowClick="go"
        />

        <!-- <component v-for="item in items" :key="item.component"
            v-show="activePage == item.component"
            :is="item.component"
            @countChanged="getCounts"
        /> -->

        <component v-if="activePage" :is="activePage" @countChanged="getCounts" />

    </div>
</template>

<script>
import Vuex from 'vuex'
import Users from './Users'
import PermissionGroups from './PermissionGroups'
import Permissions from './Permissions'
import UserTags from './UserTags'
import Employees from './Employees'
import { mapLocalState } from '@/util/vuexHelper'

export default {
    name: 'ManageUsers',
    components: {
        Users,
        PermissionGroups,
        Permissions,
        UserTags,
        Employees,
    },
    provide() {
        this.localStore = new Vuex.Store({
            state: {
            }
        })
        return {
            localStore: this.localStore,
        }
    },
    mounted() {
        this.getCounts()
    },
    computed: {
        activePage() {
            // return this.$store.state.manage.breadcrumbs['Users & Security'].slice(-1)[0]?.component
            return this.$getLastCrumb('Users & Security')?.component
        },
        districtId() {
            return this.$store.state.global.sessionUser.district.login_id
        },
        displayItems() {
            return this.items.reduce((filteredItems,x) => {
                if(x.securityCode && !this.$hasSecurity(x.securityCode)) return filteredItems

                if(x.component == 'Employees'
                    && this.$store.state.global.sessionUser.user.login != 'support'
                ) return filteredItems

                filteredItems.push({
                    name: x.name,
                    displayName: `${x.name} <span class="ml-4">${this.counts[x.count]||0}<span>`,
                    component: x.component,
                })
                return filteredItems
            },[])
        }
    },
    methods: {
        go(to) {
            this.$addCrumb('Users & Security',to)
        },
        getCounts() {
            this.$axios.get('qry.php?action=get&property=manage_user_counts').then(response => {
                this.$data.counts = response.data.counts;
            })
        }
    },
    data() {
        return {
            counts: {},
            items: [
                { name: 'Users', count: 'user_cnt', component: 'Users', securityCode: 'MANAGE_USER_SECURITY' },
                { name: 'Permission Groups', count: 'user_group_cnt', component: 'PermissionGroups', securityCode: 'MANAGE_USER_SECURITY' },
                { name: 'Permissions', count: 'permission_cnt', component: 'Permissions', securityCode: 'MANAGE_USER_SECURITY' },
                { name: 'User Tags', count: 'user_tag_cnt', component: 'UserTags', securityCode: 'MANAGE_PUBLIC_TAGS' },
                { name: 'Support Users', count: 'employee_cnt', component: 'Employees', securityCode: 'MANAGE_SUPPORT_USERS' },
            ]
        }
    },

}
</script>

<style lang="scss" scoped>
</style>
