<template>
    <fe-dialog
        v-if="rootMessage && rootMessage.root_message_id"
        ref="dialog"
        width="600px"
        acceptButtonText="Ok"
        :title="title"
        :forceClose="false"
        @accept="close"
        @dismiss="close"
    >
        <template v-if="allRecipients.length" #header>
            <div>
                {{ title }}
                <div class="fs12">
                    {{allRecipients}}
                </div>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="$refs.dialog.dismiss(true)">
                <v-icon>close</v-icon>
            </v-btn>
        </template>
        <div v-if="isChatStyle" class="scroll-container" ref="scrollContainer">
            <template v-for="(item,i) in items">
                <div v-if="i && i == firstUnreadIndex" class="new-h-line mb-4">
                    <div class="mx-4">New</div>
                </div>

                <div
                    :key="item.id"
                    :class="{'item-me':item.created_by == sessionUser.user.id}"
                    class="item d-flex"
                >
                    <v-avatar v-if="item.created_by != sessionUser.user.id" size="32" class="mr-2">
                        <img
                            :src="item.photo_url ? item.photo_url : '/images/default-student-img.png'"
                            :alt="item.created_by_full_name"
                        >
                    </v-avatar>
                    <div style="width:100%;">
                        <div class="d-flex align-center fs16">
                            <div class="font-weight-bold">
                                {{item.created_by_full_name}}
                            </div>
                            <fe-tooltip class="ml-auto" :tooltip="$dayjs(item.created).format('MMM D, YYYY h:mm A')" style="display:inline-block">
                                <div class="secondary-text fs12">
                                    {{$dayjs(item.created).format('MMM D, YYYY')}}
                                </div>
                            </fe-tooltip>
                        </div>
                        <div v-html="item.value" />
                    </div>
                </div>
            </template>
        </div>

        <div v-else class="scroll-container" ref="scrollContainer">
            <template v-for="(item,i) in items">
                <div v-html="item.value"></div>
            </template>
        </div>

        <template v-if="canReply" #footer>
            <fe-text-field
                ref="sendField"
                class="ma-2"
                placeholder="Reply"
                width="100%"
                @change="msgText=$event"
                hide-details autofocus clearable
            />
            <fe-btn class="ml-auto" usage="primary" :disabled="$_.isBlank(msgText)" @click="send" >Send</fe-btn>
        </template>

        <fe-crud
            ref="crud"
            :readParams="readParams"
            :config="$models.message"
            autoload
            @read="read"
        />
    </fe-dialog>
</template>

<script>
export default {
    name: 'DirectThread',

    props: {
        rootMessage: null,
    },

    computed: {
        sessionUser: me => me.$store.state.global.sessionUser,
        title: me => me.rootMessage.message_type_value,
        isChatStyle: me => me.items.length > 1,
        canReply: me => me.isChatStyle || ![13,14].includes(me.rootMessage.message_type_id),
        items() {
            return this.messages || []
        },
        allRecipients() {
            let index = {}
            index[this.rootMessage.created_by] = this.rootMessage.created_by_full_name.replace(/(.+), (.+)/,'$2 $1')
            this.items.forEach(x => x.recipients.forEach(y => {
                index[y.to_user_id] = y.user_full_name.replace(/(.+), (.+)/,'$2 $1')
            }))
            return Object.values(index).join(', ')
        },
        firstUnreadIndex() {
            let index = 0
            this.items.forEach((x,i) => {
                if(!index && x.recipients.find(y => y.to_user_id == this.sessionUser.user.id && !y.read_flag)) index = i
            })
            return index
        },
        readParams() {
            return {
                action: 'get_inbox_thread',
                mark_as_read: 1,
                message_id: this.rootMessage.id,
            }
        }
    },
    methods: {
        close() {
            this.messages = null
            this.$emit('close',null)
        },
        read(e) {
            this.messages = e
            this.$nextTick(() => {
                this.$refs.scrollContainer.scrollTo({
                    top: this.$refs.scrollContainer.offsetHeight,
                    left: 0,
                    behavior: 'smooth'
                })
            })
        },
        send() {
            if(this.items.length) {
                this.$refs.crud.create({
                    parent_message_id: this.items[this.items.length - 1].id,
                    value: this.msgText,
                    reply_all: this.rootMessage.single ? 0 : 1,
                    // reply_all: 1,
                    mark_as_read: 1,
                }).then(() => {
                    this.$emit('reload')
                    this.$refs.sendField.text = null
                    this.$refs.crud.read()
                })
            }
        },
    },
    data() {
        return {
            msgText: null,
            messages: null,
        }
    }
}
</script>

<style lang="scss" scoped>
.scroll-container {
    overflow:auto;
    width:100%;
    max-height:70vh;
    transition: height 2s !important;
}
.item {
    font-size: 14px;
    min-height: 0;
    flex: 1 1 0;
    padding: 12px;
    max-width: 70%;
    margin-bottom: 16px;
    border-radius: 20px 20px 20px 5px;
    background-color: var(--fe-tertiary);
    &-me {
        margin-left: auto;
        border-radius: 20px 20px 5px 20px;
        background-color: var(--fe-selected);
    }
}
.new-h-line {
    color: #006C90;
    display: flex;
    flex-direction: row;
    &::before, &::after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid;
        margin: auto;
    }

}
</style>
