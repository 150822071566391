<template>
    <form-common-config
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #fieldSpecific="{rec}">
            {{setRec(rec)}}
            <fe-grid
                ref="grid"
                simpleInsert="display_value"
                simpleInsertPlaceholderText="List Option Value"
                @cellValueChanged="onChanged"
                @simpleInsert="$refs.crud.create({
                    display_value: $event,
                    form_field_id: rec.id,
                    active: 1,
                    rank: rowData.length
                }).finally(()=>{
                    $refs.grid.$refs.simpleInsertField.reset()
                    $refs.crud.read()
                })"
                displayDensity="small"
                :rowData="rowData"
                :showToolbar="false"
                :columnDefs="columnDefs"
                domLayout="autoHeight"
                :gridOptionOverrides="gridOptions"
                @rowDragEnd="updateOrder"
            />

            <fe-crud
                ref="crud"
                :key="rec.id"
                :defaultParams="{form_field_id:rec.id}"
                :config="$models.formFieldOption"
                @read="onRead"
                autoload
            />
        </template>

    </form-common-config>
</template>

<script>
import FormCommonConfig from '../FormCommonConfig'
import Sortable from 'sortablejs'
import { mapLocalState } from '@/util/vuexHelper'

// pull in field configs and reload everytime changes are made in the grid
export default {
    inject: ['localStore'],
    components: {
        FormCommonConfig,
    },
    computed: {
        ...mapLocalState([ 'reloadFn', 'fieldConfigs' ]),
        labelStyle: me => ({ minWidth:'120px' }),
    },
    methods: {
        setRec(rec) {
            this.fieldRec = rec
        },
        onRead(e) {
            this.rowData = e
            this.fieldConfigs[this.fieldRec.id].items = e.filter(x => x.active)
        },
        onChanged(e) {
            e.newValue != e.oldValue
                ? this.$refs.crud.update(e.data).finally(() => this.$refs.crud.read())
                : null
        },
        updateOrder(e) {
            let newOrder = []
            e.api.forEachNode(function(rowNode, index) {
                newOrder.push({...rowNode.data,rank:index})
            })
            this.$refs.crud.update(newOrder).finally(() => this.$refs.crud.read())
        },
    },
    data() {
        return {
            fieldRec: null,
            rowData: [],
            gridOptions: {
                deltaRowDataMode: true,
                getRowNodeId: v => {
                    return v.id
                },
                rowDragManaged: true,
                suppressMoveWhenRowDragging: true,
                animateRows: true,
            },
            columnDefs: [{
                headerName:'Value',
                field:'display_value',
                width:200,
                rowDrag:true,
                editable:true,
                cellEditorFramework:'FeGridTextField',
                cellEditorParams: {
                    maxLength: 200,
                    required: true,
                },
            }, {
                headerName:'State Cd',
                field:'state_code',
                hide:true,
                width:100,
                editable:true,
                cellEditorFramework:'FeGridTextField',
                cellEditorParams: {
                    popup: true,
                    maxLength: 10,
                },
            },
                this.$grid.toggleColumn('Active','active',null,null,{ minWidth:60,maxWidth:60 }),
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
