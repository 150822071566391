<template>
    <v-layout column>
        <div class="headline mb-1 d-flex">
            Statuses
            <fe-info-tip
                class="ml-2"
                tooltip="Statuses"
            />
        </div>

        <fe-crud-grid
            ref="grid"
            :config="crudConfig"
            :columnDefs="columnDefs"
            displayDensity="medium"
            autoload
            refreshOnCreated
            disableInlineCreate
            @beginCreate="beginEntry"
        >
            <template v-slot:cellDialog="{cell}">
                <simple-grid-dialog-column
                    :cell="cell"
                    title="Aliases"
                    field="alias"
                    fieldHeader="Alias"
                    fieldPlaceholder="Add an Alias"
                    :fieldCounter="255"
                    :crudConfig="aliasConfig"
                    :defaultParams="{
                        property: 'AttendanceStatusAlias',
                        attendance_status_id: cell.data.id
                    }"
                    :insertDefaults="{
                        attendance_status_id: cell.data.id
                    }"
                    @created="load"
                    @destroyed="load"
                    @close="$refs.grid.$refs.grid.openMenu = false"
                />
            </template>
        </fe-crud-grid>

        <fe-dialog
            v-if="createDialog.show"
            title="Create Status"
            :footer="false"
            persistent
            disableAutoclose
            @close="dismissDialog"
        >
            <model-form
                idProperty="id"
                :records="createDialog.records"
                :fieldConfig="fieldConfigs"
                model="attendanceStatuses"
                @cancel="dismissDialog"
                @done="created"
            />
        </fe-dialog>
    </v-layout>
</template>

<script>
    import SimpleGridDialogColumn from "../../../common/SimpleGridDialogColumn"
    import ModelForm from '@/components/common/form/ModelForm'

    export default {
        name: "AttendanceStatuses",

        components: {SimpleGridDialogColumn, ModelForm},

        data() {
            return {
                cell: undefined,
                createDialog: {show: false, records: []},
                columnDefs: [
                    {
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        maxWidth: 70,
                        colId: 'checkbox_column'
                    },
                    {
                        headerName: 'Status Title',
                        field: 'name',
                        editable: true,
                        cellEditorFramework: "FeGridTextField"
                    },
                    {
                        headerName: 'Counts Against Full Day Rate',
                        field: 'affected_flag',
                        cellRendererFramework: "FeGridToggle",
                        cellRendererParams: {
                            rowDataKey: 'affected_flag'
                        },
                        maxWidth: 150
                    },
                    {
                        headerName: 'Aliases',
                        field: 'aliases',
                        editable: false,
                        valueGetter: params => {
                            return params.data.aliases.length
                        },
                        cellRendererFramework: "FeDialogColumn",
                        onCellClicked: v => {
                            this.cellClicked(v)
                        },
                        maxWidth: 100
                    },
                    {
                        headerName: 'Date Created',
                        field: 'created',
                        editable: false,
                        maxWidth: 200
                    },
                    {
                        headerName: 'Created By',
                        field: 'created_by_name',
                        editable: false,
                        maxWidth: 200
                    },
                    {
                        headerName: 'Upload Id',
                        field: 'upload_id',
                        editable: false,
                        maxWidth: 100
                    }
                ],
                fieldConfigs: [
                    {
                        field: 'name',
                        label: 'Status Title',
                        component: 'v-text-field',
                        validatorKey: 'text',
                        validatorConfig: {required: true},
                        width: '100%',
                        attrs: {
                            validateOnBlur: true,
                            flat: true,
                            solo: true,
                            dense: true
                        }
                    }, {
                        field: 'affected_flag',
                        label: 'Counts Against Full Day Rate',
                        component: 'fe-switch'
                    }
                ]
            }
        },

        computed: {
            crudConfig() {
                return _.cloneDeep(this.$models.attendanceStatuses)
            },

            aliasConfig() {
                let config = _.cloneDeep(this.$models.attendanceAliases)
                config.defaults.rootProperty = 'attendance_status_alias'
                return config
            }
        },

        created() {
            this.$parent.$once('show statuses', () => {
                this.$refs.grid.$refs.grid.resize()
            })
        },

        methods: {
            load() {
                this.$refs.grid.$refs.crud.read()
            },

            cellClicked(cell) {
                this.cell = cell
                this.$refs.grid.$refs.grid.setDialogCell(cell)
            },

            beginEntry() {
                this.resetEntry()
                this.createDialog.show = true
            },

            dismissDialog() {
                this.createDialog.show = false
            },

            resetEntry() {
                this.createDialog.records = [{
                    name: '',
                    affected_flag: 0
                }]
            },

            created() {
                this.load()
                this.dismissDialog()
            }
        }
    }
</script>

<style scoped></style>
