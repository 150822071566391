<template>
    <form-common-config
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #fieldSpecific="{rec}">
            <div class="d-flex">
                <fe-label :style="labelStyle">
                    Display Table
                    <v-flex style="display: inline-block; margin-right: 10px">
                        <fe-info-tip tooltip="The table can display any or all guardian informaion" />
                    </v-flex>
                </fe-label>
                <fe-switch style="margin-left:auto;"
                    v-model="formFieldSavedSearch.config_json.as_table"
                    :true-value="1" :false-value="0"
                />
            </div>

            <div class="d-flex">
                <fe-label :style="labelStyle">
                    As of Creation Date
                    <v-flex style="display: inline-block; margin-right: 10px">
                        <fe-info-tip tooltip="This will ONLY display guardians that were created as of this smartFORM creation date." />
                    </v-flex>
                </fe-label>
                <fe-switch style="margin-left:auto;"
                    v-model="formFieldSavedSearch.config_json.to_creation"
                    :true-value="1" :false-value="0"
                />
            </div>

            <div v-if="!formFieldSavedSearch.config_json.as_table">
                <fe-label :style="labelStyle">Guardian</fe-label>
                <fe-remote-combo
                    byId
                    style="margin-left:auto;"
                    v-model="formFieldSavedSearch.config_json.guardian"
                    itemValue="value"
                    :items="[
                        {value:1, name:'Guardian One'},
                        {value:2, name:'Guardian Two'},
                    ]"
                    :clearable="false"
                ></fe-remote-combo>
                <fe-label :style="labelStyle">Guardian Field Type</fe-label>
                <fe-remote-combo
                    byId
                    style="margin-left:auto;"
                    v-model="formFieldSavedSearch.config_json.data_field"
                    itemValue="value"
                    :items="[
                        {value:'guardian_full_name', name:'Full Name'},
                        {value:'guardian_fname', name:'First Name'},
                        {value:'guardian_lname', name:'Last Name'},
                        {value:'guardian_full_address', name:'Address'},
                        {value:'guardian_addr_street', name:'Street'},
                        {value:'guardian_addr_city', name:'City'},
                        {value:'guardian_addr_state', name:'State'},
                        {value:'guardian_addr_zip', name:'Zip'},
                        {value:'guardian_phone_number', name:'Phone'},
                        {value:'guardian_work_phone_number', name:'Work Phone'},
                        {value:'guardian_email_addr', name:'Email'},
                        {value:'guardian_last_login', name:'Last Login Date'},
                        {value:'relationship', name:'Relationship'},
                        {value:'created', name:'Created'},
                    ]"
                    :clearable="false"
                ></fe-remote-combo>
            </div>
            <div v-else>
                <fe-label :style="labelStyle">Table Settings</fe-label>
                <fe-remote-combo
                    byId multiple
                    moreLabel="Columns"
                    style="margin-left:auto;"
                    v-model="formFieldSavedSearch.config_json.display_columns"
                    itemValue="value"
                    :items="[
                        {value:'guardian_full_name', name:'Full Name'},
                        {value:'guardian_fname', name:'First Name'},
                        {value:'guardian_lname', name:'Last Name'},
                        {value:'guardian_full_address', name:'Address'},
                        {value:'guardian_addr_street', name:'Street'},
                        {value:'guardian_addr_city', name:'City'},
                        {value:'guardian_addr_state', name:'State'},
                        {value:'guardian_addr_zip', name:'Zip'},
                        {value:'guardian_phone_number', name:'Phone'},
                        {value:'guardian_work_phone_number', name:'Work Phone'},
                        {value:'guardian_email_addr', name:'Email'},
                        {value:'guardian_last_login', name:'Last Login Date'},
                        {value:'relationship', name:'Relationship'},
                        {value:'created', name:'Created'},
                    ]"
                    :clearable="false"
                ></fe-remote-combo>
            </div>


            <fe-crud
                ref="crud"
                :key="rec.id"
                :readParams="{form_field_id:rec.id}"
                :config="$models.formFieldSavedSearch"
                autoload
                @read="processRead($event,rec)"
            />
        </template>
    </form-common-config>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'
import FormCommonConfig from '../FormCommonConfig'
import configMixin from './configMixin'

export default {
    inject: ['localStore'],
    mixins: [ configMixin ],
    components: {
        FormCommonConfig,
    },
    computed: {
        ...mapLocalState([ 'reloadFn', 'selectedComponent' ]),
        labelStyle: me => ({ minWidth:'120px' }),
    },
    watch: {
        formFieldSavedSearch: {
            deep: true,
            handler: 'save',
        },
    },
    methods: {
        processRead(v,rec) {
            this.formFieldSavedSearch = this.processSavedSearch(v, {
                form_field_id: rec.id,
                config_json: {
                    as_table: 0,
                    to_creation: 0,
                    guardian: 1,
                    data_field: 'guardian_full_name',
                    display_columns: [
                        'guardian_full_name',
                        'guardian_full_address',
                        'guardian_phone_number',
                        'guardian_work_phone_number',
                        'guardian_email_addr',
                    ],
                }
            })

            if(!this.$_.isBlank(v)) {
                this.cachedValue = JSON.stringify(this.formFieldSavedSearch)
            }
        },
        save() {
            // this.onShowDemograhicCombo()
            let dirty = this.cachedValue != JSON.stringify(this.formFieldSavedSearch)
            if(dirty) {
                this.cachedValue = JSON.stringify(this.formFieldSavedSearch)
                let fieldId
                this.$refs.crud.create({...this.formFieldSavedSearch, form_saved_search_type_id: 2}).then( r => {
                    fieldId = r.data?.form_field_ids[0]
                }).finally(() => {
                    // this should be updating the ui when we make changes on the right
                    this.selectedComponent.$refs.localField.formFieldSavedSearch = {
                        ...this.selectedComponent.$refs.localField.formFieldSavedSearch,
                        ...this.formFieldSavedSearch,
                    }
                    this.reloadFn({fieldId:fieldId})
                })
            }
        }
    },
    data() {
        return {
            formFieldSavedSearch: {
                config_json: {},
            },
            cachedValue: {},
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
