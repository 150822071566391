<template>
    <poller :config="{key:'server_last_activity'}" #default="data" @polled="onPolled">
        <div class="remaining">
            {{showDistrict}}{{minsRemaining}}
        </div>
    </poller>
</template>

<script>
import { mapState } from 'vuex'
import Poller from '@/util/poller.js'

export default {
    name: 'ApplicationTimeout',
    components: {Poller},

    data() {
        return {
            baseSessionMins: 40,
            secsSinceActive: 0,
            pingInterval: 1000*60,
            promptSecs: 60*5,
        }
    },

    computed: {
        ...mapState('global', ['shareableStores', 'sessionUser']),
        minsRemaining: me => Math.round(me.baseSessionMins - me.secsSinceActive/60),
        secsRemaining: me => parseInt(me.baseSessionMins * 60 - me.secsSinceActive),
        showDistrict: me => me.$isSupport() ? me.sessionUser.district.name + ' - ' : '',
    },
    
    mounted() {
        window.addEventListener('keyup', this.onActiveUser)
        window.addEventListener('mousedown', this.onActiveUser)
        this.ping = this.$_.throttle(() => {
            this.$axios.get('qry.php?property=ping')
            
            if (this.shareableStores.authx_enabled && this.shareableStores.authx_provider) {
                try {
                    document.getElementById('ie_session_iframe')
                    .contentWindow.postMessage('refresh-session', new URL(this.shareableStores.authx_session).origin)
                } catch (e) {}
            }
            
        }, this.pingInterval)
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.onActiveUser)
        window.removeEventListener('mousedown', this.onActiveUser)
    },
    
    methods: {
        onPolled({timeStamp,serverTime}) {
            this.secsSinceActive = serverTime - timeStamp
            
            if(this.secsRemaining < 5) {
                this.logout()
            } else if(this.secsRemaining < this.promptSecs && !this.dialog) {
                this.dialog = true
                this.$messageBox({
                    title: 'Session Logout',
                    persistent: true,
                    maxWidth: 400,
                    message: `Your session is about to expire.  Would you like to continue?`,
                    actions: [{
                        text: 'No',
                        onClick: this.logout,
                    }, {
                        text: 'Yes',
                        onClick: () => {
                            this.onActiveUser()
                            this.dialog = false
                        }
                    }]
                })
            }
        },
        onActiveUser() {
            this.ping()
        },
        logout() {
            this.$axios.get('qry.php?property=logout&json=1').then(response => {
                window.onbeforeunload = null
                if(response.data.redirect) {
                    window.location = response.data.redirect
                } else {
                    location.reload()
                }
            })
        }
    },
    
}
</script>

<style lang="css" scoped>
.remaining {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    opacity: .15;
}
</style>
