<template>
    <div>
        <div class="d-flex justify-space-between">
            <div class="headline">Action Items</div>
            <div class="top-buttons">
                <fe-btn usage="primary" @click="showCreateDialog = true">
                    New Action Item
                </fe-btn>
            </div>
        </div>

        <fe-tabs
            :tabs="tabs"
            color="transparent"
            class="tabs"
            style="height: 45px;"
            noPadding
            @tabClicked="activeTab = $event"
        />

        <action-items
            v-for="(tab, i) in tabs"
            :key="`tab-${i}`"
            v-if="activeTab.show === tab.show"
            :items="items"
            :tab="activeTab.show"
            :refresh="refresh"
            :readParams="readParams"
            @updated="refresh = false"
            @edit="editActionItem"
            :urlParams="urlParams"
        />

        <edit-action-item
            v-if="showCreateDialog"
            @dismiss="dismiss()"
            @save="dismiss()"
            activeUsersOnly
            :editing="editing"
        />
    </div>
</template>

<script>
    import ActionItems from './ActionItems'
    import EditActionItem from './EditActionItem'
    import { mapState } from 'vuex'

    export default {
        name: "LandingPage",

        components: {ActionItems, EditActionItem},

        props: {
            items: {type: Array, default: () => ([])},
            urlParams: {type: Object, default: () => ({})}
        },

        data() {
            return {
                activeTab: {
                    name: 'Assigned to Me',
                    show: 'all',
                    path: '#'
                },
                tabs: [{
                    name: 'Assigned to Me',
                    show: 'all',
                    path: '#'
                }, {
                    name: 'My Action Items',
                    show: 'my',
                    path: '#'
                }, {
                    name: 'Template Items',
                    show: 'template',
                    path: '#'
                }],
                showCreateDialog: false,
                refresh: false,
                editing: null
            }
        },

        computed: {
            ...mapState('global', ['sessionUser']),
            readParams() {
                let params = {}
                let user = this.sessionUser.user
                if (user) {
                    params.user_id = user.id
                }
                if (params.user_id) {
                    switch (this.activeTab.show) {
                        case 'my':
                            params.created_by = params.user_id
                            break;
                        case 'all':
                            params.assignee_id = params.user_id
                            break;
                        case 'template':
                            params.created_by = params.user_id
                            params.template = true
                            break;
                        default:
                            break;
                    }
                }
                return params
            }
        },

        methods: {
            dismiss() {
                this.showCreateDialog = false
                this.refresh = true
                this.editing = null
            },
            editActionItem(item) {
                this.editing = item
                this.showCreateDialog = true
                this.refresh = true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tabs {
        ::v-deep .fe-tabs-color {
            .v-tab a {
                color: #b3b7c5 !important;
            }
            .v-tab--active a {
                color: #006C90 !important;
            }
        }
        ::v-deep .v-tabs-slider {
            background-color: #006C90 !important;
            border-color: #006C90 !important;
        }
    }
</style>
