<template>
    <div>
        <v-row>
            <v-col cols="6">
                <div class="overall-pct">
                    <div>Overall Percentage Earned</div>
                    <div style="font-size: 36px;">{{overall.pct}}%</div>
                    <div style="font-size: 12px;">
                        Goal {{overall.goal}}% 
                    </div>
                    <div style="font-size: 12px;">
                        Average Score {{overall.average}} 
                    </div>
                </div>
            </v-col>
            <v-col cols="6" v-for="(chart, i) in charts">
                <div>
                    <highcharts class="fe-chart" :options="chart"/>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'BehaviorMonitorSummary',
        data() {
            return {
                items: [],
                charts: [],
                overall: {
                    pct:0,
                    goal: 80,
                    average: 0
                },
                attendanceConfig: {

                },
                schedules: []
            }
        },
        props: {
            params: {

            },
            printing: {
                type: Boolean, default: false
            }
        },
        mounted () {
            this.loadData()
        },
        watch: {
            printing(v) {
                if(v) {
                    this.$emit('monitorSummaryCharts', this.charts)
                }
            }
        },
        methods: {
            loadData() {
                this.$axios.get('studentMonitors.php?action=get&property=behavior_summary&type=behavior&'+this.$objectToParams(this.params))
                    .then(r=> {
                        const monitors = this.$ecResponse(r, 'monitors')
                        if (monitors === false) {
                            return
                        }
                        this.items = monitors
                        this.buildOptions()
                    })
            },
            buildOptions() {
                this.items.forEach(item => {
                    if (item.title == 'Overall' && item.data.length) {
                        this.overall.pct = item.data[0].pct
                        this.overall.goal = item.data[0].goal || 80
                        this.overall.average = item.data[0].avg_score
                    } else {
                        this.charts.push(this.getChartConfig(item))
                    }
                })
                return {}
            },
            getChartConfig(item) {
                let c = {
                    chart: {
                        type: 'bar',
                        height: 300
                    },
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    xAxis: {
                        type: 'category'
                    },
                    yAxis: {
                        max: 100,
                        title: {
                            text: 'Percentage Earned Toward Goal'
                        }
                    },
                    title: {
                        align: 'left',
                        text: item.title
                    },
                    series: [{
                        name: 'Percent',
                        data: item.data.map(r => { return {
                            name: r[item.x_field],
                            y: parseFloat(r[item.y_field])
                        }})
                    }]
                }
                return c
            }
        },
    }
</script>

<style lang="scss" scoped>
.overall-pct {
    text-align: center;
    height: 300px;
    border: 2px solid #f3f4fb;
    border-radius: 4px;
    padding-top: 100px;
}
.fe-chart {
    border: 2px solid #f3f4fb;
    border-radius: 4px;

    ::v-deep * {
        font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
        color: #dddddd !important;
    }
}
</style>