<template>
    <TabPane ignoreCount>
        <template slot="icon">
            <div class="tile__rounded tile__rounded--group"></div>

            <h3 class="card__title card__title--group">{{ title }}</h3>
        </template>

        <template slot="buttons">
            <fe-btn
                v-if="isConditional && obj.operator === 'any'"
                usage="tertiary"
                useIcon="link_off"
                @click="$emit('requireAll')"
            >
                Require Any
            </fe-btn>

            <fe-btn
                v-else-if="isConditional && obj.operator === 'all'"
                usage="tertiary"
                useIcon="link"
                @click="$emit('requireAny')"
            >
                Require All
            </fe-btn>

            <v-menu z-index="50" bottom left :disabled="!allowDelete">
                <template v-slot:activator="{ on }">
                    <v-btn icon x-small v-on="on" :disabled="!allowDelete" style="margin-right: 8px;">
                        <v-icon>fal fa-ellipsis-v</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item v-show="allowDelete" @click="$emit('delete')">
                        <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

        <template slot="content">
            <template v-if="showLoader">
                <div class="text-xs-center">
                    <v-progress-circular indeterminate color="primary" size="40"/>
                </div>
            </template>

            <Empty
                v-else-if="showPlaceholder"
                title="Start building your Threshold"
                body="To get started, begin by adding a metric."
                button="Add a Metric"
                :rocket="true"
                @click="addMetric"
            />

            <template v-else>
                <v-layout row wrap>
                    <v-flex px-3>
                        <v-layout pa-0 ma-0 mb-1 row class="text-muted">
                            <v-flex xs9 pl-3>
                                Name (Metric Type)
                            </v-flex>

                            <v-flex xs3 pr-3 text-xs-right>
                                <span v-if="!isConditional">
                                  Weight
                                </span>
                            </v-flex>
                        </v-layout>

                        <div class="metric-list">
                            <MetricRow
                                v-for="(metric,idx) in sortedMetrics"
                                :metric="metric"
                                :conditional="isConditional"
                                :idx="idx"
                                :key="idx"
                                @edit="() => editMetric(metric)"
                                @update="(obj) => updateMetric(metric, obj)"
                                @delete="confirmingDelete = metric"
                            />
                        </div>

                        <v-layout pa-0 ma-0 mb-1 row class="text-muted">
                            <v-flex xs12 px-3 class="text-xs-right">
                                <span v-if="!isConditional">
                                  Total Weight: <strong v-html="totalWeight"/>
                                </span>
                            </v-flex>
                        </v-layout>
                        <fe-btn usage="tertiary" @click="addMetric">Add a Metric</fe-btn>
                    </v-flex>
                </v-layout>
            </template>

            <MetricEditor
                v-if="!isViewing"
                :obj="editItem"
                :metrics="sortedMetrics"
                @cancel="editItem = null"
                @save="saveMetric"
            />

            <fe-dialog
                v-if="confirmingDelete"
                usage="error"
                title="Delete Metric?"
                dismissButtonText="Cancel"
                acceptButtonText="Delete"
                body="Are you sure you want to permanently delete this metric?"
                @accept="() => deleteMetric(confirmingDelete)"
                @dismiss="confirmingDelete = null"
            />
        </template>
    </TabPane>
</template>

<script>
import MetricService from '../services/MetricService'
import TabPane from '../templates/TabPane'
import MetricEditor from '../modals/MetricEditor'
import MetricRow from './MetricRow'
import Empty from '../templates/Empty'

export default {
    name: 'Pane',

    components: {
        TabPane,
        MetricEditor,
        MetricRow,
        Empty
    },

    props: {
        obj: Object,
        placeholder: Boolean,
        title: String,
        allowDelete: Boolean
    },

    data() {
        return {
            confirmingDelete: null,
            loading: false,
            metrics: [],
            editItem: null,
            weight: 0,
            operators: [
                {value: "any", text: "Require Any"},
                {value: "all", text: "Require All"}
            ]
        }
    },

    computed: {
        isViewing() {
            return !this.editItem
        },

        isAdding() {
            return !this.isViewing && !this.editItem.id
        },

        isEditing() {
            return !this.isViewing && this.editItem.id
        },

        thresholdGroup() {
            return this.$store.getters['thresholds/group/selected']
        },

        thresholdGroupId() {
            return this.thresholdGroup.id
        },

        sortedMetrics() {
            return this.metrics.map(x => x).sort((a, b) => {
                return a.created.localeCompare(b.created)
            })
        },

        totalWeight() {
            return this.metrics.reduce((a, b) => a + (parseFloat(b.weight) || 0), 0)
        },

        hasMetrics() {
            return this.metrics.length > 0
        },

        showPlaceholder() {
            return this.placeholder || !this.hasMetrics
        },

        showLoader() {
            return this.loading
        },

        isConditional() {
            return !!this.thresholdGroup.is_conditional
        }
    },

    watch: {
        obj: function (obj) {
            if (obj) this.refresh()
        }
    },

    mounted() {
        this.refresh()
    },

    methods: {
        refresh() {
            if (this.obj && this.obj.id && !this.placeholder && !this.loading) {
                this.loading = true
                let opts = {params: {threshold_subgroup_id: this.obj.id}}
                MetricService.fetch(opts, (results) => {
                    this.metrics = results
                    this.loading = false
                })
            }
        },

        addMetric() {
            this.editItem = {__type: 'assessment', __error: null}
        },

        editMetric(metric) {
            this.editItem = Object.assign({}, metric, {__error: null})
        },

        refreshMetric(metric) {
            MetricService.fetch({
                type: metric.__type,
                params: {id: metric.id}
            }, (results) => {
                this.metrics = this.metrics.filter(itm => {
                    return !(itm.id === metric.id && itm.__type === metric.__type)
                })
                results.forEach(itm => this.metrics.push(itm))
                if (this.editItem) {
                    if (this.editItem.__type === metric.__type && this.editItem.__id === metric.id) {
                        this.editItem = (results.length > 0)
                            ? results[0]
                            : null
                    }
                }
            })
        },

        saveMetric(obj) {
            return (!this.editItem.id)
                ? this.createMetric(obj)
                : this.updateMetric(this.editItem, obj)
        },

        createMetric(data) {
            MetricService.add({
                type: data.__type,
                obj: Object.assign(data, {
                    threshold_group_id: this.thresholdGroupId,
                    threshold_subgroup_id: this.obj.id,
                    weight: 1
                })
            }, (rsp) => {
                if (rsp.length > 0) {
                    /** Had to clear this out and wait a tick to manually close the window and reopen it **/
                    this.editItem = null
                    this.$nextTick(() => {
                        this.editItem = rsp[0]
                        rsp.forEach(itm => this.metrics.push(itm))
                    })
                }
            })
        },

        updateMetric(metric, data) {
            MetricService.update({
                type: metric.__type,
                obj: Object.assign(data, {id: metric.id})
            }, () => {
                this.refreshMetric(metric)
                this.editItem = null
            })
        },

        deleteMetric(metric) {
            MetricService.remove({
                type: metric.__type,
                obj: {id: metric.id}
            }, () => {
                this.refreshMetric(metric)
            })
            this.confirmingDelete = null
        }
    }
}
</script>
