<template>
    <div class="d-flex flex-column flex-fill no-scroll mt-2 pl-4 pr-4" style="overflow: hidden; position: relative;">
        <fe-dialog
            title="Messages"
            width="800"
            v-if="messageDialog.show"
            @accept="messageDialog.show=false"
            @close="messageDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Done"
            :retain-focus="false"
        >
            <message @created="addMessage" @deleted="addMessage" :allowPrivate="false" dense :params="messageDialog.params"/>
        </fe-dialog>

        <v-slide-y-transition>
            <div v-if="selectedStudents.length > 0" class="selection-toolbar d-flex">
                <div class="mt-3 ml-5">{{selectedStudents.length}} Form{{ selectedStudents.length > 1 ? 's' : '' }} Selected</div>

                <div class="ml-auto">
                    <v-btn text class="white--text" @click="tagStudents">Tag Students</v-btn>
                    <v-btn text class="white--text" @click="$refs.grid.gridApi.deselectAll()">Cancel</v-btn>
                </div>
            </div>
        </v-slide-y-transition>

        <fe-grid
            ref="grid"
            :rowData="items"
            :columnDefs="columnDefs"
            style="height: 100%; width: 100%"
            displayDensity="small"
            :showAddRowBtn="false"
            :frameworkComponents="frameworkComponents"
            @rowSelected="doSelect"
            @cellMouseOver="doCellOver"
            @cellMouseOut="doCellOut"
            :pinnedBottomRowData="footerRows"
            :showDownload="false"
            @gridReady="onGridReady"
        >
            <template #toolbar-items>
                <fe-btn v-if="selectedStudents.length>0" @click="tagStudents">Tag Students</fe-btn>
                <fe-icon-btn useIcon="fa fa-arrow-alt-to-bottom" style="margin-top: 2px;" small @click="doDownload"/>
            </template>
        </fe-grid>

        <fe-student-card
            ref="studentCard"
            :style="{
                position: 'absolute',
                left: `${studentCard.left}px`,
                top: `${studentCard.top}px`,
                padding: '1px 6px 1px 6px',
                zIndex: '10'
            }"
            :imageUrl="studentCard.imageUrl"
            :studentRecord="studentCard.studentRecord"
            v-show="studentCard.show"
            fullNameField="student_full_name"
            schoolField="school_name"
            gradeField="grade_desc"
            genderField="gender"
            @close="() => {}"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import InterventionColumn from '@/components/modules/datawall/renderers/InterventionColumn'
    import Message from '@/components/common/Message'

    export default {
        name: 'IncidentDataWall',
        props: {
            params: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapState('global', ['studentsToTag']),
            footerRows () {
                let avgs = {}
                this.columnDefs.filter(col => col.footerAvg).forEach(col => {
                    let vals = this.items.map(itm => itm[col.field] || 0)
                    let avg = vals.reduce((p, c) => parseFloat(p) + parseFloat(c), 0) / vals.length
                    avgs[col.field] = isNaN(avg) ? avg : avg.toFixed(2)
                })
                return [avgs]
            }
        },
        components: { Message },
        data() {
            return {
                messageDialog: {
                    show: false,
                    params: null
                },
                selectedStudents: [],
                cfg: null,
                items: [],
                columnDefs: [],
                frameworkComponents: { interventionColumn: InterventionColumn },
                columnGrouper: {
                    headerName: 'Incident Range',
                    comparator(valueA, valueB) {

                    }
                },
                studentCard: {
                    show: false,
                    studentRecord: {}
                },
                columnApi: null,
            }
        },
        created() {
            this.loadData()
            window.addEventListener('beforeunload', this.editHeaders)
        },
        methods: {
            doCellOver(meta) {
                if (meta.colDef.field === "student_full_name" && meta.data.student_id) {
                    if (this.lastStudentOver == meta.data.student_id && this.items.length > 1) return
                    if (this.lastStudentOver == meta.data.student_id && this.items.length == 1) {
                        return this.studentCard = {
                            ...this.studentCard,
                            show: true
                        }
                    }
                    let studentData = null

                    this.$axios.get(
                        'students.php?action=get&property=grade_history&id=' + meta.data.student_id + '&school_year_id=' + (this.params.school_year_id || this.params.cohort_school_year_id)
                    ).then(response => {
                        if (response && response.data) {
                            studentData = response.data[0]
                            studentData.grade_desc = meta.data.grade // make sure we show current grade level in student card
                        }
                    }).finally(() => {
                        this.studentCard = {
                            ...this.studentCard,
                            show: true,
                            imageUrl: this.$axios.defaults.baseURL + 'students.php?action=get&property=student_image&id=' + meta.data.student_id,
                            studentRecord: studentData,
                            top: meta.event.clientY,
                            left: meta.event.clientX
                        }

                        this.lastStudentOver = meta.data.student_id
                    }).catch(error => {})
                }
            },
            doCellOut(meta) {
                this.studentCard = {
                    ...this.studentCard,
                    show: false,
                    studentRecord: this.items.length == 1 ? this.studentCard.studentRecord : {}
                }
            },
            addMessage(rec, records) {
                this.messageDialog.node.setDataValue('student_comments', records.length)
            },
            tagStudents() {
                let me = this
                this.$store.commit('global/tagStudents', {
                    show: true,
                    students: this.selectedStudents,
                    callback() {
                        me.loadData()
                    }
                })
            },
            resize() {
                this.$refs.grid?.gridApi?.sizeColumnsToFit()
            },
            doSelect() {
                this.selectedStudents = this.$refs.grid.gridApi.getSelectedRows()
            },
            loadData() {
                this.selectedStudents=[]
                this.$setLoading(true)
                this.$axios.get('behaviorIncident.php?action=get&property=datawall&'+this.$objectToParams(this.params))
                    .then(response => {
                        this.cfg = response.data.datawall
                        this.buildDataWall()
                        this.$setLoading(false)
                    })
            },
            buildDataWall() {
                let me = this
                if (!this.cfg?.columns) return

                let columns = [this.$grid.checkColumn()]

                this.cfg.columns.forEach(col => {
                    if (col.columns) {
                        col.columns.forEach(child => {
                            columns.push({
                                headerName: child.text,
                                field: child.dataIndex,
                                editable: false,
                                hide: child.hidden ? child.hidden : true,
                                width: child.width+20,
                                cellRenderer(v) {
                                    return v.value
                                }
                            })
                        })
                    } else {
                        col.hide = col.hidden ? col.hidden : false
                        columns.push(this.$grid.incidentColumn(col, me))
                    }
                })

                // this.cfg.data.forEach()
                this.columnDefs=columns
                this.items=this.cfg.data//.slice(0, 200)
                this.$nextTick(() => {
                    this.resize()
                })
            },

            doDownload() {
                this.$refs.grid.gridApi.exportDataAsCsv({
                    processCellCallback(cell) {
                        if (!cell.value && cell.value !== 0) return ''
                        let tmp = document.createElement("DIV")
                        tmp.innerHTML = `${cell.value}`.replace(/\</g, " <")

                        return tmp.textContent.trim() || tmp.innerText.trim() || ""
                    },
                    columnGroups: true,
                    skipHeader: false,
                    skipPinnedTop: false,
                    allColumns: true,
                    onlySelected: false,
                    fileName: 'Data Table'
                })
            },
            
            onGridReady(grid) {
                setTimeout(function() {
                    let me = this
                    this.columnApi = grid.columnApi
                    let previousHeaders = window.localStorage.getItem('ec-datawall-incident-headers') || '[]'
                    previousHeaders = JSON.parse(previousHeaders)
                    if(previousHeaders.length) {
                        previousHeaders.forEach(head => {
                            this.columnDefs.find(x => x.field == head.field).hide = head.hide
                        })
                        grid.api.setColumnDefs(this.columnDefs)
                        grid.api.sizeColumnsToFit()
                    }
                    // add global listener to set local storage on visibility change 
                    // so that going from datawall -> charts -> datawall it remains the same
                    grid.api.addGlobalListener(function(type, event) {
                        if(type.indexOf("column") >= 0) {
                            if(event.type == "columnVisible") {
                                me.columnDefs.forEach(head => {
                                    head.hide = !me.columnApi.getAllColumns().find(col => col.colDef.field == head.field).visible
                                })
                                window.localStorage.setItem('ec-datawall-incident-headers', JSON.stringify(me.columnDefs))
                            }
                        }
                    })
                }.bind(this), 500)
            },
            
            editHeaders() {
                this.columnDefs.forEach(head => {
                    head.hide = !this.columnApi.getAllColumns().find(col => col.colDef.field == head.field).visible
                })
                window.localStorage.setItem('ec-datawall-incident-headers', JSON.stringify(this.columnDefs))
            }
        },
    }
</script>

<style lang="scss" scoped>
.selection-toolbar {
    width: 97%;
    position: absolute;
    height: 48px;
    background: #66A7BC;
    color: white;
    z-index: 10;
    border-radius: 4px;
}
</style>
