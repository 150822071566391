<template>
    <div class="flex-fill flex-column">
        <div class="flex-shrink-1">
            <div class="headline">Assessments</div>

            <div class="mb-4 caption" style="color: #C0C3CF; font-size: 14px;">
                Assessments allow users to enter/import data on students to be displayed in charts, data walls and on the student page under specific category/subject types.  These assessments are grouped together when they have similar testing windows/timeframes, score types and colors for performance bands.
            </div>
        </div>
        <div class="flex-grow-1">

            <div class="d-flex behavior-code-container mb-5" @click="$emit('selectCategory', { name: 'Category', show: 'category'})" v-if="$isSupport()">
                <div class="behavior-code-title">
                    Categories
                </div>

                <div class="ml-auto">
                    <fe-icon-btn useIcon="fas fa-chevron-right"/>
                </div>
            </div>

            <div class="d-flex behavior-code-container mb-5" @click="$emit('selectAdvancedSettings', { name: 'Advanced Settings', show: 'advancedSettings'})" v-if="$isSupport()">
                <div class="behavior-code-title">
                    Advanced Settings
                </div>

                <div class="ml-auto">
                    <fe-icon-btn useIcon="fas fa-chevron-right"/>
                </div>
            </div>

            <fe-action-list
                ref="lst"
                :items="displayItems"
                :itemCheckboxDisabled="itemCheckboxDisabled"
                show-search
                hide-delete
                :titleWidth="300"
                @selectionChanged="setSelections"
                @beginCreate="beginCreate"
                @beginEdit="editing = true"
                @cancelEdit="editing = false"
                @beginDestroy="hide($event)"
                @rowClick="$emit('selectGroup', $event)"
                @chipClick="handleChipClick"
                :style="{ height: '100%' }"
                :showFiltering="false"
                :editButton="canManageGroups"
                :addButton="canManageGroups"
                @editButtonClick="showEditMenu"
            >
                <template v-slot:beforeButtons>
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <v-checkbox
                                ref="name"
                                :ripple="false"
                                v-model="showDisabled"
                                label="Show Disabled"
                                hide-details
                                :style="{ marginTop: '12px' }"
                            />
                        </div>
                        <div class="flex-shrink-1" v-if="showExtraTools">
                            <fe-btn @click="rename" usage="tertiary" :disabled="renamingSelections.length == 0" v-if="showExtraTools">Rename</fe-btn>
                        </div>
                    </div>
                </template>
                <template v-slot:chip-menu="{chip}">
                    <span v-if="chip.data.length === 0 && chip.key != 'window_names'">
                        <v-icon color="#D71608" size="14px" class="mr-2">fa-exclamation-circle</v-icon>
                        Click to {{ chip.menuButtonText }}
                    </span>
                    <span v-else-if="chip.data.length === 0 && chip.key == 'window_names'">
                        <v-list two-line class="assessment-group-list-windows">
                            <v-list-item-content v-for="option in windowOptions" :key="option.id" @click="onWindowOptionClick(option, chip)">
                                <v-list-item-title>{{ option.title }}</v-list-item-title>
                                <v-list-item-subtitle>{{ option.subTitle }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list>
                    </span>
                </template>
            </fe-action-list>

            <fe-dialog
                v-if="creating"
                disable-autoclose
                ref="dialog"
                title="Create Assessment Group"
                :acceptButtonDisabled="!!validationErrors"
                acceptButtonText="Create"
                dismissButtonText="Cancel"
                @accept="create"
                @dismiss="creating = false"
            >
                <fe-label content="Assessment Group Name" />
                <v-text-field
                    v-model="newRecord.name"
                    flat solo dense autofocus
                    placeholder="My Assessment Group"
                    @input="validateNewRecord"
                    hide-details
                />
                <div v-for="(itm, i) in errorStrings" :key="`err-${i}`" class="error--text ma-1 pa-0">
                    <small>{{ itm }}</small>
                </div>
            </fe-dialog>

            <v-dialog width="500" v-model="renaming" :key="renamingDialogKey">
                <v-card>
                    <v-card-title>
                        Rename Assessment Groups
                    </v-card-title>
                    <v-card-text>
                        <model-form
                            idProperty="id"
                            @cancel="finishRename"
                            @done="finishRename"
                            :readParams="{active: 1}"
                            :records="renamingSelections"
                            model="assessment"
                            :fieldConfig="fieldConfig"
                            submitButtonText="Save"
                            :submitPrevent="true"
                        />
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <fe-crud
            ref="crud"
            :config="$models.assessment"
        />

    </div>
</template>

<script>
    import ModelForm from '@/components/common/form/ModelForm'
    import { mapState } from 'vuex'

    export default {
        name: 'AssessmentGroupList',
        props: {
            groups: { type: Array, required: true },
            sec: { type: Object, required: true }
        },
        components: {
            ModelForm
        },
        data() {
            return {
                // showDisabled: this.$store.getters['manage/asmtDisabledState'],
                editing: false,
                creating: false,
                renaming: false,
                renamingSelections: [],
                renamingDialogKey: 1, // increment to force re-mounting of model-form with updated records
                selections: [],
                validationErrors: false,
                newRecord: {
                    name: '',
                    description: '',
                    allow_score_entry: true,
                    hidden: false
                },
                showExtraTools: false,
                fieldConfig: [{
                    field: 'name',
                    label: 'Assessment Group Name',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { limit: 250 },
                    width: '100%',
                    attrs: {
                        required: true,
                        flat: true,
                        solo: true,
                        dense: true
                    }
                }],
                windowOptions: [
                    { id: 1, title: 'Benchmark', subTitle: 'Fall, Winter, Spring' },
                    { id: 2, title: 'Quarterly', subTitle: 'Q1, Q2, Q3, Q4' },
                    { id: 3, title: 'Monthly', subTitle: 'August - July' },
                    { id: 4, title: 'Yearly', subTitle: 'Yearly' },
                    { id: 5, title: 'Custom', subTitle: 'Add your own' }
                ]
            }
        },
        computed: {
            ...mapState('manage', ['manageAssessmentsShowDisabled']),
            showDisabled: {
                get() {
                    return this.$store.getters["manage/getAssessmentsDisabledState"];
                },
                set(newValue) {
                    return this.$store.dispatch("manage/setAssessmentsDisabledState", newValue);
                },
            },
            chipTypes () {
                return [
                    { key: 'assessment_names', name: 'Assessment', security: this.sec.groups },
                    { key: 'window_names', name: 'Window', security: this.sec.groups },
                    { key: 'band_names', name: 'Band', security: this.sec.bands },
                    { key: 'data_embargo_names', name: 'Embargo Date', security: this.sec.embargoes, required: false, hideIfEmpty: true, },
                ]
            },
            canManageGroups () { return this.$hasSecurity(this.sec.groups) },
            canManageBands () { return this.$hasSecurity(this.sec.bands) },
            canManageDataEmbargoes () { return this.$hasSecurity(this.sec.embargoes) },
            showKPIs () { return this.$hasSecurity(this.sec.kpis) },
            displayItems () {
                if (!this.groups) return null
                let filtered = []
                if (!this.showDisabled && this.groups) {
                    filtered = this.groups.filter(x => !x.hidden)
                } else if (this.groups) {
                    filtered = this.groups
                }
                return filtered.map(itm => {
                    return Object.assign({}, itm, {
                        icons: this.determineRecordIcons(itm),
                        chips: this.determineRecordChips(itm)
                    })
                }).sort((a,b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1
                    } else if (a.name.toLowerCase() > b.name.toLowerCase()){
                        return 1
                    }
                })
            },
            hasSelectedGlobalAssessments () {
                return (this.renamingSelections.filter(itm => itm.id < 10000).length > 0)
            },
            nonGlobalRenamingSelections () {
                return this.renamingSelections.filter(itm => itm.id >= 10000)
            },
            errorStrings () {
                return (!this.validationErrors)
                    ? []
                    : this.validationErrors.map(itm => {
                        let msg = null
                        if (typeof itm === "string") {
                            msg = itm
                        } else if (itm instanceof Object) {
                            msg = (itm.body && itm.body.msg) ||
                                (itm.body && itm.body.message) ||
                                itm.msg ||
                                itm.message ||
                                itm.text ||
                                Object.keys(itm).map(x => itm[x]).join("<br />")
                        }
                        return msg || itm
                    })
            }
        },
        methods: {
            setSelections (items) {
                this.selections = items
                this.renamingSelections = [].concat(this.selections).map(itm => ({ ...itm, isReadonlyAssessmentGroup: false }))
            },
            showEditMenu() {
                this.selections = []
                this.renamingSelections = []
                if (!this.showExtraTools) this.selections = []
                this.showExtraTools=!this.showExtraTools
            },
            beginCreate() {
                this.creating = true
                this.newRecord.name = ''
                this.validationErrors = false
            },
            create () {
                this.validateNewRecord()
                if (!this.validationErrors) {
                    let payload = [this.newRecord]
                    this.$refs.crud.create([this.newRecord]).then(rsp => {
                        this.creating = false
                        this.$emit('reload')
                    }).catch(err => {
                        this.validationErrors = [err]
                    })
                }
            },
            hide (selections) {

            },
            itemCheckboxDisabled (item) {
                //return (item.id < 10000)
                return false
            },
            rename () {
                this.renaming = true
                this.renamingDialogKey += 1
            },
            finishRename () {
                this.renaming = false
                this.renamingSelections = []
                this.$emit('reload')
                this.$refs.lst.edit()
            },
            validateNewRecord () {
                let payload = [this.newRecord]
                this.validationErrors = this.$refs.crud.validationErrors(payload)
                if (this.groups.find(x => x.name.trim().toLowerCase() == this.newRecord.name.trim().toLowerCase())) {
                    let duplicateError = `Assessment Group Name "${this.newRecord.name.trim()}" already exists.`
                    if (this.validationErrors) {
                        this.validationErrors.push(duplicateError)
                    } else {
                        this.validationErrors = [duplicateError]
                    }
                }
            },
            determineRecordIcons (record) {
                let icons = []
                if (this.canManageGroups) {
                    if (record.allow_score_entry) {
                        icons.push({
                            icon: 'fa-user-edit',
                            tooltip: 'Users can enter scores'
                        })
                    }
                    if (record.id < 10000) {
                        icons.push({
                            icon: 'fa-globe',
                            tooltip: 'This is a global measure'
                        })
                    }
                }
                return icons
            },
            determineRecordChips (record) {
                let chips = []

                this.chipTypes.forEach(ct => {
                    if (!ct.security || this.$hasSecurity(ct.security)) {
                        let none = record[ct.key].length === 0
                        let one = record[ct.key].length === 1

                        if (none && ct.hideIfEmpty) {
                            // Don't push new chip into chips, cause this is one that is hidden when 0 items exist
                            return;
                        }

                        chips.push({
                            key: ct.key,
                            icon: (none && ct.required !== false) ? 'fa-info-circle' : null,
                            name: `${record[ct.key].length} ${ct.name}${one ? '' : 's'}`,
                            menuButtonText: `${none ? 'Add' : 'Manage'} ${ct.name}s`,
                            data: record[ct.key].map(itm => {
                                return { name: itm }
                            }),
                            showMenuButton: ct.key == 'window_names' && none ? false : true,
                            groupId: record.id
                        })
                    }
                })

                if (this.showKPIs && record.kpi_cnt && record.kpi_cnt > 0) {
                    chips.push({
                        name: `${record.kpi_cnt} KPI`
                    })
                }

                if (record.hidden) {
                    chips.push({ name: 'Disabled' })
                }

                return chips
            },
            handleChipClick (e) {
                if (e.chip.key == 'assessment_names') {
                    if (e.data.length == 0) {
                        this.$emit('createAssessment', e.parent)
                    } else {
                        this.$emit('manageAssessments', e.parent)
                    }
                } else if (e.chip.key == 'window_names') {
                    if (e.data.length == 0) {
                        this.$emit('createWindow', e.parent)
                    } else {
                        this.$emit('manageWindows', e.parent)
                    }
                } else if (e.chip.key == 'band_names') {
                    if (e.data.length == 0) {
                        this.$emit('createBand', e.parent)
                    } else {
                        this.$emit('manageBands', e.parent)
                    }
                } else if (e.chip.key == 'data_embargo_names') {
                    // Embargo chip is only shown if >= 1 embargoes exist (to reduce visual clutter)
                    this.$emit('manageDataEmbargoes', e.parent)
                }
            },
            onWindowOptionClick (option, chip) {
                let group = this.displayItems.find(item => item.id == chip.groupId)
                let objs = []
                let subTitles = null
                if (option.title == 'Custom') {
                    this.$emit('createWindow', group)
                } else {
                    this.$emit('selectGroup', group, false)
                    if(option.title == 'Monthly') {
                        subTitles = [
                            { name: 'August', start_day: '0801', end_day: '0831'},
                            { name: 'September', start_day: '0901', end_day: '0930'},
                            { name: 'October', start_day: '1001', end_day: '1031' },
                            { name: 'November', start_day: '1101', end_day: '1130' },
                            { name: 'December', start_day: '1201', end_day: '1231' },
                            { name: 'January', start_day: '0101', end_day: '0131' },
                            { name: 'February', start_day: '0201', end_day: '0229' },
                            { name: 'March', start_day: '0301', end_day: '0331' },
                            { name: 'April', start_day: '0401', end_day: '0430' },
                            { name: 'May', start_day: '0501', end_day: '0531' },
                            { name: 'June', start_day: '0601', end_day: '0630' },
                            { name: 'July', start_day: '0701', end_day: '0731' }
                        ]
                    } else {
                        subTitles = option.subTitle.split(', ')
                    }
                    subTitles.forEach((subtitle, index) => {
                        objs.push({
                            name: subtitle.name ? subtitle.name : subtitle,
                            alias: null,
                            hidden: false,
                            start_day: subtitle.start_day ? subtitle.start_day : null,
                            end_day: subtitle.end_day ? subtitle.end_day : null,
                            store_as_data_point_name_id: null,
                            import_alias: "",
                            norms_data_point_name_id: null,
                            rank: index + 1
                        })
                    })
                    objs.forEach(obj => {
                        this.$emit('createCommonWindow', Object.assign(obj, {
                            data_point_type_id: group.id
                        }))
                    })
                }
            }
        },
        watch: {
            creating: function (x) {
                if (this.$refs.dialog) this.$refs.dialog.confirm = x
            },
        }
    }

</script>

<style lang="scss">
.behavior-code-container {
    border: 2px solid #E0E1E6;
    padding: 8px;
    height: 64px;
    cursor: pointer;

    .behavior-code-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        padding-top: 10px;
        padding-left: 10px;
        color: #000000;
    }
}

.action-list-container {
    .action-list-row .v-input--checkbox.v-input--is-disabled {
        cursor: not-allowed;
    }
}

.assessment-group-list {
    &-windows {
        margin: -5px -5px -10px -5px;
        padding: 0;
        .v-list-item__content {
            padding: 5px 10px;
            cursor: pointer;
            &:hover {
                background: #ECEDF1;
            }
        }
        .v-list-item__title {
            font-size: 14px;
        }
        .v-list-item__subtitle {
            color: #636363;
            font-size: 12px;
        }
    }
}
</style>
