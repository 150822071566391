import GroupStore from './group'
import ToastStore from './Toast'
import UserGroupStore from './UserGroup'
import UserStore from './User'
import TagStore from './Tag'
import FormStore from './Form'
import SchoolStore from './School'
import GradeStore from './Grade'
import EthnicityStore from './Ethnicity'
import GenderStore from './Gender'
import DisabilityStore from './Disability'
import DataPointTypeStore from './DataPointType'
import InterventionTypeStore from './InterventionType'
import AttendanceExcusedStore from './AttendanceExcused'
import AttendanceReasonStore from './AttendanceReason'
import AttendanceStatusStore from './AttendanceStatus'
import BehaviorBehaviorStore from './BehaviorBehavior'
import BehaviorResponseStore from './BehaviorResponse'
import BehaviorBehaviorTypeStore from './BehaviorBehaviorType'
import SchoolYearStore from './SchoolYear'
import ActiveUser from './ActiveUser'
import Demographic from './Demographic'
import EllLevel from './EllLevel'
import EllStatus from './EllStatus'
import ActiveStatus from './ActiveStatus'
import ActiveDate from './ActiveDate'

export const thresholds = {
    namespaced: true,

    state: {
        activeModule: null,
        manage: [],
        launchpad: []
    },

    getters: {},

    mutations: {},

    actions: {},

    modules: {
        'group': GroupStore,
        'activeUser': ActiveUser,
        'toast': ToastStore,
        'user': UserStore,
        'userGroup': UserGroupStore,
        'tag': TagStore,
        'form': FormStore,
        'school': SchoolStore,
        'grade': GradeStore,
        'ethnicity': EthnicityStore,
        'gender': GenderStore,
        'disability': DisabilityStore,
        'interventionType': InterventionTypeStore,
        'attendanceExcused': AttendanceExcusedStore,
        'attendanceReason': AttendanceReasonStore,
        'attendanceStatus': AttendanceStatusStore,
        'dataPointType': DataPointTypeStore,
        'behaviorBehavior': BehaviorBehaviorStore,
        'behaviorResponse': BehaviorResponseStore,
        'behaviorBehaviorType': BehaviorBehaviorTypeStore,
        'schoolYear': SchoolYearStore,
        'demographic': Demographic,
        'ellLevel': EllLevel,
        'ellStatus': EllStatus,
        'activeStatus': ActiveStatus,
        'activeDate': ActiveDate,
    }
}
