export default {
    targetSetWindow: {
        defaults: {
            rootProperty: 'data_point_names',
            endpoint: 'targetSetV.php' // scope: target_set_id=14
        },
        read: {
            params: {
                action: 'get',
                property: 'data_point_names'
            }
        },
        create: {
            params: {
                action: 'create',
                property: 'data_point_names'
            }
        },
        update: {
            params: {
                action: 'update',
                property: 'data_point_names'
            }
        },
        destroy: {
            params: {
                action: 'delete',
                property: 'data_point_names'
            }
        }
    }
}