<template>
    <div class='agenda-topic d-flex flex-column' :class="{ 'topic-expanded': !collapsed, 'topic-collapsed': collapsed }">
        <div class="agenda-topic--titlebar d-flex justify-space-between" @click="collapsed = !collapsed">
            <div class='title-icon flex-shrink-1 text-right align-self-center'>
                <fe-icon-btn :useIcon="collapsed ? 'fas fa-angle-down' : 'fas fa-angle-up'" />
            </div>
            <div class="topic-dragbar">
                <i class="far fa-ellipsis-v"></i>
                <i class="far fa-ellipsis-v"></i>
            </div>
            <div class='title-text align-self-center' v-html="title" />
            <v-avatar size="26" class='title-avatar flex-shrink-1 text-right align-self-center'>
                <img v-if="topic.photo_url" :src="topic.photo_url" :alt="topic.created_by_full_name">
                <v-icon :title="topic.created_by_full_name" v-else>fas fa-user-circle</v-icon>
            </v-avatar>
            <div class='title-menu flex-shrink-1 text-right align-self-center'>
                <v-menu offset-y v-model="menu">
                    <template v-slot:activator="{ on }">
                        <fe-icon-btn
                            v-on="on"
                            color="#979797"
                            size="20"
                            useIcon="fas fa-ellipsis-v"
                        />
                    </template>

                    <v-list dense>
                        <v-list-item @click="$emit('updateTopic')" :disabled="!editable">Edit</v-list-item>
                        <v-list-item @click="deleteConfirm = true" :disabled="!editable">Delete</v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>

        <transition name="slide-y-transition">
            <div class="agenda-topic--body py-2 px-3 mb-3" v-show="!collapsed">
                <div style="font-size: 16px;">Description</div>
                <div class="agenda-topic--body-content" v-html="topic.item" />
                <fe-btn usage="tertiary" @click="adding = true" class="my-4">
                    New Note
                </fe-btn>
                <topic-message
                    v-for="msg in children"
                    :key="`topic-message-${msg.id}`"
                    :message="msg"
                    :messages="topic.replies"
                    @reply="msgReply"
                    @edit="msgEdit"
                    @delete="msgDelete"
                />
            </div>
        </transition>

        <fe-dialog
            v-if="editing"
            @dismiss="msgCancel"
            :title="`${adding ? 'New' : 'Edit'} Topic Note`"
            :acceptButtonText="`${adding ? 'Add' : 'Update'}`"
            :retainFocus="false"
            @accept="saveNote"
        >
            <fe-label>Note Body</fe-label>
            <fe-editor
                :toolbarExtras="['|', 'bulletedlist', 'numberedlist']"
                v-model="editingText"
            />
            <div class="d-flex" v-if="!editingPrivate">
                <fe-remote-combo
                    ref="studentsSearchField"
                    label="Attached Student(s)"
                    placeholder="Search Students by Name"
                    class="chips-below flex-grow-1"
                    itemText="student_full_name"
                    itemValue="student_id"
                    :items="meeting.dashboard_meeting_students"
                    hideDetails
                    validateOnBlur
                    v-model="editingStudents"
                    :returnObject="false"
                    multiple
                />
                <fe-btn
                    usage="ghost"
                    class="mt-5"
                    @click="advanced = true"
                >Advanced</fe-btn>
            </div>
            <div class="d-flex mt-4" v-if="!editingStudents.length">
                <fe-switch v-model="editingPrivate" label="Private Note" />
            </div>
        </fe-dialog>

        <fe-dialog
            v-if="advanced"
            title="Find Students"
            width="800"
            acceptButtonText="Select"
            dismissButtonText="Cancel"
            dismissable
            persistent
            @dismiss="cancelAdvanced"
            @accept="selectAdvanced"
        >
            <student-search v-model="advancedSelections" keepSelection/>
        </fe-dialog>

        <fe-dialog
            v-if="advancedConfirm"
            title="Add Students?"
            acceptButtonText="Add Students"
            dismissButtonText="Cancel"
            dismissable
            persistent
            @dismiss="advancedConfirm = false"
            @accept="addAdvancedSelections"
            body="Some selected students are not currently attached to this meeting.  Would you like to add them now?"
        />

        <fe-dialog
            v-if="deleteConfirm"
            title="Delete Topic?"
            acceptButtonText="Delete"
            dismissButtonText="Cancel"
            dismissable
            persistent
            @dismiss="deleteConfirm = false"
            @accept="destroy"
            body="Are you sure you want to permanently delete this topic and any associated messages?"
        />

        <fe-dialog
            v-if="!!deleteMsgConfirm"
            title="Delete Message?"
            acceptButtonText="Delete"
            dismissButtonText="Cancel"
            dismissable
            persistent
            @dismiss="msgCancel"
            @accept="deleteNote"
            body="Are you sure you want to permanently delete this message?"
        />

        <fe-crud
            ref="crud"
            :config="$models.message"
            :readParams="crudParams"
            refresh-on-config
        />

        <fe-crud
            ref="crudStudents"
            :config="$models.meetingStudent"
            :readParams="crudStudentParams"
            @read="$emit('students', $event)"
            refresh-on-config
            @created="$refs.crudStudents.read()"
        />
    </div>
</template>

<script>
    import Vuex, {mapState} from 'vuex'
    import StudentSearch from '@/components/common/StudentSearch'
    import TopicMessage from './TopicMessage'

    export default {
        name: 'Topic',
        components: { StudentSearch, TopicMessage },
        props: {
            meeting: { type: Object, required: true },
            occurrence: { type: Object, required: true },
            topic: { type: Object, required: true}
        },
        data () {
            return {
                updating: null,
                adding: false,
                editingText: null,
                editingPrivate: false,
                editingStudents: [],
                advanced: false,
                advancedConfirm: false,
                advancedSelections: [],
                deleteConfirm: false,
                deleteMsgConfirm: null,
                menu: false,
                collapsed: true
            }
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            editable () { return this.sessionUser.user.id == this.topic.created_by },
            editing () { return this.adding || this.updating },
            title () {
                let result = `${this.topic.item}`
                let matches = result.match(/^(.*?)(\<br\s?\/?\>|\<br\>|\<\/p\>|\<\/ul\>)/)
                return (matches && matches.length)
                    ? matches[0].replace(/\<.*?\>/g, '')+'&mldr;'
                    : result.replace(/\<.*?\>/g, ' ')+'&mldr;'
            },
            crudParams () {
                return { dashboard_meeting_agenda_id: this.topic.id }
            },
            crudStudentParams () {
                return { dashboard_meeting_id: this.meeting.id }
            },
            children () {
                return this.topic.replies.filter(itm => !itm.parent_message_id)
            }
        },
        methods: {
            destroy () {
                this.$emit('deleteTopic')
                this.deleteConfirm = false
            },
            msgReply (msg) {
                this.editingText = null
                this.editingPrivate = msg.private_flag
                this.editingStudents = msg.student_id ? [msg.student_id] : []
                this.editingParentId = msg.id
                this.adding = true
            },
            msgEdit (msg) {
                this.editingText = msg.value
                this.editingPrivate = msg.private_flag
                this.editingStudents = msg.student_id ? [msg.student_id] : []
                this.editingParentId = msg.parent_message_id
                this.updating = msg
            },
            msgDelete (msg) {
                this.deleteMsgConfirm = msg
            },
            msgCancel () {
                this.adding = false
                this.updating = null
                this.editingParentId = null
                this.editingText = null
                this.editingPrivate = false
                this.editingStudents = []
                this.deleteMsgConfirm = null
            },
            saveNote () {
                if (this.adding) this.createNote()
                else this.updateNote()
            },
            updateNote () {
                this.$refs.crud.update(Object.assign({}, this.updating, {
                    value: this.editingText,
                    private_flag: this.editingPrivate
                })).then(() => {
                    this.msgCancel()
                    this.$emit('refresh')
                })
            },
            createNote () {
                let recs = []
                if (this.editingStudents.length) {
                    this.editingStudents.forEach(stdnt => {
                        recs.push({
                            dashboard_meeting_agenda_id: this.topic.id,
                            value: this.editingText,
                            student_id: stdnt,
                            parent_message_id: this.editingParentId
                        })
                    })
                } else {
                    recs.push({
                        dashboard_meeting_agenda_id: this.topic.id,
                        value: this.editingText,
                        private_flag: this.editingPrivate,
                        parent_message_id: this.editingParentId
                    })
                }
                this.$refs.crud.create(recs).then(() => {
                    this.msgCancel()
                    this.$emit('refresh')
                })
            },
            deleteNote () {
                this.$refs.crud.destroy(this.deleteMsgConfirm).then(() => {
                    this.deleteMsgConfirm = null
                    this.$emit('refresh')
                })
            },
            cancelAdvanced () {
                this.advancedSelections = []
                this.advanced = false
            },
            selectAdvanced () {
                let unmatched = this.advancedSelections.find(itm => {
                    return this.meeting.dashboard_meeting_students.find(match => {
                        return !(itm.student_id == match.student_id)
                    })
                })
                if (unmatched) this.advancedConfirm = true
                else this.addAdvancedSelections()
            },
            addAdvancedSelections () {
                let unmatched = this.advancedSelections.filter(itm => {
                    return this.meeting.dashboard_meeting_students.find(match => {
                        return !(itm.student_id == match.student_id)
                    })
                })
                if (unmatched.length) {
                    this.$refs.crudStudents.create(unmatched.map(itm => {
                        return {
                            dashboard_meeting_id: this.meeting.id,
                            student_id: itm.student_id
                        }
                    })).then((rsp) => {
                        this.mergeAdvancedSelections()
                    })
                } else {
                    this.mergeAdvancedSelections()
                }
            },
            mergeAdvancedSelections () {
                this.advancedSelections.forEach(itm => {
                    if (!this.editingStudents.includes(itm.student_id))
                        this.editingStudents.push(itm.student_id)
                })
                this.cancelAdvanced()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .agenda-topic {
        width: 100%;
        border: 1px solid #DDE1E5;
        border-top: none;
        &:first-child { border-top: 1px solid #DDE1E5; }
        &--titlebar {
            width: 100%;
            cursor: pointer;
            .title-text {
                font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.01em;
                color: #050F2D;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: calc(100vw - 550px);
                padding-right: 20px;
                .is-collapsed & {
                    width: calc(100vw - 250px);
                }
            }
        }
        &--body {
            margin-bottom: 16px;
            &-content {
                font-size: 12px;
                line-height: 165.1%;
            }
        }
    }

    .topic-dragbar {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0,0,0,.54);
        font-size: 16px;
        margin-right: 20px;
    }
</style>
