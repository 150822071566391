<template>
    <div>
        <fe-crud-grid
            :config="gridCrud"
            :columnDefs="columnDefs"
            displayDensity="medium"
            :insertDefaults="defaultParams"
            ref="crud"
            v-if="!editing"
            @cellClicked="editClick"
        />

        <div v-if="editing">
            <fe-btn usage="secondary" @click="editing = null">Back</fe-btn>
            <incident-field :id="editing.id"/>
        </div>

    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import IncidentField from './IncidentField'

    export default {
        name: 'IncidentFieldList',
        inject: ['localStore'],
        components: {
            IncidentField
        },
        props: {
            incidentBehaviorTypeId: {}
        },
        data() {
            return {
                incidentFieldTypes: [],
                editing: null
            }
        },
        created() {
            this.$axios.get('incidentField.php?action=get&property=incident_field_type')
                .then(response => {
                    this.incidentFieldTypes = this.$ecResponse(response, 'incident_field_types')
                })
                .finally(() => {
                })
        },
        computed: {
            crudConfig() {
                return this.$models.incidentField
            },
            defaultParams() {
                return {
                    incident_behavior_type_id: this.incidentBehaviorTypeId,
                    active: true,
                    incident_field_type_id: 6
                }
            },
            columnDefs() {
                let me = this

                return [
                    { headerName: null, headerCheckboxSelection: true, checkboxSelection: true, width: 70, minWidth: 70, colId: 'checkbox_column' },
                    {
                        headerName: "Field",
                        field: "label",
                        flex: 2,
                        sortable: true,
                        editable: true
                    }, {
                        headerName: 'Type',
                        editable: false,
                        valueGetter: params => {
                            let val = ''
                            me.incidentFieldTypes.forEach((tp) => {
                                if (tp.id === params.data.incident_field_type_id) {
                                    val = tp.name
                                }
                            })
                            return val
                        }
                    }, {
                        headerName: 'Required',
                        field: 'required',
                        sortable: true,
                        cellRendererFramework: "FeGridToggle",
                        cellRendererParams: {
                            rowDataKey: "required"
                        }
                    }, {
                        headerName: 'Edit',
                        cellRenderer(params) {
                            return "<div style='cursor: pointer; color: gray;'><i class='fas fa-edit mr-2'></i>Edit</div>"
                        }
                    }
                ]
            },
            gridCrud() {
                return {
                    rules: [
                        r => (r.label && r.label.length > 0) || "A name is required",
                        r => (!isNaN(r.rank) || !r.rank) || "Must be numeric",
                    ],
                    defaults: {
                        // endpoint: this.crudConfig.read,
                        rootProperty: this.crudConfig.rootProperty
                    },
                    create: {
                        endpoint: this.crudConfig.create
                    },
                    read: {
                        endpoint: this.crudConfig.read,
                        params: {
                            incident_behavior_type_id: this.incidentBehaviorTypeId
                        }
                    },
                    update: {
                        endpoint: this.crudConfig.update
                    },
                    destroy: {
                        endpoint: this.crudConfig.destroy
                    }
                }
            }
        },
        methods: {
            editClick(p) {
                if (p.colDef.headerName == 'Edit') {
                    this.editing = p.data
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.field-edit {
    color: gray;
    margin-right: 8px;
}

</style>