<template>
    <div class="d-block flex-fill flex-column" style="height: 100%">
        <div v-if="!!params || sloRecord.id" ref="chartWrapper" class="d-flex mx-4" style="height: calc(100% + 250px);">
            <v-carousel
                v-model="activePage"
                height="100%"
                hide-delimiters light
                :show-arrows="false"
            >
                <v-carousel-item key="0">
                    <div class="d-flex" style="height: 100%; ">
                        <div class="d-flex px-4 mt-4" style="height: 100%; width: 50%;">
                            <highcharts
                                v-if="!noData"
                                ref='highchart'
                                style="height: 100%; width: 100%;"
                                :options="localConfig"
                            />

                            <fe-mask v-if="show === 'loading'" :showLoader="false"/>
                        </div>

                        <div class="d-block px-4" style="height: 100%; width: 50%; border-left: 1px solid #E0E1E6;">
                            <information
                                :params="params ? params : overallRecord.student_params"
                                :clickRecord="clickRecord ? clickRecord : clickRecordExisting"
                                :categoryId="categoryId"
                                :sloRecordExists="sloRecord"
                                style="height: 100%; width: 100%;"
                                @update="sloRecord = {...sloRecord, ...$event}"
                                @validate="isBasicInformationValid = $event"
                            />
                        </div>
                    </div>
                </v-carousel-item>

                <v-carousel-item key="1">
                    <div class="d-flex" style="height: 100%; ">
                        <div class="d-flex px-4 mt-4" style="height: 100%; width: 50%;">
                            <highcharts
                                v-if="!noData"
                                ref='highchart'
                                style="height: 100%; width: 100%;"
                                :options="localConfig"
                            />

                            <fe-mask v-if="show === 'loading'" :showLoader="false"/>
                        </div>

                        <div class="d-block px-4" style="height: 100%; width: 50%; border-left: 1px solid #E0E1E6">
                            <goals
                                :options="localConfig.series"
                                style="height: 100%; width: 100%;"
                                :sloRecord="params ? params : sloRecord"
                                @update="updateChart"
                                @validate="isGoalsValid = $event"
                            />
                        </div>
                    </div>
                </v-carousel-item>

                <v-carousel-item key="2">
                    <div class="d-flex" style="height: 100%; ">
                        <div class="d-flex px-4 mt-4" style="height: 100%; width: 50%;">
                            <highcharts
                                v-if="!noData"
                                ref='highchart'
                                style="height: 100%; width: 100%;"
                                :options="localConfig"
                            />

                            <fe-mask v-if="show === 'loading'" :showLoader="false"/>
                        </div>

                        <div class="d-block px-4" style="height: 100%; width: 50%; border-left: 1px solid #E0E1E6">
                            <strategies
                                v-if="sloRecord"
                                :slo="sloRecord"
                                :strategies="sloRecord.strategies"
                                :dryRun="sloRecord.id === null"
                                @created="onCreateStrategy"
                                @updated="onUpdateStrategy"
                                @deleted="onDeleteStrategy"
                            />
                        </div>
                    </div>
                </v-carousel-item>

                <v-carousel-item key="3">
                    <div class="d-flex" style="height: 100%; ">
                        <div class="d-flex px-4 mt-4" style="height: 100%; width: 50%;">
                            <highcharts
                                v-if="!noData"
                                ref='highchart'
                                style="height: 100%; width: 100%;"
                                :options="localConfig"
                            />

                            <fe-mask v-if="show === 'loading'" :showLoader="false"/>
                        </div>
                        <div class="d-block px-4" style="height: 100%; width: 50%; border-left: 1px solid #E0E1E6">
                            <logistics
                                v-if="sloRecord"
                                :slo="sloRecord"
                                :logistics="sloRecord.logistics"
                                :dryRun="sloRecord.id === null"
                                @created="onCreateLogistic"
                                @updated="onUpdateLogistic"
                                @deleted="onDeleteLogistic"
                            />
                        </div>
                    </div>
                </v-carousel-item>

                <v-carousel-item key="4">
                    <congratulations
                        :isNewSLO="isNewSLO"
                        :id="sloRecord.id"
                        @close="$emit('close')"
                    />
                </v-carousel-item>
            </v-carousel>
        </div>

        <v-footer class="d-flex justify-end" absolute color="white">
            <fe-btn
                v-if="activePage > 0"
                usage="tertiary"
                @click="activePage -= 1"
            >
                Back
            </fe-btn>

            <fe-btn
                v-if="activePage > 0 && isGoalsValid && activePage < 3"
                @click="saveAndConclude"
                usage="secondary"
                style="margin-left: auto;"
            >
                Save
            </fe-btn>

            <fe-btn
                v-if="(sloRecord.id && activePage === 0) || activePage === 1"
                :disabled="(activePage === 0 && !isBasicInformationValid) || (activePage === 1 && !isGoalsValid)"
                @click="nextClicked"
                :style="{'margin-left': activePage > 0 ? 0 : 'auto'}"
            >
                Save &amp; Next
            </fe-btn>

            <fe-btn v-else-if="activePage < 3" :disabled="(activePage === 0 && !isBasicInformationValid) || (activePage === 1 && !isGoalsValid)" @click="activePage += 1">
                Next
            </fe-btn>

            <fe-btn
                v-else-if="activePage === 3"
                :disabled="(activePage === 0 && !isBasicInformationValid) || (activePage === 1 && !isGoalsValid)"
                @click="onGoToCongratulationsScreen"
                usage="primary"
                style="margin-left: auto;"
            >
                Save
            </fe-btn>
        </v-footer>
    </div>
</template>

<script>
    import windowOptionsMixin from '@/mixins/windowOptions'
    import Highcharts from 'highcharts'
    import Information from './Information'
    import Goals from './Goals'
    import Strategies from '@/components/modules/slo/creation/datawall/Strategies'
    import Logistics from '@/components/modules/slo/creation/datawall/Logistics'
    import Congratulations from '@/components/modules/slo/creation/datawall/Congratulations'

    export default {
        name: 'SloCreationCharting',
        mixins: [ windowOptionsMixin ],
        components: { Information, Goals, Strategies, Logistics, Congratulations },
        props: {
            id: {
                default: null
            },
            params: {
                type: Object,
                return: () => {}
            },
            overallResults: {
                type: Object,
                default: () => {}
            },
            clickRecord: {
                type: Object,
                default: () => {}
            },
            targetSet: {
                type: Object,
                default: () => {}
            },
            options: {
                type: Object,
                default: () => {}
            },
            categoryId: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                isNewSLO: false,
                activePage: 0,
                isBasicInformationValid: false,
                isGoalsValid: false,
                noData: true,
                chartOptionsShow: false,
                goalsShow: false,
                overallRecord: [],
                clickRecordExisting: {},
                chartRecord: [],
                fields: [],
                colors: [],
                targetDescriptors: [],
                trendline: {},
                seriesConfig: [],
                localConfig: {},
                sloRecord: {
                    plan: {},
                    goals: [],
                    strategies: [],
                    logistics: []
                },
                sloCopy: {},
            }
        },

        computed: {
            show() {
                if (this.noData) return false
                return this.chartOptionsShow ? 'chart' : 'loading'
            },

            chartWrapper() {
                return this.$refs.chartWrapper?.clientHeight - 200
            },
        },

        methods: {
            parseConfig(overallResults) {
                let me = this
                let trendline_data = []
                let descriptors = []
                let chartType = this.params?.selected_target_type

                this.overallRecord = overallResults
                let newClickRecord = this.clickRecord ? this.clickRecord : this.clickRecordExisting
                let goalObj = {}

                if (!this.isNewSLO) {
                    goalObj = {
                        name: "goal",
                        target_descriptors: null,
                        goal: null
                    }
                    if (this.sloRecord.goals.length > 1) {
                        this.sloRecord.goals.forEach(goal => {
                            goalObj[goal.target_descriptor_name] = goal.goal ? parseInt(goal.goal) : goal.data[0]
                            goalObj.target_descriptors = this.sloRecord.goals
                        })
                    } else {
                        goalObj['No Target'] = newClickRecord['No Target']
                        goalObj['Avg Score'] = newClickRecord['Avg Score']
                        goalObj.baseline = parseInt(this.sloRecord.goals[0]?.baseline)
                        goalObj.goal = parseInt(this.sloRecord.goals[0]?.goal)
                        goalObj.target_descriptors = []
                    }
                }

                if (!newClickRecord.hasOwnProperty('goal')) newClickRecord.goal = null

                this.chartRecord = this.isNewSLO ? [newClickRecord, newClickRecord] : [newClickRecord, goalObj]
                this.fields = chartType && this.overallRecord[chartType + '_fields'].length ? this.overallRecord[chartType + '_fields'] : this.overallRecord.basic_fields.length ? this.overallRecord.basic_fields : this.overallRecord.detailed_fields
                this.colors = chartType && this.overallRecord[chartType + '_colors'].length ? this.overallRecord[chartType + '_colors'] : this.overallRecord.basic_colors.length ? this.overallRecord.basic_colors : this.overallRecord.detailed_colors.length ? this.overallRecord.detailed_colors : ['#A4A4A4']
                if (this.isNewSLO) {
                    if (newClickRecord.target_descriptors.length){
                        newClickRecord.target_descriptors.forEach(desc => {
                            this.fields.forEach(field => {
                                if (field == desc.target_descriptor_name) {
                                    descriptors.push({
                                        name: field,
                                        target_descriptor_id: desc.target_descriptor_id ? desc.target_descriptor_id : null,
                                        target_descriptor_color: desc.target_descriptor_color ? desc.target_descriptor_color : null,
                                    })
                                }
                            })
                        })
                    } else {
                        descriptors.push({
                            name: 'No Target',
                            target_descriptor_id: null,
                            target_descriptor_color: '#A4A4A4',
                            data: [newClickRecord['No Target'], newClickRecord['No Target']]
                        })
                    }
                } else {
                    if (this.sloRecord.goals.length > 1) {
                        this.sloRecord.goals.forEach(rec => {
                            descriptors.push({
                                name: rec.target_descriptor_name,
                                target_descriptor_id: rec.target_descriptor_id,
                                target_descriptor_color: rec.target_descriptor_color,
                            })
                        })
                    } else {
                        descriptors.push({
                            name: 'No Target',
                            target_descriptor_id: null,
                            target_descriptor_color: '#A4A4A4',
                            data: [newClickRecord['No Target'], newClickRecord['No Target']]
                        })
                    }
                }

                this.targetDescriptors = descriptors
                this.trendline.fields = this.overallRecord.trendline_fields
                this.trendline.color = this.overallRecord.trendline_colors


                if (this.trendline.fields) {
                    this.trendline.fields.forEach(field => {
                        if (!field.hidden) {
                            this.chartRecord.forEach(record => {
                                if (typeof record[field.name] == 'undefined') record[field.name] = null
                            })

                            trendline_data.push({
                                name: field.name,
                                color: field.color,
                                data: [],
                                min: this.overallRecord.trendline_min,
                                max: this.overallRecord.trendline_max,
                                zIndex: 1
                            })
                        }
                    })
                    trendline_data.forEach(td => {
                        this.chartRecord.forEach(record => {
                            if (typeof record['goal'] != 'undefined' && record.goal) {
                                td.data.push(record.goal)
                            }
                            else if (typeof record[td.name] != 'undefined' && record[td.name]) {
                                td.data.push(record[td.name])
                            }
                            else if (typeof me.chartRecord[0][td.name] != 'undefined' && me.chartRecord[0][td.name]) {
                                td.data.push(me.chartRecord[0][td.name])
                            }
                            else {
                                td.data.push(0)
                            }
                        })
                    })
                    this.trendline.data = trendline_data
                }
                this.seriesConfig = this.buildSeries(this.chartRecord, this.targetDescriptors, this.colors)
                if (this.isNewSLO) this.seriesConfig.series.reverse()
                this.localConfig = this.buildChart(this.seriesConfig)
            },

            buildSeries(data, fields, colors) {
                let me = this
                let categories = []
                let series     = []

                this.localConfig.series = []
                for (let index in data) {
                    let r = data[index]
                    fields.forEach(function(field, fieldIndex) {
                        if (field.name == "name") return
                        let serie = me.$_.find(series, function(o) {
                            return o.name == field.name
                        })

                        if (!serie) {
                            serie = {
                                name: field.name,
                                target_descriptor_id: field.target_descriptor_id ? field.target_descriptor_id : null,
                                color: field.target_descriptor_color ? field.target_descriptor_color : colors[fieldIndex] ? colors[fieldIndex] : '#A4A4A4',
                                goal_target_descriptor_color: field.target_descriptor_color ? field.target_descriptor_color : colors[fieldIndex],
                                data: []
                            }
                            series.push(serie)
                        }

                        serie.data.push(r[field.name] ? r[field.name] : r.data ? r.data : 0)
                    })

                    categories.push(index == 0 ? r.name : 'Goal')
                }
                this.trendline.data.forEach(trend => {
                    series.push({
                        type: 'scatter',
                        data: trend.data.map(d=> d===undefined? d=null : d=d),
                        color: trend.color,
                        name: trend.name,
                        yAxis: 1,
                        zIndex: 1
                    })
                })
                return {
                    categories,
                    series
                }
            },

            buildChart(seriesConfig, update = false) {
                let me = this
                let totalStudents = 0
                let studentArr = []
                let studentStr = ' '

                if (!update) {
                    if (seriesConfig.categories.length > 1) {
                        seriesConfig.categories.forEach(cat => {
                            studentArr.push({'category': cat, 'data': 0})
                        })
                    }
                    seriesConfig.series.forEach(series => {
                        if (_.some(me.fields, _.method('includes', series.name))) {
                            if (series.data.length === 1) totalStudents += series.data[0]
                            else {
                                series.data.forEach((stdnt, i) => {
                                    studentArr[i].data += stdnt
                                })
                            }
                        }
                    })
                }

                let cfg = {
                    chart: {
                        type: 'column',
                        height: this.chartWrapper,
                        zoomType: 'xy'
                    },
                    credits: {
                        enabled: false
                    },
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    },
                    title: {
                        text: '',
                    },
                    xAxis: {
                        categories: seriesConfig.categories,
                        labels: {
                            formatter() {
                                if (this.value.length > 20) {
                                    return this.value.substring(0, 19) + '...'
                                }
                                return this.value
                            },
                            style: {
                                fontSize: '10px'
                            }
                        }
                    },
                    yAxis: [{
                        min: 0,
                        endOnTick: false,
                        max: undefined,
                        title: {
                            text: '# of Students'
                        }
                    }, {
                        title: {
                            text: ''
                        },
                        gridLineWidth: 0,
                        stackLabels: {
                            enabled: false,
                            style: {
                                fontWeight: 'bold'
                            }
                        },
                        opposite: true,
                        labels: {
                            formatter: (params) => {
                                let axisTitle = params?.axis?.axisTitle?.textStr
                                let dataPointTypeId = me.params ? me.params?.data_point_type_id : me.overallRecord.student_params.data_point_type_id
                                return (!axisTitle && dataPointTypeId)
                                    ? me.$getAlphaScore(dataPointTypeId, params.value)
                                    : params.value
                            }
                        },
                    }],
                    legend: {
                        enabled: true,
                        align: 'right',
                        verticalAlign: 'bottom',
                        shadow: false
                    },
                    tooltip: {
                        formatter: function () {
                            if (studentArr.length) {
                                studentArr.forEach(stdt => {
                                    if (stdt.category == this.x) studentStr = '<br>Total Students: <b>' + stdt.data + '</b>'
                                })
                            }
                            else studentStr = '<br>Total Students: <b>' + totalStudents + '</b>'

                            let dataPointTypeId = me.params ? me.params?.data_point_type_id : me.overallRecord.student_params.data_point_type_id
                            if (!this.percentage && !this.total && dataPointTypeId) return this.series.name + ': <b>' + me.$getAlphaScore(dataPointTypeId, this.y) + '</b>'
                            else if (this.total && this.y) {
                                let percent = (100 * this.y) / this.total
                                return '<b>' + this.x + '</b><br>' + this.series.name + ': <b>' + this.y + '</b><br> Percent: <b>' + (percent ? percent.toFixed(1) : '--') + '%</b>' + studentStr
                            }
                            else if (!this.percentage && !this.total) return this.series.name + ': <b>' + this.y + '</b>'
                            else return '<b>' + this.x + '</b><br>' + this.series.name + ': <b>' + this.y + '</b><br> Percent: <b>' + (this.percentage ? this.percentage.toFixed(1) : '--') + '%</b>' + studentStr
                        }
                    },
                    plotOptions: {
                        series: {
                            cursor: 'pointer',
                        },
                        column: {
                            maxPointWidth: 50,
                            stacking: 'stacking',
                            dataLabels: {
                                allowOverlap: true,
                                enabled: true
                            }
                        }
                    },
                    series: seriesConfig.series
                }
                return cfg
            },
            nextClicked() {
                if (this.activePage == 1) this.sloRecord.id ? this.doUpdate(this.sloRecord) : this.doSave(this.sloRecord)
                else this.activePage += 1
            },
            updateChart(config, type, total) {
                let me = this
                this.sloRecord.slo_goal_type_id = type
                this.sloRecord.target_set_id = this.params ? this.params.target_set_id : this.sloRecord.target_set_id

                if (this.sloRecord.id) {
                    config.forEach(c => {
                        me.sloRecord.goals.forEach(g => {
                            if (c.target_descriptor_id && c.target_descriptor_id == g.target_descriptor_id && !c.hasOwnProperty('type')) g.goal = c.data[1]
                            else if (c.hasOwnProperty('type') && c.name == 'Avg Score') g.goal = c.data[1]
                        })
                    })
                } else {
                    this.sloRecord.goals = []
                    config.forEach(c => {
                        me.sloRecord.goals.push({
                            ...c,
                            target_descriptor_id: c.target_descriptor_id ? c.target_descriptor_id : null,
                            total: total,
                            baseline: c.data[0],
                            goal: c.data[1],
                            slo_goal_type_id: type,
                            slo_goal_id: c.slo_goal_id ? c.slo_goal_id : null,
                            goal_target_descriptor_id: c.goal_target_descriptor_id ? c.goal_target_descriptor_id : null,
                        })
                    })
                }

                this.seriesConfig.series.forEach(s => {
                    config.forEach(c => {
                        if (type == 5 && s.hasOwnProperty('type') && s.name == 'Avg Score') {
                            s.goal = c.data[1]
                            s.data[1] = c.data[1]
                        }
                        else if (s.target_descriptor_id && s.target_descriptor_id == c.target_descriptor_id && !s.hasOwnProperty('type')) {
                            s.goal = c.data[1]
                            s.data[1] = c.data[1]
                        }
                    })
                })

                this.localConfig = {}
                this.localConfig = this.buildChart(this.seriesConfig)
            },
            async onCreateStrategy(obj) {
                let id = obj.id
                let strategy = await this.$axios.get(
                    `${this.$models.getUrl('sloStrategy', 'read')}&id=${id}`
                ).then(res => res.data.slo_strategies[0])

                this.sloRecord.strategies.push(strategy)
            },
            async onUpdateStrategy(obj) {
                let id = obj.id
                let strategy = await this.$axios.get(
                    `${this.$models.getUrl('sloStrategy', 'read')}&id=${id}`
                ).then(res => res.data.slo_strategies[0])
                let index = this.sloRecord.strategies.findIndex(itm => itm.id === strategy.id)
                if (index >= 0) {
                    this.sloRecord.strategies.splice(index, 1, strategy)
                }
            },
            onDeleteStrategy(obj) {
                this.sloRecord.strategies = this.sloRecord.strategies.filter(itm => itm.id !== obj.id)
            },
            async onCreateLogistic(obj) {
                let id = obj.id
                let logistic = await this.$axios.get(
                    `${this.$models.getUrl('sloLogistic', 'read')}&id=${id}&detailed=true`
                ).then(res => res.data.logistics[0])

                this.sloRecord.logistics.push(logistic)
            },
            async onUpdateLogistic(obj) {
                let id = obj.id
                let logistic = await this.$axios.get(
                    `${this.$models.getUrl('sloLogistic', 'read')}&id=${id}&detailed=true`
                ).then(res => res.data.logistics[0])

                let index = this.sloRecord.logistics.findIndex(itm => itm.id === logistic.id)
                if (index >= 0) {
                    this.sloRecord.logistics.splice(index, 1, logistic)
                }
            },
            onDeleteLogistic(obj) {
                this.sloRecord.logistics = this.sloRecord.logistics.filter(itm => itm.id !== obj.id)
            },
            doSave(item, closeOnSuccess = false) {
                this.$setLoading(true)
                let me = this
                let data = item ? item : this.sloRecord
                let avgScore = {}
                let stripScores = []
                let updateData = this.$_.cloneDeep(data)
                if (this.sloRecord.slo_goal_type_id === 1) {
                    updateData.goals = updateData.goals.filter((chart) => {
                        if (chart.hasOwnProperty('type') && chart.name != 'Avg Score') stripScores.push(chart)
                        if (chart.name == 'Avg Score' ) avgScore = chart
                        return chart.name != 'Avg Score' && !chart.hasOwnProperty('type')
                    })
                }
                this.$axios.post('slo.php?action=create', { slo: updateData })
                .then(r => {
                    if(r.data.success) {
                        this.$snackbars.$emit('new', { text: r.data.msg, usage: 'success' })
                        me.sloRecord = r.data.slo ? r.data.slo[0] : r.data[0]
                        if (this.sloRecord.slo_goal_type_id === 1) {
                            me.sloRecord.goals.unshift(avgScore)
                            if (stripScores.length) {
                                stripScores.forEach(score => {
                                    me.sloRecord.goals.unshift(score)
                                })
                            }
                        }
                        this.$setLoading(false)
                        this.sloCopy = this.$_.cloneDeep(me.sloRecord)
                        if (closeOnSuccess) this.activePage = 4
                        else this.activePage += 1
                    } else {
                        this.$snackbars.$emit('new', { text: r.data.msg, usage: 'warning' })
                        this.$setLoading(false)
                    }
                })
                this.$setLoading(false)
            },
            async doUpdate(item, closeOnSuccess = false) {
                this.isNewSLO = false
                this.$setLoading(true)
                let me = this
                let data = item ? item : this.sloRecord
                let updateData = this.$_.cloneDeep(data)

                if (this.$isDirty(updateData, this.sloCopy)) {
                    let formatData = await this.prepareDataForUpdate(updateData)

                    this.$axios.post('slo.php?action=update', { slo: formatData })
                    .then(r => {
                        if(r.data.success) {
                            this.$snackbars.$emit('new', { text: r.data.msg, usage: 'success' })
                            me.sloRecord = r.data.slo[0] ? r.data.slo[0] : r.data
                            this.$setLoading(false)
                            this.sloCopy = this.$_.cloneDeep(me.sloRecord)
                            if (closeOnSuccess) this.activePage = 4
                        } else {
                            this.$snackbars.$emit('new', { text: r.data.msg, usage: 'warning' })
                            this.$setLoading(false)
                        }
                    })
                }
                else if (closeOnSuccess) this.activePage = 4
                else this.activePage += 1
                this.$setLoading(false)
            },

            async prepareDataForUpdate(updateData) {
                await updateData.goals.forEach((goal, i) => {
                    if (!goal.slo_goal_id) {
                        updateData.goals.splice(i, 1)
                    }
                })

                await updateData.goals.forEach((goal, i) => {
                    if (goal.total && typeof(goal.total) == 'string') updateData.goals[i].total = parseFloat(goal.total)
                    if (goal.baseline && typeof(goal.baseline) == 'string') updateData.goals[i].baseline = parseFloat(goal.baseline)
                    if (goal.goal && typeof(goal.goal) == 'string') updateData.goals[i].goal = parseFloat(goal.goal)
                })

                delete updateData.students
                delete updateData.student_count

                return updateData
            },

            async onGoToCongratulationsScreen() {
                this.sloRecord.id ? await this.doUpdate(this.sloRecord, true) : await this.doSave(this.sloRecord, true)
            },

            saveAndConclude() {
                this.sloRecord.id ? this.doUpdate(this.sloRecord, true) : this.doSave(this.sloRecord, true)
            },
        },

        async mounted () {
            if (this.id) {
                this.isNewSLO = false
                let sloData = await this.$axios.get(`slo.php?action=get&id=${this.id}`).then(res => res.data)
                this.sloRecord = sloData.slo
                let targetScoresData = null
                targetScoresData = await this.$axios.get(
                    `targetScores.php?action=get&saved_search_id=${sloData.slo.plan.saved_search_id}`
                ).then(res => res.data.categories[0].stacked_charts[0])
                this.clickRecordExisting = targetScoresData.BASIC.length ? targetScoresData.BASIC[0] : targetScoresData.DETAILED[0]
                this.parseConfig(targetScoresData)
                this.noData = false
            }
            else {
                this.isNewSLO = true
                this.parseConfig(this.overallResults)
                this.noData = false
            }
        },

        watch: { }
    }
</script>

<style lang="scss" scoped></style>