let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    targetScores: {
        defaults: {
            endpoint: 'targetScores.php',
            rootProperty: 'categories'
        },
        ...standardCrud
    },
    studentScores: {
        defaults: {
            endpoint: 'studentScores.php',
            rootProperty: 'scores'
        },
        ...standardCrud
    },
    datawall: {
        defaults: {
            endpoint: 'targetScores.php?property=datawall',
            rootProperty: 'datwall'
        },
        read: {
            params: { action: 'get' }
        }
    },
    studentScoreDetail: {
        defaults: {
            endpoint: 'scoreDetails.php',
            rootProperty: 'details'
        },
        ...standardCrud
    }
}

