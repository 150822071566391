<template>
    <a class="weblinkfield"
       :style="{
           height: height ? height+'px' : undefined,
           textAlign: fieldRec.value_align || 'left',
           fontSize: fieldRec.font_size ? fieldRec.font_size+'px': null,
       }"
       @click="openWindow()"
    >
       {{fieldRec.display_value ? fieldRec.display_value : fieldRec.property}}
    </a>
</template>

<script>
    export default {
        name: 'WebLinkField',
        props: ['fieldRec','height'],
        data() {
            return {}
        },
        methods: {
            openWindow() {
                let url = ''
                // need to prepend with http:// otherwise window.open will append eC url with entered url
                if(!this.fieldRec.property.startsWith('http://') && !this.fieldRec.property.startsWith('https://')) url = 'http://' + this.fieldRec.property
                else url = this.fieldRec.property
                window.open(url)
            }
        },
        watch: {
            'fieldRec.property'(v) {
                if(v) {
                    this.$emit('blockError',false)
                } else {
                    this.$emit('blockError',!this.fieldRec.property)
                }
            }
        },
        mounted() {
            if(!this.fieldRec.property) {
                this.$emit('blockError',!this.fieldRec.property)
            }
        },
    }
</script>

<style lang="scss" scoped>
    .weblinkfield {
        color: #0049ff;
        font-size: 14px;
    }
</style>
