<template>
    <fe-dialog 
        title="Tag Student"
        v-if="localValue"
        disableAutoclose
        :forceClose="false"
        @accept="creating ? createTag() : doTag()"
        @close="closeDialog()"
        @dismiss="creating ? creating=false : closeDialog()"
        :acceptButtonDisabled="creating ? !canCreate : !canSave"
        persistent
        dismissButtonText="Cancel"
        acceptButtonText="Save"
    >
        <v-slide-y-transition mode="out-in">
            <div v-if="!creating" key="existing">
                <div>
                    <fe-label>School Year</fe-label>
                    <fe-remote-combo
                        :url="$models.getUrl('schoolYear', 'read')"
                        itemText="name"
                        itemValue="id"
                        :multiple="false"
                        rootProperty="years"
                        v-model="model.school_year_id"
                        byId
                    />
                </div>

                <div class="d-flex">
                    <div>
                        <fe-label>Tag</fe-label>
                        <tag-combo
                            v-model="model.tag_id"
                            byId
                            multiple
                        />
                    </div>

                    <fe-btn usage="secondary" style="margin-top: 18px;" @click="creating=true">New Tag</fe-btn>
                </div>

                <div style="font-size: 12px; font-weight: bold;">Tagging {{students.length}} student(s)</div>
            </div>

            <div v-else key="new">
                <div class="d-flex">
                    <div class="flex-grow-1">
                        <fe-label>Name</fe-label>
                        <v-text-field
                            flat solo dense
                            v-model="newTag.name"
                            counter=50
                            :rules="$fieldValidators('text', null, {required: true, limit: 50})"
                        />
                    </div>

                    <div class="ml-2" style="padding-top: 25px;">
                        <fe-color-selector class="mt-4" v-model="newTag.color"></fe-color-selector>
                    </div>
                </div>

                <fe-label>Description</fe-label>
                <v-textarea
                    flat solo dense
                    v-model="newTag.description"
                    :counter="350"
                    :rules="$fieldValidators('text', null, {required: true, limit: 350})"
                />

                <v-checkbox label="Viewable to others?" v-if="$hasSecurity('MANAGE_PUBLIC_TAGS')" v-model="newTag.public_flag"/>

            </div>
        </v-slide-y-transition>
    </fe-dialog>
</template>

<script>
    import TagCombo from '@/components/common/combo/TagCombo'
    export default {
        name: 'TagStudent',
        components: { TagCombo },
        props: {
            value: { type: Boolean, default: false},
            students: { type: Array }
        },
        computed: {
            canCreate() {
                let newTag=this.newTag
                return (newTag.name && newTag.description && newTag.color) ? true : false
            },
            canSave() {
                return (this.model.school_year_id && this.model.tag_id) ? true : false
            }
        },
        data() {
            return {
                localValue: false,
                model: {
                    school_year_id: null
                },
                creating: false,
                newTag: {
                    name: '',
                    description: '',
                    color: '#006C90',
                    public_flag: false
                }
            }
        },
        watch: {
            localValue(v) {
                this.$emit('input', v)
            },
            value(v) {
                this.localValue=v
            }
        },
        mounted() {
            this.model.school_year_id = this.$store.state.global.currentYear.id
            this.localValue=this.$props.value
        },
        methods: {
            doTag() {
                let arr = []
                if (this.$_.isArray(this.model.tag_id)) {
                    this.model.tag_id.forEach(tag  => {
                        arr.push(this.students.map(s => { return {student_id: s.student_id, tag_id: tag, school_year_id: this.model.school_year_id} }))
                    })
                    arr = arr.flat()
                } else {
                    arr = this.students.map(s => { return {student_id: s.student_id, tag_id: this.model.tag_id, school_year_id: this.model.school_year_id} })
                }
                this.$axios.post('tags.php?action=create&property=student_tags', { student_tags: arr })
                    .then(r => {
                        this.$ecResponse(r)
                        this.closeDialog()
                    })
            },
            createTag() {
                this.$axios.post('tags.php?action=create', { tags: [this.newTag ]})
                    .then(r => {
                        this.$ecResponse(r)
                        this.creating=false
                    })
            },
            closeDialog() {
                this.$store.commit('global/tagStudents', {
                    show: false,
                    students: []
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>