<template>
    <div class="container">
      <div>
        <a @click="$router.push('/smartFORMS')"><v-icon class="mr-2">fas fa-chevron-left</v-icon> Back to smartFORMS</a>
        <h1>Email smartFORMS to Guardians</h1>
      </div>
      <div class="bar">
        <!-- Review Email -->
        <div class="segment" :class="{ active: !choosingSettings && !previewingEmail && !sendingEmail }">
          <i class="far fa-check" v-if="choosingSettings || previewingEmail || sendingEmail" style="margin-right: 6px;"></i>
          Review guardians & forms
        </div>
        <div class="triangle-right" :class="{ active: !choosingSettings && !previewingEmail && !sendingEmail }"></div>
        <!-- Choose Settings -->
        <div class="triangle-left" :class="{ active: choosingSettings }"></div>
        <div class="segment" :class="{ active: choosingSettings }">
          <i class="far fa-check" v-if="previewingEmail || sendingEmail" style="margin-right: 6px;"></i>
          Choose settings
        </div>
        <div class="triangle-right" :class="{ active: choosingSettings }"></div>
        <!-- Preview Email -->
        <div class="triangle-left" :class="{ active: previewingEmail }"></div>
        <div class="segment" :class="{ active: previewingEmail }">
          <i class="far fa-check" v-if="sendingEmail" style="margin-right: 6px;"></i>
          Preview email & more
        </div>
        <div class="triangle-right" :class="{ active: previewingEmail }"></div>
        <!-- Send Email -->
        <div class="triangle-left" :class="{ active: sendingEmail }"></div>
        <div class="segment" :class="{ active: sendingEmail }">Review summary & Send</div>
      </div>
      <review
        v-if="!choosingSettings && !previewingEmail && !sendingEmail"
        :students-count="studentsCount"
        :guardian-contacts-count="guardianContactsCount"
        :smartforms-count="smartformsCount"
        :column-defs="columnDefs"
        :row-data="rowData"
        @goToCommunicationSettings="goToCommunicationSettings"
      />
      <settings
        v-if="choosingSettings"
        @goToPreview="goToPreview"
        @goToReview="goToReview"
        @response-required-changed="updateResponseRequired"
      />
      <preview
        v-if="previewingEmail"
        :expiration-date="getLongExpirationDate()"
        :short-expiration-date="getShortExpirationDate()"
        :short-current-date="getShortCurrentDate()"
        @goToCommunicationSettings="goToCommunicationSettings"
        @goToSend="goToSend"
      />
      <send
        v-if="sendingEmail"
        :expiration-date="getLongExpirationDate()"
        :students-count="studentsCount"
        :guardian-contacts-count="guardianContactsCount"
        :smartforms-count="smartformsCount"
        :response-required="responseRequired"
        :form-instances="formInstances"
        @goToPreview="goToPreview"
        />
    </div>
</template>
<script>
    import { AgGridVue } from "@ag-grid-community/vue"
    import Review from './Review.vue'
    import Settings from "./Settings.vue"
    import Preview from "./Preview.vue"
    import Send from "./Send.vue"

    export default {
        name: 'GuardianEmailSetup',
        components: {
            AgGridVue,
            Review,
            Settings,
            Preview,
            Send,
        },
        data() {
            return {
                studentsCount: 0,
                guardianContactsCount: 0,
                smartformsCount: 0,
                columnDefs: [
                    { headerName: "Student", field: "student" },
                    { headerName: "Guardian Contacts", field: "guardianContacts", width: 400 },
                    { headerName: "# of Forms", field: "studentFormCount", width: 112 },
                    { headerName: "smartFORMS", field: "smartforms", width: 420 },
                ],
                rowData: [],
                choosingSettings: false,
                previewingEmail: false,
                sendingEmail: false,
                responseRequired: false,
            }
        },
        created() {
        },
        mounted() {
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.resetState()
                vm.loadSetupData()
            })
        },
        beforeRouteUpdate(to, from, next) {
            this.resetState()
            this.loadSetupData()
            next()
        },
        beforeRouteLeave(to, from, next) {
            this.resetState()
            next()
        },
        methods: {
            resetState() {
                this.choosingSettings = false
                this.previewingEmail = false
                this.sendingEmail = false
                this.rowData = []
                this.guardianContactsCount = 0
                this.smartformsCount = 0
                this.studentsCount = 0
            },
            loadGuardianFormEmailSetup(formIds, callback) {
                this.$modelGet('guardianFormEmailSetup', { form_instances: formIds })
                    .then(response => callback(response))
                    .catch(err => console.warn('Unable to load guardian form email setup', err))
            },
            loadSetupData() {
                const formIds = this.$route.query.forms
                if (!formIds) return

                this.loadGuardianFormEmailSetup(formIds, (setupData) => {
                    if (!setupData) {
                        return
                    }
                    this.getSetupData(setupData)
                    this.formInstances = []
                    Object.values(setupData.students).forEach(student => {
                        this.formInstances = this.formInstances.concat(student.form_instances)
                    })
                })
            },
            getSetupData(setupData) {
                const rowData = Object.values(setupData.students).map(student => {
                    let guardianContacts = ''
                    if (Array.isArray(student.guardians)) {
                        const emailAddresses = student.guardians.map(guardian => guardian.guardian_email_addr).filter(email => email)
                        guardianContacts = emailAddresses.join(', ')
                    } else {
                        console.log('Guardians is not an array:', student.guardians)
                    }

                    return {
                        student: student.student_full_name,
                        guardianContacts: guardianContacts,
                        studentFormCount: student.form_instances.length,
                        smartforms: student.form_names.join(', ')
                    }
                })

                this.rowData = rowData
                this.studentsCount = rowData.length
                this.guardianContactsCount = rowData.reduce((acc, student) => acc + (student.guardianContacts ? student.guardianContacts.split(', ').length : 0), 0)
                this.smartformsCount = rowData.reduce((acc, student) => acc + student.studentFormCount, 0)
            },
            goToReview() {
                this.resetState()
            },
            goToCommunicationSettings() {
                this.choosingSettings = true
                this.previewingEmail = false
                this.sendingEmail = false
            },
            goToPreview() {
                this.choosingSettings = false
                this.previewingEmail = true
                this.sendingEmail = false
            },
            goToSend() {
                this.choosingSettings = false
                this.previewingEmail = false
                this.sendingEmail = true
            },
            updateResponseRequired(value) {
                this.responseRequired = value
            },
            getLongExpirationDate() {
                const today = new Date()
                const nextWeek = new Date(today)
                nextWeek.setDate(today.getDate() + 7)
                return nextWeek.toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' })
            },
            getShortExpirationDate() {
                const today = new Date()
                const nextWeek = new Date(today)
                nextWeek.setDate(today.getDate() + 7)
                return nextWeek.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })
            },
            getShortCurrentDate() {
                const today = new Date()
                return today.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })
            },
        },
    }
</script>
<style scoped>
    .container {
        position: relative;
        margin: 16px;
    }

    .container > div > a {
        text-decoration: none;
    }

    .container > div > h1 {
        margin-top: 16px;
    }

    .segment {
        flex: 1;
        text-align: center;
        font-weight: bold;
        height: 48px;
        font-size: 14px;
        font-family: CerebriSans-Regular, Cerebri Sans, Roboto, sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: 400;
        background-color: #ECEDF1;
    }

    .segment.active {
        background-color: #E5F0F4;
    }

    .triangle-right.active {
        border-top: 24px solid white;
        border-left: 50px solid #E5F0F4;
        border-bottom: 24px solid white;
    }

    .triangle-left.active {
        border-top: 24px solid #E5F0F4;
        border-left: 50px solid transparent;
        border-bottom: 24px solid #E5F0F4;
    }

    .count {
        font-size: 20px;
        font-weight: bold;
    }

    .bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        padding: 10px;
        margin-left: -8px;
        margin-top: 32px;
        margin-bottom: 28px;
        width: 92vw;
    }

    .segment {
        flex: 1;
        text-align: center;
        font-weight: bold;
        height: 48px;
        font-size: 14px;
        font-family: CerebriSans-Regular, Cerebri Sans, Roboto, sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: 400;
    }

    .triangle-right {
        width: 0;
        height: 0;
        border-top: 24px solid white;
        border-left: 50px solid #ECEDF1;
        border-bottom: 24px solid white;
    }

    .triangle-left {
        width: 0;
        height: 0;
        border-top: 24px solid #ECEDF1;
        border-left: 50px solid transparent;
        border-bottom: 24px solid #ECEDF1;
        margin-left: -32px;
    }
</style>
