<template>
    <!-- :title="show == 'identical' ? 'Duplicate Form' : 'Copy Data to New Form'" -->
    <fe-dialog
        v-if="records && records.length"
        title="Copy smartFORM"
        :acceptButtonDisabled="!valid"
        @dismiss="$emit('close')"
        @accept="copyForm"
        dismissButtonText="Cancel"
        :acceptButtonText="acceptButtonText"
        persistent
        width="550"
    >
        <v-form v-if="hasLive">
            You have included some forms that were created from Live Templates.
            Copying these types of forms have not yet been implemented.
        </v-form>

        <v-form v-else @submit.prevent v-model="valid">
            <fe-label>Name Postfix
                <fe-info-tip
                    class="mx-1 d-inline-block"
                    tooltip="This text will be appended to the names of each smartFORM with a space between"
                />
            </fe-label>
            <v-text-field
                :rules="rules"
                :counter="postfixMaxLength"
                flat solo dense
                v-model="postfix"
            />
            <v-radio-group v-model="copyType">
                <v-radio value="identical">
                    <template #label>
                        <div>
                            <div class="font-weight-bold">Identical Copy:</div>
                            This will make an identical copy of this smartFORM.
                            If there were changes made to the template after the creation of the original form,
                            those changes will not be reflected in this duplicate copy.
                        </div>
                    </template>
                </v-radio>
                <v-radio value="updated" :disabled="false">
                    <template #label>
                        <div>
                            <div class="font-weight-bold">Updated Copy:</div>
                            This will port over this smartFORM's data to a new form using the latest
                            version of the form template. If changes were made to the template after the creation of the original form,
                            those changes will be reflected in this form.
                        </div>
                    </template>
                </v-radio>
            </v-radio-group>

            <div v-if="copyType=='updated' && oldForms.length">
                <div class="px-4">
                    Note: Templates created before April 2021 may have responses that cannot be updated.
                    Of the selected forms, the ones with the following templates have fields that
                    <b>may not</b> copy over the reponses from the original form.
                    As a result these fields <b>may</b> be blank in the new copies.
                </div>


                <v-expansion-panels class="mt-4">
                    <v-expansion-panel v-for="form in oldForms">
                        <v-expansion-panel-header class="py-1">{{form.form_name}}<span class="ml-4">({{form.fields.length}} fields)</span></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list dense>
                                <v-list-item style="border-bottom: 1px solid #ced0d9;">
                                    <v-list-item-title class="font-weight-bold">Section</v-list-item-title>
                                    <v-list-item-title class="font-weight-bold">Field</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-for="field in form.fields">
                                    <v-list-item-title class="fs10" v-text="field.form_section_name" />
                                    <v-list-item-title class="fs10" v-text="field.name" />
                                </v-list-item>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <!-- <div v-for="form in oldForms" class="flex">
                    <div>{{form.form_name}}</div>
                    <div></div>
                </div> -->
            </div>

        </v-form>
    </fe-dialog>
</template>

<script>
export default {
    name: 'CopyFormDialog',
    props: {
        records: {
            type: Array,
        },
    },
    watch: {
        records(v) {
            if(v && v.length) {
                this.valid = false
                this.copyType = 'identical'
                this.nameLength = this.records.reduce((acc,x) => {
                    if(x.display_name.length > acc) acc = x.display_name.length
                    return acc
                },0)

                // temp
                this.checkForOldFields()
            }
        },
    },
    computed: {
        hasLive: me => me.records.some(x => x.form_live),
        acceptButtonText: me => me.copyType == 'updated' && me.oldForms.length ? 'Copy Anyway' : 'Copy',
        postfixMaxLength: me => 50,
        rules: me => [
            x => !me.$_.isBlank(x),
            x => x.length <= me.postfixMaxLength,
        ]
    },
    methods: {
        checkForOldFields() {
            this.$axios.post('formResponse.php?action=copy&property=old_fields', {
                form_templates: this.records.map(x => x.form_id)
            }).then(r => {
                this.oldForms = r.data.forms
            })
        },
        copyForm() {
            let pf = ' ' + this.postfix
            let form_instances = this.records.map(x => ({
                form_instance_id: x.form_instance_id,
                display_name: x.display_name.slice(0,100 - pf.length) + pf
            }))

            let property = this.copyType == 'identical' ? 'form_instance' : 'updated_form_instance'
            this.$axios.post('formResponse.php?action=copy&property='+property, {
                form_instances: form_instances,
            }).then(r =>{
                // close only on success
                // reload if not ba
                if(r.data.batch_job_id) {
                    var me = this
                    this.$root.$emit('waiterDialog', {
                        action: 'create',
                        text: 'Copying Forms',
                        successText: 'Your smartFORMS have been copied',
                        successSubText: ' ',
                        batch_job_id: r.data.batch_job_id,
                        content_count: form_instances.length,
                        callback: function() {
                            me.$emit('reload',{deferred:true})
                        },
                    })
                    this.$emit('reload')
                    this.$emit('close')
                } else {
                    if(r.data.success) {
                        this.$snackbars.$emit('new', { text: r.data.msg, usage: 'success' })
                        this.$emit('reload')
                        this.$emit('close')
                    } else {
                        this.$snackbars.$emit('new', { text: r.data.msg, usage: 'error' })
                        this.$emit('close')
                    }
                }
            })


        },
    },
    data() {
        return {
            valid: false,
            copyType: 'identical',
            postfix: '(Copy)',
            nameLength: 0,
            oldForms: [],
        }
    }
}
</script>

<style lang="css" scoped>
</style>
