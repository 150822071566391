<template>
    <v-form v-model="validForm">
        <div>
            <fe-label>{{ featureFlagMultipleInterventionists ? 'Primary Interventionist' : 'Interventionist' }}</fe-label>
            <fe-remote-combo
                itemText="user_full_name"
                itemValue="id"
                :items="interventionists"
                :rules="$fieldValidators('select', 'Interventionist', { required: true })"
                v-model="localValue.user_id"
            />
        </div>
        <a
            v-if="featureFlagMultipleInterventionists && !showAdditionalInterventionsts"
            @click="showAdditionalInterventionsts = true"
            style="position:relative;left:20px;top:-15px;"
        >
            <v-icon size="12" color="#006c90" style="position:relative;top:-2px;padding-right:10px;">fas fa-plus</v-icon> Add Interventionists
        </a>

        <div v-if="featureFlagMultipleInterventionists && showAdditionalInterventionsts">
            <fe-label>
                Additional Interventionists (Optional)
                <fe-info-tip
                    class="ml-2"
                    style="display:inline-block;"
                    tooltip="You can add up to 5 additional interventionists."
                />
            </fe-label>
            <fe-remote-combo
                v-model="localValue.secondary_interventionists"
                itemText="user_full_name"
                itemValue="id"
                chips multiple
                :clearable="false"
                :items="filteredSecondaryInterventionists"
                :selectAllOption="false"
                :rules="secondaryInterventionistValidation"
            />
        </div>

        <div>
            <fe-label>Level</fe-label>
            <fe-remote-combo
                :url="$models.getUrl('interventionLevel', 'read') + '&active_only=1'"
                rootProperty="intervention_levels"
                :rules="$fieldValidators('select', 'Intervention level', { required: true })"
                v-model="localValue.intervention_level_id"
            />
        </div>

        <div>
            <fe-label>Skill Area</fe-label>
            <fe-remote-combo
                :items="categories"
                :rules="$fieldValidators('select', 'Skill area', { required: true })"
                v-model="localValue.category_rec"
                @change="resetValues();$store.commit('intervention/set', { key: 'categoryId', value: localValue.category_rec ? localValue.category_rec.id : null })"
                :disabled="editBehaviorPlan?true:false"
            />
        </div>

        <div>
            <fe-label>Strategy</fe-label>
            <fe-remote-combo
                :disabled="!localValue.category_rec"
                :items="interventionTypes"
                :rules="$fieldValidators('select', 'Intervention strategy', { required: true })"
                v-model="localValue.intervention_type_id"
                @change="localValue.intervention_name = ''"
            />
        </div>

        <div>
            <fe-label>Name</fe-label>
            <v-text-field
                :rules="$fieldValidators('text', 'Intervention name', { required: true, limit: 100 })"
                flat solo dense
                v-model="localValue.intervention_name"
            />

            <div v-if="autoSuggested" style="font-size: 12px; top: -20px; position: relative;">
                <v-icon class="mx-2">fal fa-info-circle</v-icon>This name has been autosuggested
            </div>
        </div>

        <div class="d-flex">
            <fe-btn usage="primary" class="ml-auto" @click="$emit('add')" :disabled="!isValid">Add</fe-btn>
        </div>
    </v-form>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'InterventionDetails',
        props: {
            value: {
                default() { return {} }
            },
            allowBehavior: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                validForm: false,
                showAdditionalInterventionsts: false,
                localValue: {
                    user_id: null,
                    category_id: null,
                    category_rec: null,
                    secondary_interventionists: [],
                    intervention_level_id: null,
                    intervention_name: '',
                    intervention_type_id: null,
                },
                interventionists: [],
                autoSuggested: false,
                clearAutoSuggestionOnNextChange: false,
                interventionTypes: [],
                categories: []
            }
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            ...mapState('intervention', ['editBehaviorPlan', 'school']),
            isValid() {
                return this.validForm && this.$checkAllKeys(this.$data.localValue, ['user_id', 'intervention_level_id', 'intervention_type_id', 'intervention_name'])
            },
            filteredSecondaryInterventionists() {
                // don't show the primary interventionist
                const primaryInterventionist = this.localValue.user_id
                return this.interventionists.filter(secondaryInterventionist => secondaryInterventionist.id != primaryInterventionist?.id)
            },
            secondaryInterventionistValidation: () => [
                v => v.length <= 5 || 'You can add up to 5 secondary interventionists.'
            ],
            featureFlagMultipleInterventionists () {
                return this.$store.getters['flags/flags']['ec-multiple-interventionists'] === true
            },
        },
        watch: {
            'localValue.user_id'(primaryInterventionist) {
                // remove selected primary interventionist from secondary interventionists if present
                this.localValue.secondary_interventionists = this.localValue.secondary_interventionists.filter(
                    secondaryInterventionist => secondaryInterventionist.id != primaryInterventionist?.id
                )
            },
            'localValue.intervention_type_id'(v) {
                this.$store.commit('intervention/set', {
                    key: 'interventionTypeId',
                    value: v
                })
                this.localValue.intervention_type_id = v
                this.autoSuggestName()
            },
            'localValue.intervention_name'(newName, oldName) {
                if (newName != oldName) {
                    if (!this.clearAutoSuggestionOnNextChange) {
                        this.clearAutoSuggestionOnNextChange = true
                        return
                    }
                    this.autoSuggested = false
                }
            },
            'localValue.category_rec': {
                handler(v) {
                    if (v) {
                        this.$modelGet('interventionType', { active: 1, category_id: v.id, school_id: this.school ? this.school.id : null })
                            .then(resp => {
                                this.interventionTypes = resp.filter(rec => rec.name != "")
                            })
                    }
                    this.localValue.category_id = v?.id
                    this.autoSuggestName()
                }
            },
            localValue: {
                deep: true,
                handler(v) {
                    // emit value changes to index
                    this.$emit('input', v)
                }
            }
        },
        mounted() {
            // pull previous values into localValue
            if (this.$props.value) {
                this.localValue = this.$_.assign(this.localValue, this.$props.value)
            }

            // default to current user if a user is not selected
            if (!this.localValue.user_id) this.localValue.user_id = this.sessionUser.user

            // show additional interventionists if any are selected
            this.showAdditionalInterventionsts = this.localValue.secondary_interventionists.length > 0

            // load categories
            this.$modelGet('category').then(r => {
                r.forEach(r => r.id === 4 ? r.name = r.name + ' (Point Sheet)' : r.name)

                if (this.allowBehavior) {
                    this.categories = r
                } else {
                    this.categories = r.filter(c => c.id != 4)
                }

                // If FB monitor, set assessment category (skill area)
                if (this.$parent.fbMonitorAvailable) {
                    let fbSkill = r.filter(c => c.id === this.$parent.fbMonitorSkill)
                    this.localValue.category_rec = fbSkill[0]
                }
            })

            // If FB monitor name available, set name
            if (this.$parent.fbMonitorName) {
                this.localValue.intervention_name = this.$parent.fbMonitorName
                this.autoSuggested = true
                this.clearAutoSuggestionOnNextChange = true
            }

            // load interventionists
            this.$axios.get(this.$models.getUrl('user', 'read') + '&active=1')
                .then(response => {
                    this.$data.interventionists = response.data
                })
        },
        methods: {
            autoSuggestName() {
                if (this.localValue.intervention_type_id && this.localValue.category_rec && !this.localValue.intervention_name) {
                    this.localValue.intervention_name = this.localValue.category_rec.name + ": " + this.localValue.intervention_type_id.name
                    this.autoSuggested = true
                    this.clearAutoSuggestionOnNextChange = false
                } else {
                    this.autoSuggested = false
                    this.clearAutoSuggestionOnNextChange = true
                }
            },
            resetValues() {
                this.localValue.intervention_type_id = null
                this.localValue.intervention_name = ''
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
