<template>
    <div class="flex-fill d-flex" :key="formKey">
        <div class="flex-column flex-fill d-flex">
            <v-form ref="form">
                <div :class="hispanicIndicatorEnabled ? 'd-flex flex-row': ''" style="height: 410px;">
                    <div :class="hispanicIndicatorEnabled ? 'd-flex flex-column': ''">
                        <div class="d-flex">
                            <fe-label>First Name</fe-label>
                            <v-text-field
                                :class="studentControl"
                                flat solo dense validateOnBlur
                                v-model="updateRec.fname"
                                :value="updateRec.fname"
                                :rules="[v => !!v || 'Field is required']"
                                :disabled="!hasSecurity"
                            ></v-text-field>
                        </div>

                        <div class="d-flex">
                            <fe-label>Last Name</fe-label>
                            <v-text-field
                                :class="studentControl"
                                flat solo dense validateOnBlur
                                v-model="updateRec.lname"
                                :value="updateRec.lname"
                                :rules="[v => !!v || 'Field is required']"
                                :disabled="!hasSecurity"
                            ></v-text-field>
                        </div>

                        <div class="d-flex">
                            <fe-label>Mdl Initial</fe-label>
                            <v-text-field
                                :class="studentControl"
                                flat solo dense validateOnBlur
                                v-model="updateRec.mdl_init"
                                :value="updateRec.mdl_init"
                                :disabled="!hasSecurity"
                            ></v-text-field>
                        </div>

                        <div class="d-flex">
                            <fe-label>Gender</fe-label>
                            <fe-remote-combo
                                ref="infoCombo"
                                :class="studentControl"
                                :items="genders"
                                :itemText="displayName"
                                itemValue="value"
                                v-model="updateRec.gender"
                                :rules="[rules.requiredRule, rules.requiredType]"
                                byId
                                :disabled="!hasSecurity"
                            />
                        </div>

                        <div class="d-flex">
                            <fe-label>Ethnicity</fe-label>
                            <fe-remote-combo
                                ref="infoCombo"
                                :class="studentControl"
                                :items="ethnicities"
                                :itemText="displayName"
                                itemValue="value"
                                v-model="updateRec.ethnicity"
                                byId
                                :disabled="!hasSecurity"
                            />
                        </div>
                    </div>

                    <div :class="hispanicIndicatorEnabled ? 'flex-fill flex-column': ''" :style="hispanicIndicatorEnabled ? 'align-items: center' : ''">
                        <div class="d-flex" v-if="hispanicIndicatorEnabled">
                            <fe-label>Reported Ethnicity</fe-label>
                            <v-text-field
                                :class="studentControl"
                                flat solo dense validateOnBlur
                                v-model="updateRec.reported_ethnicity"
                                :value="updateRec.reported_ethnicity"
                                :disabled="true"
                            ></v-text-field>
                        </div>

                        <div class="d-flex" v-if="hispanicIndicatorEnabled">
                            <fe-label>Hispanic Indicator</fe-label>
                            <v-checkbox
                                :class="studentControl"
                                flat solo dense validateOnBlur
                                v-model="updateRec.hispanic_flag"
                                :disabled="!hasSecurity"
                            ></v-checkbox>
                        </div>

                        <div class="d-flex">
                            <fe-label>DOB</fe-label>
                            <fe-date-picker
                                :class="studentControl"
                                placeholder="MM/DD/YYYY"
                                appendIconClosed="fa-caret-down"
                                appendIconOpen="fa-caret-up"
                                prependIcon="fa-calendar-alt"
                                dateFormat="MM/DD/YYYY"
                                v-model="updateRec.date_of_birth"
                                :disabled="!hasSecurity"
                            />
                        </div>

                        <div class="d-flex">
                            <fe-label>Home Language</fe-label>
                            <fe-remote-combo
                                ref="infoCombo"
                                :class="studentControl"
                                :items="languages"
                                v-model="updateRec.home_language_id"
                                :disabled="!hasSecurity"
                                itemText="home_language_display"
                                itemValue="id"
                                byId
                            />
                        </div>
                    </div>
                </div>

                <div class="d-flex float-right" style="position:relative; bottom:10px;" v-if="showUpdateBtn">
                    <fe-btn usage="ghost" @click="cancelSaveRec">Cancel</fe-btn>
                    <fe-btn usage="primary" @click="saveRec">Update</fe-btn>
                </div>

            </v-form>

        </div>
    </div>
</template>

<script>
    import Vuex, { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'
    import lodash from 'lodash'

    export default {
        name: 'StudentInfo',

        props: {
            studentInfoModel: null
        },

        data() {
            return {
                formKey: 0,
                items: [],
                selectedSchoolYear: null,
                schoolYears: [],
                ethnicities: [],
                genders: [],
                ellFlag: false,
                initRec: {},
                updateRec: {},
                showUpdateBtn: false,
                ethnicityItems: [],
                languages: [],
            }
        },

        inject: ['localStore'],

        computed: {
            ...mapLocalState(['studentIds','studentName','updateStudent','hasSecurity']),

            setEllFlag: {
                get () { return !!(this.updateRec && this.updateRec.ell_flag) },
                set (v) {
                    this.updateRec.ell_flag = v ? 1 : 0
                }
            },

            studentControl() {
                if (this.$vuetify.breakpoint.xsOnly) {
                    return 'student-controls-small'
                } else {
                    return 'student-controls'
                }
            },

            hispanicIndicatorEnabled () {
                return this.$store.state.global.shareableStores.enable_hispanic_indicator
            },

            rules () {
                return {
                    requiredRule: v => !_.isEmpty(v) || 'Field is required',
                    requiredType: v => !_.isEmpty(v) && !(/[^A-Za-z]/).test(v?.value) || 'Alpha characters only'
                }
            },
        },

        watch: {
            updateRec: {
                deep: true,
                handler(val, oldVal) {
                    if (!_.isEqual(val, this.initRec)) {
                        this.showUpdateBtn = true
                    } else {
                        this.showUpdateBtn = false
                    }
                }
            },
        },

        mounted() {
            if (this.$props.studentInfoModel) {
                this.loadData()
            }
        },

        methods: {
            loadData() {
                let studentModel = {}
                let props = {studentId: this.$props.studentInfoModel.student_id}
                this.$axios.get(this.$models.getUrl('studentRecord', 'read') + '&id=' + this.$props.studentInfoModel.student_id)
                .then((response) => {
                    if (response && response.data) {
                        studentModel = _.cloneDeep(response.data[0])
                    }
                }).finally(() => {
                    this.initRec = _.cloneDeep(studentModel)
                    this.updateRec = studentModel
                    this.loadEthnicities()
                    this.loadGenders()
                    this.loadHomeLanguages()
                })
            },

            saveRec() {
                // return
                if(this.$refs.form.validate()) {
                    this.$axios.post('students.php?property=student&action=update', JSON.stringify({
                        students: [this.updateRec]
                    }))
                    .then(response => {
                        let r = this.$ecResponse(response)

                        this.showUpdateBtn = false
                        this.$emit('updateStudentInfo')
                    })
                    .finally(() => {
                        this.loadData()
                    })
                }
            },

            cancelSaveRec() {
                let studentModel = _.cloneDeep(this.initRec)

                this.forceRerender()
                this.showUpdateBtn = false
                this.updateRec = studentModel
            },

            loadEthnicities() {
                this.$axios.get(this.$models.getUrl('ethnicity', 'read'))
                    .then((response) => {
                        if (response && response.data) {
                            this.ethnicities = response.data.ethnicity
                        }
                    })
            },

            loadGenders() {
                this.$axios.get(this.$models.getUrl('gender', 'read'))
                    .then((response) => {
                        if (response && response.data) {
                            this.genders = response.data.gender
                        }
                    })
            },

            forceRerender() {
                this.formKey += 1
            },

            loadHomeLanguages() {
                this.$modelGet('homeLanguage')
                    .then(response => {
                        this.languages = response
                    })
            },

            displayName(item) {
                // if option has a display name, use it, otherwise use the value
                if (!item.display_name_group) {
                    return item.display_name ? item.display_name : item.value
                } else {
                    return item.value + (item.display_name ? " (" + item.display_name + ")" : "")
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep div.fe-label {
        width: 76px !important;
    }

    ::v-deep {
        div.student-controls {
            width: 242px !important;
            max-width: 242px !important;
        }

        div.student-controls-small {
            width: 172px !important;
            max-width: 172px !important;
        }
    }
</style>
