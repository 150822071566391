<template>
    <v-system-bar app :height="48" v-bind="$attrs" color="primary" >
        <fe-dialog
            width=400
            title="Switch District"
            v-if="switchDistrictDialog.show"
            @dismiss="switchDistrictDialog.show=false"
            @close="switchDistrictDialog.show=false"
            @accept="doSwitchDistrict"
            :acceptButtonDisabled="!switchDistrictDialog.id"
            acceptButtonText="Switch"
            persistent
        >
            <div class="d-flex flex-column flex-fill">
                <fe-label>District ID</fe-label>
                <v-text-field
                    type="number"
                    v-model="switchDistrictDialog.id"
                    autofocus flat solo dense
                    @keyup="enter"
                />
            </div>
        </fe-dialog>

        <mobile-pin v-model="showMobilePin"/>
        <change-user-password v-model="showChangePassword"/>
        <user-preferences v-model="showUserPrefs"/>

        <fe-dialog
            width=400
            title="Switch User"
            v-if="switchUserDialog.show"
            @dismiss="switchUserDialog.show=false"
            @close="switchUserDialog.show=false"
            @accept="doSwitchUser"
            :acceptButtonDisabled="!switchUserDialog.rec"
            acceptButtonText="Switch"
            persistent
        >
            <div class="d-flex flex-column flex-fill">
                <fe-label>Select a User</fe-label>
                <fe-remote-combo
                    class="mt-2 ml-2"
                    :url="$models.getUrl('user', 'read') + '&active=1'"
                    itemText="user_full_name"
                    itemValue="id"
                    v-model="switchUserDialog.rec"
                    autofocus
                >
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title>{{data.item.user_full_name}}</v-list-item-title>
                            <v-list-item-subtitle class="pl-4">{{data.item.email_addr}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </fe-remote-combo>
            </div>
        </fe-dialog>

        <fe-dialog
            width=400
            title="Renaissance Academy"
            v-if="np.show"
            @dismiss="np.show=false"
            @close="np.show=false"
            @accept="np.show=false"
            persistent
            >
            <div style="line-height: 2.8;">
                <div style="font-weight: bold;">
                    {{ np.title }}
                </div>
                <div style="line-height: 1.5;">
                    {{ np.message }}
                </div>
                   Error {{ np.code }}
            </div>
        </fe-dialog>

        <v-layout row wrap>
            <v-flex xs12>
                <v-layout row>
                    <v-flex shrink class="nav-flex-center">
                        <div :class="dashboardOnly ? 'logo-ad' : 'logo-ec'" class="logo" @click="go(dashboardOnly ? '/AchievementDashboard' : '/'); $emit('clear-selected')">
                        </div>
                    </v-flex>

                    <v-flex shrink class="nav-flex-center">
                    <span class="launchpad-nav-educlimber">{{ dashboardOnly  ? 'Achievement Dashboard' : 'eduCLIMBER' }}</span>
                    </v-flex>

                    <v-flex shrink>
                        <div class="launchpad-nav" v-show="!dashboardOnly">
                            <fe-btn class="launchpad-nav-option first" v-if="showDistrict" @click="go('/Report/District'); $emit('clear-selected')"
                                :class="$route.path === '/Report/District' ? 'launchpad-nav-option-active' : 'launchpad-nav-option-inactive'"
                                :disabled="restricted"
                            >
                                District
                            </fe-btn>

                            <fe-btn class="launchpad-nav-option" @click="go('/Report/Grade'); $emit('clear-selected')"
                                :class="[!showDistrict ? 'first' : 'middle', $route.path === '/Report/Grade' ? 'launchpad-nav-option-active' : 'launchpad-nav-option-inactive']"
                                :disabled="restricted"
                            >
                                Grade
                            </fe-btn>

                            <fe-btn class="launchpad-nav-option last" @click="go('/Report/Class'); $emit('clear-selected')"
                                :class="$route.path === '/Report/Class' ? 'launchpad-nav-option-active' : 'launchpad-nav-option-inactive'"
                                :disabled="restricted"
                            >
                                Class
                            </fe-btn>
                        </div>
                    </v-flex>

                    <v-spacer/>

                    <v-flex shrink class="text-xs-right nav-flex-center" v-if="showStudentSearch && !showSearch">
                        <v-menu
                            transition="slide-y-transition"
                            v-model="showStudentSearchMenu"
                            bottom offset-y
                        >
                            <template v-slot:activator="{ on }">
                                <fe-btn v-on="on" usage="ghost" useIcon="arrow_drop_down" class="nav-student-search-menu-btn" useIconDirection="right">{{studentSearch.name}}</fe-btn>

                            </template>
                            <v-list class="nav-student-search-list">
                                <v-list-item style="cursor: pointer;" @click="setStudentSearch(null)">
                                    <v-list-item-content>Students</v-list-item-content>
                                </v-list-item>

                                <v-list-item @click="setStudentSearch(col)" style="cursor: pointer;" v-for="(col) in studentSearchDropdown" :key="col.id">
                                    <v-list-item-content>{{col.name}}</v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-autocomplete
                            v-model="selectedSearchRecord"
                            :search-input.sync="searchText"
                            flat solo dense
                            placeholder="Search for students"
                            class="nav-student-search-text"
                            :items="searchResults"
                            itemText="student_full_name"
                            itemValue="student_id"
                            return-object
                            append-icon=""
                        >
                            <template v-slot:item="data">
                                <v-list-item-content>
                                    <v-list-item-title>{{data.item.student_full_name}}</v-list-item-title>
                                    <v-list-item-subtitle>{{data.item.school_name}} {{data.item.grade_desc}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-flex>

                    <v-flex shrink class=" text-xs-right nav-flex-center ml-3">
                        <fe-btn :disabled="restricted" v-if="showCalendar" useIcon="fas fa-calendar" icon usage="ghost" class="nav-icon-bar" @click="tools('calendar')"></fe-btn>

                        <poller :config="{key:'message_inbox_unread'}" #default="data">
                            <v-menu
                                :disabled="!inboxEnabled"
                                v-if="showEnvelope"
                                content-class="add-field-button"
                                :close-on-content-click="false"
                                offset-y
                                z-index="10"
                            >
                                <template #activator="{ on }">
                                    <fe-btn
                                        v-on="on"
                                        :disabled="restricted"
                                        icon
                                        useIcon="fas fa-envelope"
                                        usage="ghost"
                                        class="nav-icon-bar"
                                    >
                                        <v-badge
                                            v-if="data.unreadMsg"
                                            color="pink"
                                            :content="data.unreadCount"
                                            label="count"
                                            class="badge-offset"
                                        />
                                    </fe-btn>
                                </template>
                                <inbox-dropdown :pollData="data" @unreadCount="data.unreadMsg = data.unreadCount = $event" />
                            </v-menu>
                        </poller>

                        <poller :config="{key:'new_bell_items'}" #default="data">
                            <div>
                                <waiter-dialog :pollData="data" @read="data.unreadCount -= 1"></waiter-dialog>
                                <v-menu
                                    :disabled="!inboxEnabled"
                                    v-if="showEnvelope"
                                    content-class="add-field-button"
                                    :close-on-content-click="false"
                                    offset-y
                                    z-index="10"
                                >
                                    <template #activator="{ on }">
                                        <fe-btn
                                            v-on="on"
                                            :disabled="restricted"
                                            icon
                                            useIcon="fas fa-bell"
                                            usage="ghost"
                                            class="nav-icon-bar"
                                        >
                                            <v-badge
                                                v-if="data.unreadCount"
                                                color="pink"
                                                :content="data.unreadCount"
                                                label="count"
                                                class="badge-offset"
                                            />
                                        </fe-btn>
                                    </template>
                                    <bell-dropdown :pollData="data" @unreadCount="data.unreadCount = data.unreadNotificationCount = $event" />
                                </v-menu>
                            </div>
                        </poller>
                        <poller :config="{key:'kpi_dashboard'}" @polled="onPolled" #default="data"></poller>

                        <v-menu offset-y v-model="showAppSwitcher" v-if="availableApps.length > 0">
                            <template v-slot:activator="{ on }">
                                <fe-tooltip :tooltip="'Switch Illuminate Application'">
                                    <fe-btn v-on="on"
                                        icon
                                        :class="'nav-icon-bar'"
                                        :disabled="restricted"
                                    >
                                            <v-img :src="'/images/logo-app-switch.png'" max-width="20px" max-height="20px" />
                                    </fe-btn>
                                </fe-tooltip>
                            </template>
                            <v-list dense>
                                <template v-for="opt in appSwitcherMenuItems">
                                    <v-list-item v-if="!opt.hidden"  @click="opt.handler()" :class="opt.class">
                                        <v-img class="mr-3" max-width="20px" max-height="20px" :src="opt.icon" />{{opt.name}}
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>

                        <div style="width: 35px;">
                            <fe-tooltip tooltip="Renaissance Educator Academy">
                                <v-img class="northpass-image" @click="northpassHandler" src="images/renaissance_educator_academy_logo.svg"/>
                            </fe-tooltip>
                        </div>

                        <v-menu offset-y v-model="showUserMenu" style="z-index: 98;">
                            <template v-slot:activator="{ on }">
                                <fe-tooltip :tooltip="sessionUser.user.user_full_name + ` (` + sessionUser.district.login_id + `)`" style="z-index: 98;">
                                    <fe-btn v-on="on" v-if="showUser" :useIcon="topSecret ? 'fas fa-user-lock' : !sessionUser.user.photo_url ? 'fas fa-user' : ''"
                                        icon
                                        class="nav-icon-bar-circle"
                                        :class="showUserMenu ? 'nav-icon-bar-circle-active' : 'nav-icon-bar-circle-inactive'"
                                        :disabled="restricted"
                                    >
                                        <v-avatar size="28px" v-if="!topSecret && sessionUser.user.photo_url">
                                            <v-img :src="sessionUser.user.photo_url"/>
                                        </v-avatar>
                                    </fe-btn>
                                </fe-tooltip>
                            </template>

                            <div class="user-info-group">
                                <div class="user-name">{{sessionUser.user.user_full_name}}</div>
                                <div v-if="topSecret" class="user-info top-secret">Top Secret Mode</div>
                                <div class="user-info">{{sessionUser.user.email_addr}}</div>
                                <div class="user-info">{{sessionUser.district.name}}</div>
                            </div>
                            <v-list dense>
                                <template v-for="opt in userPrefs.filter(itm => !itm.hidden)">
                                    <template v-if="!opt.component">
                                        <v-list-item @click="opt.handler()" :class="opt.class ? [opt.class, 'user-pref-icon'].join(' ') : 'user-pref-icon'">
                                            <v-icon class="mr-3">{{opt.icon}}</v-icon> {{opt.name}}
                                        </v-list-item>
                                    </template>

                                    <template v-else>
                                        <component
                                            :is="opt.component"
                                            @click="opt.handler"
                                            :class="opt.class"
                                        />
                                    </template>
                                </template>
                            </v-list>
                        </v-menu>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs12 class="mb-2" v-if="1 == 2">
                <v-layout row>
                    <v-flex xs12>
                        <template v-for="(nav, navIndex) in navigations">
                            <div
                                class="nav-btn"
                                @click="handleNavigation(nav)"
                                :key="nav.name"
                            >
                                {{nav.name}}
                                <span style="padding-left: 5px;" v-if="navIndex != navigations.length-1">/</span>
                            </div>
                        </template>
                    </v-flex>
                    <v-flex class="text-xs-right">
                        <div class="district-name">
                            {{ schoolDistrict }}
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-system-bar>
</template>

<script>
    import { mapState } from 'vuex'
    import Poller from '@/util/poller.js'
    import NavigationStepper from '@/components/common/NavigationStepper'
    import MobilePin from '@/components/modules/user/MobilePin'
    import ChangeUserPassword from '@/components/modules/user/ChangeUserPassword'
    import UserPreferences from '@/components/modules/user/UserPreferences'
    import InboxDropdown from '@/components/modules/messages/InboxDropdown'
    import BellDropdown from '@/components/modules/bell/BellDropdown'
    import WaiterDialog from '@/components/modules/bell/WaiterDialog'
    import UserTargetSetsPopout from '@/components/modules/user/UserTargetSetsPopout'
    import lodash from 'lodash'

    export default {
        name: 'NavigationBar',

        components: {
            Poller,
            NavigationStepper,
            MobilePin,
            ChangeUserPassword,
            UserPreferences,
            InboxDropdown,
            BellDropdown,
            WaiterDialog,
            UserTargetSetsPopout,
        },

        props: {
            showCalendar: { type: Boolean, default: true },
            showEnvelope: { type: Boolean, default: true },
            showBell: { type: Boolean, default: true },
            showTable: { type: Boolean, default: true },
            showUser: { type: Boolean, default: true }
        },

        data() {
            return {
                showUserPrefs: false,
                showAppSwitcher: false,
                switchDistrictDialog: {
                    show: false,
                    id: null
                },
                switchUserDialog: {
                    show: false,
                    rec: null
                },
                showMobilePin: false,
                showChangePassword: false,
                selectedPage: null,
                showStudentSearchMenu: false,
                searchText: '',
                searchResults: [],
                selectedSearchRecord: null,
                studentSearch: { id: 'students', name: 'Students' },
                studentSearchDropdown: [
                    { id: 'students', name: 'Students' }
                ],
                showSearch: true,
                showUserMenu: false,
                activeTable: false,
                preferences: {},
                availableApps: [],
                loadingNP: false,
                np: {
                    show: false,
                    title: '',
                    message: '',
                    code: null
                },
            }
        },

        computed: {
            ...mapState('global', ['recentNavigation', 'currentYear', 'sessionUser', 'restrictedUI', 'uris', 'shareableStores']),
            ...mapState('navigation', ['activeModule', 'manage', 'launchpad']),
            inboxEnabled: me => me.$store.getters['flags/flags']['ec-inbox'] === true,
            showDistrict() {
                return this.$hasSecurity('VIEW_ALL_GRADES')
            },
            schoolDistrict() {
                return 'Climberville School District'
            },
            dashboardOnly() {
                return this.sessionUser.district.dashboard_only ? true : false
            },
            restricted() {
                return this.restrictedUI ? true : false
            },
            userName() {
                return 'Matt Harris'
            },
            navigations() {
                switch (this.activeModule) {
                    case 'manage':
                        return this.manage
                        break
                    case 'launchpad':
                        return this.launchpad
                        break
                    case 'District':
                        break
                }
            },
            showStudentSearch() {
                let sz = this.$vuetify.breakpoint
                return !sz.smAndDown
            },
            topSecret() {
                return this.sessionUser.user.force_top_secret || this.preferences.TOP_SECRET_MODE
            },
            userPrefs() {
                let me = this
                let prefs = []

                if (this.restrictedUI) {
                    prefs.push({
                        name: 'Not Available',
                        icon: 'fas fa-fw fa-exclamation'
                    })
                } else {
                    prefs.push({
                        name: 'User Preference',
                        icon: 'fas fa-fw fa-cogs',
                        handler: function () {
                            me.showUserPrefs = true
                        }
                    }, {
                        name: 'Change CICO PIN',
                        icon: 'fas fa-fw fa-mobile',
                        handler: function () {
                            me.showMobilePin = true
                        }
                    }, {
                        name: 'Change Password',
                        icon: 'fas fa-fw fa-key',
                        handler: function () {
                            me.showChangePassword = true
                        }
                    }, {
                        name: 'Knowledge Base',
                        icon: 'fas fa-fw fa-users',
                        handler: function () {
                            window.open('https://educlimber.illuminateed.com/hc/en-us')
                        }
                    }, {
                        name: 'Submit a Request',
                        icon: 'fas fa-fw fa-ticket-alt',
                        hidden: me.sessionUser.security_codes.indexOf('SWITCH_USER') == -1,
                        handler: function () {
                            window.open('https://support.illuminateed.com/hc/en-us/requests/new', '_blank')
                        }
                    }, {
                        name: 'Chat with Us',
                        icon: 'fas fa-fw fa-comments',
                        hidden: me.sessionUser.security_codes.indexOf('CHAT') == -1,
                        handler: function () {
                            if (me.$store.getters['flags/flags']['ec-forethought-chatbot']) {
                                window.Forethought('widget', 'show')
                                window.Forethought('widget', 'open')
                            } else {
                                zE('webWidget', 'prefill', {
                                    name: {
                                        value: me.sessionUser.user.user_full_name
                                    },
                                    email: {
                                        value: me.sessionUser.user.email_addr
                                    }
                                });
                                zE.show();
                                zE.activate();
                            }
                        }
                    }, {
                        component: 'user-target-sets-popout',
                        name: 'Target Sets',
                        icon: 'fas fa-map-marked',
                        handler: function () {
                            // The popout component itself will handle all click events
                            return
                        },
                    // only hiding for now - may want again at later date
                    // }, {
                    //     name: 'User Guides',
                    //     icon: 'fas fa-fw fa-map-marked',
                    //     class: 'end-section',
                    //     handler: function () {
                    //         me.$store.commit('global/addDockableWindow', {
                    //             name: 'User Guides',
                    //             component: 'user-guides'
                    //         })
                    //     }
                    }, {
                        name: 'Classic View',
                        icon: 'fas fa-fw fa-step-backward',
                        class: 'start-section',
                        handler: function () {
                            window.location = me.uris['ec_classic']
                        }
                    }, {
                        name: 'Switch District',
                        icon: 'fas fa-fw fa-random',
                        hidden: !me.shareableStores.can_switch_district,
                        handler: function () {
                            me.switchDistrictDialog.id = null
                            me.switchDistrictDialog.show = true
                        }
                    }, {
                        name: 'Switch User',
                        icon: 'fas fa-fw fa-random',
                        hidden: !me.shareableStores.can_switch_user,
                        handler: function () {
                            me.switchUserDialog.rec = null
                            me.switchUserDialog.show = true
                        }
                    }, {
                        name: 'Revert to Original User',
                        icon: 'fas fa-fw fa-long-arrow-alt-left',
                        hidden: !me.shareableStores.revert_user_id,
                        handler: function() {
                            me.doSwitchUser({
                                id: me.shareableStores.revert_user_id
                            })
                        }
                    }, {
                        name: 'Logout',
                        icon: 'fas fa-fw fa-sign-out',
                        handler: function () {
                            me.$messageBox({
                                title: 'Confirm Logout',
                                persistent: true,
                                message: 'Are you sure you want to logout?',
                                maxWidth: '500',
                                actions: [{
                                    text: 'Cancel',
                                    primary: false
                                }, {
                                    text: 'Logout',
                                    primary: true,
                                    onClick: () => {
                                        me.$axios.get('qry.php?property=logout&json=1').then(response => {
                                            window.location.href = response.data.redirect
                                        })
                                    }
                                }]
                            })
                        }
                    })
                }

                return prefs
            },
            appSwitcherMenuItems() {
                let me = this
                let apps = []
                let icons = {
                    'allthedata': '/images/logo-dna.png',
                    'educlimber': '/images/logo-educlimber.png',
                    'fastbridge': '/images/logo-fastbridge.png',
                    'illuminate': '/images/logo-illuminate.png',
                }
                this.availableApps.forEach((item) => {
                    if (!item.showProduct) return
                    apps.push({
                        name: item.displayName,
                        icon: icons[item.application] || icons.illuminate,
                        hidden: false,
                        handler: () => {
                            window.location.href = item.url
                        }
                    })
                })

                return apps
            },
        },

        watch: {
            searchText: lodash.debounce(function(v) {
                if (v && v.length >= 3) {
                    this.doSearch()
                }
            }, 500),
            selectedSearchRecord(v) {
                this.$store.commit('set', {
                    module: 'global',
                    state: 'selectedStudent',
                    value: v
                })
            }
        },

        mounted() {
            this.fetchUserPrefs()
            this.fetchAvailableApps()
            // if password is expiring soon, show change password dialog first thing
            if (this.shareableStores?.user?.password_expiring_soon) {
                this.showChangePassword = true
            }
        },

        methods: {
            chkunread(v) {},
            doSwitchDistrict() {
                let id = this.switchDistrictDialog.id

                if (!id) {
                    return
                }

                this.$setLoading(true)
                this.$axios.post('switchDistrict.php', { district_id: id} )
                    .then(response => {
                        this.cleanUpBeforeLocationChange()
                        let redirect = function () {
                            if (response.data.redirect) {
                                location = response.data.redirect
                            } else {
                                location.reload()
                            }
                        }
                        // log out of IE SSO session before changing location
                        if (response.data.ie_logout) {
                            let iframe = document.createElement('iframe')
                            iframe.onload = redirect
                            iframe.src = response.data.ie_logout
                            iframe.style.dispaly = 'none'
                            document.body.appendChild(iframe)
                        } else {
                            redirect()
                        }
                    })
                    .finally(() => {
                        this.$setLoading(false)
                    })
            },
            doSwitchUser(rec) {
                rec = rec || this.switchUserDialog.rec

                if (!rec) {
                    return
                }

                this.$axios.post('switchUser.php', { user_id: rec.id} )
                    .then(response => {
                        let data = this.$ecResponse(response)

                        if (data) {
                            this.cleanUpBeforeLocationChange();
                            if (data.redirect) {
                                window.location = data.redirect
                            } else {
                                window.location.reload(true)
                            }
                        }
                    })
            },
            cleanUpBeforeLocationChange() {
                // remove IE SSO iframe
                let iesso = document.getElementById('ie_session_iframe')
                if (iesso) iesso.parentNode.removeChild(iesso)
            },
            handleNavigation(nav) {
                if (nav.to) {
                    this.go(nav.to)
                }
            },
            go(link) {
                if (this.restrictedUI) {
                    this.$snackbars.$emit('new', { text: 'This feature is not yet enabled', usage: 'warning' })
                    return
                }

                if (link == '/') {
                    this.$store.commit('global/reloadLaunchpad', { })
                }

                this.$router.push(link)
            },
            setStudentSearch(col) {
                if (!col) {
                    this.studentSearch = {
                        id: 'students',
                        name: 'Students'
                    };
                } else {
                    this.studentSearch = col
                }
            },
            doSearch() {
                let params = '&name='+this.searchText + '&school_year_id='+this.currentYear.id
                this.$axios.get(this.$models.getUrl('studentGradeHistory', 'read')+params)
                .then(response => {
                    this.$data.searchResults = response.data
                })
                .catch(error => {
                    console.error(error)
                })
            },
            tools(icon) {
                let route = {
                    id: icon,
                    name: icon
                }
                this.$emit('top-nav', route)
            },
            navigation(route) {
                this.$emit('navigation', route)
            },
            fetchUserPrefs() {
                this.$modelGet('userPreferences')
                    .then(resp => {
                        let r = {}
                        resp.forEach(pref => { r[pref.code] = parseInt(pref.user_value) })
                        this.preferences = r
                    })
            },
            fetchAvailableApps() {
                if (!this.shareableStores.authx_enabled) return
                this.$axios.get(this.shareableStores.authx_provider + "/products", {cors: true, useDefaultXhrHeader: false, withCredentials: true})
                    .then(response => {
                        let data = response.data || []
                        this.availableApps = data
                    })
            },
            enter(e) {
                if (e.key === 'Enter' && this.switchDistrictDialog.show && this.switchDistrictDialog.id) {
                    this.doSwitchDistrict()
                }
            },
            northpassHandler() {
                this.$emit('np-loading', true)
                this.$axios.get(this.$models.getUrl('northpass','read'))
                    .then(response => {
                        if (response.data?.success) {
                                window.open(response.data?.northpass.url, '_blank')
                        } else {
                            this.np = response.data?.error
                            this.np.show=true
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                    .finally(() => {
                        this.$emit('np-loading', false)
                    })
            },
            onPolled({dashboardCount}) {
                this.$store.commit('global/setKpiDashboardCount', {value: dashboardCount})
            },
        }
    }
</script>
<style lang="scss">
.v-system-bar {
    .fe-button.primary {
        .v-icon {
            margin: 0;
            color: #fff;
        }
    }
}
</style>

<style lang="scss" scoped>
.badge-offset {
    position: absolute;
    right: 12px;
    top: 12px;
}

.logo {
    width: 35px;
    border-radius: 4px;
    text-align: center;
    transition: 500ms all;
    height: 30px;
    background-position: center;
    background-size: 20px;
    background-color: transparent;

    &:hover {
        cursor: pointer;
        background-color: white;
        border: 1px solid white;
        background-position: center;
        background-size: 20px;
        width: 35px;
    }

    &-ad {
        background-image: url(/images/ad_logo.png);
        &:hover {
            background-image: url(/images/ad_logo_color.png);
        }
    }

    &-ec {
        background-image: url(/images/educlimber_white2.png);
        &:hover {
            background-image: url(/images/educlimber2.png);
        }
    }
}

.user-info-group {
    background: white;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54);

    .user-name {
        font-size: 16px;
        padding: 0;
    }

    .user-info {
        font-size: 12px;
        min-height: unset;
        padding: 0;

        &.top-secret {
            color: #930A00;
        }
    }
}

.end-section {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54);
}

.start-section {
    padding-top: 8px;
    margin-top: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.54);
}

.launchpad-nav {
    align-items: center;
    display: flex;
    height: 100%;

    .launchpad-nav-option {
        ::v-deep .fe-button {
            display: inline-block;
            min-width: 78px;
            max-width: 78px;
            height: 28px;
            color: white;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: center;
            padding: 0 !important;
            margin: 4px 1px;
            cursor: pointer;
            &:hover {
                background: #004156 !important;
            }
        }
        &-active {
            ::v-deep .fe-button{
                background: #004156 !important;
            }
        }
        &-inactive {
            ::v-deep .fe-button {
                background: var(--v-primary-darken1) !important;
            }
        }

        &.first ::v-deep .fe-button {
            border-radius: 4px 0px 0px 4px;
        }

        &.middle ::v-deep .fe-button {
            border-radius: 0;
        }

        &.last ::v-deep .fe-button {
            border-radius: 0px 4px 4px 0px;
        }

        &.standalone {
            border-radius: 4px;
            width: 71px;
            margin-left: 6px;
        }
    }

    &-educlimber {
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        padding: 16px 20px 16px 5px;
    }
}

.district-name {
    width: 200px;
    padding-right: 24px;
    padding-top: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    /* identical to box height */
    color: #FFFFFF;
}

.launchpad-menu {
    width: 432px;
    background: #FFFFFF;
    border: 1px solid #E0E1E6;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px rgba(126, 132, 148, 0.3);
    border-radius: 3px;

    .launchpad-menu-icon {
        font-size: 18px;
        color: #3F6190;
    }

    .launchpad-menu-display {
        display: inline-block;
        width: 50px;
        margin-top: 5px;
        border: 1px solid blue;
    }

    .launchpad-menu-img {
        height: 16px;
    }

    .launchpad-menu-text {
        height: 32px;
    }

    .launchpad-menu-description {
        color: #C0C3CF !important;
    }

    .launchpad-menu-item {

        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        /* identical to box height */
        color: #050F2D;
    }
}

.user-greeting {
    width: 318px;
    padding-left: 24px;

    /* Heading 1 */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    /* identical to box height */
    color: #FFFFFF;
}

.northpass-image {
    cursor: pointer
}

.nav-btn {
    color: white;
    padding-top: 14px;
    padding-right: 8px;
    font-size: 14px;
    float: left;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.nav-student-search {
    &-menu-btn {
        min-width: 120px;
        ::v-deep .fe-button {
            background: #fff !important;
            height: 28px;
        }
        ::v-deep .v-btn {
            margin: 0 !important;
            padding: 0 !important;
            border: 1px solid #C0C3CF;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            min-width: 120px;
        }
        ::v-deep i {
            font-size: 20px !important;
        }
        ::v-deep .v-btn__content {
            padding-left: 5px;
        }
    }
    &-menu-btn:hover {
        background: #ECEDF1 !important;
        border-radius: 4px;
    }

    &-search-list {
        padding: 0 !important;
        ::v-deep .v-list__tile {
            height: 40px !important;
            min-width: 125px !important;
            font-size: 13px;
        }
    }

    &-text {
        padding-top: 21px !important;
        width: 220px;
        ::v-deep .v-input__slot {
            border-radius: 4px !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            margin: 0 !important;
            min-height: 28px;
            max-height: 28px;
        }
    }
}

.nav-flex-center {
    display: flex;
    align-items: center;
}

.nav-icon-bar {
    ::v-deep .v-btn {
        height: 28px !important;
        width: 28px !important;
        .v-icon {
            margin-right: 0 !important;
        }
    }
    ::v-deep i {
        color: rgba(255, 255, 255, 0.8) !important;
    }
    &-circle {
        ::v-deep .v-btn {
            height: 28px !important;
            width: 28px !important;
            &:hover {
                background: #004156 !important;
            }
        }
        ::v-deep i {
            font-size: 12px !important;
        }
        &-active ::v-deep .v-btn {
            background: #004156 !important;
        }
        &-inactive ::v-deep .v-btn {
            background: var(--fe-secondary) !important;
        }
    }
}

.user-pref-icon {
    ::v-deep i {
        width: 22px;
    }
}

.user-pref-icon:hover {
    ::v-deep i {
        transform: scale(1.5);
        transform-origin: center;
    }
}
</style>
