var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-fill flex-column d-flex",staticStyle:{"background":"#fff"}},[_c('fe-grid',{ref:"grid",staticStyle:{"height":"100%"},attrs:{"columnDefs":_vm.mergedDefs,"rowData":_vm.rowData,"showAddRowBtn":false,"domLayout":"normal","displayDensity":"medium","exportFilename":"Students 3D","grouped":_vm.rowGroup},on:{"selection-changed":_vm.onSelect,"cellMouseOver":_vm.doCellOver,"cellMouseOut":_vm.doCellOut}},[_c('template',{slot:"toolbar-items"},[_c('fe-btn',{attrs:{"disabled":!_vm.selection.length,"usage":"secondary"},on:{"click":_vm.tagStudents}},[_vm._v("Apply Tag")]),_c('fe-btn',{attrs:{"disabled":!_vm.selection.length,"usage":"secondary"},on:{"click":_vm.createIntervention}},[_vm._v("Intervention")])],1)],2),_c('fe-student-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.studentCard.show),expression:"studentCard.show"}],ref:"studentCard",style:({
            position: 'absolute',
            left: `${_vm.studentCard.left}px`,
            top: `${_vm.studentCard.top}px`,
            padding: '1px 6px 1px 6px',
            zIndex: '10'
        }),attrs:{"imageUrl":_vm.studentCard.imageUrl,"studentRecord":_vm.studentCard.studentRecord,"fullNameField":"student_full_name","schoolField":"school_name","gradeField":"grade_desc","genderField":"gender"},on:{"close":() => {}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }