<template>
    <div>
        <fe-remote-combo
            :value="params.data[params.valueField]"
            :items="params.items"
            :style="!!params.readonly ? 'width: 100%; pointer-events: none;' : 'width: 100%;'"
            :clearable="false"
            :readonly="!!params.readonly"
            useSearchText
            @input="onInput"
            @search="searchText = $event"
        />
    </div>
</template>

<script>
    import Vue from 'vue'
    import lodash from 'lodash'

    export default Vue.extend({
        name: 'ObservationSelectColumn',
        props: {
            value: {
                type: String,
                default: ''
            },
        },

        data: () => ({
            isEditing: false,
            search: null,
            searchText: null,
        }),

        watch: {
            searchText: lodash.debounce(function(v) {
                if (typeof v === 'string' && !v.includes(' (new)')) {
                    this.checkForResult(v)
                }
            }, 200),
        },

        methods: {
            checkForResult(v) {
                if (v) {
                    let existing = this.params.items.find(x => x.name === v)
                    if (existing) {
                        return
                    } else {
                        let i = this.params.items.findIndex(item => item.id == -1)
                        this.params.items.splice(i, 1)
                        let tempItem = {
                            id: -1,
                            name: v + ' (new)',
                            type: this.params.colDef.headerName.toLowerCase() + 's' // antecedants or consequences
                        }
                        this.params.items.push(tempItem)
                    }
                }
            },
            onInput(event) {
                if (this.params.onInput) {
                    if (event) {
                        event.name = event.name.replace(' (new)', '') // for new item, get rid of '(new)' indicator
                    }
                    this.params.onInput(this.params, event)
                }
            }
        },
    })
</script>

<style lang="scss" scoped>

    ::v-deep .v-input__slot {
        border: none !important;
    }
    ::v-deep .v-input__icon--append {
        display: none !important;
    }

    ::v-deep input {
        /* We want the font size to match default font size for an ag-grid text cell */
        font-size: 14px !important;
    }

</style>
