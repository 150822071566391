<template>
    <div class="flex-fill flex-column no-scroll">
        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.unknownSubCategory"
            :readParams="{ upload_id: $props.rec.id }"
            @read="v => items = v"
        />

        <fe-mask v-show="loading" :showLoader="true" />

        <fe-dialog
            width="400"
            title="Create Assessment"
            v-if="dialog.show"
            @dismiss="dialog.show=false"
            @close="dialog.show=false"
            @accept="doCreate"
            acceptButtonText="Create"
            :acceptButtonDisabled="!dialog.valid"
            persistent
        >
            <v-form v-model="dialog.valid">
                <div v-if="isLocal && requiresCategory">
                    <fe-label>Category</fe-label>
                    <v-select
                        :items="categories"
                        v-model="dialog.record.category_id"
                        itemValue="id"
                        itemText="name"
                        clearable
                        :rules="$fieldValidators('select', 'Category', { required: isLocal ? true : false })"
                        flat solo dense
                        :disabled="dialog.record.parent_sub_category_id?true:false"
                    />
                </div>

                <fe-label>Parent Assessment</fe-label>
                <v-autocomplete
                    :items="parentAssessments"
                    v-model="dialog.record.parent_sub_category_id"
                    itemValue="id"
                    itemText="name"
                    :rules="$fieldValidators('select', 'Parent Assessment', { required: !isLocal ? true : false })"
                    clearable
                    flat solo dense
                    @change="setCategory"
                />

                <fe-label v-if="onlyOne">{{ isBehavior ? 'Display Name' : 'Assessment Name' }}</fe-label>
                <v-text-field
                    v-if="onlyOne"
                    :items="assessments"
                    v-model="dialog.record.display_name"
                    itemValue="id"
                    itemText="name"
                    flat solo dense
                />

                <div v-if="onlyOne && dataPointTypeId === 13">
                    <fe-label>{{ isBehavior ? 'Import Name' : 'Assessment Name' }}</fe-label>
                    <v-text-field
                        :items="assessments"
                        v-model="dialog.record.name"
                        itemValue="id"
                        itemText="name"
                        readonly
                        flat solo dense
                    />
                </div>
            </v-form>
        </fe-dialog>

        <fe-dialog
            width="400"
            title="Alias for Assessment"
            v-if="aliasDialog.show"
            @dismiss="aliasDialog.show=false"
            @close="aliasDialog.show=false"
            @accept="setAlias"
            acceptButtonText="Save"
            :acceptButtonDisabled="!aliasDialog.record"
            persistent
        >
            <v-form>
                <fe-label>Assessment</fe-label>
                <v-autocomplete
                    autofocus
                    clearable
                    :items="aliasForAssessments ? aliasForAssessments : assessments"
                    v-model="aliasDialog.record"
                    itemValue="id"
                    itemText="name"
                    required
                    :returnObject="false"
                    flat solo dense
                />
            </v-form>
        </fe-dialog>

        <fe-grid
            :columnDefs="columns"
            :rowData="items"
            class="flex-grow-1"
            displayDensity="small"
            ref="grid"
            :showAddRowBtn="false"
            @selectionChanged="selectRow"
            @cellValueChanged="sanitize"
            showToolbar
        >
            <template v-slot:toolbar-items>
                <fe-btn usage="primary" v-if="canSave" @click="doSave">Save</fe-btn>

                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <fe-btn usage="primary" v-on="on">
                            Actions
                        </fe-btn>
                    </template>

                    <v-list>
                        <template v-for="(item, index) in tools">
                            <fe-tooltip direction="right" :disabled="!selections.length || selections.length && index == 2 || !isMultipleGroups"
                                tooltip="These actions cannot be performed when different assessment groups are selected.">
                                <v-list-item :key="index" @click="item.handler()" v-show="!item.hidden" :disabled="isMultipleGroups && selections.length && (index == 0 || index == 1)">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </fe-tooltip>
                        </template>
                    </v-list>
                </v-menu>
            </template>
        </fe-grid>
    </div>
</template>

<script>
    export default {
        name: 'UnknownAssessments',
        props: {
            rec: {
                required: true
            }
        },
        data() {
            let me = this

            return {
                assessmentGroups: [],
                loading: false,
                exclusive: [],
                canSave: false,
                selections: [],
                categories: [],
                assessments: [],
                parentAssessments: [],
                items: [],
                dialog: {
                    valid: false,
                    show: false,
                    record: {}
                },
                aliasDialog: {
                    show: false,
                    record: null
                },
                pendingText: '<br><p class="caption mt-2">This will also save any pending changes.</p>',
                originalItems: [],
                columns: [],
                aliasAsAssessments: [],
                aliasForAssessments: [],
            }
        },
        computed: {
            requiresCategory() {
                if (this.onlyOne) {
                    return true
                } else if (this.dialog.record.parent_sub_category_id) {
                    return false
                }
                return true
            },
            tools() {
                let me = this

                return [{
                    title: 'Create Assessment',
                    description: 'Create this assessment in eduCLIMBER',
                    hidden: me.selections.length === 0,
                    handler() {
                        me.$modelGet('subcategory', { data_point_type_id: me.dataPointTypeId, alias_cd: me.selections[0].parent_name })
                            .then(response => {
                                me.parentAssessments = response
                            })

                        me.dialog.record = {
                            category_id: null,
                            name: me.selectedShortName,
                            display_name: me.selectedDisplayName,
                            assessment_type_id: me.dataPointTypeId == 13 ? 4 : 1
                        }
                        me.dialog.show = true
                    }
                }, {
                    title: 'Alias to Assessment',
                    description: 'Set this assessment as another known name for a different assessment',
                    hidden: me.selections.length === 0,
                    handler() {
                        me.$modelGet('subcategory', { data_point_type_id: me.dataPointTypeId })
                            .then(response => {
                                me.aliasForAssessments = response
                            })

                        me.aliasDialog.record = null
                        me.aliasDialog.show = true
                    }
                }, {
                    title: 'Auto Create Assessments',
                    description: 'Attempt to create selected records automatically, based on parent information provided from the upload.',
                    hidden: false,
                    handler() {
                        let count = me.selections.length
                        let prefix = count === 0 ? 'No records are selected. ' : ''


                        me.$messageBox({
                            title: 'Confirm Auto Create',
                            persistent: true,
                            maxWidth: 600,
                            message: prefix + 'This will loop through all unknown records that have a parent alias and attempt to create the assessment'+
                            ' as a child of the parent, if the parent has already been aliased.  ' + 
                                (count === 0 ? '<br><br>We only process 500 records at a time, to avoid timeouts.' : ''),
                            actions: [{
                                text: 'Cancel', primary: false
                            }, {
                                text: 'Create', primary: true,
                                onClick: () => {
                                    let ids = []
                                    me.selections.forEach(r => { ids.push(r.id) })
                                    me.$axios.post('verifyUpload.php?action=create_child_sub_categories', {
                                        upload_id: me.$props.rec.id,
                                        alias_ids: ids
                                    }).then(response => {
                                        let d = me.$ecResponse(response)
                                        me.loadAssessments()
                                    })
                                }
                            }]
                        })
                    }
                }]
            },
            isLocal() {
                return (this.selections.length && this.selections[0].data_point_type_id >= 10000 ? true : false)
            },
            onlyOne() {
                return this.selections.length == 1 ? true : false
            },
            dataPointTypeId() {
                return this.selections.length ? this.selections[0].data_point_type_id : null
            },
            isBehavior() {
                return this.selections.length && this.selections[0].data_point_type_id === 13 ? true : false
            },
            selectedShortName() {
                return this.selections.length === 1 ? this.selections[0].name : ''
            },
            selectedDisplayName() {
                return this.selections.length === 1 ? this.selections[0].display_name : ''
            },
            isMultipleGroups() {
                return this.selections.length ? this.selections.filter(item => item.data_point_type_id != this.selections[0].data_point_type_id).length > 0 : false
            }
        },
        watch: {
            items(v) {
            }
        },
        mounted() {
            this.loadAssessments()
        },
        methods: {
            setCategory(v) {
                if (!v) {
                    this.dialog.record.category_id = null
                    return
                }

                let rec = this.parentAssessments.find(r => r.id == v)
                
                if (this.onlyOne) {
                    this.dialog.record.category_id = rec.category_id
                } else {
                    this.dialog.record.category_id = null
                }
                    
            },
            doCreate() {
                let count = this.selections.length
                let records = [...this.selections]
                // this.selections.forEach(rec => { rec.sub_category_id = me.aliasDialog.record })
                Object.keys(this.dialog.record).forEach(key => {
                    if (this.dialog.record[key]) {

                        records.forEach(rec => {
                            rec.sub_category_id = null
                            rec[key] = this.dialog.record[key]

                            if (count>1 && rec.parent_sub_category_id) rec.category_id = null
                        })
                    }
                })

                this.loading = true
                this.$refs.crud.update(records)
                    .then(response => {
                        this.loadAssessments()
                    })
                    .finally(() => { 
                        this.loading = false 
                        this.$refs.grid.gridApi.deselectAll()
                    })
            },
            doSave() {
                let dirty = this.$isDirty(this.originalItems, this.items)
                this.loading = true
                this.$refs.crud.update(dirty)
                    .then(response => {
                        this.loadAssessments()
                    })
                    .finally(() => { 
                        this.loading = false 
                        this.$refs.grid.gridApi.deselectAll()
                    })
            },
            setAlias() {
                this.aliasDialog.show = false
                let me = this
                this.selections.forEach(rec => { rec.sub_category_id = me.aliasDialog.record })
                this.sanitize()
                this.$refs.grid.gridApi.deselectAll()
                this.doSave()
            },
            selectRow(row) {
                this.selections = this.$refs.grid.gridApi.getSelectedRows()
            },
            sanitize() {
                let keys = this.exclusive
                // this will check to see if the original fetched data set has been manipulated
                let dirty = this.$isDirty(this.originalItems, this.items)
                if (dirty) {
                    this.canSave = true
                } else {
                    this.canSave = false
                }
                // Since we are changing cells behind the ag-grid api, call refresh
                this.$refs.grid.gridApi.refreshCells()
            },
            setColumns() {
                let me = this

                this.columns = [me.$grid.checkColumn(), {
                    headerName: 'Assessment Group',
                    field: 'data_point_type_id',
                    sortable: true,
                    valueGetter: v => {
                        return me.assessmentGroups.find(r => r.id === v.data.data_point_type_id).name
                    },
                    flex: 1
                }, {
                    headerName: 'Assessment Name',
                    field: 'name',
                    sortable: true,
                    flex: 1
                }, {
                    headerName: 'Parent Assessment',
                    field: 'parent_name',
                    sortable: true,
                    flex: 1
                }, {
                    headerName: 'Alias as Assessment',
                    field: 'sub_category_id',
                    flex: 1,
                    editable: true,
                    tooltipValueGetter: (param) => 'This assessment will also be known as the selected assessment in eduCLIMBER',
                    cellRenderer: v => {
                        if (v.value) {
                            let r = me.assessments.find(rec => { return rec.id == v.value })
                            if (r) return r.name
                        }
                        return ''
                    },
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: v => {
                        return {
                            rowDataKey: "sub_category_id",
                            mode: "edit",
                            items: me.aliasAsAssessments[v.data.data_point_type_id],
                            keyProp: "id",
                            labelProp: "name",
                            disableChips: true
                        }
                    }
                }]
            },
            loadAssessments() {
                let id = this.$props.rec.id
                this.originalItems = []
                this.items = []

                this.$modelGet('assessment').then(r => this.assessmentGroups = r)

                this.$modelGet('category')
                    .then(responses => { 
                        this.categories = responses
                    })

                this.loading = true
                this.$modelGet('subcategory', { data_point_type_id: this.$props.rec.data_point_type_id })
                    .then(response => {
                        this.assessments = response

                        this.setColumns()
                        this.$refs.crud.read()
                            .then(() => {
                                this.originalItems = this.$_.cloneDeep(this.items)
                                this.$emit('count', this.originalItems.length)

                                // grab only the unique data_point_type_ids
                                let groups = this.items.filter((value, index, self) => {
                                    return self.findIndex(v => v.data_point_type_id === value.data_point_type_id) === index;
                                })
                                groups.forEach(group => {
                                    this.$modelGet('subcategory', { data_point_type_id: group.data_point_type_id })
                                        .then(response => {
                                            // set Alias as Assessment dropdown options to those assessments that only belong to the Assessment Group
                                            this.aliasAsAssessments[group.data_point_type_id] = response
                                        })
                                })
                            })
                            .finally(() => {
                                this.loading = false
                                this.sanitize()
                            })


                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>