<template>
    <field-container
        :title="title"
        :expanded="expanded"
        :global="global"
    >
        <fe-remote-combo
            label="Select school"
            v-model="localFilters.school_id"
            :url="schoolUrl"
            rootProperty="schools"
            chips
            multiple
        >
            <template slot="selection" slot-scope="data">
                <fe-chip
                    :input-value="data.selected"
                    @click:close="remove(data.item, 'school_id')"
                    close
                >
                    {{ data.item.name }}
                </fe-chip>
            </template>
            <template slot="item" slot-scope="data">
                <v-list-item-avatar v-if="data.item.school_group_id">
                    <span class="fa-stack">
                        <i class="fas fa-school fa-xs little-school"/>
                        <i class="fas fa-school big-school"/>
                    </span>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"/>
                </v-list-item-content>
            </template>
        </fe-remote-combo>

        <fe-remote-exclude-combo
            label="Select grade"
            :url="gradeUrl"
            rootProperty="grades"
            v-model="localFilters.grade_id"
        />

        <fe-remote-exclude-combo
            label="Select ethnicity"
            v-model="localFilters.ethnicity"
            :items="ethnicities"
            itemText="display_name"
            itemValue="value"
        />

        <fe-remote-exclude-combo
            label="Select disability"
            v-model="localFilters.disability_type_id"
            :items="disabilities"
            itemText="display_name"
            itemValue="id"
        />

        <fe-remote-combo
            label="Select gender"
            v-model="localFilters.gender"
            :items="genders"
            itemText="display_name"
            itemValue="value"
        />

        <fe-remote-exclude-combo
            v-if="tags || global"
            :label="global ? 'Select tag (Does not apply to Tag visualizations)' : 'Select tag'"
            url="tags.php?action=get&sort=name&dir=ASC"
            rootProperty="tags"
            v-model="localFilters.tag_id"
        />

        <fe-label v-if="minDays">
            Select minimum days students enrolled
            <span v-if="global">(Only applies to Attendance Visualizations)</span>
        </fe-label>
        <v-text-field
            v-if="minDays"
            v-model="localFilters.min_possible_attendance_days"
            type="number"
            mask="###"
            :rules="this.$fieldValidators('number', null, {required: false, min:0})"
            flat solo dense
        />

        <fe-remote-exclude-combo
            label="Select meal status"
            rootProperty="meal_status"
            v-model="localFilters.meal_status_id"
            multiple
            :items="meal_status"
            itemText="display_name"
            itemValue="id"
        />

        <div v-if="elStatusEnabled">
            <fe-remote-combo
                label="Select EL Status"
                :items="elStatusItems"
                v-model="localFilters.ell_flag"
            />
        </div>

        <div v-if="ellLevelEnabled">
            <fe-remote-exclude-combo
                label="Select ELP Level"
                rootProperty="ell_level"
                v-model="localFilters.ell_level_id"
                multiple
                :items="elp_levels"
                itemText="display_name"
                itemValue="id"
            />
        </div>

        <div class="d-flex">
            <div class="flex-grow-1 pr-2">
                <fe-remote-combo
                    label="Active Status"
                    :items="activeStatusItems"
                    :placeholder="'Both'"
                    v-model="localFilters.student_active_flag"
                />
            </div>
            <div class="flex-grow-1 pl-2">
                <fe-label>As Of</fe-label>
                <fe-date-picker
                    v-model="localFilters.student_active_date"
                    :disabled="activeAsOfDisabled"
                    prependIcon="fa-calendar-alt"
                    dateFormat="MM/DD/YYYY"
                    clearable
                />
            </div>
        </div>

        <advanced-demographic-filter
            v-for="demoItem in activeDemographics"
            :key="demoItem.id"
            :demographic="demoItem"
            v-model="demoFilters[demoItem.id]"
            class="demo-field"
            labelOnTop
            byId
        />
    </field-container>
</template>

<script>
import FieldContainer from "./FieldContainer"
import AdvancedDemographicFilter from "@/components/common/AdvancedDemographicFilter"

export default {
    name: "StudentFilters",

    components: {
        FieldContainer,
        AdvancedDemographicFilter
    },

    props: {
        dashboard: {
            type: Object,
            required: true
        },
        filters: {
            type: Object,
            required: true
        },
        tags: {
            type: Boolean,
            default: true
        },
        minDays: {
            type: Boolean,
            default: false
        },
        global: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            expanded: false,
            localFilters: {
                school_id: undefined,
                grade_id: undefined,
                ethnicity: undefined,
                disability_type_id: undefined,
                gender: undefined,
                tag_id: undefined,
                meal_status_id: undefined,
                ell_flag: undefined,
                ell_level_id: undefined,
                student_active_flag: undefined,
                student_active_date: undefined,
                min_possible_attendance_days: undefined,
                demographic: undefined
            },
            demoFilters: {},
            activeStatusItems: [
                {name: 'Inactive', id: 0},
                {name: 'Active', id: 1}
            ],
            elStatusItems: [
                {name: 'Yes', id: 1},
                {name: 'No', id: 0}
            ],
            elp_levels: [],
            genders: [],
            disabilities: [],
            ethnicities: [],
            meal_status: [],
            meal_status_all: [],
        }
    },

    computed: {
        schoolUrl() {
            return 'schools.php?action=get&incl_groups=1&school_year_id=' + this.dashboard.school_year_id
        },
        gradeUrl() {
            let url = 'grades.php?action=get&school_year_id=' + this.dashboard.school_year_id
            if (this.localFilters.school_id) {
                url += '&school_id=' + this.localFilters.school_id.map(x => x.id).join(',')
            }
            return url
        },
        title() {
            return this.global ? 'Global Dashboard-Level Filters' : 'Student Filters (Optional)'
        },
        activeDemographicMap() {
            let activeDemographicMap = {}
            this.activeDemographics.forEach(demo => activeDemographicMap[demo.id] = demo)
            return activeDemographicMap
        },
        demographics() {
            return this.$store.getters['global/demographicsSearchable']
        },
        activeDemographics() {
            return this.demographics.filter(dmo => dmo.active)
        },
        elStatusEnabled () {
            return this.$store.state.global.shareableStores.el_status_enabled
        },
        ellLevelEnabled () {
            return this.$store.state.global.shareableStores.ell_level_enabled
        },
        activeAsOfDisabled() {
            return this.localFilters.student_active_flag?.id == null
        }
    },

    watch: {
        localFilters: {
            deep: true,
            handler(v) {
                this.$emit('change', v)
            }
        },

        demoFilters: {
            deep: true,
            handler(v) {
                let demoFilters = []
                Object.keys(v).forEach(k => {
                    if (!this.activeDemographicMap[k]) return;
                    if (!v[k]) return;
                    let demo = this.activeDemographicMap[k]
                    // option is comma delimited
                    if (demo.value_type === 'option') {
                        let values = v[k].split(',')
                        values.forEach(val => {
                            demoFilters.push(k + ':' + val)
                        })
                    } else {
                        // strip out any commas the user may have entered
                        demoFilters.push(k + ':' + v[k].replace(/,/g, ' '))
                    }
                })
                if (demoFilters.length) {
                    this.localFilters.demographic = demoFilters
                } else {
                    this.localFilters.demographic = undefined
                }
            }
        }
    },

    mounted() {
        let newFilters = this.$_.cloneDeep(this.filters)
        if (this.global || Object.values(newFilters).some(x => !this.$_.isEmpty(x))) {
            this.expanded = true
        }
        this.localFilters = Object.assign({}, this.localFilters, newFilters)
        if (newFilters.demographic) {
            this.setDemoFilters(newFilters.demographic)
        }

        // load hybrid demo data into student filter select menus
        this.getHybrids('ellLevel', 'ell_level', 'elp_levels', 'name', 'id', 'ell_level_id')
        this.getHybrids('gender', 'gender', 'genders', 'value', 'string', 'gender')
        this.getHybrids('ethnicity', 'ethnicity', 'ethnicities', 'value', 'string', 'ethnicity')
        this.getHybrids('studentDisabilityType', 'student_disability_type', 'disabilities', 'description', 'id', 'disability_type_id')
        this.getHybrids('mealStatusType', 'meal_status', 'meal_status', 'name', 'id', 'meal_status_id')

    },

    methods: {
        setDemoFilters(v) {
            let demoFilters = {}
            v.forEach(item => {
                let match = item.name.match(/([0-9]+)\:(.+)/)
                let key = match[1]
                let value = item.rcExclude ? '!' + match[2] : match[2]
                if (!demoFilters[key]) {
                    demoFilters[key] = value
                } else {
                    demoFilters[key] += ',' + value
                }
            })
            this.demoFilters = demoFilters
        },

        /**
         * getHybrids()
         * Load hybrid demographic data into select menus
         * Pre-select any options if editing
         *
         * @param model: ellLevel | gender | ethnicity | studentDisabilityType | mealStatusType
         * @param root: ell_level | gender | ethnicity | student_disability_type | meal_status
         * @param container: elp_levels | genders | ethnicities | disabilities | meal_status
         * @param name: name | value | description
         * @param type: id | string
         * @param localFilter: ell_level_id | gender | ethnicity | disability_type_id | meal_status_id
         */

        getHybrids(model, root, container, name, type, localFilter) {
            let id = type === 'id' ? 'id' : 'value'
            this.$axios.get(this.$models.getUrl(model, 'read'))
                .then(r => {
                    this[container] = r.data[root]
                    // if display_name, change name to display_name for display
                    let filteredOptions = []
                    this[container].forEach(option => {
                        if (!option.display_name_group) {
                            option.display_name = option.display_name || option[name]
                            filteredOptions.push(option)

                            // for loading previous selections on edit
                            // need to add `value` and `display_name` fields
                            // to localFilters object so it shows up selected in menu
                            if (this.localFilters[localFilter]) {
                                let i = -1
                                if (this.localFilters[localFilter].length) {
                                    i = this.localFilters[localFilter].findIndex(f => f.name && f.name === option[name])
                                }
                                if (i !== -1) {
                                    if (    !this.localFilters[localFilter][i].id ||
                                            (this.localFilters[localFilter][i].id && isNaN(parseInt(this.localFilters[localFilter][i].id)))
                                    )  {
                                        this.localFilters[localFilter][i] = {
                                            ...this.localFilters[localFilter][i],
                                            display_name: option.display_name,
                                            [id]: option[name]
                                        }
                                    } else {
                                        this.localFilters[localFilter][i] = {
                                            ...this.localFilters[localFilter][i],
                                            display_name: option.display_name
                                        }
                                    }
                                } else if (this.localFilters[localFilter].name === option[name]) {
                                    this.localFilters[localFilter] = {
                                        ...this.localFilters[localFilter],
                                        display_name: option.display_name,
                                        [id]: option[name]
                                    }
                                }
                            }
                        } else {
                            let o = this[container].find(x => x[id] == option.display_name_group[0]) // find and return first option within display_name_group
                            if (!filteredOptions.find(x => x.display_name === o.display_name)) {
                                o = {
                                    ...o,
                                    display_name: o.display_name || o[name] // use display_name instead of value
                                }
                                filteredOptions.push(o)
                            }

                            // for loading previous selections on edit
                            // need to add `value` and `display_name` fields
                            // to localFilters object so it shows up selected in menu
                            if (this.localFilters[localFilter]) {
                                this.localFilters[localFilter].forEach((f,i) => {
                                    if (f.name && f.name === o[name]) {
                                        if (    !this.localFilters[localFilter][i].id ||
                                                (this.localFilters[localFilter][i].id || isNaN(parseInt(this.localFilters[localFilter][index].id)))
                                        )  {
                                            this.localFilters[localFilter][i] = {
                                                ...this.localFilters[localFilter][i],
                                                display_name: o.display_name,
                                                display_name_group: option.display_name_group,
                                                [id]: o[name]
                                            }
                                        } else {
                                            this.localFilters[localFilter][i] = {
                                                ...this.localFilters[localFilter][i],
                                                display_name: o.display_name,
                                                display_name_group: option.display_name_group
                                            }
                                        }
                                    }
                                })
                            }
                        }
                    })
                    this[container] = filteredOptions.sort((a, b) => a.display_name < b.display_name ? -1 : 1)
                    if (this.localFilters[localFilter] && this.localFilters[localFilter].length) {
                        this.localFilters[localFilter] = this.localFilters[localFilter].filter(obj => {
                            // remove items from array that don't have a display_name
                            // prevents empty chips in the menus
                            return obj.display_name !== undefined
                        })
                    }
                })
        },

        remove(v, key) {
            this.localFilters[key] = this.localFilters[key].filter(x => (x.id !== v.id) && (x !== v.id))
        }
    }
}
</script>

<style lang="scss" scoped>
.little-school {
    font-size: 12px;
    position: absolute;
    top: -4px;
    left: 3px;
    color: #b2b2b2;
}

.big-school {
    font-size: 16px;
    position: absolute;
    top: 3px;
    left: 8px;
    color: #8b8b8b;
}

.demo-field {
    width: 100%;
    padding-bottom: 24px;
}
</style>
