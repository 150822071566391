<template>
    <fe-filter-btn
        ref="filterBtn"
        :title="dateTitle"
        :menuOptions="{maxWidth:'500px'}"
    >
        <v-form @submit.prevent v-model="form" ref="form">
            <fe-remote-combo
                byId
                v-model="localValue.dateType"
                itemValue="value"
                :items="[
                    {value:'my', name:'Modified in School Year'},
                    {value:'cy', name:'Created in School Year'},
                    {value:'mr', name:'Modified in Date Range'},
                    {value:'cr', name:'Created in Date Range'},
                ]"
                :clearable="false"
            />
        
            <fe-remote-combo
                v-if="localValue.dateType[1]=='y'"
                url="qry.php?property=school_years"
                style="width:270px;"
                rootProperty="years"
                itemText="name"
                itemValue="id"
                placeholder="All Years"
                byId
                multiple moreText
                v-model="localValue.school_year_id"
            />

            <div v-if="localValue.dateType[1]=='r'" class="d-flex" style="width:400px;">
                <fe-date-picker
                    v-model="localValue.start_date"
                    placeholder="Start Date"
                    emitWhenValid
                    class="mr-2"
                    :max="localValue.end_date"
                    :rules="$fieldValidators('text', null, { required: true })"
                />

                <fe-date-picker
                    v-model="localValue.end_date"
                    placeholder="End Date"
                    emitWhenValid
                    class="ml-2"
                    :min="localValue.start_date"
                    :rules="$fieldValidators('text', null, { required: true })"
                />
            </div>
        </v-form>
    </fe-filter-btn>
</template>

<script>
export default {
    name: 'FormDateFilter',
    mounted() {
        this.emitValue()
            
        this.$watch('$refs.filterBtn.openMenu', v => {
            if(!this.form) {
                this.$refs.form.validate()
                this.$refs.filterBtn.openMenu = true
                return
            }
            
            if(v) {
                this.oldValue = JSON.stringify(this.localValue)
            } else if(this.oldValue != JSON.stringify(this.localValue)) {
                this.emitValue()
            }
        })
    },
    methods: {
        emitValue() {
            let v = this.localValue
            this.$emit('input',this.$_.pickBy({
                year_type: v.dateType[0] == 'm' ? 'updated' : undefined,
                created_start_date: v.dateType == 'cr' ? v.start_date : undefined,
                created_end_date: v.dateType == 'cr' ? v.end_date : undefined,
                last_updated_start_date: v.dateType == 'mr' ? v.start_date : undefined,
                last_updated_end_date: v.dateType == 'mr' ? v.end_date : undefined,
                school_year_id: v.dateType[1] == 'y'
                    ? (v.school_year_id?.length ? v.school_year_id.join(',') : v.school_year_id)
                    : undefined,
            }))
        }
    },
    computed: {
        dateTitle() {
            if(this.localValue.dateType[1] == 'y') {
                if(!this.$_.isBlank(this.localValue.school_year_id)) {
                    let ids = String(this.localValue.school_year_id).split(',')
                    return ids.length>1
                    ? 'School Years - '+ids.length
                    : this.$store.state.global.shareableStores.school_years.find(x => x.id == ids[0]).name
                }
                return 'All Years'
                
            } else {
                return 'Date Range'
            }
        },
    },
    data() {
        return {
            form: null,
            localValue: {
                dateType: 'my',
                school_year_id: this.$store.state.global.shareableStores.current_year.id,
                start_date: null,
                end_date: null,
            },
        }
    },
}
</script>

<style lang="css" scoped>
</style>
