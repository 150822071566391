let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    studentGradeHistory: {
        defaults: {
            endpoint: 'students.php?property=grade_history',
        },
        ...standardCrud
    },
    studentFormCount: {
        defaults: {
            endpoint: 'formResponse.php?action=count',
        },
        read: {

        }
    },
    studentMessageCount: {
        defaults: {
            endpoint: 'message.php?action=count',
        },
        read: {

        }
    },
    studentObservationCount: {
        defaults: {
            endpoint: 'observations.php?property=count&action=get',
        },
        read: {
            params: {

            }
        }
    },
    studentTags: {
        defaults: {
            endpoint: 'tags.php?action=get&property=student_tags',
            rootProperty: 'student_tags'
        },
        ...standardCrud
    },
    studentThresholdGroupResult: {
        defaults: {
            endpoint: 'thresholdGroupResult.php?action=get&property=student_threshold',
            rootProperty: 'threshold_group_result'
        },
        ...standardCrud
    },
    studentMerge: {
        defaults: {
            endpoint: 'studentMerge.php'
        },
        ...standardCrud
    },
    studentImage: {
        read: {
            endpoint: 'students.php?action=get&property=student_image'
        }
    },
    student: {
        defaults: {
            endpoint: 'students.php?action=get&property=student'
        },
        read: {
            
        }
    },
    studentLogin: {
        defaults: {
            endpoint: 'students.php?action=get&property=login',
            rootProperty: 'student_logins'
        },
        ...standardCrud
    },
    studentDisability: {
        defaults: {
            endpoint: 'studentDisabilities.php',
            rootProperty: 'student_disability'
        },
        ...standardCrud
    },
    userStudentSubscription: {
        defaults: {
            endpoint: 'crud.php?property=UserStudentSubscription',
            rootProperty: 'user_student_subscription'
        },
        ...standardCrud
    },
    studentAttachment: {
        defaults: {
            endpoint: 'studentAttachment.php',
            rootProperty: 'student_attachments'
        },
        ...standardCrud
    },
    studentForms: {
        defaults: {
            endpoint: 'formResponse.php?action=get&property=form_instance',
            rootProperty: 'form_instances'
        },
        ...standardCrud
    },
    studentDuplicateScore: {
        defaults: {
            endpoint: 'studentMerge.php?property=duplicate_scores',
            rootProperty: 'duplicates'
        },
        ...standardCrud
    },
    studentDoNotMerge: {
        defaults: {
            endpoint: 'crud.php?property=DistinctStudent',
            rootProperty: 'distinct_student',
        },
        ...standardCrud
    },
}