<template>
    <div>
        <v-snackbar
            v-for="w in waiters"
            style="max-width:404px;"
            :value="true"
            :timeout="-1"
            color="#152452"
            content-class="content align-center"
            right
            multi-line
        >
            <v-progress-circular v-if="w.status == null" indeterminate color="#539AF8" :size="40" />
            <v-icon v-if="w.status === true" size="20" color="#5BE893">check</v-icon>
            <v-icon v-if="w.status === false" size="20" color="#FF675D">ban</v-icon>

            <div class="flex-column ml-3 my-2">
                <div v-html="w.text" />
                <div class="text-smaller" v-html="w.subtext" />
                <!-- {{w.batch_job_id}} -->
            </div>

            <template #action="{attrs}">
                <a v-if="w.href" :href="w.href" @click="remove(w)">
                    <v-icon size="20" color="#C0C3CF">fas fa-arrow-alt-to-bottom</v-icon>
                </a>
                <v-btn icon @click="remove(w)">
                    <v-icon size="20" color="#C0C3CF">close</v-icon>
                </v-btn>
            </template>

        </v-snackbar>

        <fe-crud
            ref="crud"
            :readParams="{message_type_id:'13,14', action:'get_bell_messages', read_flag:0}"
            :config="$models.message"
            @read="readCrud"
        />
    </div>
</template>

<script>

export default {
    name: 'WaiterDialog',

    props: {
        pollData: {},
    },

    mounted() {
        this.$root.$on('waiterDialog', this.onWaiterDialog)
    },
    watch: {
        pollData(v) {
            if(this.waiters.some(x => !x.href)) {
                this.$refs.crud.read()
            }
        },
    },
    methods: {
        remove(waiter) {
            this.waiters = this.waiters.filter(x => x != waiter)
        },
        readCrud(e) {
            this.waiters.forEach(waiter => {
                let msg = e.find(msg => msg.batch_job_id == waiter.batch_job_id)
                if(msg) {
                    this.processMatch(waiter,msg)
                }
            })
        },
        onWaiterDialog(e) {
            if(e.action == 'create') {
                this.waiters.push({
                    text: e.text || 'Job Started',
                    subtext: e.subtext || `If you dismiss this notification, you’ll be alerted in the ${this.bell} upon completion of this job`,
                    status: null,
                    href: null,
                    callback: null,
                    ...e,
                })
            } else if(e.action == 'remove') {
                this.waiters.forEach(waiter => {
                    if(waiter.batch_job_id == e.batch_job_id) this.remove(waiter)
                })
            } else {
                console.warn('waiterDialog: event not processed',e);
            }
        },
        processMatch(waiter,msg) {
            if(msg.recipient.read_flag) {
                this.remove(waiter)
            } else {
                let json
                try { json = JSON.parse(msg.value) }
                catch(e) { json = null }

                if(msg.message_type_id == 13) {
                    waiter.status = true
                    waiter.text = waiter.successText || 'Job Complete'
                    waiter.subtext = waiter.successSubText || `If you dismiss this notification, you can find your files in the ${this.bell} on the nav`
                    waiter.href = json ? json.href : null
                    if(waiter.callback) waiter.callback()
                } else {
                    waiter.status = false
                    waiter.text = json?.text ? json.text : 'Job Failed'
                    waiter.subtext = json?.subtext ? json.subtext : `Something went wrong! Please try again and if the issue persists, contact support.`
                    waiter.href = null
                }
                this.markRead(msg)
            }
        },
        markRead(msg) {
            this.$axios.get('message.php?action=get', {
                params: {
                    id: msg.id,
                    mark_as_read: 1,
                }
            })
            this.$emit('read',msg)
            // this.$root.$emit('poller', {action:'poll'})
        },
    },
    computed: {
        bell: me => '<i class="fas fa-bell theme--light"></i>'
    },
    data() {
        return {
            waiters: [],
        }
    },
}
</script>

<style lang="scss" scoped>
    .content {
        padding: 24px;

    }
</style>
