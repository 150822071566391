<template>
    <fe-window
        title="View Attachment"
        dismissButtonText="Cancel"
        @dismiss="$emit('close')"
        @close="$emit('close')"
        :acceptButtonDisabled="true"
        :showTools="false"
    >
        <div v-if="this.viewType == 'pdf'" style="display: block; width: 50%; margin: 0 auto;">
            <pdf
                v-for="i in numPages"
                :src="src"
                class="my-4"
                style="display: block; width: 100%"
                :key="i"
                :page="i"
            ></pdf>
        </div>

        <img v-if="this.viewType == 'image'" :src="this.pdfData" class="my-4" style="display: flex; width: 50%; margin: 0 auto;" />
    </fe-window>
</template>

<script>
import pdf from 'vue-pdf'

export default {
    name: 'PdfView',

    props: {
        pdfData: {
            type: String,
            default: ''
        },
        viewType: {
            type: String,
            default: ''
        }
    },

    components: {
        pdf
    },

    data() {
		return {
			src: '',
			numPages: 0
		}
    },

    mounted() {
        if(this.viewType == 'pdf') {
            this.src = pdf.createLoadingTask(this.pdfData)
            this.src._capability.promise.then(pdf => {
                this.numPages = pdf.numPages
            })
        }
	}
}
</script>

<style lang="scss" scoped>
    ::v-deep div.fe-label {
        width: 76px;
    }

    ::v-deep {
        div.student-controls {
            width: 242px;
        }
    }

    ::v-deep .fe-window-title {
        width: calc(100vw - 25%) !important;
    }
</style>