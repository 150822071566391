export default {
    scoreDetailTypeSubCategory: {
        rules: [
            r => (r && r.sub_category_id && r.sub_category_id > 0) || { sub_category_id: 'Assessment is required' },
            r => (r && r.score_detail_type_id && r.score_detail_type_id > 0) || { score_detail_type_id: 'Score Detail is required' }
        ],
        defaults: {
            endpoint: 'scoreDetailTypeSubCategories.php', // scope: score_detail_type_id=209
            rootProperty: 'score_detail_type_sub_categories'
        },
        read: {
            params: { action: 'get' }
        },
        create: {
            params: { action: 'create' }
        },
        update: {
            params: { action: 'update' }
        },
        destroy: {
            params: { action: 'delete' }
        }
    }
}