<template>
    <div class="d-flex flex-column flex-fill">

        <record-dialog v-if="dialog.show"
            title="Assigned Staff"
            :value="dialog.record"
            :defaultRec="{
                student_id: $props.id
            }"
            @create="createRec"
            @close="dialog.show=false"
        >
            <template #form="{rec}">
                <fe-label>School Year</fe-label>
                <fe-remote-combo
                    url="qry.php?property=school_years"
                    v-model="rec.school_year_id"
                    rootProperty="years"
                    byId
                    :rules="$fieldValidators('select', null, {required: true })"
                />

                <fe-label>Tag</fe-label>
                <fe-remote-combo
                    url="tags.php?action=get&property=available_user_tags"
                    v-model="rec.tag_id"
                    rootProperty="tags"
                    :rules="$fieldValidators('select', null, {required: true })"
                    byId
                />

                <fe-label>User</fe-label>
                <fe-remote-combo
                    :disabled="!rec.school_year_id || !rec.tag_id"
                    :url="`users.php?action=get&tag_id=`+rec.tag_id+`&school_year_id=`+rec.school_year_id+`&property=by_tag`"
                    v-model="rec.user_id"
                    rootProperty="users"
                    itemText="user_full_name"
                    itemValue="user_id"
                    byId
                    :rules="$fieldValidators('select', null, {required: true })"
                />
            </template>
        </record-dialog>

        <fe-crud-grid
            ref="crud"
            :config="$models.userStudentSubscription"
            autoload
            @read="v => items = v"
            :readParams="{ student_id: this.$props.id }"
            @beginCreate="dialog.show=true; dialog.rec=[{}]"
            :columnDefs="columns"
            :rowData="items"
            displayDensity="medium"
            style="height: 400px"
            refreshOnCreated
            refreshOnDestroyed
            disableInlineCreate
        >
        </fe-crud-grid>
    </div>
</template>

<script>
    import RecordDialog from '@/components/common/form/RecordDialog'

    export default {
        name: 'UserSubscriptions',
        components: {
            RecordDialog,
        },
        data() {
            return {
                items: [],
                dialog: {
                    show: false,
                    rec: [{}]
                }
            }
        },
        props: ['id'],
        computed: {
            fields() {
                return [{
                    field: 'school_year_id',
                    label: 'School Year',
                    component: 'fe-remote-combo',
                    validatorKey: 'select',
                    validatorConfig: { required: true },
                    width: '100%',
                    attrs: {
                        url: 'qry.php?property=school_years',
                        rootProperty: 'years',
                        itemText: "name",
                        itemValue: "id"
                    }
                }, {
                    field: 'tag_id',
                    label: 'Tag',
                    component: 'fe-remote-combo',
                    validatorKey: 'select',
                    validatorConfig: { required: true },
                    width: '100%',
                    attrs: {
                        url: 'tags.php?action=get&property=available_user_tags',
                        rootProperty: 'tags',
                        itemText: "name",
                        itemValue: "id"
                    }
                }, {
                    field: 'school_year_id',
                    label: 'School Year',
                    component: 'fe-remote-combo',
                    validatorKey: 'select',
                    validatorConfig: { required: true },
                    width: '100%',
                    attrs: {
                        url: 'users.php?action=get&tag_id=356&school_year_id=16&property=by_tag',
                        rootProperty: 'years',
                        itemText: "name",
                        itemValue: "id"
                    }
                }]
            },
            columns() {
                return [this.$grid.checkColumn(), {
                   headerName: "School Year",
                   field: "school_year_name",
                   editable: false,
                   flex: 1
                }, {
                   headerName: "Student",
                   field: "student_full_name",
                   editable: false,
                   flex: 1
                }, {
                   headerName: "User",
                   field: "user_full_name",
                   editable: false,
                   flex: 1
                }, {
                   headerName: "User Tag Association",
                   field: "tag_name",
                   editable: false,
                   flex: 1
                }, {
                   headerName: "Created By",
                   field: "created_by_full_name",
                   editable: false,
                   flex: 1
                }, {
                   headerName: "Created",
                   field: "created",
                   editable: false,
                   flex: 1
                }]
            }
        },
        methods: {
            createRec(rec) {
                this.$refs.crud.$refs.crud.create([rec])
                    .then(response => {
                        // this.read()

                        this.dialog.show=false
                    })
            },
            read() {
                this.$refs.crud.$refs.crud.read({ student_id: this.$props.id })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
