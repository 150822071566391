<template>
    <div class="d-flex flex-fill flex-column" style="padding: 16px;">
        <div class="d-flex">
            <fe-title-select
                v-if="automationInstances.length > 0 && selectedInstance"
                placeholder="Select an Automation Instance"
                :items="automationInstances"
                v-model="selectedInstance"
                itemText="displayName"
                itemValue="name"
            ></fe-title-select>
        </div>

        <fe-crud-grid
            v-if="gridReady"
            :key="gridRenderKey"
            ref="grid"
            :columnDefs="columnDefs"
            :config="$models.instanceAutomation"
            :createParams="instanceParams"
            :readParams="instanceParams"
            :updateParams="instanceParams"
            :destroyParams="instanceParams"
            :insertDefaults="{active:1}"
            refreshOnCreated
            refreshOnUpdated
            style="height: 100%"
            displayDensity="small"
        />
    </div>
</template>

<script>
    export default {
        name: 'AimswebAutomation',
        data() {
            return {
                gridReady: false,
                gridRenderKey: `grid-${Math.random()}`,
                automationInstances: [],
                selectedInstance: null,
            }
        },
        async mounted() {
            this.automationInstances = await this.$modelGet('automationInstance').then(res => res.map(itm => ({
                ...itm,
                displayName: `${itm.name} Automation Manager`,
            })))

            if (this.automationInstances?.length > 0) {
                this.selectedInstance = this.automationInstances[0]
            }

            // Wait for nextTick to allow computed instanceParams to propagate
            this.$nextTick(() => {
                this.gridReady = true
            })
        },
        computed: {
            instanceParams() {
                return {
                    property: this.selectedInstance?.property.toLowerCase(),
                }
            },
            columnDefs() {
                let columnDefs = [
                    {
                        ...this.$grid.checkColumn(),
                        headerName: 'Checkbox',
                        colId: 'checkbox_column',
                        field: 'checkbox_column',
                        width: 50,
                        minWidth: 50,
                        maxWidth: 50,
                        pinned: 'left',
                    },
                    {
                        colId: 'id',
                        headerName: 'ID',
                        field: 'id',
                        width: 50,
                        minWidth: 50,
                        maxWidth: 50,
                        pinned: 'left',
                        hide: true,
                    },
                ]

                for (let prop of this.selectedInstance?.props) {
                    let columnDef
                    if (prop.column == 'active') {
                        columnDef = {
                            colId: 'active',
                            headerName: 'Active',
                            field: 'active',
                            cellRendererFramework: 'FeGridToggle',
                            minWidth: 60,
                            maxWidth: 60,
                        }
                    } else {
                        columnDef = {
                            colId: prop.column,
                            headerName: prop.name,
                            field: prop.column,
                            width: prop.width || 100,
                            editable: true,
                        }

                        if (prop.is_password) {
                            columnDef.cellRenderer = v => v.value ? (v.value && '&bull;'.repeat(16)) : ''
                        }
                    }

                    columnDefs.push(columnDef)
                }

                columnDefs = [
                    ...columnDefs,
                    {
                        colId: 'created',
                        headerName: 'Created',
                        field: 'created',
                        minWidth: 150,
                        maxWidth: 150,
                        editable: false
                    }, {
                        colId: 'updated',
                        headerName: 'Updated',
                        field: 'updated',
                        minWidth: 150,
                        maxWidth: 150,
                        editable: false
                    },
                ]

                return columnDefs
            },
        },
        watch: {
            selectedInstance(v) {
                // When we whole-sale change columnDefs on an ag grid, it gets
                // confused and starts screwing up the order of columns.  Let's
                // change the vue render key on the whole thing to force a full re-render
                this.gridRenderKey = `grid-${Math.random()}`

                // Wait for nextTick to allow computed instanceParams to propagate
                this.$nextTick(() => {
                    this.$refs.grid?.$refs.crud.read()
                })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
