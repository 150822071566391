<template>
    <div>
        <div class="caption">
            Progress monitoring scores have been found that have not been linked to an intervention plan. Select the measure below if you would like to use the scores that have been imported or entered.
            Future scores will automatically be added to measure linked to this intervention.
        </div>
        <div>
            <v-slide-y-transition mode="out-in">
                <fe-grid
                    key="list"
                    class="flex-grow-1 mt-4"
                    ref="grid"
                    displayDensity="small"
                    style="height: 200px;"
                    :showDensityToolbar="false"
                    :showToolbar="false"
                    :columnDefs="colDefs"
                    :rowData="items"
                    v-if="show==='list'"
                    @rowSelected="doSelect"
                >
                </fe-grid>

                <div v-else key='scores' style="">
                    <fe-btn usage="ghost"useIcon="fas fa-chevron-left" @click="show='list'">Back</fe-btn>
                    <fe-grid
                        displayDensity="small"
                        style="height: 200px;"
                        :showToolbar="false"
                        :columnDefs="scoreColDefs"
                        :rowData="scores"
                    />
                </div>
            </v-slide-y-transition>
            <!-- <div style="width: 200px; margin-top: 60px; border: 1px solid #dddddd; border-radius: 4px;" class="mx-2 pa-3">
                <div class="section-title">Scores</div>
                    <div class="d-flex">
                        <div class="flex-grow-1">1/1/2020</div>
                        <div class="flex-grow-1 text-right">200</div>
                    </div>
            </div> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StudentMonitorList',
        data() {
            let me = this
            return {
                show: 'list',
                items: [],
                scores: [],
                scoreColDefs: [{
                    headerName: 'Date',
                    field: 'schedule_date_occurrence',
                    flex: 1
                }, {
                   headerName: "Score",
                   field: "mapped_score",
                   editable: false,
                   flex: 1
                }],
                colDefs: [this.$grid.checkColumn(), {
                   headerName: "Student",
                   field: "student_full_name",
                   editable: false,
                   flex: 1
                }, {
                   headerName: "Skill Area",
                   field: "category_name",
                   editable: false,
                   flex: 1
                }, {
                   headerName: "Monitor",
                   field: "sub_category_name",
                   editable: false,
                   flex: 1
                }, {
                   headerName: "Start Date",
                   field: "schedule_start_date",
                   editable: false,
                   maxWidth: 140
                }, {
                   headerName: "End Date",
                   field: "schedule_end_date",
                   editable: false,
                   maxWidth: 140
                }, {
                    headerName: 'Import ID',
                    field: 'student_monitor_plan_upload_id',
                    editable: false,
                    maxWidth: 90
                }, {
                    headerName: 'View',
                    cellStyle: {
                        textDecoration: 'underline',
                        textAlign: 'center',
                        cursor: 'pointer',
                        color: 'darkblue'
                    },
                    onCellClicked(cell) {
                        me.show='scores'
                        me.loadScores({ student_id: cell.data.student_id, student_monitor_plan_id: cell.data.student_monitor_plan_id })
                    },
                    cellRenderer() {
                        return 'View Scores'
                    }
                }]
            }
        },
        props: {
            params: {
                type: Object,
                required: true
            }
        },
        mounted() {
            this.loadData()
        },
        methods: {
            doSelect() {
                this.$emit('input', this.$refs.grid.gridApi.getSelectedRows())
            },
            loadData() {
                if (!this.params) return

                this.$axios.get('studentMonitors.php?action=get&'+this.$objectToParams(this.params))
                    .then(r=>this.items=this.$ecResponse(r, 'monitors'))
            },
            loadScores(p) {
                this.$axios.get('studentMonitors.php?action=get&property=monitor_scores&'+this.$objectToParams(p))
                    .then(response => this.scores = this.$ecResponse(response, 'monitor_scores'))
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
