<template>
    <div style="width: 363px;" class="vertical-layout">
        <div class="layout-static">
            <v-toolbar color="white" flat>
                <v-toolbar-title>
                    {{ $t('groups.title') }}
                </v-toolbar-title>

                <v-spacer/>

                <fe-btn v-if="canManage" usage="primary" @click="$emit('mode', 'creating')">
                    {{ $t('groups.newPrompt') }}
                </fe-btn>
            </v-toolbar>

            <v-divider/>
        </div>

        <div class="layout-stretched">
            <v-list ref="list" two-line>
                <v-list-item
                    :class="(selected && selected.id === itm.id) ? 'active' : 'inactive'"
                    v-for="(itm) in collection"
                    :key="itm.id"
                >
                    <v-list-item-content class="list-item" @click="setSelected(itm.id)">
                        <v-list-item-title v-html="itm.name"/>

                        <v-list-item-subtitle v-html="itm.created_by_name"/>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-menu right bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon x-small v-on="on">
                                    <v-icon>fal fa-ellipsis-v</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item v-if="canManage" :disabled="itm.is_queued" @click="configuringRun = true">
                                    <v-list-item-title>Run Now</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="print(itm)">
                                    <v-list-item-title>Print</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </div>

        <RunSettings
            v-if="configuringRun"
            @apply="queue"
            @dismiss="configuringRun = false"
        />
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import BaseService from '../services/BaseService'
import RunSettings from '../modals/RunSettings'

export default {
    name: 'Landing',

    components: {
        RunSettings
    },

    props: {
        mode: String,
        settings: Boolean
    },

    data() {
        return {
            configuringRun: null
        }
    },

    computed: {
        ...mapGetters('thresholds/group', [
            'collection',
            'clear',
            'selected'
        ]),

        canManage () { return this.$hasSecurity('MANAGE_THRESHOLDS') },

        editing() {
            return this.mode === 'editing'
        },

        creating() {
            return this.mode === 'creating'
        },

        viewing() {
            return this.mode === 'viewing'
        },

        thresholdGroup() {
            return this.$store.getters['thresholds/group/selected']
        },

        thresholdGroupId() {
            return this.thresholdGroup.id
        }
    },

    mounted() {
        if (this.clear) this.refresh()
        this.scrollToSelected()
    },

    methods: {
        ...mapActions('thresholds/group', [
            'refreshStatus',
            'refresh',
            'select',
            'remove'
        ]),

        queue(params) {
            this.configuringRun = false
            BaseService.add({action: 'run'}, params, {type: 'thresholdGroups'}).then(rsp => {
                if (rsp.data.success) {
                    this.$store.dispatch('thresholds/toast/push', rsp.data.msg)
                    this.$store.commit('thresholds/group/setStatus', {
                        id: params.id,
                        is_queued: true
                    })
                } else {
                    this.$store.dispatch('thresholds/toast/push', {message: `${rsp.data.msg}`, color: 'error', timeout: 3000})
                }
            })
        },

        print(itm) {
            this.$emit('exporter', {type: 'print', obj: itm})
        },

        setSelected(id) {
            if (!this.selected || this.selected.id !== id) {
                this.select({id: id})
            }
        },

        scrollToSelected() {
            this.$nextTick(() => {
                let el = document.getElementsByClassName("active")[0]
                if (el) {
                    el.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center"
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../theme.scss";

.list-item {
    cursor: pointer;
}

.active {
    background-color: rgba(196, 220, 250, 0.4);
}
</style>
