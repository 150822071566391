import BaseService from '@/components/modules/thresholds/services/BaseService'
import BaseStore from '../Base'

export default Object.assign({}, BaseStore.default(), {
    namespaced: true,

    getters: Object.assign({}, BaseStore.default().getters, {
        __config(state, getters, rootState, rootGetters) {
            return {
                service: BaseService,
                params: {property: 'form', threshold_group_id: rootGetters['thresholds/group/selectedId']},
                prop: 'threshold_form',
                opts: {type: 'thresholdGroupProps'},
                add: {
                    service: BaseService,
                    params: {property: 'form', threshold_group_id: rootGetters['thresholds/group/selectedId']},
                    prop: 'form',
                    opts: {type: 'thresholdGroupProps'},
                },
                update: {
                    service: BaseService,
                    params: {property: 'form', threshold_group_id: rootGetters['thresholds/group/selectedId']},
                    prop: 'form',
                    opts: {type: 'thresholdGroupProps'},
                },
                remove: {
                    service: BaseService,
                    params: {property: 'form', threshold_group_id: rootGetters['thresholds/group/selectedId']},
                    prop: 'form',
                    opts: {type: 'thresholdGroupProps'},
                }
            }
        },

        __postData: (state, getters) => {
            return {
                dirtied: getters.dirtied,
                removed: getters.removed.map(obj => {
                    return {id: obj.id}
                }),
                added: getters.added.map(obj => {
                    return {
                        threshold_group_id: obj.threshold_group_id,
                        form_id: obj.form_id,
                        threshold_performance_band_id: obj.threshold_performance_band_id
                    }
                })
            }
        }
    })
})
