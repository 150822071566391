<template>
    <div class="data-exporter">
        <fe-dialog
            width="80%"
            :title="title"
            @dismiss="dismiss"
        >
            <ItemSelector
                v-if="type === 'print' && schoolYearSuccess"
                labelProp="name"
                keyProp="id"
                label="Results for School Year"
                :required="true"
                :collection="validSchoolYearCollection"
                :selectedKeys="schoolYearId"
                :chips="false"
                @change="changeYear"
            />

            <fe-grid
                v-if="success && collection.length > 0"
                ref="grid"
                domLayout="normal"
                :showToolbar="false"
                :columnDefs="columnDefs"
                :rowData="rowData"
                :styles="{ height: '300px' }"
            />

            <Empty
                v-if="success && collection.length === 0"
                class="mt-4"
                :title="restrictedCount === 0 ? 'No Results' : 'You do not have permission to view any of the students who meet this criteria'"
                :body="restrictedCount === 0 ? 'No results were returned for the selected school year.' : ''"
            />

            <v-btn v-if="pending || schoolYearPending" text disabled>
                <v-progress-circular indeterminate color="#999" size="20"/>
            </v-btn>

            <template v-slot:footer>
                <div v-if="restrictedCount">{{ restrictedCount }} additional students were included in this run that you do not have permission to view.</div>

                <v-spacer/>

                <fe-btn usage="ghost" @click="dismiss">Cancel</fe-btn>

                <fe-btn
                    usage="primary"
                    :loading="acceptButtonLoading"
                    :disabled="acceptButtonDisabled"
                    @click="printData"
                >
                    {{ acceptButtonText }}
                </fe-btn>
            </template>
        </fe-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import BaseService from '../services/BaseService'
import ItemSelector from '../controls/ItemSelector'
import Empty from '../templates/Empty'

export default {
    name: 'DataExporter',

    components: {
        ItemSelector,
        Empty
    },

    props: {
        type: {type: String, default: 'print'},
        obj: {type: Object, default: null}
    },

    data() {
        return {
            status: null,
            collection: [],
            restrictedCount: 0,
            selectedSchoolYearId: null,
            printing: false,
            gridApi: null
        }
    },

    computed: {
        ...mapGetters({
            schoolYearPending: 'thresholds/schoolYear/pending',
            schoolYearCollection: 'thresholds/schoolYear/collection',
            schoolYearSuccess: 'thresholds/schoolYear/success',
            schoolYearError: 'thresholds/schoolYear/error'
        }),

        pending() {
            return this.status === 'pending'
        },

        error() {
            return this.status === 'error'
        },

        success() {
            return this.status === 'success'
        },

        columnDefs() {
            let fields = [
                {title: 'Student', name: 'student', type: 'string'},
                {title: 'Performance Band', name: 'band', type: 'string'},
                {title: 'Grade', name: 'grade', type: 'string'},
                {title: 'School', name: 'school', type: 'string'},
                {title: 'Assessment', name: 'assessment', type: 'array'},
                {title: 'Attendance', name: 'attendance', type: 'array'},
                {title: 'Behavior', name: 'behavior', type: 'array'},
                {title: 'District ID', name: 'lasid', type: 'string'},
                {title: 'State ID', name: 'sasid', type: 'string'}
            ]

            let filtered = fields.filter(field => {
                return !!this.collection.find(x => {
                    return (Array.isArray(x[field.name]))
                        ? x[field.name].length > 0
                        : !!x[field.name]
                })
            })

            return filtered.map(field => {
                return {
                    headerName: field.title,
                    field: field.name,
                    sortable: true
                }
            })
        },

        rowData() {
            return this.collection
        },

        validSchoolYearCollection() {
            return this.schoolYearCollection.filter(x => !!x.active)
        },

        schoolYearId() {
            if (this.selectedSchoolYearId) {
                return this.selectedSchoolYearId
            } else if (this.validSchoolYearCollection.length > 0) {
                return this.validSchoolYearCollection[0].id
            }
            return null
        },

        schoolYearName() {
            if (this.obj && this.obj.school_year_id) {
                return this.schoolYearCollection.find(x => x.id === this.obj.school_year_id).name
            } else if (!this.obj && this.schoolYearId) {
                return this.schoolYearCollection.find(x => x.id === this.schoolYearId).name
            }
            return null
        },

        thresholdGroup() {
            return this.$store.getters['thresholds/group/selected']
        },

        thresholdGroupId() {
            return this.thresholdGroup ? this.thresholdGroup.id : null
        },

        acceptButtonText() {
            if (this.pending) {
                return 'Loading...'
            } else if (this.printing) {
                return 'Preparing Document...'
            } else {
                return 'Print'
            }
        },

        acceptButtonLoading() {
            return (this.pending || this.printing)
        },

        acceptButtonDisabled() {
            return (this.success && this.collection.length === 0)
        },

        title() {
            return this.type === 'print' ? 'Print Threshold Data' : 'View Threshold Results'
        }
    },

    watch: {
        schoolYearSuccess: function () {
            if (this.schoolYearSuccess) this.reset()
        },

        thresholdGroupId: function () {
            this.status = null
            this.reset()
        }
    },

    mounted() {
        this.reset()
    },

    updated() {
        this.reset()
    },

    methods: {
        ...mapActions({
            schoolYearRefresh: 'thresholds/schoolYear/refresh',
            print: 'thresholds/group/printTable'
        }),

        dismiss() {
            this.$emit('close')
        },

        changeYear(val) {
            this.selectedSchoolYearId = val
            this.exportDataRefresh()
        },

        exportDataRefresh() {
            if (this.type === 'run' && this.obj) {
                this.doDataFetch({
                    threshold_group_id: this.thresholdGroupId,
                    threshold_group_run_history_id: this.obj.id,
                    school_year_id: this.obj.school_year_id
                })
            } else if (this.type === 'print' && this.schoolYearId) {
                this.doDataFetch({
                    threshold_group_id: this.obj.id,
                    school_year_id: this.schoolYearId
                })
            }
        },

        doDataFetch(params) {
            this.status = 'pending'
            this.collection = []
            this.restrictedCount = 0
            BaseService.fetch(params, {type: 'results'}).then(rsp => {
                if (rsp.data) {
                    this.collection = (rsp.data.threshold_group_result) ? rsp.data.threshold_group_result : []
                    this.restrictedCount = rsp.data.restricted
                    this.status = 'success'
                } else {
                    this.status = 'error'
                }
            }).catch(() => {
                this.status = 'error'
            })

        },

        reset() {
            if (this.type === 'print' && !this.schoolYearPending && !this.schoolYearSuccess && !this.schoolYearError) {
                this.schoolYearRefresh()
            }

            if (this.schoolYearId && !this.pending && !this.success && !this.error) {
                this.exportDataRefresh()
            }
        },

        printData() {
            this.printing = true
            let dataset = []
            let columns = this.columnDefs
            this.$refs.grid.gridApi.forEachNodeAfterFilterAndSort((row) => {
                dataset.push(columns.map(def => row.data[def.field]))
            })

            let params = {
                columns,
                dataset,
                groupName: this.thresholdGroup.name,
                schoolYearName: this.schoolYearName
            }

            this.print(params).then(() => {
                this.printing = false
            })
        }
    }
}
</script>
