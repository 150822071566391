<template>
    <div ref="longitudinalWrapper" class="d-flex flex-fill flex-column chart-container pa-0">
        <div class="d-flex flex-wrap">
            <fe-filter-btn
                ref="yearFilter"
                title="Year"
                url="qry.php?property=school_years"
                rootProperty="years"
                itemValue="id"
                itemText="name"
                multiple
                :closeOnSelect="false"
                v-model="selected.school_year_id"
                showFirstSelection
            />

            <fe-filter-btn
                ref="schoolFilter"
                title="School"
                :url="schoolURL"
                v-model="selected.school_id"
                :disabled="!selected.school_year_id"
                rootProperty="schools"
                multiple
                :closeOnSelect="false"
                showFirstSelection
            />

            <fe-filter-btn
                ref="gradeFilter"
                title="Grade"
                :url="gradeURL"
                v-model="selected.grade_id"
                :disabled="!selected.school_year_id"
                rootProperty="grades"
                multiple
                :closeOnSelect="false"
                showFirstSelection
            />

            <fe-filter-btn
                ref="groupFilter"
                title="Report By"
                :items="reportByItems"
                v-model="selected.group_by"
                :multiple="false"
                showFirstSelection
            />

            <fe-btn
                usage="primary"
                @click="updateSearch"
            >
                Search
            </fe-btn>
        </div>

        <highcharts v-if="!emptyShow" ref='chartRef' :options="optionsConfig" />

        <v-layout v-else align-center justify-center column fill-height text-center>
            <img width="340px" src="/images/rocket.png" />
            <div class="subheading">
                No records associated with the provided search parameters. Select new filters and search again.
            </div>
        </v-layout>
    </div>
</template>

<script>
    import Highcharts from 'highcharts'
    import { Chart } from 'highcharts-vue'
    import windowOptionsMixin from '@/mixins/windowOptions'

    export default {
        name: 'LongitudinalChart',
        mixins: [ windowOptionsMixin ],
        components: {
            Chart
        },
        props: {
            border: {
                type: Boolean,
                default: true
            },
            chartHeight: {
                type: Number,
                default: 200
            },
            chartWidth: {
                type: Number,
                default: 200
            },
            options: {
                required: true,
                type: Object
            },
            selectedData: {
                required: true,
                type: Object
            },
            chartConfigData: {
                required: true,
                type: Object
            },
            filterParams: {
                required: true,
                type: Object
            }
        },
        computed: {
            schoolURL() {
                if (this.schoolYearAsString) {
                    return 'schools.php?action=get&school_year_id=' + this.schoolYearAsString
                }
                return null
            },
            gradeURL() {
                if (this.schoolYearAsString) {
                    return 'grades.php?action=get&school_year_id=' + this.schoolYearAsString + '&school_id=' + this.selected.school_id
                }
                return null
            },

            schoolYearAsString() {
                let year = this.selected.school_year_id

                if (Array.isArray(year)) {
                    let yr = ''
                    year.forEach((v, i) => {
                        yr += (i === 0 ? '' : '%2C') + v
                    })
                    return yr
                } else {
                    return year
                }
            },

            wrapperHeight() {
                return this.$refs.longitudinalWrapper?.clientHeight - 48
            }
        },

        watch: {
            deep: true,
            chartConfigData(v) {
                if (v === {}) {
                    this.emptyShow = true
                }
                else {
                    this.emptyShow = false
                    this.chartConfig(v)
                }
            }
        },

        data() {
            return {
                title: '',
                selected: {},
                chartRecord: [],
                fields: [],
                optionsConfig: {},
                optionsData: {},
                overallRecord: {},
                reportByItems: [
                    {
                        id: 'ethnicity',
                        name: 'Ethnicity'
                    }, {
                        id: 'gender',
                        name: 'Gender'
                    }, {
                        id: 'grade',
                        name: 'Grade'
                    }, {
                        id: 'school',
                        name: 'School'
                    }
                ],
                emptyShow: true
            }
        },
        mounted() {
            this.optionsConfig = this.options
            this.optionsData = this.selectedData

            if (this.filterParams.school_year_id) {
                this.$modelGet('schoolYear').then(r => {
                    if (Array.isArray(this.filterParams.school_year_id)) {
                        this.filterParams.school_year_id.forEach(y => {
                            let curr = r.find(yr => yr.id === parseInt(y))
                            this.$refs.yearFilter.selectItem(curr, true)
                        })
                    } else {
                        let curr = r.filter(yr => yr.id === this.filterParams.school_year_id)
                        this.$refs.yearFilter.selectItem(curr[0], true)
                    }
                })

                if (this.filterParams.school_id) {
                    this.$modelGet('school', { school_year_id: this.schoolYearAsString }).then(s => {
                        let school = s.filter(sc => sc.id === this.filterParams.school_id)
                        this.$refs.schoolFilter.selectItem(school[0], true)
                    })
                }

                if (this.filterParams.grade_id) {
                    this.$modelGet('grade', { school_year_id: this.schoolYearAsString, school_id: this.selected.school_id})
                    .then(r => {
                        if (Array.isArray(this.filterParams.grade_id)) {
                            this.filterParams.grade_id.forEach(g => {
                                let curr = r.find(gr => gr.id === parseInt(g))
                                this.$refs.gradeFilter.selectItem(curr, true)
                            })
                        } else {
                            let curr = r.filter(gr => gr.id === this.filterParams.grade_id)
                            this.$refs.gradeFilter.selectItem(curr[0], true)
                        }

                        // let grade = g.filter(gr => gr.id === this.filterParams.grade_id)
                        // this.$refs.gradeFilter.selectItem(grade[0], true)
                    })
                }
            }

            if (this.filterParams.group_by) {
                let group = this.reportByItems.filter(gp => gp.id === this.filterParams.group_by)
                this.$refs.groupFilter.selectItem(group[0], true)
            }
        },
        methods: {
            updateSearch() {
                let params = {...this.filterParams, ...this.formatParams() }

                if (params.period && Array.isArray(params.period)) {
                    params.period = params.period.join('+')
                }

                this.$setLoading(true)
                this.$axios.get('targetScores.php?action=get&property=student_trendline&'+ this.$objectToParams(params))
                .then((response) => {
                    if (response?.data?.scores.length) {
                        this.emptyShow = false
                        this.chartConfig(response.data.scores[0])
                    }
                    else {
                        this.emptyShow = true
                    }
                }).finally(() => {
                    this.$setLoading(false)
                })
            },

            formatParams() {
                let p = {
                    school_year_id: null,
                    school_id: null,
                    grade_id: null
                }

                Object.keys(this.selected).forEach(sel => {
                    let val = this.selected[sel]

                    if (this.selected[sel].included) {
                        val = this.selected[sel].included
                    } else {
                        if (!Array.isArray(val)) val = [val]
                    }

                    val.forEach(rec => {
                        if (!p[sel]) p[sel] = []
                        if (rec.id) {
                            p[sel].push(rec.id)
                        } else if (rec[sel]) {
                            p[sel].push(rec[sel])
                        } else {
                            p[sel].push(rec)
                        }
                    })
                })

                return p
            },

            chartConfig(config) {
                let me = this
                let chartType = 'line'

                this.overallRecord = config
                this.chartRecord = [...this.overallRecord.chart_data]
                this.fields = this.overallRecord.fields
                this.seriesConfig = this.buildSeries(this.chartRecord, this.fields)
                this.optionsConfig = this.buildChart(config, this.seriesConfig, chartType)
            },

            buildSeries(data, fields, colors) {
                let me = this
                let categories = []
                let series     = []

                for (let index in data) {
                    let r = data[index]
                    fields.forEach(function(field, fieldIndex) {
                        if (field == "name") return
                        let serie = me.$_.find(series, function(o) {
                            return o.name == field
                        })

                        if (!serie) {
                            serie = {
                                name: field,
                                color: colors ? colors[fieldIndex] : '',
                                data: []
                            }
                            series.push(serie)
                        }

                        serie.data.push(r[field] ? r[field] : null)
                    })
                    categories.push(r.name)
                }

                return {
                    categories: categories,
                    series: series.reverse()
                }
            },

            buildChart(chartOptions, seriesConfig, chartType) {
                let me = this

                let cfg = {
                    chart: {
                        type: chartType,
                        height: this.wrapperHeight + 'px'
                        //height: '100%'
                    },
                    credits: {
                        enabled: false
                    },
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    },
                    title: {
                        text: '',
                    },
                    xAxis: {
                        categories: seriesConfig.categories
                    },
                    yAxis: {
                        endOnTick: false,
                        // min: 0,
                        title: {
                            text: 'Score'
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold'
                            }
                        }
                    },
                    legend: {
                        align: 'right',
                        verticalAlign: 'bottom',
                        shadow: false
                    },
                    tooltip: {
                        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                        shared: true
                    },
                    plotOptions: {
                        column: {
                            maxPointWidth: 50,
                            stacking: 'percent',
                            dataLabels: {
                                enabled: false
                            }
                        }
                    },
                    series: seriesConfig.series
                }

                return cfg
            }
        }
    }
</script>

<style lang="scss" scoped>

.chart-container {
    padding: 8px;
}

.border {
    border: 1px solid #E0E1E6;
    border-radius: 5px;
}

.chart-title-bar {
    height: 20px;
    border-radius: 2px;
    padding: 5px;

    .chart-title {
        color: #404040;
    }
}

.performance-band-indicator {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
}

.expand-chart-dialog {
    height: 90vh;
}

</style>
