<template>
    <div class='collapsible-panel d-flex flex-column' :class="{ 'is-expanded': !collapsed, 'is-collapsed': collapsed }">
        <div class="collapsible-panel--titlebar d-flex" @click="collapsed = !collapsed">
            <div class='title-text d-flex flex-grow-1'>
                <slot name="title">
                    <div class="collapsible-panel-headline" :class="smallTitle ? 'collapsible-panel-headline-small-font' : ''">{{ title }}</div>
                </slot>
            </div>
            <div class='title-icon d-flex flex-shrink-1 text-right'>
                <fe-icon-btn :useIcon="collapsed ? 'fas fa-angle-left' : 'fas fa-angle-down'" />
            </div>
        </div>
        <transition name="slide-y-transition">
            <div class="collapsible-panel--body" v-show="!collapsed">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'CollapsiblePanel',
        props: {
            title: { type: String },
            value: { type: Boolean, default: false },
            smallTitle: { type: Boolean, default: false }
        },
        data () {
            return { collapsed: null }
        },
        watch: {
            value: {
                handler(v) { this.collapsed = this.value },
                immediate: true
            },
            collapsed (v) { this.$emit('input', v) }
        }
    }
</script>

<style lang="scss" scoped>
    .collapsible-panel {
        width: 100%;
        margin-bottom: 16px;
        &--titlebar {
            width: 100%;
            cursor: pointer;
            .title-icon {
                position: relative;
                top: -10px;
            }
        }
        &--body { margin-bottom: 16px; }
        &-headline {
            font-weight: 400;
            line-height: 2rem;
            letter-spacing: normal!important;
        }
        &-headline-small-font {
            font-size: 18px !important;
        }
    }
</style>