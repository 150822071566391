<template>
    <div class="d-flex flex-fill flex-column" style="padding: 16px;">
        <v-dialog width="500" persistent v-model="dialog.show" v-if="dialog.show">
            <v-card>
                <v-card-title>
                    Create Importer
                </v-card-title>
                <v-card-text>
                    <model-form
                        idProperty="id"
                        @cancel="finish"
                        @done="finish"
                        :records="dialog.record"
                        model="importType"
                        :fieldConfig="formFields"
                        submitButtonText="Save"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <fe-dialog
            title="Duplicate Importer"
            v-if="copyDialog.show"
            @close="copyDialog.show=false; copyDialog.name = null"
            dismissButtonText="Cancel"
            :acceptButtonDisabled="!copyDialog.name"
            persistent
            @accept="doCopy"
            acceptButtonText="Save"
        >
            <fe-label>New Name</fe-label>
            <v-text-field
                counter=255
                flat solo dense v-model="copyDialog.name"
                :rules="$fieldValidators('text', null, {required: true, limit: 255})"
            />
        </fe-dialog>

        <div class="d-flex">
            <fe-btn chip usage="ghost" useIcon="fas fa-chevron-left" @click="$router.replace('/Uploads')"/>
            <div class="page-title">Import Layout Management</div>
        </div>

        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.importType"
            @read="v => items = v"
            :readParams="{all: 1}"
        />
        <fe-grid
            ref="grid"
            :rowData="items"
            :columnDefs="columns"
            domLayout="normal"
            class="flex-grow-1"
            displayDensity="small"
            disableInlineCreate
            :showAddRowBtn="selections.length == 0"
            @beginCreate="doCreate"
            @selectionChanged="selectRow"
            @cellClicked="handleClick"
            @cellValueChanged="doUpdate"
            @modelUpdated="resize"
        >
            <template v-slot:toolbar-items>
                <fe-btn usage="tertiary" v-if="selections.length > 0" @click="doEdit">Edit</fe-btn>
                <fe-btn usage="tertiary" v-if="selections.length == 1" @click="copyDialog.show=true; copyDialog.name = ''">Duplicate</fe-btn>
                <fe-btn usage="error" v-if="selections.length > 0" @click="doDelete">Delete</fe-btn>
            </template>
        </fe-grid>
    </div>
</template>

<script>
    import ModelForm from '@/components/common/form/ModelForm'
    import { mapState } from 'vuex'

    export default {
        name: 'ImportLanding',
        components: {
            ModelForm
        },
        data() {
            return {
                items: [],
                originalItems: [],
                importParsers: [],
                importerTypes: [],
                ready: false,
                selections: [],
                copyDialog: {
                    show: false,
                    name: ''
                },
                dialog: {
                    show: false,
                    record: []
                }
            }
        },
        watch: {
            items: {
                deep: true,
                handler(v) {
                    v.map(r => { r.import_parser_name = this.importParsers.find(parser => parser.id === r.import_parser_id).name})
                }
            }
        },
        computed: {
            formFields() {
                let me = this
                return [{
                    field: 'name',
                    label: 'Name',
                    width: '100%',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { required: true, limit: 255 },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true,
                        counter: 255
                    }
                },  {
                    field: 'import_parser_id',
                    label: 'Parser Type',
                    width: '100%',
                    component: 'fe-remote-combo',
                    validatorKey: 'select',
                    validatorConfig: { required: true },
                    attrs: {
                        items: me.importParsers,
                        byId: true
                    }
                },  {
                    field: 'import_file_type_id',
                    label: 'Import File Type',
                    width: '100%',
                    component: 'fe-remote-combo',
                    validatorKey: 'select',
                    validatorConfig: { required: true },
                    attrs: {
                        items: me.importerTypes,
                        itemValue: 'id',
                        itemText: 'code',
                        byId: true
                    }
                }]
            },
            columns() {
                let me = this
                return this.ready ? [this.$grid.checkColumn(), {
                    headerName: 'ID',
                    field: 'id',
                    width: 80,
                    hide: true
                }, {
                    headerName: 'Norms ID',
                    field: 'norms_id',
                    width: 80,
                    hide: true
                }, {
                    headerName: 'Importer',
                    field: 'name',
                    flex: 1,
                    cellClicked(v) {
                        me.handleClick(v)
                    },
                    cellStyle: {
                        cursor: 'pointer'
                    },
                    sort: 'asc',
                    comparator: function(a, b) {
                        return (a.toLowerCase() == b.toLowerCase()) ? 0 : (a.toLowerCase() > b.toLowerCase()) ? 1 : -1;
                    },
                }, {
                    headerName: 'Parser',
                    field: 'import_parser_name',
                    maxWidth: 200,
                    // rowGroup: 'import_parser_name',
                    // hide: true
                }, {
                    headerName: 'Import File Type',
                    field: 'import_file_type_id',
                    maxWidth: 200,
                    cellRenderer(v) {
                        return me.importerTypes.find(r => r.id === v.value).code
                    },
                    editable: true,
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: {
                        rowDataKey: "import_file_type_id",
                        mode: "edit",
                        multiple: false,
                        items: me.importerTypes,
                        keyProp: "id",
                        labelProp: "code"
                    }
                }, {
                    headerName: 'Header Regex',
                    field: 'header_preg_replace',
                    maxWidth: 200,
                    editable: true
                }, {
                    headerName: 'Created',
                    field: 'created',
                    maxWidth: 200
                }] : []
            }
        },
        mounted() {
            this.$refs.grid.gridOptions.groupUseEntireRow = true
            this.loadData()
        },
        methods: {
            selectRow() {
                this.selections = this.$refs.grid.gridApi.getSelectedRows()
            },
            doEdit() {
                this.dialog.record = this.$_.cloneDeep(this.selections)
                this.dialog.show = true
            },
            doCopy() {
                /*
                url: 'importTypes.php?action=copy',
                method: 'POST',
                jsonData: Ext.encode({
                    id: rec.get('id'),
                    name: value
                }),
                */
                this.$axios.post('importTypes.php?action=copy', {
                    id: this.selections[0].id,
                    name: this.copyDialog.name
                }).then(resp => {
                    this.$ecResponse(resp)
                    this.$refs.crud.read()
                })
            },
            doUpdate(meta) {
                let dirty = this.$isDirty(this.originalItems, this.items)
                if (dirty) this.$refs.crud.update([meta.data]).then(() => {
                    this.$refs.crud.read()
                })
            },
            doDelete() {
                this.$confirmDelete(this.selections, () => {
                    this.$refs.crud.destroy(this.selections).then(() => {
                        this.$refs.crud.read()
                    })
                })
            },
            doCreate() {
                this.dialog.record = [{}]
                this.dialog.show   = true
            },
            finish() {
                this.dialog.show = false
                this.selections  = []
                this.loadData()
            },
            handleClick(v) {
                if (v.colDef.field != 'name') return

                this.$router.replace('/ImportManager/'+v.data.id)
            },
            loadData() {
                this.ready = false
                this.$modelGet('importFileType').then(types => {
                    this.importerTypes = types

                    this.$modelGet('importParser').then(data => {
                        this.importParsers = data
                        this.$refs.crud.read().then(rec => {
                            this.originalItems = this.$_.cloneDeep(rec.data.types)
                        })


                        this.ready = true
                    })
                })

                // this.$modelGet('importType').then(resp => {
                //     this.items = resp
                // })
            },
            resize() {
                this.$refs?.grid?.gridApi?.sizeColumnsToFit()
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
