<template>
    <v-layout row fill-height>
        <fe-stepper-select-panel :items="fieldItems" v-model="field" width="25%" style="min-width: 130px !important;"/>
        <v-divider vertical style="background-color:#E0E1E6"/>

        <div style="width: 50%; overflow: auto;">
        <template v-if="field">
            <fe-stepper-panel v-if="field.component == 'fe-switch'">
                <div class="d-flex justify-space-between flex-row pa-4">
                    <fe-label>{{ field.componentLabel }}</fe-label>
                    <fe-switch v-model="filter[field.model]"></fe-switch>
                </div>
            </fe-stepper-panel>

            <fe-stepper-panel v-else-if="field.component == 'fe-date-picker'">
                <div class="d-flex justify-space-between flex-row pa-4">
                    <fe-label>{{ field.componentLabel }}</fe-label>
                    <fe-date-picker
                        v-model="filter[field.model]"
                        class="filter-datepicker"
                    />
                </div>
            </fe-stepper-panel>

            <fe-remote-combo v-else-if="field.component == 'fe-remote-combo'"
                :key="field.model"
                width="100%"
                v-model="filter[field.model]"
                :itemValue="field.itemValue"
                :itemText="field.itemText"
                :items="field.items"
                hide-details
                single
                chips
                byId
            />

            <fe-stepper-panel v-else-if="field.component == 'advanced-demographic'" width="100%">
                <advanced-demographic-filter
                    byId
                    :key="field.demographic.id"
                    :demographic="field.demographic"
                    useRemoteCheckboxPanels
                    :value="filter.demographics[`demo_${field.demographic.id}`]"
                    @result="updateAdvancedDemographic(field.demographic, $event)"
                    :labelOnTop="labelsOnTop"
                    :style="{
                        minWidth: vertical ? null : '250px',
                        width: vertical ? '100%' : '20%'
                    }"
                    :class="{
                        'pa-2': !vertical,
                        'd-flex': vertical && !labelsOnTop,
                        'justify-space-between': vertical,
                        'flex-row': vertical && !labelsOnTop,
                        'mx-2': labelsOnTop
                    }"
                />
            </fe-stepper-panel>

            <fe-stepper-checkbox-panel v-else
                :key="field.model"
                width="100%"
                :url="field.url"
                :items="field.items"
                byId
                :rootProperty="field.rootProperty"
                :itemText="field.itemText"
                :itemValue="field.itemValue"
                v-model="filter[field.model]"
                search
                :canExclude="field.canExclude === false ? false : true"
                :allOption="false"
                @change="onChangeSelection(field.label, $event)"
            />
        </template>
        </div>

        <fe-stepper-panel>
            <fe-search
                v-model="selectionsFilterText"
                placeholder="Selections"
            />
            <div class="ma-2">
            <div v-for="grp in selections" :key="grp.label" class="mb-4">
                <div class="fs18 d-flex">
                    <div>{{ grp.label }}</div>
                </div>

                <div v-for="item in grp.items.filter(itm => itm[grp.field.itemText || 'name'].toLowerCase().indexOf(selectionsFilterText.toLowerCase()) >= 0)" :key="item.id" :class="`d-flex selected-item ${item.exclude ? 'excluded-item' : ''}`">
                    <div class="selected-item-name">
                        {{ item[grp.field.itemText || 'name'] }}
                    </div>
                    <v-spacer/>
                    <div class="text-right mt-2">
                        <v-icon style="width: 20px;" @click="removeSelection(grp.label, item)">fas fa-times</v-icon>
                    </div>
                </div>

                <v-spacer/>
            </div>
            </div>
        </fe-stepper-panel>

        <fe-crud ref="mealStatusCrud" :config="$models.mealStatus" autoload @read="postReadMealStatus"/>
        <fe-crud ref="elpLevelCrud" :config="$models.ellLevel" autoload @read="postReadElpLevel"/>
    </v-layout>
</template>

<script>
    import { mapState } from 'vuex'

    import AssessmentStepperPanel from './AssessmentStepperPanel'
    import IncidentStepperPanel from './IncidentStepperPanel'
    import AdvancedDemographicFilter from "@/components/common/AdvancedDemographicFilter"

    export default {
        name: 'AdvancedFiltersStepperPanel',
        components: {
            AssessmentStepperPanel,
            IncidentStepperPanel,
            AdvancedDemographicFilter,
        },
        props: {
            value: {},
            vertical: {
                type: Boolean,
                default: true
            },
            labelsOnTop: {
                type: Boolean,
                default: true
            },
        },
        watch: {
            params: {
                handler(v) {
                    this.$emit('input', v)
                },
                deep: true
            },
            'params.attendance'(v) {
                if (v) {
                    this.selectedAttendance = [{
                        id: 1,
                        name: 'Include Attendance'
                    }]
                } else {
                    this.selectedAttendance = []
                }

                this.$emit('selected', true)
            }
        },
        data() {
            return {
                field: null,
                fields: [{
                    label: 'Hidden Assessment Windows',
                    model: 'incl_hidden_data_point',
                    component: 'fe-switch',
                    componentLabel: 'Include Hidden Windows',
                }, {
                    label: 'Tag',
                    model: 'tag_id',
                    url: 'tags.php?action=get',//me.tagURL(),
                    rootProperty: 'tags',
                }, {
                    label: 'Ethnicity',
                    model: 'ethnicity',
                    url: 'filters.php?action=get&filter=ethnicity',
                    rootProperty: 'ethnicities',
                    itemText: "ethnicity",
                    itemValue: "ethnicity",
                }, {
                    label: 'Disability Code',
                    model: 'disability_type_id',
                    url: 'filters.php?action=get&filter=disability',
                    itemText: "description",
                    itemValue: "code",
                    rootProperty: 'disabilities'
                }, {
                    label: 'Gender',
                    model: 'gender',
                    itemText: 'gender',
                    itemValue: 'gender',
                    url: 'filters.php?action=get&filter=gender',
                    rootProperty: 'genders'
                },

                {
                    label: 'Intervention Level',
                    itemText: 'name',
                    itemValue: 'id',
                    model: 'intervention_level',
                    url: 'interventionLevels.php?action=get&active_only=1&active_only=1',
                    rootProperty: 'intervention_levels'
                }, {
                    label: 'Intervention Exit Status',
                    itemText: 'name',
                    itemValue: 'id',
                    model: 'intervention_exit_status',
                    url: 'crud.php?property=InterventionExitStatus&action=get',
                    rootProperty: 'intervention_exit_status'
                },

                {
                    label: 'Meal Status',
                    itemText: 'name',
                    itemValue: 'id',
                    model: 'meal_status_id',
                    items: [
                        {
                            id: -1,
                            name: 'Any Status'
                        }, {
                            id: 0,
                            name: 'No Status'
                        }
                    ],
                    rootProperty: 'meal_status'
                }, {
                    label: 'EL Status',
                    component: 'fe-remote-combo',
                    itemText: 'name',
                    itemValue: 'id',
                    model: 'ell_flag',
                    items: [{
                        id: '0',
                        name: 'No'
                    }, {
                        id: '1',
                        name: 'Yes'
                    }]
                }, {
                    label: 'ELP Level',
                    itemText: 'name',
                    itemValue: 'id',
                    model: 'ell_level_id',
                    items: [],
                    canExclude: false,
                },

                {
                    label: 'Student Active Status',
                    items: [
                        { id: '0', name: 'Inactive' },
                        { id: '1', name: 'Active' },
                        { id: null, name: 'Both' },
                    ],
                    canExclude: false,
                    model: 'student_active_flag',
                }, {
                    label: 'Student Active Date',
                    component: 'fe-date-picker',
                    componentLabel: 'As of Date',
                    model: 'student_active_date',
                },

                ],
                filter: {
                    tag_id: null,
                    ethnicity: null,
                    disability_type_id: null,
                    gender: null,
                    intervention_level: null,
                    intervention_exit_status: null,
                    meal_status_id: null,
                    ell_flag: null,
                    ell_level_id: null,
                    sub_category_id: null,
                    incl_hidden_data_point: false,
                    group_by: {
                        school: true,
                        grade: false,
                        ethnicity: true,
                        gender: true,
                        schoolgroup: false
                    },
                    selected: {},
                    demographics: {},
                    student_active_flag: 1,
                    student_active_date: null,
                },
                selections: [],
                selectionsFilterText: '',
            }
        },
        mounted() {
            this.$store.dispatch('global/loadDemographics')
        },
        methods: {
            postReadMealStatus(data) {
                if (data) {
                    this.fields.forEach(x => {
                        if (x.model === 'meal_status_id') {
                            x.items = x.items.concat(data)
                        }
                    })
                }
            },

            postReadElpLevel(data) {
                if (data) {
                    this.fields.forEach(x => {
                        if (x.model === 'ell_level_id') {
                            x.items = x.items.concat(data)
                        }
                    })
                }
            },
            updateAdvancedDemographic(demographic, val) {
                let k = `demo_${demographic.id}`
                let obj = { ...this.filter.demographics }

                if (val === null) {
                    delete obj[k]
                } else {
                    obj[k] = val.encoded
                }

                this.filter.demographics = { ...obj }
            },
            onChangeSelection(label, items) {
                let field = this.fieldItems.find(itm => itm.label == label)
                let match = this.selections.find(itm => itm.label == label)

                if (match) {
                    match.items = items

                    // Don't show sections once all items are removed
                    if (items.length === 0) {
                        this.selections = this.selections.filter(itm => itm.label != label)
                    }
                } else if (items?.length) {
                    this.selections.push({ label, items, field })
                }
            },
            removeSelection(label, item) {
                let field = this.fieldItems.find(itm => itm.label == label)
                if (field) {
                    this.filter[field.model] = this.filter[field.model]
                        .split(',')
                        .filter(itm => (itm.substr(0, 1) == '!' ? itm.substr(1) : itm) != item[field.itemValue])
                        .join(',')

                    let match = this.selections.find(itm => itm.label == label)
                    if (match) {
                        match.items = match.items.filter(itm => itm[field.itemValue] != item[field.itemValue])
                    }
                }
            },
        },
        computed: {
            ...mapState('global', ['currentYear']),
            fieldItems() {
                let items = [
                    ...this.fields.map(itm => ({
                        ...itm,
                        id: itm.label,
                        name: itm.label,
                    })),
                    ...this.activeDemographics.map(itm => ({
                        ...itm,
                        demographic: itm,
                        label: `Custom Demo ${itm.id} - ${itm.name} (?)`,
                        component: 'advanced-demographic',
                        model: 'demographics',
                    })),
                ]

                // Force defaults within the field defs so we can refer
                // to them within markup code without worrying about bad refs
                return items.map(itm => ({
                    ...itm,
                    itemText: itm.itemText || 'name',
                    itemValue: itm.itemValue || 'id',
                }))
            },
            demographics () { return this.$store.getters['global/demographicsSearchable'] },
            activeDemographics () { return this.demographics.filter(dmo => dmo.active) },
        }
    }
</script>

<style lang="scss" scoped>
.selected-item {
    background: #f5f6f8;
    padding: 4px 8px 4px 8px;
    margin: 4px 0px 4px 0px;
    &-name {
        overflow: hidden;
        white-space: nowrap;
        margin-right: 8px;
    }
}

.excluded-item {
    background: #F4E6E5;
}

.advanced-options {
    margin-top: 4px;
    font-size: 10px;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}
</style>