<template>
    <v-layout column v-show="ready">
        <div class="headline">Attendance</div>

        <div class="caption mb-2">
            Attendance data comes from your student information system but can be configured here after it is imported.
        </div>

        <v-simple-table class="mb-3">
            <template v-slot:default>
                <thead style="visibility: hidden;">
                <tr>
                    <th style="height: 0; width: 135px;"></th>
                    <th style="height: 0;"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in toggles" :key="item.id">
                    <td class="px-0" style="border: none; width: 150px;">
                        <span style="font-size: 14px;">{{item.name}}</span>
                        <fe-info-tip :tooltip="item.tooltip" style="display: inline-block;" class="ml-2"/>
                    </td>
                    <td class="px-0" style="border: none;">
                        <fe-switch
                            class="mt-2"
                            :value="prefs[item.id]"
                            @input="toggle(item, $event)"
                        />
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>

        <fe-action-list
            @rowClick="go"
            :items="$parent.items.slice(2)"
            itemText="display"
            :showSearch="false"
            :addButton="false"
            :editButton="false"
            :showFiltering="false"
            class="attendance-pages"
        />
    </v-layout>
</template>

<script>
    export default {
        name: "LandingPage",

        props: {
            prefs: {
                default: () => {}
            }
        },

        data() {
            return {
                ready: false,
                toggles: [
                    {
                        name: 'Full Day Rate',
                        id: 'ATTENDANCE_AFFECTED_RATE',
                        tooltip: 'Rate at which student is in school for the entire day with no absence/tardy events'
                    },
                    {
                        name: 'SIS Reported Rate',
                        id: 'ATTENDANCE_EQUIV_RATE',
                        tooltip: 'Rate calculated based on number of Absent Days as calculated by the SIS'
                    },
                    {
                        name: 'Period Rate',
                        id: 'ATTENDANCE_PERIOD_RATE',
                        tooltip: 'Rate calculated based on periods missed vs total periods as set by the district for each school'
                    }
                ]
            }
        },

        methods: {
            go(page) {
                this.$emit('go', page)
            },

            toggle(x, y) {
                let data = {}
                data[x.id] = y ? "1" : "0"

                this.$emit('pref-update', data)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .caption {
        color: lightgray;
    }

    ::v-deep .toggle-table {
        .v-datatable__progress {
            display: none;
        }
    }

    ::v-deep .toggle-table {
        tr, td, tbody, table {
            border: none !important;
        }
    }

    ::v-deep .toggle-table {
        tr:hover {
            background: none !important;
        }
    }

    ::v-deep .v-input--switch {
        .v-messages {
            display: none;
        }
    }

    ::v-deep .attendance-pages .layout.mb-2.row {
        display: none
    }
</style>
