export default {
    namespaced: true,

    state: {
        ellStatus: [{
            id: '0',
            name: 'No'
        }, {
            id: '1',
            name: 'Yes'
        }]
    },

    mutations: {
        set(state, payload) {
            state[payload.state] = payload.value
        }
    },

    actions: {
        async load({state}) {
            return state.ellStatus
        },

        refresh({dispatch}) {
            dispatch('load')
        }
    },

    getters: {
        all: state => state.ellStatus,

        collection: state => state.ellStatus,

        success: () => true,

        clear: () => false
    }
}
