export default {
    alphaMap: {
        rules: [
            r => (!isNaN(parseFloat(r.numeric_score)) && isFinite(r.numeric_score)) || 'Numeric Score must be a valid number.',
            r => (r.alpha_score && r.alpha_score.trim().length > 0) || 'Display Text is required.',
            r => (r.data_point_type_id && r.data_point_type_id > 0) || 'Assessment Type is required.',
        ],
        defaults: {
            endpoint: 'alphaMap.php',
            rootProperty: 'alpha_maps'
        },
        read: {
            params: { action: 'get', property: 'with_user_name' }
        },
        create: {
            params: { action: 'create' }
        },
        update: {
            params: { action: 'update' }
        },
        destroy: {
            params: { action: 'delete' }
        }
    }
}