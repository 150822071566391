<template>
    <v-dialog :value="value" @input="$emit('input',false)" max-width="400" persistent>
        <v-card>
            <v-card-title class="headline">Copy Incident Fields</v-card-title>
            <v-card-text>
                <b>Use this student's entered data to autofill empty fields for all other students in this incident.</b>
            </v-card-text>
            <v-card-text>
                <fe-checkbox v-model="overwrite" 
                    label="Check this box to use the data entered for this student to overwrite existing fields for all other students in this incident.
                     Every student in this incident will have the same data as this student." 
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                    <fe-btn text @click="$emit('input',false)">Cancel</fe-btn>
                    <fe-btn light depressed color="primary" @click="emitSelection">Continue</fe-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'CopyDialog',
    props: {
        value: { type: Boolean, default: false }
    },
    data() {
        return {
            overwrite: false
        }
    },
    watch: {
        value(v) {
            if(v) this.overwrite = false
        }
    },
    methods: {
        emitSelection() {
            this.$emit('copy', this.overwrite)
            this.$emit('input',false)
        }
    }
}
</script>
