import { render, staticRenderFns } from "./AddTemplate.vue?vue&type=template&id=8023fdfc&scoped=true"
import script from "./AddTemplate.vue?vue&type=script&lang=js"
export * from "./AddTemplate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8023fdfc",
  null
  
)

export default component.exports