<template>
    <div class="flex-fill">
        <v-overlay :value="loading" absolute>
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </v-overlay>

        <fe-grid
            ref="grid"
            displayDensity="small"
            :rowData="filteredForms"
            :columnDefs="columnDefs"
            :bordered="false"
            :showAddRowBtn="false"
            disableInlineCreate
            style="height:100%; width:100%;"
            exportFilename="Created Forms List"
            :csvExportProcessCellCallback="csvExportProcessCellCallback"
            @rowDoubleClicked="openForm"
            @selectionChanged="onSelection"
        >
            <template #toolbar-items>
                <v-slide-y-transition>
                    <div v-if="selection.length > 0" class="selection-toolbar d-flex">
                        <div class="mt-3 ml-5">{{selection.length}} Form{{ selection.length > 1 ? 's' : '' }} Selected</div>

                        <div class="ml-auto">
                            <v-btn text class="white--text" @click="unSelect(downloadFormZip(selection))">Download Zip File</v-btn>
                            <v-btn text class="white--text" @click="$refs.grid.gridApi.deselectAll()">Cancel</v-btn>
                        </div>
                    </div>
                </v-slide-y-transition>
            </template>
        </fe-grid>

    </div>
</template>

<script>
import SmartFormMixin from '../smartFormMixin'
import { mapState } from 'vuex'

export default {
    name: 'FormInstanceList',
    mixins: [ SmartFormMixin ],

    props: {
        templateId: {required: true},
        filters: {},
    },

    computed: {
        ...mapState('global', ['sessionUser', 'userPreferences']),
        canViewAllForms() {
            return this.sessionUser.security_codes.indexOf('VIEW_ALL_FORMS') !== -1
        },
        filteredForms() {
            return this.forms
        },
        studentField() { 
            return this.$isTopSecretModeActive(this.userSession, this.userPreferences) ? 'student_id' : 'student'
        },
        columnDefs() {
            return [this.$grid.checkColumn(), {
                headerName: 'Form Name',
                field: 'form_instance_name',
            }, {
                headerName: 'School',
                field: 'school',
            }, {
                headerName: 'Student',
                field: this.studentField,
                cellRenderer: v => v.value
            }, {
                headerName: 'School Year',
                field: 'school_year_name',
            }, {
                headerName: 'Last Updated',
                field: 'form_instance_last_updated',
            }, {
                headerName: 'Created',
                field: 'created',
            }, {
                headerName: 'Owner',
                field: 'created_by_full_name',
            // }, {
            //     headerName: 'Tools',
            }]
        }
    },

    watch: {
        filters: {
            immediate: true,
            handler(v) {
                // Need to check logged in user permission to determine filter availability
                // and which forms to list (all or only those owned by logged in user)
                let usersToInclude = null

                if (this.$pluckJoin(v.user_id?.included,'id') === undefined) {
                    if (this.canViewAllForms) {
                        // logged in user can view all forms and has not selected any users to filter on
                        // pass a null value so the BE will return all forms
                        usersToInclude = null
                    } else {
                        // logged in user cannot view all forms and will not be able to filter on users
                        // pass their id so we get only their forms
                        usersToInclude = this.sessionUser.user.id
                    }
                } else {
                    // logged in user has selected a user (or users) to filter on
                    usersToInclude = this.$pluckJoin(v.user_id?.included,'id')
                }

                this.loadData({
                    ...v,
                    form_id: this.templateId,
                    include_school: 1,
                    user_id: usersToInclude,
                    data_id: this.$pluckJoin(v.data_id?.included,'id'),
                    school_year_id: Array.isArray(v.school_year_id) ? v.school_year_id.join(',') : v.school_year_id,
                })
            }
        },
    },
    methods: {
        loadData(params) {
            this.forms = []

            this.loading = true
            this.$axios({
                url: 'formResponse.php?action=get&property=list',
                params: params,
            }).then(r => {
                this.forms = r.data.form_instances
                this.loading = false
            }).catch(r => {
                this.forms = []
                this.loading = false
            })
        },
        openForm(e) {
            this.$dockableWindow({
                name: e.data.form_instance_name,
                data: e.data,
                component: 'smart-form',
                attrs: {
                    id: e.data.form_instance_id,
                    template_id: e.data.form_id,
                },
            })
        },
        onSelection() {
            this.selection = this.$refs.grid.gridApi.getSelectedRows()
        },
        unSelect(keep) {
            this.$refs.grid.gridApi.getSelectedNodes().map(n => {
                if(!keep.find(rec => rec == n.data)) n.setSelected(false)
            })
        },
        csvExportProcessCellCallback(params) {
            if (params.value === undefined) {
                return ''
            } else {
                // Cast value as a string to make sure .replace never bombs out
                return `${params.value}`.replace(/<[^>]*>/g, '')
            }
        },
    },
    data() {
        return {
            loading: false,
            forms: [],
            selection: [],
        }
    }
}
</script>

<style lang="scss" scoped>
.selection-toolbar {
    width: 100%;
    position: absolute;
    left: 0px;
    height: 48px;
    background: #66A7BC;
    color: white;
    z-index: 10;
    border-radius: 4px;
}
</style>
