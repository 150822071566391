<template>
    <fe-dialog
        :width="width"
        :title="preTitle ? (updating ? `Edit ${title}` : `Create ${title}`) : title"
        :acceptButtonText="updating ? 'Save' : 'Create'"
        :acceptButtonDisabled="acceptButtonDisabled"
        :forceClose="false"
        dismissButtonText="Cancel"
        disableAutoclose
        persistent
        @accept="accept"
        @dismiss="$emit('close', rec)"
    >
        <v-form ref="form" @submit.prevent v-model="isValid">
            <slot name="form" :rec="rec"></slot>
        </v-form>

    </fe-dialog>
</template>

<script>
export default {
    name: 'RecordDialog',

    props: {
        title: { type: String, default:'Record' },
        preTitle: { type: Boolean, default:true },
        value: { type: Object, default:null },
        width: { type: String, default:'350px' },
        verifyDirty: { type: Boolean, default: true },
        verifyValid: { type: Boolean, default: false },
        defaultRec: {},
        emitCloseOnAccept: false,
    },

    created() {
        if(this.value) {
            this.updating = true
        }
        this.rec = this.value ? {...this.value} : {...this.defaultRec}
        this.originalRec = JSON.stringify(this.rec)
    },
    computed: {
        acceptButtonDisabled() {
            let disabled = false

            if (this.verifyDirty && !this.isDirty) disabled = true
            if (this.verifyValid && !this.isValid) disabled = true

            return disabled
        }
    },
    watch: {
        rec: {
            deep: true,
            handler(v) {
                this.isDirty = this.originalRec != JSON.stringify(this.rec)
            }
        }
    },
    methods: {
        accept() {
            if(this.$refs.form.validate()) {
                this.$emit(this.updating ? 'update' : 'create', this.rec)
                this.$emit('input', this.rec)
                if(this.emitCloseOnAccept) this.$emit('close')
            }
        }
    },

    data() {
        return {
            originalRec: null,
            isValid: true,
            isDirty: false,
            updating: false,
            rec: null,
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
