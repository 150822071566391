<template>
    <v-layout column>
        <div class="headline mb-2 d-flex">
            Courses
            <fe-info-tip
                class="ml-2"
                tooltip="Courses"
            />
        </div>
        <fe-crud-grid
            ref="grid"
            :config="mainCrudConfig"
            :columnDefs="courseColumnDefs"
            :autoload="false"
            :searchBar="false"
            :showFullscreen="false"
            :showDownload="false"
            :showToolbarMenu="false"
            :emptyState="emptyState"
            identifier="course_id"
            addRowBtnText="Create Course"
            displayDensity="medium"
            disableInlineCreate
            @beginCreate="beginEntry"
        >
            <template slot="selection-tools">
                <fe-btn
                    v-if="$route.name != 'Manage'"
                    usage="ghost"
                    whiteText
                    @click="saveSelected()"
                    class="fe-grid-action-btn"
                >
                    Save
                </fe-btn>
                <fe-btn
                    v-if="$route.name == 'Manage'"
                    useIcon="fal fa-trash"
                    usage="ghost"
                    whiteText
                    @click="deleteRows"
                    class="fe-grid-action-btn"
                >
                    Delete
                </fe-btn>
                <fe-btn
                    useIcon="fal fa-arrow-alt-to-bottom"
                    usage="ghost"
                    whiteText
                    @click="exportGrid('selectedRows')"
                    class="fe-grid-action-btn"
                >
                    Download
                </fe-btn>
                <fe-btn
                    usage="ghost"
                    whiteText
                    @click="editCancel"
                    class="fe-grid-action-btn-last"
                >
                    Cancel
                </fe-btn>
            </template>
            <template slot="left-toolbar-items">
                <fe-filter-btn
                    ref="filterYear"
                    title="Year"
                    v-model="yearFilter"
                    :multiple="false"
                    :items="years"
                    itemValue="id"
                    itemText="name"
                    closeOnSelect
                    @input="load"
                />
                <fe-filter-btn
                    ref="filterUser"
                    title="User Name"
                    v-model="userFilter"
                    :multiple="false"
                    :disabled="!filters.school_year.included"
                    :url="userUrl"
                    rootProperty="courses"
                    itemText="user_full_name"
                    itemValue="user_id"
                    closeOnSelect
                    @input="load"
                />
                <fe-filter-btn
                    ref="filterCourseName"
                    title="Course Name"
                    v-model="courseFilter"
                    :multiple="false"
                    :disabled="!filters.user.included"
                    :items="availableCourses"
                    rootProperty="courses"
                    itemText="course_name"
                    itemValue="course_id"
                    closeOnSelect
                    @input="load"
                />
                <fe-filter-btn
                    ref="filterPeriod"
                    title="Period"
                    v-model="periodFilter"
                    :multiple="false"
                    :disabled="!filters.course.included"
                    :url="periodUrl"
                    rootProperty="courses"
                    itemText="period"
                    itemValue="period"
                    closeOnSelect
                    @input="load"
                />
                <v-btn ref="clear" light depressed color="white" @click="clearFilters">Clear</v-btn>
            </template>

            <template v-slot:cellDialog="{cell}">
                <simple-grid-dialog-column
                    ref="dialog"
                    :cell="cell"
                    title="Students"
                    :crudConfig="studentCrudConfig"
                    :columnDefs="studentColumnDefs"
                    :readParams="{
                        user_id: cell.data.user_id,
                        school_year_id: cell.data.school_year_id,
                        course_id: cell.data.course_id,
                        period: cell.data.period
                    }"
                    :destroyParams="{
                        property: 'students',
                        user_id: cell.data.user_id,
                        school_year_id: cell.data.school_year_id,
                        course_id: cell.data.course_id
                    }"
                    :updateParams="{
                        property: 'courses',
                        courses: {
                            students: cell.data
                        }
                    }"
                    :insertDefaults="{
                        user_id: cell.data.user_id,
                        course_id: cell.data.course_id,
                        school_year_id: cell.data.school_year_id,
                        period: cell.data.period
                    }"
                    :buildInsertRec="buildStudentRec"
                    width="600px"
                    @destroyed="load"
                    @created="load"
                    @updated="load"
                    @close="closeSimpleGrid"
                >
                    <template slot="belowGrid">
                        <div class="d-flex" v-if="cell">
                            <fe-remote-combo
                                style="margin-top:16px; width:100%;"
                                ref="simpleInsertField"
                                :items="availableStudents"
                                itemText="student_full_name"
                                itemValue="student_id"
                                useSearchText
                                placeholder="Add a Student"
                                :filter="x => {
                                    if(cell.data.students.map(y=>y.student_id).includes(x.student_id)) x.disabled=true
                                    return true
                                }"
                                @search="getAvailableStudents"
                                @input="studentSelection"
                                @set="studentSelection"
                            />
                            <fe-btn
                                style="margin-top: 10px;"
                                :disabled="disableStudentAdd"
                                @click="studentAdd"
                            >
                                Add
                            </fe-btn>
                        </div>
                    </template>
                </simple-grid-dialog-column>
            </template>
        </fe-crud-grid>

        <fe-dialog
            v-if="inserting"
            title="Create Course"
            :footer="false"
            width="600"
            persistent
            disableAutoclose
            @close="dismissDialog"
            @accept="createNew"
        >
            <v-layout column>
                <v-flex xs12>
                    <v-form ref="form" @submit.prevent v-model="formValid">
                        <fe-remote-combo
                            label="Select School Year"
                            v-model="newEntry.school_year"
                            itemValue="id"
                            itemText="name"
                            :items="years"
                            :rules="requiredRule"
                            validateOnBlur
                        />

                        <fe-remote-combo
                            label="Select or Create Course Name"
                            v-model="newEntry.course"
                            itemValue="course_id"
                            itemText="course_name"
                            :items="courses"
                            :rules="requiredRule"
                            validateOnBlur
                            useSearchText
                            @set="setCourseName"
                        />

                        <fe-remote-combo
                            label="Select or Create Period/Group"
                            v-model="newEntry.period"
                            itemValue="period"
                            itemText="period"
                            :items="periods"
                            :rules="requiredRule"
                            validateOnBlur
                            useSearchText
                            @set="setPeriodName"
                        />

                        <div v-if="viewAllCourses">
                            <div class="d-flex">
                                <fe-remote-combo
                                    ref="usersSearchField"
                                    label="Select User Name(s)"
                                    placeholder="Search Users by Name"
                                    class="chips-below flex-grow-1"
                                    :class="{invalid: newEntry.invalid.users}"
                                    itemText="user_full_name"
                                    itemValue="id"
                                    :items="users"
                                    validateOnBlur
                                    @input="select($event, 'users')"
                                />
                                <fe-btn
                                    usage="ghost"
                                    class="mt-5"
                                    @click="userAdvanced = true"
                                >
                                    Advanced
                                </fe-btn>
                            </div>
                            <div v-if="newEntry.users.length" class="chip-wrapper d-flex">
                                <v-flex xs11>
                                    <v-chip
                                        v-for="user in newEntry.users"
                                        class="chip"
                                        :class="{avatar: user.photo_url}"
                                        close
                                        @click:close="deselect(user, 'users')"
                                    >
                                        <v-avatar v-if="user.photo_url">
                                            <img :src="user.photo_url" alt="">
                                        </v-avatar>
                                        {{user.user_full_name }}
                                    </v-chip>
                                </v-flex>

                                <v-flex xs1>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon v-on="on" @click="clearAll('users')">
                                                <v-icon color="#0000008a" small>fal fa-times</v-icon>
                                            </v-btn>
                                        </template>

                                        <span>Clear All</span>
                                    </v-tooltip>
                                </v-flex>
                            </div>
                            <div v-else-if="newEntry.invalid.users" style="margin-top: -10px;">
                                <span class="error-msg">This field is required</span>
                            </div>
                        </div>

                        <div class="d-flex">
                            <fe-remote-combo
                                ref="studentsSearchField"
                                label="Select Student Name(s)"
                                placeholder="Search Students by Name"
                                class="chips-below flex-grow-1"
                                :class="{invalid: newEntry.invalid.students}"
                                itemText="student_full_name"
                                itemValue="student_id"
                                :items="students"
                                hideDetails
                                validateOnBlur
                                @input="select($event, 'students')"
                            />
                            <fe-btn usage="ghost" class="mt-5" @click="studentAdvanced = true">Advanced</fe-btn>
                        </div>
                        <div v-if="newEntry.students.length" class="chip-wrapper d-flex">
                            <v-flex xs11>
                                <v-chip
                                    v-for="student in newEntry.students"
                                    class="chip"
                                    close
                                    @click:close="deselect(student, 'students')"
                                >
                                    {{getStudent(student)}}
                                </v-chip>
                            </v-flex>

                            <v-flex xs1>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="clearAll('students')">
                                            <v-icon color="#0000008a" small>fal fa-times</v-icon>
                                        </v-btn>
                                    </template>

                                    <span>Clear All</span>
                                </v-tooltip>
                            </v-flex>
                        </div>
                        <div v-else-if="newEntry.invalid.students" style="margin-top: -10px;">
                            <span class="error-msg">This field is required</span>
                        </div>
                    </v-form>

                    <div class="d-flex">
                        <div>
                            <v-progress-circular v-if="loading" color="primary" indeterminate/>
                        </div>
                        <fe-btn usage="ghost" class="ml-auto" @click="dismissDialog">Cancel</fe-btn>
                        <fe-btn usage="primary" @click="createNew" :disabled="!valid">Save</fe-btn>
                    </div>
                </v-flex>
            </v-layout>
        </fe-dialog>

        <fe-dialog
            v-if="studentAdvanced"
            title="Find Students"
            width="800"
            acceptButtonText="Select"
            dismissButtonText="Cancel"
            dismissable
            persistent
            @close="closeStudentAdvanced"
            @accept="acceptStudentAdvanced"
        >
            <student-search v-model="studentsFromAdvanced" :searchYear="newEntry.school_year" keepSelection/>
        </fe-dialog>

        <fe-dialog
            v-if="userAdvanced"
            title="Find Users"
            width="800"
            acceptButtonText="Select"
            dismissButtonText="Cancel"
            dismissable
            persistent
            @close="closeUserAdvanced"
            @accept="acceptUserAdvanced"
        >
            <user-search v-model="usersFromAdvanced" :searchYear="newEntry.school_year" keepSelection/>
        </fe-dialog>

        <fe-crud ref="yearsCrud" :config="$models.schoolYear" autoload @read="setProperty('years', $event)"/>
        <fe-crud ref="usersCrud" :config="$models.user" @read="setProperty('users', $event)"/>
        <fe-crud ref="studentsCrud" :config="$models.studentGradeHistory" @read="setProperty('students', $event)"/>
        <fe-crud ref="periodsCrud" :config="periodCrudConfig" @read="setProperty('periods', $event)"/>
        <fe-crud
            ref="coursesCrud"
            :config="courseCrudConfig"
            :failOnEmptyResponse="false"
            @read="setProperty('courses', $event)"
        />
    </v-layout>
</template>

<script>
    import {mapState} from "vuex"
    import SimpleGridDialogColumn from "../../../common/SimpleGridDialogColumn"
    import StudentSearch from '@/components/common/StudentSearch'
    import UserSearch from '@/components/common/UserSearch'

    export default {
        name: "Courses",

        components: {SimpleGridDialogColumn, StudentSearch, UserSearch},

        props: {
            selectedItems: {
                type: Array,
                default: () => []
            }
        },

        data() {
            return {
                years: [],
                users: [],
                courses: [],
                periods: [],
                students: [],
                availableStudents: [],
                availableCourses: [],
                formValid: false,
                loading: false,
                filters: {
                    school_year: {},
                    user: {},
                    course: {},
                    period: {}
                },
                cell: undefined,
                studentColumnDefs: [
                    {
                        headerName: 'Student Name',
                        field: 'student_full_name',
                        colId: 'student_full_name',
                        width: 200,
                    }, {
                        headerName: 'Active',
                        field: 'active',
                        colId: 'active',
                        valueGetter: v => !!v.data.active,
                        cellRendererFramework: "FeGridToggle",
                        width: 100,
                        onCellValueChanged: this.studentUpdate,
                        editable: false
                    }, {
                        headerName: 'Inactive Date',
                        field: 'inactive_date',
                        colId: 'inactive_date',
                        width: 150,
                        editable: false
                    }
                ],
                disableStudentAdd: true,
                studentAdvanced: false,
                studentsFromAdvanced: [],
                viewAllCourses: false,
                userAdvanced: false,
                usersFromAdvanced: [],
                inserting: false,
                newEntry: {
                    school_year: '',
                    course: '',
                    period: '',
                    students: [],
                    users: [],
                    invalid: {
                        users: false,
                        students: false
                    }
                },
                emptyState: {
                    imageSrc: '/images/rocket.png',
                    text: 'To get started, begin by selecting the school year and User Name.<br>Narrow your search by selecting Course Name or Period'
                },
                requiredRule: [v => !_.isEmpty(v) || 'This field is required']
            }
        },

        computed: {
            ...mapState('global', ['currentYear', 'sessionUser']),

            courseColumnDefs() {
                return !this.courses ? [] : [{
                    headerName: null,
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    maxWidth: 70,
                    colId: 'checkbox_column',
                }, {
                    headerName: 'Course Name',
                    field: 'course_name',
                    editable: false
                }, {
                    headerName: 'Period',
                    field: 'period',
                    maxWidth: 200,
                    editable: false
                }, {
                    headerName: 'Students',
                    valueGetter: v => {
                        return v.data.students ? v.data.students.length : null
                    },
                    cellRendererFramework: "FeDialogColumn",
                    onCellClicked: v => {
                        this.cellClicked(v)
                    },
                    maxWidth: 100,
                    editable: false
                }, {
                    headerName: 'Date Created',
                    field: 'created',
                    valueGetter: v => new Date(v.data.created).toLocaleDateString(),
                    maxWidth: 150,
                    editable: false
                }]
            },

            mainCrudConfig() {
                let cfg = this.$_.cloneDeep(this.$models.courseHistory)
                cfg.read.params.property = 'courses'
                cfg.destroy.params.property = 'courses'
                cfg.update.params.property = 'courses'
                cfg.create.params.property = 'courses'
                cfg.create.params.noReturnRecords = true
                return cfg
            },

            studentCrudConfig() {
                let cfg = this.$_.cloneDeep(this.$models.courseHistory)
                cfg.read.params.property = 'students'
                cfg.update.params.property = 'courses'
                cfg.create.params.noReturnRecords = true
                return cfg
            },

            courseCrudConfig() {
                let cfg = this.$_.cloneDeep(this.$models.courseHistory)
                cfg.read.params.property = 'course_id'
                let filterIds = this.newEntry
                if (filterIds.school_year) {
                    cfg.read.params.school_year_id = filterIds.school_year.id
                }
                return cfg
            },

            periodCrudConfig() {
                let cfg = this.$_.cloneDeep(this.$models.courseHistory)
                cfg.read.params.property = 'period'
                let filterIds = this.newEntry
                if (filterIds.school_year) {
                    cfg.read.params.school_year_id = filterIds.school_year.id
                }
                if (filterIds.course) {
                    cfg.read.params.course = filterIds.course.course_id
                }
                return cfg
            },

            yearFilter: {
                set(v) {
                    this.filters.school_year = v
                    this.$refs.usersCrud.read()
                },
                get() {
                    return this.filters.school_year
                }
            },

            userFilter: {
                set(v) {
                    this.filters.user = v
                },
                get() {
                    return this.filters.user
                }
            },

            userUrl() {
                return 'courseHistory.php?action=get&property=user_id&all=1&school_year_id=' + this.filters.school_year.included[0].id
            },

            courseFilter: {
                set(v) {
                    this.filters.course = v
                },
                get() {
                    return this.filters.course
                }
            },

            courseUrl() {
                let filterIds = this.filterIds
                let url = 'courseHistory.php?action=get&property=course_id&all=1&school_year_id=' + filterIds.school_year_id

                if (filterIds.user_id) {
                    url += '&user_id=' + filterIds.user_id
                }

                return url
            },

            periodFilter: {
                set(v) {
                    this.filters.period = v
                },
                get() {
                    return this.filters.period
                }
            },

            periodUrl() {
                let filterIds = this.filterIds
                let url = 'courseHistory.php?action=get&property=period&all=1&school_year_id=' + filterIds.school_year_id

                url += '&course_id=' + filterIds.course_id

                if (filterIds.user_id) {
                    url += '&user_id=' + filterIds.user_id
                }

                return url
            },

            filterIds() {
                let filters = this.filters
                let output = {}

                if (filters.school_year.included && filters.school_year.included.length) {
                    output.school_year_id = filters.school_year.included[0].id
                }

                if (filters.user.included && filters.user.included.length) {
                    output.user_id = filters.user.included[0].user_id
                }

                if (filters.course.included && filters.course.included.length) {
                    output.course_id = filters.course.included[0].course_id
                }

                if (filters.period.included && filters.period.included.length) {
                    output.period = filters.period.included[0].period
                }

                return output
            },

            valid() {
                return this.formValid && this.newEntry.students.length && (this.newEntry.users.length || !this.viewAllCourses)
            }
        },

        watch: {
            'newEntry.school_year': {
                handler() {
                    let params = {
                        school_year_id: this.newEntry.school_year.id || this.currentYear
                    }
                    this.$refs.usersCrud.read(params)
                    this.$refs.studentsCrud.read(params)
                    this.$refs.coursesCrud.read()
                }
            },
            'newEntry.course': {
                handler() {
                    this.$refs.periodsCrud.read()
                }
            },
            'filterIds.user_id': {
                handler() {
                    let url = this.courseUrl
                    this.$axios.get(url).then(res => {
                        let courses = this.$_.sortBy(res.data.courses, 'course_name')
                        this.availableCourses = courses
                    })
                }
            }
        },

        created() {
            this.filters.school_year.included = [this.currentYear]

            this.viewAllCourses = this.$hasSecurity('VIEW_ALL_COURSES')

            this.$parent.$once('show courses', () => {
                this.$nextTick(() => {
                    this.$refs.grid.$refs.grid.resize()
                })
            })
        },

        mounted() {
            this.$refs.grid.$refs.grid.gridOptions.deltaRowDataMode = false
            this.$refs.grid.$refs.grid.gridOptions.getRowNodeId = v => {
                return v.course_id + '_' + v.period + '_' + v.active
            }
        },

        methods: {
            setProperty(property, v) {
                this[property] = v
            },

            clearFilters() {
                this.$refs.filterUser.clear()
                this.$refs.filterCourseName.clear()
                this.$refs.filterPeriod.clear()

                this.filters = {
                    school_year: {
                        included: [this.currentYear]
                    },
                    user: {},
                    course: {},
                    period: {}
                }

                this.$nextTick(() => {
                    this.$refs.grid.clear()
                    this.$refs.filterYear.selectItem(this.currentYear, true)
                })
            },

            load() {
                let filterIds = this.filterIds
                if (filterIds.user_id) {
                    return this.$refs.grid.$refs.crud.read(filterIds)
                } else {
                    this.$refs.grid.clear()
                }
            },

            cellClicked(cell) {
                this.disableStudentAdd = true
                this.$refs.grid.$refs.grid.setDialogCell(undefined)
                this.$nextTick(() => {
                    this.cell = cell
                    this.$refs.grid.$refs.grid.setDialogCell(cell)
                })
            },

            deselect(item, property) {
                let key = property === 'students' ? 'student_id' : 'id'
                this.newEntry[property] = this.$_.differenceBy(this.newEntry[property], [item], key)
            },

            select(item, property) {
                if (item) {
                    let key = property === 'students' ? 'student_id' : 'id'
                    let specifiedArray = this.newEntry[property]
                    specifiedArray.push(item)
                    specifiedArray = this.$_.uniqBy(specifiedArray, key)
                    this.$refs[property + 'SearchField'].updateValue()
                    this.newEntry.invalid[property] = false
                }
            },

            clearAll(property) {
                this.newEntry[property] = []
                this.newEntry.invalid[property] = true
            },

            setCourseName(v) {
                if(v) {
                    if (typeof v === 'string') {
                        // course_id may be unused in the logic, but it is required
                        // for combo-search fe-remote-combo to behave correctly
                        this.newEntry.course = { course_id: v, course_name: v }
                    } else {
                        this.newEntry.course = v
                    }
                }
            },

            setPeriodName(v) {
                if(v) {
                    if (typeof v === 'string') {
                        this.newEntry.period = {period: v}
                    } else {
                        this.newEntry.period = v
                    }
                }
            },

            beginEntry() {
                this.resetEntry()
                // if they exist, pre-populate selected students from data wall
                let selectedStudents = this.selectedItems
                selectedStudents.forEach((v) => {
                    this.newEntry.students.push(v.data)
                })

                this.inserting = true
            },

            dismissDialog() {
                this.inserting = false
            },

            resetEntry() {
                this.formValid = false
                this.newEntry = {
                    school_year: '',
                    course: '',
                    period: '',
                    students: [],
                    users: [],
                    invalid: {
                        users: false,
                        students: false
                    }
                }
            },

            createNew() {
                this.loading = true
                let entry = this.newEntry
                let template = {
                    school_year_id: entry.school_year.id,
                    period: entry.period.period
                }

                if (entry.course.course_id && !entry.course.course_name) {
                    template.course_id = entry.course.course_id
                } else {
                    template.course_name = entry.course.course_name
                }

                if (!this.viewAllCourses) {
                    entry.users = [{user_id: this.sessionUser.user.id}]
                }

                let payload = []
                entry.students.forEach(student => {
                    entry.users.forEach(user => {
                        let clone = this.$_.clone(template)
                        clone.user_id = user.id
                        clone.student_id = student.student_id
                        payload.push(clone)
                    })
                })

                this.$refs.grid.$refs.crud
                    .create(payload)
                    .then(() => {
                        this.loading = false
                        this.load()
                        this.dismissDialog()
                    })
            },

            saveSelected() {
                let selected = this.$refs.grid.$refs.grid.selectedRows()
                let students = this.$props.selectedItems
                let payload = []

                this.$confirmCreate(students, () => {
                    students.forEach(student => {
                        selected.forEach(sel => {
                            let course = this.$_.cloneDeep(sel)
                            course.students.push(student.data)
                            course.student_id = student.data.student_id
                            payload.push(course)
                        })
                    })

                    this.$refs.grid.$refs.crud
                    .create(payload)
                    .then(resp => {
                        this.$ecResponse(resp)
                    })
                    .finally(() => {
                        this.load()
                    })
                }, 'add')
            },

            deleteRows() {
                this.$refs.grid.$refs.grid.deleteRows(...arguments)
            },

            exportGrid() {
                this.$refs.grid.$refs.grid.exportGrid(...arguments)
            },

            editCancel() {
                this.$refs.grid.$refs.grid.editCancel(...arguments)
            },

            getAvailableStudents(searchText) {
                if(!searchText || searchText.length <3) {
                    if(this.availableStudents.length) this.availableStudents = []
                } else if(searchText.length === 3) {
                    this.$_.debounce(() => {
                        this.$modelGet('studentGradeHistory', {
                            school_year_id: this.$store.state.global.shareableStores.current_year.id,
                            name: searchText,
                        }).then(response => {
                            this.availableStudents = response
                        })
                    },500)()
                }
            },

            studentSelection(v) {
                if (v) {
                    this.$refs.dialog.insertValue = v.student_id
                    this.disableStudentAdd = false
                } else {
                    if(this.$refs.dialog) this.$refs.dialog.insertValue = undefined
                    this.disableStudentAdd = true
                }
            },

            buildStudentRec(v, data) {
                data.student_id = v
                return data
            },

            studentAdd() {
                this.$refs.dialog.createItem()
                this.$refs.simpleInsertField.searchValue = null
            },

            studentUpdate(data) {
                let active = data.data.active
                let payload = this.$_.cloneDeep(data.data)
                delete payload.active
                payload.inactive_date = active ? null : this.$dayjs().format('YYYY-MM-DD')

                payload = {
                    courses: [{
                        students: [payload]
                    }]
                }

                this.$refs.coursesCrud.update(payload,{ action: 'update', property: 'courses' }).then(() => {
                    this.$refs.dialog.$refs.crud.read()
                })
            },

            closeSimpleGrid() {
                this.$refs.grid.$refs.grid.openMenu = false
            },

            acceptStudentAdvanced() {
                this.newEntry.students = _.unionBy(this.newEntry.students, this.studentsFromAdvanced, "student_id")
                this.newEntry.invalid.students = !this.newEntry.students.length
                this.closeStudentAdvanced()
            },

            closeStudentAdvanced() {
                this.studentAdvanced = false
            },

            acceptUserAdvanced() {
                this.newEntry.users = _.unionBy(this.newEntry.users, this.usersFromAdvanced, "id")
                this.newEntry.invalid.users = !this.newEntry.users.length
                this.closeUserAdvanced()
            },

            closeUserAdvanced() {
                this.userAdvanced = false
            },

            getStudent(student) {
                if (student.lname) {
                    return student.lname + ', ' + student.fname
                } else if (student.student_full_name) {
                    return student.student_full_name
                } else {
                    return student.student_lname + ', ' + student.student_fname
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .chip-wrapper {
        margin-top: -20px;
    }

    .chips-below::v-deep {
        margin-bottom: 25px;

        .v-text-field__details {
            display: none;
        }

        &.invalid {
            margin-bottom: 5px;

            .v-input__slot {
                .v-icon {
                    color: var(--v-error-base) !important;
                    caret-color: var(--v-error-base) !important;
                }

                border-color: #ff5252;
            }
        }
    }

    .chip::v-deep {
        .v-chip__content {
            max-width: unset;
        }

        &.avatar {
            .v-chip__content {
                padding-left: 0;
            }
        }
    }

    .error-msg {
        font-size: 12px;
        font-weight: 300;
        color: #f02d1f;
        margin-left: 15px;
    }
</style>
