<template>
    <fe-dialog
        title="Create Folder"
        @close="$emit('dismiss')"
        :width="400"
        @accept="doAccept"
        @dismiss="$emit('dismiss')"
        acceptButtonText="Save"
        :acceptButtonDisabled="!record.name"
        disableAutoclose
        persistent
    >
        <v-form @submit.prevent>
            <fe-crud
                ref="crud"
                :autoLoad="false"
                :config="$models.formFolder"
                @read="item = $event[0]"
            />
            <v-text-field
                ref="folderName"
                label="Folder Name"
                solo flat dense
                autofocus
                v-model="record.name"
                placeholder="Enter a folder name..."
                counter=30
                :rules="$fieldValidators('text', null, { required: true, limit: 30})"
            />
        </v-form>
    </fe-dialog>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'CreateFolder',
        computed: {
            ...mapState('global', ['sessionUser'])
        },
        props: ['value'],
        data() {
            return {
                record: {
                    name: null
                }
            }
        },
        mounted() {
            this.localValue = this.value

            // this.$nextTick(() => { this.$refs.folderName.$el.focus(); })
        },
        methods: {
            doAccept() {
                this.$data.record.created_by = this.sessionUser.user.id
                this.$refs.crud.create([this.$data.record])
                    .then(() => {
                        this.$emit('created')
                        this.$emit('dismiss')
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
