import axios from 'axios'
import {toJson} from 'really-relaxed-json'
import BaseService from "@/components/modules/thresholds/services/BaseService";

export default {
    default() {
        return {
            state: {
                collection: [],
                dirtied: [],
                removed: [],
                added: [],
                status: null
            },

            mutations: {
                pending(state) {
                    state.status = 'pending'
                },

                success(state, data) {
                    state.status = 'success'
                    state.dirtied = []
                    state.removed = []
                    state.added = []
                    state.collection = data
                },

                error(state) {
                    state.status = 'error'
                    state.collection = []
                },

                reconciled(state) {
                    state.status = 'success'
                    state.dirtied = []
                    state.collection = state.collection.filter((itm, idx) => {
                        return !state.removed.includes(idx)
                    })
                    state.removed = []
                    state.added = []
                },

                clear(state) {
                    state.status = null
                    state.dirtied = []
                    state.removed = []
                    state.added = []
                },

                update(state, params) {
                    Object.entries(params).map(itm => {
                        let key = itm[0]
                        let val = itm[1]
                        if (key === '__removedKeys' && Array.isArray(val) && val.length) {
                            val.forEach(k => {
                                if (Object.keys(state.collection[params.__idx]).includes(k)) {
                                    delete state.collection[params.__idx][k]
                                    if (state.added.indexOf(params.__idx) < 0) {
                                        if (state.dirtied.indexOf(params.__idx) < 0) {
                                            state.dirtied.push(params.__idx)
                                        }
                                    }
                                }
                            })
                        } else if (key.substring(0, 2) !== '__') {
                            if (state.collection[params.__idx][key] !== val) {
                                // for key: ethnicity | gender | disability_type_id | ell_level_id | demo_XX (eg demo_96)
                                // getters: thresholds/ethnicity/container
                                //          thresholds/gender/container
                                //          thresholds/disability/container
                                //          thresholds/elllevel/container
                                //          thresholds/demographic/all
                                // make sure we send all members of display_name_group
                                let collection = [] // collect all available values
                                if (key === 'ethnicity') {
                                    collection = this.getters['thresholds/ethnicity/collection']
                                } else if (key === 'gender') {
                                    collection = this.getters['thresholds/gender/collection']
                                } else if (key === 'disability_type_id') {
                                    collection = this.getters['thresholds/disability/collection']
                                } else if (key === 'ell_level_id') {
                                    collection = this.getters['thresholds/ellLevel/collection']
                                } else {
                                    collection = this.getters['thresholds/demographic/all']
                                }
                                if (collection.length > 0 && !key.includes('demo_')) { // hybrid demos
                                    // get all group members if group has been added
                                    let newParams = []
                                    let id = (key === 'gender' || key === 'ethnicity') ? 'value' : 'id'
                                    val.forEach(n => {
                                        let g = collection.find(c => c[id] == n)
                                        if (g?.display_name_group) {
                                            g.display_name_group.forEach(i => {
                                                newParams.push(i)
                                            })
                                        } else {
                                            newParams.push(n)
                                        }
                                    })
                                    state.collection[params.__idx][key] = newParams
                                } else if (collection.length > 0 && key.includes('demo_')) { // reg demos
                                    let newParams = ''
                                    let demo_id = key.split('_')[1];
                                    let demo = collection.find(c => c.id == demo_id)
                                    let option = demo.options ? demo.options.find(o => o.id == val) : null
                                    if (option && option.display_name_group) {
                                        newParams = option.display_name_group.toString()
                                    } else {
                                        newParams = val
                                    }
                                    state.collection[params.__idx][key] = newParams
                                } else {
                                    state.collection[params.__idx][key] = val
                                }

                                if (state.added.indexOf(params.__idx) < 0) {
                                    if (state.dirtied.indexOf(params.__idx) < 0) {
                                        state.dirtied.push(params.__idx)
                                    }
                                }
                            }
                        }
                    })
                },

                add(state, params) {
                    params.forEach(itm => {
                        state.collection.push(itm)
                        state.added.push(state.collection.length - 1)
                    })
                },

                remove(state, params) {
                    params.indexes.forEach(idx => state.removed.push(idx))
                }
            },

            actions: {
                refresh({dispatch, commit, getters}, params = {}) {
                    commit('pending')
                    let cfg = getters.__getMethodConfigs.fetch
                    return new Promise((resolve, reject) => {
                        cfg.service.fetch(Object.assign({}, cfg.params, params.params), cfg.opts)
                            .then(rsp => {
                                let data = (rsp.data && cfg.prop) ? rsp.data[cfg.prop] : rsp.data
                                if (typeof data === 'object') {
                                    commit('success', Array.isArray(data) ? data : [data])
                                    dispatch('afterRefresh')
                                    resolve(rsp)
                                } else {
                                    commit('error')
                                    reject(data)
                                }
                            })
                            .catch(err => {
                                commit('error')
                                reject(err)
                            })
                    })
                },

                reconcile({dispatch, commit, getters}) {
                    commit('pending')

                    let requests = []
                    let methodConfigs = getters.__getMethodConfigs
                    Object.entries(getters.__typeMap).forEach(([type, method]) => {
                        if (getters.__changes[type]) {
                            let data = {}
                            let cfg = methodConfigs[type]
                            data[`${cfg.prop}`] = getters.__postData[type]
                            requests.push(cfg.service[method](cfg.params, data, cfg.opts))
                        }
                    })

                    if (requests.length > 0) {
                        return new Promise((resolve, reject) => {
                            axios.all(requests).then(rsps => {
                                let responses = rsps.map(rsp => {
                                    return (typeof rsp.data === "object")
                                        ? rsp.data
                                        : JSON.parse(toJson(rsp.data))
                                })
                                if (responses.filter(rsp => !rsp.success).length > 0) {
                                    commit('error')
                                    reject(responses)
                                } else {
                                    dispatch('afterReconcile', responses)
                                    commit('reconciled')
                                    resolve(responses)
                                }
                            }).catch(err => {
                                commit('error')
                                reject(err)
                            })
                        })
                    } else {
                        commit('reconciled')
                    }
                },

                clear({dispatch, commit}) {
                    commit('clear')
                    dispatch('clearChildren')
                },

                clearChildren() {
                    // Must be overridden if model has child modules.
                    return true
                },

                update({dispatch, commit, getters}, params) {
                    commit('update', params)
                    if (getters.__autosave) {
                        dispatch('reconcile')
                    }
                },

                add({dispatch, commit, getters}, params) {
                    commit('add', params)
                    if (getters.__autosave) {
                        dispatch('reconcile')
                    }
                },

                remove({dispatch, commit, getters}, params) {
                    commit('remove', params)
                    if (getters.__autosave) {
                        dispatch('reconcile')
                    }
                },

                afterReconcile({dispatch}) {
                    // Can be overridden by model.  Handles updating of display data.
                    dispatch('refresh')
                },

                afterRefresh() {
                    // Can be overridden by model.
                },

                printTable({}, params) {
                    let {columns, dataset, groupName, schoolYearName} = params

                    let html = `<!DOCTYPE html>
                        <html lang="en">
                          <head>
                            <meta charset="utf-8">
                            <meta http-equiv="X-UA-Compatible" content="IE=edge">
                            <title>eduCLIMBER</title>
                            <style type="text/css">

                              body {
                                font: 11px Helvetica, Arial, sans-serif;
                                color: #333;
                              }

                              th, td { padding: 5px; margin: 0; }
                              tr { padding: 0; margin: 0; }

                              #data-head-title-cell { padding: 10px; }
                              #name { font-size: 20px; color: #000; }
                              #schoolyear { font-size: 14px; color: #666; }

                              #data-head-header-row th { color: #000; font-size: 13px; }

                              .align-left { text-align: left; }

                              #data { width: 100%; }
                              #data-body td { border-top: solid 1px #ccc; }

                            </style>
                            <style type="text/css" media="print">
                              @page {
                                size: landscape;
                              }
                            </style>
                          </head>
                          <body>
                            <table id="data">
                              <thead id="data-head">
                                <tr id="data-head-title-row">
                                  <th id="data-head-title-cell" colspan="` + columns.length + `">
                                    <div id="name">` + groupName + `</div>
                                    <div id="schoolyear">` + schoolYearName + `</div>
                                  </th>
                                </tr>
                                <tr id="data-head-header-row">`

                    columns.forEach(def => {
                        html += `<th class="align-left">` + def.headerName + `</th>`
                    })

                    html += `</tr>
                      </thead>
                      <tbody id="data-body">`

                    dataset.forEach(row => {
                        let cells = []

                        for (let i = 0; i < columns.length; i++) {
                            if (columns[i]) {
                                /**
                                 * Less readable, but array joining is more performant than string concatenation and
                                 * with 10,000+ rows, this can really add up.
                                 **/
                                cells.push(['<td class="align-left">', row[i], '</td>'].join(''))
                            }
                        }

                        html += `<tr>` + cells.join('') + `</tr>`
                    })

                    html += `</tbody>
                        </table>
                      </body>`

                    let winUrl = URL.createObjectURL(
                        new Blob([html], {type: "text/html"})
                    );

                    let printWindow = document.createElement('iframe')
                    printWindow.src = winUrl
                    printWindow.style.display = 'none'
                    document.body.appendChild(printWindow)

                    setTimeout(() => {
                        printWindow.contentWindow.print()
                        printWindow.parentNode.removeChild(printWindow)
                        return Promise.resolve()
                    }, 250)
                }
            },

            getters: {
                __config() {
                    // Must be overridden by model.
                    return {
                        service: null,
                        opts: {},
                        params: {},
                        prop: null
                    }
                },

                __typeMap() {
                    return {
                        fetch: 'fetch',
                        dirtied: 'update',
                        removed: 'remove',
                        added: 'add'
                    }
                },

                __getMethodConfigs(state, getters) {
                    let config = getters.__config
                    let keys = ['service', 'params', 'prop', 'opts']
                    let result = {}
                    Object.entries(getters.__typeMap).forEach(([type, method]) => {
                        result[type] = {}
                        keys.forEach(key => {
                            result[type][key] = (config[method] && config[method][key])
                                ? config[method][key]
                                : config[key]
                        })
                    })
                    return result
                },

                __postData: (state, getters) => {
                    return {
                        dirtied: getters.dirtied,
                        removed: getters.removed,
                        added: getters.added
                    }
                },

                __changes: (state, getters) => {
                    return {
                        dirtied: getters.hasDirtied,
                        removed: getters.hasRemoved,
                        added: getters.hasAdded
                    }
                },

                __autosave: () => true,

                count: state => state.collection.length,

                collection: state => state.collection.map((row, idx) => Object.assign({}, row, {
                    __idx: idx,
                    __dirtied: state.dirtied.includes(idx),
                    __removed: state.removed.includes(idx),
                    __added: state.added.includes(idx)
                })),

                rowData: (state, getters) => getters.collection.filter(row => !row.__removed),

                pending: state => (state.status === 'pending'),

                success: state => (state.status === 'success'),

                error: state => (state.status === 'error'),

                clear: state => (state.status === null),

                dirtied: (state) => state.collection.filter((row, idx) => state.dirtied.includes(idx) && !state.added.includes(idx) && !state.removed.includes(idx)),

                removed: (state) => state.collection.filter((row, idx) => state.removed.includes(idx) && !state.added.includes(idx)),

                added: (state) => state.collection.filter((row, idx) => state.added.includes(idx) && !state.removed.includes(idx)),

                hasDirtied: state => (state.dirtied.length > 0),

                hasRemoved: state => (state.removed.length > 0),

                hasAdded: state => (state.added.length > 0),

                hasChanges: (state, getters) => (getters.hasDirtied || getters.hasRemoved || getters.hasAdded)
            }
        }
    }
}
