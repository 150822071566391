<template>
    <div class="flex-fill flex-column no-scroll" style="height: 400px;">
        <fe-crud-grid
            ref="grid"
            identifier="student_enrollment_id"
            autoload
            :config="$models.studentEnrollments"
            :readParams="{ student_id: this.updateStudent.student_id }"
            :columnDefs="columnDefs"
            :showAddRowBtn="false"
            :showEditbar="false"
            disableInlineCreate
            displayDensity="medium"
            style="height:100%;"
        >
        </fe-crud-grid>
    </div>
</template>

<script>
    import Vuex, { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'

    export default {
        name: 'StudentEnrollments',

        data() {
            return {
                showAddDialog: false,
                studentEnrollment: [],
                gridApi: null
            }
        },

        inject: ['localStore'],

        computed: {
            ...mapLocalState(['updateStudent']),

            valuesLoaded() {
                return !!this.updateStudent
            },

            columnDefs() {
                return !this.valuesLoaded ? [] : [
                    {
                        headerName: "Internal ID",
                        field: "student_id",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "School Year",
                        field: "school_year_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "School",
                        field: "school_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Grade",
                        field: "grade_desc",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Start Dt",
                        field: "student_enrollment_start_date",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "End Dt",
                        field: "student_enrollment_end_date",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Primary",
                        field: "student_enrollment_primary_flag",
                        sortable: true,
                        cellRenderer(v) {
                            if (v.value) return '<i class="fas fa-check"></i>'
                        },
                        cellStyle: { "padding": 0, textAlign: 'center' }
                    }, {
                        headerName: "Upload ID",
                        field: "student_enrollment_upload_id",
                        sortable: true,
                        editable: false
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
