<template>
    <fe-dialog
        ref="dialog"
        title="Attach a Meeting"
        dismissButtonText="Cancel"
        acceptButtonText="Attach"
        :acceptButtonDisabled="!selection"
        persistent
        @close="$emit('close')"
        @dismiss="$emit('close')"
        @accept="$emit('attach', selection)"
    >
        <fe-remote-combo
            label="Choose a Meeting"
            placeholder="Select a Meeting..."
            rootProperty="dashboard_meetings"
            itemValue="id"
            itemText="title"
            v-model="selection"
            :items="meetings"
            dense
            lazyLoad
        >
            <template v-slot:item="{ item, index }">
                <div style="width: 100%; margin-top: 4px; margin-bottom: 4px;">
                    {{ item.title }}<br/>
                    <span
                        v-if="item.dashboard_id"
                        class='text-smaller'>
                        Collection: <strong>{{ item.dashboard_title }}</strong>
                    </span>
                </div>
            </template>
        </fe-remote-combo>

        <fe-crud :config="userMeetingsConfig" autoload @read="postReadMeetings"/>
        <fe-crud :config="createdMeetingsConfig" autoload @read="postReadMeetings"/>
    </fe-dialog>
</template>

<script>
export default {
    name: 'Attach',

    data() {
        return {
            selection: null,
            meetings: []
        }
    },

    computed: {
        currentUser() {
            return this.$store.state.global.sessionUser.user
        },

        createdMeetingsConfig() {
            let cfg = this.$_.cloneDeep(this.$models.meeting)
            cfg.read.params.created_by = this.currentUser.id
            cfg.read.params.active = 1
            return cfg
        },

        userMeetingsConfig() {
            let cfg = this.$_.cloneDeep(this.$models.userMeeting)
            cfg.read.params.user_id = this.currentUser.id
            cfg.read.params.active = 1
            return cfg
        }
    },

    methods: {
        postReadMeetings(data) {
            if (!this.meetings.length) {
                this.meetings = data
            } else {
                let concat = this.$_.concat(this.meetings, data)
                this.meetings = this.$_.sortedUniqBy(this.$_.sortBy(concat, 'title'), 'id')
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>
