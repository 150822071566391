<template>
    <record-dialog
        title="Form Status"
        width="460px"
        v-bind="$attrs"
        :defaultRec="{
            name: null,
            lock_flag: null,
            carry_over: null,
        }"
        @create="update" @update="update"
        @close="$emit('close')"
    >
        <template #form="{rec}">
            <fe-label style="width:76px;">Name</fe-label>
            <v-text-field flat solo dense autofocus
                v-model="rec.name"
                :rules="$fieldValidators('text', null, {required:true, limit:255})"
            />
            <div class="d-flex" style="align-items: center">
                <fe-label style="width:150px;">Status Locks Form</fe-label>
                <fe-tooltip style="margin-left:auto;" :disabled="!Boolean(rec.carry_over)" tooltip="This cannot be disabled while Carry Over to Next Year is enabled for this Status Code.">
                    <fe-switch style="margin-left:auto;" v-model="rec.lock_flag" :disabled="Boolean(rec.carry_over)" hide-details />
                </fe-tooltip>
            </div>
            <div class="d-flex mt-4" style="align-items: center">
                <fe-label style="width:150px; align-items: center" class="d-flex">
                    Carry Over to Next Year
                    <fe-info-tip
                        tooltip="When enabled, all form instances associated to the prior year will be locked, archived, and available to view on the first day of the new school year.
                            A form for the new school year will be created and will include all relevant available data, and will be editable for those with permissions to do so."
                        direction="top"
                        icon="far fa-info-circle"
                        class="ml-2"
                        color="#7E8494"
                    ></fe-info-tip>
                </fe-label>
                <fe-switch style="margin-left:auto;" hide-details :value="rec.carry_over" @change="onChange(...arguments, rec)" />
            </div>
            
            <fe-crud ref="crud" :config="$models.formInstanceStatus" :autoload="false" />
            
        </template>
    </record-dialog>
</template>

<script>
import RecordDialog from '@/components/common/form/RecordDialog'

export default {
    name: 'Add',
    
    components: {
        RecordDialog
    },
    
    methods: {
        update(rec) {
            if(!rec.id) {
                this.$refs.crud.create(rec)
                .finally(()=>this.$emit('close'))
            } else {
                this.$refs.crud.update(rec)
                .finally(()=>this.$emit('close'))
            }
            
        },
        onChange(v, rec) {
            if(v) {
                rec.carry_over = v
                rec.lock_flag = v
            } else {
                rec.carry_over = v
            }
        }
    },
}
</script>

<style lang="css" scoped>
</style>
