<template>

    <v-layout column fill-height class="mainPanel">
        <v-layout row style="height:36px;">

            <!-- Filter Chip Panel -->
            <v-flex ref="chippanel" grow style="white-space:nowrap; overflow:hidden;" v-resize="resize">
                <span style="margin: auto 10px;" v-show="params.filters.length"  >Filters Applied:</span>
                <template v-for="(filter,i) in params.filters">
                    <active-filter-chip ref="chips" v-if="chipFit(i)" :filter="filter" :key="i+'-'+renderKey" >
                        {{filter}}
                    </active-filter-chip>
                </template>
                <v-menu v-if="truncatedFilters.length" offset-y content-class="truncate-menu" transition="slide-y-transition">
                    <template #activator="{ on }">
                        <fe-chip v-on="on">+{{truncatedFilters.length}} more</fe-chip>
                    </template>
                    <v-layout column>
                        <active-filter-chip v-for="filter in truncatedFilters" :filter="filter">
                            {{filter}}
                        </active-filter-chip>
                    </v-layout>
                </v-menu>
            </v-flex>

            <!-- To Grid Tool -->
            <v-layout shrink justify-end style="width:170px;" v-if="records">
                <span style="margin: auto 10px;">Layout:</span>
                <v-btn icon class="ma-0" @click="showGrid = !showGrid">
                    <v-icon small>{{gridIcon}}</v-icon>
                </v-btn>
                <v-btn icon class="ma-0" :class="{disabled: disableZoom || showGrid}" @click="zoomed = !zoomed">
                    <v-icon>{{magIcon}}</v-icon>
                </v-btn>
            </v-layout>
        </v-layout>

        <!-- Empty State -->
        <fe-overlay
            v-if="!records"
            :absolute="false"
            :header="headline"
            :text="subheading"
            image="images/rocket.png"
            imageSize="60%"
            color="white"
        />

        <!-- Student Chips -->
        <renderer v-show="records && !showGrid"
            @labelHover="labelHover = $event"
            @select="selectedStudents = $event"
            style="position:relative;"
        >
            <student-card />

            <v-card dark v-if="labelHover"
                :style="{
                    position: 'absolute',
                    left: `${labelHover.x}px`,
                    top: `${labelHover.y}px`,
                    transform: 'translate(-50%, 0)',
                    padding: '1px 6px 1px 6px',
                    pointerEvents: 'none'
                }"
            >
                {{labelHover.text}}
            </v-card>
        </renderer>

        <!-- Student Grid -->
        <div v-if="records && showGrid" style="height:100%; padding:16px;">
            <grid rowGroup />
        </div>

        <fe-dialog
            v-if="!$_.isEmpty(selectedStudents)"
            title="Selected Students"
            @close="selectedStudents=[]"
            width="80%"
            persistent
        >
            <div style="height:500px;">
                <grid selected-only />
            </div>
        </fe-dialog>


    </v-layout>
</template>

<script>
import Group from '../Group'
import { mapLocalState } from '@/util/vuexHelper'
import ActiveFilterChip from './ActiveFilterChip'
import Renderer from './Renderer'
import Grid from './Grid'
import StudentCard from './StudentCard'

export default {
    name: 'MainPanel',
    inject: ['localStore'],

    components: {
        ActiveFilterChip,
        Renderer,
        Grid,
        StudentCard,
    },

    computed: {
        ...mapLocalState(['params','charts','activeStudent','selectedStudents','zoomed','activeGrouping','activeLayout']),
        gridIcon() { return this.showGrid ? 'fas fa-braille' : 'fal fa-table' },
        magIcon() { return this.zoomed ? 'zoom_out' : 'zoom_in' },
        disableZoom() {
            // return true
            return this.activeLayout?.viewWidth >= this.activeLayout?.width
        },
        records() {
            return this.charts && !this.$_.isEmpty(this.charts.records)
        },
        headline() {
            return this.$_.isEmpty(this.params.search)
                ? 'Start Searching for Students'
                : '0 Students'
        },
        subheading() {
            return this.$_.isEmpty(this.params.search)
                ? 'To get started, begin by choosing a school year and optionally school and grade.'
                : 'Try changing your filter options to broaden the search results.'
        },
        truncatedFilters() {
            if(this.renderKey<0) return null // this is a hack to force a re render of truncatedfilters
            return this.params.filters.filter((x,i) => i >= this.truncatedFilterIndex)
        }
    },

    methods: {
        resize(v) {
            this.renderKey++
        },
        chipFit(i) {
            if(i==0) this.truncatedFilterIndex = Infinity
            if(i>this.truncatedFilterIndex) return false
            if(i>0 && this.$refs.chips[i-1]) {
                let r = this.$refs.chips[i-1].$el.offsetLeft + this.$refs.chips[i-1].$el.offsetWidth - this.$refs.chippanel.offsetLeft
                if(r>this.$refs.chippanel.offsetWidth-230) {
                    this.truncatedFilterIndex = i
                    return false
                }
            }
            return true
        }
    },

    watch: {
        charts(v) {},
        ['params.filters'](v) {}
    },

    data() {
        return {
            labelHover: null,
            showGrid: false,
            renderKey: 0,
        }
    },
}
</script>

<style lang="scss" scoped>
    .mainPanel {
        border-top: 1px solid #CED0D9;
        border-left: 1px solid #CED0D9;
    }
    .truncate-menu {
        box-shadow: none;
    }
    .disabled {
        pointer-events: none;
        opacity: .3;
    }
</style>
