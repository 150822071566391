<template>
    <div class="d-flex flex-fill flex-column">
        <v-tabs v-model="selectedView" class="ec-tabs">
            <v-tab v-for="t in views" class="ec-tabs-small">{{t.name}}</v-tab>

            <v-tab-item>
                <div class="d-flex flex-fill flex-column pa-2">
                    <fe-dialog
                        title="Students"
                        v-if="dialog.show"
                        @accept="dialog.show=false"
                        @close="dialog.show=false"
                        persistent
                        :width="gridWidth"
                        dismissButtonText="Cancel"
                        acceptButtonText="Done"
                    >
                        <div class="d-flex flex-fill flex-column">
                            <fe-grid
                                ref="dialogGrid"
                                :rowData="rowData"
                                :columnDefs="columns"
                                displayDensity="small"
                                style="height: 65vh;"
                                :showAddRowBtn="false"
                                @selectionChanged="selections=$refs.dialogGrid.gridApi.getSelectedRows()"
                                groupUseEntireRow
                            >
                                <template #toolbar-items>
                                    <fe-btn usage="secondary" :disabled="selections.length===0" @click="tagStudents">Tag</fe-btn>
                                </template>
                            </fe-grid>
                        </div>

                    </fe-dialog>
                    <highcharts class="fe-chart" :options="chartConfig"/>
                </div>
            </v-tab-item>

            <v-tab-item>
                <div class="d-flex flex-fill flex-column pa-2" style="height: 79vh;">
                    <drilldown :params="drilldownParams" roi/>
                </div>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
    import ToggleButton from '@/components/common/button/ToggleButton'
    import Drilldown from './Drilldown'
    import windowOptionsMixin from '@/mixins/windowOptions'

    export default {
        name: 'InterventionAcademicDrilldown',
        mixins: [ windowOptionsMixin ],
        components: { ToggleButton, Drilldown },
        computed: {
            gridWidth() {
                return window.innerWidth*.95
            },
            drilldownParams() {
                let p = {...this.params}
                return p
            }
        },
        data() {
            let me = this
            return {
                charts: [],
                raw: [],
                crumbs: [],
                loading: false,
                toggles: [{
                    name: 'Charts',
                    icon: 'fas fa-chart-bar'
                }, {
                    name: 'Data List',
                    icon: 'fas fa-bars'
                }],
                views: [{
                    type: 'progress',
                    name: 'Goal Progress'
                }, {
                    type: 'roi',
                    name: 'Rate of Improvement'
                }],
                selectedView: null,
                activeToggle: {},
                selections: [],
                sections: [{
                    key: 'total_on_track',
                    color: '#19ADAB',
                    title: 'On Track'
                }, {
                    key: 'total_not_on_track',
                    color: '#FF675D',
                    title: 'Not On Track'
                }, {
                    key: 'total_no_goal',
                    color: '#FFEEAA',
                    title: 'No Goal'
                }, {
                    key: 'total_no_roi',
                    color: '#C0C3CF',
                    title: 'No Data Entered'
                }],
                chartConfig: {},
                columns: [me.$grid.checkColumn(), {
                   headerName: "Student",
                   field: "student_full_name",
                   width: 200
                }, {
                   headerName: "School",
                   field: "school_name",
                   width: 100
                }, {
                   headerName: "Ethnicity",
                   field: "student_ethnicity",
                   width: 100
                }, {
                   headerName: "Gender",
                   field: "student_gender",
                   width: 80
                }, {
                   headerName: "Skill Area",
                   field: "category_name",
                   editable: false,
                   width: 110,
                   rowGroup: true
                }, {
                   headerName: "Level",
                   field: "intervention_level_name",
                   editable: false,
                   width: 100
                }, {
                   headerName: "Type",
                   field: "intervention_type_name",
                   editable: false,
                   width: 200
                }, {
                   headerName: "ROI",
                   field: "roi",
                   width: 80,
                   cellStyle(v) {
                       return {
                           textAlign: 'right'
                       }
                   }
                }, {
                   headerName: "Goal ROI",
                   field: "goal_roi",
                   width: 80,
                   cellStyle(v) {
                       return {
                           textAlign: 'right'
                       }
                   }
                }, {
                   headerName: "Est Goal Dt",
                   field: "achieve_goal_date",
                   editable: false,
                   width: 100,
                }, {
                   headerName: "Anticipated Final Score",
                   field: "anticipated_final_score",
                   width: 80,
                   cellStyle(v) {
                       return {
                           textAlign: 'right'
                       }
                   }
                }],
                rowData: [],
                dialog: {
                    show: false
                }
            }
        },
        props: {
            view: {
                type: String
            },
            params: {

            }
        },
        watch: {
            loading(v) {
                this.$setLoading(v)
            },
            view(v) {},
            params: {
                handler(v) {
                    this.crumbs = [{
                        name: 'Drilldown',
                        params: {...v}
                    }]

                    this.loadData()
                },
                immediate: true
            }
        },
        created() {
            this.selectedView = this.views[0]
            this.activeToggle = this.toggles[0]
        },
        methods: {
            tagStudents() {
                let me=this
                this.$tagStudents(true, this.selections,()=> {
                    me.$refs.dialogGrid.gridApi.deselectAll()

                })
            },
            doExpand(cfg) {
                let c = this.$_.cloneDeep({...cfg})
                c.chart.height = window.innerHeight-150
                c.xAxis.labels.useHTML = true
                c.xAxis.labels.formatter = function(){
                    return this.value
                }
                this.$dockableWindow({
                    name: cfg.title.text,
                    component: 'highcharts',
                    attrs: {
                        options: c
                    }
                })
            },
            baseConfig(cfg) {
                let me = this

                return {
                    credits: { enabled: false },
                    chart: {
                        type: 'column',
                        height: window.innerHeight-200,
                        zoomType: 'xy'
                        // marginBottom: 50
                        // padding: [0,0,0,0]
                    },
                    title: {
                        text: 'Intervention Progress',
                        align: 'left'
                    },
                    yAxis: {
                        endOnTick: false,
                        title: {
                            text: 'Count'
                        }
                    },
                    plotOptions: {
                        series: {
                            color: '#08A5DA',
                            point: {
                                events: {
                                    click(e) {
                                        me.loadStudents(e.point.meta.key, e.point.meta.data)
                                    }
                                }
                            },
                            stacking: 'normal',
                            dataLabels: {
                                enabled: false,
                                style: {
                                    textOutline: 0,
                                    color: '#000000'
                                }
                            },
                            label: {
                                style: {
                                    color: 'black'
                                }
                            },
                            size: '100%'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        // categories: [],
                        labels: {
                            formatter() {
                                return (this.value+'').substring(0, 8)+'...'
                            },
                            style: {
                                fontSize: '10px',
                                color: 'gray'
                            }
                        }
                    },
                    legend: {
                        // enabled: false
                    },
                    exporting: {
                        enabled: false
                    },
                    series: []
                }
            },
            loadStudents(key, rec) {
                let me = this
                let p = {...this.params}
                p[key]=1
                p.intervention_type_id = rec.intervention_type_id
                this.$setLoading(true)
                this.$axios.get('interventionView.php?action=get&type=academic&property=roi_target_list&'+this.$objectToParams(p))
                    .then(r => {
                        this.$setLoading(false)
                        this.rowData = this.$ecResponse(r, 'interventions')
                        this.dialog.show=true
                    })
            },
            loadStudentList() {
                this.$setLoading(true)
                let p = {...this.params}
                delete p.chart_types
                this.$axios.get('interventionView.php?action=get&property=student_intervention_summary&type=academic&'+this.$objectToParams(p))
                    .then(resp => {
                        this.rowData = this.$ecResponse(resp, 'interventions')

                        this.$setLoading(false)
                    })
            },
            loadData(force) {
                let p = force || this.crumbs[this.crumbs.length-1].params
                this.loading=true
                this.$axios.get('interventionView.php?action=get&property=plan_roi_strategy&type=academic&'+this.$objectToParams(p))
                    .then(r => {
                        this.raw = this.$ecResponse(r, 'interventions')
                        this.processData()
                        this.loading=false
                    })
            },
            processData() {
                this.charts = []
                let cfg = this.baseConfig()
                let series = []

                this.sections.forEach(s => {
                    let serie = {
                        name: s.title,
                        color: s.color,
                        data: []
                    }
                    let found = {}

                    this.raw.forEach(r => {
                        if (!found[r.intervention_type_name]) {
                            found[r.intervention_type_name]=0
                        }
                        found[r.intervention_type_name]++

                        serie.data.push({
                            name: r.intervention_type_name + (found[r.intervention_type_name]===1 ? '' : ' '+found[r.intervention_type_name]),
                            color: s.color,
                            y: parseInt(r[s.key]),
                            meta: {
                                key: s.key,
                                data: r
                            }
                        })
                    })
                    series.push(serie)
                    // serie.name = r.title
                    // cfg.title.text = r.title
                    // let found = {}
                })
                cfg.series = series
                this.chartConfig = cfg
            }
        },
    }
</script>

<style lang="scss" scoped>
.fe-chart ::v-deep * {
    font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
}

.strategy-container {
    text-align: center;
    border: 1px solid #E0E1E6;
    border-radius: 5px;
    padding: 8px 0px 8px 0px;
    margin: 8px 8px 8px 8px;
    background: white;

    .count-container {
        border: 1px solid red;
    }

    .strategy-title {
        font-size: 16px;
        padding: 4px 8px 4px 8px;
    }

    .strategy-count {
        margin: 2px 4px 2px 4px;
        padding: 4px 8px 4px 8px;
        height: 48px;
        width: 48px;
        border-radius: 4px;
    }

    .goal-container {
        text-align: center;
        width: 90px;
        cursor: pointer;

        .goal-number {
            font-size: 12px;
            height: 50px;
            border-radius: 4px;
        }

        .goal-title {
            font-size: 16px;
        }
    }
}
</style>
