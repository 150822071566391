export default {
    data() {
        return {
        }
    },
    computed: {
        sectionLayoutTypes() {
            return this.$_.omit(this.$store.state.global.shareableStores.section_layout_types,'4')
        },
        formFieldTypes() {
            return this.$store.state.global.shareableStores.form_field_types
        },
        noLabels() {
            return [
                'scorefield', 'message', 'interventionblock',
                'progressmonitoringblock', 'decisionrationaleview', 'smarttagfield',
                'pdfpagebreak', 'fieldspacer',
            ].includes(this.xtype)
        },
        formTranslationsEnabled() {
            return this.$store.getters['flags/flags']['e-c-form-translations'] === true
        }
    },
    methods: {
        formFieldTypesSorted(sort) {
            return Object.values(this.$store.state.global.shareableStores.form_field_types).sort(sort).filter(x => {
                if(x.id == 32 && !this.$store.state.global.shareableStores.ell_level_enabled) {
                    return false
                }
                // return x.id!=29
                return true
            })
        },
        isField(x) {
            return x.form_field_type_id
        },
        downloadFormZip(recs, downloadLang = null, allFiles = false, filters = null) {
            if(allFiles) {
                this.$axios.post('formPrint.php?&archive=1&all=1&' + this.$objectToParams(filters))
                    .then( r => {
                        this.$root.$emit('waiterDialog', {
                            action: 'create',
                            text: 'Preparing Files',
                            successText: 'Your smartFORMS are ready',
                            batch_job_id: r.data.batch_job_id,
                            content_count: recs.length,
                        })
                    })
            } else {
                const maxBatch = 1000
                let remaining = recs.length > maxBatch ? recs.splice(maxBatch) : []
                if(recs.length) {
                    let targetLanguage = []
                    if(recs.some(x => x.home_language_code) && downloadLang === 1) {
                        recs.forEach(rec => {
                            targetLanguage.push(rec.form_instance_id.toString() + ':' + rec.home_language_code.toString())
                        })
                    }
                    let sendTargetLang = ''
                    if(this.formTranslationsEnabled && downloadLang === 1) {
                        sendTargetLang = '&target_language=' + targetLanguage
                    }
    
                    this.$axios.post('formPrint.php?&archive=1' + sendTargetLang, JSON.stringify(recs.map(x => x.form_instance_id)))
                        .then( r => {
                            this.$root.$emit('waiterDialog', {
                                action: 'create',
                                text: 'Preparing Files',
                                successText: 'Your smartFORMS are ready',
                                batch_job_id: r.data.batch_job_id,
                                content_count: recs.length,
                            })
                        })
    
                    if(remaining.length) {
                        this.$messageBox({
                            title: `${maxBatch} smartFORMS zipped`,
                            persistent: true,
                            maxWidth: 400,
                            message: `Only ${maxBatch} smartFORMS can be zipped at one time.  The remaining have been left selected`,
                            actions: [{
                                text: 'Ok'
                            }]
                        })
                    }
                }
                return remaining
            }
        },
    }
}
