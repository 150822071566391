<template>
    <div>
        <fe-text-field placeholder="Search Collections" prependIcon="fa-search" width="100%" @change="filterCollectionsTextChange"></fe-text-field>
        <div class="ec-collection-tab-item-div">
            <collection-tabs :collectionData="subcollectionDataFiltered" :activeTab="1" :filterText="filterCollectionsText" subcollection
                :collectionTypes="collectionTypes"/>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import CollectionTabs from "../landing/Tabs";
    export default {
        name: 'Subcollections',
        components: {CollectionTabs},
        props: {
            reload: { type: Boolean, default: false }
        },

        data() {
            return {
                subcollectionData: [],
                subcollectionDataFiltered: [],
                subCollections: [],
                filterCollectionsText: null,
                collectionTypes: []
            }
        },

        methods: {
            onLoad() {
                this.subcollectionData = []
                this.collectionTypes = []
                this.loadStatus()
                this.loading = true
                if(this.$route.params.id) {
                        this.dashboard_id = parseInt(this.$route.params.id.split(':')[1])
                        this.$axios.get('dashboard.php?action=get&property=dashboard_group&parent_dashboard_id=' + this.dashboard_id)
                            .then(response => {
                                this.subcollectionData = response.data.dashboard_groups
                            })
                            .finally(() => {
                                this.loading = false
                            })
                }
            },
            loadStatus() {
                this.$modelGet('collectionSavedSearchType', {active: 1})
                    .then(response => {
                        this.collectionTypes = response
                    })
            },
            filterCollectionsTextChange(v) {
                this.filterCollectionsText = v
            }
        },

        watch: {
            subcollectionData(v) {
                this.subcollectionDataFiltered  = v
            },
            reload(v) {
                if (v) {
                    this.onLoad()
                    this.$emit('reloaded', false)
                }
            }
        },

        mounted() {
            this.onLoad()
        }
    }
</script>

<style lang="scss">
    .ec-collection-card {
        &-more {
            font-size: 10px;
            font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
        }
    }
</style>
