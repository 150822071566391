<template>
    <div class="flex-fill flex-column">
        <fe-mask v-show="showMergeMask" :showLoader="true" />
        <div class="flex-shrink-1">
            <fe-title-select
                v-if="groups.length > 0 && selectedGroup"
                placeholder="Select an Assessment Group"
                :items="groups"
                v-model="selectedGroup"
            ></fe-title-select>
        </div>

        <fe-crud-grid
            ref="grid"
            :config="$models.dataPointName"
            :readParams="{
                school_year_id: overallSchoolYearId,
                data_point_type_id: this.group.id,
                with_open_window_count: 1,
                show_all: 1
            }"
            :columnDefs="columnDefs"
            :insertDefaults="{
                created_by: null,
                data_point_type_id: group.id
            }"
            style="height: 100%;"
            refresh-on-created
            refresh-on-config
            disable-inline-create
            @beginCreate="create"
            @read="windows = $event"
            @selectionChanged="selectionChanged"
            @close-dialog="refreshCells"
            @updated="$emit('reloadCurrent')"
            @destroyed="$emit('reloadCurrent')"
            @error="refresh"
            :flexColumns="false"
            :persistCellDialog="persistCellDialog"
            @confirmDestroy="crudConfirmDelete"
            @cellValueChanged="onUpdate"
        >
            <template #after-search-toolbar-items>
                <div class="mx-4">
                    <!-- Render the label absolutely to preserve the single-line flow of the
                         search toolbar -->
                    <div style="position: absolute; transform: translate(0, -100%);">School Year</div>

                    <v-select
                        flat solo dense
                        :style="{ maxWidth: '300px' }"
                        itemText="name"
                        itemValue="id"
                        :items="schoolYears"
                        v-model="overallSchoolYearId"
                        hide-details
                    />
                </div>
            </template>
            <template v-slot:extra-tools>
                <fe-btn
                    usage="ghost"
                    useIcon="far fa-code-merge"
                    whiteText
                    @click="onMerge"
                    class="fe-grid-action-btn"
                >
                    Merge
                </fe-btn>
            </template>
            <template v-slot:cellDialog="{ cell }">
                <simple-grid-dialog-column
                    suppress-creation
                    v-if="showCellDialog && !creatingOpenWindow && !isDate"
                    ref="wnds"
                    :style="{ width: '750px' }"
                    height="400px"
                    :cell="cell"
                    title="Open Windows"
                    :crudConfig="$models.assessmentWindow"
                    :defaultParams="{
                        school_year_id: schoolYearId,
                        data_point_name_id: cell.data.id
                    }"
                    :columnDefs="windowColumnDefs"
                    @creating="doInsertion($event, cell.data)"
                    @close="closeDialog"
                    @persistCellDialog="persistCellDialog = $event"
                    @cellValueChanged="openWindowUpdated"
                >
                    <template v-slot:aboveGrid>
                        <div class="flex-shrink-1">
                            <label-field labelText="School Year" labelAlign="top">
                                <v-select
                                    flat solo dense
                                    :style="{ maxWidth: '300px' }"
                                    itemText="name"
                                    itemValue="id"
                                    :items="schoolYears"
                                    v-model="schoolYearId"
                                />
                            </label-field>
                        </div>
                    </template>
                    <template v-slot:belowGrid>
                        <div class="d-flex flex-shrink-1">
                            <fe-btn
                                style="margin-top: 10px;"
                                :disabled="false"
                                @click="creatingOpenWindow = true"
                            >Create Window</fe-btn>
                        </div>
                    </template>
                </simple-grid-dialog-column>
                <open-window-creator
                    v-else-if="showCellDialog"
                    :group="group"
                    :selectedSchoolYearId="schoolYearId"
                    :schoolYears="schoolYears"
                    :schools="schools"
                    :grades="grades"
                    :assessments="assessments"
                    :window="cell.data"
                    @cancel="creatingOpenWindow = false"
                    @close="$refs.grid.$refs.grid.openMenu = false"
                    @create="createOpenWindow"
                />
            </template>
        </fe-crud-grid>

        <fe-dialog
            v-if="isMerging"
            @close="isMerging = false"
            @dismiss="isMerging = false"
            @accept="doMerge"
            title="Merge Windows"
            acceptButtonText="Apply"
            dismissButtonText="Cancel"
            :acceptButtonDisabled="!isMergeValid"
        >
            <v-form @submit.prevent v-model="isMergeValid" ref="merge">
                <div class="d-flex flex-column">
                    <template v-if="assessments.length > 0">
                        <fe-label>Merge to Window</fe-label>
                        <v-select
                            v-model="mergeId"
                            flat solo dense
                            :items="mergeWindows"
                            itemText="name"
                            itemValue="id"
                            clearable
                            :rules="[(v) => !!v || 'Must select a window to merge to.']"
                        />
                    </template>
                </div>
            </v-form>
        </fe-dialog>

        <fe-crud
            ref="crudAssessments"
            autoload
            :config="$models.subcategory"
            :readParams="{ data_point_type_id: this.group.id }"
            refresh-on-config
            @read="assessments = $event"
        />

        <fe-crud
            ref="crudNorms"
            autoload
            :config="$models.dataPointName"
            :readParams="{
                    data_point_type_id: group.id,
                    property: 'norms'
            }"
            refresh-on-config
            @read="norms = $event"
        />

        <fe-crud
            ref="crudSchoolYears"
            autoload
            :config="$models.schoolYear"
            @read="schoolYears = $event"
        />

        <fe-crud
            ref="crudSchools"
            autoload
            :config="$models.school"
            @read="schools = $event"
        />

        <fe-crud
            v-if="!!schools"
            autoload
            ref="crudGrades"
            :config="$models.grade"
            :readParams="{
                    school_year_id: schoolYearId,
                    school_id: schools.map(x => x.id)
            }"
            refresh-on-config
            @read="grades = $event"
        />

        <fe-crud
            ref="crudAssessmentWindows"
            :config="$models.assessmentWindow"
            @created="openWindowCreated"
        />

    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import WindowCreator from "./WindowCreator"
    import OpenWindowCreator from "./OpenWindowCreator"
    import SimpleGridDialogColumn from "@/components/common/SimpleGridDialogColumn"
    import LabelField from "@/components/common/LabelField"

    export default {
        name: 'AssessmentGroupWindows',
        props: {
            group: { type: Object, default: () => {}, required: true },
            groups: { type: Array, default: () => [] },
            toAddWindowDates: { }
        },
        components: {
            SimpleGridDialogColumn,
            WindowCreator,
            OpenWindowCreator,
            LabelField
        },
        data: () => {
            return {
                selections: [],
                persistCellDialog: false,
                showCellDialog: false,
                aliases: null,
                cell: null,
                windows: null,
                norms: null,
                selectedOverallSchoolYearId: null,
                selectedSchoolYearId: null,
                schoolYears: null,
                schools: null,
                grades: null,
                assessments: null,
                creatingOpenWindow: false,
                isMerging: false,
                isMergeValid: false,
                mergeId: null,
                showMergeMask: false,
                isDate: false,
            }
        },
        computed: {
            ...mapState('global',['sessionUser']),
            selectedGroup: {
                get () { return this.group },
                set (v) { this.$emit('switchGroup', v) }
            },
            schoolYearId: {
                get () { return this.selectedSchoolYearId || this.sessionUser.currentYear.id },
                set (v) {
                    let curr = this.selectedSchoolYearId || this.sessionUser.currentYear.id
                    this.selectedSchoolYearId = v
                    if (this.selectedSchoolYearId !== curr) {
                        this.$nextTick(() => {
                            this.$refs.wnds.reload()
                        })
                    }
                }
            },
            overallSchoolYearId: {
                get() { return this.selectedOverallSchoolYearId || this.sessionUser.currentYear.id },
                set(v) {
                    this.selectedOverallSchoolYearId = v
                }
            },
            mergeWindows () { return this.windows.filter(w => !this.selections.find(itm => itm.id == w.id)) },
            valuesLoaded () { return !!this.windows && !!this.norms && !!this.schoolYears && !!this.schools && !!this.grades && !!this.assessments },
            columnDefs () {
                let me = this
                return !this.valuesLoaded ? [] : [
                    {
                        colId: "checkbox_column",
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        width: 70,
                        minWidth: 70,
                        pinned: "left"
                    },
                    {
                        headerName: "Window Name",
                        colId: "name",
                        field: "name",
                        sortable: true,
                        editable: true,
                        pinned: "left",
                        minWidth: 200
                    },
                    {
                        headerName: "Alias",
                        colId: "alias",
                        field: "alias",
                        sortable: true,
                        editable: true
                    },
                    {
                        headerName: "Import Alias",
                        colId: "import_alias",
                        field: "import_alias",
                        sortable: true,
                        editable: true,
                        hide: true
                    },
                    {
                        headerName: "Start Day",
                        colId: "start_day",
                        field: "start_day",
                        sortable: true,
                        editable: true,
                        width: 150,
                        cellRenderer(v) { 
                            return v.data.start_day 
                                ? v.data.start_day.includes('/') 
                                ? v.data.start_day
                                : v.data.start_day.slice(0, 2) + "/" + v.data.start_day.slice(2) 
                                : 'MM/DD' 
                        },
                        cellStyle(v) {
                            if(!v.data.start_day) {
                                return { 'color': 'gray' }
                            }
                        },
                        hide: me.toAddWindowDates ? false : true
                    },
                    {
                        headerName: "End Day",
                        colId: "end_day",
                        field: "end_day",
                        sortable: true,
                        editable: true,
                        hide: false,
                        width: 150,
                        cellRenderer(v) { 
                            return v.data.end_day 
                                ? v.data.end_day.includes('/') 
                                ? v.data.end_day
                                : v.data.end_day.slice(0, 2) + "/" + v.data.end_day.slice(2) 
                                : 'MM/DD' 
                        },
                        cellStyle(v) {
                            if(!v.data.end_day) {
                                return { 'color': 'gray' }
                            }
                        },
                        hide: me.toAddWindowDates ? false : true
                    },
                    {
                        headerName: "Store As",
                        colId: "store_as_data_point_name_id",
                        field: "store_as_data_point_name_id",
                        sortable: true,
                        editable: true,
                        cellRenderer(v) { return me.findLabel(me.windows, v.data.store_as_data_point_name_id, 'id', 'name') },
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: {
                            rowDataKey: "store_as_data_point_name_id",
                            mode: "edit",
                            items: me.windows,
                            keyProp: "id",
                            labelProp: "name",
                            disableChips: true,
                            excludeRowKey: 'id'
                        },
                        hide: true
                    },
                    {
                        headerName: "Order",
                        colId: "rank",
                        field: "rank",
                        sortable: true,
                        editable: true,
                        width: 100,
                    },
                    {
                        headerName: "Norms Name",
                        colId: "norms_data_point_name_id",
                        field: "norms_data_point_name_id",
                        sortable: true,
                        editable: true,
                        cellRenderer(v) { return me.findLabel(me.norms, v.data.norms_data_point_name_id, 'id', 'name') },
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: {
                            rowDataKey: "norms_data_point_name_id",
                            mode: "edit",
                            items: me.norms,
                            keyProp: "id",
                            labelProp: "name",
                            disableChips: true
                        },
                        hide: true
                    },
                    {
                        // Per UX, change from "Hidden" to "Visible"
                        // reverse toggle behavior to accommodate
                        headerName: "Visible",
                        colId: "hidden",
                        field: "hidden",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: 'FeGridToggle',
                        valueGetter: v => !v.data.hidden,
                        valueSetter: v => {
                            v.data.hidden = !v.newValue
                            return true
                        },
                    },
                    {
                        headerName: "Open Windows",
                        colId: "open_window_cnt",
                        field: "open_window_cnt",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: 'FeDialogColumn',
                        onCellClicked: (v) => {
                            this.cellClicked(v)
                        }
                    }
                ]
            },
            windowColumnDefs () {
                let me = this
                return !this.valuesLoaded ? [] : [
                    {
                        headerName: 'Name',
                        field: 'name',
                        sortable: true,
                        editable: true,
                    },
                    {
                        headerName: 'Assessment',
                        field: 'sub_category_id',
                        sortable: true,
                        editable: true,
                        cellRendererFramework: "FeGridChipSelect",
                        cellRendererParams: {
                            rowDataKey: "sub_category_id",
                            items: me.assessments,
                            keyProp: "id",
                            labelProp: "name",
                            multiple: true
                        },
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: {
                            rowDataKey: "sub_category_id",
                            mode: "edit",
                            items: me.assessments,
                            keyProp: "id",
                            labelProp: "name",
                            multiple: true,
                            elProps: {
                                menuProps: {
                                    zIndex: 10
                                }
                            }
                        }
                    },
                    {
                        headerName: 'School',
                        field: 'school_id',
                        sortable: true,
                        editable: true,
                        cellRendererFramework: "FeGridChipSelect",
                        cellRendererParams: {
                            rowDataKey: "school_id",
                            items: me.schools,
                            keyProp: "id",
                            labelProp: "name",
                            multiple: true
                        },
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: {
                            rowDataKey: "school_id",
                            mode: "edit",
                            items: me.schools,
                            keyProp: "id",
                            labelProp: "name",
                            multiple: true,
                            elProps: {
                                menuProps: {
                                    zIndex: 10
                                }
                            }
                        }
                    },
                    {
                        headerName: 'Grade',
                        field: 'grade_id',
                        sortable: true,
                        editable: true,
                        cellRendererFramework: "FeGridChipSelect",
                        cellRendererParams: {
                            rowDataKey: "grade_id",
                            items: me.grades,
                            keyProp: "id",
                            labelProp: "name",
                            multiple: true
                        },
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: {
                            rowDataKey: "grade_id",
                            mode: "edit",
                            items: me.grades,
                            keyProp: "id",
                            labelProp: "name",
                            multiple: true,
                            elProps: {
                                menuProps: {
                                    zIndex: 10
                                }
                            }
                        }
                    },
                    {
                        headerName: 'Start',
                        field: 'start_date',
                        sortable: true,
                        editable: true
                    },
                    {
                        headerName: 'End',
                        field: 'end_date',
                        sortable: true,
                        editable: true
                    }
                ]
            }
        },
        methods: {
            crudConfirmDelete(rsp, resolve, reject) {
                this.$confirmDelete([1], () => {
                    resolve()
                }, () => {
                    reject()
                }, rsp.data.msg)
            },
            openWindowUpdated (params) {
                params.crud.update(params.data)
            },
            doInsertion (item, parent) {
                let vals = Object.assign({}, item, {
                    school_year_id: this.schoolYearId,
                    data_point_name_id: parent.id
                })
                this.$refs.wnds.$refs.grid.insertRow({ vals: vals, focus: 'school_id' })
            },
            closeDialog () {
                this.showCellDialog = false
                this.updateWindowCount()
                this.$refs.grid.$refs.grid.openMenu = false
            },
            updateWindowCount() {
                if (this.overallSchoolYearId === this.schoolYearId) {
                    this.$nextTick(() => {
                        this.$refs.grid.$refs.crud.read()
                    })
                }
            },
            selectionChanged (params) {
                this.selections = params.api.getSelectedRows()
            },
            refreshCells() {
                this.showCellDialog = false
                this.updateWindowCount()
                this.$refs.grid.$refs.grid.gridApi.refreshCells()
            },
            cellClicked(cell) {
                this.cell = cell
                this.creatingOpenWindow = false
                this.selectedSchoolYearId = this.selectedOverallSchoolYearId || this.sessionUser.currentYear.id
                this.showCellDialog = true
                this.$refs.grid.$refs.grid.setDialogCell(cell, 'dialog')
            },
            findLabel(collection, value, valueProp, labelProp) {
                let lbl = ''
                if (collection && value) {
                    let sel = collection.find(x => x[valueProp] == value)
                    if (sel) lbl = sel[labelProp]
                }
                return lbl
            },
            create() {
                this.$emit('createWindow', this.group)
            },
            refresh() {
                if (this.$refs.grid) this.$refs.grid.$refs.crud.read()
            },
            createOpenWindow(obj) {
                this.$refs.crudAssessmentWindows.create(obj).then(rec => {
                    this.updateWindowCount()
                })
            },
            openWindowCreated(x) {
                this.creatingOpenWindow = false
            },
            onMerge() {
                this.isMerging = true
                this.mergeId = null
            },
            doMerge() {
                if (this.selections.length && this.$refs.merge.validate()) {
                    this.showMergeMask = true

                    let updates = this.selections.map(itm => ({
                        ...itm,
                        merge_to_data_point_name_id: this.mergeId
                    }))

                    this.$refs.grid.$refs.crud.update(updates).then(() => {
                        this.$refs.grid.$refs.crud.read()
                    }).finally(() => {
                        this.mergeId = null
                        this.isMerging = false
                        this.showMergeMask = false
                    })
                }
            },
            refreshCrudGridCells (ref='grid') {
                try {
                    this.$refs[ref].$refs.grid.gridApi.refreshCells()
                } catch (err) {
                    console.warn({ err: err })
                }
            },
            onUpdate(v) {
                if(v.colDef.colId == 'start_day') {
                    v.data.start_day = v.data.start_day.replace(/[/]/, '')
                    v.colDef.cellStyle = { 'color': 'rgba(0, 0, 0, 0.87)' }
                } else if (v.colDef.colId == 'end_day') {
                    v.data.end_day = v.data.end_day.replace(/[/]/, '')
                    v.colDef.cellStyle = { 'color': 'rgba(0, 0, 0, 0.87)' }
                } 
                this.$emit('reloadCurrent')
            }
        },
    }
</script>

<style lang="scss" >

</style>
