<template>
    <div>
        <fe-remote-combo
            url='qry.php?property=school_years'
            rootProperty='years'
            itemText="name"
            itemValue="id"
            :multiple="false"
            byId
            label='School Year'
            v-model="localValue.school_year_id"
            hide-details
        />

        <fe-remote-combo
            :items="categories"
            itemText="name"
            itemValue="id"
            :multiple="false"
            byId
            label='Data Type'
            v-model="localValue.category_id"
            hide-details
        />

        <div v-if="isAssessment">
            <fe-remote-combo
                :items="assessments"
                itemText="name"
                itemValue="id"
                :multiple="false"
                byId
                label='Assessment'
                v-model="localValue.data_point_type_id"
                hide-details
            />

            <fe-remote-combo
                :url="'subcategories.php?action=get&active=1&data_point_type_id='+localValue.data_point_type_id+`&category_id=`+localValue.category_id"
                itemText="name"
                itemValue="id"
                :multiple="false"
                rootProperty="subcategories"
                byId
                label='Focus Assessment'
                v-if="localValue.data_point_type_id"
                v-model="localValue.sub_category_id"
                hide-details
            />

            <fe-remote-combo
                :url="'dataPointName.php?action=get&active=1&data_point_type_id='+localValue.data_point_type_id"
                itemText="name"
                itemValue="id"
                :multiple="false"
                rootProperty="names"
                byId
                label='Window'
                v-if="localValue.data_point_type_id"
                v-model="localValue.data_point_name_id"
                hide-details
            />

            <fe-remote-combo
                :items="targetType"
                byId
                v-model="localValue.target_type_id"
                label="Performance Band Type"
                itemText="name"
                itemValue="id"
            />
        </div>

        <div v-if="isIncident">
            <fe-remote-combo
                :url="$models.getUrl('incidentBehaviorType', 'read')"
                rootProperty="incident_behavior_type"
                itemText="name"
                itemValue="id"
                :multiple="false"
                byId
                label='Incident Type'
                v-model="localValue.incident_behavior_type_id"
                hide-details
            />
        </div>

        <div v-if="isAttendance">
            <fe-remote-combo
                :items="attendanceOptions"
                itemText="name"
                itemValue="id"
                :multiple="false"
                byId
                label='Attendance Type'
                v-model="localValue.attendance_type_id"
                hide-details
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ScatterPlotDataSet',
        props: {
            value: {}
        },
        computed: {
            isAssessment() {
                if (!this.localValue.category_id) return false

                return ['incident', 'attendance'].indexOf(this.localValue.category_id) == -1 ? true : false
            },
            isIncident() {
                if (!this.localValue.category_id) return false

                return this.localValue.category_id == 'incident' ? true : false
            },
            isAttendance() {
                if (!this.localValue.category_id) return false

                return this.localValue.category_id == 'attendance' ? true : false
            }
        },
        watch: {
            value(v) {
                this.localValue = v
            },
            localValue(v) {
                this.$emit('input', v)
            }
        },
        data() {
            return {
                attendanceOptions: [{
                    id: 'affected_days_pct',
                    name: 'Full Day Rate'
                }, {
                    id: 'days_equivalent_pct',
                    name: 'SIS Reported Rate'
                }],
                targetType: [{
                    id: 1,
                    name: 'Detailed + Data Wall'
                }, {
                    id: 3,
                    name: 'Benchmark'
                }],
                localValue: {
                    target_type_id: undefined
                },
                categories: [],
                assessments: []
            }
        },
        created() {
            this.$modelGet('category').then(r => {
                this.categories = r.concat([{id: 'attendance', name: 'Attendance'}, {id: 'incident', name: 'Incidents' }])
            })

            this.$modelGet('assessment').then(r => this.assessments = r.filter(a=>!a.hidden))
        },
        mounted() {
            this.localValue = this.value
        }
    }
</script>

<style lang="scss" scoped>

</style>
