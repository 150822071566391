<template>
    <div>
        <button @click="dialog=true">{{ displayName }}</button>

        <v-dialog v-model="dialog" width="600">
            <fe-stepper-filter
                :title="title"
                style="height:600px;"
                :applyDisabled="applyDisabled"
                @apply="emit"
                @close="dialog=false"
            >
                <fe-stepper-select-panel :items="elItems" v-model="selection" width="50%"/>

                <v-divider vertical style="background-color:#E0E1E6"/>

                <fe-stepper-panel width="50%" style="padding:10px;">
                    <template v-if="selection">
                        <fe-remote-combo
                            label="School Year"
                            infoText="If a student was exited at any time during the year, they will be considered exited for the entire year."
                            v-model="school_year"
                            rootProperty="years"
                            url="qry.php?action=get&property=school_years"
                        />

                        <v-layout row class="pb-4">
                            <v-flex class="pr-2">
                                <v-switch v-model="restrict" label="Restrict Results" hide-details/>
                            </v-flex>

                            <v-flex>
                                <v-switch
                                    v-if="selection.id"
                                    v-model="require_all"
                                    label="Require All"
                                    hide-details
                                />
                            </v-flex>
                        </v-layout>

                        <fe-remote-combo
                            v-if="selection.id"
                            label="ELP Level"
                            v-model="elp_level"
                            rootProperty="ell_level"
                            :items="elp_levels"
                            itemText="name"
                        />

                        <fe-remote-combo
                            v-else
                            label="EL Status"
                            v-model="el_status"
                            :items="elStatusItems"
                        />
                    </template>
                </fe-stepper-panel>

                <template #footer>
                    <div class="text-truncate" style="padding:14px">{{ breadcrumb }}</div>
                </template>
            </fe-stepper-filter>
        </v-dialog>
    </div>
</template>

<script>
import * as Util from '../../Util'

export default {
    name: 'ELPanel',

    rank: 1,

    data() {
        return {
            oldFilter: null,
            displayName: 'English Learner',
            type: 'ell',
            dialog: false,
            elItems: [{
                id: 0,
                name: 'EL Status',
                key: 'el_status',
                filterParam: 'ell_flag'
            }, {
                id: 1,
                name: 'ELP Level',
                key: 'elp_level',
                filterParam: 'ell_level_id'
            }],
            elStatusItems: [{
                id: 0,
                name: 'No'
            }, {
                id: 1,
                name: 'Yes'
            }],
            selection: null,
            el_status: null,
            elp_level: null,
            elp_levels: [],
            require_all: false,
            restrict: true,
            school_year: null,
        }
    },

    mounted() {
        this.getEllLevels()
    },

    computed: {
        title: me => me.oldFilter ? `Edit ${me.displayName} Filter` : `Create ${me.displayName} Filter`,

        breadcrumb: me => me.selection?.display_name || me.selection?.name,

        applyDisabled() {
            return ((this.el_status === null) && (this.elp_level === null)) || this.school_year === null
        }
    },

    watch: {
        dialog(v) {
            if (!v) this.oldFilter = null
            v || this.clear()
        },
    },

    methods: {
        clear() {
            this.selection = null
            this.restrict = true
            this.el_status = null
            this.elp_level = null
            this.require_all = false
            this.school_year = null
        },

        load(oldFilter) {
            this.oldFilter = oldFilter
            this.selection = this.$_.find(this.elItems, z => z.key === oldFilter.type)
            this.dialog = true

            let ep = oldFilter.editorParams

            this.restrict = ep.restrict
            this.el_status = ep.el_status
            this.elp_level = ep.elp_level[0]
            this.require_all = ep.require_all
            this.school_year = ep.school_year
        },

        emit() {
            this.dialog = false
            let uniqueId = Util.UUIDv4()
            let name = this.selection.name
            let key = this.selection.key
            let filterParam = this.selection.filterParam
            let elp_level = [this.elp_level]

            // combine ids from singles and groups
            let ids = []
            if (filterParam === 'ell_level_id') {
                elp_level.forEach(m => {
                    if (!m.display_name_group) {
                        ids.push(m.id)
                    } else {
                        ids = ids.concat(m.display_name_group)
                    }
                    ids = ids.map(i => parseInt(i))
                })
            } else {
                ids = this.el_status.id
            }

            let params = this.$_.pickBy({
                [filterParam]: ids,
                require_all: this.require_all,
                school_year_id: this.school_year?.id,
            }, x => !this.$_.isBlank(x))

            let filter = {
                type: key,
                id: uniqueId,
                restrict: this.restrict,
                report: true,
                params
            }

            Object.defineProperties(filter, {
                editor: {value: this},
                editorParams: {
                    value: {
                        restrict: this.restrict,
                        el_status: this.el_status,
                        elp_level: elp_level,
                        require_all: this.require_all,
                        school_year: this.school_year ? {...this.school_year} : null,
                    }
                },
                grouper: {
                    value: {
                        id: uniqueId,
                        name: name,
                        type: 'propertyArray',
                        args: {path: `filters.${uniqueId}`, property: 'value'},
                    }
                },
                chipText: {value: name},
                tooltipData: {
                    value: this.$_.pickBy({
                        [name]: this[key].name,
                        'Restrict': this.restrict,
                        'Require All': this.require_all,
                        'School Year': this.school_year?.name,
                    }, x => !this.$_.isBlank(x))
                }
            })

            this.$emit('input', filter, this.oldFilter)
        },

        getEllLevels() {
            this.$axios.get(this.$models.getUrl('ellLevel', 'read'))
                .then((response) => {
                    if (response && response.data) {
                        let data = response.data.ell_level
                        let filtered = []
                        // if available, use display_name
                        data.forEach(itm => {
                            if (!itm.display_name_group) {
                                itm.name = itm.display_name || itm.name
                                filtered.push(itm)
                            } else {
                                let o = data.find(x => x.id == itm.display_name_group[0]) // find and return first option within display_name_group; id can be string
                                if (!filtered.find(x => x.display_name === o.display_name)) {
                                    o = {
                                        ...o,
                                        name: o.display_name || o.name // use display_name instead of value
                                    }
                                    filtered.push(o)
                                }
                            }
                        })
                        this.elp_levels = filtered
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
