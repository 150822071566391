<template>
    <div class="d-flex flex-column fill-flex">
        <div class="headline mb-1 d-flex">
            Participation Types
            <fe-info-tip
                class="ml-2"
                tooltip="Create the participation status codes to be applied to the student's daily intervention attendance"
            />
        </div>

        <fe-dialog width="500"
            v-model="createDialog.show"
            v-if="createDialog.show"
            title="Add Participation Type"
            :footer="false"
            @close="createDialog.show=false"
            persistent
        >
                <model-form
                    idProperty="id"
                    @cancel="finishCreate"
                    @done="finishCreate"
                    :readParams="{active: 1}"
                    :records="createDialog.records"
                    model="interventionAttendanceType"
                    :fieldConfig="fieldConfig"
                    submitButtonText="Save"
                />
        </fe-dialog>

        <fe-crud-grid
            :autoload="false"
            :config="$models.interventionAttendanceType"
            :frameworkComponents="frameworkComponents"
            @read="v => types = v"
            @beginCreate="startCreate"
            ref="grid"
            disableInlineCreate
            domLayout="autoHeight"
            :columnDefs="columns"
            :rowData="types"
            displayDensity="medium"
        />

    </div>
</template>

<script>
    import NumericEditor from '@/components/common/grid/NumericEditor.js'
    import CellSelect from '@/components/common/grid/CellSelect.js'
    import ModelForm from '@/components/common/form/ModelForm'

    export default {
        name: "InterventionParticipationType",
        components: {
            ModelForm
        },
        data() {
            let me = this
            return {
                types: [],
                codes: [],
                frameworkComponents: {
                    numericEditor: NumericEditor,
                    cellSelect: CellSelect
                },
                createDialog: {
                    show: false,
                    records: []
                },
                columns: [],
            }
        },
        mounted() {
            this.$modelGet('interventionFidelityCode')
                .then(data => {
                    this.codes = data

                    let me = this
                    this.columns = [
                        this.$grid.checkColumn(),
                        {
                            headerName: 'Name',
                            field: 'name',
                            flex: 1,
                            editable: true
                        }, {
                            headerName: 'Fidelity Status',
                            flex: 1,
                            editable: true,
                            cellEditorFramework: 'FeGridChipSelect',
                            cellEditorParams: v => ({
                                value: v.data.fidelity_code,
                                items: me.codes,
                                keyProp: "code",
                                labelProp: "name",
                                mode: 'edit',
                                disableChips: true
                            }),
                            valueGetter: (v) => this.codes.find(r => r.code === v.data.fidelity_code)?.name ?? '',
                            valueSetter: (v) => {
                                v.data.fidelity_code = v.newValue
                                return true
                            },
                        }, {
                            headerName: 'Order',
                            field: 'rank',
                            maxWidth: 100,
                            cellEditor: 'numericEditor',
                            editable: true,
                            cellStyle: {textAlign: 'right'}
                        }, this.$grid.toggleColumn('Active', 'active', null, () => {
                    })]

                    this.$refs.grid.$refs.crud.read()
                })
        },
        computed: {
            fieldConfig() {
                let me = this
                return [{
                    field: 'name',
                    label: 'Status Name',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { required: true, limit: 50 },
                    width: '100%',
                    attrs: {
                        required: true,
                        flat: true,
                        solo: true,
                        dense: true,
                        counter: 50
                    }
                }, {
                    field: 'fidelity_code',
                    label: 'Fidelity Status',
                    component: 'fe-remote-combo',
                    validatorKey: 'select',
                    validatorConfig: { required: true },
                    width: '100%',
                    attrs: {
                        byId: true,
                        items: me.codes,
                        itemText: 'name',
                        itemValue: 'code'
                    }
                // }, {
                //     field: 'rank',
                //     label: 'Rank',
                //     component: 'v-text-field',
                //     validatorKey: 'number',
                //     validatorConfig: { required: true, min: 0, max: 99 },
                //     width: '100%',
                //     attrs: {
                //         required: true,
                //         flat: true,
                //         solo: true,
                //         dense: true
                //     }
                }, {
                    field: 'active',
                    label: 'Active',
                    component: 'fe-switch'
                }]
            },
        //     columns() {
        //         let me = this
        //         return [this.$grid.checkColumn(), {
        //             headerName: 'Name',
        //             field: 'name',
        //             flex: 1,
        //             editable: true
        //         }, {
        //             headerName: 'Fidelity Status',
        //             field: 'fidelity_code',
        //             flex: 1,
        //             editable: true,
        //             cellEditor: 'cellSelect',
        //             cellEditorParams: {
        //                 items: me.codes,
        //                 rowDataKey: "fidelity_code",
        //                 itemValue: "code",
        //                 itemText: "name"
        //             },
        //             cellRenderer(v) {
        //                 let rec = me.codes.find(r => { return r.code === v.value })
        //                 return rec ? rec.name : ''
        //             }
        //         }, {
        //             headerName: 'Order',
        //             field: 'rank',
        //             maxWidth: 100,
        //             cellEditor: 'numericEditor',
        //             editable: true,
        //             cellStyle: { textAlign: 'right' }
        //         }, this.$grid.toggleColumn('Active', 'active', null, () => { })]
        //     }
        },
        methods: {
            finishCreate() {
                this.createDialog.show = false
                this.createDialog.records = [{
                    active: true
                }]

                this.$refs.grid.$refs.crud.read()
            },
            startCreate() {
                this.createDialog.show=true
                this.createDialog.records = [{
                    rank: this.types.length,
                    active: true
                }]
            },
            updateCrud(crud, meta) {

            },
            crudConfirmDelete() {

            }
        }
    }
</script>

<style scoped lang="scss">

</style>
