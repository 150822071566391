<template>
    <div class="d-flex flex-fill flex-column">
        <div class="d-flex flex-shrink-1">
            <template v-for="type in selectionTypes">
                <fe-filter-btn
                    :ref="`filter_${type}`"
                    :key="`filter-${type}`"
                    :title="selections[type].title"
                    :items="selections[type].available"
                    :itemValue="selections[type].field.id"
                    :itemText="selections[type].field.name"
                    v-model="selections[type].filtered"
                    :multiple="!!selections[type].multiple"
                    :disabled="!selections[type].available.length"
                    :actions="true"
                    showFirstSelection
                    :closeOnSelect="false"
                ></fe-filter-btn>
            </template>
        </div>
        <div class="d-flex flex-column flex-grow-1 pa-2" style="min-height: 250px;">
            <template v-if="!anyPending && results">
                <template v-for="cat in results">
                    <div
                        v-if="showCategory(cat)"
                        :key="`category-pane-${cat.ukey}`"
                        class="d-flex flex-wrap pb-5 align-start justify-space-between"
                    >
                        <h2 style="width: 100%;" class="px-5">{{ cat.name }}</h2>
                        <div
                            v-for="(scat, scatIdx) in cat.subcategories"
                            :key="`subcategory-pane-${cat.ukey}-${scatIdx}`"
                            class="d-flex ma-5 flex-grow-1"
                            :style="{
                                'width': '45%',
                                'min-width': '500px',
                                'border': 'solid 1px #eee',
                                'border-radius': '3px'
                            }"
                        >
                            <assessment-trendline
                                :params="chartParams(scat)"
                                :height="400"
                                :style="{ width: '100%' }"
                                hide-subcategories
                                showAssessmentHistory
                            />
                        </div>
                    </div>
                </template>
            </template>
        </div>

        <fe-crud
            autoload
            :config="$models.category"
            @read="loadItems('categories', $event)"
        />

        <fe-crud
            autoload
            :config="$models.schoolYear"
            @read="loadItems('schoolYears', $event)"
        />

        <fe-crud
            autoload
            :config="$models.assessment"
            :defaultParams="{ hidden: 0 }"
            @read="loadItems('assessments', $event)"
        />

        <fe-crud
            v-if="!anyPending"
            autoload
            :config="$models.studentScores"
            :readParams="searchParams"
            @read="handleResults"
            refresh-on-config
        />

    </div>
</template>

<script>
    import AssessmentTrendline from '@/components/charts/AssessmentTrendline'

    export default {
        name: 'HistoricalScores',
        props: {
            student: { type: Object, required: true },
            category: { type: Object }
        },
        components: {
            AssessmentTrendline
        },
        data () {
            return {
                results: null,
                selections: {
                    categories: {
                        field: { id: 'id', name: 'name' },
                        title: 'All Categories',
                        available: [],
                        filtered: { included: [], excluded: [] },
                        pending: true,
                        multiple: true,
                        filterKey: 'category_id'
                    },
                    schoolYears: {
                        field: { id: 'id', name: 'name' },
                        title: 'All School Years',
                        available: [],
                        filtered: { included: [], excluded: [] },
                        pending: true,
                        multiple: true,
                        filterKey: 'school_year_id'
                    },
                    assessments: {
                        field: { id: 'id', name: 'name' },
                        title: 'All Assessment Groups',
                        available: [],
                        filtered: { included: [], excluded: [] },
                        pending: true,
                        multiple: true,
                        filterKey: 'data_point_type_id'
                    }
                }
            }
        },
        computed: {
            selectionTypes () { return Object.keys(this.selections) },
            anyPending () { return !!this.selectionTypes.filter(type => this.selections[type].pending).length },
            studentId () { return this.student.student_id },
            selectedYearId () { return this.student.school_year_id },
            searchParams () {
                let obj = {
                    student_id: this.studentId,
                    target_type_id: 1,
                    property: 'parent'
                }
                this.selectionTypes.forEach(type => {
                    let val = this.selectionVal(type)
                    if (val) obj[this.selections[type].filterKey] = val
                })

                return obj
            }
        },
        methods: {
            handleResults (rsp) {
                let now = Date.now()
                this.results = rsp.map((itm, idx) => {
                    return Object.assign(itm, {
                        ukey: `${now}-${idx}`
                    })
                })
            },
            selectionVal (type) {
                return (this.selections[type].filtered.included.length)
                    ? this.selections[type].filtered.included.map(itm => {
                        return itm[this.selections[type].field.id]
                    }).join(',')
                    : null
            },
            clearFilter (filter) {
                this.selections[filter].filtered = { included: [], excluded: [] }
                this.$refs[`filter_${filter}`][0].clear()
            },
            loadItems (type, data) {
                let sel = null
                if (type == 'categories' && this.category) sel = data.find(itm => itm.id == this.category.id)
                this.selections[type].available = data
                this.selections[type].pending = false
                if (sel) this.$nextTick(() => { this.$refs[`filter_${type}`][0].selectItem(sel, true) })
            },
            chartParams (subcategory) {
                return Object.assign({}, this.searchParams, {
                    sub_category_id: subcategory.id
                })
            },
            showCategory (category) {
                return (this.selections.categories.filtered.included.length == 0 ||
                    this.selections.categories.filtered.included.find(itm => itm.id == category.id))
            }
        },
        mounted() { },
        watch: {
            searchParams (v) {
                this.results = null
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>