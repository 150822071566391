var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-fill flex-column"},[_c('div',{staticClass:"headline mb-3 flex-shrink-1"},[_vm._v(" Score Details "),_c('div',{staticClass:"title-tip-adj"},[_c('fe-info-tip',{attrs:{"tooltip":"Score Details provide additional information to the main score displayed and are considered metadata that can be used to further analyze assessment data.  These score details can be used to create thresholds, appear in data walls and color scores in performance bands."}})],1)]),_c('div',{staticClass:"flex-shrink-1"},[(_vm.groups.length > 0 && _vm.selectedGroup)?_c('fe-title-select',{attrs:{"placeholder":"Select an Assessment Group","items":_vm.groups},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}}):_vm._e()],1),_c('fe-crud-grid',{ref:"grid",staticStyle:{"height":"100%"},attrs:{"config":_vm.$models.scoreDetailType,"columnDefs":_vm.columnDefs,"insertDefaults":{
            data_point_type_id: _vm.group.id
        },"readParams":{
            data_point_type_id: _vm.group.id,
            with_option_count: 1,
            with_sub_category_count: 1,
            with_alias_count: 1,
            order: 'rank'
        },"refresh-on-created":"","refresh-on-config":"","refresh-on-destroyed":"","disable-inline-create":"","flexColumns":false,"failOnEmptyResponse":false},on:{"beginCreate":function($event){_vm.adding = true},"error":_vm.onGridError},scopedSlots:_vm._u([{key:"cellDialog",fn:function({ cell }){return [(cell.column.colId == 'sub_category_cnt')?_c('simple-grid-dialog-column',{ref:"scs",attrs:{"height":"300px","cell":cell,"title":"Filter Score Detail","crudConfig":_vm.$models.scoreDetailTypeSubCategory,"defaultParams":{
                    score_detail_type_id: cell.data.id
                },"insertDefaults":{
                    score_detail_type_id: cell.data.id
                },"columnDefs":_vm.subCategoryColumnDefs,"buildInsertRec":_vm.buildSubCategory},on:{"close":function($event){_vm.$refs.grid.$refs.grid.openMenu = false},"read":_vm.updateSubCategoryCount},scopedSlots:_vm._u([{key:"belowGrid",fn:function(){return [_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"flex-grow-1"},[_c('v-select',{ref:"subCategoryId",staticStyle:{"margin-top":"6px","width":"260px"},attrs:{"flat":"","solo":"","dense":"","items":_vm.assessments,"item-text":"name","item-value":"id","placeholder":"Add an Assessment"},model:{value:(_vm.addSubCategoryId),callback:function ($$v) {_vm.addSubCategoryId=$$v},expression:"addSubCategoryId"}})],1),_c('div',{staticClass:"flex-shrink-1 text-xs-right"},[_c('fe-btn',{attrs:{"disabled":!_vm.addSubCategoryId},on:{"click":_vm.addSubCategory}},[_vm._v("Add")])],1)])]},proxy:true}],null,true)}):(cell.column.colId == 'option_cnt')?_c('simple-grid-dialog-column',{ref:"optss",attrs:{"height":"300px","cell":cell,"title":"Score Detail Options","field":"value","fieldHeader":"Detail Options","fieldPlaceholder":"Add a Detail Option","fieldCounter":255,"crudConfig":_vm.$models.scoreDetailTypeOption,"defaultParams":{
                    score_detail_type_id: cell.data.id
                },"insertDefaults":{
                    score_detail_type_id: cell.data.id,
                    numeric_value: cell.data.option_cnt,
                    rank: cell.data.option_cnt,
                },"columnDefs":_vm.optionColumnDefs},on:{"close":function($event){_vm.$refs.grid.$refs.grid.openMenu = false},"read":_vm.updateOptionCount,"cellValueChanged":_vm.optionUpdated}}):(cell.column.colId == 'alias_cnt')?_c('simple-grid-dialog-column',{ref:"aliases",attrs:{"height":"300px","cell":cell,"title":"Score Detail Aliases","field":"alias","fieldHeader":"Alias","fieldPlaceholder":"Add an Alias","fieldCounter":255,"crudConfig":_vm.$models.scoreDetailTypeAlias,"readParams":{
                    score_detail_type_id: cell.data.id
                },"insertDefaults":{
                    data_point_type_id: _vm.group.id,
                    score_detail_type_id: cell.data.id
                }},on:{"close":function($event){_vm.$refs.grid.$refs.grid.openMenu = false},"read":_vm.updateAliasCount}}):_vm._e()]}}])}),_c('fe-crud',{ref:"crudAssessments",attrs:{"autoload":"","config":_vm.$models.subcategory,"readParams":{ data_point_type_id: _vm.group.id },"refresh-on-config":""},on:{"read":function($event){_vm.assessments = $event}}}),_c('fe-crud',{ref:"crudWindows",attrs:{"autoload":"","config":_vm.$models.dataPointName,"readParams":{ data_point_type_id: this.group.id },"refresh-on-config":""},on:{"read":function($event){_vm.windows = $event}}}),_c('fe-crud',{ref:"crudProjectionTypes",attrs:{"autoload":"","config":_vm.$models.projectionType},on:{"read":function($event){_vm.projectionTypes = $event}}}),(_vm.adding)?_c('fe-dialog',{attrs:{"title":"Add Score Detail","acceptButtonText":"Save","dismissButtonText":"Cancel","acceptButtonDisabled":!_vm.valid,"persistent":""},on:{"close":function($event){_vm.adding = false},"dismiss":function($event){_vm.adding = false},"accept":_vm.add}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('fe-label',[_vm._v("Detail Name")]),_c('v-text-field',{style:({ width: '100%' }),attrs:{"flat":"","solo":"","dense":"","rules":_vm.rules.code},model:{value:(_vm.obj.code),callback:function ($$v) {_vm.$set(_vm.obj, "code", $$v)},expression:"obj.code"}}),_c('fe-label',[_vm._v("Description")]),_c('v-text-field',{style:({ width: '100%' }),attrs:{"flat":"","solo":"","dense":"","rules":_vm.rules.description},model:{value:(_vm.obj.description),callback:function ($$v) {_vm.$set(_vm.obj, "description", $$v)},expression:"obj.description"}})],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }