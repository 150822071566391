import Vue from "vue";
import {mapState} from "vuex"

export default Vue.extend({
    template: `<div>
                    <div v-if="!showAssignees" title="names" class="action-items-assignee-column-assignee-cell" @click="showAssignees = !showAssignees">
                        <template v-for="(item, index) in assignees">
                            <fe-tooltip :tooltip="item.user_full_name" nudge-bottom="30">
                                <img v-if="item.photo_url && index < 3" :src="item.photo_url" class="action-items-assignee-column-ap-avatar" :style="getStyle(item)">
                                <div v-else-if="!item.photo_url && index < 3" :style="getStyle(item)"
                                    class="action-items-assignee-column-ap-avatar title action-items-assignee-column-ap-avatar-letter"
                                >
                                    {{ item.user_fname[0].toUpperCase() }}
                                </div>
                            </fe-tooltip>
                        </template>
                        <div v-if="more" class="action-items-assignee-column-ap-more">+ {{ more }} more</div>
                    </div>
                    <div v-else>
                        <v-menu
                            ref="searchMenu"
                            offset-y
                            max-height="200"
                            min-width="195"
                            max-width="195"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field v-on="on" width="100%" ref="searchAssignees" v-model="searchText"
                                    flat solo dense hide-details :clearable="false" @blur="onBlurSearch"
                                    placeholder="Type to search for an assignee" class="action-items-assignee-column-custom"
                                />
                            </template>
                            <v-list>
                                <v-list-item v-for="user in filteredUsers" @mousedown="assigneeSelected(user)" :key="user.id" style="cursor: pointer;">
                                    <img v-if="user.photo_url" :src="user.photo_url" class="action-items-assignee-column-ap-avatar">
                                    <div v-else class="action-items-assignee-column-ap-avatar title action-items-assignee-column-ap-avatar-letter">
                                        {{ user.user_full_name[0].toUpperCase() }}
                                    </div>
                                    <div style="position: relative; left: 30px;">{{ user.user_full_name }}</div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
               </div>`,
    data: function () {
        return {
            showAssignees: false,
            filteredUsers: [],
            names: '',
            more: 0,
            addAssignee: null,
            assignees: [],
            searchText: '',
            availableUsers: [],
        }
    },
    computed: {
        ...mapState('global', ['currentYear', 'sessionUser']),
    },
    watch: {
        availableUsers: {
            deep: true,
            handler(v) {
                this.filteredUsers = v
            }
        },
        searchText(v) {
            if(v.length) {
                this.filteredUsers = this.availableUsers.filter(x => x.user_full_name.toLowerCase().includes(v.toLowerCase()))
            } else {
                this.filteredUsers = this.availableUsers
            }
        },
        showAssignees(v) {
            if(v) {
                this.availableUsers = this.$differenceByKey(this.params.users, this.params.data.assignees, 'id', 'user_id')
                // small timeout to make sure $refs are mounted
                setTimeout(function() {
                    this.$refs.searchAssignees.$refs.input.focus()
                    this.$refs.searchMenu.isActive = true
                }.bind(this), 50)
            }
        },
    },
    mounted() {
        this.render()
    },
    methods: {
        render() {
            this.assignees = this.$_.cloneDeep(this.params.data.assignees)
            this.more = 0
            this.names = this.assignees.map(x => x.user_full_name).join('&NewLine;')
            this.assignees.forEach((item, index) => {
                if (index < 3) {
                    item.leftOffset = (index * 15)
                } else {
                    this.more++
                }
            })
        },
        assigneeSelected(data) {
            // mousedown as event as click does not work with blur
            if (data) {
                this.params.context.componentParent.addAssignees = data
                this.params.context.componentParent.selected = this.params
           }
        },
        getStyle(item) {
            return 'left: ' + item.leftOffset + "px"
        },
        onBlurSearch() {
            this.searchText = ''
            // small timeout between list item click and close of menu
            setTimeout(function() {
                this.showAssignees = false
            }.bind(this), 50)
        },
    }
});
