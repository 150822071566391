<template>
    <div class="flex-column" :style="style">
        <v-btn
            depressed
            @click="$emit('input',group)"
            class="round v-btn--round ma-0"
            :color="meta.color"
        >
            <v-badge
                v-if="meta.unread"
                dot bordered
                color="primary"
                class="badge-offset"
                :content="meta.unread"
            />
            <span v-if="meta.stack" class="fa-stack">
                <i class="fa-stack-2x" :class="meta.icon"/>
                <i class="fa-stack-1x" :class="meta.stack"/>
            </span>
            <v-icon v-else dark small :color="iconColor">
                {{meta.icon}}
            </v-icon>
        </v-btn>
        <!-- <fe-label>{{meta.name}}<span v-if="meta.unread" class="primary--text"> ({{meta.unread}})</span></fe-label> -->
        <fe-label>{{meta.name}}</fe-label>
    </div>
</template>

<script>
export default {
    name: 'GroupButton',
    props: {
        value: String,
        group: String,
        meta: Object,
    },
    computed: {
        iconColor: me => '#152452',
        style: me => ({
            paddingBottom: '4px',
            textAlign: 'center',
            borderRadius: '1.5px',
            borderBottom: me.group == me.value ? `3px solid ${me.iconColor}` : 'none',
        }),
    },
    methods: {
    },
    data() {
        return {
        }
    }
}
</script>

<style lang="scss" scoped>
.fa-stack {
    font-size: .6em;
    margin-right: 1px;
    color: #152452;
}
.fa-stack-1x {
    padding-top: 2px;
}
button.v-btn.round[type='button'] {
    border-radius: 50%;
    // border: 2px solid;
    min-width: 38px;
    min-height: 38px;
}
.badge-offset {
    position: absolute;
    right: 8px;
    top: 0px;
}
</style>
