<template>
    <div class="flex-fill flex-column no-scroll pb-10">
        <div class="headline mb-1 d-flex">
            {{ title }}
        </div>

        <v-dialog width="500" v-model="createDialog.show" v-if="createDialog.show" persistent>
            <v-card>
                <v-card-title>
                    Create Strategy
                </v-card-title>
                <v-card-text>
                    <model-form
                        idProperty="id"
                        @cancel="finishCreate"
                        @done="finishCreate"
                        :readParams="{active: 1}"
                        :records="createDialog.records"
                        model="interventionType"
                        :fieldConfig="fieldConfig"
                        submitButtonText="Save"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <div class="d-flex flex-fill">
            <fe-action-list
                itemText="name"
                ref="list"
                :titleWidth="300"
                :items="items"
                @rowClick="doSelect"
                :showFiltering="false"
                :show-search="true"
                :editButton="false"
                @addButtonClick="startCreate"
            >
                <template #beforeButtons>
                    <v-checkbox v-model="showInactive" class="mt-3" label="Show Inactive Strategies"/>
                </template>
            </fe-action-list>
        </div>
    </div>
</template>

<script>
    import ModelForm from '@/components/common/form/ModelForm'

    export default {
        name: 'InterventionTypeList',
        props: ['type', 'title'],
        components: {
            ModelForm
        },
        data() {
            return {
                items: [],
                categories: [],
                createDialog: {
                    show: false,
                    records: []
                },
                showInactive: false
            }
        },
        watch: {
            showInactive(v) {
                this.loadData()
            }
        },
        computed: {
            fieldConfig() {
                let me = this
                let arr = []

                if (!this.isBehavior) {
                    arr.push({
                        component: 'v-select',
                        field: 'category_id',
                        label: 'Skill Area',
                        width: '100%',
                        attrs: {
                            items: me.categories.filter(rec => rec.id  !== 4),
                            itemText: 'name',
                            itemValue: 'id',
                            flat: true,
                            solo: true,
                            dense: true
                        }
                    })
                }

                arr.push({
                    component: 'v-text-field',
                    field: 'name',
                    label: 'Strategy Name',
                    width: '100%',
                    validatorKey: 'text',
                    validatorConfig: { limit: 50, required: true },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true
                    }
                }, {
                    component: 'v-textarea',
                    field: 'description',
                    label: 'Description',
                    width: '100%',
                    validatorKey: 'text',
                    validatorConfig: { limit: 255, required: false },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true
                    }
                })

                return arr
            },
            isBehavior() {
                return this.$props.type === 'academic' ? false : true
            }
        },
        methods: {
            doSelect(item) {
                this.$emit('go', {
                    show: 'intervention-strategy',
                    name: item.name
                }, { id: item.id, type: this.$props.type })
            },
            startCreate() {
                let r = {
                    active: true,
                    category_id: this.isBehavior ? 4 : null
                }

                this.createDialog.records = [r]
                this.createDialog.show = true
            },
            finishCreate() {
                this.createDialog.records = []
                this.createDialog.show = false

                this.loadData()
            },
            loadData() {
                let p = { summary: 1, active: !this.showInactive ? 1 : null }
                if (this.$props.type === 'academic') {
                    p.type = 'academic'
                } else {
                    p.type = 'behavior'
                }

                this.$modelGet('interventionType', p).then(response => {
                    this.items = response.filter(rec => rec.name)

                    this.items.forEach(item => {
                        item.icons = []

                        if (!this.$_.isEmpty(item.schools)) {
                            item.icons.push({
                                icon: 'far fa-university',
                                tooltip: 'Restricted to ' + item.schools.length + ' school'+ this.$addS(item.schools),
                                data: item.schools
                            })
                        }

                        if (!this.$_.isEmpty(item.forms)) {
                            item.icons.push({
                                icon: 'fas fa-file',
                                tooltip: 'Has ' + item.forms.length + ' default smartFORM'+ this.$addS(item.forms),
                                data: item.forms
                            })
                        }

                        if (!this.$_.isEmpty(item.attachments)) {
                            item.icons.push({
                                icon: 'fas fa-paperclip',
                                tooltip: 'Has ' + item.attachments.length + ' attachment'+ this.$addS(item.attachments),
                                data: item.forattachmentsms
                            })
                        }

                        item.chips = []
                        if (item.category_id && item.category_id != 4) {
                            item.chips.push({
                                name: this.categories.find(rec => rec.id == item.category_id).name
                            })
                        }
                    })
                })
            }
        },
        mounted() {
            this.$modelGet('category').then(v => this.categories = v)
                .finally(() => {
                    this.loadData()
                })

        }
    }
</script>

<style lang="scss" scoped>

</style>
