<template>
    <v-form v-model="planValid">
        <fe-label>Intervention Group Label</fe-label>
        <v-text-field flat solo dense
            v-model="plan.intervention_plan_name"
            :rules="$fieldValidators('text', null, { required: true, limit: 255})"
            :counter="255"
        />

        <fe-remote-combo
            :label="featureFlagMultipleInterventionists ? 'Primary Interventionist' : 'Interventionist'"
            itemValue="id"
            itemText="user_full_name"
            :items="interventionists"
            :multiple="false"
            byId
            ref="interventionist"
            v-model="plan.intervention_plan_user_id"
            :rules="$fieldValidators('select', null, { required: true })"
            @change="changePrimaryInterventionist"
        />
        <a
            v-if="featureFlagMultipleInterventionists && !showAdditionalInterventionsts"
            @click="showAdditionalInterventionsts = true"
            style="position:relative;left:20px;top:-15px;"
        >
            <v-icon size="12" color="#006c90" style="position:relative;top:-2px;padding-right:10px;">fas fa-plus</v-icon> Add Interventionists
        </a>

        <div v-if="featureFlagMultipleInterventionists && showAdditionalInterventionsts">
            <fe-label>
                Additional Interventionists (Optional)
                <fe-info-tip
                    class="ml-2"
                    style="display:inline-block;"
                    tooltip="You can add up to 5 additional interventionists."
                />
            </fe-label>
            <fe-remote-combo
                v-model="plan.secondary_interventionists"
                itemText="user_full_name"
                itemValue="id"
                byId
                chips multiple
                :clearable="false"
                :items="filteredSecondaryInterventionists"
                :selectAllOption="false"
                :rules="secondaryInterventionistValidation"
            />
        </div>

        <fe-remote-combo
            label="Level"
            :url="$models.getUrl('interventionLevel', 'read')+'&active=1'"
            rootProperty="intervention_levels"
            itemValue="id"
            itemText="name"
            :multiple="false"
            byId
            ref="interventionLevel"
            v-model="plan.intervention_level_id"
            :rules="$fieldValidators('select', null, { required: true })"
        />

        <fe-remote-combo
            label="Strategy"
            :items="strategies"
            :multiple="false"
            byId
            ref="intervetionStrategy"
            v-model="plan.intervention_type_id"
            :rules="$fieldValidators('select', null, { required: true })"
        />

        <fe-label>Phase Name</fe-label>
        <v-text-field flat solo dense
            v-model="plan.intervention_plan_phase_name"
            :rules="$fieldValidators('text', null, {limit: 255})"
            :counter="255"
        />

        <div style="font-size: 12px; font-weight: bold;">
            <v-icon>fal fa-info-circle</v-icon> This will update the current phase name.  This will not add a phase.
        </div>

        <div class="d-flex justify-end">
            <fe-btn usage="primary" @click="modifyPlan" :disabled="!planValid">Modify</fe-btn>
        </div>
    </v-form>
</template>

<script>
    export default {
        name: 'PlanDetails',
        props: {
            value: {
                default: () => { return {} }
            },
            interventionPlanId: {
                required: true
            },
            showTools: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            categoryId() {
                return this.plan.category_id || ''
            },
            filteredSecondaryInterventionists() {
                // don't show the primary interventionist
                const primaryInterventionist = this.plan.intervention_plan_user_id
                return this.interventionists.filter(interventionist => interventionist.id != primaryInterventionist)
            },
            secondaryInterventionistValidation: () => [
                v => v.length <= 5 || 'You can add up to 5 secondary interventionists.'
            ],
            featureFlagMultipleInterventionists () {
                return this.$store.getters['flags/flags']['ec-multiple-interventionists'] === true
            },
        },
        data() {
            return {
                plan: {},
                planValid: false,
                showAdditionalInterventionsts: false,
                interventionists: [],
                strategies: []
            }
        },
        watch: {
            value(v) {
                this.plan = {...v}
            },
            planValid(v) {
                this.$emit('valid', v)
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.$modelGet('interventionPlanStudent', { intervention_plan_id: this.interventionPlanId }).then(r => {
                    if (r.length) {
                        this.plan = {
                            intervention_plan_id: r[0].intervention_plan_id,
                            intervention_plan_name: r[0].intervention_plan_name,
                            intervention_plan_user_id: r[0].intervention_plan_user_id,
                            secondary_interventionists: r[0].interventionists.slice(1).map(interventionist => interventionist.user_id),
                            intervention_type_id: r[0].intervention_type_id,
                            intervention_level_id: r[0].intervention_level_id,
                            intervention_plan_phase_name: r[0].intervention_plan_phase_name
                        }

                        // show additional interventionists if any are selected
                        this.showAdditionalInterventionsts = this.plan.secondary_interventionists.length > 0

                        this.$modelGet('interventionType', { active: 1, category_id: r[0].category_id })
                            .then(s => {
                                this.strategies = s
                            })

                        // load interventionists
                        this.$axios.get(this.$models.getUrl('user', 'read') + '&active=1')
                            .then(response => {
                                this.$data.interventionists = response.data
                            })
                    } else {
                        this.plan = {}
                    }
                })
            },
            modifyPlan() {
                this.$messageBox({
                    title: 'Override Plan?',
                    persistent: true,
                    maxWidth: 600,
                    message: 'Saving this record will update the entire intervetion plan record for all students.  If you are trying to create a new phase, select <b>New Phase</b> from the previous menu.',
                    actions: [{
                        text: 'Cancel', primary: false
                    }, {
                        text: 'Override', primary: true,
                        onClick: () => {
                            this.$axios.post('interventionPlan.php?action=update&allow_new_plan=0&property=plan&confirm=1', this.plan)
                                .then(response => {
                                    this.$ecResponse(response)
                                    this.$emit('save', true)
                                })
                        }
                    }]
                })
            },
            changePrimaryInterventionist(primaryInterventionist) {
                // remove selected primary interventionist from secondary interventionists if present
                this.plan.secondary_interventionists = this.plan.secondary_interventionists.filter(
                    secondaryInterventionist => secondaryInterventionist != primaryInterventionist?.id
                )
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>