<template>
    <div>
        <fe-dialog
            :title="getTitle"
            width="600"
            height="460"
            @dismiss="cancel"
            @close="cancel"
            @accept="save"
            :acceptButtonDisabled="disableSave"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
        >
            <fe-text-field
                label="Name"
                v-model="config.name"
                width="100%"
                showRemainingCount
                :characterLimit="200"
            />
            <fe-remote-combo
                label="Link to Goal (Optional)"
                v-model="config.goal"
                :items="availableGoals"
                itemText="name"
                itemId="id"
                hide-details
            />
        </fe-dialog>
    </div>
</template>

<script>
import {mapState} from "vuex"

export default {
    name: 'CreateObjective',

    props: {
        activeDashboard: {
            type: Object, default: () => {
            }
        },
        activeObjective: {
            type: Object, default: () => {
            }
        },
        duplicateObj: {type: Boolean, default: false},
        goals: {type: Array, default: () => []},
    },

    data() {
        return {
            config: {
                name: null,
                goal: null
            },
            localObj: {}
        }
    },

    computed: {
        ...mapState('actionPlan', ['objective']),
        availableGoals() {
            return this.goals.filter(x => {
                return !x.action_plan_objective_id
            }).map(goal => {
                let name = goal.custom_statement || goal.default_statement

                return {
                    id: goal.id,
                    name: name
                }
            })
        },
        getTitle() {
            if (this.objective && Object.keys(this.objective).length) {
                return this.duplicateObj ? 'Duplicate Objective' : 'Edit Objective'
            } else {
                return 'Create Objective'
            }
        },
        disableSave() {
            return !this.config.name
        }
    },

    mounted() {
        this.config = {
            name: null,
            goal: null
        }
        if (this.objective && Object.keys(this.objective).length) {
            this.localObj = this.$_.cloneDeep(this.objective)
            this.config = Object.assign(this.config, this.objective)
        } else if (this.activeObjective && Object.keys(this.activeObjective).length) {
            this.localObj = this.$_.cloneDeep(this.activeObjective)
        }
    },

    methods: {
        cancel() {
            this.config = {
                name: null,
                goal: null
            }
            this.$store.commit('actionPlan/set', {
                key: 'objective',
                value: null
            })
            this.$emit('close')
        },

        save() {
            this.$emit('save', this.config)
            this.cancel()
        }
    }
}
</script>
