<template>
    <fe-dialog
        v-if="active"
        @dismiss="active = false"
        @close="active = false"
        @accept="update"
        title="Edit Schedule"
        acceptButtonText="Update"
        dismissButtonText="Cancel"
        :acceptButtonDisabled="!valid"
        persistent
    >
        <v-form @submit.prevent ref="form" v-model="valid">
            <div class="d-flex flex-column">
                <fe-label>Start Date</fe-label>
                <fe-date-picker
                    v-model="obj.start_date"
                    flat solo dense validateOnBlur
                    :style="{ width: '100%' }"
                    :rules="rules.start_date"
                />

                <fe-label>End Date</fe-label>
                <fe-date-picker
                    v-model="obj.end_date"
                    flat solo dense validateOnBlur
                    :style="{ width: '100%' }"
                    :rules="rules.end_date"
                />

                <fe-label>Every # Weeks</fe-label>
                <v-text-field
                    v-model="obj.frequency_cnt"
                    flat solo dense validateOnBlur
                    :style="{ width: '100%' }"
                    :rules="rules.frequency_cnt"
                    type="number"
                    :pattern="/[0-9]*/"
                />

                <div class="mb-2">
                    <weekday-picker v-model="weekdays" />
                    <div v-if="!daysOfWeekValid" class="v-messages theme--light error--text days-of-week-errors" role="alert">
                        <div class="v-messages__wrapper">
                            <div class="v-messages__message">Day of week required</div>
                        </div>
                    </div>
                </div>
            </div>
        </v-form>
    </fe-dialog>
</template>

<script>
    import WeekdayPicker from '@/components/common/button/WeekdayPicker'

    export default {
        name: 'SchedEdit',
        components: { WeekdayPicker },
        props: {
            model: { type: Object, default: () => {} }
        },
        data () {
            return {
                valid: false,
                active: false,
                obj: {
                    id: null,
                    start_date: null,
                    end_date: null,
                    frequency_cnt: 1,
                    week_days: ''
                },
                rules: {
                    start_date: [
                        v => (!!v && v.trim().length > 0) || 'Please enter a start date'
                    ],
                    end_date: [
                        v => (!!v && v.trim().length > 0) || 'Please enter an end date'
                    ],
                    frequency_cnt: [
                        v => (!!v && `${v}`.trim().length > 0 && `${v}`.match(/^[0-9]*$/) && parseInt(v) >= 1) || 'Please enter a frequency of 1 or more'
                    ],
                    week_days: [
                        v => (!!v && `${v}`.trim().length > 0 && `${v}`.match(/^[0-9]*$/) && parseInt(v) >= 1) || 'Please enter a frequency of 1 or more'
                    ]
                },
                daysOfWeekValid: true
            }
        },
        computed: {
            weekdays: {
                get () { return this.obj.week_days || '' },
                set (v) {
                    this.obj.week_days = v || ''
                    this.$nextTick(() => this.validateDaysOfWeek())
                }
            }
        },
        methods: {
            validateDaysOfWeek () { this.daysOfWeekValid = `${this.weekdays}` != '' },
            reset () {
                Object.keys(this.obj).forEach(k => {
                    this.obj[k] = (this.model?.schedule[0])
                        ? this.model?.schedule[0][k]
                        : null
                })
                this.active = true
            },
            update () {
                if (this.$refs.form.validate()) {
                    this.$emit('update', Object.assign({}, this.model, {
                        schedule: [Object.assign({}, this.model.schedule[0], this.obj)]
                    }))
                }
            }
        },
        watch: {
            active (v) { this.$emit(v ? 'active' : 'inactive') },
            model: {
                handler (v) { if (v) this.reset() },
                immediate: true
            }
        }
    }
</script>