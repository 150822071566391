<template>
    <div class="d-flex flex-fill flex-column">
        <div class="text-right">
            <fe-icon-btn small useIcon="fas fa-print" @click="$refs.chart.chart.print()">
            </fe-icon-btn>
            
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn small icon v-on="on">
                        <v-icon small>fas fa-lightbulb</v-icon>
                    </v-btn>
                </template>
                    <v-card class="pa-5">
                        <div v-for="d in summaryData" class="mb-2">
                            <div style="font-weight: bold; font-size: 14px;">{{d.name}}</div>
                            <div style="color: gray; font-size: 12px;">{{d.summary}}</div>

                        </div>
                    </v-card>
            </v-menu>
        </div>
        <highcharts ref="chart" :options="chartOptions"/>

        <fe-dialog 
            title="Selected Students"
            v-if="dialog.show"
            @accept="dialog.show=false"
            @close="dialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Done"
            width="800"
        >
            <fe-grid
                ref="grid"
                :showAddRowBtn="false"
                :columnDefs="columns"
                :rowData="selectedStudents"
                domLayout="normal"
                style="height: 400px;"
                displayDensity="small"
                @selectionChanged="selections=$refs.grid.gridApi.getSelectedRows()"
            >
                <template #toolbar-items>
                    <fe-btn usage="secondary" :disabled="selections.length===0" @click="tagStudents">Tag</fe-btn>
                </template>
            </fe-grid>
        </fe-dialog>
    </div>
</template>

<script>
    import windowOptionsMixin from '@/mixins/windowOptions'
    export default {
        name: 'ScatterPlot',
        mixins: [ windowOptionsMixin ],
        props: {
            items: {

            },
            showBands: {
                type: Boolean,
                default: false
            },
            xAxisKey: {
                type: String,
                default: 'score'
            },
            yAxisKey: {
                type: String,
                default: 'score'
            },
            xPlotLine: {
                // { value: XX, label: 'test' }
                default: null
            },
            yPlotLine: {
                default: null
            },
            chartHeight: {
                type: [String, Number],
                required: false
            },
        },
        data() {
            let me = this
            return {
                xItems: [],
                yItems: [],
                localItems: [],
                regressionItems: [],
                summaryData: [],
                coefficient: null,
                slope: null,
                coefficient: null,
                chartOptions: {},
                baseConfig: {
                    chart: {
                        type: 'scatter',
                        zoomType: 'xy'
                    },
                    title: {
                        text: 'Change Me',
                        align: 'left',
                        style: {
                            fontSize: 14
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    exporting: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            turboThreshold: 0
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    yAxis: {
                        title: {
                            text: null
                        }
                    },
                    xAxis: {
                        title: {
                            text: null
                        }
                    },
                    series: []
                },
                columns: [],
                dialog: {
                    show: false
                },
                selectedStudents: [],
                selections: []
            }
        },
        created() {
            this.chartOptions = this.baseConfig
            this.chartOptions.chart.type = 'scatter'
            this.chartOptions.chart.events = {
                selection: this.selectPointsByDrag,
                selectedpoints: this.selectedPoints,
                click: this.unselectByClick
            }
            this.chartOptions.chart.height = this.chartHeight || (window.innerHeight-200)
            this.chartOptions.legend.enabled = false
            this.chartOptions.plotOptions.scatter = {
                tooltip: {
                    headerFormat: '',
                    pointFormat: '<b>{point.name}<br> {point.xTitle}: {point.x}<br>{point.yTitle}: {point.y}'
                }
            }
            let data = []
            this.xItems = []
            this.yItems = []
            this.regressionItems = []

            this.items.forEach(i => {
                if (i.data.length===2) {
                    // Set the titles
                    let xTitle = i.data[0].sub_category_name
                    if (i.data[0].score_detail_type_code) {
                        xTitle += ` - ${i.data[0].score_detail_type_code}`
                    }
                    let yTitle = i.data[1].sub_category_name
                    if (i.data[1].score_detail_type_code) {
                        yTitle += ` - ${i.data[1].score_detail_type_code}`
                    }

                    if (!this.chartOptions.xAxis.title.text) this.chartOptions.xAxis.title.text = xTitle
                    if (!this.chartOptions.yAxis.title.text) this.chartOptions.yAxis.title.text = yTitle
                    
                    let x = parseFloat(i.data[0][this.xAxisKey])
                    let y = parseFloat(i.data[1][this.yAxisKey])

                    data.push({
                        name: i.student_full_name,
                        record: i,
                        allowPointSelect: true,
                        x: x,
                        y: y,
                        xTitle,
                        yTitle,
                    })
                    this.xItems.push(x)
                    this.yItems.push(y)
                    this.regressionItems.push([x, y])
                }
            })

            this.setColumnHeaders()
            this.chartOptions.series.push({
                allowPointSelect: true,
                data: data
            })

            this.buildTrendline()
            this.setPlotLines()
            this.doInsights()
        },
        methods: {
            tagStudents() {
                let me=this
                this.$tagStudents(true, this.selections,()=> { 
                    me.$refs.grid.gridApi.deselectAll()

                })
            },
            setColumnHeaders() {
                this.columns = [this.$grid.checkColumn(), { 
                   headerName: "Student",
                   field: "student_full_name",
                   width: 200
                }, { 
                   headerName: this.chartOptions.xAxis.title.text,
                   field: "x",
                   width: 100,
                   cellStyle: {
                       textAlign: 'right'
                   }
                }, { 
                   headerName: this.chartOptions.yAxis.title.text,
                   field: "y",
                   width: 100,
                   cellStyle: {
                       textAlign: 'right'
                   }
                }]
            },
            selectPointsByDrag(e) {
                let me = this
                let chart = this.$refs.chart.chart

                let points = chart.getSelectedPoints();
                if (points.length > 0) {
                    points.forEach(point => {
                        point.select(false)
                    })
                }
                // Select points
                chart.series.forEach(function (series) {
                    series.points.forEach(function (point) {
                        if (point.x >= e.xAxis[0].min && point.x <= e.xAxis[0].max && point.y >= e.yAxis[0].min && point.y <= e.yAxis[0].max) {
                            point.select(true, true)
                        }
                    })
                })

                // Fire a custom event
                me.selectedPoints(chart.getSelectedPoints())

                return false  // Don't zoom
            },
            selectedPoints(e) {
                this.selectedStudents = e.filter(r => r.series.name != 'Trendline').map(r => { return {
                    student_full_name: r.options.name,
                    student_id: r.record.student_id,
                    x: r.options.x,
                    y: r.options.y
                }})
                this.dialog.show=true
            },
            unselectByClick(e) {
            },
            buildTrendline() {
                this.coefficient = spearson.correlation.pearson(this.xItems, this.yItems)
                let result = regression('linear', this.regressionItems)
                this.slope = result.equation[0]
                this.yIntercept = result.equation[1]
                this.chartOptions.title.text = 'Correlation Coefficient ' + this.coefficient.toFixed(2)

                // Now, build the slope
                var foundAxis = {};

                let serie = {
                    name: 'Trendline',
                    type: 'line',
                    color: '#408AAF',
                    data: []
                }

                this.chartOptions.series[0].data.forEach(r=> {
                    serie.data.push([r.x, this.slope * r.x + this.yIntercept])
                })
                this.chartOptions.series.push(serie)
                return result;
            },
            setPlotLines() {
                let yData = {}
                let xData = {}

                this.items.forEach(rec => {
                    if (rec.data.length === 2) {
                        let isScoreDetail = !!rec.data[0].score_detail_type_id
                        let xRec = rec.data[0]
                        let yRec = rec.data[1]
                        let x = parseFloat(isScoreDetail ? (this.xPlotLine?.value || 0) : xRec[this.xAxisKey])
                        let y = parseFloat(isScoreDetail ? (this.yPlotLine?.value || 0) : yRec[this.yAxisKey])

                        // If scatter plot is based on score details, use is expected
                        // to provide line name/numeric value data; the backend does
                        // not return target descriptor for score details
                        let xDataKey = (isScoreDetail ? (this.xPlotLine?.label) : xRec.target_descriptor_name)
                        let yDataKey = (isScoreDetail ? (this.yPlotLine?.label) : yRec.target_descriptor_name)

                        if (!xData[xDataKey]) {
                            xData[xDataKey] = {
                                value: x,
                                color: xRec.target_descriptor_color // draws as default (black usually) if does not exist (i.e. score details)
                            } 
                        }

                        if (!yData[yDataKey]) {
                            yData[yDataKey] = {
                                value: y,
                                color: yRec.target_descriptor_color // draws as default (black usually) if does not exist (i.e. score details)
                            } 
                        }

                        // Plot line manipulation that is only applicable to non-score details scatter plots
                        if (!isScoreDetail) {
                            if (yData[yRec.target_descriptor_name].value > y) yData[yRec.target_descriptor_name].value = y
                            if (xData[xRec.target_descriptor_name].value > x) xData[xRec.target_descriptor_name].value = x
                        }
                    }
                })

                this.chartOptions.xAxis.plotLines = []
                for (let key in xData) {
                    this.chartOptions.xAxis.plotLines.push({
                        color: xData[key].color,
                        dashStyle: this.xPlotLine ? 'solid' : 'dot',
                        value: xData[key].value,
                        width: 2,
                        label: {
                            text: key,
                            style: {
                                fontSize: 10
                            }
                        }
                    })
                }

                this.chartOptions.yAxis.plotLines = []
                for (let key in yData) {
                    this.chartOptions.yAxis.plotLines.push({
                        color: yData[key].color,
                        dashStyle: this.yPlotLine ? 'solid' : 'dot',
                        value: yData[key].value,
                        width: 2,
                        label: {
                            text: key,
                            style: {
                                fontSize: 10
                            }
                        }
                    })
                }
            },
            doInsights() {
                let coefSummary = ''
                let coef = this.coefficient

                if (coef == -1) {
                    coefSummary = 'A perfect negative linear relationship'
                } else if (coef <= -.7) {
                    coefSummary = 'A strong negative linear relationship'
                } else if (coef <= -.5) {
                    coefSummary = 'A moderate negative linear relationship'
                } else if (coef <= -.3) {
                    coefSummary = 'A weak negative linear relationship'
                } else if (coef >= -.3 && coef <= .3) {
                    coefSummary = 'Little/No linear relationship'
                } else if (coef == 1) {
                    coefSummary = 'A perfect positive linear relationship'
                } else if (coef >= .7) {
                    coefSummary = 'A strong positive linear relationship'
                } else if (coef >= .5) {
                    coefSummary = 'A moderate positive linear relationship'
                } else if (coef >= .3) {
                    coefSummary = 'A weak positive linear relationship'
                } else if (coef >= .3) {
                    coefSummary = 'A strong positive linear relationship'
                }

                coefSummary += '.  A postive relationship indicates scores will move in the same direction.  ' +
                ' A negative relationship means scores will move in the opposite direction.  '

            
                this.summaryData = [{
                    name: 'Correlation Coefficient',
                    value: parseFloat(this.coefficient).toFixed(3),
                    summary: coefSummary
                }, {
                    name: 'Slope',
                    value: parseFloat(this.slope).toFixed(3),
                    summary: 'The slope is a measurement for how many units the line will rise for every unit to the right (x-axis)'
                // }, {
                //     name: 'R Square (Goodness of Fit)',
                //     value: parseFloat(regression.r2).toFixed(3),
                //     summary: 'A value between 0% and 100%.  The higher the percent, the tighter the scores will be to the fitted line'
                }]


                var xMedian = spearson.median(this.xItems)
                var yMedian = spearson.median(this.yItems)
                var xAvg    = spearson.mean(this.xItems)
                var yAvg    = spearson.mean(this.yItems)

                
                this.summaryData.push({
                    name: this.chartOptions.xAxis.title.text + ' Median',
                    summary: xMedian
                }, {
                    name: this.chartOptions.yAxis.title.text + ' Median',
                    summary: yMedian
                }, {
                    name: this.chartOptions.xAxis.title.text + ' Mean',
                    summary: parseFloat(xAvg).toFixed(2)
                }, {
                    name: this.chartOptions.yAxis.title.text + ' Mean',
                    summary: parseFloat(yAvg).toFixed(2)
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>