<template>
    <fe-dialog
        v-if="active"
        dismissButtonText="Cancel"
        acceptButtonText="Save"
        title="Configure Performance Bands"
        width="500"
        :acceptButtonDisabled="hasErrors"
        :scrollable="false"
        @accept="save"
        @dismiss="$emit('cancel')"
    >
        <v-container pl-1 pr-5 pt-3 pb-0 class="thresholds">
            <v-layout v-if="count > 0" row mt-2 mb-0>
                <v-flex xs2>Color</v-flex>

                <v-flex xs4>Label</v-flex>

                <v-flex xs6>Values</v-flex>
            </v-layout>

            <div v-if="count > 0" class="band-list">
                <PerformanceBandRow
                    v-for="(band,idx) in sorted"
                    :toValue="getToValue(idx)"
                    :key="idx"
                    :band="band"
                    :idx="band.__idx"
                    :errors="errors[idx]"
                    :allowDelete="sorted.length > 1"
                />
            </div>

            <v-layout v-if="hasErrors" row ma-0 pa-0>
                <v-flex xs12 my-2 pa-0 class="error--text text-small text-xs-center">
                    <div v-for="(err,idx) in errorMessages" :key="`err-${idx}`">
                        {{ err }}
                    </div>
                </v-flex>
            </v-layout>

            <v-layout row ma-0 pa-0>
                <v-flex xs12 mt-2 pa-0>
                    <fe-btn usage="tertiary" @click="addBand">Add Band</fe-btn>
                </v-flex>
            </v-layout>
        </v-container>

        <fe-dialog
            v-if="confirmSave"
            dismissButtonText="Cancel"
            acceptButtonText="Save Changes"
            title="Save Changes?"
            width="500"
            :body="confirmText"
            @accept="save"
            @dismiss="confirmSave = false"
        />
    </fe-dialog>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import PerformanceBandRow from '../groups/PerformanceBandRow'

export default {
    name: 'PerformanceBandEditor',

    components: {
        PerformanceBandRow
    },

    data() {
        return {
            confirmSave: false,
            active: true,
            errors: []
        }
    },

    computed: {
        ...mapGetters('thresholds/group/performanceBand', [
            'collection',
            'clear',
            'pending',
            'success',
            'error',
            'hasDirtied',
            'hasRemoved',
            'hasAdded',
            'hasChanges',
            'count',
            'rowData'
        ]),

        ...mapGetters({
            tags: 'thresholds/group/tag/collection',
            forms: 'thresholds/group/form/collection'
        }),

        thresholdGroup() {
            return this.$store.getters['thresholds/group/selected']
        },

        thresholdGroupId() {
            return this.thresholdGroup.id
        },

        sorted() {
            return [].concat(this.rowData).sort((a, b) => a.from_value - b.from_value).reverse()
        },

        min() {
            return (this.count === 0) ? 0 : this.sorted.slice(-1)[0].from_value
        },

        max() {
            return (this.count === 0) ? 0 : this.sorted[0].from_value
        },

        hasErrors() {
            return this.errors.filter(x => !!x).length > 0
        },

        errorMessages() {
            let messages = []
            if (this.hasErrorType('name', 'required')) messages.push("Labels are required")
            if (this.hasErrorType('name', 'length')) messages.push("Labels must not exceed 50 characters")
            if (this.hasErrorType('name', 'duplicate')) messages.push("Labels must be unique")
            if (this.hasErrorType('value', 'required')) messages.push("Values are required")
            if (this.hasErrorType('value', 'range')) messages.push("Values must be between 0 and 1,000")
            if (this.hasErrorType('value', 'duplicate')) messages.push("Values must be unique")
            return messages
        },

        formLinked() {
            return this.rowData.filter(band => {
                return this.forms.find(form => {
                    return form.threshold_performance_band_id &&
                        form.threshold_performance_band_id.includes(band.id)
                })
            })
        },

        tagLinked() {
            return this.rowData.filter(band => {
                return this.tags.find(tag => {
                    return tag.threshold_performance_band_id &&
                        tag.threshold_performance_band_id.includes(band.id)
                })
            })
        },

        confirmText() {
            if (this.formLinked.length > 0 && this.tagLinked.length > 0) {
                return 'Some performance bands are linked to applied smartFORMs and tags.  Upon saving changes, please verify linked Performance Bands in the "smartFORMs" and "Tags" tabs.'
            } else if (this.formLinked.length > 0) {
                return 'Some performance bands are linked to applied smartFORMs.  Upon saving changes, please verify linked Performance Bands in the "smartFORMs" tab.'
            } else if (this.tagLinked.length > 0) {
                return 'Some performance bands are linked to applied tags.  Upon saving changes, please verify linked Performance Bands in the "Tags" tab.'
            } else {
                return null
            }
        }
    },

    watch: {
        collection: function () {
            this.validate()
        }
    },

    mounted() {
        this.refresh()
    },

    methods: {
        ...mapActions('thresholds/group/performanceBand', [
            'refresh',
            'reconcile',
            'add',
            'remove'
        ]),

        hasErrorType(key, type) {
            return this.errors.filter(x => x && !!x[key] && x[key] === type).length > 0
        },

        getToValue(idx) {
            return (idx > 0)
                ? parseFloat(this.sorted[idx - 1].from_value)
                : null
        },

        validateName(idx) {
            let val = this.sorted[idx].name
            if (!val || val.trim() === "") {
                return 'required'
            } else if (val.length > 50) {
                return 'length'
            } else if (this.nameIsDuplicate(idx)) {
                return 'duplicate'
            }
            return null
        },

        validateValue(idx) {
            let val = this.sorted[idx].from_value
            if (val === null || isNaN(val)) {
                return 'required'
            } else if (parseFloat(val) > 10000 || parseFloat(val) < 0) {
                return 'range'
            } else if (this.valueIsDuplicate(idx)) {
                return 'duplicate'
            }
            return null
        },

        nameIsDuplicate(idx) {
            let val = this.sorted[idx].name.trim().toLowerCase()
            let matches = this.sorted.filter(x => x.name.trim().toLowerCase() === val)
            return matches.length > 1
        },

        valueIsDuplicate(idx) {
            let val = parseFloat(this.sorted[idx].from_value)
            let matches = this.sorted.filter(x => parseFloat(x.from_value) === val)
            return matches.length > 1
        },

        validate() {
            this.errors = []
            this.sorted.map((x, idx) => {
                let errs = {
                    name: this.validateName(idx),
                    value: this.validateValue(idx)
                }
                this.errors.push((errs.name || errs.value) ? errs : null)
            })
            return this.hasErrors
        },

        addBand() {
            this.add([{
                threshold_group_id: this.thresholdGroupId,
                name: `Band ${(this.count + 1)}`,
                from_value: (this.count === 0) ? '0' : (parseFloat(this.max) + 1),
                color: this.count,
                id: null
            }])
        },

        save() {
            if (this.tagLinked.length > 0 || this.formLinked.length > 0) {
                if (this.confirmSave) {
                    this.confirmSave = false
                    this.$emit('save')
                } else {
                    this.confirmSave = true
                }
            } else {
                this.confirmSave = false
                this.$emit('save')
            }
        }
    }
}
</script>
