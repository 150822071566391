import * as LaunchDarklyClient from 'launchdarkly-js-client-sdk'

export const flags = {
    namespaced: true,
    state: {
        client: null,
        flags: null,
        userParams: {
            userId: null,
            userEmail: null,
            userFirst: null,
            userLast: null,
            userLogin: null,
            districtId: null
        }
    },
    mutations: {
        clear(state) {
            state.client = null
            state.flags = null
            Object.keys(state.userParams).forEach(k => state.userParams[k] = null)
        },
        set(state, payload) {
            state.client = payload.client
            state.flags = payload.flags
            Object.keys(state.userParams).forEach(k => state.userParams[k] = payload.userParams[k])
        },
        setFlags(state, flags) {
            state.flags = flags
        },
        updateFlags(state, updates) {
            let updatedFlags = Object.assign({}, state.flags)
            Object.keys(updates).forEach(k => {
                updatedFlags[k] = updates[k].current
            })
            state.flags = updatedFlags
        }
    },
    actions: {
        // Will initialize a LaunchDarklyClient handler.  Must pass in all expected userParams
        // to be considered valid.  If the handler is already initialized for the passed-in
        // userParams it won't initialize again unless payload also contains force:true.
        async initialize({ state, commit }, payload) {
            let requiredKeys = Object.keys(state.userParams)
            let invalid = requiredKeys.find(k => !payload[k])
            if (invalid) {
                commit('clear')
                return false
            }

            let stale = requiredKeys.find(k => payload[k] !== state.userParams[k])
            if (!stale && !payload.force) return false

            // This ID is not considered a secret.
            let clientId = (window.location.host.indexOf('educlimber.com') >= 0)
                ? '63ecf870b5734a186ddd77ec' // Use Production key in environments with a production URL
                : '63ecf870b5734a186ddd77eb' // Use QA key on QA or development environments,

            let rootParamKeys = ['userEmail', 'userFirst', 'userLast']
            let customParams = {}
            requiredKeys.forEach(k => {
                if (rootParamKeys.indexOf(k) < 0) customParams[k] = payload[k]
            })

            let user = {
                key: `d${payload.districtId}-u${payload.userId}`,
                email: payload.userEmail,
                firstName: payload.userFirst,
                lastName: payload.userLast,
                custom: customParams
            }

            let client = await LaunchDarklyClient.initialize(clientId, user)

            client.waitForInitialization().then(() => {
                commit('setFlags', client.allFlags())
            })

            client.on('change', (updates) => {
                commit('updateFlags', updates)
            })

            commit('set', {
                client: client,
                flags: flags,
                userParams: payload
            })

            return true
        }
    },
    getters: {
        flags: state => state.flags
    }
}
