<template>
    <div class="flex-column flex-fill" style="position: relative;">
        <fe-left-menu-layout
            :menuItems="menuItems"
            v-model="activeItem"
            bodyPadding="0px"
            class="meeting-layout"
            full-width-title
        >
            <template #title>
                <div class="layout-title">
                    <fe-icon-btn class="layout-title--back" useIcon="fas fa-chevron-left" @click="navigate()"/>
                    <div class="layout-title--text">{{ meetingRec.title }}</div>
                </div>
                <toolbar
                    v-if="meetingRec"
                    :meeting="meetingRec"
                    :occurrences="occurrences"
                    v-model="occurrence"
                    @edit="editing = true"
                    @delete="$emit('delete', meeting)"
                />
            </template>

            <!-- since action items are iframed in and the component has aggressive margins/padding, handle body padding at the component level in here -->
            <div class="flex-grow-0 flex-shrink-0 flex-fill flex-column" style='width: 100%;'>
                <agenda :key="`meeting-${meetingRec.id}-agenda`" :meeting="meetingRec" :occurrence="occurrence" v-if="activeItem && activeItem.id==='agenda'" @students="updateStudents" />
                <div class="headline mt-5 pl-5" v-if="activeItem && activeItem.id==='notes'">Notes</div>
                <message :key="`meeting-${meetingRec.id}-message`" dense class="ma-5" :params="{ dashboard_meeting_id: $props.id}" v-if="activeItem && activeItem.id==='notes'"/>
                <action-items :key="`meeting-${meetingRec.id}-action-items`" :urlParams="{dashboard_meeting_id: $props.id, hide_menu: true }" v-if="activeItem && activeItem.id==='actionitems'" style="margin: -16px;"/>
                <students :key="`meeting-${meetingRec.id}-students`" :meeting="meetingRec" v-if="activeItem && activeItem.id==='students'" />
                <meeting-creation :key="`meeting-${meetingRec.id}-meeting-creation`" v-if="editing" :editing="meetingRec" @updated="meetingRec = $event" @closed="editing = false" @close="editing = false" />
            </div>
        </fe-left-menu-layout>
    </div>
</template>

<script>
    import MeetingCreation from '../creation'
    import Message from '@/components/common/Message'
    import ActionItems from '@/components/modules/actionItems/ActionItems.vue'
    import Students from '@/components/modules/meetings/workspace/students'
    import Agenda from '@/components/modules/meetings/workspace/agenda'
    import Toolbar from '@/components/modules/meetings/workspace/Toolbar'

    export default {
        name: 'MeetingWorkspace',
        components: { Message, ActionItems, Students, Agenda, Toolbar, MeetingCreation },
        props: {
            id: {
                required: true,
            }
        },
        watch: {
            id(v) {
                this.activeItem = this.menuItems[0]
                this.meetingRec = {}
                this.loadData()
            },
            meetingRec(v) {}
        },
        data() {
            return {
                editing: false,
                activeItem: null,
                selectedOccurrence: null,
                meetingRec: {},
                menuItems: [{
                    id: 'agenda',
                    name: 'Agenda & Summary'
                }, {
                    id: 'students',
                    name: 'Students'
                }, {
                    id: 'notes',
                    name: 'Notes'
                }, {
                    id: 'actionitems',
                    name: 'Action Items'
                }],
                prevRoute: null
            }
        },
        computed: {
            occurrence: {
                get () { return this.selectedOccurrence || (this.occurrences && this.occurrences.length > 0 && this.occurrences[0]) || null },
                set (v) { this.selectedOccurrence = v }
            },
            occurrences () {
                return (this.meetingRec.occurrences)
                    ? this.meetingRec.occurrences.map(itm => Object.assign({}, itm, {
                        name: this.$dayjs(itm.occurrence, "YYYY-MM-DD").format("dddd, MMMM Do, YYYY"),
                        icon: 'far fa-calendar-alt'
                    }))
                    : []
            }
        },
        mounted() {
            this.activeItem = this.menuItems[0]
            this.loadData()
        },
        methods: {
            updateStudents (records) {
                this.meetingRec = Object.assign({}, this.meetingRec, {
                    dashboard_meeting_students: records
                })
            },
            loadData() {
                this.$setLoading(true)
                this.$modelGet('meeting', { id: this.$props.id })
                    .then(r => {
                        if (r.length === 1) {
                            this.meetingRec = r[0]
                        } else {
                            console.warn('Unable to uniquely identify meeting', r)
                        }
                    })
                    .finally(() => {
                        this.$setLoading(false)
                    })
            },
            navigate() {
                //  go to meetings or collections based on prevRoute
                this.$router.replace(this.prevRoute.path)
            }
        },
        beforeRouteEnter(to, from, next) {
            next(me => {
                me.prevRoute = from
            })
        }
    }
</script>

<style lang="scss" scoped>
.layout-title {
    position: relative;
    &--text {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-indent: 30px;
        width: calc(100vw - 620px);
        .is-collapsed & {
            width: calc(100vw - 160px);
        }
    }
    &--back {
        display: block;
        position: absolute;
        left: -30px;
        top: -10px;
    }
}
.agenda-toolbar {
    position: absolute;
    height: 48px;
    right: 2px;
    top: 0;
    width: 500px;
    .is-collapsed & {
        top: 50px;
        left: 0;
        width: auto;
        padding-left: 20px;
        border-bottom: solid 1px #f5f5f5;
    }
}
</style>
