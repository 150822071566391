<template>
    <fe-dialog
        width="700" height="70vh"
        title="Copy Tags to Year"
        acceptButtonText="Save"
        dismissButtonText="Cancel"
        :acceptButtonDisabled="!isValid"
        persistent
        @accept="accept"
        @dismiss="$emit('close')"
    >
    
        <fe-grid
            ref="grid"
            disableInlineCreate
            displayDensity="medium"
            :rowData="rowData"
            :showToolbarMenu="false"
            :showDownload="false"
            :searchBar="false"
            :showAddRowBtn="false"
            :columnDefs="[ $grid.checkColumn(),
                {headerName: 'Student', field: 'student_full_name'},
                {headerName: 'School', field: 'school_name', width: 250},
                {headerName: 'Grade', field: 'grade_desc', maxWidth: 100},
            ]"
            style="height:100%;"
            @selectionChanged="selection = $event.api.getSelectedRows()"
        >
            <template #left-toolbar-items>
                <tag-combo v-model="oldTag" readonly class="readonly" style="maxWidth:260px;" />
                <v-icon color="rgba(0,0,0,.54)" class="mx-2" style="height:36px; font-size:20px;">fa-copy</v-icon>
                <fe-remote-combo
                    style="maxWidth:140px;"
                    v-model="newYear"
                    rootProperty="years"
                    url="qry.php?action=get&property=school_years"
                    :clearable="false"
                ></fe-remote-combo>
            </template>
        </fe-grid>
        
        <fe-crud
            ref="crud"
            autoload
            :readParams="crudParams"
            :config="$models.studentTag"
            @read="rowData = $event"
        />
        
    </fe-dialog>
</template>

<script>
import TagCombo from '@/components/common/combo/TagCombo'

export default {
    name: 'SwitchTag',
    
    components: {TagCombo},
    
    props: {
        oldTag: {},
        crudParams: {}
    },

    computed: {
        isValid() {
            return this.selection.length && this.newYear
        }
    },
    methods: {
        accept() {
            let recs = this.selection.map( x => ({
                student_id: x.student_id,
                tag_id: x.tag_id,
                school_year_id: this.newYear.id
            }))
            this.$refs.crud.create(recs).finally(()=>{
                this.$emit('close')
            })
        }
    },

    data() {
        return {
            newYear: null,
            rowData: null,
            selection: [],
        }
    }
}
</script>

<style lang="scss" scoped>
    .readonly ::v-deep .v-input__append-inner {
        display: none;
    }
</style>
