<template>
    <div class="d-flex flex-fill flex-column" v-if="meeting">
        <div class="d-flex col-header">
            <div class="headline left-col">Students</div>
            <div class="tab-container flex-grow-1 flex-shrink-1">
                <fe-tabs :tabs="tabs" show-arrows no-padding @tabClicked="tab = $event" />
            </div>
        </div>
        <div class="d-flex flex-fill no-scroll">
            <div class="d-flex left-col flex-column flex-shrink-0">
                <div class="d-flex flex-grow-0 flex-shrink-0">
                    <div class="meetings-student-list-filter">
                        <label for="filter_field" class='filter-label'>
                            <v-icon class='filter-icon' style="font-size: 18px;">search</v-icon>
                        </label>
                        <v-text-field
                            ref="filterField"
                            id="filter_field"
                            class="filter-field"
                            placeholder="Filter Students"
                            v-model="filter"
                            solo flat
                            hide-details
                            clearable
                        />
                    </div>
                </div>
                <div class="lower-scroll">
                    <v-list dense class="pa-0 ma-0">
                        <v-list-item
                            v-for="stdnt in filteredStudents"
                            :key="`meeting-student-${stdnt.id}`"
                            @click="studentId = stdnt.student_id"
                            class="px-1 py-0"
                            :class="{ 'selected-student': stdnt.student_id == studentId }"
                            dense
                        >
                            <v-list-item-avatar>
                                <div
                                    v-if="stdnt.img && stdnt.img.styles && !stdnt.img.top_secret"
                                    class="ma-2"
                                    style="border-radius: 50%; display: inline-block; width:26px; height:26px; overflow:hidden;"
                                    :title="stdnt.student_full_name"
                                >
                                    <div :style="stdnt.img.styles"></div>
                                </div>
                                <v-img
                                    v-else
                                    class="ma-2"
                                    :src="`/images/default-student-img.png`"
                                    width="26"
                                    maxWidth="26"
                                    height="26"
                                    maxHeight="26"
                                />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title style="font-size: 12px;">{{ stdnt.student_full_name }}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action class="mx-0 px-0">
                                <v-menu offset-y close-on-content-click>
                                    <template v-slot:activator="{ on }">
                                        <fe-icon-btn v-on="on" use-icon="more_vert" />
                                    </template>
                                    <v-list>
                                        <v-list-item @click="addComment(stdnt.student_id)">
                                            <v-list-item-title>Add Comment</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="addTag(stdnt.student_id)">
                                            <v-list-item-title>Add Tag</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </div>
            </div>
            <div class="flex-fill flex-column flex-grow-1 flex-shrink-1" v-if="student && tab">
                <div class="flex-fill px-5 pt-5 justify-space-between" style="height: 60px;">
                    <div class="align-self-center headline">{{ student.student_full_name }}</div>
                    <div class="align-self-center align-right" v-if="tab.name != 'Thresholds'">
                        <fe-remote-combo
                            v-if="tab.name && !['Comments','Tags'].includes(tab.name)"
                            class="ma-2"
                            :url="$models.getUrl('schoolYear', 'read')"
                            rootProperty="years"
                            v-model="schoolYear"
                            hideDetails
                        />
                    </div>
                </div>
                <div class="d-flex px-3 pb-3 lower-scroll flex-grow-1" :key="`meetings-${meeting.id}-students-${student.student_id}-year-${schoolYear.id}`">
                    <interventions v-if="tab.name == 'Interventions'" :params="{ student_id: student.student_id, school_year_id: schoolYear.id }"/>
                    <score-group
                        :student="studentWithSelectedYear"
                        v-else-if="tab.name == 'Assessments'"
                        style="overflow: scroll; width: 100%;"
                        @score-click="doScoreClick"
                    />
                    <fe-card
                        class="alt-card ml-2 mt-2"
                        headerText="Incidents"
                        v-else-if="tab.name == 'Incidents'"
                        style="overflow: hidden; width: 100%;"
                    >
                        <template v-slot:body>
                            <big-five
                                :params="bigFiveParams"
                                style="height: inherit; overflow: auto;"
                            />
                        </template>
                    </fe-card>
                    <fe-card
                        class="alt-card ml-2 mt-2"
                        headerText="Student Attendance"
                        v-else-if="tab.name == 'Attendance'"
                        style="overflow: scroll; width: 100%;"
                    >
                        <template v-slot:body><attendance :params="{ student_id: student.student_id, school_year_id: schoolYear.id }" /></template>
                    </fe-card>
                    <smartforms
                        :schoolYearId="schoolYear.id"
                        :studentId="student.student_id"
                        v-else-if="tab.name == 'smartFORMS'"
                    />
                    <fe-card
                        class="alt-card ml-2 mt-2"
                        headerText="Student Comments"
                        v-else-if="tab.name == 'Comments'"
                        style="overflow: scroll; width: 100%;"
                    >
                        <template v-slot:body><message ref="comments" dense :params="{ student_id: student.student_id }" :key="`mtg-stdnt-comments-${student.student_id}`" /></template>
                    </fe-card>
                    <fe-card
                        class="alt-card ml-2 mt-2"
                        headerText="Student Tags"
                        v-else-if="tab.name == 'Tags'"
                        style="overflow: scroll; width: 100%; height: 100%;"
                    >
                        <template v-slot:body><tag-list ref="tags" class='flex-fill' @addTagClick="addTag(student.student_id)" :params="{ student_id: student.student_id }" :key="`mtg-stdnt-tags-${student.student_id}`" /></template>
                    </fe-card>
                    <div
                        class="ml-2 mt-2"
                        v-else-if="tab.name == 'Thresholds'"
                        style="overflow: scroll; width: 100%; height: 100%;"
                    >
                        <thresholds-table ref="thresholds" class='flex-fill' :params="{ student_id: student.student_id }" meeting />
                    </div>
                </div>
            </div>
        </div>
        <fe-dialog
            title="Student Messages"
            width="800"
            v-if="messageDialog"
            @accept="() => $refs.msgCreate.doSubmit()"
            @close="messageDialog=null"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Create"
            :retain-focus="false"
        >
            <message
                ref="msgCreate"
                @created="messageDialog=null"
                dense
                create-only
                hide-submit
                :params="messageDialog"
            />
        </fe-dialog>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Interventions from '@/components/modules/meetings/workspace/students/Interventions'
    import ScoreGroup from '@/components/modules/studentprofile/ScoreGroup'
    import BigFive from '@/components/modules/incidents/BigFive'
    import Attendance from '@/components/modules/attendanceReporting/StudentAttendance'  // EC-2457
    import Smartforms from '@/components/modules/smartforms/List'
    import Message from '@/components/common/Message'
    import TagList from '@/components/modules/studentprofile/TagList'
    import ThresholdsTable from '@/components/modules/studentprofile/ThresholdsTable'

    export default {
        name: 'Students',
        props: {
            meeting: { type: Object, required: true }
        },
        components: {
            Interventions,
            ScoreGroup,
            BigFive,
            Attendance,
            Smartforms,
            Message,
            TagList,
            ThresholdsTable
        },
        data () {
            return {
                messageDialog: null,
                filter: '',
                selectedTab: null,
                selectedYear: null,
                selectedYearName: null,
                studentId: null,
                tabs: [
                    { name: 'Interventions', path: '#' },
                    { name: 'Assessments', path: '#' },
                    { name: 'Incidents', path: '#' },
                    { name: 'Attendance', path: '#' },
                    { name: 'smartFORMS', path: '#' },
                    { name: 'Comments', path: '#' },
                    { name: 'Tags', path: '#' },
                ],
                imageObjs: null,
            }
        },
        mounted() {
            if (this.filteredStudents.length > 0) {
                // Default to first student in list
                this.studentId = this.filteredStudents[0].student_id
            }
        },
        computed: {
            ...mapState('global', ['currentYear']),
            schoolYear: {
                get () { return this.selectedYear || this.currentYear },
                set (v) { this.selectedYear = v }
            },
            tab: {
                get () { return this.selectedTab || this.tabs[0] },
                set (v) { this.selectedTab = v }
            },
            students () {
                return this.meeting.dashboard_meeting_students.map(student => {
                    if (this.imageObjs) {
                        let img = this.imageObjs.images.find(itm => itm.id == student.student_id)
                        return img
                            ? Object.assign({}, student, { img: img })
                            : student
                    } else {
                        return student
                    }
                })
            },
            filteredStudents () {
                return this.students.filter(stdnt => {
                    return (this.filter && this.filter.trim().length > 0)
                        ? (stdnt.student_full_name.toLowerCase().indexOf(this.filter.trim().toLowerCase()) > -1)
                        : true
                })
            },
            student () {
                return (this.studentId)
                    ?  this.students.find(student => student.student_id == this.studentId)
                    : null
            },
            studentWithSelectedYear () {
                return (this.studentId)
                    ?  Object.assign(this.student, {
                        school_year_id: this.schoolYear.id,
                        school_year_name: this.schoolYear.name
                    })
                    : null
            },
            bigFiveParams () {
                return {
                    student_id: this.studentId,
                    school_year_id: this.schoolYear.id,
                    incident_behavior_type_id: null
                }
            },
        },
        methods: {
            addComment (student_id) {
                this.messageDialog = {
                    student_id: student_id,
                    school_year_id: this.schoolYear.id
                }
            },
            addTag (student_id) {
                let me = this
                this.$store.commit('global/tagStudents', {
                    show: true,
                    students: [{ student_id: student_id }],
                    callback() {
                        if (me.$refs.tags && me.$refs.tags.reload) me.$refs.tags.reload()
                    }
                })
            },
            fetchStudentImages () {
                let ids = this.meeting.dashboard_meeting_students.map(student => student.student_id)
                this.$fetchStudentImages(ids, 26).then((obj) => this.imageObjs = obj)
            },
            doScoreClick(rec, title) {
                this.$store.commit('global/addDockableWindow', {
                    name: title,
                    data: rec,
                    component: 'assessment-history',
                    attrs: {
                        studentId: rec.student_id,
                        subCategoryId: rec.sub_category_id,
                        dataPointScoreId: rec.data_point_score_id
                    }
                })
            },
        },
        watch: {
            meeting: {
                handler(v) { this.fetchStudentImages() },
                immediate: true
            },
            messageDialog (v) {
                if (!v && this.$refs.comments?.$refs.crud) this.$refs.comments.$refs.crud.read()
            },
            studentId(v) {
                if (v) {
                    let params = '&student_id=' + v
                    this.$axios.get(this.$models.getUrl('studentThresholdGroupResult', 'read') + params)
                        .then(resp => {
                            let thresholds = this.tabs.some(tab => tab.name == 'Thresholds')
                            if (resp.data.threshold_group_result.length && !thresholds) {
                                this.tabs.push({name: 'Thresholds', path: '#'})
                            } else if (!resp.data.threshold_group_result.length && thresholds) {
                                this.tabs = this.tabs.slice(0, this.tabs.length - 1)
                                if (this.selectedTab.name === 'Thresholds') {
                                    this.selectedTab = this.tabs.find(tab => tab.name === 'Tags')
                                }
                            }
                        })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tab-container {
        width: calc(100vw - 750px);
        .is-collapsed & {
            width: calc(100vw - 450px);
        }
    }
    .lower-scroll {
        overflow: scroll;
        height: calc(100vh - 142px);
        width: 100%;
    }
    .left-col {
        width: 250px;
        border-right: solid 1px #ccc;
        .lower-scroll {
            height: calc(100vh - 188px);
        }
    }
    .col-header {
        height: 60px;
        overflow: hidden;
        border-bottom: solid 1px #ccc;
        .left-col {
            border: none;
            &.headline {
                padding: 16px 0 0 24px;
                line-height: 28px;
            }
        }
        .fe-tabs-main {
            position: relative;
            z-index: 5;
            top: 7px;
        }
    }
    .selected-student { background-color: rgba(0, 108, 144, 0.2); }
</style>
<style lang="scss">
    .meetings-student-list-filter {
        width: 100%;
        height: 45px;
        border: none;
        border-bottom: solid 1px #ccc;
        position: relative;
        .filter-label {
            position: absolute;
            width: 50px;
            text-align: center;
            top: 10px;
        }
        .filter-field {
            position: absolute;
            left: 40px;
            top: 4px;
            width: 200px;
            text-align: center;
            &.v-text-field.v-text-field--solo {
                border: none;
                .v-input__slot {
                    border: none;
                }
            }
        }
    }
</style>
