<template>
    <fe-dialog
        :title="title"
        v-if="localValue"
        :acceptButtonDisabled="!validUpload || files.length === 0 || viewHeaders"
        :forceClose="!viewHeaders"
        :disableAutoclose="viewHeaders"
        :dismissButtonText="dismissBtn"
        acceptButtonText="Upload"
        width=800
        persistent
        dismissable
        @dismiss="dismiss"
        @close="dismiss"
        @accept="doSubmit"
    >
        <div v-show="!viewHeaders">
            <v-form v-model="validUpload">
                <v-file-input
                    required
                    multiple
                    flat solo dense
                    v-model="selectedFiles"
                    counter multiple label="File input"
                    accept=".csv,.txt,.dat,.xlsx"
                ></v-file-input>

                <div class="pa-2" v-for="(file, i) in files">
                    <div class="d-flex">
                        <div class="flex-grow-0">
                            <fe-label v-if="i===0">Upload Type</fe-label>
                            <fe-label v-else>&nbsp;</fe-label>
                            <fe-remote-combo
                                :items="uploadTypes"
                                itemText="name"
                                itemValue="id"
                                rootProperty="types"
                                :multiple="false"
                                :rules="$fieldValidators('select', 'Upload type', {required: true})"
                                class="mr-2"
                                v-model="file.upload_type"
                                @change="promptSelectAll(file, i)"
                                byId
                            />
                        </div>

                        <div class="flex-grow-1">
                            <fe-label v-if="i===0">Description/File</fe-label>
                            <fe-label v-else>&nbsp;</fe-label>
                            <v-text-field
                                v-model="file.upload_description"
                                required
                                class="mr-2"
                                :rules="$fieldValidators('text', 'Description', { required: true, limit: 255 })"
                                flat solo dense
                            />
                        </div>

                        <div v-if="file && requiresSchoolYear(file.upload_type)">
                            <fe-label>School Year</fe-label>
                            <fe-remote-combo
                                class="mr-2"
                                :url="$models.getUrl('schoolYear', 'read')"
                                rootProperty="years"
                                v-model="file.school_year_id"
                                byId
                            />
                        </div>

                        <div v-if="file && requiresSchool(file.upload_type)">
                            <fe-label>School</fe-label>
                            <fe-remote-combo
                                class="mr-2"
                                :url="$models.getUrl('school', 'read')"
                                rootProperty="schools"
                                v-model="file.school_id"
                                byId
                            />
                        </div>
                    </div>
                    <span v-if="file.upload_type && showViewHeaderLink(file.upload_type)" class="view-btn" @click="openViewHeaders(file.upload_type)"><u>View Headers</u></span>
                    <span class="filename-txt">File: {{file.filename}}</span>
                </div>
            </v-form>
        </div>
        <div v-show="viewHeaders">
            <span style="font-size: 12px; color: #dddddd;">Upload Type: {{ typeName }}</span>
            <fe-grid
                ref="grid"
                :rowData="uploadColumns"
                :columnDefs="columnDefs"
                :searchBar="false"
                :showAddRowBtn="false"
                :showDownload="false"
                :showToolbar="false"
                style="height: 500px;"
                displayDensity="small"
                disableInlineCreate
                suppressContextMenu
            />
        </div>
    </fe-dialog>
</template>

<script>
    export default {
        name: 'FileUpload',
        props: ['value'],
        watch: {
            localValue(v) {
                this.$emit('input', v)

                if (!v) {
                    this.files = []
                    this.selectedFiles = []
                }
            },
            value(v) {
                this.localValue = v

                this.$modelGet('uploadTypesColumns')
                    .then(types => this.uploadTypes = types)
            },
            selectedFiles(v) {
                this.files = []
                v.forEach((f,i) => {
                    this.files.push({ upload_type: null, filename: v[i].name, upload_description: v[i].name.split('.').slice(0, -1).join('.').substring(0, 255) })
                })
            }
        },
        data() {
            return {
                files: [],
                selectedFiles: [],
                uploadTypes: [],
                uploadColumns: [],
                columnDefs: [{
                    headerName: "Column Name",
                    field: "name",
                    editable: false
                }, {
                    headerName: "Required",
                    field: "required",
                    editable: false,
                    cellRenderer: v => {
                        return v.data.required === 1 ? `<i class="fe-grid-icon fas fa-check-circle"></i>` : ''
                    }
                }],
                localValue: false,
                validUpload: false,
                viewHeaders: false,
                typeName: '',
            }
        },
        created() {
            this.$data.localValue = this.$props.value

            this.$modelGet('uploadTypesColumns')
                .then(types => this.uploadTypes = types)
        },
        computed: {
            title() {
                return this.viewHeaders ? 'Upload Type Headers' : 'Student Data Upload'
            },
            dismissBtn() {
                return this.viewHeaders ? '< Back' : ''
            }
        },
        methods: {
            requiresSchoolYear(tp) {
                if (!tp) return false
                let type = this.uploadTypes.find(r => r.id === tp)
                return type.require_school_year ? true : false
            },
            requiresSchool(tp) {
                if (!tp) return false
                let type = this.uploadTypes.find(r => r.id === tp)
                return type.require_school ? true : false
            },
            showViewHeaderLink(tp) {
                if (!tp) return false
                let type = this.uploadTypes.find(r => r.id === tp)
                return typeof type.columns !== 'undefined'
            },
            promptSelectAll(file, i) {
                let tp = this.uploadTypes.find(rec=> rec.id === file.upload_type)

                if (i === 0 && file.upload_type && this.files.length>1) {
                    this.$messageBox({
                        title: 'Set Upload Types',
                        persistent: true,
                        maxWidth: 400,
                        message: 'Would you like to set all of the upload types to '+tp.name+'?',
                        actions: [{
                            text: 'Cancel'
                        }, {
                            text: 'Ok',
                            onClick: () => {
                                this.files.map(rec => rec.upload_type = file.upload_type)
                            }
                        }]
                    })
                }
            },
            doSubmit() {
                this.files.forEach((sel,i) => {
                    let formData = new FormData()
                    Object.keys(sel).forEach(field => {
                        formData.append(field, sel[field])
                    })
                    formData.append('upload_file', this.selectedFiles[i])
                    this.$setLoading(true)
                    this.$axios.post('fileUpload.php',  formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        this.$ecResponse(resp)
                        this.localValue = false
                    }).catch(err => {
                        console.error(err)
                    }).finally(() => { this.$setLoading(false) })
                })
            },
            openViewHeaders(tp) {
                if (!tp) return false
                let type = this.uploadTypes.find(r => r.id === tp)
                this.$refs.grid.resize()
                this.viewHeaders = true
                this.typeName = type.name
                this.uploadColumns = JSON.parse(type.columns)
            },
            dismiss() {
                if (this.viewHeaders) {
                    this.viewHeaders = false
                } else {
                    this.localValue = false
                    this.$emit('close')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .view-btn {
        position: relative;
        top: -20px;
        /*right: 60px;*/
        /*top: 70px;*/
        font-size: 12px;
        color: var(--v-primary-base);
        cursor: pointer;
    }

    .filename-txt {
        position: relative;
        float: right;
        top: -20px;
        font-size: 12px;
        color: #dddddd;
    }

</style>
