<template>
    <div
        class="sidebar flex-shrink-0"
        :class="{right, collapsed}"
        v-bind="$attrs"
        :style="{width: collapsed ? collapsedWidth + 'px' : expandedWidth + 'px'}"
    >
        <div class="title" :class="{rotated: collapsed}" :style="{'padding-left': paddingLeft}">
            {{title}}
        </div>
        <div class="content" v-show="!collapsed">
            <slot/>
        </div>
        <v-btn
            color="primary"
            fab
            dark
            small
            depressed
            class="collapse-btn"
            :style="{right: buttonRight, left: buttonLeft}"
            @click="collapsed = !collapsed"
        >
            <v-icon size=14>{{icon}}</v-icon>
        </v-btn>
    </div>
</template>

<script>
    export default {
        name: "CollapsibleSideBar",

        props: {
            title: {
                default: null
            },
            right: {
                type: Boolean,
                default: false
            },
            expandedWidth: {
                default: 300
            },
            collapsedWidth: {
                default: 60
            },
            startCollapsed: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                collapsed: false
            }
        },

        computed: {
            icon() {
                return ((this.right && !this.collapsed) || (!this.right && this.collapsed)) ? 'fas fa-chevron-right' : 'fas fa-chevron-left'
            },

            buttonRight() {
                let right = 'unset'

                if (this.right) {
                    right = this.collapsed ? (this.collapsedWidth + 10) + 'px' : (this.expandedWidth + 10) + 'px'
                }

                return right
            },

            buttonLeft() {
                let left = 'unset'

                if (!this.right) {
                    left = this.collapsed ? (this.collapsedWidth + 10) + 'px' : (this.expandedWidth + 10) + 'px'
                }

                return left
            },

            paddingLeft() {
                return this.collapsed ? ((this.collapsedWidth - 60) / 2) + 'px' : '0'
            }
        },

        mounted() {
            this.collapsed = this.startCollapsed
        }
    }
</script>

<style lang="scss" scoped>
    .sidebar {
        position: relative;
        height: 100%;
        transition: width 300ms;
        border-right: 2px solid #ECEDF1;
        padding: 0 16px;

        .rotated {
            white-space: nowrap;
            transform: rotate(90deg);
        }

        .collapse-btn {
            transition: left 300ms;
            position: fixed;
            bottom: 40px;
            border-radius: 50% !important;
            width: 32px;
            height: 32px;
        }

        &.right {
            border-right: none;
            border-left: 2px solid #ECEDF1;

            .collapse-btn {
                transition: right 300ms;
            }
        }
    }
</style>
