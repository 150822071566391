<template>
    <div>
        <v-card class="objective-card" flat>
            <v-card-title class="objective-title" :ref="`objectiveCard` + objective.id">
                <div class="d-flex objective-title-div" @mouseover="showVertObj = true"
                     @mouseleave="showVertObj = false">
                    <fe-tooltip :tooltip="getTooltip" :disabled="!showIcon || objDragging || showOptionsMenu"
                                nudge-right="50">
                        <div class="d-flex" @click="onOptionClick($event, objective)" style="cursor: pointer"
                             :style="{ visibility: showIcon ? 'visible' : 'hidden' }">
                            <v-icon class="mr-1" color="#050F2D" style="font-size: 24px !important ">fal fa-ellipsis-v
                            </v-icon>
                            <v-icon color="#050F2D" style="font-size: 24px !important">fal fa-ellipsis-v</v-icon>
                        </div>
                    </fe-tooltip>
                    <div class="flex-column ml-2" style="width: 100%">
                        <div class="d-flex objective-title-main">OBJECTIVE</div>
                        <div class="d-flex justify-space-between align-center" style="height: 20px">
                            {{ objective.name }}
                            <fe-icon-btn
                                v-if="objective.goals.length"
                                :useIcon="showGoals(objective.id) ? 'far fa-chevron-up' : 'far fa-chevron-down'"
                                usage="ghost"
                                @click="setObjective(objective.id)"
                                color="#000"
                                size="14"
                            />
                        </div>
                    </div>
                </div>
            </v-card-title>
            <v-card-text class="objective-content" v-if="objective.goals.length">
                <draggable
                    v-model="objective.goals"
                    @change="handleGoalOrder(objective, ...arguments)"
                    @start="handleStartOrder"
                    @end="handleEndOrder"
                    :move="handleCheckMove"
                    :removeCloneOnHide="false"
                    :scroll-sensitivity="300"
                    :force-fallback="true"
                    ghostClass="ap-draggable-ghost-class"
                >
                    <template v-if="showGoals(objective.id)" v-for="goal in objective.goals">
                        <goals
                            :goal="goal"
                            :goals="goals"
                            @edit-goal="$emit('edit-goal')"
                            @unlink-goal="$emit('unlink-goal')"
                            @delete-goal="deleteGoal"
                            @dismiss-task="$emit('dismiss-task')"
                        />
                    </template>
                </draggable>
            </v-card-text>
        </v-card>
        <v-menu
            v-model="showOptionsMenu"
            :position-x="optionsMenuPosition.x"
            :position-y="optionsMenuPosition.y"
            absolute
            content-class="options-menu"
        >
            <v-list>
                <v-list-item @click="editObj(objective)">Edit</v-list-item>
                <v-list-item @click="editObj(objective, true)">Duplicate</v-list-item>
                <v-list-item @click="editObj(objective, false, true)">Delete</v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import Goals from './Goals'
import draggable from 'vuedraggable'
import {mapState} from "vuex"

export default {
    name: 'Objectives',

    components: {Goals, draggable},

    props: {
        objective: {type: Object, default: () => {}},
        goals: {type: Array, default: () => []},
    },

    data() {
        return {
            openObjective: true,
            showVertObj: false,
            showOptionsMenu: false,
            optionsMenuPosition: {
                x: 0, y: 0
            },
        }
    },

    computed: {
        ...mapState('actionPlan', ['objDragging', 'objectiveStates']),
        showIcon() {
            return this.showVertObj && this.objective.id
        },
        getTooltip() {
            return "<div class='text-center'><b>Drag</b> to move <br/> <b>Click</b> to open menu</div>"
        },
    },

    methods: {
        onOptionClick(e, item) {
            let target = e.target
            if (target.localName === 'td') target = target.firstChild
            let element = target.getBoundingClientRect()
            this.optionsMenuPosition.x = element.left
            this.optionsMenuPosition.y = element.top + 30
            this.showOptionsMenu = true
        },

        editObj(obj, duplicate = false, destroy = false) {
            if (destroy) {
                this.$emit('delete-objective', obj)
            } else {
                this.$store.commit('actionPlan/set', {
                    key: 'objective',
                    value: obj
                })
                if (duplicate) this.$emit('duplicate-objective')
                else this.$emit('edit-objective')
            }
        },

        handleGoalOrder(objective, v) {
            this.$emit('reorder-goals', objective, v)
        },

        handleStartOrder() {
            this.$store.commit('actionPlan/set', {
                key: 'goalDragging',
                value: true
            })
            // if action items in goals, when closing goals for dragging,
            // window pushes users down the screen, so want to push user back
            // so that the entire objective is visible and can drag goals around
            let objective = 'objectiveCard' + this.objective.id
            this.$refs[objective].scrollIntoView()
        },

        handleEndOrder() {
            this.$store.commit('actionPlan/set', {
                key: 'goalDragging',
                value: false
            })
        },

        handleCheckMove(v) {
            if (!v.draggedContext.element.action_plan_objective_id) return false
        },

        deleteGoal(goal) {
            this.$emit('delete-goal', goal)
        },

        setObjective(id) {
            this.openObjective = this.objectiveStates.find(x => x.key == id) ? !this.objectiveStates.find(x => x.key == id).value : !this.openObjective
            this.$store.commit('actionPlan/setObjectiveStates', {
                key: id,
                value: this.openObjective
            })
        },

        showGoals(id) {
            if (this.objDragging) return false
            return this.objectiveStates.find(x => x.key == id) ? this.objectiveStates.find(x => x.key == id).value : this.openObjective
        },
    }
}
</script>

<style lang="scss" scoped>
.objective-card {
    background-color: #F5F6F8;
    border: 1px solid #E0E4E7;
    border-radius: 4px;
    margin-bottom: 10px;
}

.objective-title {
    background-color: #E0E1E6;
    font-size: 16px;
    padding: 10px;
    height: 55px;
    line-height: 1.5 !important;

    &-main {
        font-size: 10px;
    }

    &-div {
        width: 100%;
        height: 35px;
        align-items: center;
    }
}

.objective-content {
    padding: 0 !important;
}

.options-menu {
    .v-list-item {
        min-height: 38px !important;
    }
}

.ap-draggable-ghost-class {
    opacity: .5;
    background: #C8EBFB;
}
</style>
