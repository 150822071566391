<template>
    <div class="d-flex flex-fill flex-column text-center justify-center">
        <div class="mt-10">
            <v-icon color="success" style="font-size: 84px;">far fa-check-circle</v-icon>
        </div>

        <div class="fs20 mt-5">Success</div>

        <div class="fs16 mt-5">SLO has been successfully {{ isNewSLO ? 'created' : 'updated' }}</div>

        <a @click="onViewNewSLO">View my SLO</a>

        <fe-btn color="primary" @click="onDone">Done</fe-btn>
    </div>
</template>

<script>
    export default {
        name: 'Congratulations',
        props: {
            isNewSLO: { type: Boolean, required: true },
            id: { type: Number, required: true },
            parentWindowId: { type: Number, required: false }, // dockedWindowId of any window that may have launched this component
        },
        mounted() {
        },
        methods: {
            onViewNewSLO() {
                this.$emit('close')

                if (this.parentWindowId) {
                    this.$store.commit('global/removeDockedWindow', { dockedWindowId: this.parentWindowId, isStrict: true })
                }

                this.$dockableWindow({
                    name: this.name || 'View SLO',
                    component: 'slo',
                    attrs: {
                        id: this.id,
                    },
                })
            },
            onDone() {
                this.$emit('close')

                if (this.parentWindowId) {
                    this.$store.commit('global/removeDockedWindow', { dockedWindowId: this.parentWindowId, isStrict: true })
                }
            },
        },
    }
</script>

<style scoped>
.items > * {
    text-align: center;
}
</style>