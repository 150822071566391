<template>
    <div>
        <div class="d-flex">
            <v-toolbar color="white" flat dense>
                <v-toolbar-title>{{title}}</v-toolbar-title>
                <span class="id-text">{{studentRec.student_incident_id}}</span>
                <v-spacer />
                <fe-btn @click="add(true)" color="secondary" depressed>Add Incident</fe-btn>
            </v-toolbar>
        </div>
        <div>
            <div v-for="(behavior, i) in behaviors">
                <div v-if="i > 0">
                    <v-divider style="margin: 10px 0;"/>
                </div>
                <div class="d-flex" style="padding: 4px 12px">
                    <div style="flex-grow: 1; padding: 4px;">
                        <fe-remote-combo
                            v-model="behavior.incident_behavior_type_id"
                            byId
                            label="Type *"
                            ref="typeCombo"
                            rootProperty="incident_behavior_type"
                            :url='`incidentBehaviorType.php?action=get&incident_id=${studentRec.incident_id}`'
                            :defaultParams="incidentTypeParams()"
                            :rules="[v => !!v || 'Field is required']"
                            :filter="behaviorTypeFilter"
                            @input="clear(behavior)"
                            @change="$set(behavior,'incident_behavior_type',$event);setRequired($event)"
                        ></fe-remote-combo>
                    </div>
                    <div style="flex-grow: 1; padding: 4px;">
                        <fe-remote-combo
                            v-model="behavior.incident_behavior_id"
                            byId
                            label="Code *"
                            :disabled="!behavior.incident_behavior_type_id"
                            rootProperty="incident_behavior"
                            url='incidentBehavior.php?action=get'
                            :params="incidentBehaviorParams(behavior)"
                            :rules="[v => !!v || 'Field is required']"
                            @input="getCustomData(behavior)"
                            @change="behavior.incident_behavior = $event"
                        ></fe-remote-combo>
                    </div>
                    <div style="margin-top: 8px">
                        <div style="font-size: 12px; margin-left: 10px;">Primary</div>
                        <div style="padding-top: 8px">
                            <v-checkbox
                                primary hide-details
                                label="Yes"
                                :disabled="!!behavior.primary_flag"
                                v-model="behavior.primary_flag"
                                ref="primaryCheckBox"
                                @change="togglePrimary(behavior)"
                            ></v-checkbox>
                        </div>
                    </div>
                    <v-spacer></v-spacer>
                    <fe-icon-btn
                        v-if="!behavior.primary_flag"
                        style="margin-top: 25px"
                        useIcon="fas fa-minus-circle"
                        color="#bf0d00"
                        size="24px"
                        @click="remove(behavior)"
                    />
                </div>
                <div style="padding-left: 15px">
                    <div style="align-items:right" v-show="behavior.typeCount">
                        Incidents of this type in current year: <span style="font-weight:bold;">{{behavior.typeCount}}</span>
                    </div>
                </div>
                <div style="padding-left: 10px">
                    <template v-for="(descriptor, i) in behavior.descriptors">
                        <v-checkbox
                            :label="descriptor.description"
                            v-model="descriptor.checked"
                            hide-details
                        />
                    </template>
                </div>
                <div style="padding: 0 15px">
                    <template v-for="(field, i) in behavior.incident_fields">
                        <div style="padding-left: 0 15px">
                            <custom-field :field="field" />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import CustomField from './fields/CustomField'

export default {
    name: 'IncidentPanel',
    props: {
        value: {},
        studentRec: { type: Object },
        behaviors: { type: Array },
        panelVisibility: { type: Object }
    },
    components: {
        CustomField
    },
    computed: {
        ...mapState('global', ['currentYear', 'districtConfig']),
        title() {
            return this.behaviors.length > 1 ? 'Incidents' : 'Incident'
        },
    },
    mounted() {
        if(!this.behaviors.length) this.add()
    },
    methods: {
        getIncidents() {
            return this.behaviors
        },
        incidentTypeParams() {
            return {
                school_id: this.studentRec.school_id,
                active: 1
            }
        },
        incidentBehaviorParams(behavior) {
            return {
                school_id: this.studentRec.school_id,
                incident_behavior_type_id: behavior.incident_behavior_type_id,
                active: 1
            }
        },
        behaviorTypeFilter(rec,index,items) {
            return !rec.create_security_flag || rec.create_security_flag && this.$hasSecurity(rec.create_security_code)
        },
        togglePrimary(behavior) {
            if (!behavior.primary_flag) return

            this.behaviors.forEach(function(rec) {
                if (behavior !== rec) {
                    rec.primary_flag = !behavior.primary_flag
                }
            })
            this.setRequired()

        },
        remove(behavior) {
            if(!behavior.student_incident_behavior_id) {
                this.behaviors.splice(this.behaviors.indexOf(behavior),1)
            } else {
                this.$messageBox({
                    title: 'Confirm Remove',
                    message: 'Are you sure you want to remove this behavior type? This will take effect immediately.',
                    maxWidth: '400',
                    actions: [{
                        text: 'Cancel',
                    }, {
                        text: 'Remove',
                        primary: true,
                        onClick: () => {
                            let url = 'behaviorIncident.php?action=delete&property=student_incident_behavior'
                            let payload = {
                                student_incident_behaviors: [{
                                    student_incident_behavior_id: behavior.student_incident_behavior_id
                                }]
                            }
                            this.$axios.post(url,JSON.stringify(payload)).then( r => {
                                if(r.data) {
                                    if(r.data.success) {
                                        this.behaviors.splice(this.behaviors.indexOf(behavior),1)
                                        this.$snackbars.$emit('new', { text: r.data.msg, usage: 'success' })
                                    } else {
                                        this.$snackbars.$emit('new', { text: r.data.msg, usage: 'warning' })
                                    }
                                }
                            }).catch(err => {
                                console.warn('err: ',err);
                            })
                        }
                    }]
                })
            }
        },
        add(focus) {
            let cnt = this.districtConfig.INCIDENT_BEHAVIOR_CNT
            if (this.behaviors.length >= cnt) {
                this.$snackbars.$emit('new', { text: 'Your district has a limit of ' + cnt + ' responses per incident', usage: 'warning' })
                return
            }
            this.behaviors.push({
                primary_flag: this.behaviors.length > 0 ? false : true,
                descriptors: [],
                incident_fields: []
            })
            if(focus) {
                requestAnimationFrame(() => {
                    this.$refs.typeCombo[this.behaviors.length-1].focus()
                })
            }
        },
        clear(behavior) {
            if(behavior.incident_behavior_id) {
                behavior.incident_behavior_id = null
                this.$set(behavior,'descriptors',null)
                this.$set(behavior,'incident_fields',null)
            }
        },
        setRequired() {
            let me = this
            this.behaviors.forEach( b => {
                if(b.primary_flag) {
                    if(!b.incident_behavior_type) {
                        let url = `incidentBehaviorType.php?action=get&id=${b.incident_behavior_type_id}`
                        if (!b.incident_behavior_type_id) {
                          url = `incidentBehaviorType.php?action=get`;
                        }
                        this.$axios.get(url).then((response) => {
                            if (response && response.data) {
                                b.incident_behavior_type = response.data.incident_behavior_type[0]
                                me.panelVisibility.description = b.incident_behavior_type.show_description,
                                me.panelVisibility.motivation = b.incident_behavior_type.show_motivation,
                                me.panelVisibility.response = b.incident_behavior_type.show_response,
                                me.panelVisibility.parentContact = b.incident_behavior_type.show_parent_contact
                                me.$nextTick(() => {
                                    me.$emit('validate')
                                })
                            }
                        }).catch((err) => {
                            console.warn('err', err)
                        })
                    } else {
                        me.panelVisibility.description = b.incident_behavior_type.show_description,
                        me.panelVisibility.motivation = b.incident_behavior_type.show_motivation,
                        me.panelVisibility.response = b.incident_behavior_type.show_response,
                        me.panelVisibility.parentContact = b.incident_behavior_type.show_parent_contact
                    }

                }
            })
        },
        getCustomData(behavior) {
            this.$set(behavior,'descriptors',null)
            this.$set(behavior,'incident_fields',null)

            // get number of incidents this year
            this.$axios.get('behaviorIncident.php', {
                params: {
                    action: 'get',
                    property: 'big5',
                    student_id: this.studentRec.student_id,
                    school_year_id: this.currentYear.id,
                    incident_behavior_id: behavior.incident_behavior_id,
                    chart_types: 'incident_behavior_type',
                    show_all: 1
                }
            }).then( response => {
                if(response && response.data) {
                    let data = response.data.incidents.charts[0].data || []
                    this.$set(behavior,'typeCount',data.length ? data[0].total_count : 0)
                }
            })
            // get descriptors
            let url = 'incidentBehaviorDescriptor.php?action=get&incident_behavior_id='+behavior.incident_behavior_id
            this.$axios.get(url).then( response => {
                if(response && response.data) {
                    this.$set(behavior,'descriptors',response.data.behavior_descriptors)
                }
            }).catch( err => {
                console.warn('err: ',err)
            })
            // get custom fields
            url = 'incidentField.php?action=get&active=1&incident_behavior_type_id='+behavior.incident_behavior_type_id
            this.$axios.get(url).then( response => {
                if(response && response.data) {
                    this.$set(behavior,'incident_fields',response.data.incident_fields)
                }
            }).catch( err => {
                console.warn('err: ',err)
            })
        }
    }
}
</script>

<style scoped="true">

</style>
