<template>
    <div v-if="!noEditPermission" class="field-action-item d-flex flex-column" :style="rec ? '' : 'display: none !important'">
        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.task"
            :readParams="{ action: 'get', property: 'list' }"
            @read="tasks = $event"
        />

        <edit-action-item
            v-if="createDialog.show"
            :editing="editingTask"
            :extraParams="extraParams"
            standalone
            @dismiss="createDialog.show = false"
            @save="$emit('save')"
        />

        <div v-show="rec">
            <v-list v-show="loaded && tasks.length > 0" style="overflow: scroll; max-height: 200px;">
                <v-list-item v-for="(task, i) in tasks" @click="() => {}" :key="`task-`+i">
                    <v-list-item-content @click="loadTask(task)" class="action-item">
                        {{ task.name }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <fe-btn small usage="secondary" useIcon="fas fa-plus" @click="editingTask=null; createDialog.show=true">Add Action Item</fe-btn>
        </div>
    </div>
</template>

<script>
    import EditActionItem from '@/components/modules/actionItems/EditActionItem'

    export default {
        name: 'FieldActionItem',
        components: {
            EditActionItem
        },
        props: {
            rec: {},
            noEditPermission: {type:Boolean, default:false},
        },
        data() {
            return {
                loaded: false,
                showTask: false,
                tasks: [],
                params: {},
                createDialog: {
                    show: false
                },
                editingTask: null,
                extraParams: undefined
            }
        },
        mounted() {
            if (this.rec) {
                this.loadData(this.rec)
            }
        },
        watch: {
            rec(v) {
                if (v) this.loadData(v)
            }
        },
        methods: {
            loadData(v) {
                if(!this.noEditPermission) {
                    this.loaded = false
                    let params = {
                        form_instance_id: v.form_instance_id,
                        form_field_id: v.form_field_id,
                    }
                    this.extraParams = params
                    this.$refs.crud.read(params).finally(() => this.loaded=true)
                }
            },
            loadTask(rec) {
                this.editingTask = rec
                this.createDialog.show=true
            }
        }
    }
</script>

<style lang="scss" scoped>
.field-action-item {
    position: absolute;
    z-index: 1000;
    background-color: white;
    border-radius: 5px;
    padding: 2px;
    float: left;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid #e6e6e6;
    max-height: 300px;
}

.action-item {
    text-decoration: underline;
    color: var(--v-primary-base);
}

.close-btn {
    position: absolute;
    right: 20px;
    top: 5px;
}
</style>
