<template>
    <form-common-config
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #fieldSpecific="{rec}">
            <div class="d-flex">
                <fe-label :style="labelStyle">Field Align</fe-label>
                <fe-remote-combo
                    byId
                    style="margin-left:auto;"
                    v-model="rec.value_align"
                    itemValue="value"
                    :items="[
                        {value:'left', name:'To the Left'},
                        {value:'right', name:'To the Right'},
                        {value:'center', name:'Center'},
                        {value:'justify', name:'Justify'},
                    ]"
                    :clearable="false"
                ></fe-remote-combo>
            </div>
            <fe-label :style="labelStyle">Field Text</fe-label>
            <v-textarea flat solo dense
                :value="rec.display_value"
                @change="rec.display_value = $event"
                :rules="rules"
            />
        </template>
    </form-common-config>
</template>

<script>
import FormCommonConfig from '../FormCommonConfig'

export default {
    components: {
        FormCommonConfig,
    },
    computed: {
        labelStyle: me => ({ minWidth:'120px' }),
        rules: me => [
            v => (v && v.length) < 1000 || 'Field is too long',
            v => {
                if(!v) return true
                let openingTags, closingTags;
                
                v = v.replace(/<[^>]*\/\s?>/g, '');         // Remove all self closing tags
                v = v.replace(/<(br|hr|img).*?>/g, '');     // Remove all <br>, <hr>, and <img> tags
                openingTags = v.match(/<[^\/].*?>/g) || []; // Get remaining opening tags
                closingTags = v.match(/<\/.+?>/g) || [];    // Get remaining closing tags
                
                return openingTags.length === closingTags.length ? true : 'Invalid html detected';
            }
        ],
    },
}
</script>

<style lang="scss" scoped>
</style>
