import axios from 'axios'

export default () => {

    let instance = axios.create({
        withCredentials: true,
        baseURL: process.env.VUE_APP_BASE_URI.replace(/localhost/g, location.host)
    })

    instance.interceptors.request.use((config) => {
        if (config.data && typeof config.data === 'object') {
            config.data = JSON.stringify(config.data)
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    })

    return instance
}
