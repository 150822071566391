<template>
    <div>
        <span v-for="(crumb, i) in items" :key="`breadcrumb-`+i" :style="{fontSize: fontSize}">
            <span v-if="i > 0"> &gt; </span>  
            <span @click="doClick(i)" class="breadcrumb" :class="[{last: i == items.length-1 ? true : false}]">{{crumb[itemText]}}</span>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'Breadcrumb',
        watch: {
            value: {
                handler(v) {
                    this.items = v
                },
                deep: true
            }
        },
        props: {
            value: {
                type: Array,
                default() { return [] }
            },
            itemText: {
                type: String,
                default: 'name'
            },
            fontSize: {
                default: '14px'
            }
        },
        data() {
            return {
                items: []
            }
        },
        mounted() {
            this.items = this.value
        },
        methods: {
            doClick(i) {
                this.$emit('click', this.items[i], i) // return the item clicked
                this.items.splice(i + 1, this.items.length)
                this.$emit('input', this.items) // keep the v-model in sync
            }
        }
    }
</script>

<style lang="scss" scoped>
.breadcrumb {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        font-weight: bold;
    }

    &.last {
        font-weight: bold;
        text-decoration: none;
    }
}
</style>