<template>
    <div>
        <v-layout column>
            <div class="headline mb-1 d-flex">
                Manage Home Languages
            </div>

            <fe-crud-grid
                ref="grid"
                :config="$models.homeLanguage"
                :columnDefs="mainColumnDefs"
                :searchBar="false"
                displayDensity="medium"
                identifier="id"
                addRowBtnText="Add Home Language"
                refreshOnCreated
                refreshOnUpdated
                disableInlineCreate
                @read="home_language_code = $event"
                @beginCreate="beginEntry"
                domLayout="normal"
                style="height: calc(100vh - 175px); width: 100%;"
            >
                <template v-slot:cellDialog="{cell}">
                    <simple-grid-dialog-column
                        v-if="aliasCell"
                        :cell="cell"
                        title="Aliases"
                        fieldHeader="Alias"
                        fieldPlaceholder="Add an Alias"
                        field="code"
                        :fieldCounter="255"
                        :crudConfig="$models.homeLanguageAlias"
                        :defaultParams="{ home_language_id: cell.data.id }"
                        :insertDefaults="{ home_language_id: cell.data.id }"
                        @destroyed="load"
                        @created="load"
                        @close="closeDialogColumn"
                    />
                </template>
            </fe-crud-grid>

            <fe-dialog
                v-if="createDialog.show"
                title="Add Home Language"
                :footer="false"
                persistent
                disableAutoclose
                @close="dismissDialog"
            >
                <model-form
                    idProperty="id"
                    :records="createDialog.records"
                    :fieldConfig="fieldConfigs"
                    model="homeLanguage"
                    @cancel="dismissDialog"
                    @done="created"
                    :submitPrevent="true"
                />
            </fe-dialog>

            <fe-crud
                ref="crud"
                autoload
                :config="$models.formLanguage"
                @read="awsLanguages = $event"
            />

            <fe-crud
                ref="homeLanguageAliasCrud"
                autoload
                :config="$models.homeLanguageAlias"
                @read="aliases = $event"
            />

        </v-layout>
    </div>
</template>

<script>
    import SimpleGridDialogColumn from "../../../common/SimpleGridDialogColumn"
    import ModelForm from '@/components/common/form/ModelForm'

    export default {
        name: 'Languages',

        components: {SimpleGridDialogColumn, ModelForm},

        data() {
            return {
                awsLanguages: [],
                createDialog: {show: false, records: []},
                cell: undefined,
                aliasCell: false,
                requiredRule: [v => !_.isEmpty(v) || 'This field is required'],
                aliases: [],
            }
        },

        computed: {
            mainColumnDefs() {
                let me = this
                return [
                    {
                        colId: 'checkbox_column',
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        maxWidth: 70
                    }, {
                        colId: 'home_language',
                        headerName: 'Home Language',
                        field: 'home_language_display',
                        editable: true,
                        cellEditorFramework: "FeGridTextField",
                        width: 150,
                    }, {
                        colId: 'aws_language',
                        field: 'language_code_id',
                        headerName: 'Translated Language',
                        width: 150,
                        editable: true,
                        cellRenderer: v => {
                            let lang = me.awsLanguages.find(rec => rec.id == v.data.language_code_id)
                            if (lang) return lang.language_name
                            return ''
                        },
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: {
                            rowDataKey: "id",
                            mode: "edit",
                            items: this.awsLanguages,
                            keyProp: "id",
                            labelProp: "language_name",
                            disableChips: true
                        }
                    }, {
                        colId: 'aliases',
                        headerName: 'Aliases',
                        field: 'aliases',
                        maxWidth: 100,
                        editable: false,
                        cellRendererFramework: "FeDialogColumn",
                        onCellClicked: v => {
                            this.aliasCell = true
                            this.cellClicked(v)
                        },
                        valueGetter: v => me.aliases.filter(x => x.home_language_id == v.data.id).length
                    },
                ]
            },

            fieldConfigs() {
                return [
                    {
                        field: 'code',
                        label: 'Home Language Code',
                        component: 'v-text-field',
                        validatorKey: 'text',
                        validatorConfig: {required: true},
                        width: '100%',
                        attrs: {
                            flat: true,
                            solo: true,
                            dense: true
                        }
                    },
                    {
                        field: 'description',
                        label: 'Description',
                        component: 'v-text-field',
                        validatorKey: 'text',
                        validatorConfig: {required: false},
                        width: '100%',
                        attrs: {
                            flat: true,
                            solo: true,
                            dense: true,
                            placeholder: 'Optional'
                        }
                    }
                ]
            }
        },

        methods: {
            load() {
                this.$refs.grid.$refs.crud.read()
            },

            beginEntry() {
                this.resetEntry()
                this.createDialog.show = true
            },

            dismissDialog() {
                this.createDialog.show = false
            },

            resetEntry() {
                this.createDialog.records = [
                    {
                        home_language_display: undefined,
                        description: undefined,
                    }
                ]
            },

            created() {
                this.load()
                this.dismissDialog()
            },

            cellClicked(cell) {
                this.cell = cell
                this.$refs.grid.$refs.grid.setDialogCell(cell)
            },

            closeDialogColumn() {
                this.aliasCell = false
                this.$refs.grid.$refs.grid.openMenu = false
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>