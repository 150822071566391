<template>
    <div class="wrapper">
        <div v-if="toGuardian">
            <!-- url="message.php?action=get&parent_recipients=1" -->
            <fe-remote-combo
                label="Student"
                :items="students"
                rootProperty="messages"
                itemValue="id"
                itemText="full_name"
                autofocus
                @change="onStudentPick"
            />
            <fe-remote-combo
                label="Guardian"
                :items="guardians"
                itemValue="guardian_id"
                itemText="guardian_full_name"
                byId
                v-model="msgRec.to_guardian_id"
                :rules="$fieldValidators('select', null, { required: true })"
            />
        </div>

        <fe-remote-combo
            v-else
            label="Users"
            url="users.php?action=get&active=1"
            itemValue="id"
            itemText="user_full_name"
            byId
            v-model="msgRec.to_user_id"
            autofocus
            :rules="$fieldValidators('select', null, { required: true })"
        />

        <fe-editor
            class="compose"
            ref="editor"
            :editorConfig="editorConfig"
            v-model="msgRec.value"
            @ready="onReady"
            classic
            basic
        />

        <div class="d-flex">
            <fe-btn class="ml-auto" usage="ghost" @click="$emit('cancel')">Cancel</fe-btn>
            <fe-btn class="mp-0" usage="primary" :disabled="disableSend" @click="send">Send</fe-btn>
        </div>


        <fe-crud
            ref="crud"
            :config="$models.message"
        />

    </div>
</template>

<script>

export default {
    name: 'DirectCompose',
    components: {
    },

    props: {
        toGuardian: {type:Boolean, default:false}
    },

    computed: {
        disableSend() {
            return (!this.msgRec.to_guardian_id && !this.msgRec.to_user_id)
                || !this.msgRec.value || !this.msgRec.value.length
        },
    },

    mounted() {
        if(this.toGuardian) {
            this.$refs.crud.read({parent_recipients:1}).then(r =>{
                this.students = r.data.messages || []
            })
        }
    },

    methods: {
        onStudentPick(student) {
            this.msgRec.to_guardian_id = null
            this.msgRec.student_id = student ? student.id : null
            this.guardians = student ? student.guardians : []
        },
        onReady(editor) {
            // editor.editing.view.change( writer => {
            //     writer.setStyle( 'height', '210px', editor.editing.view.document.getRoot() );
            // })
            //
            // setTimeout(x=>{
            //     editor.editing.view.focus()
            // },400)
        },
        getMentionItems(queryText) {
            queryText = queryText.toLowerCase()
            return  [
                {id:'@one', name:'One', link:'https://one.com', mentionMeta:'u1' },
                {id:'@two', name:'Two', link:'https://two.com', mentionMeta:'u2'},
                {id:'@three', name:'Three', link:'https://three.com', mentionMeta:'u3'},
            ].filter(x => x.id.toLowerCase().includes(queryText) || x.name.toLowerCase().includes(queryText)).slice(0,10)
        },
        itemRenderer(item) {
            let itemEl = document.createElement('div')
            itemEl.classList.add('d-flex','flex-grow-1')
            itemEl.textContent = item.name
            return itemEl
        },
        onChange(e) {},
        send(e) {
            this.$refs.crud.create(this.msgRec)
            this.$emit('created',this.msgRec)
        },
    },


    data() {
        let to = this.toGuardian ? {
            new_parent_message: 1,
            to_guardian_id: null,
            student_id: null,
        } : {
            new_user_message: 1,
            to_user_id: null,
        }

        return {
            students: [],
            guardians: [],
            msgRec: {
                value: null,
                message_type_id: 12,
                ...to,
            },
            editorConfig: {
                // autoGrow_minHeight: 300,
                // autoGrow_onStartup: true,
                // height: 300,
                // startupFocus: true,

                // mention: {
                //     feeds: [{
                //         marker: '@',
                //         feed: this.getMentionItems,
                //         itemRenderer: this.itemRenderer,
                //         minimumCharacters: 0,
                //     }]
                // },
                toolbar: {
                    shouldNotGroupWhenFull: true,
                    items: [
                        'bold',
                        'italic',
                        'underline',
                        'link',
                        // these should be added back after EC-5635 and the other changes on backend to support them.
                        // 'fontColor',
                        // 'fontBackgroundColor',
                        'bulletedList',
                        'numberedList',
                    ]
                }
            },
        }
    },
}
</script>

<style lang="scss" scoped>
    // .wrapper {
    //     border: 1px solid lightgray;
    //     border-radius: 4px;
    // }
    // .compose ::v-deep .ck-toolbar {
    //     border: none;
    // }
    .compose ::v-deep .ck-editor__main {
    }
    // .compose ::v-deep .ck-editor__editable {
    .compose ::v-deep .ck-editor__editable_inline {
        // border: none;
        min-height: 240px;
        max-height: calc(100vh - 460px);
    }
</style>
