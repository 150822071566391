let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    interventionPlanList: {
        defaults: {
            endpoint: 'interventionPlan.php?property=list',
            rootProperty: 'plans'
        },
        read: standardCrud.read,
    },
    interventionPlanStudent: {
        defaults: {
            endpoint: 'interventionPlan.php?property=students',
            rootProperty: 'plans'
        },
        read: standardCrud.read,
    },
    interventionPlanFidelity: {
        defaults: {
            endpoint: 'interventionPlan.php?property=fidelity',
            rootProperty: 'plans'
        },
        read: standardCrud.read,
    },
    studentInterventionPlan: {
        defaults: {
            endpoint: 'studentInterventionPlans.php',
            rootProperty: 'students'
        },
        ...standardCrud
    },
    interventionROIStatus: {
        defaults: {
            endpoint: 'interventionView.php?property=roi_target_list',
            rootProperty: 'interventions'
        },
        read: standardCrud.read,
    },
    interventionLevel: {
        defaults: {
            endpoint: 'interventionLevels.php',
            rootProperty: 'intervention_levels'
        },
        ...standardCrud
    },
    interventionFidelityCode: {
        defaults: {
            endpoint: 'interventionAttendanceType.php?property=fidelity_codes',
            rootProperty: 'fidelity_codes'
        },
        ...standardCrud
    },
    interventionAttendanceType: {
        defaults: {
            endpoint: 'interventionAttendanceType.php',
            rootProperty: 'attendance_types'
        },
        ...standardCrud
    },
    interventionExitStatus: {
        defaults: {
            endpoint: 'crud.php?property=InterventionExitStatus',
            rootProperty: 'intervention_exit_status'
        },
        ...standardCrud
    },
    interventionType: {
        defaults: {
            endpoint: 'interventionTypes.php'
        },
        ...standardCrud
    },
    interventionTypeProcedure: {
        defaults: {
            endpoint: 'interventionTypeConfig.php?property=procedure',
            rootProperty: 'procedures'
        },
        ...standardCrud
    },
    interventionTypeSchool: {
        defaults: {
            endpoint: 'interventionTypes.php?property=schools',
            rootProperty: 'intervention_type_schools'
        },
        ...standardCrud
    },
    interventionTypeAttachment: {
        defaults: {
            endpoint: 'interventionTypes.php?property=attachments',
            rootProperty: 'intervention_type_attachments'
        },
        ...standardCrud
    },
    interventionTypeForm: {
        defaults: {
            endpoint: 'interventionTypes.php?property=forms',
            rootProperty: 'forms'
        },
        ...standardCrud
    },
    intervention: {
        defaults: {
            endpoint: 'interventionView.php',
            rootProperty: ''
        },
        ...standardCrud
    },
    academicMonitorChart: {
        defaults: {
            rootProperty: 'monitors',
            endpoint: 'studentMonitors.php?property=progress_charts'
        },
        ...standardCrud
    },
    studentMonitorScore: {
        defaults: {
            rootProperty: 'monitor_scores',
            endpoint: 'studentMonitors.php?property=monitor_scores'
        },
        ...standardCrud
    },
    academicMonitor: {
        defaults: {
            rootProperty: 'subcategories',
            endpoint: 'subcategories.php?property=probe&academic=1'
        },
        ...standardCrud
    },
    behaviorMonitor: {
        defaults: {
            rootProperty: 'subcategories',
            endpoint: 'subcategories.php?data_point_type_id=13&category_id=4&assessment_type_id=2'
        },
        ...standardCrud
    },
    interventionTypePeriod: {
        defaults: {
            rootProperty: 'periods',
            endpoint: 'interventionTypeConfig.php?action=get&property=monitor_period'
        },
        ...standardCrud
    },
    monitorPeriod: {
        defaults: {
            rootProperty: 'periods',
            endpoint: 'monitorPeriods.php'
        },
        ...standardCrud
    },
    monitorScoreStatus: {
        defaults: {
            rootProperty: 'status',
            endpoint: 'monitorScoreStatus.php'
        },
        ...standardCrud
    },
    interventionTypeMonitor: {
        defaults: {
            rootProperty: 'sub_cats',
            endpoint: 'interventionTypeConfig.php?property=sub_category'
        },
        ...standardCrud
    },
    studentMonitors: {
        defaults: {
            endpoint: 'studentMonitors.php'
        },
        ...standardCrud
    },
    scheduleDate: {
        defaults: {
            endpoint: 'scheduleDate.php',
            rootProperty: 'schedule_date'
        },
        ...standardCrud
    },
    interventionAnalysis: {
        defaults: {
            endpoint: 'interventionAnalysis.php',
            rootProperty: 'intervention_analyses'
        },
        ...standardCrud
    },
    interventionAnalysisSchedule: {
        defaults: {
            endpoint: 'interventionAnalysis.php',
            rootProperty: 'schedules',
            params: { property: 'schedule' }
        },
        ...standardCrud
    },
    interventionSchedule: {
        defaults: {
            endpoint: 'crud.php?property=Schedule',
            rootProperty: 'schedule'
        },
        ...standardCrud
    },
    monitorGoals: {
        defaults: {
            endpoint: 'monitorGoals.php',
            rootProperty: 'monitor_goals'
        },
        ...standardCrud
    },
    fastBridgePmPlans: {
        defaults: {
            endpoint: 'fastBridgePmPlans.php',
        },
        ...standardCrud
    },
}
