<template>
    <fe-dialog
        v-if="localValue"
        :title="title"
        width="800"
        persistent
        @accept="next"
        @close="localValue=false"
    >
        <div style="height: 70vh;" class="d-flex flex-fill flex-column">
            <v-slide-y-transition mode="out-in">
                <div v-if="stepIsStudents" key="students" class="d-flex flex-fill flex-column">
                    <selection-list v-model="selectedStudents" :items="students" identifier="student_id" multiple>
                        <template #row="{item}">
                            {{ item.student_full_name }}
                        </template>
                    </selection-list>
                </div>

                <div v-else-if="stepIsDetails" key="intervention-details">
                    <v-form v-model="planValid">
                        <fe-label>Intervention Group Label</fe-label>
                        <v-text-field
                            v-model="plan.intervention_plan_name"
                            :rules="$fieldValidators('text', null, { required: true, limit: 255})"
                            :counter="255"
                            flat solo dense
                        />

                        <fe-remote-combo
                            v-model="plan.intervention_plan_user_id"
                            :label="featureFlagMultipleInterventionists ? 'Primary Interventionist' : 'Interventionist'"
                            :items="interventionists"
                            itemValue="id"
                            itemText="user_full_name"
                            ref="interventionist"
                            :rules="$fieldValidators('select', null, { required: true })"
                            :multiple="false"
                            byId
                            @change="changePrimaryInterventionist"
                        />

                        <a
                            v-if="featureFlagMultipleInterventionists && !showAdditionalInterventionsts"
                            @click="showAdditionalInterventionsts = true"
                            style="position:relative;left:20px;top:-15px;"
                        >
                            <v-icon size="12" color="#006c90" style="position:relative;top:-2px;padding-right:10px;">fas fa-plus</v-icon> Add Interventionists
                        </a>

                        <div v-if="featureFlagMultipleInterventionists && showAdditionalInterventionsts">
                            <fe-label>
                                Additional Interventionists (Optional)
                                <fe-info-tip
                                    class="ml-2"
                                    style="display:inline-block;"
                                    tooltip="You can add up to 5 additional interventionists."
                                />
                            </fe-label>
                            <fe-remote-combo
                                v-model="plan.secondary_interventionists"
                                itemText="user_full_name"
                                itemValue="id"
                                chips multiple
                                :clearable="false"
                                :items="filteredSecondaryInterventionists"
                                :selectAllOption="false"
                                :rules="secondaryInterventionistValidation"
                                byId
                                @change="changeSecondaryInterventionists"
                            />
                        </div>

                        <fe-remote-combo
                            v-model="plan.intervention_level_id"
                            label="Level"
                            rootProperty="intervention_levels"
                            itemValue="id"
                            itemText="name"
                            ref="interventionLevel"
                            :multiple="false"
                            :rules="$fieldValidators('select', null, { required: true })"
                            :url="$models.getUrl('interventionLevel', 'read')+'&active=1'"
                            byId
                        />

                        <fe-remote-combo
                            v-model="plan.intervention_type_id"
                            label="Strategy"
                            itemValue="id"
                            itemText="name"
                            ref="interventionStrategy"
                            :multiple="false"
                            :rules="$fieldValidators('select', null, { required: true })"
                            :url="$models.getUrl('interventionType', 'read')+'&active=1&category_id='+plan.category_id"
                            byId
                        />

                        <fe-label>Phase Name</fe-label>
                        <v-text-field
                            v-model="plan.intervention_plan_phase_name"
                            :rules="$fieldValidators('text', null, {limit: 255})"
                            :counter="255"
                            flat solo dense
                        />

                        <fe-btn v-if="plan.intervention_category_id !== 4" usage="secondary" @click="showExistingGroups">
                            Move to Existing Group
                        </fe-btn>
                    </v-form>
                </div>

                <div v-else-if="stepIsSchedule" key="schedule">
                    <intervention-schedule
                        v-model="scheduleRec"
                        ref="schedule"
                        :timeRequired="plan.category_id === 4 ? false : true"
                        standalone
                    />
                </div>

                <div v-else-if="stepIsReview" key="review">
                    <div class="students-list">
                        <div
                            v-for="(s,i) in selectedStudents"
                            class="d-flex"
                            :class="{'students-list-item': i !== selectedStudents.length-1}"
                        >
                            <div class="pl-3 py-1" style="width: 50px;">
                                <v-avatar size="26px">
                                    <v-img :src="getStudentImg(s.student_id)"/>
                                </v-avatar>
                            </div>

                            <div
                                class="fs12 pl-3"
                                style="border-right: 1px solid #dddddd; width: 300px; padding-top: 6px;"
                            >
                                {{ s.student_full_name }}
                            </div>

                            <div class="fs12 pl-3 flex-grow-1" style="padding-top: 6px;">
                                {{ s.school_name }} - {{ s.grade_desc }} Grade
                            </div>
                        </div>
                    </div>

                    <v-row>
                        <v-col cols="6">
                            <div class="fs14 font-weight-bold">From</div>
                            <div class="intv-desc">
                                <div class="fs14">{{ original.intervention_plan_name }}</div>
                                <div class="fs12 d-flex">
                                    <div :title="original.intervention_type_name">{{ truncate(original.intervention_type_name, 12) }}</div>

                                    <v-icon style="font-size: 4px;" class="mx-2">fas fa-circle</v-icon>

                                    <div :title="originalInterventionists">{{ truncate(originalInterventionists, 15) }}</div>

                                    <v-icon style="font-size: 4px;" class="mx-2">fas fa-circle</v-icon>

                                    <div :title="original.intervention_level_name">{{ truncate(original.intervention_level_name, 12) }}</div>
                                </div>

                                <div class="fs12 secondary-text">
                                    {{ getScheduleStatement(original) }}
                                </div>

                                <div class="fs12 secondary-text">
                                    <v-icon class="secondary-text mr-2">far fa-calendar-alt</v-icon>

                                    {{ getDateStatement(original) }}

                                    <v-icon class="secondary-text mr-2">far fa-clock</v-icon>

                                    {{ getTimeStatement(original) }}
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="6">
                            <div class="fs14 font-weight-bold">To</div>
                            <div class="intv-desc">
                                <div class="fs14">{{ plan.intervention_plan_name }}</div>

                                <div class="fs12 d-flex">
                                    <div :title="modifiedType">{{ truncate(modifiedType, 12) }}</div>

                                    <v-icon style="font-size: 4px;" class="mx-2">fas fa-circle</v-icon>

                                    <div :title="modifiedUser">{{ truncate(modifiedUser, 15) }}</div>

                                    <v-icon style="font-size: 4px;" class="mx-2">fas fa-circle</v-icon>

                                    <div :title="modifiedLevel">{{ truncate(modifiedLevel, 12)  }}</div>
                                </div>

                                <div class="fs12 secondary-text">
                                    {{ getScheduleStatement(scheduleRec) }}
                                </div>

                                <div class="fs12 secondary-text">
                                    <v-icon class="secondary-text mr-2">far fa-calendar-alt</v-icon>

                                    {{ getDateStatement(scheduleRec) }}

                                    <v-icon class="secondary-text mr-2">far fa-clock</v-icon>

                                    {{ getTimeStatement(scheduleRec) }}
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <div v-if="plan.intervention_category_id !== 4" class="mx-3">
                        <fe-label>Decision Rationale</fe-label>
                        <v-text-field
                            v-model="decisionRationale"
                            :rules="$fieldValidators('text', null, { required: true, limit: 255})"
                            :counter="255"
                            flat solo dense
                        />
                    </div>
                </div>

                <div v-else-if="stepIsSuccess" key="success" class="d-flex flex-fill flex-column text-center pt-10">
                    <div class="mt-10">
                        <v-icon color="success" style="font-size: 48px;">far fa-check-circle</v-icon>
                    </div>

                    <div class="fs20 mt-5">Success</div>

                    <div class="fs16 mt-5">Successfully created new phase</div>
                </div>

                <div v-else-if="stepIsMoveStudents" key="move-students" class="d-flex flex-fill flex-column">
                    <!-- intervention move -->
                    <fe-grid
                        :columnDefs="moveColumnDefs"
                        ref="moveGrid"
                        :rowData="moveRowData"
                        style="height: 100%;"
                        displayDensity="small"
                        :showAddRowBtn="false"
                        rowSelection="single"
                        @rowSelected="moveRecords = $refs.moveGrid.gridApi.getSelectedRows()"
                    />
                </div>

                <div v-else-if="stepIsReviewMove" key="review-move" class="d-flex flex-fill flex-column">
                    <!-- intervention move -->
                    <fe-label>Effective Date</fe-label>
                    <fe-date-picker v-model="moveEffectiveDate"/>

                    <div class="fs14 font-weight-bold">Moving to Intervention Plan</div>
                    <div class="intv-desc">
                        <div class="fs14">{{ moveRecords[0].intervention_plan_name }}</div>
                        <div class="fs12 d-flex">
                            <div>{{ moveRecords[0].intervention_type_name }}</div>

                            <v-icon style="font-size: 4px;" class="mx-2">fas fa-circle</v-icon>

                            <div>{{ moveRecords[0].user_full_name }}</div>

                            <v-icon style="font-size: 4px;" class="mx-2">fas fa-circle</v-icon>

                            <div>{{ moveRecords[0].intervention_level_name }}</div>
                        </div>

                        <div class="fs12 secondary-text">
                            {{ getScheduleStatement(moveRecords[0]) }}
                        </div>

                        <div class="fs12 secondary-text">
                            <v-icon class="secondary-text mr-2">far fa-calendar-alt</v-icon>
                            {{ getDateStatement(moveRecords[0]) }}
                        </div>
                    </div>
                </div>

                <div v-else-if="stepIsSuccessMove" key="success-move" class="d-flex flex-fill flex-column text-center pt-10">
                    <div class="mt-10">
                        <v-icon color="success" style="font-size: 48px;">far fa-check-circle</v-icon>
                    </div>

                    <div class="fs20 mt-5">Success</div>

                    <div class="fs16 mt-5">Successfully moved student to new plan</div>
                </div>

                <div v-else-if="stepIsPointSheet" key="point-sheet" class="pt-10">
                    <behavior-schedule
                        v-model="behaviorRec"
                        ref="behaviorSchedule"
                        :showAddBtn="false"
                        @period-change="getBehaviorChanges" @expectation-change="getBehaviorChanges"
                        @selectedExpectations="selectedExpectations = $event"
                        @selectedPeriods="selectedPeriods = $event"
                    />
                </div>
            </v-slide-y-transition>
        </div>

        <template #footer>
            <div class="d-flex" style="width: 100%;">
                <fe-btn v-if="!stepIsSuccess && !stepIsSuccessMove" usage="tertiary" @click="back" :disabled="stepIsStudents">Back</fe-btn>

                <v-spacer/>

                <fe-btn v-if="!stepIsSuccess && !stepIsSuccessMove" usage="ghost" @click="localValue=false">Cancel</fe-btn>

                <fe-btn :disabled="nextDisabled" @click="next">{{ btnText }}</fe-btn>
            </div>
        </template>
    </fe-dialog>
</template>

<script>
import SelectionList from '@/components/common/SelectionList'
import InterventionSchedule from '@/components/modules/intervention/creation/Schedule'
import BehaviorSchedule from '@/components/modules/intervention/creation/BehaviorSchedule'
import {mapState} from 'vuex'

const STATE_STUDENTS = 'students'
const STATE_DETAILS = 'intervention-details'
const STATE_SCHEDULE = 'intervention-schedule'
const STATE_POINT_SHEET = 'behaviore-schedule'
const STATE_MOVE_STUDENTS = 'move'
const STATE_REVIEW = 'review'
const STATE_REVIEW_MOVE = 'review-move'
const STATE_SUCCESS = 'success'
const STATE_SUCCESS_MOVE = 'success-move'

const stepTitles = {
    [STATE_STUDENTS]: 'Select Students',
    [STATE_DETAILS]: 'Intervention Details',
    [STATE_SCHEDULE]: 'Plan Schedule',
    [STATE_POINT_SHEET]: 'Point Sheet',
    [STATE_MOVE_STUDENTS]: 'Select Plan',
    [STATE_REVIEW]: 'Change Review',
    [STATE_REVIEW_MOVE]: 'Change Review',
    [STATE_SUCCESS]: 'Success',
    [STATE_SUCCESS_MOVE]: 'Success',
}

const stepNextButtonText = {
    [STATE_REVIEW]: 'Save',
    [STATE_REVIEW_MOVE]: 'Move',
    [STATE_SUCCESS]: 'Okay',
    [STATE_SUCCESS_MOVE]: 'Okay',
}

export default {
    name: 'NewPhase',

    components: {
        SelectionList,
        InterventionSchedule,
        BehaviorSchedule
    },

    props: {
        value: {
            type: Boolean,
            default: false
        },
        interventionPlanId: {
            type: Number,
            required: true
        },
        defaultStudent: {}
    },

    data() {
        return {
            localValue: null,
            state: STATE_STUDENTS,
            plan: {},
            original: {},
            students: [],
            interventionists: [],
            showAdditionalInterventionsts: false,
            selectedStudents: [],
            scheduleRec: {
                start_time: null,
                end_time: null,
                frequency_cd: null,
                frequency_cnt: null
            },
            behaviorRec: {
                monitors: []
            },
            planValid: false,
            modifiedScheduleStatement: '',
            modifiedType: '',
            modifiedUser: '',
            modifiedLevel: '',
            decisionRationale: '',
            moveColumnDefs: [{
                minWidth: 70,
                maxWidth: 70,
                headerCheckboxSelection: false,
                checkboxSelection: true,
                colId: 'checkbox_column'
            }, {
                headerName: "Plan Name",
                field: "name",
                width: 200
            }, {
                headerName: "Strategy",
                field: "intervention_type_name",
                width: 100
            }, {
                headerName: "Level",
                field: "intervention_level_name",
                width: 100
            }, {
                headerName: "Interventionist",
                field: "user_full_name",
                width: 100
            }, {
                headerName: "Start",
                field: "start_date",
                width: 100
            }, {
                headerName: "End",
                field: "end_date",
                width: 100
            }],
            moveRowData: [],
            moveRecords: [],
            moveEffectiveDate: null,
            hasBehaviorMonitors: false,
            selectedExpectations: [],
            selectedPeriods: [],
        }
    },

    computed: {
        ...mapState('intervention', ['editBehaviorPlan']),

        stepIsDetails() { return this.state === STATE_DETAILS },
        stepIsMoveStudents() { return this.state === STATE_MOVE_STUDENTS },
        stepIsPointSheet() { return this.state === STATE_POINT_SHEET },
        stepIsReview() { return this.state === STATE_REVIEW },
        stepIsReviewMove() { return this.state === STATE_REVIEW_MOVE },
        stepIsSchedule() { return this.state === STATE_SCHEDULE },
        stepIsStudents() { return this.state === STATE_STUDENTS },
        stepIsSuccess() { return this.state === STATE_SUCCESS },
        stepIsSuccessMove() { return this.state === STATE_SUCCESS_MOVE },

        title() {
            return stepTitles[this.state]
        },

        btnText() {
            return stepNextButtonText[this.state] || 'Next'
        },

        nextDisabled() {
            switch (this.state) {
                case STATE_STUDENTS:
                    return this.selectedStudents.length === 0
                case STATE_DETAILS:
                    return !this.planValid
                case STATE_SCHEDULE:
                    let start = this.$dayjs(this.scheduleRec.start_date).format('YYYY-MM-DD')
                    let end = this.$dayjs(this.scheduleRec.end_date).format('YYYY-MM-DD')
                    let isBefore = this.$dayjs(end).isBefore(start)
                    if (isBefore) return true
                    if (this.$refs.schedule && !this.$refs.schedule.isValid) return true
                    return this.plan.category_id !== 4 && (!this.scheduleRec.start_time || !this.scheduleRec.end_time)
                case STATE_POINT_SHEET:
                    return !this.hasBehaviorMonitors && (!this.selectedExpectations.length || !this.selectedPeriods.length)
                case STATE_MOVE_STUDENTS:
                    return !this.moveRecords.length || this.moveRecords.length > 1
                case STATE_REVIEW:
                    return !this.decisionRationale && this.plan.category_id !== 4
                default:
                    return false
            }
        },

        featureFlagMultipleInterventionists () {
            return this.$store.getters['flags/flags']['ec-multiple-interventionists'] === true
        },
        secondaryInterventionistValidation: () => [
            v => v.length <= 5 || 'You can add up to 5 secondary interventionists.'
        ],
        filteredSecondaryInterventionists() {
            // don't show the primary interventionist
            const primaryInterventionist = this.plan.intervention_plan_user_id
            return this.interventionists.filter(secondaryInterventionist => secondaryInterventionist.id != primaryInterventionist)
        },

        originalInterventionists() {
            return this.original.interventionists.map(interventionist => interventionist.name).join(', ')
        },
    },

    watch: {
        value(v) {
            this.localValue = v
        },

        localValue(v) {
            this.$emit('input', v)
        },

        'plan.intervention_level_id'(v) {
            if (!this.$refs.interventionLevel) return

            let r = this.$refs.interventionLevel.localItems.find(r => r.id === v)
            this.modifiedLevel = r.name
        },

        'plan.intervention_type_id'(v) {
            this.$store.commit('intervention/set', {
                key: 'interventionTypeId',
                value: {id: v}
            })

            if (!this.$refs.interventionStrategy) return
            let r = this.$refs.interventionStrategy.localItems.find(r => r.id === v)
            this.modifiedType = r.name
        },
    },

    mounted() {
        this.localValue = this.value
        this.loadData()
    },

    destroyed() {
        this.$store.commit('set', {
            module: 'intervention',
            state: 'editBehaviorPlan',
            value: false
        })
    },

    methods: {
        getBehaviorChanges() {
            this.$refs.behaviorSchedule.formatData(false)
            this.$nextTick(() => {
                this.hasBehaviorMonitors = this.behaviorRec.monitors.length !== 0
            })
        },

        moveToPlan() {
            let r = this.moveRecords[0]
            this.$setLoading(true)
            let newDate = new Date(this.moveEffectiveDate)
            let formattedDate = this.$dayjs(newDate).format('YYYY-MM-DD')
            this.$axios.post('interventionPlan.php?action=create', {
                new_intervention_plan_id: r.id,
                intervention_plan_id: this.plan.intervention_plan_id,
                student_ids: this.selectedStudents.map(r => r.student_id),
                start_date: formattedDate
            }).then(resp => {
                this.$ecResponse(resp)
                if (resp.data.success) {
                    this.state = STATE_SUCCESS_MOVE
                }
            }).finally(() => {
                this.$setLoading(false)
            })
        },

        showExistingGroups() {
            this.$axios.get('interventionPlan.php?action=get&property=list&active=1&category_id=' + this.plan.category_id)
                .then(r => {
                    this.moveRowData = this.$ecResponse(r, 'plans')
                    this.state = STATE_MOVE_STUDENTS
                    this.moveEffectiveDate = this.$dayjs(new Date).format('MM/DD/YYYY')
                })
        },

        getScheduleStatement(rec) {
            let cd = rec.frequency_cd || rec.intervention_plan_intervention_frequency_cd
            let cnt = rec.frequency_cnt || rec.intervention_plan_intervention_frequency_cnt
            let secCnt = rec.frequency_cnt || rec.intervention_plan_intervention_secondary_cnt
            let days = rec.week_days || rec.intervention_plan_intervention_week_days
            let stmt = ''
            switch (cd) {
                case 'WEEK':
                    stmt = `Every ${cnt} week(s) on ${days}`
                    break
                case 'BLOCK':
                    stmt = `Block schedule with ${cnt} days on and ${secCnt} days off`
                    break
                case 'NOSCHED':
                    stmt = `As needed with ${cnt} total sessions`
                    break
            }

            return stmt.replace(/,/g, '')
        },

        getDateStatement(rec) {
            let start = rec.start_date || rec.intervention_plan_intervention_start_date
            let end = rec.end_date || rec.intervention_plan_intervention_end_date
            return this.$dayjs(start).format('MM/DD/YYYY') + ' to ' + this.$dayjs(end).format('MM/DD/YYYY')
        },

        getTimeStatement(rec) {
            let start = rec.start_time || rec.intervention_plan_intervention_start_time
            let end = rec.end_time || rec.intervention_plan_intervention_end_time

                if (!start || !end) return ''
                return this.$dayjs('2020-01-01 ' + start).format('hh:mm A') + ' to ' + this.$dayjs('2020-01-01 ' + end).format('hh:mm A')
            },

        loadData() {
            this.$modelGet('interventionPlanStudent', { intervention_plan_id: this.interventionPlanId })
                .then(s => {
                    this.students = s

                    if (this.defaultStudent) {
                        let found = this.students.find(r => r.student_id === this.defaultStudent.student_id)
                        this.selectedStudents = [found]
                    }
                    this.plan = {...this.students[0]}
                    this.plan.secondary_interventionists = this.plan.interventionists.slice(1).map(interventionist => interventionist.user_id)
                    this.showAdditionalInterventionsts = this.plan.secondary_interventionists.length > 0
                    this.original = {...this.students[0]}
                    this.modifiedUser = this.plan.intervention_plan_user_full_name
                    this.modifiedLevel = this.plan.intervention_level_name
                    this.modifiedType = this.plan.intervention_type_name

                    this.scheduleRec = {
                        start_date: this.$dayjs(new Date()).format('YYYY-MM-DD'),
                        end_date: this.plan.intervention_plan_intervention_end_date,
                        frequency_cd: this.plan.intervention_plan_intervention_frequency_cd,
                        frequency_cnt: this.plan.intervention_plan_intervention_frequency_cnt,
                        secondary_cnt: this.plan.intervention_plan_intervention_secondary_cnt,
                        start_time: this.plan.intervention_plan_intervention_start_time,
                        end_time: this.plan.intervention_plan_intervention_end_time,
                        week_days: this.plan.intervention_plan_intervention_week_days,
                        user_id: this.plan.intervention_plan_user_id
                    }
                })
            // load interventionists
            this.$axios.get(this.$models.getUrl('user', 'read') + '&active=1')
                .then(response => {
                    this.$data.interventionists = response.data
                })
        },

        next() {
            switch (this.state) {
                case STATE_STUDENTS:
                    this.state = STATE_DETAILS
                    break
                case STATE_DETAILS:
                    this.state = STATE_SCHEDULE
                    break
                case STATE_SCHEDULE:
                    this.state = this.plan.intervention_category_id === 4 ? STATE_POINT_SHEET : STATE_REVIEW
                    break
                case STATE_POINT_SHEET:
                    // if selected expectations or periods exist ("required" in config settings)...
                    // run getBehaviorChanges to make sure they are included
                    // in the list of monitors passed when saving
                    if (this.selectedExpectations || this.selectedPeriods) {
                        this.getBehaviorChanges()
                    }
                    this.state = STATE_REVIEW
                    break
                case STATE_REVIEW:
                    this.plan.category_id === 4 ? this.doNewBehaviorPhase() : this.doNewPhase()
                    break
                case STATE_MOVE_STUDENTS:
                    this.state = STATE_REVIEW_MOVE
                    break
                case STATE_REVIEW_MOVE:
                    this.moveToPlan()
                    break
                case STATE_SUCCESS:
                case STATE_SUCCESS_MOVE:
                    this.$emit('created')
                    this.localValue = false
                    break
            }
        },

        getPreviousStep() {
            switch (this.state) {
                case STATE_DETAILS:
                    return STATE_STUDENTS
                case STATE_SCHEDULE:
                    return STATE_DETAILS
                case STATE_POINT_SHEET:
                    return STATE_SCHEDULE
                case STATE_REVIEW:
                    return this.plan.intervention_category_id === 4 ? STATE_POINT_SHEET : STATE_SCHEDULE
                case STATE_MOVE_STUDENTS:
                    return STATE_DETAILS
                case STATE_REVIEW_MOVE:
                    return STATE_MOVE_STUDENTS
                case STATE_SUCCESS_MOVE:
                    return STATE_REVIEW_MOVE
            }
        },

        back() {
            this.state = this.getPreviousStep()
        },

        doNewBehaviorPhase() {
            let p = this.plan

            let obj = {
                category_id: p.category_id,
                intervention_plan_id: this.interventionPlanId,
                intervention_type_id: p.intervention_type_id,
                intervention_level_id: p.intervention_level_id,
                name: p.intervention_plan_name,
                intervention_plan_phase_name: p.intervention_plan_phase_name,
                user_id: this.scheduleRec.user_id,
                secondary_user_id: p.secondary_interventionists,
                students: this.selectedStudents.map(r => {
                    return {student_id: r.student_id}
                }),
                monitors: [{
                    schedule_frequency_cd: this.scheduleRec.frequency_cd,
                    schedule_frequency_cnt: 1,
                    schedule_start_date: this.scheduleRec.start_date,
                    schedule_end_date: this.scheduleRec.end_date,
                    default_start_time: this.scheduleRec.start_time,
                    default_end_time: this.scheduleRec.end_time,
                    schedule_week_days: this.scheduleRec.week_days ? this.scheduleRec.week_days : '',
                    monitor_user_id: this.scheduleRec.user_id,
                    monitor_score_options: this.behaviorRec.monitors
                }]
            }

            this.$axios.post('interventions.php?action=create', obj)
                .then(resp => {
                    if (resp) {
                        if (resp.data.success) {
                            this.state = STATE_SUCCESS
                        }
                    }
                })
        },

        doNewPhase(confirmed) {
            let p = this.plan
            let rec = {
                intervention_plan_id: this.interventionPlanId,
                intervention_type_id: p.intervention_type_id,
                intervention_level_id: p.intervention_level_id,
                intervention_plan_name: p.intervention_plan_name,
                intervention_plan_phase_name: p.intervention_plan_phase_name,
                intervention_plan_intervention_end_date: this.scheduleRec.end_date,
                intervention_plan_intervention_start_date: this.scheduleRec.start_date,
                intervention_plan_intervention_frequency_cd: this.scheduleRec.frequency_cd,
                intervention_plan_intervention_frequency_cnt: this.scheduleRec.frequency_cnt,
                intervention_plan_intervention_secondary_cnt: this.scheduleRec.secondary_cnt,
                intervention_plan_intervention_start_time: this.scheduleRec.start_time,
                intervention_plan_intervention_end_time: this.scheduleRec.end_time,
                intervention_plan_intervention_week_days: this.scheduleRec.week_days,
                intervention_plan_user_id: this.scheduleRec.user_id,
                secondary_interventionists: p.secondary_interventionists,
                decision_rationale: this.decisionRationale,
                student_ids: this.selectedStudents.map(r => r.student_id),
                intervention_plan_session_duration: this.$getTimeDiff(this.scheduleRec.start_time, this.scheduleRec.end_time),
                intervention_plan_phase_line: 1
            }

            this.$axios.post('interventionPlan.php?action=create&allow_new_plan=' + (confirmed ? 1 : 0), rec)
                .then(r => {
                    this.$ecResponse(r)

                    if (r) {
                        if (r.data.success) {
                            this.state = STATE_SUCCESS
                        }
                    }
                })
                .finally(() => {
                    this.$emit('new-phase', this.defaultStudent)
                })
        },

        getStudentImg(student_id) {
            return this.$axios.defaults.baseURL + this.$models.getUrl('studentImage', 'read') + '&id=' + student_id
        },

        changePrimaryInterventionist() {
            this.scheduleRec.user_id = this.plan.intervention_plan_user_id
            // remove selected primary interventionist from secondary interventionists if present
            this.plan.secondary_interventionists = this.plan.secondary_interventionists.filter(
                secondaryInterventionist => secondaryInterventionist != this.plan.intervention_plan_user_id
            )
            this.refreshModifiedInterventionists()
        },

        changeSecondaryInterventionists() {
            this.refreshModifiedInterventionists()
        },

        refreshModifiedInterventionists() {
            const interventionists = [this.plan.intervention_plan_user_id, ...this.plan.secondary_interventionists]
            const interventionistNames = {}
            this.interventionists.forEach(interventionist => interventionistNames[interventionist.id] = `${interventionist.fname} ${interventionist.lname}`)
            this.modifiedUser = interventionists.map(interventionist => interventionistNames[interventionist]).join(', ')
        },

        truncate(str, length) {
            return str.substring(0, length) + (str.length > length ? '...' : '')
        }
    },
}
</script>

<style lang="scss" scoped>
.students-list {
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    margin: 2px 10px 4px 10px;

    &-item {
        vertical-align: middle;
        border-bottom: 1px solid #DDDDDD;
    }
}

.intv-desc {
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    padding: 8px 8px 8px 8px;
}
</style>
