let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    districtInfo: {
        defaults: {
            endpoint: 'districts.php',
            rootProperty: 'districts'
        },
        ...standardCrud
    },

    districtLogo: {
        defaults: {
            endpoint: 'logo.php'
        }
    },

    schoolGroups: {
        defaults: {
            endpoint: 'crud.php?property=SchoolGroupV',
            rootProperty: 'school_group_v'
        },
        ...standardCrud
    },

    schoolGroupSchool: {
        defaults: {
            endpoint: 'crud.php?property=SchoolGroupSchool',
            rootProperty: 'school_group_school'
        },
        ...standardCrud
    },

    schoolAliases: {
        defaults: {
            endpoint: 'crud.php?property=SchoolAlias',
            rootProperty: 'school_alias'
        },
        ...standardCrud
    },

    gradeMap: {
        defaults: {
            endpoint: 'crud.php?property=GradeMap',
            rootProperty: 'grade_map'
        },
        ...standardCrud
    },

    schoolLink: {
        defaults: {
            endpoint: 'crud.php?property=SchoolLink',
            rootProperty: 'school_link'
        },
        ...standardCrud
    },

    schoolDaysOff: {
        defaults: {
            endpoint: 'scheduleExcludeDates.php',
            rootProperty: 'dates'
        },
        ...standardCrud
    }
}
