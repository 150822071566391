<template>
    <div class="flex-fill flex-column" ref="container">
        <assessment-group-list
            ref="assessmentGroupList"
            v-if="activePage.show == 'index' && groups"
            :groups="groups"
            @selectGroup="selectGroup"
            @selectCategory="selectCategory"
            @selectAdvancedSettings="selectAdvancedSettings"
            @createAssessment="createItem('assessments', $event)"
            @manageAssessments="manageItems('assessments', $event, 'Assessments')"
            @createWindow="createItem('windows', $event)"
            @createCommonWindow="insertItem('windows', $event)"
            @manageWindows="manageItems('windows', $event, 'Windows')"
            @createBand="createItem('bands', $event)"
            @manageBands="manageItems('bands', $event, 'Performance Bands')"
            @manageDataEmbargoes="manageItems('embargoDates', $event, 'Embargo Dates')"
            :bands="bands"
            @saveGroup="saveGroup"
            @reload="$refs.crudGroups.read()"
            :sec="sec"
        />

        <manage-category v-else-if="activePage.show == 'category'"/>

        <advanced-settings v-else-if="activePage.show == 'advancedSettings'"/>

        <assessment-group-summary
            ref="assessmentGroupSummary"
            v-else-if="activePage.show == 'summary' && selectedGroup && groups"
            @selectGroupDetail="selectGroupDetail"
            :group="selectedGroup"
            :groups="groups"
            @switchGroup="switchGroup"
            @createItem="createItem"
            @saveGroup="saveGroup"
            :sec="sec"
            @reloadCurrentGroup="reloadCurrentGroup"
            :isMissingWindowDates="isMissingWindowDates"
        />

        <assessment-group-score-display
            ref="assessmentGroupScoreDisplay"
            v-else-if="activePage.show == 'scoreDisplay' && selectedGroup && groups"
            :group="selectedGroup"
            :groups="groups"
            @switchGroup="switchGroup"
            @reloadCurrent="reloadCurrent"
        />

        <assessment-group-score-details
            ref="assessmentGroupScoreDetails"
            v-else-if="activePage.show == 'scoreDetails' && selectedGroup && groups"
            :group="selectedGroup"
            :groups="groups"
            @switchGroup="switchGroup"
        />

        <assessment-group-assessments
            ref="assessmentGroupAssessments"
            v-else-if="activePage.show == 'assessments' && selectedGroup && groups"
            :group="selectedGroup"
            :groups="groups"
            @switchGroup="switchGroup"
            @createAssessment="createItem('assessments', $event)"
            :calcTypes="calcTypes"
            :categories="categories"
            :assessmentTypes="assessmentTypes"
            @reloadCurrent="reloadCurrent"
        />

        <assessment-group-windows
            ref="assessmentGroupWindows"
            v-else-if="activePage.show == 'windows' && selectedGroup && groups"
            :group="selectedGroup"
            :groups="groups"
            @switchGroup="switchGroup"
            @createWindow="createItem('windows', $event)"
            @reloadCurrent="reloadCurrent"
            :toAddWindowDates="toAddWindowDates"
        />

        <assessment-group-bands
            ref="assessmentGroupBands"
            v-else-if="activePage.show == 'bands' && selectedGroup && groups && bands && bandDescriptors"
            :group="selectedGroup"
            :groups="groups"
            :bands="scopedBands"
            :bandDescriptors="bandDescriptors"
            @fetchBand="fetchBand"
            @switchGroup="switchGroup"
            @selectGroupDetail="selectGroupDetail"
            @create="createItem('bands')"
            @reloadCurrent="reloadCurrent"
        />

        <assessment-group-band-detail
            ref="assessmentGroupBandDetail"
            v-else-if="activePage.show == 'band-detail' && selectedGroup && bands && bandDescriptors && selectedBand && bandRangeTypes"
            :group="selectedGroup"
            :bands="scopedBands"
            :bandDescriptors="bandDescriptors"
            :band="selectedBand"
            :bandTypes="bandTypes"
            :bandRangeTypes="bandRangeTypes"
            :isScoreDetailBand="selectedBand && !!selectedBand.score_detail_type_id"
            @switchBand="switchBand"
            @closeBand="closeBand"
            @fetchBand="fetchBand"
            @fetchBandDescriptors="fetchBandDescriptors"
            @reloadCurrent="reloadCurrent"
        />

        <assessment-group-embargo-dates
            ref="assessmentGroupEmbargoDates"
            v-else-if="activePage.show == 'embargoDates' && selectedGroup"
            :groups="groups"
            :group="selectedGroup"
            @switchGroup="switchGroup"
            @reloadCurrent="reloadCurrent"
        />

        <fe-crud
            ref="crudScoreCalcType"
            autoload
            :config="$models.scoreCalcType"
            @read="calcTypes = $event"
        />

        <fe-crud
            ref="crudCategory"
            autoload
            :config="$models.category"
            @read="categories = $event"
        />

        <fe-crud
            ref="crudAssessmentType"
            autoload
            :config="$models.assessmentType"
            @read="assessmentTypes = $event"
        />

        <assessment-creator
            ref="assessmentCreator"
            :group="selectedGroup"
            :loadedAssessments="assessments"
            :loadedAssessmentTypes="assessmentTypes"
            :loadedCategories="categories"
            :loadedCalcTypes="calcTypes"
            @create="insertItem('assessments', $event)"
        />

        <fe-crud
            ref="crudAssessments"
            :config="$models.subcategory"
            @created="itemInserted('assessments', $event)"
            refresh-on-config
        />

        <fe-crud
            ref="crudGroups"
            autoload
            :config="$models.assessment"
            :readParams="{
                additional_data: 1
            }"
            refresh-on-updated
            @read="loadGroups"
        />

        <fe-crud
            v-if="selectedGroup"
            ref="crudCurrentGroup"
            :config="$models.assessment"
            :readParams="{
                additional_data: 1
            }"
            @read="loadGroup"
        />

        <window-creator
            ref="windowCreator"
            :group="selectedGroup"
            @create="insertItem('windows', $event)"
        />

        <fe-crud
            ref="crudWindows"
            :config="$models.dataPointName"
            @created="itemInserted('windows', $event)"
            refresh-on-config
        />

        <fe-crud
            ref="crudBands"
            :config="$models.targetSet"
            @read="updateBands"
            @created="itemInserted('bands', $event)"
        />

        <fe-crud
            ref="crudBandDescriptors"
            :config="$models.targetSetDescriptor"
            @read="bandDescriptors = $event"
        />

        <fe-crud
            autoload
            ref="crudBandTypes"
            :config="$models.targetSetType"
            @read="bandTypes = $event"
        />

        <fe-crud
            autoload
            ref="crudBandRangeTypes"
            :config="$models.targetType"
            @read="bandRangeTypes = $event"
        />

        <assessment-group-band-editor
            ref="bandEditor"
            :group="selectedGroup"
            @create="insertItem('bands', $event)"
        />

    </div>
</template>

<script>
    import Vuex from 'vuex'
    import AssessmentGroupList from './AssessmentGroupList'
    import AssessmentGroupSummary from './AssessmentGroupSummary'
    import AssessmentGroupScoreDisplay from './AssessmentGroupScoreDisplay'
    import AssessmentGroupScoreDetails from './AssessmentGroupScoreDetails'
    import AssessmentGroupAssessments from './AssessmentGroupAssessments'
    import AssessmentGroupBands from './AssessmentGroupBands'
    import AssessmentGroupBandDetail from './AssessmentGroupBandDetail'
    import AssessmentGroupBandEditor from './AssessmentGroupBandEditor'
    import AssessmentGroupWindows from './AssessmentGroupWindows'
    import AssessmentGroupEmbargoDates from './AssessmentGroupEmbargoDates'
    import AssessmentCreator from "./AssessmentCreator"
    import WindowCreator from "./WindowCreator"
    import ManageCategory from './Category'
    import AdvancedSettings from './AdvancedSettings'
    import { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'

    export default {
        name: 'ManageAssessment',
        components: {
            AssessmentGroupList,
            AssessmentGroupSummary,
            AssessmentGroupScoreDisplay,
            AssessmentGroupScoreDetails,
            AssessmentGroupAssessments,
            AssessmentGroupBands,
            AssessmentGroupBandDetail,
            AssessmentGroupBandEditor,
            AssessmentGroupWindows,
            AssessmentGroupEmbargoDates,
            AssessmentCreator,
            WindowCreator,
            ManageCategory,
            AdvancedSettings,
        },
        provide() {
            this.localStore = new Vuex.Store({
                state: {
                    selectedIncidentType: null,
                    selectedResponse: null
                },
                mutations: {},
                actions: {}
            })

            return {
                localStore: this.localStore
            }
        },
        data() {
            return {
                goToBand: null,
                groups: null,
                show: 'types',
                selectedGroup: null,
                selectedBand: null,
                calcTypes: null,
                categories: null,
                assessments: [],
                assessmentTypes: null,
                bands: null,
                bandDescriptors: null,
                bandTypes: null,
                bandRangeTypes: null,
                cfgs: {
                    assessments: {
                        crudRef: 'crudAssessments',
                        creatorRef: 'assessmentCreator',
                        screenRef: 'assessmentGroupAssessments',
                        rootProp: 'subcategories',
                        nameListProp: 'assessment_names'
                    },
                    windows: {
                        crudRef: 'crudWindows',
                        creatorRef: 'windowCreator',
                        screenRef: 'assessmentGroupWindows',
                        rootProp: 'names',
                        nameListProp: 'window_names'
                    },
                    bands: {
                        crudRef: 'crudBands',
                        creatorRef: 'bandEditor',
                        screenRef: 'assessmentGroupBands',
                        nameListProp: 'band_names'
                    }
                },
                sec: {
                    bands: 'MANAGE_TARGET_SETS',
                    groups: 'MANAGE_ASSESSMENT_GROUP',
                    kpis: 'MANAGE_ACHIEVEMENT_DASHBOARD',
                    embargoes: 'MANAGE_DATA_EMBARGOES',
                },
                isMissingWindowDates: false,
                toAddWindowDates: false
            }
        },
        computed: {
            ...mapState('global', ['sessionUser', 'currentYear']),
            ...mapState('manage', ['assessment', 'breadcrumbs']),
            valuesLoaded() { return !!this.groups },
            activePage() { return this.$getLastCrumb('Assessments') },
            scopedBands() {
                return (this.selectedGroup && this.bands)
                    ? this.bands.filter(band => band.data_point_type_id == this.selectedGroup.id)
                    : []
            },
        },
        methods: {
            closeBand () {
                this.selectedBand = null
                this.$refs.crudBands.read()
                this.$store.commit('manage/setCrumbs', {
                    key: "Assessments",
                    val: [{
                        name: 'Assessments',
                        show: 'index'
                    },{
                        name: this.selectedGroup.name,
                        show: 'summary'
                    },{
                        name: 'Performance Bands',
                        show: 'bands'
                    }]
                })
            },
            updateBands (records) {
                this.bands = records
                if (this.selectedBand) this.selectedBand = records.find(itm => itm.id == this.selectedBand.id)
                if (this.goToBand) {
                    let match = records.find(itm => itm.name == this.goToBand)
                    if (match) {
                        this.selectedBand = match
                        this.$store.commit('manage/setCrumbs', {
                            key: "Assessments",
                            val: [{
                                name: 'Assessments',
                                show: 'index'
                            },{
                                name: this.selectedGroup.name,
                                show: 'summary'
                            },{
                                name: 'Performance Bands',
                                show: 'bands'
                            },{
                                name: match.name,
                                show: 'band-detail'
                            }]
                        })
                    }
                    this.goToBand = null
                    this.fetchBandDescriptors()
                }
            },
            switchGroup(itm) {
                let bcs = this.breadcrumbs["Assessments"].map(bc => {
                    return (bc.show === 'summary' && bc.name === this.selectedGroup.name)
                        ? Object.assign({}, bc, { name: itm.name })
                        : Object.assign({}, bc)
                })
                this.$store.commit('manage/setCrumbs', {
                    key: "Assessments",
                    val: bcs
                })
                this.selectedGroup = itm
            },
            switchBand(itm) {
                let bcs = this.breadcrumbs["Assessments"].map(bc => {
                    return (bc.show === 'band-detail' && bc.name === this.selectedBand.name)
                        ? Object.assign({}, bc, { name: itm.name })
                        : Object.assign({}, bc)
                })
                this.$store.commit('manage/setCrumbs', {
                    key: "Assessments",
                    val: bcs
                })
                this.selectedBand = itm
            },
            selectCategory(itm) {
                this.$nextTick(() => {
                    this.go(itm)
                })
            },
            selectAdvancedSettings(itm) {
                this.$nextTick(() => {
                    this.go(itm)
                })
            },
            selectGroup(itm, go = true) {
                this.selectedGroup = itm
                if (go) {
                    this.$nextTick(() => {
                        this.go({ name: itm.name, show: 'summary' })
                    })
                }
            },
            selectGroupDetail(itm) {
                if (itm.show == 'band-detail') this.selectedBand = itm.band
                this.go({ name: itm.crumbName, show: itm.show })
            },
            manageItems(type, itm, title) {
                this.selectedGroup = itm
                this.$store.commit('manage/addCrumb', {
                    key: "Assessments",
                    val: { name: itm.name, show: 'summary' }
                })
                this.go({ name: title, show: type })
            },
            createItem(type, itm) {
                if(type == 'windowDates') {
                    if(!itm) {
                        itm = { 
                            crumbName: "Windows",
                            name: "<i style='color: #ea0101;'></i> Windows <span style=\"margin-left: 20px; font-size: 12px;\">13</span>",
                            show: "windows",
                            security: this.sec.groups
                        }
                    }
                    this.toAddWindowDates = true
                    this.selectGroupDetail(itm)
                } else {
                    if (itm) this.selectedGroup = itm
                    let cfg = this.cfgs[type]
                    setTimeout(function() {
                        if (cfg) this.$refs[cfg.creatorRef].open()
                    }.bind(this), 100)
                }
            },
            insertItem(type, itm) {
                let cfg = this.cfgs[type]
                if (cfg) this.$refs[cfg.crudRef].create(itm)
                if (type == 'bands') this.goToBand = itm.target_set_name
            },
            itemInserted(type, record) {
                let me = this
                let cfg = this.cfgs[type]
                if (cfg) {
                    this.$refs[cfg.crudRef].read({ data_point_type_id: me.selectedGroup.id }).then(rsp => {
                        let updates = []
                        if (cfg.rootProp) {
                            updates = rsp.data && rsp.data[cfg.rootProp] && Array.isArray(rsp.data[cfg.rootProp])
                                ? rsp.data[cfg.rootProp]
                                : null
                        } else {
                            updates = rsp.data && Array.isArray(rsp.data) ? rsp.data : null
                        }
                        if (updates) {
                            let updateItem = { id: me.selectedGroup.id }
                            updateItem[cfg.nameListProp] = updates.map(r => r.name)
                            me.updateGroups(updateItem)
                        }
                        if (type == 'bands') {
                            this.fetchBandDescriptors(() => this.$refs.crudBands.read())
                        }
                    })
                    try {
                        if (this.$refs[cfg.screenRef]) this.$refs[cfg.screenRef].refresh()
                    } catch (err) { }
                }
            },
            loadGroups (records) {
                if (this.selectedGroup) {
                    let itm = records.find(grp => grp.id == this.selectedGroup.id)
                    if (itm) this.switchGroup(itm)
                }
                this.groups = records
            },
            loadGroup (records) {
                if (records.length === 1 && this.selectedGroup) {
                    this.groups = this.groups.map(i => {
                        return (i.id == this.selectedGroup.id)
                            ? records[0]
                            : i
                    })
                    this.selectedGroup = this.groups.find(i => i.id == this.selectedGroup.id)
                }
            },
            reloadCurrent() {
                this.$refs.crudCurrentGroup.read({
                    id: this.selectedGroup.id
                })
            },
            reloadCurrentGroup() {
                this.reloadCurrent()
                this.$refs.crudWindows.read({school_year_id: this.currentYear.id, data_point_type_id: this.selectedGroup.id, with_open_window_count: 1, show_all: 1})
                    .then((resp) => {
                        this.isMissingWindowDates = resp.data.names.some(x => !x.end_day || !x.start_day)
                    })
            },
            updateGroups (params) {
                let me = this
                let items = Array.isArray(params)
                    ? params
                    : [params]
                items.forEach((item) => {
                    let record = this.groups.find(r => r.id === item.id)
                    if (record) Object.assign(record, item)
                    if (this.selectedGroup && this.selectedGroup.id === record.id) this.selectedGroup = record
                })
                this.groups = [].concat(this.groups)
            },
            saveGroup (obj) {
                this.$refs.crudGroups.update(obj).then(() => this.$refs.crudGroups.read())
            },
            go(page) {
                this.$addCrumb('Assessments', page)
            },
            fetchBand (obj) {
                if (obj && obj.goToBand) this.goToBand = obj.goToBand
                this.$refs.crudBands.read()
            },
            fetchBandDescriptors (cb) {
                this.$refs.crudBandDescriptors.read().then(rsp => {
                    if (cb) cb()
                })
            },
            fetchBandRangeTypes () {
                this.$refs.crudBandRangeTypes.read()
            },
            scrollTop () {
                let scrollContainer = this.$refs.container && this.$refs.container.closest('.content')
                if (scrollContainer) scrollContainer.scrollTop = 0
            }
        },
        watch: {
            activePage (v) {
                if (v.show == 'summary') {
                    this.scrollTop()
                }
                if (v.show == 'bands' || v.show == 'band-detail') {
                    if (!this.bands) this.fetchBand()
                    if (!this.bandDescriptors) this.fetchBandDescriptors()
                }
                if (v.show == 'band-detail') {
                    if (!this.bandRangeTypes) this.bandRangeTypes()
                }
                if (v.show == 'index') {
                    this.toAddWindowDates = false
                    this.isMissingWindowDates = false
                }
            },
            selectedGroup(v) {
                if(v.id) {
                    this.$refs.crudWindows.read({school_year_id: this.currentYear.id, data_point_type_id: v.id, with_open_window_count: 1, show_all: 1})
                        .then((resp) => {
                            this.isMissingWindowDates = resp.data.names.some(x => !x.end_day || !x.start_day)
                        })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.active-page {
    text-decoration: underline;
    font-weight: bold !important;
}
</style>
