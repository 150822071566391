<template>
    <div class="d-flex flex-column flex-fill">
        <component
            v-for="(item, i) in items"
            :key="'incident-item-' + i"
            v-if="item.persist || activePage.show === item.show"
            v-show="activePage.show === item.show"
            :is="item.component"
            :chartParams="chartParams"
            :chartFilters="chartFilters"
            @chart="showChart"
        />
    </div>
</template>

<script>
    import LandingPage from "./LandingPage"
    import IncidentCharting from "./IncidentCharting"

    export default {
        name: 'Incidents',

        components: {
            LandingPage,
            IncidentCharting
        },

        data() {
            return {
                activePage: {
                    name: 'Incidents',
                    show: 'index',
                    persist: true,
                    component: 'landing-page'
                },
                chartParams: {},
                chartFilters: {},
                items: [{
                    name: 'Incidents',
                    show: 'index',
                    persist: true,
                    component: 'landing-page'
                }, {
                    name: 'Incident Charting',
                    show: 'charts',
                    persist: false,
                    component: 'incident-charting'
                }]
            }
        },

        methods: {
            showChart(params, filters) {
                this.chartParams = params
                this.chartFilters = filters
                if (params) {
                    this.activePage = {
                        name: 'Incident Charting',
                        show: 'charts',
                        persist: false,
                        component: 'incident-charting'
                    }
                } else {
                    this.activePage = {
                        name: 'Incidents',
                        show: 'index',
                        persist: true,
                        component: 'landing-page'
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped></style>
