<template>
    <div class="advanced-demographic-filters">
        <advanced-demographic-filter
            v-for="demographic in activeDemographics"
            :key="`demographic-${demographic.id}`"
            :demographic="demographic"
            :value="params[`demo_${demographic.id}`]"
            @result="update(demographic, $event)"
        />
    </div>
</template>

<script>
import AdvancedDemographicFilter from './AdvancedDemographicFilter'

export default {
    name: 'AdvancedDemographicFilters',

    components: {AdvancedDemographicFilter},

    props: {
        value: {
            type: Object, default: () => {
            }
        },
        vertical: {type: Boolean, default: false}
    },

    data() {
        return {
            params: {}
        }
    },

    computed: {
        demographics() {
            return this.$store.getters['thresholds/demographic/all']
        },

        activeDemographics() {
            return this.demographics
                ? this.demographics.filter(dmo => dmo.active && dmo.can_search)
                : []
        },

        querystring() {
            return this.$objectToParams(this.params)
        }
    },

    watch: {
        params(v) {
            this.$emit('input', v)
        },

        value: {
            handler(v) {
                this.params = v
            },
            immediate: true
        }
    },

    mounted() {
        this.$store.dispatch('thresholds/demographic/load')
    },

    methods: {
        update(demographic, val) {
            let k = `demo_${demographic.id}`
            let obj = Object.assign({}, this.params)
            if (val === null) {
                delete obj[k]
            } else {
                obj[k] = val.encoded
            }
            this.params = obj
        }
    }
}
</script>
