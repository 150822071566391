export const manage = {
    namespaced: true,
    state: {
        incident: {
            page: 'types',
            incidentBehaviorTypeId: null
        },
        incidentTypes: [],
        districtPreferences: [],
        breadcrumbs: {
            'Assessments': [{
                name: 'Assessments',
                show: 'index'
            }],
            'Attendance': [{
                name: 'Attendance Configuration',
                show: 'index'
            }],
            'District & Schools': [{
                name: 'District & Schools',
                show: 'index'
            }],
            'Incidents': [{
                name: 'Incident Configuration',
                show: 'index'
            }],
            'Interventions': [{
                name: 'Intervention Configuration',
                show: 'index'
            }],
            'Users & Security': [{
                name: 'Users & Security',
                show: 'index'
            }],
            // if psportal is enabled
            'Students & Guardians': [{
                name: 'Students & Guardians',
                show: 'index'
            }],
            // if psportal is not enabled
            'Students': [{
                name: 'Students',
                show: 'index'
            }],
            'SmartForms': [{
                name: 'My Forms',
                show: 'mine'
            }],
            'Instance Information': [{
                name: 'Instance Information',
                show: 'index'
            }],
            'Assessment Usage': [{
                name: 'Assessment Usage',
                show: 'index'
            }],
            'Automation Manager': [{
                name: 'Automation Manager',
                show: 'index'
            }]
        },
        manageAssessmentsShowDisabled: false,
        manageBandsShowDisabled: false,
    },
    mutations: {
        addCrumb(state, payload) {
            if (Array.isArray(payload.val)) {
                state.breadcrumbs[payload.key] = state.breadcrumbs[payload.key].concat(payload.val)
            } else {
                if (payload.index) {
                    state.breadcrumbs[payload.key].length = payload.index
                    state.breadcrumbs[payload.key].push(payload.val)
                } else {
                    state.breadcrumbs[payload.key].push(payload.val)
                }
            }

        },
        removeCrumb(state, payload) {
            let breadcrumb = state.breadcrumbs[payload.key]
            if (!isNaN(payload.count) && breadcrumb.length > payload.count + 1) {
                breadcrumb.splice(breadcrumb.length - payload.count, payload.count)
            }
        },
        setCrumbs(state, payload) {
            state.breadcrumbs[payload.key] = payload.val
        },
        setShowDisabledAssessments(state, payload) {
            state.manageAssessmentsShowDisabled = payload
        },
        setShowDisabledBands(state, payload) {
            state.manageBandsShowDisabled = payload
        },
    },
    actions: {
        setAssessmentsDisabledState({commit}, value) {
            commit('setShowDisabledAssessments', value)
        },
        setBandsDisabledState({commit}, value) {
            commit('setShowDisabledBands', value)
        },
    },
    getters: {
        getAssessmentsDisabledState: state => state.manageAssessmentsShowDisabled,
        getBandsDisabledState: state => state.manageBandsShowDisabled,
    }
}
