<template>
    <div class="advanced-demographic-filter justify-space-between" :class="{ 'vertical': vertical }" v-if="enabled">
        <fe-label class="filter-label flex-grow-0 flex-shrink-0">{{ name }}</fe-label>

        <div class="filter-component-area" :style="{width: vertical?'95%': null}">
            <template v-if="type == 'boolean'">
                <fe-remote-combo
                    v-if="options"
                    v-model="entry"
                    class="filter-component"
                    itemText="value"
                    itemValue="id"
                    :items="options"
                    :style="{ width: rcInputWidth }"
                    hide-details
                    by-id
                />
            </template>

            <template v-else-if="type == 'option'">
                <component
                    v-if="options"
                    v-model="selectedOptions"
                    class="filter-component"
                    itemText="display_name"
                    itemValue="id"
                    :is="useRemoteCheckboxPanels ? 'fe-stepper-checkbox-panel' : 'fe-remote-exclude-combo'"
                    :canExclude="useRemoteCheckboxPanels"
                    :items="options"
                    :style="{ width: rcInputWidth, 'margin-bottom': '2px' }"
                    :byId="byId"
                    hide-details
                />
            </template>

            <div v-else>
                <v-menu :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-on="on"
                            class="filter-component"
                            :value="description"
                            flat
                            solo
                            dense
                            clearable
                            readonly
                            hide-details
                            @click:clear="entry = null"
                        />
                    </template>

                    <div class="pa-3" style="background-color: #fff; overflow-y: auto;">
                        <fe-remote-combo
                            v-model="operator"
                            itemText="text"
                            itemValue="code"
                            placeholder="Select an Operator"
                            :items="operators"
                            :clearable="false"
                            by-id
                        />

                        <fe-date-picker
                            v-if="type === 'date'"
                            v-model="entry"
                            :placeholder="isBetween ? 'Select First Date' : 'Select Date'"
                            :rules="entryRules"
                            clearable
                        />

                        <v-text-field
                            v-else
                            v-model="entry"
                            :placeholder="isBetween ? 'Enter First Value' : 'Enter a Value'"
                            :rules="entryRules"
                            flat
                            solo
                            dense
                            clearable
                            autofocus
                        />

                        <template v-if="isBetween">
                            <fe-date-picker
                                v-if="type=='date'"
                                v-model="secondEntry"
                                placeholder="Select Second Date"
                                :rules="entryRules"
                                clearable
                            />

                            <v-text-field
                                v-else
                                v-model="secondEntry"
                                placeholder="Enter Second Value"
                                :rules="entryRules"
                                flat
                                solo
                                dense
                                clearable
                            />
                        </template>
                    </div>
                </v-menu>
            </div>
        </div>
    </div>
</template>

<script>

/**
 *  This can be bound to with v-model.  It accepts a value that should be formatted like:
 *
 *   - For "option" types, please send in just the id of the selected option:
 *     - "23"
 *     - "4534"
 *   - For all other types send in a string like "[operator]:[value]":
 *     - "eq:my string"
 *     - "lte:34"
 *   - For "between" operators, send in a string like "[operator]:[value]:[value]":
 *     - "bt:10:100"
 *     - "bt:2020-01-01:2020-12-31"
 *
 *  This will emit the following events upon a change:
 *
 *   - "input":
 *     - A string formatted the same way as the "value" prop above, or a null.
 *   - "result":
 *     - An object that contains all the necessary elements if you wanted
 *       to construct your own query strings or handle in some other way.
 */

export default {
    name: 'AdvancedDemographicFilter',

    props: {
        byId: {type: Boolean, default: false},
        demographic: {type: Object, required: true},
        value: {type: String, default: null},
        vertical: {type: Boolean, default: false},
        // Optionally prefer use of checkbox lists instead of a single dropdown with chips
        useRemoteCheckboxPanels: {type: Boolean, default: false},
        // Only don't want to reset entry,operator on demographic watch if coming from search bar filters
        // Otherwise on reopen of search bar filters, all are set back to null
        resetEntry: {type: Boolean, default: true},
    },

    data() {
        return {
            operator: 'eq',
            entry: null,
            secondEntry: null,
            rules: {
                number: [
                    (v) => !v || !!`${v}`.match(/^-?[\d\,]+\.?[\d,]*$/) || 'Please enter a valid number'
                ]
            }
        }
    },

    computed: {
        rcInputWidth () { return this.vertical ? '100%' : 250 },

        enabled() {
            return this.type != 'option' || (this.options && this.options.length)
        },

        name() {
            return this.demographic.name
        },

        type() {
            return this.demographic.value_type
        },

        operatorText() {
            return this.operator
                ? this.operators.find(itm => itm.code == this.operator).text
                : ''
        },

        valid() {
            if (!this.operator) return false
            if (!this.entry) return false
            if (!this.passesRules(this.entry)) return false
            if (this.isBetween) {
                if (!this.secondEntry) return false
                if (!this.passesRules(this.secondEntry)) return false
            }
            return true
        },

        description() {
            if (this.valid && this.type == 'boolean') return this.entry === 1 ? 'Yes' : 'No'
            if (this.valid && this.type == 'option') return this.entry
            if (this.valid && this.operator == 'bt' && this.entry && this.secondEntry) return `Between ${this.entry} and ${this.secondEntry}`
            if (this.valid && this.operator && this.entry) return `${this.operatorText} ${this.entry}`
            return null
        },

        operators() {
            return this.$demographicsOperators(this.type)
        },

        options() {
            if (this.type === 'option' && this.demographic.options) {
                let options = this.$_.cloneDeep(this.demographic.options)
                let filteredOptions = []
                options.forEach(option => {
                    if (!option.display_name_group) {
                        option.display_name = option.display_name || option.value
                        filteredOptions.push(option)
                    } else {
                        let o = options.find(x => x.id == option.display_name_group[0]) // find and return first option within display_name_group; id can be string
                        if (!filteredOptions.find(x => x.display_name === o.display_name)) {
                            o = {
                                ...o,
                                display_name: o.display_name || o.value // use display_name instead of value
                            }
                            filteredOptions.push(o)
                        }
                    }
                })
                return filteredOptions
            } else if (this.type === 'boolean') {
                return [
                    {id: 't', value: 'Yes'},
                    {id: 'f', value: 'No'}
                ]
            } else {
                return null
            }
        },

        isBetween() {
            return this.operator == 'bt'
        },

        entryRules() {
            return this.rules[this.type] || []
        },

        result() {
            if (!this.valid) return null

            let v = this.entry
            if (this.type == 'boolean') v = (v === 't') ? '1' : '0'

            let obj = {
                demographicId: this.demographic.id,
                key: `demo_${this.demographic.id}`,
                entry: v
            }

            if (this.type == 'option') {
                obj.encoded = `${v}`
            } else {
                obj.operator = this.operator
                obj.encoded = `${obj.operator}:${v}`
            }

            if (this.isBetween) {
                obj.secondEntry = this.secondEntry
                obj.encoded += `:${obj.secondEntry}`
            }

            obj.query = `${obj.key}=${obj.encoded}`

            return obj
        },

        selectedOptions: {
            get() {
                if (this.byId) {
                    return this.entry
                }

                // return this.entry
                return `${this.entry}`.split(',').map(v => {
                    let exclude = `${v}`[0] == '!'
                    let id = parseInt(`${v}`.replace('!', ''))
                    let rec = this.options.find(itm => itm.id == id)
                    if (!rec) {
                        return false
                    } else {
                        return (exclude)
                            ? Object.assign({}, rec, {rcExclude: true})
                            : Object.assign({}, rec)
                    }
                }).filter(itm => !!itm)
            },
            set(v) {
                if (this.byId) {
                    this.entry = v
                } else {
                    let arr = []
                    v.forEach(option => {
                        if (!option.display_name_group) {
                            arr.push(!option.rcExclude ? option.id : "!" + option.id)
                        } else {
                            let groupArr = option.display_name_group
                            arr = arr.concat(groupArr)
                        }
                    })
                    this.entry = arr.toString()
                }
            }
        }
    },

    watch: {
        demographic() {
            if (this.resetEntry) {
                this.operator = 'eq'
                this.entry = null
                this.secondEntry = null
            }
        },

        value: {
            handler() {
                this.parse()
            },
            immediate: true
        },

        result(v) {
            this.$emit('result', v)
            this.$emit('input', v ? v.encoded : null)
        }
    },

    methods: {
        passesRules(val) {
            return !this.entryRules
                .map(rule => rule(val))
                .filter(result => result !== true)
                .length > 0
        },

        parse() {
            if (this.value) {
                if (this.type === 'option') {
                    this.operator = 'eq'
                    this.entry = this.value
                } else {
                    let matches = this.value.match(/^(.*?):(.*)$/)
                    if (matches) {
                        this.operator = matches[1]
                        if (this.type === 'boolean') {
                            if (matches[2] === 1 || matches[2] === '1' || matches[2] === true) this.entry = 't'
                            else if (matches[2] === 0 || matches[2] === '0' || matches[2] === false) this.entry = 'f'
                        } else if (this.operator === 'bt') {
                            let vals = matches[2].split(':')
                            this.entry = vals[0]
                            this.secondEntry = vals[1]
                        } else {
                            this.entry = matches[2]
                        }
                    }
                }
            } else {
                this.entry = null
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.vertical {
    .filter-label {
        width: 44%;
    }

    .filter-component-area {
        width: 100%;
    }
}
</style>
