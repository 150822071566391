<template>
    <div>
        <div>
            <v-toolbar color="white" flat dense>
                <v-toolbar-title :class="{optional: isOptional()}">{{ getTitle }}</v-toolbar-title>
            </v-toolbar>
        </div>
        <v-container fluid>
            <fe-date-picker
                v-model="studentRec.parent_contact_date"
                :label="getLabel"
                :rules="[v=>isRequired(v)]"
            ></fe-date-picker>
            <div class="theme--light v-label" :class="{required: !isOptional()}" >Comments</div>
            <v-textarea
                v-model="studentRec.parent_contact_comments"
                auto-grow
                :counter="1000"
                :maxlength="1000"
                flat solo dense
                :rules="[v=>isRequired(v)]"
            >
            </v-textarea>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'ParentPanel',
        props: {
            studentRec: { type: Object },
            panelVisibility: { type: Number }
        },
        computed: {
            getTitle() {
                let optional = this.isOptional() ? ' (optional)' : ''
                return 'Parent Contact ' + optional
            },
            getLabel() {
                let required = !this.isOptional() ? '*' : ''
                return 'Contact Date ' + required
            }
        },
        data() {
            return {
                dateMenu: false
            }
        },
        watch: {
            panelVisibility(v) {
                this.$children.forEach( c => c.validate && c.validate() )
            }
        },
        methods: {
            isRequired(v) {
                return this.panelVisibility == 2 && !v ? 'Field is required' : true
            },
            isOptional() {
                return this.panelVisibility == 1
            }
        }
    }
</script>

<style scoped="true">
</style>
