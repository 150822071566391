import Vue from "vue"
export default Vue.extend({
    template: `
        <span class="position: relative;">
            {{ params.value  }}
            <span v-if="show" :style="wrapperStyle">
                <i :style="iconStyle" :class="iconClass" />
                <span :style="countStyle">{{ count }}</span>
            </span>
        </span>
    `,
    computed: {
        count () { return this.params.data[this.params.countField] },
        show () { return this.count && this.count > 0 },
        iconClass () { return this.params.icon || 'fas fa-sitemap' },
        wrapperStyle () { return `
            position: relative;
            margin-left: 5px;
        ` },
        countStyle () { return `
            position: relative;
            font-size: 60%;
            top: -9px;
            left: -4px;
        ` },
        iconStyle () { return `
            position: relative;
            font-size: 100%;
            top: -1px;
        ` }
    }
});