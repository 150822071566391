<template>
    <div class="pa-10 d-flex flex-column flex-fill">

        <div>
            <div class="page-section">Upload Details</div>
            <v-row no-gutters>
                <v-col class="pa-1" cols="12" :md="field.cols ? field.cols : 2" v-for="(field, i) in generalFields" :key="`upload-field-`+i">
                    <fe-label>{{field.name}}</fe-label>
                    <v-text-field flat solo dense :value="field.value" readonly/>
                </v-col>
            </v-row>

            <div class="page-section">Processing Details</div>
            <v-row no-gutters>
                <v-col class="pa-1" cols="12" :md="field.cols ? field.cols : 2" v-for="(field, i) in runFields" :key="`upload-field-`+i">
                    <fe-label>{{field.name}}</fe-label>
                    <v-text-field flat solo dense :value="field.value" readonly/>
                </v-col>
            </v-row>

            <div class="page-section">Management</div>
            <v-row no-gutters>
                <v-col class="pa-1" cols="3">
                    <fe-label>Status</fe-label>
                    <fe-remote-combo
                        url="uploads.php?action=get&property=status"
                        itemText="name"
                        itemValue="id"
                        rootProperty="status"
                        byId
                        :readonly="!hasSecurity"
                        :clearable="false"
                        v-model="record.upload_status_id"
                        @change="update"
                    />
                </v-col>

                <v-col cols="9" class="pa-1">
                    <fe-label>Status Message</fe-label>
                    <v-text-field
                        v-model="record.status_message"
                        counter="255"
                        :rules="hasSecurity ? $fieldValidators('text', 'Status Message', { required: false, limit: 255 }) : []"
                        :readonly="!hasSecurity"
                        flat solo dense
                        @blur="update"
                    />
                </v-col>
            </v-row>
        </div>

        <!--
        You're probably thinking 2 separate fe-grids is stupid.  And you're right.
        Unfortunately, ag grid cannot properly maintain the ordering of columns if
        2 separate sets of column defs reuses the same colId value.  OTOH, if you try
        to use separate colId values (e.g. a ug_ prefix for ungrouped), then the
        search filter bar (e.g. search All Columns) doesn't work for the prefixed
        defs.  Additionally, the use of a specific key="..." prop is required here
        to ensure DOM is up to date each time the user toggles.

        At time of writing, CL appears to be using ag grid v20.  *Supposedly*
        newer versions of ag grid (>= 26?) may be better at properly ordering
        columns in cases such as this.
        -->
        <fe-grid
            v-if="isGroupingExceptions"
            ref="grid"
            key="groupedGrid"
            :columnDefs="columnDefs"
            :rowData="groupedExceptions"
            style="height: 400px; margin-bottom: 40px;"
            class="flex-grow-1"
            displayDensity="small"
            :showAddRowBtn="false"
        >
            <template v-slot:after-search-toolbar-items>
                <fe-switch
                    v-model="isGroupingExceptions"
                    label="Group similar exceptions"
                    :style="{ marginLeft: '8px', marginTop: '6px' }"
                />
            </template>
        </fe-grid>

        <fe-grid
            v-else
            ref="grid"
            key="ungroupedGrid"
            :columnDefs="columnDefs"
            :rowData="ungroupedExceptions"
            style="height: 400px; margin-bottom: 40px;"
            class="flex-grow-1"
            displayDensity="small"
            :showAddRowBtn="false"
        >
            <template v-slot:after-search-toolbar-items>
                <fe-switch
                    v-model="isGroupingExceptions"
                    label="Group similar exceptions"
                    :style="{ marginLeft: '8px', marginTop: '6px' }"
                />
            </template>
        </fe-grid>

        <fe-crud
            ref="crud"
            autoload
            :config="$models.uploadException"
            @read="groupedExceptions = $event"
            :readParams="{ id: this.$props.id, grouped: true }"
        />

        <fe-crud
            autoload
            :config="$models.uploadException"
            @read="ungroupedExceptions = $event"
            :readParams="{ id: this.$props.id, grouped: false }"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'UploadDetail',
        props: ['id'],
        data() {
            return {
                runFields: [],
                generalFields: [],
                isGroupingExceptions: true,
                groupedExceptions: [],
                ungroupedExceptions: [],
                record: {},
                raw: {}
            }
        },
        mounted() {
            this.loadData()
        },
        watch: {
            isGroupingExceptions() {
                // Without nextTick, the ag grid gets mad and grows too large (whatever...)
                this.$nextTick(() => {
                    this.$refs.grid?.gridOptions?.api?.sizeColumnsToFit()
                })
            },
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            hasSecurity() {
                return this.sessionUser.security_codes.indexOf('MANAGE_FTP_UPLOAD') != -1
            },
            columnDefs() {
                if (this.isGroupingExceptions) {
                    return [{
                        colId: 'exception_type_name',
                        headerName: 'Type',
                        field: 'exception_type_name',
                        maxWidth: 150
                    }, {
                        colId: 'description',
                        headerName: 'Description',
                        field: 'description',
                        maxWidth: 300,
                    }, {
                        colId: 'debug_text',
                        headerName: 'Text',
                        field: 'debug_text',
                        flex: 2
                    }, {
                        colId: 'instances',
                        headerName: 'Occurrences',
                        field: 'instances',
                        sortable: true,
                        maxWidth: 110,
                        cellStyle: { textAlign: 'right' }
                    }]
                } else {
                    return [{
                        colId: 'row_number',
                        headerName: 'Row',
                        field: 'row_number',
                        maxWidth: 110
                    }, {
                        colId: 'exception_type_name',
                        headerName: 'Type',
                        field: 'exception_type_name',
                        maxWidth: 150
                    }, {
                        colId: 'description',
                        headerName: 'Description',
                        field: 'description',
                        maxWidth: 300,
                    }, {
                        colId: 'debug_text',
                        headerName: 'Text',
                        field: 'debug_text',
                        flex: 2
                    }]
                }
            },
        },
        methods: {
            update() {
                if (this.raw.upload_status_id != this.record.upload_status_id || this.raw.status_message != this.record.status_message) {
                    this.$axios.post('uploads.php?action=update', {
                        uploads: [this.record]
                    }).then(resp => {
                        this.$emit('uploadChanged')
                        this.$ecResponse(resp)
                    })
                }
            },
            loadData() {
                this.$modelGet('upload', { id: this.$props.id }).then(upload => {
                    this.record = upload[0]
                    this.raw    = {...this.record}
                    this.generalFields = [{
                        name: 'ID',
                        value: this.record.id
                    }, {
                        name: 'Upload Name',
                        value: this.record.upload_description
                    }, {
                        name: 'Upload Type',
                        value: this.record.upload_type_name
                    }, {
                        name: 'Status',
                        value: this.record.status_name
                    }, {
                        name: 'Uploaded By',
                        value: this.record.full_name
                    }]

                    this.runFields = [{
                        name: 'Started',
                        value: this.record.start_time
                    }, {
                        name: 'Ended',
                        value: this.record.end_time
                    }, {
                        name: 'Duration',
                        value: this.record.duration
                    }, {
                        name: 'Rows Processed',
                        value: this.record.rows_processed
                    }, {
                        name: 'Warnings',
                        value: this.record.warn_count
                    }, {
                        name: 'Error Count',
                        value: this.record.error_count
                    }]
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.section-group {
    border: 1px solid #404040;
    border-radius: 4px;
}
</style>