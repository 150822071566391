<template>
    <div>
        <fe-card
            class="alt-card ma-3"
            headerText="Exit Status"
        >
            <template #title-toolbar>
                <v-icon class="mb-2" @click="doExpand">far fa-expand-alt</v-icon>
            </template>

            <template v-slot:body>
                <fe-dialog
                    title="Active Plan Details"
                    v-if="dialog.show"
                    @accept="dialog.show=false"
                    @close="dialog.show=false"
                    persistent
                    dismissButtonText="Cancel"
                    acceptButtonText="Done"
                    :width="gridWidth"
                >
                    <fe-grid
                        style="height: 400px;"
                        :rowData="dialog.data"
                        :columnDefs="columns"
                        displayDensity="small"
                        groupUseEntireRow
                    />
                </fe-dialog>

                <v-skeleton-loader
                    class="mx-auto"
                    type="text@5"
                    :loading="isLoading"
                    style="min-height: 120px;"
                >
                    <div v-if="showCharts">
                        <highcharts ref="chart" :options="chartConfig"/>
                    </div>

                    <div v-else class="text-center">
                        No students in this search have an exit status
                    </div>
                </v-skeleton-loader>
            </template>
        </fe-card>
    </div>
</template>

<script>
    import windowOptionsMixin from '@/mixins/windowOptions'
    export default {
        name: 'ImplementationFidelity',
        mixins: [ windowOptionsMixin ],
        props: {
            params: {

            }
        },
        watch: {
            params: {
                handler(v) {
                    if (v && Object.values(v).length) this.loadData()
                },
                immediate: true
            }
        },
        computed: {
            gridWidth() {
                return window.innerWidth-50
            }
        },
        data() {
            return {
                items: [],
                isLoading: false,
                showCharts: false,
                participationConfig: {},
                procedureConfig: {},
                columns: [{
                   headerName: "Student",
                   field: "student_full_name",
                   editable: false,
                   width: 150
                }, {
                   headerName: "Strategy",
                   field: "intervention_type_name",
                   editable: false,
                   width: 150
                }, {
                   headerName: "Interventionist",
                   field: "user_full_name",
                   editable: false,
                   width: 150
                }, {
                   headerName: "School",
                   field: "school_name",
                   editable: false,
                   flex: 1
                }, {
                   headerName: "Grade",
                   field: "grade_desc",
                   editable: false,
                   width: 80
                }, {
                   headerName: "Start Date",
                   field: "intervention_plan_start_date",
                   editable: false,
                   width: 100
                }, {
                   headerName: "End Date",
                   field: "intervention_plan_end_date",
                   editable: false,
                   width: 100
                }],
                dialog: {
                    show:false,
                    data: []
                },
                chartConfig: {}
            }
        },
        methods: {
            doExpand() {
                let me = this
                let c = this.$_.cloneDeep({...this.chartConfig})
                c.chart.height = window.innerHeight-150
                delete c.chart.marginTop
                delete c.chart.marginBottom
                c.plotOptions.series.point.events = {
                    click(e) {
                        let rec = me.items.find(r=>r.name ===e.point.category)
                        me.$emit('drilldown', rec)
                        me.$store.commit('global/removeDockedWindow', w)
                    }
                }
                let w = this.$dockableWindow({
                    name: 'Exit Status',
                    component: 'highcharts',
                    attrs: {
                        options: c
                    }
                })
            },
            setDetail(key) {

            },
            loadData() {
                this.isLoading=true
                this.showCharts = false
                this.$axios.get('interventionView.php?action=get&property=exit_status_breakdown&'+this.$objectToParams(this.params))
                    .then(r => {
                        this.isLoading = false
                        this.items = this.$ecResponse(r, 'exit_status_breakdown')
                        this.processData()
                    })
            },
            baseConfig() {
                let me = this
                return {
                    credits: { enabled: false },
                    chart: {
                        type: 'bar',
                        height: 130,
                        marginTop: 0,
                        marginBottom: 0
                        // padding: [0,0,0,0]
                    },
                    title: {
                        text: null
                    },
                    yAxis: {
                        endOnTick: false,
                        title: {
                            text: 'Count'
                        }
                    },
                    plotOptions: {
                        series: {
                            color: '#08A5DA',
                            point: {
                                events: {
                                    click(e) {
                                        let rec = me.items.find(r=>r.name ===e.point.category)
                                        me.$emit('drilldown', rec)
                                    }
                                }
                            },
                            stacking: 'normal',
                            dataLabels: {
                                enabled: false,
                                style: {
                                    textOutline: 0,
                                    color: '#000000'
                                }
                            },
                            label: {
                                style: {
                                    color: 'black'
                                }
                            },
                            size: '100%'
                        }
                    },
                    xAxis: {
                        categories: [],
                        labels: {
                            formatter() {
                                return (this.value+'').substring(0, 8)+'...'
                            },
                            style: {
                                fontSize: '10px',
                                color: 'gray'
                            }
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    exporting: {
                        enabled: false
                    },
                    series: [{
                        name: 'Status',
                        data: []
                    }]
                }
            },
            processData() {
                this.chartConfig = this.baseConfig()

                this.items.forEach(item => {
                    this.chartConfig.xAxis.categories.push(item.name)
                    this.chartConfig.series[0].data.push(item.count)

                    if (this.chartConfig.series[0].data.length) {
                        this.showCharts=true
                    } else {
                        this.showCharts=false
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.clickable {
    cursor: pointer;
}
</style>
