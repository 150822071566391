<template>
    <div class="flex-fill flex-column">
        <fe-grid
            ref="grid"
            :columnDefs="columns"
            :rowData="rowData"
            style="height: 100%;"
            displayDensity="small"
            :showAddRowBtn="false"
            :csvExportProcessCallback="exportData"
        >
            <template v-slot:after-search-toolbar-items>
                <fe-date-picker
                    v-model="filterDate"
                    class="ml-4"
                    placeholder="Filter by Date"
                    :allowed-dates="allowedDatesFunction"
                />
            </template>
        </fe-grid>

        <div class="grid-pager d-flex pr-10">
            <div style="margin-top: 13px;" class="ml-2">{{this.totalRecords}} Rows</div>
            <div style="margin-top: 13px;" class="ml-auto ml-2">Page</div>
            <fe-icon-btn useIcon="fas fa-chevron-double-left" :disabled="currentPage===1" @click="currentPage=1"/>
            <fe-icon-btn useIcon="fas fa-chevron-left" :disabled="currentPage===1" @click="currentPage--"/>
            <div style="width: 80px;">
                <v-select
                    v-model="currentPage"
                    flat solo dense
                    class="mt-1 ml-2"
                    :items="pages"
                />
            </div>
            <fe-icon-btn useIcon="fas fa-chevron-right" :disabled="currentPage===pages[pages.length-1]" @click="currentPage++"/>
            <fe-icon-btn useIcon="fas fa-chevron-double-right" :disabled="currentPage===pages[pages.length-1]" @click="currentPage=pages[pages.length-1]"/>
            <div style="margin-top: 13px;" class="ml-2">of {{this.pages[this.pages.length-1]}}</div>
        </div>

        <fe-dialog
            v-if="isBuildingCsv"
            title="Exporting CSV"
            persistent
            :acceptButtonDisabled="csvBuildPercent < 100"
            dismissButtonText="Cancel"
            @accept="isBuildingCsv = false"
            @dismiss="isBuildingCsv = false"
            @close="isBuildingCsv = false"
        >
            <div class="d-flex flex-column align-center">
                <div>Building your download.  This may take a couple of minutes...</div>
                <br/>
                <v-progress-circular color="primary" :value="csvBuildPercent"/>
                <div>{{ csvBuildPercent }}% Complete</div>
            </div>
        </fe-dialog>
    </div>
</template>

<script>
    export default {
        name: 'AttendanceList',
        props: {
            params: {
                required: true
            }
        },
        watch: {
            currentPage(v) {
                this.loadData()
            },
            filterDate() {
                this.loadData()
            },
        },
        mounted() {
            // this.currentPage=1
            this.loadData()
            // this.$refs.crud.read(p)
        },
        data() {
            return {
                rowData: [],
                currentPage: 1,
                totalRecords: 0,
                pages: [],
                columns: [{
                    field: 'occurrence_date',
                    minWidth: 100,
                    width: 100,
                    headerName: 'Occurrence'
                }, {
                    field: 'student_full_name',
                    flex: 1,
                    headerName: 'Student',
                    summaryType: 'count'
                }, {
                    field: 'attendance_term_name',
                    headerName: 'Term',
                    minWidth: 50,
                    width: 100,
                    summaryType: 'count'
                }, {
                    field: 'attendance_course',
                    headerName: 'Course',
                    minWidth: 100,
                    width: 150,
                }, {
                    field: 'attendance_period',
                    headerName: 'Period',
                    minWidth: 50,
                    width: 50
                }, {
                    field: 'attendance_period_school_name',
                    headerName: 'School',
                    minWidth: 100,
                    width: 150
                }, {
                    field: 'attendance_status_name',
                    headerName: 'Status',
                    minWidth: 100,
                    width: 100
                }, {
                    field: 'attendance_reason_name',
                    headerName: 'Excuse',
                    minWidth: 100,
                    width: 100
                }, {
                    field: 'attendance_excused_name',
                    headerName: 'Excused',
                    minWidth: 100,
                    width: 100
                }].map(colDef => ({
                    ...colDef,
                    // Explicitly provide a colId to workaround ag grid notorious _1 bug on data refresh
                    colId: colDef.field,
                })),
                schoolYear: null,
                filterDate: null,
                isBuildingCsv: false,
                csvBuildPercent: 0,
            }
        },
        computed: {
            allowedDatesFunction() {
                if (this.params?.occurrence_weekday) {
                    return v => this.filterDateItems.includes(v)
                } else {
                    // Allows all dates without any filter function defined
                    return null
                }
            },
            filterDateItems() {
                if (!this.schoolYear) {
                    return []
                } else if (!this.params.occurrence_weekday) {
                    return []
                }

                let dt = this.$dayjs(this.schoolYear.year_start)
                let endDt = this.$dayjs(this.schoolYear.year_end)

                // occurrence_weekday should be a human-readable day of week e.g. "Monday"
                // if it's not, we'll abort the loop to avoid infinite loop
                let loopsAllowed = 7
                while (dt.format('dddd') != this.params.occurrence_weekday) {
                    dt = dt.add(1, 'd')

                    loopsAllowed -= 1
                    if (loopsAllowed <= 0) {
                        return []
                    }
                }

                let results = []
                do {
                    results.push(dt.format('YYYY-MM-DD'))
                    dt = dt.add(1, 'w')
                } while (dt <= endDt)

                return results
            },
        },
        methods: {
            async loadData() {
                this.schoolYear = await this.$axios.get(`crud.php?action=get&property=SchoolYear&id=${this.params.cohort_school_year_id}`).then(res => res.data?.school_year?.[0])

                let p = {...this.$props.params}
                p.page=this.currentPage
                p.start=this.currentPage*25
                p.limit=25

                if (this.filterDate) {
                    p.start_date = this.filterDate
                    p.end_date = this.filterDate
                }

                this.$axios.get('attendance.php?action=get&property=attendance_detail&'+this.$objectToParams(p))
                    .then(response => {
                        this.rowData = response.data.attendance_details
                        this.totalRecords = response.data.totalCount
                        let count = this.totalRecords / 25

                        this.pages = []
                        for (let i=0; i<count;i++) {
                            this.pages.push(i+1)
                        }
                    })
            },
            async exportData() {
                let columnStates = this.$refs.grid.gridColumnApi.getColumnState()
                let csv = columnStates.filter(itm => !itm.hide).map(itm => `"${this.columns.find(col => col.colId == itm.colId).headerName}"`).join(',')

                let p = {
                    ...this.params,
                    // To reduce network/client load, only request the fields we need to build the csv
                    fields: 'attendance_details(' + columnStates.map(itm => itm.colId).join(',') + ')',
                }
                if (this.filterDate) {
                    p.start_date = this.filterDate
                    p.end_date = this.filterDate
                }

                // Show a "busy" dialog while we fetch all data from backend
                // Backend can't provide entire data because it'll run out of memory
                // Client browsers are better at this because they have to be able
                // to fit all of the advertisement tracking data and oversized
                // dependencies on the internet
                this.isBuildingCsv = true
                this.csvBuildPercent = 0

                const BATCH_SIZE = 5
                const PER_PAGE = 25

                // Download content one page at a time, but in small batches;
                // small enough to not hammer the backend, but more than one so it's not turtle slow
                let pagesNeeded = [ ...this.pages ]
                while (pagesNeeded.length > 0) {
                    let batch = pagesNeeded.splice(0, 5)

                    let promises = batch.map(page => {
                        return this.$axios.get('attendance.php?action=get&property=attendance_detail&' + this.$objectToParams({
                            ...p,
                            page: page,
                            start: page * PER_PAGE,
                            limit: PER_PAGE,
                        })).then(res => res.data.attendance_details)
                    })

                    let responses = await Promise.all(promises)
                    for (let res of responses) {
                        for (let rec of res) {
                            csv += '\n';
                            // Loop through ag grid columnStates to preserve the order of columns,
                            // as user may have rearrange them; also ignore any hidden columns
                            // (i.e. display all data as-is).
                            csv += columnStates.filter(itm => !itm.hide).map(itm => `"${rec[itm.colId]}"`).join(',')
                        }
                    }

                    let pagesComplete = (this.pages.length - pagesNeeded.length)
                    this.csvBuildPercent = Math.floor(pagesComplete / this.pages.length * 100)

                    // If user cancels partway through, just abandon everything
                    if (!this.isBuildingCsv) {
                        return
                    }
                }

                let output = "data:text/csv;charset=utf8," + csv
                let encodedUri = encodeURI(output)
                let link = document.createElement("a")
                link.setAttribute("href", encodedUri)
                link.setAttribute("download", "attendance.csv")
                document.body.appendChild(link)

                link.click()
            },
        }
    }
</script>

<style lang="scss" scoped>
.grid-pager {
    margin-top: 3px;
    height: 50px;
}
</style>
