let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    studentIncidentChart: {
        defaults: {
            endpoint: 'behaviorIncident.php?property=big5',
            rootProperty: 'incidents'
        },
        ...standardCrud
    },
    studentIncidentList: {
        defaults: {
            endpoint: 'behaviorIncident.php?action=get&property=list',
            rootProperty: 'incidents'
        },
        ...standardCrud
    },
    studentIncident: {
        defaults: {
            endpoint: 'behaviorIncident.php',
            rootProperty: 'incidents'
        },
        ...standardCrud
    }
}

