<template>
    <div>
        <button @click="dialog=true">{{ displayName }}</button>

        <v-dialog v-model="dialog" width="1024">
            <fe-stepper-filter
                :title="title" style="height:620px;"
                @apply="doSearch" @close="dialog=false"
                :applyDisabled="!formValid || !enableFinalPanel"
            >
                <fe-stepper-select-panel :items="dataPointTypes" v-model="data_point_type"/>

                <v-divider vertical style="background-color:#E0E1E6"/>

                <fe-stepper-select-panel :items="subCategories" v-model="sub_category"/>

                <v-divider vertical style="background-color:#E0E1E6"/>

                <fe-stepper-select-panel :items="dataPointNames" itemText="alias" v-model="data_point_name"/>

                <v-divider vertical style="background-color:#E0E1E6"/>

                <fe-stepper-panel style="padding:10px;">
                    <v-form v-if="enableFinalPanel" lazy-validation v-model="formValid">
                        <fe-remote-combo
                            v-model="score_detail_type"
                            label="Score Detail Type"
                            url="scoreDetailTypes.php?action=get"
                            :params="{ data_point_type_id: data_point_type.id }"
                            itemText="description"
                        />

                        <template v-if="alphaMaps.length">
                            <fe-remote-combo
                                v-model="minimum_score"
                                :items="alphaMaps"
                                label="Minimum Score"
                                item-text="alpha_score"
                                item-value="numeric_score"
                                :error-messages="minimumScoreError"
                            />

                            <fe-remote-combo
                                v-model="maximum_score"
                                :items="alphaMaps"
                                label="Maximum Score"
                                item-text="alpha_score"
                                item-value="numeric_score"
                                :error-messages="maximumScoreError"
                            />
                        </template>

                        <template v-else>
                            <div class="theme--light v-label">Minimum Score</div>

                            <v-text-field
                                v-model="minimumScoreValue"
                                flat solo dense
                                v-mask="'######'"
                                :error-messages="minimumScoreError"
                            />

                            <div class="theme--light v-label">Maximum Score</div>

                            <v-text-field
                                v-model="maximumScoreValue"
                                flat solo dense
                                v-mask="'######'"
                                :error-messages="maximumScoreError"
                            />
                        </template>

                        <fe-remote-combo
                            v-model="target_set"
                            :items="filteredPerformanceBands"
                            label="Target Set"
                        />

                        <target-descriptor-combo
                            v-model="target_descriptor"
                            :target-set-id="target_set && target_set.id"
                            types="1,3"
                            multiple
                            :disabled="!target_set"
                            :error-messages="targetDescriptorError"
                        />

                        <fe-remote-combo
                            label="School Year"
                            v-model="school_year"
                            rootProperty="years"
                            url="qry.php?action=get&property=school_years"
                        />
                    </v-form>
                </fe-stepper-panel>
                <template #footer>
                    <div class="text-truncate" style="padding:14px">{{ breadcrumb }}</div>
                </template>
            </fe-stepper-filter>
        </v-dialog>

        <embargoed-data-agreement
            v-if="showEmbargoAcceptDialog"
            :embargoes="embargoes"
            @accept="onAcceptEmbargo"
        />
    </div>
</template>

<script>
import * as Util from '../../Util'
import TargetDescriptorCombo from '@/components/common/combo/TargetDescriptorCombo'
import EmbargoedDataAgreement from '@/components/common/EmbargoedDataAgreement'

export default {
    name: 'AssessmentPanel',

    rank: 1,

    components: {
        TargetDescriptorCombo,
        EmbargoedDataAgreement,
    },

    props: ['params'],

    data() {
        return {
            formValid: true,
            oldFilter: null,
            displayName: 'Assessment',
            type: 'assessment',
            dialog: false,
            alphaMaps: [],
            dataPointTypes: [],
            subCategories: [],
            dataPointNames: [],
            data_point_type: {},
            sub_category: {},
            data_point_name: {},
            score_detail_type: null,
            minimum_score: null,
            maximum_score: null,
            target_set: null,
            target_descriptor: null,
            school_year: null,
            performanceBands: [],
            filteredPerformanceBands: [],
            showEmbargoAcceptDialog: false,
            embargoes: [],
        }
    },

    computed: {
        title() {
            return this.oldFilter ? `Edit ${this.displayName} Filter` : `Create ${this.displayName} Filter`
        },
        enableFinalPanel() {
            return !this.$_.isEmpty(this.data_point_name)
        },
        breadcrumb() {
            return [this.data_point_type.name, this.sub_category.name, this.data_point_name.name]
                .filter(Boolean)
                .join(' > ')
        },
        minimumScoreValue: {
            get: function () {
                return this.minimum_score?.value
            },
            set: function (v) {
                this.minimum_score = {value: v}
            }
        },
        maximumScoreValue: {
            get: function () {
                return this.maximum_score?.value
            },
            set: function (v) {
                this.maximum_score = {value: v}
            }
        },
        minimumScoreError() {
            return this.minimum_score?.value && this.maximum_score?.value
                ? parseInt(this.minimum_score.value) > parseInt(this.maximum_score.value)
                    ? 'Must Be Lower Than Max Score'
                    : null
                : null
        },
        maximumScoreError() {
            return this.minimum_score?.value && this.maximum_score?.value
                ? parseInt(this.maximum_score.value) < parseInt(this.minimum_score.value)
                    ? 'Must Be Higher Than Min Score'
                    : null
                : null
        },
        targetDescriptorError() {
            return this.target_set && !this.target_descriptor /*&& !this.target_descriptor.id.value.length*/
                ? 'If you select a Target Set, you must select a Target Descriptor'
                : null
        }
    },

    watch: {
        dialog(v) {
            this.changes = -1
            if (!v) {
                this.oldFilter = null;
                this.loaded = false;
            }
            v ? this.loadDataPointTypes() : this.clear([0, 1, 2, 3])
        },
        data_point_type(v) {
            if (!this.oldFilter || this.loaded) this.clear([1, 2, 3])
            this.loadSubCategories(v)
            this.filterPerformanceBands(v)
        },
        sub_category(v) {
            if (!this.oldFilter || this.loaded) this.clear([2, 3])
            this.loadDataPointNames(v)
        },
        target_set(v) {
            if (!v) this.target_descriptor = null
        },
    },

    mounted() {
        this.$modelGet('userTargetSet').then(r => this.performanceBands = r)
    },

    methods: {
        clear(panels) {
            if (panels.includes(0)) {
                this.data_point_type = {}
            }
            if (panels.includes(1)) {
                this.subCategories = []
                this.sub_category = {}
            }
            if (panels.includes(2)) {
                this.dataPointNames = []
                this.data_point_name = {}
            }
            if (panels.includes(3)) {
                this.score_detail_type = null
                this.minimum_score = null
                this.maximum_score = null
                this.target_set = null
                this.target_descriptor = null
                this.school_year = null
            }
        },

        load(oldFilter) {
            this.oldFilter = oldFilter
            this.dialog = true
            let ep = oldFilter.editorParams

            this.data_point_type = ep.data_point_type
            this.sub_category = ep.sub_category
            this.data_point_name = ep.data_point_name
            this.minimum_score = ep.minimum_score
            this.maximum_score = ep.maximum_score
            this.score_detail_type = ep.score_detail_type
            this.target_set = ep.target_set
            this.target_descriptor = ep.target_descriptor
            this.school_year = ep.school_year

            this.$nextTick(() => this.loaded = true)
        },

        doSearch() {
            if (this.$hasSecurity('VIEW_EMBARGOED_DATA')) {
                let params = {
                    data_point_type_id: this.data_point_type?.id,
                    sub_category_id: this.sub_category?.id,
                    data_point_name_id: this.data_point_name?.id,
                    school_year_id: this.school_year?.id || this.params?.search?.school_year_id,
                }

                this.$axios.get(`targetScores.php?action=get&property=data_point_embargoes&` + this.$objectToParams(params)).then(res => {
                    if (res.data.data_point_embargo?.length) {
                        this.showEmbargoAcceptDialog = true
                        this.embargoes = res.data.data_point_embargo
                    } else {
                        this.emit()
                    }
                })
            } else {
                this.emit()
            }
        },

        emit() {
            this.dialog = false
            let uniqueId = Util.UUIDv4()

            // filter submission params
            let filter = {
                type: this.type,
                id: uniqueId,
                restrict: true,
                report: true,
                params: this.$_.pickBy({
                    data_point_type_id: this.data_point_type?.id,
                    sub_category_id: this.sub_category?.id,
                    data_point_name_id: this.data_point_name?.id,
                    minimum_score: this.minimum_score && (this.minimum_score.value || this.minimum_score.numeric_score),
                    maximum_score: this.maximum_score && (this.maximum_score.value || this.maximum_score.numeric_score),
                    score_detail_type_id: this.score_detail_type?.id,
                    target_set_id: this.target_set?.id,
                    target_descriptor_id: this.target_descriptor?.length
                        ? this.target_descriptor.map(x => x.id)
                        : undefined,
                    school_year_id: this.school_year?.id,
                }, x => !this.$_.isBlank(x)),
            }

            // add this editor to filter for later invocation
            Object.defineProperties(filter, {
                editor: {value: this},
                editorParams: {
                    value: {
                        data_point_type: {...this.data_point_type},
                        sub_category: {...this.sub_category},
                        data_point_name: {...this.data_point_name},
                        minimum_score: this.minimum_score ? {...this.minimum_score} : null,
                        maximum_score: this.maximum_score ? {...this.maximum_score} : null,
                        score_detail_type: this.score_detail_type ? {...this.score_detail_type} : null,
                        target_set: this.target_set ? {...this.target_set} : null,
                        target_descriptor: this.target_descriptor ? [...this.target_descriptor] : null,
                        school_year: this.school_year ? {...this.school_year} : null,
                    }
                },
                grouper: {
                    value: {
                        id: uniqueId,
                        name: this.sub_category.name,
                        type: 'controller',
                        args: {property: `filters.${uniqueId}.value`}
                    }
                },
                chipText: {value: this.sub_category.name},
                tooltipData: {
                    value: this.$_.pickBy({
                        'Assessment Group': this.data_point_type.name,
                        'Assessment': this.sub_category.display_name,
                        'Window': this.data_point_name.name,
                        'Score Detail Type': this.score_detail_type?.name || this.score_detail_type?.code,
                        'Minimum Score': this.minimum_score && (this.minimum_score.value || this.minimum_score.alpha_score),
                        'Maximum Score': this.maximum_score && (this.maximum_score.value || this.maximum_score.alpha_score),
                        'Target Set': this.target_set?.name,
                        'Target Descriptor': this.target_descriptor?.map(x => x.name).join(', '),
                        'School Year': this.school_year?.name,
                    }, x => !this.$_.isBlank(x))
                }
            })

            this.$emit('input', filter, this.oldFilter)
        },

        loadDataPointTypes() {
            this.$axios({
                url: 'dataPointType.php?action=get&hidden=0&property=assessment',
            }).then((response) => {
                if (response && response.data) {
                    this.dataPointTypes = response.data
                }
            })
        },

        loadSubCategories(v) {
            if (this.$_.isEmpty(v)) {
                this.subCategories = []
            } else {
                this.$axios({
                    url: 'subcategories.php?action=get&hidden=0',
                    params: {data_point_type_id: v.id}
                }).then((response) => {
                    if (response && response.data) {
                        this.subCategories = response.data.subcategories
                    }
                })
            }
        },

        loadDataPointNames(v) {
            if (this.$_.isEmpty(v)) {
                this.dataPointNames = []
            } else {
                this.$axios({
                    url: 'dataPointName.php?action=get&hidden=0',
                    params: {
                        data_point_type_id: this.data_point_type.id,
                        sub_category_id: v.id
                    }
                }).then((response) => {
                    if (response && response.data) {
                        this.dataPointNames = response.data.names.map(x => {
                            if (!x.alias) {
                                x.alias = x.name
                            }
                            return x
                        })
                    }
                })
                this.$axios.get('alphaMap.php?action=get', {
                    params: {
                        data_point_type_id: this.data_point_type.id,
                        sub_category_id: v.id
                    }
                }).then(response => {
                    if (response && response.data) {
                        this.alphaMaps = response.data.alpha_maps
                    }
                })
            }
        },

        filterPerformanceBands(v) {
            let me = this
            let r = this.performanceBands

            if (r.length) {
                let d = r.filter(t => t.id == v.id)
                if (d) {
                    let paramYears = [].concat(me.params?.search?.school_year_id || [])
                    let source = []
                    d[0]?.schoolYears.forEach(year => {
                        if (!year.id || paramYears.indexOf(year.id) > -1) source = source.concat(year.targetSets)
                    })
                    me.filteredPerformanceBands = source
                }
            }
        },

        onAcceptEmbargo() {
            this.showEmbargoAcceptDialog = false
            this.emit()
        },
    }
}
</script>

<style lang="scss" scoped></style>
