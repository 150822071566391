<template>
    <!--<fe-chart-->
        <!--class="ma-2"-->
        <!--:tooltip="tooltip"-->
        <!--:name="localConfig.title"-->
        <!--:series="localConfig.series"-->
        <!--:height="localHeight"-->
        <!--:type="localConfig.chart ? localConfig.chart.type : 'column'"-->
        <!--legend="bottom"-->
        <!--:xAxisCategories="localConfig.xAxis ? localConfig.xAxis.categories : []"-->
        <!--:extraConfigs="extraConfigs"-->
        <!--:stacked="stacked"-->
        <!--:trendline="trendline"-->
        <!--:label="localConfig.subtitle.text"-->
        <!--ref="stackedChart"-->
    <!--&gt;</fe-chart>-->
    <div>
        <chart :config="localConfig" class="mr-2" ref="stackedChart" :collectionChart="collectionChart">
            <template #before-menu v-if="showTools && collectionChart">
                <fe-icon-btn useIcon="fas fa-expand-alt" @click="doExpand"/>
            </template>
        </chart>
    </div>
</template>

<script>
    import Chart from "./Chart";
    export default {
        name: 'StackedChart',
        components: {Chart},
        props: {
            config: {
                required: true,
                type: Object
            },
            type: {
                required: true,
                type: String,
                default: 'basic'
            },
            height: {
                default: 350
            },
            border: {
                type: Boolean,
                default: false
            },
            stacked: {
                type: Boolean,
                default: true
            },
            collectionChart: {
                type: Boolean,
                default: false
            },
            showTools: {
                type: Boolean,
                default: true
            }
        },
        watch: {
            deep: true,
            config(v) {
                this.parseConfig()
            }
        },
        data() {
            return {
                seriesConfig: {},
                localConfig: {
                    series: {}
                },
                chartRecord: [],
                overallRecord: {},
                baseParams: {},
                fields: [],
                colors: [],
                chartTitle: '',
                showExpandDialog: false,
                thumbnail: {
                    icon: 'bar_chart',
                    color: '#5fa2dd'
                },
                localHeight: null,
                title: '',
                selectedTargetSet: null,
                trendline: {}
            }
        },
        created() {
            if (this.$props.height === 'fullscreen') {
                this.$data.localHeight = (window.innerHeight - 200) + 'px'
            } else {
                this.$data.localHeight = this.$props.height
            }
            this.parseConfig()
        },
        methods: {
            doExpand() {
                let c = {
                    config: this.$props.config,
                    type: this.$props.type,
                    height: 'fullscreen',
                    border: false
                }
                this.$store.commit('global/addDockableWindow', {
                    name: this.title,
                    component: 'benchmark-chart',
                    attrs: c
                })
            },
            parseConfig() {
                this.overallRecord = this.$props.config
                // This is used to query to get the list of students
                this.baseParams = this.overallRecord.student_params

                let trendline_data = []

                if (this.type === 'detailed') {
                    this.chartRecord = [...this.overallRecord.DETAILED]
                    this.fields = this.overallRecord.detailed_fields
                    this.colors = this.overallRecord.detailed_colors
                } else {
                    this.chartRecord = [...this.overallRecord.BASIC]
                    this.fields = this.overallRecord.basic_fields
                    this.colors = this.overallRecord.basic_colors
                }
                this.trendline.fields = this.overallRecord.trendline_fields
                this.trendline.color = this.overallRecord.trendline_colors

                if(this.trendline.fields) {
                    this.trendline.fields.forEach(field => {
                        if(!field.hidden) {
                            trendline_data.push({
                                name: field.name,
                                color: field.color,
                                data: [],
                                min: this.overallRecord.trendline_min,
                                max: this.overallRecord.trendline_max,
                                zIndex: 1
                            })
                        }
                    })
                    trendline_data.forEach(td => {
                        this.chartRecord.forEach(record => {
                            td.data.push(
                               record[td.name]
                            )
                        })
                    })
                    this.trendline.data = trendline_data
                }

                this.seriesConfig = this.buildSeries(this.chartRecord, this.fields, this.colors)

                let chartType = 'column'

                this.localConfig = this.buildChart(this.config, this.seriesConfig, chartType)
            },

            reset() {
                this.localConfig.series = []
                this.localConfig.xAxis  = {}
            },

            buildSeries(data, fields, colors) {
                let me = this
                let categories = []
                let series     = []

                for (let index in data) {
                    let r = data[index]
                    // now, iterate through the record and build the series data
                    fields.forEach(function(field, fieldIndex) {
                        let serie = me.$_.find(series, function(o) {
                            return o.name == field
                        })

                        if (!serie) {
                            serie = {
                                name: field,
                                color: colors[fieldIndex],
                                data: []
                            }
                            series.push(serie)
                        }

                        serie.data.push(r[field] ? r[field] : 0)
                    })
                    categories.push(r.name)
                }

                return {
                    categories: categories,
                    series: series.reverse()
                }
            },

            buildChart(chartOptions, seriesConfig, chartType) {
                let me = this

                let cfg = {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: chartType
                    },
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    },
                    title: {
                        useHTML: true,
                        align: 'left',
                        style: {
                            'color': '#7E8494',
                            'border-bottom': '1px solid #E0E1E6',
                            'display': 'block',
                            'width': '100%'
                        },
                        text: me.overallRecord.title
                    },
                    subtitle: {
                        text: me.overallRecord.filters_applied,
                        useHTML: true,
                        align: 'left',
                        style: {
                            'margin': '10px 0 0 0'
                        }
                    },
                    xAxis: {
                        categories: seriesConfig.categories,
                        visible: true,
                        labels: {
                            style: {
                                fontSize: '10px',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                width: 70
                            }
                        },
                        events: {
                            setExtremes: function(event) {
                                me.$emit('chartSelected', event)
                            }
                        }
                    },
                    yAxis: [{
                        min: 0,
                        endOnTick: false,
                        title: {
                            text: '# of Students'
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold'
                            }
                        },
                        events: {
                            setExtremes: function(event) {
                                me.$emit('chartSelected', event)
                            }
                        }
                    },{
                        min: 0,
                        title: {
                            text: ''
                        },
                        gridLineWidth: 0,
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold'
                            }
                        },
                        opposite: true
                    }],
                    legend: {
                        align: 'left',
                        shadow: false,

                    },
                    tooltip: {
                        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                        shared: true
                    },
                    plotOptions: {
                        series: {
                            cursor: 'pointer',
                            point: {
                                events: {
                                    /*
                                    On click, we need to loop through the raw data to find the record in question.
                                    This probably could have been stored on the chart series itself, but maybe one day
                                    when we rewrite the backend.  I'm not not coding this anymore ;)
                                    */
                                    click: function() {
                                        let category = this.category
                                        let found    = null
                                        let descriptor = this.series.name

                                        me.chartRecord.forEach(function(rec) {
                                            if (rec.name === category) found = rec
                                        })

                                        if (!found) return
                                        let descriptorId = found[descriptor + '-TGID']
                                        let params = {
                                            target_descriptor_id: descriptorId
                                        }
                                        Object.assign(params, found.student_params)

                                        me.$dockableWindow({
                                            component: 'chart-drilldown',
                                            name: me.overallRecord.title + ' Drilldown',
                                            attrs: {
                                                params: params,
                                                // drilldownData: data,
                                                record: me.overallRecord,
                                                // currentCategory: this.scopeList[idx].currentCategory,
                                                // overallResults: this.scopeList[idx].overallResults
                                            }

                                        })
                                        // me.$store.dispatch('doQuery', {
                                        //     endpoint: 'targetScores.php?action=get&property=students' + me.buildParameters(params),
                                        //     state: 'students',
                                        //     rootProperty: 'categories'
                                        // }).finally(() => {
                                        //     me.$store.commit('set', {
                                        //         key: 'loading',
                                        //         value: false
                                        //     })
                                        // })
                                    }
                                }
                            }
                        },
                        column: {
                            maxPointWidth: 50,
                            stacking: 'percent',
                            dataLabels: {
                                enabled: false
                            }
                        },
                        line: {
                            marker: {
                                enabled: false
                            }
                        },
                        navigation: {
                            buttonOptions: {
                                enabled: false
                            }
                        }
                    },
                    series: seriesConfig.series,
                    dash_id: null,
                    saved_search_id: null,
                    index: null
                }
                cfg.yAxis[0] = {
                    min: cfg.yAxis.min,
                    title: {
                        text: cfg.yAxis[0].title.text
                    }
                }
                this.trendline.data.forEach(data => {
                    cfg.series.push({
                        type: 'line',
                        data: data.data,
                        color: data.color,
                        name: data.name,
                        yAxis: 1,
                        zIndex: 1
                    })
                })
                cfg.plotOptions.line.marker.enabled = true
                cfg.yAxis[1].title.text = this.trendline.data[0].name
                cfg.yAxis[1].min = this.trendline.data[0].min
                cfg.yAxis[1].max = this.trendline.data[0].max
                if(this.config && this.config.search_id) {
                    cfg.dash_id = this.config.search_id.dashboard_id
                    cfg.saved_search_id = this.config.search_id.saved_search_id
                }
                if(this.config && this.config.index) {
                    cfg.index = this.config.index
                }
                return cfg
            },

            buildParameters(v) {
                let opts = ''

                for (let key in v) {
                    if (v[key] || v[key] === 0 || v[key] === "0") {
                        opts += '&' + key + '=' + (Array.isArray(v[key]) ? v[key] + '%2c' : v[key])
                    }
                }
                return opts
            },

            doExpand() {
                let c = {
                    config: this.$props.config,
                    height: window.innerHeight-200,
                    type: this.$props.type,
                    showTools: false
                }
                this.$store.commit('global/addDockableWindow', {
                    name: this.config.title,
                    component: 'stacked-chart',
                    attrs: c
                })
            }
        },

        mounted() {
            this.$emit('all-charts', this.$refs.stackedChart)
        }
    }
</script>

<style lang="scss" scoped>

    .chart-container {
        padding: 8px;
    }

    .border {
        border: 1px solid #E0E1E6;
        border-radius: 5px;
    }

    .chart-title-bar {
        height: 20px;
        border-radius: 2px;
        padding: 5px;

        .chart-title {
            color: #404040;
        }
    }

    .performance-band-indicator {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 20px;
    }

    .expand-chart-dialog {
        height: 90vh;
    }

</style>
