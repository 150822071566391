<template>
    <fe-tooltip :tooltip="params.disabledTooltip" :disabled="params.data[params.enabledField]">
        <div>
            <i :class="`fe-grid-icon ${params.icon} theme--light ${ !params.data[params.enabledField] ? 'disabled-icon' : '' }`"/>
        </div>
    </fe-tooltip>
</template>

<script>
    import Vue from "vue";

    export default Vue.extend({
        name: 'DownloadColumn',
    })
</script>

<style lang="scss" scoped>
.fe-grid-icon {
    cursor: pointer;
}

.disabled-icon {
    opacity: 0.5;
    pointer: not-allowed;
}
</style>