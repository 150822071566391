<template>
    <div class="d-flex">
        <div v-if="fieldConfig.as_table" class="flex-fill no-scroll mb-5">
            <fe-grid
                ref="grid"
                :style="style"
                :defaultColDefOverrides="defaultColDef"
                :columnDefs="columnDefs"
                :rowData="rowData"
                :showToolbar="false"
                :showAddRowBtn="false"
                showScrollbars
                displayDensity="small"
                @gridReady="resize"
                @gridColumnsChanged="resize"
            />
        </div>
        <div v-else
            class="single-field"
        >
            <div class="pa-1" v-html="fieldValue"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DemographicField',
        props: {
            data: {},
            fieldRec: {},
            editor: {},
        },
        methods: {
            resize() {
                this.$refs.grid?.gridApi?.sizeColumnsToFit()
            },
        },
        computed: {
            fieldConfig() {
                let config
                if(this.editor) {
                    // formFieldSavedSearch comes from either the crud or is update by the xtypeconfig
                    config = {
                        ...this.formFieldSavedSearch,
                        ...this.formFieldSavedSearch?.config_json,
                    }
                    // the config is only really not valid if it's a field without a demographic_id
                    // formResponse will report that as config_error while the crud/xtypeconfig tells us through demographic_id
                    this.configValid = this.$_.isBlank(config) || config.as_table || config.demographic_id
                } else {
                    // this comes from the instance response
                    config = this?.data || {}
                    this.configValid = this.$_.isBlank(config) || config.as_table || !config.config_error
                }
                this.$emit('blockError',!this.configValid)
                return config
            },
            fieldValue() {
                let noConfigText = `<span style="color:red;">Configure demographic smartfield</span>`

                if(this.$_.isBlank(this.fieldConfig)) {
                    return null
                } else {
                    if(this.editor) {
                        return this.configValid ? '' : noConfigText

                    } else {
                        if(this.configValid) {
                            return this.fieldConfig.demographics?.length
                                ? this.fieldConfig.demographics[0].display_value
                                : 'Not Specified'
                        } else {
                            return noConfigText
                        }
                    }
                }
            },
            rowData() {
                return this.data?.demographics ? this.data.demographics : []
            },
            style() {
                let h = 68+30*(Math.max(1,this.rowData.length))
                if(h>300) h = 300
                return {
                    width: '100%',
                    height: `${h}px`,
                }
            },
            columnDefs() {
                let cols = this.fieldConfig?.display_columns
                return cols ? [{
                    headerName: 'Demographic Name',
                    field: 'demographic_name',
                    hide: cols ? !cols.includes('demographic_name') : true
                }, {
                    headerName: 'Value',
                    field: 'display_value',
                    hide: cols ? !cols.includes('display_value') : true
                }, {
                    headerName: 'School Year',
                    field: 'school_year_name',
                    hide: cols ? !cols.includes('school_year_name') : true
                }, {
                    headerName: 'Start Date',
                    field: 'start_date',
                    valueFormatter: v => v.data.start_date ? this.$dayjs(v.data.start_date).format('YYYY-MM-DD') : '',
                    maxWidth: 100,
                    hide: cols ? !cols.includes('start_date') : true
                }, {
                    headerName: 'End Date',
                    field: 'end_date',
                    valueFormatter: v => v.data.end_date ? this.$dayjs(v.data.end_date).format('YYYY-MM-DD') : '',
                    maxWidth: 100,
                    hide: cols ? !cols.includes('end_date') : true
                }, {
                    headerName: 'Created',
                    field: 'created',
                    valueFormatter: v => v.data.created ? this.$dayjs(v.data.created).format('YYYY-MM-DD') : '',
                    maxWidth: 100,
                    hide: cols ? !cols.includes('created') : true
                }] : []
            }
        },
        data() {
            if(!this.data) {
                // if no response data then get the columns
                this.$modelGet('formFieldSavedSearch', { form_field_id:this.fieldRec.id })
                .then(r => {
                    if(r.length) {
                        this.formFieldSavedSearch = r.length && r[0]
                        this.formFieldSavedSearch.demographic_id = this.formFieldSavedSearch?.args?.DEMOGRAPHIC_ID
                    }
                })
            }

            return {
                configValid: true,
                formFieldSavedSearch: null,
                defaultColDef: this.editor ? {
                    sortable: false,
                    resizable: false,
                    filter: false,
                    hide: false,
                    cellClass: 'x-grid-column'
                } : {
                    sortable: true,
                    resizable: true,
                    filter: true,
                    hide: false,
                    cellClass: 'x-grid-column'
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
.header {
    color: rgb(126,132,148);
    font-size: 12px;
}
.single-field {
    border: 1px solid #ced0d9;
    border-radius: 3px;
    min-height: 36px;
    padding: 2px;
    margin-bottom: 19px;
    background-color: white;
    width: 100%;
}
</style>
