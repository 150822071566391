<template>
    <div>
        <div class="d-flex">
            <v-toolbar color="white" flat dense>
                <v-toolbar-title :class="{optional: isOptional()}" >Description</v-toolbar-title>
            </v-toolbar>
        </div>
        <div style="padding: 0 15px">
            <div class="theme--light v-label" :class="{required: !isOptional()}" >Incident Description *</div>
            <v-textarea
                v-model="studentRec.description"
                auto-grow
                :counter="7500"
                :maxlength="7500"
                flat solo dense
                :rules="[v=>isRequired(v)]"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DescriptionPanel',
        props: {
            studentRec: Object,
            panelVisibility: Number
        },
        data() {
            return {
                rules: [v=>this.isRequired(v)]
            }
        },
        watch: {
            panelVisibility(v) {
                this.$children.forEach( c => c.validate && c.validate() )
            }
        },
        methods: {
            isRequired(v) {
                return this.panelVisibility == 2 && !v ? 'Field is required' : true
            },
            isOptional() {
                return this.panelVisibility == 1
            }
        }
    }
</script>

<style scoped="true">

</style>
