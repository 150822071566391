<template>
    <div>
        <fe-dialog
            :title="localTitle"
            :width="width"
            height=600
            :acceptButtonText="acceptButtonText"
            :acceptButtonDisabled="!formValid || !editingChanges"
            dismissButtonText="Cancel"
            persistent
            disableAutoclose
            @dismiss="dismiss"
            @accept="doSave"
        >
            <div>
                <fe-mask v-model="loading" showLoader/>
                <v-form v-if="ready" @submit.prevent v-model="formValid" lazy-validation>
                    <fe-label v-if="!assigneesOnly">Action Item Name</fe-label>
                    <fe-text-field
                        v-if="!assigneesOnly"
                        v-model="config.name"
                        width="100%"
                        counter=50
                        :rules="$fieldValidators('text', 'Action Item Name', {required: true, limit: 50})"
                        flat solo dense
                        clearable
                        @change="onChangesMade($event, 'name')"
                        hint=" "
                        class="mb-5"
                    />

                    <slot></slot>

                    <div>
                        <div class="d-flex" style="position: relative">
                            <fe-remote-combo
                                ref="usersSearchField"
                                v-model="userQuickAdd"
                                :label="!assigneesOnly ? 'Manage Assignees' : null"
                                :items="availableUsers"
                                itemText="user_full_name"
                                itemValue="id"
                                class="flex-grow-1"
                                placeholder="Quick Add"
                                @input="assigneesSelected"
                            />
                            <span class="assignees-advanced" @click="openUserSearch">
                            <u>Advanced</u>
                        </span>
                        </div>
                        <div v-if="currentAssignees.length" class="chip-wrapper d-flex">
                            <div class="flex-grow-1">
                                <v-chip
                                    v-for="user in currentAssignees"
                                    class="chip"
                                    :class="{avatar: user.photo_url}"
                                    close
                                    @click:close="assigneeDeleted(user)"
                                >
                                    <v-avatar v-if="user.photo_url">
                                        <img :src="user.photo_url" alt="">
                                    </v-avatar>
                                    {{user.user_full_name }}
                                </v-chip>
                            </div>
                            <div class="flex-shrink-1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="clearAll">
                                            <v-icon color="#0000008a" small>fal fa-times</v-icon>
                                        </v-btn>
                                    </template>

                                    <span>Clear All</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>

                    <div v-if="!assigneesOnly" class="d-flex">
                        <fe-date-picker label="Due Date (Optional)" class="flex-grow-1 mr-2" v-model="config.due_dt" prependIcon="fa-calendar-alt"
                            @input="onChangesMade($event, 'due_dt')"/>
                        <fe-time-scroll-picker label="Due Time (Optional)" class="flex-grow-1 ml-1" v-model="config.due_time"
                            @input="onChangesMade($event, 'due_time')"/>
                    </div>

                    <fe-remote-combo
                        v-if="!assigneesOnly"
                        label="Status"
                        v-model="config.task_status_id"
                        :items="statusOptions"
                        itemText="name"
                        itemValue="id"
                        byId
                        required
                        @change="onChangesMade($event, 'task_status_id')"
                    >
                        <template v-slot:item="data">
                            <v-icon :color="data.item.color" class="mr-3" small>fas fa-square</v-icon>{{data.item.name}}
                        </template>
                    </fe-remote-combo>

                    <fe-remote-combo
                        v-if="goals.length"
                        label="Link to Goal (Optional)"
                        v-model="config.goal_id"
                        :items="availableGoals"
                        itemText="name"
                        itemId="id"
                        hide-detail
                        byId
                        @change="onChangesMade($event, 'goal')"
                    ></fe-remote-combo>

                    <fe-label v-if="!assigneesOnly">Description (Optional)</fe-label>
                    <fe-text-area
                        width="100%"
                        v-model="config.description"
                        @change="onChangesMade($event, 'description')"
                    />
                </v-form>
            </div>
        </fe-dialog>

        <fe-dialog
            v-if="userSearch.show"
            title="Find Users"
            :width="width"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            dismissable
            persistent
            @close="userSearch.show = false"
            @accept="acceptUserSearch"
        >
            <user-search v-model="userSearch.selection"/>
        </fe-dialog>

        <fe-crud ref="taskCrud" :config="taskCrudConfig"/>
        <fe-crud ref="taskAssigneeCrud" :config="taskAssigneeCrudConfig" createSuccessSnackbarMessageProperty="msg"/>
        <fe-crud ref="userCrud" :config="userCrudConfig" autoload @read="users = $event"/>
        <fe-crud ref="goalTaskCrud" :config="goalTaskCrudConfig"/>
    </div>
</template>

<script>
    import {mapState} from "vuex"
    import UserSearch from "../../common/UserSearch"

    export default {
        name: 'EditActionItem',

        components: {UserSearch},

        props: {
            title: {
                type: String
            },
            width: {
                type: Number,
                default: 800
            },
            acceptButtonText: {
                type: String,
                default: 'Save'
            },
            assigneesOnly: {
                type: Boolean,
                default: false
            },
            standalone: {
                type: Boolean,
                default: false
            },
            editing: undefined,
            parent: undefined,
            extraParams: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            activeUsersOnly: {
                type: Boolean,
                default: true
            },
            goals: {
                type: Array,
                default: () => []
            }
        },

        data() {
            return {
                ready: false,
                formValid: false,
                userSearch: {
                    show: false,
                    selection: []
                },
                config: {
                    name: undefined,
                    due_dt: undefined,
                    due_time: undefined,
                    goal_id: undefined,
                    description: undefined,
                    assignees: [],
                    task_status_id: 1,
                    rank: undefined,
                    kpi_dashboard_id: undefined
                },
                addAssignees: [],
                deleteAssignees: [],
                users: [],
                userQuickAdd: undefined,
                statusOptions: [
                    {
                        id: 1,
                        name: 'To Do',
                        color: '#2B87FF'
                    },
                    {
                        id: 2,
                        name: 'On Hold',
                        color: '#FFA200'
                    },
                    {
                        id: 3,
                        name: 'Done',
                        color: '#746DE0'
                    },
                    {
                        id: 4,
                        name: 'In Progress',
                        color: '#0EA449'
                    }
                ],
                editingChanges: false,
                localValue: {},
                loading: false,
            }
        },

        computed: {
            ...mapState('global', ['currentYear', 'sessionUser']),
            ...mapState('actionPlan', ['activeDashboard']),

            taskCrudConfig() {
                return this.$_.cloneDeep(this.$models.task)
            },

            taskAssigneeCrudConfig() {
                return this.$_.cloneDeep(this.$models.taskAssignee)
            },

            userCrudConfig() {
                let cfg = this.$_.cloneDeep(this.$models.user)
                cfg.read.params.school_year_id = this.currentYear.id
                cfg.read.params.active = this.activeUsersOnly ? 1 : null
                return cfg
            },

            localTitle() {
                if (this.title) return this.title
                if (this.assigneesOnly) return 'Manage Assignees'
                return this.editing ? 'Edit Action Item' : 'Create Action Item'
            },

            currentAssignees() {
                let minus = this.$_.differenceBy(this.config.assignees, this.deleteAssignees, 'id')
                let plus = this.$_.concat(minus, this.addAssignees)

                return this.$_.uniqBy(plus, 'id')
            },

            availableUsers() {
                return this.$_.differenceBy(this.users, this.currentAssignees, 'id')
            },

            availableGoals() {
                return this.goals.map(goal => {
                    let name = goal.custom_statement || goal.default_statement
                    return {
                        id: goal.id,
                        name: name
                    }
                })
            },

            goalTaskCrudConfig() {
                return this.$_.cloneDeep(this.$models.goalTask)
            }
        },

        watch: {
            editing(v) {
                if (this.ready) {
                    this.config = Object.assign(this.config, v)
                }
            },
        },

        mounted() {
            this.editingChanges = false
            if (this.editing && this.editing.id) {
                this.loadTask()
            } else if(this.editing && this.editing.id == null) { // if this is sample data (eg empty action plan) without an actual id
                this.config = Object.assign(this.config, this.editing)
                this.ready = true
            } else {
                this.ready = true
            }
        },

        methods: {
            loadTask() {
                this.$refs.taskCrud.read({id: this.editing.id}).then(res => {
                    let task = res.data.tasks[0]
                    if (task) {
                        this.config = Object.assign(this.config, task)
                        this.localValue = this.$_.cloneDeep(Object.assign(this.config, task))
                    }
                    this.ready = true
                })
            },

            doSave() {
                this.loading = true

                let payload = this.$_.cloneDeep(this.config)
                payload.kpi_dashboard_id = this.activeDashboard?.id ? this.activeDashboard.id : null
                payload.due_time = payload.due_time ? this.$dayjs('2020-01-01 ' + payload.due_time).format('HH:mm:ss') : null

                if (this.standalone) {
                    delete payload.kpi_dashboard_id

                    if (this.extraParams) {
                        Object.assign(payload, this.extraParams)
                    }
                }

                if (!payload.rank) {
                    if (this.parent) {
                        payload.rank = this.parent.tasks.length
                    } else {
                        payload.rank = 0
                    }
                }

                payload.assignees = this.currentAssignees

                if (this.extraParams) {
                    payload = Object.assign(payload, this.extraParams)
                }

                if (payload.goal_id) {
                    payload.kpi_dashboard_id = this.goals.length ? this.goals[0].kpi_dashboard_id : null
                }

                let action = this.config.id ? 'update' : 'create'
                let root = this.config.id ? 'ids' : 'tasks'

                this.$refs.taskCrud[action]([payload]).then(res => {
                    let task = action === 'update' ? payload : res.data[root][0]
                    let transactions = []
                    let taskId = task.id

                    if (!this.standalone) {
                        if (payload.goal_id) {
                            if (this.editing && this.editing.goal_id !== payload.goal_id) {
                                if (this.editing.goal_link_id) {
                                    transactions.push(this.unlinkTasks({id: this.editing.goal_link_id}))
                                }
                                transactions.push(this.linkTasks({
                                    goal_id: payload.goal_id,
                                    task_id: taskId,
                                    rank: payload.rank
                                }))
                            } else if(!this.editing) {
                                transactions.push(this.linkTasks({
                                    goal_id: payload.goal_id,
                                    task_id: taskId,
                                    rank: payload.rank
                                }))
                            }
                        } else {
                            if (this.editing && this.editing.goal_link_id) {
                                transactions.push(this.unlinkTasks({id: this.editing.goal_link_id}))
                            }
                        }
                    }

                    if (this.addAssignees.length) {
                        let add = this.addAssignees.map(item => {
                            return {
                                task_id: taskId,
                                user_id: item.id,
                                task_status_id: 1
                            }
                        })

                        transactions.push(this.$refs.taskAssigneeCrud.create(add))
                    }

                    if (this.deleteAssignees.length && payload.id) {
                        transactions.push(this.$refs.taskAssigneeCrud.destroy(this.deleteAssignees))
                    }

                    Promise.all(transactions).then(() => {
                        if (this.standalone) {
                            this.$emit('save', task)
                        }
                        this.dismiss()
                    })
                })
            },

            dismiss() {
                this.loading = false
                this.$emit('dismiss')
            },

            assigneesSelected(data) {
                if (data) {
                    this.addAssignees = this.$_.concat(this.addAssignees, data)

                    this.assigneeCleanup()
                }
            },

            assigneeDeleted(data) {
                if (data) {
                    this.deleteAssignees.push(data)

                    this.assigneeCleanup()
                }
            },

            assigneeCleanup() {
                let existing = this.$_.intersectionBy(this.addAssignees, this.deleteAssignees, 'id')

                if (existing.length) {
                    this.addAssignees = this.$_.differenceBy(this.addAssignees, existing, 'id')
                    this.deleteAssignees = this.$_.differenceBy(this.deleteAssignees, existing, 'id')
                }

                setTimeout(() => {
                    this.userQuickAdd = undefined
                    this.$refs.usersSearchField.$refs.autocomplete.$refs.input.value = ''
                    this.editingChanges = true // if user added/deleted during edit, enable Save button
                }, 10)
            },

            clearAll() {
                this.addAssignees = []
                this.deleteAssignees = this.$_.cloneDeep(this.config.assignees) || []
            },

            openUserSearch() {
                this.userSearch = {
                    show: true,
                    selection: []
                }
            },

            acceptUserSearch() {
                this.userSearch.show = false
                this.assigneesSelected(this.userSearch.selection)
            },

            onChangesMade(v, id) {
                switch(id) {
                    case 'due_time':
                        v = this.$dayjs('2020-01-01 ' + v).format('HH:mm:ss')
                        break
                    case 'task_status_id':
                        v = v.id
                        break
                    default:
                        break
                }
                // if a change was made during edit, enable Save button
                if(v !== this.localValue[id]) {
                    this.editingChanges = true
                }
            },

            unlinkTasks(item) {
                let payload = {
                    goal_task: [item]
                }
                this.$refs.goalTaskCrud.destroy(payload)
            },

            linkTasks(item) {
                let dispatch = 'create'
                if (item.hasOwnProperty('id')) {
                    dispatch = 'update'
                }
                let payload = {
                    goal_task: [item]
                }
                this.$refs.goalTaskCrud[dispatch](payload)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .assignees-advanced {
        position: absolute;
        right: 35px;
        top: 35px;
        font-size: 12px;
        color: var(--v-primary-base);
        cursor: pointer;
    }

    .chip-wrapper {
        margin-top: -20px;

        ::v-deep .chip {
            .v-chip__content {
                max-width: unset;
            }

            &.avatar {
                .v-chip__content {
                    padding-left: 0;
                }
            }
        }
    }
</style>
