<template>
<div
    :draggable="editor && editorState.draggable"
    @click.stop="onClick"
    @dragstart.stop="onDragStart"
    @drop.stop="onDrop"
>
<!-- @dragover.prevent -->
    <div
        class="section"
        :class="{
            border: !editor && config.border,
            'editor-state': editor,
            'editor-state-selected': editor && editorState.selected,
            'disabled-section': isDisabled,
        }"
    >
        <div v-intersect="onIntersect" style="height:1px;"></div>
        <div class="d-flex section-title" :class="{'section-title-intersecting': editorState.intersecting}">
            <v-row v-if="editor" style="flex-wrap: nowrap; width:100%;">
                <v-menu offset-y >
                    <template #activator="{ on: gripMenu }">
                        <div class="dragbar"
                            @mouseover="editorState.draggable=true" @mouseleave="editorState.draggable=false"
                            v-on="{...gripMenu}"
                        >
                            <i class="fe-grid-icon far fa-ellipsis-v theme--light"></i>
                            <i class="fe-grid-icon far fa-ellipsis-v theme--light"></i>
                        </div>
                    </template>

                    <v-list dense>
                        <v-list-item @click="$emit('duplicate',config)">Duplicate</v-list-item>
                        <v-list-item @click="$emit('delete',config)">Delete</v-list-item>
                    </v-list>

                </v-menu>

                <v-text-field v-if="editorState.selected"
                    ref="titleField"
                    class="ma-1"
                    flat solo dense hide-details
                    :value="config.name"
                    :autofocus="/^Section \d+$/.test(config.name)"
                    :rules="$fieldValidators('text', null, {limit:100})"
                    @change="updateName"
                    @focus="/^Section \d+$/.test(config.name) && $event.target.select()"
                />
                <div v-else class="mx-1 py-auto flex-shrink-1 text-truncate">{{ config.name }}</div>
                <v-spacer></v-spacer>

                <div v-if="editor && dependentSections[config.id]">
                    <i
                        class="fe-grid-icon fas fa-project-diagram theme--light icon-btn"
                        :class="{issue:hasRuleIssues}"
                        style="font-size: 12px;"
                        @click.stop="$emit('editDependency',{id:config.id})"
                    />
                </div>

                <v-menu offset-y max-height="60vh" content-class="add-field-button">
                    <template #activator="{ on }">
                        <fe-btn v-on="on" @click="addFilterText=''" usage="tertiary">Add Field</fe-btn>
                    </template>
                    <fe-search v-model="addFilterText" />
                    <v-list dense style="height:50vh; overflow:auto;" subheader>
                        <template v-for="item in formFieldTypesSorted((a,b) => a.group > b.group ? -1 : 1)
                            .filter(v=>v.name.toLowerCase().includes(addFilterText ? addFilterText.toLowerCase() : ''))"
                        >
                            <v-subheader v-if="addBtnSubheader(item)" v-text="addBtnSubheader(item,true)"
                                style="position: sticky; top:0px; background-color:white; z-index:1;"
                            />
                            <v-list-item @click="$emit('addField',{fieldType:item,section:config})" :key="item.id">
                                {{ item.name }}
                            </v-list-item>
                        </template>
                    </v-list>
                </v-menu>

            </v-row>

            <div v-else-if="!config.hide_label" class="text-truncate" >
                {{ config.name }}
            </div>
        </div>
        <div ref="section" class="section-body">

            <div>
                <slot/>
            </div>
            <!-- <div class="disabled-section" v-if="isDisabled" :style="[{height: height, width: width}]"></div> -->

        </div>
    </div>
</div>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'
import smartFormMixin from '../smartFormMixin'

export default {
    name: 'FormSection',
    mixins: [ smartFormMixin ],
    inject: ['localStore'],

    props: {
        editor: {type:Boolean, default:false},
        config: {},
        isDisabled: { type: Boolean }
    },
    data() {
        return {
            height: 0,
            width: 0,
            addFilterText: '',
            editorState: {
                selected: false,
                draggable: false,
                intersecting: false,
            },
        }
    },
    computed: {
        ...mapLocalState('dependentSections'),
        isSelected: {
            set(v) { this.editorState.selected = v },
            get() {return this.editorState.selected},
        },
        hasRuleIssues: me => {
            return me.dependentSections[me.config.id] && me.dependentSections[me.config.id].find(x => x.dupWhen)
        }
    },
    updated() {
    },
    created() {
        // this.$data.height = this.$refs.section.clientHeight + 'px'
        // this.$data.width = this.$refs.section.clientWidth + 'px'
    },
    methods: {
        log(v) {},
        addBtnSubheader(item,update) {
            if(this.lastSubheader != item.group) {
                if(update) this.lastSubheader = item.group
                return item.group
            }
            return null
        },
        onIntersect(entries,observer,isIntersecting) {
            this.editorState.intersecting = isIntersecting
        },
        onClick(v) {
            this.editor && this.$emit('editor:click',this)
            this.$emit('clear-action-popup')
        },
        onDragStart(e) {
            e.dataTransfer.setData('sourceRec',JSON.stringify(this.config))
        },
        onDrop(e) {
            let source = JSON.parse(e.dataTransfer.getData('sourceRec'))
            this.$emit('dropped',{target:this.config, source})
        },
        updateName(e) {
            // v-text-field @change to fires on returns AND blurs even if nothing changed
            // https://github.com/vuetifyjs/vuetify/issues/10477
            if(e != this.config.name) {
                this.config.name = e
                this.$emit('configChange',this.config)
            }
        },
    },
}
</script>

<style lang="scss" scoped>

// $red: #{red(var(--fe-selected))};
// @function rgb-values($h) {
//     @return red($h), green($h), blue($h);
// }
// $fe-selected-3: #{var(--fe-selected)};
// $fergb: #{rgb-values($fe-selected-3)};
// https://www.techhive.io/our-insights/how-to-use-css-variables-with-sass-mixins

.issue {
    color: red;
}

.icon-btn {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    &:hover {
        background-color: rgba(0,0,0,.1) ;
    }
}
.section {
    margin: 4px;
    border-radius: 4px;

    .section-title {
        background-color: white;
        font-size: 16px;
        font-weight: regular;
        line-height: 48px;
        padding-left: 16px;
        padding-right: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .section-body {
        background-color: white;
        padding: 16px;
    }
}
.section.editor-state {
    --red: RED(var(--fe-selected));
    --green: GREEN(var(--fe-selected));
    --blue: BLUE(var(--fe-selected));


    .section-title {
        position: sticky;
        top: 0;
        z-index: 99;
        background-color: var(--fe-tertiary);
        padding: 0px;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
        border-top: 1px solid lightgray;
        transition: box-shadow .5s;
        &-intersecting {
            box-shadow: none;
            border: none;
        }
        .dragbar {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 20px;
            height: 100%;
            &:hover {
                box-shadow:inset 0 0 0 99999px rgba(0,0,0,0.1);
            }
        }
    }
    .section-body {
        background-color: rgba(#ECEDF1,.3);
    }

    &-selected {
        // outline: 2px solid var(--fe-selected);
        // outline-offset: -3px;
        // border-color: var(--fe-selected);
        // border-width: 2px;
        .section-title {
            background-color: var(--fe-selected);
        }
        .section-body {
            background-color: rgba(#CCE2E9,.3);
        }
    }
}

.disabled-section {
    background-color: gray;
    z-index: 100;
    opacity: .5;
    pointer-events: none;
}

.border {
    border: 1px solid lightgray;
}

.add-field-button {
    background-color: white;
    overflow: hidden;
}
</style>
