import axios from 'axios'
import Api from '../services/Api'
import {toJson} from 'really-relaxed-json'

export default {
    config() {
        return {
            mappings: {
                assessment: {
                    endpoint: 'assessmentThreshold.php',
                    name: 'Academic',
                    prop: 'assessment_threshold'
                },
                attendance: {
                    endpoint: 'attendanceThreshold.php',
                    name: 'Attendance',
                    prop: 'attendance_threshold'
                },
                behavior: {
                    endpoint: 'behaviorThreshold.php',
                    name: 'Behavioral',
                    prop: 'behavior_threshold'
                }
            }
        }
    },

    getMapping(type) {
        return this.config().mappings[type]
    },

    getDataFromResponses(rsps) {
        let result = []
        Object.entries(this.config().mappings).forEach(([k, v]) => {
            rsps.forEach(rsp => {
                if (rsp.data) {
                    let rspData = (typeof rsp.data === "object")
                        ? rsp.data
                        : JSON.parse(toJson(rsp.data))
                    if (rspData[v.prop]) {
                        rspData[v.prop].forEach(itm => {
                            result.push(Object.assign({}, itm, {__type: k}))
                        })
                    }
                }
            })
        })
        return result
    },

    buildRequests(opts, params) {
        let requests = []
        if (opts.type) {
            let mapping = this.getMapping(opts.type)
            requests.push(Api().get(mapping.endpoint, {params: params}))
        } else {
            // eslint-disable-next-line
            Object.entries(this.config().mappings).forEach(([_, mapping]) => {
                requests.push(Api().get(mapping.endpoint, {params: params}))
            })
        }
        return requests
    },

    fetch(opts, cb) {
        let params = Object.assign({action: 'get'}, opts.params)
        axios.all(this.buildRequests(opts, params)).then((rsps) => {
            if (cb) cb(this.getDataFromResponses(rsps))
        })
    },

    send(opts, cb) {
        let mapping = this.getMapping(opts.type)
        let data = {[mapping.prop]: [opts.obj]}
        Api().post(mapping.endpoint, data, {params: opts.params}).then((rsp) => {
            if (cb) cb(this.getDataFromResponses([rsp]))
        })
    },

    update(opts, cb) {
        let params = {params: {action: 'update'}}
        this.send(Object.assign(opts, params), cb)
    },

    add(opts, cb) {
        let params = {params: {action: 'create'}}
        this.send(Object.assign(opts, params), cb)
    },

    remove(opts, cb) {
        let params = {params: {action: 'delete'}}
        this.send(Object.assign(opts, params), cb)
    }
}
