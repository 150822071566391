let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    security: {
        defaults: {
            endpoint: 'security.php',
            rootProperty: 'security'
        },
        read: {
            params: { action: 'get', property: 'codes' }
        }
    },

}
