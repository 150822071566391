<template>
    <div>
        <fe-card
            class="alt-card ma-3"
            headerText="Progress Toward Assessment Goal"
        >
            <template v-slot:card-title>
                <div class="card-title">Progress Toward Assessment Goal</div>
            </template>
            <template v-slot:body>
                <v-skeleton-loader
                    class="mx-auto"
                    type="text@5"
                    :loading="isLoading"
                    style="min-height: 130px;"
                >
                    <div>
                        <div class="d-flex" style="height: 125px;" >
                            <div class="ma-1 goal-container flex-grow-1" v-ripple v-for="s in sections" @click="$emit('drilldown', s)">
                                <div class="goal-number py-2" :style="{background: s.color}">
                                    {{s.count}}

                                    <div>{{totalCount ? parseFloat((s.count / totalCount)*100).toFixed(1) : '--'}}%</div>
                                </div>

                                <div class="goal-title">{{s.title}}</div>
                            </div>
                        </div>
                        <div @click="$emit('drilldown')" class="ec-text-link">View All</div>
                    </div>
                </v-skeleton-loader>
            </template>
        </fe-card>
    </div>
</template>

<script>
    export default {
        name: 'AcademicGoalProgress',
        props: {
            params: {

            }
        },
        watch: {
            params: {
                handler(v) {
                    if (v && Object.values(v).length) this.loadData()
                },
                immediate: true
            }
        },
        computed: {
            gridWidth() {
                return window.innerWidth-50
            }
        },
        data() {
            return {
                items: [],
                isLoading: false,
                participationConfig: {},
                procedureConfig: {},

                dialog: {
                    show:false,
                    data: []
                },
                sections: [{
                    key: 'total_on_track',
                    color: '#19ADAB',
                    title: 'On Track',
                    count: 0,
                    data: []
                }, {
                    key: 'total_not_on_track',
                    color: '#FF675D',
                    title: 'Not On Track',
                    count: 0,
                    data: []
                }, {
                    key: 'total_no_goal',
                    color: '#FFEEAA',
                    title: 'No Goal Set',
                    count: 0,
                    data: []
                }, {
                    key: 'total_no_roi',
                    color: '#C0C3CF',
                    title: 'No Data Entered',
                    count: 0,
                    data: []
                }],
                totals: {
                },
                totalCount: 0
            }
        },
        methods: {
            setDetail(key) {

            },
            loadData() {
                this.isLoading=true
                this.$axios.get('interventionView.php?action=get&property=plan_roi_strategy&'+this.$objectToParams(this.params))
                    .then(r => {
                        this.isLoading=false
                        this.items = this.$ecResponse(r, 'interventions')
                        this.processData()
                    })
            },

            processData() {
                this.totals = {}
                this.totalCount = 0

                this.sections.forEach(s => {
                    s.count = 0
                    s.data  = []
                })

                this.items.forEach(item => {
                    this.sections.forEach(s => {
                        let n = item[s.key] || 0
                        s.count += parseInt(n)
                        this.totalCount += parseInt(n)
                    })
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.clickable {
    cursor: pointer;
}
.card-title {
    overflow: hidden;
    line-height: 48px;
    font-size: 16px;
    padding-left: 16px;
    white-space: nowrap;
    color: #2B3963;
}

.goal-container {
    text-align: center;
    width: 90px;
    cursor: pointer;

    .goal-number {
        font-size: 12px;
        border-radius: 4px;
    }

    .goal-title {
        font-size: 16px;
    }
}
</style>
