<template>
    <div class="d-flex flex-column fill-flex">
        <div class="headline mb-1 d-flex">
            Exit Status
            <fe-info-tip
                    class="ml-2"
                    tooltip="Available statuses for an intervention to be set to on completion/exit. When an Exit Strategy is in use it cannot be deleted."
            />
        </div>

        <fe-dialog width="500" v-model="createDialog.show" v-if="createDialog.show"
                   title="Add Intervention Exit Status"
                   :footer="false"
                   @close="createDialog.show=false"
                   persistent
        >
            <model-form
                    idProperty="id"
                    @cancel="finishCreate"
                    @done="finishCreate"
                    :readParams="{active: 1}"
                    :records="createDialog.records"
                    model="interventionExitStatus"
                    :fieldConfig="fieldConfig"
                    submitButtonText="Save"
            />
        </fe-dialog>

        <fe-crud-grid
                autoload
                :config="$models.interventionExitStatus"
                @read="v => status = v"
                @beginCreate="startCreate"
                @selectionChanged="checkSelection"
                ref="grid"
                disableInlineCreate
                domLayout="autoHeight"
                :columnDefs="columns"
                :rowData="status"
                displayDensity="medium"
        >
            <template slot="selection-tools">
                <fe-btn
                        v-if="!includesUsed"
                        useIcon="fal fa-trash"
                        usage="ghost"
                        whiteText
                        @click="deleteRows"
                        class="fe-grid-action-btn"
                >
                    Delete
                </fe-btn>
                <fe-btn
                        useIcon="fal fa-arrow-alt-to-bottom"
                        usage="ghost"
                        whiteText
                        @click="exportGrid('selectedRows')"
                        class="fe-grid-action-btn"
                >
                    Download
                </fe-btn>
                <fe-btn
                        usage="ghost"
                        whiteText
                        @click="editCancel"
                        class="fe-grid-action-btn-last"
                >
                    Cancel
                </fe-btn>
            </template>
        </fe-crud-grid>

    </div>
</template>

<script>
    import ModelForm from '@/components/common/form/ModelForm'

    export default {
        name: "InterventionExitStatus",
        components: {
            ModelForm
        },
        data() {
            return {
                status: [],
                createDialog: {
                    show: false,
                    records: [{
                        icon: 'int_level_orange'
                    }]
                },
                usedStatus: [],
                loaded: false,
                includesUsed: false,
                selection: [],
            }
        },
        computed: {
            fieldConfig() {
                let me = this
                return [{
                    field: 'name',
                    label: 'Status Name',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { required: true, limit: 50 },
                    width: '100%',
                    attrs: {
                        required: true,
                        flat: true,
                        solo: true,
                        dense: true,
                        counter: 50
                    },
                    events: {
                        blur(field, item) {
                            if (!item.tag_name) {
                                item.tag_name = item.name
                            }
                        }
                    }
                }, {
                    field: 'description',
                    label: 'Description',
                    component: 'v-textarea',
                    validatorKey: 'text',
                    validatorConfig: { required: true, limit: 255 },
                    width: '100%',
                    attrs: {
                        required: true,
                        flat: true,
                        solo: true,
                        dense: true,
                        counter: 255
                    }
                }, {
                    field: 'tag_name',
                    label: 'Tag Name on Exit',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { required: true, limit: 50 },
                    width: '100%',
                    attrs: {
                        required: true,
                        flat: true,
                        solo: true,
                        dense: true,
                        counter: 50
                    }
                }, {
                    field: 'successful',
                    label: 'Successful Exit',
                    component: 'fe-switch'
                }]
            },
            columns() {
                // make sure status usage check happens before columns are loaded
                if (!this.loaded) return null

                return [this.$grid.checkColumn(),
                    {
                        headerName: 'Status',
                        field: 'name',
                        flex: 1,
                        editable: true
                    }, {
                        headerName: 'Description',
                        field: 'description',
                        flex: 1,
                        editable: true
                    }, {
                        headerName: 'Tag Name',
                        field: 'tag_name',
                        flex: 1,
                        editable: true
                    }, this.$grid.toggleColumn('Force Tag', 'force_tag', 'Force a tag to be applied to a student with this exit status', () => { }, {
                        minWidth: 100,
                        maxWidth: 100,
                        hide: true
                    }), this.$grid.toggleColumn('Successful Exit', 'successful', 'Does this status qualify as a successful exit for the student', () => { }, {
                        minWidth: 100,
                        maxWidth: 100
                    }), {
                        headerName: 'Created',
                        field: 'created'
                    }]
            }
        },
        created() {
            this.$modelGet('studentInterventionPlan')
                .then(r => {
                    r.forEach(v => {
                        if (v.intervention_exit_status_id) {
                            if (this.usedStatus.indexOf(v.intervention_exit_status_id) === -1) {
                                this.usedStatus.push(v.intervention_exit_status_id)
                            }
                        }
                    })
                    // ok to load columns
                    this.loaded = true
                })
        },
        methods: {
            finishCreate() {
                this.createDialog.show = false
                this.createDialog.records = [{
                    icon: 'int_level_orange',
                    active: true
                }]

                this.$refs.grid.$refs.crud.read()
            },
            startCreate() {
                this.createDialog.show=true
                this.createDialog.records = [{
                    icon: 'int_level_orange',
                    active: true
                }]
            },
            updateCrud(crud, meta) {

            },
            crudConfirmDelete() {

            },
            checkSelection(v) {
                this.selection = this.$refs.grid.$refs.grid.gridApi.getSelectedRows()
                this.selection.every(r => {
                    this.usedStatus.includes(r.id) ? this.includesUsed = true : this.includesUsed = false
                })
            },
            deleteRows() {
                this.$refs.grid.$refs.grid.deleteRows(...arguments)
            },
            exportGrid() {
                this.$refs.grid.$refs.grid.exportGrid(...arguments)
            },
            editCancel() {
                this.$refs.grid.$refs.grid.editCancel(...arguments)
            },
        }
    }
</script>

<style scoped lang="scss">

    .color-picker {
        width: 36px;
        height: 36px;
        border-radius: 4px;
        margin-right: 8px;
        cursor: pointer;

        &-selected {
            border: 2px solid #303030;
        }

    }


</style>
