<template>
    <div class="d-flex flex-fill flex-column" style="padding: 16px;">
        <cloud-ftp v-model="showCloudFTP"/>

        <v-dialog width="500" persistent v-model="dialog.show" v-if="dialog.show">
            <v-card>
                <v-card-title>
                    Create SFTP
                </v-card-title>
                <v-card-text>
                    <model-form
                        idProperty="id"
                        @cancel="finish"
                        @done="finish"
                        :records="dialog.record"
                        model="ftpUpload"
                        :fieldConfig="formFields"
                        submitButtonText="Save"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <div class="d-flex">
            <fe-btn chip usage="ghost" useIcon="fas fa-chevron-left" @click="$router.replace('/Uploads')"/>
            <div class="page-title">SFTP Manager</div>
        </div>

        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.ftpUpload"
            @read="v => items = v"
        />

        <fe-grid
            ref="grid"
            :rowData="items"
            :columnDefs="columns"
            domLayout="normal"
            class="flex-grow-1"
            displayDensity="small"
            disableInlineCreate
            @cellValueChanged="doUpdate"
            @beginCreate="doCreate"
            @selectionChanged="selectRow"
        >
            <template v-slot:toolbar-items>
                <fe-btn usage="tertiary" @click="showCloudFTP=true">climberCLOUD</fe-btn>
                <fe-btn usage="tertiary" v-if="selections.length > 0" @click="doCopy">Copy</fe-btn>
                <fe-btn usage="error" v-if="selections.length > 0" @click="doDelete">Delete</fe-btn>
            </template>
        </fe-grid>
    </div>
</template>

<script>
    import ModelForm from '@/components/common/form/ModelForm'
    import { mapState } from 'vuex'
    import CloudFTP from './CloudFTP'

    export default {
        name: 'SFTPManager',
        props: ['age'],
        components: {
            ModelForm,
            'cloud-ftp': CloudFTP
        },
        watch: {
            id(v) {
                if (v) this.loadData()
            },
            age: {
                handler(v) {
                    this.loadData()
                },
                immediate: true
            },
            'assignDialog.show'(v) {
                if (v) this.assignDialog.record = {}
            },
            showCloudFTP(v) {
                if (!v) this.loadData()
            },
        },
        data() {
            return {
                showCloudFTP: false,
                uploadTypes: [],
                uploadTypeMap: {},
                users: [],
                items: [],
                originalItems: [],
                record: {},
                ready: false,
                headersDialog: {
                    show: false,
                    record: {}
                },
                dialog: {
                    show: false,
                    record: []
                },
                selections: []
            }
        },
        computed: {
            isFixedWidth() {
                return this.record.import_file_type_id === 2 ? true : false
            },
            formFields() {
                let me = this
                return [{
                    field: 'description',
                    label: 'Name',
                    width: '100%',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { required: true, limit: 255 },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true,
                        counter: 255
                    }
                }, {
                    field: 'upload_type_id',
                    label: 'Upload Type',
                    width: '100%',
                    component: 'fe-remote-combo',
                    validatorKey: 'select',
                    validatorConfig: { required: true },
                    attrs: {
                        items: me.uploadTypes,
                        byId: true,
                        itemText: 'name'
                    }
                },  {
                    field: 'ftp_path',
                    label: 'Path',
                    width: '100%',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { required: true, limit: 255 },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true,
                        counter: 255
                    }
                },  {
                    field: 'file_pattern',
                    label: 'File Pattern',
                    width: '100%',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { required: true, limit: 255 },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true,
                        counter: 255
                    }
                }, {
                    field: 'user_id',
                    label: 'Notify User',
                    width: '100%',
                    component: 'fe-remote-combo',
                    validatorKey: 'select',
                    validatorConfig: { required: true },
                    attrs: {
                        items: me.users,
                        byId: true,
                        itemText: 'user_full_name'
                    }
                }, {
                    field: 'active',
                    label: 'Active',
                    width: '100%',
                    component: 'fe-switch'
                }]
            },
            columns() {
                let me = this
                return this.ready ? self.columns = [this.$grid.checkColumn(null, true), {
                    headerName: 'ID',
                    field: 'id',
                    maxWidth: 50,
                    hide: true,
                    pinned: 'left'
                }, {
                    headerName: 'Name',
                    field: 'description',
                    flex: 1,
                    minWidth: 300,
                    pinned: 'left',
                    editable: true
                }, {
                    headerName: 'Upload Type',
                    field: 'upload_type_id',
                    maxWidth: 300,
                    minWidth: 200,
                    cellRenderer(v) {
                        let r = me.uploadTypeMap[v.value]
                        if (r !== undefined) return me.uploadTypes[r].name
                        console.warn('cannot find', v.value, me.uploadTypes)
                        return ''
                    },
                    editable: true,
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: {
                        rowDataKey: "upload_type_id",
                        mode: "edit",
                        multiple: false,
                        items: me.uploadTypes,
                        keyProp: "id",
                        labelProp: "name"
                    }
                }, {
                    headerName: 'Notify User',
                    field: 'user_id',
                    maxWidth: 300,
                    minWidth: 200,
                    cellRenderer(v) { return v.value ? me.users.find(user => user.id == v.value).user_full_name : '' },
                    editable: true,
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: {
                        rowDataKey: "user_id",
                        mode: "edit",
                        multiple: false,
                        items: me.users,
                        keyProp: "id",
                        labelProp: "user_full_name"
                    }
                }, {
                    headerName: 'File Path',
                    field: 'ftp_path',
                    flex: 1,
                    minWidth: 200,
                    editable: true
                }, {
                    headerName: 'File Pattern',
                    field: 'file_pattern',
                    minWidth: 100,
                    editable: true
                }, {
                    headerName: 'Override Path',
                    field: 'override_ftp_dir',
                    flex: 1,
                    minWidth: 200,
                    editable: true
                }, {
                    headerName: 'Start Day',
                    field: 'start_day',
                    maxWidth: 100,
                    minWidth: 100,
                    editable: true
                }, {
                    headerName: 'End Day',
                    field: 'end_day',
                    maxWidth: 100,
                    minWidth: 100,
                    editable: true
                }, {
                    headerName: 'Last Received',
                    field: 'age',
                    minWidth: 150,
                    maxWidth: 150,
                    editable: false,
                    cellRenderer(v) {
                        if (!v.data.active) return ''
                        return v.value
                    },
                    cellStyle: function(params) {
                        let colors = {
                            '1-3 days ago': me.$textColors.success,
                            '4-7 days ago': me.$textColors.warning,
                            '8-14 days ago': me.$textColors.danger,
                            '15+ days ago': me.$textColors.error,
                        }
                        return {color: colors[params.data.age] || '#000'}
                    },
                },
                    this.$grid.toggleColumn('Diff Previous', 'diff_prev_file', null, () => { }),
                    this.$grid.toggleColumn('One Time Full File', 'onetime_full_file_flag', null, () => { }),
                    this.$grid.toggleColumn('Active', 'active', null, () => { }),
                {
                    headerName: 'Created',
                    field: 'created',
                    minWidth: 150,
                    maxWidth: 150,
                    editable: false
                }] : []
            }
        },
        mounted() {
            this.loadData()
        },
        methods: {
            doDelete() {
                this.$confirmDelete(this.selections, () => {
                    this.$refs.crud.destroy(this.selections).then(() => {
                        this.$refs.crud.read()
                    })
                })
            },
            doCopy() {
                let arr = []
                this.selections.forEach((rec, i) => {
                    let r = {...rec}
                    r.id   = null
                    arr.push(r)
                })
                this.$refs.crud.create(arr).then(() => {
                    this.$refs.crud.read()
                })
            },
            addHeaders() {
                this.headersDialog.record = {}
                this.headersDialog.show = true
            },
            selectRow(row) {
                this.selections = this.$refs.grid.gridApi.getSelectedRows()
            },
            doCreate() {
                this.dialog.record = [{
                    active: true
                }]
                this.dialog.show   = true
            },
            doUpdate(meta) {
                let dirty = this.$isDirty(this.originalItems, this.items)
                if (dirty) {
                    this.$refs.crud.update([meta.data]).then(() => {
                        // update original items
                        this.originalItems = this.$_.cloneDeep(this.items)
                        // refresh grid
                        this.$refs.grid.gridApi.refreshCells({ force: true })
                    })
                }
            },
            finish() {
                this.dialog.show = false
                this.loadData()
            },
            loadData() {
                this.ready = false
                this.originalItems = []
                // this.$modelGet('user').then(users => {
                this.$axios.get('users.php?action=get').then(data => {
                    this.users = this.$ecResponse(data)

                    this.$modelGet('uploadType', { all: 1 }).then(uploadTypes => {
                        this.uploadTypes = uploadTypes
                        let uploadTypeMap = {}
                        this.uploadTypes.forEach((tp, i) => uploadTypeMap[tp.id] = i)
                        this.uploadTypeMap = uploadTypeMap
                        this.$refs.crud.read().then(ii => {
                            const today = this.$dayjs()
                            ii.data.ftp.forEach(item => {
                                if (!item.last_file_received) {
                                    item.age = null
                                    item.last_file_received_days = null
                                    return
                                }

                                const days = today.diff(this.$dayjs(item.last_file_received), 'd')
                                if (days <= 3) {
                                    item.age = '1-3 days ago'
                                } else if (days <= 7) {
                                    item.age = '4-7 days ago'
                                } else if (days <= 14) {
                                    item.age = '8-14 days ago'
                                } else {
                                    item.age = '15+ days ago'
                                }
                                item.last_file_received_days = days
                            })
                            if (this.$props.age) {
                                let m = this.$props.age.match(/^([0-9]+)([+]|-([0-9]+))$/)
                                if (m) {
                                    m[1] = parseInt(m[1], 10)
                                    if (m[1] == 1) m[1] = 0 // let 1-3 include 0 days
                                    this.items = ii.data.ftp = ii.data.ftp.filter(item => item.last_file_received_days >= m[1] && (m[2] == '+' || item.last_file_received_days <= parseInt(m[3], 10)))
                                } else if (this.$props.age === 'Never') {
                                    this.items = ii.data.ftp = ii.data.ftp.filter(item => item.last_file_received_days === null)
                                }
                            }
                            this.originalItems = this.$_.cloneDeep(ii.data.ftp)
                            if (this.uploadTypes.length) {
                                this.ready = true
                            } else {
                                console.warn('Import identifier types did not load.  Unable to render grid')
                            }

                        })
                    })
                })



                // this.$modelGet('importType').then(resp => {
                //     this.items = resp
                // })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
