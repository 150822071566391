<template>
    <div>
        <v-container px-2 py-2 v-if="rowData.length > 0">
            <v-layout row align-center class="window-row">
                <v-flex :class="{ xs5: !same, xs10: same }" pr-2>
                    <fe-label :content="same ? 'Window Name' : 'Starting Window'"/>
                </v-flex>

                <v-flex xs5 pr-2 v-if="!same">
                    <fe-label content="Ending Window"/>
                </v-flex>

                <v-flex xs2 pl-1/>
            </v-layout>

            <v-layout row align-center class="window-row" v-for="rec in rowData" :key="`window-${rec.id}`">
                <v-flex :class="{ xs5: !same, xs10: same}" pr-2>
                    {{ rec.startName }}
                </v-flex>

                <v-flex xs5 pr-2 v-if="!same">
                    {{ rec.endName }}
                </v-flex>

                <v-flex xs2 pl-1 :style="{ paddingTop: '0px', textAlign: 'right' }">
                    <v-btn text icon color="error" @click="removeItem(rec)">
                        <v-icon size="18">delete_outline</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-container>

        <v-container px-2 py-2>
            <v-layout row>
                <v-flex :class="{ xs5: !same, xs10: same}" pr-2 pt-2>
                    <v-select
                        v-model="startId"
                        :items="dataPointNames"
                        :label="same ? 'Window Name' : 'Starting Window'"
                        :errorMessages="errors"
                        item-value="id"
                        item-text="name"
                        flat solo dense
                    />
                </v-flex>

                <v-flex xs5 pr-2 pt-2 v-if="!same">
                    <v-select
                        v-model="endId"
                        :items="dataPointNames"
                        item-value="id"
                        item-text="name"
                        label="Ending Window"
                        flat solo dense
                    />
                </v-flex>

                <v-flex xs2 pl-1 :style="{ paddingTop: '3px' }">
                    <fe-btn :disabled="!hasChanges" @click="addItem()">
                        Apply
                    </fe-btn>
                </v-flex>
            </v-layout>
        </v-container>

        <fe-dialog
            v-if="errorMessage"
            acceptButtonText="Okay"
            title="Invalid Selection"
            :body="errorMessage"
            @dismiss="errorMessage = null"
            @accept="errorMessage = null"
        />
    </div>
</template>

<script>
export default {
    name: 'AssessmentWindows',

    props: {
        windows: Array,
        errors: Array,
        dataPointNames: Array,
        same: Boolean
    },

    data() {
        return {
            errorMessage: null,
            gridApi: null,
            startId: null,
            endId: null
        }
    },

    computed: {
        hasChanges() {
            return this.startId || this.endId
        },

        headers() {
            return [
                {text: this.same ? 'Window Name' : 'Starting Window'},
                {text: 'Ending Window'},
                {text: '', align: 'right'}
            ]
        },

        rowData() {
            return this.windows.map(x => {
                return {
                    id: x.id,
                    startName: this.getDataPointNameFromId(x.start_data_point_name_id),
                    endName: this.getDataPointNameFromId(x.end_data_point_name_id)
                }
            })
        }
    },

    methods: {
        getDataPointNameFromId(id) {
            let match = this.dataPointNames.find(x => x.id === id)
            return (match) ? match.name : '...'
        },

        clearSelections() {
            this.endId = null
            this.startId = null
        },

        addItem() {
            if (this.hasChanges) {
                if (this.same && this.startId) {
                    this.endId = this.startId
                }
                if (!this.same && (!this.startId || !this.endId)) {
                    this.errorMessage = "A Starting Window and an Ending Window must be selected for your current metric settings."
                } else if (!this.same && this.startId === this.endId) {
                    this.errorMessage = "Starting Window and Ending Window must be different for your current metric settings."
                } else {
                    this.$emit('addWindow', {
                        start_data_point_name_id: this.startId,
                        end_data_point_name_id: this.endId,
                        callback: () => {
                            this.clearSelections()
                        }
                    })
                }
            }
        },

        removeItem(itm) {
            this.$emit('removeWindow', itm)
        }
    }
}
</script>

<style lang="scss" scoped>
.window-row {
    border-bottom: solid 1px #eee;
}
</style>
