<template>
    <div class="fill-height" ref="chartContainer">
        <div class="toolbar d-flex">
            <fe-filter-btn
                title="School Years"
                :items="schoolYears"
                v-model="rec.school_year_id"
                itemText="name"
                itemValue="id"
                ref="schoolYearFilter"
                :closeOnSelect="false"
            />

            <fe-filter-btn
                title="Schools"
                :items="schools"
                v-model="rec.school_id"
                itemText="name"
                itemValue="id"
                ref="schoolFilter"
                :closeOnSelect="false"
            />

            <fe-filter-btn
                title="Grades"
                :items="grades"
                v-model="rec.grade_id"
                itemText="name"
                itemValue="id"
                ref="gradeFilter"
                :closeOnSelect="false"
            />

            <fe-filter-btn
                title="Windows"
                :items="windows"
                v-model="rec.data_point_name_id"
                itemText="displayName"
                itemValue="id"
                ref="windowFilter"
                :closeOnSelect="false"
            />

            <fe-filter-btn
                title="Details"
                :items="details"
                v-model="rec.score_detail_type_id"
                itemText="description"
                itemValue="id"
                ref="detailFilter"
                :closeOnSelect="false"
            />

            <fe-btn @click="doSearch" :disabled="rec.score_detail_type_id.included.length===0?true:false">Search</fe-btn>
        </div>

        <div class="text-right pr-2">
            <toggle-button :items="toggles" v-model="activeToggle"/>
        </div>


        <highcharts v-if="showChart && activeToggle.id==='chart'" ref='chartRef' :options="chartOptions" />

        <div v-if="activeToggle.id==='list'" class="d-flex flex-fill flex-column pa-2">
            <fe-grid
                style="height: 85%"
                ref="grid"
                :columnDefs="columns"
                :rowData="gridItems"
                showScrollbars
                :showToolbar="true"
                :showAddRowBtn="false"
                displayDensity="small"
                @rowDataChanged="expandFirst"
                groupUseEntireRow
            />
        </div>
    </div>
</template>

<script>
    import ToggleButton from '@/components/common/button/ToggleButton'
    import windowOptionsMixin from '@/mixins/windowOptions'

    export default {
        name: 'ScoreDetailAnalytics',
        mixins: [ windowOptionsMixin ],
        components: {
            ToggleButton,
        },
        props: {
            params: {}
        },
        data() {
            return {
                toggles: [{
                    id: 'chart',
                    name: 'Charts',
                    icon: 'fas fa-chart-bar'
                }, {
                    id: 'list',
                    name: 'Data List',
                    icon: 'fas fa-bars'
                }],
                activeToggle: {},
                localParams: {},
                schoolYears: [],
                schools: [],
                windows: [],
                grades: [],
                details: [],
                gridItems: [],
                columns: [{ 
                   headerName: "Detail",
                   field: "score_detail_type_description",
                   editable: false,
                   flex: 1,
                   rowGroup: true
                }, { 
                   headerName: "School Year",
                   field: "school_year_name",
                   editable: false,
                   flex: 1
                }, { 
                   headerName: "Window",
                   field: "data_point_name",
                   editable: false,
                   flex: 1
                }, { 
                   headerName: "Value",
                   field: "detail_value_avg",
                   editable: false,
                   flex: 1,
                   cellStyle: { textAlign: 'right' }
                }],
                rec: {
                    score_detail_type_id: {
                        included: [],
                        excluded: []
                    }
                },
                series: [],
                categories: [],
                chartData: [],
                chartOptions: {},
                showChart: false
            }
        },
        mounted() {
            this.loadStores()
            this.activeToggle = this.toggles[0]
            this.localParams = {...this.params}
        },
        methods: {
            expandFirst(params) {
                let expanded = false
                params.api.forEachNode((node) => {
                    // if (!expanded) {
                        node.setExpanded(true)
                        // expanded = true
                    // }
                })
            },
            loadStores() {
                this.$modelGet('schoolYear', { active: 1 }).then(s => {
                    this.schoolYears = s
                    let year = s.filter(sc => sc.id === parseInt(this.params.school_year_id))
                    this.$refs.schoolYearFilter.selectItem(year[0], true)
                })

                this.$modelGet('school', { school_year_id: this.params.school_year_id }).then(s => {
                    this.schools = s
                    let school = s.filter(sc => sc.id === parseInt(this.params.school_id))
                    this.$refs.schoolFilter.selectItem(school[0], true)
                })

                this.$modelGet('grade', { school_year_id: this.params.school_year_id }).then(s => {
                    this.grades = s
                    let grade = s.filter(sc => sc.id === parseInt(this.params.grade_id))
                    this.$refs.gradeFilter.selectItem(grade[0], true)
                })

                this.$modelGet('dataPointName', { data_point_type_id: this.params.data_point_type_id, active: 1})
                    .then(r => {
                        this.windows = r
                        this.windows.forEach(w => w.displayName = w.alias ? w.alias : w.name)
                    })

                this.$modelGet('scoreDetailType', { data_point_type_id: this.params.data_point_type_id, active: 1})
                    .then(r => {
                        this.details = r
                    })
            },
            doSearch() {
                let query = {...this.params}
                delete query.data_point_name_id

                Object.keys(this.rec).forEach(r => {
                    query[r] = this.rec[r].included.map(i => i.id)
                })

                this.showChart=false
                this.$setLoading(true)
                this.$axios.get('scoreDetails.php?action=get&property=average&'+this.$objectToParams(query))
                    .then(r => {
                        this.$setLoading(false)
                        this.chartData = r.data.details
                        this.gridItems = r.data.details
                        this.buildChart()
                    })
            },
            buildChart() {
                this.categories = []
                this.series = []

                this.chartData.forEach(d => {
                    let serie = this.series.find(s=>s.name===d.score_detail_type_code)

                    if (!serie) {
                        serie = {
                            name: d.score_detail_type_code,
                            data: []
                        }
                        this.series.push(serie)
                    }

                    serie.data.push({
                        name: d.window,
                        y: parseFloat(d.detail_value_avg)
                    })
                })

                let height = this.$refs.chartContainer.clientHeight-100

                this.chartOptions = {
                    chart: {
                        type: 'line',
                        height: height
                    },
                    credits: {
                        enabled: false
                    },
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    },
                    title: {
                        text: '',
                    },
                    xAxis: {
                        type: 'category'
                    },
                    yAxis: {
                        endOnTick: false,
                        // min: 0,
                        title: {
                            text: 'Score'
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold'
                            }
                        }
                    },
                    legend: {
                        align: 'right',
                        verticalAlign: 'bottom',
                        shadow: false
                    },
                    tooltip: {
                        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                        shared: true
                    },
                    plotOptions: {
                        column: {
                            maxPointWidth: 50,
                            stacking: 'percent',
                            dataLabels: {
                                enabled: false
                            }
                        }
                    },
                    series: this.series
                }
                this.showChart=true
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>