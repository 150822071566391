<template>
    <fe-dialog
        :title="title || 'Add Measure'"
        width="500px"
        dismissButtonText="Cancel"
        :acceptButtonText="view === 'students' ? 'Next' : 'Add'"
        :acceptButtonDisabled="saveDisabled"
        persistent
        disableAutoclose
        @dismiss="$emit('dismiss')"
        @accept="accept"
    >
        <v-card flat>
            <selection-list
                v-show="view === 'students'"
                v-model="selectedStudents"
                identifier="student_id"
                :items="students"
                style="height: 300px"
                multiple
            >
                <template #row="{item}">
                    {{ item.student_full_name }}
                </template>
            </selection-list>

            <academic-monitor-schedule
                v-if="view === 'monitors'"
                v-model="localMonitors"
                :students="selectedStudents"
                :fullModel="fullModel"
                :forceMonitoringTypeId="forceMonitoringTypeId"
                operation="add"
                standalone
            />
        </v-card>
    </fe-dialog>
</template>

<script>
import SelectionList from '@/components/common/SelectionList'
import AcademicMonitorSchedule from '@/components/modules/intervention/creation/AcademicMonitorSchedule'
import {mapState} from 'vuex'

export default {
    name: 'AddAcademicMonitor',

    components: {
        SelectionList,
        AcademicMonitorSchedule
    },

    props: {
        title: {
            type: String,
            required: false,
        },
        /** forceMonitoringTypeId will force the monitoring type to be selected for new measures */
        forceMonitoringTypeId: {
            type: Number,
            required: false,
        },
        students: {
            type: Array,
            required: true
        },
        monitors: {
            type: Array,
            default: () => []
        },
        startDate: {},
        endDate: {}
    },

    data() {
        return {
            selectedStudents: [],
            view: 'students',
            localMonitors: [],
            fullModel: {
                schedule: {}
            }
        }
    },

    computed: {
        ...mapState('global', ['currentYear']),

        isValid() {
            if (this.localMonitors.length === 0) return false
            let rec = this.localMonitors[this.localMonitors.length - 1]
            let fields = ['start_date', 'end_date', 'sub_category_id', 'monitor_frequency_cnt', 'monitor_user_id', 'monitor_week_days']
            let valid = true
            fields.forEach(f => {
                if (!rec[f]) valid = false
            })
            return valid
        },

        saveDisabled() {
            if (this.view === 'students') {
                return this.selectedStudents.length === 0
            } else {
                return !this.isValid
            }
        }
    },

    created() {
        this.fullModel.schedule = {
            start_date: this.startDate ? this.startDate : this.$dayjs().format('MM/DD/YYYY'),
            end_date: this.startDate ? this.startDate : this.$dayjs(this.currentYear.year_end, 'YYYY-MM-DD').format('MM/DD/YYYY'),
        }

        if (this.students.length === 1) {
            this.selectedStudents[0] = this.students[0]
            this.view = 'monitors'
        }

        if (this.monitors.length) {
            this.localMonitors = this.formatMeasures(this.monitors)
        }
    },

    methods: {
        accept() {
            if (this.view === 'students') {
                this.view = 'monitors'
            } else {
                this.addMonitor()
            }
        },

        addMonitor() {
            const monitors = []
            this.localMonitors.forEach(m => {
                if (m.hasOwnProperty('schedule_id')) {
                    return
                }
                monitors.push({
                    student_monitor_plan_id: "",
                    monitor_category_id: m.monitor_category_id,
                    schedule_start_date: this.$dayjs(m.start_date).format('YYYY-MM-DD'),
                    schedule_end_date: this.$dayjs(m.end_date).format('YYYY-MM-DD'),
                    default_start_time: m.start_time ? m.start_time + ':00' : null,
                    default_end_time: m.end_time ? m.end_time + ':00' : null,
                    monitor_user_id: m.monitor_user_id,
                    sub_category_id: m.sub_category_id.id,
                    primary_flag: m.primary_flag,
                    schedule_frequency_cd: 'WEEK',
                    schedule_frequency_cnt: m.monitor_frequency_cnt,
                    schedule_week_days: m.monitor_week_days,
                    merge_probes: m.mergedMonitors ? m.mergedMonitors.filter(r => r.merge)
                        .map(r => {
                            return {student_monitor_plan_id: r.student_monitor_plan_id}
                        }) : [],
                    goals: m.students.map(s => {
                        return {
                            student_id: s.student_id,
                            value: s.value,
                            assessment_grade_id: s.assessment_grade_id ? s.assessment_grade_id : s.grade_level,
                            assessment_grade_desc: s.assessment_grade_desc,
                            intervention_id: s.intervention_id
                        }
                    })
                })
            })
            if (!monitors.length) return
            this.$axios.post('studentMonitors.php?action=create&property=student_monitors', {monitors})
                .then(r => {
                    if (this.$ecResponse(r) === false) {
                        return
                    }
                    this.$emit('save')
                })
        },

        formatMeasures(measures) {
            return measures.map(m => {
                return {
                    student_monitor_plan_id: m.student_monitor_plan_id,
                    schedule_id: m.schedule_id,
                    start_date: m.schedule_start_date,
                    end_date: m.schedule_end_date,
                    start_time: m.schedule_start_time,
                    end_time: m.schedule_end_time,
                    monitor_frequency_cnt: m.schedule_frequency_cnt,
                    monitor_frequency_cd: m.schedule_frequency_cd,
                    monitor_week_days: m.schedule_week_days,
                    monitor_user_id: m.monitor_user_id,
                    sub_category_id: {
                        id: m.sub_category_id
                    },
                    primary_flag: m.primary_flag,
                    intervention_id: m.intervention_id,
                    students: [{
                        student_id: m.student_id,
                        student_full_name: m.student_full_name,
                        value: m.goal,
                        assessment_grade_id: m.assessment_grade_id
                    }]
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
