<template>
    <fe-dialog
        title="Embargoed Data Notification"
        persistent
        :dismissable="false"
        acceptButtonText="I Accept"
        :acceptButtonDisabled="isBusy"
        @accept="onAccept"
        width="80%"
    >
        <p>
            Data from the following measures are currently under embargo.  Your permission
            allows you to see the data during this embargo under the following conditions:
        </p>

        <p>
            By viewing data during the embargo, you agree not to copy, disclose, discuss, or otherwise
            communicate, convey, imply, or provide to any non-authorized person or entity any
            information related to the embargoed scores including state, district, school,
            or student level results, until the embargo has been lifted.  All materials
            are considered secure until the embargo is lifted.
        </p>

        <div class="d-flex embargo-header-row">
            <div class="col-3">School Year</div>
            <div class="col-3">Assessment</div>
            <div class="col-3">Window</div>
            <div class="col-3">Embargo Lift Date</div>
        </div>

        <div style="max-height: 300px; overflow: auto;">
            <div
                v-for="itm in embargoes"
                :key="itm.id"
                class="d-flex embargo-row"
            >
                <div class="col-3">{{ itm.school_year_name }}</div>
                <div class="col-3">{{ itm.sub_category_name }}</div>
                <div class="col-3">{{ itm.data_point_name_name }}</div>
                <div class="col-3">{{ formatDatetime(itm.embargo_lift_datetime) }}</div>
            </div>
        </div>

        <fe-crud
            ref="crud"
            :config="$models.acceptedDataEmbargoNotice"
        />
    </fe-dialog>
</template>

<script>
    export default {
        name: 'EmbargoedDataAgreement',
        components: {
        },
        props: {
            embargoes: {
                type: Array,
                required: true
            },
        },
        data: () => ({
            isBusy: false,
        }),
        methods: {
            formatDatetime(v) {
                return this.$dayjs(v).format('MMMM Do, YYYY HH:mma')
            },
            async onAccept() {
                this.isBusy = true

                //this.$refs.crud.create([{ data_point_embargo_id: this.embargoes.map(itm => itm.id) }]).then(() => {
                let payload = this.embargoes.map(itm => ({
                    data_point_embargo_id: itm.id
                }))
                this.$refs.crud.create(payload).then(() => {
                    this.$emit('accept')
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
.embargo-header-row > div {
    font-weight: bold;
}

.embargo-row > div {
    border: 1px solid #ddd;
}
</style>
