<template>
    <transition name="footer">
        <div class="footer__menu footer__menu--sticky" v-if="hasSelection">
            <fe-dialog
                v-if="confirm"
                acceptButtonText="Delete"
                :title="`Delete ${titleName}`"
                :body="`Are you sure you want to <strong>delete ${selectedIndexes.length} ${selectionName}</strong>?`"
                usage="error"
                @accept="deleteSelection"
                @dismiss="confirm = false"
            />

            <v-container px-0 py-2 mx-5>
                <v-layout row wrap py-1>
                    <v-flex xs12>
                        <div class="selectionIcon" @click="toggleSelection">
                            <v-icon color="white" size="12">{{ allSelected ? 'done' : 'remove' }}</v-icon>
                        </div>

                        <span class="text-muted">
                            {{ selectedIndexes.length }} Item{{ (selectedIndexes.length > 1) ? 's' : '' }} Selected
                        </span>

                        <fe-btn usage="error" @click="confirm = true">Delete</fe-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'DeleteFooter',

    props: {
        modelName: String,
        modelNameLower: String,
        modelNameTitle: String,
        items: Array,
        selectedIndexes: Array,
        grid: Object
    },

    data() {
        return {
            confirm: false
        }
    },

    computed: {
        hasSelection() {
            return this.selectedIndexes.length > 0
        },

        allSelected() {
            return this.selectedIndexes.length >= this.items.length
        },

        lowerName() {
            return this.modelNameLower || this.modelName.toLowerCase()
        },

        titleName() {
            return this.modelNameTitle || this.capitalize(this.pluralName)
        },

        pluralName() {
            return this.lowerName.toLowerCase().endsWith('s')
                ? `${this.lowerName}es`
                : `${this.lowerName}s`
        },

        selectionName() {
            return (this.selectedIndexes.length === 1)
                ? this.lowerName
                : this.pluralName
        }
    },

    methods: {
        toggleSelection() {
            if (this.allSelected) {
                this.$emit('deselectAll')
            } else {
                this.$emit('selectAll')
            }
        },

        deleteSelection() {
            this.$emit('deleteSelection')
            this.confirm = false
        },

        capitalize(str) {
            return str.toLowerCase().split(' ').map(function (word) {
                return (word.charAt(0).toUpperCase() + word.slice(1));
            }).join(' ');
        }
    }
}
</script>
