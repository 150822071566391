<template>
    <fe-dialog
        title="New Grade"
        persistent
        acceptButtonText="Save"
        dismissButtonText="Cancel"
        width="350px"
        :forceClose="false"
        dismissable
        disableAutoclose
        @accept="saveRec"
        @dismiss="dismissDialog"
    >
        <v-form ref="form">
            <div class="d-flex">
                <fe-label>School Year</fe-label>
                <fe-remote-combo
                    class="student-controls"
                    :items="this.$props.schoolYearList"
                    v-model="newRec.school_year_id"
                    itemText="name"
                    itemValue="id"
                    :rules="$fieldValidators('select', 'School year', { required: true })"
                    byId>
                </fe-remote-combo>
            </div>
            <div class="d-flex">
                <fe-label>Grade</fe-label>
                <fe-remote-combo
                    class="student-controls"
                    :items="this.grades"
                    v-model="newRec.grade_id"
                    itemText="name"
                    itemValue="id"
                    :rules="$fieldValidators('select', 'Grade', { required: true })"
                    byId>
                </fe-remote-combo>
            </div>
        </v-form>
    </fe-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { mapLocalState } from '@/util/vuexHelper'

export default {
    name: 'AddGrade',

    props: {
        crud: null,
        studentInfo: null,
        schoolYearList: null
    },

    data() {
        return {
            newRec: {
                id: undefined,
                student_id: undefined,
                school_year_id: undefined,
                grade_id: undefined
            },
            gradeDesc: undefined
        }
    },

    inject: ['localStore'],

    computed: {
        ...mapLocalState(['grades'])
    },

    watch: {
        'newRec.grade_id'(v) {
            if (v) {
                let selectedGrade = this.grades.filter(data => data.id === v )
                this.$data.gradeDesc = selectedGrade[0].name
            }
        }
    },

    methods: {
        saveRec() {
            this.newRec.student_id = this.$props.studentInfo.student_id
            this.newRec.id = "StudentGrade-" + this.gradeDesc

            if(this.$refs.form.validate()) {
                this.crud.create(this.newRec).then( r => {
                    this.resetEntry()
                    this.dismissDialog()
                })
            }
        },

        resetEntry() {
            this.newRec = {
                id: undefined,
                student_id: undefined,
                school_year_id: undefined,
                grade_id: undefined
            }
            this.gradeDesc = undefined
        },

        dismissDialog() {
            if (this.$_.isEmpty(Object.values(this.newRec).filter(x => !!x))) {
                this.$emit('close')
            } else {
                this.$confirmCancel(() => {
                    this.resetEntry()
                    this.$emit('close')
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep div.fe-label {
        width: 76px;
    }

    ::v-deep {
        div.add-school-date-picker, div.student-controls {
            width: 242px;
        }
    }
</style>
