<template>
    <v-layout row wrap style="overflow: scroll;">
        <v-flex xs12>
            <fe-crud
                ref="crud"
                :readParams="{ manage: 1 }"
                :autoload="false"
                :config="crudConfig"
                @read="v => items = v"
            />

            <fe-crud
                ref="schoolCrud"
                :autoload="false"
                :config="schoolCrudConfig"
                @read="v => schooltems = v"
            />

            <create-code @save="loadData" v-model="addDialog.show" :incidentBehaviorTypeId="selectedIncidentType.id"/>
            <fe-dialog
                title="Incident Type"
                acceptButtonText="Save"
                :acceptButtonDisabled="!editDialog.record.name || editDialog.record.name.length > 25"
                @accept="saveRecord(editDialog.record)"
                @close="editDialog.show = false"
                v-if="editDialog.show"
                persistent
            >
                <v-layout column>
                    <v-flex>
                        <v-text-field flat solo dense
                            placeholder="Enter incident type name"
                            label="Incident Type"
                            v-model="editDialog.record.name"
                            :counter="25"
                            :rules="$fieldValidators('text', null, { required: true, limit: 25})"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
            </fe-dialog>

            <div class="d-flex mb-3">
                <div class="pt-2">
                    <fe-title-select
                        fontColor="black"
                        fontSize="18px"
                        itemText="name"
                        itemValue="id"
                        placeholder="Select a Type"
                        :items="typeOptions"
                        v-model="selectedType"
                    ></fe-title-select>
                </div>


                <div class="ml-auto">
                    <menu-btn useIcon="fas fa-ellipsis-v">
                        <v-list-item @click="editDialog.show = true; editDialog.record = { id: selectedIncidentType.id, name: selectedIncidentType.name }">
                            Rename
                        </v-list-item>

                        <v-list-item @click="deleteRecord(item)">
                            <div>Delete</div>
                        </v-list-item>
                    </menu-btn>
                </div>
            </div>


            <div class="d-flex behavior-code-container" @click="selectNavigation({ name: 'Codes', show: 'codes' })">
                <div class="behavior-code-title">
                    <v-icon v-if="codesCount === 0"
                        color="red"
                        class="mr-3"
                        style="font-size: 18px;"
                    >fas fa-exclamation-circle</v-icon>
                    Codes <span class="ml-4">{{ codesCount }}</span>
                </div>

                <div class="ml-auto  ">
                    <fe-btn v-if="codesCount === 0" @click="addDialog.show=true; $event.stopPropagation();">Add Codes</fe-btn>
                    <fe-icon-btn v-if="codesCount > 0" useIcon="fas fa-chevron-right"/>
                </div>
            </div>
        </v-flex>

        <v-flex xs12>
            <div class="section-title">General</div>
        </v-flex>

        <template v-for="tf in toggleFields.filter(r => !r.restrict)">
            <v-flex xs6 md4 lg3 :key="`incident-toggle-field-status-`+ tf.model+'-'+selectedIncidentType.id">
                <label-field :labelText="tf.label" labelAlign="left">

                    <crud-select
                        v-if="tf.model == 'userGroups'"
                        :itemsModel="$models.userGroup"
                        :crudModel="$models.incidentBehaviorTypeUserGroupRestriction"
                        :crudModelReadParams="{ incident_behavior_type_id: selectedIncidentType.id }"
                        :crudModelCreateParams="{ incident_behavior_type_id: selectedIncidentType.id }"
                        crudItemValue="user_group_id"
                        multiple
                        selectStyle="width: 90%"
                    />

                    <template v-else-if="tf.model != 'schools'">
                        <fe-tooltip v-if="tf.tooltip" :tooltip="tf.tooltip">
                            <component
                                :is="tf.component"
                                v-model="item[tf.model]"
                                @change="saveRecord(item, tf.model)"
                            >
                            </component>
                        </fe-tooltip>

                        <component v-else
                            :is="tf.component"
                            v-model="item[tf.model]"
                            @change="saveRecord(item, tf.model)"
                        >
                        </component>
                    </template>

                    <crud-select
                        v-else
                        :itemsModel="$models.school"
                        :itemsModelParams="{property: 'behavior'}"
                        :crudModel="$models.incidentBehaviorTypeSchool"
                        :crudModelReadParams="{ incident_behavior_type_id: selectedIncidentType.id }"
                        :crudModelCreateParams="{ incident_behavior_type_id: selectedIncidentType.id }"
                        crudItemValue="school_id"
                        itemText="name"
                        itemValue="id"
                        multiple
                        selectStyle="width: 90%"
                    />
                </label-field>
            </v-flex>
        </template>

        <v-flex xs12 :key="`custom-fields`+selectedIncidentType.id">
            <incident-type-fields :incidentBehaviorTypeId="selectedIncidentType.id"/>
        </v-flex>

        <v-flex xs12>
            <div class="section-title">Section Properties</div>
        </v-flex>

        <v-flex v-for="tf in statusFields" xs3 :key="`incident-field-status-`+ tf.model">
            <label-field :labelText="tf.label" labelAlign="top">
                <v-select
                    flat solo dense
                    class="mr-4"
                    itemText="name"
                    itemValue="id"
                    :items="statusOptions"
                    v-model="item[tf.model]"
                    @input="saveRecord(item)"
                />
            </label-field>
        </v-flex>
    </v-layout>
</template>

<script>
    import ActionList from '@/components/common/ActionList'
    import CrudSelect from '@/components/common/combo/CrudSelect'
    import IncidentBehavior from './IncidentBehavior'
    import IncidentFieldList from './IncidentFieldList'
    import IncidentTypeFields from './IncidentTypeFields'
    import LabelField from '@/components/common/LabelField'
    import EditList from '@/components/common/EditList'
    import CreateCode from './dialogs/CreateCode'
    import Vuex from 'vuex'
    import { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'
    import MenuBtn from '@/components/common/button/MenuBtn'

    export default {
        name: 'IncidentTypeCard',
        inject: ['localStore'],
        components: {
            IncidentBehavior,
            ActionList,
            IncidentFieldList,
            IncidentTypeFields,
            EditList,
            LabelField,
            CrudSelect,
            CreateCode,
            MenuBtn
        },
        props: {
            incidentBehaviorTypeId: {

            }
        },
        watch: {
            'schoolDialog.show'(v) {
                this.$refs.crud.read()
            },
            selectedType(v) {
                this.selectedIncidentType = v
                this.$addCrumb('Incidents', {
                    name: v.name,
                    show: 'type'
                }, 2)
                this.loadData()
            },
            // localIncidentBehaviorTypeId(v, o) {
            //     if (o) {
            //         this.selectedIncidentType = this.typeOptions.find(rec => { return rec.id === v})
            //         this.loadData()
            //     }
            // }
        },
        computed: {
            ...mapState('manage', ['breadcrumbs']),
            ...mapLocalState(['selectedIncidentType']),
            crudConfig() {
                return this.$models.incidentBehaviorType
            },
            item() {
                if (this.items.length > 0) {
                    // this.$data.schoolItems = this.items[0].schools
                    return this.items[0]
                }

                return {
                    incident_behaviors: [],
                    schools: [],
                    incident_fields: []
                }
            },
            schoolCrudConfig() {
                return this.$models.incidentBehaviorTypeSchool
            },
            codesCount() {
                return this.item.incident_behaviors ? this.item.incident_behaviors.length : 0
            }
        },
        created() {
            this.localIncidentBehaviorTypeId = this.$props.incidentBehaviorTypeId

            this.$modelGet('incidentBehaviorType', { active: 1, manage: 1 })
                .then(response => {
                    this.typeOptions = response
                    this.selectedType = this.typeOptions.find(rec => { return rec.id === this.$props.incidentBehaviorTypeId })
                })
        },
        mounted() {
            this.loadData()
        },
        data() {
            return {
                typeOptions: [],
                selectedType: null,
                localIncidentBehaviorTypeId: null,
                editDialog: {
                    show: false,
                    record: {
                        name: ''
                    }
                },
                addDialog: {
                    show: false
                },
                deleteDialog: {
                    show: false,
                    record: {
                        name: ''
                    }
                },
                schoolDialog: {
                    show: false,
                    record: {
                        name: ''
                    }
                },
                fieldsDialog: {
                    show: false,
                    record: {
                        name: ''
                    }
                },
                schools: [],
                selectedSchools: [],
                incidentBehaviorTypeSchools: [],
                statusOptions: [
                    { "id": 0, "name": "Hidden" },
                    { "id": 1, "name": "Optional" },
                    { "id": 2, "name": "Required" }
                ],
                statusFields: [{
                    label: 'Description',
                    model: 'show_description'
                }, {
                    label: 'Motivation',
                    model: 'show_motivation'
                }, {
                    label: 'Response',
                    model: 'show_response'
                }, {
                    label: 'Parent Contact',
                    model: 'show_parent_contact'
                }],
                toggleFields: [{
                    label: 'Restrict Create',
                    component: 'v-switch',
                    model: 'create_security_flag'
                }, {
                    label: 'Restrict Description',
                    component: 'v-switch',
                    model: 'restrict_description'
                }, {
                    label: 'Restrict Complete',
                    component: 'v-switch',
                    model: 'complete_security_flag'
                }, {
                    label: 'Include in Default Search',
                    component: 'v-switch',
                    model: 'default_search'
                }, {
                    label: 'Enable KPI',
                    component: 'v-switch',
                    model: 'kpi',
                    restrict: !this.$hasSecurity('MANAGE_ACHIEVEMENT_DASHBOARD')
                }, {
                    label: 'Send Notification',
                    component: 'v-switch',
                    model: 'notify_flag',
                    tooltip: 'Update school office emails in Manage District & Schools'
                }, {
                    label: 'Restrict to Schools',
                    component: 'crud-select',
                    model: 'schools',
                    attrs: {
                        itemText: 'name',
                        itemValue: 'id',
                        multiple: true
                    }
                }, {
                    label: 'Restrict to Groups',
                    component: 'crud-select',
                    model: 'userGroups',
                    attrs: {
                        itemText: 'name',
                        itemValue: 'id',
                        multiple: true
                    }
                }],
                items: [],
                menuOptions: [
                    'Rename', 'Delete'
                ],
                manageBehavior: {
                    show: false
                }
            }
        },
        methods: {
            menuButtonText(item) {
                if (!item) return

                if (this.manageBehavior.show) {
                    return 'Back'
                }
                return item.incident_behaviors.length + ' Codes'
            },
            go(to) {
                this.selectedIncidentType = this.item
                this.breadcrumbs.push(to)
            },
            loadData() {
                this.$refs.crud.read({
                    id: this.selectedIncidentType.id
                })
            },
            selectNavigation(sel) {
                if (sel.name.indexOf('Custom') > -1) {
                    this.fieldsDialog.show = true
                } else {
                    this.selectedIncidentType = this.item

                    this.$addCrumb('Incidents', {
                        name: 'Codes',
                        show: 'codes'
                    })
                }
            },
            menuClick(menuItem, rec) {
                if (menuItem == 'Rename') {
                    // this
                    this.editDialog.show = true
                    this.editDialog.record = {...rec}
                } else if (menuItem == 'Delete') {
                    this.deleteDialog.show = true
                    this.deleteDialog.record = {
                        id: rec.id,
                        name: rec.name
                    }
                }
            },
            saveRecord(rec) {
                // let item = this.item
                let cfg = this.crudConfig
                let crud = this.$refs.crud

                if (rec.id) {
                    crud.update([rec])
                        .then(r => {
                            // this.loadData()
                            this.$modelGet('incidentBehaviorType', { active: 1})
                                .then(response => {
                                    this.typeOptions = response
                                    this.selectedType = this.typeOptions.find(rec => { return rec.id === this.$props.incidentBehaviorTypeId })
                                })
                        })
                } else {
                    crud.create([rec])
                        .then(r => {
                            this.loadData()
                        })
                }
            },
            deleteRecord() {
                let me = this

                this.$messageBox({
                    title: 'Confirm Delete',
                    persistent: true,
                    message: 'Are you sure you want to delete ' + this.item.name + '?  This change is permanent!',
                    maxWidth: '500',
                    actions: [{
                        text: 'Cancel',
                    }, {
                        text: 'Ok',
                        usage: 'danger',
                        onClick: () => {
                            me.$refs.crud.destroy([this.item])
                                .then(() => {
                                    this.$removeCrumb("Incidents", 1)
                                })
                                .catch(error => {
                                    console.error('location', error)
                                })
                        }
                    }]
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.behavior-code-container {
    border: 2px solid #E0E1E6;
    padding: 8px;
    height: 64px;
    cursor: pointer;

    .behavior-code-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        padding-top: 10px;
        padding-left: 10px;
        color: #000000;
    }
}

.section-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-top: 50px;
    margin-bottom: 20px;
}
</style>
