<template>
    <div class="d-flex flex-fill flex-column no-scroll pa-2">
        <fe-tabs
            :tabs="tabs"
            v-model="activeTab"
            @tabClicked="selectedTab = $event"
            style="height: 68px;"
        />

        <incident-grid
            v-if="selectedTab.type === 'entered'"
            class="my-incident-grid"
            :rowData="enteredItems"
            :showAddRowBtn="false"
            :params="{}"
            title=""
            @update="loadMyIncidents"
        />

        <incident-grid
            v-if="selectedTab.type === 'observed'"
            class="my-incident-grid"
            :rowData="observedItems"
            :showAddRowBtn="false"
            :params="{}"
            title=""
            @update="loadMyIncidents"
        />
        <fe-crud ref="crud" :config="crudConfig"/>
    </div>
</template>

<script>
    import {mapState} from "vuex"
    import IFrameRenderer from '@/components/common/IFrameRenderer'
    import IncidentGrid from './IncidentGrid'

    export default {
        name: "MyIncidents",

        components: {
            IFrameRenderer,
            IncidentGrid
        },

        props: {
            observedRowData: {
                type: Array,
                default: []
            },
            enteredRowData: {
                type: Array,
                default: []
            },
            selected: {
                type: String,
                required: true,
                default: 'entered'
            }
        },

        data() {
            return {
                observedItems: [],
                enteredItems: [],
                activeTab: 0,
                selectedTab: {
                    name: 'Incidents I Entered',
                    path: '#',
                    type: 'entered'
                },
                tabs: [{
                    name: 'Incidents I Entered',
                    path: '#',
                    type: 'entered'
                }, {
                    name: 'Incidents I Observed',
                    path: '#',
                    type: 'observed'
                }]
            }
        },

        computed: {
            ...mapState('global', ['sessionUser', 'currentYear']),

            crudConfig() {
                return _.cloneDeep(this.$models.studentIncidentList)
            }
        },

        mounted() {
            if (this.observedRowData.length || this.enteredRowData.length) {
                this.observedItems = this.observedRowData
                this.enteredItems = this.enteredRowData
            } else {
                this.loadMyIncidents()
            }
            this.selectedTab = this.tabs.filter((x, i) => {
                let active = x.type === this.selected
                if (active) this.activeTab = i
                return active
            })[0]
        },

        methods: {
            loadMyIncidents() {
                let id = this.currentYear.id
                let observedParams = {
                    observer_user_id: this.sessionUser.user.id,
                    school_year_id: id
                }
                this.$refs.crud.read(observedParams)
                    .then(r => {
                        this.observedItems = r.data
                    })

                let enteredParams = {
                    entry_user_id: this.sessionUser.user.id,
                    school_year_id: id
                }
                this.$refs.crud.read(enteredParams)
                    .then(r => {
                        this.enteredItems = r.data
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .my-incident-grid {
        height: calc(100% - 95px);
    }
</style>
