let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    studentGradeHistory: {
        defaults: {
            endpoint: 'courseHistory.php?property=grade_history',
            rootProperty: 'grades'
        },
        ...standardCrud
    },
    courseHistory: {
        defaults: {
            endpoint: 'courseHistory.php',
            rootProperty: 'courses'
        },
        ...standardCrud
    },
    courseList: {
        defaults: {
            endpoint: 'courseHistory.php?property=course_id',
            rootProperty: 'courses'
        },
        ...standardCrud
    },
    /* Incident Models */

    schoolYear: {
        defaults: {
            endpoint: 'qry.php?property=school_years',
            rootProperty: 'years'
        },
        rootProperty: 'years',
        read: 'qry.php?property=school_years',
        update: {
            endpoint: 'crud.php?property=SchoolYear',
            rootProperty: 'school_year',
            params: {
                action: 'update'
            }
        }
    },
    schoolYearCRUD: {
        defaults: {
            endpoint: 'crud.php?property=SchoolYear',
            rootProperty: 'school_year'
        },
        ...standardCrud
    },
    school: {
        rules: [
            r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        ],
        defaults: {
            endpoint: 'schools.php',
            rootProperty: 'schools'
        },
        ...standardCrud
    },
    term: {
        defaults: {
            endpoint: 'courseTerms.php',
            rootProperty: 'terms'
        },
        ...standardCrud
    },
    helpDocs: {
        defaults: {
            endpoint: 'docs.php?active=1',
            rootProperty: 'documents'
        },
        ...standardCrud
    },
    homeLanguage: {
        defaults: {
            endpoint: 'crud.php?property=HomeLanguage',
            rootProperty: 'home_language'
        },
        ...standardCrud
    },
    disabilityType: {
        defaults: {
            endpoint: 'filters.php?action=get&filter=disability',
            rootProperty: 'disabilities'
        },
        ...standardCrud
    },
    ethnicityType: {
        defaults: {
            endpoint: 'filters.php?action=get&filter=ethnicity',
            rootProperty: 'ethnicities'
        },
        ...standardCrud
    },
    genderType: {
        defaults: {
            endpoint: 'filters.php?action=get&filter=gender',
            rootProperty: 'genders'
        },
        ...standardCrud
    },
    mealStatus: {
        defaults: {
            endpoint: 'mealStatus.php',
            rootProperty: 'meal_status'
        },
        ...standardCrud
    },
    northpass: {
        defaults: {
            endpoint: '/api/core/northpass/provision',
        },
        ...standardCrud
    },

    // display_name updated endpoints
    // leaving others in place while we work through the app
    // to change them all over
    mealStatusType: {
        defaults: {
            endpoint: 'crud.php?property=MealStatus',
            rootProperty: 'meal_status'
        },
        ...standardCrud
    },
    studentDisabilityType: {
        defaults: {
            endpoint: 'crud.php?property=StudentDisabilityType',
            rootProperty: 'student_disability_type'
        },
        ...standardCrud
    },
    ethnicity: {
        defaults: {
            endpoint: 'crud.php?property=Ethnicity',
            rootProperty: 'ethnicity'
        },
        ...standardCrud
    },
    gender: {
        defaults: {
            endpoint: 'crud.php?property=Gender',
            rootProperty: 'gender'
        },
        ...standardCrud
    },
}
