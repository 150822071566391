import Vue from 'vue'

export const actionPlan = {
    namespaced: true,
    state: {
        goal: {},
        objective: {},
        actionPlan: [],
        activeDashboard: {},
        tasks: [],
        objDragging: false,
        goalDragging: false,
        objectiveStates: [],
        goalStates: [],
    },

    getters: {
        goal: state => {
            return state.goal
        },
        objective: state => {
            return state.objective
        },
        actionPlan: state => {
            return state.actionPlan
        },
        activeDashboard: state => {
            return state.activeDashboard
        },
        tasks: state => {
            return state.tasks
        },
        objDragging: state => {
            return state.objDragging
        },
        goalDragging: state => {
            return state.goalDragging
        },
        objectiveStates: state => {
            return state.objectiveStates
        },
        goalStates: state => {
            return state.goalStates
        }
    },

    mutations: {
        set(state, payload) {
            state[payload.key] = payload.value
        },
        setObjectiveStates(state, payload) {
            let found = state.objectiveStates.find(x => x.key == payload.key)
            if(found) {
                found.value = payload.value
            } else {
                state.objectiveStates.push(payload)
            }
        },
        setGoalStates(state, payload) {
            let found = state.goalStates.find(x => x.key == payload.key)
            if(found) {
                found.value = payload.value
            } else {
                state.goalStates.push(payload)
            }
        },
    },

    actions: {

    }
}
