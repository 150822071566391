import Vuex from 'vuex'
import { mapLocalState } from '@/util/vuexHelper'

export default {
    provide() {
        this.localStore = new Vuex.Store({
            state: {
                activeLayout: null, // delete all these
                layouts: [],        // delete all these
                dependencies: [],
                controls: {},
                fieldConfigs: {},
                dependentSections: {},
                dependentFields: {},
                formLogic: [],

                schoolYears: [],
                grades: [],
                schools: [],
                interventionLevels: [],
                interventionTypes: [],
                disabilityTypes: [],
                ellLevels: [],
                users: [],
                students: [],
                homeLanguages: [],
                whenActionText: {
                    13: 'Disable',
                    14: 'Hide',
                    15: 'Enable',
                    16: 'Show',
                },
                reloadFn: null,
                baseComponent: null,
                selectedComponent: null,
                isLoading: false,
            }
        })

        return { localStore: this.localStore }
    },

    data() {
        return {
            hideFields: {},
            hideSections: {},
            disableFields: {},
            disableSections: {},
        }
    },

    created() {
        this.loadCore()
    },

    mounted() {
        this.reloadFn = this.loadForm
        this.baseComponent = this
    },

    // watch: {
    //     controls() {
    //         this.setDefaultFormState()
    //         this.updateDependencies(Object.values(this.allFields))
    //     }
    // },

    computed: {
        ...mapLocalState([
            'activeLayout',
            'layouts',
            'dependencies',
            'controls',
            'fieldConfigs',
            'dependentSections',
            'dependentFields',
            'formLogic',
            'reloadFn',
            'baseComponent',
            'selectedComponent',
            'isLoading',

            'schoolYears',
            'grades',
            'schools',
            'interventionLevels',
            'interventionTypes',
            'disabilityTypes',
            'ellLevels',
            'users',
            'students',
            'homeLanguages',
        ]),
        isSmartFormInstance() {
            return this.$options.name == 'SmartForm'
        },
        allFields() {
            let f = {}
            this.sections.forEach(section => {
                let fields = this.isSmartFormInstance ? section.form_instance_responses : section.form_fields
                fields.forEach(field => {
                    if (field.xtype === 'studentcombo' && !this.firstStudentField) this.firstStudentField = field
                    f[this.formFieldId(field)] = field
                })
            })
            return f
        },
    },

    methods: {
        formSectionId(rec) {
            return this.isSmartFormInstance ? rec.form_section_id : rec.id
        },
        formFieldId(rec) {
            return this.isSmartFormInstance ? rec.form_field_id : rec.id
        },
        sectionDisabled(section) {
            let id = this.isSmartFormInstance ? section.form_section_id : section.id
            return this.disableSections[id] ? true : false
        },
        getSectionWidth(section) {
            let w = parseInt(section.width*100)
            if (w === 99) w=100
            return w + '%'
        },
        setDefaultFormState() {
            this.sections.forEach(section => {
                this.hideSections[this.formSectionId(section)] = false
                this.disableSections[this.formSectionId(section)] = false

                let fields = this.isSmartFormInstance ? section.form_instance_responses : section.form_fields
                fields.forEach(field => {
                    this.hideFields[this.formFieldId(field)] = false
                    this.disableFields[this.formFieldId(field)] = false
                })
            })
        },
        /*
        INSERT INTO `form_logic`
        (`id`, `type`,      `name`,     `value`,                 `context`) VALUES
        (1,  'STATEMENT',   'If'        ,'if'                    ,NULL        ),-- statement
        (2,  'STATEMENT',   'On'        ,'on'                    ,NULL        ),
        (3,  'CONDITIONAL', 'Equals'    ,'=='                    ,NULL        ),-- conditional
        (4,  'CONDITIONAL', 'Not Equals','!='                    ,NULL        ),
        (5,  'CONSTANT',    'True'      ,'true'                  ,NULL        ),-- constant
        (6,  'CONSTANT',    'False'     ,'false'                 ,NULL        ),
        (7,  'OPERATOR',    'And'       ,'&&'                    ,NULL        ),-- operator
        (8,  'OPERATOR',    'Or'        ,'||'                    ,NULL        ),
        (9,  'ACTION',      'Show'      ,'setHidden(false)'      ,'FIELD'     ),-- action
        (10, 'ACTION',      'Hide'      ,'setHidden(true)'       ,'FIELD'     ),
        (11, 'ACTION',      'Enable'    ,'setDisabled(false)'    ,'FIELD'     ),
        (12, 'ACTION',      'Disable'   ,'setDisabled(true)'     ,'FIELD'     ),
        (13, 'ACTION',      'Disabled'  ,'setDisabled(newValue)' ,'VALUEFIELD'),-- valueaction
        (14, 'ACTION',      'Hidden'    ,'setHidden(newValue)'   ,'VALUEFIELD'),
        (15, 'ACTION',      'Enabled'   ,'setDisabled(!newValue)','VALUEFIELD'),
        (16, 'ACTION',      'Shown'     ,'setHidden(!newValue)'  ,'VALUEFIELD'),
        (17, 'ACTION',      'Add Form'  ,'addForm'               ,'FUNCTION'  ),
        (18, 'STATEMENT',   'On Execute','on'                    ,NULL        );
        */
        handleIf(dep, value) {
            if (dep.action_form_logic_id === 9 || dep.action_form_logic_id === 10) {
                if (dep.form_logic_value == value + "") {
                    return true
                } else {
                    return false
                }
            }
        },
        looseJsonParse(obj){
            return Function('"use strict";return (' + obj + ')')();
        },
        updateDependencies(records) {
            setTimeout(function() {
                let me = this // for scope in the inline functions
                records.forEach(rec => {
                    let fieldId = this.formFieldId(rec)
                    // update the local cached values
                    this.allFields[fieldId].value = rec.value

                    /*
                    Basically, this will loop through the allFields object and check if there are any dependencies
                    on the fields that just changed.  If there were, iterate though the this.controls object to reprocess
                    all v-show/class="disabled" processing.

                    Important: the local cached value needs to be updated for processing to work
                    */
                    if (this.controls[fieldId]) {
                        let alreadyProcessed = {}
                        Object.values(this.controls).forEach(arr => {
                            arr.forEach(control => {
                                // CHANGE THIS TODO
                                let field    = this.allFields[control.controlling_form_field_id]
                                let value    = field.value
                                let fieldRef = this.$refs['field_'+control.controlling_form_field_id]
                                let newValue = this.evalValue(field) // this var is used in the eval statement
                                // reset to default value before processing logic
                                if (!alreadyProcessed[control.form_field_id]) {
                                    if (control.form_field_id) {
                                        me.hideFields[control.form_field_id] = false
                                        me.disableFields[control.form_field_id] = false
                                    } else if (control.form_section_id) {
                                        me.hideSections[control.form_section_id] = false
                                        me.disableSections[control.form_section_id] = false
                                    }
                                }
                                alreadyProcessed[control.form_field_id] = 1

                                let actionLogic = this.formLogic.find((e) => { return control.action_form_logic_id == e.id })
                                let condLogic   = this.formLogic.find((e) => { return control.cond_form_logic_id == e.id })
                                let stmtLogic   = this.formLogic.find((e) => { return control.statement_form_logic_id == e.id })

                                let setHidden = function(val) {
                                    if (control.form_section_id) {
                                        me.hideSections[control.form_section_id] = val
                                    } else if (control.form_field_id) {
                                        me.hideFields[control.form_field_id] = val
                                    }
                                }

                                let setDisabled = function(val) {
                                    if (control.form_section_id) {
                                        me.disableSections[control.form_section_id] = val
                                    } else if (control.form_field_id) {
                                        me.disableFields[control.form_field_id] = val
                                    }
                                }

                                if (stmtLogic.id == 1) {
                                    // right now string eval if this is an 'if'
                                    let str = `${stmtLogic.value} ( '${value}' ${condLogic.value} '${control.form_logic_value}' ) { ${actionLogic.value} }`

                                    eval(str)
                                } else {
                                    let me = this
                                    let result = eval(actionLogic.value)
                                }
                            })
                        })
                    }
                })

                this.hideSections = {...this.hideSections}
                this.$forceUpdate()
            }.bind(this), 100)

        },
        evalValue(field) {
            switch(field.xtype) {
                case 'checkboxfield':
                    return ![false,null,'false',''].includes(field.value)
                default:
                    return field.value ? true : false
            }
        },
        loadForm(isLoading=true) {
            this.isLoading = isLoading

            let controls = {}
            let dependentSections = {}
            let dependentFields = {}

            return new Promise((resolve, reject) => {
                let urlParams = this.isSmartFormInstance
                    ? '&form_instance_id=' + this.$props.id
                    : '&form_id=' + this.template.id

                this.$modelGet('formItemDependency', {
                    form_instance_id: this.$props.id || undefined,
                    form_id: this.template && this.template.id || undefined,
                }).then(data => {

                    let dupWhen = {}
                    data.forEach(dependency => {
                        if(dependency.statement_form_logic_id == 2) {
                            let key = [
                                'form_id',
                                'form_field_id',
                                'form_section_id',
                                'statement_form_logic_id',
                                'action_form_logic_id',
                            ].map( x => dependency[x]).join('|')

                            if(dupWhen[key] !== undefined) {
                                dupWhen[key].dupWhen = true
                            }
                            dupWhen[key] = dependency
                        }
                    })

                    this.dependencies = Object.freeze(data)
                    // build a quick lookup of the deps by ID
                    data.forEach(dep => {
                        let control = dep.controlling_form_field_id

                        if (!controls[control]) {
                            controls[control] = []
                        }
                        controls[control].push(dep)

                        // setup section lookup
                        if (dep.form_section_id) {
                            if (!dependentSections[dep.form_section_id]) {
                                dependentSections[dep.form_section_id] = []
                            }

                            dependentSections[dep.form_section_id].push(dep)
                        }

                        // setup field lookup
                        if (dep.form_field_id) {
                            if (!dependentFields[dep.form_field_id]) {
                                dependentFields[dep.form_field_id] = []
                            }

                            dependentFields[dep.form_field_id].push(dep)
                        }
                    })

                    this.loadData().then( r => {
                        this.controls = controls
                        this.dependentSections = dependentSections
                        this.dependentFields = dependentFields
                        this.setDefaultFormState()
                        this.updateDependencies(Object.values(this.allFields))
                        this.isLoading = false
                        resolve(r)
                    }).catch(error => {
                        this.isLoading = false
                        reject('loadData: '+error)
                    })
                }).catch(error => {
                    this.isLoading = false
                    reject('loadForm: '+error)
                })
            })
        },
        loadCore() {
            this.$modelGet('grade')
                .then(response => this.grades = response )
                .catch(err => console.warn('Unable to load core data [grades]', err))

            this.$modelGet('schoolYear')
                .then(response => this.schoolYears = response )
                .catch(err => console.warn('Unable to load core data [schoolYear]', err))

            this.$modelGet('school')
                .then(response => this.schools = response)
                .catch(err => console.warn('Unable to load core data [school]', err))

            this.$modelGet('interventionLevel')
                .then(response => this.interventionLevels = response)
                .catch(err => console.warn('Unable to load core data [interventionLevel]', err))

            this.$axios.get('users.php?action=get&v=2&active=1')
                .then(response => this.users = response.data)

            this.$modelGet('interventionType', { active: 1})
                .then(response => this.interventionTypes = response)
                .catch(err => console.warn('Unable to load core data [interventionType]', err))

            this.$modelGet('studentDisabilityType')
                .then(response => this.disabilityTypes = response)
                .catch(err => console.warn('Unable to load core data [disabilityType]', err))

            this.$modelGet('ellLevel')
                .then(response => this.ellLevels = response)
                .catch(err => console.warn('Unable to load core data [ellLevel]', err))

            this.$modelGet('homeLanguage')
                .then(response => this.homeLanguages = response)
                .catch(err => console.warn('Unable to load core data [homeLanguage]', err))

            this.$modelGet('formLogic')
                .then(response => this.formLogic = response)
                .catch(err => console.warn('Unable to load core data [formLogic]', err))

        },
    }
}
