import LaunchPad from '@/components/launchpad/LaunchPad'
// import Thresholds from '@/components/common/IFrameRenderer'
// import IncidentCreation from '@/components/common/IFrameRenderer'
// import IFrameRenderer from '@/components/common/IFrameRenderer'
// import Vue from 'vue'

// let thresholdInst  = Vue.extend(Thresholds)
// let adInst = Vue.extend(IFrameRenderer)

export default [{
    path: '/',
    component: LaunchPad,
    name: 'Launchpad',
    meta: {
        navigation: 'launchpad',
        routes: []
    }
}, {
    path: '*',
    component: LaunchPad,
    name: 'Launchpad',
    meta: {
        navigation: 'launchpad',
        routes: ['/']
    }
}]