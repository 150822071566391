<template>
    <div>
        <div>
            <div style="font-size: 12px;">Notify Assigned Staff</div>
            <div @click="dialog = !dialog">
                <v-icon :color="selected.length?'rgb(1,108,144)':'rgba(0,0,0,.54)'" size="20px">
                    {{iconType}}
                </v-icon>
            </div>
        </div>
        <v-dialog
            v-model="dialog"
            max-width="400"
            :disabled="disabled"
        >
            <v-card>
                <v-card-title class="headline">Select Staff to Notify</v-card-title>
                <div style="overflow:auto; max-height:70vh;">
                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="items"
                        item-key="id"
                        show-select
                        hide-default-footer
                        :sort-asc.sync="sortAsc"
                        :sort-by.sync="columnName"
                    >
                        <template slot="items" slot-scope="props">
                            <td><v-checkbox v-model="props.selected" primary hide-details /></td>
                            <td>{{ props.item.user_full_name }}</td>
                        </template>
                    </v-data-table>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <fe-btn usage="tertiary" text @click="dialog = false">Close</fe-btn>
                    <fe-btn light depressed color="primary" @click="updateEmailUsers">Update</fe-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'FindAssignedStaff',
    props: {
        emailUsers: { type: Array },
        students: { type: Array },
        disabled: { type: Boolean, default: false },
        show: { type: Boolean, default: false }
    },

    watch: {
        dialog(val) {
            if(!val || this.isLoading) return
            this.load()
        },
        emailUsers() {
            this.load()
        },
        students() {
            this.load()
        }
    },
    computed: {
        ...mapState('global', ['currentYear']),
        iconType() {
            return this.selected.length ? "fas fa-check-square" : "far fa-square"
        }
    },
    data() {
        return {
            dialog: this.show,
            headers: [{
                text: 'User',
                align: 'left',
                value: 'user_full_name',
                width: '95%'
            }],
            items: [],
            selected: [],
            isLoading: false,
            sortAsc: true,
            columnName: 'user_full_name'
        }
    },
    methods: {
        updateEmailUsers() {
            let users = []
            // isolate any emailUsers that are not in items
            this.emailUsers.forEach(x => {
                if(!this.items.find(y => x.id == y.id)) {
                    users.push(x)
                }
            })
            users = [...users, ...this.selected]
            this.$emit('update', users)
            this.dialog = false
        },
        load() {
            if(this.isLoading) return
            if(!this.currentYear) return
            this.isLoading = true
            let studentIds = this.students.map(s => s.student_id)
            let year = this.currentYear.id
            let url = 'students.php?action=get&property=assigned_users&school_year_id=' + year + '&student_id=' + studentIds
            let me = this
            this.$axios.get(url).then((response) => {
                me.isLoading = false
                if (response && response.data) {
                    // filter unique only
                    me.items = response.data.assigned_users.filter( (x,i) => {
                        return response.data.assigned_users.findIndex( user => user.id === x.id) === i
                    })
                    me.selected = me.items.filter(x => me.emailUsers.find(y => x.id == y.id))
                }
            }).catch((err) => {
                console.warn('err', err)
            })
        }
    },
}
</script>

<style scoped="true">
</style>
