<template>
    <div class="d-flex flex-fill justify-center">
        <fe-empty-state icon="fa-empty-set" text="Sorry, that page was not found or you do not have permission to access it"></fe-empty-state>
    </div>
</template>

<script>
    export default {
        name: 'PageNotFound'
    }
</script>

<style lang="scss" scoped>

</style>