<template>
    <VCard class="ma-2 chart-card" color="#fff">
        <v-card-title v-if="title">
            <div
                v-if="icon"
                class="header-icon"
                :style="{ backgroundImage: `url(${icon})`}"
                :title="`'${title}' Header Icon`"
            />

            <div
                v-if="mIcon"
                class="header-icon"
                :title="`'${title}' Header Icon`"
            >
                <v-icon color="white">{{ mIcon }}</v-icon>
            </div>
            {{ title }}
        </v-card-title>

        <div v-if="pending">
            <v-divider/>

            <div class='text-smaller text-muted ma-3 pb-3'>
                <v-progress-circular indeterminate color="#999" size="14"/>
                Loading...
            </div>
        </div>

        <div v-else-if="empty">
            <v-alert :value="true" type="info" color="primary">
                No results returned for this category.
            </v-alert>
        </div>

        <div v-else>
            <v-divider/>

            <slot></slot>
        </div>
    </VCard>
</template>

<script>
export default {
    name: 'Card',

    props: {
        title: String,
        pending: Boolean,
        empty: Boolean,
        icon: String,
        mIcon: String
    }
}
</script>

<style lang="scss" scoped>
.v-card {
    &__title {
        height: 56px;
        line-height: 56px;

        .header-icon {
            height: 28px;
            line-height: 28px;
            width: 30px;
            margin-right: 10px;
            text-align: center;
            display: inline-block;
            border-radius: 5px;
            background: #3CCCCA;
            background-position: center center;
            background-size: 55%;
            background-repeat: no-repeat;
            color: #fff;
            padding: 0;

            .material-icons {
                position: relative;
                top: -2px;
                font-size: 20px;
            }
        }
    }
}
</style>
