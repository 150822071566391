<template>
    <div class="ec-nav-stepper">
        <v-menu offset-y v-model="localShowMenu">
            <template v-slot:activator="{ on }">
                <div class="ec-nav-stepper-btn" style="float: left;" v-on="on">
                    <p>{{ displayName }} <span class="pl-1" v-if="localValue">/</span></p>
                </div>
            </template>

            <v-list>
                <v-list-item v-for="node in nodes" :key="node.name" @click="selectNode(node)">
                    <v-list-item-title>{{node.name}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <div v-if="localValue && localValue.nodes">
            <navigation-stepper :nodes="localValue.nodes" v-model="childSelection" :showMenu="true"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NavigationStepper',
        watch: {
            value(v) {
                this.$data.localValue = v

                // if (!v) this.$data.childSelection = null
            },
            localValue(v) {
                // clear the decendent tree since we switched parent navigations
                this.$data.childSelection = null
            },
            childSelection(v) {
            }
        },
        props: {
            nodes: {
                type: Array,
                default() {
                    return []
                }
            },
            value: {

            },
            placeholder: {
                type: String,
                default: 'Select'
            },
            showMenu: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            displayName() {
                return this.localValue && this.localValue.name ? this.localValue.name : this.$props.placeholder
            }
        },
        mounted() {
            this.$data.localValue = this.$props.value
            this.$data.localShowMenu = this.$props.showMenu
        },
        data() {
            return {
                localValue: null,
                localShowMenu: false,
                childSelection: null
            }
        },
        methods: {
            selectNode(node) {
                this.$emit('input', node)
                this.localValue = node
                this.$data.childSelection = null
                if (node.route) {
                    this.$router.replace(node.route)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.ec-nav-stepper {
    .ec-nav-stepper-btn {
        cursor: pointer;
        color: white;
        font-size: 14px;
        margin-top: 8px;
        margin-right: 8px;
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>