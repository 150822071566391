<template>
    <div>
        <v-layout row wrap class="ec-collection-layout">
            <v-flex v-for="(collection, index) in collectionData" class="ec-collection-card-flex "
                :key="collection.dashboard_id" v-if="(!showAll && index < 25) || showAll"  :class="checkBreakpoint()">
                <v-card outlined class="mt-1 mb-1 ec-collection-card" max-width="90%" width="90%"
                        :style="{'border-left': '7px solid ' + collection.color }" @click="routeToCollection(collection.dashboard_id)">
                    <v-card-text class="ec-collection-card-text">
                        <v-layout row class="ec-collection-card-text-layout">
                            <v-flex>
                                <div class="ec-collection-card-title">{{ collection.title }}</div>
                            </v-flex>
                            <v-flex class="ec-collection-card-icon-subcollection">
                                <i v-if="collection.hidden" class="far fa-network-wired fa-rotate-180" :style="{'color': collection.color}"></i>
                            </v-flex>
                            <v-spacer/>
                            <v-flex class="ec-collection-card-icon-flex">
                                <fe-tooltip :tooltip="iconTooltip(collection)">
                                    <div class="ec-collection-card-icon-div" v-html="getIcon(collection)" :style="{ 'color': collection.color }"></div>
                                </fe-tooltip>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <a @click="showAll = !showAll" v-if="collectionData && collectionData.length > 25 && activeTab === 0">
            <span class="ec-collection-more-count">{{ showAll ? 'Show Less' : 'Show  ' + moreCount + ' More' }}</span>
        </a>
    </div>
</template>

<script>
    export default {
        name: 'CollectionLanding',
        data() {
            return {
                showAll: false
            }
        },
        props: {
            collectionData: { type: Array },
            collectionTypes: { type: Array },
            activeTab: { type: Number },
            collectionGroups: { type: Array }
        },
        methods: {
            getIcon(types) {
                return this.switchTypes('getIcon', types)
            },
            iconTooltip(types) {
                return this.switchTypes('iconTooltip', types)
            },
            onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            },
            routeToCollection(collection) {
                this.$router.push({ path: '/Collections/id:' + collection })
            },
            switchTypes(fn, types) {
                let colTypes = []
                this.collectionTypes.forEach(ct => {
                    if(types.saved_searches) {
                        types.saved_searches.forEach(type => {
                            if(ct.id === type.dashboard_saved_search_type_id) {
                                colTypes.push(ct.code)
                            }
                        })
                    }
                    if (types.form_instances && types.form_instances.length > 0) {
                        colTypes.push('FORM_INSTANCES')
                    }
                    if (types.dashboard_meetings && types.dashboard_meetings.length > 0) {
                        colTypes.push('DASHBOARD_MEETINGS')
                    }
                })
                let unique = colTypes.filter(this.onlyUnique)
                let htmlIcons = ''
                let htmlTooltips = ''
                let count = 0
                let chartIcon = 0
                let datawallIcon = 0
                let formIcon = 0
                let meetingsIcon = 0
                let type = []
                unique = unique.sort()
                let arrIcons = []
                for(let i = 0; i < unique.length; i++) {
                    switch(unique[i]) {
                        case 'STACKEDCHART':
                        case 'STUDENTTREND':
                        case 'BIG5':
                        case 'PROGRESSMONITORING':
                        case 'BEHAVIORPROCESSMONITORING':
                        case 'ATTENDANCE':
                        case 'ATTENDANCECOUNT':
                        case 'PYRAMID':
                        case 'DETAILEDPYRAMID': {
                            if(arrIcons.indexOf('chart') === -1) {
                                arrIcons.push('chart')
                            }
                            break
                        }
                        case 'DATAWALL': {
                            arrIcons.push('datawall')
                            break
                        }
                        case 'FORM_INSTANCES': {
                            arrIcons.push('smartform')
                            break
                        }
                        case 'DASHBOARD_MEETINGS': {
                            arrIcons.push('meeting')
                            break
                        }
                    }
                }
                // want to always have same order for icons
                arrIcons = arrIcons.sort()
                for(let i = 0; i < arrIcons.length; i++) {
                    switch(arrIcons[i]) {
                        case 'chart': {
                            chartIcon++
                            count++
                            if(count <= 3) {
                                htmlIcons += "<i class='far fa-chart-bar pa-1 ec-collection-card-icon'></i>"
                            }
                            break
                        }
                        case 'datawall': {
                            datawallIcon++
                            count++
                            if(count <= 3) {
                                htmlIcons += "<i class='far fa-th-large pa-1 ec-collection-card-icon'></i>"
                            }
                            break
                        }
                        case 'smartform': {
                            formIcon++
                            count++
                            if(count <= 3) {
                                htmlIcons += this.$ecIcons('far fa-clipboard', 'fas fa-sparkles', types.color)
                            }
                            break
                        }
                        case 'meeting': {
                            meetingsIcon++
                            count++
                            if(count <= 3) {
                                htmlIcons += "<i class='far fa-calendar-alt pa-1 ec-collection-card-icon'></i>"
                            }
                            break
                        }
                    }
                }
                if(count > 3) {
                    // if more than 3, remove one and add " + [count] more"
                    let split = htmlIcons.split('</i>')
                    htmlIcons = split[0] + split[1]
                    htmlIcons += "<span class='ec-collection-card-more'> +" + (count - 2) + "</span>"
                }
                htmlTooltips = types.title
                if(chartIcon > 0) type.push('charts')
                if(datawallIcon > 0) type.push('Data Walls')
                if(meetingsIcon > 0) type.push('meetings')
                if(formIcon > 0) type.push('smartFORMs')
                if(type.length > 0) {
                    htmlTooltips += " contains "
                    for(let i = 0; i < type.length; i++) {
                        if(type.length > 1 && i === type.length - 1) {
                            htmlTooltips += ' and '
                        }
                        htmlTooltips += type[i]
                        if(type.length > 2 && i < type.length - 1) {
                            htmlTooltips += ', '
                        }
                    }
                }
                if(fn === 'getIcon') {
                    return htmlIcons
                } else {
                    return htmlTooltips
                }
            },
            checkBreakpoint() {
                if(this.$vuetify.breakpoint.mdOnly) {
                    return 'ec-collection-card-md'
                } else if(this.$vuetify.breakpoint.smAndDown) {
                    return 'ec-collection-card-sm'
                } else {
                    return 'ec-collection-card-lg'
                }
            }
        },
        computed: {
            moreCount() {
                return this.collectionData ? this.collectionData.length - 25 : 0
            }
        },
        mounted() {
            if(this.activeTab !== 0) {
                this.showAll = true
            }
        }
    }
</script>

<style lang="scss">
    .ec-collection-card {
        &-more {
            font-size: 10px;
            font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
            display: inline-block;
            padding: 4px;
        }
    }
</style>

<style lang="scss" scoped>
    .ec-collection-layout {
        margin-bottom: 8px;
    }
    .ec-collection-more-count {
        border-bottom: 1px dotted;
    }
    .ec-collection-card {
        height: 100%;
        cursor: pointer;
        &-text {
            padding: 5px 5px 5px 10px !important;
            height: 100%;
            &-layout {
                align-items: center;
                height: 100%;
            }
        }
        &-title {
            $font-size: 14px;
            $line-height: 1.5;
            $lines-to-show: 2;
            display: flex; /* Fallback for non-webkit */
            display: -webkit-box !important;
            justify-content: center;
            max-width: 100px !important;
            min-width: 100px !important;
            height: $font-size*$line-height*$lines-to-show !important; /* Fallback for non-webkit */
            font-size: $font-size !important;
            line-height: $line-height !important;
            -webkit-line-clamp: $lines-to-show !important;
            -webkit-box-orient: vertical !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            float: left !important;
        }
        &-icon-flex {
            align-items: center;
            display: flex;
            justify-content: flex-end;
        }
        &-icon-div {
            display: flex;
            align-items: center;
        }
        &-icon-subcollection {
            align-items: center;
            display: flex;
            justify-content: flex-start;
        }
        &-flex {
            align-items: center;
            margin: 4px 0;
        }
        &-lg {
            width: 20%;
            max-width: 20%;
            flex-basis: 20%;
        }
        &-md {
            width: 25%;
            max-width: 25%;
            flex-basis: 25%;
        }
        &-sm {
            width: 50%;
            max-width: 50%;
            flex-basis: 50%;
        }
    }
</style>
