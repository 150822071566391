<template>
    <span v-if="!hideIfEmpty || (collection && collection.length)">
        <fe-label v-if="label" :content="label"/>

        <fe-chip-select
            ref="cs"
            v-model="selected"
            :items="filteredCollection"
            :item-text="labelProp"
            :item-value="keyProp"
            :multiple="multiple"
            :clearable="clearableDefault"
            :untruncated="untruncated"
            :readonly="readonly || !success"
            :full-width="fullwidth"
            :rules="rules"
            :required="required"
            :autofocus="false"
            chips
            open-on-clear
            deletable-chips
            @blur="onBlur"
            contentClass="thresholds-store-selector-text-wrap"
        />
    </span>
</template>

<script>
export default {
    name: "StoreSelector",

    props: {
        storeNamespace: String,
        storeUpdateAction: String,
        storeUpdateIdx: Number,
        storeUpdateProp: String,
        label: String,
        multiple: Boolean,
        selectedKeys: [Array, Number, String],
        keyProp: String,
        labelProp: String,
        readonly: Boolean,
        fullwidth: Boolean,
        rules: Array,
        required: Boolean,
        untruncated: Boolean,
        hideIfEmpty: Boolean
    },

    data() {
        return {
            collection: undefined
        }
    },

    computed: {
        success() {
            return this.$store.getters[`${this.storeNamespace}/success`];
        },

        clear() {
            return this.$store.getters[`${this.storeNamespace}/clear`];
        },

        filteredCollection() {
            // return this.$store.getters[`${this.storeNamespace}/collection`];
            let collection = this.$_.cloneDeep(this.collection || [])

            // make sure each option has a display_name
            // use value if display_name doesn't exist
            // also check for display_name_group and only show 1 member of group
            // check for display_name and grouping
            if (['thresholds/ethnicity','thresholds/gender','thresholds/disability','thresholds/ellLevel'].includes(this.storeNamespace)) {
                let filteredOptions = []
                collection.forEach(itm => {
                    if (!itm.display_name_group) {
                        itm.display_name = itm.display_name || itm.value || itm.name || itm.description
                        filteredOptions.push(itm)
                    } else {
                        let o = collection.find(x => (x.id || x.value) == itm.display_name_group[0]) // find and return first option within display_name_group; id can be string
                        if (!filteredOptions.find(x => x.display_name === o.display_name)) {
                            o = {
                                ...o,
                                display_name: o.display_name || o.value || itm.name || itm.description // use display_name instead of value
                            }
                            filteredOptions.push(o)
                        }
                    }
                })
                collection = filteredOptions.sort((a, b) => a.display_name < b.display_name ? -1 : 1)
            }

            return collection
        },

        clearableDefault() {
            return !!this.multiple
        },

        selected: {
            get: function () {
                if (this.multiple) {
                    return Array.isArray(this.selectedKeys) &&
                    this.selectedKeys.length > 0
                        ? this.filteredCollection.filter(itm => this.itemSelected(itm))
                        : [];
                } else {
                    return this.selectedKeys;
                }
            },
            set: function (val) {
                if (this.storeUpdateAction) {
                    let obj = {__idx: this.storeUpdateIdx};
                    obj[this.storeUpdateProp] = val;
                    this.$store.dispatch(this.storeUpdateAction, obj);
                }
                this.$emit("change", val);
            }
        }
    },

    watch: {
        clear: function (val) {
            if (val) this.reset();
        },
        success: function () {
            this.setCollection()
        }
    },

    mounted() {
        if (this.clear) this.refresh();
        if (!this.collection) {
            this.setCollection()
        }
    },

    methods: {
        setCollection() {
            this.collection = this.$store.getters[`${this.storeNamespace}/collection`]
        },

        itemSelected(itm) {
            return this.selectedKeys
                .map(x => `${x}`)
                .includes(`${itm[this.keyProp]}`);
        },

        refresh() {
            this.$store.dispatch(`${this.storeNamespace}/refresh`);
        },

        reset() {
            this.refresh();
        },

        getSelectedKeys(v) {
            return this.multiple && Array.isArray(v)
                ? v.map(x => {
                    return typeof x == "object" && x.hasOwnProperty(this.keyProp)
                        ? x[this.keyProp]
                        : x;
                })
                : v;
        },

        onBlur(e) {
            if (e instanceof FocusEvent) {
                this.$emit("updates", this.getSelectedKeys(this.$refs.cs.getValue()));
            }
        }
    }
};
</script>

<style lang="scss">
    .thresholds-store-selector-text-wrap .v-list-item__title {
        white-space: normal !important;
    }
</style>