import Vue from 'vue';

export default Vue.extend({
    template: `
        <v-autocomplete
            v-model="value"
            ref="control"
            open-on-clear
            flat solo dense
            :items="items"
            :item-text="params.labelProp"
            :item-value="params.keyProp"
            :editable="editing"
            @input="valueChanged"
            :menuProps="{ contentClass: 'ag-custom-component-popup' }"
        >
            <template v-slot:item="data">
                <div v-html="data.item[params.labelProp]"></div>
            </template>
            <template v-slot:selection="data">
                <div v-html="data.item[params.itemText]"></div>
            </template>
        </v-autocomplete>`,
    name: 'ColorSelect',
    data() {
        return {
            value: [],
            multiple: false,
            items: [],
        }
    },
    computed: {
        editing() {
            return this.params.column.colDef.editable
        },
    },
    methods: {
        getValue() {
            return this.value
        },
        isPopup() {
            return this.editing
        },
        exit() {
            this.params.api.stopEditing()
        },
        valueChanged(v) {
            if (!this.multiple) this.exit()
        },
        trigger() {
            if (this.editing && this.$refs.control) {
                this.$refs.control.$el.querySelector('input').click()
            }
        }
    },
    mounted() {
        this.value = this.params.value
        this.multiple = !!this.params.multiple
        this.items = this.params.items
        this.$nextTick(() => {
            this.trigger()
        })
    }
})
