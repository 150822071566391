// export const UUIDv4 = function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)}

export function UUIDv4() {
    var firstPart = (Math.random() * 46656) | 0;
    var secondPart = (Math.random() * 46656) | 0;
    var thirdPart = (Math.random() * 46656) | 0;
    firstPart = ("000" + firstPart.toString(36)).slice(-3);
    secondPart = ("000" + secondPart.toString(36)).slice(-3);
    thirdPart = ("000" + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart + thirdPart;
}

export function fitSquares3(rectWidth, rectHeight, numItems) {
    let tableRatio = rectWidth / rectHeight
    let columns = Math.sqrt(numItems * tableRatio)
    let rows = columns / tableRatio

    columns = Math.ceil(columns)
    rows = Math.ceil(rows)

    let squareSize = rectWidth / columns

    return {
        squareSize: squareSize,
        columns: columns,
        rows: rows,
    }
}

export function fitSquares5(w, h, numItems) {
    let area = w*h
    let idealArea = area / numItems
    let idealSize = Math.sqrt(idealArea)
    let cols = Math.ceil(w / idealSize)
    let rows = Math.ceil(h / idealSize)
    let size = Math.min(w / cols, h / rows)

    return {
        squareSize: size,
        columns: cols,
        rows: rows,
    }
}

export function fitSquares6(w, h, n) {
    let sw, sh

    let pw = Math.ceil(Math.sqrt(n*w/h))
    if (Math.floor(pw*h/w)*pw < n) sw = h/Math.ceil(pw*h/w)
    else sw = w/pw

    let ph = Math.ceil(Math.sqrt(n*h/w))
    if (Math.floor(ph*w/h)*ph < n) sh = w/Math.ceil(w*ph/h)
    else sh = h/ph

    return {
        squareSize: Math.max(sw,sh),
        columns: pw,
        rows: ph,
    }

}

export function rectHit(x1,y1,w1,h1,x2,y2,w2,h2) {
    return !(
        x2 > x1+w1
        || x2+w2 < x1
        || y2 > y1+h1
        || y2+h2 < y1
    )
}

export function clamp(val, min, max) {
    return val > max ? max : val < min ? min : val;
}

export function studentFilterText(cmp,filters,studentRecord) {
    if(!studentRecord || !studentRecord.filters) return []
    return filters.map( filter => {
        let studentFilter = studentRecord.filters[filter.id]
        if(!studentFilter) {
            return { text: '', filter: null}
        }

        if(!Array.isArray(studentFilter)) {
            studentFilter = [].concat(studentFilter)
        }
        let value = studentFilter.map( x => {
            // Incident counts return as integer values and can be identified by
            // locating "Incident Count" in the extra_values field.  We'll skip toFixed for these
            let isIncidentCountValue = cmp.$_.get(x, ['extra_values', 'Incident Count'], null) !== null;
            if (isIncidentCountValue) return x.value;

            if(typeof x.value == 'boolean' && x.numeric_value) return parseFloat(x.numeric_value).toFixed(2)

            return cmp.$_.isBlank(x.value) ? null : (
                isNaN(x.value) || typeof x.value == 'boolean' ? String(x.value) : parseFloat(x.value).toFixed(2)
            )
        }).join(', ')

        return {
            text: [filter.chipText,value].filter(Boolean).join(': '),
            value: value,
            filterId: filter.id,
            type: filter.type,
            name: filter.chipText,
            detail: filter.score_detail_type,
            filter: studentFilter,
        }
    })
}
