<template>
    <div
        class="state-button"
        @click="active = !active"
        :class="{'state-button-active': active}"
    >
        <v-icon v-if="active">far fa-check</v-icon>
        <slot>{{ text }}</slot>
        <v-menu v-if="menu.items" offset-y>
            <template v-slot:activator="{ on }">
                <v-icon class="state-button-item ma-1" v-on="on" color="#050F2D" style="font-size: 24px !important; padding: 0 8px;">fas fa-ellipsis-v</v-icon>
            </template>

            <v-list>
                <v-list-item v-for="(itm, idx) in menu.items" :key="idx" hover @click="itm.handler(item)">
                    {{ itm.text }}
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    export default {
        name: 'StateButton',
        props: {
            text: { type: String },
            value: { type: Boolean },
            menu: { 
                type: Object,
                default: function () { return {} }
            },
            item: {
                type: Object,
                default: function () { return {} }
            },
        },
        data () {
            return {
                localValue: null
            }
        },
        computed: {
            active: {
                get () { return !!this.localValue },
                set (v) {
                    this.localValue = v
                    this.$emit('input', v)
                }
            },
        },
        watch: {
            value: {
                handler (v) { this.localValue = v },
                immediate: true
            }
        }
    }
</script>

<style lang="scss" scoped>
.state-button {
    background: white;
    border: 1px solid var(--fe-selected);
    padding: 8px;
    min-width: 100px;
    text-align: center;
    display: inline-flex;
    margin-right: 8px;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    &:hover {
        background: var(--fe-accent) !important;
        opacity: 0.75;

        .state-button-item {
            visibility: visible;
        }
    }
    &-active {
        background: var(--fe-accent) !important;
    }
    .state-button-item {
        visibility: hidden;
    }
}
</style>