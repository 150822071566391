<template>
    <div class="flex-column flex-fill">
        <div class="d-flex">
            <form-filter searchButton v-model="filterParams"/>
            <div ref="group" class="ec-group-nav ml-auto align-start" style="margin:6px;">
                <fe-btn
                    usage="secondary"
                    useIcon="far fa-chart-bar"
                    @click="panel=1"
                    class="ec-group-nav-option first"
                    :class="{selected:panel==1}"
                >Charts</fe-btn>
                <fe-btn
                    usage="secondary"
                    useIcon="far fa-bars"
                    @click="panel=2"
                    class="ec-group-nav-option middle"
                    :class="{selected:panel==2}"
                >List View</fe-btn>
                <fe-btn
                    usage="secondary"
                    useIcon="far fa-th-list"
                    @click="panel=3"
                    class="ec-group-nav-option last"
                    :class="{selected:panel==3}"
                >Field Data</fe-btn>
            </div>
        </div>
        
        <keep-alive>
            <form-chart v-if="panel==1||panel==3" :showGrid="panel==3" :template-id="templateId" :filters="filterParams" />
            <form-instance-list v-else :template-id="templateId" :filters="filterParams" />
        </keep-alive>
        
    </div>
</template>

<script>
import FormFilter from './FormFilter'
import FormInstanceList from './FormInstanceList'
import FormChart from './FormChart'

export default {
    name: 'FormReport',
    
    components: {
        FormFilter,
        FormInstanceList,
        FormChart,
    },
    
    props: {
        templateId: { required:true },
        showChartsFirst: { type:Boolean, default:false },
        filters: { type:Object, default:null },
    },
    
    created() {
        this.panel = this.showChartsFirst ? 1 : 2
        this.filterParams = this.filters ? {...this.filters} : {
            school_year_id: this.$store.state.global.shareableStores.current_year.id,
            modified: 1,
        }
    },
    
    data() {
        return {
            panel: 2,
            filterParams: {},
        }
    }
    
}
</script>

<style lang="scss" scoped>
</style>
