<template>
    <div v-if="params" class="d-flex flex-fill flex-column">
        <v-form v-model="isValid">
            <div class="section-title">Basic Information</div>
            <fe-label class="pt-2 px-2">Name/Label</fe-label>
            <v-text-field
                class="px-2"
                width="100%"
                v-model="sloRecord.slo_name"
                :value="sloRecord.slo_name"
                flat solo dense validateOnBlur
                :rules="$fieldValidators('text', 'Name/Label', { required: true, limit: 255})"
            />

            <fe-remote-combo
                label="Assignee"
                class="pt-2 px-2"
                :items="users"
                itemText="user_full_name"
                itemValue="id"
                byId
                validateOnBlur
                multiple
                v-model="sloRecord.slo_user_user_id"
                :value="sloRecord.slo_user_user_id"
                :rules="$fieldValidators('select', 'Assignee', {required: true})"
            />

            <fe-remote-combo
                label="Reviewer"
                class="pa-2"
                :items="approveSloUsers"
                itemText="user_full_name"
                itemValue="id"
                byId
                validateOnBlur
                v-model="sloRecord.slo_approval_user_id"
                :value="sloRecord.slo_approval_user_id"
                :rules="$fieldValidators('select', 'Reviewer', {required: true})"
            />

            <div class="section-title">Duration</div>

            <div class="d-flex pa-2" style="width: 100%">
                <fe-date-picker
                    class="flex-grow-1 mr-2"
                    label="Start Date"
                    v-model="sloRecord.slo_start_date"
                    :value="sloRecord.slo_start_date"
                    dateFormat="MM/DD/YYYY"
                    validateOnBlur
                    :rules="$fieldValidators('text', 'Start Date', { required: true })"
                />

                <fe-date-picker
                    class="flex-grow-1 ml-2"
                    label="End Date"
                    v-model="sloRecord.slo_end_date"
                    :value="sloRecord.slo_end_date"
                    dateFormat="MM/DD/YYYY"
                    validateOnBlur
                    :rules="$fieldValidators('text', 'End Date', { required: true })"
                />
            </div>

            <div class="d-flex pa-2" style="width: 100%">
                <fe-remote-combo
                    label="Ending Year"
                    :url="$models.getUrl('schoolYear', 'read')"
                    rootProperty="years"
                    v-model="sloRecord.plan.school_year_id"
                    :value="sloRecord.plan.school_year_id"
                    byId
                    validateOnBlur
                    class="flex-grow-1 mr-2"
                    :rules="$fieldValidators('select', 'Ending Year', {required: true})"
                />

                <fe-remote-combo
                    label="Ending Window"
                    :url="$models.getUrl('dataPointName', 'read') + '&data_point_type_id=' + params.data_point_type_id"
                    rootProperty="names"
                    v-model="sloRecord.plan.end_data_point_name_id"
                    :value="sloRecord.plan.end_data_point_name_id"
                    :itemText="endingWindowName"
                    byId
                    validateOnBlur
                    class="flex-grow-1 ml-2"
                    :rules="$fieldValidators('select', 'Ending Window', {required: true})"
                />
            </div>

            <div class="section-title">Monitoring</div>

            <fe-date-picker
                class="flex-grow-1 pa-2"
                label="Monitor Date"
                v-model="sloRecord.slo_monitor_date"
                :value="sloRecord.slo_monitor_date"
                dateFormat="MM/DD/YYYY"
                validateOnBlur
                :rules="$fieldValidators('text', 'Monitor Date', { required: true })"
            />

            <div class="section-title">Rationale</div>

            <fe-text-area
                class="pa-2"
                width="100%"
                v-model="sloRecord.plan.rationale_text"
                :value="sloRecord.plan.rationale_text"
                validateOnBlur
                height="56"
                :rules="$fieldValidators('text', null, { required: false, limit: 1000})"
            />
        </v-form>
    </div>
</template>

<script>
    import {mapState} from "vuex"
    import windowOptionsMixin from '@/mixins/windowOptions'
    import { FunctionalCalendar } from 'vue-functional-calendar'

    export default {
        name: 'SloCreationChartingInformation',
        mixins: [ windowOptionsMixin ],
        components: { FunctionalCalendar },
        props: {
            params: {
                type: Object,
                return: () => {}
            },
            clickRecord: {
                type: Object,
                return: () => {}
            },
            categoryId: {
                type: Number,
                default: null
            },
            sloRecordExists: {
                type: Object,
                return: () =>   {}
            }
        },
        data() {
            return {
                isValid: false,
                users: [],
                approveSloUsers: [],
                dateRange: {},
                sloRecord: {
                    slo_name: '',
                    slo_user_user_id: null,
                    reviewer: null,
                    slo_start_date: null,
                    slo_end_date: null,
                    plan: {},
                    goals: [],
                    strategies: [],
                    logistics: [],
                    slo_monitor_date: null,
                },
                fields: [],
            }
        },

        computed: {
            ...mapState('global', ['sessionUser']),
            dateFilter () { return { included: this.dateSelection ? [{ id: 0, name: this.dateText }] : [], excluded: [] } },

            dateText () { return this.dateSelection?.selectedDate || 'Date Range' },
        },

        methods: {
            endingWindowName(item) {
                return item.alias ? item.alias : item.name
            },
        },

        mounted () {
            this.$axios.get('users.php?action=get&active=1').then(response => {
                this.users = this.$ecResponse(response)
                this.approveSloUsers = this.users.filter(u => u.approve_slo)
            })
            if (this.sloRecordExists.id) {
                this.sloRecord = this.sloRecordExists
            } else {
                this.sloRecord.plan = {
                    ...this.params,
                    slo_plan_id: null,
                    data_point_name_id: this.clickRecord.data_point_name_id,
                    start_data_point_id: this.params ? this.params.data_point_id : this.clickRecord.student_params.data_point_id,
                    end_data_point_id: 0,
                    end_data_point_name_id: 0,
                    rationale_text: this.params.rationale_text ? this.params.rationale_text : "",
                    sub_category_id: this.params.sub_category_parent_id ? this.params.sub_category_parent_id : this.params.sub_category_id,
                }
                this.sloRecord.plan.category_id = this.sloRecord.plan.category_id ? this.sloRecord.plan.category_id : this.categoryId
                delete this.sloRecord.plan.sub_category_parent_id
                this.sloRecord.slo_user_user_id = [this.sessionUser.user.id]
            }
        },

        watch: {
            isValid(value) {
                this.$emit('validate', value)
                if (value) this.$emit('update', this.sloRecord)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .section-title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .filter-clearing-area {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: solid 1px #e5e5e5;
        cursor: pointer;
    }

    .lean-calendar {
        ::v-deep .vfc-main-container {
            box-shadow: none !important;
        }

        // ::v-deep .vfc-main-container {
        //     height: auto !important;
        // }
        ::v-deep .vfc-main-container {
            font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
            padding-bottom: 15px !important;
        }
        ::v-deep .vfc-top-date {
            font-size: 14px !important;
        }
        ::v-deep .vfc-arrow-right, ::v-deep .vfc-arrow-left {
            height: 10px !important;
            width: 10px !important;
        }
        ::v-deep .vfc-dayNames span {
            color: #7E8494 !important;
            margin-bottom: 0 !important;
            font-size: 13px;
        }
        ::v-deep .vfc-today:not(.vfc-marked) {
            background-color: transparent !important;
            color: #000 !important;
            border: 1px solid var(--fe-primary) !important;
        }
        ::v-deep .vfc-week {
            font-size: 13px;
        }
        ::v-deep .vfc-months-container {
            .vfc-content {
                width: 55% !important;
            }
        }

        ::v-deep .vfc-marked {
            background: #CCE2E9 !important;
        }

        ::v-deep .vfc-start-marked {
            background: #006C90 !important;
        }

        ::v-deep .vfc-end-marked {
            background: #006C90 !important;
        }

    }
</style>