<template>
    <div>
        <v-form v-model="valid" ref="form">

            <div class="d-flex justify-space-between" v-if="students">
                <v-autocomplete
                    ref="studentsSearchField"
                    :placeholder="!students.length ? 'Search Students by Name (3 characters minimum)' : 'Attached Students'"
                    :search-input.sync="searchText"
                    :returnObject="true"
                    :no-data-text="noSearchString ? '' : 'No data available'"
                    :hide-no-data="noSearchString"
                    :items="searchResults"
                    :clearable="false"
                    itemText="student_full_name"
                    itemValue="student_id"
                    class="mt-5 ml-3"
                    v-model="setStudents"
                    flat solo dense
                    more-text
                    validateOnBlur
                    @input="addStudent"
                >
                    <template v-slot:item="data">
                        <v-list-item-avatar>
                            <v-img :src="getStudentImg(data.item.student_id)"/>
                        </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{data.item.student_full_name}}</v-list-item-title>
                        <v-list-item-subtitle>{{data.item.school_name}} {{data.item.grade_desc}}</v-list-item-subtitle>
                    </v-list-item-content>
                    </template>
                </v-autocomplete>
                <fe-btn
                    usage="ghost"
                    class="mt-4"
                    @click="advanced = true"
                >Advanced</fe-btn>
            </div>

            <div id="student_chips" v-if="students">
                <v-chip
                    class="student-chip"
                    v-for="stdnt in students"
                    :key="`mtg-stdnt-chip-${stdnt.student_id}`"
                    @click:close="$emit('removeStudents', [stdnt])"
                    close
                >
                    <v-avatar left>
                        <v-img :src="studentImg(stdnt)" />
                    </v-avatar>
                    {{ stdnt.student_full_name }}
                </v-chip>
            </div>

            <div class="d-flex justify-space-between mt-5">
                <div class="self-align-center">
                    <fe-label>Use SmartFORM Agenda</fe-label>
                </div>
                <div class="pt-3">
                    <fe-switch
                        v-model="useSmartform"
                        useIcon="check"
                    />
                </div>
            </div>

            <div v-if="useSmartform" class="d-flex justify-space-between mb-5">
                <fe-remote-combo
                    ref="formField"
                    placeholder="Search SmartFORMs by Name"
                    url="form.php?property=form&action=get"
                    itemText="name"
                    itemValue="id"
                    rootProperty="forms"
                    hideDetails
                    validateOnBlur
                    v-model="formId"
                    :returnObject="false"
                    style="width: 100%;"
                />
            </div>

            <div v-if="showGoogleCalendarSync" class="d-flex justify-space-between mt-5">
                <div class="self-align-center">
                    <fe-label>Sync to Google Calendar</fe-label>
                    <p class="text-smaller">This Meeting will be sent to your Google Calendar.</p>
                </div>
                <div class="pt-3">
                    <fe-switch v-model="isCalSynced" useIcon="check" />
                </div>
            </div>

        </v-form>

        <fe-dialog
            v-if="advanced"
            title="Find Students"
            width="800"
            acceptButtonText="Select"
            dismissButtonText="Cancel"
            dismissable
            persistent
            @dismiss="cancelAdvanced"
            @accept="selectAdvanced"
        >
            <student-search v-model="advancedSelections" keepSelection/>
        </fe-dialog>
    </div>
</template>

<script>
    import StudentSearch from '@/components/common/StudentSearch'
    import lodash from 'lodash'

    export default {
        name: 'Options',
        components: { StudentSearch },
        props: {
            obj: { type: Object, required: true },
            editing: { type: Object, default: null },
            students: { type: Array, required: true },
            showGoogleCalendarSync: { type: Boolean, default: false },
        },
        data() {
            return {
                valid: true,
                formEnabled: null,
                advanced: false,
                advancedSelections: [],
                searchResults: [],
                searchText: null,
                noSearchString: true,
                setStudents: [],
            }
        },
        computed: {
            studentIds () { return this.setStudents ? this.setStudents.map(itm => itm.student_id) : [] },
            formId: {
                get () { return this.obj.form_id },
                set (v) {
                    this.setAttrs('obj', { form_id: v })
                    if (!v) this.formEnabled = v
                }
            },
            useSmartform: {
                get () { return this.formEnabled || !!this.obj.form_id },
                set (v) {
                    this.formEnabled = v
                    if (!v) this.setAttrs('obj', { form_id: null })
                }
            },
            isCalSynced: {
                get () { return this.obj.google_calendar_sync },
                set (v) {
                    this.setAttrs('obj', { google_calendar_sync: v })
                }
            },
        },
        watch: {
            searchText: lodash.debounce(function(v) {
                if (v && v.length >= 3) {
                    this.noSearchString = false
                    this.doSearch()
                } else {
                    this.noSearchString = true
                }
            }, 500),

            setStudents(v) {
                this.$emit('syncStudents', [v])
            },
        },
        mounted() {
            if (this.students) this.setStudents = this.students
        },
        methods: {
            isValid () {
                return (this.$refs.form)
                    ? this.$refs.form.validate()
                    : false
            },
            setAttrs (itm, attrs) {
                this.$emit('setAttrs', { key: itm, attrs: attrs })
            },
            fullName (stdnt) {
                return (stdnt.mdl_init)
                    ? `${stdnt.lname}, ${stdnt.fname} ${stdnt.mdl_init}.`
                    : `${stdnt.lname}, ${stdnt.fname}`
            },
            cancelAdvanced () {
                this.advancedSelections = []
                this.advanced = false
            },
            selectAdvanced () {
                this.$emit('addStudents', this.advancedSelections)
                this.cancelAdvanced()
            },
            studentImg (stdnt) {
                return `${this.$axios.defaults.baseURL}students.php?action=get&property=student_image&id=${stdnt.student_id}`
            },
            doSearch() {
                // Get the most recently applicable school year for each student
                let params = this.$objectToParams({
                    name: this.searchText
                })

                this.$axios.get(this.$models.getUrl('studentGradeHistory', 'read') + '&' + params)
                .then(response => {
                    this.searchResults = this.$ecResponse(response)
                    // sort by name and get latest academic year info for school/grade subtext
                    this.searchResults.sort((a, b) => (a.lname.localeCompare(b.lname) || a.fname.localeCompare(b.fname) || b.school_year_id - a.school_year_id))
                })
            },
            getStudentImg(student_id) {
                return this.$axios.defaults.baseURL + this.$models.getUrl('studentImage', 'read') + '&id=' + student_id
            },
            addStudent(v) {
                this.students.push(v)
            },
        },
    }
</script>

<style lang="scss">
    #student_chips {
        line-height: 35px;
        .student-chip.v-chip.v-chip--removable {
            &>span.v-chip__content {
                max-width: none;
                padding-left: 5px;
            }
        }
    }
</style>
