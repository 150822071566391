let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    studentRecord: {
        defaults: {
            endpoint: 'students.php?property=student'
        },
        ...standardCrud
    },
    studentIds: {
        defaults: {
            endpoint: 'students.php?property=identification',
            rootProperty: 'ids'
        },
        ...standardCrud
    },

    studentGrades: {
        defaults: {
            endpoint: 'students.php?property=grades',
            rootProperty: 'student_grades'
        },
        ...standardCrud
    },

    studentSchools: {
        defaults: {
            endpoint: 'schools.php?property=history',
            rootProperty: 'schools'
        },
        ...standardCrud
    },

    studentIncidents: {
        defaults: {
            endpoint: 'behaviorIncident.php?property=list',
            rootProperty: 'incidents'
        },
        ...standardCrud
    },

    studentYear: {
        defaults: {
            endpoint: 'qry.php?property=school_years',
            rootProperty: 'years'
        },
        ...standardCrud
    },

    studentSchoolsByYear: {
        defaults: {
            endpoint: 'schools.php',
            rootProperty: 'schools'
        },
        rootProperty: 'schools',
        read: 'schools.php'
    },

    studentEnrollments: {
        defaults: {
            endpoint: 'crud.php?property=StudentEnrollmentV',
            rootProperty: 'student_enrollment_v'
        },
        ...standardCrud
    }
}
