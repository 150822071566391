<template>
    <div class="flex-fill flex-column">
        <div class="d-flex flex-shrink-1">
            <template v-for="type in selectionTypes">
                <fe-filter-btn
                    :class="{
                        isOptional: selections[type].optional,
                        isEnabled: selections[type].available.length
                    }"
                    :ref="`filter_${type}`"
                    :key="`filter-${type}`"
                    :title="selections[type].title"
                    :items="selections[type].available"
                    :itemValue="selections[type].field.id"
                    :itemText="selections[type].field.name"
                    v-model="selections[type].filtered"
                    :multiple="!!selections[type].multiple"
                    :disabled="!selections[type].available.length"
                    :actions="true"
                    :closeOnSelect="false"
                    showFirstSelection
                    buffered
                />
            </template>
        </div>
        <div class="pa-2 d-flex flex-grow-1">

            <fe-spinner v-if="!plans" text="Loading plans..." />

            <fe-grid
                v-else
                ref="grid"
                :columnDefs="columnDefs"
                :rowData="plans"
                :bordered="false"
                :showDensityToolbar="false"
                :showSaveAsDefault="false"
                :showBorderToggle="false"
                :showFullscreen="false"
                :showToolbar="false"
                disable-inline-create
                :style="{
                    height: '100%',
                    width: '100%'
                }"
                :emptyState="{
                    text: 'No SLO Plans found with the current selections.',
                    imageSrc: '/images/empty-state-horn.png',
                    imageStyle: 'max-width:50%; max-height:50%;',
                }"
                @firstDataRendered="adjustGrid"
                @rowClicked="sloSelected($event)"
            />

        </div>

        <fe-crud
            autoload
            :config="$models.schoolYear"
            @read="loadItems('schoolYears', $event)"
        />

        <fe-crud
            autoload
            :config="$models.user"
            :defaultParams="{ active: 1 }"
            @read="loadItems('assignees', $event)"
        />

        <fe-crud
            v-if="params"
            autoload
            :config="$models.sloPlan"
            :defaultParams="params"
            refresh-on-config
            @read="plans = $event"
        />

    </div>

</template>

<script>
    export default {
        name: 'SloList',
        components: { },
        props: { },
        data() {
            return {
                plans: null,
                params: null,
                selections: {
                    schoolYears: {
                        field: { id: 'id', name: 'name' },
                        title: 'School Year',
                        available: [],
                        multiple: true,
                        filtered: { included: [], excluded: [] }
                    },
                    assignees: {
                        field: { id: 'id', name: 'user_full_name' },
                        title: 'Assignee',
                        available: [],
                        multiple: true,
                        filtered: { included: [], excluded: [] }
                    }
                },
                columnDefs: [
                    {
                        headerName: "SLO Name",
                        field: "slo_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Assessment Name",
                        field: "data_point_type_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Assignee",
                        field: "slo_user_display_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Approval User",
                        field: "slo_approval_user_display_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Start Date",
                        field: "slo_start_date",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Monitor Date",
                        field: "slo_monitor_date",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "End Date",
                        field: "slo_end_date",
                        sortable: true,
                        editable: false
                    }, {
                        sortable: false,
                        editable: false,
                        minWidth: 70,
                        maxWidth: 70,
                        cellRenderer: v => '<i style="cursor: pointer;" class="fe-grid-icon fas fa-chevron-right theme--light"></i>',
                        onCellClicked: v => this.sloSelected(v)
                    }
                ]
            }
        },
        computed: {
            currentYear () { return this.$store.state.global.sessionUser.currentYear },
            currentUser () { return this.$store.state.global.sessionUser.user },
            selectionTypes () {
                return Object.keys(this.selections).filter(type => {
                    return !this.selections[type].tab || this.selections[type].tab == this.tab.id
                })
            },
            selected () {
                let obj = {}
                this.selectionTypes.forEach(type => {
                    if (this.selections[type].filtered.included.length) {
                        obj[type] = (this.selections[type].multiple)
                            ? this.selections[type].filtered.included
                            : this.selections[type].filtered.included[0]
                    } else {
                        obj[type] = null
                    }
                })
                return obj
            }
        },
        methods: {
            clearFilter (filter) {
                this.selections[filter].filtered = { included: [], excluded: [] }
                this.$refs[`filter_${filter}`][0].clear()
            },
            loadItems (type, data) {
                let sel = null
                if (type == 'schoolYears') sel = this.currentYear
                if (type == 'assignees') sel = this.currentUser
                if ((type == 'assignees' && this.$hasSecurity('MANAGE_OTHERS_SLO_PLAN')) || type == 'schoolYears') this.selections[type].available = data
                if (sel) this.$nextTick(() => { this.$refs[`filter_${type}`][0].selectItem(sel, true) })
            },
            buildParams () {
                this.params = {
                    by_user: 1,
                    school_year_id: this.selected.schoolYears?.map(itm => itm.id).join(),
                    slo_user_user_id: this.selected.assignees?.map(itm => itm.id).join()
                }
            },
            sloSelected (v) {
                this.$emit('selected', v.data)
            },
            adjustGrid (e) {
                this.$nextTick(() => this.$refs.grid.resize())
            }
        },
        watch: {
            selected () {
                // The first time params are built should wait until the defaults are selected.
                if (this.params === null && (!this.selected?.assignees?.length || !this.selected?.schoolYears?.length)) return false
                this.buildParams()
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
