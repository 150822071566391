<template>
    <fe-dialog v-if="value"
        title="Subscribers"
        @close="$emit('input',false)"
        :width="500"
        :footer="false"
    >
        <fe-grid
            ref="grid"
            simpleInsert="id"
            simpleInsertPlaceholderText="Add User"
            displayDensity="medium"
            :rowData="rowData"
            :showToolbar="false"
            :columnDefs="columnDefs"
            domLayout="autoHeight"
            @simpleInsert="create"
            :simpleInsertConfig="{
                type:'combo',
                url:'users.php?action=get&active=1',
                itemText:'user_full_name',
                filter: function(x) {
                    if(!this.index) {
                        let index = {}
                        this.forEach(y => index[y.user_id]=true)
                        Object.defineProperty(this,'index',{value:index})
                    }
                    return !this.index[x.id]
                }
            }"
        />
        
        <fe-crud
            ref="crud"
            :defaultParams="{form_id:templateId}"
            :config="$models.formSubscriber"
            @read="rowData = $event"
            autoload
        />
    
    </fe-dialog>
</template>

<script>

export default {
    name: 'Subscribers',

    props: ['value', 'templateId'],
    
    methods: {
        create(e) {
            this.$refs.crud.create([{
                user_id: e.id,
                form_id: this.templateId,
            }]).finally(() => this.$refs.crud.read())
        },
    },
    
    data() {
        return {
            rowData: [],
            columnDefs: [
                { headerName:'User', field:'user_full_name' },
                this.$grid.deleteColumn(e => {
                    this.$confirmDelete(e.data, () => {
                        this.$refs.crud.destroy(e.data).finally(() => this.$refs.crud.read() )
                    })
                })
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
