<template>
    <fe-dialog
        title="climberCLOUD SFTP"
        width="80%"
        persistent
        v-model="localValue" v-if="localValue"
        acceptButtonText="Download"
        :acceptButtonDisabled="selections.length === 0"
        @dismiss="localValue=false"
        @close="localValue=false"
        @accept="download"
        disableAutoclose
    >
        <fe-crud
            ref="crud"
            autoload
            :config="$models.ftpUpload"
            :readParams="ftpParams"
            @read="v => items = v"
        />
        <fe-grid
            ref="grid"
            :rowData="items"
            :columnDefs="columns"
            domLayout="normal"
            class="flex-grow-1"
            displayDensity="small"
            disableInlineCreate
            style="height: 500px;"
            @selectionChanged="selectRow"
            :showAddRowBtn="false"
        >
            <template #toolbar-items>
                <v-checkbox label="Restrict to Existing" v-model="existingTypes"/>
                <v-checkbox label="Only New Records" v-model="unused"/>
            </template>
        </fe-grid>
    </fe-dialog>
</template>

<script>
    export default {
        name: 'CloudFTP',
        props: ['value'],
        data() {
            return {
                selections: [],
                localValue: false,
                items: [],
                uploadTypes: [],
                ready: false,
                existingTypes: true,
                unused: true
            }
        },
        watch: {
            value(v) {
                this.localValue = v
            },
            localValue(v) {
                this.$emit('input', v)
            },
            ftpParams(v) {
                this.$refs.crud.read(v)
            }
        },
        computed: {
            ftpParams() {
                return {action: 'get_from_cloud', existing_upload_types: this.existingTypes ? 1 : 0, unused: this.unused ? 1 : 0 }
            },
            columns() {
                let me = this
                return this.ready ? [this.$grid.checkColumn(null, true), {
                    headerName: 'Name',
                    field: 'description',
                    flex: 1,
                    minWidth: 300
                }, {
                    headerName: 'Upload Type',
                    field: 'upload_type_name',
                    maxWidth: 300,
                    minWidth: 200
                    // cellRenderer(v) {
                    //     let r = me.uploadTypes.find(tp => tp.id == v.value)
                    //     if (r) return r.name
                    //     console.warn('cannot find', v.value, me.uploadTypes)
                    //     return ''
                    // }
                }, {
                    headerName: 'File Path',
                    field: 'ftp_path',
                    flex: 1,
                    minWidth: 200
                }, {
                    headerName: 'File Pattern',
                    field: 'file_pattern',
                    minWidth: 100
                }, {
                    headerName: 'Override Path',
                    field: 'override_ftp_dir',
                    flex: 1,
                    minWidth: 200
                }] : []
            }
        },
        created() {
            this.$modelGet('uploadType', { all: 1 }).then(uploadTypes => {
                this.uploadTypes = uploadTypes
                this.ready = true
            })
        },
        mounted() {
            this.localValue = this.$props.value
        },
        methods: {
            selectRow(row) {
                this.selections = this.$refs.grid.gridApi.getSelectedRows()
            },
            download() {
                this.$axios.post('ftpUploads.php?action=copy_from_cloud', { ftp: this.selections })
                    .then(response => {
                        this.$ecResponse(response)
                        this.$refs.crud.read()
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
