import Vue from "vue";

export default Vue.extend({
    template: `<div>
                    <v-checkbox 
                        v-model="value" 
                        color="#050F2D" 
                        hide-details 
                        :ripple="false" 
                        class="smartforms-student-list-select-all">
                    </v-checkbox>
               </div>`,
    data() {
        return {
            value: false,
        }
    },
    watch: {
        value(v) {
            this.params.selectAll(v)
        },
    },
    mounted() {
        this.value = this.params.selectAllCheckbox
    },
})
