<template>
    <div class="flex-fill flex-column">
        <fe-mask v-show="mergeMask" :showLoader="true" />
        <div>
            <fe-title-select
                v-if="groups.length > 0 && selectedGroup"
                placeholder="Select an Assessment Group"
                :items="groups"
                v-model="selectedGroup"
            ></fe-title-select>
        </div>
        <fe-crud-grid
            ref="grid"
            :config="$models.subcategory"
            :readParams="{
                with_alias_count: 1,
                data_point_type_id: this.group.id,
                with_school_count: 1,
            }"
            :destroyParams="{
                confirm: 'true'
            }"
            :columnDefs="columnDefs"
            :insertDefaults="{
                created_by: null,
                data_point_type_id: group.id
            }"
            :frameworkComponents="frameworkComponents"
            style="height: 100%;"
            refresh-on-created
            refresh-on-config
            disable-inline-create
            @beginCreate="create"
            @read="assessments = $event"
            @selectionChanged="selectionChanged"
            @cellValueChanged="onCellChange"
            @updated="$emit('reloadCurrent')"
            @destroyed="$emit('reloadCurrent')"
            @created="$emit('reloadCurrent')"
            :flexColumns="false"
            :disableResizeOnUpdate="true"
        >
            <template v-slot:selection-tools>
                <fe-btn
                    usage="ghost"
                    useIcon="far fa-copy"
                    whiteText
                    @click="duplicating = true"
                    class="fe-grid-action-btn"
                    :disabled="selections.length != 1"
                >
                    Duplicate
                </fe-btn>
                <fe-btn
                    usage="ghost"
                    useIcon="far fa-code-merge"
                    whiteText
                    @click="merging = true"
                    class="fe-grid-action-btn"
                >
                    Merge
                </fe-btn>
                <fe-btn
                    usage="ghost"
                    useIcon="fal fa-bolt"
                    whiteText
                    @click="assigning = true"
                    class="fe-grid-action-btn"
                >
                    Assign
                </fe-btn>
                <fe-btn
                    useIcon="fal fa-trash"
                    usage="ghost"
                    whiteText
                    @click="deleteSelection"
                    class="fe-grid-action-btn"
                >
                    Delete
                </fe-btn>
                <fe-btn
                    usage="ghost"
                    whiteText
                    @click="cancelSelection"
                    class="fe-grid-action-btn-last"
                >
                    Cancel
                </fe-btn>
            </template>
            <template v-slot:cellDialog="{ cell }">
                <simple-grid-dialog-column
                    v-if="aliasCell"
                    :cell="cell"
                    title="Aliases"
                    field="alias_cd"
                    fieldHeader="Alias"
                    fieldPlaceholder="Add an Alias"
                    :crudConfig="$models.subcategoryAlias"
                    :fieldCounter="255"
                    :insertDefaults="{
                        sub_category_id: cell.data.id
                    }"
                    :readParams="{
                        sub_category_id: cell.data.id
                    }"
                    :destroyParams="{
                        sub_category_id: cell.data.id
                    }"
                    @close="$refs.grid.$refs.grid.openMenu = false"
                    @read="updateAliasCount"
                    @cellValueChanged="aliasUpdated"
                />
                <simple-grid-dialog-column
                    v-else-if="schoolCell"
                    ref="crudAssmt"
                    :cell="cell"
                    title="Schools"
                    :crudConfig="subCategoryConfig"
                    :onRead="detailRead"
                    :columnDefs="detailColumnDefs"
                    :insertDefaults="{sub_category_id: cell.data.id}"
                    :destroyParams="{school_id_action: 'remove'}"
                    :updateParams="{school_id_action: 'update'}"
                    :createParams="{
                        id: cell.data.id,
                        school_id_action: 'add',
                    }"
                    :elProps="{
                        disableInlineCreate: true,
                        simpleInsertDisabled: true
                    }"
                    @close="$refs.grid.$refs.grid.openMenu = false"
                    @read="updateSchoolsCount"
                    @cellValueChanged="schoolsUpdated"
                >
                    <template v-slot:belowGrid>
                        <div class="d-flex mt-1">
                            <div class="flex-grow-1">
                                <v-select
                                    flat solo dense
                                    multiple
                                    :items="schoolList"
                                    item-text="name"
                                    item-value="id"
                                    style=" margin-top: 6px; width: 260px;"
                                    v-model="addSchoolId"
                                />
                            </div>
                            <div class="flex-shrink-1 text-xs-right">
                                <fe-btn
                                    :disabled="!addSchoolId"
                                    @click="addSchool()"
                                >Add</fe-btn>
                            </div>
                        </div>
                    </template>
                </simple-grid-dialog-column>
            </template>
        </fe-crud-grid>

        <fe-crud
            ref="crudSchools"
            autoload
            :config="$models.school"
            @read="school_id = $event"
        />

        <fe-dialog
            v-if="assigning"
            @close="assigning = false"
            @dismiss="assigning = false"
            @accept="assign"
            title="Quick Assign Assessments"
            acceptButtonText="Apply"
            dismissButtonText="Cancel"
            :acceptButtonDisabled="!assignValid"
        >
            <v-form @submit.prevent v-model="assignValid" ref="assign">
                <div class="d-flex flex-column">
                    <template v-if="assessments.length > 0">
                        <fe-label>Parent Assessment</fe-label>
                        <v-select
                            v-model="assignObj.parent_id"
                            flat solo dense
                            :items="assessments"
                            itemText="name"
                            itemValue="id"
                            clearable
                        />
                    </template>
                    <template v-if="categories.length > 0">
                        <fe-label>Category</fe-label>
                        <v-select
                            v-model="assignObj.category_id"
                            flat solo dense
                            :items="categories"
                            itemText="name"
                            itemValue="id"
                            clearable
                        />
                    </template>
                    <template v-if="calcTypes.length > 0">
                        <fe-label>Calculation Type</fe-label>
                        <v-select
                            v-model="assignObj.score_calc_type_id"
                            flat solo dense
                            :items="calcTypes"
                            itemText="code"
                            itemValue="id"
                            clearable
                        />
                    </template>
                    <template v-if="assessmentTypes.length > 0">
                        <fe-label>Assessment Type</fe-label>
                        <v-select
                            v-model="assignObj.assessment_type_id"
                            flat solo dense
                            :items="assessmentTypes"
                            itemText="name"
                            itemValue="id"
                            clearable
                        />
                    </template>
                    <template>
                        <fe-label>Schools <fe-info-tip class="ml-1" style="display:inline-block" tooltip="School assignment only applies to assessment types that use or include Progress Monitoring."/></fe-label>
                        <div v-if="checkSelectionType.length > 0" class="msg">One or more selected assessments does not use or include Progress Monitoring.</div>
                        <v-select
                            v-else
                            v-model="assignObj.school_id"
                            flat solo dense
                            multiple
                            :items="schoolList"
                            itemText="name"
                            itemValue="id"
                            clearable
                            :disabled="checkSelectionType.length > 0"
                        />
                    </template>
                    <fe-label>Include Empty Values</fe-label>
                    <v-switch v-model="assignEmpty" />
                </div>
            </v-form>
        </fe-dialog>

        <fe-dialog
            v-if="merging"
            @close="merging = false"
            @dismiss="merging = false"
            @accept="merge"
            title="Merge Assessments"
            acceptButtonText="Apply"
            dismissButtonText="Cancel"
            :acceptButtonDisabled="!mergeValid"
        >
            <v-form @submit.prevent v-model="mergeValid" ref="merge">
                <div class="d-flex flex-column">
                    <template v-if="assessments.length > 0">
                        <fe-label>Merge to Assessment</fe-label>
                        <fe-remote-combo
                            v-model="mergeId"
                            flat solo dense
                            :items="mergeAssessments"
                            itemText="name"
                            itemValue="id"
                            clearable
                            :rules="[(v) => !!v || 'Must select an assessment to merge to.']"
                        >
                            <template slot="item" slot-scope="data">
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.name" />
                                    <v-list-item-subtitle>
                                        {{data.item.parent_name}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </fe-remote-combo>
                    </template>
                </div>
            </v-form>
        </fe-dialog>

        <fe-dialog
            v-if="duplicating"
            @close="duplicating = false"
            @dismiss="duplicating = false"
            @accept="duplicate"
            title="Duplicate Assessment"
            acceptButtonText="Apply"
            dismissButtonText="Cancel"
            :acceptButtonDisabled="!duplicateValid"
        >
            <v-form @submit.prevent v-model="duplicateValid" ref="duplicate">
                <div class="d-flex flex-column">
                    <fe-label>New Assessment Name</fe-label>
                    <v-text-field
                        v-model="duplicateName"
                        flat solo dense
                        :style="{ width: '100%' }"
                        :rules="rules.duplicate.name"
                    />
                </div>
            </v-form>
        </fe-dialog>

        <fe-dialog
            v-if="recalcDialog.show"
            @close="recalcDialog.show = false"
            @dismiss="recalcDialog.show = false"
            @accept="doRecalc"
            title="Run Recalculate Scores"
            acceptButtonText="Apply"
            dismissButtonText="Cancel"
            :acceptButtonDisabled="!mergeValid"
        >
            <v-form @submit.prevent v-model="mergeValid" ref="merge">
                <div class="d-flex flex-column">
                    <fe-label>For Assessment</fe-label>
                    <v-select
                        v-model="recalcDialog.subCategoryId"
                        flat solo dense
                        :items="recalcDialog.items"
                        itemText="name"
                        itemValue="id"
                    />
                </div>
            </v-form>
        </fe-dialog>
    </div>
</template>

<script>
    import AssessmentTitle from "./renderers/AssessmentTitleRenderer.js"
    import AssessmentCreator from "./AssessmentCreator"
    import SimpleGridDialogColumn from "@/components/common/SimpleGridDialogColumn"
    import {mapState} from "vuex";

    export default {
        name: 'AssessmentGroupAssessments',
        props: {
            group: { type: Object, default: () => {}, required: true },
            groups: { type: Array, default: () => [] },
            calcTypes: { type: Array, default: () => [], required: true },
            categories: { type: Array, default: () => [], required: true },
            assessmentTypes: { type: Array, default: () => [], required: true }
        },
        components: {
            SimpleGridDialogColumn,
            AssessmentCreator
        },
        data() {
            let me = this
            return {
                mergeMask: false,
                merging: false,
                mergeValid: true,
                mergeId: null,
                assigning: false,
                assignValid: true,
                assignEmpty: false,
                assignObj: {
                    parent_id: null,
                    category_id: null,
                    score_calc_type_id: null,
                    assessment_type_id: null,
                    school_id: null,
                },
                duplicating: false,
                duplicateValid: true,
                duplicateName: null,
                rules: {
                    duplicate: {
                        name: [
                            v => (!!v && v.trim().length > 0) || 'Please enter a name'
                        ]
                    }
                },
                aliases: null,
                aliasCell: false,
                schoolCell: false,
                cell: null,
                frameworkComponents: {
                    AssessmentTitle: AssessmentTitle
                },
                assessments: null,
                selections: [],
                recalcDialog: {
                    show: false,
                    subCategoryId: null,
                    rec: null,
                    items: [],
                    pollingTimerId: null,
                },
                addSchoolId: null,
                schoolsCnt: 0,
                school_id: null,
                subcategorySchools: [],
                subcategorySchoolNames: [],
                fieldConfig: {
                    simpleInsertDisabled: true
                },
                detailColumnDefs: [
                    {
                        headerName: 'School Name',
                        field: 'school_name'
                    }
                ],
            }
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            subCategoryConfig () {
                let config = _.cloneDeep(this.$models.subCategorySchoolGet)
                config.read.params.sub_category_id = this.cell.data.id
                config.read.params.property = 'SubCategorySchool'

                return config
            },
            selectionIds () { return this.selections.map(i => i.id) },
            mergeAssessments () {
                return this.assessments.filter(i => !this.selectionIds.includes(i.id))
            },
            selectedGroup: {
                get () { return this.group },
                set (v) { this.$emit('switchGroup', v) }
            },
            schoolList() {
                if (this.subcategorySchools?.length ) {
                    this.subcategorySchoolNames = []
                    let arr = this.subcategorySchools.map(s => s.school_id)
                    return this.school_id.filter(school => {
                        if (arr.includes(school.id)) this.subcategorySchoolNames.push(school)
                        return !arr.includes(school.id)
                })
                } else {
                    return this.school_id
                }

            },
            valuesLoaded () { return !!this.calcTypes && !!this.categories && !!this.assessments && !!this.assessmentTypes },
            checkSelectionType () {
                return this.selections.filter(a => {
                    if (a.assessment_type_code === 'STANDARD') return true
                })
            },
            columnDefs () {
                let me = this
                let cols = !this.valuesLoaded ? [] : [
                    {
                        colId: "checkbox_column",
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        width: 70,
                        minWidth: 70,
                        pinned: "left"
                    },
                    {
                        headerName: "Assessment Names",
                        field: "name",
                        sortable: true,
                        editable: true,
                        pinned: "left",
                        minWidth: 300,
                        maxWidth: 400,
                        cellRenderer: "AssessmentTitle",
                        cellRendererParams: { countField: "child_cnt" },
                        cellStyle: {
                            whiteSpace: 'normal',
                        },
                    },
                    {
                        headerName: "Parent Name",
                        field: "parent_id",
                        minWidth: 150,
                        sortable: true,
                        editable: true,
                        valueGetter: v => me.findLabel(me.assessments, v.data.parent_id, 'id', 'name'),
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.parent_id,
                                rowDataKey: "parent_id",
                                mode: "edit",
                                items: me.assessments,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true,
                                excludeRowKey: 'id'
                            }
                        }
                    },
                    {
                        headerName: "Category",
                        field: "category_id",
                        sortable: true,
                        editable: true,
                        valueGetter: v => me.findLabel(me.categories, v.data.category_id, 'id', 'name'),
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.category_id,
                                rowDataKey: "category_id",
                                mode: "edit",
                                items: me.categories,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true
                            }
                        }
                    },
                    {
                        colId: "score_calc_type_id", // Explicit colId to prevent ag-grid trailing _1 bug
                        headerName: "Calculation",
                        field: "score_calc_type_id",
                        sortable: true,
                        editable: true,
                        valueGetter: v => me.findLabel(me.calcTypes, v.data.score_calc_type_id, 'id', 'code'),
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams:  v => {
                            let calcTypes = me.calcTypes.filter((calcType) => !calcType.data_point_type_id || calcType.data_point_type_id == this.group.id)
                            return {
                                value: v.data.score_calc_type_id,
                                rowDataKey: "score_calc_type_id",
                                mode: "edit",
                                items: calcTypes,
                                keyProp: "id",
                                labelProp: "code",
                                disableChips: true
                            }
                        }
                    },
                    {
                        headerName: "Calc Weight",
                        field: "score_calc_weight",
                        sortable: true,
                        editable: true
                    },
                    {
                        headerName: "Type",
                        field: "assessment_type_id",
                        sortable: true,
                        editable: true,
                        valueGetter: v => me.findLabel(me.assessmentTypes, v.data.assessment_type_id, 'id', 'name'),
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.assessment_type_id,
                                rowDataKey: "assessment_type_id",
                                mode: "edit",
                                items: me.assessmentTypes,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true
                            }
                        }
                    },
                    {
                        headerName: "Score Display",
                        headerTooltip: "When enabled, the score display will be used for score reporting",
                        headerComponentParams: { template: '<div>Score Display <i class="far fa-info-circle"></i></div>' },
                        field: "score_alpha_map_flag",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: 'FeGridToggle',
                    },
                    {
                        headerName: "Top Level",
                        headerTooltip: "Displays data in a Chart, Data Wall, and tile on a student page",
                        headerComponentParams: { template: '<div>Top Level <i class="far fa-info-circle"></i></div>' },
                        field: "actual_top_level_flag",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: 'FeGridToggle',
                    },
                    {
                        headerName: "KPI",
                        field: "kpi",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: 'FeGridToggle'
                    },
                    {
                        headerName: "Aliases",
                        field: "alias_cnt",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: 'FeDialogColumn',
                        onCellClicked: (v) => {
                            this.schoolCell = false,
                            this.aliasCell = true,
                            this.cellClicked(v)
                        }
                    },
                    {
                        headerName: "Schools",
                        headerTooltip: "School assignment only applies to assessment types that use or include Progress Monitoring.",
                        headerComponentParams: { template: '<div>Schools <i class="far fa-info-circle"></i></div>' },
                        field: "school_cnt",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: 'FeDialogColumn',
                        tooltipValueGetter: function(v) { return v.data.assessment_type_code === 'STANDARD' ? 'School assignment only applies to assessment types that use or include Progress Monitoring.' : null },
                        onCellClicked: (v) => {
                            if (v.data.assessment_type_code !== 'STANDARD') {
                                this.aliasCell = false
                                this.schoolCell = true
                                this.cellClicked(v)
                            } else {
                                this.$snackbars.$emit('new', {
                                    text: 'School assignment only applies to assessment types that use or include Progress Monitoring.',
                                    usage: 'warning',
                                    timeout: 8000
                                })
                            }
                        },
                    },
                    {
                        headerName: "Order",
                        field: "rank",
                        sortable: true,
                        editable: true,
                        cellEditorParams: {
                            type: 'number'
                        }
                    },
                    {
                        headerName: "Score Editor Tag",
                        field: "score_editor_tag",
                        sortable: true,
                        editable: false,
                        hide: true
                    }
                ]

                // A known ag-grid bug can result in the `colId` value having a '_1' suffix appended to it when column defs are changed.
                // The recommended workaround is to explicitly specific a colId value within column defs.
                // Reference:  https://github.com/ag-grid/ag-grid/issues/2889#issuecomment-514347233
                cols = cols.map(itm => ({
                    ...itm,
                    colId: itm.colId || itm.field || undefined,
                }))

                return cols
            }
        },
        beforeUnmount() {
            if (this.recalcDialog.pollingTimerId) {
                clearInterval(this.recalcDialog.pollingTimerId)
                this.recalcDialog.pollingTimerId = null
            }
        },
        methods: {
            aliasUpdated(params) {
                params.crud.update(params.data)
            },
            updateAliasCount(items) {
                this.$refs.grid.updateCachedRecords({
                    id: this.cell.data.id,
                    alias_cnt: items.length
                })
                this.refreshCrudGridCells()
                this.$nextTick(() => {
                    if (this.$refs.grid.$refs.grid.filterText?.length) this.$refs.grid.$refs.grid.filterResults()
                })
            },
            schoolsUpdated(params) {
                params.crud.update(params.data)
            },
            updateSchoolsCount(item) {
                if (item.length !== this.schoolsCnt) {
                    this.subcategorySchools = item
                    this.$refs.grid.updateCachedRecords({
                        id: this.cell.data.id,
                        school_cnt: item.length
                    })
                    this.schoolsCnt = item.length
                    this.refreshCrudGridCells()
                    this.$nextTick(() => {
                        if (this.$refs.grid.$refs.grid.filterText?.length) this.$refs.grid.$refs.grid.filterResults()
                    })
                }
            },
            addSchool() {
                let p = {
                    subcategories: [{
                        school_id_action: 'add',
                        school_id: this.addSchoolId,
                        id: this.cell.data.id
                    }
                ]}
                this.$axios.post('subcategories.php?action=update&', JSON.stringify(p))
                .then((result) => {
                    if (result) {
                        this.$refs.crudAssmt.reload()
                        this.$refs.grid.updateCachedRecords({
                            id: this.cell.data.id,
                            school_id: this.subcategorySchools.length
                        })
                    }
                })
                .finally(() => {
                    // reset select component
                    this.addSchoolId = null
                })
            },
            cellClicked(cell) {
                if ((this.sessionUser.security_codes.includes('MANAGE_DISTRICT') && cell.column.colId === 'school_cnt') || cell.column.colId !== 'school_cnt') {
                    this.cell = cell
                    this.$refs.grid.$refs.grid.setDialogCell(cell)
                } else {
                    this.$snackbars.$emit('new', {
                        text: 'You do not have permission to add or edit school assignments.',
                        usage: 'warning',
                        timeout: 8000
                    })
                }
            },
            findLabel(collection, value, valueProp, labelProp) {
                let lbl = ''
                if (collection && value) {
                    let sel = collection.find(x => x[valueProp] == value)
                    if (sel) lbl = sel[labelProp]
                }
                return lbl
            },
            create() {
                this.$emit('createAssessment', this.group)
            },
            async onCellChange(params) {
                if (params.oldValue === params.newValue) {
                    return // Ignore bogus update event from ag grid
                } else if (!params.newValue) {
                    return // Don't offer recalc if they remove value (can we respond at all to this scenario?)
                }

                let colId = params.column?.colId
                if (colId === 'score_calc_type_id') {
                    // If user changes calc type, we won't offer them any recalc
                    // option if there are no scores to recalc (i.e. why bother?)
                    let rec = await this.$axios.get(`subcategories.php?action=get&property=score_count&id=${params.data.id}`).then(res => res.data);
                    if (rec.count !== 0) {
                        this.recalcDialog.show = true
                        this.recalcDialog.rec = params.data
                        this.recalcDialog.subCategoryId = params.data.id
                        this.recalcDialog.items = [{ id: params.data.id, name: params.data.display_name }]
                    }
                }

                this.$emit('reloadCurrent')
            },
            refresh() {
                if (this.$refs.grid) this.$refs.grid.$refs.crud.read()
            },
            refreshCrudGridCells (ref='grid') {
                try {
                    this.$refs[ref].$refs.grid.gridApi.refreshCells()
                } catch (err) {
                    console.warn({ err: err })
                }
            },
            cancelSelection () {
                this.$refs.grid.$refs.grid.editCancel()
            },
            deleteSelection () {
                this.$refs.grid.$refs.grid.deleteRows()
            },
            selectionChanged (params) {
                this.selections = params.api.getSelectedRows()
            },
            assign () {
                let obj = {}
                Object.keys(this.assignObj).forEach(k => {
                    if (this.assignObj[k] || this.assignEmpty) obj[k] = this.assignObj[k]
                })
                if (Object.keys(obj).length && this.selections.length && this.$refs.assign.validate()) {
                    let updates = []
                    this.selections.forEach(row => {
                        updates.push(Object.assign(row, obj))
                    })
                    this.$refs.grid.updateCachedRecords(updates)
                    this.$refs.grid.$refs.crud.update(updates).then(rsp => {
                        this.$refs.grid.$refs.crud.read()
                    })
                }
                this.assigning = false
            },
            merge () {
                this.mergeMask = true
                if (this.selections.length && this.$refs.merge.validate()) {
                    let updates = []
                    this.selections.forEach(row => {
                        updates.push(Object.assign(row, {
                            merge_to_sub_category_id: this.mergeId.id
                        }))
                    })
                    this.$refs.grid.$refs.crud.update(updates).then(() => {
                        this.$refs.grid.$refs.crud.read()
                    }).finally(() => {
                        this.mergeMask = false
                    })
                }
                this.merging = false
            },
            duplicate () {
                if (this.selections.length == 1 && this.$refs.duplicate.validate()) {
                    this.$refs.grid.$refs.crud.create(Object.assign({}, this.selections[0], {
                        id: null,
                        name: this.duplicateName
                    }))
                }
                this.duplicating = false
            },
            async doRecalc() {
                if (this.recalcDialog.pollingTimerId) {
                    clearInterval(this.recalcDialog.pollingTimerId)
                }

                let qs = this.$objectToParams({
                    property: 'recalc',
                    id: this.recalcDialog.rec.id,
                    data_point_type_id: this.recalcDialog.rec.data_point_type_id,
                })

                await this.$axios.get(this.$models.getUrl('subcategoryRecalc', 'read') + '&' + qs)
                    .then(res => {
                        if (res.data?.success) {
                            this.$snackbars.$emit('new', { text: res.data.msg , usage: 'success' })
                        }
                    })
                    .catch(err => {
                        this.$snackbars.$emit('new', { text: 'Unable to schedule recalculation.  Please try again.', usage: 'error' })
                    })

                this.recalcDialog.pollingTimerId = setInterval(async () => {
                    let res = await this.$axios.get(`subcategories.php?action=get&id=${this.recalcDialog.rec.id}`)
                    let records = this.$ecResponse(res, 'subcategories')
                    if (records.length) {
                        // Once we get back a record for this assessment indicating that it's no
                        // longer queued, we can stop polling and show the user a quick confirmation
                        if (!records[0].is_queued_recalc) {
                            clearInterval(this.recalcDialog.pollingTimerId)
                            this.$snackbars.$emit('new', { text: `Recalculation complete for ${records[0].display_name}`, usage: 'success' })
                        }
                    }
                }, 5000)

                this.recalcDialog.show = false
            },
            detailRead(data) {
                return data.map(x => {
                    let school = this.school_id.filter(y => y.id === x.school_id)
                    if (!school || school.length === 0) {
                        x.school_name = 'Restricted by Permission'
                    } else {
                        x.school_name = school[0].name
                    }
                    return x
                })
            },
        },
        watch: {
            assigning (v) { if (v) Object.keys(this.assignObj).forEach(k => this.assignObj[k] = null) },
            merging (v) { this.mergeId = null },
            duplicating (v) {
                if (v) {
                    this.duplicateName = `${this.selections[0].name} (copy)`
                }
            }
        }
    }
</script>

<style lang="scss">
.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.name-truncated-text-renderer {
    display: flex;
    &-toggle { display: none; }
    &-text { display: block; }
    &.truncatable {
        width: 100%;
        .name-truncated-text-renderer-toggle {
            display: block;
        }
    }
    &.is-truncated {
        text-overflow: ellipsis;
    }
}
.ai-name-column-wrapper {
    height: 25px;

    .ai-show-description {
        cursor: pointer;
        color: #006C90;
        text-decoration-line: underline;
    }
    .ai-name-column-value {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}
.msg {
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 10px;
}
</style>
