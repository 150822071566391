<template>
    <div class="flex-fill flex-column">
        <fe-grid ref="grid" style="height:100%; margin: 16px;"
            :columnDefs="columnDefs"
            :rowData="docs"
            :showAddRowBtn="false"
            domLayout="normal"
            displayDensity="medium"
            @rowDoubleClicked="loadDoc"
            v-show="!selectedDoc"
        >
        </fe-grid>

        <div v-show="selectedDoc" v-if="selectedDoc" class="pa-4 flex-fill flex-column">
            <div class="d-flex">
                <fe-btn useIcon="fas fa-chevron-left" usage="ghost" @click="selectedDoc=null">Back</fe-btn>
                <div class="page-title">{{selectedDoc.name}}</div>
            </div>

            <div v-if="!selectedDoc.html" class="flex-fill flex-column">
                <iframe :src="selectedDoc.path" width="100%" height="100%" frameborder=0>
                </iframe>
            </div>

            <div v-else>
                <div v-html="selectedDoc.html" style="margin: 60px; auto 0px auto; text-align: center;"/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UserGuides',
        data() {
            return {
                docs: [],
                selectedDoc: null,
                columnDefs: [{
                    headerName: 'Guide',
                    field: 'name',
                    autoHeight: true,
                    // field: 'name',
                    minWidth: 200,
                    flex: 2,
                    sortable: true,
                    cellRenderer(v) {
                        if (!v.data) return
                        let icon = ''
                        let rec = v.data

                        if (!rec.html) {
                            icon = 'fas fa-file-pdf';
                        } else {
                            icon = 'fas fa-file-video';
                        }

                        return `<i class="${icon}" style="margin-right: 16px; font-size: 16px"></i>` + v.data.name + '<div class="fe-grid-cell-subtext">' + rec.description + '</div>'
                    }
                }, {
                    headerName: 'Section',
                    field: 'section',
                    flex: 1,
                    hide: true,
                    rowGroup: true,
                    sortable: true
                }, {
                    headerName: 'Description',
                    field: 'description',
                    hide: true
                }]
            }
        },
        mounted() {
            this.loadData()
        },
        methods: {
            loadData() {
                this.$modelGet('helpDocs')
                    .then(response => {
                        let server = location.hostname
                        let host = './'
                        if ( server.indexOf('.com') !== -1) {
                            host = 'https://app.educlimber.com/'
                        } else if (server.indexOf('.app') !== -1) {
                            host = 'https://qa01.educlimber.app/'
                        }
                        this.docs = response.map(item => {
                            item.path = item.path.replace(/\.\//, host)
                            return item
                        })
                    })
                    .catch(err => {
                        console.warn('Unable to load help documents', err)
                    })
            },
            loadDoc(item) {
                if (item.data) {
                    this.selectedDoc = item.data
                }

            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
