<template>
    <v-form ref="form" v-model="valid">
        <v-layout row wrap my-2 v-if="this.model">
            <v-flex xs6 class="px-1">
                <fe-label content="Assessment"/>
                <v-autocomplete
                    v-model="model.data_point_type_id"
                    :items="dataPointTypes"
                    :rules="requiredRules"
                    item-value="id"
                    item-text="name"
                    flat solo dense
                    required
                />
            </v-flex>

            <v-flex xs6 class="px-1">
                <fe-label content="Score Detail"/>
                <v-autocomplete
                    v-if="scoreDetailTypes.length > 0"
                    v-model="model.score_detail_type_id"
                    :items="scoreDetailTypes"
                    item-value="id"
                    item-text="code"
                    flat solo dense
                    required
                    clearable
                />

                <v-text-field
                    v-else
                    value="N/A"
                    flat solo dense
                    readonly
                />
            </v-flex>

            <v-flex xs12 class="px-1" v-if="subcategories.length > 0">
                <FieldGroup groupTitle="Subtest Name">
                    <ItemSelector
                        :collection="subcategories"
                        :selectedKeys="subcategoryValues"
                        label="Selections"
                        keyProp="id"
                        labelProp="display_name"
                        untruncated
                        multiple
                        @updates="updateSubcategories"
                    />
                </FieldGroup>
            </v-flex>

            <v-flex xs12 class="pa-1">
                <FieldGroup groupTitle="Threshold Statement">
                    <fe-label content="Will"/>
                    <v-select
                        v-model="model.operator_cd"
                        item-value="value"
                        item-text="text"
                        :items="cdOperatorsFiltered"
                        :rules="operatorRules"
                        flat solo dense
                    />

                    <v-select
                        v-model="model.value_type_cd"
                        :items="cdValueTypesFiltered"
                        :rules="valueTypesRules"
                        item-value="value"
                        item-text="text"
                        flat solo dense
                    />

                    <template>
                        <v-text-field
                            v-if="onlyNumericValues"
                            v-model="model.value"
                            :rules="numericInputRules"
                            type="number"
                            flat solo dense
                            required
                        />

                        <v-autocomplete
                            v-else
                            v-model="formattedPrimaryValue"
                            :rules="requiredRules"
                            :items="valueItems"
                            flat solo dense
                            required
                        />
                    </template>

                    <span v-if="model.operator_cd === 'BETWEEN'">
                        <fe-label content="and"/>
                        <template>
                            <v-text-field
                                v-if="onlyNumericValues"
                                v-model="formattedSecondaryValue"
                                type="number"
                                flat solo dense
                                required
                                :rules="numericInputRules"
                            />

                            <v-autocomplete
                                v-else
                                v-model="formattedSecondaryValue"
                                :rules="requiredRules"
                                :items="valueItems"
                                flat solo dense
                                required
                            />
                        </template>
                    </span>
                </FieldGroup>
            </v-flex>

            <v-flex xs12 class="px-1">
                <FieldGroup groupTitle="Windows">
                    <AssessmentWindows
                        ref="windows"
                        :same="!isIncrOrDecr(model.operator_cd)"
                        :windows="selections.windows"
                        :dataPointNames="dataPointNames"
                        :errors="windowErrors"
                        @removeWindow="removeWindow"
                        @addWindow="addWindow"
                    />
                </FieldGroup>
            </v-flex>
        </v-layout>

        <fe-dialog
            v-if="operatorChange.message"
            dismissButtonText="Cancel"
            acceptButtonText="Okay"
            title="Change Threshold Operator?"
            :body="operatorChange.message"
            @dismiss="cancelOperatorChange"
            @accept="processOperatorChange"
        />
    </v-form>
</template>

<script>
import axios from 'axios'
import Api from '../services/Api'
import BaseService from '../services/BaseService'
import ItemSelector from '../controls/ItemSelector'
import AssessmentWindows from './AssessmentWindows'
import FieldGroup from '../templates/FieldGroup'

export default {
    name: 'AssessmentMetricFields',

    components: {
        ItemSelector,
        AssessmentWindows,
        FieldGroup
    },

    props: {
        obj: Object
    },

    data() {
        return {
            valid: false,
            enableWindowValidation: true,
            operatorChange: {
                message: null,
                prev: null
            },
            model: {
                data_point_type_id: null,
                operator_cd: "DECR",
                score_detail_type_id: null,
                secondary_value: "0",
                value: 10,
                value_type_cd: "PERCENT"
            },
            loading: false,
            cdValueTypes: [
                {value: 'PERCENT', text: 'a percent of'},
                {value: 'POINTS', text: 'a value of'}
            ],
            cdOperators: [
                {value: 'INCR', text: 'Increase by'},
                {value: 'DECR', text: 'Decrease by'},
                {value: 'GREATER', text: 'Be Greater Than'},
                {value: 'GREATER=', text: 'Be Greater Than or Equal to'},
                {value: 'LESS', text: 'Be Less Than'},
                {value: 'LESS=', text: 'Be Less Than or Equal to'},
                {value: 'BETWEEN', text: 'Be Between'},
                {value: 'EQUAL', text: 'Be Equal To'}
            ],
            endpoints: {
                subcategories: 'assessmentThresholdSubCategory.php',
                windows: 'assessmentThresholdDataPointName.php'
            },
            selections: {
                subcategories: [],
                windows: []
            },
            isRestrictingScoreDisplay: false,
            dataPointTypes: [],
            scoreDetailTypes: [],
            scoreDetailTypeOptions: [],
            subcategories: [],
            dataPointNames: [],
            numberRules: [
                v => (!!v && !isNaN(v)) || 'Value is required',
                v => parseFloat(v) >= 0 || 'Value must be 0 or more',
                v => parseFloat(v) <= 9999999.999 || 'Value must be less than 10,000,000'
            ],
            percentRules: [
                v => (!!v && !isNaN(v)) || 'Value is required',
                v => parseFloat(v) >= 0 || 'Value must be 0 or more',
                v => parseFloat(v) <= 100 || 'Value must be 100 or less'
            ],
            requiredRules: [
                v => !!v || v === 0 || 'Value is required',
            ],
            operatorRules: [
                v => !!v || 'Selection is required',
                v => this.cdOperatorsFiltered.map(x => x.value).indexOf(v) > -1 || 'Selection is required',
            ],
            valueTypesRules: [
                v => !!v || 'Selection is required',
                v => this.cdValueTypesFiltered.map(x => x.value).indexOf(v) > -1 || 'Selection is required',
            ],
            alphaMap: [],
            windowErrors: []
        }
    },

    computed: {
        isValid() {
            return this.valid
        },

        saveData() {
            return this.model
        },

        dataPointTypeId() {
            return this.model.data_point_type_id
        },

        subcategoryValues() {
            return this.selections.subcategories.map(itm => itm.sub_category_id)
        },

        onlyNumericValues() {
            // No matter what, conditions of "increase by," "by percent," etc.
            // require numeric values (e.g. you can't increase by an "A+" percent)
            if (
                this.model.value_type_cd === 'PERCENT' ||
                this.model.operator_cd === 'INCR' ||
                this.model.operator_cd === 'DECR'
            ) {
                return true
            }

                // When using a score detail, we can show a dropdown with
            // predefined options if they exist
            else if (this.model.score_detail_type_id) {
                return (this.filteredScoreDetailTypeOptions.length === 0)
            }

            // If not using score detail, then we will show a dropdown only
            // if we have alpha maps available for the selected assessment(s)
            else {
                return (this.alphaMap.length === 0)
            }
        },

        numericInputRules() {
            return (this.model.value_type_cd === 'PERCENT')
                ? this.percentRules
                : this.numberRules
        },

        cdValueTypesFiltered() {
            return this.isIncrOrDecr(this.model.operator_cd)
                ? this.cdValueTypes
                : this.cdValueTypes.filter(x => x.value !== 'PERCENT')
        },

        cdOperatorsFiltered() {
            return (this.dataPointNames.length > 1)
                ? this.cdOperators
                : this.cdOperators.filter(x => x.value !== "INCR" && x.value !== "DECR")
        },

        valueItems() {
            let result = []
            if (this.onlyNumericValues) {
                for (let i = 0; i < 1000; i++) {
                    result.push({text: `${i + 1}`, value: i + 1})
                }
            } else {
                if (this.model.score_detail_type_id) {
                    result = this.filteredScoreDetailTypeOptions.map(opt => ({
                        text: opt.value,
                        value: opt.numeric_value,
                    }))
                } else {
                    this.alphaMap.forEach(x => {
                        result.push({text: x.alpha_score, value: parseFloat(x.numeric_score)})
                    })
                }
            }
            return result
        },

        filteredScoreDetailTypeOptions() {
            return this.scoreDetailTypeOptions.filter(opt => opt.score_detail_type_id === this.model.score_detail_type_id)
        },

        hasWindowChanges() {
            return this.$refs.windows && this.$refs.windows.hasChanges
        },

        requiresWindows() {
            return true
        },

        formattedPrimaryValue: {
            get() {
                return this.onlyNumericValues ? parseFloat(this.model.value) : parseInt(this.model.value, 10)
            },
            set(val) {
                this.model.value = this.onlyNumericValues ? parseFloat(val) : parseInt(val, 10)
            }
        },

        formattedSecondaryValue: {
            get() {
                return this.onlyNumericValues ? parseFloat(this.model.secondary_value) : parseInt(this.model.secondary_value, 10)
            },
            set(val) {
                this.model.secondary_value = this.onlyNumericValues ? parseFloat(val) : parseInt(val, 10)
            }
        }
    },

    watch: {
        obj: function () {
            this.refresh()
        },

        dataPointTypeId: async function (cur, prev) {
            // If user changes assessment group, we should reset any selected assessments and windows,
            // as the assessment group they are switching to will have entirely different items
            if (prev && (cur !== prev)) {
                await Promise.all([
                    this.removeAllSubcategories(),
                    this.removeAllWindows(),
                ])

                // Also de-select any prior selected score detail
                this.model.score_detail_type_id = null
            }

            this.refreshAssessmentItems()
        },

        'model.operator_cd': function (cur, prev) {
            if (!this.operatorChange.message && this.enableWindowValidation) {
                if (this.isIncrOrDecr(cur) !== this.isIncrOrDecr(prev)) {
                    this.operatorChange.prev = prev
                    let txt = this.cdOperators.find(x => x.value === cur).text
                    this.operatorChange.message = `Changing to '${txt}' will change some options and will remove any existing academic windows for this metric.  Do you want to proceed?`
                }
            } else {
                this.operatorChange.message = null
                if (!this.isIncrOrDecr(this.model.operator_cd)) {
                    this.model.value_type_cd = "POINTS"
                }
            }
            if (cur !== 'BETWEEN') this.model.secondary_value = null
        },

        'model.data_point_type_id': function (cur, prev) {
            if (!this.operatorChange.message && this.enableWindowValidation) {
                if (this.isIncrOrDecr(cur) !== this.isIncrOrDecr(prev)) {
                    this.operatorChange.prev = prev
                    let txt = this.cdOperators.find(x => x.value === cur).text
                    this.operatorChange.message = `Changing to '${txt}' will change some options and will remove any existing academic windows for this metric.  Do you want to proceed?`
                }
            } else {
                this.operatorChange.message = null
                if (!this.isIncrOrDecr(this.model.operator_cd)) {
                    this.model.value_type_cd = "POINTS"
                }
            }
        }
    },

    mounted() {
        this.refresh()
    },

    methods: {
        cancelOperatorChange() {
            this.model.operator_cd = this.operatorChange.prev
        },

        processOperatorChange() {
            if (!this.isIncrOrDecr(this.model.operator_cd)) {
                this.model.value_type_cd = 'POINTS'
            }
            this.removeAllWindows()
            if (this.$refs.windows) this.$refs.windows.clearSelections()
            this.operatorChange.message = null
        },

        isIncrOrDecr(val) {
            return val === 'INCR' || val === 'DECR'
        },

        setModel() {
            this.enableWindowValidation = false
            Object.keys(this.model).forEach(k => {
                this.model[k] = this.obj[k] || null
            })
            this.model.value = this.obj.value ? parseFloat(this.obj.value).toString() : null
            this.$nextTick(() => {
                this.enableWindowValidation = true
            })
        },

        getDataPointTypes() {
            let params = {property: 'assessments'}
            BaseService.fetch(params, {type: 'dataPointTypes'}).then((rsp) => {
                this.dataPointTypes = rsp.data
                this.refreshAssessmentItems()
            })
        },

        async getDataPointNames() {
            if (this.dataPointTypeId) {
                let params = {data_point_type_id: this.dataPointTypeId}
                await BaseService.fetch(params, {type: 'dataPointNames'}).then((rsp) => {
                    this.dataPointNames = rsp.data.names
                })
            } else {
                this.dataPointNames = []
            }
        },

        async getAlphaMap() {
            this.isRestrictingScoreDisplay = false

            // By default, if a user has made no selections, then we calculate whether
            // the assessment group always uses Score Display values based on every assessment within
            let hasOnlyAlphaSubcategories = false
            if (this.subcategories.length > 0) {
                hasOnlyAlphaSubcategories = (this.subcategories.filter(sc => !!sc.score_alpha_map_flag).length === this.subcategories.length)
            }

            // If user has selected at least one assessment, then we can possibly
            // show Score Display values (even if not all assessment use it) if the one(s)
            // they picked are all set up to use Score Display
            if (this.selections.subcategories.length > 0) {
                hasOnlyAlphaSubcategories = (this.selections.subcategories.filter(sel => {
                    let sc = this.subcategories.find(sc => sc.id === sel.sub_category_id)
                    return (sc && sc.score_alpha_map_flag) // ec thresholds ui doesn't support optional chaining
                }).length === this.selections.subcategories.length)
            }

            if (this.dataPointTypeId && hasOnlyAlphaSubcategories) {
                let params = {data_point_type_id: this.dataPointTypeId}
                await BaseService.fetch(params, {type: 'alphaMaps'}).then((rsp) => {
                    this.alphaMap = rsp.data.alpha_maps
                })
            } else {
                this.alphaMap = []

                if (this.dataPointTypeId) {
                    this.isRestrictingScoreDisplay = true
                }
            }
        },

        async getScoreDetailTypes() {
            if (this.dataPointTypeId) {
                let params = {data_point_type_id: this.dataPointTypeId}
                await BaseService.fetch(params, {type: 'scoreDetailTypes'}).then((rsp) => {
                    this.scoreDetailTypes = rsp.data
                })
            } else {
                this.scoreDetailTypes = []
            }
        },

        async getScoreDetailTypeOptions() {
            if (this.dataPointTypeId) {
                let params = {
                    property: 'options',
                    data_point_type_id: this.dataPointTypeId,
                    score_detail_type_id: this.scoreDetailTypes.map(itm => itm.id).join(','),
                }
                await BaseService.fetch(params, {type: 'scoreDetailTypeOptions'}).then((rsp) => {
                    this.scoreDetailTypeOptions = rsp.data.options
                })
            } else {
                this.scoreDetailTypeOptions = []
            }
        },

        async getSubcategories() {
            if (this.dataPointTypeId) {
                let params = {data_point_type_id: this.dataPointTypeId}
                await BaseService.fetch(params, {type: 'subcategories'}).then((rsp) => {
                    this.subcategories = rsp.data.subcategories
                })
            } else {
                this.subcategories = []
            }
        },

        async refreshAssessmentItems() {
            await Promise.all([
                this.getDataPointNames(),
                this.getScoreDetailTypes(),
                this.getSubcategories(),
                this.refreshSelectedSubcategories(),
                this.refreshWindows(),
            ])

            await Promise.all([
                this.getAlphaMap(),
                this.getScoreDetailTypeOptions(),
            ])

            this.$nextTick(() => {
                this.$refs.form.resetValidation()
            })
        },

        refresh() {
            this.setModel()
            if (this.dataPointTypes.length === 0) {
                this.getDataPointTypes()
            } else {
                this.refreshAssessmentItems()
            }
        },

        async refreshWindows() {
            await Api().get(this.endpoints.windows, {
                params: {
                    assessment_threshold_id: this.obj.id,
                    action: `get`
                }
            }).then((rsp) => {
                this.selections.windows = (rsp.data && rsp.data.data_point_name)
                    ? rsp.data.data_point_name
                    : []
                this.validWindows()
            })
        },

        removeAllWindows() {
            let data = {data_point_name: this.selections.windows}
            Api().post(this.endpoints.windows, data, {
                params: {action: `delete`}
            }).then(() => this.refreshWindows())
        },

        validWindows() {
            this.windowErrors = (this.selections.windows.length === 0)
                ? ['A minimum of one window must be applied.']
                : []
            return this.windowErrors.length === 0
        },

        removeWindow(itm) {
            let data = {data_point_name: [itm]}
            Api().post(this.endpoints.windows, data, {
                params: {action: `delete`}
            }).then(() => this.refreshWindows())
        },

        addWindow(itm) {
            let data = {
                data_point_name: [Object.assign({}, itm, {assessment_threshold_id: this.obj.id})]
            }
            Api().post(this.endpoints.windows, data, {
                params: {action: `create`}
            }).then(() => {
                this.refreshWindows()
                if (itm.callback) {
                    itm.callback()
                }
            })
        },

        async removeAllSubcategories() {
            let data = {sub_category: this.selections.subcategories}
            await Api().post(this.endpoints.subcategories, data, {
                params: {action: `delete`}
            })

            await this.refreshSelectedSubcategories()
            await this.getAlphaMap()
        },

        async refreshSelectedSubcategories() {
            await Api().get(this.endpoints.subcategories, {
                params: {
                    assessment_threshold_id: this.obj.id,
                    action: `get`
                }
            }).then((rsp) => {
                this.selections.subcategories = (rsp.data && rsp.data.sub_category)
                    ? rsp.data.sub_category
                    : []
            })
        },

        updateSubcategories(updated) {

            let requests = []

            // ItemSelector component is returning entire objects after the
            // initial selection.  Could be something that affects other parts of
            // the application and needs further investigation, but for current
            // scope of work I'm specifically targeting this as a workaround here alone.
            updated = updated.map(itm => {
                if (typeof (itm) == 'object') {
                    return itm.id
                } else {
                    return itm
                }
            })

            let changes = {
                deletions: this.selections.subcategories.filter(v => !updated.includes(v.sub_category_id)),
                additions: updated.filter(v => !this.subcategoryValues.includes(v)).map(v => {
                    return {
                        assessment_threshold_id: this.obj.id,
                        sub_category_id: v
                    }
                })
            }

            if (changes.deletions.length > 0) {
                requests.push(Api().post(this.endpoints.subcategories, {
                    sub_category: changes.deletions
                }, {
                    params: {action: 'delete'}
                }))
            }

            if (changes.additions.length > 0) {
                requests.push(Api().post(this.endpoints.subcategories, {
                    sub_category: changes.additions
                }, {
                    params: {action: 'create'}
                }))
            }

            if (requests.length > 0) {
                axios.all(requests).then(async () => {
                    await this.refreshSelectedSubcategories()
                    await this.getAlphaMap()
                })
            }
        }
    }
}
</script>
