export default {
    scoreDetailTypeAlias: {
        defaults: {
            endpoint: 'crud.php', // scope: score_detail_type_id=209
            rootProperty: 'score_detail_type_alias',
            params: { property: 'ScoreDetailTypeAlias' }
        },
        read: {
            params: { action: 'get' }
        },
        create: {
            params: { action: 'create' }
        },
        update: {
            params: { action: 'update' }
        },
        destroy: {
            params: { action: 'delete' }
        }
    }
}