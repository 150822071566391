<template>
    <div>
        <v-layout row wrap>
            <v-flex xs3>
                <fe-remote-combo
                    label="School Year"
                    class="ma-2"
                    :url="$models.getUrl('schoolYear', 'read')"
                    rootProperty="years"
                    v-model="schoolYear"
                />

                <div
                    @click="searchBy='name'"
                    class="subheading ma-2 student-search-option"
                    :class="[{selected: searchBy == 'name'}]"
                >
                    By Name
                </div>

                <div
                    @click="searchBy='school'"
                    class="subheading ma-2 student-search-option"
                    :class="[{selected: searchBy == 'school'}]"
                >
                    By School/Grade
                </div>

                <div
                    @click="searchBy='course'"
                    class="subheading ma-2 student-search-option"
                    :class="[{selected: searchBy == 'course'}]"
                >
                    By Teacher/Course
                </div>

                <div
                    @click="searchBy='intervention'"
                    class="subheading ma-2 student-search-option"
                    :class="[{selected: searchBy == 'intervention'}]"
                >
                    By Intervention
                </div>

                <div
                    @click="searchBy='tag'"
                    class="subheading ma-2 student-search-option"
                    :class="[{selected: searchBy == 'tag'}]"
                >
                    By Tag
                </div>
            </v-flex>

            <v-flex xs9>
                <v-layout row wrap>
                    <v-flex xs12 v-show="searchBy == 'name'">
                        <fe-label style="margin-top: 8px;">Student Name</fe-label>
                        <v-text-field
                            placeholder="Type a student name (3 characters minimum)"
                            v-model="searchText"
                            solo flat dense
                        />
                    </v-flex>

                    <v-flex xs6 v-show="searchBy == 'school'">
                        <fe-remote-combo
                            label="School"
                            class="mt-2"
                            :url="$models.getUrl('school', 'read')+'&school_year_id='+schoolYear.id"
                            rootProperty="schools"
                            v-model="school"
                        />
                    </v-flex>

                    <v-flex xs6 v-show="searchBy == 'school'">
                        <fe-remote-combo
                            label="Grade"
                            class="mt-2 ml-2"
                            :url="$models.getUrl('grade', 'read')+'&school_year_id='+schoolYear.id"
                            rootProperty="grades"
                            v-model="grade"
                        />
                    </v-flex>

                    <v-flex xs6 v-show="searchBy == 'course'">
                        <fe-remote-combo
                            label="Teacher"
                            class="mt-2 ml-2"
                            :url="userCourseHistory"
                            rootProperty="courses"
                            itemText="user_full_name"
                            itemValue="user_id"
                            v-model="teacher"
                        />
                    </v-flex>

                    <v-flex xs6 v-show="searchBy == 'course'">
                        <fe-remote-combo
                            label="Course"
                            class="mt-2 ml-2"
                            :url="userCourses"
                            rootProperty="courses"
                            itemText="course_name"
                            itemValue="course_id"
                            v-model="course"
                            :disabled="!teacher"
                        />
                    </v-flex>

                    <v-flex xs6 v-show="searchBy == 'intervention'">
                        <fe-remote-combo
                            label="Intervention"
                            class="mt-2 ml-2"
                            url="crud.php?action=get&property=Category"
                            rootProperty="category"
                            v-model="category"
                            multiple
                            @change="loadDataByCategory"
                        />
                    </v-flex>

                    <v-flex xs6 v-show="searchBy == 'tag'">
                        <fe-remote-combo
                            label="Tag"
                            class="mt-2 ml-2"
                            :url="$models.getUrl('tag', 'read')"
                            rootProperty="tags"
                            v-model="tag"
                        />
                    </v-flex>

                </v-layout>

                <v-flex xs12>
                    <FeGrid
                        ref="grid"
                        :showToolbar="false"
                        :columnDefs="columnDefs"
                        :rowData="items"
                        @rowSelected="doSelect"
                        style="height: 400px;"
                        displayDensity="small"
                    >
                    </FeGrid>
                </v-flex>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: 'StudentSearch',

        props: {
            value: {},
            limit: {
                type: Number,
                default: null
            },
            searchYear: {}
        },

        data() {
            return {
                school: null,
                grade: null,
                schoolYear: {},
                tag: null,
                course: null,
                teacher: null,
                searchBy: 'name',
                searchText: null,
                items: [],
                category: [],
            }
        },

        computed: {
            ...mapState('global', ['currentYear']),
            userCourseHistory() {
                return this.$models.getUrl('courseHistory', 'read') + '&property=user_id&school_year_id=' + this.schoolYear.id
            },
            userCourses() {
                if (!this.teacher) return
                return this.$models.getUrl('courseHistory', 'read') + '&property=course_id&school_year_id=' + this.schoolYear.id + '&user_id=' + this.teacher.user_id
            },
            columnDefs() {
                return (this.searchBy == 'course')
                    ? this.byCourseColumnDefs
                    : (this.searchBy == 'intervention')
                    ? this.byCategoryColumnDefs
                    : this.defaultColumnDefs
            },
            byCourseColumnDefs() {
                return [
                    this.$grid.checkColumn(), {
                        headerName: 'Student',
                        field: 'student_full_name',
                        width: 300
                    }, {
                        headerName: 'Teacher',
                        field: 'user_full_name'
                    }, {
                        headerName: 'Course',
                        field: 'course_name'
                    }, {
                        headerName: 'Period',
                        field: 'period'
                    }
                ]
            },
            defaultColumnDefs() {
                return [
                    this.$grid.checkColumn(), {
                        headerName: 'Student',
                        field: 'student_full_name',
                        width: 300
                    }, {
                        headerName: 'School',
                        field: 'school_name'
                    }, {
                        headerName: 'Gr',
                        field: 'grade_desc'
                    }
                ]
            },
            byCategoryColumnDefs() {
                return [
                    this.$grid.checkColumn(), {
                        headerName: 'Student Name',
                        field: 'student_full_name',
                        minWidth: 200,
                        colId: 'studentFullName',
                        sortable: true,
                    }, {
                        headerName: 'Intervention Name',
                        field: 'intervention_plan_name',
                        colId: 'interventionPlanName',
                        minWidth: 200,
                    }, {
                        headerName: 'Category',
                        field: 'category_name',
                        colId: 'categoryName',
                        minWidth: 100,
                    }, {
                        headerName: 'Tier',
                        field: 'intervention_level_name',
                        colId: 'interventionLevelName',
                        minWidth: 200,
                    }, {
                        headerName: 'Strategy',
                        field: 'intervention_type_name',
                        colId: 'interventionTypeName',
                        minWidth: 200,
                    }, {
                        headerName: 'School',
                        field: 'school_name',
                        colId: 'schoolName',
                        minWidth: 200,
                    }, {
                        headerName: 'Grade',
                        field: 'grade_desc',
                        colId: 'gradeDesc',
                        minWidth: 100,
                    },
                ]
            },
        },

        watch: {
            schoolYear() {
                this.resetFields()
            },
            searchBy(v) {
                this.items = []
                this.$emit('input', [])

                this.resetFields()
            },
            searchText(v) {
                let me = this
                if (v && v.length >= 3) {
                    let s = '&name=' + this.searchText
                    this.$_.debounce(function () {
                        me.loadData(s)
                    }, 500)()
                }
            },
            tag(v) {
                if (v) {
                    let s = '&tag_id=' + v.id
                    this.loadDataByTag(s)
                }
            },
            course(v) {
                if (!v || !this.teacher) return

                let s = '&user_id=' + this.teacher.user_id + '&course_id=' + v.course_id
                this.loadData(s)
            },
            grade(v) {
                if (!v || !this.school) return

                let s = '&school_id=' + this.school.id + '&grade_id=' + v.id
                this.loadData(s)
            }
        },

        mounted() {
            if (this.searchYear) {
                this.schoolYear = {...this.searchYear}
            } else {
                this.schoolYear = {...this.currentYear}
            }
        },

        methods: {
            resetFields() {
                this.searchText = null
                this.school = null
                this.grade = null
                this.teacher = null
                this.course = null
                this.tag = null
                this.category = null
            },
            doSelect() {
                this.$emit('input', this.$refs.grid.gridApi.getSelectedRows())
            },
            loadData(str) {
                if (!str)  return

                if (this.searchBy == 'course')
                    this.loadFromStudentCourseHistory(str)
                else
                    this.loadFromStudentGradeHistory(str)
            },
            loadFromStudentCourseHistory (str) {
                this.$setLoading(true)
                this.$axios.get(this.$models.getUrl('courseHistory', 'read') + '&' + str + '&school_year_id=' + this.schoolYear.id)
                    .then(response => {
                        this.items = this.$ecResponse(response).courses
                    })
                    .finally(() => {
                        this.$setLoading(false)
                    })
            },
            loadFromStudentGradeHistory (str) {
                this.$setLoading(true)
                this.$axios.get(this.$models.getUrl('studentGradeHistory', 'read') + '&' + str + '&school_year_id=' + this.schoolYear.id)
                    .then(response => {
                        this.items = this.$ecResponse(response)
                    })
                    .finally(() => {
                        this.$setLoading(false)
                    })
            },
            loadDataByTag(str) {
                if (!str) return

                this.$setLoading(true)
                this.$axios.get(this.$models.getUrl('tag', 'read') + '&property=student_tags&' + str + '&school_year_id=' + this.schoolYear.id)
                    .then(response => {
                        this.items = this.$ecResponse(response, 'student_tags')
                    })
                    .finally(() => {
                        this.$setLoading(false)
                    })
            },
            loadDataByCategory() {
                if (!this.category.length > 0) return
                this.$setLoading(true)
                let p = {
                    category_id: [],
                    school_year_id: this.schoolYear.id
                }
                this.category.forEach(cat => {
                    p.category_id.push(cat.id)
                })
                this.$axios.get('studentInterventionPlans.php?action=get&property=interventions&'+this.$objectToParams(p))
                .then(response => {
                    this.items = this.$ecResponse(response, 'students')
                })
                .finally(() => {
                    this.$setLoading(false)
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .student-search-option {
        cursor: pointer;

        &.selected {
            text-decoration: underline;
        }
    }
</style>
