<template>
    <div class="flex-fill" v-intersect="visible">
        <fe-crud-grid
            ref="grid"
            style="height: 100%;"
            :config="$models.formInstanceStatus"
            :columnDefs="columnDefs"
            disable-inline-create
            displayDensity="medium"
            :showAddRowBtn="false"
            :autoload="false"
            @rowDoubleClicked="editRec($event.data)"
            :frameworkComponents="frameworkComponents"
        >
            <template #cellDialog="{cell}">
                <v-list-item @click="editRec(cell.data)" >Edit</v-list-item>
                <fe-tooltip
                    class="ml-auto" style="display:inline-block"
                    direction="left"
                    caret
                    tooltip="You cannot delete a locked status code. You will have to unlock before deleting."
                    :disabled="!cell.data.lock_flag"
                >
                    <v-list-item @click="deleteRec(cell.data)" :disabled="!!cell.data.lock_flag" >Delete</v-list-item>
                </fe-tooltip>
            </template>
        </fe-crud-grid>
        
        <add-status v-if="currentRec" :value="currentRec" @close="currentRec=null; reload()" />
    </div>
</template>

<script>
import AddStatus from './AddStatus'
import IconHeader from './IconHeader'
import Toggle from './Toggle'

export default {
    name: 'FormInstanceStatus',

    components: {
        AddStatus,
    },

    methods: {
        visible(e) {
            e.some(x => x.isIntersecting) && this.reload()
        },
        reload() {
            this.$refs.grid.$refs.crud.read()
                .then(() => {this.$refs.grid.$refs.grid.gridApi.refreshCells({force: true})})
        },
        editRec(rec) {
            this.$refs.grid.$refs.grid.setDialogCell(null)
            this.currentRec = rec
        },
        deleteRec(rec) {
            let msg = `You are attempting to delete a status code. There are ${rec.form_instance_cnt} forms that are using this status. `
            if(rec.form_instance_cnt) msg += `By clicking confirm you acknowledge that you intend to remove the status from these ${rec.form_instance_cnt} forms and permanently delete this code. `
                
            this.$confirmDelete([],() => {
                this.$refs.grid.$refs.grid.setDialogCell(null)
                this.$refs.grid.$refs.crud.destroy(rec)
                .finally(()=>this.reload())
            },null,msg)
        },
    },
    
    data() {
        let me = this
        return {
            currentRec: null,
            columnDefs: [{
                headerName: "Status Name",
                field: "name",
            }, {
                headerName: 'Status Locks Form',
                field: 'lock_flag',
                cellRendererFramework: Toggle,
                maxWidth: 160,
                onCellValueChanged: function(v) {
                    if(v.newValue == 0) {
                        let msg = `You are attempting to unlock a locked status code. There are ${v.data.form_instance_cnt} forms that are using this status. `
                        if(v.data.form_instance_cnt) msg += `By clicking confirm you acknowledge that you intend to unlock these ${v.data.form_instance_cnt} forms.`
                        me.$messageBox({
                            title: 'Confirm Unlock',
                            persistent: true,
                            message: msg,
                            maxWidth: '500',
                            actions: [{
                                text: 'Cancel',
                                usage: 'ghost',
                                onClick: () => {
                                    v.api.redrawRows(v.node)
                                },
                            }, {
                                text: 'Confirm',
                                usage: 'danger',
                                onClick: () => {
                                    v.data.lock_flag = 0
                                    me.$refs.grid.$refs.crud.update(v.data)
                                    .finally(()=>me.reload())
                                },
                            }]
                        })
                        
                    }
                    
                    v.data.lock_flag = 1
                    return true
                },
                cellRendererParams: function(v) {
                    return { disabled: !!v.data.carry_over }
                },
                cellStyle() {
                    return {
                        'display': 'flex',
                        'height': '100%',
                        'justify-content': 'center',
                    }
                },
            }, {
                headerComponent: 'iconHeader',
                field: 'carry_over',
                cellRendererFramework: Toggle,
                maxWidth: 160,
                onCellValueChanged: function(v) {
                    if(v.newValue == 1) {
                        v.data.lock_flag = 1
                    }
                    me.$refs.grid.$refs.crud.update(v.data)
                        .finally(()=>me.reload())
                },
                cellStyle() {
                    return {
                        'display': 'flex',
                        'height': '100%',
                        'justify-content': 'center',
                    }
                },
            }, {
                headerName: "Number of Forms",
                field: "form_instance_cnt",
                maxWidth: 160,
            }, {
                headerName: "Created",
                field: "created",
                maxWidth: 200,
            }, {
                maxWidth: 50,
                cellRenderer: v => {
                    return '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>'
                },
                onCellClicked: v => this.$refs.grid.$refs.grid.setDialogCell(v),
            }],
            frameworkComponents: {
                iconHeader: IconHeader,
            },
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
