export default {
    assessmentType  : {
        rules: [
            r => (r && r.code && r.code.trim().length > 0) || { code: 'Code is required' },
            r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        ],
        defaults: {
            endpoint: 'assessmentType.php',
            rootProperty: 'types'
        },
        read: {
            params: { action: 'get' }
        },
        create: {
            params: { action: 'create' }
        },
        update: {
            params: { action: 'update' }
        },
        destroy: {
            params: { action: 'delete' }
        }
    }
}