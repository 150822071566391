<template>
    <div>
        <button @click="dialog=true" >{{displayName}}</button>
        <v-dialog v-model="dialog" width="600">
            <fe-stepper-filter
                :title="title" style="height:600px;"
                @apply="emit" @close="dialog=false"
                :applyDisabled="!formValid || !enableFinalPanel"
            >

                <fe-stepper-checkbox-panel
                    v-model="forms"
                    :items="items" width="50%"
                />

                <v-divider vertical style="background-color:#E0E1E6"/>

                <fe-stepper-panel width="50%" style="padding:10px;">
                    <v-form v-if="enableFinalPanel" lazy-validation v-model="formValid">
                        <fe-remote-combo
                            v-model="year_type"
                            :clearable="false"
                            :items="[
                                {id:'created', name:'Created'},
                                {id:'updated', name:'Updated'},
                            ]"
                        />
                        <fe-remote-combo
                            v-model="school_year"
                            rootProperty="years"
                            url="qry.php?action=get&property=school_years"
                        />
                        <v-layout row>
                            <v-flex class="pr-2">
                                <v-switch v-model="restrict" label="Restrict Results" hide-details />
                            </v-flex>
                            <v-flex>
                                <v-switch v-model="require_all" label="Require All" hide-details />
                            </v-flex>
                        </v-layout>
                    </v-form>
                </fe-stepper-panel>

                <template #footer>
                    <div class="text-truncate" style="padding:14px">{{breadcrumb}}</div>
                </template>
            </fe-stepper-filter>
        </v-dialog>
    </div>
</template>

<script>
import * as Util from '../../Util'

export default {
    name: 'SmartformPanel',
    rank: 1,

    watch: {
        dialog(v) {
            if(!v) this.oldFilter = null
            v ? this.loadForms() : this.clear()
        },
    },

    computed: {
        title() {
            return this.oldFilter ? `Edit ${this.displayName} Filter` : `Create ${this.displayName} Filter`
        },
        enableFinalPanel() {
            return !this.$_.isEmpty(this.forms)
        },
        breadcrumb() {
            return this.forms.map(x=>x.name)
                .filter(Boolean)
                .join(', ')
        },
    },

    methods: {
//         {
//              "id": 26,
//              "type": "form",
//              "restrict": true,
//              "report": true,
//              "params": {
//                  "school_year_id": null,
//                  "year_type": 'created',
//                  "created_start_date": null,
//                  "created_end_date": null,
//                  "last_updated_start_date": null,
//                  "last_updated_end_date": null,
//                  "form_id": [39,29,17]
//              }
//          }
        clear() {
            this.restrict = true
            this.forms = []
            this.year_type = {id:'created'}
            this.require_all = false
            this.school_year = null
        },
        load(oldFilter) {
            this.oldFilter = oldFilter
            this.dialog = true
            let ep = oldFilter.editorParams

            this.restrict = ep.restrict
            this.forms = ep.forms
            this.year_type = ep.year_type
            this.require_all = ep.require_all
            this.school_year = ep.school_year
        },
        emit() {
            this.dialog = false
            let uniqueId = Util.UUIDv4()
            let name = this.forms.map(x => x.name).join(', ')
            let filter = {
                type: this.type,
                id: uniqueId,
                restrict: this.restrict,
                report: true,
                params: this.$_.pickBy({
                    school_year_id: this.school_year?.id,
                    year_type: this.year_type?.id,
                    form_id: this.forms.map(x => x.id),
                    require_all: this.require_all,
                }, x => !this.$_.isBlank(x)),
            }
            Object.defineProperties(filter, {
                editor: {value: this},
                editorParams: {value: {
                    restrict: this.restrict,
                    forms: this.forms ? [...this.forms] : null,
                    year_type: this.year_type,
                    require_all: this.require_all,
                    school_year: this.school_year ? {...this.school_year} : null,
                }},
                grouper: {value: {
                    id: uniqueId,
                    name: name,
                    type: 'propertyArray',
                    args: {path: `filters.${uniqueId}`, property: 'value'},
                }},
                chipText: {value: name},
                tooltipData: {value: this.$_.pickBy({
                    'Forms': name,
                    'School Year By': this.year_type?.name,
                    'School Year': this.school_year?.name,
                    'Restrict': this.restrict,
                    'Require All': this.require_all,
                }, x => !this.$_.isBlank(x))}
            })
            this.$emit('input', filter, this.oldFilter)
        },
        loadForms() {
            this.$axios({
                url: 'form.php?action=get&property=form&active=1&ignore_permissions=1',
            }).then((response) => {
                if (response && response.data) {
                    this.items = response.data.forms.sort(this.formSort)
                }
            })
        },
        formSort(a,b) {
            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1
            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1
            return 0
        },
    },

    data() {
        return {
            formValid: true,
            oldFilter: null,
            displayName: 'smartFORM',
            type: 'form',
            dialog: false,
            items: [],

            restrict: true,
            forms: [],
            year_type: {id:'created'},
            require_all: false,
            school_year: null,
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
