<template>
    <div class="agenda-toolbar flex-fill justify-space-between">
        <div class='d-flex flex-grow-1 flex-shrink-1 align-self-center meetings-fe-title-select'>
            <fe-title-select
                v-if="occurrences.length > 0"
                placeholder="Select a Meeting Date"
                :items="occurrences"
                v-model="selectedOccurrence"
                fontSize="18px"
            />
        </div>
        <div class='d-flex flex-shrink-1 align-self-center text-right'>
            <fe-icon-btn
                v-if="selectedOccurrence"
                color="#050F2D"
                size="14"
                @click="printing = true"
                useIcon="fas fa-print"
            />
        </div>
        <v-menu offset-y close-on-content-click>
            <template v-slot:activator="{ on }">
                <fe-icon-btn v-on="on" use-icon="more_vert" />
            </template>
            <v-list>
                <v-list-item @click="$emit('edit', meeting)">
                    <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="meeting.created_by == sessionUser.user.id" @click="deleteMeeting">
                    <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <fe-dialog
            v-if="printing"
            width="80%"
            @close="printing = false"
            @cancel="printing = false"
            title="Print Summary"
        ><iframe style="height: 60vh;" class="pdf-iframe" :src="pdfUrl" /></fe-dialog>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'Toolbar',
        components: { },
        props: {
            meeting: { type: Object, required: true },
            value: { type: Object, default: null },
            occurrences: { type: Array }
        },
        data () {
            return {
                localValue: null,
                printing: false
            }
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            selectedOccurrence: {
                get () { return this.localValue },
                set (v) { this.$emit('input', v) }
            },
            pdfUrl () {
                let host = this.$axios.defaults.baseURL
                let mid = this.meeting.id
                let sid = this.selectedOccurrence.id
                return `${host}reportCreate.php?type=dashboard_meeting_summary&dashboard_meeting_id=${mid}&schedule_date_id=${sid}`
            }
        },
        methods: {
            deleteMeeting () {
                let rec = this.meeting
                this.$confirmDelete(rec, () => {
                    this.$axios.post('googleCalendarIntegrate.php?action=delete&property=meeting', { sIds: [rec.schedule_id+'__'+rec.created_by]})
                        .then(r => { this.$ecResponse(r)})

                    this.$axios.post('dashboardMeeting.php?action=destroy', { dashboard_meetings: [rec] })
                        .then(r => { this.$ecResponse(r)})
                        .finally(() => { this.$router.push('/Meetings') })

                }, null, 'Deleting this meeting will also delete all comments created and added to students, meeting notes and action items assigned.  Are you sure you want to continue?')
            }
        },
        watch: {
            value: {
                handler (v) { this.localValue = v },
                immediate: true
            }
        }
    }
</script>

<style lang="scss" scoped>
.pdf-iframe {
    width: 100%;
    height: 300px;
    border: solid 1px #ccc;
    border-radius: 3px;
}
.meetings-fe-title-select {
    ::v-deep .fe-title-select {
        font-weight: normal !important;
    }
}
</style>