<template>
    <v-dialog
        v-model="value"
        persistent
        :width="width"
    >
        <v-card v-if="pdfSrc" width="100%" height="90vh">
            <v-toolbar color="white" flat dense>
                <v-toolbar-title>Print Preview</v-toolbar-title>
                <v-spacer></v-spacer>
                <fe-icon-btn 
                    color="#050F2D" 
                    useIcon="fal fa-times" 
                    size="24" 
                    @click="$emit('input',false)"
                ></fe-icon-btn>
            </v-toolbar>
            <iframe
                :src="pdfSrc"
                type="application/pdf"
                seamless
                frameborder="0"
                scrolling="no"
                style="width:100%; height:90%; margin:0; overflow-y:hidden;"
                >
                Oops! an error has occurred.
            </iframe>
        </v-card>
        <v-card v-else >
            <v-toolbar color="white" flat dense>
                <v-toolbar-title>Print Preview</v-toolbar-title>
            </v-toolbar>
            <div style="overflow:auto; max-height:70vh;">
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="students"
                    item-key="student_id"
                    show-select
                    hide-default-footer
                >
                    <template slot="items" slot-scope="props">
                        <td><v-checkbox v-model="props.selected" primary hide-details /></td>
                        <td>{{ props.item.student_full_name }}</td>
                    </template>
                </v-data-table>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <fe-btn usage="tertiary" @click="$emit('input',false)">Cancel</fe-btn>
                <fe-btn color="primary" :disabled="!selected.length" @click="print">Print</fe-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
    name: 'PrintSelector',
    props: {
        value: { type:Boolean, default:false },
        incidentId: { type: Number },
        students: { type: Array },
    },
    data() {
        return {
            pdfSrc: null,
            width: '400',
            height: null,
            headers: [{
                text: 'Student',
                align: 'left',
                value: 'student_full_name',
                width: '95%'
            }],
            items: [],
            selected: [],
            isLoading: false
        }
    },
    watch: {
        value(val) {
            if(val) {
                this.pdfSrc = null
                this.width = 400
                this.selected = this.students
            }
        }
    },
    methods: {
        print() {
            let studentIds = this.selected.map(s => s.student_id)
            if(!studentIds || !this.incidentId || this.isLoading) return
            let url = this.$axios.defaults.baseURL+'reportCreate.php?type=incident'
            url += '&incident_id=' + this.incidentId
            url += '&student_ids=' + studentIds
            this.width = '80%'
            this.pdfSrc = url
        }
    },
}
</script>

