<template>
    <fe-dialog
        title="Upload Attachment"
        persistent
        acceptButtonText="Upload"
        dismissButtonText="Cancel"
        width="350px"
        @accept="saveRec"
        @dismiss="$emit('close')"
        @close="localValue=false"
        :acceptButtonDisabled="files.length && selectedDescription ? false : true"
    >
        <v-form ref="form" v-model="validUpload" @submit.prevent>
            <div class="d-flex">
                <fe-label class="d-flex">Select File</fe-label>
                <v-file-input
                    v-model="selectedFiles"
                    counter label="File input"
                    accept="image/*,.csv,.wav,.pdf,.rtf,.docx,.doc,.xls,.xlsx"
                    multiple
                    flat solo dense
                />
            </div>
            <div class="d-block" v-for="(file, i) in files">
                <fe-label class="d-flex">Description</fe-label>
                <v-text-field
                    class="d-flex"
                    v-model="selectedDescription"
                    :rules="$fieldValidators('text', 'Description', { required: true, limit: 25 })"
                    flat solo dense
                />
            </div>
        </v-form>

    </fe-dialog>
</template>

<script>

export default {
    name: 'UploadAttachment',

    props: {
        crud: null,
        studentInfo: null
    },

    data() {
        return {
            selectedDescription: null,
            files: [],
            selectedFiles: [],
            uploadTypes: [],
            validUpload: false
        }
    },

    watch: {
        selectedFiles: {
            deep: true,
            handler(v) {
                this.files = []
                v.forEach((f,i) => {
                    this.files.push({ student_id: this.$props.studentInfo.student_id, description: this.selectedDescription})
                })
            }
        }
    },

    methods: {
        saveRec() {
            this.files.forEach((sel,i) => {
                sel.description = this.$data.selectedDescription
                let formData = new FormData()
                Object.keys(sel).forEach(field => {
                    formData.append(field, sel[field])
                })

                formData.append('file', this.selectedFiles[i])
                this.$axios.post('studentAttachment.php?action=create', formData).then(() => {
                }).catch(err => {
                    console.error(err)
                }).finally(() => {
                    this.$props.crud.read()
                    this.$emit('refreshDataEvt')
                    this.$emit('close')
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep div.fe-label {
        width: 76px;
    }

    ::v-deep {
        div.student-controls {
            width: 242px;
        }
    }
</style>
