<template>
    <div class="d-flex flex-fill flex-column">
        <fe-grid
            :columnDefs="columns"
            :rowData="items"
            class="flex-grow-1"
            displayDensity="small"
            ref="grid"
            :showAddRowBtn="false"
            @cellValueChanged="sanitize"
            @selectionChanged="selectRow"
            showToolbar
        >
            <template v-slot:toolbar-items>
                <fe-btn usage="primary" v-if="canSave" @click="doSave">Save</fe-btn>
            </template>
        </fe-grid>
    </div>
</template>

<script>
    const disllowEntryStyle = disallowEntry => ({
        'background': disallowEntry ? '#CCCCCC' : 'white',
        'color': disallowEntry ? '#AAA' : '',
        'pointer-events': disallowEntry ? 'none' : '',
    })

    export default {
        name: 'ValidateDataPointNames',
        props: {
            uploadId: {
                required: true,
            },
        },
        data() {
            return {
                columns: [],
                items: [],
                originalItems: [],
                existingRecords: [],
                canSave: false,
            }
        },
        mounted() {
            this.loadData()
        },
        methods: {
            sanitize() {
                // this will check to see if the original fetched data set has been manipulated
                let dirty = this.$isDirty(this.originalItems, this.items)
                if (dirty) {
                    this.canSave = true
                } else {
                    this.canSave = false
                }
                // Since we are changing cells behind the ag-grid api, call refresh
                this.$refs.grid.gridApi.refreshCells()
            },
            selectRow() {

            },
            doSave() {
                let dirty = this.$isDirty(this.originalItems, this.items)
                if (!dirty.length) return

                dirty.forEach(d => d.new_data_point_name = d.new_data_point_name === 1 ? true : false)

                this.$axios.post('verifyUpload.php?action=update&property=unknown_data_point_names&upload_id='+this.uploadId, {
                    data_point_names: dirty
                }).then(r => {
                    this.$ecResponse(r)
                    this.loadData()
                })
            },
            loadData() {
                let me = this

                this.$axios.get('verifyUpload.php?action=get&property=unknown_data_point_names&upload_id='+this.uploadId)
                    .then(r => {
                        this.items = this.$ecResponse(r, 'data_point_names')
                        this.items.forEach(i => {
                            i.new_data_point_name = 0
                            i.matched_data_point_name_id = null
                        })

                        this.originalItems = this.$_.cloneDeep(this.items)
                        this.sanitize()

                        this.$emit('count', this.originalItems.length)

                        if (this.items.length) {
                            this.$modelGet('dataPointName', { data_point_type_id: this.items[0].data_point_type_id}).then(s => {
                                this.existingRecords = s

                                this.columns = [{
                                    headerName: "Assessment Group",
                                    field: "data_point_type_name",
                                    editable: false,
                                    flex: 1,
                                }, {
                                    headerName: "Name",
                                    field: "name",
                                    editable: false,
                                    flex: 1,
                                },
                                me.$grid.toggleColumn('Create', 'new_data_point_name', null, () => this.$refs.grid.gridApi.redrawRows(this)),
                                {
                                    headerName: "Display Name",
                                    field: "display_name",
                                    cellStyle: v => disllowEntryStyle(!v.data.new_data_point_name),
                                    editable: true,
                                    flex: 1,
                                },
                                {
                                    headerName: "Alias To",
                                    field: "matched_data_point_name_id",
                                    editable: true,
                                    flex: 1,
                                    cellStyle: v => disllowEntryStyle(v.data.new_data_point_name),
                                    cellRenderer: v => v.value ? me.existingRecords.find(rec => rec.id == v.value)?.name : '',
                                    cellEditorFramework: "FeGridChipSelect",
                                    cellEditorParams: {
                                        rowDataKey: "matched_data_point_name_id",
                                        mode: "edit",
                                        items: me.$data.existingRecords,
                                        keyProp: "id",
                                        labelProp: "name",
                                        disableChips: true,
                                    },
                                },
                                ]
                            })
                        }
                    })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
