import * as Util from '@/components/common/util/AdvancedFilters'

export default {
    mounted() {
        this.$modelGet('demographicOption').then(r => {
            this.demographicOptions = r
        })
    },
    methods: {
        getGroupBy(group) {
            return Util.getGroupBy(group)
        },
        getSearchObject() {
            let noIds = ['ethnicity', 'gender']

            let p = {}
            if (this.localValue) {
                Object.keys(this.localValue).forEach(field => {
                    if (field === 'user_id') {
                        p[field] = this.localValue[field].included.map(r => r.user_id)
                    } else if (field === 'course_id') {
                        p[field] = this.localValue[field].included.map(r => r.course_id)
                    } else if (field === 'period') {
                        let string = ''

                        this.localValue[field].included.forEach(r => {
                            if (string === '') string = r.period
                            else string += '+' + r.period
                        })
                        p[field] = string
                    } else {
                        p[field] = this.localValue[field].included.map(r => r.id)
                    }
                })
            }

            // Class tab defaults to
            if (this.scope === 'Class') {
                // From analytics
                if (this.params.advancedFilters && !this.params.advancedFilters?.student_active_flag) {
                    this.params.advancedFilters = {
                        ...this.params.advancedFilters,
                        student_active_flag: this.byId ? 1 : { id: 1 }
                    }
                }

                // From non-analytics
                else if (this.advanced && !this.advanced.student_active_flag) {
                    this.advanced.student_active_flag = this.byId ? 1 : { id: 1 }
                }
            }

            if (this.advanced) {
                Object.keys(this.advanced).forEach(field => {
                    let id = 'id'
                    if (noIds.indexOf(field) > -1) id = field

                    if (field == 'demographics') {
                        Object.assign(p, this.advanced.demographics)
                    } else if (Array.isArray(this.advanced[field])) {
                        p[field] = this.advanced[field].map(a => {
                            return a.rcExclude ? "!" + a[id] : a[id]
                        })
                    } else if (field === 'group_by') {
                        p = { ...p, ...this.getGroupBy(this.advanced[field]) }
                    } else if (field === 'student_active_flag' && this.advanced.student_active_flag) {
                        p.student_active_flag = this.advanced.student_active_flag.id
                    } else if (field == 'ell_flag') {
                        p[field] = this.advanced[field]
                    }
                })
            }

            Object.keys(p).forEach(key => {
                if (key != 'student_active_flag' && !p[key] || (Array.isArray(p[key]) && p[key].length === 0)) {
                    delete p[key]
                }
            })

            return p
        },
        getSearchObjectProgEval() {
            let noIds = ['ethnicity', 'gender']

            let p = {}
            Object.keys(this.localValue).forEach(field => {
                if (field === 'user_id') {
                    p[field] = this.localValue[field].included.map(r => r.user_id)
                } else if (field === 'course_id') {
                    p[field] = this.localValue[field].included.map(r => r.course_id)
                } else if (field === 'period') {
                    let string = ''

                    this.localValue[field].included.forEach(r => {
                        if (string === '') string = r.period
                        else string += '+' + r.period
                    })
                    p[field] = string
                } else {
                    p[field] = this.localValue[field].included.map(r => r.id)
                }

            })

            // Class tab defaults to
            if (this.scope === 'Class' && !this.advanced.student_active_flag) {
                this.advanced.student_active_flag = { id: 1 }
            }

            Object.keys(this.advanced).forEach(field => {
                let id = 'id'
                if (field === 'gender' || field === 'ethnicity') {
                    id = 'value'
                } else if (noIds.indexOf(field) > -1) {
                    id = field
                }

                if (field === 'demographics') {
                    // if exists, make sure we send all members of display_name_group
                    let demos = this.advanced.demographics
                    if (demos) {
                        for (let d in demos) {
                            let id = d.split('_')[1]
                            let option = this.demographicOptions.find(x => x.demographic_id == id && x.id == demos[d])
                            if (option && option.display_name_group) {
                                demos[d] = option.display_name_group.toString()
                            }
                        }
                    }
                    Object.assign(p, demos)
                } else if (Array.isArray(this.advanced[field])) {
                    let af = this.$_.cloneDeep(this.advanced[field])
                    let fieldArr = []
                    af.forEach(a => {
                        // handle display name groups
                        if (a?.display_name_group) {
                            let dg = a.display_name_group
                            dg.forEach(g => {
                                a.rcExclude ? fieldArr.push("!" + g) : fieldArr.push(g)
                            })
                        } else {
                            let arr = a.rcExclude ? "!" + a[id] : a[id]
                            fieldArr.push(arr)
                        }
                    })
                    p[field] = fieldArr
                } else if (field === 'group_by') {
                    p = { ...p, ...this.getGroupBy(this.advanced[field]) }
                } else if (field === 'student_active_flag' && this.advanced.student_active_flag) {
                    p.student_active_flag = this.advanced.student_active_flag.id
                } else if (field === 'student_active_date' && this.advanced.student_active_date) {
                    p.student_active_date = this.advanced.student_active_date
                } else if (field == 'ell_flag') {
                    p[field] = this.advanced[field]
                } else if (field == 'incl_hidden_data_point' && this.advanced.incl_hidden_data_point) {
                    p[field] = 1 //this.advanced[field]
                }
            })

            Object.keys(p).forEach(key => {
                if (key != 'student_active_flag' && !p[key] || (Array.isArray(p[key]) && p[key].length === 0)) {
                    delete p[key]
                }
            })

            return p
        }
    }
}