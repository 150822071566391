<template>
    <fe-dialog
        @dismiss="$emit('showStudentManagerClicked', false)"
        @accept="$emit('showStudentManagerClicked', false)"
        @updateIds="updateIds"
        width="65%"
        :title="fullName"
        persistent
        disableAutoclose
        acceptButtonText="Save"
        acceptButtonDisabled
        dismissButtonText="Cancel"
        :footer="showFooter"
        :header="false"
    >
        <template v-slot:footer>
            <slot name="footer"/>
        </template>

        <v-card-title class="fe-dialog-card-title dialog-card-custom py-4">
            <slot name="header">
                <div class="flex-fill d-block">
                    {{ fullName }}
                    <v-btn class="float-right pa-0 ma-0" icon @click="$emit('showStudentManagerClicked', false)">
                        <v-icon>close</v-icon>
                    </v-btn>
                </div>
                <div class="flex-fill d-block" style="width: 100%;">
                    <v-tabs v-model="tab" class="mb-0 student-tabs" noPadding :showArrows="true">
                        <v-tab
                            v-for="(item, i) in items"
                            :key="i"
                            :href="`#tab-${i}`"
                            @click="tabClicked(item)"
                            @change="componentIndex = i"
                        >{{item.name}}</v-tab>
                    </v-tabs>
                    <v-spacer class="student-tab-spacer"></v-spacer>
                </div>
            </slot>
        </v-card-title>

        <div class="d-flex flex-column flex-fill pa-4 no-scroll" style="height: 500px;">
            <v-tabs-items v-model="tab" :continuous="false" :mandatory="true">
                <v-tab-item
                    v-for="(item, i) in items"
                    :key="i"
                    :value="`tab-${i}`"
                    :transition="false"
                    :reverseTransition="false"
                >
                    <component
                        v-show="i = componentIndex"
                        class="d-flex"
                        style="width: 100%;"
                        ref="dialog"
                        :key="'data-manager-item-' + item.tab + `-student` + selectedStudent.student_id"
                        :is="item.component"
                        :studentInfoModel="selectedStudent"
                        @updateStudentInfo="updateStudentInfoClicked"
                        @reassignBtnClick="reassignClicked"
                        @selectRowBtnClick="selectRowClicked"
                        @updateStudentImage="$emit('updateStudentImage')"
                        @updateDemographics="$emit('updateDemographics')"
                    />
                </v-tab-item>
            </v-tabs-items>
        </div>

        <reassign-modal
            v-if="reassignShowModal"
            @close="reassignShowModal = false"
            @reassignBtnClick="reassignClicked"
            :crud="componentRefs"
        />
    </fe-dialog>

</template>

<script>
    import Vuex, { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'
    import StudentInfo from './Info'
    import StudentImageTools from './ImageTools'
    import StudentIdGrid from './IdGrid'
    import StudentDisabilities from './Disabilities'
    import StudentGrades from './Grades'
    import StudentCourseManager from './CourseManager'
    import StudentSchools from './Schools'
    import StudentScores from './Scores'
    import StudentIncidents from './Incident'
    import StudentSearch from '@/components/common/StudentSearch'
    import ReassignModal from './Reassign'
    import DemographicGrid from "./DemographicGrid"
    import StudentEnrollments from "./StudentEnrollments"

    export default {
        name: 'StudentDataManager',

        components: {
            StudentInfo,
            StudentImageTools,
            StudentIdGrid,
            StudentDisabilities,
            StudentGrades,
            StudentCourseManager,
            StudentSchools,
            StudentScores,
            StudentIncidents,
            StudentSearch,
            ReassignModal,
            DemographicGrid,
            StudentEnrollments
        },

        provide() {
            this.localStore = new Vuex.Store({
                state: {
                    studentIds: this.rec,
                    updateStudent: null,
                    selections: [],
                    reassignStudent: undefined,
                    showReassignModal: false,
                    reassignShowModal: false,
                    schoolYears: null,
                    schools: null,
                    grades: null,
                    dataPointTypes: null,
                    disabilityTypes: null,
                    hasSecurity: false
                },
                mutations: {
                    set(state, payload) {
                        state[payload.key] = payload.value
                    }
                }
            })

            return {
                localStore: this.localStore
            }
        },

        props: {
            id: {},
            selectedStudent: { },
            security: [
                'MANAGE_STUDENT_DATA'
            ]
        },

        data() {
            return {
                selectedItems: [],
                tab: null,
                selectedComponent: {},
                reassignShow: false,
                componentIndex:  0,
                componentRefs: null,
                items: [
                    {'name': 'Info', 'tab': 'Info', 'component': 'StudentInfo', 'reassignShow': false},
                    {'name': 'Image Tools', 'tab': 'Image Tools', 'component': 'StudentImageTools', 'reassignShow': false},
                    {'name': 'IDs', 'tab': 'IDs', 'component': 'StudentIdGrid', 'reassignShow': true},
                    {'name': 'Demographic Fields', 'tab': 'Demographic Fields', 'component': 'DemographicGrid', 'reassignShow': true},
                    {'name': 'Disabilities', 'tab': 'Disabilities', 'component': 'StudentDisabilities', 'reassignShow': false},
                    {'name': 'Grades', 'tab': 'Grades', 'component': 'StudentGrades', 'reassignShow': true},
                    {'name': 'Courses', 'tab': 'Courses', 'component': 'StudentCourseManager', 'reassignShow': true},
                    {'name': 'Schools', 'tab': 'Schools', 'component': 'StudentSchools', 'reassignShow': true},
                    {'name': 'Scores', 'tab': 'Scores', 'component': 'StudentScores', 'reassignShow': true},
                    {'name': 'Incidents', 'tab': 'Incidents', 'component': 'StudentIncidents', 'reassignShow': true},
                    {'name': 'Enrollment', 'tab': 'Enrollment', 'component': 'StudentEnrollments', 'reassignShow': true}
                ],
                selectedSchoolYear: null,
                studentDataInfo: {},
                fullName: '',
                mdlInit: ''
            }
        },

        computed: {
            ...mapLocalState(['studentIds','studentName','updateStudent','reassignStudent','selections','schoolYears','schools','grades','showReassignModal','reassignShowModal','dataPointTypes','disabilityTypes','hasSecurity']),
            ...mapState('global',['sessionUser']),
            showFooter() {
                return this.$slots['footer'] ? true : false
            },

            checkSecurity() {
                return this.sessionUser.security_codes.indexOf('MANAGE_STUDENT_DATA') != -1
            }
        },

        watch: {
            'selectedStudent.student_id'(v) {
                if (v) this.loadBaseData()
            },
            studentDataInfo: {
                deep: true,
                handler(val, oldVal) {
                    if (!val) return ''
                    this.$data.fullName = val.lname + ', ' + val.fname + ' ' + this.$data.mdlInit
                }
            }
        },

        methods: {
            tabClicked(e) {
                // ignore tab switch if already there - this prevents it from jumping back to info tab
                if (this.$data.tab == `tab-${this.items.findIndex(v => v.tab == e.tab)}`) return;
                this.selections = []
                this.$data.selectedComponent = e
                this.$data.tab = e.tab
                this.$data.reassignShow = e.reassignShow
            },

            reassignClicked(ref) {
                this.componentRefs = ref
                this.reassignShowModal = !this.reassignShowModal
            },

            selectRowClicked() {
                let api = this.componentRefs

                this.$data.selectedItems = api.gridApi.getSelectedRows()
                this.selections = this.$data.selectedItems
                this.$data.showReassignModal = this.$data.selectedItems.length > 0 ? true : false
                api.hasSelection = !api.hasSelection
            },

            updateStudentInfoClicked() {
                this.loadStudentInfo()
            },

            updateIds(ids) {
                this.selectedIds = ids
            },

            loadStudentInfo() {
                this.$axios.get(this.$models.getUrl('studentRecord', 'read') + '&id=' + this.$props.selectedStudent.student_id)
                .then((response) => {
                    if (response && response.data) {
                        this.$data.studentDataInfo = response.data[0]
                        this.$data.mdlInit = response.data[0].mdl_init ? response.data[0].mdl_init : ''
                    }
                })
            },
            loadBaseData() {
                this.tabClicked(this.$data.items[0])
                this.updateStudent = this.$props.selectedStudent
                this.loadStudentInfo()

                this.$modelFetch(this.$models.schoolYear)
                .then(data => {
                    this.schoolYears = data
                    this.$data.selectedSchoolYear = data[0].id
                })
                .then(() => {
                    this.$axios.get(this.$models.studentSchoolsByYear.read + '?action=get&school_year_id=' + this.$data.selectedSchoolYear)
                    .then(response => {
                        this.schools = response.data.schools
                    })
                })
                .catch(error => {
                    console.error(error)
                })

                this.$axios.get(this.$models.getUrl('grade', 'read'))
                .then(response => {
                    this.grades = response.data.grades
                })

                this.$axios({
                    url: 'dataPointType.php?action=get&hidden=0&property=assessment',
                }).then((response) => {
                    if (response && response.data) {
                        this.dataPointTypes = response.data
                    }
                })

                this.$modelGet('studentDisabilityType')
                .then(response => {
                    this.disabilityTypes = response
                })

                this.hasSecurity = this.checkSecurity
            },
            setStudents(students) {
                this.selectedStudent = students[0]
                this.loadBaseData()
            }
        },

        mounted() {
            if (this.$props.selectedStudent) {
                this.loadBaseData()
            }
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep div.student-tabs .v-tab {
        text-transform: none !important;
    }

    .dialog-card-custom {
        padding: 0 16px;
    }

    .student-tab-header {
        width: 100%
    }

    div.v-tabs-bar {
        bottom: -3px;
        background-color: transparent;
    }

    .fe-tabs-main {
        height: 48px !important;
        margin-bottom: 20px
    }

    div.student-tab-spacer {
        border-bottom: #E0E1E6 solid 3px !important;
    }

    .fe-button.tertiary {
        color: #050f2d !important;
    }
</style>
