<template>
    <div class="flex-fill flex-column no-scroll">
        <div class="headline">Permissions</div>

        <fe-grid style="height:100%"
            ref="grid"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :showAddRowBtn="false"
            displayDensity="medium"
            disableInlineCreate
            @beginCreate="showAddDialog=true"
            @cellValueChanged="updateRec($event.data)"
        >
            <template #cellDialog="{cell}">
                <simple-grid-dialog-column v-if="cell.colDef.cellRendererFramework=='FeDialogColumn'"
                    :cell="cell"
                    :title="cell.data.name"
                    :deleteTool="1"
                    :field="dialogColumnDefs[cell.colDef.field].column1.field"
                    :fieldHeader="dialogColumnDefs[cell.colDef.field].column1.headerName"
                    :columnDefs="dialogColumnDefs[cell.colDef.field].columnDefs && dialogColumnDefs[cell.colDef.field].columnDefs"
                    :crudConfig="dialogColumnDefs[cell.colDef.field].model"
                    :fieldConfig="dialogColumnDefs[cell.colDef.field].fieldConfig"
                    :buildInsertRec="dialogColumnDefs[cell.colDef.field].buildRec"
                    :width="dialogColumnDefs[cell.colDef.field].width"
                    :height="dialogColumnDefs[cell.colDef.field].height"
                    :insertDefaults="cell.data"
                    :readParams="{user_group_id:cell.data.id}"
                    @close="$refs.grid.openMenu = false"
                    @created="onCreated(cell)"
                    @destroyed="onDestroyed(cell)"
                />
            </template>
        </fe-grid>

        <fe-crud
            ref="crud"
            autoload
            :config="$models.security"
            @read="rowData = $event.filter(x => {
                //Feature flag guardian email permissions
                if (!guardianEmailDisplay && x.code === 'SEND_GUARDIAN_FORM_EMAIL') {
                    return false
                }
                return x.code !== 'MANAGE_OTHERS_ECASSESSMENT' && x.code !== 'MANAGE_STUDENTS'
            })"
        />
    </div>
</template>

<script>
import SimpleGridDialogColumn from "@/components/common/SimpleGridDialogColumn"

export default {
    name: 'Permissions',
    components: {
        SimpleGridDialogColumn,
    },
    computed: {
        guardianEmailDisplay() {
            return this.$store.getters['flags/flags']['ec-guardian-emails']
        },
    },
    methods: {
        onCellClicked(v) {
            this.$refs.grid.setDialogCell(v)
        },
        renderDescription(v) {
            return v.data.description
                ? `<div style="white-space: normal;line-height: 140%;">${v.value}</div>`
                : ''
        },
    },
    data() {
        return {
            rowData: null,
            columnDefs: [{
                headerName: 'Name',
                field: 'name',
            }, {
                headerName: 'Description',
                field: 'description',
                cellRenderer: this.renderDescription,
                autoHeight: true,
            // }, {
            //     headerName: 'Users',
            //     field: 'user_cnt',
            //     maxWidth: 100,
            //     cellRendererFramework: 'FeDialogColumn',
            //     onCellClicked: this.onCellClicked,
            // }, {
            //     headerName: 'Groups',
            //     field: 'group_cnt',
            //     maxWidth: 100,
            //     cellRendererFramework: 'FeDialogColumn',
            //     onCellClicked: this.onCellClicked,
            }],
            dialogColumnDefs: {
                user_cnt: {
                    model: this.$models.userGroupUser,
                    column1: {headerName: 'Users', field: 'user_full_name'},
                    fieldConfig: {
                        type:'combo', url:'users.php?action=get&active=1', itemText:'user_full_name',
                        filter: function(x) {
                            if(!this.index) {
                                let index = {}
                                this.forEach(y => index[y.user_id]=true)
                                Object.defineProperty(this,'index',{value:index})
                            }
                            return !this.index[x.id]
                        }
                     },
                    buildRec: (v,d) => ({ user_id: v.id, user_group_id: d.id,}),
                },
                group_cnt: {
                    model: this.$models.userGroupUser,
                    column1: {headerName: 'Groups', field: 'user_full_name'},
                    fieldConfig: {
                        type:'combo', url:'users.php?action=get&active=1', itemText:'user_full_name',
                        filter: function(x) {
                            let r = this.find(y => y.security_id == x.id)
                            return !r
                        }
                     },
                    buildRec: (v,d) => ({ user_id: v.id, user_group_id: d.id,}),
                },
            }
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
