const DEFAULT_GROUP_BY = {
    school: true,
    grade: false,
    ethnicity: true,
    gender: true,
    schoolgroup: false
}

export function getGroupBy(group) {
    group = group || DEFAULT_GROUP_BY

    let p = {
        group_by: [],
        chart_group_by: []
    }
    let chartArr = ['school', 'schoolgroup']
    let groupArr = ['ethnicity', 'school', 'grade', 'gender', 'schoolgroup']

    chartArr.forEach(a => {
        if (group[a] === false) {
            p.chart_group_by.push(a)
        }
    })

    groupArr.forEach(a => {
        if (group[a] === false) {
            p.group_by.push(a)
        }
    })

    return p
}