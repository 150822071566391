<template>
    <div class="flex-fill flex-column" :class="{'thumbnail': thumbnail}">
        <ag-grid-vue
            style="height: 100%; width: 100%;"
            class="ag-theme-material"
            :gridOptions="gridOptions"
            :columnDefs="columnDefs"
            :rowData="rowData"
            @gridReady="gridReady"
        />
    </div>
</template>

<script>
import {AgGridVue} from "@ag-grid-community/vue"
import { ModuleRegistry } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { CsvExportModule } from '@ag-grid-community/csv-export'

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule])

export default {
    name: "TableView",

    components: {
        AgGridVue,
    },

    props: {
        data: {
            type: Object,
            required: true
        },
        config: {
            type: Object,
            required: true
        },
        thumbnail: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            gridOptions: undefined,
            columnDefs: undefined,
            rowData: undefined,
            enableSortSave: true,
            suppressResizeSave: true,
            setTimeInstance: false,
            modules: ClientSideRowModelModule,
        }
    },

    watch: {
        data(x) {
            if (x) {
                this.setData()
                    .then(data => {
                        if (data) {
                            this.gridReady()
                        }
                    })
            }
        }
    },

    beforeMount() {
        this.gridOptions = {
            onCellClicked: data => {
                if ('googlesheet' !== this.type && data.value.params.y) {
                    this.$emit('drilldown', Object.assign({}, this.config), Object.assign({}, data.value.params))
                }
            },

            onSortChanged: () => {
                if (this.enableSortSave && this.$parent.canModify) {
                    setTimeout(() => {
                        let sorted = this.gridOptions.api.getSortModel()

                        let args = {
                            text: 'Would you like to save this sort?',
                            timeout: 6000,
                            actions: [
                                {
                                    text: 'YES',
                                    callback: () => {
                                        let cfg = this.$_.cloneDeep(this.config)
                                        cfg.config.tableSort = sorted
                                        cfg.tableSort = sorted
                                        this.$emit('save', cfg)
                                    }
                                },
                                {
                                    text: 'NO'
                                }
                            ]
                        }

                        this.$snackbars.$emit('new', args)
                    }, 1000)
                }
            },

            onColumnResized: v => {
                if (!this.suppressResizeSave && this.$parent.canModify) {
                    if (this.setTimeInstance) clearTimeout(this.setTimeInstance);

                    this.setTimeInstance = setTimeout(() => {
                        let column = v.columns[0]
                        let args = {
                            text: 'Would you like to save these column widths?',
                            timeout: 6000,
                            actions: [
                                {
                                    text: 'YES',
                                    callback: () => {
                                        let cfg = this.$_.cloneDeep(this.config)
                                        if (!cfg.colWidths) {
                                            cfg.colWidths = {}
                                            cfg.config.colWidths = {}
                                        }
                                        cfg.colWidths[column.colId] = column.actualWidth
                                        this.$emit('save', cfg)
                                    }
                                },
                                {
                                    text: 'NO'
                                }
                            ]
                        }

                        this.$snackbars.$emit('new', args)
                    }, 1000)
                }
            },

            suppressContextMenu: true
        }

        this.setData()
    },

    methods: {
        setData() {
            return new Promise(resolve => {
                let widths = this.config.colWidths || {}

                if (this.data.hasOwnProperty('categories')) {
                    let firstColumn = {
                        headerName: '',
                        field: 'column',
                        pinned: 'left',
                        suppressSizeToFit: true,
                        suppressMenu: true,
                        sortable: true,
                        resizable: true,
                        valueFormatter: (params) => {
                            let value = params.data[params.colDef.field]
                            if (!!value && typeof value.value !== 'object') {
                                return value.value
                            }
                            return ''
                        },
                        tooltipValueGetter: value => value.value
                    }

                    if (widths.column) {
                        firstColumn.width = widths.column
                    }

                    let columnDefs = [firstColumn]

                    let rowData = this.data.categories.map(item => {
                        return {
                            column: {
                                value: item,
                                params: {y: false}
                            }
                        }
                    })

                    this.data.series.forEach(item => {
                        let field = typeof item.name === 'string' ? item.name.replace(/\W/g, '').toLowerCase() : item.name + ''

                        let columnDef = {
                            headerName: item.name,
                            headerTooltip: item.name,
                            field: field,
                            sortable: true,
                            resizable: true,
                            suppressMenu: true,
                            cellStyle: {'text-align': 'center'},
                            valueFormatter: (params) => {
                                let value = params.data[params.colDef.field]
                                if (!!value && typeof value.value !== 'object') {
                                    if (this.config.value === 'percentage') {
                                        return value.params.pct + "%"
                                    }
                                    return value.value
                                }

                                return ''
                            },
                            valueGetter: params => {
                                let value = params.data[params.colDef.field]
                                if (!value) {
                                    return {
                                        params: {},
                                        value: ''
                                    }
                                } else if (this.config.value === 'percentage') {
                                    let pct = params.data[params.colDef.field].params.pct || 0.0
                                    return {
                                        params: params.data[params.colDef.field].params,
                                        value: pct
                                    }
                                }

                                return value
                            },
                            comparator: (a, b) => {
                                let x = a ? a.value : 0
                                let y = b ? b.value : 0
                                if (x === y) return 0

                                return x < y ? -1 : 1
                            },
                            tooltipValueGetter: value => value.valueFormatted
                        }

                        if (widths[field]) {
                            columnDef.width = widths[field]
                            columnDef.suppressSizeToFit = true
                        }

                        if (item.color) {
                            columnDef.headerComponentParams = {
                                template: '<div class="ag-cell-label-container" role="presentation">' +
                                    '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true" style="opacity: 0; transition: opacity 0.2s ease 0s, border 0.2s ease 0s;"></span>' +
                                    '<div ref="eLabel" class="ag-header-cell-label" role="presentation" unselectable="on">' +
                                    '<i class="fas fa-square tw-color-swatch" style="color: ' + item.color + ';align-self: center; margin-right: 5px;"></i>' +
                                    '<span ref="eText" class="ag-header-cell-text" role="columnheader" unselectable="on">item.name</span>' +
                                    '<span ref="eFilter" class="ag-header-icon ag-filter-icon ag-hidden" aria-hidden="true"></span>' +
                                    '<span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden" aria-hidden="true"></span>' +
                                    '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden" aria-hidden="true"></span>' +
                                    '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden" aria-hidden="true"></span>' +
                                    '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden" aria-hidden="true">' +
                                    '<span class="ag-icon ag-icon-none" unselectable="on"></span>' +
                                    '</div>' +
                                    '</div>'
                            }
                        }

                        columnDefs.push(columnDef)

                        item.data.forEach((datum, index) => {
                            rowData[index][field] = {
                                value: datum.y,
                                params: datum
                            }
                        })
                    })

                    this.columnDefs = columnDefs
                    this.rowData = rowData
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
        },

        gridReady() {
            let api = this.gridOptions.api
            api.sizeColumnsToFit()
            setTimeout(() => {
                this.suppressResizeSave = false
            }, 1000)

            if (!this.$_.isEmpty(this.config.tableSort)) {
                this.enableSortSave = false
                api.setSortModel(this.config.tableSort)
                setTimeout(() => {
                    this.enableSortSave = true
                }, 250)
            }
        },

        exportCSV() {
            this.gridOptions.api.exportDataAsCsv({
                fileName: this.config.name,
                allColumns: true,
                processCellCallback: (params) => {
                    if (!params.value) {
                        return ''
                    } else if (params.value.hasOwnProperty('value')) {
                        return params.value.value
                    }
                    return params.value
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .ag-header-cell-label {
    justify-content: center;
}

::v-deep .ag-center-cols-viewport {
    .ag-cell {
        cursor: pointer;
    }
}

.thumbnail {
    ::v-deep .ag-body-horizontal-scroll {
        display: none;
    }

    ::v-deep .ag-body-viewport {
        overflow: hidden;
    }
}
</style>
