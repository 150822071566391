<template>
    <div>
        <div class="ec-section-title">Section</div>
        <div class="ec-caption">
            Configure the section with the options below. To reorder
            or delete use the 6-dot menu.
        </div>

        <form class="mt-2">
            <div class="d-flex">
                <fe-label :style="labelStyle">Show Section Name</fe-label>
                <fe-switch style="margin-left:auto;" :value="!localValue.hide_label" @input="localValue.hide_label=!$event"/>
            </div>
            
            <div class="d-flex">
                <fe-label :style="labelStyle">Show Border</fe-label>
                <fe-switch style="margin-left:auto;" v-model="localValue.border" />
            </div>
            
            <div class="d-flex">
                <fe-label :style="labelStyle">Layout</fe-label>
                <fe-remote-combo
                    byId
                    style="margin-left:auto;"
                    v-model="localValue.section_layout_type_id"
                    itemText="description"
                    :items="Object.values(sectionLayoutTypes)"
                    :clearable="false"
                ></fe-remote-combo>
            </div>
            
            <div class="d-flex">
                <fe-label :style="labelStyle">% Width of Page</fe-label>
                <fe-remote-combo
                    byId
                    style="margin-left:auto;"
                    v-model="localValue.width"
                    itemValue="value"
                    :items="[
                        {value:.25, name:'25%'},
                        {value:.33, name:'33%'},
                        {value:.50, name:'50%'},
                        {value:.66, name:'66%'},
                        {value:.75, name:'75%'},
                        {value:.99, name:'100%'}
                    ]"
                    :clearable="false"
                ></fe-remote-combo>
            </div>

        </form>
        <!-- {{ $_.omit(localValue,['form_fields'])}} -->
        <!-- {{Object.values(sectionLayoutTypes)}} -->
    </div>
</template>

<script>
import smartFormMixin from '../../smartFormMixin'
export default {
    name: 'FormSectionConfig',
    mixins: [ smartFormMixin ],
    
    props: {
        value: {type: Object, default: null},
    },
    computed: {
        labelStyle() {
            return {
                minWidth:'120px'
            }
        }
    },
    
    created() {
        this.localValue = {...this.value} || {}
    },
    watch: {
        value(v) {
            this.localValue = {...v} || {}
        },
        localValue: {
            handler: function(v) {
                let dirty = JSON.stringify(this.$_.omit(v,['form_fields'])) != JSON.stringify(this.$_.omit(this.value,['form_fields']))
                if(dirty) {
                    this.$emit('input',v)
                }
            },
            deep: true,
        }
    },
    data() {
        return {
            localValue: {}
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
