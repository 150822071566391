<template>
    <div class="flex-fill flex-column" style="padding: 0 0 40px 0 !important;">

        <slo-list
            v-show="!planId"
            @selected="selectPlan"
            @inDevelopment="featureInDevelopment"
        />

        <slo-plan
            v-if="showPlan"
            :planId="planId"
            @setTitle="setTitle"
            @inDevelopment="featureInDevelopment"
            @edit="onEditSLO"
            :logisticTypes="logisticTypes"
            @chartDrilldown="showChartParams = $event"
            @dataWallDrilldown="showDataWallParams = $event"
            @scoreBreakdown="showScoreBreakdownParams = $event"
        />

        <chart-drilldown
            v-else-if="showChartParams"
            :params="showChartParams.attrs.params"
            :clickRecord="showChartParams.attrs.clickRecord"
            :record="showChartParams.attrs.record"
        />

        <data-wall
            v-else-if="showDataWallParams && !showSubscoreScoreBreakdownParams"
            :params="showDataWallParams.attrs.params"
            :class="showDataWallParams.attrs.class"
            suppressDefaultDrilldown
            @scoreBreakdown="showSubscoreScoreBreakdownParams = $event"
        />

        <assessment-history
            v-else-if="showScoreBreakdownParams && !showSubscoreScoreBreakdownParams"
            :studentId="showScoreBreakdownParams.attrs.studentId"
            :subCategoryId="showScoreBreakdownParams.attrs.subCategoryId"
            :dataPointScoreId="showScoreBreakdownParams.attrs.dataPointScoreId"
        />

        <assessment-history
            v-else-if="showSubscoreScoreBreakdownParams"
            :studentId="showSubscoreScoreBreakdownParams.attrs.studentId"
            :subCategoryId="showSubscoreScoreBreakdownParams.attrs.subCategoryId"
            :dataPointScoreId="showSubscoreScoreBreakdownParams.attrs.dataPointScoreId"
        />

        <div v-if="planId" class='slo-breadcrumbs'>
            <breadcrumb v-model="crumbs" @click="crumbClick"/>
        </div>

        <fe-crud
            autoload
            ref="sloLogisticTypes"
            :config="$models.sloLogisticType"
            @read="logisticTypes = $event"
        />

    </div>
</template>

<script>
    import windowOptionsMixin from '@/mixins/windowOptions'
    import SloList from "./List"
    import SloPlan from "./Plan"
    import Breadcrumb from '@/components/common/Breadcrumb'
    import ChartDrilldown from '@/components/charts/ChartDrilldown'
    import DataWall from '@/components/modules/datawall/Index'
    import AssessmentHistory from '@/components/modules/studentprofile/charts/AssessmentHistory'

    export default {
        name: 'Slo',
        mixins: [ windowOptionsMixin ],
        components: {
            SloList,
            SloPlan,
            Breadcrumb,
            ChartDrilldown,
            DataWall,
            AssessmentHistory,
        },
        props: {
            id: { type: Number },
        },
        data() {
            return {
                planId: null,
                logisticTypes: null,
                showChartParams: null,
                showDataWallParams: null,
                showScoreBreakdownParams: null,
                showSubscoreScoreBreakdownParams: null,
            }
        },
        computed: {
            // A plan should be shown if one is selected and no drilldown action has been taken
            // If drilldown has occurred, we enter a third screen with an additional breadcrumb
            showPlan() {
                return !!this.planId && !this.showChartParams && !this.showDataWallParams && !this.showScoreBreakdownParams && !this.showSubscoreScoreBreakdownParams
            },
            crumbs: {
                get () {
                    let itms = []

                    // Once user has selected a plan, that plan shows up as a breadcrumb
                    if (this.planId) {
                        itms.push({
                            name: 'SLO Dashboard',
                            key: 'dashboard',
                            id: 'dashboard'
                        })
                        itms.push({
                            name: 'Plan Details', // TODO - Show actual plan name?  (UX question?)
                            key: 'plan',
                            id: this.planId
                        })

                        // If SLO created from a chart, user can drilldown into a chart segment
                        // for the next and final breadcrumb
                        if (this.showChartParams) {
                            itms.push({
                                name: this.showChartParams.name,
                                key: 'chart',
                                id: null,
                            })
                        }

                        // If SLO created from a datawall, user can click on a column header
                        // to view any assessment subscores as a datawall
                        else if (this.showDataWallParams) {
                            itms.push({
                                name: this.showDataWallParams.name,
                                key: 'dataWall',
                                id: null,
                            })

                            // Within subscores datawall, user can further click any score
                            // to view the breakdown for that student/score
                            if (this.showSubscoreScoreBreakdownParams) {
                                itms.push({
                                    name: this.showSubscoreScoreBreakdownParams.name,
                                    key: 'subscoreScoreBreakdown',
                                    id: null,
                                })
                            }
                        }

                        // If SLO created from a datawall, user can click on a score value (not
                        // the header, but an actual score) to get score breakdown for that student/score
                        else if (this.showScoreBreakdownParams) {
                            itms.push({
                                name: this.showScoreBreakdownParams.name,
                                key: 'scoreBreakdown',
                                id: null,
                            })
                        }
                    }
                    return itms
                },
                set (v) {}
            }
        },
        methods: {
            selectPlan (itm) {
                this.planId = itm.slo_id
            },
            crumbClick (crumb) {
                switch (crumb?.key) {
                    case 'dashboard':
                        this.planId = null
                        break
                    case 'plan':
                        this.showChartParams = null
                        this.showDataWallParams = null
                        this.showScoreBreakdownParams = null
                        this.showSubscoreScoreBreakdownParams = null
                        break
                    case 'dataWall':
                        this.showSubscoreScoreBreakdownParams = null
                        break
                    default:
                        break
                }
            },
            setTitle (title) {
                this.setWindowTitle(title)
            },
            featureInDevelopment () {
                this.$messageBox({
                    persistent: true,
                    title: 'In Development',
                    maxWidth: 400,
                    message: 'This feature is currently under development.',
                    actions: [{
                        text: 'Okay',
                        onClick: () => { }
                    }]
                })
            },
            onEditSLO(slo) {
                // launchdarkly feature flag check
                if (this.$store.getters['flags/flags']['ec-slo'] !== true) {
                    return this.featureInDevelopment()
                }

                let me = this

                // if student_count is >= 1 it indicates that the SLO
                // was created from the data wall
                if (slo.student_count) {
                    let w = this.$dockableWindow({
                        name: 'Edit SLO',
                        component: 'slo-creation-datawall',
                        attrs: {
                            id: slo.id,
                            parentWindowId: this.dockedWindowId,
                        },
                        events: {
                            close() {
                                me.$store.commit('global/removeDockedWindow', w)
                            },
                            closeParent() {
                                me.$store.commit('global/removeDockedWindow', { dockedWindowId: me.dockedWindowId, isStrict: true })
                            },
                        }
                    })
                } else {
                    let w = this.$dockableWindow({
                        name: 'Edit SLO',
                        component: 'slo-creation-charting',
                        attrs: {
                            id: slo.id,
                        },
                        events: {
                            close() {
                                me.$store.commit('global/removeDockedWindow', w)
                            }
                        }
                    })
                }
            }
        },
        mounted () {
            if (this.id) this.planId = this.id
        },
        watch: {
            planId (v) {
                if (!v) {
                    this.setTitle('SLO Dashboard')
                    this.showChartParams = null
                    this.showDataWallParams = null
                    this.showScoreBreakdownParams = null
                    this.showSubscoreScoreBreakdownParams = null
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .slo-breadcrumbs {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 7px 0 0 16px;
        height: 36px;
        border-top: solid 1px #ccc;
        background: #fff;
        z-index: 5;
    }
</style>
