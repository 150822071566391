import Vue from 'vue'
import GridTooltip from "./GridTooltip"

export default Vue.extend({
    template: `<grid-tooltip :tooltip="value">{{ value }}</grid-tooltip>`,

    components: {GridTooltip},

    data() {
        return {
            value: ''
        }
    },

    created() {
        this.value = this.params.value
    }
})
