<template>
    <div class="d-flex flex-fill flex-column" style="padding: 0px 16px;">
        <div class="headline mb-1 d-flex">
            Assessment Group Advanced Settings
            <div class="title-tip-adj">
                <fe-info-tip
                    class="ml-2"
                    tooltip="Manage additional settings such as the default ordering of Assessment Groups."
                />
            </div>
        </div>

        <fe-crud-grid
            ref="grid"
            :config="$models.assessment"
            :columnDefs="columnDefs"
            style="height: 100%;"
            :showAddRowBtn="false"
            disableInlineCreate
        />
    </div>
</template>

<script>
    export default {
        name: 'AdvancedSettings',
        data() {
            let me = this
            return {
                items: [],
                columnDefs: [{
                   headerName: "Name",
                   field: "name",
                   flex: 1,
                }, {
                    headerName: 'Created',
                    field: 'created',
                    width: 80,
                    valueFormatter: v => this.$dayjs(v.data.created).format('LLL'),
                }, {
                    headerName: 'Order',
                    field: 'rank',
                    editable: true,
                    width: 80,
                },/* me.$grid.iconColumn('Delete', 'fas fa-trash', (v) => {
                    me.$confirmDelete([v.data], () => {
                        me.$refs.crud.destroy(v.data)
                            .finally(() => { me.load() })
                    })
                })*/]
            }
        },
    }
</script>

<style lang="scss" scoped>

    .title-tip-adj {
        display: inline-block;
        position: relative;
        top: -3px;
    }

</style>
