<template>
    <div class="flex-fill flex-column pa-4">
        <fe-grid
            ref="grid"
            :rowData="items"
            :columnDefs="columnDefs"
            style="height: 100%"
            :showAddRowBtn="false"
            displayDensity="small"
            disableInlineCreate
            @beginCreate="beginCreate"
            @close-dialog="cellDialogClosed"
            @rowDoubleClicked="switchDistrict"
        >

            <template #toolbar-items>
                <fe-tooltip :tooltip="'Reload Table Data'" direction="top">
                    <fe-icon-btn class="theme--light" usage="ghost" useIcon="fas fa-sync-alt" @click="loadData" style="margin-top: -4px" />
                </fe-tooltip>
            </template>

            <template v-slot:cellDialog="{cell}">
              <div style="padding: 20px">
                  <h4>SFTP Information</h4>
                  <hr/><br/>
                  <div v-if="!!sftpInfo">
                      <p><strong>Host</strong>: <span>{{sftpInfo.host}}</span></p>
                      <p><strong>Port</strong>: <span>{{sftpInfo.port}}</span></p>
                      <p><strong>Username</strong>: <span>{{sftpInfo.username}}</span></p>
                      <p><strong>Password</strong>: <span>{{sftpInfo.password}}</span></p>
                        <fe-grid
                            ref="subdistrictGrid"
                            v-if="sftpInfo && sftpInfo.sub_district.length > 0"
                            title="Sub Districts"
                            :showToolbar="false"
                            :searchBar="false"
                            :rowData="sftpInfo && sftpInfo.sub_district"
                            :columnDefs="subDistrictColumnDefs"
                            style="height: 200px; width: 400px"
                            displayDensity="small"
                            disableInlineCreate
                        />
                  </div>

                  <div v-else>
                    Loading...
                  </div>
              </div>
            </template>
        </fe-grid>
        <fe-crud
            :config="$models.instance"
            ref="crud"
            :autoload="false"
        />
        <fe-dialog
            title="Create Instance"
            v-if="modifyDialog.show"
            @accept="finishModify"
            @close="modifyDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Create"
            :acceptButtonDisabled="!modifyDialog.valid || !$store.state.global.shareableStores.global_support.manage_instances || findDbMatch() || findUuidMatch()"
        >
            <p v-if="!$store.state.global.shareableStores.global_support.manage_instances">
            You do not have permission. Contact an eduCLIMBER developer.
            </p>
            <v-form v-model="modifyDialog.valid" v-if="$store.state.global.shareableStores.global_support.manage_instances">
                <fe-label>District Name</fe-label>
                <v-text-field
                    flat solo dense
                    v-model="modifyDialog.record.name"
                    :rules="$fieldValidators('text', null, { required: true, limit: 255})"
                />

                <fe-label>Database Name</fe-label>
                <v-text-field
                    flat solo dense
                    v-model="modifyDialog.record.district_db"
                    :rules="[!findDbMatch() || 'Database already in use by another instance'].concat($fieldValidators('text', null, { required: true, limit: 255}))"
                />

                <fe-label>IE Client UUID for SSO and SalesForce</fe-label>
                <v-text-field
                    flat solo dense
                    v-model="modifyDialog.record.ie_client_id"
                    :rules="[!findUuidMatch() || 'UUID already in use by another instance'].concat($fieldValidators('text', null, { required: true, limit: 36}))"
                    hint="Enter 'skip' to not require - not recommended."
                />
                <!-- <fe-label>ATD Instance</fe-label>
                <v-text-field
                    flat solo dense
                    v-model="modifyDialog.record.atd_instance"
                    :rules="$fieldValidators('text', null, { required: false, limit: 50})"
                />

                <fe-label>IO Instance</fe-label>
                <v-text-field
                    flat solo dense
                    v-model="modifyDialog.record.io_instance"
                    :rules="$fieldValidators('text', null, { required: false, limit: 50})"
                /> -->

                <fe-switch v-model="modifyDialog.record.include_ec" :label="`Enable eduCLIMBER`"></fe-switch>
                <fe-switch v-model="modifyDialog.record.include_ad" :label="`Enable AD Enterprise`"></fe-switch>
                <fe-switch v-model="modifyDialog.record.include_spark" :label="`Enable AD Spark`"></fe-switch>
            </v-form>
        </fe-dialog>
    </div>
</template>

<script>
    import SimpleGridDialogColumn from "@/components/common/SimpleGridDialogColumn"

    export default {
        name: 'InstanceInformation',
        components: {
            SimpleGridDialogColumn
        },
        watch: {
            'modifyDialog.show'(v) {
                if (!v) {
                    if (!this.modifyDialog.errored) this.modifyDialog.record = {}
                    this.modifyDialog.errored = false
                }
            }
        },
        data() {
            let me = this
            return {
                modifyDialog: {
                    show: false,
                    record: {},
                    valid: false,
                    errored: false // used to remember values from last failure
                },
                items: [],
                cell: null,
                isSftpInfoLoaded: false,
                sftpInfo: null,
                subDistrictColumnDefs: [{
                   headerName: "Client ID",
                   field: "client_id",
                   editable: false,
                }, {
                   headerName: "District",
                   field: "name",
                   editable: false,
                }, {
                   headerName: "FTP Directory",
                   field: "ftp_dir",
                   editable: false,
                }],
                columnDefs: [{
                   headerName: "ID",
                   field: "id",
                   editable: false,
                   minWidth: 80,
                   pinned: 'left'
                }, {
                   headerName: "District",
                   field: "name",
                   editable: false,
                   minWidth: 150,
                   pinned: 'left'
                }, {
                    headerName: "State",
                    field: "state",
                    editable: false,
                    minWidth: 40,
                    pinned: 'left'
                }, {
                   headerName: "DB Instance",
                   field: "district_db",
                   editable: false,
                   minWidth: 150,
                   pinned: 'left'
                }, {
                   headerName: "app_hostname",
                   field: "app_hostname",
                   editable: false,
                   flex: true
                }, {
                   headerName: "DB",
                   field: "db_hostname",
                   editable: false,
                   flex: true
                }, {
                    headerName: 'SFTP',
                    maxWidth: 55,
                    editable: false,
                    sortable: false,
                    cellRenderer: function (v) {
                        return '<i class="fe-grid-icon fal fa-external-link theme--light" style="font-size:12px"></i>';
                    },
                    onCellClicked: function(v) {
                        me.loadSftpInfoForDistrict(v.data.id)
                        me.$refs.grid.setDialogCell(v)
                        me.cellClicked(v)
                    },
                }, {
                   headerName: "AuthX UUID",
                   field: "authx_uuid",
                   editable: false,
                   flex: true
                }, {
                    headerName: 'AuthX Enabled',
                    field: 'authx_enabled',
                    editable: false,
                    flex: true,
                    cellRenderer: (v) => {
                        // Cell should only show a text value if an authx uuid exists for the district
                        if (!!v.data.authx_uuid) {
                            return v.data.authx_enabled ? 'Yes' : 'No'
                        } else {
                            return ''
                        }
                    },
                    hide: true,
                }, {
                   headerName: "SF Number",
                   field: "sf_number",
                   editable: false,
                   flex: true,
                   hide: true
                }, {
                   headerName: "SF Status",
                   field: "sf_status",
                   editable: false,
                   flex: true,
                   hide: true
                }, {
                   headerName: "ATD Instance",
                   field: "atd_instance",
                   editable: false,
                   flex: true
                }, {
                   headerName: "IO Instance",
                   field: "io_instance",
                   editable: false,
                   flex: true
                }, {
                    headerName: "Active",
                    field: "active",
                    editable: false,
                    flex: true,
                    valueGetter: v => {
                        return this.formatActiveText(v.data.active)
                    },
                    cellStyle: v => {
                        if (v.value === "Active") return {"color": "green"}
                        if (v.value === "Inactive") return {"color": "red"}
                    }
                }
                , {
                   headerName: "Created",
                   field: "created",
                   editable: false,
                   flex: true
                }]
            }
        },

        mounted() {
            this.loadData()
        },

        methods: {
            formatActiveText(activeStatus) {
                return activeStatus ? "Active" : "Inactive"
            },
            findDbMatch() {
                return !!this.items.find(i => i.district_db === this.modifyDialog.record.district_db);
            },
            findUuidMatch() {
                return !!this.items.find(i => i.authx_uuid === this.modifyDialog.record.ie_client_id);
            },
            switchDistrict(meta) {
                let me = this

                me.$messageBox({
                    title: 'Switch District',
                    persistent: true,
                    message: 'Are you sure you want to switch to ' + meta.data.name + '?',
                    maxWidth: '500',
                    actions: [{
                        text: 'Cancel',
                        primary: false
                    }, {
                        text: 'Ok',
                        primary: true,
                        onClick: () => {
                            me.$setLoading(true)
                            me.$axios.post('switchDistrict.php', { district_id: meta.data.id} )
                                .then(response => {})
                                .finally(() => {
                                    var iesso = document.getElementById('ie_session_iframe');
                                    if (iesso) iesso.parentNode.removeChild(iesso);
                                    me.$setLoading(false)
                                    location.reload()
                                })
                        }
                    }]
                })

            },
            loadData() {
                this.$refs.crud.read().then(r => {
                    this.items = this.$ecResponse(r, 'districts')
                })
            },
            loadSftpInfoForDistrict(district) {
                this.isSftpInfoLoaded = false
                this.sftpInfo = null
                this.$axios.get('instanceManager.php?action=get&property=sftp_info&district=' + encodeURIComponent(district))
                .then(response => {
                    this.isSftpInfoLoaded = true
                    this.sftpInfo = response.data
                })
            },
            beginCreate() {
                this.modifyDialog.show=true
            },
            cellClicked(cell) {
               this.cell = cell
            },
            cellDialogClosed(cell) {

            },
            setDialogCell(v) {
                this.openMenu = true
            },
            finishModify() {
                if (!/^[a-z0-9_]+_(db|live|test)$/.test(this.modifyDialog.record.district_db)) {
                    this.$snackbars.$emit('new', {
                        text: 'Invalid Database Name',
                        subtext: 'Database name must only contain lowercase letters, digits, and underscores. It must end with `_live`, `_test`, or `_db`.',
                        usage: 'error'
                    })
                    this.modifyDialog.errored = true
                    return false
                }
                if (!/^[a-f0-9-]+$/.test(this.modifyDialog.record.ie_client_id) && this.modifyDialog.record.ie_client_id !== 'skip') {
                    this.$snackbars.$emit('new', {
                        text: 'Invalid IE Client UUID',
                        subtext: 'Client UUID must be a valid UUID or the word skip',
                        usage: 'error'
                    })
                    this.modifyDialog.errored = true
                    return false
                }
                if (!this.modifyDialog.record.include_ec && !this.modifyDialog.record.include_ad && !this.modifyDialog.record.include_spark) {
                    this.$snackbars.$emit('new', {
                        text: 'Invalid product selection',
                        subtext: 'Select at least one product',
                        usage: 'error'
                    })
                    this.modifyDialog.errored = true
                    return false
                }
                if (this.modifyDialog.record.include_ad && this.modifyDialog.record.include_spark) {
                    this.$snackbars.$emit('new', {
                        text: 'Invalid product selection',
                        subtext: 'Select only AD Enterprise or AD Spark - not both.',
                        usage: 'error'
                    })
                    this.modifyDialog.errored = true
                    return false
                }

                this.$refs.crud.create({ district: this.modifyDialog.record })
                    .then(r => {
                        this.$ecResponse(r)
                        this.loadData()
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
