let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    user: {
        defaults: {
            endpoint: 'users.php',
            rootProperty: 'users'
        },
        read: {
            params: { action: 'get' },
            rootProperty: null
        },
        create: {
            params: { action: 'create' }
        },
        update: {
            params: { action: 'update' }
        },
    },
    userGroup: {
        defaults: {
            endpoint: 'userGroup.php',
            rootProperty: 'user_group'
        },
        ...standardCrud
    },
    userGroupSecurity: {
        defaults: {
            endpoint: 'userGroupSecurity.php',
            rootProperty: 'user_group_security'
        },
        ...standardCrud
    },
    userGroupUser: {
        defaults: {
            endpoint: 'userGroupUser.php',
            rootProperty: 'user_group_user'
        },
        ...standardCrud
    },
    userGroupAlias: {
        defaults: {
            endpoint: 'crud.php?property=UserGroupAlias',
            rootProperty: 'user_group_alias'
        },
        ...standardCrud
    },
    userSecurity: {
        defaults: {
            endpoint: 'userSecurity.php',
            rootProperty: 'user_security'
        },
        ...standardCrud
    },
    userGrade: {
        defaults: {
            endpoint: 'userGrade.php',
            rootProperty: 'user_grade'
        },
        ...standardCrud
    },
    userSchool: {
        defaults: {
            endpoint: 'userSchool.php',
            rootProperty: 'user_school'
        },
        ...standardCrud
    },

    userPreferences: {
        defaults: {
            endpoint: 'preferences.php',
            rootProperty: 'preferences'
        },
        ...standardCrud
    },

    userDoNotMerge: {
        defaults: {
            endpoint: 'crud.php?property=DistinctUser',
            rootProperty: 'distinct_user',
        },
        ...standardCrud
    },
    userMerge: {
        defaults: {
            endpoint: 'userMerge.php'
        },
        ...standardCrud
    },
}
