<template>
    <div class="flex-fill flex-column" style="overflow-y: scroll;" :key="`incident-response-`+selectedResponse.id">
        <fe-crud
            ref="crud"
            autoload
            :config="$models.incidentResponse"
            :readParams="{ id: selectedResponse.id }"
            @read="v => items = v"
        />

        <fe-dialog
            width="500"
            title="Rename Response"
            v-if="renameDialog.show"
            dismissButtonText="Cancel"
            @dismiss="renameDialog.show=false"
            @close="renameDialog.show=false"
            @accept="rename"
            :acceptButtonDisabled="!formValid"
            acceptButtonText="Save"
            persistent
        >
            <v-form @submit.prevent v-model="formValid" ref="form">
                <v-text-field
                    v-model="renameDialog.name"
                    flat solo dense
                    :counter="255"
                    placeholder="Enter a name..."
                    :rules="$fieldValidators('text', null, { required: true, limit: 255 })"
                />
            </v-form>
        </fe-dialog>

        <fe-dialog
            width="500"
            title="Merge With Response"
            dismissButtonText="Cancel"
            v-if="mergeDialog.show"
            @dismiss="mergeDialog.show=false"
            @accept="merge"
            :acceptButtonDisabled="!mergeDialog.record"
            acceptButtonText="Merge"
            persistent
        >
            <v-form ref="mergeForm">
                <fe-remote-combo
                    :url="$models.getUrl('incidentResponse', 'read')"
                    v-model="mergeDialog.record"
                    rootProperty='incident_responses'
                    flat solo dense
                    itemText='name'
                    itemValue='id'
                    required
                    placeholder="Select a response..."
                />
            </v-form>
        </fe-dialog>

        <fe-dialog
            width="500"
            height="500"
            title="Response Aliases"
            dismissButtonText="Cancel"
            v-if="aliasDialog.show"
            @dismiss="aliasDialog.show=false"
            @close="aliasDialog.show=false"
            persistent
        >
            <fe-crud
                ref="aliasCrud"
                :config="$models.incidentResponseAlias"
                autoload
                @read="v => aliases = v"
                :readParams="{ incident_response_id: selectedResponse.id }"
            />

            <fe-grid
                :columnDefs="aliasColumns"
                :rowData="aliases"
                style="height: 100%;"
                ref="aliasGrid"
                displayDensity="medium"
                :showToolbar="false"
            >
            </fe-grid>
        </fe-dialog>

        <div class="d-flex">
            <fe-title-select
                style="margin: 0px"
                fontColor="black"
                itemText="name"
                itemValue="id"
                placeholder="Select a Response"
                :items="responseItems"
                v-model="selectionChange"
            ></fe-title-select>

            <div class="ml-auto">
                <menu-btn>
                    <v-list-item @click="renameDialog.show = true; renameDialog.name = selectedResponse.name">
                        Rename
                    </v-list-item>

                    <v-list-item @click="mergeDialog.show = true">
                        Merge
                    </v-list-item>

                    <v-list-item @click="aliasDialog.show = true">
                        Aliases
                    </v-list-item>

                    <v-divider/>

                    <v-list-item @click="deleteRecord">
                        Delete
                    </v-list-item>
                </menu-btn>
            </div>
        </div>

        <div>
            <v-layout row wrap>
                <v-flex xs12>
                    <fe-label>Description</fe-label>
                    <v-textarea @change="item.description.length <= 255 ? saveRecord() : () => {}" flat solo dense v-model="item.description" counter=255 :rules="$fieldValidators('text', null, { required: false, limit: 255 })"/>
                </v-flex>

                <template v-for="(toggle) in toggles.filter(r => !r.restrict)">
                    <v-flex xs4 lg3 :key="`response-`+toggle.model">
                        <label-field :labelText="toggle.label" labelAlign="left">
                            <v-switch v-model="item[toggle.model]" @change="saveRecord"/>
                        </label-field>
                    </v-flex>
                </template>

                <v-flex xs6 md4 lg3>
                    <label-field labelText="State Code" labelAlign="left" class="mr-2">
                        <v-text-field flat solo dense v-model="item.state_code" @change="saveRecord"/>
                    </label-field>
                </v-flex>


                <v-flex xs6 md4>
                    <label-field labelText="Restrict to Schools" labelAlign="left" class="pr-2">
                        <crud-select
                            :itemsModel="$models.school"
                            :crudModel="$models.incidentResponseSchool"
                            :crudModelReadParams="{ incident_response_id: selectedResponse.id }"
                            :crudModelCreateParams="{ incident_response_id: selectedResponse.id }"
                            crudItemValue="school_id"
                            itemText="name"
                            itemValue="id"
                            multiple
                        />
                    </label-field>
                </v-flex>

                <v-flex xs6 md4>
                    <label-field labelText="Restrict to Type" labelAlign="left" class="pr-2">
                        <crud-select
                            :itemsModel="$models.incidentBehaviorType"
                            :crudModel="$models.incidentResponseIncidentBehaviorType"
                            :crudModelReadParams="{ incident_response_id: selectedResponse.id }"
                            :crudModelCreateParams="{ incident_response_id: selectedResponse.id }"
                            crudItemValue="incident_behavior_type_id"
                            itemText="name"
                            itemValue="id"
                            multiple
                        />
                    </label-field>
                </v-flex>

                <v-flex xs12 class="mt-8">
                    <incident-response-fields :incidentResponseId="selectedResponse.id"/>
                </v-flex>

                <v-flex xs12 class="mt-10">
                    Created at {{item.created}} by {{item.created_by_full_name ? item.created_by_full_name : 'eduCLIMBER'}}
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>

<script>
    import EditList from '@/components/common/EditList'
    import LabelField from '@/components/common/LabelField'
    import CrudSelect from '@/components/common/combo/CrudSelect'
    import Vuex from 'vuex'
    import { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'
    import MenuBtn from '@/components/common/button/MenuBtn'
    import IncidentResponseFields from './IncidentResponseFields'

    export default {
        name: 'IncidentTypeCard',
        inject: ['localStore'],
        components: {
            EditList,
            MenuBtn,
            LabelField,
            CrudSelect,
            IncidentResponseFields
        },
        props: {
            incidentBehaviorTypeId: {

            }
        },
        watch: {
            selectionChange(v) {
                this.selectedResponse = v
                this.$addCrumb('Incidents', {
                    name: v.name,
                    show: 'response'
                }, 2)
            }
        },
        computed: {
            ...mapState('manage', ['breadcrumbs']),
            ...mapLocalState(['statusFields', 'toggleFields', 'statusOptions', 'selectedResponse']),
            item() {
                if (this.items.length > 0) {
                    return this.items[0]
                }

                return {
                    incident_fields: []
                }
            },
            fieldRules() {
                this.$fieldValidators('text', 'Name', 255)
            },
            crudModel() {
                let me = this
                let m = JSON.parse(JSON.stringify(this.$models.incidentResponse))
                m.read.params.id = this.selectedResponse.id
                return m
            },
            aliasColumns() {
                return [{
                    field: 'alias',
                    flex: 1,
                    headerName: 'Alias'
                }, {
                    headerName: 'Delete',
                    width: 100,
                    cellRenderer:() => {
                        return '<i class="fas fa-trash"></i>'
                    },
                    cellStyle: { textAlign: 'center', cursor: 'pointer' },
                    onCellClicked:(meta) => {
                        this.$confirmDelete([meta.data], () => {
                            this.$refs.aliasCrud.destroy([meta.data])
                                .then(() => {
                                    this.$refs.crud.read()
                                    this.aliasDialog.show=false
                                })
                        })
                    }
                }]
            }
        },
        mounted() {
            this.$modelGet('incidentResponse').then(v => {
                this.responseItems = v
                this.selectionChange = this.responseItems.find(rec => rec.id === this.selectedResponse.id)
            })
        },
        data() {
            return {
                responseItems: [],
                selectionChange: null,
                renameDialog: {
                    show: false,
                    name: ''
                },
                mergeDialog: {
                    show: false,
                    record: null
                },
                aliasDialog: {
                    show: false
                },
                aliases: [],
                formValid: false,
                items: [],
                toggles: [{
                    model: 'admin_only',
                    label: 'Administration Only'
                }, {
                    model: 'serve_flag',
                    label: 'Can Serve'
                }, {
                    model: 'active',
                    label: 'Active'
                }, {
                    model: 'kpi',
                    label: 'Enable KPI',
                    restrict: !this.$hasSecurity('MANAGE_ACHIEVEMENT_DASHBOARD')
                }, {
                    model: 'require_responder',
                    label: 'Requires Responder'
                }]
            }
        },
        methods: {
            merge() {
                if (!this.mergeDialog.record) {
                    return
                }

                this.$confirmCreate([this.item], () => {
                    this.item.merge_to_id = this.mergeDialog.record.id
                    this.$refs.crud.update([this.item])
                        .then(response => {
                            this.$addCrumb('Incidents', {
                                name: 'Responses',
                                show: 'responses'
                            }, 1)
                            // this.selectedResponse = null
                            // this.breadcrumbs.splice(this.breadcrumbs.length-1, 1)
                        })
                        .catch(error => {
                        })
                        .finally(() => {
                            this.mergeDialog = {
                                show: false,
                                record: null
                            }
                        })
                }, 'Merge')
            },
            menuButtonText(item) {
                if (!item) return

                if (this.manageBehavior.show) {
                    return 'Back'
                }
                return item.incident_behaviors.length + ' Codes'
            },
            go(to) {
                this.selectedIncidentType = this.item
                this.breadcrumbs.push(to)
            },
            selectNavigation(sel) {
                if (sel.name.indexOf('Custom') > -1) {
                    this.fieldsDialog.show = true
                    // this.selectedIncidentType = this.item
                    // this.breadcrumbs.push({
                    //     name: 'Custom Fields',
                    //     show: 'fields'
                    // })
                } else {
                    this.selectedIncidentType = this.item
                    this.$addCrumb('Incidents', {
                        name: 'Codes',
                        show: 'codes'
                    })
                }
            },
            rename() {
                if (this.renameDialog.name && this.$refs.form.validate()) {
                    this.item.name = this.renameDialog.name
                    // get the total count of breadcrumbs
                    let count = this.breadcrumbs.Incidents.length
                    let r = {...this.breadcrumbs.Incidents[count-1]}
                    r.name = this.renameDialog.name
                    this.$addCrumb('Incidents', r, count-1)
                    // this.breadcrumbs[this.breadcrumbs.length-1].name = this.renameDialog.name
                    this.saveRecord()
                    this.renameDialog = {
                        show: false,
                        name: ''
                    }
                }
            },
            saveRecord() {
                let item = this.item
                let crud = this.$refs.crud

                if (item.id) {
                    crud.update([item])
                        .then(r => {})
                } else {
                    crud.create([item])
                        .then(r => {})
                }
            },
            deleteRecord(item) {
                let me = this

                this.$confirmDelete(this.item, () => {
                    this.$refs.crud.destroy([this.item])
                        .then(response => {
                            this.$removeCrumb("Incidents", 1)
                            // this.selectedResponse = null
                            // this.breadcrumbs.splice(this.breadcrumbs.length-1, 1)

                        })
                        .catch(error => {
                            console.warn('error', error)
                        })
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
