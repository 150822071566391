<template>
    <highcharts v-if="chartTotal > 0" ref="hc" :options="chartOptions"/>
</template>

<script>
export default {
    name: 'Bar',

    props: {
        height: Number,
        values: Array,
        labels: Array,
        urlParams: Array,
        colors: Array,
        name: String,
        category: String,
        title: String,
        max: Number
    },

    computed: {
        chartHeight() {
            return '40px'
        },

        chartTotal() {
            return this.values.reduce((tot, cur) => {
                return tot + cur
            }, 0)
        },

        chartSeries() {
            return this.values.map((v, i) => {
                return {
                    pointPadding: 0,
                    name: this.labels[i] ? this.labels[i] : `Item ${(i + 1)}`,
                    color: this.colors[i],
                    data: [{
                        y: v,
                        header: this.labels[i] ? this.labels[i] : `Item ${(i + 1)}`,
                        pcnt: `${this.getValuePercentage(v)}%`,
                        urlParams: this.urlParams && this.urlParams[i] ? this.urlParams[i] : null
                    }]
                }
            })
        },

        chartName() {
            return this.name || 'Not Specified'
        },

        chartOptions() {
            return {
                chart: {
                    type: 'bar',
                    height: this.chartHeight,
                    margin: [0, 15, 30, 15],
                    style: {
                        fontFamily: "Cerebri Sans"
                    }
                },
                title: {
                    useHTML: true,
                    text: this.chartName,
                    align: 'left',
                    style: {
                        fontSize: '10px',
                        paddingLeft: '5px'
                    },
                    floating: true,
                    x: 0,
                    y: 15
                },
                subtitle: {
                    useHTML: true,
                    text: `${this.chartTotal} Students`,
                    align: 'right',
                    style: {
                        fontSize: '10px',
                        paddingRight: '5px'
                    },
                    x: 0,
                    y: 15
                },
                tooltip: {
                    outside: true,
                    headerFormat: '<span style="font-size: 10px">{series.name}</span><br/>',
                    pointFormat: '{point.y} students <b>({point.pcnt})</b>'
                },
                plotOptions: {
                    series: {
                        stacking: this.max ? 'normal' : 'percent'
                    },
                    bar: {
                        pointWidth: 10,
                        borderRadius: 5,
                        events: {
                            click: this.showData
                        }
                    }
                },
                series: this.chartSeries,
                xAxis: {
                    visible: false,
                    maxPadding: 0
                },
                yAxis: {
                    visible: false,
                    min: 0,
                    max: this.max || null,
                    height: this.chartHeight,
                    maxPadding: 0
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                colors: this.colors
            }
        }
    },

    methods: {
        getValuePercentage(val) {
            return Math.round((val * 100) / (this.max || this.chartTotal))
        },

        showData(obj) {
            if (obj.point.options.urlParams) {
                this.$emit('showDataTable', {
                    urlParams: obj.point.options.urlParams,
                    title: this.category,
                    subtitle: this.chartName,
                    group: obj.point.options.header
                })
            }
        }
    }
}
</script>
