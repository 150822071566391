<template>
    <div v-if="editor" class="d-flex justify-center empty-editor">
        {{fieldRec.name}}
    </div>
</template>

<script>
    export default {
        name: 'PageBreak',
        props: ['fieldRec','editor'],
        data() {
            return {}
        }
    }
</script>

<style lang="scss" scoped>
div.empty-editor {
    display: block;
    border-bottom: 3px dashed rgba(0,0,0,.3);
}
</style>
