<template>
    <div>
        <v-menu offset-y v-model="isVisible">
            <template v-slot:activator="{ on }">
                <div v-on="on" class="menu-btn" :class="{'menu-btn-large': !small}" v-ripple>
                    <v-icon :style="{fontSize: fontSize}">{{useIcon}}</v-icon>
                </div>
            </template>

            <v-list>
                <slot/>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    export default {
        name: 'MenuBtn',
        props: {
            useIcon: {
                default: 'more_vert'
            },
            small: {
                type: Boolean,
                default: true
            },
            fontSize: {
                default: '22px'
            }
        },
        data() {
            return {
                isVisible: false,
            }
        },
        methods: {
            hide() {
                this.isVisible = false
            },
        },
    }
</script>

<style lang="scss" scoped>
.menu-btn {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    &:hover {
        background-color: #f7f7f7;
    }
    &-large {
        padding: 4px;
        width: 32px;
        height: 32px;
        margin: 8px 0 8px 0;
    }
}

/*
permit overflow (and content outside typical contain region)
so that nested submenus (to the side > of a list item) can appear without being clipped
*/
.v-menu__content {
    contain: none;
    overflow: visible !important;
}
    .v-menu__content {
        ::v-deep * {
            overflow: visible !important;
        }
    }

/*
allow nested submenus to display on hover
*/
.v-list {
    ::v-deep .v-list-item .child-menu {
        position: absolute;
        display: none;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 5px;
        top: 0;
        /*
            position child menu to the left, based on observations that
            MenuBtns are typically presented at the right edge of the screen
        */
        transform: translate(calc(-100% - 16px), 0);
        white-space: nowrap;
    }
    ::v-deep .v-list-item:hover .child-menu {
        display: block;
    }
}
</style>