<template>
    <div>
        <fe-card
            class="alt-card ma-3"
            headerText="Intervention Overview"
        >
            <template v-slot:body>
                <fe-dialog
                    title="Active Plan Details"
                    v-if="dialog.show"
                    @accept="dialog.show=false"
                    @close="dialog.show=false"
                    persistent
                    dismissButtonText="Cancel"
                    acceptButtonText="Done"
                    :width="gridWidth"
                >
                    <fe-grid
                        style="height: 400px;"
                        :rowData="dialog.data"
                        :columnDefs="columns"
                        displayDensity="small"
                        groupUseEntireRow
                    />
                </fe-dialog>

                <v-skeleton-loader
                    class="mx-auto"
                    type="text@5"
                    :loading="isLoading"
                    style="min-height: 120px;"
                >
                    <div class="text-center" style="height: 140px;">
                        <div class="section-title mb-2 clickable" @click="setDetail('student')">{{items.length}} Total Plans</div>

                        <fe-tooltip :tooltip="planToString(summary.student)+` of `+studentCount+` students`">
                            <div v-if="Object.keys(summary.student).length" @click="setDetail('student')" class="clickable">
                                {{ popPct(summary.student) }}% of population in intervention
                            </div>
                        </fe-tooltip>

                        <div @click="setDetail('type')" class="clickable">
                            {{ planToString(summary.type) }} Strategies
                        </div>

                        <div @click="setDetail('user')" class="clickable">
                            {{ planToString(summary.user) }} Interventionists
                        </div>

                        <div @click="setDetail('school')" class="clickable">
                            {{ planToString(summary.school) }} Schools
                        </div>
                    </div>
                </v-skeleton-loader>
            </template>
        </fe-card>
    </div>
</template>

<script>
    export default {
        name: 'ActivePlans',
        props: {
            params: {

            }
        },
        watch: {
            params: {
                handler(v) {
                    if (v && Object.values(v).length) this.loadData()
                },
                immediate: true
            }
        },
        computed: {
            gridWidth() {
                return window.innerWidth-50
            }
        },
        data() {
            return {
                items: [],
                studentCount: 0,
                isLoading: false,
                summary: {
                    type: {},
                    group: {},
                    school: {},
                    grade: {},
                    user: {},
                    student: {}
                },
                columns: [{
                   headerName: "Student",
                   field: "student_full_name",
                   editable: false,
                   width: 150
                }, {
                   headerName: "Strategy",
                   field: "intervention_type_name",
                   editable: false,
                   width: 150
                }, {
                   headerName: "Interventionist",
                   field: "user_full_name",
                   editable: false,
                   width: 150
                }, {
                   headerName: "School",
                   field: "school_name",
                   editable: false,
                   flex: 1
                }, {
                   headerName: "Grade",
                   field: "grade_desc",
                   editable: false,
                   width: 80
                }, {
                   headerName: "Start Date",
                   field: "intervention_plan_start_date",
                   editable: false,
                   width: 100
                }, {
                   headerName: "End Date",
                   field: "intervention_plan_end_date",
                   editable: false,
                   width: 100
                }],
                dialog: {
                    show:false,
                    data: []
                }
            }
        },
        methods: {
            planToString(type) {
                let num = Object.keys(type).length && Object.keys(type).length > 1 ? Object.keys(type).length - 1 : 0
                return num.toString()
            },
            popPct(type) {
                let num = Object.keys(type).length && Object.keys(type).length > 1 ? Object.keys(type).length - 1 : 0
                return (parseFloat(num / this.studentCount)*100).toFixed(1)
            },
            setDetail(key) {
                this.$emit('drilldown', this.params)
                return
                this.$dockableWindow({
                    name: 'Intervention Drilldown',
                    component: 'intervention-drilldown',
                    attrs: {
                        params: this.params
                    }
                })
                return
                this.columns.forEach(r=> { r.rowGroup=false; r.hide=false })
                let c = null

                switch (key) {
                    case 'school':
                        c = this.columns.find(r=>r.field==='school_name')
                        c.rowGroup = true
                        c.hide=true
                        break
                    case 'type':
                        c = this.columns.find(r=>r.field==='intervention_type_name')
                        c.rowGroup = true
                        c.hide=true
                        break
                    case 'user':
                        c = this.columns.find(r=>r.field==='user_full_name')
                        c.rowGroup = true
                        c.hide=true
                        break
                }

                this.dialog.data = this.summary[key].data
                this.dialog.show = true
            },
            loadData() {
                this.$axios.get('demographics.php?action=get&property=student&chart_types=student%2C&'+this.$objectToParams(this.params))
                    .then(r => {
                        let data = null
                        let arr = this.$ecResponse(r, 'demographics') || []
                        if (arr.length) data = arr[0]
                        this.studentCount = Object.values(data.total_count)[0]

                        this.isLoading=true
                        this.$axios.get('interventionView.php?action=get&property=student_intervention_summary&'+this.$objectToParams(this.params))
                            .then(r => {
                                this.items = this.$ecResponse(r, 'interventions')
                                this.processData()
                                this.isLoading = false
                            })
                    })
            },
            processData() {
                this.summary = {
                    type: {},
                    group: {},
                    school: {},
                    grade: {},
                    user: {},
                    student: {}
                }

                let base = [{
                    group: 'type',
                    key: 'intervention_type_name',
                    name: 'intervention_type_name'
                }, {
                    group: 'user',
                    key: 'intervention_plan_user_id',
                    name: 'user_full_name'
                }, {
                    group: 'school',
                    key: 'school_name',
                    name: 'school_name'
                }, {
                    group: 'grade',
                    key: 'grade_desc',
                    name: 'grade_desc'
                }, {
                    group: 'student',
                    key: 'student_id',
                    name: 'student_full_name'
                }]

                this.items.forEach(i=> {
                    base.forEach(b => {
                        // Build the groupings
                        let group = this.summary[b.group]
                        if (!group.data) group.data = []
                        // Build the summary
                        if (!group[i[b.key]]) {
                            group[i[b.key]] = {
                                name: i[b.name],
                                data: [],
                                count: 0
                            }
                        }

                        let rec = group[i[b.key]]
                        rec.data.push(i)
                        group.data.push(i)
                        rec.count++
                    })
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.clickable {
    cursor: pointer;
}
</style>
