<template>
    <form-common-config
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #fieldSpecific="{rec}">
            <div class="d-flex">
                <fe-label :style="labelStyle">User Tag</fe-label>
                <fe-remote-combo
                    v-model="rec.tag_id"
                    byId
                    rootProperty="tags"
                    url="tags.php?action=get&property=available_user_tags"
                    style="margin-left:auto; max-width: 147px;"
                    :clearable="false"
                >
                    <template #selection="{item}">
                        <v-icon :color="item.color" size="12" class="mr-2">fa-tag</v-icon>
                        <v-icon v-if="item.public_flag" size="12" color="rgba(0,0,0,.54)" class="mr-2">fa-globe-americas</v-icon>
                        {{item.name}}
                    </template>
                    <template #item="{item}">
                        <v-icon size="12" :color="item.color" class="mr-2">fa-tag</v-icon>
                        <v-icon v-if="item.public_flag" size="12" color="rgba(0,0,0,.54)" class="mr-2">fa-globe-americas</v-icon>
                        <v-list-item-title v-html="item.name"/>
                    </template>
                </fe-remote-combo>
            </div>
        </template>
    </form-common-config>
</template>

<script>
import FormCommonConfig from '../FormCommonConfig'

export default {
    inject: ['localStore'],
    components: {
        FormCommonConfig,
    },
    computed: {
        labelStyle: me => ({ minWidth:'120px' }),
    },
}
</script>

<style lang="scss" scoped>
</style>
