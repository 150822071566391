import Vue from 'vue';

export default Vue.extend({
    template: `<input :ref="'input'" :style="cssStyles" @keydown="onKeyDown($event)" v-model="value"/>`,
    data() {
        return {
            value: '',
            cancelBeforeStart: true,
            cssStyles: {
                width: '100%',
                'text-align': 'right',
                padding: '0px 12px',
            },
        };
    },
    methods: {
        getValue() {
            return this.value;
        },

        isCancelBeforeStart() {
            return this.cancelBeforeStart;
        },

        // will reject the number if it greater than 1,000,000
        // not very practical, but demonstrates the method.
        isCancelAfterEnd() {
            return this.value > 1000000;
        },

        onKeyDown(event) {
            if (!this.isKeyPressedNumeric(event)) {
                if (event.preventDefault) event.preventDefault();
            }
        },

        getCharCodeFromEvent(event) {
            event = event || window.event
            let charCode = typeof event.which === 'undefined' ? event.keyCode : event.which

            // If it's a numpad key, then event.which is not going to
            // translate as expected via .which property (try it and see)
            // Here then, we check numpads as special cases
            if (event.code && `${event.code}`.substring(0, 6).toLowerCase() == 'numpad') {
                let numpadNumberAsString = `${event.code}`.substring(6)
                charCode = numpadNumberAsString.charCodeAt(0) // Now e.g. Numpad5 (e.which = 101) will return a charCode = 53, which is what we want
            }

            return charCode
        },

        isCharNumeric(charStr) {
            return /\d/.test(charStr);
        },

        isKeyPressedNumeric(event) {
            const charCode = this.getCharCodeFromEvent(event);
            // backspace / tab / enter / delete / left arrow / right arrow / period
            let accept = [8, 9, 13, 37, 39, 46, 110, 190]
            const charStr = String.fromCharCode(charCode);
            return this.isCharNumeric(charStr) || accept.indexOf(charCode) > -1;
        },
    },
    created() {
        this.value = this.params.value;
        // only start edit if key pressed is a number, not a letter
        this.cancelBeforeStart =
        this.params.charPress && '1234567890.'.indexOf(this.params.charPress) < 0;
    },
    mounted() {
        Vue.nextTick(() => {
            // need to check if the input reference is still valid - if the edit was cancelled before it started there
            // wont be an editor component anymore
            if (this.$refs.input) {
                this.$refs.input.focus();
            }
        });
    },
});