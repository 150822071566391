<template>
    <div ref="componentContainer">
        <stepper-navigation v-model="stepper" :items="stepperItems" :nextDisabled="stepperNextDisabled"/>

        <div class="pa-4 fill-height d-flex flex-column" :style="{height: (windowHeight-80) + 'px'}">
            <keep-alive>
                <background
                    v-if="stepper.id == 'background'"
                    v-model="localModel.background"
                    :studentId="studentId"
                    :schoolYearId="schoolYearId"
                    @modified="onUpdateObservationBackground"
                    @close="$emit('close')"
                    @next="stepper = stepperItems[1]"
                />

                <setup
                    v-else-if="stepper.id == 'setup'"
                    v-model="localModel.setup"
                    @close="$emit('close')"
                    @next="stepper = stepperItems[2]"
                    @previous="stepper = stepperItems[0]"
                    @refresh="$emit('refresh')"
                    @refreshObservation="onUpdateObservationSetup"
                />

                <observation-landing
                    v-else-if="stepper.id == 'observation'"
                    :observationBehaviorTypes="observationBehaviorTypes"
                    :observationBehaviors="selectedObservationBehaviors"
                    :studentObservationId="localModel.student_observation_id"
                    @close="$emit('close')"
                    @next="stepper = stepperItems[3]"
                    @previous="stepper = stepperItems[1]"
                />

                <div v-else class="flex-grow-1">
                    <iframe class="local-pdf-iframe fill-height" :src="pdfUrl"/>
                    <div>
                        <div class="d-flex footerBtns">
                            <v-spacer/>
                            <fe-btn usage="ghost" @click="$emit('close')">Done</fe-btn>

                            <fe-btn @click="stepper = stepperItems[2]">Previous</fe-btn>
                        </div>
                    </div>
                </div>
            </keep-alive>
        </div>

        <fe-crud
            ref="crudBehaviorTypes"
            :config="$models.observationBehaviorTypes"
            autoload
            @read="observationBehaviorTypes = $event"
        />
        <fe-crud
            ref="crudBehaviors"
            :config="$models.observationBehaviors"
            autoload
            @read="observationBehaviors = $event"
        />
        <fe-crud ref="crudStudentObservation" :config="$models.studentObservations"/>
    </div>
</template>

<script>
import StepperNavigation from '@/components/modules/analytics/StepperNavigation'
import Background from './Background'
import ObservationLanding from './ObservationLanding'
import Setup from './Setup'

export default {
    name: 'ObservationCreation',

    components: {
        StepperNavigation,
        Background,
        Setup,
        ObservationLanding,
    },

    props: {
        studentId: {
            type: Number,
            required: true
        },
        schoolYearId: {
            type: Number,
            required: true
        },
        studentObservationId: {
            type: Number,
            required: false
        },
        isNew: {
            type: Boolean
        }
    },

    data() {
        return {
            stepper: {id: 'background'},
            observationBehaviorTypes: [],
            observationBehaviors: [],
            localModel: {
                student_observation_id: null,
                background: {
                    course: null,
                    description: '',
                },
                setup: {
                    observation_behaviors: [],
                },
            },
            stepperItems: [
                {
                    id: 'background',
                    name: 'Background',
                },
                {
                    id: 'setup',
                    name: 'Setup',
                },
                {
                    id: 'observation',
                    name: 'Observation',
                },
                {
                    id: 'summary',
                    name: 'Summary',
                },
            ],
            windowHeight: null,
        }
    },

    computed: {
        selectedObservationBehaviors() {
            return this.observationBehaviors.filter(itm => this.localModel.setup.observation_behaviors.includes(itm.id))
        },

        pdfUrl() {
            let host = this.$axios.defaults.baseURL
            return `${host}observations.php?action=get&property=summary&id=${this.localModel.student_observation_id}&pdf=1`
        },
        stepperNextDisabled() {
            return this.stepper.id === 'background' && (!this.localModel.background?.course || !this.localModel.background?.description)
        }
    },

    watch: {
        'stepper.id'(v, prevVal) {
            if (prevVal === 'background') {
                if (this.localModel.student_observation_id) {
                    return
                }

                this.$refs.crudStudentObservation.create({
                    student_id: this.studentId,
                    course_id: this.localModel.background.course.course_id,
                    description: this.localModel.background.description,
                }).then(res => {
                    this.localModel.student_observation_id = res.data?.student_observations[0].id
                    this.$emit('refresh')
                })
            }
        },
    },

    async mounted() {
        if (this.studentObservationId) {
            let model = await this.$refs.crudStudentObservation.read({id: this.studentObservationId}).then(res => res.data.student_observations[0])
            this.localModel = {
                student_observation_id: model.id,
                background: {
                    course: {course_id: model.course_id},
                    description: model.description,
                },
                setup: {
                    observation_behaviors: [],
                },
            }
        }

        let height = this.$refs?.componentContainer?.clientHeight
        this.windowHeight = height > 864 ? height - 52 : height > 812 && height < 864 ? 812 : height
    },

    methods: {
        onUpdateObservationBackground() {
            if (this.localModel.student_observation_id) {
                this.$refs.crudStudentObservation.update({
                    id: this.localModel.student_observation_id,
                    student_id: this.studentId,
                    course_id: this.localModel.background.course.course_id,
                    description: this.localModel.background.description,
                })
            }
        },

        onUpdateObservationSetup() {
            this.$refs.crudBehaviors.read()
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .title-text {
    font-size: 18px !important;
}

.local-pdf-iframe {
    width: 100%;
    min-height: 300px;
    border: solid 1px #ccc;
    border-radius: 3px;
}

.footerBtns {
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: #FFFFFF;
    width: 100%;
    height: 80px;
    padding: 20px;
}
</style>
