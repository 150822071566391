import Vue from 'vue';

export default Vue.extend({
    template: `<v-autocomplete
        v-model="value"
        ref="control"
        open-on-clear
        flat solo dense
        :items="items"
        :item-text="params.itemText"
        :item-value="params.itemValue"
        :multiple="multiple"
        @input="valueChanged"
        :return-object="false"
        clearable
    >
        <template v-slot:item="data">
            <div v-html="data.item[params.itemText]"></div>
        </template>
        <template v-slot:selection="data">
            <div v-html="data.item[params.itemText]"></div>
        </template>
    </v-autocomplete>`,
    name: 'CellSelect',
    data () {
        return {
            value: [],
            multiple: false,
            items: []
        }
    },
    // props: {
    //     items: {
    //         required: true,
    //         type: Array
    //     },
    //     multiple: {
    //         type: Boolean,
    //         default: false
    //     }
    // },
    methods: {
        getValue() {
            return this.value
        },
        trigger () {
            if (this.$refs.control) {
                this.$refs.control.$el.querySelector('input').click()
            }
        },
        valueChanged (v) {
            if (!this.params.multiple) this.exit()
        },
        exit() {
            this.params.api.stopEditing()
        },
        isPopup() {
            return true
        }
    },
    mounted() {
        this.value = this.params.value
        this.items = this.params.items
        this.itemText = this.params.itemText
        this.itemValue = this.params.itemValue

        this.$nextTick(() => {
            this.trigger()
        })
    }
});