<template>
    <div>
        <v-form v-if="ready" ref="form" @submit.prevent v-model="isValid">
            <fe-label class="mt-0">Visualization Name</fe-label>
            <v-text-field
                v-model="config.name"
                counter=50
                :rules="this.$fieldValidators('text', null, {required: true, limit: 50})"
                flat solo dense clearable autofocus
            />

            <fe-remote-exclude-combo
                label="Select a Tag(s) (Optional)"
                :url="tagsUrl"
                rootProperty="tags"
                v-model="savedSearch.tag_id"
            />

            <fe-remote-combo
                label="Report As"
                :items="reportAsOptions"
                v-model="config.value"
                :rules="this.$fieldValidators('select', null, {required: true})"
                byId
            />

            <group-by v-model="savedSearch.group_by" :visType="config.config.type"/>

            <vis-type v-model="config.visualization"/>

            <student-filters
                v-if="!globalFilters"
                :filters="studentFilters"
                :dashboard="dashboard"
                :tags="false"
                @change="studentFilters = $event"
            />

            <fe-label>Enter a Short Description</fe-label>
            <v-textarea
                v-model="config.description"
                counter=255
                rows=4
                :rules="this.$fieldValidators('text', null, {required: false, limit: 255})"
                flat solo dense no-resize
            />

            <display-settings :value="displaySettings" @change="displaySettings = $event"/>
        </v-form>
    </div>
</template>

<script>
import VisType from "./fields/VisType"
import GroupBy from "./fields/GroupBy"
import StudentFilters from "./fields/StudentFilters"
import DisplaySettings from "./fields/DisplaySettings"

export default {
    name: "Tag",

    components: {
        VisType,
        GroupBy,
        StudentFilters,
        DisplaySettings
    },

    props: {
        dashboard: {
            type: Object,
            required: true
        },
        edit: {
            type: Object,
            required: false
        },
        getSSValues: {
            type: Function,
            required: true
        },
        getSchoolYears: {
            type: Function,
            required: true
        },
        setConfig: {
            type: Function,
            required: true
        },
        globalFilters: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            ready: false,
            isValid: false,
            config: {
                name: '',
                description: '',
                visualization: undefined,
                value: undefined,
                colors: [],
                yAxisMin: undefined,
                yAxisMax: undefined,
                config: {
                    type: 'tag',
                    description: '',
                    visualization: undefined,
                    value: undefined,
                    colors: [],
                    yAxisMin: undefined,
                    yAxisMax: undefined,
                }
            },
            savedSearch: {
                tag_id: undefined,
                group_by: undefined,
                school_id: undefined,
                grade_id: undefined,
                ethnicity: undefined,
                disability_type_id: undefined,
                gender: undefined,
                meal_status_id: undefined,
                ell_flag: undefined,
                ell_level_id: undefined,
                student_active_flag: undefined,
                student_active_date: undefined,
                demographic: undefined
            }
        }
    },

    computed: {
        displaySettings: {
            get() {
                return {
                    colors: this.config.colors,
                    yAxisMin: this.config.yAxisMin,
                    yAxisMax: this.config.yAxisMax
                }
            },

            set(v) {
                Object.keys(v).forEach(x => {
                    if (this.config.hasOwnProperty(x)) {
                        this.config[x] = v[x]
                    }
                })
            }
        },

        studentFilters: {
            get() {
                return {
                    school_id: this.savedSearch.school_id,
                    grade_id: this.savedSearch.grade_id,
                    ethnicity: this.savedSearch.ethnicity,
                    disability_type_id: this.savedSearch.disability_type_id,
                    gender: this.savedSearch.gender,
                    tag_id: this.savedSearch.tag_id,
                    meal_status_id: this.savedSearch.meal_status_id,
                    ell_flag: this.savedSearch.ell_flag,
                    ell_level_id: this.savedSearch.ell_level_id,
                    student_active_flag: this.savedSearch.student_active_flag,
                    student_active_date: this.savedSearch.student_active_date,
                    demographic: this.savedSearch.demographic
                }
            },

            set(v) {
                Object.keys(v).forEach(x => {
                    if (this.savedSearch.hasOwnProperty(x)) {
                        this.savedSearch[x] = v[x]
                    }
                })
            }
        },

        tagsUrl() {
            let url = this.$models.getUrl('tag', 'read') + '&sort=name&dir=ASC'
            let years = this.getSchoolYears(this.dashboard.school_year_name).map(x => x.id).join(',')
            url += '&school_year_id=' + years
            return url
        },

        reportAsOptions() {
            let opts = [{
                id: 'student_total',
                name: 'Total Students'
            }, {
                id: 'percentage',
                name: 'Percent of Students'
            }]

            return opts
        }
    },

    watch: {
        isValid(n, o) {
            if (n !== o) {
                this.$emit('valid', n)
            }
        },

        config: {
            deep: true,
            handler(v) {
                this.$emit('change', v, 'config')
            }
        },

        savedSearch: {
            deep: true,
            handler(v) {
                this.$emit('change', v, 'savedSearch')
            }
        }
    },

    mounted() {
        let awaiting = []

        if (this.edit) {
            this.config = this.setConfig(this.config, this.edit)
            awaiting.push(this.getSSValues(this.config.saved_search_id, this.savedSearch))
        } else {
            // default filters
            this.savedSearch.student_active_flag = {id: 1}
        }

        Promise.all(awaiting)
            .then(() => {
                this.setColors()
                if (this.globalFilters) {
                    let tags = this.savedSearch.tag_id
                    this.getSSValues(this.dashboard.saved_search_id, this.savedSearch)
                        .then(() => {
                            this.savedSearch.tag_id = tags
                            this.ready = true
                        })
                } else {
                    this.ready = true
                }
            })
    },

    methods: {
        setColors() {
            let years = this.getSchoolYears(this.dashboard.school_year_name).reverse()
            let colors = this.config.colors
            this.config.colors = years.map(x => {
                let color = colors[x.name] || null
                return {
                    name: x.name,
                    id: x.name,
                    color: color
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
