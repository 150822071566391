<template>
    <div class="flex-fill flex-column no-scroll">
        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.unknownIncidentBehavior"
            :readParams="{ upload_id: $props.uploadId }"
            @read="v => items = v"
        />

        <fe-mask v-show="loading" :showLoader="true" />

        <fe-dialog
            width="400"
            title="New Code"
            v-if="createDialog.show"
            @dismiss="createDialog.show=false"
            @close="createDialog.show=false"
            @accept="doCreate"
            acceptButtonText="Save"
            :acceptButtonDisabled="!createDialog.valid"
            persistent
        >
            <v-form v-model="createDialog.valid">
                <fe-label>Parent Type</fe-label>
                <v-autocomplete
                    :items="incidentBehaviorTypes"
                    v-model="createDialog.record.incident_behavior_type_id"
                    itemValue="id"
                    itemText="name"
                    :rules="$fieldValidators('select', 'Parent type', { required: true })"
                    flat solo dense
                    clearable
                />

                <fe-label v-if="onlyOne">Display Name</fe-label>
                <v-text-field
                    v-if="onlyOne"
                    v-model="createDialog.record.display_name"
                    itemValue="id"
                    itemText="name"
                    flat solo dense
                />

                <fe-label v-if="onlyOne">Incident Code</fe-label>
                <v-text-field
                    v-if="onlyOne"
                    v-model="createDialog.record.incident_behavior_name"
                    itemValue="id"
                    itemText="name"
                    flat solo dense
                />

            </v-form>
        </fe-dialog>

        <fe-dialog
            width="400"
            title="Alias to Code"
            v-if="aliasDialog.show"
            @dismiss="aliasDialog.show=false"
            @close="aliasDialog.show=false"
            @accept="setAlias"
            acceptButtonText="Create"
            :acceptButtonDisabled="!aliasDialog.record"
            persistent
        >
            <v-form>
                <fe-label>Parent Type</fe-label>
                <v-autocomplete
                    :items="incidentBehaviors"
                    v-model="aliasDialog.record"
                    itemValue="id"
                    itemText="name"
                    required
                    flat solo dense
                    clearable
                >
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title>{{data.item.name}}</v-list-item-title>
                            <v-list-item-subtitle>{{data.item.incident_behavior_type_name}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </v-form>
        </fe-dialog>

        <fe-grid
            :columnDefs="columns"
            :rowData="items"
            class="flex-grow-1"
            displayDensity="small"
            ref="grid"
            :showAddRowBtn="false"
            @rowSelected="selectRow"
            @cellValueChanged="sanitize"
            showToolbar
        >
            <template v-slot:toolbar-items>
                <fe-btn usage="primary" v-if="canSave" @click="doSave">Save</fe-btn>

                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <fe-btn usage="primary" v-on="on" :disabled="!selections.length">
                            Actions
                        </fe-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="(item, index) in tools" :key="index" @click="item.handler()">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </fe-grid>
    </div>
</template>

<script>
    export default {
        name: 'UnknownIncidentBehaviors',
        props: {
            uploadId: {
                required: true
            }
        },
        data() {
            let me = this

            return {
                clearingSelections: false,
                loading: false,
                canSave: false,
                selections: [],
                incidentBehaviors: [],
                items: [],
                createDialog: {
                    valid: false,
                    show: false,
                    record: {}
                },
                aliasDialog: {
                    show: false,
                    record: null
                },
                pendingText: '<br><p class="caption mt-2">This will also save any pending changes.</p>',
                originalItems: [],
                tools: [{
                    title: 'Create New Code',
                    description: 'Create as a new incident code in eduCLIMBER',
                    handler() {
                        if (me.onlyOne) {
                            me.createDialog.record = {...me.selections[0]}
                        } else {
                            me.createDialog.record = {}
                        }
                        me.createDialog.show = true
                    }
                }, {
                    title: 'Alias to Code',
                    description: 'Set this incident code as an alternate known name for an existing incident code',
                    handler() {
                        me.aliasDialog.show = true
                    }
                }],
                columns: []
            }
        },
        computed: {
            onlyOne() {
                return this.selections.length == 1 ? true : false
            }
        },
        watch: {
            items(v) {
            }
        },
        mounted() {
            this.loadIncidentBehaviors()
        },
        methods: {
            doSave() {
                let dirty = this.$isDirty(this.originalItems, this.items)
                this.loading = true
                this.$refs.crud.update(dirty)
                    .then(response => {
                        this.loadIncidentBehaviors()
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            setAlias() {
                let me = this
                this.aliasDialog.show = false
                this.selections.forEach(rec => { rec.incident_behavior_id = this.aliasDialog.record })

                this.sanitize()
                this.$refs.grid.gridApi.deselectAll()
                this.doSave()
            },
            doCreate() {
                if (this.onlyOne) {
                    this.selections.forEach(rec => {
                        rec.display_name = this.createDialog.record.display_name
                        rec.incident_behavior_name = this.createDialog.record.incident_behavior_name
                        rec.incident_behavior_type_id = this.createDialog.record.incident_behavior_type_id
                    })
                } else {
                    this.selections.forEach(rec => {
                        rec.new_incident_behavior = true // trigger is dirty to fire
                        // rec.display_name = this.createDialog.record.display_name
                        // rec.incident_behavior_name = this.createDialog.record.incident_behavior_name
                        rec.incident_behavior_type_id = this.createDialog.record.incident_behavior_type_id
                    })
                }


                this.loading = true
                this.$refs.crud.update(this.selections)
                    .then(response => {
                        this.loadIncidentBehaviors()
                    })
                    .finally(() => {
                        this.loading = false
                    })

            },
            selectRow(meta) {
                let arr = this.$refs.grid.gridApi.getSelectedRows()
                let data = meta.data
                let f = arr.find(r => r.incident_behavior_type_id != data.incident_behavior_type_id)

                if (f) {
                    this.clearingSelections = true
                    this.$refs.grid.gridApi.deselectAll()
                    // this.$refs.grid.gridApi.forEachNode(function(node) {
                    //     if (node.data.id === data.id)
                    //         node.setSelected(true, true, true)

                    // })
                }
                this.selections = this.$refs.grid.gridApi.getSelectedRows()
            },
            sanitize() {
                // this will check to see if the original fetched data set has been manipulated
                let dirty = this.$isDirty(this.originalItems, this.items)
                if (dirty) {
                    this.canSave = true
                } else {
                    this.canSave = false
                }
                // Since we are changing cells behind the ag-grid api, call refresh
                this.$refs.grid.gridApi.refreshCells()
            },
            loadIncidentBehaviors() {
                let me = this
                let id = this.$props.uploadId
                this.originalItems = []
                this.items = []

                this.$modelGet('incidentBehaviorType', { active: 1})
                    .then(types => { this.incidentBehaviorTypes = types })

                this.loading = true
                this.$modelGet('incidentBehavior')
                    .then(response => {
                        this.incidentBehaviors = response

                        this.columns = [me.$grid.checkColumn(), {
                            headerName: 'Import Incident Code',
                            field: 'incident_behavior_name',
                            sortable: true,
                            flex: 1
                        }, {
                            headerName: 'Import Display Name',
                            field: 'display_name',
                            sortable: true,
                            flex: 1
                        }, {
                            headerName: 'Parent Type',
                            field: 'incident_behavior_type_name',
                            sortable: true,
                            flex: 1
                        }, {
                            headerName: 'Alias To',
                            field: 'incident_behavior_id',
                            editable: true,
                            flex: 1,
                            tooltipValueGetter: (param) => 'This code will also be known as the selected code in eduCLIMBER',
                            cellRenderer: v => {
                                if (v.value) {
                                    let r = me.incidentBehaviors.find(rec => { return rec.id == v.value })
                                    if (r) return r.display_name
                                }
                                return ''
                            },
                            cellEditorFramework: "FeGridChipSelect",
                            cellEditorParams: {
                                rowDataKey: "incident_behavior_id",
                                mode: "edit",
                                items: me.$data.incidentBehaviors,
                                keyProp: "id",
                                labelProp: "display_name",
                                disableChips: true
                            }
                        }]
                        this.$refs.crud.read()
                            .then(() => {
                                this.items.forEach(rec => {
                                    rec.incident_behavior_id   = undefined
                                })
                                this.originalItems = this.$_.cloneDeep(this.items)
                                this.$emit('count', this.originalItems.length)
                            })
                            .finally(() => {
                                this.loading = false
                                this.sanitize()
                            })
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
