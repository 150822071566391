<template>
    <div class="agenda-topic-message-container" :class="{ 'mr-5': !reply, 'ml-5': reply && level < 5 }">
        <div class="agenda-topic-message d-flex justify-space-between mr-5 my-3">
            <v-avatar size="26" class='title-avatar flex-shrink-1 align-self-top'>
                <img v-if="message.photo_url" :src="message.photo_url" :alt="message.created_by_full_name">
                <v-icon :title="message.created_by_full_name" v-else>fas fa-user-circle</v-icon>
            </v-avatar>
            <div class="pl-2 ma-0 flex-shrink-1 flex-grow-1 align-self-center">
                <div class="agenda-topic-message--meta">
                    <strong>{{ message.created_by_full_name }}</strong>
                    <span class="text-muted"> &bull; {{ stamp }}</span>
                    <span class="agenda-topic-message--student" v-if="message.student_id">
                        <br />
                        <a @click="$router.push({ path: `/StudentProfile/${message.student_id}` })">
                            @{{ message.student_full_name }}
                        </a>
                    </span>
                    <v-icon v-if="message.private_flag" class="ml-2" color="#050F2D" size="12">fas fa-lock</v-icon>
                </div>
                <div class="agenda-topic-message--body" v-html="message.value" />
            </div>
            <div v-if="!disabled" class='title-menu flex-shrink-1 text-right align-self-top'>
                <v-menu offset-y v-model="menu">
                    <template v-slot:activator="{ on }">
                        <fe-icon-btn
                            v-on="on"
                            color="#979797"
                            size="20"
                            useIcon="fas fa-ellipsis-v"
                        />
                    </template>

                    <v-list dense>
                        <v-list-item @click="$emit('reply', message)">Reply</v-list-item>
                        <v-list-item :disabled="!editable" @click="$emit('edit', message)">Edit</v-list-item>
                        <v-list-item :disabled="!editable" @click="$emit('delete', message)">Delete</v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>

        <topic-message
            v-for="msg in children"
            :key="`topic-message-${msg.id}`"
            :message="msg"
            :messages="messages"
            :level="level+1"
            :reply="true"
            :disabled="disabled"
            @reply="$emit('reply', $event)"
            @edit="$emit('edit', $event)"
            @delete="$emit('delete', $event)"
        />
    </div>
</template>

<script>
    import Vuex, {mapState} from 'vuex'

    export default {
        name: 'TopicMessage',
        props: {
            message: { type: Object, required: true },
            messages: { type: Array, required: true },
            reply: { type: Boolean },
            level: { type: Number, default: 0 },
            disabled: { type: Boolean, default: false }
        },
        data () {
            return {
                menu: false
            }
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            editable () { return this.sessionUser.user.id == this.message.created_by && !this.children.length },
            stamp () { return this.$dayjs(this.message.created).format("MMM D") },
            children () { return this.messages.filter(itm => itm.parent_message_id == this.message.id) }
        },
        methods: { },
        watch: { }
    }
</script>

<style lang="scss">
    .agenda-topic-message {
        min-height: 40px;
        &--body, &--meta, &--student {
            font-size: 12px;
            .ec-fe-lib .v-application & p { margin: 0; }
        }
    }
</style>
