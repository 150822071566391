<template>
    <fe-dialog
        title="Incident Search"
        width="95%"
        persistent
        acceptButtonText="Search"
        dismissButtonText="Clear Search"
        @accept="searchAdvancedIncident()"
        @close="clearAdvancedIncident('close')"
    >
        <template #footer>
            <v-spacer/>

            <fe-btn usage="ghost" @click="clearAdvancedIncident('dismiss')">Clear Search</fe-btn>

            <fe-btn @click="searchAdvancedIncident()" :disabled="!schoolYearValid">Search</fe-btn>
        </template>

        <div>
            <v-layout row wrap>
                <template v-for="(field, index) in advFields">
                    <template v-if="field.name !== 'ell_level_id' || ellLevelEnabled ">
                        <v-flex v-if="index === 0" xs12 class="incident-report-search-flex pb-3 pl-3 pr-3">
                            <span class="d-inline-block" :class="{'mb-6': !schoolYearValid}">{{ field.label }}: </span>
                            <fe-remote-combo
                                style="width: 88%"
                                v-model="field.data"
                                itemText="name"
                                :hide-details="schoolYearValid"
                                :rootProperty="field.rootProperty"
                                :url="field.url ? field.url : null"
                                :items="field.items ? field.items : null"
                                :rules="$fieldValidators('select', 'School Year', {required: true, maxEntries: 5})"
                                multiple
                                lazyLoad
                            />
                        </v-flex>

                        <v-flex
                            v-else-if="field.type === 'select' && index > 0"
                            class="incident-report-search-flex pb-3 pl-3 pr-3"
                            xs6
                        >
                            <span class="d-flex">{{ field.label }}:
                                <fe-tooltip
                                    :tooltip="field.tooltip"
                                    :disabled="!field.tooltip"
                                    direction="top"
                                >
                                    <v-icon
                                        v-if="field.tooltip"
                                        class="ml-2"
                                    >
                                        far fa-exclamation-circle
                                    </v-icon>
                                </fe-tooltip>
                            </span>

                            <fe-remote-exclude-combo
                                v-if="field.exclude && field.name !== 'school_id'"
                                v-model="field.data"
                                style="width: 75%"
                                :rootProperty="field.rootProperty"
                                :url="field.url ? field.url : null"
                                :items="field.items ? field.items : null"
                                :itemText="field.itemText ? field.itemText : 'name'"
                                :itemValue="field.itemValue ? field.itemValue : 'id'"
                                :sort="field.sort"
                                hide-details
                                lazyLoad
                                @input="field.onChange ? field.onChange(field) : () => {}"
                            />

                            <fe-remote-exclude-combo
                                v-else-if="field.name === 'school_id'"
                                v-model="field.data"
                                url="schools.php?action=get&incl_groups=1"
                                style="width: 75%"
                                hide-details
                                :rootProperty="field.rootProperty"
                                :itemText="field.itemText ? field.itemText : 'name'"
                                :itemValue="field.itemValue ? field.itemValue : 'id'"
                                :params="{ 'school_year_id': cohortYears }"
                                :sort="field.sort"
                                lazyLoad
                                @input="field.onChange ? field.onChange(field) : () => {}"
                            />

                            <template v-else>
                                <fe-remote-combo
                                    v-model="field.data"
                                    style="width: 75%"
                                    :multiple="!field.single"
                                    :rootProperty="field.rootProperty"
                                    :url="field.url ? field.url : null"
                                    :items="field.items ? field.items : null"
                                    :itemText="field.itemText ? field.itemText : 'name'"
                                    :itemValue="field.itemValue ? field.itemValue : 'id'"
                                    :chips="field.chips"
                                    hide-details
                                    lazyLoad
                                    :useSearchText="field.useSearchText ? field.useSearchText : false"
                                    @set="setObserver(field, ...arguments)"
                                />
                            </template>
                        </v-flex>

                        <v-flex
                            v-else-if="field.type === 'divider'"
                            v-show="customFields.length" xs12
                            class="incident-report-search-flex pb-3 pr-3"
                        >
                            <div class="incident-report-search-custom pl-3">
                                <div>Custom Fields</div>

                                <div>
                                    <v-row>
                                        <v-col v-for="f in customFields.filter(r=>r.component)" cols=6>
                                            <fe-label>{{ f.label }}</fe-label>
                                            <component
                                                :is="f.component"
                                                v-bind="f.attrs"
                                                v-model="f.modelValue"
                                                hide-details
                                            />
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-flex>
                        
                        <v-flex xs6 v-else-if="field.type === 'checkbox'">
                            <div v-if="showIncidentBehaviorCounts" class="incident-report-search-flex pb-3 pl-3 pr-3">
                                <div></div> <!--empty div for next flex to go halfway-->
                                <div class="d-flex" style="width: 75%">
                                    <v-checkbox label="Include only primary incidents" v-model="field.data"></v-checkbox>
                                    <fe-info-tip tooltip="When this filter is applied, only primary incidents will appear in the results."
                                        color="#006C90" icon="fas fa-info-circle"
                                    >
                                    </fe-info-tip>
                                </div>
                            </div>
                        </v-flex>

                        <v-flex v-else class="incident-report-search-flex pb-3 pl-3 pr-3" xs6>
                            <span class="d-inline-block">{{ field.label }}: </span>

                            <fe-date-picker
                                v-model="field.data"
                                class="d-inline-block incident-report-search-date"
                                placeholder="MM/DD/YYYY"
                                prependIcon="fa-calendar-alt"
                                dateFormat="MM/DD/YYYY"
                                style="width: 75%"
                                :min="field.name === 'end_date' ? startDate : ''"
                                :max="field.name === 'start_date' ? endDate : ''"
                            />
                        </v-flex>
                    </template>
                </template>

                <div class="d-flex flex-column px-2 my-4" style="width: 100%">
                    <div class="d-flex" style="cursor: pointer; font-size: 16px;">
                        <div
                            class="flex-shrink-1 py-3"
                            @click="hideAdvancedDemographics=!hideAdvancedDemographics"
                        >
                            Advanced Demographics
                        </div>

                        <div
                            class="flex-grow-1"
                            @click="hideAdvancedDemographics=!hideAdvancedDemographics"
                        >
                            <fe-icon-btn
                                :useIcon="!hideAdvancedDemographics ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
                            />
                        </div>
                    </div>

                    <advanced-demographic-filters
                        v-if="!hideAdvancedDemographics"
                        v-model="advancedDemographicParams"
                        ref="advancedDemographicFilters"
                        :resetEntry="false"
                    />
                </div>
            </v-layout>

            <v-layout row class="pt-4">
                <v-checkbox v-model="downloadExcel" :ripple="false"/>

                <span>Download as Excel</span>
            </v-layout>
        </div>

        <fe-crud v-if="$hasSecurity('SEARCH_MEAL_STATUS') && $hasSecurity('DRILLDOWN_MEAL_STATUS')" ref="mealStatusCrud" :config="$models.mealStatusType" autoload @read="postReadDemo($event, 'meal_status_id')"/>
        <fe-crud ref="elpLevelCrud" :config="$models.ellLevel" autoload @read="postReadDemo($event, 'ell_level_id')"/>
        <fe-crud ref="disabilityCodeCrud" :config="$models.studentDisabilityType" autoload @read="postReadDemo($event, 'disability_type_id')"/>
        <fe-crud ref="genderCrud" :config="$models.gender" autoload @read="postReadDemo($event, 'gender')"/>
        <fe-crud ref="ethnicityCrud" :config="$models.ethnicity" autoload @read="postReadDemo($event, 'ethnicity')"/>
    </fe-dialog>
</template>

<script>
import {mapState} from "vuex"
import AdvancedDemographicFilters from "@/components/common/AdvancedDemographicFilters"

export default {
    name: 'IncidentAdvancedSearch',

    components: {
        AdvancedDemographicFilters
    },

    props: {
        existing: {
            required: false
        }
    },

    data() {
        let me = this
        return {
            customFields: [],
            advancedFields: [
                {
                    id: 0,
                    name: 'school_year_id',
                    label: 'School Year',
                    type: 'select',
                    url: this.$models.getUrl('schoolYear', 'read'),
                    rootProperty: 'years',
                    data: []
                },
                {id: 1, name: 'start_date', label: 'Start Date', type: 'date', data: ''},
                {id: 2, name: 'end_date', label: 'End Date', type: 'date', data: ''},
                {
                    id: 3,
                    name: 'active_status',
                    label: 'Active Status',
                    type: 'select',
                    single: true,
                    items: [{id: 1, name: "Inactive"}, {id: 2, name: "Active"}, {id: 3, name: "Both"}],
                    data: null
                },
                {id: 4, name: 'active_as_of', label: 'Active as of', type: 'date', data: null},
                {
                    id: 5,
                    name: 'school_id',
                    label: 'School',
                    type: 'select',
                    rootProperty: 'schools',
                    data: [],
                },
                {
                    id: 6,
                    name: 'grade_id',
                    label: 'Grade',
                    type: 'select',
                    url: this.$models.getUrl('grade', 'read'),
                    rootProperty: 'grades',
                    exclude: true,
                    data: []
                },
                {
                    id: 7,
                    name: 'student_id',
                    label: 'Student',
                    type: 'select',
                    url: this.$models.getUrl('student', 'read'),
                    itemText: x => x.lname + ', ' + x.fname,
                    single: true,
                    data: null
                },
                {
                    id: 8,
                    name: 'entry_user_id',
                    label: 'Entry User',
                    type: 'select',
                    url: this.$models.getUrl('user', 'read') + '&active=1',
                    itemText: 'user_full_name',
                    single: true,
                    data: null
                },
                {
                    id: 9,
                    name: 'observer_user_id',
                    label: 'Observer',
                    type: 'select',
                    url: this.$models.getUrl('user', 'read') + '&active=1',
                    itemText: 'user_full_name',
                    single: true,
                    data: null,
                    useSearchText: true,
                },
                {
                    id: 10,
                    name: 'referred_to_user_id',
                    label: 'Referred  to',
                    type: 'select',
                    url: this.$models.getUrl('user', 'read') + '&active=1',
                    itemText: 'user_full_name',
                    single: true,
                    data: null
                },
                {
                    id: 11,
                    name: 'tag_id',
                    label: 'Tags',
                    type: 'select',
                    url: this.$models.getUrl('tag', 'read') + '&sort=name&dir=ASC',
                    rootProperty: 'tags',
                    exclude: true,
                    data: []
                },
                {
                    id: 12,
                    name: 'incident_location_id',
                    label: 'Locations',
                    type: 'select',
                    url: 'incidentLocations.php?action=get',
                    rootProperty: 'incident_locations',
                    exclude: true,
                    data: [],
                    sort: this.sortByName,
                },
                {
                    id: 13,
                    name: 'incident_behavior_type_id',
                    label: 'Incident Type',
                    tooltip: null, // May be set to an advisory message when mounted
                    type: 'select',
                    url: this.$models.getUrl('incidentBehaviorType', 'read'),
                    rootProperty: 'incident_behavior_type',
                    data: [],
                    onChange(v) {
                        me.customFields = []
                        if (v.data.length === 1) {
                            me.loadCustomFields(v)
                        }
                    }
                },
                {
                    id: 14,
                    name: 'incident_behavior_id',
                    label: 'Incident Code',
                    type: 'select',
                    url: this.$models.getUrl('incidentBehavior', 'read'),
                    rootProperty: 'incident_behavior',
                    exclude: true,
                    data: [],
                    sort: this.sortByName
                },
                {id: 22, type: 'divider', data: []},
                {
                    id: 15,
                    name: 'incident_response_id',
                    label: 'Response',
                    type: 'select',
                    url: 'incidentResponses.php?action=get',
                    rootProperty: 'incident_responses',
                    exclude: true,
                    data: []
                },
                {
                    id: 16,
                    name: 'incident_motivation_id',
                    label: 'Perceived Motivation',
                    type: 'select',
                    url: 'incidentMotivations.php?action=get',
                    rootProperty: 'incident_motivations',
                    exclude: true,
                    data: []
                },
                {
                    id: 17,
                    name: 'ethnicity',
                    label: 'Ethnicity',
                    type: 'select',
                    rootProperty: 'ethnicity',
                    itemText: 'display_name',
                    itemValue: 'value',
                    exclude: true,
                    data: [],
                    items: []
                },
                {
                    id: 18,
                    name: 'disability_type_id',
                    label: 'Disability',
                    type: 'select',
                    items: [
                        {
                            id: -1,
                            code: 'ALLDIS',
                            description: 'All Disabilities'
                        }, {
                            id: 0,
                            code: 'NODIS',
                            description: 'No Disabilities'
                        }
                    ],
                    rootProperty: 'student_disability_type',
                    itemText: 'display_name',
                    exclude: true,
                    onChange: v => {
                        if (v.data?.length) {
                            let allOrNone = v.data[v.data.length - 1]
                            if (allOrNone.id <= 0) {
                                v.data = [allOrNone]
                            } else {
                                v.data = v.data.filter(x => x.id > 0)
                            }
                        }
                    },
                    data: []
                },
                {
                    id: 19,
                    name: 'gender',
                    label: 'Gender',
                    type: 'select',
                    rootProperty: 'gender',
                    itemText: 'display_name',
                    itemValue: 'value',
                    exclude: true,
                    data: [],
                    items: []
                },
                {
                    id: 20,
                    name: 'intervention_level',
                    label: 'Intv Level',
                    type: 'select',
                    url: 'interventionLevels.php?action=get&active_only=1',
                    rootProperty: 'intervention_levels',
                    exclude: true,
                    data: []
                },
                {
                    id: 22,
                    name: 'ell_flag',
                    label: 'EL Status',
                    type: 'select',
                    items: [{id: 1, name: "Yes"}, {id: 0, name: "No"}],
                    data: null,
                    chips: true,
                    single: true
                },
                {
                    id: 23,
                    name: 'ell_level_id',
                    label: 'ELP Level',
                    type: 'select',
                    rootProperty: 'ell_level',
                    itemText: 'display_name',
                    data: [],
                    items: [],
                    chips: true
                },
                {
                    id: 21,
                    name: 'meal_status_id',
                    label: 'Meal Status',
                    type: 'select',
                    items: [
                        {
                            id: -1,
                            display_name: 'Any Status'
                        }, {
                            id: 0,
                            display_name: 'No Status'
                        }
                    ],
                    rootProperty: 'meal_status',
                    itemText: 'display_name',
                    onChange: v => {
                        if (v.data?.length) {
                            let allOrNone = v.data[v.data.length - 1]
                            if (allOrNone.id <= 0) {
                                v.data = [allOrNone]
                            } else {
                                v.data = v.data.filter(x => x.id > 0)
                            }
                        }
                    },
                    exclude: true,
                    data: []
                },
                {
                    id: 24,
                    name: 'include_secondary_behaviors_responses',
                    type: 'checkbox',
                    data: false,
                }
            ],
            advancedDemographicParams: {},
            hideAdvancedDemographics: true,
            downloadExcel: false,
        }
    },

    computed: {
        ...mapState('global', ['currentYear', 'sessionUser', 'defaultSchool', 'sessionFlags']),

        schoolYearValid() {
            let length = this.advancedFields[0].data.length
            return length > 0 && length < 6
        },

        ellLevelEnabled() {
            return this.$store.state.global.shareableStores.ell_level_enabled
        },

        startDate() {
            let startDate = this.advancedFields.find(x => x.name === 'start_date').data
            if (startDate) startDate = this.$dayjs(startDate).format()
            return startDate
        },

        endDate() {
            let endDate = this.advancedFields.find(x => x.name === 'end_date').data
            if (endDate) endDate = this.$dayjs(endDate).format()
            return endDate
        },

        cohortYears() {
            return this.advancedFields.find(x => x.name === 'school_year_id').data.map(x => x.id) || null
        },

        advFields() {
            if (!this.$hasSecurity('SEARCH_MEAL_STATUS') && !this.$hasSecurity('DRILLDOWN_MEAL_STATUS')) {
                // remove Meal Status field if user doesn't have permissions for it
                var removeIndex = this.advancedFields.map(item => item.id).indexOf(21)
                removeIndex >= 0 && this.advancedFields.splice(removeIndex, 1)
            }
            return this.advancedFields
        },

        showIncidentBehaviorCounts() {
            return this.$store.getters['flags/flags']['incident-behavior-counts'] === true
        } 
    },

    created() {
        this.onLoad()
    },

    watch: {
        hideAdvancedDemographics(v) {
            if (!v) {
                this.$nextTick(() => {
                    this.$refs.advancedDemographicFilters.$el.scrollIntoView({behavior: 'smooth'})
                })
            }
        }
    },

    mounted() {
        let field = this.advancedFields.find(itm => itm.name == 'incident_behavior_type_id')
        if (field) {
            field.tooltip = (!!this.sessionUser.user?.has_restricted_incident_types) ? 'There are some incident types that have been restricted from your visibility by your district' : null
        }
    },

    methods: {
        onLoad(resetDefaults = true) {
            let showPrimaryOnly = window.localStorage.getItem('ec-incidents-primary-only') || 0
            this.advancedFields.find(x => x.name === 'include_secondary_behaviors_responses').data = parseInt(showPrimaryOnly)
            if (this.existing && resetDefaults) {
                for (let [key, value] of Object.entries(this.existing.params)) {
                    switch (key) {
                        case 'range':
                            let endDate = this.advancedFields.find(x => x.name === 'end_date')
                            if (!endDate.data) {
                                endDate.data = this.$dayjs().format('MM/DD/YYYY')
                            }
                            break;
                        case 'start_date':
                            this.advancedFields.find(x => x.name === 'start_date').data = this.$dayjs(value).format('MM/DD/YYYY')
                            break;
                        case 'end_date':
                            this.advancedFields.find(x => x.name === 'end_date').data = this.$dayjs(value).format('MM/DD/YYYY')
                            break;
                        case 'active_as_of':
                            this.advancedFields.find(x => x.name === 'active_as_of').data = this.$dayjs(value).format('MM/DD/YYYY')
                            break;
                        case 'school_year_id':
                            let schoolYears = this.$_.isArray(value) ? value.map(x => {
                                let id = x.id || x
                                return {id: parseInt(id)}
                            }) : [{id: parseInt(value)}]
                            this.advancedFields.find(x => x.name === 'school_year_id').data = schoolYears
                            break;
                    }
                }
                // if freetext observer value, 
                // want to remove observer_user_full_name (which was passed to BE)
                // and add back in observer_user_id with same values
                // so that fe-remote-combo can add value to list and select
                let observer = this.existing.filters.find(x => x.key == 'observer_user_full_name')
                if(observer) {
                    this.existing.filters.push({
                        id: observer.id,
                        key: "observer_user_id",
                        name: observer.id,
                        label: 'Observer'
                    })
                    this.existing.filters = this.existing.filters.filter(x => x.key != 'observer_user_full_name')
                }

                this.existing.filters.forEach(filter => {
                    if (filter.demographicId) {
                        this.advancedDemographicParams[filter.key] = filter.id
                        this.hideAdvancedDemographics = false
                    } else if (!filter.custom) {
                        let field = this.advancedFields.find(x => x.name === filter.key)

                        switch (field.name) {
                            case 'student_id':
                                let name = filter.name.split(', ')
                                field.data = {
                                    rcExclude: filter.rcExclude,
                                    lname: name[0],
                                    fname: name[1],
                                    id: filter.id
                                }
                                break;
                            case 'entry_user_id':
                            case 'observer_user_id':
                            case 'referred_to_user_id':
                                field.data = {
                                    rcExclude: filter.rcExclude,
                                    user_full_name: filter.name,
                                    id: filter.id 
                                }
                                break;
                            case 'gender':
                            case 'ethnicity':
                                let entry = {}
                                entry.rcExclude = filter.rcExclude
                                entry.display_name = filter.display_name
                                entry.display_name_group = filter.display_name_group
                                entry.value = filter.value
                                entry.name = filter.display_name || filter.value
                                entry.id = filter.value
                                field.data.push(entry)
                                break;
                            case 'ell_level_id':
                            case 'meal_status_id':
                            case 'disability_type_id':
                                field.data.push({
                                    rcExclude: filter.rcExclude,
                                    display_name: filter.display_name,
                                    display_name_group: filter.display_name_group,
                                    name: filter.display_name || filter.description || filter.name,
                                    id: filter.id
                                })
                                break;
                            default:
                                if (!field.single) {
                                    if (!field.data) {
                                        field.data = []
                                    }
                                    field.data.push({
                                        rcExclude: filter.rcExclude,
                                        name: filter.name,
                                        id: filter.id
                                    })
                                } else {
                                    field.data = {
                                        name: filter.name,
                                        id: filter.id
                                    }
                                }
                                break;
                        }
                    }
                })
            } else {
                this.advancedFields.find(x => x.name === 'school_year_id').data = [this.currentYear]
            }
        },

        postReadDemo(data, demo) {
            if (data) {
                this.advancedFields.forEach(f => {
                    if (f.name === demo) {
                        let filteredOptions = []
                        f.items = f.items.concat(data) // any All or None options
                        f.items.forEach(itm => {
                            if (!itm.display_name_group) {
                                itm.display_name = itm.display_name || itm.value || itm.name || itm.description
                                filteredOptions.push(itm)
                            } else {
                                let o = f.items.find(x => (x.id || x.value) == itm.display_name_group[0]) // find and return first option within display_name_group; id can be string
                                if (!filteredOptions.find(x => x.display_name === o.display_name)) {
                                    o = {
                                        ...o,
                                        display_name: o.display_name || o.value || o.name || o.description // use display_name instead of value
                                    }
                                    filteredOptions.push(o)
                                }
                            }
                        })
                        f.items = filteredOptions
                    }
                })
            }
        },

        clearAdvancedIncident(type) {
            this.advancedFields.forEach(af => {
                if (af.type === 'date' || af.single) {
                    af.data = null
                } else {
                    af.data = []
                }
            })

            if (type === 'close') {
                this.$emit('close')
            } else {
                this.onLoad(false)
            }
        },

        async searchAdvancedIncident() {
            let params = {}
            let filters = []

            // If user searches on any school that is not their default school,
            // then prevent auto-application of that default school in this component henceforth
            if (this.defaultSchool && this.advancedFields.find(f => f.name == 'school_id').data.find(itm => itm.id != this.defaultSchool.id)) {
                this.$store.commit('global/updateSessionFlag', {
                    flag: 'incidentAdvancedSearchPreventDefaultSchool',
                    value: true
                })
            }

            for (let [key, value] of Object.entries(this.advancedDemographicParams)) {
                let parsed = await this.$parseDemographic(key, value)
                if (parsed.demo) {
                    filters.push({
                        label: parsed.textWithOp,
                        key: key,
                        id: value,
                        name: parsed.demo.name,
                        demographicId: parsed.demo.id,
                        tooltip: (parsed.demo.value_type === 'boolean' || parsed.demo.value_type === 'option')
                            ? `${parsed.demo.name}: ${parsed.text}`
                            : `${parsed.demo.name}: ${parsed.textWithOp}`
                    })
                }
            }

            this.advancedFields.forEach(x => {
                let filter = {
                    label: x.label,
                    key: x.name
                }

                switch (true) {
                    case x.type != 'checkbox' && this.$_.isEmpty(x.data):
                        break;
                    case x.name === 'gender' || x.name === 'ethnicity':
                        x.data.forEach(y => {
                            let yFilter = this.$_.cloneDeep(filter)
                            yFilter.rcExclude = y.rcExclude
                            yFilter.name = y.display_name
                            yFilter.display_name = y.display_name
                            yFilter.display_name_group = y.display_name_group
                            yFilter.value = y.value
                            yFilter.id = y.value
                            filters.push(yFilter)
                        })
                        params[x.name] = x.data.map(y => (y.rcExclude ? '!' : '') + y.value)
                        break;
                    case x.name === 'active_status':
                        filter.name = x.data.name
                        filter.rcExclude = x.rcExclude
                        filter.id = x.data.id
                        filters.push(filter)
                        if (x.data.id !== 3) {
                            params.student_active_flag = x.data.id === 2 ? 1 : 0
                        }
                        break;
                    case x.name === 'ell_level_id':
                    case x.name === 'meal_status_id':
                    case x.name === 'disability_type_id':
                        x.data.forEach(y => {
                            let yFilter = this.$_.cloneDeep(filter)
                            yFilter.rcExclude = y.rcExclude
                            yFilter.name = y.display_name || y.description || y.name
                            yFilter.display_name = y.display_name
                            yFilter.display_name_group = y.display_name_group
                            yFilter.id = y.id
                            filters.push(yFilter)
                        })
                        params[x.name] = x.data.map(y => (y.rcExclude ? '!' : '') + y.id)
                        break;
                    case x.name === 'school_year_id':
                        params[x.name] = x.data.map(y => {
                            return {id: y.id, name: y.name}
                        })
                        break;
                    case x.type === 'date':
                        params[x.name] = this.$dayjs(x.data).format('YYYY-MM-DD')
                        break;
                    case x.type == 'checkbox': 
                        // want to reverse since checkbox is asking to show only primary
                        // but we're passing include_secondary_behaviors_responses to BE
                        // but not reverse for localstorage (to select/deselect checkbox)
                        params[x.name] = x.data ? 0 : 1 
                        window.localStorage.setItem('ec-incidents-primary-only', x.data ? 1 : 0)
                        break;
                    case x.single:
                        switch (x.name) {
                            case 'student_id':
                                filter.name = x.data.lname + ', ' + x.data.fname
                                break;
                            case 'entry_user_id':
                            case 'observer_user_id':
                            case 'referred_to_user_id':
                                filter.name = x.data.user_full_name
                                break;
                            default:
                                filter.name = x.data.name
                                break;
                        }

                        filter.rcExclude = x.rcExclude
                        filter.id = x.data.id
                        filters.push(filter)
                        params[x.name] = x.data.id && typeof x.data.id != 'string' ? (x.rcExclude ? '!' : '') + x.data.id : null
                        // if freetext observer value, 
                        // want to remove observer_user_id from passing to network call
                        // and add observer_user_full_name
                        if(x.name == 'observer_user_id' && typeof x.data.id == 'string') {
                            params['observer_user_full_name'] = x.data.user_full_name
                            params = Object.keys(params).filter(x => x !== 'observer_user_id').reduce((p,key) => {
                                p[key] = params[key]
                                return p
                            }, {})
                            let found = filters.find(x => x.key == 'observer_user_id')
                            if(found) {
                                filters.push({
                                    id: x.data.id,
                                    key: 'observer_user_full_name',
                                    label: 'Observer',
                                    name: x.data.user_full_name
                                })
                                filters = filters.filter(x => x.key != 'observer_user_id')
                            }
                        }
                        break;
                    default:
                        x.data.forEach(y => {
                            let yFilter = this.$_.cloneDeep(filter)
                            yFilter.rcExclude = y.rcExclude
                            yFilter.name = y.name || y.user_full_name
                            yFilter.id = y.id
                            filters.push(yFilter)
                        })

                        params[x.name] = x.data.map(y => {
                            let name = y.name
                            if (!name) {
                                this.existing.params[x.name].forEach(z => {
                                    if (z.id == y.id) {
                                        name = z.name
                                    }
                                })
                            }
                            return {
                                id: y.rcExclude ? '!' + y.id : parseInt(y.id),
                                name: name
                            }
                        })
                }
            })

            this.customFields.forEach(f => {
                if (f.modelValue) {
                    let key = 'custom_field_' + f.id
                    filters.push({
                        custom: true,
                        label: f.label,
                        key,
                        id: f.id,
                        modelValue: f.modelValue,
                        name: f.modelValue.display_value
                    })
                    params[key] = f.modelValue.id
                }
            })

            if (this.downloadExcel) {
                let obj = this.$_.cloneDeep(params);
                for (let p in obj) {
                    if (Array.isArray(obj[p])) {
                        obj[p] = obj[p].map(x => { return x.hasOwnProperty('id') ? x.id : x }).join(',')
                    }
                }

                this.$axios.get('behaviorIncident.php?action=get&property=csv&' + this.$objectToParams(obj), {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/csv'
                    },
                    responseType: 'blob'
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'IncidentExport.csv'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }).catch((error) => console.error(error))
            } else {
                this.$emit('onSearchClick', params, filters)
            }
        },

        buildCustomField(f) {
            let xtype = f.incident_field_type_xtype
            if (xtype === 'combobox') {
                f.component = 'fe-remote-combo'
                f.attrs = {
                    url: this.$models.getUrl('incidentFieldOption', 'read') + '&incident_field_id=' + f.id,
                    rootProperty: 'incident_field_options',
                    itemText: 'display_value',
                    itemValue: 'id',
                    'hide-details': true,
                    lazyLoad: true
                }
            } else if (xtype === 'numberfield' || xtype === 'textfield') {
                f.component = 'v-text-field'
                f.attrs.type = xtype === 'numberfield' ? 'number' : 'text'
            } else if (f.incident_field_type_xtype.includes('combo')) {
                f.component = 'fe-remote-combo'
                let url = ''
                let display = ''
                let rootProperty = ''

                switch (xtype) {
                    case 'studentcombo':
                        url = this.$models.getUrl('student', 'read')
                        display = function (x) {
                            return x.lname + ', ' + x.fname
                        }
                        break
                    case 'usercombo':
                        url = this.$models.getUrl('user', 'read') + '&active=1'
                        display = 'user_full_name'
                        break
                    case 'schoolcombo':
                        rootProperty = 'schools'
                        url = this.$models.getUrl('school', 'read')
                        display = 'name'
                        break
                    case 'gradecombo':
                        rootProperty = 'grades'
                        url = this.$models.getUrl('grade', 'read')
                        display = 'name'
                        break
                }

                f.attrs = {
                    url: url,
                    rootProperty: rootProperty,
                    itemText: display,
                    itemValue: 'id',
                    'hide-details': true,
                    lazyLoad: true
                }
            } else if (xtype === 'datefield') {
                f.component = 'fe-date-picker'
            } else if (xtype === 'textarea') {
                f.component = 'v-textarea'
            } else {
                console.warn(f.incident_field_type_xtype, f)
            }
        },

        loadCustomFields(field) {
            this.customFields = []
            this.$modelGet('incidentField', {incident_behavior_type_id: field.data[0].id}).then(fields => {
                this.customFields = fields
                this.customFields.forEach(f => {
                    if (this.existing) {
                        let item = this.existing.filters.find(x => x.id === f.id)
                        f.modelValue = item ? item.modelValue : null
                    } else {
                        f.modelValue = null
                    }
                    f.attrs = {
                        flat: true,
                        solo: true,
                        dense: true
                    }

                    this.buildCustomField(f)
                })
            })
        },

        postReadMealStatus(data) {
            if (data) {
                this.advancedFields.forEach(x => {
                    if (x.name === 'meal_status_id') {
                        x.items = x.items.concat(data)
                    }
                })
            }
        },

        sortByName(a, b) {
            return a.name < b.name ? -1 : 1
        },

        setObserver(field, v) {
            // only want to do anything if field is for observer
            // if passing free text value, need to force text as itemText and itemValue
            // else set value to found value from users list
            if (field.name == 'observer_user_id') {
                if(typeof v === 'string') {
                    field.data = {
                        user_full_name: v,
                        id: v
                    }
                } else {
                    field.data = v
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.incident-report {
    &-search {
        &-flex {
            justify-content: space-between;
            align-items: center;
            display: flex;
        }

        &-date ::v-deep .v-text-field__details {
            display: none !important;
        }

        &-custom {
            background: #f6f6f6;
            border: 1px solid #d1d1d1;
            border-radius: 3px;
            width: 100%;
            padding: 16px;
        }
    }
}
</style>
