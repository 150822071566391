export default {
  render() {
    return this.$scopedSlots.default({})
  },
  props: {
    config: { type: Object, required: true }
  },
  provide() {
    return {
      dockedWindowId: this.dockedWindowId,
    }
  },
  data() {
    return {
      dockedWindowId: null,
    }
  },
  watch: {
    config: {
      handler (v) {
          this.dockedWindowId = v.dockedWindowId
      },
      immediate: true,
      deep: true
    }
  }
}
