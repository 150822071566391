<template>
    <div id="reportingGridContainer"  class="d-flex flex-fill flex-column" style="overflow: hidden;">
        <v-carousel
            v-model="active"
            height="100%"
            hide-delimiters light
            :show-arrows="false"
            ref="carouselContainer"
            touchless
        >
            <v-carousel-item key="0">
                <div key='district' class="d-flex flex-fill flex-column">
                    <charting scope="District" key='district-charts'/>
                </div>
            </v-carousel-item>

            <v-carousel-item key="1">
                <div key='grade' class="d-flex flex-fill flex-column">
                    <charting scope="Grade" key='grade-charts'/>
                </div>
            </v-carousel-item>

            <v-carousel-item key="2">
                <div key='class' class="d-flex flex-fill flex-column">
                    <charting scope="Class" key='class-charts'/>
                </div>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
    import Charting from './Charting'
    import Vuex from 'vuex'
    import SearchMenu from '@/components/common/SearchMenu'
    import BenchmarkChart from '@/components/charts/BenchmarkChart'
    import DataWall from '@/components/modules/datawall/Index'
    import ChartDrilldown from '@/components/charts/ChartDrilldown'
    import AttendanceReporting from '@/components/modules/attendanceReporting'
    import MenuBtn from '@/components/common/button/MenuBtn'
    import { mapState } from 'vuex'

    export default {
        name: 'SummaryReport',

        components: {
            SearchMenu,
            BenchmarkChart,
            DataWall,
            ChartDrilldown,
            AttendanceReporting,
            MenuBtn,
            Charting
        },

        props: {
            scope: {
                type: String,
                default: 'District'
            }
        },

        watch: {
            scope: {
                handler(v) {
                    if (v==='District') {
                        this.active=0
                    } else if (v==='Grade') {
                        this.active=1
                    } else if (v==='Class') {
                        this.active=2
                    }
                },
                immediate: true
            },

            $route(to, from) {
                this.pageBreadcrumb = to.params.scope + ' Search'
            }
        },

        computed: {
            ...mapState('global', ['currentYear', 'sessionUser']),
            dashboardOnly() {
                return this.sessionUser.district.dashboard_only ? true : false
            },
            // attendanceParams() {
            //     let idx = this.scopeIdx

            //     let p = {...this.scopeList[idx].lastQueryParams}
            //     delete p.category_id
            //     return p
            // },

            // allCategories() {
            //     let idx = this.scopeIdx

            //     return this.scopeList[idx].categories.concat([{
            //         id: 'attendance',
            //         name: 'Attendance'
            //     }, {
            //         id: 'incidents',
            //         name: 'Incidents'
            //     }])
            // },

            // chartConfigs() {
            //     return [this.overallResults, this.schoolResults, this.gradeResults, this.genderResults, this.ethnicityResults]
            // },
        },
        data() {
            let me = this
            return {
                active: 0,
                districtParams: {},
                gradeParams: {},
                classParams: {},
                pageKeyIndex: 0,
                localStore: null,
                schoolResults: [],
                gradeResults: [],
                genderResults: [],
                ethnicityResults: [],
                cohortSearch: false,
                currentCategory: undefined,
                pageBreadcrumb: null,
                scopeData: {
                    overallResults: [],
                    categories: [],
                    currentCategory: {
                        showDataWall: false
                    },
                    showDataWall: false,
                    lastQueryParams: null,
                    category: null,
                    showDrilldown: false,
                    drilldown: {
                        drilldownData: {},
                        drilldownTitle: '',
                        drilldownRecord: {}
                    },
                    searchSelect: undefined
                },
                scopeIdx: 0
            }
        },

        methods: {
            onLoad() {

                this.pageBreadcrumb = this.$route.params ? this.$route.params.scope + ' Search' : null
            },

            doSearch(selected, searchSelect) {
                this.$refs.searchBar[0].getSearchObject()
                return
                let idx = this.scopeIdx

                if (searchSelect) this.scopeList[idx].searchSelect = searchSelect
                this.scopeList[idx].overallResults = []
                this.scopeList[idx].categories = []
                this.scopeList[idx].lastQueryParams = selected

                this.$axios.get('targetScores.php?action=get&property=categories&'+ this.$objectToParams(selected))
                .then(response => {
                    response.data.categories.forEach(cat => {
                        this.scopeList[idx].categories.push({
                            id: cat.category_id,
                            name: cat.category_name,
                            isLoaded: false,
                            params: selected,
                            overallResults: [],
                            assessments: [],
                            showDataWall: false
                        })
                        cat.isLoaded = false
                    })
                    // selected.group_by = 'grade'

                    this.loadCategory(idx, this.scopeList[idx].categories[0])
                })
            },

            loadCategory(i, category) {
                let idx = this.scopeIdx
                let me = this

                if (category.id === 'attendance' || !category.params) {
                    return
                }

                this.scopeList[idx].currentCategory = category
                this.$setLoading(true)
                category.params.category_id = category.id
                this.$axios.get('targetScores.php?action=get&' + this.$objectToParams(category.params))
                    .then(response => {
                        category.overallResults = response.data.categories[0].stacked_charts
                        category.assessments = [...new Set(category.overallResults.map(item => item.data_point_type_name))]
                    })
                    .finally(() => {
                        this.$setLoading(false)
                    })
            }
        },

        mounted() {
            if (this.dashboardOnly) {
                this.$router.replace('/AchievementDashboard')
                return
            }

            this.onLoad()
        }
    }
</script>

<style lang="scss" scoped>
.report-toolbar {
    height: 64px;
    background-color: gray;
}

// ::v-deep .menu-title-toolbar {
//     min-height: 0;

//     .menu-title-container-bordered {
//         border: none !important;
//     }

//     .fe-left-menu-layout-title {
//         padding: 0;
//     }
// }

::v-deep div.data-wall-left-menu .nav-container {
    width: 200px !important;
    border: none;

    .nav-container-items {
        padding-left: 8px
    }
}

::v-deep div.chart-data-wall-container {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    overflow-y: scroll;
    overflow-x: hidden;

    .assessment-title {
        font-weight: 700;
        font-size: 18px;
    }
}

.launchpad-nav {
    //height: 44px;
    align-items: center;
    display: flex;
    height: 100%;

    .launchpad-nav-option {
        ::v-deep .fe-button {
            display: inline-block;
            //min-width: 72px;
            //max-width: 72px;
            //height: 28px;
            //background: var(--fe-secondary) !important;
            color: var(--fe-hover) !important;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: center;
            //padding: 0 !important;
            margin: 4px 0px;
            cursor: pointer;
            border-color: #e2e2e2 !important;
        }

        ::v-deep .fe-button:hover.secondary {
            background: transparent !important;
        }

        &.btn-selected ::v-deep .fe-button.secondary {
            background-color: rgba(0, 108, 144, 0.2) !important;

            // &:before {
            //     background-color: #006C90;
            //     opacity: 0.08;
            // }
        }

        &.first ::v-deep .fe-button {
            border-radius: 4px 0px 0px 4px;
        }

        &.middle ::v-deep .fe-button {
            border-radius: 0;
        }

        &.last ::v-deep .fe-button {
            border-radius: 0px 4px 4px 0px;
        }

        &.standalone {
            border-radius: 4px;
            width: 71px;
            margin-left: 6px;
        }
    }

    &-educlimber {
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        padding: 16px 16px 16px 10px;
    }


}
</style>
