<template>
    <div style="min-height: 300px;" class="mb-5">
        <div v-if="invalid" class="body-2 text-xs-center pa-4">
            <v-icon>info</v-icon>
            Progress Monitoring data will display when this form is linked to an intervention
        </div>

        <div v-else>
            <behavior-progress-monitoring v-if="params.category_id == 4" :params="params" />
            <academic-progress-monitoring v-else showStatement :params="params" />
        </div>
    </div>
</template>

<script>
    import AcademicProgressMonitoring from '@/components/charts/AcademicProgressMonitoring'
    import BehaviorProgressMonitoring from '@/components/charts/BehaviorProgressMonitoring'
    // import { mapState } from 'vuex'

    export default {
        name: 'ProgressMonitoringBlock',
        props: ['params'],
        components: {
            AcademicProgressMonitoring,
            BehaviorProgressMonitoring
        },
        computed: {
            invalid() {
                return !this.$props.params.intervention_id || !this.$props.params.student_id
            },
        },
        data() {
            return {

            }
        },
        mounted() {},
        methods: {
        }
    }
</script>

<style lang="scss" scoped>

</style>
