<template>
    <div class='placeholder__wrapper'>
        <div :class="`placeholder__basic placeholder__basic--${ rocket ? 'rocket' : 'other'}`"></div>
        <div class="text-xs-center">
            <h2 v-if="title" class="headline">{{ title }}</h2>

            <p v-if="body">{{ body }}</p>

            <fe-btn v-if="button" usage="tertiary" @click="$emit('click')">{{ button }}</fe-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Empty',

    props: {
        title: String,
        body: String,
        button: String,
        rocket: Boolean
    }
}
</script>
