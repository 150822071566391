export default {
    namespaced: true,

    state: {
        messages: []
    },

    mutations: {
        push(state, msg) {
            state.messages.push(msg)
        },

        shift(state) {
            state.messages.shift()
        },

        clear(state) {
            state.messages = []
        }
    },

    actions: {
        push({commit}, msg) {
            commit('push', msg)
        },

        shift({commit}) {
            commit('shift')
        },

        clear({commit}) {
            commit('clear')
        }
    },

    getters: {
        messages: state => state.messages,

        count: state => state.messages.length,

        current: state => (state.messages.length > 0) ? state.messages[0] : null
    }
}
