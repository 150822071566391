<template>
    <div class="flex-fill flex-column">
        <div class="d-flex">
            <div class="flex-grow-1">
                <fe-title-select
                    v-if="groups.length > 0 && selectedGroup"
                    placeholder="Select an Assessment Group"
                    :items="groups"
                    v-model="selectedGroup"
                ></fe-title-select>
            </div>
            <div class="flex-shrink-1" v-if="$isSupport() || (canManageGroups && !isGlobal)">
                <v-menu
                    offset-y
                    v-model="openMenu"
                    close-on-content-click
                >
                    <template v-slot:activator="{ on }">
                        <fe-icon-btn v-on="on" use-icon="more_vert" />
                    </template>
                    <v-list>
                        <v-list-item v-if="!canManageGroups && !isGlobal" @click="editing = true">
                            <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="$isSupport()" @click="pushing = true">
                            <v-list-item-title>Copy to climberCLOUD</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>

        <div class="d-flex flex-column mt-4" v-if="canManageGroups">
            <div class="d-flex">
                <div class="d-flex assessment-label">
                    <div class="label-text">Allow Score Entry <fe-info-tip class="tip" tooltip="Allows all users to enter or update scores for these assessments." /></div>

                    <div class="control">
                        <fe-switch
                            :value="allowScoreEntry"
                            @input="allowScoreEntry = $event"
                        />
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="d-flex assessment-label">
                    <div class="label-text">Assessment Enabled <fe-info-tip class="tip" tooltip="Disabling the assessment removes it from all charts. Historical scores will be saved but no longer viewable." /></div>
                    <div class="control">
                        <fe-switch
                            :value="assessmentEnabled"
                            @input="assessmentEnabled = $event"
                        />
                    </div>
                </div>
            </div>
            <div class="d-flex" v-if="secondaryEnrollmentEnabled">
                <div class="d-flex assessment-label">
                    <div class="label-text">Secondary Enrollment <fe-info-tip class="tip" tooltip="Enables Secondary Enrollment for this assessment. Window Start and End Days will also need to be configured." /></div>
                    <div class="control">
                        <fe-switch
                            :value="secondaryEnrollment"
                            @input="secondaryEnrollment = $event"
                        />
                    </div>
                </div>
            </div>
            <div v-if="featureFlagScoreType" class="d-flex">
                <div class="d-flex assessment-label">
                    <div class="label-text combo">Score Type to Display <fe-info-tip class="tip" tooltip="Changing this value only affects new data going forward." /></div>
                    <div class="control">
                        <fe-remote-combo :multiple="false" :clearable="false" :items="scoreTypeItems" @input="scoreType = $event" :value="scoreType" class="mr-3"></fe-remote-combo>
                    </div>
                </div>
            </div>
            <div class="d-flex" v-if="featureFlagScoreType && (scoreType === 'LOWEST_SCORE' || scoreType === 'HIGHEST_SCORE')">
                <div class="d-flex assessment-label">
                    <div class="label-text combo">Optional Score Detail <fe-info-tip class="tip" tooltip="Changing this value only affects new data going forward." /></div>
                    <div class="control">
                        <fe-remote-combo :multiple="false" :clearable="true" :items="scoreTypeDetailItems" @input="scoreTypeDetail = $event" :value="scoreTypeDetail" class="mr-3"></fe-remote-combo>
                    </div>
                </div>
            </div>
        </div>

        <fe-dialog
            v-if="editing"
            @close="editing = false"
            @dismiss="editing = false"
            @accept="save"
            title="Edit Assessment"
            acceptButtonText="Save"
            :acceptButtonDisabled="!valid"
            persistent
        >
            <v-form @submit.prevent v-model="valid">
                <div class="d-flex flex-column">
                    <fe-label>Assessment Name</fe-label>
                    <v-text-field
                        v-model="obj.name"
                        flat solo dense
                        :style="{ width: '100%' }"
                        :rules="rules.name"
                    />
                </div>
            </v-form>
        </fe-dialog>

        <fe-dialog
            v-if="pushing"
            @close="pushing = false"
            @dismiss="pushing = false"
            @accept="push"
            title="Copy to climberCLOUD"
            acceptButtonText="Send"
            :acceptButtonDisabled="!pusherValid"
            persistent
        >
            <v-form @submit.prevent v-model="pusherValid">
                <div class="d-flex flex-column">
                    <fe-label>Assessment Group Name</fe-label>
                    <v-text-field
                        v-model="pusher.name"
                        flat solo dense
                        :style="{ width: '100%' }"
                        :rules="pusherRules.name"
                    />

                    <fe-label>Short Name</fe-label>
                    <v-text-field
                        v-model="pusher.short_name"
                        flat solo dense
                        :style="{ width: '100%' }"
                    />

                    <fe-label>Standard Assessment?</fe-label>
                    <p class='text-smaller'>Enable to make this a standard eduCLIMBER assessment, which allows for norms and target sets to be entered. Otherwise, this will be treated as a local assessment.</p>
                    <v-switch
                        v-model="pusher.standardize"
                    />
                </div>
            </v-form>
        </fe-dialog>

        <fe-action-list
            ref="lst"
            :showFiltering="false"
            :items="displayItems"
            :addButton="false"
            :editButton="false"
            :titleWidth="300"
            @rowClick="$emit('selectGroupDetail', $event)"
            @actionButtonClick="$emit('createItem', $event.actionItemType)"
        />

        <fe-crud
            ref="crud"
            :config="$models.assessment"
            @read="item = $event[0]"
            refresh-on-config
            @error="updateError"
        />

        <fe-crud
            ref="crudCloud"
            :config="$models.assessment"
            :createParams="{
                action: 'push_to_cloud'
            }"
            @created="pushing = false"
        />

        <fe-crud
            ref="crudScoreDisplay"
            autoload
            :config="$models.alphaMap"
            :readParams="{ data_point_type_id: group.id }"
            @read="alphaMap = $event"
            refresh-on-config
        />

        <fe-crud
            ref="crudScoreDetailType"
            autoload
            :config="$models.scoreDetailType"
            :readParams="{ data_point_type_id: group.id }"
            @read="scoreDetail = $event || []"
            :failOnEmptyResponse="false"
            refresh-on-config
        />
    </div>
</template>

<script>
    export default {
        name: 'AssessmentGroupSummary',
        props: {
            group: { type: Object, default: () => {}, required: true },
            groups: { type: Array, default: () => [] },
            sec: { type: Object, required: true },
            isMissingWindowDates: { },
        },
        data () {
            return {
                openMenu: false,
                editing: false,
                valid: true,
                obj: {
                    name: null
                },
                rules: {
                    name: [
                        v => (!!v && v.trim().length > 0) || 'Name is required',
                        v => (!!v && !this.nameDuplicated(v))  || 'Name must be unique'
                    ]
                },
                pushing: false,
                pusherValid: true,
                pusher: {
                    name: null,
                    short_name: null,
                    standardize: false
                },
                pusherRules: {
                    name: [
                        v => (!!v && v.trim().length > 0) || 'Name is required'
                    ]
                },
                alphaMap: [],
                scoreDetail: [],
                attemptedSecondaryEnrollment: false
            }
        },
        computed: {
            featureFlagScoreType () {
                return this.$store.getters['flags/flags']?.['Assessment_Score_Display'] === true
            },
            scoreTypeDetailItems () {
                let items = []
                this.scoreDetail.forEach(scoreDetailType => {
                    items.push({
                        id: scoreDetailType.id,
                        name: scoreDetailType.code,
                    })
                })
                return items
            },
            scoreTypeItems () {
                let items = [{
                    id: 'LAST_IMPORTED',
                    name: 'Last Imported'
                }, {
                    id: 'MOST_RECENT',
                    name: 'Most Recent'
                }, {
                    id: 'HIGHEST_SCORE',
                    name: 'Highest Score'
                }, {
                    id: 'LOWEST_SCORE',
                    name: 'Lowest Score'
                }]
                if (this.selectedGroup.short_name == 'PALS') {
                    items.push({
                        id: 'CUSTOM_PALS',
                        name: 'Custom: PALS',
                    })
                }
                return items
            },
            isGlobal () { return this.group.id < 10000 },
            canManageGroups () { return this.$hasSecurity(this.sec.groups) },
            selectedGroup: {
                get () { return this.group || this.item },
                set (v) { this.$emit('switchGroup', v) }
            },
            allowScoreEntry: {
                get () { return !!(this.selectedGroup && this.selectedGroup.allow_score_entry) },
                set (v) {
                    this.item.allow_score_entry = v ? 1 : 0
                    this.update()
                }
            },
            scoreType: {
                get () { return this.selectedGroup ? this.selectedGroup.use_score_type : 'LAST_IMPORTED' },
                set (v) {
                    this.item.use_score_type = v.id
                    this.update()
                }
            },
            scoreTypeDetail: {
                get () { return this.selectedGroup ? this.selectedGroup.score_detail_type_id : null },
                set (v) {
                    this.item.score_detail_type_id = v ? v.id : null
                    this.update()
                }
            },
            assessmentEnabled: {
                get () { return !(this.selectedGroup && this.selectedGroup.hidden) },
                set (v) {
                    this.item.hidden = v ? 0 : 1
                    this.update()
                }
            },
            secondaryEnrollment: {
                get() { return !!(this.selectedGroup && this.selectedGroup.secondary_enrollment_flag) },
                set(v) {
                    this.item.secondary_enrollment_flag = v ? 1 : 0
                    this.update()
                }
            },
            displayItems () {
                return [{
                        crumbName: "Assessments",
                        name: this.getFormattedName("Assessments", this.selectedGroup.assessment_names.length, true),
                        show: "assessments",
                        actionButtonText: this.selectedGroup.assessment_names.length === 0
                            ? "Add Assessments"
                            : null,
                        actionItemType: "assessments",
                        security: this.sec.groups
                    }, {
                        crumbName: "Windows",
                        name: this.getFormattedName("Windows", this.selectedGroup.window_names.length, true, this.attemptedSecondaryEnrollment && this.isMissingWindowDates),
                        show: "windows",
                        actionButtonText: this.selectedGroup.window_names.length === 0
                            ? "Add Windows"
                            : this.attemptedSecondaryEnrollment && this.isMissingWindowDates
                            ? "Add Window Dates"
                            : null,
                        actionItemType: this.attemptedSecondaryEnrollment && this.isMissingWindowDates ? "windowDates" : "windows",
                        security: this.sec.groups
                    }, {
                        crumbName: "Performance Bands",
                        name: this.getFormattedName("Performance Bands", this.selectedGroup.band_names.length, true),
                        show: "bands",
                        actionButtonText: this.selectedGroup.band_names.length === 0
                            ? "Add Bands"
                            : null,
                        actionItemType: "bands",
                        security: this.sec.bands
                    }, {
                        crumbName: "Score Details",
                        name: this.getFormattedName("Score Details", this.scoreDetail.length),
                        show: "scoreDetails",
                        security: this.sec.groups
                    }, {
                        crumbName: "Score Display",
                        name: this.getFormattedName("Score Display", this.alphaMap.length),
                        show: "scoreDisplay",
                        security: this.sec.groups
                    }, {
                        crumbName: "Embargo Dates",
                        name: this.getFormattedName("Embargo Dates", this.selectedGroup.data_embargo_cnt),
                        show: "embargoDates",
                        security: null,//this.sec.embargoes,
                    },
                ].filter(itm => !itm.security || this.$hasSecurity(itm.security))
            },
            secondaryEnrollmentEnabled() {
                return this.$store.getters['flags/flags']['ec-secondaryenrollment'] === true
            }
        },
        methods: {
            nameDuplicated (v) {
                return this.groups.find(grp => grp.id !== this.group.id && `${grp.name}`.trim().toLowerCase() == `${v}`.trim().toLowerCase() )
            },
            getFormattedName (name, count, required, force = false) {
                let result = `${name} <span style="margin-left: 20px; font-size: 12px;">${count}</span>`
                if (required && (count == 0 || force)) result = `<i style='color: #ea0101;' class='fa fa-exclamation-circle'></i> ${result}`
                return result
            },
            update () {
                let updates = {
                    allow_score_entry: this.allowScoreEntry ? 1 : 0,
                    hidden: this.assessmentEnabled ? 0 : 1,
                    secondary_enrollment_flag: this.secondaryEnrollment ? 1 : 0
                }
                let updated = Object.assign({}, this.item, updates)
                if (!this.featureFlagScoreType) {
                    delete updated.use_score_type
                    delete updated.score_detail_type_id
                }
                this.$refs.crud.update([ updated ]).then((resp) => {
                    if(resp.data.success) {
                        this.$emit('reloadCurrentGroup')
                    }
                })
            },
            save () {
                this.$emit('saveGroup', Object.assign({}, this.group, this.obj))
            },
            push () {
                let obj = Object.assign({}, this.pusher, { data_point_type_id: this.group.id })
                this.$refs.crudCloud.create({}, obj)
            },
            reset () {
                this.item = this.group
                this.obj.name = this.group.name
                this.pusher.name = this.group.name
                this.pusher.short_name = this.group.short_name
            },
            updateError(v) {
                this.selectedGroup.secondary_enrollment_flag = false
                this.attemptedSecondaryEnrollment = true
            }
        },
        mounted () {
            this.reset()
        },
        watch: {
            group () { this.reset() },
            isMissingWindowDates () { this.reset() },
        },
    }
</script>

<style lang="scss" scoped>
    .assessment-label {
        width: 550px;
        .label-text {
            &.combo {
                padding-top: 7px;
            }
            color: #050F2D;
            font-size: 14px;
            width: 250px !important;
        }
        .tip {
            position: relative;
            display: inline-block;
            top: -2px;
            left: 5px;
        }
        .control {
            width: 300px !important;
        }
    }
</style>