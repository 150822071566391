export default {
    projectionType: {
        defaults: {
            endpoint: 'qry.php?property=projection_types',
            rootProperty: 'projection_types'
        },
        read: {
            params: { action: 'get' }
        },
        create: {
            params: { action: 'create' }
        },
        update: {
            params: { action: 'update' }
        },
        destroy: {
            params: { action: 'delete' }
        }
    }
}