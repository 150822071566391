<template>
  <div>
    <fe-dialog
        v-if="showSendConfirmation"
        data-test="sending-smartform-confirmation-modal"
        :dismissable="false"
        :header="false"
        :footer="false"
        persistent
        disableAutoclose
    >
        <div class="text-center mt-6">
            <v-img
                src="@/assets/paper-airplane.png"
                max-width="120"
                style="margin:0 auto;"
            />
            <h2 class="mb-6 mt-4">Sending smartFORMS</h2>
        </div>
        <p>Your smartFORMS are on their way! It may take up to a few hours to send all the emails. You will be notified in eduCLIMBER once all the emails have been sent.</p>
        <p>Check back later to see if any emails could not be delivered. You may need to resend emails or update records if emails provided are not valid.</p>
        <div class="text-center mt-2">
           <fe-btn usage="primary" @click="$router.push('/SmartForms')">Got It</fe-btn>
        </div>
    </fe-dialog>

    <fe-dialog
        v-if="showFailureModal"
        data-test="sending-smartform-failure-modal"
        :dismissable="false"
        :header="false"
        :footer="false"
        persistent
        disableAutoclose
    >
        <h2 class="mb-4 mt-6"><v-icon class="mr-2" style="color:#BF0D00">far fa-exclamation-circle</v-icon> Temporary Error</h2>
        <p>eduCLIMBER is experiencing a technical difficulty and your emails were not sent. Please try again. If the error persists, contact customer support.</p>
        <div class="mt-2" style="margin-left:-8px;">
           <fe-btn usage="primary" @click="showFailureModal=false">Got It</fe-btn>
        </div>
    </fe-dialog>

    <h3 style="font-size: 18px; margin-bottom: 16px;">Review Summary & Send</h3>
    <h5 style="font-size: 14px;" class="mb-1">Guardian emails</h5>
    <p>{{ guardianContactsCount }} guardian email{{ guardianContactsCount == 1 ? '' : 's' }} for {{ studentsCount }} student{{ studentsCount == 1 ? '' : 's' }}</p>
    <h5 style="font-size: 14px; margin-top: 16px;" class="mb-1">smartFORMS</h5>
    <p>{{ smartformsCount }} smartFORM{{ smartformsCount == 1 ? '' : 'S' }} from 1 smartFORM template</p>
    <h5 style="font-size: 14px; margin-top: 16px;" class="mb-1">Communication settings</h5>
    <ul>
      <li>smartFORM will be sent in English, not in the student’s home language.</li>
      <li v-if="responseRequired">Prompt guardians to acknowledge that they have reviewed the form.</li>
      <li v-else>Do not prompt guardians to acknowledge that they have reviewed the form.</li>
    </ul>
    <h3 style="font-size: 18px; margin-top: 20px;" class="mb-1">What happens next?</h3>
    <p>
      Once you email the smartFORMS, it may take a few hours to send all emails.
      <br>
      You will be notified when they have all been sent. You can track which guardians have acknowledged receipt.
    </p>
    <div class="notification">
      <div class="left-side"></div>
      <div class="message">
        <h5>To protect student privacy, links to smartFORMS sent via email will expire after 7 days on {{ expirationDate }}.</h5>
      </div>
    </div>
    <fe-btn
      style="margin-top: 20px; margin-left: -4px;"
      usage="secondary"
      @click="$emit('goToPreview')"
    >
      Back
    </fe-btn>
    <fe-btn
      style="margin-top: 20px; margin-left: -4px;"
      usage="primary"
      @click="emailForms"
    >
      Email Form{{ smartformsCount == 1 ? '' : 's' }} to {{ guardianContactsCount }} Guardian{{ guardianContactsCount == 1 ? '' : 's' }}
    </fe-btn>
  </div>
</template>
<script>
  export default {
    name: 'SendEmail',
    props: {
      expirationDate: {
        type: String,
        required: true
      },
      studentsCount: {
        type: Number,
        required: true
      },
      guardianContactsCount: {
        type: Number,
        required: true
      },
      smartformsCount: {
        type: Number,
        required: true
      },
      responseRequired: {
        type: Boolean,
        required: true
      },
      formInstances: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        showFailureModal: false,
        showSendConfirmation: false,
        emailing: false,
      }
    },
    mounted() {

    },
    methods: {
      emailForms() {
        // prevent double submissions
        if (this.emailing) return
        this.emailing = true

        return this.$modelGet('guardianFormEmailSend', {
            form_instances: this.formInstances,
            require_response: this.responseRequired ? '1' : '',
            translate_form: ''
          })
          .then(response => {
              if (!response?.success) throw response
              this.showSendConfirmation = true
          })
          .catch(err => {
              console.warn('Unable to load guardian form email send', err)
              this.showFailureModal = true
              this.emailing = false
          })
      }
    }
  };
</script>
<style>
  .notification {
    display: flex;
    align-items: center;
  }

  .left-side {
    width: 10px;
    height: 34px;
    background-color: #FFBD00;
  }

  .message {
    background-color: #FFF9E3;
    padding: 10px;
    height: 34px;
    align-items: center;
    font-size: 14px;
  }
</style>
