<template>
    <fe-dialog
        v-if="active"
        title="Duplicate a Threshold Grouping"
        acceptButtonText="Create"
        dismissButtonText="Cancel"
        :acceptButtonDisabled="!valid"
        @dismiss="dismiss"
        @accept="apply"
    >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-layout row wrap>
                <v-flex xs12 px-1>
                    <fe-label content="New Threshold Grouping Name"/>
                    <v-text-field
                        v-model="obj.name"
                        :rules="nameRules"
                        flat solo dense
                        required
                    />
                </v-flex>
            </v-layout>
        </v-form>
    </fe-dialog>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    name: 'GroupDuplicate',

    props: {
        active: Boolean
    },

    data() {
        return {
            obj: {},
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
                v => this.nameIsUnique(v) || 'Name must be unique'
            ]
        }
    },

    computed: {
        ...mapGetters('thresholds/group', [
            'collection',
            'clear',
            'pending',
            'success',
            'error',
            'selected'
        ]),

        thresholdGroupNames() {
            return this.$store.getters['thresholds/group/collection'].map(x => x.name.toLowerCase().trim())
        },

        thresholdGroup() {
            return this.$store.getters['thresholds/group/selected']
        },

        thresholdGroupId() {
            return this.thresholdGroup.id
        }
    },

    watch: {
        clear: function (val) {
            if (val) this.reset()
        },

        active: function (val) {
            if (val) {
                this.obj = {
                    id: this.thresholdGroupId,
                    name: `Copy of ${this.thresholdGroup.name}`
                }
                if (this.$refs.form) this.$refs.form.resetValidation()
            }
        }
    },

    mounted() {
        if (this.clear) this.refresh()
    },

    methods: {
        ...mapActions('thresholds/group', [
            'refresh'
        ]),

        nameIsUnique(name) {
            return !this.thresholdGroupNames.includes(name.toLowerCase().trim())
        },

        apply() {
            if (this.$refs.form.validate()) {
                this.$emit('apply', this.obj)
            }
        },

        dismiss() {
            this.$emit('cancel')
        },

        validate() {
            return this.$refs.form.validate()
        }
    }
}
</script>
