<template>
    <fe-dialog
        title="New School"
        persistent
        acceptButtonText="Save"
        dismissButtonText="Cancel"
        width="350px"
        :forceClose="false"
        dismissable
        disableAutoclose
        @accept="saveRec"
        @dismiss="dismissDialog"
    >
        <v-form ref="form">

            <!-- <div class="d-flex">
                <fe-label>Internal ID</fe-label>
                <v-text-field flat solo dense validateOnBlur :disabled="true" v-model="newRec.student_id" :rules="[v => !!v || 'Field is required']" />
            </div> -->
            <div class="d-flex">
                <fe-label>School Year</fe-label>
                <fe-remote-combo
                    class="student-controls"
                    :items="this.$props.schoolYearList"
                    v-model="newRec.school_year_id"
                    itemText="name"
                    itemValue="id"
                    :rules="$fieldValidators('select', 'School year', { required: true })"
                    byId>
                </fe-remote-combo>
            </div>
            <div class="d-flex">
                <fe-label>School</fe-label>
                <fe-remote-combo
                    class="student-controls"
                    :items="this.schools"
                    v-model="newRec.school_id"
                    itemText="name"
                    itemValue="id"
                    :rules="$fieldValidators('select', 'School', { required: true })"
                    byId>
                </fe-remote-combo>
            </div>
            <div class="d-flex">
                <fe-label>Inactive Dt</fe-label>
                <fe-date-picker class="add-school-date-picker" placeholder="MM/DD/YYYY" v-model="newRec.inactive_date" />
            </div>

        </v-form>

    </fe-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { mapLocalState } from '@/util/vuexHelper'

export default {
    name: 'AddSchool',

    props: {
        crud: null,
        studentInfo: null,
        schoolList: null,
        schoolYearList: null
    },

    data() {
        return {
            newRec: {
                student_id: undefined,
                school_year_id: undefined,
                school_id: undefined,
                inactive_date: undefined,
                upload_id: undefined
            }
        }
    },

    inject: ['localStore'],

    computed: {
        ...mapLocalState(['schools'])
    },

    methods: {
        saveRec() {
            this.newRec.student_id = this.$props.studentInfo.student_id

            if(this.$refs.form.validate()) {
                this.crud.create(this.newRec).then( r => {
                    this.resetEntry()
                    this.dismissDialog()
                })
            }
        },

        resetEntry() {
            this.newRec = {
                student_id: undefined,
                school_year_id: undefined,
                school_id: undefined,
                inactive_date: undefined,
                upload_id: undefined
            }
        },

        dismissDialog() {
            if (this.$_.isEmpty(Object.values(this.newRec).filter(x => !!x))) {
                this.$emit('close')
            } else {
                this.$confirmCancel(() => {
                    this.resetEntry()
                    this.$emit('close')
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep div.fe-label {
        width: 76px;
    }

    ::v-deep {
        div.add-school-date-picker, div.student-controls {
            width: 242px;
        }
    }
</style>
