<template>
    <div class="d-flex flex-fill flex-column no-scroll">
        <div class="fidelity-titlebar d-flex" v-if="student">
            <v-avatar size="36px">
                <v-img :src="getStudentImg(params.student_id)"/>
            </v-avatar>

            <div class="mx-2">
                <div style="font-size: 14px; font-weight: 500;">{{student.student_full_name}}</div>
                <div style="font-size: 12px;">{{plan.name}}</div>
            </div>
        </div>

        <div class="fidelity-chart fe-chart">
            <highcharts ref='chartRef' v-if="drawChart" :options="config"></highcharts>
        </div>

        <div class="flex-fill d-flex flex-column">
            <fe-grid
                :showToolbar="false"
                :columnDefs="columns"
                :rowData="items"
                style="height: 170px;"
                displayDensity="small"
            ></fe-grid>
        </div>
    </div>
</template>

<script>
    import windowOptionsMixin from '@/mixins/windowOptions'
    export default {
        name: 'InterventionFidelity',
        mixins: [ windowOptionsMixin ],
        props: {
            params: {
                type: Object,
                required: true
            }
        },
        computed: {
            student() {
                if (!this.intervention || !this.intervention.plans) return false
                let rec = this.intervention.plans[0].students.find(r=>r.student_id===this.params.student_id)
                return rec
            },
            plan() {
                if (!this.intervention || !this.intervention.plans) return false
                return this.intervention.plans[0]
            }
        },
        data() {
            let me = this
            return {
                items: [],
                colors: [
                    '#999999', // 0 index color
                    '#006699',
                    '#F59B00',
                    '#08916D',
                    '#529EE0',
                    '#53B7D8',
                    '#D9A5C2',
                    '#FEB03B',
                    '#53A67B',
                    '#E16032',
                    '#4FD2D2',
                    '#F0E342',
                    '#E287B2'
                ],
                drawChart: false,
                attendanceTypes: [],
                columns: [{
                   headerName: "Date",
                   field: "schedule_date_occurrence",
                   editable: false,
                   flex: 1
                }, {
                    headerName: 'Participation',
                    field: 'student_intervention_occurrence_attendance_type_id',
                    flex: 1,
                    valueGetter: v => {
                        let r = me.attendanceTypes.find(r=>r.id===v.data.student_intervention_occurrence_attendance_type_id)
                        return r?.name || ''
                    },
                    editable: true,
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: v => {
                        return {
                            value: v.data.student_intervention_occurrence_attendance_type_id,
                            rowDataKey: "student_intervention_occurrence_attendance_type_id",
                            mode: "edit",
                            items: me.attendanceTypes,
                            keyProp: "id",
                            labelProp: "name",
                            disableChips: true
                        }
                    },
                    onCellValueChanged: v => me.updateParticipation(v.data)
                }],
                config: {
                    credits: { enabled: false },
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        height: 200
                    },
                    plotOptions: {
                        pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                        }
                    },
                    title: {
                        text: undefined
                    },
                    series: [{
                        name: 'Participation',
                        data: []
                    }],
                    legend: {
                        layout: 'vertical',
                        align: 'right'
                    }
                },
                intervention: null
            }
        },
        created() {
            this.$axios.get('interventionView.php?action=get&property=intervention&'+this.$objectToParams(this.params))
                .then(r=> {
                    this.intervention = this.$ecResponse(r, 'interventions')[0]
                })
            this.$modelGet('interventionAttendanceType', { active: 1}).then(types => {
                this.attendanceTypes = types
                this.loadData()
            })

        },
        methods: {
            updateParticipation(rec) {
                this.$axios.post('studentInterventionOccurrences.php?action=update', { occurrences: [rec] })
                    .then(resp => {
                        this.$ecResponse(resp)
                        this.loadData()
                    })
            },
            loadData() {

                this.$axios.get('interventionView.php?action=get&property=student_intervention_schedule&record_type=fidelity&'+this.$objectToParams(this.params))
                    .then(response => {
                        this.items = this.$ecResponse(response, 'schedules')

                        this.buildSeries()
                    })
            },
            buildSeries() {
                let series = []
                let lookup = {}
                this.config.series[0].data = []
                this.items.forEach((i,j) => {
                    let id = i.student_intervention_occurrence_attendance_type_id
                    let name = i.student_intervention_occurrence_attendance_type_name

                    if (name) {
                        if (!lookup[id]) {
                            lookup[id] = {
                                name:   name,
                                y:      0,
                                color:  this.colors[i.student_intervention_occurrence_attendance_type_id]}
                        }
                        lookup[id].y++
                    }
                })
                for (let key in lookup) {
                    this.config.series[0].data.push(lookup[key])
                }
                this.drawChart=true
            },
            getStudentImg(student_id) {
                return this.$axios.defaults.baseURL + this.$models.getUrl('studentImage', 'read') + '&id=' + student_id
            },
        },
    }
</script>

<style lang="scss" scoped>
.fe-chart ::v-deep * {
    font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
}
</style>
