<template>
    <v-menu
        v-model="menu"
        offset-y
        content-class="menu"
        max-width="550px"
        min-width="550px"
        :close-on-content-click="false"
    >
        <template #activator="{ on }">
            <div v-on="on"
                :style="style"
                class="d-flex theme--light v-input v-text-field v-input__slot"
            >
                <div style="overflow: hidden;">
                    <div class="label">{{selectedType}}</div>
                    <div class="label">{{selectedName}}</div>
                </div>
                <div class="ml-auto"><i class="v-icon mdi mdi-menu-down theme--light primary--text"></i></div>
            </div>
        </template>
        <!-- <fe-stepper-filter
            :title="title"
            style="background-color:#E0E1E6;height:360px"
            @close="menu=false"
            @apply="$emit(selectedField.id || selectedSection.id)"
        > -->
        <div class="d-flex" style="max-height:360px;">
            <fe-stepper-select-panel :items="sections" v-model="selectedSection" width="50%"/>
            <v-divider vertical style="background-color:#E0E1E6;width:2px;"/>
            <fe-stepper-select-panel :items="fields" v-model="selectedField" width="50%"/>
        </div>
        <!-- </fe-stepper-filter> -->
    </v-menu>
</template>

<script>
import smartFormMixin from '../smartFormMixin'

export default {
    name: 'ItemStepper',
    mixins: [smartFormMixin],
    
    props: {
        title: {},
        value: {},
        items: {},
        allFields: {},
        width: {default:'100%'},
        controller: {type:Boolean, default:false},
    },
    
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(v) {
                if(this.controller) {
                    if(v.controlling_form_field_id) {
                        let f = this.allFields[v.controlling_form_field_id]
                        this.selectedSection = this.sections.find(x => x.id == f.form_section_id )
                        this.selectedField = f
                    } else {
                        this.selectedField = this.selectedSection = null
                    }
                } else {
                    if(v.form_field_id) {
                        let f = this.allFields[v.form_field_id]
                        this.selectedSection = this.sections.find(x => x.id == f.form_section_id )
                        this.selectedField = f
                    } else if(v.form_section_id) {
                        this.selectedSection = this.sections.find(x => x.id == v.form_section_id )
                    } else {
                        this.selectedField = this.selectedSection = null
                    }
                }
            }
        },
        selectedSection: {
            handler(v) {
                this.selectedField = null
                if(!v || this.controller) return
                this.value.form_section_id = v.id
                this.$emit('input',this.value)
            }
        },
        selectedField: {
            handler(v) {
                if(!v) return
                if(this.controller) {
                    this.value.controlling_form_field_id = v.id
                } else {
                    this.value.form_field_id = v.id
                    this.value.form_section_id = null
                }
                this.$emit('input',this.value)
                this.menu = false
            }
        }
    },
    
    computed: {
        selectedType() {
            if(this.selectedField) return this.formFieldTypes[this.selectedField.form_field_type_id]?.name
            if(!this.controller && this.selectedSection) return `Section ${this.selectedSection.index}`
            return ''
        },
        selectedName() {
            if(this.selectedField) return this.selectedField?.name
            if(!this.controller && this.selectedSection) return this.selectedSection?.short_name
            return ''
        },
        sections() {
            return this.items.map((x,i) => {
                return {
                    ...x,
                    name:`Section ${i+1}: ${x.name}`,
                    index: i+1,
                    short_name: x.name,
                }
            })
        },
        fields() {
            return this.selectedSection
                ? this.selectedSection.form_fields
                : []
        },
        style() {
            return {
                height: '52px',
                maxWidth: this.width,
                backgroundColor: 'white',
                borderRadius: '3px',
                border: '1px solid #ced0d9',
            }
        },
    },
    data() {
        return {
            menu: false,
            selectedSection: null,
            selectedField: null,
        }
    }
}
</script>

<style lang="scss" scoped>
.menu {
    overflow: hidden;
    background-color: white;
}
.label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}
</style>
