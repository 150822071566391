<template>
    <v-dialog
        v-model="modalActive"
        width="80%"
        :transition="false"
        :retain-focus="false"
    >
        <v-card color="white" style="width: 100%;" class="d-flex flex-column">
            <v-card-title class="fe-dialog-card-title">
                Comments
                <v-spacer></v-spacer>
                <v-btn icon @click="close(); comments.show = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="fe-dialog-card-text-no-padding flex-grow-1 flex-fill flex-column" style="overflow: hidden;">
                <fe-editor
                    :toolbarExtras="['|', 'redo', 'undo']"
                    ref="editor"
                    v-model="newComment"
                    :editorConfig="editorConfig"
                    submittable
                    @submit="submitNote"
                />
                <div class="d-flex flex-column">
                    <div v-if="comments.notes && comments.notes.length">
                        <div class="ai-detail-title">Recent Comments:</div>
                        <v-data-table
                            :items="displayedNotes"
                            :headers="noteHeaders"
                            class="aid-table"
                            hide-default-footer
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="px-2">
                                        <v-avatar size="40">
                                            <img v-if="item.photo_url" :src="item.photo_url">
                                            <v-icon v-else>fas fa-user</v-icon>
                                        </v-avatar>
                                    </td>
                                    <td class="py-3 pl-1 pr-3">
                                        <div
                                            class="ai-comment-title"
                                            v-html="item.user_full_name + ' &bull; ' + new Date(item.created).toLocaleDateString()"
                                        ></div>
                                        <div class="comment-text" v-html="item.comment"></div>
                                    </td>
                                    <td class="px-0">
                                        <v-btn icon v-if="item.can_delete" @click="deleteNote(item)" class="ma-0 delete">
                                            <v-icon small color="#f5f5f5">fas fa-trash</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                        <div v-if="comments.notes.length > 3" class="hide-show-text my-2" @click="truncated = !truncated">
                            {{ hideShowText }}
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'ActionItemComments',

        props: {
            comments: {Object, default: () => ({})},
        },

        data() {
            return {
                newComment: '',
                truncated: true,
                noteHeaders: [
                    {
                        text: '',
                        value: null,
                        sortable: false,
                        width: '40px'
                    },
                    {
                        text: '',
                        value: null,
                        sortable: false
                    },
                    {
                        text: '',
                        value: null,
                        sortable: false,
                        width: '80px'
                    }
                ],
                editorConfig: {
                    placeholder: "Add a comment"
                },
            }
        },

        computed: {
            hideShowText() {
                if (!this.truncated) return 'Hide'
                let extra = this.comments.notes.length - 3
                return 'Show ' + extra + ' older comment' + (extra !== 1 ? 's' : '')
            },
            displayedNotes() {
                let notes = this.comments.notes
                if (this.truncated && notes.length > 3) {
                    return notes.slice(0, 3)
                }
                return notes
            },
            modalActive: {
                get () { return this.comments.show },
                set (v) { this.comments.show = v }
            },
        },

        methods: {
            submitNote() {
                let rec = {
                    comment: this.newComment,
                    task_id: this.comments.task_id
                }
                this.$emit('submit-note', rec)
                this.close()
            },
            deleteNote(item) {
                let rec = {
                    id: item.id,
                }
                this.$emit('delete-note', rec)
            },
            close() {
                this.newComment = ''
                this.$refs.editor.clearNote()
            }
        }

    }
</script>

<style lang="scss" scoped>
    .hide-show-text {
        font-size: 14px;
        text-decoration: underline;
        color: rgb(0, 73, 255);
        margin: 5px;
        cursor: pointer;
    }
    .ai-detail-title {
        color: black;
        font-size: 14px;
        margin-bottom: 5px;
    }
    .ai-comment-title {
        color: inherit;
        font-size: 14px;
    }
    .ai-smart-form-link {
        color: blue;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
    }
    .comment-text {
        color: rgba(0, 0, 0, .54);
    }
    ::v-deep .comment-text p {
        margin-bottom: 0;
    }
    ::v-deep .aid-table thead * {
        height: 0 !important;
    }
    ::v-deep .aid-table .v-datatable__progress {
        display: none !important;
    }
    ::v-deep .aid-table tr, ::v-deep .aid-table td, ::v-deep .aid-table tbody, ::v-deep .aid-table table {
        border: none !important;
    }
    ::v-deep .aid-table.editor tr:hover {
        background: none !important;
    }
    .delete:hover .v-icon {
        color: #BF0D00 !important;
    }
    .aid-table {
        max-height: 300px;
        overflow: scroll;
    }
</style>
