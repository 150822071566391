<template>
    <div class="d-flex flex-column fill-flex">
        <div class="headline mb-1 d-flex">
            Point Sheet Score Status
            <fe-info-tip
                class="ml-2"
                tooltip="Available statuses for each period on a student point sheet"
            />
        </div>

        <fe-crud-grid
            autoload
            :config="$models.monitorScoreStatus"
            @read="v => status = v"
            ref="grid"
            disableInlineCreate
            domLayout="autoHeight"
            :columnDefs="columns"
            :rowData="status"
            displayDensity="medium"  
            :showAddRowBtn="false"
        />

    </div>
</template>

<script>

    export default {
        name: "ScoreStatus",
        data() {
            return {
                status: [],
                createDialog: {
                    show: false,
                    records: [{
                        icon: 'int_level_orange'
                    }]
                }
            }
        },
        computed: {
            columns() {
                let me = this
                return [{
                    headerName: 'Status',
                    field: 'name',
                    flex: 1
                }, this.$grid.toggleColumn('Applies to Goal', 'total_incl_flag', null, () => { }, {
                    minWidth: 100,
                    maxWidth: 100
                }), this.$grid.toggleColumn('Active', 'active', null, () => { }, {
                    minWidth: 100,
                    maxWidth: 100
                })]
            }
        },
        methods: {
            finishCreate() {
                this.createDialog.show = false
                this.createDialog.records = [{
                    icon: 'int_level_orange',
                    active: true
                }]

                this.$refs.grid.$refs.crud.read()
            },
            startCreate() {
                this.createDialog.show=true
                this.createDialog.records = [{
                    icon: 'int_level_orange',
                    active: true
                }]
            },
            updateCrud(crud, meta) {

            },
            crudConfirmDelete() {

            }
        }
    }
</script>

<style scoped lang="scss">
.color-picker {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;

    &-selected {
        border: 2px solid #303030;
}
}


</style>
