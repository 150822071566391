import BaseService from '@/components/modules/thresholds/services/BaseService'
import BaseStore from './Base'

export default Object.assign({}, BaseStore.default(), {
    namespaced: true,

    getters: Object.assign({}, BaseStore.default().getters, {
        __config() {
            return {
                service: BaseService,
                opts: {type: 'forms'},
                params: {active: 1, requires_student: 1},
                prop: 'forms'
            }
        }
    })
})
