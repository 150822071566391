<template>
    <div class="performance-band-graph-wrapper">
        <div class="performance-band-graph">
            <div
                v-for="(band, idx) in bands"
                :key="`band-${idx}`"
                :style="{
                  backgroundColor: colors[idx],
                  width: pWidths[idx],
                  left: pLefts[idx]
                }"
                class="graph-band"
            />
        </div>

        <div class="performance-band-labels" v-if="hasLabels">
            <div
                v-for="(band, idx) in bands"
                :key="`label-${idx}`"
                :style="{
                  width: pWidths[idx],
                  left: pLefts[idx]
                }"
                class="graph-label"
                v-html="labels[idx]"
            />
        </div>

        <div class="performance-band-labels" v-if="hasBottomLabels">
            <div class='bottom-left-label' v-html="bottomLeftLabel"/>

            <div class='bottom-right-label' v-html="bottomRightLabel"/>
        </div>
    </div>
</template>

<script>
import Colors from '../config/Colors'

export default {
    name: 'PerformanceBand',

    props: {
        bands: Array,
        values: Array,
        labels: Array,
        hardTotal: Number,
        bottomLeftLabel: String,
        bottomRightLabel: String
    },

    computed: {
        total() {
            return this.hardTotal || this.values.reduce((a, b) => a + b)
        },

        widths() {
            return this.values.map(x => (100 * x) / this.total)
        },

        pWidths() {
            return this.widths.map(x => `${x}%`)
        },

        lefts() {
            return this.values.map((x, idx) => {
                return idx === 0
                    ? 0
                    : this.widths.slice(0, idx).reduce((a, b) => a + b)
            })
        },

        pLefts() {
            return this.lefts.map(x => `${x}%`)
        },

        colors() {
            return this.bands.map(x => Colors.getColor(x.color))
        },

        hasLabels() {
            return (this.labels && this.labels.length > 0)
        },

        hasBottomLabels() {
            return (this.bottomLeftLabel || this.bottomRightLabel)
        }
    }
}
</script>

<style type="scss" scoped>
.performance-band-graph {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: 9px;
    background: #E0E1E6;
    border-radius: 5px;
    overflow: hidden;
}

.graph-band {
    position: absolute;
    margin: 0;
    padding: 0;
    height: 9px;
    top: 0;
}

.performance-band-labels {
    position: relative;
    margin: 8px 0;
    padding: 0;
    display: block;
    width: 100%;
    height: 12px;
}

.graph-label {
    position: absolute;
    margin: 0;
    padding: 0;
    height: 12px;
    top: 0;
    text-align: center;
    font-size: 11px;
    line-height: 12px;
    opacity: 0.5;
}

.bottom-left-label,
.bottom-right-label {
    position: absolute;
    width: 49%;
    margin: 0;
    padding: 0;
    height: 12px;
    top: 0;
    font-size: 11px;
    line-height: 12px;
    opacity: 0.5;
}

.bottom-left-label {
    text-align: left;
    left: 0;
}

.bottom-right-label {
    text-align: right;
    right: 0;
}
</style>
