<template>
    <record-dialog
        :title="title"
        :preTitle="!clone"
        width="460px"
        :verifyDirty="!(clone && clone.match(/cloud/))"
        v-bind="$attrs"
        :defaultRec="{
            name: null,
            description: null,
            intervention_form: false,
        }"
        @create="createRec"
        @update="$emit('update',$event)"
        @close="$emit('close')"
    >
        <template #form="{rec}">
            <fe-label style="width:76px;">Name</fe-label>
            <v-text-field flat solo dense autofocus
                v-model="rec.name"
                :rules="$fieldValidators('text', null, {required:true, limit:50}).concat(
                    v => v && !templates.some(x => x.name==v ) || 'Name already used'
                )"
            />
            <fe-label style="width:76px;">Description</fe-label>
            <v-textarea flat solo dense
                v-model="rec.description"
                height="64"
                :counter="255"
                :rules="$fieldValidators('text', null, {required:true, limit:255})"
            />
            <div v-if="!clone" class="d-flex">
                <fe-label style="width:100px;">Intervention Referral Form</fe-label>
                <fe-switch style="margin-left:auto;" v-model="rec.intervention_form" />
            </div>
            <div v-if="canCreateLiveTemplate && (!updating || duplicating)" class="d-flex">
                <fe-label style="width:100px;">Live Template</fe-label>
                <fe-switch style="margin-left:auto;" v-model="rec.live" />
            </div>
            <fe-crud ref="crud" :config="$models.form" @read="templates=$event" :readParams="{ignore_permissions:1}" :autoload="true" />
        </template>

    </record-dialog>
</template>

<script>
import { mapState } from 'vuex'
import RecordDialog from '@/components/common/form/RecordDialog'

export default {
    name: 'AddTemplate',
    
    components: {
        RecordDialog
    },
    
    props: {
        create: {type:Boolean, default:false},
        clone: {type:String, default:null},
    },
    
    computed: {
        ...mapState('global',['sessionUser']),
        liveTemplatesEnabled: me => me.$store.getters['flags/flags']['ec-live-template'] === true,
        canCreateLiveTemplate() {
            return this.liveTemplatesEnabled && this.clone != 'to_the_cloud'
        },
        title() {
            if(this.clone == 'local') return 'Copy Template'
            if(this.clone == 'cloud') return 'Download Template from climberCLOUD'
            if(this.clone == 'to_the_cloud') return 'Copy Template to climberCLOUD'
            return 'Template'
        },
        updating() {
            return this.$children[0].updating
        },
        duplicating() {
            return this.clone === 'local'
        },
    },

    methods: {
        createRec(rec) {
            if(this.create) {
                this.$refs.crud.create(rec).then( r => {
                    this.$emit('created',rec)
                    this.$emit('close')
                })
            } else {
                this.$emit('create',rec)
            }
        }
    },

    data() {
        return {
            templates: [],
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
