<template>
    <div class="flex-fill flex-row" @click="clearSelected" style="overflow-x: hidden; border-top: 1px solid lightgray;">

        <div class="flex-fill flex-column" style="background-color:white; overflow: hidden;"
            @dragover.prevent="onDragOver"
        >
            <v-overlay :value="overlay" absolute opacity=".1" z-index="101" style="top:50px;">
                <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
            </v-overlay>

        <!-- @drop.stop="onDrop" -->
            <div v-if="!cloud" class="d-flex pr-3">
                <div class="my-auto px-4 text--disabled font-weight-black">
                    {{template.live ? 'Live Template' : 'Versioned Template'}}
                </div>
                <fe-btn class="ml-auto" usage="tertiary" @click.stop="addSection">Add Section</fe-btn>
                <menu-btn :small="false">
                    <v-list-item @click="dependencyGridFilter=null;showGrid=true">Edit Dependencies</v-list-item>
                    <v-list-item @click="showSubscribers=true">Edit Subscribers</v-list-item>
                    <v-list-item @click="showAccessGrid=true" :disabled="!canManageAccess" >Manage Edit Access</v-list-item>
                    <v-list-item v-if="!template.live" @click="showDataEditor=true">Form Revision History</v-list-item>
                    <v-list-item v-if="(template.requires_student || hasStudentField) && formInstanceNamesEnabled" @click="showEditName=true">
                        Edit Form Name and Download File Name
                    </v-list-item>
                </menu-btn>
            </div>

            <div v-if="$_.isBlank(sections) && hasLoaded" class="d-flex flex-column flex-fill align-center justify-center">
                <div class="empty-state">
                    <span class="fa-stack fa-5x">
                      <i class="far fa-clipboard fa-stack-2x"></i>
                      <i class="fas fa-sparkles fa-stack-1x" style="transform: translateY(16px)"></i>
                    </span>
                </div>
                <div class="text-center mt-2">
                    smartFORM Templates allow all users to create and enter data into their copy of the form.
                    Templates contain Sections and Fields.<br>
                    Conditional Dependencies and Action Items can be added to the Template.
                    To get started, click on Add Section.
                </div>
            </div>

            <div v-else class="sf-body d-flex flex-wrap align-start" style="overflow: scroll;" @scroll="focusedField=null">
                <template v-for="(section,i) in sections">
                    <div v-if="section.id=='droptarget'"
                        :key="'droptarget'"
                        :style="{width:section.width, height:section.height}"
                        style="background-color: red;"
                    >
                    </div>

                    <form-section v-else-if="editing || !hideSections[section.id]"
                        :key="section.id"
                        :config="section"
                        @configChange="updateRec({newRec:$event, oldRec:section})"
                        :ref="'section_'+section.id"
                        :editor="editing"
                        :style="{width: getSectionWidth(section)}"
                        :isDisabled="sectionDisabled(section) && !editing"
                        @editor:click="selectObject"
                        @dropped="onDropped"
                        @delete="deleteSection"
                        @duplicate="duplicateSection"
                        @addField="addField"
                        @editDependency="editDependency"
                    >
                        <div class="d-flex flex-wrap">
                            <v-col v-for="field in section.form_fields"
                                :cols="getFieldWidth(section, field)"
                                :key="`ffr-`+field.id"
                                class="pa-0"
                            >
                                <form-field v-if="editing || !hideFields[field.id]"
                                    :data="field"
                                    :liveForm="!!template.live"
                                    @configChange="updateRec({newRec:$event, oldRec:field})"
                                    :ref="`field_` + field.id"
                                    :editor="editing"
                                    :class="[{'disabled-field': !editing && disableFields[field.id]}]"
                                    :disabled="!editing && disableFields[field.id]"
                                    @editor:click="selectObject"
                                    @dropped="onDropped"
                                    @deleteField="deleteField"
                                    @duplicateField="duplicateField"
                                    @addDependency="addDependency"
                                    @editDependency="editDependency"
                                    @addActionItem="addActionItem"
                                    @editActionItem="editActionItem"
                                    @deleteActionItem="deleteActionItem"
                                    @checkRules="updateDependencies"
                                    :duplicateFormDataIds="duplicateFormDataIds"
                                    @goToAdvanced="setGoToAdvanced(true)"
                                />
                            </v-col>
                        </div>
                    </form-section>
                </template>
            </div>
            <!-- {{logfield}} -->
            <!-- <dependency-panel class="mt-auto" v-model="currentConfig"/> -->
            <!-- <dependency-grid-old v-model="showGrid" :sections="sections" :allFields="allFields" :templateId="template.id" /> -->
            <dependency-grid
                v-model="showGrid"
                :sections="sections"
                :allFields="allFields"
                :templateId="template.id"
                :filter="dependencyGridFilter"
            />
            <dependency-editor v-if="showDependencyEditor"
                :value="currentDependency"
                :sections="sections"
                :allFields="allFields"
                :templateId="template.id"
                @close="showDependencyEditor=false"
            />
            <access-grid
                v-model="showAccessGrid"
                :templateId="template.id"
                @input="$emit('reload')"
            />
            <data-editor
                v-model="showDataEditor"
                :template="template"
            />

            <subscribers v-model="showSubscribers" :templateId="template.id" />

            <edit-action-item
                v-if="showActionItems"
                v-bind="actionItemParams"
                standalone
                @dismiss="showActionItems = false"
                @save="saveFormTask"
            >
                <v-row no-gutters>
                    <v-col class="mr-4">
                        <fe-label>Auto Assign by Tag</fe-label>
                        <fe-remote-combo
                            v-model="formTask.tag_id"
                            byId
                            rootProperty="tags"
                            url="tags.php?action=get&property=available_user_tags"
                        >
                            <template #selection="{item}">
                                <v-icon :color="item.color" size="12" class="mr-2">fa-tag</v-icon>
                                <v-icon v-if="item.public_flag" size="12" color="rgba(0,0,0,.54)" class="mr-2">fa-globe-americas</v-icon>
                                {{item.name}}
                            </template>
                            <template #item="{item}">
                                <v-icon size="12" :color="item.color" class="mr-2">fa-tag</v-icon>
                                <v-icon v-if="item.public_flag" size="12" color="rgba(0,0,0,.54)" class="mr-2">fa-globe-americas</v-icon>
                                <v-list-item-title v-html="item.name"/>
                            </template>
                        </fe-remote-combo>
                    </v-col>
                    <v-col>
                        <fe-label>Due # Days from Form Creation</fe-label>
                        <v-text-field
                            v-model="formTask.num_days_due"
                            type="number"
                            flat solo dense
                            clearable
                        />
                    </v-col>
                </v-row>
            </edit-action-item>
        </div>

        <config-panel
            :value="currentConfig"
            :section="currentSection"
            :template="template"
            @input="updateRec({newRec:$event, oldRec:currentConfig})"
            @scrollElement="scrollElement"
            @editDependency="editDependency"
            @dataIdChange="dataIdChange"
            :duplicateFormDataIds="duplicateFormDataIds"
            :goToAdvanced="goToAdvanced"
            @resetAdvancedTab="setGoToAdvanced(false)"
        />

        <fe-crud ref="crudSection" :config="$models.formSection" />
        <fe-crud ref="crudField" :config="$models.formField" />
        <fe-crud ref="crudFormTask" :config="$models.formTask" />

        <div v-if="showSnackbar" class="choose-alert" >
            <v-alert dark color="#333333" icon="fal fa-mouse-pointer" :elevation="6">
                <div class="d-flex flex-column" style="width:210px;">
                    Choose a Field or Section
                    <v-btn class="ml-auto" dark text @click.stop="beforeSelectObject=null; showSnackbar=false">Cancel</v-btn>
                </div>
            </v-alert>
        </div>

        <fe-dialog
            v-if="showEditName"
            persistent
            title="Edit File Download Name"
            width="640"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            @close="showEditName=false"
            @dismiss="showEditName=false"
            @accept="onFileNameSave"
            :acceptButtonDisabled="noStudentData"
        >
            <div >
                Use the fields below to build a form name and file download name for instances of this template.
                The preview is an example of the layout with sample data.
            </div>
            <div class="mt-2">
                Please note that saving this configuration will automatically apply the name configuration to new instances
                of this template.
            </div>
            <div class="mt-3 mb-3 d-flex">
                <div class="d-flex">
                    <draggable v-model="selectedFields" @change="onDragChange">
                        <transition-group>
                            <span v-for="(field, i) in selectedFields" :key="i">
                                <v-chip
                                    v-if="field.element !== 'Description'"
                                    close
                                    text-color="#050F2D"
                                    outlined
                                    class="smartform-template-selected-chips"
                                    @click:close="updateFields(field, 'chip')"
                                >
                                    {{ field.element }}
                                </v-chip>
                                <v-text-field
                                    v-else
                                    v-model="field.value"
                                    :placeholder="field.element"
                                    flat solo
                                    hide-details
                                    append-icon="mdi-close-circle"
                                    height="36"
                                    class="smartform-template-selected-text-field ma-1"
                                    @click:append="updateFields(field, 'chip')"
                                ></v-text-field>
                            </span>
                        </transition-group>
                    </draggable>
                </div>
                <v-menu v-model="openAvailableMenu" nudge-right="65">
                    <template v-slot:activator="{ on }">
                        <fe-tooltip top tooltip="Select fields in the dropdown to add to the form name.">
                            <fe-icon-btn
                                v-on="on"
                                useIcon="far fa-plus"
                                class="smartform-template-plus-button"
                            ></fe-icon-btn>
                        </fe-tooltip>
                    </template>
                    <v-list v-for="item in availableFields" class="smartform-template-available-list">
                        <v-list-item @click="updateFields(item, 'list')">{{ item.element }}</v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div class="mt-3">
                Preview: {{ preview }}
            </div>
            <v-divider class="mt-3" />
            <div class="mt-3 d-flex">
                <span>Use this name as form names: </span>
                <v-spacer />
                <fe-switch v-model="useFormTitle" hide-details :disabled="disableSwitch"></fe-switch>
            </div>
            <div v-if="useFormTitle">
                This will update the title on <span style="font-weight: bold">{{ instanceCount }}</span> existing form instances.
            </div>
            <div class="mt-4" v-if="noStudentData" style="color: #930A00">
                One unique identifier smartFIELD like student name or ID must be added.
            </div>
        </fe-dialog>

        <fe-crud ref="formNameCrud" :config="formNameCrudConfig" />
    </div>
</template>

<script>
import Vuex from 'vuex'
import { mapLocalState } from '@/util/vuexHelper'
import MenuBtn from '@/components/common/button/MenuBtn'
import smartFormBaseMixin from '../smartFormBaseMixin'
import smartFormMixin from '../smartFormMixin'
import FormSection from '../components/FormSection'
import FormField from '../components/FormField'
import ConfigPanel from './configpanel'
import DependencyPanel from './DependencyPanel'
import DependencyGrid from './DependencyGrid'
import Subscribers from './Subscribers'
import AccessGrid from './AccessGrid'
import DependencyEditor from './DependencyEditor'
import DataEditor from './DataEditor'
import EditActionItem from "../../actionItems/EditActionItem"
import draggable from 'vuedraggable'


export default {
    name: 'SmartFormTemplate',
    mixins: [ smartFormBaseMixin, smartFormMixin ],
    components: {
        MenuBtn,
        FormSection,
        FormField,
        ConfigPanel,
        DependencyPanel,
        DependencyGrid,
        Subscribers,
        AccessGrid,
        DependencyEditor,
        DataEditor,
        EditActionItem,
        draggable,
    },
    props: [ 'template', 'cloud' ],
    computed: {
        sessionUser: me => me.$store.state.global.sessionUser.user,
        overlay: me => me.isLoading,
        canManageAccess() {
            if(this.usersWithAccess.length) {
                let user = this.usersWithAccess.find(x => this.sessionUser.id == x.id)
                return user.owner || user.admin
            } else {
                return null
            }
        },
        noStudentData() {
            // check if any student fields are chosen, otherwise don't allow saving
            return !this.selectedFields.find(x => x.element === 'Student' || x.element === 'Student State ID' || x.element === 'Student District ID')
        },
        formNameCrudConfig() {
            return this.$_.cloneDeep(this.$models.formNameConfig)
        },
        disableSwitch() {
            return Boolean(this.existingCustomFormName && this.useFormTitle) //&& !this.changeMade
        },
        formInstanceNamesEnabled() {
            return this.$store.getters['flags/flags']['ec-form-name-config'] === true
        },
    },
    data() {
        return {
            logfield: null,
            hasLoaded: false,
            editing: true,
            sections: [],
            currentConfig: null,
            currentSection: null, // this is always set on click and is just used to provide some info to the field config panels
            showGrid: false,
            showSubscribers: false,
            showDependencyEditor: false,
            showDataEditor: false,
            showAccessGrid: false,
            showSnackbar: false,
            showActionItems: false,
            actionItemParams: null,
            currentDependency: null,
            dependencyGridFilter: null,
            formTask: {},
            duplicateFormDataIds: [],
            goToAdvanced: false,
            showEditName: false,
            openAvailableMenu: false,
            selectedFields: [
                { id: 9, element: 'School Year', value: '2020-2021' },
                { id: 1, element: 'Template', value: 'Template' },
                { id: 5, element: 'Student State ID', value: '0000' },
                { id: 4, element: 'Student', value: 'JaneDoe' },
            ],
            availableFields: [
                // { id: 1, name: 'Form Name', value: 'Form' }, // removing for now since this updates form name
                { id: 2, element: 'Created', value: '2020-01-0112:00:00' },
                { id: 3, element: 'Created By User', value: 'JohnSmith' },
                { id: 6, element: 'Student District ID', value: '1000' },
                { id: 7, element: 'School', value: 'ElementarySchool' },
                { id: 8, element: 'Grade', value: '8' },
                { id: 10, element: 'Description', value: '' }
            ],
            preview: '',
            fileNameData: [],
            useFormTitle: false,
            instanceCount: 0,
            existingCustomFormName: false,
            origSelectedFields: [],
            changeMade: false,
            newSelectedFields: [],
            reordered: false,
            removed: [],
            hasStudentField: false,
            usersWithAccess: []
        }
    },
    mounted() {
        this.loadForm()
        this.editbar = this.$parent.$parent.$children.find(x => x.name == 'EditPreviewBtn' )
        if(this.editbar) {
            this.editbar.callback = v => {
                this.editing = v
            }
        } else {
            this.editing = false
        }
        this.reloadFn = this.reload

        this.selectedFields.forEach(field => {
            this.preview += field.value
        })

        this.$axios('form.php?action=get&property=name_config_status&form_id=' + this.template.id)
            .then(resp => {
                this.useFormTitle = resp.data.status ? resp.data.status : false
            })
        this.$axios.get('form.php?property=access_users&action=get&form_id=' + this.template.id)
            .then(resp => {
                this.usersWithAccess = resp.data.users_with_access
            })
    },
    updated() {

    },
    watch: {
        selectedFields: {
            deep: true,
            handler(v) {
                this.preview = ''
                v.forEach(field => {
                    this.preview += field.value
                })
                if(v.length !== this.origSelectedFields.length) {
                    // this.changeMade = true
                    let arr1 = v.filter(({ element: id1 }) => !this.origSelectedFields.some(({ element: id2 }) => id2 === id1))
                    let arr2 = this.origSelectedFields.filter(({ element: id1 }) => !v.some(({ element: id2 }) => id2 === id1))
                    this.newSelectedFields = arr1
                    this.removed = arr2
                }
                else {
                    let arr = v.filter(({ element: id1 }) => !this.origSelectedFields.some(({ element: id2 }) => id2 === id1))
                    // if(arr.length) this.changeMade = true
                    this.newSelectedFields = arr
                }
            }
        },
        showEditName(v) {
            if(v) {
                this.$refs.formNameCrud.read({ form_id: this.template.id })
                    .then(resp => {
                        if(resp.data?.form_name_config?.length) {
                            this.existingCustomFormName = true
                            this.origSelectedFields = this.$_.cloneDeep(resp.data.form_name_config)
                            resp.data.form_name_config.forEach(field => {
                                if(this.selectedFields.find(x => x.element === field.element)) {
                                    this.selectedFields.find(x => x.element === field.element).id = field.id
                                } else if(this.availableFields.find(x => x.element === field.element)) {
                                    // remove from available and push to selected
                                    let element = this.availableFields.find(x => x.element === field.element)
                                    if(field.element === 'Description') {
                                        element.value = field.description
                                        delete element['description']
                                    }
                                    element.id = field.id
                                    this.selectedFields.push(element)
                                    this.availableFields = this.availableFields.filter(x => x.element !== field.element)
                                }
                            })
                            // remove any fields that are in the loaded selected fields that aren't in the config
                            // add to available fields
                            let arr = this.selectedFields.filter(({ element: id1 }) => !resp.data.form_name_config.some(({ element: id2 }) => id2 === id1))
                            if(arr.length) {
                                arr.forEach(a => {
                                    this.selectedFields = this.selectedFields.filter(x => x.element != a.element)
                                    this.availableFields.push(a)
                                })
                            }
                            // sort based onn rank returned from crud
                            this.selectedFields.sort((a,b) => {
                                let aRank = resp.data.form_name_config.find(x => x.element === a.element) ? resp.data.form_name_config.find(x => x.element === a.element).rank : -1
                                let bRank = resp.data.form_name_config.find(y => y.element === b.element) ? resp.data.form_name_config.find(y => y.element === b.element).rank : -1
                                return aRank - bRank
                            })
                        }
                    })
                this.$axios.get('form.php?action=get&property=instance_count&form_id=' + this.template.id)
                    .then(resp => {
                        this.instanceCount = resp.data.count
                    })
            }
        },
        // removing changeMade for now until we receive feedback on new feature
        // changeMade(v) {
        //     if(v) {
        //         this.useFormTitle = false
        //     }
        // },
        reordered(v) {
            if(v) {
                // this.changeMade = true
            }
        }
    },
    methods: {
        reload({fieldId, sectionId} = {}) {
            // this will always clear the selected element
            this.loadForm().then( () => {
                this.clearSelected()
                if(fieldId) {
                    this.selectFieldById(fieldId)
                } else if(sectionId) {
                    this.selectSectionById(sectionId)
                }
            })
        },
        loadData() {
            return this.$refs.crudSection.read({
                form_id: this.template.id,
                cloud: this.cloud ? 1 : undefined
            }).then( r => {
                this.sections = r.data.sections
                this.hasLoaded = true
                this.setDataIds(r.data)
                this.checkForStudentFields()
                return r
            }).catch(r => r)
        },
        onDragOver(e) {

        },
        onDropped({target,source}) {
            this.sections = this.sections.filter(x => x.id != 'droptarget')

            let targetSection = this.sections.find(x => this.isField(target) ? x.id == target.form_section_id : x.id == target.id)

            if(this.isField(source)) {
                let targetIndex = targetSection.form_fields.findIndex(x => x.id == target.id)
                if(targetIndex < 0) targetIndex = 0
                // remove field
                let sourceSection = this.sections.find(x => x.id == source.form_section_id)
                sourceSection.form_fields = sourceSection.form_fields.filter(x => x.id != source.id)
                // add field
                targetSection.form_fields.splice(targetIndex,0,source)
                targetSection.form_fields.forEach((x, i) => {x.rank = i; x.form_section_id = targetSection.id})

                // this.overlay = true
                this.$refs.crudField.update(targetSection.form_fields).then(r => {
                    this.loadForm().then(() => {
                        this.selectNewCurrent(r.data.changed_ids)
                    }).catch(() => this.loadForm())
                })

            } else {
                let targetIndex = this.sections.findIndex(x => x.id == target.id)
                if(targetIndex < 0) targetIndex = 0

                // remove section
                this.sections = this.sections.filter(x => x.id != source.id)
                // add section
                this.sections.splice(targetIndex,0,source)
                this.sections.forEach((x,i) => x.rank = ++i)

                // this.overlay = true
                this.$refs.crudSection.update(this.sections).then(r => {
                    this.loadForm().then(() => {
                        this.selectNewCurrent(r.data.changed_ids)
                    })
                }).catch(() => this.loadForm())
            }
        },
        scrollElement({fieldId,sectionId}) {
            let el = fieldId ? this.$refs['field_'+fieldId][0].$el : this.$refs['section_'+sectionId][0].$el
            el.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            })
            el.classList.add('editor-outline')
            setTimeout(() => {
                if(el?.classList) el.classList.remove('editor-outline')
            },4000)
        },
        selectNewCurrent(changed_ids) {
            if(this.currentConfig) {
                if(this.isField(this.currentConfig)) {
                    let id = changed_ids.fields?.[this.currentConfig.id] || this.currentConfig.id
                    this.$nextTick( () => this.selectFieldById(id) )

                } else {
                    let id = changed_ids.sections?.[this.currentConfig.id] || this.currentConfig.id
                    this.$nextTick( () => this.selectSectionById(id) )
                }
            }
        },
        clearSelected() {
            for(let [ref,a] of Object.entries(this.$refs)) {
                if(ref.startsWith('section_') || ref.startsWith('field_')) {
                    if(a.length) a[0].isSelected = false
                }
            }
            this.selectedComponent = null
            this.currentConfig = null
            this.currentSection = null
        },
        selectSectionById(id) { this.selectObject( this.$refs['section_'+id][0] ) },
        selectFieldById(id) { this.selectObject( this.$refs['field_'+id][0] ) },
        selectObject(clickedObj) {
            if(this.beforeSelectObject) {
                if(!this.beforeSelectObject(clickedObj)) return
            }

            this.clearSelected()
            clickedObj.isSelected = true
            this.selectedComponent = clickedObj
            this.currentConfig = clickedObj.data || clickedObj.config
            this.currentSection = this.isField(this.currentConfig)
                ? this.$refs['section_'+this.currentConfig.form_section_id][0].config
                : this.currentConfig
        },
        getFieldWidth(section, field) {
            let fullWidth = ['interventionblock']

            if (fullWidth.includes(field.xtype)) return 12
            if (this.sectionLayoutTypes[section.section_layout_type_id].name === 'form') return 12

            let width = parseFloat(field.column_width)
            if (field.xtype == 'scorefield' && !width) return 12

            return Math.ceil(12 * width);
        },
        addSection() {
            // this.overlay = true
            this.$refs.crudSection.create({
                form_id: this.template.id,
                name: `Section ${this.sections.length+1}`,
                hide_label: 0,
                section_layout_type_id: 2,
                width: 0.99,
                border: 0,
                rank: this.calculateRank(this.sections, this.currentSection)
            }).finally(() => {
                this.loadForm().then(r => {
                    this.$nextTick(() => {
                        let newId = r.data.sections.reduce((max,x) => x.id > max ? x.id : max, 0) // find highest id
                        this.selectSectionById( newId )
                    })
                })
            })
        },
        calculateRank(collection, afterObj) {
            let rank
            let i = collection.indexOf(afterObj)
            if(i >= 0 && collection[i+1]) {
                rank =  (Number(afterObj.rank) + Number(collection[i+1].rank)) / 2
            } else {
                rank = collection.reduce((max,x) => Number(x.rank) > max ? Number(x.rank) : max, 0) + 1
            }

            return rank
        },
        updateRec({newRec,oldRec}) {
            Object.assign(oldRec,newRec) // merge newRec
            if(this.isField(oldRec)) {
                if(this.allFields[oldRec.id]) {
                    // this.overlay = true
                    this.$refs.crudField.update(oldRec).then(r => {
                        this.loadForm().then(() => {
                            this.selectNewCurrent(r.data.changed_ids)
                        })
                    }).catch(() => this.loadForm())
                } else {
                    console.error('attempted to update field with wrong id');
                }

            } else {
                if(this.sections.find(x => oldRec.id==x.id)) {
                    // this.overlay = true
                    this.$refs.crudSection.update(oldRec).then(r => {
                        this.loadForm().then(() => {
                            this.selectNewCurrent(r.data.changed_ids)
                        })
                    }).catch(() => this.loadForm())
                } else {
                    console.error('attempted to update section with wrong id');
                }
            }
        },
        deleteSection(rec) {
            this.$confirmDelete(rec,() => {
                // this.overlay = true
                this.$refs.crudSection.destroy(rec).finally(() => {
                    this.clearSelected()
                    this.loadForm()
                })
            })
        },
        deleteField(rec) {
            this.$confirmDelete(rec,() => {
                // this.overlay = true
                this.$refs.crudField.destroy(rec).finally(() => {
                    this.clearSelected()
                    this.loadForm()
                })
            })
        },
        duplicateSection(rec) {
            this.$messageBox({
                title: 'Confirm Duplication',
                persistent: true,
                message: 'Are you sure you want to duplicate this section?',
                maxWidth: '500',
                actions: [{
                    text: 'Cancel',
                    usage: "ghost"
                }, {
                    text: 'Duplicate',
                    usage: 'danger',
                    onClick: () => {
                        this.$axios.post('form.php?action=copy&property=section', rec).finally(() => {
                            this.clearSelected()
                            this.loadForm()
                        })
                    }
                }]
            })
        },
        duplicateField({...rec}) {
            let section = this.sections.find(x => x.id == rec.form_section_id)
            let selectedfield = this.currentConfig && this.isField(this.currentConfig) ? this.currentConfig : null
            rec.rank = this.calculateRank(section.form_fields,selectedfield)

            rec.data_id = null
            this.$axios.post('form.php?action=copy&property=field', rec).then(r => {
                if(r.data.success) {
                    let field = r.data.form_fields[0]
                    this.loadForm().then(() => {
                        this.$nextTick(() => {
                            this.selectFieldById( field.id )
                            this.duplicateFieldConfig(rec)
                        })
                    })
                }
            }).catch(() => this.loadForm())
        },
        duplicateFieldConfig(fromRec) {
            if(fromRec.xtype == 'scorefield') {
                this.currentConfig.duplicateConfigFrom = fromRec
            }
        },
        addField({fieldType,section}) {
            // let rank = section.form_fields.length && section.form_fields[section.form_fields.length-1].rank+1
            // TODO add field under current selected.
            // let rank = section.form_fields.length+1
            // let rank = section.form_fields.reduce((max,x) => x.rank > max ? x.rank : max, 0) + 1
            let selectedfield = this.currentConfig && this.isField(this.currentConfig) ? this.currentConfig : null
            let rank = this.calculateRank(section.form_fields,selectedfield)

            // this.overlay = true
            this.$refs.crudField.create({
                form_section_id: section.id,
                name: fieldType.name,
                display_value: '',
                label_align: 'top',
                value_align: '',
                min_value: null,
                max_value: null,
                hide_name: false,
                column_width: 1,
                rowspan: null,
                colspan: null,
                tooltip: null,
                height: 0,
                data_id: null,
                description: '',
                rank: rank,
                sub_category_id: null,
                form_field_type_id: fieldType.id,
                tag_id: null,
                property: null,
                carry_over: null,
            }).then(r => {
                if(r.data.success) {
                    let field = r.data.form_fields[0]
                    this.loadForm().then(() => {
                        this.$nextTick(() => {
                            this.selectFieldById( field.id )
                        })
                    })
                }
            }).catch(() => this.loadForm())

        },
        addDependency(controller) {
            this.showSnackbar = true

            this.beforeSelectObject = clickedObj => {
                this.currentDependency = {
                    form_id: this.template.id,
                    statement_form_logic_id: 2,
                    controlling_form_field_id: controller.id,
                    form_field_id: clickedObj.data && clickedObj.data.id,
                    form_section_id: clickedObj.config && clickedObj.config.id,
                    cond_form_logic_id: null,
                    form_logic_value: null,
                    action_form_logic_id: null,
                }
                this.showSnackbar = false
                this.showDependencyEditor = true
                this.beforeSelectObject = null
                return false // cancel selection
            }

        },
        editDependency({id, rule}) {
            if(rule) {
                this.currentDependency = rule
                this.showDependencyEditor = true
            } else {
                this.dependencyGridFilter = x => {
                    return x.controlling_form_field_id == id || x.form_field_id == id || x.form_section_id == id
                }
                // if(controller) this.dependencyGridFilter = x => x.controlling_form_field_id == controller
                // else if(field) this.dependencyGridFilter = x => x.form_field_id == field
                // else if(section) this.dependencyGridFilter = x => x.form_section_id == section
                this.showGrid = true
            }

        },
        deleteActionItem(task) {
            this.$confirmDelete(task,() => {
                this.$refs.crudFormTask.destroy(task).then(this.loadForm)
            },null,`Are you sure you want to delete action item "${task.name}"?`)
        },
        editActionItem(task) {
            this.formTask = task
            this.actionItemParams = {
                editing: {id: task.task_id},
                extraParams: { template_flag: 1 }
            }
            this.showActionItems = true
        },
        addActionItem({id}) {
            this.formTask = {
                form_field_id: id,
            }
            this.actionItemParams = {
                extraParams: {
                    template_flag: 1,
                }
            }
            this.showActionItems = true
        },
        saveFormTask(task) {
            let op = this.formTask.id ? 'update' : 'create'
            this.$refs.crudFormTask[op]({
                id: this.formTask.id,
                task_id: task.id,
                form_field_id: this.formTask.form_field_id,
                tag_id: this.formTask.tag_id,
                num_days_due: this.formTask.num_days_due,
            }).finally(this.loadForm)
        },
        setDataIds(data) {
            // find duplicate data ids across the form
            let formDataIds = []
            this.duplicateFormDataIds = []
            data.sections.forEach(section => {
                section.form_fields.forEach(field => {
                    if(field.data_id) formDataIds.push(field.data_id)
                })
            })
            this.duplicateFormDataIds = formDataIds.filter((item, index) => formDataIds.indexOf(item) !== index)
        },
        dataIdChange(v) {
            // reload data to grab new set of data ids and see if there's now duplicates
            this.loadData()
        },
        setGoToAdvanced(v) {
            this.goToAdvanced = v
        },
        updateFields(v, type) {
            if(type === 'chip') {
                this.selectedFields = this.selectedFields.filter(x => x.element !== v.element)
                this.availableFields.push(v)
            } else {
                this.availableFields = this.availableFields.filter(x => x.element !== v.element)
                this.selectedFields.push(v)
            }
        },
        async onFileNameSave() {
            this.showEditName=false
            let savedFields = this.$_.cloneDeep(this.selectedFields)
            let newFields = this.$_.cloneDeep(this.newSelectedFields)
            Promise.all(
                savedFields.map((field, index) => {
                    field.rank = newFields.length ? this.selectedFields.findIndex(x => x.element === field.element) : index
                    field.form_id = this.template.id
                    if(field.element === 'Description') {
                        field.description = field.value
                    }
                    // let BE auto-increment if newly created name OR newly added field to existing name
                    if(!this.existingCustomFormName || (newFields.length && newFields.find(x => x.element == field.element))) delete field['id']
                    // always delete value (only needed for FE preview)
                    delete field['value']
                }))
                .then(() => {
                    let transactions = []
                    if (this.removed.length) {
                        transactions.push([
                            this.$refs.formNameCrud.destroy(this.removed)
                                .then(resp => {
                                    if (resp.data.success && this.useFormTitle) {
                                        this.onUseFormTitle()
                                    }
                                    this.removed = []
                                })
                        ])
                    }
                    if(!this.existingCustomFormName || newFields.length) {
                        let arr1 = savedFields.filter(({ element: id1 }) => !this.origSelectedFields.some(({ element: id2 }) => id2 === id1))
                        transactions.push([
                            this.savedCreatedResponse = this.$refs.formNameCrud.create(arr1)
                                .then(resp => {
                                    return resp.data.form_name_config
                                })
                        ])
                    }
                    Promise.all(transactions)
                        .then(() => {
                            let s = []
                            // if new records
                            // or if moved records
                            if (this.savedCreatedResponse) {
                                this.savedCreatedResponse
                                    .then((resp) => {
                                        if (resp) s = resp
                                    })
                                    .finally(() => {
                                        if(s.length) {
                                            savedFields.forEach(field => {
                                                if(s.find(x => x.element == field.element)) {
                                                    field.id = s.find(x => x.element == field.element).id
                                                }
                                            })
                                        }
                                        if (this.existingCustomFormName) {
                                            this.$refs.formNameCrud.update(savedFields)
                                                .then(resp => {
                                                    if (resp.data.success && this.useFormTitle) {
                                                        this.onUseFormTitle()
                                                    }
                                                    this.reordered = []
                                                })
                                        } else {
                                            this.onUseFormTitle()
                                        }
                                    })
                            } else if (this.existingCustomFormName && this.reordered) {
                                this.$refs.formNameCrud.update(savedFields)
                                    .then(resp => {
                                        if (resp.data.success && this.useFormTitle) {
                                            this.onUseFormTitle()
                                        }
                                        this.reordered = []
                                    })
                            }
                        })
                })
        },
        onUseFormTitle() {
            this.$axios.post('form.php?action=update&property=name_config_status', { form_id:this.template.id, use_name_config:1 })
                .then(resp => {
                    if(resp.data.success) {
                        this.$axios.post('form.php?action=update&property=apply_name_template&form_id=' + this.template.id)
                            .then(resp => {
                                if(resp.data.success) {
                                    // this.$snackbars.$emit('new', {text: resp.data.msg, usage: 'success'})
                                } else {
                                    this.$snackbars.$emit('new', { text: resp.data.msg, usage: 'warning' })
                                }
                            })
                    } else {
                        this.$snackbars.$emit('new', { text: resp.data.msg, usage: 'warning' })
                    }
                })
        },
        onDragChange(v) {
            // if moved, set reordered to true
            // if moved back, set reordered back to false
            let el = this.selectedFields.find(x => x.element === v.moved.element.element)
            this.reordered = v.moved.newIndex !== el.rank
        },
        checkForStudentFields() {
            this.hasStudentField = this.sections.some(section => section.form_fields.some(field => field.name.includes('Student')))
        },
    },
}
</script>

<style lang="scss" scoped>
.choose-alert {
    position: fixed;
    top: 10px;
    left: 50%;
    z-index: 100;
    transform: translateX(-50%);
}
.empty {
    ::v-deep &-editor {
        color: rgba(0,0,0,.25);
        font-weight: 900;
    }
}
.empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 340px;
    height: 340px;
    background-color: #ecedf1;
    border-radius: 100%;
}
.sf-toolbar {
    padding: 5px 5px 5px 20px;
}

.sf-body {
    background-color: white;
    padding: 0 8px 8px 8px;
}
.back {
    transform: translate(0,-35%);
}
.red {
    background-color: red;
}
.green {
    background-color: green;
}
.blue {
    background-color: blue;
}

.controller {
    border: 1px solid blue;
    border-radius: 4px;
}
.block {
    display: block;
    padding: 10px;
    margin: 8px;
    background-color: #557c83;
}
.content {
    width: 100%;
    height: 100%;
}

.disabled-field {
    opacity: .2;
    pointer-events: none;
}
.smartform-template-plus-button {
    ::v-deep .v-btn {
        background: #ECEDF1 !important;
        border-radius: 4px !important;
    }
    ::v-deep .v-btn--icon.v-size--default {
        width: 43px;
    }
}
.smartform-template-available-list {
    .v-list-item {
        min-height: 30px !important;
    }
}
.smartform-template-selected-chips.v-chip.v-size--default {
        height: 36px !important;
}
.smartform-template-selected-chips {
    ::v-deep .v-chip__content {
        font-size: 14px;
    }
}
.smartform-template-selected-text-field {
    max-width: 200px;
    display: inline-block !important;
    ::v-deep .v-input__slot {
        border-radius: 16px;
        font-size: 14px;
        border-color: rgba(0, 0, 0, 0.12);
    }
    ::v-deep .v-icon {
        font-size: 18px;
        color: #050F2D;
    }
    ::v-deep .v-input__control {
        height: 36px !important;
    }
}
.ghost-class {
    opacity: .5;
    background: #C8EBFB;
}
</style>

<style lang="scss">
.editor-outline {
    outline: 2px dashed orange;
    outline-offset: -3px;
}
</style>
