<template>
    <div class="ma-4">
        <div class="page-title mb-10">District Onboarding Sign Off Summary</div>

        <div class="ma-4">
            <div class="section-title">Student Imports</div>
            <fe-grid
                :rowData="summary.students"
                :columnDefs="studentColumns"
                displayDensity="small"
                style="height: 300px;"
                :showAddRowBtn="false"
                ref="grid"
                groupUseEntireRow
            >
            </fe-grid>

            <div class="d-flex my-5">
                <v-checkbox class="mt-3" label="I acknowledge on behalf of the school district that this represents our requested student data."/>
            </div>
        </div>

        <div class="ma-4">
            <div class="section-title">Student Tags</div>
            <fe-grid
                :rowData="summary.tags"
                :columnDefs="tagColumns"
                displayDensity="small"
                style="height: 300px;"
                :showAddRowBtn="false"
                ref="grid"
                groupUseEntireRow
            >
            </fe-grid>

            <div class="d-flex my-5">
                <v-checkbox class="mt-3" label="I acknowledge on behalf of the school district that this represents our requested student data."/>
            </div>
        </div>

        <div class="ma-4">
            <div class="section-title">Assessment Imports</div>
            <fe-grid
                :rowData="summary.assessments"
                :columnDefs="assessmentColumns"
                displayDensity="small"
                style="height: 300px;"
                :showAddRowBtn="false"
                ref="grid"
                groupUseEntireRow
            >
            </fe-grid>

            <div class="d-flex my-5">
                <v-checkbox class="mt-3" label="I acknowledge on behalf of the school district that this represents our requested assessment data."/>
            </div>
        </div>

        <div class="ma-4">
            <div class="section-title">Incident Imports</div>
            <fe-grid
                :rowData="summary.incidents"
                :columnDefs="incidentColumns"
                displayDensity="small"
                style="height: 300px;"
                :showAddRowBtn="false"
                ref="grid"
                groupUseEntireRow
            >
            </fe-grid>

            <div class="d-flex my-5">
                <v-checkbox class="mt-3" label="I acknowledge on behalf of the school district that this represents our requested assessment data."/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ImportSummary',
        data() {
            return {
                summary: {
                    assessments: [],
                    students: [],
                    incidents: []
                },
                studentColumns: [{
                   headerName: "Year",
                   field: "school_year_name",
                   width: 100,
                   rowGroup: true
                }, {
                   headerName: "School",
                   field: "school_name",
                   width: 100
                }, {
                   headerName: "Grade",
                   field: "grade_desc",
                   cellStyle: { textAlign: 'right' }
                }, {
                   headerName: "Imported Records",
                   field: "count",
                   flex: 1,
                   cellStyle: {
                       textAlign: 'right'
                   }
                }],
                tagColumns: [{
                   headerName: "Year",
                   field: "school_year_name",
                   width: 100,
                   rowGroup: true
                }, {
                   headerName: "Tag",
                   field: "tag_name"
                }, {
                   headerName: "Imported Records",
                   field: "count",
                   flex: 1,
                   cellStyle: {
                       textAlign: 'right'
                   }
                }],
                assessmentColumns: [{
                   headerName: "Year",
                   field: "school_year_name",
                   width: 100,
                   rowGroup: true
                }, {
                   headerName: "Assessment Group",
                   field: "data_point_type_name",
                   flex: 1
                }, {
                   headerName: "Window",
                   field: "data_point_name",
                   width: 100
                }, {
                   headerName: "Imported Records",
                   field: "count",
                   flex: 1,
                   cellStyle: {
                       textAlign: 'right'
                   }
                }],
                incidentColumns: [{
                   headerName: "Year",
                   field: "school_year_name",
                   width: 100,
                   rowGroup: true
                }, {
                   headerName: "Incident Type",
                   field: "incident_behavior_type_name",
                   flex: 1
                }, {
                   headerName: "Imported Records",
                   field: "count",
                   flex: 1,
                   cellStyle: {
                       textAlign: 'right'
                   }
                }]
            }
        },
        mounted () {
            this.loadData()
        },
        methods: {
            loadData() {
                this.$setLoading(true)
                this.$axios.get('districts.php?action=import_summary').then(r => {
                    this.summary = this.$ecResponse(r, 'summary')
                }).finally(() => { this.$setLoading(false) })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
