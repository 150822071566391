<template>
    <div v-if="ready" class="d-flex flex-wrap flex-shrink-1" style="overflow-y: auto; width: 100%">
        <gallery-card
            v-for="(dash, i) in dashboards"
            :key="`dash-${i}`"
            :dashboard="dash"
            @select="$emit('select', dash)"
        />
    </div>
</template>

<script>
import GalleryCard from "./GalleryCard"

export default {
    name: "GalleryView",

    components: {GalleryCard},

    props: {
        dashboards: {
            type: Array,
            default: []
        }
    },

    data() {
        return {
            ready: false
        }
    },

    watch: {
        dashboards: {
            immediate: true,
            handler() {
                this.ready = false
                this.$nextTick(() => {
                    this.ready = true
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>
