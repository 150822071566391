let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    tag: {
        defaults: {
            endpoint: 'tags.php',
            rootProperty: 'tags'
        },
        ...standardCrud,
        read: {
            params: { action: 'get' },
        },
    },

    // tags shared with users
    tagUser: {
        defaults: {
            endpoint: 'tags.php?property=users',
            rootProperty: 'tag_users'
        },
        ...standardCrud,
    },

    studentTag: {
        defaults: {
            endpoint: 'tags.php?property=student_tags',
            rootProperty: 'student_tags'
        },
        ...standardCrud,
    },

    // user attached tags
    userTag: {
        defaults: {
            endpoint: 'crud.php?property=UserTag',
            rootProperty: 'user_tag'
        },
        ...standardCrud,
    },
    userTagV: {
        defaults: {
            endpoint: 'crud.php?property=UserTag',
            rootProperty: 'user_tag'
        },
        ...standardCrud,
        read: {
            endpoint: 'crud.php?property=UserTagV',
            params: { action: 'get' },
            rootProperty: 'user_tag_v'
        },
    },


}
