<template>
    <div>
        <v-layout row wrap>
            <v-flex xs3>
                <fe-remote-combo
                    label="School Year"
                    class="ma-2"
                    :url="$models.getUrl('schoolYear', 'read')"
                    rootProperty="years"
                    v-model="schoolYear"
                />

                <div
                    @click="searchBy='name'"
                    class="subheading ma-2 user-search-option"
                    :class="[{selected: searchBy === 'name'}]"
                >
                    By Name
                </div>

                <div
                    @click="searchBy='school'"
                    class="subheading ma-2 user-search-option"
                    :class="[{selected: searchBy === 'school'}]"
                >
                    By School
                </div>

                <div
                    @click="searchBy='tag'"
                    class="subheading ma-2 user-search-option"
                    :class="[{selected: searchBy === 'tag'}]"
                >
                    By Tag
                </div>

                <div
                    @click="searchBy='group'"
                    class="subheading ma-2 user-search-option"
                    :class="[{selected: searchBy === 'group'}]"
                >
                    By User Group
                </div>
            </v-flex>

            <v-flex xs9>
                <v-layout row wrap>
                    <v-flex xs12 v-show="searchBy === 'name'">
                        <fe-label style="margin-top: 8px;">User Name</fe-label>

                        <v-text-field
                            placeholder="Type a user name (3 characters minimum)"
                            v-model="searchText"
                            solo flat dense
                        />
                    </v-flex>

                    <v-flex xs6 v-show="searchBy === 'school'">
                        <fe-remote-combo
                            label="School"
                            class="mt-2"
                            :url="$models.getUrl('school', 'read')+'&school_year_id='+schoolYear.id"
                            rootProperty="schools"
                            v-model="school"
                        />
                    </v-flex>

                    <v-flex xs6 v-show="searchBy === 'tag'">
                        <fe-remote-combo
                            label="Tag"
                            class="mt-2 ml-2"
                            :url="$models.getUrl('tag', 'read') + '&property=available_user_tags'"
                            rootProperty="tags"
                            v-model="tag"
                        />
                    </v-flex>

                    <v-flex xs6 v-show="searchBy === 'group'">
                        <fe-remote-combo
                            label="User Group"
                            class="mt-2 ml-2"
                            :url="$models.getUrl('userGroup', 'read')"
                            rootProperty="user_group"
                            v-model="group"
                        />
                    </v-flex>
                </v-layout>

                <v-flex xs12>
                    <fe-grid
                        ref="grid"
                        :showToolbar="false"
                        :columnDefs="columnDefs"
                        :rowSelection="multiSelect ? 'multiple' : 'single'"
                        :rowData="items"
                        :style="gridStyle"
                        @rowSelected="doSelect"
                        @cellClicked="onCellClicked"
                    />
                </v-flex>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'UserSearch',

    props: {
        value: {},
        searchYear: {},
        gridHeight: {
            type: Number,
            default: 400
        },
        // Initially selected users
        users: {type: Array, default: () => []},
        multiSelect: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            school: null,
            grade: null,
            schoolYear: {},
            tag: null,
            group: null,
            searchBy: 'name',
            searchText: null,
            items: []
        }
    },

    computed: {
        ...mapState('global', ['currentYear']),

        gradeUrl() {
            let url = this.$models.getUrl('grade', 'read') + '&school_year_id=' + this.schoolYear.id
            if (this.school) {
                url += '&school_id=' + this.school.id
            }
            return url
        },

        gridStyle() {
            return [{height: this.gridHeight + 'px'}]
        },

        columnDefs() {
            let columns = [{
                headerName: 'User',
                field: 'user_full_name',
                minWidth: 300
            }]

            if (this.multiSelect) {
                columns.unshift({
                    checkboxSelection: true,
                    maxWidth: 70,
                    width: 70,
                    headerCheckboxSelection: this.multiSelect,
                    colId: 'checkbox_column'
                })
            }

            if (this.searchBy !== 'group') {
                columns.push({
                    headerName: 'Title',
                    field: 'title'
                })
            }

            return columns
        }
    },

    watch: {
        schoolYear() {
            this.resetFields()
        },

        searchBy() {
            this.items = []
            this.$emit('input', [])

            this.resetFields()
        },

        searchText(v) {
            let me = this
            if (v && v.length >= 3) {
                let s = '&search=' + this.searchText
                this.$_.debounce(function () {
                    me.loadData(s)
                }, 500)()
            }
        },

        tag(v) {
            if (v) {
                let s = 'tag_id=' + v.id
                this.loadData(s)
            }
        },

        school(v) {
            if (v) {
                this.grade = null
                let s = 'school_id=' + v.id
                this.loadData(s)
            }
        },

        grade(v) {
            if (!v || !this.school) return

            let s = 'school_id=' + this.school.id + '&grade_id=' + v.id
            this.loadData(s)
        },

        group(v) {
            if (!v) return

            let s = 'user_group_id=' + this.group.id
            this.loadData(s, true)
        }
    },

    mounted() {
        if (this.searchYear) {
            this.schoolYear = {...this.searchYear}
        } else {
            this.schoolYear = {...this.currentYear}
        }
    },

    methods: {
        resetFields() {
            this.searchText = null
            this.school = null
            this.grade = null
            this.tag = null
        },

        doSelect() {
            this.$emit('input', this.$refs.grid.gridApi.getSelectedRows())
        },

        loadData(str, groups = false) {
            let me = this
            if (!str) {
                return
            }

            this.$setLoading(true)
            let model = groups ? 'userGroupUser' : 'user'
            let rootProperty = groups ? 'user_group_user' : undefined
            this.$axios.get(this.$models.getUrl(model, 'read') + '&' + str + '&school_year_id=' + this.schoolYear.id + '&active=1')
                .then(response => {
                    this.items = this.$ecResponse(response, rootProperty)
                    this.$emit('filterUsers', [...this.items])

                    // Tried to use the @rowDataChanged event (et al.) from ag grid,
                    // but even with $nextTick the timing wasn't right, and pre-checks failed
                    setTimeout(() => {
                        this.$refs.grid.gridApi.forEachNode((node) => {
                            node.setSelected(!!me.users.find(u => u.user_full_name === node.data.user_full_name))
                        })
                    }, 10)
                })
                .finally(() => {
                    this.$setLoading(false)
                })
        },

        onCellClicked(params) {
            if (!this.multiSelect) {
                params.node.setSelected(true, true)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.user-search-option {
    cursor: pointer;

    &.selected {
        text-decoration: underline;
    }
}
</style>
