export default {
    subcategory: {
        rules: [
            r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        ],
        defaults: {
            endpoint: 'subcategories.php',
            rootProperty: 'subcategories'
        },
        read: {
            params: {
                action: 'get',
                show_all: 1
            }
        },
        create: {
            params: { action: 'create' },
        },
        update: {
            params: { action: 'update' },
        },
        destroy: {
            params: { action: 'delete' },
        }
    },
    subcategoryRecalc: {
        defaults: {
            endpoint: 'subcategories.php?action=run',
        },
        read: {
            property: 'recalc',
        },
    },
}