<template>
    <div>
        <fe-dialog
            title="Print Action Plan"
            :acceptButtonText="buttonText"
            dismissButtonText="Cancel"
            @close="handleClose"
            @accept="handlePrint"
            :width="landscape ? '11.5in' : '9in'"
        >
            <v-flex row wrap style="height: 100%">
                <v-menu offset-y
                    v-model="openMenu"
                    :close-on-content-click="false"
                    width="325"
                >
                    <template v-slot:activator="{ on }">
                        <fe-icon-btn
                            v-on="on"
                            useIcon="fas fa-cog"
                            class="icon-menu-btn"
                        />
                    </template>
                    <v-flex style="background: #fff; padding: 20px 10px">
                        <div class="d-flex mb-5 align-center">
                            <fe-switch
                                v-model="landscape"
                                hide-details
                            />
                            <span class="ml-4">Landscape</span>
                        </div>
                        <div class="d-flex mb-5 align-center">
                            <fe-switch
                                v-model="descriptionShow"
                                hide-details
                            />
                            <span class="ml-4">Include Action Item Descriptions</span>
                        </div>
                        <div class="d-flex align-center" :class="commentShow ? 'mb-5' : ''">
                            <fe-switch
                                v-model="commentShow"
                                hide-details
                            />
                            <span class="ml-4">Include Action Item Comments</span>
                        </div>
                        <div v-if="commentShow" class="d-flex align-center">
                            <fe-switch
                                v-model="commenterShow"
                                hide-details
                            />
                            <span class="ml-4">Include Commenter Name</span>
                        </div>
                    </v-flex>
                </v-menu>
                <v-flex xs12 mb-2 pa-3 justify-center class="view-wrapper">
                    <div
                        class="print-group-wrapper ap-print-view"
                        :class="[{'landscape': landscape}]"
                    >
                        <div id="apprintview" ref="print">
                            <action-plan-print-view
                                :descriptions="descriptionShow"
                                :comments="commentShow"
                                :commenter="commenterShow"
                                :landscape="landscape"
                                :activeDashboard="activeDashboard"
                                :actionPlan="actionPlan"
                            />
                        </div>
                    </div>
                </v-flex>
            </v-flex>
        </fe-dialog>
    </div>
</template>

<script>
    import ActionPlanPrintView from "./PrintView"
    import html2pdf from 'html2pdf.js'
    export default {
        name: "ActionPlanPrint",
        components: { ActionPlanPrintView, html2pdf },
        props: {
            activeDashboard: { type: Object, default: () => {} },
            actionPlan: { type: Array, default: () => [] },
        },
        data() {
            return {
                landscape: true,
                descriptionShow: true,
                commentShow: false,
                commenterShow: false,
                printing: false,
                openMenu: false,
            }
        },
        watch: {
            commentShow(x) {
                if (!x) this.commenterShow = false
            },
        },
        computed: {
            buttonText() {
                if (this.printing) {
                    return '<i class="fas fa-spinner fa-spin mr-2"/>Printing'
                }
                return 'Print'
            },
        },
        methods: {
            handleClose() {
                this.$emit('close')
            },
            handlePrint() {
                let me = this
                var mywindow = window.open('', 'PRINT', 'height=400,width=800')
                var style = document.createElement('style')
                var landscape = '@page {size:landscape !important; -webkit-print-color-adjust: exact !important; margin: 0;}'
                var portrait = '@page {size: portrait !important; -webkit-print-color-adjust: exact !important; margin: 0;}'
                var extra = '@media print {body{-webkit-print-color-adjust: exact !important;}}'
                mywindow.document.write('<html><head>')
                mywindow.document.write('</head><body >')
                mywindow.document.write(document.getElementById('apprintview').innerHTML)
                var head = mywindow.document.head
                style.type = 'text/css'
                style.media = 'print'
                me.landscape ? style.appendChild(document.createTextNode(landscape)) : style.appendChild(document.createTextNode(portrait))
                style.appendChild(document.createTextNode(extra))
                head.appendChild(style)
                mywindow.document.write('</body></html>')
                mywindow.document.close() // necessary for IE >= 10
                mywindow.focus() // necessary for IE >= 10*/
                setTimeout(function() {
                    mywindow.print()
                    mywindow.close()
                }, 1000)

            }
        }
    }
</script>

<style lang="scss" scoped>
    .view-wrapper {
        height: calc(100% - 50px);
        overflow: auto;
        border: 1px dashed #d0d0d0;
        border-radius: 4px;
        background-color: #EFEFEF;
    }
    .print-group-wrapper {
        padding: .5in 0;
        min-height: 11in;
        width: 8.5in;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
    }
    .print-group {
        padding: 0 .5in;
    }
    .landscape {
        min-height: 8.5in;
        width: 11in;
    }
    ::v-deep .v-input--selection-controls__input > .v-icon {
        font-size: 20px;
        margin-top: 2px;
    }
    ::v-deep .accent--text {
        color: #49C379 !important;
    }
    .icon-menu-btn {
        margin-left: auto;
        ::v-deep .v-btn {
            margin-left: 0;
            margin-right: 0;
            .v-icon {
                color: #7E8494 !important;
            }
        }
    }
    .ap-print-view {
        font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
    }
</style>
