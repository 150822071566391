import Vue from "vue"
export default Vue.extend({
    template: `
        <div class="slo-completion-status-toggle-button" :class="classes">{{ text }}</div>
    `,
    computed: {
        preventCompletionReverse () { return this.params.preventCompletionReverse || false },
        item () { return this.params.data },
        complete () { return this.item[this.params.completeField || 'completed'] },
        dueByField () { return this.params.dueByField || null },
        overdue () {
            if (!this.dueByField) return false
            return !this.complete && this.$dayjs(`${this.item[this.dueByField]}`) < this.$dayjs()
        },
        incomplete () { return !this.complete && !this.overdue },
        classes () {
            return {
                'is-complete': this.complete,
                'is-overdue': this.overdue,
                'is-incomplete': this.incomplete,
                'allow-completion-reverse': !this.preventCompletionReverse
            }
        },
        text () {
            if (this.complete) return "Done"
            else if (this.overdue) return "Overdue"
            else return "To Do"
        }
    }
});
