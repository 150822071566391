<template lang="html">
    <fe-chip close @click="editFilter(filter)" @click:close="unsetFilter(filter)" :class="{highlight:highlight}">
        {{filter.chipText}}
        <template #tooltip >
            <!-- <v-list two-line dense dark> -->
                <v-list-item v-for="(value, label) in filter.tooltipData" :key="label" dark dense>
                    <v-list-item-content>
                        <v-list-item-title>{{ label }}</v-list-item-title>
                        <v-list-item-subtitle>
                            <span class="mr-1" >
                                {{value}}
                            </span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            <!-- </v-list> -->
        </template>
    </fe-chip>
</template>

<script>
import Group from '../Group'
import { mapLocalState } from '@/util/vuexHelper'

export default {
    name: 'ActiveFilterChip',
    inject: ['localStore'],

    props: ['filter'],

    data() {
        return {}
    },
    computed: {
        ...mapLocalState(['params','charts','groupers','activeGrouping','highlightedFilter']),
        highlight() {
            return this.highlightedFilter == this.filter.id
        },
    },
    methods: {
        editFilter(filter) {
            filter.editor.load(filter)
        },
        unsetFilter(filter) {
            this.groupers = this.groupers.filter(x => x != filter.grouper)
            this.params.filters = this.params.filters.filter(x => x != filter)
            if(this.activeGrouping.id == filter.id || !this.activeGrouping.grouper) {
                this.activeGrouping.grouper = null
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .v-tooltip__content::v-deep .v-list {
        margin: 0 !important;
        padding: 0 !important;
    }
    .highlight::v-deep .v-chip {
        border-color: black;
        box-shadow: 0 0 3px 3px rgb(83,160,228);
    }
</style>




<!-- <template>
    <v-tooltip bottom>
        <template slot="activator">
            <v-chip
                class="breadcrumb-chip"
                close
                @click="$emit('editFilter', filter)"
                @input="$emit('unsetFilter', filter)"
            >
                <span class="text-truncate text-no-wrap slot-text" >
                    {{ filter.breadcrumbText }}
                </span>
            </v-chip>
        </template>
        <v-list two-line dense dark>
            <v-list-item v-for="(param, index) in filter.params" :key="index">
                <v-list-item-content>
                    <v-list-item-title>{{ param.label }}</v-list-item-title>
                    <v-list-item-subtitle>
                        <span
                            class="mr-1"
                        >
                            {{chipText(param)}}
                        </span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-tooltip>
</template>

<script>
export default {
    name: 'ActiveFilterChip',
    props: ['filter', 'truncateBreadcrumbBy'],
    data() {
        return {}
    },
    methods: {
        chipText(x) {
            if(Array.isArray(x.value)) {
                return x.value.map(y=>y.name || y.value).join(', ')
            } else {
                return x.name || x.value
            }
        }
    }
}
</script>

<style>
</style> -->
