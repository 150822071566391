import Vue from "vue"

export default Vue.extend({
    template: `
        <div v-html="this.value" style="cursor: pointer; text-align: center">
        </div>
    `,

    data: function () {
        return {
            value: null,
            interventionLevels: {
                'int_level_orange': '<i class="fa fa-square fa-2x" style="color: orange;"></i>',
                'int_level_orange_ended': '<i class="fa fa-square fa-2x" style="color: orange; opacity: .5"></i>',
                'int_level_maroon': '<i class="fa fa-square fa-2x" style="color: #9b3939;"></i>',
                'int_level_maroon_ended': '<i class="fa fa-square fa-2x" style="color: #9b3939; opacity: .5;"></i>',
                'int_level_baby_blue': '<i class="fa fa-square fa-2x" style="color: #4af4f7;"></i>',
                'int_level_baby_blue_ended': '<i class="fa fa-square fa-2x" style="color: #4af4f7; opacity: .5;"></i>',
                'int_level_purple': '<i class="fa fa-square fa-2x" style="color: #ce4cbd;"></i>',
                'int_level_purple_ended': '<i class="fa fa-square fa-2x" style="color: #ce4cbd; opacity: .5;"></i>',
                'int_level_green': '<i class="fa fa-square fa-2x" style="color: #4bc647;"></i>',
                'int_level_green_ended': '<i class="fa fa-square fa-2x" style="color: #4bc647; opacity: .5;"></i>',
                'int_level_yellow': '<i class="fa fa-square fa-2x" style="color: yellow;"></i>',
                'int_level_yellow_ended': '<i class="fa fa-square fa-2x" style="color: yellow; opacity: .5;"></i>'
            }
        }
    },
    beforeMount() {
    },
    mounted() {
        this.value = this.params.data.interventions ? this.interventionLevels[this.params.data.interventions] : this.params.data.behavior_interventions ? this.interventionLevels[this.params.data.behavior_interventions] : null
    }
})