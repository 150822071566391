<template>
    <div>
        <div class="d-flex">
            <v-toolbar color="white" flat dense>
                <v-toolbar-title :class="{optional: isOptional()}" >{{title}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <fe-btn @click="add(true)" depressed color="secondary">Add Response</fe-btn>
            </v-toolbar>
        </div>
        <div style="padding: 0 15px">
            <div class="theme--light v-label normal">Complete?</div>
            <v-radio-group
                v-model="studentRec.completed"
                :column="false"
                :disabled="!canComplete"
                @click="followupEmail"
            >
                <v-radio :value="1" label="Yes, do not leave open for review" class="mr-2"></v-radio>
                <v-radio :value="0" label="No, leave open for review"></v-radio>
            </v-radio-group>

            <div v-for="(response, i) in responses">
                <div v-if="i > 0">
                    <v-divider style="margin-bottom: 24px;"/>
                </div>
                <div class="d-flex">
                    <fe-remote-combo
                        v-model="response.incident_response"
                        :label="getLabelType"
                        ref="typeCombo"
                        rootProperty="incident_responses"
                        url='incidentResponses.php?action=get'
                        :params="incidentResponseParams()"
                        :rules="[v=>isRequired(v)]"
                        @input="getCustomFields(response)"
                        :filter="responseFilter"
                        style="width: 42%"
                    ></fe-remote-combo>
                    <div style="margin-top: 8px">
                        <div style="font-size: 12px; margin-left: 10px;">Primary</div>
                        <div style="padding-top: 8px">
                            <v-checkbox
                                label="Yes"
                                :disabled="!response || !!response.primary_flag"
                                v-model="response.primary_flag"
                                ref="primaryCheckBox"
                                @change="togglePrimary(response)"
                            ></v-checkbox>
                        </div>
                    </div>
                    <div style="margin-top: 8px">
                        <div style="font-size: 12px; margin-left: 10px;">Response completed by administrator</div>
                        <div style="padding-top: 8px">
                            <v-checkbox
                                v-model="response.admin_flag"
                                label="Yes"
                                    :disabled="!$hasSecurity('USE_RESTRICTED_RESPONSE')"
                            ></v-checkbox>
                        </div>
                    </div>
                    <v-spacer></v-spacer>
                    <fe-icon-btn
                        v-if="!response.primary_flag"
                        style="margin-top: 25px"
                        useIcon="fas fa-minus-circle"
                        color="#bf0d00"
                        size="24px"
                        @click="remove(response)"
                    />
                </div>
                <div class="d-flex">
                    <div v-if="response.incident_response && response.incident_response.require_responder">
                        <user-combo
                            v-model="response.responder_user_id"
                            byId
                            :label="getLabelResponder"
                            :rules="[v=>isRequired(v)]"
                        />
                    </div>
                    <div v-if="response.incident_response && response.incident_response.serve_flag">
                        <fe-date-time-picker
                            v-model="response.served_start_date"
                            label="Served Start Dt"
                            :max="response.served_end_date"
                            pickerDefault="now"
                        >
                        </fe-date-time-picker>
                    </div>
                    <div v-if="response.incident_response && response.incident_response.serve_flag">
                        <fe-date-time-picker
                            v-model="response.served_end_date"
                            label="Served End Dt"
                            :min="response.served_start_date"
                            :pickerDefault="response.served_start_date ? response.served_start_date : $dayjs().format('YYYY-MM-DD HH:mm')"
                        >
                        </fe-date-time-picker>
                    </div>
                    <template v-for="(field) in response.custom_fields">
                        <div>
                            <custom-field :field="field" :key="field.id" />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomField from './fields/CustomField'
import UserCombo from './fields/UserCombo'
import { mapState } from 'vuex'

export default {
    name: 'ResponsePanel',
    props: {
        studentRec: { type: Object },
        responses: { type: Array },
        panelVisibility: { type: Number }
    },
    components: {
        CustomField,
        UserCombo,
    },
    data() {
        return {
            startDateMenu: false,
            endDateMenu: false
        }
    },
    watch: {
        panelVisibility(v) {
            this.$children.forEach( c => c.validate && c.validate() )
        },
    },
    mounted() {
        if(!this.responses.length) this.add()
    },
    computed: {
        ...mapState('global', ['districtConfig']),
        title() {
            return this.responses.length > 1 ? 'Responses' : 'Response'
        },
        canComplete() {
            let c = this.studentRec.behaviors.some(x => {
                return x.incident_behavior_type && x.incident_behavior_type.complete_security_id || x.complete_security_id
            })
            if(c && !this.$hasSecurity('PBIS_COMPLETE_MAJOR')) {
                // disable complete
                this.studentRec.completed = 0
                return false
            }
            return true
        },
        getLabelType() {
            let required = !this.isOptional() ? '*' : ''
            return 'Type ' + required
        },
        getLabelResponder() {
            let required = !this.isOptional() ? '*' : ''
            return 'Responder ' + required
        }
    },
    methods: {
        isRequired(v) {
            return this.panelVisibility == 2 && !v ? 'Field is required' : true
        },
        isOptional() {
            return this.panelVisibility == 1
        },
        followupEmail() {
            if(this.studentRec.isCompleted === 0 && this.studentRec.completed === 1) {
                this.$messageBox({
                    title: 'Send Follow Up Email',
                    persistent: true,
                    message: 'You are marking an incident as complete.  Would you like to notify the incident observer and ' +
                        'entry user of these changes?',
                    maxWidth: '500',
                    actions: [{
                        text: 'No'
                    }, {
                        text: 'Yes',
                        primary: true,
                        onClick: () => {
                            this.$emit('sendfollowup')
                        }
                    }]
                })
            }
        },
        responseFilter(rec,index,items) {
            let ibtIds = this.studentRec.behaviors.map(x => x.incident_behavior_type_id)
            if(rec.incident_behavior_types && rec.incident_behavior_types.length) {
                return rec.incident_behavior_types.some(x => {
                    return ibtIds.includes(x.incident_behavior_type_id)
                })
            }
            return true
        },
        togglePrimary(response) {
            if (!response.primary_flag) return
            this.responses.forEach(function(rec) {
                if (response !== rec) {
                    rec.primary_flag = !response.primary_flag
                }
            })
        },
        incidentResponseParams() {
            return {
                school_id: this.studentRec.school_id,
                active_only: 1
            }
        },
        remove(response) {
            if(!response.student_incident_response_id) {
                this.responses.splice(this.responses.indexOf(response),1)
            } else {
                this.$messageBox({
                    title: 'Confirm Remove',
                    message: 'Are you sure you want to remove this behavior response? This will take effect immediately.',
                    maxWidth: '400',
                    actions: [{
                        text: 'Cancel',
                    }, {
                        text: 'Remove',
                        primary: true,
                        onClick: () => {
                            let url = 'behaviorIncident.php?action=delete&property=student_incident_response'
                            let payload = {
                                student_incident_responses: [{
                                    student_incident_response_id: response.student_incident_response_id
                                }]
                            }
                            this.$axios.post(url,JSON.stringify(payload)).then( r => {
                                if(r.data) {
                                    if(r.data.success) {
                                        this.responses.splice(this.responses.indexOf(response),1)
                                        this.$snackbars.$emit('new', { text: r.data.msg, usage: 'success' })
                                    } else {
                                        this.$snackbars.$emit('new', { text: r.data.msg, usage: 'warning' })
                                    }
                                }
                            }).catch(err => {
                                console.warn('err: ',err);
                            })
                        }
                    }]
                })
            }
        },
        add(focus) {
            let cnt = this.districtConfig.INCIDENT_RESPONSE_CNT
            if (this.responses.length >= cnt) {
                this.$snackbars.$emit('new', { text: 'Your district has a limit of ' + cnt + ' responses per incident', usage: 'warning' })
                return
            }
            this.responses.push({
                primary_flag: this.responses.length > 0 ? false : true,
                custom_fields: []
            });
            if(focus) {
                requestAnimationFrame(() => {
                    this.$refs.typeCombo[this.responses.length-1].focus()
                })
            }
        },
        getCustomFields(response) {
            this.$set(response,'custom_fields',null)
            // get custom fields
            let responseId = response.incident_response ? response.incident_response.id : ''
            let url = 'incidentResponses.php?action=get&property=field&active=1&incident_response_id='+responseId
            this.$axios.get(url).then( r => {
                if(r && r.data) {
                    this.$set(response,'custom_fields',r.data.incident_response_fields)
                }
            }).catch( err => {
                console.warn('err: ',err)
            })
        }
    }
}
</script>

<style scoped="true">
</style>
