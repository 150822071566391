<template>
    <div class="flex-fill flex-column">
        <div class="d-flex justify-space-between">
            <div class="headline">Achievement Dashboard</div>

            <div class="top-buttons">
                <fe-btn usage="primary" @click="$emit('new')" v-if="hasADAccess">
                    New Dashboard
                </fe-btn>
            </div>
        </div>

        <fe-tabs
            v-if="hasADAccess"
            :tabs="tabs"
            color="transparent"
            class="tabs"
            style="height: 45px;"
            noPadding
            @tabClicked="activeTab = $event"
        />

        <dashboard-selection
            v-for="(tab, i) in tabs"
            :key="`tab-${i}`"
            v-if="activeTab.show === tab.show"
            :view="view"
            :toggleItems="toggleItems"
            :dashboards="filteredDashboards"
            @toggle="view = $event"
            @select="$emit('select', $event)"
        />
    </div>
</template>

<script>
import DashboardSelection from "./DashboardSelection"
import {mapState} from "vuex"

export default {
    name: "LandingPage",

    components: {DashboardSelection},

    props: {
        dashboards: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            activeTab: {
                name: 'All',
                show: 'all',
                path: '#'
            },
            view: {
                name: 'Gallery',
                show: 'gallery',
                icon: 'fas fa-border-all'
            },
            toggleItems: [{
                name: 'Gallery',
                show: 'gallery',
                icon: 'fas fa-border-all'
            }, {
                name: 'List',
                show: 'list',
                icon: 'fas fa-bars'
            }]
        }
    },

    computed: {
        ...mapState('global', ['sessionUser', 'shareableStores']),

        tabs() {
            let tabs = [{
                name: 'All',
                show: 'all',
                path: '#'
            }, {
                name: 'My Dashboards',
                show: 'my',
                path: '#'
            }]

            if (!this.shareableStores.district.dashboard_spark) {
                tabs.push({
                    name: 'District Dashboards',
                    show: 'district',
                    path: '#'
                })
            }

            tabs.push({
                name: 'Shared With Me',
                show: 'shared',
                path: '#'
            })

            return tabs
        },

        filteredDashboards() {
            return this[this.activeTab.show + 'Dashboards']
        },

        allDashboards() {
            return this.dashboards
        },

        myDashboards() {
            let id = this.sessionUser.user.id
            return this.dashboards.filter(x => !x.district_default && x.created_by === id)
        },

        districtDashboards() {
            return this.dashboards.filter(x => x.district_default)
        },

        sharedDashboards() {
            return this.dashboards.filter(x => x.shared)
        },

        hasADAccess() {
            return this.$hasSecurity('MANAGE_ACHIEVEMENT_DASHBOARD') || this.$hasSecurity('ACCESS_ACHIEVEMENT_DASHBOARD')
        }
    }
}
</script>

<style lang="scss" scoped>
.tabs {
    ::v-deep .fe-tabs-color {
        .v-tab a {
            color: #b3b7c5 !important;
        }

        .v-tab--active a {
            color: #006C90 !important;
        }
    }

    ::v-deep .v-tabs-slider {
        background-color: #006C90 !important;
        border-color: #006C90 !important;
    }
}
</style>
