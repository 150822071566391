<template>
    <fe-dialog
        title="New Disability"
        persistent
        acceptButtonText="Save"
        dismissButtonText="Cancel"
        width="350px"
        :forceClose="false"
        dismissable
        disableAutoclose
        @accept="saveRec"
        @dismiss="dismissDialog"
    >
        <v-form ref="form">

            <div class="d-flex">
                <fe-label>School Year</fe-label>
                <fe-remote-combo
                    class="student-controls"
                    :items="this.$props.schoolYearList"
                    v-model="newRec.school_year_id"
                    itemText="name"
                    itemValue="id"
                    :rules="$fieldValidators('select', 'School year', { required: true })"
                    byId>
                </fe-remote-combo>
            </div>
            <div class="d-flex">
                <fe-label>Disability</fe-label>
                <fe-remote-combo
                    class="student-controls"
                    :url="$models.getUrl('studentDisabilityType', 'read')"
                    rootProperty="student_disability_type"
                    v-model="newRec.student_disability_type_id"
                    :itemText="displayName"
                    itemValue="id"
                    :rules="$fieldValidators('select', 'Disability', { required: true })"
                    byId>
                </fe-remote-combo>
            </div>
            <div class="d-flex">
                <fe-label>Inactive Dt</fe-label>
                <fe-date-picker class="add-school-date-picker" placeholder="MM/DD/YYYY" v-model="newRec.inactive_date" />
            </div>

        </v-form>

    </fe-dialog>
</template>

<script>
export default {
    name: 'AddDisability',

    props: {
        crud: null,
        studentInfo: null,
        schoolYearList: null,
        disabilitiesLength: null
    },

    data() {
        return {
            newRec: {
                school_year_id: undefined,
                student_disability_type_id: undefined,
                inactive_date: undefined,
                id: undefined,
                student_id: undefined
            }
        }
    },

    methods: {
        saveRec() {
            this.newRec.student_id = this.$props.studentInfo.student_id
            this.newRec.id = "StudentDisability-" + this.$props.disabilitiesLength

            if(this.$refs.form.validate()) {
                this.crud.create(this.newRec).then( r => {
                    this.resetEntry()
                    this.dismissDialog()
                })
            }
        },

        resetEntry() {
            this.newRec = {
                school_year_id: undefined,
                student_disability_type_id: undefined,
                inactive_date: undefined,
                id: undefined,
                student_id: undefined
            }
        },

        dismissDialog() {
            if (this.$_.isEmpty(Object.values(this.newRec).filter(x => !!x))) {
                this.$emit('close')
            } else {
                this.$confirmCancel(() => {
                    this.resetEntry()
                    this.$emit('close')
                })
            }
        },

        displayName(item) {
            // if option has a display name, use it, otherwise use the value
            if (!item.display_name_group) {
                return item.display_name ? item.display_name : item.description
            } else {
                return item.description + (item.display_name ? " (" + item.display_name + ")" : "")
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep div.fe-label {
        width: 76px;
    }

    ::v-deep {
        div.add-school-date-picker, div.student-controls {
            width: 242px;
        }
    }
</style>
