var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form-common-config',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"fieldSpecific",fn:function({rec}){return [_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v(" Display Table "),_c('v-flex',{staticStyle:{"display":"inline-block","margin-right":"10px"}},[_c('fe-info-tip',{attrs:{"tooltip":"The table can display any or all guardian informaion"}})],1)],1),_c('fe-switch',{staticStyle:{"margin-left":"auto"},attrs:{"true-value":1,"false-value":0},model:{value:(_vm.formFieldSavedSearch.config_json.as_table),callback:function ($$v) {_vm.$set(_vm.formFieldSavedSearch.config_json, "as_table", $$v)},expression:"formFieldSavedSearch.config_json.as_table"}})],1),_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v(" As of Creation Date "),_c('v-flex',{staticStyle:{"display":"inline-block","margin-right":"10px"}},[_c('fe-info-tip',{attrs:{"tooltip":"This will ONLY display guardians that were created as of this smartFORM creation date."}})],1)],1),_c('fe-switch',{staticStyle:{"margin-left":"auto"},attrs:{"true-value":1,"false-value":0},model:{value:(_vm.formFieldSavedSearch.config_json.to_creation),callback:function ($$v) {_vm.$set(_vm.formFieldSavedSearch.config_json, "to_creation", $$v)},expression:"formFieldSavedSearch.config_json.to_creation"}})],1),(!_vm.formFieldSavedSearch.config_json.as_table)?_c('div',[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Guardian")]),_c('fe-remote-combo',{staticStyle:{"margin-left":"auto"},attrs:{"byId":"","itemValue":"value","items":[
                    {value:1, name:'Guardian One'},
                    {value:2, name:'Guardian Two'},
                ],"clearable":false},model:{value:(_vm.formFieldSavedSearch.config_json.guardian),callback:function ($$v) {_vm.$set(_vm.formFieldSavedSearch.config_json, "guardian", $$v)},expression:"formFieldSavedSearch.config_json.guardian"}}),_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Guardian Field Type")]),_c('fe-remote-combo',{staticStyle:{"margin-left":"auto"},attrs:{"byId":"","itemValue":"value","items":[
                    {value:'guardian_full_name', name:'Full Name'},
                    {value:'guardian_fname', name:'First Name'},
                    {value:'guardian_lname', name:'Last Name'},
                    {value:'guardian_full_address', name:'Address'},
                    {value:'guardian_addr_street', name:'Street'},
                    {value:'guardian_addr_city', name:'City'},
                    {value:'guardian_addr_state', name:'State'},
                    {value:'guardian_addr_zip', name:'Zip'},
                    {value:'guardian_phone_number', name:'Phone'},
                    {value:'guardian_work_phone_number', name:'Work Phone'},
                    {value:'guardian_email_addr', name:'Email'},
                    {value:'guardian_last_login', name:'Last Login Date'},
                    {value:'relationship', name:'Relationship'},
                    {value:'created', name:'Created'},
                ],"clearable":false},model:{value:(_vm.formFieldSavedSearch.config_json.data_field),callback:function ($$v) {_vm.$set(_vm.formFieldSavedSearch.config_json, "data_field", $$v)},expression:"formFieldSavedSearch.config_json.data_field"}})],1):_c('div',[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Table Settings")]),_c('fe-remote-combo',{staticStyle:{"margin-left":"auto"},attrs:{"byId":"","multiple":"","moreLabel":"Columns","itemValue":"value","items":[
                    {value:'guardian_full_name', name:'Full Name'},
                    {value:'guardian_fname', name:'First Name'},
                    {value:'guardian_lname', name:'Last Name'},
                    {value:'guardian_full_address', name:'Address'},
                    {value:'guardian_addr_street', name:'Street'},
                    {value:'guardian_addr_city', name:'City'},
                    {value:'guardian_addr_state', name:'State'},
                    {value:'guardian_addr_zip', name:'Zip'},
                    {value:'guardian_phone_number', name:'Phone'},
                    {value:'guardian_work_phone_number', name:'Work Phone'},
                    {value:'guardian_email_addr', name:'Email'},
                    {value:'guardian_last_login', name:'Last Login Date'},
                    {value:'relationship', name:'Relationship'},
                    {value:'created', name:'Created'},
                ],"clearable":false},model:{value:(_vm.formFieldSavedSearch.config_json.display_columns),callback:function ($$v) {_vm.$set(_vm.formFieldSavedSearch.config_json, "display_columns", $$v)},expression:"formFieldSavedSearch.config_json.display_columns"}})],1),_c('fe-crud',{key:rec.id,ref:"crud",attrs:{"readParams":{form_field_id:rec.id},"config":_vm.$models.formFieldSavedSearch,"autoload":""},on:{"read":function($event){return _vm.processRead($event,rec)}}})]}}])},'form-common-config',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }