<template>
    <form-common-config
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #fieldSpecific="{rec}">
            <div class="d-flex">
                <fe-label :style="labelStyle">Form Status</fe-label>
                <fe-remote-combo
                    v-model="rec.status_id"
                    byId
                    :url="$models.getUrl('formInstanceStatus', 'read')"
                    style="margin-left:auto; max-width: 147px;"
                    :clearable="false"
                />
            </div>
        </template>
    </form-common-config>
</template>

<script>
import FormCommonConfig from '../FormCommonConfig'

export default {
    inject: ['localStore'],
    components: {
        FormCommonConfig,
    },
    computed: {
        labelStyle: me => ({ minWidth:'120px' }),
    },
}
</script>

<style lang="scss" scoped>
</style>
