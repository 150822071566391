import BaseService from '@/components/modules/thresholds/services/BaseService'
import BaseStore from './Base'

export default Object.assign({}, BaseStore.default(), {
    namespaced: true,

    getters: Object.assign({}, BaseStore.default().getters, {
        __config() {
            return {
                service: BaseService,
                params: {property: 'AttendanceStatus'},
                prop: 'attendance_status'
            }
        }
    })
})
