let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    userTargetSet: {
        defaults: {
            endpoint: 'userTargetSet.php',
            rootProperty: 'targets'
        },
        ...standardCrud
    },
}
