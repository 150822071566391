export default {
    data() {
        return {
            selections: {
                one: null,
                two: null
            }
        }
    },
    methods: {
        async buildScatter(assessment1, assessment2) {
            let d1 = await this.fetch(assessment1)
            let d2 = await this.fetch(assessment2)

            if (d1.length === 0 || d2.length === 0) {
                this.$messageBox({
                    title: 'No Results Found',
                    persistent: true,
                    maxWidth: 600,
                    message: "<b>Data Set 1</b> returned " + d1.length + " records and <b>Data Set 2</b> returned " + d2.length + ". <br><br>Both data sets must return data.  Please modify your search.",
                    actions: [{
                        text: 'Okay', primary: true,
                        onClick: () => {
                        }
                    }]
                })

                return {}
            }

            return {
                dataset1: d1,
                dataset2: d2,
            }
        },
        async fetch(p) {
            if (p.score_detail_type_id) {
                // Fetch possible dropdown options and convert to numeric values
                // Backend score details endpoint currently doesn't return this data,
                // and we can do these calculations separately to reduce db joins
                let scoreDetailTypeOptions = await this.$axios.get(
                    `scoreDetailTypes.php?action=get&property=options&score_detail_type_id=${p.score_detail_type_id}`
                ).then(res => res.data.options)

                let resp = await this.$axios.get('scoreDetails.php?action=get&filter_students=1&' + this.$objectToParams(p))
                    .catch(err => { console.error(err) })
                let data = this.$ecResponse(resp, 'details')

                // Get unique, non-null subcat ids cause we gotta look up their names
                let sub_category_id = this.$_.uniq(data.map(itm => itm.sub_category_id)).filter(itm => !!itm)

                // Backend doesn't return the assessment name text (only the window name text),
                // and presently I'm reticent to increase the query complexity when I can do this quick lookup...
                resp = await this.$modelGet('subcategory', { id: sub_category_id })

                //data.forEach(d => d.score = parseFloat(d.score_detail_detail_value))
                return data.map(itm => {
                    // If this score detail type has options that have text-y
                    // options (e.g. "One Item Correct" has numeric equivalent of 1),
                    // we should translate them here
                    let val = scoreDetailTypeOptions.find(opt => opt.value == itm.detail_value)?.numeric_value || itm.detail_value

                    // if detail_value is 'Yes' or 'No', convert to 1 or 0
                    // not sure if this field can hold any other string values so this may need to be expanded
                    if (isNaN(parseFloat(val))) {
                        if (val.toUpperCase() === 'YES') {
                            val = 1
                        } else if (val.toUpperCase() === "NO") {
                            val = 0
                        }
                    }

                    return {
                        ...itm,
                        // Scatter plot chart expects a prop of `score` here
                        score: parseFloat(val),
                        // Scatter plot chart expects a specific prop name for the subcat
                        sub_category_name: (resp.find(sc => sc.id === itm.sub_category_id)?.name || `Assessment ${itm.sub_category_id}`) + ` (${itm.data_point_name})`,
                    }
                })
            } else {
                let resp = await this.$axios.get('targetScores.php?action=get&property=student_scatter&' + this.$objectToParams(p))
                    .catch(err => { console.error(err) })
                let data = this.$ecResponse(resp, 'scores') // calls for student_scatter which returns scores[]
                return data?.chartData.map(itm => ({
                    ...itm,
                    // Scatter plot chart expects a specific prop name for the subcat
                    sub_category_name: `${itm.sub_category_display_name} (${itm.data_point_name})`,
                }))
            }
        },
        plotConfig(data) {
            if (data.score_detail_type_id) {
                return {
                    value: data.plot_value,
                    label: data.plot_label
                }
            }
            return null
        },
    }
}
