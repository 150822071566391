<template>
    <div ref="observationContainer">
        <fe-dialog
                v-if="showBehaviorsDialog"
                title="No behaviors selected"
                acceptButtonText="Select behavior(s)"
                dismissButtonText="Cancel"
                @close="showBehaviorsDialog = false"
                @dismiss="showBehaviorsDialog = false"
                @accept="$emit('previous')"
                dismissable
        >
            <div>You must select at least one behavior in the previous step to start an observation.</div>
        </fe-dialog>

        <div class="pa-4 fill-height d-flex flex-column" style="height: 100%">
            <div class="flex-grow-1" style="overflow-y: auto; overflow-x: hidden;">
                <div class="d-flex mb-4">
                    <fe-btn color="green" use-icon="fas fa-play-circle" @click="onStartObservation(false)">Start Observation</fe-btn>
                    <fe-btn color="green" use-icon="fas fa-play-circle" @click="onStartIntervalObservation">Start Interval Observation</fe-btn>
                </div>

                <fe-crud-grid
                    ref="grid"
                    :config="$models.studentObservationBehaviorOccurrences"
                    :columnDefs="columnDefs"
                    :readParams="{ student_observation_id: studentObservationId }"
                    disableInlineCreate
                    :showAddRowBtn="false"
                    style="height: 600px"
                >
                    <template #cellDialog="{ cell }">
                        <v-list>
                            <v-list-item @click="onDeleteOccurrence(cell)">Delete</v-list-item>
                        </v-list>
                    </template>
                </fe-crud-grid>

                <collapsible-panel title="Notes" smallTitle class="mt-4">
                    <div style="background-color: #ECEDF1; border-radius: 0px;" class="pa-4">
                        <p v-if="notes.length == 0">No notes created on this day.</p>

                        <topic-message
                            v-for="msg in notes"
                            v-if="!msg.private_flag || msg.private_flag && msg.created_by == sessionUser.user.id"
                            :key="`topic-message-${msg.id}`"
                            :message="msg"
                            :messages="[msg, ...msg.replies]"
                            disabled
                        />

                        <fe-crud
                            ref="crudNotes"
                            autoload
                            refresh-on-config
                            :config="$models.message"
                            :readParams="{ student_observation_id: studentObservationId }"
                            @read="notes = $event"
                        />
                    </div>
                </collapsible-panel>
            </div>

            <div>
                <div class="d-flex footerBtns">
                    <v-spacer/>
                    <fe-btn usage="ghost" @click="$emit('close')">Done</fe-btn>
                    <fe-btn usage="secondary" @click="$emit('previous')">Previous</fe-btn>
                    <fe-btn @click="$emit('next')">Next</fe-btn>
                </div>
            </div>
        </div>

        <fe-dialog
            v-if="createIntervalDialog.show"
            @close="createIntervalDialog.show = false"
            @dismiss="createIntervalDialog.show = false"
            @accept="onCreateInterval"
            :acceptButtonDisabled="!createIntervalDialog.isValid"
            title="Setup Interval"
        >
            <v-form v-model="createIntervalDialog.isValid">
                <div class="d-flex flex-column">
                    <fe-label>Interval Length (seconds)</fe-label>
                    <v-text-field
                        v-model="createIntervalDialog.record.interval_length"
                        flat solo dense
                    />

                    <fe-label>Observation Duration (seconds)</fe-label>
                    <v-text-field
                        v-model="createIntervalDialog.record.observation_duration"
                        flat solo dense
                    />
                </div>
            </v-form>
        </fe-dialog>

        <fe-crud ref="crudAntecedents" :config="$models.observationBehaviorAntecedents" autoload @read="antecedents = $event"/>
        <fe-crud ref="crudConsequences" :config="$models.observationBehaviorConsequences" autoload @read="consequences = $event"/>
    </div>
</template>

<script>
    import {mapState} from "vuex"
    import SelectColumn from './renderers/SelectColumn'
    import CollapsiblePanel from '@/components/common/CollapsiblePanel'
    import TopicMessage from '@/components/modules/meetings/workspace/agenda/TopicMessage'
    import EndButtonRenderer from './renderers/EndButtonRenderer.js'

    export default {
        name: 'ObservationLanding',

        components: {
            CollapsiblePanel,
            TopicMessage,
        },

        props: {
            observationBehaviorTypes: {
                type: Array,
                required: true
            },
            observationBehaviors: {
                type: Array,
                required: true
            },
            studentObservationId: {
                type: Number,
                required: true
            },
        },

        data() {
            return {
                antecedents: [],
                consequences: [],
                notes: [],
                createIntervalDialog: {
                    show: false,
                    record: {
                        interval_length: 30,
                        observation_duration: 3,
                    },
                },
                timerIntervalDialog: false,
                duration: null,
                showBehaviorsDialog: false
            }
        },

        computed: {
            ...mapState('global', ['currentYear', 'sessionUser']),
            columnDefs() {
                return [
                    {
                        headerName: "Observed Behavior",
                        field: "observation_behavior_name",
                        sortable: true,
                        editable: false,
                    }, {
                        headerName: "Antecedent",
                        field: "observation_behavior_antecedent_name",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: SelectColumn,
                        cellRendererParams: {
                            items: this.antecedents,
                            valueField: "observation_behavior_antecedent_id",
                            onInput: this.onUpdateGridOccurrence.bind(this),
                        },
                    }, {
                        headerName: "Consequence",
                        field: "observation_behavior_consequence_name",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: SelectColumn,
                        cellRendererParams: {
                            items: this.consequences,
                            valueField: "observation_behavior_consequence_id",
                            onInput: this.onUpdateGridOccurrence.bind(this),
                        },
                    }, {
                        headerName: "Occurred",
                        field: "start_time",
                        sortable: true,
                        editable: false,
                        cellRenderer: (v) => (v.data?.start_time) ? this.$dayjs(v.data.start_time).format('h:mm:ss a') : '',
                    }, {
                        headerName: "Ended",
                        field: "end_time",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: EndButtonRenderer,
                        cellRendererParams: {
                            endTime: "end_time",
                        },
                        onCellClicked: async (v) => {
                            if (!v.data?.end_time) {
                                await this.endBehaviorTimer(v.data)
                            }
                        },
                    }, {
                        headerName: "Control",
                        field: "control",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: "FeGridToggle",
                        onCellValueChanged: this.onUpdateGridOccurrence.bind(this),
                        cellRendererParams: {
                            rowDataKey: 'control'
                        },
                        maxWidth: 160,
                    }, {
                        colId: 'toolmenu',
                        maxWidth: 50,
                        cellRenderer: (v) => {
                            return '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>'
                        },
                        onCellClicked: this.onCellClicked,
                        cellStyle : { display: 'flex' }
                    },
                ]
            },
        },

        mounted() {
            this.reload()
        },

        methods: {
            reload() {
                this.$refs.grid.$refs.crud.read()
                    .then(() => {this.$refs.grid.$refs.grid.gridApi.refreshCells({force: true})})
            },
            onStartObservation(isIntervalObservation = false) {
                // if no behaviors have been selected
                // ask user to select one (or more)
                if (this.observationBehaviors.length === 0) {
                    this.showBehaviorsDialog = true
                    return
                }

                let me = this
                let w = this.$dockableWindow({
                    component: 'StandardObservation',
                    name: 'Record Data',
                    attrs: {
                        style: {
                            height: '100%'
                        },
                        domLayout: 'normal',
                        studentId: this.studentId,
                        observationBehaviorTypes: this.observationBehaviorTypes,
                        observationBehaviors: this.observationBehaviors,
                        studentObservationId: this.studentObservationId,
                        isIntervalObservation: !!isIntervalObservation,
                        intervalObservationIntervalLength: parseInt(this.createIntervalDialog?.record?.interval_length || 30),
                        intervalObservationObservationDuration: parseInt(this.createIntervalDialog?.record?.observation_duration || 3),
                    },
                    events: {
                        close(v) {
                            // Clicking the 'Stop' button only closes the Observation window
                            // Clicking the 'X' in the top right closes the whole Observation module
                            // This prevents the whole module from closing if the 'X' is clicked
                            if (v === 'stopBtn') {
                                me.$store.commit('global/removeDockedWindow', w)
                            }
                            setTimeout(() => {
                                me.$refs.crudAntecedents.read()
                                me.$refs.crudConsequences.read()
                                me.$refs.crudNotes.read()
                                me.reload()
                            }, 500)
                        }
                    },
                })
            },
            onStartIntervalObservation() {
                // if no behaviors have been selected
                // ask user to select one (or more)
                if (this.observationBehaviors.length === 0) {
                    this.showBehaviorsDialog = true
                    return
                }

                this.createIntervalDialog.show = true
            },
            onCreateInterval() {
                this.createIntervalDialog.show = false
                this.onStartObservation(true)
            },
            // This may be called when the user changes certain column values in the grid
            async onUpdateGridOccurrence(params, value) {
                // Handle a newly created item
                if (value && value.id === -1) {
                    let cleanName = value.name.replace(' (new)', '')
                    await this.saveNewAntecedentConsequece(cleanName, value.type)
                    value = value.type === 'antecedents' ? this.antecedents.filter(x => x.name === value.name)[0].id : this.consequences.filter(x => x.name === value.name)[0].id
                }

                let occurrence = {
                    id: params.data.id,
                    observation_behavior_name: params.data.observation_behavior_name,
                    observation_behavior_antecedent_id: params.data.observation_behavior_antecedent_id,
                    observation_behavior_consequence_id: params.data.observation_behavior_consequence_id,
                    observation_behavior_id: params.data.observation_behavior_id,
                    student_observation_sampling_id: params.data.student_observation_sampling_id,
                    start_time: params.data.start_time,
                    end_time: params.data.end_time,
                    control: params.node.data.control,
                }

                await this.$refs.grid.$refs.crud.update({
                    ...occurrence,
                    [params.column.colId.replace('_name', '_id')]: value ? value.id || value : null,
                })
                    .then(r => {
                        this.$nextTick(() => {
                            this.reload()
                        })
                    })
            },
            // Save new antecedent or consequence added by user in table
            async saveNewAntecedentConsequece(name, type) {
                let params = type === 'antecedents' ? {observation_behavior_antecedents: [{name: name}]} : {observation_behavior_consequences: [{name: name}]}

                await this.$axios.post('observations.php?action=create&property=' + type, params)
                    .then(r => {
                        let success = this.$ecResponse(r)
                    })

                // reload antecedent/consequence options
                type === 'antecedents' ? await this.$refs.crudAntecedents.read() : await this.$refs.crudConsequences.read()
            },
            onCellClicked(cell) {
                let cfg = cell.column.colId === 'toolmenu' ? 'menu' : null
                this.$refs.grid.$refs.grid.setDialogCell(cell, cfg)
            },
            async onDeleteOccurrence(cell) {
                let msg = 'You are about to delete an observation occurrence. This action cannot be undone. Are you sure you want to continue?'

                await this.$confirmDelete([], () => {
                    this.$refs.grid.$refs.crud.destroy(cell.node.data)
                        .then((resp) => {
                            if (resp?.data?.success) {
                                this.$refs.grid.$refs.crud.read()
                            }
                            else {
                                this.$snackbars.$emit('new', {text: resp.data.msg, usage: 'warning'})
                            }
                        })
                        .catch((err) => {
                            if (err.response) this.$snackbars.$emit('new', {text: err.response, usage: 'warning'})
                            else this.$snackbars.$emit('new', {text: err.message, usage: 'warning'})
                        })
                }, null, msg)
            },
        },
    }
</script>

<style lang="scss" scoped>

    .footerBtns {
        position: absolute;
        bottom: 0px;
        right: 0px;
        background-color: #FFFFFF;
        width: 100%;
        height: 80px;
        padding: 20px;
    }

</style>
