<template>
    <fe-dialog
        v-if="value"
        v-intersect="load"
        :title="template.name"
        disableAutoclose
        :forceClose="false"
        height="90vh"
        width="90%"
        @close="confirmClose"
        @dismiss="confirmClose"
    >
        <fe-overlay
            :value="loading"
            loader
            text="Loading template history"
        />
    
        <fe-grid
            ref="grid"
            style="height:100%"
            :columnDefs="columnDefs"
            :rowData="filteredRows"
            :showAddRowBtn="false"
            :bordered="false"
            :emptyState="{text:''}"
            :getRowStyle="rowStyling"
            showScrollbars
            displayDensity="small"
            @gridReady="resize"
            @cellValueChanged="onCellValueChanged"
            @cellClicked="onRowClick"
            @rowSelected="onSelected"
            @modelUpdated="updateGridSelections"
        >
            <template #after-search-toolbar-items>
                <div v-if="!showGroup">
                    <fe-filter-btn
                        class="moveup"
                        title="Section Name"
                        :items="sectionNames"
                        itemText="form_section_name"
                        itemValue="form_section_name"
                        v-model="filters.form_section_name"
                        multiple :closeOnSelect="false"
                    />
                    <fe-filter-btn
                        class="moveup"
                        title="Field Type"
                        :items="fieldTypes"
                        itemText="form_field_type_name"
                        itemValue="form_field_type_name"
                        v-model="filters.form_field_type_name"
                        multiple :closeOnSelect="false"
                    />
                    <fe-filter-btn
                        class="moveup"
                        title="Field Name"
                        :items="fieldNames"
                        itemText="form_field_name"
                        itemValue="form_field_name"
                        v-model="filters.form_field_name"
                        multiple :closeOnSelect="false"
                    />
                    <fe-filter-btn
                        class="moveup"
                        title="Data ID"
                        :items="dataIds"
                        itemText="form_field_data_id"
                        itemValue="form_field_data_id"
                        v-model="filters.form_field_data_id"
                        multiple :closeOnSelect="false"
                    />
                    
                    <fe-btn @click="resetFilters" usage="ghost" >
                        Clear
                    </fe-btn>
                </div>
                
            </template>
            <template #toolbar-items>
                <fe-btn usage="secondary" v-if="selectionCount" @click="openUpdateDialog">Update Data ID</fe-btn>
            </template>
        </fe-grid>
        
        <template #footer>
            <div class="d-flex justify-space-between flex-grow-1">
                <div class="d-inline-flex align-self-center">
                    <fe-label class="mb-2 mr-4">Include fields with no smartFORMs</fe-label>
                    <fe-switch hide-details v-model="includeAllFields" />
                </div>
                <div class="d-inline-flex align-self-center">
                    <fe-btn
                        v-if="showGroup==1 || selectionCountText[0] != '0'"
                        :usage="showGroup==1?'tertiary':'ghost'"
                        @click="toggleShowGroup(1)"
                    >
                        {{selectionCountText}}
                    </fe-btn>
                    <fe-btn
                        v-if="showGroup==2 || modifiedCountText[0] != '0'"
                        :usage="showGroup==2?'tertiary':'ghost'"
                        @click="toggleShowGroup(2)"
                    >
                        {{modifiedCountText}}
                    </fe-btn>
                </div>
                <div class="d-inline-flex align-self-center">
                    <fe-btn @click="confirmClose" usage="ghost" >Cancel</fe-btn>
                    <fe-btn @click="askReview" :disabled="modifiedCount==0" >Save</fe-btn>
                </div>
            </div>
        </template>
        
        <fe-dialog
            v-if="showUpdateDialog"
            title="Data ID"
            width="400px"
            acceptButtonText="Apply"
            dismissButtonText="Cancel"
            @close="showUpdateDialog=false"
            @accept="updateDataId"
        >
            <fe-label>Updating Data ID for {{selectionCount}} rows</fe-label>
            <v-text-field flat solo dense autofocus
                v-model="dataIdText"
                :rules="$fieldValidators('text', null, {required:false, limit:dataIdMaxLength})"
            />
        </fe-dialog>
        
        <fe-crud
            ref="crud"
            :config="model"
            :defaultParams="{property: 'field_revisions', form_id:template.id}"
            @read="rows=$event; loading=false"
        />
    
    </fe-dialog>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'

export default {
    name: 'DataEditor',
    inject: ['localStore'],
    
    props: {
        value: {},
        template: { required:true },
    },
    
    methods: {
        toggleShowGroup(group) {
            return this.showGroup = this.showGroup==group ? 0 : group
        },
        addModified(rec) {
            this.modified[rec.form_field_id] = rec
            this.modifiedCount++
        },
        resize() {
            this.$refs.grid?.gridApi?.sizeColumnsToFit()
        },
        onCellValueChanged(e) {
            if(e.newValue != e.oldValue) {
                this.addModified(e.data)
                e.api.redrawRows(e.node)
            }
        },
        onRowClick(e) {
            if(e.column.colId != 'form_field_data_id') {
                e.node.setSelected(!e.node.selected)
            }
        },
        onSelected(e) {
            if(e.node.selected) {
                if(!this.selections[e.node.data.form_field_id]) this.selectionCount++
                this.selections[e.node.data.form_field_id] = e.node.data
            } else {
                if(this.selections[e.node.data.form_field_id]) this.selectionCount--
                delete this.selections[e.node.data.form_field_id]
            }
        },
        rowStyling(params) {
            var rec = params.node.data
            if(rec.form_section_disabled || rec.form_field_disabled) {
                return { color: '#7E8494' }
            } else {
                return { color: 'black' }
            }
        },
        updateDataId() {
            Object.values(this.selections).forEach(x => {
                if(x.form_field_data_id != this.dataIdText) {
                    this.addModified(x)
                    x.form_field_data_id = this.dataIdText
                }
            })
            this.rows = this.rows.map(x => x)
            this.resetSelection()
            this.showGroup = 0
        },
        askReview() {
            if(this.showGroup != 2) {
                this.$messageBox({
                    title: 'Review Modified',
                    persistent: true,
                    maxWidth: '500px',
                    message: 'Would you like to review modified rows before save?',
                    actions: [{
                        text: 'Cancel',
                        usage: 'ghost',
                    }, {
                        text: 'Save',
                        onClick: this.save,
                    }, {
                        text: 'Review',
                        onClick: () => this.showGroup = 2
                    }]
                })
            } else {
                this.save()
            }
        },
        save() {
            let recs = Object.values(this.modified).map(x => ({ id:x.form_field_id, data_id:x.form_field_data_id }))
            this.$refs.crud.update(recs,{ action: 'update', property: 'data_id' }).then(r => {
                this.modified = {}
                this.modifiedCount = 0
                // this.$refs.crud.read()
            })
            this.reloadFn()
            this.$emit('input',false)
        },
        openUpdateDialog() {
            this.dataIdText = Object.values(this.selections).find(x => x.form_field_data_id)?.form_field_data_id
            this.showUpdateDialog = true
        },
        confirmClose() {
            if(this.modifiedCount==0) {
                this.$emit('input',false)
                
            } else {
                this.$messageBox({
                    title: 'Confirm Close',
                    persistent: true,
                    maxWidth: '500px',
                    message: 'You have unsaved changes, do you wish to close',
                    actions: [{
                        text: 'Cancel',
                        usage: 'ghost',
                    }, {
                        text: 'Close',
                        onClick: () => this.$emit('input',false),
                    }]
                })
            }
        },
        updateGridSelections(e) {
            e.api.forEachNode(x => this.selections[x.data.form_field_id] && x.setSelected(true))
        },
        load() {
            this.$refs.crud.read()
        },
        reset() {
            this.loading = true
            this.rows = []
            this.modifiedCount = 0
            this.modified = {}
            this.showGroup = 0
            this.resetSelection()
            this.resetFilters()
        },
        resetSelection() {
            this.$refs.grid.gridApi.deselectAll()
            this.selectionCount = 0
            this.selections = {}
        },
        resetFilters() {
            this.filters = {
                form_section_name: {},
                form_field_name: {},
                form_field_type_name: {},
                form_field_data_id: {},
            }
        },
        unique(data,key) {
            return [
                ... new Map(data.map(x => [x[key],x])).values()
            ]
        },
    },
    watch: {
        value(v) {
            !v && this.reset()
        },
    },
    computed: {
        ...mapLocalState(['reloadFn']),
        sectionNames: me => me.unique(me.rows,'form_section_name'),
        fieldNames: me => me.unique(me.rows,'form_field_name'),
        fieldTypes: me => me.unique(me.rows,'form_field_type_name'),
        dataIds: me => me.unique(me.rows.filter(x=>x.form_field_data_id.length),'form_field_data_id'),
        selectionCountText() {
            if(this.selectionCount<1) return '0 rows selected'
            if(this.selectionCount<2) return '1 row selected'
            return this.selectionCount + ' rows selected'
        },
        modifiedCountText() {
            if(this.modifiedCount<1) return '0 rows modified'
            let c = Object.keys(this.modified).length
            if(c<2) return '1 row modified'
            return c + ' rows modified'
        },
        model() {
            return {
                defaults: {
                    endpoint: 'form.php',
                },
                read: {
                    params: { action: 'get', property: 'field_revisions' },
                    rootProperty: 'field_revisions'
                },
                update: {
                    params: { action: 'update', property: 'data_id' },
                    rootProperty: 'form_fields'
                },
            }
        },
        filteredRows() {
            if(this.showGroup==1) {
                return Object.values(this.selections)
            } else if(this.showGroup==2) {
                return Object.values(this.modified)
            } else {
                let filters = {}
                for(let key in this.filters) {
                    filters[key] = this.filters[key].included?.map( x => x[key])
                }
                let filtered = this.rows.filter(x => {
                    if(!x.form_field_data_id) x.form_field_data_id = ''
                    if(!this.includeAllFields && (x.form_section_disabled==1 || x.form_field_disabled==1) && !x.response_cnt) {
                        return false
                    }
                    for(let key in filters) {
                        if(filters[key]?.length && !filters[key].includes(x[key])) return false
                    }
                    return true
                })
                return filtered
            }
        },
        columnDefs() {
            let self = this
            return [{
                minWidth: 50,
                maxWidth: 50,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                colId: 'checkbox_column'
            }, {
                headerName: 'Section Archived',
                field: 'form_section_disabled',
                maxWidth: 80,
                hide: true,
                cellRenderer: x => x.value ? 'Yes' : '',
            }, {
                headerName: 'Section Name',
                field: 'form_section_name',
            }, {
                headerName: 'Field ID',
                field: 'form_field_id',
                maxWidth: 70,
            }, {
                headerName: 'Field Archived',
                field: 'form_field_disabled',
                maxWidth: 80,
                hide: true,
                cellRenderer: x => x.data.form_section_disabled || x.data.form_field_disabled ? 'Yes' : '',
                valueGetter: x => x.data.form_section_disabled || x.data.form_field_disabled,
            }, {
                headerName: 'Field Type',
                field: 'form_field_type_name',
                maxWidth: 120,
            }, {
                headerName: 'Field Name',
                field: 'form_field_name',
            }, {
                headerName: 'Data ID',
                field: 'form_field_data_id',
                maxWidth: 160,
                editable:true,
                cellEditorFramework:'FeGridTextField',
                cellEditorParams: {
                    maxLength: 200,
                    rules: [
                        v => !this.defaultDataIds.includes(v) || 'This is a system default data ID.'
                    ]
                },
                cellRenderer: v => {
                    let style = 'font-size:6px; transform:translate(-11px,-13px)'
                    let before = self.modified[v.data.form_field_id]
                        ? `<i style="${style}" class="fe-grid-icon fas fa-circle red--text mr-2"></i>`
                        : ''
                    return before + (v.value ? v.value : '')
                },
                headerComponentParams: {
                    template: `
                        <div class="ag-cell-label-container" role="presentation">
                            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                                <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                                <span class="ag-header-icon">
                                    <span class="ag-icon far fa-pen"></span>
                                </span>
                                <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
                                <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
                                <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
                                <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
                                <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                            </div>
                        </div>
                    `
                },
            }, {
                headerName: 'Forms Created',
                field: 'response_cnt',
                maxWidth: 90,
            }, {
                headerName: 'First Form',
                field: 'min_response_created',
                maxWidth: 160,
            }, {
                headerName: 'Last Form',
                field: 'max_response_created',
                maxWidth: 160,
            }, {
                headerName: 'Field Created',
                field: 'form_field_created',
                maxWidth: 160,
                hide: true,
            }]
        }
    },
    data() {
        return {
            loading: true,
            showUpdateDialog: false,
            showGroup: 0,
            dataIdText: '',
            dataIdMaxLength: 128,
            includeAllFields: false,
            rows: [],
            selectionCount: 0,
            selections: {},
            modifiedCount: 0,
            modified: {},
            filters: {
                form_section_name: {},
                form_field_name: {},
                form_field_type_name: {},
                form_field_data_id: {},
            },
            defaultDataIds: [
                'Template', 'Form Name', 'Created', 'Created By User', 'Student', 'Student ID',
                'Student District ID', 'School', 'Grade', 'School Year', 'Inactive Date'
            ]
        }
    },
    
}
</script>

<style lang="scss" scoped>
.moveup ::v-deep button.v-btn {
    margin-top: 0;
}
</style>
