<template>
    <fe-dialog
        ref="dialog"
        :title="title"
        persistent
        width=650
        acceptButtonText="Save"
        :acceptButtonDisabled="!valid || !editValid"
        @accept="apply"
        @dismiss="$emit('close')"
    >
        <div class='d-flex flex-fill flex-column flex-grow-1 flex-shrink-1'>
            <v-form ref="strategy" v-model="valid">

                <fe-label>Strategy</fe-label>
                <v-textarea flat solo dense
                    :counter="1000"
                    v-model="obj.strategy"
                    :rules="$fieldValidators('text', null, { required: true, limit: 1000 })"
                />

                <template v-if="canEditExisting || !item">

                    <fe-remote-combo
                        label="Assignees"
                        v-model="obj.assigneeIds"
                        :url="`${$models.getUrl('user', 'read')}&active=1`"
                        multiple
                        itemText="user_full_name"
                        itemValue="id"
                        byId
                        more-text
                        :rules="rules.assignee"
                        validateOnBlur
                    />

                    <div class="d-flex">
                        <div class="self-align-center" style="position: relative; top: -4px; width: 65px;"><fe-label>Recurring</fe-label></div>
                        <div class="self-align-center" style="width: 100px;"><fe-switch v-model="isRecurring" useIcon="check" /></div>
                    </div>

                    <div v-if="isRecurring" class="d-flex">
                        <div class="flex-grow-1 flex-shrink-1">
                            <fe-label style="margin-bottom: 10px;">Every # Week(s)</fe-label>
                            <v-text-field
                                flat solo dense
                                v-model="obj.frequency"
                                :rules="rules.frequency"
                            />
                        </div>
                        <div class="ml-5 flex-grow-1 flex-shrink-1">
                            <weekday-picker v-model="obj.days" />
                            <div v-if="!daysOfWeekValid" class="v-messages theme--light error--text days-of-week-errors" role="alert">
                                <div class="v-messages__wrapper">
                                    <div class="v-messages__message">Day of week required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex">
                        <div class="pr-2 flex-grow-1 flex-shrink-1">
                            <fe-label>{{ isRecurring ? 'Ending Date' : 'Date' }}</fe-label>
                            <fe-date-picker
                                :rules="$fieldValidators('text', 'Date', { required: true })"
                                v-model="obj.date"
                                prependIcon="fa-calendar-alt"
                            />
                        </div>

                        <div class="pr-2 flex-grow-1 flex-shrink-1">
                            <fe-label>Start Time</fe-label>
                            <fe-time-picker
                                prepend-icon="access_time"
                                :allowed-minutes="allowedMinutes"
                                v-model="obj.startTime"
                                :rules="$fieldValidators('text', 'Start time', { required: true })"
                            />
                        </div>

                        <div class="pr-2 flex-grow-1 flex-shrink-1">
                            <fe-label>End Time</fe-label>
                            <fe-time-picker
                                prepend-icon="access_time"
                                :allowed-minutes="allowedMinutes"
                                v-model="obj.endTime"
                                :rules="rules.endTime"
                            />
                        </div>
                    </div>

                </template>

                <fe-crud ref="crud" :config="$models.sloStrategy" />

            </v-form>
        </div>
    </fe-dialog>

</template>

<script>
import {mapState} from 'vuex'
import WeekdayPicker from '@/components/common/button/WeekdayPicker'

export default {
        name: 'SloPanelStrategiesModal',
        props: {
            slo: { type: Object, required: true },
            item: { type: Object },
            canEditExisting: { type: Boolean, default: false },
            dryRun: { type: Boolean, default: false },
        },
        components: {
            WeekdayPicker
        },
        data() {
            return {
                isRecurring: false,
                obj: {
                    strategy: '',
                    assigneeIds: [],
                    startTime: null,
                    endTime: null,
                    frequency: null,
                    days: '',
                    date: null
                },
                allowedMinutes: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
                valid: true,
                rules: {
                    strategy: [
                        v => (!!v && v.trim().length > 0) || 'Please enter a strategy'
                    ],
                    assignee: [
                        v => (!!v && Array.isArray(v) && v.length > 0) || 'Please select an assignee'
                    ],
                    date: this.$fieldValidators('text', 'Date', { required: true }),
                    endTime: [
                        v => !this.$dayjs('2000-01-01 ' + v).isBefore('2000-01-01 ' + this.obj.startTime) || 'Must end after start time'
                    ].concat(this.$fieldValidators('text', 'End time', { required: true })),
                    frequency: [
                        (v) => { return (v &&
                            `${v}`.match(/^\s*[0-9]*\s*$/) &&
                            !isNaN(parseInt(v)) &&
                            parseInt(v) >= 1) || 'Not a valid frequency'
                        }
                    ]
                },
                daysOfWeekValid: true
            }
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            title () { return this.item ? 'Edit Strategy' : 'Add Strategy' },
            editValid () {
                return this.item
                    ? this.item.slo_strategy_text !== this.obj.strategy
                    : true
            },
            saveObj () {
                let itm = {
                    id: this.item?.id || 0,
                    dryRunId: this.item?.dryRunId || null,
                    schedule_date_created: null,
                    schedule_date_id: "SloStrategyV-1",
                    slo_plan_id: this.slo?.plan.slo_plan_id,
                    slo_strategy_users: this.obj.assigneeIds,
                    schedule_date_occurrence: null,
                    schedule_end_date: null,
                    schedule_frequency_cd: this.isRecurring ? 'WEEK' : 'DAY',
                    schedule_frequency_cnt: this.isRecurring ? this.obj.frequency : '',
                    schedule_secondary_cnt: '',
                    schedule_start_date: null,
                    schedule_week_days: this.isRecurring ? `${this.obj.days}` : '',
                    start_time: this.$dayjs('2000-01-01 ' + this.obj.startTime).format('HH:mm:ss'),
                    end_time: this.$dayjs('2000-01-01 ' + this.obj.endTime).format('HH:mm:ss'),
                    strategy_text: this.obj.strategy,
                    complete: false
                }
                itm[`${this.isRecurring ? 'strategy_end_date' : 'one_time_date'}`] = this.$dayjs(this.obj.date).format('YYYY-MM-DDT00:00:00')
                return itm
            }
        },
        mounted() {
            // if existing strategy item to be edited is given, fill in default values
            if (this.item) {
                for (let key in this.obj) {
                    this.obj[key] = this.item[key] || this.obj[key]
                }

                this.isRecurring = !!this.obj.days
                if (this.isRecurring && this.item.strategy_end_date) {
                    this.obj.date = this.$dayjs(this.item.strategy_end_date).format("YYYY-MM-DD")
                }
            }
        },
        methods: {
            validateDaysOfWeek () {
                this.daysOfWeekValid = `${this.obj.days}` != ''
                if(this.daysOfWeekValid) {
                    let arr = this.obj.days.split('')
                    let order = ['M', 'T', 'W', 'H', 'F']
                    arr.sort(function(a, b){
                        return order.indexOf(a) - order.indexOf(b)
                    })
                    this.obj.days = arr.join('')
                }
            },
            async apply () {
                if (this.item) {
                    if (this.dryRun) {
                        this.$emit('updated', this.saveObj)
                    } else {
                        // if canEditExisting is not explicitly set to true, then user can
                        // only modify the strategy text
                        let response = await this.$refs.crud.update(Object.assign({}, this.canEditExisting ? this.saveObj : this.item, {
                            text: this.obj.strategy,
                            // Don't allow any component that uses this modal to update complete status on edit
                            // The very existence of a non-null value (e.g. complete === 0) results in backend
                            // entering a completion record, and then any future attempts to edit (2nd edit, 3rd edit etc.)
                            // will all fail because a completion record already exists (backend fix for this in future?)
                            complete: null
                        }))
                        if (response.data.success) this.$emit('updated', this.saveObj)
                    }
                } else {
                    if (this.isRecurring) this.validateDaysOfWeek()
                    else this.daysOfWeekValid = true

                    if (this.$refs.strategy.validate() && this.daysOfWeekValid && this.valid) {
                        if (this.dryRun) {
                            this.$emit('created', this.saveObj)
                        } else {
                            let response = await this.$refs.crud.create(this.saveObj)
                            if (response.data.success) this.$emit('created', {
                                ...this.saveObj,
                                id: response.data.slo_strategies[0].id
                            })
                        }
                    }
                }
            }
        },
        watch: {
            'obj.days': {
                handler () { this.validateDaysOfWeek() }
            },
            'obj.startTime'(v) {
                if (v && (!this.obj.endTime || this.$dayjs('2000-01-01 ' + this.obj.endTime).isBefore('2000-01-01 ' + v))) {
                    this.obj.endTime = this.$dayjs(v, 'HH:mm').add(15, 'minutes').format('HH:mm')
                }
            },
            'item': {
                handler (v) {
                    if (v && v.slo_strategy_text) this.obj.strategy = v.slo_strategy_text
                    else this.obj.assigneeIds = [this.sessionUser.user.id]
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
