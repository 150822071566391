<template>
    <div style="height: 100%;">
        <div class="d-flex align-center">
            <h3>Logistics (optional)</h3>
        </div>

        <div v-if="!logistics.length" class="d-flex flex-fill flex-column">
            <v-layout align-center justify-center column fill-height text-center>
                <img width="149px" src="/images/no-items2.svg" />
                <br/>
                <h3>This SLO has no logistics</h3>
                <br/>
                <br/>
                <fe-btn usage="tertiary" @click="showDialog = !showDialog">Add</fe-btn>
            </v-layout>
        </div>

        <logistics
            v-else
            :slo="slo"
            :logisticTypes="logisticTypes"
            @created="$emit('created', $event)"
            @updated="$emit('updated', $event)"
            @deleted="$emit('deleted', $event)"
        />

        <logistics-modal
            v-if="showDialog"
            :slo="slo"
            :item="activeLogistic"
            @created="showDialog = false; $emit('created', $event)"
            @updated="showDialog = false; $emit('updated', $event)"
            @deleted="$emit('deleted', $event)"
            @close="showDialog = false"
            :dryRun="dryRun"
        />

        <fe-crud
            autoload
            ref="sloLogisticTypes"
            :config="$models.sloLogisticType"
            @read="logisticTypes = $event"
        />
    </div>
</template>

<script>
    import LogisticsModal from '@/components/modules/slo/panel/LogisticsModal'
    import Logistics from '@/components/modules/slo/panel/Logistics'

    export default {
        name: 'SloCreationDatawallLogistics',
        components: {
            LogisticsModal,
            Logistics,
        },
        props: {
            slo: { type: Object, required: true },
            logistics: { type: Array, required: true },
            dryRun: { type: Boolean, required: true },
        },
        data() {
            return {
                showDialog: false,
                isRecurring: true,
                localModel: {
                    slo_logistic_type_id: null,
                    description: null,
                    cost: null,
                    slo_logistic_users: [],
                },
                activeLogistic: null,
                pendingUsers: [],
                learnedUsers: [],
                logisticTypes: [],
            }
        },
        methods: {
            async onEditLogistic(logistic) {
                this.activeLogistic = logistic
                this.showDialog = true
            },
            async onDeleteLogistic(logistic) {
                this.$emit('deleted', logistic)
            },
        },
    }
</script>

<style lang="scss" scoped>
</style>