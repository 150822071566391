<template>
    <div class="center-no-enrollment-text">
        <v-icon class="far fa-analytics fe-icon" style="color: #687baa; font-size: 32px; padding: 18px;" ></v-icon>
        <div :style="{'font-weight': 'bold', 'padding': '8px'}">
            No students enrolled
        </div>
        <div>
            There are 0 students enrolled on the selected date.
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NoEnrollmentData',
    }
</script>

<style lang="scss">
.center-no-enrollment-text {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center !important;
}
</style>
