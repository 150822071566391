<template>
    <div class="flex-fill flex-column">
        <v-flex shrink>
            <fe-tabs
                :tabs="tabs"
                noPadding
                @tabClicked="selectedTab = $event"
                style=" height: 60px; "
            />
        </v-flex>
        <v-flex shrink>
            <template v-for="type in selectionTypes">
                <fe-filter-btn
                    :class="{
                        hasError: erroredFilters[type],
                        isOptional: selections[type].optional,
                        isEnabled: selections[type].available.length && !disabledFilters[type]
                    }"
                    :ref="`filter_${type}`"
                    :key="`filter-${type}`"
                    :title="selections[type].title"
                    :items="selections[type].available"
                    :itemValue="selections[type].field.id"
                    :itemText="selections[type].field.name"
                    v-model="selections[type].filtered"
                    :multiple="!!selections[type].multiple"
                    :disabled="!selections[type].available.length || disabledFilters[type]"
                    :actions="true"
                    :closeOnSelect="false"
                ></fe-filter-btn>
            </template>
            <fe-filter-btn
                class="isEnabled isActiveStatus"
                title="Active Status"
            >
                <div class="d-flex justify-space-between">
                    <div class="pt-2">
                        Student Status
                    </div>
                    <div class="ml-1">
                        <v-select
                            :items="activeStatusItems"
                            v-model="activeStatus"
                            flat solo dense
                            :style="{ maxWidth: '200px' }"
                        ></v-select>
                    </div>
                </div>
                <div class="d-flex justify-space-between">
                    <div class="pt-2">
                        <span>Active Date</span>
                    </div>
                    <div class="ml-1">
                        <fe-date-picker
                            v-model="activeAsOfDate"
                            :style="{ minWidth: '200px' }"
                        />
                    </div>
                </div>
            </fe-filter-btn>
        </v-flex>
        <v-flex grow class="pa-2" style="min-height: 350px;">
            <fe-spinner v-if="creatingDataPoint" text="Building assessment window..." />
            <fe-overlay
                :value="loading"
                loader
                text=""
            />

            <fe-grid v-if="scoreEditorParams && scoreEditorData"
                ref="grid"
                :config="$models.dataPointScoreEditor"
                :columnDefs="scoreEditorColumns"
                style="height: 100%;"
                disable-inline-create
                :showAddRowBtn="false"
                displayDensity="small"
                :flexColumns="false"
                :rowData="scoreEditorRows"
                @cellValueChanged="cellUpdated"
                @inserted="true"
                stopEditingWhenCellsLoseFocus
            >
                <template v-slot:toolbar-items>
                    <fe-remote-combo
                        :disabled="scoreEditorTags.length == 0"
                        v-model="grouping"
                        flat solo dense validateOnBlur clearable by-id
                        :items="scoreEditorTags"
                        itemText="tag"
                        itemValue="tag"
                        :placeholder="scoreEditorTags.length > 0 ? 'Grouping' : 'Grouping Unavailable'"
                    />
                </template>
                <template v-slot:selection-tools>
                    <fe-btn
                        usage="ghost"
                        useIcon="fal fa-bolt"
                        whiteText
                        @click="startQuickScore"
                        class="fe-grid-action-btn"
                    >
                        Quick Score
                    </fe-btn>
                    <fe-btn
                        usage="ghost"
                        whiteText
                        @click="cancelSelection"
                        class="fe-grid-action-btn-last"
                    >
                        Cancel
                    </fe-btn>
                </template>
            </fe-grid>
            <fe-crud
                ref="crud"
                autoload
                v-if="scoreEditorParams"
                :config="$models.dataPointScoreEditor"
                :readParams="scoreEditorParams"
                refresh-on-config
                @read="scoreEditorDataLoaded"
                :failOnEmptyResponse="false"
            />
        </v-flex>

        <fe-crud
            autoload
            :config="$models.schoolYear"
            @read="loadItems('schoolYears', $event)"
        />

        <fe-crud
            autoload
            :config="$models.assessment"
            :defaultParams="{ hidden: 0 }"
            @read="loadItems('assessments', $event)"
        />

        <fe-crud
            autoload
            ref="crudSubcategories"
            v-if="selected.assessments"
            :config="$models.subcategory"
            refresh-on-config
            :readParams="{
                data_point_type_id: selections.assessments.filtered.included[0].id,
                property: 'parent'
            }"
            @read="loadItems('subcategories', $event)"
        />

        <fe-crud
            autoload
            ref="crudWindows"
            v-if="selected.assessments"
            :config="$models.dataPointName"
            refresh-on-config
            :readParams="{
                data_point_type_id: selections.assessments.filtered.included[0].id
            }"
            @read="loadItems('windows', $event)"
        />

        <fe-crud
            autoload
            disable-snackbars
            ref="crudDataPoints"
            v-if="dataPointParams"
            :config="$models.dataPoint"
            refresh-on-config
            refresh-on-created
            :readParams="dataPointParams"
            @read="validateDataPoint"
        />

        <fe-crud
            autoload
            disable-snackbars
            ref="crudSchools"
            v-if="byGrade && selected.schoolYears"
            :config="$models.school"
            refresh-on-config
            :readParams="{
                school_year_id: selected.schoolYears.id
            }"
            @read="loadItems('schools', $event)"
        />

        <fe-crud
            autoload
            disable-snackbars
            ref="crudGrades"
            v-if="byGrade && selected.schools"
            :config="$models.grade"
            refresh-on-config
            :readParams="{
                school_year_id: selected.schoolYears.id,
                school_id: selected.schools.id
            }"
            @read="loadItems('grades', $event)"
        />

        <fe-crud
            autoload
            disable-snackbars
            ref="crudUsers"
            v-if="byCourse && selected.schoolYears"
            :config="$models.courseHistory"
            refresh-on-config
            :readParams="{
                school_year_id: selected.schoolYears.id,
                property: 'user_id'
            }"
            @read="loadItems('users', $event)"
        />

        <fe-crud
            autoload
            disable-snackbars
            ref="crudCourses"
            v-if="byCourse && selected.schoolYears && selected.users"
            :config="$models.courseHistory"
            refresh-on-config
            :readParams="{
                school_year_id: selected.schoolYears.id,
                user_id: selected.users.user_id,
                property: 'course_id'
            }"
            @read="loadItems('courses', $event)"
        />

        <fe-crud
            autoload
            disable-snackbars
            ref="crudPeriods"
            v-if="byCourse && selected.schoolYears && selected.users && selected.courses"
            :config="$models.courseHistory"
            refresh-on-config
            :readParams="{
                school_year_id: selected.schoolYears.id,
                user_id: selected.users.user_id,
                course_id: selected.courses.course_id,
                property: 'period'
            }"
            @read="loadItems('periods', $event)"
        />

        <fe-crud
            ref="crudScoreDetailOptions"
            :config="$models.scoreDetailTypeOption"
        />

        <fe-crud
            autoload
            disable-snackbars
            ref="crudTags"
            v-if="tagParams"
            :config="$models.tag"
            refresh-on-config
            :readParams="tagParams"
            @read="loadItems('tags', $event)"
        />

        <fe-dialog
            v-if="createDataPoint"
            persistent
            title="Confirm New Test Window"
            body="This window does not currently exist.  Are you sure that you want to create and enter scores for this test window?"
            acceptButtonText="Continue"
            dismissButtonText="Cancel"
            @accept="buildDataPoint"
            @dismiss="cancelDataPoint"
            @close="cancelDataPoint"
        />

        <fe-dialog
            v-if="scoreEditorColumns.length && quickScoreRows.length"
            title="Quick Score"
            @dismiss="quickScoreRows = []"
            @close="quickScoreRows = []"
            @accept="applyQuickScore"
            dismissButtonText="Cancel"
            acceptButtonText="Save"
            :acceptButtonDisabled="!quickScoreValid"
            persistent
        >
            <v-form @submit.prevent>
                <div class="d-flex flex-column">
                    <v-flex mt-2>
                        <fe-switch
                            label="Overwrite Existing Values"
                            v-model="quickScoreOverwrite"
                        />
                    </v-flex>
                    <template v-for="(itm, idx) in scoreEditorData.columns">
                        <v-flex v-if="itm.scoreDetailTypeId && !itm.hidden && getScoreDetailOptions(itm.scoreDetailTypeId).length" :key="`col-${idx}`">
                            <fe-remote-combo
                                :label="stripHtml(itm.text)"
                                v-model="quickScoreObj[itm.dataIndex]"
                                flat solo dense validateOnBlur clearable by-id
                                :items="getScoreDetailOptions(itm.scoreDetailTypeId)"
                                itemText="value"
                                itemValue="value"
                            />
                        </v-flex>
                        <v-flex v-else-if="itm.scoreDetailTypeId && !itm.hidden && !getScoreDetailOptions(itm.scoreDetailTypeId).length" :key="`col-${idx}`">
                            <fe-label>{{ stripHtml(itm.text) }}</fe-label>
                            <v-text-field
                                v-model="quickScoreObj[itm.dataIndex]"
                                flat solo dense validateOnBlur
                                :style="{ width: '100%' }"
                            />
                        </v-flex>
                        <v-flex v-else-if="itm.xtype == 'alphamapcolumn' && alphaMap && itm.score_alpha_map_flag" :key="`col-${idx}`">
                            <fe-remote-combo
                                :label="stripHtml(itm.text)"
                                v-model="quickScoreObj[itm.dataIndex]"
                                flat solo dense validateOnBlur clearable by-id
                                :items="alphaMap.maps"
                                itemText="alpha_score"
                                itemValue="numeric_score"
                            />
                        </v-flex>
                        <v-flex v-else-if="itm.dataIndex != 'student_full_name' && !itm.hidden" :key="`col-${idx}`">
                            <fe-label>{{ stripHtml(itm.text) }}</fe-label>
                            <v-text-field
                                v-model="quickScoreObj[itm.dataIndex]"
                                flat solo dense validateOnBlur
                                :style="{ width: '100%' }"
                            />
                        </v-flex>
                    </template>
                </div>
            </v-form>
        </fe-dialog>
    </div>
</template>

<script>
    export default {
        name: 'Index',
        props: {
            window: { type: Object, default: null }
        },
        data () {
            return {
                tabbed: false,
                resettingValue: false,
                loading: false,
                selectedTab: null,
                windowLoaded: false,
                tabs: [
                    { 'name': 'By Grade', path: '#', id: 'grade' },
                    { 'name': 'By Course', path: '#', id: 'course' }
                ],
                selections: {
                    schoolYears: {
                        field: { id: 'id', name: 'name' },
                        title: 'School Year',
                        available: [],
                        filtered: { included: [], excluded: [] }
                    },
                    assessments: {
                        field: { id: 'id', name: 'name' },
                        title: 'Assessment Group',
                        available: [],
                        filtered: { included: [], excluded: [] }
                    },
                    subcategories: {
                        field: { id: 'id', name: 'name' },
                        title: 'Assessment',
                        available: [],
                        filtered: { included: [], excluded: [] }
                    },
                    windows: {
                        field: { id: 'id', name: 'name' },
                        title: 'Window',
                        available: [],
                        filtered: { included: [], excluded: [] }
                    },
                    schools: {
                        field: { id: 'id', name: 'name' },
                        title: 'School',
                        available: [],
                        filtered: { included: [], excluded: [] },
                        tab: 'grade'
                    },
                    grades: {
                        field: { id: 'id', name: 'name' },
                        title: 'Grade',
                        available: [],
                        filtered: { included: [], excluded: [] },
                        tab: 'grade'
                    },
                    users: {
                        field: { id: 'user_id', name: 'user_full_name' },
                        title: 'User',
                        available: [],
                        filtered: { included: [], excluded: [] },
                        tab: 'course'
                    },
                    courses: {
                        field: { id: 'course_id', name: 'course_name' },
                        title: 'Course',
                        available: [],
                        filtered: { included: [], excluded: [] },
                        tab: 'course'
                    },
                    periods: {
                        field: { id: 'period', name: 'period' },
                        title: 'Period',
                        available: [],
                        filtered: { included: [], excluded: [] },
                        multiple: true,
                        tab: 'course',
                        optional: true
                    },
                    tags: {
                        field: { id: 'id', name: 'name' },
                        title: 'Student Tags',
                        available: [],
                        filtered: { included: [], excluded: [] },
                        optional: true
                    }
                },
                dataPoints: [],
                createDataPoint: false,
                creatingDataPoint: false,
                scoreEditorData: null,
                scoreDetailOptions: null,
                quickScoreRows: [],
                quickScoreValid: true,
                quickScoreObj: {},
                quickScoreOverwrite: false,
                selectedGrouping: null,
                newScoreIds: [],
                invalidCellKeys: [],
                erroredCellKeys: [],
                activeAsOfDate: null,
                activeStatus: null,
                activeStatusItems: [
                    { text: 'Inactive', value: 0 },
                    { text: 'Active', value: 1 },
                    { text: 'Both', value: null },
                ],
            }
        },
        computed: {
            grouping: {
                get () { return this.scoreEditorTags.find(tag => tag.tag == this.selectedGrouping) },
                set (v) { this.selectedGrouping = v ? v : null }
            },
            tab () { return this.selectedTab || this.tabs[0] },
            byGrade () { return this.tab.id === 'grade' },
            byCourse () { return this.tab.id === 'course' },
            currentYear () { return this.$store.state.global.sessionUser.currentYear },
            alphaMap () {
                let globalAlpha = this.$_.cloneDeep(this.$store.state.global.alphaMaps)
                let map = (globalAlpha && this.selected.assessments && this.selected.subcategories)
                    ? globalAlpha.find(itm => itm.data_point_type_id == this.selected.assessments.id)
                    : null
                if (map && map.exclusions.indexOf(this.selected.subcategories.id) == -1) {
                    map.maps = map.maps.filter(itm => itm.id != this.selected.subcategories.id)
                }
                return map ? map : null
            },
            formattedAlphaMap () {
                return this.alphaMap
                    ? Object.assign({}, this.alphaMap, { maps: this.alphaMap.maps.map(itm => {
                        return Object.assign({}, itm, { formatted_score: `${itm.numeric_score}` })
                    })})
                    : null
            },
            selectionTypes () {
                return Object.keys(this.selections).filter(type => {
                    return !this.selections[type].tab || this.selections[type].tab == this.tab.id
                })
            },
            selected () {
                let obj = {}
                this.selectionTypes.forEach(type => {
                    if (this.selections[type].filtered.included.length) {
                        obj[type] = (this.selections[type].multiple)
                            ? this.selections[type].filtered.included
                            : this.selections[type].filtered.included[0]
                    } else {
                        obj[type] = null
                    }
                })
                return obj
            },
            hasErrors () { return Object.keys(this.erroredFilters).length > 0 },
            disabledFilters () {
                return {
                    subcategories: !this.selected.assessments,
                    windows: !this.selected.assessments,
                    tags: !this.tagParams
                }
            },
            erroredFilters () {
                let obj = {}
                this.selectionTypes.forEach(type => {
                    if (!this.selections[type].optional && !this.selected[type] && (!this.selections[type].tab || this.selections[type].tab == this.tab.id)) obj[type] = true
                })
                return obj
            },
            tagParams () {
                if (this.byGrade && this.selected.schoolYears && this.selected.grades) {
                    return {
                        school_year_id: this.selected.schoolYears.id,
                        grade_id: this.selected.grades.id
                    }
                } else if (this.byCourse && this.selected.schoolYears && this.selected.users) {
                    return {
                        school_year_id: this.selected.schoolYears.id,
                        user_id: this.selected.users.id
                    }
                } else {
                    return null
                }
            },
            dataPointParams () {
                if (!this.selected.schoolYears ||
                    !this.selected.assessments ||
                    !this.selected.subcategories ||
                    !this.selected.windows) return null

                return {
                    data_point_type_id:this.selected.assessments.id,
                    sub_category_id: this.selected.subcategories.id,
                    data_point_name_id: this.selected.windows.id,
                    school_year_id: this.selected.schoolYears.id,
                    active: this.activeStatus,
                    active_date: this.activeAsOfDate
                }
            },
            dataPoint () {
                return (Array.isArray(this.dataPoints) && this.dataPoints.length)
                    ? this.dataPoints[0]
                    : null
            },
            scoreEditorParams () {
                if (this.hasErrors || !this.dataPoint) return null
                let obj = {
                    data_point_id: this.dataPoint.id,
                    school_year_id: this.selected.schoolYears.id,
                    tag_id: this.selected.tags ? this.selected.tags.id : null,
                    active: this.activeStatus,
                    active_date: this.activeAsOfDate
                }
                if (this.byGrade) {
                    obj.grade_id = this.selected.grades.id
                    obj.school_id = this.selected.schools.id
                } else if (this.byCourse) {
                    obj.user_id = this.selected.users.user_id
                    obj.course_id = this.selected.courses.course_id
                    obj.period = this.selected.periods ? this.selected.periods.map(pd => pd.period).join('+') : null
                }
                return obj
            },
            scoreEditorColumns () {
                let me = this
                let cols = []
                if (this.scoreEditorParams && this.scoreEditorData && this.scoreDetailOptionsLoaded && this.scoreEditorData.columns) {
                    cols.push({
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        colId: 'checkbox-column',
                        maxWidth: 70,
                        minWidth: 70,
                        pinned: 'left'
                    })
                    this.scoreEditorData.columns.forEach(itm => {
                        let col = {
                            headerName: this.stripHtml(itm.text),
                            field: itm.dataIndex,
                            pinned: itm.locked ? 'left' : false,
                            width: itm.width ? itm.width*1.5 : 90,
                            hide: itm.hidden || this.filteredOutByGrouping(itm),
                            valueGetter: (params) => {
                                let val = params.data[itm.dataIndex]
                                return (val != undefined && val != null && `${val}`.trim() != "")
                                    ? val
                                    : null
                            },
                            valueFormatter: params => { return params.value?.toString() },
                            editable: (params) => {
                                let wasTabbed = this.tabbed
                                this.tabbed = false
                                if (!itm.xtype) {
                                    return false
                                } else if (itm.isCalculated) {
                                    this.$snackbars.$emit('new', {
                                        text: 'Score Locked',
                                        subtext: 'This score is automatically calculated from child scores',
                                        usage: 'warning'
                                    })
                                    return false
                                } else if (itm.xtype == 'scoredetailcolumn') {
                                    if (params.data.score != undefined && params.data.score != null && `${params.data.score}`.trim() != "") {
                                        return true
                                    } else {
                                        if (!wasTabbed) {
                                            this.$snackbars.$emit('new', {
                                                text: 'Invalid Parent Score',
                                                subtext: 'Score details require a parent score',
                                                usage: 'warning'
                                            })
                                        }
                                        return false
                                    }
                                } else {
                                    return true
                                }
                            },
                            sortable: true,
                            cellClass: itm.tdCls ? itm.tdCls : 'score-detail-unstyled',
                            cellStyle: (params) => {
                                let css = (
                                    me.invalidCellKeys.includes(me.getParamNodeKey(params)) ? { backgroundColor: '#f9f68e' }
                                    : me.erroredCellKeys.includes(me.getParamNodeKey(params)) ? { backgroundColor: '#f98e8e' }
                                    : itm.tdCls == 'score-detail-column' ? { backgroundColor: '#e5f0f4' }
                                    : itm.tdCls == 'child-sub-category-column' ? { backgroundColor: '#80B6C7' }
                                    : { backgroundColor: 'transparent' }
                                )

                                return css
                            },
                        }

                        if (itm.dataIndex == 'student_full_name') col.sort = 'asc'

                        if (itm.xtype == 'alphamapcolumn' && this.getAlphaMapOfItm(itm)) {
                            col.valueGetter = (v)  => {
                                let val = v.data[itm.dataIndex]
                                return (val !== null)
                                    ? this.getAlphaForNumeric(val, itm)
                                    : v.val
                            }
                            col.cellEditorFramework = 'FeGridChipSelect'
                            col.cellEditorParams = {
                                rowDataKey: itm.dataIndex,
                                mode: 'edit',
                                items: this.formattedAlphaMapOfItm(itm)?.maps || [],
                                keyProp: this.alphaMap ? 'alpha_score' : 'numeric_score',
                                labelProp: 'alpha_score',
                                disableChips: true,
                                insertable: true
                            }
                        }

                        if (itm.xtype == 'scoredetailcolumn') {
                            let sdopts = this.getScoreDetailOptions(itm.scoreDetailTypeId)
                            if (sdopts && sdopts.length > 0) {
                                col.cellEditorFramework = 'FeGridChipSelect'
                                col.cellEditorParams = {
                                    rowDataKey: itm.dataIndex,
                                    mode: "edit",
                                    items: sdopts,
                                    keyProp: 'value',
                                    labelProp: 'value',
                                    disableChips: true
                                }
                            }
                        }

                        cols.push(col)
                    })
                }

                cols = cols.map(itm => ({
                    ...itm,
                    colId: itm.colId || itm.field || undefined
                }))

                return cols
            },
            scoreEditorTags () {
                let results = []
                if (this.scoreEditorParams && this.scoreEditorData && this.scoreEditorData.columns) {
                    this.scoreEditorData.columns.forEach(itm => {
                        if (itm.score_editor_tag) {
                            `${itm.score_editor_tag}`.split(',').forEach(tag => {
                                if (`${tag}`.trim() != '' && results.indexOf(tag) == -1) results.push(tag)
                            })
                        }
                    })
                }
                return results.map(tag => { return { tag: tag } })
            },
            scoreEditorRows () {
                return (this.scoreEditorParams && this.scoreEditorData && this.scoreEditorData.storeData)
                    ? this.scoreEditorData.storeData
                    : []
            },
            selectedRows () {
                return this.$refs.grid
                    ? this.$refs.grid.selectedRows()
                    : []
            },
            scoreDetailOptionsLoaded () {
                return (this.scoreDetailOptions && !this.scoreDetailOptions.filter(itm => !itm).length)
            }
        },
        methods: {
            scoreEditorDataLoaded (itms) {
                this.scoreEditorData = itms
                this.loadScoreDetailOptions()
            },
            loadScoreDetailOptions () {
                if (Object.keys(this.scoreEditorData).length) {
                    this.scoreDetailOptions = this.scoreEditorData.score_detail_names.map(itm => null)
                    this.scoreEditorData.score_detail_names.forEach((itm, idx) => {
                        this.$refs.crudScoreDetailOptions.read({ score_detail_type_id: itm.id }).then((rsp) => {
                            this.scoreDetailOptions = this.scoreDetailOptions.map((mitm, midx) => {
                                return (midx == idx)
                                    ? rsp.data.options
                                    : mitm
                            })
                        })
                    })
                }
            },
            getScoreDetailOptions (sdtid) {
                let sdtidx = null
                let result = this.scoreEditorData.score_detail_names.forEach((itm, idx) => {
                    if (itm.id == sdtid) sdtidx = idx
                })
                return (this.scoreDetailOptions && this.scoreDetailOptions[sdtidx])
                    ? this.scoreDetailOptions[sdtidx]
                    : []
            },
            cancelSelection () {
                this.$refs.grid.editCancel()
            },

            // Generate a unique key for a given cell (row and column)
            getParamNodeKey (params) {
                return `${params.node?.id}-${params.column?.colId}`
            },
            filteredOutByGrouping (col) {
                if (!this.grouping || col.dataIndex == 'student_full_name') {
                    return false
                } else if (col.score_editor_tag && col.score_editor_tag.indexOf(this.grouping.tag) >= 0) {
                    return false
                } else {
                    return true
                }
            },
            colInfo (key) {
                return this.scoreEditorData.columns.find(itm => itm.dataIndex == key)
            },
            fieldInfo (key) {
                return this.scoreEditorData.fields.find(itm => itm.name == key)
            },
            editNextCell(params) {
                this.tabbed = true
                let visibleKeys = params.columnApi.columnController.displayedColumns.map(itm => itm.colId)
                let colKeys = this.scoreEditorData.columns
                    .filter(itm => !itm.hidden && !itm.locked && !itm.isCalculated && visibleKeys.indexOf(itm.dataIndex) >= 0)
                    .map(itm => itm.dataIndex)
                let rowIndex = params.rowIndex
                let colKey = colKeys[0]
                let colIdx = colKeys.indexOf(params.column.colId)
                if (colIdx >= 0) {
                    if (colIdx === colKeys.length-1) {
                        rowIndex++
                    } else if (colKeys.indexOf(params.column.colId) !== -1) {
                        colKey = colKeys[colIdx+1]
                    }
                    params.api.startEditingCell({
                        rowIndex: rowIndex,
                        colKey: colKey
                    })
                }
            },
            validateCell (params) {
                let field = params.colDef.field
                let col = this.colInfo(field)
                let fld = this.fieldInfo(field)
                let result = {
                    valid: true,
                    msg: null,
                    value: params.data[field],
                    data: this.$_.cloneDeep(params.data),
                }

                // Alpha-mapped scores get translated behind the scenes at runtime (when updated),
                // but the ag-grid data does NOT get informed of the update.  Consequentially,
                // if a user enters an A that maps to PKID=1, and then for another child assessment
                // in the next column they enter a B that maps to PKID=2, then params.data is going
                // to have a valid of "A" still for the first entry, and that value isn't going to
                // be properly translated to the PKID because this validateCell operation is only
                // for the second column (for the "B" score).
                //
                // The adjustment here is to delete out all other scid- scores except for the
                // specific subcat being edited.  The backend doesn't care if we send one scid,
                // two, or all - so we're just going to send the one, which will allow all of the
                // others to be preserved as they were when their score was originally entered.
                for (let key of Object.keys(result.data)) {
                    if (key.substring(0, 5) == 'scid-' && key != field) {
                        delete result.data[key]
                    }
                }

                if (col.xtype == 'alphamapcolumn' && (!result.value || `${result.value}`.trim() == '')) {
                    result.data[field] = null
                    result.value = null
                } else if (col.xtype == 'alphamapcolumn' && col.score_alpha_map_flag !== 0 && this.alphaMap) {
                    let match = false
                    this.formattedAlphaMap.maps.forEach(itm => {
                        if (typeof result.value == 'string' && `${itm.formatted_score}` == `${result.value}`) {
                            result.data[field] = itm.numeric_score
                            match = true
                        } else if (typeof result.value == 'string' && `${itm.alpha_score}`.toUpperCase() == `${result.value}`.toUpperCase()) {
                            result.data[field] = itm.numeric_score
                            match = true
                        } else if (typeof result.value == 'number' && parseFloat(itm.numeric_score) == result.value) {
                            result.data[field] = itm.numeric_score
                            match = true
                        }
                    })
                    if (result.value !== null && result.value !== false && !match) {
                        result.valid = false
                        result.msg = 'You have entered an invalid score for this item.'
                    }
                } else if (col.xtype == 'alphamapcolumn' && isNaN(result.value)) {
                    result.valid = false
                    result.msg = 'You have entered an invalid score for this item.'
                } else if (fld && fld.type == 'integer' && isNaN(result.value)) {
                    result.valid = false
                    result.msg = 'This field requires an integer.'
                }

                let newId = this.searchNewScoreId(params.data)
                if (newId) result.data.data_point_score_id = newId

                return result
            },
            cellUpdated (params) {
                let me = this
                let col = this.colInfo(params.colDef.field)
                if (this.resettingValue) {
                    this.resettingValue = false
                } else if (col && !col.isCalculated && params.newValue != params.oldValue) {
                    // edited test_date of '' is read as a date and set to 1969-12-31;
                    // therefore must force it to null to keep the cell empty
                    if (params.data?.test_date === "") {
                        params.data.test_date = null
                    }

                    let validation = this.validateCell(params)
                    let paramNodeKey = this.getParamNodeKey(params)

                    if (validation.valid) {
                        let hadErrorState = (this.invalidCellKeys.includes(paramNodeKey) || this.erroredCellKeys.includes(paramNodeKey))

                        me.invalidCellKeys = me.invalidCellKeys.filter(item => item !== paramNodeKey)
                        me.erroredCellKeys = me.erroredCellKeys.filter(item => item !== paramNodeKey)
                        this.loading = true
                        this.$refs.crud.update(validation.data).then(rsp => {
                            if (rsp.data.success && Array.isArray(rsp.data.score_editor)) {
                                this.editNextCell(params)

                                this.resettingValue = true
                                params.node.setDataValue('score', rsp.data.score_editor[0].score)
                                // update calculated subcategories
                                for (const key in rsp.data.score_editor[0]) {
                                    if (/^scid-\d+$/.test(key) && this.colInfo(key).isCalculated) {
                                        params.node.setDataValue(key, rsp.data.score_editor[0][key])
                                    }
                                }

                                me.recordNewScoreId(validation.data, rsp.data.score_editor[0])

                                // The behavior of the events and sequencing of ag grid updates
                                // is such that we must manually refresh cells to successfully
                                // apply cellStyle updates that indicate the cell has exited error state
                                if (hadErrorState) {
                                    params.api.refreshCells({ force: true, suppressFlash: true }) // no point refreshing if no error existed (waste of repaint)
                                }
                            } else if (!rsp.data.success) {
                                me.erroredCellKeys = [me.getParamNodeKey(params), me.erroredCellKeys.filter(item => item !== me.getParamNodeKey(params))]

                                params.node.setData({
                                    ...params.data,
                                    [params.colDef.field]: params.oldValue,
                                })

                                params.api.refreshCells({ force: true, suppressFlash: true })
                            }
                        }).finally(() => {
                            this.loading = false
                        })

                    } else {
                        this.invalidCellKeys = [paramNodeKey, ...me.invalidCellKeys.filter(item => item !== paramNodeKey)]
                        this.erroredCellKeys = me.erroredCellKeys.filter(item => item !== paramNodeKey)

                        this.resettingValue = true
                        params.node.setDataValue(params.colDef.field, params.oldValue)

                        this.$snackbars.$emit('new', {
                            text: 'Invalid Entry',
                            subtext: validation.msg,
                            usage: 'warning'
                        })

                        params.api.refreshCells({ force: true, suppressFlash: true })
                    }
                }
            },
            recordNewScoreId (sent, returned) {
                if (sent.data_point_score_id !== returned.data_point_score_id) {
                    this.newScoreIds.push({
                        student_id: sent.student_id,
                        data_point_id: sent.data_point_id,
                        data_point_score_id: returned.data_point_score_id
                    })
                }
            },
            searchNewScoreId (data) {
                let id = null
                this.newScoreIds.filter(itm => {
                    return data.student_id == itm.student_id && data.data_point_id == itm.data_point_id
                }).forEach(itm => {
                    id = itm.data_point_score_id
                })
                return id
            },
            resetNewScoreIds () {
                this.newScoreIds = []
            },
            stripHtml (string) {
                var tmp = document.createElement("DIV")
                tmp.innerHTML = string.replace(/\</g, " <")
                return tmp.textContent || tmp.innerText || ""
            },
            assessmentChanged (item) {
                this.clearFilter('subcategories')
                this.clearFilter('windows')
            },
            schoolChanged (item) {
                this.clearFilter('grades')
            },
            userChanged (item) {
                this.clearFilter('courses')
                this.clearFilter('periods')
            },
            courseChanged (item) {
                this.clearFilter('periods')
            },
            clearFilter (filter) {
                this.selections[filter].filtered = { included: [], excluded: [] }
                this.$refs[`filter_${filter}`][0].clear()
            },
            validateDataPoint (items) {
                this.creatingDataPoint = false
                this.createDataPoint = false
                this.dataPoints = items
                if (this.dataPoint) {
                    this.resetNewScoreIds()
                    this.$snackbars.$emit('new', {
                        text: 'Window Exists',
                        subtext: 'This assessment window is open and ready for scores',
                        usage: 'success'
                    })
                } else {
                    this.createDataPoint = true
                }
            },
            buildDataPoint () {
                this.creatingDataPoint = true
                this.$refs.crudDataPoints.create(this.dataPointParams).then(rsp => {
                    this.$refs.crudDataPoints.read()
                })
            },
            cancelDataPoint () {
                if (!this.creatingDataPoint) this.clearFilter('windows')
                this.createDataPoint = false
            },
            loadItems (type, data) {
                let sel = null
                if (type == 'schoolYears') sel = this.currentYear
                if (this.window) {
                    if (type == 'schoolYears') {
                        if (this.window.school_year_id) {
                            data = data.filter(itm => itm.id == this.window.school_year_id)
                            if (data.length) sel = data[0]
                        }
                    } else if (type == 'assessments') {
                        if (this.window.data_point_type_id) {
                            data = data.filter(itm => itm.id == this.window.data_point_type_id)
                            if (data.length) sel = data[0]
                        }
                    } else if (type == 'subcategories') {
                        if (this.window.sub_category_id && Array.isArray(this.window.sub_category_id) && this.window.sub_category_id.length > 0) {
                            data = data.filter(itm => this.window.sub_category_id.indexOf(itm.id) >= 0)
                            if (data.length == 1) sel = data[0]
                        }
                    } else if (type == 'windows') {
                        if (this.window.data_point_name_id) {
                            data = data.filter(itm => itm.id == this.window.data_point_name_id)
                            if (data.length) sel = data[0]
                        }
                    } else if (type == 'schools') {
                        if (this.window.school_id && Array.isArray(this.window.school_id) && this.window.school_id.length > 0) {
                            data = data.filter(itm => this.window.school_id.indexOf(itm.id) >= 0)
                            if (data.length == 1) sel = data[0]
                        }
                    } else if (type == 'grades') {
                        if (this.window.grade_id && Array.isArray(this.window.grade_id) && this.window.grade_id.length > 0) {
                            data = data.filter(itm => this.window.grade_id.indexOf(itm.id) >= 0)
                            if (data.length == 1) sel = data[0]
                        }
                    }
                }
                this.selections[type].available = data
                if (sel) this.$nextTick(() => { this.$refs[`filter_${type}`][0].selectItem(sel, true) })
            },
            validateAlpha (val) {
                return (this.alphaMap && this.alphaMap.maps.find(itm => itm.alpha_score.toUpperCase() == val.toUpperCase()))
                    ? val.toUpperCase()
                    : false
            },
            getAlphaForNumeric (numeric, item) {
                if (isNaN(numeric)) return numeric
                let val = Math.floor(numeric)
                let match = this.getAlphaMapOfItm(item) && this.getAlphaMapOfItm(item).maps.find(itm => itm.numeric_score == val)
                if (match) {
                    return match.alpha_score.toUpperCase()
                }
                // removing else because if parent is alpha and not within range of scores, it will return numeric
                // but to match with classic, we just want the parent cell to be empty if no matching alpha value
                // else {
                     // return (val %1 != 0) ? val.toFixed(1) : numeric
                // }
            },
            startQuickScore () {
                this.quickScoreRows = (this.$refs.grid)
                    ? [].concat(this.$refs.grid.selectedRows())
                    : []
                if (this.quickScoreRows.length == 0) {
                    this.$snackbars.$emit('new', {
                        text: 'No Rows Selected',
                        subtext: 'You must select some rows to quick score.',
                        usage: 'warning'
                    })
                }
            },
            applyQuickScore () {
                let values = {}
                let updates = []
                Object.keys(this.quickScoreObj).forEach(key => {
                    if ((this.quickScoreObj[key] || this.quickScoreObj[key] == 0) && `${this.quickScoreObj[key]}`.trim() != '') {
                        values[key] = this.quickScoreObj[key]
                    }
                })
                this.quickScoreRows.forEach(row => {
                    if (this.quickScoreOverwrite) {
                        updates.push(Object.assign({}, row, values))
                    } else {
                        let obj = Object.assign({}, row)
                        Object.keys(values).forEach(key => {
                            if (!obj[key] && obj[key] != 0) obj[key] = values[key]
                        })
                        updates.push(obj)
                    }
                })

                this.$refs.crud.update(updates).then(rsp => {
                    if (rsp.data.success && Array.isArray(rsp.data.score_editor)) {
                        this.quickScoreRows = []
                        this.quickScoreObj = {}
                        this.$refs.crud.read()
                    }
                })

            },
            getAlphaMapOfItm (item) {
                // don't want to use computed alphaMap property because it only looks at parent
                // with this.selected.subcategories.id and children should be checked separately
                // if they should show score display
                let map = (this.$store.state.global.alphaMaps && this.selected.assessments && this.selected.subcategories)
                    ? this.$store.state.global.alphaMaps.find(itm => itm.data_point_type_id == this.selected.assessments.id)
                    : null
                return (map && map.exclusions.indexOf(item.sub_category_id) == -1) ? map : null
            },
            formattedAlphaMapOfItm (item) {
                return this.getAlphaMapOfItm(item)
                    ? Object.assign({}, this.getAlphaMapOfItm(item), { maps: this.getAlphaMapOfItm(item).maps.map(itm => {
                            return Object.assign({}, itm, { formatted_score: `${itm.numeric_score}` })
                        })})
                    : null
            },
        },
        watch: {
            'selections.assessments.filtered.included' (v) {
                this.assessmentChanged(v)
            },
            'selections.schools.filtered.included' (v) {
                this.schoolChanged(v)
            },
            'selections.users.filtered.included' (v) {
                this.userChanged(v)
            },
            'selections.courses.filtered.included' (v) {
                this.courseChanged(v)
            }
        }
    }
</script>

<style lang="scss">
    span[feid=FeFilterBtn].isOptional.isEnabled {
        button.fe-filter-btn-btn.v-btn {
            box-shadow: inset 0 0 5px 0px rgba(#ccc, 0.5) !important;
        }
    }
    .ag-cell-edit-input {
        text-indent: 16px;
    }
    span[feid=FeFilterBtn].isActiveStatus {
        button.fe-filter-btn-btn.v-btn {
            background: var(--primary-color-light2)!important;
            border-color: transparent!important;
            .v-btn__content {
                color: var(--primary-color-dark)!important;
            }
        }
    }
</style>
