<template>
    <div
        class="d-flex"
        ref="smartFormContainer"
        style="border-top: 1px solid #ECEDF1; padding-right: 4px; overflow:hidden;"
    >

        <fe-dialog
            v-if="collectionDialog.show"
            title="Add to Collection"
            :acceptButtonDisabled="!collectionDialog.record"
            @dismiss="collectionDialog.show = false"
            @accept="addToCollection"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            persistent
            @close="collectionDialog.show=false"
        >
            <v-form @submit.prevent>
                <fe-label>Collection</fe-label>
                <fe-remote-combo
                    :url="$models.getUrl('collection', 'read')"
                    itemText="title"
                    itemValue="id"
                    rootProperty="dashboards"
                    v-model="collectionDialog.record"
                    :rules="$fieldValidators('select', 'Collection', { required: true })"
                >
                    <template v-slot:item="data">
                        <v-icon :color="data.item.color" class="mr-1" small>fas fa-square</v-icon>
                        {{ data.item.title }}
                    </template>
                </fe-remote-combo>

            </v-form>
        </fe-dialog>

        <share-form-dialog v-if="showShareDialog" :formInstance="formInstance" @close="showShareDialog=false"/>

        <div class="flex-fill flex-column" style="background-color:white; overflow: hidden;">
            <link-form v-model="linkForm.show" :rec="formInstance"/>
            <div v-show="tooltip.length" ref="tooltip" class="sf-tooltip" :data-tip="tooltip"></div>

            <!-- toolbar -->
            <div v-if="!hideTools" class="d-flex">
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <fe-btn usage="tertiary" class="ml-1" v-on="on" :disabled="noEditPermission">Set Status</fe-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="(s,i) in statuses" :key="`status-`+i" @click="setStatus(s)">
                            <v-list-item-avatar v-if="formInstance">
                                <v-icon v-if="formInstance.form_instance_status_id === s.id">
                                    fas fa-check
                                </v-icon>
                            </v-list-item-avatar>
                            {{ s.name }}
                        </v-list-item>

                        <v-list-item v-if="formInstance && formInstance.form_instance_status_id" @click="setStatus(null)">
                            <v-list-item-avatar>
                                <v-icon>fas fa-times</v-icon>
                            </v-list-item-avatar>
                            Clear Status
                        </v-list-item>
                    </v-list>

                </v-menu>

                <fe-btn usage="primary" v-if="isDirty && formInstance.subscribers > 0" @click="$refs.sidebar.showNotify(); isDirty=false">Notify Subscribers</fe-btn>

                <fe-icon-btn small class="ml-auto" useIcon="fas fa-thumbtack" @click="collectionDialog.show=true; collectionDialog.record=null"/>
                <fe-icon-btn small useIcon="fas fa-link" @click="linkForm.show=true"/>
                <fe-tooltip :disabled="checkOwner" tooltip="Only the owner of this form and users with share permission have permission to share it.">
                    <fe-icon-btn small useIcon="fas fa-user-plus" @click="showShareDialog=true" @close="showShareDialog=false" :disabled="!checkOwner" />
                </fe-tooltip>
                <fe-icon-btn small useIcon="print" @click="downloadPDF"/>
            </div>

            <div class="sf-body d-flex flex-wrap" style="overflow: auto;" ref="scrollbody">
                <field-action-item
                    :style="{bottom: (actionItemTop)+'px', left: actionItemLeft+'px'}"
                    :rec="focusedField"
                    @save="loadData"
                    :noEditPermission="noEditPermission"
                />

                <template v-if="loaded" v-for="(section, i) in sections">
                    <form-section
                        v-if="!hideSections[section.form_section_id]" :key="`sf-section-`+i"
                        :config="section"
                        :style="{width: getSectionWidth(section)}"
                        :ref="`section_` + section.form_section_id"
                        :isDisabled="sectionDisabled(section)"
                        @clear-action-popup="hideActionMenu"
                    >
                        <div class="d-flex flex-wrap pa-0">
                            <v-col
                                :cols="getFieldWidth(section, field)"
                                v-for="field in section.form_instance_responses"
                                :key="`ffr-`+field.form_field_id"
                                :class="{'smart-form-controller': controls[field.form_field_id] }"
                                class="pa-0"
                            >
                                <v-lazy>
                                    <form-field
                                        v-if="!hideFields[field.form_field_id]"
                                        :liveForm="!!formInstance.form_live"
                                        :data="field"
                                        :ref="`field_` + field.form_field_id"
                                        :formInstance="formInstance"
                                        @checkRules="updateDependencies"
                                        @updated="updateTimestamps"
                                        :class="[{'disabled-field': disableFields[field.form_field_id]}]"
                                        :disabled="disableFields[field.form_field_id] || noEditPermission"
                                        @field-focus="showActionItemStatus"
                                        @force-reload="loadData"
                                        @mousemove.native="showTooltip($event,field)"
                                        @mouseleave.native="showTooltip(null)"
                                    />
                                </v-lazy>
                            </v-col>
                        </div>
                    </form-section>
                </template>

            </div>
        </div>

        <smartform-sidebar ref="sidebar" :rec="formInstance" v-if="!hideSidebar && formInstance" class="flex-shrink-0" @highlightField="highlightField"/>

        <fe-crud ref="permissionsCrud" :config="permissionConfig" autoload  @read="v => userPermissions = v" />
    </div>
</template>

<script>
import lodash from 'lodash'
import { mapState } from 'vuex'
import SmartFormBaseMixin from './smartFormBaseMixin'
import FormField from './components/FormField'
import FormSection from './components/FormSection'
import Reporting from './reporting'
import LinkForm from './components/LinkForm'
import FieldActionItem from './components/FieldActionItem'
import SmartformSidebar from './components/SmartformSidebar'
import ShareFormDialog from './components/dialogs/ShareFormDialog'

export default {
    name: 'SmartForm',

    components: {
        FormField,
        FormSection,
        LinkForm,
        FieldActionItem,
        SmartformSidebar,
        ShareFormDialog,
    },

    mixins: [ SmartFormBaseMixin ],

    props: {
        id: {},
        folderId: {},
        hideTools: { type: Boolean, default: false },
        hideSidebar: { type: Boolean, default: false },
        template_id: {}
    },

    data() {
        let me = this

        return {
            tooltip: '',
            loaded: false,
            firstStudentField: null,
            isDirty: false,
            statuses: [],
            linkForm: {
                show: false
            },
            showShareDialog: false,
            collectionDialog: {
                show: false,
                record: null
            },
            focusedField: null,
            actionItemTop: 0,
            actionItemLeft: 0,
            items: [],


            tools: [{
                name: 'Set Status',
                icon: 'far fa-clipboard-list-check',
                handler() {}
            }],
            noEditPermission: false,
            userPermissions: {},
        }
    },

    computed: {
        ...mapState('global', ['sessionUser', 'currentYear', 'uiSavedSearch']),
        formInstance() {
            if (this.items.length > 0) {
                return this.items[0]
            }
            return null
        },
        sections() {
            if (this.formInstance) {
                return this.formInstance.form_instance_sections
            }
            return []
        },
        checkOwner() {
            return this.formInstance?.form_instance_created_by === this.sessionUser.user.id
                || Object.keys(this.userPermissions).length ? !!this.userPermissions[this.sessionUser.user.id][this.template_id].can_share : false
        },
        permissionConfig() {
            let cfg = this.$_.cloneDeep(this.$models.formPermissions)
            cfg.read.params.user_id = this.sessionUser.user.id
            return cfg
        },
    },

    watch: {
        id(v) {
            if (v) {
                this.loadForm()
            }
        },
        userPermissions(v) {
            this.noEditPermission = !this.userPermissions[this.sessionUser.user.id][this.template_id]?.can_edit
        },
    },

    async mounted() {
        // Ensure we have latest recency data
        this.$store.dispatch('global/getUiSavedSearch', { uiKey: 'RECENT_SMART_FORMS' })
            // Give global store a chance to propagate back to us
            .then(() => this.$nextTick())
            .then(async () => {
                // Regardless of where the user launched this form instance from,
                // update it as their most recently viewed instance
                let formInstanceId = this.id
                if (formInstanceId && this.uiSavedSearch?.RECENT_SMART_FORMS?.length > 0) {
                    let sf = this.uiSavedSearch.RECENT_SMART_FORMS[0]

                    // If form is already in recently viewed, remove it before appending it as newest
                    let arr = [...sf.arguments].filter(itm => itm.argument_value != formInstanceId) // soft == cause these saved searches get saved as string vals
                    arr.push({
                        saved_search_id: sf.saved_search_id,
                        param_code: 'FORM_INSTANCE_ID',
                        argument_value: formInstanceId,
                    })

                    await this.$store.dispatch('global/updateUiSavedSearch', {
                        uiKey: 'RECENT_SMART_FORMS',
                        args: arr.slice(-20),
                    })

                    await this.$store.dispatch('global/getUiSavedSearch', { uiKey: 'RECENT_SMART_FORMS' })
                    this.$store.commit('global/ping', 'recentSmartForms')
                }
            })

        document.addEventListener('click', this.hideActionMenu)
        this.$refs.scrollbody.addEventListener('scroll', this.hideActionMenu)

        this.$modelGet('formInstanceStatus').then(s => this.statuses = s)
        this.loadForm()
    },

    beforeDestroy() {
        document.removeEventListener('click', this.hideActionMenu)
        this.$refs.scrollbody.removeEventListener('scroll', this.hideActionMenu)
    },

    methods: {
        showTooltip: lodash.debounce(function(e,field) {
            if(e && field.tooltip) {
                this.$refs.tooltip.style.top = e.y - 100 + 'px'
                this.$refs.tooltip.style.left = e.x - 0 + 'px'
                this.tooltip = field.tooltip
            } else {
                this.tooltip = ''
            }
        }, 500),
        highlightField(f) {
            let r = this.$refs['field_'+f.form_field_id]
            if (r.length > 0) {
                r[0].$el.classList.remove('highlight-field-out')
                r[0].$el.classList.add('highlight-field')

                setTimeout(() => {
                    if (r && r.length > 0) {
                        r[0].$el.classList.remove('highlight-field')
                        r[0].$el.classList.add('highlight-field-out')
                    }

                }, 1000)
            }
        },
        setStatus(v) {
            let id = null
            this.formInstance.form_instance_sections.forEach(sec => {
                let found = sec.form_instance_responses.find(x => x.form_field_type_id == 37) // find the form status code field id
                if(found) id = found.form_field_id
            })
            let r = {
                form_field_id: id,
                form_instance_id: this.formInstance.form_instance_id,
                value: v ? v.id.toString() : null
            }
            // update form instance (what's pulled for status column/chip)
            // update form field response if field on form (what's pulled for export)
            let instance = {...this.formInstance}
            instance.form_instance_status_id = v ? v.id : null
            this.$axios.post('formResponse.php?action=update&property=form_instance', { form_instances: [instance] }).then(resp => {
                this.$ecResponse(resp)
            })
            if(id) {
                this.$axios.post('formResponse.php?action=update&property=form_response', { form_responses: [r] }).then((resp) => {
                    this.$ecResponse(resp)
                })
            }
            this.loadForm() 
        },
        addToCollection() {
            let fId = this.formInstance.form_instance_id

            this.$axios.post('form.php?action=create&property=form_link&form_instance_id='+fId+'&link_id='+this.collectionDialog.record.dashboard_id+'&link_type=collection')
                .then(resp => {
                    this.$ecResponse(resp)
                })
                .finally(() => {
                    this.collectionDialog.show = false
                    this.collectionDialog.record = null
                })
        },
        updateTimestamps(records) {
            this.isDirty = true
            // Update the field timestamps to match
            records.forEach(rec => {
                let field = this.allFields[rec.form_field_id]
                field.form_instance_response_created = rec.created
                field.form_instance_response_created_by_full_name = this.sessionUser.user.user_full_name
            })
        },
        showActionItemStatus(rec) {
            this.focusedField = rec

            let f = this.$refs['field_' + rec.form_field_id] || []
            if (f.length > 0) {
                let field = f[0]
                let bbox = field.$el.getBoundingClientRect()
                this.actionItemLeft = bbox.left + bbox.width - 180
                this.actionItemTop = rec.xtype.indexOf('combo') > -1 && window.innerHeight < field.$el.offsetTop
                    ? window.innerHeight - bbox.bottom - 50
                    : window.innerHeight - bbox.bottom + bbox.height - 20
            }
        },
        hideActionMenu(e) {
            this.focusedField = null
            this.tooltip = ''
        },

        getFieldValue(fId) {
            let field = this.allFields[fId]

            if (this.$refs['field_'+fId]) {

            } else {
                if (field.xtype == 'checkboxfield') {
                    return field.value ? true : false
                }
                return this.allFields[fId].value
            }
        },
        downloadPDF() {
            window.open(process.env.VUE_APP_BASE_URI+'formPrint.php?printformid=' + this.formInstance.form_instance_id)
        },

        loadData() {
            if (!this.id) return

            return new Promise((resolve, reject) => {
                this.$setLoading(true)
                this.loaded = false
                this.$modelGet('formInstance', { property: 'form_instance', form_instance_id: this.id })
                .then(response => {
                    this.loaded = true
                    if(!response) console.error('no response from: formInstance ',this.id);
                    this.$data.items = response
                    resolve(response)
                })
                .catch(() => reject(response))
                .finally(() => { this.$setLoading(false) })
            })

        },

        buildBreadcrumb() {

        },
        getFieldWidth(section, field) {
            let fullWidth = ['interventionblock']

            if (fullWidth.includes(field.xtype)) {
                return 12
            }

            if (section.section_layout_type_name === 'form') {
                return 12
            }

            let width = parseFloat(field.column_width)

            if (field.xtype == 'scorefield' && !width) return 12

            return Math.ceil(12 * width);
        }
    }
}
</script>

<style lang="scss">
.sf-tooltip {
    position: relative;
    top: 0;
    left: 0;
    opacity: .9;
    z-index: 10000;
}
.sf-tooltip::after {
    position: absolute;
    width: max-content;
    max-width: 190px;
    border-radius: 3px;
    background: black;
    color: white;
    padding: 8px;
    content: attr(data-tip);
    font-size: 12px;
    font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif;
}

.sf-toolbar {
    padding: 5px 5px 5px 20px;
}

.sf-body {
    background-color: white;
    padding: 8px;
}
// .back {
//     transform: translate(0,-35%);
// }
// .red {
//     background-color: red;
// }
// .green {
//     background-color: green;
// }
// .blue {
//     background-color: blue;
// }

.smart-form-controller {
    border-radius: 4px;
}
// .block {
//     display: block;
//     padding: 10px;
//     margin: 8px;
//     background-color: #557c83;
// }
// .content {
//     width: 100%;
//     height: 100%;
// }

.disabled-field {
    opacity: .2;
    pointer-events: none;
}

// .smart-form-footer {
//     position: sticky;
//     bottom: 0px;
//     height: 36px;
//     border-top: 1px solid #e6e6e6;
//     background: white;
//     padding-top: 8px;
//     padding-left: 16px;
//     font-size: 12px;
// }
</style>
