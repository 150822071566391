<template>
    <div class="flex-fill flex-column pa-4">
        <div>
        <fe-filter-btn
            title="Year"
            :items="schoolYears"
            itemValue="id"
            itemText="name"
            v-model="selectedYear"
            :multiple="false"
            :showClearButton="false"
        >
        </fe-filter-btn><fe-filter-btn
            title="Assessment"
            :items="dataPointTypes"
            itemValue="id"
            itemText="name"
            :buffered="true"
            v-model="selectedDataPointType"
            :multiple="false"
            :showClearButton="false"
        >
            <template v-slot:extra="{item}">
                <fe-info-tip v-if="item.tooltip" :tooltip="item.tooltip" style="order:-1;margin-right: 4px;" />
                <div v-else style="order:-1;margin-right:16px;"/>
            </template>
        </fe-filter-btn><fe-filter-btn
            title="District"
            :items="districts"
            itemValue="id"
            itemText="name"
            v-model="selectedDistrict"
            :buffered="true"
            :multiple="false"
            :showClearButton="false"
        >
        </fe-filter-btn>
        </div>


        <fe-grid
            v-if="validFilter"
            ref="grid"
            :rowData="items"
            :columnDefs="columnDefs"
            style="height: 100%"
            displayDensity="small"
            disableInlineCreate
            :showAddRowBtn='false'
            :simpleInsertDisabled='true'
            @rowDoubleClicked="switchDistrict"
        >

            <template #toolbar-items>
                <fe-tooltip :tooltip="'Reload Table Data'" direction="top">
                    <fe-icon-btn class="theme--light" usage="ghost" useIcon="fas fa-sync-alt" @click="loadData" style="margin-top: -4px" />
                </fe-tooltip>
            </template>

        </fe-grid>
        <div v-else style="padding: 30px;">
            <fe-empty-state icon="fa-empty-set" :text="emptyText"></fe-empty-state>
        </div>
        <fe-crud
            :config="$models.dataPointTypeUsage"
            ref="crud"
            :autoload="false"
        />
    </div>
</template>

<script>

    export default {
        name: 'DataPointTypeUsage',
        data() {
            let me = this
            return {
                selectedDistrict: {},
                selectedYear: {},
                selectedDataPointType: {},
                districtsLoaded: false,
                dataPointTypesLoaded: false,
                schoolYearsLoaded: false,

                dataPointTypes: [],
                schoolYears: [],
                districts: [],
                items: [],
                cell: null,
                columnDefs: [{
                   headerName: "District",
                   field: "district_name",
                   editable: false,
                   minWidth: 80,
                   pinned: 'left'
                }, {
                   headerName: "Assessment",
                   field: "data_point_type_name",
                   editable: false,
                   minWidth: 150,
                   pinned: 'left'
                }, {
                    headerName: "School Year",
                    field: "school_year_name",
                    editable: false,
                    minWidth: 40,
                }, {
                   headerName: "Record Count",
                   field: "record_count",
                   editable: false,
                   minWidth: 150,
                }, {
                   headerName: "Created",
                   field: "created",
                   editable: false,
                   flex: true
                }]
            }
        },

        mounted() {
            this.loadData()
        },

        computed: {
            errorText() {
                if (this.selectedDistrictId) {
                    if (this.selectedDataPointTypeIdDistrictId && this.selectedDistrictId != this.selectedDataPointTypeIdDistrictId) {
                        return "The selected assessment belongs to district " + this.selectedDataPointTypeIdDistrictId + ". Select another district or select another assessment."
                    }
                    if (this.selectedYearIdDistrictId && this.selectedDistrictId != this.selectedYearIdDistrictId) {
                        return "The selected school year belongs to district " + this.selectedYearIdDistrictId + ". Select another district or select another school year."
                    }
                }
                return ""
            },
            emptyText() {
                return this.errorText || "Select at least one filter above."
            },
            filtersLoaded() {
                return this.districtsLoaded && this.schoolYearsLoaded && this.dataPointTypesLoaded
            },
            validFilter() {
                return (this.selectedDistrict?.included?.length
                    || this.selectedYear?.included?.length
                    || this.selectedDataPointType?.included?.length)
                    && !this.errorText
            },
            selectedYearId() {
                return this.selectedYear?.included?.[0]?.id
            },
            selectedYearIdDistrictId() {
                return this.selectedYear?.included?.[0]?.district_id
            },
            selectedDistrictId() {
                return this.selectedDistrict?.included?.[0]?.id || this.selectedDataPointTypeIdDistrictId || this.selectedYearIdDistrictId
            },
            selectedDataPointTypeId() {
                return this.selectedDataPointType?.included?.[0]?.id
            },
            selectedDataPointTypeName() {
                return this.selectedDataPointType?.included?.[0]?.name
            },
            selectedDataPointTypeIdDistrictId() {
                return this.selectedDataPointType?.included?.[0]?.district_id
            }
        },

        watch: {
            selectedDistrict(v, o) {
                this.loadData()
            },
            selectedYear(v, o) {
                this.loadData()
            },
            selectedDataPointType(v, o) {
                this.loadData()
            },
        },

        methods: {
            switchDistrict(meta) {
                let me = this

                me.$messageBox({
                    title: 'Switch District',
                    persistent: true,
                    message: 'Are you sure you want to switch to ' + meta.data.district_name + '?',
                    maxWidth: '500',
                    actions: [{
                        text: 'Cancel',
                        primary: false
                    }, {
                        text: 'Ok',
                        primary: true,
                        onClick: () => {
                            me.$setLoading(true)
                            me.$axios.post('switchDistrict.php', { district_id: meta.data.district_id} )
                                .then(response => {})
                                .finally(() => {
                                    var iesso = document.getElementById('ie_session_iframe')
                                    if (iesso) iesso.parentNode.removeChild(iesso)
                                    me.$setLoading(false)
                                    location.reload()
                                })
                        }
                    }]
                })

            },
            loadDistricts() {
                this.districts = []
                return this.$axios.get('dataPointTypeUsage.php?action=get&property=district')
                .then(response => {
                    let districts = []
                    response.data.districts.forEach((v) => {
                        districts.push({
                            name: v.district_name,
                            id: v.district_id,
                        })
                    })
                    this.districts = districts
                    this.districtsLoaded = true
                })
            },
            loadSchoolYears() {
                this.schoolYears = []
                return this.$axios.get('dataPointTypeUsage.php?action=get&property=school_year')
                .then(response => {
                    let years = []
                    response.data.years.forEach((v) => {
                        let y = {
                            name: v.school_year_name,
                            id: v.school_year_id,
                        }
                        if (!v.normative_school_year) {
                            return;
                            //y.tooltip = "Owned by " + v.district_name
                            //y.district_id = v.district_id
                        }
                        years.push(y)
                    })
                    this.schoolYears = years
                    this.schoolYearsLoaded = true
                })
            },
            loadDataPointTypes() {
                this.dataPointTypes = []
                return this.$axios.get('dataPointTypeUsage.php?action=get&property=data_point_type')
                .then(response => {
                    let types = []
                    response.data.types.forEach((v) => {
                        let dpt = {
                            name: v.data_point_type_name,
                            id: v.data_point_type_name,
                        }
                        if (v.variations > 1) {
                            dpt.tooltip = "Variations: " + v.variations
                        } else if (v.district_id) {
                            dpt.district_id = v.district_id
                            dpt.tooltip = "Owned by " + v.district_name
                        }
                        types.push(dpt)
                    })
                    this.dataPointTypes = types
                    this.dataPointTypesLoaded = true
                })
            },
            loadFilters() {
                Promise.all([this.loadDistricts(), this.loadSchoolYears(), this.loadDataPointTypes()]).then(() => this.loadData())
            },
            loadData() {
                this.items = []
                if (!this.filtersLoaded) {
                    this.loadFilters()
                    return;
                }
                if (!this.validFilter) return;
                this.$refs.crud.read({
                    school_year_id: this.selectedYearId,
                    district_id: this.selectedDistrictId,
                    data_point_type_name: this.selectedDataPointTypeName,
                }).then(r => {
                    this.items = this.$ecResponse(r, 'usage')
                })
            },
            cellClicked(cell) {
               this.cell = cell
            },
            setDialogCell(v) {
                this.openMenu = true
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
