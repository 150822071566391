import Vue from "vue"

export default Vue.extend({
    template: `<div class="ai-name-column-wrapper">
                    <div v-if="params.data.description" :class="classes" class="d-flex slo-truncated-text-renderer justify-space-between" style="line-height: 15px; margin-top: 5px;">
                        <span v-if="!showDescription" class="slo-truncated-text-renderer-text ai-name-column-value" style="line-height: 15px; max-width: 300px;">{{ params.value }}</span>
                        <span v-if="showDescription" class="slo-truncated-text-renderer-text ai-name-column-description flex-shrink-1 flex-grow-1" :id="'aiDescription' + params.data.id">{{ params.data.description }}</span>
                        <a href="#!" @click="resize()" class="slo-truncated-text-renderer-toggle ai-show-description flex-shrink-0 flex-grow-0">{{ showDescription ? 'Hide more' : 'Show more'}}</a>
                    </div>
                </div>`,
    data() {
        return {
            showDescription: false
        }
    },
    methods: {
        // after click, resize row so description shows fully
        resize() {
            this.showDescription = !this.showDescription
            let gridOptions = this.params.context.componentParent.$refs.grid.gridOptions
            let me = this
            // small timeout to make sure description in DOM first
            setTimeout(function() {
                gridOptions.api.forEachNode(function (rowNode) {
                    if (rowNode.data && rowNode.data.id === me.params.data.id) {
                        let aiDescriptions = document.getElementsByClassName('ai-name-column-description')
                        let height = 52
                        for(let i = 0; i < aiDescriptions.length; i++) {
                            if(aiDescriptions[i].id == 'aiDescription' + me.params.data.id) {
                                height += aiDescriptions[i].offsetHeight
                            }
                        }
                        rowNode.setRowHeight(height)
                    }
                })
                gridOptions.api.onRowHeightChanged()
            }, 10)
        }
    },
    computed: {
        text () { return this.params.data[this.params.textField || this.params.colDef.field] },
        truncateLength () { return this.params.data.truncateLength || 20 },
        isTruncated () { return this.truncatable && this.truncate },
        truncatable () { return this.text.length > this.truncateLength },
        classes () {
            return {
                'truncatable': this.truncatable,
                'is-truncated': this.isTruncated
            }
        }
    },
})