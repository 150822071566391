<template>
    <div class="counts-wrapper pa-3">
        <div class="count" v-for="(val,i) in values" :key="`count-${i}`" @click="showData(i)">
            <div class="swatch" :style="{ backgroundColor: colors[i], borderColor: colors[i] }">
            </div>
            <div class="text">
                <div class="headline">{{ val }} {{ unit }}</div>

                <div class="label">{{ labels[i] }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Counts',

    props: {
        unit: String,
        values: Array,
        labels: Array,
        colors: Array,
        urlParams: Array
    },

    methods: {
        showData(idx) {
            this.$emit('showDataTable', {
                urlParams: this.urlParams[idx],
                title: this.unit,
                subtitle: this.labels[idx],
                group: null
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.count {
    position: relative;

    .swatch {
        position: absolute;
        top: 9px;
        left: 5px;
        width: 12px;
        height: 12px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        border-radius: 1px;
    }

    .text {
        margin-left: 30px;

        .label {
            opacity: 0.5;
            font-size: 80%;
        }
    }
}
</style>
