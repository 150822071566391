<template>
    <div>
        <v-autocomplete
            v-model="localValue"
            :search-input.sync="searchText"
            flat solo dense
            placeholder="Search for students"
            :items="items"
            itemText="student_full_name"
            itemValue="student_id"
            return-object
        >
            <template v-slot:item="data">
                <v-list-item-avatar v-if="data.item.student_id">
                    <v-img :src="getStudentImg(data.item.student_id)"/>
                </v-list-item-avatar>

                <v-list-item-content>

                    <v-list-item-title>{{data.item.student_full_name}}</v-list-item-title>
                    <v-list-item-subtitle>{{data.item.school_name}} {{data.item.grade_desc}}</v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
    import lodash from 'lodash'
    import { mapState } from 'vuex'

    export default {
        name: 'StudentNameSearch',
        props: {
            value: {

            },
            schoolYearId: {

            },
            byId: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState('global', ['currentYear'])
        },
        watch: {
            searchText: lodash.debounce(function(v) {
                if (v && v.length >= 3) {
                    this.doSearch()
                }

            }, 500),
            localValue(v) {
                let e = this.$props.byId ? v.student_id : v
                this.$emit('input', e)
                this.$emit('change', e)
            },
            schoolYearId(v) {
                this.searchYearId = this.schoolYearId || this.currentYear.id
            }
        },
        created() {
            this.localValue = this.$props.value
            this.searchYearId = this.schoolYearId || this.currentYear.id
            if (this.$props.value) {
                this.items.push(this.$props.value)
                this.$nextTick(() => { this.initialized = true })
            }

        },
        data() {
            return {
                searchText: '',
                searchYearId: '',
                localValue: null,
                items: [],
                initialized: false
            }
        },
        methods: {
            doSearch() {
                // if (!this.initialized) return
                this.$modelGet('studentGradeHistory', {
                    name: this.searchText,
                    school_year_id: this.searchYearId
                }).then(response => {
                    this.$data.items = response
                })
            },
            getStudentImg(student_id) {
                return this.$axios.defaults.baseURL + this.$models.getUrl('studentImage', 'read') + '&id=' + student_id
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
