import Vue from 'vue'

export default Vue.extend({
    template: `
        <div style="font-size: 14px; line-height: 20px; flex-flow: row wrap;" class="d-flex">
            <div>Carry Over to Next </div>
            <div>Year</div>
            <fe-info-tip
                tooltip="When enabled, all form instances associated to the prior year will be locked, archived, and available to view on the first day of the new school year. 
                    A form for the new school year will be created and will include all relevant available data, and will be editable for those with permissions to do so."
                direction="top"
                icon="far fa-info-circle"
                class="ml-2"
                color="#7E8494"
            ></fe-info-tip>
        </div>
    `,

    computed: {

    },
    data: function() {
        return {

        }
    },

})
