<template>
    <span>
        <v-divider class="my-4"/>

        <h4>{{ groupTitle }}</h4>

        <slot></slot>
    </span>
</template>

<script>
export default {
    name: 'FieldGroup',

    props: {
        groupTitle: String
    }
}
</script>
