<template>
    <div>
        <div class="d-flex">
            <v-toolbar color="white" flat dense>
                <v-toolbar-title :class="{optional: isOptional()}" >{{motivationLabel}}</v-toolbar-title>
            </v-toolbar>
        </div>
        <div style="padding: 0 15px">
            <fe-remote-combo
                v-model="studentRec.incident_motivation"
                :label="getLabel"
                rootProperty="incident_motivations"
                url="incidentMotivations.php?action=get"
                :sort="sortAlphabetically"
                :rules="[v=>isRequired(v)]"
                :params="{active_only:1}"
            />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    
    export default {
        name: 'MotivationPanel',
        props: {
            studentRec: Object,
            panelVisibility: Number
        },
        computed: {
            ...mapState('global', ['districtConfig']),
            getLabel() {
                let required = !this.isOptional() ? '*' : ''
                return 'Type ' + required
            },
            motivationLabel() {
                return this.districtConfig.INCIDENT_MOTIVATION_LABEL
                    ? this.districtConfig.INCIDENT_MOTIVATION_LABEL
                    : 'Motivation'
            }      
        },
        watch: {
            panelVisibility(v) {
                this.$children.forEach( c => c.validate && c.validate() )
            }
        },
        methods: {
            isRequired(v) {
                return this.panelVisibility == 2 && !v ? 'Field is required' : true
            },
            isOptional() {
                return this.panelVisibility == 1
            },
            sortAlphabetically(item1, item2) {
                return item1.name.toLowerCase() < item2.name.toLowerCase() ? -1 : 1
            },
        }
    }
</script>

<style scoped="true">
</style>
