<template>
    <div class="flex-fill flex-column no-scroll">
        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.unknownUser"
            :readParams="{ upload_id: $props.uploadId }"
            @read="v => items = v"
        />

        <fe-mask v-show="loading" :showLoader="true" />

        <fe-dialog
            width="400"
            title="Create User Alias"
            v-if="dialog.show"
            @dismiss="dialog.show=false"
            @close="dialog.show=false"
            @accept="setAlias"
            acceptButtonText="Alias"
            :acceptButtonDisabled="!dialog.record"
            persistent
        >
            <v-form>
                <fe-label>User</fe-label>
                <v-autocomplete
                    :items="users"
                    v-model="dialog.record"
                    itemValue="id"
                    itemText="user_full_name"
                    required
                    flat solo dense
                />
            </v-form>
        </fe-dialog>

        <fe-grid
            :columnDefs="columns"
            :rowData="items"
            class="flex-grow-1"
            displayDensity="small"
            ref="grid"
            :showAddRowBtn="false"
            @selectionChanged="selectRow"
            @cellValueChanged="sanitize"
            @gridReady="setGrid"
            showToolbar
        >
            <template v-slot:toolbar-items>
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <fe-btn usage="tertiary" v-on="on">
                            Select
                        </fe-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="selectMatched">
                            <v-list-item-content>
                                <v-list-item-title>All Matched</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="selectUnmatched">
                            <v-list-item-content>
                                <v-list-item-title>All Unmatched</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <fe-btn usage="primary" v-if="canSave" @click="doSave">Save</fe-btn>

                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <fe-btn usage="primary" v-on="on" :disabled="!selections.length">
                            Actions
                        </fe-btn>
                    </template>

                    <v-list>
                        <v-list-item
                            v-for="(item, index) in tools"
                            :key="index"
                            @click="item.handler()"
                            :hidden="disableActionItem(item)"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </fe-grid>
    </div>
</template>

<script>
    export default {
        name: 'UnknownUser',
        props: {
            uploadId: {
                required: true
            }
        },
        data() {
            let me = this

            return {
                yellow: '#fdff85',
                green: '#beffab',
                loading: false,
                canSave: false,
                selections: [],
                users: [],
                items: [],
                lookup: {},
                dialog: {
                    show: false,
                    record: null
                },
                pendingText: '<br><p class="caption mt-2">This will also save any pending changes.</p>',
                originalItems: [],
                tools: [{
                    title: 'Create User',
                    description: 'Create as a new user in eduCLIMBER',
                    handler() {
                        me.$messageBox({
                            title: 'Confirm Create',
                            persistent: true,
                            maxWidth: 600,
                            message: 'Are you sure you want to create ' + me.selections.length + ' record(s) as a new user? ' +
                                me.pendingText,
                            actions: [{
                                text: 'Cancel', primary: false
                            }, {
                                text: 'Create', primary: true,
                                onClick: () => {
                                    me.selections.forEach(rec => {
                                        rec.new_user = true
                                        rec.matched_user_id = null
                                    })
                                    me.doSave()
                                }
                            }]
                        })
                    }
                }, {
                    title: 'Match to User',
                    description: 'Set this user status name as an alternate known name for a selected user status',
                    handler() {
                        let invalid = false
                        me.selections.forEach(sel => {
                            if (!sel.matched_user_id) {
                                invalid = true
                            }
                        })

                        if (invalid) {
                            me.$messageBox({
                                title: 'Invalid Selection',
                                persistent: true,
                                maxWidth: 600,
                                message: 'One or more of your selections do not have a match.  Please remove unmatched records and try again',
                                actions: [{
                                    text: 'Ok', primary: true,
                                }]
                            })
                            return
                        }
                        me.$messageBox({
                            title: 'Confirm Match',
                            persistent: true,
                            maxWidth: 600,
                            message: 'Are you sure you want to match ' + me.selections.length + ' user(s)? ' +
                                me.pendingText,
                            actions: [{
                                text: 'Cancel', primary: false
                            }, {
                                text: 'Match', primary: true,
                                onClick: () => {
                                    me.selections.forEach(rec => {
                                        rec.new_user = false
                                        // rec.matched_user_id = null
                                    })
                                    me.doSave()
                                }
                            }]
                        })
                    }
                }],
                columns: []
            }
        },
        watch: {
            items(v) {
            }
        },
        mounted() {
            this.loadUsers()
        },
        methods: {
            disableActionItem(item) {
                if (this.selections.length === 0) {
                    return true
                } else if (item.title == 'Create User') {
                    return false
                } else if (item.title == 'Match to User') {
                    let found = false
                    this.selections.forEach(rec => { if (!rec.matched_user_id) found = true })
                    return found
                }
                return false
            },
            selectMatched() {
                let arr = []
                let warn = []
                this.$refs.grid.gridApi.deselectAll()
                this.$refs.grid.gridApi.forEachNode(function(node) {
                    if (node.data.matched_user_id) { node.setSelected(true) }

                    if (node.data.row_color == 'red-row') {
                        warn.push(node.data)
                    }
                })

                if (warn.length > 0) {
                    let cnt = warn.length

                    this.$messageBox({
                        title: 'Selection Warning',
                        persistent: true,
                        message: 'Your auto selection has '+cnt+' user'+(cnt>1?'s':'')+' that has multiple matches.  Please verify user before saving.',
                        maxWidth: '500',
                        actions: [{
                            text: 'Ok',
                            usage: 'danger',
                            onClick: () => {
                            }
                        }]
                    })
                }
            },
            selectUnmatched() {
                let arr = []
                this.$refs.grid.gridApi.deselectAll()
                this.$refs.grid.gridApi.forEachNode(function(node) {
                    if (!node.data.matched_user_id) { node.setSelected(true) }
                })
            },
            setGrid() {
                let me = this
                this.$refs.grid.gridOptions.getRowStyle = function(params) {
                    let rec = params.node.data

                    if (rec) {
                        let color = undefined

                        switch (rec.row_color) {
                            case 'green-row':
                                color = me.green
                                break
                            case 'red-row':
                                return {
                                    color: 'red'
                                }
                                break
                        }

                        return {
                            'background-color': color
                        }
                    }
                }
            },
            findUser(v) {
                let me = this
                if (v.value) {
                    let r = this.lookup[v.value]
                    if (r) return r.user_full_name
                }
                return 'No match'
            },
            doSave() {
                let dirty = this.$isDirty(this.originalItems, this.items)
                let updateItems = []
                dirty ? updateItems = dirty : updateItems = this.selections
                this.loading = true
                this.$refs.crud.update(updateItems)
                    .then(response => {
                        this.loadUsers()
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            setAlias() {
                let me = this
                this.dialog.show = false
                this.selections.forEach(rec => { rec.matched_user_id = this.dialog.record })

                this.sanitize()
                this.$refs.grid.gridApi.deselectAll()
                this.doSave()
            },
            selectRow(row) {
                this.selections = this.$refs.grid.gridApi.getSelectedRows()
            },
            sanitize() {
                // this will check to see if the original fetched data set has been manipulated
                let dirty = this.$isDirty(this.originalItems, this.items)

                if (dirty) {
                    this.canSave = true
                } else {
                    this.canSave = false
                }
                // Since we are changing cells behind the ag-grid api, call refresh
                this.$refs.grid.gridApi.refreshCells()
            },
            loadUsers() {
                let me = this
                let id = this.$props.uploadId
                this.originalItems = []
                this.items = []

                this.loading = true
                this.$axios.get('users.php?action=get')
                    .then(response => {
                        this.users = response.data

                        this.columns = [me.$grid.checkColumn(), {
                            headerName: 'Import Last Name',
                            field: 'lname',
                            sortable: true,
                            flex: 1
                        }, {
                            headerName: 'Import First Name',
                            field: 'fname',
                            sortable: true,
                            flex: 1
                        }, {
                            headerName: 'Import SIS ID',
                            field: 'external_id',
                            sortable: true,
                            flex: 1
                        }, {
                            headerName: 'Match To User',
                            field: 'matched_user_id',
                            editable: true,
                            flex: 1,
                            tooltipValueGetter: (param) => {
                                let d = param.node.data
                                if (d.row_color == 'red-row') {
                                    return 'There are multiple users with this name.  eduCLIMBER cannot guarantee they are the same user'
                                } else if (d.row_color == 'green-row') {
                                    return 'This user will also be known as the selected user status in eduCLIMBER'
                                }
                                return 'Select a user to match this user to'
                            },
                            cellRenderer: v => me.findUser(v),
                            cellEditorFramework: "FeGridChipSelect",
                            cellEditorParams: {
                                rowDataKey: "matched_user_id",
                                mode: "edit",
                                items: me.$data.users,
                                keyProp: "id",
                                labelProp: "user_full_name",
                                disableChips: true
                            }
                        }]
                        // populate user lookup for speed
                        this.users.forEach(u => {
                            this.lookup[u.id] = u
                        })

                        this.$refs.crud.read()
                            .then(() => {
                                this.items.forEach(rec => {
                                    rec.new_user   = null
                                    // rec.matched_user_id = null
                                })
                                this.originalItems = this.$_.cloneDeep(this.items)
                                this.$emit('count', this.originalItems.length)
                            })
                            .finally(() => {
                                this.loading = false
                                this.sanitize()
                            })
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
