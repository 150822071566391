<template>
    <v-container>

        <fe-dialog
            title="Confirm Delete"
            acceptButtonText="Delete"
            @accept="deleteRecord(deleteDialog.record)"
            @dismiss="deleteDialog.show = false"
            :body="'Are you sure you want to delete ' + deleteDialog.record.name"
            v-if="deleteDialog.show"
        >
        </fe-dialog>

        <fe-dialog
            title="Schools"
            v-if="schoolDialog.show"
            @dismiss="schoolDialog.show=false"
            :footer="false"
        >
            <edit-list
                :crudConfig="schoolCrudConfig"
                itemText="school_name"
            >
            </edit-list>
        </fe-dialog>

        <!-- Manage behavior types -->
        <v-layout column v-if="!manageBehavior.show">
            <v-flex xs12>
                <v-layout row>
                    <v-flex xs6>
                        <v-text-field style="margin-top: 6px;" flat solo dense placeholder="Filter items below"></v-text-field>
                    </v-flex>

                    <v-flex xs3>
                        <fe-filter-btn :title="filtersTitle" v-model="filters">
                            <v-layout row wrap>
                                <v-flex xs12>
                                    <v-subheader>Filters</v-subheader>
                                </v-flex>

                                <template v-for="tf in toggleFields.filter(r => !r.restrict)">
                                    <v-flex xs12 :key="`filter-incident-toggle-field-status-`+ tf.model">
                                        <v-checkbox
                                            :label="tf.label"
                                            class="ma-2"
                                            v-model="filters[tf.model]"
                                        ></v-checkbox>
                                    </v-flex>
                                </template>

                                <template v-for="sf in statusFields">
                                    <v-flex xs6 :key="`filter-incident-field-status-`+ sf.model">
                                        <fe-remote-combo
                                            flat solo dense
                                            :items="statusOptions"
                                            :label="sf.label"
                                            itemValue="id"
                                            itemText="name"
                                            class="ma-2"
                                            byId
                                            v-model="filters[sf.model]"
                                        ></fe-remote-combo>
                                    </v-flex>
                                </template>

                            </v-layout>
                        </fe-filter-btn>
                    </v-flex>

                    <v-flex xs1>
                        <fe-btn icon usage="ghost" useIcon="refresh" @click="loadData"></fe-btn>
                    </v-flex>

                    <v-flex xs2>
                        <fe-btn usage="primary" @click="editDialog.show = true">Add</fe-btn>
                    </v-flex>
                </v-layout>
            </v-flex>

            <template v-for="(item, index) in items">
                <v-flex :key="`incident-type-card-`+index">
                    <incident-type-card :item="item" @updated="loadData()"/>
                </v-flex>
            </template>

        </v-layout>
    </v-container>
</template>

<script>
    import EditList from '@/components/common/EditList'
    import IncidentBehavior from './IncidentBehavior'
    import IncidentTypeCard from './IncidentTypeCard'
    import Vuex from 'vuex'
    import { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'

    export default {
        name: 'ManageIncidentType',
        components: {
            EditList,
            IncidentTypeCard
        },
        props: ['id'],
        provide() {
            this.localStore = new Vuex.Store({
                state: {
                    statusOptions: [
                        { "id": 0, "name": "Hidden" },
                        { "id": 1, "name": "Optional" },
                        { "id": 2, "name": "Required" }
                    ],
                    statusFields: [{
                        label: 'Description',
                        model: 'show_description'
                    }, {
                        label: 'Motivation',
                        model: 'show_motivation'
                    }, {
                        label: 'Response',
                        model: 'show_response'
                    }, {
                        label: 'Parent Contact',
                        model: 'show_parent_contact'
                    }],
                    toggleFields: [{
                        label: 'Require security to create',
                        model: 'create_security_flag'
                    }, {
                        label: 'Require security to complete',
                        model: 'complete_security_flag'
                    }, {
                        label: 'Restrict description to those with security',
                        model: 'restrict_description'
                    }, {
                        label: 'Include in default search',
                        model: 'default_search'
                    }, {
                        label: 'Key Performance Indicator (KPI)',
                        model: 'kpi',
                        restrict: !this.$hasSecurity('MANAGE_ACHIEVEMENT_DASHBOARD')
                    }, {
                        label: 'Notify rostered teachers on creation',
                        model: 'notify_flag'
                    }]
                },
                mutations: {},
                actions: {}
            })

            return {
                localStore: this.localStore
            }
        },
        watch: {
            id(v) {
                this.loadData()
            },
            'editDialog.show'(v) {
                if (!v) {
                    this.editDialog.record = { name: '' }
                    this.loadData()
                }
            },
            'deleteDialog.show'(v) {
                if (!v) {
                    this.deleteDialog.record = { name: '' }
                    this.loadData()
                }
            },
            'schoolDialog.show'(v) {
                if (!v) {
                    this.deleteDialog.record = { name: '' }
                    this.loadData()
                }
            }
        },
        computed: {
            ...mapLocalState(['statusFields', 'toggleFields', 'statusOptions']),
            crudConfig() {
                return this.$models.incidentBehaviorType
            },
            filtersTitle() {
                let count = Object.keys(this.filters).filter((key) => { return this.filters[key] }).length            
                return 'More ' + (count ? ' - ' + count : '')        
            },
            filteredItems() {
                let fi = []
                let selectedFilters = Object.keys(this.filters).filter((key) => { return this.filters[key] })
                
                return this.items
                if (selectedFilters.length > 0) {
                    this.items.forEach((rec) => {
                        let count = 0

                        selectedFilters.forEach((f) => {
                            if (rec[f]) count++
                        })

                        if (count === selectedFilters.length) {
                            fi.push(rec)
                        }
                    })
                    return fi
                } else {
                    return this.items
                }
                
            }
        },
        data() {
            return {
                items: [],
                manageBehavior: {
                    name: '',
                    show: false,
                    id: null
                },
                deleteDialog: {
                    show: false,
                    record: {
                        name: ''
                    }
                },
                schoolDialog: {
                    show: false,
                    record: null
                },
                filters: {
                    show_description: null,
                    show_motivation: null,
                    show_response: null,
                    show_parent_contact: null,
                    create_security_flag: null,
                    complete_security_flag: null,
                    restrict_description: null,
                    default_search: null,
                    kpi: null,
                    notify_flag: null
                },
            }
        },

        methods: {
            loadData() {
                let cfg = this.crudConfig

                this.$axios.get(cfg.read + '&id=' + this.id)
                .then(response => {
                    let data = []

                    if (response && response.data) {
                        if (cfg.rootProperty) {
                            this.$data.items = response.data[cfg.rootProperty]
                        } else {
                            this.$data.items = response.data
                        }

                        // this.$store.commit('set', {
                        //     module: 'manage',
                        //     state: 'incidentTypes',
                        //     value: this.$data.items
                        // })
                    }
                })
            }
        },

        mounted() {
            this.loadData()
        }
    }
</script>

<style lang="scss" scoped>

</style>