import Vue from 'vue'

class Poller {
    constructor() {
        this.polls = []
        this.timer = null
        // since first poll doesn't have any pollers... the interval will start lower
        // and be increased to the default after the second poll
        this.interval = 4000
        this.defaultInterval = 60000 // pushing from 20 seconds to 1 minute

        // this.defaultConfig = {
        //     key: null,
        //     params: {},
        //     fn: null,
        // }
        this.poll()
    }

    add(v) {
        this.polls.push(v)
    }
    remove(v) {
        this.polls = this.polls.filter(x => x != v)
    }

    poll(once = false) {
        this.$axios = this.$axios || new Vue().$axios
        let pollIndexes = {}
        let payload = this.polls.filter(x => x.config?.key).map((x,i) => {
            pollIndexes[i] = x
            return {
                key: x.config.key,
                params: x.config.params,
                pollIndex: i,
            }
        })

        if(this.$axios && payload.length) {
            this.$axios({
                url: 'poll.php?action=get',
                method: 'post',
                data: {
                    polls: payload,
                }
            }).then(r => {
                let processed = {}
                let results = r.data.poll_results
                for(let key in results) {
                    results[key].forEach(({pollIndex, ...data}) => {
                        pollIndexes[pollIndex].setResponse(data)
                    })
                }

            }).catch(error => console.warn(error))
        }
        if(!once) setTimeout(this.poll.bind(this), this.interval)
        if(this.interval != this.defaultInterval) this.interval = this.defaultInterval

    }
}

const instance = new Poller()

export default {
    props: {
        config: {},
    },
    computed: {
        pollerInstance: () => instance,
    },
    data() {
        return {
            response: {},
        }
    },
    render() {
        return this.$scopedSlots.default(this.response)
    },
    mounted() {
        instance.add(this)
        // this.$root.$on('poller', e => {
        //     // if(e.action == 'poll' && !e.handled) {
        //     if(e.action == 'poll') {
        //         // e.handled = true
        //         instance.poll(true)
        //     }
        // })
    },
    beforeDestroy() {
        instance.remove(this)
    },
    watch: {
        response(nv,ov) {
            this.$emit('polled',nv)
        }
    },
    methods: {
        setResponse(v) {
            this.response = v
        }
    },
}
