<template>
    <v-container>
        <v-card color="white">
            <v-toolbar flatclass="card-toolbar" style="box-shadow: none" height="57" color="white">
                <slot name="icon"></slot>

                <v-btn text disabled v-show="pending">
                    <v-progress-circular indeterminate color="#999" size="20"/>
                </v-btn>

                <v-spacer/>

                <slot name="buttons"/>
            </v-toolbar>

            <v-divider/>

            <v-layout>
                <v-flex px-9 :py-6="!!lessPadding" :py-8="!lessPadding">
                    <Empty
                        v-if="count === 0"
                        :title="emptyTitle"
                        :body="emptyBody || 'No records found.'"
                        :button="emptyButton"
                        @click="$emit('emptyClick')"
                    />

                    <v-card flat class="text-xs-center alert-area" v-else-if="error">
                        <v-alert :value="true" type="error">
                            {{ $t('common.error') }}
                        </v-alert>
                    </v-card>

                    <slot v-else name="content"/>
                </v-flex>

                <slot name="modals"/>
            </v-layout>
        </v-card>
    </v-container>
</template>

<script>
import Empty from './Empty'

export default {
    name: 'TabPane',

    components: {
        Empty
    },

    props: {
        pending: Boolean,
        error: Boolean,
        count: Number,
        ignoreCount: Boolean,
        lessPadding: Boolean,
        emptyTitle: String,
        emptyBody: String,
        emptyButton: String
    }
}
</script>
