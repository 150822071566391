<template>
    <div class="d-flex flex-column flex-fill">
        <div class="headline mb-1 d-flex">
            FastBridge Integration
            <fe-info-tip
                    class="ml-2"
                    tooltip="This page shows imported FastBridge progress monitors and their status"
            />
        </div>

        <fe-dialog
                title="Students"
                ref="studentDialog"
                width="600"
                height="600"
                v-if="studentsDialog.show"
                persistent
                @dismiss="closeStudentDialog"
        >
            <template v-slot:footer>
                <div class="ml-auto">
                    <fe-btn
                            v-if="unmatched > 0"
                            usage="secondary"
                            class="ml-auto"
                            @click="loadUpload(uploadId)"
                    >
                        Match Students
                    </fe-btn>
                    <fe-btn
                            usage="primary"
                            class="ml-auto"
                            @click="closeStudentDialog"
                    >
                        Done
                    </fe-btn>
                </div>
            </template>
            <fe-grid
                    domLayout="normal"
                    ref="studentsGrid"
                    :showAddRowBtn="false"
                    :showDownload="false"
                    :searchBar="false"
                    :columnDefs="studentsColumnDefs"
                    :rowData="planStudents"
                    style="height: 100%; width: 100%;"
            />
        </fe-dialog>

        <div class="flex-fill flex-column no-scroll">
            <fe-grid
                    ref="grid"
                    :columnDefs="columns"
                    :rowData="filteredPlans"
                    :showAddRowBtn="false"
                    :showDownload="false"
                    displayDensity="medium"
                    style="height: 100%;"
            >

                <template v-slot:after-search-toolbar-items>
                    <fe-filter-btn
                            v-model="statusFilter"
                            title="Filter by Status"
                            ref="statusFilter"
                            style="margin-top: -5px; margin-left: 15px;"
                            itemText="name"
                            itemValue="id"
                            closeOnSelect
                            :items="statuses"
                            :multiple="false"
                            @input="filterPlans"
                    />
                </template>
            </fe-grid>
        </div>
    </div>
</template>

<script>
    import NumericEditor from '@/components/common/grid/NumericEditor.js'
    import CellSelect from '@/components/common/grid/CellSelect.js'
    import SimpleGridDialogColumn from "@/components/common/SimpleGridDialogColumn"
    import UnmatchedStudents from "./renderers/UnmatchedStudentsRenderer.js"

    export default {
        name: "FastBridgeIntegration",
        components: {
            SimpleGridDialogColumn,
        },
        data() {
            let me = this
            return {
                frameworkComponents: {
                    numericEditor: NumericEditor,
                    cellSelect: CellSelect,
                },
                school_year: null,
                studentsDialog: {
                    show: false,
                },
                unmatched: 0,
                uploadId: null,
                planStudents: [],
                plans: [],
                filteredPlans: [],
                filters: {
                    record_status: {}
                },
                statuses: [
                    {
                        id: 1,
                        name: 'AVAILABLE',
                    }, {
                        id: 2,
                        name: 'USED',
                    }, {
                        id: 3,
                        name: 'HIDDEN',
                    }
                ],
                columns: [
                    {
                        headerName: 'ID',
                        field: 'id',
                        editable: false,
                        flex: 1,
                        hide: true,
                    }, {
                        headerName: 'Client ID',
                        field: 'client_id',
                        editable: false,
                        flex: 1,
                        hide: true,
                    }, {
                        headerName: 'Intervention Name',
                        field: 'intervention_name',
                        editable: false,
                        width: 200,
                    }, {
                        headerName: 'Status',
                        field: 'record_status',
                        editable: false,
                        flex: 1,
                        cellStyle(v) {
                            if (v.value === 'AVAILABLE') return { background: '#FFFFFF', color: "#303030" }
                            if (v.value === 'USED') return { background: '#F3F3F3', color: "#999999" }
                            if (v.value === 'HIDDEN') return { background: '#dcf1f7', color: "#628894" }
                        }
                    }, {
                        headerName: 'Received',
                        field: 'received',
                        width: 120,
                        editable: false,
                        hide: true,
                        cellRenderer: v => this.$dayjs(v.value).format('M/D/YYYY'),
                    }, {
                        headerName: 'Intervention Created',
                        field: 'created',
                        width: 120,
                        editable: false,
                        cellRenderer: v => this.$dayjs(v.value).format('M/D/YYYY'),
                    }, {
                        headerName: 'Students',
                        headerComponentParams: { template: '<div>Students <i class="far fa-info-circle"></i></div>' },
                        headerTooltip: 'Red indicates unmatched students.',
                        field: 'student_count',
                        maxWidth: 100,
                        editable: false,
                        tooltipValueGetter: function(v) { return v.data.unmatched_student_count > 0 ? 'Unmatched students' : null },
                        cellRendererFramework: UnmatchedStudents,
                        cellStyle(v) {
                            if (v.data.unmatched_student_count > 0) {
                                return {
                                    'color': '#F02D1F',
                                }
                            }
                        },
                        onCellClicked: function(v) {
                            me.unmatched = v.data.unmatched_student_count
                            me.uploadId = v.data.upload_id
                            me.loadStudents(v.data.id)
                        },
                    }, {
                        headerName: 'Assessment',
                        field: 'sub_category_name',
                        editable: false,
                        flex: 1,
                    }, {
                        headerName: 'Start Date',
                        field: 'start_date',
                        editable: false,
                        flex: 1,
                        hide: true,
                        cellRenderer: v => this.$dayjs(v.value).format('M/D/YYYY'),
                    }, {
                        headerName: 'End Date',
                        field: 'end_date',
                        editable: false,
                        flex: 1,
                        hide: true,
                        cellRenderer: v => this.$dayjs(v.value).format('M/D/YYYY'),
                    }, {
                        headerName: "Hidden from Display",
                        field: 'hidden',
                        width: 80,
                        cellRendererFramework: "FeGridToggle",
                        onCellValueChanged: this.statusUpdate,
                        cellStyle(v) {
                            if (v.data.record_status === 'USED') {
                                return {
                                    'opacity': '.4',
                                    'pointerEvents': 'none',
                                }
                            }
                        },
                        valueSetter: v => {
                            if (v.newValue === 1) { v.data.record_status = 'HIDDEN' }
                            if (v.newValue === 0) { v.data.record_status = 'AVAILABLE' }
                            return true
                        },
                        valueGetter: v => v.data.record_status === 'HIDDEN' ? 1 : 0,
                    }
                ],
            }
        },
        computed: {
            studentsColumnDefs() {
                // lname and fname are the names from FB
                // student_full_name is the matching name (null if no match)
                return [{
                    headerName: 'FB Name',
                    field: 'lname',
                    editable: false,
                    cellRenderer(v) {
                        return v.data.lname + ', ' + v.data.fname
                    },
                    cellStyle(v) {
                        return !v.data.student_full_name ? {color: 'red'} : {color: 'black'}
                    }
                }, {
                    headerName: 'Matched Name',
                    field: 'student_full_name',
                    editable: false,
                    hide: true,
                    cellRenderer(v) {
                        return v.data.student_full_name ? v.data.student_full_name : '-'
                    },
                    cellStyle(v) {
                        return !v.data.student_full_name ? {color: 'red'} : {color: 'black'}
                    }
                }, {
                    headerName: 'Student ID',
                    field: 'student_id',
                    flex: 1,
                    editable: false
                }, {
                    headerName: 'Status',
                    field: 'record_status',
                    flex: 1,
                    editable: false,
                    cellRenderer(v) {
                        return v.data.student_full_name ? v.data.record_status : 'Unmatched'
                    },
                    cellStyle(v) {
                        return !v.data.student_full_name ? {color: 'red'} : {color: 'black'}
                    }
                }, {
                    headerName: 'Date of Birth',
                    field: 'date_of_birth',
                    flex: 1,
                    editable: false,
                    hide: true
                }]
            },
            statusFilter: {
                set(v) {
                    this.filters.record_status = v
                },
                get() {
                    return this.filters.record_status
                }
            },
        },
        mounted() {
            this.loadPlans()
        },
        methods: {
            loadPlans() {
                this.plans = []
                this.$modelGet('fastBridgePmPlans', {property: 'plans'})
                    .then(fb => {
                        if (fb.plans.length > 0) {
                            fb.plans.forEach(plan => {
                                this.plans.push(plan)
                            })
                        }
                    })
                this.filteredPlans = this.plans
            },
            filterPlans() {
                if (this.filters.record_status.included.length > 0) {
                    this.filteredPlans = []
                    let plans = this.plans.filter(x => x.record_status == this.filters.record_status.included[0].name)
                    plans.forEach(p => {
                        this.filteredPlans.push(p)
                    })
                } else {
                    this.$refs.statusFilter.openMenu = false
                    this.filteredPlans = this.plans
                }
            },
            onCellClicked(v) {
                this.$refs.grid.setDialogCell(v)
            },
            loadUpload(id) {
                let me = this
                me.closeStudentDialog()
                me.$store.commit('global/addDockableWindow', {
                    name: `FastBridge Monitor Upload (${id})`,
                    component: 'validate-upload',
                    attrs: {
                        id: id
                    },
                    events: {
                        close() {
                            me.loadPlans()
                        }
                    }
                })
            },
            loadStudents(plan_id) {
                this.studentsDialog.show = true
                this.planStudents = []
                this.$modelGet('fastBridgePmPlans', {
                    external_pm_plan_id: plan_id,
                    property: 'plan_students'
                }).then(fb => {
                        if (fb.plan_students.length > 0) {
                            fb.plan_students.forEach(student => {
                                this.planStudents.push(student)
                            })
                        }
                    })

            },
            closeStudentDialog() {
                this.studentsDialog.show = false
                this.planStudents = []
            },
            statusUpdate(v) {
                this.$axios.post('crud.php?property=ExternalPmPlan&action=update', {external_pm_plan: [v.data]})
                    .then(response => {
                        if (response.data.success) {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
