<template>
    <div class="flex-fill flex-column no-scroll" style="height: 400px;">
        <fe-crud-grid
            ref="grid"
            :autoload="false"
            :config="$models.studentSchools"
            :readParams="{ student_id: this.updateStudent.student_id }"
            :columnDefs="columnDefs"
            :extraTools="this.hasSecurity"
            :rowData="studentSchools"
            :showAddRowBtn="this.selections.length > 0 ? false : !this.hasSecurity ? false : true"
            :showEditbar="this.hasSecurity"
            @beginCreate="showAddDialog=true"
            @selectionChanged="setSelectedRows"
            @cellValueChanged="loadData"
            addRowBtnText="Add School"
            refreshOnUpdated
            refreshOnCreated
            disableInlineCreate
            displayDensity="medium"
            style="height:100%;"
        >
            <template v-slot:extra-tools>
                <fe-btn
                    useIcon="fa-exchange-alt"
                    usage="ghost"
                    whiteText
                    class="fe-grid-action-btn"
                    @click="$emit('reassignBtnClick', $refs.grid.$refs.crud)"
                >
                    Reassign
                </fe-btn>
            </template>
        </fe-crud-grid>

        <add-school
            v-if="showAddDialog"
            @close="showAddDialog=false"
            :crud="$refs.grid.$refs.crud"
            :studentInfo="this.updateStudent"
            :schoolList="this.schools"
            :schoolYearList="this.schoolYears"
        />
    </div>
</template>

<script>
    import Vuex, { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'
    import AddSchool from './AddSchool'

    export default {
        name: 'StudentSchools',

        components: {
            AddSchool
        },

        data() {
            return {
                showAddDialog: false,
                selectedSchoolIds: [],
                studentSchools: [],
                originalItems: null,
                gridApi: null
            }
        },

        inject: ['localStore'],

        computed: {
            ...mapLocalState(['updateStudent','selections','schools','schoolYears','hasSecurity']),
            ...mapState('global', ['shareableStores']),

            valuesLoaded() {
                return !!this.schools && !!this.updateStudent
            },

            columnDefs() {
                let me = this

                let primaryFlag = [];
                if (this.secondaryEnrollmentEnabled) {
                    primaryFlag.push({
                        headerName: "Primary",
                        field: "primary_flag",
                        sortable: true,
                        cellRenderer(v) {
                            if (v.value) return '<i class="fas fa-check"></i>'
                        },
                        cellStyle: { "padding": 0, textAlign: 'center' }
                    })
                }
                return !this.valuesLoaded ? [] : [
                    {
                        headerName: null,
                        headerCheckboxSelection: this.hasSecurity,
                        checkboxSelection: this.hasSecurity,
                        width: 70,
                        minWidth: 70,
                        hide: !this.hasSecurity,
                        colId: 'checkbox_column'
                    }, {
                        headerName: "Internal ID",
                        field: "student_id",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "School Year",
                        field: "school_year_id",
                        valueGetter: v => me.findLabel(me.schoolYears, v.data.school_year_id, 'id', 'name'),
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.school_year_id,
                                rowDataKey: "school_year_id",
                                mode: "edit",
                                items: me.schoolYears,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true
                            }
                        },
                        sortable: true,
                        editable: this.hasSecurity
                    }, {
                        headerName: "School",
                        field: "school_id",
                        valueGetter: v => me.findLabel(me.shareableStores.schools, v.data.school_id, 'id', 'name'),
                        editable: this.hasSecurity,
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.school_id,
                                rowDataKey: "school_id",
                                mode: "edit",
                                items: me.schools,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true
                            }
                        }
                    }, {
                        headerName: "Start Dt",
                        field: "start_date",
                        sortable: true,
                        editable: this.hasSecurity,
                        hide: true
                    }, {
                        headerName: "End Dt",
                        field: "end_date",
                        sortable: true,
                        editable: this.hasSecurity,
                        hide: true
                    }, {
                        headerName: "Inactive Dt",
                        field: "inactive_date",
                        sortable: true,
                        editable: this.hasSecurity
                    }, ...primaryFlag, {
                        headerName: "Upload ID",
                        field: "upload_id",
                        sortable: true,
                        editable: false
                    }
                ]
            },
            secondaryEnrollmentEnabled() {
                return this.$store.getters['flags/flags']['ec-secondaryenrollment'] === true
            },
        },

        mounted() {
            this.loadData()

            this.schools = this.schools.filter(x => !x.secondary_enrollment_flag)
        },

        methods: {
            loadData() {
                this.$refs.grid.$refs.crud.read()
                .then((response) => {
                    this.studentSchools = response.data.schools
                    this.originalItems = this.$_.cloneDeep(response.data.schools)
                })
            },

            findLabel(collection, value, valueProp, labelProp) {
                let lbl = ''
                if (collection && value) {
                    let sel = collection.find(x => x[valueProp] === value)
                    if (sel) lbl = sel[labelProp]
                }
                return lbl
            },

            setSelectedRows(meta) {
                this.$data.gridApi = meta.api
                this.selections = meta.api.getSelectedRows()
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
