<template>
    <fe-remote-combo
        label="Select a Grouping"
        :items="groupOptions"
        v-model="localValue"
        :rules="this.$fieldValidators('select', null, {required: true})"
        byId
        @change="$emit('change', $event)"
        @input="$emit('input', $event)"
        @set="$emit('set', $event)"
    />
</template>

<script>
export default {
    name: "GroupBy",

    props: {
        value: {
            required: true
        },
        visType: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            localValue: undefined
        }
    },

    computed: {
        groupOptions() {
            let base = [{
                name: 'By school',
                id: 'school'
            }, {
                name: 'By school group',
                id: 'school_group'
            }, {
                name: 'By grade',
                id: 'grade'
            }]

            let district = [{
                name: 'By district',
                id: 'district'
            }]

            let mealStatus = [{
                name: 'By meal status',
                id: 'meal_status'
            }]

            let ell = [{
                name: 'By EL Status',
                id: 'ell_flag'
            }, {
                name: 'By ELP Level',
                id: 'ell_level_id'
            }]

            let ethnicityGender = [{
                name: 'By ethnicity',
                id: 'ethnicity'
            }, {
                name: 'By gender',
                id: 'gender'
            }]

            let attendance = [{
                id: 'weekday',
                name: 'By weekday'
            }, {
                id: 'month',
                name: 'By month'
            }, {
                id: 'year',
                name: 'By year'
            }]

            let incident = [{
                id: 'incident_behavior_type',
                name: 'Incident Type'
            }, {
                id: 'incident_year',
                name: 'Incident Year'
            }, {
                id: 'incident_month',
                name: 'Incident Month'
            }, {
                id: 'incident_weekday',
                name: 'Incident Weekday'
            }, {
                id: 'incident_behavior',
                name: 'Incident Code'
            }, {
                id: 'incident_location',
                name: 'Incident Location'
            }, {
                id: 'incident_hour',
                name: 'Incident Hour'
            }, {
                id: 'incident_response',
                name: 'Incident Response'
            }]

            switch (this.visType) {
                case 'attendance':
                    return base.concat(attendance, ethnicityGender)
                case 'demographic':
                    return district.concat(base, mealStatus, ell)
                case 'incidentType':
                case 'incidentResponse':
                    return incident
                default:
                    return district.concat(base, ethnicityGender, ell)
            }
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(v) {
                this.localValue = v
            }
        }
    }
}
</script>

<style scoped>

</style>
