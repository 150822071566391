import * as pixi from 'pixi.js'

class Selection {
    constructor(options) {
        this.options = options || {}
        this.groups = options.groups
        this.container = options.container
        this.color = options.color || 0xff4500
        this.alpha = options.alpha || .3
        this.mousePosition = null
        this.active = false
        this.start = null
        this.end = null
        this.gfx = []
        this.selected = []
        this.onMouseDown = this.onMouseDown.bind(this)
        this.onMouseUp = this.onMouseUp.bind(this)
        this.onMouseOut = this.onMouseOut.bind(this)
        this.onMouseMove = this.onMouseMove.bind(this)
    }

    isActive() {
        if(!this.start || !this.end) return false
        let same = this.start.x == this.end.x && this.start.y == this.end.y
        return this.active && !same
    }

    onMouseDown() {
        this.clearRects()
        this.active = true
        this.start = this.getMousePosition()
        this.end = this.getMousePosition()
        this.gfx = []
        this.selected = []
    }
    onMouseUp() {
        if(this.active) {
            this.active = false
            this.clearRects(0)
            if(this.selected.length == 1 && this.start.x == this.end.x) {
                return this.selected[0]
            }
            return this.selected
        }
    }
    onMouseOut() {
        if(this.gfx.length) {
            this.active = false
            this.selected = []
            this.clearRects()
        }
    }
    onMouseMove(pos) {
        if(!pos) {
            this.onMouseOut()
        } else {
            this.mousePosition = pos
            if(this.active) {
                this.end = this.getMousePosition()
                if(this.gfx.length) {
                    this.clearRects()
                }

                this.addRect(
                    this.start.x, this.start.y,
                    this.end.x, this.end.y
                )

                this.selected = []
                let x = Math.min(this.start.x, this.end.x)
                let y = Math.min(this.start.y, this.end.y)
                let width = Math.max(this.start.x, this.end.x) - x
                let height = Math.max(this.start.y, this.end.y) - y

                this.groups.forEach( group => {
                    let groupRecs = []
                    group.data.forEach( student => {
                        if(student.rect && this.rectHit(
                            x,y,width,height,
                            group.x + student.rect.x,
                            group.y + student.rect.y,
                            student.rect.width, student.rect.height
                        )) {
                            this.selected.push(student)
                            groupRecs.push(student.rect)
                        }

                    })
                    let x1, y1, x2, y2
                    groupRecs.forEach( rec => {
                        if(isNaN(x1) || rec.x < x1) x1 = rec.x
                        if(isNaN(y1) || rec.y < y1) y1 = rec.y
                        if(isNaN(x2) || rec.x > x2) x2 = rec.x
                        if(isNaN(y2) || rec.y > y2) y2 = rec.y
                    })
                    this.addRect(group.x+x1, group.y+y1, group.x+x2+group.spriteSize, group.y+y2+group.spriteSize)

                })

            }
        }
    }
    addRect(x1,y1,x2,y2) {
        let gfx = new pixi.Graphics()
        if(!this.gfx.length) {
            gfx.lineStyle((1/this.container.scale.x)*1,this.color)
            gfx.drawRect(x1,y1,x2-x1,y2-y1)
        } else {
            gfx.beginFill(this.color, this.alpha)
            gfx.drawRect(x1,y1,x2-x1,y2-y1)
            gfx.endFill()
        }
        this.gfx.push(gfx)
        this.container.addChild(gfx)
    }
    clearRects(index) {
        if(this.gfx.length) {
            if(isNaN(index)) {
                this.gfx.forEach( g => this.container.removeChild(g))
                this.gfx = []
            } else {
                this.container.removeChild(this.gfx[index])
                this.gfx.splice(index,1)
            }
        }
    }
    getMousePosition() {
        return this.mousePosition
        // return this.container.toWorld(this.container.interaction.mouse.global)
    }
    rectHit(x1,y1,w1,h1,x2,y2,w2,h2) {
        return !(
            x2 > x1+w1
            || x2+w2 < x1
            || y2 > y1+h1
            || y2+h2 < y1
        )
    }
}

export default Selection
