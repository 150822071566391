<template>
    <v-layout column>
        <div class="headline mb-1 d-flex">
            Rate Adjustment
            <fe-info-tip
                class="ml-2"
                tooltip="Add values between .01 and 1.00 to modify the rate and display the adjusted value entered below."
            />
        </div>

        <fe-crud-grid
            ref="grid"
            :config="crudConfig"
            :columnDefs="columnDefs"
            displayDensity="medium"
            autoload
            refreshOnCreated
            refreshOnUpdated
            disableInlineCreate
            @fail="updateFail"
            @beginCreate="beginEntry"
        />

        <fe-dialog
            v-if="createDialog.show"
            title="Create Rate Adjustment"
            :footer="false"
            persistent
            disableAutoclose
            @close="dismissDialog"
        >
            <model-form
                idProperty="id"
                :records="createDialog.records"
                :fieldConfig="fieldConfigs"
                model="attendanceRateAdjustments"
                @cancel="dismissDialog"
                @done="created"
            />
        </fe-dialog>
    </v-layout>
</template>

<script>
    import ModelForm from '@/components/common/form/ModelForm'

    export default {
        name: "AttendanceRateAdjustments",

        components: {ModelForm},

        data() {
            return {
                createDialog: {show: false, records: []},
                columnDefs: [
                    {
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        maxWidth: 70,
                        colId: 'checkbox_column'
                    },
                    {
                        headerName: "Start Value",
                        field: 'start_value',
                        editable: true,
                        cellEditorFramework: "FeGridTextField",
                        cellEditorParams: {
                            type: 'number',
                            rules: this.$fieldValidators('number', 'Start Value', {min: 0, max: 1})
                        },
                    },
                    {
                        headerName: "End Value",
                        field: 'end_value',
                        editable: true,
                        cellEditorFramework: "FeGridTextField",
                        cellEditorParams: {
                            type: 'number',
                            rules: this.$fieldValidators('number', 'End Value', {min: 0, max: 1})
                        },
                    },
                    {
                        headerName: "Adjusted Value",
                        field: 'adjusted_value',
                        editable: true,
                        cellEditorFramework: "FeGridTextField",
                        cellEditorParams: {
                            type: 'number',
                            rules: this.$fieldValidators('number', 'Adjusted Value', {min: 0, max: 1})
                        },
                    },
                    {
                        headerName: "Created By",
                        field: 'created_by_name',
                        editable: false
                    },
                    {
                        headerName: "Created",
                        field: 'created',
                        editable: false
                    }
                ]
            }
        },

        computed: {
            crudConfig() {
                return _.cloneDeep(this.$models.attendanceRateAdjustments)
            },

            fieldConfigs() {
                return [
                    {
                        field: 'start_value',
                        label: 'Start Value',
                        component: 'v-text-field',
                        validatorKey: 'number',
                        validatorConfig: {required: true, min: 0, max: 1 },
                        width: '100%',
                        attrs: {
                            validateOnBlur: true,
                            flat: true,
                            solo: true,
                            dense: true
                        }
                    }, {
                        field: 'end_value',
                        label: 'End Value',
                        component: 'v-text-field',
                        validatorKey: 'number',
                        validatorConfig: {required: true, min: 0, max: 1 },
                        width: '100%',
                        attrs: {
                            validateOnBlur: true,
                            flat: true,
                            solo: true,
                            dense: true
                        }
                    }, {
                        field: 'adjusted_value',
                        label: 'Adjusted Value',
                        component: 'v-text-field',
                        validatorKey: 'number',
                        validatorConfig: {required: true, min: 0, max: 1 },
                        width: '100%',
                        attrs: {
                            validateOnBlur: true,
                            flat: true,
                            solo: true,
                            dense: true
                        }
                    }
                ]
            }
        },

        created() {
            this.$parent.$once('show rateAdjustment', () => {
                this.$refs.grid.$refs.grid.resize()
            })
        },

        methods: {
            load() {
                this.$refs.grid.$refs.crud.read()
            },

            beginEntry() {
                this.resetEntry()
                this.createDialog.show = true
            },

            dismissDialog() {
                this.createDialog.show = false
            },

            resetEntry() {
                this.createDialog.records = [
                    {
                        start_value: undefined,
                        end_value: undefined,
                        adjusted_value: undefined
                    }
                ]
            },

            updateFail(response) {
                this.$refs.grid.$refs.crud.read()
                this.$ecResponse(response)
            },

            created() {
                this.load()
                this.dismissDialog()
            }
        }
    }
</script>

<style scoped></style>
