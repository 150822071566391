export default {
    default: {
        path: 'crud.php',
        fetchVerb: 'get',
        createVerb: 'create',
        updateVerb: 'update',
        deleteVerb: 'delete'
    },
    crud: {
        path: 'crud.php'
    },
    tags: {
        path: 'tags.php'
    },
    userGroups: {
        path: 'userGroup.php'
    },
    users: {
        path: 'users.php'
    },
    thresholdGroups: {
        path: 'thresholdGroup.php'
    },
    thresholdGroupProps: {
        path: 'thresholdGroup.php',
        createVerb: 'add',
        deleteVerb: 'remove'
    },
    forms: {
        path: 'form.php'
    },
    schools: {
        path: 'schools.php'
    },
    grades: {
        path: 'grades.php'
    },
    filters: {
        path: 'filters.php'
    },
    interventionTypes: {
        path: 'interventionTypes.php'
    },
    dataPointTypes: {
        path: 'dataPointType.php'
    },
    subcategories: {
        path: 'subcategories.php'
    },
    scoreDetailTypes: {
        path: 'scoreDetailTypes.php'
    },
    scoreDetailTypeOptions: {
        path: 'scoreDetailTypes.php',
    },
    dataPointNames: {
        path: 'dataPointName.php'
    },
    incidentBehaviors: {
        path: 'incidentBehavior.php'
    },
    incidentBehaviorTypes: {
        path: 'incidentBehaviorType.php'
    },
    incidentResponses: {
        path: 'incidentResponses.php'
    },
    results: {
        path: 'thresholdGroupResult.php'
    },
    schoolYears: {
        path: 'qry.php',
        fetchVerb: null
    },
    alphaMaps: {
        path: 'alphaMap.php'
    }
}
