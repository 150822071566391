<template>
    <div class="d-flex flex-column flex-fill">
        <fe-dialog
            title="New Guardian"
            width="500"
            v-if="createGuardianDialog.show"
            @close="createGuardianDialog.show=false"
            persistent
            :footer="false"
        >
            <v-form v-model="validForm">
                <fe-label>First Name</fe-label>
                <v-text-field
                    flat solo dense
                    v-model="createGuardianDialog.rec.fname"
                    placeholder="First Name" required
                    :rules="$fieldValidators('text', 'First name', { required: true, limit: 50 })"
                />

                <fe-label>Last Name</fe-label>
                <v-text-field
                    flat solo dense
                    v-model="createGuardianDialog.rec.lname"
                    placeholder="First Name" required
                    :rules="$fieldValidators('text', 'Last name', { required: true, limit: 50 })"
                />

                <fe-label>Email</fe-label>
                <v-text-field
                    flat solo dense
                    v-model="createGuardianDialog.rec.email_addr"
                    placeholder="Email" required
                    :rules="$fieldValidators('text', 'Email', { required: true, limit: 100, email: true })"
                />

                <div v-if="psportalEnabled" class="d-flex justify-space-between">
                    <fe-label>Send Login Details to Guardian</fe-label>
                    <fe-switch v-model="createGuardianDialog.rec.send_email"></fe-switch>
                </div>

                <div class="d-flex justify-end">
                    <fe-btn usage="ghost" @click="createGuardianDialog.show = false">Cancel</fe-btn>
                    <fe-btn usage="secondary" :disabled="!validForm" @click="addGuardian">Save &amp; Close</fe-btn>
                    <fe-btn usage="primary" :disabled="!validForm" @click="addStudentsOpen">Next</fe-btn>
                </div>
            </v-form>
        </fe-dialog>

        <fe-dialog
            title="New Guardian from User"
            width="500"
            v-if="createGuardianFromUser.show"
            @close="resetGuardianDialog"
            persistent
            :footer="false"
        >
            <v-form v-model="validForm">
                <v-layout row class="d-flex align-center">
                    <v-autocomplete
                        v-model="userSearch.rec"
                        :search-input.sync="userText"
                        placeholder="Add a user (3 characters minimum)"
                        flat solo dense
                        class="mt-5"
                        :items="userSearchResults"
                        itemText="user_full_name"
                        itemValue="id"
                        return-object
                        validateOnBlur
                        :no-data-text="noSearchStringUsers ? '' : 'No data available'"
                        :hide-no-data="noSearchStringUsers"
                    >
                        <template v-slot:item="data">
                            <v-list-item-content>
                                <v-list-item-title>{{data.item.user_full_name}} ({{data.item.id}})</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <template v-slot:selection="data">
                            {{data.item.user_full_name}} ({{data.item.id}})
                        </template>
                    </v-autocomplete>
                </v-layout>
                <div class="d-flex justify-end">
                    <fe-btn usage="ghost" @click="resetGuardianDialog">Cancel</fe-btn>
                    <fe-btn usage="secondary" :disabled="!validForm" @click="addGuardianUser(false)">Save &amp; Close</fe-btn>
                    <fe-btn usage="primary" :disabled="!validForm" @click="addGuardianUser(true)">Next</fe-btn>
                </div>
            </v-form>
        </fe-dialog>

        <fe-dialog
            title="Students"
            width="500"
            height="400"
            v-if="addStudentsDialog"
            @close="addStudentsDialog=false"
            persistent
            acceptButtonText="Done"
        >
            <fe-grid
                v-if="studentItems"
                domLayout="normal"
                ref="studentsGrid"
                :showToolbar="false"
                :columnDefs="studentsColumnDefs"
                :rowData="studentItems"
                style="height: calc(100% - 75px); width: 100%;"
            />
            <v-layout row class="d-flex align-center">
                <v-autocomplete
                    v-model="studentSearch.rec"
                    :search-input.sync="studentText"
                    placeholder="Add a student (3 characters minimum)"
                    flat solo dense
                    class="mt-3"
                    :items="studentSearchResults"
                    itemText="student_full_name"
                    itemValue="student_id"
                    return-object
                    :no-data-text="noSearchStringStudents ? '' : 'No data available'"
                    :hide-no-data="noSearchStringStudents"
                >
                    <template v-slot:item="data">
                        <v-list-item-avatar>
                            <v-img :src="getStudentImg(data.item.student_id)"/>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{data.item.student_full_name}} ({{data.item.student_id}}), {{data.item.school_name}} - {{data.item.grade_desc}}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-slot:selection="data">
                        {{data.item.student_full_name}}
                    </template>
                </v-autocomplete>

                <fe-btn
                    :disabled="!studentSearch.rec"
                    @click="createStudent"
                    class="mb-3"
                >Add</fe-btn>
            </v-layout>
        </fe-dialog>

        <fe-dialog
            title="Edit"
            width="700"
            v-if="editGuardianDialog && selectedRow"
            @close="editGuardianDialog=false"
            persistent
        >

            <v-form v-model="validForm" class="pa-0">
                <div class="login-card">
                    <!-- Disabling fields until editing guardian is supported -->
                    <div class="d-flex justify-space-between flex-row">
                        <fe-label style="margin-top: 10px;">First Name:</fe-label>
                        <v-text-field
                            flat solo dense
                            v-model="selectedRow.fname"
                            required
                            :rules="$fieldValidators('text', 'First name', { required: true, limit: 50 })"
                            :disabled="!editable"
                        />
                    </div>

                    <div class="d-flex justify-space-between flex-row">
                        <fe-label style="margin-top: 10px;">Last Name:</fe-label>
                        <v-text-field
                            flat solo dense
                            v-model="selectedRow.lname"
                            required
                            :rules="$fieldValidators('text', 'Last name', { required: true, limit: 50 })"
                            :disabled="!editable"
                        />
                    </div>

                    <div class="d-flex justify-space-between flex-row">
                        <fe-label style="margin-top: 10px;">Email:</fe-label>
                        <v-text-field
                            flat solo dense
                            v-model="selectedRow.email_addr"
                            required
                            :rules="$fieldValidators('text', 'Email', { required: true, limit: 100 })"
                            :disabled="!editable"
                        />
                    </div>

                    <div v-if="psportalEnabled" class="d-flex justify-space-between flex-row">
                        <div class="d-flex flex-row">
                            <fe-label style="margin-top: 10px;">Login:</fe-label>
                            <fe-info-tip class="mt-1 ml-2" icon="fa-lock" color="#979797"
                                tooltip="PS Portal login is email" size="14px"
                            />
                        </div>
                        <v-text-field
                            flat solo dense
                            v-model="selectedRow.login"
                            required
                            :rules="$fieldValidators('text', 'Login', { required: true, limit: 50 })"
                            disabled
                        />
                    </div>

                    <div class="d-flex justify-space-between flex-row">
                        <fe-label style="margin-top: 10px;">Street Address:</fe-label>
                        <v-text-field
                            flat solo dense
                            v-model="selectedRow.addr_street"
                            :rules="$fieldValidators('text', 'Address', { limit: 100 })"
                            :disabled="!editable"
                        />
                    </div>
                    <div class="d-flex justify-space-between flex-row">
                        <fe-label style="margin-top: 10px;">City:</fe-label>
                        <v-text-field
                            flat solo dense
                            v-model="selectedRow.addr_city"
                            :rules="$fieldValidators('text', 'City', { limit: 100 })"
                            :disabled="!editable"
                        />
                    </div>
                    <div class="d-flex justify-space-between flex-row">
                        <fe-label style="margin-top: 10px;">State:</fe-label>
                        <v-text-field
                            flat solo dense
                            v-model="selectedRow.addr_state"
                            :rules="$fieldValidators('text', 'State', { limit: 2 })"
                            :disabled="!editable"
                        />
                    </div>
                    <div class="d-flex justify-space-between flex-row">
                        <fe-label style="margin-top: 10px;">Zip:</fe-label>
                        <v-text-field
                            flat solo dense
                            v-model="selectedRow.addr_zip"
                            :rules="$fieldValidators('text', 'Zip', { limit: 10 })"
                            :disabled="!editable"
                        />
                    </div>
                    <div class="d-flex justify-space-between flex-row">
                        <fe-label style="margin-top: 10px;">Phone:</fe-label>
                        <v-text-field
                            flat solo dense
                            v-model="selectedRow.phone_number"
                            :rules="$fieldValidators('text', 'Phone', { limit: 20 })"
                            :disabled="!editable"
                        />
                    </div>
                    <div class="d-flex justify-space-between flex-row">
                        <fe-label style="margin-top: 10px;">Work Phone:</fe-label>
                        <v-text-field
                            flat solo dense
                            v-model="selectedRow.work_phone_number"
                            :rules="$fieldValidators('text', 'Work phone', { limit: 20 })"
                            :disabled="!editable"
                        />
                    </div>
                    <div v-if="!psportalEnabled" style="height:62px;opacity:0;">spacer</div>
                </div>
            </v-form>
            <template #footer>
                <!-- Hiding until backend supports editing guardian -->
                <div v-if="editable" class="ml-auto">
                    <fe-btn usage="ghost" @click="editGuardianDialog=false">Cancel</fe-btn>
                    <fe-btn usage="primary" :disabled="!validForm" @click="updateGuardian">Save</fe-btn>
                </div>
                <fe-btn v-else class="ml-auto" @click="editGuardianDialog=false">Close</fe-btn>
            </template>
        </fe-dialog>

        <fe-mask v-model="loading" showLoader/>

        <div class="d-flex">
            <div class="page-title">Guardians</div>
        </div>

        <fe-grid
            ref="grid"
            :columnDefs="columns"
            :rowData="gridItems"
            :showAddRowBtn="false"
            class="flex-grow-1"
            domLayout="normal"
            displayDensity="small"
            @rowDoubleClicked="selectedRow=$event.data; editGuardianDialog=true"
        >
            <template v-slot:after-search-toolbar-items>
                <fe-remote-combo
                    :items="schoolYears"
                    itemText="name"
                    itemValue="id"
                    v-model="selectedYear"
                    style="width: 150px;"
                    class="ml-2"
                />
            </template>

            <template v-slot:toolbar-items>
                <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <fe-btn v-on="on">New</fe-btn>
                    </template>

                    <v-list dense>
                        <v-list-item @click="createGuardianDialog.show = true">
                            <v-list-item-content>
                                New Guardian
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="createGuardianFromUser.show = true">
                            <v-list-item-content>
                                New Guardian from User
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <template v-slot:cellDialog="{cell}">
                <template v-if="cell.colDef.field === 'edit_option'">
                    <v-list dense style="right: 150px;">
                        <v-list-item @click="editGuardianDialog = true">
                            Edit
                        </v-list-item>
                         <v-list-item @click="deleteGuardian">
                            Delete
                        </v-list-item>
                        <v-list-item v-if="psportalEnabled" @click="emailAccountInfo">
                            Send Login Details
                        </v-list-item>

                        <!-- Hiding until backend supports guardian deletion -->
                        <!-- <v-list-item @click="deleteGuardian">
                            Delete Guardian
                        </v-list-item> -->
                    </v-list>
                </template>
            </template>
        </fe-grid>

        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.guardiansGuardians"
            @read="gridItems = $event"
        />

        <fe-crud
            :config="$models.guardiansStudents"
            :autoload="false"
            ref="studentsCrud"
            @read="v => studentItems = v"
        />

        <fe-crud
            :config="$models.guardiansUserGuardian"
            :autoload="false"
            ref="guardiansCrud"
            @read="v => guardianItems = v"
        />
    </div>
</template>

<script>
    import lodash from 'lodash'

    export default {
        name: 'GuardianList',
        data() {
            return {
                studentSearch: {
                    rec: null,
                    show: false
                },
                userSearch: {
                    rec: null,
                    show: false
                },
                addStudentsDialog: false,
                editGuardianDialog: false,
                openMenu: false,
                searchResults: [],
                studentSearchResults: [],
                userSearchResults: [],
                studentText: '',
                userText: '',
                gridItems: [],
                studentItems: [],
                guardianItems: [],
                selectedYear: {
                    name: 'Select a Year'
                },
                schoolYears: [],
                validForm: false,
                loading: false,
                createGuardianDialog: {
                    show: false,
                    rec: {
                        fname: '',
                        lname: '',
                        email_addr: '',
                        send_email: true
                    }
                },
                createGuardianFromUser: {
                    show: false,
                    rec: null
                },
                noSearchStringStudents: true,
                noSearchStringUsers: true,
            }
        },
        watch: {
            'createGuardianDialog.show'(v) {
                if (!v) {
                    this.createGuardianDialog.rec = {
                        fname: '',
                        lname: '',
                        email_addr: '',
                        send_email: true
                    }
                }
            },
            'createGuardianFromUser.show'(v) {
                if (!v) {
                    this.createGuardianFromUser.rec = {}
                }
            },
            studentText: lodash.debounce(function(v) {
                if (v && v.length >= 3) {
                    this.noSearchStringStudents = false
                    this.doSearch()
                } else {
                    this.noSearchStringStudents = true
                }
            }, 500),
            userText: lodash.debounce(function(v) {
                if (v && v.length >= 3) {
                    this.noSearchStringUsers = false
                    this.doSearch(true)
                } else {
                    this.noSearchStringUsers = true
                }
            }, 500),
            selectedYear(v) {
                if (v) {
                    this.loading=true
                    this.$refs.crud.read({ school_year_id: this.selectedYear.id })
                        .finally(() => { this.loading = false })
                }

            },
            addStudentsDialog() {
                this.resetStudentDialog()
            }
        },
        computed: {
            editable: me => true,
            psportalEnabled: me => me.$store.state.global.shareableStores.district.psportal_enabled,
            columns() {
                let me = this
                return [{
                    field: 'full_name',
                    headerName: 'Name'
                }, {
                    field: 'email_addr',
                    headerName: this.psportalEnabled ? 'Email Login' : 'Email',
                }, {
                    field: 'student_count',
                    headerName: 'Students',
                    maxWidth: 80,
                    cellRendererFramework: "FeDialogColumn",
                    cellRendererParams: {
                        rowDataKey: "student_count",
                        parent: me
                    },
                    onCellClicked: function(v) {
                        me.studentItems = []
                        me.selectedRow = v.data
                        me.loadStudents()
                        me.addStudentsDialog = true
                    }
                }, {
                    field: 'addr_street',
                    headerName: 'Address',
                    cellRenderer: v => {
                        let addr = [
                            // v.data.addr_street,v.data.addr_city].filter(Boolean).join('<br/>'),
                            v.data.addr_street,
                            v.data.addr_city,
                            [v.data.addr_state,v.data.addr_zip].filter(Boolean).join(' ')
                        ].filter(Boolean).join(', ')
                        return `
                            <div class="d-flex fe-grid-cell-subtext ma-0 fill-height align-center" style="">
                                ${addr}
                            </div>
                        `
                    }
                }, {
                    field: 'phone_number',
                    headerName: 'Phone',
                    maxWidth: 160,
                    hide: true,
                }, {
                    field: 'work_phone_number',
                    headerName: 'Work Phone',
                    maxWidth: 160,
                    hide: true,
                }, {
                    field: 'last_login',
                    headerName: 'Last Login',
                    maxWidth: 160,
                    hide: !this.psportalEnabled,
                }, {
                    colId: 'tool',
                    headerName: '',
                    field: 'edit_option',
                    maxWidth: 50,
                    cellRenderer(v) {
                        return '<i class="far fa-ellipsis-v" style="font-size: 12px"></i>'
                    },
                    cellStyle() {
                        return {
                            'cursor': 'pointer',
                            'text-align': 'center'
                        }
                    },
                    onCellClicked: function(v) {
                        me.$refs.grid.setDialogCell(v)
                        me.selectedRow = v.data
                    }
                }]
            },

            studentsColumnDefs() {
                let me = this

                return [{
                    headerName: 'Name',
                    field: 'student_full_name',
                    editable: false
                }, {
                    headerName: 'Student ID',
                    maxWidth: 130,
                    field: 'student_id',
                    editable: false
                }, {
                    headerName: 'Relationship',
                    maxWidth: 170,
                    field: 'relationship',
                    editable: true,
                    onCellValueChanged: v => this.updateGuardianRelationship(v.data),
                }, {
                    headerName: 'Remove',
                    maxWidth: 100,
                    field: 'delete_option',
                    cellRenderer(v) {
                        return '<i class="fas fa-trash" style="margin-left: 15px; font-size: 12px"></i>'
                    },
                    cellStyle() {
                        return {
                            'cursor': 'pointer',
                            'text-align': 'center'
                        }
                    },
                    onCellClicked: function(v) {
                        me.deleteStudent(v.data)
                    }
                }]
            }
        },
        created() {
            this.$modelGet('schoolYear').then(d => {
                this.schoolYears = d

                if (this.schoolYears.length > 0) {
                    this.selectedYear = d[0]
                }
            })
        },
        methods: {
            loadGuardians() {
                this.loading = true
                this.$refs.crud.read()
                .finally(() => { this.loading = false })
            },
            doSearch(user) {
                if (user) {
                    this.$axios.get('users.php?action=get&active=1&search=' + this.userText)
                    .then(response => {
                        this.userSearchResults = response.data
                        this.userSearchResults.forEach(u => u.user_full_name)
                    })
                    .catch(error => {
                        console.error(error)
                    })
                } else {
                    let p = { name: this.studentText, school_year_id: this.selectedYear.id }

                    this.$modelGet('studentGradeHistory', p).then(v => {
                        this.studentSearchResults = v
                        this.studentSearchResults.forEach(s => s.student_full_name)
                    })
                }
            },
            addGuardian() {
                let me = this

                this.$axios.post('guardians.php?action=create&property=guardian', this.createGuardianDialog.rec)
                .then(response => {
                    if (response.data.success) {
                        me.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                        me.selectedRow = response.data.guardians
                        me.loadStudents()
                    }
                    else {
                        me.$snackbars.$emit('new', { text: response.data.msg, usage: 'error' })
                    }
                })
                .catch(err => {})
            },
            addGuardianUser(addStudents) {
                let me = this

                this.$axios.post('guardians.php?action=create&property=user_guardian', {account_id: this.userSearch.rec.account_id})
                .then(response => {
                    if (response.data.success) {
                        me.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                        me.selectedRow = response.data.guardians
                        if (addStudents) {
                            me.addStudentsFromUser()
                        } else {
                            me.loadGuardians()
                            me.createGuardianFromUser.show = false
                            me.resetGuardianDialog()
                        }
                    } else {
                        me.$snackbars.$emit('new', { text: response.data.msg, usage: 'error' })
                    }
                })
                .catch(err => {})
            },
            addStudentsFromUser() {
                this.studentItems = []
                this.loadStudents()
                this.addStudentsDialog = true
                this.loadGuardians()
                this.createGuardianFromUser.show = false
                this.resetGuardianDialog()
            },
            updateGuardian() {
                this.editGuardianDialog = false
                this.$refs.crud.update(this.selectedRow).finally(() => {
                    this.loadGuardians()
                })
            },
            updateGuardianRelationship(data) {
                let me = this

                this.$axios.post('guardians.php?action=update&property=student', {students: [data]})
                .then(response => {
                    if (response.data.success) {
                        me.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                        me.loadStudents()
                        me.resetStudentDialog()
                    } else {
                        me.$snackbars.$emit('new', { text: response.data.msg, usage: 'error' })
                    }
                })
                .finally(() => {
                    me.loadGuardians()
                })
                .catch(err => {})
            },
            deleteGuardian() {
                this.$confirmDelete(this.selectedRow, () => {
                    this.$refs.crud.destroy(this.selectedRow)
                        .then(response => {
                            this.loadGuardians()
                        })
                }, null, 'You are about to delete guardian <b>' + this.selectedRow.full_name + '</b>. This change is permanent!')
            },
            emailAccountInfo() {
                this.$refs.crud.update(this.selectedRow,{action:'reset_pswd'})
            },
            // deleteGuardian(guardian) {
            //     let me = this
            //     if (guardian) {
            //         this.$confirmDelete(guardian, () => {
            //             me.$refs.crud.destroy(guardian)
            //             .then(() => {
            //                 me.loadGuardians()
            //             })
            //         })
            //     }
            // },

            /* Student Pop Over Info */
            loadStudents() {
                this.$refs.studentsCrud.read({
                    guardian_id: this.selectedRow.id
                })
            },
            createStudent() {
                let me = this
                if (this.studentSearch.rec.student_id) {
                    this.$refs.studentsCrud.create([{guardian_id: this.selectedRow.id, student_id: this.studentSearch.rec.student_id}])
                    .then(() => {
                        me.loadStudents()
                        me.resetStudentDialog()
                    })
                    .finally(() => {
                        me.loadGuardians()
                    })
                }
            },
            addStudentsOpen() {
                this.addGuardian()
                this.studentItems = []
                this.addStudentsDialog = true
                this.loadGuardians()
                this.createGuardianDialog.show = false
                this.createGuardianDialog.rec = {
                    fname: '',
                    lname: '',
                    email_addr: '',
                    send_email: true
                }
            },
            deleteStudent(student) {
                let me = this
                if (student) {
                    this.$confirmDelete(student, () => {
                        me.$refs.studentsCrud.destroy(student)
                            .then(() => {
                                me.loadStudents()
                                me.resetStudentDialog()
                            })
                            .finally(() => {
                                me.loadGuardians()
                            })
                    })
                }
            },
            resetGuardianDialog() {
                this.userSearchResults = []
                this.userText = ''
                this.userSearch.rec = null
                this.createGuardianFromUser.show=false
            },
            resetStudentDialog() {
                this.studentSearchResults = []
                this.studentText = ' '
                this.studentSearch.rec = null
            },
            getStudentImg(student_id) {
                return this.$axios.defaults.baseURL + this.$models.getUrl('studentImage', 'read') + '&id=' + student_id
            },
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep.login-card {
        color: C0C3CF;
        .v-input {
            max-width: 200px !important;
        }
        column-count: 2;
        column-gap: 3em;
    }
</style>
