<template>
    <fe-dialog
        width="500"
        :title="creatingCollection ? 'New Collection' : 'Pin to Collection'"
        v-if="localValue"
        @close="creatingCollection ? creatingCollection = false : localValue=false"
        @dismiss="creatingCollection ? creatingCollection = false : localValue=false"
        @accept="doPin"
        :acceptButtonDisabled="!canSave"
        :acceptButtonText="creatingCollection ? 'Create' : 'Save'"
        disableAutoclose
        :forceClose="false"
        dismissButtonText="Cancel"
    >
        <fe-crud
            autoload
            ref="collectionCrud"
            :config="$models.collection"
            @read="v => collections = v"
            :createParams="{ standalone: 1, analytics: analytics }"
        />

        <v-slide-x-transition mode="out-in">
            <div class="create-collection" v-if="creatingCollection" key="creating">
                <fe-text-field label="Collection Name" class="mb-5" width="100%" v-model="newCollection.title"></fe-text-field>
                <fe-label>Collection Color</fe-label>
                <fe-color-selector v-model="newCollection.color"></fe-color-selector>
            </div>

            <div v-else key="notCreating">
                <v-form v-model="formValid" ref="form">
                    <fe-label>Pin Title</fe-label>
                    <v-text-field
                        v-model="localTitle"
                        autofocus flat solo dense
                        :rules="$fieldValidators('text', 'Title', { required: true, limit: 255 })"
                        :counter="255"
                        placeholder="Enter a title..."
                    />

                    <fe-switch label="Sticky Target Set" v-if="pinParams.dashboard_saved_search_type_id===1" v-model="isSticky"/>
                    <fe-switch label="Sticky Column Filters" v-if="pinParams.dashboard_saved_search_type_id===6" v-model="isSticky"/>

                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <fe-label>Selected Collection</fe-label>
                            <fe-remote-combo
                                :items="collections"
                                itemText="title"
                                itemValue="id"
                                v-model="selectedCollection.id"
                                :rules="$fieldValidators('select', 'Collection', { required: true })"
                                byId
                            >
                                <template v-slot:item="data">
                                    <v-icon :color="data.item.color" class="mr-1" small>fas fa-square</v-icon>
                                    {{ data.item.title }}
                                </template>
                            </fe-remote-combo>
                        </div>

                        <div>
                            <fe-btn usage="secondary" style="margin-top: 19px;" @click="creatingCollection=true">New Collection</fe-btn>
                        </div>
                    </div>


                </v-form>
            </div>
        </v-slide-x-transition>
    </fe-dialog>
</template>

<script>
    export default {
        name: 'PinDialog',

        props: {
            value: {
                required: true
            },
            title: {
                default: ''
            },
            pinParams: {
                required: true
            },
            analytics: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                localValue: false,
                localTitle: undefined,
                formValid: false,
                collections: [],
                selectedCollection: {},
                creatingCollection: false,
                newCollection: {
                    title: '',
                    color: '#2B87FF'
                },
                isSticky: false,
                isBusy: false,
            }
        },

        computed: {
            canSave() {
                if (this.creatingCollection && this.newCollection.title && this.newCollection.color) {
                    return true
                } else if (!this.creatingCollection && this.formValid && !this.isBusy) {
                    return true
                }
                return false
            }
        },

        watch: {
            value(v) {
                if (v) {
                    this.formValid = false
                    this.selectedCollection = {
                    }
                    this.newCollection = {
                        title: this.title || '',
                        color: '#2B87FF'
                    }
                }
                this.localValue = v
            },
            title(v) {
                this.localTitle = v || ''
            },
            localValue(v) {
                this.$emit('input', v)
            }

        },

        mounted() {
            this.localValue = this.$props.value
            this.localTitle = this.$props.title
        },

        methods: {
            doPin() {
                if (this.creatingCollection) {
                    this.$refs.collectionCrud.create([this.newCollection]).then(() => {
                        this.creatingCollection = false
                    }).finally(() => { this.$refs.collectionCrud.read() })
                } else {
                    let r = {...this.$props.pinParams}

                    if (!this.isSticky) delete r.target_set_id
                    r.title        = this.localTitle
                    r.search_title = this.localTitle
                    r.id           = this.selectedCollection.id

                    this.isBusy = true

                    this.$refs.collectionCrud.create([r], this.analytics)
                        .finally(() => {
                            this.isBusy = false
                            this.localValue = false
                        })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.collection-container {
    height: 200px;
    overflow-y: scroll;
}

.collection {
    border: 1px solid #303030;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 4px;
    cursor: pointer;
}
</style>
