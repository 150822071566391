<template>
    <form-common-config
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #fieldSpecific="{rec}">
            <div class="d-flex">
                <fe-label :style="labelStyle">
                    Display Table
                    <v-flex style="display: inline-block; margin-right: 10px">
                        <fe-info-tip tooltip="The table can display any or all demographics for the student" />
                    </v-flex>
                </fe-label>
                <fe-switch style="margin-left:auto;"
                    :value="formFieldSavedSearch.config_json.as_table"
                    :true-value="1" :false-value="0"
                    @input="changeLayout"
                />
            </div>

            <div class="d-flex">
                <fe-label :style="labelStyle">Demographic Search Range</fe-label>
                <fe-remote-combo
                    byId
                    style="margin-left:auto;"
                    v-model="formFieldSavedSearch.config_json.date_type"
                    itemValue="value"
                    :items="formFieldSavedSearch.config_json.as_table ? [
                        {value:'current', name:'Current Year'},
                        {value:'school_year', name:'School Year'},
                        {value:'all', name:'All Years'},
                    ] : [
                        {value:'current', name:'Current Year'},
                        {value:'school_year', name:'School Year'},
                    ]"
                    :clearable="false"
                ></fe-remote-combo>
            </div>
            <div v-if="formFieldSavedSearch.config_json.date_type == 'school_year'" class="d-flex">
                <fe-label :style="labelStyle">School Year</fe-label>
                <fe-remote-combo
                    byId
                    style="margin-left:auto;"
                    v-model="formFieldSavedSearch.school_year_id"
                    rootProperty="years"
                    url="qry.php?action=get&property=school_years"
                />
            </div>

            <div class="d-flex">
                <fe-label :style="labelStyle">
                    As of Creation Date
                    <v-flex style="display: inline-block; margin-right: 10px">
                        <fe-info-tip tooltip="This will ONLY display demographics that were created as of this smartFORM creation date." />
                    </v-flex>
                </fe-label>
                <fe-switch style="margin-left:auto;"
                    v-model="formFieldSavedSearch.config_json.to_creation"
                    :true-value="1" :false-value="0"
                />
            </div>

            <div v-if="formFieldSavedSearch.config_json.as_table">
                <fe-label :style="labelStyle">Demographics</fe-label>
                <fe-remote-combo
                    :key="formFieldSavedSearch.config_json.as_table"
                    byId multiple
                    moreLabel="Demographics"
                    placeholder="All Demographics"
                    style="margin-left:auto;"
                    v-model="formFieldSavedSearch.demographic_id"
                    url="crud.php?property=Demographic&action=get&display_smartform=1"
                    rootProperty="demographic"
                ></fe-remote-combo>
            </div>
            <div v-else>
                <fe-label :style="labelStyle">Demographic</fe-label>
                <fe-remote-combo
                    v-intersect="onShowDemograhicCombo"
                    :key="formFieldSavedSearch.config_json.as_table"
                    ref="demographicCombo"
                    byId
                    moreLabel="Demographic"
                    placeholder="Choose Demographic"
                    style="margin-left:auto;"
                    url="crud.php?property=Demographic&action=get&display_smartform=1"
                    rootProperty="demographic"
                    :rules="$fieldValidators('select', null, { required: true })"
                    v-model="formFieldSavedSearch.demographic_id"
                    @change="updateName($event,rec)"
                ></fe-remote-combo>
            </div>

            <div v-if="formFieldSavedSearch.config_json.as_table">
                <fe-label :style="labelStyle">Table Settings</fe-label>
                <fe-remote-combo
                    byId multiple
                    moreLabel="Columns"
                    style="margin-left:auto;"
                    v-model="formFieldSavedSearch.config_json.display_columns"
                    itemValue="value"
                    :items="[
                        {value:'demographic_name', name:'Demographic Name'},
                        {value:'display_value', name:'Value'},
                        {value:'school_year_name', name:'School Year'},
                        {value:'start_date', name:'Start Date'},
                        {value:'end_date', name:'End Date'},
                        {value:'created', name:'Created'},
                    ]"
                    :clearable="false"
                ></fe-remote-combo>
            </div>

            <fe-crud
                ref="crud"
                :key="rec.id"
                :readParams="{form_field_id:rec.id}"
                :config="$models.formFieldSavedSearch"
                autoload
                @read="processRead($event,rec)"
            />
        </template>
    </form-common-config>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'
import FormCommonConfig from '../FormCommonConfig'
import configMixin from './configMixin'

export default {
    inject: ['localStore'],
    mixins: [ configMixin ],
    components: {
        FormCommonConfig,
    },
    computed: {
        ...mapLocalState([ 'reloadFn', 'selectedComponent' ]),
        labelStyle: me => ({ minWidth:'120px' }),
        currentYear: me => me.$store.state.global.sessionUser.currentYear,
    },
    watch: {
        formFieldSavedSearch: {
            deep: true,
            handler: 'save',
        },
    },
    methods: {
        updateName(v,rec) {
        //     if(v?.name) rec.name = v.name
        //     this.formFieldSavedSearch.demographic_id = v.id
        },
        changeLayout(v) {
            // debugger
            if(v) {
                // if table make sure demo id is array
                this.formFieldSavedSearch.demographic_id = Array.isArray(this.formFieldSavedSearch.demographic_id)
                    ? this.formFieldSavedSearch.demographic_id
                    : [this.formFieldSavedSearch.demographic_id]

            } else {
                // if field make sure it's not array
                this.formFieldSavedSearch.demographic_id = this.formFieldSavedSearch.demographic_id?.length
                    ? this.formFieldSavedSearch.demographic_id[0]
                    : this.formFieldSavedSearch.demographic_id

                if(this.formFieldSavedSearch.config_json.date_type == 'all') {
                    this.formFieldSavedSearch.config_json.date_type = 'current'
                }
            }
            this.formFieldSavedSearch.config_json.as_table = v
        },
        onShowDemograhicCombo() {
            if(!this.formFieldSavedSearch.config_json.as_table) {
                this.$nextTick(() => {
                    this.$refs.demographicCombo.$refs.autocomplete.validate(true)
                })
            }
        },
        processRead(v,rec) {
            this.formFieldSavedSearch = this.processSavedSearch(v, {
                form_field_id: rec.id,
                demographic_id: null,
                school_year_id: this.currentYear.id,
                config_json: {
                    as_table: 0,
                    date_type: 'current',
                    to_creation: 0,
                    display_columns: [
                        'demographic_name',
                        'display_value',
                        'school_year_name',
                        'start_date',
                        'end_date',
                        'created',
                    ],
                }
            })

            if(!this.$_.isBlank(v)) {
                this.cachedValue = JSON.stringify(this.formFieldSavedSearch)
            }
        },
        save() {
            this.onShowDemograhicCombo()
            let dirty = this.cachedValue != JSON.stringify(this.formFieldSavedSearch)
            if(dirty) {
                this.cachedValue = JSON.stringify(this.formFieldSavedSearch)
                let fieldId
                this.$refs.crud.create({...this.formFieldSavedSearch, form_saved_search_type_id: 2}).then( r => {
                    fieldId = r.data?.form_field_ids[0]
                }).finally(() => {
                    // this should be updating the ui when we make changes on the right
                    this.selectedComponent.$refs.localField.formFieldSavedSearch = {
                        ...this.selectedComponent.$refs.localField.formFieldSavedSearch,
                        ...this.formFieldSavedSearch,
                    }
                    this.reloadFn({fieldId:fieldId})
                })
            }
        }
    },
    data() {
        return {
            formFieldSavedSearch: {
                config_json: {},
            },
            cachedValue: {},
        }
    },
}
</script>

<style lang="scss" scoped>
// {
//     demographic_id: ???,  // savedSearch arg
//     config_json: {
//         date_type: [current,all,custom],
//         start_date: date,
//         end_date: date,
//         to_creation: bool, // for use with instance creation date
//     }
// }
</style>
