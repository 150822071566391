<template>
    <fe-dialog
        v-if="active"
        :title="adding ? 'Create Tag' : 'Add New'"
        @dismiss="$emit('cancel')"
    >
        <v-form v-model="valid" v-if="adding">
            <v-layout row wrap>
                <v-flex xs2 class="px-1">
                    <fe-label content="Color"/>
                    <ColorSelector
                        ref="color"
                        :value="obj.color"
                        @change="v => obj.color = v"
                    />
                </v-flex>

                <v-flex xs10 class="px-1">
                    <fe-label content="Tag Name"/>
                    <v-text-field
                        v-model="obj.name"
                        :rules="nameRules"
                        flat solo dense
                        required
                    />
                </v-flex>

                <v-flex xs12 class="px-1">
                    <fe-label content="Description"/>
                    <v-text-field
                        v-model="obj.description"
                        flat solo dense
                    />
                </v-flex>

                <v-flex xs12 class="px-1">
                    <fe-label content="Viewable to Others?"/>
                    <fe-switch
                        v-model="obj.public_flag"
                        :label="obj.public_flag ? 'Yes' : 'No'"
                    />
                </v-flex>
            </v-layout>
        </v-form>

        <fe-grid
            v-else
            domLayout="normal"
            :showToolbar="false"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :styles="{ height: '300px' }"
            @selectionChanged="onSelectionChanged"
        />

        <template v-slot:footer>
            <fe-btn v-if="!adding" usage="tertiary" useIcon="add" @click="create">
                New Tag
            </fe-btn>

            <v-spacer/>

            <fe-btn usage="ghost" @click="cancel">Cancel</fe-btn>

            <fe-btn usage="primary" :disabled="disableAccept" @click="apply">Add</fe-btn>
        </template>
    </fe-dialog>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ColorSelector from '../controls/ColorSelector'

export default {
    name: 'TagSelector',

    components: {
        ColorSelector
    },

    props: {
        active: Boolean,
        hiddenIds: Array
    },

    data() {
        return {
            addedName: null,
            valid: false,
            selectedIds: [],
            hasSelection: false,
            adding: false,
            obj: {
                name: null,
                color: null,
                description: null,
                public_flag: true
            },
            nameRules: [
                v => !!v || 'Name is required',
                v => !!v && this.nameIsUnique(v) || 'Name must be unique'
            ]
        }
    },

    computed: {
        ...mapGetters('thresholds/tag', [
            'collection',
            'clear',
            'pending',
            'success',
            'error'
        ]),

        columnDefs() {
            return [{
                headerName: null,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                width: 70,
                minWidth: 70
            }, {
                headerName: 'Tag',
                field: 'name',
                sortable: true
            }]
        },

        rowData() {
            return this.collection.filter(itm => !(this.hiddenIds.includes(itm.id)))
        },

        selectedData() {
            return this.collection.filter(itm => this.selectedIds.includes(itm.id))
        },

        disableAccept() {
            return (this.adding && !this.valid) || (!this.adding && !this.hasSelection)
        },

        tagNames() {
            return this.$store.getters['thresholds/tag/collection'].map(x => x.name.toLowerCase().trim())
        }
    },

    watch: {
        clear: function (val) {
            if (val) this.reset()
        },

        active: function (val) {
            if (val) this.reset()
        },

        collection: function (val) {
            if (val && this.adding && this.obj.name) {
                let added = this.collection.find(itm => itm.name === this.obj.name)
                if (added && added.id) {
                    this.selectedIds.push(added.id)
                    this.adding = false
                    this.apply()
                }
            }
        }
    },

    mounted() {
        this.reset()
    },

    updated() {
        this.reset()
    },

    methods: {
        ...mapActions('thresholds/tag', [
            'refresh',
            'add'
        ]),

        nameIsUnique(name) {
            return !this.tagNames.includes(name.toLowerCase().trim())
        },

        create() {
            this.obj.name = null
            this.obj.color = null
            this.obj.description = null
            this.obj.public_flag = true
            this.adding = true
        },

        onSelectionChanged(params) {
            this.selectedIds = (params.api)
                ? params.api.getSelectedRows().map(itm => itm.id)
                : []
            this.hasSelection = (this.selectedIds.length > 0)
        },

        apply() {
            if (this.adding) {
                this.obj.name = this.obj.name.trim()
                let result = Object.assign({}, this.obj, {color: this.$refs.color.getHexCode})
                this.add([result])
            } else {
                this.$emit('apply', this.selectedData)
            }
        },

        cancel() {
            if (this.adding) {
                this.adding = false
            } else {
                this.$emit('cancel')
            }
        },

        reset() {
            this.selectedIds = []
            this.hasSelection = false
            if (this.active) {
                if (this.clear) {
                    this.refresh()
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.alert-area {
    padding: 50px;
    border: solid 1px #fff;
}
</style>
