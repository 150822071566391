<template>
    <v-layout column>
        <component
            v-for="(item, i) in items"
            :key="'attendance-item-' + i"
            v-if="item.persist || activePage.show === item.show"
            v-show="activePage.show === item.show"
            :is="item.component"
            :prefs="prefs"
            :detail="performanceBandDetail"
            :updateDescriptors="updateDescriptors"
            @pref-update="prefUpdate"
            @count-update="getCounts"
            @descriptor-update="descriptorUpdate"
            @detail-view="detailView"
            @go="go"
        />

        <fe-crud ref="prefsCrud" :config="prefsCrudConfig" @read="prefsFetched" autoload/>
        <fe-crud ref="yearsCrud" :config="$models.schoolYear" autoload @read="years = $event"/>
        <fe-crud ref="schoolsCrud" :config="$models.school" autoload @read="schools = $event"/>
    </v-layout>
</template>

<script>
    import {mapState} from "vuex"
    import AttendanceTerms from "./AttendanceTerms"
    import AttendancePeriods from "./AttendancePeriods"
    import AttendanceCodes from "./AttendanceCodes"
    import AttendanceStatuses from "./AttendanceStatuses"
    import AttendanceReasons from "./AttendanceReasons"
    import AttendancePerformanceBands from "./AttendancePerformanceBands"
    import AttendancePerformanceBandDetail from "./AttendancePerformanceBandDetail"
    import AttendanceRateAdjustments from "./AttendanceRateAdjustments"
    import Breadcrumb from "../../../common/Breadcrumb"
    import LandingPage from "./LandingPage"

    export default {
        name: "ManageAttendance",

        components: {
            AttendanceTerms,
            AttendancePeriods,
            AttendanceCodes,
            AttendanceStatuses,
            AttendanceReasons,
            AttendancePerformanceBands,
            AttendancePerformanceBandDetail,
            AttendanceRateAdjustments,
            Breadcrumb,
            LandingPage
        },

        data() {
            return {
                years: null,
                schools: null,
                counts: {
                    terms: 0,
                    periods: 0,
                    statuses: 0,
                    reasons: 0,
                    excused: 0,
                    target_sets: 0
                },
                prefs: {},
                performanceBandDetail: {},
                updateDescriptors: false
            }
        },

        computed: {
            ...mapState('manage', ['breadcrumbs', 'districtPreferences']),

            prefsCrudConfig() {
                let cfg = _.cloneDeep(this.$models.preferences)
                cfg.read.params.property = 'district'
                return cfg
            },

            activePage() {
                let b = this.breadcrumbs["Attendance"]

                let lastPage = b[b.length - 1]

                if (lastPage.show === 'index') {
                    this.getCounts()
                } else {
                    let event = 'show ' + lastPage.show
                    this.$emit(event)
                }


                return lastPage
            },

            items() {
                let pages = [{
                    name: 'Attendance',
                    show: 'index',
                    persist: false,
                    component: 'landing-page'
                }, {
                    name: this.performanceBandDetail.name,
                    show: 'attendance-performance-band-detail',
                    persist: true,
                    component: 'attendance-performance-band-detail'
                }, {
                    name: 'Terms',
                    display: 'Terms <span class="ml-4">' + (this.counts.terms || 0) + '<span>',
                    show: 'terms',
                    persist: true,
                    component: 'attendance-terms'
                }, {
                    name: 'Periods',
                    display: 'Periods <span class="ml-4">' + (this.counts.periods || 0) + '<span>',
                    show: 'periods',
                    persist: true,
                    component: 'attendance-periods'
                }, {
                    name: 'Statuses',
                    display: 'Statuses <span class="ml-4">' + (this.counts.statuses || 0) + '<span>',
                    show: 'statuses',
                    persist: true,
                    component: 'attendance-statuses'
                }, {
                    name: 'Reasons',
                    display: 'Reasons <span class="ml-4">' + (this.counts.reasons || 0) + '<span>',
                    show: 'reasons',
                    persist: true,
                    component: 'attendance-reasons'
                }, {
                    name: 'Excused Codes',
                    display: 'Excused Codes <span class="ml-4">' + (this.counts.excused || 0) + '<span>',
                    show: 'codes',
                    persist: true,
                    component: 'attendance-codes'
                }, {
                    name: 'Performance Bands',
                    display: 'Performance Bands <span class="ml-4">' + (this.counts.target_sets) || 0 + '<span>',
                    show: 'performanceBands',
                    persist: true,
                    component: 'attendance-performance-bands'
                }]

                if (this.districtPreferences.ATTENDANCE_ADJUSTED_RATE) {
                    pages.push({
                        name: 'Rate Adjustment',
                        display: 'Rate Adjustment <span class="ml-4">' + (this.counts.rate_adjustments) || 0 + '<span>',
                        show: 'rateAdjustment',
                        persist: true,
                        component: 'attendance-rate-adjustments'
                    })
                }

                return pages
            }
        },

        mounted() {
            this.getCounts()
        },

        methods: {
            go(page) {
                this.$store.commit('manage/addCrumb', {
                    key: "Attendance",
                    val: page
                })
            },

            getCounts() {
                this.$axios.get('qry.php?action=get&property=manage_attendance_counts')
                    .then(response => {
                        this.counts = response.data.counts;
                    })
            },

            detailView(item) {
                this.performanceBandDetail = item
                item.show = 'attendance-performance-band-detail'
                this.$store.commit('manage/addCrumb', {
                    key: "Attendance",
                    val: item
                })
            },

            prefsFetched(v) {
                let prefs = {}
                let hasAdjustedRateValue = false
                v.forEach(pref => {
                    prefs[pref.code] = pref.user_value !== "0"
                    if (pref.code === 'ATTENDANCE_ADJUSTED_RATE') hasAdjustedRateValue = true
                })

                if (!hasAdjustedRateValue) {
                    prefs.ATTENDANCE_ADJUSTED_RATE = false
                }

                this.prefs = prefs

                this.$store.commit('set', {
                    module: 'manage',
                    state: 'districtPreferences',
                    value: prefs
                })
            },

            prefUpdate(data) {
                this.$refs.prefsCrud
                    .update(data)
                    .then(() => {
                        this.$refs.prefsCrud.read()
                    })
            },

            descriptorUpdate() {
                /** Not really a true Boolean. Just a trigger that the descriptors have changed. **/
                this.updateDescriptors = !this.updateDescriptors
            }
        }
    }
</script>

<style lang="scss" scoped>
    .manage-toolbar {
        padding-top: 26px;
        padding-bottom: 20px;
    }
</style>
