<template>
    <div class="d-flex flex-fill flex-column px-2 pb-10">
        <fe-grid
            ref="grid"
            class="incident-grid"
            displayDensity="small"
            style="height: 100%"
            :columnDefs="columns"
            :title="showHeader ? title : ''"
            :rowData="gridItems"
            :showToolbar="showHeader"
            :showAddRowBtn="false"
            :detailCellRendererParams="detailCellRendererParams"
            :detailRowHeight="detailRowHeight"
            :gridOptionOverrides="gridOptions"
            :csvExportProcessCellCallback="csvExportProcessCellCallback"
            masterDetail
            showScrollbars
            @rowDoubleClicked="loadIncident"
            @gridReady="onGridReady"
        >
            <template v-if="showAddRowBtn" v-slot:toolbar-items>
                <fe-btn @click="createIncident">New</fe-btn>
            </template>
        </fe-grid>
    </div>
</template>

<script>
    import Vuex, {mapState} from 'vuex'
    import IFrameRenderer from '@/components/common/IFrameRenderer'
    import Vue from 'vue'

    let cellIcon = Vue.extend({
        template: "<span v-if='!params.node.expanded' style='cursor: pointer'><i class='fe-grid-icon fas fa-chevron-down'></i></span>\n" +
            "<span v-else style='cursor: pointer'><i class='fe-grid-icon fas fa-chevron-up'></i></span>"
    })

    export default {
        name: 'IncidentGrid',

        components: {
            IFrameRenderer
        },

        props: {
            params: {
                required: true
            },
            title: {
                type: String,
                default: 'Student Incidents'
            },
            rowData: Array,
            showHeader: {
                type: Boolean,
                default: true
            },
            showAddRowBtn: {
                type: Boolean,
                default: true
            },
        },

        data() {
            return {
                gridItems: [],
                columns: [
                    {
                        headerName: 'Details',
                        minWidth: 80,
                        onCellClicked: this.onCellClicked,
                        cellRendererFramework: cellIcon,
                        cellStyle: {
                            textAlign: 'center'
                        }
                    },
                    {
                        headerName: 'School Year',
                        field: 'school_year_name',
                        sortable: true,
                        minWidth: 130
                    },
                    {
                        headerName: 'School',
                        field: 'school_name',
                        sortable: true,
                        minWidth: 150,
                        maxWidth: 250
                    },
                    {
                        headerName: 'Grade',
                        field: 'grade_desc',
                        sortable: true,
                        minWidth: 100,
                        maxWidth: 250
                    },
                    {
                        headerName: 'Occurrence',
                        field: 'incident_datetime',
                        valueFormatter: v => this.$dayjs(v.data.incident_datetime).format('YYYY-MM-DD'),
                        sortable: true,
                        minWidth: 150,
                        maxWidth: 250
                    },
                    {
                        headerName: "Student",
                        field: "student_full_name",
                        sortable: true,
                        minWidth: 150,
                        maxWidth: 250
                    },
                    {
                        headerName: 'Student ID',
                        field: 'student_district_id',
                        sortable: true,
                        minWidth: 150,
                        maxWidth: 250,
                        hide: true
                    },
                    {
                        headerName: 'Student State ID',
                        field: 'student_state_id',
                        sortable: true,
                        minWidth: 150,
                        maxWidth: 250,
                        hide: true
                    },
                    {
                        headerName: "Rostered School",
                        field: "student_school_name",
                        sortable: true,
                        minWidth: 150,
                        maxWidth: 250
                    },
                    {
                        headerName: 'Primary Incident Type',
                        field: 'incident_behavior_type_name',
                        sortable: true,
                        minWidth: 150,
                        maxWidth: 250
                    },
                    {
                        headerName: 'Primary Incident Behavior',
                        field: 'incident_behavior_name',
                        sortable: true,
                        minWidth: 170,
                        maxWidth: 250
                    },
                    {
                        headerName: 'Location',
                        field: 'incident_location_name',
                        sortable: true,
                        minWidth: 150,
                        maxWidth: 250
                    },
                    {
                        headerName: 'Primary Response',
                        field: 'incident_response_name',
                        sortable: true,
                        minWidth: 150,
                        maxWidth: 250
                    },
                    {
                        headerName: 'Observer',
                        field: 'observer_user_full_name',
                        sortable: true,
                        minWidth: 150,
                        maxWidth: 250
                    },
                    {
                        headerName: 'Perceived Motivation',
                        field: 'incident_motivation_name',
                        sortable: true,
                        minWidth: 150,
                        maxWidth: 250
                    },
                    {
                        headerName: 'Ethnicity',
                        field: 'student_ethnicity',
                        sortable: true,
                        minWidth: 150,
                        maxWidth: 250,
                        hide: true
                    },

                ],
                showIncidentEdit: false,
                showIncidentCreate: false,
                incidentId: null,
                detailCellRendererParams: null,
                detailRowHeight: null,
                gridOptions: {
                    // to export detail row
                    defaultExportParams: {},
                    suppressContextMenu: true,
                    detailRowAutoHeight: true
                },
                columnApi: null,
            }
        },

        computed: {
            ...mapState('global', ['sessionUser']),

            urlParams() {
                return {
                    params: {user_id: this.sessionUser.user.id},
                    identifier: new Date().getMilliseconds()
                }

            },

            useNewIncidentCreation() { return this.$store.getters['flags/flags']['ec-incident-repo-move'] === true },
        },

        watch: {
            params: {
                deep: true,
                handler() {
                    this.gridItems = []
                    this.loadData()
                }
            },
        },

        created() {
            window.addEventListener('beforeunload', this.editHeaders)
        },

        beforeMount() {
            this.detailCellRendererParams = {
                template: (v) => {
                    let val = '<div ref="eDetailGrid"><div style="height: 100%; padding: 10px; line-height: 20px;">' +
                        '<div ref="incident-incidents" class="incident-grid-description pa-2">' +
                        '<span style="font-weight: 700; font-size: 14px;">Incidents </span>'
                        if(v.data?.behaviors?.length) {
                            v.data.behaviors.forEach(behavior => {
                                val += '<div style="font-size: 12px;">' + behavior.display_name 
                                if(behavior.primary_flag) val +=' <span class="incident-grid-primary-chip">Primary</span>'
                                val += '</div>' 
                            })
                        }
                        val += '</div>'
                        if(v.data?.description) {
                            val += '<div ref="incident-desc" class="incident-grid-description pa-2">' +
                                '<span style="font-weight: 700; font-size: 14px;">Description </span>' + 
                                '<div style="font-size: 12px; white-space: normal;">' + v.data.description + '</div>' + 
                            '</div>'
                        }
                        if(v.data?.responses?.length) {
                            val += '<div ref="incident-responses" class="incident-grid-description pa-2">' +
                                '<span style="font-weight: 700; font-size: 14px;">Responses </span>'
                        }
                        if(v.data?.responses?.length) {
                            v.data.responses.forEach(response => {
                                val += '<div style="font-size: 12px;">' + response.incident_response_name
                                if(response.primary_flag) val +=' <span class="incident-grid-primary-chip">Primary</span>'
                                val += '</div>' 
                            })
                        }
                        if(v.data?.responses?.length) val += '</div>'
                        val += '</div></div>'
                    return val
                },
                detailGridOptions: {
                    columnDefs: [
                        {
                            headerName: 'Incident Type',
                            field: 'incident_behavior_display_name',
                            sortable: true,
                            minWidth: 150,
                            maxWidth: 250
                        },
                        {
                            headerName: 'Incident Behavior',
                            field: 'incident_behavior_name',
                            sortable: true,
                            minWidth: 170,
                            maxWidth: 250
                        },
                        {
                            headerName: 'Response',
                            field: 'incident_response_name',
                            sortable: true,
                            minWidth: 150,
                            maxWidth: 250
                        }
                    ],
                    suppressContextMenu: true,
                    defaultColDef: {
                        cellStyle: {
                            'white-space': 'normal',
                            'margin': 'auto',
                        }
                    },
                },
            }
        },

        mounted() {
            this.loadData()
        },

        methods: {
            loadData() {
                if (this.rowData) {
                    this.gridItems = this.rowData
                } else {
                    this.$setLoading(true)
                    this.$axios.get(this.$models.getUrl('studentIncidentList', 'read') + '&' + this.$objectToParams(this.params))
                        .then(response => {
                            this.gridItems = response.data.incidents
                            this.$setLoading(false)
                        })
                }
            },

            onCellClicked(rowNode) {
                let api = this.$refs.grid.gridOptions.api;
                api.getDisplayedRowAtIndex(rowNode.node.rowIndex).setExpanded(!rowNode.node.expanded)
                api.refreshCells({force: true})
            },

            loadIncident(meta) {
                if(this.useNewIncidentCreation) {
                    this.$store.commit('global/addDockableWindow', {
                        name: 'Edit Incident',
                        component: 'incident-creation',
                        attrs: {
                            params: {
                                user_id: this.sessionUser.user.id,
                                incident_id: meta.data.incident_id
                            },
                            identifier: new Date().getMilliseconds()
                        }
                    })
                } else {
                    this.$store.commit('global/addDockableWindow', {
                        name: 'Edit Incident',
                        component: 'i-frame-renderer',
                        attrs: {
                            app: 'incident_creation',
                            cfg: {
                                params: {
                                    user_id: this.sessionUser.user.id,
                                    incident_id: meta.data.incident_id
                                },
                                identifier: new Date().getMilliseconds()
                            }
                        }
                    })
                }
            },

            createIncident() {
                if(this.useNewIncidentCreation) {
                    this.$store.commit('global/addDockableWindow', {
                        name: 'Create Incident',
                        component: 'incident-creation',
                        attrs: {}
                    })
                } else {
                    this.$store.commit('global/addDockableWindow', {
                        name: 'Create Incident',
                        component: 'i-frame-renderer',
                        attrs: {
                            app: 'incident_creation'
                        }
                    })
                }
            },

            incidentUpdate(type) {
                this.$emit('update')
                this.loadData()
                if(type === 'edit') {
                    this.showIncidentEdit = false
                } else {
                    this.showIncidentCreate = false
                }
            },

            resize() {
                this.$refs.grid.resize()
            },

            calculateDetailRowHeight(params) {
                // added timeout here since it was trying to find the class before
                // the element was in the DOM (e.g. when user clicks open)
                setTimeout(function () {
                    let height = 50
                    if (params.node && params.node.detail && document.querySelectorAll(".incident-grid-description").length > 0) {
                        // offset makes single line description height have no scroll
                        height = document.querySelectorAll(".incident-grid-description")[0].offsetHeight + 18
                    }
                    params.node.setRowHeight(height)
                    this.$refs.grid.$refs.grid.gridOptions.api.onRowHeightChanged()
                }.bind(this), 100)
            },

            csvExportProcessCellCallback(params) {
                if (params.value === undefined) {
                    if (params.node?.data?.description) {
                        return params.node.data.description
                    }
                }
                return params.value
            },

            onGridReady(grid) {
                let me = this
                this.columnApi = grid.columnApi
                let previousHeaders = window.localStorage.getItem('ec-incident-big-five-headers') || '[]'
                previousHeaders = JSON.parse(previousHeaders)
                if(previousHeaders.length) {
                    previousHeaders.forEach(head => {
                        this.columns.find(x => x.field == head.field).hide = head.hide
                        grid.api.setColumnDefs(this.columns)
                    })
                }
                // add global listener to set local storage on visibility change for Ethnicity column
                // so that going from datawall -> charts -> datawall it remains the same
                grid.api.addGlobalListener(function(type, event) {
                    if(type.indexOf("column") >= 0) {
                        if(event.type == "columnVisible" && event.column.colId == "student_ethnicity") {
                            me.columns.forEach(head => {
                                head.hide = !me.columnApi.getAllColumns().find(col => col.colDef.field == head.field).visible
                            })
                            window.localStorage.setItem('ec-incident-big-five-headers', JSON.stringify(me.columns))
                        }
                    }
                });
            },

            editHeaders() {
                this.columns.forEach(head => {
                    head.hide = !this.columnApi.getAllColumns().find(col => col.colDef.field == head.field).visible
                })
                window.localStorage.setItem('ec-incident-big-five-headers', JSON.stringify(this.columns))
            }
        }
    }
</script>

<style lang="scss">
    .incident-grid-primary-chip {
        background: #0EA449; //#4CAF50;
        color: #fff;
        border-radius: 16px;
        padding: 3px 6px 3px 6px;
        height: 16px;
        width: 48px;
        margin-left: 8px;
        font-size: 11px
    }
</style>

<style lang="scss" scoped>
    .incident-grid {
        ::v-deep .ag-details-row {
            padding: 0 !important;
        }

        ::v-deep .ag-details-grid {
            .ag-header {
                display: none !important;
            }

            .ag-cell {
                font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
            }
        }

        ::v-deep .ag-center-cols-container {
            height: 100% !important;
            width: 100% !important;
            .ag-cell-with-height {
                height: fit-content !important;
            }
            [col-id="description"] {
                width: 100% !important;
                overflow: visible !important;
            }
        }
    }
</style>
