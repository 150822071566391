<template>
    <div class="pl-10 pr-10 d-flex flex-column flex-fill">
        <student-data-manager
                v-if="studentDialog.show"
                @showStudentManagerClicked="studentDialog.show=false"
                :selectedStudent="studentDialog.rec"
        />

        <div v-if="!scoreConflicts" class="d-flex flex-column flex-fill">
            <div class="d-flex justify-end">
                <div>
                    <v-select
                            :items="['By ID', 'By Name']"
                            v-model="viewMergeBy"
                            flat solo dense
                            style="width: 200px"
                    />
                </div>
            </div>

            <fe-grid style="height:100%"
                     ref="mergeGrid"
                     class="student-merge-grid"
                     displayDensity="small"
                     :columnDefs="mergeColumnDefs"
                     :rowData="mergeData"
                     :showToolbar="false"
                     @modelUpdated="bringFocusBack('mergeGrid')"
                     :disableFilterUpdate="true"
            />

            <div class="py-2 d-inline-flex">
                <fe-label class="mr-4">Include Students Marked "Do Not Merge"</fe-label>
                <fe-switch hide-details v-model="includeDoNotMerge" />
            </div>
        </div>

        <div v-if="scoreConflicts" class="d-flex flex-column flex-fill">
            <div>
                <fe-btn usage="secondary" @click="keepAll('s1_keep', 's2_keep')">Keep All Left</fe-btn>
                <fe-btn usage="secondary" @click="keepAll('s2_keep', 's1_keep')">Keep All Right</fe-btn>
            </div>

            <fe-grid style="height:100%"
                     ref="scoreGrid"
                     class="score-merge-grid"
                     displayDensity="small"
                     :columnDefs="scoreMergeColumnDefs"
                     :rowData="mergeScoreData"
                     :showToolbar="false"
            />

            <div class="d-flex justify-end">
                <fe-btn :disabled="!canSaveScores" @click="finishScoreMerge">Save</fe-btn>
            </div>

        </div>

        <fe-crud
                ref="crud"
                :autoload="false"
                :config="$models.studentGradeHistory"
                :failOnEmptyResponse="false"
        />

        <fe-crud
                ref="doNotMergeCrud"
                :autoload="false"
                :config="$models.studentDoNotMerge"
                disableSnackbars
        />
    </div>
</template>

<script>
    import StudentDataManager from '@/components/modules/student/Index'

    export default {
        name: "StudentsMerge",
        components: {
            StudentDataManager,
        },
        data() {
            return {
                viewMergeBy: 'By Name',
                mergeById: [],
                mergeByName: [],
                mergeScoreData: [],
                includeDoNotMerge: false,
                scoreConflicts: false,
                studentDialog: {
                    show: false,
                    rec: null
                },
            }
        },
        mounted() {
            this.loadMerge()
        },
        computed: {
            canSaveScores() {
                if (this.mergeScoreData.length === 0) return false

                let valid = true
                this.mergeScoreData.forEach(rec => {
                    if (!rec.s1_keep && !rec.s2_keep) valid = false
                })
                return valid
            },
            canMerge() {
                return this.mergeByName.length + this.mergeById.length
            },
            recsToMerge() {
                return this.mergeByName.filter(x => x.s1_keep || x.s2_keep)
            },
            mergeData() {
                return this.viewMergeBy === 'By ID' ? this.mergeById : this.mergeByName
            },
            mergeColumnDefs() {
                return [{
                    headerName: 'Name',
                    field: 's1_student_full_name',
                    width:180,
                    cellStyle: Object.assign({ cursor: 'pointer' }, this.cellStyle),
                    cellRenderer: v => {
                        return v.value + '<i style="font-size:12px;" class="fe-grid-icon fas fa-id-card-alt ml-2 grey--text text--lighten-1"></i>'
                    },
                    onCellClicked: this.openStudentCard,
                }, {
                    headerName: 'IDs',
                    field: 's1_ids',
                    width:260,
                    cellStyle: this.cellStyle,
                    cellRenderer(v) { return v.value ? v.value.replace(/\<br\>/g,', ') : '' }
                }, {
                    headerName: 'Date of Birth',
                    field: 's1_date_of_birth',
                    width:200,
                    cellStyle: this.cellStyle
                }, {
                    headerName: 'Keep Left Student',
                    maxWidth: 90,
                    headerClass: 'user-merge-header',
                    cellStyle: {cursor: 'pointer', textAlign: 'center'},
                    cellRenderer: v => {
                        return v.data.s1_keep
                            ? '<i class="fe-grid-icon fas fa-check-square theme--light primary--text"></i>'
                            : '<i class="fe-grid-icon fas fa-arrow-circle-left theme--light"></i>'
                    },
                    onCellClicked: this.keepLeft,
                }, {
                    headerName: 'Do Not Merge',
                    field: 'distinct_students',
                    maxWidth: 90,
                    headerClass: 'user-merge-header',
                    cellStyle: {cursor: 'pointer', textAlign: 'center'},
                    cellRenderer: v => {
                        return v.data.distinct_students
                            ? '<i class="fe-grid-icon fas fa-check-square theme--light primary--text"></i>'
                            : '<i class="fe-grid-icon fas fa-minus-circle theme--light"></i>'
                    },
                    onCellClicked: this.doNotMerge,
                }, {
                    headerName: 'Keep Right Student',
                    maxWidth: 90,
                    headerClass: 'user-merge-header',
                    cellStyle: {cursor: 'pointer', textAlign: 'center'},
                    cellRenderer: v => {
                        return v.data.s2_keep
                            ? '<i class="fe-grid-icon fas fa-check-square theme--light primary--text"></i>'
                            : '<i class="fe-grid-icon fas fa-arrow-circle-right theme--light"></i>'
                    },
                    onCellClicked: this.keepRight,
                }, {
                    headerName: 'Name',
                    field: 's2_student_full_name',
                    width:180,
                    cellStyle: this.cellStyle,
                    cellRenderer: v => {
                        return v.value + '<i style="font-size:12px;" class="fe-grid-icon fas fa-id-card-alt ml-2 grey--text text--lighten-1"></i>'
                    },
                    onCellClicked: this.openStudentCard,
                }, {
                    headerName: 'IDs',
                    field: 's2_ids',
                    width:260,
                    cellStyle: this.cellStyle,
                    cellRenderer(v) { return v.value ? v.value.replace(/\<br\>/g,', ') : '' }
                }, {
                    headerName: 'Date of Birth',
                    field: 's2_date_of_birth',
                    width:200,
                    cellStyle: this.cellStyle
                }]
            },
            scoreMergeColumnDefs() {
                let me = this
                return [{
                    headerName: 'School Year',
                    field: 'school_year_name',
                }, {
                    headerName: 'Assessment Group',
                    field: 'data_point_type_name',
                }, {
                    headerName: 'Assessment',
                    field: 'sub_category_display_name',
                }, {
                    headerName: 'Window',
                    field: 'data_point_name',
                }, {
                    headerName: 'Keep Student Score',
                    field: 'keep_student_score',
                    width:180,
                    cellStyle: this.cellStyle
                }, {
                    headerName: 'Keep Left',
                    maxWidth: 90,
                    headerClass: 'user-merge-header',
                    cellStyle: {cursor: 'pointer', textAlign: 'center'},
                    cellRenderer: v => {
                        return v.data.s1_keep
                            ? '<i class="fe-grid-icon fas fa-check-square theme--light primary--text"></i>'
                            : '<i class="fe-grid-icon fas fa-arrow-circle-left theme--light"></i>'
                    },
                    onCellClicked(v) {
                        me.keepScore('s1_keep', 's2_keep', v)
                    }
                }, {
                    headerName: 'Keep Right',
                    maxWidth: 90,
                    headerClass: 'user-merge-header',
                    cellStyle: {cursor: 'pointer', textAlign: 'center'},
                    cellRenderer: v => {
                        return v.data.s2_keep
                            ? '<i class="fe-grid-icon fas fa-check-square theme--light primary--text"></i>'
                            : '<i class="fe-grid-icon fas fa-arrow-circle-right theme--light"></i>'
                    },
                    onCellClicked(v) {
                        me.keepScore('s2_keep', 's1_keep', v)
                    }
                }, {
                    headerName: 'Merge Student Score',
                    field: 'merge_student_score',
                    width:180,
                    cellStyle: this.cellStyle
                }]
            },
        },
        watch: {
            includeDoNotMerge() {
                this.loadMerge()
            },
        },
        methods: {
            loadMerge() {
                this.mergeDataIdIndex = []
                let incl = this.includeDoNotMerge ? 1 : !this.includeDoNotMerge ? 0 : this.includeDoNotMerge
                this.$modelGet('studentMerge', { property: 'students', include_distinct: incl }).then(items => {
                    this.mergeById = items
                    items.forEach(i => { this.mergeDataIdIndex[i.s1_id] = 1; this.mergeDataIdIndex[i.s2_id] = 1})
                })
                this.$modelGet('studentMerge', { property: 'students', method: 'name', include_distinct: incl }).then(items => {
                    this.mergeByName = items
                    items.forEach(i => { this.mergeDataIdIndex[i.s1_id] = 1; this.mergeDataIdIndex[i.s2_id] = 1})
                })
            },
            cellStyle(v) {
                let backgroundColor = '#f5f6f8'
                let color = 'rgba(0,0,0,.87)'
                if(v.data.s1_keep === v.data.s1_id && v.colDef.field.startsWith('s1')) backgroundColor = 'var(--fe-selected)'
                if(v.data.s2_keep === v.data.s2_id && v.colDef.field.startsWith('s2')) backgroundColor = 'var(--fe-selected)'

                return Object.assign({
                    color: color,
                    backgroundColor: backgroundColor
                }, v.colDef.field === 's1_student_full_name' || v.colDef.field === 's2_student_full_name' || v.colDef.field === 'student_full_name' ? { cursor: 'pointer'} : {})
            },
            keepScore(keep, drop, meta) {
                let data = meta.data
                data[keep] = true
                data[drop] = false
                this.mergeScoreData = this.mergeScoreData.map(x=>x)
                this.$refs.scoreGrid.gridApi.redrawRows(meta.node)
            },
            openStudentCard(v) {
                if (v.colDef.field === 's1_student_full_name') {
                    this.studentDialog.rec = {
                        student_id: v.data.s1_id,
                        fname: v.data.s1_fname,
                        lname: v.data.s1_lname
                    }
                } else {
                    this.studentDialog.rec = {
                        student_id: v.data.s2_id,
                        fname: v.data.s2_fname,
                        lname: v.data.s2_lname
                    }
                }

                this.studentDialog.show = true
            },
            keepLeft(v) {
                v.data.s1_keep = true
                v.data.s2_keep = false
                this.mergeByName = this.mergeByName.map(x=>x)
                this.$refs.mergeGrid.gridApi.redrawRows(v.node)

                this.checkDuplicates(v.data)
            },
            keepRight(v) {
                v.data.s1_keep = false
                v.data.s2_keep = true
                this.mergeByName = this.mergeByName.map(x=>x)
                this.$refs.mergeGrid.gridApi.redrawRows(v.node)

                this.checkDuplicates(v.data)
            },
            keepAll(keep, drop) {
                let me = this
                // let label = (keep === 's1_keep' ? 'Keep Left' : 'Keep Right')
                me.mergeScoreData.forEach(rec => {
                    rec[keep] = true
                    rec[drop] = false
                })
                this.mergeScoreData = this.mergeScoreData.map(x=>x)
                this.$refs.scoreGrid.gridApi.redrawRows()
            },
            doNotMerge(v) {
                let me = this

                if (v.data.distinct_students === 1) {
                    let params = {
                        id: v.data.distinct_student_id
                    }

                    me.$refs.doNotMergeCrud.destroy(params)
                        .then(response => {
                            if(response.data.success) {
                                let successMsg = "Successfully added records back into the default table view"
                                this.$snackbars.$emit('new', { text: successMsg, usage: 'success' })
                            } else {
                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                            }
                        }).finally(() => {
                        me.loadMerge()
                        me.bringFocusBack('mergeGrid')
                    })
                } else {
                    let params = {
                        student1_id: v.data.s1_id > v.data.s2_id ? v.data.s2_id : v.data.s1_id,
                        student2_id: v.data.s1_id > v.data.s2_id ? v.data.s1_id : v.data.s2_id
                    }
                    me.$messageBox({
                        title: 'Confirm Do Not Merge',
                        persistent: true,
                        maxWidth: 600,
                        message: 'Are you sure you want to complete this action? By selecting “Do Not Merge” you are acknowledging that these two students are distinct and should be removed from the default view of this table. You can always undo this action later.',
                        actions: [{
                            text: 'Cancel', primary: false,
                            onClick: () => {
                                me.bringFocusBack('mergeGrid')
                            }
                        }, {
                            text: 'Confirm', primary: true,
                            onClick: () => {
                                me.$refs.doNotMergeCrud.create(params)
                                    .then(response => {
                                        if(response.data.success) {
                                            let successMsg = "Successfully removed records from the default table view"
                                            this.$snackbars.$emit('new', { text: successMsg, usage: 'success' })
                                        } else {
                                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                                        }
                                    }).finally(() => {
                                    me.loadMerge()
                                    me.bringFocusBack('mergeGrid')
                                })
                            }
                        }]
                    })
                }
            },
            checkDuplicates(rec) {
                let keepId = rec.s1_keep ? rec.s1_id : rec.s2_id
                let mergeId = rec.s1_keep ? rec.s2_id : rec.s1_id
                let params = { keep_student_id: keepId, merge_student_id: mergeId }
                this.$modelGet('studentDuplicateScore', params)
                    .then(response => {
                        if (!response) {
                            return
                        }

                        if (response.length === 0) {
                            this.commitMerge(rec, params, true)
                        } else {
                            this.$parent.$parent.title = 'Score Conflicts'
                            this.mergeScoreData = response
                            this.scoreConflicts = rec
                        }
                    })
            },
            finishScoreMerge() {
                let me = this
                let duplicates = []
                let rec = this.scoreConflicts
                let valid = true
                this.$confirmCreate(this.mergeScoreData, () => {
                    me.mergeScoreData.forEach(rec => {
                        // This code fixes the known merge issues EC-3639
                        me.mergeScoreData.forEach(rec => {
                            if (rec.s1_keep || rec.s2_keep) {
                                duplicates.push({
                                    student_id: rec.keep_student_id,
                                    data_point_id: rec.data_point_id,
                                    // data_point_score_id: rec.keep_student_data_point_score_id,
                                    merge: rec.s2_keep
                                })
                            } else {
                                valid = false
                            }
                        })
                    })
                    if (valid) {
                        let keepId = rec.s1_keep ? rec.s1_id : rec.s2_id
                        let mergeId = rec.s1_keep ? rec.s2_id : rec.s1_id
                        let params = { keep_student_id: keepId, merge_student_id: mergeId }
                        params.duplicate_scores = JSON.stringify(duplicates)

                        this.commitMerge(rec, params)
                    }
                }, 'merge')
                this.$parent.$parent.title = 'Merge Students'
            },
            commitMerge(rec, params, confirm) {
                if (confirm) {
                    this.$confirmCreate([rec], () => {
                        this.$axios.post('studentMerge.php?action=merge', params).then(response => {
                            this.$ecResponse(response)
                        }).finally(() => {
                            this.scoreConflicts = false
                            this.loadMerge()
                        })
                    }, 'Merge', () => {
                        rec.s1_keep = false
                        rec.s2_keep = false
                        this.$refs.mergeGrid.gridApi.redrawRows()
                    })
                } else {
                    this.$axios.post('studentMerge.php?action=merge', params).then(response => {
                        this.$ecResponse(response)
                    }).finally(() => {
                        this.scoreConflicts = false
                        this.loadMerge()
                    })
                }
            },
            bringFocusBack(type) {
                let grid = type === 'grid' ? this.$refs.grid : this.$refs.mergeGrid
                let cell = grid.gridOptions.api.getFocusedCell()

                if ( cell ) {
                    this.$nextTick(() => {
                        grid.gridApi.ensureIndexVisible(cell.rowIndex, 'middle')
                        this.$flashGridRow(grid,cell.rowIndex,3000)
                    })
                }
            },
            closeMergeWindow() {
                this.$parent.$parent.closeWindow()
            },
        }
    }
</script>

<style scoped>

</style>
