<template>
<div>
    <button @click="dialog=true" >{{displayName}}</button>
    <v-dialog v-model="dialog" width="600">
        <fe-stepper-filter
            :title="title" style="height:600px;"
            @apply="emit" @close="dialog=false"
            :applyDisabled="!formValid || !enableFinalPanel"
        >

        <fe-stepper-panel width="100%" style="padding:20px;">
            <v-form lazy-validation v-model="formValid">
                <v-layout row>
                    <v-flex xs6 class="pr-2">
                        <fe-date-picker
                            ref="start_date"
                            v-model="start_date"
                            label="Incidents After"
                            :max="end_date"
                        />
                    </v-flex>
                    <v-flex xs6>
                        <fe-date-picker
                            ref="end_date"
                            v-model="end_date"
                            label="Incidents Before"
                            :min="start_date"
                        />
                    </v-flex>
                </v-layout>

                <v-layout row>
                    <v-flex xs6 class="pr-2">
                        <fe-remote-combo
                            v-model="incident_behavior_type"
                            label="Incident Type"
                            multiple moreText
                            url="incidentBehaviorType.php?action=get"
                            rootProperty="incident_behavior_type"
                        />
                    </v-flex>
                    <v-flex xs6>
                        <fe-remote-combo
                            v-model="incident_behavior"
                            label="Code"
                            multiple moreText
                            url="incidentBehavior.php?action=get"
                            :params="incident_behavior_type ? {
                                incident_behavior_type_id: incident_behavior_type.map( v => v.id )
                            } : null "
                            rootProperty="incident_behavior"
                        />
                    </v-flex>
                </v-layout>

                <v-layout row>
                    <v-flex xs6 class="pr-2">
                        <fe-remote-combo
                            v-model="incident_location"
                            label="Location"
                            multiple moreText
                            url="incidentLocations.php?action=get"
                            rootProperty="incident_locations"
                            :sort="sortByName"
                        />
                    </v-flex>
                    <v-flex xs6>
                        <fe-remote-combo
                            v-model="incident_response"
                            label="Response"
                            multiple moreText
                            url="incidentResponses.php?action=get"
                            rootProperty="incident_responses"
                        />
                    </v-flex>
                </v-layout>

                <v-layout row>
                    <v-flex xs6 class="pr-2">
                        <div class="theme--light v-label">Minimum Incidents</div>
                        <v-text-field
                            type="number"
                            flat solo dense
                            v-model="minimum_count"
                            :error-messages="incidentCountError"
                            :rules="[ v => !v || parseInt(v) >= 0 || 'Value must be greater than or equal to 0' ]"
                        />
                    </v-flex>
                    <v-flex xs6>
                        <div class="theme--light v-label">Maximum Incidents</div>
                        <v-text-field
                            type="number"
                            flat solo dense
                            v-model="maximum_count"
                            :error-messages="incidentCountError"
                            :rules="[ v => !v || parseInt(v) >= 0 || 'Value must be greater than or equal to 0' ]"
                        />
                    </v-flex>
                </v-layout>

                <v-layout row>
                    <v-flex xs6 class="pr-2">
                        <fe-remote-combo
                            label="School Year"
                            v-model="school_year"
                            rootProperty="years"
                            url="qry.php?action=get&property=school_years"
                        ></fe-remote-combo>
                    </v-flex>
                    <v-flex xs6 class="mt-8">
                        <v-switch v-model="restrict" label="Restrict Results" hide-details />
                    </v-flex>
                </v-layout>
            </v-form>

        </fe-stepper-panel>

        <template #footer>
            <div class="text-truncate" style="padding:14px">{{breadcrumb}}</div>
        </template>

        </fe-stepper-filter>
    </v-dialog>
</div>
</template>

<script>
import * as Util from '../../Util'

export default {
    name: 'IncidentPanel',
    rank: 1,

    watch: {
        dialog(v) {
            if(!v) this.oldFilter = null
            if(!v) this.clear()
        },
    },

    computed: {
        title() {
            return this.oldFilter ? `Edit ${this.displayName} Filter` : `Create ${this.displayName} Filter`
        },
        enableFinalPanel() {
            return true
        },
        breadcrumb() {
            return `Incident`
        },
        incidentCountError() {
            return this.minimum_count !== null &&
                this.maximum_count !== null
                ? parseInt(this.minimum_count) >
                  parseInt(this.maximum_count)
                    ? 'Minimum Incidents Must Be Lower Than Maximum Incidents'
                    : null
                : null
        }
    },

    methods: {
        clear(panels) {
            this.restrict = true
            this.school_year = null
            this.start_date = this.$refs.start_date.inputValue = null
            this.end_date = this.$refs.end_date.inputValue= null
            this.incident_behavior_type = []
            this.incident_behavior = []
            this.incident_location = []
            this.incident_response = []
            this.minimum_count = null
            this.maximum_count = null
        },
        load(oldFilter) {
            this.oldFilter = oldFilter
            this.dialog = true
            let ep = oldFilter.editorParams

            this.restrict = ep.restrict
            this.school_year = ep.school_year
            this.start_date = ep.start_date
            this.end_date = ep.end_date
            this.incident_behavior_type = ep.incident_behavior_type
            this.incident_behavior = ep.incident_behavior
            this.incident_location = ep.incident_location
            this.incident_response = ep.incident_response
            this.minimum_count = ep.minimum_count
            this.maximum_count = ep.maximum_count

        },
        emit() {
            this.dialog = false
            let uniqueId = Util.UUIDv4()
            let name = this.breadcrumb
            let filter = {
                type: this.type,
                id: uniqueId,
                restrict: this.restrict,
                report: true,
                params: this.$_.pickBy({
                    start_date: this.start_date,
                    end_date: this.end_date,
                    incident_behavior_type_id: this.incident_behavior_type?.map(x => x.id),
                    incident_behavior_id: this.incident_behavior?.map(x => x.id),
                    incident_location_id: this.incident_location?.map(x => x.id),
                    incident_response_id: this.incident_response?.map(x => x.id),
                    minimum_count: this.minimum_count,
                    maximum_count: this.maximum_count,
                    school_year_id: this.school_year?.id,
                }, x => !this.$_.isBlank(x)),
            }
            Object.defineProperties(filter, {
                editor: {value: this},
                editorParams: {value: {
                    restrict: this.restrict,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    incident_behavior_type: this.incident_behavior_type ? [...this.incident_behavior_type] : null,
                    incident_behavior: this.incident_behavior ? [...this.incident_behavior] : null,
                    incident_location: this.incident_location ? [...this.incident_location] : null,
                    incident_response: this.incident_response ? [...this.incident_response] : null,
                    minimum_count: this.minimum_count,
                    maximum_count: this.maximum_count,
                    school_year: this.school_year ? {...this.school_year} : null,
                }},
                grouper: {value: {
                    id: uniqueId,
                    name: name,
                    type: 'oldEvenRange',
                    args: {property: `filters.${uniqueId}.value`},
                }},
                chipText: {value: name},
                tooltipData: {value: this.$_.pickBy({
                    'Incidents After': this.start_date,
                    'Incidents Before': this.end_date,
                    'Incident Type': this.incident_behavior_type?.map(x => x.name).join(', '),
                    'Code': this.incident_behavior?.map(x => x.name).join(', '),
                    'Location': this.incident_location?.map(x => x.name).join(', '),
                    'Response': this.incident_response?.map(x => x.name).join(', '),
                    'Minimum': this.minimum_count,
                    'Maximum': this.maximum_count,
                    'Restrict': this.restrict,
                    'School Year': this.school_year?.name,
                }, x => !this.$_.isBlank(x))}
            })
            this.$emit('input', filter, this.oldFilter)
        },
        sortByName(a, b) {
            return a.name < b.name ? -1 : 1
        },

    },

    data() {
        return {
            formValid: true,
            oldFilter: null,
            displayName: 'Incident',
            type: 'incident',
            dialog: false,

            restrict: true,
            school_year: null,

            start_date: null,
            end_date: null,
            incident_behavior_type: null,
            incident_behavior: null,
            incident_location: null,
            incident_response: null,
            minimum_count: null,
            maximum_count: null,

        }
    }
}
</script>

<style lang="scss" scoped>
</style>


//         {
//             "id": 21,
//             "type": "incident",
//             "restrict": false,
//             "report": true,
//             "params": {
//                 "incident_behavior_type_id": [1,2,3],
//                 "start_date": "2017-09-01",
//                 "end_date": "2017-11-01",
//                 "minimum_count": 1
//             }
//         },
