<template>
    <div v-if="enabled" class="advanced-demographic-filter">
        <fe-label>{{ name }}</fe-label>

        <template v-if="type === 'option'">
            <fe-remote-combo
                v-if="options"
                :items="options"
                itemText="display_name"
                itemValue="id"
                v-model="entry"
                :style="{ width: inputWidth }"
                by-id
                hide-details
            />
        </template>

        <template v-else-if="type === 'boolean'">
            <fe-remote-combo
                v-if="options"
                :items="options"
                itemText="value"
                itemValue="id"
                v-model="entry"
                :style="{ width: inputWidth }"
                by-id
                hide-details
            />
        </template>

        <div v-else>
            <v-menu :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-text-field
                        v-on="on"
                        :value="description"
                        :width="inputWidth"
                        flat solo dense
                        readonly
                        clearable
                        hide-details
                        @input="onDescriptionChange"
                    />
                </template>

                <div class="pa-3" style="background-color: #fff; overflow-y: auto;">
                    <fe-remote-combo
                        v-model="operator"
                        :items="operators"
                        itemText="text"
                        itemValue="code"
                        placeholder="Select an Operator"
                        :clearable="false"
                        by-id
                    />

                    <fe-date-picker
                        v-if="type === 'date'"
                        v-model="entry"
                        :placeholder="isBetween ? 'Select First Date' : 'Select Date'"
                        :rules="entryRules"
                        clearable
                    />

                    <v-text-field
                        :value="entry"
                        :placeholder="isBetween ? 'Enter First Value' : 'Enter a Value'"
                        :rules="entryRules"
                        v-else
                        flat solo dense
                        clearable
                        autofocus
                        @change="entry = $event"
                    />

                    <template v-if="isBetween">
                        <fe-date-picker
                            v-if="type === 'date'"
                            v-model="secondEntry"
                            placeholder="Select Second Date"
                            :rules="entryRules"
                            clearable
                        />

                        <v-text-field
                            v-else
                            :value="secondEntry"
                            placeholder="Enter Second Value"
                            :rules="entryRules"
                            flat solo dense
                            clearable
                            @change="secondEntry = $event"
                        />
                    </template>
                </div>
            </v-menu>
        </div>
    </div>
</template>

<script>
// This can be bound to with v-model.  It accepts a value that should be formatted like:
//
//  - For "option" types, please send in just the id of the selected option:
//    - "23"
//    - "4534"
//  - For all other types send in a string like "[operator]:[value]":
//    - "eq:my string"
//    - "lte:34"
//  - For "between" operators, send in a string like "[operator]:[value]:[value]":
//    - "bt:10:100"
//    - "bt:2020-01-01:2020-12-31"
//
// This will emit the following events upon a change:
//
//  - "input":
//    - A string formatted the same way as the "value" prop above, or a null.
//  - "result":
//    - An object that contains all the necessary elements if you wanted
//      to construct your own query strings or handle in some other way.

export default {
    name: 'AdvancedDemographicFilter',

    props: {
        demographic: {type: Object, required: true},
        value: {type: String, default: null},
        inputWidth: {type: [Number, String], default: '100%'}
    },

    data() {
        return {
            operator: 'eq',
            entry: null,
            secondEntry: null,
            rules: {
                number: [
                    (v) => !v || !!`${v}`.match(/^-?[\d,]+\.?[\d,]*$/) || 'Please enter a valid number'
                ]
            }
        }
    },

    computed: {
        enabled() {
            return this.type !== 'option' || (this.options && this.options.length)
        },

        name() {
            return this.demographic.name
        },

        type() {
            return this.demographic.value_type
        },

        operatorText() {
            return this.operator
                ? this.operators.find(itm => itm.code === this.operator).text
                : ''
        },

        valid() {
            if (!this.operator) return false
            if (!this.entry) return false
            if (!this.passesRules(this.entry)) return false
            if (this.isBetween) {
                if (!this.secondEntry) return false
                if (!this.passesRules(this.secondEntry)) return false
            }
            return true
        },

        description() {
            if (this.valid && this.type === 'boolean') return this.entry === 1 ? 'Yes' : 'No'
            if (this.valid && this.type === 'option') return this.entry
            if (this.valid && this.operator === 'bt' && this.entry && this.secondEntry) return `Between ${this.entry} and ${this.secondEntry}`
            if (this.valid && this.operator && this.entry) return `${this.operatorText} ${this.entry}`
            return null
        },

        operators() {
            return [
                {code: 'gt', text: 'Greater Than', types: ['date', 'number']},
                {code: 'gte', text: 'Greater Than or Equal To', types: ['date', 'number']},
                {code: 'lt', text: 'Less Than', types: ['date', 'number']},
                {code: 'lte', text: 'Less Than or Equal To', types: ['date', 'number']},
                {code: 'bt', text: 'Between', types: ['date', 'number']},
                {code: 'eq', text: 'Equal To', types: ['date', 'number', 'string', 'boolean']},
                {code: 'has', text: 'Contains', types: ['string']},
                {code: 'start', text: 'Starts With', types: ['string']},
                {code: 'end', text: 'Ends With', types: ['string']}
            ].filter(itm => itm.types.includes(this.type))
        },

        options() {
            if (this.type === 'option' && this.demographic.options) {
                let options = this.$_.cloneDeep(this.demographic.options)
                let filteredOptions = []
                options.forEach(option => {
                    if (!option.display_name_group) {
                        option.display_name = option.display_name || option.value
                        filteredOptions.push(option)
                    } else {
                        let o = options.find(x => x.id == option.display_name_group[0]) // find and return first option within display_name_group; id can be string
                        if (!filteredOptions.find(x => x.display_name === o.display_name )) {
                            o = {
                                ...o,
                                display_name: o.display_name || o.value // use display_name instead of value
                            }
                            filteredOptions.push(o)
                        }
                    }
                })

                // prevent any saved search args from displaying if they are part of group
                let ids = filteredOptions.map(x => x.id)
                let selected = this.entry
                    ? this.entry.split(',').map(id => {
                        return parseInt(id);
                    })
                    : null
                this.entry = selected ? selected.filter(i => { return ids.includes(i) }).toString() : null

                return filteredOptions
            } else if (this.type === 'boolean') {
                return [
                    {id: 't', value: 'Yes'},
                    {id: 'f', value: 'No'}
                ]
            } else {
                return null
            }
        },

        isBetween() {
            return this.operator === 'bt'
        },

        entryRules() {
            return this.rules[this.type] || []
        },

        result() {
            if (!this.valid) return null

            let v = this.entry
            if (this.type === 'boolean') v = (v === 't') ? '1' : '0'

            let obj = {
                demographicId: this.demographic.id,
                key: `demo_${this.demographic.id}`,
                entry: v
            }

            if (this.type === 'option') {
                obj.encoded = `${v}`
            } else {
                obj.operator = this.operator
                obj.encoded = `${obj.operator}:${v}`
            }

            if (this.isBetween) {
                obj.secondEntry = this.secondEntry
                obj.encoded += `:${obj.secondEntry}`
            }

            obj.query = `${obj.key}=${obj.encoded}`

            return obj
        }
    },

    watch: {
        value: {
            handler() {
                this.parse()
            },
            immediate: true
        },

        result(v) {
            this.$emit('result', v)
            this.$emit('input', v ? v.encoded : null)
        }
    },

    methods: {
        passesRules(val) {
            return !this.entryRules
                .map(rule => rule(val))
                .filter(result => result !== true)
                .length > 0
        },

        parse() {
            if (this.value) {
                if (this.type === 'option') {
                    this.operator = 'eq'
                    this.entry = this.value
                } else {
                    let matches = this.value.match(/^(.*?):(.*)$/)
                    if (matches) {
                        this.operator = matches[1]
                        if (this.type === 'boolean') {
                            if (matches[2] === 1 || matches[2] === '1' || matches[2] === true) this.entry = 't'
                            else if (matches[2] === 0 || matches[2] === '0' || matches[2] === false) this.entry = 'f'
                        } else if (this.operator === 'bt') {
                            let vals = matches[2].split(':')
                            this.entry = vals[0]
                            this.secondEntry = vals[1]
                        } else {
                            this.entry = matches[2]
                        }
                    }
                }
            }
        },

        onDescriptionChange(v) {
            // The initial text field for a custom demo is readonly, but it is
            // also clearable; we want to emit a "no selection" update when cleared
            if (!v) {
                this.entry = null
            }
        },
    }
}
</script>

<style lang="scss">
</style>
