export default {
    grade: {
        defaults: {
            endpoint: 'grades.php', // scope: school_year_id=16, school_id=4 (or school_id=[4,8,11])
            rootProperty: 'grades'
        },
        read: {
            params: {
                action: 'get'
            }
        },
        create: {
            params: { action: 'create' },
        },
        update: {
            params: { action: 'update' },
        },
        destroy: {
            params: { action: 'delete' },
        }
    }
}