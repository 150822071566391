<template>
    <form-common-config
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #fieldSpecific="{rec}">
            <div class="d-flex">
                <fe-label :style="labelStyle">Id Types</fe-label>
                <fe-remote-combo
                    ref="typeCombo"
                    byId multiple moreText
                    style="margin-left:auto;"
                    v-model="savedSearch.student_id_type"
                    itemValue="value"
                    :items="[
                        {value:'state', name:'State'},
                        {value:'district', name:'District'},
                        {value:'educlimber', name:'eduCLIMBER'},
                        {value:'other', name:'Others'},
                    ]"
                    :clearable="false"
                ></fe-remote-combo>
            </div>
            <fe-crud
                ref="crud"
                :key="rec.id"
                :readParams="{form_field_id:rec.id}"
                :config="$models.formFieldSavedSearch"
                autoload
                @read="processRead($event,rec)"
            />
        </template>
    </form-common-config>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'
import FormCommonConfig from '../FormCommonConfig'
import configMixin from './configMixin'

export default {
    inject: ['localStore'],
    mixins: [ configMixin ],
    components: {
        FormCommonConfig,
    },
    computed: {
        ...mapLocalState([ 'reloadFn' ]),
        labelStyle: me => ({ minWidth:'120px' }),
    },
    mounted() {
        this.$watch('$refs.typeCombo.$refs.autocomplete.isMenuActive', v => {
            !v && this.save()
        })
    },
    watch: {
        savedSearch: {
            deep: true,
            handler: 'save',
        },
    },
    methods: {
        processRead(v,rec) {
            this.savedSearch = this.processSavedSearch(v, {
                form_field_id: rec.id,
                student_id_type: ['district'],
            })
            if(!this.$_.isBlank(v)) {
                this.cachedValue = {...this.savedSearch}
            }
        },
        save() {
            if(this.$refs.typeCombo.$refs.autocomplete.isMenuActive) return // bunch up multiple tag selects for form field versioning
            let dirty = JSON.stringify(this.cachedValue) != JSON.stringify(this.savedSearch)
            if(dirty) {
                this.cachedValue = {...this.savedSearch}
                let fieldId
                this.$refs.crud.create({...this.savedSearch, form_saved_search_type_id: 2}).then( r => {
                    fieldId = r.data?.form_field_ids[0]
                }).finally(() => {
                    this.reloadFn({fieldId:fieldId})
                })
            }
        }
    },
    data() {
        return {
            savedSearch: {},
            cachedValue: {},
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
