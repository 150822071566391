<template>
    <div class="flex-fill flex-column no-scroll" style="height: 400px;">
        <fe-crud-grid
            v-if="schoolYears.length"
            ref="grid"
            :columnDefs="columnDefs"
            :config="crudConfig"
            :readParams="readParams"
            :addRowBtnText="demographics.length ? 'Add Demographic' : 'No Demographics Defined'"
            displayDensity="medium"
            disableInlineCreate
            refreshOnCreated
            refreshOnUpdated
            @beginCreate="beginCreate"
        >
            <template #cellDialog="{cell}">
                <v-list>
                    <v-list-item @click="beginEdit(cell.data)">Edit</v-list-item>
                    <v-list-item @click="destroy(cell.data)">Delete</v-list-item>
                </v-list>
            </template>
        </fe-crud-grid>

        <add-demographic
            v-if="showAddDialog"
            :studentInfo="studentInfoModel"
            :schoolYears="schoolYears"
            :demographics="demographics"
            :editing="editing"
            @close="showAddDialog = false"
            @update="update"
            @create="create"
        />

        <fe-crud ref="yearsCrud" :config="yearsCrudConfig" autoload @read="schoolYears = $event"/>
        <fe-crud ref="demographicsCrud" :config="demographicsCrudConfig" autoload @read="demographicPostRead"/>
        <fe-crud ref="demographicOptionsCrud" :config="demographicOptionsCrudConfig"/>
    </div>
</template>

<script>
import AddDemographic from "./AddDemographic"

export default {
    name: "DemographicGrid",

    components: {
        AddDemographic
    },

    props: {
        studentInfoModel: null
    },

    data() {
        return {
            showAddDialog: false,
            demographics: [],
            schoolYears: [],
            editing: null,
            columnDefs: [
                {
                    headerName: 'Demographic Name',
                    field: 'demographic_name'
                }, {
                    headerName: 'Value',
                    field: 'display_value',
                    valueGetter: this.getValue
                }, {
                    headerName: 'School Year',
                    maxWidth: 120,
                    field: 'school_year',
                    valueGetter: this.getSchoolYearName
                }, {
                    headerName: 'Start Date',
                    maxWidth: 120,
                    field: 'start_date'
                }, {
                    headerName: 'End Date',
                    maxWidth: 120,
                    field: 'end_date'
                }, {
                    headerName: 'Created',
                    maxWidth: 120,
                    field: 'created',
                    cellRenderer: v => this.$dayjs(v.value).format('M/D/YYYY')
                }, {
                    headerName: 'Upload ID',
                    maxWidth: 120,
                    field: 'upload_id',
                    hide: true
                }, {
                    colId: 'toolmenu',
                    maxWidth: 50,
                    cellStyle: {textAlign: 'center', cursor: 'pointer'},
                    cellRenderer: v => {
                        if (this.canEdit(v)) {
                            return '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>'
                        }
                        return ''
                    },
                    onCellClicked: this.onCellClicked
                }
            ]
        }
    },

    computed: {
        crudConfig() {
            return this.$_.cloneDeep(this.$models.studentDemographic)
        },

        yearsCrudConfig() {
            return this.$_.cloneDeep(this.$models.schoolYear)
        },

        demographicsCrudConfig() {
            return this.$_.cloneDeep(this.$models.demographic)
        },

        demographicOptionsCrudConfig() {
            return this.$_.cloneDeep(this.$models.demographicOption)
        },

        readParams() {
            return {
                student_id: this.studentInfoModel.student_id
            }
        }
    },

    mounted() {
    },

    methods: {
        load() {
            this.$refs.grid.$refs.crud.read()
        },

        onCellClicked(v) {
            if (this.canEdit(v)) {
                this.$refs.grid.$refs.grid.setDialogCell(v)
            }
        },

        getSchoolYearName(v) {
            if (v.data.school_year_id) {
                let year = this.schoolYears.filter(x => x.id === v.data.school_year_id)[0]
                return year ? year.name : 'Inactive'
            }
            return null
        },

        getValue(v) {
            let value = v.data.display_value
            switch (v.data.demographic_value_type) {
                case 'number':
                    return parseFloat(value)
                default:
                    return value
            }
        },

        demographicPostRead(v) {
            let transactions = []
            v.forEach(x => {
                if (x.value_type === 'option') {
                    transactions.push(this.$refs.demographicOptionsCrud.read({demographic_id: x.id}).then(y => {
                        x.options = y.data.demographic_option
                    }))
                }
            })
            Promise.all(transactions).then(res => {
                this.demographics = v
            })
        },

        canEdit(v) {
            return !!v.data.can_edit
        },

        beginEdit(data) {
            this.editing = data
            this.showAddDialog = true
            this.$refs.grid.$refs.grid.setDialogCell()
        },

        beginCreate() {
            this.editing = null
            this.showAddDialog = true
        },

        create(data) {
            data.student_id = parseInt(this.studentInfoModel.student_id)
            this.$refs.grid.$refs.crud.create(data).then(() => {
                this.showAddDialog = false
                this.load()
                this.$emit('updateDemographics')
            })
        },

        update(data) {
            this.editing = null
            data.student_id = parseInt(this.studentInfoModel.student_id)
            this.$refs.grid.$refs.crud.update(data).then(() => {
                this.showAddDialog = false
                this.load()
                this.$emit('updateDemographics')
            })
        },

        destroy(data) {
            let payload = {id: data.id}
            this.$refs.grid.$refs.grid.setDialogCell()

            this.$confirmDelete(payload, () => {
                this.$refs.grid.$refs.crud.destroy(payload).then(() => {
                    this.load()
                    this.$emit('updateDemographics')
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
