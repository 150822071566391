export default {
    data() {
        return {
            chartData: [],
            categories: [],
        }
    },
    methods: {
        async fetchDataSetResults(name, p) {
            let resp = await this.$axios.get(
                `targetScores.php?action=get&property=student_trendline&chart_type=LINE&regression=1&series_name=${encodeURIComponent(name)}&${this.$objectToParams(p)}`
            )
                .catch(err => {
                    console.warn('analytics error', err)
                })

            return resp
        },
        resetResponseData() {
            this.chartData = []
        },
        parseResponseData(name, resp, index) {
            let count = this.$charts.colors.length
            let color = this.$charts.colors[index % count]
            if (resp.data.scores.length > 0) {
                this.setCategories(resp.data.scores)

                if (resp.data.scores.length !== 0) {
                    let scores = resp.data.scores[0]
                    let chartData = scores.chart_data

                    // Creating the chart series
                    this.chartData.push({
                        name: name,
                        color: color,
                        data: this.categories.map(c => {
                            let find = chartData.find(d=>d.name===c)

                            if (find) {
                                return find[name] ? find[name] : null
                            } else {
                                return null
                            }
                        })
                    })

                    // Creating the trendline series
                    this.chartData.push({
                        name: name+'-Trendline',
                        dashStyle: 'dash',
                        color: color,
                        lineWidth: 1,
                        data: this.categories.map(c => {
                            let find = chartData.find(d=>d.name===c)

                            if (find) {
                                return find[name+'-Trendline'] ? find[name+'-Trendline'] : null
                            } else {
                                return null
                            }
                        })
                    })
                }
            }
        },
        getChartConfig(config) {
            let me = this
            return {
                chart: {
                    height: config?.chart?.height === null ? null : (config?.chart?.height || (window.innerHeight-100)),
                },
                credits: {
                    enabled: false
                },
                title: {
                    enabled: false,
                    text: '',
                    useHTML: true,
                    align: 'left',
                    style: {
                        'color': '#7E8494',
                        'border-bottom': '1px solid #E0E1E6',
                        'display': 'block',
                        'width': '100%'
                    }
                },
                exporting: {
                    enabled: false
                },
                colorAxis: {
                    labels: {
                        step: 3,
                        enabled: true
                    },
                    gridLineWidth: 0
                },
                xAxis: {
                    min: undefined,
                    categories: me.categories,
                    gridLineWidth: 0
                },
                yAxis: {
                    // min: me.childMinValue,
                    // max: me.childMaxValue,
                    title: {
                        text: 'Score'
                    }
                },
                series: me.chartData,
                // dash_id: me.params.dashboard_id ? me.params.dashboard_id : null,
                // saved_search_id: me.params.saved_search_id ? me.params.saved_search_id : null,
                // index: me.params.index ? me.params.index : null
            }
        },
        setCategories(rec) {
            if (rec.length == 0) return
            let data = rec[0].chart_data
            if (data.length == 0) return

            let first = data[0]
            let ignore = rec[0].fields.concat(['year_start'])
            Object.keys(first).forEach(key => {
                if (ignore.indexOf(key) == -1 && !this.categories.find(itm => itm === key)) this.categories.push(key)
            })
        }
    }
}
