<template>
    <v-layout column>
        <div class="headline mb-1 d-flex">
            Periods
            <fe-info-tip
                class="ml-2"
                tooltip="Periods"
            />
        </div>

        <fe-crud-grid
            ref="grid"
            :config="crudConfig"
            :columnDefs="columnDefs"
            displayDensity="medium"
            autoload
            refreshOnCreated
            disableInlineCreate
            @fail="load"
            @beginCreate="beginEntry"
        />

        <fe-dialog
            v-if="createDialog.show"
            title="Create Period"
            :footer="false"
            persistent
            disableAutoclose
            @close="dismissDialog"
        >
            <model-form
                idProperty="id"
                :records="createDialog.records"
                :fieldConfig="fieldConfigs"
                model="attendancePeriods"
                @cancel="dismissDialog"
                @done="created"
            />
        </fe-dialog>
    </v-layout>
</template>

<script>
    import ModelForm from '@/components/common/form/ModelForm'

    export default {
        name: "AttendancePeriods",

        components: {ModelForm},

        data() {
            return {
                createDialog: {show: false, records: []}
            }
        },

        computed: {
            crudConfig() {
                return _.cloneDeep(this.$models.attendancePeriods)
            },

            schoolParams() {
                let output = {}

                if (this.newEntry.school_year_id) {
                    output.school_year_id = this.newEntry.school_year_id
                }

                return output
            },

            valuesLoaded() {
                return !!this.$parent.years && !!this.$parent.schools
            },

            columnDefs() {
                let me = this

                return !this.valuesLoaded ? [] : [
                    {
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        maxWidth: 70,
                        colId: 'checkbox_column'
                    },
                    {
                        headerName: 'School Year',
                        field: 'school_year_id',
                        valueGetter: v => me.findLabel(me.$parent.years, v.data.school_year_id, 'id', 'name'),
                        editable: true,
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.school_year_id,
                                rowDataKey: "school_year_id",
                                mode: "edit",
                                items: me.$parent.years,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true,
                                elProps: {
                                    clearable: false
                                },
                            }
                        },
                        maxWidth: 150
                    },
                    {
                        headerName: 'School Name',
                        field: 'school_id',
                        valueGetter: v => me.findLabel(me.$parent.schools, v.data.school_id, 'id', 'name'),
                        editable: true,
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.school_id,
                                rowDataKey: "school_id",
                                mode: "edit",
                                items: me.$parent.schools,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true,
                                elProps: {
                                    clearable: false
                                },
                            }
                        }
                    },
                    {
                        headerName: 'Periods Possible',
                        field: 'possible_period_cnt',
                        editable: true,
                        cellEditorFramework: "FeGridTextField",
                        cellEditorParams: {
                            type: 'number'
                        },
                        maxWidth: 200
                    }
                ]
            },

            fieldConfigs() {
                let me = this
                return [
                    {
                        field: 'school_year_id',
                        label: 'School Year',
                        component: 'fe-remote-combo',
                        validatorKey: 'select',
                        validatorConfig: {required: true},
                        width: '100%',
                        attrs: {
                            validateOnBlur: true,
                            byId: true,
                            items: me.$parent.years,
                            itemText: 'name',
                            itemValue: 'id'
                        }
                    }, {
                        field: 'school_id',
                        label: 'School',
                        component: 'fe-remote-combo',
                        validatorKey: 'select',
                        validatorConfig: {required: true},
                        width: '100%',
                        attrs: {
                            validateOnBlur: true,
                            byId: true,
                            items: me.$parent.schools,
                            itemText: 'name',
                            itemValue: 'id'
                        }
                    }, {
                        field: 'possible_period_cnt',
                        label: 'Periods Possible',
                        component: 'v-text-field',
                        validatorKey: 'number',
                        validatorConfig: {required: true, min: 1},
                        width: '100%',
                        attrs: {
                            validateOnBlur: true,
                            flat: true,
                            solo: true,
                            dense: true
                        }
                    }
                ]
            }
        },

        created() {
            this.$parent.$once('show periods', () => {
                this.$refs.grid.$refs.grid.resize()
            })
        },

        methods: {
            load() {
                this.$refs.grid.$refs.crud.read()
            },

            beginEntry() {
                this.resetEntry()
                this.createDialog.show = true
            },

            dismissDialog() {
                this.createDialog.show = false
            },

            resetEntry() {
                this.createDialog.records = [
                    {
                        school_year_id: undefined,
                        school_id: undefined,
                        possible_period_cnt: undefined
                    }
                ]
            },

            created() {
                this.load()
                this.dismissDialog()
            },

            findLabel(collection, value, valueProp, labelProp) {
                let lbl = ''
                if (collection && value) {
                    let sel = collection.find(x => x[valueProp] === value)
                    if (sel) lbl = sel[labelProp]
                }
                return lbl
            }
        }
    }
</script>

<style scoped></style>
