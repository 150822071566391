export const mapLocalState = function(key) {
    if (!Array.isArray(key)) {
        key = [key]
    }
    let o = {}

    key.forEach((f) => {
        o[f] = {
            get() {
                if (this.localStore) {
                    return this.localStore.getters[f]
                        ? this.localStore.getters[f]
                        : this.localStore.state[f]
                }

            },
            set(value) {
                if (this.localStore) {
                    this.localStore.state[f] = value
                }

            }
        }
    })

    return o
}
