<template>
    <div class="flex-fill flex-column no-scroll">
        <div class="headline" v-if="!addTagOnly && !hideTitle">Student Tags</div>

        <fe-grid style="height:100%"
            ref="grid"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :showDownload="false"
            addRowBtnText="Add Tag"
            displayDensity="medium"
            disableInlineCreate
            @beginCreate="editRec(null)"
            @cellValueChanged="updateRec($event.data,$event)"
            v-if="!addTagOnly"
        >
        <template #after-search-toolbar-items>
            <fe-remote-combo
                style="maxWidth:140px; margin-left:8px;"
                v-model="school_year"
                @input="loadTags"
                rootProperty="years"
                url="qry.php?action=get&property=school_years"
                :clearable="false"
            />
        </template>
            <template #cellDialog="{cell}">
                <simple-grid-dialog-column
                    v-if="cell.colDef.cellRendererFramework=='FeDialogColumn'"
                    :readOnly="!cell.data.can_use"
                    :cell="cell"
                    :title="cell.data.name"
                    :deleteTool="1"
                    :field="dialogColumnDefs[cell.colDef.field].column1.field"
                    :fieldHeader="dialogColumnDefs[cell.colDef.field].column1.headerName"
                    :fieldPlaceholder="dialogColumnDefs[cell.colDef.field].fieldPlaceholder"
                    :columnDefs="dialogColumnDefs[cell.colDef.field].columnDefs && dialogColumnDefs[cell.colDef.field].columnDefs"
                    :crudConfig="dialogColumnDefs[cell.colDef.field].model"
                    :fieldConfig="dialogColumnDefs[cell.colDef.field].fieldConfig"
                    :buildInsertRec="dialogColumnDefs[cell.colDef.field].buildRec"
                    :width="dialogColumnDefs[cell.colDef.field].width"
                    :height="dialogColumnDefs[cell.colDef.field].height"
                    :insertDefaults="cell.data"
                    :readParams="{tag_id:cell.data.id, school_year_id:school_year.id}"
                    :showToolbar="dialogColumnDefs[cell.colDef.field].column1.field==='student_full_name'"
                    deletable
                    @close="$refs.grid.openMenu = false"
                    @count="updateCellCount(cell,$event)"
                />

                <v-list v-else>
                    <v-list-item @click="editRec(cell.data)" :disabled="!cell.data.can_edit">Edit Tag</v-list-item>
                    <v-list-item @click="copyToYear(cell.data)" :disabled="!cell.data.can_use">Copy To School Year</v-list-item>
                    <v-list-item @click="switchTag(cell.data)" :disabled="!cell.data.can_use">Switch Tag</v-list-item>
                    <v-list-item @click="deleteRec(cell.data)" :disabled="!cell.data.can_edit">Delete Tag</v-list-item>
                </v-list>
            </template>
        </fe-grid>

        <fe-crud
            ref="crud"
            :config="$models.tag"
            @read="rowData = $event"
        />

        <record-dialog v-if="showAddDialog"
            :title="$hasSecurity('MANAGE_PUBLIC_TAGS') ? 'Tag' : 'Private Tag'"
            :value="tagRec"
            :defaultRec="{
                name: null,
                description: null,
                color: '#006C90',
                public_flag: 0,
                kpi: 0,
            }"
            @create="createRec" @update="updateRec"
            @close="showAddDialog=false"
        >
            <template #form="{rec}">
                <fe-label >Name</fe-label>
                <v-text-field autofocus flat solo dense
                    v-model="rec.name"
                    :rules="$fieldValidators('text', null, {required:true, limit:50})"
                />

                <fe-label>Description</fe-label>
                <v-textarea flat solo dense
                    v-model="rec.description"
                    :counter="350"
                    :rules="$fieldValidators('text', null, {limit:350})"
                />

                <div class="d-flex justify-space-between mb-2">
                    <fe-label>Color</fe-label>
                    <fe-color-selector v-model="rec.color"></fe-color-selector>
                </div>

                <div v-if="$hasSecurity('MANAGE_PUBLIC_TAGS')" class="d-flex justify-space-between" >
                    <fe-label>Public</fe-label>
                    <fe-switch v-model="rec.public_flag" />
                </div>

                <div v-if="$hasSecurity('MANAGE_ACHIEVEMENT_DASHBOARD')" class="d-flex justify-space-between" >
                    <fe-label>KPI</fe-label>
                    <fe-switch :disabled="!rec.public_flag" v-model="rec.kpi" />
                </div>
            </template>
        </record-dialog>

        <fe-crud ref="crudStudentTag" :config="this.$models.studentTag"/>

        <fe-dialog v-if="showStudentDialog"
            width="800px"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Add"
            @close="showStudentDialog=false"
            @accept="addAdvancedStudentSelection"
        >
            <student-search v-model="selectedStudents" />
        </fe-dialog>

        <switch-tag v-if="showSwitchDialog"
            :oldTag="lastCell.data"
            :crudParams="dialogParams"
            @close="showSwitchDialog=false; loadTags()"
        />

        <copy-tag v-if="showCopyDialog"
            :oldTag="lastCell.data"
            :crudParams="dialogParams"
            @close="showCopyDialog=false; loadTags()"
        />
    </div>
</template>

<script>
import SimpleGridDialogColumn from "@/components/common/SimpleGridDialogColumn"
import RecordDialog from '@/components/common/form/RecordDialog'
import StudentSearch from '@/components/common/StudentSearch'
import SwitchTag from './dialogs/SwitchTag'
import CopyTag from './dialogs/CopyTag'

export default {
    name: 'StudentTags',

    components: {
        SimpleGridDialogColumn,
        RecordDialog,
        StudentSearch,
        SwitchTag,
        CopyTag,
    },

    props: {
        addTagOnly: { type: Boolean, default: false },
        hideTitle: { type: Boolean, default: false },
    },

    data() {
        return {
            tagRec: null,
            dialogParams: null,
            showCopyDialog: false,
            showSwitchDialog: false,
            showStudentDialog: false,
            showAddDialog: false,
            school_year: null,
            selectedStudents: [],
            rowData: [],
            columnDefs: [
                {
                    headerName: 'Tag Name',
                    field: 'name',
                    cellRenderer: this.renderWithSubField,
                }, {
                    headerName: 'Color',
                    field: 'color',
                    maxWidth: 80,
                    cellStyle: {'text-align':'center'},
                    cellRenderer: v => {
                        return `<i class="fe-grid-icon fas fa-square-full" style="color:${v.data.color};"></i>`
                    },
                }, {
                    headerName: 'Students',
                    field: 'student_cnt',
                    maxWidth: 100,
                    cellRendererFramework: 'FeDialogColumn',
                    onCellClicked: this.onCellClicked,
                }, {
                    headerName: 'Shared With',
                    field: 'shared_cnt',
                    maxWidth: 100,
                    cellRendererFramework: 'FeDialogColumn',
                    onCellClicked: this.onCellClicked,
                }, {
                    headerName: 'Public', field: 'public_flag',
                    cellStyle: {textAlign: 'center'},
                    cellRenderer: v => v.data.public_flag ? '<i class="fe-grid-icon fas fa-check theme--light"></i>' : '',
                    maxWidth: 80,
                }, {
                    headerName: 'KPI', field: 'kpi',
                    hide: !this.$hasSecurity('MANAGE_ACHIEVEMENT_DASHBOARD'),
                    cellStyle: {textAlign: 'center'},
                    cellRenderer: v => v.data.kpi ? '<i class="fe-grid-icon fas fa-check theme--light"></i>' : '',
                    maxWidth: 80,
                }, {
                    headerName: 'Created By',
                    field: 'user_full_name',
                    maxWidth: 200,
                }, {
                    headerName: 'Created',
                    field: 'created',
                    hide: true,
                }, {
                    colId: 'toolmenu',
                    maxWidth: 50,
                    cellRenderer: v => {
                        return '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>'
                    },
                    onCellClicked: this.onCellClicked,
                }
            ],
            dialogColumnDefs: {
                student_cnt: {
                    width: '700px',
                    height: '500px',
                    model: this.$models.studentTag,
                    fieldPlaceholder: 'Search Students',
                    column1: {headerName: 'Student', field: 'student_full_name'},
                    columnDefs: [{
                        headerName: 'School', field: 'school_name', width: 250,
                    }, {
                        headerName: 'Grade', field: 'grade_desc', maxWidth: 100,
                    }],
                    fieldConfig: {
                        type:'combo',
                        url:'students.php?action=get&property=grade_history',
                        autofocus: true,
                        appendedTextBtn: 'Advanced',
                        onAppendedTextBtnClick: e => this.showStudentDialog=true,
                        itemSubtext: x => `${x.school_name} - ${x.grade_desc}`,
                        params: () => ({
                            school_year_id: this.school_year && this.school_year.id
                        }),
                        itemText:'student_full_name',
                        filter: function(x) {
                            if(!this.index) {
                                let index = {}
                                this.forEach(y => index[y.student_id]=true)
                                Object.defineProperty(this,'index',{value:index})
                            }
                            return !this.index[x.student_id]
                        }
                    },
                    buildRec: (v,d) => {
                        this.$emit('countChanged')
                        if(Array.isArray(v)) {
                            return v.map(x => ({
                                student_id: x.student_id,
                                tag_id: d.id,
                                school_year_id:this.school_year.id
                            }))
                        } else {
                            return {
                                student_id: v.student_id,
                                tag_id: d.id,
                                school_year_id:this.school_year.id
                            }
                        }
                    },
                },
                shared_cnt: {
                    model: this.$models.tagUser,
                    column1: {headerName: 'Users', field: 'user_full_name'},
                    fieldConfig: {
                        type:'combo',
                        url:'users.php?action=get&active=1',
                        itemText:'user_full_name',
                        filter: function(x) {
                            if(!this.index) {
                                let index = {}
                                this.forEach(y => index[y.user_id]=true)
                                Object.defineProperty(this,'index',{value:index})
                            }
                            return !this.index[x.id]
                        }
                    },
                    buildRec: (v,d) => ({ user_id: v.id, tag_id: d.id,}),
                },
            },

        }
    },

    computed: {
        currentYear() {
            return this.$store.state.global.sessionUser.currentYear
        },
        schoolYearId() {
            return this.school_year?.id
        },
        lastCell() {
            return this.$refs.grid.cell
        },
        currentUser() {
            return this.$store.state.global.sessionUser.user
        }
    },

    watch: {
        showAddDialog(v) {
            if(!v) {
                this.$emit('closeAddDialog')
            }
        }
    },

    created() {
        this.school_year = this.currentYear
    },

    mounted() {
        if(this.addTagOnly) {
            this.showAddDialog = true
        } else {
            this.$refs.grid.gridOptions.deltaRowDataMode = true
            this.$refs.grid.gridOptions.getRowNodeId = v => {
                return v.id
            }
        }
        this.loadTags()
    },

    methods: {
        updateCellCount(cell,count) {
            cell.node.setDataValue(cell.colDef.field,count)
        },
        onCellClicked(v) {
            this.$refs.grid.setDialogCell(v)
        },
        loadTags() {
            return this.$refs.crud.read({
                property: 'composite',
                school_year_id: this.school_year.id,
            })
        },
        editRec(v) {
            this.tagRec = v
            this.showAddDialog = true
        },
        createRec(v) {
            this.$refs.crud.create(v).then( r => {
                this.loadTags().then( r => {
                    this.showAddDialog=false

                    // find last id added
                    let index=0, row=this.rowData[0]
                    this.rowData.forEach((v,i) => {
                        if(v.id > row.id) { row = v; index = i }
                    })

                    this.$nextTick(() => {
                        if(!this.addTagOnly) {
                            this.$refs.grid.gridApi.ensureIndexVisible(index)
                            this.$flashGridRow(this.$refs.grid,index,3000)
                        }
                    })
                })
                this.$emit('countChanged')
            })
        },
        updateRec(v,e) {
            if(e && e.colDef.field.endsWith('_cnt')) return
            let save = v => {
                this.showAddDialog = false
                this.$refs.crud.update(v).finally(() => {
                    this.loadTags().finally(() => this.$refs.grid.gridApi.redrawRows())
                    this.$emit('countChanged')
                })
            }

            if(!v.public_flag && this.tagRec.public_flag) {
                let notCreatorText = this.currentUser.id != v.user_id
                    ? `This tag was created by ${v.user_full_name} so you will not be able to undo this change.`
                    : `Only you, the tag creator will be able to view this tag after this change.`
                this.$messageBox({
                    title: 'Warning',
                    persistent: true,
                    maxWidth: 400,
                    message: `You have asked to make this tag private. ${notCreatorText}`,
                    actions: [{
                        text: 'Cancel',
                    }, {
                        text: 'Continue', usage: 'danger',
                        onClick: () => save(v)
                    }]
                })
            } else {
                save(v)
            }

        },
        deleteRec(v) {
            this.$messageBox({
                title: 'Delete Tag',
                persistent: true,
                maxWidth: 400,
                message: 'Are you sure you want to delete this Tag? There may be students and user attached to it.  This action cannot be undone.',
                actions: [{
                    text: 'Cancel',
                }, {
                    text: 'Delete', usage: 'danger',
                    onClick: () => {
                        this.$refs.crud.destroy(v).then( r => {
                            this.rowData = this.rowData.filter( x => x.id !== v.id)
                            this.$emit('countChanged')
                        })
                    }
                }]
            })
        },
        copyToYear(v) {
            this.dialogParams = {
                tag_id: v.id,
                school_year_id: this.school_year.id,
            }
            this.showCopyDialog = true
        },
        switchTag(v) {
            this.dialogParams = {
                tag_id: v.id,
                school_year_id: this.school_year.id,
            }
            this.showSwitchDialog = true
        },
        addAdvancedStudentSelection() {
            if(!this.selectedStudents.length) return
            let cell = this.$refs.grid.cell
            let recs = this.selectedStudents.map(x => ({
                student_id: x.student_id,
                tag_id: cell.data.id,
                school_year_id: this.school_year.id
            }))
            this.$refs.crudStudentTag.create(recs).then(() =>{
                this.updateCellCount(cell,cell.data.student_cnt+recs.length)
            })
        },
        renderWithSubField(v) {
            let locked = !v.data.can_use
                ? '<i style="font-size:12px;" data-tip="You do not have permission to change students" class="ec-simple-tip fe-grid-icon fas fa-lock ml-2 grey--text text--lighten-1"></i>'
                : ''
            return v.data.description
                ? `<div style="line-height:24px;">${v.value}${locked}<div class="fe-grid-cell-subtext">${v.data.description}</div></div>`
                : v.value+locked
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
