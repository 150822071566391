export default {
    dataPoint: {
        defaults: {
            endpoint: 'dataPoints.php', // scope: data_point_type_id=20&sub_category_id=387&data_point_name_id=158&school_year_id=15
            rootProperty: 'points'
        },
        read: {
            params: { action: 'get' }
        },
        create: {
            params: { action: 'create' },
        },
        update: {
            params: { action: 'update' },
        },
        destroy: {
            params: { action: 'delete' },
        }
    }
}