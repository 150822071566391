<template>
    <div>
        <v-form ref="form">
            <v-toolbar fixed dark color="primary" style="position: sticky; z-index: 2; top: 0;">
                <v-toolbar-title>Incident Details</v-toolbar-title>
                <span class="ec-incident-entry-id-text">{{incident.incident_id}}</span>
                <v-spacer></v-spacer>
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn outlined v-on="on" :disabled="!incident.incident_id">
                            Options
                            <v-icon>arrow_drop_down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="showPrintDialog = true">
                            <div class="v-label theme--light normal" >
                                <v-icon small>print</v-icon>
                                Print
                            </div>
                        </v-list-item>
                        <v-list-item v-if="psportalEnabled" @click="showEmailDialog = true">
                            <div class="v-label theme--light normal">
                                <v-icon small>email</v-icon>
                                Mail
                            </div>
                        </v-list-item>
                        <v-list-item @click="deleteIncident">
                            <div class="v-label theme--light normal" style="color:red;">
                                <v-icon small color="red">delete</v-icon>
                                Delete
                            </div>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <print-selector
                    v-model="showPrintDialog"
                    :incidentId="incident.incident_id"
                    :students="students"
                />
                <email-incident
                    v-model="showEmailDialog"
                    :students="students"
                >
                </email-incident>
                <v-btn light depressed color="white" @click="save" :disabled="loading">
                    Save
                </v-btn>
            </v-toolbar>
            <div style="display: unset; overflow-y: auto;">
                <div class="d-flex" style="padding: 0 20px">
                    <div style="flex-grow: 1; padding: 4px;">
                        <fe-remote-combo
                            v-model="incident.school"
                            label="School *"
                            rootProperty="schools"
                            ref="schoolCombo"
                            :sort="sortAlphabetically"
                            :url="'schools.php?action=get&school_year_id=' + selectedSchoolYr"
                            :rules="[v => !!v || 'Field is required']"
                        ></fe-remote-combo>
                    </div>

                    <div style="flex-grow: 1; padding: 4px;">
                        <fe-remote-combo
                            v-if="!entryUserOnLoad"
                            v-model="incident.entry_user"
                            label="Entered By *"
                            ref="entryUserCombo"
                            :params="getUserParams"
                            url='users.php?action=get'
                            itemText="user_full_name"
                            :rules="[v => !!v || 'Field is required']"
                        ></fe-remote-combo>
                        <div v-if="entryUserOnLoad" style="padding-top: 8px" class="theme--light v-label">Entered By</div>
                        <v-text-field
                            v-if="entryUserOnLoad"
                            :value="entryUser"
                            readonly
                            flat solo dense
                        >
                        </v-text-field>
                    </div>

                    <div style="flex-grow: 1; padding: 4px;">
                        <fe-remote-combo
                            v-model="incident.incident_location"
                            label="Location *"
                            rootProperty='incident_locations'
                            :params="getLocationParams"
                            url='incidentLocations.php?action=get'
                            :disabled="!incident.school"
                            :sort="sortAlphabetically"
                            :rules="[v => !!v || 'Field is required']"
                        ></fe-remote-combo>
                    </div>
                </div>

                <div class="d-flex" style="padding: 0 20px">
                    <div style="flex-grow: 1; padding: 0 4px;">
                        <fe-date-picker
                            v-model="incident.incident_date"
                            label="Incident Date *"
                            :rules="[v => !!v || 'Field is required']"
                            class="ec-incident-entry-date-picker"
                        ></fe-date-picker>
                    </div>

                    <div style="flex-grow: 1; padding: 0 4px;">
                        <fe-time-picker
                            v-model="incident.incident_time"
                            label="Incident Time *"
                            :rules="[v => !!v || 'Field is required']"
                            class="ec-incident-entry-time-picker"
                        ></fe-time-picker>
                    </div>

                    <div style="flex-grow: 1; padding: 0 4px;">
                        <fe-remote-combo
                            v-model="incident.observer_user"
                            label="Observed By *"
                            :params="getUserParams"
                            url='users.php?action=get'
                            itemText="user_full_name"
                            :disabled="!incident.school"
                            :rules="[v => !!v || 'Field is required']"
                            useSearchText
                            @set="setObserver"
                        ></fe-remote-combo>
                    </div>
                </div>

                <div class="d-flex" style="padding: 0 20px">
                    <div style="flex-grow: 1; padding: 0 4px;">
                        <fe-remote-combo
                            v-model="incident.email_users"
                            label="Notify Specific Users"
                            :params="getUserParams"
                            url='users.php?action=get'
                            item-text="user_full_name"
                            item-value="id"
                            chips
                            multiple
                            :disabled="!students.length"
                            :selectAllOption="false"
                        ></fe-remote-combo>
                    </div>

                    <div class="d-flex" style="padding: 0 4px; align-items: center;">
                        <find-assigned-staff
                            :emailUsers="incident.email_users"
                            @update="incident.email_users=$event"
                            :students="students"
                            :disabled="!students.length"
                        />
                    </div>
                </div>

                <div>
                    <v-toolbar fixed color="tertiary" style="position: sticky; z-index: 1; top: 0;">
                        <v-toolbar-title>Students Involved</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn light depressed color="white" @click="showStudentDialog = true">
                            Add Student
                        </v-btn>
                        <find-student v-model="showStudentDialog" :students="students" @select="addStudent" @selectedSchoolYear="setSelectedYr" />
                        <template v-slot:extension>
                            <v-tabs
                                color="primary" ref="tabc"
                                v-model="activeTabIndex"
                                class="ec-incident-entry-full-width"
                                style="padding: 0 20px;"
                            >
                                <v-tab
                                    v-for="(student, index) in students"
                                    :index="index"
                                    :key="index"
                                    :class="{formNotValid:!student.formValid}"
                                >
                                    <v-icon color="error" v-show="!student.isCompleted">error</v-icon>
                                    <v-icon v-show="student.isCompleted">person</v-icon>
                                    <span class="ec-incident-entry-student-name">{{student.student_full_name}}</span>
                                </v-tab>
                            </v-tabs>
                        </template>
                    </v-toolbar>
                    <v-window v-model="activeTabIndex">
                        <v-window-item
                            ref="studentTabs"
                            v-for="(student, index) in students"
                            :index="index"
                            :key="index"
                        >
                            <v-card style="box-shadow: none;">
                                <v-card-text style="padding: 0 24px;">
                                    <student-details
                                        :studentRec="student"
                                        @copyfields="copyFields"
                                        @removestudent="removeStudent"
                                        @sendfollowup="sendFollowUp"
                                        :studentCount="students.length"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-window-item>
                    </v-window>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import StudentDetails from './StudentDetails'
import PrintSelector from './PrintSelector'
import EmailIncident from './EmailIncident'
import FindAssignedStaff from './FindAssignedStaff'
import FindStudent from './FindStudent'
const json = require('json5')
const addNulls = (key,v)=>{
    if(v==null) return ""
    return v
}

export default {
    name: 'IncidentCreation',
    components: {
        PrintSelector,
        EmailIncident,
        FindAssignedStaff,
        FindStudent,
        StudentDetails
    },
    props: {
        msg: {type: String},
        params: {type: Object},
        identifier: {}
    },
    data() {
        return {
            entryUserOnLoad: false,
            showPrintDialog: false,
            showEmailDialog: false,
            showStudentDialog: false,
            select: undefined,
            scrolly: 0,
            timeMenu: false,
            dateMenu: false,
            datetime: this.$dayjs().toDate(),
            loadedIncident: null,
            incident: {
                incident_id: undefined,
                school: undefined,
                entry_user: undefined,
                incident_location: undefined,
                incident_date: this.$dayjs().format('YYYY-MM-DD'),
                incident_time: this.$dayjs().format('HH:mm'),
                observer_user: null,
                email_users: [],
            },
            students: [],
            activeTabIndex: null,
            selectedSchoolYr: null,
            loading: false,
        }
    },
    computed: {
        ...mapState('incidentEntry', ['appParams']),
        ...mapState('global', ['sessionUser']),
        entryUser() {
            return this.incident.entry_user && this.incident.entry_user.user_full_name
        },
        getUserParams() {
            if (!this.$data.incident.school) return {}
            return {
                school_id: this.$data.incident.school.id,
                active: 1
            }
        },
        getLocationParams() {
            if (!this.incident.school) return {}
            return {
                school_id: this.incident.school.id,
                active_only: 1
            }
        },
        psportalEnabled: me => me.$store.state.global.shareableStores.district.psportal_enabled
    },
    mounted() {
        this.incident.entry_user = this.sessionUser.user
        this.incident.observer_user = this.sessionUser.user
        this.$el.querySelector('.v-tabs-bar').classList.add('ec-incident-entry-sticky')

        let loadId = this.params?.incident_id
        if(loadId) {
            this.load(loadId)
        } else {
            this.showStudentDialog = true
        }

        window.addEventListener('message',e => {
            if(e.origin.replace(/\/$/,'') == this.$axios.defaults.baseURL.replace(/\/$/,'')
                || this.$axios.defaults.baseURL == 'educlimber/'
                || e.origin.endsWith('.educlimber.app')
                || e.origin.endsWith('.educlimber.com')
            ){
                const target = this.$axios.defaults.baseURL == 'educlimber/' ? '*' : this.$axios.defaults.baseURL
                const eventData = (typeof e.data === 'string' || e.data instanceof String) ? JSON.parse(e.data) : e.data
                if(eventData.action) {
                    if(eventData.action == 'parentclose') {
                        const originalData = JSON.stringify(this.originalRec,addNulls)
                        const currentData = JSON.stringify(this.getSubmitRec(),addNulls)
                        const msgData = JSON.stringify({
                            identifier: this.identifier,
                            action:'close'
                        })
                        if(originalData == currentData) {
                            window.parent.postMessage(msgData,target)
                        } else {
                            this.$messageBox({
                                title: 'Confirm Close',
                                persistent: true,
                                message: 'You have unsaved changes, are you sure you want to close?',
                                maxWidth: '500',
                                actions: [{
                                    text: 'No'
                                }, {
                                    text: 'Yes',
                                    primary: true,
                                    onClick: () => {
                                        window.parent.postMessage(msgData,target)
                                    }
                                }]
                            })
                        }
                    }
                }
            }
        },false)
    },
    methods: {
        onScroll() {
            this.scrolly = window.pageYOffset || document.documentElement.scrollTop
            this.scrollx = window.pageXOffset || document.documentElement.scrollLeft
            let el = this.$el.querySelector('.v-tabs-bar')
            if(el.getBoundingClientRect().top <= 60) {
                el.classList.add('ec-incident-entry-raised')
            } else {
                el.classList.remove('ec-incident-entry-raised')
            }

        },
        addStudent(student) {
            if(!this.incident.school) {
                this.incident.school = {id:student.school_id, name:student.school_name}
            }

            let url = process.env.VUE_APP_BASE_URI+'students.php?action=get&property=student_image&id=' + student.student_id
            student.image = `background-image: url(${url});`

            this.students.push(student)
            this.activeTabIndex = this.students.length-1

            if(!student.student_incident_id) {
                student.completed = 1
            }

        },
        validateForm() {
            let me = this
            let valid = true
            if(this.students.length<1) {
                this.$snackbars.$emit('new', { text: 'Incident contains no students', usage: 'warning' })
                return false
            }

            if(!this.$refs.form.validate()) valid = false
            // let form = null
            this.$refs.studentTabs.forEach((x,i) => {
                x.$children.forEach(y => {
                    if(y.$children[0].$refs.form && !y.$children[0].$refs.form.validate()) {
                        // only set active tab to the first invalid tab found
                        if(valid) {
                            me.activeTabIndex = i
                        }
                        valid = false
                    }
                })
            })
            return valid
        },
        sendFollowUp() {
            this.incident.email_users = [
                ...this.incident.email_users,
                this.incident.entry_user,
                this.incident.observer_user
            ].filter( (x,i,email_users) => { // only unique
                return email_users.findIndex( user => user.id === x.id ) === i
            })
        },
        sortAlphabetically(item1, item2) {
            return item1.name.toLowerCase() < item2.name.toLowerCase() ? -1 : 1
        },
        test() {
            this.$errorPrompt({
                title: 'this is the title',
                message: 'this is the msg',
            })

        },
        save() {
            if( !this.validateForm() ) {
                this.$snackbars.$emit('new', { text: 'Please fill in required fields', usage: 'warning' })
            } else {
                this.loading = true
                let submitRec = this.getSubmitRec()
                let url = 'behaviorIncident.php?action=' + (submitRec?.incident_id ? 'update' : 'create')
                this.$axios.post(url,JSON.stringify(submitRec,addNulls))
                    .then(r => {
                        if(r.data) {
                            if(r.data.success) {
                                this.load(r.data.id)
                                this.$snackbars.$emit('new', { text: r.data.msg, usage: 'success' })
                            } else {
                                this.$snackbars.$emit('new', { text: r.data.msg, usage: 'error' })
                            }
                        }
                    }).catch( err => {
                        console.warn('err: ',err)
                    }).finally(() => {
                        this.loading = false
                    })
            }
        },
        getSubmitRec() {
            try {
                let i = this.incident
                let submitRec = {
                    incident_id: i.incident_id,
                    edit_student_id: i.edit_student_id,
                    school_id: i.school.id,
                    entry_user_id: i.entry_user.id,
                    incident_location_id: i.incident_location && i.incident_location.id,
                    incident_date: i.incident_date,
                    incident_time: i.incident_time,
                    datetime: i.incident_date +' '+ i.incident_time,
                    observer_user_id: typeof i.observer_user.id == 'string' ? null : i.observer_user.id,
                    observer_free_text: typeof i.observer_user.id == 'string' ? i.observer_user.id : null,
                    email_users: i.email_users.map(x => x.id),
                }
                submitRec.student_incidents = this.students.map( s => {
                    return {
                        description: s.description,
                        parent_contact_date: s.parent_contact_date,
                        parent_contact_comments: s.parent_contact_comments,
                        incident_motivation_id: s.incident_motivation ? s.incident_motivation.id : null,
                        completed: s.completed || 0,
                        student_id: s.student_id,
                        sub_categories: s.behaviors.map( b => {
                            return {
                                incident_behavior_type_id: b.incident_behavior_type_id,
                                incident_behavior_id: b.incident_behavior_id,
                                student_incident_behavior_id: b.student_incident_behavior_id,
                                primary_flag: b.primary_flag ? 1 : '',
                                descriptors: b.descriptors.filter(d => d.checked).map(d => {return {id:d.id}}),
                                incident_fields: b.incident_fields.map(f => {
                                    return {
                                        field_id: f.id,
                                        response: f.value || ""
                                    }
                                })
                            }
                        }),
                        responses: s.responses.map( r => {
                            let response = {
                                student_incident_response_id: r.student_incident_response_id,
                                incident_response_id: r.incident_response ? r.incident_response.id : null,
                                primary_flag: r.primary_flag,
                                responder_user_id: r.responder_user_id,
                                admin_flag: r.admin_flag,
                                serve_flag: (r.incident_response && r.incident_response.serve_flag) || r.serve_flag,
                                served_start_date: r.served_start_date && r.incident_response.serve_flag
                                ? this.$dayjs(r.served_start_date).format('YYYY-MM-DD HH:mm:ss')
                                : '',
                                served_end_date: r.served_end_date && r.incident_response.serve_flag
                                ? this.$dayjs(r.served_end_date).format('YYYY-MM-DD HH:mm:ss')
                                : ''
                            }
                            r.custom_fields.forEach(cf => {
                                response['incident_response_field'+cf.id] = cf.value || ""
                            })
                            return response
                        })
                    }
                })
                submitRec.student_incidents = JSON.stringify(submitRec.student_incidents,addNulls)
                return submitRec
            } catch(e) {
                console.warn(e)
                return null
            }

        },
        deleteIncident() {
            if(this.incident.incident_id) {
                this.$messageBox({
                    title: 'Delete Incident',
                    message: 'Are you sure you want to delete this incident? This change is permanent!',
                    maxWidth: '500',
                    actions: [{
                        text: 'No'
                    }, {
                        text: 'Yes',
                        primary: true,
                        onClick: () => {
                            let url = 'behaviorIncident.php?action=delete'
                            this.$axios.post(url,
                                JSON.stringify({
                                    incident_id: this.incident.incident_id
                                })
                            ).then( r => {
                                if(r.data) {
                                    if(r.data.success) {
                                        this.$snackbars.$emit('new', { text: r.data.msg, usage: 'success' })

                                        this.clear()

                                    } else {
                                        this.$snackbars.$emit('new', { text: r.data.msg, usage: 'warning' })
                                    }
                                }
                            }).catch( err => {
                                console.warn('err: ',err)
                            })
                        }
                    }]
                })
            }
        },
        clear() {
            this.incident = {
                entry_user: this.activeUser,
                observer_user: this.activeUser,
                incident_date: this.$dayjs().format('YYYY-MM-DD'),
                incident_time: this.$dayjs().format('HH:mm'),
                email_users: []
            }
            this.students.splice(0)
            this.$refs.form.reset()
            this.showStudentDialog = true
        },
        removeStudent(studentRec) {
            if(!studentRec.student_incident_id) {
                this.students = this.students.filter( x => x != studentRec)
            } else {
                let url = 'behaviorIncident.php?action=delete&property=student_incident'
                this.$axios.post(url,
                    JSON.stringify({
                        incidents: [{
                            id: studentRec.student_incident_id,
                            student_id: studentRec.student_id
                        }]
                    })
                ).then( r => {
                    if(r.data) {
                        if(r.data.success) {
                            this.load(this.incident.incident_id)
                            this.$snackbars.$emit('new', { text: r.data.msg, usage: 'success' })
                        } else {
                            this.$snackbars.$emit('new', { text: r.data.msg, usage: 'warning' })
                        }
                    }
                }).catch( err => {
                    console.warn('err: ',err)
                })
            }
        },
        copyFields(source,overwrite) {
            let set = (target,property,source) => {
                if(property == 'behaviors') {
                    if(target[property] && target[property][0].incident_behavior_type && !overwrite) {
                        return
                    }
                } else if(property == 'responses') {
                    if(target[property] && target[property][0].incident_response && !overwrite) {
                        return
                    }
                } else if(target[property] !== undefined && !overwrite) {
                    return
                }

                this.$set(target,property,source)
            }

            this.students.forEach( target => {
                if(target.student_id == source.student_id) return
                set(target,'description',source.description)
                set(target,'incident_motivation',source.incident_motivation)
                set(target,'completed',source.completed)
                set(target,'parent_contact_date',source.parent_contact_date)
                set(target,'parent_contact_comments',source.parent_contact_comments)
                set(target,'behaviors',source.behaviors.map( b => {
                    let found = target.behaviors.find(tb => {
                        if(tb.incident_behavior_id && b.incident_behavior_id) {
                            return tb.incident_behavior_id == b.incident_behavior_id
                        }
                        return false
                    }) || {}
                    return {
                        student_incident_behavior_id: found.student_incident_behavior_id,
                        incident_behavior_type_id: b.incident_behavior_type_id,
                        incident_behavior_id: b.incident_behavior_id,
                        primary_flag: b.primary_flag,
                        descriptors: b.descriptors && b.descriptors.map( x => {
                            return {
                                id: x.id,
                                active: x.active,
                                checked: x.checked,
                                description: x.description
                            }
                        }),
                        incident_fields: b.incident_fields && b.incident_fields.map( x => x )
                    }
                }))
                set(target,'responses', source.responses.map( r => {
                    let found = target.responses.find(tr => {
                        if(tr.incident_response && r.incident_response) {
                            return tr.incident_response.id == r.incident_response.id
                        }
                        return false
                    }) || {}
                    return {
                        student_incident_response_id: found.student_incident_response_id,
                        incident_response: r.incident_response,
                        primary_flag: r.primary_flag,
                        admin_flag: r.admin_flag,
                        responder_user_id: r.responder_user_id,
                        served_start_date: r.served_start_date,
                        served_end_date: r.served_end_date,
                        custom_fields: r.custom_fields
                    }
                }))
            });
        },
        load(incidentId) {
            if(!incidentId) return
            let url = 'behaviorIncident.php?action=get&property=incident&incident_id='+incidentId
            this.incident = {}
            this.students.splice(0)
            this.$nextTick(function() {
                this.$axios.get(url).then( response => {
                    if(response && response.data) {
                        let rec = response.data.incident
                        let incident = {}
                        if(!rec) {
                            this.$messageBox({
                                title: 'Incident Not Found',
                                persistent: true,
                                message: 'The incident for this id could not be found.',
                                maxWidth: '500',
                                actions: [{
                                    text: 'OK',
                                    primary: true,
                                    onClick: () => {
                                        this.clear()
                                    }
                                }]
                            })
                        }
                        this.loadedIncident = rec;

                        this.entryUserOnLoad = !!rec.entry_user_id

                        incident.incident_id = rec.id
                        incident.edit_student_id = rec.edit_student_id
                        incident.entry_datetime = rec.entry_datetime
                        incident.school = rec.school_id ? {id:rec.school_id, name:rec.school_name} : null
                        incident.entry_user = rec.entry_user_id ? {id:rec.entry_user_id, user_full_name:rec.entry_user_full_name} : null
                        incident.incident_location = rec.incident_location_id ? {id:rec.incident_location_id, name:rec.incident_location_name} : null
                        incident.incident_date = rec.incident_datetime ? this.$dayjs(rec.incident_datetime).format('YYYY-MM-DD') : ''
                        incident.incident_time = rec.incident_datetime ? this.$dayjs(rec.incident_datetime).format('HH:mm') : ''
                        incident.observer_user = rec.observer_user_id ? {id:rec.observer_user_id, user_full_name:rec.observer_user_full_name} : rec.observer_user_full_name ? rec.observer_user_full_name : null
                        incident.email_users = rec.email_users || []

                        this.incident = incident

                        rec.student_incidents.forEach( s => {
                            let student = Object.assign({
                                incident_id: rec.id,
                                student_incident_id: s.id,
                                student_id: s.student_id,
                                student_full_name: s.student_full_name,
                                school_id: s.school_id,
                                school_name: s.school_name,
                                grade_id: s.grade_id,
                                grade_desc: s.grade_desc,
                                description: s.description,
                                incident_motivation: s.incident_motivation,
                                parent_contact_date: s.parent_contact_date,
                                parent_contact_comments: s.parent_contact_comments,
                                completed: s.completed,
                                isCompleted: s.completed,
                                behaviors: s.behaviors.map( b => {
                                    return {
                                        student_incident_behavior_id: b.id,
                                        incident_behavior_type_id: b.incident_behavior_type_id,
                                        incident_behavior_id: b.incident_behavior_id,
                                        primary_flag: b.primary_behavior,
                                        admin_flag: b.admin_flag,
                                        complete_security_id: b.complete_security_id,
                                        descriptors: b.descriptors,
                                        incident_fields: b.custom_fields
                                    }
                                }),
                                responses: s.responses.map( r => {
                                    return {
                                        student_incident_response_id: r.id,
                                        incident_response: {
                                            id:r.incident_response_id,
                                            name:r.incident_response_name,
                                            serve_flag: r.serve_flag,
                                            require_responder: r.require_responder
                                        },
                                        primary_flag: r.primary_response,
                                        admin_flag: r.admin_flag,
                                        custom_fields: r.custom_fields,
                                        serve_flag: r.serve_flag,
                                        served_start_date: r.served_start_date,
                                        served_end_date: r.served_end_date,
                                        num_school_days: r.num_school_days,
                                        responder_user_id: r.responder_user_id,
                                    }
                                }),
                            })
                            this.addStudent(student)
                        })

                        this.$nextTick( x => {
                            // this needs to go into next tick as some the responses for custom fields
                            // are not yet decoded
                            this.originalRec = this.getSubmitRec()
                        })
                    }
                }).catch( err => {
                    console.warn('err: ',err)
                })

            })
        },
        setSelectedYr(value) {
            this.selectedSchoolYr = value.id ? value.id : value
        },
        setObserver(v) {
            if (typeof v === 'string') {
                this.incident.observer_user = {
                    id: v,
                    user_full_name: v
                }
            } else {
                this.incident.observer_user = v
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.ec-incident-entry-full-width {
    position:relative;
    left:-24px;
    width:calc(100% + 48px);
}
.ec-incident-entry-date-picker {
    margin-top: 8px;
}
.ec-incident-entry-time-picker {
    margin-top: 8px;
}
</style>

<style>
.formNotValid .ec-incident-entry-student-name {
    color: red !important;
}
div.ec-incident-entry-sticky {
    position: sticky !important;
    position: -webkit-sticky !important;
    top: 60px !important;
    z-index: 1;
    transition: background-color .5s, box-shadow .5s;
    background: #ecedf1 !important;
    border-color: #ecedf1 !important;
}
.ec-incident-entry-raised {
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.206), 0 0 0 2px rgba(0,0,0,0.02);
}
.ec-incident-entry-id-text {
    padding-top: 6px;
    padding-left: 8px;
    font-size: 10px;
}
</style>
