export default {
    blues: ['#0049FF', '#2B87FF', '#539AF8', '#89B7F4', '#C4DCFA'],
    greens: ['#0EA449', '#49C379', '#5BE893', '#84FFB5', '#B4F8CF'],
    oranges: ['#FF8E07', '#FFA200', '#FFBD00', '#FFE072', '#FFEEAA'],
    reds: ['#F02D1F', '#FD4336', '#FF675D', '#FF8D85', '#F8D1CE'],
    aquas: ['#3CCCCA', '#53DAD8', '#66EEEC', '#93F9F7', '#C0FBFA'],
    purples: ['#5A53C9', '#746DE0', '#8E88EA', '#AFAAF9', '#DCDAFB'],

    getList() {
        return [].concat(this.blues, this.greens, this.oranges, this.reds, this.aquas, this.purples)
    },

    getColor(idx) {
        return this.getList()[idx % this.getList().length]
    },

    getIndex(color) {
        return this.getList().indexOf(color)
    }
}
