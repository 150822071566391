<template>

    <div class="flex-fill flex-column">
        <div class="flex-shrink-1">
            <div class="headline">Interventions</div>

            <div class="caption mb-2">
                Interventions can be configured here to help your district document and monitor the impact of intervention supports.
                Interventions consist of identifying level of need, using strategies to improve a skill area,  monitoring and reviewing progress.
                 This information is aggregated and also is visible on the student profile.
            </div>

            <div>
                <fe-action-list
                    @rowClick="go"
                    :items="items"
                    :showSearch="false"
                    :addButton="false"
                    :editButton="false"
                    :showFiltering="false"
                    class="intervention-pages mt-3"
                    itemText="display"
                />
            </div>

            <div v-if="$isSupport() && fbMonitorManage" class="d-flex behavior-code-container mt-8" @click="go('fast-bridge-integration')">
                <div class="behavior-code-title">
                    FastBridge Integration
                </div>

                <div class="ml-auto">
                    <fe-icon-btn useIcon="fas fa-chevron-right"/>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: "LandingPage",
        computed: {
            ...mapState('global', ['fbMonitorManage']),
            fbMonitorManage () { return this.$store.state.global.fbMonitorManage },
            items(v) {
                let i = [...this.$parent.items.slice(1).filter(rec => rec.landingPage)]

                i.forEach((item) => {
                    if (item.count) {
                        item.display = item.name + '        <span style="margin-left: 20px; font-size: 12px;">'+item.count+'</span>'
                    } else {
                        item.display = item.name
                    }

                })

                return i
            }
        },
        methods: {
            go(page) {
                if (page === 'fast-bridge-integration') {
                    page = {
                        name: 'FastBridge Integration',
                        show: 'fast-bridge-integration',
                        persist: false,
                        component: 'fast-bridge-integration',
                        landingPage: true
                    }
                }
                this.$emit('go', page)
            },
            loadCounts() {
                this.$modelGet('interventionLevel', { active: 1}).then(c => {
                    let f = this.items.find(r => r.name === 'Levels')
                    if (f)
                        f.count = c.length

                })

                this.$modelGet('interventionType', { active: 1}).then(c => {
                    let f = this.items.find(r => r.name === 'Strategies')
                    if (f)
                        f.count = c.length
                })

                this.$modelGet('interventionExitStatus', { active: 1}).then(c => {
                    let f = this.items.find(r => r.name === 'Exit Status')
                    if (f)
                        f.count = c.length
                })

                this.$modelGet('interventionAttendanceType', { active: 1}).then(c => {
                    let f = this.items.find(r => r.name === 'Participation Types')
                    if (f)
                        f.count = c.length
                })
            }
        },
        mounted() {
            this.loadCounts()
        }
    }
</script>

<style lang="scss" scoped>
    .caption {
        color: lightgray;
    }

    ::v-deep .intervention-pages .layout.mb-2.row {
        display: none
    }

    .behavior-code-container {
        border: 2px solid #E0E1E6;
        padding: 8px;
        height: 64px;
        cursor: pointer;

        .behavior-code-title {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            padding-top: 10px;
            padding-left: 10px;
            color: #000000;
        }
    }
</style>
