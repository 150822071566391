<template>
    <div>
        <chart-panel :border="!showLoader && collectionChart" class="chart-container">
            <v-skeleton-loader
                class="mx-auto"
                :height="400"
                :loading="showLoader"
                type="image">
                <highcharts
                    style="height: 100%;"
                    class="ec-chart"
                    ref='feChart'
                    :options="chartOptions"
                    v-if="chartOptions.chart && chartOptions.chart !== 'no-data'"
                />
                <div v-else>
                    <slot name="no-data">
                        <v-flex>
                            <div class="ec-chart-no-data-title">No Data Found</div>
                            <i class="fas fa-info-circle ec-chart-no-data-title-info"></i>
                            <div class="text-center">
                                {{ (chartOptions && chartOptions.noDataText) || 'No data found. Please click the update button to verify your chart parameters.' }}
                            </div>
                        </v-flex>
                    </slot>
                </div>
                <div class="ec-chart-title" :style="{'position': 'relative', 'bottom': getHeightTitle, 'left': '15px', 'float': 'left', 'color': 'rgb(126, 132, 148)'}">
                    <slot name="chart-title"></slot>
                </div>
                <div class="ec-chart-menu-btn" :style="{'position': 'relative', 'bottom': getHeight, 'float': 'right'}">
                    <slot name="before-menu"></slot>
                    <fe-icon-btn v-if="collectionChart && chartOptions.chart" useIcon="far fa-trash" @click="showDeleteDialog = true"></fe-icon-btn>
                    <v-menu v-if="menu" offset-y left v-model="chartSettings" :close-on-content-click="clickClose">
                        <template  v-slot:activator="{ on }">
                            <v-btn text icon><v-icon v-on="on" @click="close=false">more_vert</v-icon></v-btn>
                        </template>
                        <slot name="menu">
                            <v-card flat style="background: #fff; padding: 10px">
                                <slot name="extra-menu-items"></slot>
                                <v-btn style="padding: 0" v-if="printable" text @click="printChart()">
                                    <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-print</v-icon>
                                    Print Image</v-btn><br v-if="chartOptions.chart"/>
                                <v-btn style="padding: 0" v-if="downloadable" text @click="exportChart()">
                                    <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-download</v-icon>Download Image</v-btn>
                                <br v-if="chartOptions.chart && chartOptions.type !== 'studentScore'"/>
                                <v-btn style="padding: 0" v-if="collectionChart && chartOptions.chart && chartOptions.type !== 'studentScore'" text @click="showModifyDialog = true">
                                    <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-cog</v-icon>Update Pin</v-btn><br/>
                                <v-btn style="padding: 0" v-if="!isCollection && collectionChart && chartOptions.chart" text @click="doRename">
                                    <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-edit</v-icon>Rename Pin</v-btn>
                                <v-btn style="padding: 0" @click="startModifyMeasure" text v-if="isCollection && collectionChart && monitor">
                                    <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-edit</v-icon>Modify Measure
                                </v-btn><br/>
                                <v-btn style="padding: 0" @click="enteringScores = true" text v-if="isCollection && collectionChart && monitor">
                                    <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-table</v-icon>Enter Scores
                                </v-btn>
                            </v-card>
                        </slot>
                    </v-menu>
                </div>
                <div>
                    <slot name="after-chart"></slot>
                </div>
            </v-skeleton-loader>

            <fe-dialog v-if="showDeleteDialog"
               persistent
               title="Remove Pinned Chart?"
               acceptButtonText="Remove"
               dismissButtonText="Cancel"
               @accept="showDeleteDialog = false; removePinnedChart()"
               @dismiss="showDeleteDialog = false"
               usage="error"
               :body="deleteConfirmText">
            </fe-dialog>
            <fe-dialog v-if="showModifyDialog"
               persistent
               title="Modify Saved Search"
               acceptButtonText="Save"
               dismissButtonText="Cancel"
               width="500"
               @accept="showModifyDialog = false; modifySavedSearch()"
               @dismiss="showModifyDialog = false">
                <v-layout row wrap>
                    <div class="d-flex align-center mb-2" v-if="fields.includes('SCHOOL_YEAR_ID')">
                        <span style="width: 100px">School Year: </span>
                        <fe-remote-exclude-combo
                            style="width: 290px"
                            v-model="selected.SCHOOL_YEAR_ID"
                            hide-details
                            rootProperty="years"
                            :url="$models.getUrl('schoolYear', 'read')"
                            itemText="name"
                            lazyLoad/>
                    </div>
                    <div class="d-flex align-center mb-2" v-if="fields.includes('SCHOOL_ID')">
                        <span style="width: 100px">School: </span>
                        <fe-remote-exclude-combo
                            style="width: 290px"
                            v-model="selected.SCHOOL_ID"
                            hide-details
                            rootProperty="schools"
                            :url="$models.getUrl('school', 'read')"
                            lazyLoad/>
                    </div>
                    <div class="d-flex align-center mb-2" v-if="fields.includes('GRADE_ID')">
                        <span style="width: 100px">Grade: </span>
                        <fe-remote-exclude-combo
                            style="width: 290px"
                            v-model="selected.GRADE_ID"
                            hide-details
                            rootProperty="grades"
                            :url="$models.getUrl('grade', 'read')"
                            lazyLoad/>
                    </div>
                    <div class="d-flex align-center mb-2" v-if="fields.includes('COHORT_SCHOOL_YEAR_ID')">
                        <span style="width: 100px">Cohort School Year: </span>
                        <fe-remote-exclude-combo
                            style="width: 290px"
                            v-model="selected.COHORT_SCHOOL_YEAR_ID"
                            hide-details
                            rootProperty="years"
                            :url="$models.getUrl('schoolYear', 'read')"
                            lazyLoad/>
                    </div>
                    <div class="d-flex align-center mb-2" v-if="fields.includes('GENDER')">
                        <span style="width: 100px">Gender: </span>
                        <fe-remote-exclude-combo
                            style="width: 290px"
                            v-model="selected.GENDER"
                            hide-details
                            rootProperty="genders"
                            url="filters.php?action=get&filter=gender"
                            itemText="gender"
                            itemValue="gender"
                            lazyLoad/>
                    </div>
                </v-layout>
            </fe-dialog>
            <fe-dialog v-if="showRenameDialog"
               persistent
               title="Rename Chart"
               acceptButtonText="Apply"
               dismissButtonText="Cancel"
               @accept="showRenameDialog = false; renamePin()"
               @dismiss="showRenameDialog = false">
                <v-layout row>
                    <div class="d-flex align-center">
                        <span>Rename:</span>
                        <fe-text-field v-model="newPinName" class="ml-2"></fe-text-field>
                    </div>
                </v-layout>
            </fe-dialog>

            <fe-dialog
                title="Modify Measure"
                v-if="measureDialog.show"
                @accept="updateMeasure"
                @close="measureDialog.show=false"
                persistent
                dismissButtonText="Cancel"
                acceptButtonText="Done"
                :acceptButtonDisabled="canUpdateMeasure"
                disableAutoclose
            >
                <academic-monitor-schedule
                    ref="monitorSchedule"
                    v-model="measureDialog.record"
                    :students="measureDialog.record[0].students"
                    standalone
                />
            </fe-dialog>

            <fe-dialog
                title="Enter Score"
                v-if="enteringScores"
                @accept="enteringScores=false"
                @close="enteringScores=false"
                persistent
                dismissButtonText="Cancel"
                acceptButtonText="Done"
                width="800"
            >
                <div style="height: 60vh;">
                    <student-monitor-scores
                        :params="{ student_monitor_plan_id: monitor.student_monitor_plan_id }"
                        :showFilters="false"
                    />
                </div>

            </fe-dialog>
        </chart-panel>
    </div>
</template>

<script>
    import windowOptionsMixin from '@/mixins/windowOptions'
    import StudentMonitorScores from '@/components/modules/intervention/monitor/StudentMonitorScores'
    import AcademicMonitorSchedule from '@/components/modules/intervention/creation/AcademicMonitorSchedule'
    import ChartPanel from '@/components/charts/ChartPanel'

    export default {
        name: 'Chart',
        mixins: [ windowOptionsMixin ],

        props: {
            title: { type: String, default: null },
            type: { type: String, default: 'bar' },
            series: { type: Array, default: () => [] },
            categories: { type: Array, default: () => [] },
            menu: { type: Boolean, default: true },
            printable: { type: Boolean, default: true },
            downloadable: { type: Boolean, default: true },
            config: { type: Object },
            collectionChart: { type: Boolean, default: false },
            stopClick: {
                type: Boolean,
                default: false
            },
            rawData: { type: Array, default: () => [] },
            monitor: { type: Object, default: () => {}},
            isCollection: { type: Boolean, default: false },
            pinTitle: { type: String, default: null },
        },

        components: {
            Chart: () => import('./Chart.vue'),
            StudentMonitorScores, AcademicMonitorSchedule, ChartPanel
        },

        data() {
            return {
                chartSettings: false,
                stackedType: false,
                legendPlacement: 'bottom',
                chartOptions: null,
                extraOptions: {
                    plotOptions: {
                        series: {
                            point: {
                                events: {
                                    click: null
                                }
                            }
                        }
                    }
                },
                showLoader: false,
                showDeleteDialog: false,
                reload: false,
                collectionChartData: {},
                showModifyDialog: false,
                showRenameDialog: false,
                newPinName: null,
                selected: {
                    SCHOOL_YEAR_ID: [],
                    SCHOOL_ID: [],
                    GRADE_ID: [],
                    COHORT_SCHOOL_YEAR_ID: [],
                    GENDER: []
                },
                searchArguments: [],
                fields: [],
                close: false,
                measureDialog: {
                    show: false,
                    record: null,
                    students: []
                },
                enteringScores: false,
            }
        },

        computed: {
            getChart() {
                return this.$refs.feChart.chart
            },
            getHeight() {
                if (this.config.chart === 'no-data') {
                    return '91px'
                } else {
                    return this.config && this.config.chart && this.config.chart.height ? this.config.chart.height.toString() + 'px' : '400px'
                }
            },
            getHeightTitle() {
                if (this.config.chart === 'no-data') {
                    return '91px'
                } else {
                    return this.config && this.config.chart && this.config.chart.height ? (this.config.chart.height-15).toString() + 'px' : '400px'
                }
            },
            deleteConfirmText() {
                return 'Are you sure you want to remove this pin?'
            },
            schoolYearValid() {
                let length = this.selected.schoolYear.length
                return length > 0 && length < 6
            },
            clickClose() {
                return this.close
            },
            canUpdateMeasure() {
                let canUpdate=true
                let notRequired = ['start_time', 'end_time', 'intervention_id', 'primary_flag']

                Object.keys(this.measureDialog.record[0]).forEach(field => {
                    if (!this.measureDialog.record[0][field] && notRequired.indexOf(field) == -1) {
                        canUpdate=false
                    }
                })
                return !canUpdate
            },
        },

        methods: {
            reflow() {
                this.$nextTick(() => {
                    this.$refs['feChart']?.chart?.redraw()
                })
            },
            exportChart() {
                let fileName = 'Chart'
                if(this.title) fileName = this.title
                this.getChart.exportChart({
                    type: 'application/pdf',
                    filename: fileName
                })
            },
            printChart() {
                this.getChart.print()
                this.close = true
            },
            removePinnedChart() {
                let dash_id = this.collectionChartData.dash_id || this.chartOptions.dashboard_id
                let params = {
                    dashboard_id: dash_id ? dash_id : null,
                    saved_search_id: this.collectionChartData.saved_search_id ? this.collectionChartData.saved_search_id : null
                }
                this.$axios.post('dashboard.php?action=delete&property=saved_search', { dashboard_saved_search: params })
                    .then(response => {
                        if(response.data.success) {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                            this.$store.commit('collection/set', {
                                key: 'reloadCollection',
                                value: true
                            })
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
            },
            doRename() {
                this.newPinName = this.pinTitle
                this.showRenameDialog = true
            },
            renamePin() {
                if(this.newPinName) {
                    let params = {
                        dashboard_id: this.collectionChartData.dash_id ?  this.collectionChartData.dash_id : null,
                        saved_search_id: this.collectionChartData.saved_search_id ? this.collectionChartData.saved_search_id : null,
                        rank: this.collectionChartData.index ? this.collectionChartData.index : null,
                        title: this.newPinName
                    }
                    this.$axios.post('dashboard.php?action=update&property=saved_search', { saved_searches: [params] })
                        .then(response => {
                            if(response.data.success) {
                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                                this.$emit('update:pinTitle', this.newPinName)
                            } else {
                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                            }
                        })
                }
            },
            modifySavedSearch() {
                let params = []
                let sel = {}
                this.searchArguments.forEach(p => {
                    let name = p.param_code
                    if(this.selected[name] && this.selected[name].length > 0) {
                        // add new params
                        if(name !== 'GENDER') {
                            sel[name] = this.selected[name].map(value => value.id)
                        } else  {
                            sel[name] = this.selected[name].map(value => value.gender)
                        }
                        params.push({
                            saved_search_id: p.saved_search_id,
                            saved_search_param_id: p.param_id,
                            value: sel[name].toString()
                        })
                    } else  {
                        // add old params on top of new params
                        params.push({
                            saved_search_id: p.saved_search_id,
                            saved_search_param_id: p.param_id,
                            value: p.argument_value
                        })
                    }
                })

                this.$axios.post('savedSearch.php?action=update&property=arguments&saved_search_id=' + this.collectionChartData.saved_search_id, { saved_search_arguments: params })
                    .then(response => {
                        if(response.data.success) {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                            this.$store.commit('collection/set', {
                                key: 'reloadCollection',
                                value: true
                            })
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
            },
            doClick(event, point) {
                let me = this
                this.rawData.forEach(d => {
                    if (d.data_point == event.point.category) {
                        return me.$store.commit('global/addDockableWindow', {
                            name: d.clickable + ': Score Breakdown',
                            data: d,
                            component: 'assessment-history',
                            attrs: {
                                studentId: d.student_id,
                                subCategoryId: d.sub_category_id,
                                dataPointScoreId: d.data_point_score_id
                            }
                        })
                    }
                })
                this.$emit('chartClicked', event, point)
            },
            addChartClick() {
                if (this.stopClick) return
                let me = this
                this.extraOptions.plotOptions.series.point.events.click = function(event) {
                    event.preventDefault()
                    me.doClick(event, {...event.point}) // this is a hack.  For some reason point gets cleared out up the chain of events....
                }
                let o1 = this.chartOptions
                let o2 = this.extraOptions
                let objMerge = {}
                Object.keys(o2).forEach(key => {
                    if (o2[key] instanceof Object) {
                        objMerge[key] = Object.assign({}, o1[key], o2[key]);
                    } else {
                        objMerge[key] = o2[key];
                    }
                })
                Object.assign(this.chartOptions, objMerge)
            },

            startModifyMeasure() {
                this.$axios.get(`studentMonitors.php?action=get&student_monitor_plan_id=${this.monitor.student_monitor_plan_id}`)
                    .then(r => {
                        let monitors = this.$ecResponse(r, 'monitors')
                        if (monitors.length) {
                            let m = monitors[0]
                            this.measureDialog.show = true
                            this.measureDialog.record = [{
                                schedule_id: m.schedule_id,
                                start_date: m.schedule_start_date,
                                end_date: m.schedule_end_date,
                                start_time: m.schedule_start_time,
                                end_time: m.schedule_end_time,
                                monitor_frequency_cnt: m.schedule_frequency_cnt,
                                monitor_frequency_cd: m.schedule_frequency_cd,
                                monitor_week_days: m.schedule_week_days,
                                monitor_user_id: m.monitor_user_id,
                                sub_category_id: {
                                    id: m.sub_category_id
                                },
                                primary_flag: m.primary_flag,
                                intervention_id: m.intervention_id,
                                students: [{
                                    student_id: m.student_id,
                                    student_full_name: m.student_full_name,
                                    value: m.goal,
                                    assessment_grade_id: m.assessment_grade_id
                                }]
                            }]
                        }
                    })
            },

            updateMeasure(confirm) {
                let me = this
                let m = this.measureDialog.record[0]
                let rec = {
                    student_monitor_plan_id: this.monitor.student_monitor_plan_id,
                    intervention_id: m.intervention_id,
                    primary_flag: m.primary_flag,
                    assessment_grade_id: m.assessment_grade_id ? m.assessment_grade_id : m.students[0].grade_level ? m.students[0].grade_level : m.students[0].assessment_grade_id,
                    student_id: m.students[0].student_id,
                    schedule_id: m.schedule_id,
                    schedule_week_days: m.monitor_week_days,
                    schedule_frequency_cd: m.monitor_frequency_cd,
                    schedule_frequency_cnt: m.monitor_frequency_cnt,
                    schedule_secondary_cnt: '',
                    schedule_start_date: m.start_date,
                    schedule_end_date: m.end_date,
                    schedule_start_time: m.start_time,
                    schedule_end_time: m.end_time,
                    sub_category_id: m.sub_category_id.id,
                    goal: m.students[0].value ? parseFloat(m.students[0].value) : null,
                    monitor_user_id: m.monitor_user_id
                }

                this.$axios.post('studentMonitors.php?action=update'+(confirm?'&confirm=1':''), { monitors: [rec] })
                    .then(r => {

                        if (r.data && r.data.confirm) {
                            me.$messageBox({
                                title: 'Confirm',
                                persistent: true,
                                maxWidth: 600,
                                message: r.data.msg,
                                actions: [{
                                    text: 'Cancel', primary: false,
                                    onClick: () => {
                                        me.measureDialog.show=false
                                    }
                                }, {
                                    text: 'Update', primary: true,
                                    onClick: () => {
                                        me.updateMeasure(true)
                                    }
                                }]
                            })
                        } else {
                            if (this.$ecResponse(r) === false) {
                                return
                            }
                            this.measureDialog.show=false
                            this.measureDialog.record = null
                        }
                    })
            },
        },

        watch: {
            config(v) {
                if (v) {
                    this.chartOptions = v
                    this.collectionChartData = {
                        dash_id: v.dash_id,
                        saved_search_id: v.saved_search_id,
                        index: v.index
                    }
                    if (v.chart) this.showLoader = false
                    if (this.chartOptions.title) {
                        this.chartOptions.title.text = '' // hide title that comes with chart; we're displaying it separately so it's editable without reload
                    }
                    this.addChartClick()
                } else {
                    this.showLoader = true
                }
            },
            showModifyDialog(v) {
                if (v) {
                    if(this.collectionChartData.saved_search_id) {
                        this.$axios.get('savedSearch.php?action=get&property=arguments&saved_search_id=' + this.collectionChartData.saved_search_id)
                            .then(response => {
                                if(response.data.saved_search_arguments.length > 0) {
                                    this.searchArguments = response.data.saved_search_arguments
                                    response.data.saved_search_arguments.forEach(data => {
                                        if(data.param_code === 'SCHOOL_YEAR_ID' || data.param_code ===  'SCHOOL_ID' || data.param_code ===  'GRADE_ID'
                                            || data.param_code === 'COHORT_SCHOOL_YEAR_ID') {
                                            this.selected[data.param_code].push({
                                                id: parseInt(data.argument_value)
                                            })
                                            this.fields.push(data.param_code)
                                        }  else if(data.param_code === 'GENDER') {
                                            this.selected['GENDER'].push({
                                                gender: data.argument_value
                                            })
                                            this.fields.push(data.param_code)

                                        }
                                    })
                                }
                            })
                            .catch(error => {
                                console.error(error)
                            })
                    }
                }
            }
        },

        created() {
            if (this.config) {
                this.chartOptions = this.config
                this.addChartClick()
            }
            this.showLoader = true
        },

        mounted() {
            this.showChart = true
            if (this.config) {
                this.collectionChartData = {
                    dash_id: this.config.dash_id,
                    saved_search_id: this.config.saved_search_id,
                    index: this.config.index
                }
                if (this.config.chart) this.showLoader = false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .ec-chart {
        ::v-deep * {
            font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
        }
        ::v-deep .highcharts-title {
            width: 100% !important;
            left: 0 !important;
            padding: 5px 0 37px 16px !important;
            font-size: 14px !important;
        }
        ::v-deep .highcharts-container {
            width: 100% !important;
        }
        &-menu {
            &-btn {
                z-index: 1;
                float: right;
                color: #7E8494 !important;
            }
        }
        &-no-data-title {
            white-space: normal;
            margin-left: 0px;
            margin-top: 0px;
            color: rgb(126, 132, 148);
            fill: rgb(51, 51, 51);
            border-bottom: 1px solid rgb(224, 225, 230);
            display: block;
            width: 100% !important;
            left: 0 !important;
            padding: 9px 0 16px 16px !important;
            font-size: 14px !important;
            &-info {
                display: flex;
                justify-content: center;
                padding-top: 10px;
            }
        }
        &-title {
            color: rgb(126, 132, 148);
            display: block;
            margin-top: 14px !important;
            left: 15px !important;
            font-size: 14px !important;
        }
    }
    .chart-container {
        background: #fff;
    }
</style>
