<template>
    <div
        ref="wrapper"
        class="flex-fill flex-wrap justify-center"
        style="overflow-y:scroll;"
        v-intersect="onIntersect"
    >
        <v-overlay :value="loading" absolute>
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </v-overlay>

        <div v-show="showGrid" class="flex-fill no-scroll">
            <fe-grid
                v-if="showGrid"
                :rowData="fullRowData"
                :columnDefs="columnDefs.allResponses"
                :defaultColDef="defaultColDef"
                :showAddRowBtn="false"
                displayDensity="small"
                style="width:100%;"
            ></fe-grid>
        </div>

        <div v-show="!showGrid" class="flex-fill flex-wrap justify-center">
            <template v-for="(chart, i) in charts">
                <v-col :cols="cols(chart)" class="flex-grow-1" :key="chart.ec.key">
                    <v-toolbar v-show="chart.ec.showToolbar" color="white" light flat>
                        <v-toolbar-title>{{chart.ec.title}}<div class="subtitle"><br>{{chart.ec.subtitle}}</div></v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon v-if="chart.ec.showGrid" @click="chart.ec.showGrid = false">
                            <v-icon>bar_chart</v-icon>
                        </v-btn>
                        <v-btn icon v-if="!chart.ec.showGrid" @click="chart.ec.showGrid = true">
                            <v-icon>border_all</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <template v-if="i<15">
                        <highcharts
                            ref="charts"
                            v-if="!chart.ec.showGrid"
                            :options="chart"
                        ></highcharts>

                        <fe-grid
                            ref="grids"
                            v-if="chart.ec.showGrid"
                            style="height:300px;"
                            displayDensity="small"
                            :showToolbar="false"
                            :rowData="chart.ec.rowData"
                            :columnDefs="chart.ec.columnDefs"
                            :defaultColDef="defaultColDef"
                        ></fe-grid>
                    </template>

                    <!-- v-lazy options... rootMargin does not seem to work as of this version anyway -->
                    <v-lazy v-else min-height="300px">
                        <highcharts
                            ref="charts"
                            v-if="!chart.ec.showGrid"
                            :options="chart"
                        ></highcharts>

                        <fe-grid
                            ref="grids"
                            v-if="chart.ec.showGrid"
                            style="height:300px;"
                            displayDensity="small"
                            :showToolbar="false"
                            :rowData="chart.ec.rowData"
                            :columnDefs="chart.ec.columnDefs"
                            :defaultColDef="defaultColDef"
                        ></fe-grid>
                    </v-lazy>

                </v-col>
            </template>
        </div>

    </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill'
import windowOptionsMixin from '@/mixins/windowOptions'

export default {
    name: 'FormChart',
    mixins: [ windowOptionsMixin ],

    props: {
        templateId: {required: true},
        showGrid: {default: false},
        filters: {},
    },

    mounted() {
        let reflow = this.$_.debounce(() => {
            this.$nextTick(this.$reflow)
            // this.$refs.grids && this.$refs.grids.forEach(grid => {
            //     grid.resize()
            // })
        },400,{leading:false})

        new ResizeObserver((entries, observer) => {
            this.wrapperWidth = entries[0].contentRect.width
            reflow()
        }).observe(this.$refs.wrapper)
    },

    watch: {
        isVisible: {
            immediate: true,
            handler(v) {
                if(v) this.loadData()
            }
        },
        filters: {
            deep: true,
            handler(v) {
                // $userId = Util::getParams('user_id', 'int');
                // $schoolYearId = Util::getParam('school_year_id', 'int');
                // $startDate = Util::getParam('start_date', 'string');
                // $endDate = Util::getParam('end_date', 'string');
                // $modified = Util::getParam('modified', 'int');
                // Util::getParams('form_id', 'int') ?: Util::getParams('form_types', 'int')
                // Util::getParams('form_field_id', 'int') ?: Util::safePost($post, 'form_field_id')
                // Util::getParams('form_instance_id', 'int') ?: Util::safePost($post, 'form_instance_id')

                // need $dataId   = Util::getParams('data_id', 'string');

                this.loadData()
            }
        },
    },
    methods: {
        onIntersect(e) {
            this.isVisible = e.some(x => x.isIntersecting)
        },
        cols(chart) {
            if(chart.xAxis.categories.length>8) return this.breakPoint([12,12,12,6])
            if(chart.xAxis.categories.length>3) return this.breakPoint([12,12,6,4])
            return this.breakPoint([12,6,4,3])
        },
        breakPoint(s) {
            if(this.wrapperWidth<600) return s[0]
            if(this.wrapperWidth<960) return s[1]
            if(this.wrapperWidth<1264) return s[2]
            return s[3]
        },
        loadData() {
            let params = {
                ...this.filters,
                form_id: this.templateId,
                user_id: this.$pluckJoin(this.filters.user_id?.included,'id'),
                data_id: this.$pluckJoin(this.filters.data_id?.included,'id'),
                school_year_id: Array.isArray(this.filters.school_year_id) ? this.filters.school_year_id.join(',') : this.filters.school_year_id,

            }

            if(!this.isVisible || this.lastParams == JSON.stringify(params)) return
            this.fullRowData = []
            this.lastParams = JSON.stringify(params)
            this.loading = true
            this.time1 = performance.now()

            this.$axios({
                url: 'form.php?action=get&property=report_data',
                params: params,
            }).then(r => {
                this.time2 = performance.now()

                this.loadCount++
                let keys = Object.keys(r.data.report_data)

                this.charts = keys.map((key) => {
                    this.fullRowData.push(...r.data.report_data[key].data)
                    return this.buildChartConfig(r.data.report_data[key],`${key}-${this.loadCount}`)
                    // return Object.freeze(this.buildChartConfig(r.data.report_data[key],`${key}-${this.loadCount}`))
                })

                this.loading = false
            }).catch(r => {
                this.charts = []
            })
        },
        buildChartConfig(rec,key) {
            let categories = []

            rec.data.forEach(response => {
                categories[response.display_text] = categories[response.display_text]
                    ? ++categories[response.display_text]
                    : 1
            })

            return {
                credits: { enabled: false },
                exporting: {
                    buttons: {contextButton: {enabled:false}}
                },
                chart: {
                    type: 'column',
                    height: '300px',
                    animation: false,
                },
                title: { text: null },
                xAxis: { categories: Object.keys(categories) },
                yAxis: { title: {text: 'Count'} },
                plotOptions: {
                    series: {animation: false},
                },
                series: [{
                    showInLegend: false,
                    data: Object.values(categories)
                }],
                tooltip: {
                    headerFormat: '',
                    pointFormat: '<b>{point.y}</b>',
                    shared: true
                },
                ec: {
                    key: key,
                    title: rec.title,
                    subtitle: rec.subtitle,
                    showToolbar: true,
                    showGrid: false,
                    columnDefs: this.columnDefs.response,
                    rowData: rec.data,
                }
            }

        }

    },

    data() {
        return {
            isVisible: false,
            loadCount: 0,
            loading: false,
            wrapperWidth: 0,
            charts: {},

            fullRowData: [],
            defaultColDef: {
                sortable: true,
                resizable: true,
                filter: true,
                hide: false,
                cellClass: 'x-grid-column'
            },
            columnDefs: {
                allResponses: [
                    // {headerName: 'Field Name', field: 'form_field_name', rowGroup: true},
                    {headerName: 'Field Name', field: 'form_field_name'},
                    {headerName: 'Field Type', field: 'form_field_type_name'},
                    {headerName: 'Response', field: 'display_text', cellClass: 'response-cell'},
                    {headerName: 'Responder', field: 'form_instance_response_created_by_full_name'},
                    {headerName: 'Date', field: 'form_instance_response_created'},
                ],
                response: [
                    // {headerName: 'Response', field: 'form_field_response_value', cellClass: 'response-cell'},
                    {headerName: 'Response', field: 'display_text', cellClass: 'response-cell'},
                    {headerName: 'Responder', field: 'form_instance_response_created_by_full_name'},
                    {headerName: 'Date', field: 'form_instance_response_created'}
                ],
                count: [
                    {headerName: 'Name', field: 'form_name'},
                    {headerName: 'Count', field: 'form_count'}
                ]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .subtitle {
        line-height: 70%;
        font-size: 50%;
        margin-top: 0;
        padding: 0;
        position: relative;
        bottom: 10px;
    }
    .response-cell {
        word-wrap: break-word;
    }
</style>
