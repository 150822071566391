<template>
    <v-layout column style="overflow: hidden;">
        <fe-crud
            :config="$models.incidentLocationAlias"
            :autoload="false"
            ref="aliasCrud"
            @read="v => aliasItems = v"
        />

        <create-location @save="loadData" v-model="locationDialog.show"/>

        <fe-dialog
            width="500"
            title="Merge With Location"
            v-if="mergeDialog.show"
            @dismiss="mergeDialog.show=false"
            @accept="merge"
            :acceptButtonDisabled="!mergeDialog.record"
            acceptButtonText="Merge"
            persistent
            dismissButtonText="Cancel"
        >
            <v-form ref="mergeForm">
                <fe-remote-combo
                    :items="mergeItems"
                    v-model="mergeDialog.record"
                    rootProperty='incident_locations'
                    flat solo dense
                    itemText='name'
                    itemValue='id'
                    required
                    placeholder="Select a location..."
                />
            </v-form>
        </fe-dialog>


        <div class="page-title">Locations</div>
        <v-layout column style="overflow: hidden;">
            <fe-crud-grid
                :config="$models.incidentLocation"
                autoload
                @read="v => items = v"
                ref="grid"
                style="height: 100%;"
                @selectionChanged="doSelect"
                :columnDefs="columnDefs"
                :rowData="items"
                :showFullscreen="false"
                :showAddRowBtn="false"
                displayDensity="medium"
            >
                <template v-slot:toolbar-items>
                    <fe-btn usage="primary" @click="locationDialog.show = true">Add Location</fe-btn>
                </template>

                <template v-slot:selection-tools>
                    <fe-btn useIcon="fas fa-code-merge" usage="ghost" dark @click="showMergeDialog">Merge</fe-btn>
                    <fe-btn useIcon="fal fa-trash" usage="ghost" dark @click="deleteSelected">Delete</fe-btn>
                    <fe-btn usage="ghost" dark @click="gridApi.deselectAll()">Cancel</fe-btn>
                </template>

                <template v-slot:cellDialog="{cell}">
                    <template v-if="cell.colDef.field === 'aliases'">
                        <div class="d-flex justify-space-between align-center pt-3 pr-2">
                            <v-flex grow class="grid-panel-title">Aliases</v-flex>
                            <v-flex shrink text-xs-right>
                                <v-btn icon small usage="ghost" @click="$refs.grid.$refs.grid.openMenu = false"><v-icon>close</v-icon></v-btn>
                            </v-flex>
                        </div>

                        <FeGrid
                            style="width: 600px; height: 400px; padding: 10px 20px 20px 20px;"
                            ref="aliasGrid"
                            :showToolbar="false"
                            :columnDefs="aliasColumnDefs"
                            :rowData="aliasItems"
                            :bordered="false"
                        ></FeGrid>

                        <v-layout row class="mx-5">
                            <v-text-field
                                v-model="aliasText"
                                flat solo desnse
                                :counter="255"
                                style="margin-top: 6px; margin-right: 18px;"
                                placeholder="Enter an alias"
                            />

                            <fe-btn
                                :disabled="!aliasText || aliasText.length > 255"
                                @click="addAlias"
                            >Add</fe-btn>
                        </v-layout>
                    </template>
                </template>
            </fe-crud-grid>
        </v-layout>
    </v-layout>
</template>

<script>
    import CreateLocation from './dialogs/CreateLocation'
    import { mapState } from 'vuex'

    export default {
        name: 'IncidentLocation',
        inject: ['localStore'],
        components: {
            CreateLocation
        },
        computed: {
            crudApi() {
                return this.$refs.grid.$refs.crud
            },
            mergeItems() {
                let ids = this.$_.map(this.selections, 'id')
                return this.items.filter(r => ids.indexOf(r.id) === -1)
            },
            columnDefs() {
                let me = this
                return [
                    this.$grid.checkColumn(),
                    {
                        headerName: "Location",
                        field: "name",
                        sortable: true,
                        editable: true,
                    }, {
                        headerName: "Schools",
                        field: 'school_ids',
                        sortable: true,
                        editable: true,
                        maxWidth: 300,
                        cellRenderer(v) {
                            let count = Array.isArray(v.value) ? v.value.length : 0
                            return count > 0 ? count + ' School'+(count>1?'s':''): ''
                        },
                        "cellEditorFramework": "FeGridChipSelect",
                        "cellEditorParams": {
                            "rowDataKey": "school_ids",
                            "mode": "edit",
                            "multiple": true,
                            "items": me.schools,
                            "keyProp": "id",
                            "labelProp": "name"
                        }
                    }, {
                        headerName: 'Aliases',
                        field: 'aliases',
                        maxWidth: 110,
                        valueGetter: (params) => params.data.aliases ? params.data.aliases.length : '',
                        cellRendererFramework: "FeDialogColumn",
                        cellRendererParams: {
                            rowDataKey: "aliases",
                            parent: this,
                        },
                        onCellClicked: function(v) {
                            me.$refs.grid.$refs.grid.setDialogCell(v)
                            me.selectedRow = v.data
                            me.loadAliases()
                        }
                    }, me.$grid.toggleColumn('Active', 'active', '', (v) => {
                        me.saveRecord(v)
                    })
                ]
            },
        },
        data() {
            let me = this
            return {
                items: [],
                schools: [{id: -1, name: 'just a test'}],
                aliasItems: [],
                aliasText: '',
                locationDialog: {
                    show: false
                },
                mergeDialog: {
                    show: false,
                    record: null
                },
                gridApi: null,
                selections: [],
                aliasColumnDefs: [{
                    headerName: 'Alias',
                    field: 'alias',
                    editable: true,
                    flex: 2,
                    onCellValueChanged: v => this.updateAlias(v.data),
                    // cellStyle() {
                    //     return {
                    //         'text-align': 'right'
                    //     }
                    // }
                }, {
                    headerName: 'Upload #',
                    field: 'upload_id',
                    flex: 1,
                    editable: false
                }, {
                    headerName: 'Created',
                    field: 'created',
                    editable: false
                }, {
                    headerName: 'Delete',
                    field: 'delete_option',
                    flex: 1,
                    cellRenderer(v) {
                        return '<i class="fas fa-trash" style="margin-left: 15px; font-size: 12px"></i>'
                    },
                    cellStyle() {
                        return {
                            'cursor': 'pointer',
                            'text-align': 'left'
                        }
                    },
                    onCellClicked: function(v) {
                        me.deleteAlias(v.data)
                        // me.cellClicked(v)
                    }
                }],
            }
        },
        mounted() {
            let me = this

            this.$axios.get(this.$models.getUrl('school', 'read'))
            .then(response => {
                me.$data.schools = response.data.schools
                me.loadData()
            })
        },
        methods: {
            showMergeDialog() {
                this.mergeDialog.show = true
                this.mergeDialog.record = null
            },
            doSelect(item) {
                this.$data.gridApi = item.api
                this.selections = item.api.getSelectedRows()
            },
            deleteSelected() {

                this.$confirmDelete(this.selections, () => {
                    this.$refs.grid.$refs.crud.destroy(this.selections).then(() => {
                        this.$refs.grid.$refs.crud.read()
                    })
                })
            },
            merge() {
                let sel = this.selections

                if (!this.mergeDialog.record) {
                    return
                }

                this.$confirmCreate(sel, () => {
                    sel.forEach(item => {
                        item.merge_to_id = this.mergeDialog.record.id
                    })

                    this.$refs.grid.$refs.crud.update(sel)
                        .then(response => {
                            this.$refs.grid.$refs.crud.read()
                        }).finally(() => {
                            this.mergeDialog = {
                                show: false,
                                record: null
                            }
                        })
                }, 'Merge')
            },
            /* Aliases Info */
            loadAliases() {
                if (this.selectedRow) {
                    this.$refs.aliasCrud.read({
                        incident_location_id: this.selectedRow.id
                    })
                }
            },
            addAlias() {
                if (this.aliasText) {
                    this.$refs.aliasCrud.create({
                        incident_location_id: this.selectedRow.id,
                        alias: this.aliasText
                    })
                    .then(() => {
                        this.loadAliases()
                        this.aliasText = ''
                    })
                    .finally(() => {
                        this.loadData()
                    })
                }
            },
            updateAlias(data) {
                this.$refs.aliasCrud.update(data)
                    .then(() => {})
                    .catch(err => { console.error(err) })
            },
            deleteAlias(alias) {
                if (alias) {
                    this.$confirmDelete(alias.alias, () => {
                        this.$refs.aliasCrud.destroy(alias)
                            .then(() => {
                                this.loadAliases()
                                this.aliasText = ''
                            })
                            .finally(() => {
                                this.crudApi.read()
                            })
                    })
                }
            },
            loadData() {
                this.crudApi.read()
                .then(response => {})
            },
            handleError(mixed) {},
            saveRecord(item) {
                let me = this
                this.crudApi.update(item.data)
                .then(response => {
                    me.loadData()
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
