<template>
    <collapsible-panel title="Action Items" smallTitle>

        <p v-if="tasks.length == 0">No action items created on this day.</p>

        <div
            v-for="task in tasksWithImages"
            :key="`occurrence-task-${task.id}`"
            @click="edit = task"
            class="occurrence-task d-flex justify-space-between">
            <div class='task-text align-self-center flex-grow-1'>{{ task.name }}</div>
            <v-avatar size="26" class='title-avatar flex-shrink-1 text-right align-self-center'>
                <img v-if="task.created_by_photo_url" :src="task.created_by_photo_url" :alt="task.created_by_full_name">
                <v-icon :title="task.created_by_full_name" v-else>fas fa-user-circle</v-icon>
            </v-avatar>
            <div class='task-menu flex-shrink-1 text-right align-self-center'>
                <fe-icon-btn
                    color="#979797"
                    size="16"
                    @click="edit = task"
                    useIcon="fas fa-external-link-alt"
                />
            </div>
        </div>

        <edit-action-item
            v-if="editing"
            :editing="edit"
            :extraParams="crudParams"
            standalone
            @save="edit = null"
            @dismiss="edit = null"
        />

        <fe-crud
            ref="crud"
            autoload
            v-if="meeting && occurrence"
            refresh-on-config
            :config="$models.task"
            :readParams="crudParams"
            @read="tasks = $event"
        />
    </collapsible-panel>
</template>

<script>
    import CollapsiblePanel from '@/components/common/CollapsiblePanel'
    import EditActionItem from '@/components/modules/actionItems/EditActionItem'

    export default {
        name: 'ActionItems',
        components: { CollapsiblePanel, EditActionItem },
        props: {
            meeting: { type: Object, required: true },
            occurrence: { type: Object }
        },
        data () {
            return {
                tasks: [],
                edit: null
            }
        },
        computed: {
            editing: {
                get () { return !!this.edit },
                set (v) { this.edit = v ? v : null }
            },
            stamp () { return this.$dayjs(this.occurrence.occurrence, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss") },
            crudParams () {
                return {
                    dashboard_meeting_id: this.meeting.id,
                    created: this.stamp
                }
            },
            tasksWithImages () {
                return this.tasks.map(task => {
                    let match = this.meeting.dashboard_meeting_users.find(itm => itm.user_id == task.created_by)
                    return Object.assign({}, task, {
                        created_by_photo_url: (match && match.photo_url) || null
                    })
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

    .occurrence-task {
        width: 100%;
        border: 1px solid #DDE1E5;
        border-top: none;
        &:first-child { border-top: 1px solid #DDE1E5; }
        cursor: pointer;
        .task-text {
            font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: #050F2D;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100vw - 550px);
            padding-right: 20px;
            padding-left: 10px;
            .is-collapsed & {
                width: calc(100vw - 250px);
            }
        }
    }

    .field-action-item {
        position: absolute;
        z-index: 1000;
        background-color: white;
        border-radius: 5px;
        padding: 2px;
        float: left;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: 1px solid #e6e6e6;
        max-height: 300px;
    }

    .close-btn {
        position: absolute;
        right: 20px;
        top: 5px;
    }

</style>
