<template>
    <div class="selection-list-container" style="overflow: hidden;">
        <div class="selection-row d-flex" style="cursor: auto;">
            <div class="selection-checkbox" v-if="multiple" @click="toggleAll">
                <v-icon style="font-size: 16px;">{{all ? 'fas fa-check-square' : 'far fa-square'}}</v-icon>
            </div>

            <div :class="{'ml-10': !multiple}" class="selection-body"><b>{{headerText}}</b></div>
        </div>

        <div class="d-flex flex-column flex-fill" :style="{height: this.height?this.height:undefined}">
            <div 
                v-for="(item,i) in localItems" 
                class="selection-row d-flex" v-ripple 
                :class="{'selection-row-selected': item.xSelect, 'selection-row-last': (i+1)===items.length}" 
                @click="doToggle(item)"
            >
                <div class="selection-checkbox">
                    <v-icon style="font-size: 16px;">{{item.xSelect ? 'fas fa-check-square' : 'far fa-square'}}</v-icon>
                </div>

                <div class="selection-body">
                    <slot name="row" :item="item"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SelectionList',
        data() {
            return {
                localItems: [],
                all: false
            }
        },
        watch: {
            items: {
                handler(v) {
                    // checking for the xSelect parameter
                    // if it exists, then we need to check a box on load (default is all boxes unchecked)
                    // Example: Link To Intervention (InterventionCard.vue)
                    let exists = null
                    v.every(i => {
                        return exists = typeof i.xSelect === 'undefined'
                    })
                    exists ? this.localItems = v.map(i => ({...i, xSelect: false})) : this.localItems = v
                },
                immediate: true
            },
            value(v) {
                this.localItems.forEach(l => {
                    v.forEach(o => {
                        if (l[this.identifier]===o[this.identifier]) l.xSelect=true
                    })
                })
            }
        },
        props: {
            items: {
                type: Array,
                default: () => []
            },
            headerText: {
                type: String,
                default: 'Select'
            },
            multiple: {
                type: Boolean,
                default: false
            },
            value: {
                type: Array
            },
            identifier: {
                type: String,
                default: 'name'
            },
            height: {
                type: String,
                default: undefined
            }
        },
        methods: {
            toggleAll() {
                if (this.all) {
                    this.localItems.forEach(r=>r.xSelect=false)
                } else {
                    this.localItems.forEach(r=>r.xSelect=true)
                }
                this.all=!this.all

                this.$emit('input', this.localItems.filter(r=>r.xSelect))
            },
            doToggle(item) {
                if (!this.multiple) this.localItems.forEach(r => r.xSelect=false)
                item.xSelect = !item.xSelect
                
                if (item.xSelect) {
                    this.$emit('select', item, this.localItems.filter(r=>r.xSelect))
                } else {
                    this.$emit('deselect', item, this.localItems.filter(r=>!r.xSelect))
                }

                this.$emit('input', this.localItems.filter(r=>r.xSelect))
            }
        }
    }
</script>

<style lang="scss" scoped>
.selection-list-container {
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    min-height: 50px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        'header'
        'main';

    .selection-row {
        border-bottom: 1px solid #DDDDDD;
        // min-height: 35px;
        cursor: pointer;

        &-first {
            grid-area: header;
        }

        &-last {
            border-bottom: none;
        }

        &-selected {
            background: var(--fe-accent);
        }

        &:hover {
            background: #f7f7f7;
        }
    }

    .flex-column {
        overflow: auto;
        grid-area: main;
    }

    .selection-checkbox {
        padding: 6px 12px 4px 12px;
        border-right: 1px solid #DDDDDD;

        ::v-deep .v-icon {
            color: var(--v-primary-base);
        }
    }

    .selection-body {
        padding: 6px 8px 4px 8px;
    }
}
</style>
