<template>
    <div>
        <div class="ec-section-title">{{ formFieldTypes[localValue.form_field_type_id].name }}</div>
        <div class="ec-caption">
            Configure the field with the options below. To reorder, change field type,
            add dependency, add an action item, or delete use the 6-dot menu.
        </div>
        <v-form v-model="formValid" @submit.prevent>
            <v-tabs v-model="activeTab" class="form-common-config">
                <v-tab>Basic</v-tab>
                <v-tab>Advanced</v-tab>
                <v-tab v-if="showStyling">Styling</v-tab>
            </v-tabs>

            <v-carousel
                class="mt-2" light
                v-model="activeTab"
                height="100%"
                hide-delimiters
                :show-arrows="false"
            >
                <v-carousel-item>
                    <div class="ec-section-subtitle">General</div>
                    <div class="d-flex">
                        <fe-label :style="labelStyle">Field Name</fe-label>
                        <v-text-field flat solo dense
                            :value="localValue.name"
                            @change="localValue.name = $event"
                            :rules="$fieldValidators('text', null, {required:true, limit:1000})"
                        />
                    </div>

                    <div v-if="showLabelFields" class="d-flex">
                        <fe-label :style="labelStyle">Show Label</fe-label>
                        <fe-switch style="margin-left:auto;" :value="!localValue.hide_name" @input="localValue.hide_name=!$event"/>
                    </div>

                    <div v-if="showLabelFields" class="d-flex">
                        <fe-label :style="labelStyle">Align Label</fe-label>
                        <fe-remote-combo
                            byId
                            style="margin-left:auto;"
                            v-model="localValue.label_align"
                            itemValue="value"
                            :items="[
                                {value:'left', name:'To the Left'},
                                {value:'right', name:'To the Right'},
                                {value:'top', name:'On Top'},
                            ]"
                            :clearable="false"
                        ></fe-remote-combo>
                    </div>

                    <div v-if="showColumnWidth" class="d-flex">
                        <fe-label :style="labelStyle">Column Width</fe-label>
                        <fe-remote-combo
                            byId
                            style="margin-left:auto;"
                            v-model="localValue.column_width"
                            itemValue="value"
                            :items="[
                                {value:.25, name:'25%'},
                                {value:.33, name:'33%'},
                                {value:.50, name:'50%'},
                                {value:.66, name:'66%'},
                                {value:.75, name:'75%'},
                                {value:1, name:'100%'},
                                {value:0.00, name:'Fit'},
                            ]"
                            :clearable="false"
                        ></fe-remote-combo>
                    </div>

                    <div class="ec-section-subtitle">Field Specific</div>
                    <slot name="fieldSpecific" :rec="localValue"></slot>

                </v-carousel-item>

                <v-carousel-item>
                    <div class="d-flex">
                        <fe-label :style="labelStyle">Element Height</fe-label>
                        <v-text-field
                            :value="parseInt(localValue.height)"
                            @change="localValue.height = $event"
                            flat solo dense
                            v-mask="'#####'"
                            clearable
                        />
                    </div>
                    <div class="d-flex">
                        <fe-label :style="labelStyle">Hover Text</fe-label>
                        <v-text-field flat solo dense
                            :value="localValue.tooltip"
                            @change="localValue.tooltip = $event"
                            :rules="$fieldValidators('text', null, {limit:500})"
                        />
                    </div>
                    <div v-if="showDataId" class="d-flex">
                        <fe-label :style="labelStyle">
                            Data ID
                            <v-flex v-if="new Date(template.created) < originalIdStartDate" style="display: inline-block; margin-right: 10px">
                                <fe-info-tip :tooltip="`Form templates created on or after ${this.$dayjs(this.originalIdStartDate).format('YYYY-MM-DD')}
                                    will be prompted whether to update Data ID for all forms. If you need to update this Data ID for all forms
                                    you will need to use 'Form Revision History from the 3 dot menu'`"
                                />
                            </v-flex>
                        </fe-label>
                        <v-text-field flat solo dense ref="formDataId"
                            :value="localValue.data_id"
                            @change="onChangeDataId"
                            :rules="dataIdRules"
                        />
                    </div>

                    <div v-if="!template.live" class="d-flex">
                        <fe-label :style="labelStyle">Field Type</fe-label>
                        <fe-remote-combo
                            byId
                            style="margin-left:auto;"
                            v-model="localValue.form_field_type_id"
                            :items="formFieldTypesSorted((a,b) => a.group > b.group ? -1 : 1)"
                            :clearable="false"
                            :rules="$fieldValidators('select', null, { required: true })"
                        />
                    </div>

                    <div class="d-flex" v-if="showCarryOver">
                        <fe-label :style="labelStyle">Carry Over</fe-label>
                        <fe-switch
                            style="margin-left:auto;" :value="localValue.carry_over"
                            @input="localValue.carry_over = $event === true ? 1 : 0"
                        />
                    </div>

                    <div class="ec-section-subtitle">Rules</div>
                    <div class="grid">
                        <!-- <div class="hc" >Controller</div>
                        <div class="hc" >Dependency</div>
                        <div class="hc" >Edit</div> -->

                        <template v-for="rule in myRules">
                            <div @click="$emit('scrollElement',{
                                fieldId: rule.controlling_form_field_id,
                            })" >controller</div>

                            <div @click="$emit('scrollElement',{
                                fieldId: rule.form_field_id,
                                sectionId: rule.form_section_id,
                            })" >dependency</div>

                            <div @click="$emit('editDependency',{rule})" >edit</div>

                        </template>
                    </div>
                </v-carousel-item>

                <v-carousel-item>
                    <div class="d-flex">
                        <fe-label :style="labelStyle">Bold</fe-label>
                        <fe-switch style="margin-left:auto;" v-model="localValue.bold" />
                    </div>
                    <div class="d-flex">
                        <fe-label :style="labelStyle">Italic</fe-label>
                        <fe-switch style="margin-left:auto;" v-model="localValue.italic" />
                    </div>
                    <div class="d-flex">
                        <fe-label :style="labelStyle">Underline</fe-label>
                        <fe-switch style="margin-left:auto;" v-model="localValue.underline" />
                    </div>
                    <div class="d-flex">
                        <fe-label :style="labelStyle">Font Size</fe-label>
                        <v-text-field
                            placeholder="default"
                            :value="localValue.font_size"
                            @change="localValue.font_size = $event"
                            flat solo dense
                            v-mask="'##'"
                            clearable
                        />
                    </div>

                </v-carousel-item>
            </v-carousel>
        </v-form>
    </div>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'
import smartFormMixin from '../../smartFormMixin'

export default {
    name: 'FormCommonConfig',
    inject: ['localStore'],
    mixins: [ smartFormMixin ],

    props: {
        value: {type: Object, default: null},
        section: {type: Object, default: ()=>({})},
        template: {type: Object, default: ()=>({})},
        duplicateFormDataIds: {type: Array,default: () => []},
        goToAdvanced: {type: Boolean, default: false},
    },
    computed: {
        ...mapLocalState([ 'dependencies' ]),
        labelStyle: me => ({ minWidth:'120px' }),
        showLabelFields: me => !me.noLabels,
        showColumnWidth: me => me.section.section_layout_type_id != 1,
        showDataId: me => me.localValue.data_id || !['fieldspacer', 'pdfpagebreak', 'weblink'].includes(me.value.xtype),
        showStyling: me => !['fieldspacer', 'pdfpagebreak'].includes(me.value.xtype),
        xtype() {
            if(!this.value.xtype) this.value.xtype = this.formFieldTypes[this.value.form_field_type_id].xtype
            return this.value.xtype
        },
        myRules() {
            return this.dependencies.filter(x => {
                return this.localValue.id == x.controlling_form_field_id
                    || this.localValue.id == x.form_field_id
            })
        },
        myRulesGridStyle() {
            return {
                display: 'grid',
                border: '1px solid #efefef',
                borderRadius: '4px',
                gridTemplateColumns: '60px 60px 60px',
            }
        },
        myRulesHeaderCellStyle() {

        },
        dataIdRules() {
            return [v => !this.defaultDataIds.includes(v) || 'This is a system default data ID.',
                v => !this.duplicateFormDataIds.includes(v) || 'A field exists with the same data ID.']
                .concat(this.$fieldValidators('text', null, {limit:128}))
        },
        showCarryOver() {
            let fields = ['checkboxfield', 'textfield', 'displayfield', 'numberfield', 'textarea', 'datefield', 'combobox']
            return fields.includes(this.value.xtype)
        }
    },
    methods: {
        onChangeDataId(e) {
            if(this.dataIdMsg) return

            // don't want to save value if it's a default column id
            if(this.defaultDataIds.includes(e)) return

            if(new Date(this.template.created) < this.originalIdStartDate) {
                this.localValue.data_id = e

            } else {
                this.dataIdMsg = true
                this.$messageBox({
                    title: 'Update Form Revisions',
                    persistent: true,
                    maxWidth: '500px',
                    message: `Data ID can be applied to all revisions of a smartFORM template created on or after ${this.$dayjs(this.originalIdStartDate).format('YYYY-MM-DD')}. How do you want this Data ID change to be applied?`,
                    actions: [{
                        text: 'All Forms',
                        usage: 'ghost',
                        onClick: () => {
                            this.localValue.data_id = e
                            this.localValue.update_revisions = 1
                            this.dataIdMsg = false
                        }
                    }, {
                        text: 'New Forms Only',
                        onClick: () => {
                            this.localValue.data_id = e
                            this.dataIdMsg = false
                        },
                    }]
                })
            }

            this.$emit('dataIdChange', e)
        },
        onBlur() {
            setTimeout(function() {
                // force on blur event to force validation on field
                this.$refs.formDataId.focus()
                this.$refs.formDataId.blur()
            }.bind(this), 500)
        }
    },
    created() {
        this.localValue = {...this.value} || {}
    },
    watch: {
        value(v) {
            this.localValue = {...v} || {}
            if(this.activeTab == 1) {
                this.onBlur()
            }
        },
        localValue: {
            handler: function(v) {
                let dirty = JSON.stringify(v) != JSON.stringify(this.value)
                if(dirty && this.formValid) {
                    this.$emit('input',v)
                }
            },
            deep: true,
        },
        activeTab(v) {
            if(v == 1) {
                this.onBlur()
            }
        },
        duplicateFormDataIds(oldVal, newVal) {
            if(oldVal !== newVal && this.activeTab == 1) {
                this.onBlur()
            }
        },
        goToAdvanced(v) {
            if(v) {
                this.activeTab = 1
                this.$emit('resetAdvancedTab')
            }
        }
    },
    data() {
        return {
            originalIdStartDate: new Date('2021-02-24 GMT-600'),
            activeTab: 0,
            formValid: true,
            localValue: {},
            defaultDataIds: [
                'Template', 'Form Name', 'Created', 'Created By User', 'Student', 'Student ID',
                'Student District ID', 'School', 'Grade', 'School Year', 'Inactive Date'
            ],
        }
    },
    mounted() {
        if(this.goToAdvanced) {
            this.activeTab = 1
            this.$emit('resetAdvancedTab')
        }
    }
}
</script>

<style lang="scss" scoped>
.grid {
    display: grid;
    grid-template-columns: auto auto 40px;
    font-size: 12px;

    border: 1px solid #efefef;
    grid-gap: 1px;
    background-color: #efefef;
    border-radius: 4px;
}
.hc {
    display: flex;
    align-items: center;
    font-weight: bold;
}
.grid > div {
    transition: background-color .28s cubic-bezier(0.4, 0, 0.2, 1);
    background-color: white;
    padding: 4px;
    &:not(.hc) {
        cursor: pointer;
        &:hover {
            background-color: rgba(0,0,0,.05);
        }
    }
}

</style>

<style lang="scss">
.form-common-config {
    .v-slide-group__prev { display: none }
    .v-slide-group__next { display: none }
    .v-slide-group__wrapper {
        height: 36px;
    }
    .v-tab {
        text-transform: none;
        min-width: 0;
        font-size: 12px;
        padding: 8;
    }
}
</style>
