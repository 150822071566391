<template>
    <div class="flex-fill d-flex">
        <div class="calendar-tools">
            <div
                v-for="(t, i) in eventTypes"
                :key="`event-type-`+i"
                @click="toggleType(t)"
                :class="{'event-type': 1, 'event-type-disabled': t.isActive ? 0 : 1}"
            >
                <span class="tool-name" :class="getClass(t.id)"></span>
                {{ t.title }}
            </div>
        </div>

        <calendar-view
            class="ec-calendar"
            :show-date="selectedDate"
            :events="items"
            @click-event="clickEvent"
        >
            <calendar-view-header
                slot="header"
                slot-scope="t"
                :header-props="t.headerProps"
                @input="setDate"
            />
        </calendar-view>

        <edit-action-item
            v-if="editing"
            :editing="editing"
            standalone
            @save="saveActionItem"
            @dismiss="editing = null"
        />

        <fe-dialog
            v-if="rescheduleDialog.show"
            :title="rescheduleDialog.title ? rescheduleDialog.title : ''"
            @close="rescheduleDialog.show = false"
            dismissButtonText="Cancel"
            width=600
        >
            <div class="page-section d-flex">
                <div class="py-2">Event Details</div>

                <v-menu offset-y left>
                    <template v-slot:activator="{on}">
                        <fe-icon-btn
                            class="ml-auto"
                            v-on="on"
                            useIcon="fal fa-ellipsis-v"
                        />
                    </template>

                    <v-list dense>
                        <v-list-item @click="viewSummary" v-if="isInterventionEvent">
                            <v-list-item-content>
                                View Intervention Summary
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="recordParticipation" v-if="isInterventionEvent">
                            <v-list-item-content>
                                Record Participation
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="doDelete(rescheduleDialog.rec)">
                            <v-list-item-content>
                                Delete Scheduled Date
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <v-form v-model="rescheduleDialog.valid">
                <fe-date-picker
                    class="flex-grow-1 mr-2"
                    label="Date"
                    v-model="rescheduleDialog.reschedule.startDate"
                    dateFormat="MM/DD/YYYY"
                />

                <div class="d-flex">
                    <fe-time-picker
                        label="Start Time"
                        class="flex-grow-1 mr-2"
                        v-model="rescheduleDialog.reschedule.startTime"
                    />

                    <fe-time-picker
                        class="flex-grow-1"
                        label="End Time"
                        v-model="rescheduleDialog.reschedule.endTime"
                    />
                </div>
            </v-form>

            <div v-if="isInterventionEvent">
                <div class="page-section" style="max-height: 200px; overflow: scroll;">Students in Intervention</div>
                <v-list class="py-0">
                    <v-list-item v-for="(s,i) in intvStudents" :key="`intv-student-`+i">
                        <v-list-item-content class="py-0">
                            <span style="font-size: 14px; font-weight: 500">{{ s.student_full_name}}</span>
                            <v-list-item-subtitle>
                                {{s.student_intervention_plan_intervention_start_date}} - {{ s.student_intervention_plan_intervention_end_date}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </div>

            <template #footer>
                <fe-btn usage="ghost" class="ml-auto" @click="rescheduleDialog.show = false">Cancel</fe-btn>
                <fe-btn usage="primary" :disabled="!canSave" @click="reschedule">Save</fe-btn>
            </template>
        </fe-dialog>
    </div>
</template>

<script>
    const CALENDAR_TYPE_INTERVENTIONS = 'interventions'
    const CALENDAR_TYPE_PROGRESS_MONITORING = 'progress-monitoring'
    const CALENDAR_TYPE_SLO_STRATEGIES = 'slo-strategies'
    const CALENDAR_TYPE_MEETINGS = 'meetings'
    const CALENDAR_TYPE_TASKS = 'tasks'

    import { mapState } from 'vuex'
    import { CalendarView, CalendarViewHeader } from "vue-simple-calendar"
	// The next two lines are processed by webpack. If you're using the component without webpack compilation,
	// you should just create <link> elements for these. Both are optional, you can create your own theme if you prefer.
	require("vue-simple-calendar/static/css/default.css")
    require("vue-simple-calendar/static/css/holidays-us.css")
    import EditActionItem from '../actionItems/EditActionItem'

    export default {
        name: 'Calendar',

        components: {
            CalendarView,
            CalendarViewHeader,
            EditActionItem
        },

        data() {
            return {
                rescheduleDialog: {
                    show: false,
                    title: '',
                    rec: {},
                    valid: false,
                    reschedule: {}
                },
                localValue: null,
                eventTypes: [],
                items: [],
                selectedDate: new Date(),
                intvStudents: [],
                editing: null,
            }
        },

        computed: {
            ...mapState('global', ['currentYear']),
            canSave() {
                if (this.rescheduleDialog.originalDates && this.rescheduleDialog.reschedule) {
                    let raw = this.rescheduleDialog.originalDates
                    let rec = this.rescheduleDialog.reschedule

                    if (this.$isDirty([raw], [rec])) {
                        return true
                    }
                }
                return false
            },
            isInterventionEvent() {
                return this.rescheduleDialog.rec.calendarId === CALENDAR_TYPE_INTERVENTIONS
            }
        },

        mounted() {
            this.loadData()
        },

        methods: {
            loadData() {
                this.$modelGet('calendarEventType').then(v => {
                    this.eventTypes = v
                    this.eventTypes.forEach(t => t.isActive = true)

                    this.loadEventTypes()
                })
            },

            startReschedule(e) {
                let o = e.originalEvent
                let timeRegEx = /[0-9]{2}\:[0-9]{2}\:[0-9]{2}/
                let startTime = o.startDate ? o.startDate.match(timeRegEx)[0] : null
                let endTime = o.endDate ? o.endDate.match(timeRegEx)[0] : null
                let info = {
                    startDate: o.startDate.split(' ')[0],
                    startTime,
                    endTime
                }

                this.rescheduleDialog = {
                    show: true,
                    originalDates: info,
                    rec: {...o},
                    title: e.title,
                    valid: false,
                    reschedule: {...info}
                }

                if (o.calendarId === CALENDAR_TYPE_INTERVENTIONS) {
                    let p = {
                        intervention_plan_id: o.meta.intervention_plan_id,
                        schedule_date_occurrence: o.startDate.split(' ')[0],
                    }
                    this.$axios.get('interventionView.php?action=get&property=student_intervention_schedule&'+this.$objectToParams(p))
                        .then(resp => {
                            this.intvStudents = this.$ecResponse(resp, 'schedules')
                        })
                }
            },

            reschedule() {
                let r = this.rescheduleDialog

                let occ = {
                    id: r.rec.id,
                    schedule_id: r.rec.meta.schedule_id,
                    occurrence: r.reschedule.startDate,
                    occurrence_start_time: r.reschedule.startTime,
                    occurrence_end_time: r.reschedule.endTime
                }

                this.$confirmCreate([r], () => {
                    this.$axios.post('scheduleDate.php?action=update', { schedule_date: [occ] })
                        .then(resp => {
                            this.$ecResponse(resp)
                        })
                        .finally(() => {
                            this.rescheduleDialog.show = false
                            this.loadData()
                        })
                }, 'reschedule')

            },

            clickEvent(e) {
                if (e.originalEvent.calendarId === CALENDAR_TYPE_TASKS) {
                    this.editing = {id: e.originalEvent.id}
                } else {
                    this.startReschedule(e)
                }
            },

            saveActionItem(e) {
                this.editing = null
                this.loadEventTypes()
            },

            doDelete(e) {
                let rec = {}
                let base = [CALENDAR_TYPE_INTERVENTIONS, CALENDAR_TYPE_PROGRESS_MONITORING, CALENDAR_TYPE_SLO_STRATEGIES, CALENDAR_TYPE_MEETINGS]
                if (base.indexOf(e.calendarId) > -1) {
                    rec.schedule_id = e.meta.schedule_id
                    rec.id = e.id
                } else if (e.calendarId === CALENDAR_TYPE_TASKS) {
                    return
                }

                this.$confirmDelete([rec], () => {
                    this.$axios.post('scheduleDate.php?action=delete', { schedule_date: [rec] })
                        .then(resp => {
                            this.$ecResponse(resp)
                        })
                        .finally(() => {
                            this.rescheduleDialog.show = false
                            this.loadData()
                        })
                })
            },

            toggleType(type) {
                type.isActive = !type.isActive
                this.$set(this.eventTypes, type.id-1, type)

                this.loadEventTypes()
            },

            setDate(d) {
                this.selectedDate = d
            },

            getClass(calendarId) {
                return 'ec-calendar-' + calendarId
            },

            loadEventTypes() {
                this.items = []
                this.eventTypes.filter(r => r.isActive).forEach(et => {
                    let p = {
                        calendar: et.id,
                        startDate: this.currentYear.year_start,
                        endDate: this.currentYear.year_end
                    }
                    this.$axios.get('calendar.php?action=get&property=events&'+this.$objectToParams(p)).then(resp => {
                        et.data = this.$ecResponse(resp, 'events')

                        et.data.forEach(dt => {
                            this.items.push({
                                id: dt.id,
                                startDate: dt.startDate,
                                endDate: dt.endDate,
                                title: dt.title,
                                classes: this.getClass(dt.calendarId),
                                calendarId: dt.calendarId,
                                meta: dt.meta,
                            })
                        })

                    })
                })
            },

            viewSummary() {
                let rec = this.rescheduleDialog.rec
                this.rescheduleDialog.show = false

                this.$store.commit('global/addDockableWindow', {
                    name: 'Intervention Groups',
                    component: 'intervention-workspace',
                    attrs: {interventionPlanId: rec.meta.intervention_plan_id}
                })
            },

            recordParticipation() {
                let rec = this.rescheduleDialog.rec
                this.rescheduleDialog.show = false

                this.$store.commit('global/addDockableWindow', {
                    name: 'Record Intervention Participation',
                    component: 'intervention-participation',
                    attrs: {record: {
                        record: {intervention_plan_id: rec.meta.intervention_plan_id},
                        startDate: rec.startDate,
                    }}
                })
            }
        },
    }
</script>
<style lang="scss">
.ec-calendar .previousYear,.nextYear,.previousPeriod,.nextPeriod,.currentPeriod {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    margin: 4px;
}
.ec-calendar-interventions {
    cursor: pointer;
    color: white;
    background: #FF6666 !important;
    border-radius: 12px;
}

.ec-calendar-progress-monitoring {
    cursor: pointer;
    color: white;
    background: #66B2FF !important;
    border-radius: 12px;
}

.ec-calendar-slo-strategies {
    cursor: pointer;
    color: white;
    background: #B266FF !important;
    border-radius: 12px;
}

.ec-calendar-meetings {
    cursor: pointer;
    color: gray;
    background: #00FF66 !important;
    border-radius: 12px;
}

.ec-calendar-tasks {
    cursor: pointer;
    color: white;
    background: #FFb266 !important;
    border-radius: 12px;
}

div .cv-week .dow0,.dow6 {
    background: #efefef;
}
</style>

<style lang="scss" scoped>
.calendar-tools {
    width: 200px;
    padding: 16px 8px 8px 16px;

    .tool-name {
        display: inline-block;
        width: 16px;
        height: 16px;
    }

    .event-type {
        cursor: pointer;

        &:hover {
            font-weight: bold;
        }

        &-disabled {
            opacity: .5;
        }
    }
}

.page-section {
    font-size: 16px;
    font-weight: 500;
}
</style>
