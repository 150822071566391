<template>
    <div flat class="flex-fill flex-column" style="height: 90%; overflow: hidden;">
        <fe-mask v-if="loading"/>
        <v-slide-x-transition mode="out-in">
            <div v-if="showSuccess || createdIds.length" class="text-center d-flex flex-fill flex-column align-center">
                <div style="margin: auto;" v-if="!toPrint.length">
                    <div><v-icon color="green">fas fa-check</v-icon></div>

                    <div class="section-title mt-2">Success</div>

                    <div style="font-size: 16px; margin-top: 12px;">Intervention has been successfully created</div>

                    <div @click="printCreation" class="creation-link mt-2">
                        Print intervention creation report
                    </div>
                </div>

                <div v-else style="margin: auto;">
                    <div class="page-section">Intervention Creation Reports</div>
                    <v-list>
                        <v-list-item v-for="p in toPrint" @click="printCreationReport(p)">
                            <v-list-item-icon><v-icon>fal fa-file-pdf</v-icon></v-list-item-icon>

                            <v-list-item-content>
                                {{p.student_full_name}}
                            </v-list-item-content>

                        </v-list-item>

                    </v-list>
                </div>

                <div class="ml-auto align-self-end">
                    <fe-btn @click="onClosePanel">Okay</fe-btn>
                </div>
            </div>

            <div v-else-if="!activeSection" key="selection-picker">
                <div class="d-flex">
                    <div class="page-title">Create Intervention</div>

                    <div class="ml-auto">
                        <fe-icon-btn small useIcon="fas fa-times" class="min-btn" @click="verifyClose"/>
                    </div>
                </div>

                <div class="intv-section" v-for="(section, i) in sections.filter(r=>!r.hidden)" :class="{'section-disabled': section.disabled}" :key="`intv-section-`+i">
                    <div class="d-flex">
                        <div>
                            <div class="intv-section-title" :style="{'padding-top': section.complete ? '8px' : '16px'}">{{section.name}}</div>
                            <div class="intv-section-summary" v-if="section.complete">{{ section.summary() }}</div>
                        </div>

                        <div class="ml-auto pt-1">
                            <fe-btn usage="tertiary" @click="setActive(section)" :disabled="section.disabled">
                                {{ section.complete ? 'Update' : 'Add' }}
                            </fe-btn>
                        </div>
                    </div>
                </div>

                <div class="d-flex">
                    <fe-btn class="ml-auto mr-1" @click="submitValue" :disabled="!canSubmit">Save</fe-btn>
                </div>
            </div>

            <div class="d-flex flex-column flex-fill" v-else key="focused-component" style="overflow: hidden;">
                <div class="d-flex">
                    <fe-icon-btn small useIcon="fas fa-chevron-left" class="mt-1" @click="activeSection=null"/>

                    <div class="page-title">{{activeSection.name}}</div>
                    <div class="ml-auto" v-if="showClose">
                        <fe-icon-btn small useIcon="fas fa-times" class="min-btn" @click="verifyClose"/>
                    </div>
                </div>

                <div style="overflow: scroll;" class="d-flex flex-column flex-fill pa-1">
                    <component
                        @add="next(activeSection)"
                        :students="interventionStudents"
                        :is="activeSection.component"
                        v-model="localModel[activeSection.model]"
                        :fullModel="localModel"
                        :timeRequired="timeRequired"
                        :allowBehavior="activeSection.allowBehavior"
                    />
                </div>
            </div>
        </v-slide-x-transition>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import InterventionStudents from '@/components/modules/intervention/creation/Students'
    import InterventionDetails from '@/components/modules/intervention/creation/InterventionDetails'
    import InterventionSchedule from '@/components/modules/intervention/creation/Schedule'
    import AcademicMonitorSchedule from '@/components/modules/intervention/creation/AcademicMonitorSchedule'
    import ReviewSchedule from '@/components/modules/intervention/creation/ReviewSchedule'
    import BehaviorSchedule from './BehaviorSchedule'

    export default {
        name: 'InterventionCreation',

        components: {
            InterventionStudents,
            InterventionDetails,
            InterventionSchedule,
            AcademicMonitorSchedule,
            ReviewSchedule,
            BehaviorSchedule
        },

        props: {
            value: {},
            students: {},
            showClose: {
                type: Boolean,
                default: true
            },
            allowBehavior: {
                type: Boolean,
                default: true
            },
            // pass fb status and name to panels as $parent
            fbMonitorAvailable: {
                type: Boolean,
                default: false
            },
            fbMonitorId: {
                type: Number,
                required: false
            },
            fbMonitorName: {
                type: String,
                required: false
            },
            fbMonitorStudents: {
                type: Array,
                required: false
            },
            fbMonitorStartDate: {
                type: String,
                required: false
            },
            fbMonitorEndDate: {
                type: String,
                required: false
            },
            fbMonitorType: {
                type: Number,
                required: false
            },
            fbMonitorSkill: {
                type: Number,
                required: false
            },
            fbMonitorUnmatched: {
                type: Number,
                required: false
            }
        },

        data() {
            let me = this

            return {
                toPrint: [],
                localModel: {
                    students: [],
                    schedule: {},
                    monitors: []
                },
                showSuccess: false,
                createdIds: [],
                activeSection: null,
                timeRequired: true,
                sections: [{
                    name: 'Students',
                    model: 'students',
                    expanded: 0,
                    complete: 0,
                    component: 'intervention-students',
                    disabled: false,
                    summary() {
                        let s = me.localModel.students || []
                        if (s.length > 0) {
                            return s.length + ' Student(s) in intervention'
                        }
                        return false
                    }
                }, {
                    name: 'Intervention Details',
                    model: 'intervention',
                    expanded: 0,
                    complete: 0,
                    component: 'intervention-details',
                    disabled: true,
                    allowBehavior: me.allowBehavior,
                    summary() {
                        let i = me.localModel.intervention
                        if (!i) return false

                        if (me.$checkAllKeys(i, ['category_rec', 'intervention_level_id'])) {
                            return (i.category_rec.name + " Intervention | " + i.intervention_level_id.name).substring(0, 30) + '...'
                        }
                    }
                }, {
                    name: 'Intervention Schedule',
                    model: 'schedule',
                    expanded: 0,
                    complete: 0,
                    component: 'intervention-schedule',
                    disabled: true,
                    summary() {
                        let i = me.localModel.schedule
                        if (!i) return false

                        if (i.start_date) return me.$dayjs(i.start_date).format('MM/DD/YYYY') + '-' + me.$dayjs(i.end_date).format('MM/DD/YYYY')
                    }
                }, {
                    name: 'Point Sheet',
                    model: 'behavior',
                    expanded: 0,
                    complete: 0,
                    component: 'behavior-schedule',
                    disabled: true,
                    hidden: true,
                    summary() {
                        let i = me.localModel.behavior
                        if (!i || !i.monitors) return false
                        return i.monitors.length + ' Collection Points'
                    }
                }, {
                    name: 'Monitoring Schedule',
                    model: 'monitors',
                    expanded: 0,
                    complete: 0,
                    component: 'academic-monitor-schedule',
                    disabled: true,
                    summary() {
                        let i = me.localModel.monitors
                        if (!i) return false

                        return i.length + ' Active Monitors'
                    }
                }, {
                    name: 'Review Schedule',
                    model: 'review',
                    expanded: 0,
                    complete: 0,
                    component: 'review-schedule',
                    disabled: true,
                    summary() {
                        let i = me.localModel.review

                        if (!i) return false

                        if (i.frequency_cd === 'WEEK') {
                            return 'Every ' + i.frequency_cnt + ' week' + (i.frequency_cnt > 1 ? 's' : '')
                        } else {
                            // Make sure 1 is 1st, 2 is 2nd, etc.  Use July because it has 31 days
                            return 'Every month on the ' + me.$dayjs(`2000-07-${i.frequency_cnt < 10 ? '0' : ''}${i.frequency_cnt}`).format('Do')
                        }
                    }
                }],
            }
        },

        computed: {
            ...mapState('global', ['sessionUser', 'currentYear', 'loading']),
            ...mapState('intervention', ['editBehaviorPlan']),
            interventionStudents() {
                if (this.localModel.students) {
                    let arr = []
                    this.localModel.students.forEach(stu => {
                        arr.push({
                            student_id: stu.student_id,
                            student_full_name: stu.student_full_name
                        })
                    })
                    return arr
                }
                return []
            },
            canSubmit() {
                let valid = true

                this.sections.filter(r=>!r.hidden).forEach(s => {
                    if (this.timeRequired && s.model === 'schedule') {
                        let schedule = this.localModel.schedule
                        if (!schedule.start_time || !schedule.end_time) {
                            s.complete = 0
                        }
                    }
                    if (!s.complete) valid = false
                })

                return valid
            }
        },

        watch: {
            editBehaviorPlan: {
                handler(v) {},
                immediate: true
            },
            'localModel.students': {
                deep: true,
                handler(v, o) {
                    this.localModel.monitors.forEach(m => m.students = v.map(r => { return {
                        student_id: r.student_id,
                        student_full_name: r.student_full_name,
                        goal: null,
                        merged_probes: []
                    }}))
                }
            },
            'localModel.intervention.category_rec'(v) {
                if (v?.id) {
                    if (v.id === 4) {
                        this.sections[3].hidden = false
                        this.sections[4].hidden = true
                        this.sections[5].hidden = true
                        this.timeRequired = false
                    } else {
                        this.sections[3].hidden = true
                        this.sections[4].hidden = false
                        this.sections[5].hidden = false
                        this.timeRequired = true
                    }
                }
            }
        },

        mounted() {
            let startDate = ''
            let endDate = ''
            if (!this.fbMonitorAvailable) {
                startDate = this.$dayjs().format('MM/DD/YYYY')
                endDate   = this.currentYear ? this.$dayjs(this.currentYear.year_end, 'YYYY-MM-DD').format('MM/DD/YYYY') : this.$dayjs().format('MM/DD/YYYY')
            } else {
                // if FB monitor, use dates from monitor
                startDate = this.fbMonitorStartDate
                endDate = this.fbMonitorEndDate
            }

            // if pre-selected students, make sure they're included on page mount
            if (this.$props.value?.students) {
                this.sections[0].complete = 1
                this.sections[1].disabled = false
            }

            this.$data.localModel = this.value || {
                students: this.value?.students || this.students || [],
                schedule: {
                    user_id: this.sessionUser.user.id,
                    start_date: startDate,
                    end_date: endDate,
                    frequency_cd: 'WEEK'
                },
                monitors: [],
                behavior: {},
                intervention: {},
                review: {
                    start_date: startDate,
                    end_date: endDate,
                    dashboard_meeting_id: 0,
                    frequency_cnt: 1,
                    frequency_cd: 'WEEK'
                }
            }
        },

        beforeDestroy() {
            this.$store.commit('intervention/set', {
                key: 'school',
                value: null
            })
        },

        methods: {
            verifyClose() {
                let inprogress = false
                let me = this

                this.sections.filter(r=>!r.disabled).forEach(r=> {
                    if (r.summary()) inprogress=true
                })

                if (inprogress) {
                    this.$messageBox({
                        title: 'Confirm Close',
                        persistent: true,
                        maxWidth: 600,
                        message: 'You have not completed the intervention creation process.  Are you sure you want to quit?',
                        actions: [{
                            text: 'Cancel',
                            usage: 'ghost'
                        }, {
                            text: 'Ok',
                            primary: true,
                            onClick() {
                                me.$emit('closePanel', {})
                            }
                        }]
                    })
                } else {
                    me.$emit('closePanel', {})
                }

            },

            onClosePanel() {
                this.$emit('closePanel')
                this.$store.commit('global/reloadLaunchpad', { })
            },

            setActive(section) {
                this.activeSection = section
            },

            next(section) {
                let i = this.sections.findIndex(rec => rec.name == section.name)
                let j = this.sections.findIndex((rec,si) => si > i && !rec.hidden)

                this.sections[i].complete = 1

                if (j !== -1) {
                    this.sections[j].disabled = false
                    if (!this.sections[j].complete) {
                        this.activeSection = this.sections[j]
                    } else {
                        let k = this.sections.findIndex((rec,si) => si > j && !rec.hidden)
                        if (k !== -1) {
                            this.sections[k].disabled = false
                        }

                        this.activeSection = null
                    }
                } else {
                    this.activeSection = null
                }
            },

            submitPointSheet() {
                let lm = this.localModel
                let obj  = {
                    category_id: lm.intervention.category_id,
                    intervention_level_id: lm.intervention.intervention_level_id.id,
                    intervention_type_id: lm.intervention.intervention_type_id.id,
                    intervention_plan_id: this.editBehaviorPlan ? this.editBehaviorPlan.id : null,
                    name: lm.intervention.intervention_name,
                    user_id: lm.intervention.user_id.id,
                    secondary_user_id: lm.intervention.secondary_interventionists.map(user => user.id),
                    students: lm.students.map(r => { return { student_id: r.student_id } }),
                    monitors: [{
                        schedule_frequency_cd: lm.schedule.frequency_cd,
                        schedule_frequency_cnt: lm.schedule.frequency_cnt || 1,
                        schedule_secondary_cnt: lm.schedule.secondary_cnt || null,
                        schedule_start_date: this.$dayjs(lm.schedule.start_date).utc().format('YYYY-MM-DD'),
                        schedule_end_date: this.$dayjs(lm.schedule.end_date).utc().format('YYYY-MM-DD'),
                        default_start_time: lm.schedule.start_time,
                        default_end_time: lm.schedule.end_time,
                        schedule_week_days: lm.schedule.week_days ? lm.schedule.week_days : '',
                        monitor_user_id: lm.intervention.user_id.id,
                        monitor_score_options: lm.behavior.monitors
                    }]
                }

                this.$axios.post('interventions.php?action=create', obj)
                    .then(resp => {
                        let r = this.$ecResponse(resp)
                        if (r.success) {
                            this.showSuccess = true
                            this.createdIds = r.id
                        }
                    })
            },

            printCreation() {
                if (this.createdIds.length) {
                    let p = { intervention_id: this.createdIds }
                    this.$axios.get('interventionView.php?action=get&property=student_intervention_plan&'+this.$objectToParams(p))
                        .then(r => {
                            this.toPrint = r.data.interventions
                        })
                }
            },

            printCreationReport(student) {
                this.$downloadFile(
                    'reportCreate.php?type=intervention_creation&' + this.$objectToParams({ intervention_id: student.intervention_id, student_id: student.student_id }),
                    student.student_full_name + ' Creation Report')
            },

            // Submits a new intervention creation.  Note that when formatting
            // dates into YYYY-MM-DD format, we need to call dayjs.utc; otherwise,
            // the dayjs lib will apply local user's timezone onto the raw date, which ends up
            // setting the YYYY-MM-DD to the previous day
            submitValue() {
                if (this.localModel.intervention.category_id === 4) {
                    this.submitPointSheet()
                    return
                }
                // Get this in the PHP required formats
                let s = {}
                let lm = this.localModel
                let monitors = this.localModel.monitors

                let obj = {
                    category_id: lm.intervention.category_id,
                    intervention_level_id: lm.intervention.intervention_level_id.id,
                    intervention_type_id: lm.intervention.intervention_type_id.id,
                    // external_pm_plan_id: this.$props.fbMonitorId !== -1 ? this.$props.fbMonitorId : null, // pass to mark FB monitor as USED
                    external_pm_plan_id: this.fbMonitorId !== -1 ? this.fbMonitorId : null, // pass to mark FB monitor as USED
                    name: lm.intervention.intervention_name,
                    user_id: lm.intervention.user_id.id,
                    secondary_user_id: lm.intervention.secondary_interventionists.map(user => user.id),
                    students: lm.students.map(r => { return { student_id: r.student_id, student_full_name: s.student_full_name } }),
                    monitors: [],
                    schedule: {
                        start_date: this.$dayjs(lm.schedule.start_date).utc().format('YYYY-MM-DD'),
                        end_date: this.$dayjs(lm.schedule.end_date).utc().format('YYYY-MM-DD'),
                        start_time: lm.schedule.start_time,
                        end_time: lm.schedule.end_time,
                        frequency_cd: lm.schedule.frequency_cd,
                        frequency_cnt: lm.schedule.frequency_cnt,
                        secondary_cnt: lm.schedule.secondary_cnt,
                        week_days: lm.schedule.week_days ? lm.schedule.week_days : '',
                        session_duration: this.$getTimeDiff(lm.schedule.start_time, lm.schedule.end_time),
                    },
                    analysis: {
                        start_date: this.$dayjs(lm.review.start_date).utc().format('YYYY-MM-DD'),
                        end_date: this.$dayjs(lm.review.end_date).utc().format('YYYY-MM-DD'),
                        frequency_cd: lm.review.frequency_cd,
                        frequency_cnt: lm.review.frequency_cnt,
                        week_days: lm.review.week_days
                    }
                }

                monitors.forEach(mon => {
                    obj.monitors.push({
                        monitor_category_id: mon.sub_category_id.category_id,
                        schedule_start_date: this.$dayjs(mon.start_date).utc().format('YYYY-MM-DD'),
                        schedule_end_date: this.$dayjs(mon.end_date).utc().format('YYYY-MM-DD'),
                        default_start_time: mon.start_time,
                        default_end_time: mon.end_time,
                        monitor_user_id: mon.monitor_user_id,
                        sub_category_id: mon.sub_category_id.id,
                        goals: mon.students,
                        merge_probes: mon.mergedMonitors ? mon.mergedMonitors.filter(r=>r.merge).map(r => { return { student_monitor_plan_id: r.student_monitor_plan_id} }) : [],
                        schedule_frequency_cd: 'WEEK',
                        schedule_week_days: mon.monitor_week_days,
                        schedule_frequency_cnt: mon.monitor_frequency_cnt
                    })
                })

                if (lm.review.dashboard_meeting_id) {
                    lm.intervention.dashboard_meeting_id = lm.review.dashboard_meeting_id
                }

                let url = 'interventions.php?action=create'

                this.$setLoading(true)
                this.$axios.post(url, { interventions: [obj] })
                    .then(resp => {
                        let r = this.$ecResponse(resp)
                        if (r.success) {
                            this.showSuccess = true
                            this.createdIds = r.id
                        }
                    }).finally(() => this.$setLoading(false))
            }
        }
    }
</script>

<style lang="scss" scoped>
.section-disabled {
    opacity: .5;
}

.min-btn {
    ::v-deep .v-btn {
        margin: 0 !important;
        padding: 0 !important;
    }
}

.creation-link {
    font-size: 12px;
    color: var(--v-primary-base);
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.intv-section {
    background: #FFFFFF;
    border: 1px solid #E0E1E6;
    box-sizing: border-box;
    border-radius: 5px;
    height: 56px;
    margin: 4px;

    &-title {
        font-weight: 500;
        font-size: 14px;
        padding-top: 8px;
        color: #000000;
        padding-left: 16px;
    }

    &-summary {
        font-size: 12px;
        line-height: 15px;
        padding-left: 16px;
        color: #7E8494;
    }
}
</style>
