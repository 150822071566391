<template>
    <div class="d-flex flex-fill flex-column">
        <div class="page-title">Responses</div>
        <v-layout row wrap style="overflow: hidden;">

            <fe-dialog
                v-if="addDialog.show"
                title="Add Response"
                :acceptButtonDisabled="!addDialog.name || addDialog.name.length > 255"
                @close="addDialog.show = false"
                @accept="saveRecord"
                acceptButtonText="Save"
                persistent
                dismissButtonText="Cancel"
            >
                <v-form @submit.prevent>
                    <v-text-field
                        v-model="addDialog.name"
                        placeholder="Enter name"
                        solo flat dense
                        counter="255"
                        :rules="$fieldValidators('text', null, { required: true, limit: 255 })"
                    ></v-text-field>

                </v-form>
            </fe-dialog>

            <!-- Merge Dialog -->
            <fe-dialog
                v-if="mergeDialog.show"
                title="Merge Responses"
                :acceptButtonDisabled="!mergeDialog.record"
                @dismiss="mergeDialog.show = false"
                @close="mergeDialog.show=false"
                @accept="doMerge"
                acceptButtonText="Merge"
            >
                <v-form>
                    <fe-remote-combo
                        label="Merge To"
                        class="ma-2"
                        :url="$models.getUrl('incidentResponse', 'read')"
                        rootProperty="incident_responses"
                        v-model="mergeDialog.record"
                        byId
                    />

                </v-form>
            </fe-dialog>

            <fe-crud
                ref="crud"
                autoload
                :config="crudModel"
                @read="v => items = v"
            />


            <fe-action-list
                ref="list"
                :items="filterItems"
                chipKey="chips"
                iconKey="icons"
                :titleWidth="300"
                @rowClick="doSelect"
                @beginDestroy="deleteRecords"
                addButtonText="Add Response"
                @addButtonClick="addDialog.show=false; $nextTick(() => {addDialog.show = true})"
                @editButtonClick="showEditMenu"
                :showFiltering="false"
                show-search
                @selectionChanged="selections=$event"
            >
                <template v-slot:beforeButtons>
                    <div class="d-flex">
                        <v-checkbox
                            class="mt-4"
                            label="Show Inactive Responses"
                            v-model="showInactive"
                        />
                        <fe-btn
                            @click="mergeDialog.record = null; mergeDialog.show=true"
                            usage="tertiary" :disabled="selections.length === 0"
                            class="ml-auto"
                            v-if="showExtraTools"
                        >Merge</fe-btn>
                    </div>
                </template>
            </fe-action-list>
        </v-layout>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'
    import ActionList from '@/components/common/ActionList'

    export default {
        name: 'IncidentResponseList',
        components: {
            ActionList
        },
        inject: ['localStore'],
        props: {
            headerText: {
                type: String,
                default: 'Undefined'
            }
        },
        watch: {
            'addDialog.show'() {
                this.addDialog.name = ''
            }
        },
        computed: {
            ...mapLocalState(['selectedResponse', 'breadcrumbs']),
            crudModel() {
                return this.$models.incidentResponse
            },
            filterItems() {
                let arr = [...this.items]

                arr.forEach((rec) => {
                    rec.icons = []
                    rec.chips = []

                    if (rec.kpi) {
                        rec.chips.push({
                            name: 'KPI',
                            excludeClick: true
                        })
                    }

                    if (rec.incident_behavior_types.length > 0) {
                        rec.incident_behavior_types.forEach(tp => {
                            tp.name = tp.incident_behavior_type_name
                        })

                        let c = rec.incident_behavior_types.length
                        rec.icons = [{
                            icon: 'fas fa-whistle',
                            tooltip: 'Restricted to ' + c + ' Incident Type'+(c>1?'s':''),
                            // name: rec.incident_behavior_types.length + ' Types',
                        }]
                    }

                    if (rec.admin_only) {
                        rec.icons.push({
                            icon: 'fas fa-user-shield',
                            tooltip: 'Administration Only',
                        })
                    }

                    if (rec.notify_flag)
                        rec.icons.push({
                            icon: 'fas fa-bell',
                        })

                    if (rec.can_serve)
                        rec.icons.push({
                            icon: 'fas fa-date',
                            tooltip: 'Can Be Served',
                        })

                    if (rec.incident_response_fields.length > 0)
                        rec.icons.push({
                            icon: 'fas fa-stream',
                            tooltip: 'Has Custom Fields',
                        })


                    if (rec.require_responder)
                        rec.icons.push({
                            icon: 'fas fa-reply',
                            tooltip: 'Requires Responder',
                        })

                    if (rec.schools.length > 0) {
                        let c = rec.schools.length
                        rec.icons.push({
                            icon: 'far fa-university',
                            tooltip: 'Restricted to ' + c + ' School'+(c>1?'s':''),
                        })
                    }

                })


                return arr.filter(rec => {
                    if (this.showInactive) {
                        return rec
                    } else {
                        return rec.active
                    }
                 })
            }
        },
        created() {
            // this.loadData()
        },
        data() {
            return {
                showInactive: false,
                filterText: '',
                max25chars: v => v.length <= 25 || 'Name too long!',
                items: [],
                stateDialog: {
                    show: false,
                    record: {}
                },
                addDialog: {
                    show: false,
                    name: ''
                },
                mergeDialog: {
                    show: false,
                    record: null
                },
                sortOptions: [{
                    id: 'name',
                    name: 'Name',
                    dir: null
                }, {
                    id: 'aliases',
                    name: 'Aliases',
                    dir: null
                }, {
                    id: 'descriptors',
                    name: 'Detail Options',
                    dir: null
                }, {
                    id: 'schools',
                    name: 'School Restrictions',
                    dir: null
                }],
                selectedSort: {},
                showExtraTools: false,
                selections: []
            }
        },
        methods: {
            showEditMenu() {
                if (!this.showExtraTools) this.selections = []

                this.showExtraTools=!this.showExtraTools
            },
            doMerge() {
                if (!this.mergeDialog.record) {
                    return
                }

                this.$confirmCreate(this.selections, () => {
                    this.selections.map(rec => rec.merge_to_id = this.mergeDialog.record)
                    this.$refs.crud.update(this.selections)
                        .then(response => {})
                        .catch(error => {})
                        .finally(() => {
                            this.$refs.list.edit(false)
                            this.$refs.crud.read()
                            this.mergeDialog = {
                                show: false,
                                record: null
                            }
                        })
                }, 'Merge')
            },
            doSelect(item) {
                this.selectedResponse = item

                this.$addCrumb('Incidents', {
                    name: item.name,
                    show: 'response'
                })

            },
            // loadData() {
            //     let cfg = this.crudConfig

            //     this.$axios.get(cfg.read)
            //     .then(response => {
            //         let data = []

            //         if (response && response.data) {
            //             if (cfg.rootProperty) {
            //                 this.$data.items = response.data[cfg.rootProperty]
            //             } else {
            //                 this.$data.items = response.data
            //             }
            //         }
            //     })
            // },
            deleteRecords(items) {
                this.$confirmDelete(items, () => {
                    let arr = []
                    items.forEach(rec => { arr.push({ id: rec.id })})

                    this.$refs.crud.destroy(arr)
                        .then(response => {
                            this.$refs.crud.read()
                        })
                        .catch(error => {})
                        .finally(() => {
                            this.$refs.list.edit(false)
                        })
                })
            },
            saveRecord(item) {
                let name = this.addDialog.name
                let cfg = this.crudConfig
                let r = {
                    name: this.addDialog.name,
                    active: true,
                    kpi: false,
                    admin_only: false,
                    require_responder: false,
                    serve_flag: false
                }

                this.$refs.crud.create(r)
                    .then((response) => {
                        this.$refs.crud.read().then(() => {
                            let r = this.items.find((r) => { return r.name == name })
                            if (r) {
                                this.doSelect(r)
                            }
                        })
                    }).finally(() => {
                        this.addDialog.show = false
                    })
            },
            changeStateCode(item) {
                this.stateDialog.record = {...item}
                this.stateDialog.show = true
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
