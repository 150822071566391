var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"ec-section-title"},[_vm._v("Section")]),_c('div',{staticClass:"ec-caption"},[_vm._v(" Configure the section with the options below. To reorder or delete use the 6-dot menu. ")]),_c('form',{staticClass:"mt-2"},[_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Show Section Name")]),_c('fe-switch',{staticStyle:{"margin-left":"auto"},attrs:{"value":!_vm.localValue.hide_label},on:{"input":function($event){_vm.localValue.hide_label=!$event}}})],1),_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Show Border")]),_c('fe-switch',{staticStyle:{"margin-left":"auto"},model:{value:(_vm.localValue.border),callback:function ($$v) {_vm.$set(_vm.localValue, "border", $$v)},expression:"localValue.border"}})],1),_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("Layout")]),_c('fe-remote-combo',{staticStyle:{"margin-left":"auto"},attrs:{"byId":"","itemText":"description","items":Object.values(_vm.sectionLayoutTypes),"clearable":false},model:{value:(_vm.localValue.section_layout_type_id),callback:function ($$v) {_vm.$set(_vm.localValue, "section_layout_type_id", $$v)},expression:"localValue.section_layout_type_id"}})],1),_c('div',{staticClass:"d-flex"},[_c('fe-label',{style:(_vm.labelStyle)},[_vm._v("% Width of Page")]),_c('fe-remote-combo',{staticStyle:{"margin-left":"auto"},attrs:{"byId":"","itemValue":"value","items":[
                    {value:.25, name:'25%'},
                    {value:.33, name:'33%'},
                    {value:.50, name:'50%'},
                    {value:.66, name:'66%'},
                    {value:.75, name:'75%'},
                    {value:.99, name:'100%'}
                ],"clearable":false},model:{value:(_vm.localValue.width),callback:function ($$v) {_vm.$set(_vm.localValue, "width", $$v)},expression:"localValue.width"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }