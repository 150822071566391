<template>
    <div class="border">
        <div class="center-text">
            <v-icon v-if="canManageSchools" class="fas fa-school" style="color: #687baa; font-size: 32px; padding: 18px;" ></v-icon>
            <v-icon v-else class="far fa-search-plus fe-icon search-icon" style="color: #687baa; font-size: 32px; padding: 18px;" ></v-icon>

        <div v-if="canManageSchools" style="padding: 8px;">
            <div :style="{'font-weight': 'bold', 'padding': '8px'}">
                No school groups configured
            </div>
            <div>
                If defined, you can view data by school group, such as
            </div>
            <div>
                elementary, middle, and high school. This setting is specific to
            </div>
            <div>
                each district and can be set up under System Configuration.
            </div>
        </div>
        <div v-else style="padding: 8px;">
            <div :style="{'font-weight': 'bold', 'padding': '8px'}">
                No school groups configured
            </div>
            <div>
                You can view data by school group, such as elementary,
            </div>
            <div>
                middle, and high school. This setting is specific to each
            </div>
            <div>
                district and can be set up under System Configuration.
            </div>
        </div>

            <div style="padding: 14px;">
                <fe-btn v-if=canManageSchools
                        @click="go()"
                        style="padding: 8px 4px 8px 8px;"
                >
                    Set Up School Groups
                </fe-btn>

                <fe-btn
                    @click="learnMoreNav"
                    style="padding: 8px 8px 8px 4px;;"
                    :usage="canManageSchools ? 'secondary' : 'primary'"
                >
                    Learn More
                </fe-btn>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NoSchoolGroups',

        props: {
            windowId: {
                type: Number,
                required: false
            },
            canManageSchools: {
                type: Boolean,
                required: true
            }
        },

        methods: {
            go() {
                this.$router.push('/Manage/District/SchoolGroup')
                if (this.windowId) {
                    let removeDockedWindowPayload = {
                        dockedWindowId: this.$store.getters['global/dockableWindows'].filter(w => w.dockedWindowId === this.windowId).pop().dockedWindowId,
                        isStrict: true
                    }
                    this.$store.commit('global/removeDockedWindow', removeDockedWindowPayload)
                }
            },

            learnMoreNav() {
                window.open('https://educlimber.illuminateed.com/hc/en-us/articles/1260803526149-Create-School-Groups', '_blank')
            }
        }
    }
</script>

<style lang="scss">
.border {
    position: relative;
    height: 100%;
    width: 100%;
    border: 1px solid #dddddd;

    .center-text {
        width: 100%;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center !important;
    }
}
</style>
