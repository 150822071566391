export default {
    targetSet: {
        rules: [
            r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        ],
        defaults: {
            endpoint: 'targetSet.php'
        },
        read: {
            params: { action: 'get' }
        },
        create: {
            rootProperty: 'targets',
            params: { action: 'create' },
        },
        update: {
            rootProperty: 'targets',
            params: { action: 'update' },
        },
        destroy: {
            rootProperty: 'targets',
            params: { action: 'delete' },
        }
    }
}