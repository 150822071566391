<template>
    <div class="d-flex flex-fill">
        <fe-grid
            ref="grid"
            v-if="forms"
            displayDensity="medium"
            :showToolbar="true"
            :rowData="rowData"
            :columnDefs="colDefs"
            style="height: 100%; width: 100%;"
            :show-add-row-btn="false"
            :show-toolbar-menu="false"
            :show-fullscreen="false"
            :show-download="false"
            @cellValueChanged="cellUpdated"
        >
            <template v-slot:toolbar-items>
                <fe-btn usage="tertiary" @click="adding = true">Add</fe-btn>
            </template>
        </fe-grid>

        <fe-dialog
            v-if="adding"
            @close="adding=false"
            title="Add Dates"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            @accept="changeDates"
            disable-autoclose
        >
            <fe-mask v-model="loading" modalMask showLoader persistent/>
            <v-form v-model="valid" ref="form">

                <fe-remote-combo
                    label="Method"
                    ref="addingMethod"
                    placeholder="Select a Method"
                    :items="methods"
                    itemText="name"
                    itemValue="id"
                    v-model="addingMethod"
                    :returnObject="false"
                    style="width: 100%;"
                    :rules="rules.addingMethod"
                />

                <template v-if="isRecurring">
                    <fe-remote-combo
                        label="Recurring Option"
                        ref="recurringMethod"
                        placeholder="Select a Recurring Option"
                        :items="recurringMethods"
                        itemText="name"
                        itemValue="id"
                        v-model="recurringMethod"
                        :returnObject="false"
                        style="width: 100%;"
                        :rules="rules.recurringMethod"
                    />

                    <div class="mb-4">
                        <weekday-picker v-model="weekdays" />
                        <div v-if="!daysOfWeekValid" class="v-messages theme--light error--text days-of-week-errors" role="alert">
                            <div class="v-messages__wrapper">
                                <div class="v-messages__message">Day of week required</div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <fe-label>Date Range</fe-label>
                            <div class="d-flex">
                            <div class="pr-2">
                                <fe-label>Start Date</fe-label>
                                <fe-date-picker
                                    v-model="startDate"
                                    :rules="$fieldValidators('text', 'Start date', { required: true })"
                                    prependIcon="fa-calendar-alt"
                                    dateFormat="MM/DD/YYYY"
                                />
                            </div>

                            <div>
                                <fe-label>End Date</fe-label>
                                <fe-date-picker
                                    v-model="endDate"
                                    :rules="rules.endDate"
                                    prependIcon="fa-calendar-alt"
                                    dateFormat="MM/DD/YYYY"
                                />
                            </div>
                        </div>

                        <div v-if="!dateRangeValid" class="v-messages theme--light error--text date-range-errors" role="alert">
                            <div class="v-messages__wrapper">
                                <div class="v-messages__message">Invalid Date Range</div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-fill">
                        <div class="flex-grow-1 flex-shrink-1 pr-1">
                            <fe-label>Start Time</fe-label>
                            <fe-time-picker
                                prepend-icon="access_time"
                                :allowed-minutes="allowedMinutes"
                                v-model="startTime"
                                :rules="$fieldValidators('text', 'Start time', { required: true })"
                            />
                        </div>

                        <div class="flex-grow-1 flex-shrink-1 pl-1">
                            <fe-label>End Time</fe-label>
                            <fe-time-picker
                                prepend-icon="access_time"
                                :allowed-minutes="allowedMinutes"
                                v-model="endTime"
                                :rules="rules.endTime"
                            />
                        </div>
                    </div>

                    <div>
                        <fe-label>Every # Week(s)</fe-label>
                        <v-text-field
                            flat solo dense
                            v-model="frequency"
                            validateOnBlur
                            :rules="rules.frequency"
                        />
                    </div>
                </template>

                <template v-if="isSingle">
                    <fe-label>Date </fe-label>
                    <fe-date-picker
                        style="width: 100%;"
                        v-model="singleDate"
                        emit-array
                        :rules="rules.singleDate"
                    />

                    <div class="d-flex flex-fill">
                        <div class="flex-grow-1 flex-shrink-1 pr-1">
                            <fe-label>Start Time</fe-label>
                            <fe-time-picker
                                prepend-icon="access_time"
                                :allowed-minutes="allowedMinutes"
                                v-model="startTime"
                                :rules="$fieldValidators('text', 'Start time', { required: true })"
                            />
                        </div>

                        <div class="flex-grow-1 flex-shrink-1 pl-1">
                            <fe-label>End Time</fe-label>
                            <fe-time-picker
                                prepend-icon="access_time"
                                :allowed-minutes="allowedMinutes"
                                v-model="endTime"
                                :rules="$fieldValidators('text', 'End time', { required: true })"
                            />
                        </div>
                    </div>
                </template>
            </v-form>
        </fe-dialog>

        <fe-crud
            ref="crud"
            autoload
            :config="$models.scheduleDate"
            :defaultParams="{
                dashboard_meeting_id: this.editing.id
            }"
            :readParams="{
                schedule_id: this.editing.schedule[0].id
            }"
            @read="dates = $event"
        />

        <fe-crud
            ref="crudForms"
            autoload
            :config="$models.form"
            @read="forms = $event"
        />
    </div>
</template>

<script>
    import WeekdayPicker from '@/components/common/button/WeekdayPicker'

    export default {
        name: 'Occurrences',
        components: { WeekdayPicker },
        props: {
            editing: { type: Object, default: false }
        },
        data() {
            return {
                times: [],
                forms: null,
                allowedMinutes: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
                valid: true,
                dates: [],
                adding: false,
                addingMethod: null,
                loading: false,
                methods: [
                    { id: 1, name: 'Add a Single Date' },
                    { id: 2, name: 'Add Recurring Dates' }
                ],
                recurringMethod: null,
                recurringMethods: [
                    { id: 1, name: 'ADD new dates in addition to existing dates' },
                    { id: 2, name: 'REPLACE all existing dates with new dates' }
                ],
                singleDate: null,
                obj: {
                    id: null,
                    default_start_time: null,
                    default_end_time: null,
                    start_date: null,
                    end_date: null,
                    frequency_cd: 'WEEK',
                    frequency_cnt: 1,
                    method: null,
                    week_days: ''
                },
                rules: {
                    frequency: [
                        (v) => { return (v &&
                            `${v}`.match(/^\s*[0-9]*\s*$/) &&
                            !isNaN(parseInt(v)) &&
                            parseInt(v) >= 1) || 'Not a valid frequency'
                        }
                    ],
                    addingMethod: [
                        (v) => { return !!(v && v.id && v.id > 0) || 'Method is required' }
                    ],
                    recurringMethod: [
                        (v) => { return !!(v && v.id && v.id > 0) || 'Recurring Option is required' }
                    ],
                    endDate: [
                        v => !this.$dayjs(v).isBefore(this.startDate) || 'Not a valid End Date'
                    ].concat(this.$fieldValidators('text', 'End date', { required: true })),
                    endTime: [
                        v => !this.$dayjs('2000-01-01 ' + v).isBefore('2000-01-01 ' + this.startTime) || 'Not a valid End Time'
                    ].concat(this.$fieldValidators('text', 'End time', { required: true })),
                    singleDate: [
                        (v) => { return !!v || 'Date is required' }
                    ]
                },
                dateRangeValid: true,
                daysOfWeekValid: true
            }
        },
        computed: {
            isSingle () { return this.addingMethod == 1 },
            isRecurring () { return this.addingMethod == 2 },
            frequency: {
                get () { return this.obj.frequency_cnt || 1 },
                set (v) { this.obj.frequency_cnt = parseInt(v) }
            },
            weekdays: {
                get () { return this.obj.week_days || '' },
                set (v) {
                    this.obj.week_days = v || ''
                    this.$nextTick(() => this.validateDaysOfWeek())
                }
            },
            startTime: {
                get () { return this.obj.default_start_time },
                set (v) {
                    this.obj.default_start_time = v

                    if ((v && !this.endTime) || this.$dayjs('2000-01-01 ' + this.endTime).isBefore('2000-01-01 ' + v)) {
                        this.endTime = this.$dayjs(v, 'HH:mm').add(15, 'm').format('HH:mm')
                    }
                }
            },
            endTime: {
                get () { return this.obj.default_end_time },
                set (v) { this.obj.default_end_time = v }
            },
            startDate: {
                get () { return this.obj.start_date },
                set (v) { this.obj.start_date = v }
            },
            endDate: {
                get () { return this.obj.end_date },
                set (v) { this.obj.end_date = v }
            },
            rowData () { return this.dates },
            colDefs () {
                return [
                    {
                        headerName: 'Date',
                        field: 'occurrence',
                        maxWidth: 160,
                        sortable: false,
                        editable: false,
                        autoHeight: true,
                        valueGetter: this.formatDate
                    },
                    {
                        headerName: 'Form Template',
                        field: 'form_id',
                        sortable: false,
                        editable: true,
                        autoHeight: true,
                        valueGetter: (v) => this.getFormName(v.data.form_id),
                        cellEditorFramework: 'FeGridChipSelect',
                        cellEditorParams: v => {
                            return {
                                value: v.data.form_id,
                                rowDataKey: 'form_id',
                                mode: 'edit',
                                items: this.forms,
                                keyProp: 'id',
                                labelProp: 'name',
                                disableChips: true
                            }
                        },
                        onCellValueChanged: this.clearFormValue,
                    },
                    {
                        headerName: 'Start Time',
                        field: 'occurrence_start_time',
                        maxWidth: 110,
                        sortable: false,
                        editable: true,
                        autoHeight: true,
                        valueGetter: (v) => this.getTimeDisplay(v.data.occurrence_start_time),
                        cellEditorFramework: 'FeGridChipSelect',
                        cellEditorParams: {
                            rowDataKey: 'occurrence_start_time',
                            mode: 'edit',
                            items: this.times,
                            keyProp: 'value',
                            labelProp: 'display',
                            disableChips: true,
                            elProps: {
                                clearable: false
                            }
                        }
                    },
                    {
                        headerName: 'End Time',
                        field: 'occurrence_end_time',
                        maxWidth: 110,
                        sortable: false,
                        editable: true,
                        autoHeight: true,
                        valueGetter: (v) => this.getTimeDisplay(v.data.occurrence_end_time),
                        cellEditorFramework: 'FeGridChipSelect',
                        cellEditorParams: {
                            rowDataKey: 'occurrence_end_time',
                            mode: 'edit',
                            items: this.times,
                            keyProp: 'value',
                            labelProp: 'display',
                            disableChips: true,
                            elProps: {
                                clearable: false
                            }
                        }
                    },
                    {
                        headerName: 'Remove',
                        field: 'id',
                        maxWidth: 85,
                        editable: false,
                        cellRenderer() {
                            return `<i class="fas fa-minus-circle" style="margin-left: 15px; font-size: 12px"></i>`
                        },
                        cellStyle() {
                            return { cursor: 'pointer', textAlign: 'left' }
                        },
                        onCellClicked: (v) => this.$refs.crud.destroy([v.data]).then(() => {
                            this.$emit('changeDates', this.editing.schedule_id, false)
                            this.$refs.crud.read()
                        })
                    }
                ]
            }
        },
        methods: {
            getFormName (id) { return this.forms.find(itm => itm.id == id)?.name },
            isValid () { return true },
            validateDaysOfWeek () { this.daysOfWeekValid = `${this.weekdays}` != '' },
            validateDateRange () { this.dateRangeValid = !!(this.obj.start_date && this.obj.end_date) },
            customRulesValid () { return (this.dateRangeValid && this.daysOfWeekValid) },
            changeDates () {
                if (this.$refs.form.validate() & !!this.customRulesValid()) {
                    this.loading = true
                    if (this.isRecurring) {
                        let data = {
                            dashboard_meeting_schedule: Object.assign({}, this.obj, {
                                method: (this.recurringMethod == 2) ? 'replace' : 'add',
                                start_date: this.obj.start_date,
                                end_date: this.obj.end_date
                            })
                        }
                        this.$axios.post(this.$models.getUrl('meetingSchedule', 'create') + `&id=${this.editing.id}`, data).then(() => {
                            this.$emit('changeDates', this.editing.schedule_id, false)
                            this.$refs.crud.read()
                            this.adding = false
                        }).catch(() => {}).finally(() => {
                            this.loading = false
                        })
                    } else if (this.isSingle) {
                        let data = {
                            dashboard_meeting_id: this.editing.id,
                            occurrence: this.singleDate,
                            occurrence_end_time: this.obj.default_end_time,
                            occurrence_start_time: this.obj.default_start_time,
                            schedule_id: this.editing.schedule[0].id
                        }
                        this.$refs.crud.create(data).then(() => {
                            this.$emit('changeDates', this.editing.schedule_id, false)
                            this.$refs.crud.read()
                            this.adding = false
                        }).catch(() => {}).finally(() => {
                            this.loading = false
                        })
                    }
                }
            },
            formatDate (v) { return this.$dayjs(v.data.occurrence, "YYYY-MM-DD").format("ddd, MM/DD/YYYY") },
            formatTime (v) {
                return (v.data[v.colDef.field])
                    ? this.getTimeDisplay(v.data[v.colDef.field])
                    : ''
            },
            setDefaults () {
                this.addingMethod = null
                this.recurringMethod = 1
                this.singleDate = null
                this.obj = Object.assign({}, this.editing.schedule[0], {
                    dashboard_meeting_id: this.editing.id,
                    frequency_cd: 'WEEK',
                    frequency_cnt: 1
                })
            },
            cellValid (params) {
                if (params.oldValue == params.newValue) return false

                if (/^.*_time/.test(params.column.colId) && params.data.occurrence_start_time >= params.data.occurrence_end_time) {
                    this.$snackbars.$emit('new', {
                        text: 'Invalid Time',
                        subtext: 'The end time must be later than the start time.',
                        usage: 'warning'
                    })

                    params.node.setDataValue(params.colDef.field, params.oldValue)

                    params.api.startEditingCell({
                        rowIndex: params.rowIndex,
                        colKey: params.column.colId
                    })

                    return false
                }

                return true
            },
            cellUpdated (params) {
                if (this.cellValid(params)) {
                    this.$refs.crud.update(params.data).then(() => {
                        this.$emit('changeDates', this.editing.schedule_id, false)
                    })
                }
            },
            buildTimes () {
                let tmp = []
                for (let hr=0; hr<24; hr++) {
                    for (let qtr=0; qtr<4; qtr++) {
                        let hour = `${hr}`.padStart(2, '0')
                        let minute = `${qtr*15}`.padStart(2, '0')
                        let val = `${hour}:${minute}:00`
                        tmp.push({
                            display: this.getTimeDisplay(val),
                            value: val
                        })
                    }
                }
                this.times = tmp
            },
            getTimeDisplay (val) {
                return this.$dayjs(val, "HH:mm:ss").format("h:mma")
            },
            clearFormValue(v) {
                if(!v.newValue || v.newValue == undefined) {
                    v.data.form_id = null
                    this.$refs.crud.update([v.data]).then(() => {
                        this.$emit('changeDates', this.editing.schedule_id, false)
                        this.$refs.crud.read()
                    })
                }
            }
        },
        mounted () {
            this.buildTimes()
        },
        watch: {
            adding (v) { if (v) this.setDefaults() },
            forms() {
                setTimeout(() => {
                    this.$refs.grid?.resize()
                }, 500)
            },
            dates() {
                setTimeout(() => {
                    this.$refs.grid?.resize()
                }, 500)
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
