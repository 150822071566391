<template>
    <div class="d-flex flex-column flex-fill no-scroll" style="overflow: scroll;">
        <div v-for="intv in interventions" class="ma-2" style="position: relative; border: 1px solid #dddddd; border-radius: 4px;">
            <div>
                <academic-progress-monitoring monitorDecisions v-if="intv.category_id!=4" :params="{ student_id: params.student_id, intervention_id: intv.id }"/>
                <behavior-progress-monitoring style="min-height: 300px;" v-if="intv.category_id==4" :params="{ student_id: params.student_id, intervention_id: intv.id }" :include-summary="false"/>

                <div class="tools d-flex" :class="{'academic': intv.category_id !== 4}">
                    <fe-icon-btn small useIcon="far fa-hands-heart" @click="showIntervention(intv)"/>
                    <fe-icon-btn small useIcon="far fa-comment" @click="showComments(intv)"/>
                </div>
            </div>
        </div>


        <fe-dialog
            title="Comments"
            v-if="messageDialog.show"
            @accept="messageDialog.show=false"
            @close="messageDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Done"
            width="600"
            :retain-focus="false"
        >
            <message dense :params="messageDialog.params" :allowPrivate="false"/>
        </fe-dialog>
    </div>
</template>

<script>
    import AcademicProgressMonitoring from '@/components/charts/AcademicProgressMonitoring'
    import BehaviorProgressMonitoring from '@/components/charts/BehaviorProgressMonitoring'
    import Message from '@/components/common/Message'

    export default {
        name: 'Interventions',
        components: { AcademicProgressMonitoring, BehaviorProgressMonitoring, Message },
        props: {
            params: {

            }
        },
        data () {
            return {
                interventions: [],
                messageDialog: {
                    show: false,
                    params: {}
                }
            }
        },
        created() {
            this.loadData()
        },
        computed: { },
        methods: {
            loadData() {
                this.$axios.get('interventionView.php?action=get&property=intervention&'+this.$objectToParams(this.params))
                    .then(r => {
                        this.interventions = this.$ecResponse(r, 'interventions')
                    })
            },
            showComments(rec) {
                this.messageDialog.show=true
                this.messageDialog.params={ student_id: this.params.student_id, intervention_id: rec.id }
            },
            showIntervention(rec) {

                this.$dockableWindow({
                    name: 'Intervention Groups',
                    component: 'intervention-workspace',
                    attrs: {
                        interventionPlanId: rec.plans[0].id
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.tools {
    position: absolute;
    top: 0;
    right: 40px;
    &.academic {
        right: 60px;
    }
}
</style>
