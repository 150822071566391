export default {
    targetSetTarget: {
        defaults: {
            endpoint: 'targetSetV.php', // scope: target_set_id=14&sub_category_id=19
            rootProperty: 'targets'
        },
        read: {
            params: {
                action: 'get',
                allow_large_sets: 1,
                group: { property: "group_display", direction: "ASC" }
            }

        },
        create: {
            params: {
                action: 'create',
                property: 'data_point_target'
            }
        },
        update: {
            params: {
                action: 'update',
                property: 'data_point_target'
            }
        },
        destroy: {
            params: {
                action: 'delete',
                property: 'data_point_target'
            }
        }
    }
}