<template>
    <fe-dialog
        :title="value.user_full_name"
        @close="$emit('close')"
    >
        <div v-for="v in fields" class="d-flex">
            <fe-label style="width:56px;">{{v.name}}</fe-label>
            <fe-label :content="getValue(v)"></fe-label>
        </div>

    </fe-dialog>
</template>

<script>

export default {
    name: 'UserCard',
    
    methods: {
        getValue(v) {
            if(v.type == 'boolean') {
                return this.value[v.field]
                    ? '<i class="fe-grid-icon fas fa-check-square theme--light"></i>'
                    : '<i class="fe-grid-icon far fa-square theme--light"></i>'
            } else {
                return `${this.value[v.field]}`
            }
        }
    },
    
    props: {
        value: null,
        fields: {type: Array, default: ()=>([
            {name:'Title', field:'title'},
            {name:'Email', field:'email_addr'},
            {name:'Login', field:'login'},
        ])},
    },
    
}
</script>

<style lang="scss" scoped>
</style>
