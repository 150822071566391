<template>
    <fe-dialog
        :title="title"
        width="900"
        height="600"
        class="dialog"
        :footer="false"
        dismissable
        @dismiss="$emit('close')"
    >
        <fe-remote-combo
            v-model="selected"
            class="mb-2"
            :items="availableChartTypes"
            :clearable="false"
            hideDetails
            byId
        />

        <div v-if="ready" class="wrap" style="height: 468px; width: 100%;">
            <div v-if="selected === 'student'" class="flex-column flex-fill">
                <div class="grid-wrapper flex-grow-1">
                    <fe-grid
                        ref="grid"
                        :columnDefs="columnDefs"
                        :rowData="students.list"
                        :showAddRowBtn="false"
                        style="height:100%; width:100%;"
                        disableInlineCreate
                    />
                </div>

                <div class="grid-footer d-flex flex-shrink-1">
                    <p class="student-count" v-html="studentCount"/>

                    <v-spacer/>

                    <fe-btn v-if="clickedParams" usage="ghost" @click="reset">
                        Reset
                    </fe-btn>
                </div>

                <fe-crud
                    ref="crud"
                    :config="gridConfig"
                    :readParams="readParams"
                    autoload
                    @read="students = $event"
                />
            </div>

            <visualization
                v-else
                ref="vis"
                :config="localConfig"
                :dashboard="dashboard"
                :canModify="false"
                :getSSValues="getSavedSearchValues"
                drilldown
                @drilldown="doDrilldown"
            />
        </div>
    </fe-dialog>
</template>

<script>
import Visualization from "./Visualization"

export default {
    name: "Drilldown",

    components: {
        Visualization
    },

    props: {
        config: {
            type: Object,
            required: true
        },
        dashboard: {
            type: Object,
            required: true
        },
        getSavedSearchValues: {
            type: Function,
            required: true
        },
    },

    data() {
        return {
            ready: false,
            clickedParams: undefined,
            selected: 'school',
            students: {list: []},
            chartTypes: {
                base: [{
                    id: 'school',
                    name: 'School'
                }, {
                    id: 'grade',
                    name: 'Grade'
                }, {
                    id: 'ethnicity',
                    name: 'Ethnicity'
                }, {
                    id: 'gender',
                    name: 'Gender'
                }],
                list: [{
                    id: 'student',
                    name: 'Student List'
                }],
                incident: [{
                    id: 'incident_behavior_type',
                    name: 'Primary Incident Type'
                }, {
                    id: 'incident_behavior',
                    name: 'Primary Incident Behavior'
                }, {
                    id: 'incident_motivation',
                    name: 'Incident Motivation'
                }, {
                    id: 'incident_hour',
                    name: 'Incident Hour'
                }, {
                    id: 'incident_location',
                    name: 'Incident Location'
                }, {
                    id: 'incident_response',
                    name: 'Primary Incident Response'
                }],
                assessment: [{
                    id: 'meal_status',
                    name: 'Meal Status'
                }, {
                    id: 'disability',
                    name: 'Disability'
                }, {
                    id: 'tag',
                    name: 'Student Tag'
                }, {
                    id: 'ell_flag',
                    name: 'EL Status'
                }, {
                    id: 'ell_level_id',
                    name: 'ELP Level'
                }]
            }
        }
    },

    computed: {
        title() {
            switch (this.config.type) {
                case "assessment":
                    return 'Assessment Details'
                case "attendance":
                    return 'Attendance Details'
                case 'incidentType':
                case 'incidentResponse':
                    return 'Incident Details'
                case "tag":
                    return 'Tag Details'
                case "demographic":
                    return 'Demographic Details'
                default:
                    return 'Details'
            }
        },

        availableChartTypes() {
            let chartTypes = this.chartTypes

            switch (this.config.type) {
                case "incidentType":
                case "incidentResponse":
                    return chartTypes.base.concat(chartTypes.incident, chartTypes.list)
                case "assessment":
                    return chartTypes.base.concat(chartTypes.assessment, chartTypes.list)
                default:
                    return chartTypes.base.concat(chartTypes.list)
            }
        },

        localConfig() {
            let config = Object.assign({}, this.clickedParams ? this.clickedParams : this.config)
            delete config.goal
            config.group_by = this.selected
            config.visualization = 'column'
            config.stacked = false
            config.config = {stacked: false}

            return config
        },

        gridConfig() {
            let model = this.config.type + 'Dash'
            let cfg = this.$_.cloneDeep(this.$models[model])
            cfg.read.rootProperty = 'data'

            return cfg
        },

        columnDefs() {
            let columnDefs = [
                {headerName: 'ID', field: 'student_id', hide: true},
                {headerName: 'Student', field: 'student_full_name', sortable: true}
            ]
            switch (this.config.type) {
                case 'assessment':
                    columnDefs.push(
                        {headerName: 'School', field: 'school_name', hide: true},
                        {headerName: 'Grade', field: 'grade_desc', hide: true},
                        {headerName: 'Score', field: 'score', type: "numericColumn", sortable: true},
                        {headerName: 'Ethnicity', field: 'ethnicity', hide: true}
                    )
                    break;
                case 'attendance':
                    columnDefs.push(
                        {headerName: 'School', field: 'school_name', hide: true},
                        {headerName: 'Grade', field: 'grade_desc', hide: true},
                        {headerName: 'Ethnicity', field: 'ethnicity', hide: true}
                    )

                    if (this.students.list.length) {
                        let example = this.students.list[0]
                        if (example.hasOwnProperty('full_rate')) {
                            columnDefs.push({
                                headerName: 'Full Day Rate',
                                field: 'full_rate',
                                type: "numericColumn",
                                valueGetter(v) {
                                    return parseFloat(v.data.full_rate)
                                },
                                sortable: true
                            })
                        }
                        if (example.hasOwnProperty('half_full_rate')) {
                            columnDefs.push({
                                headerName: 'Half/Full Day Rate',
                                field: 'half_full_rate',
                                type: "numericColumn",
                                valueGetter(v) {
                                    return parseFloat(v.data.half_full_rate)
                                },
                                sortable: true
                            })
                        }
                    }
                    break;
                case 'tag':
                case 'demographic':
                    columnDefs.push(
                        {headerName: 'School', field: 'school_name', sortable: true},
                        {headerName: 'Grade', field: 'grade_desc', sortable: true},
                        {headerName: 'Ethnicity', field: 'ethnicity', hide: true}
                    )
                    break;
                case 'incidentType':
                case 'incidentResponse':
                    columnDefs.push(
                        {headerName: 'School', field: 'school_name', sortable: true},
                        {headerName: 'Grade', field: 'grade_desc', sortable: true},
                        {headerName: 'Incidents', field: 'incidents', hide: true},
                        {headerName: 'Ethnicity', field: 'ethnicity', hide: true}
                    )
            }

            return columnDefs
        },

        studentCount() {
            let statement = 'Students: ' + this.students.list.length

            if (this.students.restrictedCount > 0) {
                statement += ' / ' + this.students.restrictedCount + ' <span style="opacity: .8;">(Your list is limited due to permissions)</span>'
            }

            return statement
        },

        readParams() {
            let params = Object.assign({
                drilldown: 1,
                use_cache: 1
            }, this.localConfig)

            params.param_keys = params.param_keys ? params.param_keys.join(',') : ''
            params.cohort_school_year_id = this.dashboard.school_year_id
            return params
        }
    },

    watch: {
        selected(v) {
            if (v !== 'student') {
                this.clickedParams = undefined
            } else {
                setTimeout(() => {
                    this.$refs.grid.resize()
                }, 500)
            }
        }
    },

    mounted() {
        /** Chart was loading twice. This prevents that. If you figure out why and can fix it there, remove this **/
        setTimeout(() => {
            this.ready = true
        }, 250)
    },

    methods: {
        doDrilldown(config, pointParams) {
            this.clickedParams = pointParams
            this.selected = 'student'
        },

        reset() {
            this.clickedParams = undefined
            this.$nextTick(() => {
                this.$refs.crud.read()
                    .then(() => {
                        this.$refs.grid.resize()
                    })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.student-count {
    margin-top: 10px;
    font-size: 12px;
}
</style>
