let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}
    
export default {
    category: {
        defaults: {
            endpoint: 'crud.php?property=Category',
            rootProperty: 'category'
        },
        ...standardCrud
    },
    assessment: {
        rules: [
            r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' }
        ],
        defaults: {
            endpoint: 'dataPointType.php',
            rootProperty: null
        },
        read: {
            params: { action: 'get' }
        },
        create: {
            params: { action: 'create' },
            rootProperty: 'types'
        },
        update: {
            params: { action: 'update' },
            rootProperty: 'types'
        },
        destroy: {
            params: { action: 'delete' },
            rootProperty: 'types'
        }
    }
}