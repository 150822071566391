let standardCrud = {
    read: {
        params: { action: 'get' }
    },
    create: {
        params: { action: 'create' }
    },
    update: {
        params: { action: 'update' }
    },
    destroy: {
        params: { action: 'delete' }
    }
}

export default {
    dashboards: {
        defaults: {
            endpoint: 'crud.php?property=KpiDashboard',
            rootProperty: 'kpi_dashboard'
        },
        clone: {
            endpoint: 'kpiDashboard.php',
            params: { action: 'clone' }
        },
        ...standardCrud
    },
    visualizations: {
        defaults: {
            endpoint: 'crud.php?property=KpiConfig',
            rootProperty: 'kpi_config'
        },
        ...standardCrud
    },
    dataPointType: {
        defaults: {
            endpoint: 'dataPointType.php?hidden=0&property=assessments',
            rootProperty: ''
        },
        ...standardCrud
    },
    subCategory: {
        defaults: {
            endpoint: 'subcategories.php',
            rootProperty: ''
        },
        ...standardCrud
    },
    dashboardUsers: {
        defaults: {
            endpoint: 'crud.php?property=KpiDashboardUser',
            rootProperty: 'kpi_dashboard_user'
        },
        ...standardCrud
    },
    dashGoals: {
        defaults: {
            endpoint: 'crud.php?property=Goal',
            rootProperty: 'goal'
        },
        ...standardCrud
    },
    assessmentDash: {
        defaults: {
            endpoint: 'targetScores.php?property=dashboard'
        },
        ...standardCrud
    },
    attendanceDash: {
        defaults: {
            endpoint: 'attendance.php?property=dashboard'
        },
        ...standardCrud
    },
    demographicDash: {
        defaults: {
            endpoint: 'demographics.php?property=dashboard'
        },
        ...standardCrud
    },
    incidentTypeDash: {
        defaults: {
            endpoint: 'behaviorIncident.php?property=dashboard'
        },
        ...standardCrud
    },
    incidentResponseDash: {
        defaults: {
            endpoint: 'behaviorIncident.php?property=dashboard'
        },
        ...standardCrud
    },
    tagDash: {
        defaults: {
            endpoint: 'tags.php?property=dashboard'
        },
        ...standardCrud
    },
    dashObjective: {
        defaults: {
            endpoint: 'crud.php?property=ActionPlanObjective'
        },
        ...standardCrud
    },
    apObjectiveGoal: {
        defaults: {
            endpoint: 'crud.php?property=ActionPlanObjectiveGoal'
        },
        ...standardCrud
    },
    goalTask: {
        defaults: {
            endpoint: 'crud.php?property=GoalTask'
        },
        ...standardCrud
    },
    googleSheets: {
        ...standardCrud,
        read: {
            endpoint: '/api/core/googlesheet/'
        }
    },
    objectiveGoals: {
        defaults: {
            endpoint: 'crud.php?property=GoalV',
            rootProperty: 'goal'
        },
        ...standardCrud
    }
}
