<template>
    <div class="d-flex flex-fill flex-column" style="background: #F5F6F7;">
        <div class="d-flex">
            <div v-if="isSavedSearch">
                <fe-btn usage="ghost" useIcon="fas fa-redo" @click="editSearch">Edit Search</fe-btn>
                <fe-btn v-if="localIsDirty" usage="ghost" useIcon="fas fa-save" @click="saveSearch">Save Search</fe-btn>
            </div>

            <div v-else>
                <fe-btn usage="ghost" useIcon="fas fa-redo" @click="editSearch">Edit Search</fe-btn>
                <fe-btn v-if="localIsDirty" usage="ghost" useIcon="fas fa-save" @click="saveSearch">Save Search</fe-btn>
            </div>

            <div>
                <slot name="toolbar"/>
            </div>
        </div>

        <div class="mx-5 mb-5 chart-container flex-grow-1 fill-height" style="position: relative;">
            <div ref="componentContainer" style="position: absolute; width: 100%; height: 100%;">
                <div class="d-block" v-if="component === 'highcharts'">
                    <div class="d-flex justify-end">
                        <fe-icon-btn useIcon="fas fa-thumbtack" @click="pinDialog.title = ''; pinDialog.show = true"/>

                        <menu-btn ref="menuBtn" class="pa-3">
                            <v-list-item @click="doLegend">
                                <i class="far fa-minus-circle fe-icon minus-icon"></i>
                                <span class="ml-6">{{ showLegend ? 'Hide' : 'Show' }} Legend</span>
                            </v-list-item>

                            <v-list-item @click="print">
                                <i class="far fa-print fe-icon print-icon"></i>
                                <span class="ml-6">Print Chart</span>
                            </v-list-item>
                        </menu-btn>
                    </div>
                </div>
                <component
                    v-if="isContainerReady"
                    :is="component"
                    ref="embeddedComponent"
                    v-bind="componentAttrs"
                    v-on="events"
                />
            </div>
        </div>

        <fe-dialog
            v-if="saveDialog.show"
            title="Save this Search"
            width=600
            acceptButtonText="Save"
            @accept="doSave"
            @dismiss="saveDialog.show=false"
            :acceptButtonDisabled="!saveDialog.name"
        >
            <fe-label>Name for Saved Search</fe-label>
            <v-text-field flat solo dense v-model="saveDialog.name"/>

            <fe-switch
                v-if="localSearchId"
                v-model="isSaveAsCopySelected"
                label="Create a new copy of this Saved Search"
            />
        </fe-dialog>

        <v-dialog v-if="editDialog.show" v-model="editDialog.show" width="90%" persistent>
            <district-stepper
                v-if="analyticComponent == 'charting' && analyticScope == 'district'"
                @close="editDialog.show = false"
                @apply="doBenchmarkChart(...arguments, 'District')"
                :state="editDialogState"
                :value="savedSearchParamsByDomain"
                surfaceFirstSelections
                :includeAttendance="includeAttendance"
            />
            <grade-stepper
                v-if="analyticComponent == 'charting' && analyticScope == 'grade'"
                @close="editDialog.show = false"
                @apply="doBenchmarkChart(...arguments, 'Grade')"
                :state="editDialogState"
                :value="savedSearchParamsByDomain"
                surfaceFirstSelections
                :includeAttendance="includeAttendance"
                cohort
            />
            <course-stepper
                v-if="analyticComponent == 'charting' && analyticScope == 'class'"
                @close="editDialog.show = false"
                @apply="doBenchmarkChart(...arguments, 'Class')"
                :state="editDialogState"
                :value="savedSearchParamsByDomain"
                surfaceFirstSelections
                :includeAttendance="includeAttendance"
            />
            <program-evaluation-stepper
                v-if="analyticComponent == 'program-evaluation'"
                @close="editDialog.show = false"
                :state="editDialogState"
                :value="savedSearchParamsByDomain"
                surfaceFirstSelections
            />
            <grade-stepper
                v-if="analyticComponent == 'data-wall' && analyticScope == 'grade'"
                @close="editDialog.show = false"
                @apply="doDataWall(...arguments, 'Grade Data Wall', 'Grade')"
                :state="editDialogState"
                :value="savedSearchParamsByDomain"
                surfaceFirstSelections
                :includeAttendance="includeAttendance"
                incidentTypeOnly
                requireSubcategory
                multipleGrades
                cohort
            />
            <course-stepper
                v-if="analyticComponent == 'data-wall' && analyticScope == 'class'"
                @close="editDialog.show = false"
                @apply="doDataWall(...arguments, 'Class Data Wall', 'Class')"
                :state="editDialogState"
                :value="savedSearchParamsByDomain"
                surfaceFirstSelections
                :includeAttendance="includeAttendance"
                incidentTypeOnly
                requireSubcategory
            />
            <scatter-landing
                v-if="analyticComponent == 'scatter-plot'"
                @close="editDialog.show = false"
                :state="editDialogState"
                :value="savedSearchParamsByDomain"
                surfaceFirstSelections
                :configJSON="configJSON"
                :includeAttendance="includeAttendance"
            />
            <longitudinal-landing
                v-if="analyticComponent == 'longitudinal'"
                @close="editDialog.show = false"
                :state="editDialogState"
                :value="savedSearchParamsByDomain"
                surfaceFirstSelections
                :configJSON="configJSON"
            />
        </v-dialog>

        <pin-dialog v-model="pinDialog.show" :pinParams="pinParams" :title="pinDialog.title" analytics/>
    </div>
</template>

<script>
import {mapState} from 'vuex'

import * as Util from './Util'
import Charting from '@/components/modules/summary/Charting'
import DataWall from '@/components/modules/datawall/Index'
import ScatterPlot from '@/components/modules/scatterplot/'
import ProgramEvaluation from '@/components/modules/programevaluation/ProgramEvaluation'
import MenuBtn from '@/components/common/button/MenuBtn'
import PinDialog from '@/components/common/PinDialog'
import DistrictStepper from './DistrictStepper'
import GradeStepper from './GradeStepper'
import CourseStepper from './CourseStepper'
import ScatterLanding from './ScatterLanding'
import ProgramEvaluationStepper from './ProgramEvaluationStepper'
import LongitudinalLanding from './LongitudinalLanding'

export default {
    name: 'SaveSearchContainer',

    components: {
        Charting,
        DataWall,
        ScatterPlot,
        ProgramEvaluation,
        MenuBtn,
        PinDialog,
        DistrictStepper,
        GradeStepper,
        CourseStepper,
        ScatterLanding,
        LongitudinalLanding,
        ProgramEvaluationStepper,
    },

    props: {
        windowUUID: {
            type: String,
            required: false
        },
        isSavedSearch: {
            type: Boolean,
            required: false
        },
        searchId: {
            type: Number,
            required: false
        },
        searchName: {
            type: String,
            required: false
        },
        isDirty: {
            type: Boolean,
            required: false
        },
        savedSearchType: {
            type: String,
            default: 'BENCHMARK'
        },
        // Saved search params can be grouped by "type," e.g. population, assessment,
        // incident, etc.  Each param group is saved as a unique saved search, This allows us to selectively feed a subset of the
        savedSearchParams: {
            type: [Object, Array],
            required: true
        },
        // These must be provided when editing an existing analytic saved search
        // It is a collection of multiple saved searches (population data, assessment data,
        // incident data, etc.) that collectively describe the original analytic search
        savedSearchParamsByDomain: {
            type: [Object, Array],
            required: false
        },
        configJSON: {},
        component: {
            required: true
        },
        attrs: {
            required: true
        },
        events: {}
    },

    data() {
        return {
            isContainerReady: false,
            localSavedQuery: null,
            localSearchId: null,
            localSearchName: null,
            localIsDirty: null,
            saveDialog: {
                show: false,
                name: ''
            },
            pinDialog: {
                show: false,
                title: ''
            },
            showLegend: true,
            isSaveAsCopySelected: false,
            editDialog: {
                show: false,
            },
        }
    },

    computed: {
        ...mapState('global', ['sessionUser']),
        componentAttrs() {
            if (this.component == 'highcharts') {
                let height = this.$refs.componentContainer?.offsetHeight - 60 || 400

                return {
                    ...this.attrs,
                    options: {
                        ...this.attrs.options,
                        chart: {
                            ...this.attrs.options.chart,
                            height: height,
                        },
                        legend: this.showLegend ? {
                            enabled: true,
                            align: 'center',
                            verticalAlign: 'bottom',
                            shadow: false
                        } : {enabled: false}
                    }
                }
            } else if (this.component == 'scatter-plot') {
                return {
                    ...this.attrs,
                    chartHeight: (this.$refs.componentContainer?.offsetHeight || 400) - 100,
                }
            } else {
                return this.attrs
            }
        },
        pinParams() {
            let configText = this.$_.cloneDeep(this.componentAttrs)
            delete configText?.options?.chart?.height

            let params = {
                config_text: configText
            }
            if (this.component === 'highcharts') {
                params.dashboard_saved_search_type_id = 11
            }
            return params
        },
        canModify() {
            return (
                (this.localSavedQuery?.user_id === this.sessionUser.user.id) || // created by me, so i can edit it
                (!!this.localSavedQuery?.can_modify) // shared with me with ability to modify
            )
        },
        analyticComponent() {
            return this.configJSON?.component
        },
        analyticScope() {
            return this.configJSON?.scope?.toLowerCase()
        },
        includeAttendance() {
            return !!this.configJSON?.include_attendance
        },
        editDialogState() {
            return {
                windowUUID: this.windowUUID,
                savedSearchId: this.localSearchId,
                savedSearchName: this.localSearchName,
            }
        },
    },

    mounted() {
        this.localSearchId = this.searchId
        this.localSearchName = this.searchName
        this.localIsDirty = this.isDirty

        // Don't mount the actual charting component until we know the parent
        // component has been added to DOM, so that we can get its offsetHeight
        this.$nextTick(() => {
            this.isContainerReady = true
        })
    },

    methods: {
        resizeCharts() {
            this.$nextTick(() => {
                this.$refs.embeddedComponent?.chart?.reflow()
            })
        },
        saveSearch() {
            if (this.localSearchId) {
                this.saveDialog = {
                    show: true,
                    name: this.localSearchName,
                }
            } else {
                this.saveDialog = {
                    show: true,
                    name: ''
                }
            }
        },
        editSearch() {
            this.editDialog.show = true
            this.isSaveAsCopySelected = false
        },
        deleteSearch() {
            this.$confirmDelete([1], () => {
                this.$axios.post('analyticSavedSearch.php?action=destroy&property=query', {
                    queries: [{id: this.localSearchId}]
                }).then(r => {
                    this.$ecResponse(r)
                    this.$emit('deletedSavedSearch', this.localSearchId)
                    this.$emit('close')
                })
            }, () => {
            })
        },
        doLegend() {
            this.showLegend = !this.showLegend
        },
        print() {
            this.$refs.embeddedComponent?.chart?.print()
        },
        doBenchmarkChart(opts, state, scope) {
            Util.doBenchmarkChart(this, opts, scope, null, {...state, isDirty: true})
            this.editDialog.show = false
        },
        doDataWall(opts, state, title, scope) {
            Util.doDataWall(this, opts, title, scope, null, {...state, isDirty: true})
            this.editDialog.show = false
        },
        doSave() {
            let me = this
            let p = []

            if (Array.isArray(this.savedSearchParams)) {
                p = this.$_.cloneDeep(this.savedSearchParams)
            } else {
                p = [{...this.savedSearchParams}]
            }

            p.forEach(r => {
                Object.keys(r).forEach(f => {
                    if (!r[f] || (Array.isArray(r[f]) && r[f].length == 0)) delete r[f]
                })
            })

            let rec = {
                analytic_saved_search_type_code: this.savedSearchType,
                config_json: JSON.stringify(this.configJSON),
                name: this.saveDialog.name,
                saved_params: p,
            }

            if (!this.localSearchId || this.isSaveAsCopySelected) {
                this.$axios.post('analyticSavedSearch.php?action=create&property=query', {
                    queries: [rec]
                }).then(r => {
                    let data = this.$ecResponse(r)
                    if (data) {
                        this.localSearchId = data.queries[0].id
                        this.localSearchName = rec.name
                        this.localIsDirty = false

                        this.$store.commit('global/updateDockableWindow', {
                            id: this.$store.getters['global/dockableWindows'].find(w => w.uuid === me.windowUUID)?.dockedWindowId,
                            obj: {
                                name: this.saveDialog.name
                            }
                        })

                        this.$store.commit('global/ping', 'analyticSavedSearches')
                    }
                })
            } else {
                this.$axios.post('analyticSavedSearch.php?action=update&property=query', {
                    queries: [{...rec, id: this.localSearchId}]
                }).then(r => {
                    this.$ecResponse(r)
                    this.localIsDirty = false
                    this.localSearchName = rec.name

                    this.$store.commit('global/updateDockableWindow', {
                        id: this.$store.getters['global/dockableWindows'].find(w => w.uuid === me.windowUUID)?.dockedWindowId,
                        obj: {
                            name: rec.name
                        }
                    })

                    this.$store.commit('global/ping', 'analyticSavedSearches')
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.chart-container {
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    height: 100%;
    background: white;
    overflow: hidden;
}
</style>
