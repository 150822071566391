<template>
    <div class="flex-fill flex-column no-scroll">
        <div class="headline" v-if="!addTagOnly && !hideTitle">User Tags</div>

        <fe-grid style="height:100%"
            ref="grid"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :showDownload="false"
            :showAddRowBtn="$hasSecurity('MANAGE_PUBLIC_TAGS')"
            addRowBtnText="Add Tag"
            displayDensity="medium"
            disableInlineCreate
            @beginCreate="editRec(null)"
            v-if="!addTagOnly"
        >
        <template #after-search-toolbar-items>
            <fe-remote-combo
                style="maxWidth:140px; margin-left:8px;"
                v-model="school_year"
                @input="loadTags"
                rootProperty="years"
                url="qry.php?action=get&property=school_years"
                :clearable="false"
            ></fe-remote-combo>
        </template>
            <template #cellDialog="{cell}">
                <simple-grid-dialog-column v-if="cell.colDef.cellRendererFramework=='FeDialogColumn'"
                    :readOnly="!$hasSecurity('MANAGE_PUBLIC_TAGS')"
                    :cell="cell"
                    :title="cell.data.name"
                    :deleteTool="1"
                    :field="dialogColumnDefs[cell.colDef.field].column1.field"
                    :fieldHeader="dialogColumnDefs[cell.colDef.field].column1.headerName"
                    :columnDefs="dialogColumnDefs[cell.colDef.field].columnDefs && dialogColumnDefs[cell.colDef.field].columnDefs"
                    :crudConfig="dialogColumnDefs[cell.colDef.field].model"
                    :fieldConfig="dialogColumnDefs[cell.colDef.field].fieldConfig"
                    :buildInsertRec="dialogColumnDefs[cell.colDef.field].buildRec"
                    :width="dialogColumnDefs[cell.colDef.field].width"
                    :height="dialogColumnDefs[cell.colDef.field].height"
                    :insertDefaults="cell.data"
                    :readParams="{tag_id:cell.data.id, school_year_id:school_year.id}"
                    @close="$refs.grid.openMenu = false"
                    @created="onCreated(cell)"
                    @destroyed="onDestroyed(cell)"
                />
                <v-list v-else>
                    <v-list-item @click="editRec(cell.data)" :disabled="!cell.data.can_edit">Edit Tag</v-list-item>
                    <v-list-item @click="deleteRec(cell.data)" :disabled="!cell.data.can_edit">Delete Tag</v-list-item>
                </v-list>
            </template>
        </fe-grid>

        <fe-crud
            ref="crud"
            :config="$models.tag"
            @read="tags = $event"
        />

        <record-dialog v-if="showAddDialog"
            title="User Tag"
            :value="tagRec"
            :defaultRec="{
                name: null,
                description: null,
                color: '#006C90',
                public_flag: 1,
                kpi: 0,
            }"
            @create="createRec" @update="updateRec"
            @close="showAddDialog=false"
        >
            <template #form="{rec}">
                <fe-label >Name</fe-label>
                <v-text-field autofocus flat solo dense
                    v-model="rec.name"
                    :rules="$fieldValidators('text', null, {required:true, limit:50})"
                />

                <fe-label>Description</fe-label>
                <v-textarea flat solo dense
                    v-model="rec.description"
                    :counter="350"
                    :rules="$fieldValidators('text', null, {limit:350})"
                />

                <div class="d-flex justify-space-between mb-2">
                    <fe-label>Color</fe-label>
                    <fe-color-selector v-model="rec.color"></fe-color-selector>
                </div>

            </template>
        </record-dialog>

    </div>
</template>

<script>
import SimpleGridDialogColumn from "@/components/common/SimpleGridDialogColumn"
import RecordDialog from '@/components/common/form/RecordDialog'

export default {
    name: 'UserTags',
    components: {
        SimpleGridDialogColumn,
        RecordDialog,
    },
    props: {
        addTagOnly: { type: Boolean, default: false },
        hideTitle: { type: Boolean, default: false }
    },
    computed: {
        currentYear() {
            return this.$store.state.global.sessionUser.currentYear
        },
        rowData() {
            return this.tags.filter(x => x.public_flag)
        },
    },
    created() {
        this.school_year = this.currentYear
    },
    mounted() {
        if(this.addTagOnly) {
            this.showAddDialog = true
        } else {
            this.$refs.grid.gridOptions.deltaRowDataMode = true
            this.$refs.grid.gridOptions.getRowNodeId = v => {
                return v.id
            }
        }
        this.loadTags()
    },
    methods: {
        onDestroyed(cell) {
            let v = cell.data[cell.colDef.field]
            cell.node.setDataValue(cell.colDef.field,--v)
        },
        onCreated(cell) {
            let v = cell.data[cell.colDef.field] || 0
            cell.node.setDataValue(cell.colDef.field,++v)
        },
        onCellClicked(v) {
            this.$refs.grid.setDialogCell(v)
        },
        loadTags() {
            return this.$refs.crud.read({
                property: 'composite',
                school_year_id: this.school_year.id,
            })
        },
        editRec(v) {
            this.tagRec = v
            this.showAddDialog = true
        },
        createRec(v) {
            this.$refs.crud.create(v).then( r => {
                this.loadTags().then( r => {
                    this.showAddDialog=false

                    // find last id added
                    let index=0, row=this.rowData[0]
                    this.rowData.forEach((v,i) => {
                        if(v.id > row.id) { row = v; index = i }
                    })

                    this.$nextTick(() => {
                        if(!this.addTagOnly) {
                            this.$refs.grid.gridApi.ensureIndexVisible(index)
                            this.$flashGridRow(this.$refs.grid,index,3000)
                        }
                    })
                })
                this.$emit('countChanged')
            })
        },
        updateRec(v,e) {
            if(e && e.colDef.field.endsWith('_cnt')) return
            this.showAddDialog = false
            this.$refs.crud.update(v).finally(() => {
                this.loadTags().finally(() => this.$refs.grid.gridApi.redrawRows())
                this.$emit('countChanged')
            })
        },
        deleteRec(v) {
            this.$messageBox({
                title: 'Delete Tag',
                persistent: true,
                maxWidth: 400,
                message: 'Are you sure you want to delete this Tag? There may be students and users attached to it.  This action cannot be undone.',
                actions: [{
                    text: 'Cancel',
                }, {
                    text: 'Delete', usage: 'danger',
                    onClick: () => {
                        this.$refs.crud.destroy(v).then( r => {
                            this.tags = this.tags.filter( x => x.id !== v.id)
                            this.$emit('countChanged')
                        })
                    }
                }]
            })
        },
        renderWithSubField(v) {
            let locked = !this.$hasSecurity('MANAGE_PUBLIC_TAGS')
                ? '<i style="font-size:12px;" data-tip="You do not have permission to change users" class="ec-simple-tip fe-grid-icon fas fa-lock ml-2 grey--text text--lighten-1"></i>'
                : ''
            return v.data.description
                ? `<div style="line-height:30px;">${v.value}${locked}<div class="fe-grid-cell-subtext">${v.data.description}</div></div>`
                : v.value
        },
    },
    data() {
        return {
            showAddDialog: null,
            school_year: null,
            tagRec: null,
            tags: [],
            columnDefs: [
                {
                    headerName: 'Tag Name',
                    field: 'name',
                    cellRenderer: this.renderWithSubField,
                    autoHeight: true,
                }, {
                    headerName: 'Users',
                    field: 'user_cnt',
                    maxWidth: 100,
                    cellRendererFramework: 'FeDialogColumn',
                    onCellClicked: this.onCellClicked,
                }, {
                    headerName: 'Color',
                    field: 'color',
                    maxWidth: 80,
                    cellStyle: {'text-align':'center'},
                    cellRenderer: v => {
                        return `<i class="fe-grid-icon fas fa-square-full" style="color:${v.data.color};"></i>`
                    },
                }, {
                    colId: 'toolmenu',
                    maxWidth: 50,
                    cellRenderer: v => {
                        return '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>'
                    },
                    onCellClicked: this.onCellClicked,
                }
                // { headerName: 'Public', field: 'public_flag', cellRendererFramework: 'FeGridToggle', maxWidth:80 },
                // { headerName: 'KPI', field: 'kpi', cellRendererFramework: 'FeGridToggle', maxWidth:80 },
            ],
            dialogColumnDefs: {
                user_cnt: {
                    model: this.$models.userTagV,
                    column1: {headerName: 'Name', field: 'user_full_name'},
                    fieldConfig: {
                        type:'combo', url:'users.php?action=get&active=1', itemText:'user_full_name',
                        filter: function(x) {
                            if(!this.index) {
                                let index = {}
                                this.forEach(y => index[y.user_id]=true)
                                Object.defineProperty(this,'index',{value:index})
                            }
                            return !this.index[x.id]
                        }
                     },
                    buildRec: (v,d) => {
                        this.$emit('countChanged')
                        return {user_id: v.id, tag_id: d.id, school_year_id:this.school_year.id}
                    },
                },
            },

        }
    },
    watch: {
        showAddDialog(v) {
            if(!v) {
                this.$emit('closeAddDialog')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
