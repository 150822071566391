<template>
    <v-layout column style="overflow: hidden;">
        <div class="page-title">Motivations</div>
        <fe-dialog
            title="Add Incident Motivation"
            v-if="createDialog.show"
            @dismiss="createDialog.show=false"
            @close="createDialog.show=false"
            acceptButtonText="Save"
            @accept="addMotivation({ name: createDialog.name })"
            :acceptButtonDisabled="!createDialog.name"
            persistent
            dismissButtonText="Cancel"
        >
            <v-form>
                <v-text-field v-model="createDialog.name" flat solo dense placeholder="Enter a name"/>
            </v-form>
        </fe-dialog>

        <fe-dialog
            title="Merge Motivation"
            v-if="mergeDialog.show"
            @dismiss="mergeDialog.show=false"
            @close="mergeDialog.show=false"
            acceptButtonText="Merge"
            @accept="finishMerge"
            :acceptButtonDisabled="!mergeDialog.record"
            persistent
            dismissButtonText="Cancel"
        >
            <v-form>
                <fe-remote-combo
                    label="Select a Motivation"
                    :items="mergeItems"
                    v-model="mergeDialog.record"
                    flat solo dense
                    placeholder="Select a motivation"
                    byId
                />
            </v-form>
        </fe-dialog>

        <fe-crud-grid
            :config="$models.incidentMotivation"
            autoload
            @read="v => items = v"
            :columnDefs="columnDefs"
            :rowData="items"
            style="height: 100%;"
            @selectionChanged="doSelect"
            ref="grid"
            :showAddRowBtn="false"
            :showFullscreen="false"
            @close-dialog="cellDialogClosed"
            displayDensity="medium"
        >
            <template v-slot:toolbar-items>
                <fe-btn usage="primary" @click="createDialog.name = ''; createDialog.show = true">Add Motivation</fe-btn>
            </template>

            <template v-slot:selection-tools>
                <fe-btn useIcon="fas fa-code-merge" usage="ghost" dark @click="showMergeDialog">Merge</fe-btn>
                <fe-btn useIcon="fal fa-trash" usage="ghost" dark @click="deleteSelected">Delete</fe-btn>
                <fe-btn usage="ghost" dark @click="gridApi.deselectAll()">Cancel</fe-btn>
            </template>
        </fe-crud-grid>
    </v-layout>


</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'IncidentMotivation',
        computed: {
            mergeItems() {
                let ids = this.$_.map(this.selections, 'id')
                return this.items.filter(r => ids.indexOf(r.id) === -1)
            }
        },
        data() {
            let me = this
            return {
                gridApi: null,
                createDialog: {
                    show: false,
                    name: null
                },
                mergeDialog: {
                    show: false,
                    record: null
                },
                columnDefs: [
                    this.$grid.checkColumn(),
                    {
                        headerName: "Motivation",
                        field: "name",
                        sortable: true,
                        editable: true,
                        flex: 2,
                    }, this.$grid.toggleColumn('Active', 'active', null, () => {}), {
                        headerName: 'Created',
                        flex: 1,
                        field: 'created'
                    }
                ],
                items: [],
                selections: []
            }
        },
        methods: {
            doSelect(item) {
                this.$data.gridApi = item.api
                this.selections = item.api.getSelectedRows()
            },
            showMergeDialog() {
                this.mergeDialog.show = true
                this.mergeDialog.record = null
            },
            finishMerge() {
                this.selections.forEach(sel => sel.merge_to_id = this.mergeDialog.record)

                this.$refs.grid.$refs.crud.update(this.selections).then(() => {
                    this.$refs.grid.$refs.crud.read()
                })
            },
            deleteSelected() {

                this.$confirmDelete(this.selections, () => {
                    this.$refs.grid.$refs.crud.destroy(this.selections).then(() => {
                        this.$refs.grid.$refs.crud.read()
                    })
                })
            },
            cellDialogClosed() {

            },
            addMotivation() {
                if (!this.createDialog.name) return

                this.$refs.grid.$refs.crud.create({
                    name: this.createDialog.name,
                    active: 1,
                    description: ''
                }).then(response => {

                })
                .finally(() => {
                    this.$refs.grid.$refs.crud.read()
                })
            },
            saveRecord(v) {
                if (v.data.name) {
                    this.$refs.grid.$refs.crud.update(v.data)
                        .catch(error => {
                            console.warn('error', error)
                        })
                        .finally(() => {
                            this.$refs.grid.$refs.crud.read()
                        })
                } else {
                    this.$refs.grid.$refs.crud.read()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
