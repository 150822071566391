<template>
    <fe-dialog
        v-if="value"
        v-intersect="load"
        title="Manage Edit Access"
        width="522"
        disableAutoclose
        noBodyPadding
        :forceClose="false"
        @close="close"
        @dismiss="close"
    >
        <div class="flex-column px-4">
            <div class="d-flex ml-2 mt-2">
                <fe-label>
                    {{  template.restricted
                        ? 'Restrict edit access of this smartFORM template to these users'
                        : 'Restrict edit access of this smartFORM template'
                    }}

                </fe-label>
                <fe-switch class="ml-auto" :value="template.restricted" @change="restrict" useIcon="check"/>
            </div>

            <div v-if="template.restricted" class="item-wrapper mb-3 mx-2">
                <v-list-item v-for="user in alwaysAllowedUsers" class="item" dense>
                    <v-list-item-content>{{user.display_name}}</v-list-item-content>
                    <v-list-item-action>
                        <fe-tooltip :tooltip="user.lock_tip" direction="right">
                            <v-icon size="14" color="#979797">fa-lock</v-icon>
                        </fe-tooltip>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item v-for="formUser in allowedUsers" class="item" dense>
                    <v-list-item-content>{{formUser.user_full_name}}</v-list-item-content>
                    <v-list-item-action @click="remove(formUser)">
                        <v-icon size="14" color="#050F2D">fa-minus-circle</v-icon>
                    </v-list-item-action>
                </v-list-item>
            </div>

            <div v-if="template.restricted" class="d-flex ml-2">
                <fe-remote-combo
                    ref="userCombo"
                    class="flex-grow-1"
                    style="margin-top:6px;"
                    itemText="user_full_name"
                    itemValue="id"
                    itemSubtext="title"
                    placeholder="Select a user"
                    v-model="selectedUser"
                    :items="selectableUsers"
                />
                <fe-btn class="ml-auto" usage="tertiary" @click="add">Add</fe-btn>
            </div>
        </div>

        <template #footer>
            <div class="d-inline-flex ml-auto">
                <fe-btn @click="close">Close</fe-btn>
            </div>
        </template>

        <fe-crud
            ref="crudForm"
            :config="$models.form"
            :defaultParams="{form_id:templateId}"
            :autoload="false"
            @read="template = $event[0]"
        />

        <fe-crud
            ref="crudFormUserSecurity"
            :config="$models.formUserSecurity"
            :defaultParams="{form_id:templateId}"
            :autoload="false"
            @read="allowedUsers = $event"
        />
    </fe-dialog>
</template>

<script>
export default {
    name: 'AccessGrid',

    props: ['value','templateId'],
    data() {
        return {
            template: {},
            allowedUsers: [],
            selectedUser: null,
            usersWithAccess: [],
        }
    },
    computed: {
        selectableUsers() {
            return this.usersWithAccess.filter(x => {
                return !this.alwaysAllowedUsers.some(y => x.id == y.id)
            }).filter(x => {
                return !this.allowedUsers.some(y => x.id == y.user_id)
            })
        },
        alwaysAllowedUsers() {
            return this.usersWithAccess.filter(x => x.owner || x.admin)
            .map(x => {
                return {
                    ...x,
                    display_name: `${x.user_full_name} ${x.owner ? '(owner)' : ''}`,
                    lock_tip: x.owner
                        ? 'The owner of this smartFORM template will always have edit access to the template'
                        : 'This user has a special permission that allows them to edit restricted templates',
                }
            })
            .sort(x => x.owner ? -1 : 1)
        },
    },
    created() {
        Promise.resolve(this.userPool())
            .then((resp) => {
                this.usersWithAccess = resp
            })
    },
    methods: {
        load() {
            this.$refs.crudForm.read()
            this.$refs.crudFormUserSecurity.read()
        },
        restrict(e) {
            this.template.restricted = e
            this.$refs.crudForm.update(null,{
                property: 'restriction',
                restrict: e ? 1 : 0,
            })
        },
        add() {
            this.$refs.crudFormUserSecurity.create({
                form_id: this.template.id,
                user_id: this.selectedUser.id,
            }).then(this.$refs.crudFormUserSecurity.read)
            this.selectedUser = null
        },
        remove(formUser) {
            this.$refs.crudFormUserSecurity.destroy({
                id: formUser.id
            }).then(this.$refs.crudFormUserSecurity.read)
        },
        close() {
            this.$emit('input',false)
        },
        async userPool() {
            const resp = await this.$axios.get('form.php?property=access_users&action=get&form_id=' + this.templateId)
            return resp.data.users_with_access 
                ? resp.data.users_with_access.map(x => {
                    return {...x}
                })
                .sort((a,b) => a.user_full_name.localeCompare(b.user_full_name)) || []
                : []
        },
    }

}
</script>

<style lang="scss" scoped>
    .item-wrapper {
        border: 1px solid #DDDDDD;
        border-radius: 4px;
        max-height: 400px;
        overflow-y: scroll;
    }
    .item {
        border-bottom: 1px solid #DDDDDD;
    }
</style>
