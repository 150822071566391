<template>
    <div class="flex-fill flex-column no-scroll" style="position:relative; width: auto;" v-intersect="onShow">
        <div v-if="title" class="page-title">{{ title }}</div>

        <fe-grid
            :rowData="filteredRows"
            :style="{height: '100%'}"
            :columnDefs="headers"
            :showAddRowBtn="false"
            :showFullscreen="false"
            :showSaveAsDefault="false"
            :showToolbar="showToolbar"
            :emptyState="{
                text: emptyStateMessage,
                imageSrc: '/images/empty-state-horn.png',
                imageStyle: 'max-width:50%; max-height:50%;',
            }"
            displayDensity="large"
            @selectionChanged="doSelection"
            ref="grid"
            :bordered="false"
            :defaultColDefOverrides="{ autoHeight: true }"
        >
            <template #toolbar-items v-if="showToolbar">
                <fe-btn usage="primary" @click="onClickNew">New Observation</fe-btn>
            </template>

            <template #cellDialog="{cell}">
                <v-list>
                    <v-list-item @click="onDeleteObservation(cell.data)">Delete</v-list-item>
                </v-list>
            </template>
        </fe-grid>

        <fe-crud
            ref="crud"
            :config="$models.studentObservations"
            :defaultParams="{ student_id: studentId }"
            @read="items = $event"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import CreateObservation from '@/components/modules/observations/creation/Index'

    export default {
        name: 'ObservationsList',
        components: {
            CreateObservation,
        },
        props: {
            showToolbar: {
                type: Boolean,
                default: true
            },
            showFilters: {
                type: Boolean,
                default: false
            },
            showCreateButton: {
                type: Boolean,
                default: true
            },
            title: {
                type: String
            },
            studentId: {
                type: Number,
                required: true
            },
            schoolYearId: {
                type: Number,
                required: true
            },
            studentFirstName: {
                type: String,
                default: 'Student'
            },
        },
        computed: {
            ...mapState('global', ['sessionUser', 'uiSavedSearch']),
            filteredRows() {
                return this.items
            },
        },
        watch: {
        },
        data() {
            let me = this

            return {
                selectedRows: [],
                editing: false,
                selections: [],
                items: [],
                emptyStateMessage: 'No Observation found',
                headers: [
                    {
                        headerName: 'Observation Date',
                        field: 'created',
                        comparator: me.$grid.sort,
                        cellRenderer(cell) {
                            return `${me.$dayjs(cell.data.created).format('MM/DD/YYYY')}`
                        },
                    }, {
                        headerName: 'Edit Observation',
                        field: 'edit_observation',
                        sortable: false,
                        editable: false,
                        headerClass: 'header-center',
                        cellStyle: { color: '#006C90', 'text-align': 'center' },
                        cellRenderer(cell) {
                            return '<i class="fe-grid-icon far fa-edit theme--light" style="color: #006C90"></i> Edit'
                        },
                        onCellClicked(cell) {
                            me.onEditCellClicked(cell)
                        },
                    }, {
                        headerName: 'Observed By',
                        field: 'created_by_full_name',
                        width: 400,
                        comparator: me.$grid.sort,
                        cellStyle: {'white-space': 'normal', 'line-height': '22px !important'},
                        cellRenderer(cell) {
                            return `<b>${cell.data.created_by_full_name}</b><br/>Description:  ${me.$stripHtml(cell.data.description)}`
                        },
                    }, {
                        headerName: 'View Report',
                        field: 'view_report',
                        sortable: false,
                        editable: false,
                        headerClass: 'header-center',
                        cellStyle: { color: '#006C90', 'text-align': 'center' },
                        cellRenderer(cell) {
                            return '<i class="fe-grid-icon far fa-file theme--light" style="color: #006C90"></i> View'
                        },
                        onCellClicked(cell) {
                            me.onViewCellClicked(cell)
                        },
                    },
                ],
            }
        },
        mounted() {
            if (this.showToolbar) {
                this.headers.push({
                    maxWidth: 70,
                    cellRenderer: v => {
                        return '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>'
                    },
                    onCellClicked: v => this.$refs.grid.setDialogCell(v),
                })
            }

            this.loadData()
        },
        methods: {
            onShow(e) {
                e.some(x => x.isIntersecting) && setTimeout(this.loadData,500)
            },
            onClickNew() {
                let me = this
                let w = this.$dockableWindow({
                    component: 'ObservationCreator',
                    name: `${this.$dayjs().format('MM/DD/YYYY')} - Observation for ${this.studentFirstName}`,
                    parentStyle: {
                        height: 'calc(100% - 60px)' // remove 60px for ~the fe-window title height
                    },
                    attrs: {
                        style: {
                            height: '100%',
                        },
                        domLayout: 'normal',
                        studentId: this.studentId,
                        schoolYearId: this.schoolYearId,
                        isNew: true,
                    },
                    events: {
                        refresh() {
                            me.$emit('refresh')
                        },
                        close() {
                            me.$store.commit('global/removeDockedWindow', w)
                        }
                    },
                })

                this.$emit('closeDialog')
            },
            onDeleteObservation(rec) {
                this.$confirmDelete(
                    rec,
                    async () => {
                        await this.$refs.crud.destroy(rec)
                        await this.$refs.crud.read()
                        await this.$emit('refresh')
                    }
                )
            },
            doSelection() {
                this.selections = this.$refs.grid.gridApi.getSelectedRows()
            },
            unSelect(keep) {
                this.$refs.grid.gridApi.getSelectedNodes().map(n => {
                    if(!keep.find(rec => rec == n.data)) n.setSelected(false)
                })
            },
            async loadData() {
                this.$refs.crud.read()
            },
            onViewCellClicked(cell) {
                let host = this.$axios.defaults.baseURL
                window.open(`${host}observations.php?action=get&property=summary&id=${cell.data.id}&pdf=1`)
            },
            onEditCellClicked(cell) {
                let me = this
                let w = this.$dockableWindow({
                    component: 'ObservationCreator',
                    name: `${this.$dayjs().format('MM/DD/YYYY')} - Observation for ${this.studentFirstName}`,
                    parentStyle: {
                        height: 'calc(100% - 60px)' // remove 60px for ~the fe-window title height
                    },
                    attrs: {
                        style: {
                            height: '100%',
                        },
                        domLayout: 'normal',
                        studentId: this.studentId,
                        studentObservationId: cell.data.id,
                        schoolYearId: this.schoolYearId
                    },
                    events: {
                        refresh() {
                            me.$emit('refresh')
                        },
                        close() {
                            me.$store.commit('global/removeDockedWindow', w)
                        }
                    },
                })

                this.$emit('closeDialog')
            },
        }
    }
</script>

<style lang="scss" scoped>
::v-deep .header-center .ag-header-cell-text {
    margin: 0 auto;
}

.moveup ::v-deep button.v-btn {
    margin-top: 0;
}
.selection-toolbar {
    width: 100%;
    position: absolute;
    left: 0px;
    height: 48px;
    background: #66A7BC;
    color: white;
    z-index: 10;
    border-radius: 4px;
}

.form-list-title {
    padding-top: 7px;
}

.form_status_chip {
    background: #E0E1E6;
    border-radius: 12px;
}

.empty-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 100px;
    width: 100%;
    height: 400px;
    z-index: 1;
    // &-small-screen {
    //     width: calc(100% - 200px);
    // }
}
</style>
