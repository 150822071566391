<template>
    <span feId="GridTooltip">
        <span v-if="!menu">
            <v-tooltip
                :bottom="direction === 'bottom'"
                :top="direction === 'top'"
                :left="direction === 'left'"
                :right="direction === 'right'"
                :disabled="disabled"
                color="#050F2D"
                open-delay="0"
                close-delay="0"
                :content-class="checkDirection"
                transition="none"
                max-width="220"
                v-bind="$attrs">
                <template v-slot:activator="{ on }">
                    <span v-on="on"><slot /></span>
                </template>
                <slot name="tooltip"><span>{{ tooltip }}</span></slot>
            </v-tooltip>
        </span>
        <span v-else>
            <fe-tooltip-menu v-bind="elProps" :direction="direction" @buttonClick="$emit('buttonClick')">
                <template v-slot:activator><slot /></template>
                <template v-slot:menu><slot name="menu"></slot></template>
            </fe-tooltip-menu>
        </span>
    </span>
</template>

<script>
export default {
    name: 'GridTooltip',

    props: {
        direction: { type: String, default: 'bottom' },
        tooltip: { type: String, default: 'This is a tooltip' },
        disabled: { type: Boolean, default: false },
        caret: { type: Boolean, default: false },
        menuOverridden: { type: Boolean, default: false }
    },

    computed: {
        elProps() { return Object.assign({}, this.defaultProps, this.$attrs) },
        items() { return 'items' in this.elProps },
        menu() { return !this.menuOverridden && this.items }, // if <fe-tooltip> has items prop, then it's a menu
        checkDirection() {
            if(this.caret) {
                if(this.direction === 'top') return 'tooltip-top'
                else if(this.direction === 'right') return 'tooltip-right'
                else if(this.direction === 'left') return 'tooltip-left'
                else return 'tooltip-bottom'
            } else {
                return 'custom-tooltip'
            }
        }
    },

    data() {
        return {
            defaultProps: {}
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-tooltip {
    border-radius: 3px;
    font-size: 12px;
    padding: 5px 10px;
}

.tooltip-left, .tooltip-right, .tooltip-top, .tooltip-bottom {
    border-radius: 3px;
    font-size: 12px;
    line-height: 17px;
    padding: 10px;
}
.tooltip-left:before {
    right: -5px;
    /* calculate 50% width of the parent element minus width of the current element */
    top: calc(50% - 5px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent #050F2D;
    content: "";
    position: absolute;
    display: block;
}
.tooltip-right:before {
    left: -5px;
    /* calculate 50% width of the parent element minus width of the current element */
    bottom: calc(50% - 5px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-color: transparent #050F2D transparent transparent;
    content: "";
    position: absolute;
    display: block;
}
.tooltip-top:before {
    bottom: -5px;
    /* calculate 50% width of the parent element minus width of the current element */
    left: calc(50% - 5px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #050F2D transparent transparent transparent;
    content: "";
    position: absolute;
    display: block;
}
.tooltip-bottom:before {
    top: -5px;
    /* calculate 50% width of the parent element minus width of the current element */
    right: calc(50% - 5px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #050F2D transparent;
    content: "";
    position: absolute;
    display: block;
}
</style>
