<template>
    <fe-dialog
        dismissButtonText="Cancel"
        acceptButtonText="Add"
        :acceptButtonDisabled="!valid"
        :title="title"
        @accept="$emit('apply', saveData)"
        @dismiss="$emit('cancel')"
    >
        <v-form ref="form" v-model="valid">
            <v-layout row wrap>
                <v-flex xs12 px-1>
                    <StoreSelector
                        storeNamespace="thresholds/user"
                        label="User"
                        keyProp="id"
                        labelProp="__fullName"
                        :rules="userRules"
                        :selectedKeys="model.user_id"
                        @change="v => setAttr('user_id', v)"
                    />
                </v-flex>

                <v-flex xs12 px-1>
                    <StoreSelector
                        storeNamespace="thresholds/school"
                        label="School"
                        keyProp="id"
                        labelProp="name"
                        :selectedKeys="model.school"
                        multiple
                        @change="v => setAttr('school', v)"
                    />
                </v-flex>

                <v-flex xs12 px-1>
                    <StoreSelector
                        storeNamespace="thresholds/grade"
                        label="Grade"
                        :selectedKeys="model.grade"
                        keyProp="id"
                        labelProp="name"
                        multiple
                        @change="v => setAttr('grade', v)"
                    />
                </v-flex>

                <v-flex xs12 px-1>
                    <StoreSelector
                        storeNamespace="thresholds/tag"
                        label="Tag"
                        :selectedKeys="model.tag"
                        keyProp="id"
                        labelProp="name"
                        multiple
                        @change="v => setAttr('tag', v)"
                    />
                </v-flex>
            </v-layout>
        </v-form>
    </fe-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
import StoreSelector from '../controls/StoreSelector'

export default {
    name: 'NotifyEditor',

    components: {
        StoreSelector
    },

    props: {
        obj: Object
    },

    data() {
        return {
            active: true,
            valid: false,
            model: {
                email_flag: 1,
                grade: [],
                school: [],
                tag: [],
                user_id: null
            },
            userRules: [
                v => !!v || 'User is required',
            ]
        }
    },

    computed: {
        ...mapGetters('notify', [
            'collection',
            'clear',
            'pending',
            'success',
            'error'
        ]),

        saveData() {
            return Object.assign({}, this.model, {threshold_group_id: this.thresholdGroupId})
        },

        thresholdGroupId() {
            return this.$store.getters['thresholds/group/selected'].id
        },

        adding() {
            return !(this.obj && this.obj.id)
        },

        editing() {
            return !this.adding
        },

        title() {
            return `${this.adding ? 'Add' : 'Edit'} User Notification`
        }
    },

    mounted() {
        this.reset()
    },

    methods: {
        setAttr(attr, val) {
            this.model[attr] = val
            this.validate()
        },

        validate() {
            this.$refs.form.validate()
        },

        reset() {
            if (this.editing) {
                this.model = Object.assign({}, this.obj)
                this.validate()
            } else {
                this.model = {
                    email_flag: 1,
                    grade: [],
                    school: [],
                    tag: [],
                    user_id: null
                }
            }
        }
    }
}
</script>
