<template>
    <div class="flex-fill flex-column no-scroll" style="height: 400px;">
        <fe-crud-grid
            ref="grid"
            :autoload="true"
            :columnDefs="columnDefs"
            :config="$models.studentIds"
            :extraTools="this.hasSecurity"
            :readParams="{ school_year_id: this.updateStudent.school_year_id, id: this.updateStudent.student_id }"
            :rowData="studentIds"
            :showAddRowBtn="this.selections.length > 0 ? false : !this.hasSecurity ? false : true"
            :showEditbar="this.hasSecurity"
            @beginCreate="showAddDialog=true"
            @read="v => studentIds = v"
            @selectionChanged="setSelectedRows"
            addRowBtnText="Add ID"
            displayDensity="medium"
            disableInlineCreate
            refreshOnCreated
            refreshOnUpdated
            style="height: 100%;"
        >

            <!-- Hide until new slot is added to ec-frontend-library -->
            <template v-slot:extra-tools>
                <fe-btn
                    useIcon="fa-exchange-alt"
                    usage="ghost"
                    class="fe-grid-action-btn"
                    whiteText
                    @click="$emit('reassignBtnClick', $refs.grid.$refs.crud)"
                >
                    Reassign
                </fe-btn>
            </template>
        </fe-crud-grid>

        <add-id
            v-if="showAddDialog"
            :crud="$refs.grid.$refs.crud"
            :studentInfo="this.updateStudent"
            :dataPointTypesList="this.dataPointTypes"
            @close="showAddDialog=false"
        />
    </div>
</template>

<script>
import {mapLocalState} from '@/util/vuexHelper'
import AddId from './AddId'
import Toggle from '@/components/modules/smartforms/template/Toggle'

export default {
    name: 'StudentIdGrid',

    components: {
        AddId
    },

    data() {
        return {
            showAddDialog: false,
            studentIds: [],
            gridApi: null,
            uniqueFlags: {
                primary_flag: null,
                state_id_flag: null,
                district_id_flag: null
            }
        }
    },

    inject: ['localStore'],

    computed: {
        ...mapLocalState(['updateStudent', 'selections', 'showReassignModal', 'dataPointTypes', 'hasSecurity']),

        valuesLoaded() {
            return !!this.dataPointTypes && !!this.updateStudent
        },

        columnDefs() {
            let me = this

            let cols = [
                {
                    headerName: null,
                    headerCheckboxSelection: this.hasSecurity,
                    checkboxSelection: this.hasSecurity,
                    width: 70,
                    minWidth: 70,
                    hide: !this.hasSecurity,
                    colId: 'checkbox_column',
                }, {
                    headerName: "Internal ID",
                    field: "student_id",
                    sortable: true,
                    editable: false
                }, {
                    headerName: "ID Type",
                    field: "data_point_type_id",
                    sortable: true,
                    editable: false,
                    cellRenderer: v => me.findLabel(me.dataPointTypes, v.data.data_point_type_id, 'id', 'name')
                }, {
                    headerName: "Unique ID#",
                    field: "unique_id",
                    sortable: true,
                    editable: this.hasSecurity
                },
            ]

            if (this.$hasSecurity('SUBMIT_STATE_INCIDENTS')) {
                cols.push({
                    headerName: "State ID",
                    field: "state_id_flag",
                    sortable: false,
                    cellRendererFramework: Toggle,
                    cellRendererParams: me.cellRendererParams,
                    onCellValueChanged: true,
                    cellStyle: {"padding": 0, textAlign: 'center'}
                }, {
                    headerName: "District ID",
                    field: "district_id_flag",
                    sortable: false,
                    cellRendererFramework: Toggle,
                    cellRendererParams: me.cellRendererParams,
                    onCellValueChanged: true,
                    cellStyle: {"padding": 0, textAlign: 'center'}
                }, {
                    headerName: "Primary",
                    field: "primary_flag",
                    sortable: false,
                    cellRendererFramework: Toggle,
                    cellRendererParams: me.cellRendererParams,
                    onCellValueChanged: true,
                    cellStyle: {"padding": 0, textAlign: 'center'}
                })
            } else {
                cols.push({
                    headerName: "State ID",
                    field: "state_id_flag",
                    sortable: false,
                    cellRenderer(v) {
                        if (v.value) return '<i class="fas fa-check"></i>'
                    },
                    cellStyle: {"padding": 0, textAlign: 'center'}
                }, {
                    headerName: "District ID",
                    field: "district_id_flag",
                    sortable: false,
                    cellRenderer(v) {
                        if (v.value) return '<i class="fas fa-check"></i>'
                    },
                    cellStyle: {"padding": 0, textAlign: 'center'}
                }, {
                    headerName: "Primary",
                    field: "primary_flag",
                    sortable: false,
                    cellRenderer(v) {
                        if (v.value) return '<i class="fas fa-check"></i>'
                    },
                    cellStyle: {"padding": 0, textAlign: 'center'}
                })
            }

            cols.push({
                headerName: "Upload ID",
                field: "upload_id",
                sortable: true,
                editable: false
            })

            return !this.valuesLoaded ? [] : cols
        }
    },

    watch: {
        studentIds(v, old) {
            this.uniqueFlags = {
                primary_flag: null,
                state_id_flag: null,
                district_id_flag: null
            }

            v.forEach(x => {
                Object.keys(this.uniqueFlags).forEach(y => {
                    if (x[y]) {
                        this.uniqueFlags[y] = x.id
                    }
                })
            })

            if (old) {
                this.gridApi.redrawRows()
            }
        }
    },

    mounted() {
        this.gridApi = this.$refs.grid.$refs.grid.gridOptions.api
    },

    methods: {
        findLabel(collection, value, valueProp, labelProp) {
            let lbl = ''
            if (collection && value) {
                let sel = collection.find(x => x[valueProp] === value)
                if (sel) lbl = sel[labelProp]
            }
            return lbl
        },

        setSelectedRows(meta) {
            this.gridApi = meta.api
            this.selections = meta.api.getSelectedRows()
        },

        cellRendererParams(v) {
            let flag = v.colDef.field
            let disabled = false

            if (this.uniqueFlags[flag]) {
                disabled = this.uniqueFlags[flag] !== v.data.id
            }

            return {
                disabled,
                disabledMessage: 'Only one ID can be set as ' + v.colDef.headerName
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
