<template>
    <form-common-config
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #fieldSpecific="{rec}">
            <v-form ref="form">
                <div>
                    <fe-label :style="labelStyle">URL</fe-label>
                    <v-text-field
                        :rules="$fieldValidators('text', null, {required: true})" clearable
                        flat solo dense :value="rec.property" @change="rec.property = $event"></v-text-field>
                </div>
                <div>
                    <fe-label :style="labelStyle">Display Text</fe-label>
                    <v-text-field clearable flat solo dense :value="rec.display_value" @change="rec.display_value = $event" :rules="rules"></v-text-field>
                </div>
            </v-form>
        </template>
    </form-common-config>
</template>

<script>
    import FormCommonConfig from '../FormCommonConfig'

    export default {
        components: {
            FormCommonConfig,
        },
        computed: {
            labelStyle: me => ({ minWidth:'120px' }),
            rules: me => [
                v => (v && v.length) < 1000 || 'Field is too long',
                v => {
                    if(!v) return true
                    let openingTags, closingTags;

                    v = v.replace(/<[^>]*\/\s?>/g, '');         // Remove all self closing tags
                    v = v.replace(/<(br|hr|img).*?>/g, '');     // Remove all <br>, <hr>, and <img> tags
                    openingTags = v.match(/<[^\/].*?>/g) || []; // Get remaining opening tags
                    closingTags = v.match(/<\/.+?>/g) || [];    // Get remaining closing tags

                    return openingTags.length === closingTags.length ? true : 'Invalid html detected';
                }
            ],
        },
        mounted() {
            this.$refs.form.validate()
        },
    }
</script>

<style lang="scss" scoped>
</style>
