<template>
    <div ref="panel" class="panel" :class="{ hide: !value }" @click.stop>
        <component
            v-show="value"
            :is="componentType"
            :value="value"
            v-bind="$attrs"
            v-on="$listeners"
            @dataIdChange="dataIdChange"
            :duplicateFormDataIds="duplicateFormDataIds"
            :goToAdvanced="goToAdvanced"
            @resetAdvancedTab="resetAdvancedTab"
        ></component>
        <div v-show="!value" class="overlay">
        </div>
    </div>
</template>

<script>
import smartFormMixin from '../../smartFormMixin'
import FormSectionConfig from './FormSectionConfig'
import FormCommonConfig from './FormCommonConfig'

export default {
    name: 'ConfigPanel',
    mixins: [ smartFormMixin ],
    components: {
        FormSectionConfig,
        FormCommonConfig,
    },

    created() {
        // import all components in xtypeconfig... which should be named as xtype
        const files = require.context('./xtypeconfig/',true,/\.vue$/);
        files.keys().forEach(fileName => {
            const componentConfig = files(fileName)
            const config = componentConfig.default || componentConfig
            const baseName = fileName.substring(fileName.lastIndexOf('/')+1).split('.')[0]
            this.xtypeconfig[baseName] = config
        })
    },

    props: {
        value: {type: Object, default: null},
        duplicateFormDataIds: {type: Array, default: () => []},
        goToAdvanced: {type: Boolean, default: false},
    },
    computed: {
        // xtype: me => me.value && me.formFieldTypes[me.value.form_field_type_id].xtype,
        isSection() { return !this.value?.form_field_type_id },
        componentType() {
            if(this.isSection) return 'form-section-config'
            if(this.xtypeconfig[this.value.xtype]) return this.xtypeconfig[this.value.xtype]
            return 'form-common-config'
        },
    },

    data() {
        return {
            xtypeconfig: {},
        }
    },

    methods: {
        dataIdChange(v) {
            this.$emit('dataIdChange', v)
        },
        resetAdvancedTab() {
            this.$emit('resetAdvancedTab')
        }
    },
}
</script>

<style lang="scss" scoped>
.panel {
    min-width: 300px;
    max-width: 300px;
    background-color: white;
    overflow: auto;
    overflow-x: hidden;
    transition: margin-right .15s ease-in-out;
    padding: 16px;
    margin: 0px;
    border-left: 1px solid lightgray;
}
.hide {
    // margin-right: -300px;
}
.overlay {
    background-color: rgba(236, 237, 241, 0.3);
    width: 100%;
    height: 100%;
}
</style>
