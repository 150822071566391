<template>
    <fe-dialog
        v-if="active"
        title="Run Threshold Group"
        acceptButtonText="Run"
        :acceptButtonDisabled="!info"
        @dismiss="dismiss"
        @accept="apply"
    >
        <v-btn text disabled v-if="schoolYearPending">
            <v-progress-circular indeterminate color="#999" size="20"/>
        </v-btn>

        <v-form
            v-else
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-layout row wrap>
                <v-flex xs12 px-1>
                    <ItemSelector
                        v-if="schoolYearSuccess"
                        :required="true"
                        :collection="validSchoolYearCollection"
                        :selectedKeys="schoolYearId"
                        labelProp="name"
                        keyProp="id"
                        label="For School Year"
                        @change="changeYear"
                    />
                </v-flex>

                <v-flex xs12 px-1>
                    <fe-label content="Clear Previous Results?"/>
                    <v-switch v-model="clearPrevious" :label="clearPrevious ? 'Yes' : 'No'"/>
                </v-flex>

                <v-flex xs12 px-1 my-0 py-0 v-if="clearPrevious && showClearPreviousWarning">
                    <ul class="text-small py-0 my-0">
                        <li v-if="info.tag_count > 0" class="py-0 my-0">
                            <strong>{{ info.tag_count }}</strong>
                            tags generated by this threshold will be removed
                        </li>

                        <li v-if="info.form_instance_counts.deletable > 0">
                            <strong>{{ info.form_instance_counts.deletable }}</strong>
                            empty smartFORMs generated by this threshold will be deleted
                        </li>

                        <li v-if="info.form_instance_counts.undeletable > 0">
                            <strong>{{ info.form_instance_counts.undeletable }}</strong>
                            modified smartFORMs generated by this threshold will remain
                        </li>

                        <li v-if="info.email_count > 0">
                            Up to
                            <strong>{{ info.email_count }}</strong>
                            emails will be generated
                        </li>
                    </ul>
                </v-flex>
            </v-layout>
        </v-form>
    </fe-dialog>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ItemSelector from '../controls/ItemSelector'
import Api from '../services/Api'
import Map from '../services/Map'

export default {
    name: 'RunSettings',

    components: {
        ItemSelector
    },

    props: {},

    data() {
        return {
            active: true,
            valid: true,
            selectedSchoolYearId: null,
            clearPrevious: false,
            info: null
        }
    },

    computed: {
        ...mapGetters({
            schoolYearPending: 'thresholds/schoolYear/pending',
            schoolYearCollection: 'thresholds/schoolYear/collection',
            schoolYearSuccess: 'thresholds/schoolYear/success',
            schoolYearError: 'thresholds/schoolYear/error'
        }),

        validSchoolYearCollection() {
            return this.schoolYearCollection.filter(x => !!x.active).filter(x => {
                if (!this.thresholdGroup.end_date) {
                    return x.year_end >= this.thresholdGroup.start_date
                } else {
                    return x.year_end >= this.thresholdGroup.start_date &&
                        x.year_start <= this.thresholdGroup.end_date
                }
            })
        },

        thresholdGroup() {
            return this.$store.getters['thresholds/group/selectedWithMeta']
        },

        schoolYearId() {
            if (this.selectedSchoolYearId) {
                return this.selectedSchoolYearId
            } else if (this.validSchoolYearCollection.length > 0) {
                return this.validSchoolYearCollection[0].id
            }
            return null
        },

        showClearPreviousWarning() {
            return this.info && (
                this.info.tag_count > 0 ||
                this.info.form_instance_counts.total > 0 ||
                this.info.email_count > 0
            )
        }
    },

    watch: {
        selectedSchoolYearId: function () {
            this.getInfo()
        }
    },

    mounted() {
        this.refresh()
    },

    methods: {
        ...mapActions({
            schoolYearRefresh: 'thresholds/schoolYear/refresh'
        }),

        apply() {
            if (this.$refs.form.validate()) {
                this.$emit('apply', {
                    id: this.thresholdGroup.id,
                    clear_previous_results: this.clearPrevious,
                    school_year_id: this.schoolYearId,
                })
            }
        },

        dismiss() {
            this.$emit('dismiss')
        },

        changeYear(val) {
            this.selectedSchoolYearId = val || this.validSchoolYearCollection[0].id
        },

        validate() {
            this.$refs.form.validate()
        },

        refresh() {
            if (this.active && !this.schoolYearPending && !this.schoolYearSuccess) {
                this.schoolYearRefresh()
            }
            this.getInfo()
        },

        getInfo() {
            if (this.schoolYearId) {
                Api().get(Map.results.path, {
                    params: {
                        action: 'get',
                        property: 'info',
                        threshold_group_id: this.thresholdGroup.id,
                        school_year_id: this.schoolYearId
                    }
                }).then((response) => {
                    this.info = response.data
                })
            }
        }
    }
}
</script>

<style lang="scss">
.v-input--selection-controls.v-input--switch {
    margin: 0;
}
</style>
