<template>
    <div class="flex-fill flex-column slo-plan-panel">

        <fe-grid
            ref="grid"
            :rowData="logistics"
            :columnDefs="columnDefs"
            :frameworkComponents="frameworkComponents"
            style="height: 100%;"
            disable-inline-create
            addRowBtnText="Add"
            @beginCreate="showModal = true"
            displayDensity="medium"
            :showToolbarMenu="false"
            :showDownload="false"
            :defaultColDefOverrides="defaultColDefs"
            :gridOptionOverrides="gridOptions"
            @firstDataRendered="adjustGrid"
        >
            <template #cellDialog="{cell}">
                <div v-if="cell.colDef.field == 'id'">
                    <v-list-item @click="editLogistic(cell.data)">Edit</v-list-item>
                    <v-list-item @click="destroyLogistic(cell.data)">Delete</v-list-item>
                </div>
            </template>
        </fe-grid>

        <logistics-modal
            v-if="showModal"
            :types="logisticTypes"
            :slo="slo"
            :item="this.showModal === true ? null : this.showModal"
            @close="showModal = null"
            @created="logisticCreated"
            @updated="logisticUpdated"
        />

        <fe-crud
            ref="crud"
            :config="$models.sloLogistic"
        />

    </div>
</template>

<script>
    import CompletionStatusRenderer from './CompletionStatusRenderer.js'
    import LogisticsModal from './LogisticsModal'
    import LogisticsColumn from '../renderers/LogisticsColumn.js'

    export default {
        name: 'SloPanelLogistics',
        props: {
            slo: { type: Object, required: true },
            logisticTypes: { type: Array }
        },
        components: {
            LogisticsModal
        },
        data() {
            return {
                frameworkComponents: {
                    completionStatusRenderer: CompletionStatusRenderer
                },
                showModal: null,
                gridOptions: {
                    context: { componentParent: this }
                },
                defaultColDefs: {
                    autoHeight: true
                },
            }
        },
        computed: {
            logistics () { return this.slo.logistics },
            columnDefs () {
                return [
                    {
                        headerName: "Type",
                        field: "slo_logistic_type_name",
                        sortable: true,
                        editable: false,
                        width: 100
                    },
                    {
                        headerName: 'Logistics',
                        field: 'description',
                        sortable: true,
                        editable: false,
                        cellRendererFramework: LogisticsColumn,
                        cellStyle: {
                            whiteSpace: 'normal',
                            padding: '10px'
                        },
                        minWidth: 300
                    },
                    {
                        headerName: "Cost",
                        field: "cost",
                        sortable: true,
                        editable: false,
                        cellRenderer: (v) => this.$formatDollars(v.data.cost),
                        cellStyle: { textAlign: "right" },
                        width: 100
                    },
                    {
                        headerName: "Assignee(s)",
                        field: "user_cnt",
                        sortable: true,
                        editable: false,
                        valueGetter: (v) => this.aggregateAssignees(v.data)
                    },
                    {
                        headerName: "Status",
                        field: "completed",
                        sortable: true,
                        editable: false,
                        cellRenderer: "completionStatusRenderer",
                        cellRendererParams: {
                            completeField: "completed"
                        },
                        onCellClicked: this.toggleComplete,
                        width: 100
                    },
                    {
                        headerName: "",
                        field: "id",
                        sortable: false,
                        editable: false,
                        cellRenderer: () => '<i class="fe-grid-icon far fa-ellipsis-v theme--light"></i>',
                        onCellClicked: (v) => this.showDialog(v, 'showMenu'),
                        width: 50,
                        cellStyle: { textAlign: "right" }
                    },
                ]
            }
        },
        methods: {
            adjustGrid (e) {
                this.$nextTick(() => this.$refs.grid.resize())
            },
            create () {
                this.$emit('inDevelopment')
            },
            aggregateAssignees (rec) {
                return rec.slo_logistic_users.map(usr => {
                    return `${usr.user_fname} ${usr.user_lname}`
                }).join(', ')
            },
            showDialog (cell) {
                this.$refs.grid.setDialogCell(cell)
            },
            logisticCreated (rec) {
                this.$emit('created', rec)
                this.$emit('refresh')
                this.showModal = null
            },
            logisticUpdated (rec) {
                this.$emit('updated', rec)
                this.$emit('refresh')
                this.showModal = null
            },
            editLogistic (rec) {
                this.showModal = rec
            },
            destroyLogistic (rec) {
                let msg = 'Are you sure you want to delete this logistic?'
                this.$confirmDelete(rec, () => { this.performDeletion(rec) }, null, msg)
            },
            async performDeletion (rec) {
                let rsp = await this.$refs.crud.destroy(rec)
                this.$emit('deleted', rec)
                this.$emit('refresh')
            },
            async toggleComplete (v) {
                await this.$refs.crud.update([Object.assign({}, v.data, {
                    completed: !v.data.completed
                })])
                this.$emit('updated', v.data) // allows SLO creation modal to update in realtime without refreshing entire SLO
                this.$emit('refresh')
            }
        },
        watch: { }
    }
</script>

<style lang="scss">
    .slo-truncated-text-renderer {
        display: flex;
        &-toggle { display: none; }
        &-text { display: block; }
        &.truncatable {
            width: 100%;
            .slo-truncated-text-renderer-toggle {
                display: block;
            }
        }
        &.is-truncated {
            text-overflow: ellipsis;
        }
    }
    .ai-name-column-wrapper {
        height: 25px;

        .ai-show-description {
            cursor: pointer;
            color: #006C90;
            text-decoration-line: underline;
        }
        .ai-name-column-value {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }
</style>
