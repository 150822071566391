<template>
    <fe-dialog
        title="Upload Attachment"
        v-if="localValue"
        @close="localValue=false"
        @dismiss="localValue=false"
        @accept="doSubmit"
        :acceptButtonDisabled="!validUpload || !file || !desc"
        acceptButtonText="Upload"
        width=800
        persistent
    >
        <v-form v-model="validUpload">
            <v-file-input
                required
                multiple
                flat solo dense
                v-model="file"
                counter label="File"
                accept=".csv,.txt,.dat,.pdf,.xlsx,.xls,.wav"
            ></v-file-input>

            <div class="d-flex pa-2">
                <div class="flex-grow-1">
                    <fe-label>Name</fe-label>
                    <v-text-field
                        v-model="desc"
                        required
                        class="mr-2"
                        counter="50"
                        :rules="$fieldValidators('text', 'Description', { limit: 50 })"
                        flat solo dense
                    />
                </div>
            </div>
        </v-form>
    </fe-dialog>
</template>

<script>
    export default {
        name: 'FileUpload',
        props: {
            value: {
                type: Boolean,
                default: false
            },
            interventionTypeId: {
                required: true
            }
        },
        watch: {
            localValue(v) {
                this.$emit('input', v)

                if (!v) {
                    this.file = ''
                    this.desc = ''
                }
            },
            value(v) {
                this.localValue = v
            }
        },
        data() {
            return {
                desc: '',
                file: null,
                uploadTypes: [],
                localValue: false,
                validUpload: false
            }
        },
        created() {
            this.$data.localValue = this.$props.value
        },
        methods: {
            doSubmit() {
                let formData = new FormData()
                formData.append('intervention_type_id', this.$props.interventionTypeId)
                formData.append('name', this.desc)
                formData.append('file', this.file[0])

                this.$axios.post('interventionTypes.php?property=attachment&action=create',  formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(() => {
                    this.$emit('done')
                    this.localValue = false
                    this.$snackbars.$emit('new', { text: 'Attached to intervention', usage: 'success' })
                }).catch(err => {
                    console.error(err)
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
