import Vue from 'vue'
import Router from 'vue-router'
import LayoutTemplate from '@/components/modules/aatemplate/LayoutTemplate'
import ProgramEvaluation from '@/components/modules/programevaluation/ProgramEvaluation'
import Students3D from '@/components/modules/students3d/View'
import SummaryReport from '@/components/modules/summary/Report'
import Analytics from '@/components/modules/analytics/Analytics'
import Manage from '@/components/modules/manage/Index'
import PageNotFound from '@/components/common/PageNotFound'
import InterventionAnalysis from '@/components/modules/intervention/Index'
import Users from '@/components/modules/manage/users/Users'
import District from '@/components/modules/manage/district/Index.vue'
import baseRoutes from "./baseRoutes"
import attendanceRoutes from "./attendanceRoutes"
import meetingRoutes from "./meetingRoutes"
import incidentRoutes from "./incidentRoutes"
import smartFormsRoutes from "./smartFormsRoutes"
import studentProfile from "./studentProfile"
import upload from "./upload"
import collectionRoutes from './collectionRoutes'
import AchievementDashboard from '@/components/modules/achievementDashboard/Index.vue'
import ActionItems from '@/components/modules/actionItems/Index.vue'
import Thresholds from '@/components/modules/thresholds/Index.vue'
import ResourceCenter from '@/components/common/ResourceCenter'

import store from '@/vuex'

Vue.use(Router)

/** Not sure where to put these routes but ideally they'd be moved out into other files **/
let originalRoutes = [{
    path: '/Report/:scope',
    name: 'Report',
    props: true,
    component: SummaryReport
}, {
    path: '/LayoutTemplate',
    name: 'LayoutTemplate',
    component: LayoutTemplate
}, {
    path: '/ProgramEvaluation',
    name: 'ProgramEvaluation',
    component: ProgramEvaluation,
    meta: {
        navigation: 'launchpad',
        routes: ['/']
    }
}, {
    path: '/Students3D',
    name: 'Students3D',
    component: Students3D,
    meta: {
        navigation: 'launchpad',
        routes: ['/']
    }
}, {
    path: '/Analytics',
    name: 'Analytics',
    component: Analytics
}, {
    path: '/Manage',
    name: 'Manage',
    meta: {
        navigation: 'manage',
        routes: []
    },
    component: Manage
}, {
    path: '/Manage/:page',
    name: 'Manage',
    props: true,
    component: Manage
}, {
    path: '/Manage/Users/List',
    name: 'Manage',
    props: true,
    component: Users
}, {
    path: '/Manage/District/Settings',
    name: 'Manage',
    props: {app: 'info'},
    component: District
}, {
    path: '/Manage/District/SchoolGroup',
    name: 'Manage School Groups',
    component: () => import("@/components/modules/manage/district/SchoolGroups")
}, {
    path: '/Manage/Assessment/Categories',
    name: 'Manage Categories',
    component: () => import("@/components/modules/manage/assessments/Category")
}, {
    path: '/Thresholds',
    component: Thresholds,
    props: { app: 'thresholds' },
    name: 'Thresholds'
}, {
    path: '/AchievementDashboard',
    component: AchievementDashboard,
    name: 'AchievementDashboard'
}, {
    path: '/ActionItems',
    component: ActionItems,
    name: 'ActionItems'
}, {
    path: '/PageNotFound',
    name: 'PageNotFound',
    component: PageNotFound
}, {
    path: '/Interventions',
    name: 'Interventions',
    component: InterventionAnalysis
}, {
    path: '/ResourceCenter',
    name: 'ResourceCenter',
    component: ResourceCenter
}]

let routes = [
    ...baseRoutes,
    ...originalRoutes,
    ...attendanceRoutes,
    ...incidentRoutes,
    ...smartFormsRoutes,
    ...studentProfile,
    ...upload,
    ...collectionRoutes,
    ...meetingRoutes,
]

let router = new Router({
    mode: "history",
    routes: routes
})

// skip duplicate nav
let push = Router.prototype.push
Router.prototype.push = function(location, onComplete, onAbort)  {
    if(location == this.currentRoute.path) return
    else return push.call(this, location, onComplete, onAbort)
}

// We can restrict
router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.navigation) {
        store.commit('set', {
            module: 'navigation',
            value: to.meta.navigation,
            state: 'activeModule'
        })

        let arr = []
        if (to.meta && to.meta.routes) {
            to.meta.routes.forEach((metaRoute) => {
                routes.forEach((mainRoute) => {
                    if (mainRoute.path === metaRoute) {
                        arr.push({
                            name: mainRoute.name,
                            to: mainRoute.path
                        })
                    }
                })
            })

            arr.push({
                name: to.name,
                to: to.path
            })
        }

        store.commit('set', {
            module: 'navigation',
            value: arr,
            state: to.meta.navigation
        })
    } else if (to.meta && to.meta.requireSecurity) {
        if (store.state.global.sessionUser.security_codes.indexOf(to.meta.requireSecurity) === -1) {
            next("/PageNotFound")
            return
        }
    }

    next()
})

export default router
