<template>
    <div>
        <div v-if="!fbMonitor">
            <fe-label>School Year</fe-label>
            <fe-remote-combo
                :url="$models.getUrl('schoolYear', 'read')"
                rootProperty="years"
                v-model="schoolYear"
                :rules="$fieldValidators('select', 'School year', { required: true })"
            />
        </div>

        <div v-if="!fbMonitor">
            <fe-label>School</fe-label>
            <fe-remote-combo
                :disabled="!schoolYear"
                :items="schools"
                rootProperty="schools"
                v-model="school"
                :rules="$fieldValidators('select', 'School', { required: true })"
            />
        </div>

        <div v-if="!fbMonitor || (fbMonitor && searchedStudents.length > 0)" class="section-title">Add Students</div>

        <div v-if="!fbMonitor || (fbMonitor && searchedStudents.length > 0)">
            <fe-label>Select students</fe-label>
            <fe-remote-combo
                itemText="student_full_name"
                itemValue="student_id"
                rootProperty="students"
                :disabled="!fbMonitor ? !school : null"
                :items="searchedStudents"
                v-model="student"
                @change="removeFromSelector"
            >
                <template slot="item" slot-scope="data">
                    <v-list-item-content>
                        <v-list-item-title v-html="data.item.student_full_name" />
                        <v-list-item-subtitle>
                                {{data.item.school_name}} - {{data.item.grade_desc}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </fe-remote-combo>
        </div>

        <div class="section-title mb-2">Students in Intervention</div>

        <div v-for="(student, i) in students" class="added-student d-flex ma-1" :key="`student-`+i" v-if="student.student_full_name" >
            <div class="student-name">{{student.student_full_name}}</div>

            <div class="ml-auto">
                <fe-icon-btn small useIcon="fas fa-times" @click="removeStudent(student)"/>
            </div>
        </div>

        <div v-if="fbMonitorUnmatched > 0" class="d-flex ml-5" style="color: #F02D1F">
            <v-icon class="mr-2" style="font-size: 13px; color: #F02D1F;">fas fa-exclamation-triangle</v-icon>
            {{ fbMonitorUnmatched }} unmatched student {{ fbMonitorUnmatched > 1 ? 's' : null}}
        </div>
        <div class="d-flex">
            <div class="ml-auto">
                <fe-btn :disabled="!isValid" @click="$emit('add')">Add</fe-btn>
            </div>
        </div>
    </div>

</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'InterventionStudents',
        props: {
            value: {
                type: Array,
                default: () => []
            }
        },
        watch: {
            schoolYear(v) {
                if (!v) return

                this.$modelGet('school', { school_year_id: v ? v.id : null })
                    .then(response => { this.$data.schools = response })

                if (!this.fbMonitor) { this.loadStudents() }
            },
            school(v) {
                if (!v) return

                this.loadStudents()
            },
            student(v) {
                if (v) {
                    let i = this.students.find(rec => rec.student_id === v.student_id)

                    if (!i || i != 0) {
                        this.students.push(v)
                    }

                    this.$nextTick(() => {
                        this.$data.student = null
                    })

                }
            },
            students: {
                deep: true,
                handler() {
                    this.$emit('input', this.students)
                }
            },
        },
        computed: {
            ...mapState('global', ['currentYear']),
            school: {
                get () {
                    return this.$store.state.intervention.school
                },
                set (v) {
                    this.$store.commit('intervention/set', {
                        key: 'school',
                        value: v
                    })
                }
            },
            isValid() {
                return this.students.length > 0
            }
        },
        data() {
            return {
                students: [],
                schoolYear: null,
                schools: [],
                searchedStudents: [],
                student: null,
                fbMonitor: false,
                fbMonitorUnmatched: 0
            }
        },
        mounted() {
            this.students =  this.$props.value
            this.$data.schoolYear = this.currentYear
            this.fbMonitor = this.$parent.fbMonitorAvailable
            this.fbMonitorUnmatched = this.$parent.fbMonitorUnmatched

            // FastBridge check
            // Should data come from FastBridge progress monitor?
            // Mark all students as selected and let user remove as needed
            if (this.fbMonitor) {
                this.students = this.$parent.fbMonitorStudents
            }

            if (this.school?.id && this.schoolYear?.id) this.loadStudents()
        },
        methods: {
            loadStudents() {
                this.$modelGet('studentGradeHistory', { school_year_id: this.schoolYear.id,  school_id: this.school?.id })
                    .then(response => {
                        // filter out already selected students
                        let studentMap = {}
                        this.students.forEach(student => studentMap[student.student_id] = true)
                        response = response.filter(student => !studentMap[student.student_id])

                        this.$data.searchedStudents = response
                        this.searchedStudents.sort((a, b) => (a.lname.localeCompare(b.lname) || a.fname.localeCompare(b.fname)))
                    })
            },
            removeStudent(s) {
                let i = this.students.findIndex(rec => rec.student_id === s.student_id)

                // add removed student to student selector
                // so they can be selected again, if necessary
                this.searchedStudents.push(this.students[i])
                // sort list
                this.searchedStudents.sort((a, b) => (a.lname.localeCompare(b.lname) || a.fname.localeCompare(b.fname)))

                this.students.splice(i, 1)
            },
            removeFromSelector(v) {
                if (v) {
                    let i = this.searchedStudents.findIndex(rec => rec.student_id === v.student_id)

                    // remove selected student from selector control
                    // so user can't re-select the student
                    this.$nextTick(() => {
                        this.searchedStudents.splice(i, 1)
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.added-student {
    background: #E0E1E6;
    border-radius: 4px;
    height: 38px;

    .student-name {
        line-height: 38px;
        padding-left: 16px;
    }

}
</style>
