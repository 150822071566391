var moduleFiles = require.context('./',true,/\.js$/)
var modules = {}
moduleFiles.keys().forEach( file => {
    if(file.match(/index.js$/)) return
    const moduleName = file.split('/').pop().replace(/\.[^/.]+$/, "")
    import('./'+moduleName).then(m => {
        modules = Object.assign(modules, m.default)
    })
})

modules.getUrl = function(model, action, asString=true) {
    if (this[model]) {
        let m = this[model]
        let url = m[action].endpoint ? m[action].endpoint : m.defaults.endpoint
        let p = m[action].params ? Object.assign({}, m.defaults.params, m[action].params) : m.defaults ? m.defaults.params : null

        if (!asString) {
            return {
                url: url,
                params: p
            }
        } else {
            for (let key in p) {
                url += (url.indexOf('?') > -1 ? '&' : '?') + key + '=' + encodeURIComponent(p[key])
            }
        }
        return url
    } else {
        console.error('Invalid model endpoint', model, action)
        return
    }
}

export default modules

// import incident from './incident'
// import intervention from './intervention'
// import smartform  from './smartform'
// import common from './common'
// import tag from './tag'
// import assessment from './assessment'
// import category from './category'
// import message from './message'
// import alphaMap from './alphaMap'
// import scoreDetailType from './scoreDetailType'
// import scoreCalcType from './scoreCalcType'
// import attendance from "./attendance"
// import subcategory from "./subcategory"
// import studentProfile from "./studentProfile"
// import studentIncident from "./studentIncident"
// import assessmentResults from "./assessmentResults"
// import dataPointName from "./dataPointName"
// import form from './form'
// import user from './user'
//
// export default {
//     ...incident,
//     ...intervention,
//     ...smartform,
//     ...common,
//     ...tag,
//     ...assessment,
//     ...category,
//     ...message,
//     ...alphaMap,
//     ...scoreDetailType,
//     ...scoreCalcType,
//     ...attendance,
//     ...subcategory,
//     ...form,
//     ...studentProfile,
//     ...studentIncident,
//     ...assessmentResults,
//     ...user,
//     ...dataPointName,
//     getUrl: function(model, action, asString=true) {
//         if (this[model]) {
//             let m = this[model]
//             let url = m[action].endpoint ? m[action].endpoint : m.defaults.endpoint
//             let p = m[action].params ? Object.assign({}, m.defaults.params, m[action].params) : m.defaults.params
//
//             if (!asString) {
//                 return {
//                     url: url,
//                     params: p
//                 }
//             } else {
//                 for (let key in p) {
//                     url += (url.indexOf('?') > -1 ? '&' : '?') + key + '=' + encodeURIComponent(p[key])
//                 }
//             }
//             return url
//         } else {
//             console.error('Invalid model endpoint', model, action)
//             return
//         }
//     }
// }
