export default {
    scoreDetailType: {
        defaults: {
            endpoint: 'scoreDetailTypes.php',
            rootProperty: null
        },
        read: {
            params: { action: 'get' }
        },
        create: {
            params: { action: 'create' },
            rootProperty: 'types'
        },
        update: {
            params: { action: 'update' },
            rootProperty: 'types'
        },
        destroy: {
            params: { action: 'delete' },
            rootProperty: 'types'
        }
    }
}