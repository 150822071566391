import axios from 'axios'

export const collection = {
    namespaced: true,
    state: {
        allCollections: [],
        collectionTypes: [],
        collectionGroups: [],
        collectionUsers: [],
        reloadCollection: false,
        userAndSharedCollections: [],
        breadcrumbs: {
            'Collections': [{
                name: 'Collections'
            }]
        }
    },

    getters: {
        allCollections: state => {
            return state.allCollections
        },
        reloadCollection: state => {
            return state.reloadCollection
        },
        userAndSharedCollections: state => {
            return Object.keys(state.allCollections).length ? state.allCollections.user_dashboards.concat(state.allCollections.shared_dashboards) : []
        }
    },

    mutations: {
        set(state, payload) {
            state[payload.key] = payload.value
        },
        addCrumb(state, payload) {
            if (Array.isArray(payload.val)) {
                state.breadcrumbs[payload.key] = state.breadcrumbs[payload.key].concat(payload.val)
            } else {
                if (payload.index) {
                    state.breadcrumbs[payload.key].length = payload.index
                    state.breadcrumbs[payload.key].push(payload.val)
                } else {
                    state.breadcrumbs[payload.key].push(payload.val)
                }
            }

        },
        removeCrumb(state, payload) {
            let breadcrumb = state.breadcrumbs[payload.key]
            if (!isNaN(payload.count) && breadcrumb.length > payload.count + 1) {
                breadcrumb.splice(breadcrumb.length - payload.count, payload.count)
            }
        },
        setCrumbs(state, payload) {
            state.breadcrumbs[payload.key] = payload.val
        }
    },

    actions: {

    }
}
