<template lang="html">
    <div class="flex-fill flex-column">
        <v-toolbar>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>ToolBar</v-toolbar-title>
        </v-toolbar>
        <div class="flex-fill">
            <fe-collapse-Panel title="FeCollapsePanel" style="overflow:auto;"></fe-collapse-Panel>
            <div style="overflow:auto;" class="content green">
                <div v-for="(x,i) in 26" class="block">{{i}} {{someText}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'

export default {
    name: 'ToolbarSidebarContent',
    inject: ['localStore'],
    computed: {
        ...mapLocalState('someText'),
    },
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    height: 100%;
}
</style>
