<template>
    <div class="flex-fill flex-column no-scroll" style="height: 400px;">
        <fe-crud-grid
            ref="grid"
            identifier="course_history_id"
            :autoload="true"
            :columnDefs="columnDefs"
            :config="$models.courseHistory"
            :extraTools="this.hasSecurity"
            :readParams="{ student_id: this.updateStudent.student_id }"
            :rowData="courses"
            :showAddRowBtn="showAddCourseBtn"
            :showEditbar="this.hasSecurity"
            @beginCreate="showAddDialog=true"
            @read="v => courses = v"
            @selectionChanged="setSelectedRows"
            displayDensity="medium"
            refreshOnUpdated
            style="height:100%;"
        >
            <!-- Hide until new slot is added to ec-frontend-library -->
            <template v-slot:extra-tools>
                <fe-btn
                    useIcon="fa-exchange-alt"
                    usage="ghost"
                    whiteText
                    class="fe-grid-action-btn"
                    @click="$emit('reassignBtnClick', $refs.grid.$refs.crud)"
                >
                    Reassign
                </fe-btn>
            </template>
        </fe-crud-grid>
    </div>
</template>

<script>
    import Vuex, { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'

    export default {
        name: 'StudentCourseManager',

        inject: ['localStore'],

        computed: {
            ...mapLocalState(['updateStudent','selections','schoolYears','hasSecurity']),

            valuesLoaded() {
                return !!this.schoolYears && !!this.updateStudent
            },

            columnDefs() {
                let me = this

                return !this.valuesLoaded ? [] : [
                    {
                        headerName: null,
                        headerCheckboxSelection: this.hasSecurity,
                        checkboxSelection: this.hasSecurity,
                        width: 70,
                        minWidth: 70,
                        hide: !this.hasSecurity,
                        colId: 'checkbox_column'
                    }, {
                        headerName: "Internal ID",
                        field: "student_id",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "School Year",
                        field: "school_year_id",
                        sortable: true,
                        editable: false,
                        valueGetter: v => me.findLabel(me.schoolYears, v.data.school_year_id, 'id', 'name'),
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: v => {
                            return {
                                value: v.data.school_year_id,
                                rowDataKey: "school_year_id",
                                mode: "edit",
                                items: me.schoolYears,
                                keyProp: "id",
                                labelProp: "name",
                                disableChips: true
                            }
                        }
                    }, {
                        headerName: "Course",
                        field: "course_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Teacher",
                        field: "user_full_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Period",
                        field: "period",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Inactive Dt",
                        field: "inactive_date",
                        sortable: true,
                        editable: this.hasSecurity
                    }, {
                        headerName: "Upload ID",
                        field: "upload_id",
                        sortable: true,
                        editable: false
                    }
                ]
            }
        },

        data() {
            return {
                selectedCourses: [],
                courses: [],
                showAddCourseBtn: false,
                gridApi: null
            }
        },

        methods: {
            findLabel(collection, value, valueProp, labelProp) {
                let lbl = ''
                if (collection && value) {
                    let sel = collection.find(x => x[valueProp] === value)
                    if (sel) lbl = sel[labelProp]
                }
                return lbl
            },

            setSelectedRows(meta) {
                this.$data.gridApi = meta.api
                this.selections = meta.api.getSelectedRows()
            }
        }
    }
</script>

<style lang="scss" scoped></style>
