let standardCrud = {
    read: {
        params: {action: 'get'}
    },
    create: {
        params: {action: 'create'}
    },
    update: {
        params: {action: 'update'}
    },
    destroy: {
        params: {action: 'delete'}
    }
}

export default {
    ellLevel: {
        defaults: {
            endpoint: 'crud.php?property=EllLevel',
            rootProperty: 'ell_level'
        },
        ...standardCrud
    },
    ellLevelAlias: {
        defaults: {
            endpoint: 'crud.php?property=EllLevelAlias',
            rootProperty: 'ell_level_alias'
        },
        ...standardCrud
    },
}
