import Vue from 'vue';

export default Vue.extend({
    template: `<div>
                    <fe-time-scroll-picker v-model="localValue" grid :clearable="false" class="action-items-fe-time-scroll-picker" @input="setTime" />
              </div>`,
    data() {
        return {
            localValue: null
        }
    },
    created() {
        this.localValue = this.params?.value ? this.params.value : null
    },
    methods: {
        setTime(v) {
            let val = this.$dayjs('2000-01-01 ' + v).format('HH:mm:ss')
            if(val !== this.params?.value) {
                let selected = this.params
                selected.data.due_time = val
                this.params.context.componentParent.selected = selected
            }
        }
    }
});
