export default {
    namespaced: true,

    state: {
        activeStatus: [{
            id: '0',
            name: 'Inactive'
        }, {
            id: '1',
            name: 'Active'
        }]
    },

    mutations: {
        set(state, payload) {
            state[payload.state] = payload.value
        }
    },

    actions: {
        async load({state}) {
            return state.activeStatus
        },

        refresh({dispatch}) {
            dispatch('load')
        }
    },

    getters: {
        all: state => state.activeStatus,

        collection: state => state.activeStatus,

        success: () => true,

        clear: () => false
    }
}
