export default {
    assessmentWindow: {
        rules: [
            r => (r && r.name && r.name.trim().length > 0) || { name: 'Name is required' },
            r => (r && r.school_year_id && r.school_year_id > 0) || { name: 'School Year is required' },
            r => (r && r.start_date) || { name: 'Start Date is required' },
            r => (r && r.end_date) || { name: 'End Date is required' }
        ],
        defaults: {
            endpoint: 'assessmentWindows.php', // scope: data_point_name_id=1, school_year_id=16
            rootProperty: 'assessment_windows'
        },
        read: {
            params: { action: 'get' }
        },
        create: {
            params: { action: 'create' },
        },
        update: {
            params: { action: 'update' },
        },
        destroy: {
            params: { action: 'delete' },
        }
    }
}