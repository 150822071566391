<template>
    <div class="d-flex flex-column fill-flex">
        <fe-crud-grid
            ref="grid"
            style="height: 400px;"
            :config="$models.behaviorMonitor"
            @read="v => items = v"
            domLayout="normal"
            :columnDefs="columns"
            :rowData="items"
            displayDensity="medium"
            @cellValueChanged="() => {}"
            disabledInlineCreate
            :showAddRowBtn="false"
        />

        <div class="d-flex mt-4">
            <v-text-field flat solo dense clearable v-model="name" placeholder="New expectation name..." style="margin-top: 7px;" class="flex-grow-1"/>

            <fe-btn :disabled="!name" @click="addExpectation">Add</fe-btn>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ExpectationBank',
        data() {
            return {
                items: [],
                name: ''
            }
        },
        computed: {
            columns() {
                return [this.$grid.checkColumn(), {
                    headerName: 'Monitor',
                    field: 'name',
                    flex: 1,
                    editable: true
                }, {
                    headerName: 'Created',
                    field: 'created',
                    maxWidth: 200
                }]
            }
        },
        methods: {
            addExpectation() {
                if (!this.name) return
                let r = {
                    data_point_type_id: 13,
                    category_id: 4,
                    score_calc_weight: 1,
                    assessment_type_id: 2,
                    score_calc_type_id: null,
                    parent_id: null,
                    rank: 0,
                    name: this.name
                }

                this.$refs.grid.$refs.crud.create(r)
                    .then(() => {
                        this.$refs.grid.$refs.crud.read()
                        this.name = ''
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>