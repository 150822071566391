<template>
    <div class="flex-fill flex-column no-scroll">
        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.unknownDataPointType"
            :readParams="{ upload_id: rec.id }"
            @read="onReadItems"
        />

        <fe-mask v-show="loading" :showLoader="true" />

        <fe-grid
            :columnDefs="columns"
            :rowData="items"
            class="flex-grow-1"
            displayDensity="small"
            ref="grid"
            :showAddRowBtn="false"
            @selectionChanged="selectRow"
            @cellValueChanged="sanitize"
            showToolbar
        >
            <template v-slot:toolbar-items>
                <fe-btn usage="primary" v-if="canSave" @click="doSave">Save</fe-btn>

                <v-menu v-if="tools.length > 0" offset-y>
                    <template v-slot:activator="{ on }">
                        <fe-btn usage="primary" v-on="on">
                            Actions
                        </fe-btn>
                    </template>

                    <v-list>
                        <template v-for="(item, index) in tools">
                            <v-list-item :key="index" @click="item.handler()" v-show="!item.hidden">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-menu>
            </template>
        </fe-grid>
    </div>
</template>

<script>
    export default {
        name: 'UnknownAssessmentGroups',
        props: {
            rec: {
                required: true
            }
        },
        data() {
            return {
                assessmentGroups: [],
                loading: false,
                exclusive: [],
                canSave: false,
                selections: [],
                items: [],
                originalItems: [],
            }
        },
        computed: {
            columns() {
                return [
                    this.$grid.checkColumn()
                    , {
                        headerName: 'Name',
                        field: 'name',
                        sortable: true,
                        flex: 1
                    }, {
                        headerName: 'Alias as Assessment Group',
                        field: 'matched_data_point_type_id',
                        flex: 1,
                        editable: true,
                        tooltipValueGetter: (param) => 'This value will be set to the selected Assessment Group',
                        cellRenderer: v => {
                            if (v.value) {
                                let r = this.assessmentGroups.find(rec => { return rec.id == v.value })
                                if (r) return r.name
                            }
                            return ''
                        },
                        cellEditorFramework: "FeGridChipSelect",
                        cellEditorParams: {
                            rowDataKey: "sub_category_id",
                            mode: "edit",
                            items: this.assessmentGroups,
                            keyProp: "id",
                            labelProp: "name",
                            disableChips: true
                        }
                    }
                ]
            },
            requiresCategory() {
                if (this.onlyOne) {
                    return true
                } else if (this.dialog.record.parent_sub_category_id) {
                    return false
                }
                return true
            },
            tools() {
                let me = this
                let tools = []

                if (me.selections.length > 0) {
                    tools.push({
                        title: 'Auto Create Assessment Group Alias',
                        description: 'Attempt to create new Assessment Groups automatically, based on the import column name.',
                        handler: () => {
                            let records = me.selections.map(item => ({
                                ...item,
                                new_data_point_type: true
                            }))

                            me.$refs.crud.update(records)
                                .then(response => {
                                    me.refresh()
                                })
                                .finally(() => {
                                    me.loading = false
                                    me.$refs.grid.gridApi.deselectAll()
                                })
                        }
                    })
                }

                return tools
            },
        },
        mounted() {
            this.loadAssessmentGroups()
        },
        methods: {
            refresh() {
                this.$emit('reloadCounts')
                this.loadAssessmentGroups()
            },
            onReadItems(items) {
                this.items = items.map(item => ({
                    ...item,

                    // this is the value the user can select in the dropdown
                    matched_data_point_type_id: null,

                    // this flags the request to auto-generate data point type based on unknown assessment group's "name" value
                    new_data_point_type: false,
                }))
            },
            doSave() {
                let dirty = this.$isDirty(this.originalItems, this.items)
                this.loading = true
                this.$refs.crud.update(dirty)
                    .then(response => {
                        this.refresh()
                    })
                    .finally(() => {
                        this.loading = false
                        this.$refs.grid.gridApi.deselectAll()
                    })
            },
            selectRow(row) {
                this.selections = this.$refs.grid.gridApi.getSelectedRows()
            },
            sanitize() {
                let keys = this.exclusive
                // this will check to see if the original fetched data set has been manipulated
                let dirty = this.$isDirty(this.originalItems, this.items)
                if (dirty) {
                    this.canSave = true
                } else {
                    this.canSave = false
                }
                // Since we are changing cells behind the ag-grid api, call refresh
                this.$refs?.grid.gridApi.refreshCells()
                this.$refs?.grid.gridApi.sizeColumnsToFit()
            },
            loadAssessmentGroups() {
                this.originalItems = []
                this.items = []

                this.loading = true

                this.$modelGet('assessment')
                    .then(r => {
                        this.assessmentGroups = r

                        this.$refs.crud.read()
                            .then(() => {
                                this.originalItems = this.$_.cloneDeep(this.items)
                                this.$emit('count', this.originalItems.length)
                            })
                            .finally(() => {
                                this.loading = false
                                this.sanitize()
                            })
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
