<template>
    <div>
        <div class='range-title'>
            {{ title }}
            <div class='title-tip-adj' v-if="tooltip">
                <fe-info-tip :tooltip="tooltip"/>
            </div>
        </div>
        <table class='descriptor-table'>
            <thead>
                <tr>
                    <th>Color</th>
                    <th>Label</th>
                </tr>
            </thead>
            <draggable v-model="ranges" tag="tbody" handle=".handle">
                <tr v-for="(item, idx) in ranges" :key="`desc-${item.tempId}-${idx}`">
                    <td>
                        <fe-color-selector v-model="item.color" @change="update(item)"/>
                    </td>
                    <td class="d-inline-flex">
                        <fe-text-field
                            :class="[{'invalid-band': !item.name}]"
                            flat solo dense
                            v-model="item.name"
                            width="210px"
                            placeholder="Enter a Label"
                            @input="update(item)"
                        />
                        <div class="trash-icon-area d-flex">
                            <fe-icon-btn
                                :disabled="items.length <= 1"
                                useIcon="delete"
                                @click="remove(item)"
                            />
                            <div class="dragbar" :class="{'handle': items.length > 1}">
                                <i class="fe-grid-icon far fa-ellipsis-v theme--light"/>
                                <i class="fe-grid-icon far fa-ellipsis-v theme--light"/>
                            </div>
                        </div>
                    </td>
                </tr>
            </draggable>
        </table>
        <fe-btn :disabled="!valid" usage="tertiary" @click="add">Add Range</fe-btn>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        name: 'AssessmentGroupBandEditorRanges',
        components: {draggable},
        props: {
            title: { type: String, required: true },
            tooltip: { type: String, default: null},
            typeId: { type: Number, required: true },
            items: { type: Array, default: () => [] }
        },
        data () {
            return {
                ranges: []
            }
        },
        computed: {
            valid () { return this.items.filter(desc => !desc.name || desc.name.trim() == "").length == 0 }
        },
        watch: {
            ranges() {
                this.change()
            }
        },
        mounted() {
            this.loadRanges()
        },
        methods: {
            loadRanges() {
                this.ranges = this.$_.cloneDeep(this.items).map((x, idx) => {
                    x.tempId = idx
                    return x
                })
            },
            add() {
                let tempId = this.ranges.length
                this.ranges.push({
                    name: '',
                    target_type_id: this.typeId,
                    color: "#FD4336",
                    tempId
                })
            },
            update(item) {
                this.ranges = this.ranges.map(x => {
                    if (item.tempId == x.tempId) return  item
                    return x
                })
            },
            remove(item) {
                this.ranges = this.ranges.filter(x => x.tempId !== item.tempId)
            },
            change() {
                this.$emit('change', this.ranges)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .invalid-band {
        border: 1px solid red !important;
        border-radius: 2px;
    }

    .title-tip-adj {
        display: inline-block;
        position: relative;
        top: -3px;
    }
    .range-title {
        font-size: 18px;
        line-height: 21px;
        color: #000000;
        margin-top: 20px;
    }
    .descriptor-table {
        width: 300px;
        border-radius: 2px;
        border-collapse: collapse;
        &, & th { border: 1px solid #E0E4E7; }
        thead {
            th {
                font-size: 14px;
                text-align: left;
                padding: 10px 8px;
            }
        }
        tbody {
            tr {
                td {
                    position: relative;
                    text-align: left;
                    padding: 5px;
                    margin: 0;
                    .trash-icon-area {
                        visibility: hidden;
                        top: -5px;
                        position: relative;
                        height: 36px;

                        .dragbar {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-width: 20px;
                            height: 100%;
                            position: relative;
                            top: 5px;
                        }

                        .handle {
                            cursor: grab;
                            &:hover {
                                box-shadow: inset 0 0 0 99999px rgba(0, 0, 0, 0.1);
                            }
                        }
                    }
                    &:hover .trash-icon-area { visibility: visible; }
                    &:not(:first-child) { border-left: solid 1px #E0E4E7; }
                }
                &:not(:first-child) { border-top: solid 1px #E0E4E7; }
                &[draggable='true'] {
                    td {
                        .trash-icon-area {
                            .handle {
                                cursor: grabbing !important;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
