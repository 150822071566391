<template>
    <fe-dialog
        title="Student Image Upload"
        v-if="localValue"
        @close="localValue=false"
        @dismiss="localValue=false"
        @accept="uploadFiles"
        :acceptButtonDisabled="!validUpload || filesToUpload.length === 0"
        acceptButtonText="Upload"
        width="60%"
        height="90%"
        persistent
        disableAutoclose
    >
        <v-form v-model="validUpload">
            <v-file-input
                v-model="selectedFiles"
                required
                flat solo dense
                counter
                multiple
                accept=".zip,.png,.jpg,.jpeg"
                :show-size="1000"
            >
                <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" label small>
                        {{ text }}
                    </v-chip>

                    <span
                        v-else-if="index === 2"
                        class="overline mx-2"
                    >
                        +{{ filesToUpload.length - 2 }} File(s)
                    </span>
                </template>
            </v-file-input>
        </v-form>

        <div class="d-flex flex-fill flex-row pt-2" style="height: 400px">
            <fe-grid
                ref="grid"
                disableInlineCreate
                :columnDefs="columns"
                :rowData="files"
                :statusBar="statusBar"
                :showAddRowBtn="false"
                displayDensity="medium"
                showScrollbars
                style="height: 100%; width: 100%;"
                @gridColumnsChanged="resize"
                @gridReady="resize"
            />
        </div>
    </fe-dialog>
</template>

<script>
    import lodash from 'lodash'

    export default {
        name: 'StudentImageUpload',

        props: ['value'],

        watch: {
            localValue(v) {
                this.$emit('input', v)

                if (!v) {
                    this.files = []
                    this.selectedFiles = []
                    this.filesToUpload = []
                }
            },

            value(v) {
                this.localValue = v
            },

            selectedFiles(v) {
                this.filesToUpload = []
                v.forEach((f,i) => {
                    let status = v[i].status ? v[i].status : 'Ready'

                    this.files.push({ name: v[i].name, size: v[i].size, status: status })
                    this.filesToUpload.push({ name: v[i].name, size: v[i].size, status: status })
                })
            },
            filesToUpload: {
                handler(v) {
                    if (v.length) {
                        v.forEach(file => {
                            if (file.total) {
                                let updatedFile = this.files.find(u => u === file)
                                updatedFile.status = file.added + ' of ' + file.total + ' images added'
                                if (file.total != file.added) updatedFile.background = '#FFA200;'
                                else updatedFile.background = '#49c379;'
                            }
                        })
                    }
                },
                deep: true
            }
        },

        data() {
            return {
                files: [],
                selectedFiles: [],
                filesToUpload: [],
                images: [],
                studentId: undefined,
                localValue: false,
                validUpload: false,
                progress: null,
                statusBar: null
            }
        },

        created() {
            this.$data.localValue = this.$props.value
        },

        beforeMount() {
            this.statusBar = {
                statusPanels: [
                    {
                        statusPanel: 'agTotalRowCountComponent',
                        align: 'left',
                        label: 'Count'
                    }
                ],
            }
        },

        computed: {
            valuesLoaded() {
                return !!this.files
            },

            columns() {
                let me = this

                return !this.valuesLoaded ? [] : [{
                    headerName: 'File',
                    field: 'name'
                }, {
                    headerName: 'Size',
                    field: 'size'
                },
                // {
                //     headerName: 'Sent',
                //     field: 'sent',
                //     maxWidth: 300,
                //     minWidth: 200
                // },
                {
                    headerName: 'Status',
                    field: 'status',
                    cellStyle(v) {
                        if (v.value === 'Ready') {
                            return { background: '#ffffff' }
                        } else if (v.value === 'Complete') {
                            return { background: '#49c379' }
                        } else if (v.data.total) {
                            return { background: v.data.background }
                        } else {
                            return { background: '#FF5252' }
                        }
                    }
                }
            ]}
        },

        methods: {
            resize() {
                this.$refs.grid?.gridApi?.sizeColumnsToFit()
            },
            isValid() {
                let me = this,
                    valid = false

                me.filesToUpload.forEach((form) => {
                    if(!form.uploaded) {
                        valid = true
                    }
                })
                return valid
            },

            isComplete() {
                me = this,
                    valid = true

                me.filesToUpload.forEach((form) => {
                    if(form && !form.uploaded) {
                        valid = false
                    }
                })
                return valid
            },

            // onProgress(pct, file) {
            //     file.sent = pct
            // },

            sendFiles() {
                let me = this

                if (this.filesToUpload.length > 1) {
                    this.filesToUpload.forEach((form, i) => {
                        me.sendFile(form, i)
                    })
                } else {
                    let form = this.filesToUpload.length ? this.filesToUpload[0] : null

                    if (form) {
                        this.sendFile(form, 0)
                    } else {
                        if (this.progress) {
                            this.progress = null
                        }
                    }
                }
            },

            sendFile(form, idx) {
                let me = this

                if (form) {
                    me.filesCurrentlyUploading++

                    if (!me.progress) {
                        this.$snackbars.$emit('new', { text: 'Sending: '+form.name })
                    }

                    let formData = new FormData()

                    formData.append('upload_file', this.selectedFiles[idx], form.name)

                    // const config = {
                    //     onUploadProgress:(progressEvent) => {
                    //         let progressCompleted = progressEvent.loaded
                    //         me.onProgress(progressCompleted, form)
                    //     }
                    // }

                    this.$axios.post('studentImageUpload.php', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((res) => {
                        form.status = res.data.msg
                        if (res.data.totalImages) {
                            form.total = res.data.totalImages
                            form.added = res.data.validImages
                        }
                        this.files.forEach((file, index) => {
                            if(file.name === form.name) me.files.splice(index, 1, form)
                        })
                    }).catch(err => {
                        console.error(err)
                    })
                }
            },

            uploadFiles() {
                if(this.isValid()) {
                    this.sendFiles()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .ag-status-bar {
        border-top: 1px solid #e2e2e2;
    }
</style>