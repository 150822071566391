<template>
    <div class="d-flex flex-column flex-fill">
        <student-data-manager
            v-if="studentDialog.show"
            @showStudentManagerClicked="studentDialog.show=false"
            :selectedStudent="studentDialog.rec"
        />

        <fe-dialog
            v-if="searchDialog.show"
            title="Search Students"
            @close="searchDialog.show=false"
            dismissButtonText="Cancel"
            acceptButtonText="Search"
        >
            <fe-label>{{ searchDialog.label}}</fe-label>
            <v-autocomplete
                v-model="searchDialog.rec"
                :search-input.sync="searchText"
                flat solo dense
                class="mt-5"
                :items="searchResults"
                :itemText="searchDialog.type"
                :itemValue="searchDialog.type === 'unique_id' ? 'student_id' : 'id'"
                return-object
                placeholder="Search for students (3 characters minimum)"
                :no-data-text="noSearchString ? '' : 'No data available'"
                :hide-no-data="noSearchString"
            >
                <template v-slot:item="data">
                    <v-list-item-avatar>
                        <v-img :src="getStudentImg(data.item.photo_id)"/>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{data.item.primary}} ({{data.item.secondary}})</v-list-item-title>
                    </v-list-item-content>
                </template>
            </v-autocomplete>
        </fe-dialog>

        <fe-dialog
            title="Create Student"
            width="500"
            v-if="createStudentDialog.show"
            @dismiss="createStudentDialog.show=false"
            @close="createStudentDialog.show=false"
            :acceptButtonDisabled="!validForm"
            acceptButtonText="Save"
            @accept="createStudent"
            persistent
            dismissButtonText="Cancel"
        >
            <v-form v-model="validForm">
                <fe-label>First Name</fe-label>
                <v-text-field
                    flat solo dense
                    v-model="createStudentDialog.rec.fname"
                    placeholder="First Name" required
                    :rules="$fieldValidators('text', 'First name', { required: true, limit: 50 })"
                />

                <fe-label>Last Name</fe-label>
                <v-text-field
                    flat solo dense
                    v-model="createStudentDialog.rec.lname"
                    placeholder="Last Name" required
                    :rules="$fieldValidators('text', 'Last name', { required: true, limit: 50 })"
                />

                <fe-label>Gender</fe-label>
                <v-select
                    flat solo dense
                    :items="['M', 'F']"
                    placeholder="Gender"
                    v-model="createStudentDialog.rec.gender"
                    required
                    :rules="$fieldValidators('select', 'Gender', { required: true })"
                />
            </v-form>
        </fe-dialog>

        <fe-mask v-model="loading" showLoader/>

        <div class="d-flex">
            <div class="page-title">Students</div>

            <div class="ml-auto">
                <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <fe-icon-btn
                            useIcon="fas fa-ellipsis-v"
                            usage="ghost"
                            class="ml-auto mr-1"
                            v-on="on"
                        ></fe-icon-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="doStudentSearch('student_full_name')">
                            <v-list-item-content>Search By Name</v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="doStudentSearch('id')">
                            <v-list-item-content>Search By eduCLIMBER ID</v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="doStudentSearch('unique_id')">
                            <v-list-item-content>Search By Other ID</v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>

        <fe-grid
            ref="grid"
            :gridOptionOverrides="gridOptions"
            :columnDefs="columns"
            :showDensityToolbar="false"
            :showAddRowBtn="false"
            :csvExportProcessCallback="exportData"
            domLayout="normal"
            class="flex-grow-1 grid"
            displayDensity="small"
            @cellClicked="loadStudent"
            @search="gridSearch"
            @searchColumn="gridSearch"
        >
            <template v-slot:after-search-toolbar-items>
                <fe-remote-combo
                    :url="$models.getUrl('schoolYear', 'read')"
                    :clearable="false"
                    rootProperty="years"
                    itemText="name"
                    itemValue="id"
                    v-model="selectedYear"
                    style="width: 150px;"
                    class="ml-2"
                />
            </template>

            <template v-slot:toolbar-items>
                <fe-btn usage="secondary" v-if="canMerge" @click="mergeStudents">Merge Students {{ canMerge }}</fe-btn>

                <fe-btn usage="primary" @click="startCreate">Add</fe-btn>
            </template>
        </fe-grid>

        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.studentGradeHistory"
            :failOnEmptyResponse="false"
        />

        <fe-crud
            ref="doNotMergeCrud"
            :autoload="false"
            :config="$models.studentDoNotMerge"
            disableSnackbars
        />

    </div>
</template>

<script>
    import StudentDataManager from '@/components/modules/student/Index'
    import lodash from 'lodash'
    import StudentsMerge from "./StudentsMerge";

    export default {
        name: 'StudentList',
        components: {
            StudentsMerge,
            StudentDataManager,
        },
        data() {
            return {
                gridOptions: {
                    rowHeight: 30,
                    immutableData: true,
                    getRowNodeId: v => v.student_id,
                    datasource: this,
                    maxConcurrentDatasourceRequests: 3,
                    rowModelType: 'infinite',
                    cacheBlockSize: 500,
                    blockLoadDebounceMillis: 100,
                },
                searchDialog: {
                    rec: null,
                    show: false,
                    label: null
                },
                searchResults: [],
                searchText: null,
                mergeDataIdIndex: [],
                selectedYear: this.$store.state.global.sessionUser.currentYear,
                studentDialog: {
                    show: false,
                    rec: null
                },
                validForm: false,
                loading: false,
                createStudentDialog: {
                    show: false,
                    rec: {}
                },
                mergeById: [],
                mergeByName: [],
                scoreConflicts: false,
                includeDoNotMerge: false,
                noSearchString: true,
            }
        },
        watch: {
            'searchDialog.show'(v) {
                if (v) {
                    this.searchResults = []
                    this.searchText = ''
                }
            },
            'searchDialog.rec'(v) {
                if (v) {
                    this.searchDialog.show = false
                    this.studentDialog.rec = {
                        student_id: v.photo_id
                    }
                    this.studentDialog.show = true
                }
            },
            searchText: lodash.debounce(function(v) {
                if (v && v.length >= 3) {
                    this.noSearchString = false
                    this.doSearch()
                } else {
                    this.noSearchString = true
                }

            }, 500),
            'createStudentDialog.show'(v) {
                if (!v) {
                    this.createStudentDialog.rec = {}
                }
            },
            'studentDialog.show': {
                handler(v) {
                    if (!v) {
                        this.$refs.crud.read({ school_year_id: this.selectedYear.id })
                        .finally(() => {
                            if (this.$refs.grid.filterText?.length) this.$refs.grid.filterResults()
                        })
                        this.loadMerge()
                        this.bringFocusBack('grid')
                    }
                },
                deep: true
            },
            selectedYear(v) {
                this.$refs.grid.gridOptions.api.setDatasource(this)
            },
        },
        computed: {
            canMerge() {
                return this.mergeByName.length + this.mergeById.length
            },
            columns() {
                return [{
                    field: 'student_full_name',
                    headerName: 'Student',
                    width: 200,
                    cellStyle: {
                        cursor: 'pointer'
                    },
                    cellRenderer: v => {
                        let before = this.mergeDataIdIndex[v.data?.student_id]
                            ? '<i style="font-size:12px;" class="fe-grid-icon fas fa-exchange red--text mr-2"></i>'
                            : ''

                        let after = '<i style="font-size:12px;" class="fe-grid-icon fas fa-id-card-alt ml-2 grey--text text--lighten-1"></i>'
                        return before + v.value + after
                    },
                }, {
                    field: 'date_of_birth',
                    headerName: 'Date of Birth',
                    maxWidth: 120,
                }, {
                    field: 'gender',
                    headerName: 'Gender',
                    maxWidth: 80,
                }, {
                    field: 'ethnicity',
                    headerName: 'Ethnicity',
                }, {
                    field: 'home_language_display',
                    headerName: 'Home Language',
                    width: 140,
                }, {
                    field: 'school_name',
                    headerName: 'School',
                }, {
                    field: 'grade_desc',
                    headerName: 'Grade',
                    maxWidth: 100,
                }]
            }
        },
        mounted() {
            this.loadMerge()
        },
        methods: {
            gridSearch: lodash.debounce(function(e) {
                this.$refs.grid.gridOptions.api.setDatasource(this)
            }, 500),
            exportData(params) {
                this.$refs.grid.gridOptions.cacheBlockSize = this.totalRows
                this.$refs.grid.gridOptions.api.setDatasource(this)
                this.exportParams = params
            },
            getRows(params) {
                this.$refs.crud.read({
                    include_count: 1,
                    school_year_id: this.selectedYear.id,
                    start_row: params.startRow,
                    end_row: params.endRow,
                    sort: params.sortModel[0]?.colId,
                    dir: params.sortModel[0]?.sort,
                    search: this.$refs.grid.filterText || undefined,
                    column: this.$refs.grid.filterText
                        && (this.$refs.grid.filterTextColumn.colId != 'fe-grid-all-columns')
                        && this.$refs.grid.filterTextColumn.colId
                        || undefined
                // this.$axios({
                //     method: 'post',
                //     url: 'students.php?property=grade_history&action=get',
                //     params: {
                //         include_count: 1,
                //         school_year_id: this.selectedYear.id,
                //         start_row: params.startRow,
                //         end_row: params.endRow,
                //         sort: params.sortModel[0]?.colId,
                //         dir: params.sortModel[0]?.sort,
                //         column: this.$refs.grid.filterText
                //             && (this.$refs.grid.filterTextColumn.colId != 'fe-grid-all-columns')
                //             && this.$refs.grid.filterTextColumn.colId
                //             || undefined
                //     },
                //     data: {
                //         search: this.$refs.grid.filterText || undefined,
                //     },
                }).then(r => {
                    params.successCallback(r.data.students,r.data.count)
                    this.totalRows = r.data.count
                    this.$refs.grid.gridOptions.api.sizeColumnsToFit()
                    if(this.exportParams) {
                        this.$refs.grid.gridOptions.api.exportDataAsCsv(this.exportParams)
                        this.exportParams = null
                        this.$refs.grid.gridOptions.cacheBlockSize = 500
                    }
                })
            },
            doStudentSearch(type) {
                this.searchDialog.show = true
                this.searchDialog.type = type
                switch (type) {
                    case 'student_full_name':
                        this.searchDialog.label = 'Student Name'
                        break;
                    case 'id':
                        this.searchDialog.label = 'eduCLIMBER ID'
                        break;
                    case 'unique_id':
                        this.searchDialog.label = 'Other ID'
                        break;
                }
            },
            doSearch() {
                let p = {}
                let model = 'student'
                let full_name = false
                let type = this.searchDialog.type
                switch (type) {
                    case 'student_full_name':
                        p = {name: this.searchText}
                        break;
                    case 'id':
                        p = {id: this.searchText}
                        break;
                    case 'unique_id':
                        model = 'studentIds'
                        full_name = true
                        p = {query: this.searchText}
                        break;
                }

                this.$modelGet(model, p).then(v => {
                    this.searchResults = v
                    this.searchResults.forEach(s => {
                        if (!full_name) {
                            s.student_full_name = s.lname + ', ' + s.fname
                            s.photo_id = s.id
                        } else {
                            s.photo_id = s.student_id
                        }
                        s.primary = s[type]
                        s.secondary = type === 'student_full_name' ? s.id : s.student_full_name
                    })
                })
            },
            mergeStudents() {
                let me = this
                me.$store.commit('global/addDockableWindow', {
                    name: 'Merge Students',
                    component: 'students-merge',
                    events: {
                        close() {
                            me.loadMerge()
                        },
                    }
                })
            },
            openStudentCard(v) {
                if (v.colDef.field === 's1_student_full_name') {
                    this.studentDialog.rec = {
                        student_id: v.data.s1_id,
                        fname: v.data.s1_fname,
                        lname: v.data.s1_lname
                    }
                } else {
                    this.studentDialog.rec = {
                        student_id: v.data.s2_id,
                        fname: v.data.s2_fname,
                        lname: v.data.s2_lname
                    }
                }

                this.studentDialog.show = true
            },
            loadMerge() {
                this.mergeDataIdIndex = []
                let incl = this.includeDoNotMerge ? 1 : !this.includeDoNotMerge ? 0 : this.includeDoNotMerge
                this.$modelGet('studentMerge', { property: 'students', include_distinct: incl }).then(items => {
                    this.mergeById = items

                    items.forEach(i => { this.mergeDataIdIndex[i.s1_id] = 1; this.mergeDataIdIndex[i.s2_id] = 1})
                })
                this.$modelGet('studentMerge', { property: 'students', method: 'name', include_distinct: incl }).then(items => {
                    this.mergeByName = items
                    items.forEach(i => { this.mergeDataIdIndex[i.s1_id] = 1; this.mergeDataIdIndex[i.s2_id] = 1})
                })
            },
            startCreate() {
                this.createStudentDialog.show = true
            },
            loadStudent(meta) {
                if (meta.colDef.field === 'student_full_name') {
                    this.studentDialog.show = true
                    this.studentDialog.rec  = meta.data
                }

            },
            createStudent() {
                this.$axios.post('students.php?action=create', this.createStudentDialog.rec)
                    .then(response => {
                        if (response && response.data) {
                            let r = {
                                student_id: response.data.student.id,
                                student_full_name: response.data.student.fname +', '+ response.data.student.lname
                            }

                            this.studentDialog = {
                                show: true,
                                rec: r
                            }
                        }
                        this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                    })
                    .catch(err => {})
                    .finally(() => {})
            },
            bringFocusBack(type) {
                let grid = type == 'grid' ? this.$refs.grid : this.$refs.mergeGrid
                let cell = grid.gridOptions.api.getFocusedCell()

                if ( cell ) {
                    this.$nextTick(() => {
                        grid.gridApi.ensureIndexVisible(cell.rowIndex, 'middle')
                        this.$flashGridRow(grid,cell.rowIndex,3000)
                    })
                }
            },
            getStudentImg(student_id) {
                return this.$axios.defaults.baseURL + this.$models.getUrl('studentImage', 'read') + '&id=' + student_id
            },
        }
    }
</script>

<style lang="scss" scoped>
.user-merge-grid .ag-header-cell {
    background-color: #f5f6f8;
}
.user-merge-header {
    background-color: #fff !important;
    .ag-header-cell-label {
        font-weight: normal;
        text-align: center !important;
    }
}
.fe-grid-container {
    position: relative;
    height: 100%;
    border: 1px solid #e0e1e6;
    border-radius: 5px;
}
</style>
