<template>
    <div class="flex-fill flex-column dashboard">
        <div class="d-flex justify-space-between">
            <div class="d-flex headline">
                <fe-icon-btn
                    useIcon="fas fa-chevron-left"
                    usage="ghost"
                    class="menu-btn"
                    @click="goBack"
                />

                <span class="my-2">
                    {{ dashboard.name }}
                    <span class="year-text">
                        {{ dashboard.school_year_name }}
                    </span>
                </span>
            </div>

            <div class="d-flex top-buttons">
                <div v-if="canViewAs">
                    <fe-btn usage="tertiary" @click="viewAs.show = true">
                        {{ viewAsLabel }}
                    </fe-btn>
                </div>

                <div v-if="dashboard.can_modify && activeTab.show === 'vis' && hasADAccess">
                    <fe-tooltip
                        :tooltip="'Dashboards are limited to ' + visLimit +  ' visualizations'"
                        :disabled="visualizations.length < visLimit"
                        direction="left"
                    >
                        <fe-btn usage="primary" @click="visConfig()" :disabled="visualizations.length > visLimit - 1">
                            New Visualization
                        </fe-btn>
                    </fe-tooltip>
                </div>

                <div v-if="dashboard.can_modify && activeTab.show === 'ap'">
                    <fe-btn
                        id="create-menu-activator"
                        usage="primary"
                        useIcon="fas fa-caret-down"
                        useIconDirection="right"
                        class="create-menu-btn-icon"
                    >
                        Create
                    </fe-btn>
                    <v-menu activator="#create-menu-activator" offset-y>
                        <v-list>
                            <v-list-item @click="create.objective = true">Objective</v-list-item>
                            <v-list-item @click="create.goal = true">Goal</v-list-item>
                            <v-list-item @click="create.task = true">Action Item</v-list-item>
                        </v-list>
                    </v-menu>
                </div>

                <fe-icon-btn
                    v-if="dashboard.can_share && !dashboard.district_default"
                    useIcon="fas fa-user-plus"
                    class="menu-btn"
                    @click="shareDashboard = true"
                />

                <fe-icon-btn
                    v-if="activeTab.show === 'ap'"
                    useIcon="fas fa-print"
                    class="menu-btn"
                    @click="openPrintView = true"
                />

                <ad-menu v-if="dashboardMenuItems.length" :items="dashboardMenuItems"/>
            </div>
        </div>

        <div v-if="!scrolled" class="description-text">{{ dashboard.description }}</div>

        <fe-tabs
            v-if="!scrolled"
            :tabs="tabs"
            style="height: 45px;"
            color="transparent"
            class="tabs"
            noPadding
            @tabClicked="activeTab = $event"
        />

        <visualizations
            v-if="activeTab.show === 'vis'"
            ref="visualizations"
            :dashboard="dashboard"
            :visualizations="visualizations"
            :canModify="!!dashboard.can_modify"
            :unPackConfig="unPackConfig"
            :getSavedSearchValues="getSavedSearchValues"
            @scrolled="scrolled = $event"
            @publicImage="doPublicImage"
            @goal="doGoal"
            @save="saveVis($event, false, true)"
            @edit="visConfig"
            @delete="deleteVis"
            @drilldown="doDrilldown"
            @layout-updated="layoutUpdatedEvent"
        />

        <action-plan
            v-if="activeTab.show === 'ap'"
            :canEdit="dashboard.can_modify"
            :activeDashboard="dashboard"
            :createObjective="create.objective"
            :createGoal="create.goal"
            :createTask="create.task"
            :visualizations="visualizations"
            :unPackConfig="unPackConfig"
            :getSavedSearchValues="getSavedSearchValues"
            @close="closeCreateModals"
            @reload="$emit('reload')"
            ref="actionPlan"
            :openPrintView="openPrintView"
            @close-print="openPrintView = false"
        />

        <div class="dialogs">
            <vis-config
                v-if="config.show"
                :edit="config.edit"
                :dashboard="dashboard"
                :getObjId="getObjId"
                :getSavedSearchValues="getSavedSearchValues"
                @close="config.show = false"
                @save="saveVis"
            />

            <share-dashboard v-if="shareDashboard" :dashboard="dashboard" @close="shareDashboard = false"/>

            <public-image
                v-if="publicImage.show"
                :config="publicImage.config"
                :rect="publicImage.rect"
                :dashboard="dashboard"
                :getSavedSearchValues="getSavedSearchValues"
                @update="saveVis($event, false, true)"
                @close="publicImage.show = false"
            />

            <goal-config
                v-if="goal.show"
                :visConfig="goal.config"
                :getSSValues="getSavedSearchValues"
                @save="saveGoal"
                @close="goal.show = false"
            />

            <drilldown
                v-if="drilldown.show"
                :config="drilldown.config"
                :dashboard="dashboard"
                :getSavedSearchValues="getSavedSearchValues"
                @close="drilldown.show = false"
            />

            <view-as
                v-if="viewAs.show"
                :dashboard="dashboard"
                :viewAs="viewAs.user"
                @viewAs="viewAs.user = $event; viewAs.show = false"
                @dismiss="viewAs.show = false"
            />
        </div>

        <fe-crud ref="goalCrud" :config="goalCrudConfig"/>
    </div>
</template>

<script>
import Visualizations from "./Visualizations"
import ActionPlan from "../actionPlan/Index"
import AdMenu from "./Menu"
import VisConfig from "./config/VisConfig"
import ShareDashboard from "./config/ShareDashboard"
import PublicImage from "./config/PublicImage"
import GoalConfig from "./config/GoalConfig"
import Drilldown from "./Drilldown"
import ViewAs from "./ViewAs"
import {mapState} from "vuex"

export default {
    name: "Dashboard",

    components: {
        Visualizations,
        ActionPlan,
        AdMenu,
        VisConfig,
        ShareDashboard,
        PublicImage,
        GoalConfig,
        Drilldown,
        ViewAs
    },

    props: {
        dashboard: {
            type: Object,
            required: true
        },
        getSavedSearchValues: {
            type: Function,
            required: true
        },
        prepSavedSearchIds: {
            type: Function,
            required: true
        },
        getObjId: {
            type: Function,
            required: true
        }
    },

    data() {
        return {
            scrolled: false,
            dirty: false,
            initialLayout: true,
            shareDashboard: false,
            goalDescriptors: [],
            config: {
                show: false,
                edit: undefined
            },
            goal: {
                show: false,
                config: undefined,
                edit: undefined
            },
            publicImage: {
                show: false,
                config: undefined
            },
            drilldown: {
                show: false,
                config: undefined
            },
            viewAs: {
                show: false,
                user: undefined
            },
            activeTab: {
                name: 'Visualizations',
                show: 'vis',
                path: '#'
            },
            tabs: [{
                name: 'Visualizations',
                show: 'vis',
                path: '#'
            }, {
                name: 'Action Plan',
                show: 'ap',
                path: '#'
            }],
            visualizations: [],
            goals: [],
            create: {
                objective: false,
                goal: false,
                task: false
            },
            openPrintView: false,
        }
    },

    computed: {
        ...mapState('global', ['sessionUser']),

        goalCrudConfig() {
            return this.$_.cloneDeep(this.$models.dashGoals)
        },

        dashboardMenuItems() {
            let items = []

            if (this.dashboard.can_modify) {
                items.push({
                    name: 'Dashboard Details',
                    callback: () => {
                        this.$emit('edit')
                    }
                })
            }

            if (this.dashboard.can_delete) {
                items.push({
                    name: 'Delete',
                    callback: () => {
                        this.$emit('delete', this.dashboard)
                    }
                })
            }

            if (this.dashboard.can_duplicate) {
                items.push({
                    name: 'Duplicate',
                    callback: () => {
                        this.$emit('clone', this.dashboard)
                    }
                })
            }

            return items
        },

        objCrudConfig() {
            let cfg = this.$_.cloneDeep(this.$models.dashObjective)
            cfg.read.params.kpi_dashboard_id = this.dashboard.id
            return cfg
        },

        canViewAs() {
            if (!this.dynamicEnabled) return false
            let creator = this.dashboard.created_by === this.sessionUser.user.id
            return this.dashboard.scope && creator
        },

        viewAsLabel() {
            let label = 'View As'
            if (this.viewAs.user) {
                label = 'Viewing As: ' + this.viewAs.user.user_full_name
            }
            return label
        },

        dynamicEnabled() {
            return this.$store.getters['flags/flags']['Dynamic_Dashboards'] === true
        },

        visLimit() {
            return this.$store.getters['flags/flags']['ec-ad-50-visualizations-limit'] ? 50 : 30
        },

        hasADAccess() {
            return this.$hasSecurity('MANAGE_ACHIEVEMENT_DASHBOARD') || this.$hasSecurity('ACCESS_ACHIEVEMENT_DASHBOARD')
        },
    },

    watch: {
        "viewAs.user"() {
            this.reloadVisualizations(true)
        }
    },

    mounted() {
        this.visualizations = this.dashboard.configs
    },

    methods: {
        goBack() {
            if (this.dirty) {
                this.$emit('update')
            }
            this.$emit('clear')
        },

        reloadVisualizations(force = false) {
            this.initialLayout = true
            if (this.viewAs.user) {
                let id = this.viewAs.user.id
                this.visualizations = this.$_.cloneDeep(this.visualizations).map(x => {
                    x.view_as_user_id = id
                    return x
                })
            } else {
                this.visualizations = this.$_.cloneDeep(this.dashboard.configs)
            }

            if (force) {
                this.$refs.visualizations.forceAllReload()
            }
        },

        unPackConfig(data, i) {
            let cfg = this.$_.cloneDeep(data.config)
            if (!cfg.hasOwnProperty('x')) cfg = this.arrangePanel(cfg)
            if (this.dashboard.scope) {
                cfg.scope = this.dashboard.scope
            }
            return Object.assign({i}, data, cfg)
        },

        rePackConfig(data) {
            let output = this.$_.cloneDeep(data)
            let cfg = this.$_.cloneDeep(output.config)

            if (output.id) delete output.i
            Object.keys(cfg).forEach(x => {
                if (output.hasOwnProperty(x)) {
                    if (x === 'colors' && this.$_.isArray(output[x])) {
                        let colors = {}
                        output[x].forEach(y => {
                            if (y.color) {
                                colors[y.name] = y.color
                            }
                        })
                        cfg[x] = colors
                    } else {
                        cfg[x] = output[x]
                    }
                    delete output[x]
                }
            })

            // add value to config if available (needed for numeric Attendance viz)
            if (!cfg['value'] && output.value) cfg['value'] = output.value

            if (!cfg.hasOwnProperty('x')) cfg = this.arrangePanel(cfg)
            output.config = JSON.stringify(cfg)
            output.kpi_dashboard_id = this.dashboard.id
            return output
        },

        arrangePanel(panel) {
            let config = this.$_.cloneDeep(panel)

            let w = 2
            let h = 10
            let x = 0
            let y = 0
            let minH = 3
            let isResizable = true

            let lastVis = {
                x: 0,
                y: 0,
                h: 0,
                w: 0
            }

            if (this.visualizations.length) {
                this.visualizations.forEach(vis => {
                    let cfg = vis.config

                    if (cfg.y > lastVis.y || (cfg.y === lastVis.y && cfg.x > lastVis.x)) {
                        lastVis = cfg
                    }
                })

                let lastWidth = lastVis.x + lastVis.w
                x = lastWidth < 3 ? lastWidth : 0
                y = lastWidth < 3 ? lastVis.y : lastVis.y + lastVis.h
            }

            if (config.type === 'section') {
                if (!this.$_.isEmpty(config.description)) {
                    h = 3
                } else {
                    h = 2
                }
                x = 0
                y = lastVis.y + lastVis.h
                w = 4
                minH = 2
                isResizable = false
            }

            config.w = w
            config.h = h
            config.x = x
            config.y = y
            config.minH = minH
            config.isResizable = isResizable

            return config
        },

        layoutUpdatedEvent(e) {
            if (!this.initialLayout) {
                this.saveVis(e, null)
            }
            this.initialLayout = false
        },

        visConfig(v) {
            this.config = {
                show: true,
                edit: v
            }
        },

        async saveVis(config, savedSearch = {}, refresh = true) {
            let operation = 'update'
            let transactions = []
            if (!this.$_.isArray(config)) {
                if (!config.hasOwnProperty('id')) {
                    operation = 'create'
                } else {
                    this.$store.commit('global/flagStaleDashboardWidget', {id: config.id})
                }
                if (savedSearch && (Object.keys(savedSearch).length || config.saved_search_id)) {
                    let ssOperation = 'create'
                    let property = ''
                    if (config.saved_search_id) {
                        ssOperation = 'update'
                        property += '&property=dashboard&saved_search_id=' + config.saved_search_id
                    }
                    let ssUrl = this.$models.getUrl('savedSearch', ssOperation) + property
                    let args = await this.prepSavedSearchIds(savedSearch, ssOperation)
                    transactions.push(this.$axios.post(ssUrl, {'saved_searches': args}))
                }
                config = [config]
            }

            Promise.all(transactions)
                .then(res => {
                    let payload = config.map(this.rePackConfig)

                    if (res[0]?.data?.saved_searches?.length) {
                        payload[0].saved_search_id = res[0].data.saved_searches[0].id
                    }

                    let url = this.$models.getUrl('visualizations', operation)
                    this.$axios.post(url, {'kpi_config': payload})
                        .then(res => {
                            this.dirty = true
                            res.data.kpi_config.forEach(x => {
                                this.initialLayout = true
                                if (refresh) {
                                    let toIntegrate = this.$_.cloneDeep(x)
                                    toIntegrate.config = JSON.parse(toIntegrate.config)
                                    if (config[0].scope) {
                                        toIntegrate.scope = config[0].scope
                                        if (config[0].view_as_user_id) {
                                            toIntegrate.view_as_user_id = config[0].view_as_user_id
                                        }
                                    }
                                    let existing = this.visualizations.findIndex(y => y.id === x.id)
                                    if (existing !== -1) {
                                        let toUpdate = this.$_.cloneDeep(this.visualizations)
                                        toIntegrate.goal = this.visualizations[existing].goal
                                        toUpdate[existing] = toIntegrate
                                        this.visualizations = toUpdate
                                    } else {
                                        this.visualizations.push(toIntegrate)
                                    }
                                }
                            })
                            this.config.show = false
                            this.$emit('reload')
                        })
                })
        },

        deleteVis(config) {
            let msg = 'You are attempting to delete ' + config.name + '.'
            if (!!config.publicLink) {
                msg += "<br>This will also delete the associated public image and it will no longer display in external locations where the link is utilized."
            }
            msg += '<br>This action cannot be undone.'
            msg += '<br>Are you sure you want to delete this visualization?'
            this.$confirmDelete(
                config,
                () => {
                    let toDelete = {id: config.id}
                    let url = this.$models.getUrl('visualizations', 'destroy')
                    this.$axios.post(url, {'kpi_config': [toDelete]})
                        .then(res => {
                            if (res.data.success) {
                                this.dirty = true
                                this.visualizations = this.visualizations.filter(x => x.id !== config.id)
                            }
                        })
                },
                null,
                msg
            )
        },

        doPublicImage(config, rect) {
            this.publicImage.config = config
            this.publicImage.rect = rect
            this.publicImage.show = true
        },

        doGoal(config, destroy = false) {
            if (destroy) {
                let msg = 'You are attempting to delete the goal for ' + config.name + '.'
                msg += '<br>This action cannot be undone.'
                msg += '<br>Are you sure you want to delete this goal?'
                this.$confirmDelete(
                    config.goal,
                    () => {
                        this.$refs.goalCrud.destroy({id: config.goal.id})
                            .then(() => {
                                this.$store.commit('global/flagStaleDashboardWidget', {id: config.id})
                                let existing = this.visualizations.findIndex(y => y.id === config.id)
                                let toUpdate = this.$_.cloneDeep(this.visualizations)
                                toUpdate[existing].goal = null
                                this.visualizations = toUpdate
                            })
                    },
                    null,
                    msg
                )
            } else {
                this.goal = {
                    show: true,
                    config: config
                }
            }
        },

        async saveGoal(visConfig, config, savedSearch) {
            let operation = config.id ? 'update' : 'create'
            let doSavedSearch = visConfig.type === 'assessment'

            let transactions = []

            if (doSavedSearch) {
                let property = operation === 'update' ? '&property=dashboard&saved_search_id=' + config.saved_search_id : ''
                let ssUrl = this.$models.getUrl('savedSearch', operation) + property
                let args = await this.prepSavedSearchIds(savedSearch, operation)
                transactions.push(this.$axios.post(ssUrl, {'saved_searches': args}))
            }

            Promise.all(transactions)
                .then(ssRes => {
                    if (operation === 'create') {
                        if (doSavedSearch) {
                            config.saved_search_id = ssRes[0].data.saved_searches != undefined ? ssRes[0].data.saved_searches[0].id : visConfig.saved_search_id
                        }
                        config.kpi_config_id = visConfig.id
                        config.kpi_dashboard_id = visConfig.kpi_dashboard_id
                    } else {
                        delete config.action_plan_objective_id
                        delete config.action_plan_objective_link_id
                        delete config.rank
                        delete config.tasks
                        delete config.active
                    }

                    this.$refs.goalCrud[operation](config)
                        .then(res => {
                            this.$store.commit('global/flagStaleDashboardWidget', {id: visConfig.id})
                            this.dirty = true
                            let goal = res.data.goal[0]
                            let index = this.visualizations.findIndex(y => y.id === visConfig.id)
                            let toUpdate = this.$_.cloneDeep(this.visualizations)
                            toUpdate[index].goal = goal
                            this.visualizations = toUpdate
                            this.goal.show = false
                        })
                })
        },

        doDrilldown(config, params) {
            this.drilldown.config = Object.assign({}, config, params)
            this.drilldown.show = true
        },

        closeCreateModals() {
            this.create = {
                objective: false,
                goal: false,
                task: false
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.headline {
    ::v-deep .v-btn {
        margin-left: -10px;
    }

    .year-text {
        margin-left: 6px;
        font-size: 16px;
        color: #6A78A1;
    }
}

.menu-btn {
    ::v-deep .v-btn {
        margin-left: 0;
        margin-right: 0;

        .v-icon {
            color: #050F2D !important;
        }
    }
}

.description-text {
    font-size: 14px;
    color: #050F2D;
}

.tabs {
    ::v-deep .fe-tabs-color {
        .v-tab a {
            color: #b3b7c5 !important;
        }

        .v-tab--active a {
            color: #006C90 !important;
        }
    }

    ::v-deep .v-tabs-slider {
        background-color: #006C90 !important;
        border-color: #006C90 !important;
    }
}

.dashboard-split-btn {
    .v-list-item {
        min-height: 38px !important;
    }
}

.create-menu-btn-icon {
    ::v-deep .fa-caret-down {
        color: #fff !important
    }
}
</style>
