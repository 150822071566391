<template>
    <span class="toggle-button">
        <div class="d-flex">
            <div v-ripple
                class="show-charts px-4"
                @click="localValue=item"
                :class="{selected: item.name==localValue.name, first: i===0, last: i===items.length-1}"
                v-for="(item, i) in items"
                :key="`show-item-`+i"
            >
                <v-icon class="mr-2" v-if="item.icon">{{item.icon}}</v-icon>
                {{item.name}}
            </div>
        </div>
    </span>
</template>

<script>
    export default {
        name: 'ToggleButton',
        props: {
            items: {
                type: Array,
                required: true
            },
            value: {

            }
        },
        watch: {
            value(v) {
                this.localValue=v
            },
            localValue(v) {
                this.$emit('input', v)
            }
        },
        created() {
            this.localValue = this.$props.value
        },
        data() {
            return {
                localValue: null
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../../../style/educlimber.scss";

.toggle-button {
    background: #FFFFFF;
    border-radius: 4px;
    height: 32px;
    display: inline-block;

    .first {
        border: 1px solid #E0E1E6;
        border-radius: 4px 0px 0px 4px;
    }

    .last {
        border-right: 1px solid #E0E1E6;
        border-top: 1px solid #E0E1E6;
        border-bottom: 1px solid #E0E1E6;
        border-radius: 0px 4px 4px 0px;
    }

    .selected {
        background: var(--fe-selected);
    }

    div {
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        color: #050F2D !important;

        ::v-deep .v-icon {
            color: #050F2D;
        }
    }
}
</style>
