<template>
    <div class="d-flex flex-column flex-fill">
        <div class="d-flex align-center">
            <h3>Strategies (optional)</h3>
            <v-spacer/>
            <fe-btn v-if="strategies.length"
                usage="primary"
                @click="activeStrategy = null; showDialog = true;"
            >
                Add
            </fe-btn>
        </div>
        <div v-if="!strategies.length" class="d-flex flex-fill flex-column">
            <v-layout align-center justify-center column fill-height text-center>
                <img width="149px" src="/images/no-items2.svg" />
                <br/>
                <h3>This SLO has no strategies</h3>
                <br/>
                <br/>
                <fe-btn usage="tertiary" @click="showDialog = true">Add</fe-btn>
            </v-layout>
        </div>

        <div style="overflow: auto;">
            <strategies-card
                v-for="(strategy, strategyIndex) in strategies"
                :key="strategy.id"
                :slo="slo"
                :filters="strategyFilters"
                :strategy="strategy"
                :statuses="statusItems"
                @edit="onEditStrategy"
                @deleted="$emit('deleted', $event)"
            />
        </div>

        <strategies-modal
            v-if="showDialog"
            :slo="slo"
            :item="activeStrategy"
            :canEditExisting="false"
            @created="showDialog = false; $emit('created', $event)"
            @updated="showDialog = false; $emit('updated', $event)"
            @close="showDialog = false"
            :dryRun="dryRun"
        />
    </div>
</template>

<script>
    import WeekdayPicker from '@/components/common/button/WeekdayPicker'
    import StrategiesModal from '@/components/modules/slo/panel/StrategiesModal'

    import StrategiesCard from '@/components/modules/slo/panel/StrategiesCard'

    export default {
        name: 'SloCreationDatawallStrategies',
        components: {
            WeekdayPicker,
            StrategiesModal,
            StrategiesCard,
        },
        props: {
            slo: { type: Object, required: true },
            strategies: { type: Array, required: true },
            dryRun: { type: Boolean, required: true },
        },
        data() {
            return {
                gridApi: null,
                showDialog: false,
                isRecurring: true,
                localModel: {
                    strategy_text: null,
                    slo_strategy_users: [],
                    one_time_date: null,
                    start_date: null,
                    strategy_end_date: null,
                    start_time: null,
                    end_time: null,
                    schedule_frequency_cd: null, // DAY for single or WEEK for recurring
                    schedule_frequency_cnt: null,
                    schedule_week_days: null,
                },
                activeStrategy: null,
                // filters are not used within create/edit modal; however, render
                // component expects them
                strategyFilters: {},
                statusItems: [
                    { id: 0, name: 'To Do' },
                    { id: 1, name: 'Done' },
                    { id: 2, name: 'Overdue' }
                ],
                pendingUsers: [],
                learnedUsers: [],
                rules: {
                    endDate: [
                        v => !this.$dayjs(v).isBefore(this.startDate) || 'Not a valid End Date'
                    ].concat(this.$fieldValidators('text', 'End date', { required: true })),
                    endTime: [
                        v => !this.$dayjs('2000-01-01 ' + v).isBefore('2000-01-01 ' + this.startTime) || 'Not a valid End Time'
                    ].concat(this.$fieldValidators('text', 'End time', { required: true })),
                    frequency: [
                        (v) => { return (v &&
                            `${v}`.match(/^\s*[0-9]*\s*$/) &&
                            !isNaN(parseInt(v)) &&
                            parseInt(v) >= 1) || 'Not a valid frequency'
                        }
                    ]
                },
            }
        },
        methods: {
            async onEditStrategy(strategy) {
                this.activeStrategy = strategy
                this.showDialog = true
            },
            async onDeleteStrategy(strategy) {
                this.$emit('deleted', strategy)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .date-range-error {
        .v-input.fe-date-range-text-field .v-input__slot {
            border-color: #ff5252;
        }
    }
    .days-of-week-errors {
        text-indent: 10px;
    }
    .date-range-errors {
        position: relative;
        height: 0px;
        overflow-y: visible;
        top: -20px;
        text-indent: 10px;
    }
</style>
