<template>
    <div>
        <fe-dialog
            :title="getTitle"
            width="600"
            height="460"
            @dismiss="cancel"
            @close="cancel"
            @accept="save"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            :acceptButtonDisabled="disableSave"
        >
            <fe-text-field
                label="Custom Statement"
                v-model="config.custom_statement"
                width="100%"
                showRemainingCount
                :characterLimit="200"
            />
            <fe-remote-combo
                v-if="!linkedVis"
                label="Select Visualization (Optional)"
                v-model="visualization"
                :items="visOptions"
                @input="setVisConfig"
                hide-details
            />
            <div v-if="visualization && !linkedVis" class="preview-label">
                Visualization Preview
            </div>
            <visualization
                v-if="visualization && !linkedVis"
                ref="vis"
                :config="getConfig"
                :dashboard="activeDashboard"
                :getSSValues="getSavedSearchValues"
            />
            <fe-remote-combo
                v-if="visualization"
                label="Our goal will be to have..."
                :items="operatorOptions"
                v-model="config.operator"
                byId
                required
                hide-details
            />
            <fe-text-field
                v-if="visualization"
                type="positiveFloat"
                label="A Value of"
                v-model="config.value"
                validate
                required
            />
            <fe-remote-combo
                v-if="config.assessment"
                label="Based On..."
                :items="typeOptions"
                v-model="config.type"
                required
                byId
                hide-details
            />
            <fe-remote-combo
                v-if="config.assessment"
                url='targetSetV.php?action=get'
                label='In the following combined performance bands...'
                v-model="savedSearch.targetDescriptorId"
                :params="getDescriptorFilters"
                rootProperty='descriptors'
                multiple
                required
                byId
                hide-details
            />
            <fe-remote-combo
                label="Link to Objective (Optional)"
                v-model="config.objective"
                :items="objectives"
                byId
                hide-details
            />
            <fe-text-area
                label="Description (Optional)"
                v-model="config.description"
                width="100%"
                height="86"
            />
        </fe-dialog>
        <fe-crud ref="crud" :config="crudConfig"/>
    </div>
</template>

<script>
import {mapState} from "vuex"
import Visualization from "../../achievementDashboard/Visualization";

export default {
    name: 'CreateGoal',

    components: {Visualization},

    props: {
        activeDashboard: {type: Object, default: () => {}},
        objectives: {type: Array, default: () => []},
        unPackConfig: {type: Function}
    },

    data() {
        return {
            config: {
                custom_statement: null,
                visualization: null,
                objective: null,
                description: null,
                operator: null,
                value: null,
                type: null,
                assessment: false
            },
            localGoal: {},
            linkedVis: false,
            ready: false,
            savedSearch: {
                targetSetType: undefined,
                targetSetId: undefined,
                targetDescriptorOptions: [],
                targetDescriptorId: undefined
            },
            typeOptions: [{
                id: 'count',
                name: 'Total Count/Value'
            }, {
                id: 'pct',
                name: 'Total Percent'
            }],
            operatorOptions: [{
                id: 'less',
                name: 'Less Than'
            }, {
                id: 'greater',
                name: 'Greater Than'
            }, {
                id: 'equal',
                name: 'Equal To'
            }],
            items: [],
            visualization: null,
            visOptions: [],
            visualizations: []
        }
    },

    computed: {
        ...mapState('actionPlan', ['goal', 'currentConfigsNoGoal']),
        getTitle() {
            return this.goal && Object.keys(this.goal).length ? 'Edit Goal' : 'Create Goal'
        },
        getConfig() {
            return this.visualization ? this.items.find(x => x.id == this.visualization) : {}
        },
        crudConfig() {
            return this.$_.cloneDeep(this.$models.savedSearchValues)
        },
        disableSave() {
            if (!this.config.custom_statement) return true
            else {
                if (this.visualization) {
                    let returnVal = !(this.config.operator && this.config.value)
                    if (this.config.assessment) {
                        returnVal = returnVal && this.config.type && this.savedSearch.targetDescriptorId
                    }
                    return returnVal
                }
            }
            return false
        }
    },

    mounted() {
        this.render()
    },

    methods: {
        cancel() {
            this.config = {
                custom_statement: null,
                visualization: null,
                objective: null,
                description: null,
                operator: null,
                value: null,
                type: null,
                assessment: false
            }
            setTimeout(function() {
                this.$store.commit('actionPlan/set', {
                    key: 'goal',
                    value: null
                })
            }.bind(this), 1000)
            this.$emit('close')
        },

        save() {
            this.$emit('save', this.config, this.savedSearch)
            this.cancel()
        },

        setVisConfig(config) {
            if (config) {
                if (!config.id) {
                    config = this.visualizations.find(x => x.id == config)
                }
                this.config.visualization = config
                this.visualization = config.id
                this.config.saved_search_id = config.saved_search_id
                if (config.config.type === 'assessment') {
                    this.config.assessment = true
                    this.setAssessmentValues()
                } else {
                    this.config.assessment = false
                    if (this.goal) {
                        this.setGoalConfig()
                    } else {
                        this.ready = true
                    }
                }
            } else {
                this.config.assessment = false
                if (this.goal) {
                    this.setGoalConfig()
                } else {
                    this.ready = true
                }
            }
        },

        setGoalConfig() {
            if (this.config.assessment && this.config.saved_search_id) {
                this.$refs.crud.read({id: this.config.saved_search_id})
                    .then(response => {
                        if (response.data.saved_search_values.hasOwnProperty('TARGET_DESCRIPTOR_ID') && response.data.saved_search_values['TARGET_DESCRIPTOR_ID'].length) {
                            this.savedSearch.targetDescriptorId = response.data.saved_search_values['TARGET_DESCRIPTOR_ID']
                        }
                        this.ready = true
                    })
            } else {
                this.ready = true
            }
        },

        setAssessmentValues() {
            this.$refs.crud.read({id: this.config.saved_search_id})
                .then(response => {
                    Object.keys(response.data.saved_search_values).forEach(item => {
                        let data = response.data.saved_search_values[item]
                        switch (item) {
                            case 'TARGET_SET_ID':
                                this.savedSearch.targetSetId = data[0].id
                                break;
                            case 'TARGET_DESCRIPTOR_ID':
                                this.savedSearch.targetDescriptorOptions = data.map(x => x.id)
                                break;
                            case 'TARGET_TYPE':
                                this.savedSearch.targetSetType = data[0].id
                                break;
                        }
                    })
                    if (this.goal) {
                        this.setGoalConfig()
                    } else {
                        this.ready = true
                    }
                })
        },

        getDescriptorFilters() {
            let savedSearch = this.savedSearch
            return [{
                required: true,
                field: 'types',
                value: savedSearch.targetSetType
            }, {
                required: true,
                field: 'target_set_id',
                value: savedSearch.targetSetId
            }, {
                required: true,
                field: 'id',
                value: savedSearch.targetDescriptorOptions
            }]
        },

        getSavedSearchValues(id, savedSearch = {}) {
            return new Promise(resolve => {
                if (!id) {
                    resolve({savedSearch})
                }
                let url = this.$models.getUrl('savedSearchValues', 'read') + '&id=' + id
                this.$axios.get(url)
                    .then(res => {
                        let ssValues = res.data.saved_search_values
                        Object.keys(ssValues).forEach(key => {
                            let value = ssValues[key]
                            if (value.length) {
                                let keyLower = key.toLowerCase()
                                let multi = [
                                    'school_id',
                                    'grade_id',
                                    'ethnicity',
                                    'disability_type_id',
                                    'tag_id',
                                    'incident_behavior_type_id',
                                    'incident_behavior_id',
                                    'incident_response_id',
                                    'target_descriptor_id'
                                ]
                                if (multi.includes(keyLower)) {
                                    savedSearch[keyLower] = value
                                } else {
                                    savedSearch[keyLower] = value[0]
                                }
                            }
                        })
                        resolve({savedSearch})
                    })
            })
        },

        render() {
            this.config = {
                custom_statement: null,
                visualization: null,
                objective: null,
                description: null,
                operator: null,
                value: null,
                type: null,
                assessment: false
            }
            let dash = this.$_.cloneDeep(this.activeDashboard)
            this.visualizations = dash.configs
            this.visOptions = this.visualizations.filter(x => {
                return x.goal ? x.config.type !== 'section' && x.goal?.kpi_config_id != x.id : x.config.type !== 'section'
            })
            if (this.visOptions.length) {
                this.items = this.visOptions.map(this.unPackConfig)
            }
            if (this.goal && Object.keys(this.goal).length) {
                this.localGoal = this.$_.cloneDeep(this.goal)
                this.config = Object.assign(this.config, this.localGoal)
                if (this.localGoal.action_plan_objective_id) {
                    this.config.objective = this.localGoal.action_plan_objective_id
                }
                if (!this.config.custom_statement) {
                    this.localGoal.custom_statement = this.config.default_statement
                }
                if (this.localGoal.kpi_config_id) {
                    this.setVisConfig(this.localGoal.kpi_config_id)
                    this.linkedVis = true
                } else {
                    this.setVisConfig(false)
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.preview-label {
    color: #0000008a;
    font-size: 12px;
    margin: 10px 0 0 5px;
}
</style>
