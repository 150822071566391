<template>
    <TabPane
        emptyTitle="Apply a smartFORM"
        emptyBody="To get started, add a smartFORM."
        emptyButton="Add a smartFORM"
        :pending="pending"
        :error="error"
        :count="count"
        @emptyClick="adding = true"
    >

        <template slot="icon">
            <h3 class="card__title card__title--group">Manage Applied smartFORMs</h3>
        </template>

        <template slot="buttons">
            <fe-btn usage="ghost" :disabled="!success && !error" text icon @click="reset">
                <v-icon>refresh</v-icon>
            </fe-btn>
        </template>

        <template slot="content">
            <fe-grid
                ref="grid"
                domLayout="autoHeight"
                :showToolbar="false"
                :columnDefs="columnDefs"
                :rowData="rowData"
                :styles="{ height: null }"
                @rowDataChanged="onSelectionChanged"
                @selectionChanged="onSelectionChanged"
            />

            <fe-btn class="mt-5" usage="tertiary" @click="adding = true">Add smartFORMs</fe-btn>
        </template>

        <template slot="modals">
            <FormSelector
                :active="adding"
                :hidden-ids="existingFormIds"
                @apply="addForms"
                @cancel="adding = false"
            />

            <DeleteFooter
                modelName="applied smartFORM"
                modelNameLower="applied smartFORM"
                modelNameTitle="Applied smartFORMs"
                :selectedIndexes="selectedIndexes"
                :items="rowData"
                @selectAll="() => $refs.grid.selectAll()"
                @deselectAll="() => $refs.grid.deselectAll()"
                @deleteSelection="removeForms"
            />
        </template>
    </TabPane>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import FormSelector from '../modals/FormSelector'
import TabPane from '../templates/TabPane'
import DeleteFooter from '../templates/DeleteFooter'

export default {
    name: 'Smartform',

    components: {
        FormSelector,
        TabPane,
        DeleteFooter
    },

    data() {
        return {
            selectedIndexes: [],
            adding: false
        }
    },

    computed: {
        ...mapGetters('thresholds/group/form', [
            'collection',
            'clear',
            'pending',
            'success',
            'error',
            'hasDirtied',
            'hasRemoved',
            'hasAdded',
            'hasChanges',
            'rowData',
            'count'
        ]),

        thresholdGroupId() {
            return this.$store.getters['thresholds/group/selected'].id
        },

        columnDefs() {
            return [
                {headerName: null, headerCheckboxSelection: true, checkboxSelection: true, width: 70, minWidth: 70},
                {headerName: 'Name', field: 'form_name', sortable: true},
                {
                    headerName: 'Performance Band',
                    field: 'threshold_performance_band_id',
                    sortable: true,
                    editable: true,
                    cellRendererFramework: 'FeGridChipSelect',
                    cellRendererParams: {
                        rowDataKey: 'threshold_performance_band_id',
                        mode: 'render',
                        multiple: true,
                        items: this.$store.getters['thresholds/group/performanceBand/collection'],
                        keyProp: 'id',
                        labelProp: 'name'
                    },
                    cellEditorFramework: 'FeGridChipSelect',
                    cellEditorParams: {
                        rowDataKey: 'threshold_performance_band_id',
                        mode: 'edit',
                        multiple: true,
                        items: this.$store.getters['thresholds/group/performanceBand/collection'],
                        keyProp: 'id',
                        labelProp: 'name'
                    },
                    onCellValueChanged: this.updateCell,
                    cellStyle: {padding: 0}
                },
                {headerName: 'Created', field: 'created', sortable: true}
            ]
        },

        hasSelection() {
            return (this.selectedIndexes.length > 0)
        },

        existingFormIds() {
            return this.collection.map(itm => itm.form_id)
        }
    },

    watch: {
        clear: (v) => {
            if (v) this.reset()
        }
    },

    mounted() {
        if (this.clear) this.reset()
    },

    updated() {
        if (this.clear) this.reset()
    },

    methods: {
        ...mapActions('thresholds/group/form', [
            'refresh',
            'add',
            'remove',
            'update',
            'reconcile'
        ]),

        updateCell(params) {
            this.update(params.data)
            this.$refs.grid.resize()
        },

        onSelectionChanged(params) {
            this.selectedIndexes = (params.api)
                ? params.api.getSelectedRows().map(itm => itm.__idx)
                : []
        },

        addForms(forms) {
            this.add(forms.map(form => {
                return {
                    threshold_group_id: this.thresholdGroupId,
                    form_name: form.name,
                    form_id: form.id,
                    threshold_performance_band_id: []
                }
            }))
            this.adding = false
        },

        removeForms() {
            this.remove({indexes: this.selectedIndexes})
            this.selectedIndexes = []
        },

        reset() {
            this.refresh()
            this.selectedIndexes = []
        },

        save() {
            this.reconcile()
        }
    }
}
</script>
