<template>
    <fe-dialog
        title="Change Password"
        width=500
        v-if="localValue"
        disableAutoclose
        @dismiss="handleClose"
        @close="handleClose"
        @accept="updatePswd"
        :acceptButtonText="saving?'Saving. Please wait...':'Save'"
        :acceptButtonDisabled="!valid||saving"
    >
        <v-form :disabled="saving" v-model="valid" ref="form" class="d-flex flex-column flex-fill">

            <div v-if="passwordExpiringSoon" style="margin-bottom:1em;color:#F44336;">Your password is expiring soon. Please update it now.</div>

            <div v-if="!shareableStores.user.reset_pswd">
                <fe-label>Current Password</fe-label>
                <v-text-field
                    type="password"
                    v-model="curpswd"
                    required
                    :rules="rules.currentPassword"
                    autocomplete="current-password"
                    flat solo dense
                />
            </div>

            <fe-label>New Password</fe-label>
            <v-text-field
                type="password"
                v-model="pswd1"
                required
                :rules="rules.newPassword"
                autocomplete="new-password"
                @blur="$refs.form.validate()"
                flat solo dense
            />

            <fe-label>Confirm New Password</fe-label>
            <v-text-field
                type="password"
                v-model="pswd2"
                :rules="rules.newPassword"
                required
                autocomplete="new-password"
                @blur="$refs.form.validate()"
                flat solo dense
            />

            <div style="color: red; font-size: 12px;" v-if="pswd1 != pswd2">Password do not match</div>
        </v-form>
    </fe-dialog>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'ChangeUserPassword',
        props: ['value'],
        watch: {
            value(v) {
                this.localValue = v
            },
            localValue(v) {
                this.$emit('input', v)
            }
        },
        mounted() {
            this.$data.localValue = this.$props.value
            this.passwordExpiringSoon = this.shareableStores?.user?.password_expiring_soon || false
        },
        data() {
            return {
                curpswd: '',
                pswd1: '',
                pswd2: '',
                passwordExpiringSoon: false,
                valid: false,
                saving: false,
                dialogOpen: false,
                localValue: null,
                rules: {
                    currentPassword: [
                        // required
                        v => !!v || 'Required.',
                    ],
                    newPassword: [
                        // required
                        v => !!v || 'Required.',
                        // minimumLength
                        v =>  v.length >= this.shareableStores.pml || ('Password not strong enough. Minimum ' + this.shareableStores.pml + ' characters.'),
                        // not all the same character
                        v => !/^(.)\1+$/.test(v) || ('Password must not be a string of the same character.'),
                        // matching - we display this error separately above
                        v => this.pswd1 == this.pswd2,
                    ],
                }
            }
        },
        computed: {
            ...mapState('global', ['shareableStores']),
        },
        methods: {
            handleClose() {
                // clear passwords
                this.curpswd = ''
                this.pswd1 = ''
                this.pswd2 = ''
                this.localValue = false
            },
            updatePswd() {
                let params = new URLSearchParams()
                params.append('curpswd', this.curpswd)
                params.append('pswd1', this.pswd1)
                params.append('pswd2', this.pswd2)

                this.saving = true
                this.$axios.post('users.php?action=update&property=self', params)
                .then((response) => {
                    let data = this.$ecResponse(response)
                    if (!data.success) {
                        return
                    }

                    this.shareableStores.user.reset_pswd = false
                    this.shareableStores.user.password_expiring_soon = false
                    this.passwordExpiringSoon = false
                    this.handleClose()
                })
                .catch((error) => console.warn(error))
                .finally(() => this.saving = false)
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
