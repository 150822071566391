import { datadogRum } from '@datadog/browser-rum';
import store from '../vuex';

const rum = {
    startRum: () => {
        datadogRum.init({
            applicationId: '510e2a14-3991-44ca-b6af-081ab5436bba',
            clientToken: 'pub69a02cbbcbd19bd5e5b7d377ccbbd22f',
            site: 'datadoghq.com',
            service:'ec-web',
            env : (location.hostname == 'localhost' || location.hostname.indexOf('.dev') != -1) ? 'dev'
                : location.hostname.indexOf('.app') != -1 ? 'stage'
                : 'prod',
            // version: '',
            sessionSampleRate: 20,
            sessionReplaySampleRate: 10,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            trackFrustrations: true,
            defaultPrivacyLevel: 'mask',
        })
    },

    setUserSession: () => {
        if(store.state.global.sessionUser) {
            datadogRum.setUser({
                id: store.state.global.sessionUser.user.id,
                district: store.state.global.sessionUser.district.login_id,
            })
        }
    },

    startSession: () => {
        datadogRum.startSessionReplayRecording()
    },
}

export default rum
