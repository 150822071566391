import Vue from "vue";

export default Vue.extend({
    template: `<div class="d-flex flex-row">
                   <div class="d-flex flex-column">
                        <div class="d-flex">
                            <fe-tooltip :tooltip="getTooltip" :disabled="noTooltip">
                                <span>{{getValue}}</span>
                            </fe-tooltip>
                            <fe-tooltip v-if="defaultDataIds.includes(params.data.column) && !params.data.userCreatedDuplicate"
                                tooltip='This form field is a system default field that can be added to your export.'>
                                <v-icon color="#7E8494" class="ml-2" style="font-size: 14px">fal fa-info-circle</v-icon>
                            </fe-tooltip>
                            <fe-tooltip v-if="defaultDataIds.includes(params.data.column) && params.data.userCreatedDuplicate"
                                tooltip='A field exists with the same data ID.'>
                                <v-icon color="#BF0D00" class="ml-2" style="font-size: 14px">fal fa-info-circle</v-icon>
                            </fe-tooltip>
                        </div>
                    </div>
                    <v-spacer/>
                    <div ref="rowDragger">
                        <i class="fe-grid-icon far fa-ellipsis-v" style="color: #C0C3CF"/>
                        <i class="fe-grid-icon far fa-ellipsis-v" style="color: #C0C3CF"/>
                    </div>
                </div>`,
    mounted() {
        this.$nextTick(()=> {
            let rowDragger = this.$refs.rowDragger
            this.params.registerRowDragger(rowDragger)
        })
    },
    computed: {
        getValue() {
            let val = this.params.data.column ? this.params.data.column : this.params.data.data.column
            if(val.toString().length > 35) val = val.toString().slice(0, 35) + '...'
            return val
        },
        getTooltip() {
            return this.params.data.column ? this.params.data.column : this.params.data.data.column
        },
        noTooltip() {
            return this.params.data.column ? this.params.data.column.length <= 50 : this.params.data.data.column.length <= 50
        }
    },
    data() {
        return {
            defaultDataIds: [
                'Template', 'Form Name', 'Created', 'Created By User', 'Student', 'Student ID',
                'Student District ID', 'School', 'Grade', 'School Year', 'Inactive Date'
            ]
        }
    }
})