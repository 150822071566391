<template>
    <v-input v-model="localValue" class="day-selector" :rules="[v => !required || !!v.length || 'Select at least one day']">
        <div>
            <fe-label>Day(s) of the week</fe-label>
            <div class="d-flex">
                <div v-for="day in days"
                    @click="toggleDay(day)"
                    class="day-select text-no-select"
                    :class="{'day-select-selected': localValue ? isSelected(day) : false}"
                >{{day.name}}</div>
            </div>
        </div>
    </v-input>
</template>

<script>
    export default {
        name: 'WeekdayPicker',
        props: {
            value: {
                type: String,
                default: ''
            },
            required: {
                type: Boolean,
                default: false,
            },
        },
        watch: {
            value: {
                handler(v) {
                    if (v) {
                        this.localValue = v
                    } else {
                        this.localValue = ''
                    }
                },
                immediate: true
            }
        },
        data() {
            return {
                localValue: '',
                days: [{
                    id: 'M',
                    name: 'M'
                }, {
                    id: 'T',
                    name: 'Tu'
                }, {
                    id: 'W',
                    name: 'W'
                }, {
                    id: 'H',
                    name: 'Th'
                }, {
                    id: 'F',
                    name: 'F'
                }]
            }
        },
        created() {

        },
        methods: {
            isSelected(day) {
                return this.localValue.toLowerCase().indexOf(day.id.toLowerCase()) == -1 ? false : true
            },
            toggleDay(day) {
                let f = this.localValue.toLowerCase().indexOf(day.id.toLowerCase())==-1 ? false : true
                if (f) {
                    this.localValue = this.localValue.replace(day.id, '')
                } else {
                    this.localValue+=day.id
                }
                this.$emit('input', this.localValue.toUpperCase())
            }
        }
    }
</script>

<style lang="scss" scoped>
.day-selector {
    margin-left: -8px;
}
.day-selector::v-deep .v-messages__wrapper {
    padding-left: 20px;
}
.day-select {
    cursor: pointer;
    border: 1px solid #C0C3CF;
    box-sizing: border-box;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin: 4px 8px 4px 0px;

    &-selected {
        color: white;
        background-color: var(--v-primary-base);
    }
}
</style>
