<template>
    <v-layout column>
        <div class="section-title mb-4" style="font-size: 18px;">Custom Fields</div>
        <fe-crud
            ref="crud"
            :config="$models.incidentField"
            @read="v => items = v"
        />

        <fe-crud
            ref="optionCrud"
            :autoload="false"
            :config="$models.incidentFieldOption"
            @read="v => optionItems = v"
        />

        <fe-crud
            ref="aliasCrud"
            :autoload="false"
            :config="$models.incidentFieldAlias"
            @read="v => aliasItems = v"
        />

        <fe-dialog
            width="600"
            title="Import Aliases"
            v-if="aliasDialog.show"
            :footer="false"
            persistent
            @close="aliasDialog.show=false"
        >
            <fe-grid
                :rowData="aliasItems"
                :autoload="false"
                :columnDefs="aliasColumns"
                style="height: 300px;"
                :showToolbar="false"
                displayDensity="medium"
            />

            <div class="d-flex">
                <v-text-field style="margin-top: 6px;"
                    flat solo dense
                    v-model="aliasDialog.rec.alias"
                ></v-text-field>
                <fe-btn usage="primary" :disabled="!aliasDialog.rec.alias" @click="addAlias">Add</fe-btn>
            </div>

        </fe-dialog>

        <fe-dialog
            width="600"
            title="Create Field"
            v-if="addDialog.show"
            @dismiss="addDialog.show=false"
            @accept="saveRecord"
            acceptButtonText="Save"
            :acceptButtonDisabled="!addDialog.record.label"
            persistent
        >
            <v-layout row>
                <v-flex xs6>
                    <v-form>
                        <label-field labelText="Field Name">
                            <v-text-field v-model="addDialog.record.label" flat solo dense/>
                        </label-field>

                        <label-field labelText="Field Type">
                            <v-select
                                :items="fieldTypes"
                                v-model="addDialog.record.incident_field_type_id"
                                itemValue="id"
                                itemText="name"
                                required
                                flat solo dense
                            />
                        </label-field>
                    </v-form>
                </v-flex>

                <v-flex xs6 class="pl-2">
                    <div class="pa-4" style="height: 100%; background-color: #ECEDF1">
                        <label-field :labelText="addDialog.record.label" class="pt-1">
                            <component disabled :is="previewFieldType" flat solo dense/>
                        </label-field>
                    </div>
                </v-flex>

            </v-layout>
        </fe-dialog>

        <FeGrid
            ref="grid"
            v-show="gridItems.length > 0"
            :showToolbar="false"
            :columnDefs="columnDefs"
            :rowData="gridItems"
            domLayout="autoHeight"
            displayDensity="medium"
            class="ec-basic-grid"
            minimal
            :bordered="false"
        >
            <template #cellDialog="{cell}">
                <template v-if="cell.column.colId === 'counts' && cell.data.incident_field_type_id === 4">
                    <div class="flex-fill no-scroll flex-column">

                        <div class="d-flex justify-space-between align-center pt-3 pr-2">
                            <v-flex grow class="grid-panel-title">List Options</v-flex>
                            <v-flex shrink text-xs-right>
                                <v-btn icon small usage="ghost" @click="$refs.grid.openMenu = false"><v-icon>close</v-icon></v-btn>
                            </v-flex>
                        </div>

                        <FeGrid
                            style="width: 600px; padding: 20px 20px 20px 20px; height: 300px;"
                            ref="optionGrid"
                            :showToolbar="false"
                            :columnDefs="optionColumnDefs"
                            :rowData="optionItems"
                            @updated="updateOption"
                            displayDensity="medium"
                        >
                            <template #extra-tools>
                                <fe-btn>Tes</fe-btn>
                            </template>
                        </FeGrid>

                        <v-layout row class="ml-3">
                            <v-text-field
                                v-model="addingOption.display_value"
                                flat solo desnse
                                :counter="25"
                                style="margin-top: 6px; margin-right: 18px;"
                                placeholder="Enter an option name"
                            />

                            <fe-btn
                                :disabled="!addingOption.display_value || addingOption.display_value.length > 25"
                                @click="saveOption"
                            >Add</fe-btn>
                        </v-layout>
                    </div>
                </template>

                <v-list v-else>
                    <v-list-item @click="showAliases(cell.data)">Aliases</v-list-item>
                    <v-list-item @click="deleteField(cell.data)">Delete</v-list-item>
                </v-list>
            </template>
        </FeGrid>

        <div class="mt-4">
            <fe-btn usage="tertiary" @click="addDialog.show = true">Create Field</fe-btn>
        </div>
    </v-layout>
</template>

<script>
    import LabelField from '@/components/common/LabelField'

    export default {
        name: 'IncidentTypeFields',
        components: {
            LabelField
        },
        props: {
            incidentBehaviorTypeId: {
                required: true
            }
        },
        computed: {
            gridItems() {
                this.items.forEach(row => {
                    let ft = this.fieldTypes.find((tp) => { return tp.id === row.incident_field_type_id })
                    row.field_type_name = ft.name
                })

                return this.items
            },
            previewFieldType() {
                let tp = null
                switch (this.addDialog.record.incident_field_type_id) {
                    case 6:
                        return 'v-text-field'
                        break
                    case 8:
                        return 'v-textarea'
                        break

                    default:
                        return 'v-select'
                        break
                }
            }
        },
        data() {
            let me = this
            return {
                cell: null,
                aliasDialog: {
                    show: false,
                    rec: {}
                },
                addDialog: {
                    show: false,
                    record: {
                        label: null,
                        incident_behavior_type_id: me.incidentBehaviorTypeId,
                        incident_field_type_id: null,
                        active: true,
                        required: false,
                        chart_flag: false,
                        export_flag: false,
                        multi_select: false,
                        rank: null
                    }
                },
                optionGridOptions: {
                    onCellValueChanged: function (event) {},
                },
                optionItems: [],
                addingOption: {
                    show: false,
                    display_value: ''
                },
                aliasItems: [],
                aliasColumns: [{
                   headerName: "Aliases",
                   field: "alias",
                   flex: 1
                }, me.$grid.iconColumn('', 'fas fa-trash', (v) => {
                    me.$confirmDelete([v.data], () => {
                        let id = v.data.incident_field_id
                        me.$refs.aliasCrud.destroy(v.data)
                            .then(() => {
                                me.$refs.aliasCrud.read({ incident_field_id: id })
                            })
                    })

                })],
                optionColumnDefs: [{
                    headerName: 'Order',
                    field: 'rank',
                    sortable: false,
                    editable: true,
                    width: 90,
                    onCellValueChanged: v => this.updateOption(v.data),
                    cellStyle() {
                        return {
                            'text-align': 'right'
                        }
                    }
                }, {
                    headerName: 'Option',
                    field: 'display_value',
                    sortable: false,
                    flex: 2,
                    editable: true,
                    onCellValueChanged: v => this.updateOption(v.data),
                    }, {
                    headerName: "Active",
                    field: "active",
                    sortable: false,
                    editable: false,
                    width: 100,
                    cellRendererFramework: "FeGridToggle",
                    cellRendererParams: {
                        "rowDataKey": "active"
                    },
                    "cellStyle": { "padding": 0 },
                    onCellValueChanged: v => this.updateOption(v.data)
                }, {
                    headerName: 'Delete',
                    field: 'delete_option',
                    flex: 1,
                    cellRenderer(v) {
                        return '<i class="fas fa-trash" style="margin-left: 15px; font-size: 12px"></i>'
                    },
                    cellStyle() {
                        return {
                            'cursor': 'pointer',
                            'text-align': 'left'
                        }
                    },
                    onCellClicked: function(v) {
                        me.deleteOption(v.data)
                        // me.cellClicked(v)
                    }
                }],
                columnDefs: [{
                    headerName: 'Field',
                    field: 'label',
                    minWidth: 150,
                    flex: 2,
                    editable: true,
                    onCellValueChanged: v => this.updateField(v.data)
                }, {
                    headerName: 'Field Type',
                    field: 'field_type',
                    flex: 1,
                    maxWidth: 150,
                    valueGetter: (params) => me.fieldTypes.find((tp) => { return tp.id === params.data.incident_field_type_id }).name
                }, {
                    headerName: 'Options',
                    field: 'counts',
                    maxWidth: 110,
                    flex: 1,
                    cellStyle(v) {
                        if (v.data.incident_field_type_id === 4) {
                            return {
                                cursor: 'pointer'
                            }
                        } else {
                            return {
                                display: 'none'
                            }
                        }

                    },
                    valueGetter: (params) => params.data.incident_field_options.length,
                    cellRendererFramework: "FeDialogColumn",
                    cellRendererParams: {
                        rowDataKey: "counts",
                        parent: me
                    },
                    onCellClicked: function(v) {
                        me.$refs.grid.setDialogCell(v)
                        me.addingOption.display_value = ''
                        me.addingOption.record = v.data
                        me.loadOptions(v.data)
                        // me.cellClicked(v)
                    }
                }, {
                    headerName: 'Order',
                    field: 'rank',
                    maxWidth: 80,
                    sortable: true,
                    editable: true,
                    cellEditorFramework: "FeGridTextField",
                    cellEditorParams: () => {
                        return {
                            type: 'number',
                            rules: this.$fieldValidators('number', 'Order', {
                                required: true,
                                min: 0
                            })
                        }
                    },
                    onCellValueChanged: v => {
                        me.updateField(v.data)
                    }
                }, this.$grid.toggleColumn('Chart Results', 'chart_flag', null, (v) => {
                    me.updateField(v.data)
                }), this.$grid.toggleColumn('Incl in Export', 'export_flag', null, (v) => {
                    me.updateField(v.data)
                }), this.$grid.toggleColumn('Active', 'active', null, (v) => {
                    me.updateField(v.data)
                }),this.$grid.toggleColumn('Required', 'required', null, (v) => {
                    me.updateField(v.data)
                }), {
                    colId: 'toolmenu',
                    maxWidth: 50,
                    cellRenderer: v => {
                        return '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>'
                    },
                    onCellClicked: this.showGridDialog,
                }],
                items: [],
                fieldTypes: []
            }
        },
        mounted() {
            this.loadData()

        },
        methods: {
            showAliases(v) {
                this.$refs.aliasCrud.read({ incident_field_id: v.id })
                this.aliasDialog.rec = {
                    incident_field_id: v.id,
                    alias: ''
                }
                this.aliasDialog.show=true
            },
            addAlias() {
                this.$refs.aliasCrud.create(this.aliasDialog.rec)
                    .then(() => {
                        this.$refs.aliasCrud.read({ incident_field_id: this.aliasDialog.rec.incident_field_id })
                    })
                    .finally(() => {
                        this.aliasDialog.rec.alias=''
                    })
            },
            showGridDialog(v) {
                let cfg = v.column.colId == 'toolmenu' ? 'menu' : null
                this.$refs.grid.setDialogCell(v,cfg)
            },
            updateOption(rec) {

                if (rec.display_value) {
                    this.$refs.optionCrud.update([rec])
                        .then(() => {
                            this.loadData()
                        })
                } else {
                    this.$refs.optionCrud.read({
                        incident_response_field_id: rec.incident_response_field_id
                    })
                }
            },
            saveOption() {
                let o = {
                    incident_field_id: this.addingOption.record.id,
                    display_value: this.addingOption.display_value,
                    active: true,
                    rank: (this.optionItems.length + 1)
                }

                this.$refs.optionCrud.create([o])
                    .then(response => {
                        this.loadOptions(this.addingOption.record)
                        this.addingOption.display_value = ''
                        this.loadData()
                    })
            },
            loadOptions(rec) {
                this.$refs.optionCrud.read({
                    incident_field_id: rec.id
                })
            },
            loadData() {
                this.$axios.get(this.$models.getUrl('incidentFieldType', 'read'))
                .then(response => {
                    this.$data.fieldTypes = response.data.incident_field_types

                    this.$refs.crud.read({ incident_behavior_type_id: this.$props.incidentBehaviorTypeId })
                        .then(fields => {
                        })
                })
            },
            deleteOption(rec) {
                this.$confirmDelete(rec, () => {
                    this.$refs.optionCrud.destroy([rec])
                        .then(response => {
                            this.loadData()
                        })
                })
            },
            deleteField(rec) {
                this.$confirmDelete(rec, () => {
                    this.$refs.crud.destroy([rec])
                        .then(response => {
                            this.loadData()
                        })
                        .catch(() => this.loadData())
                })
            },
            updateField(rec) {
                if (rec.label) {
                    this.$refs.crud.update([rec])
                        .then(() => {
                            this.loadData()
                        })
                        .catch(() => this.loadData())
                } else {
                    this.loadData()
                }
            },
            saveRecord() {
                let r = this.addDialog.record

                if (!r.label || !r.incident_field_type_id) return

                let rank = 0
                this.items.forEach(x => {
                    if (x.rank >= rank) {
                        rank = x.rank + 1
                    }
                })
                Object.assign(r, {
                    rank
                })

                this.$refs.crud.create([r])
                    .then(() => {
                        this.loadData()
                        this.addDialog.show = false
                        this.addDialog.record = {
                            label: null,
                            incident_behavior_type_id: this.incidentBehaviorTypeId,
                            incident_field_type_id: null,
                            active: true,
                            required: false,
                            chart_flag: false,
                            export_flag: false,
                            multi_select: false,
                            rank: null
                        }
                    })
                    .catch(() => this.loadData())
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
