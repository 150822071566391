<template>
    <v-layout column>
        <div class="section-title mb-4">Custom Fields</div>

        <fe-dialog
            width="600"
            title="Create Custom Field"
            v-if="addDialog.show"
            @close="addDialog.show=false"
            @accept="saveRecord"
            acceptButtonText="Save"
            :acceptButtonDisabled="!addDialog.record.name"
            persistent
            dismissButtonText="Cancel"
        >
            <v-layout row>
                <v-flex xs6>
                    <v-form>
                        <label-field labelText="Field Name">
                            <v-text-field v-model="addDialog.record.name" flat solo dense/>
                        </label-field>

                        <label-field labelText="Field Type">
                            <v-select
                                :items="fieldTypes"
                                v-model="addDialog.record.incident_field_type_id"
                                itemValue="id"
                                itemText="name"
                                required
                                flat solo dense
                            />
                        </label-field>
                    </v-form>
                </v-flex>

                <v-flex xs6 class="pl-2">
                    <div class="pa-4" style="height: 100%; background-color: #ECEDF1">
                        <label-field :labelText="addDialog.record.name">
                            <component disabled :is="previewFieldType" flat solo dense/>
                        </label-field>
                    </div>
                </v-flex>

            </v-layout>
        </fe-dialog>

        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.incidentResponseField"
            @read="v => items = v"
        />

        <fe-crud
            ref="optionCrud"
            :autoload="false"
            :config="$models.incidentResponseFieldOption"
            @read="v => optionItems = v"
        />

        <FeGrid
            ref="grid"
            v-show="gridItems.length > 0"
            :showToolbar="false"
            :columnDefs="columnDefs"
            :rowData="gridItems"
            domLayout="autoHeight"
        >
            <template v-slot:cellDialog="{cell}">
                <template v-if="cell.column.colId.indexOf('counts') != -1 && cell.data.incident_field_type_id === 4">

                    <div class="d-flex justify-space-between align-center pt-3 pr-2">
                        <v-flex grow class="grid-panel-title">List Options</v-flex>
                        <v-flex shrink text-xs-right>
                            <v-btn icon small usage="ghost" @click="$refs.grid.openMenu = false"><v-icon>close</v-icon></v-btn>
                        </v-flex>
                    </div>

                    <FeGrid
                        style="width: 600px; padding: 10px 20px 20px 20px;"
                        domLayout="autoHeight"
                        ref="optionGrid"
                        :showToolbar="false"
                        :columnDefs="optionColumnDefs"
                        :rowData="optionItems"
                        @updated="updateOption"
                    ></FeGrid>

                    <v-layout row class="ml-3">
                        <v-text-field
                            v-model="addingOption.text"
                            flat solo desnse
                            :counter="25"
                            style="margin-top: 6px; margin-right: 18px;"
                            placeholder="Enter an option name"
                        />
                        <fe-btn
                            class="ml-auto"
                            :disabled="!addingOption.text || addingOption.text.length > 25"
                            @click="saveOption"
                        >Add</fe-btn>
                    </v-layout>
                </template>
            </template>
        </FeGrid>

        <div class="mt-4">
            <fe-btn usage="tertiary" @click="showAdd">Create Field</fe-btn>
        </div>
    </v-layout>
</template>

<script>
    import LabelField from '@/components/common/LabelField'

    export default {
        name: 'IncidentResponseFields',
        components: {
            LabelField
        },
        props: {
            incidentResponseId: {
                required: true
            }
        },
        computed: {
            gridItems() {
                this.items.forEach(row => {
                    let ft = this.fieldTypes.find((tp) => { return tp.id === row.incident_field_type_id })
                    row.field_type_name = ft.name
                })

                return this.items
            },
            previewFieldType() {
                let tp = null
                switch (this.addDialog.record.incident_field_type_id) {
                    case 6:
                        return 'v-text-field'
                        break
                    case 8:
                        return 'v-textarea'
                        break

                    default:
                        return 'v-select'
                        break
                }
            },
            columnDefs() {
                let me = this
                return [{
                    headerName: 'Field',
                    field: 'name',
                    editable: true,
                    flex: 2,
                    onCellValueChanged: v => this.updateField(v)
                }, {
                    headerName: 'Field Type',
                    field: 'field_type',
                    flex: 1,
                    valueGetter: (params) => me.fieldTypes.find((tp) => { return tp.id === params.data.incident_field_type_id }).name
                }, {
                    headerName: 'Options',
                    field: 'counts',
                    flex: 1,
                    cellStyle(v) {
                        if (v.data.incident_field_type_id === 4) {
                            return {
                                cursor: 'pointer'
                            }
                        } else {
                            return {
                                display: 'none'
                            }
                        }

                    },
                    valueGetter: (params) => params.data.incident_response_field_options.length,
                    cellRendererFramework: "FeDialogColumn",
                    cellRendererParams: {
                        rowDataKey: "counts",
                        parent: me
                    },
                    onCellClicked: function(v) {
                        me.$refs.grid.setDialogCell(v)
                        me.addingOption.text = ''
                        me.addingOption.record = v.data
                        me.loadOptions(v.data)
                        // me.cellClicked(v)
                    }
                }, {
                    headerName: "Field Definition",
                    field: "incident_field_definition_id",
                    editable: true,
                    flex: 1,
                    valueGetter(v) {
                        let id = v.data.incident_field_definition_id

                        let f = me.fieldDefinitions.find(d => d.id === id)

                        return f ? f.name : ''
                    },
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: {
                        rowDataKey: 'incident_field_definition_id',
                        mode: 'edit',
                        multiple: false,
                        items: me.fieldDefinitions,
                        keyProp: 'id',
                        labelProp: 'name'
                    },
                    onCellValueChanged: v => this.updateField(v)
                }, , this.$grid.toggleColumn('Active', 'active', null, (v) => {
                    me.updateField(v)
                }), this.$grid.toggleColumn('Required', 'required', null, (v) => {
                    me.updateField(v)
                }, { minWidth: 110, maxWidth: 110 }), me.$grid.iconColumn('Delete', 'fas fa-trash', (v) => {
                    me.deleteField(v.data)
                })]
            }
        },
        data() {
            let me = this

            return {
                fieldDefinitions: [],
                cell: null,
                addDialog: {
                    show: false,
                    record: {
                        name: null,
                        incident_response_id: me.incidentResponseId,
                        incident_field_type_id: null,
                        active: true,
                        required: true
                    }
                },
                optionGridOptions: {
                    onCellValueChanged: function (event) {},
                },
                optionItems: [],
                addingOption: {
                    show: false,
                    text: ''
                },
                optionColumnDefs: [{
                    headerName: 'Order',
                    field: 'rank',
                    sortable: false,
                    editable: true,
                    width: 90,
                    onCellValueChanged: v => this.updateOption(v.data),
                    cellStyle() {
                        return {
                            'text-align': 'right'
                        }
                    }
                }, {
                    headerName: 'Option',
                    field: 'display_value',
                    sortable: false,
                    flex: 1,
                    editable: true,
                    onCellValueChanged: v => this.updateOption(v.data),
                }, this.$grid.toggleColumn('Active', 'active', null, (v) => {
                    me.updateOption(v.data)
                }), me.$grid.iconColumn('Delete', 'fas fa-trash', (v) => {
                    me.deleteOption(v.data)
                })],
                items: [],
                fieldTypes: []
            }
        },
        mounted() {
            this.$modelGet('incidentFieldDefinition').then(def => {
                this.fieldDefinitions = def
            })
            .finally(() => {
                this.loadData()
            })


        },
        methods: {
            showAdd() {
                let me = this
                this.addDialog = {
                    show: true,
                    record: {
                        name: null,
                        incident_response_id: me.incidentResponseId,
                        incident_field_type_id: null,
                        active: true,
                        required: true
                    }
                }
            },
            updateOption(rec) {
                if (rec.display_value) {
                    this.$refs.optionCrud.update([rec])
                        .then(() => {
                            this.loadData()
                        })
                } else {
                    this.$refs.optionCrud.read({
                        incident_response_field_id: rec.incident_response_field_id
                    })
                }
            },
            saveOption() {
                let o = {
                    incident_response_field_id: this.addingOption.record.id,
                    display_value: this.addingOption.text,
                    active: true,
                    rank: (this.optionItems.length + 1)
                }

                this.$refs.optionCrud.create([o])
                    .then(response => {
                        this.loadOptions(this.addingOption.record)
                        this.addingOption.text = ''
                        this.loadData()
                    })
            },
            loadOptions(rec) {
                this.$refs.optionCrud.read({
                    incident_response_field_id: rec.id
                })
            },
            loadData() {
                this.$axios.get(this.$models.getUrl('incidentFieldType', 'read'))
                .then(response => {
                    this.$data.fieldTypes = response.data.incident_field_types

                    this.$refs.crud.read({
                        incident_response_id: this.incidentResponseId
                    })
                })
            },
            deleteOption(rec) {
                this.$confirmDelete(rec, () => {
                    this.$refs.optionCrud.destroy([rec])
                        .then(response => {
                            this.loadData()
                        })
                })
            },
            deleteField(rec) {
                this.$confirmDelete(rec, () => {
                    this.$refs.crud.destroy([rec])
                        .then(response => {
                            this.loadData()
                        })
                        .catch(() => this.loadData())
                })
            },
            updateField(rec) {
                if (rec.data.name && rec.newValue != null && rec.oldValue != rec.newValue) {
                    this.$refs.crud.update([rec.data])
                        .then(() => {
                            this.loadData()
                        })
                        .catch(() => this.loadData())
                } else {
                    this.loadData()
                }
            },
            saveRecord() {
                let r = this.addDialog.record

                if (!r.name || !r.incident_field_type_id) return

                this.$refs.crud.create([r])
                    .then(() => {
                        this.loadData()
                    })
                    .catch(() => this.loadData())
                    .finally(() => { this.addDialog.show = false })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
