export default {
    data() {
        return {
            mealStatus: [],
            elpLevel: [],
            ethnicity: [],
            gender: [],
            disability: [],
        }
    },
    methods: {
        loadEll() {
            this.$axios.get(this.$models.getUrl('ellLevel', 'read'))
                .then(r => { this.elpLevel = r.data.ell_level })
        },

        loadEthnicities() {
            this.$axios.get(this.$models.getUrl('ethnicity', 'read'))
                .then(r => {
                    this.ethnicity = r.data.ethnicity
                })
        },

        loadGenders() {
            this.$axios.get(this.$models.getUrl('gender', 'read'))
                .then(r => { this.gender = r.data.gender })
        },

        loadDisabilities() {
            this.$axios.get(this.$models.getUrl('studentDisabilityType', 'read'))
                .then(r => { this.disability = r.data.student_disability_type })
        },

        loadMealStatus() {
            this.$axios.get(this.$models.getUrl('mealStatusType', 'read'))
                .then(r => { this.mealStatus = r.data.meal_status })
        },
    }
}
