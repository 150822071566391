<template>
    <div ref="setupContainer">
        <div class="pa-4 fill-height d-flex flex-column" style="height: 100%">
            <div class="flex-grow-1" style="overflow-y: auto; overflow-x: hidden;">
                <fe-text-field
                    placeholder="Filter"
                    prependIcon="fa-search"
                    width="100%"
                    v-model="filterText"
                    class="mb-4"
                />

                <fe-crud ref="crudBehaviorTypes" :config="$models.observationBehaviorTypes" autoload @read="observationBehaviorTypes = $event"/>
                <fe-crud ref="crudBehaviors" :config="$models.observationBehaviors" autoload @read="observationBehaviors = $event"/>
                <fe-crud ref="crudStudentBehaviors" :config="$models.studentObservations" autoload @read="studentObservations = $event"/>

                <div
                    v-for="t in observationBehaviorTypes"
                    :key="t.id"
                    class="mb-4"
                >
                    <div class="title-text">{{ t.name.toLowerCase() == "problematic" ? "Maladaptive" : t.name }}</div>

                    <div class="d-flex flex-wrap">
                        <state-button
                            v-for="b in filteredObservationBehaviors.filter(itm => itm.observation_behavior_type_id === t.id && itm.inactive_flag != 1)"
                            :key="b.id"
                            @input="onToggleBehavior(b, $event)"
                            :menu="menu"
                            :item="b"
                        >
                            {{ b.name }}
                        </state-button>

                        <fe-btn class="plus-button" usage="secondary" @click="onClickNewBehavior(t)">
                            <v-icon>far fa-plus</v-icon>
                        </fe-btn>
                    </div>
                </div>
            </div>

            <div>
                <div class="d-flex footerBtns">
                    <v-spacer/>
                    <fe-btn usage="ghost" @click="$emit('close')">Done</fe-btn>
                    <fe-btn usage="secondary" @click="$emit('previous')">Previous</fe-btn>
                    <fe-btn @click="$emit('next')">Next</fe-btn>
                </div>
            </div>
        </div>

        <fe-dialog
            v-if="createDialog.show"
            title="New Behavior"
            :acceptButtonDisabled="!isNewBehaviorValid"
            @dismiss="createDialog.show = false"
            @close="createDialog.show = false"
            @accept="onCreateBehavior"
            dismissButtonText="Cancel"
            acceptButtonText="Save"
            persistent
        >
            <v-form v-model="isNewBehaviorValid">
                <fe-label>Name</fe-label>
                <v-text-field
                    :rules="$fieldValidators('text', null, { required: true, limit: 100})"
                    counter=100
                    flat solo dense
                    v-model="createDialog.record.name"
                />

                <fe-label>Type</fe-label>
                <fe-remote-combo
                    v-model="createDialog.record.observation_behavior_type_id"
                    :items="observationBehaviorTypes"
                    byId
                />
            </v-form>
        </fe-dialog>

        <fe-dialog
            v-if="editDialog.show"
            title="Edit Behavior"
            :acceptButtonDisabled="!isEditBehaviorValid"
            @dismiss="editDialog.show = false"
            @close="editDialog.show = false"
            @accept="onEditBehavior"
            dismissButtonText="Cancel"
            acceptButtonText="Save"
            persistent
        >
            <v-form v-model="isEditBehaviorValid">
                <fe-label>Name</fe-label>
                <v-text-field
                    :rules="$fieldValidators('text', null, { required: true, limit: 100})"
                    counter=100
                    flat solo dense
                    v-model="editDialog.record.behaviorName"
                />

                <fe-label>Type</fe-label>
                <fe-remote-combo
                    v-model="editDialog.record.behaviorObvId"
                    :items="observationBehaviorTypes"
                    byId
                />
            </v-form>
        </fe-dialog>
    </div>
</template>

<script>
    import {mapState} from "vuex"
    import StateButton from '@/components/common/button/StateButton'

    export default {
        name: 'ObservationSetup',
        components: {
            StateButton,
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        watch: {
            localValue(v) {
                this.$emit('input', v)
            },
        },
        data() {
            let me = this

            return {
                localValue: {},
                observationBehaviorTypes: [],
                observationBehaviors: [],
                studentObservations: [],
                filterText: '',
                createDialog: {
                    show: false,
                    record: {
                        name: '',
                        observation_behavior_type_id: null,
                    },
                },
                editDialog: {
                    show: false,
                    record: {
                        behaviorId: null,
                        behaviorName: '',
                        behaviorObvId: null,
                    },
                },
                isNewBehaviorValid: false,
                isEditBehaviorValid: false,
                menu: {
                    items: [
                        {
                            text: 'Edit',
                            handler(item) {
                                me.editDialog.record.behaviorId = item.id
                                me.editDialog.record.behaviorName = item.name
                                me.editDialog.record.behaviorObvId = item.observation_behavior_type_id
                                me.editDialog.show = true
                            },
                        },
                        {
                            text: 'Delete',
                            handler(item) {
                                me.onDeleteBehavior(item)
                            },
                        },
                    ]
                },
            }
        },
        computed: {
            ...mapState('global', ['currentYear', 'sessionUser']),
            filteredObservationBehaviors() {
                if (!this.filterText) {
                    return this.observationBehaviors
                } else {
                    return this.observationBehaviors.filter(b => b.name.toLowerCase().includes(this.filterText.toLowerCase()))
                }
            },
        },
        mounted() {
            this.localValue = this.$_.cloneDeep(this.value)
            this.$emit('refresh')
        },
        methods: {
            checkIsSelected(behavior) {
                return this.localValue.observation_behaviors.includes(behavior.id)
            },
            onToggleBehavior(behavior, isSelected) {
                if (!isSelected) {
                    this.localValue.observation_behaviors = this.localValue.observation_behaviors.filter(id => id !== behavior.id)
                } else {
                    this.localValue.observation_behaviors.push(behavior.id)
                }
            },
            onClickNewBehavior(behaviorType) {
                this.createDialog.show = true
                this.createDialog.record = {
                    name: '',
                    observation_behavior_type_id: behaviorType.id,
                }
            },
            onCreateBehavior() {
                this.$refs.crudBehaviors.create(this.createDialog.record).then(() => {
                    this.createDialog.show = false
                    this.$refs.crudBehaviors.read()
                    this.$emit('refreshObservation')
                })
            },
            onEditBehavior() {
                let b = {
                    id: this.editDialog.record.behaviorId,
                    name: this.editDialog.record.behaviorName,
                    observation_behavior_type_id: this.editDialog.record.behaviorObvId
                }
                this.$refs.crudBehaviors.update(b).then(() => {
                    this.createDialog.show = false
                    this.$refs.crudBehaviors.read()
                    this.$emit('refreshObservation')
                }).then(() => {
                    this.editDialog.show = false
                })
            },
            onDeleteBehavior(item) {
                this.$confirmDelete(item, () => {
                    this.$refs.crudBehaviors.destroy(item).then(() => {
                        this.$refs.crudBehaviors.read()
                        this.$emit('refreshObservation')
                    })
                })
            },
        },
    }
</script>

<style lang="scss" scoped>

    .plus-button {
        ::v-deep button.fe-button {
            /* This prevents larger min-widths that undesirably result in larger button with un-centered plus icon */
            min-width: 1px !important;
        }

        ::v-deep .v-btn__content {
            color: #050F2D;
        }
        ::v-deep .v-btn {
            background-color: #ECEDF1 !important;
            --primary-color: #ECEDF1 !important;
            /* Default fe-btn/v-btn has some margin which undesirably causes other buttons in row/wrap to expand vertically too */
            margin: 0 !important;
            transform: translate(0, 2px);
        }
    }

    .grey-button {
        ::v-deep .v-btn__content {
            color: #050F2D;
        }
        ::v-deep .v-btn {
            --primary-color: #CED0D9 !important;
        }
    }

    .compose ::v-deep .ck-editor__editable_inline {
        // border: none;
        min-height: 240px;
        max-height: calc(100vh - 460px);
    }

    .footerBtns {
        position: absolute;
        bottom: 0px;
        right: 0px;
        background-color: #FFFFFF;
        width: 100%;
        height: 80px;
        padding: 20px;
    }

</style>
