<template>
    <div class="flex-fill flex-column no-scroll" :style="{ width: '750px', height: '400px', padding: '16px' }">
        <div class="d-flex justify-space-between align-center">
            <div class="flex-grow-1" style="font-size: 20px;">Create an Open Window</div>
            <div class="flex-shrink-1 text-xs-right">
                <v-btn icon small usage="ghost" @click="$emit('close')"><v-icon>close</v-icon></v-btn>
            </div>
        </div>
        <div class="mb-2">for {{ group.name }} in {{ windowName }}</div>
        <div :style="{ height: '280px', overflow: 'scroll' }">
            <v-form ref="form" @submit.prevent v-model="valid">
                <fe-label>Window Display Name</fe-label>
                <v-text-field
                    v-model="obj.name"
                    flat solo dense validateOnBlur
                    :style="{ width: '100%' }"
                    :rules="rules.name"
                />

                <fe-label>School Year</fe-label>
                <v-autocomplete
                    v-model="obj.school_year_id"
                    flat solo dense validateOnBlur clearable
                    :items="schoolYears"
                    itemText="name"
                    itemValue="id"
                />

                <fe-label>School</fe-label>
                <v-autocomplete
                    v-model="obj.school_id"
                    flat solo dense validateOnBlur clearable
                    :items="schools"
                    itemText="name"
                    itemValue="id"
                    multiple
                />

                <fe-label>Grade</fe-label>
                <v-autocomplete
                    v-model="obj.grade_id"
                    flat solo dense validateOnBlur clearable
                    :items="grades"
                    itemText="name"
                    itemValue="id"
                    multiple
                    class="mb-1"
                />

                <fe-label>Assessments</fe-label>
                <v-autocomplete
                    v-model="obj.sub_category_id"
                    flat solo dense validateOnBlur clearable
                    :items="assessments"
                    itemText="name"
                    itemValue="id"
                    multiple
                    class="mb-1"
                />

                <div class="d-flex">
                    <div class="pr-2">
                        <fe-label>Start Date</fe-label>
                        <fe-date-picker
                            v-model="obj.start_date"
                            :rules="$fieldValidators('text', 'Start date', { required: true })"
                            prependIcon="fa-calendar-alt"
                            dateFormat="MM/DD/YYYY"
                        />
                    </div>

                    <div>
                        <fe-label>End Date</fe-label>
                        <fe-date-picker
                            v-model="obj.end_date"
                            :rules="rules.endDate"
                            prependIcon="fa-calendar-alt"
                            dateFormat="MM/DD/YYYY"
                        />
                    </div>
                </div>
            </v-form>
        </div>
        <div class="flex-shrink-1 text-xs-right">
            <fe-btn
                usage="tertiary"
                style="margin-top: 10px;"
                @click="cancel"
            >Cancel</fe-btn>
            <fe-btn
                usage="primary"
                style="margin-top: 10px;"
                @click="create"
            >Create</fe-btn>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'OpenWindowCreator',
        props: {
            group: { type: Object, default: () => {} },
            window: { type: Object, default: null },
            selectedSchoolYearId: { type: Number, default: null },
            schoolYears: { type: Array, default: [] },
            schools: { type: Array, default: [] },
            grades: { type: Array, default: [] },
            assessments: { type: Array, default: [] }
        },
        data () {
            return {
                valid: null,
                obj: {
                    name: null,
                    school_year_id: null,
                    school_id: [],
                    grade_id: [],
                    sub_category_id: null,
                    start_date: null,
                    end_date: null
                },
                rules: {
                    name: [
                        v => (!!v && v.trim().length > 0) || 'Please enter a name'
                    ],
                    endDate: [
                        v => !this.$dayjs(v).isBefore(this.obj.start_date) || 'Not a valid End Date'
                    ].concat(this.$fieldValidators('text', 'End date', { required: true }))
                },
                errors: []
            }
        },
        computed: {
            windowName () {
                return (this.window.alias && this.window.alias.trim().length > 0)
                    ? this.window.alias
                    : this.window.name
            }
        },
        methods: {
            formatDate (str) {
                let p = str.split('/')
                return [p[2],p[0],p[1]].join('-')
            },
            reset () {
                this.obj.name = null
            },
            close () {
                this.$emit('close')
            },
            cancel () {
                this.$emit('cancel')
            },
            create () {
                if (this.$refs.form.validate()) {
                    // let formattedDates = {
                    //     start_date: this.formatDate(this.obj.start_date),
                    //     end_date: this.formatDate(this.obj.end_date)
                    // }
                    this.$emit('create', Object.assign(this.obj, {
                        data_point_type_id: this.group.id,
                        data_point_name_id: this.window.id
                    }))
                }
            }
        },
        mounted () {
            this.obj.school_year_id = this.selectedSchoolYearId
        }
    }
</script>
