import { render, staticRenderFns } from "./RiskRatio.vue?vue&type=template&id=aab7c03c&scoped=true"
import script from "./RiskRatio.vue?vue&type=script&lang=js"
export * from "./RiskRatio.vue?vue&type=script&lang=js"
import style0 from "./RiskRatio.vue?vue&type=style&index=0&id=aab7c03c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aab7c03c",
  null
  
)

export default component.exports