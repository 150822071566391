import BaseService from '@/components/modules/thresholds/services/BaseService'
import BaseStore from '../Base'

export default Object.assign({}, BaseStore.default(), {
    namespaced: true,

    getters: Object.assign({}, BaseStore.default().getters, {
        __config(state, getters, rootState, rootGetters) {
            return {
                service: BaseService,
                opts: {type: 'thresholdGroupProps'},
                params: {property: 'user', threshold_group_id: rootGetters['thresholds/group/selectedId']},
                prop: 'user'
            }
        },

        __postData: (state, getters) => {
            return {
                dirtied: getters.dirtied.map(obj => {
                    return Object.assign({}, obj, {email_flag: (obj.email_flag) ? 1 : 0})
                }),
                removed: getters.removed,
                added: getters.added
            }
        }
    })
})
