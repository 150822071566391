<template>
    <div class="d-flex fill-height">
        <template v-if="isMounted">
            <div :class="`${hasCategory && hasDataPointType ? 'col-2' : 'col-4'} pa-0 fill-height`">
                <fe-stepper-checkbox-panel
                    width="100%"
                    style="height: 100%"
                    :items="categories"
                    :surfaceFirstSelections="surfaceFirstSelections"
                    byId
                    v-model="localValue.category_id"
                    allOption allOptionText="All Categories"
                    search
                    searchPlaceholder="Categories"
                />
            </div>

            <v-divider vertical style="background-color:#E0E1E6"/>
        </template>

        <template v-if="isMounted && localValue && localValue.category_id.length">
            <div :class="`${hasCategory && hasDataPointType && hasSubCategory ? 'col-2' : 'col-4'} pa-0`">
                <fe-stepper-checkbox-panel
                    width="100%"
                    style="height: 100%"
                    :items="dataPointTypes"
                    :surfaceFirstSelections="surfaceFirstSelections"
                    byId
                    v-model="localValue.data_point_type_id"
                    allOption allOptionText="All Assessment Groups"
                    search
                    searchPlaceholder="Assessment Groups"
                />
            </div>

            <v-divider vertical style="background-color:#E0E1E6"/>
        </template>

        <template v-if="isMounted && localValue && localValue.category_id.length && localValue.data_point_type_id.length">
            <div class="col-5 pa-0 fill-height">
                <div class="d-flex flex-column fill-height">
                    <fe-search
                        width="100%"
                        v-model="assessmentsSearchText"
                        placeholder="Assessments"
                    />

                    <div class="flex-grow-1" style="overflow: auto;">
                        <hierarchical-checkbox-panel
                            v-for="grp in selectedAssessmentGroups"
                            :key="grp.data_point_type_id"
                            isRoot
                            :items="grp.subCategories"
                            :surfaceFirstSelections="surfaceFirstSelections"
                            :searchText="assessmentsSearchText"
                            :itemFilterFunction="filterAssessmentAgainstCategories"
                            v-model="localValue.sub_category_id"
                        />
                    </div>
                </div>
            </div>
            <v-divider vertical style="background-color:#E0E1E6"/>
        </template>

        <template v-if="localValue && localValue.category_id.length && localValue.data_point_type_id.length && localValue.sub_category_id.length">
            <div class="col-3 pa-0 fill-height">
                <div class="d-flex flex-column fill-height">
                    <fe-search
                        width="100%"
                        v-model="windowsSearchText"
                        placeholder="Windows"
                    />

                    <div class="flex-grow-1" style="overflow: auto;">
                        <hierarchical-checkbox-panel
                            v-for="grp in selectedAssessmentGroupWindows"
                            :key="grp.data_point_name_id"
                            isRoot
                            :parentIsSelectable="false"
                            :items="grp.windows"
                            :searchText="windowsSearchText"
                            v-model="localValue.data_point_name_id"
                            :surfaceFirstSelections="surfaceFirstSelections"
                            :showIcon="false"
                        />
                    </div>
                </div>
            </div>
            <!-- No divider on this one - we let the parent component close it off as needed -->
        </template>
    </div>
</template>

<script>
import HierarchicalCheckboxPanel from '@/components/common/stepper/HierarchicalCheckboxPanel'

const walkHierarchicalModel = (arr) => {
    let ids = []
    for (let itm of arr) {
        ids.push(itm.id)
        if (itm.children) {
            ids = [...ids, ...walkHierarchicalModel(itm.children)]
        }
    }

    return ids
}

export default {
    name: 'AssessmentGroupCheckboxPanel',

    components: {
        HierarchicalCheckboxPanel,
    },

    props: {
        value: {},
        surfaceFirstSelections: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            dataPointTypes: [],
            includedAll: false,
            subCategories: [],
            subCategoriesByDataPointType: [],
            windowsByDataPointType: [],
            dataPointNames: [],
            categories: [],
            schoolYears: [],
            sub_category_id: [],
            assessmentsSearchText: '',
            windowsSearchText: '',
            localValue: {
                data_point_type_id: [],
                sub_category_id: [],
                data_point_name_id: [],
                category_id: []
            },
            isMounted: false,
        }
    },

    computed: {
        hasCategory() {
            return !!this.localValue?.category_id?.length
        },
        hasDataPointType() {
            return !!this.localValue?.data_point_type_id?.length
        },
        hasSubCategory() {
            return !!this.localValue?.sub_category_id?.length
        },
        hasWindow() {
            return !!this.localValue?.data_point_name_id?.length
        },
        assessmentGroups() {
            let items = this.dataPointTypes.map(itm => ({
                data_point_type_id: itm.id,
                subCategories: this.subCategoriesByDataPointType.find(sc => sc.data_point_type_id == itm.id)?.subCategories || [],
            }))

            return items
        },
        selectedAssessmentGroups() {
            // data_point_type_id can come in as a string...convert to int before processing
            let ids = Object.values(this.localValue.data_point_type_id)
            ids.forEach((data, i) => {
                this.localValue.data_point_type_id[i] = parseInt(data)
            })

            return [
                {data_point_type_id: -1, subCategories: [{id: -1, name: 'All Assessments'}]},
                ...this.assessmentGroups.filter(ag => this.localValue?.data_point_type_id?.includes(ag.data_point_type_id))
            ]
        },
        assessmentGroupWindows() {
            let items = this.dataPointTypes.map(itm => ({
                data_point_type_id: itm.id,
                windows: this.windowsByDataPointType.find(sc => sc.data_point_type_id === itm.id)?.windows || [],
            }))

            return items
        },
        selectedAssessmentGroupWindows() {
            let ids = Object.values(this.localValue.data_point_type_id)
            ids.forEach((data, i) => {
                this.localValue.data_point_type_id[i] = parseInt(data)
            })

            return [
                {data_point_type_id: -1, windows: [{id: -1, name: 'All Windows'}]},
                ...this.assessmentGroupWindows.filter(agw => this.localValue?.data_point_type_id?.includes(agw.data_point_type_id))
            ]
        },
        searchDisplay() {
            let pieces = []
            let suffix

            if (!this.localValue.category_id.length) {
                pieces.push('<b>Select Categories</b>')
                return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
            } else {
                if (this.localValue.category_id.includes(-1) || this.localValue.category_id.includes('-1')) {
                    pieces.push('All Categories')
                } else {
                    suffix = this.localValue.category_id.length > 1 ? `...+${this.localValue.category_id.length - 1}` : ''
                    pieces.push(this.categories.find(itm => itm.id === this.localValue.category_id[0])?.name + suffix)
                }
            }

            if (!this.localValue.data_point_type_id.length) {
                pieces.push('<b>Select Assessment Groups</b>')
                return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
            } else {
                if (this.localValue.data_point_type_id.includes(-1) || this.localValue.data_point_type_id.includes('-1')) {
                    pieces.push('All Assessment Groups')
                } else {
                    suffix = this.localValue.data_point_type_id.length > 1 ? `...+${this.localValue.data_point_type_id.length - 1}` : ''
                    pieces.push(this.dataPointTypes.find(itm => itm.id === this.localValue.data_point_type_id[0])?.name + suffix)
                }
            }

            if (!this.localValue.sub_category_id.length) {
                pieces.push('<b>Select Assessments</b>')
                return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
            } else {
                if (this.localValue.sub_category_id.includes(-1)) {
                    pieces.push('All Assessments')
                } else {
                    suffix = this.localValue.sub_category_id.length > 1 ? `...+${this.localValue.sub_category_id.length - 1}` : ''
                    pieces.push(this.subCategories.find(itm => itm.id === this.localValue.sub_category_id[0])?.name + suffix)
                }
            }

            if (!this.localValue.data_point_name_id.length) {
                pieces.push('<b>Select Windows</b>')
                return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
            }

            // All options are selected; add windows and return all
            if (this.localValue.data_point_name_id.includes(-1)) {
                pieces.push('All Windows')
            } else {
                suffix = this.localValue.data_point_name_id.length > 1 ? `...+${this.localValue.data_point_name_id.length - 1}` : ''
                pieces.push(this.dataPointNames.find(itm => itm.id === this.localValue.data_point_name_id[0])?.name + suffix)
            }

            return pieces.join('&nbsp;&nbsp;<i class="fas fa-chevron-right"></i>&nbsp;&nbsp;')
        },
    },

    watch: {
        localValue(v) {
            this.$emit('input', v)
        },
        'localValue.category_id'(v, prevVal) {
            if (v.includes(-1)) {
                // Don't deselect anything, cause user is asking for "All Categories"
            } else if (v.length === 0) {
                // Don't deselect anything yet, cause user probably doesn't know
                // what they're doing and maybe just accidentally unclicked "All Categories"
                // after picking some assessments
            } else if (prevVal?.length > v?.length && this.localValue.sub_category_id?.length) {
                // If user de-selects a specific category and is not switching to
                // "All Categories," then any assessment that was previously selected
                // that belongs to that category must be deselected
                this.localValue.sub_category_id = this.localValue.sub_category_id.filter(itm => {
                    let sc = this.subCategories.find(sc => sc.id === itm)
                    return v.includes(sc?.category_id)
                })
            }

            this.$emit('selectCategories', this.categories.filter(itm => v.includes(itm.id)))
        },
        'localValue.data_point_type_id'(v, prevVal) {
            for (let dataPointTypeId of v) {
                // If already been loaded, nothing happens - keeps previous cache-y data
                this.loadSubCategories(dataPointTypeId)
                this.loadWindows(dataPointTypeId)
            }

            // If user de-selects an assessment group, any previously selected
            // assessments/windows within it should be removed
            if (v.includes(-1) && prevVal?.length) {
                // Don't uncheck if the next option was "All Assessments" as this is universally valid
                if (!this.localValue.sub_category_id?.includes(-1)) {
                    this.localValue.sub_category_id = []
                }

                // Same - don't uncheck "All Windows" as it's also universally valid
                if (!this.localValue.data_point_name_id?.includes(-1)) {
                    this.localValue.data_point_name_id = []
                }
            } else if (v.length < prevVal.length) {
                for (let dataPointTypeId of prevVal.filter(itm => !v.includes(itm))) {
                    // Get assessment ids for this data point type and remove them because it's been deselected
                    let ids = this.getSubCategoryIdsByDataPointType(dataPointTypeId)
                    this.localValue.sub_category_id = this.localValue.sub_category_id.filter(id => !ids.includes(id))

                    ids = this.getWindowIdsByDataPointType(dataPointTypeId)
                    this.localValue.data_point_name_id = this.localValue.data_point_name_id.filter(id => !ids.includes(id))
                }
            }

            // When user makes initial selection of assessment group,
            // pre-check the "All Assessments" and "All Windows" options
            if (!this.localValue.sub_category_id?.length) {
                this.localValue.sub_category_id = [-1]
            }
            if (!this.localValue.data_point_name_id?.length) {
                this.localValue.data_point_name_id = [-1]
            }

            this.$emit('selectAssessmentGroups', this.dataPointTypes.filter(itm => v.includes(itm.id)))
        },
        'localValue.sub_category_id'(v) {
            this.$emit('selectAssessments', this.subCategories.filter(itm => v.includes(itm.id)))
        },
        'localValue.data_point_name_id'() {
            this.$emit('selectWindows')
        },
        searchDisplay(v) {
            this.$emit('searchDisplayChange', v)
        },
    },

    async mounted() {
        await this.loadBase()
        this.localValue = this.$_.cloneDeep(this.value)

        // Pre-select "All Categories" at arrival
        if (!this.localValue?.category_id?.length) {
            this.localValue.category_id = [-1]
        }

        this.isMounted = true
    },

    methods: {
        async loadBase() {
            let promises = [
                this.$modelGet('schoolYear').then(r => {
                    this.schoolYears = r
                }),
                this.$modelGet('category').then(r => this.categories = r),
                this.$modelGet('assessment', {hidden: 0, school_year_id: this.schoolYearId}).then(r => {
                    this.dataPointTypes = r //[{id: -1, name: 'All Assessment Groups' }].concat(r)
                }),
                this.$modelGet('dataPointName').then(r => {
                    this.dataPointNames = r
                }),
            ]

            await Promise.all(promises)
        },

        setSelected(v, item, items) {
            this.$emit('subCategorySelected', item, items)
            this.sub_category_id = [...v]
        },

        emitItem(key, v, item, items) {
            this.$emit(key + '-selected', item, items)
        },

        removeSC(item, index) {
            this.params.sub_category_id.splice(index, 1)
            this.sub_category_id = [...this.params.sub_category_id]
        },

        loadSubCategories(dataPointTypeId) {
            if (this.subCategoriesByDataPointType.find(itm => itm.data_point_type_id === dataPointTypeId)) {
                return
            }

            let me = this
            me.$modelGet('subcategory', {data_point_type_id: dataPointTypeId, active: 1})
                .then(r => {
                    me.subCategories = [...me.subCategories, ...r]

                    me.subCategoriesByDataPointType.push({
                        data_point_type_id: dataPointTypeId,
                        subCategories: r
                            .filter(itm => !itm.parent_id)
                            .map((itm, i) => ({
                                ...itm,
                                children: itm.child_cnt > 0 ? me.getChildrenForSubCategory(itm.id, r) : null,
                                // Don't assume first autoexpands when editing existing; it's not needed,
                                // because whichever assessments/sub-assessments are selected will auto-trigger
                                // their own expansion; this is just for "brand new" analytic searches
                                _isExpanded: i === 0 && !this.surfaceFirstSelections,
                            }))
                    })
                })
        },

        loadWindows(dataPointTypeId) {
            if (this.windowsByDataPointType[dataPointTypeId]) {
                return
            }

            let me = this
            me.$modelGet('dataPointName', {data_point_type_id: dataPointTypeId, active: 1})
                .then(r => {
                    let windows = r.map(w => {
                        if (w.alias) {
                            w.name = w.alias
                        }
                        return w
                    })
                    me.dataPointNames = [...me.dataPointNames, ...windows]

                    me.windowsByDataPointType.push({
                        data_point_type_id: dataPointTypeId,
                        windows: [{
                            name: this.dataPointTypes.find(itm => itm.id === dataPointTypeId)?.name,
                            children: windows,
                            _isExpanded: true,
                        }],
                    })
                })
        },

        getSubCategoryIdsByDataPointType(dataPointTypeId) {
            let arr = this.subCategoriesByDataPointType.find(itm => itm.data_point_type_id === dataPointTypeId)?.subCategories || []
            return walkHierarchicalModel(arr)
        },

        getWindowIdsByDataPointType(dataPointTypeId) {
            let arr = this.windowsByDataPointType.find(itm => itm.data_point_type_id === dataPointTypeId)?.windows || []
            return walkHierarchicalModel(arr)
        },

        getChildrenForSubCategory(subCategoryId, subCategories) {
            let me = this
            return subCategories
                .filter(itm => itm.parent_id === subCategoryId)
                .map((itm, i) => ({
                    ...itm,
                    children: itm.child_cnt > 0 ? me.getChildrenForSubCategory(itm.id, subCategories) : null,
                    _isExpanded: false,
                }))
        },

        filterAssessmentAgainstCategories(subCategory) {
            if (this.localValue?.category_id?.includes(-1) || this.localValue?.category_id?.includes('-1') || subCategory.id === -1) {
                return true
            } else {
                return !!this.localValue?.category_id?.includes(subCategory.category_id)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.selected-item {
    background: #f5f6f8;
    padding: 4px 8px 4px 8px;
    margin: 4px 0px 4px 0px;

    &-name {
        overflow: hidden;
        white-space: nowrap;
        margin-right: 8px;
    }
}

.v-list-item__title,
::v-deep .v-label {
    font-size: 14px !important;
}

::v-deep .v-list-item__content * {
    overflow: hidden;
    text-overflow: ellipsis;
}

::v-deep .v-label {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block !important; /* necessary to allow text-overflow to work */
}

::v-deep .fe-stepper-checkbox .v-label {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block !important; /* necessary to allow text-overflow to work */
    white-space: nowrap;
}

::v-deep .pa-0 {
    transition: max-width 0.5s;
}
</style>
