<template>
    <div class="flex-column fill-height pb-5">
        <breadcrumb v-model="currentBreadcrumb" class="ml-5 pt-5" @click="doBreadcrumb" />
        <v-flex row class="pl-5" style="height: 50px">
            <span style="font-size: 24px" class="pt-5">{{ updateCollectionTitle }}</span>
            <v-spacer class="pt-5"/>
            <fe-tooltip tooltip="Share Collection">
                <fe-icon-btn color="#050F2D" useIcon="fas fa-user-plus" class="ec-collection-share" @click="showShareDialog = true"></fe-icon-btn>
            </fe-tooltip>
            <v-menu
                v-model="showManageDialog"
                bottom offset-y z-index="10"
                :disabled="!userCanEdit"
            >
                <template v-slot:activator="{ on }">
                    <fe-tooltip tooltip="Manage Collection">
                        <fe-icon-btn v-on="on" useIcon="far fa-ellipsis-v" color="#050F2D" class="ec-collection-ellipsis" :disabled="!userCanEdit"></fe-icon-btn>
                    </fe-tooltip>
                </template>
                <v-list>
                    <v-list-item v-for="item in manageItems" @click="collectionManageSelection = item.value" v-if="item.value !== 'remove' || (item.value === 'remove' && subcollectionData.length > 0)">
                        <i v-if="item.icon" :class="item.icon" class="mr-2"></i>{{ item.name }}
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-flex>
        <fe-left-menu-layout
            :menuItems="menuItems"
            v-model="activeItem"
            class="ec-collection-left-layout">
            <div v-if="activeItem && activeItem.name === 'Charts'" style="width: 100%">
                <collection-charts @all-charts="getAllCharts" :reload="reload" @reloaded="reloaded" @updated-category="updateCharts"></collection-charts>
            </div>
            <div v-else-if="activeItem && activeItem.name === 'SubCollections'" style="width: 100%">
                <subcollections :reload="reload" @reloaded="reloaded"></subcollections>
            </div>
            <div v-else-if="activeItem && activeItem.name === 'Data Walls'" style="width: 100%">
                <data-walls :reload="reload" @reloaded="reloaded" @removed="onLoad(true)"></data-walls>
            </div>
            <div v-if="activeItem && activeItem.name === 'smartFORMS'" style="width: 100%">
                <smartforms :reload="reload" @reloaded="reloaded" :dashboard_id="dashboard_id"></smartforms>
            </div>
            <div v-if="activeItem && activeItem.name === 'Meetings'" style="width: 100%">
                <meetings :reload="reload" @reloaded="reloaded"></meetings>
            </div>
        </fe-left-menu-layout>

        <fe-dialog v-if="showEditDialog"
            persistent
            :title="'Edit ' + this.collectionData[0].title"
            acceptButtonText="OK"
            dismissButtonText="Cancel"
            @accept="showEditDialog = false; editCollection(); collectionManageSelection = null;"
            @dismiss="showEditDialog = false; collectionManageSelection = null;">
            <fe-text-field v-model="updateCollectionTitle" label="New Name" class="mb-5" width="100%"></fe-text-field>
            <fe-label>New Color</fe-label>
            <fe-color-selector @change="updatedCollectionColor" :value="updateCollectionColor"></fe-color-selector>
        </fe-dialog>
        <fe-dialog v-if="showReorderDialog"
            persistent
            height="500px"
            width="500px"
            title="Reorder Charts"
            dismissButtonText="Cancel"
            @accept="changeCollectionOrder"
            @dismiss="showReorderDialog = false; collectionManageSelection = null;">
            <div>Drag and drop to reorder.</div>
                <draggable v-model="localCollectionSearches">
                    <transition-group>
                        <div v-for="(ss, index) in localCollectionSearches" :key="`list-item-`+index">
                            <v-input :value="ss.dashboard_id"
                                hide-details
                                append-icon="far fa-trash"
                                :key="ss.dashboard_id"
                                class="ec-collection-reorder-list my-2"
                                @click:append="removeSelectedSavedSearch(ss, index)"
                            >{{ ss.title }}</v-input>
                        </div>
                    </transition-group>
                </draggable>
        </fe-dialog>
        <fe-dialog v-if="showShareDialog"
            persistent
            height="500px"
            width="500px"
            title="Share Collection"
            :footer="false"
            @dismiss="showShareDialog = false; collectionManageSelection = null;">
            <v-data-table
                :loading="loadingSharedUsers"
                :headers="shareColumnDefs"
                :items="shareRowData"
                hide-default-footer
                disable-pagination
                class="collection-share-data-table">
                <template v-slot:item.can_modify="{item}">
                    <v-lazy>
                        <div class="collection-share-data-table-component">
                            <fe-switch :value="item.can_modify" useIcon="check" hide-details @change="updateCanModify(item, $event)" :disabled="!userCanEdit"></fe-switch>
                        </div>
                    </v-lazy>
                </template>
                <template v-slot:item.remove="{item}">
                    <v-lazy>
                        <div class="collection-share-data-table-component">
                            <fe-icon-btn useIcon="fas fa-minus-circle" color="#050F2D" @click="removeSharedUser(item)" :disabled="!userCanEdit"></fe-icon-btn>
                        </div>
                    </v-lazy>
                </template>
            </v-data-table>
            <div class="d-flex pt-3 collection-share-data-footer">
                <v-autocomplete class="d-inline-block"
                    v-model="newUserIdQuick" style="width: 70%"
                    :search-input.sync="searchText"
                    flat solo dense clearable :multiple="false"
                    placeholder="Add a user (3 characters minimum)"
                    :items="userSearchResults"
                    itemText="user_full_name"
                    itemValue="id"
                    return-object
                    append-icon=""
                    :no-data-text="noSearchString ? '' : 'No data available'"
                    :hide-no-data="noSearchString"
                    :disabled="!userCanEdit"
                >
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title>{{data.item.user_full_name}}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-slot:selection="{ attr, on, item, selected }">
                        {{ newUserIdQuick.user_full_name }}
                    </template>
                </v-autocomplete>
                <span
                    class="collection-share-data-advanced-btn"
                    @click="showAddNewUserDialog = true"
                    :disabled="!userCanEdit"
                    :style="[{'pointer-events': userCanEdit ? 'auto' : 'none'}]"
                >
                    <u>Advanced</u>
                </span>
                <fe-btn
                    class="collection-share-data-btn"
                    :disabled="!newUserIdQuick"
                    @click="saveNewUser('quickAdd')">
                    Add
                </fe-btn>
            </div>
        </fe-dialog>
        <fe-dialog
            v-if="showAddNewUserDialog"
            title="Find Users"
            width="800"
            acceptButtonText="Share Collection With Users"
            dismissButtonText="Cancel"
            dismissable
            persistent
            @accept="showAddNewUserDialog = false; saveNewUser(); collectionManageSelection = null; allowUsersToEdit = false; "
            @dismiss="showAddNewUserDialog = false; collectionManageSelection = null; allowUsersToEdit = false; ">
            <user-search v-model="selectedNewUsers" keepSelection />
            <v-layout>
                <v-flex xs3/>
                <v-flex xs9 class="d-flex">
                    <v-checkbox
                        hide-details=true
                        :ripple=false
                        class="ec-collection-allow-edit-checkbox" 
                        label="Allow users to edit collection"
                        v-model="allowUsersToEdit"/>
                </v-flex>
            </v-layout>
        </fe-dialog>
        <fe-dialog v-if="showDeleteDialog"
            persistent
            title="Confirm Delete"
            acceptButtonText="Delete"
            dismissButtonText="Cancel"
            @accept="showDeleteDialog = false; deleteCollection(); collectionManageSelection = null;"
            @dismiss="showDeleteDialog = false; collectionManageSelection = null;"
            usage="error"
            :body="deleteConfirmText">
        </fe-dialog>
        <fe-dialog v-if="showSubCollectionDialog"
            persistent
            height="500px"
            title="Select Collection"
            :footer="false"
            @dismiss="showSubCollectionDialog = false; collectionManageSelection = null;">
            <v-layout row wrap>
                <v-flex xs4 v-for="collection in allCollections">
                    <v-card outlined class="mt-1 mb-1 ec-collection-card" max-width="90%" width="90%"
                        @click="updateSelectedCollection(collection); showSubCollectionDialog = false;"
                        :style="[{'background': collection.color, 'cursor': 'pointer'}]">
                        <v-card-text class="ec-collection-card-text">
                            <div row class="ec-collection-card-text-text" :style="getFontSubcollection(collection)">{{ collection.title }}</div>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </fe-dialog>
        <fe-dialog v-if="showRemoveDialog"
            persistent
            height="500px"
            title="Select SubCollection"
            :footer="false"
            @dismiss="showRemoveDialog = false; collectionManageSelection = null;">
            <v-layout row wrap>
                <v-flex xs4 v-for="subcollection in subcollectionData">
                    <v-card outlined class="mt-1 mb-1 ec-collection-card" max-width="90%" width="90%"
                        @click="removeSelectedCollection(subcollection)"
                        :style="[{'background': subcollection.color, 'cursor': 'pointer'}]">
                        <v-card-text class="ec-collection-card-text">
                            <div row class="ec-collection-card-text-text" :style="getFontSubcollection(subcollection)">{{ subcollection.title }}</div>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </fe-dialog>
    </div>
</template>

<script>
    import Highcharts from 'highcharts'
    import { mapGetters } from 'vuex';
    import { mapState } from 'vuex'
    import draggable from 'vuedraggable';
    import exports from 'highcharts/modules/exporting';
    import lodash from 'lodash';
    import Subcollections from "./Subcollections";
    import CollectionCharts from "./Charts";
    import DataWalls from "./DataWalls";
    import Smartforms from "./Smartforms";
    import UserSearch from "../../../common/UserSearch";
    import Meetings from "./Meetings";
    import Breadcrumb from '@/components/common/Breadcrumb'
    import Vue from 'vue'
    exports(Highcharts)

    export default {
        name: 'Collection',
        components: {
            Meetings,
            UserSearch,
            DataWalls,
            Smartforms,
            Subcollections,
            CollectionCharts,
            Breadcrumb,
            draggable
        },
        data() {
            return {
                allowUsersToEdit: false,
                allUserNames: null,
                showManageDialog: false,
                showEditDialog: false,
                showReorderDialog: false,
                showShareDialog: false,
                showDeleteDialog: false,
                showSubCollectionDialog: false,
                showAddNewUserDialog: false,
                loading: false,
                menuItems: [
                    { name: 'Charts' }, { name: 'Data Walls' }, { name: 'smartFORMS' }, { name: 'Meetings' }, { name: 'SubCollections' }
                ],
                activeItem: { name: 'Charts' },
                collectionData: [],
                collectionManageSelection: null,
                bigFiveCharts: [],
                attendanceCharts: [],
                overallResults: [],
                categories: [],
                currentCategory: {},
                totalSearches: 0,
                currentSearch: 0,
                totalCharts: [],
                noDataChart: [{ name: null, data: [] }],
                emptyCharts: false,
                updateCollectionTitle: null,
                updateCollectionColor: null,
                shareColumnDefs: [
                    {
                        text: 'User Name',
                        value: 'full_name',
                        align: 'left'
                    },
                    {
                        text: 'Can Edit',
                        value: 'can_modify',
                        align: 'left'
                    },
                    {
                        text: 'Remove',
                        value: 'remove',
                        align: 'left'
                    }
                ],
                shareNewColumnDefs: [
                    {
                        text: 'User',
                        value: 'user_full_name',
                        align: 'left'
                    },
                    {
                        text: 'School',
                        value: 'school',
                        align: 'left'
                    },
                    {
                        text: 'Grade',
                        value: 'grade',
                        align: 'left'
                    }
                ],
                shareNewRowData: [],
                reorderRowData: [],
                shareRowData: [],
                collectionUsers: [],
                studentScoresCharts: [],
                academicProgressMonitorCharts: [],
                charts: [],
                localCollectionSearches: [],
                allDashboards: [],
                dashboard_id: null,
                manageItems: [
                    { name: 'Edit Collection', value: 'edit', icon: 'far fa-puzzle-piece' },
                    { name: 'Reorder Collection', value: 'reorder', icon: 'far fa-puzzle-piece' },
                    { name: 'Create SubCollection', value: 'create', icon: 'far fa-puzzle-piece' },
                    { name: 'Remove SubCollection(s)', value: 'remove', icon: 'far fa-puzzle-piece' },
                    // { name: 'Print Collection', value: 'print', icon: 'far fa-download' },
                    { name: 'Delete Collection', value: 'delete', icon: 'far fa-trash' }
                ],
                reload: false,
                newUserId: null,
                newUserIdQuick: null,
                newUserCanModify: false,
                userSearchResults: [],
                searchText: '',
                schoolId: null,
                gradeId: null,
                selected: {},
                selectedNewUsers: [],
                showRemoveDialog: false,
                subcollectionData: [],
                allCollections: [],
                loadingSharedUsers: true,
                noSearchString: true,
                crumbExists: 0,
            }
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            ...mapState('collection', ['breadcrumbs']),
            ...mapGetters('collection', {
                reloadCollection: 'reloadCollection'
            }),
            userCanEdit() {
                let collectionUser = this.collectionData?.find(user => user.user_id === this.sessionUser.user.id)
                return collectionUser ? collectionUser.can_modify : false
            },
            deleteConfirmText() {
                return 'Are you sure you want to delete this collection? <br>' +
                    'This change is permanent!'
            },
            getDataWallParams() {
                return {}
            },
            schoolURL() {
                if (this.schoolYearAsString) {
                    return 'schools.php?action=get&school_year_id=' + this.schoolYearAsString
                }
                return null
            },
            gradeURL() {
                if (this.schoolYearAsString) {
                    return 'grades.php?action=get&school_year_id=' + this.schoolYearAsString + '&school_id=' + this.selected.school_id
                }
                return null
            },
            schoolYearAsString() {
                let year = this.selected.school_year_id

                if (Array.isArray(year)) {
                    let yr = ''
                    year.forEach((v, i) => {
                        yr += (i === 0 ? '' : '%2C') + v
                    })
                    return yr
                } else {
                    return year
                }
            },
            currentBreadcrumb: {
                get() {
                    let crumbs = this.breadcrumbs['Collections']

                    if (!crumbs) {
                        return [{
                            name: 'vuex path not set in manage/index.js breadcrumbs',
                        }]
                    } else {
                        return [...crumbs]
                    }
                },
                set(v) {
                    this.$store.commit('collection/setCrumbs', {
                        key: 'Collections',
                        val: v
                    })
                }
            }
        },
        methods: {
            editCollection() {
                if(!this.updateCollectionColor) {
                    this.updateCollectionColor = '#0049FF'
                }
                let params = {
                    dashboard_id: this.collectionData[0].dashboard_id,
                    title: this.updateCollectionTitle,
                    color: this.updateCollectionColor
                }
                this.$axios.post('dashboard.php?action=update&property=dashboard', { dashboards: [params] })
                    .then(response => {
                        if(response.data.success) {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                            this.reload = true
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
            },
            updatedCollectionColor(v) {
                this.updateCollectionColor = v
            },
            updateSelectedCollection(v) {
                let params = {
                    parent_dashboard_id: this.collectionData[0].dashboard_id,
                    child_dashboard_id: v.dashboard_id
                }
                this.$axios.post('dashboard.php?action=create&property=dashboard_group', { dashboard_groups: [params] })
                    .then(response => {
                        if(response.data.success) {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                            if (this.activeItem.name === 'SubCollections') {
                                this.reload = true
                            }
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
                    .finally(() => {
                        this.getSubcollections(this.dashboard_id) // updates manageItems menu
                        this.collectionManageSelection = null
                    })
            },
            getCollectionUsers() {
                this.loadingSharedUsers = true
                this.shareRowData = []
                this.$axios.get('dashboard.php?action=get&property=users&id=' + this.dashboard_id)
                    .then(response => {
                        this.collectionUsers = response
                        if (this.allUserNames) {
                            return response
                        } else {
                            return this.$axios.get('users.php?action=get').then(userResponse => {
                                this.allUserNames = userResponse.data.map(u => {
                                    return {
                                        id: u.id,
                                        name: u.user_full_name
                                    }
                                })
                                return response
                            })
                        }
                    }).then(response => {
                        response.data.users.forEach(user => {
                            let userName = this.allUserNames.find(u => u.id === user.user_id)
                            if (userName) {
                                this.shareRowData.push({
                                    id: userName.id,
                                    full_name: userName.name,
                                    can_modify: user.can_modify
                                })
                            }
                        })
                        this.$nextTick(() => this.loadingSharedUsers = false)
                    })
            },
            changeCollectionOrder() {
                let params = []
                this.localCollectionSearches.forEach((coll, index) => {
                    params.push({
                        dashboard_id: coll.dashboard_id,
                        saved_search_id: coll.saved_search_id,
                        rank: index,
                        title: coll.title
                    })
                })
                this.$axios.post('dashboard.php?action=update&property=saved_search', { saved_searches: params })
                    .then(response => {
                        if(response.data.success) {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                            this.reload = true
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
                    .finally(() => {
                        this.showReorderDialog = false
                        this.collectionManageSelection = null
                    })
            },
            removeSelectedSavedSearch(ss, index) {
                let params = {
                    dashboard_id: this.dashboard_id,
                    saved_search_id: ss.saved_search_id
                }
                this.$axios.post('dashboard.php?action=delete&property=saved_search', { dashboard_saved_search: params })
                    .then(response => {
                        if(response.data.success) {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                            this.reload = true
                            this.localCollectionSearches.splice(index, 1)
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
            },
            deleteCollection() {
                let params = {
                    dashboard_id: this.dashboard_id
                }
                this.$axios.post('dashboard.php?action=delete&property=dashboard', { dashboards: [params] })
                    .then(response => {
                        if(response.data.success) {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                            this.$router.replace('/Collections')
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
            },
            onLoad(forceReload=false) {
                this.loading = true
                this.collectionData = []
                this.collectionManageSelection = null
                this.$modelGet('collectionHome', {active: 1})
                    .then(response => {
                        this.allCollections = response.user_dashboards.concat(response.shared_dashboards)
                    })
                    .finally(() => {
                        if(this.allCollections && this.allCollections.length > 0 && this.$route.params.id) {
                            this.dashboard_id = parseInt(this.$route.params.id.split(':')[1])
                            this.collectionData = this.allCollections.filter(data => data.dashboard_id === this.dashboard_id)
                            this.updateCollectionTitle = this.collectionData[0].title
                            this.updateCollectionColor = this.collectionData[0].color
                            if(forceReload) {
                                this.reload = true
                            }
                        }
                        this.showManageDialog = false
                        this.showEditDialog = false
                        this.showReorderDialog = false
                        this.showShareDialog = false
                        this.showDeleteDialog = false
                        this.showSubCollectionDialog = false
                        this.loading = false

                        this.getSubcollections(this.dashboard_id)

                        // reset breadcrumbs if browser back button is used
                        window.addEventListener('popstate', () => {
                            this.currentBreadcrumb = [{
                                name: 'Collections'
                            }]
                        })

                        // add breadcrumb if it doesn't already exist
                        this.crumbExists = this.breadcrumbs['Collections'].findIndex(x => x.id === this.dashboard_id)
                        if (!this.crumbExists || this.crumbExists === -1) {
                            this.$store.commit('collection/addCrumb', {
                                key: "Collections",
                                val: {
                                    id: this.dashboard_id,
                                    name: this.updateCollectionTitle
                                }
                            })
                        }
                    })
            },
            getAllCharts(v) {
                this.charts = v
            },
            doSearch() {
                this.$axios.get('users.php?action=get&active=1&search=' + this.searchText)
                    .then(response => {
                        this.$data.userSearchResults = response.data
                    })
                    .catch(error => {
                        console.error(error)
                    })
            },
            saveNewUser(type = null) {
                let params = []
                if(type === 'quickAdd') {
                    params.push({
                        dashboard_id: this.dashboard_id,
                        user_id: this.newUserIdQuick.id,
                        can_modify: false
                    })
                }
                else {
                    this.selectedNewUsers.forEach(userId => {
                        params.push({
                            dashboard_id: this.dashboard_id,
                            user_id: userId.user_id || userId.id,
                            can_modify: this.allowUsersToEdit
                        })
                    })
                }
                this.$axios.post('dashboard.php?action=create&property=users', { users: params })
                    .then(response => {
                        if(response.data.success) {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
                    .finally(() => {
                        this.getCollectionUsers()
                        this.showAddNewUserDialog = false
                    })
            },
            updateCanModify(item, event) {
                this.loadingSharedUsers = true
                let can_modify = 1
                if(!event) can_modify = 0
                let params = {
                    dashboard_id: this.dashboard_id,
                    user_id: item.id,
                    can_modify: can_modify
                }
                this.$axios.post('dashboard.php?action=update&property=users', { users: [params] })
                    .then(response => {
                        if(response.data.success) {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
                    .finally(() => {
                        this.getCollectionUsers()
                    })
            },
            printCharts(charts) {
                let origDisplay = [],
                    origParent = [],
                    body = document.body,
                    childNodes = body.childNodes

                // hide all body content
                Highcharts.each(childNodes, function (node, i) {
                    if (node.nodeType === 1) {
                        origDisplay[i] = node.style.display
                        node.style.display = "none"
                    }
                })

                // put the charts back in
                charts.forEach((chart, i) => {
                    origParent[i] = chart.container.parentNode
                    body.appendChild(chart.container)
                })

                // print
                window.print()

                // allow the browser to prepare before reverting
                setTimeout(function () {
                    // put the chart back in
                    charts.forEach((chart, i) => {
                        origParent[i].appendChild(chart.container)
                    })
                    // restore all body content
                    Highcharts.each(childNodes, function (node, i) {
                        if (node.nodeType === 1) {
                            node.style.display = origDisplay[i]
                        }
                    })
                }, 500)
            },
            updateCharts(v) {
                if(!v.title) {
                    let index = this.localCollectionSearches.findIndex(lcs => lcs.id === v.dash_id)
                    this.localCollectionSearches[index].title = v.overallResults[0].sub_category_name
                }
            },
            removeSharedUser(item) {
                this.loadingSharedUsers = true
                let params = {
                    dashboard_id: this.dashboard_id,
                    user_id: item.id
                }
                this.$axios.post('dashboard.php?action=delete&property=users', { users: [params] })
                    .then(response => {
                        if(response.data.success) {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
                    .finally(() => {
                        this.shareRowData = []
                        this.getCollectionUsers()
                    })
            },
            reloaded() {
                this.$store.commit('collection/set', {
                    key: 'reloadCollection',
                    value: false
                })
                this.reload = false
            },
            removeSelectedCollection(v) {
                this.$axios.post('dashboard.php?action=delete&property=dashboard_group', { dashboard_groups: [v] })
                    .then(response => {
                        if(response.data.success) {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                            if (this.activeItem.name === 'SubCollections') {
                                this.reload = true
                            }
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
                    .finally(() => {
                        this.getSubcollections(this.dashboard_id) // updates manageItems menu
                        this.showRemoveDialog = false
                        this.collectionManageSelection = null
                    })
            },
            getFontSubcollection(collection) {
                let fontColor = '#fff'
                if(!collection.color) {
                    fontColor = '#000'
                }
                return {
                    'color': fontColor
                }
            },
            getSubcollections(v) {
                if(v) {
                    this.$axios.get('dashboard.php?action=get&property=dashboard_group&parent_dashboard_id=' + this.dashboard_id)
                        .then(response => {
                            this.subcollectionData = response.data.dashboard_groups
                        })
                }
            },
            doBreadcrumb(v) {
                if (v.id) {
                    // does crumb exist?
                    // used in onLoad
                    this.crumbExists = this.currentBreadcrumb.findIndex(x => x.id === v.id)
                    this.$router.push('/Collections/id:' + v.id)
                } else {
                    this.$router.push('/Collections/')
                }
            }
        },
        watch: {
            collectionData(v) {
                this.localCollectionSearches = []
                if(v[0]) {
                    v[0].saved_searches.forEach(ss => {
                        this.localCollectionSearches = v[0].saved_searches
                    })
                } else {
                    this.localCollectionSearches = []
                }
            },
            collectionManageSelection(v) {
                if(v) {
                    this.showManageDialog = false
                }
                if(v === 'edit') {
                    this.showEditDialog = true
                } else if(v ===  'reorder') {
                    this.showReorderDialog = true
                } else if(v ===  'share') {
                    this.shareRowData = []
                    this.getCollectionUsers()
                } else if(v ===  'delete') {
                    this.showDeleteDialog = true
                } else if(v === 'create') {
                    this.showSubCollectionDialog = true
                } else if(v === 'print') {
                    this.printCharts(this.charts)
                    this.onLoad()
                } else if(v === 'remove') {
                    this.showRemoveDialog = true
                }
            },
            showShareDialog(v) {
                if(v) {
                    this.collectionManageSelection = 'share'
                }
            },
            searchText: lodash.debounce(function(v) {
                if (v && v.length >= 3) {
                    this.noSearchString = false
                    this.doSearch()
                } else {
                    this.noSearchString = true
                }
            }, 500),
            $route(to, from) {
                // reset breadcrumbs if moving from collections to another page
                // helps prevent duplicate breadcrumbs
                if (from.path.includes('/Collections') && !to.path.includes('/Collections')) {
                    this.currentBreadcrumb = [{
                        name: 'Collections'
                    }]
                }

                if (to.path.includes('/Collections/id')) {
                    this.activeItem = { name: 'Charts' }
                    this.onLoad()
                }
            },
            reloadCollection(v) {
                this.reload = v
            }
        },
        mounted() {
            this.onLoad()
        }
    }
</script>

<style lang="scss" scoped>
    .ec-collection {
        &-left-layout {
            ::v-deep .menu-title-toolbar {
                min-height: 46px !important;
                height: 46px;
                display: flex !important;
                align-items: center !important;
            }
            ::v-deep  .nav-item-selected {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            ::v-deep .left-menu-container {
                width: 190px !important;
                border-right: 1px solid #E0E1E6;
            }
            ::v-deep .flex-grow-1 {
                border-top: 1px solid #E0E1E6;
            }
            ::v-deep .menu-title-container-bordered {
                border: none !important;
            }
        }
        &-layout-main {
            height: calc(100vh - 200px);
            overflow: auto;
        }
        &-empty-state {
            justify-content: center;
            display: flex;
            text-align: center;
        }
        &-card {
            min-height: 100px;
            max-height: 100px;
            line-height: 0;
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            min-width: 0;
            &-text {
                padding: 4px !important;
                text-align: center;
                &-text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 90px;
                    width: 90px;
                    white-space: nowrap;
                    display: block;
                    position: relative;
                }
            }
        }
        &-reorder-list {
            cursor: pointer;
            border: 1px solid #000;
            border-radius: 4px;
            margin-bottom: 2px;
            height: 35px;
            min-height: 35px !important;
            display: flex;
            align-items: center;
        }
        &-ellipsis {
            padding-top: 8px;
            ::v-deep i {
                font-size: 20px !important;
            }
        }
        &-share {
            padding-top: 7px;
            ::v-deep i {
                font-size: 16px !important;
            }
            ::v-deep .v-btn {
                margin-right: 0 !important;
            }
        }
        &-allow-edit-checkbox {
            padding-top: 24px;
            ::v-deep label {
                font-size: 16px !important;
            }
        }
    }
    .collection-share-data {
        &-table {
            border: 1px solid #E0E1E6;
            ::v-deep th:not(:last-of-type) {
                border-right: 1px solid #E0E1E6;
            }
            ::v-deep td:not(:last-child) {
                border-right: 1px solid #E0E1E6;
            }
            &-th {
                height: 54px;
                vertical-align: middle;
                font-size: 14px;
                line-height: 16px;
                border-bottom: 1px solid #E0E1E6;
            }
            &-component {
                justify-content: center;
                display: flex;
                align-items: center;
            }
        }
        &-btn {
            ::v-deep .fe-button {
                margin: 0 0 0 8px !important;
            }
        }
        &-advanced-btn {
            position: absolute;
            right: 100px;
            padding-top: 8px;
            font-size: 12px;
            color: var(--v-primary-base);
            cursor: pointer;
        }
        &-footer {
            position: absolute;
            bottom: 0;
            width: 90%;
        }
    }
    .small-tab {
        text-transform: capitalize;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        font-size: 14px;
        letter-spacing: normal;
        justify-content: flex-start;
        &-name {
            min-width: 126px;
            max-width: 126px;
            padding-left: 6px;
            margin: 5px 15px 5px 0;
        }
        &-school {
            min-width: 190px;
            max-width: 190px;
            margin: 5px 0;
        }
    }
    .small-tab::before {
        border-radius: 4px;
    }
    .collection-share-data {
        &-tabs {
            ::v-deep .v-item-group {
                position: relative;
                flex-wrap: nowrap;
                white-space: nowrap;
                max-width: 100%;
            }
            ::v-deep .v-tabs-items {
                padding-top: 20px;
            }
            ::v-deep .v-tabs-slider {
                border-radius: 1.5px;
                &-wrapper {
                    height: 3px !important;
                }
            }
        }
        &-tab-item-div {
            height: calc(100vh - 200px);
            overflow: auto;
        }
        &-find-btn {
            position: relative;
            right: 0;
            ::v-deep .fe-button {
                margin: 0 !important;
            }
        }
        &-table {
            max-height: 350px;
            ::v-deep .v-data-table__wrapper {
                max-height: 350px;
                overflow-y: auto !important;
            }
        }
    }
</style>
