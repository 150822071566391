<template>
    <div class="d-flex flex-fill flex-column">
        <div class="text-right pr-5 mb-2">
            <toggle-button :items="toggles" v-model="activeToggle"/>
        </div>

        <div v-if="view==='academic'">
            Charts
        </div>

        <v-row style="margin-bottom: 40px !important; overflow: scroll;" v-if="activeToggle.name === 'Charts'">
            <v-col cols="6" v-for="(cfg,i) in charts" v-if="!loading">
                <div class="chart-container">
                    <highcharts :ref="`chart-${i}`" :options="cfg"/>
                    <div class="chart-tools">
                        <fe-icon-btn useIcon="fas fa-expand-alt" @click="doExpand(cfg)"/>
                    </div>
                </div>
            </v-col>
        </v-row>

        <div class="d-flex flex-fill flex-column pa-2" v-else>
            <v-slide-y-transition>
                <div v-if="selections.length > 0" class="selection-toolbar d-flex">
                    <div class="mt-3 ml-5">{{selections.length}} Student{{ selections.length > 1 ? 's' : '' }} Selected</div>

                    <div class="ml-auto">
                        <v-btn text class="white--text" @click="tagStudents">Tag Students</v-btn>
                        <v-btn text class="white--text" @click="$refs.grid.gridApi.deselectAll()">Cancel</v-btn>
                    </div>
                </div>
            </v-slide-y-transition>

            <fe-grid
                :rowData="rowData"
                :columnDefs="columns"
                displayDensity="small"
                style="height: 100%;"
                :showAddRowBtn="false"
                :csvExportProcessCallback="exportData"
                ref="grid"
                @selectionChanged="selections=$refs.grid.gridApi.getSelectedRows()"
                @gridReady="loadStudentList"
                groupUseEntireRow
                @rowDoubleClicked="loadIntervention"
                @rowDataChanged="expandNodes"
            />
        </div>

        <div class="nav-breadcrumb" v-show="activeToggle.name==='Charts'">
            <breadcrumb v-model="crumbs" @click="crumbClick"/>
        </div>

    </div>
</template>

<script>
    import ToggleButton from '@/components/common/button/ToggleButton'
    import Breadcrumb from '@/components/common/Breadcrumb'
    import windowOptionsMixin from '@/mixins/windowOptions'

    export default {
        name: 'InterventionAnalysisDrilldown',
        mixins: [ windowOptionsMixin ],
        components: { Breadcrumb, ToggleButton },
        data() {
            let me = this
            return {
                charts: [],
                raw: [],
                crumbs: [],
                loading: false,
                toggles: [{
                    name: 'Charts',
                    icon: 'fas fa-chart-bar'
                }, {
                    name: 'Data List',
                    icon: 'fas fa-bars'
                }],
                activeToggle: {},
                selections: [],
                columns: [me.$grid.checkColumn(), {
                        headerName: "Student ID",
                    field: "student_id",
                    hide: true
                }, {
                    headerName: "State ID",
                    field: "student_state_id",
                    hide: true
                }, {
                    headerName: "District ID",
                    field: "student_district_id",
                    hide: true
                }, {
                   headerName: "Student",
                   field: "student_full_name",
                   width: 200
                }, {
                   headerName: "Participation",
                   field: "fidelity",
                   width: 80,
                   cellStyle(v) {
                       let color = !v.value ? '' : (parseInt(v.value) < 80 ? '#FF675D' : '#19ADAB')

                       return {
                           textAlign: 'right',
                           background: color
                       }
                   }
                }, {
                   headerName: "ROI",
                   field: "roi",
                   width: 80,
                   cellStyle: { textAlign: 'right' }
                }, {
                   headerName: "Skill Area",
                   field: "category_name",
                   editable: false,
                   width: 110,
                   rowGroup: true
                }, {
                   headerName: "Interventionist",
                   field: "interventionist_names",
                   width: 150,
                }, {
                   headerName: "Level",
                   field: "intervention_level_name",
                   editable: false,
                   width: 100
                }, {
                   headerName: "Type",
                   field: "intervention_type_name",
                   editable: false,
                   width: 200
                }, {
                   headerName: "Title",
                   field: "intervention_plan_name",
                   editable: false,
                   width: 200
                }, {
                   headerName: "School",
                   field: "school_name",
                   width: 100
                }, {
                   headerName: "Grade",
                   field: "grade_desc",
                   width: 100
                }, {
                   headerName: "Ethnicity",
                   field: "student_ethnicity",
                   width: 100
                }, {
                   headerName: "Gender",
                   field: "student_gender",
                   width: 80
                }, {
                    headerName: "Category ID",
                    field: "category_id",
                    hide: true
                }, {
                    headerName: "Intervention ID",
                    field: "intervention_id",
                    hide: true
                }, {
                    headerName: "Intervention Plan Start Date",
                    field: "intervention_plan_intervention_start_date",
                    hide: true
                }, {
                    headerName: "Intervention Plan End Date",
                    field: "intervention_plan_intervention_end_date",
                    hide: true
                }, {
                    headerName: "Student Intervention Plan ID",
                    field: "student_intervention_plan_id",
                    hide: true
                }, {
                    headerName: "Intervention Plan ID",
                    field: "intervention_plan_id",
                    hide: true
                }, {
                    headerName: "Plan Start Date",
                    field: "intervention_plan_start_date",
                    hide: true
                }, {
                    headerName: "Plan End Date",
                    field: "intervention_plan_end_date",
                    hide: true
                }, {
                    headerName: "Plan Session Duration",
                    field: "intervention_plan_session_duration",
                    hide: true
                }, {
                    headerName: "Plan User ID",
                    field: "intervention_plan_user_id",
                    hide: true
                }, {
                    headerName: "Possible Days",
                    field: "possible_days",
                    hide: true
                }, {
                    headerName: "Completed Days",
                    field: "completed_days",
                    hide: true
                }, {
                    headerName: "Current Day Complete",
                    field: "current_day_complete",
                    hide: true
                }, {
                    headerName: "Exit Status",
                    field: "exit_status",
                    hide: true
                }, {
                    headerName: "Recorded Day",
                    field: "recorded_day",
                    hide: true
                }, {
                    headerName: "Schedule Date Occurrence",
                    field: "schedule_date_occurrence",
                    hide: true
                }, {
                    headerName: "Disabilities",
                    field: "disabilities",
                    hide: true
                }],
                rowData: []
            }
        },
        props: {
            view: {
                type: String
            },
            params: {

            },
            roi: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            loading(v) {
                this.$setLoading(v)
            },
            params: {
                handler(v) {
                    this.crumbs = [{
                        name: 'Drilldown',
                        params: {...v}
                    }]

                    this.loadData()
                },
                immediate: true
            }
        },
        mounted() {
            this.activeToggle = this.toggles[0]
        },
        methods: {
            expandNodes() {
                if (!this.$refs.grid.gridApi) return

                this.$refs.grid.gridApi.forEachNode((node, b) => {
                    if (node.level === 0) {
                        node.setExpanded(true)
                    }
                })
            },
            loadIntervention(node) {
                this.$dockableWindow({
                    name: 'Intervention Groups',
                    component: 'intervention-workspace',
                    attrs: {
                        interventionPlanId: node.data.intervention_plan_id
                    }
                })
            },
            tagStudents() {
                let me=this
                this.$tagStudents(true, this.selections,()=> {
                    me.$refs.grid.gridApi.deselectAll()

                })
            },
            crumbClick(crumb) {
                this.loadData(crumb.params)
            },
            doExpand(cfg) {
                let c = this.$_.cloneDeep({...cfg})
                c.chart.height = window.innerHeight-150
                c.xAxis.labels.useHTML = true
                c.xAxis.labels.formatter = function(){
                    return this.value
                }
                this.$dockableWindow({
                    name: cfg.title.text,
                    component: 'highcharts',
                    attrs: {
                        options: c
                    }
                })
            },
            baseConfig(cfg) {
                let me = this

                return {
                    credits: { enabled: false },
                    chart: {
                        type: 'column',
                        height: 350,
                        zoomType: 'xy'
                        // marginBottom: 50
                        // padding: [0,0,0,0]
                    },
                    title: {
                        text: null,
                        align: 'left'
                    },
                    yAxis: {
                        endOnTick: false,
                        title: {
                            text: 'Count'
                        }
                    },
                    plotOptions: {
                        series: {
                            color: '#08A5DA',
                            point: {
                                events: {
                                    click(e) {
                                        let rec = cfg.data.find(r=>r[cfg.key] === e.point.name)
                                        let p = {}
                                        me.crumbs.forEach(c => { p = {...p, ...c.params} })

                                        p[cfg.key_id] = rec[cfg.key_id]
                                        me.crumbs.push({
                                            name: rec[cfg.key],
                                            params: {
                                                ...p,
                                                user_id: p.intervention_plan_user_id || p.user_id || undefined
                                            }
                                        })
                                        me.loadData()
                                    }
                                }
                            },
                            stacking: 'normal',
                            dataLabels: {
                                enabled: true,
                                style: {
                                    textOutline: 0,
                                    color: '#000000'
                                },
                                formatter: function() {
                                    let tip=this
                                    let rec = cfg.data.find(r=>{
                                        return r[cfg.key]===tip.key
                                    })
                                    return me.roi ? rec?.roi : null
                                }
                            },
                            label: {
                                style: {
                                    color: 'black'
                                }
                            },
                            size: '100%'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        labels: {
                            formatter() {
                                return (this.value+'').substring(0, 8)+'...'
                            },
                            style: {
                                fontSize: '10px',
                                color: 'gray'
                            }
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    exporting: {
                        enabled: false
                    },
                    series: [{
                        name: 'Status',
                        dataSorting: {
                            enabled: true
                        },
                        data: []
                    }]
                }
            },
            loadStudentList() {
                this.$setLoading(true)
                let p = {...this.params, ...this.crumbs[this.crumbs.length-1].params}
                delete p.chart_types
                this.$axios.get('interventionView.php?action=get&property=student_intervention_summary&type=academic&export=csvx&'+this.$objectToParams(p))
                    .then(resp => {
                        const interventions = this.$ecResponse(resp, 'interventions')
                        interventions.forEach(intervention => intervention.interventionist_names = intervention.interventionists.map(interventionist => interventionist.name).join(', '))
                        this.rowData = interventions
                        this.$setLoading(false)
                    })
            },
            loadData(force) {
                let p = force || this.crumbs[this.crumbs.length-1].params
                this.loading=true
                this.loadStudentList()
                this.$axios.get('interventionView.php?action=get&property=student_intervention_summary_charts&'+this.$objectToParams(p))
                    .then(r => {
                        this.raw = this.$ecResponse(r, 'charts')
                        this.processData()
                        this.loading=false
                    })
            },
            processData() {
                this.charts = []
                this.raw.forEach(r => {
                    let cfg = this.baseConfig({...r})
                    let serie = cfg.series[0]
                    serie.name = r.title
                    cfg.title.text = r.title
                    let found = {}
                    // cfg.xAxis.categories = r.data.map(d=>d[r.key])


                    if (r.chart_type === 'student' && r.param_key != 'four_week_possible_pct') {
                        serie.data = r.data.map(r=>r.roi ? {
                            name: r.student_full_name,
                            y: parseFloat(r.roi)
                         } : null)
                    } else {
                        serie.data = r.data.map(d=>{
                            let key = d[r.key] || 'Not Specified'
                            let val = r.param_key

                            if (!found[key]) {
                                found[key] = 0
                            }

                            found[key]++
                            return {
                                name: key + (found[key]==1 ? '' : (' ' + found[key])), // names are not guaranteed unique :(
                                y: d[val] ? parseInt(d[val]) : 0,
                                meta: {
                                    data: d
                                }
                            }
                        })
                    }

                    serie.data = this.$_.orderBy(serie.data, 'y', 'desc')
                    this.charts.push(cfg)
                })

            },
            exportData() {
                this.$refs.grid.gridApi.exportDataAsCsv({
                    processCellCallback(cell) {
                        if (!cell.value && cell.value !== 0) return ''
                        let tmp = document.createElement("DIV")
                        tmp.innerHTML = `${cell.value}`.replace(/\</g, " <")
                        return tmp.textContent.trim() || tmp.innerText.trim() || ""
                    },
                    columnGroups: true,
                    skipHeader: false,
                    skipPinnedTop: false,
                    allColumns: true,
                    onlySelected: false,
                    fileName: 'interventions.csv'
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.selection-toolbar {
    width: 99%;
    position: absolute;
    left: 6px;
    height: 48px;
    background: #66A7BC;
    color: white;
    z-index: 10;
    border-radius: 4px;
}

.chart-container {
    position: relative;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 12px 12px 12px 12px;
    background: white;

    ::v-deep .highcharts-root * {
        font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
    }

    .chart-tools {
        position: absolute;
        top: 0px;
        right: 0px;
    }
}

.nav-breadcrumb {
    position: absolute;
    width: 100%;
    border-top: 1px solid #dddddd;
    bottom: 0;
    background: white;
    padding: 4px 8px 4px 16px;
}
</style>
