<template>
    <div class="d-block flex-fill flex-column" style="height: 100%">
        <v-form v-model="isValid">
            <div class="section-title">Goals</div>

            <fe-remote-combo
                class="pa-2"
                :items="goalTypeItems"
                :clearable="false"
                itemText="name"
                itemValue="id"
                byId
                v-model="selectedType"
            />

            <div
                class="mx-2 slo-strategy-grid"
                ref="slo-strategy-grid"
                v-if="selectedType == 1 && chartOptions.length"
            >
                    <div class="d-flex row flex-nowrap headers">
                        <div class="flex-grow-1 flex-shrink-1 cell cell-0">Target</div>
                        <div class="flex-grow-0 flex-shrink-0 cell cell-1">Baseline</div>
                        <div class="flex-grow-0 flex-shrink-0 cell cell-2">Goal</div>
                        <div class="flex-grow-0 flex-shrink-0 cell cell-3">%</div>
                    </div>
                    <div class="row-container">
                        <div
                            class="d-flex row flex-nowrap"
                            v-for="(item, i) in chartOptions"
                            :key="`slo-goal-card-${i}`"
                        >
                            <div class="d-flex flex-grow-1 flex-shrink-1 cell cell-0 align-center justify-center">
                                <div class="mr-2" style="height: 25px; width: 25px;" :style="{'background-color': item.color}" />
                                {{item.name}}
                            </div>
                            <v-text-field
                                filled
                                disabled
                                :value="item.data[0]"
                                type="number"
                                class="flex-grow-0 flex-shrink-0 cell cell-1"
                                flat solo dense
                            />
                            <v-text-field
                                filled
                                :value="item.data[1]"
                                @input="item.data[1] = parseFloat($event)"
                                @change="update(item)"
                                type="number"
                                class="flex-grow-0 flex-shrink-0 cell cell-2"
                                flat solo dense validateOnBlur
                            />
                            <v-text-field
                                filled
                                disabled
                                :value="item.goal_pct"
                                @input="percent(item, parseFloat($event))"
                                type="number"
                                class="flex-grow-0 flex-shrink-0 cell cell-3"
                                flat solo dense
                            />
                        </div>
                        <div
                            class="d-flex row flex-nowrap">
                            <div class="flex-grow-1 flex-shrink-1 cell cell-0">{{chartOptions.length}} Ranges</div>
                            <div class="flex-grow-0 flex-shrink-0 cell cell-1 total">{{baselineTotal}}</div>
                            <div class="flex-grow-0 flex-shrink-0 cell cell-2 total">{{goalTotal}}</div>
                            <div class="flex-grow-0 flex-shrink-0 cell cell-3 total">{{rangeTotal}}</div>
                        </div>
                    </div>
            </div>

            <div v-if="selectedType == 5 && avgScore.data" class="d-block">
                <div class="d-flex">
                    <fe-label class="pt-2 px-2" style="width: 40%; font-size: 14px">Baseline</fe-label>
                    <v-text-field
                        :value="avgScore.data[0]"
                        class="px-2 disabled-label"
                        style="width: 60%"
                        flat solo dense
                        disabled
                    />
                </div>

                <div class="d-flex">
                    <fe-label class="pt-2 px-2" style="width: 40%; font-size: 14px">Goal</fe-label>
                    <v-text-field
                        @input="avgScore.data[1] = parseFloat($event)"
                        :value="avgScore.data[1]"
                        @change="updateAvg(avgScore)"
                        class="pa-2"
                        style="width: 60%"
                        flat solo dense
                        type="number"
                        validateOnBlur
                        :rules="$fieldValidators('text', 'Goal', { required: true })"
                    />
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
    import windowOptionsMixin from '@/mixins/windowOptions'
    import NumericEditor from "@/components/common/grid/NumericEditor"

    export default {
        name: 'SloCreationChartingGoals',
        mixins: [ windowOptionsMixin ],
        components: { NumericEditor },
        props: {
            options: {
                type: Array,
                default: () => []
            },
            headers: { type: Boolean, default: false },
            sloRecord: { type: Object, required: true },
        },
        data() {
            return {
                isValid: false,
                chartOptions: [],
                selectedType: this.sloRecord.target_set_id ? 1 : 5,
                baselineTotal: 0,
                goalTotal: 0,
                rangeTotal: 0,
                avgScore: {},
                stripScores: [],
            }
        },

        computed: {
            goalTypeItems() {
                if (this.sloRecord.target_set_id) {
                    return [{ id: 1, name: 'Target Growth' }, { id: 5, name: 'Average Score' }]
                } else {
                    return [{ id: 5, name: 'Average Score' }]
                }
            },
        },

        methods: {
            percent(item, val) {
                if (val) return val
                return ((item.data[1] / this.baselineTotal) * 100).toFixed(1)
            },
            update(item) {
                this.goalTotal = 0
                this.chartOptions.forEach(option => {
                    this.goalTotal += option.data[1]
                })
                this.chartOptions.forEach(opt => {
                    opt.goal_pct = ((opt.data[1] / this.baselineTotal) * 100).toFixed(1)
                    opt.baseline_pct = ((opt.data[0] / this.baselineTotal) * 100).toFixed(1)
                })
                this.rangeTotal = ((this.goalTotal / this.baselineTotal) * 100).toFixed(1)
                let updateOptions = this.$_.cloneDeep(this.chartOptions)
                this.stripScores.forEach(score => {
                    updateOptions.unshift(score)
                })
                updateOptions.unshift(this.avgScore)
                this.$emit('update', updateOptions, this.selectedType, this.baselineTotal, this.avgScore)
            },
            updateAvg(item) {
                let updateOptions = this.$_.cloneDeep(this.chartOptions)
                this.stripScores.forEach(score => {
                    updateOptions.unshift(score)
                })
                updateOptions.unshift(this.avgScore)
                this.$emit('update', [this.avgScore], this.selectedType, this.baselineTotal, this.avgScore)
            },
            filterUpdate() {
                this.stripScores = []
                let optsCopy = _.cloneDeep(this.options)
                let filtered = optsCopy.filter((chart) => {
                    if (chart.hasOwnProperty('type') && chart.name != 'Avg Score') this.stripScores.push(chart)
                    if (chart.name == 'Avg Score') this.avgScore = chart
                    if (!chart.hasOwnProperty('target_descriptor_id')) chart.target_descriptor_id = null
                    return !chart.hasOwnProperty('type')
                })

                filtered.forEach(option => {
                    this.baselineTotal += option.data[0]
                    this.goalTotal += option.data[1]
                })
                filtered.forEach(opt => {
                    opt.goal_pct = ((opt.data[1] / this.baselineTotal) * 100).toFixed(1)
                    opt.baseline_pct = ((opt.data[0] / this.baselineTotal) * 100).toFixed(1)
                })
                this.rangeTotal = ((this.goalTotal / this.baselineTotal) * 100).toFixed(1)
                this.chartOptions = filtered
            },
        },

        mounted () {
            this.filterUpdate()
        },

        watch: {
            isValid(value) {
                this.$emit('validate', value)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .section-title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .slo-strategy-grid {
        overflow: hidden;
        border: solid 1px #e5e5e5;
        border-radius: 4px;
        .row-container {
            position: relative;
            max-height: 400px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
    .row {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: visible;
        line-height: 30px;
        border-top: solid 1px #e5e5e5;
        &.headers {
            border-top: none;
            font-weight: bold;
            line-height: 50px;
        }
        .cell {
            padding: 5px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-align: center;
            border-right: solid 1px #e5e5e5;
            border-radius: 0;
            &-0 {
                min-width: 50px;
            }
            &-1 { width: 100px;}
            &-2 { width: 150px;}
            &-3 { width: 120px;}

            ::v-deep .v-input__slot {
                height: 30px;
                border: none !important;
            }

            ::v-deep input {
                text-align: center;
            }
        }
        ::v-deep .v-input--is-disabled {
            background-color: #ECEDF1;
            .v-input__slot {
                background-color: inherit;
            }
        }
        ::v-deep .v-input__control {
            height: 30px !important;
        }
        .cell:last-child {
            border-right: none;
        }
        .total {
            background-color: #ECEDF1;
            color: rgba(0, 0, 0, 0.38);
        }
    }
    ::v-deep .disabled-label .v-input__control .v-input__slot {
        background-color: #ECEDF1;
        color: rgba(0, 0, 0, 0.38);
    }
</style>