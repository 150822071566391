<template>
    <fe-remote-combo
        v-bind="this.$attrs"
        :value="value" @input="$emit('input',$event)"
        :label="label"
        rootProperty="schools"
        url='schools.php?action=get'
        lazyLoad
    ></fe-remote-combo>
</template>

<script>
export default {
    name: 'SchoolCombo',
    
    props: {
        value: {},
        label: {type: String, default: 'School'}
    }
    
}
</script>
