<template>
    <div flat class="flex-fill flex-column">
        <v-slide-x-transition mode="out-in">
            <div class="d-flex flex-fill flex-column">
                <div class="d-flex flex-grow-0">
                    <div class="page-title">Manage Score Details</div>

                    <div class="ml-auto">
                        <fe-icon-btn small useIcon="fas fa-times" class="min-btn" @click="$emit('closePanel')" />
                    </div>
                </div>

                <div class="flex-grow-0">
                    <fe-label>Configuration Name</fe-label>
                    <v-text-field
                        ref="name"
                        v-model="localModel.name"
                        flat solo dense validateOnBlur
                        :style="{ width: '100%' }"
                        @change="onBlurName"
                    />
                </div>

                <div class="d-flex">
                    <fe-label>Default Configuration</fe-label>
                    <v-spacer/>
                    <fe-switch v-model="localModel.is_default_datawall" :disabled="!localModel.id" />
                </div>

                <!--<div class="d-flex">-->
                    <!--<fe-label>Public</fe-label>-->
                    <!--<v-spacer/>-->
                    <!--<fe-switch v-model="localModel.public_flag" :disabled="!localModel.id" />-->
                <!--</div>-->

                <fe-label>Assessment Group</fe-label>
                <fe-remote-combo
                    v-model="selectedAssessmentGroup"
                    :disabled="!localModel.id || isBusy"
                    url="dataPointType.php?action=get"
                    flat solo dense
                    :style="{ width: '100%' }"
                />

                <fe-label>Score Detail</fe-label>
                <fe-remote-combo
                    v-model="selectedScoreDetail"
                    ref="scoreDetail"
                    :disabled="!localModel.id || !selectedAssessmentGroup || isBusy"
                    flat solo dense
                    :style="{ width: '100%' }"
                    placeholder="Add Score Details"
                    :items="unusedScoreDetailTypes"
                    itemText="code"
                    itemValue="id"
                    @input="onAddScoreDetailType"
                />

                <div class="flex-grow-1" style="overflow: auto;">
                    <div
                        v-for="(ag, agIndex) in localModel.assessmentGroups"
                        :key="`ag-${ag.id}`"
                    >
                        <fe-label>{{ ag.name }}</fe-label>
                        <div
                            v-for="(sdt, sdtIndex) in getScoreDetailTypesByAssessmentGroup(ag)"
                            :key="`sdt-${sdt.id}`"
                            style="background-color: #ECEDF1; padding: 8px; margin-bottom: 2px;"
                            class="d-flex flex-row selected-item"
                        >
                            <fe-label>{{ sdt.code }}</fe-label>
                            <v-spacer/>
                            <fe-icon-btn small useIcon="fas fa-times" class="min-btn" @click="onRemoveScoreDetailType(sdt)"/>
                        </div>
                    </div>
                </div>

                <br/>
                <div class="d-flex">
                    <v-btn
                        v-if="localModel.id"
                        outlined
                        block
                        color="red"
                        style="border: 1px solid red; width: 100%; margin: 8px 0px 0px 0px !important;"
                        @click="onDeleteConfig"
                    >
                        Delete Configuration
                    </v-btn>
                </div>
            </div>
        </v-slide-x-transition>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'ScoreDetailsConfigPanel',
        components: {
        },
        props: {
            id: { type: Number, default: null },
            showClose: { type: Boolean, default: true },
        },
        computed: {
            ...mapState('global', ['sessionUser', 'currentYear', 'userPreferences']),
            // The score detail types dropdown should not show items that
            // the user has already selected (can't add twice...)
            unusedScoreDetailTypes() {
                let usedIds = this.localModel.scoreDetailTypes.map(itm => itm.score_detail_type_id)
                return this.scoreDetailTypes.filter(itm => !usedIds.includes(itm.id))
            },
        },
        watch: {
            selectedAssessmentGroup(v) {
                this.loadScoreDetailTypes(v.id)
            },
            'localModel.public_flag'(v, prev) {
                if (prev !== null) {
                    this.doUpdate()
                }
            },
            'localModel.is_default_datawall'(v, prev) {
                if (prev !== null) {
                    this.saveDefaultDatawallSetting()
                }
            },
        },
        data() {
            let me = this

            return {
                isBusy: false,
                selectedAssessmentGroup: null,
                selectedScoreDetail: null,

                dataWallConfigs: [],
                scoreDetailTypes: [],

                localModel: {
                    id: null,
                    name: null,
                    is_default_datawall: null,
                    public_flag: null,
                    assessmentGroups: [],
                    scoreDetailTypes: [],
                },
            }
        },
        mounted() {
            this.loadData()
        },
        methods: {
            onBlurName() {
                if (this.localModel.name) {
                    if (!this.localModel.id) {
                        this.doCreate()
                    } else {
                        this.doUpdate()
                    }
                }
            },

            loadAssessments() {
                return this.$axios.get('dataPointType.php?action=get').then(res => {
                    this.assessmentGroups = res.data
                })
            },

            async loadData() {
                await this.loadAssessments()

                if (this.id) {
                    this.localModel.is_default_datawall = (this.userPreferences.DATAWALL_CONFIG?.user_value == this.id) // soft == because prefs come back as STRINGS

                    await this.$axios.get(`dataWallConfig.php?action=get&property=config&id=${this.id}`).then(res => {
                        let rec = res.data.data_wall_configs[0]

                        this.localModel.id = rec.id
                        this.localModel.name = rec.name
                        this.localModel.public_flag = !!rec.public_flag
                    })

                    await this.loadDataWallScoreDetailTypes()
                }
            },

            async loadDataWallScoreDetailTypes() {
                return await this.$axios.get(`dataWallConfig.php?action=get&property=score_detail_types&data_wall_config_id=${this.localModel.id}`).then(res => {
                    let records = res.data.score_detail_types

                    this.localModel.scoreDetailTypes = []
                    for (let rec of records) {
                        let ag = this.localModel.assessmentGroups.find(ag => ag.id == rec.data_point_type_id)
                        if (!ag) {
                            this.localModel.assessmentGroups.push(
                                this.$_.cloneDeep(this.assessmentGroups.find(ag => ag.id == rec.data_point_type_id))
                            )
                        }

                        // There shouldn't be duplicates, but if they are then show them
                        // so that it's obvious to the user, and they have the power to delete one of them
                        this.localModel.scoreDetailTypes.push({
                            id: rec.id,
                            data_wall_config_id: rec.data_wall_config_id,
                            code: rec.score_detail_type_description,
                            score_detail_type_id: rec.score_detail_type_id,
                            data_point_type_id: rec.data_point_type_id
                        })
                    }

                    // If user is editing an existing Datawall config and it's just got
                    // score details from one assessment group, kindly pre-select that one
                    // with the presumption that they want to just add another score detail from it...
                    if (this.selectedAssessmentGroup === null && this.localModel.assessmentGroups.length === 1) {
                        this.selectedAssessmentGroup = this.localModel.assessmentGroups[0]
                    }
                })
            },

            loadScoreDetailTypes(data_point_type_id) {
                this.$axios.get(`scoreDetailTypes.php?action=get&data_point_type_id=${data_point_type_id}`).then(res => {
                    this.scoreDetailTypes = res.data
                })
            },

            loadConfigScoreDetailTypes(id) {
                this.$axios.get(`dataWallConfig.php?action=get&property=score_detail_types&data_wall_config_id=${id}`).then(res => {
                    this.scoreDetailTypes = res.data.score_detail_types
                })
            },

            doCreate() {
                this.$axios.post('dataWallConfig.php?action=create&property=config', {
                    data_wall_configs: [{ name: this.localModel.name, public_flag: this.localModel.public_flag ? 1 : 0 }]
                }).then(res => {
                    if (res.data.success) {
                        this.localModel.id = res.data.data_wall_configs[0].id
                        this.$snackbars.$emit('new', { text: res.data.msg, usage: 'success' })
                        this.$emit('createdDataWallConfig', res.data.data_wall_configs[0])
                        this.$store.commit('global/addPanelObjectEvent', { objectName: 'dataWallConfigCreation', objectModel: this.$_.cloneDeep(res.data.data_wall_configs[0]) })
                    } else {
                        this.$snackbars.$emit('new', { text: res.data.msg, usage: 'warning' })
                    }
                })
            },

            doUpdate() {
                this.$axios.post('dataWallConfig.php?action=update&property=config', {
                    data_wall_configs: [this.localModel]
                }).then(res => {
                    if (res.data.success) {
                        this.$snackbars.$emit('new', { text: res.data.msg, usage: 'success' })
                        this.$store.commit('global/addPanelObjectEvent', { objectName: 'dataWallConfigEdit', objectModel: this.$_.cloneDeep(this.localModel) })
                    } else {
                        this.$snackbars.$emit('new', { text: res.data.msg, usage: 'warning' })
                    }
                })
            },

            async saveDefaultDatawallSetting() {
                await this.$axios.post('preferences.php?action=update', {
                    user_id: this.sessionUser.user.id,
                    DATAWALL_CONFIG: this.localModel.is_default_datawall ? this.localModel.id : null
                })

                this.$store.dispatch('global/loadUserPreferences')
            },

            onDeleteConfig() {
                this.$axios.post(`dataWallConfig.php?action=destroy&property=config`, {
                    data_wall_configs: [this.localModel]
                }).then(res => {
                    if (res.data.msg_type == 'warn') {
                        return
                    } else if (res.data.success) {
                        // If user just deleted their default datawall config, then we
                        // must clear out that user preference before we're done
                        if (this.localModel.is_default_datawall) {
                            this.localModel.is_default_datawall = false
                            this.saveDefaultDatawallSetting()
                        }

                        this.$store.commit('global/addPanelObjectEvent', { objectName: 'dataWallConfigDeletion', objectModel: this.$_.cloneDeep(this.localModel) })
                        this.$emit('closePanel')
                    }
                })
            },

            async onAddScoreDetailType(sdt) {
                if (!sdt || this.isBusy) {
                    return // Clearable remove combo triggers a sdt === null type event
                }

                this.isBusy = true // suppress duplicate adds

                if (!this.localModel.assessmentGroups.find(itm => itm.id == sdt.data_point_type_id)) {
                    this.localModel.assessmentGroups.push(this.selectedAssessmentGroup)
                }

                if (!this.localModel.scoreDetailTypes.find(itm => itm.score_detail_type_id == sdt.id)) {
                    await this.saveScoreDetailType({
                        ...sdt,
                        data_wall_config_id: this.localModel.id,
                        id: null,
                        score_detail_type_id: sdt.id
                    })

                    await this.loadDataWallScoreDetailTypes() // Refresh list with models that have the id so they can be deleted
                }

                this.selectedScoreDetail = null
                this.isBusy = false
            },

            async saveScoreDetailType(obj) {
                return await this.$axios.post('dataWallConfig.php?action=create&property=score_detail_types', {
                    score_detail_types: [obj]
                })
            },

            async onRemoveScoreDetailType(sdt) {
                await this.destroyScoreDetailType(sdt).then(res => {
                    if (res?.data?.canceled) {
                        return null
                    }

                    let idx = this.localModel.scoreDetailTypes.findIndex(itm => itm.id == sdt.id)
                    if (idx >= 0) {
                        this.localModel.scoreDetailTypes.splice(idx, 1)

                        // If this was the last/only score detail type from a
                        // given assessment group, then we should remove the group from local tracking
                        if (this.localModel.scoreDetailTypes.filter(itm => itm.data_point_type_id === sdt.data_point_type_id).length === 0) {
                            this.localModel.assessmentGroups = this.localModel.assessmentGroups.filter(itm => itm.id !== sdt.data_point_type_id)
                        }
                    }
                }).catch(err => this.$snackbars.$emit('new', { text: err.message, usage: 'error' }))
            },

            async destroyScoreDetailType(obj) {
                return await this.$axios.post(`dataWallConfig.php?action=destroy&property=score_detail_types`, {
                    score_detail_types: [obj]
                }).then(res => {
                    if (res.data?.success === false && res.data?.msg_type !== 'warn') {
                        throw new Error(res.data?.msg || 'Server error occurred when attempting to delete this item.')
                    }

                    return res
                })
            },

            getScoreDetailTypesByAssessmentGroup(ag) {
                return this.localModel.scoreDetailTypes.filter(itm => itm.data_point_type_id == ag.id)
            },
        }
    }
</script>

<style lang="scss" scoped>
.min-btn {
    ::v-deep .v-btn {
        margin: 0px 0px 0px 0px !important;
        padding: 0px 0px 0px 0px !important;
    }
}

.selected-item {
    border-radius: 3px;
}
</style>
