let standardCrud = {
    read: {
        params: {}
    },
    create: {
        params: {}
    },
    update: {
        params: {}
    },
    destroy: {
        params: {}
    }
}

export default {
    enrollmentSummary: {
        defaults: {
            endpoint: 'api/core/enrollment/summary',
            rootProperty: 'summary'
        },
        ...standardCrud
    },

    enrollmentCounts: {
        defaults: {
            endpoint: 'api/core/enrollment/count',
            rootProperty: 'enrollments'
        },
        ...standardCrud
    },

    enrollmentTrends: {
        defaults: {
            endpoint: 'api/core/enrollment/trend',
            rootProperty: 'enrollment_trend'
        },
        ...standardCrud
    }
}
