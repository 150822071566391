<template>
    <fe-dialog
        title="Change PIN"
        width=500
        v-if="localValue"
        @dismiss="handleClose"
        disableAutoclose
        @close="handleClose"
        @accept="updatePIN"
        :acceptButtonText="saving?'Saving. Please wait...':'Save'"
        :acceptButtonDisabled="!valid||saving"
    >
        <v-form :disabled="saving" v-model="valid" ref="form" class="d-flex flex-column flex-fill">
        <fe-label>New PIN</fe-label>
            <v-text-field
                type="password"
                autocomplete="new-password"
                v-model="pinOne"
                required
                @keypress="numberRestrict"
                :rules="rules.pin"
                @blur="$refs.form.validate()"
                flat solo dense
            />

            <fe-label>Confirm New PIN</fe-label>
            <v-text-field
                type="password"
                autocomplete="new-password"
                v-model="pinTwo"
                @keypress="numberRestrict"
                :rules="rules.pin"
                required
                @blur="$refs.form.validate()"
                flat solo dense
            />

            <div style="color: red; font-size: 12px;" v-if="pinOne != pinTwo">PINs do not match</div>
        </v-form>
    </fe-dialog>
</template>

<script>
    export default {
        name: 'MobilePin',
        props: ['value'],
        watch: {
            value(v) {
                this.localValue = v
            },
            localValue(v) {
                this.$emit('input', v)
            }
        },
        mounted() {
            this.$data.localValue = this.$props.value
        },
        data() {
            return {
                saving: false,
                curpswd: '',
                pinOne: '',
                pinTwo: '',
                valid: false,
                localValue: null,
                rules: {
                    currentPassword: [
                        v => !!v || 'Required.',
                    ],
                    pin: [
                        // minimum length
                        v => v.length >= this.$store.state.global.shareableStores.pinml || ("PIN must be at least " + this.$store.state.global.shareableStores.pinml + " digits"),
                        // must contain only digits
                        v => /^[0-9]{1,100}$/.test(v) || ("PIN must contain only digits"),
                        // must be the same - error message is displayed separately
                        v => this.pinOne == this.pinTwo,
                    ],
                }
            }
        },
        methods: {
            numberRestrict(e) {
                let x=e.which||e.keycode;
                if(x>=48 && x<=57) {
                    return true;
                } else {
                    e.preventDefault();
                }
            },
            updatePIN() {
                let me = this
                let params = new URLSearchParams()
                params.append('curpswd', this.curpswd)
                params.append('pin1', this.pinOne)
                params.append('pin2', this.pinTwo)
                this.saving = true
                this.$axios.post('users.php?action=update&property=self', params)
                .then((response) => {
                    let data = this.$ecResponse(response)
                    if (!data.success) {
                        return
                    }
                    this.handleClose()
                })
                .catch((error) => console.warn(error))
                .finally(() => this.saving = false)
            },
            handleClose() {
                this.curpswd = ''
                this.pinOne = ''
                this.pinTwo = ''
                this.localValue = false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
