import { render, staticRenderFns } from "./LogisticsModal.vue?vue&type=template&id=7baf7729&scoped=true"
import script from "./LogisticsModal.vue?vue&type=script&lang=js"
export * from "./LogisticsModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7baf7729",
  null
  
)

export default component.exports