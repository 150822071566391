<template lang="html">
    <div class="flex-fill flex-column">
        <v-toolbar flat :max-height="64">
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>GridWithPageScroll</v-toolbar-title>
        </v-toolbar>
        <div class="flex-fill">
            <!-- <fe-collapse-Panel style="overflow:auto;"></fe-collapse-Panel> -->
            <div class="flex-fill flex-column pa-4">

                <div>
                    grid with 500px min-height and page scroll when needed.  toggle autoheight for just page scroll<br />
                    <fe-btn @click="count++" >add top space</fe-btn>
                    <fe-btn @click="count=0" >clear</fe-btn>
                    <fe-btn @click="autoHeight = !autoHeight">
                        Change to {{autoHeight ? '100% Height' : 'AutoHeight'}}
                    </fe-btn>
                    <fe-btn @click="rowData = []" >no rows</fe-btn>
                    <fe-btn @click="$refs.crud.read()" >load rows</fe-btn>
                    <div v-for="(x,i) in count" >{{i}} {{someText}}</div>
                </div>

                <fe-overlay
                    :value="!rowData || !rowData.length"
                    image="/images/empty-state-horn.png"
                    text="No recently viewed smartFORMS"
                >
                    <!-- had to use show here... if else would not initialize the grid with scrollbars after first -->
                    <fe-grid v-show="autoHeight"
                        domLayout="autoHeight"
                        :columnDefs="columnDefs"
                        :rowData="rowData"
                        :showAddRowBtn="false"
                    />
                    <!-- :emptyState="{
                        text: 'Void',
                        imageSrc: '/images/empty-state-horn.png'
                    }" -->

                    <fe-grid v-show="!autoHeight"
                        style="height:100%;"
                        :columnDefs="columnDefs"
                        :rowData="rowData"
                        :showAddRowBtn="false"
                    />
                    <!-- :emptyState="{
                        text: 'Void',
                        imageSrc: '/images/empty-state-horn.png',
                        imageStyle: 'max-width:50%; max-height:50%;',
                    }" -->
            </fe-overlay>

                <fe-crud
                    ref="crud"
                    autoload
                    :config="$models.assessment"
                    @read="rowData = $event"
                />

            </div>
        </div>
    </div>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'

export default {
    name: 'GridWithPageScroll',
    inject: ['localStore'],
    computed: {
        ...mapLocalState('someText'),
    },

    data() {
        return {
            count: 0,
            rowData: [],
            autoHeight: false,
            columnDefs: [
                { headerName: 'id', field: 'id', sortable: true, width: 50 },
                { headerName: 'Name', field: 'name', sortable: true, width: 150 },
                { headerName: 'Description', field: 'description', sortable: true },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    height: 100%;
}
</style>
