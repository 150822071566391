<template>
    <div class="d-flex flex-fill flex-column">
        <search-bar
            v-if="!searchParams"
            ref="searchBar"
            v-model="params"
            :scope="scope"
            :key="`scope-${scope}`"
            @search="doSearch"
        />

        <v-layout
            v-if="!categories || categories.length === 0"
            align-center
            justify-center
            column
            fill-height
            text-center
        >
            <img width="340px" src="/images/rocket.png"/>

            <div class="headline">
                {{ scope }} Search
            </div>

            <div v-if="scope.toLowerCase() !== 'class'" class="subheading">
                To get started, begin by selecting the school year and grade. <br/>
                You can narrow your search by school or assessment information as well.
            </div>

            <div v-else class="subheading">
                To get started, begin by selecting the User and Course Name. <br/>
                You can narrow your search by period or assessment as well as select more than one year of assessments
                for this cohort of students.
            </div>
        </v-layout>

        <fe-left-menu-layout
            v-else
            v-model="activeCategory"
            bodyPadding="0px"
            :menuItems="menu"
            full-width-title
        >
            <template #title>
                <div v-if="activeCategory" class="d-flex report-titlebar">
                    <div class="d-flex">
                        {{ activeCategory.name }}
                        <fe-tooltip
                            tooltip="There are some incident types that have been restricted from your visibility by your district."
                        >
                            <v-icon v-if="activeCategory.id === 'incidents' && hasRestrictedIncidentTypes" class="ml-2">
                                far fa-exclamation-circle
                            </v-icon>
                        </fe-tooltip>

                        <fe-tooltip
                            min-width="400"
                                    tooltip="To enable multi-column sorting, hold down Command + Shift on Mac or CTRL on Windows while clicking on the subsequent column header(s) of your choice."
                        >
                            <v-icon v-if="activeToggle.name !== 'Charts'" class="ml-2" color="#050F2D">
                                far fa-info-circle
                            </v-icon>
                        </fe-tooltip>
                    </div>

                    <v-spacer/>

                    <div class="report-tools">
                        <toggle-button
                            v-if="scope.toLowerCase() !== 'district' && activeCategory && activeCategory.id !== 'incidents' && activeCategory.id !== 'attendance'"
                            class="mr-4 fix-font"
                            :items="toggleItems" v-model="activeToggle"
                        />
                    </div>
                </div>
            </template>

            <div
                v-if="activeCategory && activeCategory.id === 'incidents'"
                class="d-flex flex-fill flex-column"
                style="width: 100%;"
            >
                <big-five :params="attendanceParams" includeDataWall/>
            </div>

            <div v-else-if="activeCategory && activeCategory.id==='attendance'" class="d-flex flex-fill flex-column">
                <attendance-reporting
                    :editSearchBtn="false"
                    :params="attendanceParams"
                />
            </div>

            <div
                v-else
                class="d-flex flex-fill flex-column pb-6"
                style="background: #f6f6f6; height: calc(100% - 24px);" ref="assessmentContainer"
                v-resize="resize"
            >
                <v-row
                    v-if="activeCategory && activeToggle.name=='Charts'"
                    style="overflow: scroll; padding-bottom: 50px;"
                    class="d-flex flex-wrap pb-5 align-start"
                >
                    <v-col
                        v-for="(cfg,i) in activeCategory.charts"
                        class="pa-0 flex-grow-1"
                        :key="`chart-`+i"
                        :cols="cols"
                    >
                        <v-lazy>
                            <benchmark-chart
                                ref="benchmarkRef"
                                class="ma-1"
                                type='basic'
                                :key="uuid"
                                :config='cfg'
                                :overallRecord="cfg"
                                :performanceBands="performanceBands"
                                :params="activeCategory.params"
                                :scope="scope"
                                :advancedFilters="advancedFilters"
                                @bandNotFound="fetchPerformanceBands(true)"
                            />
                        </v-lazy>
                    </v-col>
                </v-row>

                <div v-else class="d-flex flex-fill flex-column">
                    <data-wall
                        v-if="activeCategory"
                        :key="`datawall-container-` + activeCategory.id"
                        :params="activeCategory.params"
                        :scope="scope"
                        :categories="[]"
                        :sloCapable="enableSlo"
                        :sloDisabledMessage="sloDisabledMessage"
                    />
                </div>
            </div>
        </fe-left-menu-layout>
    </div>
</template>

<script>
import SearchBar from './SearchBar'
import BenchmarkChart from '@/components/charts/BenchmarkChart'
import ToggleButton from '@/components/common/button/ToggleButton'
import DataWall from '@/components/modules/datawall/Index'
import BigFive from '@/components/modules/incidents/BigFive'
import AttendanceReporting from '@/components/modules/attendanceReporting'
import {mapState} from 'vuex'

export default {
    name: 'Charting',

    components: {
        SearchBar,
        BenchmarkChart,
        ToggleButton,
        DataWall,
        BigFive,
        AttendanceReporting
    },

    props: {
        scope: {
            type: String,
            default: 'District'
        },
        searchParams: {
            default: null
        },
        searchAttendanceParams: {
            default: null
        },
        searchIncidentParams: {
            default: null
        },
        noAssessmentData: {
            type: Boolean,
            default: false
        },
        noAttendanceData: {
            type: Boolean,
            default: false
        },
        noIncidentData: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            menu: [],
            categories: [],
            params: {},
            activeCategory: null,
            activeToggle: null,
            toggleItems: [{
                name: 'Charts',
                icon: 'fas fa-chart-bar'
            }, {
                name: 'Data Wall',
                icon: 'fas fa-table'
            }],
            cols: 3,
            performanceBands: [],
            isFetchingPerformanceBands: false,
            advancedFilters: [],
            uuid: `chart-${Math.random()}`
        }
    },

    computed: {
        ...mapState('global', ['sessionUser']),

        enableSlo() {
            // launchDarkly feature flag check
            if (this.$store.getters['flags/flags']['ec-slo'] !== true) {
                return false
            }

            return !(this.scope !== 'Grade' && this.scope !== 'Class');
        },

        sloDisabledMessage() {
            if (this.params.school_year_id?.included?.length > 1) {
                return 'SLO cannot be created in multi-year searches'
            }
        },

        attendanceParams() {
            let p = {...this.activeCategory.params}
            if (this.scope.toLowerCase() === 'district') {
                if (p.cohort_school_year_id) p.school_year_id = p.cohort_school_year_id
            }
            return p
        },

        hasRestrictedIncidentTypes() {
            return (!!this.sessionUser.user?.has_restricted_incident_types)
        }
    },

    watch: {
        activeCategory(v) {
            if (['incidents', 'attendance'].indexOf(v.id) === -1) {
                this.loadCategory(v)
            }
        },

        searchParams: {
            handler(v) {
                if (v) this.doSearch()
            },
            immediate: true
        }
    },

    created() {
        this.resize()
        this.activeToggle = this.toggleItems[0]

        this.fetchPerformanceBands()
    },

    methods: {
        async fetchPerformanceBands(isResync = false) {
            // If this is to resync missing PBs, then there might be a ton of
            // charts with the same problem (e.g. 29 charts on district search).
            // As long as one of their emits gets processed, we should hope/expect
            // all of them will be brought up-to-date.
            if (isResync && this.isFetchingPerformanceBands) {
                return
            } else if (isResync) {
                console.warn('Performance Band not found, possibly out of sync; requesting new bands data...')
            }

            this.isFetchingPerformanceBands = true
            this.performanceBands = await this.$modelGet('userTargetSet')
            this.isFetchingPerformanceBands = false
        },

        resize() {
            let w = window.innerWidth
            let col = 6

            if (w <= 900) {
                col = 12
            } else if (w <= 1800) {
                col = 6
            } else {
                col = 4
            }
            this.cols = col

            if (this.$refs.benchmarkRef) {
                this.$refs.benchmarkRef.forEach(chart => {
                    chart.disableTooltip()
                })
            }
        },

        loadOtherData(p) {
            if (!this.noIncidentData && (this.searchIncidentParams || (!p.category_id || p.category_id.indexOf('incidents') !== -1 || !p.category_id.length))) {
                this.menu.push({
                    id: 'incidents',
                    name: 'Incidents',
                    isLoaded: false,
                    showDataWall: false,
                    params: this.searchIncidentParams ? {...this.searchIncidentParams} : {...p},
                    charts: [],
                })

                this.categories.push({
                    id: 'incidents',
                    name: 'Incidents',
                })
            }

            if (!this.noAttendanceData && (this.searchAttendanceParams || (!p.category_id || p.category_id.indexOf('attendance') !== -1 || !p.category_id.length))) {
                this.menu.push({
                    id: 'attendance',
                    name: 'Attendance',
                    isLoaded: false,
                    showDataWall: false,
                    params: this.searchAttendanceParams ? {...this.searchAttendanceParams} : {...p},
                    charts: [],
                })

                this.categories.push({
                    id: 'attendance',
                    name: 'Attendance',
                })
            }
        },

        doSearch() {
            let p = this.searchParams || this.$refs.searchBar.getSearchObject()

            this.categories = []
            if (this.noAssessmentData) {
                this.loadOtherData(p)
                if (this.menu.length) {
                    this.activeCategory = this.menu[0]
                }
                return
            }

            this.$setLoading(true)
            this.$axios.get('targetScores.php?action=get&property=categories&' + this.$objectToParams(p))
                .then(response => {
                    this.$setLoading(false)
                    this.categories = this.$ecResponse(response, 'categories')

                    this.menu = this.categories.map(c => {
                        return {
                            id: c.category_id,
                            name: c.category_name,
                            isLoaded: false,
                            showDataWall: false,
                            params: {...p, ...{category_id: c.category_id}},
                            charts: [],
                        }
                    })
                }).finally(() => {
                this.loadOtherData(p)
                if (this.menu.length) {
                    this.activeCategory = this.menu[0]
                }
            })
        },

        loadCategory(category) {
            // resets the benchmark chart key so it reloads properly
            // when switching categories in the left menu
            this.uuid = `chart-${Math.random()}`

            if (category.isLoaded) return

            this.advancedFilters = []
            let advanced = this.$refs.searchBar ? this.$refs.searchBar.getAdvancedDems : []

            this.$setLoading(true)
            this.$axios.get('targetScores.php?action=get&' + this.$objectToParams(category.params))
                .then(response => {
                    category.isLoaded = true

                    if (!response.data.success && !response.data.categories) {
                        this.$errorPrompt({
                            title: 'Please refine your search',
                            message: response.data.msg
                        })
                    } else {
                        let categories = this.$ecResponse(response, 'categories')

                        if (categories.length) {
                            category.charts = categories[0].stacked_charts
                        }
                        if (advanced.length) {
                            this.advancedFilters = advanced
                        }
                    }
                })
                .finally(() => {
                    this.$setLoading(false)
                })
        }
    },
}
</script>

<style lang="scss" scoped>
.report-titlebar {
    background: #fff;

    .report-tools {
        position: absolute;
        right: 10px;

        .fix-font {
            font-size: 14px !important;
            font-weight: normal !important;
        }
    }
}

::v-deep .left-menu-container {
    width: 225px !important;
    border-right: 1px solid #E0E1E6;

    .nav-item {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .nav-item:hover {
        white-space: normal;
        word-break: break-word;
        overflow: visible;
        height: 100%;
    }

    .nav-container-items {
        padding-left: 16px !important
    }

    .nav-container {
        width: 224px !important;
    }
}
</style>
