<template>
    <div>
        <div class="theme--light v-label" style="font-weight:normal;">{{label}}</div>
        <v-component :is="field.component" :record="field" />
    </div>
</template>

<script>
import StudentCombo from './StudentCombo'
import SchoolCombo from './SchoolCombo'
import UserCombo from './UserCombo'

export default {
    name: 'CustomField',
    
    props: {
        field: {required:true}
    },
    
    computed: {
        label() {
            let required = this.field.required ? `:rules="[v => !!v || 'Field is required']"` : ''
            return (this.field.label || this.field.name) + (required ? ' *' : '')
        }
    },
    
    beforeMount() {
        // currently the php is not setting multi_select correctly for behavior combo boxes or response combo boxes
        if(this.field.incident_field_type_xtype == 'combobox') {
            if(this.field.incident_response_id > 0) {
                // force no for responses
                this.field.multi_select = 0
            } else {
                // force yes for behaviors
                this.field.multi_select = 1
            }
        }
        
        let component = {
            name: 'custom'+Date.now(),
            props: {
                record: {required:true}
            },
            computed: {
                options() {
                    return this.record.options || this.record.incident_field_options || this.record.incident_response_field_options
                },
                response: {
                    set(v) {
                        this.record.value = v
                    },
                    get() {
                        let r = (typeof this.record.response === 'undefined') ? this.record.value : this.record.response
                        if(Array.isArray(r) && !r.length) {
                            r = null
                        } else if(!this.record.multi_select && Array.isArray(r) && r.length==1) {
                        // multi_select is present as a property but it seems we always assume it's true
                            r = r[0]
                        }
                        
                        if(this.record.valueType == Number) {
                            r = Array.isArray(r) ? r.map( x => Number(x) ) : Number(r)
                        }
                        
                        this.record.value = r
                        return r
                    }
                }
            }
        }

        let required = this.field.required ? `:rules="[v => !!v || 'Field is required']"` : ''
        let model = `v-model="response"`
        
        let defaults = `label="" ${required} solo flat dense class="customfield"`

        switch(this.field.incident_field_type_xtype) {
            case "studentcombo":
                component.components = { StudentCombo }
                component.template = `<student-combo ${model} ${defaults} />`
                this.field.valueType = Number
                break
            case "usercombo":
                component.components = { UserCombo }
                component.template = `<user-combo byId ${model} ${defaults} active />`
                this.field.valueType = Number
                break
            case "datefield":
                component.template = `<fe-date-picker ${model} ${defaults} />`
                break
            case "combobox":
                let items = ':items="options" item-value="id" item-text="display_value" '
                let multiple = this.field.multi_select ? 'multiple' : ''
                component.template = `<v-select :returnObject="false" ${model} ${defaults} ${items} ${multiple} />`
                this.field.valueType = Number
                break
            case "schoolcombo":
                component.components = { SchoolCombo }
                component.template = `<school-combo byId ${model} ${defaults} />`
                this.field.valueType = Number
                break
            case "textfield":
                component.template = `<v-text-field ${model} ${defaults} :counter="1500" :maxlength="1500" />`
                break
            case "numberfield":
                component.template = `<v-text-field ${model} ${defaults} type="number" />`
                this.field.valueType = Number
                break
            case "textarea":
                component.template = `<v-textarea ${model} ${defaults} :counter="2000" :maxlength="2000" />`
                break
            default:
                component = null
        }
        this.field.component = component
    }
}
</script>

<style>
</style>
