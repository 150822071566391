<template>
    <fe-dialog
        v-if="ready"
        title="Public Image"
        :width="dialog.width"
        dismissable
        dismissButtonText="Cancel"
        acceptButtonText="Done"
        @accept="$emit('close')"
        @dismiss="$emit('close')"
    >
        <div class="flex-column">
            <div ref="imageGroup" class="pi-wrapper pb-2 mb-3 flex-grow-1">
                <visualization
                    class="pi-vis"
                    :config="localConfig"
                    :dashboard="dashboard"
                    :getSSValues="getSavedSearchValues"
                    publicImage
                />

                <div
                    v-if="include.description"
                    class="pi-description"
                    :class="{'divider': include.goalDescription}"
                >
                    {{ localConfig.description }}
                </div>

                <div v-if="include.goalDescription" class="pi-description">
                    <b>Goal description:</b> {{ localConfig.goal.description }}
                </div>

                <div class="watermark">As of: {{ today }}</div>
            </div>

            <v-text-field
                v-if="ready"
                v-model="image"
                class="link-field"
                ref="linkInput"
                placeholder='Click "Create" to create a public image'
                flat
                solo
                dense
                readonly
                hide-details
            >
                <template v-slot:append>
                    <div v-if="image" class="btn-group d-flex">
                        <fe-tooltip tooltip="Click to delete public image" direction="top">
                            <v-btn @click="deleteImage" class="ma-0 px-3" outlined>
                                <v-icon>fal fa-trash</v-icon>
                            </v-btn>
                        </fe-tooltip>

                        <fe-tooltip :tooltip="refreshTooltip" direction="top" :key="refreshTooltip">
                            <v-btn @click="createImage" class="ma-0 px-3" outlined>
                                <v-icon>fal fa-sync-alt {{ updating ? 'fa-spin' : '' }}</v-icon>
                            </v-btn>
                        </fe-tooltip>

                        <fe-tooltip :tooltip="copyTooltip" direction="top" :key="copyTooltip">
                            <v-btn @click="copyLink" class="ma-0 px-3" outlined>
                                <v-icon>fal fa-copy</v-icon>
                            </v-btn>
                        </fe-tooltip>
                    </div>
                    <div v-else class="btn-group d-flex">
                        <v-btn class="ma-0" color="#E0E1E6" :disabled="updating" depressed @click="createImage">
                            <span v-if="!updating">Create</span>
                            <v-icon v-else>fas fa-spin fa-spinner</v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-text-field>

            <div class="d-flex mt-2">
                <v-checkbox
                    v-if="!this.$_.isEmpty(config.description)"
                    v-model="include.description"
                    label="Include description"
                    hide-details
                />
                <v-checkbox
                    v-if="!this.$_.isEmpty(config.goal)"
                    v-model="include.goal"
                    label="Include goal"
                    hide-details
                />
                <v-checkbox
                    v-if="!this.$_.isEmpty(localConfig.goal) && !this.$_.isEmpty(localConfig.goal.description)"
                    v-model="include.goalDescription"
                    label="Include goal description"
                    hide-details
                />
            </div>
        </div>
    </fe-dialog>
</template>

<script>
import Visualization from "../Visualization"
import domToImage from 'dom-to-image'

export default {
    name: "PublicImage",

    components: {
        Visualization
    },

    props: {
        config: {
            type: Object,
            required: true
        },
        rect: {
            required: true
        },
        dashboard: {
            type: Object,
            required: true
        },
        getSavedSearchValues: {
            type: Function,
            required: true
        }
    },

    data() {
        return {
            ready: false,
            copied: false,
            updating: false,
            image: undefined,
            dialog: {
                height: 600,
                width: 900
            },
            include: {
                description: true,
                goal: true,
                goalDescription: false
            }
        }
    },

    computed: {
        today() {
            let date = new Date();
            return date.toLocaleDateString()
        },

        copyTooltip() {
            return this.copied ? 'Copied' : 'Click to copy public image link'
        },

        refreshTooltip() {
            return this.updating ? 'Refreshing' : 'Click to refresh data'
        },

        localConfig() {
            let cfg = this.reformatPublicLinkConfig(this.$_.cloneDeep(this.config))
            if (!this.include.goal) {
                delete cfg.goal
            }
            return cfg
        }
    },

    watch: {
        'include.goal' (v) {
            if (!v) {
                this.include.goalDescription = false
            }
        }
    },

    mounted() {
        this.dialog.width = this.rect.width + 48

        if (this.localConfig.hasOwnProperty("publicLink")) {
            this.include = this.$_.clone(this.localConfig.publicLink.include)
            this.image = this.localConfig.publicLink.link
        }

        this.$nextTick(() => {
            this.ready = true
        })
    },

    methods: {
        createImage() {
            this.updating = true

            let node = this.$refs.imageGroup
            domToImage.toBlob(node)
                .then(blob => {
                    this.createUpdateLink(blob)
                })
                .catch(error => {
                    console.error(error)
                })
        },

        createUpdateLink(blob) {
            let data = new FormData()
            let fileName = ''

            let existingLink = this.localConfig?.publicLink?.link
            if (existingLink) {
                fileName = /\/([a-z0-9]{7,50}\.png)$/.exec(existingLink)[1]
            } else {
                fileName = 'new' // server will generate
            }

            data.append('file', blob, fileName)

            this.$axios.post('s3.php?action=put', data)
                .then(res => {
                    if (res.data.ids && res.data.ids.length) {
                        let image = res.data.ids
                        this.image = image
                        let linkJSON = {
                            link: image,
                            include: this.$_.clone(this.include)
                        }
                        this.updateConfig(linkJSON)
                    } else if (!res.data.success) {
                        this.updating = false
                        this.$snackbars.$emit('new', {
                            text: res.data.msg,
                            usage: 'error'
                        })
                        console.error(res.data)
                    }
                })
        },

        deleteImage() {
            let msg = 'You are attempting to delete a public image. Once deleted, the image will no longer display in any external locations where the link is utilized. This action cannot be undone. Are you sure you want to delete this public image? '

            this.$confirmDelete(
                null,
                () => {
                    let fileName = /\/([a-z0-9]{7,50}\.png)$/.exec(this.image)[1]
                    let data = new FormData()
                    data.append('file', fileName)
                    this.$axios.post('s3.php?action=delete', data).then(response => {
                        if (response.data.success) {
                            this.updateConfig('delete')
                        } else if (!response.data.success) {
                            this.$snackbars.$emit('new', {
                                text: response.data.msg,
                                usage: 'error'
                            })
                        }
                    })
                },
                null,
                msg
            )

            /** Had to add in this hack because the Cancel button was getting focused **/
            setTimeout(() => {
                document.activeElement.blur()
            }, 10)
        },

        updateConfig(link) {
            let cloneConfig = Object.assign({}, this.config)
            cloneConfig.config.publicLink = null
            if (link !== 'delete') {
                cloneConfig.publicLink = link
            } else {
                cloneConfig.publicLink = null
            }
            this.$emit('update', cloneConfig)
            this.updating = false
            if (link === 'delete') {
                this.$emit('close')
            }
        },

        copyLink() {
            let $el = this.$refs.linkInput.$refs.input
            navigator.clipboard.writeText($el.value)
                .then(() => {
                    $el.blur()
                    window.getSelection().removeAllRanges()
                    this.copied = true
                    setTimeout(() => {
                        this.copied = false
                    }, 2000)
                })
                .catch(err => {
                    console.error(err)
                    $el.blur()
                    window.getSelection().removeAllRanges()
                })
        },

        reformatPublicLinkConfig(cfg) {
            /** Was getting null values that messed things up **/
            if (this.$_.isEmpty(cfg.publicLink)) delete cfg.publicLink

            if (cfg.publicLink && typeof cfg.publicLink === 'string') {
                let x = cfg.publicLink
                cfg.publicLink = {
                    link: x,
                    include: {
                        description: !this.$_.isEmpty(this.config.description),
                        goal: false,
                        goalDescription: false
                    }
                }
            }

            return cfg
        }
    }
}
</script>

<style lang="scss" scoped>
.pi-wrapper {
    padding: 4px;
    border-radius: 8px;
    border: 1px dashed #d0d0d0;
    background-color: #FFFFFF;
    position: relative;

    .pi-vis {
        width: 100%;
    }

    .watermark {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 12px;
        background: rgb(236, 237, 241, .5);
        padding: 8px 12px;
        color: #000000;
        border-radius: 5px;
    }

    .pi-description {
        margin: 8px;

        &.divider {
            border-bottom: 1px solid #E0E1E6;
            padding-bottom: 8px;
        }
    }
}

.link-field {
    ::v-deep .v-input__slot {
        border-color: #E0E1E6;
    }

    .btn-group {
        height: 34px;
        margin-right: -8px;

        ::v-deep .v-btn--outlined {
            min-width: unset;
        }

        ::v-deep .v-btn {
            border: none;
            height: 34px;
            border-radius: 0;
        }
    }
}
</style>
