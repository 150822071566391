<template>
<div>
    <button @click="dialog=true" >{{displayName}}</button>
    <v-dialog v-model="dialog" width="600">
        <fe-stepper-filter
            :title="title" style="height:600px;"
            @apply="emit" @close="dialog=false"
            :applyDisabled="!formValid || !enableFinalPanel"
        >

            <fe-stepper-checkbox-panel v-model="tags" :items="tagItems" width="50%">

                <template v-slot:label="{item}">
                    <v-icon :color="item.color" class="mx-2">fa-tag</v-icon>
                    {{item.name}}
                </template>

            </fe-stepper-checkbox-panel>
            <v-divider vertical style="background-color:#E0E1E6"/>

            <fe-stepper-panel width="50%" style="padding:10px;">
                <v-form v-if="enableFinalPanel" lazy-validation v-model="formValid">
                    <FeDatePicker label="Tagged Before" v-model="tagged_as_of_date" />
                    <fe-remote-combo
                        label="School Year"
                        v-model="school_year"
                        rootProperty="years"
                        url="qry.php?action=get&property=school_years"
                    ></fe-remote-combo>
                    <v-layout row>
                        <v-flex class="pr-2">
                            <v-switch v-model="restrict" label="Restrict Results" hide-details />
                        </v-flex>
                        <v-flex>
                            <v-switch v-model="require_all" label="Require All" hide-details />
                        </v-flex>
                    </v-layout>
                </v-form>
            </fe-stepper-panel>

            <template #footer>
                <div class="text-truncate" style="padding:14px">{{breadcrumb}}</div>
            </template>
        </fe-stepper-filter>
    </v-dialog>
</div>
</template>

<script>
import * as Util from '../../Util'

export default {
    name: 'TagPanel',
    rank: 1,

    watch: {
        dialog(v) {
            if(!v) this.oldFilter = null
            v ? this.loadTags() : this.clear()
        },
    },

    computed: {
        title() {
            return this.oldFilter ? `Edit ${this.displayName} Filter` : `Create ${this.displayName} Filter`
        },
        enableFinalPanel() {
            return !this.$_.isEmpty(this.tags)
        },
        breadcrumb() {
            return this.tags.map(x=>x.name)
                .filter(Boolean)
                .join(', ')
        },
    },

    methods: {
        clear() {
            this.restrict = true
            this.tags = []
            this.tagged_as_of_date = null
            this.require_all = false
            this.school_year = null
        },
        load(oldFilter) {
            this.oldFilter = oldFilter
            this.dialog = true
            let ep = oldFilter.editorParams

            this.restrict = ep.restrict
            this.tags = ep.tags
            this.tagged_as_of_date = ep.tagged_as_of_date
            this.require_all = ep.require_all
            this.school_year = ep.school_year
        },
        emit() {
            this.dialog = false
            let uniqueId = Util.UUIDv4()
            let name = this.tags.map(x => x.name).join(', ')
            let filter = {
                type: this.type,
                id: uniqueId,
                restrict: this.restrict,
                report: true,
                params: this.$_.pickBy({
                    tag_id: this.tags.map(x => x.id),
                    tagged_as_of_date: this.tagged_as_of_date,
                    require_all: this.require_all,
                    school_year_id: this.school_year?.id,
                }, x => !this.$_.isBlank(x)),
            }
            Object.defineProperties(filter, {
                editor: {value: this},
                editorParams: {value: {
                    restrict: this.restrict,
                    tags: this.tags ? [...this.tags] : null,
                    tagged_as_of_date: this.tagged_as_of_date,
                    require_all: this.require_all,
                    school_year: this.school_year ? {...this.school_year} : null,
                }},
                grouper: {value: {
                    id: uniqueId,
                    name: name,
                    type: 'propertyArray',
                    args: {path: `filters.${uniqueId}`, property: 'value'},
                }},
                chipText: {value: name},
                tooltipData: {value: this.$_.pickBy({
                    'Tags': name,
                    'Tagged Before': this.tagged_as_of_date,
                    'Restrict': this.restrict,
                    'Require All': this.require_all,
                    'School Year': this.school_year?.name,
                }, x => !this.$_.isBlank(x))}
            })
            this.$emit('input', filter, this.oldFilter)
        },
        loadTags() {
            this.$axios({
                url: 'tags.php?action=get&active=1',
            }).then((response) => {
                if (response && response.data) {
                    this.tagItems = response.data.tags
                }
            })
        }
    },

    data() {
        return {
            formValid: true,
            oldFilter: null,
            displayName: 'Tag',
            type: 'tag',
            dialog: false,
            tagItems: [],

            restrict: true,
            tags: [],
            tagged_as_of_date: null,
            require_all: false,
            school_year: null,
        }
    }
}
</script>

<style lang="scss" scoped>
// {
//     "id": 4,
//     "type": "tag",
//     "restrict": true,
//     "report": true,
//     "params": {
//         "tag_id": [
//             79
//         ],
//         "tagged_as_of_date": "2019-10-01",
//         "require_all": false
//     }
// }
//         {
//             "id": 10,
//             "type": "tag",
//             "restrict": true,
//             "report": true,
//             "params": {
//                 "tag_id": [71,72]
//             }
//         },
//         {
//             "id": 11,
//             "type": "tag",
//             "restrict": false,
//             "report": true,
//             "params": {
//                 "tag_id": [71,72],
//                 "require_all": true
//             }
//         },
//         {
//             "id": 12,
//             "type": "tag",
//             "restrict": false,
//             "report": true,
//             "params": {
//                 "tag_id": [71],
//                 "require_all": false
//             }
//         },
//         {
//             "id": 13,
//             "type": "tag",
//             "restrict": false,
//             "report": true,
//             "params": {
//                 "tag_id": [71,72],
//                 "require_all": true
//             }
//         },
</style>
