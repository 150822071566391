<template>
    <div class="flex-fill" >
        <div class="flex-fill flex-column">
            <v-form v-model="validUpload" class="d-flex flex-fill">
                <v-col cols=12 ref="form" class="flex-fill" :class="$vuetify.breakpoint.mdAndUp ? 'd-flex' : 'd-block '" style="height: 100%;">
                    <div class="d-flex justify-center col" :class="$vuetify.breakpoint.mdAndUp ? 'col-5' : 'col-12'">
                        <v-avatar :size="$vuetify.breakpoint.mdAndUp ? 225 : 125">
                            <img :key="stdntUpdateIdx" :src="getStudentUrl" alt="Student Image" />
                        </v-avatar>
                    </div>
                    <div class="col" :class="$vuetify.breakpoint.mdAndUp ? 'col-7' : 'col-12'">
                        <v-row class="d-block">
                            <fe-label class="student-controls">Change Image</fe-label>
                            <div class="d-flex">
                                <v-file-input
                                    flat solo dense
                                    v-model="selectedFiles"
                                    label="Browse Images"
                                    accept=".jpg,.jpeg,.png,.gif"
                                    class="student-controls"
                                    prepend-icon=""
                                    :key="stdntUpdateIdx"
                                    :disabled="!hasSecurity"
                                ></v-file-input>
                                <fe-btn
                                    usage="tertiary"
                                    block
                                    class="student-btn pa-0"
                                    @click="saveRec"
                                    :disabled="hasSecurity && files.length ? false : true"
                                >
                                    Upload
                                </fe-btn>
                            </div>

                            <div v-if="files.length">
                                <div  class="d-block" v-for="(file, i) in files" :key="`studentImage-`+i">
                                    <fe-label class="d-flex">Image Name (optional)</fe-label>
                                    <v-text-field
                                        class="d-flex"
                                        v-model="file.name"
                                        :rules="$fieldValidators('text', 'Image Name', { limit: 25 })"
                                        flat solo dense
                                    />
                                </div>
                            </div>
                        </v-row>

                        <v-row class="d-block">
                            <fe-label class="student-controls">Reassign Image</fe-label>
                            <div class="d-flex">
                                <v-autocomplete
                                    v-model="newRec.selectedSearchRecord"
                                    :search-input.sync="newRec.searchText"
                                    flat solo dense
                                    placeholder="Search Student (3 characters minimum)"
                                    class="nav-student-search-text student-controls"
                                    :items="newRec.searchResults"
                                    :itemText="text"
                                    itemValue="id"
                                    return-object
                                    :rules="$fieldValidators('select', 'Student', { required: false })"
                                    validateOnBlur
                                    clearable
                                    :disabled="!hasSecurity"
                                    :no-data-text="noSearchString ? '' : 'No data available'"
                                    :hide-no-data="noSearchString"
                                >
                                    <template v-slot:item="data">
                                        <v-list-item-content>
                                            <v-list-item-title v-html="text(data.item)"></v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <template v-slot:selection="data">
                                        <div v-html="text(data.item)"></div>
                                    </template>
                                </v-autocomplete>
                                <fe-btn
                                    usage="tertiary"
                                    block
                                    class="student-btn pa-0"
                                    @click="updateRec"
                                    :disabled="hasSecurity && newRec.selectedSearchRecord ? false : true"
                                >
                                    Reassign
                                </fe-btn>
                            </div>
                        </v-row>

                        <v-row class="d-block">
                            <fe-btn
                                class="student-controls"
                                usage="warning"
                                @click="deleteRec(stdntImage)"
                                :disabled="!hasSecurity"
                            >
                                Delete Image
                            </fe-btn>
                        </v-row>
                    </div>
                </v-col>
            </v-form>
        </div>
    </div>
</template>

<script>
    import Vuex, { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'
    import lodash from 'lodash'

    export default {
        name: 'StudentImageTools',

        props: {
            studentInfoModel: null
        },

        data() {
            return {
                files: [],
                selectedFiles: undefined,
                newRec: {
                    searchText: undefined,
                    searchResults: undefined,
                    selectedSearchRecord: undefined
                },
                validUpload: false,
                stdntImage: "",
                stdntUpdateIdx: 0,
                noSearchString: true
            }
        },

        inject: ['localStore'],

        computed: {
            ...mapLocalState(['reassignStudent','updateStudent','hasSecurity']),

            getStudentUrl() {
                return this.$axios.defaults.baseURL + this.$models.getUrl('studentImage', 'read') + '&id=' + this.updateStudent.student_id
            },

            chartCols() {
                if (this.$vuetify.breakpoint.mdAndDown) {
                    return 6
                } else if (this.$vuetify.breakpoint.lgAndDown) {
                    return 4
                }

                return 3
            }
        },

        watch: {
            selectedFiles: {
                deep: true,
                handler(v) {
                    if(this.selectedFiles) {
                        this.files = [{ student_id: this.updateStudent.student_id, name: v.name.substring(0, 20).split('.').slice(0, -1).join('.')}]
                    }
                }
            },

            'newRec.searchText': lodash.debounce(function(v) {
                if (v && v.length >= 3) {
                    this.noSearchString = false
                    this.doSearch()
                } else {
                    this.noSearchString = true
                }

            }, 500),

            'newRec.selectedSearchRecord'(v) {
                v ? this.reassignStudent = v : this.reassignStudent = undefined
            }
        },

        mounted() {
            this.loadData()
        },

        methods: {
            loadData() {
                this.stdntImage = this.$axios.defaults.baseURL + this.$models.getUrl('studentImage', 'read') + '&id=' + this.updateStudent.student_id
            },

            text(item) {
                return item.mdl_init ? item.lname + " " + item.mdl_init + ", " + item.fname + " (" + item.id + ")" : item.lname + ", " + item.fname + " (" + item.id + ")"
            },

            saveRec() {
                let me = this

                this.files.forEach((sel,i) => {
                    let formData = new FormData()
                    Object.keys(sel).forEach(field => {
                        formData.append(field, sel[field])
                    })

                    formData.append('upload_file', this.selectedFiles)

                    this.$axios.post('studentImageUpload.php?property=identification', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(() => {
                        this.selectedFiles = undefined
                        this.files = []
                        this.stdntUpdateIdx++
                        this.$emit('updateStudentImage')
                    }).catch(err => {
                        console.error(err)
                    })
                })
            },

            deleteRec(rec) {
                let params = new URLSearchParams()
                params.append('delete_student_id', this.updateStudent.student_id)

                this.$confirmDelete([rec], () => {
                    this.$axios.post('studentImageUpload.php', params).then(resp => {
                        this.stdntUpdateIdx++
                        this.$emit('updateStudentImage')
                    })
                    .catch(err => {
                        console.error(err)
                    })
                })
            },

            updateRec() {
                let params = new URLSearchParams()
                params.append('student_id', this.newRec.selectedSearchRecord.id)
                params.append('from_student_id', this.updateStudent.student_id)

                this.$axios.post('studentImageUpload.php', params).then(resp => {
                    this.newRec.selectedSearchRecord = undefined
                    this.stdntUpdateIdx++
                    this.$emit('updateStudentImage')
                })
                .catch(err => {
                    console.error(err)
                })
            },

            doSearch() {
                let params = '&name=' + this.newRec.searchText
                let me = this

                this.$axios.get(this.$models.getUrl('student', 'read') + params)
                .then(response => {
                    let ids = [this.updateStudent.student_id]
                    this.newRec.searchResults = response.data.filter(function (item) {
                        return ids.indexOf(item.id) === -1
                    })
                })
                .catch(err => {
                    console.error(err)
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        div.student-controls {
            width: 100% !important;
            max-width: 100% !important;
            font-size: 14px !important;

            .fe-button {
                width: 100% !important;
                max-width: 100% !important;
                margin: 12px 0 !important;
            }

            .v-input__slot {
                border-radius: 3px 0 0 3px !important;
                border-right: none;
            }

            .v-label {
                font-size: 14px !important;
                color: rgba(0,0,0,0.4);
                font-weight: 400;
            }
        }

        div.student-btn .fe-button {
            margin: 0 !important;
            border-radius: 0 4px 4px 0 !important;
        }
    }
</style>