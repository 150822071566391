<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on }">
            <v-btn
                v-on="on"
                :color="getHexCode"
                style="margin: 0;"
                text icon dark
            >
                <v-icon>fas fa-circle</v-icon>
            </v-btn>
        </template>

        <Compact
            @input="emitSelection"
            :value="getHexCode"
            :palette="list"
        />
    </v-menu>
</template>

<script>
import {Compact} from 'vue-color'
import Colors from '../config/Colors'

export default {
    name: 'ColorSelector',

    components: {
        Compact
    },

    props: {
        useIndex: {type: Boolean, default: false},
        value: {type: [Number, String], default: null}
    },

    computed: {
        list() {
            return Colors.getList()
        },

        getHexCode() {
            if (this.value) {
                return this.useIndex
                    ? Colors.getColor(this.value)
                    : this.value
            } else {
                return Colors.getColor(0)
            }
        },

        getIndex() {
            return this.useIndex ? this.value : Colors.getIndex(this.value)
        },

        getValue() {
            return this.useIndex ? this.getIndex : this.getHexCode
        }
    },

    methods: {
        emitSelection(v) {
            let val = this.useIndex ? Colors.getIndex(v.hex) : v.hex
            this.$emit('change', val)
        }
    }
}
</script>
