<template>
    <fe-stepper-filter
        :title="title" style="height:620px;"
        @apply="next" @close="$emit('close'); clearFilters()"
        :applyDisabled="searchDisabled"
        :applyBtnText="applyBtnText"
    >
        <template #footer>
            <div class="d-flex" style="width: 100%;">
                <div class="mt-3 ml-4" v-html="searchDisplay"></div>
                <v-spacer/>
            </div>
        </template>

        <keep-alive v-if="localValue">
            <template v-if="view.id=='population'">
                <student-stepper-panel
                    ref="studentStepper"
                    v-model="localValue.population"
                    :surfaceFirstSelections="surfaceFirstSelections"
                    byId
                    style="height: 480px;"
                    @selected="checkValid"
                    @searchDisplayChange="searchDisplay = $event"
                    @summaryChange="$emit('update:populationSummary', $event)"
                />
            </template>

            <template v-if="view.id=='advanced'">
                <advanced-filters-stepper-panel style="height: 480px;"/>
            </template>
        </keep-alive>
    </fe-stepper-filter>
</template>

<script>
    import * as Util from './Util'
    import StudentStepperPanel from '@/components/common/stepper/StudentStepperPanel'
    import AdvancedFiltersStepperPanel from '@/components/common/stepper/AdvancedFiltersStepperPanel'
    import StepperNavigation from './StepperNavigation'

    export default {
        name: 'DistrictStepper',
        components: {
            StudentStepperPanel,
            AdvancedFiltersStepperPanel,
            StepperNavigation
        },

        props: {
            state: {
                type: Object,
                required: false
            },
            byId: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: 'Cohort Search'
            },
            applyBtnText: {
                type: String,
                default: 'Search'
            },
            value: {
                type: Object,
                default: null
            },
            surfaceFirstSelections: {
                type: Boolean,
                default: false
            },
        },
        watch: {
            'view.id'(v, prevVal) {
                if (prevVal == 'population') {
                    this.population = {
                        ...this.population,
                        ...this.$refs.studentStepper.getSubmitParams(true) // only want the filters - this.population holds school year, school et al. already
                    }
                }

                this.checkValid()
            },
            population: {
                deep: true,
                handler(v) {
                    this.format()
                }
            }
        },
        data() {
            return {
                localValue: {},
                population: {
                    advancedFilters: {
                        student_active_flag: 1
                    }
                },
                formattedParams: {},
                view: {
                    id: ''
                },
                navs: [{
                    id: 'population',
                    name: 'Student Population'
                },],
                selections: {
                    assessment: {
                        school_year_id: [],
                        category_id: [],
                        data_point_type_id: [],
                        sub_category_id: [],
                        data_point_name_id: [],
                    },
                    incident: {
                        incident_behavior_type_id: [],
                        incident_behavior_id: [],
                        incident_response_id: [],
                    },
                    attendance: false,
                },
                searchDisabled: true,
                allowNext: false,
                searchDisplay: '<b>Select School Year</b>',
            }
        },
        computed: {
            formValid() {
                return false
            },
            schoolYearId() {
                return this.population.school_year?.id
            },
        },
        mounted() {
            this.localValue = { population: this.$_.cloneDeep(this.value) }
            this.view = this.navs[0]
            this.loadBase()
        },
        methods: {
            checkValid(item) {
                if (this.view.id == 'population') {
                    this.searchDisabled = (
                        !this.localValue?.population?.school_year_id ||
                        !this.localValue?.population?.school_id?.length ||
                        !this.localValue?.population?.grade_id?.length
                    )
                } else {
                    this.searchDisabled = false
                }
            },
            clearFilters() {
                this.localValue.population = {}
                this.view='population'
            },
            next() {
                switch (this.view.id) {
                    default:
                        this.emit()
                        break
                }
            },
            loadBase() {
                this.dialog=true
            },
            emit() {
                this.$emit('input', this.localValue.population)
                this.$emit('close')
            }
        },
    }
</script>

<style lang="scss" scoped>
</style>
