import Vue from "vue"

export default Vue.extend({
    // Please note that applying the .ag-tooltip class is very important;
    // otherwise, the tooltip doesn't position correctly at all (it's a disaster, TIAS!)
    template: `
        <div class="ag-tooltip ec-users-grid-popout-column-tooltip" v-html="tooltip"/>
    `,
    data() {
        return {
            tooltip: '',
        }
    },
    beforeMount() {
        this.tooltip = this.params.tooltip
    }
})