<template>
    <div class="flex-fill slo-container" :class="{ 'hide-title': hideTitle }">
        <fe-left-menu-layout
            class="slo-plan-left-menu"
            :menuItems="navigation"
            v-model="selectedNavigation"
            menuWidth="200px"
            full-width-title
            bodyPadding="0"
        >

            <fe-spinner v-if="!slo" text="Loading SLO..." />

            <slo-panel-summary
                :key="`slo-summary-${slo.id}`"
                v-else-if="selectedNavigation.rec.id == 1"
                @refreshSlo="refreshSlo"
                @inDevelopment="$emit('inDevelopment')"
                @edit="$emit('edit', $event)"
                @chartDrilldown="$emit('chartDrilldown', $event)"
                @dataWallDrilldown="$emit('dataWallDrilldown', $event)"
                @scoreBreakdown="$emit('scoreBreakdown', $event)"
                :slo="slo"
            />

            <slo-panel-forms
                v-else-if="selectedNavigation.rec.id == 2"
                @refreshSlo="refreshSlo"
                @inDevelopment="$emit('inDevelopment')"
                :slo="slo"
            />

            <slo-panel-strategies
                v-else-if="selectedNavigation.rec.id == 3"
                @refresh="refreshSlo"
                @inDevelopment="$emit('inDevelopment')"
                :slo="slo"
            />

            <slo-panel-logistics
                v-else-if="selectedNavigation.rec.id == 4"
                @refresh="refreshSlo"
                @inDevelopment="$emit('inDevelopment')"
                :slo="slo"
                :logisticTypes="logisticTypes"
            />

            <slo-panel-journal
                v-else-if="selectedNavigation.rec.id == 5"
                @inDevelopment="$emit('inDevelopment')"
                :slo="slo"
            />

        </fe-left-menu-layout>

        <fe-crud
            ref="crud"
            v-if="planId"
            autoload
            :config="$models.slo"
            :defaultParams="{ id: planId }"
            refresh-on-config
            @read="slo = $event"
        />

    </div>
</template>

<script>
    import sloPanelSummary from "./panel/Summary"
    import sloPanelForms from "./panel/Forms"
    import sloPanelStrategies from "./panel/Strategies"
    import sloPanelLogistics from "./panel/Logistics"
    import sloPanelJournal from "./panel/Journal"

    export default {
        name: 'SloPlan',
        props: {
            planId: { type: Number },
            logisticTypes: { type: Array }
        },
        components: {
            sloPanelSummary,
            sloPanelForms,
            sloPanelStrategies,
            sloPanelLogistics,
            sloPanelJournal
        },
        data() {
            return {
                slo: null,
                selectedNavigationId: 1,
                navigation: [{
                    name: 'Summary',
                    rec: { id: 1, name: 'Summary' }
                }, {
                    name: 'Forms',
                    rec: { id: 2, name: 'Forms' }
                }, {
                    name: 'Strategies',
                    rec: { id: 3, name: 'Strategies' }
                }, {
                    name: 'Logistics',
                    rec: { id: 4, name: 'Logistics' }
                }, {
                    name: 'Journal',
                    rec: { id: 5, name: 'Journal' }
                }]
            }
        },
        computed: {
            selectedNavigation: {
                get () { return this.navigation.find(x => x.rec.id === this.selectedNavigationId) },
                set (v) { this.selectedNavigationId = v.rec.id }
            },
            hideTitle () { return !this.$vuetify.breakpoint.mdAndDown }
        },
        methods: {
            refreshSlo () { this.$refs.crud.read() }
        },
        watch: {
            slo (v) {
                this.$emit('setTitle', v?.slo_name || 'SLO Details')
            }
        }
    }
</script>

<style lang="scss">
    .slo-container {
        overflow: hidden;
        margin-top: -5px;
        &.hide-title { margin-top: -48px; }

        .slo-plan-left-menu {
            .slo-plan-panel {
                overflow: auto;
                padding: 16px;
            }
            .nav-container {
                .nav-container-items {
                    padding-left: 0px
                }
            }
        }
    }
</style>
