<template>
    <div style="position: relative;">
        <div class="home-btn">
            <fe-btn usage="ghost" useIcon="fas fa-chevron-left" @click="$router.replace('/')">Resource Center</fe-btn>
        </div>
        <iframe style="border: none; height: 85vh;" class="pdf-iframe" src="https://schoolcity.com/learning-hub/index.php/ec/" />
    </div>
</template>

<script>

    export default {
        name: 'ResourceCenter',
        data() {
            return {
                
            }
        },
    }
</script>

<style lang="scss" scoped>
.home-btn {
    position: relative;
    top: 0px;
    left: 20px;
    background-color: white;
}
</style>