<template>
    <v-dialog @input="close" :value="true" :max-width="maxWidth" :persistent="persistent" >
        <v-card class="pa-2">
            <v-card-title v-if="title" class="headline">{{title}}</v-card-title>
            <v-card-text v-html="message" />
            <v-card-actions>
                <v-spacer />
                <template v-for="(btn,i) in actions">
                    <fe-btn
                        class="button"
                        :usage="btnColor(btn,i)"
                        :href="btn.href"
                        @click="click(btn)"
                    >
                        {{btn.text}}
                    </fe-btn>
                </template>
            </v-card-actions>
        </v-card>
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
        <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/></template>
    </v-dialog>
</template>

<script>
export default {
    name: 'MessageBox',

    props: {
        title: String,
        message: String,
        actions: Array,
        maxWidth: Number,
        persistent: {type:Boolean,default:true}
    },
    methods: {
        btnColor(btn,i) {
            if(btn.usage) return btn.usage
            return i == this.actions.length-1 ? 'primary' : 'tertiary'
        },
        close() {
            this.$destroy()
        },
        click(btn) {
            if(btn.onClick) btn.onClick(btn)
            this.$destroy()
        }
    }
}
</script>
<style lang="scss" scoped>
    .headline {
        word-break: break-word;
        word-wrap: normal;
    }
    .button ::v-deep .v-btn {
        text-transform: none;
    }
</style>
