<template>
    <div>
        <PerformanceBands v-if="!isConditional"/>

        <ConditionalOperator v-else/>

        <template v-if="!pending">
            <Pane
                v-for="(grp) in collection"
                :allowDelete="count > 1"
                :key="grp.id"
                :title="`Threshold ${(grp.__idx+1)}`"
                :obj="grp"
                @requireAll="requireAll(grp)"
                @requireAny="requireAny(grp)"
                @delete="confirmingDelete = grp"
            />

            <div style="height: 70px"></div>
        </template>

        <div class="footer__menu footer__menu--sticky">
            <v-container px-0 py-2 mx-5>
                <v-layout row wrap py-1>
                    <v-flex xs12>
                        <v-btn color="primary" dark depressed @click="addGroup">
                            + New Threshold
                        </v-btn>

                        <span class="text-muted">
                            <span class="text-muted">
                                {{ count }} Total Thresholds
                            </span>
                        </span>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>

        <fe-dialog
            v-if="confirmingDelete"
            usage="error"
            title="Delete Threshold?"
            dismissButtonText="Cancel"
            acceptButtonText="Delete"
            body="Are you sure you want to permanently delete this threshold?"
            @accept="() => removeGroup(confirmingDelete)"
            @dismiss="confirmingDelete = null"
        />
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Pane from '../groups/Pane'
import PerformanceBands from '../groups/PerformanceBands'
import ConditionalOperator from '../groups/ConditionalOperator'

export default {
    name: 'Groupings',

    components: {
        Pane,
        PerformanceBands,
        ConditionalOperator
    },

    props: {
        isActive: Boolean
    },

    data() {
        return {
            confirmingDelete: null
        }
    },

    computed: {
        ...mapGetters('thresholds/group/subgroup', [
            'collection',
            'clear',
            'pending',
            'success',
            'error',
            'hasDirtied',
            'hasRemoved',
            'hasAdded',
            'hasChanges',
            'count'
        ]),

        thresholdGroup() {
            return this.$store.getters['thresholds/group/selected']
        },

        thresholdGroupId() {
            return this.thresholdGroup.id
        },

        isConditional() {
            return (this.thresholdGroup.is_conditional === 1)
        },

        hasRecord() {
            return (this.count > 0)
        }
    },

    watch: {
        clear: function (val) {
            if (val) this.reset()
        },
        success: function (val) {
            if (val && this.count === 0) this.addGroup()
        }
    },

    beforeMount() {
        this.refresh()
    },

    methods: {
        ...mapActions('thresholds/group/subgroup', [
            'refresh',
            'reconcile',
            'update',
            'add',
            'remove'
        ]),

        addGroup() {
            this.add([{
                threshold_group_id: this.thresholdGroupId,
                name: `Threshold Set ${(this.count + 1)}`,
                operator: "all",
                conditional_weight: 1.0
            }])
        },

        requireAll(grp) {
            this.update(Object.assign({}, grp, {operator: 'all'}))
        },

        requireAny(grp) {
            this.update(Object.assign({}, grp, {operator: 'any'}))
        },

        removeGroup(grp) {
            this.remove({indexes: [grp.__idx]})
            this.confirmingDelete = null
        },

        reset() {
            this.refresh()
        },

        save() {
            this.reconcile()
        }
    }
}
</script>
