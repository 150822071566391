<template>
    <v-menu ref="menu" v-model="open" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on }">
            <div class="label ml-2" v-on="on">
                <v-icon class="filter-icon">fal fa-filter ml-1</v-icon>
                More Filters
            </div>
        </template>


        <div class="filter-menu">
            <div class="demo-list">
                <advanced-demographic-filters v-model="filters" ref="filters" vertical/>
            </div>

            <div class="d-flex tools">
                <v-btn class="ma-0" text style="min-width: 25px;" @click="clear">Clear</v-btn>
                <v-spacer/>
                <v-btn class="ma-0" text style="min-width: 25px; color: white; background: var(--v-primary-base)" @click="apply">Apply</v-btn></div>
        </div>
    </v-menu>
</template>

<script>
import AdvancedDemographicFilters from "@/components/common/AdvancedDemographicFilters.vue";

export default {
    name: "MoreFilters",

    components: {AdvancedDemographicFilters},

    props: {
        value: { type: Object, default: () => {} }
    },

    data() {
        return {
            open: false,
            filters: {},
            demographicOptions: undefined
        }
    },

    watch: {
        open(v) {
            if (v) {
                this.filters = this.$_.cloneDeep(this.value)
            }
        }
    },

    methods: {
        log() {
            console.log('jr', ...arguments)
        },

        clear() {
            this.filters = {}
        },

        apply() {
            this.$emit('apply', this.filters)
            this.close()
        },

        close() {
            this.$refs.menu.save()
        }
    }
}
</script>

<style scoped lang="scss">
.label {
    margin-top: 14px;;
    cursor: pointer;
    color: var(--v-primary-base);
}

.filter-icon {
    color: var(--v-primary-base);
    padding-right: 4px;
}

.filter-menu {
    width: 400px;
    background-color: #FFFFFFFF;

    .demo-list {
        overflow: scroll;
        max-height: 300px;
        margin: 8px;
        padding-right: 8px;

        .advanced-demographic-filters {
            margin: 8px;

            ::v-deep .advanced-demographic-filter {
                margin-bottom: 8px;
            }
        }
    }

    .tools {
        height: 53px;
        padding: 8px;
        border-top: 1px solid rgb(224, 225, 230);
    }
}
</style>
