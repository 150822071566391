<template>
    <fe-dialog
        v-if="active"
        @dismiss="active = false"
        @close="active = false"
        @accept="save"
        title="Performance Band Properties"
        dismissButtonText="Cancel"
        acceptButtonText="Save"
        :acceptButtonDisabled="false"
        width="780px"
    >
        <fe-tabs :tabs="tabs" @tabClicked="tab = $event" style="height: 50px;" />
        <div style=" margin-top: 8px; ">
            <div class="flex-fill flex-column">
                <fe-grid
                    :searchBar="false"
                    :showToolbar="false"
                    style="height: 250px;"
                    :rowData="rowData[tab.type]"
                    :columnDefs="columns[tab.type]"
                />
                <div class="d-flex mt-1">
                    <div class="flex-grow-1">
                        <v-autocomplete
                            ref="newItem"
                            flat solo dense
                            :items="unselectedRecords[tab.type]"
                            item-text="name"
                            :item-value="this.cfg[tab.type].comparison.available"
                            :placeholder="tab.placeholder"
                            style=" margin-top: 6px; width: 100%;"
                            v-model="insert[tab.type]"
                        />
                    </div>
                    <div class="flex-shrink-1">
                        <fe-btn
                            :disabled="!insert[tab.type]"
                            @click="addRecord(tab.type)"
                        >Add</fe-btn>
                    </div>
                </div>
            </div>
        </div>

        <fe-crud
            v-if="!scoreDetailTypes"
            autoload
            :config="$models.scoreDetailType"
            :readParams="{ data_point_type_id: group.id }"
            @read="scoreDetailTypes = $event"
        />
    </fe-dialog>
</template>

<script>
    export default {
        name: 'AssessmentGroupBandProperties',

        props: {
            group: { type: Object, default: () => {} },
            band: { type: Object, default: () => {} },
            availableAssessments: { type: Array, default: null },
            availableGrades: { type: Array, default: null },
            availableWindows: { type: Array, default: null },
            availableSchoolYears: { type: Array, default: null },
            availableScoreDetailTypes: { type: Array, default: null },
            bandAssessments: { type: Array, default: () => [] },
            bandGrades: { type: Array, default: () => [] },
            bandWindows: { type: Array, default: () => [] },
            bandSchoolYears: { type: Array, default: () => [] },
            bandTypes: { type: Array, default: () => [], required: true },
            cfg: { type: Object, required: true }
        },

        data () {
            return {
                itemToAdd: null,
                active: false,
                selectedTab: null,
                tabs: [{
                    name: 'Assessments',
                    type: 'assessments',
                    path: '#',
                    placeholder: 'Add an Assessment'
                }, {
                    name: 'Windows',
                    type: 'windows',
                    path: '#',
                    placeholder: 'Add a Window'
                }, {
                    name: 'Grades',
                    type: 'grades',
                    path: '#',
                    placeholder: 'Add a Grade'
                }, {
                    name: 'School Years',
                    type: 'schoolYears',
                    path: '#',
                    placeholder: 'Add a School Year'
                }],
                tmp: {
                    assessments: [],
                    grades: [],
                    windows: [],
                    schoolYears: []
                },
                insert: {
                    assessments: null,
                    grades: null,
                    windows: null,
                    schoolYears: null
                }
            }
        },

        computed: {
            bandRecords () {
                return {
                    assessments: this.bandAssessments,
                    grades: this.bandGrades,
                    windows: this.bandWindows,
                    schoolYears: this.bandSchoolYears
                }
            },
            availableRecords () {
                return {
                    assessments: [{ id: -1, name: "[All Remaining Assessments]"}, ...this.availableAssessments],
                    grades: this.availableGrades,
                    windows: this.availableWindows,
                    schoolYears: this.availableSchoolYears
                }
            },
            unselectedRecords () {
                let obj = {}
                Object.keys(this.availableRecords).forEach(type => {
                    obj[type] = this.availableRecords[type].filter(itm => {
                        return !this.tmp[type].find(i => {
                            return ((itm[this.cfg[type].comparison.available] === i[this.cfg[type].comparison.band])
                                || (itm[this.cfg[type].comparison.available] === -1 && i[this.cfg[type].comparison.band] === null))
                        })
                    })
                })
                return obj
            },
            tab: {
                get () { return this.selectedTab || this.tabs[0] },
                set (v) { this.selectedTab = v }
            },
            scoreDetailTypes: {
                get () { return this.availableScoreDetailTypes },
                set (v) { this.$emit('scoreDetailTypesLoaded', v) }
            },
            columns () {
                let cols = {}
                Object.keys(this.tmp).forEach(type => {
                    let colarray = [{
                        headerName: "Name",
                        field: "name",
                        sortable: true,
                        editable: false,
                        width: 200,
                        cellRenderer (v) {
                            if (v.data.hidden) {
                                return v.value + '<i class="fas fa-eye-slash" style="margin-left: 10px; font-size: 12px; color: #BFBFBF;"></i>'
                            } else {
                                return v.value
                            }
                        },
                        tooltipValueGetter: function(v) { return v.data.hidden ? 'This assessment window is hidden. Hidden windows will not display in charts' : null },

                    }]

                    if (this.scoreDetailTypes && this.scoreDetailTypes.length && (type == 'assessments' || type == 'windows'))  {
                        colarray.push({
                            headerName: 'Focus',
                            field: 'score_detail_type_id',
                            sortable: true,
                            editable: true,
                            cellRenderer: v => v.data.score_detail_type_name,
                            cellEditorFramework: "FeGridChipSelect",
                            cellEditorParams: {
                                rowDataKey: "score_detail_type_id",
                                mode: "edit",
                                items: this.scoreDetailTypes,
                                keyProp: "id",
                                labelProp: "description",
                                multiple: false
                            },
                            onCellValueChanged: v => this.updateRecord(type, v),
                        })
                    }

                    colarray.push({
                        headerName: 'Remove',
                        field: this.cfg[type].comparison.available,
                        maxWidth: 100,
                        cellRenderer () { return '<i class="fas fa-minus-circle" style="margin-left: 15px; font-size: 12px"></i>' },
                        cellStyle () {
                            return {
                                cursor: 'pointer',
                                textAlign: 'left'
                            }
                        },
                        onCellClicked: (v) => this.removeRecord(type, v)
                    })

                    cols[type] = colarray
                })
                return cols
            },
            rowData () {
                let data = {}
                Object.keys(this.tmp).map(type => {
                    data[type] = this.tmp[type].map(itm => {
                        let rec = this.availableRecords[type].find(i => {
                            return ((i[this.cfg[type].comparison.available] === itm[this.cfg[type].comparison.band])
                                || (i[this.cfg[type].comparison.available] === -1 && itm[this.cfg[type].comparison.band] === null))
                        })
                        let meta = {
                            score_detail_type_id: itm.score_detail_type_id,
                            score_detail_type_name: itm.score_detail_type_name
                        }

                        if (rec && rec[this.cfg[type].comparison.available] === null) meta[this.cfg[type].comparison.available] = -1
                        return Object.assign({}, rec, meta)
                    })
                })
                return data
            },
            cachedUpdates () {
                let updates = []
                Object.keys(this.tmp).map(type => {
                    if (this.availableRecords[type]) {
                        updates.push({
                            type: type,
                            operation: 'create',
                            payload: this.tmp[type]
                                .filter(itm => !this.bandRecords[type].find(i => i[this.cfg[type].comparison.band] == itm[this.cfg[type].comparison.band]))
                                .map(itm => Object.assign({}, itm, { target_set_id: this.band.id }))
                        })
                        updates.push({
                            type: type,
                            operation: 'destroy',
                            payload: this.bandRecords[type]
                                .filter(itm => !this.tmp[type].find(i => i[this.cfg[type].comparison.band] == itm[this.cfg[type].comparison.band]))
                        })
                        updates.push({
                            type: type,
                            operation: 'update',
                            payload: this.bandRecords[type]
                                .filter(itm => this.tmp[type].find(i => {
                                    return (i[this.cfg[type].comparison.band] == itm[this.cfg[type].comparison.band] && i.score_detail_type_id != itm.score_detail_type_id)
                                        ? Object.assign(itm, { score_detail_type_id: i.score_detail_type_id })
                                        : false
                                }))
                        })
                    }
                })
                updates = updates.filter(itm => itm.payload.length > 0)
                return (updates.length > 0) ? updates : null
            }
        },

        watch: {
            band () { this.reset() },
            group () { this.reset() },
            bandAssessments () { this.reset() },
            bandGrades () { this.reset() },
            bandWindows () { this.reset() },
            bandSchoolYears () { this.reset() },
            availableAssessments () { this.reset() },
            availableGrades () { this.reset() },
            availableWindows () { this.reset() },
            availableSchoolYears () { this.reset() },
            active (v) { if (v) this.selectedTab = this.tabs[0] },
            tab (v) { if (v) this.insert[v.type] = null }
        },

        methods: {
            reset () {
                if (this.tmp) {
                    Object.keys(this.tmp).forEach(type => {
                        this.tmp[type] = [].concat(this.bandRecords[type])
                    })
                }
            },
            updateRecord (type, val) {
                let aRec = this.availableRecords[type].find(i => i[this.cfg[type].comparison.available] === val.data[this.cfg[type].comparison.available])
                let tRec = this.tmp[type].find(i => {
                    return ((i[this.cfg[type].comparison.band] === val.data[this.cfg[type].comparison.available]) ||
                        (i[this.cfg[type].comparison.band] === null && val.data[this.cfg[type].comparison.available] === -1))
                })
                let obj = Object.assign({}, tRec)
                if (Array.isArray(this.scoreDetailTypes) && (type == 'assessments' || type == 'windows')) {
                    let sdt = this.scoreDetailTypes.find(x => x.id == val.data.score_detail_type_id)
                    obj.score_detail_type_id = (sdt) ? sdt.id : null
                    obj.score_detail_type_name = (sdt) ? sdt.code : ''
                }
                this.tmp[type] = this.tmp[type].map(i => (i == tRec) ? obj : i)
            },
            addRecord (type) {
                let obj = {
                    score_detail_type_id: null,
                    score_detail_type_name: ''
                }
                obj[this.cfg[type].comparison.band] = (this.insert[type] === -1)
                    ? null
                    : this.insert[type]
                this.tmp[type] = this.tmp[type].concat(obj)

                this.insert[type] = null
            },
            removeRecord (type, val) {
                this.tmp[type] = this.tmp[type].filter(i => {
                    return ((i[this.cfg[type].comparison.band] != val.data[this.cfg[type].comparison.available])
                        && !(i[this.cfg[type].comparison.band] === null && val.data[this.cfg[type].comparison.available] === -1))
                })
            },
            open () {
                this.active = true
                this.$emit('open')
                this.reset()
            },
            close () {
                this.active = false
                this.$emit('close')
            },
            save () {
                this.$emit('update', this.cachedUpdates)
            }
        }
    }
</script>
