<template>
    <div
        class="advanced-demographic-filters d-flex"
        :class="{
            'flex-column': vertical,
            'flex-wrap': !vertical
        }"
    >
        <advanced-demographic-filter
            v-for="demographic in activeDemographics"
            :key="`demographic-${demographic.id}`"
            :value="params[`demo_${demographic.id}`]"
            :demographic="demographic"
            :labelOnTop="labelsOnTop"
            :style="{
                minWidth: vertical ? null : '250px',
                width: vertical ? '100%' : '20%'
            }"
            :class="{
                'pa-2': !vertical,
                'd-flex': vertical && !labelsOnTop,
                'justify-space-between': vertical,
                'flex-row': vertical && !labelsOnTop,
                'mx-2': labelsOnTop
            }"
            :vertical="vertical"
            :byId="byId"
            :resetEntry="resetEntry"
            @result="update(demographic, $event)"
        />
    </div>
</template>

<script>
import AdvancedDemographicFilter from './AdvancedDemographicFilter'

export default {
    name: 'AdvancedDemographicFilters',

    components: {AdvancedDemographicFilter},

    props: {
        byId: {type: Boolean, default: false},
        value: {type: Object, default: () => {}},
        vertical: {type: Boolean, default: false},
        labelsOnTop: {type: Boolean, default: false},
        resetEntry: {type: Boolean, default: true},
    },

    data() {
        return {
            params: {}
        }
    },

    computed: {
        demographics() {
            return this.$store.getters['global/demographicsSearchable']
        },

        activeDemographics() {
            return this.demographics.filter(dmo => dmo.active)
        },

        querystring() {
            return this.$objectToParams(this.params)
        }
    },

    watch: {
        params(v) {
            this.$emit('input', v)
        },

        value: {
            handler(v) {
                this.params = v
            },
            immediate: true
        }
    },

    mounted() {
        this.load()
    },

    methods: {
        async load() {
            await this.$store.dispatch('global/loadDemographics')
        },

        update(demographic, val) {
            this.$emit('result', demographic, val)
            let k = `demo_${demographic.id}`
            let obj = Object.assign({}, this.params)
            if (val === null) {
                delete obj[k]
            } else {
                obj[k] = val.encoded
            }
            this.params = obj
        }
    }
}
</script>

<style lang="scss">
</style>
