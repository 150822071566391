<template>
    <div class="d-flex justify-center">
        <fe-dialog
            title="Choose Plan"
            v-if="chooseDialog.show"
            @accept="chooseAction(chooseDialog.action, chooseDialog.record[0])"
            @close="chooseDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Choose"
            width="600"
            :acceptButtonDisabled="!chooseDialog.record || chooseDialog.record.length === 0"
        >
            <selection-list identifier="student_intervention_plan_id" :multiple="false" :items="chooseDialog.plans" style="height: 200px" v-model="chooseDialog.record">
                <template #row="{item}">
                    <div class="d-flex">
                        <div style="width: 150px; overflow: hidden;">{{item.intervention_plan_name}}</div>

                        <div class="ml-5" style="font-size: 10px; color: gray;">
                            {{item.intervention_plan_intervention_start_date}} to {{ item.intervention_plan_intervention_end_date}}
                        </div>
                    </div>
                </template>
            </selection-list>
        </fe-dialog>
        <fe-dialog
            title="Continue"
            v-if="continueDialog.show"
            @accept="doContinue"
            @close="continueDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Save"
            width="600"
            :acceptButtonDisabled="!message"
        >
            <fe-label>Decision Rationale</fe-label>
            <v-textarea flat solo dense v-model="message"/>
        </fe-dialog>

        <fe-dialog
            title="End"
            v-if="endDialog.show"
            @accept="doEnd"
            @close="endDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Save"
            :acceptButtonDisabled="!endDialog.valid"
            width="600"
        >
            <v-form v-model="endDialog.valid">
                <fe-date-picker
                    label="Start Date"
                    readonly
                    disabled
                    :rules="$fieldValidators('text', null, { required: true })"
                    v-model="endDialog.record.intervention_plan_intervention_start_date"
                ></fe-date-picker>

                <fe-date-picker
                    label="End Date"
                    :rules="$fieldValidators('text', null, { required: true })"
                    v-model="endDialog.record.intervention_plan_intervention_end_date"
                ></fe-date-picker>

                <fe-remote-combo
                    :items="exitStatuses"
                    itemText="name"
                    itemValue="id"
                    :rules="$fieldValidators('select', null, { required: true })"
                    v-model="endDialog.record.intervention_exit_status_id"
                    byId
                />

                <fe-label>Decision Rationale</fe-label>
                <v-textarea flat solo dense
                    :counter="1000"
                    v-model="message"
                    :rules="$fieldValidators('text', null, { required: true, limit: 1000 })"
                />
            </v-form>

        </fe-dialog>

        <fe-dialog
            title="Change (New Phase)"
            v-if="modifyDialog.show"
            @accept="doModify"
            @close="modifyDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Save"
            :acceptButtonDisabled="!modifyDialog.valid "
        >
            <modify-plan v-model="modifyDialog.record" @valid="(v) => { modifyDialog.valid = v }"/>
        </fe-dialog>

        <fe-btn @click="decision('continue')" usage="tertiary">Continue</fe-btn>
        <fe-btn @click="decision('modify')" usage="tertiary">Change (New Plan)</fe-btn>
        <fe-btn @click="decision('end')" usage="tertiary">End</fe-btn>
    </div>
</template>

<script>
    import SelectionList from '@/components/common/SelectionList'
    import ModifyPlan from './ModifyPlan'
    export default {
        name: 'MonitorDecisions',
        components: { ModifyPlan, SelectionList },
        props: {
            params: {
                type: Object
            },
            messagePrefix: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                continueDialog: {
                    record: {},
                    show: false
                },
                endDialog: {
                    show: false,
                    record: {}
                },
                chooseDialog: {
                    show: false,
                    record: [],
                    plans: []
                },
                modifyDialog: {
                    show: false,
                    record: {},
                    valid: false
                },
                changeDialog: false,
                message: '',
                exitStatus: null,
                exitStatuses: []
            }
        },
        mounted() {
            this.message = (this.messagePrefix ? this.messagePrefix + ' ' : '') + this.message
        },
        methods: {
            doUpdate(action) {

            },
            loadPlan() {
                let p = {
                    intervention_id: this.params.intervention_id,
                    student_id: this.params.student_id
                }
                return new Promise((resolve, reject) => {
                    this.$axios.get('studentInterventionPlans.php?action=get&property=interventions&'+this.$objectToParams(p))
                        .then(r => {
                            resolve(this.$ecResponse(r, 'students'))
                        })
                })


            },
            chooseAction(action, plan) {
                this.message = (this.messagePrefix ? this.messagePrefix + ' ' : '')

                switch(action) {
                    case 'continue':
                        this.continueDialog.show=true
                        this.continueDialog.record=plan
                        break
                    case 'end':
                        this.$modelGet('interventionExitStatus').then(r=>this.exitStatuses=r)
                        this.endDialog.show=true
                        this.endDialog.record=plan
                        break
                    case 'modify':
                        this.modifyDialog.show=true
                        this.modifyDialog.record = {...plan}
                        break
                }
            },
            decision(action) {
                this.loadPlan().then(plans => {
                    if (plans?.length>1) {
                        this.chooseDialog.show = true
                        this.chooseDialog.record = []
                        this.chooseDialog.plans = plans
                        this.chooseDialog.action = action
                    } else if (plans?.length) {
                        this.chooseAction(action, plans[0])
                    } else {
                        this.$snackbars.$emit('new', { text: 'Monitor must be linked to an intervention to use this function', usage: 'warning' })
                    }
                })
            },
            doEnd() {
                let dRec = this.endDialog.record

                let rec = {
                    id: dRec.student_intervention_plan_id,
                    intervention_plan_id: dRec.intervention_plan_id,
                    decision_rationale: this.message,
                    intervention_exit_status_id: dRec.intervention_exit_status_id,
                    intervention_id: dRec.intervention_id,
                    start_date: dRec.intervention_plan_intervention_start_date,
                    end_date: dRec.intervention_plan_intervention_end_date,
                    student_id: dRec.student_id
                }

                this.$axios.post('studentInterventionPlans.php?action=update', { students: [rec] })
                    .then(r => {
                        this.$ecResponse(r)
                        this.$emit('created', r)
                        this.endDialog = {
                            show: false,
                            record: {}
                        }
                    })
                // students

            },
            doModify(confirmed) {
                let rec = this.modifyDialog.record
                rec.student_ids = rec.student_id
                this.$axios.post('interventionPlan.php?action=create&allow_new_plan=' + (confirmed ? 1 : 0), rec)
                    .then(r => {
                        this.$ecResponse(r)
                        this.$emit('created', r)
                    })
            },
            doContinue() {
                let rec = {...this.params, ...{message_type_id: 3, value: this.message}}
                this.$axios.post('message.php?action=create', { messages: [rec] })
                    .then(r => {
                        this.$ecResponse(r)
                        this.$emit('created', r)
                        this.continueDialog = {
                            show: false,
                            record: {}
                        }
                    })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
