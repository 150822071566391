<template>
    <v-form v-model="validForm">
        <div>
            <div class="d-flex">
                <fe-date-picker
                    label="Start Date"
                    v-model="localValue.start_date"
                    :rules="$fieldValidators('text', 'Start date', { required: true })"
                    class="mr-2 flex-grow-1"
                    dateFormat="MM/DD/YYYY"
                />
                <fe-date-picker
                    label="End Date"
                    v-model="localValue.end_date"
                    :rules="rules.endDate"
                    class="flex-grow-1"
                    :class="{'invalid-date': invalidEndDate}"
                    dateFormat="MM/DD/YYYY"
                />
            </div>
        </div>

        <div>
            <fe-label>Schedule Type</fe-label>
            <fe-remote-combo
                flat solo dense
                :multiple="false"
                itemText="name"
                itemValue="id"
                v-model="localValue.frequency_cd"
                :items="scheduleTypes"
                byId
                :rules="$fieldValidators('select', 'Schedule type', { required: true })"
            />
        </div>

        <div v-if="localValue.frequency_cd == 'WEEK'">
            <weekday-picker v-model="localValue.week_days"/>
        </div>

        <div v-if="localValue.frequency_cd == 'BLOCK'" class="d-flex">
            <div class="flex-grow-1 pr-2">
                <fe-label>Days on</fe-label>
                <v-text-field
                    v-model="localValue.frequency_cnt"
                    flat solo dense
                    type="number"
                    :rules="$fieldValidators('number', 'Schedule type', { min: 0, max: 14, required: true })"
                />
            </div>

            <div class="flex-grow-1">
                <fe-label>Days off</fe-label>
                <v-text-field
                    v-model="localValue.secondary_cnt"
                    flat solo dense
                    type="number"
                    :rules="$fieldValidators('number', 'Schedule type', { min: 0, max: 14 })"
                />
            </div>
        </div>

         <div v-if="localValue.frequency_cd == 'NOSCHED'" class="d-flex">
            <div class="flex-grow-1 pr-2">
                <fe-label>Total number of sessions</fe-label>
                <v-text-field
                    v-model="localValue.frequency_cnt"
                    flat solo dense
                    type="number"
                    :rules="$fieldValidators('number', 'Schedule type', { min: 1, max: 200, required: true })"
                />
            </div>
        </div>

        <div class="d-flex">
            <div class="pr-2 flex-grow-1">
                <fe-label>Start Time</fe-label>
                <fe-time-scroll-picker
                    v-model="localValue.start_time"
                    :rules="$fieldValidators('text', 'Start time', { required: timeRequired })"
                />
            </div>

            <div class="flex-grow-1">
                <fe-label>End Time</fe-label>
                <fe-time-scroll-picker
                    v-model="localValue.end_time"
                    :rules="rules.endTime"
                />
            </div>
        </div>

        <div class="d-flex" v-if="!standalone">
            <fe-btn class="ml-auto" usage="primary"  :disabled="!isValid" @click="$emit('add')">Add</fe-btn>
        </div>
    </v-form>
</template>

<script>
    import WeekdayPicker from '@/components/common/button/WeekdayPicker'
    import { mapState } from 'vuex'
    import timeMixin from './mixins/time'

    export default {
        name: 'Schedule',
        components: {
            WeekdayPicker
        },
        mixins: [timeMixin],
        props: {
            value: {
                required: true
            },
            standalone: {
                type: Boolean,
                default: false
            },
            timeRequired: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            ...mapState('global', ['currentYear']),
            isValid() {
                if (!this.validForm) return false
                let schedType = this.localValue.frequency_cd
                if (!schedType) return false
                if (this.invalidEndDate) return false
                if (this.invalidEndTime()) return false
                let valid = true

                switch (schedType) {
                    case 'WEEK':
                        valid = this.$checkAllKeys(this.$data.localValue, ['start_date', 'end_date', 'frequency_cd', 'week_days'], true)
                        break
                    case 'BLOCK':
                        valid = this.$checkAllKeys(this.$data.localValue, ['start_date', 'end_date', 'frequency_cd', 'frequency_cnt', 'secondary_cnt'], true)
                        break
                    case 'NOSCHED':
                        valid = this.$checkAllKeys(this.$data.localValue, ['start_date', 'end_date', 'frequency_cd', 'frequency_cnt'], true)
                        break
                }
                return valid
            },
            invalidEndDate() {
                if (!this.localValue.end_date || !this.localValue.start_date) return true

                return this.$dayjs(this.localValue.end_date).isBefore(this.localValue.start_date)
            },
        },
        data() {
            return {
                validForm: false,
                localValue: {
                    user_id: null,
                    frequency_cd: 'WEEK',
                    week_days: '',
                    start_time: null,
                    end_time: null,
                    start_date: null,
                    end_date: null
                },
                autoSuggested: false,
                scheduleTypes: [{
                    id: 'WEEK',
                    name: 'Weekly Schedule'
                }, {
                    id: 'BLOCK',
                    name: 'Block Schedule'
                }, {
                    id: 'NOSCHED',
                    name: 'As Needed'
                }],
                rules: {
                    endDate: [
                        v => !this.$dayjs(v).isBefore(this.localValue.start_date) || 'Not a valid End Date'
                    ].concat(this.$fieldValidators('text', 'End date', { required: true })),
                    endTime: [
                        v => !this.$dayjs('2000-01-01 ' + v).isBefore('2000-01-01 ' + this.localValue.start_time) || 'Not a valid End Time'
                    ].concat(this.$fieldValidators('text', 'End time', { required: true }))
                }
            }
        },
        watch: {
            localValue: {
                deep: true,
                handler(v) {
                    this.$emit('input', v)
                }
            },
            'localValue.start_time'(startTime) {
                this.localValue.end_time = this.getEndTime(startTime, this.localValue.end_time)
            },
            value(v) {
                this.localValue = v
            }
        },
        methods: {
            invalidEndTime() {
                if ((this.timeRequired || this.localValue.start_time) && !this.localValue.end_time) return true

                let start = '2000-01-01 ' + this.localValue.start_time
                return this.$dayjs('2000-01-01 ' + this.localValue.end_time).isBefore(start)
            }
        },
        created() {
            this.localValue = this.$props.value
            if (!this.localValue.start_date) this.$dayjs().format('MM/DD/YYYY')
            if (!this.localValue.end_date) this.currentYear ? this.$dayjs(this.currentYear.year_end, 'YYYY-MM-DD').format('MM/DD/YYYY') : this.$dayjs().format('MM/DD/YYYY')
        },
    }
</script>

<style lang="scss" scoped>
.invalid-date {
    ::v-deep .v-input__slot {
        border: 1px solid #f02d1f !important;
    }

}
</style>
