<template>
    <div>
        <v-skeleton-loader
            class="mx-auto"
            :height="400"
            :loading="showLoader"
            type="image">
            <div v-if="datawalls.length > 0">
                <v-tabs height="100%" v-model="activeCollectionTab" class="ec-datawalls-tabs" show-arrows>
                    <v-tab v-for="dw in datawalls" :key="dw.index" class="small-tab">{{ dw.title }}</v-tab>
                </v-tabs>

                <v-tabs-items v-model="activeCollectionTab">
                    <v-tab-item v-for="dw in datawalls" :key="dw.index">
                        <div class="ec-datawalls-tab-item-div">
                            <data-wall
                                :params="dw.params"
                                showDataWall
                                forCollections
                                @remove-from-collection="removeSelectedSavedSearch(dw)"
                            />
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </div>
            <v-layout v-else align-center justify-center column fill-height text-center class="empty-state">
                <fe-empty-state
                    image="/images/rocket_transparent.png"
                    text="You have not linked any Data Walls to this collection yet."
                />
            </v-layout>
        </v-skeleton-loader>
    </div>
</template>
<script>
    import DataWall from "../../datawall/Index";
    export default {
        name: 'DataWalls',

        components: {
            DataWall
        },

        props: {
            reload: { type: Boolean, default: false }
        },

        data() {
            return {
                activeCollectionTab: 0,
                showLoader: false,
                datawalls: []
            }
        },

        methods: {
            removeSelectedSavedSearch(category) {
                let params = {
                    dashboard_id: this.dashboard_id,
                    saved_search_id: category.params.saved_search_id
                }
                this.$axios.post('dashboard.php?action=delete&property=saved_search', { dashboard_saved_search: params })
                    .then(response => {
                        if(response.data.success) {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                            this.$store.commit('collection/set', {
                                key: 'reloadCollection',
                                value: true
                            })
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                        }
                    })
            },
            onLoad() {
                this.showLoader = true
                this.datawalls = []

                if(this.$route.params.id) {
                    this.dashboard_id = parseInt(this.$route.params.id.split(':')[1])
                    this.$axios.get('dashboard.php?action=get&property=saved_search&dashboard_id=' + this.dashboard_id)
                        .then(response => {
                            response.data.saved_searches.forEach(ss => {
                                let params = {
                                    saved_search_id: ss.saved_search_id
                                }
                                if(ss.dashboard_saved_search_type_id === 6) {
                                    this.datawalls.push({
                                        title: ss.title,
                                        params: params
                                    })
                                }
                            })
                        })
                        .finally(() => {
                            this.showLoader = false

                            if(this.reload) {
                                this.$emit('reloaded', false)
                            }
                        })
                }
            }
        },

        watch: {
            $route(to, from) {
                this.onLoad()
            },
            reload(v) {
                if(v) {
                    this.onLoad()
                }
            }
        },

        mounted() {
            this.onLoad()
        }
    }
</script>

<style lang="scss" scoped>
    .small-tab {
        text-transform: capitalize;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        font-size: 14px;
        letter-spacing: normal;
        justify-content: flex-start;
        min-width: 50px;
        max-width: 175px;
        margin: 5px 0;
    }
    .small-tab::before {
        border-radius: 4px;
    }
    .ec-datawalls {
        &-tabs {
            width: 90%;
            position: relative;
            z-index: 5;
            ::v-deep .v-item-group {
                position: relative;
                flex-wrap: nowrap;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                height: 100%;
            }
            ::v-deep .v-tabs-slider {
                border-radius: 1.5px;
                &-wrapper {
                    height: 3px !important;
                }
            }
        }
        &-tab-item-div {
            height: calc(100vh - 245px);
            position: relative;
        }
        &-empty-state {
            justify-content: center;
            display: flex;
            text-align: center;
        }
    }
    ::v-deep .empty-state img {
        height: 340px;
    }
</style>
