<template>
    <div class="flex-grow-1" :class="{'pt-4': !scrolled}">
        <div v-if="!items.length" style="width: 100%">
            <div class="empty-state">
                <fe-empty-state
                    image="/images/rocket_small.png"
                    text="You do not have any visualizations.<br>Create one by selecting New Visualization above."
                />
            </div>
        </div>

        <div v-else class="flex-fill flex-column">
            <div v-if="scrolled || sections.length" ref="sectionLinks" class="d-flex section-header">
                <fe-icon-btn useIcon="fas fa-arrow-alt-to-top" x-small @click="scroll('top')"/>

                <div v-if="narrow">
                    <v-menu close-on-content-click>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-on="on"
                                class="text-none ma-0"
                                text solo dense ghost
                            >
                                Select Section <i class="ml-2 fas fa-sort"></i>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item v-for="section in sections" style="cursor: pointer">
                                <v-list-item-title @click="scroll(section)">{{ section.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>

                <v-btn
                    v-else
                    v-for="section in sections"
                    :key="`section-${section.id}`"
                    @click="scroll(section)"
                    class="text-none ma-0"
                    text solo dense ghost
                >
                    {{ section.name }}
                </v-btn>
            </div>

            <div class="grid-wrapper flex-fill" @scroll="checkScroll">
                <div class="top-anchor"/>

                <grid-layout
                    ref="gridLayout"
                    class="grid-layout"
                    :layout="items"
                    :col-num="4"
                    :row-height="20"
                    :margin="[10, 20]"
                    style="height: unset"
                    isDraggable
                    isResizable
                    :useCssTransforms="false"
                    @layout-updated="layoutUpdatedEvent"
                >
                    <grid-item
                        v-for="item in items"
                        :x="item.x ? item.x : 0"
                        :y="item.y ? item.y : 0"
                        :w="item.w ? item.w : 1"
                        :h="item.h ? item.h : 1"
                        :minH="item.minH"
                        :i="item.i"
                        :key="`grid-item-${item.id}-${item.i}`"
                        :isResizable="(item.type !== 'section') && canModify"
                        dragAllowFrom=".handle"
                        @resized="resizeEvent(item)"
                        @moved="resizeEvent"
                    >
                        <div class="vis d-flex flex-fill">
                            <div class="d-flex flex-fill flex-shrink-1 grip" :class="{'can-modify': canModify}">
                                <div class="handle">
                                    <span class="fa-stack">
                                        <i class="far fa-ellipsis-v"/>
                                        <i class="far fa-ellipsis-v"/>
                                    </span>
                                </div>
                            </div>

                            <div
                                v-if="item.type === 'section'"
                                class="flex-fill d-flex section"
                                :class="[`section-id-${item.id}`]"
                            >
                                <div class="section-text-wrapper flex-grow-1">
                                    <div class="section-name">
                                        {{ item.name }}
                                    </div>

                                    <div v-if="item.description" class="section-description-wrapper">
                                        <div class="section-description" v-html="item.description" />
                                    </div>
                                </div>

                                <ad-menu
                                    v-if="canModify"
                                    class="flex-shrink-1"
                                    :items="sectionMenuItems"
                                    :returnValue="item"
                                    x-small
                                    dense
                                />
                            </div>

                            <visualization
                                v-else-if="ready"
                                ref="vis"
                                :config="item"
                                :dashboard="dashboard"
                                :canModify="canModify"
                                :getSSValues="getSavedSearchValues"
                                lazy
                                v-on="$listeners"
                            />
                        </div>
                    </grid-item>
                </grid-layout>
            </div>
        </div>
    </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout'
import AdMenu from "./Menu"
import Visualization from "./Visualization"

export default {
    name: "Visualizations",

    components: {
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        AdMenu,
        Visualization
    },

    props: {
        visualizations: {
            type: Array,
            default: []
        },
        dashboard: {
            type: Object,
            required: true
        },
        canModify: {
            type: Boolean,
            required: true
        },
        unPackConfig: {
            type: Function,
            required: true
        },
        getSavedSearchValues: {
            type: Function,
            required: true
        }
    },

    data() {
        return {
            ready: true,
            items: [],
            scrolled: false,
            scrolling: false,
            narrow: false,
            sectionMenuItems: [{
                name: 'Edit Section',
                callback: v => {
                    this.$emit('edit', v)
                }
            }, {
                name: 'Delete Section',
                callback: v => {
                    this.$emit('delete', v)
                }
            }]
        }
    },

    computed: {
        sections() {
            return this.items
                .filter(x => x.type === 'section')
                .sort((a, b) => {
                    return a.y > b.y ? 1 : -1
                })
        }
    },

    watch: {
        visualizations: {
            handler(v) {
                if (v) {
                    this.items = v.map(this.unPackConfig)
                }
            },
            immediate: true
        },
        scrolled(n, o) {
            if (n !== o) {
                this.$emit('scrolled', n)
            }
        }
    },

    mounted() {
        window.addEventListener('resize', this.checkSizing)
        this.checkSizing()
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.checkSizing)
    },

    methods: {
        checkScroll() {
            if (this.scrolling) clearTimeout(this.scrolling)

            this.scrolling = setTimeout(() => {
                let anchor = document.querySelector('.top-anchor').getBoundingClientRect()
                let wrapper = document.querySelector('.grid-wrapper').getBoundingClientRect()

                if (!this.scrolled) {
                    if (anchor.y < wrapper.y - 65) this.scrolled = true
                } else {
                    if (anchor.y === wrapper.y) this.scrolled = false
                }
            }, 50)
        },

        save(v) {
            this.$emit('save', v)
        },

        resizeEvent(e) {
            this.$refs.vis.forEach(x => {
                if(x.config === e) {
                    x.$reflow()
                    x.adjustXMax()
                }
            })
            /** TODO make charts reflow when resize happens **/
        },

        layoutUpdatedEvent(e) {
            this.$refs.vis.forEach(x => x.setDimensions())
            this.$nextTick(() => {
                this.$reflow()
            })
            this.$emit('layout-updated', e)
        },

        checkSizing() {
            if (window.innerWidth < 900) {
                this.narrow = true
            } else if (this.sections.length) {
                let count = 0
                let textWidth = 0
                this.sections.forEach(item => {
                    textWidth += 10
                    count += item.name.length + 1
                })
                let maxWidth = this.$refs.sectionLinks.offsetWidth - 70
                textWidth += count * 8.5
                this.narrow = textWidth > maxWidth
            } else {
                this.narrow = false
            }

            if (this.narrow) this.selectedLink = ""
        },

        scroll(item) {
            let sectionSelector = ''

            switch (item) {
                case 'top-instant':
                case 'top':
                    sectionSelector = '.top-anchor'
                    break;
                default:
                    sectionSelector = '.section-id-' + item.id
            }

            const section = document.querySelector(sectionSelector)

            if (section) {
                let args = {}
                if (item !== 'top-instant') {
                    args = {
                        behavior: 'smooth',
                        alignToTop: true,
                        block: 'start',
                        inline: 'nearest'
                    }
                }

                setTimeout(() => {
                    section.scrollIntoView(args)
                }, 50)
            }
        },

        forceAllReload() {
            this.ready = false
            this.$nextTick(() => {
                this.ready = true
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.empty-state {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
}

.section-header {
    height: 36px;
}

::v-deep .vue-grid-item.vue-grid-placeholder {
    background: #006C90 !important;
}

.vis {
    .grip {
        width: 14px;
        visibility: hidden;

        & .handle {
            width: 14px;
            padding: 0 3px;
            height: 28px;
            margin-top: 4px;
            background: #ECEDF1;
            border-radius: 4px 0 0 4px;
        }
    }

    &:hover .grip.can-modify {
        visibility: visible;
    }

    .section {
        background: #FFFFFF;
        border: 1px solid #E0E1E6;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 12px;

        & .section-text-wrapper {
            position: relative;

            & .section-name {
                font-size: 16px;
                font-weight: bold;
                margin: 5px 0;
                color: #000000;
            }

            & .section-description-wrapper {
                position: relative;
                height: 50px;

                & .section-description {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 12px;
                    color: #606060;
                }
            }
        }
    }
}

.grid-wrapper {
    width: calc(100% + 16px);
    margin-left: -8px;
    overflow-y: auto;
    overflow-x: hidden;
}

.grid-layout {
    width: 100%;
}
</style>
