<template>
    <TabPane ignoreCount lessPadding>
        <template slot="icon">
            <h3 class="card__title card__title--group">
                Performance Band
                <span class="text-muted text-small ml-2">
                    {{ thresholdGroup.start_date }}{{ (thresholdGroup.start_date && thresholdGroup.end_date) ? "&mdash;" : "" }}{{ thresholdGroup.end_date }}
                </span>
            </h3>
        </template>

        <template slot="buttons">
            <v-menu z-index="50" bottom left>
                <template v-slot:activator="{ on }">
                    <v-btn icon x-small v-on="on" style="margin-right: 8px">
                        <v-icon>fal fa-ellipsis-v</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item @click="editing = true">
                        <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

        <template slot="content">
            <div v-if="pending">
                <v-progress-circular indeterminate color="#999" size="16"/>
                Loading...
            </div>

            <div v-else-if="count === 0">
                <div class="placeholder__basic placeholder__basic--other"/>

                <div class="text-xs-center">
                    <p>You have not configured the Performance Band yet. The Performance Band will help you to visualize
                        your Threshold data.</p>
                    <p>To get started, click edit in the 3 dot menu.</p>
                </div>
            </div>

            <div class="my-4" v-else>
                <PerformanceBandChart
                    :bands="rowData"
                    :values="bandValues"
                    :labels="bandLabels"
                />
            </div>

            <PerformanceBandEditor
                v-if="isEditing"
                @cancel="cancel"
                @save="save"
            />
        </template>
    </TabPane>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import TabPane from '../templates/TabPane'
import PerformanceBandEditor from '../modals/PerformanceBandEditor'
import PerformanceBandChart from '../charts/PerformanceBand'

export default {
    name: 'PerformanceBands',

    components: {
        TabPane,
        PerformanceBandEditor,
        PerformanceBandChart
    },

    data() {
        return {
            loading: false,
            editing: false,
            reloadAfterErrorCount: 0
        }
    },

    computed: {
        ...mapGetters('thresholds/group/performanceBand', [
            'collection',
            'clear',
            'pending',
            'count',
            'rowData',
            'error'
        ]),

        isViewing() {
            return !this.editing
        },

        isEditing() {
            return !this.isViewing
        },

        thresholdGroup() {
            return this.$store.getters['thresholds/group/selected']
        },

        thresholdGroupId() {
            return this.thresholdGroup.id
        },

        range() {
            return `${this.min} - ${this.max}`
        },

        sorted() {
            return [].concat(this.rowData).sort((a, b) => a.from_value - b.from_value)
        },

        bandValues() {
            return this.rowData.map(() => 1)
        },

        bandLabels() {
            return this.sorted.map((x, idx) => {
                let floor = x.from_value
                if (idx === this.rowData.length - 1) {
                    return `${floor}+<br /><strong>${x.name}</strong>`
                } else {
                    let ceil = (parseFloat(this.sorted[idx + 1].from_value) - 0.1).toFixed(1)
                    return `${floor}–${ceil}<br /><strong>${x.name}</strong>`
                }
            })
        },

        max() {
            return (this.count === 0) ? 0 : this.sorted.slice(-1)[0].from_value
        },

        min() {
            return (this.count === 0) ? 0 : this.sorted[0].from_value
        }
    },

    watch: {
        obj: function (obj) {
            if (obj) this.refresh()
        },

        error: function (v) {
            if (v) {
                this.$store.dispatch('thresholds/toast/push', {
                    message: 'An error occurred while updating Performance Bands.',
                    color: 'error'
                })
                this.reloadAfterError()
            }
        },

        success: function (v) {
            if (v) this.reloadAfterErrorCount = 0
        }
    },

    mounted() {
        this.refresh()
    },

    methods: {
        ...mapActions('thresholds/group/performanceBand', [
            'refresh',
            'reconcile'
        ]),

        save() {
            this.reconcile()
            this.editing = false
        },

        cancel() {
            this.refresh()
            this.editing = false
        },

        reloadAfterError() {
            setTimeout(() => {
                if (this.reloadAfterErrorCount < 3) {
                    this.reloadAfterErrorCount++
                    this.refresh()
                }
            }, 500)
        }
    }
}
</script>
