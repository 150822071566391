<template>
    <div class="d-flex" style="overflow: auto; border-top: 1px #ECEDF1 solid;">
        <fe-dialog
            v-if="timerIntervalDialog"
            title=""
            :footer="false"
            :dismissable="false"
        >
            <div class="pa-10" style="text-align:center;">
                <div>
                    <i class="fas fa-stopwatch timer-icon"/>
                    <span class="timer-text">{{ duration }} {{ duration > 1 ? 'seconds' : 'second' }}</span>
                </div>
                <div class="timer-subtext">Observe the student</div>
            </div>
        </fe-dialog>

        <div class="flex-grow-1">
            <template>
                <div class="pa-4" style="height: 325px; overflow: hidden;">
                    <div class="d-flex align-center">
                        <fe-btn color="red" use-icon="fas fa-stop-circle" @click="onStop('stop')">Stop</fe-btn>
                        <v-text-field
                            v-model="samplingName"
                            placeholder="Sampling Name"
                            style="width: 100%;"
                            hide-details
                            @blur="onChangeSamplingName"
                        />
                        <span v-if="isIntervalObservation && !timerIntervalDialog" :style="[{'color': duration <= 3 ? 'red' : null }]"><i class="fas fa-stopwatch pa-1"/></span>
                        <span v-if="isIntervalObservation && !timerIntervalDialog" :style="[{'color': duration <= 3 ? 'red' : null }]">{{ duration }}</span>
                        <span v-if="isIntervalObservation && !timerIntervalDialog" :style="[{'color': duration <= 3 ? 'red' : null }]">{{ duration > 1 ? '&nbsp;seconds' : '&nbsp;second'}}</span>
                    </div>

                    <div>
                        <highcharts v-if="renderChart" :options="chart"/>
                    </div>
                </div>
            </template>

            <template>
                <div class="pa-4">
                    <div class="d-block flex-wrap" style="max-height: 425px; overflow: auto; border: 1px solid #E0E1E6; border-radius: 4px;">
                        <div class="d-block pa-4">
                            <span class="d-flex flex-row pb-1" style="font-size: 22px;">Adaptive Behaviors</span>
                            <div class="d-flex flex-wrap">
                                <template v-for="behavior in observationBehaviors">
                                    <ObservationBehavior
                                        :ref="`behavior${behavior.id}`"
                                        :key="behavior.id"
                                        v-if="behavior.observation_behavior_type_id === 1"
                                        :behavior="behavior"
                                        :observationBehaviors="observationBehaviors"
                                        @createInstantOccurrence="onCreateInstantOccurrence($event)"
                                        @startBehaviorTimer="startBehaviorTimer($event)"
                                        @endBehaviorTimer="endBehaviorTimer($event)"
                                        @updateLocalBehaviorColor="onUpdateBehaviorColor($event)"
                                    />
                                </template>
                            </div>
                        </div>
                        <div class="d-block pa-4">
                            <span class="d-flex flex-row pb-1" style="font-size: 22px;">Maladaptive Behaviors</span>
                            <div class="d-flex flex-wrap">
                                <template v-for="behavior in observationBehaviors">
                                    <ObservationBehavior
                                        :ref="`behavior${behavior.id}`"
                                        :key="behavior.id"
                                        v-if="behavior.observation_behavior_type_id === 2"
                                        :behavior="behavior"
                                        :observationBehaviors="observationBehaviors"
                                        @createInstantOccurrence="onCreateInstantOccurrence($event)"
                                        @startBehaviorTimer="startBehaviorTimer($event)"
                                        @endBehaviorTimer="endBehaviorTimer($event)"
                                        @updateLocalBehaviorColor="onUpdateBehaviorColor($event)"
                                    />
                                </template>
                            </div>
                        </div>
                    </div>

                    <fe-crud-grid
                        ref="grid"
                        :autoload="false"
                        :config="$models.studentObservationBehaviorOccurrences"
                        :columnDefs="columnDefs"
                        :readParams="{ student_observation_id: studentObservationId, student_observation_sampling_id: activeSampling.id }"
                        disableInlineCreate
                        :showAddRowBtn="false"
                        :showToolbar="false"
                        style="height: 445px;"
                        class="pt-4"
                        @read="occurrences = $event"
                    >
                        <template #cellDialog="{ cell }">
                            <v-list>
                                <v-list-item @click="onDeleteOccurrence(cell)">Delete</v-list-item>
                            </v-list>
                        </template>
                    </fe-crud-grid>

                    <fe-dialog
                        v-if="createOccurrenceDialog.show"
                        @close="createOccurrenceDialog.show = false"
                        @dismiss="createOccurrenceDialog.show = false"
                        @accept="onSaveOccurrence"
                        :acceptButtonDisabled="!createOccurrenceDialog.isValid"
                        title="Behavior Details"
                    >
                        <v-form v-model="createOccurrenceDialog.isValid">
                            <div class="d-flex flex-wrap">
                                <div class="col-12">
                                    <fe-remote-combo
                                        label="Antecedent"
                                        byId
                                        hide-details
                                        v-model="createOccurrenceDialog.record.observation_behavior_antecedent_id"
                                        :items="antecedents"
                                    />
                                </div>
                                <div class="col-12">
                                    <fe-remote-combo
                                        label="Consequence"
                                        byId
                                        hide-details
                                        v-model="createOccurrenceDialog.record.observation_behavior_consequence_id"
                                        :items="consequences"
                                    />
                                </div>

                                <div class="col-6">
                                    <fe-time-scroll-picker
                                        label="Start Time"
                                        v-model="createOccurrenceDialog.record.start_time"
                                        :rules="$fieldValidators('text', 'Start Time', { required: true })"
                                        hide-details="auto"
                                    />
                                </div>
                                <div class="col-6">
                                    <fe-time-scroll-picker
                                        label="End Time"
                                        v-model="createOccurrenceDialog.record.end_time"
                                        :rules="$fieldValidators('text', 'End Time', { required: true })"
                                        hide-details="auto"
                                    />
                                </div>

                                <div class="col-12">
                                    <fe-label>Control Student</fe-label>
                                    <fe-switch
                                        v-model="createOccurrenceDialog.record.control"
                                        hide-details
                                    />
                                </div>
                            </div>
                        </v-form>
                    </fe-dialog>

                    <fe-crud ref="crudSampling" :config="$models.studentObservationSamplings"/>
                    <fe-crud ref="crudOccurrence" :config="$models.studentObservationBehaviorOccurrences"/>

                    <fe-crud ref="crudAntecedents" :config="$models.observationBehaviorAntecedents" autoload @read="antecedents = $event"/>
                    <fe-crud ref="crudConsequences" :config="$models.observationBehaviorConsequences" autoload @read="consequences = $event"/>
                </div>
            </template>
        </div>

        <div class="pa-4 fill-height sidebar" :style="{ width: isSidebarCollapsed ? '41px' : '300px' }">
            <div v-show="isSidebarCollapsed" class="title-text rotated">Notes</div>
            <template v-if="!isSidebarCollapsed">
                <div class="title-text">Notes</div>
                <message dense :params="{ student_observation_id: studentObservationId }" :allowPrivate="false" actionItemsBelow/>
            </template>
            <v-btn
                color="primary"
                fab dark small depressed
                class="collapse-btn"
                style="margin-left: -15px"
                @click="isSidebarCollapsed = !isSidebarCollapsed"
            >
                <v-icon size=14>{{ !isSidebarCollapsed ? 'fas fa-chevron-right' : 'fas fa-chevron-left' }}</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex"
    import SelectColumn from './renderers/SelectColumn'
    import Message from '@/components/common/Message'
    import ObservationBehavior from './ObservationBehavior'
    import EndButtonRenderer from './renderers/EndButtonRenderer.js'

    export default {
        name: 'StandardObservation',
        components: {
            Message,
            ObservationBehavior,
        },
        props: {
            observationBehaviorTypes: {
                type: Array,
                required: true
            },
            observationBehaviors: {
                type: Array,
                required: true
            },
            studentObservationId: {
                type: Number,
                required: true
            },
            isIntervalObservation: {
                type: Boolean,
                default: false
            },
            intervalObservationIntervalLength: {
                type: Number,
                required: false
            },
            intervalObservationObservationDuration: {
                type: Number,
                required: false
            },
        },
        watch: {
            'activeSampling.id'() {
                //this.$refs.grid.$refs.crud.read()
            },
        },
        data() {
            return {
                isSidebarCollapsed: false,
                observationInterval: {
                    timer: null,
                    end: null,
                    progress: 100,
                },
                renderChart: true,
                chartInterval: null,
                chartStartTimestamp: null,
                chartEndTimestamp: null,
                chartItems: [],
                // These defaults are copied from the various defaults in FeColorSelector
                localBehaviorColors: [
                    '#0049FF', '#0EA449', '#FF8E07', '#F02D1F', '#3CCCCA', '#5A53C9',
                    '#2B87FF', '#49C379', '#FFA200', '#FD4336', '#53DAD8', '#746DE0',
                    '#539AF8', '#5BE893', '#FFBD00', '#FF675D', '#66EEEC', '#8E88EA',
                    '#89B7F4', '#84FFB5', '#FFE072', '#FF8D85', '#93F9F7', '#AFAAF9',
                    '#C4DCFA', '#B4F8CF', '#FFEEAA', '#F8D1CE', '#C0FBFA', '#DCDAFB',
                ],
                occurrences: [],
                antecedents: [],
                consequences: [],
                activeSampling: {
                    id: null,
                    start_time: null,
                    context: null,
                },
                samplingName: null,
                activeTimerBehaviorIds: [],
                activeTimers: [],
                createOccurrenceDialog: {
                    show: false,
                    isValid: false
                },
                notes: [],
                notesDialog: {},
                timerIntervalDialog: false,
                timerType: '',
                duration: 0,
                timeoutLoop: null,
                sidebarBtnLoc: null,
            }
        },
        computed: {
            ...mapState('global', ['currentYear', 'sessionUser']),
            chart() {
                return {
                    chart: {
                        type: 'xrange',
                        height: 275
                    },
                    xxxAxis: {
                        type: 'category',
                        title: {
                            text: 'Time of Day'
                        },
                        labels: {
                            tickPlacement: 'on'
                        }
                    },
                    xAxis: {
                        type: 'datetime',
                        min: this.chartStartTimestamp,
                        max: this.chartEndTimestamp,
                    },
                    yAxis: {
                        title: {
                            text: ''
                        },
                        categories: this.observationBehaviors.map(itm => itm.name),
                        reversed: true
                    },
                    legend: {
                        enabled: false
                    },
                    title: {
                        text: 'Observed Behaviors',
                        align: 'center'
                    },
                    series: [{
                        animation: false,
                        data: this.chartItems?.filter(x => x.type === 'xrange'),
                        dataLabels: { enabled: true, align: 'top', distance: 50, alternate: false, },
                        pointPadding: 0,
                        groupPadding: 5,
                        borderColor: 'grey',
                        pointWidth: 20,
                        colors: this.localBehaviorColors,
                    },
                    {
                        animation: false,
                        stickyTracking: false,
                        marker: {
                            enabled: true,
                            symbol: 'circle',
                            lineWidth: 5,
                            radius: 10
                        },
                        type: 'scatter',
                        data: this.chartItems?.filter(x => x.type === 'scatter'),
                    }],
                    time: {
                        useUTC: false,
                    },
                }
            },
            columnDefs() {
                return [
                    {
                        colId: 'observation_behavior_name',
                        headerName: "Observed Behavior",
                        field: "observation_behavior_name",
                        sortable: true,
                        editable: false,
                    }, {
                        colId: 'observation_behavior_antecedent_name',
                        headerName: "Antecedent",
                        field: "observation_behavior_antecedent_name",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: SelectColumn,
                        cellRendererParams: {
                            items: this.antecedents,
                            valueField: "observation_behavior_antecedent_id",
                            onInput: this.onUpdateGridOccurrence.bind(this),
                        },
                    }, {
                        colId: 'observation_behavior_consequence_name',
                        headerName: "Consequence",
                        field: "observation_behavior_consequence_name",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: SelectColumn,
                        cellRendererParams: {
                            items: this.consequences,
                            valueField: "observation_behavior_consequence_id",
                            onInput: this.onUpdateGridOccurrence.bind(this),
                        },
                    }, {
                        colId: 'start_time',
                        headerName: "Occurred",
                        field: "start_time",
                        sortable: true,
                        editable: false,
                        cellRenderer: (v) => (v.data?.start_time) ? this.$dayjs(v.data.start_time).format('h:mm:ss a') : '',
                    }, {
                        colId: 'end_time',
                        headerName: "Ended",
                        field: "end_time",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: EndButtonRenderer,
                        cellRendererParams: {
                            endTime: "end_time",
                        },
                    }, {
                        colId: 'control',
                        headerName: "Control",
                        field: "control",
                        sortable: true,
                        editable: false,
                        cellRendererFramework: "FeGridToggle",
                        onCellValueChanged: this.onUpdateGridOccurrence.bind(this),
                        cellRendererParams: {
                            rowDataKey: 'control'
                        },
                        maxWidth: 160,
                    }, {
                        colId: 'toolmenu',
                        maxWidth: 50,
                        cellRenderer: (v) => {
                            return '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>'
                        },
                        onCellClicked: this.onCellClicked,
                        cellStyle : { display: 'flex' }
                    },
                ]
            },
            filteredObservationBehaviors() {
                if (!this.filterText) {
                    return this.observationBehaviors
                } else {
                    return this.observationBehaviors.filter(b => b.name.toLowerCase().includes(this.filterText.toLowerCase()))
                }
            },
        },
        mounted() {
            this.chartInterval = setInterval(() => {
                this.buildTimelineXAxis()
            }, 1000)

            this.beginSampling()

            if (this.isIntervalObservation) {
                this.enterIdlePeriod()

                // interval timer
                this.timerIntervalDialog = true
                this.duration = this.intervalObservationObservationDuration
                this.timerType = 'observe'
                this.countDownTimer(this.duration, this.timerType)
            }
        },
        beforeDestroy() {
            clearInterval(this.chartInterval)
            if (this.observationInterval.timer) {
                clearInterval(this.observationInterval.timer)
                this.observationInterval.timer = null
            }
            this.onStop()
        },
        methods: {
            reload() {
                this.$refs.grid.$refs.crud.read()
                    .then(() => {this.$refs.grid.$refs.grid.gridApi.refreshCells({force: true})})
            },
            enterIdlePeriod() {
                if (this.observationInterval.timer) {
                    clearInterval(this.observationInterval.timer)
                }

                this.observationInterval.progress = 100 // progress bar starts full and depletes as interval is almost over, like an hourglass

                this.observationInterval.end = this.$dayjs().add(this.intervalObservationObservationDuration, 's')
                this.observationInterval.timer = setInterval(() => {
                    // Once the observation interval is over, hide the overlay and enter
                    // a period of time during which user can input occurrence data
                    if (this.$dayjs() >= this.observationInterval.end) {
                        return this.exitIdlePeriod()
                    } else {
                        let a = this.observationInterval.end.diff(this.$dayjs())
                        let b = (this.intervalObservationObservationDuration * 1000) // dayjs.diff is in milliseconds

                        this.observationInterval.progress = Math.floor((a / b) * 100)
                    }
                }, 50)
            },
            exitIdlePeriod() {
                if (this.observationInterval.timer) {
                    clearInterval(this.observationInterval.timer)
                }

                this.observationInterval.end = this.$dayjs().add(this.intervalObservationIntervalLength, 's')
                this.observationInterval.timer = setInterval(() => {
                    // Once the period of time during which user is allowed to input
                    // occurrence data has concluded, show the countdown/timer/spinner again
                    if (this.$dayjs() >= this.observationInterval.end) {
                        return this.enterIdlePeriod()
                    }
                }, 50)
            },
            onClickNewBehavior(behaviorType) {
                this.createOccurrenceDialog.show = true
                this.createOccurrenceDialog.record = {
                    name: '',
                    observation_behavior_type_id: behaviorType.id,
                }
            },
            onUpdateBehaviorColor(behavior) {
                let index = this.observationBehaviors.findIndex(itm => itm.id === behavior.behavior.id)
                if (index >= 0) {
                    this.localBehaviorColors[index] = behavior.color
                }
            },
            onCellClicked(cell) {
                let cfg = cell.column.colId === 'toolmenu' ? 'menu' : null
                this.$refs.grid.$refs.grid.setDialogCell(cell, cfg)
            },
            buildTimelineXAxis() {
                let mEnd = this.$dayjs()
                let mStart = this.$dayjs(mStart).add(-29, 's')

                // For render efficiency, it's better to remove chart and re-render it each time
                this.renderChart = false

                // When the observation first begins, we "need" to supply dummy data into the
                // chart so that it renders the labels of each selected behavior.  However,
                // when >= 1 occurrence has been entered, not only do we no longer "need" this
                // data, but it becomes actively harmful (causes the timestamps on the x axis to
                // disappear for unknown reason)
                let defaultChartItems = this.occurrences.length > 0 ? [] : this.observationBehaviors
                    .map((itm, i) => ({
                        x: this.$dayjs().add(-1, 'm').unix() * 1000,
                        x2: this.$dayjs().add(-1, 'm').unix() * 1000,
                        y: i,
                    }))

                this.chartItems = [
                    ...defaultChartItems,
                    ...this.occurrences.map(itm => {
                        let x = this.$dayjs(itm.start_time).unix() * 1000
                        let x2 = (itm.end_time ? this.$dayjs(itm.end_time).unix() : this.$dayjs().unix()) * 1000
                        let thisIndex = this.observationBehaviors.findIndex(ob => ob.id === itm.observation_behavior_id)
                        return x == x2 ?
                                {
                                    x: this.$dayjs(itm.start_time).unix() * 1000,
                                    y: thisIndex >= 0 ? thisIndex : 0,
                                    type: 'scatter',
                                    color: thisIndex ? this.localBehaviorColors[thisIndex] : this.localBehaviorColors[0]
                                } :
                                {
                                    x: this.$dayjs(itm.start_time).unix() * 1000,
                                    // If occurrence has not been marked complete, then force end time of "now" so it continues to appear on the graph
                                    x2: (itm.end_time ? this.$dayjs(itm.end_time).unix() : this.$dayjs().unix()) * 1000,
                                    // If occurrence behavior is not one of the selected behaviors to observe,
                                    // thisIndex could be -1, which then shows up in the chart y axis
                                    // this prevents that from happening
                                    y: thisIndex >= 0 ? thisIndex : 0,
                                    type: 'xrange'
                                }
                        })
                ]

                this.chartStartTimestamp = mStart.unix() * 1000
                this.chartEndTimestamp = mEnd.unix() * 1000

                this.$nextTick(() => {
                    this.renderChart = true
                })
            },
            checkIsBehaviorActive(behavior) {
                return !!this.activeTimers.find(itm => itm.behaviorId === behavior.id)
            },
            async beginSampling() {
                if (this.activeSampling.id) {
                    return this.activeSampling
                }

                this.activeSampling = {
                    student_observation_id: this.studentObservationId,
                    start_time: this.$dayjs().format('YYYY-MM-DD HH:mm:ss'),
                    end_time: null,
                    context: this.samplingName,
                }

                this.activeSampling = await this.$refs.crudSampling.create(this.activeSampling).then(res => res.data.student_observation_samplings[0])

                // For this call, we have to specifically provide the sampling id because
                // the fe-crud-grid readParams hasn't been updated yet
                this.$refs.grid.$refs.crud.read({ student_observation_sampling_id: this.activeSampling.id })

                return this.activeSampling
            },
            async onChangeSamplingName() {
                await this.$refs.crudSampling.update({
                    ...this.activeSampling,
                    context: this.samplingName,
                })
            },
            async beginTimingBehavior(behavior, isEndedImmediately) {
                if (!isEndedImmediately) {
                    this.activeTimers = this.activeTimers.filter(itm => itm.behaviorId !== behavior.id)
                }
                let sampling = this.activeSampling

                if (!this.samplingName) {
                    this.samplingName = 'Untitled Sampling'
                    this.onChangeSamplingName()
                }

                let occurrence = await this.$refs.grid.$refs.crud.create({
                    observation_behavior_id: behavior.id,
                    student_observation_sampling_id: sampling.id,
                    start_time: this.$dayjs().format('YYYY-MM-DD HH:mm:ss'),
                    end_time: isEndedImmediately ? this.$dayjs().format('YYYY-MM-DD HH:mm:ss') : null, //if plus icon, don't give any time
                }).then(res => res.data.student_observation_behavior_occurrences[0])

                if (!isEndedImmediately) {
                    this.activeTimers.push({
                        behaviorId: behavior.id,
                        occurrence: occurrence,
                    })
                }

                return occurrence
            },
            async startBehaviorTimer(behavior) {
                // If timer is "off," then ensure a sampling has begun and start
                // a new occurrence for that sampling
                if (!this.checkIsBehaviorActive(behavior)) {
                    let sampling = await this.beginSampling()
                    let occurrence = await this.beginTimingBehavior(behavior)
                    let thisBehavior = 'behavior' + occurrence.observation_behavior_id
                    this.$refs[thisBehavior][0].timerStatus = true

                    await this.$refs.grid.$refs.crud.read()//{ student_observation_id: this.studentObservationId })
                }
            },
            async endBehaviorTimer(occurrence, skipRead) {
                if (!occurrence.observation_behavior_id) {
                    // if call comes from timer button, use behavior_id (occurrence.id) to get occurrence to stop
                    // and make sure we get only the "occurrence" object
                    // (this.activeTimers may contain behaviorId and occurrence)
                    occurrence = this.activeTimers.find(itm => itm.behaviorId === occurrence.id).occurrence
                    this.activeTimers = this.activeTimers.filter(itm => itm.behaviorId !== occurrence.observation_behavior_id)
                } else {
                    // if call comes from table button, use observation_behavior_id
                    this.activeTimers = this.activeTimers.filter(itm => itm.behaviorId !== occurrence.observation_behavior_id)
                }

                await this.$refs.grid.$refs.crud.update({
                    ...occurrence,
                    end_time: this.$dayjs().format('YYYY-MM-DD HH:mm:ss'),
                })

                if (!skipRead) {
                    await this.$refs.grid.$refs.crud.read()
                }

                // update specific timer button status in ObservationBehavior component
                let thisBehavior = 'behavior' + occurrence.observation_behavior_id
                if (typeof this.$refs[thisBehavior][0] !== 'undefined') {
                    this.$refs[thisBehavior][0].timerStatus = false
                }
            },
            // This may be called when the user changes certain column values in the grid
            async onUpdateGridOccurrence(params, value) {
                // Handle a newly created item
                if (value && value.id === -1) {
                    let cleanName = value.name.replace(' (new)', '')
                    await this.saveNewAntecedentConsequece(cleanName, value.type)
                    value = value.type === 'antecedents' ? this.antecedents.filter(x => x.name === value.name)[0].id : this.consequences.filter(x => x.name === value.name)[0].id
                }

                let occurrence = {
                    id: params.data.id,
                    observation_behavior_name: params.data.observation_behavior_name,
                    observation_behavior_antecedent_id: params.data.observation_behavior_antecedent_id,
                    observation_behavior_consequence_id: params.data.observation_behavior_consequence_id,
                    observation_behavior_id: params.data.observation_behavior_id,
                    student_observation_sampling_id: params.data.student_observation_sampling_id,
                    start_time: params.data.start_time,
                    end_time: params.data.end_time,
                    control: params.node.data.control,
                }

                await this.$refs.grid.$refs.crud.update({
                    ...occurrence,
                    [params.column.colId.replace('_name', '_id')]: value ? value.id || value : null,
                })
                    .then(r => {
                        this.$nextTick(() => {
                            this.reload()
                        })
                    })
            },
            // Save new antecedent or consequence added by user in table
            async saveNewAntecedentConsequece(name, type) {
                let params = type === 'antecedents' ? {observation_behavior_antecedents: [{name: name}]} : {observation_behavior_consequences: [{name: name}]}

                await this.$axios.post('observations.php?action=create&property=' + type, params)
                    .then(r => {
                        let success = this.$ecResponse(r)
                    })

                // reload antecedent/consequence options
                type === 'antecedents' ? await this.$refs.crudAntecedents.read() : await this.$refs.crudConsequences.read()
            },
            async onCreateInstantOccurrence(behavior) {
                let sampling = await this.activeSampling
                let occurrence = await this.beginTimingBehavior(behavior, true)

                await this.$refs.grid.$refs.crud.read()
            },
            async onSaveOccurrence() {
                // Because the dialog shows no-date time pickers, and the backend expects
                // a day of year as well (YYYY MM DD etc.) then we should manually craft those here
                this.createOccurrenceDialog.record.start_time = this.$dayjs()
                    .set('hour', this.createOccurrenceDialog.record.start_time.split(':')[0])
                    .set('minute', this.createOccurrenceDialog.record.start_time.split(':')[1].split(' ')[0])
                    .set('second', 0)
                    .format('YYYY-MM-DD HH:mm:ss')

                this.createOccurrenceDialog.record.end_time = this.$dayjs()
                    .set('hour', this.createOccurrenceDialog.record.end_time.split(':')[0])
                    .set('minute', this.createOccurrenceDialog.record.end_time.split(':')[1].split(' ')[0])
                    .set('second', 0)
                    .format('YYYY-MM-DD HH:mm:ss')

                this.isBusy = true
                await this.$refs.grid.$refs.crud.create(this.createOccurrenceDialog.record)

                this.isBusy = false
                this.createOccurrenceDialog.show = false
                await this.$refs.grid.$refs.crud.read()
            },
            async onDeleteOccurrence(cell) {
                let msg = 'You are about to delete an observation occurrence. This action cannot be undone. Are you sure you want to continue?'

                await this.$confirmDelete([], () => {
                    this.$refs.grid.$refs.crud.destroy(cell.node.data)
                        .then((resp) => {
                            if (resp?.data?.success) {
                                this.$refs.grid.$refs.crud.read()
                            }
                            else {
                                this.$snackbars.$emit('new', {text: resp.data.msg, usage: 'warning'})
                            }
                        })
                        .catch((err) => {
                            if (err.response) this.$snackbars.$emit('new', {text: err.response, usage: 'warning'})
                            else this.$snackbars.$emit('new', {text: err.message, usage: 'warning'})
                        })
                }, null, msg)
            },
            async onStop() {
                // Make sure to enforce an end time on any occurrence of any behavior
                // that the user "forgot" to click stop for
                let pendingOccurrences = this.occurrences.filter(itm => !itm.end_time)
                let promises = pendingOccurrences.map(itm => this.endBehaviorTimer(itm, true))
                await Promise.all(promises)

                this.activeSampling.end_time = this.$dayjs().format('YYYY-MM-DD HH:mm:ss')
                if (typeof this.$refs.crudSampling !== 'undefined') {
                    this.$refs.crudSampling.update(this.activeSampling)

                }

                this.stopTimer()
                this.$emit('close', 'stopBtn')
            },
            countDownTimer(duration, type) {
                this.duration = duration
                if (this.duration > 0) {
                    this.timeoutLoop = setTimeout(() => {
                        this.duration -= 1
                        this.countDownTimer(this.duration, type)
                    }, 1000)
                } else {
                    if (type === 'observe') {
                        this.timerIntervalDialog = false
                        type = 'interval'
                        duration = this.intervalObservationIntervalLength
                    } else {
                        this.timerIntervalDialog = true
                        type = 'observe'
                        duration = this.intervalObservationObservationDuration
                    }
                    this.countDownTimer(duration, type)
                }
            },
            stopTimer() {
                clearTimeout(this.timeoutLoop)
            },
        },
    }
</script>

<style lang="scss" scoped>

    .no-transition, .no-transition ::v-deep * {
        transition: all 0.05s linear !important;
    }

    .sidebar {
        transition: width 300ms;
        border-left: 1px #ECEDF1 solid;
        overflow: hidden;
    }

    .collapse-btn {
        transition: right 300ms;
        position: absolute;
        bottom: 40px;
        border-radius: 50% !important;
        width: 32px;
        height: 32px;
        transform: translate(-50%, 0);
    }

    .rotated {
        padding-top: 12px;
        white-space: nowrap;
        transform: rotate(90deg);
    }

    .disabled {
        background-color: #eee !important;
        color: #777;
        pointer-events: none;
        cursor: not-allowed;
    }

    .title-text {
        font-size: 18px;
    }

    ::v-deep .fe-color-selector-card {
        border: 0 !important;
    }

    .timer-icon {
        font-size: 64px;
        position: absolute;
        color: #006C90;
        left: 90px;
        top: 40px;
    }

    .timer-text {
        font-size: 24px;
        position: relative;
        color: #006C90;
        text-align: left;
        padding-left: 50px;
    }

    .timer-subtext {
        font-size: 16px;
        position: relative;
        padding-top: 40px;
    }

</style>
