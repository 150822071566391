<template>
  <div>
    <h3 style="font-size: 18px; margin-bottom: 4px;">Review Students, Guardians, & Forms</h3>
    <div>
      Review the students, guardian contacts, and forms to send below. Email addresses for guardians are from the
      <a @click="$router.push('/Uploads')">Student Guardian Upload.</a>
    </div>
    <div style="margin-top: 32px;">
      Students: <span class="count" v-text="studentsCount"></span>
      Guardian Contacts: <span class="count" v-text="guardianContactsCount"></span>
      smartFORMS: <span class="count" v-text="smartformsCount"></span>
    </div>
    <fe-grid
      style="height: 56vh; margin-top: -38px; width: 90vw;"
      class="flex-grow-1 grid"
      :columnDefs="columnDefs"
      :rowData="rowData"
      domLayout="normal"
      :showAddRowBtn="false"
      :gridOptions="gridOptions"
      :showDownload="false"
      :showToolbarMenu="false"
      :showScrollbars="true"
      :searchBar="false"
    >
    </fe-grid>
    <fe-btn
      style="margin-top: 16px; margin-left: -8px;"
      usage="primary"
      @click="$emit('goToCommunicationSettings')"
    >
      Continue
    </fe-btn>
  </div>
</template>

<script>
  export default {
    name: 'ReviewEmail',
    props: ['studentsCount', 'guardianContactsCount', 'smartformsCount', 'columnDefs', 'rowData'],
    data() {
      return {
        gridOptions: {
          defaultColDef: {
            flex: 1,
            resizable: true
          }
        }
      }
    }
  }
</script>
