var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-fill flex-column no-scroll"},[_c('div',{staticClass:"headline"},[_vm._v("Permissions")]),_c('fe-grid',{ref:"grid",staticStyle:{"height":"100%"},attrs:{"columnDefs":_vm.columnDefs,"rowData":_vm.rowData,"showAddRowBtn":false,"displayDensity":"medium","disableInlineCreate":""},on:{"beginCreate":function($event){_vm.showAddDialog=true},"cellValueChanged":function($event){return _vm.updateRec($event.data)}},scopedSlots:_vm._u([{key:"cellDialog",fn:function({cell}){return [(cell.colDef.cellRendererFramework=='FeDialogColumn')?_c('simple-grid-dialog-column',{attrs:{"cell":cell,"title":cell.data.name,"deleteTool":1,"field":_vm.dialogColumnDefs[cell.colDef.field].column1.field,"fieldHeader":_vm.dialogColumnDefs[cell.colDef.field].column1.headerName,"columnDefs":_vm.dialogColumnDefs[cell.colDef.field].columnDefs && _vm.dialogColumnDefs[cell.colDef.field].columnDefs,"crudConfig":_vm.dialogColumnDefs[cell.colDef.field].model,"fieldConfig":_vm.dialogColumnDefs[cell.colDef.field].fieldConfig,"buildInsertRec":_vm.dialogColumnDefs[cell.colDef.field].buildRec,"width":_vm.dialogColumnDefs[cell.colDef.field].width,"height":_vm.dialogColumnDefs[cell.colDef.field].height,"insertDefaults":cell.data,"readParams":{user_group_id:cell.data.id}},on:{"close":function($event){_vm.$refs.grid.openMenu = false},"created":function($event){return _vm.onCreated(cell)},"destroyed":function($event){return _vm.onDestroyed(cell)}}}):_vm._e()]}}])}),_c('fe-crud',{ref:"crud",attrs:{"autoload":"","config":_vm.$models.security},on:{"read":function($event){_vm.rowData = $event.filter(x => {
            //Feature flag guardian email permissions
            if (!_vm.guardianEmailDisplay && x.code === 'SEND_GUARDIAN_FORM_EMAIL') {
                return false
            }
            return x.code !== 'MANAGE_OTHERS_ECASSESSMENT' && x.code !== 'MANAGE_STUDENTS'
        })}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }