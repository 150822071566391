<template>
    <div class="demographic-container">
        <v-dialog
            v-model="showAttachments"
            height="500"
            width="900"
            :footer="false"
            @close="showAttachments=false"
            content-class="ec-student-profile-show-attachments"
        >
            <v-card v-if="showAttachments">
                <v-card-title>
                    <div class="d-flex" style="width: 100%;">
                        <div class="d-flex align-center">Student Attachments</div>
                        <v-spacer/>
                        <fe-icon-btn class="close-icon" useIcon="fal fa-times" @click="showAttachments=false"/>
                    </div>
                </v-card-title>
                <v-card-text>
                    <student-attachment :studentInfo="{student_id: this.studentRec.student_id}"/>
                </v-card-text>
            </v-card>
        </v-dialog>

        <fe-dialog
            title="Assigned Staff"
            v-if="showSubscriptions"
            height="500"
            width="900"
            :footer="false"
            @close="showSubscriptions=false"
        >
            <user-subscriptions :id="this.studentRec.student_id"/>
        </fe-dialog>

        <div class="d-flex pl-2 pt-2 pb-2" style="border-bottom: 1px solid #E0E1E6;">

            <fe-card
                headerText="Student Information"
                width="100%"
                :outlined="false"
            >
                <template v-slot:title-toolbar>
                    <menu-btn>
                        <v-list-item @click="onShowPasswordDialog">
                            Set Student Password
                        </v-list-item>
                    </menu-btn>
                </template>

                <template v-slot:body>
                    <!-- Student Image -->
                    <v-row align="center" justify="center">
                        <v-avatar size="128" class="mt-5">
                            <v-img
                                :key="imageIdx"
                                :src="getStudentUrl"
                                alt="Student Image"
                                :options="{'use-credentials': true }"
                            />
                        </v-avatar>
                    </v-row>

                    <!-- Student Demographics -->
                    <div class="ma-2">
                        <div class="d-flex flex-wrap mb-3" v-if="rec">
                            <div class="demographic"> DOB: {{ getVal(rec.date_of_birth) }} </div>
                            <div class="demographic"> Gender: {{ getVal(rec.gender) }} </div>
                            <div class="demographic"> Ethnicity: {{ getVal(rec.ethnicity) }} </div>
                            <div class="demographic"> {{ getVal(rec.school_name) }}</div>
                            <div class="demographic"> {{ getVal(rec.grade_desc) }} Grade</div>
                            <div class="demographic" v-if="homeLanguage !== 'Not Found'"> Home Language: {{ homeLanguage }} </div>
                            <div v-if="ellStatusLabel" class="demographic"> ELL Status: {{ ellStatusLabel }}</div>
                            <div v-if="elpLevel" class="demographic"> ELP Level: {{ elpLevel }}</div>

                            <div
                                v-for="(demo, idx) in visibleDemographicLabels"
                                :key="`student-demographic-${idx}`"
                                class="demographic"
                                v-html="demo"
                                v-show="idx == 0 || unhideDemographics"
                            />

                            <a
                                v-if="visibleDemographicLabels.length > 1"
                                href="#!"
                                @click="unhideDemographics = !unhideDemographics"
                                class="hideToggle"
                            >
                                Show {{ unhideDemographics ? 'Less' : 'More' }}
                            </a>
                        </div>

                        <div v-else class="not-rostered">
                            Not rostered in selected year
                        </div>

                        <div v-for="(btn, index) in buttons" class="detail-item" :key="`detail-item-`+index">
                            <fe-tooltip v-if="index === 0" tooltip="View Student Data" style="display:inline-block">
                                <v-flex @click="btn.handler">
                                    <v-icon class="detail-item-icon">{{btn.icon}}</v-icon> {{btn.text}}
                                </v-flex>
                            </fe-tooltip>
                            <v-flex v-if="index !== 0" @click="btn.handler">
                                <v-icon class="detail-item-icon">{{btn.icon}}</v-icon> {{btn.text}}
                            </v-flex>
                        </div>

                        <student-data-manager
                            v-if="showStudentManagerWindow"
                            @showStudentManagerClicked="showStudentManagerClicked"
                            @updateStudentImage="imageIdx++"
                            @updateDemographics="loadDemographics"
                            :selectedStudent="selectedStudent"
                        />
                    </div>
                </template>
            </fe-card>
        </div>

        <fe-crud
            ref="crudStudentLogin"
            :config="$models.studentLogin"
            :defaultParams="{ student_id: studentRec.student_id, property: 'login' }"
        />

        <fe-dialog
            v-if="showPasswordDialog"
            :acceptButtonText="isSaving ? 'Saving. Please wait...' : 'Save'"
            :acceptButtonDisabled="!isStudentLoginInfoValid || isSaving"
            dismissButtonText="Cancel"
            :dismissButtonDisable="isSaving"
            disableAutoclose
            @dismiss="onClose"
            @accept="onSavePassword"
        >
            <v-layout column>
                <p>Creating a student login will give the student access to the DPR app and psPortal if your district has it enabled.</p>
                <p>The student can access psPortal and DPR at https://www.educlimber.com/</p>

                <v-row class="flex-grow-1 mt-3">
                    <v-col cols="12">
                        <v-form ref="studentLoginForm" :disabled="isSaving" v-model="isStudentLoginInfoValid">
                        <div class="d-flex">
                            <fe-label class="label">Login</fe-label>
                            <v-text-field
                                v-model="studentLogin.login"
                                flat solo dense
                                placeholder="Create user name"
                                prependInnerIcon="far fa-user-circle"
                                :rules="[rules.requiredRule, rules.requiredLength6]"
                            />
                        </div>
                        <div class="d-flex">
                            <fe-label class="label">Password</fe-label>
                            <v-text-field
                                v-model="studentLogin.password"
                                flat solo dense
                                type="password"
                                :placeholder="studentLogin.id ? '****************' : 'Create password'"
                                prependInnerIcon="far fa-lock"
                                autocomplete="new-password"
                                :rules="studentLogin.id ? [rules.optionalPasswordLength] : [rules.requiredRule, rules.requiredPasswordLength]"
                                @input="$refs.studentLoginForm.validate()"
                            />
                        </div>
                        <div class="d-flex">
                            <fe-label class="label">Confirm</fe-label>
                            <v-text-field
                                v-model="studentLogin.passwordConfirm"
                                flat solo dense
                                type="password"
                                :placeholder="studentLogin.id ? '****************' : 'Reenter password'"
                                prependInnerIcon="far fa-lock"
                                autocomplete="new-password"
                                :rules="(studentLogin.id && !studentLogin.password) ? [rules.optionalPasswordLength, rules.requiredPasswordMatch] : [rules.requiredRule, rules.requiredPasswordLength, rules.requiredPasswordMatch]"
                                @input="$refs.studentLoginForm.validate()"
                            />
                        </div>
                        <div class="d-flex">
                            <fe-label class="label">Email</fe-label>
                            <v-text-field
                                v-model="studentLogin.emailAddr"
                                flat solo dense
                                placeholder="Enter email (Optional)"
                                prependInnerIcon="far fa-envelope"
                                :rules="[]"
                            />
                        </div>
                        </v-form>
                    </v-col>
                </v-row>
            </v-layout>
        </fe-dialog>
    </div>
</template>

<script>
    import Vuex, { mapState } from 'vuex'
    import MenuBtn from '@/components/common/button/MenuBtn'
    import StudentDataManager from '@/components/modules/student/Index'
    import StudentAttachment from '../studentprofile/attachments/Index'
    import UserSubscriptions from "./UserSubscriptions"

    export default {
        name: 'StudentDemographics',
        components: {
            MenuBtn,
            StudentDataManager,
            StudentAttachment,
            UserSubscriptions
        },

        watch: {
            studentRec: {
                deep: true,
                handler(v) {
                    this.loadData()
                }
            },
            showAttachments(v) {
                if (!v) {
                    this.$modelGet('studentAttachment', { student_id: this.studentRec.student_id }).then(records => {
                        this.buttons[1].text = 'Attachments ' + records.length
                    })
                }
            },
            showSubscriptions(v) {
                if (!v) {
                    this.$modelGet('userStudentSubscription', { student_id: this.studentRec.student_id }).then(records => {
                        this.buttons[2].text = 'Assigned Staff ' + records.length
                    })
                }
            },
        },
        computed: {
            ...mapState('global', ['sessionUser', 'currentYear']),
            getStudentUrl() {
                return this.$axios.defaults.baseURL + this.$models.getUrl('studentImage', 'read') + '&id=' + this.studentRec.student_id
            },
            allDemographics () { return this.$store.getters['global/demographicsViewable'] },
            visibleDemographicLabels () {
                return this.studentDemographics.map(sd => {
                    let label = null
                    this.allDemographics.forEach(dmo => {
                        if (dmo.id == sd.demographic_id && dmo.display_student_profile) {
                            let val = null
                            if (dmo.value_type == 'string') val = sd.string_value
                            else if (dmo.value_type == 'number') val = sd.number_value === null ? null : `${sd.number_value}`.trim('0').trim('.')
                            else if (dmo.value_type == 'boolean') val = sd.boolean_value === null ? null : sd.boolean_value == 1 ? 'Yes' : 'No'
                            else if (dmo.value_type == 'date') val = sd.date_value
                            else if (dmo.value_type == 'option') val = sd.demographic_option_id === null ? null : dmo.options.find(opt => opt.id == sd.demographic_option_id)?.display_name || dmo.options.find(opt => opt.id == sd.demographic_option_id)?.value
                            if (val !== null && val !== '') label = `${dmo.name}: ${val}`
                        }
                    })
                    return (label) ? label : null
                }).filter(lbl => !!lbl)
            },
            ellStatusLabel () {
                if (this.rec.ell_flag === 1) return 'Yes'
                else if (this.rec.ell_flag === 0) return 'No'
                else return null
            },
            rules () {
                return {
                    requiredRule: v => !_.isEmpty(v) || 'This field is required',
                    requiredLength6: v => !_.isEmpty(v) && v.length >= 6 || 'The minimum length for this field is 6',
                    requiredPasswordLength: v => !_.isEmpty(v) && v.length >= this.$store.state.global.shareableStores.pml || ('The minimum length for this field is ' + this.$store.state.global.shareableStores.pml),
                    optionalPasswordLength: v => !v || v.length >= this.$store.state.global.shareableStores.pml || ('The minimum length for this field is ' + this.$store.state.global.shareableStores.pml),
                    requiredPasswordMatch: v => !v || v === this.studentLogin.password || 'The passwords you entered must match',
                }
            },
        },
        props: {
            studentRec: {
                default: false
            },
            selectedStudent: { },
            elpLevel: { }
        },
        mounted() {
            if (this.studentRec && this.studentRec.student_id) this.loadData()
        },
        data() {
            let that = this
            return {
                isSaving: false,
                rec: false,
                showAttachments: false,
                showSubscriptions: false,
                showPasswordDialog: false,
                studentLogin: {
                    id: null,
                    studentId: null,
                    login: null,
                    password: null,
                    passwordConfirm: null,
                    emailAddr: null
                },
                isStudentLoginInfoValid: true,
                unhideDemographics: false,
                buttons: [{
                    text: 'Student Data',
                    icon: 'fas fa-id-card-alt',
                    handler() {
                        that.showStudentManagerWindow = true
                    }
                }, {
                    text: 'Attachments',
                    icon: 'fas fa-paperclip',
                    handler() {
                        that.showAttachments=true
                    }
                }, {
                    text: 'Assigned Staff',
                    icon: 'fas fa-bullhorn',
                    handler() {
                        that.showSubscriptions = true
                    }
                }],
                showStudentManagerWindow: false,
                imageIdx: 0,
                studentDemographics: [],
                homeLanguage: 'Not Found',
            }
        },
        methods: {
            getVal(v) {
                return v ? v : 'Not Found'
            },
            showStudentManagerClicked(e) {
                this.showStudentManagerWindow = e
            },

            updateIds(ids) {
                this.selectedIds = ids
            },

            loadDemographics() {
                this.$axios.get(`studentDemographics.php?action=get&single_value=1&student_id=${this.studentRec.student_id}&school_year_id=${this.studentRec.school_year_id}`).then(rsp => {
                    if (rsp.data?.student_demographic) this.studentDemographics = rsp.data.student_demographic
                })
            },
            onClose() {
                this.isSaving = false
                this.studentLogin.password = null
                this.studentLogin.passwordConfirm = null
                this.showPasswordDialog = false
            },
            onShowPasswordDialog() {
                this.showPasswordDialog = true
                this.$refs.crudStudentLogin.read().then(res => {
                    if (res.data.student_logins.length > 0) {
                        this.studentLogin.id = res.data.student_logins[0].id
                        this.studentLogin.login = res.data.student_logins[0].login
                        this.studentLogin.emailAddr = res.data.student_logins[0].email_addr

                        this.$refs.studentLoginForm?.validate()
                    }
                })
            },

            onSavePassword() {
                this.isSaving = true
                if (this.studentLogin.id) {
                    this.$refs.crudStudentLogin.update({
                        id: this.studentLogin.id,
                        student_id: this.studentRec.student_id,
                        temp_pswd: this.studentLogin.password,
                        login: this.studentLogin.login,
                        email_addr: this.studentLogin.emailAddr
                    }).then(res => {
                        this.onClose()
                    }).catch(err => {}).finally(() => this.isSaving = false)
                } else {
                    this.$refs.crudStudentLogin.create({
                        student_id: this.studentRec.student_id,
                        temp_pswd: this.studentLogin.password,
                        login: this.studentLogin.login,
                        email_addr: this.studentLogin.emailAddr
                    }).then(res => {
                        this.onClose()
                    }).catch(err => {}).finally(() => this.isSaving = false)
                }
            },

            loadData() {
                let me = this
                let params = this.$objectToParams(this.studentRec)

                this.$store.dispatch('global/loadDemographics', {
                    // No need to constantly re-fetch this rarely-changing data over and over
                    cacheUntilMoment: this.$dayjs().add(15, 'm'),
                })

                this.loadDemographics()

                // Reset count-ish texts pending data refresh
                this.buttons[1].text = 'Attachments 0'
                this.buttons[2].text = 'Assigned Staff 0'

                this.$axios.get('/api/core/student/profile/bio?' + params).then(res => {
                    // Update rec to populate misc. sidebar data details
                    if (!res.data?.bio_data?.details?.students?.length) {
                        this.rec = false
                    } else {
                        this.rec = res.data.bio_data.details.students[0]
                    }

                    // Update attachment and subscription counts
                    this.buttons[1].text = `Attachments ${res.data?.bio_data?.attachments?.length || 0}`
                    this.buttons[2].text = `Assigned Staff ${res.data?.bio_data?.assignedStaff?.length || 0}`

                    this.loadHomeLanguages()
                })
            },

            loadHomeLanguages() {
                this.$modelGet('homeLanguage')
                    .then(response => {
                        this.homeLanguage = this.rec?.home_language_id ? response.find(x => x.id == this.rec.home_language_id).home_language_display : 'Not Found'
                    })
            },
        }
    }
</script>

<style lang="scss">
.ec-student-profile-show-attachments {
    overflow: visible;
}
</style>

<style lang="scss" scoped>
.label {
    width: 125px;
    font-size: 14px;
}

.demographic-container {
    width: 270px;
    background: #FFFFFF;
    border: 1px solid #E0E1E6;
    box-sizing: border-box;
    border-radius: 5px;
}

.not-rostered {
    padding: 16px;
    font-size: 16px;
}

.card-header {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 8px;
}

.title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding-top: 4px;
    padding-left: 6px;
}

.demographic {
    background: #ECEDF1;
    border-radius: 3px;
    padding: 6px 8px 6px 8px;
    margin: 3px;
    font-size: 12px;
    align-self: flex-start;
}

.detail-item {
    font-size: 14px;
    line-height: 18px;
    color: #152452;
    padding: 6px;
    cursor: pointer;
}

.detail-item-icon {
    color: #152452;
    margin-right: 8px;
}

.hideToggle {
    margin-top: 4px;
    text-decoration: none;
    font-size: 11px;
    line-height: 18px;
    padding: 0 4px;
    margin-top: 8px;
}

.close-icon {
    ::v-deep i {
        font-size: 24px !important;
    }
}

</style>
