<template>
    <div class="d-flex flex-fill flex-column">
        <div class="d-flex">
            <fe-filter-btn
                :items="windows"
                itemText="displayName"
                itemValue="id"
                v-model="compare.start_data_point_name_id"
                title="From Window"
                :multiple="false"
            />

            <fe-filter-btn
                :items="windows"
                itemText="displayName"
                itemValue="id"
                v-model="compare.end_data_point_name_id"
                title="To Window"
                :multiple="false"
            />

            <fe-filter-btn
                :items="targetSets"
                itemText="name"
                itemValue="id"
                v-model="compare.target_set_id"
                title="Default Performance Band"
                :multiple="false"
            />

            <fe-btn usage="primary" :disabled="!canSearch" @click="load">Search</fe-btn>
        </div>

        <fe-dialog
            title="Students"
            v-if="dialog.show"
            @accept="closeDialog"
            @close="closeDialog"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Done"
            width="900"
        >
            <div class="d-flex flex-fill flex-row" style="height: 500px;">
                <fe-grid
                    :rowData="dialog.students"
                    :columnDefs="dialogColumns"
                    displayDensity="small"
                    style="height: 100%;"
                    :showAddRowBtn="false"
                    ref="grid"
                    @selectionChanged="selectionChanged"
                    @cellClicked="cellClicked"
                >
                    <template #toolbar-items>
                        <fe-btn usage="secondary" :disabled="selections.length===0" @click="tagStudents">Tag</fe-btn>
                    </template>
                </fe-grid>

                <div v-if="!scoreDetails.length" class="empty-state-container pa-0">
                    <fe-overlay v-if="isLoadingScoreDetails" loader/>
                    <v-layout v-else align-center justify-center column fill-height text-center>
                        <img width="340px" src="/images/rocket.png"/>
                        <div class="subheading">
                            <template v-if="isScoreDetailsLoaded">
                                The student has no Score Details.
                            </template>
                            <template v-else>
                                Select a student to show the Score Details.
                            </template>
                        </div>
                    </v-layout>
                </div>

                <fe-grid
                    v-if="scoreDetails.length"
                    ref="detailsGrid"
                    :columnDefs="detailsColumnDefs"
                    :rowData="scoreDetails"
                    :searchBar="false"
                    :showAddRowBtn="false"
                    :showDownload="false"
                    :showToolbar="false"
                    displayDensity="small"
                    class="d-flex flex-fill pa-0"
                />
            </div>

            <fe-crud
                ref="crud"
                :config="$models.studentScoreDetail"
                @read="onScoreDetailsRead"
            />
        </fe-dialog>

        <div class="d-flex flex-fill flex-column" ref="chartContainer">
            <fe-empty-state class="empty-state" v-if="!showChart" image="/images/empty-state-horn.png" :text="emptyStateMessage" header="Effectiveness"></fe-empty-state>
            <chart-panel v-if="showChart">
                <template #tools>
                    <v-menu>
                        <template v-slot:activator="{ on }">
                            <fe-icon-btn v-on="on" small useIcon="fas fa-lightbulb"/>
                        </template>

                        <v-card>
                            <v-card-text>
                                <div v-for="d in raw.data" class="d-flex">
                                    <div class="descriptor">
                                        {{d.target_descriptor_name}}
                                    </div>

                                    <div class="desc">
                                        <v-icon color="green">fas fa-arrow-circle-up</v-icon>
                                        {{getPct(d.count_up, d.count)}} moved up
                                    </div>

                                    <div class="desc">
                                        <v-icon color="gray">fas fa-minus-circle</v-icon>
                                        {{getPct(d.count_same, d.count)}} stayed the same
                                    </div>

                                    <div class="desc">
                                        <v-icon color="red">fas fa-arrow-circle-down</v-icon>
                                        {{getPct(d.count_down, d.count)}} moved down
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                    <fe-icon-btn small useIcon="fas fa-print" @click="$refs.chart.chart.print()"/>
                </template>

                <highcharts ref="chart" class="fe-chart" :options="chartOptions"/>
            </chart-panel>
        </div>
    </div>
</template>

<script>
    import ChartPanel from './ChartPanel'
    import windowOptionsMixin from '@/mixins/windowOptions'
    export default {
        name: 'ProgramEffectiveness',
        mixins: [ windowOptionsMixin ],
        components: {
            ChartPanel
        },
        props: {
            params: {
                required: true
            }
        },
        data() {
            let me = this

            return {
                dialog: {
                    show: false,
                    students: []
                },
                emptyStateMessage: 'Select a <b>From Window</b> to <b>To Window</b> window to search for data',
                showChart: false,
                selections: [],
                dialogColumns: [{
                    colId: "checkbox",
                    headerName: null,
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    pinned: "left",
                    width: 70
                }, {
                    headerName: "Student",
                    field: "student_full_name",
                    width: 350
                }, {
                    headerName: "Score",
                    field: "score",
                    width: 100,
                    cellStyle: {
                        textAlign: 'right'
                    }
                }, {
                    headerName: "School",
                    field: "school_name",
                    hide: true
                }, {
                    headerName: "Grade",
                    field: "grade_desc",
                    hide: true
                }, {
                    headerName: "Gender",
                    field: "gender",
                    hide: true
                }, {
                    headerName: "Ethnicity",
                    field: "ethnicity",
                    hide: true
                }, {
                    headerName: "Hispanic",
                    field: "hispanic",
                    hide: true
                }, {
                    headerName: "ELL",
                    field: "ell_level_name",
                    hide: true
                }, {
                    headerName: "Meal Status",
                    field: "meal_status_name",
                    hide: true
                }],
                url: 'targetScores.php?action=get&property=crosswalk&',
                windows: [],
                targetSets: [],
                scoreDetails: [],
                isLoadingScoreDetails: false,
                isScoreDetailsLoaded: false,
                detailsColumnDefs: [
                    {
                        headerName: "Description",
                        field: "score_detail_type_code",
                        sortable: true,
                        editable: false,
                        width: 210
                    },
                    {
                        headerName: "Value",
                        field: "score_detail_detail_value",
                        sortable: true,
                        editable: false,
                        width: 100
                    }
                ],
                compare: {},
                raw: {},
                chartOptions: {
                    title: {
                        text: 'Effectiveness'
                    },
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                }
            }
        },
        computed: {
            canSearch() {
                return this.compare.start_data_point_name_id && this.compare.end_data_point_name_id ? true : false
            }
        },
        created() {
            this.$modelGet('dataPointName', { data_point_type_id: this.params.data_point_type_id, active: 1})
                .then(r => {
                    this.windows = r
                    this.windows.forEach(w => w.displayName = w.alias ? w.alias : w.name)
                })

            this.$modelGet('targetSet', { data_point_type_id: this.params.data_point_type_id, active: 1})
                .then(r => this.targetSets = r)
        },
        methods: {
            getPct(count, total) {
                if (!total) return '0%'

                return ((count/total)*100).toFixed(1)+'% ('+count+')'
            },
            tagStudents() {
                let me = this
                this.$store.commit('global/tagStudents', {
                    show: true,
                    students: this.selections,
                    callback() {
                        me.$refs.grid.gridApi.deselectAll()
                    }
                })
            },
            handleFromClick(rec) {},
            handleToClick(from, to) {
                let p = {...to.params, ...this.params}
                p.school_year_id = to.school_year_id
                p.previous_data_point_id = from.params.data_point_id
                p.previous_target_descriptor_id = from.params.target_descriptor_id
                p.include_demographics = 1
                this.$axios.get('targetScores.php?action=get&property=students&'+this.$objectToParams(p, true))
                    .then(r => {
                        this.dialog.show=true
                        this.dialog.students = this.$ecResponse(r, 'categories')
                    })
            },
            cellClicked(cell) {
                let prevSelected = cell.api.getSelectedNodes()

                // Nothing was previously clicked, so auto-check this cell
                if (prevSelected.length === 0) {
                    cell.node.setSelected(true)
                }

                    // Clicking another row when exactly 1 previous row as clicked will
                    // move the single checkbox to the new row (if a different row),
                // unless the user specifically clicks on the checkbox column
                else if (prevSelected.length === 1) {
                    if (cell.column?.colId == 'checkbox') {
                        cell.node.setSelected(!cell.node.isSelected())
                    } else {
                        prevSelected[0].setSelected(false)
                        if (prevSelected[0].id !== cell.node.id) {
                            cell.node.setSelected(true)
                        }
                    }
                }

                    // If more than 1 row is already selected, then clicking anywhere
                    // on an additional row will simply toggle its checkbox state
                // without affecting the multiple checked rows
                else {
                    cell.node.setSelected(!cell.node.isSelected())
                }
            },
            selectionChanged() {
                let selected = this.$refs.grid.gridApi.getSelectedRows()
                this.selections = selected
                this.tagEnabled = !!selected.length

                if (selected.length === 1 && selected[0].data_point_score_id) {
                    this.scoreDetails = []
                    this.isLoadingScoreDetails = true
                    this.$refs.crud.read({data_point_score_id: selected[0].data_point_score_id})
                } else if (selected.length !== 1) {
                    this.scoreDetails = []
                    this.isLoadingScoreDetails = false
                    this.isScoreDetailsLoaded = false
                } else {
                    this.isScoreDetailsLoaded = true
                }
            },
            onScoreDetailsRead(data) {
                data = data.sort((a, b) => {
                    if (a.score_detail_type_id < b.score_detail_type_id) {
                        return -1
                    } else if (a.score_detail_type_id > b.score_detail_type_id){
                        return 1
                    } else {
                        // Shouldn't be needed for score_detail_type_id, but it won't hurt to leave it
                        return 0
                    }
                })
                this.scoreDetails = data
                this.isLoadingScoreDetails = false
                this.isScoreDetailsLoaded = true // helps differentiate between [] no data for a given student and [] never even tried yet
            },
            closeDialog() {
                this.scoreDetails = []
                this.isLoadingScoreDetails = false
                this.isScoreDetailsLoaded = false
                this.dialog.show = false
            },
            load() {
                let me = this
                let p = {...this.params, ...{
                    start_data_point_name_id: this.compare.start_data_point_name_id.included[0].id,
                    end_data_point_name_id: this.compare.end_data_point_name_id.included[0].id,
                    target_set_id: this.compare.target_set_id ? this.compare.target_set_id.included[0].id : null
                }}

                this.$setLoading(true)
                this.$axios.get(this.url+this.$objectToParams(p, true))
                    .then(r => {
                        this.$setLoading(false)
                        this.raw = this.$ecResponse(r, 'data')
                        let arr = []
                        let NAarr = []
                        let descriptors = {}

                        if (!this.raw.data.length) {
                            this.showChart=false
                            return
                        }
                        this.showChart=true

                        // get all the available target descriptors that are present
                        let childDescriptors = []
                        this.raw.data.forEach(parent => {
                            parent.to_descriptors.forEach(child => {
                                let c = childDescriptors.find(r=>r.target_descriptor_id===child.target_descriptor_id)
                                if (!c) childDescriptors.push(child)
                            })
                        })

                        this.raw.data.forEach(parent => {
                            let from = parent.target_descriptor_name + ' ('+parent.data_point_name+')'
                            descriptors[from] = {
                                name: from,
                                color: parent.target_descriptor_color,
                                rank: parent.target_descriptor_rank,
                                rec: parent
                            }

                            this.$_.orderBy(Object.values(childDescriptors), 'target_descriptor_rank', 'ASC').forEach(desc => {
                                let to = desc.target_descriptor_name
                                if (desc.data_point_name) {
                                    to += ' ('+desc.data_point_name+')'
                                }
                                descriptors[to] = {
                                    name: to,
                                    color: desc.target_descriptor_color,
                                    rank: desc.target_descriptor_rank,
                                    rec: desc
                                }

                                let c = parent.to_descriptors.find(r => r.target_descriptor_id === desc.target_descriptor_id)
                                if (c) {
                                    if (to != 'NA') {
                                        arr.push([from, to, parseInt(c.count)])
                                    } else {
                                        NAarr.push([from, to, parseInt(c.count)])
                                    }
                                } else {
                                    if (to != 'NA') {
                                        arr.push([from, to, 0])
                                    } else {
                                        NAarr.push([from, to, 0])
                                    }
                                }
                            })
                        })

                        let nodes = this.$_.orderBy(Object.values(descriptors), 'target_descriptor_rank', 'ASC').map((d,i) => { return {
                            id: d.name,
                            color: d.color,
                            rec: d.rec
                        }})

                        this.chartOptions = {
                            chart: {
                                height: this.$refs.chartContainer.clientHeight-10
                            },
                            title: {
                                text: 'Program Effectiveness'
                            },
                            accessibility: {
                                point: {
                                valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.'
                                }
                            },
                            plotOptions: {
                                series: {
                                    cursor: 'pointer',
                                    point: {
                                        events: {
                                            click: function(e) {
                                                if (e.point.fromNode) {
                                                    // transition click
                                                    me.handleToClick(e.point.fromNode.rec, e.point.toNode.rec)
                                                } else {
                                                    // base click
                                                    // this does nothing...
                                                    me.handleFromClick(e.point.rec)
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            tooltip: {
                                formatter() {
                                    if (!this.point.fromNode) {
                                        return this.point.name+': '+this.point.sum
                                    }
                                    let from = this.point.fromNode?.sum
                                    let pct  = ((this.point.weight/from)*100).toFixed(1)
                                    return this.point.from + ' to ' + this.point.to + ': <b>' + this.point.weight + ' (' + pct + '%)</b>'
                                }
                            },
                            exporting: {
                                enabled: false
                            },
                            credits: {
                                enabled: false
                            },
                            series: [{
                                nodes: nodes,
                                keys: ['from', 'to', 'weight'],
                                data: arr.reverse().concat(NAarr), // NA values always at the bottom of the chart without affecting the rest of the chart
                                type: 'sankey',
                                name: 'Effectiveness'
                            }]
                        }
                    })
            }
        },
    }
</script>

<style lang="scss" scoped>
.fe-chart ::v-deep * {
    font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
}

.empty-state {
    margin-top: 50px;
    ::v-deep img {
        height: 200px;
    }
}

.descriptor {
    width: 150px;
    font-weight: bold;
}
.desc {
    min-width: 190px;
    margin-right: 8px;
}
</style>
