<template>
    <div class="search-container">
        <fe-student-card
            v-if="selectedOption"
            ref="studentCard"
            style="position: absolute; z-index: 10;"
            :style="[{left: cardLeft, top: cardTop}]"
            :imageUrl="studentImageURL"
            :studentRecord="studentCardRecord ? studentCardRecord : {}"
            v-show="showStudentCard"
            fullNameField="student_full_name"

            schoolField="school_name"
            gradeField="grade_desc"
            genderField="gender"
            @close="showStudentCard = false"
        ></fe-student-card>

        <div class="student-text-search">
            <div class="page-title">Find Students</div>

            <div>
                <fe-title-select
                    fontSize="16px"
                    :items="schoolYears"
                    itemValue="id"
                    itemText="name"
                    v-model="selectedSchoolYear"
                    return-object
                />
            </div>

            <div>
                <v-autocomplete
                    :disabled="!selectedSchoolYear"
                    v-model="selectedStudent"
                    :search-input.sync="searchText"
                    flat solo dense
                    placeholder="Search for students (3 characters minimum)"
                    class="mt-5 ml-3"
                    :items="searchResults"
                    itemText="student_full_name"
                    itemValue="student_id"
                    return-object
                    :no-data-text="noSearchString ? '' : 'No data available'"
                    :hide-no-data="noSearchString"
                >
                    <template v-slot:item="data">
                        <v-list-item-avatar>
                            <v-img :src="getStudentImg(data.item.student_id)"/>
                        </v-list-item-avatar>

                        <v-list-item-content>

                            <v-list-item-title>{{data.item.student_full_name}}</v-list-item-title>
                            <v-list-item-subtitle class="d-flex">
                                <div>{{data.item.school_name}} {{data.item.grade_desc}}</div>
                                <v-spacer/>
                                <div>{{ data.item.school_year_name }}</div>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </div>

            <div class="d-flex justify-center my-10">
                <div class="divider"/>
                or
                <div class="divider"/>
            </div>
        </div>

        <div class="d-flex justify-center">

        </div>

        <v-slide-y-transition mode="out-in">
            <div v-if="!selectedOption" key="se-hide">
                <div class="category-search">
                    <div class="page-title">Find in Categories</div>

                    <div>
                        <fe-title-select
                            fontSize="16px"
                            :items="categorySchoolYears"
                            itemValue="id"
                            itemText="name"
                            v-model="selectedCategorySchoolYear"
                            return-object
                        />
                    </div>
                </div>

                <div class="d-flex flex-wrap justify-left" v-if="!selectedOption" style="margin: 0px auto 0px auto;">
                    <div class="search-option" v-for="opt in options" @click="select(opt)" :key="`stdnt-search-opt-`+opt.name">
                        <div class="search-option-title">
                            <v-icon>{{opt.icon}}</v-icon>{{ opt.name }} ({{ getCount(opt.data, opt.countMethod) }})
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="mx-5" key="se-show">
                <fe-btn useIcon="fas fa-chevron-left" usage="ghost" @click="selectedOption = null">Back to categories</fe-btn>

                <v-row class="d-flex flex-wrap">
                    <v-col cols="5" class="ma-2 search-option-item" v-for="(item, i) in selectedOption.data" :key="`so-`+i">
                        <span class="group-title" @click="loadGroup(item)">{{ item[selectedOption.itemText] || selectedOption.name }}</span>

                        <div class="student-images d-flex flex-wrap">
                            <template v-if="getIds(item).length">
                                <template v-for="(img, si) in getImgs(item).slice(0,8)">
                                    <div
                                        v-if="img.styles && !img.top_secret"
                                        :key="`student-id-img-${si}`"
                                        class="ma-2"
                                        style="border-radius: 50%; display: inline-block; width:64px; height:64px; overflow:hidden;"
                                        @click="selectedStudent = studentCardRecord"
                                        @mousemove="showCard(img.id, $event)"
                                        @mouseover="showCard(img.id, $event)"
                                        @mouseout="showStudentCard = false"
                                    >
                                        <div :style="img.styles"></div>
                                    </div>
                                    <v-img
                                        v-else
                                        :key="`student-id-img-ph-${si}`"
                                        class="ma-2"
                                        :src="`/images/default-student-img.png`"
                                        width="64"
                                        maxWidth="64"
                                        height="64"
                                        maxHeight="64"
                                        @click="selectedStudent = studentCardRecord"
                                        @mousemove="showCard(img.id, $event)"
                                        @mouseover="showCard(img.id, $event)"
                                        @mouseout="showStudentCard = false"
                                    />
                                </template>
                            </template>
                        </div>

                        <div class="student-count mt-3" v-if="item.student_ids">+ {{ getIds(item).slice(8).length }} More</div>
                    </v-col>
                </v-row>
            </div>
        </v-slide-y-transition>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import lodash from 'lodash'

    export default {
        name: 'StudentProfileSearch',
        watch: {
            selectedSchoolYear(v) {
                if (v) {
                    this.$emit('selectYear', v)
                    this.fetchData()
                }
            },
            selectedCategorySchoolYear(v) {
                if (v) {
                    this.$emit('selectYear', v)
                    this.fetchData()
                }
            },
            selectedStudent(v) {
                let r = {...v}
                r.student_fname = r.fname
                r.student_lname = r.lname

                this.selectedSchoolYear = { id: r.school_year_id }

                // Wait for the school year event to propagate up to parent
                this.$nextTick(() => {
                    this.$emit('studentSelect', [r])
                })
            },
            currentYear(v) {
                if (!v) return
                this.$data.selectedSchoolYear = v
                this.fetchData()
            },
            selectedOption(v) {
                this.showStudentCard = false
                let ids = []
                if (!v) return false
                ids = this.getIds(v.data).splice(0, 20)
                if (ids.length) {
                    this.$fetchStudentImages(ids, 64).then((obj) => this.imageObjs = obj.images)
                } else {
                    this.imageObjs = []
                }
            },
            searchText: lodash.debounce(function(v) {
                if (v && v.length >= 3) {
                    this.noSearchString = false
                    this.doSearch()
                } else {
                    this.noSearchString = true
                }
            }, 500)
        },
        computed: {
            ...mapState('global', ['sessionUser', 'currentYear', 'districtConfig'])
        },
        data() {
            let me = this
            return {
                imageObjs: [],
                showingId: null,
                selectedSchoolYear: null,
                selectedCategorySchoolYear: null,
                schoolYears: [],
                categorySchoolYears: [],
                selectedStudent: null,
                selectedOption: null,
                showStudentCard: false,
                studentCardRecord: null,
                studentImageURL: '',
                cardLeft: '0px',
                cardTop: '0px',
                selectedItem: null,
                searchResults: [],
                searchText: null,
                photoURL: null,
                studentImages: [],
                options: [{
                    icon: 'fas fa-chalkboard',
                    name: 'Course',
                    rootProperty: 'courses',
                    data: [],
                    itemValue: 'course_id',
                    itemText: 'display_name',
                    queryKeys: ['course_id', 'period'],
                    fetchData() {
                        let p = {
                            action: 'get',
                            school_year_id: me.selectedCategorySchoolYear.id,
                            user_id: me.sessionUser.user.id,
                            property: 'course_period'
                        }
                        return  'courseHistory.php?' + me.$objectToParams(p)
                    },
                    fetchStudents() {
                        let sel = me.selectedOption
                        let item = me.selectedItem

                        let p = {
                            course_id: item.course_id,
                            period: item.period,
                            school_year_id: me.selectedCategorySchoolYear.id,
                            user_id: me.sessionUser.user.id,
                            active_only: true,
                        }
                        let url = 'courseHistory.php?action=get&'+me.$objectToParams(p)
                        me.$axios.get(url)
                        .then(response => {
                            me.setStudents(response.data.courses)
                        })

                    }
                }, {
                    name: 'Interventions',
                    rootProperty: 'plans',
                    icon: 'fas fa-hands-heart',
                    data: [],
                    itemValue: 'id',
                    itemText: 'name',
                    rollUp(response) {
                        let data = []
                        response.data.plans.forEach(plan => {
                            let students = {
                                name: plan.name,
                                student_ids: '',
                                students: plan.students
                            }

                            plan.students.forEach(student => {
                                students.student_ids += student.student_id += ','
                            })

                            data.push(students)
                        })
                        me.options[1].data = data
                    },
                    fetchData() {
                        let p = {
                            action: 'get',
                            school_year_id: me.selectedCategorySchoolYear.id,
                            user_id: me.sessionUser.user.id,
                            property: 'list',
                            include_students: 1
                        }
                        return 'interventionPlan.php?' + me.$objectToParams(p)
                    },
                    fetchStudents() {
                        let sel = me.selectedOption
                        let item = me.selectedItem
                        let data = []
                        item.students.forEach(student => {
                            data.push({
                                student_id: student.student_id.replace(',',''),
                                student_full_name: student.student_full_name,
                                school_year_id: me.selectedCategorySchoolYear.id
                            })
                        })
                        me.setStudents(data)

                    }
                }, {
                    name: 'Students I Monitor',
                    icon: 'fas fa-chalkboard',
                    rootProperty: 'monitors',
                    itemText: 'name',
                    data: [],
                    countMethod: 'students',
                    rollUp(response) {
                        let data = {
                            name: 'Students I Monitor',
                            student_ids: ''
                        }
                        response.data.monitors.forEach(rec => {
                            data.student_ids += rec.student_id + ","
                        })
                        me.options[2].data = [data]
                        // opt.data = [data]
                    },
                    fetchData() {
                        let sel = me.selectedOption
                        let item = me.selectedItem

                        let p = {
                            academic: 1,
                            monitor_user_id: me.sessionUser.user.id,
                            school_year_id: me.selectedCategorySchoolYear.id,
                        }

                        return 'studentMonitors.php?action=get&' + me.$objectToParams(p)
                    },
                    fetchStudents() {
                        let sel = me.selectedOption
                        let item = me.selectedItem

                        let p = {
                            academic: 1,
                            monitor_user_id: me.sessionUser.user.id,
                            school_year_id: me.selectedCategorySchoolYear.id,
                        }

                        let url = 'studentMonitors.php?action=get&' + me.$objectToParams(p)

                        me.$axios.get(url)
                            .then(response => {
                                me.setStudents(response.data.monitors)
                            })

                    }
                }, {
                    name: 'Assigned Students',
                    icon: 'fas fa-chalkboard',
                    rootProperty: 'user_student_subscription',
                    fetchData() {
                        return `crud.php?action=get&property=UserStudentSubscription&school_year_id=${me.selectedCategorySchoolYear.id}`
                    },
                    fetchStudents() {
                        me.$axios.get(`crud.php?action=get&property=UserStudentSubscription&school_year_id=${me.selectedCategorySchoolYear.id}`)
                            .then(response => {
                                me.setStudents(response.data.user_student_subscription)
                            })
                    }
                }],
                noSearchString: true,
            }
        },
        mounted() {
            /* Note - there are currently performance issues related to searching all years
               Until those can possibly be resolved, all years is disabled.
            */
            // By default, search all school years for student text search
            //this.selectedSchoolYear = { id: null, name: 'All School Years' }

            // Searching by course, intervention, etc. shall not support an "all years" option
            if (this.currentYear?.id) {
                this.selectedSchoolYear = this.currentYear
                this.selectedCategorySchoolYear = this.currentYear
                this.fetchData()
            }

            this.$modelGet('schoolYear').then(v => {
                //this.schoolYears = [
                //    { id: null, name: 'All School Years' },
                //    ...v
                //]
                this.schoolYears = [
                    ...v
                ]

                this.categorySchoolYears = [...v]
            })
        },
        methods: {
            getCount(data, countMethod) {
                if (!data) return 0
                return (countMethod && countMethod == 'students')
                    ? this.getIds(data).length
                    : data.length
            },
            getIds(data) {
                let arr = Array.isArray(data) ? data : [data]
                let result = this.$_.flatten([
                    ...arr.filter(itm => itm.student_ids).map(itm => itm.student_ids.split(',')),
                    ...arr.filter(itm => itm.student_id).map(itm => [itm.student_id])
                ])

                return result.filter(id => id && `${id}`.length > 0 && id > 0)
            },
            getImgs(data) {
                return this.getIds(data).map(id => this.getStudentImage(id) || { id: id })
            },
            getStudentImage(id) {
                return this.imageObjs.find(x => x.id == id)
            },
            showCard(id, evt) {
                let screenWidth = window.innerWidth
                let screenHeight = window.innerHeight
                if (this.showingId != id) {
                    this.showingId = id
                    let p = {
                        id: id,
                        school_year_id: this.$data.selectedCategorySchoolYear.id
                    }

                    this.$data.studentImageURL = this.$axios.defaults.baseURL +
                        'students.php?action=get&property=student_image&id=' + id

                    this.$axios.get('students.php?action=get&property=grade_history&' + this.$objectToParams(p))
                        .then(response => {
                            this.$data.studentCardRecord = response.data[0]
                            this.$data.overStudentId = id
                            this.$data.cardTop   = (evt.clientY > (screenHeight - 250) ? evt.clientY - 250 : evt.clientY) + 'px'
                            this.$data.cardLeft  = (evt.clientX  > (screenWidth / 2) ? evt.clientX - 550 : evt.clientX) + 'px'
                            this.showStudentCard = true
                        })
                } else {
                    this.$data.cardTop   = (evt.clientY > (screenHeight - 250) ? evt.clientY - 250 : evt.clientY) + 'px'
                    this.$data.cardLeft  = (evt.clientX  > (screenWidth / 2) ? evt.clientX - 550 : evt.clientX) + 'px'
                    this.showStudentCard = true
                }
            },
            select(v) {
                this.$data.selectedOption = v
            },
            doSearch() {
                // Get the most recently applicable school year for each student
                let params = this.$objectToParams({
                    name: this.searchText,
                    school_year_id: this.selectedSchoolYear.id,
                })
                this.$axios.get(`students.php?action=get&property=search_student_profile&`+params)
                .then(response => {
                    this.$data.searchResults = response.data.students
                })
                .catch(error => {
                    console.error(error)
                })
            },
            fetchData() {
                this.options.forEach(opt => {
                    if (opt.fetchData) {
                        let url = opt.fetchData()

                        if (url) {
                            this.$axios.get(url)
                            .then(response => {
                                if (!opt.rollUp) {
                                    let data = this.$ecResponse(response, opt.rootProperty)
                                    opt.data = data
                                } else {
                                    opt.rollUp(response)
                                    // me.setStudents(response.data.monitor)
                                }
                            })
                        }
                    }
                })
            },
            loadGroup(item) {
                this.selectedItem = item

                this.selectedOption.fetchStudents()
            },
            setStudents(students) {
                this.$emit('studentSelect', students)
            },
            getStudentImg(student_id) {
                return this.$axios.defaults.baseURL + this.$models.getUrl('studentImage', 'read') + '&id=' + student_id
            },
        }
    }
</script>

<style lang="scss" scoped>
.divider {
    display: inline-block;
    border-bottom: 1px solid #E0E1E6;
    width: 60px;
    margin: 0px 30px 0px 30px;
    height: 12px;
}

.search-container {
    border-radius: 5px;
    margin: 8px;
    padding: 16px;
    max-width: 1000px;
    margin: 50px auto 20px auto;

    .student-text-search, .category-search {
        text-align: center;
        width: 600px;
        margin: 20px auto 20px auto;
    }

    .search-option {
        border: 1px solid #C0C3CF;
        border-radius: 4px;
        height: 70px;
        width: 262px;
        margin: 16px;
        cursor: pointer;

        &-title {
            line-height: 68px;
            padding-left: 16px;
            color: black;

            i {
                color: black;
                margin-right: 16px;
            }
        }
    }

    .student-images {
        min-height: 75px;
    }

    .student-count {
        position: relative;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: black;
        bottom: 8px;
    }

    .group-title {
        font-size: 14px;
        line-height: 18px;
        color: black;
        bottom: 8px;

        &:hover {
            text-decoration: underline;
        }
    }

}
.search-option-item {
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #C0C3CF;
    padding: 16px;
}
</style>
