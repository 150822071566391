<template>
    <fe-left-menu-layout
        :menuItems="validItems"
        v-model="activeItem"
        title="System Configuration"
    >
        <template v-slot:toolbar>
            <div class="pa-4">
                <breadcrumb
                    v-model="currentBreadcrumb"
                />
            </div>
        </template>
        <div class="flex-column flex-fill content" style="overflow:hidden auto; padding: 5px 0px 0px 0px;">
            <keep-alive>
                <component :is="activeItem.component" />
            </keep-alive>
        </div>
    </fe-left-menu-layout>
</template>

<script>
    import ManageUsers from '@/components/modules/manage/users'
    import ManageStudents from '@/components/modules/manage/students'
    import ManageAssessment from '@/components/modules/manage/assessments/Index'
    import ManageIncident from '@/components/modules/manage/incidents/Index'
    import IncidentTypeList from '@/components/modules/manage/incidents/IncidentTypeList'
    import IncidentBehavior from '@/components/modules/manage/incidents/IncidentBehavior'
    import IncidentResponseList from '@/components/modules/manage/incidents/IncidentResponseList'
    import InstanceInformation from '@/components/modules/manage/instanceInformation/Index'
    import DataPointTypeUsage from '@/components/modules/manage/dataPointTypeUsage/Index'
    import AutomationManager from '@/components/modules/manage/automationManager/Index'
    import ManageAttendance from '@/components/modules/manage/attendance/Index'
    import ManageDistrict from '@/components/modules/manage/district/Index'
    import ManageInterventions from '@/components/modules/manage/interventions/Index'
    import VerticalMenu from '@/components/navigation/VerticalMenu'
    import Breadcrumb from '@/components/common/Breadcrumb'
    import { mapState } from 'vuex'

    export default {
        name: 'Manage',
        components: {
            ManageAssessment,
            ManageIncident,
            IncidentTypeList,
            IncidentBehavior,
            IncidentResponseList,
            ManageAttendance,
            ManageDistrict,
            ManageInterventions,
            VerticalMenu,
            Breadcrumb
        },
        props: ['page'],
        computed: {
            ...mapState('manage', ['breadcrumbs']),
            ...mapState('global', ['sessionUser', 'shareableStores']),
            miniMenu() {
                let sz = this.$vuetify.breakpoint
                return sz.smAndDown
            },
            validItems() {
                let codes = this.sessionUser.security_codes
                let arr   = []

                this.items.forEach(rec => {
                    if (typeof rec.name === 'function') rec.name = rec.name();
                    if (typeof rec.description === 'function') rec.description = rec.description();
                    if (rec.securityCode) {
                        let allowed = (Array.isArray(rec.securityCode))
                            ? rec.securityCode
                            : [rec.securityCode]
                        if (allowed.find(code => codes.indexOf(code) > -1)) arr.push(rec)
                    } else if (rec.securityCallback) {
                        if (rec.securityCallback()) {
                            arr.push(rec)
                        }
                    } else {
                        arr.push(rec)
                    }
                })
                return arr
            },
            currentBreadcrumb: {
                get() {
                    let active = this.$data.activeItem

                    if (active && active.name) {
                        let crumbs = this.breadcrumbs[active.name]

                        if (!crumbs) {
                            return [{
                                name: 'vuex path not set in manage/index.js breadcrumbs',
                            }]
                        } else {
                            return [...crumbs]
                        }
                    }
                    return []
                },
                set(v) {
                    this.$store.commit('manage/setCrumbs', {
                        key: this.$data.activeItem.name,
                        val: v
                    })
                }
            }
        },
        mounted() {
            if (this.$props.page) {
                this.items.forEach(i => {
                    if (i.to === '/Manage/'+this.$props.page) {
                        this.activeItem = i
                    }
                })
            }
        },
        data() {
            return {
                activeItem: 'v-text-field',
                items: [{
                    name: 'Assessments',
                    icon: 'far fa-file-alt',
                    description: 'Manage assessment data within eduCLIMBER',
                    to: '/Manage/Assessment',
                    securityCode: ['MANAGE_ASSESSMENT_GROUP', 'MANAGE_TARGET_SETS'],
                    component: ManageAssessment
                }, {
                    name: 'Attendance',
                    icon: 'fas fa-tasks-alt',
                    description: 'Manage attendance rate performance bands and how attendance is reported',
                    to: '/Manage/Attendance',
                    securityCode: 'MANAGE_ATTENDANCE_SETUP',
                    component: ManageAttendance
                }, {
                    name: 'Incidents',
                    icon: 'fas fa-thumbtack',
                    description: 'Setup incident severities, responses and locations.',
                    to: '/Manage/Incident',
                    securityCode: 'MANAGE_PBIS_SETUP',
                    component: ManageIncident
                }, {
                    name: 'Interventions',
                    icon: 'fas fa-bullseye-pointer',
                    description: '',
                    to: '/Manage/Intervention',
                    securityCode: 'MANAGE_INT_SETUP',
                    component: ManageInterventions
                }, {
                    name: 'Users & Security',
                    description: 'Manage users, groups and their permission within eduCLIMBER',
                    to: '/Manage/Users',
                    securityCode: ['MANAGE_USER_SECURITY', 'MANAGE_SUPPORT_USERS', 'MANAGE_PUBLIC_TAGS'],
                    component: ManageUsers,
                }, {
                    name: 'Students & Guardians',
                    icon: 'fas fa-flag-checkered',
                    description: 'Manage students, student tags, and guardians',
                    to: '/Manage/students',
                    securityCode: null,
                    component: ManageStudents
                }, {
                    name: 'District & Schools',
                    icon: 'fas fa-flag-checkered',
                    description: '',
                    securityCode: ['MANAGE_SCHOOLS', 'MANAGE_DAYS_OFF', 'MANAGE_COURSES'],
                    component: 'manage-district',
                    to: '/Manage/District'
                },  {
                    name: 'Instance Information',
                    icon: 'fas fa-flag-checkered',
                    description: '',
                    securityCallback: () => {
                        return this.$isSupport() && this.$store.state.global.shareableStores.global_support;
                    },
                    component: InstanceInformation,
                    to: '/Manage/InstanceInformation'
                }, {
                    name: 'Assessment Usage',
                    icon: 'fas fa-flag-checkered',
                    description: '',
                    securityCallback: () => {
                        return this.$isSupport() && this.$store.state.global.shareableStores.global_support;
                    },
                    component: DataPointTypeUsage,
                    to: '/Manage/DataPointTypeUsage'
                }, {
                    name: 'Automation Manager',
                    icon: 'fas fa-flag-checkered',
                    description: '',
                    securityCallback: () => {
                        return this.$isSupport() && this.$store.state.global.shareableStores.global_support?.manage_automations;
                    },
                    component: AutomationManager,
                    to: '/Manage/AutomationManager'
                }]
            }
        }
    }
</script>

<style lang="scss" scoped>
.config-setting-icon {
    font-size: 16px;
    color: #3F6190;
}

.config-setting-nav {
    background: linear-gradient(0deg, #F9F9F9, #F9F9F9), #F9F9F9;
    border: 1px solid #C0C3CF;
    margin-left: 0 !important;
    padding: 10px;
}

.config-setting-nav ::v-deep .headline {
    font-weight: 500;
    font-size: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000000;
}

.config-setting-nav ::v-deep .collapsed-filter-panel {
    font-weight: 500;
    font-size: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000000;
}

.config-setting {
    border: none;
    box-shadow: none;
    background-color: #F9F9F9 !important;
    background: linear-gradient(0deg, #F9F9F9, #F9F9F9), #F9F9F9;
}

.config-setting-header {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;

    &:hover {
        font-weight: bold;
    }
}

.config-setting-panel {
    background-color: transparent !important;
}

.config-setting-list {
    background-color: #F9F9F9 !important;
}
</style>
