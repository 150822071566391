<template>
    <div class="panel">
        <v-form ref="form" v-model="studentRec.formValid">
            <div>
                <div class="d-flex">
                    <div>
                        <img class="avatar" :style="studentRec.image"/>
                    </div>
                    <div>
                        <div style="margin-top: 24px; padding-left: 16px;">
                            <h2>{{studentRec.student_full_name}}</h2>
                            <h4>{{studentRec.school_name}}</h4>
                            <h4>{{studentRec.grade_desc}} Grade</h4>
                        </div>
                    </div>
                    <v-spacer />
                    <div style="margin: 16px">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on }">
                                <fe-btn color="#0049ff" text v-on="on">Actions<v-icon>arrow_drop_down</v-icon></fe-btn>
                            </template>
                            <v-list>
                                <!-- there is a weird delay in disabling this for some reason
                                so this is an attempt to mitigate it -->
                                <v-list-item :class="{disabled:copyDisabled}" @click="showCopyDialog = copyDisabled?false:true">
                                    <div class="v-label theme--light normal" >
                                        <v-icon small>file_copy</v-icon>
                                        Copy to Other Students
                                    </div>
                                </v-list-item>
                                <v-list-item @click="emitRemove">
                                    <div class="v-label theme--light normal" style="color:red;">
                                        <v-icon small color="red">delete</v-icon>
                                        Remove This Student
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <copy-dialog v-model="showCopyDialog" @copy="emitCopy"/>
                    </div>
                </div>
                
                <incident-panel
                    :panelVisibility="panelVisibility"
                    :behaviors="studentRec.behaviors"
                    :studentRec="studentRec"
                    @validate="validate($event)"
                />
                <description-panel v-show="panelVisibility.description"
                    :panelVisibility="panelVisibility.description"
                    :studentRec="studentRec"
                />
                <motivation-panel v-show="panelVisibility.motivation"
                    :panelVisibility="panelVisibility.motivation"
                    :studentRec="studentRec"
                />
                <response-panel v-show="panelVisibility.response"
                    :panelVisibility="panelVisibility.response"
                    :responses="studentRec.responses"
                    :studentRec="studentRec"
                    @sendfollowup="$emit('sendfollowup')"
                />
                <parent-panel v-show="panelVisibility.parentContact"
                    :panelVisibility="panelVisibility.parentContact"
                    :studentRec="studentRec"
                />

                <div class="footnote">* An asterisk indicates a required field</div>
            </div>
        </v-form>
    </div>
</template>

<script>
import ResponsePanel from './ResponsePanel'
import DescriptionPanel from './DescriptionPanel'
import MotivationPanel from './MotivationPanel'
import IncidentPanel from './IncidentPanel'
import ParentPanel from './ParentPanel'
import CopyDialog from './CopyDialog'

export default {
    name: 'GeneralDetails',
    components: {
        ResponsePanel,
        DescriptionPanel,
        MotivationPanel,
        IncidentPanel,
        ParentPanel,
        CopyDialog
    },
    props: {
        studentRec: Object,
        studentCount: Number
    },
    computed: {
        supportText() {
            if(this.$isSupport()) {
                return 'id: ' + this.studentRec.incident_id + ' - ' + this.studentRec.student_incident_id
            }
            return null
        },
        copyDisabled() {
            return this.studentCount < 2
        }
    },
    data() {
        return {
            showCopyDialog: false,
            panelVisibility: {
                description: 2,
                motivation: 2,
                response: 2,
                parentContact: 1
            }
        }
    },
    created() {
    
        if(!this.studentRec.behaviors) {
            this.$set(this.studentRec,'behaviors',[])
        }
        if(!this.studentRec.responses) {
            this.$set(this.studentRec,'responses',[])
        }
    },
    methods: {
        validate(e) {
            if(this.$refs.form) this.$refs.form.validate()
        },
        emitCopy(overwrite) {
            this.$emit('copyfields',this.studentRec,overwrite)
        },
        emitRemove(e) {
            this.$messageBox({
                title: 'Confirm Remove',
                message: 'Are you sure you want to remove <b>'+this.studentRec.student_full_name+'</b> from the incident? This will take effect immediately.',
                maxWidth: '400',
                actions: [{
                    text: 'Cancel',
                }, {
                    text: 'Remove',
                    primary: true,
                    onClick: () => {
                        this.$emit('removestudent',this.studentRec)
                    }
                }]
            })
        }
    }
}
</script>

<style scoped="true">
.panel {
    box-shadow: 0 1.35px 2.7px rgb(0 0 0 / 28%);
    border-top: 1px solid rgb(0 0 0 / 5%);
}
.footnote {
    padding-left: 28px;
    padding-bottom: 8px;
    font-size: 12px;
}
h2 {
    font-size: 24px;
    font-weight: normal;
}
h4 {
    color: #7E8494;
    font-weight: normal;
}
.avatar {
    width: 81px;
    height: 81px;
    border: 0px;
    margin-left: 24px;
    margin-top: 24px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.disabled {
    pointer-events: none;
    opacity: .30;
}
</style>
