<template>
    <div class="flex-fill no-scroll mb-5">
        <fe-grid
            v-if="fieldConfig.attendance_type == 'period_list'"
            ref="grid"
            :style="style"
            :defaultColDefOverrides="defaultColDef"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :showToolbar="false"
            :showAddRowBtn="false"
            showScrollbars
            displayDensity="small"
            @gridReady="resize"
            @gridColumnsChanged="resize"
        />
        <!-- <div v-else
            class="single-field"
        >
            <div class="pa-1" v-html="fieldValue"></div>
        </div> -->

        <div v-else class="d-flex flex-wrap wrapper" >
            <div v-if="!editor" v-for="x in rowData" :key="x.school_year_id" class="card">
                <div class="header">{{x.school_year_name}}</div>
                <div class="content">{{x.attendance_value}}</div>
            </div>
            <div v-if="editor" class="card">
                <div class="dim header">Year</div>
                <div class="dim content">100.00</div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'AttendanceField',
        props: {
            data: {},
            fieldRec: {},
            editor: {},
        },
        methods: {
            resize() {
                this.$refs.grid?.gridApi?.sizeColumnsToFit()
            },
        },
        computed: {
            fieldConfig() {
                let config
                if(this.editor) {
                    // formFieldSavedSearch comes from either the crud or is update by the xtypeconfig
                    config = {
                        ...this.formFieldSavedSearch,
                        ...this.formFieldSavedSearch?.config_json,
                    }
                } else {
                    // this comes from the instance response
                    config = this?.data || {}
                }
                return config
            },
            // fieldValue() {
            //     // let noConfigText = `<span style="color:red;">Configure attendance smartfield</span>`
            //
            //     if(this.$_.isBlank(this.fieldConfig)) {
            //         return null
            //     } else {
            //         return this.fieldConfig.attendance_data
            //     }
            // },
            // rowData: me => me.data?.attendance_data || [],
            rowData() {
                return this.data?.attendance_data || []
            },
            style() {
                let h = 68+30*(Math.max(1,this.rowData.length))
                if(h>300) h = 300
                return {
                    width: '100%',
                    height: `${h}px`,
                }
            },
            columnDefs() {
                let cols = this.fieldConfig?.display_columns
                return cols ? [{
                    headerName: 'Date',
                    field: 'occurrence_date',
                    hide: cols ? !cols.includes('occurrence_date') : true
                }, {
                    headerName: 'Weekday',
                    field: 'occurrence_weekday',
                    hide: cols ? !cols.includes('occurrence_weekday') : true
                }, {
                    headerName: 'Term',
                    field: 'attendance_term_name',
                    hide: cols ? !cols.includes('attendance_term_name') : true
                }, {
                    headerName: 'Period',
                    field: 'attendance_period',
                    hide: cols ? !cols.includes('attendance_period') : true
                }, {
                    headerName: 'Course',
                    field: 'attendance_course',
                    hide: cols ? !cols.includes('attendance_course') : true
                }, {
                    headerName: 'Status',
                    field: 'attendance_status_name',
                    hide: cols ? !cols.includes('attendance_status_name') : true
                }, {
                    headerName: 'Reason',
                    field: 'attendance_reason_name',
                    hide: cols ? !cols.includes('attendance_reason_name') : true
                }, {
                    headerName: 'Excused',
                    field: 'attendance_excused_name',
                    hide: cols ? !cols.includes('attendance_excused_name') : true
                }] : []
            },
        },
        data() {
            if(!this.data) {
                // if no response data then get the columns
                this.$modelGet('formFieldSavedSearch', { form_field_id:this.fieldRec.id })
                .then(r => {
                    if(r.length) {
                        this.formFieldSavedSearch = r.length && r[0]
                        // this.formFieldSavedSearch.demographic_id = this.formFieldSavedSearch?.args?.DEMOGRAPHIC_ID
                    }
                })
            }

            return {
                formFieldSavedSearch: null,
                defaultColDef: this.editor ? {
                    sortable: false,
                    resizable: false,
                    filter: false,
                    hide: false,
                    cellClass: 'x-grid-column'
                } : {
                    sortable: true,
                    resizable: true,
                    filter: true,
                    hide: false,
                    cellClass: 'x-grid-column'
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
// .header {
//     color: rgb(126,132,148);
//     font-size: 12px;
// }
// .single-field {
//     border: 1px solid #ced0d9;
//     border-radius: 3px;
//     min-height: 36px;
//     padding: 2px;
//     margin-bottom: 19px;
//     background-color: white;
//     width: 100%;
// }

.wrapper {
    border: 1px solid #ced0d9;
    border-radius: 3px;
    min-height: 43px;
    padding: 2px;
    margin-bottom: 19px;
    background-color: white;
    width: 100%;
}
.card {
    padding: 0 8px;
}
.header {
    color: rgb(126,132,148);
    font-size: 12px;
    text-align: center;
}
.content {
    font-size: 13px;
    text-align: center;
}
.dim {
    opacity: .65;
}

// [
//       'district_name' => '9535 | QA Test Site-Copy of 1052',
//       'grade_rank' => 13,
//       'grade_desc' => '10th',
//       'district_id' => 1,
//       'school_name' => 'West High School',
//       'student_id' => 7968,
//       'school_id' => 9,
//       'student_school_start_date' => '2020-09-01',
//       'student_school_end_date' => '2021-06-04',
//       'student_school_inactive_date' => NULL,
//       'school_year_id' => 17,
//       'year_start' => '2020-09-25',
//       'year_end' => '2021-07-28',
//       'year_start_datetime' => '2020-09-25 00:00:00',
//       'year_end_datetime' => '2021-07-28 23:59:59',
//       'school_year_name' => '2020-2021',
//       'fname' => 'Andrew',
//       'mdl_init' => NULL,
//       'lname' => 'Newhouse',
//       'student_full_name' => 'Newhouse, Andrew ',
//       'gender' => 'M',
//       'ethnicity' => 'White',
//       'date_of_birth' => '2005-08-29',
//       'image' => '542821.jpg',
//       'home_language_id' => 1,
//       'home_language_code' => 'ENGLISH',
//       'home_language_description' => 'ENGLISH',
//       'home_language_display' => 'ENGLISH',
//       'grade_id' => 12,
//       'student_grade_history_id' => 143792,
//       'student_grade_upload_id' => 8677,
//       'ell_flag' => 0,
//       'ell_flag_name' => 'No',
//       'ell_level_id' => NULL,
//       'ell_level_name' => NULL,
//       'ell_entry_date' => NULL,
//       'ell_fluent_date' => NULL,
//       'occurrence' => '2020-10-02',
//       'student_attendance_date_id' => 1470760,
//       'absent_days_equivalent' => '0.50',
//       'adj_absent_days_equivalent' => '0.50',
//       'occurrence_date' => '2020-10-02',
//       'occurrence_weekday' => 'Friday',
//       'occurrence_weekday_num' => 4,
//       'occurrence_month' => 'October',
//       'occurrence_month_num' => 10,
//       'occurrence_year' => 2020,
//       'student_attendance_period_id' => 5435140,
//       'attendance_term_name' => 'S1',
//       'attendance_course' => 'GEOMETRY S1',
//       'attendance_period' => '8 (TUE/FR)',
//       'attendance_status_id' => 1,
//       'attendance_status_name' => 'Absent',
//       'attendance_reason_id' => 18,
//       'attendance_reason_name' => 'Absent - Virutal',
//       'attendance_excused_id' => NULL,
//       'attendance_excused_name' => NULL,
//     ],
</style>
