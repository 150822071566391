<template>
    <div class="flex-fill flex-column slo-plan-panel">
        <!-- Journal panel is under development. -->
        <Message 
            :params="{
                slo_id: slo.id
            }"
        />
    </div>
</template>

<script>
    import Message from '@/components/common/Message'

    export default {
        name: 'SloPanelJournal',
        props: {
            slo: { type: Object, required: true }
        },
        components: { Message },
        data() {
            return {}
        },
        computed: { },
        methods: { },
        watch: { }
    }
</script>

<style lang="scss" scoped>
</style>
