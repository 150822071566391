<template>
    <div>
        <fe-dialog
            :title="value && value.name ? value.name : 'SmartForm Template'"
            width="95%"
            height="600"
            @close="$emit('close')"
            persistent
        >
            <fe-overlay
                :value="loading"
                loader
                text=""
            />
            <div v-if="limitedData && !openConfigure" class="mb-4">
                Please note that the table shown below is just a preview that may not reflect all of the available data.
                All data selected in your configuration will be included once exported.
            </div>
            <div v-if="!openConfigure" style="height: calc(100% - 38px)">
                <fe-grid
                     ref="notConfiguredGrid"
                     :rowData="rowData"
                     :columnDefs="columnDefs"
                     :gridOptionOverrides="gridOptions"
                     displayDensity="small"
                     style="height: 100%; width: 100%"
                     domLayout="normal"
                     :showAddRowBtn="false"
                     suppressFieldDotNotation
                     :showToolbar="false"
                     :overlayNoRowsTemplate="overlayNoRowsTemplate"
                />
            </div>
            <configure-export v-else
                  ref="configureExportGrid"
                  :backupRowData="rows"
                  :backupExportRowData="exportRow"
                  :reset="reset"
                  @resetFinished="reset=false"
                  :grabConfigureData="grabConfigureData"
                  @pushConfigureData="checkForDuplicates"
                  @change="changeDisableBtns"
            />
            <template #footer>
                <div v-if="openConfigure">
                    <fe-btn usage="tertiary" @click="openConfigure=false">Back</fe-btn>
                </div>
                <v-spacer/>
                <div v-if="!openConfigure" class="d-flex">
                    <fe-tooltip v-if="columnDefs.length && !rowData.length" tooltip="You can only export the form if there is instance data.">
                        <fe-btn v-if="columnDefs.length && !rowData.length" class="smartform-export-disabled-reset"><v-icon class="mr-2">far fa-download</v-icon>Export</fe-btn>
                    </fe-tooltip>
                    <fe-btn v-else usage="secondary" @click="submitCSV"><v-icon class="mr-2">far fa-download</v-icon>Export</fe-btn>
                    <fe-btn v-if="!savedConfig && $hasSecurity('MANAGE_FORM_EXPORT_CONFIG')" @click="openConfigure=true" :disabled="exportConfigType === 'testhound'">
                        <v-icon class="mr-2">fa fa-hammer</v-icon>Configure
                    </fe-btn>
                    <fe-btn v-else-if="savedConfig && $hasSecurity('MANAGE_FORM_EXPORT_CONFIG')" @click="openConfigure=true" :disabled="exportConfigType === 'testhound'">
                        <v-icon class="mr-2">fa fa-redo</v-icon>Edit Export Configuration
                    </fe-btn>
                </div>
                <div v-else>
                    <fe-btn v-if="!disableBtns" usage="secondary" @click="reset=true">Reset</fe-btn>
                    <fe-btn v-else class="smartform-export-disabled-reset">Reset</fe-btn>
                    <fe-btn v-if="!disableBtns" @click="checkForDuplicates; grabConfigureData=true"><v-icon class="mr-2">fa fa-save</v-icon>Save and Preview</fe-btn>
                    <fe-btn v-else class="smartform-export-disabled-save"><v-icon class="mr-2">fa fa-save</v-icon>Save and Preview</fe-btn>
                </div>
            </template>
        </fe-dialog>

        <fe-dialog
            title="Confirm Export Configuration"
            width="500"
            height="300"
            persistent
            @close="confirmDuplicates=false;grabConfigureData=false;"
            @accept="onPushConfigureData"
            v-if="confirmDuplicates"
        >
            <div>
                This configuration contains multiple fields that have the same Data ID. This might create duplicates.
                <br/><br/>
                Are you sure you want to continue?
            </div>
        </fe-dialog>

        <fe-crud ref="formExportSFTPConfigCrud" :config="$models.formExportSFTPConfig" />
    </div>
</template>

<script>
    import ConfigureExport from "./ConfigureExport";
    import {mapState} from 'vuex';
    export default {
        name: 'ExportData',
        components: {
            ConfigureExport,
        },
        props: {
            value: { type: Object },
        },

        data() {
            return {
                columns: [],
                rowData: [],
                params: {
                    data_id: [],
                    school_year_id: null,
                    form_id: null,
                },
                gridOptions: {
                    suppressFieldDotNotation: true,
                },
                savedConfig: false,
                openConfigure: false,
                formNames: [],
                exportColumn: [],
                rows: [],
                exportRow: [],
                reset: false,
                grabConfigureData: false,
                columnDefs: [],
                savedDataIds: [],
                disableBtns: true,
                loading: false,
                savedCreatedResponse: null,
                confirmDuplicates: false,
                rowsForExport: [],
                limitedData: false,
                exportColumnSaved: [],
                columnsSaved: [],
                exportConfigType: null,
                exportMode: 'csv'
            }
        },

        computed: {
            ...mapState('global', ['sessionUser']),
            overlayNoRowsTemplate() {
                return this.loading ? "Loading Data Ids..."
                    : this.columnDefs.length ? "No instances have been created for this SmartForm."
                        : "No Data Ids have been set for this SmartForm."
            },
        },

        mounted() {
            this.params.school_year_id = this.value.school_year_id
            this.params.form_id = this.value.id || this.value.form_id
            if(this.value.sftp) {
                this.exportMode = 'sftp'
            }
            this.loadSavedConfig()
        },

        methods: {
            loadSavedConfig() {
                // first check if there is already a saved config for this template
                // then load all datah
                this.loading = true
                this.$axios.get('crud.php?property=FormExportConfig&action=get&form_id='+this.params.form_id)
                    .then((response) => {
                        this.savedDataIds = []
                        if(response.data.form_export_config.length) {
                            this.exportRow = []
                            this.savedConfig = true
                            this.savedDataIds = response.data.form_export_config
                        }
                    })
                    .finally(() => {
                        this.loadData()
                    })
            },
            loadData() {
                let saved = this.savedDataIds
                this.rowData = []
                let p = {...this.params}
                // grab all data ids that are not default columns (listed below)
                let transactions = [
                    this.$axios.get('form.php?property=data_id&action=get&form_id='+this.params.form_id)
                        .then(r => {
                            this.params.data_id = r.data.data_id || []
                        })
                ]
                Promise.all(transactions).then(() => {
                    // concat data ids with default columns
                    let defaultColumns = [
                        'Template', 'Form Name', 'Created', 'Created By User', 'Student', 'Student ID',
                        'Student District ID', 'School', 'Grade', 'School Year', 'Inactive Date'
                    ]
                    p.data_id = this.params.data_id.map(p=>p.data_id).concat(defaultColumns)
                    this.$axios.post('formResponse.php?action=get&property=export&export_mode=display', p)
                        .then((response) => {
                            this.rowData = response.data.form_instances.data
                            this.limitedData = response.data.form_instances.limited
                            this.exportColumn = []
                            this.columns = []
                            this.rows = []
                            this.exportRow = []
                            this.columnDefs = []
                            this.exportColumnSaved = []
                            this.columnsSaved = []
                            let firstData = response.data.form_instances.data[0]
                            let data_ids = []
                            let configure_data_ids = []
                            let extra_columns_obj = response.data.form_instances.extra_column_index
                            let extra_columns = []
                            let extra_main = []
                            this.exportConfigType = response.data.form_instances.export_config
                            // if formResponse returns extra_column_index
                            // need to separately use those data ids (e.g. score field)
                            // and their corresponding extra fields
                            if(extra_columns_obj) {
                                Object.keys(extra_columns_obj).forEach(col => {
                                    if(saved.find(x => x.data_id == col)) {
                                        extra_main.push(col)
                                        extra_columns_obj[col].forEach(v => {
                                            extra_columns.push(v)
                                        })
                                    }
                                })
                            }

                            // this is for export rows - can be limited
                            if(firstData) {
                                Object.keys(firstData).forEach(key => {
                                    if(key !== 'ID') data_ids.push(key)
                                })
                            }
                            // this is for configure page - NOT limited
                            p.data_id.forEach(id => {
                                if(id !== 'ID') configure_data_ids.push(id)
                            })

                            // find if any user entered data_id match a default column
                            // keep track of indices since first index of duplicate would be user created
                            // and second index would be system default (each require different tooltips)
                            let findDuplicates = []
                            let findDuplicatesConfig = []
                            let dupIndex = []
                            let dupIndexConfig = []
                            findDuplicates = data_ids.filter((item, index) => data_ids.indexOf(item) !== index)
                            findDuplicatesConfig = configure_data_ids.filter((item, index) => configure_data_ids.indexOf(item) !== index)
                            if(findDuplicates.length) {
                                data_ids.forEach((id, index) => {
                                    findDuplicates.forEach(dup => {
                                        if (id === dup) {
                                            dupIndex.push({duplicate: dup, index: index})
                                        }
                                    })
                                })
                            }
                            if(findDuplicatesConfig.length) {
                                configure_data_ids.forEach((id, index) => {
                                    findDuplicatesConfig.forEach(dup => {
                                        if (id === dup) {
                                            dupIndexConfig.push({duplicate: dup, index: index})
                                        }
                                    })
                                })
                            }

                            // checked for duplicates that were saved
                            let savedDuplicates = saved.length ?
                                saved.filter((item, index) => saved.map(el => el.data_id).indexOf(item.data_id) != index).map(el => el.data_id) : []
                            // find if only one of the duplicates was saved
                            // will be assuming this is user created and not system default
                            let diff = findDuplicates.filter(x => !savedDuplicates.includes(x))
                            let diffConfig = findDuplicatesConfig.filter(x => !savedDuplicates.includes(x))
                            data_ids.forEach((col, index) => {
                                let firstDup = false
                                let foundInExtra = false
                                if(dupIndex.length) {
                                    firstDup = dupIndex.find(dup => dup.duplicate == col) && dupIndex.find(dup => dup.duplicate == col).index == index
                                }
                                // if data_id is inside the extra columns for the saved data_id
                                // e.g. if score field, key is score field id but display ids are
                                // inside the array of extra columns[score field]
                                if(extra_columns.length) {
                                    foundInExtra = extra_columns.includes(col)
                                }
                                // if the data_id isn't from a saved config OR if the second of the duplicates wasn't saved but first was
                                if(!this.savedDataIds.find(x => x.data_id == col) && !foundInExtra || diff.length && diff.includes(col) && !firstDup) {
                                    this.columns.push({
                                        headerName: col.length > 16 ? col.toString().slice(0, 16) + '...' : col,
                                        field: col,
                                        editable: false,
                                        minWidth: 100,
                                        colId: index,
                                        duplicate: !!dupIndex.find(dup => dup.duplicate === col),
                                        userCreatedDuplicate: firstDup,
                                        headerTooltip: col.length > 16 ? col : null
                                    })
                                } else {
                                    // if column is in the data id's extra columns
                                    // grab rank of the orig data id inside saved and place on all
                                    // they won't be different ranks, but they'll be together in the correct place
                                    let rank = index
                                    let findInExtraDataIds = null
                                    if(foundInExtra) {
                                        // if score field and the extra data id starts with the score field data id
                                        findInExtraDataIds = extra_main.find(x => col.startsWith(x))
                                        if(findInExtraDataIds) {
                                            rank = saved.find(x => x.data_id == findInExtraDataIds).rank
                                        }
                                    } else {
                                        rank = saved.length ? saved.find(x => x.data_id == col).rank : index
                                    }
                                    this.columnsSaved.push({
                                        headerName: col.length > 16 ? col.toString().slice(0, 16) + '...' : col,
                                        field: col,
                                        editable: false,
                                        minWidth: 100,
                                        colId: rank,
                                        duplicate: !!dupIndex.find(dup => dup.duplicate === col),
                                        userCreatedDuplicate: firstDup,
                                        headerTooltip: col.length > 16 ? col : null
                                    })
                                }
                            })
                            configure_data_ids.forEach((col, index) => {
                                let firstDup = false
                                let rank = index
                                let id = index
                                if(dupIndexConfig.length) {
                                    // if there's duplicates, it means that col (data_id) matches so can't just find by col
                                    // if it's not the first index of the duplicate, than search by lastIndexOf
                                    // NOTE: there can only be two of each data_id since if the user creates multiple of the same id, only one returns
                                    firstDup = dupIndexConfig.find(dup => dup.duplicate == col) && dupIndexConfig.find(dup => dup.duplicate == col).index == index
                                    rank = firstDup && saved.length && saved.find(x => x.data_id == col) ? saved.find(x => x.data_id == col).rank
                                        : !firstDup && saved.length && saved.find(x => x.data_id == col) ? saved[saved.map(el => el.data_id).lastIndexOf(col)].rank
                                            : index
                                    id = firstDup && saved.length && saved.find(x => x.data_id == col) ? saved.find(x => x.data_id == col).id
                                        : !firstDup && saved.length && saved.find(x => x.data_id == col) ? saved[saved.map(el => el.data_id).lastIndexOf(col)].id
                                            : index
                                } else {
                                    rank = saved.length && saved.find(x => x.data_id == col) ? saved.find(x => x.data_id == col).rank
                                        : index
                                    id = saved.length && saved.find(x => x.data_id == col) ? saved.find(x => x.data_id == col).id
                                        : index
                                }
                                // if the data_id isn't from a saved config OR if the second of the duplicates wasn't saved but first was
                                if(!this.savedDataIds.find(x => x.data_id == col) || diffConfig.length && diffConfig.includes(col) && !firstDup) {
                                    this.exportColumn.push({
                                        headerName: col.length > 16 ? col.toString().slice(0, 16) + '...' : col,
                                        field: col,
                                        editable: false,
                                        minWidth: 100,
                                        rank: rank,
                                        id: id,
                                        colId: rank,
                                        duplicate: !!dupIndexConfig.find(dup => dup.duplicate === col),
                                        userCreatedDuplicate: firstDup,
                                        headerTooltip: col.length > 16 ? col : null
                                    })
                                } else {
                                    this.exportColumnSaved.push({
                                        headerName: col.length > 16 ? col.toString().slice(0, 16) + '...' : col,
                                        field: col,
                                        editable: false,
                                        minWidth: 100,
                                        rank: rank,
                                        id: id,
                                        colId: rank,
                                        duplicate: !!dupIndexConfig.find(dup => dup.duplicate === col),
                                        userCreatedDuplicate: firstDup,
                                        headerTooltip: col.length > 16 ? col : null
                                    })
                                }
                            })
                            this.columns.forEach((row, index) => {
                                row.id = index + 1
                            })
                            this.exportColumn.forEach((col, index) => {
                                this.rows.push({
                                    data: {
                                        id: index + 1,
                                        column: col.field,
                                        rowIndex: index + 1,
                                        position: index + 1,
                                        duplicate: col.duplicate,
                                        userCreatedDuplicate: col.userCreatedDuplicate,
                                    }
                                })
                            })
                            this.exportColumn.sort((a,b) => parseInt(a.rank) - parseInt(b.rank))
                            this.exportColumnSaved.sort((a,b) => parseInt(a.rank) - parseInt(b.rank))
                            this.columns.sort((a,b) => parseInt(a.colId) - parseInt(b.colId))
                            this.columnsSaved.sort((a,b) => parseInt(a.colId) - parseInt(b.colId))
                            this.exportColumnSaved.forEach((col, index) => {
                                this.exportRow.push({
                                    id: col.id,
                                    column: col.field,
                                    rowIndex: col.rank,
                                    position: col.rank + 1,
                                    duplicate: col.duplicate,
                                    userCreatedDuplicate: col.userCreatedDuplicate,
                                })
                            })
                            // only show grid if either there are instances created (row data exists) or
                            // if the user has created custom data ids (with or without data)
                            this.columnDefs = this.params.data_id.length || this.rowData.length ?
                                this.savedConfig ? this.columnsSaved : this.columns : []
                        }).catch((error) => console.error(error))
                        .finally(() => {
                            this.loading = false
                            this.$refs.notConfiguredGrid.gridApi.refreshCells({force: true})
                            this.$refs.notConfiguredGrid.resize()
                        })
                })
            },
            submitCSV() {
                // same call whether exporting existing data or edited config
                let p = {...this.params}
                p.data_id = []
                if(this.exportColumnSaved.length) {
                    this.exportColumnSaved.forEach(col => {
                        p.data_id.push(col.field)
                    })
                } else {
                    this.exportColumn.forEach(col => {
                        p.data_id.push(col.field)
                    })
                }
                p.hide_id = true
                this.$setLoading(true)
                if(this.exportMode == 'csv') {
                    this.$axios.post('formResponse.php?action=get&property=export&export_mode=' + this.exportMode, p, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/csv'
                        },
                        responseType: 'blob'
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        const name = this.value.name ? this.value.name + '.csv' : 'smartFormsExport.csv'
                        link.href = url
                        link.setAttribute('download', name)
                        document.body.appendChild(link)
                        link.click()
                    }).catch((error) => console.error(error))
                        .finally(() => { this.$setLoading(false) })
                } else {
                    this.$axios.post('formResponse.php?action=get&property=export&export_mode=' + this.exportMode, p)
                        .then(response => {
                            if (response.data.success) {
                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                            } else {
                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                            }
                        })
                        .finally(() => { this.$setLoading(false) })
                }
            },
            onPushConfigureData() {
                this.confirmDuplicates = false
                this.grabConfigureData = false
                this.openConfigure = false
                this.disableBtns = true
                let p = []
                let action = 'create'
                let transactions = []
                let rows = this.rowsForExport
                // sort by rank to match order of user
                rows.sort((a,b) => parseInt(a.colId) - parseInt(b.colId))
                // find duplicates (if user has selected that they want to proceed even with duplicates)
                let findDuplicates = this.rowsForExport.filter((item, index) => this.rowsForExport.map(el => el.column).indexOf(item.column) != index).map(el => el.column)
                let saved = this.savedDataIds
                let dupIndex = []
                this.rowsForExport.forEach((id, index) => {
                    findDuplicates.forEach(dup => {
                        if (id.column === dup) {
                            dupIndex.push({duplicate: dup, index: index})
                        }
                    })
                })
                // add ID column to front of column defs
                // if there's duplicates, it means that column.field (data_id) matches so can't just find by column.field
                // if it's not the first index of the duplicate, than search by lastIndexOf
                rows.forEach((column, index) => {
                    let firstDup = dupIndex.find(dup => dup.duplicate == column.column) && dupIndex.find(dup => dup.duplicate == column.column).index == index
                    let id = firstDup && saved.length && saved.find(x => x.data_id == column.column) ? saved.find(x => x.data_id == column.column).id
                        : !firstDup && saved.length && saved.find(x => x.data_id == column.column) ? saved[saved.map(el => el.data_id).lastIndexOf(column.column)].id
                            : index + 1
                    p.push({
                        id: this.savedConfig ? id : index + 1,
                        data_id: column.column,
                        form_id: this.params.form_id,
                        rank: index,
                    })
                })
                if(!this.savedConfig) {
                    // if not yet saved, create new
                    action = 'create'
                    transactions.push([
                        this.$axios.post('crud.php?property=FormExportConfig&action=create', {form_export_config: p})
                            .then(response => {
                                if (response.data.success) {
                                    this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                                } else {
                                    this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                                }
                            })
                    ])
                } else {
                    // if there's a new row
                    if(rows.filter(b => !this.savedDataIds.some(a => a.data_id === b.column && b.id === a.id)).length) {
                        action = 'create'
                        let createParams = []
                        rows.filter(b => !this.savedDataIds.some(a => a.data_id === b.column && b.id === a.id)).forEach(column => {
                            createParams.push({
                                data_id: column.column,
                                form_id: this.params.form_id,
                                rank: rows.find(x => x.column == column.column && x.id == column.id).colId,
                            })
                        })
                        transactions.push([
                            // want value returned from create so can grab new ids
                            this.savedCreatedResponse = this.$axios.post('crud.php?property=FormExportConfig&action=create', {form_export_config: createParams})
                                .then(response => {
                                    if (response.data.success) {
                                        this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                                        return response.data.form_export_config
                                    } else {
                                        this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                                        return
                                    }
                                })
                        ])
                    }
                    // if there's ones to delete
                    if(this.savedDataIds.filter(b => !rows.some(a => a.column === b.data_id && a.id === b.id)).length) {
                        action = 'delete'
                        let deleteParams = []
                        deleteParams = this.savedDataIds.filter(b => !rows.some(a => a.column === b.data_id && a.id === b.id))
                        p = p.filter(param => !deleteParams.some(d => d.column === param.data_id && d.id === param.id))
                        transactions.push([
                            this.$axios.post('crud.php?property=FormExportConfig&action=delete', {form_export_config: deleteParams})
                                .then(response => {
                                    if (response.data.success) {
                                        this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                                    } else {
                                        this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                                    }
                                })
                        ])
                    }
                }

                Promise.all(transactions).then(() => {
                    // update ids. if new ids, need ones returned from create
                    // if existing ids, need to pull from saved data ids (delete doesn't return ids)
                    if(this.savedConfig) {
                        let s = []
                        // grab actual value returned from promise IF there's a row to create
                        if(this.savedCreatedResponse) {
                            this.savedCreatedResponse
                                .then((resp) => {
                                    if(resp) s = resp
                                })
                                .finally(() => {
                                    p = []
                                    // if there's duplicates, it means that col (data_id) matches so can't just find by col
                                    // if it's not the first index of the duplicate, than search by lastIndexOf
                                    rows.forEach((column, index) => {
                                        let firstDup = dupIndex.find(dup => dup.duplicate == column.column) && dupIndex.find(dup => dup.duplicate == column.column).index == index
                                        let id = index
                                        // if there's a savedCreatedResponse (meaning rows were created and new ids returned)
                                        // !p.some makes sure you are not passing the same ID for the duplicates
                                        if (s.length && s.find(x => x.data_id == column.column) &&
                                            !p.some(y => y.id === s.find(x => x.data_id == column.column).id && y.data_id == column.column)) {
                                            id = firstDup && s.length && s.find(x => x.data_id == column.column) ? s.find(x => x.data_id == column.column).id
                                                : !firstDup && s.length && s.find(x => x.data_id == column.column) ? s[s.map(el => el.data_id).lastIndexOf(column.column)].id
                                                    : index
                                        } else {
                                            id = firstDup && this.savedDataIds.length && this.savedDataIds.find(x => x.data_id == column.column) ? this.savedDataIds.find(x => x.data_id == column.column).id
                                                : !firstDup && this.savedDataIds.length && this.savedDataIds.find(x => x.data_id == column.column)
                                                    ? this.savedDataIds[this.savedDataIds.map(el => el.data_id).lastIndexOf(column.column)].id
                                                        : index
                                        }
                                        p.push({
                                            id: id,
                                            data_id: column.column,
                                            form_id: this.params.form_id,
                                            rank: index,
                                        })
                                    })
                                    this.$axios.post('crud.php?property=FormExportConfig&action=update', {form_export_config: p})
                                        .then(response => {
                                            if (response.data.success) {
                                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                                            } else {
                                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                                            }
                                        })
                                        .finally(() => {
                                            this.loading = true
                                            this.savedConfig = true
                                            this.savedCreatedResponse = null
                                            setTimeout(function() {
                                                this.loadSavedConfig()
                                            }.bind(this), 100)
                                        })
                                })
                        } else {
                            this.$axios.post('crud.php?property=FormExportConfig&action=update', {form_export_config: p})
                                .then(response => {
                                    if (response.data.success) {
                                        this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                                    } else {
                                        this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                                    }
                                })
                                .finally(() => {
                                    this.loading = true
                                    this.savedConfig = true
                                    setTimeout(function() {
                                        this.loadSavedConfig()
                                    }.bind(this), 200)
                                })
                        }
                    } else {
                        this.loading = true
                        this.savedConfig = true
                        setTimeout(function() {
                            this.loadSavedConfig()
                        }.bind(this), 200)
                    }
                })
            },
            changeDisableBtns(v) {
                this.disableBtns = v
            },
            checkForDuplicates(rows) {
                // check for duplicates in the to-be-saved rows
                // if duplicates, warn user
                this.rowsForExport = rows
                let findDuplicates = rows.filter((item, index) => rows.map(el => el.column).indexOf(item.column) != index).map(el => el.column)
                if(findDuplicates.length) {
                    this.confirmDuplicates = true
                } else {
                    this.onPushConfigureData()
                }
            },
        }

    }
</script>

<style lang="scss" scoped>
    .smartform-export-disabled-reset {
        pointer-events: none;
        ::v-deep .fe-button {
            background: #fff !important;
            color: #C0C3CF !important;
            border: 1px solid #C0C3CF !important;
        }
    }
    .smartform-export-disabled-save {
        pointer-events: none;
        ::v-deep .fe-button {
            background: #006C904D !important;
            color: #fff !important;
        }
    }
    .smartforms-export-data-header {

    }
</style>
