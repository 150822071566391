import CollectionsLanding from '@/components/modules/collections/Index'
import Collection from '@/components/modules/collections/collection/Collection'

export default [{
    path: '/Collections/:id',
    props: true,
    name: 'Collections',
    component: Collection
}, {
    path: '/Collections',
    props: true,
    name: 'Collections',
    component: CollectionsLanding
}]