<template>
    <div class="d-flex flex-fill flex-column">
        <div>
            <fe-label>
                Periods
                <span v-if="periods.filter(r=>r.required_flag).length" style="margin-left: 20px; font-size: 10px; font-weight: bold;">*Required Periods added</span>
            </fe-label>
            <fe-remote-combo
                :items="periods.filter(r=>!r.require_flag)"
                itemText="monitor_period_name"
                itemValue="id"
                v-model="selectedPeriods"
                multiple
                moreText
                @change="resetPeriods"
                :rules="$fieldValidators('select', null, { required: true })"
            />
        </div>

        <div>
            <fe-label>Expectations</fe-label>
            <fe-remote-combo
                :items="expectations"
                itemText="expectation_name"
                itemValue="id"
                v-model="selectedExpectations"
                multiple
                moreText
                @change="resetExpectations"
                :rules="$fieldValidators('select', null, { required: true })"
            />
        </div>

        <div  v-if="selectedPeriods.length" class="caption">Drag and drop to reorder</div>
        <v-expansion-panels v-if="selectedPeriods.length" flat>
            <draggable style="width: 100%;" v-model="selectedPeriods">
                <v-expansion-panel v-for="p in selectedPeriods" class="mb-2 point-sheet-editor">
                    <v-expansion-panel-header class="selections-header">
                        {{p.monitor_period_name}}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="pa-0">
                        <v-data-table :items-per-page="100" :headers="headers" :items="p.expectations" style="border: 1px solid #e6e6e6; border-radius: 0px 0px 5px 5px;">
                            <template v-slot:item.no_score_flag="{ item }">
                                <v-simple-checkbox v-model="item.no_score_flag"></v-simple-checkbox>
                            </template>
                            <!-- lowest score editor -->
                            <template v-slot:item.lowest_score="props">
                                <v-edit-dialog
                                    :return-value.sync="props.item.lowest_score"
                                >
                                    <v-icon v-if="props.item.force_score_range">fas fa-lock</v-icon>
                                    {{props.item.no_score_flag ? '--' : props.item.lowest_score}}
                                    <template #input v-if="!props.item.force_score_range">
                                        <v-text-field
                                            class="mt-2"
                                            flat solo dense type="number"
                                            single-line
                                            :readonly="props.item.no_score_flag===1 ? true : false"
                                            v-model="props.item.lowest_score"
                                        />
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <!-- highest score editor -->
                            <template v-slot:item.highest_score="props">
                                <v-edit-dialog
                                    :return-value.sync="props.item.highest_score"
                                >
                                    <v-icon v-if="props.item.force_score_range">fas fa-lock</v-icon>
                                    {{props.item.no_score_flag ? '--' : props.item.highest_score}}
                                    <template #input v-if="!props.item.force_score_range">
                                        <v-text-field
                                            class="mt-2"
                                            flat solo dense type="number"
                                            single-line
                                            :readonly="props.item.no_score_flag===1 ? true : false || props.item.force_score_range===1 ? true : false"
                                            v-model="props.item.highest_score"
                                        />
                                    </template>
                                </v-edit-dialog>
                            </template>

                        </v-data-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </draggable>
        </v-expansion-panels>

        <div class="d-flex" v-if="showAddBtn">
            <fe-btn usage="primary" class="ml-auto" @click="formatData" :disabled="!isValid">Add</fe-btn>
        </div>
    </div>
</template>

<script>
    import Draggable from 'vuedraggable';
    import { mapState } from 'vuex'

    export default {
        name: 'BehaviorSchedule',
        components: { Draggable },
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        expectations: [],
                        periods: [],
                        monitors: []
                    }

                }
            },
            showAddBtn: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            ...mapState('intervention', ['interventionTypeId']),
            isValid() {
                if (this.selectedPeriods.length === 0) return false
                if (this.selectedExpectations.length === 0) return false
                return true
            }
        },
        watch: {
            dragPosition(v) {},
            localValue(v) {
                this.$emit('input', v)
            },
            'localValue.expectations': {
                deep: true,
                handler() {
                    this.$emit('change', this.localValue)
                }
            },
            'localValue.periods': {
                deep: true,
                handler() {
                    this.$emit('change', this.localValue)
                }
            },
            selectedPeriods(v) {
                this.setTableData()
                this.$emit('selectedPeriods', v)
            },
            selectedExpectations(v) {
                this.setTableData()
                this.$emit('selectedExpectations', v)
            }
        },
        data() {
            return {
                dragPosition: null,
                headers: [{
                    value: 'expectation_name',
                    align: 'start',
                    sortable: false
                }, {
                    text: 'Not Scored',
                    value: 'no_score_flag',
                    sortable: false,
                    align: 'center'
                }, {
                    text: 'Min Score',
                    value: 'lowest_score',
                    sortable: false,
                    align: 'end'
                }, {
                    text: 'Max Score',
                    value: 'highest_score',
                    sortable: false,
                    align: 'end'
                }],
                localValue: {},
                expectations: [],
                periods: [],
                selectedPeriods: [],
                selectedExpectations: []
            }
        },
        mounted() {
            this.localValue = this.$props.value
            this.localValue.expectations = this.localValue.expectations || []
            this.localValue.periods      = this.localValue.periods || []
            this.loadData()
        },
        methods: {
            formatData(doEmit=true) {
                let mon = []
                this.$nextTick(() => {
                    this.selectedPeriods.forEach(p => {
                        if (p.expectations) {
                            p.expectations.forEach(e => {
                                mon.push({
                                    sub_category_id: e.sub_category_id,
                                    expectation_name: e.expectation_name,
                                    lowest_score: e.lowest_score,
                                    highest_score: e.highest_score,
                                    monitor_period_id: p.monitor_period_id,
                                    force_score_range: e.force_score_range,
                                    no_score_flag: e.no_score_flag ? 1 : 0,
                                })
                            })
                        }
                    })

                    this.localValue.monitors = mon

                    if (doEmit) this.$emit('add')
                })
            },
            resetExpectations(v) {
                if (v.length===0) this.selectedExpectations = this.expectations.filter(r => r.require_flag)
                this.$emit('expectation-change', v)
            },
            resetPeriods(v) {
                if (v.length===0) this.selectedPeriods = this.periods.filter(r => r.require_flag)
                this.$emit('period-change', v)
            },
            setTableData() {
                this.selectedPeriods.forEach(p => {
                    p.expectations = p.expectation_name || []
                    let exp = p.expectations || []
                    p.expectations = []

                    this.selectedExpectations.forEach(e => {
                        let found = exp.find(r => r.sub_category_id === e.sub_category_id)
                        if (found) {
                            p.expectations.push(found)
                        } else {
                            p.expectations.push({
                                sub_category_id: e.sub_category_id,
                                no_score_flag: false,
                                force_score_range: e.force_score_range,
                                expectation_name: e.expectation_name,
                                lowest_score: e.lowest_score,
                                highest_score: e.highest_score
                            })
                        }
                    })
                })
            },
            loadData() {
                this.$modelGet('interventionTypeMonitor', { intervention_type_id: this.interventionTypeId.id }).then(expectations => {
                    this.expectations = expectations
                    this.expectations.forEach(r => { if (r.require_flag) r.disabled = true })
                    this.selectedExpectations = expectations.filter(r => r.require_flag)
                })

                this.$modelGet('interventionTypePeriod', { intervention_type_id: this.interventionTypeId.id }).then(periods => {
                    this.periods = periods
                    this.periods.forEach(r => { if (r.require_flag) r.disabled = true })
                    this.selectedPeriods = periods.filter(r => r.require_flag)
                })
            }

        }
    }
</script>

<style lang="scss" scoped>
.selections-header {
    background: var(--fe-secondary);
    color: white;
    border-radius: 5px 5px 0px 0px;
    height: 35px;
    line-height: 35px;
    padding-left: 16px;

    ::v-deep .v-data-footer {
        display: none !important;
    }

    ::v-deep .v-icon {
        color: white !important;
    }
}

.point-sheet-editor {
    ::v-deep .v-expansion-panel-content__wrap {
        padding: 0px;
    }

    ::v-deep .v-simple-checkbox .v-icon {
        color: var(--v-primary-base) !important;
    }

    ::v-deep .v-data-footer {
        display: none !important;
    }
}

</style>
