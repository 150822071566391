import Vue from "vue";

export default Vue.extend({
    template: `<div class="d-flex flex-column" style="margin-top: -6px;">
                <div v-if="!getEndTime" style="font-style: italic;">In progress...</div>
                <div v-else>{{getEndTime}}</div>
            </div>`,
    computed: {
        getEndTime() {
            return this.params.data[this.params.endTime] ? this.$dayjs(this.params.data[this.params.endTime]).format('h:mm:ss a') : null
        }
    }
})
