<template lang="html">
    <div class="flex-fill flex-column no-scroll">
        <v-toolbar flat :max-height="64">
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>GridScroll</v-toolbar-title>
        </v-toolbar>
        <div class="flex-fill no-scroll">
            <fe-collapse-Panel style="overflow:auto;"></fe-collapse-Panel>
            <div class="flex-fill flex-column no-scroll">

                <div>
                    other stuff goes here and this is resizeable and the grid below adjusts
                    <fe-btn @click="count++" >add top space</fe-btn>
                    <fe-btn @click="count=0" >clear</fe-btn>
                    <div v-for="(x,i) in count" >{{i}} {{someText}}</div>
                </div>

                <fe-grid style="height:100%;"
                    :columnDefs="columnDefs"
                    :rowData="rowData"
                    :showAddRowBtn="false"
                >
                </fe-grid>
                
                <fe-crud
                    ref="crud"
                    autoload
                    :config="$models.assessment"
                    @read="rowData = $event"
                />

            </div>
        </div>
    </div>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'

export default {
    name: 'GridScroll',
    inject: ['localStore'],
    computed: {
        ...mapLocalState('someText'),
    },

    data() {
        return {
            count: 0,
            rowData: null,
            columnDefs: [
                { headerName: 'id', field: 'id', sortable: true, width: 50 },
                { headerName: 'Name', field: 'name', sortable: true, width: 150 },
                { headerName: 'Description', field: 'description', sortable: true },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    height: 100%;
}
</style>
