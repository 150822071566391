var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form-common-config',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"fieldSpecific",fn:function({rec}){return [_vm._v(" "+_vm._s(_vm.setRec(rec))+" "),_c('fe-grid',{ref:"grid",attrs:{"simpleInsert":"display_value","simpleInsertPlaceholderText":"List Option Value","displayDensity":"small","rowData":_vm.rowData,"showToolbar":false,"columnDefs":_vm.columnDefs,"domLayout":"autoHeight","gridOptionOverrides":_vm.gridOptions},on:{"cellValueChanged":_vm.onChanged,"simpleInsert":function($event){_vm.$refs.crud.create({
                display_value: $event,
                form_field_id: rec.id,
                active: 1,
                rank: _vm.rowData.length
            }).finally(()=>{
                _vm.$refs.grid.$refs.simpleInsertField.reset()
                _vm.$refs.crud.read()
            })},"rowDragEnd":_vm.updateOrder}}),_c('fe-crud',{key:rec.id,ref:"crud",attrs:{"defaultParams":{form_field_id:rec.id},"config":_vm.$models.formFieldOption,"autoload":""},on:{"read":_vm.onRead}})]}}])},'form-common-config',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }