<template>
    <div>
        <v-form v-if="ready" ref="form" @submit.prevent v-model="isValid">
            <fe-label class="mt-0">Section Header Name</fe-label>
            <v-text-field
                v-model="config.name"
                counter=50
                :rules="this.$fieldValidators('text', null, {required: true, limit: 50})"
                flat solo dense clearable autofocus
            />

            <fe-label>Enter a Short Description</fe-label>
            <fe-editor
                v-model="config.description"
                ref="editor"
                :editorConfig="editorConfig"
                :showErrorMsg="false"
                :characterLimit="255"
                showRemainingCount
            />
        </v-form>
    </div>
</template>

<script>
export default {
    name: "SectionHeader",

    props: {
        dashboard: {
            type: Object,
            required: true
        },
        edit: {
            type: Object,
            required: false
        },
        setConfig: {
            type: Function,
            required: true
        }
    },

    data() {
        return {
            ready: false,
            isValid: false,
            config: {
                name: '',
                description: '',
                config: {
                    type: 'section',
                    description: ''
                }
            },
            editorConfig: {
                toolbar: {
                    shouldNotGroupWhenFull: true,
                    items: [
                        'bold',
                        'italic',
                        'underline',
                        'fontColor',
                    ]
                }
            },
        }
    },

    watch: {
        isValid(n, o) {
            if (n !== o) {
                this.$emit('valid', n)
            }
        },

        config: {
            deep: true,
            handler(v) {
                this.$emit('change', v)
            }
        },

        'config.description': {
            handler(v) {
                this.config.h = v ? 3 : 2
            }
        }
    },

    mounted() {
        if (this.edit) {
            this.config = this.setConfig(this.config, this.edit)
        }

        this.ready = true
    }
}
</script>

<style lang="scss" scoped>

</style>
