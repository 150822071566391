<template>
    <fe-dialog
        v-if="active"
        @dismiss="active = false"
        @close="active = false"
        @accept="save"
        dismissButtonText="Cancel"
        acceptButtonText="Save"
        :acceptButtonDisabled="!valid"
        width="780px"
        persistent
    >
        <template v-slot:header>
            {{ isNew ? 'Create' : 'Edit' }} Performance Band
            <div class='title-tip-adj'>
                <fe-info-tip tooltip="Performance Bands pair colors and labels alongside the score value allowing your staff to more easily understand the score or score detail." />
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="active = false">
                <v-icon>close</v-icon>
            </v-btn>
        </template>

        <v-form @submit.prevent>
            <div class="d-flex flex-column">
                <fe-label>Performance Band Name</fe-label>
                <v-text-field
                    v-model="boundName"
                    flat solo dense validateOnBlur
                    :style="{ width: '100%' }"
                    :rules="rules[boundNameKey]"
                />
                <div class="d-flex">
                    <div class="mr-1 flex-grow-1">
                        <fe-remote-combo
                            label="Based On"
                            v-model="obj.target_set_type_id"
                            flat solo dense validateOnBlur clearable by-id
                            :items="targetSetTypes"
                            itemText="name"
                            itemValue="id"
                            :rules="rules.target_set_type_id"
                        />
                    </div>
                    <div v-if="obj.target_set_type_id == 2" class="ml-1 flex-grow-1">
                        <fe-remote-combo
                            label="Select Score Detail Type"
                            v-model="obj.score_detail_type_id"
                            flat solo dense validateOnBlur clearable by-id
                            :items="scoreDetailTypes"
                            itemText="code"
                            itemValue="id"
                            :rules="rules.score_detail_type_id"
                        />
                    </div>
                </div>
                <div class="d-flex">
                    <div class="mr-1">
                        <assessment-group-band-editor-ranges
                            title="Benchmark Ranges"
                            tooltip="This will be the first colors users will see on assessment charts."
                            :typeId="3"
                            :items="benchmarkDescriptors"
                            @change="benchmarkDescriptors = $event"
                        />
                    </div>
                    <div class="ml-1">
                        <assessment-group-band-editor-ranges
                            title="Performance Ranges"
                            tooltip="These colors will appear on the data wall and when toggled to Performance Ranges on charts."
                            :typeId="1"
                            :items="performanceDescriptors"
                            @change="performanceDescriptors = $event"
                        />
                    </div>
                </div>
            </div>
        </v-form>

        <fe-crud
            autoload
            ref="crudScoreDetailTypes"
            :config="$models.scoreDetailType"
            @read="scoreDetailTypes = $event"
            :readParams="{
                data_point_type_id: group && group.id
            }"
            :failOnEmptyResponse="false"
            refresh-on-config
        />
    </fe-dialog>
</template>

<script>
    import AssessmentGroupBandEditorRanges from './AssessmentGroupBandEditorRanges'

    export default {
        name: 'AssessmentGroupBandEditor',
        props: {
            group: { type: Object, default: () => {} },
            band: { type: Object, default: () => {} },
            descriptors: { type: Array, default: () => [] }
        },
        components: {
            AssessmentGroupBandEditorRanges
        },
        data () {
            return {
                scoreDetailTypes: null,
                active: false,
                obj: {
                    id: null,
                    name: null,
                    target_set_name: null,
                    description: null,
                    data_point_type_id: null,
                    target_data_point_type: null,
                    target_set_type_id: null,
                    score_detail_type_id: null,
                    target_data_point_name: null
                },
                rules: {
                    name: [
                        v => (this.isNew || (!!v && v.trim().length > 0)) || 'Please enter a name'
                    ].concat(this.$fieldValidators('text', 'Name', {limit: 255})),
                    target_set_name: [
                        v => (!this.isNew || (!!v && v.trim().length > 0)) || 'Please enter a name'
                    ].concat(this.$fieldValidators('text', 'Name', {limit: 255})),
                    target_set_type_id: [
                        v => (!!v && !!this.rangeTypes.filter(rt => rt.id == v)) || 'Please select a basis'
                    ],
                    score_detail_type_id: [
                        v => (this.obj.target_set_type_id != 2 || !!v) || 'Please select a score detail'
                    ]
                },
                errors: [],
                targetSetTypes: [
                    { id: 1, name: 'Primary Score' },
                    { id: 2, name: 'Score Details' }
                ],
                rangeTypes: [
                    { id: 3, name: "BENCHMARK", display: "Benchmark Range" },
                    { id: 1, name: "DETAILED+DATA WALL", display: "Performance Range" }
                ],
                benchmarkDescriptors: [],
                performanceDescriptors: [],
                defaultDescriptors: {
                    benchmark: [
                        {
                            name: "Below Benchmark",
                            color: "#FD4336",
                            target_type_id: 3
                        }, {
                            name: "At/Above Benchmark",
                            color: "#49C379",
                            target_type_id: 3
                        }
                    ],
                    performance: [
                        {
                            name: "Low",
                            color: "#FD4336",
                            target_type_id: 1
                        }, {
                            name: "Low Average",
                            color: "#FFE072",
                            target_type_id: 1
                        }, {
                            name: "Average",
                            color: "#49C379",
                            target_type_id: 1
                        }, {
                            name: "High Average",
                            color: "#53DAD8",
                            target_type_id: 1
                        }, {
                            name: "High",
                            color: "#539AF8",
                            target_type_id: 1
                        }
                    ]
                }
            }
        },
        computed: {
            boundNameKey () { return this.isNew ? 'target_set_name' : 'name' },
            boundName: {
                get () { return this.obj[this.boundNameKey] },
                set (v) { this.obj[this.boundNameKey] = v }
            },
            isNew () { return !this.band },
            valid () {
                let errs = []

                let validBand = true
                let nameCount = {}
                // Check for duplicate names and null values
                this.performanceDescriptors.forEach(band => {
                    if (!band.name) validBand = false

                    if (!nameCount[band.name]) nameCount[band.name] = { rec: band, count: 0}
                    nameCount[band.name].count++
                })

                for (let name in nameCount) {
                    if (nameCount[name].count > 1) {
                        validBand = false
                        nameCount[name].rec.name = ''
                    }
                }

                nameCount = {}
                this.benchmarkDescriptors.forEach(band => {
                    if (!band.name) validBand = false

                    if (!nameCount[band.name]) nameCount[band.name] = { rec: band, count: 0}
                    nameCount[band.name].count++
                })

                for (let name in nameCount) {
                    if (nameCount[name].count > 1) {
                        validBand = false
                        nameCount[name].rec.name = ''
                    }
                }

                if (!validBand) return false
                // End duplicate / null check

                Object.keys(this.rules).forEach(field => {
                    this.rules[field].forEach(rule => {
                        let test = rule(this.obj[field])
                        if (test !== true) errs.push(test)
                    })
                })
                this.errors = errs
                return errs.length === 0
            },
        },
        methods: {
            reset () {
                Object.keys(this.obj).forEach(k => {
                    this.obj[k] = (this.band && this.band[k]) || null
                })
                if (this.group && this.group.id) this.obj.target_data_point_type = this.group.id
                this.benchmarkDescriptors = this.isNew
                    ? [].concat(this.defaultDescriptors.benchmark)
                    : [].concat(this.descriptors.filter(x => x.target_type_id === 3))
                this.performanceDescriptors = this.isNew
                    ? [].concat(this.defaultDescriptors.performance)
                    : [].concat(this.descriptors.filter(x => x.target_type_id === 1))
            },
            open () {
                this.active = true
                this.$emit('open')
                this.reset()
            },
            close () {
                this.active = false
                this.$emit('close')
            },
            save () {
                let payload = Object.assign({}, this.obj)
                let descs = []
                this.benchmarkDescriptors.forEach((desc, idx) => {
                    descs.push(Object.assign({}, desc, {
                        rank: idx + 1
                    }))
                })
                this.performanceDescriptors.forEach((desc, idx) => {
                    descs.push(Object.assign({}, desc, {
                        rank: idx + 1
                    }))
                })

                descs.forEach(x => { delete x.tempId })

                if (this.isNew) {
                    payload.grades = []
                    payload.target_sub_category = []
                    payload.target_data_point_name = []
                    payload.descriptors = descs
                    this.$emit('create', payload)
                } else {
                    this.$emit('update', {
                        obj: payload,
                        descriptors: descs
                    })
                }
            }
        },
        watch: {
            band () { this.reset() },
            group () { this.reset() },
            descriptors () { this.reset() }
        }
    }
</script>

<style scoped>
    .range-title {
        font-size: 18px;
        line-height: 21px;
        color: #000000;
        margin-top: 20px;
    }
    .title-tip-adj {
        display: inline-block;
        position: relative;
        top: -3px;
        left: 5px;
    }
</style>
