import Vue from "vue";

export default Vue.extend({
    template: `<div @mouseover="params.context.componentParent.showPencilEditIcon = true" @mouseleave="params.context.componentParent.showPencilEditIcon = false">
                    <div class="d-flex flex-row" v-if="params.data.column != 'ID'">
                        {{ params.data.position }}
                        <v-spacer/>
                        <v-icon v-show="params.context.componentParent.showPencilEditIcon" class="mt-1" style="font-size: 10px">far fa-pencil</v-icon>
                    </div>
                    <div v-else class="smartform-configure-export-disabled-cell">
                        <fe-tooltip tooltip="This column cannot be sorted or excluded from export." direction="top">
                            <span>Disabled</span> 
                        </fe-tooltip>
                    </div>
                </div>`,
})