<template>
    <div data-test="intervention-group-view" class="d-flex flex-fill flex-column" style=" width: 100%;">
        <div class="page-title mx-4">{{name}}</div>

        <add-academic-monitor
            v-if="addMonitorDialog.show"
            data-test="add-academic-monitor-modal"
            :title="addMonitorDialog.title"
            :students="addMonitorDialog.students"
            :monitors="addMonitorDialog.monitors"
            :forceMonitoringTypeId="addMonitorDialog.forceMonitoringTypeId"
            @dismiss="addMonitorDialog.show=false"
            @save="addMonitorDialog.show=false; loadMonitors()"
        />

        <fe-dialog
            v-if="editMeasureDialog.show"
            :data-test="editMeasureDialog.test"
            :title="editMeasureDialog.title"
            dismissButtonText="Cancel"
            acceptButtonText="Apply Changes"
            :acceptButtonDisabled="!editMeasureDialog.valid"
            persistent
            disableAutoclose
            @accept="editMeasureDialog.handler()"
            @close="editMeasureDialog.show=false"
        >
            <academic-monitor-schedule
                ref="monitorSchedule"
                v-model="editMeasureDialog.record"
                :operation="editMeasureDialog.operation"
                :students="editMeasureDialog.record.length > 0 ? editMeasureDialog.record[0].students : []"
                standalone
                @validityChanged="(isValid) => editMeasureDialog.valid = isValid"
            />
        </fe-dialog>

        <fe-dialog
            v-if="editPrimaryMonitorModal.show"
            data-test="set-primary-monitor-modal"
            title="Set as Primary Measure"
            dismissButtonText="Cancel"
            :acceptButtonText="editPrimaryMonitorModal.studentMonitorPlansAffected.length > 0 ? 'Yes, Set as Primary Measure' : 'Done'"
            :dismissable="editPrimaryMonitorModal.studentMonitorPlansAffected.length > 0"
            persistent
            disableAutoclose
            @accept="savePrimaryMonitorModal"
            @close="editPrimaryMonitorModal.show=false"
        >
            <div v-if="editPrimaryMonitorModal.studentsWithMultipleMeasures.length > 0" style="background-color:#FFEEAA;border-left:6px solid #FFBD00;padding: 10px;" class="pt-3 pb-3 mb-5">
                <p>Only one measure for a student can be set as primary. More than 1 measure is selected for the following students:</p>
                <ul v-for="student in editPrimaryMonitorModal.studentsWithMultipleMeasures">
                    <li>{{ student }}</li>
                </ul>
            </div>
            <div v-if="editPrimaryMonitorModal.studentMonitorPlansAffected.length > 0">
                <p>If you set {{ selectedMonitoringType?.name }} as the primary measure, this measure will be used for all Rate of Improvement (ROI) charts and reports.</p>
                <p>Are you sure you want to set this measure as the primary?</p>
            </div>
        </fe-dialog>

        <fe-dialog
            v-if="editMonitorGoalGradeModal.show"
            data-test="edit-measure-goal-grade-modal"
            title="Edit Measure Goals & Grades"
            dismissButtonText="Cancel"
            acceptButtonText="Apply Changes"
            :acceptButtonDisabled="!editMonitorGoalGradeModal.valid"
            persistents
            disableAutoclose
            @accept="saveMonitorGoalGrade"
            @close="editMonitorGoalGradeModal.show=false"
        >
            <p>Set the goal and content level of the measure used.</p>
            <v-form v-model="editMonitorGoalGradeModal.valid">
                <fe-text-field
                    data-test="monitoring-goal-field"
                    v-model="editMonitorGoalGradeModal.goal"
                    label="Goal (Accuracy)"
                    type="text"
                    width="100%"
                    placeholder="Example: 80"
                    class="mb-5"
                    :rules="this.$fieldValidators('number', 'Goal', {required: false, min: 0, max: 9999})"
                    />

                <fe-remote-combo
                    data-test="monitoring-grade-field"
                    :url="$models.getUrl('grade', 'read') + '&active=1'"
                    label="Grade (Level of Measure)"
                    placeholder="Select"
                    itemText="name"
                    itemValue="id"
                    rootProperty="grades"
                    v-model="editMonitorGoalGradeModal.grade_id"
                    byId
                    :rules="$fieldValidators('select', 'Grade', { required: false })"
                />
            </v-form>

        </fe-dialog>

        <fe-dialog
            title="Modify Plan Details"
            data-test="modify-plan-detail-modal"
            v-if="modifyPlanDialog.show"
            @close="modifyPlanDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Modify"
            :acceptButtonDisabled="!modifyPlanDialog.valid"
            :footer="false"
        >
            <plan-details :interventionPlanId="interventionPlanId" @save="finishModify"/>
        </fe-dialog>


        <div style="border-bottom: 1px solid #dddddd;">
            <v-tabs height="28px" v-model="activeTab" class="ec-collection-tabs">
                <v-tab data-test="overview-tab-btn" href="#overview" class="small-tab small-tab-all">Group Overview</v-tab>
                <v-tab v-if="featureFlagBulkModify && hasAcademicMonitors" data-test="measures-tab-btn" href="#measures" class="small-tab small-tab-all">Measures</v-tab>
                <v-tab data-test="comments-tab-btn" href="#comments" class="small-tab small-tab-all">Comments</v-tab>
                <v-tab data-test="forms-tab-btn" href="#forms" class="small-tab small-tab-all">smartFORMS</v-tab>
                <v-tab data-test="reports-tab-btn" href="#reports" class="small-tab small-tab-all">Prebuilt Reports</v-tab>
                <v-tab data-test="schedules-tab-btn" href="#schedules" class="small-tab small-tab-all">Schedules</v-tab>
                <v-tab data-test="students-tab-btn" href="#students" class="small-tab small-tab-all">Student Details</v-tab>
            </v-tabs>
        </div>
        <v-carousel
            v-model="activeTab"
            height="100%"
            hide-delimiters light
            :show-arrows="false"
        >
            <div data-test="overview-tab" class="d-flex flex-fill flex-column" v-if="activeTab==='overview'">
                <div>
                    <fe-card
                        class="alt-card ma-3"
                        headerText="Summary"
                        :menu="hasAcademicMonitors ? ['Modify Plan Details'] : undefined"
                        @menu-item-clicked="handleOverviewMenuClick"
                    >
                        <template v-slot:body v-if="record">{{ planSummary }}</template>
                    </fe-card>
                </div>

                <!-- Progress Monitoring -->
                <v-row>
                    <v-col cols="6">
                        <fe-card
                            class="alt-card"
                            headerText="Implementation Fidelity"
                        >
                            <template v-slot:body>
                                <v-row>
                                    <v-col cols="6">
                                        <highcharts ref="chart-a" v-if="showFidelity" :options="fidelityConfig"/>
                                    </v-col>

                                    <v-col cols="6">
                                        <highcharts ref="chart-b" v-if="showFidelity" :options="proceduresConfig"/>
                                    </v-col>
                                </v-row>
                            </template>
                        </fe-card>
                    </v-col>

                    <v-col cols="6">
                        <fe-card
                            class="alt-card"
                            headerText="Progress toward Assessment Goal"
                        >
                            <template v-slot:body>
                                <div class="d-flex justify-space-around">
                                    <div v-for="key in monitorProgress" class="monitor-progress">
                                        <div class="monitor-progress-count" :style="{background: key.color}">
                                            <div style="font-size: 10px;">
                                                {{key.count}}
                                            </div>

                                            <div style="font-size: 12px;">
                                                {{((key.count / monitoredCount)*100).toFixed(1)}}%
                                            </div>

                                        </div>

                                        <div style="font-size: 14px;">{{key.title}}</div>
                                    </div>
                                </div>
                            </template>
                        </fe-card>
                    </v-col>
                </v-row>
            </div>

            <div data-test="comments-tab" class="d-flex flex-fill flex-column" v-if="activeTab==='comments'">
                <message dense style="margin: 16px;" v-if="activeTab=='comments'" :params="planParams"/>
            </div>

            <div data-test="forms-tab" class="d-flex flex-fill flex-column" v-if="activeTab==='forms'">
                <list v-if="activeTab=='forms'" style="margin: 16px;" intervention :params="planParams" :interventionId="interventionId"/>
            </div>

            <div data-test="reports-tab" class="d-flex flex-fill flex-column" v-if="activeTab==='reports'">
                <fe-grid
                    data-test="reports-grid"
                    :rowData="prebuilts"
                    style="height: 100%; margin: 16px;"
                    displayDensity="small"
                    :columnDefs="prebuiltCols"
                    :showToolbar="false"
                    @rowDoubleClicked="loadPrebuilt"

                />
            </div>

            <div data-test="schedules-tab" class="d-flex flex-fill flex-column" v-if="activeTab==='schedules'">
                <group-schedule
                    v-if="activeTab=='schedules'"
                    :interventionPlanId="interventionPlanId"
                    :studentPlans="studentPlans"
                    @new-phase="$emit('new-phase')"
                />
            </div>

            <div data-test="students-tab" class="d-flex flex-fill flex-column" v-if="activeTab==='students'">
                <v-slide-y-transition>
                    <div v-if="selectedStudents.length > 0" class="ec-grid-selection-toolbar d-flex mt-4">
                        <div class="mt-3 ml-5">{{selectedStudents.length}} Student{{ selectedStudents.length > 1 ? 's' : '' }} Selected</div>

                        <div class="ml-auto">
                            <v-btn text class="white--text" @click="promptDeleteStudentConfirm()">Remove Student</v-btn>
                            <v-btn text class="white--text" @click="$refs.studentGrid.gridApi.deselectAll()">Cancel</v-btn>
                        </div>
                    </div>
                </v-slide-y-transition>

                <fe-grid
                    data-test="students-grid"
                    :rowData="studentDetails"
                    style="height: 100%; margin: 16px;"
                    displayDensity="small"
                    :columnDefs="studentDetailCols"
                    :showAddRowBtn="false"
                    ref="studentGrid"
                    @selectionChanged="selectedStudents = $refs.studentGrid.gridApi.getSelectedRows()"
                />
            </div>

            <div data-test="measures-tab" class="d-flex flex-fill flex-column" v-if="featureFlagBulkModify && activeTab==='measures'">
                <div data-test="no-measure-view" class="ml-2 mr-2 mt-5" v-if="!loadMonitors && monitorDetails.length == 0">
                    <div style="padding:80px 40px;border:1px solid #E0E1E6;border-radius:8px;text-align:center;">
                        <div style="width:500px;text-align:center;display:inline-block;">
                            <v-icon class="mb-5" style="color:#747F9E;font-size:35px;">far fa-file-search</v-icon>
                            <h3 class="mb-4">No Measures Added</h3>
                            <p style="font-size:16px;">There are no progress monitoring measures currently used within this intervention plan. Add a measure to start tracking student progress.</p>
                            <fe-btn useIcon="fal fa-plus" data-test="add-measure-when-none-btn" usage="primary" @click="showAddNewMeasureModal">Add Measure</fe-btn>
                        </div>
                    </div>
                </div>
                <div data-test="measures-available-view" class="mr-2 mt-6" v-if="monitorDetails.length > 0">
                    <div class="d-flex flex-fill flex-row ml-2 fill-height align-center">
                        <h3 v-if="monitoringTypes.length <= 1" class="mt-2 ml-2 mr-2" style="display:inline-block;">{{ selectedMonitoringType?.name }}</h3>
                        <fe-remote-combo
                            v-if="monitoringTypes.length > 1"
                            data-test="measure-filter"
                            label="Measures"
                            :clearable="false"
                            hideDetails
                            itemText="name"
                            itemValue="id"
                            :items="monitoringTypes"
                            v-model="selectedMonitoringType"
                            style="width:300px;border-radius:16px;"
                        />
                        <a data-test="add-measure-btn" @click="showAddNewMeasureModal" class="ml-5" :class="monitoringTypes.length > 1 ? 'mt-6' : 'mt-2'"><v-icon style="color:inherit;margin-right:8px;">far fa-plus</v-icon> Add New Measure</a>
                    </div>
                </div>
                <div v-if="selectedMonitoringType != null" class="d-flex flex-fill flex-column">
                    <v-slide-y-transition>
                        <div v-if="selectedStudentMonitors.length > 0" class="ec-grid-selection-toolbar d-flex">
                            <div data-test="measures-selected-label" class="mt-3 ml-5">{{selectedStudentMonitors.length}} Selected</div>
                            <div class="ml-auto">
                                <v-btn data-test="set-as-primary-btn" text class="white--text" @click="changePrimaryMonitorModal(selectedStudentMonitors)"><v-icon class="mr-2">far fa-check</v-icon>Set as Primary</v-btn>
                                <v-menu offset-y location="bottom">
                                    <template v-slot:activator="{ on }">
                                        <v-btn data-test="edit-details-btn" text class="white--text" @click="close = false" v-on="on">Edit Details<v-icon class="ml-2">far fa-angle-down</v-icon></v-btn>
                                    </template>
                                    <slot name="menu">
                                        <v-card flat style="background: #fff;">
                                            <slot name="extra-menu-items"></slot>
                                            <v-btn style="padding:0 8px;" data-test="edit-monitoring-user-btn" text @click="showChangeMonitoringUserModal">Monitoring User</v-btn><br>
                                            <v-btn style="padding:0 8px;" data-test="edit-monitoring-type-btn" text @click="showChangeMonitoringTypeModal">Monitoring Type</v-btn><br>
                                            <v-btn style="padding:0 8px;" data-test="edit-dates-btn" text @click="showChangeMonitorDatesModal">Start &amp; End Dates</v-btn><br>
                                            <v-btn style="padding:0 8px;" data-test="edit-frequency-times-btn" text @click="showChangeMonitorFrequencyTimeModal">Frequency &amp; Times</v-btn><br>
                                            <v-btn style="padding:0 8px;" data-test="edit-goals-grades-btn" text @click="showChangeMonitorGoalGradeModal">Goals &amp; Grades</v-btn><br>
                                        </v-card>
                                    </slot>
                                </v-menu>
                                <v-btn data-test="delete-measures-btn" text class="white--text" @click="promptDeleteMonitorsConfirm(selectedStudentMonitors)"><v-icon class="mr-2">far fa-trash</v-icon>Delete</v-btn>
                                <v-btn data-test="cancel-btn" text class="white--text" @click="$refs.monitorGrid.gridApi.deselectAll()"><v-icon class="mr-2">far fa-times</v-icon>Cancel</v-btn>
                            </div>
                        </div>
                    </v-slide-y-transition>
                    <fe-grid
                        data-test="measures-grid"
                        :rowData="filteredMonitorDetails"
                        style="height: 100%; margin: 0 16px 16px;"
                        displayDensity="medium"
                        :columnDefs="monitorDetailCols"
                        :showAddRowBtn="studentsMissingMonitorForSelectedMeasure.length > 0"
                        :searchBar="false"
                        :disableInlineCreate="true"
                        @beginCreate="showAddStudentsToMeasureModal"
                        :addRowBtnText="`Add Students to Measure (${studentsMissingMonitorForSelectedMeasure.length})`"
                        ref="monitorGrid"
                        @gridReady="onMeasuresGridReady()"
                        @selectionChanged="selectedStudentMonitors = $refs.monitorGrid.gridApi.getSelectedRows()"
                    >
                        <template #left-toolbar-items><strong class="mt-3">{{ `${filteredMonitorDetails.length} Measure Record${filteredMonitorDetails.length == 1 ? '' : 's'}`}}</strong></template>
                        <template #cellDialog="{ cell }">
                            <v-list>
                                <v-list-item data-test="single-measure-edit-menu-item" @click="editMeasure(cell.data)">Edit Measure</v-list-item>
                                <v-list-item data-test="single-measure-set-primary-menu-item" @click="changePrimaryMonitorModal([cell.data])" :disabled="!!cell.data.primary_flag">Set as Primary</v-list-item>
                                <v-list-item data-test="single-measure-delete-menu-item" @click="promptDeleteMonitorsConfirm([cell.data])">Delete Measure</v-list-item>
                            </v-list>
                        </template>
                    </fe-grid>
                </div>
            </div>

        </v-carousel>
    </div>
</template>

<script>
    import Message from '@/components/common/Message'
    import List from '@/components/modules/smartforms/List'
    import GroupSchedule from './tabs/GroupSchedule'
    import PlanDetails from '../common/PlanDetails'
    import windowOptionsMixin from '@/mixins/windowOptions'
    import AddAcademicMonitor from '@/components/modules/intervention/monitor/AddAcademicMonitor'
    import AcademicMonitorSchedule  from '@/components/modules/intervention/creation/AcademicMonitorSchedule'

    export default {
        name: 'InterventionGroupView',
        mixins: [ windowOptionsMixin ],
        components: {
            Message, List, GroupSchedule, PlanDetails, AddAcademicMonitor, AcademicMonitorSchedule
        },
        props: {
            interventionPlanId: {
                required: true
            },
            name: {
                required: true
            },
            interventionId: {},
        },
        watch: {
            /**
             * selectedMonitoringType() watcher will clear the selected monitors on change
             */
            selectedMonitoringType(v) {
                this.selectedStudentMonitors = []
            },
            interventionPlanId(v) {
                if (v) this.loadData()
            }
        },
        data() {
            let me = this

            return {
                addMonitorDialog: {
                    show: false,
                    title: '',
                    forceMonitoringTypeId: 0,
                    students: [],
                    monitors: [],
                },
                editMeasureDialog: {
                    show: false,
                    valid: false,
                    test: 'edit-academic-monitor-modal',
                    title: 'Edit Measure',
                    operation: 'edit',
                    record: [],
                    handler() {},
                },
                editMonitorGoalGradeModal: {
                    valid: false,
                    show: false,
                    grade_id: null,
                    goal: '',
                },
                editPrimaryMonitorModal: {
                    show: false,
                    studentsWithMultipleMeasures: [],
                    studentMonitorPlansAffected: [],
                },
                selectedStudents: [],
                selectedMonitoringType: null,
                selectedStudentMonitors: [],
                exitStatus: [],
                showPdf: false,
                pdfData: null,
                record: {},
                activeTab: 'overview',
                fidelity: 0,
                procedureAvg: 0,
                showFidelity: false,
                proceduresConfig: {},
                fidelityConfig: {},
                monitoredCount: 0,
                modifyPlanDialog: {
                    valid: false,
                    show: false,
                    rec: null
                },
                monitorProgress: {
                    on_track: {
                        title: 'On Track',
                        color: '#49C379',
                        count: 0,
                        records: []
                    },
                    not_on_track: {
                        title: 'Not On Track',
                        color: '#FFE072',
                        count: 0,
                        records: []
                    },
                    no_roi: {
                        title: 'No Data',
                        color: '#E6E6E6',
                        count: 0,
                        records: []
                    },
                    no_goal: {
                        title: 'No Goal',
                        color: '#E6E6E6',
                        count: 0,
                        records: []
                    },
                },
                prebuilts: [],
                prebuiltCols: [{
                   headerName: "Student",
                   field: "student_full_name",
                   editable: false,
                   flex: 1
                }, {
                   headerName: "Report Type",
                   field: "report_type",
                   editable: false,
                   flex: 1
                }],
                studentPlans: [],
                studentDetails: [],
                studentDetailCols: [me.$grid.checkColumn(), {
                    headerName: "Student",
                    field: "student_full_name",
                    editable:  false,
                    flex: 1
                }, {
                    headerName: "Start Date",
                    field: "start_date",
                    editable: true,
                    cellEditorFramework: 'FeGridDatePicker',
                    flex: 1,
                    onCellValueChanged: v => {
                        me.updateStudents(v)
                    }
                }, {
                    headerName: "End Date",
                    field: "end_date",
                    editable: true,
                    cellEditorFramework: 'FeGridDatePicker',
                    flex: 1,
                    onCellValueChanged: v => {
                        me.updateStudents(v)
                    }
                }, {
                    headerName: "Exit Status",
                    field: "intervention_exit_status_id",
                    editable: true,
                    flex: 1,
                    cellRenderer:(v) => {
                        if (v.value) return me.exitStatus.find(r => r.id === v.value).name
                        return ''
                    },
                    onCellValueChanged: v => {
                        me.updateStudents(v)
                    },
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: v => {
                        return {
                            value: v.data.intervention_exit_status_id,
                            rowDataKey: "intervention_exit_status_id",
                            mode: "edit",
                            items: me.exitStatus,
                            keyProp: "id",
                            labelProp: "name",
                            disableChips: true
                        }
                    }
                }],

                loadingMonitors: false,
                monitorDetails: [],
                monitorDetailCols: [me.$grid.checkColumn(), {
                    headerName: "Student",
                    field: "student_full_name",
                    flex: 1,
                }, {
                    hide: true,
                    headerName: "Monitoring User",
                    field: "monitor_user_full_name",
                    flex: 3,
                }, {
                    headerName: "Primary",
                    field: "display_primary_flag",
                    width: 100,
                }, {
                    headerName: "Dates",
                    field: "display_dates",
                    flex: 1,
                }, {
                    hide: true,
                    headerName: "Frequency",
                    field: "display_frequency",
                    flex: 1,
                }, {
                    hide: true,
                    headerName: "Days",
                    field: "display_days",
                    flex: 1,
                }, {
                    headerName: "Times",
                    field: "display_times",
                    flex: 1,
                }, {
                    headerName: "Goal",
                    field: "goal",
                    headerClass: 'ec-cs-semi-bold ag-right-aligned-header',
                    cellStyle: {textAlign: "right"},
                    width: 75,
                    minWidth: 75,
                }, {
                    headerName: "Grade",
                    field: "assessment_grade_desc",
                    width: 100,
                }, {
                    colId: 'toolmenu',
                    maxWidth: 50,
                    cellRenderer: v => '<i class="fe-grid-icon far fa-ellipsis-v theme--light"></i>',
                    onCellClicked: (v) => this.$refs.monitorGrid.setDialogCell(v, 'menu'),
                }]
            }
        },
        computed: {
            /**
             * studentsMissingMonitorForSelectedMeasure() returns the count of students missing a monitor for the
             * selected measure.
             * @return {Object[]}
             */
            studentsMissingMonitorForSelectedMeasure() {
                const studentsWithMonitor = this.$_.uniqBy(this.filteredMonitorDetails, 'student_id')
                const studentsOnPlan = this.$_.uniqBy(this.studentPlans, 'student_id')
                const studentsWithoutMonitor = studentsOnPlan.filter(student => !studentsWithMonitor.find(student2 => student2.student_id == student.student_id))
                return studentsWithoutMonitor
            },

            featureFlagBulkModify () {
                return this.$store.getters['flags/flags']?.['ec-intervention-bulk-monitor-updates'] === true
            },

            /**
             * hasAcademicMonitors() returns whether or not the given plan has academic monitors by checking if it is
             * a behavior plan
             * @return {Boolean}
             */
            hasAcademicMonitors() {
                return this.record && this.record.category_id != 4
            },

            /**
             * planSummary() returns a summary of the plan which includes the interventionist(s), strategy, and level.
             * @return {String}
             */
            planSummary() {
                if (!this.record || !this.record.interventionists) {
                    return ''
                }
                let nameList = this.record.interventionists.map(v => v.name)
                if (nameList.length > 1) {
                    nameList.push(' and ' + nameList.pop())
                }

                let summary = nameList.join(nameList.length > 2 ? ', ' : '')
                summary += ' ' + (nameList.length > 1 ? 'are' : 'is')
                summary += ` implementing the intervention strategy of ${this.record.intervention_type_name},`
                summary += ` targeting ${this.record.category_name} at the ${this.record.intervention_level_name} level of need.`
                return summary
            },

            planParams() {
                return { intervention_plan_id: this.interventionPlanId }
            },

            /**
             * monitoringTypes() returns the unique monitoring types across all the monitors sorted alphabetically
             */
            monitoringTypes() {
                const monitoringTypes = this.$_.map(this.$_.uniq(
                    this.$_.map(this.monitorDetails, monitor => JSON.stringify({id: monitor.sub_category_id, name: monitor.sub_category_name}))
                ), monitorJson => JSON.parse(monitorJson))
                monitoringTypes.sort((type1, type2) => type1.name.localeCompare(type2.name))
                return monitoringTypes
            },

            /**
             * filteredMonitorDetails() returns monitors for the currently selected monitoring type
             */
            filteredMonitorDetails() {
                if (this.selectedMonitoringType === null) {
                    return []
                }
                return this.monitorDetails.filter(monitor => monitor.sub_category_id == this.selectedMonitoringType.id)
            }
        },
        mounted() {
            this.$modelGet('interventionExitStatus').then(status => {
                this.exitStatus = status
                this.loadData()
            })
        },
        methods: {
            /**
             * onMeasuresGridReady() makes sure the grid restores the selected rows on load
             */
            onMeasuresGridReady() {
                this.$refs.monitorGrid.gridApi.forEachNode((node) => {
                    node.setSelected(!!this.selectedStudentMonitors.find(monitor => monitor.student_monitor_plan_id === node.data.student_monitor_plan_id))
                })
            },

            saveMeasure(selectedMeasure) {
                const changes = {}
                changes.sub_category_id = this.editMeasureDialog.record[0].sub_category_id.id
                changes.monitor_user_id = this.editMeasureDialog.record[0].monitor_user_id
                changes.schedule_start_date = this.editMeasureDialog.record[0].start_date
                changes.schedule_end_date = this.editMeasureDialog.record[0].end_date
                changes.schedule_start_time = this.editMeasureDialog.record[0].start_time
                changes.schedule_end_time = this.editMeasureDialog.record[0].end_time
                changes.schedule_frequency_cd = 'WEEK'
                changes.schedule_frequency_cnt = this.editMeasureDialog.record[0].monitor_frequency_cnt
                changes.schedule_week_days = this.editMeasureDialog.record[0].monitor_week_days
                changes.goal = this.editMeasureDialog.record[0].students[0].value
                changes.assessment_grade_id = this.editMeasureDialog.record[0].students[0].assessment_grade_id
                this.saveMonitorChanges([selectedMeasure], changes, () => this.editMeasureDialog.show = false)
            },

            editMeasure(selectedMeasure) {
                this.editMeasureDialog.record = [{
                    monitor_category_id: selectedMeasure.category_id,
                    sub_category_id: {id: selectedMeasure.sub_category_id},
                    monitor_user_id: selectedMeasure.monitor_user_id,
                    start_date: selectedMeasure.schedule_start_date,
                    start_time: selectedMeasure.schedule_start_time,
                    end_date: selectedMeasure.schedule_end_date,
                    end_time: selectedMeasure.schedule_end_time,
                    monitor_frequency_cnt: selectedMeasure.schedule_frequency_cnt,
                    monitor_week_days: selectedMeasure.schedule_week_days,
                    primary_flag: selectedMeasure.primary_flag,
                    students: [
                        {
                            student_id: selectedMeasure.student_id,
                            student_full_name: selectedMeasure.student_full_name,
                            value: selectedMeasure.goal,
                            assessment_grade_id: selectedMeasure.assessment_grade_id,
                        }
                    ]
                }]
                this.editMeasureDialog.title = 'Edit Measure'
                this.editMeasureDialog.test = 'edit-measure-modal'
                this.editMeasureDialog.operation = 'edit'
                this.editMeasureDialog.handler = () => this.saveMeasure(selectedMeasure)
                this.editMeasureDialog.show = true
            },

            savePrimaryMonitorModal() {
                this.editPrimaryMonitorModal.show = false
                // skip if there's nothing to do
                if (this.editPrimaryMonitorModal.studentMonitorPlansAffected.length == 0) {
                    return
                }
                this.saveMonitorChanges(this.editPrimaryMonitorModal.studentMonitorPlansAffected, {primary_flag: 1})
            },

            /**
             * changePrimaryMonitorModal() shows the modal for setting the primary measure.
             * Based off the selected
             */
            changePrimaryMonitorModal(monitors) {
                const monitorsPerStudent = this.$_.countBy(monitors, monitor => monitor.student_id)
                const studentsWithMultipleMeasures = this.$_.uniq(
                    monitors.filter(
                        monitor => monitorsPerStudent[monitor.student_id] > 1
                    ).map(monitor => monitor.student_full_name)
                )
                studentsWithMultipleMeasures.sort()
                this.editPrimaryMonitorModal.studentMonitorPlansAffected = monitors.filter(monitor => monitorsPerStudent[monitor.student_id] == 1)
                this.editPrimaryMonitorModal.studentsWithMultipleMeasures = studentsWithMultipleMeasures
                this.editPrimaryMonitorModal.show = true
            },

            saveMonitoringUser() {
                this.editMeasureDialog.show = false
                if (!this.selectedStudentMonitors.length) return;
                const changes = {}
                changes.monitor_user_id = this.editMeasureDialog.record[0].monitor_user_id
                this.saveMonitorChanges(this.selectedStudentMonitors, changes)
            },

            showChangeMonitoringUserModal() {
                this.editMeasureDialog.record = []
                this.editMeasureDialog.title = 'Edit Monitoring User'
                this.editMeasureDialog.test = 'edit-monitoring-user-modal'
                this.editMeasureDialog.operation = 'edit-monitoring-user'
                this.editMeasureDialog.handler = () => this.saveMonitoringUser()
                this.editMeasureDialog.show = true
            },

            saveMonitoringType() {
                this.editMeasureDialog.show = false
                if (!this.selectedStudentMonitors.length) return;
                const changes = {}
                changes.sub_category_id = this.editMeasureDialog.record[0].sub_category_id.id
                this.saveMonitorChanges(this.selectedStudentMonitors, changes, () => {
                    this.selectedMonitoringType = this.editMeasureDialog.record[0].sub_category_id
                })
            },

            showChangeMonitoringTypeModal() {
                this.editMeasureDialog.record = []
                this.editMeasureDialog.title = 'Edit Monitoring Type'
                this.editMeasureDialog.test = 'edit-monitoring-type-modal'
                this.editMeasureDialog.operation = 'edit-monitoring-type'
                this.editMeasureDialog.handler = () => this.saveMonitoringType()
                this.editMeasureDialog.show = true
            },

            saveMonitorDates() {
                this.editMeasureDialog.show = false
                if (!this.selectedStudentMonitors.length) return;
                const changes = {}
                changes.schedule_start_date = this.editMeasureDialog.record[0].start_date
                changes.schedule_end_date = this.editMeasureDialog.record[0].end_date
                this.saveMonitorChanges(this.selectedStudentMonitors, changes)
            },

            showChangeMonitorDatesModal() {
                this.editMeasureDialog.record = []
                this.editMeasureDialog.title = 'Edit Measure Dates'
                this.editMeasureDialog.test = 'edit-measure-dates-modal'
                this.editMeasureDialog.operation = 'edit-dates'
                this.editMeasureDialog.handler = () => this.saveMonitorDates()
                this.editMeasureDialog.show = true
            },

            saveMonitorTimes() {
                this.editMeasureDialog.show = false
                if (!this.selectedStudentMonitors.length) return;
                const changes = {}
                changes.schedule_start_time = this.editMeasureDialog.record[0].start_time
                changes.schedule_end_time = this.editMeasureDialog.record[0].end_time
                changes.schedule_frequency_cd = 'WEEK'
                changes.schedule_frequency_cnt = this.editMeasureDialog.record[0].monitor_frequency_cnt
                changes.schedule_week_days = this.editMeasureDialog.record[0].monitor_week_days
                this.saveMonitorChanges(this.selectedStudentMonitors, changes)
            },

            showChangeMonitorFrequencyTimeModal() {
                this.editMeasureDialog.record = []
                this.editMeasureDialog.title = 'Edit Measure Frequency & Times'
                this.editMeasureDialog.test = 'edit-measure-frequency-and-times-modal'
                this.editMeasureDialog.operation = 'edit-times'
                this.editMeasureDialog.handler = () => this.saveMonitorTimes()
                this.editMeasureDialog.show = true
            },

            saveMonitorGoalGrade() {
                this.editMonitorGoalGradeModal.show = false
                const changes = {}
                if (('' + this.editMonitorGoalGradeModal.goal).length) {
                    changes.goal = this.editMonitorGoalGradeModal.goal
                }
                if (this.editMonitorGoalGradeModal.grade_id) {
                    changes.assessment_grade_id = this.editMonitorGoalGradeModal.grade_id
                }
                if (!Object.keys(changes).length || !this.selectedStudentMonitors.length) return;
                this.saveMonitorChanges(this.selectedStudentMonitors, changes)
            },

            showChangeMonitorGoalGradeModal() {
                this.editMonitorGoalGradeModal.valid = false
                this.editMonitorGoalGradeModal.goal = ''
                this.editMonitorGoalGradeModal.grade_id = null
                this.editMonitorGoalGradeModal.show = true
            },

            saveMonitorChanges(monitors, changes, callback)
            {
                monitors = monitors.map(monitor => {
                    return {
                        student_id: monitor.student_id,
                        student_monitor_plan_id: monitor.student_monitor_plan_id,
                        intervention_id: monitor.intervention_id,
                        monitor_user_id: monitor.monitor_user_id,
                        ...changes
                    };
                })
                this.$axios.post(`studentMonitors.php?action=update&confirm=1`, {monitors})
                    .then(r => {
                        if (this.$ecResponse(r) === false) {
                            return
                        }
                        if (callback) callback()
                        this.selectedStudentMonitors = []
                        this.loadMonitors()
                    })
            },

            /**
             * showAddNewMeasureModal() for all students on plan
             */
            showAddNewMeasureModal() {
                this.addMonitorDialog.title = 'Add New Measure'
                this.addMonitorDialog.forceMonitoringTypeId = 0
                this.addMonitorDialog.students = this.$_.uniqBy(this.studentPlans, 'student_id')
                this.addMonitorDialog.show = true
            },

            /**
             * showAddStudentsToMeasureModal() for students that aren't on the currently selected monitor
             */
            showAddStudentsToMeasureModal() {
                this.addMonitorDialog.title = 'Add Students to Measure'
                this.addMonitorDialog.students = this.studentsMissingMonitorForSelectedMeasure
                this.addMonitorDialog.forceMonitoringTypeId = this.selectedMonitoringType.id
                this.addMonitorDialog.show = true
            },

            handleOverviewMenuClick(b) {
                this.modifyPlanDetails()
            },
            finishModify() {
                this.modifyPlanDialog.show=false
                this.$emit('reload')
                // this.$refs.planSelector.clearSelections()
                // this.loadPlans()

            },
            modifyPlanDetails() {
                this.modifyPlanDialog = {
                    show: true,
                    valid: false,
                    rec: {}
                }
            },

            /**
             * removeStudent() performs API request to remove the selected students from the plan
             */
            removeStudent() {
                this.$axios.post(`studentInterventionPlans.php?action=delete&confirm=1`, { students: this.selectedStudents })
                    .then(r => {
                        if (this.$ecResponse(r) === false) {
                            return
                        }
                        this.$emit('reload')
                        this.loadData()
                        this.selectedStudents = []
                    })
            },

            /**
             * removeMeasures() performs API request to remove the selected monitors from the plan
             */
            removeMeasures(monitors) {
                if (!monitors.length) return;
                monitors = monitors.map(monitor => {
                    return {
                        student_monitor_plan_id: monitor.student_monitor_plan_id,
                    };
                })
                this.$axios.post(`studentMonitors.php?action=delete&confirm=1`, {monitors})
                    .then(r => {
                        if (this.$ecResponse(r) === false) {
                            return
                        }
                        this.selectedStudentMonitors = []
                        this.loadMonitors()
                    })
            },

            promptDeleteStudentConfirm() {
                const studentCount = this.selectedStudents.length
                this.$messageBox({
                    title: 'Confirm Remove Student',
                    persistent: true,
                    maxWidth: 600,
                    message: `You are about to delete ${studentCount} student${studentCount > 1 ? 's' : ''}.`,
                    actions: [{
                        text: 'Cancel'
                    }, {
                        text: 'Delete',
                        usage: 'error',
                        onClick: () => this.removeStudent(),
                    }]
                })
            },
            promptDeleteMonitorsConfirm(monitors) {
                const monitorCount = monitors.length
                const studentCount = this.$_.uniqBy(monitors, 'student_id').length
                this.$messageBox({
                    title: 'Delete Measure',
                    persistent: true,
                    maxWidth: 600,
                    message: `Removing a measure is a permanent change that cannot be undone. If the measure is a student’s primary measure, Rate of Improvement (ROI) reports will be affected. Any previously entered scores will be deleted.<br/><br/><strong>Are you sure you want to remove ${monitorCount > 1 ? ('these ' + monitorCount + ' measures') : ' this measure'}?</strong>`,
                    actions: [{
                        text: 'Cancel'
                    }, {
                        text: `Remove Measure${monitorCount > 1 ? 's' : ''} for ${studentCount} Student${studentCount > 1 ? 's' : ''}`,
                        usage: 'error',
                        onClick: () => this.removeMeasures(monitors),
                    }]
                })
            },
            buildPrebuilts(records) {
                let arr = []
                records.forEach(s => {
                    arr.push({
                        student_id: s.student_id,
                        student_full_name: s.student_full_name,
                        report_type: 'Creation Report',
                        type: 'creation',
                        intervention_id: s.intervention_id
                    }, {
                        student_id: s.student_id,
                        student_full_name: s.student_full_name,
                        report_type: 'Fidelity Report',
                        type: 'fidelity',
                        intervention_id: s.intervention_id
                    })
                })
                this.prebuilts = arr
            },
            updateStudents(v) {
                if (v.oldValue === v.newValue) return
                if (!v.data.intervention_exit_status_id) v.data.intervention_exit_status_id = null
                this.$axios.post('studentInterventionPlans.php?action=update', { students: [v.data] })
                    .then(response => {
                        this.$ecResponse(response)
                    })
                    .finally(() => {
                        this.loadData()
                    })
            },
            loadPrebuilt(row) {
                this.$downloadFile('reportCreate.php?type=intervention_'+row.data.type+'&student_id='+row.data.student_id+'&intervention_id='+row.data.intervention_id,
                    row.data.student_full_name + ' ' + row.data.report_type
                )
            },
            setStudentDetails(records) {
                let arr = []
                records.forEach(s => {
                    arr.push({
                        id: s.student_intervention_plan_id,
                        student_id: s.student_id,
                        student_full_name: s.student_full_name,
                        start_date: s.student_intervention_plan_effective_start_date,
                        end_date: s.student_intervention_plan_effective_end_date,
                        intervention_exit_status_id: s.intervention_exit_status_id
                    })
                })
                this.studentDetails=arr
            },
            setStudentPlans(records) {
                this.studentPlans = records
            },

            /**
             * loadMonitors() performs API request to load all monitors for students in the given plan
             */
            loadMonitors() {
                this.loadingMonitors = true
                const params = {}
                params.intervention_id = this.studentPlans.map(plan => plan.intervention_id).join(',')
                params.student_id = this.studentPlans.map(plan => plan.student_id).join(',')

                this.$axios.get(`studentMonitors.php?action=get&academic=1&property=roi_target_list&probes_only=1&${this.$objectToParams(params)}&v=2`)
                .then(response => {
                    this.monitorDetails = response.data.monitors.map(monitor => {
                        // make sure days are in order. BE might store out of order
                        // use Tu and Th instead of T and H, respectively
                        const days = [['M', 'M'], ['T', 'Tu'], ['W', 'W'], ['H', 'Th'], ['F', 'F']].filter(
                            day => monitor.schedule_week_days.indexOf(day[0]) >= 0
                        ).map(day => day[1])
                        const start_date = this.$dayjs(monitor.schedule_start_date).format('M/D/YY')
                        const end_date = this.$dayjs(monitor.schedule_end_date).format('M/D/YY')
                        const start_time = this.$dayjs('2000-01-01 ' + monitor.schedule_start_time).format('h:mm A')
                        const end_time = this.$dayjs('2000-01-01 ' + monitor.schedule_end_time).format('h:mm A')
                        monitor.display_times = `${start_time} - ${end_time}`
                        monitor.display_dates = `${start_date} - ${end_date}`
                        monitor.display_primary_flag = monitor.primary_flag ? 'Yes' : 'No'
                        monitor.display_frequency = monitor.schedule_frequency_cnt == 1 ? 'Every week' : `Every ${monitor.schedule_frequency_cnt} weeks`
                        monitor.display_days = days
                        return monitor
                    })

                    // clear selected monitoring type if no longer present
                    if (this.selectedMonitoringType !== null && !this.monitorDetails.find(monitor => monitor.sub_category_id == this.selectedMonitoringType.id)) {
                        this.selectedMonitoringType = null
                    }

                    // use first monitor alphabetically if none currently selected
                    if (this.selectedMonitoringType === null && this.monitorDetails.length > 0) {
                        this.selectedMonitoringType = this.monitoringTypes[0]
                    }
                })
                .finally(() => this.loadingMonitors = false)
            },
            loadData() {
                let p = { intervention_plan_id: this.interventionPlanId }
                this.$modelGet('interventionPlanStudent', p)
                    .then(r => {
                        this.record = r[0]
                        let total = 0
                        let procedureTotal=0
                        Object.keys(this.monitorProgress).forEach(mp => {
                            this.monitorProgress[mp].count=0
                            this.monitorProgress[mp].data=[]
                        })
                        this.buildPrebuilts(r)
                        this.setStudentDetails(r)
                        this.setStudentPlans(r)

                        this.loadMonitors()

                        r.forEach(s => {
                            total+=parseFloat(s.fidelity?s.fidelity:0)

                            // procedure totals
                            let localProc = 0
                            s.procedures.forEach(proc => {
                                localProc=parseFloat(proc.pct)
                            })
                            procedureTotal+=localProc

                            if (s.primary_probe) this.monitorSummary(s.primary_probe)
                        })

                        // this.fidelity = total/r.length
                        // this.procedureAvg = procedureTotal/r.length
                        this.fidelity = this.$twoDecimals(total/r.length)
                        this.procedureAvg = this.$twoDecimals(procedureTotal/r.length)

                        this.proceduresConfig = this.gaugeConfig('PARTICIPATION<br>RATE', this.fidelity)
                        this.fidelityConfig   = this.gaugeConfig('PROCEDURES<br>COMPLETED', this.procedureAvg)
                        this.showFidelity=true

                        this.$emit('setInterventionId', r[0].intervention_id)

                    })
            },
            monitorSummary(rec) {
                for (let key in this.monitorProgress) {

                    let mon = this.monitorProgress[key]
                    if (rec[key]) {
                        mon.count++
                        mon.records.push(rec)
                    }
                }
                this.monitoredCount++
            },
            gaugeConfig(title, count) {
                return {
                    chart: {
                        type: 'pie',
                        height: '120px',
                        plotShadow: false,
                        margin: [7,5,7,5]
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        verticalAlign: 'middle',
                        floating: true,
                        text: '<span style="font-size: 16px;">'+count+'%</span><br>'+title,
                        style: {
                            marginTop: '20px',
                            fontSize: 12
                        }
                    },
                    exporting: {
                        enabled: false
                    },

                    tooltip: {
                        enabled: false,
                        borderWidth: 0,
                        backgroundColor: '#FFFFFF',
                        // shadow: false,
                        style: {
                            fontSize: '16px'
                        },
                        valueSuffix: '%',
                        // pointFormat: '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
                        // positioner: function (labelWidth) {
                        //     return {
                        //         x: (this.chart.chartWidth - labelWidth) / 2,
                        //         y: (this.chart.plotHeight / 2) + 15
                        //     };
                        // }
                    },

                    plotOptions: {
                        pie: {
                            innerSize: '100%',
                            borderWidth: 12,
                            borderColor: null,
                            slicedOffset: 0,
                            dataLabels: {
                                connectorWidth: 0,
                                enabled: false
                            }
                        }
                    },

                    series: [{
                        name: 'On Track',
                        // size: '90%',
                        // innerSize: '82%',
                        data: [{
                            name: 'Not On Track',
                            color: '#e6e6e6',
                            sliced: true,
                            y: 100-count
                        }, {
                            name: 'On Track',
                            color: '#408AAF',
                            sliced: true,
                            y: count
                        }]
                    }]
                }
            }
        }

    }
</script>

<style lang="scss" scoped>
.ec-collection {
    &-tabs {
        // ::v-deep .v-item-group {
        //     background: white !important;
        //     position: relative;
        //     flex-wrap: nowrap;
        //     white-space: nowrap;
        //     max-width: 100%;
        //     overflow: auto;
        // }
        ::v-deep .v-tabs-slider {
            border-radius: 1.5px;
            &-wrapper {
                height: 3px !important;
            }
        }

        .small-tab {
            text-transform: none;
            letter-spacing: normal;
        }
        .small-tab::before {
            border-radius: 4px;
        }
    }
}

.monitor-progress {
    text-align: center;

    .monitor-progress-count {
        margin: 0px auto;
        width: 57px;
        height: 42px;
        border-radius: 4px;
    }
}

.ec-grid-selection-toolbar {
    width: calc(100% - 32px);
    box-sizing: border-box;
    left: 16px;
}
</style>
