<template>
    <div class='academic-monitor-data-table mb-5'>
        <h3 class="pa-3 pb-1">Score Table </h3>
        <fe-grid
            v-if="showDataTable"
            ref="grid"
            :columnDefs="columns"
            :rowData="rowData"
            :showFullscreen="false"
            :showAddRowBtn="false"
            displayDensity="small"
            :flexColumns="true"
            :searchBar="false"
            :showToolbar="false"
            :showDownload="false"
            style="width: 100%; height: 300px;"
            class="pa-3 pt-0"
        >
            <template #left-toolbar-items><strong>Score Table</strong></template>
        </fe-grid>
        <v-alert type="info" class="ma-3" v-else>No scores returned for this monitor.</v-alert>
    </div>
</template>

<script>
    export default {
        name: 'MonitorData',
        props: {
            chartData: { type: Array, required: true },
            maps: { },
            selectedSchoolYear: { },
            showAllYearsMonitor: { }
        },
        data () {
            return {
                dataTableData: null,
            }
        },
        computed: {
            loadDataTable () { return (this.chartData && this.chartData.length) },
            showDataTable () { return (this.chartData && this.chartData.length && this.rowData && this.rowData.length) },
            columns () {
                if (!this.dataTableData) return []
                return this.dataTableData.columns
                    .filter(col => !col.isParent || (col.columns && col.columns.length))
                    .map(col => {
                        let obj = {
                            headerName: col.text || '',
                            field: col.dataIndex,
                            pinned: col.locked ? 'left' : false,
                            minWidth: this.dataTableData.fields.length < 10 ? 85 : 100,
                            hide: col.hidden,
                            sortable: true,
                            editable: false
                        }

                        if (col.isParent) {
                            obj.headerClass = 'left-border'
                            obj.marryChildren = true
                            obj.children = col.columns.map(child => {
                                return {
                                    headerName: child.text.replace('<br>', '-'),
                                    field: child.dataIndex,
                                    pinned: child.locked ? 'left' : false,
                                    minWidth: 100,
                                    hide: child.hidden,
                                    sortable: true,
                                    editable: false,
                                    cellRenderer: this.cellDisplay,
                                    cellStyle: this.cellStyle,
                                    headerClass: this.headerClass
                                }
                            })
                        }

                        return obj
                    })
            },
            rowData () {
                return (this.dataTableData)
                    ? this.dataTableData.data
                    : []
            },
            getYearId() {
                return this.showAllYearsMonitor ? null : this.selectedSchoolYear?.included.map(r => r.id)
            }
        },
        watch: {
            showAllYearsMonitor(newVal, oldVal) {
                if(newVal != oldVal) {
                    this.buildTable()
                }
            },
        },  
        methods: {
            buildTable() {
                if (this.loadDataTable) {
                    this.$axios.get(`studentMonitors.php?action=get&student_monitor_plan_id=${this.chartData[0].student_monitor_plan_id}
                        &property=display_grid&type=academic&school_year_id=${this.getYearId}`)
                        .then(rsp => {
                            this.dataTableData = rsp.data.monitor_scores
                            // return alpha value for score display in grid
                            if(this.dataTableData && Object.keys(this.maps).length > 0) {
                                this.dataTableData['data'].forEach(dt => {
                                    if(dt['key'].includes('Score')) {
                                        Object.keys(dt).forEach(date => {
                                            if(date !== 'key' && dt[date]) {
                                                let i = Math.floor(dt[date])
                                                let mappedValue = this.maps[i]
                                                if (mappedValue || mappedValue === 0) {
                                                    dt[date] = `${mappedValue}`
                                                } 
                                            }
                                        })
                                    } 
                                })
                            }
                        })
                }
            },
            headerClass (p) {
                let idx = p.column.parent?.children?.findIndex(col => col.colDef.field == p.column.colDef.field)
                return (idx === 0) ? 'left-border-text-right' : 'text-right'
            },
            cellStyle (p) {
                let style = { 'text-align': 'right' }
                let idx = p.column.parent?.children?.findIndex(col => col.colDef.field == p.column.colDef.field)
                if (idx === 0) style['border-left'] = 'solid 1px #aaa;'
                return style
            },
            cellDisplay (p) {
                if (p.data.key === 'Score') {
                    let idx = p.column.parent?.children?.findIndex(col => col.colDef.field === p.column.colDef.field)
                    if (idx && idx > 0) {
                        let prevField = p.column.parent.children[idx-1].colDef.field
                        let prevVal = prevField ? parseFloat(p.data[prevField]) : null
                        let value = parseFloat(p.value)
                        if (prevVal && prevVal < value) return `<i class="far fa-arrow-alt-circle-up" style="color: green;"></i> ${value}`
                        else if (prevVal && prevVal > value) return `<i class="far fa-arrow-alt-circle-down" style="color: red;"></i> ${value}`
                        else if (prevVal && prevVal === value) return `<span style="opacity: 0.6;">${value}</span>`
                    }
                }
                return p.value
            }
        },
        mounted() {
            setTimeout(function() {
                this.buildTable()
            }.bind(this), 100)
        }
    }
</script>

<style lang="scss">
    .academic-monitor-data-table {
        .left-border { border-left: solid 1px #aaa; }
        .text-right { text-align: right; }
        .left-border-text-right { text-align: right; border-left: solid 1px #aaa; }
    }
</style>
