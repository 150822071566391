import Vue from "vue";

export default Vue.extend({
    template: `
        <div>
            <div class="d-flex" @click="handleClick">
                <div class="mr-1"><i class="grid-icon fas fa-sitemap"></i></div>
                <div v-html="params.displayName"></div>
            </div>
        </div>
    `,
    // template: `
    //     <div>
    //         <div v-if="params.enableMenu" ref="menuButton" class="customHeaderMenuButton" @click="onMenuClicked($event)"><i class="fa" :class="params.menuIcon"></i></div> 
    //         <div class="customHeaderLabel">{{params.displayName}}</div> 
    //         <div v-if="params.enableSorting" @click="onSortRequested('asc', $event)" :class="ascSort" class="customSortDownLabel"><i class="fa fa-long-arrow-alt-down"></i></div> 
    //         <div v-if="params.enableSorting" @click="onSortRequested('desc', $event)" :class="descSort" class="customSortUpLabel"><i class="fa fa-long-arrow-alt-up"></i></div> 
    //         <div v-if="params.enableSorting" @click="onSortRequested('', $event)" :class="noSort" class="customSortRemoveLabel"><i class="fa fa-times"></i></div>
    //     </div>
    // `,
    data: function () {
        return {
            groupExpanded: false
        }
    },
    beforeMount() {
    },
    mounted() {
        
    },
    methods: {
        handleClick(e) {
            let p = {...this.params.drilldownParams, ...{sub_category_parent_id: this.params.raw.sub_category_id}}

            // Optional $emit type action (via passed function) for parent components
            // that might want to do something other than open in new window
            if (this.params.onHeaderClick) {
                this.params.onHeaderClick({
                    name: this.params.displayName.replace(/<[^>]*>?/gm, '') + ' Subscores',
                    attrs: {
                        params: p,
                        class: 'pa-4',
                    },
                })
            }

            if (!this.params.suppressDefaultDrilldown) {
                this.$dockableWindow({
                    component: 'data-wall',
                    name: this.params.displayName.replace(/<[^>]*>?/gm, ''),
                    attrs: {
                        params: p,
                        class: 'pa-4'
                    }
                })
            }
        }
    }
});