<template>
    <fe-dialog
        v-if="active"
        title="Add New"
        dismissButtonText="Cancel"
        acceptButtonText="Add"
        @dismiss="$emit('cancel')"
    >
        <fe-grid
            domLayout="normal"
            :showToolbar="false"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :styles="{ height: '300px' }"
            @selectionChanged="onSelectionChanged"
        />

        <template v-slot:footer>
            <v-spacer/>

            <fe-btn usage="ghost" @click="$emit('cancel')">Cancel</fe-btn>

            <fe-btn usage="primary" :disabled="selectedIds.length === 0" @click="apply">Add</fe-btn>
        </template>
    </fe-dialog>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    name: 'UserGroupSelector',

    props: {
        active: Boolean,
        hiddenIds: Array
    },

    data() {
        return {
            selectedIds: []
        }
    },

    computed: {
        ...mapGetters('thresholds/userGroup', [
            'collection',
            'clear',
            'pending',
            'success',
            'error'
        ]),

        columnDefs() {
            return [{
                headerName: null,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                width: 50
            }, {
                headerName: 'Group',
                field: 'name',
                sortable: true
            }]
        },

        rowData() {
            return this.collection.filter(itm => !(this.hiddenIds.includes(itm.id)))
        },

        hasSelection() {
            return (this.selectedIds.length > 0)
        },

        selectedData() {
            return this.collection.filter(itm => this.selectedIds.includes(itm.id))
        }
    },

    watch: {
        clear: function (val) {
            if (val) this.reset()
        },

        active: function (val) {
            if (val) this.reset()
        }
    },

    mounted() {
        this.reset()
    },

    updated() {
        this.reset()
    },

    methods: {
        ...mapActions('thresholds/userGroup', [
            'refresh'
        ]),

        onGridReady(params) {
            this.gridApi = params.api
            this.resizeColumns()
        },

        resizeColumns() {
            if (this.gridApi) this.gridApi.sizeColumnsToFit()
        },

        onSelectionChanged(params) {
            this.selectedIds = (params.api)
                ? params.api.getSelectedRows().map(itm => itm.id)
                : []
        },

        apply() {
            this.$emit('apply', this.selectedData)
        },

        dismiss() {
            this.$emit('cancel')
        },

        reset() {
            this.selectedIds = []
            if (this.active) {
                if (this.clear) {
                    this.refresh()
                }
                this.resizeColumns()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.alert-area {
    padding: 50px;
    border: solid 1px #fff;
}
</style>
