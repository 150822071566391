<template>
    <div class="d-flex flex-fill flex-column">
        <fe-grid
            :columnDefs="columns"
            :rowData="items"
            class="flex-grow-1"
            displayDensity="small"
            ref="grid"
            :showAddRowBtn="false"
            @cellValueChanged="sanitize"
            @selectionChanged="selectRow"
            showToolbar
        >
            <template v-slot:toolbar-items>
                <fe-btn usage="primary" v-if="canSave" @click="doSave">Save</fe-btn>
            </template>
        </fe-grid>
    </div>
</template>

<script>
    const disllowEntryStyle = disallowEntry => ({
        'background': disallowEntry ? '#CCCCCC' : 'white',
        'color': disallowEntry ? '#AAA' : '',
        'pointer-events': disallowEntry ? 'none' : '',
    })

    export default {
        name: 'ValidateScoreAlphaMaps',
        props: {
            uploadId: {
                required: true,
            },
        },
        data() {
            let me = this
            return {
                columns: [],
                items: [],
                originalItems: [],
                existingRecords: [],
                canSave: false,
            }
        },
        mounted() {
            this.loadData()
        },
        methods: {
            sanitize() {
                // this will check to see if the original fetched data set has been manipulated
                let dirty = this.$isDirty(this.originalItems, this.items)
                this.canSave = !!dirty
                // Since we are changing cells behind the ag-grid api, call refresh
                this.$refs.grid.gridApi.refreshCells()
            },
            selectRow() {

            },
            doSave() {
                let dirty = this.$isDirty(this.originalItems, this.items)
                if (!dirty.length) return

                dirty.forEach(d => {
                    d.new_score_alpha_map = d.new_score_alpha_map === 1 ? true : false
                    d.excl_calc           = d.excl_calc === 1           ? true : false
                    d.excl_score          = d.excl_score === 1          ? true : false
                })
                this.$axios.post('verifyUpload.php?action=update&property=unknown_score_alpha_maps&upload_id='+this.uploadId, {
                    score_alpha_maps: dirty
                }).then(r => {
                    this.$ecResponse(r)
                    this.loadData()
                })
            },
            loadData() {
                let me = this

                this.$axios.get('verifyUpload.php?action=get&property=unknown_score_alpha_maps&upload_id='+this.uploadId)
                    .then(r => {
                        this.items = this.$ecResponse(r, 'score_alpha_maps')
                        this.items.forEach(i => {
                            i.numeric_score = 0
                            i.excl_calc = 0
                            i.excl_score = 0
                            i.new_score_alpha_map = 0
                            i.matched_score_alpha_map_id = null
                        })

                        this.originalItems = this.$_.cloneDeep(this.items)
                        this.sanitize()

                        this.$emit('count', this.originalItems.length)

                        if (this.items.length) {
                            this.$modelGet('alphaMap', { data_point_type_id: this.items[0].data_point_type_id}).then(s => {
                                s.forEach(i => i.label = i.alpha_score + ' = ' + i.numeric_score)
                                this.existingRecords = s

                                this.columns = [{
                                    headerName: "Assessment Group",
                                    field: "data_point_type_name",
                                    editable: false,
                                    flex: 1,
                                }, {
                                    headerName: "Alpha Score",
                                    field: "alpha_score",
                                    editable: false,
                                    flex: 1,
                                },
                                me.$grid.toggleColumn('Create', 'new_score_alpha_map', null, () => this.$refs.grid.gridApi.redrawRows(this)),
                                {
                                    headerName: "Numeric Score",
                                    field: "numeric_score",
                                    editable: true,
                                    cellStyle: v => disllowEntryStyle(!v.data.new_score_alpha_map),
                                    flex: 1,
                                },
                                me.$grid.toggleColumn('Exclude from Calculation', 'excl_calc', null, () => { }, {
                                    cellStyle: v => disllowEntryStyle(!v.data.new_score_alpha_map),
                                }),
                                me.$grid.toggleColumn('Exclude Score', 'excl_score', null, () => { }, {
                                    cellStyle: v => disllowEntryStyle(!v.data.new_score_alpha_map),
                                }),
                                {
                                    headerName: "Alias To",
                                    field: "matched_score_alpha_map_id",
                                    editable: true,
                                    flex: 1,
                                    cellStyle: v => disllowEntryStyle(v.data.new_score_alpha_map),
                                    cellRenderer: v => v.value ? me.existingRecords.find(rec => rec.id == v.value)?.label : '',
                                    cellEditorFramework: "FeGridChipSelect",
                                    cellEditorParams: {
                                        rowDataKey: "matched_score_alpha_map_id",
                                        mode: "edit",
                                        items: me.$data.existingRecords,
                                        keyProp: "id",
                                        labelProp: "label",
                                        disableChips: true,
                                    },
                                },
                                ]
                            })
                        }
                    })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>