var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-fill flex-column slo-plan-panel"},[_c('div',{staticClass:"pa-2 d-flex flex-grow-1"},[(!_vm.forms)?_c('fe-spinner',{attrs:{"text":"Loading forms..."}}):_vm._e(),_c('fe-crud-grid',{ref:"grid",style:({
                height: '100%',
                width: '100%'
            }),attrs:{"bordered":false,"columnDefs":_vm.columnDefs,"config":_vm.$models.sloForms,"defaultParams":{
                slo_id: _vm.slo.id
            },"emptyState":{
                text: 'No SLO Forms found with the current selections.',
                imageSrc: '/images/empty-state-horn.png',
                imageStyle: 'max-width:50%; max-height:50%;',
            },"rowData":_vm.forms,"showBorderToggle":false,"showDensityToolbar":false,"showDownload":false,"showFullscreen":false,"showSaveAsDefault":false,"addRowBtnText":"Add","autoload":"","disableFilterUpdate":"","disableInlineCreate":"","refreshOnDestroyed":""},on:{"beginCreate":_vm.beginEntry,"firstDataRendered":_vm.adjustGrid,"rowDoubleClicked":_vm.loadSmartForm,"created":_vm.filterResults,"destroyed":_vm.filterResults,"read":function($event){_vm.forms = $event; _vm.filterResults()}}}),(_vm.createDialog.show)?_c('fe-dialog',{attrs:{"acceptButtonText":"Save","dismissButtonText":"Cancel","title":"Add Form","disableAutoclose":"","acceptButtonDisabled":!_vm.valid,"persistent":""},on:{"accept":_vm.addForm,"close":function($event){_vm.createDialog.show=false},"dismiss":function($event){_vm.createDialog.show=false}}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('fe-remote-combo',{staticClass:"pr-2",attrs:{"label":"Form Type","items":_vm.formTypes,"itemText":"name","itemValue":"id","hide-details":"","multiple":false,"byId":""},model:{value:(_vm.createDialog.record.form_id),callback:function ($$v) {_vm.$set(_vm.createDialog.record, "form_id", $$v)},expression:"createDialog.record.form_id"}}),_c('div',{staticClass:"flex-fill flex-column pr-2"},[_c('fe-label',[_vm._v("Form Name")]),_c('v-text-field',{style:({ width: '100%' }),attrs:{"flat":"","solo":"","dense":"","rules":_vm.$fieldValidators('text', 'Form Name', {required: true, limit: 50})},model:{value:(_vm.createDialog.record.name),callback:function ($$v) {_vm.$set(_vm.createDialog.record, "name", $$v)},expression:"createDialog.record.name"}})],1)],1)],1):_vm._e()],1),_c('fe-crud',{ref:"formCrud",attrs:{"config":_vm.$models.form,"autoload":""},on:{"read":function($event){_vm.formTypes = $event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }