<template>
    <div class="flex-fill flex-column no-scroll">
        <div class="headline">Support Users</div>

        <fe-crud-grid
            :config="$models.accountSupport"
            :columnDefs="sessionUser.district.login_id !== 9000 ? columnDefs : columnDefs9000"
            style="height:100%;"
            refresh-on-created
            @created="$emit('countChanged')"
            @destroyed="$emit('countChanged')"
        />

    </div>
</template>

<script>

import {mapState} from 'vuex'
export default {
    name: 'Employees',

    computed: {
        ...mapState('global', ['sessionUser']),
    },

    data() {
        return {
            columnDefs: [
                { headerName: null, headerCheckboxSelection: true, checkboxSelection: true, width: 70, maxWidth: 70, colId: 'checkbox_column'},
                { headerName: 'Username', field: 'username', 'editable': true}
            ],
            columnDefs9000: [
                { headerName: null, headerCheckboxSelection: true, checkboxSelection: true, width: 70, maxWidth: 70, colId: 'checkbox_column'},
                { headerName: 'Username', field: 'username', 'editable': true},
                { headerName: 'Manage Instances', field: 'manage_instances', cellRendererFramework: 'FeGridToggle', maxWidth: 175},
                { headerName: 'Manage Automations', field: 'manage_automations', cellRendererFramework: 'FeGridToggle', maxWidth: 175},
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
