<template>
    <div @click="$emit('click')" v-bind="$attrs" class="ec-collection" :style="[{'background-color': color}]">
        <div class="ec-collection-title">
            {{ title }}
        </div>

        <div class="ec-collection-icon">
            <v-icon color="white">extension</v-icon>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CollectionButton',
        props: {
            title: {
                default: 'No Title'
            },
            color: {
                default: '#5FA2DD'
            }
        }
    }
</script>

<style lang="scss" scoped>
.ec-collection {
    cursor: pointer;
    width: 100px;
    height: 90px;
    vertical-align: middle;
    padding: 10px;
    border-radius: 5px;

    .ec-collection-title {
        color: white;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .ec-collection-icon {
        color: white;
        text-align: center;
    }
}
</style>