<template>
    <div class="flex-fill flex-column pa-4">
        <list ref="list" :params="listParams" :folderCreate="false" @unpin="removePinnedForm" collection></list>
    </div>
</template>

<script>
    import List from '../../smartforms/List'
    import SmartForm from '../../smartforms/SmartForm'
    import { mapState } from 'vuex'
    export default {
        name: 'Smartforms',

        components: {
            List,
            SmartForm
        },

        props: {
            dashboard_id: { }
        },

        computed: {
            ...mapState('global', ['sessionUser']),

            listParams() {
                let p = {
                    property: 'list',
                    user_id: this.sessionUser.user.id,
                    dashboard_id: this.dashboard_id
                }
                return p
            }
        },

        methods: {
            removePinnedForm(data) {
                this.$confirmDelete([1], () => {
                    this.$axios.post('dashboard.php?action=delete&property=form_instances', { dashboard_form_instances: [data] })
                        .then(response => {
                            if(response.data.success) {
                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                                this.$store.commit('collection/set', {
                                    key: 'reloadCollection',
                                    value: true
                                })
                                this.$refs.list.loadData()
                            } else {
                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                            }
                        })
                }, null, 'Are you sure you want to remove this pinned form?')
            }
        }
    }
</script>
