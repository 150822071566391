<template>
    <fe-dialog
        v-if="active"
        @dismiss="active = false"
        @close="active = false"
        @accept="create"
        title="Create Assessment"
        acceptButtonText="Save Assessment"
        dismissButtonText="Cancel"
        :acceptButtonDisabled="!valid"
        persistent
    >
        <v-form @submit.prevent>
            <div class="d-flex flex-column">
                <fe-label>Assessment Name</fe-label>
                <v-text-field
                    v-model="obj.name"
                    flat solo dense validateOnBlur
                    :style="{ width: '100%' }"
                    :rules="rules.name"
                />

                <template v-if="currentCategories.length > 0">
                    <fe-remote-combo
                        label="Category"
                        v-model="obj.category_id"
                        flat solo dense validateOnBlur clearable by-id
                        :items="currentCategories"
                        itemText="name"
                        itemValue="id"
                        :rules="rules.category_id"
                    />
                </template>
                <template v-if="currentAssessmentTypes.length > 0">
                    <fe-remote-combo
                        label="Assessment Type"
                        v-model="obj.assessment_type_id"
                        flat solo dense validateOnBlur clearable by-id
                        :items="currentAssessmentTypes"
                        itemText="name"
                        itemValue="id"
                        :rules="rules.assessment_type_id"
                    />
                </template>
            </div>
        </v-form>
    </fe-dialog>
</template>

<script>
    export default {
        name: 'AssessmentCreator',
        props: {
            group: { type: Object, default: () => {} },
            loadedAssessments: { type: Array },
            loadedCategories: { type: Array },
            loadedCalcTypes: { type: Array },
            loadedAssessmentTypes: { type: Array }
        },
        data () {
            return {
                assessments: null,
                active: false,
                obj: {
                    name: null,
                    parent_id: null, // select
                    category_id: null,  // select
                    score_calc_type_id: null,  // select
                    score_calc_weight: null,  // numeric
                    assessment_type_id: null, // select
                    score_alpha_map_flag: false, // boolean
                    actual_top_level_flag: false, // boolean
                    kpi: false, // boolean
                    rank: 0
                },
                rules: {
                    name: [
                        v => (!!v && v.trim().length > 0) || 'Please enter a name'
                    ].concat(this.$fieldValidators('text', 'Name', {required: true, limit: 255})),
                    score_calc_weight: [
                        v => (!v || !isNaN(v)) || 'Please enter a valid number'
                    ],
                    category_id: [
                        v => !!v || 'Please select a category'
                    ],
                    assessment_type_id: [
                        v => !!v || 'Please select an assessment type'
                    ]
                },
                errors: []
            }
        },
        computed: {
            currentAssessments () { return this.loadedAssessments ? this.loadedAssessments : this.assessments },
            currentCategories () { return this.loadedCategories ? this.loadedCategories : this.categories },
            currentCalcTypes () { return this.loadedCalcTypes ? this.loadedCalcTypes : this.calcTypes },
            currentAssessmentTypes () { return this.loadedAssessmentTypes ? this.loadedAssessmentTypes : this.assessmentTypes },
            valid () {
                let errors = []
                Object.keys(this.rules).forEach(field => {
                    this.rules[field].forEach(rule => {
                        let test = rule(this.obj[field])
                        if (test !== true) errors.push(test)
                    })
                })
                return errors.length == 0
            }
        },
        methods: {
            reset () {
                this.obj.name = null
                this.obj.parent_id = null // select
                this.obj.category_id = null  // select
                this.obj.score_calc_type_id = null  // select
                this.obj.score_calc_weight = null  // numeric
                this.obj.assessment_type_id = null // select
                this.obj.score_alpha_map_flag = false // boolean
                this.obj.actual_top_level_flag = false // boolean
                this.obj.kpi = false // boolean
                this.obj.rank = this.group.assessment_names.length + 1 // add order number to assessments automatically EC-5941
            },
            open () {
                this.active = true
                this.$emit('open')
                this.reset()
            },
            close () {
                this.active = false
                this.$emit('close')
            },
            create () {
                this.$emit('create', Object.assign(this.obj, {
                    data_point_type_id: this.group.id
                }))
            }
        }
    }
</script>