<template>
    <v-form ref="form" v-model="valid">
        <v-layout row wrap my-2 v-if="this.model">
            <v-flex xs12 class="px-1">
                <fe-label content="Based On"/>
                <v-select
                    v-model="model.type"
                    :items="types"
                    :rules="requiredRules"
                    flat solo dense
                    required
                />
            </v-flex>

            <v-flex xs6 class="px-1">
                <fe-label content="Starting Day"/>
                <fe-date-picker
                    :value="startDate"
                    :emitWhenValid="true"
                    :rules="startDateRules"
                    :showYear="false"
                    dateFormat="MM/DD/YYYY"
                    flat solo dense
                    @input="v => startDate = v"
                />
            </v-flex>

            <v-flex xs6 class="px-1">
                <fe-label content="Ending Day"/>
                <fe-date-picker
                    :value="endDate"
                    :emitWhenValid="true"
                    :rules="endDateRules"
                    :showYear="false"
                    dateFormat="MM/DD/YYYY"
                    flat solo dense
                    @input="v => endDate = v"
                />
            </v-flex>

            <v-flex xs12 class="pa-1">
                <FieldGroup groupTitle="Threshold Statement">
                    <fe-label content="Within a(n)"/>
                    <v-autocomplete
                        v-model="model.day_qty"
                        :items="dayQtyItems"
                        placeholder="Any"
                        flat solo dense
                    />

                    <fe-label content="day period, that"/>
                    <v-select
                        v-model="model.same_behavior_flag"
                        :items="behaviorFlags"
                        :rules="requiredRules"
                        item-value="value"
                        item-text="text"
                        flat solo dense
                        required
                    />

                    <fe-label content="incident(s)/response(s) will be"/>
                    <v-select
                        v-model="model.operator_cd"
                        :items="cdOperators"
                        :rules="requiredRules"
                        item-value="value"
                        item-text="text"
                        flat solo dense
                        required
                    />

                    <v-text-field
                        v-model="model.value"
                        :rules="numberRules"
                        type="number"
                        flat solo dense
                    />

                    <span v-if="model.operator_cd === 'BETWEEN'">
                        <fe-label content="and"/>
                        <v-text-field
                            v-model="model.secondary_value"
                            :rules="numberRules"
                            type="number"
                            flat solo dense
                            required
                        />
                    </span>
                    <fe-label content="number of occurrences"/>
                </FieldGroup>
            </v-flex>

            <v-flex
                xs12
                class="px-1"
                v-for="list in currentSelectionTypes"
                :key="list"
            >
                <FieldGroup :groupTitle="model.type === 'RESPONSE' ? 'Response Selections' : 'Incident Selections'">
                    <div v-if="model.type !== 'RESPONSE'">
                        <fe-label content="Within"/>
                        <v-select
                            v-model="behaviorList"
                            :items="behaviorListItems"
                            flat solo dense
                            required
                        />
                    </div>

                    <StoreSelector
                        :storeNamespace="`thresholds/behavior${capitalize(list)}`"
                        label="Selections"
                        :selectedKeys="selectionValues[list]"
                        :autofocus="false"
                        keyProp="id"
                        labelProp="name"
                        multiple
                        untruncated
                        @updates="v => updateSelections(list, v)">
                    </StoreSelector>
                </FieldGroup>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import axios from 'axios'
import Api from '../services/Api'
import StoreSelector from '../controls/StoreSelector'
import FieldGroup from '../templates/FieldGroup'

export default {
    name: 'BehavioralMetricFields',

    components: {
        StoreSelector,
        FieldGroup
    },

    props: {
        obj: Object
    },

    data() {
        return {
            valid: false,
            endpoint: 'behaviorThreshold.php',
            behaviorList: "behavior",
            behaviorListItems: [
                {text: 'Incident Types', value: 'behavior_type'},
                {text: 'Incident Codes', value: 'behavior'},
            ],
            model: {
                start_day: null,
                end_day: null,
                day_qty: null,
                operator_cd: "GREATER",
                same_behavior_flag: 1,
                secondary_value: "0",
                type: "BEHAVIOR",
                value: "1",
                value_type_cd: "COUNT"
            },
            startDateFull: null,
            endDateFull: null,
            types: [
                {value: 'BEHAVIOR', text: 'Incident Type'},
                {value: 'RESPONSE', text: 'Response'}
            ],
            cdOperators: [
                {value: 'GREATER', text: 'Greater Than'},
                {value: 'GREATER=', text: 'Greater Than or Equal to'},
                {value: 'LESS', text: 'Less Than'},
                {value: 'LESS=', text: 'Less Than or Equal to'},
                {value: 'BETWEEN', text: 'Between'},
                {value: 'EQUAL', text: 'Equal To'}
            ],
            behaviorFlags: [
                {value: 1, text: 'the same'},
                {value: 0, text: 'any selected'}
            ],
            selections: {
                response: [],
                behavior: [],
                behavior_type: []
            },
            numberRules: [
                v => (v !== null && !isNaN(v)) || 'Value is required',
                v => parseFloat(v) >= 0 || 'Value must be 0 or more',
                v => parseFloat(v) <= 1000 || 'Value must be 1000 or less'
            ],
            requiredRules: [
                v => v !== null || 'Selection is required'
            ],
            startDateRules: [
                v => {
                    let msg = !v
                        ? 'Required with End Date'
                        : 'Requires an End Date'
                    return (!!v === !!this.model.end_day) || msg
                }
            ],
            endDateRules: [
                v => {
                    let msg = !v
                        ? 'Required with Start Date'
                        : 'Requires a Start Date'
                    return (!!v === !!this.model.start_day) || msg
                }
            ]
        }
    },

    computed: {
        isValid() {
            return this.$refs.form.validate()
        },

        saveData() {
            return this.model
        },

        selectionTypes() {
            return Object.keys(this.selections)
        },

        currentSelectionTypes() {
            return this.selectionTypes.filter(x => this.showList(x))
        },

        selectionValues() {
            let obj = {}
            this.selectionTypes.forEach(x => {
                obj[x] = this.selections[x].map(sel => sel[`incident_${x}_id`])
            })
            return obj
        },

        dayQtyItems() {
            let result = [{text: 'Any', value: null}]
            for (let i = 0; i < 365; i++) {
                result.push({text: `${i + 1}`, value: i + 1})
            }
            return result
        },

        startDate: {
            get() {
                return this.startDateFull
            },
            set(val) {
                this.startDateFull = val
                this.model.start_day = val
                    ? this.getFormattedDate(val)
                    : null
                if (this.$refs.form) this.$refs.form.validate()
            }
        },

        endDate: {
            get() {
                return this.endDateFull
            },
            set(val) {
                this.endDateFull = val
                this.model.end_day = val
                    ? this.getFormattedDate(val)
                    : null
                if (this.$refs.form) this.$refs.form.validate()
            }
        }
    },

    watch: {
        obj: function () {
            this.setModel()
        },
        behaviorList() {
            this.refreshSelections()
        }
    },

    mounted() {
        this.setModel()
    },

    methods: {
        showList(list) {
            return (this.model.type === 'RESPONSE')
                ? (list === 'response')
                : (list === this.behaviorList)
        },

        determineFullDate(formatted) {
            let changeMonth = 7 // This is when we change to new school year
            let now = new Date()
            let currentMonth = now.getMonth() + 1
            let currentYear = now.getFullYear()
            let m = formatted.substring(0, 2)
            let d = formatted.substring(2, 4)
            let y = currentYear
            // Determine if we need to add or subtract from the current year
            // depending on where we are in the school year.
            if (parseInt(m) >= changeMonth && currentMonth < changeMonth) y--
            if (parseInt(m) < changeMonth && currentMonth >= changeMonth) y++
            return new Date(`${m}/${d}/${y}`).toISOString().substring(0, 10)
        },

        getFormattedDate(full) {
            let date = new Date(full)
            let m = `0${date.getUTCMonth() + 1}`.slice(-2)
            let d = `0${date.getUTCDate()}`.slice(-2)
            return `${m}${d}`
        },

        setModel() {
            Object.keys(this.model).forEach(k => {
                this.model[k] = this.obj[k] || null
                if (this.obj[k] === 0) this.model[k] = 0
            })
            this.model.value = `${parseInt(this.model.value)}`
            if (this.model.start_day) this.startDateFull = this.determineFullDate(this.model.start_day)
            if (this.model.end_day) this.endDateFull = this.determineFullDate(this.model.end_day)
            this.refreshAllSelections()
        },

        refreshAllSelections() {
            this.selectionTypes.forEach(itm => {
                this.refreshSelections(itm)
            })
        },

        refreshSelections(list) {
            Api().get(this.endpoint, {
                params: {
                    behavior_threshold_id: this.obj.id,
                    action: `get_${list}`
                }
            }).then((rsp) => {
                this.selections[list] = (rsp.data && rsp.data[list])
                    ? rsp.data[list]
                    : []
                if (this.selections[list].length > 0 && list[0] === 'b') this.behaviorList = list
            })
        },

        clearOtherLists(list) {
            this.selectionTypes.forEach(itm => {
                if (itm !== list) this.updateSelections(itm, [])
            })
        },

        updateSelections(list, updated) {
            if (updated.length > 0) this.clearOtherLists(list)

            let requests = []

            let changes = {
                deletions: this.selections[list].filter(v => !updated.includes(v[`incident_${list}_id`])),
                additions: updated.filter(v => !this.selectionValues[list].includes(v)).map(v => {
                    return {
                        behavior_threshold_id: this.obj.id,
                        [`incident_${list}_id`]: v
                    }
                })
            }

            if (changes.deletions.length > 0) {
                requests.push(Api().post(this.endpoint, {
                    [list]: changes.deletions
                }, {
                    params: {action: `remove_${list}`}
                }))
            }

            if (changes.additions.length > 0) {
                requests.push(Api().post(this.endpoint, {
                    [list]: changes.additions
                }, {
                    params: {action: `add_${list}`}
                }))
            }

            if (requests.length > 0) {
                axios.all(requests).then(() => {
                    this.refreshSelections(list)
                })
            }
        },

        capitalize(str) {
            /** Remove underscores e.g. behavior_type **/
            if(str.includes('_')) {
                return str.toLowerCase().split('_').map(function (word) {
                    return (word.charAt(0).toUpperCase() + word.slice(1));
                }).join('');
            }
            /** Return with no spaces intentionally **/
            return str.toLowerCase().split(' ').map(function (word) {
                return (word.charAt(0).toUpperCase() + word.slice(1));
            }).join('');
        }
    }
}
</script>
