<template>
    <div>
        <button @click="dialog=true">Courses</button>
        <v-dialog v-model="dialog" width="900">
            <fe-stepper-filter
                :title="title"
                style="height:100%;"
                :applyDisabled="!enableFinalPanel"
                @apply="emit"
                @close="clear(); dialog = false"
            >
                <course-stepper-panel
                    v-if="dialog"
                    ref="courseStepper"
                    v-model="selections"
                    style="height:500px;"
                    :includeFilters=false
                    surfaceFirstSelections
                    :byId=false
                    :editing=!!oldFilter
                />

                <template #footer>
                    <div class="text-truncate" style="padding:14px">{{ breadcrumb }}</div>
                </template>
            </fe-stepper-filter>
        </v-dialog>
    </div>
</template>

<script>
import * as Util from '../../Util'
import CourseStepperPanel from '@/components/common/stepper/CourseStepperPanel'

export default {
    name: 'CoursePanel',

    rank: 1,

    components: {CourseStepperPanel},

    data() {
        return {
            oldFilter: null,
            type: 'course',
            dialog: false,
            selections: {
                advancedFilters: {
                    student_active_flag: {id: 1}
                }
            },
            periods: null,
        }
    },

    computed: {
        title() {
            return this.oldFilter ? `Edit Courses Filter` : `Create Courses Filter`
        },

        enableFinalPanel() {
            return !this.$_.isEmpty(this.selections.course_id) && !this.$_.isEmpty(this.selections.period)
        },

        breadcrumb() {
            return this.selections.course_id?.map(x => x.course_name)
                .filter(Boolean)
                .join(', ')
        },
    },

    methods: {
        load(oldFilter) {
            this.oldFilter = oldFilter
            this.dialog = true
            let ep = oldFilter.editorParams
            this.selections = ep.selections
        },

        clear() {
            this.oldFilter = null
            this.selections = {}
        },

        emit() {
            let uniqueId = Util.UUIDv4()
            let name = this.selections.course_id?.map(x => x.course_name).join(', ')
            let period = this.selections.period?.map(x => x.period)
            let user = this.selections.user_id.user_full_name
            if (period[0] === 'All Periods' || !period.length) {
                // If All Periods is selected, pass all selectable periods
                // Need to do this rather than just ignore the period param since a course can have
                // periods that aren't part of the stepper panel for whatever reason (inactive, etc.)
                this.loadPeriods().then(p => {
                    period = p.map(x => x.period)
                    this.filterStudents(uniqueId, name, period, user)
                })
            } else {
                this.filterStudents(uniqueId, name, period, user)
            }
        },

        filterStudents(id, name, period, user) {
            let filter = {
                type: this.type,
                id: id,
                restrict: true,
                report: false,
                params: this.$_.pickBy({
                    course_id: this.selections.course_id?.map(x => x.course_id),
                    period: period,
                    user_id: this.selections.user_id.user_id,
                }, x => !this.$_.isBlank(x)),
            }
            Object.defineProperties(filter, {
                editor: {value: this},
                editorParams: {
                    value: {
                        selections: this.selections ? {...this.selections} : null
                    }
                },
                chipText: {value: name},
                tooltipData: {
                    value: this.$_.pickBy({
                        'Course': name,
                        'Period': period,
                        'User': user,
                    }, x => !this.$_.isBlank(x))
                }
            })
            this.$emit('input', filter, this.oldFilter)
            this.dialog = false
            this.clear()
        },

        async loadPeriods() {
            const courseId = this.selections.course_id.map(x => x.course_id)
            return await this.$axios.get('courseHistory.php?action=get&property=period&school_year_id=' + this.selections.cohort_school_year_id + '&user_id=' + this.selections.user_id + '&course_id=' + courseId)
                .then(r => this.periods = r.data.courses)
        }
    }
}
</script>

<style lang="scss" scoped></style>
