<template>
    <form-common-config
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #fieldSpecific="{rec}">
            <div class="d-flex">
                <fe-label :style="labelStyle">Attendance Type</fe-label>
                <fe-remote-combo
                    byId
                    style="margin-left:auto;"
                    v-model="formFieldSavedSearch.config_json.attendance_type"
                    itemValue="value"
                    :items="[
                        {value:'period_list', name:'Absence Records'},
                        {value:'affected_rate', name:'Full Day Rate'},
                        {value:'period_rate', name:'Period Rate'},
                        {value:'equiv_rate', name:'SIS Reported Rate'},
                        {value:'affected_count', name:'Total Missed Days'},
                        {value:'equiv_count', name:'SIS Missed Days Count'},
                    ]"
                    :clearable="false"
                ></fe-remote-combo>
            </div>

            <div class="d-flex">
                <fe-label :style="labelStyle">Attendance Search Range</fe-label>
                <fe-remote-combo
                    byId
                    style="margin-left:auto;"
                    v-model="formFieldSavedSearch.config_json.date_type"
                    itemValue="value"
                    :items="[
                        {value:'form_created', name:'Year smartFORM Created'},
                        {value:'current', name:'Current Year'},
                        {value:'school_year', name:'School Year'},
                        {value:'all', name:'All Years'},
                    ]"
                    :clearable="false"
                ></fe-remote-combo>
            </div>
            <div v-if="formFieldSavedSearch.config_json.date_type == 'school_year'" class="d-flex">
                <fe-label :style="labelStyle">School Year</fe-label>
                <fe-remote-combo
                    byId multiple
                    moreLabel="Years"
                    :clearable="false"
                    style="margin-left:auto;"
                    v-model="formFieldSavedSearch.school_year_id"
                    rootProperty="years"
                    url="qry.php?action=get&property=school_years"
                />
            </div>

            <div class="d-flex">
                <fe-label :style="labelStyle">
                    As of Creation Date
                    <v-flex style="display: inline-block; margin-right: 10px">
                        <fe-info-tip tooltip="This will ONLY display attendance data up to the smartFORM creation date." />
                    </v-flex>
                </fe-label>
                <fe-switch style="margin-left:auto;"
                    v-model="formFieldSavedSearch.config_json.to_creation"
                    :true-value="1" :false-value="0"
                />
            </div>

            <div v-if="formFieldSavedSearch.config_json.attendance_type == 'period_list'">
                <fe-label :style="labelStyle">Table Settings</fe-label>
                <fe-remote-combo
                    byId multiple
                    moreLabel="Columns"
                    style="margin-left:auto;"
                    v-model="formFieldSavedSearch.config_json.display_columns"
                    itemValue="value"
                    :items="[
                        {value:'occurrence_date', name:'Date'},
                        {value:'occurrence_weekday', name:'Weekday'},
                        {value:'attendance_term_name', name:'Term'},
                        {value:'attendance_period', name:'Period'},
                        {value:'attendance_course', name:'Course'},
                        {value:'attendance_status_name', name:'Status'},
                        {value:'attendance_reason_name', name:'Reason'},
                        {value:'attendance_excused_name', name:'Excused'},
                    ]"
                    :clearable="false"
                ></fe-remote-combo>
            </div>

            <fe-crud
                ref="crud"
                :key="rec.id"
                :readParams="{form_field_id:rec.id}"
                :config="$models.formFieldSavedSearch"
                autoload
                @read="processRead($event,rec)"
            />
        </template>
    </form-common-config>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'
import FormCommonConfig from '../FormCommonConfig'
import configMixin from './configMixin'

export default {
    inject: ['localStore'],
    mixins: [ configMixin ],
    components: {
        FormCommonConfig,
    },
    computed: {
        ...mapLocalState([ 'reloadFn', 'selectedComponent' ]),
        labelStyle: me => ({ minWidth:'120px' }),
        currentYear: me => me.$store.state.global.sessionUser.currentYear,
    },
    watch: {
        formFieldSavedSearch: {
            deep: true,
            handler: 'save',
        },
    },
    methods: {
        processRead(v,rec) {
            this.formFieldSavedSearch = this.processSavedSearch(v, {
                form_field_id: rec.id,
                school_year_id: this.currentYear.id,
                config_json: {
                    attendance_type: 'period_list',
                    date_type: 'form_created',
                    to_creation: 0,
                    display_columns: [
                        'occurrence_date',
                        'attendance_period',
                        'attendance_course',
                        'attendance_status_name',
                        'attendance_reason_name',
                        'attendance_excused_name',
                    ],
                }
            })

            if(!this.$_.isBlank(v)) {
                this.cachedValue = JSON.stringify(this.formFieldSavedSearch)
            }
        },
        save() {
            let dirty = this.cachedValue != JSON.stringify(this.formFieldSavedSearch)
            if(dirty) {
                this.cachedValue = JSON.stringify(this.formFieldSavedSearch)
                let fieldId
                this.$refs.crud.create({...this.formFieldSavedSearch, form_saved_search_type_id: 2}).then( r => {
                    fieldId = r.data?.form_field_ids[0]
                }).finally(() => {
                    // this should be updating the ui when we make changes on the right
                    this.selectedComponent.$refs.localField.formFieldSavedSearch = {
                        ...this.selectedComponent.$refs.localField.formFieldSavedSearch,
                        ...this.formFieldSavedSearch,
                    }
                    this.reloadFn({fieldId:fieldId})
                })
            }
        }
    },
    data() {
        return {
            formFieldSavedSearch: {
                config_json: {},
            },
            cachedValue: {},
        }
    },
}
</script>

<style lang="scss" scoped>
// {
//     config_json: {
//         date_type: [current,all,custom],
//         start_date: date,
//         end_date: date,
//         to_creation: bool, // for use with instance creation date
//         attendance_type: [count,full,sis]
//     }
// }

// save
// form.php?property=saved_searches&action=create&v=2
// {
//     "saved_searches": [
//         {
//             "form_field_id": 3052,
//             "tag_id": [],
//             "tag_date": "current",
//             "tag_show_name": 0,
//             "tag_show_description": 0,
//             "form_saved_search_type_id": 2
//         }
//     ]
// }

// attendance.php?action=get&property=charts&chart_type=student&student_id=1308&school_year_id=17&v=2
// {
//     "charts": [
//         {
//             "rollup_used": true,
//             "params": {
//                 "chart_type": "student",
//                 "school_id": null,
//                 "grade_id": null,
//                 "school_year_id": 17,
//                 "student_id": 1308,
//                 "start_date": null,
//                 "end_date": null,
//                 "occurrence_weekday": null,
//                 "occurrence_month": null,
//                 "attendance_term_name": null,
//                 "attendance_course": null,
//                 "attendance_period": null,
//                 "attendance_reason_id": null,
//                 "attendance_excused_id": null,
//                 "attendance_status_id": null,
//                 "ethnicity": null,
//                 "gender": null,
//                 "course_id": null,
//                 "period": null,
//                 "user_id": null,
//                 "cohort_grade_id": null,
//                 "cohort_school_id": null,
//                 "cohort_school_year_id": null,
//                 "meal_status_id": null,
//                 "ell": null,
//                 "intervention_level": null,
//                 "disability_type_id": null,
//                 "tag_id": null,
//                 "student_active_flag": null,
//                 "student_active_date": null,
//                 "min_possible_attendance_days": null,
//                 "saved_search_id": null
//             },
//             "data": [
//                 {
//                     "school_year_id": 17,
//                     "school_year_name": "2020-2021",
//                     "student_id": 1308,
//                     "student_full_name": "Garber, Aiden S",
//                     "school_name": "Edgerton High School",
//                     "grade_desc": "10th",
//                     "absent_days_equivalent": "16.50",
//                     "possible_days": "34",
//                     "affected_days": "26",
//                     "possible_period_cnt": null,
//                     "affected_period_cnt": "76",
//                     "days_equivalent_pct": "0.514706",
//                     "affected_days_pct": "0.2353",
//                     "affected_period_pct": null,
//                     "params": {
//                         "school_year_id": 17,
//                         "student_id": 1308
//                     },
//                     "Full Day Attendance Rate": 23.53,
//                     "SIS Reported Attendance Rate": 51.47
//                 }
//             ],
//             "chartType": "summary",
//             "key": "student_full_name",
//             "param_key": "student_id",
//             "title": "By Student (Top 20)",
//             "fields": [
//                 "student_full_name",
//                 "Full Day Attendance Rate",
//                 "SIS Reported Attendance Rate"
//             ],
//             "y_fields": [
//                 "Full Day Attendance Rate",
//                 "SIS Reported Attendance Rate"
//             ],
//             "children": []
//         }
//     ]
// }
</style>
