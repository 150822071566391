<template>
    <v-btn
        text icon dark
        style="margin: 0;"
        @click="onSelectInterventionLevel(itm)"
    >
        <v-icon class="square" :style="{ background: params.data.color }"></v-icon>
    </v-btn>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    name: 'ColoredSquare',
})
</script>

<style lang="scss" scoped>

.square {
    height: 24px;
    width: 24px;
    border-radius: 4px;
}

</style>