<template>
    <highcharts :options="chartOptions"/>
</template>

<script>
export default {
    name: 'Pie',

    props: {
        height: Number,
        values: Array,
        labels: Array,
        urlParams: Array,
        colors: Array,
        name: String,
        category: String,
        title: String
    },

    computed: {
        chartHeight() {
            return this.height ? `${this.height}px` : '300px'
        },

        chartTotal() {
            return this.values.reduce((tot, cur) => {
                return tot + cur
            }, 0)
        },

        chartData() {
            return this.values.map((v, i) => {
                return {
                    y: v,
                    name: this.labels[i] ? this.labels[i] : `Item ${(i + 1)}`,
                    percentage: this.getValuePercentage(v),
                    urlParams: this.urlParams && this.urlParams[i] ? this.urlParams[i] : null
                }
            })
        },

        chartName() {
            return this.name || 'Value'
        },

        chartOptions() {
            return {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    height: this.chartHeight,
                    style: {
                        fontFamily: "Roboto"
                    }
                },
                tooltip: {
                    pointFormat: '{point.y} students <b>({point.percentage:.1f}%)</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true,
                        events: {
                            click: this.showData
                        }
                    }
                },
                title: null,
                series: [{
                    label: this.chartName,
                    type: 'pie',
                    data: this.chartData
                }],
                legend: {
                    enabled: true
                },
                credits: {
                    enabled: false
                },
                colors: this.colors
            }
        }
    },

    methods: {
        getValuePercentage(val) {
            return Math.round((val * 100) / this.chartTotal)
        },

        showData(obj) {
            if (obj.point.options.urlParams) {
                this.$emit('showDataTable', {
                    urlParams: obj.point.options.urlParams,
                    title: this.category,
                    subtitle: this.name,
                    group: obj.point.options.name
                })
            }
        }
    }
}
</script>
