<template>
    <div class="d-flex flex-fill no-scroll">
        <div v-if="ready" class="d-flex flex-fill" style="overflow: auto;">
            <collapsible-side-bar title="Interventions" :expanded-width="500" class="d-flex flex-fill flex-column">
                <div class="d-flex wrap flex-row">
                    <fe-filter-btn
                        title="All Schools"
                        v-model="filters.school"
                        :multiple="false"
                        :items="schools"
                        closeOnSelect
                        @input="loadInterventions"
                    />
                    <fe-filter-btn
                        title="Interventionist"
                        v-model="filters.interventionist"
                        :multiple="false"
                        :items="users"
                        itemText="user_full_name"
                        closeOnSelect
                    />
                    <v-menu
                        ref="dateMenu"
                        offset-y
                        left
                    >
                        <template v-slot:activator="{on}">
                            <v-btn
                                v-on="on"
                                rounded
                                depressed
                                color="#e5f0f3"
                                class="date-btn"
                            >
                                <span rel="dateLabel">{{ filters.date.format('M/D/YYYY') }}</span>
                                <v-icon class="pl-2">fa-caret-down</v-icon>
                            </v-btn>
                        </template>
                        <v-date-picker
                            ref="datePicker"
                            :value="filters.date.format('YYYY-MM-DD')"
                            @input="setDate"
                            :color="$vuetify.theme.themes.light.primary.base"
                        />
                    </v-menu>
                </div>
                <div v-if="interventions.length" class="d-flex flex-fill flex-column pr-2">
                    <v-expansion-panels flat ref="panels" class="intv-expansion-panels" multiple accordion
                                        v-model="intvPanels">
                        <v-expansion-panel v-for="(item, i) in interventions" :key="item.intervention_plan_id">
                            <v-expansion-panel-header class="intv-header" :expand-icon="null">
                                <div class="d-flex intv-header-content">
                                    <div>{{item.name}}</div>
                                    <v-spacer/>
                                    <v-icon x-small color="white">
                                        {{intvPanels.indexOf(i) === -1 ? 'fas fa-plus' : 'fas fa-minus'}}
                                    </v-icon>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div
                                    v-for="(record, r) in item.records"
                                    :key="`ip-${item.intervention_plan_id}-sip-${record.student_intervention_plan_id}`"
                                    class="d-flex intv-record"
                                    :class="{'active': activeRecord.intervention_id === record.intervention_id}"
                                    @click="selectRecord(record)"
                                >
                                    <span>{{record.student_full_name}}</span>
                                    <v-spacer/>
                                    <span class="mr-3">{{record.four_week_possible_pct ? record.four_week_possible_pct + '%' : ''}}</span>
                                    <v-btn class="my-0" icon x-small @click.stop="messages(record)">
                                        <v-icon>fas fa-comments</v-icon>
                                    </v-btn>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
                <div v-else class="empty-overlay">
                    <fe-empty-state
                        image="/images/empty-state-horn.png"
                        header="No Interventions"
                        text="No Interventions found with the applied filters.<br>Change filter criteria and try again."
                    />
                </div>
            </collapsible-side-bar>
            <div class="flex-fill flex-column pa-3 mx-2" style="position: relative;">
                <div class="d-flex">
                    <v-menu
                        ref="studentDateMenu"
                        offset-y
                        left
                        :disabled="!activeRecord.intervention_id"
                    >
                        <template v-slot:activator="{on}">
                            <v-btn
                                v-on="on"
                                rounded
                                depressed
                                color="#e5f0f3"
                                class="date-btn"
                                :disabled="!activeRecord.intervention_id"
                            >
                                <span rel="studentDateLabel">{{ studentDate.format('M/D/YYYY') }}</span>
                                <v-icon class="pl-2">fa-caret-down</v-icon>
                            </v-btn>
                        </template>
                        <v-date-picker
                            ref="studentDatePicker"
                            :value="studentDate.format('YYYY-MM-DD')"
                            :color="$vuetify.theme.themes.light.primary.base"
                            :allowed-dates="allowedStudentDatesFunction"
                            @input="setStudentDate"
                        />
                    </v-menu>

                    <div class="vertical-divider"></div>

                    <fe-btn
                        useIcon="fas fa-bolt"
                        usage="tertiary"
                        :disabled="!activeRecord.intervention_id"
                        @click="dialog = 'quickEnter'"
                    >
                        Quick Enter
                    </fe-btn>

                    <fe-btn
                        useIcon="fas fa-eraser"
                        usage="tertiary"
                        :disabled="!activeRecord.intervention_id"
                        @click="dialog = 'eraseAll'"
                    >
                        Erase Scores
                    </fe-btn>

                    <v-menu
                        ref="date-tools"
                        offset-y
                        left
                    >
                        <template v-slot:activator="{on}">
                            <fe-btn
                                v-on="on"
                                usage="tertiary"
                                useIcon="fas fa-caret-down"
                                useIconDirection="right"
                                :disabled="!activeRecord.intervention_id"
                            >
                                Date Tools
                            </fe-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(tool, i) in dateTools" :key="'date-tool-' + i" @click="dialog = tool.dialog">
                                <v-list-item-title>{{tool.name}}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
                <div class="my-3">
                    <h1>{{activeRecord.student_full_name}}</h1>
                </div>
                <fe-grid
                    v-if="gridReady"
                    ref="scoreGrid"
                    style="height: 100%"
                    displayDensity="medium"
                    :gridOptionOverrides="gridOptionOverrides"
                    :columnDefs="columns"
                    :rowData="gridItems"
                    :showAddRowBtn="false"
                    @cellValueChanged="gridCellUpdate"
                    suppressFieldDotNotation
                />
                <div v-else class="empty-overlay">
                    <fe-empty-state
                        image="/images/empty-state-horn.png"
                        header="Select a Student"
                        text="To get started, begin by selecting a student from the side panel"
                    />
                </div>
            </div>
        </div>

        <fe-dialog
            v-if="dialog === 'quickEnter'"
            title="Quick Enter"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            :acceptButtonDisabled="!quickEnter.valid"
            persistent
            @dismiss="dialog = false"
            @accept="handleQuickEnter"
        >
            <v-form ref="quick-add-form" @submit.prevent v-model="quickEnter.valid">
                <fe-text-field
                    v-model="quickEnter.value"
                    label="Score"
                    type="number"
                    width="100%"
                    :placeholder="quickEnter.low + '-' + quickEnter.high"
                    :rules="this.$fieldValidators('number', 'Score', {required: true, min: quickEnter.low, max: quickEnter.high})"
                />
            </v-form>
        </fe-dialog>

        <fe-dialog
            v-if="dialog === 'eraseAll'"
            title="Erase Scores"
            acceptButtonText="Erase"
            dismissButtonText="Cancel"
            body="Are you sure you want to erase all scores?<br>This action cannot be undone."
            persistent
            @dismiss="dialog = false"
            @accept="handleQuickEnter"
        />

        <fe-dialog
            v-if="dialog === 'rescheduleDate'"
            title="Reschedule Date"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            @dismiss="dialog = false"
            @accept="rescheduleDate"
        >
            <fe-date-picker
                label="Original Date"
                :value="studentDate.format('YYYY-MM-DD')"
                prependIcon="far fa-calendar-alt"
                disabled
            />
            <fe-date-picker
                label="New Date"
                prependIcon="far fa-calendar-alt"
                v-model="newDate"
            />
        </fe-dialog>

        <fe-dialog
            v-if="dialog === 'addDate'"
            title="Add Date"
            acceptButtonText="Add"
            dismissButtonText="Cancel"
            @dismiss="dialog = false"
            @accept="addDate"
        >
            <fe-date-picker
                label="New Date"
                prependIcon="far fa-calendar-alt"
                v-model="newDate"
            />
        </fe-dialog>

        <fe-dialog
            v-if="dialog === 'removeDate'"
            title="Remove Date"
            acceptButtonText="Remove"
            dismissButtonText="Cancel"
            body="Are you sure you want to remove this date?<br>This action cannot be undone."
            @dismiss="dialog = false"
            @accept="removeDate"
        />

        <fe-dialog
            title="Messages"
            width="800"
            v-if="dialog === 'messages'"
            @accept="dialog = false"
            @close="dialog = false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Done"
            :retain-focus="false"
        >
            <message :allowPrivate="false" dense :params="messageParams"/>
        </fe-dialog>

        <fe-crud ref="schoolCrud" :config="schoolCrudConfig" autoload @read="setSchools"/>
        <fe-crud ref="usersCrud" :config="userCrudConfig" autoload @read="users = $event"/>
        <fe-crud ref="mssCrud" :config="mssCrudConfig" autoload @read="statusOptions = $event"/>
        <fe-crud ref="scheduleCrud" :config="scheduleCrudConfig" @read="parseInterventions"/>
        <fe-crud ref="scoresCrud" :config="scoresCrudConfig"/>
        <fe-crud ref="datesCrud" :config="datesCrudConfig"/>
        <fe-crud ref="scheduleDateCrud" :config="scheduleDateCrudConfig"/>
    </div>
</template>

<script>
    import CollapsibleSideBar from "../../common/CollapsibleSideBar"
    import Message from "../../common/Message"
    import {mapState} from "vuex"

    export default {
        name: "PointSheetMonitoring",

        components: {CollapsibleSideBar, Message},

        props: {
            params: {
            },
        },

        data() {
            return {
                ready: false,
                schools: [],
                users: [],
                interventions: [],
                hasSelectedFirstDefaultRecord: false,
                intvPanels: [],
                statusOptions: [],
                activeRecord: {},
                studentDate: null,
                filters: {
                    school: {included:[]},
                    interventionist: undefined,
                    date: undefined
                },
                gridReady: false,
                addlColumns: [],
                rawScores: [],
                rawDates: [],
                recordDates: [],
                gridOptionOverrides: {
                    immutableData: true,
                    getRowNodeId: v => {
                        // Needs to be unique per student, monitor plan, date and monitor period (each row is a period)
                        return `${v.student_id}::${v.student_monitor_plan_id}::${v.schedule_date_id}::${v.monitor_period_id}`
                    },
                },
                gridItems: [],
                dateTools: [{
                    name: 'Reschedule Date',
                    dialog: 'rescheduleDate'
                }, {
                    name: 'Add Date',
                    dialog: 'addDate'
                }, {
                    name: 'Remove Date',
                    dialog: 'removeDate'
                }],
                dialog: false,
                quickEnter: {
                    valid: false,
                    high: 1000,
                    low: 0,
                    value: undefined
                },
                newDate: undefined,
                messageParams: {},
                noScoreAllowed: {},
                lastCell: null
            }
        },

        computed: {
            ...mapState('global', ['currentYear', 'sessionUser']),

            includeAssignedStudents() {
                // Don't allow this to return true until we have loaded users (avoids false positive)
                if (this.users?.length) {
                    if (this.filters.interventionist?.included?.length === 0) {
                        return true
                    }
                }

                return false
            },

            userCrudConfig() {
                let cfg = this.$_.cloneDeep(this.$models.user)
                cfg.read.params.school_year_id = this.currentYear.id
                cfg.read.params.active = 1

                if (!this.$hasSecurity('SEARCH_BY_INTERVENTIONIST')) {
                    cfg.read.params.id = this.sessionUser.user.id // id represents the user_id on backend
                }

                return cfg
            },

            schoolCrudConfig() {
                return this.$_.cloneDeep(this.$models.school)
            },

            scheduleCrudConfig() {
                let cfg = this.$_.cloneDeep(this.$models.intervention)
                cfg.read.params.property = 'student_intervention_schedule'
                // It's important not to send this property *at all* to the backend
                // when it's false; backend interprets a 0, a false, etc. as "truthy"
                if (this.includeAssignedStudents) {
                    cfg.read.params.include_assigned_students = 1
                }
                cfg.read.rootProperty = 'schedules'
                return cfg
            },

            scoresCrudConfig() {
                let cfg = this.$_.cloneDeep(this.$models.studentMonitors)
                cfg.read.params.property = 'monitor_scores'
                cfg.update.params.property = 'monitor_scores'
                cfg.read.rootProperty = 'monitor_scores'
                cfg.update.rootProperty = 'monitor_scores'
                return cfg
            },

            datesCrudConfig() {
                let cfg = this.$_.cloneDeep(this.$models.studentMonitors)
                cfg.read.params.property = 'monitor_dates'
                cfg.read.rootProperty = 'monitor_dates'
                return cfg
            },

            mssCrudConfig() {
                return this.$_.cloneDeep(this.$models.monitorScoreStatus)
            },

            scheduleDateCrudConfig() {
                return this.$_.cloneDeep(this.$models.scheduleDate)
            },

            allowedStudentDatesFunction() {
                return v => this.recordDates.find(itm => itm.id == v)
            },

            columns() {
                let me = this
                let columns = [{
                    headerName: 'Period',
                    field: 'monitor_period_name',
                    sortable: true,
                    pinned: 'left'
                }, {
                    headerName: 'Attendance',
                    field: 'monitor_score_status_id',
                    editable: true,
                    maxWidth: 150,
                    valueFormatter: v => me.findLabel(this.statusOptions, v.data.monitor_score_status_id || 1, 'id', 'name'),
                    cellEditorFramework: "FeGridChipSelect",
                    cellEditorParams: v => {
                        return {
                            value: v.data.monitor_score_status_id,
                            rowDataKey: 'monitor_score_status_name',
                            mode: "edit",
                            items: this.statusOptions,
                            labelProp: 'name',
                            keyProp: 'id',
                            disableChips: true
                        }
                    },
                    sortable: true
                }]

                this.addlColumns.forEach(x => {
                    columns.push({
                        headerName: x.name,
                        field: x.name,
                        meta: x?.meta,
                        maxWidth: 150,
                        cellRenderer: v => {
                            let data = v.data
                            let noScoreParam = 'z' + data.monitor_period_name.replace(/[^A-Za-z0-9]/g, '') + x.name.replace(/[^A-Za-z0-9]/g, '') + '_no_score'
                            noScoreParam = eval(`data.${noScoreParam}`)
                            if (!this.$_.isNull(data[x.name])) return data[x.name]
                            if (noScoreParam === 1) return '<span style="opacity: .3;">--</span>'
                            if (x.high && noScoreParam === 0) return '<span style="opacity: .5;">' + x.low + ' to ' + x.high + '</span>'
                            return 'no value'
                        },
                        cellStyle: v => {
                            let data = v.data
                            let noScoreParam = 'z' + data.monitor_period_name.replace(/[^A-Za-z0-9]/g, '') + x.name.replace(/[^A-Za-z0-9]/g, '') + '_no_score'
                            noScoreParam = eval(`data.${noScoreParam}`)
                            return {
                                'text-align': 'right',
                                'background': noScoreParam ? '#CCCCCC' : 'white',
                                'pointer-events': noScoreParam ? 'none' : ''
                            }
                        },
                        cellEditorFramework: 'FeGridTextField',
                        cellEditorParams: v => {
                            return {
                                type: 'number',
                                rules: this.$fieldValidators('number', x.name, {
                                    required: true,
                                    min: x.low,
                                    max: x.high
                                }),
                                block: true,
                            }
                        },
                        sortable: true,
                        editable: true,
                    })
                })

                return columns
            }
        },

        watch: {
            'filters.interventionist'() {
                // The fe-filter-btn gets a little unreliable re: actually reloading
                // the data if we don't use nextTick here
                this.$nextTick(() => {
                    this.loadInterventions()
                })
            },
        },

        mounted() {
            // Partly for performance reasons, if the current user can
            // see all users' interventions, then pre-select themself on first load;
            // otherwise, do not pre-select any user (i.e. a standard user will
            // see all interventions involving their students, not merely the
            // interventions they specifically created)
            if (this.$hasSecurity('SEARCH_BY_INTERVENTIONIST')) {
                this.filters.interventionist = {
                    included: [this.sessionUser.user],
                }
            }

            this.filters.date = this.$dayjs()

            this.studentDate = this.$dayjs()

            this.$nextTick(() => {
                this.loadInterventions()
                this.ready = true
            })
        },

        methods: {
            reset() {
                this.activeRecord = {}
                this.gridReady = false
                this.rawScores = []
                this.rawDates = []
                this.recordDates = []
                this.gridItems = []
            },

            setSchools(data) {
                this.schools = data
            },

            loadInterventions() {
                if (this.gridReady) {
                    this.lastCell = this.$refs.scoreGrid.gridOptions.api.getFocusedCell()
                }

                let user = this.filters.interventionist?.included[0]
                let base = {
                    schedule_date_occurrence: this.filters.date.format('YYYY-MM-DD'),
                    type: 'behavior'
                }

                let params = this.params ? {...this.params, ...base} : {
                    user_id: user ? user.id : null,
                    schedule_date_occurrence: this.filters.date.format('YYYY-MM-DD'),
                    type: 'behavior'
                }

                if (this.filters.school.included[0]) {
                    params.school_id = this.filters.school.included[0].id
                }

                this.$refs.scheduleCrud.read(params)
            },

            parseInterventions(data) {
                let interventions = {}

                data.forEach(item => {
                    if (!interventions[item.intervention_plan_name]) {
                        interventions[item.intervention_plan_name] = {
                            intervention_plan_id: item.intervention_plan_id,
                            name: item.intervention_plan_name,
                            student_ids: {},
                            records: []
                        }
                    }
                    // because we don't enforce start/end dates and names may overlap, only add each student once
                    if (!interventions[item.intervention_plan_name].student_ids[item.student_id]) {
                        interventions[item.intervention_plan_name].student_ids[item.student_id] = true
                        interventions[item.intervention_plan_name].records.push(item)
                    }
                })

                this.interventions = Object.values(interventions)
                this.intvPanels = this.interventions.map((x, i) => i)

                // Although we just set intvPanels to default all intervention panels
                // to "open," there's a glitch in the system (somewhere) that will cause
                // one or more panels to auto-close in some cases.  As a workaround here,
                // we will re-perform the same calculation on nextTick to "lock it in"
                this.$nextTick(() => {
                    this.intvPanels = this.interventions.map((x, i) => i)
                })

                // If component is being loaded for the first time (not a data refresh), courteously
                // pre-select the first student if there are any records at all
                if (!this.hasSelectedFirstDefaultRecord) {
                    for (let ip of this.interventions) {
                        for (let sip of ip.records) {
                            if (!this.hasSelectedFirstDefaultRecord) {
                                this.selectRecord(sip)
                                this.hasSelectedFirstDefaultRecord = true
                            }
                        }
                    }
                }
            },

            setDate(v, isRecursive) {
                let date = this.$dayjs(v)
                if (date.$D !== this.filters.date.$D) {
                    this.filters.date = date
                    this.loadInterventions()
                }

                if (!isRecursive) {
                    // if intervention list date is changed to one that is not
                    // valid for the selected student+intervention, return to empty state on right panel
                    let s = date.format('YYYY-MM-DD')
                    if (this.gridReady && this.studentDate && this.activeRecord.intervention_id && !this.recordDates.find(itm => itm.id == s)) {
                        this.activeRecord = {}
                        this.gridReady = false
                        this.lastCell = null
                    } else {
                        this.setStudentDate(v, true)
                    }
                }
            },

            setStudentDate(v, isRecursive) {
                this.studentDate = this.$dayjs(v)

                if (!isRecursive) {
                    this.setDate(v, true)
                }

                if (this.activeRecord.intervention_id) {
                    this.fetchRecord(this.activeRecord, this.studentDate.format('YYYY-MM-DD'))
                }
            },

            messages(record) {
                this.messageParams = this.$_.pick(record, ['intervention_plan_id', 'student_id'])
                this.dialog = 'messages'
            },

            selectRecord(record, keepDate=false) {
                this.activeRecord = record
                let id = this.filters.date.format('YYYY-MM-DD')
                if (this.studentDate.format('YYYY-MM-DD') !== id) {
                    if (!keepDate) {
                        // The user changed the scoring date and we don't want it to change the date after auto-save
                        this.studentDate = this.$dayjs(id)
                    }
                    this.fetchRecord(record, this.studentDate.format('YYYY-MM-DD'))
                } else {
                    this.fetchRecord(record, id)
                }
            },

            fetchRecord(record, date) {
                let transactions = []
                transactions.push(this.$refs.scoresCrud.read({
                    intervention_id: record.intervention_id,
                    schedule_date_occurrence: date
                }))
                transactions.push(this.$refs.datesCrud.read({
                    intervention_id: record.intervention_id,
                    intervention_category_id: record.intervention_category_id
                }))
                Promise.all(transactions).then(responses => {
                    this.rawScores = responses[0].data.monitor_scores
                    this.rawDates = responses[1].data.monitor_dates
                    this.parseRecord()
                })
            },

            parseRecord() {
                let columns = {}
                let data = {}
                this.gridReady = false
                this.noScoreAllowed = {}

                // Quickly reset quick score bounds to defaults; we'll recalculate
                // them based off rawScores data in a moment (may change between interventions)
                this.quickEnter.low = 0
                this.quickEnter.high = 1000

                this.rawScores.forEach(x => {
                    if (!x.monitor_period_name) return
                    let period = x.monitor_period_name.replace(/[\.:"]/g, '')
                    let subcat = x.sub_category_name.replace(/[\.:"]/g, '')
                    let key = period+'-'+subcat
                    // add 'z' because the noScoreParam can't start with a number
                    let key2 = 'z' + period.replace(/[^A-Za-z0-9]/g, '') + subcat.replace(/[^A-Za-z0-9]/g, '')
                    this.noScoreAllowed[key] = x.no_score_flag

                    if (!columns[x.sub_category_name]) {
                        columns[x.sub_category_name] = {
                            name: x.sub_category_name,
                            field: key,
                            meta: {
                                score: true,
                                monitor_score_id: x.monitor_score_id,
                                period: x.monitor_period_id,
                                subcat: x.sub_category_id,
                            },
                            id: x.sub_category_id,
                            rank: x.sub_category_rank,
                            high: x.highest_score,
                            low: x.lowest_score
                        }

                        if (this.quickEnter.low < x.lowest_score) this.quickEnter.low = x.lowest_score
                        if (this.quickEnter.high > x.highest_score) this.quickEnter.high = x.highest_score
                    }
                    if (!data[x.monitor_period_name]) {
                        data[x.monitor_period_name] = this.$_.cloneDeep(x)
                    }

                    data[x.monitor_period_name][key] = x.score
                    data[x.monitor_period_name][x.sub_category_name] = x.score
                    data[x.monitor_period_name][key2 + '_no_score'] = x.no_score_flag
                })

                this.recordDates = this.rawDates.map(date => {
                    return {
                        name: this.$dayjs(date.schedule_date_occurrence).format('MM/DD/YYYY'),
                        id: date.schedule_date_occurrence
                    }
                })
                this.addlColumns = Object.values(columns)
                this.gridItems = Object.values(data)
                this.gridReady = true
            },

            gridCellUpdate(v) {
                let toUpdate = []
                let periodId = v.node.data.monitor_period_id
                this.rawScores.forEach(x => {
                    let push = false
                    let update = this.$_.cloneDeep(x)
                    if (v.colDef?.meta?.score) {
                        if (x.monitor_period_id == periodId && x.sub_category_id == v.colDef.meta.subcat && v.newValue != x.score) {
                            update.score = v.newValue
                            push = true
                        }
                    } else { // we are setting attendance
                        if (update.monitor_score_status_id !== v.data.monitor_score_status_id && x.monitor_period_id == periodId) {
                            update.monitor_score_status_id = v.data.monitor_score_status_id
                            push = true
                        }
                    }
                    // if (x.monitor_period_id === v.data.monitor_period_id) {
                    //     let update = this.$_.cloneDeep(x)
                    //     let push = false
                    //     if (update.score !== v.data[update.sub_category_name]) {
                    //         update.score = v.data[update.sub_category_name]
                    //         push = true
                    //     }
                    //     if (update.monitor_score_status_id !== v.data.monitor_score_status_id) {
                    //         update.monitor_score_status_id = v.data.monitor_score_status_id
                    //         push = true
                    //     }
                    if (push) {
                        toUpdate.push(update)
                    }
                    // }
                })

                if (toUpdate.length) {
                    this.update(toUpdate, v)
                }
            },

            handleQuickEnter() {
                let score = this.quickEnter.value
                let toUpdate = this.rawScores.map(x => {
                    let update = this.$_.cloneDeep(x)
                    update.score = score || null
                    return update
                })

                if (toUpdate.length) {
                    this.update(toUpdate)
                }
                this.dialog = false
                this.quickEnter.value = undefined
            },

            rescheduleDate() {
                let reschedule = {}
                this.rawScores.forEach(item => {
                    if(!reschedule[item.schedule_id]) {
                        reschedule[item.schedule_id] = {
                            id: item.schedule_date_id,
                            schedule_id: item.schedule_id,
                            occurrence: this.newDate,
                            original_occurrence: item.schedule_date_occurrence
                        }
                    }
                })

                this.$refs.scheduleDateCrud.update(Object.values(reschedule)).then(() => {
                    this.setStudentDate(this.newDate)

                    this.dialog = false
                    this.newDate = undefined
                })
            },

            addDate() {
                let add = {}
                this.rawScores.forEach(item => {
                    if(!add[item.schedule_id]) {
                        add[item.schedule_id] = {
                            schedule_id: item.schedule_id,
                            occurrence: this.newDate
                        }
                    }
                })

                this.$refs.scheduleDateCrud.create(Object.values(add)).then(() => {
                    this.setStudentDate(this.newDate)

                    this.dialog = false
                    this.newDate = undefined
                })
            },

            removeDate() {
                let remove = {}
                this.rawScores.forEach(item => {
                    if(!remove[item.schedule_id]) {
                        remove[item.schedule_id] = {
                            id: item.schedule_date_id,
                            schedule_id: item.schedule_id
                        }
                    }
                })

                this.$refs.scheduleDateCrud.destroy(Object.values(remove)).then(() => {
                    this.reset()
                    this.loadInterventions()
                    this.dialog = false
                })
            },

            update(records, meta) {
                this.$refs.scoresCrud.update(records).then(() => {
                    this.loadInterventions()
                    this.selectRecord(this.activeRecord, true)
                })
            },

            findLabel(collection, value, valueProp, labelProp) {
                let lbl = ''
                if (collection && value) {
                    let sel = collection.find(x => x[valueProp] === value)
                    if (sel) lbl = sel[labelProp]
                }
                return lbl
            }
        }
    }
</script>

<style lang="scss" scoped>
    .date-btn {
        ::v-deep .v-btn__content {
            color: inherit;
        }
    }

    .date-btn:hover {
        border: 1px solid #E0E1E6 !important;
    }

    .date-btn {
        border: 1px solid #E0E1E6;
        color: var(--primary-color) !important;
        font-size: 14px;
    }

    ::v-deep .v-expansion-panel {
        margin-bottom: 10px;
    }

    ::v-deep .v-expansion-panel-header {
        padding: 0;
        margin: 5px 0;
        min-height: unset;

        .intv-header-content {
            padding: 5px 10px;
            border-radius: 5px;
            background-color: var(--v-primary-base);
            color: white;
        }
    }

    ::v-deep .v-expansion-panel-content__wrap {
        padding: 0;

        .intv-record {
            padding: 5px 10px;
            margin-bottom: 5px;
            border-radius: 5px;
            cursor: pointer;

            &.active {
                background-color: var(--v-primary-lighten2);
            }
        }
    }

    .empty-overlay {
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        background: white;
    }

    .vertical-divider {
        margin: 6px 10px;
        border-left: 2px solid #ECEDF1;
    }

    ::v-deep .content {
        min-height: 200px !important;
        overflow: hidden;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        grid-template-areas:
            'header'
            'main';

        .d-flex.wrap {
            flex-wrap: wrap;
        }

        .d-flex.wrap.flex-row {
            grid-area: header;
        }

        .d-flex.flex-fill.flex-column {
            overflow: auto;
            grid-area: main;
        }
    }
</style>
