<template>
    <form-common-config
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #fieldSpecific="{rec}">
            <v-list>
                <v-list-item v-for="(ss,i) in savedSearch"
                    :key="ss.saved_search_id"
                    @click="currentScoreRec=ss; showAddDialog=i+1"
                >
                    <v-list-item-content>Score {{i+1}}</v-list-item-content>
                    <fe-icon-btn use-icon="fa-times" @click.stop="deleteRec(ss)" />
                </v-list-item>
            </v-list>

            <div class="d-flex">
                <fe-btn @click="showAddDialog=savedSearch ? savedSearch.length+1 : 1" usage="tertiary" class="ml-auto" >
                    {{savedSearch.length ? 'Add More' : 'Configure Scores'}}
                </fe-btn>
            </div>


            <record-dialog v-if="showAddDialog"
                :title="`Scores ${showAddDialog}`"
                :value="currentScoreRec"
                :defaultRec="{
                    form_field_id: rec.id,
                    saved_search_id: null,
                    data_point_type_id: null,
                    sub_category_id: null,
                    data_point_name_id: null,
                    school_year_id: null,
                    score_detail_type_id: null,
                    chart_display_options: 'norm',
                }"
                @input="createRec($event) ; showAddDialog=null"
                @close="showAddDialog=null; currentScoreRec=null"
            >
                <template #form="{rec}">
                    <fe-label>Assessment Group</fe-label>
                    <fe-remote-combo
                        byId
                        v-model="rec.data_point_type_id"
                        url="dataPointType.php?action=get&hidden=0&property=assessments"
                        @input="rec.sub_category_id = rec.data_point_name_id = rec.score_detail_type_id = null"
                        :rules="$fieldValidators('select', null, { required: true })"
                    />

                    <fe-label>Assessment</fe-label>
                    <fe-remote-combo
                        byId multiple moreText
                        v-model="rec.sub_category_id"
                        itemText="display_name"
                        url="subcategories.php?action=get"
                        rootProperty="subcategories"
                        :rules="$fieldValidators('select', null, { required: true })"
                        :defaultParams="{ data_point_type_id:rec.data_point_type_id }"
                        @input="rec.score_detail_type_id = null"
                    />

                    <fe-label>Window</fe-label>
                    <fe-remote-combo
                        byId multiple moreText
                        v-model="rec.data_point_name_id"
                        url="dataPointName.php?action=get"
                        rootProperty="names"
                        :rules="$fieldValidators('select', null, { required: true })"
                        :defaultParams="{ data_point_type_id:rec.data_point_type_id }"
                    />

                    <fe-label>School Years</fe-label>
                    <fe-remote-combo
                        byId multiple moreText
                        v-model="rec.school_year_id"
                        rootProperty="years"
                        url="qry.php?action=get&property=school_years"
                        placeholder="All Years"
                    />

                    <fe-label>Score Details</fe-label>
                    <fe-remote-combo
                        byId multiple moreText
                        v-model="rec.score_detail_type_id"
                        itemText="description"
                        url="scoreDetailTypes.php?action=get"
                        :defaultParams="{
                            data_point_type_id:rec.data_point_type_id,
                            sub_category_id:rec.sub_category_id,
                        }"
                    />

                    <fe-label>Chart Options</fe-label>
                    <fe-remote-combo
                        byId
                        v-model="rec.chart_display_options"
                        itemValue="value"
                        :items="[
                            {value:'norm', name:'With Normative Data'},
                            {value:'nonorm', name:'Without Normative Data'},
                            {value:'hidechart', name:'Hide Chart'}
                        ]"
                        :clearable="false"
                    />

                </template>
            </record-dialog>

            <fe-crud
                ref="crud"
                :key="rec.id"
                :readParams="{form_field_id:rec.id}"
                :config="$models.formFieldSavedSearch"
                autoload
                @read="processRead($event,rec)"
            />
        </template>
    </form-common-config>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'
import FormCommonConfig from '../FormCommonConfig'
import RecordDialog from '@/components/common/form/RecordDialog'

export default {
    inject: ['localStore'],
    components: {
        FormCommonConfig,
        RecordDialog,
    },
    computed: {
        ...mapLocalState([ 'reloadFn' ]),
        labelStyle: me => ({ minWidth:'120px' }),
    },
    methods: {
        deleteRec(rec) {
            this.$refs.crud.destroy(rec).finally(() => this.$refs.crud.read())
        },
        createRec(rec) {
            if(rec.saved_search_id) {
                this.savedSearch = this.savedSearch.map(x => {
                    return rec.saved_search_id == x.saved_search_id ? rec : x
                })
            } else {
                this.savedSearch = [ ...this.savedSearch, rec ]
            }
        },
        processRead(v,rec) {
            if(v.length) {
                this.savedSearch = this.processRecords(v)
            } else {
                if(rec.duplicateConfigFrom) {
                    // debugger
                    // this.$refs.crud.read({form_field_id:duplicateConfigFrom.id}).then(r => {
                    //     debugger
                    // })
                    this.$modelGet('formFieldSavedSearch',{form_field_id: rec.duplicateConfigFrom.id}).then(r => {
                        // let config = this.processRecords(r)
                        this.savedSearch = this.processRecords(r).map(x => {
                            return {
                                ...x,
                                form_field_id: rec.id,
                                form_field_saved_search_id: undefined,
                                saved_search_id: undefined,
                            }
                        })
                    })
                    rec.duplicateConfigFrom = undefined
                }
                this.savedSearch = []
            }

            this.cachedValue = JSON.stringify(this.savedSearch)
        },
        processRecords(v) {
            return v.map(ss => {
                return {
                    ...this.$_.omit(ss,['args']),
                    ...(this.$_.transform(ss.args, (acc,val,key) => {
                        return acc[key.toLowerCase()] = val.length
                            ? (val.length==1 ? this.castIfNumber(val[0]) : val.map(v => this.castIfNumber(v)))
                            : null
                    }))
                }
            })
        },
        castIfNumber(v) {
            const str = (''+ v).trim()
            if (str.length === 0) return v
            return !isNaN(+str) ? Number(v) : v
        }
    },
    watch: {
        savedSearch: {
            deep: true,
            handler(v) {
                let dirty = this.cachedValue != JSON.stringify(v)
                if(dirty) {
                    v.forEach(x => x.form_saved_search_type_id = 1)
                    let fieldId
                    this.$refs.crud.create(v).then( r => {
                        fieldId = r.data?.form_field_ids[0]
                    }).finally(() => {
                        this.reloadFn({fieldId:fieldId})
                        this.$refs.crud.read()
                    })
                }
            },
        },
    },
    data() {
        return {
            showAddDialog: null,
            currentScoreRec: null,
            savedSearch: [],
            cachedValue: {},
        }
    },
}
</script>

<style lang="scss" scoped>
// form.php?action=destroy&property=saved_searches

// form.php?action=create&property=saved_searches
// {
//     "saved_searches": [
//         {
//             "form_saved_search_type_id": "1",
//             "form_field_saved_search_id": "2",
//             "saved_search_id": "610",
//             "form_field_id": "30",
//             "form_instance_response_id": "",
//             "data_point_type_id": 1,
//             "sub_category_id": [
//                 18
//             ],
//             "data_point_name_id": [
//                 11,
//                 12,
//                 13
//             ],
//             "school_year_id": "",
//             "score_detail_type_id": "",
//             "chart_display_options": "norm"
//         },
//         {
//             "form_saved_search_type_id": "1",
//             "form_field_saved_search_id": "",
//             "saved_search_id": "",
//             "form_field_id": "30",
//             "form_instance_response_id": "",
//             "data_point_type_id": 1,
//             "sub_category_id": [
//                 19
//             ],
//             "data_point_name_id": [
//                 11,
//                 12,
//                 13
//             ],
//             "school_year_id": "",
//             "score_detail_type_id": "",
//             "chart_display_options": "norm"
//         }
//     ]
// }
</style>
