<template>
    <TabPane :pending="pending" :error="error" :count="count">
        <template slot="icon">
            <h3 class="card__title card__title--group">Manage Filters</h3>
        </template>

        <template slot="buttons">
            <v-btn color="#888" text icon :disabled="!success && !error" @click="reset">
                <v-icon>refresh</v-icon>
            </v-btn>
        </template>

        <template slot="content">
            <StoreSelector
                storeNamespace="thresholds/school"
                label="Filter by School"
                keyProp="id"
                labelProp="name"
                :selectedKeys="selection('school_id')"
                :readonly="pending"
                multiple
                untruncated
                @updates="v => apply(v, 'thresholds/group/filter/update', 'school_id')"
            />

            <StoreSelector
                storeNamespace="thresholds/grade"
                label="Filter by Grade"
                keyProp="id"
                labelProp="name"
                :selectedKeys="selection('grade_id')"
                :readonly="pending"
                multiple
                untruncated
                @updates="v => apply(v, 'thresholds/group/filter/update', 'grade_id')"
            />

            <StoreSelector
                storeNamespace="thresholds/tag"
                label="Filter by Tag"
                keyProp="id"
                labelProp="name"
                :selectedKeys="selection('tag_id')"
                :readonly="pending"
                multiple
                untruncated
                @updates="v => apply(v, 'thresholds/group/filter/update', 'tag_id')"
            />

            <StoreSelector
                storeNamespace="thresholds/ethnicity"
                label="Filter by Ethnicity"
                keyProp="value"
                labelProp="display_name"
                :selectedKeys="selection('ethnicity')"
                :readonly="pending"
                multiple
                untruncated
                @updates="v => apply(v, 'thresholds/group/filter/update', 'ethnicity')"
            />

            <StoreSelector
                storeNamespace="thresholds/gender"
                label="Filter by Gender"
                keyProp="value"
                labelProp="display_name"
                :selectedKeys="selection('gender')"
                :readonly="pending"
                multiple
                untruncated
                @updates="v => apply(v, 'thresholds/group/filter/update', 'gender')"
            />

            <StoreSelector
                storeNamespace="thresholds/disability"
                label="Filter by Disability"
                keyProp="id"
                labelProp="display_name"
                :selectedKeys="selection('disability_type_id')"
                :readonly="pending"
                multiple
                untruncated
                @updates="v => apply(v, 'thresholds/group/filter/update', 'disability_type_id')"
            />

            <StoreSelector
                storeNamespace="thresholds/interventionType"
                label="Filter by Intervention Type"
                keyProp="id"
                labelProp="name"
                :selectedKeys="selection('intervention_level_id')"
                :readonly="pending"
                multiple
                untruncated
                @updates="v => apply(v, 'thresholds/group/filter/update', 'intervention_level_id')"
            />

            <StoreSelector
                storeNamespace="thresholds/ellStatus"
                label="Filter by EL Status"
                keyProp="id"
                labelProp="name"
                :selectedKeys="selectionFirst('ell_flag')"
                :readonly="pending"
                untruncated
                @updates="v => apply(v, 'thresholds/group/filter/update', 'ell_flag')"
            />

            <StoreSelector
                storeNamespace="thresholds/ellLevel"
                label="Filter by ELP Level"
                keyProp="id"
                labelProp="display_name"
                :selectedKeys="selection('ell_level_id')"
                :readonly="pending"
                multiple
                untruncated
                hide-if-empty
                @updates="v => apply(v, 'thresholds/group/filter/update', 'ell_level_id')"
            />

            <StoreSelector
                storeNamespace="thresholds/activeStatus"
                label="Filter by Active Status"
                keyProp="id"
                labelProp="name"
                :selectedKeys="selectionFirst('active_status')"
                :readonly="pending"
                untruncated
                @updates="v => apply(v, 'thresholds/group/filter/update', 'active_status')"
            />

            <div>
                <fe-label>Active as of Date</fe-label>
                <fe-date-picker
                    clearable
                    :value="selectionFirst('active_date')"
                    @input="v => apply(v, 'thresholds/group/filter/update', 'active_date')"
                />
            </div>

            <advanced-demographic-filters v-model="demographics" vertical/>
        </template>
    </TabPane>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import StoreSelector from '../controls/StoreSelector';
import TabPane from '../templates/TabPane';
import AdvancedDemographicFilters from '../demographics/AdvancedDemographicFilters'

export default {
    name: "Filters",

    components: {
        StoreSelector,
        TabPane,
        AdvancedDemographicFilters
    },

    props: {
        isActive: Boolean
    },

    data() {
        return {
            filterIndex: 0,
        };
    },

    computed: {
        ...mapGetters("thresholds/group/filter", [
            "collection",
            "clear",
            "pending",
            "success",
            "error",
            "hasDirtied",
            "hasRemoved",
            "hasAdded",
            "hasChanges",
            "count"
        ]),

        thresholdGroupId() {
            return this.$store.getters["thresholds/group/selected"].id;
        },

        hasRecord() {
            return this.count > 0;
        },

        demographics: {
            get() {
                if (!this.collection[this.filterIndex]) return null
                let obj = {}
                Object.keys(this.collection[this.filterIndex]).forEach(k => {
                    if (k.match(/^demo_\d+$/)) obj[k] = this.collection[this.filterIndex][k]
                })
                return obj
            },
            set(v) {
                this.updateDemographics(v)
            }
        },

        ellLevels() {
            return this.$store.getters['thresholds/ellLevel/all']
        }
    },

    watch: {
        clear: function (val) {
            if (val) this.reset();
        }
    },

    mounted() {
        if (this.clear) this.refresh()
        this.$store.dispatch('thresholds/ellLevel/load')
    },

    methods: {
        ...mapActions("thresholds/group/filter", ["refresh", "reconcile"]),

        selection(key) {
            return this.collection[this.filterIndex]
                ? this.collection[this.filterIndex][key]
                : null;
        },

        selectionFirst(key) {
            let sel = this.selection(key)
            return (sel && Array.isArray(sel))
                ? sel[0] || null
                : sel
        },

        reset() {
            this.refresh();
        },

        apply(val, action, prop) {
            if (val && !Array.isArray(val)) {
                val = [val]
            } else if (!val) {
                val = []
            }
            let obj = {__idx: this.filterIndex};
            obj[prop] = val;
            this.$store.dispatch(action, obj);
        },

        save() {
            this.reconcile();
        },

        updateDemographics(v) {
            let changeset = {}

            Object.keys(v).forEach(k => {
                let empty = (typeof this.demographics[k] === 'undefined' || this.demographics[k] === null) && v[k] === null
                let matched = this.demographics[k] === v[k]
                if (!empty && !matched) changeset[k] = v[k]
            })

            Object.keys(this.demographics).forEach(k => {
                if (!Object.keys(v).includes(k)) {
                    if (!changeset.__removedKeys) changeset.__removedKeys = []
                    changeset.__removedKeys.push(k)
                }
            })

            if (Object.keys(changeset).length) {
                changeset.__idx = this.filterIndex
                this.$store.dispatch('thresholds/group/filter/update', changeset)
            }
        }
    }
};
</script>
