<template>
    <fe-dialog
        title="Add Incident Location"
        v-if="localValue"
        @dismiss="localValue=false"
        @accept="addLocation({ name: name })"
        :acceptButtonDisabled="!name"
        acceptButtonText="Save"
        persistent
        dismissButtonText="Cancel"
    >
        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.incidentLocation"
            @read="v => items = v"
        />

        <v-form>
            <v-text-field v-model="name" flat solo dense placeholder="Enter a location name"/>
        </v-form>
    </fe-dialog>
</template>

<script>
    export default {
        name: 'CreateIncidentLocation',
        props: {
            value: {
                default: false
            },
            id: {

            }
        },
        watch: {
            value(v) {
                if (v) {
                    this.name = ''
                }
                this.$data.localValue = v
            },
            localValue(v) {
                this.$emit('input', v)
            }
        },
        data() {
            return {
                name: '',
                localValue: false
            }
        },
        mounted() {
            this.$data.localValue = this.$props.value

            if (this.$props.id) this.loadData()
        },
        methods: {
            loadData() {
                this.$refs.crud.read({
                    id: this.$props.id,
                    // incident_behavior_type_id: this.$props.incidentBehaviorTypeId
                }).then(response => {})
            },
            addLocation() {
                if (!this.$data.name) return

                let o = {
                    // incident_behavior_type_id: this.incidentBehaviorTypeId,
                    name: this.$data.name,
                    rank: 0,
                    active: 1
                }

                this.$refs.crud.create(o)
                    .then(response => {})
                    .finally(() => {
                        this.$data.localValue = false
                        this.$emit('save')
                    })
            }
        }

    }
</script>

<style lang="scss" scoped>

</style>
