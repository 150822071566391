<template>
    <fe-dialog
        title="Add Incident Code"
        v-if="localValue"
        @dismiss="localValue=false"
        @accept="addCode()"
        :acceptButtonDisabled="!isValid"
        acceptButtonText="Save"
        persistent
    >
        <fe-crud
            ref="crud"
            :autoload="false"
            :config="$models.incidentBehavior"
            @read="v => items = v"
        />

        <v-form>
            <fe-label>Number of New Codes</fe-label>
            <v-text-field v-model="toCreate" type="number" flat solo dense/>

            <div v-for="(rec,i) in records" :key="`inc-code-`+i">
                <fe-label>Code Title</fe-label>
                <v-text-field v-model="rec.name" flat solo dense placeholder="Enter a name"/>
            </div>
        </v-form>
    </fe-dialog>
</template>

<script>
    export default {
        name: 'CreateIncidentCode',
        props: {
            value: {
                default: false
            },
            incidentBehaviorTypeId: {
                required: true
            },
            id: {

            }
        },
        watch: {
            toCreate(v) {
                this.$data.records = []
                for (let i=0; i<this.toCreate; i++) {
                    this.$data.records.push({
                        incident_behavior_type_id: this.$props.incidentBehaviorTypeId,
                        name: '',
                        rank: 0
                    })
                }
            },
            value(v) {
                this.$data.localValue = v
                this.toCreate = 1

                this.$data.records = [{
                    incident_behavior_type_id: this.$props.incidentBehaviorTypeId,
                    name: '',
                    rank: 0
                }]
            },
            localValue(v) {
                this.$emit('input', v)
            }
        },
        computed: {
            isValid() {
                if (this.$data.records.length === 0) return true

                let valid = true
                this.$data.records.forEach(rec => {
                    if (!rec.name) valid = false
                })
                return valid
            }
        },
        data() {
            let me = this
            return {
                records: [{
                    incident_behavior_type_id: me.$props.incidentBehaviorTypeId,
                    name: '',
                    rank: 0
                }],
                name: '',
                toCreate: 1,
                localValue: false
            }
        },
        mounted() {
            this.$data.localValue = this.$props.value
            if (this.$props.id) this.loadData()
        },
        methods: {
            loadData() {
                this.$refs.crud.read({
                    id: this.$props.id,
                    incident_behavior_type_id: this.$props.incidentBehaviorTypeId
                }).then(response => {})
            },
            addCode() {

                if (!this.isValid) return false

                this.$data.records.forEach(rec => { rec.incident_behavior_type_id = this.$props.incidentBehaviorTypeId })
                this.$refs.crud.create(this.$data.records)
                    .then(response => {

                    })
                    .finally(() => {
                        this.$data.localValue = false
                        this.$emit('save')
                    })
            }
        }

    }
</script>

<style lang="scss" scoped>

</style>
