<template>
    <div class="flex-fill flex-column no-scroll">
        <create-code @save="loadData" v-model="addDialog.show" :incidentBehaviorTypeId="localIncidentBehaviorTypeId"/>

        <fe-dialog
            width="400"
            title="Merge Codes"
            v-if="mergeDialog.show"
            @dismiss="mergeDialog.show=false"
            @close="mergeDialog.show=false"
            @accept="doMerge"
            acceptButtonText="Merge"
            :acceptButtonDisabled="!mergeDialog.record"
        >
            <v-form>
                <fe-label>Incident Code</fe-label>
                <fe-remote-combo
                    :items="mergeItems"
                    v-model="mergeDialog.record"
                    rootProperty='incident_behavior'
                    flat solo dense
                    itemText='name'
                    itemValue='id'
                    required
                    placeholder="Select a code..."
                />
            </v-form>
        </fe-dialog>

        <fe-dialog
            width="400"
            title="Move to Type"
            v-if="moveTypeDialog.show"
            @dismiss="moveTypeDialog.show=false"
            @close="moveTypeDialog.show=false"
            @accept="doMoveType"
            acceptButtonText="Move"
            :acceptButtonDisabled="!moveTypeDialog.record"
        >
            <v-form>
                <fe-label>Incident Type</fe-label>
                <fe-remote-combo
                    :url="$models.getUrl('incidentBehaviorType', 'read') + '&active=1'"
                    v-model="moveTypeDialog.record"
                    rootProperty='incident_behavior_type'
                    flat solo dense
                    itemText='name'
                    itemValue='id'
                    required
                    placeholder="Select a type..."
                />
            </v-form>
        </fe-dialog>

        <fe-crud
            ref="aliasCrud"
            :autoload="false"
            :config="$models.incidentBehaviorAlias"
            @read="v => aliasItems = v"
        />

        <fe-crud
            ref="descriptorCrud"
            :autoload="false"
            :config="$models.incidentBehaviorDescriptor"
            @read="v => descriptorItems = v"
        />

        <div>
            <fe-title-select
                style="margin: 0px"
                fontColor="black"
                itemText="name"
                itemValue="id"
                placeholder="Select a Behavior Code"
                :items="behaviorTypes"
                v-model="selectionChange"
            ></fe-title-select>
        </div>

        <fe-crud-grid
            ref="grid"
            :autoload="false"
            :readParams="readParams"
            :config="$models.incidentBehavior"
            @read="v => items = v"
            @selectionChanged="setSelectedRows"
            domLayout="normal"
            style="width: 100%"
            :columnDefs="columnDefs"
            :rowData="items"
            :showFullscreen="false"
            :showAddRowBtn="false"
            :showSaveAsDefault="false"
            @close-dialog="cellDialogClosed"
        >
            <template v-slot:selection-tools>
                <fe-btn useIcon="fas fa-exchange-alt" usage="ghost" dark @click="moveTypeDialog.show=true">Move to Type</fe-btn>
                <fe-btn useIcon="fas fa-code-merge" usage="ghost" dark @click="showMergeDialog()">Merge</fe-btn>
                <fe-btn useIcon="fal fa-trash" usage="ghost" dark @click="deleteSelected">Delete</fe-btn>
                <fe-btn usage="ghost" dark @click="gridApi.deselectAll()">Cancel</fe-btn>
            </template>

            <template v-slot:toolbar-items>
                <fe-btn @click="addDialog.show = !addDialog.show">Add Code</fe-btn>
            </template>

            <template v-slot:cellDialog="{cell}">
                <template v-if="cell.colDef.field === 'aliases'">

                    <div class="d-flex justify-space-between align-center pt-3 pr-2">
                        <v-flex grow class="grid-panel-title">Aliases</v-flex>
                        <v-flex shrink text-xs-right>
                            <v-btn icon small usage="ghost" @click="$refs.grid.$refs.grid.openMenu = false"><v-icon>close</v-icon></v-btn>
                        </v-flex>
                    </div>

                    <FeGrid
                        style="width: 600px; padding: 10px 20px 20px 20px;"
                        domLayout="autoHeight"
                        ref="aliasGrid"
                        :showToolbar="false"
                        :columnDefs="aliasColumnDefs"
                        :rowData="aliasItems"
                        :bordered="false"
                    ></FeGrid>

                    <div class="d-flex px-4">
                        <v-text-field
                            v-model="aliasText"
                            flat solo dense
                            :counter="1000"
                            style="margin-top: 6px; margin-right: 18px; margin-left: 4px;"
                            placeholder="Enter an alias"
                        />

                        <fe-btn
                            class="ml-auto"
                            :disabled="!aliasText || aliasText.length > 1000"
                            @click="addAlias"
                        >Add</fe-btn>
                    </div>
                </template>

                <template v-if="cell.colDef.field === 'descriptors'">

                    <div class="d-flex justify-space-between align-center pt-3 pr-2">
                        <v-flex grow class="grid-panel-title">Subtypes</v-flex>
                        <v-flex shrink text-xs-right>
                            <v-btn icon small usage="ghost" @click="$refs.grid.$refs.grid.openMenu = false"><v-icon>close</v-icon></v-btn>
                        </v-flex>
                    </div>

                    <FeGrid
                        style="width: 600px; padding: 10px 20px 20px 20px;"
                        domLayout="autoHeight"
                        ref="descriptorGrid"
                        :showToolbar="false"
                        :showFullscreen="false"
                        :showDownload="false"
                        :columnDefs="descriptorColumnDefs"
                        :rowData="descriptorItems"
                        :bordered="false"
                    ></FeGrid>

                    <v-layout row class="mx-5">
                        <v-text-field
                            v-model="descriptorText"
                            flat solo desnse
                            :counter="1000"
                            style="margin-top: 6px; margin-right: 18px;"
                            placeholder="Enter an subtype"
                        />

                        <fe-btn
                            :disabled="!descriptorText || descriptorText.length > 1000"
                            @click="addDescriptor"
                        >Add</fe-btn>
                    </v-layout>
                </template>
            </template>
        </fe-crud-grid>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'
    import CreateCode from './dialogs/CreateCode'
    import CrudTools from '@/components/common/CrudTools'

    export default {
        name: 'ManageIncidentBehavior',
        components: {
            CrudTools,
            CreateCode
        },
        props: {
            incidentBehaviorTypeId: {
                // type: Number,
                // required: true
            },
            headerText: {
                type: String,
                default: 'Undefined'
            }
        },
        inject: ['localStore'],
        watch: {
            localIncidentBehaviorTypeId(v, o) {
                if (!o) return
                // this.$removeCrumb('Incidents', 2)
                // For some reason nextTick fixes this, otherwise the localIncidentBehaviorTypeId is the old id
                this.$addCrumb('Incidents', {
                    name: 'Codes',
                    show: 'codes'
                }, 2)
                this.loadData()
            },
            selectionChange(v, o) {
                if (o) {
                    this.localIncidentBehaviorTypeId = v.id
                }

            }
        },
        computed: {
            ...mapLocalState(['selectedIncidentType']),
            ...mapState('manage', ['incidentTypes', 'breadcrumbs']),
            ...mapState('global', ['sessionUser']),
            sortedText() {
                return this.selectedSort.name ? this.selectedSort.name : 'Sort By'
            },
            readParams() {
                return { incident_behavior_type_id: this.localIncidentBehaviorTypeId, include_alias: 1, include_descriptors: 1 }
            },
            // defaultParams() {
            //     return {
            //         rank: 0,
            //         kpi: false,
            //         incident_behavior_type_id: this.localIncidentBehaviorTypeId,
            //         active: true
            //     }
            // },
            columnDefs() {
                let me = this
                return [{
                    headerName: null,
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    width: 70,
                    minWidth: 70,
                    colId: 'checkbox_column',
                }, {
                    headerName: "Code Title",
                    field: "name",
                    sortable: true,
                    editable: true,
                    minWidth: 150,
                    flex: 1
                }, {
                    headerName: "Restriction to Schools",
                    field: 'school_ids',
                    sortable: true,
                    editable: true,
                    flex: 1,
                    cellRenderer(v) {
                        let count = Array.isArray(v.value) ? v.value.length : 0
                        return count > 0 ? count + ' School'+(count>1?'s':''): ''
                    },
                    "cellEditorFramework": "FeGridChipSelect",
                    "cellEditorParams": {
                        "rowDataKey": "school_ids",
                        "mode": "edit",
                        "multiple": true,
                        "items": me.schools,
                        "keyProp": "id",
                        "labelProp": "name"
                    }
                }, {
                    headerName: 'Aliases',
                    field: 'aliases',
                    flex: 1,
                    cellStyle() {
                        return {
                            cursor: 'pointer'
                        }
                    },
                    valueGetter: (params) => params.data.aliases.length,
                    cellRendererFramework: "FeDialogColumn",
                    cellRendererParams: {
                        rowDataKey: "aliases",
                        parent: this
                    },
                    onCellClicked: function(v) {
                        me.$refs.grid.$refs.grid.setDialogCell(v)
                        me.selectedRow = v.data
                        me.loadAliases()
                    }
                }, {
                    headerName: 'Subtypes',
                    field: 'descriptors',
                    flex: 1,
                    cellStyle(v) {
                        return {
                            cursor: 'pointer'
                        }
                    },
                    valueGetter: (params) => params.data.descriptors.length,
                    cellRendererFramework: "FeDialogColumn",
                    cellRendererParams: {
                        rowDataKey: "descriptors",
                        parent: this
                    },
                    onCellClicked: function(v) {
                        me.selectedRow = v.data
                        me.$refs.grid.$refs.grid.setDialogCell(v)
                        me.loadDescriptors()
                    }
                }, me.$grid.toggleColumn('KPI', 'kpi', null, () => {}, {
                    hide: me.$hasSecurity('MANAGE_ACHIEVEMENT_DASHBOARD') ? false : true
                }), {
                    headerName: 'State Code',
                    field: 'state_code',
                    hide: me.sessionUser?.district.edfi_enabled ? false : true,
                    minWidth: 130,
                    flex: 1,
                    editable: true,
                    sortable: true
                }, {
                    headerName: 'Created',
                    field: 'created'
                }]
            },
            aliasColumnDefs() {
                let me = this
                return [{
                    headerName: 'Alias',
                    field: 'alias',
                    editable: true,
                    minWidth: 200,
                    flex: 1,
                    onCellValueChanged: v => this.updateAlias(v.data)
                    // onCellValueChanged: v => this.updateOption(v.data),
                    // cellStyle() {
                    //     return {
                    //         'text-align': 'right'
                    //     }
                    // }
                }, {
                    headerName: 'Upload #',
                    field: 'upload_id',
                    editable: false,
                    flex: 1,
                    onCellValueChanged: v => this.updateAlias(v.data)
                }, {
                    headerName: 'Created',
                    field: 'created',
                    editable: false,
                    flex: 1,
                }, {
                    headerName: 'Delete',
                    field: 'delete_option',
                    flex: 1,
                    cellRenderer(v) {
                        return '<i class="fas fa-trash" style="margin-left: 15px; font-size: 12px"></i>'
                    },
                    cellStyle() {
                        return {
                            'cursor': 'pointer',
                            'text-align': 'left'
                        }
                    },
                    onCellClicked: function(v) {
                        me.deleteAlias(v.data)
                        // me.cellClicked(v)
                    }
                }]
            },
            descriptorColumnDefs() {
                let me = this
                return [{
                    headerName: 'Subtype',
                    field: 'description',
                    editable: true,
                    flex: 1,
                    onCellValueChanged: v => this.updateDescriptor(v.data)
                    // cellStyle() {
                    //     return {
                    //         'text-align': 'right'
                    //     }
                    // }
                }, {
                    headerName: 'State Code',
                    field: 'state_code',
                    flex: 1,
                    editable: true,
                    onCellValueChanged: v => this.updateDescriptor(v.data)
                }, {
                    headerName: 'Rank',
                    field: 'rank',
                    editable: true,
                    flex: 1,
                    onCellValueChanged: v => this.updateDescriptor(v.data),
                    cellStyle: {
                        'text-align': 'right'
                    }
                }, {
                    headerName: 'Delete',
                    field: 'delete_option',
                    flex: 1,
                    cellRenderer(v) {
                        return '<i class="fas fa-trash" style="margin-left: 15px; font-size: 12px"></i>'
                    },
                    cellStyle() {
                        return {
                            'cursor': 'pointer',
                            'text-align': 'left'
                        }
                    },
                    onCellClicked: function(v) {
                        me.deleteDescriptor(v.data)
                        // me.cellClicked(v)
                    }
                }]
            },
            crudConfig() {
                return this.$models.incidentBehavior
            },
        },
        mounted() {
            this.localIncidentBehaviorTypeId = this.incidentBehaviorTypeId
            this.loadData()

        },
        created() {
            this.$axios.get(this.$models.getUrl('incidentBehaviorType', 'read'))
                .then(response => {
                    this.$data.behaviorTypes = response.data.incident_behavior_type
                    this.$data.selectionChange = this.$data.behaviorTypes.find((rec) => { return rec.id === this.$props.incidentBehaviorTypeId })
                })
                .catch(error => {
                    console.error(error)
                })
        },
        data() {
            let me = this

            return {
                cell: null,
                localIncidentBehaviorTypeId: null,
                behaviorTypes: [],
                selectedPage: null,
                selectionChange: null,
                max25chars: v => v.length <= 25 || 'Name too long!',
                filterText: '',
                items: [],
                schools: [],
                aliasItems: [],
                selectedRow: null,
                aliasText: null,
                descriptorItems: [],
                descriptorText: null,
                selectedRows: [],
                stateDialog: {
                    show: false,
                    record: {}
                },
                mergeDialog: {
                    show: false,
                    record: null
                },
                moveTypeDialog: {
                    show: false,
                    record: null
                },
                addDialog: {
                    show: false,
                    name: ''
                },
                gridApi: null,
            }
        },
        methods: {
            updateDescriptor(data) {
                this.$refs.descriptorCrud.update([data])
                    .then(response => { })
                    .finally(() => {})
            },
            updateAlias(data) {
                this.$refs.aliasCrud.update([data])
                    .then(response => { })
                    .finally(() => {})
            },
            setSelectedRows(meta) {
                this.$data.gridApi = meta.api
                this.selectedRows = meta.api.getSelectedRows()
            },
            deleteSelected() {
                this.$confirmDelete(this.selectedRows, () => {
                    this.$refs.grid.$refs.crud.destroy(this.selectedRows)
                        .then(() => {
                            this.loadData()
                        })
                })
            },
            showMergeDialog() {
                let selections = this.selectedRows
                this.mergeDialog.record = null
                // $models.getUrl('incidentBehavior', 'read') + '&incident_behavior_type_id='+localIncidentBehaviorTypeId
                this.$modelGet('incidentBehavior', {incident_behavior_type_id: this.localIncidentBehaviorTypeId})
                    .then(response => {
                        this.mergeItems = response.filter(rec => !selections.some(sel => rec.id === sel.id))
                    })
                    .finally(() =>  { this.mergeDialog.show = true })
            },
            doMerge() {
                this.$confirmCreate(this.selectedRows, () => {
                    this.selectedRows.forEach(rec => {
                        rec.merge_to_id = this.mergeDialog.record.id
                    })

                    this.$refs.grid.$refs.crud.update(this.selectedRows)
                        .then(() => {
                            this.loadData()
                        })
                }, 'merge')

            },
            doMoveType() {
                this.selectedRows.forEach(rec => {
                    rec.incident_behavior_type_id = this.moveTypeDialog.record.id
                })

                this.$refs.grid.$refs.crud.update(this.selectedRows)
                    .then(() => {
                        this.loadData()
                    })
            },
            /* Aliases Info */
            loadAliases() {
                if (this.selectedRow) {
                    this.$refs.aliasCrud.read({
                        incident_behavior_id: this.selectedRow.id
                    })
                }
            },
            addAlias() {
                if (this.aliasText) {
                    this.$refs.aliasCrud.create({
                        incident_behavior_id: this.selectedRow.id,
                        alias: this.aliasText
                    })
                    .then(() => {
                        this.loadAliases()
                        this.aliasText = ''
                    })
                    .finally(() => {
                        this.$refs.crud.read()
                    })
                }
            },
            deleteAlias(alias) {
                if (alias) {
                    this.$confirmDelete(alias.alias, () => {
                        this.$refs.aliasCrud.destroy(alias)
                            .then(() => {
                                this.loadAliases()
                                this.aliasText = ''
                            })
                            .finally(() => {
                            })
                    })
                }
            },
             /* Descriptor Info */
            loadDescriptors() {
                if (this.selectedRow) {
                    this.$refs.descriptorCrud.read({
                        incident_behavior_id: this.selectedRow.id
                    })
                }
            },
            addDescriptor() {
                if (this.descriptorText) {
                    this.$refs.descriptorCrud.create({
                        incident_behavior_id: this.selectedRow.id,
                        description: this.descriptorText,
                        rank: this.selectedRow.descriptors.length + 1
                    })
                    .then(() => {
                        this.loadDescriptors()
                        this.descriptorText = ''
                    })
                }
            },
            deleteDescriptor(descriptor) {
                if (descriptor) {
                    this.$confirmDelete(descriptor.description, () => {
                        this.$refs.descriptorCrud.destroy(descriptor)
                            .then(() => {
                                this.loadDescriptors()
                                this.descriptorText = ''
                            })
                    })
                }
            },
            cellClicked(cell) {
                this.cell = cell
            },
            cellDialogClosed(cell) {
                this.loadData()
            },
            saveRecord(item) {
                if (!item.data.name) {
                    this.loadData()
                    return
                }

                this.$refs.grid.$refs.crud.update(item.data)
                    .then(() => {
                        this.loadData()
                    })
            },
            loadData() {
                this.$setLoading(true)

                this.$axios.get(this.$models.getUrl('school', 'read'))
                    .then(response => {
                        this.$data.schools = response.data.schools

                        // now, load the behavior list
                        this.$refs.grid.$refs.crud.read()
                            .finally(() => {
                                this.$setLoading(false)
                    })
                    .catch(error => {
                        console.error(error)
                    })

                })
            },
            changeStateCode(item) {
                this.stateDialog.record = {...item}
                this.stateDialog.show = true
            }
        }
    }
</script>

<style lang="scss" scoped>
// .ec-table {
//     &th {
//         position: absolute;
//         top: 0px;
//     }
// }

.ec-table ::v-deep .v-input--checkbox {
    height: 24px;
}
</style>
