<template>
    <v-layout column>
        <div class="headline">
        <v-menu v-if="$isSupport() || ($checkSecurity('MANAGE_STUDENT_DATA') && $store.getters['flags/flags']['ec-data-manager'])" offset-y
            v-model="openMenu"
            :close-on-content-click="false"
            :nudge-bottom="5">
            <template v-slot:activator="{ on }">
                <fe-icon-btn v-on="on" useIcon="fa-ellipsis-v" style="float:right"></fe-icon-btn>
            </template>
            <v-list>
                <v-list-item @click="openDataManagerWindow">Data Manager</v-list-item>
            </v-list>
        </v-menu>

        District &amp; Schools</div>

        <div class="caption mb-2">
            Information about your district and schools is imported but the following areas can be configured here. Be sure to manage your school days off for accurate attendance reporting and intervention configuration.
        </div>

        <data-manager v-if="showDataManagerWindow" @showDataManagerClicked="showDataManagerWindow=false" />

        <fe-action-list
            @rowClick="go"
            :items="$parent.validItems.slice(1)"
            :showSearch="false"
            :addButton="false"
            :editButton="false"
            :showFiltering="false"
            class="district-pages mt-3"
        />
    </v-layout>
</template>

<script>
    import DataManager from '@/components/modules/manage/dataManager/Index'

    export default {
        name: "LandingPage",
        components: {
            DataManager,
        },
        data() {
            return {
                openMenu: false,
                showDataManagerWindow: false,
            }
        },
        methods: {
            openDataManagerWindow() {
                this.showDataManagerWindow = true
            },
            go(page) {
                this.$emit('go', page)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .caption {
        color: lightgray;
    }

    ::v-deep .district-pages .layout.mb-2.row {
        display: none
    }
</style>
