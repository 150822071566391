<template>
    <div class="d-flex flex-column" style="overflow: hidden; height: 94vh;">
        <div class="page-title d-flex">Create Scatter Plot
            <v-spacer/>
            <v-icon @click="$emit('closePanel')">fas fa-times</v-icon>
        </div>

        <div class="d-flex flex-fill flex-column" style="overflow-y: auto; overflow-x: hidden;">
            <v-expansion-panels flat class="pa-0">
                <v-expansion-panel class="pa-0">
                    <v-expansion-panel-header class="pa-0">
                        <div class="section-title">Student Selector</div>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="no-padding">
                        <fe-remote-combo
                            url="qry.php?property=school_years"
                            rootProperty="years"
                            itemText="name"
                            itemValue="id"
                            label="School Year"
                            byId
                            v-model="localCohortParams.cohort_school_year_id"
                        />

                        <fe-remote-combo
                            :url="`schools.php?action=get&school_year_id=`+localCohortParams.cohort_school_year_id"
                            rootProperty="schools"
                            itemText="name"
                            itemValue="id"
                            label="School"
                            byId
                            :disabled="!localCohortParams.cohort_school_year_id"
                            v-model="localCohortParams.cohort_school_id"
                        />

                        <fe-remote-combo
                            :url="`grades.php?action=get&school_year_id=`+localCohortParams.cohort_school_year_id+`&school_id=`+localCohortParams.cohort_school_id"
                            rootProperty="grades"
                            itemText="name"
                            itemValue="id"
                            label="Grade"
                            byId
                            :disabled="!localCohortParams.cohort_school_id"
                            v-model="localCohortParams.cohort_grade_id"
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="pa-0" :disabled="!cohortComplete">
                    <v-expansion-panel-header class="pa-0">
                        <div class="section-title">Data Set 1</div>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="no-padding">
                        <data-set v-model="firstSeries"/>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel  :disabled="!cohortComplete">
                    <v-expansion-panel-header class="pa-0">
                        <div class="section-title">Data Set 2</div>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="no-padding">
                        <data-set v-model="secondSeries"/>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>

        <div>
            <fe-btn :disabled="!canCreate" block usage="primary" @click="loadData">Create</fe-btn>
        </div>
    </div>
</template>

<script>
    import DataSet from './DataSet'
    import { mapState } from 'vuex'

    export default {
        name: 'CreateScatterPlot',
        components: {
            DataSet
        },
        props: {
            cohortParams: {
            }
        },
        computed: {
            ...mapState('global', ['currentYear']),
            canCreate() {
                let valid = true
                if (!this.cohortComplete) return false

                if (this.firstSeries.category_id==='incident') {
                    if (!this.firstSeries.incident_behavior_type_id) valid = false
                    return valid
                }

                if (this.secondSeries.category_id==='incident') {
                    if (!this.secondSeries.incident_behavior_type_id) valid = false
                    return valid
                }

                if (this.firstSeries.category_id==='attendance') {
                    if (!this.firstSeries.attendance_type_id) valid = false
                    return valid
                }

                if (this.secondSeries.category_id==='attendance') {
                    if (!this.secondSeries.attendance_type_id) valid = false
                    return valid
                }

                this.requiredKeys.forEach(key => {
                    if (['incident'].indexOf(this.firstSeries.category_id) == -1 && !this.firstSeries[key]) valid = false
                    if (['incident'].indexOf(this.secondSeries.category_id) == -1 && !this.secondSeries[key]) valid = false
                })

                return valid
            },
            cohortComplete() {
                let valid = true

                this.cohortKeys.forEach(key => {
                    if (!this.localCohortParams[key]) valid = false
                })
                return valid
            }
        },
        data() {
            let me = this
            return {
                firstSeries: {

                },
                secondSeries: {

                },
                xKey: 'score',
                yKey: 'score',
                localCohortParams: {

                },
                cohortKeys: ['cohort_school_year_id', 'cohort_school_id', 'cohort_grade_id'],
                requiredKeys: ['school_year_id', 'sub_category_id', 'data_point_name_id']
            }
        },
        created() {
            this.localCohortParams = this.cohortParams || {
                cohort_school_year_id: this.currentYear.id
            }
        },
        methods: {
            loadData() {
                let p1 = {...this.localCohortParams, ...this.firstSeries}
                let p2 = {...this.localCohortParams, ...this.secondSeries}

                this.$setLoading(true)
                this.fetch(this.firstSeries, p1).then((r,key) => {
                    this.firstSeriesData = r
                    if (key) this.xKey = key

                    this.fetch(this.secondSeries, p2).then((s, yKey) => {
                        if (yKey) this.yKey = yKey
                        this.secondSeriesData = s
                        this.$setLoading(false)
                        this.processData()
                    })
                })
            },
            fetch(serie, p) {
                return new Promise((resolve, reject) => {
                    if (serie.category_id === 'incident') {
                        this.$axios.get('behaviorIncident.php?action=get&property=datawall&'+this.$objectToParams(p))
                            .then(r => {
                                let d = this.$ecResponse(r, 'datawall')
                                let key = d.columns.find(r => r.xtype == 'incidentcolumn').dataIndex
                                // fake the descriptor colors
                                d.data.forEach(rec => {

                                    let v = rec[key] || 0
                                    rec.isIncident = true
                                    rec.sub_category_name = key
                                    rec.score = v

                                    if (v <= 1) {
                                        rec.target_descriptor_name = '0-1'
                                        rec.target_descriptor_color = 'green'
                                    } else if (v >= 2 && v <= 5) {
                                        rec.target_descriptor_name = '2-5'
                                        rec.target_descriptor_color = 'yellow'
                                    } else if (v > 5) {
                                        rec.target_descriptor_name = '6+'
                                        rec.target_descriptor_color = 'red'
                                    }
                                })
                                resolve(d.data)
                            })
                    } else if (serie.category_id === 'attendance') {
                        this.$axios.get('attendance.php?&property=datawall&action=get&'+this.$objectToParams(p))
                            .then(r => {
                                let d = this.$ecResponse(r, 'datawall')
                                // fake the descriptor colors
                                d.data.forEach(rec => {
                                    let v = rec[p.attendance_type_id] || 0
                                    rec.isAttendance = true
                                    rec.sub_category_name = 'Attendance'
                                    rec.score = v

                                    if (p.attendance_type_id === 'days_equivalent_pct') {
                                        if (v < 90) {
                                            rec.target_descriptor_name = '<90%'
                                            rec.target_descriptor_color = 'red'
                                        } else if (v < 95) {
                                            rec.target_descriptor_name = '<95%'
                                            rec.target_descriptor_color = 'yellow'
                                        } else {
                                            rec.target_descriptor_name = '95%+'
                                            rec.target_descriptor_color = 'green'
                                        }
                                    } else if (p.attendance_type_id === 'affected_days_pct') {
                                        if (v < 86) {
                                            rec.target_descriptor_name = '<70%'
                                            rec.target_descriptor_color = 'red'
                                        } else if (v < 90) {
                                            rec.target_descriptor_name = '<90%'
                                            rec.target_descriptor_color = 'yellow'
                                        } else {
                                            rec.target_descriptor_name = '90%+'
                                            rec.target_descriptor_color = 'green'
                                        }
                                    }


                                })
                                resolve(d.data)
                            })
                    } else {
                        this.$axios.get('targetScores.php?action=get&property=students&'+this.$objectToParams(p))
                            .then(r => {
                                resolve(this.$ecResponse(r, 'categories'))
                            })
                    }
                })
            },
            processData() {
                let me = this
                let students = this.$_.uniqBy(this.firstSeriesData.concat(this.secondSeriesData), 'student_id').map(s => { return { student_id: s.student_id, student_full_name: s.student_full_name, data: [] }})

                students.forEach(s => {
                    let first = this.firstSeriesData.find(r=>r.student_id===s.student_id)
                    let second = this.secondSeriesData.find(r=>r.student_id===s.student_id)

                    if (first && second) {
                        s.data.push(first, second)
                    }
                })

                this.$dockableWindow({
                    name: 'Scatter Plot',
                    component: 'scatter-plot',
                    attrs: {
                        items: students,
                        // xAxisKey: me.xKey,
                        // yAxisKey: me.yKey,
                        class: 'ma-5'
                    }
                })

                this.$emit('closePanel')
            }
        }
    }
</script>

<style lang="scss" scoped>
.no-padding {
    ::v-deep .v-expansion-panel-content__wrap {
        padding: 0px 0px 0px 0px;
    }
}
</style>
