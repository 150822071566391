<template>
    <fe-remote-combo
        label="Select a Visualization Type"
        :items="visOptions"
        v-model="localValue"
        class="vt-field"
        :rules="this.$fieldValidators('select', null, {required: true})"
        byId
        @change="$emit('change', $event)"
        @input="$emit('input', $event)"
        @set="$emit('set', $event)"
    >
        <template slot="item" slot-scope="data">
            <v-list-item-icon>
                <v-icon :class="data.item.icon" color="#050F2D" small/>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    <div>
                        <span class="vt-name mr-2">{{ data.item.name }}</span>
                        <span class="vt-desc">{{ data.item.description }}</span>
                    </div>
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </fe-remote-combo>
</template>

<script>
export default {
    name: "VisType",

    props: {
        value: {
            required: true
        },
        numeric: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            localValue: undefined
        }
    },

    computed: {
        visOptions() {
            let options = [{
                id: 'bar',
                name: 'Bar Chart',
                description: 'used to show how individual parts make up the whole',
                icon: 'fas fa-bars'
            }, {
                id: 'column',
                name: 'Column Chart',
                description: 'used to compare values, show data distribution, and analyze trends',
                icon: 'fas fa-chart-bar'
            }, {
                id: 'trendline',
                name: 'Line Chart',
                description: 'used to compare values, show data distribution, and understand relationships',
                icon: 'fas fa-chart-line'
            }, {
                id: 'table',
                name: 'Table',
                description: 'used when individual, precise values are needed to compare',
                icon: 'fas fa-table'
            }]

            if (this.numeric) {
                options.splice(2, 0, {
                    id: 'numeric',
                    name: 'Numeric Display',
                    description: 'used to compare values by showing the high and low values in a set',
                    icon: 'fas fa-hashtag'
                })
            }
            return options
        }
    },

    mounted() {
        this.localValue = this.value
    }
}
</script>

<style lang="scss" scoped>
::v-deep.v-list-item__icon {
    margin-right: 8px !important;
}

.vt-name {
    font-size: 14px;
    color: #050F2D
}

.vt-desc {
    font-size: 10px;
    color: #7E8494;
}
</style>
