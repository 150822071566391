<template>
    <fe-remote-combo
        v-bind="this.$attrs"
        :value="value" @input="$emit('input',$event)"
        :label="label"
        :params="params"
        url='users.php?action=get'
        itemText="user_full_name"
        lazyLoad
        placeholder="Search or select an option"
        no-data-text="No users found..."
    ></fe-remote-combo>
</template>

<script>
export default {
    name: 'UserCombo',
    
    props: {
        value: {},
        label: {type: String, default: 'User'},
        active: {type: [Number,Boolean], default: 1}
    },
    
    computed: {
        params() {
            return {active:this.$props.active?1:0}
        }
    }
}
</script>
