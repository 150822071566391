<template>
    <fe-dialog
        v-if="records"
        title="Set Form Status"
        @dismiss="$emit('close')"
        @accept="setStatus"
        dismissButtonText="Cancel"
        acceptButtonText="Save"
        persistent
    >
        <v-form @submit.prevent v-model="valid">
            <fe-label>Form Status</fe-label>
            <fe-remote-combo
                clearable
                placeholder="select a status"
                rootProperty="form_instance_status"
                :url="$models.getUrl('formInstanceStatus', 'read')"
                v-model="status"
            />
        </v-form>
        
        <fe-crud ref="crud" :config="$models.formInstance" :autoload="false" />
        <fe-crud ref="responseCrud" :config="$models.formInstanceResponse" :autoload="false" />
    </fe-dialog>
</template>

<script>
export default {
    name: 'SetStatusDialog',
    props: {
        records: {
            type: Array,
        },

    },
    watch: {
        records(v) {
            if(v) this.status = null
        }
    },
    methods: {
        setStatus() {
            // if form status field on form, update field value
            this.records.forEach(rec => {
                let id = null
                this.$axios.get('formResponse.php?action=get&property=form_response&form_instance_id=' + [rec.form_instance_id])
                    .then(resp => {
                        let found = resp.data.form_responses.find(x => x.form_field_type_id == 37) // find the form status code field id
                        if(found) id = found.form_field_id
                    })
                    .finally(() => {
                        if(id) {
                            let r = {
                                form_field_id: id,
                                form_instance_id: rec.form_instance_id,
                                value: this.status ? this.status.id.toString() : null
                            }
                            this.$axios.post('formResponse.php?action=update&property=form_response', { form_responses: [r] })
                        }
                    })
            })

            this.records.forEach(rec => {
                rec.form_instance_status_id = this.status?.id
                if(this.status?.id == 3) {
                    rec.form_instance_locked_flag = 1
                } else {
                    rec.form_instance_locked_flag = 0
                }
            })

            this.$refs.crud.update(this.records)
                .finally(() => {
                    this.$emit('close')
                    this.$emit('changed',this.records)
                })
        },
    },
    data() {
        return {
            status: null,
            valid: false,
        }
    }
}
</script>

<style lang="css" scoped>
</style>
