<template lang="html">
    <div class="flex-fill flex-column">
        <div class="flex-fill">
            <fe-collapse-Panel title="FeCollapsePanel" style="overflow:auto;"></fe-collapse-Panel>
            <div class="flex-fill flex-column">
                <v-toolbar flat>
                    <v-app-bar-nav-icon></v-app-bar-nav-icon>
                    <v-toolbar-title>
                        <fe-btn @click="overlay=!overlay">overlay</fe-btn>
                        {{overlay}}
                    </v-toolbar-title>
                </v-toolbar>

                <div ref="content" style="overflow-x:hidden; overflow-y:auto; position:relative;" class="content">
                    <div v-for="(x,i) in 36" class="block">{{i}} {{someText}}</div>
                    <fe-overlay
                        :value="overlay"
                        image="/images/empty-state-horn.png"
                        header="Intervention Workspace"
                        text="Select a group from the filter above"
                        color="white"
                    ></fe-overlay>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'

export default {
    name: 'SidebarToolbarContent',
    inject: ['localStore'],
    computed: {
        ...mapLocalState('someText'),
        overlayData() {
            return 'some text here'
        }
    },

    data() {
        return {
            overlay: false
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
}
</style>
