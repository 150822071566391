<template>
    <div class="d-flex behavior-button fill-height align-stretch col col-3 mr-2">
        <fe-color-selector :value="setBehaviorColor" @change="onUpdateBehaviorColor($event)"/>
        <div style="flex: 1;">
            <div class="stretch-inner fill-height">
                {{ behavior.name }}
            </div>
        </div>

        <template v-if="!checkIsBehaviorActive(behavior)">
            <div class="icon-button" style="background-color: #006C90;" @click="$emit('createInstantOccurrence', behavior)">
                <div class="stretch-inner fill-height">
                    <i class="far fa-plus"/>
                </div>
            </div>
            <div class="timer-button" :style="activeBtn.color" @click="timerButtonStatus">
                <div class="stretch-inner fill-height">
                    <i class="far fa-stopwatch"/>{{ activeBtn.text }}
                </div>
            </div>
        </template>
        <template v-else>
            <div class="icon-button disabled" style="background-color: #006C90;">
                <div class="stretch-inner fill-height">
                    <i class="far fa-plus"/>
                </div>
            </div>
            <div class="timer-button disabled" :style="activeBtn.color">
                <div class="stretch-inner fill-height">
                    <i class="far fa-stopwatch"/>{{ activeBtn.text }}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ObservationBehavior',
    props: {
        behavior: {
            type: Object,
            required: true
        },
        observationBehaviors: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            // These defaults are copied from the various defaults in FeColorSelector
            localBehaviorColors: [
                '#0049FF', '#0EA449', '#FF8E07', '#F02D1F', '#3CCCCA', '#5A53C9',
                '#2B87FF', '#49C379', '#FFA200', '#FD4336', '#53DAD8', '#746DE0',
                '#539AF8', '#5BE893', '#FFBD00', '#FF675D', '#66EEEC', '#8E88EA',
                '#89B7F4', '#84FFB5', '#FFE072', '#FF8D85', '#93F9F7', '#AFAAF9',
                '#C4DCFA', '#B4F8CF', '#FFEEAA', '#F8D1CE', '#C0FBFA', '#DCDAFB',
            ],
            behaviorColor: null,
            timerStatus: false, // stopped by default
            activeBtn: {
                text: 'Start',
                color: 'background-color: #0EA449;',
                func: 'startBehaviorTimer'
            }
        }
    },

    computed: {
        setBehaviorColor() {
            return this.behaviorColor ? this.behaviorColor : this.getBehaviorColor(this.behavior)
        }
    },

    methods: {
        getBehaviorColor(behavior) {
            let index = this.observationBehaviors.findIndex(itm => itm.id === behavior.id)
            return this.localBehaviorColors[index]
        },
        onUpdateBehaviorColor(value) {
            this.behaviorColor = value
            this.$emit('updateLocalBehaviorColor', {
                behavior: this.behavior,
                color: value,
            })
        },
        checkIsBehaviorActive(behavior) {
            return this.behavior.behavior_id == behavior.id
        },
        timerButtonStatus() {
            this.$emit(this.activeBtn.func, this.behavior)
            this.timerStatus = !this.timerStatus
        }
    },

    watch: {
        timerStatus: function() {
            return this.activeBtn = {
                text: this.timerStatus ? 'Stop' : 'Start',
                color: this.timerStatus ? 'background-color: #F44337;' : 'background-color: #0EA449;',
                func: this.timerStatus ? 'endBehaviorTimer' : 'startBehaviorTimer'
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .behavior-button {
        border-radius: 5px;
        border: 2px #ddd solid;
        align-items: stretch;
    }

    .icon-button {
        width: 45px;
        color: #fff;
        text-align: center;
        cursor: pointer;

        .stretch-inner {
            text-align: center;

            i {
                margin: 0 auto;
            }
        }
    }

    .timer-button {
        width: 65px;
        color: #fff;
        text-align: center;
        cursor: pointer;

        .stretch-inner {
            display: inline-flex;
            text-align: center;
            margin-left: -5px;

            i {
                margin: 5px;
            }
        }
    }

    .stretch-inner {
        display: flex;
        align-items: center;
    }

    .disabled {
        background-color: #eee !important;
        color: #777;
        pointer-events: none;
        cursor: not-allowed;
    }

    .title-text {
        font-size: 18px;
    }

    ::v-deep .fe-color-selector-card {
        border: 0 !important;
    }
</style>
