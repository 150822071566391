<template>
    <fe-dialog
        title="Reassign Records"
        acceptButtonText="Reassign"
        width="500px"
        persistent
        :forceClose="false"
        dismissable
        disableAutoclose
        @accept="saveRec"
        @dismiss="dismissDialog"
    >
        <v-form ref="form">
            <div class="d-flex pb-2">
                <label class="pr-4 my-auto student-info-label">Current Student</label>
                <fe-text-field
                    class="student-controls"
                    v-model="studentNameId"
                    disabled
                />
            </div>

            <div class="d-flex pb-2">
                <label class="pr-4 my-2 student-info-label">To Student</label>
                <v-autocomplete
                    v-model="newRec.selectedSearchRecord"
                    :search-input.sync="newRec.searchText"
                    flat solo dense
                    placeholder="Search for students"
                    class="nav-student-search-text student-controls"
                    :items="newRec.searchResults"
                    :itemText="text"
                    itemValue="id"
                    return-object
                    :rules="$fieldValidators('select', 'To student', { required: true })"
                    validateOnBlur
                    clearable
                >
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title v-html="text(data.item)"></v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-slot:selection="data">
                        <div v-html="text(data.item)"></div>
                    </template>
                </v-autocomplete>
            </div>
        </v-form>
    </fe-dialog>
</template>

<script>
    import Vuex, { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'
    import lodash from 'lodash'

    export default {
        name: 'ReassignModal',

        props: {
            crud: null
        },

        data() {
            return {
                newRec: {
                    searchText: undefined,
                    searchResults: undefined,
                    selectedSearchRecord: undefined
                }
            }
        },

        inject: ['localStore'],

        computed: {
            ...mapLocalState(['updateStudent','reassignStudent','reassignShowModal','selections']),
            ...mapState('global',['currentYear']),

            studentNameId () {
                return this.updateStudent.student_full_name + ' (' + this.updateStudent.student_id + ')'
            }
        },

        methods: {
            text(item) {
                return item.mdl_init ? item.lname + " " + item.mdl_init + ", " + item.fname + " (" + item.id + ")" : item.lname + ", " + item.fname + " (" + item.id + ")"
            },

            doSearch() {
                // Changing params to remove school_year_id and changing endpoint to student
                // let params = '&name=' + this.newRec.searchText + '&school_year_id=' + this.currentYear.id
                let params = '&name=' + this.newRec.searchText
                let me = this

                this.$axios.get(this.$models.getUrl('student', 'read') + params)
                .then(response => {
                    // Use filter to exclude Current student from results
                    // and in case we want more ids excluded in the future
                    let ids = [this.updateStudent.student_id]
                    this.newRec.searchResults = response.data.filter(function (item) {
                        return ids.indexOf(item.id) === -1
                    })
                })
                .catch(error => {
                    console.error(error)
                })
            },

            formatDate(date) {
                if (typeof date == "string")
                {
                    date = new Date()
                }

                let year = date.getFullYear(),
                    month = (1 + date.getMonth()).toString(),
                    day = date.getDate().toString(),
                    hours = date.getHours().toString(),
                    minutes = date.getMinutes().toString(),
                    seconds = date.getSeconds().toString()

                month = month.length > 1 ? month : '0' + month
                day = day.length > 1 ? day : '0' + day
                hours = hours.length > 1 ? hours : '0' + hours
                minutes = minutes.length > 1 ? minutes : '0' + minutes
                seconds = seconds.length > 1 ? seconds : '0' + seconds

                return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes
            },

            saveRec() {
                let me = this

                if(this.$refs.form.validate()) {
                    let newItems = _.cloneDeep(this.selections)

                    newItems.map(item => {
                        item.student_id = this.reassignStudent.id
                        if (item.incident_datetime) {
                            item.datetime = this.formatDate(item.incident_datetime)
                        }
                    })

                    this.crud.update(newItems).then( r => {
                        this.resetEntry()
                        this.dismissDialog()
                    })
                }
            },

        resetEntry() {
            this.newRec = {
                searchText: undefined,
                searchResults: undefined,
                selectedSearchRecord: undefined
            }

            this.reassignStudent = undefined
        },

        dismissDialog() {
            if (this.$_.isEmpty(Object.values(this.newRec).filter(x => !!x))) {
                this.$emit('reassignBtnClick')
            } else {
                this.$confirmCancel(() => {
                    this.resetEntry()
                    this.$emit('reassignBtnClick')
                })
            }
            this.selections = []
        }
        },

        watch: {
            'newRec.searchText': lodash.debounce(function(v) {
                if (v && v.length >= 3) {
                    this.doSearch()
                }

            }, 500),

            'newRec.selectedSearchRecord'(v) {
                v ? this.reassignStudent = v : this.reassignStudent = undefined
            }
        }
    }
</script>

<style lang="scss" scoped>
    .student-controls {
        max-width: 330px;
        min-width: 330px;
    }

    label.student-info-label {
        width: 125px;
    }

    ::v-deep .nav-student-search-text {
        font-size: 14px;
    }
</style>
