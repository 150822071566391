<template>
    <div :style="{height: height+'px'}" style="overflow: hidden;">

        <pin-dialog v-model="pinDialog.show" :pinParams="pinParams" :title="pinDialog.title"/>

        <div class="d-flex">
            <div v-if="chartTabs.length" class="pl-5" v-show="!isSavedSearch">
                <tabs :tabs="chartTabs" v-model="activeTab"></tabs>
            </div>

            <div class="ml-auto">
                <slot name="extra-tools"></slot>
                <fe-icon-btn useIcon="fas fa-thumbtack" @click="doPin" v-if="pinnable && activeTab.index !== 1"/>
            </div>
        </div>

        <chart v-show="activeTab && activeTab.index === 0" :key="`main-chart-${activeTab.index}`" :stopClick="!showAssessmentHistory" ref='chartRef' :config="chartOptions" :collectionChart="collectionChart" :rawData="rawData">
            <template #before-menu v-if="showTools && collectionChart">
                <fe-icon-btn useIcon="fas fa-expand-alt" @click="doExpand"/>
            </template>
        </chart>
        <chart v-show="activeTab && activeTab.index === 1" :key="`child-chart-${activeTab.index}`" :stopClick="!showAssessmentHistory" ref='childChartRef' :config="childChartOptions" :collectionChart="collectionChart" :rawData="rawData">
            <template #before-menu v-if="showTools && collectionChart">
                <fe-icon-btn useIcon="fas fa-expand-alt" @click="doExpand"/>
            </template>
        </chart>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Tabs from '@/components/common/button/Tabs'
    import MenuBtn from '@/components/common/button/MenuBtn'
    import PinDialog from '@/components/common/PinDialog'
    import Chart from "./Chart";
    export default {
        components: {
            Chart,
            MenuBtn,
            PinDialog,
            Tabs
        },
        name: 'AssessmentTrendline',
        data() {
            return {
                rawData: [],
                data: [],
                series: [],
                childSeries: [],
                windows: [],
                childWindows: [],
                minValue: undefined,
                maxValue: undefined,
                childMinValue: undefined,
                childMaxValue: undefined,
                chartReady: false,
                chartLabel: 'Not Set',
                chartTabs: [],
                selectedTab: null,
                pinDialog: {
                    show: false,
                    title: ''
                },
                isParent: false,
                parentSubCategoryId: null,
                tooltip: function() {
                    return this.series.name + ': ' + this.y
                },
                axisValues: [],
                axisLabels: [],
            }
        },
        watch: {
            activeTab(v, o) {
                this.$nextTick(() => {
                    let ref = v.index === 0 ? 'chartRef' : 'childChartRef'
                    this.$refs[ref].reflow()
                })

                if (!o || !o.name) return

                if (v.index !== 0) {
                    this.loadChildData(v.params)
                }
            }
        },
        props: {
            params: {
                required: true
            },
            height: {
                type: Number,
                default: 500
            },
            border: {
                type: Boolean,
                default: true
            },
            pinnable: {
                type: Boolean,
                default: true
            },
            collectionChart: {
                type: Boolean,
                default: false
            },
            showTools: {
                type: Boolean,
                default: true
            },
            hideSubcategories: {
                type: Boolean,
                default: false
            },
            dataPointTypeId: {
                type: Number,
                default: null
            },
            showAssessmentHistory: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapState('global', ['sessionUser', 'userPreferences']),
            topSecretModeActive(){
                return this.$isTopSecretModeActive(this.sessionUser, this.userPreferences)
            },
            activeTab: {
                get () { return this.selectedTab || (this.chartTabs.length && this.chartTabs[0]) || {} },
                set (v) { this.selectedTab = v }
            },
            isSavedSearch() {
                if (this.$props.params.hasOwnProperty('saved_search_id')) {
                    return true
                }
                return false
            },
            pinParams() {
                let p = {...this.$props.params}
                p.dashboard_saved_search_type_id = 2
                delete p.target_type_id
                p.data_point_type_id = this.dataPointTypeId
                return p
            },
            childChartOptions() {
                let me = this

                let opt = {
                    chart: this.childSeries.length ? {
                        height: me.height-80 // 80 is for the toolbar height
                    } : 'no-data',
                    noDataText: 'No data found for Sub Assessments.',
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: 'Sub Assessments',
                        useHTML: true,
                        align: 'left',
                        style: {
                            'color': '#7E8494',
                            'border-bottom': '1px solid #E0E1E6',
                            'display': 'block',
                            'width': '100%'
                        }
                    },
                    exporting: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            fillOpacity: 0.1,
                            point: {
                                events: {
                                    click(event) {
                                        let series = event.point.series.chart.series,
                                            x = event.point.x,
                                            y = event.point.y
                                        event.preventDefault()
                                        return
                                    }
                                }
                            },
                        }
                    },
                    tooltip: {
                        pointFormatter: function(v) {
                            v = me.$getAlphaScore(this.series?.options?.custom?.data_point_type_id, this.y)
                            return `<ul style="color: ${this.color};"><li>● <span style="color: black;">${this.series.name}: <b>${v}</b></span></li></ul>`
                        },
                    },
                    colorAxis: {
                        labels: {
                            step: 3,
                            enabled: true
                        },
                        gridLineWidth: 0
                    },
                    xAxis: {
                        min: undefined,
                        categories: me.childWindows,
                        gridLineWidth: 0
                    },
                    yAxis: {
                        title: {
                            text: 'Score'
                        },
                        stackLabels: {
                            enabled: false,
                            style: {
                                fontWeight: 'bold'
                            }
                        },
                        opposite: false,
                        labels: {
                            formatter: (params) => {
                                let dpId = 
                                    this.params?.data_point_type_id ? this.params?.data_point_type_id : 
                                    this.series[0]?.custom?.data_point_type_id ? this.series[0]?.custom?.data_point_type_id : 
                                    null

                                return (dpId && params.value)
                                ? me.$getAlphaScore(dpId, params.value)
                                : params.value
                            }
                        },
                        min: me.childMinValue !== undefined ? me.childMinValue : me.minValue,
                        max: me.childMaxValue !== undefined ? me.childMaxValue : me.maxValue,
                    },
                    series: me.childSeries,
                    dash_id: me.params.dashboard_id ? me.params.dashboard_id : null,
                    saved_search_id: me.params.saved_search_id ? me.params.saved_search_id : null,
                    index: me.params.index ? me.params.index : null,
                    type: me.params.type ? me.params.type : null
                }
                return opt
            },
            chartOptions() {
                let me = this
                let opt = {
                    chart: {
                        height: me.height-80 // 80 is for the toolbar height
                    },
                    credits: {
                        enabled: false
                    },
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    },
                    title: {
                        text: me.chartLabel,
                        useHTML: true,
                        align: 'left',
                        style: {
                            'color': '#7E8494',
                            'border-bottom': '1px solid #E0E1E6',
                            'display': 'block',
                            'width': '100%'
                        }
                    },
                    exporting: {
                        enabled: false
                    },
                    legend: {
                        align: 'left',
                        shadow: false,
                    },
                    plotOptions: {
                        series: {
                            fillOpacity: 0.1,
                            point: {
                                events: {
                                    click(e) {
                                        if (e.point.series.type != 'area') return
                                        let rec = me.rawData.find(r => r.data_point == e.point.category)
                                        me.$emit('chartClicked', rec, e)
                                    }
                                }
                            }
                        }
                    },
                    tooltip: {
                        pointFormatter: function(v) {
                            v = me.$getAlphaScore(this.series?.options?.custom?.data_point_type_id, this.y)
                            return `<ul style="color: ${this.color};"><li>● <span style="color: black;">${this.series.name}: <b>${v}</b></span></li></ul>`
                        },
                    },
                    colorAxis: {
                        labels: {
                            step: 3,
                            enabled: true
                        },
                        gridLineWidth: 0
                    },
                    xAxis: {
                        min: undefined,
                        categories: me.windows,
                        gridLineWidth: 0
                    },
                    yAxis: {
                        title: {
                            text: 'Score'
                        },
                        stackLabels: {
                            enabled: false,
                            style: {
                                fontWeight: 'bold'
                            }
                        },
                        opposite: false,
                        labels: {
                            formatter: (params) => {
                                let dpId = 
                                    this.params?.data_point_type_id ? this.params?.data_point_type_id : 
                                    this.series[0]?.custom?.data_point_type_id ? this.series[0]?.custom?.data_point_type_id : 
                                    null

                                let scoreDisplay = null
                                if (dpId && params.value && !this.axisValues.includes(params.value) && !this.axisLabels.includes(me.$getAlphaScore(dpId, params.value))) {
                                    scoreDisplay = me.$getAlphaScore(dpId, params.value)
                                    this.axisValues.push(params.value)
                                    this.axisLabels.push(me.$getAlphaScore(dpId, params.value))
                                } else {
                                    let i = this.axisValues.indexOf(params.value)
                                    scoreDisplay = (i == -1) ? params.value : this.axisLabels[i]
                                }

                                return scoreDisplay
                            }
                        },
                        min: me.minValue,
                        max: me.maxValue
                    },
                    series: me.series,
                    dash_id: me.params.dashboard_id ? me.params.dashboard_id : null,
                    saved_search_id: me.params.saved_search_id ? me.params.saved_search_id : null,
                    index: me.params.index ? me.params.index : null,
                    type: me.params.type ? me.params.type : null
                }

                return opt
            }
        },
        mounted() {
            this.loadData(this.$props.params)
            if(this.$refs.childChartRef) {
                this.$emit('all-charts', this.$refs.childChartRef)
            } else {
                this.$emit('all-charts', this.$refs.chartRef)
            }
        },
        methods: {
            doPin() {
                this.pinDialog.title = this.chartLabel
                this.pinDialog.show  = true
            },
            doExpand() {
                let c = {
                    params: this.$props.params,
                    height: window.innerHeight-200,
                    showTools: false,
                    pinnable: false
                }
                this.$store.commit('global/addDockableWindow', {
                    name: this.chartLabel,
                    component: 'assessment-trendline',
                    attrs: c
                })
            },
            loadChildData(params) {
                this.$setLoading(true)
                this.$modelGet('studentScores', params).then(response => {
                    if (response.length === 0) {
                        this.childSeries = []
                        this.childWindows = []
                        this.$setLoading(false)
                        return
                    }
                    let config = response
                    let series = []
                    let categories = []
                    this.$setLoading(false)
                    config.forEach(c => {
                        c.subcategories.forEach(s => {
                            s.data.forEach(d => {
                                if (categories.indexOf(d.data_point)===-1) categories.push(d.data_point)
                            })
                        })
                    })

                    response.forEach(cat => {
                        cat.subcategories.forEach(s => {
                            let serie = series.find(r=>r.name===s.sub_category_name)
                            if (!serie) {
                                serie = {
                                    name: s.sub_category_name,
                                    custom: {
                                        data_point_type_id: s.data_point_type_id,
                                    },
                                    data: [],
                                }
                                series.push(serie)
                            }

                            // need to make sure data is aligned with the categories (add "filler" data)
                            // otherwise, data might end up in the wrong category on the chart
                            categories.forEach(c => {
                                let data = s.data
                                if (!data.some(d => d.data_point == c)) {
                                    data.push({
                                        data_point: c,
                                        sub_category_id: s.id,
                                        data_align: true
                                    })
                                }
                                // data.sort((a, b) => (a.data_point > b.data_point) ? 1 : ((b.data_point > a.data_point) ? -1 : 0))
                            })

                            s.data.forEach(r => {
                                let d
                                if (categories.indexOf(r.data_point) !== -1) d = r

                                // When top secret mode is on, the backend returns with student id,
                                // whereas when top secret mode is off, backend returns with student full name
                                let v
                                if (this.topSecretModeActive) {
                                    v = d ? parseFloat(d[s.student_id]) : null
                                } else {
                                    v = d ? d[s.student_full_name] : null
                                }

                                if (this.childMinValue === null || this.childMinValue === undefined || v < this.childMinValue) this.childMinValue = v
                                if (this.childMaxValue === null || this.childMaxValue === undefined || v > this.childMaxValue) this.childMaxValue = v
                                serie.data.push({y: v})

                                if (categories.indexOf(r.data_point) !== -1) return d
                            })
                        })
                    })
                    this.childSeries = series
                    this.childWindows = categories
                })
            },
            loadData(params) {
                let me = this
                let p = this.$objectToParams(params)
                me.$data.chartReady = false
                this.$setLoading(true)
                this.$axios.get(this.$models.getUrl('studentScores', 'read') + '&' + p)
                    .then(response => {

                        if (response.data && response.data.scores) {
                            me.$data.data = this.filterDuplicateData(response.data.scores)
                            me.parseData()

                            // Only build the tabs on the first query
                            if (me.chartTabs.length === 0) {
                                me.$data.chartTabs.push({
                                    name: me.chartLabel,
                                    index: 0,
                                    params: me.$props.params
                                })

                                if (me.isParent && !this.hideSubcategories) {
                                    let childParams = {...me.$props.params}
                                    childParams.v = 2
                                    childParams.sub_category_parent_id = me.parentSubCategoryId
                                    delete childParams.sub_category_id
                                    me.$data.chartTabs.push({
                                        name: 'Sub Assessments',
                                        index: 1,
                                        params: childParams
                                    })
                                }
                            }
                        }
                    })
                    .finally(() => {
                        this.$setLoading(false)
                        me.$data.chartReady = true
                    })
            },
            filterDuplicateData(recs) {
                return recs.map(rec => {
                    rec.subcategories = rec.subcategories.map(sc => {
                        let included = []
                        sc.data = sc.data.map(itm => {
                            let id = `${itm.student_id}:${itm.data_point_score_id}`
                            if (included.includes(id)) return null
                            included.push(id)
                            return itm
                        }).filter(itm => itm != null)
                        return sc
                    })
                    return rec
                })
            },
            parseData() {
                let me = this

                this.$data.data.forEach(group => {
                    group.subcategories.forEach(sub => {
                        me.$data.chartLabel = me.params.title? me.params.title : sub.data_point_type_name + ' - ' + sub.sub_category_name
                        me.isParent = sub.is_parent
                        me.parentSubCategoryId = sub.id

                        this.$data.series = this.buildSeries(sub.fields, sub.colors, sub.data_point_type_id)
                        this.setSeriesData(sub.data)
                    })
                })
            },
            buildSeries(fields, colors, dataPointTypeId) {
                let series = []

                fields.filter(r => { return r.name != 'data_point' }).forEach((serie, i) => {
                    series.push({
                        type: serie.fill ? 'area' : 'line',
                        dashStyle: serie.fill ? undefined : 'ShortDash',
                        marker: {
                            radius: 5
                        },
                        name: serie.name,
                        custom: {
                            data_point_type_id: dataPointTypeId,
                        },
                        data: [],
                        color: colors[i]
                    })
                })

                return series
            },
            setSeriesData(data) {
                this.rawData = data

                this.$data.windows = []
                this.minValue = null
                this.maxValue = null

                data.forEach((d, i) => {
                    this.$data.windows.push(d.data_point)
                    this.$data.series.forEach(serie => {
                        let score = d[serie.name]
                        if (this.minValue === null || score < this.minValue) this.minValue = score
                        if (this.maxValue === null || score > this.maxValue) this.maxValue = score

                        let hasScore = !!score || score === 0
                        if (serie.type === 'area') {
                            serie.data.push(hasScore ? { y: score, color: d.target_descriptor_color } : null)
                        } else {
                            serie.data.push(hasScore ? score : null)
                        }
                    })
                })

                // min or max of 0 is fine, even though it's falsy, but if he
                // didn't find any score data at all, set generic defaults here
                if (this.minValue === null) {
                    this.minValue = 0
                }
                if (this.maxValue === null) {
                    this.maxValue = 1
                }
            },
            chartClicked(evt) {
                this.$emit('chartClicked', evt)
            }
        }
    }
</script>

<style lang="scss" scoped>
.fe-chart ::v-deep * {
    font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
}
</style>
