<template>
    <div>
        <fe-dialog
            :title="'Permissions for: ' + data.name"
            width="700"
            height="70vh"
            :footer="false"
            persistent
            @close="$emit('close')"
            @dismiss="$emit('close')"
            @accept="$emit('close')"
        >
            <fe-tabs
                :tabs="tabs"
                @tabClicked="selectedTab = $event"
                no-padding
                style="height: 60px;"
            />

            <fe-grid
                v-if="selectedTab.type === 'user'"
                :rowData="user"
                :columnDefs="userColumnDefs"
                :showDownload="false"
                style="height: calc(100% - 60px);"
                addRowBtnText="Add User"
                displayDensity="medium"
                disableInlineCreate
                suppressContextMenu
                @beginCreate="beginAddUsers"
            />

            <fe-grid
                v-if="selectedTab.type === 'group'"
                :rowData="group"
                :columnDefs="groupColumnDefs"
                :showDownload="false"
                :showAddRowBtn="false"
                style="height: calc(100% - 60px);"
                addRowBtnText="Add Group"
                displayDensity="medium"
                disableInlineCreate
                suppressContextMenu
            >
                <template #toolbar-items>
                    <fe-remote-combo
                        v-if="selectedTab.type === 'group'"
                        :url="$models.getUrl('userGroup', 'read')"
                        rootProperty="user_group"
                        v-model="add.group"
                        placeholder="Add Group"
                        byId
                    />
                </template>
            </fe-grid>
        </fe-dialog>

        <fe-dialog
            v-if="addUsers"
            title="Find Users"
            width="700"
            height="70vh"
            acceptButtonText="Select"
            dismissButtonText="Cancel"
            dismissable
            persistent
            @close="addUsers = false"
            @accept="create('user')"
        >
            <user-search v-model="add.user" :searchYear="currentYear"/>
        </fe-dialog>
    </div>
</template>

<script>
import {mapState} from "vuex"
import UserSearch from '@/components/common/UserSearch'

export default {
    name: "DemographicPermissions",

    components: {UserSearch},

    props: {
        data: {required: true},
        userCrud: {required: true},
        groupCrud: {required: true},
    },

    data() {
        return {
            selectedTab: {
                name: 'Users (0)',
                path: '#',
                type: 'user'
            },
            addUsers: false,
            add: {
                user: [],
                group: undefined
            },
            user: [],
            group: [],
            readParams: {},
            userColumnDefs: [
                {
                    headerName: 'User Name',
                    field: 'user_full_name',
                    sortable: true,
                    editable: false
                }, {
                    headerName: 'Can View',
                    field: 'can_view',
                    width: 90,
                    sortable: true,
                    editable: false,
                    cellRendererFramework: "FeGridToggle",
                    onCellValueChanged: v => this.update('user', v.data)
                }, {
                    headerName: 'Can Search',
                    field: 'can_search',
                    width: 90,
                    sortable: true,
                    editable: false,
                    cellRendererFramework: "FeGridToggle",
                    onCellValueChanged: v => this.update('user', v.data)
                }, {
                    headerName: 'Delete',
                    field: 'delete_option',
                    width: 70,
                    cellRenderer() {
                        return '<i class="fas fa-trash" style="font-size: 12px"></i>'
                    },
                    cellStyle() {
                        return {
                            'cursor': 'pointer',
                            'text-align': 'center'
                        }
                    },
                    onCellClicked: v => {
                        this.destroy('user', v.data)
                    }
                }
            ],
            groupColumnDefs: [
                {
                    headerName: 'Group Name',
                    field: 'user_group_name',
                    sortable: true,
                    editable: false
                }, {
                    headerName: 'Can View',
                    field: 'can_view',
                    width: 90,
                    sortable: true,
                    editable: false,
                    cellRendererFramework: "FeGridToggle",
                    onCellValueChanged: v => this.update('group', v.data)
                }, {
                    headerName: 'Can Search',
                    field: 'can_search',
                    width: 90,
                    sortable: true,
                    editable: false,
                    cellRendererFramework: "FeGridToggle",
                    onCellValueChanged: v => this.update('group', v.data)
                }, {
                    headerName: 'Delete',
                    field: 'delete_option',
                    width: 70,
                    cellRenderer() {
                        return '<i class="fas fa-trash" style="font-size: 12px"></i>'
                    },
                    cellStyle() {
                        return {
                            'cursor': 'pointer',
                            'text-align': 'center'
                        }
                    },
                    onCellClicked: v => {
                        this.destroy('group', v.data)
                    }
                }
            ],
            tabs: [
                {
                    name: 'Users (0)',
                    path: '#',
                    type: 'user'
                }, {
                    name: 'User Groups (0)',
                    path: '#',
                    type: 'group'
                }
            ]
        }
    },

    computed: {
        ...mapState('global', ['currentYear'])
    },

    watch: {
        'add.group'(v) {
            if (v) {
                this.create('group')
            }
        }
    },

    mounted() {
        this.readParams = {demographic_id: this.data.id}

        let transactions = [
            this.userCrud.read(this.readParams),
            this.groupCrud.read(this.readParams)
        ]

        Promise.all(transactions).then(res => {
            this.user = res[0].data.demographic_user || []
            this.group = res[1].data.demographic_user_group || []

            this.tabs = [{
                name: 'Users (' + this.user.length + ')',
                path: '#',
                type: 'user'
            }, {
                name: 'User Groups (' + this.group.length + ')',
                path: '#',
                type: 'group'
            }]
        })
    },

    methods: {
        beginAddUsers() {
            this.add.user = []
            this.addUsers = true
        },

        create(crud) {
            let addElement = this.add[crud]
            let toAdd = []

            switch (crud) {
                case 'user':
                    if (addElement.length) {
                        let existing = this.user.map(x => x.user_id)
                        toAdd = addElement.filter(x => !existing.includes(x.id))
                            .map(y => {
                                return {
                                    demographic_id: this.data.id,
                                    user_id: y.id,
                                    can_view: 1,
                                    can_search: 1,
                                    can_edit: 0
                                }
                            })
                    }
                    break;
                case 'group':
                    if (addElement) {
                        toAdd.push({
                            demographic_id: this.data.id,
                            user_group_id: addElement,
                            can_view: 1,
                            can_search: 1,
                            can_edit: 0
                        })
                        this.$nextTick(() => {
                            this.add.group = undefined
                        })
                    }
                    break;
            }
            if (toAdd.length) {
                crud = crud + 'Crud'
                this[crud].create(toAdd).then(() => {
                    this.$emit('update')
                    this.read(crud)
                })
            }
        },

        read(crud) {
            this[crud].read(this.readParams).then(res => {
                if (crud === 'userCrud') {
                    this.user = res.data.demographic_user
                    this.tabs[0].name = 'Users (' + res.data.demographic_user.length + ')'
                } else {
                    this.group = res.data.demographic_user_group
                    this.tabs[1].name = 'User Groups (' + res.data.demographic_user_group.length + ')'
                }
            })
        },

        update(crud, data) {
            crud = crud + 'Crud'
            this[crud].update(data)
        },

        destroy(crud, data) {
            let payload = this.$_.clone(data)
            crud = crud + 'Crud'

            this.$confirmDelete(payload, () => {
                this[crud].destroy(payload).then(() => {
                    this.$emit('update')
                    this.read(crud)
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
