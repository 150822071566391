<template>
    <collapsible-panel title="Agenda" class='meeting-agenda-panel' ref="topicPanel" smallTitle>
        <fe-btn
            usage="tertiary"
            :disabled="!occurrence"
            :style="{opacity: !occurrence ? '0.3' : '1'}"
            :tooltip="!occurrence ? 'Meeting must be scheduled before adding an agenda topic.' : null"
            @click="adding = true"
        >
            Add Topic
        </fe-btn>

        <div class="topics mb-5 pb-5">
            <draggable v-model="topics" @change="updateTopics">
                <topic
                    v-for="topic in topics"
                    :key="`agenda-topic-${topic.id}`"
                    :topic="topic"
                    :meeting="meeting"
                    :occurrence="occurrence"
                    @students="$emit('students', $event)"
                    @deleteTopic="destroyTopic(topic)"
                    @updateTopic="editTopic(topic)"
                    @refresh="$refs.crud.read()"
                />
            </draggable>
        </div>

        <smartform
            v-if="formInstance"
            :id="formInstance.form_instance_id"
            :template_id="formInstance.form_instance.form_id"
            hide-tools
            hide-sidebar
        />

        <fe-dialog
            v-if="editing"
            :title="`${adding ? 'Add' : 'Edit'} Topic`"
            :acceptButtonText="adding ? 'Add' : 'Update'"
            :retainFocus="false"
            :acceptButtonDisabled="disableSave"
            @accept="saveTopic"
            @dismiss="cancelTopic"
        >
            <fe-label>Topic Description</fe-label>
            <fe-editor
                v-model="editingText"
                ref="topicEditor"
                :toolbarExtras="['|', 'bulletedlist', 'numberedlist']"
                @input="updateEditingText"
            />
        </fe-dialog>

        <fe-crud
            v-if="meeting && occurrence"
            ref="crud"
            :config="meetingAgendaCrudConfig"
            autoload
            refresh-on-config
            @read="topics = $event"
            @created="$refs.crud.read()"
            @destroyed="$refs.crud.read()"
            @updated="$refs.crud.read()"
        />

        <fe-crud
            v-if="meeting && occurrence"
            ref="crudForm"
            :config="formInstanceCrudConfig"
            autoload
            refresh-on-config
            @read="formInstances = $event"
        />
    </collapsible-panel>
</template>

<script>
import Smartform from '@/components/modules/smartforms/SmartForm'
import CollapsiblePanel from '@/components/common/CollapsiblePanel'
import Topic from './Topic'
import draggable from 'vuedraggable'

export default {
    name: 'Agenda',

    components: {
        CollapsiblePanel,
        Topic,
        Smartform,
        draggable,
    },

    props: {
        meeting: {type: Object, required: true},
        occurrence: {type: Object}
    },

    data() {
        return {
            adding: false,
            updating: null,
            editingText: null,
            topics: [],
            formInstances: [],
            disableSave: false,
        }
    },

    computed: {
        formInstance() {
            return this.formInstances.length ? this.formInstances[0] : null
        },

        formInstanceCrudConfig() {
            if (this.meeting.id && this.occurrence.id) {
                let cfg = this.$_.cloneDeep(this.$models.meetingFormInstance)
                cfg.read.params.dashboard_meeting_id = this.meeting.id
                cfg.read.params.schedule_date_id = this.occurrence.id
                return cfg
            }
        },

        meetingAgendaCrudConfig() {
            if (this.meeting.id && this.occurrence.id) {
                let cfg = this.$_.cloneDeep(this.$models.meetingAgenda)
                cfg.read.params.dashboard_meeting_id = this.meeting.id
                cfg.read.params.schedule_date_id = this.occurrence.id
                return cfg
            }
        },

        editing() {
            return this.adding || this.updating
        }
    },

    watch: {
        editing(v) {
            if (v) {
                // set focus on load of modal
                setTimeout(function () {
                    this.$refs?.topicEditor.editorInstance.editing.view.focus()
                }.bind(this), 100)
            }
        }
    },

    methods: {
        saveTopic() {
            if (this.adding) this.createTopic()
            else this.updateTopic()
        },

        editTopic(topic) {
            this.editingText = topic.item
            this.updating = topic
        },

        createTopic() {
            this.$refs.crud.create({
                dashboard_meeting_id: this.meeting.id,
                schedule_date_id: this.occurrence.id,
                item: this.editingText
            }).finally(() => {
                this.cancelTopic()
            })
        },

        updateTopic() {
            this.$refs.crud.update(Object.assign({}, this.editing, {
                item: this.editingText
            })).finally(() => {
                this.cancelTopic()
            })
        },

        destroyTopic(topic) {
            this.$refs.crud.destroy(topic)
        },

        cancelTopic() {
            this.adding = false
            this.updating = null
            this.editingText = null
        },

        updateEditingText(v) {
            this.disableSave = v && v.replace(/&nbsp;/g, ' ').replace(/<\/?[^>]+(>|$)/g, "").length > 500
        },

        updateTopics() {
            this.topics.forEach((topic, index) => {
                topic.rank = index
            })
            this.$refs.crud.update(this.topics)
        }
    }
}
</script>

<style lang="scss">
.meeting-agenda-panel {
    button.fe-button.v-btn {
        margin: 8px 0;
    }
}
</style>
