<template>
    <div>
        <div>
            <div class="headline">Incidents</div>
            <p class="my-4 caption" style="color: #C0C3CF; font-size: 14px;">
                Incidents allow users to collect information about student-specific events that can be imported from your SIS or created/entered by users in eduCLIMBER.
                Incident data can be instantly visualized in charts or linked into the thresholds for automated reporting allowing teams to easily monitor
                and analyze trends at the school-wide, small group, and student level.
            </p>
        </div>

        <div>
            <v-form v-model="valid">
                <div class="d-flex">
                    <div class="incident-label">Motivation Display Name</div>
                    <div>
                        <v-text-field
                            flat solo dense
                            v-model="keys.INCIDENT_MOTIVATION_LABEL"
                            @change="savePreferences"
                            style="width: 200px;"
                            clearable
                        />
                    </div>
                </div>

                <div class="d-flex">
                    <div class="incident-label">Types per Incident Limit</div>

                    <div>
                        <v-text-field
                            required
                            flat solo dense
                            v-model="keys.INCIDENT_BEHAVIOR_CNT"
                            @change="savePreferences"
                            type="number"
                            style="width: 100px;"
                            :rules="$fieldValidators('integer', 'Limit', { min: 1, max: 10 })"
                            clearable
                        />
                    </div>
                </div>

                <div class="d-flex">
                    <div class="incident-label">Incident Response Limit</div>
                    <div>
                        <v-text-field
                            required
                            flat solo dense
                            v-model="keys.INCIDENT_RESPONSE_CNT"
                            @change="savePreferences"
                            type="number"
                            style="width: 100px;"
                            :rules="$fieldValidators('integer', 'Limit', { min: 1, max: 10 })"
                            clearable
                        />
                    </div>
                </div>

                <div class="d-flex" v-if="showIncidentBehaviorCounts">
                    <div class="incident-label">
                        Display Counts by Primary Incidents
                        <fe-info-tip tooltip="Disabling this option will count each behavior and response as an individual count 
                            when more than one behavior or response is linked to the same incident." class="ml-2"></fe-info-tip>
                    </div>
                    <div>
                        <fe-switch v-model="keys.INCIDENT_COUNT_BY_PRIMARY" @change="savePreferences"></fe-switch>
                    </div>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
    import DetailChip from '@/components/common/DetailChip'

    export default {
        name: 'IncidentGeneral',
        components: {
            DetailChip
        },
        data() {
            return {
                valid: false,
                preferences: {},
                keys: {
                    INCIDENT_MOTIVATION_LABEL: null,
                    INCIDENT_BEHAVIOR_CNT: null,
                    INCIDENT_RESPONSE_CNT: null,
                    INCIDENT_COUNT_BY_PRIMARY: null,
                }
            }
        },
        computed: {
            showIncidentBehaviorCounts() {
                return this.$store.getters['flags/flags']['incident-behavior-counts'] === true
            } 
        },
        mounted() {
            this.load()
        },
        methods: {
            doTest() {},
            load() {
                this.$axios.get('preferences.php?action=get&property=district')
                    .then(response => {
                        this.preferences = this.$ecResponse(response, 'preferences')

                        this.preferences.forEach(pref => {
                            Object.keys(this.keys).forEach(key => {
                                if (pref.code === key) {
                                    if (pref.code == 'INCIDENT_COUNT_BY_PRIMARY') this.keys[key] = pref.user_value == "1" ? true : false
                                    else this.keys[key] = pref.user_value
                                }
                            })
                        })
                    })
            },
            savePreferences(value) {
                if (!this.valid) return

                let keys = this.$_.cloneDeep(this.keys)

                Object.keys(keys).forEach(key => {
                    if (key == 'INCIDENT_COUNT_BY_PRIMARY') keys[key] = value == true ? "1" : "0"
                    if (keys[key] === "") keys[key] = null
                })

                this.$axios.post('preferences.php?action=update', keys)
                    .then(response => {
                        this.$ecResponse(response)
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
.incident-label {
    width: 275px !important;
    color: #050F2D;
    font-size: 14px;
    padding-top: 7px;
    display: flex;
}
</style>
