export const navigation = {
    namespaced: true,
    state: {
        activeModule: null,
        manage: [],
        launchpad: []
    },
    mutations: {},
    actions: {}
}

