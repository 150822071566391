<template>
    <div class="metric-list-item text-muted">
        <v-layout pa-0 ma-0 row>
            <v-flex xs10 pl-3>
                <div class="metric-list-tile">{{ metric.abbrev }}</div>
                {{ metric.name }}
                ({{ displayType }})
            </v-flex>

            <v-flex xs2 pr-1 class="text-xs-right">
                <v-text-field
                    v-if="!conditional"
                    type="number"
                    id="weight-input"
                    class="weight-input"
                    :rules="rules"
                    :value="metric.weight"
                    flat solo
                    single-line
                    hide-details
                    @input="(v) => $emit('update', { weight: v })"
                />
            </v-flex>
        </v-layout>

        <div class="metric-list-item-menu d-flex">
            <v-menu z-index="50" bottom left>
                <template v-slot:activator="{ on }">
                    <v-btn icon x-small v-on="on" style="margin-top: 12px;">
                        <v-icon>fal fa-ellipsis-v</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item @click="$emit('edit')">
                        <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="$emit('delete')">
                        <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MetricRow',

    props: {
        metric: Object,
        idx: Number,
        conditional: Boolean
    },

    data() {
        return {
            rules: [
                v => (!!v && !isNaN(v)) || 'Weight is required',
                v => (v > 0) || 'Weight must be more than 0',
                v => (v < 1000) || 'Weight must be less than 1000'
            ]
        }
    },

    computed: {
        type() {
            return this.metric.__type
        },

        displayType() {
            if (this.type === 'assessment') {
                return 'academic'
            } else if (this.type === 'behavior') {
                return 'incident'
            } else {
                return this.type
            }
        }
    }
}
</script>
