<template>
    <div class="d-flex flex-fill flex-column" style="position: relative;">
        <v-slide-y-transition>
            <div v-if="selectedStudents.length > 0" class="selection-toolbar d-flex">
                <div class="mt-3 ml-5">
                    {{ selectedStudents.length }} Record{{ selectedStudents.length > 1 ? 's' : '' }} Selected
                </div>

                <div class="ml-auto">
                    <v-btn text class="white--text" @click="tagStudents">
                        Tag Students
                    </v-btn>
                    <v-btn text class="white--text" @click="$refs.grid.gridApi.deselectAll()">
                        Cancel
                    </v-btn>
                </div>
            </div>
        </v-slide-y-transition>

        <fe-grid
            v-if="gridReady"
            ref="grid"
            domLayout="normal"
            style="width: 100%; height: 100%;"
            :showAddRowBtn="false"
            :columnDefs="columns"
            :defaultColDefOverrides="defaultColDef"
            :rowData="data"
            :pinnedBottomRowData="footerRows"
            displayDensity="small"
            @cellMouseOver="showStudentCard"
            @cellMouseOut="hideStudentCard"
            @cellClicked="doClick"
            @rowSelected="doSelect"
            @gridReady="onGridReady"
        />

        <fe-student-card
            ref="studentCard"
            v-show="studentCard.show"
            fullNameField="student_full_name"
            schoolField="school_name"
            gradeField="grade_desc"
            genderField="gender"
            :style="{
                position: 'absolute',
                left: `${studentCard.left}px`,
                top: `${studentCard.top}px`,
                padding: '1px 6px 1px 6px',
                zIndex: '10'
            }"
            :imageUrl="studentCard.imageUrl"
            :studentRecord="studentCard.studentRecord"
            @close="() => {}"
        />
    </div>
</template>

<script>
export default {
    name: 'AttendanceDatawall',

    props: {
        params: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            columns: [],
            data: [],
            gridReady: false,
            lastStudentOver: null,
            selectedStudents: [],
            defaultColDef: {
                flex: 1,
                sortable: true,
                filter: true,
                // removing otherwise adds row that has filter per column; already have search field at top
                // floatingFilter: true,
                suppressMenu: false
            },
            studentCard: {
                show: false,
                studentRecord: {}
            }
        }
    },

    computed: {
        footerRows() {
            let avgs = {}
            this.columns.filter(col => col.filter == 'agNumberColumnFilter').forEach(col => {
                let vals = this.data.map(itm => itm[col.field] || 0)
                let avg = vals.reduce((p, c) => parseFloat(p) + parseFloat(c), 0) / vals.length
                avgs[col.field] = isNaN(avg) ? avg : avg.toFixed(2)
            })
            return [avgs]
        }
    },

    mounted() {
        this.loadData()
    },

    created() {
        window.addEventListener('beforeunload', this.editHeaders)
    },

    methods: {
        loadData() {
            let me = this
            me.gridReady = false

            this.selectedStudents = []
            this.$setLoading(true)
            this.$modelGet('attendanceDatawall', this.$props.params)
                .then(response => {
                    me.columns = []
                    let cols = [{
                        ...this.$grid.checkColumn(null, true),
                        width: 70,
                        headerCheckboxSelectionFilteredOnly: true,
                    }]

                    let targets = {
                        EQUIV: [],
                        AFFECTED: []
                    }
                    this.$modelGet('attendanceTargets').then(r => {
                        r.forEach(x => {
                            let target = {
                                start: parseFloat(x.start_value),
                                end: parseFloat(x.end_value),
                                color: x.attendance_target_descriptor_color
                            }
                            if (x.attendance_target_type_name === 'EQUIV') {
                                targets.EQUIV.push(target)
                            } else {
                                targets.AFFECTED.push(target)
                            }
                        })

                        response.columns.forEach(col => {
                            if (!col.columns) {
                                cols.push(this.$grid.attendanceColumn(col, this, targets))
                            } else {
                                col.columns.forEach(child => {
                                    cols.push({
                                        headerName: child.text,
                                        field: child.dataIndex,
                                        minWidth: 80,
                                        width: col.width ? col.width * 1.5 : 90,
                                        filter: 'agTextColumnFilter',
                                        headerComponentParams: {
                                            displayName: child.text
                                        },
                                        cellStyle: {
                                            color: '#9297A6',
                                            fontSize: '12px'
                                        }
                                    })
                                })
                            }
                        })
                        me.columns = cols
                        me.data = response.data
                        me.gridReady = true
                    })
                })
                .finally(() => {
                    this.$setLoading(false)
                })
        },

        tagStudents() {
            let me = this
            this.$store.commit('global/tagStudents', {
                show: true,
                students: this.selectedStudents,
                callback() {
                    me.loadData()
                }
            })
        },

        doSelect() {
            this.selectedStudents = this.$refs.grid.gridApi.getSelectedRows()
        },

        showStudentCard(meta) {
            if (meta.colDef.field === "student_full_name" && meta.data.student_id) {
                if (this.lastStudentOver == meta.data.student_id && this.data.length > 1) return
                if (this.lastStudentOver == meta.data.student_id && this.data.length == 1) {
                    return this.studentCard = {
                        ...this.studentCard,
                        show: true
                    }
                }
                let studentData = null

                this.$axios.get(
                    'students.php?action=get&property=grade_history&id=' + meta.data.student_id + '&school_year_id=' + meta.data.school_year_id
                ).then(response => {
                    if (response && response.data) {
                        studentData = response.data[0]
                    }
                }).finally(() => {
                    this.studentCard = {
                        ...this.studentCard,
                        show: true,
                        imageUrl: this.$axios.defaults.baseURL + 'students.php?action=get&property=student_image&id=' + meta.data.student_id,
                        studentRecord: studentData,
                        top: meta.event.clientY,
                        left: meta.event.clientX
                    }

                    this.lastStudentOver = meta.data.student_id
                }).catch(error => {
                    console.error(error)
                })
            }
        },

        hideStudentCard() {
            this.studentCard = {
                ...this.studentCard,
                show: false,
                studentRecord: this.data.length == 1 ? this.studentCard.studentRecord : {}
            }
        },

        doClick(meta) {
            if (meta.colDef.field === 'days_equivalent_pct' || meta.colDef.field === 'affected_days_pct') {
                let params = {
                    student_id: meta.data.student_id,
                    school_year_id: meta.data.school_year_id
                }

                this.$store.commit('global/addDockableWindow', {
                    name: meta.data.student_full_name + ' Attendance',
                    component: 'student-attendance',
                    attrs: {
                        params: params,
                        pinnable: false
                    }
                })
            } else if (meta.colDef.field === 'student_full_name') {
                this.$router.replace('/StudentProfile/' + meta.data.student_id + '/' + meta.data.school_year_id)
                this.$store.commit('global/showStudentCard', null)
            }
        },
        onGridReady(grid) {
            let me = this
            this.columnApi = grid.columnApi
            let previousHeaders = window.localStorage.getItem('ec-datawall-attendance-headers') || '[]'
            previousHeaders = JSON.parse(previousHeaders)
            if(previousHeaders.length) {
                previousHeaders.forEach(head => {
                    this.columns.find(x => x.field == head.field).hide = head.hide
                })
                grid.api.setColumnDefs(this.columns)
                grid.api.sizeColumnsToFit()
            }
            // so that going from datawall -> charts -> datawall it remains the same
            grid.api.addGlobalListener(function(type, event) {
                if(type.indexOf("column") >= 0) {
                    if(event.type == "columnVisible") {
                        me.columns.forEach(head => {
                            head.hide = !me.columnApi.getAllColumns().find(col => col.colDef.field == head.field).visible
                        })
                        window.localStorage.setItem('ec-datawall-attendance-headers', JSON.stringify(me.columns))
                    }
                }
            })
        },
        editHeaders() {
            this.columns.forEach(head => {
                head.hide = !this.columnApi.getAllColumns().find(col => col.colDef.field == head.field).visible
            })
            window.localStorage.setItem('ec-datawall-attendance-headers', JSON.stringify(this.columns))
        }
    },
}
</script>

<style lang="scss" scoped>
.selection-toolbar {
    width: 100%;
    position: absolute;
    height: 48px;
    background: #66A7BC;
    color: white;
    z-index: 10;
    border-radius: 4px;
}
</style>
