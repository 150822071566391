<template>
    <div>
        <v-card class="goal-card" flat>
            <v-card-title class="goal-title" v-if="goal.custom_statement || goal.default_statement">
                <div class="d-flex goal-title-div" @mouseover="showVertGoal= true" @mouseleave="showVertGoal = false">
                    <fe-tooltip
                        :tooltip="getTooltip"
                        :disabled="!showIcon || goalDragging || showOptionsMenu"
                        nudge-right="50"
                    >
                        <div
                            class="d-flex"
                            @click="onOptionClick($event)"
                            style="cursor: pointer"
                            :style="{ visibility: showIcon ? 'visible' : 'hidden' }"
                        >
                            <v-icon class="mr-1" color="#050F2D" style="font-size: 24px !important">fal fa-ellipsis-v
                            </v-icon>
                            <v-icon color="#050F2D" style="font-size: 24px !important">fal fa-ellipsis-v</v-icon>
                        </div>
                    </fe-tooltip>
                    <div class="flex-column ml-2" style="width: 100%">
                        <div class="d-flex goal-title-main">GOAL</div>
                        <div class="d-flex justify-space-between align-center" style="height: 20px">
                            {{ goal.custom_statement || goal.default_statement }}
                            <fe-icon-btn
                                v-if="goal.tasks.length"
                                :useIcon="showAI(goal.id) ? 'far fa-chevron-up' : 'far fa-chevron-down'"
                                usage="ghost"
                                @click="setGoal(goal.id)"
                                color="#000"
                                size="14"
                            />
                        </div>
                    </div>
                </div>
            </v-card-title>
            <v-card-text class="goal-content" v-if="goal.tasks.length && showAI(goal.id)">
                <action-items
                    :items="goal.tasks"
                    :goals="goals"
                    :readParams="readParams"
                    @edit="$emit('edit-goal')"
                    @save="handleCloseActionItem"
                    @dismiss="handleCloseActionItem"
                    actionPlan
                />
            </v-card-text>
        </v-card>
        <v-menu
            v-model="showOptionsMenu"
            :position-x="optionsMenuPosition.x"
            :position-y="optionsMenuPosition.y"
            absolute
            content-class="options-menu"
        >
            <v-list>
                <v-list-item @click="editGoal(goal)">Edit</v-list-item>
                <v-list-item @click="editGoal(goal, true)">Unlink</v-list-item>
                <v-list-item @click="editGoal(goal, false, true)">Delete</v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import ActionItems from '../actionItems/ActionItems'
import {mapState} from "vuex"

export default {
    name: 'Goals',

    components: {ActionItems},

    props: {
        goal: {type: Object, default: () => {}},
        goals: {type: Array, default: () => []},
    },

    data() {
        return {
            openGoal: true,
            showVertGoal: false,
            showOptionsMenu: false,
            optionsMenuPosition: {
                x: 0, y: 0
            },
        }
    },

    computed: {
        ...mapState('actionPlan', ['goalDragging', 'goalStates', 'activeDashboard']),
        showIcon() {
            return this.showVertGoal
        },
        getTooltip() {
            return "<div class='text-center'><b>Drag</b> to move <br/> <b>Click</b> to open menu</div>"
        },
        readParams() {
            return {
                kpi_dashboard_id: this.activeDashboard.id
            }
        }
    },

    methods: {
        onOptionClick(e) {
            let target = e.target
            if (target.localName === 'td') target = target.firstChild
            let element = target.getBoundingClientRect()
            this.optionsMenuPosition.x = element.left
            this.optionsMenuPosition.y = element.top + 30
            this.showOptionsMenu = true
        },

        editGoal(goal, unlink = false, destroy = false) {
            if (destroy) {
                this.$emit('delete-goal', goal)
            } else {
                this.$store.commit('actionPlan/set', {
                    key: 'goal',
                    value: goal
                })
                if (unlink) this.$emit('unlink-goal')
                else this.$emit('edit-goal')
            }
        },

        handleCloseActionItem() {
            this.$emit('dismiss-task')
        },

        setGoal(id) {
            this.openGoal = this.goalStates.find(x => x.key == id) ? !this.goalStates.find(x => x.key == id).value : !this.openGoal
            this.$store.commit('actionPlan/setGoalStates', {
                key: id,
                value: this.openGoal
            })
        },

        showAI(id) {
            if (this.goalDragging) return false
            return this.goalStates.find(x => x.key == id) ? this.goalStates.find(x => x.key == id).value : this.openGoal
        },
    }
}
</script>

<style lang="scss" scoped>
.goal-card {
    margin-left: 20px;
    border: 1px solid #DDE1E5;
    border-radius: 0;
}

.goal-title {
    color: #000000;
    font-size: 14px;
    padding: 10px;
    line-height: 1.5 !important;
    display: block !important;
    height: 55px;

    &-main {
        font-size: 10px;
    }

    &-div {
        width: 100%;
        height: 35px;
        align-items: center;
    }
}

.goal-content {
    background-color: #F5F6F8;
    padding: 10px;
}

.options-menu {
    .v-list-item {
        min-height: 38px !important;
    }
}
</style>
