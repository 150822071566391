<template>
    <div class="numeric-wrapper" :class="{'thumbnail': thumbnail}">
        <div class="d-flex flex-wrap flex-shrink-1" :class="{'justify-center': thumbnail}">
            <div v-for="item in data" class="numeric-card flex-shrink-1" @click="item.clickHandler">
                <div class="flex-fill flex-column">
                    <div class="count-wrapper flex-grow-1">
                        <div class="color-name d-flex align-left">
                            <div class="swatch-wrapper mr-2">
                                <v-icon
                                    class="fas fa-square-full color-swatch"
                                    :color="item.color"
                                    small
                                />
                            </div>

                            <div class="name-wrapper">
                                {{ item.name }}
                            </div>
                        </div>

                        <div v-if="item.percentage === undefined">
                            <div class="count">
                                {{ item.count }}
                            </div>

                            <div v-if="countOf" class="count-of">
                                {{ countOf + (item.count !== 1 ? 's' : '') }}
                            </div>
                        </div>

                        <div v-else>
                            <div class="count">
                                {{ item.percentage + '%' }}
                            </div>

                            <div class="count-of">
                                {{ item.count + ' of ' + item.total + ' students' }}
                            </div>
                        </div>


                        <div v-if="item.full_rate">
                            <div class="count">
                                {{ item.full_rate }}
                            </div>

                            <div class="count-of">
                                Full Day Rate
                            </div>
                        </div>

                        <div v-if="item.half_full_rate">
                            <div class="count">
                                {{ item.half_full_rate }}
                            </div>

                            <div class="count-of">
                                {{ item.half_full_label || 'Half / Full Day Rate' }}
                            </div>
                        </div>
                    </div>

                    <div v-if="item.pct" class="change mt-1 d-flex align-center flex-shrink-1">
                        <v-icon :color="item.pct >= 0 ? 'green' : 'red'" class="mr-1 fe-split-btn-icon">
                            {{ item.pct >= 0 ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                        </v-icon>

                        <div class="change-value">{{ item.pct }}%</div>

                        <v-spacer/>

                        <div class="change-comp">{{ item.comparisonText }}</div>
                    </div>

                    <div
                        v-else-if="item.comparison === 'custom'"
                        class="change mt-1 d-flex align-center flex-shrink-1"
                        v-for="comp in item.comparisonText"
                    >
                        <v-icon :color="comp.pct >= 0 ? 'green' : 'red'" class="mr-1 fe-split-btn-icon">
                            {{ comp.pct >= 0 ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                        </v-icon>

                        <div class="change-value">{{ comp.pct }}%</div>

                        <v-spacer/>

                        <div class="change-comp">{{ comp.text }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NumericView",

    props: {
        data: {
            type: Array,
            required: true
        },
        config: {
            type: Object,
            required: true
        },
        thumbnail: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        countOf() {
            let value
            if (this.config.value && this.config.report_as !== 'targets') {
                /** For: Assessment, Demographic, Tag **/
                value = this.config.value
            } else if (this.config.report_as) {
                /** For: Attendance, Incident Type, Incident Response **/
                value = this.config.report_as
            }

            switch (value) {
                case 'percentage':
                case 'rate':
                    return false
                case 'incident_average':
                case 'incident_total':
                case 'incident_count':
                    return 'Incident'
                default:
                    return 'Student'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.numeric-wrapper {
    overflow-y: auto;
    width: 100%;

    &.thumbnail {
        overflow: hidden;
    }

    .numeric-card {
        width: 175px;
        padding: 11px;
        margin: 8px;
        text-align: center;
        background-color: #ECEDF17F;
        cursor: pointer;

        .swatch-wrapper {
            .color-swatch {
                margin-top: -2px;
            }
        }

        .name-wrapper {
            text-align: left;
        }

        .count {
            font-size: 24px;
        }

        .count-of {
            font-size: 12px;
        }

        .change {
            font-size: 12px;
            color: #7E8494;
        }
    }
}
</style>
