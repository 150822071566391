import groupings from './Groupings'
import filters from './Filters'
import notify from './Notify'
import notifyGroups from './NotifyGroups'
import email from './Email'
import tags from './Tags'
import smartform from './Smartform'

export default {
    groupings, filters, notify, notifyGroups, email, tags, smartform
}
