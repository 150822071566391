var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pl-10 pr-10 d-flex flex-column flex-fill"},[(_vm.isLoading)?_c('fe-spinner',{attrs:{"text":"Loading..."}}):_c('fe-grid',{ref:"mergeGrid",staticClass:"user-merge-grid",staticStyle:{"height":"100%","margin-bottom":"10px"},attrs:{"displayDensity":"small","columnDefs":_vm.mergeColumnDefs,"rowData":_vm.mergeData,"showToolbar":false,"disableFilterUpdate":""},on:{"modelUpdated":_vm.bringFocusBack}}),(!_vm.isLoading)?_c('div',{staticClass:"py-2 d-inline-flex"},[_c('fe-label',{staticClass:"mr-4"},[_vm._v("Include Users Marked \"Do Not Merge\"")]),_c('fe-switch',{attrs:{"hide-details":""},model:{value:(_vm.includeDoNotMerge),callback:function ($$v) {_vm.includeDoNotMerge=$$v},expression:"includeDoNotMerge"}}),_c('v-spacer'),_c('fe-btn',{attrs:{"usage":"ghost"},on:{"click":_vm.closeMergeWindow}},[_vm._v(" "+_vm._s('Cancel')+" ")]),_c('fe-btn',{attrs:{"usage":'primary',"disabled":!_vm.recsToMerge.length || _vm.isBusyMerging},on:{"click":_vm.submitMergeUsers}},[_vm._v(" "+_vm._s('Merge')+" ")])],1):_vm._e(),(_vm.showUserCard)?_c('user-card',{attrs:{"value":_vm.userRec,"fields":[
            {name:'Id', field:'id'},
            {name:'Title', field:'title'},
            {name:'Email', field:'email_addr'},
            {name:'Login', field:'login'},
            {name:'Active', field:'active', type:'boolean'},
            {name:'Schools', field:'school_cnt'},
            {name:'Grades', field:'grade_cnt'},
            {name:'IDs', field:'indentification_cnt'},

        ]},on:{"close":function($event){_vm.showUserCard=false}}}):_vm._e(),_c('fe-crud',{ref:"crud",attrs:{"autoload":"","config":_vm.$models.user},on:{"read":function($event){_vm.rowData = $event}}}),_c('fe-crud',{ref:"doNotMergeCrud",attrs:{"autoload":false,"config":_vm.$models.userDoNotMerge,"disableSnackbars":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }