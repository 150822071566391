<template>
    <v-layout column style="overflow:hidden;" id="viewport">
        <demographic-search :value="params.search" @input="params.search = $event" />
        <v-layout row fill-height style="overflow:hidden;">
            <left-side-panel :show="showLeftSidePanel"/>
            <div class="pixiwrapper">
                <main-panel />
            </div>
        </v-layout>
    </v-layout>
</template>

<script>
import * as Util from './Util'
import Group from './Group'
import Vuex from 'vuex'
import { mapLocalState } from '@/util/vuexHelper'
import DemographicSearch from './components/DemographicSearch'
import LeftSidePanel from './components/LeftSidePanel'
import MainPanel from './components/MainPanel'

export default {
    name: 'Students3D',

    components: {
        DemographicSearch,
        LeftSidePanel,
        MainPanel,
    },

    computed: {
        ...mapLocalState(['params', 'charts', 'showLeftSidePanel', 'activeGrouping','activeLayout','atlas','ss3','vueLoaderId']),
    },

    provide() {
        this.localStore = new Vuex.Store({
            state: {
                showLeftSidePanel: false,
                ss3: {
                    spriteSize: 64, // default size... changes depending on student count
                    studentSpritesOn: 3000, // under this we will ask for and render student image sprites
                    tiledSpritesOn: 8000, // above this we will use tiled sprites
                },
                highlightedFilter: null,
                params: {
                    search: {},
                    filters: [],
                },
                charts: {},
                selectedStudents: [],
                activeStudent: {
                    rec: null,
                    x: 0, y: 0,
                    show: false,
                    modal: false,
                },
                activeGrouping: {
                    type: null,
                    groups: [],
                },
                activeLayout: null,
                groupers: [
                    {name:'School', type: 'property', args: {property: 'school_id', textProperty: 'school_name'}, id:Util.UUIDv4() },
                    {name:'Grade', type: 'property', args: {property: 'grade_id', textProperty: 'grade_desc'}, id:Util.UUIDv4() },
                    {name:'Gender', type: 'property', args: {property: 'gender', textProperty: 'gender'}, id:Util.UUIDv4() },
                    {name:'Ethnicity', type: 'property', args: {property: 'ethnicity', textProperty: 'ethnicity'}, id:Util.UUIDv4() },
                    {
                        name:'Meal Status',
                        type: 'property',
                        security: ['SEARCH_MEAL_STATUS','DRILLDOWN_MEAL_STATUS'],
                        args: {property: 'meal_status_name', textProperty: 'meal_status_name'},
                        id:Util.UUIDv4()
                    },{
                        name:'English Learner',
                        el: true,
                        type: 'multiple',
                        subGroupers: [
                            {name:'EL Status', type: 'boolean', args: {property: 'ell_flag', textProperty: 'ell_flag'}, id:Util.UUIDv4() },
                            {name:'ELP Level', type: 'property', args: {property: 'ell_level_name', textProperty: 'ell_level_name'}, id:Util.UUIDv4() },
                        ],
                        id:Util.UUIDv4()
                    },
                    {
                        name:'Disability',
                        type: 'propertyArray',
                        args: {path: 'disabilities', property: 'description', emptyName: 'No Disability'},
                        infoText: "The percent calculated is the number of students with a disability in the category out of the total students filtered and may sum to greater than 100%.",
                        id:Util.UUIDv4()
                    },
                ],
                atlas: null,
                zoomed: true,
                vueLoaderId: null,
            },
            mutations: {},
            actions: {}
        })

        this.spriteSheets = {}
        this.oldSpriteSheets = {}   // these are sheets that need to be GC'd

        return {
            localStore: this.localStore,
            spriteSheets: this.spriteSheets,
            oldSpriteSheets: this.oldSpriteSheets,
        }
    },

    mounted() {
        this.localStore.watch( state => state.params.search, this.doSearch)
        this.localStore.watch( state => state.params.filters, this.doSearch)
        this.localStore.watch( state => state.charts, this.refresh)
        this.vueLoaderId = this.$route.query.tstamp
    },

    methods: {
        doSearch() {
            this.activeLayout = null
            this.$setLoading(true)
            this.$axios.post('student3D.php?action=get', JSON.stringify(
                this.params
            )).then(response => {
                this.$setLoading(false)

                if(this.$_.isEmpty(response.data.charts)) {
                    this.$errorPrompt({
                        title: 'Error',
                        message: response.data.msg || 'Unable to process server response.  Please contact support'
                    })
                    return
                }
                this.charts = Object.freeze(response.data.charts)

                if(this.activeGrouping.grouper) {
                    this.activeGrouping = Group.setGrouper(this.charts,this.activeGrouping.grouper)
                } else {
                    if(this.charts) {
                        this.activeGrouping = Group.none(this.charts)
                    }
                }

                if(this?.charts?.records.length) {
                    this.ss3.spriteSize = 256
                    if(this.charts.records.length > 16) this.ss3.spriteSize = 128
                    if(this.charts.records.length > 512) this.ss3.spriteSize = 64
                    if(this.charts.records.length > 1600) this.ss3.spriteSize = 32

                    if(this.charts.records.length < this.ss3.studentSpritesOn) {
                        this.getSpriteSheets(this.charts.records)
                    }
                }
            })
        },
        refresh() {
            this.showLeftSidePanel = !!this.charts?.records.length
        },
        getSpriteSheets(studentRecs) {
            const ids = studentRecs.map(r => r.student_id)
            this.$axios.post(`ss3.php?action=generate&sprite_size=${this.ss3.spriteSize}&debug=${parseInt(this.debug)}`,
                {id: ids}
            ).then(response => {
                try {
                    if(response.data.index && response.data.index.length) {
                        let h = []
                        let atlas = []
                        const frameHeight = this.ss3.spriteSize
                        const frameWidth = this.ss3.spriteSize
                        response.data.index.forEach((v, i) => {
                            if (v.filename === 'defaultimg.png') return;

                            let h2 = []
                            v.data.forEach(d => {
                                h[d.id] = { filename: v.filename, x: d.x, y: d.y, default_pic: response.data.top_secret_mode }
                                if(!response.data.top_secret_mode) {
                                    h2[`${d.id}@${v.filename}`] = {
                                        frame: { x: d.x, y: d.y, w: frameWidth, h: frameHeight },
                                        sourceSize: { w: frameWidth, h: frameHeight }
                                    }
                                }
                            })

                            atlas[i] = {
                                meta: {
                                    image: `${ this.$axios.defaults.baseURL }ss3.php?action=get_image&file_name=${ v.filename }`,
                                    simpleName: v.filename
                                },
                                frames: h2
                            }
                        })

                        studentRecs.forEach(v => v.spriteSheet = h[v.student_id] )

                        this.atlas = Object.freeze(atlas)
                    } else {
                        throw 'No sprite sheet definitions returned.'
                    }
                } catch(error) {
                    console.warn('Warning: getSpriteSheets...',error);
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.pixiwrapper {
    width: 100%;
    height: 100%;
}
</style>
