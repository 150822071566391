<template>
    <v-layout column>
        <component
            v-for="(item, i) in validItems"
            :key="'district-item-' + i"
            v-if="item.persist || activePage.show === item.show"
            v-show="activePage.show === item.show"
            :is="item.component"
            @go="go"
        />
    </v-layout>
</template>

<script>
    import {mapState} from "vuex"
    import Breadcrumb from "../../../common/Breadcrumb"
    import LandingPage from "./LandingPage"
    import DistrictInfo from "./DistrictInfo"
    import SchoolGroups from "./SchoolGroups"
    import Schools from "./Schools"
    import SchoolDaysOff from "./SchoolDaysOff"
    import Courses from "./Courses"
    import Grades from "./Grades"
    import SchoolYears from './SchoolYears'
    import Languages from './Languages'

    export default {
        name: "ManageDistrict",

        components: {
            Breadcrumb,
            LandingPage,
            DistrictInfo,
            SchoolGroups,
            Schools,
            SchoolDaysOff,
            Courses,
            Grades,
            SchoolYears,
            Languages
        },

        props: ['app'],

        data() {
            return {
                items: [{
                    name: 'District & Schools',
                    show: 'index',
                    persist: false,
                    component: 'landing-page'
                }, {
                    name: 'District Information',
                    show: 'info',
                    persist: true,
                    securityCode: 'MANAGE_SCHOOLS',
                    component: 'district-info'
                }, {
                    name: 'School Groups',
                    show: 'schoolGroups',
                    persist: true,
                    securityCode: 'MANAGE_SCHOOLS',
                    component: 'school-groups'
                }, {
                    name: 'Manage Schools',
                    show: 'schools',
                    persist: true,
                    securityCode: 'MANAGE_SCHOOLS',
                    component: 'schools'
                }, {
                    name: 'School Days Off',
                    show: 'schoolDaysOff',
                    persist: true,
                    securityCode: 'MANAGE_DAYS_OFF',
                    component: 'school-days-off'
                }, {
                    name: 'Courses',
                    show: 'courses',
                    persist: true,
                    securityCode: 'MANAGE_COURSES',
                    component: 'courses'
                }, {
                    name: 'School Years',
                    show: 'school-years',
                    persist: true,
                    securityCode: 'MANAGE_DAYS_OFF',
                    component: 'school-years'
                }, {
                    name: 'Manage Grades',
                    show: 'grades',
                    persist: true,
                    securityCode: 'MANAGE_SCHOOLS',
                    component: 'grades'
                }, {
                    name: 'Manage Home Languages',
                    show: 'languages',
                    persist: true,
                    securityCode: 'MANAGE_SCHOOLS',
                    component: 'languages',
                    featureFlag: 'e-c-form-translations'
                }]
            }
        },

        computed: {
            ...mapState('manage', ['breadcrumbs']),
            ...mapState('global', ['sessionUser']),

            activePage() {
                let b = this.breadcrumbs["District & Schools"]

                let lastPage = b[b.length - 1]

                let event = 'show ' + lastPage.show
                this.$emit(event)

                return lastPage
            },

            validItems() {
                let codes = this.sessionUser.security_codes
                let featureFlags = this.$store.getters['flags/flags']

                return this.items.filter(rec => {
                    let allowCode = true
                    let allowFlag = true
                    if (rec.securityCode) {
                        let allowed = (Array.isArray(rec.securityCode))
                            ? rec.securityCode
                            : [rec.securityCode]
                        allowCode = allowed.find(code => codes.indexOf(code) > -1)
                    }
                    if (rec.featureFlag) {
                        let found = Object.keys(featureFlags).find(flag => flag == rec.featureFlag)
                        if(found) {
                            allowFlag = featureFlags[found]
                        } else {
                            allowFlag = false
                        }
                    }
                    return allowCode && allowFlag
                })
            }
        },

        mounted() {
            if (this.app) {
                let page = this.items.filter(x => x.show === this.app)[0]
                if (page) {
                    this.go(page)
                }
            }
        },

        methods: {
            go(page) {
                this.$store.commit('manage/addCrumb', {
                    key: "District & Schools",
                    val: page
                })
                let event = 'show ' + page.show
                this.$emit(event)
            }
        }
    }
</script>

<style scoped>

</style>
