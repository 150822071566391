<template>
    <div>
        <div v-if="collectionDataFiltered && collectionDataFiltered.length > 0 || filterCollectionType.length > 0">
            <v-flex row class="ec-collection-title-flex">
                <span class="pt-4 pb-4 ec-collection-title">{{ title }}</span>
                <v-spacer/>
                <div v-if="!subcollection" class="d-flex">
                    <fe-tooltip tooltip="Show SubCollections" class="d-inline-block">
                        <fe-icon-btn :color="$vuetify.theme.themes.light.primary.base" class="ec-collection-icon" useIcon="far fa-network-wired"
                            @click="addSubCollections = !addSubCollections" :class="addSubCollections ? 'ec-collection-icon-active' : ''"></fe-icon-btn>
                    </fe-tooltip>
                    <fe-tooltip tooltip="Filter" class="d-inline-block">
                        <v-menu
                            transition="slide-y-transition"
                            v-model="showFilterMenu"
                            :close-on-content-click="false"
                            bottom offset-y>
                            <template v-slot:activator="{ on }">
                                <fe-icon-btn v-on="on" :color="$vuetify.theme.themes.light.primary.base" class="ec-collection-icon material-icons" useIcon="filter_list"
                                    :class="showFilterMenu || filterCollectionType.length > 0 ? 'ec-collection-icon-active' : ''"></fe-icon-btn>
                            </template>
                            <v-list class="ec-collection-filter-list">
                                <v-list-item v-for="filter in filterCollectionTypes">
                                    <v-checkbox :input-value="filterCollectionType.find(x => x === filter.type)"
                                        @change="filterByType(filter.type, $event)" :label="filter.type" hide-details></v-checkbox>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </fe-tooltip>
                    <fe-tooltip tooltip="Sort" class="d-inline-block">
                        <fe-icon-btn v-if="sortClick < 2" @click="sortCollections(); sortCollection = !sortCollection" :color="$vuetify.theme.themes.light.primary.base"
                            class="ec-collection-icon" useIcon="fas fa-arrow-down" :class="sortClick > 0 && sortClick < 3 ? 'ec-collection-icon-active' : ''"></fe-icon-btn>
                        <fe-icon-btn v-else @click="sortCollections('up'); sortCollection = !sortCollection" :color="$vuetify.theme.themes.light.primary.base"
                            class="ec-collection-icon" useIcon="fas fa-arrow-up" :class="sortClick > 0 && sortClick < 3 ? 'ec-collection-icon-active' : ''"></fe-icon-btn>
                    </fe-tooltip>
                </div>
            </v-flex>
            <collection-view :collectionGroups="collectionGroups" :activeTab="activeTab" :collectionData="collectionDataFiltered" :collectionTypes="collectionTypes" />
        </div>
        <div v-else class="ec-collection-empty-state">
            <div v-if="activeTab === 0 && title !== 'Shared with Me' || activeTab !== 0" class="empty-state">
                <fe-empty-state
                    image="/images/rocket_transparent.png"
                    text="Collections store pinned charts, data walls, smartFORMS, meetings and subcollections. <br>
                    To create a collection, click New or click the pin icon on any chart."
                />
            </div>
        </div>
    </div>
</template>

<script>
    import CollectionView from '@/components/modules/collections/landing/Landing'
    import { mapGetters } from 'vuex'
    export default {
        name: "CollectionTabs",
        components: {
            CollectionView
        },
        props: {
            collectionData: { type: Array },
            collectionTypes: { type: Array },
            activeTab: { type: Number, default: 0 },
            title: { type: String, default: null },
            filterText: { type: String, default: null },
            collectionGroups: { type: Array },
            subcollection: { type: Boolean, default: false },
            reload: { type: Boolean, default: false }
        },
        data() {
            return {
                showFilterMenu: false,
                filterCollectionTypes: [
                    { type: 'Charts', checked: false }, { type: 'Data Walls', checked: false },
                    { type: 'smartFORMS', checked: false }, { type: 'Meetings', checked: false },
                    { type: 'SubCollections', checked: false }
                ],
                filterCollectionType: [],
                sortCollection: false,
                collectionDataFiltered: [],
                sortClick: 0,
                subCollections: [],
                addSubCollections: false,
                allCollections: []
            }
        },
        methods: {
            filterByType(type, select) {
                if(select) {
                    if(this.filterCollectionType.length === 0) this.filterCollectionType = [type]
                    else this.filterCollectionType.push(type)
                    this.loopThroughFilters()
                }
                else {
                    let index = -1
                    if (this.filterCollectionType.length > 0) {
                        index = this.filterCollectionType.indexOf(type)
                    }
                    this.filterCollectionType.splice(index, 1)
                    if(!this.addSubCollections && !this.subcollection) {
                        this.collectionDataFiltered = this.collectionData.filter( coll => {
                            return !coll.hidden
                        })
                    } else {
                        this.collectionDataFiltered = this.collectionData
                    }
                    this.loopThroughFilters()
                }
            },
            sortCollections() {
                this.sortClick++
                switch(this.sortClick) {
                    case 1:
                        this.collectionDataFiltered.sort((a,b) => (a.title.toLowerCase() > b.title.toLowerCase()) 
                            ? 1 : ((b.title.toLowerCase() > a.title.toLowerCase()) ? -1 : 0))
                        break
                    case 2:
                        this.collectionDataFiltered.sort((a,b) => (a.title.toLowerCase() < b.title.toLowerCase()) 
                            ? 1 : ((b.title.toLowerCase() < a.title.toLowerCase()) ? -1 : 0))
                        break
                    case 3:
                        this.collectionDataFiltered.sort((a,b) => (a.dashboard_created > b.dashboard_created) ? 1 : ((b.dashboard_created > a.dashboard_created) ? -1 : 0))
                        this.sortClick = 0
                        break
                }
            },
            filterCollectionTextChange(v) {
                if(v) {
                    if(!this.subcollection) {
                        this.collectionDataFiltered = this.collectionData.filter(coll => coll.title.toLowerCase().includes(v.toLowerCase()))
                    } else {
                        this.collectionDataFiltered = this.filterInSubCollections(v)
                    }
                } else {
                    if(!this.subcollection) {
                        this.collectionDataFiltered = this.collectionData
                    } else {
                        this.collectionDataFiltered = this.subcollectionsWithSubcollections()
                    }
                }
            },
            loopThroughFilters() {
                this.filterCollectionType.forEach( fct => {
                    if( fct === 'Charts' ) {
                        this.collectionDataFiltered = this.collectionDataFiltered.filter(dash => {
                            return dash.saved_searches.some(item => item.dashboard_saved_search_type_id === 1
                                || item.dashboard_saved_search_type_id === 2 || item.dashboard_saved_search_type_id === 3
                                || item.dashboard_saved_search_type_id === 4 || item.dashboard_saved_search_type_id === 5
                                || item.dashboard_saved_search_type_id === 7 || item.dashboard_saved_search_type_id === 8
                                || item.dashboard_saved_search_type_id === 9 || item.dashboard_saved_search_type_id === 10)
                        })
                    } else if( fct === 'smartFORMS' ) {
                        this.collectionDataFiltered = this.collectionDataFiltered.filter( coll => {
                            return coll.form_instances.length > 0
                        })
                    } else if( fct === 'Meetings' ) {
                        this.collectionDataFiltered = this.collectionDataFiltered.filter( coll => {
                            return coll.dashboard_meetings.length > 0
                        })
                    } else if( fct === 'Data Walls' ) {
                        this.collectionDataFiltered = this.collectionDataFiltered.filter( dash => {
                            return dash.saved_searches.some(item => item.dashboard_saved_search_type_id === 6)
                        })
                    } else if( fct === 'SubCollections' ) {
                        this.collectionDataFiltered = this.collectionData.filter( coll => {
                            return coll.parent_dashboard.length > 0
                        })
                    }
                })
            },
            subcollectionsWithSubcollections() {
                let subs = []
                this.collectionDataFiltered = this.collectionData
                if(this.addSubCollections) {
                    this.collectionData.forEach( coll => {
                        if(coll.parent_dashboard.length > 0) {
                            coll.parent_dashboard.forEach( pd => {
                                let dash = this.allCollections.filter(cd => cd.dashboard_id === pd.child_dashboard_id)
                                subs.push(dash[0])
                            })
                        }
                    })
                    if(subs.length > 0) {
                        let ids = new Set(this.collectionDataFiltered.map(d => d.dashboard_id))
                        return [...this.collectionData, ...subs.filter(d => !ids.has(d.dashboard_id))]
                    } else {
                        return this.collectionDataFiltered
                    }
                } else {
                    return this.collectionData
                }
            },
            filterInSubCollections(v) {
                if(v) {
                    return this.subcollectionsWithSubcollections().filter(coll => coll.title.toLowerCase().includes(v.toLowerCase()))
                } else {
                    return this.subcollectionsWithSubcollections()
                }
            },
            onLoad() {
                this.$modelGet('collectionHome', {active: 1})
                    .then(response => {
                        this.allCollections = response.user_dashboards.concat(response.shared_dashboards)
                    })
            }
        },
        watch: {
            collectionData(v) {
                if(v.length > 0) {
                    if(!this.addSubCollections && !this.subcollection) {
                        this.collectionDataFiltered = v.filter( coll => {
                            return !coll.hidden
                        })
                    } else {
                        this.collectionDataFiltered = v
                    }
                } else {
                    this.collectionDataFiltered = []
                }
            },
            filterText(v) {
                if(v) {
                    this.filterCollectionTextChange(v)
                }
                else {
                    if(!this.addSubCollections && !this.subcollection) {
                        this.collectionDataFiltered = this.collectionData.filter( coll => {
                            return !coll.hidden
                        })
                    } else if(this.addSubCollections && this.subcollection) {
                        this.collectionDataFiltered = this.subcollectionsWithSubcollections()
                    } else {
                        this.collectionDataFiltered = this.collectionData
                    }
                }
            },
            addSubCollections(v) {
                if(v) {
                    if(!this.subcollection) {
                        this.collectionDataFiltered = this.collectionData
                    } else {
                        this.collectionDataFiltered = this.subcollectionsWithSubcollections()
                    }
                } else {
                    if(!this.subcollection) {
                        this.collectionDataFiltered = this.collectionData.filter( coll => {
                            return !coll.hidden
                        })
                    } else {
                        this.collectionDataFiltered = this.collectionData
                    }
                }
            },
            reload(v) {
                if(v) {
                    this.filterCollectionType = []
                    this.sortCollection = false
                    this.sortClick = 0
                    this.$emit('reloaded')
                }
            }
        },
        created() {
            if(this.collectionData && this.collectionData.length > 0) {
                if(!this.subcollection) {
                    this.collectionDataFiltered = this.collectionData.filter( coll => {
                        return !coll.hidden
                    })
                } else {
                    this.collectionDataFiltered = this.collectionData
                }
            } else {
                this.collectionDataFiltered = []
            }
        },
        mounted() {
            this.onLoad()
        }
    }
</script>

<style lang="scss" scoped>
    .ec-collection {
        &-icon {
            ::v-deep .v-btn {
                margin: 0 !important;
            }
            &.material-icons {
                ::v-deep i {
                    font-size: 24px !important;
                }
            }
            &-active {
                background: #ECEDF1 !important;
                border-radius: 50%;
            }
        }
        &-title {
            font-size: 16px;
            font-weight: bold;
            &-flex {
                align-items: center;
                height: 45px;
            }
        }
        &-tabs {
            ::v-deep .v-item-group {
                background: #F5F6F8;
                height: 100%;
                position: relative;
            }
            ::v-deep .v-tabs-slider {
                border-radius: 1.5px;
                &-wrapper {
                    height: 3px !important;
                }
            }
        }
        &-filter-list{
            overflow: hidden;
            padding-top: 10px;
        }
        &-empty-state {
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: 5%;
        }
    }
    .empty-state {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        ::v-deep img {
            height: 340px;
        }
    }
</style>
