<template>
    <div class="pl-10 pr-10 d-flex flex-column flex-fill">
        <fe-spinner v-if="isLoading" text="Loading..." />
        <fe-grid v-else
             style="height: 100%; margin-bottom: 10px;"
             ref="mergeGrid"
             class="user-merge-grid"
             displayDensity="small"
             :columnDefs="mergeColumnDefs"
             :rowData="mergeData"
             :showToolbar="false"
             @modelUpdated="bringFocusBack"
             disableFilterUpdate
        />
        <div v-if="!isLoading" class="py-2 d-inline-flex">
            <fe-label class="mr-4">Include Users Marked "Do Not Merge"</fe-label>
            <fe-switch hide-details v-model="includeDoNotMerge" />
            <v-spacer></v-spacer>
            <fe-btn usage="ghost" @click="closeMergeWindow">
                {{ 'Cancel' }}
            </fe-btn>
            <fe-btn :usage="'primary'" @click="submitMergeUsers" :disabled="!recsToMerge.length || isBusyMerging">
                {{ 'Merge' }}
            </fe-btn>
        </div>
        <user-card v-if="showUserCard"
                   :value="userRec"
                   :fields="[
                {name:'Id', field:'id'},
                {name:'Title', field:'title'},
                {name:'Email', field:'email_addr'},
                {name:'Login', field:'login'},
                {name:'Active', field:'active', type:'boolean'},
                {name:'Schools', field:'school_cnt'},
                {name:'Grades', field:'grade_cnt'},
                {name:'IDs', field:'indentification_cnt'},

            ]"
                   @close="showUserCard=false"
        />
        <fe-crud
            ref="crud"
            autoload
            :config="$models.user"
            @read="rowData = $event"
        />
        <fe-crud
            ref="doNotMergeCrud"
            :autoload="false"
            :config="$models.userDoNotMerge"
            disableSnackbars
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import UserCard from './dialogs/UserCard'

    export default {
        name: "UsersMerge",
        components: {
            UserCard,
        },
        data() {
            return {
                userRec: null,
                showUserCard: false,
                includeDoNotMerge: false,
                isBusyMerging: false,
                isLoading: false,
                rowData: [],
                mergeData: [],
                mergeDataIdIndex: {},
                mergeColumnDefs: [{
                    headerName: 'Name',
                    field: 'u1_user_full_name',
                    width:180,
                    cellStyle: this.cellStyle,
                    cellRenderer: v => {
                        return v.value + '<i style="font-size:12px;" class="fe-grid-icon fas fa-id-card-alt ml-2 grey--text text--lighten-1"></i>'
                    },
                    onCellClicked: this.openUserCard,
                }, {
                    headerName: 'Email Address',
                    field: 'u1_email_addr',
                    width:260,
                    cellStyle: this.cellStyle
                }, {
                    headerName: 'Last Login',
                    field: 'u1_last_login',
                    width:200,
                    cellStyle: this.cellStyle
                }, {
                    headerName: 'Keep Left User',
                    maxWidth: 90,
                    headerClass: 'user-merge-header',
                    cellStyle: {cursor: 'pointer', textAlign: 'center'},
                    cellRenderer: v => {
                        return v.data.keep_user_id == v.data.u1_id
                            ? '<i class="fe-grid-icon fas fa-check-square theme--light primary--text"></i>'
                            : '<i class="fe-grid-icon fas fa-arrow-circle-left theme--light"></i>'
                    },
                    onCellClicked: this.keepLeft,
                }, {
                    headerName: 'Do Not Merge',
                    field: 'distinct_users',
                    maxWidth: 90,
                    headerClass: 'user-merge-header',
                    cellStyle: {cursor: 'pointer', textAlign: 'center'},
                    cellRenderer: v => {
                        return v.data.distinct_users
                            ? '<i class="fe-grid-icon fas fa-check-square theme--light primary--text"></i>'
                            : '<i class="fe-grid-icon fas fa-minus-circle theme--light"></i>'
                    },
                    onCellClicked: this.doNotMerge,
                }, {
                    headerName: 'Keep Right User',
                    maxWidth: 90,
                    headerClass: 'user-merge-header',
                    cellStyle: {cursor: 'pointer', textAlign: 'center'},
                    cellRenderer: v => {
                        return v.data.keep_user_id == v.data.u2_id
                            ? '<i class="fe-grid-icon fas fa-check-square theme--light primary--text"></i>'
                            : '<i class="fe-grid-icon fas fa-arrow-circle-right theme--light"></i>'
                    },
                    onCellClicked: this.keepRight,
                }, {
                    headerName: 'Name',
                    field: 'u2_user_full_name',
                    width:180,
                    cellStyle: this.cellStyle,
                    cellRenderer: v => {
                        return v.value + '<i style="font-size:12px;" class="fe-grid-icon fas fa-id-card-alt ml-2 grey--text text--lighten-1"></i>'
                    },
                    onCellClicked: this.openUserCard,
                }, {
                    headerName: 'Email Address',
                    field: 'u2_email_addr',
                    width:260,
                    cellStyle: this.cellStyle
                }, {
                    headerName: 'Last Login',
                    field: 'u2_last_login',
                    width:180,
                    cellStyle: this.cellStyle
                }]
            }
        },
        mounted() {
            this.loadMergeData()
        },
        computed: {
            ...mapState('global', ['sessionUser']),
            recsToMerge() {
                return this.mergeData.filter(x => x.keep_user_id && x.merge_user_id)
            },
        },
        watch: {
            includeDoNotMerge() {
                this.loadMergeData()
            },
        },
        methods: {
            loadMergeData() {
                this.isLoading = true
                let incl = this.includeDoNotMerge ? 1 : !this.includeDoNotMerge ? 0 : this.includeDoNotMerge
                this.$modelGet('userMerge', { property: 'users', include_distinct: incl }).then(r => {
                    this.mergeData = r
                    this.mergeDataIdIndex = {}
                    this.mergeData?.forEach(x => {
                        x.u1_user_full_name = `${x.u1_lname}, ${x.u1_fname}`
                        x.u2_user_full_name = `${x.u2_lname}, ${x.u2_fname}`
                        this.mergeDataIdIndex[x.u1_id] = true
                        this.mergeDataIdIndex[x.u2_id] = true
                    })
                    this.isLoading = false
                    this.$refs.mergeGrid.gridApi.redrawRows()
                })
            },
            keepRight(v) {
                if(v.data.keep_user_id === v.data.u2_id) {
                    // toggle off
                    v.data.keep_user_id = undefined
                    v.data.merge_user_id = undefined
                } else {
                    v.data.keep_user_id = v.data.u2_id
                    v.data.merge_user_id = v.data.u1_id
                }
                this.mergeData = this.mergeData.map(x=>x)
                this.$refs.mergeGrid.gridApi.redrawRows(v.node)
            },
            keepLeft(v) {
                if(v.data.keep_user_id === v.data.u1_id) {
                    // toggle off
                    v.data.keep_user_id = undefined
                    v.data.merge_user_id = undefined
                } else {
                    v.data.keep_user_id = v.data.u1_id
                    v.data.merge_user_id = v.data.u2_id
                }
                this.mergeData = this.mergeData.map(x=>x)
                this.$refs.mergeGrid.gridApi.redrawRows(v.node)
            },
            doNotMerge(v) {
                let me = this
                if (v.data.distinct_users === 1) {
                    let params = {
                        id: v.data.distinct_user_id
                    }
                    me.$refs.doNotMergeCrud.destroy(params)
                        .then(response => {
                            if(response.data.success) {
                                let successMsg = "Successfully added records back into the default table view"
                                this.$snackbars.$emit('new', { text: successMsg, usage: 'success' })
                            } else {
                                this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                            }
                        }).finally(() => {
                        me.loadMergeData()
                        me.bringFocusBack('mergeGrid')
                    })
                } else {
                    let params = {
                        user1_id: v.data.u1_id > v.data.u2_id ? v.data.u2_id : v.data.u1_id,
                        user2_id: v.data.u1_id > v.data.u2_id ? v.data.u1_id : v.data.u2_id
                    }
                    me.$messageBox({
                        title: 'Confirm Do Not Merge',
                        persistent: true,
                        maxWidth: 600,
                        message: 'Are you sure you want to complete this action? By selecting “Do Not Merge” you are acknowledging that these two users are distinct and should be removed from the default view of this table. You can always undo this action later.',
                        actions: [{
                            text: 'Cancel', primary: false,
                            onClick: () => {
                                me.bringFocusBack('mergeGrid')
                            }
                        }, {
                            text: 'Confirm', primary: true,
                            onClick: () => {
                                me.$refs.doNotMergeCrud.create(params)
                                    .then(response => {
                                        if(response.data.success) {
                                            let successMsg = "Successfully removed records from the default table view"
                                            this.$snackbars.$emit('new', { text: successMsg, usage: 'success' })
                                        } else {
                                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                                        }
                                    }).finally(() => {
                                    me.loadMergeData()
                                    me.bringFocusBack('mergeGrid')
                                })
                            }
                        }]
                    })
                }
            },
            bringFocusBack() {
                let cell = this.$refs.mergeGrid.gridOptions.api.getFocusedCell()

                if ( cell ) {
                    this.$nextTick(() => {
                        this.$refs.mergeGrid.gridApi.ensureIndexVisible(cell.rowIndex, 'middle')
                        this.$flashGridRow(this.$refs.mergeGrid,cell.rowIndex,3000)
                    })
                }
            },
            submitMergeUsers() {
                this.$messageBox({
                    title: 'Merge Users',
                    persistent: true,
                    maxWidth: 400,
                    message: `Are you sure you want to merge records?  This action cannot be undone.`,
                    actions: [{
                        text: 'Cancel',
                    }, {
                        text: 'Merge', usage: 'danger',
                        onClick: () => {
                            this.isBusyMerging = true
                            this.$axios({
                                url: 'userMerge.php',
                                method: 'post',
                                params: {
                                    action: 'merge',
                                    property: 'users'
                                },
                                data: JSON.stringify({
                                    users: this.recsToMerge
                                })
                            }).then(r => {
                                this.$ecResponse(r)
                                this.$refs.crud.read()
                                this.loadMergeData()
                            }).finally(() => {
                                this.isBusyMerging = false
                            })
                        }
                    }]
                })
            },
            closeMergeWindow() {
                this.$parent.$parent.closeWindow()
            },
            cellStyle(v) {
                let backgroundColor = '#f5f6f8'
                let color = 'rgba(0,0,0,.87)'
                if(v.data.keep_user_id === v.data.u1_id && v.colDef.field.startsWith('u1')) backgroundColor = 'var(--fe-selected)'
                if(v.data.keep_user_id === v.data.u2_id && v.colDef.field.startsWith('u2')) backgroundColor = 'var(--fe-selected)'
                if(!v.data.u1_active && v.colDef.field.startsWith('u1')) color = 'rgba(0,0,0,.45)'
                if(!v.data.u2_active && v.colDef.field.startsWith('u2')) color = 'rgba(0,0,0,.45)'
                return {
                    color: color,
                    backgroundColor: backgroundColor
                }
            },
            openUserCard(v) {
                this.userRec = v.data
                if(v.colDef.field === 'u1_user_full_name') this.userRec = this.rowData.find(x => x.id === v.data.u1_id)
                if(v.colDef.field === 'u2_user_full_name') this.userRec = this.rowData.find(x => x.id === v.data.u2_id)
                this.showUserCard = true
            },
        }
    }
</script>

<style scoped>

</style>
