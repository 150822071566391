<template>
    <div>
        <div class="d-flex">
            <div 
                v-for="(item,i) in items" 
                :class="{active: local && local.id==item.id, 'next-active': local && i < items.length-1 && items[i+1].id==local.id, last: i===items.length-1}" 
                class="flex-grow-1 filter-segment" 
                @click="onClick(item)"
                :key="`stepper-navigation-`+i"
            >{{item.name}}</div>
            <!-- <div :class="{active: view=='population', 'next-active': view=='assessment'}" class="flex-grow-1 filter-segment" @click="view='population'">Student Population</div>
            <div :class="{active: view=='assessment', 'next-active': view=='incident'}" class="flex-grow-1 filter-segment" @click="view='assessment'">Assessment</div>
            <div :class="{active: view=='incident', 'next-active': view=='attendance'}" class="flex-grow-1 filter-segment" @click="view='incident'">Incidents</div>
            <div :class="{active: view=='attendance'}" class="flex-grow-1 filter-segment last" @click="view='attendance'">Attendance</div> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StepperNavigation',
        props: {
            value: {},
            disabled: {
                type: Boolean,
                default: false
            },
            nextDisabled: {
                type: Boolean,
                default: false
            },
            items: {
                default: () => []
            }
        },
        watch: {
            value: {
                handler(v) {
                    this.local = v
                },
                immediate: true
            },
            local(v) {
                this.$emit('input', v)
            }
        },
        data() {
            return {
                local: null
            }
        },
        mounted() {
            if (!this.value && this.items.length>0) this.local = this.items[0] 
        },
        methods: {
            onClick(item) {
                if (this.disabled) {
                    // All movement must be disabled in this scenario, even previously completed steps
                    return
                }

                if (this.nextDisabled) {
                    let currentIndex = this.items.findIndex(itm => itm === this.local)
                    let clickedIndex = this.items.findIndex(itm => itm === item)

                    // User cannot proceed further into the stepper until the current
                    // page is completed.  Backwards movement should be allowed.
                    if (clickedIndex > currentIndex) {
                        return
                    }
                }

                this.local = item
            },
        },
    }
</script>

<style lang="scss" scoped>
.filter-segment {
    cursor: pointer;
    line-height: 36px;
    padding: 8px 16px 8px 16px;
    background: #F5F6F8;
    color: #7E8494;
    font-size: 14px;

    background-image:
        linear-gradient( -45deg, #F5F6F8 25px, transparent 25px),
        linear-gradient(-135deg, #F5F6F8 25px, transparent 25px),
        linear-gradient( -45deg, white 32px, transparent 32px),
        linear-gradient(-135deg, white 32px, transparent 32px),
        linear-gradient( -45deg, #F5F6F8 40px, transparent 40px),
        linear-gradient(-135deg, #F5F6F8 40px, transparent 40px);

    &.next-active:not(.last) {
        background-image:
            linear-gradient( -45deg, #d7eff7 25px, transparent 25px),
            linear-gradient(-135deg, #d7eff7 25px, transparent 25px),
            linear-gradient( -45deg, white 32px, transparent 32px),
            linear-gradient(-135deg, white 32px, transparent 32px),
            linear-gradient( -45deg, #F5F6F8 40px, transparent 40px),
            linear-gradient(-135deg, #F5F6F8 40px, transparent 40px);
        color: #050F2D;
    }

    &.active {
        background: #d7eff7;
        background-image:
            linear-gradient( -45deg, #F5F6F8 25px, transparent 25px),
            linear-gradient(-135deg, #F5F6F8 25px, transparent 25px),
            linear-gradient( -45deg, white 32px, transparent 32px),
            linear-gradient(-135deg, white 32px, transparent 32px),
            linear-gradient( -45deg, #d7eff7 40px, transparent 40px),
            linear-gradient(-135deg, #d7eff7 40px, transparent 40px);
        color: #050F2D;
    }

    &.last {
        background-image: none;
    }

}
</style>