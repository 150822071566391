<template>
    <div class="flex-fill flex-column no-scroll" style="height: 400px;">
        <fe-crud-grid
            ref="grid"
            identifier="data_point_score_id"
            :autoload="true"
            :columnDefs="columnDefs"
            :config="$models.studentScores"
            :extraTools="this.hasSecurity"
            :readParams="{ student_id: this.updateStudent.student_id, property: 'student' }"
            :rowData="scores"
            :showAddRowBtn="showAddCourseBtn"
            :showEditbar="this.hasSecurity"
            @read="v => scores = v"
            @selectionChanged="setSelectedRows"
            displayDensity="medium"
            refreshOnCreated
            refreshOnUpdated
            style="height:100%;"
        >
            <!-- Hide until new slot is added to ec-frontend-library -->
            <template v-slot:extra-tools>
                <fe-btn
                    v-if="canReassign"
                    useIcon="fa-exchange-alt"
                    usage="ghost"
                    whiteText
                    class="fe-grid-action-btn"
                    @click="$emit('reassignBtnClick', $refs.grid.$refs.crud)"
                >
                    Reassign
                </fe-btn>
            </template>
        </fe-crud-grid>
    </div>
</template>

<script>
    import Vuex, { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'

    export default {
        name: 'StudentScores',

        data() {
            return {
                scores: null,
                showAddCourseBtn: false,
                gridApi: null
            }
        },

        inject: ['localStore'],

        computed: {
            ...mapState('global', ['alphaMaps','sessionUser']),
            ...mapLocalState(['studentName','updateStudent','selections','hasSecurity']),

            canReassign() {
                return this.sessionUser.security_codes.indexOf('DELETE_SCORES') != -1
            },

            columnDefs() {
                let me = this

                return [
                    {
                        headerName: null,
                        headerCheckboxSelection: this.hasSecurity,
                        checkboxSelection: this.hasSecurity,
                        width: 70,
                        minWidth: 70,
                        hide: !this.hasSecurity,
                        colId: 'checkbox_column'
                    }, {
                        headerName: "Score ID",
                        field: "data_point_score_id",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Year",
                        field: "school_year_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Assessment Group",
                        field: "data_point_type_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Assessment",
                        field: "sub_category_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Data Point",
                        field: "data_point_id",
                        sortable: true,
                        editable: false,
                        hide: true
                    }, {
                        headerName: "Window",
                        field: "data_point_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Score",
                        field: "score",
                        sortable: true,
                        editable: false,
                        valueGetter: v => me.findLabel(this.alphaMaps, v.data.data_point_type_id, v.data.score, 'data_point_type_id', 'maps', 'numeric_score', 'alpha_score'),
                        comparator: this.$sortStringsNumerically,
                    }, {
                        headerName: "Upload ID",
                        field: "data_point_score_upload_id",
                        sortable: true,
                        editable: false
                    }
                ]
            }
        },

        methods: {
            findLabel(collection, value, childValue, valueProp, childArr, childValueProp, labelProp) {
                let lbl = undefined
                let rndVal = Math.round(childValue)

                if (collection && value) {
                    let sel = collection.find(x => x[valueProp] === value)
                    if (sel) {
                        let childSel = sel[childArr].find(y => y[childValueProp] === childValue)
                        let childSelRnd = sel[childArr].find(z => z[childValueProp] === rndVal)

                        if (childSel) lbl = childSel[labelProp]
                        else if (childSelRnd) lbl = childSelRnd[labelProp]
                    }
                }

                if (lbl) return lbl
                else return childValue
            },

            setSelectedRows(meta) {
                this.$data.gridApi = meta.api
                this.selections = meta.api.getSelectedRows()
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
