<template>
    <field-container
        title="Display Settings (Optional)"
        :expanded="expanded"
    >
        <div v-if="localValue.colors.length">
            <fe-label>Customize Series Colors</fe-label>
            <div>
                <div v-for="item in localValue.colors" class="series-item d-flex my-2">

                    <fe-color-selector
                        :value="item.color || '#D8D8D8'"
                        @change="setColor($event, item.name)"
                    />

                    <div class="pa-2">{{ item.name }}</div>

                    <fe-tooltip tooltip="Reset to default color">
                        <fe-icon-btn
                            class="unset-btn"
                            useIcon="fal fa-times"
                            small
                            @click="setColor(null, item.name)"
                        />
                    </fe-tooltip>
                </div>
            </div>
        </div>

        <fe-label>Axis Range</fe-label>
        <div class="d-flex">
            <v-text-field
                type="number"
                placeholder="Minimum"
                v-model="localValue.yAxisMin"
                class="mr-2"
                flat solo dense
                clearable
            />
            <v-text-field
                type="number"
                placeholder="Maximum"
                v-model="localValue.yAxisMax"
                class="ml-2"
                flat solo dense
                clearable
            />
        </div>
    </field-container>
</template>

<script>
import FieldContainer from "./FieldContainer"

export default {
    name: "DisplaySettings",
    components: {FieldContainer},

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            localValue: {
                colors: [],
                yAxisMin: undefined,
                yAxisMax: undefined,
            },
            expanded: false
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(v) {
                Object.keys(v).forEach(x => {
                    if (!this.expanded) {
                        let value = v[x]
                        if (x === 'colors') {
                            value.forEach(y => {
                                if (y.color) this.expanded = true
                            })
                        } else if (!this.$_.isEmpty(value)) {
                            this.expanded = true
                        }
                    }
                })

                this.localValue = Object.assign({}, this.localValue, v)
            }
        },

        localValue: {
            deep: true,
            handler(v) {
                this.$emit('change', v)
            }
        }
    },

    methods: {
        setColor(hex, name) {
            this.localValue.colors = this.localValue.colors.map(x => {
                if (x.name === name) x.color = hex
                return x
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.series-item {
    .unset-btn {
        opacity: 0;
        transition: opacity 0.2s linear;
    }

    &:hover {
        .unset-btn {
            opacity: 1;
        }
    }
}
</style>
