<template>
    <fe-stepper-filter
        :title="`Scatter Plot:  ${name}`"
        style="height:620px;"
        :applyDisabled="searchDisabled"
        applyBtnText="Save Data Set"
        @apply="emit"
        @close="$emit('close'); clearFilters()"
    >
        <template #footer>
            <div class="d-flex" style="width: 100%;">
                <div class="mt-3 ml-4" v-html="searchDisplay"></div>
                <v-spacer/>
            </div>
        </template>

        <!-- Have to overwrite default apply slot to add in the tooltip -->
        <template #apply>
            <v-spacer/>
            <fe-tooltip :tooltip="warningTooltip" :disabled="!warningTooltip">
                <fe-btn :disabled="(warningTooltip && warningTooltip.length > 0) || searchDisabled" usage="primary" @click="emit">Save Data Set</fe-btn>
            </fe-tooltip>
        </template>

        <keep-alive v-if="localValue">
            <sub-category-stepper-panel
                byId
                v-model="localValue"
                :surfaceFirstSelections="surfaceFirstSelections"
                @selected="checkValid"
                @summaryChange="summary = $event"
                @searchDisplayChange="searchDisplay = $event"
            />
        </keep-alive>
    </fe-stepper-filter>
</template>

<script>
    import * as Util from './Util'
    import StudentStepperPanel from '@/components/common/stepper/StudentStepperPanel'
    import SubCategoryStepperPanel from '@/components/common/stepper/SubCategoryStepperPanel'
    import IncidentStepperPanel from '@/components/common/stepper/IncidentStepperPanel'
    import MultiDataStepperPanel from '@/components/common/stepper/MultiDataStepperPanel'
    import StepperNavigation from './StepperNavigation'
    import scatterMixin from './mixins/scatter'

    export default {
        name: 'ScatterStepper',
        mixins: [scatterMixin],
        components: {
            StudentStepperPanel,
            SubCategoryStepperPanel,
            IncidentStepperPanel,
            MultiDataStepperPanel,
            StepperNavigation
        },
        props: {
            state: {
                type: Object,
                required: false
            },
            byId: {
                type: Boolean,
                default: true
            },
            requireSubcategory: {
                type: Boolean,
                default: false
            },
            value: {
                type: Object,
                required: true
            },
            surfaceFirstSelections: {
                type: Boolean,
                default: false
            },
            opposingValue: {
                type: Object,
                required: true
            },
            name: {
                type: String,
                required: false
            },
        },
        watch: {
        },
        data() {
            return {
                localValue: null,
                searchDisabled: true,
                allowNext: false,
                searchDisplay: '<b>Select School Year</b>',
                summary: '',
            }
        },
        computed: {
            formValid() {
                return false
            },
            schoolYearId() {
                return this.population.school_year_id
            },
            isDuplicatingOpposingDataSet() {
                return (
                    this.localValue?.school_year_id === this.opposingValue?.school_year_id &&
                    this.localValue?.data_point_type_id === this.opposingValue?.data_point_type_id &&
                    this.localValue?.sub_category_id === this.opposingValue?.sub_category_id &&
                    this.localValue?.data_point_name_id === this.opposingValue?.data_point_name_id &&
                    this.localValue?.score_detail_type_id === this.opposingValue?.score_detail_type_id
                )
            },
            warningTooltip() {
                if (this.isDuplicatingOpposingDataSet) {
                    return 'This dataset is identical to the other dataset you created for this graph.  In order to save, you must make this dataset unique.'
                } else {
                    return null
                }
            },
        },

        mounted() {
            this.localValue = this.$_.cloneDeep(this.value)
            this.loadBase()
        },
        methods: {
            checkValid(item) {
                let dpn = this.localValue?.data_point_name_id
                if (!dpn)  {
                    this.searchDisabled = true
                } else {
                    this.searchDisabled = false
                }
            },
            clearFilters() {
                this.population={}
                this.assessment={}
                this.incident={}
            },
            loadBase() {
                this.dialog=true
            },
            async emit() {
                this.$emit('update:assessmentSummary', this.summary)
                this.$emit('input', this.localValue)
                this.$emit('close')
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
