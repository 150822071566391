<template>
    <div class="flex-column flex-fill" v-intersect="onShow">
        <fe-overlay
            :value="loading"
            loader
            text=""
        />

        <fe-grid style="height:100%"
            ref="grid"
            :columnDefs="columnDefs"
            :rowData="templates"
            :bordered="false"
            :showAddRowBtn="false"
            displayDensity="medium"
            disableInlineCreate
            @rowDoubleClicked="selectTemplate($event.data)"
        >
        </fe-grid>


        <add-template v-if="addDialog.show"
            :value="addDialog.rec"
            clone="cloud"
            @close="addDialog.show=false"
            @update="downloadRec"
        />

        <fe-crud ref="crud" :config="$models.form" :autoload="false" @read="templates = $event" />
    </div>
</template>

<script>
import Vuex from 'vuex'
import { mapLocalState } from '@/util/vuexHelper'
import SmartFormTemplate from '../template/SmartFormTemplate'
import AddTemplate from '../template/AddTemplate'

export default {
    name: 'ClimberCloud',
    
    components: {
        SmartFormTemplate,
        AddTemplate,
    },

    methods: {
        onShow(e) {
            this.loading = true
            e.some(x => x.isIntersecting) && this.$refs.crud.read({
                cloud: 1,
            })
            .then(() => {
                this.loading = false
            })
        },
        selectTemplate(rec) {
            this.$store.commit('global/addDockableWindow', {
                name: 'Preview: '+rec.name,
                component: 'SmartFormTemplate',
                attrs: {
                    template: rec,
                    cloud: 1,
                },
            })
        },
        downloadRec(v) {
            this.$refs.crud.create({
                name: v.name,
                description: v.description,
                source_form_id: v.id,
            },{
                property: 'clone_form',
                cloud: 1,
            }).finally( () => this.addDialog.show = false )
        },
        renderWithSubField(v) {
            return v.data.description
                ? `<div style="font-weight:400; line-height:30px;"><b>${v.value}</b><div class="fe-grid-cell-subtext">${v.data.description}<br></div></div>`
                : `${v.value}`
        },
    },

    data() {
        return {
            addDialog: {
                show: false,
                rec: null,
            },
            loading: false,
            templates: [],
            columnDefs: [{
                headerName: 'Title and Description',
                field: 'name',
                autoHeight: true,
                width: 300,
                cellRenderer: this.renderWithSubField,
            }, {
                headerName: 'District',
                field: 'district_name',
                width: 195,
                cellStyle: { color: '#9297A6', fontSize: '12px' }
            }, {
                headerName: 'Shared By',
                field: 'user_full_name',
                maxWidth: 160,
                cellStyle: { color: '#9297A6', fontSize: '12px' }
            }, {
                headerName: 'Shared On',
                field: 'created',
                maxWidth: 180,
                cellStyle: { color: '#9297A6', fontSize: '12px' }
            }, {
                headerName: 'Downloads',
                field: 'download_count',
                maxWidth: 120,
                cellStyle: { color: '#9297A6', fontSize: '12px' }
            }, {
                colId: 'toolmenu',
                maxWidth: 60,
                cellRenderer: v => {
                    return '<i class="fe-grid-icon far fa-cloud-download theme--light"></i>'
                },
                onCellClicked: v => {
                    this.addDialog = {
                        show: true,
                        rec: v.data,
                    }
                },
            }],
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
