<template>
    <div>
        <fe-crud
            :config="$models[model]"
            ref="crud"
            @read="handleRead"
        />

        <div v-if="display === 'list'">
        </div>

        <div v-else>
            <v-form v-model="valid" @submit="onSubmit">
                <div v-if="title" class="page-title">{{ title }} </div>
                <div class="d-flex flex-wrap">
                    <div v-if="field.toggle ? field.toggle(item) : true" v-for="(field, i) in fieldConfig" :style="{width: field.width ? field.width : null}" class="pr-2" :key="`model-form-`+i">
                        <fe-label v-if="field.label">{{field.label}}</fe-label>
                        <component
                            :is="field.component"
                            v-bind="field.attrs"
                            v-model="item[field.field]"
                            :rules="$fieldValidators(field.validatorKey, field.label, field.validatorConfig)"
                            v-on="generateEvents(field, item)"
                        />
                    </div>
                </div>
                <slot/>
            </v-form>

            <div class="d-flex">
                <div class="record-counter" v-if="display == 'page' && items.length>1">Record {{activeIndex+1}} of {{items.length}}</div>

                <div>
                    <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </div>
                <fe-btn usage="ghost" class="ml-auto" @click="$emit('cancel')">{{cancelButtonText}}</fe-btn>
                <fe-btn v-if="secondaryButtonText" usage="secondary" @click="doSave(true)" :disabled="!valid">{{secondaryButtonText}}</fe-btn>
                <fe-btn usage="primary" @click="doSave(false)" :disabled="!valid">{{activeIndex+1 === items.length ? submitButtonText : 'Next'}}</fe-btn>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ModelForm',
        props: {
            display: {
                type: String,
                default: 'page'
            },
            title: {
                type: String
            },
            model: {
                type: String,
                required: true
            },
            idProperty: {
                type: String,
                required: true
            },
            records: {
                type: Array,
                required: true
            },
            readParams: {

            },
            autoload: {
                type: Boolean,
                default: false
            },
            fieldConfig: {
                type: Array,
                required: true
                /*
                Example:
                {
                    field: 'student_full_name',
                    fieldLabel: 'Student',
                    component: 'v-textfield',
                    validatorKey: 'text',
                    validatorConfig: { limit: 255 },
                    attrs: {}
                }
                */
            },
            submitButtonText: {
                type: String,
                default: 'Save'
            },
            secondaryButtonText: {
                type: String,
                default: null
            },
            cancelButtonText: {
                type: String,
                default: 'Cancel'
            },
            submitPrevent: {
                type: Boolean,
                default: false
            },
            savePrevent: {
                type: Boolean,
                default: false
            },
        },
        watch: {
            activeIndex(v) {
                this.$data.item = this.$data.items[v]
            },
            records: {
                handler(v) {
                    if (this.$data.items.length === 0) {
                        this.$data.items = v.map(rec => { return {...rec} })

                        this.$data.activeIndex = 0

                        if (v.length > 0) {
                            this.$data.item = v[0]
                        }
                    } else {
                        for (let rec of v) {
                            let itemIndex = this.items.findIndex(itm => itm[this.idProperty] == rec[this.idProperty])
                            if (itemIndex >= 0) {
                                this.items.splice(itemIndex, 1, { ...this.items[itemIndex], rec })
                            } else {
                                this.items.push({ ...rec })
                            }
                        }

                        this.activeIndex = 0
                        this.item = this.items[0]
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                valid: false,
                items: [],
                item: {},
                activeIndex: 0,
                loading: false
            }
        },
        mounted() {
            if (this.$props.records) {
                this.$data.items = this.$props.records.map(rec => { return {...rec} })
                if (this.$data.items.length > 0) {
                    this.$data.item = this.$data.items[0]
                }
            }

            if (this.$props.autoload) {
                this.loadData()
            }

        },
        methods: {
            generateEvents(field, item) {
                let me = this

                if (!field.events) return {}

                return Object.entries(field.events).reduce((acc, [eventName, methodName]) => {
                    if (!acc) acc = {}
                    acc[eventName] = function() {
                        methodName(field, item)
                        me.$forceUpdate()
                    }
                    return acc;
                }, {})
            },
            doSave(action=false) {
                if (this.savePrevent) {
                    if (action) {
                        this.$emit('action')
                    }
                    this.$emit('save', this.$data.items)
                    return
                }

                let rec = this.$data.items[this.$data.activeIndex]
                let orig = this.$props.records[this.$data.activeIndex]
                let dirty = false

                this.fieldConfig.forEach(cfg => {
                    if (rec[cfg.field] != orig[cfg.field]) dirty = true
                })

                if (!dirty) {
                    this.continueToNext()
                    return
                }

                if (rec[this.$props.idProperty]) {
                    // Updating
                    this.loading = true
                    this.$refs.crud.update([rec])
                        .then(response => {
                            this.continueToNext()
                        })
                        .finally(() => this.loading = false)
                } else {
                    this.loading = true
                    // Creating
                    this.$refs.crud.create([rec])
                        .then(response => {
                            this.continueToNext()
                        })
                        .finally(() => this.loading = false)
                }


            },
            continueToNext() {
                if ((this.$data.activeIndex+1) < this.$data.items.length) {
                    this.$data.activeIndex++
                } else if (this.$data.activeIndex+1 === this.$data.items.length) {
                    this.$emit('done')
                }
            },
            handleRead(v) {
                this.$data.items = v.length > 0 ? v : [{}]

                this.$data.item = this.$data.items[0]
            },
            loadData() {
                this.$refs.crud.read(this.$props.readParams ? this.$props.readParams : null)
                    .then(response => {})
            },
            onSubmit(e) {
                if (this.submitPrevent) {
                    e.preventDefault();
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.record-counter {
    line-height: 36px;
    font-size: 12px;

}

.page-counter {
    cursor: pointer;
    height: 26px;
    width: 26px;
    border: 1px solid #1976D2;
    color: #1976D2;
    line-height: 24px;
    margin: 3px;
    text-align: center;
    border-radius: 20px;
    padding-left: 1px;

    &-active {
        background-color: #1976D2;
        color: white;
    }
}
</style>
