<template>
    <div style="font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important; font-size: 12px;">
        <v-layout class="ap-wrap" wrap>
            <v-layout row wrap>
                <div style="text-align: center; font-weight: bold;-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;max-width: 100%;margin-top:20px;margin-bottom:20px;">
                    <div style="text-align: center; margin-bottom: 8px;">
                        <img :src="$axios.defaults.baseURL + 'logo.php'" height="40" crossorigin="use-credentials" id="apLogoImg"/>
                    </div>
                    Action Plan for {{activeDashboard.name}}
                </div>
            </v-layout>
            <v-flex v-for="(objective, index) in actionPlan" :key="index" xs12>
                <v-layout style="background-color: #666666;color: white;padding: 10px;">
                    <v-flex xs12>{{objective.name}}</v-flex>
                </v-layout>

                <v-layout v-for="(goal, index) in objective.goals" :key="index">
                    <v-flex xs12>
                        <v-layout style="padding: 10px;background-color: #B7B7B7;" wrap v-if="goal.id">
                            <v-flex xs12>
                                {{goal.custom_statement || goal.default_statement}}
                            </v-flex>
                        </v-layout>

                        <div
                            v-for="(task, index) in goal.tasks"
                            :key="index"
                            :class="[{'margin-fix': !landscape}]"
                            style="page-break-before: auto; page-break-inside: avoid; display: flex;-webkit-box-flex: 1;flex: 1 1 auto;min-width: 0;flex-wrap:wrap"
                        >
                            <div style="background-color: #D9D9D9; font-weight: bold;padding: 10px;display: flex;
                                flex-basis: 33.5%;-webkit-box-flex: 0;-ms-flex-positive: 0;flex-grow: 0;max-width: 33.5%;" class="ap-xs5"
                            >
                                Action Item
                            </div>
                            <div style="background-color: #D9D9D9; font-weight: bold;padding: 10px;display: flex;
                                flex-basis: 25%;-webkit-box-flex: 0;-ms-flex-positive: 0;flex-grow: 0;max-width: 25%;"
                            >
                                Assignee(s)
                            </div>
                            <div style="background-color: #D9D9D9; font-weight: bold;padding: 10px;display: flex;
                                flex-basis: 16.6666666667%;-webkit-box-flex: 0;-ms-flex-positive: 0;flex-grow: 0;max-width: 16.6666666667%;"
                            >
                                Due Date
                            </div>
                            <div style="background-color: #D9D9D9; font-weight: bold;padding: 10px;display: flex;-webkit-box-flex: 0;-ms-flex-positive: 0;flex-grow: 0;"
                                :style="landscape ? 'flex-basis: 17%;max-width: 17%;' : 'flex-basis: 14.5%;max-width: 14.5%;'" class="ap-xs2"
                            >
                                Status
                            </div>
                            <div style="background-color: #FFFFFF;padding: 10px;display: flex;
                                flex-basis: 33.5%;-webkit-box-flex: 0;-ms-flex-positive: 0;flex-grow: 0;max-width: 33.5%;" class="ap-xs5"
                            >
                                {{task.name}}
                            </div>
                            <div style="background-color: #FFFFFF;padding: 10px;display: flex;
                                flex-basis: 25%;-webkit-box-flex: 0;-ms-flex-positive: 0;flex-grow: 0;max-width: 25%;"
                            >
                                <p
                                    v-for="(assignee, index) in task.assignees"
                                    :key="index"
                                >
                                    {{assignee.user_full_name}}
                                </p>
                            </div>
                            <div style="background-color: #FFFFFF;padding: 10px;display: flex;
                                flex-basis: 16.6666666667%;-webkit-box-flex: 0;-ms-flex-positive: 0;flex-grow: 0;max-width: 16.6666666667%;"
                            >
                                <span v-if="task.due_dt">{{new Date(task.due_dt).toLocaleDateString()}}</span>
                            </div>
                            <div style="background-color: #FFFFFF;padding: 10px;display: flex;"
                                 :style="landscape ? 'flex-basis: 17%;max-width: 17%;' : 'flex-basis: 14.5%;max-width: 14.5%;'" class="ap-xs2"
                            >
                                {{statusName(task.task_status_id)}}
                            </div>
                            <v-flex
                                v-if="(descriptions && task.description) || (comments && task.notes.length)"
                                style="padding: 10px;"
                            >
                                <p v-if="descriptions && task.description">
                                    Description&colon;<br>
                                    {{task.description}}
                                </p>

                                <p
                                    v-if="comments && task.notes.length"
                                    :class="[{'mt-2': descriptions && task.description}]"
                                >
                                    Comments&colon;
                                </p>

                                <div
                                    v-if="comments && task.notes.length"
                                    v-for="note in task.notes"
                                >
                                    <p>
                                        <b>
                                        <span v-if="commenter">
                                            {{note.user_full_name}} &bull;
                                        </span>
                                            {{new Date(note.created).toLocaleDateString()}} &colon;
                                        </b>
                                        <span v-html="note.comment"/>
                                    </p>
                                </div>
                            </v-flex>
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    export default {
        name: "ActionPlanPrintView",
        props: {
            descriptions: {
                type: Boolean,
                default: true,
            },
            comments: {
                type: Boolean,
                default: true,
            },
            commenter: {
                type: Boolean,
                default: true,
            },
            landscape: {
                type: Boolean,
                default: true
            },
            activeDashboard: {
                type: Object,
                default: () => {}
            },
            actionPlan: {
                type: Array,
                default: () => []
            },
        },
        methods: {
            statusName(id) {
                switch (id) {
                    case 1:
                        return 'To Do'
                    case 2:
                        return 'On Hold'
                    case 3:
                        return 'Done'
                    case 4:
                        return 'In Progress'
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .objective, .goal, .action-item-group {
        page-break-before: auto;
        page-break-inside: avoid;
    }
    .objective, .goal, .action-item, .action-item-details {
        padding: 10px;
    }
    .objective {
        background-color: #666666;
        color: white;
    }
    .goal {
        background-color: #B7B7B7;
    }
    .action-item {
        background-color: #FFFFFF;
    }
    .action-item.header {
        background-color: #D9D9D9;
        font-weight: bold;
    }
    /** Temporary to account for Bootstrap **/
    p {
        margin-bottom: 0;
    }
    .ap-print-view {
        font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
    }
    .ap
    {
        &-xs5 {
            -ms-flex-preferred-size: 41.6666666667% !important;
            flex-basis: 41.6666666667% !important;
            -webkit-box-flex: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 41.6666666667% !important;
        }
        &-xs2 {
            -ms-flex-preferred-size: 16.6666666667% !important;
            flex-basis: 16.6666666667% !important;
            -webkit-box-flex: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 16.6666666667% !important;
        }
    }
</style>
