<template>
    <fe-remote-combo
        v-bind="$attrs"
        rootProperty="tags"
        url="tags.php?action=get"
        :params="{property: 'composite'}"
        @input="$emit('input',$event)"
        :multiple="multiple"
    >
        <template #item="{item}">
            <v-icon :color="item.color" class="mr-2">fa-tag</v-icon>
            <v-icon v-if="item.public_flag" color="rgba(0,0,0,.54)" class="mr-2">fa-globe-americas</v-icon>
            {{item.name}}
            <!-- <div class="d-flex flex-column">
                {{item.name}}
                <span v-if="!item.public_flag" class="tiny">Private</span>
            </div> -->
        </template>
        <template #selection="{item}">
            <div>
                <v-icon :color="item.color" class="mr-2">fa-tag</v-icon>
                <v-icon v-if="item.public_flag" color="rgba(0,0,0,.54)" class="mr-2">fa-globe-americas</v-icon>
                {{item.name}}
            </div>
        </template>
    </fe-remote-combo>
</template>

<script>
export default {
    name: 'TagCombo',

    props: {
        multiple: { type: Boolean, default: false },
    }
}
</script>

<style lang="scss" scoped>
    .tiny {
        font-size: 10px;
        color: rgba(0,0,0,.4);
    }
</style>
